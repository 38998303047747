import React from 'react'

const CustomPaginatorTemplateOpenEnd = {
    //layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink',
    'FirstPageLink': (options) => {
        return (
            <button style={{ background: 'transparent' }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-paginator-icon pi pi-angle-double-left"></span>
            </button>
        )
    },
    'PrevPageLink': (options) => {
        return (
            <React.Fragment>
                <button style={{ background: 'transparent' }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-paginator-icon pi pi-angle-left"></span>
                </button>
                <button style={{ background: 'transparent', marginLeft: -10, marginBottom: 4 }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span style={{ color: '#0973c0', fontSize: 13 }} className="p-3">Previous page</span>
                </button>
            </React.Fragment>
        )
    },
    'PageLinks': (options) => {
        //console.log('options: ', options);
        return (
            <>
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                </button>
                {options.currentPage + 1 < options.totalPages && options.page + 1 === options.totalPages ?
                    <span style={{ marginRight: 20 }}>...</span> : null}
            </>
        )
    },
    'NextPageLink': (options) => {
        return (
            <React.Fragment>
                <button style={{ background: 'transparent', marginRight: -10, marginBottom: 4 }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span style={{ color: '#0973c0', fontSize: 13 }} className="p-3">Next page</span>
                </button>
                <button style={{ background: 'transparent' }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-paginator-icon pi pi-angle-right"></span>
                </button>
            </React.Fragment>
        )
    }
};

export default CustomPaginatorTemplateOpenEnd;