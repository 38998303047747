import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Button } from "primereact/button";
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { TreeSelect } from 'primereact/treeselect';
import { isArrayEmpty, createCheckedKeys, getYearRangeFuture } from "../../webapi/util";
import useCreateEditApp from './useCreateEditApp';
import RadioDropdown from './RadioDropdown/RadioDropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import ConfirmationDialog from "../ConfirmDialog/ConfirmationDialog";
import { Toast } from "primereact/toast";
import { AppsService } from '../../webapi/apps/core/services/dataTableService';
// Dropdown options
const dropdownOptionsCycles = [{ label: 'Daily', value: 'Daily' }, { label: 'Weekly', value: 'Weekly' }, { label: 'Monthly', value: 'Monthly' }]
//const dropdownOptionsReceiveAlerts = [{ label: 'yes', value: true }, { label: 'no', value: false }]
//const editableOptions = [{ label: 'yes', value: true }, { label: 'no', value: false }]
const sourceTypes = [
    { name: 'My Saved Searches', id: 1 },
    { name: 'My Library', id: 2 }
]
const CreateEditApp = ({
    userData,
    displayDialog = false,
    onHide,
    initialData = null,
    onShowToast,
    onReload = null,
    isEdit = false
}) => {
    const customToast = useRef();
    // States
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const [appID, setAppID] = useState(null);
    const [appName, setAppName] = useState('');
    const [appGroup, setAppGroup] = useState('');
    const [appDescription, setAppDescription] = useState('');
    const [appComments, setAppComments] = useState('');
    const [dropdownValueUpdateCycle, setDropdownValueUpdateCycle] = useState('Daily')
    const [dropdownValueReceiveAlerts, setDropdownValueReceiveAlerts] = useState(true)
    const [selectedRepos, setSelectedRepos] = useState([])
    const [availableRepositories, setAvailableRepositories] = useState([])
    const [checkedKeys, setCheckedKeys] = useState({})
    const [checkedOrgKeys, setCheckedOrgKeys] = useState({})
    const [usersShared, setUsersShared] = useState([])
    const [usersSharedInvisible, setUsersSharedInvisible] = useState([])
    const [orgShared, setOrgShared] = useState([])
    const [selectedSourceType, SetSelectedSourceType] = useState(null)
    const [sourceTypeDisabled, SetSourceTypeDisabled] = useState(false)
    const [selectedSourceName, SetSelectedSourceName] = useState(null)
    const [sourceNameDisabled, SetSourceNameDisabled] = useState(false)
    const [selectedReadcubeSource, SetSelectedReadcubeSource] = useState(null)
    const [readcubeSourceDisabled, SetReadcubeSourceDisabled] = useState(false)
    const [appNameDisabled, SetAppNameDisabled] = useState(false)
    const [appGroupDisabled, SetAppGroupDisabled] = useState(false)
    const [appDescriptionDisabled, SetAppDescriptionDisabled] = useState(false)
    const [appCommentsDisabled, SetAppCommentsDisabled] = useState(false)
    const [appURLDisabled, SetAppURLDisabled] = useState(false)
    const [expirationDateDisabled, SetExpirationDateDisabled] = useState(false)
    const [deleteAppDisabled, SetDeleteAppDisabled] = useState(false)
    const [updateCycleDisabled, SetUpdateCycleDisabled] = useState(false)
    const [selectedTemplate, SetSelectedTemplate] = useState(null)
    const [appURL, SetAppURL] = useState('')
    const [expirationDate, SetExpirationDate] = useState(null)
    const [sourceTemplateDisabled, SetSourceTemplateDisabled] = useState(false)
    const [allOrgArray, setAllOrgArray] = useState([])
    const { userArray, orgArray, //allOrgsAndSuborgs, 
        departmentMembers, submitCreateEditApp, templates, queryCollection, documentCollections, readcubeCollections } = useCreateEditApp({ userData, onShowToast })

    /*useEffect(() => {
        // Fetch department members, templates, and query collections on component mount
        if (initialData?.start) {
            let newOrgArray = orgArray
            if (allOrgsAndSuborgs && Object.keys(allOrgsAndSuborgs).length > 0) {
                newOrgArray?.forEach(org => {
                    Object.keys(allOrgsAndSuborgs).forEach(key => {
                        if (org.key === key) {
                            allOrgsAndSuborgs[key].forEach(suborg => {
                                if (!org.children.some(e => e.key === suborg.key)) {
                                    org.children = [...org.children, { children: [], expanded: false, id: suborg.id, key: suborg.key, isOrg: false, label: suborg.label !== 'default' ? suborg.label : 'Unassigned / Inactive regular users', selectable: true }]
                                }
                            })
                        }
                    })
                })
            }

            if (allOrgsAndSuborgs && Object.keys(allOrgsAndSuborgs).length > 0) {
                Object.keys(allOrgsAndSuborgs).forEach(key => {
                    if (!newOrgArray.some(e => e.key === key)) {
                        newOrgArray = [...newOrgArray, { children: allOrgsAndSuborgs[key], expanded: true, isOrg: true, key: key, label: allOrgsAndSuborgs[key][0].orgLabel, selectable: false }]
                    }
                })
            }
            setAllOrgArray(newOrgArray)
        }
    }, []);*/
    // Set initial data for update
    useEffect(() => {
        /*let newOrgArray = orgArray
        if (allOrgsAndSuborgs && Object.keys(allOrgsAndSuborgs).length > 0) {
            newOrgArray?.forEach(org => {
                Object.keys(allOrgsAndSuborgs).forEach(key => {
                    if (org.key === key) {
                        allOrgsAndSuborgs[key].forEach(suborg => {
                            if (!org.children.some(e => e.key === suborg.key)) {
                                org.children = [...org.children, { children: [], expanded: false, id: suborg.id, key: suborg.key, isOrg: false, label: suborg.label !== 'default' ? suborg.label : 'Unassigned / Inactive regular users', selectable: true }]
                            }
                        })
                    }
                })
            })
        }

        if (allOrgsAndSuborgs && Object.keys(allOrgsAndSuborgs).length > 0) {
            Object.keys(allOrgsAndSuborgs).forEach(key => {
                if (!newOrgArray.some(e => e.key === key)) {
                    newOrgArray = [...newOrgArray, { children: allOrgsAndSuborgs[key], expanded: true, isOrg: true, key: key, label: allOrgsAndSuborgs[key][0]?.orgLabel, selectable: false }]
                }
            })
        }
        setAllOrgArray(newOrgArray)*/
        SetSelectedSourceName(initialData?.queryCollection ? initialData?.queryCollection : initialData?.library ? initialData?.library : null)
        if (!initialData) {
            SetSelectedSourceType(null)
        }
        if (initialData && !isEdit && initialData?.start === undefined) {
            SetSelectedSourceType(initialData?.sourceType ?? null)
        }
        if (initialData && initialData?.start === undefined) {
            SetSourceTypeDisabled(true)
            SetSourceNameDisabled(true)
        }

        if (initialData && isEdit) {
            if (userData.userDetails?.highestAdminType === 'ROLE_COMPANY_ADMIN') {
                SetAppNameDisabled(true)
                SetAppGroupDisabled(true)
                SetAppDescriptionDisabled(true)
                SetAppCommentsDisabled(true)
                SetAppURLDisabled(true)
                SetExpirationDateDisabled(true)
                SetDeleteAppDisabled(true)
                SetUpdateCycleDisabled(true)
            }
            SetSelectedSourceType(initialData?.queryCollection ? sourceTypes[0] : initialData?.library ? sourceTypes[1] : initialData?.template === 'static' ? sourceTypes[2] : initialData?.template === 'open_science_tab1' ? sourceTypes[3] : null)
            setAppID(initialData?.id ?? null)
            setAppName(initialData?.name ?? "");
            setAppGroup(initialData?.group ?? "");
            setAppDescription(initialData?.description ?? "");
            setAppComments(initialData?.comments ?? "");
            setDropdownValueUpdateCycle(initialData?.updateCycle ?? "Daily");
            setDropdownValueReceiveAlerts(initialData?.alertOnUpdate ?? true);

            if (initialData?.repositories) {
                let oldAvailableRepos = availableRepositories
                selectedRepos.forEach(rep => {
                    if (!oldAvailableRepos.some(reposi => reposi.repositoryId === rep.repositoryId)) {
                        rep.optionDisabled = true
                        rep.label += ' (not available)'
                        oldAvailableRepos = [...oldAvailableRepos, rep]
                    }
                })
                setAvailableRepositories(oldAvailableRepos)
                setSelectedRepos(initialData?.repositories ?? []);
            }

            if (initialData?.readcubeCollectionId) {
                let selectedRCLib = readcubeCollections.filter(obj => {
                    return obj.id === initialData?.readcubeCollectionId
                })
                let unknownCollection = {}
                if (selectedRCLib[0] === undefined) {
                    unknownCollection.id = initialData?.readcubeCollectionId
                    unknownCollection.label = initialData?.readcubeCollectionId
                }
                SetSelectedReadcubeSource(selectedRCLib[0] !== undefined ? selectedRCLib[0] : unknownCollection)
                SetReadcubeSourceDisabled(true)
            }
            SetSelectedTemplate(initialData.template ?? null);
            SetAppURL(initialData?.url ?? "")
            SetExpirationDate(initialData?.expiryDate ? new Date(initialData?.expiryDate) : null)
            let checkedUsers = {}
            let usersShared = []
            let usersSharedInvisible = []
            if (initialData.userShares) {
                initialData.userShares.forEach(user => {
                    //if (user.username !== userData.userDetails.username) {
                    checkedUsers[user.id] = { partialChecked: false, checked: true }
                    usersShared = [...usersShared, user.id]
                    //} else {
                    //    usersSharedInvisible = [...usersSharedInvisible, user.id]
                    //}
                })
            }
            setUsersShared(initialData.userShares ? usersShared : []);
            setUsersSharedInvisible(usersSharedInvisible);
            setCheckedKeys(initialData.userShares ? checkedUsers : {});
            let checkedOrgs = {}
            let orgsShared = []
            if (initialData.departmentShares) {
                initialData.departmentShares.forEach(org => {
                    checkedOrgs[`${org.id}-${org.name}`] = { partialChecked: false, checked: true }
                    orgsShared = [...orgsShared, org.id]
                })
            }
            //console.log(usersShared)
            //console.log(checkedUsers)
            setOrgShared(initialData.departmentShares ? orgsShared : []);
            setCheckedOrgKeys(initialData.departmentShares ? checkedOrgs : {});
            //SetSourceTypeDisabled(true)
            //SetSourceNameDisabled(true)
            SetSourceTemplateDisabled(true)
        }
    }, [initialData, availableRepositories]);

    // Event handlers
    const onAppNameChange = (e) => setAppName(e.target.value)
    const onAppGroupChange = (e) => setAppGroup(e.target.value)
    const onAppDescriptionChange = (e) => setAppDescription(e.target.value)
    const onAppCommentsChange = (e) => setAppComments(e.target.value)
    const onDropdownUpdateCycleChange = (e) => setDropdownValueUpdateCycle(e.value)
    const onDropdownAlertsChange = (e) => setDropdownValueReceiveAlerts(e.value)
    const onRepositoriesChange = (e) => setSelectedRepos(e.value)
    const onSourceNameChange = (e) => SetSelectedSourceName(e.value)
    const onReadcubeSourceChange = (e) => {
        SetSelectedReadcubeSource(e.value)
    }
    const onAppURLChange = (e) => SetAppURL(e.target.value)
    const expirationDateChange = (date) => SetExpirationDate(date)

    const onSourceTypeChange = (e) => {
        SetSelectedSourceName(null)
        setSelectedRepos([])
        SetSelectedReadcubeSource(null)
        SetSelectedSourceType(e.value)
        if (e.value.id === 3) {
            SetSourceTemplateDisabled(true)
            SetSelectedTemplate('static')
        } else if (e.value.id === 4) {
            SetSourceTemplateDisabled(true)
            SetSelectedTemplate('open_science_tab1')
        } else {
            //SetSelectedTemplate(null)
            SetSourceTemplateDisabled(false)
        }
    }

    const onTemplateChange = (e) => {
        let template = e.value
        if (!initialData?.queryCollection && !initialData?.library) {
            setSelectedRepos([])
            SetAppURL('')
            SetExpirationDate(null)
            SetSelectedSourceName(null)
            SetSelectedReadcubeSource(null)
            if (template === 'open_science_tab4' || template === 'pubtracker') {
                SetSelectedSourceType(sourceTypes[0])
            } else {
                SetSelectedSourceType(null)
            }
        }
        SetSelectedTemplate(template)
    }

    const handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = []
        let orgKeys = []
        orgArray && orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (departmentMembers.some(mem => mem.id === Number(user))) {
                users = [...users, Number(user)]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = createCheckedKeys(members, departmentMembers, userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })
        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            setUsersShared(users)
            setCheckedKeys(userChecked)
        } else {
            let allOrgs = []
            userArray.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            setOrgShared(onlySubOrgs)
            setCheckedOrgKeys(orgChecked)
        }
    }



    const onHideOverlay = () => {
        onHide()
        setTimeout(() => {
            setAppName('')
            setAppGroup('')
            setAppDescription('')
            setAppComments('')
            setDropdownValueUpdateCycle('Daily')
            setDropdownValueReceiveAlerts(true)
            setSelectedRepos([])
            SetSelectedReadcubeSource(null)
            SetReadcubeSourceDisabled(false)
            setAvailableRepositories([])
            setUsersShared([])
            setOrgShared([])
            setCheckedKeys({})
            setCheckedOrgKeys({})
            SetSelectedSourceType(null)
            SetSourceTypeDisabled(false)
            SetSelectedSourceName(null)
            SetSourceNameDisabled(false)
            SetSelectedTemplate(null)
            SetSourceTemplateDisabled(false)
            SetAppURL('')
            SetExpirationDate(null)
            //setAllOrgArray([])
            setAppID(null)
            if (onReload)
                onReload()
        }, 500);
    }

    // Fetch repositories
    const fetchRepositories = async () => {
        let availableRepositories = []
        let selectedRepositories = []
        await userData.userDetails?.department?.selectedRepositories?.filter(rep => //rep.name === 'patents' ||
            rep.name === 'ifipatents' || rep.name === 'dspub' || rep.name === 'dsgrants' || rep.name === 'biembase2' || rep.name === 'med').forEach(rep => {
                if (rep.active && rep.features.includes("DOCUMENT_SEARCH")) {
                    let newObj = Object.keys(rep).filter(key =>
                        key === 'label' || key === 'name' || key === 'id'
                    ).reduce((newObj, currKey) =>
                        (newObj[currKey] = rep[currKey], newObj),
                        {});
                    newObj.repositoryId = newObj.id
                    delete newObj.id
                    availableRepositories.push(newObj);
                }
            })
        if (!isArrayEmpty(availableRepositories)) {
            availableRepositories.forEach(rep => { selectedRepositories.push(rep); })
        }
        setAvailableRepositories(availableRepositories)

        // console.log(selectedRepos, 'Selected', selectedRepositories)

        // setSelectedRepos(selectedRepositories)
    }

    const accept = () => {
        const service = new AppsService();
        const appIds = [appID]
        if (appIds?.length > 0) {
            service.deleteApps(appIds).then(res => {
                customToast.current.show({ severity: 'success', summary: 'Success', detail: 'App successfully deleted', life: 6000 });
                onReload()
                setDisplayConfirmDialog(false)
                onHide()

            }).catch(err => {
                customToast.current.show({ severity: 'error', summary: 'Error', detail: err?.message, life: 6000 });
            });
        }
    }

    const reject = () => {
        setDisplayConfirmDialog(false)
    }

    const onDeleteApp = () => {
        setDisplayConfirmDialog(true)
    };

    // Render component

    let today = new Date()
    let minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

    return <Dialog
        focusOnShow={false}
        visible={displayDialog}
        blockScroll
        onHide={onHideOverlay}
        onShow={() => fetchRepositories()}
        className='styledDialog'
        header={!isEdit ? `Create new app` : `Edit app`}
        style={{ minWidth: "60vw", width: '60vw' }}
        modal={true}
        dismissableMask={false}>
        <div>
            <Toast ref={customToast} />
            <div className="grid p-fluid"
                style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>

                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                    <label style={{ verticalAlign: 'sub' }}>Name *</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                    <InputText autoComplete='off'
                        disabled={appNameDisabled}
                        maxLength={200}
                        onChange={onAppNameChange}
                        value={appName}
                    //placeholder='Name'
                    />
                    <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                    <label style={{ verticalAlign: 'sub' }}>Description</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                    <InputText autoComplete='off'
                        disabled={appDescriptionDisabled}
                        maxLength={60}
                        onChange={onAppDescriptionChange}
                        value={appDescription}
                    //placeholder='Name'
                    />
                    <label style={{ color: 'darkgray' }}>Max. 60 characters</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                    <label style={{ verticalAlign: 'sub' }}>Comments</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                    <InputTextarea autoComplete='off'
                        maxLength={10000}
                        autoResize
                        rows={3}
                        disabled={appCommentsDisabled}
                        onChange={onAppCommentsChange}
                        //placeholder='Description'
                        value={appComments} />
                    <label style={{ color: 'darkgray' }}>Max. 10,000 characters</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                    <label style={{ verticalAlign: 'sub' }}>Template *</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                    <Dropdown
                        appendTo={document.body}
                        disabled={sourceTemplateDisabled}
                        value={selectedTemplate}
                        options={initialData?.queryCollection ? templates?.filter(temp => temp.id !== 'static' && temp.id !== 'open_science_tab1') : initialData?.library ? templates?.filter(temp => temp.id !== 'static' && temp.id !== 'open_science_tab1' && temp.id !== 'open_science_tab4' && temp.id !== 'pubtracker') : templates}
                        optionValue='id'
                        onChange={onTemplateChange}
                        placeholder="Select template"
                        style={{
                            borderBottom: '1px solid gray'
                        }}
                    >
                    </Dropdown>
                </div>
                {userData.userDetails?.highestAdminType !== 'ROLE_COMPANY_ADMIN' && ((selectedTemplate !== 'static' && selectedTemplate !== 'open_science_tab1' && selectedTemplate !== null) || (initialData?.queryCollection || initialData?.library)) ?
                    <React.Fragment>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Source type *</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <RadioDropdown
                                disabled={sourceTypeDisabled}
                                //disabled={true}
                                onChangeHandler={onSourceTypeChange}
                                selOption={selectedSourceType}
                                options={selectedTemplate === 'open_science_tab4' || selectedTemplate === 'pubtracker' ? sourceTypes?.filter(type => type.id === 1) : sourceTypes}
                                placeholder={'Select source type'} />
                        </div>
                    </React.Fragment>
                    : null
                }
                {selectedSourceType?.id !== 3 && selectedSourceType?.id !== 4 && userData.userDetails?.highestAdminType !== 'ROLE_COMPANY_ADMIN'
                    && ((selectedTemplate !== 'static' && selectedTemplate !== 'open_science_tab1' && selectedTemplate !== null) || (initialData?.queryCollection || initialData?.library)) ?
                    <React.Fragment>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Source name *</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <RadioDropdown disabled={sourceNameDisabled}
                                onChangeHandler={onSourceNameChange}
                                options={selectedSourceType?.id === 1 ? queryCollection : documentCollections}
                                selOption={selectedSourceName}
                                placeholder={'Select source name'} />
                        </div>
                    </React.Fragment>
                    : null
                }
                {selectedTemplate === 'static' ?
                    <React.Fragment>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Source URL *</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <InputText autoComplete='off'
                                disabled={appURLDisabled}
                                onChange={onAppURLChange}
                                value={appURL}
                            //placeholder='Name'
                            />
                        </div>
                    </React.Fragment>
                    : null
                }
                {selectedTemplate === 'static' ?
                    <React.Fragment>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Expiration date</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <Calendar
                                className='adminCalendar'
                                style={{
                                    // width: '70%',
                                    height: 31,
                                    verticalAlign: 'bottom',
                                    marginTop: 5
                                    //border: '1px solid #d6d6d6',
                                    //padding: 2
                                }}
                                value={expirationDate}
                                disabled={expirationDateDisabled}
                                id='calendar'
                                minDate={minDate}
                                readOnlyInput={true}
                                showIcon={false}
                                dateFormat='yy-mm-dd'
                                showButtonBar={true}
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={getYearRangeFuture(10)}
                                appendTo={document.body}
                                onChange={(e) => {
                                    expirationDateChange(e.value)
                                }}
                                aria-label='Select expiration date for static app'
                                title='Select expiration date for static app'
                            />
                        </div>
                    </React.Fragment>
                    : null
                }
                {((selectedTemplate !== 'static' && selectedTemplate !== 'open_science_tab1' && selectedTemplate !== null) || (initialData?.queryCollection || initialData?.library)) && selectedSourceType?.id === 1 && userData.userDetails?.highestAdminType !== 'ROLE_COMPANY_ADMIN' ?
                    <React.Fragment>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>{selectedSourceType?.id === 1 ? "Repositories *" : "Repositories"}</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <MultiSelect
                                pt={{
                                    checkboxIcon: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            if (e.target.className.baseVal !== "") {
                                                e.target.parentNode.click();
                                            } else {
                                                e.target.parentNode.parentNode.click();
                                            }
                                        },
                                    },
                                    headerCheckbox: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            e.target.parentNode.click();
                                        },
                                    },
                                }}
                                appendTo={document.body}
                                filter
                                value={selectedRepos}
                                disabled={selectedSourceType?.id === 1 ? false : true}
                                optionDisabled='optionDisabled'
                                options={selectedTemplate === 'open_science_tab4' ? availableRepositories.filter(rep => rep.name === 'dspub') : selectedTemplate === 'pubtracker' ? availableRepositories.filter(rep => rep.name === 'dspub' || rep.name === 'biembase2' || rep.name === 'med') : availableRepositories}
                                onChange={onRepositoriesChange}
                                placeholder="Select repositories"
                                style={{// width: 150, minWidth: 150,
                                    borderBottom: '1px solid gray', marginTop: 1
                                }} />
                        </div>
                    </React.Fragment>
                    : null
                }
                {((selectedTemplate === 'open_science_tab4' || selectedTemplate === 'pubtracker') && selectedTemplate !== null) && selectedSourceType?.id === 1 && userData.userDetails?.highestAdminType !== 'ROLE_COMPANY_ADMIN' ?
                    <React.Fragment>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>ReadCube Library *</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <RadioDropdown disabled={readcubeSourceDisabled}
                                onChangeHandler={onReadcubeSourceChange}
                                options={readcubeCollections}
                                selOption={selectedReadcubeSource}
                                placeholder={'Select Readcube Library'}
                                optionLabel={'label'} />
                        </div>
                    </React.Fragment>
                    : null
                }
                {selectedSourceType?.id !== 3 && ((selectedTemplate !== 'static' && selectedTemplate !== null) || (initialData?.queryCollection || initialData?.library)) ?
                    <React.Fragment>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Update cycle</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <Dropdown
                                appendTo={document.body}
                                value={dropdownValueUpdateCycle}
                                disabled={updateCycleDisabled}
                                options={dropdownOptionsCycles}
                                onChange={onDropdownUpdateCycleChange}
                                placeholder="Choose update interval"
                                style={{
                                    borderBottom: '1px solid gray'
                                }} >
                            </Dropdown>
                        </div>
                    </React.Fragment>
                    : null
                }
                {/*<div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                    <label style={{ verticalAlign: 'sub' }}>Receive alerts</label>
                </div>

                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                    <Dropdown
                        value={dropdownValueReceiveAlerts}
                        options={dropdownOptionsReceiveAlerts}
                        onChange={onDropdownAlertsChange}
                        placeholder="Receive alerts"
                        style={{
                            borderBottom: '1px solid gray'
                        }}>
                    </Dropdown>
                    </div>*/}
                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                    <label style={{ verticalAlign: 'sub' }}>Group</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                    <InputText autoComplete='off'
                        disabled={appGroupDisabled}
                        maxLength={200}
                        onChange={onAppGroupChange}
                        value={appGroup}
                    //placeholder='Name'
                    />
                    <label style={{ color: 'darkgray' }}>Used for grouping apps</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                    <label style={{ verticalAlign: 'sub' }}>Visibility</label>
                </div>
                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ display: 'flex', paddingRight: 10, paddingTop: 5 }}>
                    <div className="w-full" >
                        <TreeSelect disabled={departmentMembers && departmentMembers.length !== 0 ? false : true}
                            id='sharingTreeSelect'
                            appendTo={document.body}
                            resetFilterOnHide={true}
                            //appendTo={document.body}
                            selectionMode='checkbox'
                            placeholder='Select suborganizations'
                            value={checkedOrgKeys}
                            options={orgArray}
                            display='chip'
                            scrollHeight='200px'
                            filter
                            onChange={(e) => {
                                e.stopPropagation()
                                handleMemberChange(e.value, true)
                            }} style={{
                                borderBottom: '1px solid gray', maxWidth: '19vw'
                            }} />
                    </div>
                    <div className="w-full " >
                        <TreeSelect disabled={departmentMembers && departmentMembers.length !== 0 ? false : true}
                            id='sharingTreeSelect'
                            resetFilterOnHide={true}
                            appendTo={document.body}
                            selectionMode='checkbox'
                            placeholder='Select users'
                            value={checkedKeys}
                            options={userArray}
                            display='chip'
                            scrollHeight='200px'
                            filter
                            onChange={(e) => {
                                e.stopPropagation()
                                handleMemberChange(e.value, false)
                            }} style={{
                                borderBottom: '1px solid gray', maxWidth: '19vw', marginLeft: 10
                            }} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', paddingLeft: 17 }}>
                    <div className="full-text-message col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8">
                        Notes: i) Apps for search collections will be created only for searches of type 'Quick Search' and 'Advanced Search'. ii) Users changing the suborganization will lose access to apps shared on suborganization level, but will keep access to apps shared on personal level.
                    </div>
                </div>
            </div>
            <div className='col-12' style={{ paddingRight: 15, paddingTop: 8, marginBottom: 33 }}>
                {isEdit && !deleteAppDisabled ?
                    <Button style={{ float: 'left', marginLeft: 20, height: '27.19px' }} label="Delete app"
                        className='p-button-sm'
                        onClick={() => onDeleteApp(appID)} disabled={false}
                    />
                    :
                    <label style={{ float: 'left', paddingTop: 0, marginTop: 0, paddingLeft: 20 }}>* required</label>
                }
                <Button className="p-button-sm primaryButton"
                    style={{ marginRight: 5, float: 'right', marginTop: 5 }}
                    label={!isEdit ? `Create` : `Edit`}
                    disabled={selectedTemplate === null || appName === '' || (((selectedTemplate === 'basic' || selectedTemplate === 'open_science') &&
                        (selectedSourceName === null ||
                            (selectedRepos.length === 0 && selectedSourceType?.id === 1))) ||
                        (selectedTemplate === 'static' && appURL === '') || ((selectedTemplate === 'open_science_tab4' || selectedTemplate === 'pubtracker') &&
                            (selectedSourceName === null ||
                                (selectedRepos.length === 0 && selectedSourceType?.id === 1) || selectedReadcubeSource === null)))}//|| !usersShared?.length > 0 || !orgShared?.length > 0}
                    onClick={() => {
                        submitCreateEditApp({ initialData, isEdit, appID, selectedSourceName, usersShared, usersSharedInvisible, orgShared, selectedRepos, selectedTemplate, dropdownValueReceiveAlerts, dropdownValueUpdateCycle, appName, appDescription, appComments, selectedSourceType, appURL, expirationDate, appGroup, selectedReadcubeSource }, onHideOverlay)
                    }
                    }
                />
                <Button className="p-button-secondary p-button-sm"
                    style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }}
                    label="Cancel"
                    onClick={onHideOverlay}
                />
            </div>
            <ConfirmationDialog
                displayDialog={displayConfirmDialog}
                onHide={reject}
                onSubmit={accept}
                headerText="Confirm"
                messageText='Would you like to delete this app?'
                submitButtonLabel="Delete" />
        </div>
    </Dialog>
}

CreateEditApp.propTypes = {
    userData: PropTypes.object,
    displayDialog: PropTypes.bool,
    onHide: PropTypes.func,
    onShowToast: PropTypes.func,
    onReload: PropTypes.func,
    initialData: PropTypes.object,
    isEdit: PropTypes.bool
};

export default CreateEditApp


