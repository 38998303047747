import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { Component } from 'react';
import { hasUserRole, isArrayEmpty, shortenLargeNumber } from '../../../util';
import { createLinkoutsMenuItems } from '../../utils/chemistryDisplayUtil';
import { createQuickSearchRequestFromConceptData, createShortcutMenuItems, executeQuickSearchShortcut, executeQuickSearchWithTermlocShortcut } from '../../../util/shortcuts';
import { SEARCH_TYPE_FILTER_FULLMATCH } from '../../utils/chemistrySearchConstants';
import { SEARCH_TARGET_PRODUCTS } from '../../utils/chemistryDBs';
import { createChemSearchShortcutDataItemForOcid, createChemSearchShortcutDataItemForSmiles, executeChemSearchShortcut } from '../../../util/shortcuts2';


class CompoundMentions extends Component {

    render() {

        const { compoundData, preparationsTarget } = this.props;

        if (!compoundData) return null;

        const ocid = compoundData.ocid;
        const domain = compoundData.domain;
        const name = compoundData.preferredName;
        const smiles = compoundData.extendedData?.smiles ? compoundData.extendedData?.smiles[0] : null;
        const mculeLink = compoundData.extendedData?.mculeLink;
        const sourceReferences = compoundData.sourceReferences;

        const shortcutsItems = createShortcutMenuItems(true, true, true, ocid, domain, name, null, true, true);
        const linkoutsItems = createLinkoutsMenuItems(smiles, sourceReferences);

        const docCountPats = this.props.docCountPatents !== undefined ? this.props.docCountPatents === -1 ?
            '(fetching)' : `(${shortenLargeNumber(this.props.docCountPatents)})` : '';
        const docCountPatClaims = this.props.docCountPatentClaims !== undefined ? this.props.docCountPatentClaims === -1 ?
            '(fetching)' : `(${shortenLargeNumber(this.props.docCountPatentClaims)})` : '';
        const docCountPubs = this.props.docCountPublications !== undefined ? this.props.docCountPublications === -1 ?
            '(fetching)' : `(${shortenLargeNumber(this.props.docCountPublications)})` : '';

        return (
            <div style={{ padding: '0px 0px', lineHeight: 1.7 }}>
                {ocid ?
                    <div style={{ padding: '0 0 10px 0' }}>
                        {this.props.patentsRepository ?
                            <>
                                <div className={this.props.docCountPatents < 1 ? 'disableContent' : ''}>
                                    <Button title="Search in patents"
                                        label={`${`Patents`} ${docCountPats}`}
                                        className="secondaryButtonAsLink"
                                        style={{ textAlign: 'left', backgroundColor: '#ffffff !important' }}
                                        onClick={e => {
                                            const req = createQuickSearchRequestFromConceptData(ocid, domain, name, null, null, true);
                                            executeQuickSearchShortcut(req, this.props.patentsRepository)
                                        }}
                                    /></div>
                                <div className={this.props.docCountPatentClaims < 1 ? 'disableContent' : ''}>
                                    <Button title="Search in patent claims"
                                        label={`${`Patent claims`} ${docCountPatClaims}`}
                                        className="secondaryButtonAsLink"
                                        style={{ textAlign: 'left', backgroundColor: '#ffffff !important' }}
                                        onClick={e => {
                                            const req = createQuickSearchRequestFromConceptData(ocid, domain, name, 'claims', 'Claim', true);
                                            executeQuickSearchWithTermlocShortcut(req, this.props.patentsRepository)
                                        }}
                                    /></div>
                            </> : null}

                        {this.props.publicationsRepository ?
                            <div className={this.props.docCountPublications < 1 ? 'disableContent' : ''}>
                                <Button title="Search in publications"
                                    label={`${`Publications`} ${docCountPubs}`}
                                    className="secondaryButtonAsLink"
                                    style={{ textAlign: 'left', backgroundColor: '#ffffff !important' }}
                                    onClick={e => {
                                        const req = createQuickSearchRequestFromConceptData(ocid, domain, name, null, null, true);
                                        executeQuickSearchShortcut(req, this.props.publicationsRepository)
                                    }}
                                /></div> : null}
                    </div> : null}

                <div style={{ padding: '10px 0' }}>

                    <div style={{ visibility: !isArrayEmpty(shortcutsItems) ? 'visible' : 'hidden' }}>
                        <Button title="Send to searches"
                            label='Search in ...'
                            className="secondaryButtonAsLink"
                            style={{ textAlign: 'left' }}
                            onClick={e => this.shortcutsOverlay.toggle(e)}
                        /></div>

                    <div>
                        {hasUserRole('ROLE_REACTIONSEARCH') && this.props.includePreparationsSearch ?
                            ocid ?
                                <Button title="Search as reaction product"
                                    label='Search for preparations'
                                    className="secondaryButtonAsLink"
                                    style={{ textAlign: 'left' }}
                                    onClick={e => {
                                        const dataItem = createChemSearchShortcutDataItemForOcid(ocid, domain, name, SEARCH_TARGET_PRODUCTS, SEARCH_TYPE_FILTER_FULLMATCH);
                                        executeChemSearchShortcut(dataItem, preparationsTarget, true);
                                    }}
                                />
                                :
                                smiles ?
                                    <Button title="Search as reaction product"
                                        label='Search for preparations'
                                        className="secondaryButtonAsLink"
                                        style={{ textAlign: 'left' }}
                                        onClick={e => {
                                            const dataItem = createChemSearchShortcutDataItemForSmiles(smiles, SEARCH_TARGET_PRODUCTS, SEARCH_TYPE_FILTER_FULLMATCH);
                                            executeChemSearchShortcut(dataItem, preparationsTarget, true);
                                        }}
                                    />
                                    : null : null
                        }
                    </div>

                    <div style={{ visibility: !isArrayEmpty(linkoutsItems) ? 'visible' : 'hidden' }}>
                        <Button title="Linkouts"
                            label='Linkouts'
                            className="secondaryButtonAsLink"
                            style={{ textAlign: 'left' }}
                            onClick={e => this.linkoutsMenu.toggle(e)}
                        />
                        <span style={{ fontSize: 13, position: 'relative', top: 7, marginLeft: 5 }} className='material-icons'>launch</span>
                    </div>

                    <div style={{ visibility: mculeLink?.conceptUrl ? 'visible' : 'hidden' }}>
                        <Button title="Open Mcule in new tab"
                            label='Open in Mcule'
                            className="secondaryButtonAsLink"
                            style={{ textAlign: 'left', paddingLeft: 1 }}
                            onClick={e => window.open(mculeLink.conceptUrl, '_blank')}
                        />
                        <span style={{ fontSize: 13, position: 'relative', top: 5, marginLeft: 5 }} className='material-icons'>launch</span>
                    </div>
                </div>

                <TieredMenu
                    className='chemAdditionalInfoMenu'
                    model={shortcutsItems}
                    popup={true}
                    ref={(el) => this.shortcutsOverlay = el} />

                <TieredMenu
                    className='chemAdditionalInfoMenu'
                    model={linkoutsItems}
                    popup={true}
                    ref={(el) => this.linkoutsMenu = el} />
            </div>
        )
    }
}
export default CompoundMentions