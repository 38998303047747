import "./ResultsStatistics.css";
import { IoTriangle, IoEllipse } from "react-icons/io5";
import { MdListAlt } from "react-icons/md";
import { addThousandsSeparatorToNumber } from "../../../../../util";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";

const ResultsStatistics = ({ groupID, isEditGroup, fetching }) => {
  const numberOfResults = useSelector(
    (state) => state.advancedSearch3Reducer.numberOfResults
  );
  const numberOfResultsPreview = useSelector(
    (state) => state.advancedSearch3Reducer.numberOfResultsPreview
  );

  const numOfResults = numberOfResults[groupID]?.hitCount ?? 0;
  const showPreview = !!numberOfResultsPreview;
  const numOfResultsPreview =
    showPreview && numberOfResultsPreview[groupID]?.hitCount
      ? numberOfResultsPreview[groupID].hitCount
      : 0;
  const numOfResultsDiff = numOfResultsPreview - numOfResults;
  const numOfResultsDiffAbs = Math.abs(numOfResultsDiff);

  const renderHits = () => {
    const sameNumOfHits = numOfResultsDiff === 0;
    const moreHits = numOfResultsDiff > 0;

    const numOfResultsDiffAbsDispl =
      addThousandsSeparatorToNumber(numOfResultsDiffAbs);

    const tooltip = sameNumOfHits
      ? `Same number of hits, if changes were applied`
      : moreHits
      ? `${numOfResultsDiffAbsDispl} more ${
          numOfResultsDiffAbs === 1 ? "hit" : "hits"
        }, if changes were applied`
      : `${numOfResultsDiffAbsDispl} fewer ${
          numOfResultsDiffAbs === 1 ? "hit" : "hits"
        }, if changes were applied`;

    const icon = sameNumOfHits ? (
      <IoEllipse className="same-hits-icon" />
    ) : moreHits ? (
      <IoTriangle className="more-hits-icon" />
    ) : (
      <IoTriangle className="fewer-hits-icon" />
    );

    return (
      <div className="search-group-stats-difference" title={tooltip}>
        {numOfResultsDiffAbsDispl}
        {icon}
      </div>
    );
  };

  const numOfResultsDispl = addThousandsSeparatorToNumber(numOfResults);
  const numOfResultsPreviewDispl =
    addThousandsSeparatorToNumber(numOfResultsPreview);

  return (
    <div>
      {fetching ? (
        <div className="search-group-stats-spinner">
          <ProgressSpinner className="results-progress-spinner" />
        </div>
      ) : (
        <div className="search-group-stats-results">
          <div>
            {showPreview ? (
              <div
                className="search-group-stats-difference"
                title={`${numOfResultsPreviewDispl} ${
                  numOfResultsDiffAbs === 1 ? "hit" : "hits"
                }, if changes were applied`}
              >
                <div className="search-group-stats-preview-number">
                  {numOfResultsPreviewDispl}
                </div>
                <MdListAlt className="num-of-results-icon" />
              </div>
            ) : (
              <div
                title={`${numOfResultsDispl} ${
                  numOfResults === 1 ? "hit" : "hits"
                }`}
              >
                {numOfResultsDispl}
              </div>
            )}
          </div>

          {showPreview && isEditGroup && renderHits()}
        </div>
      )}
    </div>
  );
};

export default ResultsStatistics;
