import {
    QUERY_TYPE_QUICK_SEARCH, QUERY_TYPE_QUICK_SEARCH_2,
    REPOSITORY_INFO_NOT_AVAILABLE, QUERY_TYPE_ADVANCED_SEARCH, QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH
} from "../../../../properties";

export const WILDCARD_ASTERISK = '*';

export const ALLOWED_SAVED_SEARCH_TYPES = [QUERY_TYPE_QUICK_SEARCH.id, QUERY_TYPE_QUICK_SEARCH_2.id];

export const ALLOWED_SENTENCE_ANALYSIS_SAVED_SEARCH_TYPES = [QUERY_TYPE_QUICK_SEARCH.id, QUERY_TYPE_QUICK_SEARCH_2.id, QUERY_TYPE_ADVANCED_SEARCH.id];

export const ALLOWED_SENTENCE_ANALYSIS_LOAD_SEARCH_TYPES = [QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id];

export const FILTER_CATEGORY_PREFIX = 'category:';
export const PREFIX_DOMAIN_FACET = 'DOMAIN__';
export const DOMAIN_FACETS = ['DOMAIN__chem', 'DOMAIN__diseases', 'DOMAIN__drugs', 'DOMAIN__methods', 'DOMAIN__proteins'];
export const DOMAIN_FACET_BLOCKLIST = {
    'DOMAIN__chem': ['compounds'],
    'DOMAIN__diseases': ['disease'],
    'DOMAIN__drugs': ['drug'],
    'DOMAIN__methods': ['method'],
    'DOMAIN__proteins': ['proteins and genes'],
};

export const SEARCH_FILTER_ID_DOMAIN = 'domain';
export const SEARCH_FILTER_ID_DOMAIN_ANY = 'domainAny';

export const SEARCH_FILTER_ID_PUBDATE = 'pubDate';
export const SEARCH_FILTER_ID_INSDATE = 'insDate';
export const SEARCH_FILTER_ID_PRIO_DATE = 'prioDate';
export const SEARCH_FILTER_ID_START_DATE = 'startDate';
export const SEARCH_FILTER_ID_END_DATE = 'endDate';
export const SEARCH_FILTER_ID_TERMLOC = 'termloc';
export const SEARCH_FILTER_ID_SEARCH_MODE = 'searchMode';
export const SEARCH_FILTER_ID_SEARCH_MODE_LOCAL = 'searchModeLocal';
export const SEARCH_FILTER_ID_CONCEPT_DISTANCE = 'conceptDistance';
export const SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL = 'conceptDistanceLocal';
export const SEARCH_FILTER_ID_GROUP_BY_PAT_FAM = 'groupByPatFam';
export const SEARCH_FILTER_ID_BULK_IMPORT = 'bulkImport';
export const SEARCH_FILTER_ID_TITLE_SEARCH = 'searchTitle';
export const SEARCH_FILTER_ID_AUTHOR = 'author';
export const SEARCH_FILTER_ID_IPC_DOMAIN = 'ipcDomain';
export const SEARCH_FILTER_ID_CONDITION = 'condition';
export const SEARCH_FILTER_ID_DRUG = 'drug';
export const SEARCH_FILTER_ID_ASSIGNEE = 'assignee';
export const SEARCH_FILTER_ID_INVENTOR = 'inventor';
export const SEARCH_FILTER_ID_PATENT_OFFICE = 'patentOffice';
export const SEARCH_FILTER_ID_IPC_CLASS = 'ipcClass';
export const SEARCH_FILTER_ID_CPC_CLASS = 'cpcClass';
export const SEARCH_FILTER_ID_PATENT_NO = 'patNo';
export const SEARCH_FILTER_ID_PATENT_TYPE = 'patentType';
export const SEARCH_FILTER_ID_DOI = 'doi';
export const SEARCH_FILTER_ID_OA_STATUS = 'openAccessStatus';
export const SEARCH_FILTER_ID_PMCID = 'pmcID';
export const SEARCH_FILTER_ID_PMID = 'pmID';
export const SEARCH_FILTER_ID_PUB_TYPE = 'publicationType';
export const SEARCH_FILTER_ID_PUBLISHER = 'publisher';
export const SEARCH_FILTER_ID_JOURNAL = 'journal';
export const SEARCH_FILTER_ID_VOLUME = 'volume';
export const SEARCH_FILTER_ID_ISSUE = 'issue';
export const SEARCH_FILTER_ID_JOURNAL_SOURCE = 'journalSource';
export const SEARCH_FILTER_ID_CLIN_TRIAL_ID = 'trialID';
export const SEARCH_FILTER_ID_SPONSOR = 'sponsor';
export const SEARCH_FILTER_ID_STUDY_TYPE = 'studyType';
export const SEARCH_FILTER_ID_FUNDER = 'funder';
export const SEARCH_FILTER_ID_FUNDER_COUNTRY = 'funderCountry';
export const SEARCH_FILTER_ID_GRANT_NO = 'grantNo';
export const SEARCH_FILTER_ID_GRANTEE = 'grantee';
export const SEARCH_FILTER_ID_RESEARCH_ORG = 'researchOrg';
export const SEARCH_FILTER_ID_RESEARCH_ORG_COUNTRY = 'researchOrgCountry';
export const SEARCH_FILTER_ID_CLIN_PHASE = 'clinPhase';
export const SEARCH_FILTER_ID_STATUS = 'status';

export const SEARCH_FILTER_TYPE_TERMLOC = 'termloc';
export const SEARCH_FILTER_TYPE_IPC_DOMAIN = 'ipcDomain';
export const SEARCH_FILTER_TYPE_CHECKBOX = 'checkBox';
export const SEARCH_FILTER_TYPE_DROPDOWN_STATIC = 'dropdownStatic';
export const SEARCH_FILTER_TYPE_RADIO_STATIC = 'radioStatic';
export const SEARCH_FILTER_TYPE_INPUT_FREE = 'inputFree';
export const SEARCH_FILTER_TYPE_DATE_RANGE = 'dateRange';
export const SEARCH_FILTER_TYPE_NUM_RANGE = 'numRange';
export const SEARCH_FILTER_TYPE_FACETS_WITH_INPUT = 'facetsInputFree';
export const SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY = 'facetsExamples';
export const SEARCH_FILTER_TYPE_AUTOCOMPLETE_WITH_TERMLOC = 'autocompleteTermloc';
export const SEARCH_FILTER_TYPE_INPUT_FREE_TERMLOC = 'inputFreeTermloc';

export const QUERY_TERM_TYPE_CONCEPT = 'concept';
export const QUERY_TERM_TYPE_METADATA = 'metadata';
export const QUERY_TERM_TYPE_TERMLOC = 'termloc';
export const QUERY_TERM_TYPE_IPC_DOMAIN = 'ipcDomain';
export const QUERY_TERM_TYPE_TERMLOC_QUERY_TERMS = 'termlocQueryTerms';
export const QUERY_TERM_TYPE_BULK_IDS = 'bulkIDs';

export const QUERY_PREFIX_FACETFILTER = 'facetfilter';
export const QUERY_PREFIX_EXTRAFIELD = 'extrafield';
export const QUERY_PREFIX_EXTRAFIELD_EXACT = 'xi-dn_';
export const QUERY_PREFIX_EXTRAFIELD_TEXT = 'xt-dn_';

export const FREETEXT_WITH_VARIANTS = { prefix: 't', label: 'Text with variants' };
export const FREETEXT_EXACT = { prefix: 'tr', label: 'Text exact' };

export const BULK_IMPORT_TYPE_ALL_EXT = 'mdid';
export const BULK_IMPORT_TYPE_INT_DOC_ID = 'docid';
export const BULK_IMPORT_TYPE_DOI = 'doi';
export const BULK_IMPORT_TYPE_PATENT_NO = 'pdid';
export const BULK_IMPORT_TYPE_PMCID = 'pmc';
export const BULK_IMPORT_TYPE_PMID = 'pmid';
export const BULK_IMPORT_TYPE_GRANT_NO = 'xi-dn_projectnum';
export const BULK_IMPORT_TYPE_EXT_DOC_ID = 'extid';
export const BULK_IMPORT_TYPE_CLINTRIALID = 'xi-dn_trialid';

export const bulkImportTypes = {};
bulkImportTypes[BULK_IMPORT_TYPE_ALL_EXT] = {
    id: BULK_IMPORT_TYPE_ALL_EXT, label: 'External document IDs (all types)', prio: 1
};
bulkImportTypes[BULK_IMPORT_TYPE_CLINTRIALID] = {
    id: BULK_IMPORT_TYPE_CLINTRIALID, label: 'Clinical trial IDs', prio: 4,
    isOfType: (value) => {
        const regex = /^([A-Z]{3}[A-Z]*[0-9]+)|([1-2][0-9]{3}-[0-9]{6}-[0-9]{2}(-[A-Z]{2})?)$/g;
        return ((value.match(regex) || []).length > 0);
    }
};
bulkImportTypes[BULK_IMPORT_TYPE_DOI] = {
    id: BULK_IMPORT_TYPE_DOI, label: 'DOIs', prio: 7,
    isOfType: (value) => {
        return (value.startsWith('http://dx.doi.org/') || value.startsWith('10.'));
    }
};
bulkImportTypes[BULK_IMPORT_TYPE_GRANT_NO] = {
    id: BULK_IMPORT_TYPE_GRANT_NO, label: 'Grant numbers', prio: 3,
    isOfType: (value) => {
        const regex = /^(([A-Z][A-Z]\/X[0-9]+\/[0-9]+)|([1-9][0-9]\/[0-9]+-[0-9]+))$/g;
        return ((value.match(regex) || []).length > 0);
    }
};
bulkImportTypes[BULK_IMPORT_TYPE_PATENT_NO] = {
    id: BULK_IMPORT_TYPE_PATENT_NO, label: 'Patent numbers', prio: 5,
    isOfType: (value) => {
        // const regex = /^[A-Z][A-Z]-?[0-9]+(-| )?[A-Z][0-9]*$/g;
        const regex = /^[A-Z][A-Z]-?[0-9]+((-| )?\(?[A-Z][0-9]*\)?)?$/g;
        return ((value.match(regex) || []).length > 0);
    }
};
bulkImportTypes[BULK_IMPORT_TYPE_PMCID] = {
    id: BULK_IMPORT_TYPE_PMCID, label: 'PMCIDs', prio: 8,
    isOfType: (value) => {
        const regex = /^PMC[0-9]+$/g;
        return ((value.match(regex) || []).length > 0);
    }
};
bulkImportTypes[BULK_IMPORT_TYPE_PMID] = {
    id: BULK_IMPORT_TYPE_PMID, label: 'PMIDs', prio: 2,
    isOfType: (value) => {
        const regex = /^[0-9]+$/g;
        return ((value.match(regex) || []).length > 0);
    }
};
/*bulkImportTypes[BULK_IMPORT_TYPE_EXT_DOC_ID] = {
    id: BULK_IMPORT_TYPE_EXT_DOC_ID, label: 'External document IDs', prio: 0
};*/
bulkImportTypes[BULK_IMPORT_TYPE_INT_DOC_ID] = {
    id: BULK_IMPORT_TYPE_INT_DOC_ID, label: 'Internal document IDs', prio: 6,
    isOfType: (value) => {
        const regex = /^[a-z]+.[0-9]+$/g;
        return ((value.match(regex) || []).length > 0);
    }
};
export const BULK_IMPORT_TYPES = bulkImportTypes;

/*
export const FILTER_MAP = {
    'Publisher': { prefix: 'publisher', label: 'Publisher' },
    'Journal': { prefix: 'journal', label: 'Journal' },
    'Author': { prefix: 'auth', label: 'Author' },
    'Assignee': { prefix: 'pass', label: 'Assignee' },
    'Inventor': { prefix: 'pinv', label: 'Inventor' },
    'AuthorCountry': { prefix: 'authcntr', label: 'Author Country' },
    'AuthorContinent': { prefix: 'authcont', label: 'Author Continent' },
    'PublicationType': { prefix: 'pubtype', label: 'Publication Type' },
    'Keywords': { prefix: 'keyw', label: 'Keywords' },
}
*/

export const FACET_CONCEPTS = 'Concepts'; // ['all']; //["DOMAIN__chem", "DOMAIN__species", "DOMAIN__substances", "DOMAIN__drugs", "DOMAIN__companies", "Inventor", "Assignee", "Author"];

export const FACET_TERM_UNKNOWN = 'unknown';

export const DEFAULT_ROW_COUNT = 20;

export const MAX_NUM_OF_FILTER_FACETS = 10;
export const MAX_NUM_OF_DOMAIN_FACETS = 16;
export const MAX_NUM_OF_FACETS = 500;

export const LOGICALS = [
    { label: 'AND', value: 'and' },
    { label: 'OR', value: 'or' }];

export const SORT_FIELD_PUBDATE = 'pdate';
export const SORT_FIELD_STARTDATE = 'startDate';
export const SORT_FIELD_INSTIME = 'insTime';
export const SORT_FIELD_RELEVANCE_AND_DATE = 'relevanceAndDate';
export const SORT_FIELD_RELEVANCE = 'relevance';

export const SORT_MODE_ASCENDING = 'ascending';
export const SORT_MODE_DESCENDING = 'descending';

export const DEFAULT_SORT_FACTOR = 0.01;

export const SORT_MODES = [
    { label: SORT_MODE_ASCENDING, value: SORT_MODE_ASCENDING },
    { label: SORT_MODE_DESCENDING, value: SORT_MODE_DESCENDING }];

export const DEFAULT_SORT_CRITERIA = { sortField: SORT_FIELD_RELEVANCE_AND_DATE, sortMode: SORT_MODE_DESCENDING, sortFactor: DEFAULT_SORT_FACTOR };
export const DEFAULT_QUERY_SETTINGS = {
    query: 'all:*',
    internalQuery: '+all:"*"',
    rankedQuery: '+all:* dis:* proteins:* drugs:*',
    defaultSortingOptions: [SORT_FIELD_RELEVANCE_AND_DATE, SORT_FIELD_RELEVANCE],
};

export const INITIAL_SEARCH_FORM_SETTINGS = {
    queryChanged: false,
    queryTerms: [],
    logicalOperator: LOGICALS[0].value,
    repositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE,
    showDomainFacets: false,
    showResults: false,
    selectedFilters: [],
    selectedDocuments: {},
    selectedSavedSearch: null,
    savedSearchID: null,
    savedSearchName: null,
    editQuery: false,
    editableQuery: false,
    editQueryID: null,
    queryFullName: '',
    queryName: '',
    queryDescription: '',
    queryString: '',
    selectedQueryCollectionIDs: []
};
export const INITIAL_RESULTS_SETTINGS = {
    first: 0,
    rows: DEFAULT_ROW_COUNT,
    facets: {},
    //openFilterCategories: {}
    //...DEFAULT_SORT_CRITERIA
};