import { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";


class CompoundSearchInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="compoundSearchInfo"
                title="Click for information about compound search"
                imageAltText="Compound search information"
                overlayContent={
                    <>
                        <h3>Compound search search types</h3>

                        <p className='secondaryInfo'>
                            Please note: Not all search types are available for all settings.
                        </p>

                        <h5>Exact</h5>
                        <p>
                            OpenChemLib's IDcode is a very compact string that beside the molecular graph it also
                            considers different numbers of implicit hydrogen atoms, electronic and isotopic states as
                            well as stereochemistry of the query molecule - thus we will find exactly the molecule as
                            drawn in the structure editor.
                        </p>

                        <h5>Full match</h5>
                        <p>
                            A full match search helps to find molecules in our database that have the same molecular
                            structure as the query structure. If special molecular features are present in the query
                            such as stereochemistry, isotopes or electronic states, etc. then these features will be
                            disregarded in the search and all molecules that match the query with all feature variants
                            are selected as hits.
                        </p>

                        <h5>Substructure</h5>
                        <p>
                            Substructure search helps to find those molecules in our database that contain the query structure
                            sketched in the structure editor. The algorithm uses modified OpenChemLib
                            fingerprints and molecular graph-based algorithms to find matching molecules.
                            We have also implemented advanced substructure search features such as atom lists
                            and open variable positions flagged with an "R" as a substituent in the query molecule
                            in the structure editor.
                        </p>

                        <h5>Similarity</h5>
                        <p>
                            The similarity search helps to find structurally similar molecules to the query structure and
                            target molecules. We employ a structural tanimoto similarity descriptor that considers features
                            such as aromaticity and environment spheres around atoms but neglects stereochemistry.
                        </p>
                    </>
                }
            />
        );
    }
}

export default CompoundSearchInfo;