import "./Infos.css";
import InfoOverlayWithIcon from "../../../webapi/general/InfoOverlayWithIcon";

const PreviewRepositoryInfo = () => {
  return (
    <InfoOverlayWithIcon
      infoID="previewRepoInfo"
      title="Click for information about preview repository"
      imageAltText="Information about preview repository"
      overlayContent={
        <div className="info-text-container">
          <h1>Preview repository</h1>
          <p>
            Select a preview repository from the list of available document
            collections to check if a search group produces any results in this
            repository and to see how the number changes when you edit a group.
          </p>
          <p>
            On the right side of each search group you will see the number of
            hits in the selected preview repository for this specific group,
            including all resolved references.
          </p>
          <p>
            If you edit a search group you will see the updated number of
            results if the changes were applied as well as the info about how
            many more or fewer hits there are compared to the latest version
            before the editing step.
          </p>
        </div>
      }
    />
  );
};

export default PreviewRepositoryInfo;
