import {
    CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
    CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, CHEMISTRY_QUERY_TERM_TYPE_INCHI, CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY,
    CHEMISTRY_QUERY_TERM_TYPE_OCID, CHEMISTRY_QUERY_TERM_TYPE_SMILES
} from "../utils/chemistrySearchConstants";


export const CHEMISTRY_EXAMPLES = [
    {
        // id is mandatory, it will be used as key,
        //  since there can be multiple sections
        id: "ident",
        label: 'Identifier',
        description: "Search by compound identifier.",
        examples: [{
            isAttributeSearch: true, label: 'aspirin',
            description: 'Search by term "aspirin".',
            queryTerms: [{
                domains: ['chem'],
                queryValues: ['aspirin'],
                term: 'aspirin',
                type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
                ocids: [190000021540]
            }]
        }, {
            isAttributeSearch: true, label: 'benazepril',
            description: 'Search by term "benazepril".',
            queryTerms: [{
                domains: ['chem'],
                queryValues: ['benazepril'],
                term: 'benazepril',
                type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
                ocids: [190000020749]
            }]
        }, {
            isAttributeSearch: true, label: 'C1=CC=C(C=C1)C=O',
            description: 'Search by SMILES.',
            queryTerms: [{
                queryValues: ['C1=CC=C(C=C1)C=O'],
                term: 'C1=CC=C(C=C1)C=O',
                type: CHEMISTRY_QUERY_TERM_TYPE_SMILES
            }]
        }, {
            isAttributeSearch: true, label: 'RZVAJINKPMORJF-UHFFFAOYSA-N',
            description: 'Search by InChI key.',
            queryTerms: [{
                queryValues: ['RZVAJINKPMORJF-UHFFFAOYSA-N'],
                term: 'RZVAJINKPMORJF-UHFFFAOYSA-N',
                type: CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY,
            }]
        }, {
            isAttributeSearch: true, label: 'InChI=1S/C3H6O/c1-3(2)4/h1-2H3',
            description: 'Search by InChI.',
            queryTerms: [{
                queryValues: ['InChI=1S/C3H6O/c1-3(2)4/h1-2H3'],
                term: 'InChI=1S/C3H6O/c1-3(2)4/h1-2H3',
                type: CHEMISTRY_QUERY_TERM_TYPE_INCHI,
            }]
        }, {
            isAttributeSearch: true, label: '190000010374',
            description: 'Search by OCID.',
            queryTerms: [{
                queryValues: ['190000010374'],
                term: '190000010374',
                type: CHEMISTRY_QUERY_TERM_TYPE_OCID,
                subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND
            }]
        }]
    },
    {
        id: "class",
        label: 'Classification',
        description: "Search by a chemical class representing a large set of actual chemical compounds.",
        examples: [{
            isAttributeSearch: true, label: 'steroids',
            description: 'A search for "steroids" will find chemical compounds which are classified as steroids.',
            queryTerms: [{
                domains: ['chem'],
                queryValues: ['steroids'],
                term: 'steroids',
                type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS,
                ocids: [150000003431],
            }]
        }, {
            isAttributeSearch: true, label: 'monoterpenes',
            description: 'A search for "monoterpenes" will find chemical compounds which are classified as monoterpenes.',
            queryTerms: [{
                domains: ['chem'],
                queryValues: ['monoterpenes'],
                term: 'monoterpenes',
                type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS,
                ocids: [150000002773], //125000002299
            }]
        }]
    },
    {
        id: "probl",
        label: 'Problematic input',
        description: "May lead to errors, at least for certain search settings.",
        examples: [{
            isAttributeSearch: true, label: '57-27-2',
            description: 'Ambiguous input. The term "57-27-2" is a synonym of multiple chemical compounds with different structures. But non-exact searches can only be done with a unique structure.',
            queryTerms: [{
                domains: ['chem'],
                queryValues: ['57-27-2'],
                term: '57-27-2',
                type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
                ocids: [190005521192, 190003849732, 190006071787, 190000008452],
            }]
        }, {
            isAttributeSearch: true, label: 'alcohol',
            description: 'Compound and class. The term "alcohol" can represent a chemical compound as well as a chemical class. But both cannot be combined in one search.',
            queryTerms: [{
                domains: ['chem'],
                queryValues: ['alcohol'],
                term: 'alcohol',
                type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
                ocids: [190000014300],
            }, {
                domains: ['chem'],
                queryValues: ['alcohol'],
                term: 'alcohol',
                type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS,
                ocids: [150000001298],
            }]
        }]
    },
    {
        id: "struct",
        label: 'Structure',
        description: "Search by drawn compound structure.",
        examples: [{
            isAttributeSearch: false, label: 'caffeine',
            description: 'Search by "caffeine" structure.',
            structure: 'CN1C=NC2=C1C(=O)N(C)C(=O)N2C',
        }, {
            isAttributeSearch: false, label: 'n-benzylnaphthalene-2-carboxamide',
            description: 'Search by "n-benzylnaphthalene-2-carboxamide" structure.',
            structure: 'O=C(NCC1=CC=CC=C1)C1=CC2=C(C=CC=C2)C=C1',
        }]
    }
]