import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { isArrayEmpty } from "../../util";
import { fetchTermSuggestions } from "../../../../api/content/ConceptApi";
import { checkResultAndGetPayload } from "../../../../api";
import { InputText } from "primereact/inputtext";


class FilterSectionSearchField extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }


    autocomplete = (event) => {
        //console.log('event: ', event);
        setTimeout(async () => {
            // --- fetch term suggestions --- //
            const response = await fetchTermSuggestions(event.query, this.props.filterDomains, true, false, false);
            const terms = checkResultAndGetPayload(response, this.growl);
            //console.log('autocomplete terms: ', terms);

            if (terms) {
                // --- no match --- //
                if (isArrayEmpty(terms) || terms[0].term !== event.query) {
                    terms.unshift({
                        domains: ['t'],
                        label: event.query + " (Text with variants)",
                        ocids: null,
                        prefnames: [event.query],
                        term: event.query,
                    });
                }
            }

            this.setState({
                suggestions: terms
            });
        }, 200);
    }


    render() {

        const { filterID, input, placeholder, onFilterValuesChange, filterInfo } = this.props;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className="grid">
                    <div className="col">
                        <div key={filterID} style={{ marginLeft: 0 }}>
                            <InputText
                                value={input}
                                onChange={(e) => {
                                    const filterObject = e.target.value ? {
                                        filterID: filterID,
                                        input: e.target.value
                                    } : {};
                                    onFilterValuesChange(filterObject);
                                }}
                                placeholder={placeholder ? placeholder : 'Type away'}
                                style={{ width: '100%' }}>
                            </InputText>
                        </div>
                    </div>
                    {filterInfo ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {filterInfo}
                        </div> : null}
                </div>


            </>
        );
    }
}

export default FilterSectionSearchField;