/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button } from 'primereact/button';
//import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';

class AddBackend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abbreviation: '',
            isDefault: false,
            label: '',
            username: '',
            numberParallelConnections: '',
            certFolder: 'cert/webapi',
            password: '',
            name: '',
            port: '',
            type: null,
            url: '',
            updatePassword: false,
            useSSL: false
        }
    }

    componentDidMount() {
    }

    onAddBackend = () => {
        this.props.addBackend(this.state.abbreviation, this.state.isDefault, this.state.label, this.state.username,
            this.state.numberParallelConnections, this.state.certFolder, this.state.password, this.state.name, this.state.port, this.state.type, this.state.url, this.state.updatePassword, this.state.useSSL)
    }

    onUpdatePasswordChange = (e) => {
        if (e.checked) {
            this.setState({
                updatePassword: true
            })
        } else {
            this.setState({
                updatePassword: false
            })
        }
    }

    onUpdateSSLChange = (e) => {
        if (e.checked) {
            this.setState({
                useSSL: true
            })
        } else {
            this.setState({
                useSSL: false
            })
        }
    }

    render() {

        /*const footerAddBackend = (
            <div>
                <Button label="Cancel"
                    onClick={this.props.onCancelAddBackend}
                    className='p-button-sm p-button-secondary'
                />
                <Button label="Add"
                    onClick={this.onAddBackend}
                    className='p-button-sm primaryButton'
                />
            </div>
        )*/

        return (
            <Dialog visible={this.props.displayDialogAddBackend} style={{ 'width': "70vw" }} header="Add new backend" focusOnShow={false}
                modal={true} //blockScroll
                //footer={footerAddBackend}
                dismissableMask={false}
                onHide={() => this.props.onCancelAddBackend()} className='styledDialog'>
                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend label (for displaying)</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ label: e.target.value }) }} value={this.state.label} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend name</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend abbreviation</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ abbreviation: e.target.value }) }} value={this.state.abbreviation} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label># Parallel connections</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} keyfilter='pnum'
                            onChange={(e) => { this.setState({ numberParallelConnections: e.target.value }) }} value={this.state.numberParallelConnections} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Certificate folder</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ certFolder: e.target.value }) }} value={this.state.certFolder} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Username</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ username: e.target.value }) }} value={this.state.username} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Password</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ password: e.target.value }) }} disabled={this.state.updatePassword ? false : true} value={this.state.password} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Use password</label>
                        <div>
                            <Checkbox inputId='activateUserChecked'
                                onChange={(e) => this.onUpdatePasswordChange(e)}
                                checked={this.state.updatePassword ? true : false}
                                value='Check to use password' style={{ 'cursor': 'pointer', marginTop: 5, paddingTop: 5 }} />
                        </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Port</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} keyfilter='pnum'
                            onChange={(e) => { this.setState({ port: e.target.value }) }} value={this.state.port} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Type</label>
                        <Dropdown style={{ width: '100%', border: '1px solid #d6d6d6', marginTop: 5 }}
                            className='dropdownNoBorder'
                            appendTo={document.body}
                            value={this.state.type}
                            options={this.props.allBackendTypes}
                            onChange={(e) => this.setState({ type: e.value })}
                            placeholder='Select backend type' />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>URL</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ url: e.target.value }) }} value={this.state.url} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Is default</label>
                        <div>
                            <InputSwitch style={{
                                marginRight: 10, marginTop: 10 // , verticalAlign: 'sub' 
                            }} checked={this.state.isDefault}
                                onChange={(e) => this.setState({ isDefault: e.value })} />
                            <label>{this.state.isDefault ? 'Yes' : 'No'}</label>
                        </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Use SSL</label>
                        <div>
                            <Checkbox inputId='activateUserChecked'
                                onChange={(e) => this.onUpdateSSLChange(e)}
                                checked={this.state.useSSL ? true : false}
                                value='Check to use SSL' style={{ 'cursor': 'pointer', marginTop: 5, paddingTop: 5 }} />
                        </div>
                    </div>
                </div>
                <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                    <Button label="Add"
                        onClick={this.onAddBackend}
                        className='p-button-sm primaryButton'
                        style={{ marginRight: 5, float: 'right' }} />
                    <Button label="Cancel"
                        onClick={this.props.onCancelAddBackend}
                        className='p-button-sm p-button-secondary'
                        style={{ float: 'right', marginRight: 5 }} />
                </div>
            </Dialog>
        )
    }
}

export default AddBackend