import { isArrayEmpty } from "../../webapi/util";
import { ID_TYPE_DOI, ID_TYPE_PATENT_ID, ID_TYPE_PMCID, ID_TYPE_PMID } from "./ReadCubePapersLink";

export const getReadcubePapersIdentifier = (documentMetadata) => {

    const data = {
        data: null,
        identifier: null,
    };

    if (documentMetadata) {
        if (!isArrayEmpty(documentMetadata.doi)) {
            data.type = ID_TYPE_DOI;
            data.identifier = documentMetadata.doi[0];
        }
        else if (!isArrayEmpty(documentMetadata.pmc)) {
            data.type = ID_TYPE_PMCID;
            data.identifier = documentMetadata.pmc[0]
        }
        else if (!isArrayEmpty(documentMetadata.pmid)) {
            data.type = ID_TYPE_PMID;
            data.identifier = documentMetadata.pmid[0]
        }
        else if (!isArrayEmpty(documentMetadata["Pat_document-id"])) {
            data.type = ID_TYPE_PATENT_ID;
            data.identifier = documentMetadata["Pat_document-id"][0]
        }
    }

    return data;
}