// TODO: clean up, move to common folder and use for all pages

import "./PageHeader.css";
import { isArrayEmpty } from "../../../webapi/util";
import TutorialVideos from "../../../webapi/general/TutorialVideos";
import SeparatorPoint from "../../../webapi/general/SeparatorPoint";
import SearchExamples from "../../../webapi/general/searchexamples/SearchExamples";

const PageHeader = ({
  pageID,
  heading,
  infoText,
  tutorialVideos,
  examples,
  onSelectExample,
  isBetaVersion
}) => {
  return (
    <>
      <div className="grid page-header">
        <div className="col textAlignLeft">
          <h1>
            {heading}
            {isBetaVersion &&
              <span className='ml-3 text-base' style={{ color: 'red' }}>
                <strong>beta version</strong>
              </span>}
          </h1>

        </div>
        <div className="col-fixed textAlignRight">
          {!isArrayEmpty(tutorialVideos) && pageID && heading ? (
            <TutorialVideos
              pageTutorials={tutorialVideos}
              section={pageID}
              sectionLabel={heading}
            />
          ) : null}
        </div>
        <div className="col-12">
          <span className="page-header-info">{infoText}</span>
          {!isArrayEmpty(examples) ? (
            <span className="inline-flex align-items-center">
              <SeparatorPoint />
              <SearchExamples
                buttonLabel="Examples"
                examples={examples}
                onExampleClick={(example) => onSelectExample(example)}
              />
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PageHeader;
