import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { createAnnotatedDocumentRequest, createDocumentSearchRequest, fetchAnnotatedDocument, fetchDocuments } from "../../api/content/DocumentApi";
import { isObjectEmpty, sortMapByKeys } from "../webapi/util";

const QUERY = '+all:*';

const NUM_OF_DOCUMENTS = 25;

const RESULT_DOC = 'resDoc';
const ANN_DOC = 'annDoc';

const IGNORE_KEYS = {
    'abstract': true,
    'api': true,
    'annotationRanges': true,
    'annotationType': true,
    'conceptScores_relevanceScore': true,
    'convType-XML-topElement': true,
    'document': true,
    'documentFormat': true,
    'hasTextCoordinates': true,
    'hasAbstract': true,
    'hasFulltext': true,
    'hasTitle': true,
    'namedDocumentRanges': true,
    'queryAbstract': true,
    'queryString': true,
    'repository': true,
    'repositoryId': true,
}
const CHECK_SUB_KEYS = {
    'docMetadata': true,
}

const RepositoryMetadata = ({
    displayDialog,
    onToggleDialog,
    repository
}) => {

    const growl = useRef(null);

    const [metadata, setMetadata] = useState({});
    const [fetchingData, setFetchingData] = useState({});
    const [message, setMessage] = useState('');


    useEffect(() => {
        setMetadata({});
        setMessage('');

        if (repository) {
            fetchRepositoryMetadata(repository.id);
        }
    }, [repository]);


    const fetchRepositoryMetadata = async (repositoryID) => {

        const metadataAll = {};

        setFetchingData(true);
        setMetadata(metadataAll);

        const requestDoclist = createDocumentSearchRequest(repositoryID, QUERY);
        const responseDoclist = await fetchDocuments(0, NUM_OF_DOCUMENTS, requestDoclist);

        if (responseDoclist?.status === 'SUCCESS' && responseDoclist?.payload?.documents) {
            let resultDocs = responseDoclist.payload;

            for (let resultDoc of resultDocs.documents) {
                addExtractedMetadata(resultDoc, metadataAll, RESULT_DOC);

                const requestDocs = createAnnotatedDocumentRequest(repositoryID, resultDoc.ocDocId, null,
                    false, true, true, false, false, 0, false, false, null, null);
                const responseDocs = await fetchAnnotatedDocument(requestDocs);

                if (responseDocs?.status === 'SUCCESS' && responseDocs?.payload) {
                    const annDoc = responseDocs.payload.annotatedDocument;
                    addExtractedMetadata(annDoc, metadataAll, ANN_DOC);
                }
            }
        }
        else if (responseDoclist?.status === 'FAILED') {
            growl.current.show({ severity: 'error', summary: 'An error occurred', detail: responseDoclist.message, life: 6000 });
            setMessage(responseDoclist.message);
        }

        setFetchingData(false);
        setMetadata(sortMapByKeys(metadataAll));
    }

    const addExtractedMetadata = (documentData, metadataAll = {}, type) => {

        for (let key of Object.keys(documentData)) {
            if (!IGNORE_KEYS[key]) {
                if (CHECK_SUB_KEYS[key]) {
                    for (let key2 of Object.keys(documentData[key])) {
                        if (!IGNORE_KEYS[key2]) {
                            addMetadataEntry(metadataAll, key2, documentData[key][key2], type);
                        }
                    }
                }
                else {
                    addMetadataEntry(metadataAll, key, documentData[key], type);
                }
            }
        }
    }

    const addMetadataEntry = (metadata, key, value, type) => {

        if (!metadata[key]) {
            metadata[key] = {};
        }
        metadata[key][type] = JSON.stringify(value);
    }

    return <>
        <Toast ref={growl} />

        <Dialog
            visible={displayDialog}
            onHide={(e) => onToggleDialog(false)}
            header={`${repository?.label}`}
            style={{ minWidth: '35vw', maxWidth: '70vw', minHeight: '50vh', maxHeight: '80vh' }}
            blockScroll>

            {fetchingData ?
                <p>Fetching data...</p>
                :
                message ?
                    <p>{message}</p>
                    :
                    <>
                        <h3>
                            {repository?.label ? `We looked at ${NUM_OF_DOCUMENTS} documents and found the following metadata:` : 'No repo selected'}
                        </h3>

                        <p style={{ marginTop: 20 }}>
                            {!isObjectEmpty(metadata) ? <div className='grid'
                                style={{ lineHeight: 1.5, fontSize: 13 }}>

                                <div className='col-3'>Metadata</div>
                                <div className='col-1' title='Result document'>Result document</div>
                                <div className='col-1' title='Annotated document'>Annotated document</div>
                                <div className='col-7'>Example</div>

                                {Object.keys(metadata)?.map(md => {
                                    return <React.Fragment key={md}>
                                        <div className='breakRow' style={{ borderTop: '1px solid #ddd' }}></div>
                                        <div className='col-3'>{md}</div>
                                        <div className='col-1'>{metadata[md][RESULT_DOC] ? 'Yes' : '-'}</div>
                                        <div className='col-1'>{metadata[md][ANN_DOC] ? 'Yes' : '-'}</div>
                                        <div className='col-7'>
                                            {metadata[md][RESULT_DOC] ?
                                                <div>{metadata[md][RESULT_DOC]}</div>
                                                :
                                                metadata[md][ANN_DOC] ?
                                                    <div>{metadata[md][ANN_DOC]}</div> : null}
                                        </div>
                                    </React.Fragment>
                                })}
                            </div> : 'No metadata found'}
                        </p>
                    </>
            }
        </Dialog>
    </>
}

export default RepositoryMetadata;