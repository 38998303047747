import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import styles from './DocviewModal.module.scss';
import './DocviewModal.module.scss';
import './DocviewModal.css';
import { useEffect, useState } from 'react';
import { DocviewModalHeader } from '../DocviewModalHeader/DocviewModalHeader';
import DocumentViewContainer from '../../../container/DocumentViewContainer';
import { useDispatch } from 'react-redux';
import { setDocumentTitle } from '../../../../../redux/actions/QuickSearchActions';
import { createDocViewUrl } from '../../../util';

export const DocViewModal = ({
  isVisible,
  docId,
  selectedDoc,
  repId,
  documents = {},
  repositoryInfo = null,
  onCloseDocviewModal,
  onPageChange,
  first = 0,
  rows = 20,
  documentCount,
  isFunctionalCom = false,
  isSemanticSearch = false
}) => {
  const [ocDocId, setOcDocId] = useState('')
  const [selectedDocument, setSelectedDocument] = useState(selectedDoc)
  const [repositoryId, setRepositoryId] = useState('')
  const [headerDocuments, setHeaderDocuments] = useState([])
  const [pageOffset, setPageOffset] = useState(first)
  const [numberOfRow, setNumberOfRow] = useState(rows)

  const dispatch = useDispatch()

  useEffect(() => {
    setOcDocId(docId)
    setRepositoryId(repId)
    const temp = { ...selectedDoc, number: getDocumentNumber(docId, documents) }
    setSelectedDocument(temp)
    fetchMore(temp.number)
    setNumberOfRow(rows)
  }, [isVisible, docId])


  useEffect(() => {
    setHeaderDocuments(Object.values(documents).map(doc => (doc)).flat())
  }, [documents])


  useEffect(() => {
    setPageOffset(first)
  }, [first])


  const nextDocument = (docs, selectedDoc, isOverlay = false) => {
    const condition = isOverlay ? selectedDoc?.number : selectedDoc?.number + 1
    const doc = docs?.find((item) => item?.number === condition)

    fetchMore(doc.number)
    if (doc) {
      dispatch(setDocumentTitle({ terms: {} }))
      setDocumentDetails(doc)
    }
  };
  const fetchMore = (docNumber) => {
    if ((pageOffset + numberOfRow) - docNumber <= 5 && (pageOffset + numberOfRow) < documentCount) {
      const temp = pageOffset + numberOfRow;

      if (!checkDataExist(temp)) {
        setPageOffset(temp)
        isFunctionalCom ? onPageChange({ first: temp, rows: numberOfRow }, true)
          : onPageChange(temp, numberOfRow);
      }
    }
    if (docNumber - pageOffset <= 5 && pageOffset !== 0) {
      const temp = pageOffset - numberOfRow;

      if (!checkDataExist(temp)) {
        setPageOffset(temp)
        isFunctionalCom ? onPageChange({ first: temp, rows: numberOfRow }, true)
          : onPageChange(temp, numberOfRow);
      }
    }
  }

  const checkDataExist = (page) => {
    return documents[(page / numberOfRow) + 1]
  }

  const previousDocument = (docs, selectedDoc, isOverlay = false) => {
    const condition = isOverlay ? selectedDoc?.number : selectedDoc?.number - 1
    const doc = docs?.find((item) => item?.number === condition)

    fetchMore(doc.number)
    if (doc) {
      dispatch(setDocumentTitle({ terms: {} }))
      setDocumentDetails(doc)
    }

  };

  const getDocumentNumber = (docId, docs) => {
    const result = headerDocuments.length > 0 ? headerDocuments : docs[`${(first / rows) + 1}`]
    return result?.find((item) => (item.ocDocId || item.docId) === docId)?.number || 1
  }

  const setDocumentDetails = (doc) => {
    if (doc?.query)
      createDocViewUrl(doc.repositoryId, doc.ocDocId, { query: `text:"${doc.query}"` })

    setOcDocId(doc.ocDocId || doc.docId)
    setRepositoryId(doc.repositoryId)
    setSelectedDocument(doc)

  }


  const onModalClose = () => {
    dispatch(setDocumentTitle({ terms: {} }))
    onCloseDocviewModal()
  }

  return <div id='modal-wrapper'>
    <Dialog
      id='docview-modal'
      visible={isVisible}
      onHide={onModalClose}
      draggable={false}
      className={['docViewModal', styles.content]}
      maximized={true}
      header={
        <DocviewModalHeader
          nextDocument={nextDocument}
          previousDocument={previousDocument}
          selectedDocument={selectedDocument}
          elements={headerDocuments}
          // setDocumentDetails={setDocumentDetails}
          repositoryInfo={repositoryInfo}
          documentCount={documentCount}

        />
      }
      onClose={onModalClose}
      //blockScroll={true} // not needed, if maximum size is set (is default, if no size is set)
    >
      <DocumentViewContainer
        onHideOverlayDocview={onModalClose}
        matchs={{
          params: {
            repID: repositoryId,
            ocDocID: ocDocId,
            isSemanticSearch,
            doi: selectedDoc?.doi ?? []
          },
        }}
      />
    </Dialog>
  </div>
}


DocViewModal.propTypes = {
  docId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  repId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  renderDocumentOnly: PropTypes.bool,
  isVisible: PropTypes.bool,
  selectedDoc: PropTypes.object,
  repositoryInfo: PropTypes.object,
  documents: PropTypes.object,
  onPageChange: PropTypes.func,
  first: PropTypes.number,
  rows: PropTypes.number,
  documentCount: PropTypes.number,
  isFunctionalCom: PropTypes.bool,
  isSemanticSearch: PropTypes.bool
};
