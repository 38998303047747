import { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { isArrayEmpty, isObjectEmpty } from '../../../util';
import { createQueryTermForOcid } from '../../utils/chemistryQueryTerms';
import { addConceptsData } from '../../utils/chemistryResults';
import { convertAttributeSearchToOcids } from '../../utils/searchFormUtil';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import CustomPaginatorTemplateWithoutLastPage from '../../../../common/CustomPaginator/CustomPaginatorTemplateWithoutLastPage';
import CompoundDataView from '../compounds/CompoundDataView';


const INITIAL_STATE = {
    ocids: null,
    resultEntries: [],
    selectedCompoundOcids: {},
    start: 0,
    pageSize: 12
};

class AmbiguousInputDialog extends Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {

        if (!isArrayEmpty(this.props.queryTerms)) {
            this.initCompoundResults();
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.queryTerms !== this.props.queryTerms) {
            this.initCompoundResults();
        }
    }

    onPage = async (event) => {

        this.setState({ loading: true });

        const resultEntries = await this.fetchCompoundsData(this.state.ocids, this.state.resultEntries, event.first, event.rows);

        this.setState({
            start: event.first,
            pageSize: event.rows,
            resultEntries: resultEntries,
            loading: false
        });
    }

    fetchCompoundResults = async (queryTerms, start, pageSize) => {

        const ocids = await convertAttributeSearchToOcids(queryTerms);
        let resultEntries = [];
        if (ocids) {
            ocids.forEach(ocid => { resultEntries.push({ ocid: ocid }); });
            resultEntries = await this.fetchCompoundsData(ocids, resultEntries, start, pageSize);
        }
        return { ocids, resultEntries };
    }

    fetchCompoundsData = async (ocids, resultEntries, start, pageSize) => {

        if (ocids && resultEntries) {
            const visibleEntries = ocids ? await addConceptsData(ocids, start, start + pageSize, {}, {}, null) : [];
            let cnt = start + 1;
            visibleEntries.forEach(entry => entry.key = cnt++);
            resultEntries.splice(start, pageSize, ...visibleEntries);
            return resultEntries;
        }
        return [];
    }

    initCompoundResults = async () => {
        this.setState({ loading: true });

        const { ocids, resultEntries } = await this.fetchCompoundResults(this.props.queryTerms, this.state.start, this.state.pageSize);

        this.setState({
            ocids: ocids,
            resultEntries: resultEntries,
            loading: false,
            hide: false
        });
    }

    onSelectCompound = (ocid, checked, compound) => {
        if (checked) {
            this.setState({ selectedCompoundOcids: { [ocid]: {} } });
        }
        else {
            this.setState({ selectedCompoundOcids: {} });
        }
    }
    onReplaceQuery = (queryTerm, addToQuery, notifyQueryChanged, runSearch) => {
        this.onHide();
        this.props.onReplaceQuery(queryTerm, addToQuery, notifyQueryChanged, runSearch)
    }

    onReplaceStructure = (smiles, callback) => {
        this.onHide();
        this.props.onReplaceStructure(smiles, callback)
    }

    onHide = () => {
        this.setState({ ...INITIAL_STATE, hide: true });
        this.props.onToggleDialog(false);
    }

    render() {

        const { displayDialog, onExactCompoundSearch, onSelectedCompoundSearch, exactSearchWithMultipleStructuresAllowed,
            includePreparationsSearch, preparationsTarget, patentsRepository, publicationsRepository } = this.props;

        const footer = (
            <div style={{ marginTop: 15 }}>
                {/* <label style={{ float: 'left', paddingTop: 10 }}></label> */}
                <Button label="Run search with selected compound"
                    className='p-button-sm primaryButton'
                    onClick={() => {
                        this.onHide();
                        onSelectedCompoundSearch(createQueryTermForOcid(Object.keys(this.state.selectedCompoundOcids)[0]));
                    }}
                    disabled={isObjectEmpty(this.state.selectedCompoundOcids)}
                    style={{ float: 'right', marginRight: 0 }}
                />
                {exactSearchWithMultipleStructuresAllowed ?
                    <Button label="Run exact search with all compounds"
                        className='p-button-secondary p-button-sm'
                        onClick={() => {
                            this.onHide();
                            onExactCompoundSearch();
                        }}
                        style={{ float: 'right', marginRight: 10 }}
                    /> : null}
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.onHide}
                    style={{ float: 'right', marginRight: 10 }}
                />
            </div>
        )

        return (
            <Dialog
                focusOnShow={false}
                visible={displayDialog}
                style={{ height: '80vh', width: '80vw' }}
                header="Choose unique structure"
                footer={footer}
                modal={true}
                dismissableMask={true}
                onShow={() => {
                    this.setState({ ...INITIAL_STATE },
                        async () => this.initCompoundResults());
                }}
                onHide={this.onHide}
                className='styledDialog'
            >

                <div className="grid" style={{ padding: '10px 10px 10px 20px', width: '100%' }}>
                    <div className="col-12">
                        <LoadingOverlay
                            active={this.state.loading || this.state.hide}
                            spinner={true}
                            text='Loading compounds data.' >
                            <p>
                                <span className='valignMiddle'>
                                    It looks like your input matches multiple structures.
                                    {exactSearchWithMultipleStructuresAllowed ?
                                        "However, non-exact searches only work with a unique structure."
                                        :
                                        "However, this chemistry database only supports searches with a unique structure."
                                    }
                                    So please choose one of the compounds below to perform a search with your current settings
                                    {exactSearchWithMultipleStructuresAllowed ?
                                        "or run an exact compound search with all compounds."
                                        :
                                        "."
                                    }
                                </span>
                            </p>

                            {!this.state.loading && !this.state.hide ?
                                <CompoundDataView
                                    concepts={this.state.resultEntries}
                                    totalRecords={this.state.ocids?.length}
                                    start={this.state.start}
                                    pageSize={this.state.pageSize}
                                    isComplete={true}
                                    onPage={this.onPage}
                                    paginatorTemplate={CustomPaginatorTemplateWithoutLastPage}
                                    patentsRepository={patentsRepository}
                                    publicationsRepository={publicationsRepository}
                                    onReplaceStructure={this.onReplaceStructure}
                                    onReplaceQuery={this.onReplaceQuery}
                                    includePreparationsSearch={includePreparationsSearch}
                                    preparationsTarget={preparationsTarget}
                                    onSelectCompound={this.onSelectCompound}
                                    selectedCompoundOcids={this.state.selectedCompoundOcids}
                                    singleSelection={true}
                                /> : null
                            }
                        </LoadingOverlay>
                    </div>
                </div>

            </Dialog>
        );
    }
}
export default AmbiguousInputDialog;