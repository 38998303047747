import _ from "lodash";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { isArrayEmpty, addThousandsSeparatorToNumber } from "../../../util";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { FileUpload } from "primereact/fileupload";
import { OverlayPanel } from "primereact/overlaypanel";
import infoIcon from "../../../../../assets/images/icons/info/dimensions-icon-info.png";


const BulkImportSettings = ({
    inputEntries,
    entries,
    inputText,
    isSmiFile,
    selectedSeparator,
    autoRecSeparator,
    autoRecValueType,
    selectedValueType,
    separators,
    processInputText,
    valueTypes,
    hasSmiType,
    autoRecognizeValueType,
    extractEntries,
    onChangeInputEntries,
    onChangeSelectedSeparator,
    onChangeSelectedValueType,
    onChangeSmiFile,
    cnt,
    acceptedFileTypesString,
    maxValues,
    uploadHandler,
    fileUploadInfo
}) => {

    const toast = useRef(null);

    return <>
        <Toast ref={toast} />

        <div className="col-12">
            <>
                <div className="grid" style={{ marginTop: 0 }}>
                    <>
                        <div className="col-fixed">
                            <FileUpload
                                key={cnt}
                                name="upload"
                                className="p-button-secondary p-button-sm"
                                accept={acceptedFileTypesString}
                                customUpload={true}
                                uploadHandler={e => uploadHandler(e)}
                                mode="basic"
                                auto={true}
                                chooseLabel="Upload file" />
                        </div>
                        <div className="col-fixed" style={{ margin: 'auto', marginRight: 80 }}>
                            <a className="infoIconLink valignMiddle"
                                title="Click for information about file upload"
                                onClick={e => fileUploadInfo.toggle(e)}
                            >
                                <img src={infoIcon} alt="File upload information" />
                            </a>
                            <OverlayPanel
                                ref={(el) => fileUploadInfo = el}
                                appendTo={document.body}
                                style={{ maxWidth: '40vw', minWidth: 300, fontSize: 13 }}
                                className="whiteSpaceBreakSpaces"
                                dismissable={true}>
                                Supported file formats: {acceptedFileTypesString}
                                {maxValues ?
                                    <>
                                        <br />
                                        Max. number of values allowed: {addThousandsSeparatorToNumber(maxValues)}
                                    </>
                                    : null}
                            </OverlayPanel>
                        </div>

                        {inputText ?
                            <>
                                <div className={`col-fixed ${isSmiFile ? 'disableContent' : ''}`} style={{ marginRight: 0 }}>
                                    <span style={{ marginRight: 0 }}>
                                        Separator:
                                        {autoRecSeparator && !selectedSeparator ?
                                            <Dropdown
                                                id="separator"
                                                className="valignMiddle"
                                                style={{ marginLeft: 10 }}
                                                value={autoRecSeparator}
                                                options={separators}
                                                //optionValue="id"
                                                optionLabel="label"
                                                onChange={(e) => {
                                                    const entriesUpdated = processInputText ? extractEntries(inputText, e.value, selectedValueType) : entries;
                                                    // this.setState({
                                                    //     selectedSeparator: e.value,
                                                    //     inputEntries: entriesUpdated
                                                    // });
                                                    onChangeInputEntries(entriesUpdated);
                                                    onChangeSelectedSeparator(e.value);
                                                }}
                                                scrollHeight="400px"
                                                appendTo={document.body}
                                            />
                                            :
                                            <Dropdown
                                                id="separator"
                                                className="withClearIcon valignMiddle"
                                                style={{ marginLeft: 10 }}
                                                value={selectedSeparator}
                                                options={separators}
                                                //optionValue="id"
                                                optionLabel="label"
                                                onChange={(e) => {
                                                    if (e.value) {
                                                        const entriesUpdated = processInputText ? extractEntries(inputText, e.value, selectedValueType) : entries;
                                                        // this.setState({
                                                        //     selectedSeparator: e.value,
                                                        //     inputEntries: entriesUpdated
                                                        // });
                                                        onChangeInputEntries(entriesUpdated);
                                                        onChangeSelectedSeparator(e.value);
                                                    }
                                                    else {
                                                        const entriesUpdated = extractEntries(inputText, null, selectedValueType || autoRecValueType);
                                                        onChangeInputEntries(entriesUpdated);
                                                    }
                                                }}
                                                showClear={true}
                                                scrollHeight="400px"
                                                appendTo={document.body}
                                                placeholder="Select"
                                            />}
                                    </span>
                                </div>
                            </> : null}

                        {(inputText || !isArrayEmpty(inputEntries)) &&
                            <div className={`col-fixed ${isSmiFile ? 'disableContent' : ''}`}>
                                <span style={{ marginRight: 0 }}>
                                    Value type:
                                    {autoRecValueType && !selectedValueType ?
                                        <Dropdown
                                            id="contentType"
                                            className="valignMiddle"
                                            style={{ marginLeft: 10 }}
                                            value={autoRecValueType}
                                            options={valueTypes}
                                            //optionValue="id"
                                            optionLabel="label"
                                            onChange={(e) => onChangeSelectedValueType(e.value)}
                                            scrollHeight="400px"
                                            appendTo={document.body}
                                        />
                                        :
                                        <Dropdown
                                            id="contentType"
                                            className="withClearIcon valignMiddle"
                                            style={{ marginLeft: 10 }}
                                            value={selectedValueType}
                                            options={valueTypes}
                                            //optionValue="id"
                                            optionLabel="label"
                                            onChange={(e) => {
                                                if (e.value) {
                                                    onChangeSelectedValueType(e.value);
                                                }
                                                else {
                                                    autoRecognizeValueType(inputEntries);
                                                }
                                            }}
                                            showClear={true}
                                            scrollHeight="400px"
                                            appendTo={document.body}
                                            placeholder="Select"
                                        />}
                                </span>
                            </div>
                        }
                    </>

                    {(hasSmiType && inputText) ?
                        <div className="col" style={{ display: "flex" }}>
                            <Checkbox
                                id="isSmiFile"
                                onChange={(e) => onChangeSmiFile(e.checked)}
                                checked={isSmiFile}
                                style={{
                                    alignSelf: "center"
                                    //display: 'inline-block', paddingTop: 7, paddingBottom: 7 
                                }}
                            />
                            <label
                                htmlFor="isSmiFile"
                                className={`p-checkbox-label`}
                                style={{ alignSelf: "center" }}
                            //style={{ display: 'inline-block', paddingTop: 7, paddingBottom: 7 }}
                            >
                                Extract SMILES from SMI file
                            </label>
                        </div> : null}

                    <div className="col"></div>
                </div>
                <hr />
            </>
        </div>
    </>
}

export default BulkImportSettings;