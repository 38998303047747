import loginBGImage from '../../assets/images/bg/shutterstock_707879404_small.44d88a51.44d88a51_min.jpg';
import appLogo from '../../assets/images/logos/boehringer/BI.png';

export const APP_PROPERTIES = {
  // --- logos for login page and header --- //
  LOGIN_BG_IMAGE: loginBGImage,

  HEADER_LOGO_APP: appLogo,
  HEADER_LOGO_APP_STYLE: {
    height: 40,
    margin: '2px 5px',
    position: 'relative',
    top: -5,
  },

  SAML_URL:
    'https://animalhealth.imart.bi.ontochem.com:8443/webapp-animal-health/auth/login',

  // --- feedback and support --- //
  SUPPORT_FORM_URL:
    'https://forms.monday.com/forms/312f55bdb5829b7c7f342002021f19b7',

  // --- active pages --- //
  ACTIVE_PAGES: {
    docSearch: true,
    verificationPage: true,
    backendAdministration: true,
  },

  // --- active modules or functionalities --- //
  ACTIVE_FUNCTIONALITIES: {
    showDocumentsOnStart: true,
    fetchSortableFields: true,
    addSSOUser: true,
    showClinicalTrialFilters: true,
    showGrantsDSFilters: true,
    showPublicationsDSFilters: true,
  },

  // --- chemistry-related settings --- //
  CHEMISTRY: {
    showGoogleLinks: true,
  },
} as const;
