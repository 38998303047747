import ReferencedGroups from "../../../ReferencedGroups";
import SearchGroupCriteriaCompact from "../../SearchGroupCriteriaCompact";
import { isObjectEmpty } from "../../../../../../../webapi/util";
import { useMemo } from "react";

const LogicGroupCompact = ({ group, groups }) => {
  const searchCriteria = useMemo(
    () =>
      isObjectEmpty(group?.searchCriteria)
        ? null
        : Object.entries(group.searchCriteria),
    [group?.searchCriteria]
  );
  return (
    <div>
      {searchCriteria && (
        <SearchGroupCriteriaCompact searchCriteria={searchCriteria} />
      )}
      <ReferencedGroups
        groupReferences={group?.searchGroupIDRefs}
        groups={groups}
        operator={group.operator}
        showOperator
        sort
      />
    </div>
  );
};

export default LogicGroupCompact;
