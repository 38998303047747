/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { getHeaderToken } from '../../api/index'
import axios from 'axios'
import { Column } from 'primereact/column';
import dateFormat from 'dateformat';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import DeleteUsers from './DeleteUsers'
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import ToastContent from '../common/CustomToast/ToastContent';
import { hasUserRole } from '../../components/webapi/util';
import { APP_PROPERTIES } from '../../properties/index';

class GlobalUserSearch extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);
        this.state = {
            displayCustomToast: false,
            first: 0,
            rows: 20,
            userType: 'all',
            loginType: 'allTime',
            userTypeFilterActive: false,
            loginFilterActive: false,
            globalFilterUsers: '',
            selectedUsersTable: [],
            filteredUsers: []
        }
        this.columns = [
            { field: "lastName", header: "Name", style: {}, body: this.nameTableTemplate, sortable: true },
            { field: "forename", header: "Forename", style: { display: 'none' } },
            { field: "username", header: "Username", style: { display: 'none' } },
            { field: "email", header: "Email", body: this.emailTemplate, sortable: true },
            { field: "highestAdminType", header: 'Status', body: this.statusTemplate, sortable: true },
            { field: "created", header: 'Created', body: this.createdTemplate, sortable: true },
            { field: "lastLogin", header: 'Last login', body: this.loginTemplate, sortable: true },
            { field: "company.name", header: 'Org.', body: this.orgTemplate, sortable: true },
            { field: "department.name", header: 'Suborg.', body: this.suborgTemplate, sortable: true },
            { field: "", body: this.jumpTemplate, style: { width: 75 } },
            { field: "", body: this.editTemplate, style: { width: 80 } }
        ]
    }

    componentDidMount() {
    }

    nameTableTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Name</span>
            <span>
                <label style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'break-spaces'
                }} title={rowData.samlLogin === true ? `${rowData.lastName}, ${rowData.forename} (${rowData.username}, SSO)` :
                    `${rowData.lastName}, ${rowData.forename} (${rowData.username})`}>{rowData.samlLogin === true ?
                        `${rowData.lastName}, ${rowData.forename} (${rowData.username}, SSO)`
                        : `${rowData.lastName}, ${rowData.forename} (${rowData.username})`}</label>
            </span>
        </div>
    }

    emailTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Email</span>
            <a id="emailUser"
                className='aTagInference'
                title={`${rowData.email}`}
                style={{
                    display: 'block',
                    cursor: 'default',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{rowData.email}</a>
            <a id="emailUser"
                className="p-column-title"
                title={`${rowData.email}`}
                style={{
                    fontWeight: 'normal',
                    cursor: 'default',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{rowData.email}</a>
        </div>
    }

    loginTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Last login</span>
            {rowData.lastLogin ?
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`${dateFormat(new Date(rowData.lastLogin), 'yyyy-mm-dd')}`}>
                        {`${dateFormat(new Date(rowData.lastLogin), 'yyyy-mm-dd')}`
                        }</label>
                    <label
                        className="p-column-title"
                        style={{
                            fontWeight: 'normal',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`${dateFormat(new Date(rowData.lastLogin), 'yyyy-mm-dd')}`}>
                        {`${dateFormat(new Date(rowData.lastLogin), 'yyyy-mm-dd')}`
                        }</label>
                </React.Fragment>
                :
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`-`}>
                        {`-`
                        }</label>
                    <label
                        className="p-column-title"
                        style={{
                            fontWeight: 'normal',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`-`}>
                        {`-`
                        }</label>
                </React.Fragment>
            }
        </div>
    }

    createdTemplate = (rowData) => {
        if (rowData.created) {
            return <div>
                <span className="p-column-title">Created</span>
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`${dateFormat(new Date(rowData.created), 'yyyy-mm-dd')}`}>
                        {`${dateFormat(new Date(rowData.created), 'yyyy-mm-dd')}`
                        }</label>
                    <label
                        className="p-column-title"
                        style={{
                            fontWeight: 'normal',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`${dateFormat(new Date(rowData.created), 'yyyy-mm-dd')}`}>
                        {`${dateFormat(new Date(rowData.created), 'yyyy-mm-dd')}`
                        }</label>
                </React.Fragment>
            </div>
        } else {
            return <div>
                <span className="p-column-title">Created</span>
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`-`}>
                        {`-`
                        }</label>
                    <label
                        className="p-column-title"
                        style={{
                            fontWeight: 'normal',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`-`}>
                        {`-`
                        }</label>
                </React.Fragment>
            </div>
        }
    }

    suborgTemplate = (rowData) => {

        let depName = ''
        if (rowData.department.name === 'default') {
            depName = 'Unassigned / Inactive regular users'
        } else {
            depName = rowData.department.name
        }
        let activationState = ''
        activationState = rowData.department.activationState
        const activationStateStrg = `${depName} ${activationState === 'demo' ? ' (demo)' : activationState !== 'activated' ? ' (deactivated)' : ''}`;
        return <div>
            <span className="p-column-title">Suborganization</span>
            <span>
                <label title={activationStateStrg}>
                    {activationStateStrg}
                </label>
            </span>
        </div>
    }

    orgTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Organization</span>
            <span>
                <label title={rowData.company.deactivated === true ? `${rowData.company.name} (deactivated)` : `${rowData.company.name}`}>
                    {rowData.company.deactivated === true ? `${rowData.company.name} (deactivated)` : `${rowData.company.name}`}
                </label>
            </span>
        </div>
    }

    editTemplate = (rowData) => {
        return (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (rowData.username === this.props.userDetails.username) ||
            (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && rowData.highestAdminType !== 'ROLE_SUPER_ADMIN') ||
            (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && rowData.highestAdminType !== 'ROLE_SUPER_ADMIN' && rowData.highestAdminType !== 'ROLE_COMPANY_ADMIN') ||
            (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (rowData.highestAdminType !== 'ROLE_SUPER_ADMIN' &&
                rowData.highestAdminType !== 'ROLE_COMPANY_ADMIN' &&
                rowData.highestAdminType !== 'ROLE_DEPARTMENT_ADMIN'))
            ?
            <React.Fragment>
                <a id="emailUser"
                    className='aTagInference'
                    onClick={(e) => this.openEditUser(e, rowData)}
                    title='Edit'
                    style={{
                        fontWeight: 'normal',
                        float: 'right'
                    }}
                >Edit</a>
                <a id="emailUser"
                    className="p-column-title"
                    onClick={(e) => this.openEditUser(e, rowData)}
                    title='Edit'
                    style={{
                        fontWeight: 'normal',
                        textAlign: 'left'
                    }}>Edit</a>
            </React.Fragment>
            : <React.Fragment>
                <label
                    className='labelInference'
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'block'
                    }} title='Not editable'>Not editable</label>
                <label
                    className='p-column-title'
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: 'normal'
                    }} title='Not editable'>Not editable</label>
            </React.Fragment>

    }

    jumpTemplate = (rowData) => {
        return <React.Fragment>
            <a id="emailUser"
                className='aTagInference'
                onClick={(e) => this.openSelectUser(e, rowData)}
                title='Jump to'
                style={{
                    fontWeight: 'normal',
                    float: 'right'
                }}
            >Jump to</a>
            <a id="emailUser"
                className="p-column-title"
                onClick={(e) => this.openSelectUser(e, rowData)}
                title='Jump to'
                style={{
                    fontWeight: 'normal',
                    textAlign: 'left'
                }}>Jump to</a>
        </React.Fragment>
    }

    statusTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Status</span>
            {(rowData.highestAdminType === 'ROLE_SUPER_ADMIN' && (!rowData.deactivated && rowData.verified)) ?
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block'
                        }} title='Super admin'>{`Super admin`}</label>
                    <label
                        className='p-column-title'
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontWeight: 'normal'
                        }} title='Super admin'>{`Super admin`}</label>
                </React.Fragment>
                :
                (rowData.highestAdminType === 'ROLE_SUPPORT_ADMIN' && (!rowData.deactivated && rowData.verified)) ?
                    <React.Fragment>
                        <label
                            className='labelInference'
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block'
                            }} title='Support admin'>{`Support admin`}</label>
                        <label
                            className='p-column-title'
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal'
                            }} title='Support admin'>{`Support admin`}</label>
                    </React.Fragment>
                    :
                    (rowData.highestAdminType === 'ROLE_SUPER_ADMIN' && (!rowData.deactivated && !rowData.verified)) ? <div>
                        <label
                            className='labelInference'
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block'
                            }} title='Super admin (not verified by email)'>Super admin (not verified by email)</label>
                        <label
                            className='p-column-title'
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal'
                            }} title='Super admin (not verified by email)'>Super admin (not verified by email)</label>
                        {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN") &&
                            <div>
                                <a id="editUser"
                                    onClick={(e) => this.openResendVerification(e, rowData)}
                                    style={{ display: 'inline-block' }}>Resend email</a>
                                <a id="editUser"
                                    onClick={(e) => this.openVerifyUser(e, rowData)}
                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                            </div>
                        }
                    </div> :
                        (rowData.highestAdminType === 'ROLE_SUPPORT_ADMIN' && (!rowData.deactivated && !rowData.verified)) ? <div>
                            <label
                                className='labelInference'
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'block'
                                }} title='Support admin (not verified by email)'>Support admin (not verified by email)</label>
                            <label
                                className='p-column-title'
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'normal'
                                }} title='Support admin (not verified by email)'>Support admin (not verified by email)</label>
                            {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN") &&
                                <div>
                                    <a id="editUser"
                                        onClick={(e) => this.openResendVerification(e, rowData)}
                                        style={{ display: 'inline-block' }}>Resend email</a>
                                    <a id="editUser"
                                        onClick={(e) => this.openVerifyUser(e, rowData)}
                                        style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                </div>
                            }
                        </div> :
                            (rowData.highestAdminType === 'ROLE_SUPER_ADMIN' && (rowData.deactivated && !rowData.verified)) ?
                                <React.Fragment>
                                    <label
                                        className='labelInference'
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            display: 'block'
                                        }} title='Super admin (deactivated, not verified by email)'>{`Super admin (deactivated, not verified by email)`}</label>
                                    <label
                                        className='p-column-title'
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontWeight: 'normal'
                                        }} title='Super admin (deactivated, not verified by email)'>{`Super admin (deactivated, not verified by email)`}</label>
                                </React.Fragment>
                                :
                                (rowData.highestAdminType === 'ROLE_SUPPORT_ADMIN' && (rowData.deactivated && !rowData.verified)) ?
                                    <React.Fragment>
                                        <label
                                            className='labelInference'
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }} title='Support admin (deactivated, not verified by email)'>{`Support admin (deactivated, not verified by email)`}</label>
                                        <label
                                            className='p-column-title'
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                fontWeight: 'normal'
                                            }} title='Support admin (deactivated, not verified by email)'>{`Support admin (deactivated, not verified by email)`}</label>
                                    </React.Fragment>
                                    :
                                    (rowData.highestAdminType === 'ROLE_SUPER_ADMIN' && (rowData.deactivated && rowData.verified)) ?
                                        <React.Fragment>
                                            <label
                                                className='labelInference'
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    display: 'block'
                                                }} title='Super admin (deactivated)'>{`Super admin (deactivated)`}
                                            </label>
                                            <label
                                                className='p-column-title'
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 'normal'
                                                }} title='Super admin (deactivated)'>{`Super admin (deactivated)`}
                                            </label>
                                        </React.Fragment> :
                                        (rowData.highestAdminType === 'ROLE_SUPPORT_ADMIN' && (rowData.deactivated && rowData.verified)) ?
                                            <React.Fragment>
                                                <label
                                                    className='labelInference'
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        display: 'block'
                                                    }} title='Support admin (deactivated)'>{`Support admin (deactivated)`}
                                                </label>
                                                <label
                                                    className='p-column-title'
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontWeight: 'normal'
                                                    }} title='Support admin (deactivated)'>{`Support admin (deactivated)`}
                                                </label>
                                            </React.Fragment> :
                                            (rowData.highestAdminType === 'ROLE_COMPANY_ADMIN' && (!rowData.deactivated && rowData.verified)) ?
                                                <React.Fragment>
                                                    <label
                                                        className='labelInference'
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            display: 'block'
                                                        }} title='Organization admin'>{`Organization admin`}
                                                    </label>
                                                    <label
                                                        className='p-column-title'
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: 'normal'
                                                        }} title='Organization admin'>{`Organization admin`}
                                                    </label>
                                                </React.Fragment> :
                                                (rowData.highestAdminType === 'ROLE_COMPANY_ADMIN' && (!rowData.deactivated && !rowData.verified)) ? <div>
                                                    <label
                                                        className='labelInference'
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            display: 'block'
                                                        }} title='Organization admin (not verified by email)'>Organization admin (not verified by email)</label>
                                                    <label
                                                        className='p-column-title'
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: 'normal'
                                                        }} title='Organization admin (not verified by email)'>Organization admin (not verified by email)</label>
                                                    {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" || this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN") ?
                                                        <div style={{ marginTop: 5 }}>
                                                            <a id="editUser"
                                                                onClick={(e) => this.openResendVerification(e, rowData)}
                                                                style={{ display: 'inline-block' }}>Resend email</a>
                                                            {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                <a id="editUser"
                                                                    onClick={(e) => this.openVerifyUser(e, rowData)}
                                                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                            }
                                                        </div>
                                                        : null
                                                    }
                                                </div> :
                                                    (rowData.highestAdminType === 'ROLE_COMPANY_ADMIN' && (rowData.deactivated && !rowData.verified)) ?
                                                        <React.Fragment>
                                                            <label
                                                                className='labelInference'
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    display: 'block'
                                                                }} title='Organization admin (deactivated, not verified by email)'>{`Organization admin (deactivated, not verified by email)`}
                                                            </label>
                                                            <label
                                                                className='p-column-title'
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    fontWeight: 'normal'
                                                                }} title='Organization admin (deactivated, not verified by email)'>{`Organization admin (deactivated, not verified by email)`}
                                                            </label>
                                                        </React.Fragment>
                                                        :
                                                        (rowData.highestAdminType === 'ROLE_COMPANY_ADMIN' && (rowData.deactivated && rowData.verified)) ?
                                                            <React.Fragment>
                                                                <label
                                                                    className='labelInference'
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        display: 'block'
                                                                    }} title='Organization admin (deactivated)'>{`Organization admin (deactivated)`}
                                                                </label>
                                                                <label
                                                                    className='p-column-title'
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        fontWeight: 'normal'
                                                                    }} title='Organization admin (deactivated)'>{`Organization admin (deactivated)`}
                                                                </label>
                                                            </React.Fragment>
                                                            :
                                                            (rowData.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && (!rowData.deactivated && rowData.verified)) ? `Suborganization admin` :
                                                                (rowData.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && (!rowData.deactivated && !rowData.verified)) ? <div>
                                                                    <label
                                                                        className='labelInference'
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            display: 'block'
                                                                        }} title='Suborganization admin (not verified by email)'>Suborganization admin (not verified by email)
                                                                    </label>
                                                                    <label
                                                                        className='p-column-title'
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            fontWeight: 'normal'
                                                                        }} title='Suborganization admin (not verified by email)'>Suborganization admin (not verified by email)
                                                                    </label>
                                                                    {(this.props.userDetails.highestAdminType !== "NONE") &&
                                                                        <div style={{ marginTop: 5 }}>
                                                                            <a id="editUser"
                                                                                onClick={(e) => this.openResendVerification(e, rowData)}
                                                                                style={{ display: 'inline-block' }}>Resend email</a>
                                                                            {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                                <a id="editUser"
                                                                                    onClick={(e) => this.openVerifyUser(e, rowData)}
                                                                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div> :
                                                                    (rowData.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && (rowData.deactivated && !rowData.verified)) ?
                                                                        <React.Fragment>
                                                                            <label
                                                                                className='labelInference'
                                                                                style={{
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    display: 'block'
                                                                                }} title='Suborganization admin (deactivated, not verified by email)'>
                                                                                {`Suborganization admin (deactivated, not verified by email)`}
                                                                            </label>
                                                                            <label
                                                                                className='p-column-title'
                                                                                style={{
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    fontWeight: 'normal'
                                                                                }} title='Suborganization admin (deactivated, not verified by email)'>
                                                                                {`Suborganization admin (deactivated, not verified by email)`}
                                                                            </label>
                                                                        </React.Fragment>
                                                                        :
                                                                        (rowData.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && (rowData.deactivated && rowData.verified)) ?
                                                                            <React.Fragment>
                                                                                <label
                                                                                    className='labelInference'
                                                                                    style={{
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        whiteSpace: 'nowrap',
                                                                                        display: 'block'
                                                                                    }} title='Suborganization admin (deactivated)'>{`Suborganization admin (deactivated)`}
                                                                                </label>
                                                                                <label
                                                                                    className='p-column-title'
                                                                                    style={{
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        whiteSpace: 'nowrap',
                                                                                        fontWeight: 'normal'
                                                                                    }} title='Suborganization admin (deactivated)'>{`Suborganization admin (deactivated)`}
                                                                                </label>
                                                                            </React.Fragment>
                                                                            :
                                                                            (rowData.highestAdminType === 'NONE' && (!rowData.deactivated && rowData.verified)) ?
                                                                                <React.Fragment>
                                                                                    <label
                                                                                        className='labelInference'
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            display: 'block'
                                                                                        }} title='User'>{`User`}
                                                                                    </label>
                                                                                    <label
                                                                                        className='p-column-title'
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            fontWeight: 'normal'
                                                                                        }} title='User'>{`User`}
                                                                                    </label>
                                                                                </React.Fragment>
                                                                                :
                                                                                (rowData.highestAdminType === 'NONE' && (!rowData.deactivated && !rowData.verified)) ? <div>
                                                                                    <label
                                                                                        className='labelInference'
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            display: 'block'
                                                                                        }} title='User (not verified by email)'>User (not verified by email)</label>
                                                                                    <label
                                                                                        className='p-column-title'
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            fontWeight: 'normal'
                                                                                        }} title='User (not verified by email)'>User (not verified by email)</label>
                                                                                    {(this.props.userDetails.highestAdminType !== "NONE") &&
                                                                                        <div style={{ marginTop: 5 }}>
                                                                                            <a id="editUser"
                                                                                                onClick={(e) => this.openResendVerification(e, rowData)}
                                                                                                style={{ display: 'inline-block' }}>Resend email</a>
                                                                                            {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                                                <a id="editUser"
                                                                                                    onClick={(e) => this.openVerifyUser(e, rowData)}
                                                                                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div> :
                                                                                    (rowData.highestAdminType === 'NONE' && (rowData.deactivated && !rowData.verified)) ?
                                                                                        <React.Fragment>
                                                                                            <label
                                                                                                className='labelInference'
                                                                                                style={{
                                                                                                    overflow: 'hidden',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                    display: 'block'
                                                                                                }} title='User (deactivated, not verified by email)'>{`User (deactivated, not verified by email)`}
                                                                                            </label>
                                                                                            <label
                                                                                                className='p-column-title'
                                                                                                style={{
                                                                                                    overflow: 'hidden',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                    fontWeight: 'normal'
                                                                                                }} title='User (deactivated, not verified by email)'>{`User (deactivated, not verified by email)`}
                                                                                            </label>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (rowData.highestAdminType === 'NONE' && (rowData.deactivated && rowData.verified)) ?
                                                                                            <React.Fragment>
                                                                                                <label
                                                                                                    className='labelInference'
                                                                                                    style={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        display: 'block'
                                                                                                    }} title='User (deactivated)'>{`User (deactivated)`}
                                                                                                </label>
                                                                                                <label
                                                                                                    className='p-column-title'
                                                                                                    style={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        fontWeight: 'normal'
                                                                                                    }} title='User (deactivated)'>{`User (deactivated)`}
                                                                                                </label>
                                                                                            </React.Fragment>
                                                                                            : null
            }
        </div>
    }

    handleGlobalFilterChange = (filter) => {
        this.setState({
            globalFilterUsers: filter
        })
    }

    onTypeChange = (userType) => {
        this.setState({
            userType: userType,
            globalFilterUsers: ''
        })
    }

    onFilterUserTypeClick = () => {
        if (this.state.userTypeFilterActive) {
            this.setState({
                userTypeFilterActive: false,
                globalFilterUsers: ''
            })
        } else {
            this.setState({
                userTypeFilterActive: true,
                globalFilterUsers: ''
            })
        }
    }

    onFilterLoginClick = () => {
        if (this.state.loginFilterActive) {
            this.setState({
                loginFilterActive: false,
                globalFilterUsers: ''
            })
        } else {
            this.setState({
                loginFilterActive: true,
                globalFilterUsers: ''
            })
        }
    }

    onLoginChange = (loginType) => {
        this.setState({
            loginType: loginType,
            globalFilterUsers: ''
        })
    }

    openVerifyUser = (e, user) => {
        this.props.openVerifyUser(e, user)
    }

    openResendVerification = (e, user) => {
        this.props.openResendVerification(e, user)
    }

    openSelectUser = (e, user) => {
        this.props.openSelectUser(user)
    }

    openEditUser = (e, user) => {
        this.props.openEditUser(e, user, user.department.name)
    }

    openDeleteUsersGlobal = () => {
        this.setState({
            displayDeleteUsers: true
        })
    }

    onCancelDeleteUsers = () => {
        this.setState({
            displayDeleteUsers: false
        })
    }

    onDeleteUsers = async () => {
        let userIds = ''
        this.state.selectedUserDetails.forEach(key => {
            userIds += key.id + ','
        })
        userIds = userIds.slice(0, -1)
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/users/${userIds}`, { headers: getHeaderToken() })
            .then(function (response) {
                self.toastBC.current.clear()
                self.showDeleteUsersSuccessGrowl()
                self.setState({
                    displayDeleteUsers: false,
                    selectedUsers: [],
                    selectedUserDetails: [],
                    allUsersSelected: false,
                    displayCustomToast: false
                }, () =>
                    self.props.getGlobalUsers()
                )
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showDeleteUsersFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showDeleteUsersFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    showDeleteUsersSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Users successfully deleted.` };
        this.growl.show(msg)
    }

    showDeleteUsersFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    openMoveUsersGlobal = () => {
        this.props.openMoveUsersGlobal(this.state.selectedUserDetails)
    }

    openActivateUsersGlobal = () => {
        this.props.openActivateUsersGlobal(this.state.selectedUserDetails)
    }
    openInactivateUsersGlobal = () => {
        this.props.openInactivateUsersGlobal(this.state.selectedUserDetails)
    }

    onValueChange = (filteredData) => {
        this.setState({
            filteredUsers: filteredData
        }, () => {
            let filteredAllowedUsers = []

            this.state.filteredUsers && this.state.filteredUsers.forEach(user => {
                if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.username === this.props.userDetails.username) ||
                    (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.highestAdminType !== 'ROLE_SUPER_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.highestAdminType !== 'ROLE_SUPER_ADMIN' && user.highestAdminType !== 'ROLE_COMPANY_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.highestAdminType !== 'ROLE_SUPER_ADMIN' &&
                        user.highestAdminType !== 'ROLE_COMPANY_ADMIN' &&
                        user.highestAdminType !== 'ROLE_DEPARTMENT_ADMIN'))) {
                    filteredAllowedUsers = [...filteredAllowedUsers, user]
                }
            })

            if (this.state.selectedUsersTable.length < filteredAllowedUsers.length) {
                this.setState({
                    allUsersSelected: false
                })
            } else if (this.state.selectedUsersTable.length === filteredAllowedUsers.length && filteredAllowedUsers.length !== 0) {
                this.setState({
                    allUsersSelected: true
                })
            }
        })
    }

    checkUserTemplate = (rowData) => {
        return <div key={rowData.username}>
            <span className="p-column-title">Select user</span>
            <span>
                <Checkbox
                    key={rowData.username}
                    disabled={(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (rowData.username === this.props.userDetails.username) ||
                        (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && rowData.highestAdminType !== 'ROLE_SUPER_ADMIN') ||
                        (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && rowData.highestAdminType !== 'ROLE_SUPER_ADMIN' && rowData.highestAdminType !== 'ROLE_COMPANY_ADMIN') ||
                        (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (rowData.highestAdminType !== 'ROLE_SUPER_ADMIN' &&
                            rowData.highestAdminType !== 'ROLE_COMPANY_ADMIN' &&
                            rowData.highestAdminType !== 'ROLE_DEPARTMENT_ADMIN'))
                        ? false : true}
                    style={{
                        cursor: (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (rowData.username === this.props.userDetails.username) ||
                            (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && rowData.highestAdminType !== 'ROLE_SUPER_ADMIN') ||
                            (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && rowData.highestAdminType !== 'ROLE_SUPER_ADMIN' && rowData.highestAdminType !== 'ROLE_COMPANY_ADMIN') ||
                            (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (rowData.highestAdminType !== 'ROLE_SUPER_ADMIN' &&
                                rowData.highestAdminType !== 'ROLE_COMPANY_ADMIN' &&
                                rowData.highestAdminType !== 'ROLE_DEPARTMENT_ADMIN'))
                            ? 'pointer' : 'default'
                    }}
                    onChange={(e) => this.handleSelectionUsersTable(e, rowData)}
                    checked={this.state.selectedUsersTable.includes(rowData.username)}>
                </Checkbox>
            </span>
        </div>
    }

    handleSelectionUsersTable = (e, userData) => {
        let userDetails = []

        let possibleUsers = []

        this.allUsers && this.allUsers.forEach(user => {
            userDetails = [...userDetails, { userName: user.username, foreName: user.forename, lastName: user.lastName, id: user.id }]
            if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.username === this.props.userDetails.username) ||
                (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.highestAdminType !== 'ROLE_SUPER_ADMIN') ||
                (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.highestAdminType !== 'ROLE_SUPER_ADMIN' && user.highestAdminType !== 'ROLE_COMPANY_ADMIN') ||
                (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.highestAdminType !== 'ROLE_SUPER_ADMIN' &&
                    user.highestAdminType !== 'ROLE_COMPANY_ADMIN' &&
                    user.highestAdminType !== 'ROLE_DEPARTMENT_ADMIN'))) {
                possibleUsers = [...possibleUsers, user]
            }
        })

        let selectedUsersTable = [...this.state.selectedUsersTable];

        if (e.checked)
            selectedUsersTable.push(userData.username);
        else
            selectedUsersTable.splice(selectedUsersTable.indexOf(userData.username), 1);


        let userDetailsList = []
        for (let i = 0; i < selectedUsersTable.length; i++) {
            for (let j = 0; j < userDetails.length; j++) {
                if (userDetails[j].userName === selectedUsersTable[i]) {
                    userDetailsList = [...userDetailsList, userDetails[j]]
                }
            }
        }

        if (userDetailsList.length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (userDetailsList.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }

        this.setState({
            selectedUsersTable: selectedUsersTable,
            selectedUserDetails: userDetailsList,
            allUsersSelected: possibleUsers.length === userDetailsList.length ? true : false,
            highlightedUser: undefined
        })
    }

    rejectSelectedUsers = () => {
        this.toastBC.current.clear()
        this.setState({
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            allUsersSelected: false,
            displayCustomToast: false
        })
    }

    handleSelectAllUsers = (e) => {
        if (e.checked) {
            let userDetails = []

            if (this.state.filteredUsers && this.state.filteredUsers.length > 0 && this.state.globalFilterUsers !== "") {
                this.state.filteredUsers && this.state.filteredUsers.forEach(user => {
                    userDetails = [...userDetails, { userName: user.username, foreName: user.forename, lastName: user.lastName, id: user.id, highestAdminType: user.highestAdminType }]
                })
            } else {
                this.allUsers && this.allUsers.forEach(user => {
                    userDetails = [...userDetails, { userName: user.username, foreName: user.forename, lastName: user.lastName, id: user.id, highestAdminType: user.highestAdminType }]
                })
            }

            let selectedUsersTable = [];

            userDetails.forEach(user => {
                if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.userName === this.props.userDetails.username) ||
                    (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.highestAdminType !== 'ROLE_SUPER_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.highestAdminType !== 'ROLE_SUPER_ADMIN' && user.highestAdminType !== 'ROLE_COMPANY_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.highestAdminType !== 'ROLE_SUPER_ADMIN' &&
                        user.highestAdminType !== 'ROLE_COMPANY_ADMIN' &&
                        user.highestAdminType !== 'ROLE_DEPARTMENT_ADMIN'))) {
                    selectedUsersTable.push(user.userName)
                }
            })

            let userDetailsList = []
            for (let i = 0; i < selectedUsersTable.length; i++) {
                for (let j = 0; j < userDetails.length; j++) {
                    if (userDetails[j].userName === selectedUsersTable[i]) {
                        userDetailsList = [...userDetailsList, userDetails[j]]
                    }
                }
            }

            if (userDetailsList.length === 0) {
                this.toastBC.current.clear();
                this.setState({
                    displayCustomToast: false
                })
            } else if (userDetailsList.length !== 0) {
                this.setState({
                    displayCustomToast: true
                })
            }

            this.setState({
                allUsersSelected: true,
                selectedUsersTable: selectedUsersTable,
                selectedUserDetails: userDetailsList,
                highlightedUser: undefined
            })
        } else {
            this.toastBC.current.clear();
            this.setState({
                allUsersSelected: false,
                selectedUserDetails: [],
                selectedUsersTable: [],
                highlightedUser: undefined,
                displayCustomToast: false
            })
        }
    }

    onCancelGlobalUserSearch = () => {
        this.setState({
            displayCustomToast: false,
            first: 0,
            rows: 20,
            userType: 'all',
            loginType: 'allTime',
            userTypeFilterActive: false,
            loginFilterActive: false,
            globalFilterUsers: '',
            selectedUsersTable: [],
            filteredUsers: []
        }, () => {
            this.props.onCancelGlobalUserSearch()
        })
    }

    render() {


        this.allUsers = this.props.allGlobalUsers

        const TODAY = new Date();
        const LAST_YEAR = new Date(TODAY.getFullYear() - 1, TODAY.getMonth(), TODAY.getDate());
        const LAST_WEEK = new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() - 7);
        const LAST_MONTH = new Date(TODAY.getFullYear(), TODAY.getMonth() - 1, TODAY.getDate());

        if (this.state.userType !== 'all' &&
            this.state.userType === 'super') {
            this.allUsers = this.allUsers.filter(obj => {
                return obj.highestAdminType === 'ROLE_SUPER_ADMIN'
            })
        } else if (this.state.userType !== 'all' &&
            this.state.userType === 'support') {
            this.allUsers = this.allUsers.filter(obj => {
                return obj.highestAdminType === 'ROLE_SUPPORT_ADMIN'
            })
        } else if (this.state.userType !== 'all' &&
            this.state.userType === 'org') {
            this.allUsers = this.allUsers.filter(obj => {
                return obj.highestAdminType === 'ROLE_COMPANY_ADMIN'
            })
        } else if (this.state.userType !== 'all' &&
            this.state.userType === 'suborg') {
            this.allUsers = this.allUsers.filter(obj => {
                return obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN'
            })
        } else if (this.state.userType !== 'all' &&
            this.state.userType === 'user') {
            this.allUsers = this.allUsers.filter(obj => {
                return obj.highestAdminType === 'NONE'
            })
        }

        if (this.state.loginType !== 'allTime' &&
            this.state.loginType === 'week') {
            this.allUsers = this.allUsers.filter(obj => {
                return obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK
            })
        } else if (this.state.loginType !== 'allTime' &&
            this.state.loginType === 'month') {
            this.allUsers = this.allUsers.filter(obj => {
                return obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH
            })
        } else if (this.state.loginType !== 'allTime' &&
            this.state.loginType === 'year') {
            this.allUsers = this.allUsers.filter(obj => {
                return obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR
            })
        } else if (this.state.loginType !== 'allTime' &&
            this.state.loginType === 'never') {
            this.allUsers = this.allUsers.filter(obj => {
                return !obj.lastLogin
            })
        }

        let numberSuperAdmins = this.state.loginType !== 'allTime' && this.state.loginType === 'month' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPER_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'week' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPER_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'year' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPER_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'never' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPER_ADMIN' && !obj.lastLogin
        }) : this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPER_ADMIN'
        })

        let numberSupportAdmins = this.state.loginType !== 'allTime' && this.state.loginType === 'month' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPPORT_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'week' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPPORT_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'year' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPPORT_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'never' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPPORT_ADMIN' && !obj.lastLogin
        }) : this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_SUPPORT_ADMIN'
        })

        let numberOrgAdmins = this.state.loginType !== 'allTime' && this.state.loginType === 'month' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_COMPANY_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'week' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_COMPANY_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'year' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_COMPANY_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'never' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_COMPANY_ADMIN' && !obj.lastLogin
        }) : this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_COMPANY_ADMIN'
        })

        let numberDepAdmins = this.state.loginType !== 'allTime' && this.state.loginType === 'month' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'week' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'year' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'never' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && !obj.lastLogin
        }) : this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN'
        })


        let numberRegularUser = this.state.loginType !== 'allTime' && this.state.loginType === 'month' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'NONE' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'week' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'NONE' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'year' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'NONE' && obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR
        }) : this.state.loginType !== 'allTime' && this.state.loginType === 'never' ? this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'NONE' && !obj.lastLogin
        }) : this.props.allGlobalUsers.filter(obj => {
            return obj.highestAdminType === 'NONE'
        })

        let numberAllUserTypes = this.state.loginType !== 'allTime' ?
            numberSuperAdmins.length + numberSupportAdmins.length + numberOrgAdmins.length + numberDepAdmins.length + numberRegularUser.length
            : this.props.allGlobalUsers.length

        let numberLoggedInMonth = this.state.userType !== 'all' && this.state.userType === 'super' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH && obj.highestAdminType === 'ROLE_SUPER_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'support' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH && obj.highestAdminType === 'ROLE_SUPPORT_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'org' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH && obj.highestAdminType === 'ROLE_COMPANY_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'suborg' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH && obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'user' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH && obj.highestAdminType === 'NONE'
        }) : this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_MONTH
        })

        let numberLoggedInWeek = this.state.userType !== 'all' && this.state.userType === 'super' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK && obj.highestAdminType === 'ROLE_SUPER_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'support' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK && obj.highestAdminType === 'ROLE_SUPPORT_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'org' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK && obj.highestAdminType === 'ROLE_COMPANY_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'suborg' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK && obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'user' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK && obj.highestAdminType === 'NONE'
        }) : this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_WEEK
        })

        let numberLoggedInYear = this.state.userType !== 'all' && this.state.userType === 'super' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR && obj.highestAdminType === 'ROLE_SUPER_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'support' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR && obj.highestAdminType === 'ROLE_SUPPORT_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'org' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR && obj.highestAdminType === 'ROLE_COMPANY_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'suborg' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR && obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'user' ? this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR && obj.highestAdminType === 'NONE'
        }) : this.props.allGlobalUsers.filter(obj => {
            return obj.lastLogin && new Date(obj.lastLogin) >= LAST_YEAR
        })

        let numberLoggedInNever = this.state.userType !== 'all' && this.state.userType === 'super' ? this.props.allGlobalUsers.filter(obj => {
            return !obj.lastLogin && obj.highestAdminType === 'ROLE_SUPER_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'support' ? this.props.allGlobalUsers.filter(obj => {
            return !obj.lastLogin && obj.highestAdminType === 'ROLE_SUPPORT_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'org' ? this.props.allGlobalUsers.filter(obj => {
            return !obj.lastLogin && obj.highestAdminType === 'ROLE_COMPANY_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'suborg' ? this.props.allGlobalUsers.filter(obj => {
            return !obj.lastLogin && obj.highestAdminType === 'ROLE_DEPARTMENT_ADMIN'
        }) : this.state.userType !== 'all' && this.state.userType === 'user' ? this.props.allGlobalUsers.filter(obj => {
            return !obj.lastLogin && obj.highestAdminType === 'NONE'
        }) : this.props.allGlobalUsers.filter(obj => {
            return !obj.lastLogin
        })

        let numberAllLogins = this.state.userType !== 'all' ?
            Math.max(numberLoggedInMonth.length, numberLoggedInWeek.length, numberLoggedInYear.length) + numberLoggedInNever.length
            : this.props.allGlobalUsers.length



        let adminTypes = [
            { label: `All user types`, value: 'all' },
            { label: `Super Admins`, value: 'super' },
            { label: `Support Admins`, value: 'support' },
            { label: `Org. Admins`, value: 'org' },
            { label: `Suborg. Admins`, value: 'suborg' },
            { label: `Users`, value: 'user' }
        ]

        let logins = [
            { label: `No time restriction`, value: 'allTime' },
            { label: 'Logged in last week', value: 'week' },
            { label: 'Logged in last month', value: 'month' },
            { label: 'Logged in last year', value: 'year' },
            { label: 'Never logged in', value: 'never' }
        ]

        const adminTypeTemplate = (option) => {
            if (option.value === 'super') {
                return <div>{`${option.label} (${numberSuperAdmins.length})`}</div>
            } else if (option.value === 'support') {
                return <div>{`${option.label} (${numberSupportAdmins.length})`}</div>
            } else if (option.value === 'org') {
                return <div>{`${option.label} (${numberOrgAdmins.length})`}</div>
            } else if (option.value === 'suborg') {
                return <div>{`${option.label} (${numberDepAdmins.length})`}</div>
            } else if (option.value === 'user') {
                return <div>{`${option.label} (${numberRegularUser.length})`}</div>
            } else if (option.value === 'all') {
                return <div>{`${option.label} (${numberAllUserTypes})`}</div>
            }
        }

        const loginTimeTemplate = (option) => {
            if (option.value === 'week') {
                return <div>{`${option.label} (${numberLoggedInWeek.length})`}</div>
            } else if (option.value === 'month') {
                return <div>{`${option.label} (${numberLoggedInMonth.length})`}</div>
            } else if (option.value === 'year') {
                return <div>{`${option.label} (${numberLoggedInYear.length})`}</div>
            } else if (option.value === 'never') {
                return <div>{`${option.label} (${numberLoggedInNever.length})`}</div>
            } else if (option.value === 'allTime') {
                return <div>{`${option.label} (${numberAllLogins})`}</div>
            }
        }

        let headerUsers = <div className='grid' style={{
            marginRight: 0,
            paddingTop: 18, paddingBottom: 20,
            paddingLeft: 5
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: 'calc(100% - 625px)', height: 30, marginRight: 15, marginBottom: 10 }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search users by name, email, organization or suborganization">
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', float: 'left', width: '85%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline' }}
                    type="search"
                    title='Search users by name, email, organization or suborganization'
                    value={this.state.globalFilterUsers}
                    className="collectionFilter"
                    onInput={(e) => this.handleGlobalFilterChange(e.target.value)}
                    placeholder='Search by name, email, organization or suborganization'
                />
            </div>
            <div style={{ paddingRight: 0, backgroundColor: '#f4f4f4', width: 192, textAlign: 'center', height: 44, marginRight: 15, marginBottom: 10 }}>
                <div style={{ marginTop: 7, marginBottom: 2 }}>
                    <label style={{ fontSize: 14 }}>All users</label>
                </div>
                <div>
                    <label style={{ fontSize: 14, fontWeight: 'bold' }}>{this.props.allGlobalUsers.length}</label>
                </div>
            </div>
            <div style={{ paddingRight: 0, backgroundColor: '#f4f4f4', width: 192, textAlign: 'center', height: 44, marginRight: 15, marginBottom: 10 }}>
                <div style={{ marginTop: 5, marginBottom: -6 }}>
                    <Dropdown className='dropdownNoBorder'
                        style={{
                            fontSize: 14,
                            marginTop: -5
                        }}
                        value={this.state.userType}
                        options={adminTypes}
                        itemTemplate={adminTypeTemplate}
                        onChange={(e) => { this.onTypeChange(e.value) }} />
                </div>
                <div>
                    <label style={{ fontSize: 14, fontWeight: 'bold' }}>{
                        this.state.userType === 'all' ? numberAllUserTypes : this.state.userType === 'super' ? numberSuperAdmins.length : this.state.userType === 'support' ? numberSupportAdmins.length :
                            this.state.userType === 'org' ? numberOrgAdmins.length : this.state.userType === 'suborg' ? numberDepAdmins.length : numberRegularUser.length}</label>
                    {this.state.userType !== 'all' ?
                        <div style={{ marginTop: -15 }}>
                            <a title='Clear filter' style={{ paddingRight: 10, float: 'right' }} onClick={(e) => this.setState({
                                userType: 'all'
                            })}>Clear filter</a>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <div style={{ paddingRight: 0, backgroundColor: '#f4f4f4', width: 192, textAlign: 'center', height: 44, marginBottom: 10 }}>
                <div style={{ marginTop: 5, marginBottom: -6 }}>
                    <Dropdown className='dropdownNoBorder'
                        style={{
                            fontSize: 14,
                            marginTop: -5
                        }}
                        value={this.state.loginType}
                        options={logins}
                        itemTemplate={loginTimeTemplate}
                        onChange={(e) => { this.onLoginChange(e.value) }} />
                </div>
                <div>
                    <label style={{ fontSize: 14, fontWeight: 'bold' }}>{
                        this.state.loginType === 'allTime' ? numberAllLogins : this.state.loginType === 'week' ? numberLoggedInWeek.length : this.state.loginType === 'month' ? numberLoggedInMonth.length :
                            this.state.loginType === 'year' ? numberLoggedInYear.length : numberLoggedInNever.length}</label>
                    {this.state.loginType !== 'allTime' ?
                        <div style={{ marginTop: -15 }}>
                            <a title='Clear filter' style={{ paddingRight: 10, float: 'right' }} onClick={(e) => this.setState({
                                loginType: 'allTime'
                            })}>Clear filter</a>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>

        let columnDataUsers = this.columns.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        this.possibleUsers = []

        this.allUsers && this.allUsers.forEach(user => {
            if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.username === this.props.userDetails.username) ||
                (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.highestAdminType !== 'ROLE_SUPER_ADMIN') ||
                (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.highestAdminType !== 'ROLE_SUPER_ADMIN' && user.highestAdminType !== 'ROLE_COMPANY_ADMIN') ||
                (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.highestAdminType !== 'ROLE_SUPER_ADMIN' &&
                    user.highestAdminType !== 'ROLE_COMPANY_ADMIN' &&
                    user.highestAdminType !== 'ROLE_DEPARTMENT_ADMIN'))) {
                if (this.state.filteredUsers.length === 0 && this.state.globalFilterUsers === "") {
                    this.possibleUsers = [...this.possibleUsers, user]
                }
            }
        })

        let filteredAllowedUsers = []

        if (this.state.filteredUsers && this.state.filteredUsers.length > 0) {
            this.state.filteredUsers && this.state.filteredUsers.length > 0 && this.state.filteredUsers.forEach(user => {
                if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.username === this.props.userDetails.username) ||
                    (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.highestAdminType !== 'ROLE_SUPER_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.highestAdminType !== 'ROLE_SUPER_ADMIN' && user.highestAdminType !== 'ROLE_COMPANY_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.highestAdminType !== 'ROLE_SUPER_ADMIN' &&
                        user.highestAdminType !== 'ROLE_COMPANY_ADMIN' &&
                        user.highestAdminType !== 'ROLE_DEPARTMENT_ADMIN'))) {
                    filteredAllowedUsers = [...filteredAllowedUsers, user]
                }
            })
            this.possibleUsers = filteredAllowedUsers
        }

        const checkUserTemplateHeader = () => {
            return <Checkbox
                disabled={this.allUsers.length > 0 && this.possibleUsers.length > 0 ? false : true}
                style={{ cursor: this.allUsers.length > 0 && this.possibleUsers.length > 0 ? "pointer" : "default" }}
                checked={this.state.allUsersSelected}
                onChange={(e) => this.handleSelectAllUsers(e)}>
            </Checkbox>
        }

        columnDataUsers.unshift(
            <Column
                key="check"
                field="check"
                header={checkUserTemplateHeader()}
                body={this.checkUserTemplate}
                style={{ width: '4em' }}>
            </Column>
        )

        return (

            <Dialog visible={this.props.displayGlobalUserSearch} style={{ 'width': "90vw", 'height': '90vh' }}
                header="Global user search" focusOnShow={false}
                modal={true} blockScroll
                dismissableMask={false}
                onHide={() => this.onCancelGlobalUserSearch()} className='styledDialog'>
                <div className='col-12' style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0 }}>
                    <div className="datatable-responsive-demo">
                        {headerUsers}
                        <DataTable ref={(el) => this.dt = el}
                            className="p-datatable-responsive-demo"
                            emptyMessage="No users available."
                            removableSort={true}
                            value={this.allUsers}
                            onValueChange={filteredData => this.onValueChange(filteredData)}
                            paginator={true}
                            rows={this.state.rows}
                            globalFilter={this.state.globalFilterUsers}
                            first={this.state.first}
                            rowsPerPageOptions={[10, 20, 50]}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                            {columnDataUsers}
                        </DataTable>
                    </div>
                </div>
                {this.state.displayDeleteUsers &&
                    <DeleteUsers
                        displayDeleteUsers={this.state.displayDeleteUsers}
                        onCancelDeleteUsers={this.onCancelDeleteUsers}
                        onDeleteUsers={this.onDeleteUsers}
                        selectedUserDetails={this.state.selectedUserDetails}
                    />
                }
                <Toast ref={this.toastBC} position="bottom-center" style={{ width: '30rem', bottom: 65, backgroundColor: 'white', opacity: '1', boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }} />
                <Toast ref={(el) => { this.growl = el }} />
                <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                    onHide={this.rejectSelectedUsers} style={{ width: '37rem', bottom: 2 }}
                    draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                    <ToastContent selectedElements={this.state.selectedUserDetails}
                        elementLabel='user'
                        onReload={this.onReloadToast}
                        onHide={this.rejectSelectedUsers}
                        onActivate={this.openActivateUsersGlobal}
                        onDeactivate={this.openInactivateUsersGlobal}
                        onMoveUsers={this.openMoveUsersGlobal}
                        onDelete={this.openDeleteUsersGlobal}
                        userDetails={this.props.userDetails}
                        activeOptions={['activateUsers', 'deactivateUsers', 'moveUsers', 'deleteUsers']} />
                </Dialog>
            </Dialog>
        )
    }
}

export default GlobalUserSearch