/* eslint-disable jsx-a11y/anchor-is-valid */
//import React, { Component, createRef } from 'react'
import { Component, createRef } from 'react'
//import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
//import { APP_PROPERTIES } from '../../../properties/index';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { TabView, TabPanel } from 'primereact/tabview'
import ExportGroup from './ExportGroup';
import ExportList from './ExportList';
import './Export.css'
import { ExportService } from './core/services/dataTableService';
import _ from 'lodash';
import { EXPORT_TITLE } from './core/constant';
import { addDynamicPanel, hasUserRole } from '../util';
import readcubeIcon from '../../../assets/images/icons/readcube.png'

class ExportContainer extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);

        this.state = {
            pageTutorials: [],
            section: '',
            sectionLabel: '',
            exports: [],
            selectedCategory: EXPORT_TITLE,
            categories: [],
            loader: false,
            isReload: false,
            activeIndex: 0
        }
        this.op = createRef();
    }


    componentDidMount() {
        this.init();
    }



    init() {
        const service = new ExportService();
        this.setState({
            loader: true,
        })
        const query = new URLSearchParams(this.props.location.search);
		const tab = query.get('tab')
        if(tab !== null){
            window.history.replaceState(null, null, window.location.pathname)
        }
        service.getExports().then(res => {
            let generalExports = []
            let readcubeExports = []
            generalExports = res.data?.filter(exp => exp.fileFormat !== 'readcube')
            readcubeExports = res.data?.filter(exp => exp.fileFormat === 'readcube')
            this.categoryGrouping(res.data?.filter(exp => exp.fileFormat !== 'readcube'))
            this.setState({
                generalExports: generalExports,
                readcubeExports: readcubeExports,
                loader: false,
                activeIndex: tab !== null && tab === 'readcube' ? 1 : 0
            })
        }).catch(err => {
            this.showFailGrowl(err.message);
            this.setState({
                loader: false
            })
        });
    }

    backgroundLoading() {
        const service = new ExportService();
        service.getExports().then(res => {
            let generalExports = []
            let readcubeExports = []
            generalExports = res.data?.filter(exp => exp.fileFormat !== 'readcube')
            readcubeExports = res.data?.filter(exp => exp.fileFormat === 'readcube')
            this.categoryGrouping(res.data?.filter(exp => exp.fileFormat !== 'readcube'))
            this.setState({
                generalExports: generalExports,
                readcubeExports: readcubeExports,
            })
        }).catch(err => {
            this.showFailGrowl(err.message);
        });
    }

    categoryGrouping(exports) {
        if (exports) {
            const counts = _.sortBy(exports, 'updated').reverse().reduce((p, c) => {
                const name = c.category;
                if (!p.hasOwnProperty(name)) {
                    p[name] = {
                        count: 0,
                        lastDate: c.updated
                    };
                }
                p[name].count++;
                return p;
            }, {});

            const groupedCategories = Object.keys(counts).map((category) => {
                return {
                    title: category,
                    count: counts[category]?.count,
                    lastModifiedDate: counts[category]?.lastDate,
                    isSelected: false
                };
            });

            this.setState({
                categories: groupedCategories
            });
        }

    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    handleCategoryChange = (value) => {
        this.setState({
            selectedCategory: value
        })
    }

    onTabChange = (e) => {
        this.setState({
            activeIndex: e.index
        })
    }
    render() {

        const readcubeHeader = <div className="flex align-items-center" style={{ cursor: 'pointer', marginTop: -3 }}>
            <span>
                <img src={readcubeIcon} alt="ReadCube papers"
                    style={{ height: 17, paddingRight: 5 }} />
            </span>
            ReadCube exports
        </div>

        return (
            <div className="grid "  >
                <Toast ref={(el) => { this.growl = el }} />

                <LoadingOverlay
                    className='fullWidth'
                    active={this.state.loader}
                    spinner={true} >
                    <div className='col-12 p-0 float-left'>
                        <div className='col-12 pb-0' >
                            <div className='grid'>
                                <div className="col textAlignLeft pb-0" >
                                    <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader mb-0">My Export Center</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10" style={{
                            paddingLeft: 7
                        }}>
                            <label className='ex-description'>
                                Your general exports are available to download for 30 days. Note: At peak times exports may take several hours depending on system activity.</label>
                        </div>
                    </div>
                    <div className='col-12 tabview-demo' style={{ display: 'inline-block', paddingTop: 0 }}>
                        <TabView style={{ paddingRight: 0 }} className="tabview-custom" activeIndex={this.state.activeIndex} onTabChange={(e) => this.onTabChange(e)}>
                            <TabPanel header="General exports">
                                <div className='grid col-12 pl-0 pt-0' >
                                    <div className='col-6 sm:col-6 md:col-5 lg:col-4 xl:col-3 ex-gr-wrapper' style={{ paddingLeft: 5 }}>
                                        <ExportGroup
                                            categories={this.state.categories}
                                            allExportLength={this.state.generalExports?.length || 0}
                                            handleExportChange={this.handleCategoryChange}
                                        />
                                    </div>
                                    <div className='col-6 sm:col-6 md:col-7 lg:col-8 xl:col-9 ex-list-wrapper' >
                                        <ExportList
                                            userData={this.props.userData}
                                            exports={this.state.generalExports}
                                            selectedCategory={this.state.selectedCategory}
                                            onReload={() => {
                                                this.backgroundLoading()
                                            }}
                                            onErrorToast={this.showFailGrowl}
                                            showHeader={true}
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            {addDynamicPanel(hasUserRole('ROLE_READCUBEVIEW')).map(hack => {
                                return <TabPanel key={hack} header={readcubeHeader}>
                                    <div className='col-12 pt-0 pl-0'>
                                        <ExportList
                                            userData={this.props.userData}
                                            exports={this.state.readcubeExports}
                                            selectedCategory={this.state.selectedCategory}
                                            onReload={() => {
                                                this.backgroundLoading()
                                            }}
                                            onErrorToast={this.showFailGrowl}
                                            showHeader={false}
                                        />
                                    </div>
                                </TabPanel>
                            })}
                        </TabView>
                    </div>
                    {/* <Toast ref={this.toastBC} className='toast' position="bottom-center" style={{ width: this.state.selectedExport !== undefined && !this.state.selectedExport.sharedComment ? '30rem' : '25rem' }} /> */}
                </LoadingOverlay>
            </div>
        )
    }
}

export default ExportContainer
