import { Component } from "react";
import { scrollToElementViaScrollToButton } from "../../docview/util/htmlHandling";

import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
} from "./pdfViewer";

import type { IHighlight, NewHighlight } from "./react-pdf-highlighter";
import { Spinner } from "./Spinner";

import "./style/App.css";
import ConceptDetailsDialog from "../../conceptdetails/ConceptDetailsDialog";
import SentenceContainer from "../../SentenceAnalysis/container/SentenceContainer";

interface State {
  // Define the component's state variables here with explanations
  url: string;
  highlights: Array<IHighlight>;
  clickedEntry: string;
  visibleConceptInfo: boolean;
  isMatchOn: boolean;
  annTypeLabelsMap: any;
  domainColors: any;
  activeAnnotations: Array<string>;
  activeSearchTerms: Array<any>;
  selectedSentence: any;
  availableContentHeight: number;
}

// Utility function to generate a unique ID for highlights
const getNextId = () => String(Math.random()).slice(2);

// Utility function to extract highlight ID from the URL hash
const parseIdFromHash = () => document.location.hash.slice("#highlight-".length);

// Utility function to reset the URL hash
const resetHash = () => {
  document.location.hash = "";
};

// Initial URL for the PDF document
const initialUrl = '';

class PDFDocument extends Component<any, State> {
  state = {
    // Initialize component state variables
    url: initialUrl,
    highlights: this.props.annotations,
    clickedEntry: '',
    visibleConceptInfo: false,
    annTypeLabelsMap: this.props.annTypeLabelsMap,
    domainColors: this.props.domainColors,
    activeAnnotations: [],
    activeSearchTerms: [],
    isMatchOn: true,
    selectedSentence: this.props.selectedSentence,
    availableContentHeight: this.props.availableContentHeight,
  };

  // Function to render the popup for highlights
  HighlightPopup = (ann: any) => {
    return <div className="Highlight__popup">
      {
        ann?.map((item: any) => {
          return { ...item, ...this.props.conceptsData[item?.ocid] }
        })
      }
    </div>
  }

  // Function to reset all highlights
  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };

  // Function to set highlights
  setHighlights = (highlights: any) => {
    this.setState({
      highlights: highlights,
    });
  };

  // Function to scroll the viewer to a highlight
  scrollViewerTo = (highlight: any) => { };

  // Function to scroll to a highlight based on the URL hash
  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());
    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  // Static function to handle changes in props and update state
  static getDerivedStateFromProps(props: any, state: any) {
    if (props.annotations?.length !== state.highlights?.length ||
      props.activeSearchTerms !== state.activeSearchTerms ||
      props.isMatchOn !== state.isMatchOn
    ) {
      return {
        activeAnnotations: props.activeAnnotations,
        highlights: props.annotations,
        activeSearchTerms: props.activeSearchTerms,
        isMatchOn: props.isMatchOn,
        selectedSentence: props.selectedSentence
      };
    }
    return null;
  }

  // Component lifecycle method: componentDidMount
  componentDidMount() {
    window.addEventListener("hashchange", this.scrollToHighlightFromHash, false);
    //console.log(this.props)
    if (this.props.initQuery && this.props?.annotations[0]?.annId && this.props.readcubeData?.available) {
      document.location.hash = `highlight-${this.props?.annotations[0]?.annId}`;
    }
  }

  // Function to disable Chrome's built-in search when pressing 'f' or 'Ctrl+f'
  disableChromeSearch(e: any) {
    if (e.key === 'f' || (e.ctrlKey && e.key === 'Control')) {
      e.preventDefault();
    }
  }

  // Component lifecycle method: componentWillUnmount
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.scrollToHighlightFromHash);
  }

  // Function to get a highlight by ID
  getHighlightById(id: string) {
    const { highlights } = this.state;
    return highlights.find((highlight: any) => highlight.annId === id);
  }

  // Function to add a new highlight
  addHighlight(highlight: NewHighlight) {
    const { highlights } = this.state;
    this.setState({
      highlights: [{ ...highlight, annId: getNextId() }, ...highlights],
    });
  }

  // Function to show concept details
  onShowConceptDetails = (ocid: any) => {
    this.setState({
      visibleConceptInfo: true,
      clickedEntry: ocid
    });
  }

  // Function to set the active sentence and scroll to it
  setActiveSentence = (id: any) => {
    scrollToElementViaScrollToButton('#scrollToBtnSent', '#' + id)
    document.location.hash = `highlight-${id}`;
    this.props.activeSentenceChange(id)
  }

  render() {
    // Destructure state variables for easier use
    const {
      url,
      highlights,
      clickedEntry,
      visibleConceptInfo,
      annTypeLabelsMap,
      activeAnnotations,
      activeSearchTerms,
      domainColors
    } = this.state;

    let annotationCoords: any[] = []

    // Conditionally process annotations based on 'renderDocumentOnly' prop
    if (this.props.renderDocumentOnly) {
      // Iterate over annotations to extract coordinates
      Object.keys(this.props.annotations).forEach(key => {
        let selectedAnnotationsIndex = this.props.annotations[key].annotations.findIndex((ann: any) => ann.hasOwnProperty("queryId"))
        if (selectedAnnotationsIndex !== -1 && this.props.annotations[key].hasOwnProperty("sentNr") &&
          this.props.annotations[key].annotations[selectedAnnotationsIndex].queryTerm) {
          let newEl = document.getElementById(this.props.annotations[key].annId)
          if (newEl !== null) {
            let topCoordinate = newEl.offsetTop
            //if (annotationCoords.length === 0 || (!annotationCoords.some(coord => (coord.top === topCoordinate) && (coord.color === newEl?.style.backgroundColor)))) {
              annotationCoords = [...annotationCoords, { top: topCoordinate, color: newEl?.style.backgroundColor, element: 'entity', id: newEl.id, queryId: this.props.annotations[key].annotations[selectedAnnotationsIndex].queryId }]
            //}
          }
        }
      })

      // Iterate over annotations to extract section coordinates
      Object.keys(this.props.annotations).forEach(key => {
        if (this.props.annotations[key].hasOwnProperty("section")) {
          let newElSec = document.getElementById(this.props.annotations[key].annId)
          if (newElSec !== null) {
            let topCoordinate = newElSec.offsetTop
            //console.log(this.props.annotations)
            if (!annotationCoords.some(coord => (coord.top === topCoordinate && coord.element !== 'entity')  || (coord.element !== 'entity' && coord.sectionInfo?.includes(this.props.annotations[key].section))))
            //))
           {
              annotationCoords = [...annotationCoords, { top: topCoordinate, color: "", element: "section", sectionInfo: this.props.annotations[key].section }]
            }
          }
        }
      })
    }

    return (
      <div className="pdf-viewer-wrapper" >
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        >
          <PdfLoader url={this.props.pdf} beforeLoad={<Spinner />}>
            {(pdfDocument) => (
              <PdfHighlighter
                isMatchOn={this.props.isMatchOn}
                pdfDocument={pdfDocument}
                enableAreaSelection={(event) => event.altKey}
                onScrollChange={resetHash}
                availableContentHeight={this.props.availableContentHeight}
                renderDocumentOnly={this.props.renderDocumentOnly}
                annotationCoords={annotationCoords}
                scrollRef={(scrollTo) => {
                  this.scrollViewerTo = scrollTo;
                  this.scrollToHighlightFromHash();
                }}
                highlightTransform={(highlight, index, setTip, hideTip, isScrolledTo) => {
                  const component =
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight?.position}
                      annotations={highlight?.annotations}
                      activeSearchTerms={this.props.activeSearchTerms}
                      activeAnnotations={activeAnnotations}
                      domains={this.props.domains}
                      isMatchOn={this.props.isMatchOn}
                      selectedSentence={this.props.selectedSentence}
                      sentenceId={highlight?.annId}
                    />
                  return (
                    <Popup
                      popupContent={this.HighlightPopup(highlight?.annotations)}
                      onClick={(popupContent, rectIndex) => {
                        if (popupContent.props.children.props.children.length > 0) {
                          setTip(highlight, rectIndex, (highlight) => popupContent)
                        } else {
                          this.setActiveSentence(highlight?.annId)
                        }
                      }}
                      onMouseOut={hideTip}
                      key={index}
                      text={highlight.position.text}
                      children={component}
                      onShowConcept={(ocid => this.onShowConceptDetails(ocid))}
                      domainColors={domainColors}
                      annTypeLabelsMap={annTypeLabelsMap}
                    />
                  );
                }}
                highlights={highlights}
                activeSearchTerms={this.props.activeSearchTerms}
              />
            )}
          </PdfLoader>

          <ConceptDetailsDialog
            visible={visibleConceptInfo}
            includeQuickSearchShortcut={true}
            includeChemSearchShortcut={true}
            includeCoocSearchShortcut={true}
            ocid={clickedEntry}
            onConceptClick={() => { }}
            onHide={() => this.setState({ visibleConceptInfo: false })}
          />
        </div>
      </div>
    );
  }
}

export default PDFDocument;