import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { Accordion, AccordionTab } from "primereact/accordion";
import { isArrayEmpty } from "../../util";
import FilterSectionDropdown from "../../general/filters/FilterSectionDropdown";
import FilterSectionNumRange from "../../general/filters/FilterSectionNumRange";
import { SEARCH_FILTER_TYPE_CHECKBOX, SEARCH_FILTER_TYPE_DROPDOWN_STATIC, SEARCH_FILTER_TYPE_RADIO_STATIC } from "../../general/docsearch/searchConstants";
import { CHEMISTRY_SEARCH_FILTER_ID_INCL_MIXTURES, CHEMISTRY_SEARCH_FILTER_ID_MIN_SIMILARITY, CHEMISTRY_SEARCH_FILTER_TYPE_NUM_INPUT_FREE, CHEMISTRY_SEARCH_FILTER_TYPE_NUM_INPUT_SLIDER, CHEMISTRY_SEARCH_FILTER_TYPE_NUM_RANGE } from "../utils/chemistrySearchConstants";
import FilterSectionNumSlider from "../../general/filters/FilterSectionNumSlider";
import FilterSectionRadioSelection from "../../general/filters/FilterSectionRadioSelection";
import filterIcon from "../../../../assets/images/icons/filter/Icon_filter_remove_outline.svg";
import { SEARCH_TARGET_COMPOUNDS, SEARCH_TYPE_SIMILARITY } from "../utils/chemistryDBs";
import '../../general/filters/Filters.css';
import { FILTER_INFO_MAPPING, FILTER_INFO_MAPPING_BY_SEARCH_TYPE, FILTER_INFO_MAPPING_BY_TYPE } from "../infos/filterInfoMapping";
import FilterSectionCheckbox from "../../general/filters/FilterSectionCheckbox";


class ChemistryFiltersBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            //activeIndices: [0],
        };

        this.growl = createRef();
        this.toast = createRef();

        this.resetActivetIndices = true;

        this.filterIndexMap = {};
        this.indexFilterMap = {};
        this.activeFilterIDs = {};
        this.doNoToggle = false;
    }


    addToActiveIndices = (activeIndices, index, filter) => {
        if (filter.openOnDefault) {
            activeIndices.push(index);
        }
    }

    isMaxNumberOfOccurrencesReached = (filter, queryTerms) => {
        let maxNumOfOccReached = false;
        if (filter?.maxNumOfOcc && filter.maxNumOfOcc > 0) {
            let numOfOcc = 0;
            queryTerms?.forEach(queryTerm => { if (queryTerm.filterID === filter.id) { numOfOcc++; } });
            maxNumOfOccReached = numOfOcc >= filter.maxNumOfOcc;
        }
        return maxNumOfOccReached;
    }

    getCurrentFilter = (filterDefinitions, filterID) => {
        return filterDefinitions && filterDefinitions[filterID] ? filterDefinitions[filterID] : null;
    }

    renderAccordionHeader = (filterID, label, filterInfo, active, filterInfoAlwaysVisible) => {
        let info = filterInfoAlwaysVisible || (this.activeFilterIDs && this.activeFilterIDs[filterID]) ? filterInfo : null;

        return <span className='accordion-header'>
            <span className={`accordion-header-text ${active ? 'active' : ''}`} title={label}>{label}</span>
            <span className='accordion-header-icons close'>
                {info ?
                    <span className="material-icons-outlined accordion-header-icon"
                        style={{ right: active ? -5 : '' }}
                        onClick={(e) => { this.doNoToggle = true; }}>
                        {info}
                    </span> : null}
                {active ? <span className="material-icons-outlined accordion-header-icon"
                    title='Clear filter'
                    onClick={(e) => {
                        this.doNoToggle = true;
                        this.props.onRemoveFilter(filterID);
                    }}>close</span> : null}
            </span>
        </span>;
    }


    renderAccordionHeader_bak = (filterID, label, active, filterInfo) => {
        return <>
            <span className='accordion-header with-icons'>
                <span className={`accordion-header-text ${active ? 'active' : ''}`}
                    title={label}>{label}</span>
                <span className='accordion-header-icons'
                    onClick={(e) => { this.doNoToggle = true; }}>
                    {active ? <span
                        className="pi pi-times close-icon"
                        style={{ top: filterInfo ? -3 : 3 }}
                        onClick={(e) => { this.props.onRemoveFilter(filterID); }}
                    ></span> : null}
                    <span className='info-icon'>
                        {filterInfo}
                    </span>
                </span>
            </span>
        </>;
    }

    renderAccordionHeader_test = (filterID, label, active) => {
        return <span className='accordion-header'>
            <span className={`accordion-header-text ${active ? 'active' : ''}`} title={label}>{label}</span>
            <span className='accordion-header-icons'>
                {active ? <img
                    className='accordion-header-icon remove-filter'
                    src={filterIcon}
                    alt='Clear filter'
                    title='Clear filter'
                    onClick={(e) => {
                        this.doNoToggle = true;
                        this.props.onRemoveFilter(filterID);
                        //stopEventPropagation(e); // not working!
                    }} /> : null}
            </span>
        </span>;
    }

    renderFilterSections = (repoFilters) => {

        this.filterIndexMap = {};
        this.indexFilterMap = {};
        this.inclMixturesFilter = null;
        this.disabledFilters = {};

        let hasAdditionalFilters = false;

        let currIndex = -1;
        let activeIndices = [];

        const filterSections = (
            repoFilters ?
                repoFilters
                    .filter(filterID => {
                        const filter = this.props.filterDefinitions[filterID];
                        const maxNumOfOccReached = this.isMaxNumberOfOccurrencesReached(filter, this.props.queryTerms);
                        //console.log('maxNumOfOccReached: ', maxNumOfOccReached);
                        return (filter && !maxNumOfOccReached);
                    })
                    .map(filterID => {
                        const filter = this.props.filterDefinitions[filterID];
                        //console.log('filter: ', filter);
                        // -- check if a maximum number of occurrences for this filter is defined and reached --- //
                        // TODO: does it ever happen???

                        currIndex++;

                        this.filterIndexMap[filter.id] = currIndex;
                        this.indexFilterMap[currIndex] = filter.id;
                        hasAdditionalFilters = hasAdditionalFilters || filter.isAdditionalFilter;

                        const filterInfo = FILTER_INFO_MAPPING[filter.id] ? FILTER_INFO_MAPPING[filter.id] : FILTER_INFO_MAPPING_BY_TYPE[filter.type];

                        const disableAdditionalFilter = filter.isAdditionalFilter && this.props.disableAdditionalFilters;
                        if (disableAdditionalFilter) {
                            this.disabledFilters[currIndex] = filter.id;
                        }

                        switch (filter.type) {

                            case SEARCH_FILTER_TYPE_RADIO_STATIC: {
                                const filterValues = this.props.filterInputValues && this.props.filterInputValues[filter.id] ? this.props.filterInputValues[filter.id] : null;
                                this.addToActiveIndices(activeIndices, currIndex, filter);
                                const filterInfoHeader = FILTER_INFO_MAPPING_BY_SEARCH_TYPE[this.props.selectedChemDB?.type];
                                return <AccordionTab
                                    key={filter.id}
                                    header={this.renderAccordionHeader(filter.id, filter.label, filterInfoHeader, false, true)}
                                    disabled={disableAdditionalFilter}
                                    headerClassName={'no-style-header label-no-padding-left'}>
                                    <div title=''>
                                        <FilterSectionRadioSelection
                                            filterID={filter.id}
                                            filterEntries={filter.filterValues}
                                            selectedEntry={filterValues?.value}
                                            onFilterInputValuesChange={(filterObject) => { this.props.onImmediatelyAddFiltersToQuery(filterObject, filter) }}
                                            placeholder={filter.placeholder}
                                            showClear={false}
                                            searchTarget={this.props.searchTarget}
                                        />
                                    </div>
                                </AccordionTab>
                            }

                            case SEARCH_FILTER_TYPE_DROPDOWN_STATIC: {
                                const filterValues = this.props.filterInputValues && this.props.filterInputValues[filter.id] ? this.props.filterInputValues[filter.id] : null;
                                this.addToActiveIndices(activeIndices, currIndex, filter);
                                return <AccordionTab
                                    key={filter.id}
                                    header={this.renderAccordionHeader(filter.id, filter.label, filterInfo, filterValues?.value)}
                                    disabled={disableAdditionalFilter}>
                                    <div title=''>
                                        <FilterSectionDropdown
                                            filterID={filter.id}
                                            filterEntries={filter.filterValues}
                                            selectedEntry={filterValues?.value}
                                            onFilterInputValuesChange={(filterObject) => { this.props.onImmediatelyAddFiltersToQuery(filterObject, filter) }}
                                            placeholder={filter.placeholder}
                                            showClear={false}
                                        />
                                    </div>
                                </AccordionTab>
                            }

                            case CHEMISTRY_SEARCH_FILTER_TYPE_NUM_RANGE: {
                                const filterValues = this.props.filterInputValues && this.props.filterInputValues[filter.id] ? this.props.filterInputValues[filter.id] : null;
                                this.addToActiveIndices(activeIndices, currIndex, filter);
                                return <AccordionTab
                                    key={filter.id}
                                    header={this.renderAccordionHeader(filter.id, filter.label, filterInfo, filterValues?.minValue || filterValues?.maxValue)}
                                    disabled={disableAdditionalFilter}>
                                    <div title=''>
                                        <FilterSectionNumRange
                                            filterID={filter.id}
                                            minValue={filterValues?.minValue ? filterValues.minValue : ''}
                                            maxValue={filterValues?.maxValue ? filterValues.maxValue : ''}
                                            unit={filter.unit}
                                            onFilterValuesChange={this.props.onFilterInputValuesChange}
                                            placeholder={filter.placeholder}
                                            placeholder2={filter.placeholder2}
                                        />
                                    </div>
                                </AccordionTab>
                            }

                            case CHEMISTRY_SEARCH_FILTER_TYPE_NUM_INPUT_FREE: {
                                const filterValues = this.props.filterInputValues && this.props.filterInputValues[filter.id] ? this.props.filterInputValues[filter.id] : null;
                                this.addToActiveIndices(activeIndices, currIndex, filter);
                                return <AccordionTab
                                    key={filter.id}
                                    header={this.renderAccordionHeader(filter.id, filter.label, filterInfo, filterValues?.minValue || filterValues?.maxValue)}
                                    disabled={disableAdditionalFilter}>
                                    <div title=''>
                                        <FilterSectionNumSlider
                                            filterID={filter.id}
                                            value={filterValues?.value ? filterValues.value : ''}
                                            unit={filter.unit}
                                            minValue={filter.minValue}
                                            maxValue={filter.maxValue}
                                            onFilterValuesChange={this.props.onFilterInputValuesChange}
                                        />
                                    </div>
                                </AccordionTab>
                            }

                            case CHEMISTRY_SEARCH_FILTER_TYPE_NUM_INPUT_SLIDER: {
                                if (this.props.searchTarget === SEARCH_TARGET_COMPOUNDS) {
                                    const enable = filter.id !== CHEMISTRY_SEARCH_FILTER_ID_MIN_SIMILARITY || this.props.filterInputValues?.searchType?.value?.filterValue === SEARCH_TYPE_SIMILARITY;
                                    if (!enable) {
                                        this.disabledFilters[currIndex] = filter.id;
                                    }
                                    const filterValues = this.props.filterInputValues && this.props.filterInputValues[filter.id] ? this.props.filterInputValues[filter.id] : null;
                                    this.addToActiveIndices(activeIndices, currIndex, filter);
                                    return <AccordionTab
                                        key={filter.id}
                                        header={this.renderAccordionHeader(filter.id, filter.label, filterInfo, filterValues?.minValue || filterValues?.maxValue)}
                                        disabled={!enable || disableAdditionalFilter}>
                                        <div title=''>
                                            <FilterSectionNumSlider
                                                filterID={filter.id}
                                                value={filterValues?.value ? filterValues.value : filter.defaultValue}
                                                unit={filter.unit}
                                                minValue={filter.minValue}
                                                maxValue={filter.maxValue}
                                                onFilterValuesChange={this.props.onFilterInputValuesChange}
                                            />
                                        </div>
                                    </AccordionTab>
                                }
                                break;
                            }

                            case SEARCH_FILTER_TYPE_CHECKBOX: {
                                if (filter.id === CHEMISTRY_SEARCH_FILTER_ID_INCL_MIXTURES) {
                                    //this.inclMixturesFilter = filter;

                                    const enabled = !disableAdditionalFilter && this.props.includeWithMixtures;
                                    if (!enabled) {
                                        this.disabledFilters[currIndex] = filter.id;
                                    }

                                    const header = enabled ? <span className='accordion-header with-icons'>
                                        <span className={`accordion-header-text`}
                                            style={{ left: -20 }}
                                            title={filter.label}
                                            onClick={(e) => { this.doNoToggle = true; }}>
                                            <FilterSectionCheckbox
                                                filterID={filter.id}
                                                label={filter.label}
                                                value={this.props.withMixtures}
                                                onFilterValuesChange={this.props.onWithMixturesChange}
                                                disabled={!enabled}
                                            />
                                        </span>
                                        <span className='accordion-header-icons'
                                            style={{ position: 'relative', top: 5 }}
                                            onClick={(e) => { this.doNoToggle = true; }}>
                                            <span className='info-icon'>
                                                {filterInfo}
                                            </span>
                                        </span>
                                    </span>
                                        :
                                        this.renderAccordionHeader(filter.id, filter.label, false)

                                    return <AccordionTab
                                        key={filter.id}
                                        header={header}
                                        disabled={!enabled}
                                        headerClassName={enabled ? 'no-style-header' : ''}
                                    >
                                    </AccordionTab>
                                }
                                break;
                            }

                            default: // do nothing
                        }

                        return <React.Fragment key={filter.id}></React.Fragment>;
                    }) : null
        );

        const nonDisabledActiveIndices = this.props.activeFilterIndices ?
            this.props.activeFilterIndices.filter(activeFilterIndex => !this.disabledFilters[activeFilterIndex]) : [];

        return (
            <div title='Some filters are not available for certain settings'>
                <Accordion
                    className="simpleAccordion facets chemistry"
                    multiple={true}
                    activeIndex={nonDisabledActiveIndices}
                    expandIcon="pi pi-chevron-up"
                    collapseIcon="pi pi-chevron-down"
                    onTabChange={(e) => {
                        if (!this.doNoToggle) {
                            this.activeFilterIDs = {};
                            e.index?.forEach(index => {
                                let activeFilterID = (this.indexFilterMap && this.indexFilterMap[index]) ? (this.indexFilterMap[index]) : null;
                                if (activeFilterID) {
                                    this.activeFilterIDs[activeFilterID] = true;
                                }
                            });
                            this.props.onToggleFilters(e.index);
                        }
                        else {
                            this.doNoToggle = false;
                        }
                    }}
                >
                    {filterSections}
                </Accordion>

                {this.inclMixturesFilter ?
                    <div title=''
                        style={{ marginTop: 10, fontSize: 15, textTransform: 'uppercase', position: 'relative', left: -10 }}>
                        <FilterSectionCheckbox
                            filterID={this.inclMixturesFilter.id}
                            label={this.inclMixturesFilter.label}
                            value={!!this.props.withMixtures}
                            onFilterValuesChange={this.props.onWithMixturesChange}
                            filterInfo={FILTER_INFO_MAPPING[this.inclMixturesFilter.id] ?
                                FILTER_INFO_MAPPING[this.inclMixturesFilter.id] :
                                FILTER_INFO_MAPPING_BY_TYPE[this.inclMixturesFilter.type]}
                            disabled={!this.props.includeWithMixtures}
                        /></div> : null}
            </div>
        );
    }


    render() {

        const { activeFilters, fetchingFilters } = this.props;

        const repoFilters = activeFilters;
        //console.log('repoFilters: ', repoFilters);

        return (
            <>
                <Toast ref={this.growl} />
                <Toast ref={this.toast} position="bottom-left" className="filterToast" />

                <h2 className="filterBlockHeading">FILTERS</h2>
                {fetchingFilters ?
                    <div className="secondaryInfo">Fetching filters...</div>
                    :
                    this.props.filterDefinitions && !isArrayEmpty(repoFilters) ?
                        this.renderFilterSections(repoFilters)
                        :
                        <div className="secondaryInfo">No filters</div>
                }
            </>
        );
    }
}

export default ChemistryFiltersBlock;