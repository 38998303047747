import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import PropTypes from 'prop-types';

import styles from './ConfirmationDialog.module.scss';
import { ActionButton } from '../ActionButtons';

export const ConfirmationDialog = ({
  title,
  content,
  text,
  cancelText,
  confirmText,
  visible,
  onClose,
  onConfirm,
  isRemoval = false,
  isLoading = false,
  contentExtraStyles = [],
  ...rest
}) => {
  return (
    <ConfirmDialog
      className={styles.container}
      contentClassName={contentExtraStyles}
      visible={visible}
      onHide={onClose}
      reject={onClose}
      accept={onConfirm}
      message={
        <div className={styles.contentContainer}>
          <div className={styles.contentText}>{content}</div>
          <div className={styles.contentText}>{text}</div>
        </div>
      }
      header={<span className={styles.titleText}>{title}</span>}
      closable={false}
      breakpoints={{
        '1440px': '25vw',
        '1024px': '40vw',
        '768px': '50vw',
      }}
      footer={
        <div className={styles.footer}>
          <ActionButton
            extraClassNames={[styles.cancelButton]}
            label={cancelText}
            onClick={onClose}
            disabled={isLoading}
          />
          {isRemoval ? (
            <ActionButton
              isButtonLink={false}
              extraClassNames={[styles.removeButton, styles.acceptButton]}
              label={confirmText}
              onClick={onConfirm}
              disabled={isLoading}
            />
          ) : (
            <ActionButton
              isButtonLink={false}
              extraClassNames={[styles.confirmButton, styles.acceptButton]}
              label={confirmText}
              onClick={onConfirm}
              disabled={isLoading}
            />
          )}
        </div>
      }
      {...rest}
    />
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isRemoval: PropTypes.bool,
  isLoading: PropTypes.bool,
  contentExtraStyles: PropTypes.arrayOf(PropTypes.string),
};
