import {
} from '../../../../redux/actions/WebApiActions'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Library from '../Library'

const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    userData: state.user.data,
    availableRepositories: state.webAPI.availableRepositories
})

const mapDispatchToProps = dispatch => ({
})


const LibraryContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Library))

export default LibraryContainer