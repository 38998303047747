import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Ripple } from 'primereact/ripple';

class AppSubmenu extends Component {

    static defaultProps = {
        className: null,
        items: null,
        onRootItemClick: null,
        onMenuItemClick: null,
        root: false,
        layoutMode: null,
        menuActive: false,
        parentMenuItemActive: false
    }

    static propTypes = {
        className: PropTypes.string,
        items: PropTypes.array,
        onRootItemClick: PropTypes.func,
        onMenuItemClick: PropTypes.func,
        root: PropTypes.bool,
        layoutMode: PropTypes.string,
        menuActive: PropTypes.bool,
        parentMenuItemActive: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    onMenuItemClick(event, item, index) {
        //avoid processing disabled items
        //console.log(item)
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        if (this.props.root && this.props.onRootItemClick) {
            this.props.onRootItemClick({
                originalEvent: event,
                item: item
            });
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
            event.preventDefault();
        }

        if (index === this.state.activeIndex) {
            //console.log("if")
            this.setState({ activeIndex: null });
        } else {
            //console.log("else")
            this.setState({ activeIndex: index });
        }

        if (this.props.onMenuItemClick) {
            //console.log("if")
            this.props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    onKeyDown(event, item, index) {
        if (event.key === 'Enter') {
            this.onMenuItemClick(event, item, index);
        }
    }

    onMenuItemMouseEnter(index) {
        if (this.props.root && this.props.menuActive && this.isHorizontal() && !this.isMobile()) {
            this.setState({ activeIndex: index });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.parentMenuItemActive === false) {
            return {
                activeIndex: null
            }
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //if (this.isHorizontal() && !this.isMobile() && prevProps.menuActive && !this.props.menuActive) {
        if (this.isHorizontal() && prevProps.menuActive && !this.props.menuActive) {
            //console.log("update")
            this.setState({ activeIndex: null });
        }
    }

    isHorizontal() {
        return (this.props.layoutMode === 'horizontal');
    }

    isMobile() {
        return window.innerWidth <= 1024;
    }

    renderLinkContent(item) {
        //console.log(item)
        //let submenuIcon = item.items && <i className="pi pi-angle-down layout-submenu-toggler"></i>;
        let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

        // --- remove style classes from submenus, since they are used for custom icons --- //
        if (!item.isMainMenu) {
            delete item.styleClass;
        }

        //console.log(this.state)

        return (
            <React.Fragment>
                {//!item.template &&
                    (item.isMainMenu || (!item.isMainMenu && !item.info)) &&
                    <i className="material-icons">{item.icon}</i>
                }
                {/*<i className="material-icons">{item.icon}</i>*/}
                {/*<i className={item.icon}></i>*/}
                <span className="menuitem-text menuitemName">{item.label}</span>
                {!item.isMainMenu && item.info ? <div className="menuitemInfoText">{item.info}</div> : null}
                {badge}
                {/*submenuIcon*/}
            </React.Fragment>
        );
    }

    renderLink(item, i) {
        //console.log(item)
        let content = this.renderLinkContent(item);
        let linkStyle = classNames(item.styleClass, 'p-ripple');
        if (item.to) {
            //console.log(item)
            return (
                <NavLink activeClassName="router-link-active" to={item.to}
                    onClick={(e) => this.onMenuItemClick(e, item, i)} exact role="menuitem"
                    target={item.target} onMouseEnter={(e) => this.onMenuItemMouseEnter(i)}
                    className={`${linkStyle} ${item.styleClassName ? item.styleClassName : ''}`}
                    style={{
                        paddingLeft: (!item.isMainMenu) //&& item.info) 
                            && 15, paddingRight: (!item.isMainMenu && item.info) && 15, textDecoration: 'none'
                    }}
                //style={{ paddingLeft: item.template && 10 }}
                >{content}
                    <Ripple />
                </NavLink>
            )
        } else {
            return (
                <a className={`${linkStyle} ${item.styleClassName ? item.styleClassName : ''}`}
                    role="menuitem" href={item.url} tabIndex={item.url ? '' : 0}
                    style={{ textDecoration: 'none' }}
                    onClick={(e) => this.onMenuItemClick(e, item, i)} target={item.target} onKeyDown={(e) => this.onKeyDown(e, item, i)}
                    onMouseEnter={(e) => this.onMenuItemMouseEnter(i)}>
                    {content}
                    <Ripple />
                </a>
            );
        }
    }


    render() {
        //console.log(this.props)
        var items = this.props.items && this.props.items.map((item, i) => {
            let active = this.state.activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active });

            ///let template = this.props.items.template === 'librayTemplate' ?
            //<div>Test</div> : null

            return <li className={styleClass} key={i} role="none" style={{ background: 'white' }}>
                {this.renderLink(item, i)}
                <CSSTransition classNames="layout-submenu" timeout={{ enter: 400, exit: 400 }} in={active}>
                    <AppSubmenu items={item.items} onMenuItemClick={this.props.onMenuItemClick}
                        layoutMode={this.props.layoutMode}
                        menuActive={this.props.menuActive} parentMenuItemActive={active} />
                </CSSTransition>
            </li>
        });

        return <ul role="menu" className={this.props.className}>{items}</ul>;

    }
}

export class AppMenu extends Component {

    static defaultProps = {
        model: null,
        onRootMenuItemClick: null,
        onMenuItemClick: null,
        layoutMode: null,
        active: false
    }

    static propTypes = {
        model: PropTypes.array,
        layoutMode: PropTypes.string,
        onRootMenuItemClick: PropTypes.func,
        onMenuItemClick: PropTypes.func,
        active: PropTypes.bool
    }

    render() {

        return (
            <AppSubmenu items={this.props.model} className="layout-menu" menuActive={this.props.active}
                onMenuItemClick={this.props.onMenuItemClick} onRootItemClick={this.props.onRootMenuItemClick}
                root={true} layoutMode={this.props.layoutMode} parentMenuItemActive={true} />
        );
    }
}