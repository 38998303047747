import { RadioButton } from "primereact/radiobutton";
import { Component } from "react";
import { hasUserRole } from '../../../../components/webapi/util';


class ChemistrySearchSelection extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        const { options, onSelectOption, selectedOption } = this.props;

        //console.log(options)

        return (
            <>
                <label className="valignMiddle" style={{ marginLeft: 0 }}>Search in:</label>
                <div className="valignMiddle">
                    {options
                        ?.filter(option => (option.value === 'compounds' || (option.value !== 'compounds' && hasUserRole('ROLE_REACTIONSEARCH'))))
                        .map(option => {
                            //if (option.value === 'compounds' || (option.value !== 'compounds' && hasUserRole('ROLE_REACTIONSEARCH')))
                            return <span key={option.value}
                                className="p-field-radiobutton inline"
                                style={{ marginBottom: 0 }}>
                                <RadioButton
                                    inputId={option.value}
                                    name="option"
                                    value={option}
                                    onChange={(e) => { onSelectOption(e.value.value) }}
                                    checked={selectedOption === option.value}
                                    style={{ display: 'inline-block', marginLeft: 15 }}
                                />
                                <label style={{ verticalAlign: 'middle' }}
                                    htmlFor={option.value}>{option.label}
                                </label>
                            </span>
                        })}
                </div>
            </>
        );
    }
}

export default ChemistrySearchSelection;