import "./Infos.css";
import InfoOverlayWithIcon from "../../../webapi/general/InfoOverlayWithIcon";

const DateRangeInfo = () => {
  return (
    <InfoOverlayWithIcon
      infoID="dateRangeInfo"
      title="Click for information about date ranges"
      imageAltText="Information about date ranges"
      overlayContent={
        <div className="info-text-container">
          <h1>Date ranges</h1>
          <p>
            Supported date formats are:
            <br />
            YYYY-MM-DD or YYYYMMDD or YYYY-MM or YYYYMM or YYYY
            <br />
            Either start or end date are optional.
          </p>
          <h2>Publication date</h2>
          <p>
            Publication date is the earliest date a document was published.
            Usually it is the E-publication date.
          </p>
          <h2>Insertion date</h2>
          <p>
            Insertion date is the date a document was processed and first added
            to our system. It can be very different from the publication date,
            e.g. if a new repository was just added to the application.
          </p>
        </div>
      }
    />
  );
};

export default DateRangeInfo;
