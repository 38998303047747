import appLogoLogin from '../../assets/images/bg/logo-dimensions-LC-black_long-h43@2x.png';
import appLogo from '../../assets/images/bg/logo-dimensions-LC-white_long-h43@2x.png';
import loginBGImage from '../../assets/images/bg/shutterstock_707879404_small.44d88a51.44d88a51_min.jpg';

export const APP_PROPERTIES = {
  // --- logos for login page and header --- //
  LOGIN_BG_IMAGE: loginBGImage,

  LOGIN_LOGO_APP: appLogoLogin,
  LOGIN_LOGO_APP_STYLE: { height: 43, width: 'auto', margin: '2px 5px' },

  HEADER_LOGO_APP: appLogo,
  HEADER_LOGO_APP_STYLE: { height: 38, padding: '5px 15px' },

  // --- feedback and support --- //
  SUPPORT_FORM_URL:
    'https://forms.monday.com/forms/312f55bdb5829b7c7f342002021f19b7',

  // --- active pages --- //
  ACTIVE_PAGES: {
    docSearch: true,
    verificationPage: true,
    backendAdministration: true,
  },

  // --- active modules or functionalities --- //
  ACTIVE_FUNCTIONALITIES: {
    showDocumentsOnStart: true,
    fetchSortableFields: true,
    showClinicalTrialFilters: true,
    showGrantsDSFilters: true,
    showPublicationsDSFilters: true,
  },

  // --- chemistry-related settings --- //
  CHEMISTRY: {},
} as const;
