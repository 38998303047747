import { isArrayEmpty } from "../../util";
import {
    BULK_IMPORT_TYPES, QUERY_TERM_TYPE_METADATA, QUERY_TERM_TYPE_TERMLOC,
    QUERY_TERM_TYPE_TERMLOC_QUERY_TERMS, QUERY_TERM_TYPE_BULK_IDS
} from "../../general/docsearch/searchConstants";


export const createFilterTermForAutocompleteTermloc = (filter, values) => {

    if (filter && !isArrayEmpty(values)) {

        const ocids = {};
        values?.forEach(entry => { entry.ocids?.forEach(ocid => ocids[ocid] = true); });
        const freetextTerms = {};
        values?.forEach(entry => { freetextTerms[entry.term] = true });

        const terms = values?.map(entry => entry.term);
        const termsLabel = `${terms.slice(0, 10).join(' OR ')}${terms.length > 10 ? ' OR ...' : ''}`;
        //const termsLabel = `${terms.join(' OR ')}`;

        const filterTerm = {
            type: QUERY_TERM_TYPE_TERMLOC_QUERY_TERMS,
            filterID: filter.id,
            queryPrefix: filter.queryPrefix,
            termloc: filter.termloc,
            domains: filter.domains,
            term: termsLabel,
            freetextTerms: Object.keys(freetextTerms),
            ocids: Object.keys(ocids),
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForFreeInputTermLocation = (filter, value) => {

    if (filter && value) {
        const filterTerm = {
            type: QUERY_TERM_TYPE_TERMLOC_QUERY_TERMS,
            filterID: filter.id,
            queryPrefix: filter.queryPrefix,
            term: value,
            termloc: filter.termloc,
            freetextTerms: [value],
            ocids: [],
            domains: []
        }
        if (filter.fuzziness) {
            filterTerm.fuzziness = filter.fuzziness;
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForMetadata = (filter, value, termLabel, booster, fuzziness) => {
    
    console.log('***************');
    console.log('filter', filter);
    console.log('value', value);
    console.log('termLabel', termLabel);
    console.log('booster', booster);
    console.log('fuzziness', fuzziness);

    if (filter && value) {
        const filterTerm = {
            type: QUERY_TERM_TYPE_METADATA,
            filterID: filter.id,
            extraFieldID: filter.extraFieldID,
            queryPrefix: filter.queryPrefix,
            queryValues: [value],
            term: termLabel ? termLabel : value,
        }
        if (booster || filter.booster) {
            filterTerm.booster = booster ? booster : filter.booster
        }
        if (fuzziness || filter.fuzziness) {
            filterTerm.fuzziness = fuzziness ? fuzziness : filter.fuzziness
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForMetadataMultipleStructuredValues = (filter, termObjects) => {

    if (filter && !isArrayEmpty(termObjects)) {

        const addFuzzinessToSpecificTermsOnly = {};
        const termsDeduplicated = {};
        termObjects.forEach(termVal => {
            termsDeduplicated[termVal.term] = true;
            if (termVal.isManuallyAdded) { 
                addFuzzinessToSpecificTermsOnly[termVal.term] = true; 
            }
        });
        const terms = Object.keys(termsDeduplicated); //termObjects.map(val => val.term);
        const termsLabel = `${terms.slice(0, 10).map(t => addFuzzinessToSpecificTermsOnly[t] ? `~ ${t}` : t).join(' OR ')}${terms.length > 10 ? ' OR ...' : ''}`;

        const filterTerm = {
            type: QUERY_TERM_TYPE_METADATA,
            filterID: filter.id,
            facetID: filter.facetID,
            extraFieldID: filter.extraFieldID,
            queryPrefix: filter.queryPrefix,
            queryValues: terms,
            term: termsLabel,
            addFuzzinessToSpecificTermsOnly: addFuzzinessToSpecificTermsOnly
        }
        if (filter.booster) {
            filterTerm.booster = filter.booster
        }
        if (filter.fuzziness) {
            filterTerm.fuzziness = filter.fuzziness
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForDateRanges = (filter, startDate, endDate) => {

    if (filter && (startDate || endDate)) {
        const startDateStrg = startDate ? startDate : '';
        const endDateStrg = endDate ? endDate : '';
        const dateStrg = `${startDateStrg}${endDateStrg ? `_${endDateStrg}` : ''}`;
        const dateLabelStrg = `${startDateStrg} - ${endDateStrg}`;

        const filterTerm = {
            type: QUERY_TERM_TYPE_METADATA,
            filterID: filter.id,
            queryPrefix: filter.queryPrefix,
            term: dateLabelStrg,
            queryValues: [dateStrg],
        };
        return filterTerm;
    }
    return null;
}

export const createFilterTermForBulkImport = (filter, bulkIDType, bulkIDs) => {
    return createFilterTermForBulkImportV2(filter.id, bulkIDType, bulkIDs);
}
export const createFilterTermForBulkImportV2 = (filterID, bulkIDType, bulkIDs) => {

    if (filterID && bulkIDType && bulkIDs) {
        // TODO: pot error: bulk ID not defined
        const label = BULK_IMPORT_TYPES[bulkIDType] ? BULK_IMPORT_TYPES[bulkIDType].label : bulkIDType;

        const filterTerm = {
            type: QUERY_TERM_TYPE_BULK_IDS,
            filterID: filterID, //'bulkImport', // CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT
            bulkIDType: bulkIDType, // BULK_IMPORT_TYPE_OCDOCID
            bulkIDs: bulkIDs,
            term: `${bulkIDs?.length} ${label}`
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForTermLocation = (filter, value) => {

    if (filter && value) {
        const filterTerm = {
            type: QUERY_TERM_TYPE_TERMLOC,
            filterID: filter.id,
            queryPrefix: filter.queryPrefix,
            term: filter.filterValueLabels ? filter.filterValueLabels[value] : value,
            termloc: value
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForIPCDomain = (filter, values) => {

    if (filter && values) {
        const label = filter.filterValues?.find(fv => fv.filterValue == values)?.filterValueLabel;

        const filterTerm = {
            type: QUERY_TERM_TYPE_METADATA,
            filterID: filter.id,
            queryPrefix: filter.queryPrefix,
            term: label ? label : values,
            queryValues: values?.split(',')?.map(dom => dom.trim())
        }
        return filterTerm;
    }
    return null;
}