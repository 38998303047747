/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Rating } from 'primereact/rating';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkResultAndGetPayload } from '../../../api';
import { createDocumentPartsRequest, fetchAnnotatedDocument } from '../../../api/content/DocumentApi';
import { APP_PROPERTIES, PDF_REPOS } from '../../../properties/index';
import ReadCubePapersLink from '../../common/ReadCube/ReadCubePapersLink';
import { getReadcubePapersIdentifier } from '../../common/ReadCube/readCubePapersUtil';
import AddRemoveDocumentsDropdown from '../docCollection/AddRemoveDocumentsDropdown';
import DocumentBookmark from '../general/DocumentResult/DocumentBookmark';
import { convertInitialLetterToUpperCase, createDocViewUrl, hasUserRole, isArrayEmpty } from '../util';
import DocumentMetadataInfo from './DocumentMetadataInfo';
import './DocumentPartsDialog.css';


class DocumentPartsDialog extends Component {

    constructor() {
        super();
        this.state = {};

        this.growl = createRef();
    }


    fetchDocPartsRelatedData = async () => {
        //console.log('componentDidMount: ', this.props);
        if (this.props.repository && this.props.ocDocID &&
            this.props.documentPart && this.props.internalQuery) {
            const index = this.props.documentParts && this.props.documentPart ? this.props.documentParts.indexOf(this.props.documentPart) : -1;
            await this.loadDocumentPart(index);
        }
    }

    /**
     * Loads specific document part depending on active index of the tab view.
     */
    loadDocumentPart = async (index) => {

        // --- get document part identifier for active tab --- //
        const documentPart = index >= 0 && this.props.documentParts && this.props.documentParts[index] ? this.props.documentParts[index] : null;
        //console.log('=> load document part: ', documentPart)

        if (!documentPart) {
            //this.props.growl.show({ sticky: false, closable: true, severity: 'error', summary: 'Error occurred', detail: 'Could not identify document part to load.' });
            return;
        }

        this.setState({
            fetchingDocumentPart: true,
            docPartsHtml: ''
        });

        // --- create request for annotated document part --- //
        const request = createDocumentPartsRequest(
            this.props.repository, this.props.ocDocID, this.props.internalQuery,
            true, true, true, true, true, 0, true, true);
        request.documentParts = [{ name: documentPart }];
        // @todo
        /* request.documentPartsPreferredLanguages = ["en"]; */

        // --- request respective document part --- //
        const response = await fetchAnnotatedDocument(request);
        const result = checkResultAndGetPayload(response, this.growl);
        //console.log('result ann doc part', result);

        // --- update document part --- //
        if (result && result.annotatedDocument && result.annotatedDocument.namedDocumentRanges) {
            this.setState({
                docPartsHtml: result.annotatedDocument.namedDocumentRanges[documentPart],
            });
        }

        this.setState({
            fetchingDocumentPart: false,
            activeIndex: index,
        });
    }

    // ----------------------------------------------------------------------- //
    // --- add documents to collections -------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Opens dialog to add document to collections. 
     * Sends document ID of this document.
     */
    onOpenAddToCollectionsDialog = () => {

        this.props.onOpenAddToCollectionsDialog([{
            ocDocId: this.props.ocDocID,
            title: this.props.documentData.title
        }]);
    }


    /**
     * Loads document part when tab is changed.
     */
    onViewChange = async (index) => {

        await this.loadDocumentPart(index);
    }


    render() {

        const { displayDialog, documentData, additionalDocumentInfo, onChangeRating, onChangeReadStatus,
            documentParts, repository, ocDocID, organisms, onHide, onOpenAddToCollectionsDialog } = this.props;
        //console.log('doc parts: ', this.props.documentParts);
        //console.log('this.props: ', this.props);

        const rating = additionalDocumentInfo?.rating || 0;
        const read = !!additionalDocumentInfo?.read;
        const collections = additionalDocumentInfo?.documentCollectionList || [];

        const header = <div style={{ background: 'yellow', width: '100%' }}>
            <div style={{ float: 'left' }}>Document preview</div>
            <div style={{ float: 'right' }}>
                <Button
                    title="Open annotated document in new tab"
                    className="p-button-sm primaryButton"
                    style={{ fontSize: 13, padding: '3px 10px', marginRight: 20 }}
                    onClick={e => {
                        let link = createDocViewUrl(repository, ocDocID, { query: this.props.internalQuery, sequenceID: this.props.sequenceID });
                        window.open(link, "_blank");
                    }}>
                    Open document detail page
                </Button>
            </div>
        </div>

        const { type, identifier } = getReadcubePapersIdentifier(this.props.documentData);

        return (
            <>
                <Toast ref={this.growl} />

                <Dialog
                    focusOnShow={false}
                    header={header}
                    visible={displayDialog}
                    className="docPartPreview styledDialog"
                    style={{ width: '65vw', maxWidth: '90vw', minHeight: '65vh', maxHeight: '90vh' }}
                    modal={true}
                    onShow={() => { this.fetchDocPartsRelatedData() }}
                    onHide={(e) => { onHide(e) }}
                    blockScroll>

                    {!!documentData ?
                        <div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 15, paddingTop: 10, marginRight: 0 }}>
                            <div >
                                <div style={{ fontSize: '16px', marginBottom: 10 }} >
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                        href={createDocViewUrl(repository, ocDocID, { query: this.props.internalQuery, sequenceID: this.props.sequenceID })}>
                                        {documentData.title}</a>
                                </div>
                                <DocumentMetadataInfo
                                    repositoryInfo={this.props.repositoryInfo}
                                    documentData={documentData}
                                    organisms={organisms}
                                />

                            </div>
                            <div style={{ padding: '10px 0' }}>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <div className="__valignMiddle" style={{ marginRight: 30 }}>
                                        {hasUserRole("ROLE_READDOCUMENTS") &&
                                            <DocumentBookmark
                                                isRead={read}
                                                repository={repository}
                                                ocDocID={documentData.ocDocId}
                                                title={documentData.title}
                                                onChangeReadStatus={onChangeReadStatus}
                                            />}
                                    </div>

                                    <Rating value={rating}
                                        className="valignMiddle"
                                        id="libraryRating"
                                        title="Your rating for this document"
                                        //cancel={!this.props.ratingReadOnly}
                                        style={{ marginRight: 30 }}
                                        onChange={(e) => onChangeRating(repository, documentData.ocDocId, documentData.title, e.value || 0)}
                                    />
                                </div>
                                {!!documentData.groupedDocsTotalCount && documentData.groupedDocsTotalCount > 0 ?
                                    <>
                                        <a id={`famMembers`}
                                            className="valignMiddle"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${createDocViewUrl(repository, ocDocID, { query: this.props.internalQuery, sequenceID: this.props.sequenceID })}/5`}
                                            style={{ marginRight: 30 }}>
                                            {this.props.patentFamilyLabel} members:
                                            <span id={`numOfFamMembers`}>{documentData.groupedDocsTotalCount}</span>
                                        </a>
                                    </> : null
                                }
                                {onOpenAddToCollectionsDialog && hasUserRole('ROLE_LIBRARY') ?
                                    <>
                                        <label className="valignMiddle" style={{ marginRight: 5 }}>In collections:</label>
                                        <AddRemoveDocumentsDropdown
                                            inCollections={collections}
                                            placeholder="none"
                                            onAddToCollections={this.props.onAddToCollections}
                                            onRemoveFromCollections={this.props.onRemoveFromCollections}
                                            userData={this.props.userData}
                                        />
                                    </>
                                    : null}

                                {identifier && APP_PROPERTIES.INCLUDE_READCUBE &&
                                    <span id={`readcubeLinks`}
                                        style={{ marginLeft: 20 }}>
                                        <ReadCubePapersLink
                                            type={type}
                                            identifier={identifier}
                                            compactView={true}
                                        />
                                    </span>}
                            </div>
                        </div> : null
                    }

                    {!isArrayEmpty(documentParts) ?
                        <div style={{ paddingLeft: 25, paddingRight: 30 }}>
                            <TabView
                                activeIndex={this.state.activeIndex}
                                renderActiveOnly={true}
                                onTabChange={(e) => this.onViewChange(e.index)}>
                                {documentParts.map(docPartID => {
                                    return (
                                        <TabPanel key={docPartID}
                                            header={docPartID === 'dsabstract' ? 'Abstract' : convertInitialLetterToUpperCase(docPartID)}
                                        //header={convertInitialLetterToUpperCase(docPartID)}
                                        >
                                            <div>
                                                {this.state.fetchingDocumentPart ?
                                                    <div>{`Fetching preview...`}</div>
                                                    :
                                                    this.state.docPartsHtml ?
                                                        this.state.docPartsHtml.map(docPart => {
                                                            return (
                                                                <div id="annTextContentStyled"
                                                                    key={docPart.startOffset}
                                                                    className={PDF_REPOS[this.props.repositoryInfo?.name] ? 'pdfRepo' : ''}>
                                                                    <div dangerouslySetInnerHTML={{ __html: `${docPart.rangeDocument}` }} />
                                                                </div>
                                                            );
                                                        })
                                                        :
                                                        <div>{`Not found.`}</div>
                                                }
                                            </div>
                                        </TabPanel>)
                                })}
                            </TabView>
                        </div>
                        :
                        <div style={{ padding: '0 20px 20px 20px' }}>
                            <hr />
                            No document parts available for preview.
                        </div>
                    }
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.user.data
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentPartsDialog))