import * as ActionTypes from '../actions'

const initUserData = {
    userDetails: {
        forename: '',
        lastName: '',
        email: '',
        highestAdminType: '',
        department: {
            settingsModel: {
                selectedRepositories: []
            },
            // looks like something changed in init userData. I have to add additional 'selectedRepositories' field to prevent
            //  selectedRepositories = undefined in Biomarker tool
            selectedRepositories: [],
        },
        userApps: [],
        userRepositories: [],
        adminApps: [],
        company: {}
    },
    permissions: {}
}

const initState = {
    data: initUserData,
    failed: false,
    authenticated: !!localStorage.token,
    fetching: null
}

const user = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                failed: false,
            }
        case ActionTypes.IS_NOT_AUTHENTICATED:
            return {
                ...state,
                authenticated: false,
                data: initUserData,
            }
        case ActionTypes.IS_AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
                failed: false,
                data: action.userData
            }
        case ActionTypes.LOGIN_FAILED:
            return {
                ...state,
                failed: true
            }
        case ActionTypes.START_FETCHING:
            return {
                ...state,
                fetching: true
            }
        case ActionTypes.DONE_FETCHING:
            return {
                ...state,
                fetching: false
            }
        case ActionTypes.UPDATE_USER_DETAILS:
            return {
                ...state,
                data: {
                    ...state.data,
                    userDetails: {
                        ...state.data.userDetails,
                        forename: action.userDetails.forename,
                        lastName: action.userDetails.lastName,
                        email: action.userDetails.email,
                    }
                }
            }
        case ActionTypes.UPDATE_USER_APPS:
            return {
                ...state,
                data: {
                    ...state.data,
                    userDetails: {
                        ...state.data.userDetails,
                        userApps: action.userApps
                    }
                }
            }
        case ActionTypes.UPDATE_USER_REPOSITORIES:
            return {
                ...state,
                data: {
                    ...state.data,
                    userDetails: {
                        ...state.data.userDetails,
                        userRepositories: action.userRepositories
                    }
                }
            }
        case ActionTypes.UPDATE_ADMIN_APPS:
            return {
                ...state,
                data: {
                    ...state.data,
                    userDetails: {
                        ...state.data.userDetails,
                        adminApps: action.adminApps
                    }
                }
            }
        case ActionTypes.START_FETCHING_TOKEN:
            return {
                ...state,
                fetchingToken: true
            }
        case ActionTypes.DONE_FETCHING_TOKEN:
            return {
                ...state,
                fetchingToken: false
            }
        case ActionTypes.MINUTES_LEFT_IN_SESSION:
            return {
                ...state,
                minutesLeftInSession: action.minutesLeftInSession
            }
        default:
            return state
    }
}

export default user
