import { sendApiRequest } from "..";
import { APP_PROPERTIES } from "../../properties";


export const fetchUserShareStatus = async () => {
    //const response = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/department/user/list`);
    const response = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/users`);
    return response;
};

export const fetchUserShareStatusBlocklists = async () => {
    //const response = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/department/user/list`);
    const response = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/department/users`);
    return response;
};

export const fetchAlertsIntervals = async () => {
    const response = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications/intervals`);
    return response;
};

export const addQueryAlert = async (queryID, active, interval, nextSendingDate, repositories, updateRepositories = true) => {
    const req = {
        queryId: queryID,
        active: active,
        interval: interval,
        nextSendingDate: nextSendingDate,
        repositories: repositories,
        updateRepositories: updateRepositories,
    }

    const response = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications`, req);
    return response;
};

export const updateQueryAlert = async (alertID, queryID, active, interval, nextSendingDate, repositories, updateRepositories = true) => {
    const req = {
        queryId: queryID,
        active: active,
        interval: interval,
        nextSendingDate: nextSendingDate,
        repositories: repositories,
        updateRepositories: updateRepositories,
    }

    const response = await sendApiRequest('PUT', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${alertID}`, req);
    return response;
};

export const deleteQueryAlert = async (alertID) => {
    const response = await sendApiRequest('DELETE', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${alertID}`);
    return response;
};