import { FILTER_MODE_INTERSECTION } from '../../advancedSearch/components/toolbarFilterQueries/FilterQueryTab';
import { isArrayEmpty } from '../../util';
import { BASE_URL_DOI } from '../../util/externalLinks';
import {
    BULK_IMPORT_TYPE_DOI, BULK_IMPORT_TYPE_PMCID, FREETEXT_EXACT, FREETEXT_WITH_VARIANTS,
    QUERY_PREFIX_FACETFILTER, QUERY_PREFIX_EXTRAFIELD, QUERY_TERM_TYPE_BULK_IDS, QUERY_TERM_TYPE_METADATA,
    QUERY_TERM_TYPE_TERMLOC, QUERY_TERM_TYPE_TERMLOC_QUERY_TERMS, SEARCH_FILTER_ID_DOI, SEARCH_FILTER_ID_PMCID,
    QUERY_PREFIX_EXTRAFIELD_TEXT, QUERY_PREFIX_EXTRAFIELD_EXACT, WILDCARD_ASTERISK
} from '../../general/docsearch/searchConstants';


export const removeQuotesAndTrimText = (text) => {
    return text ? `${text.trim()}`.replace(/"|“|’|'/g, '') : '';
}

const convertMetadataGroupToQueryString = (metadataGroup) => {
    //console.log('metadataGroup: ', metadataGroup);
    if (metadataGroup.queryPrefix && !isArrayEmpty(metadataGroup.queryValues)) {

        const addFuzziness = metadataGroup.fuzziness;
        const addFuzzinessToSpecificTermsOnly = !!metadataGroup.addFuzzinessToSpecificTermsOnly;

        let metadataGroupString = metadataGroup.queryValues.map(qVal => {
            // --- convert dates or numbers to string --- //
            let qTerm = removeQuotesAndTrimText(qVal);

            const booster = metadataGroup.booster ? `^${metadataGroup.booster}` : '';
            let prefix = metadataGroup.queryPrefix;
            let fuzziness = '';

            if (metadataGroup.queryPrefix === QUERY_PREFIX_FACETFILTER) {
                qTerm = `${metadataGroup.facetID}:${qTerm}`;
            }
            else if (metadataGroup.queryPrefix === QUERY_PREFIX_EXTRAFIELD) {
                if (addFuzziness) {
                    prefix = `${QUERY_PREFIX_EXTRAFIELD_TEXT}${metadataGroup.extraFieldID}`;
                    if (addFuzzinessToSpecificTermsOnly) {
                        fuzziness = metadataGroup.addFuzzinessToSpecificTermsOnly[qVal] ? `~${metadataGroup.fuzziness}` : '~0';
                    }
                    else {
                        fuzziness = `~${metadataGroup.fuzziness}`;
                    }
                }
                else {
                    prefix = `${QUERY_PREFIX_EXTRAFIELD_EXACT}${metadataGroup.extraFieldID}`;
                }
            }
            else {
                if (addFuzziness) {
                    if (addFuzzinessToSpecificTermsOnly) {
                        fuzziness = metadataGroup.addFuzzinessToSpecificTermsOnly[qVal] ? `~${metadataGroup.fuzziness}` : '~0';
                    }
                    else {
                        fuzziness = `~${metadataGroup.fuzziness}`;
                    }
                }
            }

            if (metadataGroup.filterID === SEARCH_FILTER_ID_DOI) {
                //console.log('DOI: ', qTerm);
                qTerm = fixDOI(qTerm);
            }
            else if (metadataGroup.filterID === SEARCH_FILTER_ID_PMCID) {
                qTerm = fixPMCID(qTerm);
            }
            return `${prefix}:"${qTerm}"${fuzziness}${booster}`;
        }
        ).join(' ');
        metadataGroupString = metadataGroup.queryValues.length > 1 ? `(${metadataGroupString})` : `${metadataGroupString}`;
        return metadataGroupString;
    }
    return null;
}

const convertBulkImportGroupToQueryString = (bulkImportGroup) => {
    if (bulkImportGroup.bulkIDType && !isArrayEmpty(bulkImportGroup.bulkIDs)) {
        let bulkImportGroupString = bulkImportGroup.bulkIDs.map(bulkID => {
            let id = bulkID;
            if (bulkImportGroup.bulkIDType === BULK_IMPORT_TYPE_DOI) {
                id = fixDOI(id);
            }
            else if (bulkImportGroup.bulkIDType === BULK_IMPORT_TYPE_PMCID) {
                id = fixPMCID(id);
            }
            return `${bulkImportGroup.bulkIDType}:"${id}"`;
        }).join(' ');
        bulkImportGroupString = bulkImportGroup.bulkIDs.length > 1 ? `(${bulkImportGroupString})` : `${bulkImportGroupString}`;
        return bulkImportGroupString;
    }
    return null;
}

const fixDOI = (value) => {
    return value ? value.replace(BASE_URL_DOI, '') : '';
}

const fixPMCID = (value) => {
    return value ? value.startsWith('PMC') ? value : `PMC${value}` : '';
}

const createIncreaseRecallString = (group) => {
    return group?.queryValues?.map(term => {
        if (term !== WILDCARD_ASTERISK) {
            const qTerm = `${term}`;
            return `${FREETEXT_EXACT.prefix}:"${removeQuotesAndTrimText(qTerm)}"`;
        }
        return '';
    }).join(' ');
}

/**
 * Converts list of query concepts into query string with backend syntax.
 * 
 * @param {Array} array of query concepts 
 */
export const convertQueryTermsToQueryString = (queryTerms, combineGroupsOperator = "or", increaseRecall = false) => { // , groupByPatentFamily

    let queryString = '';
    let bulkImportStrings = [];

    let termlocFilter = null;

    if (!isArrayEmpty(queryTerms)) {
        const conceptAndTermGroups = [];
        const groups = [];
        // --- concepts/terms/filters within a group will be OR-combined (separated by blank) --- //
        // --- groups of concepts/terms/filters will be either AND- or OR-combined -------------- //
        // --- (separated by blank, preceeded by +) ------------------------------- //
        queryTerms.forEach(group => {
            // --- metadata filter --- //
            if (group.type === QUERY_TERM_TYPE_METADATA) {
                const metadataGroupString = convertMetadataGroupToQueryString(group);
                if (metadataGroupString) {
                    //console.log('metadataGroupString: ', metadataGroupString);
                    groups.push(metadataGroupString);
                }
            }
            else if (group.type === QUERY_TERM_TYPE_TERMLOC) {
                termlocFilter = group;
            }
            else if (group.type === QUERY_TERM_TYPE_TERMLOC_QUERY_TERMS) {
                //console.log('termlocQueryTerms: ', group);
                // --- add OCIDs --- //
                let conceptsGroupString = group.ocids.map(ocid => { return `ocid:${ocid}` }).join(' ');
                // --- add terms --- //
                const fuzziness = group.fuzziness ? `~${group.fuzziness}` : '';
                const booster = group.booster ? `^${group.booster}` : '';
                conceptsGroupString += `${conceptsGroupString ? ' ' : ''}` + group.freetextTerms.map(term => {
                    const qTerm = `${term}`;
                    return `${FREETEXT_WITH_VARIANTS.prefix}:"${removeQuotesAndTrimText(qTerm)}"${fuzziness}${booster}`;
                }).join(' ');
                // --- add term location --- //
                if (group.termloc) {
                    conceptsGroupString = `(termloc:${group.termloc} ${conceptsGroupString})`;
                }
                else {
                    conceptsGroupString = (group.ocids.length + group.freetextTerms.length) > 1 ? `(${conceptsGroupString})` : `${conceptsGroupString}`;
                }
                //console.log('conceptsGroupString: ', conceptsGroupString);
                groups.push(conceptsGroupString);
            }
            // --- bulk import IDs --- //
            else if (group.type === QUERY_TERM_TYPE_BULK_IDS) {
                const bulkImportGroupString = convertBulkImportGroupToQueryString(group);
                if (bulkImportGroupString) {
                    bulkImportStrings.push(bulkImportGroupString);
                }
            }
            // --- if there are OCIDs -> use OCIDs --- //
            else if (!isArrayEmpty(group.ocids)) {
                let conceptsGroupString = group.ocids.map(ocid => { return `ocid:${ocid}`; }).join(' ');
                if (increaseRecall) {
                    conceptsGroupString = [conceptsGroupString, createIncreaseRecallString(group)].join(' ');
                }
                conceptsGroupString = group.ocids.length > 1 || increaseRecall ? `(${conceptsGroupString})` : `${conceptsGroupString}`;
                conceptAndTermGroups.push(conceptsGroupString);
            }
            // --- otherwise check if there are concept or free text domains --- //
            else if (!isArrayEmpty(group.domains)) {
                //console.log('HERE domains: ', group.domains);
                // TODO: multiple terms
                if (group.domains.includes(FREETEXT_EXACT.prefix)) {
                    conceptAndTermGroups.push(`${FREETEXT_EXACT.prefix}:"${removeQuotesAndTrimText(group.term)}"`);
                }
                // TODO: multiple terms
                else if (group.domains.includes(FREETEXT_WITH_VARIANTS.prefix)) {
                    conceptAndTermGroups.push(`${FREETEXT_WITH_VARIANTS.prefix}:"${removeQuotesAndTrimText(group.term)}"`);
                }
                else {
                    // ??? only first domain ???
                    let termsGroupString = group.queryValues.map(t => { return `${group.domains[0]}:"${removeQuotesAndTrimText(t)}"`; }).join(' ');
                    if (increaseRecall) {
                        termsGroupString = [termsGroupString, createIncreaseRecallString(group)].join(' ');
                    }
                    termsGroupString = group.queryValues.length > 1 || increaseRecall ? `(${termsGroupString})` : `${termsGroupString}`;
                    conceptAndTermGroups.push(termsGroupString);
                }
            }
        })

        let queryStringConceptsAndTerms = '';
        if (!isArrayEmpty(conceptAndTermGroups)) {
            queryStringConceptsAndTerms = combineGroupsOperator === "and" ?
                `+${conceptAndTermGroups.join(' +')}` : conceptAndTermGroups.join(' ');
        }
        // --- filters are always AND-combined --- //
        let queryStringFilters = '';
        if (!isArrayEmpty(groups)) {
            queryStringFilters = `+${groups.join(' +')}`;
        }

        const qsTermloc = termlocFilter ? `+termloc:${termlocFilter.termloc}` : '';
        const qsConceptsAndTerms = queryStringConceptsAndTerms ? `+(${queryStringConceptsAndTerms})` : '';
        const qsFilters = queryStringFilters ? `+(${queryStringFilters})` : '';
        //const qsPatFam = groupByPatentFamily ? `+groupby:"${groupByPatentFamily}"^100` : '';

        queryString = `${qsTermloc} ${qsConceptsAndTerms} ${qsFilters}`.trim();
    }

    const searchObject = { query: queryString || '' };
    if (!queryString) {
        if (!isArrayEmpty(bulkImportStrings)) {
            searchObject.query = bulkImportStrings.shift();
        }
    }
    if (!isArrayEmpty(bulkImportStrings)) {
        searchObject.filterQueries = bulkImportStrings.map(bulkImportStrg => {
            return {
                query: bulkImportStrg,
                filterMode: FILTER_MODE_INTERSECTION,
            }
        })
    }
    // console.log('searchObject2', searchObject);
    return searchObject;
}