import { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import './Export.css'
import { EXPORT_DESCRIPTION, EXPORT_TITLE, SORT_OPTION } from './core/constant';
import ExportGroupItem from './components/ExportGroupItem';
import _ from 'lodash';


const ExportGroup = (props) => {

    const [categories, setCategories] = useState(props.categories)
    const [selectedOption, setSelectedOption] = useState('count')
    const [selectedCategory, setSelectedCategory] = useState('')

    const onSortExports = (e) => {
        setSelectedOption(e.value)
        setCategories(_.orderBy(props.categories, e.value, [e.value !== 'title' ? 'desc' : 'asc']))
    }

    const onSearch = (e) => {
        setCategories(props.categories?.filter((item) => item?.title?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
    }

    useEffect(() => {
        setCategories(props.categories)
        //console.log(selectedCategory,categories)
    }, [props.categories])


    const handleExportChange = (e, list) => {
        e.stopPropagation()
        setSelectedCategory(list)
        props.handleExportChange(list)
    }

    return (
        <div className="col-12 grid-nogutter p-0" style={{ paddingLeft: 5 }}>
            <div className='col-12 px-0 pt-0' >
                <div className='col-12 p-0'>
                    <div className='col-12 pt-0'>
                        <div className='col-12 ex-gr-header pl-0' style={{ paddingTop: 5 }}>
                            <h1 className="pageHeader">Export collections</h1>
                        </div>
                        <div className='grid ex-header-container' >
                            <div className="col-12" >
                                <i className="material-icons search-icon"
                                    title='Export collections'>
                                    {'search'}
                                </i>
                                <InputText
                                    type="search"
                                    className="collectionFilter ex-gr-seach"
                                    onInput={onSearch}
                                    placeholder="Export collections"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 py-0 pl-0 pr-10' >
                        <div className='col-12 ex-gr-sort-container' >
                            <label >Name</label>
                            <div >
                                <label className='mr-0' >Sort by: </label>
                                <Dropdown
                                    className='dropdownNoBorder mr-5'
                                    appendTo={document.body}
                                    options={SORT_OPTION}
                                    value={selectedOption}
                                    onChange={(e) => onSortExports(e)}
                                />
                                <span className='ml-0' >
                                    <label className='mr-15'>Entries</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 pt-0 pr-10 pl-0' >
                        <ExportGroupItem
                            key={EXPORT_TITLE}
                            handleExportChange={handleExportChange}
                            category={{
                                title: EXPORT_TITLE,
                                description: EXPORT_DESCRIPTION,
                                count: props.allExportLength
                            }}
                            style={{ background: `${(selectedCategory === '' || selectedCategory === EXPORT_TITLE || props.allExportLength === 0) ? '#e8f3fc' : ''}` }}
                        />
                        {
                            categories?.map(category => {
                                return <ExportGroupItem key={category.title}
                                    handleExportChange={handleExportChange}
                                    category={category}
                                    selectedCategory={selectedCategory}
                                />

                                    })
                                }
                            </div>
                        </div>
            </div>
        </div >
    )
}

export default ExportGroup
