import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

class LogoutUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {

        const footerLogoutUser = (
            <div>
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelLogoutUser}
                />
                <Button label="Sign out"
                    className='p-button-sm primaryButton'
                    onClick={this.props.onLogoutUser}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayLogoutDialog} style={{ 'width': "40vw" }} header="Sign out" focusOnShow={false}
                modal={true} footer={footerLogoutUser} dismissableMask={false} //blockScroll
                onHide={() => this.props.onCancelLogoutUser()} className='styledDialog'>
                <div style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 10 }}>
                    <span>Warning! You moved yourself to a different organization/suborganization.
                     Hence, specific settlings like available features, repositories or domains may have changed.
                      It is strongly recommended to sign out and in again to make changes visible.</span>
                </div>
            </Dialog>
        )
    }
}

export default LogoutUser