import moment from "moment";

export const createSearchOption = (startDate, endDate, period) => {
    const start = moment(startDate);
    const end = moment(endDate);

    switch (period) {
        case 'yearly':
            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();
            const nyears = endYear - startYear + 1;

            return {endYear, nyears};
        case 'monthly':
            //Difference in number of months;
            const monthCount = Math.ceil(moment.duration(end.diff(start)).asMonths()) || 1;
            return {endDate: moment(endDate).format("YYYY-MM-DD"), ntime: `${monthCount}m`};
        case 'weekly':
            //Difference in number of days;
            const weekCount = Math.ceil(moment.duration(end.diff(start)).asWeeks()) || 1;
            return {endDate: moment(endDate).format("YYYY-MM-DD"), ntime: `${weekCount}w`};
        default:
            return
    }
}
