/* eslint-disable jsx-a11y/anchor-is-valid */
import { PureComponent } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { isArrayEmpty } from '../../util';
import infoIcon from '../../../../assets/images/icons/info/dimensions-icon-info.png';
import { APP_PROPERTIES } from '../../../../properties';
import { fetchOriginalSourceDocument } from '../../../../api/content/DocumentApi';
import { checkResultAndGetPayload } from '../../../../api';
import { BASE_URL_PUBMED } from '../../util/externalLinks';

const TEST1 = false;
const TEST2 = false;

class DocumentLinkoutButton extends PureComponent {


    openExternalLink = (url) => {
        window.open(url, '_blank');
    }

    downloadOriginalFile = async (repositoryID, repositoryName, ocDocID) => {
        this.setState({ downloadingFile: true });

        const response = await fetchOriginalSourceDocument(repositoryID, repositoryName, ocDocID);
        const result = checkResultAndGetPayload(response, this.growl);
        if (result) {
            if (result.fileContent && result.filename) {
                const fileType = result.filename.split('.').pop();
                const linkSource = `data:application/${fileType};base64,${result.fileContent}`;
                const downloadLink = document.createElement('a');
                downloadLink.href = linkSource;
                downloadLink.target = '_self';
                downloadLink.download = result.filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
            }
            else if (result.processState?.errorMsg) {
                this.growl.show({ severity: 'error', summary: 'An error occurred', detail: result.processState.errorMsg, life: 10000 });
            }
            else {
                this.growl.show({ severity: 'error', summary: 'An error occurred', detail: 'The document could not be downloaded due to an unknown error.', life: 10000 });
            }
        }

        this.setState({ downloadingFile: false });
    }

    render() {
        const { docMetadata, ocDocID, repositoryInfo } = this.props;

        // Add to library button  works for dspub 1002455454

        //console.log('docMetadata: ', docMetadata);
        //console.log('repositoryInfo: ', repositoryInfo);

        if (TEST1) {
            return <span class='__dimensions_badge_embed__' data-doi='10.1001/jama.2016.9797'></span>;
        }

        if (TEST2) {
            return <div>
                <div>Test1</div>
                <span
                    className='__readcube-access-button'
                    data-bt='readcube_access_button'
                    data-doi='10.1038/nature10414'
                    //data-doi='10.6026/973206300171134'
                    //data-pmcid='PMC8900169'
                    //data-doi='10.1038/nature10414'
                    //data-pmid='33683025'
                    //data-pmid=''
                    data-large='true'
                    readcube-loader-injected='true'
                //data-oa-url='https://www.bioinformation.net/017/973206300171134.pdf'
                //data-oa-url='https://scialert.net/qredirect.php?doi=pjbs.2021.1.12&amp;linkid=pdf'
                >
                </span>
                <div>Test2</div>
            </div>
        }

        let url = null;
        let label = 'Source link';
        let infoIconText;

        if (docMetadata) {

            // TEST other IDs: docview/10/1014261126 (dspub)
            if (APP_PROPERTIES.INCLUDE_READCUBE && repositoryInfo?.name === 'dspub' &&
                (!isArrayEmpty(docMetadata.doi) || !isArrayEmpty(docMetadata.pmid) || !isArrayEmpty(docMetadata.pmc))) {
                const doi = docMetadata.doi && docMetadata.doi[0] ? docMetadata.doi[0] : '';
                const pmid = docMetadata.pmid && docMetadata.pmid[0] ? docMetadata.pmid[0] : '';
                const pmcid = docMetadata.pmc && docMetadata.pmc[0] ? docMetadata.pmc[0] : '';
                return <div>
                    <div key={`accessBtn`}>
                        <span className='__readcube-access-button width100perc'
                            data-doi={doi}
                            data-pmid={pmid}
                            data-pmcid={pmcid}
                            //data-arxiv=
                            data-large='true'
                        ></span>
                    </div>
                    <div key={`libraryBtn`} style={{ marginTop: 10 }}>
                        <span className='__readcube-library-button width100perc'
                            data-doi={doi}
                            data-pmid={pmid}
                            data-pmcid={pmcid}
                            //data-arxiv=
                            data-large='true'
                        ></span>
                    </div>
                </div>
            }
            else if (repositoryInfo?.name === 'dsm_labdocs') {
                return (
                    <Button
                        key='origFileDownloadBtn'
                        className='primaryButton linkout-button p-button-sm width100perc valignMiddle'
                        style={{ padding: '5px 10px', width: '100%' }}
                        label='Download original file'
                        onClick={e => this.downloadOriginalFile(repositoryInfo.id, repositoryInfo.name, ocDocID)}
                    />
                );
            }
            else if (repositoryInfo?.name !== 'patdocdb' && !isArrayEmpty(docMetadata['Pat_document-id'])) {
                let patID = docMetadata['Pat_document-id'][0];
                var regex = new RegExp('(^[A-Z]{2})-?0+([0-9]+)-?(.*$)?');
                var match = regex.exec(patID);
                if (match) {
                    const part1 = match[1];
                    const part2 = match[2];
                    const part3 = match[3];
                    // console.log('part1', part1);
                    // console.log('part2', part2);
                    // console.log('part3', part3);
                    patID = `${part1}-${part2}${part3 ? `-${part3}` : ''}`;
                }
                // console.log('patID*', patID);

                url = `https://app.dimensions.ai/downloads/patents?ucid=${patID}`;
                label = 'Full text';
                // https://app.dimensions.ai/downloads/patents?ucid=US-7999973-B2
                /*
                const patNo = docMetadata['Pat_document-id'][0].replaceAll('-', '');
                url = `https://patents.google.com/patent/${patNo}`;
                label = 'Google Patents';
                infoIconText = 'New documents may not be added to Google Patents yet. This can take up to a few weeks.';
                */
            }
            else if (!isArrayEmpty(docMetadata.linksAll)) {
                url = `${docMetadata.linksAll[0]}`;
            }
            else if (!isArrayEmpty(docMetadata.doi)) {
                const doiEncoded = encodeURI(`${docMetadata.doi[0]}`);
                url = `${BASE_URL_PUBMED}?term=${doiEncoded}`;
                label = 'PubMed link';
            }
            else {
                return null;
            }
            /*
            if (!isArrayEmpty(docMetadata.trialid)) {
                url = `https://clinicaltrials.gov/ct2/show/${docMetadata.trialid[0]}`;
            }
            else if (!isArrayEmpty(docMetadata.trialid)) {
                url = `https://clinicaltrials.gov/ct2/show/${docMetadata.trialid[0]}`;
            }
            */
        }

        //console.log('url: ', url);

        return url ?
            <>
                <Button
                    className='primaryButton linkout-button p-button-sm width100perc valignMiddle'
                    style={{ padding: '5px 10px', width: infoIconText ? 'calc(100% - 30px)' : '100%' }}
                    label={label}
                    onClick={e => this.openExternalLink(url)}
                >
                </Button>
                {infoIconText ? <>
                    <a title='Click for information about linkout'
                        className='infoIconLink valignMiddle'
                        onClick={e => this.linkoutInfo.toggle(e)}
                        style={{ width: '20px', marginLeft: 10 }}>
                        <img src={infoIcon} alt='Linkout information' />
                    </a>
                    <OverlayPanel
                        ref={(el) => this.linkoutInfo = el}
                        appendTo={document.body}
                        style={{ maxWidth: '40vw', minWidth: 300, fontSize: 13 }}
                        className='whiteSpaceBreakSpaces'
                        dismissable={true}>
                        {infoIconText}
                    </OverlayPanel>
                </> : null
                }
            </> : null;
    }
}

export default DocumentLinkoutButton;