/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import * as Actions from '../../redux/actions';
import { connect } from 'react-redux';
import { doLogin, doLoginSAML } from '../../redux/actions/UserActions';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { APP_PROPERTIES, FUNCTION_ROLES } from '../../properties/index';
import { getCurrentYear } from '../webapi/util';
import './Login.css';

class Login extends Component {

	state = {
		username: '',
		password: '',
		activeIndex: 0,
		showOneClickFirst: true
	}


	UNSAFE_componentWillMount() {
		//console.log(this.props)

		/*localStorage.setItem('token', 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJndWVzdCIsImV4cCI6MTYxMTIzMTA2NX0.DqLdBG8Yp8sx-Kh5ykjaNDAT5jfAsf13ZWfYoXd_hbXMe-lNwhjDGHiPpTc9upWp6hkBpFL-micXS879suyOjw')
		let tok = localStorage.getItem('token')
		console.log(this.props.history.location.pathname)
		this.props.doLoginSAML(this.props.history, tok, this.props.history.location.pathname)*/

		// ???
		// --- if token is found in properties -> SAML login --- //
		if (this.props.token) {
			//console.log("token",this.props.token)
			this.props.doLoginSAML(this.props.history, this.props.token)
		}
		// --- check if user has token stored in local storage and is authenticated  --- //
		// --- -> redirect to home page --- //
		else if (localStorage.token && this.props.authenticated) {
			this.homepage = ''
			const tokenParts = localStorage.token.split(".");
			const tokenPayload = JSON.parse(atob(tokenParts[1]));
			const tokenLanding = tokenPayload.landing;
			if (tokenLanding) {
				let hasLanding = false
				tokenPayload.rol.forEach(role => {
					if (role.toLowerCase().includes(tokenLanding)) {
						FUNCTION_ROLES.forEach(role => {
							if (role.name === tokenLanding) {
								this.homepage = role.url
								hasLanding = true
							}
						})
					}
				})
				if (hasLanding === false) {
					this.homepage = APP_PROPERTIES.HOME_PAGE
				}
			} else {
				this.homepage = APP_PROPERTIES.HOME_PAGE
			}

			this.props.history.push(this.homepage);
		}
	}

	componentDidMount() {
		if (this.props.error) {
			//console.log(this.props.error)
			this.showLoginFailedGrowl(this.props.error)
		}
	}

	handleLogin = (event) => {
		event.preventDefault();
		this.props.doLogin(this.state.username, this.state.password, this.props.history, this.showLoginFailedGrowl, false)
	}

	handleForceLogin = (event) => {
		event.preventDefault();
		this.props.doLogin(this.state.username, this.state.password, this.props.history, this.showLoginFailedGrowl, true);
	}

	showLoginFailedGrowl = (errorMsg) => {
		let msg = { sticky: false, closable: true, severity: 'error', summary: 'Login failed!', detail: errorMsg, life: 6000 };
		this.growl.show(msg);
	}

	handleSAMLLogin() {
		window.location = APP_PROPERTIES.SAML_URL;
	}

	handleForgotPassword() {
		console.log("forgot")
	}

	handleRegisterClick = (e) => {
		window.open(`https://forms.monday.com/forms/0879173367add73fa8493c260aa4c43a`, "_blank")
	}

	onActiveIndexChange = (index) => {
		if (index !== null) {
			if (this.state.showOneClickFirst) {
				this.setState({
					showOneClickFirst: false
				})
			} else {
				this.setState({
					showOneClickFirst: true
				})
			}
		}
	}

	render() {

		//console.log(APP_PROPERTIES.LOGIN_LOGO_APP)
		//console.log(APP_PROPERTIES.APP_ID)

		let loginContent = <React.Fragment>
			<div className="col-12" style={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}>
				{APP_PROPERTIES.SAML_URL ?
					<div style={{ textAlign: 'center', paddingBottom: 10, marginTop: -10 }} //className="p-float-label"
					>{APP_PROPERTIES.APP_ID === 'animalhealth' ? 'iMart credentials' : APP_PROPERTIES.APP_ID === 'bon' ? 'DSM credentials'
						: 'Credentials'}</div>
					: null
				}
				<span //className="p-float-label"
				>
					<InputText id="username-input"
						placeholder='Your username/email'
						className='form-login-width'
						autoComplete="off"
						style={{ backgroundColor: 'white' }}
						type="text"
						value={this.state.username}
						onChange={(event) => this.setState({ username: event.target.value })}
						size="30" />
					{/*<label htmlFor="username-input">Your username/email</label>*/}
				</span>
			</div>
			<div className="col-12" style={{ paddingTop: 5, paddingLeft: 0, paddingRight: 0 }}>
				<span //className="p-float-label"
				>
					<InputText id="password-input"
						placeholder='Password'
						autoComplete="off"
						className='form-login-width'
						type="password"
						style={{ backgroundColor: 'white' }}
						value={this.state.password}
						onChange={(event) => this.setState({ password: event.target.value })}
						size="30" />
					{/*<label htmlFor="password-input">Password</label>*/}
				</span>
			</div>
			<div className="col-12" style={{ paddingTop: 0, paddingBottom: !!APP_PROPERTIES.SAML_URL ? 20 : 10, paddingLeft: 0, paddingRight: 0, textAlign: 'center' }}>
				{/*<Button label="Sign in" icon="pi-md-person" onClick={() => { window.location = "/#" }} />*/}
				{APP_PROPERTIES.ACTIVE_PAGES.verificationPage &&
					<Link to='/forgotpw' style={{ display: 'inline-block', paddingTop: 13, color: '#0973c0', float: 'left' }}>Forgot password</Link>
				}
				{APP_PROPERTIES.APP_ID === 'sciwalker_test' || APP_PROPERTIES.APP_ID === 'sciwalker' ?
					<React.Fragment>
						<a style={{ display: 'inline-block', paddingTop: 13, color: '#0973c0', float: !APP_PROPERTIES.ACTIVE_PAGES.verificationPage ? 'left' : '' }} onClick={(e) => this.handleRegisterClick(e)}>
							Request registration
						</a>
					</React.Fragment>
					: null}
				<Button className="primaryButtonLogin p-button-sm"
					id="signin"
					//disabled={this.state.username !== '' && this.state.password !== '' ? false : true}
					label="Sign in"
					title="Sign in"
					icon="pi pi-arrow-right"
					iconPos='right'
					type='submit'
					style={{
						//color: '#757575', background: '#d8d8d8', border: '1px solid #d1d1d1', 
						fontSize: 16, float: 'right'
					}} />
			</div>
			{
				APP_PROPERTIES.INCLUDE_FRESHDESK ?
					<div className="col-12" style={{ color: '#0973c0', textAlign: 'center' }}>
						<a onClick={(e) => {
							if (this.state.freshworkOpened) {
								window.FreshworksWidget('hide');
								window.FreshworksWidget('close');
								this.setState({ freshworkOpened: false });
							}
							else {
								window.FreshworksWidget('open');
								this.setState({ freshworkOpened: true });
							}
						}}>Request registration</a>
					</div>
					: null
			}
			{
				APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.singleUser ?
					<div className='col-12' style={{ paddingTop: 10 }}>
						<Button
							onClick={this.handleForceLogin}
							className='form-login-width red-btn'
							title="Terminate other active session and sign in"
							icon='pi-md-person'
							type="button"
							//disabled={true}
							label="Force sign in" />
					</div> : null
			}
			{/*APP_PROPERTIES.ACTIVE_PAGES.verificationPage ?
<div className='col-12' style={{ textAlign: 'center', paddingTop: 0 }}>
	<Link to='/forgotpw' style={{ display: 'inline-block', paddingTop: 0, color: '#007fdb' }}>Forgot password?</Link>
</div> : null
*/}
			{/*<div className="col-12">
Don't have an account? <a href="/">Sign Up</a> now.
</div>*/}
			{!APP_PROPERTIES.SAML_URL ?
				//APP_PROPERTIES.APP_ID === 'dimensions' ?
				<div className='col-12' style={{ paddingTop: 110 }}>
					<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
					<div className='col-12'>
						<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
						{APP_PROPERTIES.INCLUDE_ONETRUST &&
							<React.Fragment>
								<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
								{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
								<a className="optanon-show-settings" style={{ margin: '0 5px', color: '#757575' }}>Cookie settings</a>
							</React.Fragment>
						}
						<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
						<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
					</div>
				</div>
				:
				null
			}
		</React.Fragment>

		return (
			<div className="login-body" style={{
				background: APP_PROPERTIES.APP_ID !== 'dimensions' ?
					`url('${APP_PROPERTIES.LOGIN_BG_IMAGE}') no-repeat scroll center center / cover` : 'white'
				//`url('${APP_PROPERTIES.LOGIN_BG_IMAGE}') no-repeat scroll center center / cover`
			}}>
				<Toast ref={(el) => { this.growl = el }} />
				<div className="login-panel" style={{
					border: '1px solid #d1d1d1', top: '40%' //APP_PROPERTIES.APP_ID !== 'dimensions' ? '50%' : '40%' 
				}}>
					{APP_PROPERTIES.LOGIN_LOGO_APP ?
						<div className="login-panel-header" style={{ marginTop: 66, marginLeft: 'auto', marginRight: 'auto', background: 'white', padding: 0 }}>
							<img src={APP_PROPERTIES.LOGIN_LOGO_APP} alt={`${APP_PROPERTIES.APP_NAME} logo`} style={APP_PROPERTIES.LOGIN_LOGO_APP_STYLE} />
							{/*<span>Dies ist TEST NUMMER 13!!!</span>*/}
						</div>
						:
						null
					}
					<form onSubmit={this.handleLogin} id="loginform">
						<div className="login-panel-content">
							<div className="grid">
								<div className="col-12" style={{ textAlign: 'center' }}>
									{/*<h1>Sign in to {APP_PROPERTIES.APP_NAME}</h1>*/}
									<label className="login-panel-label">Sign in</label>
								</div>
								{APP_PROPERTIES.SAML_URL ?
									this.state.showOneClickFirst ?
										<React.Fragment>
											<div className='col-12' style={{ textAlign: 'center', paddingLeft: 0, paddingRight: 0, paddingTop: 29 }}>
												<Button className="primaryButtonLogin p-button-sm"
													title={APP_PROPERTIES.APP_ID === 'animalhealth' ? 'Use your BI one-click login' : APP_PROPERTIES.APP_ID === 'bon' ? 'Use your DSM one-click login'
														: 'Use your one-click login'}
													label={APP_PROPERTIES.APP_ID === 'animalhealth' ? 'Use your BI one-click login' : APP_PROPERTIES.APP_ID === 'bon' ? 'Use your DSM one-click login'
														: 'Use your one-click login'}
													onClick={this.handleSAMLLogin}
													icon="pi pi-arrow-right"
													iconPos='right'
													style={{ width: '100%', textAlign: 'center', fontSize: 16 }}>
												</Button>
											</div>
											<Accordion className="simpleAccordion loginAccordion"
												expandIcon="pi pi-chevron-up"
												collapseIcon="pi pi-chevron-down"
												style={{ width: '100%' }}
												//activeIndex={this.state.activeIndex}
												onTabChange={(e) => this.onActiveIndexChange(e.index)}>
												<AccordionTab header="Use credentials">
													{loginContent}
												</AccordionTab>
											</Accordion>
											<div className='col-12' style={{ paddingTop: 110 }}>
												<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
												<div className='col-12'>
													<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
													{APP_PROPERTIES.INCLUDE_ONETRUST &&
														<React.Fragment>
															<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
															{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
															<a className="optanon-show-settings" style={{ margin: '0 5px', color: '#757575' }}>Cookie settings</a>
														</React.Fragment>
													}
													<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
													<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
												</div>
											</div>
										</React.Fragment>
										:
										<React.Fragment>
											{loginContent}
											<Accordion className="simpleAccordion loginAccordion"
												expandIcon="pi pi-chevron-up"
												collapseIcon="pi pi-chevron-down"
												style={{ width: '100%' }}
												//activeIndex={this.state.activeIndex}
												onTabChange={(e) => this.onActiveIndexChange(e.index)}>
												<AccordionTab header="Use one-click login" style={{ paddingTop: 0 }}>
													<div className='col-12' style={{ textAlign: 'center', paddingLeft: 0, paddingRight: 0 }}>
														<Button className="primaryButtonLogin p-button-sm"
															title={APP_PROPERTIES.APP_ID === 'animalhealth' ? 'Use your BI one-click login' : APP_PROPERTIES.APP_ID === 'bon' ? 'Use your DSM one-click login'
																: 'Use your one-click login'}
															label={APP_PROPERTIES.APP_ID === 'animalhealth' ? 'Use your BI one-click login' : APP_PROPERTIES.APP_ID === 'bon' ? 'Use your DSM one-click login'
																: 'Use your one-click login'}
															onClick={this.handleSAMLLogin}
															icon="pi pi-arrow-right"
															iconPos='right'
															style={{ width: '100%', textAlign: 'center', fontSize: 16 }}>
														</Button>
													</div>
												</AccordionTab>
											</Accordion>
											<div className='col-12' style={{ paddingTop: 110 }}>
												<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
												<div className='col-12'>
													<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
													{APP_PROPERTIES.INCLUDE_ONETRUST &&
														<React.Fragment>
															<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
															{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
															<a className="optanon-show-settings" style={{ margin: '0 5px', color: '#757575' }}>Cookie settings</a>
														</React.Fragment>
													}
													<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
													<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
												</div>
											</div>
										</React.Fragment>
									:
									loginContent
								}
							</div>
						</div>
					</form>
				</div>
				{/*<div id="logo" className="logo">
					<img src={APP_LOGO} alt="Logo" width="350px" style={{ paddingLeft: 30, paddingTop: 30 }} />
	</div>*/}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	// test
	authenticated: state.user.authenticated,
	// end test
	user: state.user.data,
	loginFailed: state.user.failed,
	fetching: state.user.fetching
})

const mapDispatchToProps = (dispatch) => ({
	// test
	isLoggedIn: (history) => dispatch(Actions.isLoggedIn(history)),
	// end test

	doLogin: (username, password, history, showLoginFailedGrowl, forceLogin) => dispatch(doLogin(username, password, history, showLoginFailedGrowl, forceLogin)),
	doLoginSAML: (history, token) => dispatch(doLoginSAML(history, token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))