const routerBaseName = process.env.REACT_APP_ROUTER_BASE_NAME;
const frontendBaseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;

export const APP_PROPERTIES = {
  APP_ID: process.env.REACT_APP_ID,
  APP_NAME: process.env.REACT_APP_NAME,

  INCLUDE_BATCHES: process.env.REACT_APP_INCLUDE_BATCHES === 'true',
  INCLUDE_READCUBE: process.env.REACT_APP_INCLUDE_READCUBE === 'true',
  INCLUDE_FRESHDESK: process.env.REACT_APP_INCLUDE_FRESHDESK === 'true',
  INCLUDE_ONETRUST: process.env.REACT_APP_INCLUDE_ONETRUST === 'true',

  ROUTER_BASE_NAME: routerBaseName,
  FRONTEND_URL: frontendBaseUrl + routerBaseName,
  HOME_PAGE: '/docfinder/quicksearch',
  MIDDLEWARE_BASE_URL: process.env.REACT_APP_MIDDLEWARE_BASE_URL,
  BRAT_BASE_URL: process.env.REACT_APP_BRAT_BASE_URL,

  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_FRONTEND_KEY,

  // --- active pages --- //
  ACTIVE_PAGES: {
    docSearch: false,
    history: false,
    verificationPage: false,
    backendAdministration: false,
  },

  ACTIVE_FUNCTIONALITIES: {
    allowAnnotationFeedback: false,
    showServerStatus: false,
    showSessionTimeLeft: false,
    autoRefreshToken: false,
    showDocumentsOnStart: false,
    // --- single/multiple user mode has to be supported by middleware! --- //
    singleUser: false,
    addSSOUser: false,
    showIcons: false,
    fetchSortableFields: false,
    showAnnotatedPDFLink: false,
    clearQueryCache: false,
    showJournalFilters: false,
    showClinicalTrialFilters: false,
    showGrantsDSFilters: false,
    showPublicationsDSFilters: false,
  },

  CHEMISTRY: {
    // --- available compound services --- //
    CHEMAXON: 'ca',
    OC: 'oc',
    OC_BIG_QUERY: 'ocbq',
    // --- available reaction services --- //
    BIG_QUERY: 'bq',
    BIG_QUERY_SAVI: 'bqsavi',
    BIG_QUERY_SAVI_PRODUCTS: 'bqsavi_products',
    XFACT_DB: 'xfactdb',
    XFACT_DB_RXN: 'xfactdb_rxn',
    // --- allow compound export to BigQuery table --- //
    INCLUDE_BQ_COMPOUND_EXPORT:
      process.env.REACT_APP_INCLUDE_BQ_COMPOUND_EXPORT === 'true',
    // --- include Mcule links --- //
    INCLUDE_MCULE_LINKS: process.env.REACT_APP_INCLUDE_MCULE_LINKS === 'true',
    // --- show Google structure search links --- //
    showGoogleLinks: false,
    // CA-specific stuff: can be undefined
    INCLUDE_CHEMAXON_MARVIN4JS:
      process.env.REACT_APP_INCLUDE_CHEMAXON_MARVIN4JS === 'true',
    INCLUDE_CHEMAXON_WEBSERVICES:
      process.env.REACT_APP_INCLUDE_CHEMAXON_WEBSERVICES === 'true',
    CHEMAXON_MARVIN4JS_URL: process.env.REACT_APP_CHEMAXON_MARVIN4JS_URL,
    CHEMAXON_WEBSERVICES_URL: process.env.REACT_APP_CHEMAXON_WEBSERVICES_URL,
    CHEMAXON_WEBSERVICES_VERSION:
      process.env.REACT_APP_CHEMAXON_WEBSERVICES_VERSION,
  },
} as const;
