//import "./RectangleBatches.css";
import readIcon from "../../../../assets/images/icons/Bookmark_active@2x.png";
import unreadIcon from "../../../../assets/images/icons/Bookmark_inactive@2x.png";
import { hasUserRole } from "../../util";


const DocumentBookmark = ({
    isRead,
    readOnly,
    repository,
    ocDocID,
    title,
    onChangeReadStatus
}) => {

    const icon = isRead ? readIcon : unreadIcon;
    const label = isRead ? 'Bookmarked' : 'Bookmark';
    const tooltip = isRead ? 'You bookmarked this document.' : 'You have not bookmarked this document';
    const altText = isRead ? 'Bookmarked document' : 'Bookmark document';
    const cursor = readOnly ? 'default' : 'pointer';

    return (
        hasUserRole("ROLE_READDOCUMENTS") ?
            <span
                title={tooltip}
                onClick={(e) => {
                    if (!readOnly) {
                        onChangeReadStatus(repository, ocDocID, title, !isRead);
                    }
                }}
                style={{ display: 'inline-flex', alignItems: 'center', cursor: cursor }}>
                <span style={{ lineHeight: 1, height: 11 }}>
                    <img src={icon}
                        alt={altText}
                        style={{ height: 11 }} />
                </span>
                <span style={{ fontWeight: 'bold', marginLeft: 5 }}>{label}</span>
            </span> : null
    )
}

export default DocumentBookmark;