import React, { Component } from 'react';
import Repository from './Repository';


/**
 * visualise a set of repositories, in a table
 */
class Repositories extends Component {
  
  /**
   * render the component -> display a table of cartridges
   */
  render() {
    return (
      <div className="repositoryTable">
        <div className="grid">
          <div className="col-12 md:col-5 lg:col-5 sm:col-5"><strong>Name</strong></div>
          <div className="col-12 md:col-5 lg:col-5 sm:col-5"><strong>Number of Documents</strong></div>
        </div>
        {
          this.props.repositories.map ((repository) => 
              <Repository key={repository.name} name={repository.name} numberDocuments={repository.numberDocuments} />
            )
        }
      </div>
    );
  }
}
export default Repositories;
