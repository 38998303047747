import $ from 'jquery';


/**
 * Opens all closed sections in the document.
 */
export const openAllSections = () => {
    $('#openAllSectionsBtn').click();
}

/**
 * Scrolls to element defined by elemSelector using button defined by btnSelector.
 * @param {*} selector 
 */
export const scrollToElementViaScrollToButton = (btnSelector, elemSelector) => {
    //console.log(elemSelector)
    const scrollToBtn = $(btnSelector);
    // --- set selector of element we want to scroll to --- //
    scrollToBtn.text(elemSelector);
    // --- click button that will call the scroll function --- //
    scrollToBtn.click();
}