import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataView } from 'primereact/dataview';
import { Toast } from 'primereact/toast';
import { Component, createRef } from 'react';
import { checkResultAndGetPayload } from '../../../../../api';
import { createDocumentSearchRequestV1, fetchDocumentsV1 } from '../../../../../api/content/DocumentApi';
import CustomPaginatorLeftSide from '../../../../common/CustomPaginator/CustomPaginatorLeftSide';
import CustomPaginatorRightSide from '../../../../common/CustomPaginator/CustomPaginatorRightSide';
import CustomPaginatorTemplate from '../../../../common/CustomPaginator/CustomPaginatorTemplate';
import { createDocViewUrl, isArrayEmpty, sleep } from '../../../util';
import ResultDocument from './ResultDocument';
import { Link, Element } from 'react-scroll';
import LoadingOverlay from "@speedy4all/react-loading-overlay";

const pageSizeOptions = [
    { label: `3 entries per page`, value: 3 },
    { label: `5 entries per page`, value: 5 },
    { label: `10 entries per page`, value: 10 },
    { label: `20 entries per page`, value: 20 },
    { label: `50 entries per page`, value: 50 }
]

class ReactionSources extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: null,
            rows: 3,
            first: 0
        }

        this.sourceTemplate = this.sourceTemplate.bind(this);

        this.growl = createRef();
    }

    onOpenDocument = (document) => {
        let link = createDocViewUrl(this.props.repositoryInfo?.id, document.ocDocId);
        window.open(link, '_blank');
    }

    onFetchSourceDocuments = async (documentIDs) => {
        this.setState({ fetchingSources: true });

        const documents = {};
        if (!isArrayEmpty(documentIDs)) {
            //+groupby:"${patentFamilyGrouping.filterValue}"^${patentFamilyGrouping.booster}
            let documentQuery = ''; //'+groupby:"pat_famOC"^100 ';
            for (var documentID of documentIDs) {
                documentQuery += `pdid:"${documentID}" `;
            }
            var start = 0;
            const count = 100;
            while (start < documentIDs.length) {
                const request = createDocumentSearchRequestV1(this.props.repositoryInfo.id, documentQuery, start, count, true, false, false, false);
                const resp = await fetchDocumentsV1(request, true);
                const res = checkResultAndGetPayload(resp, this.props.growl);
                if (res && res.hitDocuments) {
                    for (var hitDocument of res.hitDocuments) {
                        if (hitDocument && hitDocument.docMetadata && hitDocument.docMetadata["Pat_document-id"]) {
                            documents[hitDocument.docMetadata["Pat_document-id"]] = hitDocument;
                        }
                    }
                }
                start += count;
            }
        }
        this.setState({ documents: documents, fetchingSources: false });
    }

    onPaging = (event, relID) => {
        this.setState({ first: event.first }, () => {
            document.getElementById(`scrollToBtn_${relID}`)?.click();
        });
    }

    sourceTemplate = (docID) => {
        return <div key={docID} className="width100perc">
            <ResultDocument
                repositoryInfo={this.props.repositoryInfo}
                document={this.state.documents[docID]}
                reactionData={this.props.reactionSourceData[docID]}
                onOpenDocument={this.onOpenDocument}
            />
        </div>
    }

    render() {

        const { documentIDs, relID } = this.props;

        return <>
            <Toast ref={this.growl} />

            <LoadingOverlay
                active={this.state.fetchingSources}
                spinner={true}
                className="fullPage"
                text="Fetching source documents..." >

                {!isArrayEmpty(documentIDs) ?
                    <Accordion
                        className="simpleAccordion reactionSources"
                        multiple={false}
                        activeIndex={this.state.activeIndex}
                        expandIcon="pi pi-chevron-up"
                        collapseIcon="pi pi-chevron-down"
                        onTabChange={(e) => { this.setState({ activeIndex: e.index }); }}
                        onTabOpen={(e) => { if (e.index === 0) { this.onFetchSourceDocuments(documentIDs) } }}
                    >
                        <AccordionTab header={`${documentIDs.length} ${documentIDs.length === 1 ? 'source' : 'sources'}`}>
                            {this.state.documents ?
                                <Element name={`topElement_${relID}`}>
                                    <DataView
                                        value={documentIDs}
                                        totalRecords={documentIDs.length}
                                        layout={'list'}
                                        onPage={(e) => this.onPaging(e, relID)}
                                        first={this.state.first}
                                        rows={this.state.rows}
                                        alwaysShowPaginator={false}
                                        paginator={true}
                                        paginatorPosition="bottom"
                                        paginatorTemplate={CustomPaginatorTemplate}
                                        paginatorLeft={<CustomPaginatorLeftSide
                                            first={this.state.first}
                                            contentLength={documentIDs.length}
                                            rows={this.state.rows}></CustomPaginatorLeftSide>}
                                        paginatorRight={<CustomPaginatorRightSide
                                            rows={this.state.rows}
                                            onChange={(e) => this.setState({ rows: e.value })}
                                            pageSizeOptions={pageSizeOptions}
                                        ></CustomPaginatorRightSide>}
                                        itemTemplate={this.sourceTemplate}>
                                    </DataView>
                                    <Link id={`scrollToBtn_${relID}`} to={`topElement_${relID}`}
                                        spy={true} smooth={true} offset={-150} duration={100}>
                                    </Link>
                                </Element>
                                : !this.state.fetchingSources ? 'No data' : ''}
                        </AccordionTab>
                    </Accordion>
                    :
                    null}
            </LoadingOverlay>
        </>
    }
}
export default ReactionSources