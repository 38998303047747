import { sendApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';
import { sortObjectArrayByAttribute, sortObjectMapByAttribute } from '../../components/webapi/util';


// ----------------------------------------------------------------------- //
// --- run API requests -------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Fetches all available domains.
 * TODO: extra endpoints for different lists!
 */
export const fetchAvailableDomains = async () => {

    //console.log('*** FETCH DOMAINS');
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/domains`, {});

    if (result.status === 'SUCCESS') {
        const availDomains = result?.payload?.domains || {};
        //console.log('*** availDomains: ', availDomains);

        // --- remove frames cartridges from domain list, @todo in backend --- //
        // delete availDomains['frames-ocmrelations'];
        // delete availDomains['frames-sofc'];

        var domains = {};
        var domainLabelsMap = {};
        for (const key of Object.keys(availDomains)) {
            // --- remove frames cartridges from domain list, @todo in backend --- //
            if (key.startsWith('frames-')) {
                continue;
            }

            const value = availDomains[key];
            const ocids = [];
            if (value.isaRootConcepts) {
                for (var isaRootConcept of value.isaRootConcepts) {
                    ocids.push(isaRootConcept.ocid);
                }
            }
            domains[key] = {
                value: key,
                label: value.name,
                ocids: ocids,
                isaRootConcepts: value.isaRootConcepts,
                //active: value.active
            };
            domainLabelsMap[key] = value.name;
        }

        const domainsSorted = sortObjectMapByAttribute(domains, 'label');
        //console.log('*** domainsSorted: ', domainsSorted);

        return ({
            status: 'SUCCESS',
            payload: {
                domains: domainsSorted,
                domainLabelsMap: domainLabelsMap,
            }
        });
    }

    return result;
};


/**
 * Fetches root concepts for given filter domains.
 * 
 * @param {String[]} filterDomains array of active domain prefixes 
 */
export const fetchOntologyRootNodes = async (filterDomains) => {
    //console.log('*** FETCH ROOTS');
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/domains`, {});

    if (result.status === 'SUCCESS') {
        const domains = result?.payload?.domains || {};
        //console.log('*** domains: ', domains);

        // --- remove frames cartridges from domain list, @todo in backend --- //
        // delete domains['frames-ocmrelations'];
        // delete domains['frames-sofc'];

        var domainNodes = [];
        var domainLabelsMap = {};
        for (const key of Object.keys(domains)) {
            // --- remove frames cartridges from domain list, @todo in backend --- //
            if (key.startsWith('frames-')) {
                continue;
            }
            const value = domains[key];

            // --- if root concept belongs to an active domain add it to results --- //
            if (!filterDomains || filterDomains.includes(key)) {
                domainNodes.push({
                    key: value.isaRootConcepts[0].ocid,
                    label: value.name,
                    leaf: false,
                    data: {
                        ocid: value.isaRootConcepts[0].ocid,
                        domain: key
                    }
                });

                domainLabelsMap[key] = value.name;
            }
        }

        await addDataToNodes(domainNodes, [], domainLabelsMap);

        const nodesSorted = sortObjectArrayByAttribute(domainNodes, 'label', true, true);

        return ({ status: 'SUCCESS', payload: nodesSorted });
    }

    return result;
};

/**
 * Collects paths from given concepts up to the root. 
 * 
 * @param {Object} concepts map of concepts which represent the leaves in the tree
 */
export const fetchPathsToConcepts = async (concepts, withFamilyMembers) => {

    const ocids = [];
    for (const domConcepts of Object.values(concepts)) {
        for (const conc of Object.values(domConcepts)) {
            ocids.push(conc.ocid);
        }
    }

    if (ocids.length >= 0) {

        const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ontology/tree`, {
            ocids: ocids
        });

        if (result.status === 'SUCCESS') {
            const nodes = result.payload.partialTree;
            const firstMatches = {};

            let domainLabelsMap = {};
            const resultDomains = await fetchAvailableDomains();
            if (resultDomains.status === 'SUCCESS' && resultDomains.payload && resultDomains.payload.domainLabelsMap) {
                domainLabelsMap = resultDomains.payload.domainLabelsMap;
            }

            await addDataToNodes(nodes, ocids, domainLabelsMap, null, firstMatches, withFamilyMembers);

            const nodesSorted = sortObjectArrayByAttribute(nodes, 'label', true, true);

            for (const node of Object.values(firstMatches)) {
                node.isFirst = true;
            }

            return ({ status: 'SUCCESS', payload: nodesSorted });
        }
        else {
            return result;
        }
    }
    else {
        return ({ status: 'FAILED', message: 'No valid concept specified.' });
    }
}



/**
 * 
 * @param {Array} nodes 
 * @param {Array} matchOcids 
 * @param {string} parentKey 
 */
const addDataToNodes = async (nodes, matchOcids, domainLabelsMap = {}, parentKey, firstMatches = {}, withFamilyMembers = false) => {
    let childCount = 1;
    for (const node of nodes) {
        // --- if parentKey is undefined -> domain node --- //
        const key = parentKey ? parentKey + '-' + childCount : childCount;

        const isMatch = matchOcids.includes(node.data.ocid);

        // --- set unique key --- //
        node.key = key;
        node.isMatch = isMatch;

        if (isMatch) {
            if (!firstMatches[node.data.ocid]) {
                firstMatches[node.data.ocid] = node;
            }
            else if (key < firstMatches[node.data.ocid].key) {
                firstMatches[node.data.ocid] = node;
            }
        }

        // --- add icon, update label etc. --- //
        if (!parentKey) {
            //console.log('root node: ', node);
            node.label = domainLabelsMap[node.data.domain] ? domainLabelsMap[node.data.domain] : node.data.domain;
            ///node.icon = "pi pi-fw pi-ontology";
            node.type = 'ont';
        }
        else {
            //node.icon = (isMatch ? "pi pi-fw pi-star" : "pi pi-fw  pi-circle-off");
            node.leaf = true;
        }

        childCount++;

        // --- do the same for all children --- //
        if (node.children && node.children.length > 0)
            await addDataToNodes(node.children, matchOcids, domainLabelsMap, key, firstMatches, withFamilyMembers);
    }
}