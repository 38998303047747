export const findParents = (parentNode, key) => {
    const arr = [];
    searchParent(parentNode, key);

    function searchParent(node, key) {
        if (node.key === key) {
            arr.push(node.key);
            return arr;
            // searchParent(parentNode, node.key, false);
        }

        node?.children?.forEach(el => {
            if (el.key === key) {
                arr.push(node.key);
                searchParent(parentNode, node.key);
            } else {
                searchParent(el, key);
            }
        })
    }

    return arr;
}

