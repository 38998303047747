/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { getHeaderToken, getHeaderTokenText } from '../../api/index'
import axios from 'axios'
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import dateFormat from 'dateformat';
import { Toast } from 'primereact/toast';
import { TreeTable } from 'primereact/treetable'
import AddCompany from './AddCompany'
import AddDepartment from './AddDepartment'
import AddUser from './AddUser'
import AddRepository from './AddRepository'
import EditRepository from './EditRepository'
import SetupRepository from './SetupRepository'
import DeleteRepository from './DeleteRepository'
import AddDomain from './AddDomain'
import EditDomain from './EditDomain'
import SetupDomain from './SetupDomain'
import AddFeature from './AddFeature'
import EditFeature from './EditFeature'
import SetupFeature from './SetupFeature'
import DeleteDomain from './DeleteDomain'
import DeleteCompany from './DeleteCompany'
import DeleteDpartment from './DeleteDepartment'
import DeleteUser from './DeleteUser'
import DeleteUsers from './DeleteUsers'
import ActivateUsers from './ActivateUsers'
import InactivateUsers from './InactivateUsers'
import MoveUsers from './MoveUsers'
import ResendVerification from './ResendVerification'
import VerifyUser from './VerifyUser'
import EditCompany from './EditCompany'
import EditDepartment from './EditDepartment'
import EditUser from './EditUser'
import EditRegularUser from './EditRegularUser'
import LogoutUser from './LogoutUser'
import GlobalSettings from './GlobalSettings'
import GlobalUserSearch from './GlobalUserSearch'
import ToastContent from '../common/CustomToast/ToastContent';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import { APP_PROPERTIES, REPO_ROLES } from '../../properties/index';
import { hasUserRole, isArrayEmpty } from '../../components/webapi/util';

class Administration extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);
        this.state = {
            displayCustomToast: false,
            activeIndex: 0,
            first: 0,
            rows: 20,
            displayGlobalSettings: false,
            displayGlobalUserSearch: false,
            displayAddCompany: false,
            displayAddDepartment: false,
            displayAddRepository: false,
            displayAddDomain: false,
            displayAddFeature: false,
            displayEditCompany: false,
            displayDeleteCompany: false,
            displayEditDepartment: false,
            displayDeleteDepartment: false,
            displayDeleteRepository: false,
            displayEditDomain: false,
            displaySetupDomain: false,
            displayEditFeature: false,
            displaySetupFeature: false,
            displayEditRepository: false,
            displayCopyRepository: false,
            displaySetupRepository: false,
            displayDeleteDomain: false,
            displayAddUser: false,
            displayEditUser: false,
            displayDeleteUser: false,
            displayDeleteUsers: false,
            displayMoveUsers: false,
            displayVerifyUser: false,
            displayResendVerification: false,
            displayActivateUsers: false,
            displayInactivateUsers: false,
            displayLogoutDialog: false,
            newCompanyName: '',
            newDepartmentName: '',
            allRepositoriesChecked: false,
            allHighlightedDomainsChecked: false,
            allSearchableDomainsChecked: false,
            selectedRepositories: [],
            selectedRepositoriesCompany: [],
            availableRepositoriesCompany: [],
            availableRepositories: [],
            selectedHighlightDomainsCompany: [],
            selectedSearchDomainsCompany: [],
            selectedSearchDomainsNewCompany: [],
            allDomainSelectItems: [],
            selectedHighlightDomains: [],
            selectedSearchDomains: [],
            mouseOverRepositoryButton: false,
            companies: [],
            selectedCompany: null,
            selectedDepartment: null,
            selectedCompanyName: null,
            selectedCompanyApplicationName: null,
            selectedCompanyLandingPage: '',
            selectedDepartmentLandingPage: '',
            selectedDepartmentMaxDownloads: '',
            selectedCompanyMaxUsers: '',
            selectedCompanyMaxDownloads: '',
            maxUsers: '',
            availableFeatures: [],
            deleteCompanyChecked: false,
            selectedUsers: [],
            selectedUsersTable: [],
            departmentUsers: [],
            selectedUserDetails: [],
            newUserName: '',
            newCompanyChecked: false,
            newCompanyApplicationName: '',
            selectedDepartments: [],
            allDomainsHighlightItems: [],
            highlightDomainsCompany: [],
            allAvailableFeatures: [],
            selectedRepositoryLabel: '',
            selectedRepositoryName: '',
            selectedRepositoryID: '',
            selectedRepositoryDescription: '',
            selectedDomainID: '',
            selectedDomainName: '',
            selectedDomainLabel: '',
            selectedDomainActive: true,
            selectedDomainColor: '',
            selectedDomainDescription: '',
            selectedDomainOrderPriority: '1',
            expandedKeys: {},
            globalFilterUsers: '',
            repositoryFeatures: [],
            allUsersSelected: false,
            filteredUsers: [],
            allGlobalUsers: [],
            highlightedUser: undefined,
            availableFeaturesCompany: [],
            availableHighlightDomainsCompany: [],
            availableSearchDomainsCompany: []
        }
        this.columns = [
            { field: "name", header: "Name", style: {}, body: this.nameTableTemplate, sortable: true },
            { field: "email", header: "Email", body: this.emailTemplate, sortable: true },
            { field: "status.adminRole", header: 'Status', body: this.statusTemplate, sortable: true },
            { field: "created", header: 'Created', body: this.createdTemplate, sortable: true },
            { field: "lastLogin", header: 'Last login', body: this.loginTemplate, sortable: true },
            { field: "status.departmentName", header: 'Suborganization', body: this.suborgTemplate, sortable: true },
            { field: "", body: this.editTemplate, style: { width: 100 } }
        ]
    }

    componentDidMount = async () => {
        let self = this;

        if (self.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' || self.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' || self.props.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN') {
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies`, { headers: getHeaderToken() })
                .then(function (response) {
                    let companies = response.data
                    response.data.forEach((comp, i) => {
                        if (comp.id === self.props.userDetails.company.id) {
                            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/departments`, { headers: getHeaderToken() })
                                .then(function (response) {
                                    let departments = response.data
                                    let departmentKey = self.props.userDetails.department.name + '-' + self.props.userDetails.department.id
                                    axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/users`, { headers: getHeaderToken() })
                                        .then(function (response) {
                                            let departmentUsers = response.data
                                            self.setState({
                                                companies: companies,
                                                selectedCompany: comp.id,
                                                selectedCompanyName: comp.name,
                                                selectedCompanyApplicationName: comp.applicationName ? comp.applicationName : '',
                                                selectedCompanyMaxUsers: comp.maximumNumberUsers ? comp.maximumNumberUsers : '',
                                                selectedCompanyMaxDownloads: comp.maximumNumberDownloads ? comp.maximumNumberDownloads : '',
                                                selectedDepartments: departments,
                                                selectedCompanyLandingPage: comp.landingPage ? comp.landingPage : '',
                                                expandedKeys: { [departmentKey]: true },
                                                departmentUsers: departmentUsers
                                            })
                                        }).catch(function (error) {
                                            if (error.response.status === 400) {
                                                self.showFailGrowl(error.response.data)
                                            } else if (error.response.status === 401) {
                                                self.props.history.push('/')
                                            } else if (error.response.status === 404) {
                                                console.log("not found")
                                            }
                                        })
                                }).catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.showFailGrowl(error.response.data)
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        }
                    })
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }

        if (this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN') {
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/backend-apis`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        allBackends: response.data
                    })
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })

            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/repositories`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        availableRepositories: response.data
                    })
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })

            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories/features`, { headers: getHeaderToken() })
                .then(function (response) {
                    const repositoryFeatures = {};
                    if (response.data) {
                        response.data.forEach(feature => {
                            const repoRole = REPO_ROLES[feature];
                            repositoryFeatures[feature] = {
                                id: feature,
                                label: repoRole && repoRole.label ? repoRole.label : feature,
                                abbreviation: repoRole && repoRole.abbreviation ? repoRole.abbreviation : feature.substring(0, 4)
                            };
                        })
                    }
                    self.setState({
                        repositoryFeatures: repositoryFeatures
                    })
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })

            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        allDomainSelectItems: response.data,
                        allDomainsHighlightItems: response.data
                    })
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })

            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/function-roles`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        allAvailableFeatures: response.data
                    })
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let self = this

        if (self.props.userDetails !== prevProps.userDetails) {
            if (self.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' || self.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' || self.props.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN') {
                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies`, { headers: getHeaderToken() })
                    .then(function (response) {
                        let companies = response.data
                        response.data.forEach((comp, i) => {
                            if (comp.id === self.props.userDetails.company.id) {
                                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/departments`, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        let departments = response.data
                                        let departmentKey = self.props.userDetails.department.name + '-' + self.props.userDetails.department.id
                                        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/users`, { headers: getHeaderToken() })
                                            .then(function (response) {
                                                let departmentUsers = response.data
                                                self.setState({
                                                    companies: companies,
                                                    selectedCompany: comp.id,
                                                    selectedCompanyName: comp.name,
                                                    selectedCompanyApplicationName: comp.applicationName ? comp.applicationName : '',
                                                    selectedCompanyMaxUsers: comp.maximumNumberUsers ? comp.maximumNumberUsers : '',
                                                    selectedCompanyMaxDownloads: comp.maximumNumberDownloads ? comp.maximumNumberDownloads : '',
                                                    selectedDepartments: departments,
                                                    selectedCompanyLandingPage: comp.landingPage ? comp.landingPage : '',
                                                    expandedKeys: { [departmentKey]: true },
                                                    departmentUsers: departmentUsers
                                                })
                                            }).catch(function (error) {
                                                if (error.response.status === 400) {
                                                    self.showFailGrowl(error.response.data)
                                                } else if (error.response.status === 401) {
                                                    self.props.history.push('/')
                                                } else if (error.response.status === 404) {
                                                    console.log("not found")
                                                }
                                            })
                                    }).catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showFailGrowl(error.response.data)
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            }
                        })
                    }).catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }

            if (self.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN') {
                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/backend-apis`, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.setState({
                            allBackends: response.data
                        })
                    }).catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })

                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/repositories`, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.setState({
                            availableRepositories: response.data
                        })
                    }).catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })

                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories/features`, { headers: getHeaderToken() })
                    .then(function (response) {
                        const repositoryFeatures = {};
                        if (response.data) {
                            response.data.forEach(feature => {
                                const repoRole = REPO_ROLES[feature];
                                repositoryFeatures[feature] = {
                                    id: feature,
                                    label: repoRole && repoRole.label ? repoRole.label : feature,
                                    abbreviation: repoRole && repoRole.abbreviation ? repoRole.abbreviation : feature.substring(0, 4)
                                };
                            })
                        }
                        self.setState({
                            repositoryFeatures: repositoryFeatures
                        })
                    }).catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })

                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains`, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.setState({
                            allDomainSelectItems: response.data,
                            allDomainsHighlightItems: response.data
                        })
                    }).catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })

                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/function-roles`, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.setState({
                            allAvailableFeatures: response.data
                        })
                    }).catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
        }
    }

    refreshCompanies = async () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies`, { headers: getHeaderToken() })
            .then(function (response) {
                let companies = response.data
                self.setState({
                    companies: companies,
                    highlightedUser: undefined
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshRepositories = () => {
        let self = this
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/repositories`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    availableRepositories: response.data,
                    highlightedUser: undefined
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshDomains = () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allDomainSelectItems: response.data,
                    allDomainsHighlightItems: response.data,
                    highlightedUser: undefined
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshFeatures = () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/function-roles`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allAvailableFeatures: response.data,
                    highlightedUser: undefined
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshCompaniesAfterDelete = async () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies`, { headers: getHeaderToken() })
            .then(function (response) {
                let companies = response.data
                response.data.forEach((comp, i) => {
                    if (comp.id === self.props.userDetails.company.id) {
                        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/departments`, { headers: getHeaderToken() })
                            .then(function (response) {
                                let departments = response.data
                                let departmentKey = self.props.userDetails.department.name + '-' + self.props.userDetails.department.id
                                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/users`, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        let departmentUsers = response.data
                                        self.setState({
                                            companies: companies,
                                            selectedCompany: comp.id,
                                            selectedCompanyName: comp.name,
                                            selectedCompanyApplicationName: comp.applicationName ? comp.applicationName : '',
                                            selectedCompanyMaxUsers: comp.maximumNumberUsers ? comp.maximumNumberUsers : '',
                                            selectedCompanyMaxDownloads: comp.maximumNumberDownloads ? comp.maximumNumberDownloads : '',
                                            selectedDepartments: departments,
                                            selectedCompanyLandingPage: comp.landingPage ? comp.landingPage : '',
                                            expandedKeys: { [departmentKey]: true },
                                            departmentUsers: departmentUsers,
                                            highlightedUser: undefined
                                        })
                                    }).catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showFailGrowl(error.response.data)
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            }).catch(function (error) {
                                if (error.response.status === 400) {
                                    self.showFailGrowl(error.response.data)
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                    }
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshUserDetails = () => {
        this.props.isLoggedIn(this.props.history)
    }

    refreshDepartments = async (companyID) => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies`, { headers: getHeaderToken() })
            .then(function (response) {
                let companies = response.data
                response.data.forEach((comp, i) => {
                    if (comp.id === companyID) {
                        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${companyID}/departments`, { headers: getHeaderToken() })
                            .then(function (response) {
                                let departments = response.data
                                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/users`, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        let departmentUsers = response.data
                                        self.setState({
                                            companies: companies,
                                            selectedDepartments: departments,
                                            departmentUsers: departmentUsers,
                                            highlightedUser: undefined
                                        })
                                        if (self.state.selectedDepartment === self.props.userData.userDetails.department.id) {
                                            self.refreshUserDetails()
                                        }
                                    }).catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showFailGrowl(error.response.data)
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            }).catch(function (error) {
                                if (error.response.status === 400) {
                                    self.showFailGrowl(error.response.data)
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                    }
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    openGlobalSettings = () => {
        this.toastBC.current.clear()
        this.setState({
            displayGlobalSettings: true,
            highlightedUser: undefined,
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            displayCustomToast: false
        })
    }

    openGlobalUserSearch = () => {
        let self = this
        self.toastBC.current.clear()
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/users`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    displayGlobalUserSearch: true,
                    allGlobalUsers: response.data,
                    highlightedUser: undefined,
                    selectedUsers: [],
                    selectedUsersTable: [],
                    selectedUserDetails: [],
                    displayCustomToast: false
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    getGlobalUsers = () => {
        let self = this
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/users`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allGlobalUsers: response.data
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    openAddCompany = () => {
        this.toastBC.current.clear()
        this.setState({
            displayAddCompany: true,
            highlightedUser: undefined,
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            displayCustomToast: false
        })
    }

    openAddRepository = () => {
        this.setState({
            displayAddRepository: true
        })
    }

    openEditRepository = (id, label, name, abbreviation, active, backendId, statistics, features, description) => {
        this.setState({
            displayEditRepository: true,
            selectedRepositoryLabel: label,
            selectedRepositoryName: name,
            selectedRepositoryID: id,
            selectedRepositoryDescription: description,
            selectedRepositoryAbbreviation: abbreviation,
            selectedRepositoryActive: active,
            selectedRepositoryBackend: backendId,
            selectedRepositoryStatistics: statistics,
            selectedRepositoryFeatures: features
        })
    }

    openCopyRepository = (label, name, abbreviation, active, backendId, statistics, features, description) => {
        this.setState({
            displayCopyRepository: true,
            selectedRepositoryLabel: label,
            selectedRepositoryName: name,
            selectedRepositoryDescription: description,
            selectedRepositoryAbbreviation: abbreviation,
            selectedRepositoryActive: active,
            selectedRepositoryBackend: backendId,
            selectedRepositoryStatistics: statistics,
            selectedRepositoryFeatures: features
        })
    }

    openSetupRepository = (id, label, name, abbreviation, active, backendId, statistics, features, description) => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/companies/repositories`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allGlobalRepositories: response.data,
                    displaySetupRepository: true,
                    selectedRepositoryLabel: label,
                    selectedRepositoryName: name,
                    selectedRepositoryID: id,
                    selectedRepositoryDescription: description,
                    selectedRepositoryAbbreviation: abbreviation,
                    selectedRepositoryActive: active,
                    selectedRepositoryBackend: backendId,
                    selectedRepositoryStatistics: statistics,
                    selectedRepositoryFeatures: features
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    openSetupFeature = (id, label, name) => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/companies/roles`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allGlobalFeatures: response.data,
                    displaySetupFeature: true,
                    selectedFeatureLabel: label,
                    selectedFeatureName: name,
                    selectedFeatureID: id
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshGlobalRepositories = () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/companies/repositories`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allGlobalRepositories: response.data
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    openDeleteRepository = (id, label, name) => {
        this.setState({
            displayDeleteRepository: true,
            selectedRepositoryLabel: label,
            selectedRepositoryName: name,
            selectedRepositoryID: id
        })
    }

    onCancelDeleteRepository = () => {
        this.setState({
            displayDeleteRepository: false
        })
    }

    openEditDomain = (label, name, id, active, color, description, orderPriority) => {
        this.setState({
            displayEditDomain: true,
            selectedDomainID: id,
            selectedDomainName: name,
            selectedDomainLabel: label,
            selectedDomainActive: active,
            selectedDomainColor: color,
            selectedDomainDescription: description,
            selectedDomainOrderPriority: orderPriority
        })
    }

    openSetupDomain = (label, name, id) => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/companies/domains`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allGlobalDomains: response.data,
                    displaySetupDomain: true,
                    selectedDomainID: id,
                    selectedDomainName: name,
                    selectedDomainLabel: label
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshGlobalDomains = () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/companies/domains`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allGlobalDomains: response.data
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshGlobalFeatures = () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/companies/roles`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allGlobalFeatures: response.data
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    openEditFeature = (label, name, id, canBeDeleted, isDefault) => {
        this.setState({
            displayEditFeature: true,
            selectedFeatureID: id,
            selectedFeatureName: name,
            selectedFeatureLabel: label,
            selectedFeatureCanBeDeleted: canBeDeleted,
            selectedFeatureIsDefault: isDefault
        })
    }

    openDeleteDomain = (label, name, id) => {
        this.setState({
            displayDeleteDomain: true,
            selectedDomainID: id,
            selectedDomainName: name,
            selectedDomainLabel: label
        })
    }

    openAddDomain = () => {
        this.setState({
            displayAddDomain: true
        })
    }

    openAddFeature = () => {
        this.setState({
            displayAddFeature: true
        })
    }

    onCancelEditRepository = () => {
        this.setState({
            displayEditRepository: false
        })
    }

    onCancelCopyRepository = () => {
        this.setState({
            displayCopyRepository: false
        })
    }

    onCancelSetupRepository = () => {
        this.setState({
            displaySetupRepository: false
        })
    }

    onCancelEditDomain = () => {
        this.setState({
            displayEditDomain: false
        })
    }

    onCancelSetupDomain = () => {
        this.setState({
            displaySetupDomain: false
        })
    }

    onCancelEditFeature = () => {
        this.setState({
            displayEditFeature: false
        })
    }

    onCancelSetupFeature = () => {
        this.setState({
            displaySetupFeature: false
        })
    }

    onCancelDeleteDomain = () => {
        this.setState({
            displayDeleteDomain: false
        })
    }

    onRepositoryOrgClick = async (checked, org, rep, choice) => {
        if (checked) {
            let suborgActivation = choice === 'all' ? true : false
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/repository/${rep.id}/add?suborgActivation=${suborgActivation}`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalRepositories()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/repository/${rep.id}/remove`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalRepositories()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    onRepositorySuborgClick = async (e, suborg, rep) => {
        if (e.checked) {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/repository/${rep.id}/add`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalRepositories()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/repository/${rep.id}/remove`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalRepositories()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    onDomainOrgClick = async (checked, org, dom, choice, highlightChecked, searchableChecked) => {
        if (checked) {
            let suborgActivation = choice === 'all' ? true : false
            let self = this;
            if (searchableChecked) {
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/domain/${dom.id}/add?suborgActivation=${suborgActivation}`, {
                }, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.refreshGlobalDomains()
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
            if (highlightChecked) {
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/domain/annotation/${dom.id}/add?suborgActivation=${suborgActivation}`, {
                }, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.refreshGlobalDomains()
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
        } else {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/domain/${dom.id}/remove`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalDomains()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })

            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/domain/annotation/${dom.id}/remove`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalDomains()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    onDomainSuborgClick = async (checked, suborg, dom, highlightChecked, searchableChecked) => {
        if (checked) {
            let self = this;
            if (searchableChecked) {
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/domain/${dom.id}/add`, {
                }, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.refreshGlobalDomains()
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
            if (highlightChecked) {
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/domain/annotation/${dom.id}/add`, {
                }, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.refreshGlobalDomains()
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
        } else {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/domain/${dom.id}/remove`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalDomains()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })

            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/domain/annotation/${dom.id}/remove`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalDomains()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    onFeatureOrgClick = async (checked, org, feat, choice) => {
        if (checked) {
            let suborgActivation = choice === 'all' ? true : false
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/role/${feat.id}/add?suborgActivation=${suborgActivation}`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalFeatures()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/role/${feat.id}/remove`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalFeatures()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    onFeatureSuborgClick = async (e, suborg, feat) => {
        if (e.checked) {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/role/${feat.id}/add`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalFeatures()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/role/${feat.id}/remove`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalFeatures()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    handleActivationStatusRepository = async (value, id) => {
        let activeInactive = value === true ? 'activate' : 'deactivate'
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories/${id}/activation?activationStatus=${activeInactive}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                self.refreshRepositories()
                self.refreshDepartments(self.state.selectedCompany)
            }).catch(function (error) {
                if (error.response.status === 400) {
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleActivationStatusDomain = async (value, id) => {
        let activeInactive = value === true ? 'activate' : 'deactivate'
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains/${id}/activation?activationStatus=${activeInactive}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                self.refreshDomains()
                self.refreshDepartments(self.state.selectedCompany)
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    onRepositoryOrderChange = async (ids) => {
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories/order-priority`, ids, { headers: getHeaderToken() })
            .then(function (response) {
                self.showOrderRepositoriesSuccessGrowl()
                self.refreshRepositories()
                self.refreshCompanies()
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showOrderRepositoriesFailGrowl()
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    openAddDepartment = async () => {
        let self = this
        this.toastBC.current.clear()
        await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${self.state.selectedCompany}`, { headers: getHeaderToken() })
            .then(function (response) {
                let comp = response.data
                let availableRepositoriesCompany = []
                let availableSearchDomainsCompany = []
                let availableHighlightDomainsCompany = []
                let availableFeaturesCompany = []
                let departmentLandingPage = ''
                let departmentMaxDownloads = null

                let backgroundColor
                let fontColor
                let applicationHeader1
                let applicationHeader2
                let applicationHeader3
                let logo
                let emailDomains

                departmentLandingPage = comp.landingPage ? comp.landingPage : 'docsearch'

                departmentMaxDownloads = comp.numberMaximumEntriesToExport ? comp.numberMaximumEntriesToExport : null

                availableSearchDomainsCompany = comp.selectedDomains ? comp.selectedDomains : []

                availableHighlightDomainsCompany = comp.selectedAnnotationTypes ? comp.selectedAnnotationTypes : []

                availableRepositoriesCompany = comp.selectedRepositories ? comp.selectedRepositories : []

                availableFeaturesCompany = comp.selectedFunctionRoles ? comp.selectedFunctionRoles : []

                backgroundColor = (comp.backgroundColor && comp.useCustomHeader) ? comp.backgroundColor : undefined
                fontColor = (comp.fontColor && comp.useCustomHeader) ? comp.fontColor : undefined
                applicationHeader1 = (comp.applicationHeader1 && comp.useCustomHeader) ? comp.applicationHeader1 : ''
                applicationHeader2 = (comp.applicationHeader2 && comp.useCustomHeader) ? comp.applicationHeader2 : ''
                applicationHeader3 = (comp.applicationHeader3 && comp.useCustomHeader) ? comp.applicationHeader3 : ''
                logo = (comp.logo && comp.useCustomHeader) ? comp.logo : undefined

                emailDomains = comp.emailDomains ? comp.emailDomains : []

                self.setState({
                    selectedDepartmentLandingPage: departmentLandingPage,
                    selectedDepartmentMaxDownloads: departmentMaxDownloads,
                    availableRepositoriesCompany: availableRepositoriesCompany,
                    availableFeaturesCompany: availableFeaturesCompany,
                    availableHighlightDomainsCompany: availableHighlightDomainsCompany,
                    availableSearchDomainsCompany: availableSearchDomainsCompany,
                    selectedCompanyBackgroundColor: backgroundColor,
                    selectedCompanyFontColor: fontColor,
                    selectedCompanyApplicationHeader1: applicationHeader1,
                    selectedCompanyApplicationHeader2: applicationHeader2,
                    selectedCompanyApplicationHeader3: applicationHeader3,
                    selectedCompanyLogo: logo,
                    selectedCompanyEmailDomains: emailDomains,
                    displayAddDepartment: true,
                    highlightedUser: undefined,
                    selectedUsers: [],
                    selectedUsersTable: [],
                    selectedUserDetails: [],
                    displayCustomToast: false
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    openEditCompany = async () => {
        let self = this
        self.toastBC.current.clear()
        await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${self.state.selectedCompany}`, { headers: getHeaderToken() })
            .then(function (response) {
                let comp = response.data
                let selectedCompanyRepositories = []
                let selectedSearchDomainsCompany = []
                let selectedHighlightDomainsCompany = []
                let selectedFeaturesCompany = []
                let availableRepositoriesCompany = []
                let availableSearchDomainsCompany = []
                let availableHighlightDomainsCompany = []
                let availableFeaturesCompany = []
                let companyName
                let companyApplicationName
                let companyMaxUsers
                let companyMaxDownloads
                let companyActive
                let landingPage
                let backgroundColor
                let fontColor
                let applicationHeader1
                let applicationHeader2
                let applicationHeader3
                let logo
                let useCustomHeader
                let defaultBackend
                let defaultChemBackend
                let emailDomains

                comp.selectedRepositories && comp.selectedRepositories.forEach(rep => {
                    selectedCompanyRepositories = [...selectedCompanyRepositories, rep.id]
                })

                comp.selectedDomains && comp.selectedDomains.forEach(dom => {
                    selectedSearchDomainsCompany = [...selectedSearchDomainsCompany, dom.name]
                })

                comp.selectedAnnotationTypes && comp.selectedAnnotationTypes.forEach(anno => {
                    selectedHighlightDomainsCompany = [...selectedHighlightDomainsCompany, anno.name]
                })

                comp.selectedFunctionRoles && comp.selectedFunctionRoles.forEach(role => {
                    selectedFeaturesCompany = [...selectedFeaturesCompany, role.name]
                })

                availableSearchDomainsCompany = comp.availableDomains ? comp.availableDomains : []

                availableHighlightDomainsCompany = comp.availableAnnotationTypes ? comp.availableAnnotationTypes : []

                availableRepositoriesCompany = comp.availableRepositories ? comp.availableRepositories : []

                availableFeaturesCompany = comp.availableFunctionRoles ? comp.availableFunctionRoles : []

                companyName = comp.name

                defaultBackend = comp.defaultBackendApi ? comp.defaultBackendApi.id : null

                defaultChemBackend = comp.defaultChemistryBackendApi ? comp.defaultChemistryBackendApi.id : null

                companyApplicationName = comp.applicationName ? comp.applicationName : ''

                companyMaxUsers = comp.maximumNumberUsers ? comp.maximumNumberUsers : ''

                companyMaxDownloads = comp.numberMaximumEntriesToExport ? comp.numberMaximumEntriesToExport : null

                companyActive = comp.deactivated === false ? true : false

                landingPage = comp.landingPage ? comp.landingPage : 'docsearch'

                backgroundColor = comp.backgroundColor ? comp.backgroundColor : undefined
                fontColor = comp.fontColor ? comp.fontColor : undefined
                applicationHeader1 = comp.applicationHeader1 ? comp.applicationHeader1 : ''
                applicationHeader2 = comp.applicationHeader2 ? comp.applicationHeader2 : ''
                applicationHeader3 = comp.applicationHeader3 ? comp.applicationHeader3 : ''
                logo = comp.logo ? comp.logo : undefined
                useCustomHeader = comp.useCustomHeader

                emailDomains = comp.emailDomains ? comp.emailDomains : []

                self.setState({
                    selectedRepositoriesCompany: selectedCompanyRepositories,
                    highlightDomainsCompany: selectedHighlightDomainsCompany,
                    functionRolesCompany: selectedFeaturesCompany,
                    selectedSearchDomainsCompany: selectedSearchDomainsCompany,
                    availableRepositoriesCompany: availableRepositoriesCompany,
                    availableFeaturesCompany: availableFeaturesCompany,
                    availableHighlightDomainsCompany: availableHighlightDomainsCompany,
                    availableSearchDomainsCompany: availableSearchDomainsCompany,
                    selectedCompanyName: companyName,
                    selectedCompanyDefaultBackend: defaultBackend,
                    selectedCompanyDefaultChemistryBackend: defaultChemBackend,
                    selectedCompanyApplicationName: companyApplicationName,
                    selectedCompanyMaxUsers: companyMaxUsers,
                    selectedCompanyMaxDownloads: companyMaxDownloads,
                    displayEditCompany: true,
                    selectedCompanyLandingPage: landingPage,
                    companyActive: companyActive,
                    selectedCompanyBackgroundColor: backgroundColor,
                    selectedCompanyFontColor: fontColor,
                    selectedCompanyApplicationHeader1: applicationHeader1,
                    selectedCompanyApplicationHeader2: applicationHeader2,
                    selectedCompanyApplicationHeader3: applicationHeader3,
                    selectedCompanyLogo: logo,
                    selectedCompanyUseCustomHeader: useCustomHeader,
                    selectedCompanyEmailDomains: emailDomains,
                    highlightedUser: undefined,
                    selectedUsers: [],
                    selectedUsersTable: [],
                    selectedUserDetails: [],
                    displayCustomToast: false
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    openEditDepartment = async (e, id) => {
        let self = this
        this.toastBC.current.clear()
        await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${self.state.selectedCompany}`, { headers: getHeaderToken() })
            .then(async function (response) {

                let comp = response.data

                let companyBackgroundColor
                let companyFontColor
                let companyApplicationHeader1
                let companyApplicationHeader2
                let companyApplicationHeader3
                let companyLogo
                let companyDefaultBackend
                let companyDefaultChemBackend
                let companyEmailDomains
                let companyMaxDownloads

                companyBackgroundColor = (comp.backgroundColor && comp.useCustomHeader) ? comp.backgroundColor : undefined
                companyFontColor = (comp.fontColor && comp.useCustomHeader) ? comp.fontColor : undefined
                companyApplicationHeader1 = (comp.applicationHeader1 && comp.useCustomHeader) ? comp.applicationHeader1 : ''
                companyApplicationHeader2 = (comp.applicationHeader2 && comp.useCustomHeader) ? comp.applicationHeader2 : ''
                companyApplicationHeader3 = (comp.applicationHeader3 && comp.useCustomHeader) ? comp.applicationHeader3 : ''
                companyLogo = (comp.logo && comp.useCustomHeader) ? comp.logo : undefined
                companyDefaultBackend = comp.defaultBackendApi ? comp.defaultBackendApi.id : null
                companyDefaultChemBackend = comp.defaultChemistryBackendApi ? comp.defaultChemistryBackendApi.id : null
                companyEmailDomains = comp.emailDomains ? comp.emailDomains : []
                companyMaxDownloads = comp.numberMaximumEntriesToExport ? comp.numberMaximumEntriesToExport : null

                await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/departments/${id}`, { headers: getHeaderToken() })
                    .then(function (response) {
                        let dep = response.data
                        let selectedDepartmentName
                        let selectedDepartmentDefaultBackend
                        let selectedDepartmentDefaultChemBackend
                        let selectedDepartmentApplicationName
                        let selectedDepartment
                        let selectedDepartmentRepositories = []
                        let selectedSearchDomainsDepartment = []
                        let selectedHighlightDomainsDepartment = []
                        let selectedFeaturesDepartment = []
                        let availableRepositoriesCompany = []
                        let availableRepositoriesDepartment = []
                        let availableSearchDomainsCompany = []
                        let availableHighlightDomainsCompany = []
                        let availableFeaturesCompany = []
                        let departmentActive
                        let startTrialPeriod
                        let endTrialPeriod
                        let departmentLandingPage
                        let backgroundColor
                        let fontColor
                        let applicationHeader1
                        let applicationHeader2
                        let applicationHeader3
                        let logo
                        let useCustomHeader
                        let emailDomains
                        let maxDownloads

                        dep.selectedRepositories && dep.selectedRepositories.forEach(rep => {
                            selectedDepartmentRepositories = [...selectedDepartmentRepositories, rep.id]
                        })

                        dep.selectedDomains && dep.selectedDomains.forEach(dom => {
                            selectedSearchDomainsDepartment = [...selectedSearchDomainsDepartment, dom.name]
                        })

                        dep.selectedAnnotationTypes && dep.selectedAnnotationTypes.forEach(anno => {
                            selectedHighlightDomainsDepartment = [...selectedHighlightDomainsDepartment, anno.name]
                        })

                        availableRepositoriesDepartment = dep.availableRepositories ? dep.availableRepositories : []

                        selectedFeaturesDepartment = dep.selectedFunctionRoles ? dep.selectedFunctionRoles : []

                        selectedDepartment = dep.id

                        selectedDepartmentName = dep.name ? dep.name : ''

                        selectedDepartmentDefaultBackend = dep.defaultBackendApi ? dep.defaultBackendApi.id : companyDefaultBackend

                        selectedDepartmentDefaultChemBackend = dep.defaultChemistryBackendApi ? dep.defaultChemistryBackendApi.id : companyDefaultChemBackend

                        selectedDepartmentApplicationName = dep.applicationName ? dep.applicationName : ''

                        startTrialPeriod = dep.startTrialPeriod ? new Date(dep.startTrialPeriod) : ''

                        endTrialPeriod = dep.endTrialPeriod ? new Date(dep.endTrialPeriod) : ''

                        departmentActive = dep.activationState ? dep.activationState : 'activated'

                        departmentLandingPage = dep.landingPage ? dep.landingPage : 'docsearch'

                        availableSearchDomainsCompany = dep.availableDomains ? dep.availableDomains : []

                        availableHighlightDomainsCompany = dep.availableAnnotationTypes ? dep.availableAnnotationTypes : []

                        availableRepositoriesCompany = dep.availableRepositories ? dep.availableRepositories : []

                        availableFeaturesCompany = dep.availableFunctionRoles ? dep.availableFunctionRoles : []

                        backgroundColor = dep.backgroundColor ? dep.backgroundColor : (companyBackgroundColor && !dep.useCustomHeader && !dep.fontColor && !dep.logo
                            && !dep.applicationHeader2 && !dep.applicationHeader3 && !dep.applicationHeader1) ? companyBackgroundColor : undefined
                        fontColor = dep.fontColor ? dep.fontColor : (companyFontColor && !dep.useCustomHeader && !dep.backgroundColor && !dep.logo && !dep.applicationHeader2
                            && !dep.applicationHeader1 && !dep.applicationHeader3) ? companyFontColor : undefined
                        applicationHeader1 = dep.applicationHeader1 ? dep.applicationHeader1 : (!dep.useCustomHeader && !dep.applicationHeader2 && !dep.applicationHeader3 && companyApplicationHeader1) ? companyApplicationHeader1 : ''
                        applicationHeader2 = dep.applicationHeader2 ? dep.applicationHeader2 : (!dep.useCustomHeader && !dep.applicationHeader1 && !dep.applicationHeader3 && companyApplicationHeader2) ? companyApplicationHeader2 : ''
                        applicationHeader3 = dep.applicationHeader3 ? dep.applicationHeader3 : (!dep.useCustomHeader && !dep.applicationHeader1 && !dep.applicationHeader2 && companyApplicationHeader3) ? companyApplicationHeader3 : ''
                        logo = dep.logo && dep.logo !== APP_PROPERTIES.HEADER_LOGO_APP ? dep.logo : companyLogo ? companyLogo : undefined
                        useCustomHeader = dep.useCustomHeader

                        emailDomains = dep.emailDomains && dep.emailDomains.length > 0 ? dep.emailDomains : companyEmailDomains
                        maxDownloads = (dep.numberMaximumEntriesToExport && companyMaxDownloads !== null && dep.numberMaximumEntriesToExport < companyMaxDownloads) ? dep.numberMaximumEntriesToExport
                            : (dep.numberMaximumEntriesToExport && companyMaxDownloads === null) ? dep.numberMaximumEntriesToExport
                                : companyMaxDownloads

                        self.setState({
                            displayEditDepartment: true,
                            departmentActive: departmentActive,
                            selectedDepartmentName: selectedDepartmentName,
                            selectedDepartmentDefaultBackend: selectedDepartmentDefaultBackend,
                            selectedDepartmentDefaultChemistryBackend: selectedDepartmentDefaultChemBackend,
                            selectedDepartmentApplicationName: selectedDepartmentApplicationName,
                            startTrialPeriodDepartment: startTrialPeriod,
                            endTrialPeriodDepartment: endTrialPeriod,
                            selectedDepartment: selectedDepartment,
                            selectedDepartmentRepositories: selectedDepartmentRepositories,
                            selectedHighlightDomainsDepartment: selectedHighlightDomainsDepartment,
                            selectedSearchDomainsDepartment: selectedSearchDomainsDepartment,
                            selectedFeaturesDepartment: selectedFeaturesDepartment,
                            availableRepositoriesCompany: availableRepositoriesCompany,
                            availableFeaturesCompany: availableFeaturesCompany,
                            availableHighlightDomainsCompany: availableHighlightDomainsCompany,
                            availableSearchDomainsCompany: availableSearchDomainsCompany,
                            availableRepositoriesDepartment: availableRepositoriesDepartment,
                            selectedDepartmentLandingPage: departmentLandingPage,
                            selectedDepartmentBackgroundColor: backgroundColor,
                            selectedDepartmentFontColor: fontColor,
                            selectedDepartmentApplicationHeader1: applicationHeader1,
                            selectedDepartmentApplicationHeader2: applicationHeader2,
                            selectedDepartmentApplicationHeader3: applicationHeader3,
                            selectedDepartmentLogo: logo,
                            selectedDepartmentUseCustomHeader: useCustomHeader,
                            selectedDepartmentEMailDomains: emailDomains,
                            selectedDepartmentMaxDownloads: maxDownloads,
                            availableCompanyEmailDomains: companyEmailDomains && companyEmailDomains.length > 0 ? companyEmailDomains : dep.emailDomains,
                            companyEmailDomains: companyEmailDomains,
                            highlightedUser: undefined,
                            selectedUsers: [],
                            selectedUsersTable: [],
                            selectedUserDetails: [],
                            displayCustomToast: false,
                            selectedCompanyMaxDownloads: companyMaxDownloads
                        })
                    }).catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })

            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })


    }

    onDeleteDepartmentAndUsers = async () => {
        let self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/departments/${self.state.selectedDepartment}`, { headers: getHeaderToken() })
            .then(function (response) {
                self.showDeleteDepartmentSuccessGrowl()
                self.setState({
                    displayDeleteDepartment: false,
                    displayEditDepartment: false
                }, () => self.refreshDepartments(self.state.selectedCompany))
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showDeleteDepartmentFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showDeleteDepartmentFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    openAddUser = (e, id, name) => {
        this.toastBC.current.clear()
        this.setState({
            displayAddUser: true,
            selectedDepartment: id,
            selectedDepartmentName: name,
            highlightedUser: undefined,
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            displayCustomToast: false
        })
    }

    openDeleteUsers = () => {
        this.setState({
            displayDeleteUsers: true
        })
    }

    openDeleteUsersGlobal = (users) => {
        this.setState({
            displayDeleteUsers: true,
            selectedUserDetails: users
        })
    }

    openMoveUsers = () => {
        this.setState({
            displayMoveUsers: true,
            highlightedUser: undefined
        })
    }

    openMoveUsersGlobal = (users) => {
        this.setState({
            displayMoveUsers: true,
            selectedUserDetails: users
        })
    }

    openActivateUsers = () => {
        this.setState({
            displayActivateUsers: true,
            highlightedUser: undefined
        })
    }

    openActivateUsersGlobal = (users) => {
        this.setState({
            displayActivateUsers: true,
            selectedUserDetails: users
        })
    }

    openInactivateUsers = () => {
        this.setState({
            displayInactivateUsers: true,
            highlightedUser: undefined
        })
    }

    openInactivateUsersGlobal = (users) => {
        this.setState({
            displayInactivateUsers: true,
            selectedUserDetails: users
        })
    }

    openEditUser = (e, user, name) => {
        this.toastBC.current.clear()
        this.setState({
            displayEditUser: true,
            selectedUserID: user.id,
            selectedUserName: user.lastName,
            selectedUserForename: user.forename,
            selectedUserEmail: user.email,
            selectedUserRole: user.adminRole ? user.adminRole : user.highestAdminType ? user.highestAdminType : undefined,
            selectedUserActive: user.deactivated,
            selectedUserCompany: user.company,
            selectedUserUsername: user.username,
            selectedUserDepartment: user.department,
            selectedUserSAML: user.samlLogin ? user.samlLogin : false,
            selectedDepartmentName: name,
            highlightedUser: undefined,
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            displayCustomToast: false
        })
    }

    openVerifyUser = (e, user) => {
        this.toastBC.current.clear()
        this.setState({
            displayVerifyUser: true,
            selectedUserID: user.id,
            selectedUserName: user.lastName,
            selectedUserForename: user.forename,
            highlightedUser: undefined,
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            displayCustomToast: false
        })
    }

    openResendVerification = (e, user) => {
        this.toastBC.current.clear()
        this.setState({
            displayResendVerification: true,
            selectedUserID: user.id,
            selectedUserName: user.lastName,
            selectedUserForename: user.forename,
            selectedUserEmail: user.email,
            highlightedUser: undefined,
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            displayCustomToast: false
        })
    }

    openSelectUser = (user) => {
        let departmentKey = user.department.name + '-' + user.department.id
        this.setState({
            displayGlobalUserSearch: false,
            expandedKeys: { [departmentKey]: true },
            activeIndex: 0
        }, () => {
            this.handleCompanyChange(user.company.id, user.username)
        })
    }

    onCancelAddCompany = () => {
        this.setState({
            displayAddCompany: false
        })
    }

    onCancelAddDepartment = () => {
        this.setState({
            displayAddDepartment: false
        })
    }

    onCancelAddUser = () => {
        this.setState({
            displayAddUser: false
        })
    }

    onCancelLogoutUser = () => {
        this.setState({
            displayLogoutDialog: false
        })
    }

    onCancelAddRepository = () => {
        this.setState({
            displayAddRepository: false
        })
    }

    onCancelAddDomain = () => {
        this.setState({
            displayAddDomain: false
        })
    }

    onCancelAddFeature = () => {
        this.setState({
            displayAddFeature: false
        })
    }

    onCancelEditCompany = () => {
        this.setState({
            displayEditCompany: false
        })
    }

    onCancelEditDepartment = () => {
        this.setState({
            displayEditDepartment: false
        })
    }

    setLandingPage = async (name) => {
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${self.state.selectedCompany}/landing-page`, {
            landingPage: name
        }, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    selectedCompanyLandingPage: name
                }, () => {
                    self.showSetLandingPageSuccessGrowl()
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showSetLandingPageFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showSetLandingPageFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    callbackFromDefaultBackendChange = async (backendId, type) => {
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/backend-apis?backendApiId=${backendId}&companyIds=${this.state.selectedCompany}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (type === 'webapi') {
                    self.setState({
                        selectedCompanyDefaultBackend: backendId
                    }, () => {
                        self.showChangeDefaultBackendSuccessGrowl()
                    })
                } else {
                    self.setState({
                        selectedCompanyDefaultChemistryBackend: backendId
                    }, () => {
                        self.showChangeDefaultChemBackendSuccessGrowl()
                    })
                }
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showChangeDefaultBackendFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    callbackFromDefaultBackendChangeDepartment = async (backendId, type) => {
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/backend-apis?backendApiId=${backendId}&departmentIds=${this.state.selectedDepartment}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (type === 'webapi') {
                    self.setState({
                        selectedDepartmentDefaultBackend: backendId
                    }, () => {
                        self.showChangeDefaultBackendSuccessGrowl()
                    })
                } else {
                    self.setState({
                        selectedDepartmentDefaultChemistryBackend: backendId
                    }, () => {
                        self.showChangeDefaultChemBackendSuccessGrowl()
                    })
                }
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showChangeDefaultBackendFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    onCancelEditUser = () => {
        this.setState({
            displayEditUser: false
        })
    }

    onCancelGlobalSettings = () => {
        this.setState({
            displayGlobalSettings: false
        })
    }

    onCancelGlobalUserSearch = () => {
        this.setState({
            displayGlobalUserSearch: false,
            selectedUserDetails: []
        })
    }

    onAddCompany = async (selectedHighlightDomains, newCompanyApplicationName, selectedRepositories, newCompanyChecked, selectedSearchDomains, selectedFeatures, maxUsers, newCompanyName, landingPage, customHeaderChecked, applicationHeader1, applicationHeader2, applicationHeader3, backgroundColor, fontColor, logo, propertiesLogo, emailDomains, maxDownloads) => {
        let annotationTypes = []
        this.state.allDomainsHighlightItems.forEach(item => {
            selectedHighlightDomains.forEach(selectedItem => {
                if (item.name === selectedItem) {
                    annotationTypes = [...annotationTypes, item.id]
                }
            })
        })

        let domains = []
        this.state.allDomainSelectItems.forEach(item => {
            selectedSearchDomains.forEach(selectedItem => {
                if (item.name === selectedItem) {
                    domains = [...domains, item.id]
                }
            })
        })

        let functionRoles = []
        this.state.allAvailableFeatures.forEach(item => {
            selectedFeatures.forEach(selectedItem => {
                if (item.name === selectedItem) {
                    functionRoles = [...functionRoles, item.id]
                }
            })
        })

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/companies`, {
            active: newCompanyChecked,
            annotationTypes: annotationTypes,
            applicationName: newCompanyApplicationName,
            domains: domains,
            functionRoles: functionRoles,
            maximumUsers: maxUsers,
            name: newCompanyName,
            selectedRepositories: selectedRepositories,
            landingPage: landingPage,
            applicationHeader1: applicationHeader1 !== '' ? applicationHeader1 : null,
            applicationHeader2: applicationHeader2 !== '' ? applicationHeader2 : null,
            applicationHeader3: applicationHeader3 !== '' ? applicationHeader3 : null,
            fontColor: fontColor !== '' ? fontColor : null,
            backgroundColor: backgroundColor !== '' ? backgroundColor : null,
            logo: logo !== propertiesLogo ? logo : null,
            updateLogo: logo !== propertiesLogo ? true : false,
            useCustomHeader: customHeaderChecked ? true : false,
            emailDomains: emailDomains && emailDomains.length > 0 ? emailDomains : null,
            customDashboards: null,
            numberMaximumEntriesToExport: maxDownloads
        }, { headers: getHeaderToken() })
            .then(function (response) {
                self.showCreateCompanySuccessGrowl()
                self.setState({
                    displayAddCompany: false
                }, () => self.refreshCompanies())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showCreateCompanyFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showCreateCompanyFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    editCompany = async (companyActive, selectedHighlightDomains, newCompanyApplicationName, selectedSearchDomains, selectedFeatures, maxUsers, newCompanyName, selectedRepositories, landingPage, customHeaderChecked, applicationHeader1, applicationHeader2, applicationHeader3, backgroundColor, fontColor, logo, propertiesLogo, emailDomains, maxDownloads) => {
        let annotationTypes = []
        this.state.allDomainsHighlightItems.forEach(item => {
            selectedHighlightDomains.forEach(selectedItem => {
                if (item.name === selectedItem) {
                    annotationTypes = [...annotationTypes, item.id]
                }
            })
        })

        let domains = []
        this.state.allDomainSelectItems.forEach(item => {
            selectedSearchDomains.forEach(selectedItem => {
                if (item.name === selectedItem) {
                    domains = [...domains, item.id]
                }
            })
        })

        let functionRoles = []
        this.state.allAvailableFeatures.forEach(item => {
            selectedFeatures.forEach(selectedItem => {
                if (item.name === selectedItem) {
                    functionRoles = [...functionRoles, item.id]
                }
            })
        })

        let file = await fetch(logo).then(r => r.blob()).then(blobFile => new File([blobFile], "test", { type: "image/png" }))
        let logoData = {}
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async function (e) {
            logoData = reader.result
            logo = logoData

            let self = this;
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/companies/${self.state.selectedCompany}`, {
                active: companyActive,
                annotationTypes: annotationTypes,
                applicationName: newCompanyApplicationName,
                domains: domains,
                functionRoles: functionRoles,
                maximumUsers: maxUsers,
                name: newCompanyName,
                selectedRepositories: selectedRepositories,
                landingPage: landingPage,
                applicationHeader1: applicationHeader1 !== '' ? applicationHeader1 : null,
                applicationHeader2: applicationHeader2 !== '' ? applicationHeader2 : null,
                applicationHeader3: applicationHeader3 !== '' ? applicationHeader3 : null,
                fontColor: fontColor !== '' ? fontColor : null,
                backgroundColor: backgroundColor !== '' ? backgroundColor : null,
                logo: logo !== self.state.selectedCompanyLogo ? logo : null,
                updateLogo: logo !== self.state.selectedCompanyLogo ? true : false,
                useCustomHeader: customHeaderChecked ? true : false,
                emailDomains: emailDomains && emailDomains.length > 0 ? emailDomains : null,
                customDashboards: null,
                numberMaximumEntriesToExport: maxDownloads
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.showEditCompanySuccessGrowl()
                    self.setState({
                        displayEditCompany: false
                    }, () => self.refreshCompanies(),
                        self.refreshDepartments(self.state.selectedCompany))
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEditCompanyFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showEditCompanyFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }.bind(this)
    }

    onAddRepository = async (repositoryLabel, repositoryName, repositoryAbbreviation, apiLabel, apiName, apiAbbreviation, backend, repositoryFeatures, repositoryDescription) => {
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories`, {
            abbreviation: repositoryAbbreviation,
            label: repositoryLabel,
            name: repositoryName,
            description: repositoryDescription,
            features: repositoryFeatures,
            statistics: true,
            backendId: backend
        }, { headers: getHeaderToken() })
            .then(function (response) {
                self.showCreateRepositorySuccessGrowl()
                self.setState({
                    displayAddRepository: false
                }, () => self.refreshRepositories(),
                    self.refreshCompanies())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showCreateRepositoryFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showCreateRepositoryFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onCopyRepository = async (repositoryLabel, repositoryName, repositoryAbbreviation, apiLabel, apiName, apiAbbreviation, backend, repositoryFeatures, repositoryDescription) => {
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories`, {
            abbreviation: repositoryAbbreviation,
            label: repositoryLabel,
            name: repositoryName,
            description: repositoryDescription,
            features: repositoryFeatures,
            statistics: true,
            backendId: backend
        }, { headers: getHeaderToken() })
            .then(function (response) {
                self.showCreateRepositorySuccessGrowl()
                self.setState({
                    displayCopyRepository: false
                }, () => self.refreshRepositories(),
                    self.refreshCompanies())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showCreateRepositoryFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showCreateRepositoryFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onDeleteRepository = async (id) => {
        let self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/repositories?repositoryId=${id}`,
            { headers: getHeaderToken(), data: { id } })
            .then(function (response) {
                self.showDeleteRepositorySuccessGrowl()
                self.setState({
                    displayDeleteRepository: false
                }, () => self.refreshRepositories(),
                    self.refreshCompanies())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showDelteRepositoryFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showDeleteRepositoryFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onAddDomain = async (label, name, color) => {
        let self = this;
        const request = {
            label: label.charAt(0).toUpperCase() + label.slice(1),
            name: name
        };
        if (color) {
            request.color = color;
        }
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains`, request, { headers: getHeaderToken() })
            .then(function (response) {
                self.showCreateDomainSuccessGrowl()
                self.setState({
                    displayAddDomain: false
                }, () => self.refreshDomains(),
                    self.refreshCompanies()
                )
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showCreateDomainFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showCreateDomainFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onAddFeature = async (label, name, canBeDeleted, isDefault) => {
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/function-roles`, {
            label: label,
            name: name,
            canBeDeleted: canBeDeleted,
            isDefault: isDefault
        }, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    displayAddFeature: false
                }, () => self.refreshDomains(),
                    self.refreshCompanies()
                )
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showCreateDomainFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showCreateDomainFailGrowl(error.response.data)
                }
            })
    }

    onEditRepository = async (id, label, name, features, description) => {
        var request = {
            id: id, label: label, name: name, abbreviation: this.state.selectedRepositoryAbbreviation, description: description,
            active: this.state.selectedRepositoryActive, backendId: this.state.selectedRepositoryBackend,
            statistics: this.state.selectedRepositoryStatistics
        };
        if (!isArrayEmpty(features)) {
            request.features = features;
        }
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories/${id}`, request, { headers: getHeaderToken() })
            .then(function (response) {
                self.showEditRepositorySuccessGrowl()
                self.setState({
                    displayEditRepository: false
                }, () => self.refreshRepositories(),
                    self.refreshCompanies())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showEditRepositoryFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showEditRepositoryFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onSetupRepository = async (id, label, name, features, description) => {
        let self = this;
        self.setState({
            displaySetupRepository: false
        }, () => self.refreshRepositories(),
            self.refreshCompanies())
    }

    onSetupFeature = async (id, label, name, features, description) => {
        let self = this;
        self.setState({
            displaySetupFeature: false
        }, () => self.refreshRepositories(),
            self.refreshCompanies())
    }

    onEditDomain = async (id, label, name, active, color, description, orderPriority) => {
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains/${id}`, {
            id: id, label: label, name: name, active: active, description: description, color: color, orderPriority: orderPriority
        }, { headers: getHeaderToken() })
            .then(function (response) {
                self.showEditDomainSuccessGrowl()
                self.setState({
                    displayEditDomain: false
                }, () => self.refreshDomains(),
                    self.refreshCompanies())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showEditDomainFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showEditDomainFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onSetupDomain = async (id, label, name) => {
        let self = this;
        self.setState({
            displaySetupDomain: false
        }, () => self.refreshDomains(),
            self.refreshCompanies())
    }

    onEditFeature = async (id, label, name, canBeDeleted, isDefault) => {
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/function-roles/${id}`, {
            id: id, label: label, name: name, canBeDeleted: canBeDeleted, isDefault: isDefault
        }, { headers: getHeaderToken() })
            .then(function (response) {
                self.showEditFeatureSuccessGrowl()
                self.setState({
                    displayEditFeature: false
                }, () => self.refreshFeatures(),
                    self.refreshCompanies())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showEditFeatureFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showEditDomainFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onDeleteDomain = async (id) => {
        let self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains?domainId=${id}`,
            { headers: getHeaderToken(), data: { id } })
            .then(function (response) {
                self.showDeleteDomainSuccessGrowl()
                self.setState({
                    displayDeleteDomain: false
                }, () => self.refreshDomains(),
                    self.refreshCompanies())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showDelteDomainFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showDeleteDomainFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onAddDepartment = async (activeDepartment, selectedHighlightDomains, newDepartmentApplicationName, selectedSearchDomains, endDemoTime, endDemoChecked, selectedFeatures, newDepartmentName, selectedRepositories, startDemoTime, startDemoChecked, landingPage, customHeaderChecked, applicationHeader1, applicationHeader2, applicationHeader3, backgroundColor, fontColor, logo, companyLogo, emailDomains, maxDownloads) => {
        //console.log(emailDomains)
        //console.log(maxDownloads)
        if (activeDepartment === 'demo' && endDemoChecked && startDemoChecked && (dateFormat(endDemoTime, 'yyyy-mm-dd') <= dateFormat(startDemoTime, 'yyyy-mm-dd'))) {
            this.showTimeFrameFailGrowl()
        } else {
            let annotationTypes = []
            this.state.availableHighlightDomainsCompany.forEach(item => {
                selectedHighlightDomains.forEach(selectedItem => {
                    if (item.name === selectedItem) {
                        annotationTypes = [...annotationTypes, item.id]
                    }
                })
            })

            let domains = []
            this.state.availableSearchDomainsCompany.forEach(item => {
                selectedSearchDomains.forEach(selectedItem => {
                    if (item.name === selectedItem) {
                        domains = [...domains, item.id]
                    }
                })
            })

            let functionRoles = []
            this.state.availableFeaturesCompany.forEach(item => {
                selectedFeatures.forEach(selectedItem => {
                    if (item.name === selectedItem) {
                        functionRoles = [...functionRoles, item.id]
                    }
                })
            })

            let file = await fetch(logo).then(r => r.blob()).then(blobFile => new File([blobFile], "test", { type: "image/png" }))
            let logoData = {}
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async function (e) {
                logoData = reader.result
                logo = logoData

                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/departments/${self.state.selectedCompany}`, {
                    activationState: activeDepartment,
                    annotationTypes: annotationTypes,
                    applicationName: newDepartmentApplicationName,
                    domains: domains,
                    endTrialPeriod: activeDepartment === 'demo' && endDemoChecked ? dateFormat(endDemoTime, 'yyyy-mm-dd') : null,
                    functionRoles: functionRoles,
                    name: newDepartmentName,
                    selectedRepositories: selectedRepositories,
                    startTrialPeriod: activeDepartment === 'demo' && startDemoChecked ? dateFormat(startDemoTime, 'yyyy-mm-dd') : null,
                    landingPage: landingPage,
                    applicationHeader1: applicationHeader1 !== '' ? applicationHeader1 : null,
                    applicationHeader2: applicationHeader2 !== '' ? applicationHeader2 : null,
                    applicationHeader3: applicationHeader3 !== '' ? applicationHeader3 : null,
                    fontColor: fontColor !== '' ? fontColor : null,
                    backgroundColor: backgroundColor !== '' ? backgroundColor : null,
                    logo: logo !== companyLogo ? logo : companyLogo,
                    updateLogo: true,
                    useCustomHeader: customHeaderChecked ? true : false,
                    emailDomains: emailDomains && emailDomains.length > 0 ? emailDomains : null,
                    customDashboards: null,
                    numberMaximumEntriesToExport: maxDownloads
                }, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.showCreateDepartmentSuccessGrowl()
                        self.setState({
                            displayAddDepartment: false
                        }, () => self.refreshDepartments(self.state.selectedCompany))
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showCreateDepartmentFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            self.showCreateDepartmentFailGrowl(error.response.data)
                            console.log("not found")
                        }
                    })
            }.bind(this)
        }
    }

    onEditDepartment = async (activeDepartment, selectedHighlightDomains, newDepartmentApplicationName, selectedSearchDomains, endDemoTime, endDemoChecked, selectedFeatures, newDepartmentName, selectedRepositories, startDemoTime, startDemoChecked, landingPage, customHeaderChecked, applicationHeader1, applicationHeader2, applicationHeader3, backgroundColor, fontColor, logo, oldLogo, emailDomains, maxDownloads) => {
        //console.log(maxDownloads)
        if (activeDepartment === 'demo' && endDemoChecked && startDemoChecked && (dateFormat(endDemoTime, 'yyyy-mm-dd') <= dateFormat(startDemoTime, 'yyyy-mm-dd'))) {
            this.showTimeFrameFailGrowl()
        } else {
            let annotationTypes = []
            this.state.availableHighlightDomainsCompany.forEach(item => {
                selectedHighlightDomains.forEach(selectedItem => {
                    if (item.name === selectedItem) {
                        annotationTypes = [...annotationTypes, item.id]
                    }
                })
            })

            let domains = []
            this.state.availableSearchDomainsCompany.forEach(item => {
                selectedSearchDomains.forEach(selectedItem => {
                    if (item.name === selectedItem) {
                        domains = [...domains, item.id]
                    }
                })
            })

            let functionRoles = []
            this.state.availableFeaturesCompany.forEach(item => {
                selectedFeatures.forEach(selectedItem => {
                    if (item.name === selectedItem) {
                        functionRoles = [...functionRoles, item.id]
                    }
                })
            })

            let file = await fetch(logo).then(r => r.blob()).then(blobFile => new File([blobFile], "test", { type: "image/png" }))
            let logoData = {}
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async function (e) {
                logoData = reader.result
                logo = logoData

                let self = this;
                await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/departments/${self.state.selectedDepartment}`, {
                    activationState: activeDepartment,
                    annotationTypes: annotationTypes,
                    applicationName: newDepartmentApplicationName,
                    domains: domains,
                    endTrialPeriod: activeDepartment === 'demo' && endDemoChecked ? dateFormat(endDemoTime, 'yyyy-mm-dd') : null,
                    functionRoles: functionRoles,
                    name: newDepartmentName === 'Unassigned / Inactive regular users' ? 'default' : newDepartmentName,
                    selectedRepositories: selectedRepositories,
                    startTrialPeriod: activeDepartment === 'demo' && startDemoChecked ? dateFormat(startDemoTime, 'yyyy-mm-dd') : null,
                    landingPage: landingPage,
                    applicationHeader1: applicationHeader1 !== '' ? applicationHeader1 : null,
                    applicationHeader2: applicationHeader2 !== '' ? applicationHeader2 : null,
                    applicationHeader3: applicationHeader3 !== '' ? applicationHeader3 : null,
                    fontColor: fontColor !== '' ? fontColor : null,
                    backgroundColor: backgroundColor !== '' ? backgroundColor : null,
                    logo: logo ? logo : null,
                    updateLogo: logo !== oldLogo ? true : null,
                    useCustomHeader: customHeaderChecked,
                    emailDomains: emailDomains && emailDomains.length > 0 ? emailDomains : null,
                    customDashboards: null,
                    numberMaximumEntriesToExport: maxDownloads
                }, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.showEditDepartmentSuccessGrowl()
                        self.setState({
                            displayEditDepartment: false
                        }, () => self.refreshDepartments(self.state.selectedCompany)
                        )
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showEditDepartmentFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            self.showEditDepartmentFailGrowl(error.response.data)
                            console.log("not found")
                        }
                    })
            }.bind(this)
        }
    }

    onDeleteCompany = () => {
        this.setState({
            displayDeleteCompany: true
        })
    }

    onDeleteDepartment = () => {
        this.setState({
            displayDeleteDepartment: true
        })
    }

    onDeleteUser = () => {
        this.setState({
            displayDeleteUser: true
        })
    }

    onDeleteSingleUser = async (userID) => {
        let self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/users/${userID}`, { headers: getHeaderToken() })
            .then(function (response) {
                self.showDeleteUserSuccessGrowl()
                self.setState({
                    displayDeleteUser: false,
                    displayEditUser: false
                }, () => {
                    self.refreshDepartments(self.state.selectedCompany)
                    if (self.state.displayGlobalUserSearch) {
                        self.getGlobalUsers()
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showDeleteUserFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showDeleteUserFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onVerifySingleUser = async (userID) => {
        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/users/${userID}/verification`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    displayVerifyUser: false
                }, () => {
                    self.refreshDepartments(self.state.selectedCompany)
                    if (self.state.displayGlobalUserSearch) {
                        self.getGlobalUsers()
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showVerifyUserFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showVerifyUserFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onResendVerification = async (userID) => {
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/users/${userID}/verification/email`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                self.showResendVerificationSuccessGrowl()
                self.setState({
                    displayResendVerification: false
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showResendVerificationFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showResendVerificationFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onDeleteUsers = async () => {
        let userIds = ''
        this.state.selectedUserDetails.forEach(key => {
            userIds += key.id + ','
        })
        userIds = userIds.slice(0, -1)

        let self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/users/${userIds}`, { headers: getHeaderToken() })
            .then(function (response) {
                self.showDeleteUsersSuccessGrowl()
                self.toastBC.current.clear()
                self.setState({
                    displayDeleteUsers: false,
                    selectedUsers: [],
                    selectedUserDetails: [],
                    allUsersSelected: false,
                    displayCustomToast: false
                }, () => {
                    self.refreshDepartments(self.state.selectedCompany)
                    if (self.state.displayGlobalUserSearch) {
                        self.getGlobalUsers()
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showDeleteUsersFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showDeleteUsersFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onMoveUsers = async (company, department) => {
        if (this.state.activeIndex === 0 || this.state.displayGlobalUserSearch) {
            let logout = false
            let userArray = []
            this.state.selectedUserDetails.forEach(key => {
                userArray = [...userArray, { username: key.userName, newCompany: company, newDepartment: department }]
            })
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/companies/user/update`, userArray, { headers: getHeaderToken() })
                .then(function (response) {
                    self.toastBC.current.clear()
                    self.state.selectedUserDetails.forEach(key => {
                        if (key.userName === self.props.userDetails.username) {
                            self.refreshUserDetails()
                            logout = true
                        }
                    })
                    self.showMoveUsersSuccessGrowl()
                    self.setState({
                        displayMoveUsers: false,
                        selectedUsers: [],
                        selectedUserDetails: [],
                        allUsersSelected: false,
                        displayCustomToast: false,
                        displayLogoutDialog: logout
                    }, () => {
                        self.refreshDepartments(self.state.selectedCompany)
                        if (self.state.displayGlobalUserSearch) {
                            self.getGlobalUsers()
                        }
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showMoveUsersFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showMoveUsersFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        } else {
            let logout = false
            let userArray = []
            this.state.selectedUsersTable.forEach(key => {
                userArray = [...userArray, { username: key, newCompany: company, newDepartment: department }]
            })
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/companies/user/update`, userArray, { headers: getHeaderToken() })
                .then(function (response) {
                    self.toastBC.current.clear()
                    self.state.selectedUsersTable.forEach(key => {
                        if (key === self.props.userDetails.username) {
                            self.refreshUserDetails()
                            logout = true
                        }
                    })
                    self.showMoveUsersSuccessGrowl()
                    self.setState({
                        displayMoveUsers: false,
                        selectedUsersTable: [],
                        selectedUserDetails: [],
                        allUsersSelected: false,
                        displayCustomToast: false,
                        displayLogoutDialog: logout
                    }, () => {
                        self.refreshDepartments(self.state.selectedCompany)
                        if (self.state.displayGlobalUserSearch) {
                            self.getGlobalUsers()
                        }
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showMoveUsersFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showMoveUsersFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    onActivateUsers = async () => {
        let userIds = ''
        this.state.selectedUserDetails && this.state.selectedUserDetails.forEach(user => {
            userIds += user.id + ','
        })
        userIds = userIds.slice(0, -1)

        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/users/activation?activationStatus=activate&userIds=${userIds}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                self.toastBC.current.clear()
                self.showActivateUsersSuccessGrowl()
                self.setState({
                    displayActivateUsers: false,
                    selectedUsers: [],
                    selectedUsersTable: [],
                    selectedUserDetails: [],
                    allUsersSelected: false,
                    displayCustomToast: false
                }, () => {
                    self.refreshDepartments(self.state.selectedCompany)
                    if (self.state.displayGlobalUserSearch) {
                        self.getGlobalUsers()
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showActivateUsersFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showActivateUsersFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onInactivateUsers = async () => {
        let userIds = ''
        this.state.selectedUserDetails && this.state.selectedUserDetails.forEach(user => {
            userIds += user.id + ','
        })
        userIds = userIds.slice(0, -1)

        let self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/users/activation?activationStatus=deactivate&userIds=${userIds}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                self.showInactivateUsersSuccessGrowl()
                self.toastBC.current.clear()
                self.setState({
                    displayInactivateUsers: false,
                    selectedUsers: [],
                    selectedUsersTable: [],
                    selectedUserDetails: [],
                    allUsersSelected: false,
                    displayCustomToast: false
                }, () => {
                    self.refreshDepartments(self.state.selectedCompany)
                    if (self.state.displayGlobalUserSearch) {
                        self.getGlobalUsers()
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showInactivateUsersFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showInactivateUsersFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    rejectSelectedUsers = () => {
        this.toastBC.current.clear()
        this.setState({
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            allUsersSelected: false,
            highlightedUser: undefined,
            displayCustomToast: false
        })
    }

    onEditUser = async (newUserRole, selectedCompany, newUserActive, selectedDepartment, newUserEmail, newUserForename, newUserName, newUserPassword, newUserPasswordRepeated, newUserUsername, forceUpdatePassword) => {
        if (newUserPassword !== newUserPasswordRepeated) {
            this.showPasswordWarnGrowl()
        } else {
            let encodedPassword = btoa(newUserPassword);
            let self = this;
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/users`, {
                adminRole: newUserRole,
                company: selectedCompany,
                deactivated: newUserActive === true ? false : true,
                department: selectedDepartment,
                email: newUserEmail,
                forename: newUserForename.trim(),
                lastName: newUserName.trim(),
                password: encodedPassword,
                username: newUserUsername,
                forcePassword: forceUpdatePassword
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    if (newUserUsername === self.props.userDetails.username) {
                        self.refreshUserDetails()
                    }
                    self.showEditUserSuccessGrowl()
                    self.setState({
                        displayEditUser: false
                    }, () => {
                        self.refreshDepartments(self.state.selectedCompany)
                        if (self.state.displayGlobalUserSearch) {
                            self.getGlobalUsers()
                        }
                    }
                    )
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEditUserFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showEditUserFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    onEditRegularUser = async (newUserPassword, newUserPasswordRepeated) => {
        if (newUserPassword !== newUserPasswordRepeated) {
            this.showPasswordWarnGrowl()
        } else {
            let self = this;
            let encodedPassword = btoa(newUserPassword);
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/user/password`, encodedPassword, { headers: getHeaderTokenText() })
                .then(function (response) {
                    self.showEditRegularUserSuccessGrowl()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEditUserFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showEditUserFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    onCancelDeleteCompany = () => {
        this.setState({
            displayDeleteCompany: false
        })
    }

    onCancelDeleteDepartment = () => {
        this.setState({
            displayDeleteDepartment: false
        })
    }

    onCancelDeleteUser = () => {
        this.setState({
            displayDeleteUser: false
        })
    }

    onCancelVerifyUser = () => {
        this.setState({
            displayVerifyUser: false
        })
    }

    onCancelResendVerification = () => {
        this.setState({
            displayResendVerification: false
        })
    }

    onCancelDeleteUsers = () => {
        this.setState({
            displayDeleteUsers: false
        })
    }

    onCancelMoveUsers = () => {
        this.setState({
            displayMoveUsers: false
        })
    }

    onCancelActivateUsers = () => {
        this.setState({
            displayActivateUsers: false
        })
    }

    onCancelInactivateUsers = () => {
        this.setState({
            displayInactivateUsers: false
        })
    }

    onDeleteCompanyAndUsers = async () => {
        let self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${self.state.selectedCompany}`, { headers: getHeaderToken() })
            .then(function (response) {
                self.showDeleteCompanySuccessGrowl()
                self.setState({
                    displayDeleteCompany: false,
                    displayEditCompany: false
                }, () => self.refreshCompaniesAfterDelete())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showDeleteCompanyFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showDeleteCompanyFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    showCreateCompanySuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Organization successfully added to database.' };
        this.growl.show(msg)
    }

    showCreateCompanyFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showCreateRepositorySuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Repository successfully added to database.' };
        this.growl.show(msg)
    }

    showCreateRepositoryFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showDeleteRepositorySuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Repository successfully deleted.' };
        this.growl.show(msg)
    }

    showDeleteRepositoryFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showEditRepositorySuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Repository successfully edited.' };
        this.growl.show(msg)
    }

    showEditRepositoryFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showEditDomainSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Domain successfully edited.' };
        this.growl.show(msg)
    }

    showEditDomainFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showEditFeatureSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Feature successfully edited.' };
        this.growl.show(msg)
    }

    showEditFeatureFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showDeleteDomainSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Domain successfully deleted.' };
        this.growl.show(msg)
    }

    showDeleteDomainFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showCreateDomainSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Domain successfully added to database.' };
        this.growl.show(msg)
    }

    showCreateDomainFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showOrderRepositoriesSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Repository order successfully saved.' };
        this.growl.show(msg)
    }

    showOrderRepositoriesFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Repository order could not be saved.', life: 6000 };
        this.growl.show(msg)
    }

    showEditCompanySuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Organization successfully edited.' };
        this.growl.show(msg)
    }

    showEditCompanyFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showChangeDefaultBackendSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Default data backend successfully set.' };
        this.growl.show(msg)
    }

    showChangeDefaultChemBackendSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Default chemistry backend successfully set.' };
        this.growl.show(msg)
    }

    showChangeDefaultBackendFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showSetLandingPageSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Landing page successfully set.' };
        this.growl.show(msg)
    }

    showSetLandingPageFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showDeleteCompanySuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Organization successfully deleted.' };
        this.growl.show(msg)
    }

    showDeleteCompanyFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showCreateDepartmentSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Suborganization successfully added to ${this.state.selectedCompanyName}.` };
        this.growl.show(msg)
    }

    showCreateDepartmentFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showEditDepartmentSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Suborganization successfully edited.` };
        this.growl.show(msg)
    }

    showEditDepartmentFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showDeleteDepartmentSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Suborganization successfully deleted.' };
        this.growl.show(msg)
    }

    showDeleteDepartmentFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    onSaveGlobalSettings = () => {
        this.setState({
            displayGlobalSettings: false
        })
    }

    handleCompanyChange = async (value, userToHighlight) => {
        this.toastBC.current.clear()
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies`, { headers: getHeaderToken() })
            .then(function (response) {
                let companies = response.data
                companies.forEach((comp, i) => {
                    if (comp.id === value) {
                        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/departments`, { headers: getHeaderToken() })
                            .then(function (response) {
                                let departments = response.data
                                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/users`, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        let departmentUsers = response.data

                                        let companyName
                                        let companyApplicationName
                                        let companyLandingPage
                                        let companyMaxUsers
                                        let companyMaxDownloads

                                        companyName = comp.name
                                        companyApplicationName = comp.applicationName ? comp.applicationName : ''
                                        companyMaxUsers = comp.maximumNumberUsers ? comp.maximumNumberUsers : ''
                                        companyMaxDownloads = comp.maximumNumberDownloads ? comp.maximumNumberDownloads : ''
                                        companyLandingPage = comp.landingPage ? comp.landingPage : ''

                                        self.setState({
                                            selectedCompany: value,
                                            selectedCompanyName: companyName,
                                            selectedCompanyApplicationName: companyApplicationName,
                                            selectedCompanyMaxUsers: companyMaxUsers,
                                            selectedCompanyMaxDownloads: companyMaxDownloads,
                                            selectedCompanyLandingPage: companyLandingPage,
                                            selectedDepartments: departments,
                                            selectedUsers: [],
                                            selectedUsersTable: [],
                                            selectedUserDetails: [],
                                            allUsersSelected: false,
                                            departmentUsers: departmentUsers,
                                            highlightedUser: userToHighlight ? userToHighlight : undefined,
                                            displayCustomToast: false
                                        })
                                    }).catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showFailGrowl(error.response.data)
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            }).catch(function (error) {
                                if (error.response.status === 400) {
                                    self.showFailGrowl(error.response.data)
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                    }
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleSelectionUsers = (e) => {
        let names = []
        let userDetails = []
        this.state.departmentUsers && this.state.departmentUsers.forEach(user => {
            names = [...names, user.username]
            userDetails = [...userDetails, { userName: user.username, foreName: user.forename, lastName: user.lastName, id: user.id, role: user.adminRole }]
        })

        let userList = {}
        let userDetailsList = []
        for (let i = 0; i < Object.keys(e.value).length; i++) {
            userList[Object.keys(e.value)[i]] = (e.value[Object.keys(e.value)[i]])
            for (let j = 0; j < userDetails.length; j++) {
                if (userDetails[j].userName === Object.keys(e.value)[i] &&
                    ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (this.props.userDetails.username === userDetails[j].userName) ||
                        (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" & hasUserRole("ROLE_SUPPORT_ADMIN") && userDetails[j].role === "ROLE_SUPPORT_ADMIN") ||
                        (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" & hasUserRole("ROLE_SUPPORT_ADMIN") && userDetails[j].role === "ROLE_COMPANY_ADMIN") ||
                        (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" & hasUserRole("ROLE_SUPPORT_ADMIN") && userDetails[j].role === "ROLE_DEPARTMENT_ADMIN") ||
                        (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" & hasUserRole("ROLE_SUPPORT_ADMIN") && userDetails[j].role === "NONE") ||
                        (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && userDetails[j].role === "ROLE_DEPARTMENT_ADMIN") ||
                        (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && userDetails[j].role === "NONE") ||
                        (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && userDetails[j].role === "NONE"))) {
                    userDetailsList = [...userDetailsList, userDetails[j]]
                }
            }
        }

        if (userDetailsList.length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (userDetailsList.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }

        this.setState({
            expandedKeys: userList,
            selectedUsers: userList,
            selectedUserDetails: userDetailsList,
            highlightedUser: undefined
        })
    }

    handleSelectionUsersTable = (e, userData) => {
        let userDetails = []

        let possibleUsers = []

        this.state.departmentUsers && this.state.departmentUsers.forEach(user => {
            userDetails = [...userDetails, { userName: user.username, foreName: user.forename, lastName: user.lastName, id: user.id }]
            if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.username === this.props.userDetails.username) ||
                (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.adminRole !== 'ROLE_SUPER_ADMIN') ||
                (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.adminRole !== 'ROLE_SUPER_ADMIN' && user.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.adminRole !== 'ROLE_SUPER_ADMIN' &&
                    user.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                    user.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))) {
                possibleUsers = [...possibleUsers, user]
            }
        })

        let selectedUsersTable = [...this.state.selectedUsersTable];

        if (e.checked)
            selectedUsersTable.push(userData.status.username);
        else
            selectedUsersTable.splice(selectedUsersTable.indexOf(userData.status.username), 1);


        let userDetailsList = []
        for (let i = 0; i < selectedUsersTable.length; i++) {
            for (let j = 0; j < userDetails.length; j++) {
                if (userDetails[j].userName === selectedUsersTable[i]) {
                    userDetailsList = [...userDetailsList, userDetails[j]]
                }
            }
        }

        if (userDetailsList.length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (userDetailsList.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }

        this.setState({
            selectedUsersTable: selectedUsersTable,
            selectedUserDetails: userDetailsList,
            allUsersSelected: possibleUsers.length === userDetailsList.length ? true : false,
            highlightedUser: undefined
        })
    }

    onAddUser = async (newUserRole, selectedCompany, newUserActive, selectedDepartment, newUserEmail, newUserForename, newUserName, newUserPassword, newUserPasswordRepeated, newUserUsername, forceUpdatePassword, samlLogin) => {
        if (newUserPassword !== newUserPasswordRepeated) {
            this.showPasswordWarnGrowl()
        } else {
            let self = this;
            let encodedPassword = btoa(newUserPassword);
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/users`, {
                adminRole: newUserRole,
                company: selectedCompany,
                deactivated: newUserActive === true ? false : true,
                department: selectedDepartment,
                email: newUserEmail,
                forename: newUserForename.trim(),
                lastName: newUserName.trim(),
                password: encodedPassword,
                username: newUserUsername.trim(),
                forcePassword: forceUpdatePassword,
                samlLogin: APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.addSSOUser ? samlLogin : null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.showAddUserSuccessGrowl()
                    self.setState({
                        displayAddUser: false
                    }, () => self.refreshDepartments(self.state.selectedCompany))
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showAddUserFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showAddUserFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    onLogoutUser = () => {
        this.props.logout()
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    showPasswordWarnGrowl = () => {
        let msg = { severity: 'error', summary: 'Error!', detail: `The entered passwords are not identical.` };
        this.growl.show(msg)
    }

    showTimeFrameFailGrowl = () => {
        let msg = { severity: 'error', summary: 'Error!', detail: `The entered trial dates are not valid. Expire date has to be larger than start date.`, life: 6000 };
        this.growl.show(msg)
    }

    showAddUserSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `User successfully added to ${this.state.selectedCompanyName}, suborganization ${this.state.selectedDepartmentName === 'default' ? 'Unassigned / Inactive regular users' : this.state.selectedDepartmentName}` };
        this.growl.show(msg)
    }

    showAddUserFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showEditUserSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `User successfully edited.` };
        this.growl.show(msg)
    }

    showEditRegularUserSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `User details successfully edited.` };
        this.growl.show(msg)
    }

    showEditUserFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showDeleteUserSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `User successfully deleted from ${this.state.selectedCompanyName}, suborganization ${this.state.selectedDepartmentName === 'default' ? 'Unassigned / Inactive regular users' : this.state.selectedDepartmentName}.` };
        this.growl.show(msg)
    }

    showDeleteUserFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showVerifyUserFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showResendVerificationSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Verification email successfully sent to user.` };
        this.growl.show(msg)
    }

    showResendVerificationFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showDeleteUsersSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Users successfully deleted.` };
        this.growl.show(msg)
    }

    showDeleteUsersFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showMoveUsersSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Users successfully moved.` };
        this.growl.show(msg)
    }

    showMoveUsersFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showActivateUsersSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Users successfully activated.` };
        this.growl.show(msg)
    }

    showActivateUsersFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showInactivateUsersSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Users successfully inactivated.` };
        this.growl.show(msg)
    }

    showInactivateUsersFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    handleGlobalFilterChange = (filter) => {
        this.setState({
            globalFilterUsers: filter,
            highlightedUser: undefined
        })
    }

    onValueChange = (filteredData) => {
        this.setState({
            filteredUsers: filteredData,
            highlightedUser: undefined
        }, () => {
            let filteredAllowedUsers = []

            this.state.filteredUsers && this.state.filteredUsers.forEach(user => {
                if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.status.username === this.props.userDetails.username) ||
                    (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.status.adminRole !== 'ROLE_SUPER_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.status.adminRole !== 'ROLE_SUPER_ADMIN' && user.status.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.status.adminRole !== 'ROLE_SUPER_ADMIN' &&
                        user.status.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                        user.status.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))) {
                    filteredAllowedUsers = [...filteredAllowedUsers, user]
                }
            })

            if (this.state.selectedUsersTable.length < filteredAllowedUsers.length) {
                this.setState({
                    allUsersSelected: false
                })
            } else if (this.state.selectedUsersTable.length === filteredAllowedUsers.length && filteredAllowedUsers.length !== 0) {
                this.setState({
                    allUsersSelected: true
                })
            }
        })
    }

    checkUserTemplate = (rowData) => {
        return <div key={rowData.status.username}>
            <span className="p-column-title">Select user</span>
            <span>
                <Checkbox
                    key={rowData.status.username}
                    disabled={(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (rowData.status.username === this.props.userDetails.username) ||
                        (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && rowData.status.adminRole !== 'ROLE_SUPER_ADMIN') ||
                        (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && rowData.status.adminRole !== 'ROLE_SUPER_ADMIN' && rowData.status.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                        (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (rowData.status.adminRole !== 'ROLE_SUPER_ADMIN' &&
                            rowData.status.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                            rowData.status.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))
                        ? false : true}
                    style={{
                        cursor: (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (rowData.status.username === this.props.userDetails.username) ||
                            (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && rowData.status.adminRole !== 'ROLE_SUPER_ADMIN') ||
                            (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && rowData.status.adminRole !== 'ROLE_SUPER_ADMIN' && rowData.status.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                            (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (rowData.status.adminRole !== 'ROLE_SUPER_ADMIN' &&
                                rowData.status.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                                rowData.status.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))
                            ? 'pointer' : 'default'
                    }}
                    onChange={(e) => this.handleSelectionUsersTable(e, rowData)}
                    checked={this.state.selectedUsersTable.includes(rowData.status.username)}>
                </Checkbox>
            </span>
        </div>
    }



    handleSelectAllUsers = (e) => {
        if (e.checked) {
            let userDetails = []

            if (this.state.filteredUsers && this.state.filteredUsers.length > 0) {
                this.state.filteredUsers && this.state.filteredUsers.forEach(user => {
                    userDetails = [...userDetails, { userName: user.status.username, foreName: user.status.forename, lastName: user.status.lastName, id: user.status.id, adminRole: user.status.adminRole }]
                })
            } else {
                this.state.departmentUsers && this.state.departmentUsers.forEach(user => {
                    userDetails = [...userDetails, { userName: user.username, foreName: user.forename, lastName: user.lastName, id: user.id, adminRole: user.adminRole }]
                })
            }

            let selectedUsersTable = [];

            userDetails.forEach(user => {
                if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.userName === this.props.userDetails.username) ||
                    (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.adminRole !== 'ROLE_SUPER_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.adminRole !== 'ROLE_SUPER_ADMIN' && user.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.adminRole !== 'ROLE_SUPER_ADMIN' &&
                        user.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                        user.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))) {
                    selectedUsersTable.push(user.userName)
                }
            })

            let userDetailsList = []
            for (let i = 0; i < selectedUsersTable.length; i++) {
                for (let j = 0; j < userDetails.length; j++) {
                    if (userDetails[j].userName === selectedUsersTable[i]) {
                        userDetailsList = [...userDetailsList, userDetails[j]]
                    }
                }
            }

            if (userDetailsList.length === 0) {
                this.toastBC.current.clear();
                this.setState({
                    displayCustomToast: false
                })
            } else if (userDetailsList.length !== 0) {
                this.setState({
                    displayCustomToast: true
                })
            }
            this.setState({
                allUsersSelected: true,
                selectedUsersTable: selectedUsersTable,
                selectedUserDetails: userDetailsList,
                highlightedUser: undefined
            })
        } else {
            this.toastBC.current.clear();
            this.setState({
                allUsersSelected: false,
                selectedUserDetails: [],
                selectedUsersTable: [],
                highlightedUser: undefined,
                displayCustomToast: false
            })
        }
    }

    nameTableTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Name</span>
            <span>
                <label style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'break-spaces'
                }} title={`${rowData.name}`}>{`${rowData.name}`}</label>
            </span>
        </div>
    }

    statusTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Status</span>
            {(rowData.status.adminRole === 'ROLE_SUPER_ADMIN' && (!rowData.status.deactivated && rowData.status.verified)) ?
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block'
                        }} title='Super admin'>{`Super admin`}</label>
                    <label
                        className='p-column-title'
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontWeight: 'normal'
                        }} title='Super admin'>{`Super admin`}</label>
                </React.Fragment>
                :
                (rowData.status.adminRole === 'ROLE_SUPPORT_ADMIN' && (!rowData.status.deactivated && rowData.status.verified)) ?
                    <React.Fragment>
                        <label
                            className='labelInference'
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block'
                            }} title='Support admin'>{`Support admin`}</label>
                        <label
                            className='p-column-title'
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal'
                            }} title='Support admin'>{`Support admin`}</label>
                    </React.Fragment>
                    :
                    (rowData.status.adminRole === 'ROLE_SUPER_ADMIN' && (!rowData.status.deactivated && !rowData.status.verified)) ? <div>
                        <label
                            className='labelInference'
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block'
                            }} title='Super admin (not verified by email)'>Super admin (not verified by email)</label>
                        <label
                            className='p-column-title'
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal'
                            }} title='Super admin (not verified by email)'>Super admin (not verified by email)</label>
                        {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN") &&
                            <div>
                                <a id="editUser"
                                    onClick={(e) => this.openResendVerification(e, rowData.status)}
                                    style={{ display: 'inline-block' }}>Resend email</a>
                                <a id="editUser"
                                    onClick={(e) => this.openVerifyUser(e, rowData.status)}
                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                            </div>
                        }
                    </div> :
                        (rowData.status.adminRole === 'ROLE_SUPPORT_ADMIN' && (!rowData.status.deactivated && !rowData.status.verified)) ? <div>
                            <label
                                className='labelInference'
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'block'
                                }} title='Support admin (not verified by email)'>Support admin (not verified by email)</label>
                            <label
                                className='p-column-title'
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'normal'
                                }} title='Support admin (not verified by email)'>Support admin (not verified by email)</label>
                            {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN") &&
                                <div>
                                    <a id="editUser"
                                        onClick={(e) => this.openResendVerification(e, rowData.status)}
                                        style={{ display: 'inline-block' }}>Resend email</a>
                                    <a id="editUser"
                                        onClick={(e) => this.openVerifyUser(e, rowData.status)}
                                        style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                </div>
                            }
                        </div> :
                            (rowData.status.adminRole === 'ROLE_SUPER_ADMIN' && (rowData.status.deactivated && !rowData.status.verified)) ?
                                <React.Fragment>
                                    <label
                                        className='labelInference'
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            display: 'block'
                                        }} title='Super admin (deactivated, not verified by email)'>{`Super admin (deactivated, not verified by email)`}</label>
                                    <label
                                        className='p-column-title'
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontWeight: 'normal'
                                        }} title='Super admin (deactivated, not verified by email)'>{`Super admin (deactivated, not verified by email)`}</label>
                                </React.Fragment>
                                :
                                (rowData.status.adminRole === 'ROLE_SUPPORT_ADMIN' && (rowData.status.deactivated && !rowData.status.verified)) ?
                                    <React.Fragment>
                                        <label
                                            className='labelInference'
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }} title='Support admin (deactivated, not verified by email)'>{`Support admin (deactivated, not verified by email)`}</label>
                                        <label
                                            className='p-column-title'
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                fontWeight: 'normal'
                                            }} title='Support admin (deactivated, not verified by email)'>{`Support admin (deactivated, not verified by email)`}</label>
                                    </React.Fragment>
                                    :
                                    (rowData.status.adminRole === 'ROLE_SUPER_ADMIN' && (rowData.status.deactivated && rowData.status.verified)) ?
                                        <React.Fragment>
                                            <label
                                                className='labelInference'
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    display: 'block'
                                                }} title='Super admin (deactivated)'>{`Super admin (deactivated)`}
                                            </label>
                                            <label
                                                className='p-column-title'
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 'normal'
                                                }} title='Super admin (deactivated)'>{`Super admin (deactivated)`}
                                            </label>
                                        </React.Fragment> :
                                        (rowData.status.adminRole === 'ROLE_SUPPORT_ADMIN' && (rowData.status.deactivated && rowData.status.verified)) ?
                                            <React.Fragment>
                                                <label
                                                    className='labelInference'
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        display: 'block'
                                                    }} title='Support admin (deactivated)'>{`Support admin (deactivated)`}
                                                </label>
                                                <label
                                                    className='p-column-title'
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontWeight: 'normal'
                                                    }} title='Support admin (deactivated)'>{`Support admin (deactivated)`}
                                                </label>
                                            </React.Fragment> :
                                            (rowData.status.adminRole === 'ROLE_COMPANY_ADMIN' && (!rowData.status.deactivated && rowData.status.verified)) ?
                                                <React.Fragment>
                                                    <label
                                                        className='labelInference'
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            display: 'block'
                                                        }} title='Organization admin'>{`Organization admin`}
                                                    </label>
                                                    <label
                                                        className='p-column-title'
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: 'normal'
                                                        }} title='Organization admin'>{`Organization admin`}
                                                    </label>
                                                </React.Fragment> :
                                                (rowData.status.adminRole === 'ROLE_COMPANY_ADMIN' && (!rowData.status.deactivated && !rowData.status.verified)) ? <div>
                                                    <label
                                                        className='labelInference'
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            display: 'block'
                                                        }} title='Organization admin (not verified by email)'>Organization admin (not verified by email)</label>
                                                    <label
                                                        className='p-column-title'
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: 'normal'
                                                        }} title='Organization admin (not verified by email)'>Organization admin (not verified by email)</label>
                                                    {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" || this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN") ?
                                                        <div style={{ marginTop: 5 }}>
                                                            <a id="editUser"
                                                                onClick={(e) => this.openResendVerification(e, rowData.status)}
                                                                style={{ display: 'inline-block' }}>Resend email</a>
                                                            {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                <a id="editUser"
                                                                    onClick={(e) => this.openVerifyUser(e, rowData.status)}
                                                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                            }
                                                        </div>
                                                        : null
                                                    }
                                                </div> :
                                                    (rowData.status.adminRole === 'ROLE_COMPANY_ADMIN' && (rowData.status.deactivated && !rowData.status.verified)) ?
                                                        <React.Fragment>
                                                            <label
                                                                className='labelInference'
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    display: 'block'
                                                                }} title='Organization admin (deactivated, not verified by email)'>{`Organization admin (deactivated, not verified by email)`}
                                                            </label>
                                                            <label
                                                                className='p-column-title'
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    fontWeight: 'normal'
                                                                }} title='Organization admin (deactivated, not verified by email)'>{`Organization admin (deactivated, not verified by email)`}
                                                            </label>
                                                        </React.Fragment>
                                                        :
                                                        (rowData.status.adminRole === 'ROLE_COMPANY_ADMIN' && (rowData.status.deactivated && rowData.status.verified)) ?
                                                            <React.Fragment>
                                                                <label
                                                                    className='labelInference'
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        display: 'block'
                                                                    }} title='Organization admin (deactivated)'>{`Organization admin (deactivated)`}
                                                                </label>
                                                                <label
                                                                    className='p-column-title'
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        fontWeight: 'normal'
                                                                    }} title='Organization admin (deactivated)'>{`Organization admin (deactivated)`}
                                                                </label>
                                                            </React.Fragment>
                                                            :
                                                            (rowData.status.adminRole === 'ROLE_DEPARTMENT_ADMIN' && (!rowData.status.deactivated && rowData.status.verified)) ? `Suborganization admin` :
                                                                (rowData.status.adminRole === 'ROLE_DEPARTMENT_ADMIN' && (!rowData.status.deactivated && !rowData.status.verified)) ? <div>
                                                                    <label
                                                                        className='labelInference'
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            display: 'block'
                                                                        }} title='Suborganization admin (not verified by email)'>Suborganization admin (not verified by email)
                                                                    </label>
                                                                    <label
                                                                        className='p-column-title'
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            fontWeight: 'normal'
                                                                        }} title='Suborganization admin (not verified by email)'>Suborganization admin (not verified by email)
                                                                    </label>
                                                                    {(this.props.userDetails.highestAdminType !== "NONE") &&
                                                                        <div style={{ marginTop: 5 }}>
                                                                            <a id="editUser"
                                                                                onClick={(e) => this.openResendVerification(e, rowData.status)}
                                                                                style={{ display: 'inline-block' }}>Resend email</a>
                                                                            {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                                <a id="editUser"
                                                                                    onClick={(e) => this.openVerifyUser(e, rowData.status)}
                                                                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div> :
                                                                    (rowData.status.adminRole === 'ROLE_DEPARTMENT_ADMIN' && (rowData.status.deactivated && !rowData.status.verified)) ?
                                                                        <React.Fragment>
                                                                            <label
                                                                                className='labelInference'
                                                                                style={{
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    display: 'block'
                                                                                }} title='Suborganization admin (deactivated, not verified by email)'>
                                                                                {`Suborganization admin (deactivated, not verified by email)`}
                                                                            </label>
                                                                            <label
                                                                                className='p-column-title'
                                                                                style={{
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    fontWeight: 'normal'
                                                                                }} title='Suborganization admin (deactivated, not verified by email)'>
                                                                                {`Suborganization admin (deactivated, not verified by email)`}
                                                                            </label>
                                                                        </React.Fragment>
                                                                        :
                                                                        (rowData.status.adminRole === 'ROLE_DEPARTMENT_ADMIN' && (rowData.status.deactivated && rowData.status.verified)) ?
                                                                            <React.Fragment>
                                                                                <label
                                                                                    className='labelInference'
                                                                                    style={{
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        whiteSpace: 'nowrap',
                                                                                        display: 'block'
                                                                                    }} title='Suborganization admin (deactivated)'>{`Suborganization admin (deactivated)`}
                                                                                </label>
                                                                                <label
                                                                                    className='p-column-title'
                                                                                    style={{
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        whiteSpace: 'nowrap',
                                                                                        fontWeight: 'normal'
                                                                                    }} title='Suborganization admin (deactivated)'>{`Suborganization admin (deactivated)`}
                                                                                </label>
                                                                            </React.Fragment>
                                                                            :
                                                                            (rowData.status.adminRole === 'NONE' && (!rowData.status.deactivated && rowData.status.verified)) ?
                                                                                <React.Fragment>
                                                                                    <label
                                                                                        className='labelInference'
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            display: 'block'
                                                                                        }} title='User'>{`User`}
                                                                                    </label>
                                                                                    <label
                                                                                        className='p-column-title'
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            fontWeight: 'normal'
                                                                                        }} title='User'>{`User`}
                                                                                    </label>
                                                                                </React.Fragment>
                                                                                :
                                                                                (rowData.status.adminRole === 'NONE' && (!rowData.status.deactivated && !rowData.status.verified)) ? <div>
                                                                                    <label
                                                                                        className='labelInference'
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            display: 'block'
                                                                                        }} title='User (not verified by email)'>User (not verified by email)</label>
                                                                                    <label
                                                                                        className='p-column-title'
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            fontWeight: 'normal'
                                                                                        }} title='User (not verified by email)'>User (not verified by email)</label>
                                                                                    {(this.props.userDetails.highestAdminType !== "NONE") &&
                                                                                        <div style={{ marginTop: 5 }}>
                                                                                            <a id="editUser"
                                                                                                onClick={(e) => this.openResendVerification(e, rowData.status)}
                                                                                                style={{ display: 'inline-block' }}>Resend email</a>
                                                                                            {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                                                <a id="editUser"
                                                                                                    onClick={(e) => this.openVerifyUser(e, rowData.status)}
                                                                                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div> :
                                                                                    (rowData.status.adminRole === 'NONE' && (rowData.status.deactivated && !rowData.status.verified)) ?
                                                                                        <React.Fragment>
                                                                                            <label
                                                                                                className='labelInference'
                                                                                                style={{
                                                                                                    overflow: 'hidden',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                    display: 'block'
                                                                                                }} title='User (deactivated, not verified by email)'>{`User (deactivated, not verified by email)`}
                                                                                            </label>
                                                                                            <label
                                                                                                className='p-column-title'
                                                                                                style={{
                                                                                                    overflow: 'hidden',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                    fontWeight: 'normal'
                                                                                                }} title='User (deactivated, not verified by email)'>{`User (deactivated, not verified by email)`}
                                                                                            </label>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (rowData.status.adminRole === 'NONE' && (rowData.status.deactivated && rowData.status.verified)) ?
                                                                                            <React.Fragment>
                                                                                                <label
                                                                                                    className='labelInference'
                                                                                                    style={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        display: 'block'
                                                                                                    }} title='User (deactivated)'>{`User (deactivated)`}
                                                                                                </label>
                                                                                                <label
                                                                                                    className='p-column-title'
                                                                                                    style={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        fontWeight: 'normal'
                                                                                                    }} title='User (deactivated)'>{`User (deactivated)`}
                                                                                                </label>
                                                                                            </React.Fragment>
                                                                                            : null
            }
        </div>
    }

    emailTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Email</span>
            <a id="emailUser"
                className='aTagInference'
                title={`${rowData.email}`}
                style={{
                    display: 'block',
                    cursor: 'default',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{rowData.email}</a>
            <a id="emailUser"
                className="p-column-title"
                title={`${rowData.email}`}
                style={{
                    fontWeight: 'normal',
                    cursor: 'default',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{rowData.email}</a>
        </div>
    }

    loginTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Last login</span>
            {rowData.lastLogin ?
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`${dateFormat(new Date(rowData.lastLogin), 'yyyy-mm-dd')}`}>
                        {`${dateFormat(new Date(rowData.lastLogin), 'yyyy-mm-dd')}`
                        }</label>
                    <label
                        className="p-column-title"
                        style={{
                            fontWeight: 'normal',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`${dateFormat(new Date(rowData.lastLogin), 'yyyy-mm-dd')}`}>
                        {`${dateFormat(new Date(rowData.lastLogin), 'yyyy-mm-dd')}`
                        }</label>
                </React.Fragment>
                :
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`-`}>
                        {`-`
                        }</label>
                    <label
                        className="p-column-title"
                        style={{
                            fontWeight: 'normal',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`-`}>
                        {`-`
                        }</label>
                </React.Fragment>
            }
        </div>
    }

    createdTemplate = (rowData) => {
        if (rowData.created) {
            return <div>
                <span className="p-column-title">Created</span>
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`${dateFormat(new Date(rowData.created), 'yyyy-mm-dd')}`}>
                        {`${dateFormat(new Date(rowData.created), 'yyyy-mm-dd')}`
                        }</label>
                    <label
                        className="p-column-title"
                        style={{
                            fontWeight: 'normal',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`${dateFormat(new Date(rowData.created), 'yyyy-mm-dd')}`}>
                        {`${dateFormat(new Date(rowData.created), 'yyyy-mm-dd')}`
                        }</label>
                </React.Fragment>
            </div>
        } else {
            return <div>
                <span className="p-column-title">Created</span>
                <React.Fragment>
                    <label
                        className='labelInference'
                        style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`-`}>
                        {`-`
                        }</label>
                    <label
                        className="p-column-title"
                        style={{
                            fontWeight: 'normal',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`-`}>
                        {`-`
                        }</label>
                </React.Fragment>
            </div>
        }
    }

    suborgTemplate = (rowData) => {
        let depName = ''
        let activationState = ''
        this.state.selectedDepartments && this.state.selectedDepartments.forEach(dep => {
            if (dep.id === rowData.status.department) {
                activationState = dep.activationState
            }
        })
        depName = rowData.status.departmentName
        if (depName === 'default') {
            depName = 'Unassigned / Inactive regular users'
        }
        const activationStateStrg = `${depName} ${activationState === 'demo' ? ' (demo)' : activationState !== 'activated' ? ' (deactivated)' : ''}`;
        return <div>
            <span className="p-column-title">Suborganization</span>
            <span>
                <label title={activationStateStrg}>
                    {activationStateStrg}
                </label>
            </span>
        </div>
    }

    editTemplate = (rowData) => {
        let depName = ''
        this.state.selectedDepartments && this.state.selectedDepartments.forEach(dep => {
            if (dep.id === rowData.status.department) {
                depName = dep.name
            }
        })
        return (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (rowData.status.username === this.props.userDetails.username) ||
            (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && rowData.status.adminRole !== 'ROLE_SUPER_ADMIN') ||
            (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && rowData.status.adminRole !== 'ROLE_SUPER_ADMIN' && rowData.status.adminRole !== 'ROLE_COMPANY_ADMIN') ||
            (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (rowData.status.adminRole !== 'ROLE_SUPER_ADMIN' &&
                rowData.status.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                rowData.status.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))
            ? <React.Fragment>
                <a id="emailUser"
                    className='aTagInference'
                    onClick={(e) => this.openEditUser(e, rowData.status, depName)}
                    title='Edit user'
                    style={{
                        fontWeight: 'normal',
                        float: 'right'
                    }}
                >Edit user</a>
                <a id="emailUser"
                    className="p-column-title"
                    onClick={(e) => this.openEditUser(e, rowData.status, depName)}
                    title='Edit user'
                    style={{
                        fontWeight: 'normal',
                        textAlign: 'left'
                    }}>Edit user</a>
            </React.Fragment>
            :
            <React.Fragment>
                <label
                    style={{ float: 'right' }}
                    className='labelInference'>
                    Not editable</label>
                <label
                    className="p-column-title"
                    style={{
                        fontWeight: 'normal'
                    }}>
                    Not editable</label>
            </React.Fragment>
    }

    handleTabChange = (index) => {
        this.toastBC.current.clear()
        this.setState({
            selectedUsers: [],
            selectedUsersTable: [],
            selectedUserDetails: [],
            allUsersSelected: false,
            activeIndex: index,
            globalFilterUsers: '',
            highlightedUser: undefined,
            displayCustomToast: false
        })
    }

    render() {

        let userDetails = this.props.userDetails

        let companies = []

        this.state.companies && this.state.companies.forEach(comp => {
            let activeOrNor = comp.deactivated ? "Deactivated" : " Active"
            companies = [...companies, { label: `${comp.name} - ${activeOrNor}`, value: comp.id, name: `${comp.name}` }]
        })

        let today = new Date()
        let minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

        let departmentCounters = {}
        let departmentActiveCounters = {}
        let departmentInactiveCounters = {}
        let departmentNotVerifiedCounters = {}
        this.state.selectedDepartments && this.state.selectedDepartments.forEach(dep => {
            let departmentCounter = 0
            let departmentActiveCounter = 0
            let departmentInactiveCounter = 0
            let departmentNotVerifiedCounter = 0
            this.state.departmentUsers && this.state.departmentUsers.filter(user => user.department === dep.id).forEach(user => {
                departmentCounter += 1
                if (!user.deactivated && user.verified) {
                    departmentActiveCounter += 1
                } else if (!user.verified) {
                    departmentNotVerifiedCounter += 1
                } else if (user.deactivated) {
                    departmentInactiveCounter += 1
                }
            })
            departmentCounters[dep.id] = departmentCounter
            departmentActiveCounters[dep.id] = departmentActiveCounter
            departmentInactiveCounters[dep.id] = departmentInactiveCounter
            departmentNotVerifiedCounters[dep.id] = departmentNotVerifiedCounter
        })

        let userData = this.state.selectedDepartments && this.state.selectedDepartments.map(department => (
            {
                selectable: departmentCounters[department.id] > 0 ? true : false,
                key: `${department.name}-${department.id}`,
                data: {
                    name: <span style={{
                        fontWeight: 'bold', color: department.activationState === 'deactivated' ? 'gray' : 'black', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'break-spaces'
                    }} title={department.name === 'default' ? `Unassigned / Inactive regular users  \n ${departmentActiveCounters[department.id]} ${departmentActiveCounters[department.id] !== 1 ? "active users" : "active user"}\n ${departmentInactiveCounters[department.id]} deactivated\n ${departmentNotVerifiedCounters[department.id]} not verified` :
                        `${department.name} \n ${departmentActiveCounters[department.id]} ${departmentActiveCounters[department.id] !== 1 ? "active users" : "active user"}\n ${departmentInactiveCounters[department.id]} deactivated\n ${departmentNotVerifiedCounters[department.id]} not verified`}>{department.name === 'default' ? `Unassigned / Inactive regular users (${departmentCounters[department.id]})` : `${department.name} (${departmentCounters[department.id]})`}</span>,
                    email: null,
                    status: <span style={{ fontWeight: 'bold', color: department.activationState === 'deactivated' ? 'gray' : 'black' }}>{department.activationState === 'deactivated' ? 'Inactive' : department.activationState === 'demo' ? 'Demo' : 'Active'}</span>,
                    demo: department.startTrialPeriod || department.endTrialPeriod ?
                        <span style={{
                            fontWeight: 'bold', color: department.activationState === 'deactivated' ? 'gray' : 'black', display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>
                            <label title={(department.startTrialPeriod && department.startTrialPeriod > (dateFormat(minDate, 'yyyy-mm-dd'))) ?
                                `Starts: ${department.startTrialPeriod}` :
                                department.endTrialPeriod ? `Expires: ${department.endTrialPeriod}` : ''}> {(department.startTrialPeriod && department.startTrialPeriod > (dateFormat(minDate, 'yyyy-mm-dd'))) ?
                                    `Starts: ${department.startTrialPeriod}` :
                                    department.endTrialPeriod ? `Expires: ${department.endTrialPeriod}` : ''
                                }
                            </label>

                        </span>
                        : null,
                    add: <div className='col-12'
                        key={`${department.name}`}>
                        <a id="addUser"
                            onClick={(e) => this.openAddUser(e, department.id, department.name)}
                            style={{ display: 'inline-block' }}>Add user</a>
                    </div>,
                    edit: this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" || this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" ?
                        <div className='col-12'
                            key={`${department.name}`}>
                            <a id="editDepartment"
                                onClick={(e) => this.openEditDepartment(e, department.id)}
                                title='Edit suborganization'
                                style={{
                                    display: 'block',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}>Edit suborganization</a>
                        </div>
                        :
                        null
                },
                children: this.state.departmentUsers && this.state.departmentUsers.filter(user => user.department === department.id).map(user => ({
                    selectable: (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.username === this.props.userDetails.username) ||
                            (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.adminRole !== 'ROLE_SUPER_ADMIN') ||
                            (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.adminRole !== 'ROLE_SUPER_ADMIN' && user.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                            (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.adminRole !== 'ROLE_SUPER_ADMIN' &&
                                user.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                                user.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))
                            ? true : false,
                    key: user.username,
                    data: {
                        name: <label style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'break-spaces',
                            backgroundColor: this.state.highlightedUser === user.username ? "#F8E71C" : 'white'
                        }} title={user.samlLogin === true ? `${user.lastName}, ${user.forename} (${user.username}, SSO)` :
                            `${user.lastName}, ${user.forename} (${user.username})`}>{user.samlLogin === true ?
                                `${user.lastName}, ${user.forename} (${user.username}, SSO)`
                                : `${user.lastName}, ${user.forename} (${user.username})`}</label>,
                        email: <div className='col-12'
                            key={`${user.email}`}>
                            <a id="emailUser"
                                title={`${user.email}`}
                                style={{
                                    display: 'block',
                                    cursor: 'default',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}>{user.email}</a>
                        </div>,
                        status: (user.adminRole === 'ROLE_SUPER_ADMIN' && (!user.deactivated && user.verified)) ? <label style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block'
                        }} title='Super admin'>{`Super admin`}</label> :
                            (user.adminRole === 'ROLE_SUPPORT_ADMIN' && (!user.deactivated && user.verified)) ? <label style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block'
                            }} title='Support admin'>{`Support admin`}</label> :
                                (user.adminRole === 'ROLE_SUPER_ADMIN' && (!user.deactivated && !user.verified)) ? <div>
                                    <label style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        display: 'block'
                                    }} title='Super admin (not verified by email)'>Super admin (not verified by email)</label>
                                    {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN") &&
                                        <div>
                                            <a id="editUser"
                                                onClick={(e) => this.openResendVerification(e, user)}
                                                style={{ display: 'inline-block' }}>Resend email</a>
                                            <a id="editUser"
                                                onClick={(e) => this.openVerifyUser(e, user)}
                                                style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                        </div>
                                    }
                                </div> :
                                    (user.adminRole === 'ROLE_SUPPORT_ADMIN' && (!user.deactivated && !user.verified)) ? <div>
                                        <label style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            display: 'block'
                                        }} title='Support admin (not verified by email)'>Support admin (not verified by email)</label>
                                        {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN") &&
                                            <div>
                                                <a id="editUser"
                                                    onClick={(e) => this.openResendVerification(e, user)}
                                                    style={{ display: 'inline-block' }}>Resend email</a>
                                                <a id="editUser"
                                                    onClick={(e) => this.openVerifyUser(e, user)}
                                                    style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                            </div>
                                        }
                                    </div> :
                                        (user.adminRole === 'ROLE_SUPER_ADMIN' && (user.deactivated && !user.verified)) ? <label
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }} title='Super admin (deactivated, not verified by email)'>{`Super admin (deactivated, not verified by email)`}</label> :
                                            (user.adminRole === 'ROLE_SUPPORT_ADMIN' && (user.deactivated && !user.verified)) ? <label
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    display: 'block'
                                                }} title='Support admin (deactivated, not verified by email)'>{`Support admin (deactivated, not verified by email)`}</label> :
                                                (user.adminRole === 'ROLE_SUPER_ADMIN' && (user.deactivated && user.verified)) ? <label
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        display: 'block'
                                                    }} title='Super admin (deactivated)'>{`Super admin (deactivated)`}</label> :
                                                    (user.adminRole === 'ROLE_SUPPORT_ADMIN' && (user.deactivated && user.verified)) ? <label
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            display: 'block'
                                                        }} title='Support admin (deactivated)'>{`Support admin (deactivated)`}</label> :
                                                        (user.adminRole === 'ROLE_COMPANY_ADMIN' && (!user.deactivated && user.verified)) ? <label
                                                            style={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                display: 'block'
                                                            }} title='Organization admin'>{`Organization admin`} </label> :
                                                            (user.adminRole === 'ROLE_COMPANY_ADMIN' && (!user.deactivated && !user.verified)) ? <div>
                                                                <label
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        display: 'block'
                                                                    }} title='Organization admin (not verified by email)'>Organization admin (not verified by email)</label>
                                                                {(this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" || this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN') ?
                                                                    <div style={{ marginTop: 5 }}>
                                                                        <a id="editUser"
                                                                            onClick={(e) => this.openResendVerification(e, user)}
                                                                            style={{ display: 'inline-block' }}>Resend email</a>
                                                                        {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                            <a id="editUser"
                                                                                onClick={(e) => this.openVerifyUser(e, user)}
                                                                                style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div> :
                                                                (user.adminRole === 'ROLE_COMPANY_ADMIN' && (user.deactivated && !user.verified)) ? <label
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        display: 'block'
                                                                    }} title='Organization admin (deactivated, not verified by email)'>{`Organization admin (deactivated, not verified by email)`}</label> :
                                                                    (user.adminRole === 'ROLE_COMPANY_ADMIN' && (user.deactivated && user.verified)) ? <label
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            display: 'block'
                                                                        }} title='Organization admin (deactivated)'>{`Organization admin (deactivated)`}</label> :
                                                                        (user.adminRole === 'ROLE_DEPARTMENT_ADMIN' && (!user.deactivated && user.verified)) ? `Suborganization admin` :
                                                                            (user.adminRole === 'ROLE_DEPARTMENT_ADMIN' && (!user.deactivated && !user.verified)) ? <div>
                                                                                <label style={{
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    display: 'block'
                                                                                }} title='Suborganization admin (not verified by email)'>Suborganization admin (not verified by email)</label>
                                                                                {(this.props.userDetails.highestAdminType !== "NONE") &&
                                                                                    <div style={{ marginTop: 5 }}>
                                                                                        <a id="editUser"
                                                                                            onClick={(e) => this.openResendVerification(e, user)}
                                                                                            style={{ display: 'inline-block' }}>Resend email</a>
                                                                                        {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                                            <a id="editUser"
                                                                                                onClick={(e) => this.openVerifyUser(e, user)}
                                                                                                style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div> :
                                                                                (user.adminRole === 'ROLE_DEPARTMENT_ADMIN' && (user.deactivated && !user.verified)) ? <label style={{
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    display: 'block'
                                                                                }} title='Suborganization admin (deactivated, not verified by email)'>{`Suborganization admin (deactivated, not verified by email)`}</label> :
                                                                                    (user.adminRole === 'ROLE_DEPARTMENT_ADMIN' && (user.deactivated && user.verified)) ? <label style={{
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        whiteSpace: 'nowrap',
                                                                                        display: 'block'
                                                                                    }} title='Suborganization admin (deactivated)'>{`Suborganization admin (deactivated)`}</label> :
                                                                                        (user.adminRole === 'NONE' && (!user.deactivated && user.verified)) ? <label style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            display: 'block'
                                                                                        }} title='User'>{`User`}</label> :
                                                                                            (user.adminRole === 'NONE' && (!user.deactivated && !user.verified)) ? <div>
                                                                                                <label style={{
                                                                                                    overflow: 'hidden',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                    display: 'block'
                                                                                                }} title='User (not verified by email)'>User (not verified by email)</label>
                                                                                                {(this.props.userDetails.highestAdminType !== "NONE") &&
                                                                                                    <div style={{ marginTop: 5 }}>
                                                                                                        <a id="editUser"
                                                                                                            onClick={(e) => this.openResendVerification(e, user)}
                                                                                                            style={{ display: 'inline-block' }}>Resend email</a>
                                                                                                        {this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" &&
                                                                                                            <a id="editUser"
                                                                                                                onClick={(e) => this.openVerifyUser(e, user)}
                                                                                                                style={{ display: 'inline-block', marginLeft: 5, paddingLeft: 5, borderLeft: '1px solid #007fdb' }}>Verify</a>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div> :
                                                                                                (user.adminRole === 'NONE' && (user.deactivated && !user.verified)) ? <label style={{
                                                                                                    overflow: 'hidden',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                    display: 'block'
                                                                                                }} title='User (deactivated, not verified by email)'>{`User (deactivated, not verified by email)`}</label> :
                                                                                                    (user.adminRole === 'NONE' && (user.deactivated && user.verified)) ? <label style={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        display: 'block'
                                                                                                    }} title='User (deactivated)'>{`User (deactivated)`}</label> : '',
                        demo: user.lastLogin && !user.created ? <label style={{
                            display: 'block',
                            cursor: 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }} title={`Last login: ${dateFormat(new Date(user.lastLogin), 'yyyy-mm-dd')}`}>{`Last login: ${dateFormat(new Date(user.lastLogin), 'yyyy-mm-dd')}`}</label> :
                            user.lastLogin && user.created ?
                                <div>
                                    <label style={{
                                        display: 'block',
                                        cursor: 'default',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }} title={`Created: ${dateFormat(new Date(user.created), 'yyyy-mm-dd')}`}>{`Created: ${dateFormat(new Date(user.created), 'yyyy-mm-dd')}`}</label>
                                    <label style={{
                                        display: 'block',
                                        cursor: 'default',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        marginTop: 2
                                    }} title={`Last login: ${dateFormat(new Date(user.lastLogin), 'yyyy-mm-dd')}`}>{`Last login: ${dateFormat(new Date(user.lastLogin), 'yyyy-mm-dd')}`}</label>
                                </div>
                                : !user.lastLogin && user.created ?
                                    <label style={{
                                        display: 'block',
                                        cursor: 'default',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }} title={`Created: ${dateFormat(new Date(user.created), 'yyyy-mm-dd')}`}>{`Created: ${dateFormat(new Date(user.created), 'yyyy-mm-dd')}`}</label>
                                    : '',
                        edit:
                            (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.username === this.props.userDetails.username) ||
                                (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.adminRole !== 'ROLE_SUPER_ADMIN') ||
                                (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.adminRole !== 'ROLE_SUPER_ADMIN' && user.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                                (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.adminRole !== 'ROLE_SUPER_ADMIN' &&
                                    user.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                                    user.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))
                                ? <div className='col-12'
                                    key={`${user.username}`}>
                                    <a id="editUser"
                                        onClick={(e) => this.openEditUser(e, user, department.name)}
                                        title='Edit user'
                                        style={{
                                            display: 'block',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>Edit user</a>
                                </div>
                                :
                                <label>Not editable</label>
                    }
                }))
            }
        ))



        let nameTemplate = (node) => {
            return (
                <React.Fragment>
                    <span>{node.data.name}</span>
                    <div className="sm-visible-block" style={{ paddingLeft: 7 }}> {node.data.status}</div>
                    {node.data.email !== null &&
                        <div className="sm-visible" style={{ paddingRight: -7 }}> {node.data.email}</div>
                    }
                    {node.data.demo !== null &&
                        <div className="sm-visible" style={{ paddingLeft: 7 }}> {node.data.demo}</div>
                    }
                    <div className="sm-visible" style={{}}> {node.data.add}</div>
                    <div className="sm-visible" style={{}}> {node.data.edit}</div>
                </React.Fragment>
            )
        }


        this.allUsers = []
        this.possibleUsers = []

        this.state.departmentUsers && this.state.departmentUsers.forEach(user => {
            this.allUsers = [...this.allUsers, {
                name: user.samlLogin === true ? `${user.lastName}, ${user.forename} (${user.username}, SSO)` :
                    `${user.lastName}, ${user.forename} (${user.username})`,
                email: `${user.email}`,
                status: user,
                created: user.created,
                lastLogin: user.lastLogin,
                suborg: `${user.department}`
            }]
            if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.username === this.props.userDetails.username) ||
                (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.adminRole !== 'ROLE_SUPER_ADMIN') ||
                (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.adminRole !== 'ROLE_SUPER_ADMIN' && user.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.adminRole !== 'ROLE_SUPER_ADMIN' &&
                    user.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                    user.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))) {
                if (this.state.filteredUsers.length === 0 && this.state.globalFilterUsers === "") {
                    this.possibleUsers = [...this.possibleUsers, user]
                }
            }
        })

        let filteredAllowedUsers = []

        if (this.state.filteredUsers && this.state.filteredUsers.length > 0) {
            this.state.filteredUsers && this.state.filteredUsers.length > 0 && this.state.filteredUsers.forEach(user => {
                if ((this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN")) || (user.status.username === this.props.userDetails.username) ||
                    (this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && user.adminRole !== 'ROLE_SUPER_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN" && user.status.adminRole !== 'ROLE_SUPER_ADMIN' && user.status.adminRole !== 'ROLE_COMPANY_ADMIN') ||
                    (this.props.userDetails.highestAdminType === "ROLE_DEPARTMENT_ADMIN" && (user.status.adminRole !== 'ROLE_SUPER_ADMIN' &&
                        user.status.adminRole !== 'ROLE_COMPANY_ADMIN' &&
                        user.status.adminRole !== 'ROLE_DEPARTMENT_ADMIN'))) {
                    filteredAllowedUsers = [...filteredAllowedUsers, user]
                }
            })
            this.possibleUsers = filteredAllowedUsers
        }

        let columnDataUsers = this.columns.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        const checkUserTemplateHeader = () => {
            return <Checkbox
                disabled={this.state.departmentUsers.length > 0 && this.possibleUsers.length > 0 ? false : true}
                style={{ cursor: this.state.departmentUsers.length > 0 && this.possibleUsers.length > 0 ? "pointer" : "default" }}
                checked={this.state.allUsersSelected}
                onChange={(e) => this.handleSelectAllUsers(e)}>
            </Checkbox>
        }

        columnDataUsers.unshift(
            <Column
                key="check"
                field="check"
                header={checkUserTemplateHeader()}
                body={this.checkUserTemplate}
                style={{ width: '4em' }}>
            </Column>
        )

        let headerUsers = <div className='grid' style={{
            marginRight: 0,
            paddingTop: 18, paddingBottom: 10,
            paddingLeft: 5
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search users by name, email or suborganization">
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', float: 'left', width: '80%' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.handleGlobalFilterChange(e.target.value)}
                    placeholder='Search users by name, email or suborganization'
                />
            </div>
        </div>

        return (
            <div className='col-12' style={{ margin: "-0.5rem" }}>
                <Toast ref={(el) => { this.growl = el }} />
                {this.props.userDetails.highestAdminType !== 'NONE' ?
                    <div className='col-12'>
                        <div className='col-4' style={{ float: 'left', padding: 0 }}>
                            <h1 style={{ marginTop: 0 }} className="pageHeader">{this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ? 'Administration' :
                                this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' ? 'Administration' : this.props.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' ? 'Administration' : 'User details'}</h1>
                        </div>
                        {this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' &&
                            <div className='col-7' style={{ float: 'right', textAlign: 'right' }}>
                                <a id="globalSettings"
                                    onClick={this.openGlobalUserSearch}
                                    style={{ display: 'inline-block', paddingRight: 15 }}>Global user search</a>
                                <a id="globalSettings"
                                    onClick={this.openGlobalSettings}
                                    style={{ display: 'inline-block', marginRight: 5 }}>Global settings</a>
                            </div>
                        }
                        {this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ?
                            <div className='col-12' style={{ paddingBottom: 20, float: 'left', paddingLeft: 0 }}>
                                <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}> Configure all organizations, change the appearance of the status bar,
                                    add/remove repositories and ontologies, add/delete and de/-active users, reset passwords.</label>
                            </div>
                            : this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' ?
                                <div className='col-12' style={{ paddingBottom: 20, float: 'left', paddingLeft: 0 }}>
                                    <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}> Configure your organization, change the appearance of the status bar,
                                        de-/select repositories and ontologies, add/delete and de/-active users, reset passwords.</label>
                                </div>
                                : this.props.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' ?
                                    <div className='col-12' style={{ paddingBottom: 20, float: 'left', paddingLeft: 0 }}>
                                        <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}> Configure your suborganization, change the appearance of the status bar,
                                            de-/select repositories and ontologies, add/delete and de/-active users, reset passwords.</label>
                                    </div>
                                    :
                                    null
                        }
                        {(this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' || this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN') &&
                            <React.Fragment>
                                <div className='col-12' style={{
                                    paddingLeft: 0
                                }}>
                                    <div className='col-12' style={{
                                        paddingLeft: 0,
                                        marginTop: 30
                                    }}>
                                        <label>Organization</label>
                                        {this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' &&
                                            <a id="addCompany"
                                                onClick={this.openAddCompany}
                                                style={{ display: 'inline-block', float: 'right' }}>Add organization</a>
                                        }
                                    </div>
                                    <Dropdown style={{ width: '100%', border: '1px solid #d6d6d6', fontWeight: 'bold', fontSize: 16 }}
                                        className='dropdownNoBorder'
                                        filter
                                        showClear
                                        value={this.state.selectedCompany}
                                        options={companies}
                                        disabled={this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ? false : true}
                                        onChange={(e) => this.handleCompanyChange(e.value)}
                                        placeholder='Select organization'>
                                    </Dropdown>
                                </div>
                                <div className='col-7' style={{ float: 'right', textAlign: 'right', marginBottom: 15, marginTop: -5 }}>
                                    {this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' &&
                                        <a id="addCompany"
                                            onClick={this.openEditCompany}
                                            style={{
                                                display: 'inline-block',
                                                marginRight: 5
                                            }}>Edit organization</a>
                                    }
                                </div>
                            </React.Fragment>
                        }
                        <div className='col-12' style={{
                            marginTop: 10,
                            marginBottom: 70, paddingLeft: 0
                        }}>
                            <div className='col-12' style={{
                                paddingLeft: 0,
                                marginTop: this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' || this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' ? 30 : 62,
                                marginBottom: this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' || this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' ? -20 : 10
                            }}>
                                {(this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' || this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN') &&
                                    <a id="globalSettings"
                                        onClick={this.openAddDepartment}
                                        style={{ display: 'inline-block', float: 'right' }}>Add suborganization</a>
                                }
                            </div>
                            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.handleTabChange(e.index)}>
                                <TabPanel header='Users by suborganizations'>
                                    <div className='treetable-responsive-demo'>
                                        <TreeTable value={userData}
                                            expandedKeys={this.state.expandedKeys}
                                            onToggle={e => this.setState({ expandedKeys: e.value })}
                                            selectionMode="checkbox"
                                            selectionKeys={this.state.selectedUsers}
                                            onSelectionChange={(e) => this.handleSelectionUsers(e)}
                                            className='treetable-no-header'>
                                            <Column field='name' body={nameTemplate} header='Select user(s)' expander style={{ width: "30%" }}
                                            />
                                            <Column field='email' header='EMail' style={{ width: "15%" }}
                                                headerClassName="sm-invisible" bodyClassName="sm-invisible" />
                                            <Column field='status' header='Status' style={{ width: "20%" }}
                                                headerClassName="sm-invisible" bodyClassName="sm-invisible" />
                                            <Column field='demo' header='Demo' style={{ width: "15%" }}
                                                headerClassName="sm-invisible" bodyClassName="sm-invisible" />
                                            <Column field='add' header='Add user' style={{
                                                width: "10%",
                                                textAlign: 'right'
                                            }}
                                                headerClassName="sm-invisible" bodyClassName="sm-invisible" />
                                            <Column field='edit' header='Edit' style={{
                                                width: "15%",
                                                textAlign: 'right'
                                            }}
                                                headerClassName="sm-invisible" bodyClassName="sm-invisible" />
                                        </TreeTable>
                                    </div>
                                </TabPanel>
                                <TabPanel header={this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ? 'Org. user list' :
                                    this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' ? 'Org. user list' : 'Suborg. user list'}>
                                    <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0 }}>
                                        <div className="datatable-responsive-demo">
                                            {headerUsers}
                                            <DataTable ref={(el) => this.dt = el}
                                                className="p-datatable-responsive-demo"
                                                emptyMessage="No users available."
                                                removableSort={true}
                                                value={this.allUsers}
                                                onValueChange={filteredData => this.onValueChange(filteredData)}
                                                paginator={true}
                                                rows={this.state.rows}
                                                globalFilter={this.state.globalFilterUsers}
                                                first={this.state.first}
                                                rowsPerPageOptions={[10, 20, 50]}
                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                                                {columnDataUsers}
                                            </DataTable>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                        {this.state.displayGlobalSettings ?
                            <GlobalSettings
                                userDetails={this.props.userDetails}
                                onRepositoryOrderChange={this.onRepositoryOrderChange}
                                allAvailableFeatures={this.state.allAvailableFeatures}
                                allAvailableRepositories={this.state.availableRepositories}
                                allAvailableRepositoryFeatures={this.state.repositoryFeatures}
                                allDomainsHighlightItems={this.state.allDomainsHighlightItems}
                                allDomainsSearch={this.state.allDomainSelectItems}
                                displayGlobalSettings={this.state.displayGlobalSettings}
                                onCancelGlobalSettings={this.onCancelGlobalSettings}
                                onSaveGlobalSettings={this.onSaveGlobalSettings}
                                openAddDomain={this.openAddDomain}
                                openAddFeature={this.openAddFeature}
                                openAddRepository={this.openAddRepository}
                                openCopyRepository={this.openCopyRepository}
                                openDeleteRepository={this.openDeleteRepository}
                                openDeleteDomain={this.openDeleteDomain}
                                openEditDomain={this.openEditDomain}
                                openSetupDomain={this.openSetupDomain}
                                openEditFeature={this.openEditFeature}
                                openSetupFeature={this.openSetupFeature}
                                openEditRepository={this.openEditRepository}
                                openSetupRepository={this.openSetupRepository}
                                handleActivationStatusRepository={this.handleActivationStatusRepository}
                                handleActivationStatusDomain={this.handleActivationStatusDomain}
                                allBackends={this.state.allBackends}
                            />
                            : null
                        }
                        <GlobalUserSearch
                            userDetails={this.props.userDetails}
                            displayGlobalUserSearch={this.state.displayGlobalUserSearch}
                            onCancelGlobalUserSearch={this.onCancelGlobalUserSearch}
                            allGlobalUsers={this.state.allGlobalUsers}
                            openVerifyUser={this.openVerifyUser}
                            openEditUser={this.openEditUser}
                            openResendVerification={this.openResendVerification}
                            openSelectUser={this.openSelectUser}
                            openDeleteUsersGlobal={this.openDeleteUsersGlobal}
                            openMoveUsersGlobal={this.openMoveUsersGlobal}
                            openActivateUsersGlobal={this.openActivateUsersGlobal}
                            openInactivateUsersGlobal={this.openInactivateUsersGlobal}
                            getGlobalUsers={this.getGlobalUsers} />

                        {this.state.displayAddRepository &&
                            <AddRepository
                                displayAddRepository={this.state.displayAddRepository}
                                onCancelAddRepository={this.onCancelAddRepository}
                                onAddRepository={this.onAddRepository}
                                allAvailableRepositoryFeatures={this.state.repositoryFeatures}
                            />
                        }
                        {this.state.displayDeleteRepository &&
                            <DeleteRepository
                                displayDeleteRepository={this.state.displayDeleteRepository}
                                onCancelDeleteRepository={this.onCancelDeleteRepository}
                                onDeleteRepository={this.onDeleteRepository}
                                selectedRepositoryLabel={this.state.selectedRepositoryLabel}
                                selectedRepositoryName={this.state.selectedRepositoryName}
                                selectedRepositoryID={this.state.selectedRepositoryID}
                            />
                        }
                        {this.state.displayAddDomain &&
                            <AddDomain
                                displayAddDomain={this.state.displayAddDomain}
                                onCancelAddDomain={this.onCancelAddDomain}
                                onAddDomain={this.onAddDomain}
                            />
                        }
                        {this.state.displayEditRepository &&
                            <EditRepository
                                displayEditRepository={this.state.displayEditRepository}
                                onCancelEditRepository={this.onCancelEditRepository}
                                onEditRepository={this.onEditRepository}
                                selectedRepositoryLabel={this.state.selectedRepositoryLabel}
                                selectedRepositoryName={this.state.selectedRepositoryName}
                                selectedRepositoryID={this.state.selectedRepositoryID}
                                selectedRepositoryDescription={this.state.selectedRepositoryDescription}
                                selectedRepositoryFeatures={this.state.selectedRepositoryFeatures}
                                allAvailableRepositoryFeatures={this.state.repositoryFeatures}
                            />
                        }
                        <AddRepository
                            displayAddRepository={this.state.displayCopyRepository}
                            onCancelAddRepository={this.onCancelCopyRepository}
                            onAddRepository={this.onCopyRepository}
                            allAvailableRepositoryFeatures={this.state.repositoryFeatures}
                            label={this.state.selectedRepositoryLabel}
                            name={this.state.selectedRepositoryName}
                            abbreviation={this.state.selectedRepositoryAbbreviation}
                            description={this.state.selectedRepositoryDescription}
                            features={this.state.selectedRepositoryFeatures}
                            backend={this.state.selectedRepositoryBackend}
                        />
                        {this.state.displaySetupRepository &&
                            <SetupRepository
                                displaySetupRepository={this.state.displaySetupRepository}
                                onCancelSetupRepository={this.onCancelSetupRepository}
                                selectedDepartments={this.state.selectedDepartments}
                                onSetupRepository={this.onSetupRepository}
                                selectedRepositoryLabel={this.state.selectedRepositoryLabel}
                                selectedRepositoryName={this.state.selectedRepositoryName}
                                selectedRepositoryID={this.state.selectedRepositoryID}
                                selectedRepositoryDescription={this.state.selectedRepositoryDescription}
                                allAvailableRepositories={this.state.availableRepositories}
                                allGlobalRepositories={this.state.allGlobalRepositories}
                                onRepositoryOrgClick={this.onRepositoryOrgClick}
                                onRepositorySuborgClick={this.onRepositorySuborgClick}
                                allBackends={this.state.allBackends}
                            />
                        }
                        {this.state.displayEditDomain &&
                            <EditDomain
                                displayEditDomain={this.state.displayEditDomain}
                                onCancelEditDomain={this.onCancelEditDomain}
                                onEditDomain={this.onEditDomain}
                                selectedDomainName={this.state.selectedDomainName}
                                selectedDomainLabel={this.state.selectedDomainLabel}
                                selectedDomainID={this.state.selectedDomainID}
                                selectedDomainActive={this.state.selectedDomainActive}
                                selectedDomainColor={this.state.selectedDomainColor}
                                selectedDomainDescription={this.state.selectedDomainDescription}
                                selectedDomainOrderPriority={this.state.selectedDomainOrderPriority}
                            />
                        }
                        {this.state.displaySetupDomain &&
                            <SetupDomain
                                displaySetupDomain={this.state.displaySetupDomain}
                                onCancelSetupDomain={this.onCancelSetupDomain}
                                onSetupDomain={this.onSetupDomain}
                                selectedDepartments={this.state.selectedDepartments}
                                selectedDomainName={this.state.selectedDomainName}
                                selectedDomainLabel={this.state.selectedDomainLabel}
                                selectedDomainID={this.state.selectedDomainID}
                                allGlobalDomains={this.state.allGlobalDomains}
                                allAvailableDomains={this.state.allDomainSelectItems}
                                onDomainOrgClick={this.onDomainOrgClick}
                                onDomainSuborgClick={this.onDomainSuborgClick}
                            />
                        }
                        {this.state.displayAddFeature &&
                            <AddFeature
                                displayAddFeature={this.state.displayAddFeature}
                                onCancelAddFeature={this.onCancelAddFeature}
                                onAddFeature={this.onAddFeature}
                            />
                        }
                        {this.state.displayEditFeature &&
                            <EditFeature
                                displayEditFeature={this.state.displayEditFeature}
                                onCancelEditFeature={this.onCancelEditFeature}
                                onEditFeature={this.onEditFeature}
                                selectedFeatureName={this.state.selectedFeatureName}
                                selectedFeatureLabel={this.state.selectedFeatureLabel}
                                selectedFeatureID={this.state.selectedFeatureID}
                                selectedFeatureCanBeDeleted={this.state.selectedFeatureCanBeDeleted}
                                selectedFeatureIsDefault={this.state.selectedFeatureIsDefault}
                            />
                        }
                        {this.state.displaySetupFeature &&
                            <SetupFeature
                                displaySetupFeature={this.state.displaySetupFeature}
                                onCancelSetupFeature={this.onCancelSetupFeature}
                                selectedDepartments={this.state.selectedDepartments}
                                onSetupFeature={this.onSetupFeature}
                                selectedFeatureLabel={this.state.selectedFeatureLabel}
                                selectedFeatureName={this.state.selectedFeatureName}
                                selectedFeatureID={this.state.selectedFeatureID}
                                allAvailableFeatures={this.state.allAvailableFeatures}
                                allGlobalFeatures={this.state.allGlobalFeatures}
                                onFeatureOrgClick={this.onFeatureOrgClick}
                                onFeatureSuborgClick={this.onFeatureSuborgClick}
                            />
                        }
                        {this.state.displayDeleteDomain &&
                            <DeleteDomain
                                displayDeleteDomain={this.state.displayDeleteDomain}
                                onCancelDeleteDomain={this.onCancelDeleteDomain}
                                onDeleteDomain={this.onDeleteDomain}
                                selectedDomainName={this.state.selectedDomainName}
                                selectedDomainLabel={this.state.selectedDomainLabel}
                                selectedDomainID={this.state.selectedDomainID}
                            />
                        }

                        <AddCompany
                            allAvailableRepositories={this.state.availableRepositories}
                            allAvailableFeatures={this.state.allAvailableFeatures}
                            allDomainsHighlightItems={this.state.allDomainsHighlightItems}
                            allDomainsSearch={this.state.allDomainSelectItems}
                            displayAddCompany={this.state.displayAddCompany}
                            onCancelAddCompany={this.onCancelAddCompany}
                            onAddCompany={this.onAddCompany}
                            allBackends={this.state.allBackends}
                        />

                        <AddDepartment
                            displayAddDepartment={this.state.displayAddDepartment}
                            onCancelAddDepartment={this.onCancelAddDepartment}
                            onAddDepartment={this.onAddDepartment}
                            availableRepositoriesCompany={this.state.availableRepositoriesCompany}
                            availableFeaturesCompany={this.state.availableFeaturesCompany}
                            availableHighlightDomainsCompany={this.state.availableHighlightDomainsCompany}
                            availableSearchDomainsCompany={this.state.availableSearchDomainsCompany}
                            departmentLandingPage={this.state.selectedDepartmentLandingPage}
                            departmentMaxDownloads={this.state.selectedDepartmentMaxDownloads}
                            emailDomains={this.state.selectedCompanyEmailDomains}
                            departmentBackgroundColor={this.state.selectedCompanyBackgroundColor}
                            departmentFontColor={this.state.selectedCompanyFontColor}
                            departmentApplicationHeader1={this.state.selectedCompanyApplicationHeader1}
                            departmentApplicationHeader2={this.state.selectedCompanyApplicationHeader2}
                            departmentApplicationHeader3={this.state.selectedCompanyApplicationHeader3}
                            departmentLogo={this.state.selectedCompanyLogo}
                            allBackends={this.state.allBackends}
                        />

                        {this.state.displayEditCompany &&
                            <EditCompany
                                displayEditCompany={this.state.displayEditCompany}
                                onCancelEditCompany={this.onCancelEditCompany}
                                onEditCompany={this.editCompany}
                                onDeleteCompany={this.onDeleteCompany}
                                companyName={this.state.selectedCompanyName}
                                companyDefaultBackend={this.state.selectedCompanyDefaultBackend}
                                companyDefaultChemistryBackend={this.state.selectedCompanyDefaultChemistryBackend}
                                companyApplicationName={this.state.selectedCompanyApplicationName}
                                companyMaxUsers={this.state.selectedCompanyMaxUsers}
                                companyMaxDownloads={this.state.selectedCompanyMaxDownloads}
                                companyLandingPage={this.state.selectedCompanyLandingPage}
                                companyActive={this.state.companyActive}
                                setLandingPage={this.setLandingPage}
                                availableRepositoriesCompany={this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ? this.state.availableRepositories : this.state.availableRepositoriesCompany}
                                selectedFeaturesCompany={this.state.functionRolesCompany}
                                availableFeaturesCompany={this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ? this.state.allAvailableFeatures : this.state.availableFeaturesCompany}
                                availableHighlightDomainsCompany={this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ? this.state.allDomainsHighlightItems : this.state.availableHighlightDomainsCompany}
                                availableSearchDomainsCompany={this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ? this.state.allDomainSelectItems : this.state.availableSearchDomainsCompany}
                                selectedCompanyRepositories={this.state.selectedRepositoriesCompany}
                                selectedHighlightDomainsCompany={this.state.highlightDomainsCompany}
                                selectedSearchDomainsCompany={this.state.selectedSearchDomainsCompany}
                                selectedCompanyEmailDomains={this.state.selectedCompanyEmailDomains}
                                companyBackgroundColor={this.state.selectedCompanyBackgroundColor}
                                companyFontColor={this.state.selectedCompanyFontColor}
                                companyApplicationHeader1={this.state.selectedCompanyApplicationHeader1}
                                companyApplicationHeader2={this.state.selectedCompanyApplicationHeader2}
                                companyApplicationHeader3={this.state.selectedCompanyApplicationHeader3}
                                companyLogo={this.state.selectedCompanyLogo}
                                companyUseCustomHeader={this.state.selectedCompanyUseCustomHeader}
                                allBackends={this.state.allBackends}
                                callbackFromDefaultBackendChange={this.callbackFromDefaultBackendChange}
                            />
                        }
                        {this.state.displayDeleteCompany &&
                            <DeleteCompany
                                displayDeleteCompany={this.state.displayDeleteCompany}
                                onCancelDeleteCompany={this.onCancelDeleteCompany}
                                onDeleteCompanyAndUsers={this.onDeleteCompanyAndUsers}
                                selectedCompanyName={this.state.selectedCompanyName}
                            />
                        }
                        {this.state.displayEditDepartment &&
                            <EditDepartment
                                displayEditDepartment={this.state.displayEditDepartment}
                                onCancelEditDepartment={this.onCancelEditDepartment}
                                onDeleteDepartment={this.onDeleteDepartment}
                                onEditDepartment={this.onEditDepartment}
                                selectedDepartmentName={this.state.selectedDepartmentName}
                                selectedDepartmentDefaultBackend={this.state.selectedDepartmentDefaultBackend}
                                selectedDepartmentDefaultChemistryBackend={this.state.selectedDepartmentDefaultChemistryBackend}
                                callbackFromDefaultBackendChangeDepartment={this.callbackFromDefaultBackendChangeDepartment}
                                availableRepositoriesCompany={this.state.availableRepositoriesCompany}
                                availableRepositoriesDepartment={this.state.availableRepositoriesDepartment}
                                availableFeaturesCompany={this.state.availableFeaturesCompany}
                                availableHighlightDomainsCompany={this.state.availableHighlightDomainsCompany}
                                availableSearchDomainsCompany={this.state.availableSearchDomainsCompany}
                                departmentActive={this.state.departmentActive}
                                selectedDepartmentRepositories={this.state.selectedDepartmentRepositories}
                                selectedHighlightDomainsDepartment={this.state.selectedHighlightDomainsDepartment}
                                selectedSearchDomainsDepartment={this.state.selectedSearchDomainsDepartment}
                                selectedFeaturesDepartment={this.state.selectedFeaturesDepartment}
                                selectedDepartmentApplicationName={this.state.selectedDepartmentApplicationName}
                                startTrialPeriodDepartment={this.state.startTrialPeriodDepartment}
                                endTrialPeriodDepartment={this.state.endTrialPeriodDepartment}
                                departmentLandingPage={this.state.selectedDepartmentLandingPage}
                                selectedDepartmentEMailDomains={this.state.selectedDepartmentEMailDomains}
                                selectedDepartmentMaxDownloads={this.state.selectedDepartmentMaxDownloads}
                                selectedCompanyMaxDownloads={this.state.selectedCompanyMaxDownloads}
                                availableCompanyEmailDomains={this.state.availableCompanyEmailDomains}
                                companyEmailDomains={this.state.companyEmailDomains}
                                departmentBackgroundColor={this.state.selectedDepartmentBackgroundColor}
                                departmentFontColor={this.state.selectedDepartmentFontColor}
                                departmentApplicationHeader1={this.state.selectedDepartmentApplicationHeader1}
                                departmentApplicationHeader2={this.state.selectedDepartmentApplicationHeader2}
                                departmentApplicationHeader3={this.state.selectedDepartmentApplicationHeader3}
                                departmentLogo={this.state.selectedDepartmentLogo}
                                useCustomHeader={this.state.selectedDepartmentUseCustomHeader}
                                allBackends={this.state.allBackends}
                            />
                        }
                        {this.state.displayDeleteDepartment &&
                            <DeleteDpartment
                                displayDeleteDepartment={this.state.displayDeleteDepartment}
                                onCancelDeleteDepartment={this.onCancelDeleteDepartment}
                                selectedDepartmentName={this.state.selectedDepartmentName}
                                onDeleteDepartmentAndUsers={this.onDeleteDepartmentAndUsers}
                            />
                        }
                        {this.state.displayEditUser &&
                            <EditUser
                                displayEditUser={this.state.displayEditUser}
                                onCancelEditUser={this.onCancelEditUser}
                                onEditUser={this.onEditUser}
                                onDeleteUser={this.onDeleteUser}
                                selectedUserName={this.state.selectedUserName}
                                selectedUserForename={this.state.selectedUserForename}
                                selectedUserRole={this.state.selectedUserRole}
                                selectedUserActive={this.state.selectedUserActive}
                                selectedUserEmail={this.state.selectedUserEmail}
                                selectedUserUsername={this.state.selectedUserUsername}
                                selectedCompany={this.state.selectedCompany}
                                selectedDepartment={this.state.selectedDepartment}
                                selectedUserSAML={this.state.selectedUserSAML}
                                userDetails={userDetails}
                            />
                        }
                        {this.state.displayResendVerification &&
                            <ResendVerification
                                displayResendVerification={this.state.displayResendVerification}
                                onCancelResendVerification={this.onCancelResendVerification}
                                selectedUserName={this.state.selectedUserName}
                                selectedUserID={this.state.selectedUserID}
                                selectedUserForename={this.state.selectedUserForename}
                                selectedUserUsername={this.state.selectedUserUsername}
                                selectedUserEmail={this.state.selectedUserEmail}
                                onResendVerification={this.onResendVerification}
                            />
                        }
                        {this.state.displayVerifyUser &&
                            <VerifyUser
                                displayVerifyUser={this.state.displayVerifyUser}
                                onCancelVerifyUser={this.onCancelVerifyUser}
                                selectedUserName={this.state.selectedUserName}
                                selectedUserID={this.state.selectedUserID}
                                selectedUserForename={this.state.selectedUserForename}
                                selectedUserUsername={this.state.selectedUserUsername}
                                onVerifySingleUser={this.onVerifySingleUser}
                            />
                        }
                        {this.state.displayDeleteUser &&
                            <DeleteUser
                                displayDeleteUser={this.state.displayDeleteUser}
                                onCancelDeleteUser={this.onCancelDeleteUser}
                                selectedUserName={this.state.selectedUserName}
                                selectedUserID={this.state.selectedUserID}
                                selectedUserForename={this.state.selectedUserForename}
                                selectedUserUsername={this.state.selectedUserUsername}
                                onDeleteSingleUser={this.onDeleteSingleUser}
                            />
                        }
                        {this.state.displayDeleteUsers &&
                            <DeleteUsers
                                displayDeleteUsers={this.state.displayDeleteUsers}
                                onCancelDeleteUsers={this.onCancelDeleteUsers}
                                onDeleteUsers={this.onDeleteUsers}
                                selectedUserDetails={this.state.selectedUserDetails}
                            />
                        }
                        {this.state.displayMoveUsers &&
                            <MoveUsers
                                displayMoveUsers={this.state.displayMoveUsers}
                                onCancelMoveUsers={this.onCancelMoveUsers}
                                onMoveUsers={this.onMoveUsers}
                                companies={this.state.companies}
                                departments={this.state.selectedDepartments}
                                selectedUserDetails={this.state.selectedUserDetails}
                                userDetails={userDetails}
                                selectedCompany={this.state.selectedCompany}
                            />
                        }
                        {this.state.displayActivateUsers &&
                            <ActivateUsers
                                displayActivateUsers={this.state.displayActivateUsers}
                                onCancelActivateUsers={this.onCancelActivateUsers}
                                onActivateUsers={this.onActivateUsers}
                                selectedUserDetails={this.state.selectedUserDetails}
                            />
                        }
                        {this.state.displayInactivateUsers &&
                            <InactivateUsers
                                displayInactivateUsers={this.state.displayInactivateUsers}
                                onCancelInactivateUsers={this.onCancelInactivateUsers}
                                onInactivateUsers={this.onInactivateUsers}
                                selectedUserDetails={this.state.selectedUserDetails}
                            />
                        }

                        <AddUser
                            displayAddUser={this.state.displayAddUser}
                            onCancelAddUser={this.onCancelAddUser}
                            onAddUser={this.onAddUser}
                            selectedCompany={this.state.selectedCompany}
                            selectedDepartment={this.state.selectedDepartment}
                            userDetails={userDetails}
                        />

                        <LogoutUser
                            displayLogoutDialog={this.state.displayLogoutDialog}
                            onCancelLogoutUser={this.onCancelLogoutUser}
                            onLogoutUser={this.onLogoutUser}
                        />

                    </div>
                    :
                    <div className='col-12'>
                        <Toast ref={(el) => { this.growl = el }} />
                        <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0 }}>
                            <EditRegularUser
                                onEditRegularUser={this.onEditRegularUser}
                                userDetails={userDetails}
                            />
                        </div>
                    </div>
                }
                <Toast ref={this.toastBC} position="bottom-center" style={{ width: '30rem', bottom: 65, backgroundColor: 'white', opacity: '1', boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }} />
                <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                    onHide={this.rejectSelectedUsers} style={{ width: '37rem', bottom: 65 }}
                    draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                    <ToastContent selectedElements={this.state.selectedUserDetails}
                        elementLabel='user'
                        onReload={this.onReloadToast}
                        onHide={this.rejectSelectedUsers}
                        onActivate={this.openActivateUsers}
                        onDeactivate={this.openInactivateUsers}
                        onMoveUsers={this.openMoveUsers}
                        onDelete={this.openDeleteUsers}
                        userDetails={this.props.userDetails}
                        activeOptions={['activateUsers', 'deactivateUsers', 'moveUsers', 'deleteUsers']} />
                </Dialog>
            </div>
        )
    }
}

export default Administration