import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";


class FilterSectionDateRange extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }

    render() {

        const { filterID, startDate, endDate, filterInfo, onFilterValuesChange } = this.props;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className="grid" style={{ marginLeft: 0 }}>
                    <div className="col-fixed labelColumn">
                        <label htmlFor="startDate" className="valignMiddle">From</label>
                    </div>
                    <div className="col">
                        <InputText
                            id='startDate'
                            value={startDate ? startDate : ''}
                            onChange={(e) => {
                                const filterObject = e.target.value || endDate ? {
                                    filterID: filterID,
                                    startDate: e.target.value,
                                    endDate: endDate
                                } : {};
                                onFilterValuesChange(filterObject);
                            }}
                            placeholder={`e.g. 2019-10`}
                            className="valignMiddle"
                            style={{ width: '100%' }}>
                        </InputText>
                    </div>
                    <div className="col-fixed labelColumn">
                        <label htmlFor="endDate" className="valignMiddle">to</label>
                    </div>
                    <div className="col">
                        <InputText
                            id='endDate'
                            value={endDate ? endDate : ''}
                            onChange={(e) => {
                                const filterObject = startDate || e.target.value ? {
                                    filterID: filterID,
                                    startDate: startDate,
                                    endDate: e.target.value
                                } : {};
                                onFilterValuesChange(filterObject);
                            }}
                            placeholder={`e.g. 2022`}
                            className="valignMiddle"
                            style={{ width: '100%' }}>
                        </InputText>
                    </div>
                    {filterInfo ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {filterInfo}
                        </div> : null}
                </div>
            </>
        );
    }
}

export default FilterSectionDateRange;