import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class VerifyUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            verifyUserChecked: false
        }
    }

    onCheckVerifyUser = (e) => {
        if (e.checked) {
            this.setState({
                verifyUserChecked: true
            })
        } else {
            this.setState({
                verifyUserChecked: false
            })
        }
    }

    onVerifySingleUser = () => {
        this.props.onVerifySingleUser(this.props.selectedUserID)
    }

    render() {

        const footerVerifyUser = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Cancel"
                    className='p-button-sm p-button-secondary'
                    onClick={this.props.onCancelVerifyUser}
                />
                <Button label="Verify"
                    className='p-button-sm primaryButton'
                    disabled={this.state.verifyUserChecked ? false : true}
                    onClick={this.onVerifySingleUser}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayVerifyUser} style={{ 'width': "40vw" }} header="Verify user" focusOnShow={false}
                modal={true} footer={footerVerifyUser} dismissableMask={false}
                onHide={() => this.props.onCancelVerifyUser()} className='styledDialog'>
                <div  style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div style={{ marginBottom: 10 }}>
                        <label>{`This will verify user '${this.props.selectedUserName}, ${this.props.selectedUserForename}'.`}</label>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <Checkbox inputId='verifyUserChecked'
                            onChange={(e) => this.onCheckVerifyUser(e)}
                            checked={this.state.verifyUserChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                        />
                        <label htmlFor='verifyUserChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default VerifyUser