import React from 'react';
import {YearStatisticsPanel} from "./YearStatisticsPanel/YearStatisticsPanel";
import {YearStatisticsChart} from "./YearStatisticsChart/YearStatisticsChart";
import styles from './YearStatistics.module.scss';
import {useSelector} from "react-redux";
import {useResizeDetector} from "react-resize-detector";

export const YearStatistics = () => {
    const selectedSearches = useSelector(({yearStatistics: {selectedSearches}}) => selectedSearches);
    const isData = selectedSearches.filter(({searchName}) => searchName).length;
    const {height, ref} = useResizeDetector();

    return (
        <div className={styles.statisticsContainer}>
            <YearStatisticsPanel ref={ref}/>
            {!!isData && <YearStatisticsChart height={height}/>}
        </div>
    );
};
