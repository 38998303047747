/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { TreeTable } from 'primereact/treetable'

class SetupBackends extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allGlobalBackends: [],
            allBackends: [],
            selectedOrgs: [],
            selectedBackends: [],
            selectedBackendType: 'data'
        }
    }

    componentDidMount() {
        let selectedOrgs = []
        let selectedBackends = []
        let allGlobalBackends = this.props?.companyBackends
        let allBackends = this.props?.allBackends

        this.props?.companyBackends.forEach(org => {
            selectedOrgs = [...selectedOrgs, org.id]
        })
        this.props.allBackends?.forEach(be => {
            if (be.type === 'webapi') {
                selectedBackends = [...selectedBackends, be.id]
            }
        })

        this.setState({
            selectedOrgs: selectedOrgs,
            selectedBackends: selectedBackends,
            allGlobalBackends: allGlobalBackends,
            allBackends: allBackends
        })
    }

    componentDidUpdate(prevProps) {
        var self = this
        if (self.props.companyBackends !== prevProps.companyBackends) {
            let allGlobalBackends = this.props.companyBackends
            this.setState({
                allGlobalBackends: allGlobalBackends
            })
        }
    }

    onSelectedOrgsChange = (e) => {
        this.setState({
            selectedOrgs: e.value
        })
    }

    onSelectedBackendsChange = (e) => {
        this.setState({
            selectedBackends: e.value
        })
    }

    handleBackendTypeChange = (type) => {
        let selectedBackends = []
        if (type === 'data') {
            this.props.allBackends?.forEach(be => {
                if (be.type === 'webapi') {
                    selectedBackends = [...selectedBackends, be.id]
                }
            })
        } else if (type === 'chem') {
            this.props.allBackends?.forEach(be => {
                if (be.type === 'chemistry') {
                    selectedBackends = [...selectedBackends, be.id]
                }
            })
        }
        this.setState({
            selectedBackendType: type,
            selectedBackends: selectedBackends
        })
    }

    onOrgClick = (e, org, be, type) => {
        this.props.onBackendOrgClick(e, org, be, type)
    }

    onSuborgClick = (e, suborg, be, type) => {
        this.props.onBackendSuborgClick(e, suborg, be, type)
    }

    render() {

        let allOrgs = []

        //console.log(this.state.allGlobalBackends)

        this.state.allGlobalBackends && this.state.allGlobalBackends?.forEach(org => {
            allOrgs = [...allOrgs, org]
        })
        let orgOptions = []

        allOrgs && allOrgs.forEach(org => {
            orgOptions = [...orgOptions, { label: org.name, value: org.id }]
        })

        let allBackends = []

        this.state.allBackends && this.state.allBackends.forEach(be => {
            if (this.state.selectedBackendType === 'data') {
                if (be.type === 'webapi') {
                    allBackends = [...allBackends, be]
                }
            } else if (this.state.selectedBackendType === 'chem') {
                if (be.type === 'chemistry') {
                    allBackends = [...allBackends, be]
                }
            }
        })

        let backendOptions = []

        allBackends && allBackends.forEach(be => {
            if (this.state.selectedBackendType === 'data') {
                if (be.type === 'webapi') {
                    backendOptions = [...backendOptions, { label: be.label, value: be.id }]
                }
            } else if (this.state.selectedBackendType === 'chem') {
                if (be.type === 'chemistry') {
                    backendOptions = [...backendOptions, { label: be.label, value: be.id }]
                }
            }
        })

        let orgs = []

        this.state.allGlobalBackends.forEach(org => {
            orgs = [...orgs, org]
        })

        //console.log(orgs)
        //console.log(this.state.selectedOrgs)

        let orgData = []

        orgData = orgs && orgs?.filter(org => this.state.selectedOrgs.includes(org.id)).map(org => (
            {
                selectable: false,
                key: org.id,
                label: org.name,
                data: {
                    name: <span style={{
                        color: org.deactivated ? 'gray' : 'black', fontWeight: 'bold', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'break-spaces'
                    }} title={org.name}>{org.name}</span>,
                    defaultBackendApi: org.defaultBackendApi ? org.defaultBackendApi :
                        this.props.allBackends?.find(be => be.isDefault === true && be.type === 'webapi'),
                    defaultChemistryBackendApi: org.defaultChemistryBackendApi ? org.defaultChemistryBackendApi :
                        this.props.allBackends?.find(be => be.isDefault === true && be.type === 'chemistry')
                },
                children: org.departments.map(department => (
                    {
                        selectable: false,
                        key: department.id,
                        data: {
                            name: <span style={{
                                color: department.deactivated ? 'gray' : 'black', overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'break-spaces'
                            }} title={department.name === 'default' ? `Unassigned / Inactive regular users` :
                                `${department.name}`}>{department.name === 'default' ? `Unassigned / Inactive regular users` : `${department.name}`}</span>,
                            defaultBackendApi: department.defaultBackendApi ? department.defaultBackendApi :
                                this.props.allBackends?.find(be => be.isDefault === true && be.type === 'webapi'),
                            defaultChemistryBackendApi: department.defaultChemistryBackendApi ? department.defaultChemistryBackendApi :
                                this.props.allBackends?.find(be => be.isDefault === true && be.type === 'chemistry')
                        }
                    }))
            }))

        allBackends && allBackends.forEach(be => {
            if (this.state.selectedBackendType === 'data') {
                orgData.forEach(org => {
                    org.data[be.id] = <Checkbox disabled={org.data.defaultBackendApi?.id === be.id} checked={org.data.defaultBackendApi?.id === be.id} onChange={(e) => this.onOrgClick(e, org, be, 'data')}></Checkbox>
                    org.children.forEach(suborg => {
                        suborg.data[be.id] = <Checkbox style={{ marginLeft: 15 }} disabled={suborg.data.defaultBackendApi?.id === be.id} checked={suborg.data.defaultBackendApi?.id === be.id} onChange={(e) => this.onSuborgClick(e, suborg, be, 'data')}></Checkbox>
                    })
                })
            } else {
                orgData.forEach(org => {
                    org.data[be.id] = <Checkbox disabled={org.data.defaultChemistryBackendApi?.id === be.id} checked={org.data.defaultChemistryBackendApi?.id === be.id} onChange={(e) => this.onOrgClick(e, org, be, 'chem')}></Checkbox>
                    org.children.forEach(suborg => {
                        suborg.data[be.id] = <Checkbox disabled={suborg.data.defaultChemistryBackendApi?.id === be.id} style={{ marginLeft: 15 }} checked={suborg.data.defaultChemistryBackendApi?.id === be.id} onChange={(e) => this.onSuborgClick(e, suborg, be, 'chem')}></Checkbox>
                    })
                })
            }
        })


        const rowClassName = (node) => {
            return { 'p-highlight': (node.children) }
        }

        let backendColumns = allBackends && allBackends.filter(be => this.state.selectedBackends.includes(be.id)).map(be => (
            <Column key={be.id} style={{
                width: '100px'/*, overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'*/
            }} field={be.id} header={`${be.label}`}></Column>
        ))

        //console.log(backendColumns)

        let nameTemplate = (node) => {
            return (
                <React.Fragment>
                    <span>{node.data.name}</span>
                </React.Fragment>
            )
        }

        const backendTypes = [
            { label: 'Data', value: 'data' },
            { label: 'Chemistry', value: 'chem' }
        ]

        return (
            <Dialog visible={this.props.displayDialogSetupBackends} style={{ 'width': "70vw" }} header="Setup backends" focusOnShow={false}
                modal={true} //blockScroll
                //footer={footerAddBackend}
                dismissableMask={false}
                onHide={() => this.props.onCancelSetupBackends()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 0, paddingTop: 20, marginRight: 0, marginBottom: 0 }}>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Organizations: </span>
                    <MultiSelect
                        maxSelectedLabels={3}
                        filter
                        options={orgOptions}
                        value={this.state.selectedOrgs}
                        onChange={(e) => this.onSelectedOrgsChange(e)}
                        style={{ marginRight: 20, verticalAlign: '-moz-middle-with-baseline' }}
                    ></MultiSelect>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Backend types: </span>
                    <Dropdown style={{ marginRight: 20 }}
                        className='dropdownNoBorder'
                        value={this.state.selectedBackendType}
                        options={backendTypes}
                        onChange={(e) => this.handleBackendTypeChange(e.value)}
                        placeholder='Select backend type'>
                    </Dropdown>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Backends: </span>
                    <MultiSelect
                        maxSelectedLabels={3}
                        filter
                        options={backendOptions}
                        value={this.state.selectedBackends}
                        onChange={(e) => this.onSelectedBackendsChange(e)}
                        style={{ verticalAlign: '-moz-middle-with-baseline' }}
                    ></MultiSelect>
                </div>
                <div className='treetable-responsive-demo' style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 30, marginRight: 0 }}>
                    <TreeTable
                        value={orgData}
                        rowClassName={rowClassName}
                        scrollable
                        //scrollHeight='65vh'
                        width="95vw"
                        className="treetable-with-header"
                        expandedKeys={this.state.expandedKeys}
                        onToggle={e => this.setState({ expandedKeys: e.value })}>
                        <Column field='name' body={nameTemplate} header='Orgs./Suborgs.' expander style={{ width: "200px" }} />
                        {backendColumns}
                    </TreeTable>
                </div>
            </Dialog>
        )
    }
}

export default SetupBackends