import "./InputFieldWithClear.css";
import { MdClear } from "react-icons/md";


const InputFieldWithClear = ({
    onClear,
    showIcon = true,
    children,
}) => {

    return (
        <div className="autocomplete-with-clear-container">
            {children}
            {showIcon &&
                <span
                    className="autocomplete-clear-icon"
                    title="Clear input field"
                    onClick={e => onClear(e)}>
                    <MdClear />
                </span>
            }
        </div>
    );
}

export default InputFieldWithClear;