import SearchGroup from "../../searchGroup/SearchGroup";


const GroupContentColumn = ({
  data,
  hasError,
  loading,
  onRunSearch,
}) => {

  return (
    <SearchGroup
      group={data}
      onRunPartialSearch={onRunSearch}
      hasError={hasError}
      fetching={loading}
    />
  );
}

export default GroupContentColumn;