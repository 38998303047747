import _ from "lodash";
import { exportDocumentsMetaDataByDocIDs } from "../../../../api/content/DocumentApi";
import { EXPORTER_TARGET_ID_FILE } from "../../../../properties";
import { saveFile } from "../services"
import { sortSelectedDocumentsByOriginalOrder } from "./selections";


export const exportDocumentIDs = (selectedDocuments = {}, idAttributeName, fileName = 'documentIDs.txt') => {

    const selDocsListSorted = sortSelectedDocumentsByOriginalOrder(selectedDocuments);
    const content = (selDocsListSorted?.map(docData => docData[idAttributeName])).join('\n');

    saveFile(content, fileName);
}


export const exportNonSelectedDocumentIDs = (inputIDs = [], selectedDocuments = {}, fileName = 'documentIDs.txt') => {

    const selDocOrigIDs = Object.values(selectedDocuments).map(selDoc => selDoc.origDocumentID);
    const notSelectedIDs = _.difference(inputIDs, selDocOrigIDs);
    const content = notSelectedIDs.join('\n');

    saveFile(content, fileName);
}


export const exportMetadata = async (selectedDocuments = {}) => {

    const selDocsListSorted = sortSelectedDocumentsByOriginalOrder(selectedDocuments);
    const documentIDs = selDocsListSorted.map(doc => {
        return {
            documentIdentifier: doc.documentID,
            repositoryIdentifier: doc.repositoryID
        }
    });

    return await exportDocumentsMetaDataByDocIDs(documentIDs, EXPORTER_TARGET_ID_FILE);
}