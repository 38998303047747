export const prepareMetaData = (data) => {
    const metadata = data.filter(item => item.fields.doc_id?.storedData)
        .map(result => (
            {[result.fields.srcrep.terms[0]]: result.fields.doc_id?.storedData}));

    // group by repoName
    const reducer = (acc, curVal) => {
        const repoName = Object?.keys(curVal)[0];
        if (acc[repoName]) {
            acc[repoName] = {docIds: [...acc[repoName].docIds, curVal[repoName]]};
        } else {
            acc[repoName] = {docIds: [curVal[repoName]]};
        }
        return acc;
    }
    return metadata.reduce(reducer, {});
};
