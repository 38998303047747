import * as ActionTypes from '../actions'

const initState = {
    additionalDocumentData: []
}

const document = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.ADDITIONAL_DOCUMENT_DATA:
            return {
                ...state,
                additionalDocumentData: action.additionalDocumentData
            }

        default:
            return state
    }
}

export default document
