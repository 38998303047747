import * as ActionTypes from '../actions'

const initialState = {
    data: [],
    repositories: []
}


const company = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_ALL_COMPANIES:
            return {
                ...state,
                data: action.companies
            }
        case ActionTypes.ADD_ALL_REPOSITORIES:
            return {
                ...state,
                repositories: action.repositories
            }
        default:
            return state

    }
}

export default company