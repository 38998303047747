import './LogoUpload.css';
import { FileUpload } from "primereact/fileupload";
import { useState } from "react";
import { APP_PROPERTIES } from "../../../properties";


const LogoUpload = ({
    accept = '.jpg,.jpeg,.png',
    maxFileSize = 102400,
    label = 'Logo',
    buttonLabel = 'Upload image',
    infoText = 'Max. file size: 100kb. Accepted: jpg, png',
    onImageUpload
}) => {

    const [key, setKey] = useState('key');

    const handleUpload = ({ files }) => {
        setKey(`key_${Math.random()}`);

        let image = APP_PROPERTIES.HEADER_LOGO_APP;
        if (files && files[0]) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = function (e) {
                image = reader.result;
                onImageUpload(image);
            }.bind(this);
        }
    }

    return (
        <>
            <div className="logo-upload-label"><label>{label}</label></div>
            <FileUpload
                key={key}
                auto={true}
                customUpload={true}
                uploadHandler={handleUpload}
                mode="basic"
                accept={accept}
                maxFileSize={maxFileSize}
                chooseLabel={buttonLabel} />
            <div className="logo-upload-infoText">{infoText}</div>
        </>
    );
};

export default LogoUpload;