import LogicGroupCompact from "../../../../../../common/queryBuilder/searchForm/searchGroup/compactMode/groupTypes/LogicGroupCompact/LogicGroupCompact";
import {
  SEARCH_GROUP_TYPE_API_SYNTAX,
  SEARCH_GROUP_TYPE_FILTER,
  SEARCH_GROUP_TYPE_LOGIC,
  SEARCH_GROUP_TYPE_SAVED_SEARCH,
  SEARCH_GROUP_TYPE_TERM,
} from "../../../../helpers/constants";
import SavedSearchGroupCompact from "../../../../../../common/queryBuilder/searchForm/searchGroup/compactMode/groupTypes/SavedSearchGroupCompact/SavedSearchGroupCompact";
import APISyntaxGroupCompact from "../../../../../../common/queryBuilder/searchForm/searchGroup/compactMode/groupTypes/APISyntaxGroupCompact/APISyntaxGroupCompact";
import TermGroupCompactContainer from "./TermGroupCompactContainer/TermGroupCompactContainer";
import FilterGroupCompactContainer from "./FilterGroupCompactContainer/FilterGroupCompactContainer";

const SearchGroupItem = ({ group, searchGroups }) => {
  const { type } = group;
  if (type === SEARCH_GROUP_TYPE_TERM) {
    return <TermGroupCompactContainer group={group} />;
  }
  if (type === SEARCH_GROUP_TYPE_LOGIC) {
    return <LogicGroupCompact group={group} groups={searchGroups} />;
  }
  if (type === SEARCH_GROUP_TYPE_FILTER) {
    return <FilterGroupCompactContainer group={group} />;
  }
  if (type === SEARCH_GROUP_TYPE_SAVED_SEARCH) {
    return (
      <SavedSearchGroupCompact
        savedSearchID={group?.savedSearchID}
        savedSearchData={group?.savedSearchData}
      />
    );
  }
  if (type === SEARCH_GROUP_TYPE_API_SYNTAX) {
    return <APISyntaxGroupCompact queryString={group.queryString} />;
  }
  return null;
};

export default SearchGroupItem;
