import { Toast } from "primereact/toast";
import { useRef } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { stopEventPropagation } from "../../util";


const CellData = ({
    content,
    copyContent,
    align,
    allowCopy,
    cropContent,
    onContentClick,
    contentTooltip = "",
    containerStyleClass = '',
    containerStyle = {},
    contentStyleClass = "",
    contentStyle = {},
    noPaddingLeft,
}) => {

    const growl = useRef(null);

    const handleContentClick = (e) => {
        stopEventPropagation(e);
        onContentClick(e);
    }

    const pColAlign = align || 'left';
    const paddingLeft = noPaddingLeft ? { paddingLeft: 0 } : {};
    const pColStyle = { textAlign: pColAlign, ...containerStyle, ...paddingLeft };
    const allowCopyStyleClasses = allowCopy ? 'hoverTrigger highlightOnHover' : '';

    return (
        <>
            {content &&
                <div className={`${allowCopyStyleClasses} ${containerStyleClass} width100perc breakWord breakAll`} style={pColStyle}>
                    <Toast ref={growl} />

                    {allowCopy ?
                        <CopyToClipboard text={copyContent || content}>
                            <div className="grid grid-nogutter width100perc"
                                title={`Copy to clipboard: ${copyContent || content}`}
                                onClick={(e) => growl.current.show({
                                    sticky: false, severity: 'success', summary: 'Success',
                                    detail: <div>
                                        <div>Copied to clipboard:</div>
                                        <div><b>{copyContent || content}</b></div>
                                    </div>
                                })}>
                                <div className={`col ${cropContent ? 'clampingContainer line-clamp line-clamp-1-line' : ''}`}>
                                    {onContentClick ?
                                        <a className={contentStyleClass} title={contentTooltip} onClick={handleContentClick}>{content}</a>
                                        :
                                        content
                                    }
                                </div>
                                <div className="col-fixed textAlignRight"
                                    style={{ alignSelf: 'center', marginLeft: 10, paddingRight: 5, width: 35 }}>
                                    <span className="showOnHover">Copy</span>
                                </div>
                            </div>
                        </CopyToClipboard>
                        :
                        <div className={`${cropContent ? 'clampingContainer line-clamp line-clamp-1-line' : ''}`}
                            title={copyContent || content}>
                            {content}
                        </div>
                    }
                </div>}
        </>
    );
}

export default CellData;