
import React, { Component } from "react";
import SharedPopup from "./shared/Popup";
// import "../../style/popup.css";

interface Props {
  onMoveAway: () => void;
  paddingX: number;
  paddingY: number;
  children: JSX.Element;
  onShowConcept: (ocid: any) => void;
  annTypeLabelsMap: any;
  domainColors: any;
  text: string;
}

class PopupContainer extends Component<Props> {
  container: HTMLDivElement | null = null;
  state = {
    domain: 'green'
  }
  unsubscribe = () => { };

  onMouseMove = (event: MouseEvent) => {
    if (!this.container) {
      return;
    }

    const { onMoveAway, paddingX, paddingY } = this.props;

    const { clientX, clientY } = event;

    // TODO: see if possible to optimize
    const { left, top, width, height } = this.container.getBoundingClientRect();

    const inBoundsX =
      clientX > left - paddingX && clientX < left + width + paddingX;
    const inBoundsY =
      clientY > top - paddingY && clientY < top + height + paddingY;

    const isNear = inBoundsX && inBoundsY;

    if (!isNear) {
      onMoveAway();
    }
  };

  rowClassName = (rowData: any) => {
    if (rowData?.key === 'ocid') {
      return { 'title': true };
    } else {
      return { 'title': false }
    }
  }
  attachRef = (ref: HTMLDivElement | null) => {
    this.container = ref;
    this.unsubscribe();

    if (ref) {
      const { ownerDocument: doc } = ref;
      doc.addEventListener("mousemove", this.onMouseMove);
      this.unsubscribe = () => {
        doc.removeEventListener("mousemove", this.onMouseMove);
      };
    }
  };

  render() {
    const { children, onShowConcept, annTypeLabelsMap, domainColors, text } = this.props;
    return (
      <div ref={this.attachRef} className='popup'>
        <SharedPopup
          children={children}
          onShowConcept={onShowConcept}
          annTypeLabelsMap={annTypeLabelsMap}
          domainColors={domainColors}
          text={text}
        />
      </div>
    );
  }
}

export default PopupContainer;
