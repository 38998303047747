import React, { Component } from 'react'
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { TreeTable } from 'primereact/treetable'
import ConfirmationDialog from "../common/ConfirmDialog/ConfirmationDialog";

class SetupFeature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            featureLabel: '',
            featureName: '',
            featureID: '',
            selectedFeature: [],
            selectedOrgs: [],
            allAvailableFeatures: [],
            allGlobalFeatures: [],
            displayConfirmationDialog: false,
            selectedFeat: { label: '' },
            selectedOrg: { label: '' }
        }
    }

    componentDidMount() {
        let allAvailableFeatures = this.props.allAvailableFeatures
        let selectedFeatures = []
        let selectedOrgs = []
        let featureName = this.props.selectedFeatureName ? this.props.selectedFeatureName : ''
        let featureLabel = this.props.selectedFeatureLabel ? this.props.selectedFeatureLabel : ''
        let featureID = this.props.selectedFeatureID ? this.props.selectedFeatureID : ''
        let allGlobalFeatures = this.props.allGlobalFeatures

        this.props.allAvailableFeatures.forEach(feat => {
            if ((feat.name !== 'infopage' && feat.name !== 'history' && feat.name !== 'tutorials' && feat.name !== 'feedback' && feat.name !== 'manual' && feat.name !== 'support')) {
                selectedFeatures = [...selectedFeatures, feat.id]
            }
        })

        this.props.allGlobalFeatures.forEach(org => {
            selectedOrgs = [...selectedOrgs, org.id]
        })

        this.setState({
            featureLabel: featureLabel,
            featureName: featureName,
            featureID: featureID,
            allAvailableFeatures: allAvailableFeatures,
            selectedFeatures: selectedFeatures,
            selectedOrgs: selectedOrgs,
            allGlobalFeatures: allGlobalFeatures
        })
    }

    componentDidUpdate(prevProps) {
        var self = this
        if (self.props.allGlobalFeatures !== prevProps.allGlobalFeatures) {
            let allGlobalFeatures = this.props.allGlobalFeatures
            this.setState({
                allGlobalFeatures: allGlobalFeatures
            })
        }
    }

    onSetupFeature = () => {
        this.props.onSetupFeature(this.props.selectedFeatureID, this.state.featureLabel, this.state.featureName)
    }

    onOrgClick = (e, org, feat) => {
        if (e.checked) {
            this.setState({
                displayConfirmationDialog: true,
                checked: true,
                selectedOrg: org,
                selectedFeat: feat
            })
        } else {
            this.props.onFeatureOrgClick(false, org, feat)
        }
    }

    onSuborgClick = (e, suborg, feat) => {
        this.props.onFeatureSuborgClick(e, suborg, feat)
    }

    onSelectedFeaturesChange = (e) => {
        this.setState({
            selectedFeatures: e.value
        })
    }

    onSelectedOrgsChange = (e) => {
        this.setState({
            selectedOrgs: e.value
        })
    }

    onHideConfirmationDialog = () => {
        this.setState({
            displayConfirmationDialog: false
        })
    }

    onConfirmClick = (choice) => {
        this.setState({
            displayConfirmationDialog: false
        }, () => {
            this.props.onFeatureOrgClick(this.state.checked, this.state.selectedOrg, this.state.selectedFeat, choice)
        })
    }

    render() {

        let allFeatures = []

        this.state.allAvailableFeatures && this.state.allAvailableFeatures.forEach(feat => {
            if ((feat.name !== 'infopage' && feat.name !== 'history' && feat.name !== 'tutorials' && feat.name !== 'feedback' && feat.name !== 'manual' && feat.name !== 'support')) {
                allFeatures = [...allFeatures, feat]
            }
        })

        let allOrgs = []

        this.state.allGlobalFeatures && this.state.allGlobalFeatures.forEach(org => {
            allOrgs = [...allOrgs, org]
        })

        let orgs = []

        this.state.allGlobalFeatures.forEach(org => {
            orgs = [...orgs, org]
        })

        let orgData = []


        orgData = orgs && orgs.filter(org => this.state.selectedOrgs.includes(org.id)).map(org => (
            {
                selectable: false,
                key: org.id,
                label: org.name,
                data: {
                    name: <span style={{
                        color: org.deactivated ? 'gray' : 'black', fontWeight: 'bold', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'break-spaces'
                    }} title={org.name}>{org.name}</span>,
                    selectedFeatures: org.selectedFunctionRoles
                },
                children: org.departments.map(department => (
                    {
                        selectable: false,
                        key: department.id,
                        data: {
                            name: <span style={{
                                color: department.deactivated ? 'gray' : 'black', overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'break-spaces'
                            }} title={department.name === 'default' ? `Unassigned / Inactive regular users` :
                                `${department.name}`}>{department.name === 'default' ? `Unassigned / Inactive regular users` : `${department.name}`}</span>,
                            selectedFeatures: department.selectedFunctionRoles
                        }
                    }))
            }))

        let featureOptions = []

        allFeatures && allFeatures.forEach(feat => {
            featureOptions = [...featureOptions, { label: `${feat.isDefault ? feat.label.concat(' *') : feat.label}`, value: feat.id }]
            orgData.forEach(org => {
                org.data[feat.label] = <Checkbox style={{ cursor: feat.isDefault ? 'default' : 'pointer' }} disabled={feat.isDefault ? true : false} checked={org.data.selectedFeatures.some(el => el.id === feat.id)} onChange={(e) => this.onOrgClick(e, org, feat)}></Checkbox>
                org.children.forEach(suborg => {
                    suborg.data[feat.label] = <Checkbox disabled={feat.isDefault ? true : false} style={{ marginLeft: 15, cursor: feat.isDefault ? 'default' : 'pointer' }} checked={suborg.data.selectedFeatures.some(el => el.id === feat.id)} onChange={(e) => this.onSuborgClick(e, suborg, feat)}></Checkbox>
                })
            })
        })

        let orgOptions = []

        allOrgs && allOrgs.forEach(org => {
            orgOptions = [...orgOptions, { label: org.name, value: org.id }]
        })

        let nameTemplate = (node) => {
            return (
                <React.Fragment>
                    <span>{node.data.name}</span>
                </React.Fragment>
            )
        }

        let featureColumns = allFeatures && allFeatures.filter(feat => this.state.selectedFeatures.includes(feat.id)).map(feat => (
            <Column key={feat.id} style={{
                width: '129px'
            }} field={feat.label} header={`${feat.isDefault ? feat.label.concat(' *') : feat.label}`}></Column>
        ))

        const rowClassName = (node) => {
            return { 'p-highlight': (node.children) }
        }

        return (
            <Dialog visible={this.props.displaySetupFeature} style={{ 'width': "97vw", 'height': '95vh' }} header={this.state.featureLabel !== "" ? "Setup feature" : "Setup features"} focusOnShow={false}
                modal={true}
                dismissableMask={false}
                onHide={() => this.props.onCancelSetupFeature()} className='styledDialog'>
                {this.state.featureLabel !== "" &&
                    <div style={{ marginBottom: 20 }}>
                        <span style={{}}>Feature label: {this.state.featureLabel}</span>
                        <span style={{ marginLeft: 30 }}>Feature ID: {this.state.featureID}</span>
                        <span style={{ marginLeft: 30 }}>Feature name: {this.state.featureName}</span>
                    </div>
                }
                <div style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 0, paddingTop: 20, marginRight: 0, marginBottom: 0 }}>
                    <span>Organizations: </span>
                    <MultiSelect
                        pt={{
                            checkboxIcon: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (e.target.className.baseVal !== "") {
                                        e.target.parentNode.click();
                                    } else {
                                        e.target.parentNode.parentNode.click();
                                    }
                                },
                            },
                            headerCheckbox: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                },
                            },
                        }}
                        filter
                        options={orgOptions}
                        value={this.state.selectedOrgs}
                        onChange={(e) => this.onSelectedOrgsChange(e)}
                        style={{ marginRight: 20 }}
                        maxSelectedLabels={3}
                        placeholder='Select organizations'
                    ></MultiSelect>
                    <span>Features: </span>
                    <MultiSelect
                        pt={{
                            checkboxIcon: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (e.target.className.baseVal !== "") {
                                        e.target.parentNode.click();
                                    } else {
                                        e.target.parentNode.parentNode.click();
                                    }
                                },
                            },
                            headerCheckbox: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                },
                            },
                        }}
                        filter
                        options={featureOptions}
                        value={this.state.selectedFeatures}
                        style={{ marginRight: 20 }}
                        onChange={(e) => this.onSelectedFeaturesChange(e)}
                        maxSelectedLabels={3}
                        placeholder='Select features'
                    ></MultiSelect>
                    <label>* = Default feature</label>
                </div>
                <div className='treetable-responsive-demo' style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, marginRight: 0 }}>
                    <TreeTable value={orgData}
                        rowClassName={rowClassName}
                        scrollable
                        scrollHeight='65vh'
                        width="95vw"
                        className="treetable-with-header"
                        expandedKeys={this.state.expandedKeys}
                        onToggle={e => this.setState({ expandedKeys: e.value })}>
                        <Column field='name' body={nameTemplate} header='Orgs./Suborgs.' expander style={{ width: "200px" }} />
                        {featureColumns}
                    </TreeTable>
                </div>
                <ConfirmationDialog
                    displayDialog={this.state.displayConfirmationDialog}
                    onHide={() => this.onHideConfirmationDialog()}
                    onSubmit={() => this.onConfirmClick('all')}
                    onSubmitSecondChoice={() => this.onConfirmClick('none')}
                    headerText={`Activate feature "${this.state.selectedFeat.label}" for "${this.state.selectedOrg.label}"`}
                    messageText={`By clicking "For all suborganizations" this feature will be activated for the organization and all of its suborganizations.
                     Warning: The feature then will be visible for all users of these suborganizations immediately! If you only want to activate this feature for the organization click "Only for organization".
                      The feature then will not be visible for the users of all suborganizations. The feature needs to be activated for a certain suborganization in the next step.`}
                    submitButtonLabel="For all suborganizations"
                    cancelButtonLabel="Only for organization"
                    secondChoice={true}>
                </ConfirmationDialog>
            </Dialog>
        )
    }
}

export default SetupFeature