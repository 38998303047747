import AddRemoveDocumentsDropdown from '../docCollection/AddRemoveDocumentsDropdown';
import { getReadcubePapersIdentifier } from '../../common/ReadCube/readCubePapersUtil';
import ReadCubePapersLink from '../../common/ReadCube/ReadCubePapersLink';
//import { Checkbox } from 'primereact/checkbox';
import { APP_PROPERTIES } from '../../../properties/index';
import { hasUserRole } from '../util';

const LibraryControls = ({
    doc,
    onAddToCollections,
    onRemoveFromCollections,
    onCustomClick,
    documentCollections,
    selectedDocument
    //selectedDocumentsAction,
    //onDocumentSelectionChange
}) => {
    const { type, identifier } = getReadcubePapersIdentifier(doc.metadata);
    const collections = doc.metadata?.documentCollectionList || [];
    return (
        <div style={{ marginTop: 0, marginBottom: -10 }} onClick={e => onCustomClick(e)} key={doc.metadata?.ocDocId}>
            {hasUserRole('ROLE_LIBRARY') ?
                <>
                    {/*  <span style={{ display: 'inline-block' }}>
                        <Checkbox inputId='userPassword'
                            onChange={(e) => onDocumentSelectionChange(e, doc.metadata?.ocDocId, doc.metadata?.repositoryId, doc.metadata?.title)}
                            checked={selectedDocumentsAction.some(document => document['docId'] === doc.metadata.ocDocId)}
                            value='' style={{ marginRight: 10 }}>
                        </Checkbox>
    </span>*/}

                    {identifier && APP_PROPERTIES.INCLUDE_READCUBE &&
                        <>
                            <span id={`readcubeLinks`}
                                style={{ marginRight: 10, verticalAlign: 'middle', display: 'inline-block' }}>
                                <ReadCubePapersLink
                                    type={type}
                                    identifier={identifier}
                                    compactView={false}
                                />
                            </span>
                            <span style={{ verticalAlign: 'top', display: 'inline-block', marginRight: 10, color: '#979797' }}>|</span>
                        </>
                    }
                    <span>
                        <label style={{ verticalAlign: 'top', display: 'inline-block', marginTop: 1, color: 'black' }}>In collections:</label>
                    </span>
                    <span style={{ verticalAlign: 'top', display: 'inline-block', marginTop: -4, color: 'black' }}>
                        <AddRemoveDocumentsDropdown
                            docMetadata={doc.metadata}
                            selectedDocument={selectedDocument}
                            documentCollections={documentCollections}
                            noCreation={true}
                            inCollections={collections}
                            placeholder="none"
                            maxSelectedLabels={2}
                            onAddToCollections={(e) => onAddToCollections(doc.metadata, e)}
                            onRemoveFromCollections={(e) => onRemoveFromCollections(doc.metadata, e)}
                        />
                    </span>
                </>
                : null}
        </div >
    );
}
export default LibraryControls