import classNames from "classnames";
import "./Badge.scss";

interface BadgeProps {
  value: number | string;
  className?: string;
  styles?: React.CSSProperties;
}

const Badge: React.FC<BadgeProps> = ({ value, className = "", styles = {} }) => {
  return (
    <span className={classNames('badge', className)} style={styles}>
      {value}
    </span>
  );
};

export default Badge;
