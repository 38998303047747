import { FILTER_MODE_INTERSECTION } from "../advancedSearch/components/toolbarFilterQueries/FilterQueryTab";

export const ADVANCED_SEARCH_EXAMPLES = [
    {
        // id is mandatory, it will be used as key,
        // since there can be multiple sections
        id: "concepts",
        label: "Concepts",
        description: 'Search by concept identifiers like OCID or domain/name.',
        examples: [{
            label: 'chem:"aspirin"',
            description: 'Search "aspirin" by domain and name.',
            queryString: 'chem:"aspirin"',
        }, {
            label: 'ocid:"210000004185"',
            description: 'Search "liver" from the domain "Anatomy (Viral and Animal)" by its OCID.',
            queryString: 'ocid:"210000004185"',
        }, {
            label: '+inchi:"InChI=1S/C6H6/c1-2-4-6-5-3-1/h1-6H" +inchikey:"MVPPADPHJFYWMZ-UHFFFAOYSA-N"',
            description: 'Search chemical compounds by InChI or InChI key, search for "benzene" and "chlorobenzene".',
            queryString: '+inchi:"InChI=1S/C6H6/c1-2-4-6-5-3-1/h1-6H" +inchikey:"MVPPADPHJFYWMZ-UHFFFAOYSA-N"',
        }]
    },
    {
        id: "text",
        label: "Text",
        examples: [{
            label: 't:"amorph"',
            description: 'Will find variants (stemming). Matches "amorph","amorphous", and "amorphization" etc.',
            queryString: 't:"amorph"',
        }, {
            label: 'tr:"amorph"',
            description: 'Will find the exact term, only case-insensitive. Matches "amorph" or "Amorph".',
            queryString: 'tr:"amorph"',
        }]
    },
    {
        id: "mode",
        label: "Search mode",
        examples: [{
            label: 'smode:"s" chem:"monoterpenes"',
            description: 'Global search mode, "concept only, with synonyms". Will find "monoterpenes" and all synonyms of this concept, but no ontological subclasses.',
            queryString: 'smode:"s" chem:"monoterpenes"',
        }, {
            label: '+cmode:"o" +chem:"steroids" +cmode:"s" +diseases:"inflammation"',
            description: 'Different local search modes. Will find "steroids", its synonyms and all subclasses together with "inflammation" and all of its synonyms, but no subclasses',
            queryString: '+cmode:"o" +chem:"steroids" +cmode:"s" +diseases:"inflammation"',
        }]
    },
    {
        id: "termloc",
        label: "Search in (document parts)",
        examples: [{
            label: '+(termloc:"title" t:"thrombosis" termloc:"ta" t:"embolism") -(t:"pulmonary hypertension" t:"catheter")',
            description: 'Finds all documents which have "thrombosis" mentioned in the title or "embolism" mentioned in the title or abstract but don\'t have "pulmonary hypertension" or "catheter mentioned" in the whole document.',
            queryString: '+(termloc:"title" t:"thrombosis" termloc:"ta" t:"embolism") -(t:"pulmonary hypertension" t:"catheter")',
        }]
    },
    {
        id: "distance",
        label: "Distance",
        examples: [/*{
            label: 'near:"filter"^10 (+chem:"monoterpenes" +methods:"synthesis" +methods:"extraction")',
            description: 'Concept distance. Matches "... carveol ... chemical synthesis ... extraction ..." with a distance of 70 characters between 2 of these 3 concepts in all combinations.',
            queryString: 'near:"filter"^10 (+chem:"monoterpenes" +methods:"synthesis" +methods:"extraction")',
        },*/ {
                label: '+(nearby:"20"^2 +chem:"monoterpenes" +diseases:"inflammation") +region:"canada"',
                description: 'Concept distance. Will find "monoterpenes" and "inflammation" within a distance of max. 20 words in between and increase the importance of the nearby group.',
                queryString: '+(nearby:"20"^2 +chem:"monoterpenes" +diseases:"inflammation") +region:"canada"',
            }, {
                label: 'tr:"progressive fibrosis"~5',
                description: 'Text distance. Will find e.g. "progressive fibrosis", "pregressive renal fibrosis", "progressive renal and pulmonary fibrosis", "fibrosis being progressive".',
                queryString: 'tr:"progressive fibrosis"~5',
            }]
    },
    {
        id: "logic",
        label: "Logical operators",
        description: 'SHOULD, MUST and NOT.',
        examples: [{
            label: 'diseases:"pain" diseases:"inflammation"',
            description: 'One of the concepts should occur. Finds either pain or inflammation.',
            queryString: 'diseases:"pain" diseases:"inflammation"',
        }, {
            label: '+chem:"aspirin" +diseases:"headache"',
            description: 'All concepts must occur.',
            queryString: '+chem:"aspirin" +diseases:"headache"',
        }, {
            label: '+diseases:"cancer" -diseases:"breast cancer"',
            description: 'Concept must not occur. Finds all cancers, but not breast cancers.',
            queryString: '+diseases:"cancer" -diseases:"breast cancer"',
        }]
    },
    /*{
        id: "complex",
        label: "Complex queries",
        examples: [{
            label: '(termloc:"ta" +(tr:"Drug Delivery System*"~3 tr:"MiniMed" [...]',
            description: 'Will find Insulin delivery devices.',
            queryString: '(termloc:"ta" +(tr:"Drug Delivery System*"~3 tr:"MiniMed" tr:"MiniMedTM") +(ocid:"190000020213" ocid:"229940069975" ocid:"229940123452" ocid:"239000012582" t:"regular insulin")) (termloc:"title" +(ocid:"190000020213" ocid:"229940069975" ocid:"229940123452" ocid:"239000012582" t:"regular insulin") +(ocid:"237300009121" tr:"delivery device*"~1 tr:"delivery system*"~1 tr:"delivery option*"~1 tr:"delivery method*"~1 t:"pen" ocid:"237300006357" t:"syringe" ocid:"237300011244" t:"pump" ocid:"237300007918" ocid:"231200001845" ocid:"229940090044" ocid:"238000002347" ocid:"238000010999" ocid:"239000007924" tr:"automated insulin delivery"~1 ocid:"237300013006")) (termloc:"title" ocid:"237300011634" tr:"MiniMed" tr:"MiniMedTM" ocid:"237300013098") (termloc:"ta" t:"Insulin infusion sets"~1 t:"Insulin infusion device"~1 t:"insulin delivery system"~1) +pdate:"2022-04-01_2022-09-01"',
        }]
    },*/
    {
        id: "filters",
        label: "Filter queries",
        examples: [{
            label: 'chem:"insulin" [...]',
            description: 'Find "insulin" within a set of five patents.',
            queryString: 'chem:"insulin"',
            filterQueries: [{
                key: 'qry_1',
                query: 'pdid:"US-20230064214-A1" pdid:"WO-2022164738-A1" pdid:"US-20220126017-A1" pdid:"US-20220134017-A1" pdid:"WO-2022139525-A1"',
                filterMode: FILTER_MODE_INTERSECTION
            }]
        }]
    }
]


