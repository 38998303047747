/* eslint-disable jsx-a11y/anchor-is-valid */
import BiomarkerLabel from "./BiomarkerLabel/BiomarkerLabel";
import CheckboxWithLabel from "../../../CheckboxWithLabel/CheckboxWithLabel";
import styles from './BiomarkerSearchResultItem.module.scss';
import { addOrRemove } from "../../../../../common/helpers/addOrRemove";
/*import {
    createDocViewUrl //, isExternalURL 
} from "../../../../util";*/
import { useDispatch, useSelector } from "react-redux";
import { setBiomarkerCheckedResults, setBiomarkerQueryTerms } from "../../../../../../redux/actions/BiomarkerActions";
import BiomarkerDocumentMetadataInfo from "./BiomarkerDocumentMetadataInfo/BiomarkerDocumentMetadataInfo";
import { biomarkerCheckedResults, biomarkerFirstResultIndex } from "../../../../../../redux/selectors/selectors";
import { CLINICAL_TRIAL_REPOS } from '../../../../../../properties';
import ExternalLinkIcon from "../../../../general/ExternalLinkIcon/ExternalLinkIcon";
// import MetadataTitle from "./MetadataTitle";

const BiomarkerSearchResultItem = ({ labelList, isChecked, data, showToast, repositoryInfo, documentData, queryTerms, onShowDocviewModal }) => {
    const dispatch = useDispatch();
    const checkedResults = useSelector(biomarkerCheckedResults);
    const firstResultIndex = useSelector(biomarkerFirstResultIndex);

    const searchLabelList = labelList.map(label => <BiomarkerLabel key={label.name + label.terms}
        name={label.label}
        terms={label.terms}
        ocidPrefNames={label.ocidPrefNames}
        ocids={label.ocids}
        showToast={showToast}
        fieldName={label.fieldName}
        setBiomarkerQueryTerms={setBiomarkerQueryTerms}
        queryTerms={queryTerms}

    />)
    const globalOrderNumber = ((firstResultIndex + 1) + data.searchIndex).toString();

    const checkItem = () => {
        dispatch(setBiomarkerCheckedResults(addOrRemove(checkedResults, data.relationId)));
    };

    //console.log(data?.fields)
    const docId = data?.fields?.doc_id?.storedData;
    const text = data?.fields?.srcsent?.storedData;
    // const isMetadata = Object.keys(documentData).length;

    const fallbackText = data?.fields?.text?.storedData;
    const date = data?.fields?.source_date?.storedData;
    // const source = data?.fields?.srcrep?.terms[0];
    const sourceId = data?.fields?.source_id?.storedData;
    const externalLink = data?.fields?.source_link?.storedData;
    const clinicalLink = `https://clinicaltrials.gov/ct2/show/${sourceId}`
    // const isExternalLink = isExternalURL(externalLink);
    //const query = data?.fields?.srcsent;
    //const url = createDocViewUrl(documentData.repositoryId, docId, query);
    const repoName = data.fields?.srcrep.terms[0];
    const repoLabel = data.fields?.srcrep.terms[1];

    //console.log(documentData)

    return (
        <div className={styles.result}>
            <div className={styles.left}>
                <CheckboxWithLabel checked={isChecked} handleCheckBox={checkItem} inputId={globalOrderNumber}
                    labelText={globalOrderNumber} />
            </div>
            <div>
                <div className={styles.header}>
                    {searchLabelList}
                </div>

                <div>
                    <p className={styles.text}>{text || fallbackText}</p>
                    <div className={styles.info}>
                        {/*Title is added in case patents repo or like fallback if no document metadata*/}
                        {/*{source === 'patents' || !isMetadata ?*/}
                        {/*    <MetadataTitle url={url} documentData={documentData} externalLink={externalLink}*/}
                        {/*                   isExternalLink={isExternalLink} sourceId={sourceId}/> :*/}
                        {!!docId && <a className={styles.title} onClick={() => {
                            onShowDocviewModal(documentData.repositoryId, docId, documentData)
                        }} target='_blank'>{documentData?.title}</a>}

                        <BiomarkerDocumentMetadataInfo documentData={documentData}
                            repositoryInfo={repositoryInfo} />

                        <p className={styles.resultFooter}>
                            <span>Publication date: {date}</span>
                            <span>Repository: {repoLabel}</span>
                            {repoName !== 'dspub' &&
                                <span>{`Source link: `}
                                    <a className="secondaryLink" style={{ color: 'black' }}
                                        href={CLINICAL_TRIAL_REPOS[repoName] ? clinicalLink : externalLink}
                                        target='_blank' rel="noreferrer">{sourceId}
                                        <ExternalLinkIcon standardLinkIcon={true} />
                                    </a>
                                </span>
                            }
                        </p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default BiomarkerSearchResultItem;
