import { VERSION_2_0 } from "../../../../properties";
import { isArrayEmpty } from "../../util";

export const KEY_PREFIX = "qry_";

export const isEmptySearch = (advancedQueries) => {
    return !(advancedQueries && advancedQueries[0]?.query);
}

export const addKeysToAdvancedQueries = (advancedQueries, currCounter = 0) => {
    let counter = currCounter;
    advancedQueries?.forEach(aq => aq.key = `${KEY_PREFIX}${counter++}`);
    return counter;
}

export const addKeyToAdvancedQuery = (advancedQuery, currCounter = 0) => {
    let counter = currCounter;
    advancedQuery.key = `${KEY_PREFIX}${counter++}`;
    return counter;
}

export const removeKeysFromAdvancedQueries = (advancedQueries) => {
    return advancedQueries?.map(aq => {
        const { key, ...rest } = aq;
        return rest;
    });
}

export const removeHtmlFromAdvancedQueries = (advancedQueries) => {
    return advancedQueries?.map(aq => {
        const { htmlEditorContent, ...rest } = aq;
        return rest;
    });
}

export const createFormContentFromAdvancedQueries = (advancedQueries) => {
    const advancedQueriesNew = removeKeysFromAdvancedQueries([...advancedQueries]);

    return !isEmptySearch(advancedQueries) ? {
        queries: advancedQueriesNew,
        version: VERSION_2_0
    } : null;
}

export const createRequestFromAdvancedQueries = (advancedQueries, allowGroupByPatentFamily, patentFamilyGrouping) => {
    let advancedQueriesNew = removeKeysFromAdvancedQueries(advancedQueries);
    advancedQueriesNew = removeHtmlFromAdvancedQueries(advancedQueriesNew);
    let query = getMainQueryStringFromAdvancedQueries(advancedQueriesNew);
    query = addPatentFamilyGroupingToQueryString(query, allowGroupByPatentFamily, patentFamilyGrouping);
    const filterQueries = getFilterQueriesFromAdvancedQueries(advancedQueriesNew);

    const request = { query: query };
    if (!isArrayEmpty(filterQueries)) {
        request.filterQueries = filterQueries;
    }
    return request;
}

export const createRequestFromAdvancedQueriesV2 = (query, filterQueries, allowGroupByPatentFamily, patentFamilyGrouping) => {
    const queryNew = addPatentFamilyGroupingToQueryString(query, allowGroupByPatentFamily, patentFamilyGrouping);
    const request = { query: queryNew };
    if (!isArrayEmpty(filterQueries)) {
        request.filterQueries = filterQueries;
    }
    return request;
}

/*export const convertFormContentToString = (formContent) => {
    return formContent ? JSON.stringify(formContent) : "";
}*/

export const getFilterQueriesFromAdvancedQueries = (advancedQueries) => {
    const advancedQueriesNew = removeKeysFromAdvancedQueries(advancedQueries);
    return advancedQueriesNew?.slice(1).filter(advQry => !!advQry.query);
}

/*export const getMainQueryFromAdvancedQueries = (advancedQueries) => {
    const advancedQueriesNew = removeKeysFromAdvancedQueries(advancedQueries);
    return !isArrayEmpty(advancedQueriesNew) ? advancedQueriesNew[0] : null;
}*/

export const getMainQueryStringFromAdvancedQueries = (advancedQueries) => {
    return !isArrayEmpty(advancedQueries) && advancedQueries[0].query ? advancedQueries[0].query : "";
}

export const addPatentFamilyGroupingToQueryString = (queryString, allowGroupByPatentFamily, patentFamilyGrouping) => {
    const patFamGrouping = allowGroupByPatentFamily && patentFamilyGrouping ? patentFamilyGrouping : null;
    let qryString = queryString;
    if (patFamGrouping?.filterValue !== 'none' && patFamGrouping?.filterValue && patFamGrouping?.booster) {
        qryString = `+(${qryString}) +groupby:"${patFamGrouping.filterValue}"^${patFamGrouping.booster}`;
    }
    return qryString;
}