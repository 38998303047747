import React, { useMemo } from "react";
import {
  LIST_CUTOFF_FILTER_TERMS,
  LOGICAL_OPERATORS_AND_OR,
} from "../../../../../helpers/constants";
import FilterGroupCompact from "../../../../../../../common/queryBuilder/searchForm/searchGroup/compactMode/groupTypes/FilterGroupCompact/FilterGroupCompact";

const FilterGroupCompactContainer = ({ group }) => {
  const selectedOperator = LOGICAL_OPERATORS_AND_OR.find(
    ({ value }) => value === group.operator
  );
  const queryTerms = useMemo(
    () => group?.queryTerms ?? [],
    [group?.queryTerms]
  );

  return (
    <FilterGroupCompact
      filterTermsListMax={LIST_CUTOFF_FILTER_TERMS}
      operator={selectedOperator}
      queryTerms={queryTerms}
    />
  );
};

export default FilterGroupCompactContainer;
