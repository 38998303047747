import { PureComponent } from 'react';
import { isArrayEmpty } from '../../util';
import { SelectButton } from 'primereact/selectbutton';


class HighlightButtons extends PureComponent {

    render() {
        const { highlightOptions, highlightValues, changeHighlighting } = this.props;

        return (
            <>
                {!isArrayEmpty(highlightOptions) ?
                    <>
                        <label htmlFor="highlight" className="valignMiddle">Highlight:</label>
                        <SelectButton
                            id="highlight"
                            className="primaryButtonSet buttonSmall valignMiddle"
                            style={{ marginLeft: 10 }}
                            value={highlightValues}
                            multiple={true}
                            options={highlightOptions}
                            onChange={(e) => changeHighlighting(e)} />

                    </> : null}

            </>
        );
    }
}

export default HighlightButtons;