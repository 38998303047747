/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
//import {InputTextarea} from "primereact/inputtextarea";
import {hasUserRole} from "../../util";
import {Checkbox} from "primereact/checkbox";

export const SaveDialog = ({isSaveDialogVisible, onHideSaveDialog}) => {
    const [fullName, setFullName] = useState('');
    const [shortName, setShortName] = useState('');

    const onSaveQuery = () => {
        console.log('SAVED');
    };

    const onFullNameChange = (e) => {
        setFullName(e.value);
    };

    const onShortNameChange = (e) => {
        setShortName(e.value);
    };

    const footer = (
        <div>
            <label style={{float: 'left', paddingTop: 10}}>* required</label>
            <Button label="Save"
                    className='p-button-sm primaryButton'
                    onClick={onSaveQuery}
                    disabled={!!(fullName && shortName)}
                    style={{float: 'right', marginRight: -5}}
            />
            <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={onHideSaveDialog}
                    style={{float: 'right', marginRight: 10}}
            />
        </div>
    )

    return (

        <Dialog visible={isSaveDialogVisible} dismissableMask onHide={onHideSaveDialog} header="Save search"
                footer={footer}>
            <div className="grid p-fluid" style={{marginTop: 10, borderBottom: '1px solid #d6d6d6'}}>
                <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 grid p-fluid'
                     style={{marginRight: 20}}>
                    <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{padding: '.75em'}}><label
                        style={{verticalAlign: 'sub'}} htmlFor="fullname">Name *</label>
                    </div>

                    <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{padding: '.5em'}}>
                        <InputText id="fullname"
                                   onChange={onFullNameChange} value={fullName}/>
                    </div>

                    <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{padding: '.75em'}}><label
                        style={{verticalAlign: 'sub'}} htmlFor="name">Short name *</label>
                    </div>

                    <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{padding: '.5em'}}>
                        <InputText style={{marginBottom: 5}}
                            //keyfilter='alphanum'
                                   id="name" onChange={onShortNameChange} value={shortName}/>
                        <label style={{color: 'darkgray'}}>Used in compact views, max. 20 characters</label>
                    </div>

                    <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{padding: '.75em'}}><label
                        style={{verticalAlign: 'sub'}} htmlFor="description">Description</label>
                    </div>
                    {/*<div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{padding: '.5em'}}>*/}
                    {/*    <InputTextarea rows={2} cols={30} onChange={(e) => {*/}
                    {/*        this.updateSimpleQueryDescription(e.target.value)*/}
                    {/*    }} value={this.state.selectedSimpleQueryDescription}/>*/}
                    {/*</div>*/}
                    {/*<div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{padding: '.75em'}}><label*/}
                    {/*    style={{verticalAlign: 'sub'}} htmlFor="query">Search</label></div>*/}
                    {/*<div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{padding: '.5em'}}>*/}
                    {/*    <InputTextarea rows={5} cols={30} readOnly={true}*/}
                    {/*                   value={this.state.selectedSimpleQuery}/>*/}
                    {/*</div>*/}
                    {hasUserRole("ROLE_ALERTS") &&
                    <React.Fragment>
                        <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3"
                             style={{padding: '.75em', paddingTop: 1}}><label style={{verticalAlign: 'sub'}}
                                                                              htmlFor="query">Alert</label>
                        </div>
                        <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9"
                             style={{padding: '.5em', paddingTop: 0}}>
                            <Checkbox
                                onChange={(e) => this.onCheckGetAlerts(e)}
                                // checked={this.state.addAlert}
                                //disabled={this.props.selectedUserSAML ? true : false}
                                value='Get email updates on new results'
                                style={{'cursor': 'pointer', marginTop: 4}}
                            />
                            <label className='p-checkbox-label'>Get email updates on new results</label>
                        </div>
                        <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3"
                             style={{padding: '.75em', paddingTop: 1}}></div>
                        {/*{this.state.addAlert ?*/}
                        {/*    <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9 grid p-fluid"*/}
                        {/*         style={{padding: '.5em', paddingTop: 0}}>*/}
                        {/*        <div className='col-12 sm:col-5 md:col-5 lg:col-4 xl:col-4'*/}
                        {/*             style={{paddingLeft: 0, paddingBottom: 0}}>*/}
                        {/*            <Dropdown*/}
                        {/*                appendTo={document.body}*/}
                        {/*                className='dropdownNoBorder'*/}
                        {/*                value={this.state.activeAlert}*/}
                        {/*                options={activeAlertOptions}*/}
                        {/*                onChange={(e) => {*/}
                        {/*                    this.setState({activeAlert: e.value})*/}
                        {/*                }}*/}
                        {/*                style={{ //width: 75, minWidth: 75,*/}
                        {/*                    marginTop: -1, marginBottom: -6*/}
                        {/*                }}*/}
                        {/*                showClear={false}*/}
                        {/*                placeholder='Alert status'/>*/}
                        {/*        </div>*/}
                        {/*        <div className='col-12 sm:col-7 md:col-7 lg:col-8 xl:col-8'*/}
                        {/*             style={{paddingBottom: 0}}>*/}
                        {/*            <label style={{float: 'left', marginTop: 6}}>Interval:</label>*/}
                        {/*            <Dropdown*/}
                        {/*                appendTo={document.body}*/}
                        {/*                className='dropdownNoBorder'*/}
                        {/*                value={this.state.interval}*/}
                        {/*                options={this.state.queryIntervals}*/}
                        {/*                onChange={(e) => {*/}
                        {/*                    this.setState({interval: e.value})*/}
                        {/*                }}*/}
                        {/*                style={{ //width: 90, minWidth: 90,*/}
                        {/*                    marginTop: -1*/}
                        {/*                }}*/}
                        {/*                showClear={false}*/}
                        {/*                placeholder='Add alert'/>*/}
                        {/*        </div>*/}
                        {/*        <div className='col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12'*/}
                        {/*             style={{paddingTop: 0}}>*/}
                        {/*            <label style={{float: 'left', marginTop: 6}}>From repositories:</label>*/}
                        {/*            <MultiSelect*/}
                        {/*                value={this.state.queryRepositories}*/}
                        {/*                options={selectedRepositories}*/}
                        {/*                onChange={(e) => this.setState({queryRepositories: e.value})}*/}
                        {/*                appendTo={document.body}*/}
                        {/*                placeholder="Select repositories"*/}
                        {/*                style={{ //width: 150, minWidth: 150,*/}
                        {/*                    border: 'none', marginTop: 1*/}
                        {/*                }}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    : null*/}
                        {/*}*/}
                    </React.Fragment>
                    }
                </div>

                <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 grid p-fluid'>
                    <div className="col-12" style={{padding: '.75em', paddingLeft: 10}}>
                        <label style={{verticalAlign: 'sub'}}>Add to search collections - can be shown on the
                            dashboard to easily keep track of new results
                        </label>
                        <div>
                            <a onClick={(e) => this.addNewWatchlist(e)}
                               style={{display: 'inline-block', marginTop: 10}}>Create new collection</a>
                        </div>
                        <div>
                            {/*{this.state.allWatchlists && this.state.allWatchlists.length > 0 ?*/}
                            {/*    <div //className="datatable-responsive-demo"*/}
                            {/*    >*/}
                            {/*        {headerWatchlists}*/}
                            {/*        <DataTable ref={(el) => this.dt = el}*/}
                            {/*                   scrollable*/}
                            {/*                   scrollHeight='190px'*/}
                            {/*                   selection={this.state.selectedWatchlists}*/}
                            {/*                   removableSort={true}*/}
                            {/*                   onSelectionChange={e => this.handleSelectionChange(e)}*/}
                            {/*            //className="dataTableQueries checkboxCircle standardTableHeader"*/}
                            {/*            //className="p-datatable-responsive-demo"*/}
                            {/*                   value={this.state.allWatchlists}*/}
                            {/*                   responsive={true}*/}
                            {/*                   paginator={false}*/}
                            {/*                   rows={10}*/}
                            {/*                   globalFilter={this.state.globalFilterWatchlists}>*/}
                            {/*            {columnData}*/}
                            {/*        </DataTable>*/}
                            {/*    </div>*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
