import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { useEffect, useMemo, useRef, useState } from "react";
import BulkImportIDResultsTable from "./BulkImportIDResultsTable";
import { DOC_ID_SEARCH_MAP } from "../../helpers/constants";
import useMatchingDocumentRepositoriesData from "../../hooks/matching-document-repositories-data";
import { isDataLoaded, runDocumentMatchingForAllRepos, runSimilarDocumentMatchingForAllRepos } from "../../helpers/search";
import { analyzeResults, convertResults, hasExactMatch, hasSimilarMatch } from "../../helpers/results";
import { documentSelectionByOption, documentSelectionByToggle } from "../../helpers/selections";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import InfoOverlayWithIcon from "../../../general/InfoOverlayWithIcon";
import { isArrayEmpty, isObjectEmpty } from "../../../util";
import { Dialog } from "primereact/dialog";
import "./BulkImportIDCheck.css";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";


const BulkImportIDCheck = ({
    type = '',
    values = [],
    selectedDocuments = {},
    onSelectionChange
}) => {

    const toast = useRef(null);

    const searchData = DOC_ID_SEARCH_MAP[type];
    const allRepositoriesData = useMatchingDocumentRepositoriesData(searchData?.repos) || [];

    const [loading, setLoading] = useState();
    const [message, setMessage] = useState('');

    const [resultsExact, setResultsExact] = useState({});
    const [resultsSimilar, setResultsSimilar] = useState({});
    const [displayDuplicatesDialog, setDisplayDuplicatesDialog] = useState();

    const [showExactMatches, setShowExactMatches] = useState(true);
    const [showSimilarMatches, setShowSimilarMatches] = useState(true);
    const [showNoneMatches, setShowNoneMatches] = useState(true);

    const [includeSimilarDocuments, setIncludeSimilarDocuments] = useState();
    const [hideSelected, setHideSelected] = useState(false);
    const [sortOption, setSortOption] = useState(searchData?.sortingOptions && searchData?.sortingOptions[0]?.value);
    const [repositoriesData, setRepositoriesData] = useState([...allRepositoriesData]);
    const [showResults, setShowResults] = useState();


    useEffect(() => {
        handleClearAll();
        fetchMatchingDocumentsForAllRepos();
    }, [type, values, sortOption]);


    const fetchMatchingDocumentsForAllRepos = async () => {
        if (!isDataLoaded(searchData, values, repositoriesData)) {
            // @todo: not working if value type is mdid (external IDs) and await function (runDocumentMatchingForAllRepos) is called 
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error occured', detail: 'Some data is missing.', life: 6000 });
            }
            return;
        }

        setLoading(true);
        setShowResults(false);
        onSelectionChange({});

        setMessage('Searching for matching documents');
        const resultsMap = await runDocumentMatchingForAllRepos(values, repositoriesData, searchData, sortOption);
        setResultsExact(resultsMap);

        setShowResults(true);
        setLoading(false);
    }


    const fetchSimilarDocumentsForAllRepos = async () => {
        if (!isDataLoaded(searchData, values, repositoriesData)) {
            toast.current.show({ severity: 'error', summary: 'Error occured', detail: 'Some data is missing.', life: 6000 });
            return;
        }

        setLoading(true);

        setMessage('Searching for similar documents');
        const resultsMap = await runSimilarDocumentMatchingForAllRepos(resultsExact, values, repositoriesData, searchData, sortOption);
        setResultsSimilar(resultsMap);

        setLoading(false);
    }


    const handleIncludeSimilarDocumentsChange = (isActive) => {
        setIncludeSimilarDocuments(isActive);
        if (isActive) {
            fetchSimilarDocumentsForAllRepos();
        }
        else {
            setResultsSimilar({});
        }
    }


    const handleClearAll = () => {
        setShowResults(false);
        setResultsExact({});
        setResultsSimilar({});
        onSelectionChange({});
        setIncludeSimilarDocuments(false);
        setHideSelected(false);
    }


    const handleDocumentSelectionChange = (repositoryID, documentID, metadataDocumentID, document, isExact) => {
        const selectedDocumentsUpdated = documentSelectionByToggle(repositoryID, documentID, metadataDocumentID, document, isExact, selectedDocuments);
        onSelectionChange(selectedDocumentsUpdated);
    }


    const handleDocumentSelectionOptionChange = (selectionOption, repositoryID, documentIDMetadataProp) => {
        const selectedDocumentsUpdated = documentSelectionByOption(selectionOption, repositoryID, documentIDMetadataProp, selectedDocuments, resultsExact, resultsSimilar)
        onSelectionChange(selectedDocumentsUpdated);
    }


    // @todo: move down???
    const results = useMemo(
        () => convertResults(values, resultsExact, resultsSimilar),
        [values, resultsExact, resultsSimilar]
    );
    const { matchingDocumentCount, similarDocumentCount, noDocumentCount,
        uniqueDocuments, duplicateDocuments, duplicateDocumentTuples } = useMemo(
            () => analyzeResults(results, repositoriesData),
            [results]
        );

    // console.log('UNIQUE: ', uniqueDocuments);
    // Object.entries(uniqueDocuments).forEach(([repoID, docIDs]) => {
    //     console.log(`${repoID} =>`, Object.keys(docIDs).length);
    // });

    const resultsFiltered = results.filter(res => {
        return ((showExactMatches && hasExactMatch(res?.results)) ||
            (showSimilarMatches && hasSimilarMatch(res?.results)) ||
            (showNoneMatches && !res?.results));
    });


    // if (!isDataLoaded(searchData, values, repositoriesData)) {
    //     return null;
    // }

    return <>
        <Toast ref={toast} />

        <div className="grid">

            <div className="col-12">
                <div className="flex-space-between-center" style={{ padding: 0 }}>
                    <div className="inline-flex-center">
                        {/* <label htmlFor="repos" style={{ marginRight: 10 }}>Check repositories:</label>
                        <MultiSelect
                            id='repos'
                            appendTo={document.body}
                            value={repositoriesData}
                            options={allRepositoriesData}
                            onChange={(e) => {
                                handleClearAll();
                                setRepositoriesData(e.value || []);
                            }}
                            style={{ marginRight: 35 }} /> */}
                        <label htmlFor="sortOption" style={{ marginRight: 10 }}>Match document with:</label>
                        <Dropdown
                            id='sortOption'
                            options={searchData?.sortingOptions}
                            value={sortOption}
                            onChange={(e) => {
                                // handleClearAll();
                                setSortOption(e.value);
                            }}
                            style={{ marginRight: 35 }}
                        />
                        {/* <Button
                            label="Find documents"
                            className='primaryButton p-button-sm'
                            onClick={(e) => {
                                handleClearAll();
                                fetchMatchingDocumentsForAllRepos(e);
                                setShowResults(true);
                            }}
                            style={{ width: 'auto' }} /> */}
                    </div>
                </div>
                <hr style={{ marginBottom: 0 }} />
            </div>

            {loading ?
                <div className="col-12">
                    <LoadingOverlay
                        active={loading}
                        spinner={true}
                        text={message}
                        className="height100perc">
                    </LoadingOverlay>
                </div>
                :
                showResults &&
                <>
                    <div className="col-12">
                        <div className="flex-space-between-center" style={{ padding: 0 }}>
                            <div className="inline-flex-center">
                                <div className="inline-flex-center" style={{ marginRight: 7, fontWeight: 'bold' }}>IDs with:</div>
                                <div className="inline-flex-center document-matches-info-box documents-bg-color exact">
                                    <Checkbox
                                        onChange={(e) => setShowExactMatches(e.checked)}
                                        checked={showExactMatches}
                                        style={{ marginRight: 10 }}
                                    ></Checkbox>
                                    <span>Exact match: {matchingDocumentCount}</span>
                                </div>
                                {includeSimilarDocuments && similarDocumentCount >= 0 &&
                                    <div className="inline-flex-center document-matches-info-box documents-bg-color similar">
                                        <Checkbox
                                            onChange={(e) => setShowSimilarMatches(e.checked)}
                                            checked={showSimilarMatches}
                                            style={{ marginRight: 10 }}
                                        ></Checkbox>
                                        <span>Similar match only: {similarDocumentCount}</span>
                                    </div>}
                                <div className="inline-flex-center document-matches-info-box documents-bg-color none">
                                    <Checkbox
                                        onChange={(e) => setShowNoneMatches(e.checked)}
                                        checked={showNoneMatches}
                                        style={{ marginRight: 10 }}
                                    ></Checkbox>
                                    <span>No matches: {noDocumentCount}</span>
                                </div>
                                <div className="document-matches-info-box original">Total: {results?.length || 0}</div>
                                {!isArrayEmpty(duplicateDocumentTuples) &&
                                    <a className="document-matches-info-box primaryLink"
                                        title="Click to see duplicates"
                                        onClick={() => setDisplayDuplicatesDialog(true)}>Show duplicates</a>}
                            </div>

                            <div className="inline-flex-center">
                                {searchData?.allowSimilarSearch &&
                                    <>
                                        <Checkbox
                                            id="inclSimilar"
                                            onChange={(e) => handleIncludeSimilarDocumentsChange(e.checked)}
                                            checked={includeSimilarDocuments}
                                            style={{ marginRight: 10 }}
                                        ></Checkbox>
                                        <label htmlFor="inclSimilar" style={{ marginRight: 10 }}>Include similar documents</label>
                                        <span style={{ marginRight: 35 }}>
                                            <InfoOverlayWithIcon
                                                infoID="inclSimilarInfo"
                                                title="Click for information about search for similar documents"
                                                imageAltText="Similar document search information"
                                                overlayContent={<span>{searchData.inclSimilarInfoText} Results in more, but less exact hits.</span>}
                                            />
                                        </span>
                                    </>}
                                <Checkbox
                                    id="hideSelected"
                                    onChange={(e) => setHideSelected(e.checked)}
                                    checked={hideSelected}
                                    style={{ /*position: 'relative', top: 1,*/ marginRight: 10 }}
                                ></Checkbox>
                                <label htmlFor="hideSelected" style={{ marginRight: 10 }}>Hide completed rows</label>
                                <span style={{ marginRight: 35 }}>
                                    <InfoOverlayWithIcon
                                        infoID="hideSelectedInfo"
                                        title="Click for information about hiding completed rows"
                                        imageAltText="Hide completed rows information"
                                        overlayContent={<span>Hide table rows if one of the suggested documents is selected.</span>}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <BulkImportIDResultsTable
                            documents={resultsFiltered}
                            duplicateDocuments={duplicateDocuments}
                            // repositories={repositoriesData}
                            repositories={repositoriesData}
                            selectedDocuments={selectedDocuments}
                            onSelectionChange={handleDocumentSelectionChange}
                            onSelectionOptionChange={handleDocumentSelectionOptionChange}
                            documentIDMetadataProp={searchData?.metadataDocID}
                            includeSimilarDocuments={includeSimilarDocuments}
                            hideSelected={hideSelected}
                        />
                    </div>
                </>}
        </div>

        <Dialog
            visible={displayDuplicatesDialog}
            header="Duplicates"
            focusOnShow={false}
            modal={true}
            dismissableMask={true}
            onHide={() => setDisplayDuplicatesDialog(false)}
        >
            {!isArrayEmpty(duplicateDocumentTuples) &&
                <>
                    <div style={{ padding: '10px 10px 20px 10px' }}>
                        <strong>Some input IDs are matching the same document within a repository.</strong>
                    </div>
                    {duplicateDocumentTuples.map(entry => {
                        // console.log('entry', entry);
                        return <div key={entry.repoID} style={{ padding: '10px 10px 0px 10px' }}>
                            <h3>{entry.repoLabel}:</h3>
                            <p>Number of unique matching documents: <strong>{Object.values(uniqueDocuments[entry.repoID]).length}</strong></p>
                            <p>Each group of these input IDs matches the same document:</p>
                            {Object.entries(entry.origDocIDs).map(([key, origDocIDs]) => {
                                return <ul key={key} style={{ lineHeight: 1.5, marginTop: 20, marginBottom: 10 }}>
                                    {origDocIDs.map(origID => {
                                        return <li key={origID}>{origID}</li>
                                    })}
                                </ul>
                            })}
                        </div>
                    })}
                </>}
        </Dialog>
    </>
}

export default BulkImportIDCheck;