import { sortObjectArrayByAttribute, removeValueFromArray, isArrayEmpty, getNumberOfObjectsWithValueInArray, cloneDeep } from '../../util';
import * as OntologyClassUtil from './OntologyClassUtil';
import _ from 'lodash';

// --- subtree types --- //
export const SUBTREE_FRAME = 'frame';
export const SUBTREE_ROLE = 'role';
export const SUBTREE_SYNSET = 'synset';
export const SUBTREE_MACRO = 'macro';

// --- empty frame ontology with empty subtrees --- //
export const EMPTY_FRAME_ONTOLOGY = {
    header: '',

    nonTermStanzas: [],
    knownTermStanzas: {},

    frames: [],
    maxOcidFrames: null,

    roles: [],
    maxOcidRoles: null,

    synsets: [],
    maxOcidSynsets: null,
};

// ----------------------------------------------------------------------- //
// --- ontology format conversion ---------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Converts ontology into "frontend" format.
 * @param {*} origFrameOntology 
 * @returns 
 */
export const convertOntologyToFrontendFormat = (origFrameOntology) => {

    if (origFrameOntology) {

        const frameOntology = origFrameOntology;

        // --- convert frames, roles and synsets subtrees to frontend format --- //
        convertSubtreeToFrontendFormat(frameOntology, SUBTREE_FRAME, 'frames', 'maxOcidFrames');
        convertSubtreeToFrontendFormat(frameOntology, SUBTREE_ROLE, 'roles', 'maxOcidRoles');
        convertSubtreeToFrontendFormat(frameOntology, SUBTREE_SYNSET, 'synsets', 'maxOcidSynsets');

        frameOntology.macros = [];
        if (!isArrayEmpty(frameOntology.unknownTermStanzas)) {
            const origMacroClass = frameOntology.unknownTermStanzas[0];
            const macroClass = {
                ...origMacroClass,
                isRoot: true,
                key: origMacroClass.id,
                label: origMacroClass.name,
            }
            frameOntology.macros.push(macroClass);
        }

        return frameOntology;
    }

    return { ...EMPTY_FRAME_ONTOLOGY };
}

/**
 * Converts subtree (frames, roles or synsets) to "frontend" format.
 * @param {*} origFrameOntology 
 * @param {*} origSubtreeType 
 * @param {*} frontendSubtreeType 
 * @param {*} maxOcidType 
 */
const convertSubtreeToFrontendFormat = (origFrameOntology, origSubtreeType, frontendSubtreeType, maxOcidType) => {

    origFrameOntology[frontendSubtreeType] = [];

    if (origFrameOntology.relTypeToTree[origSubtreeType]) {
        // --- add current maximum OCID --- //
        origFrameOntology[maxOcidType] = origFrameOntology.relTypeToTree[origSubtreeType].maximumOcid;

        if (origFrameOntology.relTypeToTree[origSubtreeType].treeRoots) {
            // --- add root node info --- //
            origFrameOntology.relTypeToTree[origSubtreeType].treeRoots.forEach(root => { root.isRoot = true; });
            // --- add nodes as subtree --- //
            origFrameOntology[frontendSubtreeType] = origFrameOntology.relTypeToTree[origSubtreeType].treeRoots;
            // --- add unique keys to each node --- //
            addKeysToNodes(origFrameOntology[frontendSubtreeType]);
            // --- sort nodes by name --- //
            origFrameOntology[frontendSubtreeType] = sortNodesByName(origFrameOntology[frontendSubtreeType], origFrameOntology.knownTermStanzas, true);
            // --- delete original subree --- //
            delete origFrameOntology.relTypeToTree[origSubtreeType].treeRoots;
        }
    }
}

/**
 * Converts ontology into "middleware" format.
 * @param {Object} frameOntology 
 */
export const convertOntologyToMiddlewareFormat = (frameOntology, roleSelectItems) => {

    // --- add relationship tag for each role in each frame --- //
    OntologyClassUtil.addRelationshipTagsToSubtree(frameOntology.frames, frameOntology.knownTermStanzas, roleSelectItems);

    // --- removes attributes only used in frontend from classes --- //
    const attributes = ['key', 'label', 'isRoot']
    removeAttributesFromNodes(frameOntology.frames, attributes);
    removeAttributesFromNodes(frameOntology.roles, attributes);
    removeAttributesFromNodes(frameOntology.synsets, attributes);
    removeAttributesFromNodes(frameOntology.macros, attributes);

    // --- move ontology nodes "middleware style" --- // 
    frameOntology.relTypeToTree = {
        synset: { treeRoots: frameOntology.synsets, maximumOcid: frameOntology.maxOcidSynsets },
        role: { treeRoots: frameOntology.roles, maximumOcid: frameOntology.maxOcidRoles },
        frame: { treeRoots: frameOntology.frames, maximumOcid: frameOntology.maxOcidFrames },
    }
    // --- move macros --- //
    frameOntology.unknownTermStanzas = frameOntology.macros;
    // --- update knownTermStanzasOrder - must contain OCIDs of all knownTermStanzas --- //
    frameOntology.knownTermStanzasOrder = frameOntology.knownTermStanzasOrder ? frameOntology.knownTermStanzasOrder : [];
    const ocidsMap = {};
    // --- remove deleted class OCIDs --- //
    frameOntology.knownTermStanzasOrder.filter(ocid => {
        if (!!frameOntology.knownTermStanzas[ocid]) {
            ocidsMap[ocid] = true;
        }
        return !!frameOntology.knownTermStanzas[ocid]
    });
    // -- add new class OCIDs --- //
    for (var ocid of Object.keys(frameOntology.knownTermStanzas)) {
        if (!ocidsMap[ocid]) {
            frameOntology.knownTermStanzasOrder.push(ocid);
        }
    }

    // --- remove ontology nodes "frontend style" --- //
    delete frameOntology.frames;
    delete frameOntology.maxOcidFrames;

    delete frameOntology.roles;
    delete frameOntology.maxOcidRoles;

    delete frameOntology.synsets;
    delete frameOntology.maxOcidSynsets;

    delete frameOntology.macros;

    return frameOntology;
}

/**
 * Adds unique key to each treenode.
 * @param {*} subtree 
 * @param {*} parentKey 
 * @param {*} check 
 */
export const addKeysToNodes = (subtree, parentKey = null, check = {}) => {

    for (const node of subtree) {
        // --- set unique key; if parentKey is undefined -> domain node --- //
        const key = parentKey ? parentKey + '-' + node.id : node.id;
        node.key = key;

        // --- do the same for all children --- //
        if (node.children && node.children.length > 0)
            addKeysToNodes(node.children, key, check);
    }
}

/**
 * Removes given attributes from nodes in given subtree.
 * @param {*} subtree 
 * @param {*} attributes 
 */
export const removeAttributesFromNodes = (subtree, attributes) => {

    if (subtree && attributes) {
        for (var node of subtree) {
            // --- delete attributes which are only used in frontend --- //
            for (var attr of attributes) {
                delete node[attr];
            }

            // --- do the same for all children --- //
            if (node.children && node.children.length > 0)
                removeAttributesFromNodes(node.children, attributes);
        }
    }
}

// ----------------------------------------------------------------------- //
// --- ontology manipulation --------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Adds a (new) class to a subtree. Stores class data.
 * @param {*} subtree 
 * @param {*} ontClasses 
 * @param {*} newNodeChildren 
 * @param {*} newClass 
 * @param {*} selParentNode 
 * @returns the new node which was placed under selected parent node
 */
export const addClassToSubtree = (subtree, ontClasses, newNodeChildren, newClass, selParentNode) => {

    const parentNodes = getNodesWithID(subtree, selParentNode.id);
    if (parentNodes && newClass) {
        let selOntClass;
        // --- add data of new node --- //
        ontClasses[newClass.id] = newClass;
        // --- add nodes to each occurrence of parent in tree --- //
        for (var parentNode of parentNodes) {
            // --- create new node for each new child node --- //
            const newNode = {
                id: newClass.id,
                key: `${parentNode.key}-${newClass.id}`
            };
            if (!isArrayEmpty(newNodeChildren)) {
                newNode.children = newNodeChildren;
            }

            if (!parentNode.children) {
                parentNode.children = [];
            }
            // --- if class is not yet a child of this parent -> add it --- //
            if (getNumberOfObjectsWithValueInArray(parentNode.children, 'id', newClass.id) === 0) {
                parentNode.children.push(newNode);
                parentNode.children = sortNodesByName(parentNode.children, ontClasses, false);
            }
            // --- store child node of selected parent to return it --- //
            if (parentNode === selParentNode) {
                selOntClass = newNode;
            }
        }
        return selOntClass;
    }
    return null;
}

/*
export const sortFrameOntologyByLabel = (nodes) => {

    for (const index of Object.keys(nodes)) {
        const node = nodes[index];
        // --- check all children --- //
        if (node.children && node.children.length > 0) {
            this.deleteFrame(node.children);
        }
    }
}
*/

/**
 * Deletes a class from a subtree.
 * @param {*} frameOntology 
 * @param {*} relType 
 * @param {*} node 
 */
export const deleteClassFromSubtree = (subtree, ontClasses, node) => { //(frameOntology, relType, node) => {
    deleteNodesFromSubtreeRecursively(subtree, node.id);
    delete ontClasses[node.id];
}

/**
 * Removes all nodes with a given ID from a subtree.
 * @param {*} nodes node array (subtree)
 * @param {*} classID the ID of the nodes to delete
 */
const deleteNodesFromSubtreeRecursively = (subtree, classID) => {

    for (var nd of subtree) {
        if (nd.id === classID) {
            removeValueFromArray(subtree, nd);
        }
        // --- check all children --- //
        else if (nd.children && nd.children.length > 0) {
            deleteNodesFromSubtreeRecursively(nd.children, classID);
        }
    }
}

/**
 * Checks if item with a certain name exists in a list of items.
 * Item with id == ignoreID will be ignored.
 * @param {*} name 
 * @param {*} ignoreID 
 * @param {*} selectItems 
 * @returns 
 */
export const doesNameExistInItemList = (name, ignoreID, selectItems) => {

    if (selectItems && ignoreID && name) {
        for (const item of selectItems) {
            if (item.id !== ignoreID && item.label === name) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Extracts classes from subtree and returns them in an array sorted by label.
 * @param {*} subtree 
 * @param {*} ontClasses 
 * @param {*} inclRoot 
 * @returns 
 */
export const extractClasses = (subtree, ontClasses, inclRoot = true) => {
    const classesMap = extractClassesRecursively(subtree, ontClasses, inclRoot);
    return sortObjectArrayByAttribute(Object.values(classesMap), 'label', true, true);
}
const extractClassesRecursively = (subtree, ontClasses, inclRoot, classesMap = {}) => {
    for (const index of Object.keys(subtree)) {
        const node = subtree[index];
        if (inclRoot || !node.isRoot) {
            const name = ontClasses[node.id] ? ontClasses[node.id].name : node.id;
            const normName = OntologyClassUtil.normalizeClassName(name);
            if (!classesMap[node.id]) {
                classesMap[node.id] = {
                    id: node.id,
                    label: normName,
                    value: normName,
                };
            }
        }
        // --- check all children --- //
        if (node.children && node.children.length > 0) {
            extractClassesRecursively(node.children, ontClasses, inclRoot, classesMap);
        }
    }
    return classesMap;
}

// ----------------------------------------------------------------------- //
// --- find nodes, classes or path keys ---------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Returns ontology class for a given node. Identified by node id.
 * @param {*} frameOntology 
 * @param {*} node 
 * @returns 
 */
export const getClassForNode = (frameOntology, node) => {
    if (node) {
        return getClassWithID(frameOntology, node.id);
    }
    return null;
}

/**
 * Returns ontology class for a given node id.
 * @param {*} frameOntology 
 * @param {*} classID
 * @returns 
 */
export const getClassWithID = (frameOntology, classID) => {
    if (frameOntology && frameOntology.knownTermStanzas) {
        return frameOntology.knownTermStanzas[classID];
    }
    return null;
}

/**
 * Returns class with the given name. Names are normalized before comparison.
 * @param {*} ontClasses 
 * @param {*} className 
 * @returns 
 */
export const getClassWithName = (ontClasses, className) => {
    if (ontClasses && className) {
        const normClassName = OntologyClassUtil.normalizeClassName(className);
        for (var termStanza of Object.values(ontClasses)) {
            const normName = OntologyClassUtil.normalizeClassName(termStanza.name);
            if (normName === normClassName) {
                return termStanza;
            }
        };
    }
    return null;
}

/**
 * Returns first node which was found with given id.
 * @param {*} subtree 
 * @param {*} classID 
 * @returns 
 */
export const getNodeWithID = (subtree, classID) => {
    //console.log('*classID: ', classID);
    const match = {};
    getNodeWithIDRecursively(subtree, classID, match);
    return match.node;
}
const getNodeWithIDRecursively = (subtree, classID, match) => {

    for (const index of Object.keys(subtree)) {
        const node = subtree[index];
        if (node.id == classID) {
            match.node = node;
            return;
        }
        // --- check all children --- //
        else if (node.children && node.children.length > 0) {
            getNodeWithIDRecursively(node.children, classID, match);
        }
    }
}

/**
 * Returns all nodes with a given id.
 * @param {*} subtree 
 * @param {*} classID 
 * @returns 
 */
export const getNodesWithID = (subtree, classID) => {
    //console.log('*classID: ', classID);
    const matches = [];
    getNodesWithIDRecursively(subtree, classID, matches);
    return matches;
}
const getNodesWithIDRecursively = (subtree, classID, matches) => {

    for (const index of Object.keys(subtree)) {
        const node = subtree[index];
        if (node.id == classID) {
            matches.push(node);
        }
        // --- check all children --- //
        else if (node.children && node.children.length > 0) {
            getNodesWithIDRecursively(node.children, classID, matches);
        }
    }
}

/**
 * Returns node with given key.
 * @param {*} subtree 
 * @param {*} key 
 * @returns 
 */
export const getClassWithKey = (subtree, key) => {
    const match = {};
    getClassByKeyRecursively(subtree, key, match);
    return match.node;
}
const getClassByKeyRecursively = (subtree, key, match) => {

    for (const index of Object.keys(subtree)) {
        const node = subtree[index];
        // --- check node --- //
        if (node.key == key) {
            match.node = node;
            return;
        }
        // --- check all children --- //
        else if (node.children && node.children.length > 0) {
            getClassByKeyRecursively(node.children, key, match);
        }
    }
}

/**
 * Returns keys of all nodes on the path from given node to the root.
 * Info is extracted from the key of the given node.
 * @param {*} node 
 * @returns 
 */
export const getNodeKeysOnPathToNode = (node) => {

    let nodeKey = node.key;
    let currNodeKey;
    const pathKeysMap = {};

    let done = false;
    let cnt = 0;
    // --- in case something goes wrong -> stop at 25 iterations --- //
    while (nodeKey && !done && cnt < 25) {
        currNodeKey = OntologyClassUtil.extractParentKeyFromKey(nodeKey); //nodeKey.replace(/-[0-9]+$/, '');
        pathKeysMap[currNodeKey] = true;

        done = (currNodeKey == nodeKey);
        nodeKey = currNodeKey;
        cnt++;
    }
    //console.log('pathKeysMap: ', pathKeysMap);
    return pathKeysMap;
}

/**
 * Checks if adding the given child to the given parent would cause cycles.
 * @param {*} potentialParents 
 * @param {*} potentialChild 
 * @returns 
 */
export const doesAddingNodeAsChildCauseCycle = (potentialParents, potentialChild) => {

    const subclassIDs = getAllSubclassesIDs([potentialChild]);

    for (var potParent of potentialParents) {
        const parentKey = potParent.key;
        const parentKeyIDs = parentKey.split('-');
        const matches = _.intersection(Object.keys(subclassIDs), parentKeyIDs);
        if (!isArrayEmpty(matches)) {
            return true;
        }
    }
    return false;
}

/**
 * Returns IDs of all subclasses of the given subtree as a map.
 * @param {*} subtree 
 * @returns 
 */
export const getAllSubclassesIDs = (subtree) => {
    const childrenIDs = {};
    getAllSubclassesIDsRecursivley(subtree, childrenIDs);
    return childrenIDs;
}
const getAllSubclassesIDsRecursivley = (subtree, childrenIDs) => {

    for (const index of Object.keys(subtree)) {
        const node = subtree[index];
        childrenIDs[node.id] = true;
        // --- check all children --- //
        if (node.children && node.children.length > 0) {
            getAllSubclassesIDsRecursivley(node.children, childrenIDs);
        }
    }
}

// ----------------------------------------------------------------------- //
// --- sorting ----------------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Sorts nodes of subtree by name. Names are taken from the ontClasses map.
 * @param {*} subtree 
 * @param {*} ontClasses 
 * @param {*} sortChildren 
 * @returns 
 */
export const sortNodesByName = (subtree, ontClasses, sortChildren = true) => {
    return sortNodesByNameRecursively(subtree, ontClasses, sortChildren);
}
const sortNodesByNameRecursively = (subtree, ontClasses, sortChildren) => {

    const nodesSorted = sortNodeArrayByAttribute(subtree, ontClasses, 'name', true, true);
    for (const index of Object.keys(nodesSorted)) {
        const node = nodesSorted[index];
        // --- check all children --- //
        if (sortChildren && node.children && node.children.length > 0) {
            node.children = sortNodesByNameRecursively(node.children, ontClasses, sortChildren);
        }
    }

    return nodesSorted;
}

/**
 * Sorts array of objects by given attribute.
 * @param {Array} nodeArray array with nodes to be sorted 
 * @param {string} attrName name of the attribute the object array will be sorted by
 * @param {Boolean} ascending if true sort ascending, otherwise descending
 */
export const sortNodeArrayByAttribute = (nodeArray, ontClasses, attrName, ascending = true, ignoreCase = false) => {

    let ordered = [...nodeArray];
    if (ascending) {
        ordered.sort((a, b) => {
            if (!ontClasses[a.id] || !ontClasses[a.id][attrName]) return 1;
            if (!ontClasses[b.id] || !ontClasses[b.id][attrName]) return -1;

            const normNameA = OntologyClassUtil.normalizeClassName(ontClasses[a.id][attrName]);
            const normNameB = OntologyClassUtil.normalizeClassName(ontClasses[b.id][attrName]);
            if (ignoreCase)
                return (normNameA.toLowerCase() > normNameB.toLowerCase()) ? 1 : -1;
            else
                return (normNameA > normNameB) ? 1 : -1;
        });
    }
    else {
        ordered.sort((a, b) => {
            if (!ontClasses[a.id][attrName]) return -1;
            if (!ontClasses[b.id][attrName]) return 1;

            const normNameA = OntologyClassUtil.normalizeClassName(ontClasses[a.id][attrName]);
            const normNameB = OntologyClassUtil.normalizeClassName(ontClasses[b.id][attrName]);
            if (ignoreCase)
                return (normNameA.toLowerCase() < normNameB.toLowerCase()) ? 1 : -1;
            else
                return (normNameA < normNameB) ? 1 : -1;
        });
    }
    return ordered;
}

// ----------------------------------------------------------------------- //
// --- cleaning steps ---------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Removes identical syntaxes from the frame ontology.
 * @param {Object} framesSubtree frame classes subtree
 * @returns number of removed syntaxes overall
 */
export const removeRedundantSyntaxesFromFrameOntology = (framesSubtree) => {

    const numOfRemovedSyntaxes = { counter: 0 };
    removeRedundantSyntaxesFromFrameOntologyRecursively(framesSubtree, numOfRemovedSyntaxes);
    return numOfRemovedSyntaxes.counter;
}
const removeRedundantSyntaxesFromFrameOntologyRecursively = (framesSubtree, numOfRemovedSyntaxes) => {

    for (const index of Object.keys(framesSubtree)) {
        const node = framesSubtree[index];
        // --- remove redundant syntaxes --- //
        numOfRemovedSyntaxes.counter += OntologyClassUtil.removeRedundantSyntaxesFromFrameClass(node);

        // --- check all children --- //
        if (node.children && node.children.length > 0) {
            node.children = removeRedundantSyntaxesFromFrameOntologyRecursively(node.children, numOfRemovedSyntaxes);
        }
    }

    return framesSubtree;
}


/*
 export const getPathToClass = (node, className) => {
    const track = [];
    getPathToClassRecursively(node, className, track);
    return track;
}
const getPathToClassRecursively = (node, className, track) => {

    if (!node) return false;

    const normName = OntologyClassUtil.normalizeClassName(node.name);
    if (normName === className) {
        track.push(node);
        return true;
    }

    if (node.children && node.children.length > 0) {
        for (var child of node.children) {
            if (getPathToClassRecursively(child, className, track)) {
                track.unshift(node);
                return true;
            }
        }
    }

    return false;
}
*/

// TODO: implement again
/*
export const getNodeKeysOnPathToClass = (node, className) => {
    const track = {};
    getNodeKeysOnPathToClassRecursively(node, className, track);
    return track;
}

const getNodeKeysOnPathToClassRecursively = (node, className, track) => {

    if (!node) return false;

    const normName = OntologyClassUtil.normalizeClassName(node.name);
    if (normName === className) {
        track[node.key] = true;
        return true;
    }

    if (node.children && node.children.length > 0) {
        for (var child of node.children) {
            if (getNodeKeysOnPathToClassRecursively(child, className, track)) {
                track[node.key] = true;
                return true;
            }
        }
    }

    return false;
}
*/