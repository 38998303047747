import { useSelector } from "react-redux";


const useDomainColor = (domainID) => {
    const domainList = useSelector((state) => state.user.data.userDetails.department.selectedDomains);
    const domainColor = domainID && domainList?.find(domain => domain.name === domainID)?.color;

    return domainColor;
}

export default useDomainColor;