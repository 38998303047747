import { Component } from "react";
import TutorialVideos from "../../general/TutorialVideos";
import { videos } from '../../../../properties/videos'
import { APP_PROPERTIES } from "../../../../properties";
import { isArrayEmpty } from "../../util";
import { QUICK_SEARCH_EXAMPLES } from "./examples";
import SearchExamples from "../../general/searchexamples/SearchExamples";
import SeparatorPoint from "../../general/SeparatorPoint";


class HeaderBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('quick')) });
        }
    }


    render() {

        return (
            <>
                <div className='grid'>
                    <div className="col textAlignLeft" style={{ paddingLeft: 0 }}>
                        <h1 className="pageHeader" style={{ marginBottom: 0 }}>Quick Search</h1>
                    </div>
                    <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                        {!isArrayEmpty(this.state.pageTutorials) ?
                            <TutorialVideos
                                pageTutorials={this.state.pageTutorials}
                                section='quick'
                                sectionLabel='Quick Search'
                            /> : null
                        }
                    </div>
                    <div className="col-12 pageInfo" style={{ paddingLeft: 0, marginLeft: 0 }}>
                        <span>
                            Quickly find relevant documents using our powerful semantic search.
                            Just type away and the system will propose matching semantic concepts from a wide variety of
                            knowledge domains. Just select the matching concepts and add metadata filters if needed.
                        </span>

                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <SeparatorPoint />
                            <SearchExamples
                                buttonLabel="Examples"
                                //panelStyle={{ maxWidth: 400, maxHeight: 600, height: "100vh" }}
                                examples={QUICK_SEARCH_EXAMPLES}
                                onExampleClick={(example) => this.props.onSelectExample(example)}
                            />
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export default HeaderBlock;



