import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class DeleteUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteUsersChecked: false
        }
    }

    onCheckDeleteUsers = (e) => {
        if (e.checked) {
            this.setState({
                deleteUsersChecked: true
            })
        } else {
            this.setState({
                deleteUsersChecked: false
            })
        }
    }

    onDeleteUsers = () => {
        this.props.onDeleteUsers()
    }

    render() {

        let userDetails = this.props.selectedUserDetails && this.props.selectedUserDetails.map(user => {
            return <div key={user.userName} style={{ marginBottom: 5 }}>
                <label>{`${user.lastName}, ${user.foreName} (${user.userName})`}</label>
            </div>
        })


        const footerDeleteUsers = (
            <div>
                <Button label="Delete"
                    className='p-button-sm p-button-danger'
                    disabled={this.state.deleteUsersChecked ? false : true}
                    onClick={this.onDeleteUsers}
                />
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelDeleteUsers}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayDeleteUsers} style={{ 'width': "40vw" }} header="Delete users" focusOnShow={false}
                modal={true} footer={footerDeleteUsers} dismissableMask={false}
                onHide={() => this.props.onCancelDeleteUsers()}  className='styledDialog'>
                <div style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 25, paddingBottom: 15, paddingTop: 15 }}>
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <label>{`This will delete the following users:`}</label>
                    </div>
                    {userDetails}
                    <div style={{ marginBottom: 15, marginTop: 15, marginLeft: -2 }}>
                        <Checkbox inputId='deleteUserChecked'
                            onChange={(e) => this.onCheckDeleteUsers(e)}
                            checked={this.state.deleteUsersChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer' }}
                        />
                        <label htmlFor='deleteUserChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default DeleteUsers