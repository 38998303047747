import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';

import {
  deleteRepository
} from '../../../../redux/actions';

/**
 * handle a single repository
 */
class Repository extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // is this repository about to be deleted? (-> for displaying a loading sign)
      deleting: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.repositoryDeleting)
      this.setState({ deleting: false });
  }
  /**
   * render the component as a row in the repositories table
   */
  render() {
    return (
      <div className="grid p-align-center">
        <div className="col-12 md:col-5 lg:col-5 sm:col-5"><strong>{this.props.name}</strong></div>
        <div className="col-12 md:col-5 lg:col-5 sm:col-5">{this.props.numberDocuments}</div>
        <div className="col-12 md:col-2 lg:col-2 sm:col-2">
          {/*
        <Button icon={this.state.deleting ? "pi-spin pi-spinner" : "pi-md-trash" } 
        className="p-button-danger p-button-sm" onClick={() => this.rmRepository ()} tooltip={"Remove " + this.props.name}/>
        */}
          <Button label={this.state.deleting ? "Deleting" : "Delete"}
            title={`Remove ${this.props.name}`}
            className="p-button-text dangerButtonLink"
            onClick={() => this.rmRepository()}
          />
        </div>
      </div>
    );
  }

  /**
   * delete this repository
   */
  rmRepository() {
    this.setState({ deleting: true });
    this.props.deleteRepository(this.props.name);

  }
}

const mapStateToProps = state => {
  return {
    repositoryDeleting: state.managerReducer.repositoryDeleting,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    deleteRepository: (name) => dispatch(deleteRepository(name)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Repository);
