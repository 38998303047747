import React, { Component } from 'react'
import InferencePathComponent from './InferencePathComponent';
//import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
//import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
//import { InputSwitch } from 'primereact/inputswitch'
//import { SplitButton } from 'primereact/splitbutton';
//import axios from 'axios'
//import { getHeaderToken, url } from '../../../api/index'
import LoadingOverlay from "@speedy4all/react-loading-overlay";
//import { Fieldset } from 'primereact/fieldset';
import ConfirmationDialog from '../../common/ConfirmDialog/ConfirmationDialog';

class InferencePathSearchComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startTerm: [],
            startTermShow: [],
            startTermOCID: [],
            relation: [],
            relationOCID: [],
            relationMode: [],
            endTerm: [],
            endTermShow: [],
            endTermOCID: [],
            type: ['relations'],
            pathRepositories: [[]],
            visible: false,
            displayDialogExecute: false,
            pathToExecute: 0,
            splitButtonItems: [
                {
                    label: 'all domains - [all relations] - all domains', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        /*let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            for (let j = 0; j < unfilteredRepositories[i].tools.length; j++) {
                                if (unfilteredRepositories[i].tools[j].toolName === 'sciwalker') {
                                    filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                                }
                            }
                        }*/
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'aaa'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'all domains - [all relations] - compound', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'aac'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'all domains - [all relations] - disease', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'aad'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'all domains - [all relations] - protein', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'aap'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'all domains - [all relations] - physiological process', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'aapp'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'compound - [all relations] - all domains', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'caa'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'disease - [all relations] - all domains', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'daa'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'protein - [all relations] - all domains', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'paa'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'physiological process - [all relations] - all domains', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'ppaa'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'all relations'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'compound - [treats] - disease', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'ctd'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'treats'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000390'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'compound - [inhibits] - protein', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'cip'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'inhibits'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000380'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'compound - [induces] - disease', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'cid'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'induces'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000379'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'protein - [induces] - disease', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'pid'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'induces'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000379'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'compound - [is metabolized to] - compound', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'cic'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'is metabolized to'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000387'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'protein - [metabolizes] - compound', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'pmc'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'metabolizes'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000439'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'compound - [inhibits] - physiological process', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'cipp'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'inhibits'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000380'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'protein - [modulates] - physiological process', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'pmpp'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'modulates'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000378'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'physiological process - [modulates] - protein', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'ppmp'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'modulates'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000378'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'physiological process - [induces] - physiological process', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'ppipp'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'induces'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000884'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'physiological process - [prevents] - disease', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'pppd'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'prevents'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000391'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                },
                {
                    label: 'disease - [modulates] - protein', command: (e) => {
                        const newType = this.state.type.slice()
                        newType[this.props.pathNumber] = 'relations'
                        let filteredReps = []
                        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                        for (let i = 0; i < unfilteredRepositories.length; i++) {
                            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                        }
                        const newPathRepositories = this.state.pathRepositories.slice()
                        newPathRepositories[this.props.pathNumber] = filteredReps

                        const newLogicals = this.props.logicals.slice()
                        newLogicals[this.props.pathNumber] = 'dmp'

                        const newRelations = this.props.relation.slice()
                        newRelations[this.props.pathNumber] = 'modulates'

                        const newRelationOCID = this.props.relationOCID.slice()
                        newRelationOCID[this.props.pathNumber] = '232000000378'

                        const newStartTerms = this.props.startTerm.slice()
                        newStartTerms[this.props.pathNumber] = ''

                        const newStartTermsOCID = this.props.startTermOCID.slice()
                        newStartTermsOCID[this.props.pathNumber] = ''

                        const newStartChecked = this.props.startChecked.slice()
                        newStartChecked[this.props.pathNumber] = 'ontological'

                        const newRelationMode = this.props.relationMode.slice()
                        newRelationMode[this.props.pathNumber] = 'ontological'

                        const newEndTerms = this.props.endTerm.slice()
                        newEndTerms[this.props.pathNumber] = ''

                        const newEndTermsOCID = this.props.endTermOCID.slice()
                        newEndTermsOCID[this.props.pathNumber] = ''

                        const newEndChecked = this.props.endChecked.slice()
                        newEndChecked[this.props.pathNumber] = 'ontological'

                        this.setState({
                            pathNumber: this.props.pathNumber + 1,
                            type: newType,
                            pathRepositories: newPathRepositories,
                            logicals: newLogicals,
                            relation: newRelations,
                            relationOCID: newRelationOCID,
                            startTerm: newStartTerms,
                            startTermOCID: newStartTermsOCID,
                            startChecked: newStartChecked,
                            relationMode: newRelationMode,
                            endTerm: newEndTerms,
                            endTermOCID: newEndTermsOCID,
                            endChecked: newEndChecked
                        }, () => {
                            this.props.pathNumber > 0 ? this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode) :
                                this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked, this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
                        })
                    }
                }
            ]
        };
    }

    /*componentDidMount = async () => {
        if (this.props.userDetails.department?.selectedRepositories && this.props.fromEdit === false) {
            await this.onClearClick()
            this.onStartChild()
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.userDetails.department?.selectedRepositories !== prevProps.userDetails.department?.selectedRepositories && this.props.fromEdit === false) {
            await this.onClearClick()
            this.onStartChild()
        }
    }*/

    componentWillUnmount() {
        this.onClearClick()
    }

    onStartChild = () => {
        const newType = this.state.type.slice()
        newType[this.props.pathNumber] = 'relations'
        let filteredReps = []
        let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
        for (let i = 0; i < unfilteredRepositories.length; i++) {
            filteredReps = [...filteredReps, unfilteredRepositories[i].name]
        }
        const newPathRepositories = this.state.pathRepositories.slice()
        newPathRepositories[this.props.pathNumber] = filteredReps

        const newLogicals = this.props.logicals.slice()
        newLogicals[this.props.pathNumber] = 'aaa'

        const newRelations = this.props.relation.slice()
        newRelations[this.props.pathNumber] = 'all relations'

        const newRelationOCID = this.props.relationOCID.slice()
        newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

        const newStartTerms = this.props.startTerm.slice()
        newStartTerms[this.props.pathNumber] = ''

        const newStartTermsShow = this.props.startTermShow.slice()
        newStartTermsShow[this.props.pathNumber] = ''

        const newStartTermsOCID = this.props.startTermOCID.slice()
        newStartTermsOCID[this.props.pathNumber] = ''

        const newStartChecked = this.props.startChecked.slice()
        newStartChecked[this.props.pathNumber] = 'ontological'

        const newRelationMode = this.props.relationMode.slice()
        newRelationMode[this.props.pathNumber] = 'ontological'

        const newEndTerms = this.props.endTerm.slice()
        newEndTerms[this.props.pathNumber] = ''

        const newEndTermsShow = this.props.endTermShow.slice()
        newEndTermsShow[this.props.pathNumber] = ''

        const newEndTermsOCID = this.props.endTermOCID.slice()
        newEndTermsOCID[this.props.pathNumber] = ''

        const newEndChecked = this.props.endChecked.slice()
        newEndChecked[this.props.pathNumber] = 'ontological'

        this.setState({
            pathNumber: this.props.pathNumber + 1,
            type: newType,
            pathRepositories: newPathRepositories,
            logicals: newLogicals,
            relation: newRelations,
            relationOCID: newRelationOCID,
            startTerm: newStartTerms,
            startTermShow: newStartTermsShow,
            startTermOCID: newStartTermsOCID,
            startChecked: newStartChecked,
            relationMode: newRelationMode,
            endTerm: newEndTerms,
            endTermShow: newEndTermsShow,
            endTermOCID: newEndTermsOCID,
            endChecked: newEndChecked
        }, () => {
            this.props.callbackFromStart(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermShow, this.state.startTermOCID, this.state.startChecked,
                this.state.endTerm, this.state.endTermShow, this.state.endTermOCID, this.state.endChecked, false, this.state.relationMode)
        })
    }

    onAddChild = () => {
        if (this.props.logicals[this.props.pathNumber - 1] === 'ctd' || this.props.logicals[this.props.pathNumber - 1] === 'cid'
            || this.props.logicals[this.props.pathNumber - 1] === 'pid' || this.props.logicals[this.props.pathNumber - 1] === 'pppd'
            || this.props.logicals[this.props.pathNumber - 1] === 'aad') {
            const newType = this.state.type.slice()
            newType[this.props.pathNumber] = 'relations'
            /*let filteredReps = []
                       let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                       for (let i = 0; i < unfilteredRepositories.length; i++) {
                           for (let j = 0; j < unfilteredRepositories[i].tools.length; j++) {
                               if (unfilteredRepositories[i].tools[j].toolName === 'sciwalker') {
                                   filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                               }
                           }
                       }*/
            let filteredReps = []
            let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
            for (let i = 0; i < unfilteredRepositories.length; i++) {
                filteredReps = [...filteredReps, unfilteredRepositories[i].name]
            }
            const newPathRepositories = this.state.pathRepositories.slice()
            newPathRepositories[this.props.pathNumber] = filteredReps

            const newLogicals = this.props.logicals.slice()
            newLogicals[this.props.pathNumber] = 'daa'

            const newRelations = this.props.relation.slice()
            newRelations[this.props.pathNumber] = 'all relations'

            const newRelationOCID = this.props.relationOCID.slice()
            newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

            const newStartTerms = this.props.startTerm.slice()
            newStartTerms[this.props.pathNumber] = ''

            const newStartTermsOCID = this.props.startTermOCID.slice()
            newStartTermsOCID[this.props.pathNumber] = ''

            const newStartChecked = this.props.startChecked.slice()
            newStartChecked[this.props.pathNumber] = 'ontological'

            const newRelationMode = this.props.relationMode.slice()
            newRelationMode[this.props.pathNumber] = 'ontological'

            const newEndTerms = this.props.endTerm.slice()
            newEndTerms[this.props.pathNumber] = ''

            const newEndTermsOCID = this.props.endTermOCID.slice()
            newEndTermsOCID[this.props.pathNumber] = ''

            const newEndChecked = this.props.endChecked.slice()
            newEndChecked[this.props.pathNumber] = 'ontological'

            this.setState({
                pathNumber: this.props.pathNumber + 1,
                type: newType,
                pathRepositories: newPathRepositories,
                logicals: newLogicals,
                relation: newRelations,
                relationOCID: newRelationOCID,
                startTerm: newStartTerms,
                startTermOCID: newStartTermsOCID,
                startChecked: newStartChecked,
                relationMode: newRelationMode,
                endTerm: newEndTerms,
                endTermOCID: newEndTermsOCID,
                endChecked: newEndChecked
            }, () => {
                this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked,
                    this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
            })
        } else if (this.props.logicals[this.props.pathNumber - 1] === 'cip' || this.props.logicals[this.props.pathNumber - 1] === 'ppmp' ||
            this.props.logicals[this.props.pathNumber - 1] === 'dmp' || this.props.logicals[this.props.pathNumber - 1] === 'aap') {
            const newType = this.state.type.slice()
            newType[this.props.pathNumber] = 'relations'
            /*let filteredReps = []
                       let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                       for (let i = 0; i < unfilteredRepositories.length; i++) {
                           for (let j = 0; j < unfilteredRepositories[i].tools.length; j++) {
                               if (unfilteredRepositories[i].tools[j].toolName === 'sciwalker') {
                                   filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                               }
                           }
                       }*/
            let filteredReps = []
            let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
            for (let i = 0; i < unfilteredRepositories.length; i++) {
                filteredReps = [...filteredReps, unfilteredRepositories[i].name]
            }
            const newPathRepositories = this.state.pathRepositories.slice()
            newPathRepositories[this.props.pathNumber] = filteredReps

            const newLogicals = this.props.logicals.slice()
            newLogicals[this.props.pathNumber] = 'paa'

            const newRelations = this.props.relation.slice()
            newRelations[this.props.pathNumber] = 'all relations'

            const newRelationOCID = this.props.relationOCID.slice()
            newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

            const newStartTerms = this.props.startTerm.slice()
            newStartTerms[this.props.pathNumber] = ''

            const newStartTermsOCID = this.props.startTermOCID.slice()
            newStartTermsOCID[this.props.pathNumber] = ''

            const newStartChecked = this.props.startChecked.slice()
            newStartChecked[this.props.pathNumber] = 'ontological'

            const newRelationMode = this.props.relationMode.slice()
            newRelationMode[this.props.pathNumber] = 'ontological'

            const newEndTerms = this.props.endTerm.slice()
            newEndTerms[this.props.pathNumber] = ''

            const newEndTermsOCID = this.props.endTermOCID.slice()
            newEndTermsOCID[this.props.pathNumber] = ''

            const newEndChecked = this.props.endChecked.slice()
            newEndChecked[this.props.pathNumber] = 'ontological'

            this.setState({
                pathNumber: this.props.pathNumber + 1,
                type: newType,
                pathRepositories: newPathRepositories,
                logicals: newLogicals,
                relation: newRelations,
                relationOCID: newRelationOCID,
                startTerm: newStartTerms,
                startTermOCID: newStartTermsOCID,
                startChecked: newStartChecked,
                relationMode: newRelationMode,
                endTerm: newEndTerms,
                endTermOCID: newEndTermsOCID,
                endChecked: newEndChecked
            }, () => {
                this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked,
                    this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
            })
        } else if (this.props.logicals[this.props.pathNumber - 1] === 'cic' || this.props.logicals[this.props.pathNumber - 1] === 'pmc' ||
            this.props.logicals[this.props.pathNumber - 1] === 'aac') {
            const newType = this.state.type.slice()
            newType[this.props.pathNumber] = 'relations'
            /*let filteredReps = []
                       let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                       for (let i = 0; i < unfilteredRepositories.length; i++) {
                           for (let j = 0; j < unfilteredRepositories[i].tools.length; j++) {
                               if (unfilteredRepositories[i].tools[j].toolName === 'sciwalker') {
                                   filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                               }
                           }
                       }*/
            let filteredReps = []
            let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
            for (let i = 0; i < unfilteredRepositories.length; i++) {
                filteredReps = [...filteredReps, unfilteredRepositories[i].name]
            }
            const newPathRepositories = this.state.pathRepositories.slice()
            newPathRepositories[this.props.pathNumber] = filteredReps

            const newLogicals = this.props.logicals.slice()
            newLogicals[this.props.pathNumber] = 'caa'

            const newRelations = this.props.relation.slice()
            newRelations[this.props.pathNumber] = 'all relations'

            const newRelationOCID = this.props.relationOCID.slice()
            newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

            const newStartTerms = this.props.startTerm.slice()
            newStartTerms[this.props.pathNumber] = ''

            const newStartTermsOCID = this.props.startTermOCID.slice()
            newStartTermsOCID[this.props.pathNumber] = ''

            const newStartChecked = this.props.startChecked.slice()
            newStartChecked[this.props.pathNumber] = 'ontological'

            const newRelationMode = this.props.relationMode.slice()
            newRelationMode[this.props.pathNumber] = 'ontological'

            const newEndTerms = this.props.endTerm.slice()
            newEndTerms[this.props.pathNumber] = ''

            const newEndTermsOCID = this.props.endTermOCID.slice()
            newEndTermsOCID[this.props.pathNumber] = ''

            const newEndChecked = this.props.endChecked.slice()
            newEndChecked[this.props.pathNumber] = 'ontological'

            this.setState({
                pathNumber: this.props.pathNumber + 1,
                type: newType,
                pathRepositories: newPathRepositories,
                logicals: newLogicals,
                relation: newRelations,
                relationOCID: newRelationOCID,
                startTerm: newStartTerms,
                startTermOCID: newStartTermsOCID,
                startChecked: newStartChecked,
                relationMode: newRelationMode,
                endTerm: newEndTerms,
                endTermOCID: newEndTermsOCID,
                endChecked: newEndChecked
            }, () => {
                this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked,
                    this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
            })
        } else if (this.props.logicals[this.props.pathNumber - 1] === 'cipp' || this.props.logicals[this.props.pathNumber - 1] === 'pmpp' ||
            this.props.logicals[this.props.pathNumber - 1] === 'ppipp' || this.props.logicals[this.props.pathNumber - 1] === 'aapp') {
            const newType = this.state.type.slice()
            newType[this.props.pathNumber] = 'relations'
            /*let filteredReps = []
                       let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                       for (let i = 0; i < unfilteredRepositories.length; i++) {
                           for (let j = 0; j < unfilteredRepositories[i].tools.length; j++) {
                               if (unfilteredRepositories[i].tools[j].toolName === 'sciwalker') {
                                   filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                               }
                           }
                       }*/
            let filteredReps = []
            let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
            for (let i = 0; i < unfilteredRepositories.length; i++) {
                filteredReps = [...filteredReps, unfilteredRepositories[i].name]
            }
            const newPathRepositories = this.state.pathRepositories.slice()
            newPathRepositories[this.props.pathNumber] = filteredReps

            const newLogicals = this.props.logicals.slice()
            newLogicals[this.props.pathNumber] = 'ppaa'

            const newRelations = this.props.relation.slice()
            newRelations[this.props.pathNumber] = 'all relations'

            const newRelationOCID = this.props.relationOCID.slice()
            newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

            const newStartTerms = this.props.startTerm.slice()
            newStartTerms[this.props.pathNumber] = ''

            const newStartTermsOCID = this.props.startTermOCID.slice()
            newStartTermsOCID[this.props.pathNumber] = ''

            const newStartChecked = this.props.startChecked.slice()
            newStartChecked[this.props.pathNumber] = 'ontological'

            const newRelationMode = this.props.relationMode.slice()
            newRelationMode[this.props.pathNumber] = 'ontological'

            const newEndTerms = this.props.endTerm.slice()
            newEndTerms[this.props.pathNumber] = ''

            const newEndTermsOCID = this.props.endTermOCID.slice()
            newEndTermsOCID[this.props.pathNumber] = ''

            const newEndChecked = this.props.endChecked.slice()
            newEndChecked[this.props.pathNumber] = 'ontological'

            this.setState({
                pathNumber: this.props.pathNumber + 1,
                type: newType,
                pathRepositories: newPathRepositories,
                logicals: newLogicals,
                relation: newRelations,
                relationOCID: newRelationOCID,
                startTerm: newStartTerms,
                startTermOCID: newStartTermsOCID,
                startChecked: newStartChecked,
                relationMode: newRelationMode,
                endTerm: newEndTerms,
                endTermOCID: newEndTermsOCID,
                endChecked: newEndChecked
            }, () => {
                this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermOCID, this.state.startChecked,
                    this.state.endTerm, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
            })
        } else if (this.props.logicals[this.props.pathNumber - 1] === 'aaa' || this.props.logicals[this.props.pathNumber - 1] === 'caa' ||
            this.props.logicals[this.props.pathNumber - 1] === 'daa' || this.props.logicals[this.props.pathNumber - 1] === 'ppaa' ||
            this.props.logicals[this.props.pathNumber - 1] === 'paa') {
            const newType = this.state.type.slice()
            newType[this.props.pathNumber] = 'relations'
            /*let filteredReps = []
                       let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
                       for (let i = 0; i < unfilteredRepositories.length; i++) {
                           for (let j = 0; j < unfilteredRepositories[i].tools.length; j++) {
                               if (unfilteredRepositories[i].tools[j].toolName === 'sciwalker') {
                                   filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                               }
                           }
                       }*/
            let filteredReps = []
            let unfilteredRepositories = this.props.userDetails.department?.selectedRepositories
            for (let i = 0; i < unfilteredRepositories.length; i++) {
                filteredReps = [...filteredReps, unfilteredRepositories[i].name]
            }
            const newPathRepositories = this.state.pathRepositories.slice()
            newPathRepositories[this.props.pathNumber] = filteredReps

            const newLogicals = this.props.logicals.slice()
            newLogicals[this.props.pathNumber] = 'aaa'

            const newRelations = this.props.relation.slice()
            newRelations[this.props.pathNumber] = 'all relations'

            const newRelationOCID = this.props.relationOCID.slice()
            newRelationOCID[this.props.pathNumber] = ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']

            const newStartTerms = this.props.startTerm.slice()
            newStartTerms[this.props.pathNumber] = ''

            const newStartTermsShow = this.props.startTermShow.slice()
            newStartTermsShow[this.props.pathNumber] = ''

            const newStartTermsOCID = this.props.startTermOCID.slice()
            newStartTermsOCID[this.props.pathNumber] = ''

            const newStartChecked = this.props.startChecked.slice()
            newStartChecked[this.props.pathNumber] = 'ontological'

            const newRelationMode = this.props.relationMode.slice()
            newRelationMode[this.props.pathNumber] = 'ontological'

            const newEndTerms = this.props.endTerm.slice()
            newEndTerms[this.props.pathNumber] = ''

            const newEndTermsShow = this.props.endTermShow.slice()
            newEndTermsShow[this.props.pathNumber] = ''

            const newEndTermsOCID = this.props.endTermOCID.slice()
            newEndTermsOCID[this.props.pathNumber] = ''

            const newEndChecked = this.props.endChecked.slice()
            newEndChecked[this.props.pathNumber] = 'ontological'

            this.setState({
                pathNumber: this.props.pathNumber + 1,
                type: newType,
                pathRepositories: newPathRepositories,
                logicals: newLogicals,
                relation: newRelations,
                relationOCID: newRelationOCID,
                startTerm: newStartTerms,
                startTermOCID: newStartTermsOCID,
                startChecked: newStartChecked,
                relationMode: newRelationMode,
                endTerm: newEndTerms,
                endTermShow: newEndTermsShow,
                endTermOCID: newEndTermsOCID,
                endChecked: newEndChecked,
                startTermShow: newStartTermsShow
            }, () => {
                this.props.callbackFromAddPath(this.state.pathNumber, this.state.type, this.state.pathRepositories, this.state.logicals,
                    this.state.relation, this.state.relationOCID, this.state.startTerm, this.state.startTermShow, this.state.startTermOCID, this.state.startChecked,
                    this.state.endTerm, this.state.endTermShow, this.state.endTermOCID, this.state.endChecked, this.state.relationMode)
            })
        }
    }

    onClearClick = () => {
        this.props.callbackFromClear()
    }

    myCallbackFromStartTermChange = async (startTerm, startTermOCID, complete, pathNumber, domains, autocompleteOrBrowserStart, run, selectedMode, selectedRelations) => {

        if (selectedMode) {
            await this.myCallbackFromStartToggleChange(selectedMode, pathNumber - 1)
        }

        const newStartTerms = this.props.startTerm.slice()
        newStartTerms[pathNumber - 1] = startTerm
        const newStartTermsOCID = this.props.startTermOCID.slice()
        newStartTermsOCID[pathNumber - 1] = complete

        this.setState({
            startTerms: newStartTerms,
            startTermsOCID: newStartTermsOCID
        }, () => this.props.callbackFromStartTermChange(this.state.startTerms, this.state.startTermsOCID, complete, pathNumber, domains,
            autocompleteOrBrowserStart, run, selectedRelations)
        )

    }

    myCallbackFromRelationChange = (relation, relationOCID, complete, pathNumber) => {
        const newRelations = this.props.relation.slice()
        newRelations[pathNumber - 1] = relation
        const newRelationsOCID = this.props.relationOCID.slice()
        newRelationsOCID[pathNumber - 1] = relationOCID
        this.setState({
            relation: newRelations,
            relationOCID: newRelationsOCID
        }, () => this.props.callbackFromRelationChange(this.state.relation, this.state.relationOCID, complete, pathNumber))
    }

    myCallbackFromRelationDropdownChange = (label, value, pathNumber) => {
        const newRelations = this.props.relation.slice()
        newRelations[pathNumber - 1] = label
        const newRelationsOCID = this.props.relationOCID.slice()
        newRelationsOCID[pathNumber - 1] = value
        this.setState({
            relation: newRelations,
            relationOCID: newRelationsOCID
        }, () => this.props.callbackFromRelationChange(this.state.relation, this.state.relationOCID, pathNumber))
    }

    myCallbackFromEndTermChange = async (endTerm, endTermOCID, complete, pathNumber, domains, autocompleteOrBrowserEnd, run, selectedMode) => {

        if (selectedMode) {
            await this.myCallbackFromEndToggleChange(selectedMode, pathNumber - 1)
        }

        const newEndTerms = this.props.endTerm.slice()
        newEndTerms[pathNumber - 1] = endTerm
        const newEndTermsOCID = this.props.endTermOCID.slice()
        newEndTermsOCID[pathNumber - 1] = complete

        this.setState({
            endTerms: newEndTerms,
            endTermsOCID: newEndTermsOCID
        }, () => this.props.callbackFromEndTermChange(this.state.endTerms, this.state.endTermsOCID, complete, pathNumber, domains, autocompleteOrBrowserEnd, run))
    }

    myCallbackFromTypeRadioChange = (type, pathNumber) => {
        const newType = this.state.type.slice()
        newType[pathNumber - 1] = type
        this.setState({
            type: newType
        }, () => this.props.callbackFromTypeRadioChange(this.state.type, pathNumber))
    }

    myCallbackFromRepositories = (filter, pathNumber) => {
        let newPathRepositories = this.state.pathRepositories.slice()
        newPathRepositories[pathNumber - 1] = filter
        this.setState({
            pathRepositories: newPathRepositories
        }, () => this.props.callBackFromRepositories(this.state.pathRepositories))
    }

    myCallbackFromStartToggleChange = (value, pathNumber) => {
        let newStartChecked = this.props.startChecked.slice()
        newStartChecked[pathNumber] = value
        this.setState({
            startChecked: newStartChecked
        }, () => this.props.callbackFromStartToggleChange(this.state.startChecked, pathNumber))
    }

    myCallbackFromRelationToggleChange = (value, pathNumber) => {
        let newRelationMode = this.props.relationMode.slice()
        newRelationMode[pathNumber] = value
        this.setState({
            relationMode: newRelationMode
        }, () => this.props.callbackFromRelationToggleChange(this.state.relationMode, pathNumber))
    }

    myCallbackFromEndToggleChange = (value, pathNumber) => {
        let newEndChecked = this.props.endChecked.slice()
        newEndChecked[pathNumber] = value
        this.setState({
            endChecked: newEndChecked
        }, () => this.props.callbackFromEndToggleChange(this.state.endChecked, pathNumber))
    }

    myCallbackFromExecutePath = (number) => {
        this.props.myCallbackFromExecutePath(number)
    }

    handleFilterCheckedChange = (value) => {
        this.props.callbackFromFilterCheckedChange(value)
    }

    render() {
        const children = []

        let filteredSplitButtonItems

        if (this.props.pathNumber < 1) {
            filteredSplitButtonItems = this.state.splitButtonItems
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'free') {
            filteredSplitButtonItems = this.state.splitButtonItems
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'ctd') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'disease - [modulates] - protein' || element.label === 'disease - [all relations] - all domains'
            })
        }
        else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'aad') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'disease - [modulates] - protein' || element.label === 'disease - [all relations] - all domains'
            })
        }
        else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'pppd') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'disease - [modulates] - protein' || element.label === 'disease - [all relations] - all domains'
            })
        }
        else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'cip') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'protein - [induces] - disease' || element.label === 'protein - [metabolizes] - compound' ||
                    element.label === 'protein - [modulates] - physiological process' || element.label === 'protein - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'cid') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'disease - [modulates] - protein' || element.label === 'disease - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'pid') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'disease - [modulates] - protein' || element.label === 'disease - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'cic') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'compound - [treats] - disease' || element.label === 'compound - [inhibits] - protein' ||
                    element.label === 'compound - [induces] - disease' || element.label === 'compound - [is metabolized to] - compound' ||
                    element.label === 'compound - [is metabolized to] - compound' || element.label === 'compound - [inhibits] - physiological process' ||
                    element.label === 'compound - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'aac') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'compound - [treats] - disease' || element.label === 'compound - [inhibits] - protein' ||
                    element.label === 'compound - [induces] - disease' || element.label === 'compound - [is metabolized to] - compound' ||
                    element.label === 'compound - [is metabolized to] - compound' || element.label === 'compound - [inhibits] - physiological process' ||
                    element.label === 'compound - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'pmc') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'compound - [treats] - disease' || element.label === 'compound - [inhibits] - protein' ||
                    element.label === 'compound - [induces] - disease' || element.label === 'compound - [is metabolized to] - compound' ||
                    element.label === 'compound - [is metabolized to] - compound' || element.label === 'compound - [inhibits] - physiological process' ||
                    element.label === 'compound - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'cipp') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'physiological process - [modulates] - protein' || element.label === 'physiological process - [induces] - physiological process' ||
                    element.label === 'physiological process - [prevents] - disease' || element.label === 'physiological process - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'pmpp') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'physiological process - [modulates] - protein' || element.label === 'physiological process - [induces] - physiological process' ||
                    element.label === 'physiological process - [prevents] - disease' || element.label === 'physiological process - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'ppmp') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'protein - [induces] - disease' || element.label === 'protein - [metabolizes] - compound' ||
                    element.label === 'protein - [modulates] - physiological process' || element.label === 'protein - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'ppipp') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'physiological process - [modulates] - protein' || element.label === 'physiological process - [induces] - physiological process' ||
                    element.label === 'physiological process - [prevents] - disease' || element.label === 'physiological process - [all relations] - all domains'
            })
        }
        else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'aapp') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'physiological process - [modulates] - protein' || element.label === 'physiological process - [induces] - physiological process' ||
                    element.label === 'physiological process - [prevents] - disease' || element.label === 'physiological process - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'dmp') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'protein - [induces] - disease' || element.label === 'protein - [metabolizes] - compound' ||
                    element.label === 'protein - [modulates] - physiological process' || element.label === 'protein - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'aap') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'protein - [induces] - disease' || element.label === 'protein - [metabolizes] - compound' ||
                    element.label === 'protein - [modulates] - physiological process' || element.label === 'protein - [all relations] - all domains'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'aaa') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'all domains - [all relations] - all domains' || element.label === 'all domains - [all relations] - compound' ||
                    element.label === 'all domains - [all relations] - disease' || element.label === 'all domains - [all relations] - physiological process' ||
                    element.label === 'all domains - [all relations] - protein'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'caa') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'all domains - [all relations] - all domains' || element.label === 'all domains - [all relations] - compound' ||
                    element.label === 'all domains - [all relations] - disease' || element.label === 'all domains - [all relations] - physiological process' ||
                    element.label === 'all domains - [all relations] - protein'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'daa') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'all domains - [all relations] - all domains' || element.label === 'all domains - [all relations] - compound' ||
                    element.label === 'all domains - [all relations] - disease' || element.label === 'all domains - [all relations] - physiological process' ||
                    element.label === 'all domains - [all relations] - protein'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'ppaa') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'all domains - [all relations] - all domains' || element.label === 'all domains - [all relations] - compound' ||
                    element.label === 'all domains - [all relations] - disease' || element.label === 'all domains - [all relations] - physiological process' ||
                    element.label === 'all domains - [all relations] - protein'
            })
        } else if (this.props.pathNumber >= 1 && this.props.logicals[this.props.pathNumber - 1] === 'paa') {
            filteredSplitButtonItems = this.state.splitButtonItems.filter(function (element) {
                return element.label === 'all domains - [all relations] - all domains' || element.label === 'all domains - [all relations] - compound' ||
                    element.label === 'all domains - [all relations] - disease' || element.label === 'all domains - [all relations] - physiological process' ||
                    element.label === 'all domains - [all relations] - protein'
            })
        }

        let startOCIDs = []
        let relationOCIDs = []
        let endOCIDs = []
        let endOCIDs2 = []
        let relationOCIDs2 = []
        let relationOCIDs3 = []
        let endOCIDs3 = []
        let relationOCIDs4 = []
        let endOCIDs4 = []
        let relationOCIDs5 = []
        let endOCIDs5 = []
        let hits = []

        this.state.matchedPaths && this.state.matchedPaths.length > 0 && this.state.matchedPaths.forEach(match => {
            startOCIDs = [...startOCIDs, match.path[0]]
            relationOCIDs = [...relationOCIDs, match.path[1]]
            endOCIDs = [...endOCIDs, match.path[2]]
            if (this.state.pathToExecute >= 1) {
                relationOCIDs2 = [...relationOCIDs2, match.path[3]]
                endOCIDs2 = [...endOCIDs2, match.path[4]]
            }
            if (this.state.pathToExecute >= 2) {
                relationOCIDs3 = [...relationOCIDs3, match.path[5]]
                endOCIDs3 = [...endOCIDs3, match.path[6]]
            }
            if (this.state.pathToExecute >= 3) {
                relationOCIDs4 = [...relationOCIDs4, match.path[7]]
                endOCIDs4 = [...endOCIDs4, match.path[8]]
            }
            if (this.state.pathToExecute >= 4) {
                relationOCIDs5 = [...relationOCIDs5, match.path[9]]
                endOCIDs5 = [...endOCIDs5, match.path[10]]
            }
            if (match.rankScore && match.rankScore) {
                hits = [...hits, Math.round(match.rankScore)]
            } else {
                hits = [...hits, 1]
            }
        })


        let startNames = []
        let relationNames = []
        let endNames = []
        let endNames2 = []
        let relationNames2 = []
        let relationNames3 = []
        let endNames3 = []
        let relationNames4 = []
        let endNames4 = []
        let relationNames5 = []
        let endNames5 = []


        startOCIDs.forEach(start => {
            if (this.state.prefNames[start]) {
                startNames = [...startNames, this.state.prefNames[start]]
            }
        })

        relationOCIDs.forEach(relation => {
            if (this.state.prefNames[relation]) {
                relationNames = [...relationNames, this.state.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim()]
            }
        })

        endOCIDs.forEach(end => {
            if (this.state.prefNames[end]) {
                endNames = [...endNames, this.state.prefNames[end]]
            }
        })

        relationOCIDs2.forEach(relation => {
            if (this.state.prefNames[relation]) {
                relationNames2 = [...relationNames2, this.state.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim()]
            }
        })

        relationOCIDs3.forEach(relation => {
            if (this.state.prefNames[relation]) {
                relationNames3 = [...relationNames3, this.state.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim()]
            }
        })

        relationOCIDs4.forEach(relation => {
            if (this.state.prefNames[relation]) {
                relationNames4 = [...relationNames4, this.state.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim()]
            }
        })

        relationOCIDs5.forEach(relation => {
            if (this.state.prefNames[relation]) {
                relationNames5 = [...relationNames5, this.state.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim()]
            }
        })

        endOCIDs2.forEach(end => {
            if (this.state.prefNames[end]) {
                endNames2 = [...endNames2, this.state.prefNames[end]]
            }
        })

        endOCIDs3.forEach(end => {
            if (this.state.prefNames[end]) {
                endNames3 = [...endNames3, this.state.prefNames[end]]
            }
        })

        endOCIDs4.forEach(end => {
            if (this.state.prefNames[end]) {
                endNames4 = [...endNames4, this.state.prefNames[end]]
            }
        })

        endOCIDs5.forEach(end => {
            if (this.state.prefNames[end]) {
                endNames5 = [...endNames5, this.state.prefNames[end]]
            }
        })

        let paths = []
        startNames.map((name, i) => {
            this.state.pathToExecute === 0 ?
                paths[i] = { start: startNames[i], relation: relationNames[i], end: endNames[i], hits: hits[i] }
                : this.state.pathToExecute === 1 ?
                    paths[i] = { start: startNames[i], relation: relationNames[i], end: endNames[i], relation2: relationNames2[i], end2: endNames2[i], hits: hits[i] }
                    : this.state.pathToExecute === 2 ?
                        paths[i] = {
                            start: startNames[i], relation: relationNames[i], end: endNames[i], relation2: relationNames2[i], end2: endNames2[i],
                            relation3: relationNames3[i], end3: endNames3[i], hits: hits[i]
                        }
                        : this.state.pathToExecute === 3 ?
                            paths[i] = {
                                start: startNames[i], relation: relationNames[i], end: endNames[i], relation2: relationNames2[i], end2: endNames2[i],
                                relation3: relationNames3[i], end3: endNames3[i], relation4: relationNames4[i], end4: endNames4[i], hits: hits[i]
                            }
                            :
                            this.state.pathToExecute === 4 ?
                                paths[i] = {
                                    start: startNames[i], relation: relationNames[i], end: endNames[i], relation2: relationNames2[i], end2: endNames2[i],
                                    relation3: relationNames3[i], end3: endNames3[i], relation4: relationNames4[i], end4: endNames4[i],
                                    relation5: relationNames5[i], end5: endNames5[i], hits: hits[i]
                                }
                                : paths = []
            return paths
        })


        for (var i = 0; i < this.props.pathNumber; i += 1) {
            children.push(
                <div key={i} //className={i === 0 ? 'col-4' : 'col-3'}
                    style={{
                        marginBottom: 35,// marginLeft: i === 2 || i === 4 ? -50 : 0,
                        width: i === 0 ? 590 : 380
                    }}>
                    <InferencePathComponent
                        key={i}
                        number={i}
                        pathNumber={this.props.pathNumber}
                        startTerm={i >= 1 && this.props.endTerm[i - 1] !== '' ? this.props.endTerm[i - 1] : this.props.startTerm[i]}
                        startTermShow={i >= 1 && this.props.endTermShow[i - 1] !== '' ? this.props.endTermShow[i - 1] : this.props.startTermShow[i]}
                        startTermOCID={i >= 1 && this.props.endTermOCID[i - 1] !== '' ? this.props.endTermOCID[i - 1] : this.props.startTermOCID[i]}
                        startChecked={i >= 1 && this.props.endTerm[i - 1] !== '' ? this.props.endChecked[i - 1] : this.props.startChecked[i]}
                        endTerm={this.props.endTerm[i]}
                        endTermShow={this.props.endTermShow[i]}
                        endTermOCID={this.props.endTermOCID[i]}
                        endChecked={this.props.endChecked[i]}
                        relation={this.props.relation[i]}
                        relationOCID={this.props.relationOCID[i]}
                        relationValues={this.props.relationValues[i]}
                        fetchTermSuggestions={this.props.fetchTermSuggestions}
                        callbackFromStartTermChange={this.myCallbackFromStartTermChange}
                        callbackFromRelationChange={this.myCallbackFromRelationChange}
                        callbackFromEndTermChange={this.myCallbackFromEndTermChange}
                        terms={this.props.terms}
                        userData={this.props.userData}
                        userDetails={this.props.userDetails}
                        pathRepositories={this.props.pathRepositories[i]}
                        callBackFromRepositories={this.myCallbackFromRepositories}
                        type={this.props.type[i]}
                        queryNames={this.props.queryNames}
                        callbackFromTypeRadioChange={this.myCallbackFromTypeRadioChange}
                        logicals={this.props.logicals[i]}
                        callbackFromRemove={this.props.callbackFromRemove}
                        callbackFromRelationDropdownChange={this.myCallbackFromRelationDropdownChange}
                        callbackFromExecutePath={this.props.myCallbackFromExecutePath}
                        callbackFromTripleExecute={this.props.callbackFromTripleExecute}
                        callbackFromStartToggleChange={this.myCallbackFromStartToggleChange}
                        callbackFromEndToggleChange={this.myCallbackFromEndToggleChange}
                        selectedBlacklists={this.props.selectedBlacklists}
                        availableDomains={this.props.availableDomains}
                        callbackFromDomainBrowser={this.props.callbackFromDomainBrowser}
                        selectedRepository={this.props.selectedRepository}
                        filterTriple={this.props.filterTriple}
                        filterSub={this.props.filterSub}
                        filterFull={this.props.filterFull}
                        callbackFromRelationMultiChange={this.props.callbackFromRelationMultiChange}
                        callbackFromRelationModeChange={this.props.callbackFromRelationModeChange}
                        relationOCIDMulti={this.props.relationOCIDMulti[i]}
                        relationMode={this.props.relationMode[i]}
                        tripleHits={this.props.tripleHits[i]}
                        subPathHits={this.props.subPathHits[i]}
                        fullPathHits={this.props.fullPathHits[i]}
                        leftDomains={i >= 1 ? this.props.rightDomains[i - 1] : this.props.leftDomains[i]}
                        rightDomains={this.props.rightDomains[i]}
                        leftDomainsShow={i >= 1 ? this.props.rightDomainsShow[i - 1] : this.props.leftDomainsShow[i]}
                        rightDomainsShow={this.props.rightDomainsShow[i]}
                        executeSinglePath={this.props.executeSinglePath}
                        numberOfHops={this.props.numberOfHops}
                        callbackFromHopNumberChange={this.props.callbackFromHopNumberChange}
                        callbackFromSearchWithHops={this.props.callbackFromSearchWithHops}
                        startTermDomains={i >= 1 ? this.props.endTermDomains[i - 1] : this.props.startTermDomains[i]}
                        endTermDomains={this.props.endTermDomains[i]}
                        autocompleteOrBrowserStart={this.props.autocompleteOrBrowserStart[i]}
                        autocompleteOrBrowserEnd={this.props.autocompleteOrBrowserEnd[i]}
                    //callbackFromStartToggleChange={this.props.callbackFromStartToggleChange}
                    //callbackFromEndToggleChange={this.props.callbackFromEndToggleChange}
                    //showFirstRelation={this.props.showFirstRelation} 
                    //callbackFromFirstRelationClick={this.props.callbackFromFirstRelationClick}
                    />
                </div>
            );
        };

        //let sortedItems = filteredSplitButtonItems.sort((a, b) => (a.label > b.label) ? 1 : -1)

        return (
            <span style={{ display: "block", paddingBottom: 10 }}>
                <LoadingOverlay
                    active={this.state.fetchingResults}
                    spinner={true} >
                    <div className='col-12 md:col-12 lg:col-12 grid'>
                        {/*this.props.pathNumber === 0 &&
                            <a onClick={() => this.onStartChild()}
                                style={{ display: 'inline-block', marginTop: 10 }}>
                                Start with free form
                        </a>
                        */}
                        {/*this.props.pathNumber <= 5 && this.props.pathNumber > 1 &&
                            <div style={{ marginBottom: 10 }}>
                                <label style={{ fontSize: '14px', paddingRight: 5, marginBottom: 10, marginRight: 5, fontWeight: 'bold' }}>
                                    Filter direct matches</label>
                                <InputSwitch
                                    className='coloredInputSwitch'
                                    checked={this.props.filterChecked && (this.props.startTerm[0] !== "" && this.props.startTerm[0].length > 0 &&
                                        this.props.endTerm[this.props.pathNumber - 1] !== "" && this.props.endTerm[this.props.pathNumber - 1].length > 0
                                        // && this.props.fullPathHits[this.props.pathNumber - 1] > 0
                                    )}
                                    onChange={(e) => this.handleFilterCheckedChange(e.value)}
                                    style={{ "verticalAlign": 'text-top' }}
                                    disabled={this.props.startTerm[0] !== "" && this.props.startTerm[0].length > 0 &&
                                        this.props.endTerm[this.props.pathNumber - 1] !== "" && this.props.endTerm[this.props.pathNumber - 1].length > 0
                                        //&& this.props.fullPathHits[this.props.pathNumber - 1] > 0 
                                        ? false : true}
                                    tooltip={(this.props.startTerm[0] === "" || this.props.startTerm[0].length === 0 ||
                                        this.props.endTerm[this.props.pathNumber - 1] === "" || this.props.endTerm[this.props.pathNumber - 1].length === 0
                                        //|| this.props.fullPathHits[this.props.pathNumber - 1] === 0
                                    )
                                        ? "Define at least first and last entities. Number of hits for full path have to be > 0." : this.props.filterChecked ? "Filter direct matches." : "Do not filter direct matches."}>
                                </InputSwitch>
                            </div>
                        */}
                        <div className='grid' style={{
                            width: '100%',// justifyContent: this.props.pathNumber > 3 ? 'start' : 'center' 
                            justifyContent: 'start'
                        }}>
                            {children}
                            {this.props.pathNumber < 5 && this.props.pathNumber > 0 &&
                                <div style={{ marginTop: 68 }}>
                                    <a onClick={() => this.onAddChild()}
                                        style={{}}>
                                        Add entity
                                    </a>
                                </div>
                            }

                        </div>
                        {/* <div>
                            {this.props.pathNumber < 5 && this.props.pathNumber > 0 &&
                                <SplitButton label="Add possible path or choose from templates"
                                    model={sortedItems}
                                    className='p-button-sm'
                                    onClick={() => this.onAddChild()}
                                    icon="pi pi-plus"
                                    style={{ marginLeft: this.props.pathNumber * 60 }} />
                            }
                            {/*this.props.pathNumber >= 1 &&
                                    <Button title="Reset page" label="Clear all" className="p-button-danger p-button-sm"
                                        onClick={() => this.setState({ displayConfirmationDialog: true })} 
                                        style={{ float: 'right', marginRight: 0 }} />
                                
                        </div>*/}
                    </div>
                    <Dialog visible={this.state.displayDialogExecute} width="700px" header={`Top matches up to path ${this.state.pathToExecute + 1}`}
                        modal={true} dismissableMask={true} focusOnShow={false}
                        onHide={() => this.setState({ displayDialogExecute: false })}>
                        <div className='col-12'>
                            <DataTable value={paths} scrollable={true} scrollHeight="500px">
                                <Column field="start" header="Entity 1" style={{ "fontWeight": 'normal' }} />
                                <Column field="relation" header="Relation 1" style={{ "fontWeight": 'normal' }} />
                                <Column field="end" header="Entity 2" style={{ "fontWeight": 'normal' }} />
                                {this.state.pathToExecute >= 1 &&
                                    <Column field="relation2" header="Relation 2" style={{ "fontWeight": 'normal' }} />
                                }
                                {this.state.pathToExecute >= 1 &&
                                    <Column field="end2" header="Entity 3" style={{ "fontWeight": 'normal' }} />
                                }
                                {this.state.pathToExecute >= 2 &&
                                    <Column field="relation3" header="Relation 3" style={{ "fontWeight": 'normal' }} />
                                }
                                {this.state.pathToExecute >= 2 &&
                                    <Column field="end3" header="Entity 4" style={{ "fontWeight": 'normal' }} />
                                }
                                {this.state.pathToExecute >= 3 &&
                                    <Column field="relation4" header="Relation 4" style={{ "fontWeight": 'normal' }} />
                                }
                                {this.state.pathToExecute >= 3 &&
                                    <Column field="end4" header="Entity 5" style={{ "fontWeight": 'normal' }} />
                                }
                                {this.state.pathToExecute >= 4 &&
                                    <Column field="relation5" header="Relation 5" style={{ "fontWeight": 'normal' }} />
                                }
                                {this.state.pathToExecute >= 4 &&
                                    <Column field="end5" header="Entity 6" style={{ "fontWeight": 'normal' }} />
                                }
                                <Column field="hits" header="Occurrences (avg)" sortable={true} />
                            </DataTable>
                        </div>
                    </Dialog>
                    <ConfirmationDialog
                        displayDialog={this.props.displayConfirmationDialog}
                        onHide={() => this.props.callbackFromCloseConfirm()}
                        onSubmit={async () => {
                            await this.props.callbackFromClear()
                            this.onStartChild()
                            this.props.callbackFromCloseConfirm();
                        }}
                        headerText="Confirm"
                        messageText="Clear all input?"
                        submitButtonLabel={"Clear"}
                    />
                </LoadingOverlay>
            </span>
        )
    }
}

export default InferencePathSearchComponent;