/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import AutoComplete from '../../common/AutoComplete/AutoComplete';
import { checkResultAndGetPayload } from '../../../api';
import { fetchMergedTermSuggestions } from '../../../api/content/ConceptApi';
import OntologyBrowserDialog from '../ontbrowser/OntologyBrowserDialog';
import { isArrayEmpty, normalizeConceptTerm, deduplicateTerms, findMatchIgnoreCase, removeValueFromArray } from '../util';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { WILDCARD_ASTERISK } from '../general/docsearch/searchConstants';
import { addConceptsToQueryTerms, replaceQueryTermWithConcepts } from '../general/docsearch/searchUtil';
import InputFieldWithClear from '../general/inputfields/InputFieldWithClear';
import DomainColorBar from '../../common/DomainColorBar/DomainColorBar';


class CorrelationsInputField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            selectedPreset: { label: '' },
            key: 'inputField'
        };

        this.searchTermTemplate = this.searchTermTemplate.bind(this);
        this.suggestionTemplate = this.suggestionTemplate.bind(this);
    }


    autocomplete = (event) => {

        this.latestTermFragment = event.query;

        setTimeout(async () => {
            // --- fetch term suggestions --- //
            let terms = [];
            let domains = null;
            let ocidSubtrees = null;
            if (this.props.restrictions && !!this.props.restrictions.restrictAutocomplete) {
                domains = this.props.restrictions.domains;
                ocidSubtrees = [];
                if (this.props.restrictions.restrictAutocompleteDomains) {
                    ocidSubtrees.push(this.props.restrictions.restrictAutocompleteDomains);
                }
                if (this.props.restrictions.restrictAutocompleteOcids) {
                    ocidSubtrees.push(this.props.restrictions.restrictAutocompleteOcids);
                }
            }

            const response = await fetchMergedTermSuggestions(event.query, domains, ocidSubtrees, null, true, false);
            terms = checkResultAndGetPayload(response, this.props.growl);

            if (event.query === this.latestTermFragment && terms) {
                this.setState({
                    suggestions: terms,
                    inputText: event.query
                });
            }
        }, 200);
    }


    handleInputChange = (value) => {
        this.props.handleInputChange(value);
    }

    handleAddPreset = (preset) => {
        const queryTermsNew = !this.props.addPresetsToInput || preset.isSingleInput ||
            isArrayEmpty(this.props.queryTerms) || this.props.queryTerms?.some(qt => qt.isSingleInput) ?
            [] : [...this.props.queryTerms];
        if (!queryTermsNew.some(qt => qt === preset)) {
            queryTermsNew.push(preset);
        }

        this.props.handleInputChange(queryTermsNew);
    }


    handleInputSelect = (value) => {

        this.setState({
            latestSelectedQueryTerm: value
        });
    }
    /**/

    searchTermTemplate(item) {

        //console.log('item: ', item);
        //console.log('domains: ', item.domains);
        //console.log('this.props.domains: ', this.props.domains);

        let info = '';
        let coloredDomains = []
        let coloredInfo
        if (item.domains && item.domains[0] !== '_ALL') {
            const uniqueLabels = item.domains.map(dom =>
                this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                    this.props.domainLabelsMap[dom] : dom);
            uniqueLabels.sort().forEach(lab => {
                let color = this.props.domainColors && this.props.domainColors.find(obj => {
                    return obj.label === lab
                })
                if (color !== undefined) {
                    coloredDomains = [...coloredDomains, '#' + color.color]
                } else {
                    coloredDomains = [...coloredDomains, 'black']
                }
            })

            coloredInfo = uniqueLabels.sort().map((lab, i) => {
                let color = this.props.domainColors && this.props.domainColors.find(obj => {
                    return obj.label === lab
                })
                const col = color?.color ? `#${color.color}` : '';
                return <div key={`${lab}-${i}`} className="grid" style={{ margin: 0, paddingBottom: 5 }}>
                    <DomainColorBar color={col} />
                    <span style={{ marginLeft: 4, paddingRight: 10 }}>{lab}</span>
                </div>
            })
            info = uniqueLabels.sort().join(', ');
        } else if (item.domains && item.domains[0] === '_ALL') {
            info = 'All domains';
        }

        let gradientString = ''
        let size = 100 / coloredDomains.length
        coloredDomains.length > 0 && coloredDomains.forEach((col, i) => {
            gradientString += `${col} ${size * i}%,${col} ${size * (i + 1)}% `
            if (i < coloredDomains.length - 1) {
                gradientString += ','
            }
        })

        const tooltipID = `tt-${item.term}-${item.domains?.join('-')}`.replace(/[^a-zA-Z0-9_-]/g, '_');

        return <div>
            {info && info !== 'All domains' ?
                <div className="grid search-field-item-with-domain-bar">
                    {coloredDomains.length > 0 ?
                        <div className="search-field-item-domain-bar"
                            style={{ background: `linear-gradient(${gradientString})` }}
                        >
                        </div>
                        : null
                    }
                    <div className={`search-field-item-content ${tooltipID}`}>
                        <Tooltip className="whiteTooltip" target={`.${tooltipID}`} position={'bottom'}>
                            <div>
                                <div>
                                    Open in domain explorer:
                                </div>
                                <div style={{ paddingTop: 4, paddingBottom: 7, fontWeight: 'bold' }}>{item.term}</div>
                                <div>{coloredInfo}</div>
                            </div>
                        </Tooltip>
                        <a className="noStyleLink"
                            //title={`Open in domain explorer: ${item.term}`}
                            onClick={(e) => { this.onOpenDomainExplorerToSpecifyTerm(item) }}>
                            <div style={{ fontWeight: 'bold' }}
                                className="clampingContainer line-clamp line-clamp-1-line">
                                {item.term}
                            </div>
                            {info ?
                                <div style={{ opacity: 0.7, fontSize: '0.8em' }}>{info}</div>
                                : null
                            }
                        </a>
                    </div>
                </div>
                :
                <React.Fragment>
                    <div style={{ fontWeight: 'bold' }}
                        className="clampingContainer line-clamp line-clamp-1-line">
                        {item.term}
                    </div>
                    {info ?
                        <div style={{ color: '#818181', fontSize: '0.8em' }}>{info}</div>
                        : null
                    }
                </React.Fragment>
            }
        </div>;
    }


    suggestionTemplate(item) {
        //console.log('item: ', item);
        let term = item.term;
        let coloredDomains
        let info = '';
        if (item.domains) {
            const uniqueLabels = item.domains.map(dom =>
                this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                    this.props.domainLabelsMap[dom] : dom);
            coloredDomains = uniqueLabels.sort().map((lab, i) => {
                let color = this.props.domainColors && this.props.domainColors.find(obj => {
                    return obj.label === lab
                })
                const col = color?.color ? `#${color.color}` : '';
                return <React.Fragment key={`${lab}-${i}`}>
                    <DomainColorBar color={col} />
                    <span style={{ marginLeft: 4, paddingRight: 10 }}>{lab}</span>
                </React.Fragment>
            })
            info = uniqueLabels.sort().join(', ');
        }

        let prefName = '';
        const prefnamesUnique = item.prefnames ? deduplicateTerms(item.prefnames) : [];
        if (prefnamesUnique && prefnamesUnique.length === 1 && normalizeConceptTerm(item.prefnames[0], true) !== normalizeConceptTerm(term, true)) {
            prefName = ` (${item.prefnames[0]})`;
        }
        let termFormatted = term;
        const match = findMatchIgnoreCase(term, this.state.inputText);
        if (match) {
            termFormatted = termFormatted.replace(match, `<span style="font-weight: normal">${match}</span>`);
        }
        termFormatted += `<span style="font-weight: normal">${prefName}</span>`;

        const cutOffOtherTerms = item.otherTerms ? [...item.otherTerms].splice(0, 2) : [];

        return <div key={item.term} className='grid' style={{ paddingRight: 20 }}>
            <div className="col-6"
                style={{ padding: '0 5px' }}
                title={item.term}>
                <span style={{ fontWeight: 'normal' }}>
                    <span className="clampingContainer line-clamp line-clamp-1-line"
                        dangerouslySetInnerHTML={{ __html: termFormatted }}></span>
                    {!isArrayEmpty(item.otherTerms) ?
                        <span className="secondaryInfo clampingContainer line-clamp line-clamp-1-line"
                            style={{ fontWeight: 'normal', display: 'block' }}
                            title={item.otherTerms.join(' · ')}
                        >{cutOffOtherTerms.join(' · ')}{item.otherTerms.length > 2 ? ' ...' : ''}
                        </span> : null
                    }
                </span>
            </div>
            <div className="col clampingContainer line-clamp line-clamp-1-line"
                style={{ padding: '0 5px' }}>
                {info ?
                    <div className="grid" style={{ //opacity: 0.7, margin: 0
                        //opacity: 0.7,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', margin: 0, color: '#818181'
                    }}
                        title={info}>
                        {coloredDomains !== undefined ? coloredDomains : info}</div>
                    : null
                }
            </div>
            <div className="col-fixed"
                style={{ padding: '0 5px 0 10px' }}>
                <a title="Specify concepts"
                    className="primaryLink showOnHover"
                    onClick={(e) => {
                        //stopEventPropagation(e);
                        this.onOpenDomainExplorerToSpecifyTerm(item);
                    }}
                >Specify</a>
            </div>
            <div className="breakRow" />
        </div>;
    }

    // ----------------------------------------------------------------------- //
    // --- domain explorer --------------------------------------------------- //
    // ----------------------------------------------------------------------- // 
    onOpenDomainExplorerToSpecifyTerm = (item) => {

        this.setState({
            isLeftField: item.isLeftField,
            initialSearchTerm: isArrayEmpty(item.ocids) && item.term,
            initialOcids: !isArrayEmpty(item.ocids) && item.ocids,
            replaceItem: item
        }, () => this.setState({ domainExplorerVisible: true }));
    }

    onOpenDomainExplorer = (isLeftField) => {
        this.setState({
            domainExplorerVisible: true,
            isLeftField: isLeftField
        });
    }


    onDomainExplorerClose = () => {
        this.setState({
            domainExplorerVisible: false,
            initialSearchTerm: null,
            initialOcids: null,
            replaceItem: null
        });
    }

    onDomainExplorerSubmit = (concepts) => {
        //console.log('concepts: ', concepts);

        const replaceItem = this.state.replaceItem;
        this.setState({
            domainExplorerVisible: false,
            replaceItem: null
        });

        if (concepts && !isArrayEmpty(Object.values(concepts))) {
            let queryTerms;
            if (replaceItem) {
                queryTerms = replaceQueryTermWithConcepts(concepts, this.props.queryTerms, replaceItem);
            }
            else {
                queryTerms = addConceptsToQueryTerms(concepts, this.props.queryTerms, true);
            }

            this.props.handleInputChange(queryTerms);
        }
    }


    render() {

        const { queryTerms, restrictions, placeholder, appendSuggestionsTo } = this.props;

        const domains = restrictions && restrictions.domains && this.props.availableDomains ?
            this.props.availableDomains.filter(dom => restrictions.domains.includes(dom.value)) :
            this.props.availableDomains;

        const presets = restrictions && restrictions.subtrees ?
            restrictions.subtrees.filter(subtree => !!subtree.isPreset) : [];
        const searchable = restrictions && restrictions.subtrees ?
            restrictions.subtrees.filter(subtree => !!subtree.isSearchable) : [];


        //console.log('restrictions: ', restrictions);
        //console.log('presets: ', presets);
        //console.log('domains: ', domains);

        return (
            <>
                <div className="grid">
                    {isArrayEmpty(searchable) && isArrayEmpty(presets) ?
                        <div className="col"></div> : null
                    }
                    {!isArrayEmpty(searchable) ?
                        <div className="col textAlignLeft">
                            <div style={{ marginTop: 5, marginRight: 10 }}
                                className="clampingContainer line-clamp line-clamp-1-line"
                                title={searchable.map(subtree => subtree.label).join(', ')}>
                                Available: {searchable.map(subtree => subtree.label).join(', ')}
                            </div>
                        </div>
                        : null
                    }
                    {!isArrayEmpty(presets) ?
                        <>
                            <div className="col textAlignLeft clampingContainer line-clamp line-clamp-1-line">
                                <a title="Click to see all available presets"
                                    className="secondaryLink"
                                    style={{ display: 'inline-block', margin: '5px 10px 5px 0', fontWeight: 'bold' }} // 
                                    onClick={() => {
                                        this.setState({ presetSelectionVisible: true });
                                    }}
                                >Presets
                                    <span className="material-icons" style={{ fontSize: 15, position: 'relative', top: 3 }}>
                                        keyboard_arrow_down</span>
                                    :</a>
                                {presets.map(preset => {
                                    return <span key={preset.label}>
                                        <a className="secondaryLink"
                                            title={preset.term}
                                            style={{ display: 'inline-block', margin: '5px 10px 5px 0', fontWeight: 'bold' }} // 
                                            onClick={e => {
                                                this.handleAddPreset(preset);
                                                this.setState({ presetSelectionVisible: false })
                                            }} >{preset.label}{!!preset.isSingleInput ? WILDCARD_ASTERISK : ''}</a>
                                    </span>
                                })/**/}
                            </div>
                        </> : null
                    }
                    <div className="col-fixed textAlignRight" style={{ marginTop: 2 }}>
                        <a title="Browse domains and add concepts to your search"
                            style={{ display: 'inline-block', margin: '5px 0' }} //, float: 'right'
                            onClick={() => this.onOpenDomainExplorer()}
                        //onClick={() => this.props.onAddConcept()}
                        >Domain explorer</a>
                    </div>
                </div>

                <InputFieldWithClear
                    onClear={() => this.handleInputChange([])}
                    showIcon={!isArrayEmpty(queryTerms)}>
                    <AutoComplete
                        key={this.state.key}
                        value={queryTerms}
                        className="width100perc increasedHeight"
                        field="term"
                        suggestions={this.state.suggestions}
                        completeMethod={this.autocomplete}
                        placeholder={placeholder}
                        minLength={1}
                        multiple={true}
                        onChange={e => this.handleInputChange(e.value)}
                        onSelect={e => this.handleInputSelect(e.value)}
                        onUnselect={(e) => { this.setState({ key: `inputField_${Math.floor(Math.random() * 10000)}` }) }}
                        selectedItemTemplate={this.searchTermTemplate}
                        itemTemplate={this.suggestionTemplate}
                        appendTo={appendSuggestionsTo ? appendSuggestionsTo : ''}
                        //disabled={this.props.disableOnPreset && !isArrayEmpty(this.state.queryTerms) && this.state.queryTerms[0].isPreset}
                        disabled={this.props.queryTerms?.some(qt => qt.isSingleInput)}
                        autoHighlight={true}
                    />
                </InputFieldWithClear>

                <OntologyBrowserDialog
                    //ontBrowserDialogID="coocDomExplorerDlg"
                    headerLabel="Domain explorer"
                    selectionMode="multiple"
                    placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                    placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                    domains={domains}
                    numOfChildNodes={10} // not used for preloaded ontology
                    allowConceptSearchByClick={true}
                    allowSearchInOntologies={true}
                    loadOntologiesOnStart={true}
                    initialSearchTerm={this.state.initialSearchTerm}
                    initialOcids={this.state.initialOcids}
                    ontBrowserVisible={this.state.domainExplorerVisible}
                    onOntBrowserClose={this.onDomainExplorerClose}
                    onOntBrowserSubmit={this.onDomainExplorerSubmit}
                    onOntBrowserShow={() => { }}
                    width="90vw"
                    height="90vh"
                />

                <Dialog
                    focusOnShow={false}
                    header={'Select preset'}
                    visible={this.state.presetSelectionVisible}
                    //style={{ width: width, height: height }}
                    modal={true}
                    onHide={(e) => this.setState({ presetSelectionVisible: false })}
                >
                    <ul className="noStyleList" style={{ lineHeight: 2, paddingLeft: 20, paddingRight: 20 }}>
                        {presets.map(preset => {
                            return <li key={preset.label}>
                                <a onClick={e => {
                                    this.handleAddPreset(preset);
                                    this.setState({ presetSelectionVisible: false })
                                }} >{preset.label}</a>
                            </li>
                        })}
                    </ul>
                </Dialog>
            </>
        );
    }
}

export default CorrelationsInputField;