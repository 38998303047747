import loginBGImage from '../../assets/images/bg/dsm_bg.jpg';
import appLogo from '../../assets/images/logos/dsm/logo-dsm-mobile.png';

export const APP_PROPERTIES = {
  // --- logos for login page and header --- //
  LOGIN_BG_IMAGE: loginBGImage,

  HEADER_LOGO_APP: appLogo,
  HEADER_LOGO_APP_STYLE: { height: 40, padding: '5px 15px' },

  // --- default page loaded after login if no other page is set via admin GUI --- //
  HOME_PAGE: '/analytics/cooccurrences',
  SAML_URL: 'https://dsm.ontochem.com:8443/mw-dsm/auth/login',

  // --- feedback and support --- //
  SUPPORT_FORM_URL:
    'https://forms.monday.com/forms/9885b74fd4ab28927c06d72fc877c869?r=use1',

  // --- active pages --- //
  ACTIVE_PAGES: {
    docSearch: true,
    verificationPage: true,
    backendAdministration: true,
  },

  // --- active modules or functionalities --- //
  ACTIVE_FUNCTIONALITIES: {
    showDocumentsOnStart: true,
    fetchSortableFields: true,
    showJournalFilters: true,
    showClinicalTrialFilters: true,
  },

  // --- chemistry-related settings --- //
  CHEMISTRY: {
    showGoogleLinks: true,
  },
} as const;
