import * as ActionTypes from './index'
import * as BiomarkerApi from '../../api/content/BiomarkerApi';
import {prepareMetaData} from "../../components/common/helpers/prepareMetaData";
import {fetchMetaData} from "../../api/content/BiomarkerApi";

export const toggleBiomarkerIsFetching = isFetching => ({type: ActionTypes.BIOMARKER_TOGGLE_IS_FETCHING, payload: isFetching});

export const setReposWithBiomarkerSearchFeature = repoList => ({
    type: ActionTypes.BIOMARKER_SET_REPOS_WITH_BIOMARKER_SEARCH_FEATURE,
    payload: repoList
});

export const setBiomarkerSelectedReposForSearch = repoList => ({
    type: ActionTypes.BIOMARKER_SET_SELECTED_REPOS_FOR_SEARCH,
    payload: repoList
});

const setMetaData = (metaData) => ({type: ActionTypes.BIOMARKER_SET_METADATA, payload: metaData});

export const searchBiomarkers = (data, repoNameAndIdMap, showToast) => async (dispatch) => {
    try {
        dispatch(toggleBiomarkerIsFetching(true));

        const biomarkers = await BiomarkerApi.fetchSearchResults(data);
        // filter results with doc_id and find it's srcrep => [{repoName: docId}, {repoName: docId}, ...]
        const metadata = prepareMetaData(biomarkers.payload.relationResults);

        // add repo id
        for (let key in metadata) {
            metadata[key] = {...metadata[key], repoId: repoNameAndIdMap[key].id};
        }
        const metadataRequests = Object.keys(metadata).map(item => fetchMetaData(metadata[item]));
        const info = await Promise.allSettled(metadataRequests);
        const apiMetadata = info.filter(({status}) => status === 'fulfilled')
            .map(result => result.value.payload.documents);

        // combine all arrays into one
        const combinedApiMetaData = apiMetadata.reduce((acc, curVal) => [...acc, ...curVal], []);

        dispatch(setMetaData(combinedApiMetaData));
        dispatch(setBiomarkerSearchResults(biomarkers.payload));
    } catch (e) {
        showToast(`Something went wrong while searching`, 'Error occurred', 'error', 10000);
    } finally {
        dispatch(toggleBiomarkerIsFetching(false));
    }
};

export const setBiomarkerSearchResults = results => ({
    type: ActionTypes.BIOMARKER_SET_SEARCH_RESULTS,
    payload: results
});

export const setBiomarkerQueryTerms = queryTerms => ({
    type: ActionTypes.BIOMARKER_SET_QUERY_TERMS,
    payload: queryTerms
});

export const setPopupExpandedKeys = (keys) => ({
    type: ActionTypes.BIOMARKER_SET_POPUP_EXPANDED_KEYS, payload: keys
});

export const setPopupTreeName = (name) => ({
    type: ActionTypes.BIOMARKER_SET_POPUP_TREE_NAME, payload: name
});

export const setBiomarkerLeftAsideExpandedKeys = (keys) => ({
    type: ActionTypes.BIOMARKER_SET_LEFT_ASIDE_EXPANDED_KEYS, payload: keys
});

export const setBiomarkerCheckedResults = (checkedResults) => ({
    type: ActionTypes.BIOMARKER_SET_CHECKED_RESULTS, payload: checkedResults
});

export const setBiomarkerRoleMapping = (roleMapping) => ({type: ActionTypes.BIOMARKER_SET_ROLE_MAPPING, payload: roleMapping});

export const setBiomarkerNodes = (nodes) => ({type: ActionTypes.BIOMARKER_SET_NODES, payload: nodes});

export const setPopupNodes = (nodes) => ({type: ActionTypes.BIOMARKER_SET_POPUP_NODES, payload: nodes});

export const setBiomarkerHeadersStructureSkeleton = (skeleton) => ({
    type: ActionTypes.BIOMARKER_SET_HEADERS_STRUCTURE_SKELETON,
    payload: skeleton
});

export const setBiomarkerSuggestions = (suggestions) => ({
    type: ActionTypes.BIOMARKER_SET_SUGGESTIONS,
    payload: suggestions
});

export const setBiomarkerInputText = (text) => ({
    type: ActionTypes.BIOMARKER_SET_INPUT_TEXT,
    payload: text
});

export const setBiomarkerSortField = (field) => ({
    type: ActionTypes.BIOMARKER_SET_SORT_FIELD,
    payload: field
});

export const setBiomarkerSortDirection = (direction) => ({
    type: ActionTypes.BIOMARKER_SET_SORT_DIRECTION,
    payload: direction
});

export const setBiomarkerFirstResultIndex = (index) => ({
    type: ActionTypes.BIOMARKER_SET_FIRST_RESULT_INDEX,
    payload: index
});

export const setBiomarkerResultCount = (count) => ({
    type: ActionTypes.BIOMARKER_SET_RESULT_COUNT,
    payload: count
});

export const resetBiomarkerState = () => ({
    type: ActionTypes.BIOMARKER_RESET_STATE,
});

export const setBiomarkerIsFirstRender = (isFirstRender) => ({
    type: ActionTypes.BIOMARKER_SET_IS_FIRST_RENDER,
    payload: isFirstRender
});


