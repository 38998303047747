import React from "react";
import {
  SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL,
  SEARCH_FILTER_ID_SEARCH_MODE_LOCAL,
  SEARCH_FILTER_ID_TERMLOC,
} from "../../../../../../general/docsearch/searchConstants";
import SearchFilterIDConceptDistanceLocal from "../../../../../../../common/queryBuilder/SearchGroupCriteriaEditor/SearchGroupFilterts/SearchFilterIDConceptDistanceLocal/SearchFilterIDConceptDistanceLocal";
import SearchFilterIDSearchModeLocal from "../../../../../../../common/queryBuilder/SearchGroupCriteriaEditor/SearchGroupFilterts/SearchFilterIDSearchModeLocal/SearchFilterIDSearchModeLocal";
import SearchFilterTypeTermlocContainer from "./SearchFilterTypeTermlocContainer";

const SearchGroupFilters = ({ filter, selectedEntry, updateSearchCriteria }) => {
  const filterID = filter.id;
  if (filterID === SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL) {
    const currentValue = selectedEntry?.filterValue ?? null;
    return (
      <SearchFilterIDConceptDistanceLocal
        value={currentValue}
        onClear={() => updateSearchCriteria(filterID, null)}
        onChange={(e) => {
          const filterValueNew =
            e.value >= 0
              ? {
                  filterID,
                  value: {
                    filterValue: e.value,
                    filterValueLabel: `Max. distance of ~${e.value} words`,
                  },
                }
              : null;
          updateSearchCriteria(filterID, filterValueNew);
        }}
      />
    );
  }
  if (filterID === SEARCH_FILTER_ID_SEARCH_MODE_LOCAL) {
    return (
      <SearchFilterIDSearchModeLocal
        filter={filter}
        onChange={(filterObject) => updateSearchCriteria(filterID, filterObject)}
        selectedEntry={selectedEntry}
      />
    );
  }
  if (filterID === SEARCH_FILTER_ID_TERMLOC) {
    return (
      <SearchFilterTypeTermlocContainer
        filter={filter}
        onChange={(filterObject) => updateSearchCriteria(filterID, filterObject)}
        selectedEntry={selectedEntry}
      />
    );
  }

  return <div>Filter not available</div>;
};

export default SearchGroupFilters;
