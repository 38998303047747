export const BUTTON_OK = 'ok';
export const BUTTON_CANCEL = 'cancel';
export const BUTTON_CLOSE = 'cancel';
export const BUTTON_RUN_DEFAULT_SEARCH = 'default_search';


export const ERROR_TYPE_EMPTY_SEARCH = {
    id: 'empty_search',
    problem: 'Empty search',
    info: 'Please provide an identifier or a structure.',
    buttons: { [BUTTON_OK]: true }
};
export const ERROR_TYPE_CLASS_AND_COMPOUND_QUERY_TERM_MIXED = {
    id: 'class_compound_term_mix',
    problem: 'Input found that is both a chemical class and compound',
    info: 'Please specify if you would like to search for chemical compounds or classes.',
    //buttons: { [BUTTON_OK]: true }
};
export const ERROR_TYPE_CLASS_AND_COMPOUND_SEARCH_MIXED = {
    id: 'class_compound_mix',
    problem: 'Chemical classes and compounds found',
    info: 'Please make sure that your search contains either only chemical compounds or chemical classes. Searching for both at the same time is not possible. In some cases an input term can be both a compound and a class, then please specify what you are looking forby clicking on the box and selecting one of the options.',
    buttons: { [BUTTON_OK]: true }
};
export const ERROR_TYPE_CLASS_AND_COMPOUND_CONCEPT_MIXED = {
    id: 'class_compound_concept_mix',
    problem: 'Input is chemical class and compound',
    info: 'Please specify if you would like to search for the chemical compound or class.',
    buttons: { [BUTTON_OK]: true }
};
export const ERROR_TYPE_CHEM_CLASS_SEARCH_NOT_SUPPORTED = {
    id: 'unsupported_chem_class_search',
    problem: 'Search by chemical classes not supported',
    info: 'Searching by chemical classes is currently not supported for this database. Please choose a specific compound instead. ',
    buttons: { [BUTTON_CLOSE]: true }
};
export const ERROR_TYPE_AMBIGUOUS_COMPOUNDS_NOT_SUPPORTED = {
    id: 'unsupported_ambiguous_compounds',
    problem: 'Multiple structures found for input',
    info: 'Choose a specific compound instead.',
    buttons: { [BUTTON_OK]: true }
};

export const ERROR_TYPE_CHEM_CLASS_SEARCH_SETTINGS_NOT_SUPPORTED = {
    id: 'unsupported_settings',
    problem: 'Search settings not supported for chemical classes',
    info: 'If your input consists of chemical classes you can only search for exact compounds. You can either change the settings to exact compound search or use your current settings with a specific chemical compound instead.',
    buttons: { [BUTTON_OK]: true, [BUTTON_RUN_DEFAULT_SEARCH]: true }
};
//
export const ERROR_TYPE_MISSING_OCIDS = {
    id: 'missing_ocids',
    problem: 'At least one chemical class does not have any OCIDs',
    buttons: { [BUTTON_OK]: true }
};
export const ERROR_TYPE_AMBIGUOUS_COMPOUNDS = {
    id: 'ambiguous_compounds'
};