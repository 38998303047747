import React, {useEffect, useRef, useState} from 'react';
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import styles from "./BioactivitiesFiltersPopup.module.scss";
import {dummyPopupData} from "./dummyPopupData";
import {InputText} from "primereact/inputtext";
import './BioactivitiesFiltersPopup.css';
import {Button} from "primereact/button";

const BioactivitiesFiltersPopup = ({visible, setShowPopup, popupHeader}) => {
    const [data, setData] = useState(null);
    const [selectedResults, setSelectedResults] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    useEffect(() => setData(dummyPopupData), []);

    const renderTableHeader = () => {
        return (
            <div className={styles.tableHeader}>
                <span className="p-input-icon-right">
                    <i className="pi pi-search"/>
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)}/>
                </span>
            </div>
        );
    }

    const preferredNameTemplate = ({preferredName}) => <p>{preferredName}</p>
    const originalNameTemplate = ({originalName}) => <p>{originalName}</p>
    const tableHeader = renderTableHeader();

    const onHide = () => {
        setShowPopup(false);
        setGlobalFilter(null);
    };

    const popupFooter = (
        <div className={styles.footer}>
            <Button label="Close" className="p-button-text p-button-plain" onClick={onHide}/>
            <Button label="Filter" className="p-button-text p-button-plain" onClick={() => console.log('FILTER')}/>
            <Button label="Exclude" className="p-button-text p-button-plain" onClick={() => console.log('EXCLUDE')}/>
        </div>
    );

    return (
        <Dialog visible={visible} onHide={onHide} blockScroll dismissableMask
                breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}}
                header={popupHeader} footer={popupFooter} appendTo='self'>
            <DataTable id='bioactivities-popup-table' ref={dt} value={data} globalFilter={globalFilter}
                       dataKey="id" rowHover selection={selectedResults}
                       onSelectionChange={e => setSelectedResults(e.value)}
                       header={tableHeader}
            >

                <Column selectionMode="multiple" className={styles.selection}/>
                <Column header="Preferred name" body={preferredNameTemplate} filterField='preferredName'/>
                <Column header="Original name" body={originalNameTemplate} filterField='originalName'/>
            </DataTable>
        </Dialog>
    );
};

export default BioactivitiesFiltersPopup;
