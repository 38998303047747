import React from "react";
import { COMPOUND_PROPERTIES } from "../../../chemistry/results/compounds/compoundProperties";
import { COMPOUND_PROPERTY_OCID } from "../../../chemistry/utils/chemistryResults";
import CellData from "../../../general/tabledata/CellData";
import { isArrayEmpty } from "../../../util";


const Identifiers = ({ extendedConceptData }) => {

  const identifiersDefinitions = COMPOUND_PROPERTIES.filter(propDef =>
    propDef.id !== COMPOUND_PROPERTY_OCID && propDef.isIdentifier &&
    extendedConceptData && extendedConceptData[propDef.id]);

  return !isArrayEmpty(identifiersDefinitions) ?
    <div className="grid small-padding-tb">
      {identifiersDefinitions.map(identDef => {
        return extendedConceptData[identDef.id] ? <React.Fragment key={identDef.label}>
          <div className={`col-fixed breakWord`}
            style={{ width: 90 }}>
            {identDef.label}
          </div>
          <div className='col'>
            <CellData
              cropContent={true}
              allowCopy={true}
              content={extendedConceptData[identDef.id]}
              noPaddingLeft={false}
            />
          </div>
          <div className="breakRow"></div>
        </React.Fragment> : null
      })}
    </div>
    :
    <span>No entries</span>
}

export default Identifiers;