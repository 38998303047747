import React, { Component, createRef } from "react";
import { Tree } from "primereact/tree";
import "./OntologyBrowser.css";
import { Toast } from "primereact/toast";

// --- toggle state constants --- //
export const TOGGLE_TREE_COLLAPSE = 'COLLAPSE';
export const TOGGLE_TREE_EXPAND = 'EXPAND';
export const TOGGLE_TREE_NONE = 'NONE';
export const TOGGLE_TREE_EXPAND_FIRST_LEVEL = 'EXPAND_LEVEL_1';

class OntologyTree extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nodes: [],
      ocidsKeysMap: {},
      expandedKeys: {},
      selectedNodeKeys: null,
      loading: false,
      selectedNodeKeys3: null
    }

    this.nodeTemplate = this.nodeTemplate.bind(this);

    // --- store references for all claim texts (dynamically) --- //
    this.ontRefsOcids = {};

    this.growl = createRef();
  }

  /**
   * Update tree when component is mounted, e.g. toggling and selecting nodes.
   */
  componentDidMount = () => {
    this.updateTree(this.props);
  }

  /**
   * Update tree when component receives new properties, e.g. toggling and selecting nodes.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateTree(nextProps);
  }


  /**
   * Update tree, e.g. toggling and selecting nodes.
   * 
   * @param {Object} props properties
   */
  updateTree = (props) => {
    const { nodes, toggleAction, selectedConcept, jumpToElement } = props; // loading

    // --- collect all OCIDs and the respective nodes in a map  --- //
    const ocidsKeysMap = this.collectOcidsKeysMap(nodes, {});

    // --- collect all node keys with this OCID to select these nodes in the tree --- //
    let selectedKeys = {};
    if (selectedConcept) {
      selectedKeys = this.collectKeysOfNodesWithOcid(selectedConcept.ocid, this.state.ocidsKeysMap);
    }

    // --- update selected concept and selected nodes in tree --- //
    this.setState({
      nodes: nodes,
      ocidsKeysMap: ocidsKeysMap,
      selectedNodeKeys: selectedKeys
    });

    // --- expand all nodes --- //
    if (toggleAction === TOGGLE_TREE_EXPAND) {
      this.toggleAllNodes(true, nodes);
    }
    // --- collapse all nodes --- //
    else if (toggleAction === TOGGLE_TREE_COLLAPSE) {
      this.toggleAllNodes(false);
    }
    else if (toggleAction === TOGGLE_TREE_EXPAND_FIRST_LEVEL) {
      this.expandFirstLevel(nodes);
    }
    // --- reset toggle action to 'do nothing' --- //
    this.props.changeToggleAction(TOGGLE_TREE_NONE);

    //console.log('jumpToElement');
    //console.log(jumpToElement);

    this.setState({
      jumpToElement: jumpToElement
    });

    if (jumpToElement && jumpToElement !== this.state.jumpToElement) {
      this.handleScrollToElement(jumpToElement);
      //console.log('scroll to ' + jumpToElement);
    }
  }

  /**
   * Updates expanded keys if a node is expanded or collapsed.
   */
  onNodeToggle = (event) => {
    this.setState({
      expandedKeys: event.value
    });
  }

  /**
    * Expands or collapses all nodes.
    */
  toggleAllNodes = (expand, nodes) => {
    // --- collect keys of all nodes --- //
    let nodeKeys = {};
    if (expand) {
      nodeKeys = this.collectNodeKeys(nodes, {});
    }
    // --- update expanded node keys --- //
    this.setState({
      expandedKeys: nodeKeys
    });
    /* let expandedKeys = { ...this.state.expandedKeys };
    if (expandedKeys['2']) delete expandedKeys['2'];
    else expandedKeys['2'] = true; */
  }

  /**
    * Expands all nodes on first level.
    */
  expandFirstLevel = (nodes) => {
    // --- collect keys of all first level nodes --- //
    let nodeKeys = this.collectNodeKeysFirstLevel(nodes, {});
    // --- update expanded node keys --- //
    this.setState({
      expandedKeys: nodeKeys
    });
  }

  /**
   * Collects keys of all nodes in the current tree and returns 
   * them in a map where keyMap[key] == true.
   */
  collectNodeKeys = (nodes, keyMap) => {
    if (nodes) {
      for (const node of Object.values(nodes)) {
        keyMap[node.key] = true;
        if (node.children) {
          this.collectNodeKeys(node.children, keyMap);
        }
      }
    }
    return keyMap;
  }


  /**
   * Collects keys of all first-level nodes in the current tree and returns 
   * them in a map where keyMap[key] == true.
   */
  collectNodeKeysFirstLevel = (nodes, keyMap) => {
    if (nodes) {
      for (const node of Object.values(nodes)) {
        keyMap[node.key] = true;
      }
    }
    return keyMap;
  }


  /**
   * If a node is expanded all of its children will be fetched if they aren't already.
   */
  onNodeExpand = (event) => {
    if (!event.node.children) {
      this.loadChildNodes(event.node, 0);
    }
  }

  /**
   * If a node is selected the callback function will be called with the selected node.
   */
  onNodeSelect = (event) => {
    if (event.node.type !== 'nav') {
      this.props.onNodeSelect(event.node);
    }
  }

  /**
   * Updates selected node keys when node is clicked.
   */
  onNodeSelectionChange = (event) => {
    Object.keys(event.value).forEach(key => {
      // --- do not select children paging nodes --- //
      if (key && !key.match(/nav_.*/)) {
        this.setState({ selectedNodeKeys: event.value });
      }
    })
  }

  /**
   * Collects keys of all nodes in the current tree with a certain OCID 
   * and returns them in a map where keyMap[key] === true.
   */
  collectKeysOfNodesWithOcid = (ocid, ocidsKeysMap) => {
    const keyMap = {};
    if (ocidsKeysMap[ocid]) {
      for (const nodeKey of ocidsKeysMap[ocid]) {
        keyMap[nodeKey] = true;
      }
    }
    return keyMap;
  }

  /**
   * Collects keys of all nodes in the current tree with a certain OCID 
   * and returns them in a map where ocidMap[ocid] === [keys].
   */
  collectOcidsKeysMap = (nodes, ocidMap) => {
    if (nodes) {
      for (const node of Object.values(nodes)) {
        if (node.data) {
          if (!ocidMap[node.data.ocid]) {
            ocidMap[node.data.ocid] = [];
          }
          ocidMap[node.data.ocid].push(node.key);
        }
        if (node.children && node.children.length > 0) {
          this.collectOcidsKeysMap(node.children, ocidMap);
        }
      }
    }
    return ocidMap;
  }

  /**
   * Enriches children of node with some additional data, e.g. prefname and whether it has children or not.
   * Offset used for paging to handle nodes with many children.
   */
  loadChildNodes = async (node, offset) => {
    if (node) {
      //console.log(node)
      this.setState({ loading: true });

      // --- fetch concept data incl. children --- //
      let concept = null;
      if (node.data) {
        concept = await this.props.ocidToConcept(node.data.ocid, false, offset);
      }

      //console.log(concept);

      // --- add children to tree node --- //
      let nodes = [...this.state.nodes];
      if (concept !== null) {
        const children = [];

        let navNodeTop = null;
        // --- add children paging node if node has too many children (top) --- //
        if (concept.childrenCount > this.props.numOfChildNodes) {
          navNodeTop = {
            key: 'nav_top_' + node.key,
            type: 'nav',
            parentOcid: node.data.ocid,
            parentNode: node,
            count: concept.childrenCount,
            offset: offset,
            hasPrev: (offset > 0),
            hasNext: (concept.childrenCount > (this.props.numOfChildNodes + offset))
          };
          //children.push(navNodeTop);
        }

        // --- add children --- //
        let childCounter = offset + 1;
        for (const child of concept.children) {
          const key = node.key + '-' + childCounter++;
          children.push({
            key: key,
            label: child.preferredName,
            leaf: !child.hasChildren,
            data: {
              ocid: child.ocid,
              domain: node.data.domain
            }
          });
        }

        // --- add children paging node if node has too many children (bottom) --- //
        if (navNodeTop) {
          const navNodeBottom = { ...navNodeTop };
          navNodeBottom.key = 'nav_bot_' + node.key;
          children.push(navNodeBottom);
        }

        // --- add all children to node --- //
        node.children = children;
        node.childrenCount = concept.childrenCount;

        // --- replace node with updated node in tree --- //
        var foundIndex = nodes.findIndex(n => n.key === node.key);
        nodes[foundIndex] = node;
      }

      this.setState({
        nodes: nodes,
        loading: false
      });
    }
  }

  /**
   * Tree node template.
   */
  nodeTemplate(node) {
    const labelMaxLength = 50;

    // if (node.key.toString().match(/\-/g) && node.key.toString().match(/\-/g).length === 1) {
    if (node.key.toString().match(/-/g)?.length === 1) {
      if (this.domainColors && node.data?.domain && this.domainColors[node.data.domain]) {
        node.style = { borderLeft: '2px solid', borderColor: '#' + this.domainColors[node.data.domain] }
      }
    }

    // --- node for children paginator --- //
    if (node.type && node.type === 'nav') {

      // --- cut off if maximum number of children is below next offset maximum --- //
      let maxNum = node.offset + this.props.numOfChildNodes;
      maxNum = maxNum > node.count ? node.count : maxNum;

      return (
        <span className="childNavigation">
          {
            node.hasPrev ?
              <span
                className="link"
                onClick={() => this.loadChildNodes(node.parentNode, (node.offset - this.props.numOfChildNodes))}
                style={{ textAlign: 'left', margin: "20px" }}
                title="Load previous nodes">
                {"<< Previous"}
              </span> : null
          }
          {
            node.hasPrev || node.hasNext ?
              <span>{`${(node.offset + 1)}-${maxNum}/${node.count}`}</span> : null
          }
          {
            node.hasNext ?
              <span
                className="link"
                onClick={() => this.loadChildNodes(node.parentNode, (node.offset + this.props.numOfChildNodes))}
                style={{ textAlign: 'left', margin: "0 5px 0 20px" }}
                title="Load next nodes">
                {"Next >> "}
              </span> : null
          }
        </span>
      )
    }
    // --- default node --- //
    else {
      if (node.isFirst) {
        return (
          <span ref={ref => { this.ontRefsOcids[node.data.ocid] = ref; }}
            title={`${node.label?.length > labelMaxLength ? node.label : ''}`}>
            {node.label ? node.label.substring(0, labelMaxLength) : '[unknown]'}{node.label?.length > labelMaxLength ? '...' : ''}
          </span>
        )
      }
      else {
        return (
          <span title={`${node.label?.length > labelMaxLength ? node.label : ''}`}>
            {node.label ? node.label.substring(0, labelMaxLength) : '[unknown]'}{node.label?.length > labelMaxLength ? '...' : ''}
          </span>
        )
      }
    }

  }


  onDragDrop = (event) => {
    console.log(event);
    console.log(event.detail); // undefined
    console.log(event.originalEvent);
    //console.log(event.nativeEvent); // undefined
    console.log(event.originalEvent.dataTransfer);
    console.log(event.originalEvent.dataTransfer.dropEffect); // move
    console.log(event.originalEvent.dataTransfer.items);
    //console.log(event.originalEvent.dataTransfer.items[1].webkitGetAsEntry()); // undefined
    console.log(event.originalEvent.currentTarget);
    console.log(event.originalEvent.target);

    const newNodes = event.value;
    //const newNodes = [...event.value];

    this.props.onTreeChanged(newNodes);
  }


  /*
    onSelectionChange = (event) => {
      console.log(event);
      this.setState({selectedNodeKeys3: event.value})
    }
  */


  /**
     * Scroll to element with given ID. Location: bottom.
     * 
     * @param {string} id the ID of the element to scroll into view
     */
  handleScrollToElement(ocid) {
    if (this.ontRefsOcids[ocid]) {
      this.ontRefsOcids[ocid].scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  /**
   * 
   */


  render() {
    this.domainColors = {}

    this.props.domainColors && this.props.domainColors.forEach(col => {
      this.domainColors[col.name] = col.color
    })

    return (
      <>
        <Toast ref={this.growl} />

        <div className="ontTree">
          <Tree
            selectionMode="multiple"
            nodeTemplate={this.nodeTemplate}
            value={this.state.nodes}
            loading={this.state.loading}
            expandedKeys={this.state.expandedKeys}
            selectionKeys={this.state.selectedNodeKeys}
            onToggle={this.onNodeToggle}
            onExpand={this.onNodeExpand}
            onSelect={this.onNodeSelect}

          //dragdropScope="framesont" 
          //onDragDrop={event => this.onDragDrop(event)}

          //selectionMode="checkbox" 
          //selectionKeys={this.state.selectedNodeKeys3} 
          //onSelectionChange={e => this.onSelectionChange(e)}     
          />
        </div>
      </>
    )
  }
}

export default OntologyTree;
