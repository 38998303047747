export const fieldListForExportBioactivitiesInXLSX = [
    {
        "name": "details",
        "type": "termFields"
    },
    {
        "name": "biomarker",
        "type": "termFields"
    },
    {
        "name": "gene_variant",
        "type": "termFields"
    },
    {
        "name": "biomarker_target",
        "type": "termFields"
    },
    {
        "name": "drug",
        "type": "termFields"
    },
    {
        "name": "type",
        "type": "termFields"
    },
    {
        "name": "anatomy",
        "type": "termFields"
    },
    {
        "name": "population",
        "type": "termFields"
    },
    {
        "name": "kit",
        "type": "termFields"
    },
    {
        "name": "srcsent",
        "type": "termFields"
    },
    {
        "name": "srcrep",
        "type": "termFields"
    },
    {
        "name": "source_link",
        "type": "termFields"
    },
    {
        "name": "source_date",
        "type": "termFields"
    },
    {
        "name": "docView",
        "type": "termFields"
    },
    {
        "name": "details",
        "type": "conceptFields"
    },
    {
        "name": "biomarker",
        "type": "conceptFields"
    },
    {
        "name": "gene_variant",
        "type": "conceptFields"
    },
    {
        "name": "biomarker_target",
        "type": "conceptFields"
    },
    {
        "name": "drug",
        "type": "conceptFields"
    },
    {
        "name": "type",
        "type": "conceptFields"
    },
    {
        "name": "anatomy",
        "type": "conceptFields"
    },
    {
        "name": "population",
        "type": "conceptFields"
    },
    {
        "name": "kit",
        "type": "conceptFields"
    },
    {
        "name": "srcsent",
        "type": "conceptFields"
    },
    {
        "name": "srcrep",
        "type": "conceptFields"
    },
    {
        "name": "source_link",
        "type": "conceptFields"
    },
    {
        "name": "source_date",
        "type": "conceptFields"
    },
    {
        "name": "docView",
        "type": "conceptFields"
    }
];
