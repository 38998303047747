import { Component } from 'react';
import QueryEditor from '../advancedSearch/components/QueryEditor';


class SearchComponent extends Component {

    constructor() {
        super();
        this.state = {};
    }


    addConcepts = (concepts) => {
        this.queryEditor.addConcepts(concepts);
    }

    render() {

        return (
            <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10 }}>
                <QueryEditor
                    ref={(el) => { this.queryEditor = el }}
                    //initialInput={this.props.queryStringExpertSearch}
                    //
                    activeQueryTabIndex={this.props.activeQueryTabIndex}
                    onActiveQueryTabIndexChange={this.props.onActiveQueryTabIndexChange}
                    advancedQueries={this.props.advancedQueries}
                    onQueryChange={this.props.onQueryChange}
                    onFilterModeChange={this.props.onFilterModeChange}
                    onAddFilterQuery={this.props.onAddFilterQuery}
                    onRemoveFilterQuery={this.props.onRemoveFilterQuery}
                    //
                    onOpenOntologyBrowser={this.props.onOpenOntologyBrowser}
                    domains={this.props.domains}
                    // --- add structure to expert syntax editor --- //
                    onAddStructure={this.props.onAddStructureToExpertSearch}
                    height={400}
                    onClearAll={this.props.onClearAll}
                    reset={this.props.resetQueryEditor}
                />
            </div>
        );
    }
}

export default SearchComponent;