import { isArrayEmpty } from '../../util';
import { createSourcesLinkoutMenuItems } from '../../util/chemSourceLinkouts';
import { createGoogleLinkoutMenuItems } from '../../util/googleLinkouts';
import { createQueryTermForOcid, createQueryTermForSmiles } from './chemistryQueryTerms';


export const createLinkoutsMenuItems = (smiles, sourceReferences) => {

    let items = [];

    if (smiles) {
        const googleItems = createGoogleLinkoutMenuItems(smiles);
        items = items.concat(googleItems);
    };

    if (!isArrayEmpty(sourceReferences)) {
        const sourceReferencesItems = createSourcesLinkoutMenuItems(sourceReferences);
        if (!isArrayEmpty(items)) {
            items.push({ separator: true });
        }
        items = items.concat(sourceReferencesItems);
    };

    return items;
}

export const createAddToSearchMenuItems = (ocid, smiles, onReplaceStructure, onReplaceQuery, allowAddToSearchField) => {

    const items = [];

    if (onReplaceStructure) {
        items.push({
            label: 'Load into editor',
            command: () => {
                onReplaceStructure(smiles)
            }
        });
    }

    if (onReplaceQuery) {
        items.push({
            label: allowAddToSearchField ? 'Replace input in search field' : 'Load into search field',
            command: () => {
                if (ocid) {
                    onReplaceQuery(createQueryTermForOcid(ocid), false);
                }
                else if (smiles) {
                    onReplaceQuery(createQueryTermForSmiles(smiles), false);
                }
            }
        });

        if (allowAddToSearchField) {
            items.push({
                label: 'Add to search field',
                command: () => {
                    if (ocid) {
                        onReplaceQuery(createQueryTermForOcid(ocid), true);
                    }
                    else if (smiles) {
                        onReplaceQuery(createQueryTermForSmiles(smiles), true);
                    }
                }
            });
        }
    }

    return items;
}