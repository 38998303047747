export const addAnnotationVersionToBackends = (backends, repositoriesCatalog) => {

    if (backends && repositoriesCatalog) {
        backends.forEach(backend => {
            const repoCatalog = repositoriesCatalog[backend.id];

            backend.annotationVersion = '-';
            backend.status = '-';

            if (repoCatalog) {
                if (repoCatalog.error) {
                    backend.status = 'Failed';
                    backend.errorMessage = repoCatalog.errorMessage;
                }
                else if (repoCatalog.repositoryData) {
                    let version;
                    for (let repo of repoCatalog.repositoryData) {
                        if (!version && repo.conceptDomainsCatalogMajorVersion) {
                            version = repo.conceptDomainsCatalogMajorVersion;
                            break;
                        }
                    };
                    backend.annotationVersion = version || 'Unknown';
                    backend.status = 'OK';
                }
            }
        })
    }

    return backends;
}


export const addAnnotationVersionToRepositories = (repositories, repositoriesCatalog) => {

    if (repositories && repositoriesCatalog) {
        repositories.forEach(repo => {
            const repoCatalog = repositoriesCatalog[repo.backendId];

            repo.annotationVersion = '-';

            if (repoCatalog) {
                if (repoCatalog.error) {
                    repo.errorMessage = repoCatalog.errorMessage;
                }
                else if (repoCatalog.repositoryData) {
                    const repoData = repoCatalog.repositoryData?.find(repData => repData.name === repo.name);
                    if (repoData) {
                        repo.annotationVersion = repoData.conceptDomainsCatalogMajorVersion || 'Unknown';
                    }
                    /*else {
                        repo.annotationVersion = 'Repo not found';
                    }*/
                }
            }
        })
    }

    return repositories;
}