import { PureComponent } from "react";

import PopupContainer from "./popup/container";

interface Props {
  onClick: (content: JSX.Element, rectIndex: number) => void;
  popupContent: JSX.Element;
  onMouseOut: () => void;
  children: JSX.Element;
  onShowConcept: (ocid: any) => void;
  annTypeLabelsMap: any;

  domainColors: any;
  text: string;
}

interface State {
  mouseIn: boolean;
}

export class Popup extends PureComponent<Props, State> {
  state: State = {
    mouseIn: false,
  };

  onShowConceptDetails = (ocid: any) => {
    this.props.onShowConcept(ocid)
  }

  render() {
    const { onClick, popupContent, onMouseOut, annTypeLabelsMap, text, domainColors } = this.props;
   
    return (
      <div
        onClick={(event) => {
          this.setState({ mouseIn: true });
          onClick(
            <PopupContainer
              onMoveAway={() => {
                if (this.state.mouseIn) {
                  return;
                }
                onMouseOut();
              }}
              paddingX={60}
              paddingY={30}
              children={popupContent}
              onShowConcept={(ocid => this.onShowConceptDetails(ocid))}
              annTypeLabelsMap={annTypeLabelsMap}
              domainColors={domainColors}
              text={text}
            />,
            parseInt((event.target as HTMLElement)?.id?.split('_')[0]) || 0
          );
        }}
        onMouseOut={() => {
          this.setState({ mouseIn: false });
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Popup;
