import { DataView } from 'primereact/dataview';
import { Component } from 'react';
import CompoundResult from './CompoundResult';
import { fetchDocumentsBulk } from '../../../../../api/content/DocumentApi';
import { isArrayEmpty } from '../../../util';
import { createDocCountRequestsForConcepts, extractHitCountsFromResult, extractPatentsHitCountsFromResults } from '../chemistryResultsUtil';
import ConceptDetailsDialog from '../../../conceptdetails/ConceptDetailsDialog';


class CompoundDataView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layout: 'grid',
            visibleCompoundInfo: false,
            compound: {}
        };
    }

    async componentDidMount() {
        // --- update layout of compound results --- //
        window.addEventListener('resize', this.updateLayout);
        this.updateLayout();

        if (!isArrayEmpty(this.props.concepts)) {
            this.fetchDocumentCounts([...this.props.concepts].splice(this.props.start, this.props.pageSize));
        }
    }

    async componentDidUpdate(prevProps) {

        if (prevProps.concepts !== this.props.concepts && !isArrayEmpty(this.props.concepts)) {
            this.fetchDocumentCounts([...this.props.concepts].splice(this.props.start, this.props.pageSize));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLayout);
    }

    updateLayout = () => {
        const width = document.getElementById('compoundResults')?.offsetWidth;
        //console.log('width: ', width);
        let cssClass = 'col-12';
        if (width < 800) { cssClass = 'col-12'; }
        else if (width < 1200) { cssClass = 'col-6'; }
        else if (width < 2000) { cssClass = 'col-4'; }
        else if (width < 3500) { cssClass = 'col-3'; }
        else if (width < 5000) { cssClass = 'col-2'; }

        this.setState({ cssClass: cssClass });
    };

    fetchDocumentCounts = (concepts) => {
        // --- create request objects for bulk document queries --- //
        const { patentRequest, pubRequest } = createDocCountRequestsForConcepts(concepts, this.props.patentsRepository, this.props.publicationsRepository);
        // --- fetch number of matching patents and patent claims for each concept --- //
        if (this.props.patentsRepository && !isArrayEmpty(Object.keys(patentRequest.queries))) {
            fetchDocumentsBulk(patentRequest, [this.props.patentsRepository.id]).then(patentResp => {
                const { patentsMap, claimsMap } = extractPatentsHitCountsFromResults(patentResp, this.props.patentsRepository.id);
                this.setState({ numOfDocsPatents: patentsMap, numOfDocsPatentClaims: claimsMap });
            });
        }
        // --- fetch number of matching publications for each concept --- //
        if (this.props.publicationsRepository && !isArrayEmpty(Object.keys(pubRequest.queries))) {
            fetchDocumentsBulk(pubRequest, [this.props.publicationsRepository.id]).then(pubResp => {
                const publicationsMap = extractHitCountsFromResult(pubResp, this.props.publicationsRepository.id);
                this.setState({ numOfDocsPublications: publicationsMap });
            });
        }
    }

    showCompoundInfo = (ocid) => {
        this.setState({
            displayCompoundInfo: true,
            conceptOcid: ocid
        })
    }


    renderGridItem(data) {

        return (
            <div className={this.state.cssClass}>
                <CompoundResult
                    data={data}
                    docCountPatents={this.state.numOfDocsPatents ? this.state.numOfDocsPatents[data.ocid] : '-'}
                    docCountPatentClaims={this.state.numOfDocsPatentClaims ? this.state.numOfDocsPatentClaims[data.ocid] : '-'}
                    docCountPublications={this.state.numOfDocsPublications ? this.state.numOfDocsPublications[data.ocid] : '-'}
                    patentsRepository={this.props.patentsRepository}
                    publicationsRepository={this.props.publicationsRepository}
                    onReplaceStructure={this.props.onReplaceStructure}
                    onReplaceQuery={this.props.onReplaceQuery}
                    includePreparationsSearch={this.props.includePreparationsSearch}
                    includeAddToSearchFieldOption={this.props.includeAddToSearchFieldOption}
                    preparationsTarget={this.props.preparationsTarget}
                    showCompoundInfo={this.showCompoundInfo}
                    onSelectCompound={this.props.onSelectCompound}
                    isSelected={this.props.selectedCompoundOcids && !!this.props.selectedCompoundOcids[data.ocid]}
                    singleSelection={this.props.singleSelection}
                />
            </div>
        );
    }

    onPage = (event) => {
        // TODO: only if results are new
        this.fetchDocumentCounts([...this.props.concepts].splice(event.first, this.props.pageSize));
        this.props.onPage(event);
    }

    render() {
        const { concepts, totalRecords, start, pageSize, loading, pageLinkSize,
            paginatorTemplate, paginatorLeft, paginatorRight } = this.props;

        const itemTemplate = (item, layout) => {
            //console.log('***** item: ', item);
            if (!item) { return null; }
            if (layout === 'grid') { return this.renderGridItem(item); }
            else { return this.renderGridItem(item); }
        }

        return (
            <>
                <DataView
                    id='compoundResults'
                    className="chemSearchResults"
                    value={concepts}
                    layout={this.state.layout}
                    itemTemplate={itemTemplate}
                    pageLinkSize={pageLinkSize}
                    first={start}
                    rows={pageSize}
                    totalRecords={totalRecords}
                    onPage={this.onPage}
                    alwaysShowPaginator={false}
                    paginator={true}
                    paginatorPosition="bottom"
                    paginatorTemplate={paginatorTemplate}
                    paginatorLeft={paginatorLeft}
                    paginatorRight={paginatorRight}
                    loading={loading}
                />

                <ConceptDetailsDialog
                    visible={this.state.displayCompoundInfo}
                    includeQuickSearchShortcut={true}
                    includeChemSearchShortcut={true}
                    includeCoocSearchShortcut={true}
                    ocid={this.state.conceptOcid}
                    onConceptClick={this.showCompoundInfo}
                    onHide={() => this.setState({ displayCompoundInfo: false })}
                />
            </>
        )
    }
}

export default CompoundDataView;