import { QUERY_FORM_DISEASE_TARGETS, QUERY_FORM_SUPPLIERS, QUERY_FORM_CORRELATIONS, QUERY_FORM_PATHOLOGIES } from '../../../properties';
import { MAX_COOC_TERMS } from './helper';


// --- correlation views --- //
export const CORRELATION_VIEWS = [{
    id: QUERY_FORM_CORRELATIONS.id,
    name: QUERY_FORM_CORRELATIONS.label,
    teaser: 'Find potential connections between two concepts.',
    info: 'When two terms are often mentioned in close proximity, this usually means that those two terms are connected with each other. With Co-occurrence Analysis you can perform such co-occurrence analyses in a semantic way across hundreds of millions of documents within seconds. Co-occurrence Analysis offers you insights without the need to read the documents. Try searching for chemical compounds of a certain class often mentioned near a term describing a function (e.g. “steroids” and “anti-inflammatory”) or try searching for chemical compounds associated with a specific edible plant (e.g. “Brassica sinapistrum” and “fatty acids”).',
    headerLabelTopTermsLeft: `Top ${MAX_COOC_TERMS} concepts left`,
    headerLabelTopTermsRight: `Top ${MAX_COOC_TERMS} concepts right`,
    restrictions: { left: {}, right: {} }
}, {
    id: QUERY_FORM_SUPPLIERS.id,
    name: QUERY_FORM_SUPPLIERS.label,
    teaser: 'Find potential connections with organizations.',
    info: 'Correlate physical (e.g. chemical compounds and drugs) or non-physical concepts (e.g. diseases) of your interest with organizations. Depending on which concept you search on the left, this allows you to identify and validate potential suppliers for chemical compounds, organizations involved in a specific drug development & distribution, research institutions working on a specific disease area etc.',
    headerLabelTopTermsLeft: `Top ${MAX_COOC_TERMS} concepts`,
    headerLabelTopTermsRight: `Top ${MAX_COOC_TERMS} organizations`,
    //domainsEntity1: ['chem', 'devices', 'diseases', 'drugs', 'humangenes', 'inorgmat', 'natprod', 'polymers', 'substances'],
    //domainsEntity2: ['companies'],
    restrictions: {
        left: {
            restrictAutocomplete: true,
            restrictAutocompleteDomains: ['chem', 'devices', 'diseases', 'drugs', 'humangenes', 'inorgmat', 'natprod', 'polymers', 'substances'],
            restrictAutocompleteOcids: null,
            subtrees: [
                { domains: ['chem'], isSearchable: true },
                { domains: ['devices'], isSearchable: true },
                { domains: ['diseases'], isSearchable: true },
                { domains: ['drugs'], isSearchable: true },
                { domains: ['humangenes'], isSearchable: true },
                { domains: ['inorgmat'], isSearchable: true },
                { domains: ['natprod'], isSearchable: true },
                { domains: ['polymers'], isSearchable: true },
                { domains: ['substances'], isSearchable: true },
            ]
        },
        right: {
            restrictAutocomplete: true,
            restrictAutocompleteDomains: ['companies'],
            restrictAutocompleteOcids: null,
            subtrees: [
                { domains: ['companies'], label: 'All companies', isPreset: true },
                { domains: ['companies'], ocids: [281000000006], label: 'Top 50 pharma', term: 'Top 50 pharmaceutical companies', isPreset: true },
                { domains: ['companies'], ocids: [281000014063], label: 'Top 50 chem', term: 'Top 50 chemical companies', isPreset: true },
                { domains: ['companies'], ocids: [281999990030], label: 'Research institutions', term: 'Research institutions', isPreset: true },
                //{ label: 'Custom', isPreset: true },
            ],
        }
    }
}, {
    id: QUERY_FORM_DISEASE_TARGETS.id,
    name: QUERY_FORM_DISEASE_TARGETS.label,
    teaser: 'Find potential connections with pathways, proteins or genes.',
    info: 'Correlate physical (e.g. drug) and non-physical (e.g. disease) concepts with biological signaling pathways and genes & proteins. This allows you to identify and validate potential molecular mechanisms of a disease and understand drug mechanisms of action & targets.',
    headerLabelTopTermsLeft: `Top ${MAX_COOC_TERMS} concepts left`,
    headerLabelTopTermsRight: `Top ${MAX_COOC_TERMS} concepts right`,
    //domainsEntity1: ['chem', 'drugs', 'diseases', 'natprod'],
    //domainsEntity2: ['proteins'],
    restrictions: {
        left: {
            restrictAutocomplete: true,
            restrictAutocompleteDomains: ['diseases', 'drugs', 'natprod'],
            restrictAutocompleteOcids: null,
            subtrees: [
                { domains: ['diseases'], isSearchable: true },
                { domains: ['drugs'], isSearchable: true },
                { domains: ['natprod'], ocids: [229930000044], label: 'Secondary metabolite', isSearchable: true },
                // TODO: throws error
                //{ domains: ['chem'], ocids: [151000000875], label: 'Compound', isSearchable: true },
            ]
        },
        right: {
            restrictAutocomplete: true,
            restrictAutocompleteDomains: ['effects'],
            restrictAutocompleteOcids: [108090000623],
            subtrees: [
                { domains: ['effects'], ocids: [230000037361], label: 'Pathway', term: 'Pathway', isPreset: true },
                { domains: ['proteins'], ocids: [108090000623], label: 'Protein or gene', term: 'Prot or gene', isPreset: true },
                //{ label: 'Custom', isPreset: true },
                //{ domains: domains, ocids: ocids, term: "All" }
            ],
        }
    }
}, {
    id: QUERY_FORM_PATHOLOGIES.id,
    name: QUERY_FORM_PATHOLOGIES.label,
    teaser: 'Find potential connections with toxicities or pathophysiological processes.',
    info: 'Correlate physical concepts (e.g. drugs) with toxicities and pathophysiological processes. This tool allows you to identify and validate potential toxicities and therapeutic applications for drugs, chemical compounds, human proteins etc.',
    headerLabelTopTermsLeft: `Top ${MAX_COOC_TERMS} concepts left`,
    headerLabelTopTermsRight: `Top ${MAX_COOC_TERMS} concepts right`,
    //domainsEntity1: ['diseases', 'drugs'],
    //domainsEntity2: ['proteins'],
    restrictions: {
        left: {
            restrictAutocomplete: true,
            restrictAutocompleteDomains: ['chem', 'diseases', 'drugs', 'humangenes', 'natprod', 'polymers'],
            restrictAutocompleteOcids: null,
            subtrees: [
                { domains: ['chem'], isSearchable: true },
                { domains: ['diseases'], isSearchable: true },
                { domains: ['drugs'], isSearchable: true },
                { domains: ['humangenes'], isSearchable: true },
                { domains: ['natprod'], isSearchable: true },
                { domains: ['polymers'], isSearchable: true },
            ]
        },
        right: {
            restrictAutocomplete: true,
            restrictAutocompleteDomains: ['effects', 'toxicity'],
            restrictAutocompleteOcids: null,
            subtrees: [
                { domains: ['effects'], ocids: [230000035778], label: 'Pathophysiological process', isPreset: true },
                { domains: ['toxicity'], ocids: [231100000419], label: 'Toxicity', isPreset: true },
            ],
        }
    }
}];