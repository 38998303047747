/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component, createRef } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import ToastContent from '../../common/CustomToast/ToastContent';
import DocumentResultList from '../docresults/DocumentResultList';
import AddReadcubeDialog from "../../common/ReadCube/AddReadcubeDialog";
import {
    createDocumentSearchRequest, fetchDocuments,
    createDocumentPartsInfoRequest, fetchDocumentPartsInfo,
    addDocumentsToCollections, fetchAdditionalDocumentsInfo,
    removeDocumentsFromDocumentCollection, semanticExportFromDocumentsByDocIDs,
    semanticExportFromDocumentsByQuery, exportDocumentsToReadcube
} from '../../../api/content/DocumentApi';
import { checkResultAndGetPayload } from '../../../api';
import AddRemoveDocuments from '../docCollection/AddRemoveDocuments';
import { APP_PROPERTIES, REPOSITORY_INFO_NOT_AVAILABLE, EXPORTER_DOC_SELECTIONS, SEMANTIC_EXPORTER_DOC_SELECTIONS, EXPORTER_FORMATS, EXPORTER_NUM_OF_DOCS_LIMIT, EXPORTER_TARGETS, EXPORTER_TARGET_ID_BIGQUERY, EXPORTER_TARGET_ID_FILE, GLOBAL_METADATA_DOWNLOAD_LIMIT, QUERY_TYPE_QUICK_SEARCH, QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH, PDF_REPOS } from '../../../properties';
import { Dialog } from 'primereact/dialog';
import Extractor from '../SemanticExtractor/Extractor';
import { TabPanel, TabView } from 'primereact/tabview';
import { RadioButton } from 'primereact/radiobutton';
import { addDynamicPanel, addThousandsSeparatorToNumber, hasUserRole, isArrayEmpty } from '../util';
import { executeSentenceAnalyzerShortcut } from '../util/shortcuts';
import BulkExport from '../DocumentFinder/BulkExport';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { DocViewModal } from '../docview/common/DocviewModal/DocviewModal';
import ExportLimit from '../export/ExportLimit';
import ExportCenterSuccessMessage from '../export/ExportCenterSuccessMessage';
import { fetchAdditionalDocumentData, updateDocumentRating, updateDocumentReadStatus } from '../../../redux/actions/DocumentActions';

const DOCUMENT_PARTS = {
    abstract: true,
    description: true,
    claims: true,
    bibliography: true,
    background: true,
    introduction: true,
    methods: true,
    experimental: true,
    results: true,
    discussion: true,
    conclusion: true,
    perspectives: true,
    supplement: true,
    references: true,
    basicdata: true,
    design: true,
    eligibility: true,
    endpoints: true,
    labeledcomp: true,
    explcomp: true,
    contraindications: true,
    ddinteraction: true,
    indications: true,
    sideeffects: true
};
const DOCUMENT_PARTS_DSPUB = {
    dsabstract: true
}
const DOCUMENT_PARTS_PDF = {
    abstract: true
}

/**
 * Renders document results either as list or table.
 */
class DocumentResultWrapper extends Component {

    constructor(props) {

        super(props);

        let allowedDocParts = DOCUMENT_PARTS;
        if (props.repositoryInfo.name === 'dspub') {
            allowedDocParts = DOCUMENT_PARTS_DSPUB;
        }
        else if (PDF_REPOS[props.repositoryInfo.name]) {
            allowedDocParts = DOCUMENT_PARTS_PDF;
        }

        //const allowedDocParts = props.repositoryInfo && props.repositoryInfo.name === 'dspub' ? DOCUMENT_PARTS_DSPUB : DOCUMENT_PARTS;
        const exporterTargets = EXPORTER_TARGETS.filter(target => target.id !== EXPORTER_TARGET_ID_BIGQUERY || props.allowBigQueryExport);
        const selectedTarget = !isArrayEmpty(exporterTargets) ? exporterTargets[0] : null;
        const displayCustomToast = props.selectedDocuments && !isArrayEmpty(Object.keys(props.selectedDocuments));

        this.state = {
            docsAdditionalInfo: {},
            docPartsInformation: {},
            allowedDocParts: allowedDocParts,
            exporterTargets: exporterTargets,
            selectedTarget: selectedTarget,
            displayCustomToast: displayCustomToast,
            displayAddReadcubeDialog: false,
            isDocviewModalVisible: false,
            ocDocId: '',
            selectedDocument: null,
            repositoryId: '',
            headerDocuments: {},
            pageOffset: 0,
            pageRows: 20,
            sortField: '',
            sortMode: '',
            sortFactor: null,
            activeExportIndex: 0
        };

        this.growl = createRef();
        this.toastBC = createRef();
    }



    onShowDocviewModal = (repId, docId, doc) => {
        const { setIsLoaderActive } = this.props
        if (setIsLoaderActive)
            setIsLoaderActive(true)

        this.setState({
            ocDocId: docId,
            repositoryId: repId,
            selectedDocument: doc,
            isDocviewModalVisible: true
        })
    };



    onCloseDocviewModal = () => {
        const { setIsLoaderActive } = this.props
        if (setIsLoaderActive)
            setIsLoaderActive(false)

        this.setState({
            ocDocId: '',
            repositoryId: '',
            selectedDocument: null,
            isDocviewModalVisible: false
        })
    };


    /**
     * Fetches additional document info, such as read status,
     * rating, collections as well as document parts info 
     * for list of current documents and stores data in state.
     */
    componentDidMount = async () => {

        if (this.props.documents && this.props.documents.documents) {
            let temp = {}
            temp[`${(this.props.first / this.props.rows) + 1}`] = this.getModifiedDocuments(this.props.documents.documents)
            this.setState({
                headerDocuments: { ...temp },
                pageOffset: this.props.first,
                pageRows: this.props.rows,
                sortField: this.props.sortCriteria?.sortField,
                sortMode: this.props.sortCriteria?.sortMode,
                sortFactor: this.props.sortCriteria?.sortFactor
            })
            // --- retrieve additional document info, such as read status, rating, collections --- //
            await this.fetchAdditionalDocumentsInfo(this.props.documents.documents, this.props.repositoryInfo.id);
            // --- retrieve document parts info --- //
            await this.updateDocumentPartsInfo(this.props.documents.documents, this.props.repositoryInfo.id);
        }
    }

    /**
     * Fetches additional document info, such as read status,
     * rating, collections as well as document parts info 
     * for list of current documents and stores data in state.
     */
    componentDidUpdate = async (prevProps) => {

        if (this.props.documents?.documents && prevProps.documents?.documents &&
            this.props.documents.documents !== prevProps.documents.documents) {
            let temp = {}
            const page = (this.props.first / this.props.rows) + 1
            temp[`${page}`] = this.getModifiedDocuments(this.props.documents.documents)
            if (!this.state.headerDocuments[page]
                || this.props.documents.documentCount !== prevProps.documents.documentCount
                || this.props.repositoryInfo.id !== this.state.repositoryId
                || this.props.rows !== this.state.pageRows
                || this.props.sortCriteria?.sortField !== this.state.sortField
                || this.props.sortCriteria?.sortMode !== this.state.sortMode
                || this.props.sortCriteria?.sortFactor !== this.state.sortFactor
            ) {
                if (this.props.documents.documentCount !== prevProps.documents.documentCount
                    || this.props.rows !== prevProps.rows
                    || this.props.sortCriteria?.sortField !== this.state.sortField
                    || this.props.sortCriteria?.sortMode !== this.state.sortMode
                    || this.props.sortCriteria?.sortFactor !== this.state.sortFactor
                ) {
                    this.setState({
                        headerDocuments: { ...temp },
                        pageOffset: this.props.first,
                        pageRows: this.props.rows,
                        repositoryId: this.props.repositoryInfo.id,
                        sortField: this.props.sortCriteria?.sortField,
                        sortMode: this.props.sortCriteria?.sortMode,
                        sortFactor: this.props.sortCriteria?.sortFactor
                    })
                } else {
                    this.setState({
                        headerDocuments: { ...this.state.headerDocuments, ...temp },
                        pageOffset: this.props.first,
                        pageRows: this.props.rows,
                        repositoryId: this.props.repositoryInfo.id,
                        sortField: this.props.sortCriteria?.sortField,
                        sortMode: this.props.sortCriteria?.sortMode,
                        sortFactor: this.props.sortCriteria?.sortFactor
                    })
                }
            }
            // --- retrieve additional document info, such as read status, rating, collections --- //
            await this.fetchAdditionalDocumentsInfo(this.props.documents.documents, this.props.repositoryInfo.id);
            // --- retrieve document parts info --- //
            await this.updateDocumentPartsInfo(this.props.documents.documents, this.props.repositoryInfo.id);
        }

        // additional document data changed for a single document 
        if (prevProps.additionalDocumentData !== this.props.additionalDocumentData) {
            //console.log('UPDATE ADDITIONAL DOC DATA IN LIST', this.props.additionalDocumentData);
            //const docData = this.props.additionalDocumentData ? [this.props.additionalDocumentData] : [];
            this.updateAdditionalDocumentsInfoLocally(this.props.additionalDocumentData);
        }
    }

    // ----------------------------------------------------------------------- //
    // --- additional document data ------------------------------------------ //
    // ----------------------------------------------------------------------- //
    /**
     * Fetches additional document info, such as read status,
     * rating, collections for list of current documents and 
     * stores data in state.
     */
    fetchAdditionalDocumentsInfo = async (documents, repID) => {
        //console.log("documents fetch", documents)
        const docsAdditionalInfo = {};
        if (documents && documents.length > 0) {
            const ocDocIDs = [];
            const repIDs = []
            documents.forEach(doc => {
                ocDocIDs.push(doc.ocDocId);
                repIDs.push(doc.repositoryId ? doc.repositoryId : repID)
            });
            const resp = await fetchAdditionalDocumentsInfo(repID, ocDocIDs, repIDs);
            const result = checkResultAndGetPayload(resp, this.growl);

            if (result) {
                result.forEach(doc => {
                    docsAdditionalInfo[doc.docId] = doc;
                });
            }
        }

        this.setState({
            docsAdditionalInfo: docsAdditionalInfo
        });
    }

    /**
     * Fetches additional document info, such as read status,
     * rating, collections for list of documents and
     * updates state with new data.
     */
    updateAdditionalDocumentsInfo = async (documents, repID) => {
        //console.log("update fetch", documents)
        const docsAdditionalInfo = { ...this.state.docsAdditionalInfo };
        if (documents) {
            const ocDocIDs = [];
            const repIDs = []
            documents.forEach(doc => {
                //console.log('doc: ', doc);
                //console.log('doc.ocDocId: ', doc.ocDocId); // undef???
                //console.log('doc.docId: ', doc.docId);
                const docId = doc.ocDocId ? doc.ocDocId : doc.docId;
                ocDocIDs.push(docId);
                repIDs.push(doc.repositoryId ? doc.repositoryId : repID)
            });
            const resp = await fetchAdditionalDocumentsInfo(repID, ocDocIDs, repIDs);
            const result = checkResultAndGetPayload(resp, this.growl);

            if (result) {
                result.forEach(doc => {
                    docsAdditionalInfo[doc.docId] = doc;
                });
            }
        }

        this.setState({
            docsAdditionalInfo: docsAdditionalInfo
        });
    }

    /**
     * Updates additional document info, such as read status,
     * rating, collections for a single document locally.
     * Data is already updated in the MW.
     */
    updateAdditionalDocumentsInfoLocally = async (document) => {

        const docsAdditionalInfo = { ...this.state.docsAdditionalInfo };
        if (document?.docId) {
            docsAdditionalInfo[document.docId] = document;
        }

        this.setState({ docsAdditionalInfo: docsAdditionalInfo });
    }

    /**
     * Fetches available document parts for each entry in the 
     * current list of documents.
     */
    updateDocumentPartsInfo = async (documents, repID) => {

        const docPartsInfo = {};

        const docIDs = [];
        if (documents) {
            documents.forEach(doc => {
                docIDs.push(doc.ocDocId);
            });
        }

        if (docIDs.length > 0) {
            const request = createDocumentPartsInfoRequest(repID, docIDs, true, false, false);
            const response = await fetchDocumentPartsInfo(request);
            const result = checkResultAndGetPayload(response, this.growl);

            if (result && result.documents) {
                for (var doc of result.documents) {
                    let hasDocParts = false;
                    if (doc.namedDocParts) {
                        for (var docPart of Object.keys(doc.namedDocParts)) {
                            if (this.state.allowedDocParts[docPart] === true) {
                                hasDocParts = true;
                                break;
                            }
                        }
                        doc.hasDocParts = hasDocParts;
                    }
                    docPartsInfo[doc.docId] = doc;
                }
            }
        }

        this.setState({
            docPartsInformation: docPartsInfo
        });
    }

    // ----------------------------------------------------------------------- //
    // --- selected documents ------------------------------------------------ //
    // ----------------------------------------------------------------------- //
    onToggleAllDocumentSelections = () => { }

    /**
     * Adds or removes documents of active page to/from list of selected documents.
     */
    onToggleAllDocumentSelectionsOnPage = (selectAll) => {
        const selectedDocuments = { ...this.props.selectedDocuments };
        const documents = this.props.documents && this.props.documents.documents ? this.props.documents.documents : null;

        if (documents) {
            documents.forEach(doc => {
                // --- add all documents to selections -- //
                if (selectAll) {
                    selectedDocuments[doc.ocDocId] = doc;
                }
                // --- remove all documents from selections-- //
                else {
                    delete selectedDocuments[doc.ocDocId];
                }
            });
        }

        // --- update content of toast for current document selections --- //
        this.updateOptionsToast(selectedDocuments);

        // --- update state with selected documents and show options toast if neccessary --- //
        this.props.onDocumentSelectionChange(selectedDocuments);
        //this.showOptionsToast();
    }

    /**
     * Adds or removes single document to/from list of selected documents.
     */
    onToggleDocumentSelection = (doc) => {

        const selectedDocuments = { ...this.props.selectedDocuments };

        if (!selectedDocuments[doc.ocDocId]) {
            selectedDocuments[doc.ocDocId] = doc;
        }
        else {
            delete selectedDocuments[doc.ocDocId];
        }

        // --- update content of toast for current document selections --- //
        this.updateOptionsToast(selectedDocuments);

        // --- update state with selected documents and show options toast if neccessary --- //
        this.props.onDocumentSelectionChange(selectedDocuments);
        //this.showOptionsToast();
    }

    /**
     * Clears document selections.
     */
    clearDocumentSelection = () => {
        this.props.onDocumentSelectionChange({});
    }

    // ----------------------------------------------------------------------- //
    // --- options for selected documents ------------------------------------ //
    // ----------------------------------------------------------------------- //
    updateOptionsToast = (selectedDocuments) => {

        if (Object.keys(selectedDocuments).length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (Object.keys(selectedDocuments).length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
    }

    hideOptionsToast = () => {
        this.setState({
            displayCustomToast: false
        })
        this.toastBC.current.clear();
    }

    rejectSelectedDocuments = () => {
        this.hideOptionsToast();
        this.clearDocumentSelection();
    }

    // ----------------------------------------------------------------------- //
    // --- add or remove preview document to/from collections ---------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Adds given document to collection.
     */
    onAddToCollections = async (collections, docData) => {
        const docs = [];
        if (!!docData && !!collections && collections.length > 0) {
            docs.push({
                docId: docData.ocDocId,
                repository: docData.repository,
                repositoryId: docData.repositoryId,
                title: docData.title
            });

            let ids = [];
            collections && collections.forEach(coll => {
                ids.push(coll.id);
            });

            const response = await addDocumentsToCollections(ids, docs);
            checkResultAndGetPayload(response, this.growl);

            this.updateAdditionalDocumentsInfo(docs, docData.repositoryId);
        }
        else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'Either no documents or collections were selected.'
            });
        }
    }

    /**
     * Removes given document from collection.
     */
    onRemoveFromCollections = async (collections, docData) => {

        const docs = [];
        if (!!docData && !!collections && collections.length > 0) {
            docs.push({
                docId: docData.ocDocId,
                repository: docData.repositoryId,
            });

            for (var coll of collections) {
                const response = await removeDocumentsFromDocumentCollection(coll.id, coll.documentLink);
                checkResultAndGetPayload(response, this.growl);
            }

            this.updateAdditionalDocumentsInfo(docs, docData.repositoryId);
        }
        else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'Either no documents or collections were selected.'
            });
        }
    }


    // ----------------------------------------------------------------------- //
    // --- add documents to collections -------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Opens dialog to add document(s) to collections. 
     * 
     * documentsToAdd is only set if the document(s) we want to add to the library
     * are different from the selected documents in the list/table, e.g.
     * when a previewed document is added to collection(s).
     */
    onOpenAddToCollectionsDialog = (documentsToAdd = null) => {

        this.setState({
            displayDialogAddToCollections: true,
            documentsToAdd: documentsToAdd
        });
    }

    /**
     * Closes dialog to add document(s) to collections. 
     */
    onCloseAddToCollectionsDialog = () => {

        this.setState({
            displayDialogAddToCollections: false
        });
    }

    /**
     * Adds selected documents to collections. If document preview is opened
     * for a  specific document this document will be added, otherwise the
     * list of selected documents.
     */
    onSubmitAddToCollections = async (collections) => {

        const docs = [];
        if (!!this.state.documentsToAdd && this.state.documentsToAdd.length > 0) {
            Object.values(this.state.documentsToAdd).forEach(doc => {
                docs.push({
                    docId: doc.ocDocId,
                    repository: this.props.repositoryInfo.id,
                    title: doc.title
                });
            });
        }
        else if (this.props.selectedDocuments && Object.values(this.props.selectedDocuments).length > 0) {
            Object.values(this.props.selectedDocuments).forEach(doc => {
                docs.push({
                    docId: doc.ocDocId,
                    repository: this.props.repositoryInfo.id,
                    repositoryId: this.props.repositoryInfo.id,
                    title: doc.title
                });
            });
        }
        else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'No document selected.'
            });
        }

        let ids = [];
        collections && collections.forEach(col => {
            ids.push(col.id);
        });

        //console.log('docs: ', docs);
        if (docs.length > 0 && ids.length > 0) {
            const response = await addDocumentsToCollections(ids, docs);
            checkResultAndGetPayload(response, this.growl, 'Success!',
                `${docs.length === 1 ? 'Document' : 'Documents'} successfully added to ${ids.length === 1
                    ? 'collection.' : 'collections.'}`);

            this.updateAdditionalDocumentsInfo(docs, this.props.repositoryInfo.id);
            //this.updateAdditionalInfo();
        }
        //console.log('added to coll: ', result);

        this.setState({
            displayDialogAddToCollections: false
        });
    }

    // ----------------------------------------------------------------------- //
    // --- change sort by, sort mode, patent family grouping etc. ------------ //
    // ----------------------------------------------------------------------- //
    /**
     * Changes sort field and clears selected documents.
     */
    onSortFieldChange = (field, value) => {

        this.clearDocumentSelection();
        this.props.onSortFieldChange(field, value);
    }

    /**
     * Changes sort mode and clears selected documents.
     */
    onSortModeChange = (mode, value) => {

        this.clearDocumentSelection();
        this.props.onSortModeChange(mode, value);
    }

    /**
     * Changes patent family grouping and clears selected documents.
     */
    onGroupByPatentFamilyChange = (patFamily) => {

        this.clearDocumentSelection();
        this.props.onGroupByPatentFamilyChange(patFamily);
    }


    onExportSubmit = async () => {
        this.setState({ exportRunning: true });

        let repID;
        let docIDs;
        if (this.props.repositoryInfo && this.props.repositoryInfo.id) {
            repID = this.props.repositoryInfo.id;
        }

        if (this.state.selectedExportDocSelection.type === 'byDocIDs') {
            if (repID && this.state.selectedExportDocSelection.id === 'onPage') {
                if (this.props.documents && this.props.documents.documents) {
                    docIDs = this.props.documents.documents.map(doc => {
                        return {
                            // documentIdentifier: parseInt(doc.ocDocId),
                            documentIdentifier: doc.ocDocId,
                            repositoryIdentifier: repID
                        };
                    })
                }
            }
            else if (this.state.selectedExportDocSelection.id === 'selDocs') {
                if (this.props.selectedDocuments && !isArrayEmpty(Object.keys(this.props.selectedDocuments))) {
                    docIDs = Object.keys(this.props.selectedDocuments).map(docID => {
                        return {
                            // documentIdentifier: parseInt(docID),
                            documentIdentifier: docID,
                            repositoryIdentifier: repID
                        };
                    });
                }
            }
            this.props.onExport({ docIDs: docIDs }, this.state.selectedTarget?.id, this.state.selectedFormat?.id);
        }
        else if (this.state.selectedExportDocSelection.type === 'byQuery') {
            const maxNumOfDocs = this.state.selectedExportDocSelection.max;
            this.props.onExport({ maxNumOfResults: maxNumOfDocs }, this.state.selectedTarget?.id, this.state.selectedFormat?.id);
        }

        this.setState({
            displayExportDialog: false,
            exportRunning: false
        });
    }


    onSemanticExport = () => {
        this.setState({ displayExportDialog: true });
    }

    onAddToReadcube = () => {
        this.setState({
            displayAddReadcubeDialog: true
        })
    }

    onAnalyze = (queryType, documents, repositoryInfo) => {
        this.setState({
            //displayAnalyzeDialog: true,
            queryTypeSentenceAnalysis: queryType,
            pageDocuments: documents,
            repositoryInfoSentenceAnalysis: repositoryInfo,
            selectedSentenceAnalysisOption: { label: 'All documents (max. 1,500)', value: 'all' }
        }, () => {
            this.onAnalyzeSubmit()
        })
    }

    onAnalyzeFromToast = () => {
        this.setState({
            displayAnalyzeDialog: true,
            queryTypeSentenceAnalysis: QUERY_TYPE_QUICK_SEARCH.label,
            pageDocuments: this.props.documents,
            repositoryInfoSentenceAnalysis: this.props.repositoryInfo
        })
    }

    onAnalyzeSubmit = async (e) => {
        //console.log(this.props)
        if (this.state.selectedSentenceAnalysisOption.value === 'page') {
            this.setState({
                displayAnalyzeDialog: false
            })
            executeSentenceAnalyzerShortcut(undefined, this.state.queryTypeSentenceAnalysis, this.state.pageDocuments,
                this.state.repositoryInfoSentenceAnalysis, this.props.queryTerms, this.state.docsAdditionalInfo, this.props.sortFields, this.props.sortCriteria, true)
        } else if (this.state.selectedSentenceAnalysisOption.value === 'all') {
            /*this.setState({
                loadingDocuments: true
            })*/
            let query = this.props.internalQuery;


            /*if (this.props.starQuery && this.props.sortField &&
                (this.props.sortField === 'pdate' || this.props.sortField === 'startdate' || this.props.sortField === 'insTime')) {
                query = '+all:"*" dis:* proteins:* drugs:*'
            }
            let sortFactor = this.props.sortFactor;
            if (!this.props.sortFactor && this.props.sortMode) {
                if (this.props.sortMode === "descending" && this.props.starQuery && this.props.sortField &&
                    (this.props.sortField === 'pdate' || this.props.sortField === 'startdate' || this.props.sortField === 'insTime')) {
                    sortFactor = 20;
                }
            }*/
            //console.log(this.props.sortField)
            //console.log(this.props.sortMode)
            //console.log(sortFactor)


            //const request = createDocumentSearchRequest(this.state.repositoryInfoSentenceAnalysis.id, query, null, this.props.sortCriteria?.sortField, this.props.sortCriteria?.sortMode, this.props.sortCriteria?.sortFactor); // availableFacets

            //console.log(request)

            //const resp = await fetchDocuments(1, 500, request)
            //let documentData = checkResultAndGetPayload(resp, this.growl);
            /* if (documentData) {
                 this.setState({
                 }, async () => {
                     const docsAdditionalInfo = {};
                     if (documentData?.documents && documentData?.documents?.length > 0) {
                         const ocDocIDs = [];
                         const repIDs = []
                         documentData?.documents.forEach(doc => {
                             ocDocIDs.push(doc.ocDocId);
                             repIDs.push(doc.repositoryId ? doc.repositoryId : '')
                         });
                         const resp = await fetchAdditionalDocumentsInfo(this.props.repositoryInfo.id, ocDocIDs, repIDs);
                         const result = checkResultAndGetPayload(resp, this.growl);
                         if (result) {
                             result.forEach(doc => {
                                 docsAdditionalInfo[doc.docId] = doc;
                             });
                         }
                     }*/
            this.setState({
                displayAnalyzeDialog: false,
                //loadingDocuments: false
            }, () => {
                executeSentenceAnalyzerShortcut(query, this.state.queryTypeSentenceAnalysis, undefined,
                    this.state.repositoryInfoSentenceAnalysis, this.props.queryTerms, undefined, this.props.sortFields,
                    this.props.sortCriteria, true)
            });
            //});
            //}
        } else {
            let documents = []
            this.props.selectedDocuments && Object.keys(this.props.selectedDocuments).forEach(doc => {
                documents = [...documents, this.props.selectedDocuments[doc]]
            })
            const documentData = { documents: documents, internalQuery: this.state.pageDocuments.internalQuery }
            /*this.setState({
            }, async () => {
                if (documents) {
                    const docsAdditionalInfo = {};
                    if (documents && documents?.length > 0) {
                        const ocDocIDs = [];
                        const repIDs = []
                        documents.forEach(doc => {
                            ocDocIDs.push(doc.ocDocId);
                            repIDs.push(doc.repositoryId ? doc.repositoryId : '')
                        });
                        const resp = await fetchAdditionalDocumentsInfo(this.props.repositoryInfo.id, ocDocIDs, repIDs);
                        const result = checkResultAndGetPayload(resp, this.growl);
                        if (result) {
                            result.forEach(doc => {
                                docsAdditionalInfo[doc.docId] = doc;
                            });
                        }
                    }*/
            this.setState({
                displayAnalyzeDialog: false,
                //loadingDocuments: false
            }, () => {
                executeSentenceAnalyzerShortcut(undefined, this.state.queryTypeSentenceAnalysis, documentData,
                    this.state.repositoryInfoSentenceAnalysis, this.props.queryTerms, undefined, this.props.sortFields, this.props.sortCriteria, true)
            });
            //}
            //})


            /*this.setState({
                displayAnalyzeDialog: false
            })
            executeSentenceAnalyzerShortcut(this.state.queryTypeSentenceAnalysis, documentData,
                this.state.repositoryInfoSentenceAnalysis, this.props.queryTerms, this.state.docsAdditionalInfo)
                */
        }
    }

    onSemanticExportSubmit = async (queryTerms, target, format) => {
        this.setState({ exportRunning: true });

        let response;

        let targetID;
        let formatID;
        let repID;
        let docIDs;
        let category = '';
        let ocids = [];
        if (target && target.id) {
            targetID = target.id;
        }
        if (format && format.id) {
            formatID = format.id;
        }
        if (this.props.repositoryInfo && this.props.repositoryInfo.id) {
            repID = this.props.repositoryInfo.id;
        }

        if (!isArrayEmpty(queryTerms)) {
            if (queryTerms[0].specialCase) {
                category = queryTerms[0].specialCase;
            }
            else if (queryTerms.length === 1 && queryTerms[0].isPreset &&
                queryTerms[0].domains && queryTerms[0].domains[0]) {
                category = queryTerms[0].domains[0];
            }
            else {
                category = 'ocids';
                queryTerms.forEach(qt => { ocids.push(...qt.ocids) });
            }
        } else {
            category = "_ALL"
        }

        if (this.state.selectedSemanticExportDocSelection.type === 'byDocIDs') {
            if (repID && this.state.selectedSemanticExportDocSelection.id === 'onPage') {
                if (this.props.documents && this.props.documents.documents) {
                    docIDs = this.props.documents.documents.map(doc => {
                        return {
                            documentIdentifier: doc.ocDocId,
                            repositoryIdentifier: repID
                        };
                    })
                }
            }
            else if (this.state.selectedSemanticExportDocSelection.id === 'selDocs') {
                if (this.props.selectedDocuments && !isArrayEmpty(Object.keys(this.props.selectedDocuments))) {
                    docIDs = Object.keys(this.props.selectedDocuments).map(docID => {
                        return {
                            documentIdentifier: docID,
                            repositoryIdentifier: repID
                        };
                    });
                }
            }
            // --- run export --- //
            if (targetID && formatID && repID && category) {
                response = await semanticExportFromDocumentsByDocIDs(targetID, formatID, docIDs, category, ocids);
            }
        }
        else if (this.state.selectedSemanticExportDocSelection.type === 'byQuery') {
            const maxNumOfDocs = this.state.selectedSemanticExportDocSelection.max;
            // --- run export --- //
            if (targetID && formatID && repID && category) {
                response = await semanticExportFromDocumentsByQuery(targetID, formatID, repID, this.props.query, this.props.filterQueries,
                    this.props.sortCriteria?.sortMode, this.props.sortCriteria?.sortField, this.props.sortCriteria?.sortFactor, category, ocids, maxNumOfDocs);
            }
        }

        if (response && targetID === EXPORTER_TARGET_ID_BIGQUERY) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                const datasetExportIdentifier = result.datasetExportIdentifier;
                const forwardUrl = result.forwardUrl;
                if (forwardUrl) {
                    window.open(forwardUrl, '_blank');
                }
                else {
                    this.growl.current.show({
                        sticky: true, closable: true, severity: 'success',
                        summary: 'Export successful', detail: `Dataset export identifier: ${datasetExportIdentifier}`
                    });
                }
            }
        }
        else {
            checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage />, null, true);
        }


        this.setState({
            displayExportDialog: false,
            exportRunning: false
        });
    }

    getModifiedDocuments = docs => (docs?.map((item, index) => {
        return {
            number: this.props.first + index + 1,
            ...item
        }

    }))

    onSubmitReadcubeLibrary = async (lib) => {

        let docIds = []

        if (this.props.selectedDocuments && Object.values(this.props.selectedDocuments).length > 0) {
            Object.values(this.props.selectedDocuments).forEach(doc => {
                docIds.push({
                    documentIdentifier: doc.ocDocId,
                    repositoryIdentifier: doc.repositoryId
                });
            });
            let response;
            response = await exportDocumentsToReadcube(docIds, 'readcube', lib.id);
            if (response) {
                const result = checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage toReadcubeExports={true} />, null, true);
                if (response.status === 'SUCCESS') {
                    this.setState({
                        displayAddReadcubeDialog: false
                    })
                }
            }
        }
        else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'No document selected.'
            });
        }
    }

    render() {

        const { repositoryInfo, documents,
            ratingReadOnly, onPageChange, onExport, fetching, sortFields, sortCriteria, first, rows, query,
            showGroupByPatentFamily, groupByPatentFamily, onGroupByPatentFamilyChange,
            addOrganisms, patentInfo, selectedSequenceID, starQuery,
            isPatentFamilyMutliple, onPatentFamilyGroupingChange, patentFamilyGrouping, patentFamilyGroupingValues,
            hasExportCenter } = this.props;

        const hasSelectedDocuments = this.props.selectedDocuments && !isArrayEmpty(Object.values(this.props.selectedDocuments));
        //&& (EXPORTER_DOC_SELECTIONS.some(docSel => docSel.id === 'selDocs'))
        const exporterDocSelectionOptions = EXPORTER_DOC_SELECTIONS.filter(docSel =>
            (hasSelectedDocuments || docSel.id !== 'selDocs') && (hasExportCenter || !docSel.onlyViaExportCenter)
        );
        const exporterSemanticDocSelectionOptions = SEMANTIC_EXPORTER_DOC_SELECTIONS.filter(docSel => hasSelectedDocuments || docSel.id !== 'selDocs');
        let sentenceAnalysisOptions = [{ label: 'Selected documents', value: 'selected' }, //{ label: 'Documents on current page', value: 'page' }, 
        { label: 'All documents (max. 1,500)', value: 'all' }]


        if (!hasSelectedDocuments) {
            sentenceAnalysisOptions = sentenceAnalysisOptions.filter(opt => {
                return opt.value !== "selected"
            })
        }

        let activeToastOptions = ['addElements']

        if (!repositoryInfo.name.startsWith("rc_")) {
            activeToastOptions = [...activeToastOptions, 'export']
        }

        if (hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer) {
            activeToastOptions = [...activeToastOptions, 'analyze']
        }
        if (hasUserRole('ROLE_READCUBEVIEW') && !repositoryInfo.name.startsWith("rc_")) {
            activeToastOptions = [...activeToastOptions, 'addToReadcube']
        }


        return (
            <>
                <Toast ref={this.growl} appendTo={document.body} />

                <Toast
                    ref={this.toastBC}
                    className="toast"
                    //style={{ minWidth: 800 }}
                    position="bottom-center"
                    closable="true"
                />

                <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                    onHide={this.rejectSelectedDocuments} style={{
                        width:/* hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer && !repositoryInfo.name.startsWith("rc_") ? '42rem'
                            : hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer && repositoryInfo.name.startsWith("rc_") ? '23rem'
                                : !(hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer) && !repositoryInfo.name.startsWith("rc_") ? '33rem'
                                    : '10rem'*/
                            activeToastOptions?.length === 4 ? '42rem' : activeToastOptions?.length === 3 ? '37rem' : '29rem', bottom: 45
                    }}
                    draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                    <ToastContent selectedElements={this.props.selectedDocuments}
                        elementLabel='document'
                        onHide={this.rejectSelectedDocuments}
                        onAddElements={this.onOpenAddToCollectionsDialog}
                        onExport={this.onSemanticExport}
                        onAnalyze={this.onAnalyzeFromToast}
                        onAddToReadcube={this.onAddToReadcube}
                        activeOptions={/*hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer && hasUserRole('ROLE_READCUBEVIEW') &&
                            !repositoryInfo.name.startsWith("rc_") ?
                            ['addElements', 'addToReadcube', 'export', 'analyze'] :
                            hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer && repositoryInfo.name.startsWith("rc_") ?
                                ['analyze'] :
                                !(hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer) && hasUserRole('ROLE_READCUBEVIEW') && !repositoryInfo.name.startsWith("rc_") ?
                                    ['addElements', 'addToReadcube', 'export']
                                    : !(hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer) && !hasUserRole('ROLE_READCUBEVIEW') && !repositoryInfo.name.startsWith("rc_") ?
                                        ['addElements', 'export']
                : []*/
                            activeToastOptions} />
                </Dialog>

                {
                    this.props.showResults &&
                    <DocumentResultList
                        onShowDocviewModal={this.onShowDocviewModal}
                        // @todo query, filter queries etc. - only for bulk export?
                        ////query={this.props.query}
                        ////filterQueries={this.props.filterQueries}
                        //
                        starQuery={starQuery}
                        repositoryInfo={this.props.repositoryInfo}
                        documents={documents}
                        // --- active doc parts: abstract, methods, claims; not title --- //
                        activeDocParts={this.state.allowedDocParts}
                        // --- map: doc ID => doc parts --- //
                        docPartsInfo={this.state.docPartsInformation}
                        // --- map: doc ID => read, rating, collections, title, etc.
                        docsAdditionalInfo={this.state.docsAdditionalInfo}
                        onChangeReadStatus={this.props.updateDocumentReadStatus}
                        onChangeRating={this.props.updateDocumentRating}
                        ratingReadOnly={ratingReadOnly}
                        // --- change doc selection, read status, rating, collections --- //
                        selectedDocuments={this.props.selectedDocuments}
                        onToggleDocumentSelection={this.onToggleDocumentSelection}
                        onToggleAllDocumentSelectionsOnPage={this.onToggleAllDocumentSelectionsOnPage}
                        // --- add or remove preview document to/from collections --- //
                        onOpenAddToCollectionsDialog={this.onOpenAddToCollectionsDialog}
                        onAddToCollections={this.onAddToCollections}
                        onRemoveFromCollections={this.onRemoveFromCollections}

                        // --- paging --- //
                        onPageChange={onPageChange}
                        first={first}
                        rows={rows}
                        // --- sorting --- //
                        sortFields={sortFields}
                        sortCriteria={sortCriteria}
                        onSortFieldChange={this.onSortFieldChange}
                        onSortModeChange={this.onSortModeChange}
                        // --- export --- //
                        onExport={onExport}
                        onSemanticExport={this.onSemanticExport}
                        fetching={fetching}
                        onAnalyze={this.onAnalyze}
                        withSentenceAnalyzer={this.props.withSentenceAnalyzer}
                        //onSimilaritySearch={this.onSimilaritySearch}
                        //onSimilarityLocationChange={this.onSimilarityLocationChange}

                        // --- sequences --- //
                        addOrganisms={addOrganisms}
                        patentInfo={patentInfo}
                        selectedSequenceID={selectedSequenceID}
                        // -- group by patent family --- //
                        showGroupByPatentFamily={showGroupByPatentFamily}
                        isPatentFamilyMutliple={isPatentFamilyMutliple}
                        groupByPatentFamily={groupByPatentFamily}
                        patentFamilyGroupingValues={patentFamilyGroupingValues}
                        onPatentFamilyGroupingChange={onPatentFamilyGroupingChange}
                        patentFamilyGrouping={patentFamilyGrouping}
                        // obsolete
                        onGroupByPatentFamilyChange={onGroupByPatentFamilyChange}
                    />
                }
                {/*Replace?*/
                 /* add or remove selected documents to/from collections */}
                <AddRemoveDocuments
                    userData={this.props.userData}
                    displayDialogRemoveDocument={this.state.displayDialogRemoveDocument}
                    displayDialogAddToCollections={this.state.displayDialogAddToCollections}
                    onCloseAddToCollectionsDialog={this.onCloseAddToCollectionsDialog}
                    onSubmitAddToCollections={this.onSubmitAddToCollections}
                    onCancelAddToCollections={this.onCloseAddToCollectionsDialog}
                />

                {/*
                <DocumentExportDialog
                    displayExportDialog={this.state.displayExportDialog}
                    allowSelectDocumentsOption={hasSelectedDocuments}
                    onMetadataExport={this.onExport}
                    onSemanticExport={this.onSemanticExportSubmit}
                />
                */}

                <DocViewModal
                    isVisible={this.state.isDocviewModalVisible} // && this.props.documents.documents.length >= 0
                    docId={this.state.ocDocId}
                    repId={this.state.repositoryId}
                    documents={this.state.headerDocuments}
                    selectedDoc={this.state.selectedDocument}
                    repositoryInfo={repositoryInfo}
                    onCloseDocviewModal={this.onCloseDocviewModal}
                    onPageChange={onPageChange}
                    first={first}
                    rows={rows}
                    documentCount={documents.documentCount}
                    setIsLoaderActive={this.props.setIsLoaderActive}
                    isSemanticSearch={this.props.isSemanticSearch || false}
                />

                <Dialog focusOnShow={false}
                    visible={this.state.displayAnalyzeDialog}
                    style={{ 'width': "60vw", height: 210 }}
                    header={`Analyze documents`}
                    modal={true}
                    blockScroll
                    dismissableMask={true}
                    onShow={e => {
                        this.setState({
                            selectedSentenceAnalysisOption: sentenceAnalysisOptions[0]
                        });
                    }}
                    onHide={() => this.setState({ displayAnalyzeDialog: false })} className='styledDialog'>
                    <LoadingOverlay
                        active={this.state.loadingDocuments}
                        spinner={true}
                        text='Fetching documents data...' >
                        <div style={{ paddingLeft: 20, paddingRight: 25 }}>
                            <div style={{ borderBottom: '1px solid #d6d6d6', marginRight: -15, height: 100 }}>
                                <div style={{ margin: '5px 0px 30px 0px', lineHeight: 1.5 }}>
                                    Analyze the sentences of your documents.
                                </div>
                                <div className="grid" style={{ paddingLeft: 7 }}>
                                    <div className="p-col-fixed" style={{ width: 130 }}>Source documents</div>
                                    <div className="p-col">
                                        {sentenceAnalysisOptions.map(opt => {
                                            return <div key={opt.value}
                                                style={{ display: 'inline-block', marginBottom: 10, marginTop: 0, marginRight: 10, top: 0 }}
                                                className="p-field-radiobutton inline whiteSpaceNoWrap">
                                                <RadioButton
                                                    style={{ top: 0 }}
                                                    inputId={opt.value}
                                                    name="opt"
                                                    value={opt}
                                                    onChange={(e) => this.setState({ selectedSentenceAnalysisOption: e.value })}
                                                    checked={this.state.selectedSentenceAnalysisOption && this.state.selectedSentenceAnalysisOption.value === opt.value}
                                                />
                                                <label style={{ marginRight: 10 }} htmlFor={opt.value}>{opt.label}{opt.value === 'selected' ? ` (${this.props.selectedDocuments ? Object.values(this.props.selectedDocuments).length : ''})` : ''}</label>
                                            </div>
                                        })}
                                    </div>
                                    <div className="breakRow"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            {/*<div style={{ paddingTop: 5, float: 'left', paddingLeft: 0 }}>
                    <ExportLimit />
                    </div>*/}
                            <Button style={{ marginTop: 4, float: 'right', marginRight: 15 }}
                                className='p-button-sm primaryButton'
                                label="Analyze"
                                //tooltip='Export semantic concepts'
                                onClick={e => {
                                    this.onAnalyzeSubmit(e);
                                }}
                            />
                        </div>
                    </LoadingOverlay>
                </Dialog>

                <AddReadcubeDialog
                    displayDialog={this.state.displayAddReadcubeDialog}
                    onHide={() => this.setState({ displayAddReadcubeDialog: false })}
                    onSubmitReadcubeLibrary={this.onSubmitReadcubeLibrary}
                    readcubeRequests={null}>
                </AddReadcubeDialog>

                <Dialog focusOnShow={false}
                    visible={this.state.displayExportDialog}
                    style={{ 'width': "60vw", height: 450 }}
                    header={`Export`}
                    modal={true}
                    blockScroll
                    dismissableMask={true}
                    onShow={e => {
                        this.setState({
                            selectedExportDocSelection: exporterDocSelectionOptions[0],
                            selectedFormat: EXPORTER_FORMATS[0],
                            selectedSemanticExportDocSelection: exporterSemanticDocSelectionOptions[0]
                        });
                    }}
                    onHide={() => this.setState({ displayExportDialog: false })} className='styledDialog'>
                    <LoadingOverlay
                        active={this.state.exportRunning}
                        spinner={true}
                        text="Export in progress..." >
                        {/*Search {this.props.internalQuery} in {this.props.repositoryInfo.label}*/}
                        <div style={{ paddingLeft: 20, paddingRight: 25 }}>
                            <TabView
                                //className="tabview-custom"
                                activeIndex={this.state.activeExportIndex}
                                onTabChange={(e) => this.setState({ activeExportIndex: e.index })}
                            >
                                <TabPanel
                                    key="metadata"
                                    header="Metadata">
                                    <div style={{ borderBottom: '1px solid #d6d6d6', marginRight: -15, height: 285 }}>
                                        <div style={{ margin: '5px 0px 30px 0px', lineHeight: 1.5 }}>
                                            Metadata export will help you extract metadata information from documents like title,
                                            author/assignee, publication dates and various other fields that might be general or more
                                            specific for certain document types.
                                        </div>
                                        <div className="grid">

                                            <div className="col-fixed" style={{ width: 130 }}>Source documents</div>
                                            <div className="col">
                                                {exporterDocSelectionOptions
                                                    .filter(docSel => (docSel.id !== 'selDocs' || hasSelectedDocuments))
                                                    .map(docSel => {
                                                        if (docSel.id === 'custom') {
                                                            return <div key={docSel.id}
                                                                style={{ display: 'inline-block', marginBottom: 10, marginTop: 0, marginRight: 10, top: 0 }}
                                                                className="p-field-radiobutton inline whiteSpaceNoWrap">
                                                                <RadioButton
                                                                    inputId={docSel.id}
                                                                    name="docSel"
                                                                    value={docSel}
                                                                    onChange={(e) => this.setState({ selectedExportDocSelection: e.value })}
                                                                    checked={this.state.selectedExportDocSelection && this.state.selectedExportDocSelection.id === docSel.id}
                                                                />
                                                                <label style={{ marginRight: 10 }} htmlFor={docSel.id}>{docSel.label}</label>
                                                                <InputText
                                                                    style={{ width: 100 }}
                                                                    disabled={!this.state.selectedExportDocSelection || this.state.selectedExportDocSelection.id !== docSel.id}
                                                                    onInput={(e) => this.setState({ exportLimit: e.target.value })}
                                                                    placeholder={`max. ${EXPORTER_NUM_OF_DOCS_LIMIT}`}
                                                                />
                                                            </div>
                                                        }
                                                        //if (docSel.id !== 'selDocs' || hasSelectedDocuments) {
                                                        return <div key={docSel.id}
                                                            style={{
                                                                display: 'inline-block', marginBottom: 10, marginTop: 0, marginRight: 10, top: 0,
                                                                opacity: (starQuery && docSel.potSlowPerformance) ? 0.5 : 1
                                                            }}
                                                            className="p-field-radiobutton inline whiteSpaceNoWrap">
                                                            <RadioButton
                                                                inputId={docSel.id}
                                                                name="docSel"
                                                                value={docSel}
                                                                onChange={(e) => this.setState({ selectedExportDocSelection: e.value })}
                                                                checked={this.state.selectedExportDocSelection && this.state.selectedExportDocSelection.id === docSel.id}
                                                                disabled={starQuery && docSel.potSlowPerformance}
                                                                style={{ cursor: (starQuery && docSel.potSlowPerformance) ? 'default' : '' }}
                                                            />
                                                            <label style={{ marginRight: 10 }} htmlFor={docSel.id}>
                                                                {docSel.label}{docSel.id === 'selDocs' ? ` (${this.props.selectedDocuments ? Object.values(this.props.selectedDocuments).length : ''})` : ''}
                                                            </label>
                                                        </div>
                                                        //}
                                                    })}
                                                {/*<span style={{ whiteSpace: 'nowrap' }}>
                                            {starQuery ? '* export options not available for empty search' : '* export may take a while'}
                                            </span>*/}
                                            </div>
                                            <div className="breakRow"></div>

                                            <div className="col-12">
                                                {this.state.exporterTargets?.length > 1 ?
                                                    <>
                                                        <label className="valignMiddle" htmlFor="exportTo">Export to:</label>
                                                        <Dropdown
                                                            id='exportTo'
                                                            value={this.state.selectedTarget}
                                                            options={this.state.exporterTargets}
                                                            onChange={e => this.setState({ selectedTarget: e.value })}
                                                            placeholder="Select target"
                                                            appendTo={document.body}
                                                        />
                                                    </> : null}
                                                {this.state.selectedTarget?.type === EXPORTER_TARGET_ID_FILE ?
                                                    <>
                                                        <label htmlFor="format">File format:</label>
                                                        <Dropdown
                                                            id='format'
                                                            value={this.state.selectedFormat}
                                                            options={EXPORTER_FORMATS}
                                                            onChange={e => this.setState({ selectedFormat: e.value })}
                                                            placeholder="Select file format"
                                                            appendTo={document.body}
                                                        />
                                                    </> : null}
                                            </div>
                                            <div className="breakRow"></div>

                                            <div className="col">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div style={{ paddingTop: 5, float: 'left', paddingLeft: 0 }}>
                                            <ExportLimit maximumEntriesToExport={GLOBAL_METADATA_DOWNLOAD_LIMIT} />
                                        </div>
                                        <Button style={{ marginTop: 4, float: 'right', marginBottom: 4, marginRight: -22 }}
                                            className='p-button-sm primaryButton'
                                            label="Export"
                                            //tooltip='Export semantic concepts'
                                            onClick={e => {
                                                this.setState({ displayExportDialog: false });
                                                this.onExportSubmit();
                                            }}
                                        />
                                    </div>
                                </TabPanel>

                                {addDynamicPanel(hasUserRole('ROLE_BULKEXPORT')).map(hack => {
                                    return <TabPanel
                                        key="bulk"
                                        header="Full text (bulk)">
                                        {starQuery ?
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                Full text export not available for empty search
                                            </span>
                                            :
                                            <>
                                                <div style={{ height: 285 }}>
                                                    <div style={{ margin: '5px 0px 30px 0px' }}>
                                                        Bulk export of full text documents is limited to {addThousandsSeparatorToNumber(50000)} documents.
                                                    </div>
                                                    <div className="grid" style={{ marginRight: -15 }}>
                                                        <div className="col" style={{ paddingLeft: 2, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>
                                                            <BulkExport
                                                                starQuery={starQuery}
                                                                repository={repositoryInfo.id}// repositoryInfo.name???
                                                                userData={this.props.userData}
                                                                internalQuery={query}
                                                                // @todo
                                                                //query={query}
                                                                //filterQueries={ilterQueries}
                                                                numberDocuments={this.props.documents.documentCount}
                                                                repositoryInfo={this.props.repositoryInfo}
                                                            />
                                                        </div>
                                                        {/*<div className="col textAlignRight">
                                                    <Button //style={{ marginLeft: 30, marginTop: 5, merginBottom: 0 }}
                                                        className='p-button-sm primaryButton'
                                                        label="Export"
                                                    //tooltip='Export semantic concepts'
                                                    //onClick={onExport}
                                                    />
                                                </div>*/}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </TabPanel>
                                })}

                                {addDynamicPanel(hasUserRole('ROLE_SEMANTICEXPORT')).map(hack => {
                                    return <TabPanel
                                        key="semantic"
                                        header="Semantic concepts">

                                        <div style={{ margin: '5px 0px 30px 0px', lineHeight: 1.5 }}>
                                            Semantic export will help you extract selected named entities from all specified full texts.
                                            Those entities can be chemical compounds, companies, or substances mentioned.
                                            The output will contain both the found named entity as well as a number of metadata
                                            (e.g. title, ID, author/assignee, etc.) taken from the document the named entity was found in.
                                        </div>

                                        <div className="grid">
                                            <div className="col-fixed" style={{ width: 130 }}>Source documents</div>
                                            <div className="col">
                                                {exporterSemanticDocSelectionOptions
                                                    .filter(docSel => (docSel.id !== 'selDocs' || hasSelectedDocuments))
                                                    .map(docSel => {
                                                        if (docSel.id === 'custom') {
                                                            return <div key={docSel.id}
                                                                style={{ display: 'inline-block', marginBottom: 10, marginTop: 0, marginRight: 10, top: 0 }}
                                                                className="p-field-radiobutton inline whiteSpaceNoWrap">
                                                                <RadioButton
                                                                    inputId={docSel.id}
                                                                    name="docSel"
                                                                    value={docSel}
                                                                    onChange={(e) => this.setState({ selectedSemanticExportDocSelection: e.value })}
                                                                    checked={this.state.selectedSemanticExportDocSelection && this.state.selectedSemanticExportDocSelection.id === docSel.id}
                                                                />
                                                                <label style={{ marginRight: 10 }} htmlFor={docSel.id}>{docSel.label}</label>
                                                                <InputText
                                                                    //size={5}
                                                                    style={{ width: 100 }}
                                                                    type="search"
                                                                    //className="collectionFilter"
                                                                    onInput={(e) => this.setState({ exportLimit: e.target.value })}
                                                                    placeholder={`max. ${EXPORTER_NUM_OF_DOCS_LIMIT}`}
                                                                />
                                                            </div>
                                                        }
                                                        else {
                                                            //if (docSel.id !== 'selDocs' || hasSelectedDocuments) {
                                                            return <div key={docSel.id}
                                                                style={{ display: 'inline-block', marginBottom: 10, marginTop: 0, marginRight: 10, top: 0 }}
                                                                className="p-field-radiobutton inline whiteSpaceNoWrap">
                                                                <RadioButton
                                                                    inputId={docSel.id}
                                                                    name="docSel"
                                                                    value={docSel}
                                                                    onChange={(e) => this.setState({ selectedSemanticExportDocSelection: e.value })}
                                                                    checked={this.state.selectedSemanticExportDocSelection && this.state.selectedSemanticExportDocSelection.id === docSel.id}
                                                                />
                                                                <label style={{ marginRight: 10 }} htmlFor={docSel.id}>
                                                                    {docSel.label}{docSel.id === 'selDocs' ? ` (${this.props.selectedDocuments ? Object.values(this.props.selectedDocuments).length : ''})` : ''}
                                                                </label>
                                                            </div>
                                                        }
                                                    })}
                                            </div>
                                            <div className="breakRow"></div>

                                            <div className="col-fixed" style={{ width: 130 }}>Extract entities</div>
                                            <div className="col">
                                                <Extractor
                                                    allowCustomInput={true}
                                                    onExport={this.onSemanticExportSubmit}
                                                    appendSuggestionsTo={document.body}
                                                />
                                            </div>
                                            <div className="breakRow"></div>
                                        </div>
                                    </TabPanel>
                                })}

                            </TabView>
                        </div>
                    </LoadingOverlay>
                </Dialog>
            </>
        )
    }

    // --- set default props --- //
    static defaultProps = {
        repositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE
    }
}

const mapStateToProps = state => ({
    userData: state.user.data,
    additionalDocumentData: state.document.additionalDocumentData
})

const mapDispatchToProps = dispatch => ({
    fetchAdditionalDocumentData: (repoID, docID) => dispatch(fetchAdditionalDocumentData(repoID, docID)),
    updateDocumentRating: (repoID, docID, title, stars) => dispatch(updateDocumentRating(repoID, docID, title, stars)),
    updateDocumentReadStatus: (repoID, docID, title, isRead) => dispatch(updateDocumentReadStatus(repoID, docID, title, isRead)),
    // @todo
    //addDocumentToCollections: (repoID, docID, title, docCollectionIDs) => dispatch(addDocumentToCollections(repoID, docID, title, docCollectionIDs)),
    //removeDocumentFromCollection: (repoID, docID, docCollID, docCollDocLink) => dispatch(removeDocumentFromCollection(repoID, docID, docCollID, docCollDocLink)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentResultWrapper))