export const TREND_ANALYSIS_EXAMPLES = [
    {
        // id is mandatory, it will be used as key,
        // since there can be multiple sections
        id: "trend",
        description: "See a trend analysis for a topic of your interest.",
        examples: [{
            label: 'aspirin',
            //description: 'See a trend analysis for "aspirin".',
            queryString: '+(ocid:190000021540 ocid:229960001138)',
        }, {
            label: 'covid-19',
            //description: 'See a trend analysis for "covid-19".',
            queryString: '+(ocid:208000025721)',
        }, {
            label: 'malaria',
            //description: 'See a trend analysis for "malaria".',
            queryString: '+(ocid:201000004792)',
        }]
    }
]