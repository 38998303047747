import React, { useState, useRef } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Accordion, AccordionTab } from "primereact/accordion";
import { Badge } from 'primereact/badge';
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { OverlayPanel } from "primereact/overlaypanel";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from 'primereact/toast';
import { addThousandsSeparatorToNumber } from '../../webapi/util';
import {
    setSelectedSentenceAnalysis
} from '../../../redux/actions/SentenceAnalysisActions';
import { scrollToElementViaScrollToButton } from "../docview/util/htmlHandling";
import DocumentInformation from './DocumentInformation'
import DocumentMetadataInfo from '../docparts/DocumentMetadataInfo';
import LibraryControls from './LibraryControls'
import PatFamilyMembersOverlay from './PatFamilyMembersOverlay'
import DocumentIDLinks from "../general/DocumentResult/DocumentIDLinks";
import {
    fetchDocumentAsPDF
} from '../../../api/content/DocumentApi';
import { checkResultAndGetPayload } from "../../../api";
//import { APP_PROPERTIES } from '../../../properties/index';
import './SentenceAnalysis.css'
import $ from 'jquery';
// eslint-disable-next-line import/no-webpack-loader-syntax
import cssFile from '!!css-loader?{"sourceMap":false,"exportType":"string"}!../docview/docTextToPDF.css';
import utf8 from 'utf8'
import { saveAs } from 'file-saver';

const DocumentList = React.memo((props) => {

    //const [documents, setDocument] = useState(props.selectedDocuments)
    //const [selectedSentenceOption, setSelectedSentenceOption] = useState(props.selectedSentenceOption)
    const [isCustomClick, setIsCustomClick] = useState(false)
    const settingsOverlay = useRef();
    const growl = useRef(null);
    const [isLoadingPDF, setIsLoadingPDF] = useState(false)

    const sentenceTermsOptions = [{
        name: 'with any term',
        value: 'all'
    }, {
        name: 'with minimum number of terms:',
        value: 'threshold'
    }];

    const highlightTermsOptions = [{
        name: 'all terms',
        value: false
    }, {
        name: 'only terms in matching sentences',
        value: true
    }];

    const sentenceOptions = sentenceTermsOptions.map((option) => {
        let importantTermsNumber = props.selectedQueryTerms.filter(term => term.important === true).length;

        return (
            <div key={option.name} className="p-field-radiobutton" style={{ marginLeft: 5, marginBottom: 5, marginRight: 10, marginTop: option.value === 'threshold' ? -5 : 0 }}>
                <RadioButton style={{ verticalAlign: 'baseline', marginRight: 5, cursor: option.value === 'threshold' && props.selectedQueryTerms && props.selectedQueryTerms.length < 2 ? 'default' : 'pointer' }} inputId={option.name} name="category" value={option}
                    onChange={(e) => sentenceOptionChange(option)}
                    checked={props.selectedSentenceOption === option.value}
                    disabled={option.value === 'threshold' && props.selectedQueryTerms && props.selectedQueryTerms.length < 2 && !(importantTermsNumber > 0)} />
                <label style={{ color: option.value === 'threshold' && props.selectedQueryTerms && props.selectedQueryTerms.length < 2 && !(importantTermsNumber > 0) ? '#757575' : 'black' }}
                    htmlFor={option.name}>{option.name}</label> {option.value === 'threshold' ?
                        <span style={{ display: 'inline-block' }}>
                            <Button style={{ color: 'black', backgroundColor: 'white', verticalAlign: 'baseline' }}
                                icon="pi pi-minus" className='p-button-sm iconButton'
                                onClick={(e) => onThresholdChange(e, "minus")}
                                disabled={props.threshold === 1 || props.selectedSentenceOption === 'all'}></Button>
                            <span style={{ color: props.selectedSentenceOption === 'all' ? '#757575' : 'black' }}>{props.threshold}</span>
                            <Button style={{ color: 'black', backgroundColor: 'white', verticalAlign: 'baseline' }}
                                icon="pi pi-plus" className='p-button-sm iconButton'
                                onClick={(e) => onThresholdChange(e, "plus")}
                                disabled={props.threshold === props.selectedQueryTerms.length || props.selectedSentenceOption === 'all'}></Button>
                        </span>
                        : null
                }
            </div>
        )
    });

    const highlightOptions = highlightTermsOptions.map((option) => (
        <div key={option.name} className="p-field-radiobutton" style={{ marginLeft: 5, marginBottom: 5, marginRight: 10 }}>
            <RadioButton style={{ verticalAlign: 'baseline', marginRight: 5, cursor: props.selectedQueryTerms && props.selectedQueryTerms.length < 1 ? 'default' : 'pointer' }} inputId={option.name} name="category" value={option}
                onChange={(e) => props.highlightOptionChange(option.value)}
                checked={props.showSentenceEntities === option.value}
                disabled={props.selectedQueryTerms && props.selectedQueryTerms.length < 1} />
            <label style={{ color: props.selectedQueryTerms && props.selectedQueryTerms.length < 1 ? '#757575' : 'black' }}
                htmlFor={option.name}>{option.name}</label>
        </div>
    ));

    const sentenceOptionChange = (option) => {
        props.selectedSentenceOptionChange(option.value)
    }

    const onThresholdChange = (e, action) => {
        const oldThreshold = props.threshold
        let newThreshold
        if (action === "plus") {
            newThreshold = oldThreshold + 1
        } else {
            newThreshold = oldThreshold - 1
        }
        props.thresholdChange(newThreshold)
    }

    const activeIndexChange = (index) => {
        //if (index !== null)
        if (!isCustomClick)
            onIndexChange(index)
    }

    const onCustomClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsCustomClick(true)
    }

    const onDocumentSelectionChange = (e, ocDocId, repositoryId, title) => {
        setIsCustomClick(true)
        props.onDocumentSelectionChange(e, ocDocId, repositoryId, title)
    }

    const onExport = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsCustomClick(true)

        setIsLoadingPDF(true)
        let fileString = `<html>
        <head>
        <style>
        ${cssFile}
        </style>
        </head>
        <body>
        ${props.docHTML?.replace('#fcf492', '#faf7d2')}
        </body>
        </html>`
        let request = {
            filename: `${props.selectedDocument}.html`,
            filedata: btoa(utf8.encode(fileString))
        }
        const response = await fetchDocumentAsPDF(request);
        const pdfFile = checkResultAndGetPayload(response, growl);

        if (pdfFile && pdfFile.pdf) {
            let content = atob(pdfFile.pdf)
            let dataArray = new Array(content.length);
            for (let i = 0; i < content.length; i++) {
                dataArray[i] = content.charCodeAt(i);
            }
            let byteArray = new Uint8Array(dataArray)
            saveAs(new Blob([byteArray]), `${props.selectedDocument}.pdf`)
        }
        setIsLoadingPDF(false)
    }

    const renderTitle = (doc, index) => {
        return <React.Fragment>
            <span className={index === props.activeIndex ? 'documentIndex read' : 'documentIndex'}>{index + 1}</span>
            <span style={{ display: 'inline-block' }}>
                <Checkbox inputId='userPassword'
                    onChange={(e) => onDocumentSelectionChange(e, doc.metadata?.ocDocId, doc.metadata?.repositoryId, doc.metadata?.title)}
                    checked={props.selectedDocumentsAction.some(document => document['docId'] === doc.metadata.ocDocId)}
                    value='' style={{ marginRight: 8, position: 'relative', top: -1 }}>
                </Checkbox>
            </span>
            <span style={{ marginRight: 10 }} className='activeTitleLink'>{doc.metadata.title}</span>
            {!props.loader ?
                props.activeIndex === index ?
                    <>
                        <span title={`${props.documentSentences?.length} matching ${props.documentSentences?.length !== 1 ? 'sentences' : 'sentence'}`}>
                            <Badge style={{ backgroundColor: '#ddd', fontSize: 'smaller', color: 'black', fontWeight: 400, marginRight: 10 }}
                                value={`${props.documentSentences?.length}`}>
                            </Badge>
                        </span>
                        {!props.documentIsPDF ?
                            <span>
                                <a style={{ color: '#007fdb', fontSize: 13 }} onClick={(e) => onExport(e)}>Export</a>
                            </span>
                            : null
                        }
                    </>
                    :
                    null
                : null
            }
            <React.Fragment>
                {props.activeIndex === index ?
                    <div className='documentTitleMetadata' key={`'lib_'${doc.metadata.ocDocId}`} onClick={(e) => onCustomClick(e)}>
                        <LibraryControls
                            doc={doc}
                            onAddToCollections={props.onAddToCollections}
                            onRemoveFromCollections={props.onRemoveFromCollections}
                            selectedDocumentsAction={props.selectedDocumentsAction}
                            selectedDocument={props.selectedDocument}
                            onDocumentSelectionChange={props.onDocumentSelectionChange}
                            onCustomClick={(e) => onCustomClick(e)}
                            documentCollections={props.documentCollections}
                        >
                        </LibraryControls>
                    </div>
                    : null
                }
                <div className='documentTitleMetadata' key={`'info_'${doc.metadata.ocDocId}`} onClick={(e) => onCustomClick(e)}>
                    <DocumentMetadataInfo
                        key={doc.metadata.ocDocId}
                        repositoryInfo={doc.repoInfo}
                        documentData={doc.metadata}
                        organisms={null}
                        compactViewSentenceAnalysis={props.selectedMetadataOption === 'compact' ? true : false}
                        showCustomInfo={true}
                    //oneLineOverflow={true}
                    />
                    {!props.loader ?
                        props.activeIndex === index ? //&& props.repositoryInfo !== null
                            //&& (doc.metadata['pat_document-id'] || doc.metadata['Pat_document-id']) ?
                            <>
                                <span style={{}}>
                                    <span id={`identifiers_${doc.metadata.ocDocId}`}
                                        className="doc-additional-data-entry">
                                        <DocumentIDLinks documentData={doc.metadata} showCustomPatentLinks={true} />
                                    </span>
                                </span>
                                {doc.metadata['pat_document-id'] || doc.metadata['Pat_document-id'] ?
                                    <span id={`famMembers_${doc.metadata.ocDocId}`} style={{ fontWeight: 'bold' }}
                                        className="doc-additional-data-entry"
                                        title="Matching members in this patent family">
                                        <a style={{ color: '#007fdb' }} onClick={(e) => props.onShowFamilyMembers(e, doc.metadata)}>Show family members</a>
                                        {props.patFamilyMembers && props.patFamilyMembers.length > 0 ?
                                            <PatFamilyMembersOverlay
                                                patFamilyMembers={props.patFamilyMembers}
                                                showFamilyMembersDialog={props.showFamilyMembersDialog}
                                                onCloseFamilyMembersDialog={props.onCloseFamilyMembersDialog}
                                                selectedPatent={doc.metadata['pat_document-id'] ? doc.metadata['pat_document-id'] : doc.metadata['Pat_document-id']}
                                                selectedFamilyDocuments={props.selectedFamilyDocuments}
                                                selectedFamilyDocumentsRemove={props.selectedFamilyDocumentsRemove}
                                                handleSelectionChange={props.handleSelectionChange}
                                                onAddFamilyMembers={props.onAddFamilyMembers}
                                                onRemoveFamilyMembers={props.onRemoveFamilyMembers}
                                                allDocuments={props.selectedDocuments}
                                            >
                                            </PatFamilyMembersOverlay>
                                            : null
                                        }
                                    </span>
                                    : null
                                }
                            </>
                            : null
                        : null
                    }
                </div>
            </React.Fragment>
        </React.Fragment >
    }

    const onNextClick = () => {
        if (props.selectedSentence && props.documentSentences) {
            let sentenceIndex = props.documentSentences.indexOf(props.selectedSentence)
            const newIndex = sentenceIndex + 1
            if (newIndex < props.documentSentences.length) {
                props.onSetSelectedSentence(props.documentSentences[newIndex])
                if (props.documentIsPDF) {
                    scrollToElementViaScrollToButton('#scrollToBtnSent', '#' + props.documentSentences[newIndex])
                    document.location.hash = `highlight-${props.documentSentences[newIndex]}`;
                }
            } else {
                const newActiveIndex = props.activeIndex + 1
                onIndexChange(newActiveIndex)
            }
        } else {
            const newActiveIndex = props.activeIndex + 1
            onIndexChange(newActiveIndex)
        }
    }

    const onIndexChange = (newActiveIndex) => {
        props.activeIndexChange(newActiveIndex)
    }

    const onNextDocumentClick = () => {
        const newActiveIndex = props.activeIndex + 1
        props.activeIndexIncreaseChange(true);
        onIndexChange(newActiveIndex)
    }

    const onPrevClick = () => {
        if (props.selectedSentence && props.documentSentences) {
            let sentenceIndex = props.documentSentences.indexOf(props.selectedSentence)
            const newIndex = sentenceIndex - 1
            if (newIndex >= 0) {
                props.onSetSelectedSentence(props.documentSentences[newIndex])
                if (props.documentIsPDF) {
                    scrollToElementViaScrollToButton('#scrollToBtnSent', '#' + props.documentSentences[newIndex])
                    document.location.hash = `highlight-${props.documentSentences[newIndex]}`;
                }
            } else {
                const newActiveIndex = props.activeIndex - 1
                onIndexChange(newActiveIndex)
            }
        } else {
            const newActiveIndex = props.activeIndex - 1
            onIndexChange(newActiveIndex)
        }
    }

    const onPrevDocumentClick = () => {
        const newActiveIndex = props.activeIndex - 1
        props.activeIndexIncreaseChange(false)
        onIndexChange(newActiveIndex)
    }

    /*const refineSearch = () => {
        console.log("refine")
    }*/

    const availableSentenceListHeight = $("#controlDiv").height() - 100;

    const metadataOptions = [
        { label: 'Compact view', value: 'compact' },
        { label: 'Detailed view', value: 'detailed' }
    ]

    const sortOptions = [
        { label: 'ascending', value: 'ascending' },
        { label: 'descending', value: 'descending' }
    ]

    //console.log(props.sortDirectionDisabled)

    var htmlObj = $('<div>' + '</div>');
    htmlObj.append('<span id="scrollToBtnDoc" onclick="scrollToHtmlElement(\'#scrollToBtnDoc\', \'#' + "sentenceScroll" + '\')" style="display:none;"></span>');

    return (
        <div className="col-12 p-nogutter p-0" style={{ paddingLeft: 6 }}>
            <Toast ref={growl} />
            <LoadingOverlay
                active={isLoadingPDF}
                spinner={true}
                text='Exporting PDF...'>
                <div className='col-12 px-0 pt-0' style={{ paddingTop: 10, paddingBottom: 10, borderBottom: '1px solid #dfdfdf' }}>
                    <div style={{ borderBottom: '1px solid #dfdfdf', paddingBottom: 5, marginBottom: 10 }}>
                        <span>
                            <label style={{ fontWeight: 'bolder', paddingRight: 10 }}>
                                {`${props.selectedDocuments ? addThousandsSeparatorToNumber(props.selectedDocuments.length) : ""}`} documents</label>
                            {/*<span style={{ opacity: 0.7, paddingLeft: 10, paddingRight: 10 }}>&nbsp;|&nbsp;</span>
    <label style={{ fontWeight: 'bolder', paddingRight: 10 }}>xxx sentences</label>
                            <Button
                                className="p-button-text primaryButtonAsLink"
                                title="Filter documents"
                                style={{ marginRight: 5 }}
                                onClick={() => refineSearch()}>
                                Filter
                            </Button>
                            <i className='pi pi-filter mr-2' style={{ fontSize: '1em', color: '#0973c0', paddingRight: 5 }}></i>
                        */}
                        </span>
                    </div>
                    <div id='controlDiv' style={{ paddingTop: 0 }}>
                        <div style={{ paddingTop: 0, marginBottom: 10 }} className='col-12'>
                            <Button className='primaryButton p-button-sm'
                                title='Jump to next document'
                                style={{ padding: '0.2rem', marginTop: -3, float: 'right', marginRight: 7, height: 22, width: '35%' }}
                                onClick={() => onNextDocumentClick()}
                                disabled={props.activeIndex === null || (props.activeIndex === props.selectedDocuments.length - 1) ? true : false}>
                                Next document
                                <i className='pi pi-angle-double-right' style={{ position: 'absolute', right: 0 }}></i>
                            </Button>
                            <Button className='primaryButton p-button-sm'
                                title='Jump to next sentence'
                                style={{ padding: '0.2rem', marginRight: 7, marginTop: -3, float: 'right', height: 22, width: '35%' }}
                                onClick={() => onNextClick()}
                                disabled={props.activeIndex === null || ((props.documentSentences?.length === 0 || props.selectedSentence === props.documentSentences[props.documentSentences.length - 1])
                                    && props.activeIndex === props.selectedDocuments?.length - 1) ? true : false}>
                                Next sentence
                                <i className='pi pi-angle-right' style={{ position: 'absolute', right: 0 }}></i>
                            </Button>
                            <Button className='primaryButton p-button-sm'
                                title='Jump to previous sentence'
                                style={{
                                    marginRight: 7, padding: '0.2rem', marginTop: -3, float: 'right', height: 22, width: '10%'
                                }}
                                onClick={() => onPrevClick()}
                                disabled={props.activeIndex === null || ((props.documentSentences?.indexOf(props?.selectedSentence) === 0 || props.documentSentences?.length === 0)
                                    && props.activeIndex === 0) ? true : false}>
                                <i className='pi pi-angle-left' style={{ position: 'absolute', left: '30%' }}></i>
                            </Button>
                            <Button className='primaryButton p-button-sm'
                                title='Jump to previous document'
                                style={{ marginRight: 7, padding: '0.2rem', marginTop: -3, float: 'right', height: 22, width: '10%' }}
                                onClick={() => onPrevDocumentClick()}
                                disabled={props.activeIndex === null || props.activeIndex === 0 ? true : false}>
                                <i className='pi pi-angle-double-left' style={{ position: 'absolute', left: '30%' }}></i>
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ borderBottom: '1px solid #dfdfdf', paddingBottom: 5 }}>
                    <React.Fragment>
                        <a className="infoIconLink valignMiddle"
                            style={{ marginTop: 9, marginBottom: 5, marginLeft: 0, color: 'black', fontWeight: 'bolder' }}
                            title="Open settings"
                            onClick={e => settingsOverlay.current.toggle(e)}
                        >
                            <i style={{ paddingRight: 5 }} className="pi pi-angle-down"></i>
                            Settings
                        </a>
                        <OverlayPanel
                            className="sentenceAnalysisOverlay"
                            ref={settingsOverlay}
                            dismissable={true}>
                            <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                                <div>
                                    <div style={{ marginBottom: 10, marginLeft: 5 }}>
                                        <label>Show sentences</label>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        {sentenceOptions}
                                    </div>
                                </div>
                                <div>
                                    <div style={{ marginBottom: 10, marginLeft: 5 }}>
                                        <label>Highlight</label>
                                    </div>
                                    <div style={{ paddingBottom: 0, display: 'flex' }}>
                                        {highlightOptions}
                                    </div>
                                </div>
                                <div>
                                    <div style={{ paddingBottom: 0, display: 'flex', marginLeft: 5 }}>
                                        <span style={{ marginTop: 15 }}><label>Limit to sections: </label>
                                        </span>
                                        <Dropdown style={{ marginTop: 8 }}
                                            disabled={props.repositoryInfo?.name === 'dspub' || props.savedSearch === null || props.repositoryInfo?.name?.startsWith('rc_')}
                                            className='dropdownNoBorder'
                                            value={props.selectedSections}
                                            options={props.sectionOptions}
                                            optionLabel="filterValueLabel"
                                            appendTo={document.body}
                                            onChange={(e) => props.handleSectionChange(e.value)}
                                            placeholder='Whole document'>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ paddingBottom: 0, display: 'flex', marginLeft: 5 }}>
                                        <span style={{ marginTop: 5 }}><label>Show metadata: </label>
                                        </span>
                                        <Dropdown style={{ marginTop: -2 }}
                                            className='dropdownNoBorder'
                                            value={props.selectedMetadataOption}
                                            options={metadataOptions}
                                            appendTo={document.body}
                                            onChange={(e) => props.handleMetadataChange(e.value)}
                                            placeholder='Select metadata'>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div style={{ borderTop: '1px solid #d6d6d6' }}>
                                    <div style={{ paddingBottom: 0, display: 'flex', marginLeft: 5, marginTop: 5 }}>
                                        <span style={{ marginTop: 5 }}><label>Sort documents by: </label>
                                        </span>
                                        <Dropdown style={{ marginTop: -2 }}
                                            disabled={props.savedSearch === null}
                                            className='dropdownNoBorder'
                                            value={props.selectedSortField}
                                            options={props.sortFields}
                                            appendTo={document.body}
                                            onChange={(e) => props.handleSortFieldChange(e)}
                                            placeholder='Select sort field'>
                                        </Dropdown>
                                        <Dropdown style={{ marginTop: -2 }}
                                            className='dropdownNoBorder'
                                            value={props.selectedSortDirection}
                                            disabled={props.sortDirectionDisabled || props.savedSearch === null}
                                            options={sortOptions}
                                            appendTo={document.body}
                                            onChange={(e) => props.handleSortDirectionChange(e)}
                                            placeholder='Select sort direction'>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </React.Fragment>
                </div>
                {
                    props.selectedDocuments &&
                    <div id='sentenceScroll' className='sentenceScroll' style={{
                        height: availableSentenceListHeight ? availableSentenceListHeight > 0 ? props.availableContentHeight - 17 : props.availableContentHeight + 39 : '100vh',
                        //"calc(100vh - 130px)",
                        overflow: 'auto', overflowX: 'hidden',
                        marginTop: 5
                    }}>
                        <Accordion
                            expandIcon="pi pi-chevron-up"
                            collapseIcon="pi pi-chevron-down"
                            className="simpleAccordion documents"
                            multiple={false}
                            activeIndex={props?.activeIndex}
                            onTabChange={(e) => {
                                setTimeout(() => {
                                    if (!isCustomClick) {
                                        activeIndexChange(e.index)
                                    } else {
                                        setIsCustomClick(false)
                                    }
                                }, 20)

                            }}>
                            {
                                props.selectedDocuments?.map((doc, i) => {
                                    //console.log(doc)
                                    return <AccordionTab
                                        key={doc.metadata?.ocDocId}
                                        id={doc.metadata?.ocDocId}
                                        header={renderTitle(doc, i)}>
                                        <DocumentInformation
                                            key={`info_${doc.metadata?.ocDocId}`}
                                            doc={doc}
                                            sentences={props.sentences}
                                            selectedSentence={props.selectedSentence}
                                            loader={props.loader}
                                            onSetSelectedSentence={props.onSetSelectedSentence}
                                            documentIsPDF={props.documentIsPDF}
                                        />
                                    </AccordionTab>
                                })
                            }
                        </Accordion>
                        <span dangerouslySetInnerHTML={{ __html: htmlObj.html() }}></span>
                    </div>
                }
            </LoadingOverlay >
        </div >
    )
})

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetSelectedSentence: (id) =>
            dispatch(setSelectedSentenceAnalysis(id))
    }
}

const areEqual = (prevProps, nextProps) => {
    if ((nextProps.selectedSentence === prevProps.selectedSentence) && nextProps.loader === true) {
        return true
    }
    return false
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((React.memo(DocumentList, areEqual))))
