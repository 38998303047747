import {createDropdownOptions} from "./createDropdownOptions";

export const createOptionsForSearchTemplate = (item, truncatedTerm) => {
    const hasTooltip = (item.term && item.term[0] !== truncatedTerm && item.term[0] !== "Any") || (item.preferredName && item.preferredName !== truncatedTerm);
    const hasDropDown = !item.domainTerms; // no sense render dropdown for 'any' case
    const lookupTypeField = item.conceptLookupType ? 'conceptLookupType' : 'termLookupType';
    const lookupTypeValue = item.conceptLookupType || item.termLookupType;
    const dropdownClassName = item.occurrence === 'MUST' ? 'autocompleteDropdown' : 'autocompleteDropdownExclude';
    const dropdownOptions = createDropdownOptions(item);
    const dropdownValue = {[lookupTypeField]: lookupTypeValue, occurrence: item.occurrence};

    return {hasTooltip, hasDropDown, dropdownValue, dropdownClassName, dropdownOptions}
}
