import React, { useEffect, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import './BiomarkerLeftAside.css';
import { useDispatch, useSelector } from "react-redux";
import {
    setBiomarkerHeadersStructureSkeleton, setBiomarkerQueryTerms, setPopupExpandedKeys, setPopupNodes, setPopupTreeName,
    setBiomarkerSelectedReposForSearch, setBiomarkerLeftAsideExpandedKeys
} from "../../../../redux/actions/BiomarkerActions";
import { LeftAsideNodeTemplate } from "../../../common/LeftAsideNodeTemplate/LeftAsideNodeTemplate";
import LeftAsideTree from "../../../common/LeftAsideTree/LeftAsideTree";
import { sendApiRequest } from "../../../../api";
import { addFieldNameToTree } from "../../../common/helpers/addFieldNameToTree";
import {
    biomarkerAggregationResults,
    biomarkerHeadersStructureSkeleton,
    biomarkerLeftAsideExpandedKeys,
    biomarkerNodes, biomarkerPopupNodes, biomarkerQueryTerms,
    biomarkerReposWithBiomarkerSearchFeature,
    biomarkerRoleMapping, biomarkerSelectedReposForSearch
} from "../../../../redux/selectors/selectors";
import { createHeadersSkeleton } from "../../../common/helpers/createHeadersSkeleton";
import { addAnyToQueryTerms } from "../../../common/helpers/addAnyToQueryTerms";
import { APP_PROPERTIES } from '../../../../properties';

export const BiomarkerLeftAside = ({
    setIsDialogVisible,
    setPopupIsLoading,
    showToast,
    setTargetToScroll
}) => {
    const aggregationResults = useSelector(biomarkerAggregationResults);
    const roleMapping = useSelector(biomarkerRoleMapping);
    const headersStructureSkeleton = useSelector(biomarkerHeadersStructureSkeleton);
    const leftAsideExpandedKeys = useSelector(biomarkerLeftAsideExpandedKeys);
    const nodes = useSelector(biomarkerNodes);
    const reposWithBiomarkerSearchFeature = useSelector(biomarkerReposWithBiomarkerSearchFeature);
    const queryTerms = useSelector(biomarkerQueryTerms);
    const selectedReposForSearch = useSelector(biomarkerSelectedReposForSearch);
    const popupNodes = useSelector(biomarkerPopupNodes);

    const dispatch = useDispatch();
    const repoNames = useMemo(() => reposWithBiomarkerSearchFeature?.map(repo => repo.label), [reposWithBiomarkerSearchFeature]);
    const barChartData = useMemo(() => aggregationResults?.srcrep?.filter(item => repoNames.includes(item.term)), [aggregationResults, repoNames]);

    const { width, ref } = useResizeDetector();

    // create filters from skeleton and exclude repositories - it has other behavior
    const filters = useMemo(() => roleMapping.map(role => role.name).filter(name => name !== undefined), [roleMapping]);

    // prepare facettes to render and limit it's amount
    const facettes = useMemo(() => {
        const results = {};

        for (let filter of filters) {
            results[filter] = aggregationResults?.[filter]//?.slice(0, maxAmount);
        }
        return results;
    }, [filters, aggregationResults]);

    useEffect(() => {
        const headersSkeleton = createHeadersSkeleton(nodes, facettes);
        dispatch(setBiomarkerHeadersStructureSkeleton(headersSkeleton));

    }, [dispatch, nodes, facettes]);

    const handleSelect = ({ value }) => {
        dispatch(setBiomarkerSelectedReposForSearch(value));
    }

    const handleCheckboxAny = (fieldName) => {
        const commonQueries = queryTerms.filter(({ domainTerms }) => !domainTerms);
        let anyQueries = queryTerms.filter(({ domainTerms }) => domainTerms);
        const isAdded = !!anyQueries.filter(item => item.fieldName === fieldName).length;
        if (commonQueries && commonQueries.length > 0) {
            let filteredObject = commonQueries.filter(function (item) {
                return (item.fieldName && item.fieldName === fieldName && item.occurrence === "MUST") ||
                    (item.occurrence === "MUST" && item.biomarkerRoles && item.biomarkerRoles[0] === roleMapping.find(role => role.name === fieldName)?.label)
            })
            if (filteredObject.length > 0 && !isAdded) {
                showToast(`By filtering with "Any", all filtered specific terms for that role will be ignored.`, 'Warning', 'warn');
            }
        }
    
    const _queryTerms = addAnyToQueryTerms(fieldName, queryTerms)
    dispatch(setBiomarkerQueryTerms(_queryTerms));
}

const handleLinkClick = async (node) => {
    try {
        setIsDialogVisible(true);
        setTargetToScroll(null);

        if (popupNodes?.[0].label === node.tree) {
            const keyToExpand = popupNodes[0].key;
            dispatch(setPopupExpandedKeys({ [keyToExpand]: true }));
        } else {
            dispatch(setPopupTreeName(node.tree))

            if (popupNodes) {
                dispatch(setPopupNodes(null));
            }

            setPopupIsLoading(true);
            const response = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/${node.url}`);

            const keyToExpand = response.payload[0].key;

            // add fieldName to nodes. this field is required in queryTerms
            const nodesWithFieldName = addFieldNameToTree(response.payload[0], node.name);
            dispatch(setPopupNodes(nodesWithFieldName));

            dispatch(setPopupExpandedKeys({ [keyToExpand]: true }));
        }
    } catch (e) {
        showToast(`Something went wrong while loading data\n ${e} `, 'Error occurred', 'error');
    } finally {
        setPopupIsLoading(false);
    }
}

const processDataForBarChart = (RepositoriesBarChartData) => {
    const reducer = (accumulator, currentValue) => {
        accumulator[0][currentValue.term] = currentValue.count;
        return accumulator;
    }

    return RepositoriesBarChartData?.reduce(reducer, [{}]);
}

const onToggle = (e) => {
    dispatch(setBiomarkerLeftAsideExpandedKeys(e.value));
}

// to prevent redundant chart rerender useMemo is used
const memoizedChartData = useMemo(() => processDataForBarChart(barChartData), [barChartData]);
const tooltipText = 'The biomarker information comes from many different sources that can be selected here.' +
    'The selection to choose from may vary depending on the licences available.'

// custom node creating
const nodeTemplate = (node) => <LeftAsideNodeTemplate node={node} setIsDialogVisible={setIsDialogVisible}
    setPopupIsLoading={setPopupIsLoading}
    showToast={showToast} setTargetToScroll={setTargetToScroll}
    repositoriesArray={reposWithBiomarkerSearchFeature}
    handleSelect={handleSelect}
    memoizedChartData={memoizedChartData}
    chartWidth={width} handleCheckboxAny={handleCheckboxAny}
    facettes={facettes} queryTerms={queryTerms}
    setQueryTerms={setBiomarkerQueryTerms}
    tooltipText={tooltipText}
    selectedReposForSearch={selectedReposForSearch}
    handleLinkClick={handleLinkClick}
/>

return (
    <>
        <h2 style={{ marginBottom: 0 }}>Filters</h2>
        <LeftAsideTree headersStructureSkeleton={headersStructureSkeleton} nodeTemplate={nodeTemplate}
            leftAsideExpandedKeys={leftAsideExpandedKeys} ref={ref} onToggle={onToggle} />
    </>
);
};
