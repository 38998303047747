//import "./DocumentQuickInfoLinks.css";

import React from "react";
import { convertInitialLetterToUpperCase } from "../../util";
import SeparatorPoint from "../SeparatorPoint";


const DocumentQuickInfoLinks = ({
    documentData,
    activeDocParts,
    docPartsInfo,
    onLoadDocumentPart
}) => {

    const ocDocID = documentData.ocDocId;

    let defaultDocPart;
    if (activeDocParts && docPartsInfo && docPartsInfo[ocDocID] &&
        docPartsInfo[ocDocID].namedDocParts) {
        for (let docPart of Object.keys(activeDocParts)) {
            if (docPartsInfo[ocDocID].namedDocParts[docPart]) {
                defaultDocPart = docPart;
                break;
            }
        }
    }
    
    return (

        <>
            {/*<span style={{ fontSize: 11 }} //style={{ display: 'inline-block', marginRight: 3, fontSize: 12, position: 'relative', top: 1 }}
                className="material-icons">crop_free</span>*/}

            <span style={{ display: 'inline-block' }}>
                {activeDocParts && docPartsInfo &&
                    docPartsInfo[ocDocID]?.hasDocParts &&
                    docPartsInfo[ocDocID]?.namedDocParts ?
                    <span>Quick info:</span>
                    :
                    <a className="secondaryLink"
                        title={`Show document quick info`}
                        style={{ fontWeight: 'bold' }}
                        onClick={(e) => onLoadDocumentPart(ocDocID, defaultDocPart, {}, activeDocParts, documentData)}>
                        Quick info
                    </a>
                }
            </span>

            {activeDocParts && docPartsInfo &&
                docPartsInfo[ocDocID]?.hasDocParts &&
                docPartsInfo[ocDocID]?.namedDocParts ?
                <span title="Preview of certain document parts"
                    style={{ marginLeft: 8 }}>
                    {Object.keys(activeDocParts)
                        .filter(docPart => {
                            return !!docPartsInfo[ocDocID].namedDocParts[docPart];
                        })
                        .map((docPart, index) => {
                            return (
                                <span id={`docPart_${documentData.ocDocId}_${docPart}`} key={docPart}>
                                    {(index !== 0) && <SeparatorPoint margin={4} />}
                                    <a className="secondaryLink"
                                        title={`Show preview`}
                                        style={{ fontWeight: 'bold' }}
                                        onClick={(e) => onLoadDocumentPart(ocDocID, docPart,
                                            docPartsInfo[ocDocID].namedDocParts, activeDocParts, documentData)}>
                                        {docPart === 'dsabstract' ? 'Abstract' : convertInitialLetterToUpperCase(docPart)}
                                    </a>
                                </span>
                            );
                        })}
                </span> : null
            }
        </>
    )
}

export default DocumentQuickInfoLinks;