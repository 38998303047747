import * as ActionTypes from '../actions'
//import { Router, Route } from 'react-router-dom'

const initialState = {
  currentTutorial: []
}

const tutorialReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STAR_TUTORIAL:
            return {
                ...state,
                currentTutorial: action.tutorial
            }
        default:
            return state
    }
}

export default tutorialReducer
