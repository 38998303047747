import { useRef } from 'react';
import { Calendar } from "primereact/calendar";
import styles from './DatePicker.module.scss';
import { useDispatch, useSelector } from "react-redux";
import {
    setEndDate,
    setStartDate,
    setTimeRange,
} from "../../../../../../redux/actions/YearStatisticsActions";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";

export const DatePicker = ({ showToast, checkLimit }) => {
    const startDate = useSelector(({ yearStatistics: { startDate } }) => startDate);
    const endDate = useSelector(({ yearStatistics: { endDate } }) => endDate);
    const period = useSelector(({ yearStatistics: { period } }) => period);
    const endDateRef = useRef();
    const dispatch = useDispatch();
    const focusedDiv = useRef();

    const lastAvailableYear = 1800;
    const currentYear = new Date().getFullYear();
    const minDate = new Date(1800, 0, 1);
    const today = new Date();
    const maxStartDate = new Date(moment(today).subtract(1, 'days').format());

    const createYearOptions = (date) => {
        let options = [];

        for (let i = lastAvailableYear; i <= currentYear; i++) {
            const value = new Date(date).setFullYear(i);

            options = [...options, { label: i, value: new Date(value) }]
        }

        return options;
    }

    const startYearOptions = createYearOptions(startDate);
    const endYearOptions = createYearOptions(endDate);

    const showDateError = (text) => {
        showToast(text);
        dispatch(setTimeRange(null));
    };

    const changeStartDate = ({ value }) => {
        dispatch(setTimeRange(null));

        if (value > endDate) {
            showDateError('Invalid time range. Please make sure that the "From" date lies before the "to" date.');
            return;
        }

        const isOutOfLimit = checkLimit(value, endDate, period);

        if (isOutOfLimit) {
            return;
        }

        dispatch(setStartDate(value));
    }

    const updateDatePicker = () => {
        //there is possibility with mode view=month to select future month. maxDate prop limits date,
        // but future months are not disabled in layout. All future months have value = maxDate, and it is impossible
        // to detect invalid value. To handle this case maxDate = undefined. Then this value is used in logic and calculated
        // value is set to state. For some reason component doesn't update. To fix it internal method reFocusInputField is run
        // and to unfocus this input fake div is focused
        endDateRef.current?.reFocusInputField();
        focusedDiv.current?.focus();
    }

    const changeEndDate = (e) => {
        dispatch(setTimeRange(null));
        const max = moment(today);
        const end = moment(e.value);
        const endDiff = max.diff(end, 'days');
        const firstDayOfMonth = moment(e.value).startOf('month').format('YYYY/MM');
        const lastDayOfMonth = moment(e.value).endOf('month').format();
        const currentMonth = moment(today).format('YYYY/MM');
        const isCurrentMonth = currentMonth === firstDayOfMonth;

        const valueToSet = isCurrentMonth ? today : new Date(lastDayOfMonth);

        const isOutOfLimit = checkLimit(startDate, e.value, period);

        if (e.value < startDate) {
            showDateError('Invalid time range. Please make sure that the "From" date lies before the "to" date.');
            return;
        }
        if (endDiff < 0) {
            showDateError('Invalid month. You selected a date in the future. This date was automatically changed to the current day.');
            dispatch(setEndDate(today));
            updateDatePicker();

            return;
        }

        if (isOutOfLimit) {
            return;
        }

        if (period === 'monthly') {
            dispatch(setEndDate(valueToSet));
        } else {
            dispatch(setEndDate(e.value));
        }

        updateDatePicker();
    }

    return (
        <div className={styles.wrapper}>
            <p>From</p>

            {period === 'yearly' ?
                <Dropdown
                    id='trend-from'
                    className="dropdownNoBorder"
                    options={startYearOptions}
                    value={startDate}
                    onChange={changeStartDate}
                /> :

                <Calendar value={startDate} onChange={changeStartDate} yearNavigator monthNavigator
                    yearRange={`${lastAvailableYear}:${currentYear}`} minDate={minDate}
                    maxDate={maxStartDate}
                    view={period === 'monthly' ? 'month' : 'date'}
                />
            }

            <p>to</p>
            {period === 'yearly' ?
                <Dropdown
                    id='trend-to'
                    className="dropdownNoBorder"
                    options={endYearOptions}
                    value={endDate}
                    onChange={changeEndDate}
                />
                :
                <Calendar ref={endDateRef}
                    value={endDate} onChange={changeEndDate} yearNavigator monthNavigator
                    yearRange={`${lastAvailableYear}:${currentYear}`} minDate={startDate}
                    maxDate={period === 'monthly' ? undefined : today}
                    view={period === 'monthly' ? 'month' : 'date'} />
            }

            <div ref={focusedDiv} tabIndex="-1" />
        </div>
    );
};
