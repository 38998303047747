import loginBGImage from '../../assets/images/bg/shutterstock_463720262_min.jpg';
import appLogo from '../../assets/images/logos/sciwalker/Sciwalker_weiss_min.png';

export const APP_PROPERTIES = {
  // --- logos for login page and header --- //
  LOGIN_BG_IMAGE: loginBGImage,

  HEADER_LOGO_APP: appLogo,
  HEADER_LOGO_APP_STYLE: { height: 40, padding: '5px 15px' },

  // --- feedback and support --- //
  SUPPORT_FORM_URL:
    'https://forms.monday.com/forms/312f55bdb5829b7c7f342002021f19b7',

  // --- active pages --- //
  ACTIVE_PAGES: {
    frameEditor: true,
    pipelineManager: true,
    servicesStatus: true,
    docSearch: true,
    backendAdministration: true,
  },

  // --- active modules or functionalities --- //
  ACTIVE_FUNCTIONALITIES: {
    showServerStatus: true,
    autoRefreshToken: true,
    showDocumentsOnStart: true,
    // --- single/multiple user mode has to be supported by middleware! --- //
    singleUser: true,
    fetchSortableFields: true,
    ignoreQueryCache: true,
  },

  // --- chemistry-related settings --- //
  CHEMISTRY: {},
} as const;
