import { TabPanel, TabView } from "primereact/tabview";

const HighlightingHelp = () => {
    return (
        <TabView>
            <TabPanel header="Groups">
                <div className="query-examples">

                    <div className="paragraph">
                        A group is a part of the query which is enclosed in parentheses, e.g. to apply specific search criteria to a group of
                        query terms only. They also help to apply AND/OR logic and can be nested.
                        <div className="query-example">+(cmode:"s" chem:"steroids" chem:"monoterpenes") +(termloc:"title" diseases:"inflammation")</div>
                        <div className="query-example-info">
                            limits the synonyms-only search mode to the concepts "steroid" or "monterpenes" and
                            the concept "inflammation" has to be found in the title.
                        </div>
                        <div className="query-example">+(diseases:"headache" (+diseases:"pain" +anatomy:"head")) +(termloc:"ta" drugs:"drug candidate")</div>
                        <div className="query-example-info">
                            finds either "headache" or the combination of "pain" and "head" together with "drug candidates" in the title or abstract.
                        </div>
                        <div className="query-note">The color of the highlighting gives a hint about the nesting level, e.g. first level groups are yellow, second level groups are blue, third level groups are pink etc.</div>
                    </div>

                    <h4>Group at cursor position</h4>

                    <h5>Outer group only</h5>
                    <div className="paragraph">
                        Put your cursor somewhere in your query and select "Outer group only" to highlight the innermost enclosing group.
                        <div className="query-example border-only">+<span style={{ backgroundColor: '#FFEC99' }}>(diseases:"headache" (+diseases:"pain" +anatomy:"head"))</span> +(termloc:ta drugs:"drug candidate")</div>
                        <div className="query-example-info">highlights the innermost enclosing group, if the cursor was put somewhere within that group, e.g. "headache".</div>
                    </div>
                    <h5>Including all subgroups</h5>
                    <div className="paragraph">
                        Put your cursor somewhere in your query and select "Including all subgroups" to highlight the innermost enclosing group
                        as well as all of its subgroups in different colors, depending on their nesting level.
                        <div className="query-example border-only">+<span style={{ backgroundColor: '#FFEC99' }}>(diseases:"headache" <span style={{ backgroundColor: '#9AC9EE' }}>(+diseases:"pain" +anatomy:"head")</span>)</span> +(termloc:ta drugs:"drug candidate")</div>
                        <div className="query-example-info">highlights the innermost enclosing group and all of its subgroups, if the cursor was put somewhere within that group, e.g. "headache".</div>
                    </div>

                    <h4>All groups</h4>

                    <h5>Outer groups only</h5>
                    <div className="paragraph">
                        This option will highlight all of the outermost groups in the query.
                        <div className="query-example border-only">+<span style={{ backgroundColor: '#FFEC99' }}>(diseases:"headache" (+diseases:"pain" +anatomy:"head"))</span> +<span style={{ backgroundColor: '#FFEC99' }}>(termloc:ta drugs:"drug candidate")</span></div>
                        <div className="query-example-info">highlights all first level groups of the query.</div>
                    </div>
                    <h5>Including all subgroups</h5>
                    <div className="paragraph">
                        This option will highlight all groups in the query, including all subgroups. The highlighting colors are based on their nesting level.
                        <div className="query-example border-only">+<span style={{ backgroundColor: '#FFEC99' }}>(diseases:"headache" <span style={{ backgroundColor: '#9AC9EE' }}>(+diseases:"pain" +anatomy:"head")</span>)</span> +<span style={{ backgroundColor: '#FFEC99' }}>(termloc:ta drugs:"drug candidate")</span></div>
                        <div className="query-example-info">highlights all groups of the query, colored based on their nesting level.</div>
                    </div>
                 
                    <h4>Unmatched parentheses</h4>

                    <h5>Unmatched parentheses only</h5>
                    <div className="paragraph">
                        This option will highlight all unmatched parentheses in red.
                        <div className="query-example border-only">+(diseases:"headache" (+diseases:"pain" +anatomy:"head"))<span style={{ backgroundColor: 'red' }}>)</span> +<span style={{ backgroundColor: 'red' }}>(</span>(termloc:ta drugs:"drug candidate")</div>
                        <div className="query-example-info">highlights all unmatching parentheses in red.</div>
                    </div>

                    <h5>All groups, including all sub groups and unmatched parentheses</h5>
                    <div className="paragraph">
                        This option will highlight all groups in the query, including all subgroups. The highlighting colors are based on their nesting level.
                        Further all unmatched parenthesis will be marked red.
                        <div className="query-example border-only">+<span style={{ backgroundColor: '#FFEC99' }}>(diseases:"headache" <span style={{ backgroundColor: '#9AC9EE' }}>(+diseases:"pain" +anatomy:"head")</span>)</span><span style={{ backgroundColor: 'red' }}>)</span> +<span style={{ backgroundColor: 'red' }}>(</span><span style={{ backgroundColor: '#FFEC99' }}>(termloc:ta drugs:"drug candidate")</span></div>
                        <div className="query-example-info">highlights all groups of the query, colored based on their nesting level, as well as all unmatching parentheses in red.</div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Remove">
                <div className="query-examples">
                    <div className="paragraph">
                        Clears all highlighting in the editor.
                    </div>
                </div>
            </TabPanel>
        </TabView>
    )
}

export default HighlightingHelp;