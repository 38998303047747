import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class ActivateUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activateUsersChecked: false
        }
    }

    onCheckActivateUsers = (e) => {
        if (e.checked) {
            this.setState({
                activateUsersChecked: true
            })
        } else {
            this.setState({
                activateUsersChecked: false
            })
        }
    }

    onActivateUsers = () => {
        this.props.onActivateUsers()
    }

    render() {

        let userDetails = this.props.selectedUserDetails && this.props.selectedUserDetails.map(user => {
            return <div key={user.userName} style={{ marginBottom: 5 }}>
                <label>{`${user.lastName}, ${user.foreName} (${user.userName})`}</label>
            </div>
        })

        const footerActivateUsers = (
            <div>
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelActivateUsers}
                />
                <Button label="Activate"
                    className='p-button-sm primaryButton'
                    disabled={this.state.activateUsersChecked ? false : true}
                    onClick={this.onActivateUsers}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayActivateUsers} style={{ 'width': "40vw" }} header="Activate users" focusOnShow={false}
                modal={true} footer={footerActivateUsers} dismissableMask={false}
                onHide={() => this.props.onCancelActivateUsers()} className='styledDialog'>
                <div style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 25, paddingBottom: 15, paddingTop: 15 }}>
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <label>{`This will activate the following users:`}</label>
                    </div>
                    {userDetails}
                    <div style={{ marginBottom: 15, marginTop: 15, marginLeft: -2 }}>
                        <Checkbox inputId='activateUserChecked'
                            onChange={(e) => this.onCheckActivateUsers(e)}
                            checked={this.state.activateUsersChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer' }}
                        />
                        <label htmlFor='activateUserChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default ActivateUsers