import "./RectangleBatches.css";


const RectangleBatches = ({ documentData }) => {

    return (
        documentData?.doi ?
            <>
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span
                        id={`batch_dim_${documentData.doi}`}
                        className="__dimensions_badge_embed__ addMarginRight"
                        data-style="small_rectangle"
                        data-hide-zero-citations="true"
                        //style={{ /*display: 'inline-flex'*//*, position: 'relative', top: -1*/ }}
                        data-doi={documentData.doi}
                    >
                    </span>
                </span>
                {/* 
                    <div className='altmetric-embed' data-hide-less-than="0" data-badge-type='3'
                        style={{ marginRight: 15, display: 'inline-block', position: 'relative', top: 5 }}
                        data-doi={documentData.doi} data-badge-popover="right" data-link-target="_blank"></div>
                    */}
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <div
                        id={`batch_alt_${documentData.doi}`}
                        className="altmetric-embed"
                        data-badge-popover="right"
                        data-badge-type="2"
                        data-hide-no-mentions="true"
                        data-link-target="_blank"
                        //style={{ /*display: 'inline-flex', marginRight: 15*//*, position: 'relative', top: 1*/ }}
                        data-doi={documentData.doi}
                    >
                    </div>
                </span>
            </> : null
    )
}

export default RectangleBatches;