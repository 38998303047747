import { useEffect, useState } from "react";
import { createConceptDataRequest, fetchConceptData } from "../../../../../api/content/ConceptApi";
import PagingList from "../../../ontbrowser/PagingList";
import { isArrayEmpty } from "../../../util";

const MAX_NUM_OF_CHILDREN = 10;


const ChildClasses = ({
  ocid,
  onConceptClick,
}) => {

  const [children, setChildren] = useState([]);
  const [childrenData, setChildrenData] = useState({});
  const [offset, setOffset] = useState(0);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  useEffect(() => {
    // fetch children for OCID
    if (ocid) {
      loadChildren(ocid, 0, MAX_NUM_OF_CHILDREN);
    }
  }, [ocid]);

  const loadPrevChildren = () => {
    loadChildren(ocid, offset - MAX_NUM_OF_CHILDREN, MAX_NUM_OF_CHILDREN);
  }

  const loadNextChildren = () => {
    loadChildren(ocid, offset + MAX_NUM_OF_CHILDREN, MAX_NUM_OF_CHILDREN);
  }

  const loadChildren = async (ocid, offset, childrenPageSize) => {
    // request children of current concept with given offset
    const request = createConceptDataRequest([ocid], true, false, false, true, offset, childrenPageSize, true);
    const response = await fetchConceptData(request, false);
    const concept = response.payload;
    const children = concept && concept.children ? concept.children : [];

    setChildren(children);
    setChildrenData({
      count: concept.childrenCount,
      offset: offset,
    });
    setOffset(offset);
    setHasPrev(offset > 0);
    setHasNext(concept.childrenCount > (childrenPageSize + offset));
  }

  return !isArrayEmpty(children) ?
    <PagingList
      data={childrenData}
      numOfEntries={MAX_NUM_OF_CHILDREN}
      conceptList={children}
      hasPrev={hasPrev}
      hasNext={hasNext}
      loadPrev={loadPrevChildren}
      loadNext={loadNextChildren}
      onConceptClick={onConceptClick}
      allowConceptClick={true}
      allowCopy={true}
      cropContent={true}
    />
    :
    <span>No child classes</span>;
}

export default ChildClasses;