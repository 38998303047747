import { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
//import { InputMask } from "primereact/inputmask";
//import { InputNumber } from "primereact/inputnumber";


class FilterSectionNumRange extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }

    render() {

        const { filterID, minValue, maxValue, unit, placeholder, placeholder2, filterInfo, onFilterValuesChange } = this.props;
        
        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className="grid" style={{ marginLeft: 0 }}>
                    <div className="col-fixed labelColumn">
                        <label htmlFor="minValue" className="valignMiddle">From</label>
                    </div>
                    <div className="col">
                        {/*
                        <InputMask mask="999999999999"
                            value={minValue ? minValue : ''}
                            onChange={(e) => {
                                const filterObject = {
                                    filterID: filterID,
                                    minValue: e.target.value,
                                    maxValue: maxValue
                                };
                                onFilterValuesChange(filterObject);
                            }}
                            className="valignMiddle"
                            style={{ width: '100%' }}
                        >
                        </InputMask>
                       
                        <InputNumber
                            value={minValue ? minValue : ''}
                            onChange={(e) => {
                                const filterObject = {
                                    filterID: filterID,
                                    minValue: e.target?.value ? e.target.value : '',
                                    maxValue: maxValue
                                };
                                onFilterValuesChange(filterObject);
                            }}
                        />
                        */}
                        <InputText
                            id='minValue'
                            value={minValue ? minValue : ''}
                            onChange={(e) => {
                                const filterObject = {
                                    filterID: filterID,
                                    minValue: e.target.value,
                                    maxValue: maxValue
                                };
                                onFilterValuesChange(filterObject);
                            }}
                            placeholder={placeholder ? placeholder : ''}
                            className="valignMiddle"
                            style={{ width: '100%' }}>
                        </InputText>
                        {/**/}
                    </div>
                    <div className="col-fixed labelColumn">
                        <label htmlFor="maxValue" className="valignMiddle">to</label>
                    </div>
                    <div className="col">
                        <InputText
                            id='maxValue'
                            value={maxValue ? maxValue : ''}
                            onChange={(e) => {
                                const filterObject = {
                                    filterID: filterID,
                                    minValue: minValue,
                                    maxValue: e.target.value
                                };
                                onFilterValuesChange(filterObject);
                            }}
                            placeholder={placeholder2 ? placeholder2 : ''}
                            className="valignMiddle"
                            style={{ width: '100%' }}>
                        </InputText>
                    </div>
                    {unit ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {unit}
                        </div> : null}
                    {filterInfo ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {filterInfo}
                        </div> : null}
                </div>
            </>
        );
    }
}

export default FilterSectionNumRange;