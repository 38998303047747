import React, { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";


class PatentNumberFilterInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="patNoInfo"
                title="Click for information about the patent publication number"
                imageAltText="Patent publication number information"
                overlayContent={
                    <>
                        <h3>Patent publication number</h3>
                        <ul>
                            <li>Can be searched with country code only, country code and number or complete ID (country code and number and kind code).
                                For the missing parts everything matches.</li>
                            <li>The dash is optional.</li>
                            <li>Leading zeros are optional.</li>
                        </ul>
                    </>
                }
            />
        );
    }
}

export default PatentNumberFilterInfo;