import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { YearStatistics } from "./YearStatistics/YearStatistics";
import {
    resetYearStatisticsChart, resetYearStatistics, setIsFetching, setRepositories, setSelectedSearches, setStatisticsChartData, setUpdate,
    setYearStatisticsSearchParameters
} from "../../../redux/actions/YearStatisticsActions";
import { TrendAnalysisModal } from "./TrendAnalysisModal/TrendAnalysisModal";
import { usePrevious } from "../../common/customHooks/usePrevious";
import { fetchDocumentResultsWithStatistics } from "../../../api/content/DocumentApi";
import { createSearchOption } from "./YearStatistics/YearStatisticsChart/chartFunctions/createSearchOptions";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import styles from './TrendAnalysis.module.scss';
import { checkIsPeriodEnded } from "./YearStatistics/YearStatisticsChart/chartFunctions/checkIsPeriodEnded";
//import { convertQueryConceptsToQueryString } from "../../../api/content/QueryApi";
import SeparatorPoint from '../general/SeparatorPoint';
import SearchExamples from '../general/searchexamples/SearchExamples';
import { TREND_ANALYSIS_EXAMPLES } from './examples';

export const TrendAnalysis = () => {
    const repositoriesFromProps = useSelector(({ user: { data: { userDetails: { department: { selectedRepositories } } } } }) => selectedRepositories);
    const startDate = useSelector(({ yearStatistics: { startDate } }) => startDate);
    const endDate = useSelector(({ yearStatistics: { endDate } }) => endDate);
    const selectedSearches = useSelector(({ yearStatistics: { selectedSearches } }) => selectedSearches);
    const period = useSelector(({ yearStatistics: { period } }) => period);
    const searchParameters = useSelector(({ yearStatistics: { searchParameters } }) => searchParameters);
    const dataForChart = useSelector(({ yearStatistics: { chartData } }) => chartData);
    const repositoriesFromState = useSelector(({ yearStatistics: { repositories } }) => repositories);
    const chartDataFromProps = useSelector(({ yearStatistics: { chartData } }) => chartData);
    const isOutOfLimit = useSelector(({ yearStatistics: { isOutOfLimit } }) => isOutOfLimit);
    const dataIsFetching = useSelector(({ yearStatistics: { dataIsFetching } }) => dataIsFetching);

    const dispatch = useDispatch();

    const prevStartDate = usePrevious(startDate);
    const prevEndDate = usePrevious(endDate);
    const prevPeriod = usePrevious(period);
    const prevDataForChart = usePrevious(dataForChart);

    /*const examples = [{ name: 'aspirin', queryString: '+(ocid:190000021540 ocid:229960001138)' },
    { name: 'covid-19', queryString: '+(ocid:208000025721)' },
    { name: 'malaria', queryString: '+(ocid:201000004792)' }]*/

    useEffect(() => {
        const localStorageSearchParameters = localStorage.getItem('searchParameters')
        if (localStorageSearchParameters) {
            dispatch(setYearStatisticsSearchParameters([...searchParameters, JSON.parse(localStorageSearchParameters)]));
        }
        localStorage.removeItem('searchParameters');
    }, [dispatch, searchParameters]);

    useEffect(() => {
        if (searchParameters.length && repositoriesFromProps.length && !isOutOfLimit) {
            const chartNames = dataForChart.map(({ name }) => name);
            const prevEnd = moment(prevEndDate);
            const end = moment(endDate);
            const endDiff = prevEnd.diff(end, 'days');
            // console.log('prevEndDate', prevEndDate);
            // console.log('endDate', endDate);
            // console.log('equal', prevEndDate === endDate);
            // console.log('endDiff', endDiff);
            const shouldUpdateAllChart = startDate !== prevStartDate || endDiff !== 0 || period !== prevPeriod;
            const targets = shouldUpdateAllChart ? searchParameters : searchParameters.filter(({ searchName }) => !chartNames.includes(searchName));
            const periodIsEnded = checkIsPeriodEnded(period, endDate);

            if (targets.length && prevDataForChart.length <= dataForChart.length) {
                dispatch(setIsFetching(true));

                const promises = targets.map(async searchOption => {
                    const { searchParameters } = searchOption;
                    const searchOptions = createSearchOption(startDate, endDate, period);
                    return fetchDocumentResultsWithStatistics(searchParameters, searchOptions);
                });

                Promise.all(promises).then(results => {
                    let _statisticsChartData = shouldUpdateAllChart ? [] : [...chartDataFromProps];
                    let _repositories = { ...repositoriesFromState };
                    let _selectedSearches = [...selectedSearches];

                    const summarize = (prev, cur) => {
                        return prev + cur.cnt
                    };

                    const compare = (a, b) => {
                        const aCount = a.statistics.reduce(summarize, 0);
                        const bCount = b.statistics.reduce(summarize, 0);

                        return bCount - aCount;
                    }

                    //@todo refactor this function. maybe special action should be created for handling api response
                    //add error handling!

                    results.forEach((result, index) => {
                        const searchName = targets[index].searchName;

                        const chartData = {
                            name: searchName, data: Object.values(result.payload.results[0].repData)
                                .map(item => {
                                    const _statistics = item.statistics.map((stat, index, array) => {
                                        const penultimateValue = array[array.length - 2]

                                        if (!periodIsEnded && stat === penultimateValue) {
                                            // style line for estimated value

                                            return { ...stat, dash: "5,5", estimated: '' }
                                        }

                                        if (!periodIsEnded && stat === array[array.length - 1]) {
                                            //here the data is mutated. for current period we use previous value as estimated

                                            return {
                                                ...stat,
                                                cnt: penultimateValue?.cnt || 0,
                                                proportion: penultimateValue?.proportion || 0,
                                                estimated: '(estimated)'
                                            }
                                        }

                                        return { ...stat, estimated: '' }
                                    }
                                    )
                                    return { name: item.name, statistics: _statistics }
                                }
                                ).sort(compare)
                        };

                        // const color = selectedSearches.find(search => search.searchName === null).color;

                        // chartData.color = color

                        const repoNames = Object.values(result.payload.results[0].repData).map(({ name }) => name);

                        const filteredRepositories = repositoriesFromProps.filter(({ statistics }) => statistics)
                            .filter(({ name }) => repoNames.includes(name))
                            .map(({ label, name }) => {
                                const item = chartData.data.find(item => item.name === name);
                                const disabled = item.statistics.every(({ cnt }) => cnt === 0);

                                return {
                                    label: disabled ? `${label} (no hits)`
                                        : label,
                                    value: name,
                                    disabled
                                };
                            });

                        const reposWithData = chartData.data.filter((item => !item.statistics.every(({ cnt }) => cnt === 0)))
                            .map(repo => {
                                const hits = repo.statistics.reduce(summarize, 0);

                                return { ...repo, hits };
                            }).sort(({ hits }) => hits);

                        // set repo with max hits like initial value in the dropdown
                        const repoWithMaxHits = searchParameters[0].selectedRepository !== undefined && reposWithData.filter(e => e.name === searchParameters[0].selectedRepository.name).length > 0 ?
                            searchParameters[0].selectedRepository.name : reposWithData[0]?.name;

                        _statisticsChartData = [..._statisticsChartData, chartData];
                        _repositories = { ..._repositories, [searchName]: filteredRepositories };

                        if (!selectedSearches.find(repo => repo.searchName === searchName)) {
                            const target = _selectedSearches.find(repo => repo.searchName === null);
                            target.repoName = repoWithMaxHits || filteredRepositories[0].value;
                            target.searchName = searchName;
                        }
                    })

                    //console.log('_statisticsChartData', _statisticsChartData)
                    dispatch(setStatisticsChartData(_statisticsChartData));
                    dispatch(setRepositories(_repositories));
                    dispatch(setSelectedSearches(_selectedSearches));
                    dispatch(setIsFetching(false));
                    // dispatch(setUpdate({}));
                    setTimeout(() => {
                        dispatch(setUpdate({}));
                    });

                })
            }
        }
    }
        ,
        [startDate, prevStartDate, endDate, prevEndDate, period, prevPeriod, repositoriesFromProps,
            searchParameters, repositoriesFromState, selectedSearches, chartDataFromProps, prevDataForChart, dataForChart, isOutOfLimit,
            dispatch]
    )
        ;

    useEffect(() => {
        return () => dispatch(resetYearStatistics());
    }, [dispatch]);

    const startSearch = (name, queryString) => {
        dispatch(resetYearStatisticsChart());
        const searchOptions = {
            searchName: name,
            searchParameters: queryString
        }
        setTimeout(() => {
            dispatch(setYearStatisticsSearchParameters([searchOptions]));
        });
    }

    return (
        <LoadingOverlay
            active={dataIsFetching}
            spinner={true} >

            <h1 style={{ marginBottom: 0, marginTop: 3 }}>Trend Analysis</h1>
            <div style={{ marginTop: 10 }}>
                <span className={styles.info}>
                    <span>
                        This tool allows you to perform trend analyses of individual searches over
                        time and to visually compare different searches. You can also see the shift in visibility of topics
                        from
                        patents to publications and from grants to clinical trials by comparing the same search in different
                        repositories. You can add up to 4 lines.
                        {/* To get an impression try one of these:*/}
                    </span>
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <SeparatorPoint />
                        <SearchExamples
                            buttonLabel="Examples"
                            examples={TREND_ANALYSIS_EXAMPLES}
                            onExampleClick={(example) => startSearch(example.label, example.queryString)}
                        />
                    </span>
                </span>
            </div>
            <YearStatistics />
            <TrendAnalysisModal />
        </LoadingOverlay>
    )
}
    ;
