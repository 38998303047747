// Function to get the document containing an element (or fallback to the global document)
export const getDocument = (elm: any): Document =>
  (elm || {}).ownerDocument || document;

// Function to get the window object associated with an element (or fallback to the global window)
export const getWindow = (elm: any): typeof window =>
  (getDocument(elm) || {}).defaultView || window;

// Function to check if an element is an HTML element
export const isHTMLElement = (elm: any) =>
  elm instanceof HTMLElement || elm instanceof getWindow(elm).HTMLElement;

// Function to check if an element is an HTML canvas element
export const isHTMLCanvasElement = (elm: any) =>
  elm instanceof HTMLCanvasElement ||
  elm instanceof getWindow(elm).HTMLCanvasElement;

// Function to cast a value as an HTMLElement
export const asElement = (x: any): HTMLElement => x;

// Function to get a page element and its page number from a target element
export const getPageFromElement = (target: HTMLElement) => {
  const node = asElement(target.closest(".page"));

  if (!node || !isHTMLElement(node)) {
    return null;
  }

  const number = Number(asElement(node).dataset.pageNumber);

  return { node, number };
};

// Function to get a page element and its page number from a Range object
export const getPageFromRange = (range: Range) => {
  const parentElement = range.startContainer.parentElement;

  if (!isHTMLElement(parentElement)) {
    return undefined;
  }

  return getPageFromElement(asElement(parentElement));
};

// Function to create a container layer within an HTML element
export const createContainerLayer = (
  container: HTMLElement,
  layer: Element | null,
  className: string
) => {
  const doc = getDocument(container);

  layer = doc.createElement("div");
  layer.className = className;
  container.appendChild(layer);

  return layer;
};

// Function to find a container layer within an HTML element by class name
export const findContainerLayer = (
  container: HTMLElement,
  className: string
) => {
  let layer = container.querySelector(`.${className}`);
  return layer;
};

// Function to find or create a container layer within an HTML element by class name
export const findOrCreateContainerLayer = (
  container: HTMLElement,
  className: string
) => {
  const doc = getDocument(container);
  let layer = container.querySelector(`.${className}`);

  if (!layer) {
    layer = doc.createElement("div");
    layer.className = className;
    container.appendChild(layer);
  }

  return layer;
};
