import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import reducer from './redux/reducers'
//import * as serviceWorker from './serviceWorker';
//import registerServiceWorker, { unregister } from './registerServiceWorker'
import { unregister } from './registerServiceWorker'
import AppWrapper from './AppWrapper';
import { APP_PROPERTIES } from './properties/index';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';

const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  composedEnhancers(
    applyMiddleware(thunk)
  ))

const container =  document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter basename={APP_PROPERTIES.ROUTER_BASE_NAME}>
      <AppWrapper />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

//registerServiceWorker()
unregister()

/*
serviceWorker.register({
  onSuccess: () => alert('onSuccess'),
  onUpdate: reg => { console.log('reg: ', reg); alert('onUpdate - reg: ' + reg) },
});
*/
