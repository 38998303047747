
import React, { useState, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
//import { RadioButton } from "primereact/radiobutton";

export default function RadioDropdown({ disabled, options, selOption, placeholder, onChangeHandler, optionLabel }) {
    const [selectedOption, setSelectedOption] = useState(selOption);


    // Set initial data for update
    useEffect(() => {
        setSelectedOption(selOption)
    }, [selOption]);

    const selectedDropdownTemplate = (option, props) => {
        if (option || selectedOption?.name || selectedOption?.label) {
            return (
                <div className="flex align-items-center">
                    <label>{option?.name ?? selectedOption?.name ?? selectedOption?.label}</label>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const dropdownOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/*<RadioButton inputId={option.id} name="radioDropdown" checked={option.name === selectedOption?.name} />*/}
                <label htmlFor={option.id} className="ml-2">{option.name ? option.name : option.label}</label>
            </div>
        );
    };

    return (
        <Dropdown
            appendTo={document.body}
            value={selectedOption}
            onChange={(e) => {
                onChangeHandler(e)
                //setSelectedOption(e.value)
            }}
            options={options}
            optionLabel={optionLabel ? optionLabel : "name"}
            placeholder={placeholder}
            valueTemplate={selectedDropdownTemplate}
            itemTemplate={dropdownOptionTemplate}
            className="w-full"
            style={{
                borderBottom: '1px solid gray'
            }}
            disabled={disabled}
        />
    )
}
