import SeparatorPoint from "../../../../../../../general/SeparatorPoint";
import dateFormat from "dateformat";
import { isArrayEmpty } from "../../../../../../../util";
import { isSavedSearchEditable } from "../../../../../../helpers/savedSearches";
import { toggleSavedSearchGroupDialog } from "../../../../../../../../../redux/features/queryBuilder/actions";
import { QUERY_TYPE_ADVANCED_SEARCH_3 } from "../../../../../../../../../properties";
import SavedSearchesDialog from "../../../../../../../general/SavedSearches/SavedSearchesDialog";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../../../../../common/ConfirmDialog/ConfirmationDialog";
import { useState } from "react";

const SavedSearchGroupEdit = ({
  savedSearchData,
  onExpandSavedSearch,
  onValuesChange,
}) => {
  const toggleDialog = useSelector(
    (state) => state.advancedSearch3Reducer.toggleSavedSearchGroupDialog
  );
  const dispatch = useDispatch();

  const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState();

  const onSelectSavedSearch = (savedSearch) => {
    onValuesChange(savedSearch);
    dispatch(toggleSavedSearchGroupDialog(false));
  };

  return (
    <>
      {savedSearchData && (
        <div className="flex flex-column gap-1 line-height-3">
          <div>
            {savedSearchData ? (
              <div className="mb-3" /*style={{ marginLeft: 15 }}*/>
                <a onClick={() => dispatch(toggleSavedSearchGroupDialog(true))}>
                  Choose different saved search
                </a>
                <SeparatorPoint />
                <a onClick={() => setDisplayConfirmationDialog(true)}>
                  Expand saved search
                </a>
              </div>
            ) : (
              <a onClick={() => dispatch(toggleSavedSearchGroupDialog(true))}>
                Add saved search
              </a>
            )}
          </div>
          <div>
            <strong>{savedSearchData.fullName}</strong>
          </div>
          {savedSearchData.description && (
            <div>{savedSearchData.description}</div>
          )}
          <div className="secondaryInfo">
            Owner: {savedSearchData.owner.lastName},{" "}
            {savedSearchData.owner.forename}
            <SeparatorPoint />
            {isSavedSearchEditable(savedSearchData)
              ? "Editable"
              : "Not editable"}
            {!isArrayEmpty(savedSearchData.queryCollectionList) && (
              <>
                <SeparatorPoint />
                <span>
                  In collections:{" "}
                  {savedSearchData.queryCollectionList
                    ?.map((coll) => coll.name)
                    .join(", ")}
                </span>
              </>
            )}
            <SeparatorPoint />
            Created: {dateFormat(savedSearchData.created, "yyyy-mm-dd HH:MM")}
            {savedSearchData.modified && (
              <>
                <SeparatorPoint />
                <span>
                  Last modified:{" "}
                  {dateFormat(savedSearchData.modified, "yyyy-mm-dd HH:MM")}
                </span>
              </>
            )}
          </div>
        </div>
      )}
      <SavedSearchesDialog
        displayDialog={toggleDialog}
        onHide={() => dispatch(toggleSavedSearchGroupDialog(false))}
        allowedQueryTypes={[QUERY_TYPE_ADVANCED_SEARCH_3.id]}
        onSelect={(savedSearch) => {
          onSelectSavedSearch(savedSearch, true);
        }}
      />
      <ConfirmationDialog
        displayDialog={displayConfirmationDialog}
        onHide={() => setDisplayConfirmationDialog(false)}
        onSubmit={() => {
          onExpandSavedSearch(savedSearchData);
          setDisplayConfirmationDialog(false);
        }}
        headerText="Expand saved search"
        messageText="This search group will be replaced by a copy of the content of its referenced saved search. Changes in the original search will have no effect on your current search anymore. Would you like to proceed?"
        submitButtonLabel={"Confirm"}
      />
    </>
  );
};

export default SavedSearchGroupEdit;
