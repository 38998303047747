//import React, { Component } from 'react';
import { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import App from "./App";
//import { APP_PROPERTIES } from './properties/index';
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import Verification from "./components/auth/Verification";
import UpdatePassword from "./components/auth/UpdatePassword";
import { NotFound } from "./components/auth/NotFound";

class AppWrapper extends Component {

	/*UNSAFE_componentWillMount(){
		localStorage.setItem('token', 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJndWVzdCIsImV4cCI6MTYxMTIzMTA2NX0.DqLdBG8Yp8sx-Kh5ykjaNDAT5jfAsf13ZWfYoXd_hbXMe-lNwhjDGHiPpTc9upWp6hkBpFL-micXS879suyOjw')
	}*/

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {

		const query = new URLSearchParams(this.props.location.search);
		const token = query.get('token')
		const error = query.get('error')
		let savedSearchesObject = {}

		if (//this.props.location.pathname === "/my/savedsearches" || this.props.location.pathname === "/my/library"
			this.props.location.pathname !== "/") {
			savedSearchesObject = { url: this.props.location.pathname, params: this.props.location.search }
			localStorage.setItem('savedSearches', JSON.stringify(savedSearchesObject))
		}

		switch (this.props.location.pathname) {
			case "/":
				return <Route path="/" render={() => <Login token={token} error={error} />} />
			case "/forgotpw":
				return <Route path="/forgotpw" render={() => <ForgotPassword />} />
			case "/verification":
				return <Route path="/verification" render={() => <Verification token={token} />} />
			case "/updatepw":
				return <Route path="/updatepw" render={() => <UpdatePassword token={token} />} />
			case "/notfound":
				return <Route path="/notfound" component={NotFound} />
			default:
				return <App />;
		}
	}
}

export default withRouter(AppWrapper);