import { ProgressBar } from 'primereact/progressbar';
import './ProgressBarMini.css';


function ProgressBarMini({ height = '3px' }) {
    return (
        <ProgressBar
            className="progressbar-mini"
            mode="indeterminate"
            style={{ height: height }}>
        </ProgressBar>
    );
}

export default ProgressBarMini;