//import React, { useState} from 'react'
import './TrendAnalysisQuickSearch.css'
import {TrendAnalysisSimpleSearch} from "./TrendAnalysisSimpleSearch";

export const TrendAnalysisQuickSearch = (props) => {
    //const [queryTerms, setQueryTerms] = useState([]);

    const onQueryTermsChanged = (values) => {
        props.callbackFromQueryChange(values);
    }

    return (

        <TrendAnalysisSimpleSearch
            fetchTermSuggestions={props.fetchTermSuggestions}
            terms={props.terms}
            userData={props.userData}
            logicalOperator='and'
            queryTerms={props.queryTerms}
            onQueryTermsChanged={onQueryTermsChanged}
        />
    );
}
