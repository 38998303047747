import {dropdownFieldsForConcepts, dropdownFieldsForTerms} from "../SearchTemplate/dropdownFields";

export const createDropdownOptions = (item) => {
    //custom dropdown for bulk import
    if (item.inputType) {
        return [{
            label: 'Filter', code: 'Filter',
            items: [
                {
                    label: item.inputType,
                    value: {termLookupType: 'TERM_NORMALIZED', occurrence: 'MUST'},
                    className: "filter"
                },
            ]
        }, {
            label: 'Exclude', code: 'Exclude',
            items: [
                {
                    label: item.inputType,
                    value: {termLookupType: 'TERM_NORMALIZED', occurrence: 'MUST_NOT'},
                    className: "exclude"
                },
            ]
        }];
    }

    if (item.ocids) {
        return dropdownFieldsForConcepts;
    }

    return dropdownFieldsForTerms;
}
