export const ChartBox = ({ id, style = {}, dataIsFetching, isAllReposEmpty }) => {
  return (
    <div
      id={id}
      className="amcharts-amexport-menu-root"
      style={{
        display: dataIsFetching || isAllReposEmpty ? 'none' : 'block',
        ...style,
      }}
    ></div>
  );
};
