/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { getHeaderToken } from '../../api/index'
import axios from 'axios'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
//import dateFormat from 'dateformat';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import ClearQuerycache from './ClearQuerycache'
import EditBackend from './EditBackend'
import AddBackend from './AddBackend';
import SetupBackends from './SetupBackends'
import { APP_PROPERTIES } from '../../properties';
import { hasUserRole } from '../../components/webapi/util';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import CustomPaginatorLeftSide from '../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../common/CustomPaginator/CustomPaginatorTemplate'
import { fetchRepositoryCatalogs } from '../../api/AdministrationApi';
import { addAnnotationVersionToBackends } from '../Administration/util';

class BackendAdministration extends Component {

    constructor(props) {
        super(props);
        let columns = [
            { field: "status", header: 'Status', body: this.statusTemplate, sortable: true, filter: true },
            { field: "id", header: "ID", body: this.idTemplate, sortable: true, filter: true, style: { width: 50 } },
            { field: "label", header: "Label", body: this.labelTemplate, sortable: true, filter: true },
            { field: "name", header: 'Name', body: this.nameTemplate, sortable: true, filter: true },
            { field: "abbreviation", header: 'Abbrev.', body: this.abbrevTemplate, sortable: true, filter: true },
            { field: "type", header: 'Type', body: this.typeTemplate, sortable: true, filter: true },
            { field: "annotationVersion", header: 'Annotation', sortable: true, filter: true },
            { field: "url", header: 'URL', body: this.urlTemplate, sortable: true, filter: true },
            { field: "port", header: 'Port', body: this.portTemplate, sortable: true, filter: true },
            { field: "certFolder", header: 'Cert. folder', body: this.certFolderTemplate, sortable: true, filter: true },
            { field: "numberParallelConnections", header: 'Par. conn.', body: this.connectionsTemplate, sortable: true, filter: true },
            { field: "username", header: 'User', body: this.userTemplate, sortable: true, filter: true },
            { field: "isDefault", body: this.defaultTemplate, header: "Default", sortable: true, filter: true },
            { field: "useSSL", body: this.SSLTemplate, header: "SSL", sortable: true, filter: true },
            //{ field: "created", body: this.createdTemplate, header: 'Created' },
            //{ field: "modified", body: this.modifiedTemplate, header: 'Modified' },
            { field: "", body: this.clearQCTemplate, style: { width: '6em' } },
            { field: "", body: this.editTemplate, style: { width: '4em' } }
        ]
        this.state = {
            colsBackends: columns,
            allBackends: [],
            first: 0,
            rows: 20,
            displayDialogEditBackend: false,
            displayDialogAddBackend: false,
            displayClearQCAll: false,
            displayDialogSetupBackends: false
        }
    }

    componentDidMount = async () => {
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/backend-apis`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allBackends: response.data
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/backend-apis/types`, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response.data)
                let backendTypes = []
                Object.keys(response.data).forEach(key => {
                    backendTypes = [...backendTypes, { label: key, value: response.data[key] }]
                })
                self.setState({
                    allBackendTypes: backendTypes
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    statusTemplate = (rowData) => {
        const color = rowData.availability === 'AVAILABLE' ? '#b5e08b' : rowData.availability === 'UNAVAILABLE' ? '#f4c6c6' : '#e8e8e8'; // #ffefad
        return (
                <React.Fragment>
                    <span className="p-column-title">Status</span>
                    <div style={{ backgroundColor: color, padding: '2px 5px' }}>{rowData.availability ? rowData.availability : '-'}</div>
                </React.Fragment>
        );
    }

    idTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">ID</span>
                {rowData.id}
            </React.Fragment>
        );
    }

    labelTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Label</span>
                {rowData.label}
            </React.Fragment>
        );
    }

    nameTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </React.Fragment>
        );
    }

    abbrevTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Abbreviation</span>
                {rowData.abbreviation}
            </React.Fragment>
        );
    }

    typeTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Type</span>
                {rowData.type}
            </React.Fragment>
        );
    }

    urlTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">URL</span>
                {rowData.url}
            </React.Fragment>
        );
    }

    portTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Port</span>
                {rowData.port}
            </React.Fragment>
        );
    }

    certFolderTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Certificate folder</span>
                {rowData.certFolder}
            </React.Fragment>
        );
    }

    connectionsTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Parallel connections</span>
                {rowData.numberParallelConnections}
            </React.Fragment>
        );
    }

    userTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Username</span>
                {rowData.username}
            </React.Fragment>
        );
    }

    defaultTemplate = (rowData) => {
        let yesno = ''
        if (rowData.isDefault) {
            yesno = 'yes'
        } else {
            yesno = 'no'
        }
        return (
            <React.Fragment>
                <span className="p-column-title">Default</span>
                {yesno}
            </React.Fragment>
        );
    }

    SSLTemplate = (rowData) => {
        let useSSL = ''
        if (rowData.hasOwnProperty('useSSL')) {
            if (rowData.useSSL === true) {
                useSSL = 'yes'
            } else {
                useSSL = 'no'
            }
        } else {
            useSSL = 'No info'
        }
        return (
            <React.Fragment>
                <span className="p-column-title">SSL</span>
                {useSSL}
            </React.Fragment>
        );
    }

    createdTemplate = (rowData) => {
        //console.log(rowData)
        if (rowData.created) {
            return <label title={moment(rowData.created + "Z").format("YYYY/MM/DD, LT")}>{moment(rowData.created + "Z").format("YYYY/MM/DD, LT")}</label>
        } else {
            return <label>-</label>
        }
    }

    modifiedTemplate = (rowData) => {
        //console.log(rowData)
        if (rowData.modified) {
            return <label title={moment(rowData.modified + "Z").format("YYYY/MM/DD, LT")}>{moment(rowData.modified + "Z").format("YYYY/MM/DD, LT")}</label>
        }
    }

    clearQCTemplate = (rowData) => {
        if (rowData.type && rowData.type === 'webapi') {
            return <a onClick={() => this.openClearQCDialog(rowData)}>Clear QC</a>
        } else {
            return <label>-</label>
        }
    }

    editTemplate = (rowData) => {
        return <a onClick={() => this.onEditBackend(rowData)}>Edit</a>
    }

    onEditBackend = (rowData) => {
        //console.log("row:",rowData)
        this.setState({
            displayDialogEditBackend: true,
            selectedAbbreviation: rowData.abbreviation,
            selectedDefault: rowData.isDefault,
            selectedLabel: rowData.label,
            selectedUsername: rowData.username,
            selectedNumberParallelConnections: rowData.numberParallelConnections,
            selectedCertFolder: rowData.certFolder,
            selectedID: rowData.id,
            selectedName: rowData.name,
            selectedURL: rowData.url,
            selectedPort: rowData.port,
            selectedType: rowData.type,
            selectedUseSSL: rowData.useSSL
        })
    }

    clearQC = async (name) => {
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/querycache/${name}`, { headers: getHeaderToken() })
            .then(function (response) {
                self.showClearQCSuccessGrowl()
                self.setState({
                    displayDialogClearQC: false
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showClearQCFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showClearQCFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    showClearQCFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    showClearQCSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `Querycache for ${this.state.selectedLabel} successfully cleared.` };
        this.growl.show(msg)
    }

    openClearQCDialog = (rowData) => {
        this.setState({
            displayDialogClearQC: true,
            selectedAbbreviation: rowData.abbreviation,
            selectedDefault: rowData.isDefault,
            selectedLabel: rowData.label,
            selectedUsername: rowData.username,
            selectedNumberParallelConnections: rowData.numberParallelConnections,
            selectedCertFolder: rowData.certFolder,
            selectedID: rowData.id,
            selectedName: rowData.name,
            selectedURL: rowData.url,
            selectedPort: rowData.port,
            selectedType: rowData.type,
            selectedUseSSL: rowData.useSSL
        })
    }

    openClearQCAll = () => {
        this.setState({
            displayClearQCAll: true
        })
    }

    onCheckClearQCAll = (e) => {
        if (e.checked) {
            this.setState({
                clearQCCheckedAll: true
            })
        } else {
            this.setState({
                clearQCCheckedAll: false
            })
        }
    }

    onClearQCAll = async () => {
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/querycache`, { headers: getHeaderToken() })
            .then(function (response) {
                self.showClearQCAllSuccessGrowl()
                self.setState({
                    displayClearQCAll: false,
                    clearQCCheckedAll: false
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showClearQCFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.showClearQCFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    showClearQCAllSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: "Querycache for all backends with type 'webapi' successfully cleared." };
        this.growl.show(msg)
    }
    onCancelEditBackend = () => {
        this.setState({
            displayDialogEditBackend: false
        })
    }

    onCancelClearQC = () => {
        this.setState({
            displayDialogClearQC: false
        })
    }

    onCancelClearQCAll = () => {
        this.setState({
            displayClearQCAll: false,
            clearQCCheckedAll: false
        })
    }

    editBackend = async (selectedAbbreviation, selectedDefault, selectedLabel, selectedUsername, selectedNumberParallelConnections, selectedCertFolder, newPassword, updatePassword) => {
        if (selectedNumberParallelConnections > 32) {
            this.showEditWarnGrowl()
        } else {
            let encodedPW
            if (updatePassword && newPassword !== '') {
                encodedPW = btoa(newPassword)
            }
            var self = this;
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/backend-apis`, {
                abbreviation: selectedAbbreviation,
                certFolder: selectedCertFolder !== '' ? selectedCertFolder : null,
                id: this.state.selectedID,
                isDefault: selectedDefault,
                label: selectedLabel,
                numberParallelConnections: selectedNumberParallelConnections !== '' ? selectedNumberParallelConnections : null,
                password: updatePassword ? encodedPW : null,
                username: selectedUsername !== '' ? selectedUsername : null,
                name: this.state.selectedName,
                url: this.state.selectedURL,
                port: this.state.selectedPort,
                type: this.state.selectedType,
                changePassword: updatePassword,
                useSSL: this.state.selectedUseSSL === undefined ? false : this.state.selectedUseSSL
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //self.showEditCompanySuccessGrowl()
                    self.setState({
                        displayDialogEditBackend: false
                    }, () => self.refreshBackends())
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEditFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        //self.showEditCompanyFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    showEditWarnGrowl = () => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: `The number of parallel connections is limited to 32.` };
        this.growl.show(msg)
    }

    showEditFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Error!', detail: e };
        this.growl.show(msg)
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    onAddBackend = () => {
        this.setState({
            displayDialogAddBackend: true
        })
    }

    onCancelAddBackend = () => {
        this.setState({
            displayDialogAddBackend: false
        })
    }

    onSetupBackends = () => {
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/companies/backends`, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response.data)
                self.setState({
                    companyBackends: response.data,
                    displayDialogSetupBackends: true
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshGlobalBackends = () => {
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/companies/backends`, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response.data)
                self.setState({
                    companyBackends: response.data
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    onCancelSetupBackends = () => {
        this.setState({
            displayDialogSetupBackends: false
        })
    }

    addBackend = async (abbreviation, isDefault, label, username, numberParallelConnections, certFolder, password, name, port, type, backendURL, updatePassword, useSSL) => {
        if (numberParallelConnections > 32) {
            this.showEditWarnGrowl()
        } else {
            let encodedPW
            if (updatePassword && password !== '') {
                encodedPW = btoa(password)
            }
            var self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/backend-apis`, {
                abbreviation: abbreviation,
                certFolder: certFolder !== '' ? certFolder : null,
                isDefault: isDefault,
                label: label,
                name: name,
                numberParallelConnections: numberParallelConnections !== '' ? numberParallelConnections : null,
                password: updatePassword ? encodedPW : null,
                port: port,
                type: type,
                url: backendURL,
                username: username !== '' ? username : null,
                changePassword: updatePassword,
                useSSL: useSSL
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        displayDialogAddBackend: false
                    }, () => {
                        self.refreshBackends()
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEditFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        // self.showSetLandingPageFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    refreshBackends = () => {
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/backend-apis`, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response.data)
                self.setState({
                    allBackends: response.data
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    onBackendOrgClick = async (e, org, be, type) => {
        if (e.checked && type === 'data') {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/backend/${be.id}`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalBackends()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (e.checked && type === 'chem') {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/company/${org.key}/backend/chemistry/${be.id}`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalBackends()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    onBackendSuborgClick = async (e, suborg, be, type) => {
        if (e.checked && type === 'data') {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/backend/${be.id}`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalBackends()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (e.checked && type === 'chem') {
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/department/${suborg.key}/backend/chemistry/${be.id}`, {
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshGlobalBackends()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    render() {

        //let userDetails = this.props.userDetails

        let columnDataBackends = this.state.colsBackends.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        let headerBackends = <div className='grid' style={{
            marginRight: 0,
            //borderBottom: '1px solid #d6d6d6',
            paddingTop: 18, paddingBottom: 10,
            paddingLeft: 5
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search all backends">
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', float: 'left', width: '80%' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterBackends: e.target.value })}
                    placeholder="Search all backends"
                />
            </div>
        </div>

        const footerClearQCAll = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Clear"
                    className='p-button-sm p-button-danger'
                    disabled={this.state.clearQCCheckedAll ? false : true}
                    onClick={this.onClearQCAll}
                />
                <Button label="Cancel"
                    className='p-button-sm p-button-secondary'
                    onClick={this.onCancelClearQCAll}
                />
            </div>
        )

        return (
            <div className='col-12' style={{ margin: "-0.5rem" }}>
                <Toast ref={(el) => { this.growl = el }} />
                <div className='col-12'>
                    <div className='col-4' style={{ float: 'left', padding: 0 }}>
                        <h1 style={{ marginTop: 0 }} className="pageHeader">Backend Administration</h1>
                    </div>
                    <div className='col-8' style={{ paddingRight: 20, float: 'right', textAlign: 'right' }}>
                        <label htmlFor="qc"
                            style={{ fontWeight: 'bold' }}>Clear querycache for this application</label>
                        <div style={{ marginTop: 15 }}>
                            {!hasUserRole("ROLE_SUPPORT_ADMIN") &&
                                <a onClick={this.openClearQCAll}
                                    style={{}}>Clear Querycache</a>
                            }
                        </div>
                    </div>
                    <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0 }}>
                        <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0 }}>
                            <div className="datatable-responsive-demo">
                                {/* <LoadingOverlay
                                    active={this.state.fetchingAnnotationVersions}
                                    text={'Checking webapi backends'}
                                    spinner={true} > */}
                                {headerBackends}
                                <DataTable ref={(el) => this.dt = el}
                                    className="p-datatable-responsive-demo"
                                    //dataKey="id"
                                    emptyMessage="No backends available."
                                    //selection={this.props.selectedAlerts}
                                    removableSort={true}
                                    //onSelectionChange={e => this.handleSelectionChange(e)}
                                    //className="dataTableQueries"
                                    value={this.state.allBackends}
                                    //responsive={true}
                                    paginator={true}
                                    rows={this.state.rows}
                                    globalFilter={this.state.globalFilterBackends}
                                    //footer={footerAlerts}
                                    first={this.state.first}
                                    paginatorTemplate={CustomPaginatorTemplate}
                                    paginatorLeft={<CustomPaginatorLeftSide
                                        first={this.state.first}
                                        contentLength={this.state.allBackends.length}
                                        rows={this.state.rows}>
                                    </CustomPaginatorLeftSide>}
                                    paginatorRight={<CustomPaginatorRightSide
                                        rows={this.state.rows}
                                        onChange={(e) => this.setState({
                                            rows: e.value
                                        })}
                                    >
                                    </CustomPaginatorRightSide>}
                                //rowsPerPageOptions={[10, 20, 50]}
                                //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                >
                                    {columnDataBackends}
                                </DataTable>
                                {/* </LoadingOverlay> */}
                            </div>
                        </div>
                        <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, textAlign: 'right', paddingTop: 15 }}>
                            <a style={{ paddingRight: 20 }} onClick={() => this.onSetupBackends()}>Setup backends</a>
                            <a onClick={() => this.onAddBackend()}>Add backend</a>
                        </div>
                    </div>
                    {this.state.displayDialogEditBackend &&
                        <EditBackend
                            displayDialogEditBackend={this.state.displayDialogEditBackend}
                            onCancelEditBackend={this.onCancelEditBackend}
                            editBackend={this.editBackend}
                            selectedAbbreviation={this.state.selectedAbbreviation}
                            selectedDefault={this.state.selectedDefault}
                            selectedLabel={this.state.selectedLabel}
                            selectedUsername={this.state.selectedUsername}
                            selectedNumberParallelConnections={this.state.selectedNumberParallelConnections}
                            selectedCertFolder={this.state.selectedCertFolder}
                            selectedName={this.state.selectedName}
                            selectedURL={this.state.selectedURL}
                            selectedPort={this.state.selectedPort}
                            selectedType={this.state.selectedType}
                            selectedUseSSL={this.state.selectedUseSSL}>
                        </EditBackend>
                    }
                    {this.state.displayDialogClearQC &&
                        <ClearQuerycache
                            displayDialogClearQC={this.state.displayDialogClearQC}
                            onCancelClearQC={this.onCancelClearQC}
                            clearQC={this.clearQC}
                            selectedAbbreviation={this.state.selectedAbbreviation}
                            selectedLabel={this.state.selectedLabel}
                            selectedName={this.state.selectedName}>
                        </ClearQuerycache>
                    }
                    {this.state.displayDialogAddBackend &&
                        <AddBackend
                            displayDialogAddBackend={this.state.displayDialogAddBackend}
                            onCancelAddBackend={this.onCancelAddBackend}
                            addBackend={this.addBackend}
                            allBackendTypes={this.state.allBackendTypes}>
                        </AddBackend>
                    }
                    {this.state.displayDialogSetupBackends &&
                        <SetupBackends
                            displayDialogSetupBackends={this.state.displayDialogSetupBackends}
                            onCancelSetupBackends={this.onCancelSetupBackends}
                            companyBackends={this.state.companyBackends}
                            allBackends={this.state.allBackends}
                            onBackendOrgClick={this.onBackendOrgClick}
                            onBackendSuborgClick={this.onBackendSuborgClick}>
                        </SetupBackends>
                    }
                    <Dialog visible={this.state.displayClearQCAll} style={{ 'width': "40vw" }}
                        header="Clear querycache" focusOnShow={false}
                        modal={true}
                        footer={footerClearQCAll}
                        dismissableMask={false}
                        onHide={() => this.onCancelClearQCAll()} className='styledDialog'>
                        <div style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            <div style={{ marginBottom: 10 }}>
                                <label>{`This will clear the querycache of all backends with type 'webapi' for the whole application.`}</label>
                            </div>
                            <div style={{ marginBottom: 15 }}>
                                <Checkbox inputId='clearQCChecked'
                                    onChange={(e) => this.onCheckClearQCAll(e)}
                                    checked={this.state.clearQCCheckedAll ? true : false}
                                    value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                                />
                                <label htmlFor='clearQCChecked' className='p-checkbox-label'>Please check to confirm</label>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default BackendAdministration
