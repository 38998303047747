import * as am4core from "@amcharts/amcharts4/core";

export const addWatermarkSubscription = (chart) => {
    // --- add watermark on export --- //
    const watermark = chart.current.createChild(am4core.Label);
    watermark.text = `© ${new Date().getFullYear()} Digital Science & Research Solutions Inc. All rights reserved. Non-commercial\nredistribution / external re-use of this work is permitted subject to appropriate\nacknowledgement. This work is sourced from Dimensions® at www.dimensions.ai.`; //"Source: [bold]amcharts.com[/]";
    watermark.align = "center";
    watermark.fillOpacity = 0.7;
    watermark.valign = "bottom";
    watermark.position = 'absolute';
    watermark.bottom = 0;
    watermark.disabled = true;

    // --- add to container --- //
    chart.current.tooltipContainer.children.push(watermark); // chartContainer  tooltipContainer
    // --- enable watermark on export --- //
    chart.current.exporting.events.on("exportstarted", function () {
        watermark.disabled = false;
    });

    chart.current.exporting.events.on("exportfinished", function () {
        watermark.disabled = true;
    });
    // --- add watermark to validated sprites --- //
    chart.current.exporting.validateSprites.push(watermark);
}
