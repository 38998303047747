// --- generation of unique IDs for, e.g. syntaxes, tokens and types --- //
let syntaxTagIDIndex = 1;
let roleTagIDIndex = 1;
let macroTagIDIndex = 1;
let tokenIDIndex = 1;
let typeIDIndex = 1;

export const generateNewSyntaxTagID = () => {
    return `ST_${syntaxTagIDIndex++}`;
}
export const generateNewRoleTagID = () => {
    return `RT_${roleTagIDIndex++}`;
}
export const generateNewMacroTagID = () => {
    return `MC_${macroTagIDIndex++}`;
}
export const generateNewTokenID = () => {
    return `TK_${tokenIDIndex++}`;
}
export const generateNewTypeID = () => {
    return `TP_${typeIDIndex++}`;
}