import {sendApiRequest} from '../index'
import {APP_PROPERTIES} from '../../properties/index';
import {createTermSuggestionsRequest} from "./ConceptApi";

export const fetchBioactivitiesSuggestions = async (term, repositoriesIdArray, filterDomains, filterOcids = null,
    annotatedTermsOnly = true, termsOnly = true, addFreetextOptions = false) => {

    // --- fetch term suggestions --- //
    const request = createTermSuggestionsRequest(term, filterDomains, 20, true, true, annotatedTermsOnly, filterOcids);
    delete request.useDomainIndex;

    request.repositories = repositoriesIdArray;

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/bioactivities/suggestions/all`, request);

    if (result.status === 'SUCCESS') {
        let terms = [];
        if (addFreetextOptions) {
            addFreetextOptions(term, terms);
        }

        terms = result.payload
        return ({status: 'SUCCESS', payload: terms});
    }

    return result;
}

export const fetchBioactivitiesRoleMapping = async () => {
    const {payload} = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/bioactivities/roleMapping`);
    return payload;
};

export const fetchSearchResults = async (data) => {
    const {startIndex, count, ...restData} = data;

    return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/bioactivities/relations/count/${count}/start/${startIndex}`, restData);
}

export const fetchSmiles = async (smilesArray) => {
    if (smilesArray.length) {
        const structures = {structures: smilesArray, width: 300, height: 300, format: 'svg'};
        return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry/images`, structures);
    }
}
