import { sendApiRequest, sendExportToFileApiRequest } from '../index';
import { APP_PROPERTIES, QUERY_CATEGORY_DOC_SEARCH, QUERY_FORM_ADVANCED_3, QUERY_TYPE_ADVANCED_SEARCH_3 } from '../../properties/index';


/**
 * Fetches watchlists of user. Removes history and favorites entries if wanted.
 * @param {Boolean} includeHistory include history entries
 * @param {Boolean} includeFavorites include favorites entries
 * @param {Boolean} includeSharedWithMe include watchlists that are shared with this user
 * @returns 
 */
export const fetchWatchlists = async (includeHistory = false, includeFavorites = false, includeSharedWithMe = false) => {

    const page = 0;
    const count = 1000;

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/page/${page}/count/${count}/list`);

    if (result.status === 'SUCCESS' && result.payload.content) {
        // --- remove history and favorites if neccessary --- //
        let watchlists = result.payload.content.filter(function (collection) {
            return (includeHistory || collection.name !== 'History') &&
                (includeFavorites || collection.name !== 'My Favorites') &&
                (includeSharedWithMe && (collection.writable)) // hasOwnProperty('sharedComment')
        })
        result.payload = watchlists;
    }

    return result;
}

/**
 * Returns watchlists which have the "active on dashboard" flag.
 * @returns 
 */
export const getActiveDashboardWatchlists = async () => {

    const tagName = 'dashboard';
    const page = 0;
    const count = 1000;

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/tag/${tagName}/page/${page}/count/${count}/list`);

    return result;
}

/**
 * Changes the "active on dashboard" flag of a watchlist.
 * @param {Integer} watchlistID 
 * @param {Boolean} active 
 * @returns 
 */
export const changeActiveDashboardStatus = async (watchlistID, active = false) => {

    const tagName = 'dashboard';
    const method = active ? 'PUT' : 'DELETE';

    const result = await sendApiRequest(method, `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${watchlistID}/tag?queryCollectionTag=${tagName}`);

    return result;
}

/**
 * Stores a new watchlist with name and description and flag if it should be shown on the dashboard. 
 * @param {*} name name of the watchlist
 * @param {*} description descrition of the watchlist
 * @param {*} shared shared for department
 * @param {*} users shared for these users
 * @returns 
 */
export const createWatchlist = async (name, description, shared, usersRead = [], usersWrite = [], orgShared = [], editableForSharedUsers) => {
    let departments = []
    orgShared.forEach(dep => {
        departments = [...departments, Number(dep)]
    })
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection`, {
        name: name,
        description: description,
        //sharedForDepartment: shared,
        userSharesRead: usersRead,
        userSharesWrite: usersWrite,
        departmentSharesRead: departments,
        //shareWritable: editableForSharedUsers
    });
    return result;
}

/**
 * Returns summary for specific watchlist.
 * @param {*} watchlistID watchlistID
 * @param {*} time start date
 */
export const getSummaryForWatchlist = async (watchlistID, time) => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/collection/${watchlistID}/time/${time}/hitcount`);
    return result;
}

/**
 * Returns all possible watchlist tags.
 */
export const getWatchlistTags = async () => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/tags`);

    return result;
}

/**
 * Returns statistics for a specific query.
 * @param {*} queryID query ID
 * @param {*} time start date
 */
export const getStatisticsForQuery = async (queryID, time) => {

    const result = time ?
        await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/${queryID}/time/${time}/statistics`) :
        await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/${queryID}/statistics`);

    return result;
}

/**
 * Runs document search for specific query.
 * @param {*} queryID 
 * @param {*} repID 
 * @param {*} facets 
 * @param {*} start 
 * @param {*} count 
 * @param {*} time 
 * @param {*} sortField 
 * @param {*} sortDirection 
 * @param {*} groupByPatentFamily 
 * @returns 
 */
export const getDocumentsForQuery = async (queryID, repID, facets, start, count, time, sortField, sortDirection, groupByPatentFamily) => {

    const req = {};

    if (sortField) {
        req.sortBy = sortField;
    }
    if (sortDirection) {
        req.sortDirection = sortDirection;
    }
    if (facets && facets.length > 0) {
        req.facettes = facets;
    }
    if (groupByPatentFamily) {
        req.groupBy = groupByPatentFamily;
    }

    const result = time ?
        await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/${queryID}/documents/repository/${repID}/time/${time}/start/${start}/count/${count}`, req) :
        await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/${queryID}/documents/repository/${repID}/start/${start}/count/${count}`, req);
    return result;
}

/**
 * Exports document meta data for specific query.
 * @param {*} queryID 
 * @param {*} repID 
 * @param {*} format 
 * @param {*} time 
 * @param {*} field 
 * @param {*} direction 
 * @param {*} maxNumOfResults 
 */
export const exportDocumentsForQuery = async (queryID, repID, format, time, field, direction, maxNumOfResults) => {

    const req = {
        sortBy: field,
        sortDirection: direction
    };
    if (maxNumOfResults) {
        req.documentLimit = maxNumOfResults;
    }

    if (time) {
        await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/${queryID}/documents/repository/${repID}/time/${time}/format/${format}`, 'documents.' + format, req);
    }
    else {
        await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/${queryID}/documents/repository/${repID}/format/${format}`, 'documents.' + format, req);
    }
}


// === saved searches === //

/**
 * Fetches a saved search by its ID.
 * @param {string} queryID 
 * @returns saved search with given ID
 */
export const fetchQueryByID = async (queryID) => {
    const url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${queryID}`;
    const response = await sendApiRequest('GET', url);
    return response;
}

/**
 * Stores a query, incl. search form content used to restore the search form later.
 * If watchlist IDs are  given, the query will be added to these watchlists.
 * 
 * @param {string} query query string
 * @param {Array} filterQueries list of filter query objects
 * @param {string} name short name of the stored query
 * @param {string} fullName longer name of the stored query
 * @param {string} description description of the stored query
 * @param {string} searchCategory search category identifier, e.g. cat_doc_search (document search), cat_cooc_search (correlations search)
 * @param {string} searchType search type identifier, e.g. quick_search, quick_search_2, advanced_search, cooc_search
 * @param {string} searchForm search form identifier, e.g. quick, advanced, cooc (in case there are different forms for a search type)
 * @param {Object} formContent search form content used for restoring the form
 * @param {Array} watchlistIDs array containing watchlist IDs
 */
export const storeQuery = async (query, filterQueries, name, fullName, description, searchCategory, searchType, searchForm, formContent = {}, watchlistIDs = null) => {

    const hasWatchlists = watchlistIDs && watchlistIDs.length > 0;
    const method = hasWatchlists ? 'PUT' : 'POST';
    const url = hasWatchlists ?
        `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection?collectionIds=${watchlistIDs}` :
        `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query`;

    const request = {
        value: query,
        name: name,
        fullName: fullName,
        description: description,
        category: searchCategory,
        type: searchType,
        form: searchForm,
        formContent: JSON.stringify(formContent)
    };

    if (filterQueries) {
        request.filterQueries = filterQueries;
    }

    const result = await sendApiRequest(method, url, request);

    return result;
}

/**
 * Updates a query, incl. search form content used to restore the search form later. 
 * If watchlist IDs are  given, the query will be added or moved to these watchlists.
 * 
 * @param {string} queryID query ID in case existing query should be updated
 * @param {string} query query string
 * @param {Array} filterQueries list of filter query objects
 * @param {string} name short name of the stored query
 * @param {string} fullName longer name of the stored query
 * @param {string} description description of the stored query
 * @param {string} searchCategory search category identifier, e.g. cat_doc_search (document search), cat_cooc_search (correlations search)
 * @param {string} searchType search type identifier, e.g. quick_search, quick_search_2, advanced_search, cooc_search
 * @param {string} searchForm search form identifier, e.g. quick, advanced, cooc (in case there are different forms for a search type)
 * @param {Object} formContent search form content used for restoring the form
 * @param {Array} watchlistIDs array containing watchlist IDs
 */
export const updateQuery = async (queryID, query, filterQueries, name, fullName, description, searchCategory, searchType, searchForm, formContent = {}, watchlistIDs = null) => {

    const urlUpdate = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${queryID}`;

    const request = {
        value: query,
        name: name,
        fullName: fullName,
        description: description,
        category: searchCategory,
        type: searchType,
        form: searchForm,
        formContent: JSON.stringify(formContent)
    };

    if (filterQueries) {
        request.filterQueries = filterQueries;
    }

    const resultUpdate = await sendApiRequest('POST', urlUpdate, request);

    const hasWatchlists = watchlistIDs && watchlistIDs.length > 0;
    if (hasWatchlists && resultUpdate.status === 'SUCCESS') {

        const url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${queryID}/collections?ids=${watchlistIDs}`;

        const result = await sendApiRequest('PUT', url, {});
        if (result.status !== 'SUCCESS') {
            return result;
        }
    }

    return resultUpdate;
}

export const deleteQuery = async (queryID) => {

    const url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query?ids=${queryID}`;

    const result = await sendApiRequest('DELETE', url, {});
    return result;
}


// === backup searches === //

export const fetchBackupQueries = async () => {

    const page = 0;
    const count = 100;

    const url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/name/backups/page/${page}/count/${count}/list`;
    const response = await sendApiRequest('GET', url);
    return response;
}

export const storeBackupQuery = async (request) => {

    const url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/backups`;
    const result = await sendApiRequest('PUT', url, request);

    return result;
}

export const updateBackupQuery = async (queryID, request) => {

    const url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${queryID}`;
    const result = await sendApiRequest('POST', url, request);

    return result;
}


/**
 * Converts old form content of saved document query to new format 
 * by simply adding query term objects.
 * Old format stores term and domains separate from OCIDs.
 * New format stores complete query term objects. 
 */
export const convertOldFormContentToNewFormat = (formContent) => {

    if (formContent && formContent.queryOCIDS && formContent.queryNames) {
        // --- update query terms --- /
        formContent.queryTerms = [];
        for (let i = 0; i < formContent.queryNames.length; i++) {
            // -- query term display name --- //
            let term = formContent.queryNames[i].term;
            if (term) {
                term = term.replace(' (Text exact)', '');
                term = term.replace(' (Text with variants)', '');
            }
            // --- query term domains --- //
            let domains = [];
            if (formContent.queryNames[i].domains) {
                domains = typeof formContent.queryNames[i].domains === 'string' ?
                    [formContent.queryNames[i].domains] : formContent.queryNames[i].domains;
            }
            // --- term OCIDs (->collect) OR free text (-> update domains) --- //
            const entries = formContent.queryOCIDS[i];
            let ocids = [];
            entries.forEach(entry => {
                if (entry['+ocid']) {
                    ocids.push(entry['+ocid']);
                }
                else if (entry['+tr']) {
                    domains = ['tr'];
                }
                else if (entry['+t']) {
                    domains = ['t'];
                }
            });

            // --- add as new query term --- //
            const queryTerm = {
                term: term,
                domains: domains
            };
            if (ocids.length > 0) {
                queryTerm.ocids = ocids;
            }
            formContent.queryTerms.push(queryTerm);
        }

        // --- update filters --- //
        if (formContent.filters) {
            if (formContent.filters.patentFilters) {
                formContent.filters.additionalFiltersTab = 'patentFilters';
                formContent.filters.additionalFilters = formContent.filters.patentFilters;
            }
            else if (formContent.filters.journalFilters) {
                formContent.filters.additionalFiltersTab = 'journalFilters';
                formContent.filters.additionalFilters = formContent.filters.journalFilters;
            }
            if (formContent.filters.clinicalTrialFilters) {
                formContent.filters.additionalFiltersTab = 'clinicalTrialFilters';
                formContent.filters.additionalFilters = formContent.filters.clinicalTrialFilters;
            }
            // --- delete old filter objects --- //
            delete formContent.filters.activeAdditionalFilters;
            delete formContent.filters.patentFilters;
            delete formContent.filters.journalFilters;
            delete formContent.filters.clinicalTrialFilters;
        }
    }

    return formContent;
}