import React, { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";
import DateRangeFilterText from "./DateRangeFilterText";


class PublicationDateFilterInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="pubDateInfo"
                title="Click for information about publication date"
                imageAltText="Publication date information"
                overlayContent={
                    <>
                        <h3>Publication date</h3>
                        <p>
                            Publication date is the earliest date a document was published. 
                            Usually it is the E-publication date.
                        </p>
                        <DateRangeFilterText />
                    </>
                }
            />
        );
    }
}

export default PublicationDateFilterInfo;