import "./DocumentMetadataInfo.css";
import { Component } from 'react';
import { CLINICAL_TRIAL_REPOS, DRUG_LABELS_REPO, GRANT_DS_REPOS, GRANT_REPOS } from '../../../properties';
import ExternalLinkIcon from '../general/ExternalLinkIcon/ExternalLinkIcon';
import SeparatorPoint from '../general/SeparatorPoint';
import { convertInitialLetterToUpperCase, getYearFromDate, isArrayEmpty, isExternalURL, shortenLargeNumber } from '../util';


class DocumentMetadataInfo extends Component {

    getMetadataBlocks = (documentData, organisms) => {

        let pubdate = !isArrayEmpty(documentData.pubdate) ? new Date(documentData.pubdate[0]).getFullYear() : null;
        pubdate = pubdate ? pubdate : documentData.pubdate;
        const assignees = !isArrayEmpty(documentData['Pat_assignees_normalized']) ? documentData['Pat_assignees_normalized'] : documentData['Pat_assignees'];

        const metadata = [];

        // --- DSM Labdocs - people --- //
        if (this.props.repositoryInfo.name === 'dsm_labdocs' && (!isArrayEmpty(documentData.laboratory) || !isArrayEmpty(documentData.technician))) {

            const entries = [];
            if (!isArrayEmpty(documentData.laboratory)) {
                entries.push(<span title={this.renderTooltipForList(documentData.laboratory, 'Laboratory head')}>
                    {`Laboratory head: ${this.renderList(documentData.laboratory)}`}
                </span>);
            }
            if (!isArrayEmpty(documentData.technician)) {
                entries.push(<span title={this.renderTooltipForList(documentData.technician, 'Technician')}>
                    {`Technician: ${this.renderList(documentData.technician)}`}
                </span>);
            }

            if (!isArrayEmpty(entries)) {
                metadata.push(<span>{this.renderMetadataBlock(entries, true)}</span>);
            }
        }

        // --- DSM Labdocs - original file path --- //
        if (this.props.repositoryInfo.name === 'dsm_labdocs' && documentData.extId) {

            metadata.push(<span title="Path to original document">
                {`Original file path: ${documentData.extId}`}
            </span>);
        }

        // --- EFSA demo - original file path --- //
        if (this.props.repositoryInfo.name === 'efsademo' && !isArrayEmpty(documentData.origFileRelPath)) {

            metadata.push(<span title="Path to original document">
                {`Original file path: ${documentData.origFileRelPath[0]}`}
            </span>);
        }

        // --- authors --- //
        if (this.props.repositoryInfo.name !== 'dsm_labdocs' && !isArrayEmpty(documentData.authors) && isArrayEmpty(documentData.Pat_inventors)) {

            metadata.push(<span title={this.renderTooltipForList(documentData.authors, 'Author')}>
                {`${this.renderList(documentData.authors)}`}
            </span>);
        }

        // --- Patents - people --- //
        if (!isArrayEmpty(assignees) || !isArrayEmpty(documentData.Pat_inventors)) {

            const entries = [];
            if (!isArrayEmpty(assignees)) {
                entries.push(<span>
                    <span title={this.renderTooltipForList(assignees, 'Assignee')}>
                        {`Assignee: ${this.renderList(assignees)}`}
                    </span>
                    {!isArrayEmpty(documentData.Pat_assignees_parent_company) ?
                        <span title={this.renderTooltipForList(documentData.Pat_assignees_parent_company, 'Assignees parent company')}>
                            {` (${this.renderList(documentData.Pat_assignees_parent_company)})`}
                        </span> : null}
                </span>);
            }
            if (!isArrayEmpty(documentData.Pat_inventors && (!this.props.showCustomInfo || (this.props.showCustomInfo && !this.props.compactViewSentenceAnalysis)))) {
                entries.push(<span title={this.renderTooltipForList(documentData.Pat_inventors, 'Inventor')}>
                    {`Inventor: ${this.renderList(documentData.Pat_inventors)}`}
                </span>);
            }

            if (!isArrayEmpty(entries)) {
                metadata.push(<span>{this.renderMetadataBlock(entries, true)}</span>);
            }
        }

        // --- patents - office, date etc. --- //
        if (!isArrayEmpty(documentData.Pat_country) || !isArrayEmpty(documentData['Pat_priority-claim_date'])) {

            const entries = [];
            if (!isArrayEmpty(documentData.Pat_country && (!this.props.showCustomInfo || (this.props.showCustomInfo && !this.props.compactViewSentenceAnalysis)))) {
                entries.push(<span title={this.renderTooltipForList(documentData.Pat_inventors, 'Patent office')}>
                    {`Patent office: ${this.renderList(documentData.Pat_country)}`}
                </span>);
            }
            if (!isArrayEmpty(documentData['Pat_document-id']) && this.props.showCustomInfo) {
                entries.push(<span style={{ fontWeight: 'bold' }} title="Patent number">
                    {`Patent number: ${documentData['Pat_document-id']}`}
                </span>);
            }
            if (!isArrayEmpty(documentData['Pat_priority-claim_date'])) {
                entries.push(<span title="Priority claim date">
                    {`Priority claim date: ${documentData['Pat_priority-claim_date']}`}
                </span>);
            }

            if (!isArrayEmpty(entries)) {
                metadata.push(<span>{this.renderMetadataBlock(entries, true)}</span>);
            }
        }

        // --- journals - data --- //
        if (pubdate || !isArrayEmpty(documentData.journal) || !isArrayEmpty(documentData.source_title) || !isArrayEmpty(documentData.pubType)) {

            const entries = [];

            if (pubdate && !documentData.startdate && !documentData['Pat_priority-claim_date']) {
                entries.push(<span title="Publication date">
                    {pubdate}
                </span>);
            }

            if (!isArrayEmpty(documentData.pubType) || !isArrayEmpty(documentData.journal) ||
                (isArrayEmpty(documentData.journal) && !isArrayEmpty(documentData.source_title))) {
                entries.push(<span>
                    {!isArrayEmpty(documentData.journal) ?
                        <span title="Journal">
                            {`${documentData.journal[0]}`}
                        </span> : null
                    }
                    {isArrayEmpty(documentData.journal) && !isArrayEmpty(documentData.source_title) ?
                        <span title="Source">
                            {`${documentData.source_title[0]}`}
                        </span> : null
                    }
                    {!isArrayEmpty(documentData.pubType) ?
                        <span title="Publication type">
                            {!isArrayEmpty(documentData.journal) || (isArrayEmpty(documentData.journal) && !isArrayEmpty(documentData.source_title)) ? ' - ' : ''}
                            {`${convertInitialLetterToUpperCase(documentData.pubType[0])}`}
                        </span> : null
                    }
                </span>);
            }

            if (!isArrayEmpty(entries)) {
                metadata.push(<span>{this.renderMetadataBlock(entries, true)}</span>);
            }
        }

        if (DRUG_LABELS_REPO[this.props.repositoryInfo.name] && documentData.source) {

            const entries = [];
            if (documentData.source) {
                entries.push(<span title="Source">
                    {`Source: ${documentData.source}`}
                </span>);
            }

            if (!isArrayEmpty(entries)) {
                metadata.push(<span>{this.renderMetadataBlock(entries, true)}</span>);
            }
        }

        // --- clinical trials - general data --- //
        if (CLINICAL_TRIAL_REPOS[this.props.repositoryInfo.name] &&
            (documentData.source || !isArrayEmpty(documentData.startdate) ||
                !isArrayEmpty(documentData.clinicalphase) || !isArrayEmpty(documentData.status))) {

            const entries = [];

            if (documentData.source) {
                entries.push(<span title="Source">
                    {`Source: ${documentData.source}`}
                </span>);
            }
            if (!isArrayEmpty(documentData.startdate)) {
                entries.push(<span title="Start date">
                    {`Start date: ${documentData.startdate[0]}`}
                </span>);
            }
            if (!isArrayEmpty(documentData.clinicalphase)) {
                entries.push(<span title={this.renderTooltipForList(documentData.Pat_inventors, 'Phase')}>
                    {`Phase: ${this.renderList(documentData.clinicalphase)}`}
                </span>);
            }
            if (!isArrayEmpty(documentData.status)) {
                entries.push(<span title={this.renderTooltipForList(documentData.Pat_inventors, 'Status')}>
                    {`Status: ${this.renderList(documentData.status)}`}
                </span>);
            }

            if (!isArrayEmpty(entries)) {
                metadata.push(<span>{this.renderMetadataBlock(entries, true)}</span>);
            }
        }

        // --- clinical trials - sponsors --- //
        if (CLINICAL_TRIAL_REPOS[this.props.repositoryInfo.name] && documentData.sponsors) {

            metadata.push(<span title={this.renderTooltipForList(organisms, 'Sponsor')}>
                {`Sponsor: ${this.renderList(documentData.sponsors)}`}
            </span>);
        }

        // --- grants - data --- //
        if ((GRANT_DS_REPOS[this.props.repositoryInfo.name] || GRANT_REPOS[this.props.repositoryInfo.name]) &&
            (!isArrayEmpty(documentData.funder) || !isArrayEmpty(documentData.grantees) ||
                !isArrayEmpty(documentData.funding_amount) || !isArrayEmpty(documentData.funding_currency) ||
                (!isArrayEmpty(documentData.startdate) || !isArrayEmpty(documentData.enddate)))) {

            if (!isArrayEmpty(documentData.funder)) {
                metadata.push(<span title={this.renderTooltipForList(organisms, 'Funder')}>
                    {`${this.renderList(documentData.funder)}`}
                </span>);
            }
            if (!isArrayEmpty(documentData.grantees)) {
                metadata.push(<span title={this.renderTooltipForList(organisms, 'Investigator')}>
                    {`to ${this.renderList(documentData.grantees)}`}
                </span>);
            }
            if ((!isArrayEmpty(documentData.funding_amount) && !isArrayEmpty(documentData.funding_currency)) ||
                (!isArrayEmpty(documentData.startdate) || !isArrayEmpty(documentData.enddate))) {
                metadata.push(<span>
                    <span>Funding: </span>
                    {!isArrayEmpty(documentData.funding_amount) && !isArrayEmpty(documentData.funding_currency) ?
                        <span title="Funding amount">
                            {documentData.funding_currency[0]} {shortenLargeNumber(documentData.funding_amount[0])}
                        </span> : null
                    }
                    {!isArrayEmpty(documentData.funding_amount) && !isArrayEmpty(documentData.funding_currency) &&
                        (!isArrayEmpty(documentData.startdate) || !isArrayEmpty(documentData.enddate)) ?
                        <SeparatorPoint /> : null}
                    {!isArrayEmpty(documentData.startdate) || !isArrayEmpty(documentData.enddate) ?
                        <span title="Funding period">
                            {`${!isArrayEmpty(documentData.startdate) ? getYearFromDate(documentData.startdate[0]) : ''} - ${!isArrayEmpty(documentData.enddate) ? getYearFromDate(documentData.enddate[0]) : ''}`}
                        </span> : null
                    }
                </span>);
            }
        }

        // --- organisms --- //
        if (organisms && Object.keys(organisms).length > 0) {

            metadata.push(<span title={this.renderTooltipForList(organisms, 'Organism')}>
                {`Organism: ${this.renderList(Object.keys(organisms))}`}
            </span>);
        }

        return metadata;
    }

    renderMetadataBlock = (metadata, compactView, compactViewSentenceAnalysis) => {
        return <>
            {metadata?.map((md, index) => {
                if (compactView || !compactViewSentenceAnalysis) {
                    return <span key={`md${index}`}>
                        {md}
                        {index < metadata.length - 1 ? <SeparatorPoint /> : null}
                    </span>
                }
                else {
                    return <div key={`md${index}`}>
                        {md}
                    </div>
                }
            })}
        </>
    }

    renderTooltipForList = (list = [], label = 'Entries', cutOff = 5) => {
        let tooltip = '';
        if (list) {
            tooltip = `${label}${list.length > cutOff ? ` (${list.length})` : ''}`;
        }
        return tooltip;
    }

    renderList = (list = [], cutOff = 5) => {
        return `${list.slice(0, cutOff).join('; ')}${list.length > cutOff ? ', ...' : ''}`;
    }


    render() {

        const { documentData, organisms, compactView, oneLineOverflow, compactViewSentenceAnalysis } = this.props;

        const md = this.getMetadataBlocks(documentData, organisms);

        return (
            <div className={oneLineOverflow ? "oneLineOverflow" : "whiteSpaceBreakSpaces"}>
                {this.renderMetadataBlock(md, compactView, compactViewSentenceAnalysis)}
            </div>
        )
    }
}

export default DocumentMetadataInfo;

