import axios from 'axios';
import { saveAs } from 'file-saver';
import { sleep } from '../components/webapi/util';
import { APP_PROPERTIES } from '../properties/index';

export const url = APP_PROPERTIES.MIDDLEWARE_BASE_URL;


let headers; /*= {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}*/

//export const getHeaderNoToken = () => ( headers = { ...headers } );
//export const getHeaderToken = () => (headers = { ...headers, 'Authorization': `Bearer ${localStorage.token}` });
//export const getHeaderNoToken = () => { return { ...headers } };
//export const getHeaderToken = () => { return { ...headers, 'Authorization': `Bearer ${localStorage.token}` } };

export const getHeaderNoToken = () => (headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' });
export const getHeaderToken = () => (headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.token}` });
export const getHeaderTokenText = () => (headers = { 'Accept': 'text/html', 'Content-Type': 'text/html', 'Authorization': `Bearer ${localStorage.token}` });


export const postVoid = (postUrl, body) => fetch(postUrl, {
    method: 'POST',
    headers: getHeaderToken(),
    body
})
    .then(res => res.ok)
    .then(isOk => (isOk ? { status: 'SUCCESS', undefined } : { status: 'FAILED', undefined }))

export const deleteVoid = (deleteUrl) => fetch(deleteUrl, {
    method: 'DELETE',
    headers: getHeaderToken()
})
    .then(res => res.ok)
    .then(isOk => (isOk ? { status: 'SUCCESS', undefined } : { status: 'FAILED', undefined }))


/**
 * Sends request to given URL with given request body. Header  incl. token is added automatically.
 * Returns object containing 'status' and either 'payload' if 'status' === 'SUCCESS' or 'message'
 * containing the error message if 'status' === 'FAILED'.
 * 
 * @param {string} method the request method (GET, POST, PUT, ...)
 * @param {string} url API call URL
 * @param {Object} data the request body
 * 
 */
export const sendApiRequest = async (method, url, data = '', tryAgainIf401 = false) => {
    try {
        const result = await axios({
            method: method,
            url: url,
            headers: getHeaderToken(),
            data: JSON.stringify(data),
        });
        //console.log('result: ', result);

        if (result.ok || result.status < 400) {
            return ({ status: 'SUCCESS', statusCode: result.status, payload: result.data });
        }
        else {
            let message;
            if (result.statusText) {
                message = result.statusText;
            }
            else if (result.message) {
                message = result.message;
            }
            else if (result.userMessage) {
                message = result.userMessage;
            }
            return ({ status: 'FAILED', statusCode: result.status, message: message });
        }
    }
    catch (e) {
        /*
        console.log('3 e: ', e);
        */
        if (tryAgainIf401 && e.response && e.response.status === 401) {
            await sleep(2000);
            //console.log('FAILED with token : ', token);
            return sendApiRequest(method, url, data, false);
        }
        /*
        console.log('error:  ', url);
        console.log('e.response: ', e.response);
        console.log('data type: ', (typeof e.response.data));
        console.log('data string: ', (typeof e.response.data === 'string'));
        if (e.response) {
            console.log('e.response.statusText: ', e.response.statusText);
            console.log('e.response.status: ', e.response.status);
        }
        */
        let message = 'Unknown error occurred.';
        let statusCode = 520;
        if (e.response) {
            if (e.response.status) {
                statusCode = e.response.status;
            }

            if (e.response.data) {
                if (typeof e.response.data === 'string') {
                    message = '' + e.response.data;
                }
                else if (e.response.data.message) {
                    message = e.response.data.message;
                }
                else if (e.response.data.userMessage) { // do not show  e.response.data.detailedMessage
                    message = e.response.data.userMessage;
                }
            }
            else if (e.response.statusText) {
                message = e.response.statusText;
            }
            else if (e.response.message) {
                message = e.response.message;
            }
        }

        return ({ status: 'FAILED', statusCode: statusCode, message: message });
    }
}

// TODO
export const sendChemAxonWebservicesApiRequest = async (method, url, data = '') => {
    try {
        const result = await axios({
            method: method,
            url: url,
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json;charset=utf-8"
            },
            //timeout: timeout,
            data: JSON.stringify(data)
        });

        console.log('result: ', result);

        if (result.status < 400) {
            return ({ status: 'SUCCESS', statusCode: result.status, payload: result.data });
        }
        else {
            let message;
            if (result.statusText) {
                message = result.statusText;
            }
            else if (result.message) {
                message = result.message;
            }
            return ({ status: 'FAILED', statusCode: result.status, message: message });
        }
    }
    catch (e) {
        let message = 'Unknown error occurred.';
        let statusCode = 520;
        if (e.response) {
            if (e.response.status) {
                statusCode = e.response.status;
            }
            if (e.response.statusText) {
                message = e.response.statusText;
            }
            else if (e.response.data) {
                message = '' + e.response.data;
            }
        }
        return ({ status: 'FAILED', statusCode: statusCode, message: message });
    }
}


/**
 * Sends request to given URL. Header incl. token is added automatically.
 * Ends with save as dialog to save file on users disk.
 * 
 * @param {string} method the request method (GET, POST, ...)
 * @param {string} url API call URL
 * @param {string} fileName name of the exported file
 * @param {Object} data request payload object
 */
let exportFileName = null;
export const sendExportToFileApiRequest = async (method, url, defaultFileName, data, returnURL) => fetch(
    url, {
    method: method,
    responseType: 'blob',
    headers: getHeaderToken(),
    body: data ? JSON.stringify(data) : null
}).then(function (response) {
    //console.log('response: ', response);
    if (response.ok) {
        exportFileName = response.headers && response.headers.get('content-disposition') ?
            response.headers.get('content-disposition').replace(/.*filename=/, '') : defaultFileName;
        return response.blob();
    }
    else {
        return ({ status: 'FAILED', statusCode: response.status, message: response.text() });
    }
}).then(function (blob) {
    if (blob) {
        if (returnURL) {
            return window.URL.createObjectURL(blob, { oneTimeOnly: true });
        }
        else {
            saveAs(blob, exportFileName);
        }
    }
    else {
        //console.log('error2: ');
        return { status: 'FAILED', message: 'An error occurred. Could not save file.' };
    }
}).then(payload => ({
    status: 'SUCCESS', payload
})).catch(function (e) {
    //console.log('error3: ', e);
    let message = 'Error occurred.';
    if (e.response && e.response.status && e.response.statusText) {
        message = e.response.statusText;
        if (e.response.data) {
            message += ' ' + e.response.data;
        }
        return ({ status: 'FAILED', statusCode: e.response.status, message: message });
    }
    return ({ status: 'FAILED', statusCode: -1, message: message });
    //return ({ status: 'FAILED', statusCode: e.response.status, message: e.response.statusText + ': ' + e.response.data });
});


/**
 * Sends request to given URL. Header incl. token is added automatically.
 * Ends with save as dialog to save file on users disk.
 * 
 * @param {string} method the request method (GET, POST, ...)
 * @param {string} url API call URL
 * @param {string} fileName name of the exported file
 * @param {Object} data request payload object
 */
let exportFileName2 = null;
export const sendExportFileApiRequest = async (method, url, defaultFileName, data) => fetch(
    url, {
    method: method,
    responseType: 'blob',
    headers: getHeaderToken(),
    body: data ? JSON.stringify(data) : null
}).then(async function (response) {
    //console.log('response: ', response);
    if (response.ok) {
        //console.log('response.ok: ');
        exportFileName2 = response.headers && response.headers.get('content-disposition') ?
            response.headers.get('content-disposition').replace(/.*filename=/, '') : defaultFileName;
        return response.blob();
    }
    else {
        //console.log('response not ok: ');
        if (response.status === 400) {
            return {
                status: response.status,
                statusText: await response.text()
            }
        }
        else if (response.status === 404) {
            return {
                status: response.status,
                statusText: response.statusText,
                message: response.error
            }
        }
        else {
            return response.json();
        }
    }
}).then(function (data) {
    //console.log('blob?: ', data);
    // --- data is of type "blob" -> save as file
    if ((data instanceof Blob) || (data.constructor.name === 'Blob')) {
        //console.log('save as blob: ');
        saveAs(data, exportFileName2);
        return { status: 'SUCCESS', payload: 'File download successful' }
    }
    // -- some error occurred --- //
    else {
        let message = 'Unknown error occurred.';
        let statusCode = 520;
        // --- get status code --- //
        if (data.status) {
            statusCode = data.status;
        }
        // --- try to get the error message --- //
        if (data.statusText) {
            message = data.statusText;
        }
        else if (data.message) {
            message = data.message;
        }
        else if (data.error) {
            message = data.error;
        }
        else if (data.data) {
            message = '' + data.data;
        }
        return ({ status: 'FAILED', statusCode: statusCode, message: message });
    }
}).then(res => res).catch(function (e) {
    //console.log('error3: ', e);
    let message = 'Unknown error occurred.';
    let statusCode = 520;
    if (e.response && e.response.status && e.response.statusText) {
        message = e.response.statusText;
        if (e.response.data) {
            message += ' ' + e.response.data;
        }
        return ({ status: 'FAILED', statusCode: e.response.status, message: message });
    }
    return ({ status: 'FAILED', statusCode: statusCode, message: message });
    //return ({ status: 'FAILED', statusCode: e.response.status, message: e.response.statusText + ': ' + e.response.data });
});


/*
export const sendExportToFileApiRequest_test3 = async (method, url, defaultFileName, data) => {
    let status = 'FAILED'
    //console.log('search: ', search);

    //return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/xlsx?repositoryId=${search.request.repository}`,
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/xlsx/document-meta-data`,
        { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search.request) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.blob()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(function (blob) {
            saveAs(blob, fileName3)
        }).then(payload => ({ status, payload }))
    //.catch(error => ({ status: 'FAILED', message: error }))
}
*/

/**
 * Send some binary to the API
 * 
 * Sends a POST request to given URL. Header incl. token is added automatically.
 * 
 * @param {string} url API call URL
 * @param {Object} data request payload object, binary data
 */
export const postApiBlob = async (url, data) => {

    try {
        var header_fields = getHeaderToken();
        header_fields["Content-Type"] = "application/octet-stream";
        console.log("header_fields", header_fields);
        const result = await axios({
            method: "POST",
            url: url,
            headers: header_fields,
            //timeout: timeout,
            data: data
        });

        if (result.ok || result.status < 400) {
            return ({ status: 'SUCCESS', statusCode: result.status, payload: result.data });
        }
        else {
            return ({ status: 'FAILED', statusCode: result.status, message: result.statusText });
        }
    }
    catch (e) {
        let message = 'Error occurred.';
        if (e.response && e.response.status && e.response.statusText) {
            message = e.response.statusText;
            if (e.response.data) {
                message += ' ' + e.response.data;
            }
            return ({ status: 'FAILED', statusCode: e.response.status, message: message });
        }
        return ({ status: 'FAILED', statusCode: -1, message: message });
    }
}

/**
 * Download the API response
 * 
 * Request some URL from the API, but download the response instead of parsing the JSON.
 * Ends with save as dialog to save file on users disk.
 * 
 * @param {string} method the request method (GET, POST, ...)
 * @param {string} url API call URL
 * @param {string} fileName name of the exported file
 * @param {Object} data request payload object
 * 
 */
export const downloadApiResponse = async (method, url, fileName, data) => fetch(
    url, {
    method: method,
    responseType: 'blob',
    headers: getHeaderToken()
}).then(function (response) {
    if (response.ok) {
        return response.blob();
    }
    else {
        //return { status: 'FAILED', message: response.statusText };
        return ({ status: 'FAILED', statusCode: response.status, message: response.text() });
    }
}).then(function (blob) {
    if (blob) {
        saveAs(blob, fileName);
    }
    else {
        return { status: 'FAILED', message: 'An error occurred. Could not save file.' };
    }
}).then(payload => ({
    status: 'SUCCESS', payload
})).catch(function (e) {
    let message = 'Error occurred.';
    if (e.response && e.response.status && e.response.statusText) {
        message = e.response.statusText;
        if (e.response.data) {
            message += ' ' + e.response.data;
        }
        return ({ status: 'FAILED', statusCode: e.response.status, message: message });
    }
    return ({ status: 'FAILED', statusCode: -1, message: message });
});



/**
* Checks the result of a request (SUCCESS or FAILED od LOGGEDOUT). 
* If an error occurred the user is informed via growl and the result 
* that is returned is null. Otherwise the payload is returned.
 * 
 * @param {Object} result 
 * @param {Object} growl 
 * @param {string} customSuccessSummary
 * @param {string} customSuccessMsg
 * @param {string} customErrorMsg
 * @param {Boolean} sticky
 */
export const checkResultAndGetPayload = (result, growl, customSuccessSummary, customSuccessMsg, customErrorMsg, sticky = false, errorLife = 6000) => {
    if (result.status === 'SUCCESS') {
      
        if (customSuccessSummary && customSuccessMsg && growl && growl.current) {

            growl.current.show({
                life: errorLife, sticky: sticky, closable: true, severity: 'success',
                summary: customSuccessSummary, detail: customSuccessMsg
            });
        }else if(customSuccessSummary && customSuccessMsg && growl && growl?.show ){
            growl.show({
                life: errorLife, sticky: sticky, closable: true, severity: 'success',
                summary: customSuccessSummary, detail: customSuccessMsg
            });
        }
        return result.payload;
    }
    else if (result.status === 'FAILED' || result.status === 'LOGGEDOUT') {
        const errorMsg = !!customErrorMsg ? customErrorMsg : result.message;
        if (growl && growl.current) {
            growl.current.show({
                life: errorLife, sticky: sticky, closable: true, severity: 'error',
                summary: 'Error occurred', detail: errorMsg
            });
        }
        else if (growl && growl?.show) {
            growl.current.show({
                life: errorLife, sticky: sticky, closable: true, severity: 'error',
                summary: 'Error occurred', detail: errorMsg
            });
        }
        else {
            console.log(errorMsg);
        }
    }
    return null;
}


/**
 * 
 * @param {Number} milliseconds time in milliseconds
 */
export const Sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}





// DELETE !!!

/**
 * Sends POST request to given URL. Header incl. token is added automatically.
 * Ends with save as dialog to save file on users disk.
 *
 * @param {string} url API call URL
 * @param {string} fileName name of the exported file
 * @param {Object} data request payload object
 */
/*
export const exportToFilePostRequest = async (url, fileName, data) => fetch(
    url, {
    method: 'POST',
    headers: getHeaderToken(),
    responseType: 'blob',
    body: JSON.stringify(data)
}).then(function (response) {
    if (response.ok) {
        return response.blob();
    }
    else {
        //alert(response.statusText);
        return { status: 'FAILED', message: response.statusText };
    }
})
    .then(function (blob) {
        if (blob) {
            saveAs(blob, fileName);
        }
        else {
            return { status: 'FAILED', message: 'error occurred' };
        }
    })
    .then(payload => ({ status: 'SUCCESS', payload }));
*/

/**
 * Sends GET request to given URL. Header incl. token is added automatically.
 * Ends with save as dialog to save file on users disk.
 *
 * @param {string} url API call URL
 * @param {string} fileName name of the exported file
 */
/*
export const exportToFileGetRequest = async (url, fileName) => fetch(
    url, {
    method: 'GET',
    responseType: 'blob',
    headers: getHeaderToken()
}).then(function (response) {
    if (response.ok) {
        return response.blob();
    }
    else {
        //alert(response.statusText);
        return { status: 'FAILED', message: response.statusText };
    }
}).then(function (blob) {
    if (blob) {
        saveAs(blob, fileName);
    }
    else {
        return { status: 'FAILED', message: 'error occurred' };
    }
})
    .then(payload => ({ status: 'SUCCESS', payload }));
*/


export const getFileAsStream = async (method, url, data = '', tryAgainIf401 = false) => {

    // TODO: use parameter tryAgainIf401 in calls
    /*
    let token = localStorage.token;
    if (!tryAgainIf401) {
        console.log('NEXT TRY!!!!!!!! with token: ', token);
    }
    */


    try {
        const result = await axios({
            method: method,
            url: url,
            headers: getHeaderToken(),
            //timeout: 60 * 5 * 1000,
            data: JSON.stringify(data),
            responseType: 'arraybuffer'

        });

        //console.log('result: ', result);

        if (result.ok || result.status < 400) {
            /*
            if (!tryAgainIf401) {
                console.log('SUCCESS with token : ', token);
            }
            */
            return ({ status: 'SUCCESS', statusCode: result.status, payload: result.data });
        }
        else {
            let message;
            if (result.statusText) {
                message = result.statusText;
            }
            else if (result.message) {
                message = result.message;
            }
            else if (result.userMessage) {
                message = result.userMessage;
            }
            return ({ status: 'FAILED', statusCode: result.status, message: message });
        }
    }
    catch (e) {
        /*
        console.log('3 e: ', e);
        */
        if (tryAgainIf401 && e.response && e.response.status === 401) {
            await sleep(2000);
            //console.log('FAILED with token : ', token);
            return sendApiRequest(method, url, data, false);
        }
        /*
        console.log('error:  ', url);
        console.log('e.response: ', e.response);
        console.log('data type: ', (typeof e.response.data));
        console.log('data string: ', (typeof e.response.data === 'string'));
        if (e.response) {
            console.log('e.response.statusText: ', e.response.statusText);
            console.log('e.response.status: ', e.response.status);
        }
        */
        let message = 'Unknown error occurred.';
        let statusCode = 520;
        if (e.response) {
            if (e.response.status) {
                statusCode = e.response.status;
            }

            if (e.response.data) {
                if (typeof e.response.data === 'string') {
                    message = '' + e.response.data;
                }
                else if (e.response.data.message) {
                    message = e.response.data.message;
                }
            }
            else if (e.response.statusText) {
                message = e.response.statusText;
            }
            else if (e.response.message) {
                message = e.response.message;
            }
        }

        return ({ status: 'FAILED', statusCode: statusCode, message: message });
    }
}
