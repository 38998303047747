/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { getSentenceAnalysisSearchFromStorage } from "./utils/util";
import { addThousandsSeparatorToNumber, createQueryTermsString, isArrayEmpty, hasUserRole } from '../util';
import { ALLOWED_SENTENCE_ANALYSIS_SAVED_SEARCH_TYPES, ALLOWED_SENTENCE_ANALYSIS_LOAD_SEARCH_TYPES } from "../general/docsearch/searchConstants";
import { readFilterDefinitions, METADATA_SORT_FIELDS, PATENT_FAMILIES, QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH, QUERY_CATEGORY_SENTENCE_ANALYSIS_SEARCH } from "../../../properties";
import {
    fetchDocumentsFromCollection,
    fetchDocumentsMetadata,
    addDocumentsToCollections,
    removeDocumentsFromDocumentCollection,
    fetchAdditionalDocumentsInfo,
    fetchSortableFields,
    fetchAvailableDocumentCollections,
    createDocumentSearchRequest,
    fetchMoreDocuments,
    fetchDocumentsV1,
    createDocumentSearchRequestV1,
    exportDocumentsToReadcube
} from '../../../api/content/DocumentApi';
import { storeQuery, updateQuery } from '../../../api/content/WatchlistApi';
import { checkResultAndGetPayload } from '../../../api';
import { getRepositoryForName } from '../../../api/content/ContentApi';
import { DOMAIN_ALIASES, FREE_TEXT_DOMAINS } from '../docview/semantic-search/constants/general';
import { EDV_EXAMPLES } from './examples';
import {
    setSelectedSentenceAnalysis,
    setQueryTermsThresholdSentenceAnalysis,
    setActiveSearchTermsSentenceAnalysis,
    setSentenceOptionSentenceAnalysis,
    setLoaderSentenceAnalysis,
    setShowSentenceEntitiesSentenceAnalysis,
    setSelectedDocumentsSentenceAnalysis,
    setSelectedMetadataOptionSentenceAnalysis,
    setSectionOptionsSentenceAnalysis,
    setSelectedSectionsSentenceAnalysis,
    setSortFieldsSentenceAnalysis,
    setSelectedSortFieldSentenceAnalysis,
    setSelectedSortDirectionSentenceAnalysis,
    setSortDirectionDisabledSentenceAnalysis,
    setSelectedFamilyDocumentsSentenceAnalysis,
    setSelectedFamilyDocumentsRemoveSentenceAnalysis,
    setSearchLoadedSentenceAnalysis
} from '../../../redux/actions/SentenceAnalysisActions';
import { addDataToLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage } from "../util/localStorage";
import { createSortFieldsList } from '../util/content';
import { replaceQueryTermWithConcepts, addConceptsToQueryTerms } from "../general/docsearch/searchUtil";
import AddReadcubeDialog from "../../common/ReadCube/AddReadcubeDialog";
import AddRemoveDocuments from '../docCollection/AddRemoveDocuments';
import SavedSearchesDialog from "../general/SavedSearches/SavedSearchesDialog";
import SeparatorPoint from "../general/SeparatorPoint";
import DocumentList from "./DocumentList";
import DocumentViewContainer from '../container/DocumentViewContainer';
import OntologyBrowserDialog from "../ontbrowser/OntologyBrowserDialog";
import UpdateSearchResultsInfo from "../docsearch/infos/UpdateSearchResultsInfo";
import ToastContent from '../../common/CustomToast/ToastContent';
import ConfirmationDialog from "../../common/ConfirmDialog/ConfirmationDialog";
import SaveSearchDialog from "../../common/SaveSearch/SaveSearchDialog";
import SaveSearchButtons from '../../common/SaveSearch/SaveSearchButtons';
import $ from 'jquery';
import _ from 'lodash';
import { SemanticAutoCompleteMultipleChoices } from '../docview/semantic-search/SemanticAutoCompleteMultipleChoices';
import SearchExamples from '../general/searchexamples/SearchExamples';
import ExportCenterSuccessMessage from "../export/ExportCenterSuccessMessage";

const PATENTS_REPOSITORY_NAME = 'ifipatents';
const PMC_REPOSITORY_NAME = 'pmc';

class SentenceAnalysisContainer extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            activeIndexIncrease: undefined,
            queryTermsAutocomplete: [],
            displaySavedSearchesDialog: false,
            displayLibraryDialog: false,
            domainBrowserVisible: false,
            docViewModalVisible: false,
            queryChanged: false,
            displayCustomToast: false,
            loadCollection: true,
            patFamilyMembers: [],
            showFamilyMembersDialog: false,
            displayRemoveConfirmDialog: false,
            displaySaveDialog: false,
            displayLoadDialog: false,
            abortLoadingClicked: undefined,
            displayProceedSearchToast: false,
            importantTermsNumber: 0,
            displayAddReadcubeDialog: false
            //searchLoaded: false
            //docInfos: {}
        }
        this.growl = createRef();
    }

    componentDidMount = async () => {
        const dataFromStorage = getDataFromLocalStorage(QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id);
        if (dataFromStorage !== undefined && dataFromStorage?.data?.hasOwnProperty("formContent")) {
            this.clearAll(false)
            this.onLoadSearch(dataFromStorage.data)
            removeDataFromLocalStorage(QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id);
        } else {
            this.clearAll(true)
            this.initSearchForm();
        }
        const filteredDocumentCollections = await this.getAvailableDocumentCollections();
        this.setState({ documentCollections: filteredDocumentCollections });

    }

    componentDidUpdate(prevProps) {
        if (prevProps.documentSentences.length !== this.props.documentSentences.length) {
            this.setDisplayProceedSearchToast(false);
        }
        if (!this.state.displayProceedSearchToast && this.state.importantTermsNumber < 1 && this.props.loader !== prevProps.loader && !this.props.loader &&
            this.props.documentSentences.length === 0 && this.props.activeSearchTerms.length > 0) {
            this.setDisplayProceedSearchToast(true);
        }
        if (!_.isEqual(prevProps.userData, this.props.userData)) {
            this.setDisplayProceedSearchToast(false)
            this.initSearchForm();
        }
    }

    refreshDocumentCollections = async () => {
        const filteredDocumentCollections = await this.getAvailableDocumentCollections();
        this.setState({ documentCollections: filteredDocumentCollections });
    }

    getAvailableDocumentCollections = async () => {

        const response = await fetchAvailableDocumentCollections(0, 100);
        const result = checkResultAndGetPayload(response);

        let filteredDocumentCollections = [];
        if (result && result.content) {
            result.content.forEach(collection => {
                if (!collection.shared || collection.editableForSharedUsers) { // sharedComment
                    filteredDocumentCollections.push(collection);
                }
            })
        }

        return filteredDocumentCollections;
    }

    clearAll = (clearSearchTerms) => {
        this.props.onSetThreshold(1)
        if (clearSearchTerms) {
            this.setState({
                queryTerms: '',
                savedSearchType: '',
                savedSearchID: null,
                savedSearchName: null
            }, () => {
                this.props.onLoading(false)
                this.props.onSetActiveSearchTerms([])
            })

        }
        this.setDisplayProceedSearchToast(false)
        this.props.onSetSelectedSentence("")
        this.props.onSelectedSentenceOptionChange('all')
    }

    initSearchForm = async () => {
        const savedSearch = getSentenceAnalysisSearchFromStorage();
        if (savedSearch !== null && !savedSearch.hasOwnProperty('formContent')) {
            const { filterDefinitions } = await readFilterDefinitions();
            this.props.onLoading(true)
            const schema = await this.getRepositorySchema(savedSearch.repositoryInfo.id);
            const filterEntries = filterDefinitions['termloc']?.filterValueLabels &&
                schema?.termLocations ?
                Object.entries(filterDefinitions['termloc'].filterValueLabels)
                    .filter(([termlocID, termlocLabel]) => {
                        return schema.termLocations.includes(termlocID)
                    }).map(([termlocID, termlocLabel]) => {
                        return { filterValue: termlocID, filterValueLabel: termlocLabel }
                    }) : null;
            filterEntries.unshift({
                filterValueLabel: 'Whole document', filterValue: 'all'
            })
            let filteredEntries = filterEntries.filter(el => el.filterValue !== 'labeledcomp')
            let queryTerms = " - "
            let queryTermsAutocomplete = []
            let queryImportIDs = []
            let documents = []
            let docInfos = {}
            let sortFields = []
            let selectedSortField = null
            let selectedSortDirection = null
            let sortDirectionDisabled = false
            let docsAdditionalInfo = {}

            if (savedSearch.queryTerms && savedSearch.queryTerms.length > 0) {
                queryTerms = createQueryTermsString(savedSearch.queryTerms, this.props.domainLabelsMap, filterDefinitions)
                savedSearch.queryTerms.forEach(term => {
                    if ('domains' in term) {
                        term.id = this.generateUID(term)
                        term.color = this.getExactColor(this.generateUID(term), term?.domains, term.ocids)
                        queryTermsAutocomplete = [...queryTermsAutocomplete, term]
                    } else if (term.filterID === 'bulkImport') {
                        term.bulkIDs?.forEach(id => {
                            queryImportIDs = [...queryImportIDs, id.replace(/-/g, '').replace(/\s/g, '')]
                        })
                    }
                })
            }

            if (savedSearch.documents !== undefined && savedSearch.docInfos !== undefined) {
                savedSearch.documents.forEach(doc => {
                    documents = [...documents, { metadata: doc, repoInfo: savedSearch.repositoryInfo }]
                })
            } else if (savedSearch.query !== undefined) {
                const request = createDocumentSearchRequest(savedSearch.repositoryInfo.id, savedSearch.query.split('+groupby')[0]
                    , null, savedSearch.sortCriteria?.sortField, savedSearch.sortCriteria?.sortMode, savedSearch.sortCriteria?.sortFactor);
                const resp = await fetchMoreDocuments(request)
                let documentData = checkResultAndGetPayload(resp, this.growl);
                if (documentData && documentData?.documentCount !== -1) {
                    if (documentData?.documents && documentData?.documents?.length > 0) {
                        const ocDocIDs = [];
                        const repIDs = []
                        documentData?.documents.forEach(doc => {
                            documents = [...documents, { metadata: doc, repoInfo: savedSearch.repositoryInfo }]
                            ocDocIDs.push(doc.ocDocId);
                            repIDs.push(doc.repositoryId ? doc.repositoryId : '')
                        });
                        const resp = await fetchAdditionalDocumentsInfo(savedSearch.repositoryInfo.id, ocDocIDs, repIDs);
                        const result = checkResultAndGetPayload(resp, this.growl);
                        if (result) {
                            result.forEach(doc => {
                                docsAdditionalInfo[doc.docId] = doc;
                            });
                        }
                    }
                } else {
                    this.props.onLoading(false)
                    this.growl.current.show({
                        sticky: false, closable: true, severity: 'error',
                        summary: 'No documents', detail: 'No documents available. Maybe there was a timeout for your search.'
                    });
                }
            } else if (savedSearch.documents !== undefined && savedSearch.docInfos === undefined) {
                if (savedSearch.documents && savedSearch.documents?.length > 0) {
                    const ocDocIDs = [];
                    const repIDs = []
                    savedSearch.documents.forEach(doc => {
                        documents = [...documents, { metadata: doc, repoInfo: savedSearch.repositoryInfo }]
                        ocDocIDs.push(doc.ocDocId);
                        repIDs.push(doc.repositoryId ? doc.repositoryId : '')
                    });
                    const resp = await fetchAdditionalDocumentsInfo(savedSearch.repositoryInfo.id, ocDocIDs, repIDs);
                    const result = checkResultAndGetPayload(resp, this.growl);
                    if (result) {
                        result.forEach(doc => {
                            docsAdditionalInfo[doc.docId] = doc;
                        });
                    }
                }
            }

            if (savedSearch.docInfos !== undefined) {
                docInfos = savedSearch.docInfos
            } else {
                docInfos = docsAdditionalInfo
            }

            documents && documents.forEach(doc => {
                if (doc.metadata?.ocDocId in docInfos) {
                    doc.metadata.documentCollectionList = docInfos[doc.metadata?.ocDocId].documentCollectionList
                }
            })

            if (savedSearch.sortFields) {
                sortFields = savedSearch.sortFields.filter(field => field.value !== 'insTime' && field.value !== 'relevance' && field.value !== 'relevanceAndDate')
                if (queryImportIDs.length > 0) {
                    sortFields.unshift({ label: 'Imported IDs', value: 'import' })
                }
                sortFields.unshift({ label: 'Original order', value: 'original' })
            }
            if (savedSearch.sortCriteria) {
                if (savedSearch.sortCriteria.value !== 'insTime' && savedSearch.sortCriteria.value !== 'relevance' && savedSearch.sortCriteria.value !== 'relevanceAndDate') {
                    selectedSortField = savedSearch.sortCriteria.value
                } else {
                    sortDirectionDisabled = true
                    selectedSortField = 'original'
                }
                selectedSortDirection = savedSearch.sortCriteria?.sortMode
            }

            let selectedDocument = documents[0]
            this.setState({
                activeIndex: 0,
                docViewModalVisible: true,
                repositoryInfo: savedSearch.repositoryInfo,
                selectedDocumentsStart: documents,
                selectedDocument: selectedDocument,
                selectedDocuments: documents,
                savedSearch: savedSearch,
                savedSearchType: savedSearch.queryType,
                queryTerms: queryTerms,
                queryTermsAutocomplete: queryTermsAutocomplete,
                queryImportIDs: queryImportIDs,
                filterDefinitions: filterDefinitions,
                selectedDocumentCollectionName: undefined
                //docInfos: docInfos
            }, () => {
                this.props.onSetActiveSearchTerms(queryTermsAutocomplete)
                this.props.onSetSectionOptions(filteredEntries)
                this.props.onSetSortFields(sortFields)
                this.props.onSetSelectedSortField(selectedSortField)
                this.props.onSetSelectedSortDirection(selectedSortDirection)
                this.props.onSetSortDirectionDisabled(sortDirectionDisabled)
                this.props.onSetSelectedSections({ filterValueLabel: 'Whole document', filterValue: 'all' })
                this.clearAll(false)
            })
        } else {
        }
    }

    getRepositorySchema = async (repositoryID) => {
        // --- request repository schema, e.g. sortable fields, available facets, etc. --- //
        let schema;
        const response2 = await fetchSortableFields(repositoryID);
        schema = checkResultAndGetPayload(response2, this.growl);
        // --- remove facets that are not active --- //
        if (schema?.facets && this.state.activeFacets) {
            const activeRepoFacets = schema.facets.filter(facID => this.state.activeFacets[facID]);
            schema.facets = activeRepoFacets;
        }
        return schema;
    }

    openSavedSearch = () => {
        this.setState({
            displaySavedSearchesDialog: true
        })
    }

    openLibrary = () => {
        this.setState({
            loadCollection: true,
            displayLibraryDialog: true
        })
    }

    onCloseLibraryDialog = () => {
        this.setState({
            displayLibraryDialog: false
        })
    }

    getDocumentsFromCollection = async (collectionID) => {

        const response = await fetchDocumentsFromCollection(collectionID);
        const result = checkResultAndGetPayload(response, this.growl);
        let collectionDocuments = [];
        if (result && result.content) {
            collectionDocuments = result.content
        }

        return collectionDocuments;
    }

    getDocumentsMetadata = async (documents) => {

        const response = await fetchDocumentsMetadata(documents);
        const result = checkResultAndGetPayload(response, this.growl);
        //console.log(result)
        let documentsMetadata = [];
        if (result && result.hitDocuments) {
            documentsMetadata = result.hitDocuments
        } else {
            let msg = { severity: 'error', summary: 'Failed!', detail: "Document collection could not be imported.", life: 6000 };
            this.growl.current.show(msg)
        }

        return documentsMetadata;
    }

    onSubmitLibrary = async (collection) => {
        if (this.state.loadCollection) {
            const collectionDocuments = await this.getDocumentsFromCollection(collection.id)
            let collectionDocumentsContent = []
            collectionDocuments.forEach(doc => {
                collectionDocumentsContent = [...collectionDocumentsContent, { documentIdentifier: doc.document.docId, repositoryIdentifier: doc.document.repositoryId }]
            })
            //console.log(collectionDocumentsContent)
            const documentsWithMetadata = await this.getDocumentsMetadata(collectionDocumentsContent)
            let documentsWithMetadataAndRepo = []
            documentsWithMetadata.forEach(doc => {
                let repoInfo = this.props.userData.userDetails.department.selectedRepositories.find(rep => rep.id === doc.repositoryId)
                let documentCollections = collectionDocuments.find(colDoc => colDoc.document.docId === doc.ocDocId)
                documentsWithMetadataAndRepo = [...documentsWithMetadataAndRepo, { metadata: { ...doc, ...doc.docMetadata, documentCollectionList: documentCollections.document.documentCollectionList }, repoInfo: repoInfo }]
            })
            this.setState({
                activeIndex: 0,
                savedSearch: null,
                displayLibraryDialog: false,
                selectedDocuments: documentsWithMetadataAndRepo,
                selectedDocument: documentsWithMetadataAndRepo[0],
                docViewModalVisible: true,
                selectedDocumentCollectionName: collection.name,
                selectedDocumentCollectionNumberDocuments: collection.numberDocuments,
                queryChanged: false,
                repositoryInfo: null
            }, () => {
                this.props.onSetActiveSearchTerms(this.state.queryTermsAutocomplete)
                this.props.onLoading(true)
                this.props.onSetSelectedSentence("")
                this.props.onSelectedSentenceOptionChange('all')
                this.props.onSetThreshold(1)
                this.props.onSetSelectedSortField('original')
                this.props.onSetSelectedSortDirection('descending')
                this.props.onSetSortDirectionDisabled(true)
                this.props.onSetSelectedSections({ filterValueLabel: 'Whole document', filterValue: 'all' })
                if (this.state.selectedDocuments?.length > 0 && this.state.queryTermsAutocomplete?.length > 0) {
                    this.onAnalyze(false)
                }
            })
        } else {
            const docs = [];
            if (!!this.props.documents && this.props.documents.length > 0) {
                this.props.documents.forEach(doc => {
                    docs.push({
                        docId: doc.docId,
                        repositoryId: doc.repository,
                        repository: doc.repository,
                        title: doc.title
                    });
                });
            } else {
                this.growl.current.show({
                    sticky: false, closable: true, severity: 'error',
                    summary: 'Missing data', detail: 'No document selected.'
                });
            }

            let ids = [];
            collection && collection.forEach(col => {
                ids.push(col.id);
            });
            if (docs.length > 0 && ids.length > 0) {
                const response = await addDocumentsToCollections(ids, docs);
                checkResultAndGetPayload(response, this.growl, 'Success!',
                    `${docs.length === 1 ? 'Document' : 'Documents'} successfully added to ${ids.length === 1
                        ? 'collection.' : 'collections.'}`);
                //console.log(docs)
                docs.forEach(doc => {
                    this.updateAdditionalDocumentsInfo([doc], doc.repositoryId);
                })
            }
            this.setState({
                displayLibraryDialog: false
            });
        }
    }

    activeIndexChange = (index) => {
        const { selectedDocuments } = this.state;
        if (index !== null && index < selectedDocuments.length) {
            this.setState({
                activeIndex: index,
                selectedDocument: this.state.selectedDocuments[index]
            }, () => {
                if (this.state.queryTermsAutocomplete.length > 0) {
                    this.props.onLoading(true)
                    this.props.onSetSelectedSentence("")
                }
                this.setAbortLoadingClicked(false);
                this.setDisplayProceedSearchToast(false);
            })
        } else if (index === null) {
            this.setState({
                activeIndex: index
            })
            this.setAbortLoadingClicked(false);
            this.setDisplayProceedSearchToast(false);
        }
    }

    setAbortLoadingClicked = (abortLoadingClicked) => {
        if (abortLoadingClicked) {
            this.setDisplayProceedSearchToast(true);
        }
        this.setState({
            abortLoadingClicked: abortLoadingClicked
        })
    }

    setDisplayProceedSearchToast = (displayProceedSearchToast) => {
        this.setState({
            displayProceedSearchToast: displayProceedSearchToast,
        })
    }

    setImportantTermsNumber = (importantTermsNumber) => {
        this.setState({
            importantTermsNumber: importantTermsNumber,
        })
    }

    activeIndexIncreaseChange = (indexIncrease) => {
        this.setState({
            activeIndexIncrease: indexIncrease
        })
    }

    thresholdChange = (threshold, withLoading = true) => {
        this.props.onSetThreshold(threshold)
        if (withLoading) {
            this.props.onSetSelectedSentence("")
            this.props.onLoading(true)
        }
    }

    highlightOptionChange = (option) => {
        if (option === true) {
            this.props.onSetShowSentenceEntities(true)
        } else {
            this.props.onSetShowSentenceEntities(false)
        }
    }

    onDocumentSelectionChange = (e, docId, repoId, title) => {
        let newArray = []
        if (e.checked) {
            newArray = [...this.props.documents, { docId: docId, repository: repoId, repositoryId: repoId, title: title }]
            this.props.onSetSelectedDocuments(newArray)
        } else {
            newArray = this.props.documents.filter(document => document.docId !== docId)
            this.props.onSetSelectedDocuments(newArray)
        }
        if (newArray.length === 0) {
            this.setState({
                displayCustomToast: false
            }, () => {
                //this.toastBC.current.clear();
            })

        } else if (newArray.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
    }

    onSelectSavedSearch = (savedSearch) => {
        console.log(savedSearch)
    }

    onAnalyze = (noLoading) => {
        //console.log("start")
        this.setState({
            queryChanged: false,
            //searchLoaded: false
        }, async () => {
            if (!noLoading) {
                this.setAbortLoadingClicked(false)
                await this.props.onSetActiveSearchTerms([])
                await this.props.onLoading(true)
                await this.props.onSetActiveSearchTerms(this.state.queryTermsAutocomplete)
            }
            //if (!noLoading) {
            await this.props.onSetSelectedSentence("")
            //}
            if (this.state.queryTermsAutocomplete.length <= 1) {
                await this.props.onSetThreshold(1)
                await this.props.onSelectedSentenceOptionChange('all')
            }
            this.activeIndexIncreaseChange(undefined);
            let importantTermsNumber = this.state.queryTermsAutocomplete.filter(term => term.important === true).length
            this.setImportantTermsNumber(importantTermsNumber);
            if (this.state.importantTermsNumber > 0) {
                await this.props.onSetThreshold(importantTermsNumber);
                await this.props.onSelectedSentenceOptionChange('threshold');
            }
        })
    }

    onPageChange() {
        //console.log("test")
    }

    darkenColor = (color, percent) => {
        const hex = color.replace('/^#/', '');
        const num = parseInt(hex, 16);
        const amt = Math.round(2.55 * percent);

        const r = (num >> 16) & 255;
        const g = (num >> 8) & 255;
        const b = num & 255;

        const newR = Math.max(0, r - amt);
        const newG = Math.max(0, g - amt);
        const newB = Math.max(0, b - amt);

        return `${(newR << 16 | newG << 8 | newB).toString(16).padStart(6, '0')}`;
    }

    /**
   * MBshaban change highligt color
   * @param {*} isDomain 
   * @returns 
   */

    changeOcidAnnotationColor = async (id, color, concept, colorForUnderlining) => {
        let newActiveSearchTerms = this.state.queryTermsAutocomplete?.map((item) => {
            if (item?.id === concept.id) {
                const newItem = { ...item, color: color, id: id };
                if (concept.regex) {
                    newItem.colorForUnderlining = this.darkenColor(color, 20);
                    newItem.label = newItem.regex
                }
                return newItem;
            } else {
                return item;
            }
        })
        this.setState({
            queryTermsAutocomplete: newActiveSearchTerms,
            queryChanged: true
        }/*, async () => {
            await this.props.onLoading(true)
            await this.props.onSetActiveSearchTerms(newActiveSearchTerms)
        }*/)
    }


    onSpecifyQueryTerm = async (queryTerm) => {
        this.setState({
            queryTermToReplace: queryTerm,
            domainExplorerQuery: isArrayEmpty(queryTerm?.ocids) && queryTerm?.term,
            domainExplorerOcids: !isArrayEmpty(queryTerm?.ocids) && queryTerm.ocids,
        }, () => {
            //sleep(1000); // test
            this.setState({
                domainBrowserVisible: true
            }, () => {
                this.props.onSetSelectedSentence("")
            })
        });
    }

    onDomainExplorerClose = () => {
        this.setState({ domainBrowserVisible: false });
    }

    onDomainExplorerSubmit = (concepts) => {
        if (this.state.queryTermToReplace) {
            const queryTerms = replaceQueryTermWithConcepts(concepts, this.state.queryTermsAutocomplete, this.state.queryTermToReplace, true, this.getDomainColorTopPeriority());
            queryTerms.forEach(term => {
                term.label = term.term
            })
            this.setState({
                queryTermsAutocomplete: queryTerms,
                queryTermToReplace: null,
                domainBrowserVisible: false,
                queryChanged: true
            }/*, async () => {
                await this.props.onLoading(true)
                await this.props.onSetActiveSearchTerms(queryTerms)
                await this.props.onSetSelectedSentence("")
            }*/
            )
        } else {
            const queryTerms = addConceptsToQueryTerms(concepts, this.state.queryTermsAutocomplete, true, true, this.getDomainColorTopPeriority());
            queryTerms.forEach(term => {
                term.label = term.term
            })
            this.setState({
                queryTermsAutocomplete: queryTerms,
                queryTermToReplace: null,
                domainBrowserVisible: false,
                queryChanged: true
            });
        }
    }

    getDomainColorTopPeriority = () => {
        return _.orderBy(this.props.domains, ['orderPriority'], 'desc')
    }

    clearSemanticSearch = () => {
        this.props.onLoading(true)
        this.setState({
            queryTermsAutocomplete: [],
            queryChanged: true
        }, () => {
            //this.props.onSetActiveSearchTerms([])
            this.clearAll(true)
        })
    }

    onAddTerm = (value, id) => {
        const newTerm = value;
        let oldQueryTerms = this.state.queryTermsAutocomplete
        newTerm.id = id
        newTerm.color = newTerm.ocids ? (this.getExactColor(id, newTerm?.domains, newTerm.ocids)) : (this.getExactColor(id));
        if (newTerm.regex) {
            newTerm.colorForUnderlining = "a7a7a7";
            newTerm.label = newTerm.regex
        }
        const newQueryTerms = [...oldQueryTerms, newTerm]
        this.setState({
            queryTermsAutocomplete: newQueryTerms,
            queryChanged: true
        },/* async () => {
            await this.props.onSetActiveSearchTerms(newQueryTerms)
            await this.props.onLoading(true)
            await this.props.onSetActiveSearchTerms(newQueryTerms)
            await this.props.onSetSelectedSentence("")
            
        }*/
        )
    }

    editTermTerm = (newTerm, previousId) => {
        //console.log(newTerm)
        let oldQueryTerms = this.state.queryTermsAutocomplete
        let newQueryTerms = oldQueryTerms.map((term) => {
            if (term.id === previousId) {
                return { ...newTerm, id: this.generateUID(newTerm), color: this.getExactColor(previousId), label: newTerm.regex, colorForUnderlining: this.darkenColor(this.getExactColor(previousId), 20) };
            }
            return term;
        })
        this.setState({
            queryTermsAutocomplete: newQueryTerms,
            queryChanged: true
        });
    }

    editTermImportant = (newTerm) => {
        let oldQueryTerms = this.state.queryTermsAutocomplete;
        let newQueryTerms = oldQueryTerms.map((term) => {
            if (term.id === newTerm.id) {
                return { ...newTerm, important: !term.important };
            }
            return term;
        })
        this.setState({
            queryTermsAutocomplete: newQueryTerms,
            queryChanged: true
        });
    }

    removeTerm = (item) => {
        const uniqeId = this.generateUID(item)
        let oldQueryTerms = this.state.queryTermsAutocomplete
        const newQueryTerms = oldQueryTerms.filter((item) => (item.id !== uniqeId))
        this.setState({
            queryTermsAutocomplete: newQueryTerms,
            queryChanged: true
        },/*async () => {
            await this.props.onSetActiveSearchTerms(newQueryTerms)
            await this.props.onLoading(true)
            await this.props.onSetActiveSearchTerms(newQueryTerms)
            await this.props.onSetSelectedSentence("")
            if (newQueryTerms.length <= 1) {
                await this.props.onSetThreshold(1)
                await this.props.onSelectedSentenceOptionChange('all')
            }        
        }*/
        )
    }
    openDomainExplorer = () => {
        this.setState({
            domainBrowserVisible: true,
            queryTermToReplace: null,
            domainExplorerQuery: null,
            domainExplorerOcids: null
        })
    }

    generateUID = (term) => {
        return `q${term.term.trim().split(' ').join('') + (term.ocids ? term.ocids?.length : term.domains[0]) + term.domains?.length}`
    }

    getExactColor = (id, domain, ocids) => {
        //console.log(id)
        //console.log(domain)
        //console.log(ocids)
        let temp = this.state.queryTermsAutocomplete?.find((term) => term?.id === id)
        //console.log(temp)
        if (temp) {
            //console.log("if")
            return temp?.color
        } else {
            //console.log("else")
            if (ocids) {
                //console.log("if ocids")
                const tempDomain = this.getHighPiriortyDomain(domain, ocids)
                return tempDomain.length > 0 ? tempDomain[0]?.color : 'dee2e6'
            } else {
                //console.log("else ocids")
                return 'dee2e6'
            }
        }
    }

    getHighPiriortyDomain = (domain, ocids = null) => {
        if (!ocids && FREE_TEXT_DOMAINS.includes(domain[0])) {
            return domain.map((item) => {
                return {
                    name: DOMAIN_ALIASES[item]
                }
            })
        }
        const domains = this.getDomainColorTopPeriority()
        return domains.filter((dom) => domain.some((item) => item === dom.name));
    }

    onAddToCollections = async (doc, collections) => {
        const docs = [];
        if (!!doc && !!collections && collections.length > 0) {
            docs.push({
                docId: doc.ocDocId,
                repository: doc.repository,
                repositoryId: doc.repositoryId,
                title: doc.title
            });

            let ids = [];
            collections && collections.forEach(coll => {
                ids.push(coll.id);
            });

            const response = await addDocumentsToCollections(ids, docs);
            checkResultAndGetPayload(response, this.growl);

            this.updateAdditionalDocumentsInfo(docs, doc.repositoryId);
            this.refreshDocumentCollections()
        } else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'Either no documents or collections were selected.'
            });
        }
    }

    onRemoveFromCollections = async (doc, collections) => {
        const docs = [];
        if (!!doc && !!collections && collections.length > 0) {
            docs.push({
                docId: doc.ocDocId,
                repository: doc.repositoryId,
            });

            for (var coll of collections) {
                const response = await removeDocumentsFromDocumentCollection(coll.id, coll.documentLink);
                checkResultAndGetPayload(response, this.growl);
            }

            this.updateAdditionalDocumentsInfo(docs, doc.repositoryId);
            this.refreshDocumentCollections()
        }
        else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'Either no documents or collections were selected.'
            });
        }
    }

    /**
     * Fetches additional document info, such as read status,
     * rating, collections for list of documents and
     * updates state with new data.
     */
    updateAdditionalDocumentsInfo = async (documents, repID) => {
        //console.log("update fetch", documents)
        const docsAdditionalInfo = { ...this.state.docInfos };
        if (documents) {
            const ocDocIDs = [];
            const repIDs = []
            documents.forEach(doc => {
                //console.log('doc: ', doc);
                //console.log('doc.ocDocId: ', doc.ocDocId); // undef???
                //console.log('doc.docId: ', doc.docId);
                const docId = doc.ocDocId ? doc.ocDocId : doc.docId;
                ocDocIDs.push(docId);
                repIDs.push(doc.repositoryId ? doc.repositoryId : repID)
            });
            const resp = await fetchAdditionalDocumentsInfo(repID, ocDocIDs, repIDs);
            const result = checkResultAndGetPayload(resp, this.growl);

            if (result) {
                result.forEach(doc => {
                    docsAdditionalInfo[doc.docId] = doc;
                });
            }
        }

        const newDocuments = [...this.state.selectedDocuments]

        newDocuments && newDocuments.forEach(doc => {
            if (doc.metadata?.ocDocId in docsAdditionalInfo) {
                doc.metadata.documentCollectionList = docsAdditionalInfo[doc.metadata?.ocDocId].documentCollectionList
            }
        })

        this.setState({
            selectedDocuments: newDocuments
        });
    }

    onNextDocumentClick = () => {
        const newActiveIndex = this.state.activeIndex + 1
        this.activeIndexIncreaseChange(true);
        this.activeIndexChange(newActiveIndex);
    }

    rejectSelectedDocuments = () => {
        this.setState({
            displayCustomToast: false,
        }, () => {
            this.clearDocumentSelection()
        })
    }

    onOpenAddToCollectionsDialog = () => {
        this.setState({
            loadCollection: false,
            displayLibraryDialog: true
        });
    }

    onRemoveElements = () => {
        this.setState({
            displayRemoveConfirmDialog: true
        })
    }

    removeSelectedDocuments = () => {
        let newDocuments = JSON.parse(JSON.stringify(this.state.selectedDocuments))
        let newIndex = 0
        this.props.documents.forEach(doc => {
            let index = newDocuments.findIndex(el => el.metadata.ocDocId === doc.docId)
            newDocuments.splice(index, 1)
        })
        newIndex = newDocuments.findIndex(el => el.metadata.ocDocId === this.state.selectedDocument.metadata.ocDocId)
        if (newIndex === -1 && this.state.activeIndex !== null && (this.state.activeIndex <= (newDocuments.length - 1))) {
            newIndex = this.state.activeIndex
        } else if (newIndex === -1 && this.state.activeIndex !== null && (this.state.activeIndex > (newDocuments.length - 1))) {
            newIndex = newDocuments.length - 1
        } else if (newIndex === -1 && this.state.activeIndex === null) {
            newIndex = 0
        }
        this.setState({
            displayRemoveConfirmDialog: false,
            selectedDocuments: newDocuments,
            displayCustomToast: false
        }, () => {
            this.activeIndexChange(newIndex)
            this.clearDocumentSelection()
            this.growl.current.show({
                sticky: false, closable: true, severity: 'success',
                summary: 'Documents removed', detail: 'Selected documents were removed successfully.'
            });
        })
    }

    onRemoveFamilyMembers = (documents) => {
        let newDocuments = JSON.parse(JSON.stringify(this.state.selectedDocuments))
        let newIndex = 0
        this.props.familyDocumentsRemove.forEach(doc => {
            let index = newDocuments.findIndex(el => el.metadata.ocDocId === doc.metadata.ocDocId)
            newDocuments.splice(index, 1)
        })
        newIndex = newDocuments.findIndex(el => el.metadata.ocDocId === this.state.selectedDocument.metadata.ocDocId)
        if (newIndex === -1 && this.state.activeIndex !== null && (this.state.activeIndex <= (newDocuments.length - 1))) {
            newIndex = this.state.activeIndex
        } else if (newIndex === -1 && this.state.activeIndex !== null && (this.state.activeIndex > (newDocuments.length - 1))) {
            newIndex = newDocuments.length - 1
        } else if (newIndex === -1 && this.state.activeIndex === null) {
            newIndex = 0
        }
        this.setState({
            showFamilyMembersDialog: false,
            selectedDocuments: newDocuments
        }, () => {
            this.activeIndexChange(newIndex)
            this.props.onSetSelectedFamilyDocuments([])
            this.props.onSetSelectedFamilyDocumentsRemove([])
            this.growl.current.show({
                sticky: false, closable: true, severity: 'success',
                summary: 'Documents removed', detail: 'Selected documents were removed successfully.'
            });
        })
    }

    onAddToReadcube = () => {
        this.setState({
            displayAddReadcubeDialog: true
        })
    }

    clearDocumentSelection = () => {
        this.props.onSetSelectedDocuments([]);
    }

    handleMetadataChange = (metadataOption) => {
        this.props.onSetSelectedMetadataOption(metadataOption)
    }

    handleSectionChange = (section) => {
        if (this.props.sectionOptions && this.props.sectionOptions.filter(opt => opt.filterValue === section.filterValue).length > 0) {
            this.props.onSetSelectedSections(section)
        } else {
            this.props.onSetSelectedSections({ filterValueLabel: 'Whole document', filterValue: 'all' })
        }
    }

    handleSortFieldChange = (e) => {
        let sortedDocuments = []
        let sortDirectionDisabled = false
        let oldSelectedDocument = JSON.parse(JSON.stringify(this.state.selectedDocument))
        if (e.value !== 'original' && METADATA_SORT_FIELDS.hasOwnProperty(e.value)) {
            sortedDocuments = JSON.parse(JSON.stringify(this.state.selectedDocuments))
            if (METADATA_SORT_FIELDS[e.value].type === 'string') {
                if (this.props.selectedSortDirection === 'ascending') {
                    sortedDocuments = sortedDocuments.sort((a, b) => a.metadata[METADATA_SORT_FIELDS[e.value].metadataName].localeCompare(b.metadata[METADATA_SORT_FIELDS[e.value].metadataName]))
                } else {
                    sortedDocuments = sortedDocuments.sort((a, b) => b.metadata[METADATA_SORT_FIELDS[e.value].metadataName].localeCompare(a.metadata[METADATA_SORT_FIELDS[e.value].metadataName]))
                }
            } else if (METADATA_SORT_FIELDS[e.value].type === 'date') {
                if (this.props.selectedSortDirection === 'descending') {
                    sortedDocuments = sortedDocuments.sort((a, b) => new Date(b.metadata[METADATA_SORT_FIELDS[e.value].metadataName]) - new Date(a.metadata[METADATA_SORT_FIELDS[e.value].metadataName]))
                } else {
                    sortedDocuments = sortedDocuments.sort((a, b) => new Date(a.metadata[METADATA_SORT_FIELDS[e.value].metadataName]) - new Date(b.metadata[METADATA_SORT_FIELDS[e.value].metadataName]))
                }
            }
        } else if (e.value === 'import') {
            sortedDocuments = JSON.parse(JSON.stringify(this.state.selectedDocuments))
            let uniqueIDs = [...new Set(this.state.queryImportIDs)]
            //console.log(uniqueIDs)
            let sortedByIDs = []
            for (let id of uniqueIDs) {
                for (let doc of sortedDocuments) {
                    let identifier = doc?.metadata.hasOwnProperty('pat_document-id') ? 'pat_document-id' : 'Pat_document-id'
                    if (uniqueIDs.indexOf(doc?.metadata[identifier]?.replace(/-/g, '')) !== -1) {
                        if (doc?.metadata[identifier].replace(/-/g, '') === id) {
                            sortedByIDs = [...sortedByIDs, doc]
                            break
                        }
                    }
                }
            }

            if (sortedByIDs.length !== this.state.selectedDocuments) {
                for (let doc of sortedDocuments) {
                    let identifier = doc?.metadata.hasOwnProperty('pat_document-id') ? 'pat_document-id' : 'Pat_document-id'
                    if (uniqueIDs.indexOf(doc?.metadata[identifier]?.replace(/-/g, '')) === -1) {
                        sortedByIDs.push(doc)
                    }
                }
            }

            sortedDocuments = sortedByIDs
            sortDirectionDisabled = true
        }
        else {
            sortedDocuments = JSON.parse(JSON.stringify(this.state.selectedDocumentsStart))
            sortDirectionDisabled = true
        }
        this.setState({
            selectedDocuments: sortedDocuments,
            selectedDocument: sortedDocuments[0]
        }, () => {
            this.props.onSetSelectedSentence("")
            this.props.onSetSelectedSortField(e.value)
            this.props.onSetSortDirectionDisabled(sortDirectionDisabled)
            if (sortedDocuments[0].metadata.ocDocId !== oldSelectedDocument.metadata.ocDocId) {
                this.props.onLoading(true)
            }
        })
    }

    handleSortDirectionChange = (e) => {
        let sortedDocuments = JSON.parse(JSON.stringify(this.state.selectedDocuments))
        let oldSelectedDocument = JSON.parse(JSON.stringify(this.state.selectedDocument))
        if (METADATA_SORT_FIELDS[this.props.selectedSortField].type === 'string') {
            if (e.value === 'ascending') {
                sortedDocuments = sortedDocuments.sort((a, b) => a.metadata[METADATA_SORT_FIELDS[this.props.selectedSortField].metadataName].localeCompare(b.metadata[METADATA_SORT_FIELDS[this.props.selectedSortField].metadataName]))
            } else {
                sortedDocuments = sortedDocuments.sort((a, b) => b.metadata[METADATA_SORT_FIELDS[this.props.selectedSortField].metadataName].localeCompare(a.metadata[METADATA_SORT_FIELDS[this.props.selectedSortField].metadataName]))
            }
        } else if (METADATA_SORT_FIELDS[this.props.selectedSortField].type === 'date') {
            if (e.value === 'descending') {
                sortedDocuments = sortedDocuments.sort((a, b) => new Date(b.metadata[METADATA_SORT_FIELDS[this.props.selectedSortField].metadataName]) - new Date(a.metadata[METADATA_SORT_FIELDS[this.props.selectedSortField].metadataName]))
            } else {
                sortedDocuments = sortedDocuments.sort((a, b) => new Date(a.metadata[METADATA_SORT_FIELDS[this.props.selectedSortField].metadataName]) - new Date(b.metadata[METADATA_SORT_FIELDS[this.props.selectedSortField].metadataName]))
            }
        }

        this.setState({
            selectedDocuments: sortedDocuments,
            selectedDocument: sortedDocuments[0]
        }, () => {
            this.props.onSetSelectedSentence("")
            if (sortedDocuments[0].metadata.ocDocId !== oldSelectedDocument.metadata.ocDocId) {
                this.props.onLoading(true)
            }
            this.props.onSetSelectedSortDirection(e.value)
        })
    }

    onShowFamilyMembers = async (e, metadata) => {
        let patFamilyMembers = [];
        const hitStartPos = 0;
        const hitCount = 100;
        const withDocMetaData = true;
        const withImageCatalog2 = false;
        const withQryAbstract = false;
        const withTeaserImage = false;
        for (const patFam of Object.values(PATENT_FAMILIES)) {
            if (metadata[patFam.metadataName]) {
                const patFamID = !isArrayEmpty(metadata[patFam.metadataName]) ? metadata[patFam.metadataName][0] : '-1';
                if (patFamID !== '-1') {
                    const query = patFam.membersQryPrefix + ':' + metadata[patFam.metadataName][0];
                    const request = createDocumentSearchRequestV1(metadata.repositoryId, query, hitStartPos, hitCount,
                        withDocMetaData, withImageCatalog2, withQryAbstract, withTeaserImage);
                    const resp = await fetchDocumentsV1(request, true);
                    const familyRes = checkResultAndGetPayload(resp, this.growl);
                    if (familyRes) {
                        patFamilyMembers.push({ label: patFam.label, members: familyRes.hitDocuments });
                    }
                }
            }
        }
        this.setState({
            patFamilyMembers: patFamilyMembers,
            showFamilyMembersDialog: true
        })
    }

    onCloseFamilyMembersDialog = () => {
        this.setState({
            showFamilyMembersDialog: false,
        }, () => {
            this.props.onSetSelectedFamilyDocuments([])
            this.props.onSetSelectedFamilyDocumentsRemove([])
        })
    }

    handleSelectionChange = (e, rowData, selectedPatent, remove) => {
        if (!remove) {
            let newSelectedDocuments = JSON.parse(JSON.stringify(this.props.familyDocuments))
            if (e.checked) {
                newSelectedDocuments.push({ metadata: { ...rowData.docMetadata, title: rowData.title, ocDocId: rowData.ocDocId, repositoryId: rowData.repositoryId, repository: rowData.repositoryId, 'pat_document-id': rowData.docMetadata['Pat_document-id'] }, repoInfo: { id: rowData.repositoryId } });
            } else {
                let index = newSelectedDocuments.findIndex(el => (el.metadata.hasOwnProperty('pat_document-id') && el.metadata['pat_document-id'] === selectedPatent) || (el.metadata.hasOwnProperty('Pat_document-id') && el.metadata['Pat_document-id'] === selectedPatent))
                newSelectedDocuments.splice(index, 1);
            }
            this.props.onSetSelectedFamilyDocuments(newSelectedDocuments)
        } else {
            let newSelectedDocumentsRemove = JSON.parse(JSON.stringify(this.props.familyDocumentsRemove))
            if (e.checked) {
                newSelectedDocumentsRemove.push({ metadata: { ...rowData.docMetadata, title: rowData.title, ocDocId: rowData.ocDocId, repositoryId: rowData.repositoryId, repository: rowData.repositoryId, 'pat_document-id': rowData.docMetadata['Pat_document-id'] }, repoInfo: { id: rowData.repositoryId } });
            } else {
                let index = newSelectedDocumentsRemove.findIndex(el => (el.metadata.hasOwnProperty('pat_document-id') && el.metadata['pat_document-id'] === selectedPatent) || (el.metadata.hasOwnProperty('Pat_document-id') && el.metadata['Pat_document-id'] === selectedPatent))
                newSelectedDocumentsRemove.splice(index, 1);
            }
            this.props.onSetSelectedFamilyDocumentsRemove(newSelectedDocumentsRemove)
        }
    }

    onAddFamilyMembers = async (documents, selectedPatent) => {
        let index = 0
        let docsAdditionalInfo = {}
        let newDocuments = JSON.parse(JSON.stringify(this.state.selectedDocuments))

        if (documents && documents?.length > 0) {
            const ocDocIDs = [];
            const repIDs = []
            documents.forEach(doc => {
                ocDocIDs.push(doc.metadata?.ocDocId);
                repIDs.push(doc.metadata?.repositoryId ? doc.metadata?.repositoryId : '')
            });
            const resp = await fetchAdditionalDocumentsInfo(this.state.repositoryInfo ? this.state.repositoryInfo.id : repIDs[0], ocDocIDs, repIDs);
            const result = checkResultAndGetPayload(resp, this.growl);
            if (result) {
                result.forEach(doc => {
                    docsAdditionalInfo[doc.docId] = doc;
                });
            }
        }

        documents && documents.forEach(doc => {
            if (doc.metadata?.ocDocId in docsAdditionalInfo) {
                doc.metadata.documentCollectionList = docsAdditionalInfo[doc.metadata?.ocDocId].documentCollectionList
            }
        })

        index = this.state.selectedDocuments.findIndex(el => (el.metadata.hasOwnProperty('pat_document-id') && el.metadata['pat_document-id'] === selectedPatent) || (el.metadata.hasOwnProperty('Pat_document-id') && el.metadata['Pat_document-id'] === selectedPatent))
        for (let i = 1; i <= documents.length; i++) {
            newDocuments.splice(index + i, 0, documents[i - 1])
        }

        this.setState({
            showFamilyMembersDialog: false,
            selectedDocuments: newDocuments
        }, () => {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'success',
                summary: 'Documents added', detail: 'Selected documents were added successfully.'
            });
            this.props.onSetSelectedFamilyDocuments([])
            this.props.onSetSelectedFamilyDocumentsRemove([])
        })
    }

    selectedSentenceOptionChange = (selectedOption, withLoading = true) => {
        this.props.onSelectedSentenceOptionChange(selectedOption)
        if (withLoading) {
            if (this.props.threshold !== 1) {
                this.props.onLoading(true)
                this.props.onSetSelectedSentence("")
            }
        }
    }

    onSave = async (editQuery) => {
        //console.log(editQuery)
        this.setState({
            displaySaveDialog: true,
            queryString: undefined,
            editQuery: editQuery
        })
    }

    closeSaveDialog = () => {
        this.setState({
            displaySaveDialog: false
        })
    }

    onSaveSearch = async (queryString, queryName, queryFullName, queryDescription, watchlistIDs, saveOption) => {
        console.log(this.state)

        let queryStringDocuments = "+("
        this.state.selectedDocuments.forEach(doc => {
            queryStringDocuments += `docid:\"${doc.metadata.ocDocId}\"` + " "
        })
        queryStringDocuments = queryStringDocuments.slice(0, -1)
        queryStringDocuments += ")"
        let formContent = {
            queryTermsAutocomplete: this.state.queryTermsAutocomplete,
            selectedSentenceOption: this.props.sentenceOption,
            threshold: this.props.threshold,
            showSentenceEntities: this.props.showSentenceEntities,
            selectedMetadataOption: this.props.selectedMetadataOption,
            selectedSections: this.props.selectedSections,
            repositoryInfo: this.state.repositoryInfo,
            queryStringDocuments: queryStringDocuments,
            sortFields: this.props.sortFields,
            selectedDocumentID: this.state.selectedDocument.metadata.ocDocId,
            selectedSentence: this.props.selectedSentence,
            queryString: queryString
        };
        if (saveOption.value === 'terms') {
            delete formContent.selectedSentenceOption
            delete formContent.threshold
            delete formContent.showSentenceEntities
            delete formContent.selectedMetadataOption
            delete formContent.selectedSections
            delete formContent.queryStringDocuments
            delete formContent.selectedDocumentID
            delete formContent.selectedSentence
            delete formContent.repositoryInfo
        } else if (saveOption.value === 'searchSettings') {
            delete formContent.selectedMetadataOption
            delete formContent.selectedSections
            delete formContent.queryStringDocuments
            delete formContent.selectedDocumentID
            delete formContent.selectedSentence
            delete formContent.repositoryInfo
        } else if (saveOption.value === 'documents') {
            delete formContent.queryTermsAutocomplete
            delete formContent.selectedSentenceOption
            delete formContent.threshold
            delete formContent.showSentenceEntities
            delete formContent.selectedMetadataOption
            delete formContent.selectedSections
            delete formContent.selectedDocumentID
            delete formContent.selectedSentence
        } else if (saveOption.value === 'docSettings') {
            delete formContent.queryTermsAutocomplete
            delete formContent.selectedSentenceOption
            delete formContent.threshold
            delete formContent.showSentenceEntities
        }

        let response;
        let queryID;
        // --- existing query is updated --- //
        if (this.state.editQuery) {
            response = await updateQuery(this.state.savedSearchID,
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_SENTENCE_ANALYSIS_SEARCH.id,
                QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id, "sentence", formContent, watchlistIDs);
            queryID = this.state.savedSearchID;
        }
        // --- new query is stored --- //
        else {
            response = await storeQuery(
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_SENTENCE_ANALYSIS_SEARCH.id,
                QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id, "sentence", formContent, watchlistIDs);
            queryID = response?.payload?.id;
        }


        const result = checkResultAndGetPayload(response, this.growl, 'Success',
            'Search successfully stored.');
        if (response.status === "SUCCESS") {
            this.setState({
                queryNameEdit: queryName,
                queryDescriptionEdit: queryDescription,
                queryFullNameEdit: queryFullName,
                displaySaveDialog: false,
                selectedQueryCollectionIDs: watchlistIDs,
                savedSearchID: queryID,
                savedSearchName: queryFullName,
                editableQuery: true
            })
        }
    }

    onLoadSearch = async (search) => {
        let loadedSearch = search
        let formContent = JSON.parse(loadedSearch.formContent)
        this.props.onLoading(true)
        this.setState({
            displayLoadDialog: false
        })

        let filteredEntries = []
        if (formContent.repositoryInfo) {
            const { filterDefinitions } = await readFilterDefinitions();
            const schema = await this.getRepositorySchema(formContent.repositoryInfo.id);
            filteredEntries = filterDefinitions['termloc']?.filterValueLabels &&
                schema?.termLocations ?
                Object.entries(filterDefinitions['termloc'].filterValueLabels)
                    .filter(([termlocID, termlocLabel]) => {
                        return schema.termLocations.includes(termlocID)
                    }).map(([termlocID, termlocLabel]) => {
                        return { filterValue: termlocID, filterValueLabel: termlocLabel }
                    }) : null;
            filteredEntries.unshift({
                filterValueLabel: 'Whole document', filterValue: 'all'
            })
            filteredEntries = filteredEntries.filter(el => el.filterValue !== 'labeledcomp')

            /*if (formContent.queryTermsAutocomplete) {
                createQueryTermsString(formContent.queryTermsAutocomplete, this.props.domainLabelsMap, filterDefinitions)
            }*/
        }
        let repositoryInfo = formContent.repositoryInfo ? formContent.repositoryInfo : null
        let loadedDocuments = []
        let docsAdditionalInfo = {}
        let orderedDocIds = []

        let editable = true

        if (loadedSearch.queryCollectionList && loadedSearch.queryCollectionList.length > 0) {
            loadedSearch.queryCollectionList.every(list => {
                if (list.shared && !list.writable) {
                    editable = false
                    return false
                }
                return true
            })
        }

        if (formContent.queryStringDocuments && repositoryInfo !== null) {
            let documentsOrdered = formContent.queryStringDocuments.split("docid:")
            documentsOrdered.forEach((doc, index) => {
                if (index >= 1) {
                    orderedDocIds = [...orderedDocIds, doc.replace(/\"/gi, "").slice(0, -1)]
                }
            })

            const request = createDocumentSearchRequest(repositoryInfo.id, formContent.queryStringDocuments, null, null, null, null);
            const resp = await fetchMoreDocuments(request)
            let documentData = checkResultAndGetPayload(resp, this.growl);
            if (documentData && documentData?.documentCount !== -1) {
                if (documentData?.documents && documentData?.documents?.length > 0) {
                    const ocDocIDs = [];
                    const repIDs = []
                    documentData?.documents.forEach(doc => {
                        loadedDocuments = [...loadedDocuments, { metadata: doc, repoInfo: repositoryInfo }]
                        ocDocIDs.push(doc.ocDocId);
                        repIDs.push(doc.repositoryId ? doc.repositoryId : '')
                    });
                    const resp = await fetchAdditionalDocumentsInfo(repositoryInfo.id, ocDocIDs, repIDs);
                    const result = checkResultAndGetPayload(resp, this.growl);
                    if (result) {
                        result.forEach(doc => {
                            docsAdditionalInfo[doc.docId] = doc;
                        });
                    }
                }
            }
            loadedDocuments && loadedDocuments.forEach(doc => {
                if (doc.metadata?.ocDocId in docsAdditionalInfo) {
                    doc.metadata.documentCollectionList = docsAdditionalInfo[doc.metadata?.ocDocId].documentCollectionList
                }
            })
        } else if (formContent.queryStringDocuments && repositoryInfo === null) {
            console.log("else")
        }

        const itemPositions = {}
        if (loadedDocuments.length > 0 && orderedDocIds.length > 0) {
            for (const [index, id] of orderedDocIds.entries()) {
                itemPositions[id] = index
            }
            loadedDocuments.sort((a, b) => itemPositions[a.metadata.ocDocId] - itemPositions[b.metadata.ocDocId])
        }

        let documentIndex = 0

        if (formContent.selectedDocumentID) {
            let docIndex = loadedDocuments.findIndex(doc => doc.metadata.ocDocId === formContent.selectedDocumentID)
            if (docIndex !== -1) {
                documentIndex = docIndex
            }
        }

        if ((this.state.selectedDocuments?.length === 0 || this.state.selectedDocuments === undefined) && formContent.queryStringDocuments === undefined) {
            this.props.onLoading(false)
            /*setTimeout(() => {
                this.growl.current.show({
                    sticky: true, closable: true, severity: 'warn',
                    summary: 'No documents loaded', detail: 'This search only contains terms for highlighting. Please import documents and load this search again.'
                });
            }, 500);*/
        }
        /*else {*/
        this.setState({
            queryTermsAutocomplete: formContent.queryTermsAutocomplete ? formContent.queryTermsAutocomplete : this.state.queryTermsAutocomplete,
            queryTerms: null,
            savedSearchType: 'Loaded search',
            //displayLoadDialog: false,
            //queryChanged: true,
            //searchLoaded: true,
            savedSearchID: loadedSearch.id,
            savedSearchName: loadedSearch.fullName,
            queryString: formContent.queryString ? formContent.queryString : undefined,
            editableQuery: editable,
            selectedDocuments: formContent.queryStringDocuments ? loadedDocuments : this.state.selectedDocuments,
            selectedDocumentsStart: formContent.queryStringDocuments ? loadedDocuments : this.state.selectedDocuments,
            selectedDocument: loadedDocuments.length > 0 ? formContent.selectedDocumentID ? loadedDocuments[documentIndex] : loadedDocuments[0] : this.state.selectedDocument,
            //selectedDocument: loadedDocuments.length > 0 ? loadedDocuments[0] : null,
            activeIndex: loadedDocuments.length > 0 ? formContent.selectedDocumentID ? documentIndex : 0 : this.state.activeIndex,
            repositoryInfo: repositoryInfo !== null ? repositoryInfo : this.state.repositoryInfo,
            savedSearch: formContent,
            selectedDocumentCollectionName: formContent.queryStringDocuments ? undefined : this.state.selectedDocumentCollectionName ? this.state.selectedDocumentCollectionName : undefined,
            docViewModalVisible: true
        }, async () => {
            if (formContent.sortFields) {
                this.props.onSetSortFields(formContent.sortFields)
            }
            if (formContent.repositoryInfo) {
                await this.props.onSetSectionOptions(filteredEntries)
            } else {
                await this.props.onSetSectionOptions(this.props.sectionOptions)
            }
            if (formContent.selectedSections) {
                this.handleSectionChange(formContent.selectedSections)
            }
            if (formContent.selectedMetadataOption) {
                this.handleMetadataChange(formContent.selectedMetadataOption)
            }
            if (formContent.selectedSentenceOption) {
                this.selectedSentenceOptionChange(formContent.selectedSentenceOption, false)
            }
            if (formContent.threshold) {
                this.thresholdChange(formContent.threshold, false)
            }
            if (formContent.showSentenceEntities) {
                this.highlightOptionChange(formContent.showSentenceEntities)
            }
            /*if (formContent.selectedSentence) {
                this.props.onSetSelectedSentence(formContent.selectedSentence)
            } else {
                this.props.onSetSelectedSentence("")
            }*/
            this.props.onSetActiveSearchTerms(this.state.queryTermsAutocomplete)
            if (this.state.selectedDocuments?.length > 0)
                this.props.onSetSearchLoaded(true)
            if (formContent.queryStringDocuments) {
                this.props.onSetSelectedSortField('original')
                this.props.onSetSelectedSortDirection('descending')
                this.props.onSetSortDirectionDisabled(true)
            }
            if (this.state.selectedDocuments?.length > 0)
                this.onAnalyze(true)
        })
        //}
    }

    onLoad = () => {
        this.setState({
            displayLoadDialog: true
        })
    }

    closeLoadDialog = () => {
        this.setState({
            displayLoadDialog: false
        })
    }

    onSelectExample = async (example) => {
        this.props.onLoading(true)
        let response
        if (example.queryObject.id === 'pat_example') {
            response = await getRepositoryForName(PATENTS_REPOSITORY_NAME, false);
        } else if (example.queryObject.id === 'pmc_example') {
            response = await getRepositoryForName(PMC_REPOSITORY_NAME, false);
        }
        example.queryObject.repositoryInfo = response.payload
        const schema = await this.getRepositorySchema(response?.payload?.id);
        const sortFields = createSortFieldsList(schema?.sortCriteria, response?.payload);
        example.queryObject.sortFields = sortFields
        addDataToLocalStorage(QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id, { data: example.queryObject });
        //this.clearAll(false)
        this.initSearchForm()
    }
    onSubmitReadcubeLibrary = async (lib) => {
        let docIds = []
        this.props.documents.forEach(doc => {
            docIds = [...docIds, { documentIdentifier: doc.docId, repositoryIdentifier: doc.repositoryId }]
        })
        let response;
        response = await exportDocumentsToReadcube(docIds, 'readcube', lib.id);
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage toReadcubeExports={true} />, null, true);
            if (response.status === 'SUCCESS') {
                this.setState({
                    displayAddReadcubeDialog: false
                })
            }
        }
    }

    render() {

        const { loader, domainLabelsMap, domainColors, isSemanticOn } = this.props;

        let availableContentHeight = 0

        if ($('#' + "sentenceContainer").length > 0 && $('#' + "sentenceSearchContainer").length > 0) {
            let searchContHeight = $('#' + "sentenceSearchContainer").height() - $('#' + "abortLoadingBtn").height()
            let sentenceContHeight = $('#' + "sentenceContainer").height();
            searchContHeight = roundToDecimalPlaces(searchContHeight, 3)
            sentenceContHeight = roundToDecimalPlaces(sentenceContHeight, 3)
            availableContentHeight = sentenceContHeight - (50 + (230 - (window.innerHeight - (searchContHeight + sentenceContHeight))));
        }

        function roundToDecimalPlaces(value, decimalPlaces) {
            const multiplier = 10 ** decimalPlaces;
            return Math.round(value * multiplier) / multiplier;
        }

        return (
            <div className="grid">
                <Toast ref={this.growl} />
                <div className='col-12'>
                    <div id='sentenceSearchContainer'>
                        <div className='col-12' style={{ paddingBottom: 0 }} >
                            <div className='grid'>
                                <div className="p-col textAlignLeft" >
                                    <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader">Sentence Analysis</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10" style={{
                            paddingLeft: 0
                        }}>
                            <label className='ex-description'>
                                Browse through documents to find sentences containing terms of interest.</label>

                            <label className='ex-description' style={{ marginRight: 0 }}>
                                Note: Due to technical reasons PDF documents are not fully supported, yet.</label>
                            <label className='ex-description'>
                                <SeparatorPoint />
                                <SearchExamples
                                    buttonLabel="Examples"
                                    //panelStyle={{ maxWidth: 400, maxHeight: 600, height: "100vh" }}
                                    examples={EDV_EXAMPLES}
                                    buttonStyle={{ verticalAlign: 'baseline' }}
                                    onExampleClick={(example) => this.onSelectExample(example)}
                                />
                            </label>
                        </div>
                        <div style={{
                            paddingLeft: 0, marginBottom: -25, position: 'relative', zIndex: 100, width: '80%'
                        }}>
                            {this.state.savedSearch && this.state.selectedDocuments?.length > 0 &&
                                <React.Fragment>
                                    <span style={{ marginBottom: -3, maxWidth: '80vw', display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        <label title={this.state.savedSearchType !== '' ? `Based on: ${this.state.savedSearchType} ${this.state.queryTerms !== null ? `'${this.state.queryTerms}'` : ''}
                                        ${this.state.repositoryInfo?.label ? this.state.repositoryInfo?.label : ''}` : ''}>
                                            {this.state.savedSearchType !== '' ? `Based on: ${this.state.savedSearchType} ${this.state.queryTerms !== null ? `'${this.state.queryTerms}'` : ''}` : ''}
                                            {this.state.savedSearchType !== '' && this.state.repositoryInfo?.label ?
                                                <SeparatorPoint />
                                                : null
                                            }
                                            {`${this.state.repositoryInfo?.label ? this.state.repositoryInfo?.label : ''}`}
                                            {/*<SeparatorPoint /> {`${addThousandsSeparatorToNumber(this.state.savedSearch.documentCount)} documents`}*/}
                                            <span style={{ opacity: 0.7, paddingLeft: 10, paddingRight: 10 }}>&nbsp;|&nbsp;</span>
                                        </label>
                                    </span>
                                    {/*
                                <Button
                                    className="p-button-text primaryButtonAsLink"
                                    title="Refine your loaded search"
                                    style={{ marginRight: 20 }}
                                    onClick={() => this.refineSearch()}>
                                    Refine
                                </Button>
                        */}
                                </React.Fragment>
                            }
                            {this.state.selectedDocumentCollectionName &&
                                <React.Fragment>
                                    <label>
                                        {`Loaded document collection: ${this.state.selectedDocumentCollectionName}`}  <SeparatorPoint /> {`${addThousandsSeparatorToNumber(this.state.selectedDocumentCollectionNumberDocuments)} documents`}  <span style={{ opacity: 0.7, paddingLeft: 10, paddingRight: 10 }}>&nbsp;|&nbsp;</span>
                                    </label>
                                </React.Fragment>
                            }
                            {/*<Button
                            className="p-button-text primaryButtonAsLink"
                            title="Load a saved search"
                            style={{ paddingRight: 20 }}
                            onClick={() => this.openSavedSearch()}>
                            Saved searches
                    </Button>*/}
                            <Button
                                className="p-button-text primaryButtonAsLink"
                                title="Load a document collection"
                                style={{}}
                                onClick={() => this.openLibrary()}>
                                Library
                            </Button>
                            {/*hasUserRole('ROLE_WATCHLISTS') && (!this.state.selectedDocuments || this.state.selectedDocuments.length === 0) ?
                                <React.Fragment>
                                    <SeparatorPoint />
                                    <Button
                                        className="p-button-text primaryButtonAsLink"
                                        title="Load search"
                                        style={{}}
                                        onClick={() => this.onLoad()}>
                                        Load search
                                    </Button>
                                </React.Fragment>
                                : null
                */}
                        </div>
                        <LoadingOverlay
                            active={loader}
                            spinner={true}
                            text={this.state.selectedDocuments?.length > 0 ? `Searching sentences in document ${addThousandsSeparatorToNumber(this.state?.activeIndex + 1)} of ${addThousandsSeparatorToNumber(this.state.selectedDocuments?.length)}` : 'Loading...'} >
                            <React.Fragment>
                                <div style={{ paddingLeft: 0, paddingBottom: 10 }}>
                                    <SemanticAutoCompleteMultipleChoices
                                        activeSearchTerms={this.state.queryTermsAutocomplete ? this.state.queryTermsAutocomplete : []}
                                        changeOcidAnnotationColor={this.changeOcidAnnotationColor}
                                        domainLabelsMap={domainLabelsMap}
                                        clearSemanticSearch={this.clearSemanticSearch}
                                        domains={_.orderBy(this.props.domains, ['orderPriority'], 'desc')}
                                        onSpecifyQueryTerm={this.onSpecifyQueryTerm}
                                        domainColors={domainColors}
                                        isSemanticOn={isSemanticOn}
                                        showFooter={false}
                                        noCounting={true}
                                        addTerm={this.onAddTerm}
                                        editTermTerm={this.editTermTerm}
                                        editTermImportant={this.editTermImportant}
                                        removeTerm={this.removeTerm}
                                        placeholder={"Search term or keyword"}
                                        openDomainExplorer={this.openDomainExplorer}
                                    />
                                </div>
                                <div className="grid col-12" style={{ paddingTop: 5, paddingLeft: 0 }}>
                                    {hasUserRole('ROLE_WATCHLISTS') //&& this.state.savedSearch !== null 
                                        && (this.state.selectedDocuments?.length > 0) ?
                                        <div className='col-fixed' style={{ paddingTop: 0 }}>
                                            <SaveSearchButtons
                                                savedSearchExists={!!this.state.savedSearchID}
                                                editableQuery={this.state.editableQuery}
                                                noVerticalAlignedButtons={true}
                                                onSaveSearch={this.onSave}
                                                savedSearchName={this.state.savedSearchName}
                                            />
                                        </div>
                                        : null}
                                    {hasUserRole('ROLE_WATCHLISTS') //&& this.state.savedSearch !== null 
                                        && (this.state.selectedDocuments?.length > 0) ?
                                        <SeparatorPoint />
                                        : null
                                    }
                                    {hasUserRole('ROLE_WATCHLISTS') ?
                                        <div className='col-fixed' style={{ paddingTop: 0 }}>
                                            <Button
                                                className="p-button-text primaryButtonAsLink"
                                                title="Load search"
                                                style={{ marginRight: 15, float: 'right' }}
                                                onClick={() => this.onLoad()}>
                                                Load search
                                            </Button>
                                        </div>
                                        : null
                                    }
                                    <div className="p-col textAlignRight" style={{ paddingTop: 0, marginLeft: 'auto' }}>
                                        <Button
                                            label='Analyze'
                                            disabled={(this.state.queryTermsAutocomplete && this.state.queryTermsAutocomplete.length > 0) && this.state.selectedDocuments?.length > 0 ? false : true}
                                            className={`p-button-sm primaryButton valignMiddle`}
                                            onClick={() => this.onAnalyze(false)}
                                            style={{}} />
                                    </div>
                                </div>
                                <div id='abortLoadingBtn' className="grid col-12" style={{ placeContent: 'center' }}>
                                    {(loader && this.state.importantTermsNumber > 0) ?
                                        (<Button
                                            //className="p-button-sm primaryButton"
                                            className="p-button-text primaryButtonAsLink"
                                            title="Stop automatic searching"
                                            style={{ zIndex: 101, marginTop: 0, marginLeft: 12 }}
                                            onClick={() => { this.setAbortLoadingClicked(true) }}>
                                            Stop automatic searching
                                        </Button>) : null}
                                </div>
                            </React.Fragment>
                        </LoadingOverlay>
                    </div>
                    {/*this.state.selectedDocuments && this.state.selectedDocuments.length > 0 ?
                        <div className='grid col-12 pl-0 pt-0' style={{ borderTop: '1px solid #dfdfdf', marginTop: 10 //, marginRight: -45 
                    }}>
                */}
                    {this.state.docViewModalVisible && this.state.selectedDocuments && this.state.selectedDocuments.length > 0 ?
                        <div id='sentenceContainer' className='grid col-12' style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0, marginTop: 5, height: 'calc(100vh - 450px)' }}>
                            {this.state.queryChanged ?
                                <div className='col-12'>
                                    <UpdateSearchResultsInfo onClick={() => this.onAnalyze(false)}
                                        text='Run analysis to see matching sentences.' />
                                </div> : null}
                            <div className={`${this.state.queryChanged ? 'col-6 sm:col-6 md:col-5 lg:col-4 xl:col-3 ex-gr-wrapper disableContent' : 'col-6 sm:col-6 md:col-5 lg:col-4 xl:col-3 ex-gr-wrapper'}`}>
                                <DocumentList
                                    queryTermsAutocomplete={this.state.queryTermsAutocomplete}
                                    selectedDocuments={this.state.selectedDocuments}
                                    selectedDocument={this.state.selectedDocument.metadata.ocDocId}
                                    selectedSentenceOption={this.props.sentenceOption}
                                    activeIndex={this.state.activeIndex}
                                    activeIndexChange={this.activeIndexChange}
                                    activeIndexIncreaseChange={this.activeIndexIncreaseChange}
                                    abortLoadingClicked={this.state.abortLoadingClicked}
                                    setAbortLoadingClicked={this.setAbortLoadingClicked}
                                    sentences={this.props.sentences}
                                    selectedSentence={this.props.selectedSentence}
                                    documentSentences={this.props.documentSentences}
                                    selectedQueryTerms={this.props.activeSearchTerms ? this.props.activeSearchTerms : []}
                                    threshold={this.props.threshold}
                                    loader={loader}
                                    availableContentHeight={availableContentHeight}
                                    thresholdChange={this.thresholdChange}
                                    showSentenceEntities={this.props.showSentenceEntities}
                                    highlightOptionChange={this.highlightOptionChange}
                                    onDocumentSelectionChange={this.onDocumentSelectionChange}
                                    documentIsPDF={this.props.documentIsPDF}
                                    selectedDocumentsAction={this.props.documents}
                                    onAddToCollections={this.onAddToCollections}
                                    onRemoveFromCollections={this.onRemoveFromCollections}
                                    selectedMetadataOption={this.props.selectedMetadataOption}
                                    handleMetadataChange={this.handleMetadataChange}
                                    sectionOptions={this.props.sectionOptions}
                                    selectedSections={this.props.selectedSections}
                                    handleSectionChange={this.handleSectionChange}
                                    sortFields={this.props.sortFields}
                                    selectedSortField={this.props.selectedSortField}
                                    handleSortFieldChange={this.handleSortFieldChange}
                                    documentCollections={this.state.documentCollections}
                                    selectedSortDirection={this.props.selectedSortDirection}
                                    sortDirectionDisabled={this.props.sortDirectionDisabled}
                                    handleSortDirectionChange={this.handleSortDirectionChange}
                                    repositoryInfo={this.state.repositoryInfo}
                                    onShowFamilyMembers={this.onShowFamilyMembers}
                                    patFamilyMembers={this.state.patFamilyMembers}
                                    showFamilyMembersDialog={this.state.showFamilyMembersDialog}
                                    onCloseFamilyMembersDialog={this.onCloseFamilyMembersDialog}
                                    selectedFamilyDocuments={this.props.familyDocuments}
                                    selectedFamilyDocumentsRemove={this.props.familyDocumentsRemove}
                                    handleSelectionChange={this.handleSelectionChange}
                                    onAddFamilyMembers={this.onAddFamilyMembers}
                                    onRemoveFamilyMembers={this.onRemoveFamilyMembers}
                                    selectedSentenceOptionChange={this.selectedSentenceOptionChange}
                                    savedSearch={this.state.savedSearch}
                                    onSave={this.onSave}
                                    onLoad={this.onLoad}
                                    savedSearchID={this.state.savedSearchID}
                                    savedSearchName={this.state.savedSearchName}
                                    editableQuery={this.state.editableQuery}
                                    searchLoaded={this.props.searchLoaded}
                                    docHTML={this.props.docHTML}
                                //docInfos={this.state.docInfos}
                                >
                                </DocumentList>
                            </div>
                            <div className={`${this.state.queryChanged ? 'col-6 sm:col-6 md:col-7 lg:col-8 xl:col-9 disableContent' : 'col-6 sm:col-6 md:col-7 lg:col-8 xl:col-9'}`} style={{ paddingRight: 0 }}>
                                <DocumentViewContainer
                                    loader={this.props.loader}
                                    selectedDocument={this.state.selectedDocument.metadata.ocDocId}
                                    queryChanged={this.state.queryChanged}
                                    searchLoaded={this.props.searchLoaded}
                                    activeIndex={this.state.activeIndex}
                                    activeIndexChange={this.activeIndexChange}
                                    activeIndexIncrease={this.state.activeIndexIncrease}
                                    abortLoadingClicked={this.state.abortLoadingClicked}
                                    setAbortLoadingClicked={this.setAbortLoadingClicked}
                                    threshold={this.props.threshold}
                                    selectedSentenceOption={this.props.sentenceOption}
                                    renderDocumentOnly={true}
                                    availableContentHeight={availableContentHeight}
                                    selectedSentence={this.props.selectedSentence}
                                    selectedQueryTerms={this.props.activeSearchTerms ? this.props.activeSearchTerms : []}
                                    annotationCoordinates={this.props.annotationCoordinates}
                                    showSentenceEntities={this.props.showSentenceEntities}
                                    selectedSections={this.props.selectedSections}
                                    matchs={{
                                        params: {
                                            repID: this.state.selectedDocument.metadata.repositoryId,
                                            ocDocID: this.state.selectedDocument.metadata.ocDocId,
                                            isSemanticSearch: true
                                        },
                                    }}
                                />

                            </div>
                        </div>
                        : null
                    }
                    <SavedSearchesDialog
                        displayDialog={this.state.displaySavedSearchesDialog}
                        onHide={() => this.setState({ displaySavedSearchesDialog: false })}
                        allowedQueryTypes={ALLOWED_SENTENCE_ANALYSIS_SAVED_SEARCH_TYPES}
                        onSelect={(savedSearch) => {
                            this.setState({ displaySavedSearchesDialog: false })
                            this.onSelectSavedSearch(savedSearch);
                        }}
                    />
                    {this.state.displayLibraryDialog ?
                        <AddRemoveDocuments
                            displayDialogAddToCollections={this.state.displayLibraryDialog}
                            onCloseAddToCollectionsDialog={this.onCloseLibraryDialog}
                            onSubmitAddToCollections={this.onSubmitLibrary}
                            onCancelAddToCollections={this.onCloseLibraryDialog}
                            loadCollection={this.state.loadCollection}
                            userData={this.props.userData}
                        />
                        : null
                    }
                    <OntologyBrowserDialog
                        ontBrowserDialogID="docViewDomBrowserDlg"
                        headerLabel="Domain explorer"
                        selectionMode="multiple"
                        placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                        placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                        domains={this.props.availableDomains}
                        initialSearchTerm={this.state.domainExplorerQuery}
                        initialOcids={this.state.domainExplorerOcids}
                        numOfChildNodes={10} // not used for preloaded ontology
                        allowConceptSearchByClick={true}
                        allowSearchInOntologies={true}
                        loadOntologiesOnStart={true}
                        ontBrowserVisible={this.state.domainBrowserVisible}
                        onOntBrowserClose={this.onDomainExplorerClose}
                        onOntBrowserSubmit={this.onDomainExplorerSubmit}
                        onOntBrowserShow={() => { }}
                        width="90vw"
                        height="90vh"
                    />
                </div>
                <Dialog ref={this.toastBC} visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                    onHide={this.rejectSelectedDocuments} style={{ width: this.state.repositoryInfo?.name?.startsWith('rc_') ? '26rem' : hasUserRole('ROLE_READCUBEVIEW') ? '37rem' : '30rem', bottom: 10 }}
                    draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                    <ToastContent selectedElements={this.props.documents}
                        elementLabel='document'
                        onHide={this.rejectSelectedDocuments}
                        onAddElements={this.onOpenAddToCollectionsDialog}
                        onRemoveElements={this.onRemoveElements}
                        onAddToReadcube={this.onAddToReadcube}
                        activeOptions={this.state.repositoryInfo?.name?.startsWith('rc_') ? ['removeElements'] : hasUserRole('ROLE_READCUBEVIEW') ? ['addElements', 'addToReadcube',
                            'removeElements'] : ['addElements', 'removeElements']} />
                </Dialog>
                <ConfirmationDialog
                    displayDialog={this.state.displayRemoveConfirmDialog}
                    onHide={() => this.setState({ displayRemoveConfirmDialog: false })}
                    onSubmit={() => {
                        this.removeSelectedDocuments();
                        this.setState({ displayRemoveConfirmDialog: false });
                    }}
                    headerText="Confirm"
                    messageText="Remove selected documents?"
                    submitButtonLabel="Remove"
                />
                <SaveSearchDialog
                    addSaveOptions={true}
                    displayDialogStoreQuery={this.state.displaySaveDialog}
                    editQuery={this.state.editQuery}
                    queryString={this.state.queryString}
                    savedSearchID={this.state.savedSearchID}
                    allowAlerts={false}
                    onSubmit={this.onSaveSearch}
                    onHide={this.closeSaveDialog}
                    userData={this.props.userData}
                />
                <SavedSearchesDialog
                    displayDialog={this.state.displayLoadDialog}
                    onHide={this.closeLoadDialog}
                    allowedQueryTypes={ALLOWED_SENTENCE_ANALYSIS_LOAD_SEARCH_TYPES}
                    onSelect={(savedSearch) => {
                        this.onLoadSearch(savedSearch);
                    }}
                />
                <AddReadcubeDialog
                    displayDialog={this.state.displayAddReadcubeDialog}
                    onHide={() => this.setState({ displayAddReadcubeDialog: false })}
                    onSubmitReadcubeLibrary={this.onSubmitReadcubeLibrary}
                    readcubeRequests={null}>
                </AddReadcubeDialog>
                {this.state.selectedDocuments?.length > 0 ?
                    <Dialog ref={this.toastBC} visible={this.state.displayProceedSearchToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                        style={{ width: '28.5rem', bottom: '6.1rem', textAlign: 'center' }}
                        draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                        <div style={{ paddingLeft: 20 }} className='toast-container'>
                            <div>
                                <p style={{ fontWeight: 500, paddingTop: 5 }}>{`No matching sentences found in document ${this.state?.activeIndex + 1} of ${this.state.selectedDocuments?.length}`}</p>
                                <Button
                                    className="p-button-text primaryButtonAsLink"
                                    title="Search in next document"
                                    style={{ marginBottom: 12 }}
                                    onClick={() => { this.onNextDocumentClick() }}>
                                    Search in next document
                                </Button>
                            </div>
                            <div className="toast-icons-close pointer" onClick={() => { this.setDisplayProceedSearchToast(false) }} >
                                <span className='material-symbols-outlined'>{'close'}</span>
                            </div>
                        </div>
                    </Dialog>
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetSelectedSentence: (id) =>
            dispatch(setSelectedSentenceAnalysis(id)),
        onSetThreshold: (threshold) =>
            dispatch(setQueryTermsThresholdSentenceAnalysis(threshold)),
        onSetActiveSearchTerms: (searchTerms) =>
            dispatch(setActiveSearchTermsSentenceAnalysis(searchTerms)),
        onSelectedSentenceOptionChange: (sentenceOption) =>
            dispatch(setSentenceOptionSentenceAnalysis(sentenceOption)),
        onLoading: (loader) =>
            dispatch(setLoaderSentenceAnalysis(loader)),
        onSetShowSentenceEntities: (showSentenceEntities) =>
            dispatch(setShowSentenceEntitiesSentenceAnalysis(showSentenceEntities)),
        onSetSelectedDocuments: (documents) =>
            dispatch(setSelectedDocumentsSentenceAnalysis(documents)),
        onSetSelectedMetadataOption: (option) =>
            dispatch(setSelectedMetadataOptionSentenceAnalysis(option)),
        onSetSectionOptions: (options) =>
            dispatch(setSectionOptionsSentenceAnalysis(options)),
        onSetSelectedSections: (sections) =>
            dispatch(setSelectedSectionsSentenceAnalysis(sections)),
        onSetSortFields: (sortFields) =>
            dispatch(setSortFieldsSentenceAnalysis(sortFields)),
        onSetSelectedSortField: (sortField) =>
            dispatch(setSelectedSortFieldSentenceAnalysis(sortField)),
        onSetSelectedSortDirection: (sortDirection) =>
            dispatch(setSelectedSortDirectionSentenceAnalysis(sortDirection)),
        onSetSortDirectionDisabled: (disabled) =>
            dispatch(setSortDirectionDisabledSentenceAnalysis(disabled)),
        onSetSelectedFamilyDocuments: (familyDocuments) =>
            dispatch(setSelectedFamilyDocumentsSentenceAnalysis(familyDocuments)),
        onSetSelectedFamilyDocumentsRemove: (familyDocumentsRemove) =>
            dispatch(setSelectedFamilyDocumentsRemoveSentenceAnalysis(familyDocumentsRemove)),
        onSetSearchLoaded: (loaded) =>
            dispatch(setSearchLoadedSentenceAnalysis(loaded))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SentenceAnalysisContainer))
