import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    fetchTermSuggestions,
    fetchDocumentSearchResults,
    fetchSortFields,
    fetchQueryString,
    addQuery,
    fetchSimilarDocumentSearchResults,
    fetchSimilarPublicationListXLSX,
    fetchPublicationListXLSX,
    fetchIPCClasses,
    fetchStatistics,
    fetchAvailableUserRepositories
} from '../../../redux/actions/WebApiActions'
import DocumentFinderNew from '../DocumentFinder/DocumentFinderNew'


const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    terms: state.webAPI.terms,
    documents: state.webAPI.documents,
    sortFields: state.webAPI.sortFields,
    queryString: state.webAPI.queryString,
    similarDocuments: state.webAPI.similarDocuments,
    publicationListXLSX: state.webAPI.publicationListXLSX,
    publicationListSimilarXLSX: state.webAPI.publicationListSimilarXLSX,
    ipcClasses: state.webAPI.ipcClasses,
    userData: state.user.data,
    statistics: state.webAPI.statistics
})

const mapDispatchToProps = dispatch => ({
    fetchTermSuggestions: (history, search, showFailGrowlSuggestions) => dispatch(fetchTermSuggestions(history, search, showFailGrowlSuggestions)),
    fetchDocumentSearchResults: (history, search, showFailGrowl) => dispatch(fetchDocumentSearchResults(history, search, showFailGrowl)),
    fetchSortFields: (history, search, showFailGrowl) => dispatch(fetchSortFields(history, search, showFailGrowl)),
    fetchQueryString: (history, search, showFailGrowlQueryString) => dispatch(fetchQueryString(history, search, showFailGrowlQueryString)),
    addQuery: (history, search, showQueryAddSuccessGrowl, showQueryAddFailGrowl) => dispatch(addQuery(history, search, showQueryAddSuccessGrowl, showQueryAddFailGrowl)),
    fetchSimilarDocumentSearchResults: (history, search, showFailGrowl) => dispatch(fetchSimilarDocumentSearchResults(history, search, showFailGrowl)),
    fetchSimilarPublicationListXLSX: (history, search) => dispatch(fetchSimilarPublicationListXLSX(history, search)),
    fetchStatistics: (history, search) => dispatch(fetchStatistics(history, search)),
    fetchPublicationListXLSX: (history, search) => dispatch(fetchPublicationListXLSX(history, search)),
    fetchIPCClasses: (history) => dispatch(fetchIPCClasses(history)),
    fetchAvailableUserRepositories: (history) => dispatch(fetchAvailableUserRepositories(history))
})

const DocumentFinderContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentFinderNew))

export default DocumentFinderContainer