/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AutoComplete from '../../common/AutoComplete/AutoComplete';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
//import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
//import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import NumberFormat from 'react-number-format';
import { RadioButton } from 'primereact/radiobutton';
//import Repositories from './Repositories'
//import OntologyBrowserView from "../../../components/webapi/ontbrowser/OntologyBrowserView"
import OntologyBrowserDialog from "../ontbrowser/OntologyBrowserDialog"
//import { Slider } from 'primereact/slider'
import axios from 'axios'
import { getHeaderToken } from '../../../api/index'
import { normalizeConceptTerm, deduplicateTerms, findMatchIgnoreCase, isArrayEmpty } from '../util'
import Options from './Options'
//import './InferenceSearch.css'
import { APP_PROPERTIES } from '../../../properties/index';
//import iconAddConcept from "../../../images/icons/ontology/DomainBrowser_2_212121.svg";
//import Types from './Types'
import runTripleIcon from "../../../../src/assets/images/icons/icon_run_triple@2x.png";
import runSubPathIcon from "../../../../src/assets/images/icons/icon_run_two_triples@2x.png";
import { Tooltip } from 'primereact/tooltip';
import InputFieldWithClear from '../../webapi/general/inputfields/InputFieldWithClear';

class InferencePathComponent extends Component {

    constructor() {
        super();
        this.counterStart = 0
        this.counterEnd = 0
        this.modes = [{ name: 'Ontological', key: 'ontological' }, { name: 'Exact', key: 'exact' }];
        this.state = {
            replaceIndex: null,
            replaceItem: null,
            visible: false,
            displayDialogExecute: false,
            displayDialogDomains: false,
            displayDialogEditEntity: false,
            displayDialogEditRelation: false,
            domain: 'chem',
            relations: [],
            entityNumber: null,
            selectedMode: this.modes[0],
            startEnd: 'start',
            query: [],
            startProps: {},
            endProps: {},
            suggestedTerms: [],
            directFragmentMatches: []
        };
        this.itemTemplate = this.itemTemplate.bind(this);
        this.selItemTemplateStart = this.selItemTemplateStart.bind(this);
        this.selItemTemplateEnd = this.selItemTemplateEnd.bind(this);
    }

    componentDidMount() {
        if (this.props.relationValues && this.props.relationValues.length > 0) {
            this.setState({
                relations: this.props.relationValues
            })
        }
        if (this.props.relationMode) {
            this.setState({
                relationMode: this.props.relationMode
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.relationValues !== prevProps.relationValues) {
            this.setState({
                relations: this.props.relationValues
            })
        }
        if (this.props.relationMode !== prevProps.relationMode) {
            this.setState({
                relationMode: this.props.relationMode
            })
        }
    }

    onClick = () => {
        this.setState({ visible: true });
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    handleRemove = () => {
        this.props.callbackFromRemove(this.props.number, this.props.layerNumber)
    }

    onExecuteClick = () => {
        this.props.executeSinglePath(this.props.number + 1)

        /*let blacklistEntries = []

        if (this.props.selectedBlacklists && this.props.selectedBlacklists.length > 0) {
            for (let i = 0; i < this.props.selectedBlacklists.length; i++) {
                await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${this.props.selectedBlacklists[i]}`, { headers: getHeaderToken() })
                    // eslint-disable-next-line
                    .then(function (response) {
                        let entries = response.data
                        blacklistEntries = [...blacklistEntries, entries]
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                        } else if (error.response.status === 401) {
                            this.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
        }

        let negated = []

        blacklistEntries.forEach(entry => {
            entry.forEach(item => {
                negated = [...negated, { ocid: item.ocid, negated: true, conceptLookupType: item.conceptLookupType }]
            })
        })

        this.setState({
            fetchingResults: true
        })

        let request = {
            apiRevision: 0,
            domainWhitelist: [
            ],
            forceCompletePathMatching: true,
            matchedPathsMaxCount: 100,
            matchedPathsOffset: 0,
            matchedPathsRanking: "TRIPLE_COUNT_MIN",
            matchedPathsWithTripleBaseData: true,
            maxMegaTriplesToProcess: 0,
            maxTopMatchedConceptsPerPathStep: 0,
            matchedPathsOrdering: "RANKED",
            matchedPathsMaxTripleSentences: 10,
            pathEntitiesFilter: {
            },
            pathRelationsFilter: {
            },
            repository: this.props.selectedRepository,
            topMatchPerPathStepCountingUseTripleCount: true,
            topMatchedEntitiesPerPathStepIndexes: [
                0
            ],
            topMatchedRelationsPerPathStepIndexes: [
                0
            ],
            uniqueMatchedEntitiesPerPathStepIndexes: [
                0
            ],
            uniqueMatchedRelationsPerPathStepIndexes: [
                0
            ],
            withConceptDomains: true,
            withConceptPrefnames: true
        }

        let entitiesStart = {}
        let entitiesEnd = {}

        let rootOCIDs = [{ ocid: 150000001799 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 }]
        let rootOCIDsAll = [{ ocid: 150000001799 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
        { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }]

        if (this.props.startTerm === '' || this.props.startTerm.length === 0) {
            this.props.logicals === 'ctd' || this.props.logicals === 'cip' || this.props.logicals === 'cid' || this.props.logicals === 'cic' ||
                this.props.logicals === 'cipp' || this.props.logicals === 'caa' ?
                entitiesStart['0'] = rootOCIDs
                :
                this.props.logicals === 'aaa' || this.props.logicals === 'aac' || this.props.logicals === 'aad' || this.props.logicals === 'aapp' ||
                    this.props.logicals === 'aap' ?
                    entitiesStart['0'] = rootOCIDsAll
                    :
                    entitiesStart['0'] = [
                        {
                            domain:
                                this.props.logicals === 'pid' ? 'proteins' :
                                    this.props.logicals === 'paa' ? 'proteins' :
                                        this.props.logicals === 'pmc' ? 'proteins' :
                                            this.props.logicals === 'pmpp' ? 'proteins' :
                                                this.props.logicals === 'ppmp' ? '' :
                                                    this.props.logicals === 'ppipp' ? '' :
                                                        this.props.logicals === 'pppd' ? '' :
                                                            this.props.logicals === 'dmp' ? 'diseases' :
                                                                this.props.logicals === 'daa' ? 'diseases' : '',
                            ocid: this.props.logicals === 'ppmp' || this.props.logicals === 'ppipp' || this.props.logicals === 'pppd'
                                || this.props.logicals === 'ppaa' ? '230000035790' : ''
                        }
                    ]
        } else {
            entitiesStart['0'] = []
            let allStartEntities = []
            for (let j = 0; j < this.props.startTermOCID.length; j++) {
                let allSubEntities = []
                for (let k = 0; k < this.props.startTermOCID[j].length; k++) {
                    allSubEntities = [...allSubEntities, { ocid: this.props.startTermOCID[j][k]['+ocid'], conceptLookupType: this.props.startChecked === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                }
                allSubEntities.forEach(entity => {
                    allStartEntities = [...allStartEntities, (entity)]
                })
            }
            entitiesStart['0'] = allStartEntities
        }

        if (this.props.endTerm === '' || this.props.endTerm.length === 0) {
            this.props.logicals === 'cic' || this.props.logicals === 'pmc' || this.props.logicals === 'aac' ?
                entitiesEnd['1'] = rootOCIDs
                :
                this.props.logicals === 'aaa' || this.props.logicals === 'caa' || this.props.logicals === 'daa' ||
                    this.props.logicals === 'ppaa' || this.props.logicals === 'paa' ?
                    entitiesEnd['1'] = rootOCIDsAll
                    :
                    entitiesEnd['1'] = [{
                        domain:
                            this.props.logicals === 'ctd' ? 'diseases' :
                                this.props.logicals === 'cip' ? 'proteins' :
                                    this.props.logicals === 'cid' ? 'diseases' :
                                        this.props.logicals === 'cipp' ? '' :
                                            this.props.logicals === 'ppipp' ? '' :
                                                this.props.logicals === 'pid' ? 'diseases' :
                                                    this.props.logicals === 'pppd' ? 'diseases' :
                                                        this.props.logicals === 'aad' ? 'diseases' :
                                                            this.props.logicals === 'pmpp' ? '' :
                                                                this.props.logicals === 'ppmp' ? 'proteins' :
                                                                    this.props.logicals === 'aap' ? 'proteins' :
                                                                        this.props.logicals === 'dmp' ? 'proteins' : '',
                        ocid: this.props.logicals === 'cipp' || this.props.logicals === 'pmpp' || this.props.logicals === 'ppipp'
                            || this.props.logicals === 'aapp' ? '230000035790' : ''
                    }
                    ]
        } else {
            entitiesEnd['1'] = []
            let allEndEntities = []
            for (let j = 0; j < this.props.endTermOCID.length; j++) {
                let allSubEntities = []
                for (let k = 0; k < this.props.endTermOCID[j].length; k++) {
                    allSubEntities = [...allSubEntities, { ocid: this.props.endTermOCID[j][k]['+ocid'], conceptLookupType: this.props.endChecked === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                }
                allSubEntities.forEach(entity => {
                    allEndEntities = [...allEndEntities, (entity)]
                })
            }
            entitiesEnd['1'] = allEndEntities
        }

        let startEnd = Object.assign(entitiesStart, entitiesEnd)

        if (negated && negated.length > 0) {
            Object.keys(startEnd).forEach(key => {
                for (let j = 0; j < negated.length; j++) {
                    startEnd[key] = [...startEnd[key], negated[j]]
                }
            })
        }

        request['pathEntitiesFilter'] = startEnd

        let allRelations = []
        if (typeof this.props.relationOCID !== 'string') {
            if (this.props.relationOCID.length > 0) {
                this.props.relationOCID.forEach(ocid => {
                    allRelations = [...allRelations, { ocid: ocid }]
                })
            } else {
                allRelations = [
                    { ocid: '232000000390' }, { ocid: '232000000380' }, { ocid: '232000000379' }, { ocid: '232000000387' }, { ocid: '232000000439' }, { ocid: '232000000391' }, { ocid: '232000000884' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000578' }, { ocid: '232000000588' }, { ocid: '232000000589' }, { ocid: '232000000590' }, { ocid: '232000000152' }
                ]
            }
        }

        if (typeof this.props.relationOCID === 'string') {
            if (this.props.relationOCID !== "") {
                request['pathRelationsFilter'][1] = [{
                    ocid: this.props.relationOCID
                }]
            } else {
                allRelations = [
                    { ocid: '232000000390' }, { ocid: '232000000380' }, { ocid: '232000000379' }, { ocid: '232000000387' }, { ocid: '232000000439' }, { ocid: '232000000391' }, { ocid: '232000000884' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000578' }, { ocid: '232000000588' }, { ocid: '232000000589' }, { ocid: '232000000590' }, { ocid: '232000000152' }
                ]
            }
        }

        request['pathRelationsFilter'][1] = allRelations

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({
                        matchedPaths: response.data.matchedPaths,
                        matchedPathsCount: response.data.matchedPathsCount,
                        prefNames: response.data.ocidToPreferredName,
                        tripleRequest: request,
                        //displayDialogExecute: true,
                        fetchingResults: false,
                        number: self.props.number + 1
                    }, () => {
                        self.props.callbackFromTripleExecute(self.state.matchedPaths, self.state.matchedPathsCount, self.state.prefNames,
                            self.state.number, self.state.tripleRequest)
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })*/
    }

    onExecutePathClick = () => {
        this.props.callbackFromExecutePath(this.props.number)
    }

    filterStartTerm = (e) => {
        let newLeftDomains = []

        if (this.props.leftDomains[0] !== 'all domains') {
            this.props.leftDomains.forEach(entry => {
                //subtreeOcids += entry + ', '
                if (entry === 'effects or process') {
                    newLeftDomains.push('effects')
                } else if (entry === 'chemistry') {
                    newLeftDomains.push('chem')
                } else if (entry === 'drug') {
                    newLeftDomains.push('drugs')
                } else if (entry === 'natural products') {
                    newLeftDomains.push('natprod')
                } else if (entry === 'protein') {
                    newLeftDomains.push('proteins')
                    newLeftDomains.push('humangenes')
                    //newLeftDomains.push('antibodies')
                } else if (entry === 'disease') {
                    newLeftDomains.push('diseases')
                } else if (entry === 'substance') {
                    newLeftDomains.push('substances')
                    newLeftDomains.push('natprod')
                    newLeftDomains.push('drugs')
                    newLeftDomains.push('chem')
                } else if (entry === 'cellline') {
                    newLeftDomains.push('celllines')
                } else {
                    newLeftDomains.push(entry)
                }
            })
        }

        let subtreeOcids = ''

        if (this.props.leftDomains[0] !== 'all domains') {
            subtreeOcids = '230000035790, '
            newLeftDomains && newLeftDomains.length > 0 && newLeftDomains.forEach(dom => {
                if (dom !== "effects")
                    subtreeOcids += dom + ', '
            })
            subtreeOcids = subtreeOcids.slice(0, -2)
        } else {
            subtreeOcids = '230000035790, species, chem, drugs, natprod, proteins, diseases, substances, humangenes, anatomy, celllines'
        }

        let selectedRepositories = []
        this.props.userData.userDetails.department?.selectedRepositories &&
            this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
                selectedRepositories = [...selectedRepositories, rep.id]
            })


        setTimeout(() => {
            this.setState({
                part: e.query
            }, () => {
                var self = this;
                axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions`, {
                    termFragment:
                        self.state.part, mergeDomains: true, withDirectMatches: true,
                    withPrefname: true,
                    domains: self.props.logicals === 'free' ? ['chem', 'proteins', 'diseases', 'effects'] :
                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains') || (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains') ||
                            (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains') || (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains') ||
                            (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains') ? newLeftDomains :
                            (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') || (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                                (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') || (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                                (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ? ['chem', 'drugs', 'natprod', 'substances', 'proteins', 'effects', 'diseases', 'species', 'humangenes', 'anatomy', 'celllines'] :
                                self.props.logicals === 'caa' ? ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cip' || self.props.logicals === 'ctd' ?
                                    ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cid' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                        self.props.logicals === 'pid' ? ['proteins'] : self.props.logicals === 'cic' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                            self.props.logicals === 'pmc' || self.props.logicals === 'paa' ? ['proteins'] : self.props.logicals === 'cipp' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                self.props.logicals === 'pmpp' ? ['proteins'] : self.props.logicals === 'ppmp' || self.props.logicals === 'ppaa' ? ['effects'] :
                                                    self.props.logicals === 'ppipp' ? ['effects'] : self.props.logicals === 'pppd' ? ['effects'] : self.props.logicals === 'dmp' ||
                                                        self.props.logicals === 'daa' ? ['diseases'] : [],
                    namedFilter:
                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            self.props.logicals === 'ppmp' || self.props.logicals === 'ppipp' || self.props.logicals === 'ppaa' || self.props.logicals === 'pppd' ||
                            self.props.logicals === 'free' ? 'ocidSubtrees' : '',
                    namedFilterParameters:
                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ||
                            (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                            (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                            self.props.logicals === 'ppmp' || self.props.logicals === 'ppipp' || self.props.logicals === 'ppaa' || self.props.logicals === 'pppd' ?
                            { allowedSubtreesRootOcids: subtreeOcids }
                            : self.props.logicals === 'free' ? { allowedSubtreesRootOcids: '150000001799,201000010099,108020003292,230000035790' }
                                : {}
                }, { headers: getHeaderToken() })
                    // eslint-disable-next-line
                    .then(function (response) {
                        self.setState({
                            suggestedTerms: response.data.suggestedTerms,
                            directFragmentMatches: response.data.directFragmentMatches
                        })
                    }
                    )
            })
        }, 0)
    }

    handleCancelEdit = () => {
        this.setState({
            displayDialogEditEntity: false
        }, () => {
            if (this.state.startEnd === 'start') {
                this.props.callbackFromStartTermChange(this.state.startProps.startTerm, this.state.startProps.startTermOCID,
                    this.state.startProps.startTermOCID, this.props.number + 1, this.state.startProps.startTermDomains,
                    this.state.startProps.autocompleteOrBrowserStart, false)
            } else {
                this.props.callbackFromEndTermChange(this.state.endProps.endTerm, this.state.endProps.endTermOCID,
                    this.state.endProps.endTermOCID, this.props.number + 1, this.state.endProps.endTermDomains,
                    this.state.endProps.autocompleteOrBrowserEnd, false)
            }
        }
        )
    }

    handleCancelEditRelation = () => {
        this.setState({
            displayDialogEditRelation: false
        })
    }

    handleRelationModeChangeAfterOK = () => {
        this.setState({
            displayDialogEditRelation: false
        }, () => {
            this.props.callbackFromRelationModeChange(this.state.relationMode, this.props.number + 1)
        })
    }

    handleStartTermChangeAfterOK = async (query) => {
        let selectedRepositories = []
        this.props.userData.userDetails.department?.selectedRepositories &&
            this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
                selectedRepositories = [...selectedRepositories, rep.id]
            })

        let newLeftDomains = []

        if (this.props.leftDomains[0] !== 'all domains') {
            this.props.leftDomains.forEach(entry => {
                //subtreeOcids += entry + ', '
                if (entry === 'effects or process') {
                    newLeftDomains.push('effects')
                } else if (entry === 'chemistry') {
                    newLeftDomains.push('chem')
                } else if (entry === 'drug') {
                    newLeftDomains.push('drugs')
                } else if (entry === 'natural products') {
                    newLeftDomains.push('natprod')
                } else if (entry === 'protein') {
                    newLeftDomains.push('proteins')
                    newLeftDomains.push('humangenes')
                    //newLeftDomains.push('antibodies')
                } else if (entry === 'disease') {
                    newLeftDomains.push('diseases')
                } else if (entry === 'substance') {
                    newLeftDomains.push('substances')
                    newLeftDomains.push('natprod')
                    newLeftDomains.push('drugs')
                    newLeftDomains.push('chem')
                } else if (entry === 'cellline') {
                    newLeftDomains.push('celllines')
                } else {
                    newLeftDomains.push(entry)
                }
            })
        }

        let subtreeOcids = ''

        if (this.props.leftDomains[0] !== 'all domains') {
            subtreeOcids = '230000035790, '
            newLeftDomains && newLeftDomains.length > 0 && newLeftDomains.forEach(dom => {
                if (dom !== "effects")
                    subtreeOcids += dom + ', '
            })
            subtreeOcids = subtreeOcids.slice(0, -2)
        } else {
            subtreeOcids = '230000035790, species, chem, drugs, natprod, proteins, diseases, substances, humangenes, anatomy, celllines'
        }

        let array = []
        let arrayDom = []
        let arrayOCIDs = []
        let arrayDomains = []
        this.setState({
            query: query,
            relations: []
        }, async () => {
            if (query.length === 0) {
                this.setState({
                    ocidsQueryAuto: [],
                    ocidsQueryAutoRemove: [],
                    arrayDomains: [],
                    autocompleteOrBrowserStart: [],
                    displayDialogEditEntity: false,
                    displayDialogEditRelation: false
                }, () =>
                    this.props.callbackFromStartTermChange(this.state.query, this.state.ocidsQueryAuto, this.state.ocidsQueryAuto, this.props.number + 1, this.state.arrayDomains, this.state.autocompleteOrBrowserStart, true, this.state.selectedMode, this.state.relations))
            } else {
                let operationsCompleted = 0;
                function operation() {
                    ++operationsCompleted;
                    arrayOCIDs = []
                    arrayDomains = []
                    if (operationsCompleted === query.length) {
                        self.setState({
                            ocidsQuery: arrayOCIDs,
                            displayDialogEditEntity: false,
                            displayDialogEditRelation: false
                        }, () => self.props.callbackFromStartTermChange(self.state.query, arrayOCIDs, completeArray, self.props.number + 1, completeDomainArray, autocompleteOrBrowserStart, true, self.state.selectedMode, self.state.relations))
                    }
                }
                let completeArray = []
                let completeDomainArray = []
                let autocompleteOrBrowserStart = this.props.autocompleteOrBrowserStart
                let domainsStart = this.props.startTermDomains
                let startTerms = this.props.startTerm
                let startTermOCIDS = this.props.startTermOCID
                if (query.length < this.props.startTerm.length) {
                    let missing = startTerms.filter(function (v) {
                        return !query.includes(v)
                    })
                    let missingIndex = startTerms.indexOf(missing[0])
                    autocompleteOrBrowserStart.splice(missingIndex, 1)
                    startTerms.splice(missingIndex, 1)
                    domainsStart.splice(missingIndex, 1)
                    startTermOCIDS.splice(missingIndex, 1)
                    this.props.callbackFromStartTermChange(startTerms, [startTermOCIDS], startTermOCIDS, this.props.number + 1, domainsStart, autocompleteOrBrowserStart, true, undefined, self.props.relationOCID)
                } else {
                    for (let i = 0; i < query.length; i++) {
                        var self = this;
                        if (autocompleteOrBrowserStart[i] === undefined || autocompleteOrBrowserStart[i] === "autocomplete") {
                            autocompleteOrBrowserStart[i] = 'autocomplete'

                            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions`, {
                                termFragment:
                                    query[i], mergeDomains: true, withDirectMatches: true,
                                domains: self.props.logicals === 'free' ? ['chem', 'proteins', 'diseases', 'effects'] :
                                    (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains') || (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains') ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains') || (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains') ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains') ? newLeftDomains :
                                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') || (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                                            (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') || (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                                            (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ? ['chem', 'drugs', 'natprod', 'substances', 'proteins', 'effects', 'diseases', 'species', 'humangenes', 'anatomy', 'celllines'] :
                                            self.props.logicals === 'caa' ? ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cip' || self.props.logicals === 'ctd' ?
                                                ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cid' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                    self.props.logicals === 'pid' ? ['proteins'] : self.props.logicals === 'cic' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                        self.props.logicals === 'pmc' || self.props.logicals === 'paa' ? ['proteins'] : self.props.logicals === 'cipp' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                            self.props.logicals === 'pmpp' ? ['proteins'] : self.props.logicals === 'ppmp' || self.props.logicals === 'ppaa' ? ['effects'] :
                                                                self.props.logicals === 'ppipp' ? ['effects'] : self.props.logicals === 'pppd' ? ['effects'] : self.props.logicals === 'dmp' ||
                                                                    self.props.logicals === 'daa' ? ['diseases'] : [],
                                namedFilter:
                                    (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        self.props.logicals === 'ppmp' || self.props.logicals === 'ppipp' || self.props.logicals === 'ppaa' || self.props.logicals === 'pppd' ||
                                        self.props.logicals === 'free' ? 'ocidSubtrees' : '',
                                namedFilterParameters:
                                    (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                                        self.props.logicals === 'ppmp' || self.props.logicals === 'ppipp' || self.props.logicals === 'ppaa' || self.props.logicals === 'pppd' ?
                                        { allowedSubtreesRootOcids: subtreeOcids }
                                        : self.props.logicals === 'free' ? { allowedSubtreesRootOcids: '150000001799,201000010099,108020003292,230000035790' }
                                            : {}
                            }, { headers: getHeaderToken() })
                                // eslint-disable-next-line
                                .then(function (response) {
                                    let ocids = {}
                                    if (!response.data.suggestedTerms) {
                                        self.setState({
                                            suggestedTerms: [],
                                            directFragmentMatches: {}
                                        })
                                        if (query[i].includes(' (Text exact)')) {
                                            ocids = {
                                                "+tr": query[i].replace(' (Text exact)', '')
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        } else if (query[i].includes(' (Text with variants)')) {
                                            ocids = {
                                                "+t": query[i].replace(' (Text with variants)', '')
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        } else {
                                            ocids = {
                                                "+struct": query[i]
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        }
                                    } else {
                                        self.setState({
                                            suggestedTerms: [],
                                            directFragmentMatches: {}
                                        })
                                        if (response.data.directFragmentMatches && Object.keys(response.data.directFragmentMatches).length > 0) {
                                            Object.keys(response.data.directFragmentMatches).forEach(entry => {
                                                arrayDomains = [...arrayDomains, entry]
                                                for (let k = 0; k < response.data.directFragmentMatches[entry].length; k++) {
                                                    ocids = {
                                                        "+ocid": response.data.directFragmentMatches[entry][k].ocid.toString()
                                                    }
                                                    arrayOCIDs = [...arrayOCIDs, ocids]
                                                }
                                            })
                                        } else {
                                            response.data.suggestedTerms.filter(filter => filter.term === query[i]).forEach(term => {
                                                array = term.ocids
                                                arrayDom = term.domains
                                                for (var j = 0; j < array.length; j++) {
                                                    ocids = {
                                                        "+ocid": array[j].toString()
                                                    }
                                                    arrayOCIDs = [...arrayOCIDs, ocids]
                                                }
                                                for (var k = 0; k < arrayDom.length; k++) {
                                                    arrayDomains = [...arrayDomains, arrayDom[k]]
                                                }
                                            })
                                        }

                                    }
                                    completeArray = [...completeArray, arrayOCIDs]
                                    completeDomainArray = [...completeDomainArray, arrayDomains]
                                    operation();
                                })

                        } else if (autocompleteOrBrowserStart[i] === "browser") {
                            completeDomainArray = [...completeDomainArray, this.props.startTermDomains[i]]
                            completeArray = [...completeArray, this.props.startTermOCID[i]]
                            operation()
                        }
                    }
                }
            }
        }
        )
    }

    handleStartTermChange = async (e) => {
        let queryNames = []
        if (e.value.length !== 0) {
            e.value.forEach(term => {
                if (term.term) {
                    queryNames = [...queryNames, term.term]
                } else {
                    queryNames = [...queryNames, term]
                }
            })
        }
        if (queryNames.length < this.state.startProps.startTermShow.length && queryNames.length !== 0) {
            let autocompleteOrBrowserStart = JSON.parse(JSON.stringify(this.props.autocompleteOrBrowserStart))
            let domainsStart = JSON.parse(JSON.stringify(this.props.startTermDomains))
            let startTerms = JSON.parse(JSON.stringify(this.props.startTerm))
            let startTermOCIDS = JSON.parse(JSON.stringify(this.props.startTermOCID))
            let missing = startTerms.filter(function (v) {
                return !queryNames.includes(v)
            })
            let missingIndex = startTerms.indexOf(missing[0])
            autocompleteOrBrowserStart.splice(missingIndex, 1)
            startTerms.splice(missingIndex, 1)
            domainsStart.splice(missingIndex, 1)
            startTermOCIDS.splice(missingIndex, 1)
            this.props.callbackFromStartTermChange(startTerms, [startTermOCIDS], startTermOCIDS, this.props.number + 1, domainsStart, autocompleteOrBrowserStart, false, this.state.startProps.startTermShow)
        } else {
            let selectedRepositories = []
            this.props.userData.userDetails.department?.selectedRepositories &&
                this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
                    selectedRepositories = [...selectedRepositories, rep.id]
                })

            let newLeftDomains = []

            if (this.props.leftDomains[0] !== 'all domains') {
                this.props.leftDomains.forEach(entry => {
                    //subtreeOcids += entry + ', '
                    if (entry === 'effects or process') {
                        newLeftDomains.push('effects')
                    } else if (entry === 'chemistry') {
                        newLeftDomains.push('chem')
                    } else if (entry === 'drug') {
                        newLeftDomains.push('drugs')
                    } else if (entry === 'natural products') {
                        newLeftDomains.push('natprod')
                    } else if (entry === 'protein') {
                        newLeftDomains.push('proteins')
                        newLeftDomains.push('humangenes')
                        //newLeftDomains.push('antibodies')
                    } else if (entry === 'disease') {
                        newLeftDomains.push('diseases')
                    } else if (entry === 'substance') {
                        newLeftDomains.push('substances')
                        newLeftDomains.push('natprod')
                        newLeftDomains.push('drugs')
                        newLeftDomains.push('chem')
                    } else if (entry === 'cellline') {
                        newLeftDomains.push('celllines')
                    } else {
                        newLeftDomains.push(entry)
                    }
                })
            }

            let subtreeOcids = ''

            if (this.props.leftDomains[0] !== 'all domains') {
                subtreeOcids = '230000035790, '
                newLeftDomains && newLeftDomains.length > 0 && newLeftDomains.forEach(dom => {
                    if (dom !== "effects")
                        subtreeOcids += dom + ', '
                })
                subtreeOcids = subtreeOcids.slice(0, -2)
            } else {
                subtreeOcids = '230000035790, species, chem, drugs, natprod, proteins, diseases, substances, humangenes, anatomy, celllines'
            }

            let array = []
            let arrayDom = []
            let arrayOCIDs = []
            let arrayDomains = []
            this.setState({
                query: queryNames,
                //relations: []
            }, async () => {
                if (queryNames.length === 0) {
                    this.setState({
                        ocidsQueryAuto: [],
                        ocidsQueryAutoRemove: [],
                        arrayDomains: [],
                        autocompleteOrBrowserStart: []
                    }, () =>
                        this.props.callbackFromStartTermChange(this.state.query, this.state.ocidsQueryAuto, this.state.ocidsQueryAuto, this.props.number + 1, this.state.arrayDomains, this.state.autocompleteOrBrowserStart, false))
                } else if (queryNames.length >= this.state.startProps.startTermShow.length) {
                    let operationsCompleted = 0;
                    function operation() {
                        ++operationsCompleted;
                        arrayOCIDs = []
                        arrayDomains = []
                        if (operationsCompleted === queryNames.length) {
                            self.setState({
                                ocidsQuery: arrayOCIDs
                            }, () => self.props.callbackFromStartTermChange(self.state.query, arrayOCIDs, completeArray, self.props.number + 1, completeDomainArray, autocompleteOrBrowserStart, false))
                        }
                    }
                    let completeArray = []
                    let completeDomainArray = []
                    let autocompleteOrBrowserStart = this.props.autocompleteOrBrowserStart

                    for (let i = 0; i < queryNames.length; i++) {
                        var self = this;
                        if (autocompleteOrBrowserStart[i] === undefined || autocompleteOrBrowserStart[i] === "autocomplete") {
                            autocompleteOrBrowserStart[i] = 'autocomplete'

                            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions`, {
                                termFragment:
                                    e.value[i].term ? e.value[i].term : e.value[i], mergeDomains: true, withDirectMatches: true,
                                domains: self.props.logicals === 'free' ? ['chem', 'proteins', 'diseases', 'effects'] :
                                    (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains') || (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains') ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains') || (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains') ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains') ? newLeftDomains :
                                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') || (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                                            (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') || (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                                            (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ? ['chem', 'drugs', 'natprod', 'substances', 'proteins', 'effects', 'diseases', 'species', 'humangenes', 'anatomy', 'celllines'] :
                                            self.props.logicals === 'caa' ? ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cip' || self.props.logicals === 'ctd' ?
                                                ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cid' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                    self.props.logicals === 'pid' ? ['proteins'] : self.props.logicals === 'cic' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                        self.props.logicals === 'pmc' || self.props.logicals === 'paa' ? ['proteins'] : self.props.logicals === 'cipp' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                            self.props.logicals === 'pmpp' ? ['proteins'] : self.props.logicals === 'ppmp' || self.props.logicals === 'ppaa' ? ['effects'] :
                                                                self.props.logicals === 'ppipp' ? ['effects'] : self.props.logicals === 'pppd' ? ['effects'] : self.props.logicals === 'dmp' ||
                                                                    self.props.logicals === 'daa' ? ['diseases'] : [],
                                namedFilter:
                                    (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        self.props.logicals === 'ppmp' || self.props.logicals === 'ppipp' || self.props.logicals === 'ppaa' || self.props.logicals === 'pppd' ||
                                        self.props.logicals === 'free' ? 'ocidSubtrees' : '',
                                namedFilterParameters:
                                    (self.props.logicals === 'aaa' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aaa' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aac' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aac' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aad' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aap' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aap' && self.props.leftDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] !== 'all domains' && newLeftDomains.includes('effects')) ||
                                        (self.props.logicals === 'aapp' && self.props.leftDomains[0] === 'all domains') ||
                                        self.props.logicals === 'ppmp' || self.props.logicals === 'ppipp' || self.props.logicals === 'ppaa' || self.props.logicals === 'pppd' ?
                                        { allowedSubtreesRootOcids: subtreeOcids }
                                        : self.props.logicals === 'free' ? { allowedSubtreesRootOcids: '150000001799,201000010099,108020003292,230000035790' }
                                            : {}
                            }, { headers: getHeaderToken() })
                                // eslint-disable-next-line
                                .then(function (response) {
                                    let ocids = {}
                                    if (!response.data.suggestedTerms) {
                                        self.setState({
                                            suggestedTerms: [],
                                            directFragmentMatches: {}
                                        })
                                        if (e.value[i].term.includes(' (Text exact)')) {
                                            ocids = {
                                                "+tr": e.value[i].term.replace(' (Text exact)', '')
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        } else if (e.value[i].term.includes(' (Text with variants)')) {
                                            ocids = {
                                                "+t": e.value[i].term.replace(' (Text with variants)', '')
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        } else {
                                            ocids = {
                                                "+struct": e.value[i].term
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        }
                                    } else {
                                        self.setState({
                                            suggestedTerms: [],
                                            directFragmentMatches: {}
                                        })
                                        if (response.data.directFragmentMatches && Object.keys(response.data.directFragmentMatches).length > 0) {
                                            Object.keys(response.data.directFragmentMatches).forEach(entry => {
                                                arrayDomains = [...arrayDomains, entry]
                                                for (let k = 0; k < response.data.directFragmentMatches[entry].length; k++) {
                                                    ocids = {
                                                        "+ocid": response.data.directFragmentMatches[entry][k].ocid.toString()
                                                    }
                                                    arrayOCIDs = [...arrayOCIDs, ocids]
                                                }
                                            })
                                        } else {
                                            let term = e.value[i].term ? e.value[i].term : e.value[i]
                                            response.data.suggestedTerms.filter(filter => filter.term === term).forEach(term => {
                                                array = term.ocids
                                                arrayDom = term.domains
                                                for (var j = 0; j < array.length; j++) {
                                                    ocids = {
                                                        "+ocid": array[j].toString()
                                                    }
                                                    arrayOCIDs = [...arrayOCIDs, ocids]
                                                }
                                                for (var k = 0; k < arrayDom.length; k++) {
                                                    arrayDomains = [...arrayDomains, arrayDom[k]]
                                                }
                                            })
                                        }

                                    }
                                    completeArray = [...completeArray, arrayOCIDs]
                                    completeDomainArray = [...completeDomainArray, arrayDomains]
                                    operation();
                                })

                        } else if (autocompleteOrBrowserStart[i] === "browser") {
                            completeDomainArray = [...completeDomainArray, this.props.startTermDomains[i]]
                            completeArray = [...completeArray, this.props.startTermOCID[i]]
                            operation()
                        }
                    }
                }
            }
            )
        }
    }

    filterEndTerm = (e) => {
        let newRightDomains = []

        if (this.props.rightDomains[0] !== 'all domains') {
            this.props.rightDomains.forEach(entry => {
                //subtreeOcids += entry + ', '
                if (entry === 'effects or process') {
                    newRightDomains.push('effects')
                } else if (entry === 'chemistry') {
                    newRightDomains.push('chem')
                } else if (entry === 'drug') {
                    newRightDomains.push('drugs')
                } else if (entry === 'natural products') {
                    newRightDomains.push('natprod')
                } else if (entry === 'protein') {
                    newRightDomains.push('proteins')
                    newRightDomains.push('humangenes')
                    //newRightDomains.push('antibodies')
                } else if (entry === 'disease') {
                    newRightDomains.push('diseases')
                } else if (entry === 'substance') {
                    newRightDomains.push('substances')
                    newRightDomains.push('natprod')
                    newRightDomains.push('drugs')
                    newRightDomains.push('chem')
                } else if (entry === 'cellline') {
                    newRightDomains.push('celllines')
                } else {
                    newRightDomains.push(entry)
                }
            })
        }

        let subtreeOcids = ''

        if (this.props.rightDomains[0] !== 'all domains') {
            subtreeOcids = '230000035790, '
            newRightDomains && newRightDomains.length > 0 && newRightDomains.forEach(dom => {
                if (dom !== "effects")
                    subtreeOcids += dom + ', '
            })
            subtreeOcids = subtreeOcids.slice(0, -2)
        } else {
            subtreeOcids = '230000035790, species, chem, drugs, natprod, proteins, diseases, substances, humangenes, anatomy, celllines'
        }

        let selectedRepositories = []
        this.props.userData.userDetails.department?.selectedRepositories &&
            this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
                selectedRepositories = [...selectedRepositories, rep.id]
            })
        this.setState({
            part: e.query
        }, () => {
            var self = this;
            axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions`, {
                termFragment:
                    self.state.part, mergeDomains: true, withDirectMatches: true,
                withPrefname: true,
                domains: self.props.logicals === 'free' ? ['chem', 'proteins', 'diseases', 'effects'] :
                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains') || (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains') ||
                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains') || (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains') ||
                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains') ? newRightDomains :
                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') || (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                            (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') || (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                            (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ? ['chem', 'drugs', 'natprod', 'substances', 'proteins', 'effects', 'diseases', 'species', 'humangenes', 'anatomy', 'celllines'] :
                            self.props.logicals === 'aac' ? ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cip' ?
                                ['proteins'] : self.props.logicals === 'cid' || self.props.logicals === 'aad' ? ['diseases'] :
                                    self.props.logicals === 'pid' || self.props.logicals === 'pppd' || self.props.logicals === 'ctd' ? ['diseases'] : self.props.logicals === 'cic' || self.props.logicals === 'pmc' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                        self.props.logicals === 'aap' || self.props.logicals === 'ppmp' || self.props.logicals === 'dmp' ? ['proteins'] : self.props.logicals === 'cipp' ? ['effects'] :
                                            self.props.logicals === 'pmpp' ? ['effects'] :
                                                self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ? ['effects'] :
                                                    [],
                namedFilter:
                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        self.props.logicals === 'pmpp' || self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ||
                        self.props.logicals === 'cipp' || self.props.logicals === 'free' ? 'ocidSubtrees' : '',
                namedFilterParameters:
                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                        self.props.logicals === 'pmpp' || self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ||
                        self.props.logicals === 'cipp' || self.props.logicals === 'free' ?
                        { allowedSubtreesRootOcids: subtreeOcids }
                        : {}
            }, { headers: getHeaderToken() })
                // eslint-disable-next-line
                .then(function (response) {
                    self.setState({
                        suggestedTerms: response.data.suggestedTerms,
                        directFragmentMatches: response.data.directFragmentMatches
                    })
                }
                )
        })
    }

    handleEndTermChangeAfterOK = async (query) => {
        let newRightDomains = []

        if (this.props.rightDomains[0] !== 'all domains') {
            this.props.rightDomains.forEach(entry => {
                //subtreeOcids += entry + ', '
                if (entry === 'effects or process') {
                    newRightDomains.push('effects')
                } else if (entry === 'chemistry') {
                    newRightDomains.push('chem')
                } else if (entry === 'drug') {
                    newRightDomains.push('drugs')
                } else if (entry === 'natural products') {
                    newRightDomains.push('natprod')
                } else if (entry === 'protein') {
                    newRightDomains.push('proteins')
                    newRightDomains.push('humangenes')
                    //newRightDomains.push('antibodies')
                } else if (entry === 'disease') {
                    newRightDomains.push('diseases')
                } else if (entry === 'substance') {
                    newRightDomains.push('substances')
                    newRightDomains.push('natprod')
                    newRightDomains.push('drugs')
                    newRightDomains.push('chem')
                } else if (entry === 'cellline') {
                    newRightDomains.push('celllines')
                } else {
                    newRightDomains.push(entry)
                }
            })
        }

        let subtreeOcids = ''

        if (this.props.rightDomains[0] !== 'all domains') {
            subtreeOcids = '230000035790, '
            newRightDomains && newRightDomains.length > 0 && newRightDomains.forEach(dom => {
                if (dom !== "effects")
                    subtreeOcids += dom + ', '
            })
            subtreeOcids = subtreeOcids.slice(0, -2)
        } else {
            subtreeOcids = '230000035790, species, chem, drugs, natprod, proteins, diseases, substances, humangenes, anatomy, celllines'
        }

        let selectedRepositories = []
        this.props.userData.userDetails.department?.selectedRepositories &&
            this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
                selectedRepositories = [...selectedRepositories, rep.id]
            })

        let array = []
        let arrayDom = []
        let arrayOCIDs = []
        let arrayDomains = []

        this.setState({
            query: query,
            relations: []
        }, async () => {
            if (query.length === 0) {
                this.setState({
                    ocidsQueryAuto: "",
                    ocidsQueryAutoRemove: "",
                    //ocidsQueryAuto: [],
                    //ocidsQueryAutoRemove: [],
                    arrayDomains: [],
                    autocompleteOrBrowserEnd: [],
                    displayDialogEditEntity: false,
                    displayDialogEditRelation: false
                }, () =>
                    this.props.callbackFromEndTermChange(this.state.query, this.state.ocidsQueryAuto, this.state.ocidsQueryAuto, this.props.number + 1, this.state.arrayDomains, this.state.autocompleteOrBrowserEnd, true, this.state.selectedMode))
            } else {
                let operationsCompleted = 0;
                function operation() {
                    ++operationsCompleted;
                    arrayOCIDs = []
                    arrayDomains = []
                    if (operationsCompleted === query.length) {
                        self.setState({
                            ocidsQuery: arrayOCIDs,
                            displayDialogEditEntity: false,
                            displayDialogEditRelation: false
                        }, () => self.props.callbackFromEndTermChange(self.state.query, arrayOCIDs, completeArray, self.props.number + 1, completeDomainArray, autocompleteOrBrowserEnd, true, self.state.selectedMode))
                    }
                }
                let completeArray = []
                let completeDomainArray = []
                let autocompleteOrBrowserEnd = this.props.autocompleteOrBrowserEnd
                let domainsEnd = this.props.endTermDomains
                let endTerms = this.props.endTerm
                let endTermOCIDS = this.props.endTermOCID
                if (query.length < this.props.endTerm.length) {
                    let missing = endTerms.filter(function (v) {
                        return !query.includes(v)
                    })
                    let missingIndex = endTerms.indexOf(missing[0])
                    autocompleteOrBrowserEnd.splice(missingIndex, 1)
                    endTerms.splice(missingIndex, 1)
                    domainsEnd.splice(missingIndex, 1)
                    endTermOCIDS.splice(missingIndex, 1)
                    this.props.callbackFromEndTermChange(endTerms, [endTermOCIDS], endTermOCIDS, this.props.number + 1, domainsEnd, autocompleteOrBrowserEnd, true)
                } else {
                    for (let i = 0; i < query.length; i++) {
                        var self = this;
                        if (autocompleteOrBrowserEnd[i] === undefined || autocompleteOrBrowserEnd[i] === "autocomplete") {
                            autocompleteOrBrowserEnd[i] = 'autocomplete'

                            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions`, {
                                termFragment:
                                    query[i], mergeDomains: true, withDirectMatches: true,
                                domains: self.props.logicals === 'free' ? ['chem', 'proteins', 'diseases', 'effects'] :
                                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains') || (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains') ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains') || (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains') ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains') ? newRightDomains :
                                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') || (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                                            (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') || (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                                            (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ? ['chem', 'drugs', 'natprod', 'substances', 'proteins', 'effects', 'diseases', 'species', 'humangenes', 'anatomy', 'celllines'] :
                                            self.props.logicals === 'aac' ? ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cip' ?
                                                ['proteins'] : self.props.logicals === 'cid' || self.props.logicals === 'aad' ? ['diseases'] :
                                                    self.props.logicals === 'pid' || self.props.logicals === 'pppd' || self.props.logicals === 'ctd' ? ['diseases'] : self.props.logicals === 'cic' || self.props.logicals === 'pmc' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                        self.props.logicals === 'aap' || self.props.logicals === 'ppmp' || self.props.logicals === 'dmp' ? ['proteins'] : self.props.logicals === 'cipp' ? ['effects'] :
                                                            self.props.logicals === 'pmpp' ? ['effects'] :
                                                                self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ? ['effects'] :
                                                                    [],
                                namedFilter:
                                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        self.props.logicals === 'pmpp' || self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ||
                                        self.props.logicals === 'cipp' || self.props.logicals === 'free' ? 'ocidSubtrees' : '',
                                namedFilterParameters:
                                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        self.props.logicals === 'pmpp' || self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ||
                                        self.props.logicals === 'cipp' || self.props.logicals === 'free' ?
                                        { allowedSubtreesRootOcids: subtreeOcids }
                                        : {}
                            }
                                , { headers: getHeaderToken() })
                                // eslint-disable-next-line
                                .then(function (response) {
                                    let ocids = {}
                                    if (!response.data.suggestedTerms) {
                                        self.setState({
                                            suggestedTerms: [],
                                            directFragmentMatches: {}
                                        })
                                        if (query[i].includes(' (Text exact)')) {
                                            ocids = {
                                                "+tr": query[i].replace(' (Text exact)', '')
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        } else if (query[i].includes(' (Text with variants)')) {
                                            ocids = {
                                                "+t": query[i].replace(' (Text with variants)', '')
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        } else {
                                            ocids = {
                                                "+struct": query[i]
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        }
                                    } else {
                                        self.setState({
                                            suggestedTerms: response.data.suggestedTerms,
                                            directFragmentMatches: response.data.directFragmentMatches
                                        })
                                        if (response.data.directFragmentMatches && Object.keys(response.data.directFragmentMatches).length > 0) {
                                            Object.keys(response.data.directFragmentMatches).forEach(entry => {
                                                arrayDomains = [...arrayDomains, entry]
                                                for (let k = 0; k < response.data.directFragmentMatches[entry].length; k++) {
                                                    ocids = {
                                                        "+ocid": response.data.directFragmentMatches[entry][k].ocid.toString()
                                                    }
                                                    arrayOCIDs = [...arrayOCIDs, ocids]
                                                }
                                            })
                                        } else {
                                            response.data.suggestedTerms.filter(filter => filter.term === query[i]).forEach(term => {
                                                array = term.ocids
                                                arrayDom = term.domains
                                                for (var j = 0; j < array.length; j++) {
                                                    ocids = {
                                                        "+ocid": array[j].toString()
                                                    }
                                                    arrayOCIDs = [...arrayOCIDs, ocids]
                                                }
                                                for (var k = 0; k < arrayDom.length; k++) {
                                                    arrayDomains = [...arrayDomains, arrayDom[k]]
                                                }
                                            })
                                        }
                                    }
                                    completeArray = [...completeArray, arrayOCIDs]
                                    completeDomainArray = [...completeDomainArray, arrayDomains]
                                    operation();
                                })
                        } else if (autocompleteOrBrowserEnd[i] === "browser") {
                            completeDomainArray = [...completeDomainArray, this.props.endTermDomains[i]]
                            completeArray = [...completeArray, this.props.endTermOCID[i]]
                            operation()
                        }
                    }
                }
            }
        }
        )
    }

    handleEndTermChange = async (e) => {

        let queryNames = []
        if (e.value.length !== 0) {
            e.value.forEach(term => {
                if (term.term) {
                    queryNames = [...queryNames, term.term]
                } else {
                    queryNames = [...queryNames, term]
                }
            })
        }
        if (queryNames.length < this.state.endProps.endTermShow.length && queryNames.length !== 0) {
            let domainsEnd = JSON.parse(JSON.stringify(this.props.endTermDomains))
            let endTerms = JSON.parse(JSON.stringify(this.props.endTerm))
            let endTermOCIDS = JSON.parse(JSON.stringify(this.props.endTermOCID))
            let autocompleteOrBrowserEnd = JSON.parse(JSON.stringify(this.props.autocompleteOrBrowserEnd))
            let missing = endTerms.filter(function (v) {
                return !queryNames.includes(v)
            })
            let missingIndex = endTerms.indexOf(missing[0])
            autocompleteOrBrowserEnd.splice(missingIndex, 1)
            endTerms.splice(missingIndex, 1)
            domainsEnd.splice(missingIndex, 1)
            endTermOCIDS.splice(missingIndex, 1)
            this.props.callbackFromEndTermChange(endTerms, [endTermOCIDS], endTermOCIDS, this.props.number + 1, domainsEnd, autocompleteOrBrowserEnd, false)
        } else {
            let newRightDomains = []
            if (this.props.rightDomains[0] !== 'all domains') {
                this.props.rightDomains.forEach(entry => {
                    //subtreeOcids += entry + ', '
                    if (entry === 'effects or process') {
                        newRightDomains.push('effects')
                    } else if (entry === 'chemistry') {
                        newRightDomains.push('chem')
                    } else if (entry === 'drug') {
                        newRightDomains.push('drugs')
                    } else if (entry === 'natural products') {
                        newRightDomains.push('natprod')
                    } else if (entry === 'protein') {
                        newRightDomains.push('proteins')
                        newRightDomains.push('humangenes')
                        //newRightDomains.push('antibodies')
                    } else if (entry === 'disease') {
                        newRightDomains.push('diseases')
                    } else if (entry === 'substance') {
                        newRightDomains.push('substances')
                        newRightDomains.push('natprod')
                        newRightDomains.push('drugs')
                        newRightDomains.push('chem')
                    } else if (entry === 'cellline') {
                        newRightDomains.push('celllines')
                    } else {
                        newRightDomains.push(entry)
                    }
                })
            }

            let subtreeOcids = ''

            if (this.props.rightDomains[0] !== 'all domains') {
                subtreeOcids = '230000035790, '
                newRightDomains && newRightDomains.length > 0 && newRightDomains.forEach(dom => {
                    if (dom !== "effects")
                        subtreeOcids += dom + ', '
                })
                subtreeOcids = subtreeOcids.slice(0, -2)
            } else {
                subtreeOcids = '230000035790, species, chem, drugs, natprod, proteins, diseases, substances, humangenes, anatomy, celllines'
            }

            let selectedRepositories = []
            this.props.userData.userDetails.department?.selectedRepositories &&
                this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
                    selectedRepositories = [...selectedRepositories, rep.id]
                })

            let array = []
            let arrayDom = []
            let arrayOCIDs = []
            let arrayDomains = []

            this.setState({
                query: queryNames,
                //relations: []
            }, async () => {
                if (queryNames.length === 0) {
                    this.setState({
                        ocidsQueryAuto: "",
                        ocidsQueryAutoRemove: "",
                        //ocidsQueryAuto: [],
                        //ocidsQueryAutoRemove: [],
                        arrayDomains: [],
                        autocompleteOrBrowserEnd: []
                    }, () =>
                        this.props.callbackFromEndTermChange(this.state.query, this.state.ocidsQueryAuto, this.state.ocidsQueryAuto, this.props.number + 1, this.state.arrayDomains, this.state.autocompleteOrBrowserEnd, false))
                } else if (queryNames.length >= this.state.endProps.endTermShow.length) {
                    let operationsCompleted = 0;
                    function operation() {
                        ++operationsCompleted;
                        arrayOCIDs = []
                        arrayDomains = []
                        if (operationsCompleted === queryNames.length) {
                            self.setState({
                                ocidsQuery: arrayOCIDs
                            }, () => self.props.callbackFromEndTermChange(self.state.query, arrayOCIDs, completeArray, self.props.number + 1, completeDomainArray, autocompleteOrBrowserEnd, false))
                        }
                    }
                    let completeArray = []
                    let completeDomainArray = []
                    let autocompleteOrBrowserEnd = this.props.autocompleteOrBrowserEnd

                    for (let i = 0; i < queryNames.length; i++) {
                        var self = this;
                        if (autocompleteOrBrowserEnd[i] === undefined || autocompleteOrBrowserEnd[i] === "autocomplete") {
                            autocompleteOrBrowserEnd[i] = 'autocomplete'

                            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions`, {
                                termFragment:
                                    e.value[i].term ? e.value[i].term : e.value[i], mergeDomains: true, withDirectMatches: true,
                                domains: self.props.logicals === 'free' ? ['chem', 'proteins', 'diseases', 'effects'] :
                                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains') || (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains') ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains') || (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains') ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains') ? newRightDomains :
                                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') || (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                                            (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') || (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                                            (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ? ['chem', 'drugs', 'natprod', 'substances', 'proteins', 'effects', 'diseases', 'species', 'humangenes', 'anatomy', 'celllines'] :
                                            self.props.logicals === 'aac' ? ['chem', 'drugs', 'natprod', 'substances'] : self.props.logicals === 'cip' ?
                                                ['proteins'] : self.props.logicals === 'cid' || self.props.logicals === 'aad' ? ['diseases'] :
                                                    self.props.logicals === 'pid' || self.props.logicals === 'pppd' || self.props.logicals === 'ctd' ? ['diseases'] : self.props.logicals === 'cic' || self.props.logicals === 'pmc' ? ['chem', 'drugs', 'natprod', 'substances'] :
                                                        self.props.logicals === 'aap' || self.props.logicals === 'ppmp' || self.props.logicals === 'dmp' ? ['proteins'] : self.props.logicals === 'cipp' ? ['effects'] :
                                                            self.props.logicals === 'pmpp' ? ['effects'] :
                                                                self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ? ['effects'] :
                                                                    [],
                                namedFilter:
                                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        self.props.logicals === 'pmpp' || self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ||
                                        self.props.logicals === 'cipp' || self.props.logicals === 'free' ? 'ocidSubtrees' : '',
                                namedFilterParameters:
                                    (self.props.logicals === 'aaa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'aaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'caa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'caa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'daa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'paa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'paa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] === 'all domains') ||
                                        (self.props.logicals === 'ppaa' && self.props.rightDomains[0] !== 'all domains' && newRightDomains.includes('effects')) ||
                                        self.props.logicals === 'pmpp' || self.props.logicals === 'ppipp' || self.props.logicals === 'aapp' ||
                                        self.props.logicals === 'cipp' || self.props.logicals === 'free' ?
                                        { allowedSubtreesRootOcids: subtreeOcids }
                                        : {}
                            }
                                , { headers: getHeaderToken() })
                                // eslint-disable-next-line
                                .then(function (response) {
                                    let ocids = {}
                                    if (!response.data.suggestedTerms) {
                                        self.setState({
                                            suggestedTerms: [],
                                            directFragmentMatches: {}
                                        })
                                        if (e.value[i].term.includes(' (Text exact)')) {
                                            ocids = {
                                                "+tr": e.value[i].term.replace(' (Text exact)', '')
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        } else if (e.value[i].term.includes(' (Text with variants)')) {
                                            ocids = {
                                                "+t": e.value[i].term.replace(' (Text with variants)', '')
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        } else {
                                            ocids = {
                                                "+struct": e.value[i].term
                                            }
                                            arrayOCIDs = [...arrayOCIDs, ocids]
                                        }
                                    } else {
                                        self.setState({
                                            suggestedTerms: [],
                                            directFragmentMatches: {}
                                        })
                                        if (response.data.directFragmentMatches && Object.keys(response.data.directFragmentMatches).length > 0) {
                                            Object.keys(response.data.directFragmentMatches).forEach(entry => {
                                                arrayDomains = [...arrayDomains, entry]
                                                for (let k = 0; k < response.data.directFragmentMatches[entry].length; k++) {
                                                    ocids = {
                                                        "+ocid": response.data.directFragmentMatches[entry][k].ocid.toString()
                                                    }
                                                    arrayOCIDs = [...arrayOCIDs, ocids]
                                                }
                                            })
                                        } else {
                                            let term = e.value[i].term ? e.value[i].term : e.value[i]
                                            response.data.suggestedTerms.filter(filter => filter.term === term).forEach(term => {
                                                array = term.ocids
                                                arrayDom = term.domains
                                                for (var j = 0; j < array.length; j++) {
                                                    ocids = {
                                                        "+ocid": array[j].toString()
                                                    }
                                                    arrayOCIDs = [...arrayOCIDs, ocids]
                                                }
                                                for (var k = 0; k < arrayDom.length; k++) {
                                                    arrayDomains = [...arrayDomains, arrayDom[k]]
                                                }
                                            })
                                        }
                                    }
                                    completeArray = [...completeArray, arrayOCIDs]
                                    completeDomainArray = [...completeDomainArray, arrayDomains]
                                    operation();
                                })
                        } else if (autocompleteOrBrowserEnd[i] === "browser") {
                            completeDomainArray = [...completeDomainArray, this.props.endTermDomains[i]]
                            completeArray = [...completeArray, this.props.endTermOCID[i]]
                            operation()
                        }
                    }
                }
            }
            )
        }
    }

    myCallbackFromTypeRadioChange = (type, pathNumber) => {
        this.props.callbackFromTypeRadioChange(type, pathNumber)
    }

    showFailGrowlSuggestions = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'No suggestions available. Maybe there is a problem with the backend. Please contact us.', life: 6000 };
        this.growl.show(msg);
    }

    onRemoveClick = () => {
        this.props.callbackFromRemove(this.props.number + 1)
    }

    onTripleSelect = (e) => {
        this.newTriple = false;
        this.setState({
            displayDialog: true,
            triple: Object.assign({}, e.data)
        });
    }

    onDomainClick = (e, location) => {
        let newLeftDomains = []
        if (this.props.leftDomains[0] !== 'all domains') {
            this.props.leftDomains.forEach(entry => {
                //subtreeOcids += entry + ', '
                if (entry === 'effects or process') {
                    newLeftDomains.push('effects')
                } else if (entry === 'chemistry') {
                    newLeftDomains.push('chem')
                } else if (entry === 'drug') {
                    newLeftDomains.push('drugs')
                } else if (entry === 'natural products') {
                    newLeftDomains.push('natprod')
                } else if (entry === 'protein') {
                    newLeftDomains.push('proteins')
                    newLeftDomains.push('humangenes')
                    //newLeftDomains.push('antibodies')
                } else if (entry === 'disease') {
                    newLeftDomains.push('diseases')
                } else if (entry === 'substance') {
                    newLeftDomains.push('substances')
                    newLeftDomains.push('natprod')
                    newLeftDomains.push('drugs')
                    newLeftDomains.push('chem')
                } else if (entry === 'cellline') {
                    newLeftDomains.push('celllines')
                } else {
                    newLeftDomains.push(entry)
                }
            })
        }

        let newRightDomains = []
        if (this.props.rightDomains[0] !== 'all domains') {
            this.props.rightDomains.forEach(entry => {
                //subtreeOcids += entry + ', '
                if (entry === 'effects or process') {
                    newRightDomains.push('effects')
                } else if (entry === 'chemistry') {
                    newRightDomains.push('chem')
                } else if (entry === 'drug') {
                    newRightDomains.push('drugs')
                } else if (entry === 'natural products') {
                    newRightDomains.push('natprod')
                } else if (entry === 'protein') {
                    newRightDomains.push('proteins')
                    newRightDomains.push('humangenes')
                    //newRightDomains.push('antibodies')
                } else if (entry === 'disease') {
                    newRightDomains.push('diseases')
                } else if (entry === 'substance') {
                    newRightDomains.push('substances')
                    newRightDomains.push('natprod')
                    newRightDomains.push('drugs')
                    newRightDomains.push('chem')
                } else if (entry === 'cellline') {
                    newRightDomains.push('celllines')
                } else {
                    newRightDomains.push(entry)
                }
            })
        }

        let domain

        if ((location === 'start' && this.props.logicals === 'ctd') || (location === 'start' && this.props.logicals === 'cip') ||
            (location === 'start' && this.props.logicals === 'cid') || (location === 'start' && this.props.logicals === 'cic') ||
            (location === 'start' && this.props.logicals === 'cipp') ||
            (location === 'start' && this.props.logicals === 'caa')) {
            //domain = 'chem'
            domain = ['chem', 'natprod', 'substances', 'drugs']
        } else if ((location === 'start' && this.props.logicals === 'pid') || (location === 'start' && this.props.logicals === 'pmc') ||
            (location === 'start' && this.props.logicals === 'pmpp') ||
            (location === 'start' && this.props.logicals === 'paa')) {
            domain = 'proteins'
        } else if ((location === 'start' && this.props.logicals === 'ppmp') || (location === 'start' && this.props.logicals === 'ppipp') || (location === 'start' && this.props.logicals === 'pppd')
            || (location === 'start' && this.props.logicals === 'ppaa')) {
            domain = 'effects'
        } else if ((location === 'start' && this.props.logicals === 'dmp') || (location === 'start' && this.props.logicals === 'daa')) {
            domain = 'diseases'
        } else if (((location === 'start' && this.props.logicals === 'aaa') || (location === 'start' && this.props.logicals === 'aac') || (location === 'start' && this.props.logicals === 'aad') || (location === 'start' && this.props.logicals === 'aapp') || (location === 'start' && this.props.logicals === 'aap')) && this.props.leftDomains[0] === 'all domains') {
            domain = ['chem', 'natprod', 'substances', 'drugs', 'proteins', 'effects', 'diseases', 'species', 'humangenes', 'anatomy', 'celllines']
        }
        else if (((location === 'start' && this.props.logicals === 'aaa') || (location === 'start' && this.props.logicals === 'aac') || (location === 'start' && this.props.logicals === 'aad') || (location === 'start' && this.props.logicals === 'aapp') || (location === 'start' && this.props.logicals === 'aap')) && this.props.leftDomains[0] !== 'all domains') {
            domain = newLeftDomains
        }

        if ((location === 'end' && this.props.logicals === 'ctd') || (location === 'end' && this.props.logicals === 'cid') ||
            (location === 'end' && this.props.logicals === 'pid') ||
            (location === 'end' && this.props.logicals === 'pppd') ||
            (location === 'end' && this.props.logicals === 'aad')) {
            domain = 'diseases'
        } else if ((location === 'end' && this.props.logicals === 'cip') || (location === 'end' && this.props.logicals === 'dmp') ||
            (location === 'end' && this.props.logicals === 'ppmp') ||
            (location === 'end' && this.props.logicals === 'aap')) {
            domain = 'proteins'
        } else if ((location === 'end' && this.props.logicals === 'cic') || (location === 'end' && this.props.logicals === 'pmc') ||
            (location === 'end' && this.props.logicals === 'aac')) {
            domain = ['chem', 'natprod', 'substances', 'drugs']
        } else if ((location === 'end' && this.props.logicals === 'cipp') ||
            (location === 'end' && this.props.logicals === 'aapp') || (location === 'end' && this.props.logicals === 'pmpp') || (location === 'end' && this.props.logicals === 'ppipp')) {
            domain = 'effects'
        } else if (((location === 'end' && this.props.logicals === 'aaa') || (location === 'end' && this.props.logicals === 'caa') || (location === 'end' && this.props.logicals === 'daa') || (location === 'end' && this.props.logicals === 'ppaa') || (location === 'end' && this.props.logicals === 'paa')) && this.props.rightDomains[0] === 'all domains') {
            domain = ['chem', 'natprod', 'substances', 'drugs', 'proteins', 'effects', 'diseases', 'species', 'humangenes', 'anatomy', 'celllines']
        } else if (((location === 'end' && this.props.logicals === 'aaa') || (location === 'end' && this.props.logicals === 'caa') || (location === 'end' && this.props.logicals === 'daa') || (location === 'end' && this.props.logicals === 'ppaa') || (location === 'end' && this.props.logicals === 'paa')) && this.props.rightDomains[0] !== 'all domains') {
            domain = newRightDomains
        }

        this.setState({
            displayDialogDomains: true,
            domain: domain,
            domainLocation: location,
            showOntologyBrowser: true
        })
    }

    myCallbackFromSubmit = (concepts) => {
        let replaceItem = this.state.replaceItem
        let replaceIndex = this.state.replaceIndex
        this.setState({
            displayDialogDomains: false,
            replaceItem: null,
            replaceIndex: null,
            initialSearchTerm: null
        }, () => this.props.callbackFromDomainBrowser(concepts, this.state.domainLocation, this.props.number + 1, replaceItem, replaceIndex))
    }

    handleRelationMultiChange = (e) => {
        let keys = []
        let relationNames = []
        e.value.forEach(val => {
            if (this.props.relationOCIDMulti.hasOwnProperty(val)) {
                if (keys.length > 0) {
                    let newKeys = Object.keys(this.props.relationOCIDMulti).filter(fil => this.props.relationOCIDMulti[fil].replace(/ *\[[^\]]*]/g, '').trim() === this.props.relationOCIDMulti[val].replace(/ *\[[^\]]*]/g, '').trim())
                    keys = keys.concat(newKeys)
                    keys.forEach(key => {
                        if (!relationNames.includes(this.props.relationOCIDMulti[key].replace(/ *\[[^\]]*]/g, '').trim())) {
                            relationNames.push(this.props.relationOCIDMulti[key].replace(/ *\[[^\]]*]/g, '').trim())
                        }
                    })
                } else {
                    keys = Object.keys(this.props.relationOCIDMulti).filter(fil => this.props.relationOCIDMulti[fil].replace(/ *\[[^\]]*]/g, '').trim() === this.props.relationOCIDMulti[val].replace(/ *\[[^\]]*]/g, '').trim())
                    keys.forEach(key => {
                        if (!relationNames.includes(this.props.relationOCIDMulti[key].replace(/ *\[[^\]]*]/g, '').trim())) {
                            relationNames.push(this.props.relationOCIDMulti[key].replace(/ *\[[^\]]*]/g, '').trim())
                        }
                    })
                }
            }
        })

        let matchesLeft = []
        let matchesRight = []
        keys.forEach(key => {
            Object.keys(this.props.relationOCIDMulti).forEach(entry => {
                if (key === entry) {
                    let singleMatch = this.props.relationOCIDMulti[entry].match(/\[(.*?)\]/g)
                    if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'physiology' ||
                        singleMatch[0].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'effect') {
                        matchesLeft.push("effects or process")
                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'alloy') {
                        matchesLeft.push("substance")
                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'animal disease') {
                        matchesLeft.push("disease")
                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'chem') {
                        matchesLeft.push("chemistry")
                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'bacterium'
                        || singleMatch[0].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'virus') {
                        matchesLeft.push("species")
                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'compound') {
                        matchesLeft.push("chemistry")
                        matchesLeft.push("drug")
                        matchesLeft.push("natural products")
                    } else {
                        matchesLeft.push(singleMatch[0].match(/\[(.*?)\]/)[1])
                    }

                    if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiology' ||
                        singleMatch[1].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'effect') {
                        matchesRight.push("effects or process")
                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'animal disease') {
                        matchesRight.push("disease")
                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alloy') {
                        matchesRight.push("substance")
                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'chem') {
                        matchesRight.push("chemistry")
                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'bacterium'
                        || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'virus') {
                        matchesRight.push("species")
                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'compound') {
                        matchesRight.push("chemistry")
                        matchesRight.push("drug")
                        matchesRight.push("natural products")
                    } else {
                        matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                    }
                }
            })
        })
        let uniqueLeft = [...new Set(matchesLeft)]
        let uniqueRight = [...new Set(matchesRight)]
        this.setState({
            relations: e.value
        }, () => this.props.callbackFromRelationMultiChange(keys, this.props.number + 1, uniqueLeft, uniqueRight, relationNames, e.value))
    }

    handleHopNumberChange = (value) => {
        this.props.callbackFromHopNumberChange(value)
    }

    onSearchWithHops = () => {
        this.props.callbackFromSearchWithHops(this.props.numberOfHops[0], this.props.numberOfHops[1])
    }

    selItemTemplateStart(item) {

        let index = this.counterStart
        let term = item.term;
        let info = ''
        let coloredDomains = []
        let coloredInfo

        if (this.props.startTermDomains && this.props.startTermDomains[index]) {
            const uniqueLabels = this.props.startTermDomains[index].map(dom =>
                this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                    this.props.domainLabelsMap[dom] : dom);
            uniqueLabels.sort().forEach(lab => {
                let color = this.props.domainColors && this.props.domainColors.find(obj => {
                    return obj.label === lab
                })
                if (color !== undefined) {
                    coloredDomains = [...coloredDomains, '#' + color.color]
                } else {
                    coloredDomains = [...coloredDomains, 'black']
                }
            })

            info = uniqueLabels.sort().join(', ');

            coloredInfo = uniqueLabels.sort().map((lab, i) => {
                let color = this.props.domainColors && this.props.domainColors.find(obj => {
                    return obj.label === lab
                })
                return <div key={`${lab}-${i}`} className="grid" style={{ margin: 0, paddingBottom: 5 }}>
                    <div key={`${lab}-${i + 1}`} style={{
                        width: 4, height: 11, border: '2px solid', borderColor: color !== undefined ? '#' + color.color : 'black',
                        background: color !== undefined ? '#' + color.color : 'black', marginRight: 4, marginTop: 1
                    }}></div>
                    <span style={{ paddingRight: 10 }}>{lab}</span>
                </div>
            })
        }

        if (this.counterStart < this.props.startTermDomains.length) {
            this.counterStart += 1
        }

        if (this.counterStart === this.props.startTermDomains.length) {
            this.counterStart = 0
        }

        let gradientString = ''
        let size = 100 / coloredDomains.length
        coloredDomains.length > 0 && coloredDomains.forEach((col, i) => {
            gradientString += col + ` ${size * i}%` + ',' + col + ` ${size * (i + 1)}% `
            if (i < coloredDomains.length - 1) {
                gradientString += ','
            }
        })

        const tooltipID = `tt-${item.term}-${item.domains?.join('-')}`.replace(/[^a-zA-Z0-9_-]/g, '_')

        return (
            <div className="grid search-field-item-with-domain-bar">
                {coloredDomains.length > 0 ?
                    <div className="search-field-item-domain-bar"
                        style={{ background: `linear-gradient(${gradientString})` }}
                    >
                    </div>
                    : null
                }
                <div className={`search-field-item-content ${tooltipID}`}>
                    <Tooltip className="whiteTooltip" target={`.${tooltipID}`} position={'bottom'}>
                        <div>
                            <div>
                                Open in domain explorer:
                            </div>
                            <div style={{ paddingTop: 4, paddingBottom: 7, fontWeight: 'bold' }}>{item.term}</div>
                            <div>{coloredInfo}</div>
                        </div>
                    </Tooltip>

                    <a className="noStyleLink"
                        onClick={(e) => { this.onOpenDomainExplorerToSpecifyTermStart(item.term, index) }}>
                        <div style={{ fontWeight: 'bold' }}>{term}</div>
                        <div style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis', margin: 0, color: '#818181', backgroundColor: ''
                        }}>{info}</div>
                    </a>
                </div>
            </div>

        )
    }

    selItemTemplateEnd(item) {

        //let index = this.props.endTerm.indexOf(item)
        let index = this.counterEnd
        let term = item.term;
        let info = ''
        let coloredDomains = []
        let coloredInfo

        if (this.props.endTermDomains && this.props.endTermDomains[index]) {
            const uniqueLabels = this.props.endTermDomains[index].map(dom =>
                this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                    this.props.domainLabelsMap[dom] : dom);
            uniqueLabels.sort().forEach(lab => {
                let color = this.props.domainColors && this.props.domainColors.find(obj => {
                    return obj.label === lab
                })
                if (color !== undefined) {
                    coloredDomains = [...coloredDomains, '#' + color.color]
                } else {
                    coloredDomains = [...coloredDomains, 'black']
                }
            })

            coloredInfo = uniqueLabels.sort().map((lab, i) => {
                let color = this.props.domainColors && this.props.domainColors.find(obj => {
                    return obj.label === lab
                })
                return <div key={`${lab}-${i}`} className="grid" style={{ margin: 0, paddingBottom: 5 }}>
                    <div key={`${lab}-${i + 1}`} style={{
                        width: 4, height: 11, border: '2px solid', borderColor: color !== undefined ? '#' + color.color : 'black',
                        background: color !== undefined ? '#' + color.color : 'black', marginRight: 4, marginTop: 1
                    }}></div>
                    <span style={{ paddingRight: 10 }}>{lab}</span>
                </div>
            })

            info = uniqueLabels.sort().join(', ');
        }

        if (this.counterEnd < this.props.endTermDomains.length) {
            this.counterEnd += 1
        }

        if (this.counterEnd === this.props.endTermDomains.length) {
            this.counterEnd = 0
        }

        let gradientString = ''
        let size = 100 / coloredDomains.length
        coloredDomains.length > 0 && coloredDomains.forEach((col, i) => {
            gradientString += col + ` ${size * i}%` + ',' + col + ` ${size * (i + 1)}% `
            if (i < coloredDomains.length - 1) {
                gradientString += ','
            }
        })

        const tooltipID = `tt-${item.term}-${item.domains?.join('-')}`.replace(/[^a-zA-Z0-9_-]/g, '_')

        return (
            <div className="grid search-field-item-with-domain-bar">
                {coloredDomains.length > 0 ?
                    <div className="search-field-item-domain-bar"
                        style={{ background: `linear-gradient(${gradientString})` }}
                    >
                    </div>
                    : null
                }
                <div className={`search-field-item-content ${tooltipID}`}>
                    <Tooltip className="whiteTooltip" target={`.${tooltipID}`} position={'bottom'}>
                        <div>
                            <div>
                                Click to open in domain explorer:
                            </div>
                            <div style={{ paddingTop: 4, paddingBottom: 7, fontWeight: 'bold' }}>{item.term}</div>
                            <div>{coloredInfo}</div>
                        </div>
                    </Tooltip>
                    <a className="noStyleLink"
                        onClick={(e) => { this.onOpenDomainExplorerToSpecifyTermEnd(item.term, index) }}>
                        <div style={{ fontWeight: 'bold' }}>{term}</div>
                        <div style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis', margin: 0, color: '#818181', backgroundColor: ''
                        }}>{info}</div>
                    </a>
                </div>
            </div>
        )

    }


    /*onAddRelationClick = () => {
        this.props.callbackFromFirstRelationClick(true)
    }*/

    openEditStartEntity = (e) => {
        e.stopPropagation()
        this.setState({
            startProps: this.props,
            displayDialogEditEntity: true,
            startEnd: 'start',
            entityNumber: this.props.number + 1,
            //selectedMode: { ...this.state.selectedMode, key: this.props.startChecked }
            selectedMode: this.props.startChecked
        })
    }

    openEditRelation = (e) => {
        e.stopPropagation()
        this.setState({
            startProps: this.props,
            displayDialogEditRelation: true,
            relatioNumber: this.props.number + 1,
            //selectedMode: { ...this.state.selectedMode, key: this.props.startChecked }
            relationMode: this.props.relationMode
        })
    }

    openEditEndEntity = (e) => {
        e.stopPropagation()
        this.setState({
            endProps: this.props,
            displayDialogEditEntity: true,
            startEnd: 'end',
            entityNumber: this.props.number + 2,
            //selectedMode: { ...this.state.selectedMode, key: this.props.endChecked }
            selectedMode: this.props.endChecked
        })
    }

    editEntity = () => {
        this.setState({
            displayDialogEditEntity: false
        })
    }

    onStartToggleChange = (e) => {
        if (this.props.startTerm.length !== 0) {
            this.setState({
                selectedMode: e.value.key
            }
                //, () => this.props.callbackFromStartToggleChange(e.value.key, this.props.number)
            )
        }
        /*if (this.props.startTerm !== '' && this.props.pathNumber === 0) {
            this.setState({
                checked1: e.value
            }, () => this.props.callbackFromStartToggleChange(this.state.checked1, this.props.pathNumber))
        }*/
    }

    onEndToggleChange = (e) => {
        if (this.props.endTerm.length !== 0) {
            this.setState({
                //endMode: e.value
                selectedMode: e.value.key
            }
                //, () => this.props.callbackFromEndToggleChange(e.value.key, this.props.number)
            )
        }
        /*if (this.props.endTerm !== '') {
            this.setState({
                checked2: e.value
            }, () => this.props.callbackFromEndToggleChange(this.state.checked2, this.props.pathNumber))
        }*/

    }

    onRelationToggleChange = (e) => {
        /*this.setState({
            relationMode: e.value.key
        })*/
        this.props.callbackFromRelationModeChange(e.value.key, this.props.number + 1)
    }

    clearStart = () => {
        this.setState({
            query: [],
            ocidsQueryAuto: [],
            ocidsQueryAutoRemove: [],
            arrayDomains: [],
            autocompleteOrBrowserStart: [],
            selectedMode: 'ontological'
        }, () =>
            this.props.callbackFromStartTermChange(this.state.query, this.state.ocidsQueryAuto, this.state.ocidsQueryAuto, this.props.number + 1, this.state.arrayDomains, this.state.autocompleteOrBrowserStart, false))
    }

    clearEnd = () => {
        this.setState({
            ocidsQueryAuto: "",
            ocidsQueryAutoRemove: "",
            query: [],
            //ocidsQueryAuto: [],
            //ocidsQueryAutoRemove: [],
            arrayDomains: [],
            autocompleteOrBrowserEnd: [],
            selectedMode: 'ontological'
        }, () =>
            this.props.callbackFromEndTermChange(this.state.query, this.state.ocidsQueryAuto, this.state.ocidsQueryAuto, this.props.number + 1, this.state.arrayDomains, this.state.autocompleteOrBrowserEnd, false))
    }

    itemTemplate(item) {
        let term = item.term;
        if (term !== undefined && term !== '') {
            let info = 'Text exact (not found in any domain)';
            let coloredDomains

            let hasDomains = false;
            let isTextExact = false;
            let isTextWithVariants = false;
            if (item.domains) {
                isTextExact = item.domains.includes('tr');
                isTextWithVariants = item.domains.includes('t');
                if (!isTextExact && !isTextWithVariants) {
                    hasDomains = true;
                    const uniqueLabels = item.domains.map(dom =>
                        this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                            this.props.domainLabelsMap[dom] : dom);
                    coloredDomains = uniqueLabels.sort().map(lab => {
                        let color = this.props.domainColors && this.props.domainColors.find(obj => {
                            return obj.label === lab
                        })
                        return <React.Fragment>
                            <div style={{
                                width: 4, height: 11, border: '2px solid', borderColor: color !== undefined ? '#' + color.color : 'black',
                                background: color !== undefined ? '#' + color.color : 'black', marginRight: 4, marginTop: 2
                            }}></div>
                            <span style={{ paddingRight: 10 }}>{lab}</span>
                        </React.Fragment>
                    })
                    info = uniqueLabels.sort().join(', ');
                }
            }

            let prefName = '';
            const prefnamesUnique = item.prefnames ? deduplicateTerms(item.prefnames) : [];
            if (prefnamesUnique && prefnamesUnique.length === 1 && normalizeConceptTerm(prefnamesUnique[0], true) !== normalizeConceptTerm(term, true)) {
                prefName = ` (${prefnamesUnique[0]})`;
            }

            let termFormatted = term;
            const match = findMatchIgnoreCase(term, this.state.part);
            if (match) {
                termFormatted = termFormatted.replace(match, `<span style="font-weight: normal">${match}</span>`);
            }
            //let termFormatted = term ? term.replace(this.state.part.toLowerCase(), `<span style="font-weight: normal">${this.state.part}</span>`) : term;
            termFormatted += `<span style="font-weight: normal">${prefName}</span>`;


            if (termFormatted !== undefined) {
                return <div key={item.term} className='grid'>
                    <div className="col-4 clampingContainer line-clamp line-clamp-1-line"
                        style={{ padding: '0 5px', color: hasDomains ? '' : '#777' }}
                        title={term}>
                        <span style={{ fontWeight: 'bold' }}>
                            <span dangerouslySetInnerHTML={{ __html: termFormatted }}></span>
                        </span>
                    </div>
                    <div className="col-5 clampingContainer line-clamp line-clamp-1-line"
                        style={{ padding: '0 5px', color: hasDomains ? '' : '#777' }}>
                        {info ?
                            <div className="grid" style={{ opacity: 0.7, margin: 0 }}
                                title={info}>
                                {coloredDomains !== undefined ? coloredDomains : info}</div>
                            : null
                        }
                    </div>
                    {/* <div className="col-1 textAlignCenter"
                style={{ padding: '0 5px 0 10px' }}>
                {hasDomains ?
                    <a title="Specify concepts"
                        className="primaryLink showOnHover"
                        onClick={(e) => {
                            //stopEventPropagation(e);
                            this.props.onOpenDomainExplorerToSpecifyTerm(item.term);
                        }}
                    >Specify
                    </a> : null
                }
            </div>
            <div className="col-1 clampingContainer line-clamp line-clamp-1-line textAlignCenter"
                style={{ padding: '0 5px' }}>
                {hasDomains || isTextWithVariants ?
                    <a title="Search as exact text"
                        className="primaryLink showOnHover"
                        onClick={(e) => {
                            item.domains = ['tr'];
                            item.term = item.term;
                            item.ocids = null;
                        }}
                    >Text exact
                    </a> : null
                }
            </div>
            <div className="col-1 clampingContainer line-clamp line-clamp-1-line textAlignCenter"
                style={{ padding: '0 5px' }}>
                {hasDomains || isTextExact ?
                    <a title="Search as text with variants"
                        className="primaryLink showOnHover"
                        onClick={(e) => {
                            item.domains = ['t'];
                            item.term = item.term;
                            item.ocids = null;
                        }}
                    >Text with variants
                </a> : null
                }
            </div>
            <div className="breakRow" />*/}
                </div>;
            }
        }
    }

    footerTemplate = () => {
        if (this.props.relationValues.length > 0) {
            return <div style={{ paddingTop: 5, paddingBottom: 5, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                {this.modes.map((mode, i) => {
                    return (
                        <div style={{ display: 'inline', marginLeft: i === 0 ? 15 : 0 }} key={mode.key} className="p-field-radiobutton">
                            <RadioButton
                                inputId={mode.key} name="mode" value={mode}
                                onChange={(e) => this.onRelationToggleChange(e)}
                                checked={this.state.relationMode === mode.key}
                                style={{ verticalAlign: 'baseline' }} />
                            <label style={{ color: 'black', marginRight: 10, marginLeft: '0.5rem' }} htmlFor={mode.key}>{mode.name}</label>
                        </div>
                    )
                })
                }
            </div>
        } else return null
    }

    onOpenDomainExplorerToSpecifyTermStart = (item, index, e) => {
        this.setState({
            initialSearchTerm: item,
            replaceItem: item,
            replaceIndex: index
        }, () => this.onDomainClick(e, 'start'));
    }

    onOpenDomainExplorerToSpecifyTermEnd = (item, index, e) => {
        this.setState({
            initialSearchTerm: item,
            replaceItem: item,
            replaceIndex: index
        }, () => this.onDomainClick(e, 'end'));
    }

    render() {
        const { fetching } = this.props

        this.data = [{ term: null, domains: null }]

        this.autocompleteDomains = []

        if (this.state.suggestedTerms && this.state.suggestedTerms.length > 0 && !fetching) {
            let arr = []
            this.state.suggestedTerms.forEach(entry => {
                if (this.state.directFragmentMatches && Object.keys(this.state.directFragmentMatches).length !== 0 && this.state.part === entry.term) {
                } else {
                    arr.push({
                        term: entry.term, domains: entry.domains, ocids: entry.ocids,
                        prefnames: entry.prefnames
                    })
                    this.autocompleteDomains.push(entry.domains)
                }
            })
            if (this.state.directFragmentMatches && Object.keys(this.state.directFragmentMatches).length !== 0) {
                let directDomains = []
                let ocids = [];
                let prefnames = [];
                Object.keys(this.state.directFragmentMatches).forEach(key => {
                    directDomains = [...directDomains, key]
                    // --- collect all OCIDs of exact match --- //
                    this.state.directFragmentMatches[key].forEach(term => {
                        ocids.push(term.ocid);
                        prefnames.push(term.preferredName);
                    });
                })
                this.autocompleteDomains.unshift(directDomains)
                arr.unshift({
                    term: this.state.part, domains: directDomains, ocids: ocids,
                    prefnames: prefnames
                })
            }
            this.data = arr

            if (this.data[0] === "") {
                this.data = null
            }
        } else if (this.state.suggestedTerms === undefined && !fetching) {
            this.data = null

        } else {
            let arr = [""]
            this.data = arr
        }


        let dataStartTerms = [""]

        if (this.state.suggestedTerms && this.state.suggestedTerms.length > 0 && !fetching) {
            let arr = []
            this.state.suggestedTerms.forEach(entry => {
                if (this.state.directFragmentMatches && Object.keys(this.state.directFragmentMatches).length !== 0 && this.state.startTerm === entry.term) {
                } else {
                    arr.push(entry.term)
                }
            })
            if (this.state.directFragmentMatches && Object.keys(this.state.directFragmentMatches).length !== 0) {
                arr.unshift(this.state.startTerm)
            }
            /*arr.unshift(this.state.startTerm + ' (Text with variants)')
            arr.unshift(this.state.startTerm + ' (Text exact)')*/
            dataStartTerms = arr.slice(1)
        } else {
            let arr = [""]
            /*let arr = [this.state.startTerm + ' (Text exact)']
            arr.push(this.state.startTerm + ' (Text with variants)')*/
            dataStartTerms = arr
        }

        let dataEndTerms = [""]

        if (this.state.suggestedTerms && this.state.suggestedTerms.length > 0 && !fetching) {
            let arr = []
            this.state.suggestedTerms.forEach(entry => {
                if (this.state.directFragmentMatches && Object.keys(this.state.directFragmentMatches).length !== 0 && this.state.endTerm === entry.term) {
                } else {
                    arr.push(entry.term)
                }
            })
            if (this.state.directFragmentMatches && Object.keys(this.state.directFragmentMatches).length !== 0) {
                arr.unshift(this.state.endTerm)
            }
            /*arr.unshift(this.state.endTerm + ' (Text with variants)')
            arr.unshift(this.state.endTerm + ' (Text exact)')*/
            dataEndTerms = arr.slice(1)
        } else {
            let arr = [""]
            /*let arr = [this.state.endTerm + ' (Text exact)']
            arr.push(this.state.endTerm + ' (Text with variants)')*/
            dataEndTerms = arr
        }

        this.relations = [
            //{ label: '', value: '' },
            { label: 'treats', value: '232000000390' },
            { label: 'inhibits', value: '232000000380' },
            { label: 'induces', value: '232000000379' },
            { label: 'is metabolized to', value: '232000000387' },
            { label: 'metabolizes', value: '232000000439' },
            { label: 'modulates', value: '232000000378' }
        ];

        let startOCIDs = []

        this.state.matchedPaths && this.state.matchedPaths.length > 0 && this.state.matchedPaths.forEach(match => {
            startOCIDs = [...startOCIDs, match.path[0]]
        })

        let relationOCIDs = []

        this.state.matchedPaths && this.state.matchedPaths.length > 0 && this.state.matchedPaths.forEach(match => {
            relationOCIDs = [...relationOCIDs, match.path[1]]
        })

        let endOCIDs = []

        this.state.matchedPaths && this.state.matchedPaths.length > 0 && this.state.matchedPaths.forEach(match => {
            endOCIDs = [...endOCIDs, match.path[2]]
        })

        let startNames = []

        startOCIDs.forEach(start => {
            if (this.state.prefNames[start]) {
                startNames = [...startNames, this.state.prefNames[start]]
            }
        })

        let relationNames = []

        relationOCIDs.forEach(relation => {
            if (this.state.prefNames[relation]) {
                relationNames = [...relationNames, this.state.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim()]
            }
        })

        let endNames = []

        endOCIDs.forEach(end => {
            if (this.state.prefNames[end]) {
                endNames = [...endNames, this.state.prefNames[end]]
            }
        })

        let hits = []

        this.state.matchedPaths && this.state.matchedPaths.length > 0 && this.state.matchedPaths.forEach(match => {
            if (match.tripleData && match.tripleData[0] && match.tripleData[0].occurrence) {
                hits = [...hits, match.tripleData[0].occurrence]
            } else {
                hits = [...hits, 1]
            }
        })

        let paths = []
        startNames.map((name, i) => {
            paths[i] = { start: startNames[i], relation: relationNames[i], end: endNames[i], hits: hits[i] }
            return paths
        })

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Add triple to blocklist" icon="pi pi-times" onClick={this.delete} />
        </div>;

        let relations = []

        if (this.props.relationOCIDMulti && typeof this.props.relationOCIDMulti === 'object') {
            Object.keys(this.props.relationOCIDMulti).forEach(entry => {
                let index = relations.findIndex(rel => rel.label === this.props.relationOCIDMulti[entry].replace(/ *\[[^\]]*]/g, '').trim())
                if (index === -1) {
                    relations.push({ label: this.props.relationOCIDMulti[entry].replace(/ *\[[^\]]*]/g, '').trim(), value: entry })
                }
            })
        }

        /*let newStateRelations = this.state.relations

        newStateRelations && newStateRelations > 0 && newStateRelations.forEach((rel,i) => {
            relations && relations.length > 0 && relations.forEach(rela => {
                if (rela.value === rel) {
                    i++
                } else {
                    newStateRelations.splice(i, 1)
                }
            })
        })*/

        let leftDomains = ''
        if (this.props.leftDomains && this.props.leftDomains.length > 0 && this.props.leftDomains[0] !== '') {
            let newLeftDomains = []
            this.props.leftDomains.forEach(entry => {
                if (entry === 'effects or process') {
                    newLeftDomains.push('effects')
                } else if (entry === 'chemistry') {
                    newLeftDomains.push('chem')
                } else if (entry === 'drug') {
                    newLeftDomains.push('drugs')
                } else if (entry === 'natural products') {
                    newLeftDomains.push('natprod')
                } else if (entry === 'protein') {
                    newLeftDomains.push('proteins')
                    newLeftDomains.push('humangenes')
                    //newLeftDomains.push('antibodies')
                } else if (entry === 'disease') {
                    newLeftDomains.push('diseases')
                } else if (entry === 'substance') {
                    newLeftDomains.push('substances')
                    newLeftDomains.push('natprod')
                    newLeftDomains.push('drugs')
                    newLeftDomains.push('chem')
                } else if (entry === 'cellline') {
                    newLeftDomains.push('celllines')
                } else {
                    newLeftDomains.push(entry)
                }
            })
            newLeftDomains.forEach(domain => {
                if (this.props.domainLabelsMap && this.props.domainLabelsMap[domain]) {
                    leftDomains += this.props.domainLabelsMap[domain] + '; '
                } else {
                    leftDomains += domain + '; '
                }
            })
            leftDomains = leftDomains.slice(0, -2)
        }

        let leftDomainsShow = ''
        if (this.props.leftDomainsShow && this.props.leftDomainsShow.length > 0 && this.props.leftDomainsShow[0] !== '') {
            let newLeftDomainsShow = []
            this.props.leftDomainsShow.forEach(entry => {
                if (entry === 'effects or process') {
                    newLeftDomainsShow.push('effects')
                } else if (entry === 'chemistry') {
                    newLeftDomainsShow.push('chem')
                } else if (entry === 'drug') {
                    newLeftDomainsShow.push('drugs')
                } else if (entry === 'natural products') {
                    newLeftDomainsShow.push('natprod')
                } else if (entry === 'protein') {
                    newLeftDomainsShow.push('proteins')
                    newLeftDomainsShow.push('humangenes')
                    //newLeftDomainsShow.push('antibodies')
                } else if (entry === 'disease') {
                    newLeftDomainsShow.push('diseases')
                } else if (entry === 'substance') {
                    newLeftDomainsShow.push('substances')
                    newLeftDomainsShow.push('natprod')
                    newLeftDomainsShow.push('drugs')
                    newLeftDomainsShow.push('chem')
                } else if (entry === 'cellline') {
                    newLeftDomainsShow.push('celllines')
                } else {
                    newLeftDomainsShow.push(entry)
                }
            })
            newLeftDomainsShow.forEach(domain => {
                if (this.props.domainLabelsMap && this.props.domainLabelsMap[domain]) {
                    leftDomainsShow += this.props.domainLabelsMap[domain] + '; '
                } else {
                    leftDomainsShow += domain + '; '
                }
            })
            leftDomainsShow = leftDomainsShow.slice(0, -2)
        }

        let rightDomains = ''
        if (this.props.rightDomains && this.props.rightDomains.length > 0 && this.props.rightDomains[0] !== '') {
            let newRightDomains = []
            this.props.rightDomains.forEach(entry => {
                if (entry === 'effects or process') {
                    newRightDomains.push('effects')
                } else if (entry === 'chemistry') {
                    newRightDomains.push('chem')
                } else if (entry === 'drug') {
                    newRightDomains.push('drugs')
                } else if (entry === 'natural products') {
                    newRightDomains.push('natprod')
                } else if (entry === 'protein') {
                    newRightDomains.push('proteins')
                    newRightDomains.push('humangenes')
                    //newRightDomains.push('antibodies')
                } else if (entry === 'disease') {
                    newRightDomains.push('diseases')
                } else if (entry === 'substance') {
                    newRightDomains.push('substances')
                    newRightDomains.push('natprod')
                    newRightDomains.push('drugs')
                    newRightDomains.push('chem')
                } else if (entry === 'cellline') {
                    newRightDomains.push('celllines')
                } else {
                    newRightDomains.push(entry)
                }
            })
            newRightDomains.forEach(domain => {
                if (this.props.domainLabelsMap && this.props.domainLabelsMap[domain]) {
                    rightDomains += this.props.domainLabelsMap[domain] + '; '
                } else {
                    rightDomains += domain + '; '
                }
            })
            rightDomains = rightDomains.slice(0, -2)
        }

        let rightDomainsShow = ''

        if (this.props.rightDomainsShow && this.props.rightDomainsShow.length > 0 && this.props.rightDomainsShow[0] !== '') {
            let newRightDomainsShow = []
            this.props.rightDomainsShow.forEach(entry => {
                if (entry === 'effects or process') {
                    newRightDomainsShow.push('effects')
                } else if (entry === 'chemistry') {
                    newRightDomainsShow.push('chem')
                } else if (entry === 'drug') {
                    newRightDomainsShow.push('drugs')
                } else if (entry === 'natural products') {
                    newRightDomainsShow.push('natprod')
                } else if (entry === 'protein') {
                    newRightDomainsShow.push('proteins')
                    newRightDomainsShow.push('humangenes')
                    //newRightDomainsShow.push('antibodies')
                } else if (entry === 'disease') {
                    newRightDomainsShow.push('diseases')
                } else if (entry === 'substance') {
                    newRightDomainsShow.push('substances')
                    newRightDomainsShow.push('natprod')
                    newRightDomainsShow.push('drugs')
                    newRightDomainsShow.push('chem')
                } else if (entry === 'cellline') {
                    newRightDomainsShow.push('celllines')
                } else {
                    newRightDomainsShow.push(entry)
                }
            })
            newRightDomainsShow.forEach(domain => {
                if (this.props.domainLabelsMap && this.props.domainLabelsMap[domain]) {
                    rightDomainsShow += this.props.domainLabelsMap[domain] + '; '
                } else {
                    rightDomainsShow += domain + '; '
                }
            })
            rightDomainsShow = rightDomainsShow.slice(0, -2)
        }

        let ontBrowserDialogDomains = []

        if (typeof this.state.domain === 'string') {
            this.props.availableDomains && this.props.availableDomains.forEach(item => {
                if (item.value === this.state.domain) {
                    ontBrowserDialogDomains = [...ontBrowserDialogDomains, item]
                }
            })
        } else {
            this.props.availableDomains && this.props.availableDomains.forEach(item => {
                this.state.domain.forEach(dom => {
                    if (item.value === dom) {
                        ontBrowserDialogDomains = [...ontBrowserDialogDomains, item]
                    }
                })
            })
        }

        let footerEditEntity = <div className='col-12' style={{ paddingTop: 0, paddingRight: 0, marginTop: 10 }}>
            <Button label="OK"
                className='p-button-sm primaryButton'
                onClick={this.state.startEnd === 'start' ?
                    (e) => this.handleStartTermChangeAfterOK(this.props.startTerm) :
                    (e) => this.handleEndTermChangeAfterOK(this.props.endTerm)}
                style={{ float: 'right' }}
            />
            <Button label="Cancel"
                className='p-button-secondary p-button-sm'
                onClick={(e) => {
                    this.handleCancelEdit(e)
                }}
                style={{ float: 'right', marginRight: 5 }}
            />
        </div>

        let footerEditRelation = <div className='col-12' style={{ paddingRight: 0, marginTop: -7 }}>
            <Button label="OK"
                className='p-button-sm primaryButton'
                onClick={(e) => this.handleRelationModeChangeAfterOK(e)}
                style={{ float: 'right' }}
            />
            <Button label="Cancel"
                className='p-button-secondary p-button-sm'
                onClick={(e) => {
                    this.handleCancelEditRelation(e)
                }}
                style={{ float: 'right', marginRight: 5 }}
            />
        </div>

        let startTermsAC = []
        if (this.props.startTerm && this.props.startTerm.length > 0) {
            this.props.startTerm.forEach(term => {
                startTermsAC = [...startTermsAC, { term: term, test: 'test' }]
            })
        }

        let endTermsAC = []
        if (this.props.endTerm && this.props.endTerm.length > 0) {
            this.props.endTerm.forEach(term => {
                endTermsAC = [...endTermsAC, { term: term, test: 'test' }]
            })
        }


        return (
            <LoadingOverlay
                active={this.state.fetchingResults}
                spinner={true} >
                <div className='grid'>
                    <div className='grid' style={{ width: '100%' }}>
                        {/*<div style={{ textAlign: 'center', marginBottom: 10 }}>
                        <React.Fragment>
                            <label style={{ fontSize: '14px', paddingRight: 5, marginBottom: 10, marginRight: 10, color: this.props.tripleHits > 0 ? '#4CAF50' : '#C62828', fontWeight: 'bold' }}>
                                {`Triple hits: `}<NumberFormat value={this.props.tripleHits} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} />
                            </label>
                            {this.props.number > 0 && (this.props.pathNumber - this.props.number !== 1) &&
                                <label style={{ fontSize: '14px', paddingRight: 5, marginBottom: 10, color: this.props.subPathHits > 0 ? '#EF6C00' : '#C62828', fontWeight: 'bold' }}>
                                    {`Hits for sub path: `}<NumberFormat value={this.props.subPathHits} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} />
                                </label>
                            }
                            {this.props.number > 0 && (this.props.pathNumber - this.props.number === 1) &&
                                <label style={{ fontSize: '14px', paddingRight: 5, marginBottom: 10, color: this.props.fullPathHits > 0 ? '#2E7D32' : '#C62828', fontWeight: 'bold' }}>
                                    {`Hits for full path: `} <NumberFormat value={this.props.fullPathHits} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} />
                                </label>
                            }
                        </React.Fragment>
                        </div>*/}
                        {/*<div className='col-12' style={{ paddingBottom: 0, display: 'flex', paddingTop: 0 }}>*/}
                        {(this.props.logicals === 'aaa' || this.props.logicals === 'aac' ||
                            this.props.logicals === 'aad' || this.props.logicals === 'aapp' || this.props.logicals === 'aap') && this.props.number === 0 &&
                            <div className='col-4' style={{ marginBottom: 0, cursor: 'pointer' }}
                                onClick={(e) => this.openEditStartEntity(e)}>
                                <Card className='cardNoPadding' style={{ background: '#efefef', border: '1px solid #e1e1e1', height: 160, width: 184 }}>
                                    <div style={{ paddingLeft: 10, paddingTop: 6, height: 160 }}>
                                        <div>
                                            <div style={{ float: 'left', width: '70%' }}>
                                                <label style={{
                                                    fontSize: '16px', fontWeight: 'bold', paddingRight: 5, marginBottom: 0,
                                                    width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer'
                                                }} onClick={(e) => this.openEditStartEntity(e)}>{`Entity ${this.props.number + 1}`}</label>
                                            </div>
                                            <div style={{ float: 'right', paddingRight: 10, paddingTop: 3 }}>
                                                <a
                                                    onClick={this.openEditStartEntity}
                                                    style={{ display: 'inline-block' }}>Edit</a>
                                            </div>
                                        </div>
                                        <div>
                                            <label style={{
                                                fontSize: '13px', paddingRight: 5, marginBottom: 0, color: '#757575',
                                                width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer'
                                            }} title={`${leftDomainsShow}`} onClick={(e) => this.openEditStartEntity(e)}>
                                                {`${leftDomainsShow}`}
                                            </label>
                                        </div>
                                        {this.props.startTermShow.length > 0 ?
                                            <div style={{ paddingTop: 10, paddingBottom: 15 }}>
                                                {this.props.startTermShow.map((term, i) => {
                                                    if (i <= 2) {
                                                        return (
                                                            <div style={{ paddingBottom: 3 }} key={term + "_" + i}>
                                                                <label title={term} style={{ color: 'black', marginRight: 10, fontSize: 13, fontWeight: 600, display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={(e) => this.openEditStartEntity(e)}>{term}</label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                                {this.props.startTermShow.length > 3 &&
                                                    <div style={{ paddingBottom: 3 }}>
                                                        <label style={{ color: '#757575', marginRight: 10, fontSize: 13, fontWeight: 600, cursor: 'pointer' }}
                                                            onClick={(e) => this.openEditStartEntity(e)}>{`${this.props.startTermShow.length - 3} more`}</label>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div style={{ paddingTop: 10, paddingBottom: 15 }}>
                                                <label style={{ color: '#757575', marginRight: 10, fontSize: 13, fontWeight: 'normal', cursor: 'pointer' }} onClick={(e) => this.openEditStartEntity(e)}>All concepts from available domains</label>
                                            </div>
                                        }
                                        {this.props.startTermShow.length !== 0 &&
                                            <div style={{ paddingBottom: 5, position: 'absolute', bottom: 10 }}>
                                                <label style={{ color: 'black', marginRight: 10, fontSize: 13, fontWeight: 'normal', paddingBottom: 10, cursor: 'pointer' }} onClick={(e) => this.openEditStartEntity(e)}>{this.props.startChecked === 'ontological' ? 'Ontological' : 'Exact'}</label>
                                            </div>
                                        }
                                    </div>
                                </Card>
                                {/*<div style={{paddingTop: 2}}>
                                    <a
                                        onClick={this.openMoveUsers}
                                        style={{ display: 'inline-block', paddingTop: 2, paddingRight: 10, float: 'right' }}>Remove</a>
                                        </div>*/}

                                {(this.props.logicals === 'caa' || this.props.logicals === 'cid' || this.props.logicals === 'cipp' || this.props.logicals === 'cip' || this.props.logicals === 'cic' || this.props.logicals === 'ctd') &&
                                    <label style={{
                                        fontSize: '11px', fontWeight: 500, paddingRight: 5, marginBottom: 0, color: '#007FDB',
                                        width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                    }}>{`Entity ${this.props.number + 1} from: chemistry, natural products, substances and drugs`}</label>
                                }
                                {(this.props.logicals === 'daa' || this.props.logicals === 'dmp') &&
                                    <label style={{
                                        fontSize: '11px', fontWeight: 500, paddingRight: 5, marginBottom: 0, color: '#007FDB',
                                        width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                    }}>{`Entity ${this.props.number + 1} from: disease`}</label>
                                }
                                {(this.props.logicals === 'ppaa' || this.props.logicals === 'ppipp' || this.props.logicals === 'ppmp' || this.props.logicals === 'pppd') &&
                                    <label style={{
                                        fontSize: '11px', fontWeight: 500, paddingRight: 5, marginBottom: 0, color: '#007FDB',
                                        width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                    }}>{`Entity ${this.props.number + 1} from: effects or process`}</label>
                                }
                                {(this.props.logicals === 'paa' || this.props.logicals === 'pid' || this.props.logicals === 'pmc' || this.props.logicals === 'pmpp') &&
                                    <label style={{
                                        fontSize: '11px', fontWeight: 500, paddingRight: 5, marginBottom: 0, color: '#007FDB',
                                        width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                    }}>{`Entity ${this.props.number + 1} from: protein`}</label>
                                }

                                {/*{(this.props.logicals === 'aaa' || this.props.logicals === 'caa' || this.props.logicals === 'daa' || this.props.logicals === 'ppaa' || this.props.logicals === 'paa') &&
                        <label style={{ fontSize: '10px', fontWeight: 500, paddingRight: 5, marginBottom: 0, float: this.props.number === 0 ? 'right' : '', color: '#007FDB' }}>{`Possible domains: ${rightDomains}`}</label>
                    }
                    {(this.props.logicals === 'aac' || this.props.logicals === 'cic' || this.props.logicals === 'pmc') &&
                        <label style={{ fontSize: '10px', fontWeight: 500, paddingRight: 5, marginBottom: 0, float: this.props.number === 0 ? 'right' : '', color: '#007FDB' }}>{`Possible domains: chemistry, natural products, substances and drugs`}</label>
                    }
                    {(this.props.logicals === 'aad' || this.props.logicals === 'cid' || this.props.logicals === 'ctd' || this.props.logicals === 'pppd' || this.props.logicals === 'pid') &&
                        <label style={{ fontSize: '10px', fontWeight: 500, paddingRight: 5, marginBottom: 0, float: this.props.number === 0 ? 'right' : '', color: '#007FDB' }}>{`Possible domain: disease`}</label>
                    }
                    {(this.props.logicals === 'aapp' || this.props.logicals === 'cipp' || this.props.logicals === 'pmpp' || this.props.logicals === 'ppipp') &&
                        <label style={{ fontSize: '10px', fontWeight: 500, paddingRight: 5, marginBottom: 0, float: this.props.number === 0 ? 'right' : '', color: '#007FDB' }}>{`Possible domain: effects or process`}</label>
                    }
                    {(this.props.logicals === 'cip' || this.props.logicals === 'dmp' || this.props.logicals === 'ppmp' || this.props.logicals === 'aap') &&
                        <label style={{ fontSize: '10px', fontWeight: 500, paddingRight: 5, marginBottom: 0, float: this.props.number === 0 ? 'right' : '', color: '#007FDB' }}>{`Possible domain: protein`}</label>
                    }*/}
                                {this.props.number === 0 &&
                                    <div>
                                        {/*<label style={{ fontSize: '14px', fontWeight: 500, paddingRight: 5, marginBottom: 10 }}>
                                {`Entity ${this.props.number + 1}:`}
                </label>*/}
                                        {/*{this.props.logicals === 'aaa' ||
                            this.props.logicals === 'aac' || this.props.logicals === 'aad' || this.props.logicals === 'aapp' || this.props.logicals === 'aap' ?
                            `Entity ${this.props.number + 1}:` : this.props.logicals === 'ctd' || this.props.logicals === 'caa' ? "Compound:" :
                                this.props.logicals === 'cip' ? 'Compound:' :
                                    this.props.logicals === 'cid' ? 'Compound:' : this.props.logicals === 'pid' ? 'Protein:' : this.props.logicals === 'cic' ? "Compound:" :
                                        this.props.logicals === 'pmc' ?
                                            "Protein:" : this.props.logicals === 'cipp' ? 'Compound:' : this.props.logicals === 'pmpp' || this.props.logicals === 'paa' ? 'Protein:' :
                                                this.props.logicals === 'ppmp' || this.props.logicals === 'ppaa' ? 'Physiol. process:' :
                                                    this.props.logicals === 'ppipp' ? 'Physiol. process:' : this.props.logicals === 'pppd' ? 'Physiol. process:' :
                    this.props.logicals === 'dmp' || this.props.logicals === 'daa' ? 'Disease:' : "Start:"}*/}

                                        {/*
                                    <AutoComplete
                                        id='autoPath'
                                        value={this.props.startTerm}
                                        suggestions={dataStartTerms}
                                        completeMethod={this.filterStartTerm}
                                        className='autocompleteMulti'
                                        placeholder={this.props.logicals === 'free' ?
                                            "Type term (e.g. diabetes mellitus)" : this.props.logicals === 'ctd' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'ctd' && this.props.number === 0 ? "type e.g. aspirin or leave empty" :
                                                this.props.logicals === 'cip' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'cip' && this.props.number === 0 ? 'type e.g. acetylsalicylic acid or leave empty' :
                                                    this.props.logicals === 'caa' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'caa' && this.props.number === 0 ? 'type e.g. acetylsalicylic acid or leave empty' :
                                                        this.props.logicals === 'cid' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'cid' && this.props.number === 0 ? 'type e.g. testosterone or leave empty' :
                                                            this.props.logicals === 'pid' && this.props.number > 0 ? 'domain proteins' : this.props.logicals === 'pid' && this.props.number === 0 ? 'type e.g. HDL or leave empty' :
                                                                this.props.logicals === 'cic' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'cic' && this.props.number === 0 ? "type e.g. cholesterol or leave empty" :
                                                                    this.props.logicals === 'pmc' && this.props.number > 0 ? 'domain proteins' : this.props.logicals === 'pmc' && this.props.number === 0 ? "type e.g. dihydrofolate reductase or leave empty" :
                                                                        this.props.logicals === 'paa' && this.props.number > 0 ? 'domain proteins' : this.props.logicals === 'paa' && this.props.number === 0 ? "type e.g. dihydrofolate reductase or leave empty" :
                                                                            this.props.logicals === 'cipp' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'cipp' && this.props.number === 0 ? 'type e.g. 17β-estradiol or leave empty' :
                                                                                this.props.logicals === 'pmpp' && this.props.number > 0 ? 'domain proteins' : this.props.logicals === 'pmpp' && this.props.number === 0 ? 'type e.g. matrix metalloproteinase 9 or leave empty' :
                                                                                    this.props.logicals === 'ppmp' && this.props.number > 0 ? 'domain effects' : this.props.logicals === 'ppmp' && this.props.number === 0 ? 'type e.g. angiogenesis or leave empty' :
                                                                                        this.props.logicals === 'ppaa' && this.props.number > 0 ? 'domain effects' : this.props.logicals === 'ppaa' && this.props.number === 0 ? 'type e.g. angiogenesis or leave empty' :
                                                                                            this.props.logicals === 'ppipp' && this.props.number > 0 ? 'domain effects' : this.props.logicals === 'ppipp' && this.props.number === 0 ? 'type e.g. aging or leave empty' :
                                                                                                this.props.logicals === 'pppd' && this.props.number > 0 ? 'domain effects' : this.props.logicals === 'pppd' && this.props.number === 0 ? 'type e.g. exercise or leave empty' :
                                                                                                    this.props.logicals === 'dmp' && this.props.number > 0 ? 'domain diseases' : this.props.logicals === 'dmp' && this.props.number === 0 ? 'type e.g. diabetes mellitus or leave empty' :
                                                                                                        this.props.logicals === 'daa' && this.props.number > 0 ? 'domain diseases' : this.props.logicals === 'daa' && this.props.number === 0 ? 'type e.g. diabetes mellitus or leave empty' :
                                                                                                            this.props.logicals === 'aaa' || this.props.logicals === 'aac' || this.props.logicals === 'aad' || this.props.logicals === 'aapp' || this.props.logicals === 'aap' ? 'type e.g. aspirin or leave empty' :
                                                                                                                "Type start term"}
                                        minLength={1}
                                        multiple={true}
                                        onChange={(e) => this.handleStartTermChange(e)}
                                        style={{ marginRight: '10px', width: '330px', verticalAlign: 'super' }}
                                        disabled={this.props.number > 0
                                            ? true : false}
                                        selectedItemTemplate={this.selItemTemplateStart}
                                    />
                                    {this.props.number === 0 &&
                                        <Button className="customAddConceptIcon"
                                            icon={"nonsense_icon"} title='Open domain explorer' onClick={e => this.onDomainClick(e, 'start')}
                                            style={{ marginRight: 10, marginLeft: -27, width: '2em', height: '2em', boxShadow: 'none', WebkitBoxShadow: 'none', background: 'white', backgroundColor: 'transparent' }}>
                                            <img src={iconAddConcept} alt='Domain explorer' />
                                        </Button>
                                    }
                                */}
                                    </div>
                                }
                            </div>
                        }

                        <div className={this.props.number === 0 ? 'col-4' : 'col-6'} style={{
                            width: 190, textAlign: 'center' //marginLeft: this.props.logicals !== 'aaa' && this.props.logicals !== 'aac' && this.props.logicals !== 'aad' && this.props.logicals !== 'aapp' && this.props.logicals !== 'aap' && this.props.logicals !== 'caa' && this.props.logicals !== 'daa' && this.props.logicals !== 'ppaa' && this.props.logicals !== 'paa' ? 27 : 16 
                        }}>
                            {this.props.logicals !== 'aaa' && this.props.logicals !== 'aac' && this.props.logicals !== 'aad' && this.props.logicals !== 'aapp' && this.props.logicals !== 'aap' && this.props.logicals !== 'caa' && this.props.logicals !== 'daa' && this.props.logicals !== 'ppaa' && this.props.logicals !== 'paa' &&
                                <div>
                                    {/*<div>
                                        <label style={{ fontWeight: 'bold', marginLeft: 5, color: '#007FDB', fontSize: 12, display: 'inline-block', width: 100 }}>{`Relation ${this.props.number + 1}`}</label>
                                    </div>*/}
                                    <div>
                                        <InputText className='inputPP' readOnly={true} value={this.props.relation} style={{ marginRight: 10, marginLeft: 5, marginBottom: 16 }} />
                                    </div>
                                </div>

                            }
                            {/*{(this.props.logicals === 'aaa' || this.props.logicals === 'aac' || this.props.logicals === 'aad' || this.props.logicals === 'aapp' ||
                            this.props.logicals === 'aap' || this.props.logicals === 'caa' || this.props.logicals === 'daa' || this.props.logicals === 'ppaa' ||
                            this.props.logicals === 'paa') && this.props.number === 0 && this.props.pathNumber < 2 && !this.props.showFirstRelation &&
                            this.state.relations.length === 0 &&
                            <Button title='Add relations'
                                icon='pi-md-add'
                                label='Add relations'
                                className="blue-grey-btn"
                                onClick={this.onAddRelationClick} style={{marginLeft: 20}} />
/                        }*/}
                            {(this.props.logicals === 'aaa' || this.props.logicals === 'aac' || this.props.logicals === 'aad' || this.props.logicals === 'aapp' ||
                                this.props.logicals === 'aap' || this.props.logicals === 'caa' || this.props.logicals === 'daa' || this.props.logicals === 'ppaa' ||
                                this.props.logicals === 'paa')
                                //|| this.props.showFirstRelation
                                //|| this.state.relations.length > 0
                                &&
                                <div>
                                    {/*<div>
                                        <label style={{ fontWeight: 'bold', marginLeft: 5, color: '#007FDB', fontSize: 12, display: 'inline-block', marginBottom: 11, width: 100 }}>{`Relation ${this.props.number + 1}`}</label>
                                    </div>*/}
                                    <div>
                                        <div style={{ marginTop: this.props.number > 0 && (this.props.pathNumber - this.props.number !== 1) ? 31 : 45, marginBottom: 3 }}>


                                            {this.props.number > 0 && (this.props.pathNumber - this.props.number !== 1) &&
                                                <div>
                                                    <label style={{ fontSize: '13px', marginBottom: 10, color: this.props.subPathHits > 0 ? '#757575' : '#d0021b' }}>
                                                        <NumberFormat value={this.props.subPathHits} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} /> sub paths
                                                    </label>
                                                </div>
                                            }
                                            <label style={{ fontSize: '13px', marginBottom: 10, color: this.props.tripleHits > 0 ? '#757575' : '#d0021b' }}>
                                                <NumberFormat value={this.props.tripleHits} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} /> {this.props.tripleHits && this.props.tripleHits === 1 ? 'triple' : 'triples'}
                                            </label>
                                        </div>
                                        <MultiSelect panelFooterTemplate={this.footerTemplate} className='inferenceMultiSelect' value={this.state.relations} options={relations} onChange={(e) => this.handleRelationMultiChange(e)}
                                            style={{ //borderLeft: 'none', borderRight: 'none', borderTop: 'none' 
                                                marginTop: this.props.relationValues.length > 0 ? 0 : 0, border: '1px solid #e1e1e1', color: 'black', width: '100%'
                                            }}
                                            filter={true} filterPlaceholder="Search relations" resetFilterOnHide={true} //showClear={true}
                                            placeholder={relations.length > 0 ? "All relations or choose" : "No relation possible"} pt={{
                                                checkboxIcon: {
                                                    onClick: (e) => {
                                                        e.stopPropagation();
                                                        if (e.target.className.baseVal !== "") {
                                                            e.target.parentNode.click();
                                                        } else {
                                                            e.target.parentNode.parentNode.click();
                                                        }
                                                    },
                                                },
                                                headerCheckbox: {
                                                    onClick: (e) => {
                                                        e.stopPropagation();
                                                        e.target.parentNode.click();
                                                    },
                                                },
                                            }} />
                                        {this.props.relationValues.length > 0 ?
                                            <div style={{ marginTop: 3 }}>
                                                <label style={{ fontSize: '13px', marginBottom: 3, color: '#757575' }}>
                                                    {this.props.relationMode === 'ontological' ? "Ontological" : "Exact"}
                                                </label>
                                                {/* <a
                                                    onClick={this.openEditRelation}
                                        style={{ float: 'right' }}>Edit</a> */}
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={this.props.number === 0 ? 'col-4' : 'col-6'}>
                            {/*<label style={{ fontSize: '14px', fontWeight: 500, paddingRight: 5, marginBottom: 10, marginLeft: 10 }}>
                            {`Entity ${this.props.number + 2}:`}
                        </label>*/}
                            {/*{this.props.logicals === 'aaa' || this.props.logicals === 'caa' || this.props.logicals === 'daa' || this.props.logicals === 'ppaa' ||
                                this.props.logicals === 'paa' ? `Entity ${this.props.number + 2}:` : this.props.logicals === 'ctd' || this.props.logicals === 'aad' ?
                                    "Disease:" : this.props.logicals === 'cip' || this.props.logicals === 'aap' ? 'Protein:' :
                                        this.props.logicals === 'cid' ? 'Disease:' : this.props.logicals === 'pid' ? 'Disease:' :
                                            this.props.logicals === 'pppd' ? 'Disease:' : this.props.logicals === 'cic' ? 'Compound:' :
                                                this.props.logicals === 'pmc' || this.props.logicals === 'aac' ? "Compound:" : this.props.logicals === 'cipp' ||
                                                    this.props.logicals === 'aapp' ? "Physiol. process:" : this.props.logicals === 'ppipp' ? "Physiol. process:" :
                                                        this.props.logicals === 'pmpp' ? "Physiol. process:" :
                    this.props.logicals === 'ppmp' ? "Protein:" : this.props.logicals === 'dmp' ? "Protein:" : "End:"}*/}
                            {(this.props.logicals === 'aaa' || this.props.logicals === 'caa' || this.props.logicals === 'daa' || this.props.logicals === 'ppaa' ||
                                this.props.logicals === 'paa') &&
                                <React.Fragment>
                                    <div style={{ cursor: 'pointer' }}
                                        onClick={(e) => this.openEditEndEntity(e)}>
                                        <Card className='cardNoPadding' style={{ background: '#efefef', border: '1px solid #e1e1e1', height: 160, width: 184 }}>
                                            <div style={{ paddingLeft: 10, paddingTop: 6, height: 160, cursor: 'pointer' }} onClick={(e) => this.openEditEndEntity(e)}>
                                                <div style={{ cursor: 'pointer' }}
                                                    onClick={(e) => this.openEditEndEntity(e)}>
                                                    <div style={{ float: 'left', width: '70%' }}>
                                                        <label style={{
                                                            fontSize: '16px', fontWeight: 'bold', paddingRight: 5, marginBottom: 0,
                                                            width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                                            cursor: 'pointer'
                                                        }} onClick={(e) => this.openEditEndEntity(e)}>{`Entity ${this.props.number + 2}`}</label>
                                                    </div>
                                                    <div style={{ float: 'right', paddingRight: 10, paddingTop: 3 }}>
                                                        <a
                                                            onClick={this.openEditEndEntity}
                                                            style={{ display: 'inline-block' }}>Edit</a>
                                                    </div>
                                                </div>
                                                <div style={{ cursor: 'pointer' }}
                                                    onClick={(e) => this.openEditEndEntity(e)}>
                                                    <label style={{
                                                        fontSize: '13px', paddingRight: 5, marginBottom: 0, color: '#757575',
                                                        width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer'
                                                    }} title={`${rightDomainsShow}`} onClick={(e) => this.openEditEndEntity(e)}>{`${rightDomainsShow}`}</label>
                                                </div>
                                                {this.props.endTermShow.length > 0 ?
                                                    <div style={{ paddingTop: 10, paddingBottom: 15 }}>
                                                        {this.props.endTermShow.map((term, i) => {
                                                            if (i <= 2) {
                                                                return (
                                                                    <div style={{ paddingBottom: 3 }} key={term + "_" + i}>
                                                                        <label title={term}
                                                                            style={{
                                                                                color: 'black', marginRight: 10, fontSize: 13, fontWeight: 600, display: 'block',
                                                                                overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer'
                                                                            }} onClick={(e) => this.openEditEndEntity(e)}>{term}</label>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        }
                                                        {this.props.endTermShow.length > 3 &&
                                                            <div style={{ paddingBottom: 3 }}>
                                                                <label style={{ color: '#757575', marginRight: 10, fontSize: 13, fontWeight: 600, cursor: 'pointer' }} onClick={(e) => this.openEditEndEntity(e)}>{`${this.props.endTermShow.length - 3} more`}</label>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ paddingTop: 10, paddingBottom: 15 }}>
                                                        <label style={{ color: '#757575', marginRight: 10, fontSize: 13, fontWeight: 'normal', cursor: 'pointer' }} onClick={(e) => this.openEditEndEntity(e)}>All concepts from available domains</label>
                                                    </div>
                                                }
                                                {this.props.endTermShow.length !== 0 &&
                                                    <div style={{ paddingBottom: 5, position: 'absolute', bottom: 10 }}>
                                                        <label style={{ color: 'black', marginRight: 10, fontSize: 13, fontWeight: 'normal', paddingBottom: 10, cursor: 'pointer' }} onClick={(e) => this.openEditEndEntity(e)}>{this.props.endChecked === 'ontological' ? 'Ontological' : 'Exact'}</label>
                                                    </div>
                                                }
                                            </div>
                                        </Card>
                                    </div>
                                    <div style={{ paddingTop: 2 }}>
                                        {this.props.pathNumber > 1 &&
                                            <Button className='p-button-rounded p-button-text'
                                                title='Execute triple'
                                                icon={"nonsense"}
                                                onClick={this.onExecuteClick}
                                                style={{
                                                    color: 'black', position: 'absolute',
                                                    right: this.props.number > 0 && (this.props.pathNumber - this.props.number !== 1) ? 40 : this.props.number === 0 ? 15 : 8
                                                }}
                                            >
                                                <img style={{ height: '1em' }} src={runTripleIcon} alt='Execute triple' />
                                            </Button>
                                        }
                                        {this.props.number > 0 && (this.props.pathNumber - this.props.number !== 1) &&
                                            <Button title='Execute path up to this point'
                                                className='p-button-rounded p-button-text'
                                                icon="nonsense"
                                                onClick={this.onExecutePathClick} style={{ color: 'black', position: 'absolute', right: 10 }}>
                                                <img style={{ height: '1em' }} src={runSubPathIcon} alt='Execute sub path' />
                                            </Button>
                                        }
                                        {this.props.number >= 1 &&
                                            <a title='Remove triples up to this point'
                                                onClick={this.onRemoveClick}
                                                style={{ display: 'inline-block', marginTop: -20, paddingRight: 10, float: 'right' }}>Remove</a>
                                        }
                                    </div>
                                </React.Fragment>
                            }
                            {(this.props.logicals === 'aac' || this.props.logicals === 'cic' || this.props.logicals === 'pmc') &&
                                <label style={{
                                    fontSize: '11px', fontWeight: 500, paddingRight: 5, marginBottom: 0, color: '#007FDB',
                                    width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>{`Entity ${this.props.number + 2} from: chemistry, natural products, substances and drugs`}</label>
                            }
                            {(this.props.logicals === 'aad' || this.props.logicals === 'cid' || this.props.logicals === 'ctd' || this.props.logicals === 'pppd' ||
                                this.props.logicals === 'pid') &&
                                <label style={{
                                    fontSize: '11px', fontWeight: 500, paddingRight: 5, marginBottom: 0, color: '#007FDB',
                                    width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>{`Entity ${this.props.number + 2} from: disease`}</label>
                            }
                            {(this.props.logicals === 'aapp' || this.props.logicals === 'cipp' || this.props.logicals === 'pmpp' || this.props.logicals === 'ppipp') &&
                                <label style={{
                                    fontSize: '11px', fontWeight: 500, paddingRight: 5, marginBottom: 0, color: '#007FDB',
                                    width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>{`Entity ${this.props.number + 2} from: effects or process`}</label>
                            }
                            {(this.props.logicals === 'cip' || this.props.logicals === 'dmp' || this.props.logicals === 'ppmp' || this.props.logicals === 'aap') &&
                                <label style={{
                                    fontSize: '11px', fontWeight: 500, paddingRight: 5, marginBottom: 0, color: '#007FDB',
                                    width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>{`Entity ${this.props.number + 2} from: protein`}</label>
                            }
                            {/*<div>
                                <AutoComplete value={this.props.endTerm}
                                    id='autoPath'
                                    suggestions={dataEndTerms}
                                    completeMethod={this.filterEndTerm}
                                    className='autocompleteMulti'
                                    placeholder={this.props.logicals === 'free' ? "Type term (e.g. caspase 3)" :
                                        this.props.logicals === 'ctd' ? "type e.g. headache or leave empty" :
                                            this.props.logicals === 'pppd' ? "type e.g. cardiovascular diseases or leave empty" :
                                                this.props.logicals === 'cip' || this.props.logicals === 'aap' ? 'type e.g. cyclooxygenase-1 or leave empty' :
                                                    this.props.logicals === 'cid' || this.props.logicals === 'aad' ? 'type e.g. androgenic alopecia or leave empty' :
                                                        this.props.logicals === 'pid' ? 'type e.g. arteriosclerosis or leave empty' :
                                                            this.props.logicals === 'cic' || this.props.logicals === 'aac' ? 'type e.g. 25-hydroxycholesterol or leave empty' :
                                                                this.props.logicals === 'pmc' ? "type e.g. folic acid or leave empty" :
                                                                    this.props.logicals === 'cipp' ? "type e.g. blood pressure or leave empty" :
                                                                        this.props.logicals === 'ppipp' ? "type e.g. mitochondrial dysfunction or leave empty" :
                                                                            this.props.logicals === 'pmpp' || this.props.logicals === 'aapp' ? "type e.g. cell migration or leave empty" :
                                                                                this.props.logicals === 'ppmp' ? "type e.g. urokinase-type plasminogen activator or leave empty" :
                                                                                    this.props.logicals === 'aaa' || this.props.logicals === 'caa' || this.props.logicals === 'daa' || this.props.logicals === 'ppaa' || this.props.logicals === 'paa' ? "type e.g. headache or leave empty" :
                                                                                        this.props.logicals === 'dmp' ? "type e.g. caspase 3 or leave empty" : "Type end term"}
                                    minLength={1}
                                    multiple={true}
                                    onChange={(e) => this.handleEndTermChange(e)}
                                    style={{ marginRight: '10px', width: '330px', verticalAlign: 'super' }}
                                    selectedItemTemplate={this.selItemTemplateEnd} />

                                <Button title='Open domain explorer'
                                    className="customAddConceptIcon"
                                    icon={"nonsense_icon"}
                                    onClick={e => this.onDomainClick(e, 'end')}
                                    style={{ marginRight: 10, marginLeft: -27, width: '2em', height: '2em', boxShadow: 'none', WebkitBoxShadow: 'none', background: 'white', backgroundColor: 'transparent' }}>
                                    <img src={iconAddConcept} alt='Domain explorer' />
                                </Button>
                                {/*<Button title='Triple settings' className='purple-btn p-button-sm p-button-rounded p-button-icon-only p-button-help' icon="pi pi-cog" onClick={this.onClick} style={{ verticalAlign: 'super', marginLeft: 10 }} />
                                {this.props.pathNumber > 1 &&
                                    <Button className='p-button-success p-button-rounded p-button-icon-only' title='Execute triple' icon="pi pi-caret-right" onClick={this.onExecuteClick} style={{ verticalAlign: 'super', marginLeft: 5 }} />
                                }
                                {this.props.number > 0 && (this.props.pathNumber - this.props.number !== 1) &&
                                    <Button title='Execute path up to this point' className='p-button-warning p-button-sm p-button-rounded p-button-icon-only' icon="pi pi-angle-double-right" onClick={this.onExecutePathClick} style={{ verticalAlign: 'super', marginLeft: 5 }} />
                                }
                                {this.props.number >= 1 &&
                                    <Button title='Remove triples up to this point' icon='pi pi-trash' className="p-button-danger p-button-sm p-button-rounded p-button-icon-only" onClick={this.onRemoveClick} style={{ verticalAlign: 'super', marginLeft: 5 }} />
                                }
                            </div>*/}
                        </div>

                        {/*this.props.number === 0 && (this.props.startTerm !== "" && this.props.endTerm !== "" && this.props.startTerm.length !== 0 && this.props.endTerm.length !== 0) &&
                            <div style={{ marginTop: -5 }}>
                                <div style={{ display: 'inline-block', marginLeft: 440 }}>
                                    <h4 style={{
                                        marginTop: 0, marginBottom: 10,
                                    }}>Search with at least {this.props.numberOfHops[0]} and max. {this.props.numberOfHops[1]} hops</h4>
                                    <Slider disabled={this.props.startTerm === "" || this.props.endTerm === "" || this.props.startTerm.length === 0 || this.props.endTerm.length === 0 ? true : false} style={{ width: 100, marginLeft: 75 }} value={this.props.numberOfHops} onChange={(e) => this.handleHopNumberChange(e.value)} step={1} min={1} max={4} range={true} />
                                </div>
                                <Button disabled={this.props.startTerm === "" || this.props.endTerm === "" || this.props.startTerm.length === 0 || this.props.endTerm.length === 0 ? true : false} className='#007FDB-grey-btn' icon="pi-md-search" onClick={this.onSearchWithHops} style={{ marginLeft: 10 }} />
                            </div>*}
                        {/*this.props.number === 0 &&
                            <InputNumber style={{ verticalAlign: '-webkit-baseline-middle', marginLeft: 10, marginTop: -5}} size={1} value={this.props.numberOfHops} onChange={(e) => this.handleHopNumberChange(e.value)} min={1} max={4} step={1} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger p-button-sm" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"  />
                        */}
                        {/*</div>*/}
                    </div>
                    <Dialog header={`Settings for triple ${this.props.number + 1}`} visible={this.state.visible} focusOnShow={false}
                        style={{ width: '50vw' }}
                        onHide={this.onHide}
                        dismissableMask={true}>
                        <Options
                            startTerm={this.props.startTerm}
                            startChecked={this.props.startChecked}
                            leftDomains={this.props.leftDomains}
                            endTerm={this.props.endTerm}
                            endChecked={this.props.endChecked}
                            rightDomains={this.props.rightDomains}
                            domain={this.props.logicals}
                            pathNumber={this.props.number}
                            callbackFromStartToggleChange={this.props.callbackFromStartToggleChange}
                            callbackFromEndToggleChange={this.props.callbackFromEndToggleChange} >
                        </Options>
                    </Dialog>
                    <Dialog visible={this.state.displayDialogExecute}
                        width="700px"
                        focusOnShow={false}
                        header={`Top matches for path ${this.props.number + 1}`}
                        modal={true} dismissableMask={true}
                        onHide={() => this.setState({ displayDialogExecute: false, selectedTriple: null })}>
                        <div className="col-12" style={{ marginTop: 10 }}>
                            <DataTable value={paths} scrollable={true} scrollHeight="500px"
                                selectionMode="single"
                                selection={this.state.selectedTriple}
                                onSelectionChange={e => this.setState({ selectedTriple: e.value })}
                                onRowSelect={this.onTripleSelect}>
                                <Column field="start" header="Entity 1" style={{ "fontWeight": 'normal' }} />
                                <Column field="relation" header="Relation" style={{ "fontWeight": 'normal' }} />
                                <Column field="end" header="Entity 2" style={{ "fontWeight": 'normal' }} />
                                <Column field="hits" header="Occurrences" sortable={true} style={{ "fontWeight": 'normal' }} />
                            </DataTable>
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.state.displayDialog} width="300px" header="Triple details" modal={true} footer={dialogFooter} onHide={() => this.setState({ displayDialog: false })}>
                        {
                            this.state.triple &&
                            <div className="grid p-fluid">
                                <div className="col-4" style={{ padding: '.75em' }}><label htmlFor="start">Entity 1:</label></div>
                                <div className="col-8" style={{ padding: '.5em' }}>
                                    <InputText readOnly={true} id="start" onChange={(e) => { this.updateProperty('start', e.target.value) }} value={this.state.triple.start} />
                                </div>
                                <div className="col-4" style={{ padding: '.75em' }}><label htmlFor="relation">Relation:</label></div>
                                <div className="col-8" style={{ padding: '.5em' }}>
                                    <InputText readOnly={true} id="relation" onChange={(e) => { this.updateProperty('relation', e.target.value) }} value={this.state.triple.relation} />
                                </div>
                                <div className="col-4" style={{ padding: '.75em' }}><label htmlFor="end">Entity 2:</label></div>
                                <div className="col-8" style={{ padding: '.5em' }}>
                                    <InputText readOnly={true} id="end" onChange={(e) => { this.updateProperty('end', e.target.value) }} value={this.state.triple.end} />
                                </div>
                                <div className="col-4" style={{ padding: '.75em' }}><label htmlFor="hits">Occurrence:</label></div>
                                <div className="col-8" style={{ padding: '.5em' }}>
                                    <InputText readOnly={true} id="hits" onChange={(e) => { this.updateProperty('hits', e.target.value) }} value={this.state.triple.hits} />
                                </div>
                            </div>
                        }
                    </Dialog>
                    <Dialog focusOnShow={false}
                        className='dialog'
                        id='relationDialog'
                        visible={this.state.displayDialogEditRelation}
                        header={`Relation ${this.state.relatioNumber}`}
                        modal={true} footer={footerEditRelation}
                        dismissableMask={true}
                        onHide={(e) => this.handleCancelEditRelation(e)}
                        style={{ width: '20vw' }}>
                        <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 15, marginRight: 15 }}>
                            <div>
                                {this.modes.map((mode) => {
                                    return (
                                        <div style={{ display: 'inline' }} key={mode.key} className="p-field-radiobutton">
                                            <RadioButton
                                                style={{ verticalAlign: 'middle' }}
                                                inputId={mode.key} name="mode" value={mode}
                                                onChange={(e) => this.onRelationToggleChange(e)}
                                                checked={this.state.relationMode === mode.key} />
                                            <label style={{ color: 'black', marginRight: 10, marginLeft: '0.5rem' }} htmlFor={mode.key}>{mode.name}</label>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false}
                        blockScroll
                        className='dialog'
                        id='startEndDialog'
                        visible={this.state.displayDialogEditEntity}
                        header={`Entity ${this.state.entityNumber}`}
                        modal={true} footer={footerEditEntity}
                        dismissableMask={true}
                        onHide={(e) => this.handleCancelEdit(e)}
                        style={{ width: '60vw' }} >
                        <div style={{ borderBottom: '1px solid #d6d6d6', paddingLeft: 20, paddingRight: 15, paddingBottom: 15, paddingTop: 15, marginRight: 0 }}>
                            <div>
                                <label style={{
                                    fontSize: '13px', paddingRight: 5, marginBottom: 5, color: '#757575', paddingLeft: 5,
                                    width: '90%', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>{this.state.startEnd === 'start' ? leftDomains : rightDomains}</label>
                            </div>
                            <div className='col-12'>
                                {this.state.startEnd === 'start' ?
                                    <InputFieldWithClear
                                        onClear={(e) => this.clearStart(e)}
                                        showIcon={!isArrayEmpty(this.props.startTerm)}>
                                        <AutoComplete
                                            field="term"
                                            autoFocus={true}
                                            //appendTo={document.getElementById('startEndDialog')}
                                            //panelStyle={{maxHeight: '200px !important'}}
                                            //id='autoPath'
                                            //scrollHeight={"400px"}
                                            value={startTermsAC}
                                            //suggestions={dataStartTerms}
                                            suggestions={this.data}
                                            completeMethod={this.filterStartTerm}
                                            className='dialogAutocomplete'
                                            placeholder={this.props.logicals === 'free' ?
                                                "Type term (e.g. diabetes mellitus)" : this.props.logicals === 'ctd' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'ctd' && this.props.number === 0 ? "type e.g. aspirin or leave empty" :
                                                    this.props.logicals === 'cip' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'cip' && this.props.number === 0 ? 'type e.g. acetylsalicylic acid or leave empty' :
                                                        this.props.logicals === 'caa' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'caa' && this.props.number === 0 ? 'type e.g. acetylsalicylic acid or leave empty' :
                                                            this.props.logicals === 'cid' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'cid' && this.props.number === 0 ? 'type e.g. testosterone or leave empty' :
                                                                this.props.logicals === 'pid' && this.props.number > 0 ? 'domain proteins' : this.props.logicals === 'pid' && this.props.number === 0 ? 'type e.g. HDL or leave empty' :
                                                                    this.props.logicals === 'cic' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'cic' && this.props.number === 0 ? "type e.g. cholesterol or leave empty" :
                                                                        this.props.logicals === 'pmc' && this.props.number > 0 ? 'domain proteins' : this.props.logicals === 'pmc' && this.props.number === 0 ? "type e.g. dihydrofolate reductase or leave empty" :
                                                                            this.props.logicals === 'paa' && this.props.number > 0 ? 'domain proteins' : this.props.logicals === 'paa' && this.props.number === 0 ? "type e.g. dihydrofolate reductase or leave empty" :
                                                                                this.props.logicals === 'cipp' && this.props.number > 0 ? 'domains chemistry, natural products, substances and drugs' : this.props.logicals === 'cipp' && this.props.number === 0 ? 'type e.g. 17β-estradiol or leave empty' :
                                                                                    this.props.logicals === 'pmpp' && this.props.number > 0 ? 'domain proteins' : this.props.logicals === 'pmpp' && this.props.number === 0 ? 'type e.g. matrix metalloproteinase 9 or leave empty' :
                                                                                        this.props.logicals === 'ppmp' && this.props.number > 0 ? 'domain effects' : this.props.logicals === 'ppmp' && this.props.number === 0 ? 'type e.g. angiogenesis or leave empty' :
                                                                                            this.props.logicals === 'ppaa' && this.props.number > 0 ? 'domain effects' : this.props.logicals === 'ppaa' && this.props.number === 0 ? 'type e.g. angiogenesis or leave empty' :
                                                                                                this.props.logicals === 'ppipp' && this.props.number > 0 ? 'domain effects' : this.props.logicals === 'ppipp' && this.props.number === 0 ? 'type e.g. aging or leave empty' :
                                                                                                    this.props.logicals === 'pppd' && this.props.number > 0 ? 'domain effects' : this.props.logicals === 'pppd' && this.props.number === 0 ? 'type e.g. exercise or leave empty' :
                                                                                                        this.props.logicals === 'dmp' && this.props.number > 0 ? 'domain diseases' : this.props.logicals === 'dmp' && this.props.number === 0 ? 'type e.g. diabetes mellitus or leave empty' :
                                                                                                            this.props.logicals === 'daa' && this.props.number > 0 ? 'domain diseases' : this.props.logicals === 'daa' && this.props.number === 0 ? 'type e.g. diabetes mellitus or leave empty' :
                                                                                                                this.props.logicals === 'aaa' || this.props.logicals === 'aac' || this.props.logicals === 'aad' || this.props.logicals === 'aapp' || this.props.logicals === 'aap' ? 'type e.g. aspirin or leave empty' :
                                                                                                                    "Type start term"}
                                            minLength={1}
                                            multiple={true}
                                            onChange={(e) => this.handleStartTermChange(e)}
                                            style={{ width: '100%', display: 'grid' }}
                                            disabled={this.props.number > 0
                                                ? true : false}
                                            selectedItemTemplate={this.selItemTemplateStart}
                                            itemTemplate={this.itemTemplate}
                                            autoHighlight={true}
                                        />
                                    </InputFieldWithClear>
                                    :
                                    <InputFieldWithClear
                                        onClear={(e) => this.clearEnd(e)}
                                        showIcon={!isArrayEmpty(this.props.endTerm)}>
                                        <AutoComplete
                                            value={endTermsAC}
                                            //appendTo={document.body}
                                            //id='autoPath'
                                            //appendTo={document.getElementById('startEndDialog')}
                                            //suggestions={dataEndTerms}
                                            suggestions={this.data}
                                            field="term"
                                            completeMethod={this.filterEndTerm}
                                            className='dialogAutocomplete'
                                            placeholder={this.props.logicals === 'free' ? "Type term (e.g. caspase 3)" :
                                                this.props.logicals === 'ctd' ? "type e.g. headache or leave empty" :
                                                    this.props.logicals === 'pppd' ? "type e.g. cardiovascular diseases or leave empty" :
                                                        this.props.logicals === 'cip' || this.props.logicals === 'aap' ? 'type e.g. cyclooxygenase-1 or leave empty' :
                                                            this.props.logicals === 'cid' || this.props.logicals === 'aad' ? 'type e.g. androgenic alopecia or leave empty' :
                                                                this.props.logicals === 'pid' ? 'type e.g. arteriosclerosis or leave empty' :
                                                                    this.props.logicals === 'cic' || this.props.logicals === 'aac' ? 'type e.g. 25-hydroxycholesterol or leave empty' :
                                                                        this.props.logicals === 'pmc' ? "type e.g. folic acid or leave empty" :
                                                                            this.props.logicals === 'cipp' ? "type e.g. blood pressure or leave empty" :
                                                                                this.props.logicals === 'ppipp' ? "type e.g. mitochondrial dysfunction or leave empty" :
                                                                                    this.props.logicals === 'pmpp' || this.props.logicals === 'aapp' ? "type e.g. cell migration or leave empty" :
                                                                                        this.props.logicals === 'ppmp' ? "type e.g. urokinase-type plasminogen activator or leave empty" :
                                                                                            this.props.logicals === 'aaa' || this.props.logicals === 'caa' || this.props.logicals === 'daa' || this.props.logicals === 'ppaa' || this.props.logicals === 'paa' ? "type e.g. headache or leave empty" :
                                                                                                this.props.logicals === 'dmp' ? "type e.g. caspase 3 or leave empty" : "Type end term"}
                                            minLength={1}
                                            multiple={true}
                                            onChange={(e) => this.handleEndTermChange(e)}
                                            style={{ width: '100%', display: 'grid' }}
                                            selectedItemTemplate={this.selItemTemplateEnd}
                                            itemTemplate={this.itemTemplate}
                                            autoHighlight={true}
                                        />
                                    </InputFieldWithClear>
                                }
                            </div>
                            <div style={{ paddingTop: 15, paddingBottom: 22, paddingLeft: 5 }}>
                                {this.state.startEnd === 'start' && this.props.startTerm.length !== 0 ?
                                    this.modes.map((mode) => {
                                        return (
                                            <div style={{ display: 'inline' }} key={mode.key} className="p-field-radiobutton">
                                                <RadioButton disabled={this.props.startTerm.length === 0 ? true : false}
                                                    inputId={mode.key} name="mode" value={mode}
                                                    onChange={(e) => this.onStartToggleChange(e)}
                                                    checked={this.state.selectedMode === mode.key}
                                                    style={{ verticalAlign: 'middle' }} />
                                                <label style={{ color: 'black', marginRight: 10, marginLeft: '0.5rem', verticalAlign: 'middle' }} htmlFor={mode.key}>{mode.name}</label>
                                            </div>
                                        )
                                    })
                                    : this.state.startEnd === 'end' && this.props.endTerm.length !== 0 ?
                                        this.modes.map((mode) => {
                                            return (
                                                <div style={{ display: 'inline' }} key={mode.key} className="p-field-radiobutton">
                                                    <RadioButton disabled={this.props.endTerm.length === 0 ? true : false}
                                                        inputId={mode.key}
                                                        name="mode"
                                                        value={mode}
                                                        onChange={(e) => this.onEndToggleChange(e)}
                                                        style={{ verticalAlign: 'middle' }}
                                                        //checked={this.props.endChecked === mode.key} 
                                                        checked={this.state.selectedMode === mode.key} />
                                                    <label style={{ color: 'black', marginRight: 10, marginLeft: '0.5rem', verticalAlign: 'middle' }} htmlFor={mode.key}>{mode.name}</label>
                                                </div>
                                            )
                                        })
                                        : null
                                }
                                {this.state.startEnd === 'start' ?
                                    <div style={{ paddingTop: 10 }}>
                                        {this.props.startTerm.length > 0 &&
                                            <a title='Clear input' onClick={e => this.clearStart(e)}
                                                style={{ float: 'left', paddingTop: 5 }}>
                                                Clear
                                            </a>
                                        }
                                        <a title='Open domain explorer' onClick={e => this.onDomainClick(e, 'start')}
                                            style={{ float: 'right', paddingRight: 10, paddingTop: 5 }}>
                                            Add with domain explorer
                                        </a>
                                    </div>
                                    :
                                    <div style={{ paddingTop: 10 }}>
                                        {this.props.endTerm.length > 0 &&
                                            <a title='Clear input' onClick={e => this.clearEnd(e)}
                                                style={{ float: 'left', paddingTop: 5 }}>
                                                Clear
                                            </a>
                                        }
                                        <a title='Open domain explorer' onClick={e => this.onDomainClick(e, 'end')}
                                            style={{ float: 'right', paddingRight: 10, paddingTop: 5 }}>
                                            Add with domain explorer
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </Dialog>
                    {/*<Dialog visible={this.state.displayDialogDomains}
                        style={{ width: '90vw' }}
                        focusOnShow={false}
                        header='Domain explorer'
                        modal={true} dismissableMask={true}
                        onHide={() => this.setState({ displayDialogDomains: false })}>
                        <div className="col-12" style={{ marginTop: 10 }}>
                            <OntologyBrowserView
                                availableDomains={this.props.availableDomains}
                                withPredefinedDomainsMultiple={true}
                                selectedDomains={this.state.domain}
                                callbackFromSubmit={this.myCallbackFromSubmit}
                                dontShowHeader={true}>
                            </OntologyBrowserView>
                           
                        </div>
                        </Dialog>*/}
                    <OntologyBrowserDialog
                        ontBrowserDialogID="docSearchDomBrowserDlg"
                        headerLabel="Domain explorer"
                        selectionMode="multiple"
                        placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                        placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                        domains={ontBrowserDialogDomains}
                        numOfChildNodes={10} // not used for preloaded ontology
                        allowConceptSearchByClick={true}
                        allowSearchInOntologies={true}
                        loadOntologiesOnStart={true}
                        ontBrowserVisible={this.state.displayDialogDomains}
                        onOntBrowserClose={() => this.setState({ displayDialogDomains: false, initialSearchTerm: null })}
                        onOntBrowserSubmit={this.myCallbackFromSubmit}
                        onOntBrowserShow={() => { }}
                        width="90vw"
                        height="90vh"
                        initialSearchTerm={this.state.initialSearchTerm}>
                    </OntologyBrowserDialog>
                </div>
            </LoadingOverlay>
        )
    }
}

const mapStateToProps = (state) => ({
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
    domainColors: state.user.data.userDetails.department.selectedDomains
})
const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InferencePathComponent));