import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";
import { REPOSITORY_COLORS } from "../../../../../properties";
import './RepositoriesBarChart.css';
import {addThousandsSeparatorToNumber} from "../../../util";

const RepositoriesBarChart = ({barChartData, width}) => {
    const bars = Object?.keys(barChartData?.[0])?.map((bar, index) => <Bar key={bar} dataKey={bar} stackId="a"
                                                                           fill={`${REPOSITORY_COLORS[index]}`}/>)
    const leftIndent = 14; // the distance chosen experimentally so that the bar chart has the desired width

    return (
        <BarChart
            width={width - leftIndent}
            height={15}
            margin={{top: 0, left: 0, right: 0, bottom: 0}}
            data={barChartData}
            layout="vertical"
            className='biomarker-barchart'
        >
            <XAxis type="number" hide domain={['dataMin', 'dataMax']} unit='tips'/>
            <YAxis type="category" dataKey="name" hide/>
            <Tooltip itemStyle={{overflow: "hidden", whiteSpace: "break-spaces"}} wrapperStyle={{top: 15, width: width - leftIndent}} unit='tips' formatter={value => `${addThousandsSeparatorToNumber(value)} hits`}/>
            {bars}
        </BarChart>
    );
}

export default RepositoriesBarChart;
