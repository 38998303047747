import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

export const createXaxis = (chart, categoryAxis) => {
    categoryAxis.current = chart.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.current.dataFields.category = "date";
    categoryAxis.current.renderer.labels.template.fill = am4core.color('#757575');
    categoryAxis.current.renderer.minGridDistance = 70;
    categoryAxis.current.renderer.grid.template.strokeWidth = 0;
    categoryAxis.current.startLocation = -0.5;
    categoryAxis.current.endLocation = 0.5;
}
