import { Component } from 'react';
import _ from 'lodash';
import InfoOverlayWithIcon from '../../general/InfoOverlayWithIcon';
import { addThousandsSeparatorToNumber, roundNumber, roundNumberUpToNextLevel, shortenLargeNumber } from '../../util';
import { CHEM_DB_STATE_ERROR, CHEM_DB_STATE_INITIALISED, CHEM_DB_STATE_LOADING, CHEM_DB_STATE_SUCCESS, getNumberOfResults } from '../utils/chemistryResults';
import { destructureChemDBKey } from '../utils/chemistrySearchUtil';


class ChemistryDBOverview extends Component {

    onRepositoryClick = (repositoryInfo, allowClick) => {
        if (allowClick) {
            this.props.onSelectChemDB(repositoryInfo);
        }
    }

    renderSelectedChemDB = () => {
        // TODO
    }

    renderLabel = (result, chemDB) => {
        let labelElement = '';
        if (result?.hasError) {
            labelElement = <span style={{ textDecoration: 'line-through' }}>{chemDB.label ? chemDB.label : chemDB.service}</span>;
        }
        else {
            labelElement = <span>{chemDB.label ? chemDB.label : chemDB.service}</span>;
        }

        return <div style={{ textTransform: 'uppercase', fontSize: 15 }}>
            {labelElement}
        </div>
    }

    renderInfoText = (result, isSelChemDB, multipleStructuresProblem, startIndex, defaultInfo = '') => {
        let infoElement = defaultInfo;
        if (isSelChemDB) {
            if (multipleStructuresProblem) {
                infoElement = <span>Not available for input</span>;
            }
            else if (result) {
                if (result.hasError) {
                    infoElement = <span title={result.message}>Not available</span>;
                }
                // What is the difference???
                else if (result.status === CHEM_DB_STATE_ERROR) {
                    infoElement = <span>Not available</span>;
                }
                else if (result.status === CHEM_DB_STATE_LOADING) {
                    infoElement = <span>Searching in database...</span>;
                }
                else if (result.status === CHEM_DB_STATE_SUCCESS && isSelChemDB) {
                    const { numberOfHits, isFPHitCount } = getNumberOfResults(result, startIndex);

                    if (numberOfHits < 0) {
                        infoElement = `Unknown number of hits`;
                    }
                    else if (numberOfHits === 0) {
                        infoElement = `No hits`;
                    }
                    else {
                          infoElement = isFPHitCount ? (
                            <span>
                              {`Less than ${shortenLargeNumber(roundNumberUpToNextLevel(numberOfHits))} ${numberOfHits === 1 ? 'hit' : 'hits'}`}
                            </span>
                          ) : (
                            <span>{`${addThousandsSeparatorToNumber(numberOfHits)} ${numberOfHits === 1 ? 'hit' : 'hits'}`}</span>
                          );

                        // infoElement = <span>{`${hitInfo} ${numberOfHits === 1 ? 'hit' : 'hits'}`}</span>;
                        // infoElement = <span>{`${preText}${addThousandsSeparatorToNumber(numberOfHits)} ${numberOfHits === 1 ? 'hit' : 'hits'}`}</span>;
                    }
                }
            }
        }

        return <div id='chemSearchHits' style={{ fontSize: 13 }}>
            {infoElement}
        </div>
    }


    render() {
        const { chemDBItems, chemDBStatistics, selectedChemDB, onSelectChemDB, multipleQueryStructures, blockSearch } = this.props; // repoStatisticsResults

        return (
            <>
                <div className='grid'
                    style={{ marginTop: 25, borderTop: '1px solid #000000' }}>
                    {chemDBStatistics ?
                        Object.entries(chemDBStatistics).map(([chemDBService, result]) => {

                            const [service, repositoryID] = destructureChemDBKey(chemDBService);
                            const isSelChemDB = result?.status !== CHEM_DB_STATE_INITIALISED &&
                                selectedChemDB?.service === service && selectedChemDB?.repositoryID == repositoryID;
                            const chemDB = chemDBItems?.find(db => { return db.service === service && db.repositoryID == repositoryID });
                            //const numOfHits = result?.totalHits ? result?.totalHits : result?.numberFingerprintHits ? result?.numberFingerprintHits : 0;
                            //const isLoading = result?.status === CHEM_DB_STATE_LOADING;
                            const multipleStructuresProblem = !chemDB?.multipleStructuresAllowed && multipleQueryStructures;
                            const tooltip = result?.numberTotalHitsInRequestedIndex ? `There are currently ${addThousandsSeparatorToNumber(result.numberTotalHitsInRequestedIndex)} entries in this database.` : '';

                            return <div
                                key={chemDBService}
                                title={tooltip}
                                onClick={() => {
                                    if (!blockSearch && !isSelChemDB && !multipleStructuresProblem && chemDB) {
                                        onSelectChemDB(chemDB);
                                    }
                                }}
                                className="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4"
                                style={{
                                    paddingTop: isSelChemDB ? 0 : 2,
                                    paddingLeft: 0,
                                    cursor: blockSearch ? 'progress' : !isSelChemDB && !multipleStructuresProblem ? 'pointer' : '',
                                    opacity: blockSearch || multipleStructuresProblem ? 0.3 : 1,
                                    borderTop: isSelChemDB ? '3px solid #000000' : '1px solid #000000',
                                    position: 'relative',
                                    top: -1,
                                    background: '#ffffff',
                                }}>
                                <div style={{ fontWeight: `${isSelChemDB ? 'bold' : 'normal'}`, pointerEvents: `${isSelChemDB ? 'none' : ''}`, padding: 10, paddingLeft: 0 }}>
                                    {chemDB ?
                                        <div className="grid">
                                            <div className="col-fixed">
                                                {this.renderLabel(result, chemDB)}
                                                {this.renderInfoText(result, isSelChemDB, multipleStructuresProblem, this.props.startIndex, chemDB.shortInfo)}
                                            </div>
                                            <div className="col">
                                                {/*isLoading ?
                                                <ProgressSpinner
                                                    className="valignMiddle"
                                                    style={{ width: 30, height: 30, marginLeft: 10 }} /> : null*/}
                                            </div>
                                        </div>
                                        : ""}
                                </div>
                            </div>
                        })
                        : null
                    }
                </div>
            </>
        )
    }
}
export default ChemistryDBOverview