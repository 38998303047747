import axios from "axios";
import { useEffect, useState } from "react";
import { getHeaderToken } from "../../../api";
import { createUserArray, fetchOrgsAndSuborgs, hasUserRole } from "../../webapi/util";
import { APP_PROPERTIES } from "../../../properties";
import { createNewApp, editApp } from "../../../api/content/AppsApi";
import { fetchAppsForUser } from "../../../redux/actions";
import { fetchAllReadcubeLibraries } from "../../../api/TestApi"
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import { useDispatch } from 'react-redux';

const useCreateEditApp = ({ userData, onShowToast }) => {
  const [userArray, setUserArray] = useState([]);
  const [orgArray, setOrgArray] = useState([]);
  //const [allOrgsAndSuborgs, setAllOrgsAndSuborgs] = useState({});
  const [departmentMembers, setDepartmentMembers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [queryCollection, setQueryCollection] = useState([]);
  const [documentCollections, setDocumentCollections] = useState([]);
  const [readcubeCollections, setReadCubeCollections] = useState([]);
  const dispatch = useDispatch()

  useEffect(() => {
    // Fetch department members, templates, and query collections on component mount
    fetchDepartmentMembers();
    fetchTemplates();
    fetchQueryCollection();
    fetchDocumentCollections()
    if (hasUserRole('ROLE_READCUBEVIEW')) {
      fetchReadcubeLibraries()
    }
  }, []);

  // Fetch department members
  const fetchDepartmentMembers = () => {
    axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/users`, {
      headers: getHeaderToken(),
    })
      .then(function (response) {
        // Update department members and user array state
        setDepartmentMembers(response.data);
        setUserArray(createUserArray(response.data, userData, 'user'));
        setOrgArray(createUserArray(response.data, userData, 'org'));
        //setAllOrgsAndSuborgs(fetchOrgsAndSuborgs(userData))
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          onShowToast(error.response.data);
        }
      });
  };

  // Fetch templates
  const fetchTemplates = () => {
    axios.get(
      `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards/templates`,
      { headers: getHeaderToken() }
    )
      .then(function (response) {
        // Update templates state
        setTemplates(response.data);
      })
      .catch(function (error) {
        onShowToast(error.response.data);
      });
  };

  // Fetch query collection
  const fetchQueryCollection = () => {
    axios.post(
      `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/page/0/count/100000/list`,
      {},
      { headers: getHeaderToken() }
    )
      .then(function (response) {
        let allWatchlists = response.data.content.filter(function (collection) {
          return collection.name !== "History" && collection.name !== "My Favorites" && collection.numberQueries > 0;
        });
        // Update query collection state
        setQueryCollection(allWatchlists);
      })
      .catch(function (error) {
        onShowToast(error.response.data);
      });
  };

  // Fetch document collections
  const fetchDocumentCollections = () => {
    axios.get(
      `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/page/0/count/100000/list`,
      { headers: getHeaderToken() }
    )
      .then(function (response) {
        let allDocumentCollections = response.data.content.filter(function (collection) {
          return collection.numberDocuments > 0;
        });
        // Update query collection state
        setDocumentCollections(allDocumentCollections);
      })
      .catch(function (error) {
        onShowToast(error.response.data);
      });
  };

  const fetchReadcubeLibraries = async () => {
    try {
      const response = await fetchAllReadcubeLibraries();
      if (response?.payload) {
        setReadCubeCollections(response?.payload)
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Submit create/edit app
  const submitCreateEditApp = async (data, onHideOverlay) => {
    let request = {}
    let usersSharedCombined = data?.usersShared.concat(data?.usersSharedInvisible)

    if ((data?.selectedTemplate !== 'static' && data?.selectedTemplate !== 'open_science_tab1' && data?.selectedTemplate !== 'open_science_tab4' && data?.selectedTemplate !== 'pubtracker') && data?.selectedSourceType?.id === 1) {
      request = {
        appID: data?.appID,
        dashboardTemplate: data?.selectedTemplate,//'TEST_DASHBOARD_TEMPLATE',//data?.selectedTemplate,
        departmentShares: data?.orgShared.map((item) => parseInt(item)),//data?.orgShared.map((item) => parseInt(item.split("-")[0])),
        description: data?.appDescription,
        comments: data?.appComments,
        keepUpdating: true,
        name: data?.appName,
        group: data?.appGroup !== "" ? data?.appGroup : null,
        queryCollectionIdentifier: data?.selectedSourceName.id,
        repositoryIdentifiers: data?.selectedRepos.map((item) => item.repositoryId),
        sendEmail: true,
        updateCycle: data?.dropdownValueUpdateCycle,
        userShares: data?.usersSharedInvisible ? usersSharedCombined : data?.usersShared
      };
    } else if ((data?.selectedTemplate !== 'static' && data?.selectedTemplate !== 'open_science_tab1') && data?.selectedSourceType?.id === 2) {
      request = {
        appID: data?.appID,
        dashboardTemplate: data?.selectedTemplate,//'TEST_DASHBOARD_TEMPLATE',//data?.selectedTemplate,
        departmentShares: data?.orgShared.map((item) => parseInt(item)),//data?.orgShared.map((item) => parseInt(item.split("-")[0])),
        description: data?.appDescription,
        comments: data?.appComments,
        keepUpdating: true,
        name: data?.appName,
        group: data?.appGroup !== "" ? data?.appGroup : null,
        libraryIdentifier: data?.selectedSourceName.id,
        //repositoryIdentifiers: data?.selectedRepos.map((item) => item.repositoryId),
        sendEmail: true,
        updateCycle: data?.dropdownValueUpdateCycle,
        userShares: data?.usersSharedInvisible ? usersSharedCombined : data?.usersShared
      }
    } else if (data?.selectedTemplate === 'static') {
      request = {
        appID: data?.appID,
        dashboardTemplate: data?.selectedTemplate,//'TEST_DASHBOARD_TEMPLATE',//data?.selectedTemplate,
        departmentShares: data?.orgShared.map((item) => parseInt(item)),//data?.orgShared.map((item) => parseInt(item.split("-")[0])),
        description: data?.appDescription,
        comments: data?.appComments,
        name: data?.appName,
        group: data?.appGroup !== "" ? data?.appGroup : null,
        url: data?.appURL,
        expiryDate: data?.expirationDate && data?.expirationDate !== null ? dateFormat(data?.expirationDate, 'yyyy-mm-dd') : null,
        userShares: data?.usersSharedInvisible ? usersSharedCombined : data?.usersShared
      }
    } else if (data?.selectedTemplate === 'open_science_tab1') {
      //console.log(data)
      request = {
        appID: data?.appID,
        dashboardTemplate: data?.selectedTemplate,//'TEST_DASHBOARD_TEMPLATE',//data?.selectedTemplate,
        departmentShares: data?.orgShared.map((item) => parseInt(item)),//data?.orgShared.map((item) => parseInt(item.split("-")[0])),
        description: data?.appDescription,
        comments: data?.appComments,
        name: data?.appName,
        group: data?.appGroup !== "" ? data?.appGroup : null,
        userShares: data?.usersSharedInvisible ? usersSharedCombined : data?.usersShared,
        keepUpdating: true,
        sendEmail: true,
        updateCycle: data?.dropdownValueUpdateCycle
      }
    } else if (data?.selectedTemplate === 'open_science_tab4' || data?.selectedTemplate === 'pubtracker') {
      request = {
        appID: data?.appID,
        dashboardTemplate: data?.selectedTemplate,//'TEST_DASHBOARD_TEMPLATE',//data?.selectedTemplate,
        departmentShares: data?.orgShared.map((item) => parseInt(item)),//data?.orgShared.map((item) => parseInt(item.split("-")[0])),
        description: data?.appDescription,
        comments: data?.appComments,
        keepUpdating: true,
        name: data?.appName,
        group: data?.appGroup !== "" ? data?.appGroup : null,
        queryCollectionIdentifier: data?.selectedSourceName.id,
        repositoryIdentifiers: data?.selectedRepos.map((item) => item.repositoryId),
        readCubeCollectionIdentifier: data?.selectedReadcubeSource.id,
        sendEmail: true,
        updateCycle: data?.dropdownValueUpdateCycle,
        userShares: data?.usersSharedInvisible ? usersSharedCombined : data?.usersShared
      }
    }

    let response

    if (data.isEdit) {
      response = await editApp(request);
    } else {
      delete request.appID
      response = await createNewApp(request);
    }

    if (response.status === "SUCCESS") {
      //dispatch(fetchAppsForUser())
      if (data.isEdit) {
        onShowToast(data?.selectedTemplate !== 'static' ?
          "App successfully edited. Depending on the data that was changed (e.g. repositories) updating the app will take some time. The changes will be visible after the next update date or after refreshing."
          : 'App successfully edited.', "success", "App edited");
      } else {
        onShowToast(<>
          <div>
            {
              data?.selectedTemplate !== 'static' ?
                "App successfully requested. Depending on the number of searches/documents and their complexity the creation of this app may take some time. You will be informed by email as soon as the app is available."
                : "Static app successfully created."
            }
          </div>
          {(data?.initialData?.library !== undefined || data?.initialData?.queryCollection !== undefined) && data?.selectedTemplate !== 'static' && data?.selectedTemplate !== 'open_science_tab1' ?
            <div className="export-center-link-container">
              <Link
                to="/my/apps"
                target="_blank"
                title='Visit "My Apps"'
                className="export-center-message-link">
                My Research &rarr; My Apps
              </Link>
            </div>
            : null
          }
        </>, "success", data?.selectedTemplate !== 'static' ? "App requested" : "App created");
      }
      onHideOverlay();
    } else if (response.status === "FAILED" || response.status === "LOGGEDOUT") {
      onShowToast("An error occurred.");
    }
  };

  return {
    userArray,
    orgArray,
    //allOrgsAndSuborgs,
    departmentMembers,
    submitCreateEditApp,
    queryCollection,
    documentCollections,
    readcubeCollections,
    templates,
  };
};

export default useCreateEditApp;
