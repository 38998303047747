// "viewport" rectangle is { top, left, width, height }

// "scaled" means that data structure stores (0, 1) coordinates.
// for clarity reasons I decided not to store actual (0, 1) coordinates, but
// provide width and height, so user can compute ratio himself if needed

import type { LTWH, Scaled, Viewport } from "../types";

interface WIDTH_HEIGHT {
  width: number;
  height: number;
}

export const viewportToScaled = (
  rect: LTWH,
  { width, height }: WIDTH_HEIGHT
): Scaled => {
  return {
    x1: rect.left,
    y1: rect.top,

    x2: rect.left + rect.width,
    y2: rect.top + rect.height,

    width,
    height,
  };
};


export const scaledToViewport = (
  scaled: Scaled,
  pageHeight: number,
  pageWidth: number,
  viewport: Viewport,
): LTWH => {
  const { width, height, rotation } = viewport;

  
  const viewBoxX = viewport?.viewBox?.length > 0 ? viewport.viewBox[0] * viewport.scale :0 ;
  const viewBoxY = viewport?.viewBox?.length > 0 ? viewport.viewBox[1] * viewport.scale:0;
  if (scaled.x1 === undefined) {
    throw new Error("You are using old position format, please update");
  }

  if (rotation === 90) {
    const x1 = (height * scaled.y1) / pageWidth;
    const y1 = (width * scaled.x1) / pageHeight;
    const x2 = (height * scaled.y2) / pageWidth;
    const y2 = (width * scaled.x2) / pageHeight;

    return {
      left: (width - (x2 - x1)) - x1 + viewBoxX,
      top: y1 + viewBoxY,
      width: x2 - x1,
      height: y2 - y1,
    };
  }
  else if (rotation === 270) {
    const x1 = (height * scaled.y1) / pageWidth;
    const y1 = (width * scaled.x1) / pageHeight;
    const x2 = (height * scaled.y2) / pageWidth;
    const y2 = (width * scaled.x2) / pageHeight;

    return {
      left: x1 - viewBoxX,
      top: (height - (y2 - y1)) - y1 - viewBoxY,
      width: x2 - x1,
      height: y2 - y1,
    };
  }

  else if (rotation === 180) {

    const x1 = (width * scaled.x1) / pageWidth;
    const y1 = (height * scaled.y1) / pageHeight;
    const x2 = (width * scaled.x2) / pageWidth;
    const y2 = (height * scaled.y2) / pageHeight;


    return {
      left: (width - (x2 - x1)) - x1 + viewBoxX,
      top: (height - (y2 - y1)) - y1 - viewBoxY,
      width: x2 - x1,
      height: y2 - y1,
    };
  }

  else {
    const x1 = (width * scaled.x1) / pageWidth;
    const y1 = (height * scaled.y1) / pageHeight;
    const x2 = (width * scaled.x2) / pageWidth;
    const y2 = (height * scaled.y2) / pageHeight;

    return {
      left: x1 - viewBoxX,
      top: y1 +  viewBoxY,
      width: x2   - x1 ,
      height: y2 - y1,
    };
  }

};

