import React, { Component, createRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from 'primereact/chips';
//import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import OntologyBrowserDialog from '../../../ontbrowser/OntologyBrowserDialog';
import * as OntologyClassUtil from '../../util/OntologyClassUtil';
import * as PhraseTokenUtil from '../../util/PhraseTokenUtil';
import { cloneDeep, removeValueFromArray, deduplicateArrayValues, isArrayEmpty, removeArrayOfValuesFromArray } from '../../../util';
import { fetchConceptsData, createConceptDataRequest } from '../../../../../api/content/ConceptApi';
import { checkResultAndGetPayload } from '../../../../../api';
import { Toast } from 'primereact/toast';
import ConfirmationDialog from '../../../../common/ConfirmDialog/ConfirmationDialog';



class EditTokenDialog extends Component {

    constructor(props) {
        super(props);

        const types = this.createTokenTypesList(props.type, props.isGroup);
        const tokenDataMap = this.createAttributesButtons(props.type, props.isGroup);

        this.state = {
            types: types,
            tokenDataMap: tokenDataMap,
            neDomains: [],
            neIsBlacklist: false,
        }

        this.growl = createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        //console.log('nextProps: ', nextProps);
        //console.log('isGroup: ', nextProps.isGroup);

        if (this.props.token !== nextProps.token) {

            const types = this.createTokenTypesList(nextProps.type, nextProps.isGroup);
            const tokenDataMap = this.createAttributesButtons(nextProps.type, nextProps.isGroup);

            // --- remove duplicates from array --- //
            if (nextProps.token && nextProps.token.ocid && nextProps.token.ocid.length > 0) {
                nextProps.token.ocid = deduplicateArrayValues(nextProps.token.ocid);
            }
            if (nextProps.token && nextProps.token.negOcid && nextProps.token.negOcid.length > 0) {
                nextProps.token.negOcid = deduplicateArrayValues(nextProps.token.negOcid);
            }
            // --- retrieve preferred names for all OCIDs --- //
            this.updatePrefNames(nextProps.token);

            this.setState({
                types: types,
                tokenDataMap: tokenDataMap,
                token: nextProps.token ? cloneDeep(nextProps.token) : nextProps.token,
                neIsBlacklist: nextProps.token && nextProps.token.negOcid && nextProps.token.negOcid.length > 0,
            });
        }
    }



    createTokenTypesList = (tokenType, isGroup) => {

        const types = [];

        if (isGroup) {
            types.push({ label: 'Group', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_GROUP });
        }
        else {
            types.push({ label: 'Any', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_ANY });
            types.push({ label: 'Adjective', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_ADJECTIVE });
            types.push({ label: 'Adverb', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_ADVERB });
            //types.push({ label: 'Bracket (TODO)', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_BRACKET });
            types.push({ label: 'Determiner', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_DETERMINER });
            types.push({ label: 'Frame', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_FRAME });
            types.push({ label: 'Macro', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_MACRO });
            types.push({ label: 'Named Entity', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE });
            types.push({ label: 'Negation', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_NEGATION });
            types.push({ label: 'Number', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_NUMBER });
            types.push({ label: 'Pronoun', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_PRONOUN });
            types.push({ label: 'Punctuation', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_PUNCTUATION });
            types.push({ label: 'Reference', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_REFERENCE });
            if (tokenType === OntologyClassUtil.TAG_TYPE_SYNTAX) {
                types.push({ label: 'Role reference', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_ROLE_REFERENCE });
            }
            types.push({ label: 'Synset', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_SYNSET });
            types.push({ label: 'Text', value: PhraseTokenUtil.PHRASE_TOKEN_TYPE_TEXT });
        }

        return types;
    }


    createAttributesButtons = (tokenType, isGroup) => {

        const tokenDataMap = {};

        // --- add attributes independent of type --- //
        if (!isGroup) {
            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE] = {
                label: 'Token type', tooltip: 'token type (multiple possible)',
                value: PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE); }
            };
        }
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID] = {
            label: 'Allowed subtrees', tooltip: 'allowed subtrees', verb: 'allow subtrees',
            value: PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID); }
        };
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID] = {
            label: 'Blocked subtrees', tooltip: 'blocked subtrees', verb: 'block subtrees',
            value: PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID); }
        };
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT] = {
            label: 'Token text', tooltip: 'free text',
            value: PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT); }
        };
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_MIN] = {
            label: 'Min', tooltip: 'minimum number of occurrences',
            value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_MIN,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_MIN); }
        };
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_MAX] = {
            label: 'Max', tooltip: 'maximum number of occurrences',
            value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_MAX,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_MAX); }
        };
        if (isGroup) {
            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ALTERNATIVE] = {
                label: 'Alternatives', tooltip: 'alternative members flag (group members are alternatives, only one has to match)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ALTERNATIVE,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ALTERNATIVE); }
            };
            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_VARIABLE] = {
                label: 'Variable order', tooltip: 'variable members flag (group members can have variable order)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_VARIABLE,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_VARIABLE); }
            };
        }
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT] = {
            label: 'Is optional', tooltip: 'is optional',
            value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT); }
        };
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT_POSSESSIVE] = {
            label: 'Is optional+', tooltip: 'is optional possessive (similar to possessive in regular expressions)',
            value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT_POSSESSIVE,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT_POSSESSIVE); }
        };
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_FIRST] = {
            label: 'Is first', tooltip: 'is first token (must occur as first token in a phrase)',
            value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_FIRST,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_FIRST); }
        };
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_LAST] = {
            label: 'Is last', tooltip: 'is last token (must occur as last token in a phrase)',
            value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_LAST,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_LAST); }
        };
        tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_NO_MATCH] = {
            label: 'Is no match', tooltip: 'is no match (this pattern must not be matched)',
            value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_NO_MATCH,
            command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_NO_MATCH); }
        };

        // --- add attributes for type syntax --- //
        if (tokenType === OntologyClassUtil.TAG_TYPE_SYNTAX) {

            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID] = {
                label: 'ID', tooltip: 'ID (neccessary if token will be target for other token)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID); }
            };
            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE] = {
                label: 'Role', tooltip: 'role (the role this entity plays within this frame or syntax)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE); }
            };
        }
        // --- add attributes for type role (relationship) --- //
        else if (tokenType === OntologyClassUtil.TAG_TYPE_ROLE) {

            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_REQUIRED] = {
                label: 'Is required', tooltip: 'is required (in a relationship)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_REQUIRED,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_REQUIRED); }
            };
            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_UNIQUE] = {
                label: 'Is unique', tooltip: 'is unique (in a relationship)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_UNIQUE,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_UNIQUE); }
            };
            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_DEFAULT] = {
                label: 'Is default', tooltip: 'is default (is per default part of the relationship)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_DEFAULT,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_DEFAULT); }
            };
            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_SUBJECT] = {
                label: 'Is subject', tooltip: 'is subject (of the relationship)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_SUBJECT,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_SUBJECT); }
            };
            tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OBJECT] = {
                label: 'Is object', tooltip: 'is object (of the relationship)',
                value: PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OBJECT,
                command: (e) => { this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OBJECT); }
            };
        }

        return tokenDataMap;
    }



    /**
     * 
     */
    addToToken = (token, tokenDataID, attrValue = null) => {

        let newToken;
        switch (tokenDataID) {

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE: {
                newToken = PhraseTokenUtil.addDefaultTypeToToken({ ...token });
                break;
            }
            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT: {
                newToken = PhraseTokenUtil.setTextsInToken({ ...token }, attrValue);
                break;
            }
            case PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID: {
                newToken = PhraseTokenUtil.setOcidsInToken({ ...token }, attrValue);
                break;
            }
            case PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID: {
                newToken = PhraseTokenUtil.setNegOcidsInToken({ ...token }, attrValue);
                break;
            }
            default: {
                newToken = PhraseTokenUtil.setAttributeInToken({ ...token }, tokenDataID, attrValue);
                break;
            }
        }

        this.setState({
            token: newToken,
        });

        //console.log('newToken: ', newToken);
    }

    /**
     * 
     */
    removeFromToken = (token, tokenDataID, objectToRemove) => {

        let newToken;
        switch (tokenDataID) {

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE: {
                newToken = PhraseTokenUtil.removeTypeFromToken({ ...token }, objectToRemove);
                break;
            }
            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT: {
                newToken = PhraseTokenUtil.removeTextFromToken({ ...token }, objectToRemove);
                break;
            }
            case PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID: {
                newToken = PhraseTokenUtil.removeOcidsFromToken({ ...token }, objectToRemove);
                break;
            }
            case PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID: {
                newToken = PhraseTokenUtil.removeNegOcidsFromToken({ ...token }, objectToRemove);
                break;
            }
            default: {
                newToken = PhraseTokenUtil.removeAttributeFromToken({ ...token }, tokenDataID);
                break;
            }
        }

        this.setState({
            token: newToken,
        });
    }



    /**
     * 
     */
    hasTokenData = (token, tokenDataID) => {

        switch (tokenDataID) {

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE:
                return false;

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT:
                return PhraseTokenUtil.hasTokenText(token);

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID:
                return PhraseTokenUtil.hasTokenOcids(token);

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID:
                return PhraseTokenUtil.hasTokenNegOcids(token);

            default:
                return PhraseTokenUtil.hasTokenAttribute(token, tokenDataID);
        }
    }

    getTokenData = (token, tokenDataID) => {
        switch (tokenDataID) {

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE:
                return '';

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT:
                return PhraseTokenUtil.getTokenText(token);

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID:
                return PhraseTokenUtil.getTokenOcids(token);

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID:
                return PhraseTokenUtil.getTokenNegOcids(token);

            //case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID:
            //    return PhraseTokenUtil.getTokenAttribute(token, tokenDataID);

            default:
                return PhraseTokenUtil.getTokenAttribute(token, tokenDataID);
        }
    }




    // ---------------------------------------------------- //
    // --- add or replace concept ------------------------- //
    // ---------------------------------------------------- //
    /**
     * Opens domain explorer dialog.
     */
    onAddNewConcept = (isBlacklist) => { // type

        //console.log('onAddNewConcept this.state.token: ', this.state.token);
        const domains = {};
        this.state.token.types.forEach(type => {
            if (type.type === PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE) {
                domains[type.subType] = true;
            }
        });
        //console.log('domains: ', domains);
        let filteredDomains = this.props.domains.filter(domain => {
            return !!domains[domain.value];
        });
        //console.log('filteredDomains1: ', filteredDomains);
        //console.log('this.props.domains: ', this.props.domains);
        if (isArrayEmpty(filteredDomains)) {
            filteredDomains = this.props.domains;
        }
        //console.log('filteredDomains2: ', filteredDomains);

        this.setState({
            ontBrowserVisible: true,
            resetBrowser: false,
            replaceExistingEntity: false,
            initialSearchTerm: null, //this.state.token.ocid ? this.state.token.ocid : ' ', //TODO
            neDomains: filteredDomains,
            //neDomains: this.props.domains, //!!type.subType && !!getDomain(type.subType) ? [getDomain(type.subType)] : this.props.domains,
            //type: type
            neIsBlacklist: isBlacklist
        });
    }

    onOpenOcidInOntBrowser = (ocid, index) => {

        //console.log('onAddNewConcept this.state.token: ', this.state.token);
        const domains = {};
        this.state.token.types.forEach(type => {
            if (type.type === PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE) {
                domains[type.subType] = true;
            }
        });
        //console.log('domains: ', domains);
        const filteredDomains = this.props.domains.filter(domain => {
            return !!domains[domain.value];
        });
        //console.log('filteredDomains1: ', filteredDomains);
        //console.log('this.props.domains: ', this.props.domains);

        if (isArrayEmpty(filteredDomains)) {
            filteredDomains = !this.state.neIsBlacklist ? this.props.domains : [];
        }
        //console.log('filteredDomains2: ', filteredDomains);

        this.setState({
            ontBrowserVisible: true,
            replaceExistingEntity: true,
            resetBrowser: false,
            initialSearchTerm: ocid,
            neDomains: filteredDomains,
            //neDomains: this.props.domains,
            replaceOcid: ocid,
            replaceIndex: index
        });
    }

    onOntBrowserClose = () => {
        this.setState({
            ontBrowserVisible: false,
            resetBrowser: true
        });
    }

    onOntBrowserSubmit = (selectedConcepts) => {

        if (selectedConcepts && Object.keys(selectedConcepts).length > 0) {
            // --- copy token and update values --- // 
            const newToken = { ...this.state.token };

            // --- check if concepts are from one domain only --- //
            const conceptDomains = {};
            for (const selConcept of Object.values(selectedConcepts)) {
                conceptDomains[selConcept.domain] = true;
            }
            if (Object.keys(conceptDomains).length > 1) {
                this.growl.current.show({
                    sticky: true, closable: true, severity: 'error', life: 20000,
                    summary: 'Multiple different domains not allowed.',
                    detail: `All subtrees should be from the same domain. If you would like to combine subtrees from different domains, please use a group of OR-combined tokens, one token for each domain.`
                });
                return;
            }



            if (this.state.neIsBlacklist) {
                newToken.negOcid = this.state.replaceExistingEntity ?
                    removeValueFromArray([...newToken.negOcid], this.state.replaceOcid) : newToken.negOcid;
            }
            else {
                newToken.ocid = this.state.replaceExistingEntity ?
                    removeValueFromArray([...newToken.ocid], this.state.replaceOcid) : newToken.ocid;
            }
            // --- extract all domains for newly added concepts and add OCIDs to token --- //
            //const conceptDomains = {};
            for (const selConcept of Object.values(selectedConcepts)) {
                if (this.state.neIsBlacklist) {
                    newToken.negOcid.push(selConcept.ocid);
                }
                else {
                    newToken.ocid.push(selConcept.ocid);
                }
                //conceptDomains[selConcept.domain] = true;
            }
            // --- remove duplicate values --- //
            if (this.state.neIsBlacklist) {
                deduplicateArrayValues(newToken.negOcid);
            }
            else {
                deduplicateArrayValues(newToken.ocid);
            }

            // --- check which domains are not yet added as NE; remember empty NE types --- //
            const removeEmptyNETypes = [];
            newToken.types.forEach(type => {
                if (type.type === PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE) {
                    delete conceptDomains[type.subType];
                    if (!type.subType) {
                        removeEmptyNETypes.push(type);
                    }
                }
            });
            // --- add missing domains, remove empty NEs --- //
            const missingDomains = Object.keys(conceptDomains);
            if (missingDomains.length > 0) {
                newToken.types = removeArrayOfValuesFromArray(newToken.types, removeEmptyNETypes);
                missingDomains.forEach(dom => {
                    PhraseTokenUtil.addTypeToToken(newToken, {
                        type: PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE,
                        subType: dom
                    });
                });
                this.growl.current.show({
                    sticky: false, closable: true, severity: 'info', life: 15000, summary: 'Missing named entity type added.',
                    detail: `Named entity type for the following domain was added automatically: ${missingDomains.join(', ')}`
                });
            }

            this.updatePrefNames(newToken);

            this.setState({
                ontBrowserVisible: false,
                token: newToken
            });
        }
    }

    updatePrefNames = async (token) => {

        const prefNamesMap = {};

        if (token) {
            let ocids = [];
            if (token.ocid) {
                ocids = ocids.concat(token.ocid);
            }
            if (token.negOcid) {
                ocids = ocids.concat(token.negOcid);
            }

            const request = createConceptDataRequest(ocids, true, false, false, false, 0, 0, false);
            const response = await fetchConceptsData(request, false);
            const concepts = checkResultAndGetPayload(response, this.growl);

            if (concepts) {
                concepts.map(concept => {
                    prefNamesMap[concept.ocid] = concept.preferredName;
                });
            }
        }

        this.setState({
            prefNamesMap: prefNamesMap
        });
    }


    clearOcids = (isBlacklist) => {

        const newToken = { ...this.state.token };
        if (!isBlacklist) {
            newToken.ocid = [];
        }
        else {
            newToken.negOcid = [];
        }
        this.setState({ token: newToken });
    }


    changeNEDomain = (type, newSubtypeValue) => {
        if (!!newSubtypeValue) {
            type.subType = newSubtypeValue;
        }
        else {
            delete type.subType;
        }
        delete type.subSubType;

        const newToken = { ...this.state.token };
        PhraseTokenUtil.removeOcidsFromToken(newToken);
        PhraseTokenUtil.removeNegOcidsFromToken(newToken);

        this.setState({ token: newToken });
    }


    changeType = (type, newTypeValue) => {
        type.type = newTypeValue;
        delete type.subType;
        delete type.subSubType;

        const newToken = { ...this.state.token };
        PhraseTokenUtil.removeOcidsFromToken(newToken);
        PhraseTokenUtil.removeNegOcidsFromToken(newToken);
        PhraseTokenUtil.removeTextFromToken(newToken);

        this.setState({ token: newToken });
    }

    /**
     * 
     */
    renderTokenAttributeData = (tokenDataID) => {

        switch (tokenDataID) {

            // --- attributes for type syntax --- //
            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID:
                return (
                    <InputText value={this.getTokenData(this.state.token, tokenDataID)}
                        onChange={(e) => { this.addToToken(this.state.token, tokenDataID, e.target.value) }}
                        style={{ width: 'auto' }}
                        keyfilter={/^[A-Za-z0-9_]+$/} />
                );

            /*
        case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE:
            return (
                <Dropdown value={this.getTokenData(this.state.token, tokenDataID)}
                    options={this.props.roleSelectItems}
                    optionLabel="label"
                    onChange={(e) => { this.setState({ role: e.value }); this.addToToken(this.state.token, tokenDataID, e.value.value) }}
                    placeholder="Select role"
                    filter={true}
                    filterBy="label"
                //style={{ minWidth: '150px', width: '30%' }}
            />
            );
            */

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE:
                return (
                    <>
                        <RadioButton
                            value="frame"
                            name="roletype"
                            onChange={(e) => {
                                let newToken = { ...this.state.token };
                                newToken = PhraseTokenUtil.removeAttributeFromToken(newToken, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET);
                                newToken = PhraseTokenUtil.setAttributeInToken(newToken, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE, null);
                                this.setState({ token: newToken })
                            }}
                            checked={!PhraseTokenUtil.hasTokenAttribute(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET)}
                            style={{ marginLeft: 0 }}
                        />
                        <span>Select from frame-specific roles</span>
                        <RadioButton
                            value="syntax"
                            name="roletype"
                            onChange={(e) => {
                                let newToken = { ...this.state.token };
                                newToken = PhraseTokenUtil.setAttributeInToken(newToken, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET, null);
                                newToken = PhraseTokenUtil.setAttributeInToken(newToken, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE, null);
                                this.setState({ token: newToken })
                            }}
                            checked={PhraseTokenUtil.hasTokenAttribute(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET)}
                            style={{ marginLeft: 10 }} />
                        <span>Type in target-specific role:</span>

                        {PhraseTokenUtil.hasTokenAttribute(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET) ?
                            <div>
                                <br />
                                <div>
                                    <InputText defaultValue={this.getTokenData(this.state.token, tokenDataID)}
                                        onChange={(e) => { this.addToToken(this.state.token, tokenDataID, e.target.value) }}
                                        style={{ minWidth: '250px', maxWidth: '100%' }}
                                        keyfilter={/^[A-Za-z0-9_]+$/}
                                        placeholder='Choose a role name, e.g "unit" or "value". Allowed characters are: A-Z a-z 0-9 and _'
                                        title='Choose any name for the role this token plays within this syntax, e.g "value" or "unit"' />
                                </div>
                                <div>
                                    <br />
                                    <Dropdown value={this.getTokenData(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET)}
                                        options={Object.values(this.props.targetIds)}
                                        onChange={(e) => {
                                            this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET, e.value)
                                        }}
                                        placeholder="Select target token ID"
                                        filter={true}
                                        filterBy="label"
                                        appendTo={document.body}
                                    />
                                </div>
                            </div>
                            :
                            <div>
                                <br />
                                <Dropdown
                                    value={this.getTokenData(this.state.token, tokenDataID)}
                                    options={this.props.roles}
                                    onChange={(e) => {
                                        this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE, e.value)
                                    }}
                                    placeholder="Select role"
                                    filter={true}
                                    filterBy="label"
                                    appendTo={document.body}
                                />
                            </div>
                        }
                    </>
                );

            // --- attributes for type role (relationship) --- //           
            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_REQUIRED:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_UNIQUE:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_DEFAULT:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_SUBJECT:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OBJECT:
                return ('Yes');

            // --- attributes independent of type --- //      
            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT_POSSESSIVE:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ALTERNATIVE:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_VARIABLE:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_NO_MATCH:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_FIRST:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_LAST:
                return ('Yes');

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_MIN:
                return (
                    <InputText value={this.getTokenData(this.state.token, tokenDataID)}
                        onChange={(e) => { this.addToToken(this.state.token, tokenDataID, e.target.value) }}
                        keyfilter="pint"
                        maxLength={2}
                        style={{ width: 'auto' }} />
                );

            case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_MAX:
                return (
                    <InputText value={this.getTokenData(this.state.token, tokenDataID)}
                        onChange={(e) => { this.addToToken(this.state.token, tokenDataID, e.target.value) }}
                        keyfilter="pint"
                        maxLength={2}
                        style={{ width: 'auto' }} />
                );

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID:
                return this.renderBlackWhiteLists(this.state.token.ocid, false);

            case PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID:
                return this.renderBlackWhiteLists(this.state.token.negOcid, true);


            case PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT:
                return (
                    <>
                        <Chips className="width100perc inputWidth100perc"
                            value={this.state.token.text}
                            onChange={(e) => {
                                const newToken = { ...this.state.token };
                                newToken.text = e.value;
                                this.setState({ token: newToken })
                            }}
                            placeholder="Type something and press enter to select"
                        >
                        </Chips>
                        <span className="material-icons"
                            style={{ marginRight: 10, position: 'relative', top: 5, color: '#4CAF50' }}>info</span>
                        <span>{`You can enter multiple texts. Please press "Enter" to submit each individual text.`}</span>
                    </>
                );
            /**/

            default:
                return null;
        }
    }


    renderBlackWhiteLists = (listOcids, isBlacklist = false) => {

        return <>
            <Button className={`buttonSmall`}
                label={`${'Add with domain explorer'}`}
                onClick={(e) => this.onAddNewConcept(isBlacklist)}
                title="Browse or search domains and select subtree(s)"
            />
            {listOcids && listOcids.length > 0 ?
                <Button className="buttonSmall p-button-danger"
                    label={`${'Clear'}`}
                    onClick={(e) => this.clearOcids(isBlacklist)}
                    style={{ marginLeft: 5 }}
                    title="Clear the subtree(s) selection"
                /> : null
            }
            <div>
                <span className="material-icons"
                    style={{ marginRight: 10, position: 'relative', top: 5, color: '#4CAF50' }}>info</span>
                <span>{isBlacklist ?
                    `The domain restriction in the "Types" section for "Named Entity" is mandatory for blocked subtrees, i.e. a domain has to be specified and the blocked subtrees must be from this domain.` :
                    `The domain restriction in the "Types" section for "Named Entity" is optional for allowed subtrees. However, if a domain is selected then the allowed subtrees must be from this domain.`
                }</span>
            </div>
            <ul className="noStyleList" style={{ paddingLeft: 15 }}>
                {listOcids ? Object.keys(listOcids).map(index => {
                    return <li key={'test_' + listOcids[index]} style={{ padding: 3 }}>
                        <span>
                            {`${this.state.prefNamesMap && this.state.prefNamesMap[listOcids[index]] ?
                                listOcids[index] + ' (' + this.state.prefNamesMap[listOcids[index]] + ')' : listOcids[index]}`}
                        </span>
                        {/* */}
                        <Button
                            label="Edit"
                            className="p-button-text primaryButtonLink"
                            title="Edit list entry"
                            onClick={(e) => this.onOpenOcidInOntBrowser(listOcids[index], index)}
                        />
                        <Button
                            label="Remove"
                            className="p-button-text dangerButtonLink"
                            title='Remove concept/subtree from list'
                            onClick={(e) => {
                                removeValueFromArray(listOcids, listOcids[index]);
                                this.setState({ token: { ...this.state.token } });
                            }}
                        />
                    </li>;
                }) : null}
            </ul>
        </>
    }


    /**
     * 
     */
    renderTypeSpecifics = (type) => {

        //console.log('type: ', type);
        //console.log('this.state.token: ', this.state.token);

        switch (type.type) {

            /*
            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_TEXT:
                return (
                    <>
                        <div className="col-12">
                            <Chips className="width100perc inputWidth100perc"
                                style={{ maxWidth: '100%' }}
                                value={this.state.token.text ? this.state.token.text : []}
                                onChange={(e) => {
                                    const newToken = { ...this.state.token };
                                    newToken.text = e.value;
                                    this.setState({ token: newToken })
                                }}
                                placeholder="Type text and press enter to select"
                            >
                            </Chips>
                        </div>
                    </>
                );
            */

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE + '':

                //const index = this.state.token.types.indexOf(type);
                //console.log('index: ', index);
                //this.neCount = this.neCount ? this.neCount++ : 1;
                //console.log('this.neCount: ', this.neCount);

                return (
                    <>
                        <div className="col-12">
                            <div>
                                <Dropdown
                                    className="withClearIcon"
                                    value={type.subType}
                                    options={this.props.domains}
                                    onChange={(e) => {
                                        // --- check if allowed or blocked subtrees are defined -> confirm to delete --- //
                                        const activeAllowList = !isArrayEmpty(this.state.token.ocid);
                                        const activeBlocklist = !isArrayEmpty(this.state.token.negOcid);
                                        if (!!type.subType && (activeAllowList || activeBlocklist)) {
                                            this.setState({
                                                displayConfirmationDialog: true,
                                                change: 'neDomain',
                                                changeNEDomain: type,
                                                changeNEDomainValue: e.value
                                            });
                                            return;
                                        }
                                        // --- otherwise --- //
                                        if (!!e.value)
                                            type.subType = e.value;
                                        else
                                            delete type.subType;
                                        delete type.subSubType;
                                        this.setState({ token: { ...this.state.token } });
                                    }}
                                    placeholder="Restrict to domain (neccessary to allow or block subtrees)"
                                    filterBy="label"
                                    filter={true}
                                    showClear={true}
                                    appendTo={document.body}
                                />
                            </div>

                            <div style={{ marginLeft: 25 }}>
                                {type.subType === 'chem' ?
                                    <div>
                                        <Dropdown
                                            className="withClearIcon"
                                            value={type.subSubType}
                                            options={[{ label: 'Compound', value: 'chemCompound' }, { label: 'Class', value: 'chemClass' }, { label: 'Group', value: 'chemGroup' }]}
                                            onChange={(e) => {
                                                if (!!e.value)
                                                    type.subSubType = e.value;
                                                else
                                                    delete type.subSubType;
                                                this.setState({ token: { ...this.state.token } });
                                            }}
                                            placeholder="Restrict to subdomain (optional)"
                                            showClear={true}
                                            appendTo={document.body} />
                                    </div> : null
                                }
                            </div>
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE + '_': //

                return (
                    <>
                        <div className="col-12">

                            <div className="p-field-radiobutton">
                                <RadioButton
                                    inputId="city1"
                                    name="city"
                                    value="domains"
                                    onChange={(e) => this.setState({ neSelection: e.value })}
                                    checked={this.state.neSelection === 'domains'}
                                />
                                <label htmlFor="city1">Select domain(s)</label>
                            </div>

                            {this.state.neSelection === 'domains' ?
                                <div style={{ padding: '5px 10px 20px 30px' }}>
                                    {/*
                                    <MultiSelect
                                        value={this.state.token.text}
                                        options={this.props.domains}
                                        onChange={(e) => {
                                            const newToken = { ...this.state.token };
                                            newToken.text = e.value;
                                            this.setState({ token: newToken })
                                        }}
                                        placeholder="Select specific values (optional)"
                                        appendTo={document.body}
                                    />
                                    */}
                                    <Dropdown
                                        className="withClearIcon"
                                        value={type.subType}
                                        options={this.props.domains}
                                        onChange={(e) => {
                                            if (!!e.value)
                                                type.subType = e.value;
                                            else
                                                delete type.subType;
                                            delete type.subSubType;
                                            this.setState({ token: { ...this.state.token } });
                                        }}
                                        placeholder="Select domain (optional)"
                                        filterBy="label"
                                        filter={true}
                                        showClear={true}
                                        appendTo={document.body}
                                    />
                                </div> : null
                            }

                            <div className="p-field-radiobutton">
                                <RadioButton
                                    inputId="city2"
                                    name="city"
                                    value="allowlists"
                                    onChange={(e) => this.setState({ neSelection: e.value })}
                                    checked={this.state.neSelection === 'allowlists'}
                                />
                                <label htmlFor="city2">Select allowed subtrees</label>
                            </div>
                            <div className="p-field-radiobutton">
                                <RadioButton
                                    inputId="city3"
                                    name="city"
                                    value="Halle"
                                    onChange={(e) => this.setState({ neSelection: e.value })}
                                    checked={this.state.neSelection === 'Halle'}
                                />
                                <label htmlFor="city2">Select subtrees to exclude</label>
                            </div>


                            <div style={{ marginLeft: 25 }}>
                                {type.subType === 'chem' ?
                                    <div>
                                        <br />
                                        <Dropdown
                                            className="withClearIcon"
                                            value={type.subSubType}
                                            options={[{ label: 'Compound', value: 'chemCompound' }, { label: 'Class', value: 'chemClass' }, { label: 'Group', value: 'chemGroup' }]}
                                            onChange={(e) => {
                                                if (!!e.value)
                                                    type.subSubType = e.value;
                                                else
                                                    delete type.subSubType;
                                                this.setState({ token: { ...this.state.token } });
                                            }}
                                            placeholder="Select subdomain (optional)"
                                            showClear={true}
                                            appendTo={document.body} />
                                    </div> : null
                                }
                            </div>

                            <div style={{ marginLeft: 0, marginTop: 20 }}>
                                <span className="material-icons"
                                    style={{ marginRight: 10, position: 'relative', top: 5, color: '#4CAF50' }}>info</span>
                                <span>{`You can use the "Allowed subtrees" or "Blocked subtrees" attributes to include or exclude certain domain subtrees. 
                                    You can also use "Text" to define specific concept names or synonyms.`}</span>
                            </div>
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_FRAME:
                return (
                    <>
                        <div className="col-12">
                            <Dropdown
                                className="withClearIcon"
                                value={type.subType}
                                options={this.props.frameSelectItems}
                                onChange={(e) => {
                                    if (!!e.value)
                                        type.subType = e.value;
                                    else
                                        delete type.subType;
                                    this.setState({ token: { ...this.state.token } });
                                }}
                                placeholder="Select frame (optional)"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter frames"
                                showClear={true}
                                appendTo={document.body}
                            />
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_MACRO:
                return (
                    <>
                        <div className="col-12">
                            <Dropdown
                                value={type.subType}
                                options={this.props.macroSelectItems}
                                onChange={(e) => { type.subType = e.value; this.setState({ token: { ...this.state.token } }); }}
                                placeholder="Select macro"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter macros"
                                appendTo={document.body}
                            />
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_SYNSET:

                return (
                    <>
                        <div className="col-12" title="Synset name">
                            <Dropdown value={this.props.synsetSelectItems[type.subType]}
                                options={Object.values(this.props.synsetSelectItems)}
                                onChange={(e) => {
                                    type.subType = e.value.label;
                                    this.setState({ token: { ...this.state.token } });
                                }}
                                placeholder="Select synset"
                                optionLabel="label"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter synsets"
                                appendTo={document.body}
                            />
                        </div>
                        <div className="col-12" title="Type">
                            <Dropdown value={type.subSubType}
                                options={this.props.synsetSelectItems[type.subType] ? this.props.synsetSelectItems[type.subType].types : []}
                                onChange={(e) => {
                                    type.subSubType = e.value;
                                    this.setState({ token: { ...this.state.token } });
                                }}
                                placeholder="Select type"
                                filter={true}
                                filterBy="label"
                                appendTo={document.body}
                            />
                        </div>

                        <div className="col-12" title="Inflection">
                            <Dropdown value={this.getTokenData(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_INFLECTION)}
                                options={PhraseTokenUtil.INFLECTION_TYPES}
                                onChange={(e) => {
                                    //console.log('sel inflection: ', e.value);
                                    this.addToToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_INFLECTION, e.value);
                                }}
                                placeholder="Select inflection"
                                filter={true}
                                filterBy="label"
                                appendTo={document.body}
                            />
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_ROLE_REFERENCE:
                return (
                    <>
                        <div className="col-12">
                            <Dropdown value={type.subType}
                                options={this.props.roles}
                                onChange={(e) => { type.subType = e.value; this.setState({ token: { ...this.state.token } }); }}
                                placeholder="Select role"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter roles"
                                appendTo={document.body}
                            />
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_DETERMINER:
                return (
                    <>
                        <div className="col-12">
                            <Dropdown
                                className="withClearIcon"
                                value={type.subType}
                                options={PhraseTokenUtil.DETERMINER_TYPES}
                                onChange={(e) => {
                                    if (!!e.value)
                                        type.subType = e.value;
                                    else
                                        delete type.subType;
                                    this.setState({ token: { ...this.state.token } });
                                }}
                                placeholder="Select determiner (optional)"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter determiners"
                                showClear={true}
                                appendTo={document.body}
                            />
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_ADJECTIVE:
                console.log('type.subType: ', type.subType);
                return (
                    <>
                        <div className="col-12">
                            <Dropdown
                                className="withClearIcon"
                                value={type.subType}
                                options={Object.values(PhraseTokenUtil.ADJECTIVE_TYPES)}
                                onChange={(e) => {
                                    if (!!e.value)
                                        type.subType = e.value;
                                    else
                                        delete type.subType;
                                    this.setState({ token: { ...this.state.token } });
                                }}
                                placeholder="Select adjective type (optional)"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter adjective types"
                                showClear={true}
                                appendTo={document.body}
                            />
                            {/*type.subType && PhraseTokenUtil.ADJECTIVE_TYPES[type.subType] && PhraseTokenUtil.ADJECTIVE_TYPES[type.subType].specifics ?
                                <Dropdown
                                    className="withClearIcon"
                                    value={this.state.token.text}
                                    options={PhraseTokenUtil.ADJECTIVE_TYPES[type.subType].specifics}
                                    onChange={(e) => {
                                        const newToken = { ...this.state.token };
                                        if (e.value) {
                                            newToken.text = e.value;
                                        }
                                        else {
                                            delete newToken.text;
                                        }
                                        this.setState({ token: newToken })
                                    }}
                                    placeholder="Select specific adjective (optional)"
                                    filter={true}
                                    filterBy="label"
                                    filterPlaceholder="Filter adjectives"
                                    showClear={true}
                                    appendTo={document.body}
                                />
                                : null
                                */}
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_ADVERB:
                return (
                    <>
                        <div className="col-12">
                            <Dropdown
                                className="withClearIcon"
                                value={type.subType}
                                options={PhraseTokenUtil.ADVERB_TYPES}
                                onChange={(e) => {
                                    if (!!e.value)
                                        type.subType = e.value;
                                    else
                                        delete type.subType;
                                    this.setState({ token: { ...this.state.token } });
                                }}
                                placeholder="Select adverb (optional)"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter adverbs"
                                showClear={true}
                                appendTo={document.body}
                            />
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_PRONOUN:
                return (
                    <>
                        <div className="col-12">
                            <Dropdown
                                className="withClearIcon"
                                value={type.subType}
                                options={PhraseTokenUtil.PRONOUN_TYPES}
                                onChange={(e) => {
                                    if (!!e.value)
                                        type.subType = e.value;
                                    else
                                        delete type.subType;
                                    this.setState({ token: { ...this.state.token } });
                                }}
                                placeholder="Select pronoun (optional)"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter pronouns"
                                showClear={true}
                                appendTo={document.body}
                            />
                        </div>
                    </>
                );

            case PhraseTokenUtil.PHRASE_TOKEN_TYPE_PUNCTUATION:
                return (
                    <>
                        <div className="col-12">
                            <span className="material-icons"
                                style={{ marginRight: 10, position: 'relative', top: 5, color: '#4CAF50' }}>info</span>
                            <span>{`You can use the "Text" attribute to specify punctuation values, e.g. ( ; or ? etc.`}</span>
                            {/*
                            <MultiSelect
                            pt={{
                                checkboxIcon: {
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        if (e.target.className.baseVal !== "") {
                                            e.target.parentNode.click();
                                        } else {
                                            e.target.parentNode.parentNode.click();
                                        }
                                    },
                                },
                                headerCheckbox: {
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        e.target.parentNode.click();
                                    },
                                },
                            }}
                            maxSelectedLabels={3}
                                value={this.state.token.text}
                                options={PhraseTokenUtil.PUNCTUATION_TYPES}
                                onChange={(e) => {
                                    const newToken = { ...this.state.token };
                                    newToken.text = e.value;
                                    this.setState({ token: newToken })
                                }}
                                placeholder="Select specific values (optional)"
                                appendTo={document.body}
                            />
                            */}
                        </div>
                    </>
                );

            default:
                return null;
        }

    }

    onEditPhraseTokenSubmit = () => {

        //console.log('has: ', PhraseTokenUtil.hasTokenAttribute(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET));
        //console.log('get: ', !PhraseTokenUtil.getTokenAttribute(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET));

        //console.log('!PhraseTokenUtil.hasTokenType(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE): ', (!PhraseTokenUtil.hasTokenType(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE)));
        //console.log('ocids: ', !!PhraseTokenUtil.hasTokenOcids(this.state.token));
        //console.log('negOcids: ', !!PhraseTokenUtil.hasTokenNegOcids(this.state.token));

        if (PhraseTokenUtil.hasTokenAttribute(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET) &&
            !PhraseTokenUtil.getTokenAttribute(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET)) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'No target defined.',
                detail: 'If you choose "target-specific role" please select a valid target.'
            });
            return;
        }
        // --- has allow or blocklist but not NE defined --- //
        else if (!PhraseTokenUtil.hasTokenType(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE) &&
            (!!PhraseTokenUtil.hasTokenOcids(this.state.token) || !!PhraseTokenUtil.hasTokenNegOcids(this.state.token))) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', life: 15000, summary: 'No Named Entity type defined.',
                detail: 'Allowed or blocked subtrees are only valid for type "Named Entity".'
            });
            return;
        }
        else {
            if (this.state.token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE]) {
                for (const tp of this.state.token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE]) {
                    if (tp.type === PhraseTokenUtil.PHRASE_TOKEN_TYPE_ROLE_REFERENCE && !tp.subType) {
                        this.growl.current.show({
                            sticky: true, closable: true, severity: 'error', summary: 'No role defined.',
                            detail: 'Please select a valid role or add a role to the frame if there is none yet.'
                        });
                        return;
                    }
                }
            }
        }

        let newToken = PhraseTokenUtil.removeEmptyDataFromToken(this.state.token);
        //newToken = PhraseTokenUtil.cleanAttributesOfToken(newToken); // No
        //newToken = PhraseTokenUtil.removeForbiddenAttributesFromToken(token, PhraseTokenUtil.getMainTokenType(token)); // Yes?
        this.props.onEditPhraseTokenSubmit(newToken);
    }


    render() {

        const { editTokenVisible, onHideEditPhraseToken, isGroup } = this.props;
        let oddRow = true;

        //console.log('render isGroup: ', isGroup);

        return (
            <>
                <Toast ref={this.growl} appendTo={document.body} />

                <Dialog header={isGroup ? 'Edit group' : 'Edit phrase token'}
                    visible={editTokenVisible}
                    modal={true}
                    style={{ minWidth: '80vw', width: '80vw' }}
                    onHide={(e) => onHideEditPhraseToken(e)}
                    onClose={(e) => onHideEditPhraseToken(e)}
                    focusOnShow={false}
                    className="overflowAuto" >

                    <div className="grid"> {/*  style={{ minWidth: '60wv', minHeight: '70vh',  maxHeight: '90vh',  overflow: 'auto' }} */}

                        {this.state.token ?
                            <>
                                <div className="col-12" style={{ marginBottom: 5, paddingBottom: 0, paddingTop: 10 }}>
                                    <div className="grid">
                                        <div className="col-fixed" style={{ width: 150 }}>
                                            Add attributes:
                                        </div>
                                        <div className="col">
                                            {Object.keys(this.state.tokenDataMap).map(tokenDataID => {
                                                const tokenData = this.state.tokenDataMap[tokenDataID];
                                                let css;
                                                switch (tokenDataID) {
                                                    case PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE: css = 'teal-btn'; break;
                                                    case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID: css = 'purple-btn'; break;
                                                    //case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET: css = 'orange-btn'; break;
                                                    case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE: css = 'orange-btn'; break;
                                                    case PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_NO_MATCH: css = 'red-btn'; break;
                                                    default: css = 'blue-grey-btn';
                                                }

                                                const activeText = tokenDataID === PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT && !this.state.token.text;
                                                const aciveWhitelist = tokenDataID === PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID && !this.state.token.ocid;
                                                const activeBlacklist = tokenDataID === PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID && !this.state.token.negOcid;
                                                const activeAttribute = (tokenDataID !== PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT && tokenDataID !== PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID &&
                                                    tokenDataID !== PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID && (!this.state.token.attr || !this.state.token.attr[tokenDataID]));

                                                // text is its own attribute, others are part of attributes list
                                                if (activeText || activeAttribute) {  // aciveWhitelist || activeBlacklist || 
                                                    return (
                                                        <Button className={`${css} buttonSmall`}
                                                            key={tokenDataID}
                                                            label={tokenData.label}
                                                            title={`Add ${tokenData.tooltip ? tokenData.tooltip : tokenData.label}`}
                                                            onClick={(e) => tokenData.command()}
                                                            style={{ margin: '5px 10px 5px 0px' }}
                                                        />
                                                    )
                                                }
                                                else if (tokenDataID !== PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID && tokenDataID !== PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID) {
                                                    return (
                                                        <Button className={`${css} buttonSmall`}
                                                            key={tokenDataID}
                                                            label={tokenData.label}
                                                            style={{ margin: '5px 10px 5px 0px', opacity: 0.2, cursor: 'default' }}
                                                        />
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>

                                {this.state.token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE] && this.state.token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE].length > 0 ?
                                    <div className="col-12 oddRow"
                                        style={{ borderTop: '1px solid #005594', borderBottom: '1px solid #bcd4e5', marginTop: 0, paddingTop: 15, paddingBottom: 0 }}>
                                        <div className="grid">
                                            {this.state.token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE].map((type, index) => {
                                                return <React.Fragment key={`${type.key}`}>
                                                    <div className="col-12 oddRow">
                                                        <div className="grid">
                                                            <div className="col-fixed" style={{ width: 120 }}>
                                                                {index === 0 ? 'Types:' : ''}
                                                            </div>
                                                            <div className="col">
                                                                <div className="grid">
                                                                    <div className="col">
                                                                        <Dropdown value={type.type}
                                                                            options={this.state.types}
                                                                            onChange={(e) => {
                                                                                //console.log('change type.type: ', type.type);
                                                                                // --- if old type was NE and is now changed or ---------------------------- //
                                                                                // --- new type is NE and token now has multiple NEs -> clear attributes --- //
                                                                                if ((type.type === PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE) ||
                                                                                    (e.value === PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE &&
                                                                                        PhraseTokenUtil.hasTokenType(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE))) {
                                                                                    const activeAllowList = !isArrayEmpty(this.state.token.ocid);
                                                                                    const activeBlocklist = !isArrayEmpty(this.state.token.negOcid);
                                                                                    const hasText = PhraseTokenUtil.hasTokenText(this.state.token);
                                                                                    if (activeAllowList || activeBlocklist || hasText) {
                                                                                        this.setState({
                                                                                            displayConfirmationDialog: true,
                                                                                            change: 'type',
                                                                                            changeType: type,
                                                                                            changeTypeValue: e.value
                                                                                        });
                                                                                        return;
                                                                                    }
                                                                                }

                                                                                type.type = e.value;
                                                                                delete type.subType;
                                                                                delete type.subSubType;

                                                                                const newToken = { ...this.state.token };

                                                                                if (type.type === PhraseTokenUtil.PHRASE_TOKEN_TYPE_TEXT && !newToken.text) {
                                                                                    newToken[PhraseTokenUtil.PHRASE_TOKEN_DATA_TEXT] = [];
                                                                                }

                                                                                // --- delete all attributes except types and key --- //
                                                                                /*
                                                                                const newToken = {};
                                                                                newToken.types = this.state.token.types;
                                                                                newToken.key = this.state.token.key;

                                                                                this.setState({ token: newToken });
                                                                                */

                                                                                this.setState({ token: newToken });
                                                                            }}
                                                                            placeholder="Select type"
                                                                            filter={true}
                                                                            filterBy="label"
                                                                            appendTo={document.body}
                                                                        />
                                                                    </div>
                                                                    <div className="col-fixed textAlignRight">
                                                                        {this.state.token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE].length > 1 ?
                                                                            <Button
                                                                                label="Remove"
                                                                                className="p-button-text dangerButtonLink"
                                                                                title={`Remove type`}
                                                                                style={{}}
                                                                                onClick={(e) => this.removeFromToken(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE, type)}
                                                                            />
                                                                            :
                                                                            <i className="material-icons"
                                                                                title="At least one type is required."
                                                                                style={{ color: '#009688', position: 'relative', top: 5, marginRight: 10 }} >
                                                                                check_circle
                                                                            </i>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="grid" style={{ padding: '0px 5px 0px 60px' }}>
                                                                    {type.type ? this.renderTypeSpecifics(type) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            })}
                                            {PhraseTokenUtil.hasTokenType(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE) &&
                                                !PhraseTokenUtil.hasTokenMultipleTypesOfSameType(this.state.token, PhraseTokenUtil.PHRASE_TOKEN_TYPE_NE) ?
                                                <div className="col" style={{ paddingLeft: 130, margin: 0, position: 'relative', top: -10 }}>
                                                    <span className="material-icons"
                                                        style={{ marginRight: 10, position: 'relative', top: 5, color: '#4CAF50' }}
                                                    >info</span>
                                                    <span>{`You can`}</span>
                                                    <Button className={`p-button-text primaryButtonLink`}
                                                        label={this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID].verb}
                                                        title={`Add ${this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID].tooltip ? this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID].tooltip : this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID].label}`}
                                                        onClick={(e) => this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_OCID].command()}
                                                        disabled={this.state.token.ocid}
                                                    />
                                                    <span>or</span>
                                                    <Button className={`p-button-text primaryButtonLink`}
                                                        label={this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID].verb}
                                                        title={`Add ${this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID].tooltip ? this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID].tooltip : this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID].label}`}
                                                        onClick={(e) => this.state.tokenDataMap[PhraseTokenUtil.PHRASE_TOKEN_DATA_NEGATIVE_OCID].command()}
                                                        disabled={this.state.token.negOcid}
                                                    />
                                                    <span>
                                                        {`from a single domain. Please make sure that the selected domain in the "Types" section is identical to all of the defined subtrees. If you would like to define specific concept names or synonyms, just add the "Token text" attribute.`}
                                                    </span>
                                                </div> : null
                                            }
                                        </div>
                                    </div> : null}

                                {Object.keys(this.state.tokenDataMap).map((tokenDataID) => {
                                    if (this.hasTokenData(this.state.token, tokenDataID)) {
                                        const label = this.state.tokenDataMap[tokenDataID] ? this.state.tokenDataMap[tokenDataID].label : tokenDataID;
                                        oddRow = !oddRow;
                                        return (
                                            <div className={`col-12 ${oddRow ? 'oddRow' : 'evenRow'}`}
                                                style={{ borderBottom: '1px solid #bcd4e5', marginTop: 0, paddingTop: 15, paddingBottom: 0 }}
                                                key={tokenDataID} >
                                                <div className="grid">
                                                    <div className="col-fixed" style={{ width: 120 }}>
                                                        {label}:
                                                    </div>
                                                    <div className="col">
                                                        {this.renderTokenAttributeData(tokenDataID)}
                                                    </div>
                                                    <div className="col-fixed" style={{}}>
                                                        <Button
                                                            label="Remove"
                                                            className="p-button-text dangerButtonLink"
                                                            title={`Remove "${label}"`}
                                                            onClick={(e) => this.removeFromToken(this.state.token, tokenDataID)}
                                                        />
                                                        {/* 
                                                            <Button
                                                            className="iconOnlySmall"
                                                            icon="pi pi-md-delete" style={{ fontSize: '12px', background: 'red' }}
                                                            title={`Remove "${label}"`}
                                                            onClick={(e) => this.removeFromToken(this.state.token, tokenDataID)} />
                                                            */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else { return null; }
                                })}
                            </> : null
                        }

                        <div className="col-12" style={{ textAlign: 'right' }}>
                            <Button label="Confirm"
                                className="primaryButton p-button-sm"
                                disabled={false}
                                onClick={(e) => this.onEditPhraseTokenSubmit()}
                            >
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <OntologyBrowserDialog
                    modal={true}
                    ontBrowserDialogID="entityDomBrowserDlg"
                    headerLabel="Domain explorer"
                    selectionMode="multiple"
                    placeholder='Type away...'
                    placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                    domains={this.state.neDomains}
                    numOfChildNodes={10} // not used for preloaded ontology
                    allowConceptSearchByClick={true}
                    allowSearchInOntologies={true}
                    loadOntologiesOnStart={true}
                    ontBrowserVisible={this.state.ontBrowserVisible}
                    onOntBrowserClose={this.onOntBrowserClose}
                    onOntBrowserSubmit={this.onOntBrowserSubmit}
                    initialSearchTerm={this.state.initialSearchTerm}
                    onOntBrowserShow={this.onOntBrowserShow}
                    resetBrowser={this.state.resetBrowser}
                    width="86vw"
                    height="86vh"
                />

                <ConfirmationDialog
                    displayDialog={this.state.displayConfirmationDialog}
                    onHide={() => this.setState({ displayConfirmationDialog: false })}
                    onSubmit={() => {
                        if (this.state.change === 'type') {
                            this.changeType(this.state.changeType, this.state.changeTypeValue);
                        }
                        else if (this.state.change === 'neDomain') {
                            this.changeNEDomain(this.state.changeNEDomain, this.state.changeNEDomainValue);
                        }
                        this.setState({ displayConfirmationDialog: false });
                    }}
                    headerText="Confirm"
                    messageText={`Allowed or blocked subtrees${this.state.change === 'type' ? ' as well as the token text attribute' : ''} are only allowed for a single "Named Entity" type. Would you like to proceed and clear both subtrees${this.state.change === 'type' ? ' and the token text attribute' : ''}?`}
                    submitButtonLabel={"Clear"}
                />
            </>
        );
    }
}

export default EditTokenDialog;