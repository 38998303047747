import { Component } from 'react';
import { APP_PROPERTIES } from '../../../properties/index';
import Changelog from './Changelog';
import { CHANGELOG } from './changelogs';


class Changelogs extends Component {

  constructor(props) {
    super(props);

    // --- merge changelog entries for this specific application (all + app-specific) --- //
    CHANGELOG.forEach(cl => {
      let allEntries = [];
      if (cl.entries) {
        if (cl.entries[APP_PROPERTIES.APP_ID]) {
          allEntries = cl.entries[APP_PROPERTIES.APP_ID];
        }
        if (cl.entries.all) {
          allEntries = allEntries.concat(cl.entries.all);
        }
      }
      cl.changes = allEntries;
    });
  }


  render() {
    return (
      <div style={{ marginLeft: '17%', marginTop: 36 }}>
        <h1 style={{ marginBottom: 37 }}>Release notes</h1>
        {/*<p>Please note: not all features or domains are available for all users.</p>*/}
        <Changelog
          changelog={CHANGELOG}
        />
      </div>
    );
  }
}

export default Changelogs;