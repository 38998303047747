import { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";
import pdfFileChemSearchUserGuide from '../../../../assets/images/tutorial/Chemistry_Search_User_Guide.pdf';

class ReactionSearchInfo extends Component {

    openManual = () => {
        window.open(pdfFileChemSearchUserGuide, '_blank');
    }


    render() {

        return (
            <InfoOverlayWithIcon
                infoID="compoundSearchInfo"
                title="Click for information about compound search"
                imageAltText="Compound search information"
                overlayContent={
                    <>
                        <h3>Reaction search types</h3>

                        <p>
                            A reaction may contain a reaction arrow after the reactants, before the products or in between
                            the reactants and products. For subreaction search a reaction arrow is mandatory,
                            otherwise it is optional.
                            Reaction agents may be drawn above the reaction arrow.
                            Thus, we are able to search for specific reaction products or reactants.
                            If no arrow is present the query molecule will be searched as any of the reaction parts.
                        </p>

                        <h5>Full match</h5>
                        <p>
                            A full match reaction search works similarly to the full match compound search and
                            helps to find reactions in a database that exhibit a matching molecular skeleton.
                            Different numbers of implicit hydrogen atoms, different electronic and isotopic states
                            as well as stereochemistry are not considered in this search - thus we may find all
                            isomers of a given molecular skeleton.
                        </p>

                        <h5>Substructure</h5>
                        <p>
                            Substructure reaction search works similarly to the compound substructure search and
                            helps to find those reactions in our database that
                            contain the query structure sketched in the structure editor. The algorithm uses
                            modified OpenChemLib fingerprints and molecular graph-based algorithms to find matching
                            molecules.
                        </p>

                        <h5>Subreaction</h5>
                        <p>
                            A subreaction search looks for reactions in the database that are true positive
                            reaction transforms as opposed to a substructure search. The input has to be a
                            subreaction and must contain a reaction arrow and at least one reactant 
                            and one product.
                        </p>
                    </>
                }
            />
        );
    }
}

export default ReactionSearchInfo;