import "./SearchGroupsToolbar.css";
import { Button } from "primereact/button";
import {
  SEARCH_GROUP_TYPE_API_SYNTAX,
  SEARCH_GROUP_TYPE_FILTER,
  SEARCH_GROUP_TYPE_LOGIC,
  SEARCH_GROUP_TYPE_SAVED_SEARCH,
  SEARCH_GROUP_TYPE_TERM,
} from "../../helpers/constants";
import SaveSearchButtons from "../../../../common/SaveSearch/SaveSearchButtons";
import { useSelector } from "react-redux";
import { isObjectEmpty } from "../../../util";
import SearchGroupsInfo from "../../../advancedsearch3/components/infos/SearchGroupsInfo";

const SearchGroupsToolbar = ({
  onAddGroup,
  onRunSearch,
  onClearAll,
  onSaveSearch,
  isEditModeActive,
  isEmptySearch,
  savedSearchData,
}) => {
  const errors = useSelector((state) => state.advancedSearch3Reducer.errors);
  const hasError = !isObjectEmpty(errors);

  return (
    <>
      <div className="inline-flex align-items-center justify-content-between w-full search-groups-toolbar">
        <div className="inline-flex align-items-center">
          <span className="mr-3">Add group:</span>

          <Button
            className="p-button-text primaryButtonAsLink toolbar-link"
            title=""
            onClick={() => onAddGroup(SEARCH_GROUP_TYPE_TERM)}
            disabled={isEditModeActive}
          >
            Terms
          </Button>

          <Button
            className="p-button-text primaryButtonAsLink toolbar-link"
            title=""
            onClick={() => onAddGroup(SEARCH_GROUP_TYPE_LOGIC)}
            disabled={isEditModeActive || isEmptySearch}
          >
            Logic
          </Button>

          <Button
            className="p-button-text primaryButtonAsLink toolbar-link"
            title=""
            onClick={() => onAddGroup(SEARCH_GROUP_TYPE_FILTER)}
            disabled={isEditModeActive}
          >
            Metadata
          </Button>

          <Button
            className="p-button-text primaryButtonAsLink toolbar-link"
            title=""
            onClick={() => onAddGroup(SEARCH_GROUP_TYPE_SAVED_SEARCH)}
            disabled={isEditModeActive}
          >
            Saved search
          </Button>

          <Button
            className="p-button-text primaryButtonAsLink toolbar-link"
            title=""
            onClick={() => onAddGroup(SEARCH_GROUP_TYPE_API_SYNTAX)}
            disabled={isEditModeActive}
          >
            API syntax
          </Button>

          <SearchGroupsInfo />
        </div>

        <div className="inline-flex align-items-center">
          <SaveSearchButtons
            savedSearchExists={!!savedSearchData?.savedSearchID}
            editableQuery={savedSearchData?.isEditable}
            onSaveSearch={onSaveSearch}
            savedSearchName={savedSearchData?.savedSearchName}
            disabled={isEditModeActive || isEmptySearch}
          />

          <Button
            className="p-button-text primaryButtonAsLink toolbar-link"
            title=""
            onClick={onClearAll}
            disabled={isEditModeActive || isEmptySearch}
          >
            Clear all
          </Button>

          <Button
            label="Run search"
            className="p-button-sm primaryButton"
            disabled={hasError || isEditModeActive || isEmptySearch}
            onClick={onRunSearch}
          />
        </div>
      </div>
    </>
  );
};

export default SearchGroupsToolbar;
