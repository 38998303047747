import React from 'react';
import LabelTermMenuHeader from "./LabelTermMenuHeader/LabelTermMenuHeader";
import TermMenuButtonGroup from "./TermMenuButtonGroup/TermMenuButtonGroup";

const TermMenu = ({
                      ocids,
                      normalizedTerms,
                      originalTerms,
                      setBiomarkerQueryTerms,
                      hideOverlayPanel,
                      showToast, fieldName, queryTerms
                  }) => (
    <>
        <LabelTermMenuHeader occurrence='MUST'/>
        <TermMenuButtonGroup occurrence='MUST' ocids={ocids} normalizedTerms={normalizedTerms}
                             originalTerms={originalTerms}
                             setBiomarkerQueryTerms={setBiomarkerQueryTerms} hideOverlayPanel={hideOverlayPanel}
                             showToast={showToast}
                             fieldName={fieldName}
                             queryTerms={queryTerms}/>

        <LabelTermMenuHeader occurrence='MUST_NOT'/>
        <TermMenuButtonGroup occurrence='MUST_NOT' ocids={ocids} normalizedTerms={normalizedTerms}
                             originalTerms={originalTerms}
                             setBiomarkerQueryTerms={setBiomarkerQueryTerms}
                             hideOverlayPanel={hideOverlayPanel}
                             showToast={showToast}
                             fieldName={fieldName}
                             queryTerms={queryTerms}/>
    </>

);

export default TermMenu;
