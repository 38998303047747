import moment from "moment"
//import { isbold } from "./services/helperFunctions"
import { APP_PROPERTIES } from '../../../../properties';


export const SORT_OPTION = [
    { label: 'Entries', value: 'count' },
    { label: 'Modified', value: 'lastModifiedDate' },
    { label: 'Name', value: 'title' }
]

const sourceTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Source</span>
        <span style={{ marginBottom: 5 }}>
            <label title={rowData?.queryCollection ? 'Search collection' : rowData?.library ? 'Document collection' : 'Source not available'}>{rowData?.queryCollection ? 'Search collection' : rowData?.library ? 'Document collection' : 'Source not available'}</label>
        </span>

        <div>
            {rowData?.queryCollection ?
                rowData.queryCollection.isOwner ?
                    <span>
                        <a
                            target='_blank'
                            rel="noreferrer"
                            href={`${APP_PROPERTIES.FRONTEND_URL}my/savedsearches?searchcol=${rowData?.queryCollection?.id}`}
                            title="Click to open app in new tab">{rowData?.queryCollection?.name ?? '-'}</a>
                    </span>
                    :
                    <label title={rowData?.queryCollection?.name ?? '-'}>{rowData?.queryCollection?.name ?? '-'}</label>
                : rowData?.library ?
                    rowData.library.isOwner ?
                        <span>
                            <a
                                target='_blank'
                                rel="noreferrer"
                                href={`${APP_PROPERTIES.FRONTEND_URL}my/library?doccol=${rowData?.library?.id}`}
                                title="Click to open app in new tab">{rowData?.library?.name ?? '-'}</a>
                        </span>
                        :
                        <label title={rowData?.library?.name ?? '-'}>{rowData?.library?.name ?? '-'}</label>
                    :
                    <label title='Source name not available'>Source name not available</label>
            }
        </div>

        <div>
            {rowData?.owner ?
                <label title={`Created by: ${rowData?.owner?.forename} ${rowData?.owner?.lastName}`}>{`Created by: ${rowData?.owner?.forename} ${rowData?.owner?.lastName}`}</label>
                :
                <label title='Creator not available'>Creator not available</label>
            }
        </div>
    </div>
}


const insertionTimeTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Created</span>
        <span>
            <label
                title={moment(rowData?.created).format("YYYY-MM-DD")}>{moment(rowData?.created).format("YYYY-MM-DD")}</label>
        </span>
    </div>
}


const modifiedTimeTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Updated</span>
        <span>
            <label
                title={rowData.dataUpdated ? moment(rowData?.dataUpdated).format("YYYY-MM-DD") : '-'}>{rowData.dataUpdated ? moment(rowData?.dataUpdated).format("YYYY-MM-DD") : '-'}</label>
        </span>
    </div>
}


const repositoriesTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Repositories</span>
        <span>
            {rowData?.repositories ?
                <label title={'Repositories'}>{rowData?.repositories?.length > 0 ? rowData?.repositories?.length : '-'}</label>
                :
                <label title={'Repositories not available'}>Repositories not available</label>
            }
        </span>
    </div>
}

const cycleTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Update cycle</span>
        <span>
            <label title={rowData?.updateCycle ? rowData?.updateCycle : '-'}>{rowData?.updateCycle ? rowData?.updateCycle : '-'}</label>
        </span>
        <div>
            <span>
                <label title={rowData?.nextUpdate ?  moment(rowData?.nextUpdate).format("YYYY-MM-DD") : '-'}>{rowData?.nextUpdate ?  moment(rowData?.nextUpdate).format("YYYY-MM-DD") : '-'}</label>
            </span>
        </div>
    </div>
}

export const COLUMNS = [
    {
        field: "source",
        header: "Source",
        body: sourceTemplate,
        //style: { width: '16em', textAlign: 'left' }
        style: { width: '16em', textAlign: 'left' }
    },
    {
        field: "repositories",
        header: 'Repositories',
        body: repositoriesTemplate,
        //style: { width: '8em', textAlign: 'left', fontWeight: 'normal' }
        style: { width: '4em', textAlign: 'left', fontWeight: 'normal' }
    },
    {
        field: "created",
        header: "Added",
        sortable: true,
        body: insertionTimeTemplate,
        //style: { width: '7.2em', textAlign: 'left' }
        style: { width: '7.2em', textAlign: 'left' }
    },
    {
        field: "dataUpdated",
        header: "Updated",
        sortable: true,
        body: modifiedTimeTemplate,
        //style: { width: '7.2em', textAlign: 'left' }
        style: { width: '7.2em', textAlign: 'left' }
    },
    {
        field: "nextUpdate",
        header: "Update cycle",
        sortable: true,
        body: cycleTemplate,
        //style: { width: '8.4em', textAlign: 'left' }
        style: { width: '8.4em', textAlign: 'left' }
    }
]




export const APP_TITLE = 'All apps';

export const APP_DESCRIPTION = 'All apps';
export const ROW_PER_PAGE_OPTIONS = [2, 10, 20, 50];



export const APP_STATUS = {

    requested: {
        label: 'Requested',
    },
    creating: {
        label: 'Creating',
    },
    finished: {
        label: 'Finished'
    },
    updateing: {
        label: 'Updating'
    }
}



export const APP_STATUS_ARR = [
    //'Requested',
    //'Creating',
    //'Transfering App Data',
    'Finished',
    'Failed'
    //'Waiting for update',
    //'Updating',
    // 'Updated'
] 