import * as ActionTypes from '../actions'

const initState = {
    data: [
      {
          roles: [],
          department: {}
      }
    ]
}

const users = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_ALL_USERS:
            return {
                ...state,
                data: action.users
            }
        default:
            return state
    }

}
export default users
