
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { getHeaderToken } from "../../../../api";
import { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { COLUMNS, APP_STATUS, APP_STATUS_ARR } from '../core/constants';
import { isbold } from "../core/services/helperFunctions"
import { APP_PROPERTIES } from '../../../../properties';
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from 'primereact/inputswitch';
import { OverlayPanel } from "primereact/overlaypanel";
import CustomPaginatorLeftSide from '../../../common/CustomPaginator/CustomPaginatorLeftSide';
import CustomPaginatorRightSide from '../../../common/CustomPaginator/CustomPaginatorRightSide';
import CustomPaginatorTemplate from '../../../common/CustomPaginator/CustomPaginatorTemplate';
import ConfirmationDialog from "../../../common/ConfirmDialog/ConfirmationDialog";
import infoIcon from "../../../../assets/images/icons/info/dimensions-icon-info.png";
import ToastContent from './ToastContent';
import { Dialog } from 'primereact/dialog';
import moment from "moment"


const ExportsTable = ({ apps, onReload, editApp, changeAlertStatus, changeActiveStatus, refreshApp, userData }) => {
    const [templates, setTemplates] = useState([]);
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const [selectedAppID, setSelectedAppID] = useState(null);
    const [selectedApps, setSelectedApps] = useState(null);
    const [dataTableRows, setDataTableRows] = useState(10);
    const [dataTablePage, setDataTablePage] = useState(0);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const recallInfoOverlayDoc = useRef(null);


    useEffect(() => {
        fetchTemplates();
    }, []);

    // Fetch templates
    const fetchTemplates = () => {
        axios.get(
            `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards/templates`,
            { headers: getHeaderToken() }
        )
            .then(function (response) {
                // Update templates state
                setTemplates(response.data);
            })
            .catch(function (error) {
                showFailGrowl(error.response.data);
            });
    };

    const showFailGrowl = (e, type = 'error', summary = 'Failed!') => {
        let msg = { severity: type, summary, detail: e, life: 10000 };
    };

    useEffect(() => {
        if (apps?.find(item => !APP_STATUS_ARR.includes(item.status))) {
            const interval = setInterval(() => {
                onReload();
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [apps]);

    const onHide = () => {
        setIsDialogVisible(false);
        setSelectedApps([])

    }

    const onPageChange = (e, isPageChange) => {
        if (isPageChange) {
            setDataTablePage(e.first);
            setDataTableRows(e.rows);
        }
    }

    const selectedRecords = (e) => {
        setSelectedApps(e.value);

        if (e.value?.length > 0) {
            if (!isDialogVisible) {
                setIsDialogVisible(true);
            }
        } else {
            setIsDialogVisible(false);
        }
    }

    const edit = (rowData) => {
        editApp(rowData)
    }

    const onRefresh = (rowData) => {
        setSelectedAppID(rowData.id)
        setDisplayConfirmDialog(true)
    }

    const reject = () => {
        setDisplayConfirmDialog(false)
    }

    const accept = () => {
        refreshApp(selectedAppID)
        setDisplayConfirmDialog(false)
    }

    const editTemplate = (rowData) => {
        return <div>
            {userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' || userData.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' ?
                rowData?.status === 'Finished' || rowData.status === 'Updated' ?
                    <a onClick={() => edit(rowData)}>
                        Edit
                    </a>
                    :
                    <label>Edit</label>
                :
                <label>Not editable</label>
            }
        </div>
    }

    const refreshTemplate = (rowData) => {
        return <div>
            {userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' && rowData.template !== 'static' ?
                rowData?.status === 'Finished' || rowData.status === 'Updated' ?
                    <a onClick={() => onRefresh(rowData)}>
                        Refresh
                    </a>
                    :
                    <label>Refresh</label>
                :
                <label>Not refreshable</label>
            }
        </div>
    }


    const alertOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ]


    const alertTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Alert</span>
            {rowData.template !== 'static' ?
                <span>
                    <Dropdown
                        style={{ marginTop: -5, marginLeft: -5 }}
                        value={rowData?.alertOnUpdate}
                        options={alertOptions}
                        onChange={(e) => onAlertChange(e.value, rowData?.id)}>
                    </Dropdown>
                </span>
                : <span> -
                </span>
            }
        </div>
    }

    const onAlertChange = (value, id) => {
        changeAlertStatus(value, id)
    }

    const statusTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Status</span>
            <span>
                <label title={rowData?.status} className={isbold(rowData)}>{rowData?.status}</label>
            </span>
            {rowData.message ?
                <span> <a className="infoIconLink valignMiddle"
                    style={{ marginTop: -1 }}
                    title="Click for more information"
                    onClick={e => recallInfoOverlayDoc.current.toggle(e)}>
                    <img src={infoIcon} alt="Status information"
                        style={{ marginLeft: 10 }} />
                </a>
                    <OverlayPanel
                        //className="removeArrow"
                        ref={recallInfoOverlayDoc}
                        dismissable={true}>
                        <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                            <p>
                                {rowData?.message}
                            </p>
                        </div>
                    </OverlayPanel></span>
                : null
            }
        </div>
    }

    const sharingTemplate = (rowData) => {
        let usersShared = []
        if (rowData.userShares) {
            rowData.userShares.forEach(user => {
                //if (user.username !== userData.userDetails.username) {
                usersShared = [...usersShared, user.id]
                //}
            })
        }
        return <div>
            <span className="p-column-title">Sharing</span>
            {rowData?.departmentShares ?
                <div>
                    <span>
                        <label title={rowData?.departmentShares ? `Suborgs.: ${rowData?.departmentShares.length}` : '-'}>{rowData?.departmentShares ? `Suborgs.: ${rowData?.departmentShares.length}` : '-'}</label>
                    </span>
                </div>
                : null
            }
            {rowData?.userShares && usersShared.length > 0 ?
                <div>
                    <label title={rowData?.userShares ? `Individual users: ${usersShared.length}` : '-'}>{rowData?.userShares ? `Individual users: ${usersShared.length}` : '-'}</label>
                </div>
                : null
            }
            {!rowData.departmentShares && (!rowData.userShares || usersShared.length === 0) &&
                <label title='No info'>No info</label>
            }
        </div>
    }


    const nameTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Name</span>
            <span style={{ marginBottom: 5 }}>
                {rowData?.readyForDisplay && rowData?.active && (rowData.userShares?.filter(users => users.id === userData?.userDetails?.id).length > 0 || rowData.departmentShares?.filter(users => users.id === userData?.userDetails?.department?.id).length > 0) ?
                    <a target='_blank'
                        rel="noreferrer"
                        href={`${APP_PROPERTIES.FRONTEND_URL}apps/${rowData?.id}`}
                        title="Click to open app in new tab">{rowData?.name ? rowData?.name : '-'}</a>
                    :
                    <label title={rowData?.name ? rowData?.name : '-'}>{rowData?.name ? rowData?.name : '-'}</label>
                }
            </span>
            <div>
                <span>
                    <label style={{ color: '#757575' }} title={rowData?.template ? templates?.find(x => x.id === rowData?.template)?.label : 'Template not available'}>{rowData?.template ? templates?.find(x => x.id === rowData?.template)?.label : 'Template not available'}</label>
                </span>
            </div>
            {rowData?.group && rowData?.group !== '' ?
                <div>
                    <span>
                        <label style={{ color: '#757575' }} title={rowData?.group}>{`Group: ${rowData?.group}`}</label>
                    </span>
                </div>
                : null
            }
        </div>
    }


    const activeTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Active</span>
            <span style={{ marginBottom: 5 }}>
                <div>
                    <InputSwitch disabled={!rowData.readyForDisplay} checked={rowData.active} onChange={(e) => onActiveChange(e.value, rowData?.id)} />
                </div>
            </span>
        </div>
    }

    const onActiveChange = (value, id) => {
        changeActiveStatus(value, id)
    }

    const expirationTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Exp. date</span>
            <span>
                <label
                    title={rowData?.expiryDate ? moment(rowData?.expiryDate).format("YYYY-MM-DD") : '-'}>{rowData?.expiryDate ? moment(rowData?.expiryDate).format("YYYY-MM-DD") : '-'}</label>
            </span>
        </div>
    }

    const checkCol = {
        field: "check",
        selectionMode: 'multiple',
        style: { width: '4em', fontWeight: 'normal' }
    }

    const nameCol = {
        field: "name",
        header: "Name, Template",
        body: nameTemplate,
        //style: { width: '16em', textAlign: 'left' }
        style: { width: '16em', textAlign: 'left' }
    }

    const activeCol = {
        field: "active",
        header: "Active",
        body: activeTemplate,
        //style: { width: '16em', textAlign: 'left' }
        style: { width: '5.2em', textAlign: 'left' }
    }

    const editCol = {
        field: "edit",
        header: '',
        body: editTemplate,
        style: { width: '2em', textAlign: 'left', fontWeight: 'normal' }
    };

    const refreshCol = {
        field: "refresh",
        header: '',
        body: refreshTemplate,
        style: { width: '2em', textAlign: 'left', fontWeight: 'normal' }
    };

    const alertCol = {
        field: "alertOnUpdate",
        header: "Alert",
        body: alertTemplate,
        //style: { width: '7.2em', textAlign: 'left' }
        style: { width: '7.2em', textAlign: 'left' }
    }


    const statusCol = {
        field: "Status",
        header: "Status",
        body: statusTemplate,
        //style: { width: '7.2em', textAlign: 'left' }
        style: { width: '8.2em', textAlign: 'left' }
    }

    const expirationCol = {
        field: "expiryDate",
        sortable: true,
        header: "Exp. date",
        body: expirationTemplate,
        //style: { width: '7.2em', textAlign: 'left' }
        style: { width: '8.2em', textAlign: 'left' }
    }

    const sharingCol = {
        field: "sharing",
        header: "Sharing",
        body: sharingTemplate,
        //style: { width: '7.2em', textAlign: 'left' }
        style: { width: '9.2em', textAlign: 'left' }
    }

    return (
        <div className="datatable-responsive-demo-l">

            <DataTable
                removableSort
                value={apps}
                paginator
                className="p-datatable-responsive-demo-l"
                rows={dataTableRows}
                first={dataTablePage}
                paginatorTemplate={CustomPaginatorTemplate}
                paginatorLeft={<CustomPaginatorLeftSide
                    first={dataTablePage}
                    contentLength={apps.length}
                    rows={dataTableRows}>
                </CustomPaginatorLeftSide>}
                paginatorRight={<CustomPaginatorRightSide
                    rows={dataTableRows}
                    onChange={(e) => setDataTableRows(e.value)}>
                </CustomPaginatorRightSide>}
                dataKey="id"
                rowHover
                onPage={(e) => onPageChange(e, true)}
                selection={selectedApps}
                selectionMode={userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ? 'checkbox' : ''}
                onSelectionChange={selectedRecords}
                filterDisplay="menu"
                responsiveLayout="scroll"
                emptyMessage="No apps found."
            >
                {userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ?
                    [checkCol, nameCol, ...COLUMNS, alertCol, sharingCol, statusCol, expirationCol, activeCol, refreshCol, editCol]?.map((col, i) => {
                        return <Column className="columnheader" key={col.field}
                            field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                            header={col.header} sortable={col.sortable} body={col.body} style={col.style}
                            filter={col.filter} filterMatchMode={col.filterMatchMode} />
                    })
                    :
                    [nameCol, ...COLUMNS, alertCol, sharingCol, statusCol, expirationCol, editCol]?.filter(col => col.field !== 'source' && col.field !== 'repositories').map((col, i) => {
                        return <Column className="columnheader" key={col.field}
                            field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                            header={col.header} sortable={col.sortable} body={col.body} style={col.style}
                            filter={col.filter} filterMatchMode={col.filterMatchMode} />
                    })
                }
            </DataTable>

            <Dialog visible={isDialogVisible} closable={false} showHeader={false} position={'bottom'} modal={false}
                onHide={onHide} style={{ backgroundColor: 'white', opacity: '1', bottom: 55, boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }}
                draggable={false} resizable={false} contentClassName='dialog-content' className='dialog-custom'>
                <ToastContent selectedApps={selectedApps} onReload={onReload} onHide={onHide} />
            </Dialog>
            <ConfirmationDialog
                displayDialog={displayConfirmDialog}
                onHide={reject}
                onSubmit={accept}
                headerText="Confirm"
                messageText='Would you like to refresh this app?'
                submitButtonLabel="Refresh" />
        </div>
    );
}


export default ExportsTable