import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import { getHeaderToken } from '../../../api/index'
import { createCheckedKeys } from '.././util'
//import axios from 'axios'
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
//import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { TreeSelect } from 'primereact/treeselect';
import { Toast } from 'primereact/toast';
//import { APP_PROPERTIES } from '../../../properties/index';

class CreateCollection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newCollectionName: '',
            newCollectionDescription: '',
            sharedForDepartment: false,
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            editableForSharedUsers: false
        };
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    onHide = () => {
        this.resetData()
        this.props.onHide()
    }

    resetData = () => {
        this.setState({
            newCollectionName: '',
            newCollectionDescription: '',
            sharedForDepartment: false,
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            editableForSharedUsers: false
        })
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.props.orgArray && this.props.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.props.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.props.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.props?.departmentMembers, this.props.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.props.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        //console.log(members)
        this.setState({
            usersWrite: members
        })
    }

    handleCollectionDescriptionChange = (desc) => {
        this.setState({
            newCollectionDescription: desc
        })
    }


    handleCollectionNameChange = (name) => {
        this.setState({
            newCollectionName: name
        })
    }

    onSubmit = () => {
        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }
        this.props.onSubmit(this.state.newCollectionName, this.state.newCollectionDescription, this.state.sharedForDepartment, usersRead, this.state.usersWrite, this.state.orgShared, this.state.editableForSharedUsers)
    }

    render() {

        const footerAddCollection =
            <div className='col-12' style={{ paddingRight: 15, paddingBottom: 0 }}>
                <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 5 }}>* required</label>
                <Button className='p-button-secondary p-button-sm'
                    label="Cancel"
                    onClick={() => this.props.onHide()}
                />
                <Button className='primaryButton p-button-sm'
                    label="Create"
                    onClick={() => this.onSubmit()}
                    disabled={this.props.collectionName !== '' ? false : true}
                    style={{ marginRight: -5 }}
                />
                {/*this.props.showAddDocument ?
                    <Button className='primaryButton p-button-sm'
                        label="Create and add document"
                        onClick={() => this.props.onSubmit(this.state.name, this.state.description, true)}
                        disabled={!this.state.name}
                        style={{ marginRight: -5 }} />
                    : null
                */}
            </div>

        return (
            <Dialog focusOnShow={false}
                visible={this.props.displayDialog}
                style={{ 'width': "60vw" }}
                header="Create new document collection"
                modal={true}
                blockScroll
                footer={footerAddCollection}
                dismissableMask={false}
                onShow={() => this.resetData()}
                onHide={() => this.onHide()} className='styledDialog'>
                <Toast ref={(el) => { this.growl = el }} />
                <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                        <label htmlFor="name">Name *</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                        <InputText id="name"
                            maxLength={50}
                            autoComplete="off"
                            onChange={(e) => { this.handleCollectionNameChange(e.target.value) }}
                            value={this.state.newCollectionName} />
                        <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                        <label htmlFor="description">Description</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                        <InputText id="description"
                            maxLength={200}
                            autoComplete="off"
                            onChange={(e) => { this.handleCollectionDescriptionChange(e.target.value) }}
                            value={this.state.newCollectionDescription} />
                        <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                        <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ display: 'flex', paddingBottom: 15, paddingRight: 10, paddingTop: 5 }}>
                        <div className="w-full" >
                            <TreeSelect disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.props.departmentMembers?.length !== 0 ? false : true}
                                id='sharingTreeSelect'
                                resetFilterOnHide={true}
                                //appendTo={document.body}
                                selectionMode='checkbox'
                                placeholder='Select suborganizations'
                                value={this.state.checkedOrgKeys}
                                options={this.props.orgArray}
                                display='chip'
                                scrollHeight='200px'
                                filter
                                onChange={(e) => {
                                    e.stopPropagation()
                                    this.handleMemberChange(e.value, true)
                                }} style={{
                                    borderBottom: '1px solid gray', maxWidth: '19vw'
                                }} />
                        </div>
                        <div className="w-full" >
                            <TreeSelect disabled={this.props.departmentMembers?.length !== 0 ? false : true}
                                id='sharingTreeSelect'
                                resetFilterOnHide={true}
                                appendTo={document.body}
                                selectionMode='checkbox'
                                placeholder='Select users'
                                //title='Select colleagues'
                                value={this.state.checkedKeys}
                                options={this.props.userArray}
                                display='chip'
                                scrollHeight='200px'
                                //optionLabel='label'
                                //optionGroupLabel='label'
                                //optionGroupChildren="items"
                                //optionGroupTemplate={groupedItemsTemplate}
                                filter
                                onChange={(e) => {
                                    e.stopPropagation()
                                    this.handleMemberChange(e.value, false)
                                }} style={{
                                    borderBottom: '1px solid gray', marginLeft: 10, maxWidth: '19vw'
                                }} />
                        </div>
                    </div>
                    {this.state.usersShared && this.state.usersShared.length !== 0 ?
                        <React.Fragment>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Editable for shared users</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                <MultiSelect
                                    pt={{
                                        checkboxIcon: {
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                if (e.target.className.baseVal !== "") {
                                                    e.target.parentNode.click();
                                                } else {
                                                    e.target.parentNode.parentNode.click();
                                                }
                                            },
                                        },
                                        headerCheckbox: {
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                e.target.parentNode.click();
                                            },
                                        },
                                    }}
                                    id='sharingMultiSelect'
                                    display='chip'
                                    placeholder='Select users'
                                    onChange={(e) => this.onWriteMembersChange(e.value)}
                                    value={this.state.usersWrite}
                                    options={this.state.sharedUsers}
                                >
                                </MultiSelect>
                            </div>
                        </React.Fragment>
                        : null
                    }
                    <div style={{ display: 'flex', justifyContent: 'end', paddingLeft: 17 }}>
                        <div className="full-text-message-collections col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8">
                            Note: Users changing the suborganization will lose access to document collections shared on suborganization level, but will keep access to document collections shared on personal level.
                        </div>
                    </div>
                </div>
                <div>
                    {/*<div className='col-12' style={{ paddingTop: 10, paddingRight: 0, marginTop: 10 }}>
                        <label style={{ float: 'left', paddingTop: 20 }}>* required</label>
                        <Button
                            label="Create"
                            className='p-button-sm primaryButton'
                            onClick={this.onSaveCollection}
                            disabled={this.state.newCollectionName && this.state.newCollectionName.length > 0 ? false : true}
                            style={{ float: 'right' }} />
                        <Button label="Cancel"
                            onClick={(e) => this.hideCreateCollection(e)}
                            className="p-button-secondary p-button-sm"
                            style={{ float: 'right', marginRight: 5 }} />
        </div>*/}
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.data
})
const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCollection));
