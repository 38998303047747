import { Link } from 'react-scroll';

const TruncateToggleButton = ({
  truncated,
  handleChange,
  scrollToContainerID,
  scrollTargetElement,
}) => {

  return <>
    {truncated ?
      <a onClick={(e) => handleChange(false)}>Show all</a>
      :
      <Link
        to={scrollTargetElement}
        containerId={scrollToContainerID}
        spy={true}
        smooth={true}
        offset={0}
        duration={50}
        onClick={(e) => handleChange(true)}>
        Show less
      </Link>}
  </>;
}
export default TruncateToggleButton;