import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { getHeaderToken } from '../../../api/index';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { APP_PROPERTIES } from '../../../properties/index';



class ServicesStatus extends Component {

    /*constructor(props) {
        super(props);
    }*/


    // --- query cache --- //

    /**
     * Clears query cache.
     */
    handleDelete = async () => {
        //console.log("delete")

        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/querycache`, { headers: getHeaderToken(), data: {} })
            .then(function (response) {
                if (response.statusText === 'OK') {
                    self.showSuccessGrowl()
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showFailGrowl()
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
    }

    showSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Querycache successfully deleted' };
        this.growl.show(msg);
    }

    showFailGrowl = (e) => {
        let msg = { sticky: true, closable: true, severity: 'error', summary: 'Failed!', detail: 'Querycache could not be deleted' };
        this.growl.show(msg);
    }


    render() {
        return (
            <>
                <Toast ref={(el) => { this.growl = el }} />

                <div style={{ padding: 10 }}>
                    <h1 className="pageHeader">Status</h1>

                    <h3>Services</h3>
                    <div className="grid dashboard" style={{ height: '100%', width: '100%' }}> {/*style={{ height: 'calc(100vh - 250px)' }}*/}
                        <div className="col-12 infoText">
                            The middleware functions as a connector between the frontend and the backend, as well as other services.
                    </div>
                        {this.props.serverStatus && this.props.serverStatus.services && Object.keys(this.props.serverStatus.services).map(serviceName => {
                            return (
                                <div className="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4"
                                    key={serviceName}>
                                    <div className="image-box services" style={{ margin: "0 auto", height: "100%" }}>
                                        <div className={`task-box task-box-${this.props.serverStatus.services[serviceName].available ? 'green' : 'red'} shadowMedium`}
                                            style={{ margin: "0 auto", height: "100%" }}>
                                            <div className="task-box-header">
                                                {serviceName}
                                            </div>
                                            <div className="task-box-content"
                                                style={{ padding: '10px' }}>
                                                <div>
                                                    Status: {this.props.serverStatus.services[serviceName].message}
                                                    {!this.props.serverStatus.services[serviceName].available ?
                                                        <div>
                                                            <br />
                                                        Please contact your administrator.
                                                    </div> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.clearQueryCache ?
                        <>
                            <h3>Querycache</h3>
                            <div className="grid">
                                <div className="col-12 infoText">
                                    Query cache stores queries to allow faster responsees next time the exact same search is executed.
                                    Queries are updated once a day, i.e. all queries are repeated and new results are written to the store.
                                    Sometimes caching queries can lead to problems and it is recommended to clear the cache.
                                </div>
                                <div className="col-12">
                                    <Button label="Clear query cache"
                                        className="p-button-danger p-button-sm"
                                        title='Warning! Query cache will be cleared.'
                                        onClick={this.handleDelete}
                                        icon="pi-md-refresh" />
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            </>
        );
    }
}

export default withRouter(ServicesStatus);