import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { APP_PROPERTIES } from '../../properties';
import { hasUserRole } from '../../components/webapi/util';

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newUserName: '',
            newUserForename: '',
            newUserUsername: '',
            newUserRole: 'NONE',
            newUserActive: true,
            newUserEmail: '',
            newUserPassword: '',
            newUserPasswordRepeated: '',
            userForceUpdatePassword: false,
            samlLogin: false
        }
    }

    handleUserRoleChange = (e) => {
        this.setState({
            newUserRole: e.value
        })
    }

    handleSAMLLoginChange = (e) => {
        this.setState({
            samlLogin: e.checked
        })
    }

    changeUserName = (name) => {
        this.setState({
            newUserName: name,
            newUserUsername: (this.state.newUserForename.trim().charAt(0).concat(name.trim())).replace(/\s/g, "")
        })
    }

    changeUserForename = (forename) => {
        this.setState({
            newUserForename: forename,
            newUserUsername: (forename.trim().charAt(0).concat(this.state.newUserName.trim())).replace(/\s/g, "")
        })
    }

    changeUserUsername = (username) => {
        this.setState({
            newUserUsername: username.replace(/\s/g, "")
        })
    }

    changeUserEmail = (email) => {
        this.setState({
            newUserEmail: email.replace(/\s/g, "")
        })
    }

    onAddUser = () => {
        this.props.onAddUser(this.state.newUserRole, this.props.selectedCompany, this.state.newUserActive, this.props.selectedDepartment, this.state.newUserEmail,
            this.state.newUserForename, this.state.newUserName, this.state.newUserPassword, this.state.newUserPasswordRepeated, this.state.newUserUsername,
            this.state.userForceUpdatePassword, this.state.samlLogin)
        this.onCancelAddUser()
    }

    onCancelAddUser = () => {
        this.setState({
            newUserName: '',
            newUserForename: '',
            newUserUsername: '',
            newUserRole: 'NONE',
            newUserActive: true,
            newUserEmail: '',
            newUserPassword: '',
            newUserPasswordRepeated: '',
            userForceUpdatePassword: false,
            samlLogin: false
        }, () => {
            this.props.onCancelAddUser()
        })
    }

    render() {

        let userRoles = [
            {
                label: 'Super admin', value: 'ROLE_SUPER_ADMIN'
            },
            {
                label: 'Support admin', value: 'ROLE_SUPPORT_ADMIN'
            },
            {
                label: 'Organization admin', value: 'ROLE_COMPANY_ADMIN'
            },
            {
                label: 'Suborganization admin', value: 'ROLE_DEPARTMENT_ADMIN'
            },
            {
                label: 'User', value: 'NONE'
            }
        ]

        userRoles = this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' ? userRoles.slice(2) :
            this.props.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' ? userRoles.slice(3) :
                this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") ? userRoles.slice(1) :
                    userRoles

        return (
            <Dialog visible={this.props.displayAddUser} style={{ 'width': "70vw" }} header="Add new user" focusOnShow={false}
                modal={true} blockScroll
                dismissableMask={false}
                onHide={() => this.onCancelAddUser()} className='styledDialog'>
                <div className="grid p-fluid" style={{ borderBottom: '1px solid #d6d6d6', paddingLeft: 20, paddingRight: 0, paddingBottom: 15, paddingTop: 20, marginRight: 0 }}>
                    <div className="col-12 grid p-fluid">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="departmentName">Last name *</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userName"
                                onChange={(e) => { this.changeUserName(e.target.value) }} value={this.state.newUserName} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="departmentName">First name *</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userForeName"
                                onChange={(e) => { this.changeUserForename(e.target.value) }} value={this.state.newUserForename} />
                        </div>
                        {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.addSSOUser ?
                            <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                                <label>Use SSO (if available)</label>
                                <div>
                                    <Checkbox inputId='userPassword'
                                        onChange={(e) => this.handleSAMLLoginChange(e)}
                                        checked={this.state.samlLogin}
                                        value='Use SSO (if available)' style={{ 'cursor': 'pointer', marginTop: 10, marginLeft: -2 }}
                                    />
                                </div>
                            </div>
                            : <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em' }}>
                                <label htmlFor="companyName">Username (*, cannot be changed later)</label>
                                <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userUserName"
                                    onChange={(e) => { this.changeUserUsername(e.target.value) }} value={this.state.newUserUsername} />
                            </div>
                        }

                        {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.addSSOUser ?
                            <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em' }}>
                                <label htmlFor="companyName">Username (*, cannot be changed later)</label>
                                <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userUserName"
                                    onChange={(e) => { this.changeUserUsername(e.target.value) }} value={this.state.newUserUsername} />
                            </div>
                            : null
                        }
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="companyName">EMail *</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userEmail"
                                onChange={(e) => { this.changeUserEmail(e.target.value) }} value={this.state.newUserEmail} />
                        </div>
                        <div className={APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.addSSOUser ? "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" : "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4"} style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>User role</label>
                            <Dropdown style={{ width: '100%', border: '1px solid #d6d6d6', marginTop: 5 }}
                                className='dropdownNoBorder'
                                value={this.state.newUserRole}
                                options={userRoles}
                                appendTo={document.body}
                                onChange={(e) => this.handleUserRoleChange(e)}
                                placeholder='Select user role'>
                            </Dropdown>
                        </div>
                        <div className={APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.addSSOUser ? "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" : "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4"} style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>Status</label>
                            <div>
                                <InputSwitch style={{
                                    marginRight: 10, marginTop: 10
                                }} checked={this.state.newUserActive} onChange={(e) => this.setState({ newUserActive: e.value })} />
                                <label>{this.state.newUserActive ? 'Active' : 'Deactivated'}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                    <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 15 }}>* required</label>
                    <Button label="Save"
                        className='p-button-sm primaryButton'
                        disabled={this.state.newUserName !== '' && this.state.newUserForename !== '' && this.state.newUserEmail !== '' && this.state.newUserRole !== null &&
                            this.state.newUserUsername !== '' ? false : true}
                        style={{ float: 'right' }}
                        onClick={this.onAddUser} />
                    <Button className='p-button-secondary p-button-sm'
                        label="Cancel"
                        onClick={this.onCancelAddUser}
                        style={{ float: 'right', marginRight: 5 }} />
                </div>
            </Dialog>
        )
    }
}

export default AddUser