/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from "react";
import { MultiSelect } from 'primereact/multiselect';
import AutoComplete from "../../common/AutoComplete/AutoComplete";
import InputFieldWithClear from "../general/inputfields/InputFieldWithClear";
import { Button } from 'primereact/button';
import { fetchMergedTermSuggestions } from '../../../api/content/ConceptApi';
import { checkResultAndGetPayload } from '../../../api';
import ConfirmationDialog from "../../common/ConfirmDialog/ConfirmationDialog";
import { normalizeConceptTerm, deduplicateTerms, findMatchIgnoreCase, isArrayEmpty } from "../util";
import { Toast } from "primereact/toast";
import DomainColorBar from "../../common/DomainColorBar/DomainColorBar";


class OntologySearch extends Component {

  constructor(props) {
    super(props);

    // --- labels for domain selection --- //
    this.domainSelLabelNone = "No active domains";
    this.domainSelLabelAll = "Domains: All (" + (props.domainSelectItems ? props.domainSelectItems.length : 0) + ")";
    this.domainSelLabelSome = "Domain filter active";

    this.state = {
      domains: props.domainSelectItems ? props.domainSelectItems.map(domain => (domain.value)) : {},
      //inputText: props.initialSearchTerm ? props.initialSearchTerm : "",
      inputText: props.initialSearchTerm ? { term: props.initialSearchTerm } : { term: '' },
      termSuggestions: [],
      placeholder: this.domainSelLabelAll,
      part: ''
    };

    this.selItemTemplate = this.selItemTemplate.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);

    this.growl = createRef();
  }


  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.domainSelectItems !== this.props.domainSelectItems) {
      this.domainSelLabelAll = "All domains active (" + (nextProps.domainSelectItems ? nextProps.domainSelectItems.length : 0) + ")";

      this.setState({
        domains: nextProps.domainSelectItems ? nextProps.domainSelectItems.map(domain => (domain.value)) : {},
        placeholder: this.domainSelLabelAll
      });
    }

    //console.log('componentWillReceiveProps initialSearchTerm: ', nextProps.initialSearchTerm);

    if (nextProps.initialSearchTerm) {
      this.setState({
        //inputText: nextProps.initialSearchTerm
        inputText: nextProps.initialSearchTerm ? { term: nextProps.initialSearchTerm } : { term: '' },
      });
    }
  }

  /**
   * Handles term suggestions request for input text and selected domains.
   */
  autocomplete = (event) => {

    this.latestTermFragment = event.query;
    setTimeout(async () => {
      // --- fetch term suggestions --- //
      let terms = [];
      if (this.state.domains && this.state.domains.length > 0) {
        // term, filterDomains, annotatedTermsOnly
        //const response = await fetchTermSuggestions(event.query, this.state.domains, true, false);
        const response = await fetchMergedTermSuggestions(event.query, this.state.domains, null, this.props.rangeOcids, false, false);
        terms = checkResultAndGetPayload(response, this.growl);

        if (event.query === this.latestTermFragment && terms) {
          this.setState({
            termSuggestions: terms,
            part: event.query
          });
        }
      }
    }, 200);
  }

  /**
   * Handles changes in input field.
   */
  handleChangeInputText = (term) => {

    let value = term;
    // --- check if it is the object from the autocomplete field or a simple string --- //
    if (term instanceof Object) {
      //console.log('object: ', term);
      value = term.term;
    }

    this.setState({
      inputText: { term: value }
    });
  }


  onSuggestionSelected = (suggestion) => {
    //console.log('onSuggestionSelected: ', suggestion);
    this.props.onSubmitSearch(suggestion.term, this.state.domains);
  }

  /**
   * Updates domains and domain selection label when domain selection changes.
   */
  onSelectDomains = (domains) => {

    // --- adjust domain selection label depending on current selection --- //
    let placeholder;
    if (!domains || domains.length === 0) {
      placeholder = this.domainSelLabelNone;
    }
    else if (domains.length === this.props.domainSelectItems.length) {
      placeholder = this.domainSelLabelAll;
    }
    else {
      placeholder = this.domainSelLabelSome + ' (' + domains.length + ')';
    }

    this.setState({
      placeholder: placeholder,
      domains: domains
    });

    // --- call callback function when domains are updated --- //
    this.props.onSelectDomains(domains);
  }


  /**
   * Clear form. Submit empty search to load all ontlogies.
   */
  onClearAll = () => {

    const allDomains = this.props.domainSelectItems ? this.props.domainSelectItems.map(domain => (domain.value)) : {};
    this.onSelectDomains(allDomains);
    this.handleChangeInputText({ term: '' });
    this.props.onSubmitSearch('', allDomains);
    this.props.onClear();
  }

  selItemTemplate(item) {
    //console.log('sel item: ', item);
    return item.term;
  }

  itemTemplate(item) {
    //console.log('item: ', item);
    let term = item.term;
    let info = ''
    let coloredDomains

    if (item.domains) {

      const uniqueLabels = item.domains.map(dom =>
        this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
          this.props.domainLabelsMap[dom] : dom);
      coloredDomains = uniqueLabels.sort().map(lab => {
        let color = this.props.domainColors && this.props.domainColors.find(obj => {
          return obj.label === lab
        })
        const col = color?.color ? `#${color.color}` : '';
        return <React.Fragment key={lab}>
          <DomainColorBar color={col} />
          <span style={{ paddingLeft: 4, paddingRight: 10 }}>{lab}</span>
        </React.Fragment>
      })
      info = uniqueLabels.sort().join(', ');
    }

    let prefName = '';
    const prefnamesUnique = item.prefnames ? deduplicateTerms(item.prefnames) : [];
    if (prefnamesUnique && prefnamesUnique.length === 1 && normalizeConceptTerm(item.prefnames[0], true) !== normalizeConceptTerm(term, true)) {
      prefName = ` (${item.prefnames[0]})`;
    }
    let termFormatted = term;
    const match = findMatchIgnoreCase(term, this.state.part);
    if (match) {
      termFormatted = termFormatted.replace(match, `<span style="font-weight: normal">${match}</span>`);
    }
    termFormatted += `<span style="font-weight: normal">${prefName}</span>`;

    const cutOffOtherTerms = item.otherTerms ? [...item.otherTerms].splice(0, 2) : [];

    return <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{
        padding: '0 5px',
        width: '50%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
        title={term}>
        <span style={{ fontWeight: 'normal' }}>
          <span dangerouslySetInnerHTML={{ __html: termFormatted }}></span>
          {!isArrayEmpty(item.otherTerms) ?
            <span className="secondaryInfo clampingContainer line-clamp line-clamp-1-line"
              style={{ fontWeight: 'normal', display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', paddingBottom: 0 }}
              title={item.otherTerms.join(' · ')}
            >{cutOffOtherTerms.join(' · ')}{item.otherTerms.length > 2 ? ' ...' : ''}
            </span> : null
          }
        </span>
      </div>
      <div className="col-4 clampingContainer line-clamp line-clamp-1-line"
        style={{
          padding: '0 5px', width: '50%', overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
        {info ?
          <div className="grid" style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis', margin: 0, color: '#818181'
          }}
            title={info}>
            {coloredDomains}</div>
          : null
        }
      </div>
    </div>
  }


  render() {
    const { domainSelectItems, placeholder, initialSearchTerm, onSubmitSearch } = this.props;

    return (
      <>
        <Toast ref={this.growl} />

        <div className="grid" style={{ marginTop: 20 }}>
          <div className="col-fixed" style={{ paddingLeft: 0 }}>
            {domainSelectItems ?
              domainSelectItems.length === 1 ?
                <div className="matchDomain"
                  style={{ width: "240px", textAlign: "center" }}>
                  {domainSelectItems[0].label}
                </div>
                :
                <MultiSelect value={this.state.domains}
                  pt={{
                    checkboxIcon: {
                      onClick: (e) => {
                        e.stopPropagation();
                        if (e.target.className.baseVal !== "") {
                          e.target.parentNode.click();
                        } else {
                          e.target.parentNode.parentNode.click();
                        }
                      },
                    },
                    headerCheckbox: {
                      onClick: (e) => {
                        e.stopPropagation();
                        e.target.parentNode.click();
                      },
                    },
                  }}
                  maxSelectedLabels={3}
                  options={domainSelectItems}
                  style={{ width: "auto", border: 'none', verticalAlign: '50%' }}
                  scrollHeight="300px"
                  fixedPlaceholder={true}
                  placeholder={this.state.placeholder}
                  onChange={(e) => this.onSelectDomains(e.value)}
                  appendTo={document.body} />
              : null
            }
          </div>
          <div className="col">
            <InputFieldWithClear
              onClear={() => this.setState({ inputText: { term: '' } })}
              showIcon={this.state.inputText?.term}>
              <AutoComplete
                id="ontBrowserInput"
                className="ontBrowserInput width100perc" //unsetSuggestionListHeight
                field="term"
                defaultValue={initialSearchTerm}
                value={this.state.inputText}
                suggestions={this.state.termSuggestions}
                completeMethod={(e) => this.autocomplete(e)}
                placeholder={placeholder}
                minLength={1}
                multiple={false}
                onChange={(e) => this.handleChangeInputText(e.value)}
                onSelect={(e) => this.onSuggestionSelected(e.value)}
                itemTemplate={this.itemTemplate}
                //selectedItemTemplate={this.selItemTemplate}
                autoHighlight={true}
              />
            </InputFieldWithClear>
          </div>
          <div className="breakRow"></div>
          <div className="col-12 textAlignRight" >
            <a title="Reset page"
              onClick={() => this.setState({ displayConfirmationDialog: true })}
              style={{ display: 'inline-block', marginRight: 30, verticalAlign: '50%' }}>
              Clear all</a>
            <Button className="primaryButton p-button-sm"
              onClick={() => onSubmitSearch(this.state.inputText.term, this.state.domains)}
              style={{ verticalAlign: '50%' }}
              label='Filter'
              disabled={!this.state.domains || this.state.domains.length === 0} />
          </div>
        </div>

        <ConfirmationDialog
          displayDialog={this.state.displayConfirmationDialog}
          onHide={() => this.setState({ displayConfirmationDialog: false })}
          onSubmit={() => {
            this.onClearAll();
            this.setState({ displayConfirmationDialog: false });
          }}
          headerText="Confirm"
          messageText="Clear all input?"
          submitButtonLabel={"Clear"}
        />
      </>
    )
  }
}

export default OntologySearch;
