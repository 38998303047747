// --- Applications --- //

export const APP_ID_BON = 'bon';
export const APP_ID_CVG = 'cvg';
export const APP_ID_DIMENSIONS = 'dimensions';
export const APP_ID_DOW = 'dow';
export const APP_ID_IMART = 'animalhealth';
export const APP_ID_IQNOW = 'iqnow';
export const APP_ID_SCIWALKER = 'sciwalker';
export const APP_ID_SCIWALKER_STUDIO = 'sciwalker_studio';
export const APP_ID_SCIWALKER_ALPHA = 'sciwalker_test';

export const APP_IDS = [
  APP_ID_BON,
  APP_ID_CVG,
  APP_ID_DIMENSIONS,
  APP_ID_DOW,
  APP_ID_IMART,
  APP_ID_IQNOW,
  APP_ID_SCIWALKER,
  APP_ID_SCIWALKER_STUDIO,
  APP_ID_SCIWALKER_ALPHA,
] as const;

// --- API --- //

export const RESPONSE_STATUS_SUCCESS = 'SUCCESS';
export const RESPONSE_STATUS_FAILED = 'FAILED';
export const RESPONSE_STATUS_LOGGED_OUT = 'LOGGEDOUT';

// --- export --- //

export const GLOBAL_METADATA_DOWNLOAD_LIMIT = 20000;

export const EXPORTER_NUM_OF_DOCS_LIMIT = 1000;

export const EXPORTER_TARGET_ID_BIGQUERY = 'bigquery';
export const EXPORTER_TARGET_ID_FILE = 'file';

export const EXPORTER_TARGETS = [
  { id: EXPORTER_TARGET_ID_BIGQUERY, label: 'BigQuery', type: 'cloud' },
  { id: EXPORTER_TARGET_ID_FILE, label: 'File', type: 'file' },
] as const;

export const EXPORTER_FORMATS = [
  //    { id: 'csv', label: 'CSV' },
  { id: 'xls', label: 'XLSX' },
  { id: 'ris', label: 'RIS' },
] as const;

export const EXPORTER_DOC_SELECTIONS = [
  { id: 'selDocs', label: 'Selected documents', type: 'byDocIDs' },
  { id: 'onPage', label: `Documents on current page`, type: 'byDocIDs' },
  //{ id: 'top50', label: 'Top 50', max: 50, type: 'byQuery' },
  { id: 'top100', label: 'Top 100', max: 100, type: 'byQuery' },
  //{ id: 'top250', label: 'Top 250', max: 250, type: 'byQuery' },
  //{ id: 'top500', label: 'Top 500', max: 500, type: 'byQuery' },
  { id: 'top1000', label: 'Top 1,000', max: 1000, type: 'byQuery' },
  {
    id: 'top10000',
    label: 'Top 10,000',
    max: 10000,
    type: 'byQuery',
    onlyViaExportCenter: true,
  },
  //{ id: 'top100000', label: 'Top 100,000', max: 100000, type: 'byQuery', onlyViaExportCenter: true },
  //{ id: 'top500000', label: 'Top 500,000', max: 500000, type: 'byQuery', onlyViaExportCenter: true },
  {
    id: 'maxAllowed',
    label: `Maximum available *`,
    type: 'byQuery',
    onlyViaExportCenter: true,
  },
  /**/
  /*{ id: 'top10000', label: 'Top 10,000 *', max: 10000, type: 'byQuery', potSlowPerformance: true },
    { id: 'top25000', label: 'Top 25,000 *', max: 25000, type: 'byQuery', potSlowPerformance: true },
    { id: 'top50000', label: 'Top 50,000 *', max: 50000, type: 'byQuery', potSlowPerformance: true },*/
  //{ id: 'maxAllowed', label: `Max. allowed (1000)`, type: 'byQuery' },
] as const;

export const EXPORTER_DOC_SELECTIONS_LIBRARY = [
  { id: 'selDocs', label: 'Selected documents', type: 'byDocIDs' },
  { id: 'all', label: `All collection documents`, type: 'byDocIDs' },
] as const;

// --- semantic exporter --- //

export const SEMANTIC_EXPORTER_PRESETS = {
  restrictAutocomplete: false,
  subtrees: [
    {
      domains: ['chem'],
      label: 'Chemical compounds',
      term: 'Chemical compounds',
      isPreset: true,
      isSingleInput: true,
      specialCase: 'compounds',
    },
    {
      domains: ['companies'],
      ocids: [281000000000],
      label: 'Companies',
      term: 'Companies',
      isPreset: true,
    },
    {
      domains: ['devices'],
      ocids: [237200000591],
      label: 'Devices',
      term: 'Devices',
      isPreset: true,
    },
    {
      domains: ['diseases'],
      ocids: [201000010099],
      label: 'Diseases',
      term: 'Diseases',
      isPreset: true,
    },
    {
      domains: ['drugs'],
      ocids: [229940000407],
      label: 'Drugs',
      term: 'Drugs',
      isPreset: true,
    },
    {
      domains: ['polymers'],
      ocids: [229920000642],
      label: 'Polymers',
      term: 'Polymers',
      isPreset: true,
    },
    {
      domains: ['proteins'],
      ocids: [108020003292],
      label: 'Proteins and genes',
      term: 'Proteins and genes',
      isPreset: true,
    },
    {
      domains: ['species'],
      ocids: [241000894007],
      label: 'Species',
      term: 'Species',
      isPreset: true,
    },
    {
      domains: ['substances'],
      ocids: [239000000126],
      label: 'Substances',
      term: 'Substances',
      isPreset: true,
    },
    {
      domains: ['_ALL'],
      label: 'All concepts',
      term: 'All concepts',
      isPreset: true,
      isSingleInput: true,
      specialCase: '_ALL',
    },
  ],
} as const;

export const SEMANTIC_EXPORTER_DOC_SELECTIONS = [
  { id: 'selDocs', label: 'Selected documents', type: 'byDocIDs' },
  //{ id: 'maxAllowed', label: `Max. allowed` },
  { id: 'onPage', label: `Documents on current page`, type: 'byDocIDs' },
  { id: 'top50', label: 'Top 50', max: 50, type: 'byQuery' },
  //{ id: 'custom', label: 'Limit to:' },
  { id: 'top100', label: 'Top 100', max: 100, type: 'byQuery' },
  //{ id: 'top250', label: 'Top 250', max: 250, type: 'byQuery' },
  //{ id: 'top500', label: 'Top 500', max: 500, type: 'byQuery' },
] as const;

export const SEMANTIC_EXPORTER_TARGETS = [
  //{ id: 'looker', label: 'Looker', type: 'cloud' },
  { id: EXPORTER_TARGET_ID_BIGQUERY, label: 'BigQuery', type: 'cloud' },
  { id: EXPORTER_TARGET_ID_FILE, label: 'File', type: 'file' },
] as const;

export const SEMANTIC_EXPORTER_FORMATS = [
  //{ id: 'smi', label: 'SMI', specialCase: 'compounds' },
  { id: 'xlsx', label: 'XLSX' },
  { id: 'csv', label: 'CSV' },
] as const;

// --- watchlists and queries --- //

export const LOCAL_STORAGE_DOC_FINDER_DATA = 'docFinderData' as const;

// --- search categories --- //
export const QUERY_CATEGORY_DOC_SEARCH = {
  id: 'cat_doc_search',
  label: 'Document Search',
} as const;
export const QUERY_CATEGORY_COOC_SEARCH = {
  id: 'cat_cooc_search',
  label: 'Co-occurrence Analysis',
} as const;
export const QUERY_CATEGORY_FACT_SEARCH = {
  id: 'cat_fact_search',
  label: 'Fact Finder Search',
} as const;
export const QUERY_CATEGORY_CHEM_SEARCH = {
  id: 'cat_chem_search',
  label: 'Chemistry Search',
} as const;
export const QUERY_CATEGORY_SENTENCE_ANALYSIS_SEARCH = {
  id: 'cat_sentence_analysis_search',
  label: 'Sentence Analysis',
} as const;

// --- search types --- //
export const QUERY_TYPE_QUICK_SEARCH = {
  id: 'quick_search',
  label: 'Quick Search',
} as const;
export const QUERY_TYPE_QUICK_SEARCH_2 = {
  id: 'quick_search_2',
  label: 'Quick Search',
} as const;
export const QUERY_TYPE_ADVANCED_SEARCH = {
  id: 'advanced_search',
  label: 'Advanced Search',
} as const;
export const QUERY_TYPE_ADVANCED_SEARCH_3 = {
  id: 'advanced_search_3',
  label: 'Query Builder',
} as const;
export const QUERY_TYPE_COOC_SEARCH = {
  id: 'cooc_search',
  label: 'Co-occurrence Analysis',
} as const;
export const QUERY_TYPE_FACT_SEARCH = {
  id: 'fact_search',
  label: 'Fact Finder',
} as const;
export const QUERY_TYPE_CHEM_SEARCH = {
  id: 'chem_search',
  label: 'Chemistry Search',
} as const;
export const QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH = {
  id: 'sentence_analysis_search',
  label: 'Sentence Analysis',
} as const;

export const QUERY_TYPES_MAP = {
  [QUERY_TYPE_QUICK_SEARCH.id]: QUERY_TYPE_QUICK_SEARCH,
  [QUERY_TYPE_QUICK_SEARCH_2.id]: QUERY_TYPE_QUICK_SEARCH_2,
  [QUERY_TYPE_ADVANCED_SEARCH.id]: QUERY_TYPE_ADVANCED_SEARCH,
  [QUERY_TYPE_ADVANCED_SEARCH_3.id]: QUERY_TYPE_ADVANCED_SEARCH_3,
  [QUERY_TYPE_COOC_SEARCH.id]: QUERY_TYPE_COOC_SEARCH,
  [QUERY_TYPE_FACT_SEARCH.id]: QUERY_TYPE_FACT_SEARCH,
  [QUERY_TYPE_CHEM_SEARCH.id]: QUERY_TYPE_CHEM_SEARCH,
  [QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id]: QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH,
} as const;

// --- search forms (id can have max. 12 characters!) --- //
export const QUERY_FORM_QUICK = { id: 'quick', label: 'Quick' } as const;
export const QUERY_FORM_ADVANCED = {
  id: 'advanced',
  label: 'Advanced',
} as const;
export const QUERY_FORM_ADVANCED_3 = {
  id: 'advanced_3',
  label: 'Advanced NG',
} as const;
export const QUERY_FORM_CHEM_ATTRIBUTE = {
  id: 'chemAttr',
  label: 'Attribute',
} as const;
export const QUERY_FORM_CHEM_STRUCTURE = {
  id: 'chemStruct',
  label: 'Structure',
} as const;
export const QUERY_FORM_CORRELATIONS = {
  id: 'correlations',
  label: 'Correlations',
} as const;
export const QUERY_FORM_SUPPLIERS = {
  id: 'suppliers',
  label: 'Correlate Organizations',
} as const;
export const QUERY_FORM_DISEASE_TARGETS = {
  id: 'dis_targ',
  label: 'Correlate Mechanisms of Action',
} as const;
export const QUERY_FORM_PATHOLOGIES = {
  id: 'pathologies',
  label: 'Correlate Pathologies',
} as const;
export const QUERY_FORM_DRUG_REPURPOSING = {
  id: 'drugrepurp',
  label: 'Drug Repurposing',
} as const;
export const QUERY_FORM_PROTEIN_TARGETS = {
  id: 'prottargets',
  label: 'Protein targets',
} as const;

export const QUERY_FORM_AUTO_SAVE = {
  id: 'auto-save',
  label: 'Auto-saved search',
} as const;

// --- search types map --- //

export const VERSION_1_0 = 'v1.0';
export const VERSION_2_0 = 'v2.0';
export const VERSION_2_1 = 'v2.1';
