const today = new Date();

/**
 * Checks if given date has expired.
 * @param {string} expirationDateStrg date as string in format "yyyy-mm-dd"
 * @returns 
 */
export const hasDateExpired = (expirationDateStrg) => {
    const expirationDate = expirationDateStrg ? new Date(expirationDateStrg) : null;
    return expirationDate && expirationDate.getTime() <= today.getTime();
}