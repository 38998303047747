import {combineReducers} from 'redux'
import {IS_NOT_AUTHENTICATED} from '../actions'
import user from './user'
import users from './users'
import webAPI from './webAPI'
import company from './company'
import admin from './admin'
import managerReducer from './manager'
import tutorialReducer from './tutorials'
import biomarker from './biomarker'
import bioactivities from './bioactivities'
import yearStatistics from "./yearStatistics"
import pdfViewReducer from './pdfview'
import quickSearchReducer from './quickSearch'
import sentenceAnalysisReducer from './sentenceAnalysis'
import document from './document'
import filtersReducer from '../common/filters/reducers/filters'
import advancedSearch3Reducer from '../features/queryBuilder/reducers';
import notificationReducer from './notification'


/**
 * The root reducer clears all the state information if the users perform a logout operation. If there is no logout
 * operation the state and action is passed to the combined reducers.
 *
 * @param state, redux state
 * @param action, redux action
 * @returns combined reducer
 */
const rootReducer = (state, action) => {
    if (action.type === IS_NOT_AUTHENTICATED) {
        state = undefined
    }
    return appReducer(state, action)
}

const appReducer = combineReducers({
    user,
    users,
    webAPI,
    company,
    admin,
    managerReducer,
    tutorialReducer,
    biomarker,
    bioactivities,
    yearStatistics,
    pdfViewReducer,
    quickSearchReducer,
    sentenceAnalysisReducer,
    document,
    filtersReducer,
    advancedSearch3Reducer,
    notificationReducer
})

export default rootReducer
