import { simplifyQuery } from "../../../../api/content/QueryApi";
import { RESPONSE_STATUS_SUCCESS } from "../../../../properties";
import {
  FREETEXT_EXACT,
  FREETEXT_WITH_VARIANTS,
  SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL,
  SEARCH_FILTER_ID_SEARCH_MODE_LOCAL,
  SEARCH_FILTER_ID_TERMLOC,
  WILDCARD_ASTERISK,
} from "../../general/docsearch/searchConstants";
import { isArrayEmpty, isObjectEmpty } from "../../util";
import { isFreeText, sortGroupReferences } from "./advancedSearch";
import {
  LOGICAL_OPERATOR_AND,
  LOGICAL_OPERATOR_NOT,
  LOGICAL_OPERATOR_OR,
  SEARCH_GROUP_TYPE_API_SYNTAX,
  SEARCH_GROUP_TYPE_FILTER,
  SEARCH_GROUP_TYPE_LOGIC,
  SEARCH_GROUP_TYPE_SAVED_SEARCH,
  SEARCH_GROUP_TYPE_TERM,
} from "./constants";

// TODO:  ignore default values, e.g. a boost value of 1

// === create all query strings for each group === //

/**
 * Creates all query strings for each group.
 * @param {*} searchGroups
 * @param {*} filterDefinitions
 * @returns map with group IDs as keys and their query strings as values
 */
export const createAllQueryStrings = async (
  searchGroups,
  filterDefinitions
) => {
  const queryStrings = {};
  if (!isArrayEmpty(searchGroups)) {
    for (let searchGroup of searchGroups) {
      const queryString = await createPartialQueryString(
        searchGroup,
        searchGroups,
        filterDefinitions
      );
      queryStrings[searchGroup.id] = { query: queryString };
    }
  }
  return queryStrings;
};

// === create query string for complete search === //

/**
 * Creates query string for complete search.
 * Starting from last group; all references will be resolved; unreachable groups will be ignored.
 * @param {*} searchGroups
 * @param {*} filterDefinitions
 * @returns
 */
export const createQueryString = async (searchGroups, filterDefinitions) => {
  if (!isArrayEmpty(searchGroups)) {
    const mainSearchGroup = searchGroups[searchGroups.length - 1];
    return createPartialQueryString(
      mainSearchGroup,
      searchGroups,
      filterDefinitions
    );
  }
  return "";
};

// === create query string for partial search === //

/**
 * Creates query string for partial search.
 * Starting from given group; all references will be resolved; unreachable groups will be ignored.
 * @param {*} startSearchGroup
 * @param {*} searchGroups
 * @param {*} filterDefinitions
 * @param {*} includeFilterGroups
 * @returns
 */
export const createPartialQueryString = async (
  startSearchGroup,
  searchGroups,
  filterDefinitions
) => {
  let queryStringComplete = "";
  if (startSearchGroup) {
    queryStringComplete = await createGroupQueryString(
      startSearchGroup,
      searchGroups,
      filterDefinitions
    );
  }

  // TODO: simplify query as soon as BE/MW calls work
  const queryStringSimplified = queryStringComplete;
  // const queryStringSimplified = simplifyQueryString(queryStringComplete);

  return queryStringSimplified || queryStringComplete;
};

const createGroupQueryString = async (
  searchGroup,
  searchGroups,
  filterDefinitions
) => {
  let queryString = "";
  let queryTermString = "";
  const { searchCriteria, operator } = searchGroup;
  const searchCriteriaString = createSearchCriteriaQueryString(
    searchCriteria,
    filterDefinitions
  );

  switch (searchGroup?.type) {
    case SEARCH_GROUP_TYPE_TERM: {
      const { queryTerms } = searchGroup;
      const queryStringParts = [];

      // === concept terms === //
      const conceptsQueryTerms = queryTerms.filter(
        (qt) => !isFreeText(qt.domains)
      );
      const nearbyString = createLocalNearbyQueryString(
        searchCriteria,
        filterDefinitions
      );
      if (nearbyString) {
        const conceptsQueryString = createTermsQueryString(
          conceptsQueryTerms,
          operator,
          filterDefinitions,
          false
        );
        // const simplifiedConceptsQueryString = await simplifyQueryString(conceptsQueryString);
        const nearbyConceptsQueryString = `(${nearbyString} ${conceptsQueryString})`;
        queryStringParts.push(nearbyConceptsQueryString);
      } else {
        const conceptsQueryString = createTermsQueryString(
          conceptsQueryTerms,
          operator,
          filterDefinitions,
          true
        );
        queryStringParts.push(conceptsQueryString);
      }

      // === text terms === //
      const textsQueryTerms = queryTerms.filter((qt) => isFreeText(qt.domains));
      const textsQueryString = createTermsQueryString(
        textsQueryTerms,
        operator,
        filterDefinitions,
        true
      );
      if (textsQueryString) {
        queryStringParts.push(textsQueryString);
      }
      queryTermString = combineQueryStrings(queryStringParts, operator, false);
      break;
    }

    case SEARCH_GROUP_TYPE_LOGIC: {
      const { searchGroupIDRefs } = searchGroup;
      const queryGroupParts = [];

      // === referenced groups === //
      const groupReferencesSorted = sortGroupReferences(
        searchGroupIDRefs,
        searchGroups,
        operator === LOGICAL_OPERATOR_NOT.value
      );
      for (let groupIDRef of groupReferencesSorted) {
        const refGroup = searchGroups.find((sg) => sg.id === groupIDRef);
        const groupQueryString = await createGroupQueryString(
          refGroup,
          searchGroups,
          filterDefinitions
        );
        queryGroupParts.push(groupQueryString);
      }

      const nearbyString = createLocalNearbyQueryString(
        searchCriteria,
        filterDefinitions
      );
      if (nearbyString) {
        queryTermString = combineQueryStrings(queryGroupParts, operator, false);
        // console.log('logic group queryTermString', queryTermString);
        // const simplifiedConceptsQueryString = await simplifyQueryString(groupQueryString);
        queryTermString = `(${nearbyString} ${queryTermString})`;
      } else {
        queryTermString = combineQueryStrings(queryGroupParts, operator, true);
      }

      break;
    }

    case SEARCH_GROUP_TYPE_FILTER: {
      const { queryTerms } = searchGroup;
      queryTermString = createFiltersQueryString(
        queryTerms,
        operator,
        filterDefinitions
      );
      break;
    }

    case SEARCH_GROUP_TYPE_SAVED_SEARCH: {
      queryTermString = `${
        searchGroup.savedSearchData?.value ||
        searchGroup.savedSearchData?.val ||
        ""
      }`;
      break;
    }

    case SEARCH_GROUP_TYPE_API_SYNTAX: {
      queryTermString = `${searchGroup.queryString || ""}`;
      break;
    }
  }

  queryString = searchCriteriaString
    ? `(${searchCriteriaString} ${queryTermString})`
    : `(${queryTermString})`;
  // console.log('END ===> query string', queryString);

  return queryString.trim();
};

const createSearchCriteriaQueryString = (searchCriteria, filterDefinitions) => {
  // console.log('searchCriteria', searchCriteria);
  let queryString = "";
  if (!isObjectEmpty(searchCriteria)) {
    if (searchCriteria[SEARCH_FILTER_ID_SEARCH_MODE_LOCAL]) {
      const filter = filterDefinitions[SEARCH_FILTER_ID_SEARCH_MODE_LOCAL];
      queryString += ` ${filter.queryPrefix}:"${searchCriteria[SEARCH_FILTER_ID_SEARCH_MODE_LOCAL].filterValue}"`;
    }
    if (searchCriteria[SEARCH_FILTER_ID_TERMLOC]) {
      const filter = filterDefinitions[SEARCH_FILTER_ID_TERMLOC];
      queryString += ` ${filter.queryPrefix}:"${searchCriteria[SEARCH_FILTER_ID_TERMLOC].filterValue}"`;
    }
  }

  return queryString.trim();
};

const createLocalNearbyQueryString = (searchCriteria, filterDefinitions) => {
  // console.log('searchCriteria', searchCriteria);
  let queryString = "";
  if (!isObjectEmpty(searchCriteria)) {
    if (searchCriteria[SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL]) {
      const filter = filterDefinitions[SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL];
      queryString += `${filter.queryPrefix}:"${searchCriteria[SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL].filterValue}"`;
    }
  }

  return queryString.trim();
};

const createTermsQueryString = (
  queryTerms,
  operator,
  filterDefinitions,
  addGroupBrackets = true
) => {
  // console.log('queryTerms', queryTerms);
  // console.log('operator', operator);

  let queryString = "";
  const conceptAndTermGroups = [];

  queryTerms.forEach((queryTerm) => {
    conceptAndTermGroups.push(
      createTermQueryString(queryTerm, filterDefinitions)
    );
    // console.log('conceptAndTermGroups', conceptAndTermGroups);
  });

  if (!isArrayEmpty(conceptAndTermGroups)) {
    queryString = combineQueryStrings(
      conceptAndTermGroups,
      operator,
      addGroupBrackets
    );
  }
  // console.log('conceptAndTermGroups', conceptAndTermGroups);
  // console.log('===> queryString', queryString);
  return queryString.trim();
};

const createTermQueryString = (queryTerm, filterDefinitions) => {
  // console.log('queryTerm', queryTerm);

  let queryString = ``;

  if (!isArrayEmpty(queryTerm.ocids)) {
    queryString = queryTerm.ocids
      .map((ocid) => {
        return `ocid:"${ocid}"`;
      })
      .join(" ");
    // TODO: handle increase recall
    // queryString = queryTerm.increaseRecall ? [queryString, createIncreaseRecallQueryString(queryTerm)].join(' ') : queryString;
    queryString =
      queryTerm.ocids.length > 1 ? `(${queryString})` : `${queryString}`;
  } else if (!isArrayEmpty(queryTerm.domains)) {
    const textDistance = queryTerm.textDistance
      ? `~${queryTerm.textDistance}`
      : "";
    if (queryTerm.domains.includes(FREETEXT_EXACT.prefix)) {
      queryString = `${FREETEXT_EXACT.prefix}:"${removeQuotesFromString(
        queryTerm.term
      )}"${textDistance}`;
    } else if (queryTerm.domains.includes(FREETEXT_WITH_VARIANTS.prefix)) {
      queryString = `${FREETEXT_WITH_VARIANTS.prefix}:"${removeQuotesFromString(
        queryTerm.term
      )}"${textDistance}`;
    }
  }

  const booster = queryTerm.booster ? `^${queryTerm.booster.toFixed(1)}` : "";
  queryString = `${queryString}${booster}`;

  if (queryTerm.searchMode) {
    const filter = filterDefinitions[SEARCH_FILTER_ID_SEARCH_MODE_LOCAL];
    queryString = `(${filter.queryPrefix}:${queryTerm.searchMode} ${queryString})`;
  }

  // console.log('-> createQueryTermString', queryString);
  return queryString.trim();
};

// === filter group === //

const createFiltersQueryString = (queryTerms, operator, filterDefinitions) => {
  // console.log('queryTerms', queryTerms);
  // console.log('operator', operator);

  let queryString = "";
  const conceptAndTermGroups = [];

  queryTerms.forEach((queryTerm) => {
    conceptAndTermGroups.push(
      createFilterQueryString(queryTerm, filterDefinitions)
    );
  });
  queryString = combineQueryStrings(conceptAndTermGroups, operator);
  // console.log('-> createFiltersQueryString', queryString);
  return queryString.trim();
};

const createFilterQueryString = (queryTerm, filterDefinitions) => {
  // console.log('filterTerm', queryTerm);

  const filter = filterDefinitions[queryTerm?.filterID];
  let queryString = ``;

  if (filter && queryTerm?.val) {
    if (queryTerm.val.term) {
      queryString = `${filter.queryPrefix}:"${queryTerm.val.term}"`;
    } else if (queryTerm.val.date) {
      queryString = `${filter.queryPrefix}:"#${queryTerm.val.date}"`;
    } else if (queryTerm.val.startDate || queryTerm.val.endDate) {
      queryString = `${filter.queryPrefix}:"${queryTerm.val.startDate || ""}_${
        queryTerm.val.endDate || ""
      }"`;
    }
  }
  // console.log('-> createFilterQueryString', queryString);
  return queryString.trim();
};

// === general === //

const combineQueryStrings = (
  queryStrings,
  operator,
  addGroupBrackets = false
) => {
  let queryString = "";

  const queryStringsFiltered = queryStrings
    ? queryStrings.filter((qryString) => !!qryString)
    : [];
  // console.log('queryStringsFiltered', queryStringsFiltered);
  if (queryStringsFiltered.length === 1) {
    return queryStringsFiltered[0];
  } else if (queryStringsFiltered.length > 1) {
    // const queryStringsGrouped = queryStringsFiltered.map(qryString => `(${qryString})`);
    const queryStringsGrouped = queryStringsFiltered;

    switch (operator) {
      case LOGICAL_OPERATOR_AND.value: {
        queryString = `+${queryStringsGrouped.join(" +")}`;
        break;
      }
      case LOGICAL_OPERATOR_OR.value: {
        queryString = queryStringsGrouped.join(" ");
        break;
      }
      case LOGICAL_OPERATOR_NOT.value: {
        queryString = `+${queryStringsGrouped.join(" -")}`;
        break;
      }
    }
    queryString = addGroupBrackets ? `(${queryString})` : queryString;
  }
  return queryString.trim();
};

const removeQuotesFromString = (text) => {
  return text ? `${text.trim()}`.replace(/"|“|’|'/g, "") : "";
};

const createIncreaseRecallQueryString = (queryTerm) => {
  return queryTerm?.queryValues
    ?.map((term) => {
      if (term !== WILDCARD_ASTERISK) {
        const qTerm = `${term}`;
        return `${FREETEXT_EXACT.prefix}:"${removeQuotesFromString(qTerm)}"`;
      }
      return "";
    })
    .join(" ");
};

/**
 *
 * @param {string} queryString
 */
export const simplifyQueryString = async (queryString) => {
  const fetchSimplifiedQuery = async () => {
    const result = await simplify(queryString);
    console.log("simplify result", result);
    return result;
  };
  const test = await fetchSimplifiedQuery().catch(console.error);
  console.log("test", test);
  return test;
};

const simplify = async (queryString) => {
  const response = await simplifyQuery(queryString, true);
  console.log("response", response);

  if (response.status === RESPONSE_STATUS_SUCCESS) {
    if (response.payload?.hasErrors && response.payload.errors?.errors) {
      let errorMsg = "";
      Object.values(response.payload.errors.errors).forEach((error) => {
        errorMsg += '"' + error.queryFragment + '" (' + error.type + "); ";
      });
      console.error(errorMsg);
    } else {
      return response.payload?.query;
    }
  } else {
    console.error(response.message);
  }
  return null;
};

// TODO: move to general file?
export const createAllTermQueryStrings = (queryTerms, filterDefinitions) => {
  const queryObjects = {};
  let index = 0;
  if (!isObjectEmpty(queryTerms)) {
    for (let queryTerm of queryTerms) {
      const queryString = createTermQueryString(queryTerm, filterDefinitions);
      queryObjects[queryTerm.id] = { query: queryString };
      index++;
    }
  }

  return queryObjects;
};

export const findDuplicateQueryStrings = (queryObjects) => {
  const queryStrings = {};
  const duplicates = {};
  if (!isObjectEmpty(queryObjects)) {
    Object.entries(queryObjects).forEach(([qtID, queryObject]) => {
      if (!queryStrings[queryObject.query]) {
        queryStrings[queryObject.query] = [];
      } else {
        queryStrings[queryObject.query].push(qtID);
        duplicates[qtID] = true;
      }
    });
  }

  return duplicates;
};
