/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Chips } from 'primereact/chips';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { OverlayPanel } from "primereact/overlaypanel";
import infoIcon from "../../assets/images/icons/info/dimensions-icon-info.png";
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { APP_PROPERTIES } from '../../properties/index';
import { RadioButton } from 'primereact/radiobutton';
import { getYearRangeFuture } from '../webapi/util';
import { hasUserRole, addThousandsSeparatorToNumber } from '../../components/webapi/util';
import LogoUpload from '../common/LogoUpload/LogoUpload';

class EditDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFeatures: [],
            allRepositoriesChecked: false,
            allHighlightedDomainsChecked: false,
            allSearchableDomainsChecked: false,
            selectedRepositories: [],
            selectedHighlightDomains: [],
            selectedSearchDomains: [],
            mouseOverRepositoryButton: false,
            departmentApplicationName: '',
            newDeparmentChecked: false,
            startDemoDepartmentChecked: false,
            endDemoDepartmentChecked: false,
            activeDepartment: false,
            startDemoTime: '',
            endDemoTime: '',
            defaultFeatures: [],
            defaultFeaturesStart: [],
            departmentName: '',
            departmentLandingPage: 'docsearch',
            statusValue: 'deactivated',
            applicationHeader1: '',
            applicationHeader2: '',
            applicationHeader3: '',
            backgroundColor: '',
            fontColor: '',
            customHeaderChecked: false,
            headerImage: APP_PROPERTIES.HEADER_LOGO_APP,
            emailDomains: [],
            selectedEmailDomains: [],
            emailDomainOptions: [],
            startSelectedEMailDomains: [],
            companyEmailDomains: [],
            displayDefaultFeatures: false,
            defaultBackend: null,
            defaultChemBackend: null,
            maxDownloads: null,
            maxDownloadsCompany: null
        }
        this.statusOptions = [
            { name: 'Active', value: 'activated' },
            { name: 'Demo', value: 'demo' },
            { name: 'Inactive', value: 'deactivated' }
        ];

        this.hiddenRepoStates = {}

        this.growl = createRef();
    }

    componentDidMount() {
        let today = new Date()
        let minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        let minDateEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)

        let selected = [...this.state.selectedFeatures]
        let defaultFeatures = []
        let defaultFeaturesStart = []
        this.props.selectedFeaturesDepartment && this.props.selectedFeaturesDepartment.forEach(feat => {
            selected.push(feat.name)
            if (feat.isDefault) {
                defaultFeatures.push(feat.name)
            }
        })

        this.props.availableFeaturesCompany && this.props.availableFeaturesCompany.forEach(feat => {
            if (feat.isDefault) {
                defaultFeaturesStart.push(feat.name)
            }
        })

        let selectedRepositories = [...this.state.selectedRepositories]
        this.props.selectedDepartmentRepositories && this.props.selectedDepartmentRepositories.forEach(rep => {
            selectedRepositories.push(rep)
        })

        let selectedHighlightDomains = [...this.state.selectedHighlightDomains]
        this.props.selectedHighlightDomainsDepartment && this.props.selectedHighlightDomainsDepartment.forEach(dom => {
            selectedHighlightDomains.push(dom)
        })

        let selectedSearchDomains = [...this.state.selectedSearchDomains]
        this.props.selectedSearchDomainsDepartment && this.props.selectedSearchDomainsDepartment.forEach(dom => {
            selectedSearchDomains.push(dom)
        })

        let departmentName = this.props.selectedDepartmentName
        let applicationName = this.props.selectedDepartmentApplicationName
        let departmentActive = this.props.departmentActive
        let startDemoTime = this.props.startTrialPeriodDepartment !== '' ? this.props.startTrialPeriodDepartment : minDate
        let endDemoTime = this.props.endTrialPeriodDepartment !== '' ? this.props.endTrialPeriodDepartment : minDateEnd
        let startDemoDepartmentChecked = this.props.startTrialPeriodDepartment !== '' ? true : false
        let endDemoDepartmentChecked = this.props.endTrialPeriodDepartment !== '' ? true : false
        let departmentLandingPage = ''

        let backgroundColor = this.props.departmentBackgroundColor !== undefined ? this.props.departmentBackgroundColor : ''
        let fontColor = this.props.departmentFontColor !== undefined ? this.props.departmentFontColor : ''
        let applicationHeader1 = this.props.departmentApplicationHeader1 !== '' ? this.props.departmentApplicationHeader1 : ''
        let applicationHeader2 = this.props.departmentApplicationHeader2 !== '' ? this.props.departmentApplicationHeader2 : ''
        let applicationHeader3 = this.props.departmentApplicationHeader3 !== '' ? this.props.departmentApplicationHeader3 : ''
        let logo = this.props.departmentLogo !== undefined ? this.props.departmentLogo : APP_PROPERTIES.HEADER_LOGO_APP
        let useCustomHeader = this.props.useCustomHeader

        if (this.props.departmentLandingPage && this.props.selectedFeaturesDepartment.some(el => el.name === this.props.departmentLandingPage)) {
            departmentLandingPage = this.props.departmentLandingPage
        } else {
            departmentLandingPage = 'docsearch'
        }

        let defaultBackend = null
        if (this.props.selectedDepartmentDefaultBackend !== null) {
            defaultBackend = this.props.selectedDepartmentDefaultBackend
        } else {
            this.props.allBackends && APP_PROPERTIES.ACTIVE_PAGES.backendAdministration && this.props.allBackends.forEach(be => {
                if (be.isDefault === true && (be.type === 'webapi')) {
                    defaultBackend = be.id
                }
            })
        }

        let defaultChemBackend = null
        if (this.props.selectedDepartmentDefaultChemistryBackend !== null) {
            defaultChemBackend = this.props.selectedDepartmentDefaultChemistryBackend
        } else {
            this.props.allBackends && APP_PROPERTIES.ACTIVE_PAGES.backendAdministration && this.props.allBackends.forEach(be => {
                if (be.isDefault === true && (be.type === 'chemistry')) {
                    defaultChemBackend = be.id
                }
            })
        }

        let emailDomains = this.props.selectedDepartmentEMailDomains ? this.props.selectedDepartmentEMailDomains : []
        let availableEmailDomains = this.props.availableCompanyEmailDomains ? this.props.availableCompanyEmailDomains : []
        let companyEmailDomains = this.props.companyEmailDomains ? this.props.companyEmailDomains : []
        let maxDownloads = this.props.selectedDepartmentMaxDownloads ? this.props.selectedDepartmentMaxDownloads : null
        let maxDownloadsCompany = this.props.selectedCompanyMaxDownloads !== null ? this.props.selectedCompanyMaxDownloads : null

        let multiDomains = []
        let selectedEmailDomains = []
        let startSelectedEMailDomains = []
        if (emailDomains && emailDomains.length > 0) {
            emailDomains.forEach(email => {
                selectedEmailDomains = [...selectedEmailDomains, email]
                startSelectedEMailDomains = [...startSelectedEMailDomains, email]
            })
        }

        if (availableEmailDomains && availableEmailDomains.length > 0) {
            availableEmailDomains.forEach(email => {
                multiDomains = [...multiDomains, { label: email, value: email }]
            })
        }

        this.setState({
            defaultBackend: defaultBackend,
            defaultChemBackend: defaultChemBackend,
            departmentName: departmentName === 'default' ? 'Unassigned / Inactive regular users' : departmentName,
            departmentApplicationName: applicationName,
            selectedHighlightDomains: selectedHighlightDomains,
            selectedSearchDomains: selectedSearchDomains,
            selectedFeatures: selected,
            selectedRepositories: selectedRepositories,
            defaultFeatures: defaultFeatures,
            defaultFeaturesStart: defaultFeaturesStart,
            statusValue: departmentActive,
            startDemoTime: startDemoTime,
            startDemoDepartmentChecked: startDemoDepartmentChecked,
            endDemoTime: endDemoTime,
            endDemoDepartmentChecked: endDemoDepartmentChecked,
            departmentLandingPage: departmentLandingPage,
            applicationHeader1: applicationHeader1,
            applicationHeader2: applicationHeader2,
            applicationHeader3: applicationHeader3,
            backgroundColor: backgroundColor,
            fontColor: fontColor,
            headerImage: logo,
            customHeaderChecked: useCustomHeader,
            emailDomains: emailDomains,
            emailDomainOptions: multiDomains,
            selectedEmailDomains: selectedEmailDomains,
            startSelectedEMailDomains: startSelectedEMailDomains,
            companyEmailDomains: companyEmailDomains,
            maxDownloads: maxDownloads,
            maxDownloadsCompany: maxDownloadsCompany
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDepartmentDefaultBackend !== prevProps.selectedDepartmentDefaultBackend) {
            this.setState({
                defaultBackend: this.props.selectedDepartmentDefaultBackend
            })
        }
        if (this.props.selectedDepartmentDefaultChemistryBackend !== prevProps.selectedDepartmentDefaultChemistryBackend) {
            this.setState({
                defaultChemBackend: this.props.selectedDepartmentDefaultChemistryBackend
            })
        }
    }

    onDefaultBackendChange = (e, type) => {
        this.props.callbackFromDefaultBackendChangeDepartment(e.value.id, type)
    }

    updateDepartmentName = (name) => {
        this.setState({
            departmentName: name
        })
    }

    updateDepartmentApplicationName = (name) => {
        this.setState({
            departmentApplicationName: name
        })
    }

    updateDepartmentEmailDomains = (domains) => {
        if (domains.length === 0 && this.state.startSelectedEMailDomains.length > 0) {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'warn', life: 15000,
                summary: 'Using email domains from organization.',
                detail: `The organization has entered email domains to restrict user access.
                 By choosing none the email domains from the organization are used for this suborganization automatically .`
            });
        }
        this.setState({
            selectedEmailDomains: domains
        })
    }

    updateMaxDownloads = (max) => {
        this.setState({
            maxDownloads: max
        })
    }

    onAvailableRepositoriesChange = (e, id) => {
        let selected = [...this.state.selectedRepositories]
        if (e.checked) {
            selected.push(id)

        } else {
            selected.splice(selected.indexOf(id), 1)
        }
        this.setState({
            selectedRepositories: selected
        })
    }

    onAvailableFeaturesChange = (e, id) => {
        if ((id === this.state.departmentLandingPage) && e.checked === false) {
            this.setState({
                departmentLandingPage: 'docsearch'
            })
        }

        let selected = [...this.state.selectedFeatures]
        let defaultFeaturesStart = [...this.state.defaultFeaturesStart]
        if (e.checked) {
            selected.push(id)
            defaultFeaturesStart.push(id)
        } else {
            selected.splice(selected.indexOf(id), 1)
            defaultFeaturesStart.splice(selected.indexOf(id), 1)
        }
        this.setState({
            selectedFeatures: selected,
            defaultFeaturesStart: defaultFeaturesStart
        })
    }

    onHighlightDomainsChange = (e, value) => {
        let selected = [...this.state.selectedHighlightDomains]
        if (e.checked) {
            selected.push(value)
        } else {
            selected.splice(selected.indexOf(value), 1)
        }
        this.setState({
            selectedHighlightDomains: selected
        })
    }

    onSearchDomainsChange = (e, value) => {
        let selected = [...this.state.selectedSearchDomains]
        if (e.checked) {
            selected.push(value)
        } else {
            selected.splice(selected.indexOf(value), 1)
        }
        this.setState({
            selectedSearchDomains: selected
        })
    }

    onActiveDepartmentChange = (e) => {
        this.setState({
            activeDepartment: e.value
        })
    }

    onCheckStartDemoDepartment = (e) => {
        if (e.checked) {
            this.setState({
                startDemoDepartmentChecked: true
            })
        } else {
            this.setState({
                startDemoDepartmentChecked: false
            })
        }
    }

    onCheckEndDemoDepartment = (e) => {
        if (e.checked) {
            this.setState({
                endDemoDepartmentChecked: true
            })
        } else {
            this.setState({
                endDemoDepartmentChecked: false
            })
        }
    }

    onCheckAllRepos = (e) => {
        if (e.checked) {
            let allCheckedRepositories = []
            this.props.availableRepositoriesDepartment.forEach(rep => {
                allCheckedRepositories = [...allCheckedRepositories, rep.id]

            })
            this.setState({
                allRepositoriesChecked: true,
                selectedRepositories: allCheckedRepositories
            })
        } else {
            this.setState({
                allRepositoriesChecked: false,
                selectedRepositories: []
            })
        }
    }

    onCheckAllFeatures = (e) => {
        if (e.checked) {
            let allCheckedFeatures = []
            this.props.availableFeaturesCompany.forEach(feat => {
                allCheckedFeatures = [...allCheckedFeatures, feat.name]

            })
            this.setState({
                allFeaturesChecked: true,
                selectedFeatures: allCheckedFeatures
            })
        } else {
            this.setState({
                allFeaturesChecked: false,
                selectedFeatures: []
            })
        }
    }

    onCheckAllHighlightedDomains = (e) => {
        if (e.checked) {
            let allSelectedHighlightDomains = []
            this.props.availableHighlightDomainsCompany.forEach(dom => {
                allSelectedHighlightDomains = [...allSelectedHighlightDomains, dom.name]

            })
            this.setState({
                allHighlightedDomainsChecked: true,
                selectedHighlightDomains: allSelectedHighlightDomains
            })
        } else {
            this.setState({
                allHighlightedDomainsChecked: false,
                selectedHighlightDomains: []
            })
        }
    }

    onCheckAllSearchableDomains = (e) => {
        if (e.checked) {
            let allSelectedSearchDomains = []
            this.props.availableSearchDomainsCompany.forEach(dom => {
                allSelectedSearchDomains = [...allSelectedSearchDomains, dom.name]

            })
            this.setState({
                allSearchableDomainsChecked: true,
                selectedSearchDomains: allSelectedSearchDomains
            })
        } else {
            this.setState({
                allSearchableDomainsChecked: false,
                selectedSearchDomains: []
            })
        }
    }

    handleStartDemoTimeChange = (value) => {
        this.setState({
            startDemoTime: value
        })
    }

    handleEndDemoTimeChange = (value) => {
        this.setState({
            endDemoTime: value
        })
    }

    onEditDepartment = () => {
        this.props.onEditDepartment(this.state.statusValue, this.state.selectedHighlightDomains, this.state.departmentApplicationName, this.state.selectedSearchDomains,
            this.state.endDemoTime, this.state.endDemoDepartmentChecked, this.state.selectedFeatures, this.state.departmentName, this.state.selectedRepositories,
            this.state.startDemoTime, this.state.startDemoDepartmentChecked, this.state.departmentLandingPage, this.state.customHeaderChecked, this.state.applicationHeader1,
            this.state.applicationHeader2, this.state.applicationHeader3, this.state.backgroundColor, this.state.fontColor, this.state.headerImage, this.props.departmentLogo, this.state.selectedEmailDomains, this.state.maxDownloads)
    }

    enterButtonFeature = (e, name) => {
        this.setState({
            mouseOverFeatureButton: true,
            featName: name
        })
    }

    leaveButtonFeature = (e) => {
        this.setState({
            mouseOverFeatureButton: false
        })
    }

    setLandingPage = (e, name) => {
        this.setState({
            departmentLandingPage: name
        })
    }

    handleStatusChange = (e) => {
        if (e.value !== null) {
            this.setState({
                statusValue: e.value
            })
        }
    }

    handleImageUpload = (image) => {
        this.setState({ headerImage: image })
    }

    onCheckCustomHeader = (e) => {
        if (e.checked) {
            this.setState({
                customHeaderChecked: true
            })
        } else {
            this.setState({
                customHeaderChecked: false
            })
        }
    }

    updateApplicationHeader1 = (name) => {
        this.setState({
            applicationHeader1: name
        })
    }

    updateApplicationHeader2 = (name) => {
        this.setState({
            applicationHeader2: name
        })
    }

    updateApplicationHeader3 = (name) => {
        this.setState({
            applicationHeader3: name
        })
    }

    updateBackgroundColor = (color) => {
        this.setState({
            backgroundColor: color
        })
    }

    updateFontColor = (color) => {
        this.setState({
            fontColor: color
        })
    }

    closeDefaultFeatures = () => {
        this.setState({
            displayDefaultFeatures: false
        })
    }

    render() {

        let numberApplicationsHeaders = ((this.state.applicationHeader1 !== '' && this.state.applicationHeader2 === '' && this.state.applicationHeader3 === '')
            || (this.state.applicationHeader2 !== '' && this.state.applicationHeader1 === '' && this.state.applicationHeader3 === '')
            || (this.state.applicationHeader3 !== '' && this.state.applicationHeader1 === '' && this.state.applicationHeader2 === '')) ? 1 :
            ((this.state.applicationHeader1 !== '' && this.state.applicationHeader2 !== '' && this.state.applicationHeader3 === '')
                || (this.state.applicationHeader2 !== '' && this.state.applicationHeader1 === '' && this.state.applicationHeader3 !== '')
                || (this.state.applicationHeader3 !== '' && this.state.applicationHeader1 !== '' && this.state.applicationHeader2 === '')) ? 2 :
                (this.state.applicationHeader3 !== '' && this.state.applicationHeader1 !== '' && this.state.applicationHeader2 !== '') ? 3 :
                    0

        let allBackends = {}
        APP_PROPERTIES.ACTIVE_PAGES.backendAdministration && this.props.allBackends && this.props.allBackends.forEach(be => {
            if (be.type === 'webapi' || be.type === 'chemistry') {
                allBackends[be.id] = []
                this.props.availableRepositoriesDepartment && this.props.availableRepositoriesDepartment.forEach(rep => {
                    if (rep.backendId === be.id && !rep.active) {
                        allBackends[be.id] = [...allBackends[be.id], rep]
                    }
                })
                if (allBackends[be.id] && allBackends[be.id].length > 0) {
                    if (this.hiddenRepoStates[be.id] === undefined) {
                        this.hiddenRepoStates[be.id] = false
                    }
                }
            }
        })

        let firstChemBE

        if (this.props.allBackends && APP_PROPERTIES.ACTIVE_PAGES.backendAdministration) {
            firstChemBE = this.props.allBackends.find(el => el.type === 'chemistry')
        }

        let availableRepositoriesDepartment = this.props.allBackends && APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ? this.props.allBackends.map(be =>
            (be.type === 'webapi' || be.type === 'chemistry') &&
            <div key={`${be.name}`}>
                {be.type === 'webapi' ?
                    <div style={{ marginTop: 20, marginBottom: 10, borderTop: be.type === 'chemistry' ? '1px solid #d6d6d6' : 'none', paddingTop: be.type === 'chemistry' ? 25 : 0 }}>
                        <RadioButton
                            inputId={be.name}
                            name="name"
                            value={be}
                            onChange={(e) => this.onDefaultBackendChange(e, be.type)}
                            checked={this.state.defaultBackend === be.id}
                            disabled={!hasUserRole("ROLE_SUPPORT_ADMIN") ? false : true}
                            style={{ marginLeft: 1, marginRight: 11 }}
                        />
                        <label htmlFor={be.id} title={`Backend label: ${be.label} | Backend name: ${be.name} | Backend ID: ${be.id}`}
                            style={{
                                fontWeight: 'bold'
                            }}>{`${be.label} (${be.name}, ${be.id}${be.id === this.state.defaultBackend ? ', default-data' : ''})`}</label>
                    </div>
                    :
                    <div style={{ marginTop: 20, marginBottom: 10, borderTop: be.type === 'chemistry' && be.id === firstChemBE.id ? '1px solid #d6d6d6' : 'none', paddingTop: be.type === 'chemistry' && be.id === firstChemBE.id ? 25 : 0 }}>
                        <RadioButton
                            inputId={be.name}
                            name="name"
                            value={be}
                            onChange={(e) => this.onDefaultBackendChange(e, be.type)}
                            checked={this.state.defaultChemBackend === be.id}
                            disabled={!hasUserRole("ROLE_SUPPORT_ADMIN") ? false : true}
                            style={{ marginLeft: 1, marginRight: 11 }}
                        />
                        <label htmlFor={be.id} title={`Backend label: ${be.label} | Backend name: ${be.name} | Backend ID: ${be.id}`}
                            style={{
                                fontWeight: 'bold'
                            }}>{`${be.label} (${be.name}, ${be.id}${be.id === this.state.defaultChemBackend ? ', default-chem' : ''})`}</label>
                    </div>
                }
                {this.props.availableRepositoriesDepartment && this.props.availableRepositoriesDepartment.map(rep =>
                    rep.backendId === be.id && ((this.hiddenRepoStates[be.id] === false && rep.active) || this.hiddenRepoStates[be.id] === true || this.hiddenRepoStates[be.id] === undefined) &&
                    <div className='col-12'
                        key={`${rep.id}`} style={{ paddingLeft: 0 }}>
                        <Checkbox inputId={`${rep.id}`}
                            onChange={(e) => this.onAvailableRepositoriesChange(e, rep.id)}
                            checked={this.state.selectedRepositories.includes(rep.id) ? true : false}
                            value={rep.id} style={{ 'cursor': 'pointer' }}
                        />
                        <label htmlFor={`${rep.id}`}
                            className={`p-checkbox-label ${!rep.active ? 'inactiveEntry' : ''}`}
                            title={`Repository label: ${rep.label} | Repository name: ${rep.name} | Repository ID: ${rep.id}`}>
                            {`${rep.label} (${rep.name}, ${rep.id})`} {!rep.active ? '(inactive)' : ''}</label>
                    </div>
                )
                }
                {
                    allBackends[be.id].length > 0 && this.hiddenRepoStates[be.id] === false ?
                        <div className='col-12'
                            key={`${be.id}`} style={{ paddingLeft: 0 }}>
                            <a onClick={() => this.hiddenRepoStates[be.id] = true}>Show inactive repositories ({allBackends[be.id].length})</a>
                        </div>
                        : allBackends[be.id].length > 0 && this.hiddenRepoStates[be.id] === true ?
                            <div className='col-12'
                                key={`${be.id}`} style={{ paddingLeft: 0 }}>
                                <a onClick={() => this.hiddenRepoStates[be.id] = false}>Hide inactive repositories ({allBackends[be.id].length})</a>
                            </div>
                            : null
                }
            </div>
        )
            :
            this.props.availableRepositoriesDepartment && this.props.availableRepositoriesDepartment.map(rep =>
                <div className='col-12'
                    key={`${rep.id}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${rep.id}`}
                        onChange={(e) => this.onAvailableRepositoriesChange(e, rep.id)}
                        checked={this.state.selectedRepositories.includes(rep.id) ? true : false}
                        value={rep.id} style={{ 'cursor': 'pointer' }}
                    />
                    <label htmlFor={`${rep.id}`}
                        className={`p-checkbox-label ${!rep.active ? 'inactiveEntry' : ''}`}
                        title={`Repository label: ${rep.label} | Repository name: ${rep.name} | Repository ID: ${rep.id}`}>
                        {`${rep.label} (${rep.name}, ${rep.id})`} {!rep.active ? '(inactive)' : ''}</label>
                </div>
            )

        let departmentFeatures = this.props.availableFeaturesCompany && this.props.availableFeaturesCompany.map(feat =>
            (feat.name === 'bulkexport' || feat.name === 'docview' || feat.name === 'support' || feat.name === 'feedback' || feat.name === 'readcubeview')
                && (!feat.isDefault)
                ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label htmlFor={`${feat.name}`} className='p-checkbox-label'>{`${feat.label}`} </label>
                </div>
                : null
        )

        let generalApps = this.props.availableFeaturesCompany && this.props.availableFeaturesCompany.map(feat =>
            (feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'reactionsearch' && feat.name !== 'support' && feat.name !== 'feedback') &&
                (feat.name === 'querybuilder' || feat.name === 'dashboard' || feat.name === 'changelog' || feat.name === 'docsearch'
                    || feat.name === 'manage') && (!feat.isDefault) ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label onMouseLeave={(e) => this.leaveButtonFeature(e)} onMouseEnter={(e) => this.enterButtonFeature(e, feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.departmentLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.departmentLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback'
                            && feat.name !== 'support' && feat.name !== this.state.departmentLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={(e) => this.setLandingPage(e, feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }
                    </label>
                </div>
                : null
        )

        let analyticApps = this.props.availableFeaturesCompany && this.props.availableFeaturesCompany.map(feat =>
            (feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'reactionsearch' && feat.name !== 'support' && feat.name !== 'feedback') &&
                (feat.name === 'sentenceanalysis' || feat.name === 'domainexplorer' || feat.name === 'pathsearch' || feat.name === 'semanticexport' || feat.name === 'coocs' || feat.name === 'biomarker' || feat.name === 'trendanalysis' || feat.name === 'bioactivity') && !feat.isDefault ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label onMouseLeave={(e) => this.leaveButtonFeature(e)} onMouseEnter={(e) => this.enterButtonFeature(e, feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.departmentLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.departmentLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback' && feat.name !== 'semanticexport'
                            && feat.name !== 'support' && feat.name !== this.state.departmentLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={(e) => this.setLandingPage(e, feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }
                    </label>
                </div>
                : null
        )

        let chemApps = this.props.availableFeaturesCompany && this.props.availableFeaturesCompany.map(feat =>
            (feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'support' && feat.name !== 'feedback') &&
                (feat.name === 'chemsearch' || feat.name === 'compoundsearch' || feat.name === 'sequencesearch' ||
                    feat.name === 'sequenceexport' || feat.name === 'reactionsearch') && !feat.isDefault ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label onMouseLeave={(e) => this.leaveButtonFeature(e)} onMouseEnter={(e) => this.enterButtonFeature(e, feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.departmentLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.departmentLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback' && feat.name !== 'sequenceexport'
                            && feat.name !== 'support' && feat.name !== this.state.departmentLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={(e) => this.setLandingPage(e, feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }
                    </label>
                </div>
                : null
        )

        let userApps = this.props.availableFeaturesCompany && this.props.availableFeaturesCompany.map(feat =>
            (feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'reactionsearch' && feat.name !== 'support' && feat.name !== 'feedback')
                && (feat.name === 'alerts' || feat.name === 'watchlists' || feat.name === 'blacklists' || feat.name === 'library'
                    || feat.name === 'readdocuments' || feat.name === 'apps') && !feat.isDefault
                ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label onMouseLeave={(e) => this.leaveButtonFeature(e)} onMouseEnter={(e) => this.enterButtonFeature(e, feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.departmentLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.departmentLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback'
                            && feat.name !== 'support' && feat.name !== this.state.departmentLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={(e) => this.setLandingPage(e, feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }
                    </label>
                </div>
                : null
        )

        let defaultApps = this.props.availableFeaturesCompany && this.props.availableFeaturesCompany.map(feat =>
            (feat.name !== 'tutorials' && feat.name !== 'history' && feat.name !== 'manual' && feat.name !== 'infopage' && feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'reactionsearch' && feat.name !== 'support' && feat.name !== 'feedback')
                && feat.isDefault
                ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'default' }}
                    />
                    <label onMouseLeave={(e) => this.leaveButtonFeature(e)} onMouseEnter={(e) => this.enterButtonFeature(e, feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.departmentLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.departmentLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback'
                            && feat.name !== 'support' && feat.name !== this.state.departmentLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={(e) => this.setLandingPage(e, feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }</label>
                </div>
                : null
        )

        let domainsHighlightDepartment = this.props.availableHighlightDomainsCompany && this.props.availableHighlightDomainsCompany.map(dom =>
            <div className='col-12'
                key={`${dom.name}`} style={{ paddingLeft: 0 }}>
                <Checkbox inputId={`${dom.name}`}
                    onChange={(e) => this.onHighlightDomainsChange(e, dom.name)}
                    checked={this.state.selectedHighlightDomains.includes(dom.name) ? true : false}
                    value={dom.name} style={{ 'cursor': 'pointer' }}
                />
                <label htmlFor={`${dom.name}`} className={`p-checkbox-label ${!dom.active ? 'inactiveEntry' : ''}`}>
                    {`${dom.label}`} {!dom.active ? '(inactive)' : ''}</label>
            </div>
        )

        let domainsSearchDepartment = this.props.availableSearchDomainsCompany && this.props.availableSearchDomainsCompany.map(dom =>
            <div className='col-12'
                key={`${dom.name}`} style={{ paddingLeft: 0 }}>
                <Checkbox inputId={`${dom.name}`}
                    onChange={(e) => this.onSearchDomainsChange(e, dom.name)}
                    checked={this.state.selectedSearchDomains.includes(dom.name) ? true : false}
                    value={dom.name} style={{ 'cursor': 'pointer' }}
                />
                <label htmlFor={`${dom.name}`} className={`p-checkbox-label ${!dom.active ? 'inactiveEntry' : ''}`}>
                    {`${dom.label}`} {!dom.active ? '(inactive)' : ''}</label>
            </div>
        )

        let difference = []
        if (this.state.selectedEmailDomains && this.state.selectedEmailDomains.length > 0) {
            difference = this.state.selectedEmailDomains.filter((id1) => !this.state.emailDomainOptions.some(({ value: id2 }) => id2 === id1));
        }

        difference.forEach(diff => {
            this.state.emailDomainOptions.push({ label: `${diff} (invalid)`, value: diff, disabled: true })
        })

        return (
            <React.Fragment>
                <Toast ref={this.growl} />
                <Dialog visible={this.props.displayEditDepartment} style={{ 'width': "80vw" }} header="Suborganization details" focusOnShow={false}
                    modal={true}
                    dismissableMask={false}
                    onHide={() => this.props.onCancelEditDepartment()} className='styledDialog'>
                    <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 10, paddingBottom: 15, paddingTop: 20, marginRight: 0 }}>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-5" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>Suborganization name</label>
                            <InputText disabled={this.props.selectedDepartmentName === 'default' ? true : false} style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="departmentName" onChange={(e) => { this.updateDepartmentName(e.target.value) }} value={this.state.departmentName} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15, paddingRight: 5 }}>
                            <label style={{ marginLeft: 0 }}>Status</label>
                            <SelectButton
                                className='adminSelect'
                                optionLabel='name'
                                value={this.state.statusValue}
                                options={this.statusOptions}
                                style={{ paddingTop: 5 }}
                                onChange={(e) => this.handleStatusChange(e)} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <div className="grid p-fluid col-12" style={{ paddingLeft: 0 }}>
                                <div className="col-6" style={{ paddingTop: 0 }}>
                                    <label>Demo starts on</label>
                                    <div className="grid p-fluid" style={{ marginTop: 5, marginLeft: 0 }}>
                                        <Calendar
                                            className='adminCalendar'
                                            style={{
                                                width: '70%',
                                                verticalAlign: 'bottom'
                                            }}
                                            value={this.state.startDemoTime}
                                            id='calendar'
                                            readOnlyInput={true}
                                            showIcon={false}
                                            dateFormat='yy-mm-dd'
                                            showButtonBar={true}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            yearRange={getYearRangeFuture(10)}
                                            appendTo={document.body}
                                            onChange={(e) => {
                                                this.handleStartDemoTimeChange(e.value)
                                            }}
                                            aria-label='Select start date for demo'
                                            title='Select start date for demo'
                                            disabled={this.state.statusValue !== 'demo' ? true : false} />
                                        <Checkbox inputId='demoStartsChecked'
                                            onChange={(e) => this.onCheckStartDemoDepartment(e)}
                                            checked={this.state.startDemoDepartmentChecked ? true : false}
                                            style={{
                                                'cursor': 'pointer',
                                                marginLeft: 5,
                                                marginTop: 5
                                            }}
                                            disabled={this.state.statusValue !== 'demo' ? true : false}>
                                        </Checkbox>
                                    </div>
                                </div>
                                <div className="col-6" style={{ paddingTop: 0 }}>
                                    <label>Demo expires on</label>
                                    <div className="grid p-fluid" style={{ marginTop: 5, marginLeft: 1 }}>
                                        <Calendar
                                            className='adminCalendar'
                                            style={{
                                                width: '70%',
                                                verticalAlign: 'bottom'
                                            }}
                                            value={this.state.endDemoTime}
                                            id='calendar'
                                            readOnlyInput={true}
                                            showIcon={false}
                                            dateFormat='yy-mm-dd'
                                            showButtonBar={true}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            yearRange={getYearRangeFuture(10)}
                                            appendTo={document.body}
                                            onChange={(e) => {
                                                this.handleEndDemoTimeChange(e.value)
                                            }}
                                            aria-label='Select end date for demo'
                                            title='Select end date for demo'
                                            disabled={this.state.statusValue !== 'demo' ? true : false} />
                                        <Checkbox inputId='demoStartsChecked'
                                            onChange={(e) => this.onCheckEndDemoDepartment(e)}
                                            checked={this.state.endDemoDepartmentChecked ? true : false}
                                            style={{
                                                'cursor': 'pointer',
                                                marginLeft: 5,
                                                marginTop: 5
                                            }}
                                            disabled={this.state.statusValue !== 'demo' ? true : false}>
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-5" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>Application name</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="applicationName" onChange={(e) => { this.updateDepartmentApplicationName(e.target.value) }} value={this.state.departmentApplicationName} />
                        </div>

                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>Restrict adding users to email domains</label>
                            {this.state.companyEmailDomains.length === 0 ?
                                <React.Fragment>
                                    <Button icon='pi pi-info-circle'
                                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none', paddingBottom: 0, paddingTop: 1, marginBottom: 4 }}
                                        title={"Enter email domains (e.g. xyz.com) without '@'.\nHit enter after each domain to check it in."}>
                                    </Button>
                                    <Chips className="adminChip" style={{ marginTop: 0 }} placeholder='Enter email domains (e.g. xyz.com)'
                                        tooltipOptions={{ position: 'bottom' }} allowDuplicate={false} id="emailDomains" onChange={(e) => { this.updateDepartmentEmailDomains(e.value) }} value={this.state.selectedEmailDomains} />
                                </React.Fragment>
                                :
                                <MultiSelect
                                    pt={{
                                        checkboxIcon: {
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                if (e.target.className.baseVal !== "") {
                                                    e.target.parentNode.click();
                                                } else {
                                                    e.target.parentNode.parentNode.click();
                                                }
                                            },
                                        },
                                        headerCheckbox: {
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                e.target.parentNode.click();
                                            },
                                        },
                                    }}
                                    maxSelectedLabels={3}
                                    className='adminMultiSelect'
                                    style={{ border: '1px solid rgb(214, 214, 214)', height: 31, marginTop: 5, borderRadius: 1 }}
                                    disabled={this.state.startSelectedEMailDomains.length === 0
                                        ? true : false}
                                    placeholder="Select email domains"
                                    display="chip"
                                    optionDisabled="disabled"
                                    value={this.state.selectedEmailDomains}
                                    options={this.state.emailDomainOptions}
                                    onChange={(e) => this.updateDepartmentEmailDomains(e.value)} />
                            }
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" style={{ padding: '.5em', paddingBottom: 15 }}>
                            {this.state.maxDownloadsCompany !== null ?
                                <React.Fragment>
                                    <label>{`Export limit (max. ${addThousandsSeparatorToNumber(this.state.maxDownloadsCompany, ".")})`}</label>
                                    <a className="infoIconLink valignMiddle"
                                        style={{ marginTop: -2 }}
                                        title="Click for information about the export limit"
                                        onClick={e => this.recallInfoOverlayMaximum.toggle(e)}>
                                        <img src={infoIcon} alt="Export limit information"
                                            style={{ marginLeft: 10 }} />
                                    </a>
                                    <OverlayPanel
                                        ref={(el) => this.recallInfoOverlayMaximum = el}
                                        dismissable={true}>
                                        <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                                            <p>
                                                An export limit was already set for the organization. A suborganization can not have a higher export limit.
                                                Export limit is currently only used for exports from the Chemistry Search.
                                            </p>
                                        </div>
                                    </OverlayPanel>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <label>Export limit</label>
                                    <Button icon='pi pi-info-circle'
                                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none', paddingBottom: 0, paddingTop: 0, marginBottom: -1, marginTop: -2 }}
                                        title={"Export limit is currently only used for exports from the Chemistry Search."}>
                                    </Button>
                                </React.Fragment>
                            }
                            <InputNumber placeholder='e.g. 10.000' style={{ marginTop: 5 }} id="maxDownloads" onValueChange={(e) => { this.updateMaxDownloads(e.value) }} value={this.state.maxDownloads}
                                max={this.state.maxDownloadsCompany !== null ? Number(this.state.maxDownloadsCompany) : null} />
                        </div>

                        <div className="col-12" style={{ padding: '.5em' }}>
                            <Checkbox inputId='customHearder'
                                onChange={(e) => this.onCheckCustomHeader(e)}
                                checked={this.state.customHeaderChecked ? true : false}
                                value='' style={{ 'cursor': 'pointer' }}
                            />
                            <label style={{ fontWeight: 'bolder' }} className='p-checkbox-label'>Use custom suborganization application header - replaces organization settings</label>
                        </div>
                        {this.state.customHeaderChecked &&
                            <React.Fragment>
                                <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingLeft: 8, paddingBottom: 15 }}>
                                    <LogoUpload
                                        onImageUpload={this.handleImageUpload}
                                    />
                                </div>
                                <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-7" style={{ padding: '.5em', paddingBottom: 15 }}>
                                    <label >Custom text in application header</label>
                                    <div>
                                        <InputText placeholder='Line 1' style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6', textAlign: 'left' }} id="appHeader1" onChange={(e) => { this.updateApplicationHeader1(e.target.value) }} value={this.state.applicationHeader1} />
                                    </div>
                                    <div style={{ paddingTop: 5 }}>
                                        <InputText placeholder='Line 2' style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6', textAlign: 'left' }} id="appHeader2" onChange={(e) => { this.updateApplicationHeader2(e.target.value) }} value={this.state.applicationHeader2} />
                                    </div>
                                    <div style={{ paddingTop: 5 }}>
                                        <InputText placeholder='Line 3' style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6', textAlign: 'left' }} id="appHeader3" onChange={(e) => { this.updateApplicationHeader3(e.target.value) }} value={this.state.applicationHeader3} />
                                    </div>
                                </div>
                                <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" style={{ padding: '.5em', paddingBottom: 15 }}>
                                    <label>Background color</label>
                                    <div>
                                        <InputText placeholder='e.g. #0d4667' style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6', textAlign: 'left' }} id="backColor" onChange={(e) => { this.updateBackgroundColor(e.target.value) }} value={this.state.backgroundColor} />
                                    </div>
                                    <div style={{ marginTop: 28 }}>
                                        <label>Text color</label>
                                    </div>
                                    <div style={{ marginTop: 3 }}>
                                        <InputText placeholder='e.g. #FFFFFF' style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6', textAlign: 'left' }} id="backColor" onChange={(e) => { this.updateFontColor(e.target.value) }} value={this.state.fontColor} />
                                    </div>
                                </div>
                                <div className="col-12" style={{ paddingTop: 0 }}>
                                    <label>Preview</label>
                                    <div style={{ marginTop: 5, height: 60, backgroundColor: this.state.backgroundColor !== '' ? this.state.backgroundColor : '#0d4667' }}>
                                        <img
                                            height='40px'
                                            alt='Preview of header logo'
                                            src={this.state.headerImage}
                                            style={{ marginTop: 10, marginLeft: 10 }} />
                                        <div className='col-6' style={{ display: 'inline-block', paddingBottom: numberApplicationsHeaders === 1 ? 7 : 2, verticalAlign: 'bottom', paddingLeft: 15 }}>
                                            {this.state.applicationHeader1 !== '' &&
                                                <div>
                                                    <label style={{ color: this.state.fontColor !== '' ? this.state.fontColor : '#FFFFFF', fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 11.5 }}>{this.state.applicationHeader1}</label>
                                                </div>
                                            }
                                            {this.state.applicationHeader2 !== '' &&
                                                <div>
                                                    <label style={{ color: this.state.fontColor !== '' ? this.state.fontColor : '#FFFFFF', fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 11.5 }}>{this.state.applicationHeader2}</label>
                                                </div>
                                            }
                                            {this.state.applicationHeader3 !== '' &&
                                                <div>
                                                    <label style={{ color: this.state.fontColor !== '' ? this.state.fontColor : '#FFFFFF', fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 11.5 }}>{this.state.applicationHeader3}</label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="grid p-fluid" style={{ borderBottom: '1px solid #d6d6d6', paddingLeft: 20, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0 }}>
                        <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3'>
                            <div style={{ marginBottom: 5 }}>
                                <label style={{ fontWeight: 'bold' }}>Repositories</label>
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <label>Text, database or chemistry sources available</label>
                            </div>
                            <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 5 }}>
                                <Checkbox inputId='allRepositoriesChecked'
                                    onChange={(e) => this.onCheckAllRepos(e)}
                                    disabled={this.props.availableRepositoriesDepartment.length === 0 ? true : false}
                                    checked={(this.props.availableRepositoriesDepartment.length === this.state.selectedRepositories.length) && this.props.availableRepositoriesDepartment.length !== 0 ? true : false}
                                    value='All' style={{ 'cursor': 'pointer' }}
                                />
                                <label className='p-checkbox-label'>All</label>
                            </div>
                            <div style={{ marginTop: 15 }}>
                                {availableRepositoriesDepartment}
                            </div>
                        </div>
                        <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3'>
                            <div style={{ marginBottom: 5 }}>
                                <label style={{ fontWeight: 'bold' }}>Features</label>
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <label>GUI features and apps available</label>
                            </div>
                            <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 5 }}>
                                <Checkbox inputId='allRepositoriesChecked'
                                    onChange={(e) => this.onCheckAllFeatures(e)}
                                    checked={(this.state.selectedFeatures.length === this.props.availableFeaturesCompany.length) || (this.state.defaultFeaturesStart.length === this.props.availableFeaturesCompany.length) ? true : false}
                                    value='All' style={{ 'cursor': 'pointer' }}
                                />
                                <label className='p-checkbox-label'>All</label>
                            </div>
                            <div>
                                <div style={{ marginTop: 25, marginBottom: 10 }}>
                                    <label style={{
                                        fontWeight: 'bold'
                                    }}>GENERAL</label>
                                </div>
                                {generalApps}
                            </div>
                            {chemApps.some(function (app) { return app !== null }) &&
                                <div>
                                    <div style={{ marginTop: 20, marginBottom: 10 }}>
                                        <label style={{
                                            fontWeight: 'bold'
                                        }}>CHEMISTRY FINDER</label>
                                    </div>
                                    {chemApps}
                                </div>
                            }
                            <div>
                                <div style={{ marginTop: 20, marginBottom: 10 }}>
                                    <label style={{
                                        fontWeight: 'bold'
                                    }}>ANALYTICS</label>
                                </div>
                                {analyticApps}
                            </div>
                            <div>
                                <div style={{ marginTop: 20, marginBottom: 10 }}>
                                    <label style={{
                                        fontWeight: 'bold'
                                    }}>USER RESEARCH</label>
                                </div>
                                {userApps}
                            </div>
                            {departmentFeatures.some(function (feat) { return feat !== null }) &&
                                <div>
                                    <div style={{ marginTop: 20, marginBottom: 10 }}>
                                        <label style={{
                                            fontWeight: 'bold'
                                        }}>ADDITIONAL FEATURES</label>
                                    </div>
                                    {departmentFeatures}
                                </div>
                            }
                            <div>
                                <div style={{ marginTop: 25, marginBottom: 5 }}>
                                    <label style={{
                                        fontWeight: 'bold'
                                    }}>DEFAULT FEATURES</label>
                                    <a className="infoIconLink valignMiddle"
                                        style={{ marginTop: -1 }}
                                        title="Click for information about default features"
                                        onClick={e => this.recallInfoOverlay.toggle(e)}>
                                        <img src={infoIcon} alt="Default features information"
                                            style={{ marginLeft: 10 }} />
                                    </a>
                                    <OverlayPanel
                                        ref={(el) => this.recallInfoOverlay = el}
                                        dismissable={true}>
                                        <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                                            <p>
                                                Default features are automatically available for every organization and suborganization.
                                            </p>
                                        </div>
                                    </OverlayPanel>
                                </div>
                                {defaultApps}
                            </div>
                        </div>
                        {APP_PROPERTIES.APP_ID !== 'sciwalker_studio' &&
                            <>
                                <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3'>
                                    <div style={{ marginBottom: 5 }}>
                                        <label style={{ fontWeight: 'bold' }}>Domains highlighting</label>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <label>Domains highlighted in annotated text</label>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 5 }}>
                                        <Checkbox inputId='allHighlightedDomainsChecked'
                                            onChange={(e) => this.onCheckAllHighlightedDomains(e)}
                                            checked={(this.props.availableHighlightDomainsCompany.length === this.state.selectedHighlightDomains.length) && this.props.availableHighlightDomainsCompany.length !== 0 ? true : false}
                                            disabled={this.props.availableHighlightDomainsCompany.length !== 0 ? false : true}
                                            value='All' style={{ 'cursor': 'pointer' }}
                                        />
                                        <label className='p-checkbox-label'>All</label>
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        {domainsHighlightDepartment}
                                    </div>
                                </div>
                                <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3'>
                                    <div style={{ marginBottom: 5 }}>
                                        <label style={{ fontWeight: 'bold' }}>Searchable domains</label>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <label>Domains available in autocomplete</label>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 5 }}>
                                        <Checkbox inputId='allSearchableDomainsChecked'
                                            onChange={(e) => this.onCheckAllSearchableDomains(e)}
                                            checked={(this.props.availableSearchDomainsCompany.length === this.state.selectedSearchDomains.length) && this.props.availableSearchDomainsCompany.length !== 0 ? true : false}
                                            disabled={this.props.availableSearchDomainsCompany.length !== 0 ? false : true}
                                            value='All' style={{ 'cursor': 'pointer' }}
                                        />
                                        <label className='p-checkbox-label'>All</label>
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        {domainsSearchDepartment}
                                    </div>
                                </div>
                            </>}
                    </div>
                    <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                        <Button className='p-button-sm' style={{ float: 'left', marginLeft: 14, height: '27.19px' }} label="Delete suborganization"
                            onClick={this.props.onDeleteDepartment} disabled={false}
                        />
                        <Button className='p-button-sm primaryButton' label="Save"
                            onClick={this.onEditDepartment}
                            style={{ float: 'right' }}
                        />
                        <Button className='p-button-secondary p-button-sm' label="Cancel"
                            onClick={this.props.onCancelEditDepartment}
                            style={{ float: 'right', marginRight: 5 }}
                        />
                    </div>
                    <Dialog visible={this.state.displayDefaultFeatures} style={{ 'width': "50vw" }} header="Default features" focusOnShow={false}
                        modal={true}
                        dismissableMask={false} blockScroll
                        onHide={() => this.closeDefaultFeatures()}>
                        {defaultApps}
                    </Dialog>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default EditDepartment