import React, { useEffect, useMemo, useState } from 'react';
import { BiomarkerSearch } from "./BiomarkerSearch/BiomarkerSearch";
import { BiomarkerList } from "./BiomarkerList/BiomarkerList";
import { sendExportToFileApiRequest } from "../../../../api";
import { APP_PROPERTIES } from "../../../../properties";
import { fieldListForExportBiomarkerInXLSX } from "./fieldListForExportInXLSX";
import { useDispatch, useSelector } from "react-redux";
import {
    searchBiomarkers, setBiomarkerIsFirstRender,
    setBiomarkerLeftAsideExpandedKeys,
    toggleBiomarkerIsFetching
} from "../../../../redux/actions/BiomarkerActions";
import { createRepoAndIdMap } from "../../../common/helpers/createRepoAndIdMap";
import { createQueryList } from "../../../common/helpers/createQueryList";
import { usePrevious } from "../../../common/customHooks/usePrevious";
import { initialLeftAsideState } from "../../../../redux/reducers/biomarker";
import { RightAsideDescription } from "../../../common/RightAsideDescription/RightAsideDescription";
import { fullText, shortText } from "../../../common/RightAsideDescription/description";
import { addSearchIndex } from "../../../common/helpers/addSearchIndex";
import {
    biomarkerFirstResultIndex,
    biomarkerIsFetching, biomarkerIsFirstRender,
    biomarkerQueryTerms, biomarkerResultCount,
    biomarkerRoleMapping,
    biomarkerSearchResults,
    biomarkerSelectedReposForSearch, biomarkerSortDirection, biomarkerSortField
} from "../../../../redux/selectors/selectors";
import { setQuickSearchSelectedTerms } from '../../../../redux/actions/QuickSearchActions';

export const BiomarkerRightAside = ({ showToast, setIsSaveDialogVisible, clearToast }) => {
    //@TODO fix lint warnings in hook dependencies
    const [needSearch, setNeedSearch] = useState({});

    const dispatch = useDispatch();
    const isFetching = useSelector(biomarkerIsFetching);
    const searchResults = useSelector(biomarkerSearchResults);
    const roleMapping = useSelector(biomarkerRoleMapping);
    const selectedReposForSearch = useSelector(biomarkerSelectedReposForSearch);
    const queryTerms = useSelector(biomarkerQueryTerms);
    const sortField = useSelector(biomarkerSortField);
    const sortDirection = useSelector(biomarkerSortDirection);
    const firstResultIndex = useSelector(biomarkerFirstResultIndex);
    const resultCount = useSelector(biomarkerResultCount);
    const isFirstRender = useSelector(biomarkerIsFirstRender);
    const prevQueryTerms = usePrevious(queryTerms);


    // repositoriesIdList is used for for calling search API method
    const repositoriesIdList = useMemo(() => selectedReposForSearch?.map(repo => repo.id), [selectedReposForSearch]);
    const repoNameAndIdMap = useMemo(() => createRepoAndIdMap(selectedReposForSearch), [selectedReposForSearch]);

    useEffect(() => {
        if (!isFirstRender) {
            handleSearch();
        }
    }, [resultCount, firstResultIndex, isFirstRender, needSearch, sortField, sortDirection]);

    useEffect(() => {
        // search should be run after mounting
        if (repositoriesIdList.length && isFirstRender) {

            handleSearch();

            dispatch(setBiomarkerIsFirstRender(false));
        }
    }, [repositoriesIdList.length, isFirstRender, dispatch]);

    useEffect(() => {
        // run default search after removing last item from search input
        if (prevQueryTerms?.length && !queryTerms.length && !isFetching && !isFirstRender) {
            handleSearch();
            dispatch(setBiomarkerLeftAsideExpandedKeys(initialLeftAsideState));
        }
    }, [queryTerms, isFetching, isFirstRender, dispatch, prevQueryTerms]);

    const isSearchResult = !!Object.keys(searchResults).length;
    const randomName = `biomarker_results_${Date.now().toString()}`;

    const handleSearch = async () => {
        // prevent sending http request when app is fetching data
        dispatch(setQuickSearchSelectedTerms({ newTerms: [] }))

        if (!isFetching && repositoriesIdList.length) {
            const fieldQueryList = createQueryList(queryTerms, roleMapping);
            if (queryTerms?.length > 0)
                dispatch(setQuickSearchSelectedTerms({ newTerms: [queryTerms?.filter((item) => item.domains)] }))

            const data = {
                repositories: repositoriesIdList,
                sortBy: sortField,
                sortDataType: "CONCEPT",
                sortType: sortDirection,
                fieldQueryList,
                startIndex: firstResultIndex,
                count: resultCount
            };

            dispatch(searchBiomarkers(data, repoNameAndIdMap));
        }
    };

    const exportTopResults = async (topX) => {
        try {
            // prevent sending http request when app is fetching data
            if (!isFetching && repositoriesIdList.length) {
                dispatch(toggleBiomarkerIsFetching(true));
                const fieldQueryList = createQueryList(queryTerms, roleMapping);
                const data = {
                    repositories: repositoriesIdList,
                    sortBy: sortField,
                    sortDataType: "CONCEPT",
                    sortType: sortDirection,
                    fieldQueryList,
                    fields: fieldListForExportBiomarkerInXLSX,
                    topX
                };


                await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/biomarker/relations/xlsx`, `${randomName}.xlsx`, data);
            }
        } catch (e) {
            showToast(`Something went wrong while loading data\n ${e} `, 'Error occurred', 'error', 10000);
        } finally {
            dispatch(toggleBiomarkerIsFetching(false));
        }
    };

    const exportSelectedResults = async (relationIds) => {
        try {
            // prevent sending http request when app is fetching data
            if (!isFetching && repositoriesIdList.length) {
                dispatch(toggleBiomarkerIsFetching(true));

                const data = {
                    relationIds,
                    fields: fieldListForExportBiomarkerInXLSX,
                };

                await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/biomarker/relations/v2/xlsx`, `${randomName}.xlsx`, data);
            }
        } catch (e) {
            showToast(`Something went wrong while loading data\n ${e} `, 'Error occurred', 'error', 10000);
        } finally {
            dispatch(toggleBiomarkerIsFetching(false));
        }
    };

    const resultsWithIndexes = addSearchIndex(searchResults.relationResults);

    return (
        <div style={{ paddingLeft: 17 }}>
            <h1 style={{ marginBottom: 5 }}>Biomarker</h1>

            <RightAsideDescription shortText={shortText} fullText={fullText} />

            <BiomarkerSearch repositoriesIdList={repositoriesIdList}
                setNeedSearch={setNeedSearch}
                setIsSaveDialogVisible={setIsSaveDialogVisible}
                showToast={showToast}
                clearToast={clearToast}
            />
            {
                isSearchResult &&
                <BiomarkerList value={resultsWithIndexes} hitCount={searchResults.totalHits}
                    isFetching={isFetching}
                    showToast={showToast}
                    exportTopResults={exportTopResults}
                    exportSelectedResults={exportSelectedResults}
                    setIsSaveDialogVisible={setIsSaveDialogVisible}
                />
            }
        </div>
    )
};
