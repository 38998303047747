import moment from "moment";

export const checkRangeIsOutOfLimit = (startDate, endDate, period) => {
    //limit - max number of points. this limit due to backend
    const limits = {
        yearly: 200,
        monthly: 240,
        weekly: 530
    }

    const start = moment(startDate);
    const end = moment(endDate);

    switch (period) {
        case 'yearly':
            const startYear = new Date(startDate).getFullYear();
            const endYear = new Date(endDate).getFullYear();
            const nyears = endYear - startYear + 1;

            return nyears > limits[period];
        case 'monthly':
            //Difference in number of months;
            const monthCount = Math.ceil(moment.duration(end.diff(start)).asMonths());
            return monthCount > limits[period];
        case 'weekly':
            //Difference in number of weeks;
            const weekCount = Math.ceil(moment.duration(end.diff(start)).asWeeks());
            return weekCount > limits[period];

        default:
            return
    }

}
