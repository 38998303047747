/* eslint-disable jsx-a11y/anchor-is-valid */
import "./SearchExamples.css";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useRef } from "react";
import SearchExampleSection from "./SearchExampleSection";
import { Button } from "primereact/button";

const SearchExamples = ({
    buttonLabel = "Examples",
    buttonStyle = {},
    panelStyle = {},
    infoText,
    examples = [],
    onExampleClick,
}) => {

    const op = useRef(null);

    const handleExampleClick = (example, e) => {
        op.current.hide(e);
        onExampleClick(example);
    }

    return <>
        <Button
            className="p-button-text primaryButtonAsLink"
            onClick={(e) => op.current.toggle(e)}
            title="Click to see examples"
            style={{ ...buttonStyle }}
            >
            {buttonLabel}
        </Button>
        {/*<a onClick={(e) => op.current.toggle(e)}
            style={buttonStyle}>
            {buttonLabel}
        </a>*/}
        <OverlayPanel
            className='search-examples-overlay'
            style={panelStyle}
            ref={(el) => { op.current = el }}>
            <div>
                <div className="search-examples-info-text">
                    {infoText || <span>
                        To get an impression of the different possibilities,
                        you might want to try one of these examples:
                    </span>}
                </div>
                {examples?.map(exampleSection => {
                    return <React.Fragment key={exampleSection.id}>
                        <SearchExampleSection
                            exampleSection={exampleSection}
                            onExampleClick={handleExampleClick}
                        />
                    </React.Fragment>
                })}
            </div>
        </OverlayPanel>
    </>
}


export default SearchExamples;