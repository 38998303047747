import React from 'react';
import {deduplicateTerms, findMatchIgnoreCase, isArrayEmpty, normalizeConceptTerm} from "../../webapi/util";

const SuggestionTemplate = ({item, inputText}) => {
    let term = item.term;
    let info = item.roles ? item.roles[0] : 'Filter Original Term';
    let prefName = '';
    let termFormatted = term;
    const match = findMatchIgnoreCase(term, inputText);
    const prefnamesUnique = item.prefnames ? deduplicateTerms(item.prefnames) : [];
    const cutOffOtherTerms = item.otherTerms ? [...item.otherTerms].splice(0, 2) : [];

    if (prefnamesUnique && prefnamesUnique.length === 1 && normalizeConceptTerm(item.prefnames[0], true) !== normalizeConceptTerm(term, true)) {
        prefName = ` (${item.prefnames[0]})`;
    }

    if (match) {
        termFormatted = termFormatted.replace(match, `<span style="font-weight: normal">${match}</span>`);
    }

    termFormatted += `<span style="font-weight: normal">${prefName}</span>`;

    return <div className='grid' style={{paddingRight: 0, color: item.roles ? '#000000' : '#777777'}}>
        <div className="col clampingContainer line-clamp line-clamp-1-line"
             style={{padding: '0 5px', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '40vw'}}
             title={item.term}>
                <span style={{fontWeight: 'normal'}}>
                    <span 
                          dangerouslySetInnerHTML={{__html: termFormatted}}/>
                    {!isArrayEmpty(item.otherTerms) ?
                        <span className="secondaryInfo clampingContainer line-clamp line-clamp-1-line"
                              style={{fontWeight: 'normal', display: 'block'}}
                              title={item.otherTerms.join(' · ')}
                        >{cutOffOtherTerms.join(' · ')}{item.otherTerms.length > 2 ? ' ...' : ''}
                        </span> : null
                    }
                </span>
        </div>

        <div className="col clampingContainer line-clamp line-clamp-1-line"
             style={{padding: '0 5px'}}>
            {info ?
                <div style={{opacity: 0.7}}
                     title={info}>
                    {info}</div> : null
            }
        </div>
        <div className="breakRow"/>
    </div>;
};

export default SuggestionTemplate;
