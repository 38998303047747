import PagingList from "../../../ontbrowser/PagingList";
import { isArrayEmpty } from "../../../util";


const ParentClasses = ({
  concepts = [],
  onConceptClick,
}) => {

  return !isArrayEmpty(concepts) ?
    <PagingList
      conceptList={concepts}
      hasPrev={false}
      hasNext={false}
      onConceptClick={onConceptClick}
      allowConceptClick={true}
      allowCopy={true}
      cropContent={true}
    />
    :
    <span>No parent classes</span>;
}

export default ParentClasses;