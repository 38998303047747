import React from "react";
import SeparatorPoint from "../../../../webapi/general/SeparatorPoint";
const SearchGroupCriteriaLabels = ({
  selectedOperatorLabel,
  searchCriteria,
  filterDefinitions,
  onEdit,
}) => (
  <div className="flex align-items-center">
    {selectedOperatorLabel && <span>{selectedOperatorLabel}-combined</span>}
    {searchCriteria &&
      Object.entries(searchCriteria).map(
        ([filterID, searchCriterion], searchCriteriaIndex) => {
          const filterLabel = filterDefinitions && filterDefinitions[filterID]?.label;
          const addSeparator = searchCriteriaIndex > 0 || selectedOperatorLabel;
          return (
            <div key={filterID}>
              {addSeparator && <SeparatorPoint />}
              <span>
                {filterLabel || filterID}: {searchCriterion.filterValueLabel}
              </span>
            </div>
          );
        }
      )}
    <div style={{ marginLeft: 20 }}>
      <a onClick={onEdit}>Edit criteria</a>
    </div>
  </div>
);

export default SearchGroupCriteriaLabels;
