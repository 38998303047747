import "./Infos.css";
import InfoOverlayWithIcon from "../../../webapi/general/InfoOverlayWithIcon";

const SearchModeInfo = () => {
  return (
    <InfoOverlayWithIcon
      infoID="searchModeInfo"
      title="Click for information about search modes"
      imageAltText="Information about search modes"
      overlayContent={
        <div className="info-text-container">
          <h1>Search mode</h1>
          <p>
            There are two different search modes for concepts:
            <ul>
              <li>
                <strong>Ontologically, with synonyms</strong>:
                <br />
                Includes synonyms and subclasses with all of their synonyms
                (default).
              </li>
              <li>
                <strong>Concept only, with synonyms</strong>:
                <br />
                Includes synonyms only, but no subclasses.
              </li>
            </ul>
            The chosen search mode is valid for all concept terms within this
            search group and also for all subgroups.
          </p>
          <hr />
          <h2>Example</h2>
          <p>
            Concept: Inflammation (Disease)
            <br />
            Search mode: Concept only, with synonyms
            <br />
            <br />
            This will find occurrences of "inflammation" including all of its
            synonyms, but no child concepts classified as "inflammation".
          </p>
        </div>
      }
    />
  );
};

export default SearchModeInfo;
