import {
    YEAR_STATISTICS_SET_CHART_DATA,
    YEAR_STATISTICS_SET_SEARCH_PARAMETERS,
    YEAR_STATISTICS_SET_REPOSITORIES,
    YEAR_STATISTICS_SET_CHART_TYPE,
    YEAR_STATISTICS_RESET,
    YEAR_STATISTICS_SET_CHART_TITLE,
    YEAR_STATISTICS_SET_DATA_DATA_IS_FETCHING,
    YEAR_STATISTICS_SET_SELECTED_SEARCHES,
    YEAR_STATISTICS_SET_MODAL_VISIBLE,
    YEAR_STATISTICS_SET_MODAL_MESSAGE,
    YEAR_STATISTICS_SET_VALUE_TYPE,
    YEAR_STATISTICS_SET_PERIOD,
    YEAR_STATISTICS_CLEAR_CHART,
    YEAR_STATISTICS_SHOW_MODAL,
    YEAR_STATISTICS_SET_START_DATE,
    YEAR_STATISTICS_SET_END_DATE,
    YEAR_STATISTICS_SET_UPDATE,
    YEAR_STATISTICS_SET_TIME_RANGE,
    YEAR_STATISTICS_SET_IS_OUT_OF_LIMIT
} from "../actions";

const today = new Date();
export const historyDepth = 20;
const history10 = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
export const selectionLimit = 4;
const chartColors = ["#5495d6", "#4bae93", "#c55ca2", "#ef944e"];

const createSelected = count => Array(count).fill(null).map((value, index) => ({
    searchName: null,
    repoName: null,
    color: chartColors[index]
}));

const initialState = {
    showModal: false,
    searchParameters: [],
    chartData: [],
    selectedSearches: createSelected(selectionLimit),
    repositories: {},
    chartType: 'linear',
    valueType: 'absolute',
    chartTitle: '',
    dataIsFetching: false,
    modalVisible: false,
    modalMessage: '',
    // errorMessage: null, // @todo implement error handling logic. the error can be displayed on the diagram similarly to the loader
    // errorMessageVisible: false,
    selectionLimit,
    period: 'yearly',
    startDate: history10,
    endDate: today,
    update: {},
    timeRange: '10y',
    isOutOfLimit: false,
};

const yearStatisticsReducer = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case YEAR_STATISTICS_SHOW_MODAL:
            return { ...state, showModal: payload }
        case YEAR_STATISTICS_SET_SEARCH_PARAMETERS:
            return { ...state, searchParameters: payload }
        case YEAR_STATISTICS_SET_CHART_DATA:
            return { ...state, chartData: payload }
        case YEAR_STATISTICS_SET_SELECTED_SEARCHES:
            return { ...state, selectedSearches: payload }
        case YEAR_STATISTICS_SET_REPOSITORIES:
            return { ...state, repositories: payload }
        case YEAR_STATISTICS_SET_CHART_TYPE:
            return { ...state, chartType: payload }
        case YEAR_STATISTICS_SET_VALUE_TYPE:
            return { ...state, valueType: payload }
        case YEAR_STATISTICS_SET_START_DATE:
            return { ...state, startDate: payload }
        case YEAR_STATISTICS_SET_END_DATE:
            return { ...state, endDate: payload }
        case YEAR_STATISTICS_RESET:
            return { ...initialState, selectedSearches: createSelected(selectionLimit) }
        case YEAR_STATISTICS_CLEAR_CHART:
            return {
                ...state,
                selectedSearches: createSelected(selectionLimit),
                searchParameters: [],
                chartData: [],
                repositories: {},
                chartTitle: '',
                period: 'yearly',
                startDate: history10,
                endDate: today,
                timeRange: '10y',
                chartType: 'linear',
                valueType: 'absolute',
                isOutOfLimit: false
            }
        case YEAR_STATISTICS_SET_CHART_TITLE:
            return { ...state, chartTitle: payload }
        case YEAR_STATISTICS_SET_PERIOD:
            return { ...state, period: payload }
        case YEAR_STATISTICS_SET_DATA_DATA_IS_FETCHING:
            return { ...state, dataIsFetching: payload }
        case YEAR_STATISTICS_SET_MODAL_VISIBLE:
            return { ...state, modalVisible: payload }
        case YEAR_STATISTICS_SET_MODAL_MESSAGE:
            return { ...state, modalMessage: payload }
        case YEAR_STATISTICS_SET_UPDATE:
            return { ...state, update: {} }
        case YEAR_STATISTICS_SET_TIME_RANGE:
            return { ...state, timeRange: payload }
        case YEAR_STATISTICS_SET_IS_OUT_OF_LIMIT:
            return { ...state, isOutOfLimit: payload }
        // case YEAR_STATISTICS_SET_ERROR_MESSAGE:
        //     return {...state, errorMessage: payload}
        // case YEAR_STATISTICS_SET_ERROR_MESSAGE_VISIBLE:
        //     return {...state, showErrorMessage: payload}
        default:
            return state
    }
}

export default yearStatisticsReducer
