import {
    APP_PROPERTIES, QUERY_CATEGORY_CHEM_SEARCH, QUERY_CATEGORY_COOC_SEARCH, QUERY_CATEGORY_DOC_SEARCH, QUERY_FORM_CORRELATIONS,
    QUERY_TYPE_CHEM_SEARCH, QUERY_TYPE_COOC_SEARCH, QUERY_TYPE_QUICK_SEARCH_2, QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH
} from '../../../properties';
import { createChemistrySearchObjectFromConcept } from '../chemistry/util';
import { createQuickSearchObjectFromConcepts, createQuickSearchObjectFromConceptsWithTermloc } from '../general/docsearch/searchUtil';
import { hasUserRole } from '../util';
import { addDataToLocalStorage } from './localStorage';


// deprecated, too complicated
// TODO: replace with shortcuts2.js???


/**
 * 
 * @param {*} ocid 
 * @param {*} domain 
 * @param {*} name 
 * @returns 
 */
export const createShortcutMenuItems = (inclQuickSearch, inclCoocSearch, inclChemSearch,
    ocid, domain, name, repositoryInfo = null, openInNewTab = true, synonymsOnly = false) => {

    if (!ocid) {
        return []; // null???
    }

    const items = [];

    if (inclQuickSearch) {
        const shortcutQuickSearch = {
            label: QUERY_CATEGORY_DOC_SEARCH.label,
            command: () => {
                const req = createQuickSearchRequestFromConceptData(ocid, domain, name, null, null, synonymsOnly);
                executeQuickSearchShortcut(req, repositoryInfo, openInNewTab);
            }
        }
        items.push(shortcutQuickSearch);
    }

    if (inclCoocSearch && hasUserRole("ROLE_COOCS")) {
        const shortcutCoocs = {
            label: QUERY_CATEGORY_COOC_SEARCH.label,
            command: () => {
                const req = createCoocSearchRequestFromConceptData(ocid, domain, name);
                executeCoocShortcut(req, openInNewTab);
            }
        }
        items.push(shortcutCoocs);
    }

    if (inclChemSearch && hasUserRole("ROLE_COMPOUNDSEARCH")) {
        const shortcutChem = {
            label: QUERY_CATEGORY_CHEM_SEARCH.label,
            command: () => {
                const req = createChemSearchRequestFromConceptData(ocid, domain, name);
                executeChemSearchShortcut(req, openInNewTab);
            }
        }
        items.push(shortcutChem);
    }

    if (openInNewTab) {
        items.push({
            separator: true
        });
        items.push({
            label: 'Searches will open in new tab',
            className: 'tiered-menu-info _secondaryInfo',
            disabled: true
        });
    }

    return items;
}

/**
 * 
 * @param {*} ocid 
 * @param {*} domain 
 * @param {*} name 
 * @returns 
 */
export const createQuickSearchRequestFromConceptData = (ocid, domain, name, termloc = null, termlocLabel = null, synonymsOnly = false) => {
    const request = {
        ocid: ocid,
        domain: domain,
        name: name,
        termloc: termloc,
        termlocLabel: termlocLabel,
        synonymsOnly: synonymsOnly
    };
    return request;
}

/**
 * 
 * @param {*} ocid 
 * @param {*} domain 
 * @param {*} name 
 * @returns 
 */
export const createCoocSearchRequestFromConceptData = (ocid, domain, name) => {
    const request = {
        ocid: ocid,
        domain: domain,
        name: name
    };
    return request;
}

export const createCoocQueryTermFromConceptDataV2 = (ocid, domain, name) => {
    const queryTerm = {
        term: name,
        label: name,
        domains: [domain],
        ocids: ocid ? [ocid] : null,
        prefnames: [name]
    };
    return queryTerm;
}

/**
 * 
 * @param {*} ocid 
 * @param {*} domain 
 * @param {*} name 
 * @param {*} searchTargetOption 
 * @param {*} searchType 
 * @returns 
 */
export const createChemSearchRequestFromConceptData = (ocid, domain, name, searchTargetOption = null, searchType = null) => {
    const request = {
        ocid: ocid,
        domain: domain,
        name: name,
        searchTargetOption: searchTargetOption,
        searchType: searchType
    };
    return request;
}

export const createChemSearchRequestFromSmiles = (smiles, searchTargetOption = null, searchType = null) => {
    const request = {
        smiles: smiles,
        searchTargetOption: searchTargetOption,
        searchType: searchType
    };
    return request;
}


/**
 * 
 * @param {*} ocid 
 * @param {*} domain 
 * @param {*} name 
 * @param {*} openInNewTab 
 */
export const executeQuickSearchShortcut = (request, repositoryInfo, openInNewTab = true) => {
    const req = { ocid: request?.ocid, domain: request.domain, preferredName: request.name }
    let concepts = request?.ocid ? { [request.ocid]: req } : { [request.domain]: req };
    addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, { data: { ...createQuickSearchObjectFromConcepts(concepts, null, true, request.synonymsOnly), repositoryInfo: repositoryInfo } });
    window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, openInNewTab ? '_blank' : undefined);
}

// TODO
export const executeQuickSearchWithTermlocShortcut = (request, repositoryInfo, openInNewTab = true) => {
    let concepts = request?.ocid ? { [request.ocid]: { ocid: request.ocid, domain: request.domain, preferredName: request.name } } : {}
    addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, {
        data: { ...createQuickSearchObjectFromConceptsWithTermloc(concepts, request.termloc, request.termlocLabel, null, true, request.synonymsOnly), repositoryInfo: repositoryInfo }
    });
    window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, openInNewTab ? '_blank' : undefined);
}

/**
 * 
 * @param {*} ocid 
 * @param {*} domain 
 * @param {*} name 
 * @param {*} openInNewTab 
 */
export const executeCoocShortcut = (request, openInNewTab = true) => {
    let queryObject = {
        category: QUERY_CATEGORY_COOC_SEARCH.id,
        type: QUERY_TYPE_COOC_SEARCH.id,
        form: QUERY_FORM_CORRELATIONS.id,
        formContent: {
            queryTermsLeft: [{
                term: request.name,
                label: request.name,
                domains: [request.domain],
                ocids: request?.ocid ? [request.ocid] : null,
                prefnames: [request.name]
            }]
        },
        edit: false
    }
    addDataToLocalStorage(QUERY_TYPE_COOC_SEARCH.id, queryObject)
    window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/cooccurrences`, openInNewTab ? '_blank' : undefined)
}

export const executeCoocShortcutV2 = (queryTermsLeft, queryTermsRight, openInNewTab = true) => {
    let queryObject = {
        category: QUERY_CATEGORY_COOC_SEARCH.id,
        type: QUERY_TYPE_COOC_SEARCH.id,
        form: QUERY_FORM_CORRELATIONS.id,
        formContent: {},
        edit: false
    }
    if (queryTermsLeft) {
        queryObject.formContent.queryTermsLeft = queryTermsLeft;
    }
    if (queryTermsRight) {
        queryObject.formContent.queryTermsRight = queryTermsRight;
    }

    addDataToLocalStorage(QUERY_TYPE_COOC_SEARCH.id, queryObject)
    window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/cooccurrences`, openInNewTab ? '_blank' : undefined)
}

export const executeChemSearchShortcut = (request, openInNewTab = true) => {
    addDataToLocalStorage(QUERY_TYPE_CHEM_SEARCH.id, {
        data: { ...createChemistrySearchObjectFromConcept(request) }
    });
    window.open(`${APP_PROPERTIES.FRONTEND_URL}chemfinder/chemsearch`, openInNewTab ? '_blank' : undefined);
}

/**
 * @param {*} query
 * @param {*} queryType
 * @param {*} documents
 * @param {*} repository
 * @param {*} openInNewTab 
 */

export const executeSentenceAnalyzerShortcut = (query, queryType, documents, repositoryInfo, queryTerms, docInfos, sortFields = [], sortCriteria = null, openInNewTab = true) => {
    addDataToLocalStorage(QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id, { data: { query, queryType, ...documents, repositoryInfo, queryTerms: queryTerms, docInfos: docInfos, sortFields, sortCriteria } });
    window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/sentenceanalysis`, openInNewTab ? '_blank' : '_self');

}
