// === user roles === //

export const USER_ROLE_COMPOUND_SEARCH = 'ROLE_COMPOUNDSEARCH';


// === function roles === //

export const FUNCTION_ROLES = [
    { name: 'trendanalysis', label: 'Trend Analysis', isPage: true, isFeature: true, url: '/analytics/trendanalysis' },
    { name: 'biomarker', label: 'Biomarker', isPage: true, isFeature: false, url: '/analytics/biomarker' },
    { name: 'blacklists', label: 'Blocklists', isPage: true, isFeature: false, url: '/my/blocklists' },
    { name: 'bioactivity', label: 'Bioactivity', isPage: true, isFeature: false, url: '/analytics/bioactivities' },
    { name: 'bulkexport', label: 'Bulk Export', isPage: false, isFeature: true, url: '' },
    { name: 'changelog', label: 'Changelog', isPage: true, isFeature: false, url: '/changelog' },
    { name: 'chemsearch', label: 'Chemistry Search', isPage: true, isFeature: false, url: '/chemfinder/chemsearch' },
    { name: 'compoundsearch', label: 'Compound Search', isPage: true, isFeature: false, url: '/chemfinder/chemsearch' },
    { name: 'dashboard', label: 'My Dashboard', isPage: true, isFeature: false, url: '/dashboard' },
    { name: 'alerts', label: 'Notifications', isPage: true, isFeature: true, url: '/my/alerts' },
    { name: 'docsearch', label: 'Document Search', isPage: true, isFeature: false, url: '/docfinder/quicksearch' },
    { name: 'docview', label: 'Document View', isPage: false, isFeature: true, url: '' },
    { name: 'domainexplorer', label: 'Domain Explorer', isPage: true, isFeature: false, url: '/analytics/domainexplorer' },
    { name: 'feedback', label: 'Feedback', isPage: false, isFeature: true, url: '' },
    { name: 'infopage', label: 'Info Page', isPage: true, isFeature: false, url: '/start' },
    { name: 'library', label: 'Library', isPage: true, isFeature: false, url: '/my/library' },
    { name: 'manual', label: 'Manual', isPage: true, isFeature: false, url: '/manual' },
    { name: 'pathsearch', label: 'Fact Finder', isPage: true, isFeature: false, url: '/analytics/factfinder' },
    { name: 'history', label: 'Query History', isPage: true, isFeature: false, url: '/my/history' },
    { name: 'reactionsearch', label: 'Reaction Search', isPage: false, isFeature: true, url: '' },
    { name: 'readdocuments', label: 'Bookmarked Documents', isPage: true, isFeature: true, url: '/my/library' },
    { name: 'sentenceanalysis', label: 'Sentence Analysis', isPage: true, isFeature: true, url: '/analytics/sentenceanalysis' },
    { name: 'sequencesearch', label: 'Sequence Search', isPage: true, isFeature: false, url: '/chemfinder/sequencesearch' },
    { name: 'sequenceexport', label: 'Sequence Exporter', isPage: true, isFeature: false, url: '/chemfinder/sequenceexporter' },
    { name: 'support', label: 'Support', isPage: false, isFeature: true, url: '' },
    { name: 'tutorials', label: 'Tutorials', isPage: true, isFeature: true, url: '' },
    { name: 'manage', label: 'Administration', isPage: true, isFeature: false, url: '/administration' },
    { name: 'watchlists', label: 'Saved Searches', isPage: true, isFeature: true, url: '/my/savedsearches' },
    { name: 'coocs', label: 'Co-occurrence Analysis', isPage: true, isFeature: true, url: '/analytics/cooccurrences' },
    { name: 'semanticexport', label: 'Semantic Exporter', isPage: true, isFeature: false, url: '/analytics/semanticexporter' },
    { name: 'apps', label: 'My Apps', isPage: true, isFeature: true, url: '/my/apps' },
    { name: 'readcubeview', label: 'ReadCube View', isPage: false, isFeature: true, url: '' },
    { name: 'querybuilder', label: 'Query Builder', isPage: true, isFeature: false, url: '/docfinder/querybuilder' }
];