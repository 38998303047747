import { QUERY_CATEGORY_COOC_SEARCH, QUERY_FORM_CORRELATIONS, QUERY_TYPE_COOC_SEARCH } from "../../../properties";

export const CORRELATIONS_EXAMPLES = [
    {
        // id is mandatory, it will be used as key,
        //  since there can be multiple sections
        id: "coocs",
        //label: 'Search coocs',
        //description: 'blablubb',
        examples: [{
            label: 'Vitamin A acetate with companies',
            description: 'Find co-occurrences between "vitamin A acetate" and "companies".',
            queryObject: {
                category: QUERY_CATEGORY_COOC_SEARCH.id,
                type: QUERY_TYPE_COOC_SEARCH.id,
                form: QUERY_FORM_CORRELATIONS.id,
                formContent: {
                    queryTermsLeft: [{
                        "term": "vitamin A acetate",
                        "label": "vitamin A acetate",
                        "domains": ["chem", "drugs", "nutrition", "substances"],
                        "ocids": [190000020184, 229960000342, 235000019173, 239000011770],
                        "prefnames": ["vitamin A acetate"]
                    }],
                    queryTermsRight: [{
                        "term": "companies",
                        "label": "companies",
                        "domains": ["companies"],
                        "ocids": [281000001403],
                        "prefnames": ["companies"]
                    }]
                },
                edit: false,
                runSearch: true,
            },
        }, {
            label: 'Helicobacter pylori with inflammation',
            description: 'Find co-occurrences between "Helicobacter pylori" and "inflammation".',
            queryObject: {
                category: QUERY_CATEGORY_COOC_SEARCH.id,
                type: QUERY_TYPE_COOC_SEARCH.id,
                form: QUERY_FORM_CORRELATIONS.id,
                formContent: {
                    queryTermsLeft: [{
                        "term": "Helicobacter pylori",
                        "label": "Helicobacter pylori",
                        "domains": ["species", "drugs"],
                        "ocids": [241000590002, 229940037467],
                        "prefnames": ["Helicobacter pylori"]
                    }],
                    queryTermsRight: [{
                        "term": "inflammation",
                        "label": "inflammation",
                        "domains": ["diseases", "effects"],
                        "ocids": [206010061218, 230000004054],
                        "prefnames": ["inflammation"]
                    }]
                },
                edit: false,
                runSearch: true,
            },
        }, {
            label: 'Aspirin with pain',
            description: 'Find co-occurrences between "Aspirin" and "pain".',
            queryObject: {
                category: QUERY_CATEGORY_COOC_SEARCH.id,
                type: QUERY_TYPE_COOC_SEARCH.id,
                form: QUERY_FORM_CORRELATIONS.id,
                formContent: {
                    queryTermsLeft: [{
                        "term": "Aspirin",
                        "label": "Aspirin",
                        "domains": ["chem", "drugs"],
                        "ocids": [190000021540, 229960001138],
                        "prefnames": ["Aspirin"]
                    }],
                    queryTermsRight: [{
                        "term": "Pain",
                        "label": "Pain",
                        "domains": ["diseases", "effects"],
                        "ocids": [208000002193, 230000036407],
                        "prefnames": ["Pain"]
                    }]
                },
                edit: false,
                runSearch: true,
            },
        }]
    }
]