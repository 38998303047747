import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class DeleteUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteUserChecked: false
        }
    }

    onCheckDeleteUser = (e) => {
        if (e.checked) {
            this.setState({
                deleteUserChecked: true
            })
        } else {
            this.setState({
                deleteUserChecked: false
            })
        }
    }

    onDeleteSingleUser = () => {
        this.props.onDeleteSingleUser(this.props.selectedUserID)
    }

    render() {

        const footerDeleteUser = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete"
                    className='p-button-sm p-button-danger'
                    disabled={this.state.deleteUserChecked ? false : true}
                    onClick={this.onDeleteSingleUser}
                />
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelDeleteUser}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayDeleteUser} style={{ 'width': "40vw" }} header="Delete user" focusOnShow={false}
                modal={true} footer={footerDeleteUser} dismissableMask={false}
                onHide={() => this.props.onCancelDeleteUser()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div style={{ marginBottom: 10 }}>
                        <label>{`This will delete user '${this.props.selectedUserName}, ${this.props.selectedUserForename}'.`}</label>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <Checkbox inputId='deleteUserChecked'
                            onChange={(e) => this.onCheckDeleteUser(e)}
                            checked={this.state.deleteUserChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                        />
                        <label htmlFor='deleteUserChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default DeleteUser