import { APP_PROPERTIES, QUERY_TYPE_CHEM_SEARCH } from "../../../../properties";
import { SORT_BY_FIELD_DEFAULT, SORT_DIRECTION_DEFAULT } from "./chemistryResults";

// duplicate!!!
export const STRUCTURE_FORMAT_MOL = 'mol';
export const STRUCTURE_FORMAT_RXN = 'rxn';
export const STRUCTURE_FORMAT_MRV = 'mrv';
export const STRUCTURE_FORMAT_SMILES = 'smiles';
// remove one set
export const CHEMISTRY_FORMAT_MOL = 'mol';
export const CHEMISTRY_FORMAT_RXN = 'rxn';
export const CHEMISTRY_FORMAT_MRV = 'mrv';
export const CHEMISTRY_FORMAT_SMILES = 'smiles';

export const ALLOWED_SAVED_CHEM_SEARCH_TYPES = [QUERY_TYPE_CHEM_SEARCH.id];
// --- filter IDs used in frontend --- //
export const CHEMISTRY_SEARCH_FILTER_ID_SEARCH_TYPE = 'searchType';
export const CHEMISTRY_SEARCH_FILTER_ID_H_DONORS = 'hDonors';
export const CHEMISTRY_SEARCH_FILTER_ID_H_ACCEPTORS = 'hAcceptors';
export const CHEMISTRY_SEARCH_FILTER_ID_MOL_WEIGHT = 'molWeight';
export const CHEMISTRY_SEARCH_FILTER_ID_ABS_MOL_WEIGHT = 'absMolWeight';
export const CHEMISTRY_SEARCH_FILTER_ID_REL_MOL_WEIGHT = 'relMolWeight';
export const CHEMISTRY_SEARCH_FILTER_ID_DRUGABILITY = 'drugability';
export const CHEMISTRY_SEARCH_FILTER_ID_MIN_YIELD = 'minYield';
export const CHEMISTRY_SEARCH_FILTER_ID_MIN_SIMILARITY = 'minSimilarity';
export const CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT = 'bulkImport';
export const CHEMISTRY_SEARCH_FILTER_ID_INCL_MIXTURES = 'inclMixtures';
export const CHEMISTRY_SEARCH_FILTER_ID_SURFACE_AREA = 'totalSurfaceArea';
export const CHEMISTRY_SEARCH_FILTER_ID_ROTATABLE_BOND_COUNT = 'rotBonds';
export const CHEMISTRY_SEARCH_FILTER_ID_C_LOG_P = 'cLogP';
export const CHEMISTRY_SEARCH_FILTER_ID_SOLUBILITY = 'solubility';

export const CHEMISTRY_SEARCH_FILTER_TYPE_NUM_INPUT_FREE = 'numInputFree';
export const CHEMISTRY_SEARCH_FILTER_TYPE_NUM_INPUT_SLIDER = 'numInputSlider';
export const CHEMISTRY_SEARCH_FILTER_TYPE_NUM_RANGE = 'numRange';
export const CHEMISTRY_SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY = 'facetsExamples';

export const CHEMISTRY_QUERY_TERM_TYPE_CONCEPT = 'concept';
export const CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND = 'chemComp';
export const CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS = 'chemClass';
export const CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX = 'chemCompClass';
export const CHEMISTRY_QUERY_TERM_TYPE_OCID = 'ocid';
export const CHEMISTRY_QUERY_TERM_TYPE_INCHI = 'inchi';
export const CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY = 'inchikey'; // inchiKey
export const CHEMISTRY_QUERY_TERM_TYPE_SMILES = 'smiles';
export const CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS = 'bulkIDs';
export const CHEMISTRY_QUERY_TERM_TYPE_PROPERTY = 'chemProp';
export const CHEMISTRY_QUERY_TERM_TYPE_UNKNOWN = 'unknown';
// --- frontend identifiers --- //
export const CHEMISTRY_QUERY_TERM_TYPES = {
    [CHEMISTRY_QUERY_TERM_TYPE_UNKNOWN]: { value: CHEMISTRY_QUERY_TERM_TYPE_UNKNOWN, label: 'Unknown', allowSelection: false },
    [CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS]: { value: CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS, label: 'Bulk import', allowSelection: false },
    [CHEMISTRY_QUERY_TERM_TYPE_CONCEPT]: { value: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, label: '', allowSelection: false },
    [CHEMISTRY_QUERY_TERM_TYPE_INCHI]: { value: CHEMISTRY_QUERY_TERM_TYPE_INCHI, label: 'InChI', allowSelection: true },
    [CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY]: { value: CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY, label: 'InChI key', allowSelection: true },
    [CHEMISTRY_QUERY_TERM_TYPE_OCID]: { value: CHEMISTRY_QUERY_TERM_TYPE_OCID, label: 'OCID ', allowSelection: true },
    [CHEMISTRY_QUERY_TERM_TYPE_SMILES]: { value: CHEMISTRY_QUERY_TERM_TYPE_SMILES, label: 'SMILES', allowSelection: true },
    // subtypes
    [CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND]: { value: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND, label: 'Chemical compound', allowSelection: false },
    [CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS]: { value: CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS, label: 'Chemical class', allowSelection: false },
    [CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX]: { value: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX, label: 'compound and class', allowSelection: false },
};
// --- midlleware identifiers --- //
export const CHEMISTRY_IDENTIFIER_OCID = 'OCID';
export const CHEMISTRY_IDENTIFIER_SMILES = 'SMILES';
export const CHEMISTRY_IDENTIFIER_INCHI = 'InChI';
export const CHEMISTRY_IDENTIFIER_INCHI_KEY = 'InChIKey';
export const CHEMISTRY_IDENTIFIER_CAS = 'CAS';
export const CHEMISTRY_IDENTIFIER_UNKNOWN = 'unknown';

/*
export const QUERY_PREFIX_FACETFILTER = 'facetfilter';
export const QUERY_PREFIX_EXTRAFIELD = 'extrafield';
export const QUERY_PREFIX_EXTRAFIELD_EXACT = 'xi-dn_';
export const QUERY_PREFIX_EXTRAFIELD_TEXT = 'xt-dn_';

export const FREETEXT_WITH_VARIANTS = { prefix: 't', label: 'Text with variants' };
export const FREETEXT_EXACT = { prefix: 'tr', label: 'Text exact' };
*/
export const CHEMISTRY_BULK_IMPORT_FILE_FORMATS = ['.txt', '.csv', '.smi', '.smiles'];

export const CHEMISTRY_BULK_IMPORT_TYPE_SMILES = CHEMISTRY_QUERY_TERM_TYPE_SMILES;
export const CHEMISTRY_BULK_IMPORT_TYPE_INCHI = CHEMISTRY_QUERY_TERM_TYPE_INCHI;
export const CHEMISTRY_BULK_IMPORT_TYPE_INCHI_KEY = CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY;
export const CHEMISTRY_BULK_IMPORT_TYPE_OCID = CHEMISTRY_QUERY_TERM_TYPE_OCID;

export const CHEMISTRY_BULK_IMPORT_TYPES = {
    [CHEMISTRY_BULK_IMPORT_TYPE_SMILES]: {
        id: CHEMISTRY_BULK_IMPORT_TYPE_SMILES, label: 'SMILES', prio: 0,
    },
    [CHEMISTRY_BULK_IMPORT_TYPE_INCHI]: {
        id: CHEMISTRY_BULK_IMPORT_TYPE_INCHI, label: 'InChIs', prio: 3,
        isOfType: (value) => {
            return value?.toLowerCase().startsWith('inchi=');
        }
    },
    [CHEMISTRY_BULK_IMPORT_TYPE_INCHI_KEY]: {
        id: CHEMISTRY_BULK_IMPORT_TYPE_INCHI_KEY, label: 'InChI keys', prio: 2,
        isOfType: (value) => {
            const regex = /^[A-Z]{14}-[A-Z]{10}-[A-Z]$/g;
            return ((value.match(regex) || []).length > 0);
        }
    },
    [CHEMISTRY_BULK_IMPORT_TYPE_OCID]: {
        id: CHEMISTRY_BULK_IMPORT_TYPE_OCID, label: 'OCIDs', prio: 1,
        isOfType: (value) => {
            const regex = /^[1-9][0-9]{11}$/g;
            return ((value.match(regex) || []).length > 0);
        }
    },
};

export const MAX_NUM_OF_RESULTS = 500;

export const LOGICAL_OPERATORS = [
    { label: 'AND', value: 'and' },
    { label: 'OR', value: 'or' }
];

export const DEFAULT_DOC_ROW_COUNT = 20;

// --- are CA webservices active? reaction output format depends on 
export const HAS_CA_WEBSERVICES = !!APP_PROPERTIES.CHEMISTRY.INCLUDE_CHEMAXON_WEBSERVICES;
export const EDITOR_STRUCTURE_FORMAT = CHEMISTRY_FORMAT_RXN;
export const COMPOUNDS_INPUT_FORMAT = CHEMISTRY_FORMAT_MRV;
export const REACTIONS_INPUT_FORMAT = CHEMISTRY_FORMAT_MRV;
//export const REACTIONS_INPUT_FORMAT = HAS_CA_WEBSERVICES ? CHEMISTRY_FORMAT_RXN : CHEMISTRY_FORMAT_MOL;

export const INITIAL_SEARCH_RESULTS = {};
INITIAL_SEARCH_RESULTS[APP_PROPERTIES.CHEMISTRY.OC] = null;
INITIAL_SEARCH_RESULTS[APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI_PRODUCTS] = null;

INITIAL_SEARCH_RESULTS[APP_PROPERTIES.CHEMISTRY.XFACT_DB_RXN] = null;
//INITIAL_SEARCH_RESULTS[APP_PROPERTIES.CHEMISTRY.XFACT_DB] = null;
INITIAL_SEARCH_RESULTS[APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI] = null;

const DEFAULT_START = 0;
// max 50 allowed by chem service???
const DEFAULT_PAGE_SIZE = 12;
//const DEFAULT_PAGE_SIZE_REACTIONS = 10;
export const DEFAULT_ACTIVE_FILTER_INDICES = [0];

export const INITIAL_STATE = {
    queryTerm: null,
    queryTerms: [],
    structure: null,
    availableTargetOptions: [],
    selectedTargetOption: null,
    attributeQueryChanged: false,
    structureQueryChanged: false,
    hideStructureChangedNotification: false,
    isAttributeSearch: true,
    isSearchInitiated: false,
    showChemDBOverview: false,
    filterDefinitions: {},
    chemDBFilterMap: {},
    showFilters: true,
    chemDBStatistics: { ...INITIAL_SEARCH_RESULTS },
    chemDBResult: {},
    sortByField: SORT_BY_FIELD_DEFAULT,
    sortDirection: SORT_DIRECTION_DEFAULT,
    start: DEFAULT_START,
    pageSize: DEFAULT_PAGE_SIZE,
    selectedCompoundOcidsMap: {},
    activeFilterIndices: DEFAULT_ACTIVE_FILTER_INDICES,
    shortcutItems: [],
    maxLoadedPage: -1,
    maxLoadedEntry: -1,
    withMixtures: false
};

export const INITIAL_CHEM_DB_SEARCH_RESULT = {
    chemDBSearchResult: {},
    chemDBSearchResultEntries: [],
    start: 0,
    maxLoadedPage: -1,
    maxLoadedEntry: -1,
    showResults: false
}


//export const SEARCH_TYPE_DUPLICATE = 'duplicate';
//export const SEARCH_TYPE_FULLMATCH = 'full-match';
export const SEARCH_TYPE_FILTER_FULLMATCH = {
    filterID: "searchType",
    value: {
        filterValue: "full-match", filterValueLabel: "Full match",
        //filterValue: "duplicate", filterValueLabel: "Exact",
    }
}
export const SEARCH_TYPE_FILTER_DUPLICATE = {
    filterID: "searchType",
    value: { filterValue: "duplicate", filterValueLabel: "Duplicate" }
}

export const DOC_COUNT_REPOSITORIES_PATENTS = ['ifipatents', 'patents'];
export const DOC_COUNT_REPOSITORIES_PUBLICATIONS = ['dspub', 'pmc', 'med'];

/*
export const DEFAULT_ROW_COUNT = 20;
export const MAX_NUM_OF_FACETS = 500;

export const DEFAULT_SORT_CRITERIA = { sortField: 'relevance', sortMode: 'descending', sortFactor: null };
export const DEFAULT_QUERY_SETTINGS = {
    query: 'all:*',
    internalQuery: '+all:"*"',
    sortingOrderPriorities: ['pdate', 'startdate', 'insTime'],
    rankedQuery: '+all:* dis:* proteins:* drugs:*',
    rankedQuerySortFactor: 20,
    rankedQuerySortFields: { pdate: true, startdate: true, insTime: true },
    rankedQuerySortModes: { descending: true }
    //isDefaultQuery: function (q) { return q === DEFAULT_QUERY_SETTINGS.query },
    //useRankedQuery: function (q, s) { return q === DEFAULT_QUERY_SETTINGS.query && (s === 'pdate' || s === 'startdate' || s === 'insTime') }
};

export const INITIAL_SEARCH_FORM_SETTINGS = {
    queryChanged: false,
    queryTerms: [],
    logicalOperator: LOGICALS[0].value,
    repositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE,
    showRepositoryStatistics: false,
    showResults: false,
    selectedFilters: [],
    selectedSavedSearch: null,
    editQuery: false,
    editableQuery: false,
    editQueryID: null,
    queryFullName: '',
    queryName: '',
    queryDescription: '',
    queryString: '',
    selectedQueryCollectionIDs: []
};
export const INITIAL_RESULTS_SETTINGS = {
    first: 0,
    rows: DEFAULT_ROW_COUNT,
    facets: {},
    //openFilterCategories: {}
    //...DEFAULT_SORT_CRITERIA
};
*/