/* eslint-disable jsx-a11y/anchor-is-valid */
import { PureComponent } from "react";
import { DataScroller } from "primereact/datascroller";
import { checkResultAndGetPayload } from "../../../api";
import { Button } from "primereact/button";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { countAtomsInSmiles, isArrayEmpty } from "../util";
import { convertStructuresToImagesV2, createStructuresToImagesRequestV2 } from "../../../api/content/ChemistryApi";
import './CompoundsList.css'


//const COMPOUND_IMAGE_HEIGHT = 500
//const COMPOUND_IMAGE_HEIGHT_MEDIUM = 250
//const COMPOUND_IMAGE_HEIGHT_SMALL = 100;


class CompoundsList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            //nextCompoundNumberToShow: 0,
            numOfImagesShown: 0,
            compoundOcidData: [],
            compoundImages: {},
            numberOfAtoms: {},
            first: 0,
            rows: 25,
        };
        this.imgTemplate = this.imgTemplate.bind(this);
    }

    /*
    componentDidUpdate(prevProps) {
        const arraysEqual = (_.isEqual(prevProps.compoundOcidData || [], this.props.compoundOcidData || []));
        if (this.props.compoundOcidData && !arraysEqual) {
            //if (prevProps.compoundOcidData !== this.props.compoundOcidData) {
            console.log('UPDATE DATA');
            const numOfCompounds = this.props.compoundOcidData.length;

            this.setState({
                nextCompoundNumberToShow: 0,
                //numOfImagesShown: this.state.first + this.state.rows <= this.props.compoundOcidData.length ? this.state.first + this.state.rows : this.props.compoundOcidData.length,
                numOfImagesShown: this.state.rows <= numOfCompounds ? this.state.rows : numOfCompounds,
                fetchingImages: false,
                compoundOcidData: [],
                compoundImages: {},
                numberOfAtoms: {},
                //hasMoreImages: this.props.compoundOcidData && this.props.compoundOcidData.length > this.state.first + this.state.rows,
                hasMoreImages: this.props.compoundOcidData && numOfCompounds > this.state.rows,
                first: 0
            }, () => this.loadData());
        }
    }
    */

    /**
     * Renders compound structure image with preferred name in header.
     * Image size is determined by number of characters in smiles 
     * (should be replaced by number of atoms somehow).
     * 
     * @param {Object} comp compound object
     */
    imgTemplate(comp) {

        //console.log('comp.number: ', comp.number);
        //console.log('nextCompoundNumberToShow: ', this.state.nextCompoundNumberToShow);
        //const scrollTo = comp.number === this.state.nextCompoundNumberToShow;

        const { conceptsData, ocidToAnnIdsMap, annTypeChemStructure, onClick } = this.props;

        //const compoundSize = this.state.numberOfAtoms[comp.ocid] ? this.state.numberOfAtoms[comp.ocid] : 0; // comp.smiles.length
        //const height = compoundSize < 3 ? COMPOUND_IMAGE_HEIGHT_SMALL : compoundSize < 50 ? COMPOUND_IMAGE_HEIGHT_MEDIUM : COMPOUND_IMAGE_HEIGHT;

        return (
            <div className="task-box compounds-list">
                <div className={`task-box-header small compounds-list-header clampingContainer line-clamp line-clamp-1-line ann_${annTypeChemStructure}`}
                    title={`Show details for ${comp.preferredName}`}
                    style={{ lineHeight: 1.5 /*textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'*/ }}>
                    {comp.number}. <a className="secondaryLink" onClick={() => onClick(comp.ocid)}>{comp.preferredName}</a>
                </div>
                <div className="task-box-content">
                    <div className="grid">
                        <div className="col-8 textAlignLeft">
                            {conceptsData && conceptsData[comp.ocid] &&
                                conceptsData[comp.ocid].relevanceScore ?
                                <span title="The relevance score gives an estimation of how relevant a concept is in the context of one individual document. The higher the relevance score the more relevant this concept is for the topic of this document. The relevance score is calculated for chemical entities only.">
                                    {'Relevance: ' + conceptsData[comp.ocid].relevanceScore}
                                </span> : ''}
                        </div>
                        <div className="col-4 textAlignRight">
                            <span className='compounds-list-hitcount'>
                                {ocidToAnnIdsMap && ocidToAnnIdsMap[comp.ocid] ?
                                    ocidToAnnIdsMap[comp.ocid].length + 'x' : ''}
                            </span>
                            <Button
                                className="p-button-secondary p-button-sm buttonSmall jumpto-button jumpto-prev"
                                type="button"
                                label="<<"
                                title="Jump to previous occurrence"
                                onClick={() => this.props.jumpToMatch(comp.ocid, false, annTypeChemStructure)} >
                            </Button>
                            <Button
                                className="p-button-secondary p-button-sm buttonSmall jumpto-button jumpto-next"
                                type="button"
                                label=">>"
                                title="Jump to next occurrence"
                                onClick={() => this.props.jumpToMatch(comp.ocid, true, annTypeChemStructure)} >
                            </Button>
                        </div>
                    </div>
                    <div className='compounds-list-image'>
                        {this.state.compoundImages[comp.ocid] ?
                            <img onClick={() => onClick(comp.ocid)}
                                title={`Show details for ${comp.preferredName}`}
                                style={{ height: 250, width: '100%', cursor: 'pointer' }}
                                src={`data:image/svg+xml;base64,${this.state.compoundImages[comp.ocid]}`}
                                alt={`Chemical structure  ${comp.preferredName}`} />
                            :
                            <span>No image available</span>
                        }
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Loads compound images for next chunk of compounds in list.
     */
    loadData = async () => {

        this.setState({
            fetchingImages: true
        });

        // --- keep previous data so user can scroll through images --- //
        const compoundOcidData = [...this.state.compoundOcidData];
        const compoundImages = { ...this.state.compoundImages };
        const numberOfAtoms = { ...this.state.numberOfAtoms };

        if (this.props && this.props.compoundOcidData) {
            // --- collect smiles of compounds and map to OCID --- //
            const smiles = {};
            for (var i = this.state.first; i < this.props.compoundOcidData.length && i < this.state.first + this.state.rows; i++) {
                // --- add incremented number and smiles to compound --- //
                const compound = this.props.compoundOcidData[i];
                compound.number = i + 1;
                compoundOcidData.push(compound);
                if (!isArrayEmpty(compound.extendedData.smiles)) {
                    smiles[compound.extendedData.smiles[0]] = compound.ocid;
                    compound.smiles = compound.extendedData.smiles[0];
                }
            }

            if (Object.keys(smiles)) {
                // --- request images for unique smiles --- //
                const request = createStructuresToImagesRequestV2(Object.keys(smiles));
                const response = await convertStructuresToImagesV2(request);
                const result = checkResultAndGetPayload(response, this.props.growl);

                if (result && result.images) {
                    const images = result.images;
                    for (var smi of Object.keys(images)) {
                        compoundImages[smiles[smi]] = images[smi];
                        // --- hack: may not be exact number of atoms, but good enough --- //
                        const numOfAtoms = countAtomsInSmiles(smi);
                        numberOfAtoms[smiles[smi]] = numOfAtoms;
                    }
                }
            }
        }

        this.setState({
            nextCompoundNumberToShow: this.state.first,
            numOfImagesShown: this.state.first + this.state.rows <= this.props.compoundOcidData.length ? this.state.first + this.state.rows : this.props.compoundOcidData.length,
            fetchingImages: false,
            compoundOcidData: compoundOcidData,
            compoundImages: compoundImages,
            numberOfAtoms: numberOfAtoms,
            hasMoreImages: this.props.compoundOcidData && this.props.compoundOcidData.length > this.state.first + this.state.rows,
            first: this.state.first + this.state.rows
        }/*, () => scrollToComponent(this.compoundInView, {
        align: 'top',
        //offset: -140,
        duration: 500
    })*/);
    }



    render() {
        const { compoundOcidData, emptyMessage } = this.props;
        //console.log('comps props: ', this.props);

        const footer = this.state.hasMoreImages ? <div className='compounds-list-footer'>
            <a onClick={() => this.ds.load()}>Load more structures</a>
        </div> : null;

        return (
            <LoadingOverlay
                active={this.state.fetchingImages}
                spinner={true} >
                <div className="dashboard">
                    {!isArrayEmpty(compoundOcidData) ?
                        <DataScroller
                            ref={(el) => this.ds = el}
                            value={this.state.compoundOcidData}
                            itemTemplate={this.imgTemplate}
                            rows={this.state.rows}
                            loader
                            footer={footer}
                            header={`${this.state.numOfImagesShown} of ${compoundOcidData.length} stuctures loaded`}
                            lazy={true}
                            onLazyLoad={this.loadData}
                            scrollHeight="calc(100vh - 400px)"
                            inline={true}
                        />
                        :
                        <div>{emptyMessage}</div>
                    }
                </div>
            </LoadingOverlay>
        );
    }
}

export default CompoundsList;