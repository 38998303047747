import { Repository } from '../repository/Repository';

export class Service {
  static MethodNotImplemented = 'The method is not implemented';

  #repository;

  constructor(repository) {
    this.#repository = repository;
  }

  get repository() {
    return this.#repository;
  }

  setAuthToken(token) {
    this.#repository.setAuthToken(token);
  }

  static async fetchAuthToken(userName, userPassword) {
    const token = (await Repository.fetchAuthToken(userName, userPassword));
    return token;
  }
}
