import { useCallback, useEffect, useRef, useState } from 'react';
import { PageDivider } from "../../common/PageDivider/PageDivider";
import BioactivitiesRightAside from "./BioactivitiesRightAside/BioactivitiesRightAside";
import BioactivitiesLeftAside from "./BioactivitiesLeftAside/BioactivitiesLeftAside";
import { useDispatch, useSelector } from "react-redux";
import {
    resetBioactivitiesState, setBioactiviesTargetOcid, setBioactivitiesNodes,
    setBioactivitiesQueryTerms, setBioactivitiesRoleMapping,
    setBioactivitiesSelectedReposForSearch, setBioactivitiesShowConceptPopup, setReposWithBioactivitiesSearchFeature
} from "../../../redux/actions/BioactivitiesActions";
import BioactivitiesFiltersPopup from "./BioactivitiesRightAside/BioactivitiesFiltersPopup/BioactivitiesFiltersPopup";
import BioactivitiesConceptInfoPopup
    from "./BioactivitiesRightAside/BioactivitiesConceptInfoPopup/BioactivitiesConceptInfoPopup";
import {
    bioactivitiesIsFetching,
    bioactivitiesIsFirstRender,
    bioactivitiesShowConceptPopup, bioactivitiesTargetOcid
} from "../../../redux/selectors/selectors";
import { addKeysToRoles } from "../../common/helpers/addKeysToRoles";
import { fetchBioactivitiesRoleMapping } from "../../../api/content/BioactivitiesApi";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { Toast } from "primereact/toast";

const Bioactivities = () => {
    const allRepos = useSelector(({ user: { data: { userDetails: { department: { selectedRepositories } } } } }) => selectedRepositories);
    const isFirstRender = useSelector(bioactivitiesIsFirstRender);
    const isFetching = useSelector(bioactivitiesIsFetching);
    const showConceptPopup = useSelector(bioactivitiesShowConceptPopup);
    const targetOcid = useSelector(bioactivitiesTargetOcid);
    const [showFiltersPopup, setShowFiltersPopup] = useState(false);
    const [popupHeader, setPopupHeader] = useState('');

    const toast = useRef(null);
    const dispatch = useDispatch();
    const getRoleMapping = useCallback(async () => {
        try {
            const roleMapping = await fetchBioactivitiesRoleMapping();
            const nodes = addKeysToRoles(roleMapping);

            dispatch(setBioactivitiesRoleMapping(roleMapping));
            dispatch(setBioactivitiesNodes(nodes));
            // dispatch(setBiomarkerHeadersStructureSkeleton(nodesWithRepositories));
        } catch (e) {
            showToast(`Something went wrong while loading data\n ${e} `, 'Error occurred', 'error', 10000);
        }
    }, [dispatch]
    )

    useEffect(() => {
        if (isFirstRender) {
            getRoleMapping();
        }
    }, [isFirstRender, getRoleMapping])

    useEffect(() => {
        // set available repos and select all on default
        const availableRepos = allRepos.filter(repo =>
            repo.active && repo.features.includes("BIOACTIVITY_SEARCH"));

        if (availableRepos.length) {
            dispatch(setReposWithBioactivitiesSearchFeature(availableRepos));
            dispatch(setBioactivitiesSelectedReposForSearch(availableRepos));
        }
    }, [dispatch, allRepos]);

    // reset state after unmounting
    useEffect(() => () => dispatch(resetBioactivitiesState()), [dispatch]);

    const showToast = (detail, summary = 'Term added', severity = 'success', life = 1500, sticky = false) => {
        const msg = { detail, sticky, summary, severity, life };
        toast.current.show(msg);
    }

    const clearToast = () => toast.current.clear();

    const showCompoundInfo = (ocid) => {
        dispatch(setBioactivitiesShowConceptPopup(true));
        dispatch(setBioactiviesTargetOcid(ocid));
    };

    const hideCompoundInfo = () => {
        dispatch(setBioactivitiesShowConceptPopup(false));
        dispatch(setBioactiviesTargetOcid(null));
    }

    return (
        <LoadingOverlay
            active={isFetching}
            spinner={true} >
            <Toast ref={toast} />

            <div className='bioactivities-container'>
                <BioactivitiesFiltersPopup visible={showFiltersPopup} setShowPopup={setShowFiltersPopup}
                    popupHeader={popupHeader} />

                <BioactivitiesConceptInfoPopup visible={showConceptPopup} conceptOcid={targetOcid}
                    hideCompoundInfo={hideCompoundInfo} showCompoundInfo={showCompoundInfo} />

                <PageDivider
                    leftAside={<BioactivitiesLeftAside setQueryTerms={setBioactivitiesQueryTerms}
                        setShowPopup={setShowFiltersPopup}
                        setPopupHeader={setPopupHeader} showToast={showToast} />}
                    rightAside={<BioactivitiesRightAside showToast={showToast} clearToast={clearToast} />} />
            </div>
        </LoadingOverlay>
    );
};

export default Bioactivities;
