export const ADVANCED_SEARCH_3_TERMLOC_CATEGORIES = [
  { key: "clear", label: "Clear selection", selectable: true },
  { key: "general", label: "General", selectable: false },
  { key: "patents", label: "Patents", selectable: false },
  { key: "publications", label: "Publications", selectable: false },
  { key: "clintrials", label: "Clinical trials", selectable: false },
  { key: "druglabels", label: "Drug labels", selectable: false },
];

export const ADVANCED_SEARCH_3_TERMLOC_DATA = {
  doc: {
    filterValue: "doc",
    filterValueLabel: "Whole document (explicit)",
    categories: ["general"],
  },
  title: {
    filterValue: "title",
    filterValueLabel: "Title",
    categories: ["general"],
  },
  ta: {
    filterValue: "ta",
    filterValueLabel: "Title and abstract",
    categories: ["general"],
  },
  tac: {
    filterValue: "tac",
    filterValueLabel: "Title, abstract and claim",
    categories: ["patents"],
  },
  claims: {
    filterValue: "claims",
    filterValueLabel: "Claim",
    categories: ["patents"],
  },
  labeledcomp: {
    filterValue: "labeledcomp",
    filterValueLabel: "Labeled compounds",
    categories: ["patents"],
  },
  contraindications: {
    filterValue: "contraindications",
    filterValueLabel: "Contraindications",
    categories: ["druglabels"],
  },
  ddinteraction: {
    filterValue: "ddinteraction",
    filterValueLabel: "Drug-drug interaction",
    categories: ["druglabels"],
  },
  indications: {
    filterValue: "indications",
    filterValueLabel: "Indications",
    categories: ["druglabels"],
  },
  sideeffects: {
    filterValue: "sideeffects",
    filterValueLabel: "Side effects",
    categories: ["druglabels"],
  },
  background: {
    filterValue: "background",
    filterValueLabel: "Background",
    categories: ["publications"],
  },
  introduction: {
    filterValue: "introduction",
    filterValueLabel: "Introduction",
    categories: ["publications"],
  },
  methods: {
    filterValue: "methods",
    filterValueLabel: "Methods",
    categories: ["publications"],
  },
  experimental: {
    filterValue: "experimental",
    filterValueLabel: "Experimental section",
    categories: ["publications", "patents"],
  },
  results: {
    filterValue: "results",
    filterValueLabel: "Results",
    categories: ["publications"],
  },
  discussion: {
    filterValue: "discussion",
    filterValueLabel: "Discussion",
    categories: ["publications"],
  },
  conclusion: {
    filterValue: "conclusion",
    filterValueLabel: "Conclusion",
    categories: ["publications"],
  },
  perspectives: {
    filterValue: "perspectives",
    filterValueLabel: "Perspectives",
    categories: ["publications"],
  },
  supplement: {
    filterValue: "supplement",
    filterValueLabel: "Supplement",
    categories: ["publications"],
  },
  basicdata: {
    filterValue: "basicdata",
    filterValueLabel: "Basic data",
    categories: ["clintrials"],
  },
  design: {
    filterValue: "design",
    filterValueLabel: "Design",
    categories: ["clintrials"],
  },
  description: {
    filterValue: "description",
    filterValueLabel: "Description",
    categories: ["clintrials"],
  },
  eligibility: {
    filterValue: "eligibility",
    filterValueLabel: "Eligibility",
    categories: ["clintrials"],
  },
  endpoints: {
    filterValue: "endpoints",
    filterValueLabel: "Endpoints",
    categories: ["clintrials"],
  },
  references: {
    filterValue: "references",
    filterValueLabel: "References",
    categories: ["publications", "clintrials"],
  },
};

export const ADVANCED_SEARCH_3_TERMLOCS =
  ADVANCED_SEARCH_3_TERMLOC_CATEGORIES.map((cat) => {
    return {
      key: cat.key,
      type: "category",
      label: cat.label,
      selectable: cat.selectable,
      children:
        Object.values(ADVANCED_SEARCH_3_TERMLOC_DATA)
          .filter((data) => data.categories?.includes(cat.key))
          .map((data) => {
            return {
              key: data.filterValue,
              label: data.filterValueLabel,
              filterField: `${data.filterValue} --- ${data.filterValueLabel}`,
            };
          }) || [],
    };
  });
