import React, { Component } from 'react';
import classNames from 'classnames';
import * as Actions from './redux/actions'
import { APP_PROPERTIES } from './properties';
import { createMenuForRole } from './menu.js';
import { AppTopbar } from './components/layout/AppTopbar';
//import { AppFooter } from './components/layout/AppFooter';
import { AppMenu } from './components/layout/AppMenu';
import { AppBreadcrumb } from "./components/layout/AppBreadcrumb";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchFilterDefinitions, fetchRepositoryFilterMap } from './redux/common/filters/thunks/filters';
import Routes from './components/app/Routes';
import './ripple.js';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './styles/App.css';
import './styles/App.scss';
import NotificationTopbar from './components/common/NotificationTopbar/NotificationTopbar';

// --- interval for token refresh check --- //
/*
   10000; // 10 seconds
   27000; // 27 seconds
   60000; //  1 minute
 3480000; // 58 minutes
 3600000; // 60 minutes
*/
// --- check logged in status every INTERVAL_IN_MS ms --- //
const INTERVAL_IN_MS = 60000; //  1 minute
// --- if less than SAFETY_TIME ms are left then refresh token --- //
const SAFETY_TIME = 600000; // 10 minutes

//PrimeReact.ripple = false;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTopbarItem: null,
            layoutMode: 'horizontal',
            mobileMenuActive: null,
            topbarMenuActive: null,
            currentRoute: null,
            menuActive: false,
            showWelcomePassword: false,
            themeColor: 'bluegrey',
            layoutColor: 'moody',
            configDialogActive: false,
            menu: [] //this.createMenu()
        };

        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMobileMenuButtonClick = this.onTopbarMobileMenuButtonClick.bind(this);
        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onSidebarMouseEnter = this.onSidebarMouseEnter.bind(this);
        this.onSidebarMouseLeave = this.onSidebarMouseLeave.bind(this);
        this.onToggleMenuClick = this.onToggleMenuClick.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.changeLayout = this.changeLayout.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);

        // --- hide Freshdesk registration widget --- //
        if (window.FreshworksWidget) {
            window.FreshworksWidget('hide');
            window.FreshworksWidget('close');
        }
    }

    componentDidMount = async () => {
        //const { history, authenticated, userData, fetchingData } = this.props;
        const { history, authenticated, userData } = this.props;

        // --- check if user has token stored in local storage and is authenticated --- //
        if (!localStorage.token || !authenticated) {
            history.push('/');
            return;
        }

        // -- if user is authenticated but user data is empty (why???) then retrieve user data --- //
        if (userData === null || !userData.username || userData.username === '') { // !fetchingData &&
            this.props.isLoggedIn(history);
        }

        // --- fetch available domains and keep in redux store -- //
        //console.log('fetch domains');
        this.props.fetchAvailableDomains();

        this.props.fetchAvailableUserRepositories();

        // --- fetch dynamic filters and repository filter map --- //
        this.props.fetchFilters();
        this.props.fetchRepoFilterMap();

        // --- check for backend services, token refresh etc. --- //
        this.checkStatus();

        // --- start interval to check for backend services, token refresh etc. --- //
        this.intervalID = setInterval(
            () => {
                this.checkStatus();
            }, INTERVAL_IN_MS
        );
    }


    componentWillUnmount() {
        // --- stop interval checks --- //
        clearInterval(this.intervalID);
        //localStorage.setItem('App.js', 'unmount');
    }


    checkStatus = async () => {
        //console.log('***CHECK***');

        // --- check server status --- //
        if (APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showServerStatus || APP_PROPERTIES.ACTIVE_PAGES.servicesStatus) {
            this.props.checkServerStatus();
        }

        // --- check user login status, refresh token if neccessary --- //
        //const msLeft = millisecondsLeftInSession(localStorage.token);
        //const minutesLeft = convertMillisecondsToMinutes(msLeft);
        const { history } = this.props;
        this.props.checkUserLoginStatus(history, localStorage.token, APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.autoRefreshToken, INTERVAL_IN_MS, SAFETY_TIME);
    }

    componentDidUpdate(prevProps) {

        // --- check login status --- //
        if (!this.props.fetchingData && this.props.authenticated && !this.props.userData?.username) {
            this.props.isLoggedIn(this.props.history);
            return;
        }
        if (prevProps.authenticated !== this.props.authenticated)
            !this.props.fetchingData && !this.props.authenticated && this.props.history.push('/');

        // --- handle welcome password --- //
        let showWelcomePassword = localStorage.getItem('showWelcomePassword');
        if (showWelcomePassword === null) {
            localStorage.setItem("showWelcomePassword", true);
        }
        showWelcomePassword = localStorage.getItem('showWelcomePassword');
        if (this.props.welcomePassword === true && showWelcomePassword === "true") {
            this.props.history.push('/newPassword');
        }
        if (this.props.welcomePassword && this.props.history.location.pathname !== '/newPassword') {
            this.props.history.push('/newPassword');
        }

        // --- create custom menu for user depending on their access rights --- //
        if (this.props.userData !== prevProps.userData) {
            const menu = this.createMenu();
            this.setState({ menu: menu });
        }
    }

    onWrapperClick(event) {
        if (!this.menuClick && !this.menuButtonClick && this.state.mobileMenuActive) {
            this.setState({ mobileMenuActive: false });
        }

        if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.configClick) {
            this.setState({ configDialogActive: false });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isOverlay()) {
                this.setState({
                    menuActive: false
                })
            }
        }

        this.menuClick = false;
        this.configClick = false;
        this.menuButtonClick = false;
        this.topbarMenuClick = false;
        this.topbarMenuButtonClick = false;
    }

    onTopbarItemClick(event) {
        //console.log(event)
        this.topbarMenuClick = true;
        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();

    }

    onMenuButtonClick(event) {
        this.menuButtonClick = true;

        if (this.isMobile()) {
            this.setState({ mobileMenuActive: !this.state.mobileMenuActive });
        }

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event) {
        this.topbarMenuButtonClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive }, () =>
            this.onTopbarItemClick({
                originalEvent: event,
                item: 'profile'
            }));
        event.preventDefault();
    }

    onToggleMenuClick(event) {
        this.setState({ layoutMode: (this.state.layoutMode !== 'static') ? 'static' : 'overlay' })
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onSidebarMouseEnter(event) {
        if (this.sidebarTimeout) {
            clearTimeout(this.sidebarTimeout);
        }
        this.addClass(this.sidebar, 'layout-sidebar-active');
    }

    addClass = (element, className) => {
        if (element) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        }
    }

    onSidebarMouseLeave(event) {
        this.sidebarTimeout = setTimeout(() => {
            this.removeClass(this.sidebar, 'layout-sidebar-active');
        }, 250);
    }

    removeClass = (element, className) => {
        if (element) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }


    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onMenuItemClick(event) {
        if (!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
        if (!event.item.isMainMenu && this.isMobile()) {
            //console.log("if")
            this.setState({
                mobileMenuActive: false,
                //menuActive: false
            }, () => {
                this.sidebarTimeout = setTimeout(() => {
                    this.removeClass(this.sidebar, 'layout-sidebar-active');
                }, 0);
            })
        }
    }


    createMenu() {
        if (this.props.userData?.userDetails) {
            // --- create menu for certain user role --- //
            const highestAdminType = this.props.userData.userDetails.highestAdminType;
            const customDashboards = this.props.userData.userDetails.department?.selectedCustomDashboards;
            const customApps = this.props.userData.userDetails.userApps;
            //console.log(customApps)
            const { menu } = createMenuForRole(highestAdminType, customDashboards, customApps);
            return menu;
        }
        return [];
    }

    onConfigButtonClick(event) {
        this.configClick = true;
        this.setState({ configDialogActive: !this.state.configDialogActive })
    }

    onConfigCloseClick() {
        this.setState({ configDialogActive: false })
    }

    onConfigClick() {
        this.configClick = true;
    }

    changeMenuMode(event) {
        this.setState({ layoutMode: event.menuMode });
    }

    changeMenuColor(event) {
        this.setState({ darkMenu: event.darkMenu })
    }

    changeTheme(event) {
        this.setState({ themeColor: event.theme })
        this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
    }

    changeLayout(event) {
        this.setState({ layoutColor: event.theme })
        this.changeStyleSheetUrl('layout-css', event.theme, 'layout');
    }

    changeStyleSheetUrl(id, value, prefix) {

        let element = document.getElementById(id);
        let oldURL = element.getAttribute('href');
        let urlTokens = oldURL.split('/');
        //urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        urlTokens[urlTokens.length - 1] = prefix + value + '.css';
        let newURL = urlTokens.join('/');

        //console.log('oldURL: ', oldURL);
        //console.log('newURL: ', newURL);

        if (newURL !== oldURL) {
            //console.log('replaceLink: ', newURL);
            this.replaceLink(element, newURL);
        }
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    isMobile() {
        return window.innerWidth <= 1024;
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    render() {
        const { logout, userData } = this.props
        //console.log("userData", userData)

        //console.log(this.state.showWelcomePassword)

        let wrapperClass = classNames('layout-wrapper', {
            'layout-wrapper-static': this.state.layoutMode === 'static',
            'layout-wrapper-active': this.state.mobileMenuActive,
            'layout-menu-horizontal': this.state.layoutMode === 'horizontal'
        });
        let sidebarClassName = classNames("layout-sidebar", { 'layout-sidebar-dark': this.state.darkMenu });
        const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

        let backgroundColor = (userData.userDetails.department.backgroundColor && userData.userDetails.department.useCustomHeader) ? userData.userDetails.department.backgroundColor :
            (userData.userDetails.company.backgroundColor && userData.userDetails.company.useCustomHeader) ? userData.userDetails.company.backgroundColor : '#0d4667'

        //console.log(userData.userDetails.highestAdminType)

        return (
            this.props.authenticated && !!userData.username ?
                <React.Fragment>
                    <NotificationTopbar
                    userData={userData}/>
                    <LoadingOverlay
                        active={this.props.fetchingToken || this.state.checkingStatus}
                        spinner={true}
                        className="fullPage"
                        text={this.props.fetchingToken ? 'Refreshing session token...' : 'Checking status of services...'} >

                        <div className={wrapperClass} onClick={this.onWrapperClick}>

                            <nav id="page-navigation" ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}
                                onMouseEnter={this.onSidebarMouseEnter} onMouseLeave={this.onSidebarMouseLeave}>

                                <div className="sidebar-logo" style={{ backgroundColor: backgroundColor }}>
                                    <button className="p-link" style={{ verticalAlign: 'center', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                                        <img
                                            height='30px'
                                            alt={`${APP_PROPERTIES.APP_NAME} application logo`}
                                            src={(userData.userDetails.department.logo && userData.userDetails.department.useCustomHeader) ? userData.userDetails.department.logo :
                                                (userData.userDetails.company.logo && userData.userDetails.company.useCustomHeader) ?
                                                    userData.userDetails.company.logo : APP_PROPERTIES.HEADER_LOGO_APP}
                                            title="Home"
                                            className="dimensions---line-ChemFinder" />
                                    </button>
                                    <button className="p-link sidebar-anchor" title="Toggle Menu" onClick={this.onToggleMenuClick}> </button>
                                </div>

                                { /*<ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>*/}
                                <div className="layout-menu-container" >
                                    <AppMenu model={this.state.menu} onRootMenuItemClick={this.onRootMenuItemClick} layoutMode={this.state.layoutMode}
                                        active={this.state.menuActive} onMenuItemClick={this.onMenuItemClick} />
                                </div>
                                { /*</ScrollPanel>*/}
                            </nav>

                            <div className="layout-main">
                                <AppTopbar layoutMode={this.state.layoutMode} activeTopbarItem={this.state.activeTopbarItem} onTopbarItemClick={this.onTopbarItemClick}
                                    onMenuButtonClick={this.onMenuButtonClick} onTopbarMobileMenuButtonClick={this.onTopbarMobileMenuButtonClick}
                                    topbarMenuActive={this.state.topbarMenuActive} logout={logout} user={userData} history={this.props.history}
                                    sessionTimeLeft={this.props.minutesLeftInSession}
                                    serverStatus={this.props.serverStatus} />

                                <AppBreadCrumbWithRouter />

                                <main className="layout-content">
                                    <Routes />
                                </main>

                                {/*<AppConfig layoutMode={this.state.layoutMode} darkMenu={this.state.darkMenu} layoutColor={this.state.layoutColor} themeColor={this.state.themeColor}
                  changeMenuMode={this.changeMenuMode} changeMenuColor={this.changeMenuColor} changeTheme={this.changeTheme} changeLayout={this.changeLayout}
                  onConfigButtonClick={this.onConfigButtonClick} onConfigCloseClick={this.onConfigCloseClick}
        onConfigClick={this.onConfigClick} configDialogActive={this.state.configDialogActive}/>*/}

                                {/*<AppFooter />*/}

                                {this.state.mobileMenuActive && <div className="layout-main-mask"></div>}
                            </div>
                        </div>

                    </LoadingOverlay>
                </React.Fragment> : null
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    fetchingData: state.user.fetching,
    userData: state.user.data,
    welcomePassword: state.user.data.userDetails.welcomePassword,
    minutesLeftInSession: state.user.minutesLeftInSession,
    fetchingToken: state.user.fetchingToken,
    serverStatus: state.webAPI.serverStatus,
})

const mapDispatchToProps = (dispatch) => ({
    isLoggedIn: (history) => dispatch(Actions.isLoggedIn(history)),
    logout: () => dispatch(Actions.logout()),
    localLogout: () => dispatch(Actions.localLogout()),
    checkUserLoginStatus: (history, token, autoRefreshToken, intervalInMs, safetyTimeInMs) => dispatch(Actions.checkUserLoginStatus(history, token, autoRefreshToken, intervalInMs, safetyTimeInMs)),
    checkServerStatus: () => dispatch(Actions.checkServerStatus()),
    fetchAvailableDomains: () => dispatch(Actions.fetchAvailableDomains()),
    fetchAvailableUserRepositories: (history) => dispatch(Actions.fetchAvailableUserRepositories(history)),
    fetchFilters: () => dispatch(fetchFilterDefinitions()),
    fetchRepoFilterMap: () => dispatch(fetchRepositoryFilterMap()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))