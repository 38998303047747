import { PureComponent } from 'react';
import { APP_PROPERTIES } from '../../../../properties';


class Batches extends PureComponent {

    render() {

        const { doi, pmid, pmcid } = this.props;

        return (
            <>
                {APP_PROPERTIES.INCLUDE_BATCHES && (doi || pmid || pmcid) ?
                    <>
                        <h2 style={{ fontSize: 16, fontWeight: 700, margin: '10px 10px 10px 40px' }}>Metrics</h2>
                        <div id="dimensionsBatches" className="grid" style={{ width: '100%', textAlign: 'left', padding: '0px 10px 5px 40px' }}>
                            <>
                                <div className="col-fixed textAlignLeft" style={{ width: 100 }}>
                                    <div
                                        className="__dimensions_badge_embed__"
                                        data-style="small_circle"
                                        data-legend="hover-right"
                                        //data-hide-zero-citations="true"
                                        //style={{ marginRight: 15, display: 'inline-block' }}
                                        data-doi={doi}
                                        data-pmid={pmid}
                                        data-pmcid={pmcid}
                                    >
                                    </div>
                                </div>
                                <div className="col-fixed textAlignLeft" style={{ width: 100 }}>
                                    <div
                                        className="altmetric-embed"
                                        data-badge-popover="right"
                                        data-badge-type="donut"
                                        data-hide-no-mentions="false"
                                        data-link-target="_blank"
                                        //style={{ display: 'inline-block' }}
                                        data-doi={doi}
                                        data-pmid={pmid}
                                        data-pmcid={pmcid}
                                    >
                                    </div>
                                </div>
                                <div className="col"></div>
                                <div className="breakRow"></div>
                                <div className="col-fixed textAlignLeft" style={{ width: 100, paddingTop: 0 }}>Citations</div>
                                <div className="col-fixed textAlignLeft" style={{ width: 100, paddingTop: 0 }}>Altmetric</div>
                                <div className="col"></div>
                                <div className="breakRow"></div>
                            </>
                        </div>
                        <hr style={{ borderColor: '#e3e3e3', margin: '3px 0' }} />
                    </> : null}
            </>
        );
    }
}

export default Batches;