import React from "react";
import SearchFilterTypeTermloc from "../../../../../../../common/queryBuilder/SearchGroupCriteriaEditor/SearchGroupFilterts/SearchFilterTypeTermloc/SearchFilterTypeTermloc";
import {
  ADVANCED_SEARCH_3_TERMLOC_DATA,
  ADVANCED_SEARCH_3_TERMLOCS,
} from "../criteria/termlocs";

const SearchFilterTypeTermlocContainer = ({
  filter,
  onChange,
  selectedEntry,
}) => (
  <SearchFilterTypeTermloc
    filter={filter}
    onChange={onChange}
    selectedEntry={selectedEntry}
    termlocData={ADVANCED_SEARCH_3_TERMLOC_DATA}
    termlocs={ADVANCED_SEARCH_3_TERMLOCS}
  />
);
export default SearchFilterTypeTermlocContainer;
