import React from 'react';
import styles from './BiomarkerLabel.module.scss';
import BiomarkerLabelTerm from "./BiomarkerLabelTerm/BiomarkerLabelTerm";
import {Tooltip} from "primereact/tooltip";
import './BiomarkerLabel.css';

const BiomarkerLabel = ({terms, name, ocidPrefNames, ocids, showToast, setBiomarkerQueryTerms, fieldName, queryTerms}) => {
    const normalizedTerms = ocidPrefNames?.join(' ,');
    const originalTerms = terms.join(' ,');
    const toolTip = normalizedTerms ? `Normalized term: ${normalizedTerms}\n\nOriginal term: ${originalTerms}` : `Original term: ${originalTerms}`;
    // normalized terms is preferable, but if no this parameter terms should be rendered
    let termSource;
    termSource = ocidPrefNames ? ocidPrefNames : terms;

    const termList = termSource.map(term => <BiomarkerLabelTerm key={term} term={term}
                                                                normalizedTerms={normalizedTerms}
                                                                ocids={ocids}
                                                                originalTerms={originalTerms}
                                                                setBiomarkerQueryTerms={setBiomarkerQueryTerms}
                                                                showToast={showToast}
                                                                fieldName={fieldName}
                                                                queryTerms={queryTerms}
    />)

    return (
        <div className={styles.label} data-pr-tooltip={toolTip} data-pr-position="top">
            <Tooltip className='biomarker-label-custom-tooltip' target={`.${styles.label}`}/>
            <p className={styles.labelHeader}>{name}</p>
            {termList}
        </div>
    );
};

export default BiomarkerLabel;
