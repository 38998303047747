import React, {useState} from 'react';
import SearchInput from "../../../../common/SearchInput/SearchInput";
import {useDispatch, useSelector} from "react-redux";
import SearchControls from "../../../../common/SearchControls/SearchControls";
import styles from './Bioactivities.module.scss';
import Link from "../../../../common/Link/Link";
import {
    resetBioactivitiesState, setBioactivitiesFirstResultIndex, setBioactivitiesInputText,
    setBioactivitiesQueryTerms, setBioactivitiesResultCount,
    setBioactivitiesSuggestions
} from "../../../../../redux/actions/BioactivitiesActions";
import OntologyBrowserDialog from "../../../ontbrowser/OntologyBrowserDialog";
import BulkImportDialog from "../../../bulkimport/components/BulkImportDialog";
import {BULK_SELECT_VALUE_TYPES} from "./bulkTypes";
import SuggestionTemplate from "../../../../common/SuggestionTemplate/SuggestionTemplate";
import {cutString} from "../../../../common/helpers/cutString";
import SearchTemplate from "../../../../common/SearchTemplate/SearchTemplate";
import ConfirmationDialog from "../../../../common/ConfirmDialog/ConfirmationDialog";
import {checkResultAndGetPayload} from "../../../../../api";
import {
    bioactivitiesInputText,
    bioactivitiesQueryTerms, bioactivitiesRoleMapping,
    bioactivitiesSelectedReposForSearch,
    bioactivitiesSuggestions
} from "../../../../../redux/selectors/selectors";
import {fetchBioactivitiesSuggestions} from "../../../../../api/content/BioactivitiesApi";
import {defaultResultCount} from "../../../../../redux/reducers/biomarker";
import {addSearchParamsToQueryTerms} from "../../../../common/helpers/addSearchParamsToQueryTerms";
import {createOptionsForSearchTemplate} from "../../../../common/helpers/createOptionsForSearchTemplate";

let latestTermFragment;

const BioactivitiesSearch = ({
                                 setNeedSearch,
                                 clearToast,
                                 showToast,
                             }) => {
    const queryTerms = useSelector(bioactivitiesQueryTerms);
    const availableDomains = useSelector(({webAPI: {availableDomains}}) => availableDomains);
    const inputText = useSelector(bioactivitiesInputText);
    const suggestions = useSelector(bioactivitiesSuggestions);
    const roleMapping = useSelector(bioactivitiesRoleMapping);
    const selectedReposForSearch = useSelector(bioactivitiesSelectedReposForSearch);

    const dispatch = useDispatch();
    const [domainExplorerVisible, setDomainExplorerVisible] = useState(false);
    const [displayBulkImportDialog, setDisplayBulkImportDialog] = useState(false);
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);

    const bulkState = [...queryTerms].filter(term => term.inputType)?.[0];

    const bioactivitiesDomains = availableDomains?.filter(({value}) => value === 'chem' || value === 'proteins');

    const handleInputChange = (values) => {
        const _queryTerms = addSearchParamsToQueryTerms(values)

        dispatch(setBioactivitiesQueryTerms(_queryTerms));
    };

    const handleInputSelect = () => {
        console.log("")
    }

    const onSearchClick = () => {
        dispatch(setBioactivitiesFirstResultIndex(0));
        dispatch(setBioactivitiesResultCount(defaultResultCount));
        // hack for handle search with actual parameters. setting empty object and pass it as dependency in useEffect in BiomarkerRightAside
        // @todo redesign this logic
        setNeedSearch({});
    };

    const onClearAll = () => {
        setDisplayConfirmationDialog(true);
    };

    const saveAndAlert = () => {
        console.log('SAVE AND ALERT!!!')
    };

    const onDomainExplorerSubmit = (concepts) => {
        const domainExplorerFieldNameMap = {proteins: 'target', chem: 'compound'};
        setDomainExplorerVisible(false);

// set default state for concept
        const terms = Object.values(concepts).map(term => ({
            ...term,
            fieldName: domainExplorerFieldNameMap[term.domain],
            ocids: [term.ocid],
            conceptLookupType: 'ONTOLOGICAL',
            occurrence: 'MUST'
        }))

        dispatch(setBioactivitiesQueryTerms([...queryTerms, ...terms]));
    };

    const onBulkImportSubmit = (inputType, bulkItems) => {
        const term = {
            term: `${BULK_SELECT_VALUE_TYPES[inputType].label}: ${bulkItems.length}`,
            bulkItems,
            inputType,
            termLookupType: 'TERM_NORMALIZED',
            occurrence: 'MUST',
            domain: 'bulkImport'
        };

        const rest = [...queryTerms].filter(term => !term.inputType);

        dispatch(setBioactivitiesQueryTerms([...rest, term]));
        setDisplayBulkImportDialog(false);
    };

    const onHideBulkImportDialog = () => {
        setDisplayBulkImportDialog(false);
    };

    const createTerm = (item) => {
        const maxTermLength = 20;

        // bulk import case
        if (item.inputType) {
            return <Link style={{color: '#000000', display: 'inline', margin: 0, verticalAlign: 'baseline'}}
                         title={item.term}
                         onClick={() => setDisplayBulkImportDialog(true)}/>
        }

        if (typeof item.term === 'string') {
            return cutString(item.term, maxTermLength);
        }

        // term from autocomplete is array with one item. to cut term check it before
        if (typeof item.term === 'object') {
            return cutString(item.term?.[0], maxTermLength);
        }

        // domain explorer case
        return cutString(item.preferredName, maxTermLength);
    }

    const searchTermTemplate = (item) => {
        const toolTip = item.term || item.preferredName;

        // if no fieldName, first role is used. fieldName is set from left aside facettes
        const labelFromMap = roleMapping?.find(role => role.name === item.fieldName)?.label;
        const label = item.roles?.[0];
        let info = labelFromMap || label || 'Free Text';
        const truncatedTerm = createTerm(item);

        const {
            hasTooltip,
            hasDropDown,
            dropdownValue,
            dropdownClassName,
            dropdownOptions
        } = createOptionsForSearchTemplate(item, truncatedTerm);

        return <SearchTemplate dropdownClassName={dropdownClassName} dropdownOptions={dropdownOptions}
                               dropdownValue={dropdownValue} info={info}
                               hasDropDown={hasDropDown} hasTooltip={hasTooltip} toolTip={toolTip}
                               truncatedTerm={truncatedTerm} item={item} queryTerms={queryTerms}
                               handleInputChange={handleInputChange} handleInputSelect={handleInputSelect}/>
    }

    const suggestionTemplate = (item) => <SuggestionTemplate item={item} inputText={inputText}/>

    const completeMethod = (event) => {
        try {
            clearToast();

            latestTermFragment = event.query;
            setTimeout(async () => {
                // --- fetch term suggestions --- //
                let terms;
                const repositoriesIdArray = selectedReposForSearch.map(repo => repo.id);

                if (repositoriesIdArray.length) {
                    const response = await fetchBioactivitiesSuggestions(event.query, repositoriesIdArray);

                    terms = checkResultAndGetPayload(response);
                   
                    if (event.query === latestTermFragment && terms) {
                        dispatch(setBioactivitiesSuggestions(terms));
                        dispatch(setBioactivitiesInputText(event.query));
                    } 
                } else {
                    showToast('Select at least one repository', 'No repositories selected', 'error', 6000);
                }

            }, 200);
        } catch {
            showToast(`Something went wrong while getting suggestions`, 'Error occurred', 'error', 10000);
        }
    };

    const setInitialState = () => {
        setDisplayConfirmationDialog(false);
        dispatch(resetBioactivitiesState());
    };

    const onHideConfirmationDialog = () => {
        setDisplayConfirmationDialog(false);
    };

    return (
        <>
            <OntologyBrowserDialog
                headerLabel="Domain explorer"
                selectionMode="multiple"
                placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details
            view to see concepts matching your search."
                domains={bioactivitiesDomains}
                numOfChildNodes={10}
                allowConceptSearchByClick={true}
                allowSearchInOntologies={true}
                loadOntologiesOnStart={true}
                ontBrowserVisible={domainExplorerVisible}
                onOntBrowserClose={() => setDomainExplorerVisible(false)}
                onOntBrowserSubmit={onDomainExplorerSubmit}
                onOntBrowserShow={() => {
                }}
                width="90vw"
                height="90vh"
            />

            <BulkImportDialog
                displayDialog={displayBulkImportDialog}
                valueTypes={Object.values(BULK_SELECT_VALUE_TYPES)}
                acceptedFileTypes={['.txt', '.csv', '.smi', '.smiles']}
                initialValueType={bulkState?.inputType}
                initialValues={bulkState?.bulkItems}
                onSubmit={onBulkImportSubmit}
                onHide={onHideBulkImportDialog}
            />

            <SearchInput queryTerms={queryTerms}
                         handleInputChange={handleInputChange}
                         handleInputSelect={handleInputSelect}
                         suggestions={suggestions}
                         completeMethod={completeMethod}
                         searchTermTemplate={searchTermTemplate}
                         suggestionTemplate={suggestionTemplate}
            />

            <ConfirmationDialog
                displayDialog={displayConfirmationDialog}
                onHide={onHideConfirmationDialog}
                onSubmit={setInitialState}
                headerText="Confirm"
                messageText="Clear all input?"
                submitButtonLabel="Clear"
            />

            <div className={styles.tools}>
                <div className={styles.links}>
                    <Link title='Domain Explorer' onClick={() => setDomainExplorerVisible(true)}/>
                    {/*<Link title='Bulk import' onCLick={() => setDisplayBulkImportDialog(true)}/>*/}
                </div>

                <SearchControls onSearchClick={onSearchClick}
                                onClearAll={onClearAll} saveAndAlert={saveAndAlert}/>
            </div>
        </>
    );
};

export default BioactivitiesSearch;
