import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BlacklistManager from '../BlacklistManager'

const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    userDetails: state.user.data.userDetails,
    userData: state.user.data,
    availableDomains: state.webAPI.availableDomains
})

const mapDispatchToProps = dispatch => ({
})

const BlacklistContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(BlacklistManager))

export default BlacklistContainer