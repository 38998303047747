import { isArrayEmpty } from "../../../util";

const Definition = ({ definitions }) => {

  return !isArrayEmpty(definitions) ?
    <div>
      {definitions.map(def => {
        return <div key={def}>{def}</div>
      })}
    </div> : null;
}

export default Definition;