
// zoom box text
export const ZOOM_TEXT: any = {
    'auto': 1,
    'page-fit': 0.6,
    'page-width': 1.25
}


// zoom lables and value
export const ZOOM_COMPARE = [

    {
        label: '25%',
        value: 0.25,
        step: 1
    },
    {
        label: '50%',
        value: 0.5,
        step: 2
    },
    {
        label: '60%',
        value: 0.6,
        step: 3
    },
    {
        label: '70%',
        value: 0.7,
        step: 4
    },
    {
        label: '80%',
        value: 0.8,
        step: 5
    },
    {
        label: '90%',
        value: 0.9,
        step: 6
    },
    {
        label: '100%',
        value: 1,
        step: 7
    },
    {
        label: '125%',
        value: 1.25,
        step: 8
    },
    {
        label: '150%',
        value: 1.5,
        step: 9
    },
    {
        label: '200%',
        value: 2,
        step: 10
    },
    {
        label: '250%',
        value: 2.5,
        step: 11
    },
    {
        label: '300%',
        value: 3,
        step: 12
    },
    {
        label: '350%',
        value: 3.5,
        step: 13
    },


]

export const ZOOM_SELECT = [
    {
        label: 'page-fit',
        value: 'page-fit',
        step: 0
    },
    {
        label: 'page-width',
        value: 'page-width',
        step: 0
    },
    {
        label: '25%',
        value: 0.25,
        step: 1
    },
    {
        label: '50%',
        value: 0.5,
        step: 2
    },
    {
        label: '60%',
        value: 0.6,
        step: 3
    },
    {
        label: '70%',
        value: 0.7,
        step: 4
    },
    {
        label: '80%',
        value: 0.8,
        step: 5
    },
    {
        label: '90%',
        value: 0.9,
        step: 6
    },
    {
        label: '100%',
        value: 1,
        step:7
    },
    {
        label: '125%',
        value: 1.25,
        step: 8
    },
    {
        label: '150%',
        value: 1.5,
        step: 9
    },
    {
        label: '200%',
        value: 2,
        step: 10
    },
    {
        label: '250%',
        value: 2.5,
        step: 11
    },
    {
        label: '300%',
        value: 3,
        step: 12
    },
    {
        label: '350%',
        value: 3.5,
        step: 13
    },


]


// emptiy ID
export const EMPTY_ID = "empty-id";


