import { PureComponent } from 'react';
import { isArrayEmpty } from '../../util';
//import { SelectButton } from 'primereact/selectbutton';
import AnnotationsTree from './AnnotationsTree';
import _ from 'lodash';


class Annotations extends PureComponent {

    modifyConceptTreeData = (conceptTreeData, isAnnGrouped) => {
        return _.orderBy(conceptTreeData?.map((item => {
            const temp = this.props.domains.find(obj => obj.name === item.domain)
            if (!_.isEmpty(temp)) {
                return {
                    ...item,
                    color: temp.color,
                    orderPriority: temp.orderPriority
                }
            } else {
                return item;
            }
        })), [isAnnGrouped ? 'orderPriority' : 'label'], isAnnGrouped ? 'desc' : 'asc');

    }

    render() {
        const { conceptTreeData, onClick,
            //  highlightOptions, highlightVals, changeHighlighting,
            activeAnnotations,
            jumpToMatch, toggleDomainHighlighting, isAnnGrouped } = this.props;

        return (
            <>
                {!isArrayEmpty(conceptTreeData) ?
                    <>
                        {/* <div className="grid">
                            <div className="col-12" style={{ paddingTop: '0px' }}>
                                <span>Highlight:</span>
                                <SelectButton
                                    className="primaryButtonSet buttonSmall"
                                    style={{ display: "inline", position: 'relative', top: '5px', marginLeft: 10 }}
                                    value={highlightVals}
                                    multiple={true}
                                    options={highlightOptions}
                                    onChange={changeHighlighting} />
                            </div>
                        </div> */}
                        <AnnotationsTree
                            conceptTreeData={this.modifyConceptTreeData(conceptTreeData, isAnnGrouped)}
                            activeAnnotations={activeAnnotations}
                            jumpToMatch={jumpToMatch}
                            toggleDomainHighlighting={toggleDomainHighlighting} 
                            onClick={onClick}/>
                    </>
                    :
                    <span>No annotations in this document.</span>
                }
            </>
        );
    }
}

export default Annotations;