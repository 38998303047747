import React, { Component } from 'react'
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { TreeTable } from 'primereact/treetable'
import ConfirmationDialog from "../common/ConfirmDialog/ConfirmationDialog";

class SetupDomain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDomains: [],
            selectedOrgs: [],
            allAvailableDomains: [],
            allGlobalDomains: [],
            displayConfirmationDialog: false,
            selectedDom: { label: '' },
            selectedOrg: { label: '' },
            selectedSuborg: { label: '' },
            highlightChecked: true,
            searchableChecked: true
        }
    }

    componentDidMount() {
        let allAvailableDomains = this.props.allAvailableDomains
        let selectedDomains = []
        let selectedOrgs = []
        let allGlobalDomains = this.props.allGlobalDomains

        this.props.allAvailableDomains.forEach(dom => {
            selectedDomains = [...selectedDomains, dom.id]
        })

        this.props.allGlobalDomains.forEach(org => {
            selectedOrgs = [...selectedOrgs, org.id]
        })

        this.setState({
            allAvailableDomains: allAvailableDomains,
            selectedDomains: selectedDomains,
            selectedOrgs: selectedOrgs,
            allGlobalDomains: allGlobalDomains
        })
    }

    componentDidUpdate(prevProps) {
        var self = this
        if (self.props.allGlobalDomains !== prevProps.allGlobalDomains) {
            let allGlobalDomains = this.props.allGlobalDomains
            this.setState({
                allGlobalDomains: allGlobalDomains
            })
        }
    }

    onOrgClick = (e, org, dom) => {
        if (e.checked) {
            this.setState({
                selectedSuborg: { label: '' },
                displayConfirmationDialog: true,
                checked: true,
                selectedOrg: org,
                selectedDom: dom,
                highlightChecked: true,
                searchableChecked: true
            })
        } else {
            this.props.onDomainOrgClick(false, org, dom)
        }
    }

    onSuborgClick = (e, suborg, dom) => {
        if (e.checked) {
            this.setState({
                selectedOrg: { label: '' },
                displayConfirmationDialog: true,
                checked: true,
                selectedSuborg: suborg,
                selectedDom: dom,
                highlightChecked: true,
                searchableChecked: true
            })
        } else {
            this.props.onDomainSuborgClick(false, suborg, dom, this.state.highlightChecked, this.state.searchableChecked)
        }
    }

    onSelectedDomainsChange = (e) => {
        this.setState({
            selectedDomains: e.value
        })
    }

    onSelectedOrgsChange = (e) => {
        this.setState({
            selectedOrgs: e.value
        })
    }

    onHideConfirmationDialog = () => {
        this.setState({
            displayConfirmationDialog: false,
            searchableChecked: true,
            highlightChecked: true
        })
    }

    onConfirmClick = (choice) => {
        this.setState({
            displayConfirmationDialog: false
        }, () => {
            this.props.onDomainOrgClick(this.state.checked, this.state.selectedOrg, this.state.selectedDom, choice, this.state.highlightChecked, this.state.searchableChecked)
        })
    }

    onConfirmSuborgClick = () => {
        this.setState({
            displayConfirmationDialog: false
        }, () => {
            this.props.onDomainSuborgClick(true, this.state.selectedSuborg, this.state.selectedDom, this.state.highlightChecked, this.state.searchableChecked)
        })
    }

    onChangeSearchableChecked = (e) => {
        if (e.checked) {
            this.setState({
                searchableChecked: true
            })
        } else {
            this.setState({
                searchableChecked: false
            })
        }
    }

    onChangeHighlightedChecked = (e) => {
        if (e.checked) {
            this.setState({
                highlightChecked: true
            })
        } else {
            this.setState({
                highlightChecked: false
            })
        }
    }

    render() {

        let allDomains = []

        this.state.allAvailableDomains && this.state.allAvailableDomains.forEach(dom => {
            allDomains = [...allDomains, dom]
        })

        let allOrgs = []

        this.state.allGlobalDomains && this.state.allGlobalDomains.forEach(org => {
            allOrgs = [...allOrgs, org]
        })

        let orgs = []

        this.state.allGlobalDomains.forEach(org => {
            orgs = [...orgs, org]
        })

        let orgData = []


        orgData = orgs && orgs.filter(org => this.state.selectedOrgs.includes(org.id)).map(org => (
            {
                selectable: false,
                key: org.id,
                label: org.name,
                data: {
                    name: <span style={{
                        color: org.deactivated ? 'gray' : 'black', fontWeight: 'bold', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'break-spaces'
                    }} title={org.name}>{org.name}</span>,
                    selectedDomains: org.selectedDomains,
                    selectedAnnotationTypes: org.selectedAnnotationTypes
                },
                children: org.departments.map(department => (
                    {
                        selectable: false,
                        key: department.id,
                        label: department.name,
                        data: {
                            name: <span style={{
                                color: department.deactivated ? 'gray' : 'black', overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'break-spaces'
                            }} title={department.name === 'default' ? `Unassigned / Inactive regular users` :
                                `${department.name}`}>{department.name === 'default' ? `Unassigned / Inactive regular users` : `${department.name}`}</span>,
                            selectedDomains: department.selectedDomains,
                            selectedAnnotationTypes: department.selectedAnnotationTypes
                        }
                    }))
            }))

        let domainOptions = []

        allDomains && allDomains.forEach(dom => {
            domainOptions = [...domainOptions, { label: dom.label, value: dom.id }]
            orgData.forEach(org => {
                org.data[dom.label] = <React.Fragment><Checkbox checked={org.data.selectedDomains.some(el => el.id === dom.id) || org.data.selectedAnnotationTypes.some(el => el.id === dom.id)} onChange={(e) => this.onOrgClick(e, org, dom)}></Checkbox>
                    <label className='p-checkbox-label'>{
                        org.data.selectedDomains.some(el => el.id === dom.id) && org.data.selectedAnnotationTypes.some(el => el.id === dom.id) ?
                            "S, H"
                            : org.data.selectedDomains.some(el => el.id === dom.id) && !org.data.selectedAnnotationTypes.some(el => el.id === dom.id) ?
                                "S"
                                : !org.data.selectedDomains.some(el => el.id === dom.id) && org.data.selectedAnnotationTypes.some(el => el.id === dom.id) ?
                                    "H"
                                    : null
                    }
                    </label></React.Fragment>
                org.children.forEach(suborg => {
                    suborg.data[dom.label] = <React.Fragment><Checkbox style={{ marginLeft: 15 }} checked={suborg.data.selectedDomains.some(el => el.id === dom.id) || suborg.data.selectedAnnotationTypes.some(el => el.id === dom.id)} onChange={(e) => this.onSuborgClick(e, suborg, dom)}></Checkbox>
                        <label className='p-checkbox-label'>{
                            suborg.data.selectedDomains.some(el => el.id === dom.id) && suborg.data.selectedAnnotationTypes.some(el => el.id === dom.id) ?
                                "S, H"
                                : suborg.data.selectedDomains.some(el => el.id === dom.id) && !suborg.data.selectedAnnotationTypes.some(el => el.id === dom.id) ?
                                    "S"
                                    : !suborg.data.selectedDomains.some(el => el.id === dom.id) && suborg.data.selectedAnnotationTypes.some(el => el.id === dom.id) ?
                                        "H"
                                        : null
                        }</label></React.Fragment>
                })
            })
        })

        let orgOptions = []

        allOrgs && allOrgs.forEach(org => {
            orgOptions = [...orgOptions, { label: org.name, value: org.id }]
        })

        let nameTemplate = (node) => {
            return (
                <React.Fragment>
                    <span>{node.data.name}</span>
                </React.Fragment>
            )
        }

        let domainColumns = allDomains && allDomains.filter(dom => this.state.selectedDomains.includes(dom.id)).map(dom => (
            <Column key={dom.id} style={{
                width: '100px'
            }} field={dom.label} header={dom.label}></Column>
        ))

        const rowClassName = (node) => {
            return { 'p-highlight': (node.children) }
        }

        return (
            <Dialog visible={this.props.displaySetupDomain} style={{ 'width': "97vw", 'height': '95vh' }} header="Setup domains" focusOnShow={false}
                modal={true}
                dismissableMask={false}
                onHide={() => this.props.onCancelSetupDomain()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 0, paddingTop: 20, marginRight: 0, marginBottom: 0 }}>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Organizations: </span>
                    <MultiSelect
                        pt={{
                            checkboxIcon: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (e.target.className.baseVal !== "") {
                                        e.target.parentNode.click();
                                    } else {
                                        e.target.parentNode.parentNode.click();
                                    }
                                },
                            },
                            headerCheckbox: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                },
                            },
                        }}
                        maxSelectedLabels={3}
                        filter
                        options={orgOptions}
                        value={this.state.selectedOrgs}
                        onChange={(e) => this.onSelectedOrgsChange(e)}
                        style={{ marginRight: 20, verticalAlign: '-moz-middle-with-baseline' }}
                        placeholder='Select organizations'
                    ></MultiSelect>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Domains: </span>
                    <MultiSelect
                        pt={{
                            checkboxIcon: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (e.target.className.baseVal !== "") {
                                        e.target.parentNode.click();
                                    } else {
                                        e.target.parentNode.parentNode.click();
                                    }
                                },
                            },
                            headerCheckbox: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                },
                            },
                        }}
                        maxSelectedLabels={3}
                        filter
                        options={domainOptions}
                        value={this.state.selectedDomains}
                        style={{ verticalAlign: '-moz-middle-with-baseline' }}
                        onChange={(e) => this.onSelectedDomainsChange(e)}
                        placeholder='Select domains'
                    ></MultiSelect>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Legend: S = Searchable domain, H = Highlighted domain</span>
                </div>
                <div className='treetable-responsive-demo' style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, marginRight: 0 }}>
                    <TreeTable value={orgData}
                        rowClassName={rowClassName}
                        scrollable
                        scrollHeight='65vh'
                        width="95vw"
                        className="treetable-with-header"
                        expandedKeys={this.state.expandedKeys}
                        onToggle={e => this.setState({ expandedKeys: e.value })}>
                        <Column field='name' body={nameTemplate} header='Orgs./Suborgs.' expander style={{ width: "200px" }} />
                        {domainColumns}
                </TreeTable>
                </div>
                <ConfirmationDialog
                    displayDialog={this.state.displayConfirmationDialog}
                    onHide={() => this.onHideConfirmationDialog()}
                    onSubmit={() => this.state.selectedOrg.label !== "" ? this.onConfirmClick('all') : this.onConfirmSuborgClick()}
                    onSubmitSecondChoice={() => this.onConfirmClick('none')}
                    headerText={`Activate domain "${this.state.selectedDom.label}" for "${this.state.selectedOrg.label !== "" ? this.state.selectedOrg.label : this.state.selectedSuborg.label !== "" ? this.state.selectedSuborg.label : ""}"`}
                    messageText={this.state.selectedOrg.label !== "" ? `By clicking "For all suborganizations" this domain will be activated for the organization and all of its suborganizations.
                     Warning: The domain then will be visible for all users of these suborganizations immediately! If you only want to activate this domain for the organization click "Only for organization".
                      The domain then will not be visible for the users of all suborganizations. The domain needs to be activated for a certain suborganization in the next step.` : "Activate domain for this suborganization."}
                    submitButtonLabel={this.state.selectedOrg.label !== "" ? "For all suborganizations" : "Activate"}
                    cancelButtonLabel="Only for organization"
                    secondChoice={this.state.selectedOrg.label !== "" ? true : false}
                    onlyOneChoice={this.state.selectedOrg.label !== "" ? false : true}
                    customContent={
                        <div className='col-12'>
                            <label>Activate as searchable and highlighted domain?</label>
                            <div className='col-12' style={{ paddingLeft: 0, marginLeft: -2 }}>
                                <div style={{ paddingBottom: 10 }}>
                                    <Checkbox checked={this.state.searchableChecked} onChange={(e) => this.onChangeSearchableChecked(e)}></Checkbox>
                                    <label className='p-checkbox-label'>Searchable domain</label>
                                </div>
                                <div>
                                    <Checkbox checked={this.state.highlightChecked} onChange={(e) => this.onChangeHighlightedChecked(e)}></Checkbox>
                                    <label className='p-checkbox-label'>Highlighted domain</label>
                                </div>
                            </div>
                        </div>
                    }>
                </ConfirmationDialog>
            </Dialog>
        )
    }
}

export default SetupDomain