import "./TermGroupEdit.css";
import { Fragment, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { isArrayEmpty, removeValueFromArray } from "../../../../../../../util";
import OntologyBrowserDialog from "../../../../../../../ontbrowser/OntologyBrowserDialog";
import {
  addConceptsToQueryTerms,
  replaceQueryTermWithConcepts,
} from "../../../../../../../general/docsearch/searchUtil";
import {
  abbreviateText,
  addIDToQueryTerm,
  addIDsToQueryTerms,
  createDomainLabelsArray,
  createQueryTermFromDirectMatch,
  isFreeText,
  renderList,
} from "../../../../../../helpers/advancedSearch";
import { getQueryTermID } from "../../../../../../helpers/getQueryTermID";
import SpecifyDomainsDialog from "./SpecifyDomainsDialog";
import QueryTermsTable from "./QueryTermsTable/QueryTermsTable";
import QueryTermsInput from "./QueryTermsInput";
import BulkImportTermsDialog from "./BulkImportTermsDialog";
import { fetchTermConcepts } from "../../../../../../../../../api/content/ConceptApi";
import { RESPONSE_STATUS_SUCCESS } from "../../../../../../../../../properties";
import { SEARCH_FILTER_ID_SEARCH_MODE_LOCAL } from "../../../../../../../general/docsearch/searchConstants";
import { LIST_CUTOFF_QUERY_TERMS } from "../../../../../../helpers/constants";
import TruncateEntriesLink from "../../../../../../../../common/queryBuilder/searchForm/searchGroup/common/TruncateEntriesLink";
import { toggleTermGroupDialog } from "../../../../../../../../../redux/features/queryBuilder/actions";
import { filterDefinitionsSelector } from "../../../../../../../../../redux/common/filters/selectors/filters";

const TermGroupEdit = ({ queryTerms, onValuesChange }) => {
  const availableDomains = useSelector(
    (state) => state.webAPI.availableDomains
  );
  const domainLabelsMap = useSelector(
    (state) => state.user.data.userDetails.department.domainLabelsMap
  );
  const filterDefinitions = useSelector(filterDefinitionsSelector);
  const toggleDialog = useSelector(
    (state) => state.advancedSearch3Reducer.toggleTermGroupDialog
  );
  const dispatch = useDispatch();

  const [displayDomainExplorerDialog, setDisplayDomainExplorerDialog] =
    useState();
  const [displaySpecifyDomainsDialog, setDisplaySpecifyDomainsDialog] =
    useState();
  const [displayBulkImportTermsDialog, setDisplayBulkImportTermsDialog] =
    useState();

  const [queryTermsAutocomplete, setQueryTermsAutocomplete] = useState([]);
  const [queryTermToEdit, setQueryTermToEdit] = useState();
  const [queryTermsUpdated, setQueryTermsUpdated] = useState([]);

  const [showAll, setShowAll] = useState(false);

  const searchModeFilter = filterDefinitions
    ? filterDefinitions[SEARCH_FILTER_ID_SEARCH_MODE_LOCAL]
    : null;

  // TODO: check: do this here or elsewhere?
  useEffect(() => {
    const queryTermsNew = addIDsToQueryTerms(queryTerms);
    setQueryTermsUpdated(queryTermsNew);
  }, [queryTerms]);

  const handleOpenDomainExplorer = (queryTerm) => {
    setQueryTermToEdit(queryTerm);
    setDisplayDomainExplorerDialog(true);
  };

  const handleBulkImportTerms = () => {
    setDisplayBulkImportTermsDialog(true);
  };

  const handleSpecifyDomains = (queryTerm) => {
    setQueryTermToEdit(queryTerm);
    setDisplaySpecifyDomainsDialog(true);
  };

  const handleSpecifyTextType = (queryTerm) => {
    setQueryTermToEdit(queryTerm);
    setDisplaySpecifyDomainsDialog(true);
  };

  const handleAddAutocompleteQueryTerm = (queryTermsAC) => {
    const queryTermsNew = addIDsToQueryTerms(queryTermsAC);
    const queryTermsUpdatedNew = [...queryTermsUpdated, ...queryTermsNew];
    setQueryTermsUpdated(queryTermsUpdatedNew);
  };

  const handleSubmitSpecifyConcepts = (concepts) => {
    if (queryTermToEdit) {
      const index = queryTermsUpdated.indexOf(queryTermToEdit);
      const queryTermsUpdatedNew = replaceQueryTermWithConcepts(
        concepts,
        queryTermsUpdated,
        queryTermToEdit
      );
      if (queryTermsUpdatedNew[index]) {
        const qtNew = queryTermsUpdatedNew[index];
        addIDToQueryTerm(qtNew);
        qtNew.searchByOcids = true;
      }
      setQueryTermsUpdated(queryTermsUpdatedNew);
      setQueryTermToEdit(null);
    } else {
      const queryTermsUpdatedNew = addConceptsToQueryTerms(
        concepts,
        queryTermsUpdated,
        true
      );
      if (queryTermsUpdatedNew[queryTermsUpdatedNew.length - 1]) {
        const qtNew = queryTermsUpdatedNew[queryTermsUpdatedNew.length - 1];
        addIDToQueryTerm(qtNew);
        qtNew.searchByOcids = true;
      }
      setQueryTermsUpdated(queryTermsUpdatedNew);
    }
  };

  const handleSubmitSpecifyDomains = (domains) => {
    const { label, ocids, prefnames, otherTerms, ...queryTermToEditNew } =
      queryTermToEdit;
    queryTermToEditNew.domains = domains;
    replaceQueryTerm(queryTermToEdit, queryTermToEditNew);
    setQueryTermToEdit(null);
  };

  const handleSubmitBulkImportTerms = async (bulkImportValues) => {
    // TODO: move to util
    const conceptDataNew = [];
    if (!isArrayEmpty(bulkImportValues)) {
      for (let term of bulkImportValues) {
        const response = await fetchTermConcepts(term);
        if (response.status === RESPONSE_STATUS_SUCCESS) {
          const result = response.payload;
          const queryTerm = createQueryTermFromDirectMatch(term, result);
          addIDToQueryTerm(queryTerm);
          conceptDataNew.push(queryTerm);
        }
      }
    }
    const queryTermsUpdatedNew = [...queryTermsUpdated, ...conceptDataNew];
    setQueryTermsUpdated(queryTermsUpdatedNew);
  };

  const handleUpdateSynonymsOnly = (queryTerm, searchMode) => {
    const queryTermNew = { ...queryTerm, searchMode: searchMode };
    replaceQueryTerm(queryTerm, queryTermNew);
  };

  const handleUpdateIncreaseRecall = (queryTerm, increaseRecall) => {
    const queryTermNew = { ...queryTerm, increaseRecall: increaseRecall };
    replaceQueryTerm(queryTerm, queryTermNew);
  };

  const handleUpdateBooster = (queryTerm, booster) => {
    const queryTermNew = { ...queryTerm, booster: booster };
    replaceQueryTerm(queryTerm, queryTermNew);
  };

  const handleUpdateFuzziness = (queryTerm, fuzziness) => {
    const queryTermNew = { ...queryTerm, fuzziness: fuzziness };
    replaceQueryTerm(queryTerm, queryTermNew);
  };

  const handleUpdateTextDistance = (queryTerm, textDistance) => {
    const queryTermNew = { ...queryTerm, textDistance: textDistance };
    replaceQueryTerm(queryTerm, queryTermNew);
  };

  const replaceQueryTerm = (queryTerm, queryTermNew) => {
    const idx = queryTermsUpdated.indexOf(queryTerm);
    const queryTermsUpdatedNew = [...queryTermsUpdated];
    queryTermsUpdatedNew[idx] = queryTermNew;

    setQueryTermsUpdated(queryTermsUpdatedNew);
  };

  const handleDeleteQueryTerm = (queryTerm) => {
    let queryTermsUpdatedNew = [...queryTermsUpdated];
    queryTermsUpdatedNew = removeValueFromArray(
      queryTermsUpdatedNew,
      queryTerm
    );

    setQueryTermsUpdated(queryTermsUpdatedNew);
  };

  const handleDeleteQueryTerms = (queryTermIDs) => {
    const queryTermIDsMap = {};
    queryTermIDs?.forEach((qtID) => {
      queryTermIDsMap[qtID] = true;
    });
    let queryTermsUpdatedNew = [...queryTermsUpdated];
    queryTermsUpdatedNew = queryTermsUpdatedNew.filter(
      (qt) => !queryTermIDsMap[qt.id]
    );

    setQueryTermsUpdated(queryTermsUpdatedNew);
  };

  const handleClose = () => {
    dispatch(toggleTermGroupDialog(false));
    setQueryTermsUpdated(queryTerms);
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          className="p-button-sm p-button-secondary"
          onClick={handleClose}
        />
        <Button
          label="OK"
          className="p-button-sm primaryButton"
          disabled={!isArrayEmpty(queryTermsAutocomplete)}
          onClick={() => {
            dispatch(toggleTermGroupDialog(false));
            onValuesChange([...queryTermsUpdated]);
          }}
        />
      </>
    );
  };

  const groupQueryTerms = queryTerms ?? [];
  const queryTermsTruncated = showAll
    ? groupQueryTerms
    : [...groupQueryTerms].slice(0, LIST_CUTOFF_QUERY_TERMS);

  return (
    <>
      <div>
        <div className="col-12">
          <div className="inline-flex align-items-center flex-wrap gap-2 term-group-edit">
            {queryTermsTruncated.map((queryTerm) => {
              const term = abbreviateText(queryTerm.term);
              const searchMode = searchModeFilter?.filterValues?.find(
                (fv) => fv.filterValue === queryTerm.searchMode
              );
              const booster = queryTerm.booster ? `^${queryTerm.booster}` : "";
              const fuzziness = queryTerm.textDistance
                ? `~${queryTerm.textDistance}`
                : "";
              const domainLabels = createDomainLabelsArray(
                queryTerm.domains,
                domainLabelsMap
              );
              const domainLabelsString = domainLabels.join(", ");
              const backgroundColor = isFreeText(queryTerm.domains)
                ? "#F3F3F3"
                : "#e8f3fc";
              const key = getQueryTermID(queryTerm);
              return (
                <Fragment key={key}>
                  <div
                    className="flex flex-column justify-content-around term-box"
                    style={{ backgroundColor: backgroundColor }}
                  >
                    <div className="flex align-items-center">
                      <div title={queryTerm.term}>
                        <b>{term}</b>
                      </div>
                      <div>
                        {fuzziness && (
                          <span title="Text proximity" className="term-option">
                            {fuzziness}
                          </span>
                        )}
                        {searchMode && (
                          <span
                            title={`${searchMode.filterValueLabel}`}
                            className="term-option uppercase"
                          >
                            {searchMode.filterValue}
                          </span>
                        )}
                        {booster && (
                          <span
                            title="Importance factor"
                            className="term-option"
                          >
                            {booster}
                          </span>
                        )}
                      </div>
                    </div>
                    <div title={domainLabelsString}>
                      {!isArrayEmpty(domainLabels) && (
                        <div>{renderList(domainLabels, 3)}</div>
                      )}
                    </div>
                  </div>
                </Fragment>
              );
            })}

            <TruncateEntriesLink
              visible={LIST_CUTOFF_QUERY_TERMS < groupQueryTerms.length}
              numberOfEntries={groupQueryTerms.length}
              showAll={showAll}
              onClick={setShowAll}
            />

            <div>
              {!isArrayEmpty(queryTerms) ? (
                <div style={{ marginLeft: 15 }}>
                  <a onClick={() => dispatch(toggleTermGroupDialog(true))}>
                    Edit terms
                  </a>
                </div>
              ) : (
                <a onClick={() => dispatch(toggleTermGroupDialog(true))}>
                  Add terms
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        id="queryTermsTableDialog"
        visible={toggleDialog}
        onHide={handleClose}
        onShow={() => {
          setQueryTermsAutocomplete([]);
        }}
        blockScroll
        style={{ minWidth: "70vw", maxWidth: "90vw", maxHeight: "90vh" }}
        header="Search terms"
        footer={renderFooter}
        appendTo="self"
        className="styled-dialog"
      >
        <div>
          <QueryTermsInput
            queryTermsAutocomplete={queryTermsAutocomplete}
            queryTerms={queryTermsUpdated}
            onAutocompleteInputChange={(qts) =>
              handleAddAutocompleteQueryTerm(qts)
            }
            onOpenDomainExplorer={handleOpenDomainExplorer}
            onBulkImportTerms={handleBulkImportTerms}
          />
          <hr />
        </div>

        {!isArrayEmpty(queryTermsUpdated) ? (
          <QueryTermsTable
            queryTerms={queryTermsUpdated}
            onSpecifyConcepts={handleOpenDomainExplorer}
            onSpecifyDomains={handleSpecifyDomains}
            onSpecifyTextType={handleSpecifyTextType}
            onUpdateSynonymsOnly={handleUpdateSynonymsOnly}
            onUpdateIncreaseRecall={handleUpdateIncreaseRecall}
            onUpdateBooster={handleUpdateBooster}
            onUpdateFuzziness={handleUpdateFuzziness}
            onUpdateTextDistance={handleUpdateTextDistance}
            onDeleteQueryTerm={handleDeleteQueryTerm}
            onDeleteQueryTerms={handleDeleteQueryTerms}
          />
        ) : (
          "No search terms added, yet."
        )}
      </Dialog>

      <SpecifyDomainsDialog
        domains={queryTermToEdit?.domains}
        domainLabelsMap={domainLabelsMap}
        onValuesChange={handleSubmitSpecifyDomains}
        visible={displaySpecifyDomainsDialog}
        onHide={() => setDisplaySpecifyDomainsDialog(false)}
      />

      <BulkImportTermsDialog
        visible={displayBulkImportTermsDialog}
        onSubmit={handleSubmitBulkImportTerms}
        onHide={() => setDisplayBulkImportTermsDialog(false)}
      />

      <OntologyBrowserDialog
        ontBrowserDialogID="advSearch3DomBrowserDlg"
        headerLabel="Domain explorer"
        selectionMode="multiple"
        placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
        placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
        domains={availableDomains}
        initialOcids={queryTermToEdit?.ocids || []}
        numOfChildNodes={10}
        allowConceptSearchByClick={true}
        allowSearchInOntologies={true}
        loadOntologiesOnStart={true}
        ontBrowserVisible={displayDomainExplorerDialog}
        onOntBrowserClose={() => setDisplayDomainExplorerDialog(false)}
        onOntBrowserSubmit={handleSubmitSpecifyConcepts}
        onOntBrowserShow={() => {}}
        width="90vw"
        height="90vh"
      />
    </>
  );
};

export default TermGroupEdit;
