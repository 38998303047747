/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from 'primereact/button';
import { PureComponent } from 'react';
import { isArrayEmpty } from '../../util';


class Sequences extends PureComponent {

    renderSequences = (docAdditionalOrganisms, minSequences, maxSequences, ocDocID) => {
        if (docAdditionalOrganisms?.patIdSequencesMap && Object.keys(docAdditionalOrganisms.patIdSequencesMap).length > 0) {
            return docAdditionalOrganisms.patIdSequencesMap[ocDocID].slice(minSequences, maxSequences).map(seq => {
                return <div key={`${seq.seqNo}-${seq.seqType}`}>
                    <a id="detailSbjct"
                        key={`${seq.seqNo}-${seq.seqType}`}
                        title='Click to show details'
                        onClick={seq.seqType === 'DNA' ? (e) => this.props.handleShowSequenceDetailsNucleotide(e, seq.sequenceId) :
                            (e) => this.props.handleShowSequenceDetailsProtein(e, seq.sequenceId)}
                        style={{ display: 'inline-block' }}>{seq.seqType === 'DNA' ? 'Nucleotide' : 'Protein'} sequence number {`${seq.seqNo}`}</a>
                </div>
            }
            )
        }
    }

    renderMatchedSequences = (docAdditionalOrganisms, sequenceID, ocDocID) => {
        if (docAdditionalOrganisms && docAdditionalOrganisms.patIdSequencesMap && Object.keys(docAdditionalOrganisms.patIdSequencesMap).length > 0 &&
            sequenceID !== undefined) {
            return docAdditionalOrganisms.patIdSequencesMap[ocDocID].map((seq, i) => {
                if (seq.sequenceId === parseInt(sequenceID)) {
                    return <div key={`${seq.seqNo}-${seq.seqType}-${i}`}>
                        <a id="detailSbjct"
                            key={`${seq.seqNo}-${seq.seqType}-${i}`}
                            title='Click to show details'
                            onClick={seq.seqType === 'DNA' ? (e) => this.props.handleShowSequenceDetailsNucleotide(e, seq.sequenceId) :
                                (e) => this.props.handleShowSequenceDetailsProtein(e, seq.sequenceId)}
                            style={{ display: 'inline-block' }}>{seq.seqType === 'DNA' ? 'Nucleotide' : 'Protein'} sequence number {`${seq.seqNo}`}</a>
                    </div>
                }
            }
            )
        }
        return [];
    }

    render() {
        const { sequenceID, docAdditionalOrganisms, ocDocID,
            exportNucleotideSequencesFromDocument, exportProteinSequencesFromDocument,
            decreaseNumberOfSequences, increaseNumberOfSequences, minSequences, maxSequences } = this.props;

        const matchedSequences = this.renderMatchedSequences(docAdditionalOrganisms, sequenceID, ocDocID);
        const matchUndef = matchedSequences.every(function (v) { return v === undefined })

        return (
            <>
                {!isArrayEmpty(matchedSequences) && matchUndef === false ?
                    <div className="col-fixed" style={{ width: '300px', marginLeft: '-0.5em', marginBottom: 10 }}>
                        <span>Matched sequences:</span>
                        <div>
                            {matchedSequences}
                        </div>
                    </div>
                    : null
                }
                <div className="grid" style={{ marginBottom: 5, marginTop: 12 }}>

                    <div className="col-fixed">
                        <span>Download sequences (max. 1000):</span>
                    </div>
                    <div className="col textAlignRight">
                        <a onClick={() => exportNucleotideSequencesFromDocument(ocDocID)}>
                            FASTA (.zip) nucleotide
                        </a>
                        <span style={{ paddingLeft: 10, paddingRight: 10 }}> | </span>
                        <a onClick={() => exportProteinSequencesFromDocument(ocDocID)}>
                            FASTA (.zip) protein
                        </a>
                    </div>
                </div>
                <hr />
                <div>
                    {this.renderSequences(docAdditionalOrganisms, minSequences, maxSequences, ocDocID)}
                </div>
                <div style={{ textAlign: 'right' }}>
                    <Button
                        className="p-button-secondary p-button-sm buttonSmall"
                        type="button"
                        label="<"
                        title="Show previous 20 sequences"
                        onClick={(e) => decreaseNumberOfSequences(e)}
                        disabled={minSequences <= 0}>
                    </Button>
                    <Button
                        className="p-button-secondary p-button-sm buttonSmall"
                        type="button"
                        label=">"
                        title="Show next 20 sequences"
                        style={{ marginRight: 0 }}
                        onClick={(e) => increaseNumberOfSequences(e)}
                        disabled={maxSequences >= docAdditionalOrganisms.patIdSequencesMap[ocDocID].length}>
                    </Button>
                </div>
            </>
        );
    }
}

export default Sequences;