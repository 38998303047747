export const DATA_TYPE_COOC_FORM = 'cooc_form';
//export const DATA_TYPE_XXX = 'xxx';


/**
 * Adds data to local storage. Overwrites previous entry.
 * 
 * @param {string} dataType data type ID
 * @param {Object} data actual data to store
 */
export const addDataToLocalStorage = (dataType, data) => {
    if (dataType) {
        // --- update data in local storage --- //
        let dataStrg;
        if (data) {
            dataStrg = JSON.stringify(data);
        }
        localStorage.setItem(dataType, dataStrg);
    }
}

/**
 * Removes data from local storage.
 * 
 * @param {string} dataType data type ID
 */
export const removeDataFromLocalStorage = (dataType) => {

    // --- delete data from local storage --- //
    if (dataType) {
        localStorage.removeItem(dataType);
    }
}

/**
 * Returns data from local storage.
 * 
 * @param {string} dataType data type ID
 */
export const getDataFromLocalStorage = (dataType) => {

    // --- get data from local storage --- //
    let data;
    let dataStrg = localStorage.getItem(dataType);
    if (dataStrg) {
        data = JSON.parse(dataStrg);
    }
    return data;
}