/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Component, createRef } from "react";
import { APP_PROPERTIES } from "../../../properties";
import { isArrayEmpty } from "../util";


class TutorialVideos extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.op = createRef();
    }

    onTutorialClick = (section, label, videoTitle) => {
        this.op.hide();
        let tutorialObject = { section: section, label: label, title: videoTitle };
        localStorage.setItem('tutorialData', JSON.stringify(tutorialObject));
        window.open(`${APP_PROPERTIES.FRONTEND_URL}tutorials`, "_blank");
    }

    renderVideos = (pageTutorials) => {
        return pageTutorials ? pageTutorials.map((vid, i) => {
            return <div key={vid.title} style={{ borderBottom: i + 1 !== pageTutorials.length ? '1px solid #b2b2b2' : 'none' }}>
                <a key={vid.title} title={`${vid.title} ${vid.time}`}
                    style={{ display: 'inline-block', color: 'black', paddingBottom: 5, paddingTop: 5 }}
                    onClick={(e) => {
                        this.onTutorialClick(this.props.section, this.props.sectionLabel, vid.title);
                    }}>{`${vid.title} ${vid.time}`}</a>
            </div>
        }) : null;
    }

    render() {

        const { pageTutorials, tooltip } = this.props;

        return (
            <>
                {!isArrayEmpty(pageTutorials) ?
                    <>
                        <Button className="youtube p-p-0" style={{ backgroundColor: 'white', border: 'none' }}
                            title={tooltip ? tooltip : 'Click to show available tutorial videos'}
                            onClick={(e) => this.op.toggle(e)}>
                            <i className="pi pi-youtube p-px-2" style={{ color: 'black', paddingRight: 5, lineHeight: 'unset' }}></i>
                            <span style={{ color: 'black' }}>
                                {pageTutorials.length > 1 ? "Tutorials available" : "Tutorial available"}
                            </span>
                        </Button>
                        <OverlayPanel className='tutorialOverlay' ref={(el) => { this.op = el }}>
                            {this.renderVideos(pageTutorials)}
                        </OverlayPanel>
                    </> : null}
            </>
        );
    }
}

export default TutorialVideos;