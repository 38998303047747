import _ from "lodash";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { isArrayEmpty } from "../../../util";


const BulkImportOutput = ({
    inputEntries
}) => {

    const toast = useRef(null);

    return <>
        <Toast ref={toast} />

        <div style={{
            opacity: 0.9, width: '100%', minHeight: 'calc(100% - 2px)', height: '100%', /*height: 'calc(100vh - 475px)',*/ background: '#fafafa', /* height: '25vh', maxHeight: '25vh', */
            overflow: 'auto', border: !isArrayEmpty(inputEntries) ? '1px solid #dddddd' : '1px solid #e5e5e5', padding: 5
        }}>
            {inputEntries ?
                <ul style={{ marginTop: 10 }}>
                    {inputEntries.map(entry => {
                        return <li key={entry}>
                            {entry}
                        </li>
                    })}
                </ul> : ''}
        </div>
    </>
}

export default BulkImportOutput;