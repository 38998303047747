import { PureComponent } from 'react';
import { Button } from 'primereact/button';


class MatchesButtons extends PureComponent {

    render() {
        const { matchCount, jumpToMatch } = this.props;

        return (
            <>
                <label className="valignMiddle" style={{marginLeft:10}}> ({matchCount}):</label>
                <div className="valignMiddle">
                    <Button
                        className="minorButton p-button-sm buttonSmall"
                        style={{ padding: '0 10px', marginLeft: 10 }}
                        type="button"
                        label="<"
                        title="Jump to previous occurrence"
                        onClick={() => jumpToMatch(0, false)}
                        disabled={matchCount <= 0}>
                    </Button>
                    <Button
                        className="minorButton p-button-sm buttonSmall"
                        style={{ padding: '0 10px' }}
                        type="button"
                        label=">"
                        title="Jump to next occurrence"
                        onClick={() => jumpToMatch(0, true)}
                        disabled={matchCount <= 0}>
                    </Button>
                </div>
            </>
        );
    }
}

export default MatchesButtons;