/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

class ConceptOptions extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    onLoadSentences = (event) => {

        const { queryTermsLeft, queryTermsRight, resultTermListLeft, resultTermListRight, concept } = this.props;

        //console.log('queryTermsLeft: ', queryTermsLeft);
        //console.log('queryTermsRight: ', queryTermsRight);
        //console.log('resultTermListRight: ', this.props.resultTermListRight);

        if (concept.isLeft) {
            const queryOcidsRight = [];
            if (resultTermListRight) {
                resultTermListRight.forEach(term => {
                    if (term.ocid) {
                        queryOcidsRight.push(term.ocid);
                    }
                });
            }
            this.props.onLoadSentences([concept.ocid], queryOcidsRight, concept.label, queryTermsRight.label, concept);
        }
        else {
            const queryOcidsLeft = [];
            if (resultTermListLeft) {
                resultTermListLeft.forEach(term => {
                    if (term.ocid) {
                        queryOcidsLeft.push(term.ocid);
                    }
                });
            }
            this.props.onLoadSentences(queryOcidsLeft, [concept.ocid], queryTermsLeft.label, concept.label, concept);
        }

        this.props.hideConceptOptions(event);
    }


    onSelectConcept = (event) => {

        const selectedConcepts = { ...this.props.selectedConcepts };

        //console.log('this.props.selectedConcepts: ', this.props.selectedConcepts);

        if (selectedConcepts[this.props.concept.ocid]) {
            delete selectedConcepts[this.props.concept.ocid];
        }
        else {
            selectedConcepts[this.props.concept.ocid] = {
                label: this.props.concept.label,
                prefname: this.props.concept.prefname
            }
        }

        //console.log('selectedConcepts: ', selectedConcepts);

        this.props.updateSelectedConcepts(selectedConcepts);

        this.props.hideConceptOptions(event);
    }

    onShowConceptDetails = (event) => {

        this.props.onShowConceptDetails();

        this.props.hideConceptOptions(event);
    }

    onRefineSearch = (event) => {

        this.props.onReplaceQueryTerms([{
            ocids: [this.props.concept.ocid],
            domains: [this.props.concept.domain],
            term: this.props.concept.label,
            prefnames: [this.props.concept.label]
        }], this.props.concept.isLeft);

        this.props.hideConceptOptions(event);
    }

    render() {

        const { concept } = this.props;

        return (
            <React.Fragment>
                {concept ?
                    <div style={{ lineHeight: 2 }}>
                        {/*<span className="infoText">Correlations</span>*/}
                        <p><a onClick={(e) => this.onLoadSentences(e)}
                            title={`Load sentences containing ${concept.label} together with ${concept.isLeft ? this.props.queryTermsLabelRight : this.props.queryTermsLabelLeft}`}>{`Load sentences`}</a></p>
                        <p><a onClick={(e) => this.onRefineSearch(e)}
                            title={`Search for correlations with ${concept.label}`}>Refine search</a></p>
                        {/*<hr />
                        <span className="infoText">Concept</span>*/}
                        <p><a onClick={(e) => this.onShowConceptDetails(e)}
                            title="Show concept details">Show concept details</a></p>
                        {/*<p><a onClick={(e) => this.onSelectConcept(e)}
                            title="Add concept to current selections, so that you can exclude or blocklist them collectively">Add to selection</a></p>
                        <hr />
                        <span className="infoText">Exclude concept</span>*/}
                        <p><a onClick={(e) => {
                            const ocid = concept ? concept.ocid : null;
                            if (!!ocid) {
                                const exclude = {};
                                exclude[ocid] = {
                                    label: concept.label,
                                    prefname: concept.prefname
                                }
                                this.props.hideConceptOptions(e);
                                this.props.onExcludeConcepts(exclude);
                            }
                        }}
                            title="Block concept temporarily">Block</a></p>
                        <p><a onClick={(e) => {
                            const ocid = concept ? concept.ocid : null;
                            if (!!ocid) {
                                const exclude = {};
                                exclude[ocid] = {
                                    label: concept.label,
                                    prefname: concept.prefname
                                }
                                this.props.hideConceptOptions(e);
                                this.props.onAddToBlacklists(exclude);
                            }
                        }}
                            title="Add concept to blocklist">Add to blocklist</a></p>

                    </div> : 'No options available'
                }
            </React.Fragment>
        );
    }
}

export default ConceptOptions;