/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component, createRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { stopEventPropagation, addThousandsSeparatorToNumber } from '../util';
import ConceptOptions from './ConceptOptions';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import ToastContent from '../../common/CustomToast/ToastContent';
import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'
import ConceptDetailsDialog from '../conceptdetails/ConceptDetailsDialog';

class CorrelationsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedResultTerms: {},
            selectedEntries: {},
            clickedEntry: null,
            displayCustomToast: false
        }

        // --- bind table cell templates --- //
        this.conceptTemplate = this.conceptTemplate.bind(this);
        //this.sentencesTemplate = this.sentencesTemplate.bind(this);
        this.toast = createRef();
    }

    componentDidMount() {
        //console.log('componentDidMount: ', this.props);

        if (this.props.correlationPartner && this.props.selectedConceptsOCIDs) {
            let selectedConcepts = [];
            //console.log('this.props.selectedConceptsOCIDs: ', this.props.selectedConceptsOCIDs);
            //console.log('selectedPairsMap: ', selectedPairsMap);

            const correlationPartner = this.props.correlationPartner;
            const resultTerms = correlationPartner && correlationPartner.resultData && correlationPartner.resultData.resultTerms ? correlationPartner.resultData.resultTerms : [];
            selectedConcepts = resultTerms.filter(term => {
                return !!this.props.selectedConceptsOCIDs[term.ocid];
            });

            this.setState({
                selectedConcepts: selectedConcepts
            });
        }
        /**/
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        //console.log('UNSAFE_componentWillReceiveProps: ', nextProps);

        if (this.props.selectedConceptsOCIDs !== nextProps.selectedConceptsOCIDs ||
            this.props.correlationPartner !== nextProps.correlationPartner) {

            if (nextProps.correlationPartner && nextProps.selectedConceptsOCIDs) {
                let selectedConcepts = [];
                //console.log('this.props.selectedConceptsOCIDs: ', nextProps.selectedConceptsOCIDs);
                //console.log('selectedPairsMap: ', selectedPairsMap);

                const correlationPartner = nextProps.correlationPartner;
                const resultTerms = correlationPartner && correlationPartner.resultData && correlationPartner.resultData.resultTerms ? correlationPartner.resultData.resultTerms : [];
                selectedConcepts = resultTerms.filter(term => {
                    return !!nextProps.selectedConceptsOCIDs[term.ocid];
                });

                //console.log('set selectedConcepts: ', selectedConcepts);
                this.setState({
                    selectedConcepts: selectedConcepts
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.correlationPartner !== prevProps.correlationPartner) {
            this.setState({
                selectedEntries: {}
            });
        }
    }

    onRowSelect = (concepts) => {

        //console.log('concepts: ', concepts);

        this.setState({
            selectedConcepts: concepts
        });

        if (concepts.length > 0) {

            const { queryTermsOtherPartner, isLeft, onLoadSentences, onSelectConcept } = this.props;

            const concept = concepts.pop();
            //console.log('concept pop: ', concept);
            const queryOcidsOther = [];
            if (queryTermsOtherPartner) {
                queryTermsOtherPartner.forEach(term => {
                    if (term.ocids) {
                        queryOcidsOther.push(...term.ocids);
                    }
                });
            }

            if (!!isLeft) {
                onSelectConcept(concept, isLeft, queryOcidsOther);
                onLoadSentences([concept.ocid], queryOcidsOther, concept.label, null);
            }
            else {
                onSelectConcept(concept, isLeft, queryOcidsOther);
                onLoadSentences(queryOcidsOther, [concept.ocid], null, concept.label);
            }
        }
    }

    onLoadSentencesForConcept = (ocidsLeft, ocidsRight, labelLeft, labelRight, concept) => {

        this.setState({
            selectedConcepts: [concept]
        });
        // this.props.isLeft or concept.isLeft???
        const ocidsOther = this.props.isLeft ? ocidsRight : ocidsLeft;
        this.props.onSelectConcept(concept, this.props.isLeft, ocidsOther);
        this.props.onLoadSentences(ocidsLeft, ocidsRight, labelLeft, labelRight);
    }

    // --- concept options --- //
    updateSelectedConcepts = (selectedConcepts) => {
        this.setState({
            selectedEntries: selectedConcepts
        });
    }

    onShowConceptDetails = () => {
        this.setState({
            visibleConceptInfo: true
        });
    }

    hideConceptOptions = (event) => {
        this.op.hide(event);
    }

    showConceptInfo = (ocid) => {

        const clickedEntry = { ocid: ocid };
        this.setState({
            visibleConceptInfo: true,
            clickedEntry: clickedEntry
        })
    }

    sendConceptTo = (concept) => {

        const sendEntry = {

        };
        this.setState({
            sendEntry: sendEntry
        });
    }

    onResultTermSelect = (resultTerm, event) => {

        const selectedResultTerms = { ...this.state.selectedResultTerms };
        if (selectedResultTerms[resultTerm.ocid]) {
            delete selectedResultTerms[resultTerm.ocid];
        }
        else {
            selectedResultTerms[resultTerm.ocid] = resultTerm;
        }

        // --- update content of toast for current result term selections --- //
        this.updateOptionsToast(selectedResultTerms);

        // --- update state with selected result terms and show options toast if neccessary --- //
        this.setState({
            selectedResultTerms: selectedResultTerms
        });
    }

    // ----------------------------------------------------------------------- //
    // --- options for selected result terms --------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Updates toast content with options for current selection of documents.
     */
    updateOptionsToast = (selectedResultTerms) => {

        const numOfSelectedResultTerms = selectedResultTerms ? Object.keys(selectedResultTerms).length : 0;

        if (numOfSelectedResultTerms === 0) {
            this.setState({
                displayCustomToast: false
            })
        } else if (numOfSelectedResultTerms !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
    }

    /**
     * Closes toast and clears selected result terms.
     */
    rejectSelectedResultTerms = () => {
        this.setState({
            selectedResultTerms: {},
            displayCustomToast: false
        });
    }


    /**
     * Table cell templates.
     */
    conceptTemplate(rowData, column) {

        const resultTerm = {
            ocid: rowData.ocid,
            label: rowData.label,
            prefname: rowData.name,
            domain: rowData.domain,
            isLeft: this.props.isLeft
        };

        return (
            <div title="Load sentences containing correlations including synonyms, but without ontological children">
                <div style={{ display: 'inline-block', padding: 0 }}
                    className="col coocResultTerm clampingContainer line-clamp line-clamp-1-line">
                    <a onClick={(e) => { stopEventPropagation(e); }}>
                        <Checkbox
                            title={`Click to select '${rowData.name}'`}
                            className="showOnHover1"
                            style={{ marginRight: 10 }}
                            onChange={e => {
                                this.onResultTermSelect(resultTerm, e);
                            }}
                            checked={!!this.state.selectedResultTerms[resultTerm.ocid]}>
                        </Checkbox>
                    </a>
                    <a onClick={(e) => {
                        stopEventPropagation(e);
                        this.setState({ clickedEntry: resultTerm });
                        this.op.toggle(e);
                    }}>
                        <span title={`Click for options | '${rowData.name}'`}>{rowData.label}</span>
                    </a>
                </div>
            </div>
        );
    }

    weightTemplate(rowData) {
        return (
            <div title={addThousandsSeparatorToNumber(rowData.weight)}>
                {addThousandsSeparatorToNumber(rowData.weight)}
            </div>
        );
    }


    onPaging = (event, fromPaginator) => {
        this.props.onNumOfRowsChange(fromPaginator ? event.first : this.props.first, fromPaginator ? event.rows : event.value);
    }

    render() {
        //console.log('children: ', this.props.sentences);

        const { correlationPartner, queryTermsLabel, queryTermsLabelOtherPartner } = this.props;

        const resultTerms = correlationPartner && correlationPartner.resultData && correlationPartner.resultData.resultTerms ? correlationPartner.resultData.resultTerms : [];
        const totalRecords = resultTerms.length;
        const showMentions = correlationPartner.hasPatentCount || correlationPartner.hasClaimCount || correlationPartner.hasCTCount;
        const showOtherInfo = correlationPartner.hasGOTerms;

        const headerGroup = <ColumnGroup>
            <Row>
                <Column header={`Correlations of  ${queryTermsLabelOtherPartner} with`} colSpan={2} />
                {/*<Column header="Matches (without children)" colSpan={1} />*/}
                {showMentions && <Column header="Mentions" colSpan={2} style={{}} />}
                {showOtherInfo && <Column header="Other info" colSpan={1} style={{}} />}
            </Row>
            <Row>
                {/* entity */}
                <Column field="name" header={queryTermsLabel} sortable sortField="name" filter filterField="name" />
                {/* cooc count */}
                <Column field="weight" header="Count" sortable />
                {/* sentences */}
                {/*<Column header="Sentences" />*/}
                {/* gene only */}
                {correlationPartner.hasPatentCount && <Column field="patentOccurrences" header="In patents" sortable />}
                {correlationPartner.hasClaimCount && <Column field="claimOccurrences" header="In claims" sortable style={{}} />}
                {correlationPartner.hasCTCount && <Column field="ctOccurrences" header="In clinical trials" sortable />}
                {correlationPartner.hasGOTerms && <Column field="goTerms" header="GO terms" sortable />}
            </Row>
        </ColumnGroup>;

        const columns = [
            <Column key="name" field="name" header={queryTermsLabel} body={this.conceptTemplate} sortable={true} sortField="name" filter={true} filterField="name" />,
            <Column key="weight" field="weight" header="Correlations" body={this.weightTemplate} style={{ width: "140px" }} sortable={true} />,
        ];
        /*<Column key="sentences" field="sentences" header="Sentences" body={this.sentencesTemplate} style={{ width: "110px" }} />*/ 
        if (correlationPartner.hasPatentCount) {
            columns.push(<Column key="patentOccurrences" field="patentOccurrences" />);
        }
        if (correlationPartner.hasClaimCount) {
            columns.push(<Column key="claimOccurrences" field="claimOccurrences" />);
        }
        if (correlationPartner.hasCTCount) {
            columns.push(<Column key="ctOccurrences" field="ctOccurrences" />);
            columns.push(<Column key="value3" field="value3" />);
        }

        return (
            <>
                <Toast
                    ref={this.toast}
                    className="toast"
                    style={{ minWidth: 500 }}
                    position="bottom-center"
                    closable="true"
                />

                <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                    onHide={this.rejectSelectedResultTerms} style={{ width: '30rem', bottom: 30 }}
                    draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                    <ToastContent selectedElements={this.state.selectedResultTerms}
                        elementLabel='concept'
                        onHide={this.rejectSelectedResultTerms}
                        activeOptions={['blockConcept', 'addToBlocklist']}
                        onBlock={() => this.props.onExcludeConcepts(this.state.selectedResultTerms)}
                        onAddToBlocklist={() => this.props.onAddToBlacklists(this.state.selectedResultTerms)} />
                </Dialog>

                <div className="grid">
                    <div className="col-12">
                        <DataTable
                            className="dataTable highlightSelectedRows breakWord increasedLineHeight standardTableHeader textAlignLeft"
                            sortField="weight"
                            sortOrder={-1}
                            value={resultTerms}
                            headerColumnGroup={headerGroup}
                            autoLayout={true}
                            paginator={true}
                            paginatorPosition="bottom"
                            first={this.props.first}
                            rows={this.props.rows}
                            onPage={(e) => this.onPaging(e, true)}
                            rowsPerPageOptions={[5, 15, 25, 50, 100, 500]}
                            totalRecords={totalRecords}
                            selectionMode="multiple"
                            selection={this.state.selectedConcepts}
                            onSelectionChange={e => this.onRowSelect(e.value)}
                            dataKey="ocid"
                            //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            //currentPageReportTemplate="{totalRecords} entries"
                            paginatorTemplate={CustomPaginatorTemplate}
                            paginatorLeft={<CustomPaginatorLeftSide
                                first={this.props.first}
                                contentLength={resultTerms.length}
                                rows={this.props.rows}>
                            </CustomPaginatorLeftSide>}
                            paginatorRight={<CustomPaginatorRightSide
                                rows={this.props.rows}
                                onChange={(e) => this.onPaging(e, false)}
                            ></CustomPaginatorRightSide>}
                        >
                            {columns}
                        </DataTable>
                    </div>
                </div>

                <OverlayPanel ref={(el) => this.op = el}
                    className="textAlignLeft"
                    style={{ marginTop: 25, maxWidth: 600 }}>
                    <ConceptOptions
                        concept={this.state.clickedEntry}
                        queryTermsLeft={this.props.queryTermsLeft}
                        queryTermsRight={this.props.queryTermsRight}
                        onLoadSentences={this.onLoadSentencesForConcept}
                        selectedConcepts={this.state.selectedEntries}
                        updateSelectedConcepts={this.updateSelectedConcepts}
                        onShowConceptDetails={this.onShowConceptDetails}
                        onReplaceQueryTerms={this.props.onReplaceQueryTerms}
                        hideConceptOptions={this.hideConceptOptions}

                        onExcludeConcepts={this.props.onExcludeConcepts}
                        onAddToBlacklists={this.props.onAddToBlacklists}
                        tempExcludedTerms={this.props.tempExcludedTerms}

                        queryTermsLabelLeft={this.props.isLeft ? this.props.queryTermsLabel : this.props.queryTermsLabelOtherPartner}
                        queryTermsLabelRight={this.props.isLeft ? this.props.queryTermsLabelOtherPartner : this.props.queryTermsLabel}
                        resultTermListLeft={this.props.resultTermListLeft}
                        resultTermListRight={this.props.resultTermListRight}
                    />
                </OverlayPanel>

                <ConceptDetailsDialog
                    visible={this.state.visibleConceptInfo}
                    includeQuickSearchShortcut={true}
                    includeChemSearchShortcut={true}
                    includeCoocSearchShortcut={false}
                    ocid={this.state.clickedEntry?.ocid}
                    onConceptClick={this.showConceptInfo}
                    onHide={() => this.setState({ visibleConceptInfo: false })}
                />
            </>
        );
    }
}

export default CorrelationsList;