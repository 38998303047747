const addFieldNameToNode = (treeNode, fieldName) => {
    const newTree = {...treeNode};
    newTree.fieldName = fieldName;

    if(newTree.children) {
        newTree.children = newTree.children.map(child => addFieldNameToNode(child, fieldName));
    }

    return newTree;
}

export const addFieldNameToTree = (treeNode, fieldName) => {
   const newTree = addFieldNameToNode(treeNode, fieldName);

    return [newTree];
}
