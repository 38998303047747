import { REPOSITORY_INFO_NOT_AVAILABLE, SORT_FIELDS_BLOCKLIST, getFilterDefinitionForRepository } from "../../../properties";
import { DEFAULT_SORT_FACTOR, SORT_FIELD_RELEVANCE_AND_DATE, PREFIX_DOMAIN_FACET, SORT_FIELD_RELEVANCE, SORT_MODES, SORT_MODE_DESCENDING, SORT_FIELD_PUBDATE, SORT_FIELD_STARTDATE, SORT_FIELD_INSTIME, SEARCH_FILTER_ID_GROUP_BY_PAT_FAM } from "../general/docsearch/searchConstants";
import { isArrayEmpty, sortObjectMapByAttribute } from "../util";

/**
 * Determines repository which should be active. Basis are query results, i.e. a map of 
 * repositories and the number of results. If there already is a valid active repository 
 * then it will be returned if its number of results are > 0.
 * Otherwise the repositories will be sorted by priority and the first one with results > 0
 * will be returned.
 * @param {Object} repositoryResults 
 * @param {Object} currActiveRepository current active repository
 * @param {string} queryCountAttribute "all" (default) or "cust" (dashboard)
 * @returns new active repository with results > 0
 * @deprecated use determineActiveRepositoryV2 instead
 */
export const determineActiveRepository = (repositoryResults, currActiveRepository = REPOSITORY_INFO_NOT_AVAILABLE, queryCountAttribute = 'all') => {

    let newActiveRepository = currActiveRepository;
    let activateFirstRepoWithResults = false;

    if (repositoryResults && !isArrayEmpty(Object.values(repositoryResults))) {
        // --- check currently active repository first (if it is valid) --- //
        if (currActiveRepository && !currActiveRepository.unavailable) {
            for (let rep of Object.values(repositoryResults)) {
                // --- user selected repo has no results --- //
                if (rep.repositoryId === currActiveRepository.id && rep.qryCounts[queryCountAttribute] <= 0) {
                    activateFirstRepoWithResults = true;
                    break;
                }
            }
        }
        else {
            activateFirstRepoWithResults = true;
        }
        // --- user selected repository is undefined or has no results  --- //
        // --- -> use first one with results (sorted by priority) --------- //
        if (activateFirstRepoWithResults) {
            const reposSorted = sortObjectMapByAttribute(repositoryResults, 'repositoryOrderPriority', false);
            // --- set first repository with results as active --- //
            for (let rep of reposSorted) {
                if (rep.qryCounts[queryCountAttribute] > 0) {
                    newActiveRepository = { ...rep };
                    newActiveRepository.id = newActiveRepository.repositoryId;
                    break;
                }
            }
        }
    }

    return newActiveRepository;
}

/**
 * Determines repository which should be active. 
 * If there already is a valid active repository then it will be returned.
 * Otherwise the first repository from the list of repositories will be returned.
 * @param {Array} repositories all allowed repositories, must be sorted by order priority
 * @param {Object} currActiveRepository current active repository
 * @returns new active repository
 */
export const determineActiveRepositoryV2 = (repositories, currActiveRepository = REPOSITORY_INFO_NOT_AVAILABLE) => {

    let newActiveRepository = currActiveRepository;
    if (!currActiveRepository || currActiveRepository.unavailable) {
        if (!isArrayEmpty(repositories)) {
            newActiveRepository = repositories[0];
        }
    }

    return newActiveRepository;
}

/**
 * Determines sorting criteria which should be used for a repository's given criteria
 * and a given list of prioritized sort fields.
 * @param {Array} sortFields all available sort fields
 * @param {Array} sortingOrderPrio a list of field names in order of priority
 * @param {Object} defaultSortCriteria if no prioritized criteria matches any of the available fields, a default criteria can be returned
 * @returns
 */
export const determineActiveSortCriteria = (sortFields, defaultSortingOptions = [], defaultSortCriteria) => {
    for (let defSortField of defaultSortingOptions) {
        const sortOption = sortFields.find(option => option.value === defSortField);
        if (sortOption) {
            return sortOption;
        }
    }
    return defaultSortCriteria;
}


/**
 * Returns patent family grouping information for certain repository.
 * @param {Object} filterDefinitions filter definitions
 * @param {Object} repositoryFilterMap filter map
 * @param {Object} repository the active repository
 * @returns 
 */
export const determineActivePatentFamilyGrouping = (filterDefinitions, repositoryFilterMap, repository) => {
    // const activeRepoFilters = repositoryFilterMap[repository?.name];
    const activeRepoFilters = getFilterDefinitionForRepository(repositoryFilterMap, repository?.name);
    const isPatFamGroupingAllowed = activeRepoFilters ? activeRepoFilters.includes(SEARCH_FILTER_ID_GROUP_BY_PAT_FAM) : false;
    let patFamGroupingOptions = null, patFamGroupingValue = null;
    if (isPatFamGroupingAllowed) {
        patFamGroupingOptions = filterDefinitions[SEARCH_FILTER_ID_GROUP_BY_PAT_FAM]?.filterValues;
        patFamGroupingOptions = patFamGroupingOptions?.filter(val => {
            return isArrayEmpty(val.repoAllowList) || val.repoAllowList.includes(repository?.name)
        });
        patFamGroupingValue = patFamGroupingOptions?.find(val => val.isDefault);
    }
    return { isPatFamGroupingAllowed, patFamGroupingOptions, patFamGroupingValue };
}


// @todo clean up
/**
 * Creates list of sorting options for a given repository and its schema sorting info
 * @param {*} sortFields 
 * @param {*} repositoryInfo 
 * @param {*} addRelevance 
 * @param {*} addNewAndRelevant 
 * @returns 
 */
export const createSortFieldsList = (sortFields, repositoryInfo, addRelevance = true, addNewAndRelevant = true) => {

    let dropdownFields = [];
    let dropdownFieldsFiltered = []
    let hasPubdate;
    let hasStartDate;
    let uniq = {};

    if (sortFields) {
        Object.keys(sortFields).forEach(field => {
            const val = sortFields[field].names[0];

            hasPubdate = hasPubdate || val === SORT_FIELD_PUBDATE;
            hasStartDate = hasStartDate || val === SORT_FIELD_STARTDATE;

            if (!SORT_FIELDS_BLOCKLIST[repositoryInfo.name] || !SORT_FIELDS_BLOCKLIST[repositoryInfo.name].hasOwnProperty(val)) {
                if (sortFields[field].names[0] !== 'revTime') {
                    dropdownFields.push({
                        value: val,
                        label: sortFields[field].names.length > 2 ? sortFields[field].names[2] : sortFields[field].names[1],
                        sortField: val,
                        sortMode: sortFields[field].defaultDirection,
                        sortModes: SORT_MODES,
                    });
                }
            }
        })
    }

    dropdownFieldsFiltered = dropdownFields.filter(obj => !uniq[obj.label] && (uniq[obj.label] = true));

    if (addRelevance) {
        dropdownFieldsFiltered.push({
            label: 'Relevance',
            value: SORT_FIELD_RELEVANCE,
            sortField: SORT_FIELD_RELEVANCE,
            sortModes: SORT_MODES?.filter(sm => sm.value === SORT_MODE_DESCENDING),
            sortMode: SORT_MODE_DESCENDING,
            disableSortModes: true,
            useRankedQuery: true,
        });
    }
    if (addNewAndRelevant) {
        dropdownFieldsFiltered.unshift({
            label: 'Relevance and date',
            value: SORT_FIELD_RELEVANCE_AND_DATE,
            sortField: hasPubdate ? SORT_FIELD_PUBDATE : hasStartDate ? SORT_FIELD_STARTDATE : SORT_FIELD_INSTIME,
            sortMode: SORT_MODE_DESCENDING,
            sortModes: SORT_MODES?.filter(sm => sm.value === SORT_MODE_DESCENDING),
            sortFactor: DEFAULT_SORT_FACTOR,
            disableSortModes: true,
            useRankedQuery: true,
        });
    }

    return dropdownFieldsFiltered;
}

/**
 * Determines domain facets which are available to the current user.
 * @param {*} domainFacets 
 * @param {*} domainLabelsMap 
 * @returns 
 */
export const determineActiveDomainFacets = (domainFacets, domainLabelsMap) => {
    const availDomainFacets = domainFacets?.filter(domFacet => {
        return !!domainLabelsMap[domFacet.replace(PREFIX_DOMAIN_FACET, '')];
    });
    return availDomainFacets;
}

/**
 * Determines if query is default query, the ranked default query in case it should be used, 
 * as well as the sort factor.
 * @param {*} defaultSettings 
 * @param {*} query 
 * @param {*} patentFamilyGrouping 
 * @param {*} sortCriteria 
 * @returns 
 */
export const determineActiveQuerySettings = (defaultSettings, query, patentFamilyGrouping, sortCriteria) => {
    let queryString = query;
    const isDefaultQuery = query === defaultSettings.query;
    if (isDefaultQuery) {
        queryString = (sortCriteria?.useRankedQuery) ? defaultSettings.rankedQuery : defaultSettings.query;
    }

    if (patentFamilyGrouping?.filterValue !== 'none' && patentFamilyGrouping?.filterValue && patentFamilyGrouping?.booster) {
        queryString = `+(${queryString}) +groupby:"${patentFamilyGrouping.filterValue}"^${patentFamilyGrouping.booster}`;
    }

    return { isDefaultQuery, queryString };
}