import React, { Component } from 'react';
import * as PhraseTokenUtil from '../../util/PhraseTokenUtil';

class PhraseTokenDisplay extends Component {

    render() {

        const { phraseTokens, selectedTokens, onTokenClick, onTokenDoubleClick, onTokenRightClick, showProperties,
            onGroupClick, onGroupDoubleClick, onGroupRightClick, showAdditionalData, allowRightClickMenu, editable } = this.props;

        let groupIndex = this.props.groupIndex + 1;

        //console.log('phraseTokens*: ', phraseTokens);

        return (
            <>
                {phraseTokens ?
                    Object.keys(phraseTokens).map(index => {
                        const token = phraseTokens[index];
                        const isSelToken = selectedTokens[token.key];

                        // --- texts as string --- //
                        const text = token.text && token.text.length > 0 ? token.text.join(' / ') : null;

                        // --- all types as string --- //
                        const typesStrg = token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE] ? Object.values(token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE]).map(type => {
                            let typeStrg = type.type === 'RELATION' ? 'SYNSET' : type.type;
                            typeStrg += type.subType ? ' > ' + type.subType : '';
                            typeStrg += type.subSubType ? ' > ' + type.subSubType : '';
                            return typeStrg;
                        }).join(' / ') : 'undef';

                        // --- get first type -> remove everything after first blank --- //
                        let firstType = typesStrg.replace(/ .*/g, '');

                        const css = token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE] && Object.keys(token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE]).length > 1 ? 'mult' : firstType;

                        // --- generate attributes string --- //
                        const hasAttributes = token.attr && Object.keys(token.attr).length > 0;
                        let attrStrg = hasAttributes ? Object.keys(token.attr).map(key => {
                            const val = token.attr[key];

                            if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT_POSSESSIVE) {
                                return 'is optional+';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OPT) {
                                return 'is optional';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ALTERNATIVE) {
                                return 'alternatives';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_VARIABLE) {
                                return 'variable';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_FIRST) {
                                return 'is first';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_LAST) {
                                return 'is last';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_NO_MATCH) {
                                return 'is no match';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_REQUIRED) {
                                return 'is required';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_UNIQUE) {
                                return 'is unique';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_DEFAULT) {
                                return 'is default';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_SUBJECT) {
                                return 'is subject';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_OBJECT) {
                                return 'is object';
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_TARGET) {
                                return `target=${val}`;
                            }
                            else if (key === PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_INFLECTION) {
                                return `inflection=${val}`;
                            }
                            else {
                                return key + '=' + val
                            }
                        }).join(' | ') : '';


                        if (token.ocid && token.ocid.length > 0) {
                            //attrStrg += hasAttributes ? ' | ocids=' + token.ocid : 'ocids=' + token.ocid;
                            attrStrg += attrStrg.length > 0 ? ' | allow=' : 'allow=';
                            if (token.ocid.length <= 3) {
                                attrStrg += token.ocid.join(',');
                            }
                            else {
                                const more = token.ocid.length - 3;
                                attrStrg += token.ocid.slice(0, 3).join(',') + `...(${more}more)`;
                            }
                        }
                        if (token.negOcid && token.negOcid.length > 0) {
                            //attrStrg += hasAttributes ? ' | !ocids=' + token.negOcid : '!ocids=' + token.negOcid;
                            attrStrg += attrStrg.length > 0 ? ' | block=' : 'block=';
                            if (token.negOcid.length <= 3) {
                                attrStrg += token.negOcid.join(',');
                            }
                            else {
                                const more = token.negOcid.length - 3;
                                attrStrg += token.negOcid.slice(0, 3).join(',') + `...(${more}more)`;
                            }
                        }
                        /**/

                        // --- tooltip text --- //
                        /*
                        let title = `types=${typesStrg}` +
                            `${text ? ' | text=' + text : ''}` +
                            `${attrStrg ? ' | ' + attrStrg : ''}`;
                            */

                        return (
                            <React.Fragment key={token.key}>
                                {typesStrg === 'GROUP' ?
                                    <div className={`token type-${typesStrg} ${editable ? '' : 'no-edit'} ${isSelToken ? 'selected' : ''}`}
                                        onClick={(e) => { if (onGroupClick) onGroupClick(token, e) }}
                                        onDoubleClick={(e) => { if (onGroupDoubleClick) onGroupDoubleClick(token, e) }}
                                        onContextMenu={(e) => { if (onGroupRightClick) onGroupRightClick(token, e) }} >
                                        <div style={{ display: 'inline' }} >
                                            {showAdditionalData ?
                                                <div style={{ fontSize: '0.8em', opacity: attrStrg ? 0.8 : 0.0, wordBreak: 'break-word' }}>
                                                    {attrStrg ? attrStrg : '-'}
                                                </div> : null
                                            }
                                            <div style={{ display: 'inline', paddingRight: 5 }}>
                                                {/*groupTypeLabel ? groupTypeLabel : typesStrg*/}
                                            </div>
                                        </div>
                                        <PhraseTokenDisplay
                                            phraseTokens={token.groupMembers}
                                            selectedTokens={selectedTokens}
                                            onTokenClick={onTokenClick}
                                            onTokenDoubleClick={onTokenDoubleClick}
                                            onTokenRightClick={onTokenRightClick}
                                            onGroupClick={onGroupClick}
                                            onGroupDoubleClick={onGroupDoubleClick}
                                            onGroupRightClick={onGroupRightClick}
                                            allowRightClickMenu={allowRightClickMenu}
                                            showAdditionalData={showAdditionalData}
                                            showProperties={showProperties}
                                            groupIndex={groupIndex}
                                            editable={editable}
                                        />
                                    </div>
                                    :
                                    <div className={`token type-${css} ${editable ? '' : 'no-edit'} ${isSelToken ? 'selected' : ''}`}
                                        //title={title}
                                        onClick={(e) => { if (onTokenClick) onTokenClick(token, e) }}
                                        onDoubleClick={(e) => { if (onTokenDoubleClick) onTokenDoubleClick(token, e) }}
                                        onContextMenu={(e) => { if (onTokenRightClick) onTokenRightClick(token, e) }}
                                    >
                                        {showProperties ?
                                            <div style={{ fontSize: '0.8em', opacity: attrStrg ? 0.8 : 0.0, wordBreak: 'break-word' }}>
                                                {attrStrg ? attrStrg : '-'}
                                            </div> : null
                                        }
                                        <div>
                                            {showAdditionalData ? typesStrg : null}
                                            {showAdditionalData && text ? ': ' + text : text ? text : ''}{/*<span style={{ fontWeight: 'bold' }}></span>*/}
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })
                    : null
                }
            </>
        );
    }
}

export default PhraseTokenDisplay;