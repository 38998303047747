import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableRowData from "../../common/TableData/TableRowData";
import TruncDataList from "../../common/ListData/TruncDataList";
import { getDayAndMonthFromDate, getYearFromDate, isArrayEmpty, shortenLargeNumber } from "../util";
import ConceptDetailsDialog from "../conceptdetails/ConceptDetailsDialog";
import CellData from "../general/tabledata/CellData";
import ExternalLinkIcon from "../general/ExternalLinkIcon/ExternalLinkIcon";
import { createDOILink, createPubmedLink } from "../util/externalLinks";
import SeparatorPoint from "../general/SeparatorPoint";

// TODO

const BIB_DATA = [
    { id: 'ocDocID', label: 'Internal document ID' },
    { id: 'Pat_document-id', label: 'Patent publication No.' },
    { id: 'oai', label: 'OAI ID' },
    { id: 'doi', label: 'DOI' }, // , ignoreForRepos: ['dspub']
    { id: 'pmc', label: 'PMCID' },
    { id: 'pmid', label: 'PMID', asPlainText: true },
    { id: 'pii', label: 'Publisher item identifier' },
    { id: 'issn', label: 'ISSN' },
    { id: 'eissn', label: 'eISSN' },
    { id: 'trialid', label: 'Trial ID' },
    { id: 'Pat_assignees', label: 'Assignees' },
    //{ id: 'Pat_assignees_normalized', label: 'Assignees' },
    { id: 'Pat_assignees_parent_company', label: 'Assignees parent company' },
    { id: 'Pat_inventors', label: 'Inventors' },
    { id: 'Pat_families-inpadoc', label: 'INPADOC family' },
    { id: 'Pat_oc-family', label: 'OC patent family' },
    { id: 'Pat_classifications-cpc', label: 'CPC' },
    { id: 'Pat_classifications-ipc', label: 'IPC' },
    { id: 'Pat_classifications-national', label: 'NCL' },
    { id: 'Pat_country', label: 'Patent office' },
    { id: 'Pat_priority-claims', label: 'Priority claims' },
    { id: 'Pat_priority-claim_doc-number', label: 'Priority claim document No.' },
    { id: 'Pat_application-reference', label: 'Application reference' },
    { id: 'Pat_priority-claim_date', label: 'Oldest priority date' },
    { id: 'Pat_filing_date', label: 'Filing date' },
    { id: 'Pat_application_date', label: 'Application date' },
    { id: 'Pat_application_publication_date', label: 'Application publication date' },
    { id: 'Pat_publication_date', label: 'Publication date', divID: 'publicationDate' },
    //
    { id: 'authors.json', label: 'Authors', ignoreForRepos: ['dspub', 'dsm_labdocs'] },
    { id: 'authors', label: 'Authors', ignoreForRepos: ['dspub', 'dsm_labdocs'] }, // patents???
    { id: 'authors_aff', label: "Affiliations", ignoreForRepos: ['dspub', 'dsm_labdocs'] },
    //
    //{ id: 'investigators.json', label: 'Investigators', allowForRepos: ['ctcoll', 'ctciteline'] },
    { id: 'investigators', label: 'Investigators', allowForRepos: ['ctcoll', 'ctciteline'] },
    { id: 'investigators_aff', label: 'Affiliations', allowForRepos: ['ctcoll', 'ctciteline'] },
    //
    { id: 'laboratory', label: 'Laboratory head' },
    { id: 'technician', label: 'Technician' },
    // --- TODO: Journal, volume, issue, pages --- //
    { id: 'journal', label: 'Journal' },
    { id: 'publisher', label: 'Publisher' }, //, ignoreForRepos: ['dspub']
    { id: 'pubType', label: 'Publication type', ignoreForRepos: ['dspub'] },
    //{ id: 'openaccess_categories', label: 'Open access' },
    { id: 'pubdate', label: 'Publication date' },
    { id: 'ppubdate', label: 'Print publication date' },
    { id: 'insertDate', label: 'Insertion date' },
    { id: 'lastRevisionDate', label: 'Last revision date' },   
    { id: 'source', label: 'Source' },
    { id: 'startdate', label: 'Start date' },
    { id: 'enddate', label: 'End date' },
    { id: 'clinicalphase', label: 'Phases' },
    { id: 'status', label: 'Status' },
    { id: 'studytype', label: 'Study type' },
    { id: 'diseases', label: 'Diseases' },
    { id: 'drugs', label: 'Drugs' },
    { id: 'sponsors', label: 'Sponsors' },
    { id: 'pmid', label: 'Publisher site' },
    { id: 'links', label: 'Links' },
    { id: 'extId', label: 'Original file path', allowForRepos: ['dsm_labdocs'] },
    { id: 'origFileRelPath', label: 'Original file path', allowForRepos: ['efsademo'] },
    { id: 'docType', label: 'Document type' },
    { id: 'fileSuffix', label: 'File suffix' },
    { id: 'textSize', label: 'Text size' },
    { id: 'compounds.json', label: 'Compounds' },
    { id: 'smarts', label: 'SMARTS' },
    { id: 'reactions', label: 'Reactions' },
    // drug labels 2
    { id: 'authoractivities', label: 'Author activities' },
    { id: 'activemoiety', label: 'Active moiety' },
    { id: 'productname', label: 'Product name' },
    { id: 'productcategory', label: 'Product category' },
    { id: 'ndccodes', label: 'National drug codes' },
]

class Bibliography extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {};
    }

    showConceptInfo = (ocid) => {
        this.setState({
            visibleConceptInfo: true,
            conceptOcid: ocid,
            activeIndex: 0
        })
    }

    templates = (repositoryName, bibData) => {

        const { docMetadata } = this.props;

        if (!isArrayEmpty(bibData.allowForRepos) && !bibData.allowForRepos.includes(repositoryName)) {
            return null;
        }
        else if (!isArrayEmpty(bibData.ignoreForRepos) && bibData.ignoreForRepos.includes(repositoryName)) {
            return null;
        }
        if (!docMetadata || !docMetadata[bibData.id]) {
            return null;
        }

        switch (bibData.id) {

            case 'doi':
                return this.renderListOfExternalLinks(bibData, docMetadata[bibData.id], createDOILink);

            case 'authors':
            case 'authors_aff':
                return !docMetadata['Pat_inventors'] && !docMetadata['authors.json'] ?
                    this.renderListOfStrings(bibData, docMetadata[bibData.id]) : null;

            case 'authors.json':
                if (!docMetadata['Pat_inventors']) {
                    if (repositoryName === 'dspub') {
                        const parsedAuthors = docMetadata[bibData.id].map(authorJson => {
                        try {
                                const parsedAuthor = JSON.parse(authorJson);
                                const affiliation = parsedAuthor.affiliations[0] || {};
                                const normAuthor = { name: `${parsedAuthor.first_name} ${parsedAuthor.last_name}` }
                                if (affiliation.affiliation) {
                                    normAuthor.affiliation = `${affiliation.affiliation}`;
                                }
                                return JSON.stringify(normAuthor);
                            } catch (e) {
                                return {};
                            }                      
                        });
                        return !docMetadata['Pat_inventors'] ?
                        this.renderListOfObjects(bibData, parsedAuthors, 'name', 'affiliation') : null;
                    }
                    else {
                        return !docMetadata['Pat_inventors'] ?
                        this.renderListOfObjects(bibData, docMetadata[bibData.id], 'name', 'affiliation') : null;
                    }     
                }  
                return null;        

            case 'investigators':
            case 'investigators_aff':
            // case 'ndccodes':
                //return !docMetadata['investigators.json'] ?
                //    this.renderListOfStrings(bibData, docMetadata[bibData.id]) : null;
                return this.renderListOfStrings(bibData, docMetadata[bibData.id]);

            //case 'investigators.json':
            //    return this.renderListOfObjects(bibData, docMetadata[bibData.id], 'investigator', 'affiliation');

            case 'Pat_assignees':
                const hasNormalizedAssignees = !isArrayEmpty(docMetadata['Pat_assignees_normalized']);
                const assignees = hasNormalizedAssignees ? docMetadata['Pat_assignees_normalized'] : docMetadata['Pat_assignees'];
                return this.renderListOfStrings(bibData, assignees, null, docMetadata['Pat_assignees'].join('; '));

            case 'Pat_publication_date':
                return this.renderListOfStrings(bibData, docMetadata[bibData.id], "publicationDate");

            case 'pubdate':
                return !docMetadata['Pat_publication_date'] ?
                    this.renderListOfStrings(bibData, docMetadata[bibData.id], "publicationDate") : null;

            case 'pmid':
                if (bibData.asPlainText) {
                    return this.renderListOfStrings(bibData, docMetadata[bibData.id]);
                }
                else {
                    return this.renderListOfExternalLinks(bibData, docMetadata[bibData.id], createPubmedLink);
                }

            case 'compounds.json':
                const compounds = JSON.parse(docMetadata[bibData.id]);

                return (
                    <TableRowData
                        label={bibData.label}
                        colSizeLeft="4"
                        colSizeRight="8"
                        content={
                            <TruncDataList
                                dataList={
                                    compounds.map(entry => {
                                        return (
                                            <React.Fragment key={entry.name + ':' + entry.ocid}>
                                                <CellData
                                                    cropContent={true}
                                                    allowCopy={true}
                                                    content={entry.name}
                                                    noPaddingLeft={false}
                                                    onContentClick={() => this.showConceptInfo(entry.ocid)}
                                                    contentTooltip={`Click for information about ${entry.name}`}
                                                    contentStyleClass={"secondaryLink"}
                                                />
                                            </React.Fragment>
                                        );
                                    })
                                }
                                totalNumber={compounds.length}
                                maxNumberToDisplay={compounds.length}
                                suffix="..." />
                        }
                    />
                );

            case 'links':
                const linksAllID = 'linksAll';
                const origFileID = 'origFileRelPath';
                return (
                    docMetadata['linksAll'] && docMetadata[linksAllID].length > 0 ?
                        this.renderListOfExternalLinks(bibData, docMetadata[linksAllID])
                        :
                        docMetadata[origFileID] && docMetadata[origFileID].length > 0 ?
                            this.renderListOfStrings(bibData, docMetadata[bibData.id])
                            : null
                );

            default:
                return this.renderListOfStrings(bibData, docMetadata[bibData.id]);
        }
    }


    renderListOfStrings = (bibData, metadata, divID = '', tooltip = '') => {
        if (bibData.id !== 'ocDocID' && metadata[0] !== -1) {
            return <TableRowData
                label={bibData.label}
                colSizeLeft="4"
                colSizeRight="8"
                content={
                    <div id={divID} title={tooltip}>
                        <TruncDataList
                            dataList={metadata.map(entry => {
                                return (
                                    <React.Fragment key={entry}>
                                        <CellData
                                            cropContent={true}
                                            allowCopy={true}
                                            content={entry}
                                            noPaddingLeft={false}
                                        />
                                    </React.Fragment>
                                )
                            })}
                            totalNumber={metadata.length}
                            maxNumberToDisplay={metadata.length}
                            suffix="..." />
                    </div>
                }
            />
        } else {
            return null
        }
    }

    renderListOfExternalLinks = (bibData, metadata, linkCreatorFunction, divID = '', tooltip = '') => {

        return <TableRowData
            label={bibData.label}
            colSizeLeft="4"
            colSizeRight="8"
            content={
                <div id={divID} title={tooltip}>
                    <TruncDataList
                        dataList={metadata.map(entry => {
                            const url = linkCreatorFunction ? linkCreatorFunction(entry) : entry;
                            return (
                                <React.Fragment key={entry}>
                                    <CellData
                                        cropContent={true}
                                        allowCopy={true}
                                        content={<>{url}<ExternalLinkIcon standardLinkIcon={true} /></>}
                                        copyContent={url}
                                        noPaddingLeft={false}
                                        onContentClick={() => window.open(url, '_blank')}
                                        contentTooltip={`Open external link`}
                                        contentStyleClass={"secondaryLink"}
                                    />
                                </React.Fragment>
                            )
                        })}
                        totalNumber={metadata.length}
                        maxNumberToDisplay={metadata.length}
                        suffix="..." />
                </div>
            }
        />
    }

    renderListOfObjects = (bibData, metadata, primaryField = 'name', secondaryField = 'affiliation', divID = '') => {

        return <TableRowData
            label={bibData.label}
            colSizeLeft="4"
            colSizeRight="8"
            content={
                <TruncDataList
                    dataList={
                        metadata.map(jsonData => {
                            const entry = JSON.parse(jsonData);
                            const primaryData = entry[primaryField] || '';
                            const secondaryData = entry[secondaryField] || '';
                            const content = <span>
                                <span>{primaryData}</span>
                                {secondaryData ? <>
                                    <SeparatorPoint />
                                    <span className="secondaryInfo">{secondaryData}</span>
                                </> : null
                                }
                            </span>
                            return (
                                <React.Fragment key={primaryData + ':' + secondaryData}>
                                    <CellData
                                        cropContent={true}
                                        allowCopy={true}
                                        content={content}
                                        copyContent={`${primaryData}${secondaryData ? ` (${secondaryData})` : ''}`}
                                        noPaddingLeft={false}
                                        contentStyleClass={"secondaryLink"}
                                    />
                                </React.Fragment>
                            );
                        })
                    }
                    totalNumber={metadata.length}
                    maxNumberToDisplay={metadata.length}
                    suffix="..." />
            }
        />;
    }


    // ----------------------------------------------------------------------- //
    // --- render different types of documents ------------------------------- //
    // ----------------------------------------------------------------------- //
    renderBibliography = (repositoryName) => {

        switch (repositoryName) {

            case 'dsgrants':
                return this.renderGrantsDS();

            //case 'dspub':
            //    return this.renderPublicationsDS();

            default:
                return this.renderDefault(repositoryName);
        }
    }

    renderGrantsDS = () => {

        const { docMetadata } = this.props;
        const metaData = docMetadata ? docMetadata : {};

        //console.log('metaData: ', metaData);

        return (
            <>
                {(!isArrayEmpty(metaData.funding_amount) && !isArrayEmpty(metaData.funding_currency)) ||
                    (!isArrayEmpty(metaData.startdate) && !isArrayEmpty(metaData.enddate)) ?
                    <>
                        {/* <h2 className="ocTextHeading_2" style={{ marginTop: 0 }}>Details</h2>*/}
                        {!isArrayEmpty(metaData.funding_amount) && !isArrayEmpty(metaData.funding_currency) ?
                            <>
                                <h4 className="ocTextHeading_4" style={{ marginTop: 0 }}>Funding amount</h4>
                                <div className="majorDetails" style={{ fontSize: 22 }}>{metaData.funding_currency[0]} {shortenLargeNumber(metaData.funding_amount[0])}</div>
                            </> : null
                        }

                        {!isArrayEmpty(metaData.startdate) && !isArrayEmpty(metaData.enddate) ?
                            <>
                                {!isArrayEmpty(metaData.funding_amount) && !isArrayEmpty(metaData.funding_currency) ? <br /> : null}
                                <h4 className="ocTextHeading_4">Funding period</h4>
                                <div className="majorDetails" style={{ fontSize: 22 }}>
                                    <div style={{ width: 55, display: 'inline-block' }}>{getYearFromDate(metaData.startdate[0])}</div>
                                    <div style={{ width: 15, display: 'inline-block' }}>-</div>
                                    <div style={{ width: 55, display: 'inline-block' }}>{getYearFromDate(metaData.enddate[0])}</div>
                                </div>
                                <div className="secondaryInfo" style={{ fontSize: 13 }}>
                                    <div style={{ width: 70, display: 'inline-block' }}>{getDayAndMonthFromDate(metaData.startdate[0])}</div>
                                    <div style={{ width: 55, display: 'inline-block' }}>{getDayAndMonthFromDate(metaData.enddate[0])}</div>
                                </div>
                            </> : null
                        }
                    </> : null}
            </>
        );
    }

    renderPublicationsDS = () => {

        return (
            <>
            </>
        );
    }

    renderDefault = (repositoryName) => {

        return (
            <div className="grid">
                {BIB_DATA.map(bibData => {
                    return <React.Fragment key={`${bibData.id}_${bibData.label}`}>
                        {this.templates(repositoryName, bibData)}
                    </React.Fragment>
                })}
            </div>
        );
    }

    render() {

        const { docMetadata, repositoryInfo } = this.props;

        return (
            <>
                {docMetadata ? this.renderBibliography(repositoryInfo.name) : null}

                <ConceptDetailsDialog
                    visible={this.state.visibleConceptInfo}
                    includeQuickSearchShortcut={true}
                    includeChemSearchShortcut={true}
                    includeCoocSearchShortcut={true}
                    ocid={this.state.conceptOcid}
                    onConceptClick={this.showConceptInfo}
                    onHide={() => this.setState({ visibleConceptInfo: false })}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.data,
})

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bibliography))
