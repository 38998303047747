/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { RadioButton } from 'primereact/radiobutton';
import moment from 'moment';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ConfirmationDialog from "../../common/ConfirmDialog/ConfirmationDialog";

const TODAY = new Date();
const MIN_DATE = new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() + 1);
const MIN_DATE_WEEK = new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() + 7);
const MIN_DATE_MONTH = new Date(TODAY.getFullYear(), TODAY.getMonth() + 1, TODAY.getDate());

class AddEditAlert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedInterval: 'WEEKLY',
            startTime: MIN_DATE_WEEK,
            activeOptions: [
                { label: 'Activate alert', value: 'active' },
                { label: 'Pause', value: 'paused' },
                //{ label: 'Remove', value: 'remove' }
            ],
            activeOption: 'active',
            removeChecked: false,
            showMore: false
        };
    }

    componentDidMount() {
        let selectedInterval = (this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit.notifications.length !== 0) ?
            this.props.selectedCollectionAddEdit.notifications[0].interval : 'WEEKLY'
        let startTime = (this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit.notifications.length !== 0) ?
            new Date(this.props.selectedCollectionAddEdit.notifications[0].nextSendingDate) : MIN_DATE_WEEK
        let activeOption = (this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit.notifications.length !== 0 &&
            !this.props.selectedCollectionAddEdit.notifications[0].active) ? 'paused' : 'active'
        this.setState({
            selectedInterval: selectedInterval,
            startTime: startTime,
            activeOption: activeOption
        })
    }

    onIntervalChange = (interval) => {
        this.setState({
            selectedInterval: interval,
            startTime: interval === 'WEEKLY' ? MIN_DATE_WEEK : interval === 'MONTHLY' ? MIN_DATE_MONTH : MIN_DATE
        })
    }

    handleTimeChange = (time) => {
        this.setState({
            startTime: time
        })
    }

    onStatusChange = (value) => {
        this.setState({
            activeOption: value.value,
            removeChecked: false
        })
    }

    onCheckRemove = (e) => {
        if (e.checked) {
            this.setState({
                removeChecked: true
            })
        } else {
            this.setState({
                removeChecked: false
            })
        }
    }

    onShowMore = () => {
        this.setState({
            showMore: true
        })
    }

    onShowLess = () => {
        this.setState({
            showMore: false
        })
    }

    onSaveClick = (action) => {
        if (action && action === 'remove') {
            this.props.callbackFromSave(this.props.selectedCollectionAddEdit, action, this.state.selectedInterval, this.state.startTime)
        } else {
            this.props.callbackFromSave(this.props.selectedCollectionAddEdit, this.state.activeOption, this.state.selectedInterval, this.state.startTime)
        }
    }

    onDeleteAlert = () => {
        this.props.onDeleteAlert()
    }

    onHide = () => {
        this.setState({
            selectedInterval: 'WEEKLY',
            startTime: MIN_DATE_WEEK,
            activeOption: 'active'
        }, () => {
            this.props.onHide()
        })
    }
    render() {

        let alertStatus = this.state.activeOptions && this.state.activeOptions.map(option =>
            <div key={option.label} style={{ marginBottom: 10 }}>
                <RadioButton
                    key={option.label}
                    inputId={option.label}
                    name="label"
                    value={option}
                    onChange={(e) => this.onStatusChange(e.value)}
                    checked={this.state.activeOption === option.value}
                    style={{
                        verticalAlign: 'unset', cursor: ((this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit?.notifications.length === 0) ||
                            this.props.selectedCollectionAddEdit?.notifications === undefined) && (option.value === 'paused' || option.value === 'remove') ? 'default' : 'pointer'
                    }}
                    disabled={((this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit?.notifications.length === 0) ||
                        this.props.selectedCollectionAddEdit?.notifications === undefined) && (option.value === 'paused' || option.value === 'remove') ? true : false}
                />
                <label htmlFor={option.label} title={`${option.label}`}
                    style={{
                        // textDecoration: 'underline',
                        paddingLeft: 5,
                        color: ((this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit.notifications.length === 0) ||
                            this.props.selectedCollectionAddEdit?.notifications === undefined) && (option.value === 'paused' || option.value === 'remove') ? '#757575' : 'black'
                    }}>{`${option.label}`}</label>
            </div>
        )

        return (
            <div>
                <Toast ref={(el) => this.growl = el} />
                <Dialog focusOnShow={false}
                    visible={this.props.displayAddEditAlert}
                    header={`${(this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit?.notifications.length !== 0 ? 'Edit alert' : 'Add alert')} for '${this.props.type !== 'search' ? this.props.selectedCollectionAddEdit?.name : this.props.selectedCollectionAddEdit?.fullName}'`}
                    breakpoints={{ '1665px': '50vw', '1335px': '60vw', '1115px': '70vw', '995px': '80vw', '835px': '90vw', '740px': '95vw' }}
                    style={{ width: '40vw' }}
                    modal={true}
                    dismissableMask={false}
                    closable={true}
                    //blockScroll
                    //footer={dialogFooter}
                    onHide={() => this.onHide()}
                    className='styledDialog'>
                    <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                        <label style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal', lineHeight: "20px" }}>{`Alerts inform you about changes on a
                        ${this.props.type}. Activate an alert and select the interval to receive updates`} {`via email ${this.props.userDetails && this.props.userDetails.email && `(${this.props.userDetails.email})`}. Alerts are always personalized and will not be shared.`}
                            {this.state.showMore && this.props.explanationText ?
                                ` ${this.props.explanationText}`
                                : null}
                            {this.state.showMore === false ?
                                <a
                                    style={{ paddingLeft: 5 }}
                                    title="Click for more details"
                                    onClick={() => this.onShowMore()}>More</a>

                                :
                                <a
                                    style={{ paddingLeft: 5 }}
                                    title="Click to hide details"
                                    onClick={() => this.onShowLess()}>Less
                                </a>

                            }
                        </label>
                        <div className="col-12 grid p-fluid" style={{ marginLeft: 3, marginTop: 10, paddingBottom: 0 }}>
                            <div className="col-4" style={{ paddingLeft: 0, paddingTop: 0 }}>
                                {alertStatus}
                            </div>
                            <div className="col-4 grid p-fluid" style={{ height: 20 }} >
                                <label style={{ paddingTop: 2 }}>Interval:</label>
                                <Dropdown className='dropdownNoBorder'
                                    style={{
                                        marginTop: -5
                                    }}
                                    value={this.state.selectedInterval}
                                    options={this.props.intervals}
                                    disabled={this.state.activeOption === 'remove' || this.state.activeOption === 'paused' ? true : false}
                                    onChange={(e) => { this.onIntervalChange(e.value) }} />
                            </div>
                            <div className="col-4 grid p-fluid" style={{ height: 20 }}>
                                <label style={{ paddingTop: 2 }}>Next email on:</label>
                                <Calendar
                                    //className='adminCalendar'
                                    className="standardDateWidth noBorder"
                                    placeholder="Select date"
                                    style={{
                                        marginTop: -8
                                    }}
                                    disabled={this.state.activeOption === 'remove' || this.state.activeOption === 'paused' ? true : false}
                                    value={this.state.startTime}
                                    onClearButtonClick={(e) => this.handleTimeChange(MIN_DATE)}
                                    id='calendar'
                                    //maxDate={YESTERDAY}
                                    minDate={(this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit.notifications.length !== 0) ? null : MIN_DATE}
                                    readOnlyInput={true}
                                    dateFormat='yy/mm/dd'
                                    showButtonBar={false}
                                    monthNavigator={true}
                                    //yearNavigator={true}
                                    appendTo={document.body}
                                    //disabled={this.props.selectedDocuments.length !== 0 ? false : true}
                                    onChange={(e) => {
                                        this.handleTimeChange(e.value)
                                    }}
                                    aria-label='Select date'
                                    title='Select date'
                                    showIcon
                                //disabled={false}
                                />
                            </div>
                        </div>
                        {this.state.activeOption === 'remove' &&
                            <div style={{ marginBottom: 10, marginLeft: 10 }}>
                                <Checkbox inputId='activateUserChecked'
                                    onChange={(e) => this.onCheckRemove(e)}
                                    checked={this.state.removeChecked ? true : false}
                                    value='Please check to confirm' style={{ 'cursor': 'pointer' }}
                                />
                                <label htmlFor='activateUserChecked' className='p-checkbox-label'>Please check to confirm</label>
                            </div>
                        }
                        <div className="col-12" style={{ display: 'flex', marginLeft: 5 }}>
                            <label style={{ color: '#757575', fontWeight: 'normal', lineHeight: "20px" }}>{this.props.type && `Alert type: ${this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)}`}
                            </label>
                            {
                                (this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit.notifications.length !== 0 && this.props.selectedCollectionAddEdit.notifications[0].created) ?
                                    <React.Fragment>
                                        <label style={{ color: '#757575', paddingLeft: 10, paddingRight: 10, fontWeight: 'normal', lineHeight: "20px" }}>|</label>
                                        <label style={{ color: '#757575', fontWeight: 'normal', lineHeight: "20px" }}>{`Created: ${moment(this.props.selectedCollectionAddEdit.notifications[0].created + "Z").format("YYYY-MM-DD")}`}</label>
                                    </React.Fragment>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div>
                    <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                            {(this.props.selectedCollectionAddEdit?.notifications && this.props.selectedCollectionAddEdit.notifications.length !== 0) ?
                                <Button style={{ float: 'left', marginLeft: 20, color: 'white', background: "#607d8bEB", borderColor: 'transparent' }} label="Remove"
                                    className='p-button-sm p-button-secondary'
                                    onClick={this.onDeleteAlert} />
                                :
                                null}
                            <Button
                                label="Save"
                                className='p-button-sm primaryButton'
                                onClick={() => this.onSaveClick()}
                                disabled={this.state.activeOption === 'remove' && !this.state.removeChecked ? true : false}
                                style={{ float: 'right' }} />
                            <Button label="Cancel"
                                onClick={(e) => this.onHide(e)}
                                className="p-button-secondary p-button-sm"
                                style={{ float: 'right', marginRight: 5 }} />
                        </div>
                    </div>
                </Dialog>
                <ConfirmationDialog
                    displayDialog={this.props.displayConfirmationDialog}
                    onHide={this.props.onHideConfirmationDialog}
                    onSubmit={() => this.onSaveClick('remove')}
                    headerText="Confirm"
                    messageText={`Would you like to remove this alert? You will no longer receive emails for this ${this.props.type}.`}
                    submitButtonLabel="Remove">
                </ConfirmationDialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userDetails: state.user.data.userDetails
})

export default withRouter(connect(mapStateToProps, null)(AddEditAlert))