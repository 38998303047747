import ConceptDistanceFilterInfo from "./ConceptDistanceFilterInfo";
import GeneralDateRangeFilterInfo from "./GeneralDateRangeFilterInfo";
import InsertionDateFilterInfo from "./InsertionDateFilterInfo";
import PatentNumberFilterInfo from "./PatentNumberFilterInfo";
import PublicationDateFilterInfo from "./PublicationDateFilterInfo";
import SearchModeFilterInfo from "./SearchModeFilterInfo";

export const FILTER_INFO_MAPPING = {
    'conceptDistance': <ConceptDistanceFilterInfo />,
    'searchMode': <SearchModeFilterInfo />,
    'pubDate': <PublicationDateFilterInfo />,
    'insDate': <InsertionDateFilterInfo />,
    'patNo': <PatentNumberFilterInfo />,
}

export const FILTER_INFO_MAPPING_BY_TYPE = {
    'dateRange': <GeneralDateRangeFilterInfo />
}