import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputSwitch } from 'primereact/inputswitch';
import { PureComponent } from 'react';
import { addThousandsSeparatorToNumber, hasUserRole, isArrayEmpty } from '../../util';
import Bibliography from '../Bibliography';
import ImagesList from '../ImagesList';
import PatentFamilyMembers from '../PatentFamilyMembers';
import { SemanticAutoComplete } from '../semantic-search/SemanticAutoComplete';
import { HIGHLIGHT_OPTIONS } from '../util/documentView';
import AnnotatedCompounds from './AnnotatedCompounds';
import Annotations from './Annotations';
import Sequences from './Sequences';
import infoIcon from '../../../../assets/images/icons/info/dimensions-icon-info.png';
import { OverlayPanel } from 'primereact/overlaypanel';
import { getFilteredAnnotatioin, totalHiddenMatches, totalMatches } from '../util/general';
import AnnotationInfo from './AnnotationsInfo';
import SemanticSearchInfo from './SemanticSearchInfo';
import { Checkbox } from 'primereact/checkbox';
//import _ from 'lodash';


class StructuredDocumentData extends PureComponent {

    state = {
        isCustomClick: false,
        selectedPart: '',
        isAnnGrouped: true,
    }

    renderAccordionTabHeader = (textLeft, textRight) => {
        return (
            <span>
                <span>{textLeft}</span>
                <span style={{ position: 'absolute', right: 10 }}>{textRight}</span>
            </span>
        );
    }

    renderCustomHeader = (rightEl, leftEl, part) => {
        return (
            <span>
                <div style={{ display: 'inline' }}>
                    <div style={{ display: 'inline', paddingRight: 10 }}>
                        <div style={{ display: 'inline-block' }}>{rightEl}</div>
                        <div style={{ display: 'inline-block', verticalAlign: 'text-top' }}>
                            <InputSwitch checked={this.props[part]} className='ann-switch' onChange={(e) => {
                                // if (part === 'isAnnotationOn') {
                                this.setState({
                                    isCustomClick: true,
                                })
                                // }
                                this.props.switchHighlighting({
                                    value: e.value ? [...this.props.highlightVals, HIGHLIGHT_OPTIONS[1].value] : this.props.highlightVals?.includes('qry') ? [HIGHLIGHT_OPTIONS[0].value] : []
                                }, e.value, part, true)

                                this.props.setSwitchOnOFValue(part, e.value)

                            }} />
                        </div>
                    </div>
                    <div className='search-document-header-end' style={{ display: 'contents' }}>
                        {
                            (this.props.abstractOnly && part !== 'isAnnotationOn' && leftEl !== ' ' && !this.props.readcubeData?.available) ? <span>
                                {`${getFilteredAnnotatioin(this.props.highlights, null)} matches`}
                                <span className='matches-full-text'>
                                    {leftEl !== ' ' ? `(${leftEl})` : ''}
                                </span>
                            </span> :
                                <span>
                                    {leftEl}
                                </span>
                        }
                        <span className="semantic-info-icon"
                            title="Click for information about this feature"
                            onClick={(event) => {
                                this.setState({
                                    isCustomClick: true,
                                    selectedPart: part
                                })
                                this.annotationInfo.toggle(event)
                            }}
                        >
                            <img src={infoIcon} alt="information" />
                        </span>

                    </div>
                </div>
            </span>
        );
    }


    render() {
        const { annTextDetailsIndex, toggleAnnTextDetailsView, docMetadata, ocDocID, repositoryInfo, conceptTreeData,
            patFamilyMembers, annotationsCount, highlightVals, highlightOptions, changeHighlighting, activeAnnotations, completeAnnTypesMap,
            jumpToMatch, toggleDomainHighlighting, compoundOcidData, labeledCompoundOcidData, showLabeledCompounds, onShowLabeledCompoundsChange,
            ocidToAnnIdsMap, exportCompoundsFromDocument, fetchingCompounds, conceptsData, annTypeChemStructure, images, jumpToImage,
            sequenceID, docAdditionalOrganisms, handleShowSequenceDetailsNucleotide, handleShowSequenceDetailsProtein,
            exportNucleotideSequencesFromDocument, exportProteinSequencesFromDocument, decreaseNumberOfSequences, increaseNumberOfSequences,
            minSequences, maxSequences, domains, highlights, conceptDataMap, activeSearchTermsCount, jumpToMatchGenrally, clearSemanticSearch,
            activateOcidHighlighting, activeSearchTerms, changeOcidAnnotationColor, domainLabelsMap, domainColors,
            onSpecifyQueryTerm, abstractOnly, isSemanticOn, onAnnotationEntryClick, onCompoundEntryClick,hiddenExactTextMatches
        } = this.props;

        const repID = repositoryInfo?.id;
        const repLabel = repositoryInfo?.label;

        return <>
            <Accordion
                className="simpleAccordion"
                expandIcon="pi pi-chevron-up"
                collapseIcon="pi pi-chevron-down"
                activeIndex={annTextDetailsIndex}
                onTabChange={(e) => {
                    setTimeout(() => {
                        if (!this.state.isCustomClick) {
                            toggleAnnTextDetailsView(e)
                        } else {
                            this.setState({
                                isCustomClick: false
                            })
                        }
                    }, 20)

                }}>

                <AccordionTab

                    className='semantic-acc-tab' header={this.renderCustomHeader('Search in text', ` ${totalMatches(activeSearchTermsCount || {}) > 0
                     ? addThousandsSeparatorToNumber(totalMatches(activeSearchTermsCount) - totalHiddenMatches(hiddenExactTextMatches)) + ` ${abstractOnly ? ' in full text' : ' matches'} ` : ''}`, 'isSemanticOn')}  >
                    <SemanticAutoComplete
                        annotations={highlights}
                        conceptsData={conceptDataMap}
                        activateOcidHighlighting={activateOcidHighlighting}
                        activeSearchTerms={activeSearchTerms}
                        changeOcidAnnotationColor={changeOcidAnnotationColor}
                        domainLabelsMap={domainLabelsMap}
                        jumpToMatchGenrally={jumpToMatchGenrally}
                        clearSemanticSearch={clearSemanticSearch}
                        domains={domains}
                        activeSearchTermsCount={activeSearchTermsCount}
                        onSpecifyQueryTerm={onSpecifyQueryTerm}
                        domainColors={domainColors}
                        abstractOnly={abstractOnly}
                        isSemanticOn={isSemanticOn}
                        hiddenExactTextMatches={hiddenExactTextMatches}
                        showFooter={true}
                    />
                </AccordionTab>
                <AccordionTab header="Metadata">
                    <Bibliography
                        docMetadata={docMetadata}
                        repositoryInfo={repositoryInfo}
                        repository={repLabel} />
                </AccordionTab>

                <AccordionTab
                    disabled={isArrayEmpty(conceptTreeData)}
                    header={this.renderCustomHeader('Annotations', `${conceptTreeData ? conceptTreeData.length : 0} domains | ${annotationsCount ? addThousandsSeparatorToNumber(annotationsCount) : 0} hits`, 'isAnnotationOn')}>
                    <div className="field-checkbox text-right" >
                        <Checkbox inputId="group" checked={this.state.isAnnGrouped} onChange={e => {
                            this.setState({
                                isAnnGrouped: e.checked,
                            })
                        }} />
                        <label htmlFor="group" className='group-ann'> Grouped</label>
                    </div>
                    <Annotations
                        highlightVals={highlightVals}
                        highlightOptions={highlightOptions}
                        conceptTreeData={conceptTreeData}
                        changeHighlighting={changeHighlighting}
                        activeAnnotations={activeAnnotations}
                        jumpToMatch={jumpToMatch}
                        toggleDomainHighlighting={toggleDomainHighlighting}
                        domains={domains}
                        isAnnGrouped={this.state.isAnnGrouped}
                        onClick={onAnnotationEntryClick}
                    />
                </AccordionTab>

                <AccordionTab
                    disabled={!completeAnnTypesMap || isArrayEmpty(completeAnnTypesMap[annTypeChemStructure])}
                    header={this.renderAccordionTabHeader('Chemical structures', completeAnnTypesMap && completeAnnTypesMap[annTypeChemStructure] ? completeAnnTypesMap[annTypeChemStructure].length : 0)}>
                    <AnnotatedCompounds
                        compoundOcidData={compoundOcidData}
                        labeledCompoundOcidData={labeledCompoundOcidData}
                        showLabeledCompounds={showLabeledCompounds}
                        onShowLabeledCompoundsChange={onShowLabeledCompoundsChange}
                        ocidToAnnIdsMap={ocidToAnnIdsMap}
                        exportCompoundsFromDocument={exportCompoundsFromDocument}
                        fetchingCompounds={fetchingCompounds}
                        conceptsData={conceptsData}
                        jumpToMatch={jumpToMatch}
                        annTypeChemStructure={annTypeChemStructure}
                        repID={repID}
                        ocDocID={ocDocID}
                        onClick={onCompoundEntryClick}
                    />
                </AccordionTab>

                <AccordionTab
                    disabled={isArrayEmpty(images)}
                    header={this.renderAccordionTabHeader('Images', images ? images.length : 0)}>
                    <ImagesList
                        images={images}
                        jumpToImage={jumpToImage}
                        emptyMessage="No images in this document." />
                </AccordionTab>

                {patFamilyMembers ? patFamilyMembers.map(patFam => {
                    return (
                        <AccordionTab key={`${patFam.label}`}
                            header={this.renderAccordionTabHeader(patFam.label, patFam.totalMemberCount || 0)}>
                            {patFam.totalMemberCount > patFam.members?.length && <div>Note: Only 100 entries are shown</div>}
                            <PatentFamilyMembers
                                key={`${patFam.label}`}
                                repID={repID}
                                familyMembers={patFam.members} />
                        </AccordionTab>
                    )
                }) : null}

                {(hasUserRole('ROLE_SEQUENCESEARCH')) &&
                    docAdditionalOrganisms && docAdditionalOrganisms.patIdSequencesMap &&
                    Object.keys(docAdditionalOrganisms.patIdSequencesMap)?.length > 0 &&
                    docAdditionalOrganisms.patIdSequencesMap[ocDocID]?.length > 0 &&
                    <AccordionTab
                        header={this.renderAccordionTabHeader('Sequences', docAdditionalOrganisms.patIdSequencesMap[ocDocID].length)}>
                        <Sequences
                            sequenceID={sequenceID}
                            docAdditionalOrganisms={docAdditionalOrganisms}
                            ocDocID={ocDocID}
                            handleShowSequenceDetailsNucleotide={handleShowSequenceDetailsNucleotide}
                            handleShowSequenceDetailsProtein={handleShowSequenceDetailsProtein}
                            exportNucleotideSequencesFromDocument={exportNucleotideSequencesFromDocument}
                            exportProteinSequencesFromDocument={exportProteinSequencesFromDocument}
                            decreaseNumberOfSequences={decreaseNumberOfSequences}
                            increaseNumberOfSequences={increaseNumberOfSequences}
                            minSequences={minSequences}
                            maxSequences={maxSequences}
                        />
                    </AccordionTab>
                }
            </Accordion>


            <OverlayPanel
                ref={(el) => this.annotationInfo = el}
                dismissable={true}
                className="moveRightArrow">

                {
                    this.state.selectedPart === 'isAnnotationOn' ?
                        <AnnotationInfo /> : <SemanticSearchInfo />
                }

            </OverlayPanel>
        </>
    }
}

export default StructuredDocumentData;