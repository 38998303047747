//import { getHeaderToken, postVoid, sendApiRequest, downloadApiResponse, postApiBlob } from './index'
import { sendApiRequest, downloadApiResponse, postApiBlob } from './index'
import { APP_PROPERTIES } from '../../src/properties/index';


export const fetchCartridges = () => sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/cartridges`);
export const fetchCartridge = (name) => downloadApiResponse('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/cartridges/${name}`, name + ".zip");
export const rmCartridge = (name) => sendApiRequest('DELETE', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/cartridges/${name}`);
export const uploadCartridge = (file) => postApiBlob(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/cartridges`, file);
export const installAvailableCartridge = (name) => postApiBlob(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/cartridges?uri=` + name, "");
export const addCartridgeDomain = (cartDomain) => sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains`, cartDomain);
export const editCartridgeDomain = (cartDomain) => sendApiRequest('PUT', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/domains/${cartDomain.id}`, cartDomain);

export const fetchRepositories = () => sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories`);
export const deleteRepository = (name) => sendApiRequest('DELETE', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repositories/${name}`);

export const annotateDocument = (name, file) => postApiBlob(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/annotations/document?name=${name}`, file);
export const annotateResource = (job) => sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/annotations/resource`, job);

export const fetchJobs = (count, page) => sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/annotations/jobs?count=${count}&page=${page}`);
export const fetchActiveJobs = () => sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/annotations/jobs?count=5&page=0&active=true`);
export const deleteJobs = (annotationJobIds) => sendApiRequest('DELETE', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/annotations/jobs?annotationJobIds=${annotationJobIds}`);
export const getJobErrors = (jobId) => sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/annotations/jobs/item-errors/${jobId}`);
