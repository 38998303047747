//import "./SavedSearchesLink.css";

import { useState } from "react";
import SavedSearchesDialog from "./SavedSearchesDialog";
import { Button } from "primereact/button";


const SavedSearchesLink = ({
    label = "Saved searches",
    allowedQueryTypes = [],
    onSearchSelect,
    asLink
}) => {

    const [displayDialog, setDisplayDialog] = useState();

    // @todo use button only

    return <>
        {!asLink ?
            <Button
                id='savedSearches'
                className="p-button-text primaryButtonAsLink"
                title="Load a saved search into the search form"
                onClick={() => setDisplayDialog(true)}>
                {label}
            </Button>
            :
            <a id='savedSearches'
                title='Load a saved search into the search form'
                onClick={() => setDisplayDialog(true)}>
                {label}
            </a>
        }

        <SavedSearchesDialog
            displayDialog={displayDialog}
            onHide={() => setDisplayDialog(false)}
            allowedQueryTypes={allowedQueryTypes}
            onSelect={(savedSearch) => {
                setDisplayDialog(false);
                onSearchSelect(savedSearch);
            }}
        />
    </>
}

export default SavedSearchesLink;