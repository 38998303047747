import _ from "lodash";
import { STRUCTURE_FORMAT_MOL, STRUCTURE_FORMAT_RXN } from "./chemistrySearchConstants";
import { determineStructureFormat } from "./chemistrySearchUtil";

/**
 * Compares two structure file contents, ignoring the structure ID.
 * @param {*} structure1 
 * @param {*} structure2 
 * @param {*} format 
 * @returns 
 */
export const areStructuresEqual = (structure1, structure2, format) => {

    // equal, if both are undefined
    if (!structure1 && !structure2) {
        return true;
    }
    // not equal, if one of the structures is undefined, but the other one is defined
    else if ((!structure1 || !structure2) && structure1 != structure2) {
        return false;
    }

    let struct1 = structure1;
    let struct2 = structure2;

    let actFormat = format;
    if (format === STRUCTURE_FORMAT_RXN) {
        actFormat = determineStructureFormat(struct1);
    }

    switch (actFormat) {
        case STRUCTURE_FORMAT_RXN: {
            // e.g. both in same file: Mrv2214  082420221235 || Mrv2214 08242212202D
            struct1 = structure1.replaceAll(/Mrv[0-9]+\s+[0-9]+[A-Z]?/g, '');
            struct2 = structure2.replaceAll(/Mrv[0-9]+\s+[0-9]+[A-Z]?/g, '');
            //console.log('struct1: ', struct1);
            //console.log('struct2: ', struct2);
            break;
        }
        case STRUCTURE_FORMAT_MOL: {
            struct1 = structure1.substring(33);
            struct2 = structure2.substring(33);
            //console.log('struct1: ', struct1);
            //console.log('struct2: ', struct2);
            break;
        }
        default: // do nothing
    }

    return _.isEqual(struct1, struct2);
}