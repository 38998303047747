import React from "react";
import InputFieldWithClear from "../../../../../webapi/general/inputfields/InputFieldWithClear";
import { InputNumber } from "primereact/inputnumber";
import ConceptDistanceInfo from "../../../infos/ConceptDistanceInfo";

const SearchFilterIDConceptDistanceLocal = ({
    onClear,
    value,
    onChange
                                            }) => {
    const isClearButtonShown = value !== null
  return (
    <div className="flex align-items-center gap-3">
      <div>
        <InputFieldWithClear
          onClear={onClear}
          showIcon={isClearButtonShown}
        >
          <InputNumber
            min={0}
            max={10000}
            maxFractionDigits={0}
            size={3}
            value={value}
            onChange={onChange}
          />
        </InputFieldWithClear>
      </div>
      <div> ~ maximum number of words</div>
      <ConceptDistanceInfo />
    </div>
  );
};

export default SearchFilterIDConceptDistanceLocal;
