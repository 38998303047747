import { Component } from "react";
import TutorialVideos from "../../general/TutorialVideos";
import { videos } from '../../../../properties/videos'
import { APP_PROPERTIES } from "../../../../properties";
import { hasUserRole, isArrayEmpty } from "../../util";
import UserManualLink from "../../general/UserManualLink";
import pdfFileChemSearchUserGuide from '../../../../assets/images/tutorial/Chemistry_Search_User_Guide.pdf'
import SearchExamples from "../../general/searchexamples/SearchExamples";
import { CHEMISTRY_EXAMPLES } from "./examples";
import SeparatorPoint from "../../general/SeparatorPoint";


class ChemistryHeaderBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('chemsearch')) });
        }
    }

    render() {

        return (
            <div className='grid'>
                <div className="col textAlignLeft">
                    <h1 className="pageHeader" style={{ marginBottom: 0 }}>Chemistry Search</h1>
                </div>
                <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                    <UserManualLink
                        manualFilePath={pdfFileChemSearchUserGuide}
                        label='Open manual'
                        tooltip='Click to open manual in new tab'
                        styleClass='secondaryButtonAsLink valignMiddle'
                    />
                    {!isArrayEmpty(this.state.pageTutorials) ?
                        <span style={{ marginLeft: 30 }}>
                            <TutorialVideos
                                pageTutorials={this.state.pageTutorials}
                                section='chem'
                                sectionLabel='Chemistry Search'
                            /></span> : null
                    }
                </div>
                <div className="col-12 pageInfo">
                    <div>
                        <span>
                            Find chemical compounds {hasUserRole('ROLE_REACTIONSEARCH') && 'or reactions'} by searching for names, registry numbers,
                            SMILES etc. or even by simply drawing the structure.                         
                        </span>
                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <SeparatorPoint />
                            <SearchExamples
                                buttonLabel="Examples"
                                //buttonStyle={{ marginLeft: 5 }}
                                //panelStyle={{ maxWidth: 300, maxHeight: 90vh }}
                                examples={CHEMISTRY_EXAMPLES}
                                onExampleClick={(example) => this.props.onSelectExample(example)}
                            />
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChemistryHeaderBlock;



