import AutocompleteInputField from "../../../../../../../general/autocomplete/AutocompleteInputField";
import { useSelector } from "react-redux";
import SeparatorPoint from "../../../../../../../general/SeparatorPoint";
import { filterDefinitionsSelector } from "../../../../../../../../../redux/common/filters/selectors/filters";

const QueryTermsInput = ({
  queryTermsAutocomplete,
  onAutocompleteInputChange,
  onOpenDomainExplorer,
  onBulkImportTerms,
}) => {
  const filterDefinitions = useSelector(filterDefinitionsSelector);

  return (
    <>
      <div className="flex align-items-center justify-content-between gap-3 mt-3 mr-0 mb-4 ml-3">
        <div>Type away</div>
        <div className="flex-grow-1">
          <AutocompleteInputField
            filterDefinitions={filterDefinitions}
            queryTerms={queryTermsAutocomplete}
            onQueryTermsChange={(qts) => onAutocompleteInputChange(qts)}
            onSpecifyQueryTerm={onOpenDomainExplorer}
          />
        </div>
      </div>
      <div className="flex align-items-center mt-3 mr-0 mb-4 ml-3">
        <div style={{ width: 73 }}>or</div>
        <div>
          <a onClick={onBulkImportTerms}>Add list of terms</a>
          <SeparatorPoint />
          <a onClick={() => onOpenDomainExplorer()}>Add with Domain Explorer</a>
        </div>
      </div>
    </>
  );
};

export default QueryTermsInput;
