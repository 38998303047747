import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { Accordion, AccordionTab } from "primereact/accordion";
import { isArrayEmpty } from "../../util";
import { Button } from "primereact/button";
import FilterSectionAutocompleteTermloc from "./FilterSectionAutocompleteTermloc";
import FilterSectionDateRange from "./FilterSectionDateRange";
import FilterSectionDropdown from "./FilterSectionDropdown";
import FilterSectionFacetsWithValues from "./FilterSectionFacetsWithValues";
import FilterSectionSearchField from "./FilterSectionSearchField";
import StatisticsCharts from "../../docsearch/charts/StatisticsCharts";
import FacetsSelectionDialog from "./FacetsSelectionDialog";
import {
    SEARCH_FILTER_TYPE_TERMLOC, SEARCH_FILTER_TYPE_INPUT_FREE, SEARCH_FILTER_TYPE_DROPDOWN_STATIC, SEARCH_FILTER_TYPE_DATE_RANGE,
    SEARCH_FILTER_TYPE_FACETS_WITH_INPUT, SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY, SEARCH_FILTER_TYPE_AUTOCOMPLETE_WITH_TERMLOC,
    SEARCH_FILTER_TYPE_IPC_DOMAIN, SEARCH_FILTER_TYPE_INPUT_FREE_TERMLOC, PREFIX_DOMAIN_FACET, FILTER_CATEGORY_PREFIX
} from "../docsearch/searchConstants";
import { getNumberOfTermsAndConceptsInQuery } from "../docsearch/searchUtil";
import { FILTER_INFO_MAPPING, FILTER_INFO_MAPPING_BY_TYPE } from "../../docsearch/infos/filterInfoMapping";
import YearStatisticsInfo from "../../docsearch/infos/YearStatisticsInfo";
import { fetchIPCClasses } from "../../../../api/content/DocumentApi";
import { checkResultAndGetPayload } from "../../../../api";
import FilterSectionInfo from "../../docsearch/infos/FilterSectionInfo";
import './Filters.css';
import { getFilterDefinitionForRepository } from "../../../../properties";


class FiltersBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndices: [],
            //activeIndex: -1
            //activeIndicesMap: {}
        };

        this.growl = createRef();
        this.toast = createRef();

        this.resetActivetIndices = true;

        this.filterIndexMap = {};
        this.indexFilterMap = {};
    }

    async componentDidMount() {
        const response = await fetchIPCClasses();
        const ipcDomains = checkResultAndGetPayload(response, this.props.growl);
        this.setState({
            ipcDomains: ipcDomains
        });
    }


    addToActiveIndices = (activeIndices, index, filter) => {
        if (filter.openOnDefault) {
            activeIndices.push(index);
        }
    }

    isMaxNumberOfOccurrencesReached = (filter, queryTerms) => {
        let maxNumOfOccReached = false;
        if (filter?.maxNumOfOcc) {
            let numOfOcc = 0;
            queryTerms?.forEach(queryTerm => { if (queryTerm.filterID === filter.id) { numOfOcc++; } });
            maxNumOfOccReached = numOfOcc >= filter.maxNumOfOcc;
        }
        return maxNumOfOccReached;
    }

    getCurrentFilter = (filterDefinitions, filterID) => {
        return filterDefinitions && filterDefinitions[filterID] ? filterDefinitions[filterID] : null;
    }

    onLoadMoreFacets = (filter) => {
        //const currentFilter = this.getCurrentFilter(this.props.filterDefinitions, filter?.id);
        this.props.onLoadMoreFacets(filter);
        this.setState({ displayMoreFacetsDialog: true, moreFacetsFilter: filter });
    }

    determineActiveIndex = () => {
        let activeIndex = -1;
        const actIndex = this.props.openFilterCategory ? this.filterIndexMap[this.props.openFilterCategory] : -1;
        if (actIndex >= 0) {
            activeIndex = actIndex;
        }
        if (this.disabledFilters[activeIndex]) {
            activeIndex = -1;
        }
        return activeIndex;
    }

    filterSectionsFilter = (filterSections) => {
        const filterSectionsFiltered = [];
        let filteredIndex = 0;
        //console.log('filterSections: ', filterSections);

        filterSections.forEach((filterSect, index) => {
            //console.log('filterSect: ', filterSect);
            //console.log('index: ', index);
            if (!filterSect) {
                return;
            }
            const filterID = filterSect?.key;
            //console.log('filterID: ', filterID);
            if (filterID?.startsWith(FILTER_CATEGORY_PREFIX)) {
                const next = filterSections[index + 1];
                const nextFilterID = next?.key;
                //console.log('nextFilterID: ', nextFilterID);
                const categoryHasEntries = !!next && !nextFilterID?.startsWith(FILTER_CATEGORY_PREFIX);
                //console.log('categoryHasEntries: ', categoryHasEntries);
                if (!categoryHasEntries) {
                    return;
                }
            }
            this.filterIndexMap[filterID] = filteredIndex;
            this.indexFilterMap[filteredIndex] = filterID;
            if (filterSect.props?.disabled) {
                this.disabledFilters[filteredIndex] = true;
            }
            filteredIndex++;
            filterSectionsFiltered.push(filterSect);
        });

        return filterSectionsFiltered;
    }

    renderFilterButton = (filter, filterInputValues) => {

        if (!this.state.displayMoreFacetsDialog && filter?.id === filterInputValues?.filterID &&
            filter.type === SEARCH_FILTER_TYPE_DATE_RANGE) {
            const isWrongStartDateFormat = !!filterInputValues.startDate &&
                !filterInputValues.startDate.match(/^[0-9]{4}(-?[0-9]{2})?(-?[0-9]{2})?$/);
            const isWrongEndDateFormat = !!filterInputValues.endDate &&
                !filterInputValues.endDate.match(/^[0-9]{4}(-?[0-9]{2})?(-?[0-9]{2})?$/);
            if (isWrongStartDateFormat || isWrongEndDateFormat) {
                return <div className="secondaryInfo" style={{ marginTop: 0, marginLeft: 10 }}>
                    Valid date formats are YYYY-MM-DD or YYYYMMDD or YYYY-MM or YYYYMM or YYYY.
                </div>
            }
        }

        if (!this.state.displayMoreFacetsDialog && filter?.id === filterInputValues?.filterID &&
            ((filterInputValues.filterID && filter.type !== SEARCH_FILTER_TYPE_FACETS_WITH_INPUT) ||
                (filter.type === SEARCH_FILTER_TYPE_FACETS_WITH_INPUT && !isArrayEmpty(filterInputValues.selectedFilterEntries)))) {
            return <Button
                type="button"
                label={`Add filter`}
                className="p-button-sm primaryButton width100perc"
                style={{ marginTop: 10 }}
                onClick={(e) => { this.props.onAddFiltersToQuery(filter) }} />
        }

        return null;
    }

    renderFilterSections = (repoFilters) => {

        this.filterIndexMap = {};
        this.indexFilterMap = {};
        this.disabledFilters = {};

        //let currIndex = -1;
        //let activeIndices = [];

        const filtersMetadata = {}, filtersDomains = {};
        if (this.props.facets) {
            Object.keys(this.props.facets).forEach(filterID => {
                if (filterID.startsWith(PREFIX_DOMAIN_FACET)) filtersDomains[filterID] = this.props.facets[filterID];
                else filtersMetadata[filterID] = this.props.facets[filterID];
            })
        }
        // --- for check if a minimum number of terms or concepts is defined but not reached --- //
        const { numOfTermsInQuery, numOfConceptsInQuery } = getNumberOfTermsAndConceptsInQuery(this.props.queryTerms);

        let filterSections = (
            repoFilters ?
                repoFilters
                    .filter((filterID, index) => {
                        const filter = this.props.filterDefinitions[filterID] || filterID.startsWith(FILTER_CATEGORY_PREFIX);
                        return !!filter;
                    })
                    .map((filterID, index) => {
                        // --- check if entry is a filter category and if this category has any filter entries --- //
                        if (filterID.startsWith(FILTER_CATEGORY_PREFIX)) {
                            const label = filterID.replace(FILTER_CATEGORY_PREFIX, '');
                            return this.renderCategory(label, filterID);
                        }

                        const filter = this.props.filterDefinitions[filterID];

                        // -- check if a maximum number of occurrences for this filter is defined and reached --- //
                        const maxNumOfOccReached = this.isMaxNumberOfOccurrencesReached(filter, this.props.queryTerms);

                        let disableFilter = (maxNumOfOccReached || (filter.minNumOfTerms && numOfTermsInQuery < filter.minNumOfTerms) ||
                            (filter.minNumOfConcepts && numOfConceptsInQuery < filter.minNumOfConcepts));
                        if (disableFilter) {
                            this.disabledFilters[filterID] = true;
                        }

                        const filterInfo = FILTER_INFO_MAPPING[filter.id] ? FILTER_INFO_MAPPING[filter.id] : FILTER_INFO_MAPPING_BY_TYPE[filter.type];

                        switch (filter.type) {

                            case SEARCH_FILTER_TYPE_INPUT_FREE:
                            case SEARCH_FILTER_TYPE_INPUT_FREE_TERMLOC: {
                                //this.addToActiveIndices(activeIndices, currIndex, filter);
                                const content = <FilterSectionSearchField
                                    filterID={filter.id}
                                    input={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.input : ''}
                                    onFilterValuesChange={this.props.onFilterInputValuesChange}
                                    placeholder={filter.placeholder}
                                />;
                                return this.renderTab(filter, this.props.filterInputValues, content, disableFilter, filterInfo);
                            }

                            case SEARCH_FILTER_TYPE_DROPDOWN_STATIC: {
                                //this.addToActiveIndices(activeIndices, currIndex, filter);
                                const content = <FilterSectionDropdown
                                    filterID={filter.id}
                                    filterEntries={filter.filterValues}
                                    selectedEntry={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.value : null}
                                    onFilterInputValuesChange={(filterObject) => { this.props.onImmediatelyAddFiltersToQuery(filterObject, filter) }}
                                    placeholder={filter.placeholder}
                                />;
                                return this.renderTab(filter, this.props.filterInputValues, content, disableFilter, filterInfo);
                            }

                            case SEARCH_FILTER_TYPE_TERMLOC: {
                                const filterEntries = this.props.filterDefinitions[filter.type]?.filterValueLabels &&
                                    this.props.repositorySchema?.termLocations ?
                                    Object.entries(this.props.filterDefinitions[filter.type].filterValueLabels)
                                        .filter(([termlocID, termlocLabel]) => {
                                            return this.props.repositorySchema.termLocations.includes(termlocID)
                                        }).map(([termlocID, termlocLabel]) => {
                                            return { filterValue: termlocID, filterValueLabel: termlocLabel }
                                        }) : null;
                                if (!isArrayEmpty(filterEntries)) {
                                    //this.addToActiveIndices(activeIndices, currIndex, filter);
                                    const content = <FilterSectionDropdown
                                        filterID={filter.id}
                                        filterEntries={filterEntries}
                                        selectedEntry={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.value : null}
                                        onFilterInputValuesChange={(filterObject) => { this.props.onImmediatelyAddFiltersToQuery(filterObject, filter) }}
                                        placeholder={filter.placeholder}
                                    />;
                                    return this.renderTab(filter, this.props.filterInputValues, content, disableFilter, filterInfo);
                                }
                                break;
                            }

                            case SEARCH_FILTER_TYPE_IPC_DOMAIN: {
                                //this.addToActiveIndices(activeIndices, currIndex, filter);
                                const content = <FilterSectionDropdown
                                    filterID={filter.id}
                                    filterEntries={filter.filterValues}
                                    selectedEntry={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.value : null}
                                    onFilterInputValuesChange={(filterObject) => { this.props.onImmediatelyAddFiltersToQuery(filterObject, filter) }}
                                    placeholder={filter.placeholder}
                                />;
                                return this.renderTab(filter, this.props.filterInputValues, content, disableFilter, filterInfo);
                            }

                            case SEARCH_FILTER_TYPE_DATE_RANGE: {
                                //this.addToActiveIndices(activeIndices, currIndex, filter);
                                const content = <FilterSectionDateRange
                                    filterID={filter.id}
                                    startDate={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.startDate : ''}
                                    endDate={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.endDate : ''}
                                    onFilterValuesChange={this.props.onFilterInputValuesChange}
                                />;
                                return this.renderTab(filter, this.props.filterInputValues, content, disableFilter, filterInfo);
                            }

                            case SEARCH_FILTER_TYPE_FACETS_WITH_INPUT: {
                                if (this.props.facets && !isArrayEmpty(this.props.facets[filter.facetID])) {
                                    //this.addToActiveIndices(activeIndices, currIndex, filter);
                                    const content = <FilterSectionFacetsWithValues
                                        filter={filter}
                                        filterID={filter.id}
                                        placeholder={filter.placeholder}
                                        facetEntries={this.props.facets[filter.facetID] ? this.props.facets[filter.facetID] : []}
                                        selectedFacets={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.selectedFilterEntries : []}
                                        input={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.input : ''}
                                        onInputChange={this.props.onFilterInputValuesChange}
                                        onSelectionChange={this.props.onSelectionChange}
                                        onAddSelectedFacet={this.props.onAddSelectedFacet}
                                        onLoadMoreFacets={this.onLoadMoreFacets}
                                        displayMoreFacetsDialog={this.state.displayMoreFacetsDialog}
                                        onHideMoreFacetsDialog={() => this.setState({ displayMoreFacetsDialog: false })}
                                        includeInputField={true}
                                        showOccurrences={this.props.showOccurrences}
                                    />;
                                    return this.renderTab(filter, this.props.filterInputValues, content, disableFilter, filterInfo);
                                }
                                break;
                            }

                            case SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY: {
                                //console.log('this.props.exampleValues: ', this.props.exampleValues);
                                if (this.props.facets && !isArrayEmpty(this.props.facets[filter.facetID])) {
                                    //this.addToActiveIndices(activeIndices, currIndex, filter);
                                    const content = <FilterSectionFacetsWithValues
                                        filter={filter}
                                        filterID={filter.id}
                                        placeholder={filter.placeholder}
                                        facetEntries={this.props.facets[filter.facetID] ? this.props.facets[filter.facetID] : []}
                                        selectedFacets={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.selectedFilterEntries : []}
                                        onInputChange={this.props.onFilterInputValuesChange}
                                        onSelectionChange={this.props.onSelectionChange}
                                        onAddSelectedFacet={this.props.onAddSelectedFacet}
                                        onLoadMoreFacets={this.onLoadMoreFacets}
                                        displayMoreFacetsDialog={this.state.displayMoreFacetsDialog}
                                        onHideMoreFacetsDialog={() => this.setState({ displayMoreFacetsDialog: false })}
                                        includeInputField={false}
                                        showOccurrences={this.props.showOccurrences}
                                    />;
                                    return this.renderTab(filter, this.props.filterInputValues, content, disableFilter, filterInfo);
                                }
                                break;
                            }

                            case SEARCH_FILTER_TYPE_AUTOCOMPLETE_WITH_TERMLOC: {
                                if (!isArrayEmpty(filter?.domains)) {
                                    //this.addToActiveIndices(activeIndices, currIndex, filter);
                                    const content = <FilterSectionAutocompleteTermloc
                                        filterID={filter.id}
                                        filterDomains={filter.domains}
                                        placeholder={filter.placeholder}
                                        termlocQueryTerms={this.props.filterInputValues?.filterID === filter.id ? this.props.filterInputValues.termlocQueryTerms : null}
                                        onFilterValuesChange={this.props.onFilterInputValuesChange}
                                    />;
                                    return this.renderTab(filter, this.props.filterInputValues, content, disableFilter, filterInfo);
                                }
                                break;
                            }

                            default: // do nothing
                        }

                        return <React.Fragment key={filter.id}></React.Fragment>;
                    }) : null
        );

        //console.log('filterSections: ', filterSections);
        const filterSectionsFiltered = this.filterSectionsFilter(filterSections);

        this.userActiveIndex = this.determineActiveIndex();
        /*
        const userActiveIndices = [];
        if (this.props.openFilterIDs) {
            Object.keys(this.props.openFilterIDs).forEach(activeFilterID => {
                const actIndex = this.filterIndexMap[activeFilterID];
                if (actIndex >= 0) {
                    userActiveIndices.push(actIndex);
                }
            });
        }
        */

        return (
            <div title='Some filters are only available for specific input, e.g. the "Search in" and "Search mode" filters can only be used if the search contains at least one concept. The "Concept distance" filter only makes sense if there are at least two concepts present.'>
                <Accordion
                    className="simpleAccordion facets"
                    multiple={false}
                    activeIndex={this.userActiveIndex}
                    expandIcon="pi pi-chevron-up"
                    collapseIcon="pi pi-chevron-down"
                    onTabChange={(e) => {
                        if (!this.doNoToggle) {
                            let activeFilterID = null;
                            if (this.indexFilterMap[e.index]) {
                                activeFilterID = this.indexFilterMap[e.index];
                            }
                            this.activeFilterID = activeFilterID;
                            this.props.onToggleFilters(activeFilterID);
                        }
                        else {
                            this.doNoToggle = false;
                        }
                        /*
                        const activeFilters = {};
                        e.index?.forEach(activeIndex => {
                            if (this.indexFilterMap[activeIndex]) {
                                activeFilters[this.indexFilterMap[activeIndex]] = true;
                            }
                        });
                        this.props.onToggleFilters(activeFilters, e.index);
                        */
                    }}
                >
                    {filterSectionsFiltered}
                </Accordion>
            </div>
        );
    }

    renderAccordionHeader = (filterID, label, active, filterInfo) => {

        return <span className='accordion-header'>
            <span className={`accordion-header-text ${active ? 'active' : ''}`} title={label}>{label}</span>
            <span className='accordion-header-icons close'>
                {filterInfo ?
                    <span className="material-icons-outlined accordion-header-icon" //style={{ margin: 'auto' }}
                        onClick={(e) => {
                            this.doNoToggle = true;
                        }}
                    >
                        {filterInfo}
                    </span> : null}
            </span>
        </span>;
    }

    renderTab = (filter, filterInputValues, content, disabled, filterInfo) => {

        let info = this.activeFilterID === filter.id ? filterInfo : null;

        return <AccordionTab
            key={filter.id}
            header={this.renderAccordionHeader(filter.id, filter.label, false, info)}
            disabled={disabled}>
            {!disabled ?
                <>
                    {content}
                    {this.renderFilterButton(filter, filterInputValues)}
                </> : null
            }
        </AccordionTab>;
    }

    renderCategory = (label, id) => {
        // --- reset global tooltip (only overwritten for non-disabled elements) --- //
        const header = <span title=''>{label}</span>;
        return <AccordionTab
            key={id}
            header={header}
            disabled={true}
            headerClassName='filter-category'>
        </AccordionTab>;
    }

    render() {

        const { repositoryFilterMap, includeStatistics, statistics, fetchingFilters, fetchingStatistics,
            selectedRepository, queryTerms, query, filterQueries, showRelativeValues, showTrendAnalysisLink } = this.props;

        const repoFilters = getFilterDefinitionForRepository(repositoryFilterMap, selectedRepository?.name);
        // const repoFilters = repositoryFilterMap ? repositoryFilterMap[selectedRepository?.name] : [];
        //console.log('repoFilters: ', repoFilters);

        return (
            <>
                <Toast ref={this.growl} />
                <Toast ref={this.toast} position="bottom-left" className="filterToast" />

                {includeStatistics ?
                    <>
                        <div className="grid grid-nogutter">
                            <div className="col"><h2 className="filterBlockHeading">30-YEAR TREND</h2></div>
                            <div className="col-fixed" style={{ paddingTop: 10 }}>
                                <YearStatisticsInfo />
                            </div>
                        </div>

                        {fetchingStatistics ?
                            <div className="secondaryInfo">Fetching publication statistics...</div>
                            :
                            statistics ?
                                <div className="textAlignCenter">
                                    <StatisticsCharts
                                        statistics={statistics}
                                        displayName={!isArrayEmpty(queryTerms) && queryTerms[0]?.label ? `${queryTerms[0].label}` : 'Quick Search'}
                                        query={query}
                                        filterQueries={filterQueries}
                                        showRelativeValues={showRelativeValues}
                                        showTrendAnalysisLink={showTrendAnalysisLink}
                                        selectedRepository={selectedRepository}
                                    />
                                </div>
                                :
                                <div className="secondaryInfo" style={{ marginBottom: 20 }}>No publication dates available</div>
                        }
                    </> : null}

                {this.props.filterDefinitions && !isArrayEmpty(repoFilters) ?
                    <div className="grid grid-nogutter simple-accordion-filter-heading">
                        <div className="col"><span>Filter by</span></div>
                        <div className="col-fixed">
                            <FilterSectionInfo />
                        </div>
                    </div> : null}

                {fetchingFilters ?
                    <div className="secondaryInfo">Fetching filters...</div>
                    :
                    this.props.filterDefinitions && !isArrayEmpty(repoFilters) ?
                        this.renderFilterSections(repoFilters)
                        :
                        <div className="secondaryInfo">No filters</div>
                }

                <FacetsSelectionDialog
                    filter={this.state.moreFacetsFilter}
                    filterID={this.state.moreFacetsFilter?.id}
                    selectedFacets={this.props.filterInputValues?.filterID === this.state.moreFacetsFilter?.id ?
                        this.props.filterInputValues?.selectedFilterEntries : []}
                    onSelectionChange={this.props.onSelectionChange}
                    onAddFacetsToQuery={(filter) => {
                        this.setState({ displayMoreFacetsDialog: false });
                        this.props.onAddFiltersToQuery(filter);
                    }}
                    exampleValues={this.props.exampleValues && this.props.exampleValues[this.state.moreFacetsFilter?.facetID] ?
                        this.props.exampleValues[this.state.moreFacetsFilter?.facetID] : null}
                    displayDialog={this.state.displayMoreFacetsDialog}
                    onHide={() => this.setState({ displayMoreFacetsDialog: false })}
                    showOccurrences={this.props.showOccurrences}
                />
            </>
        );
    }
}

export default FiltersBlock;