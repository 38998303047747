import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CooccurrenceAnalysis from '../coocs/CooccurrenceAnalysis';

const mapStateToProps = state => ({
    userData: state.user.data,
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
    domainColors: state.user.data.userDetails.department.selectedDomains,
    availableDomains: state.webAPI.availableDomains,
    fetchingAvailableDomains: state.webAPI.fetchingAvailableDomains,
    repositories: state.user.data.userDetails.department?.selectedRepositories,
})

const mapDispatchToProps = dispatch => ({})

const CooccurrencesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CooccurrenceAnalysis));

export default CooccurrencesContainer