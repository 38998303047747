import { SharedToolbarType } from "../../../types";
import "./Toolbar.css";
import { ToolbarItem } from "./ToolbarItem";

export const SharedToolbar = ({
    numPages,
    pageNumber,
    prevPage,
    nextPage,
    pageNumberChange,
    zoomIn,
    zoomOut,
    fitPage,
    widthPage,
    rotateNext,
    rotate,
    zoomPrecentage
}: SharedToolbarType) => {


    return (
        <div className="toolbar" >
            <span className="item-parent">
                <ToolbarItem
                    className="toolbar-icons"
                    img='assets/pdfReaderIcons/up.svg'
                    clickHandler={prevPage}
                />
                <span >
                    <input className="toolbar-label text-right" onChange={pageNumberChange} type={'text'} value={pageNumber} />
                    of {numPages}
                </span>

                <ToolbarItem
                    className="toolbar-icons"
                    img='assets/pdfReaderIcons/down.svg'
                    clickHandler={nextPage}
                />
            </span>

            <span className="item-parent">
                <ToolbarItem
                    className="toolbar-icons"
                    img='assets/pdfReaderIcons/minus.svg'
                    clickHandler={zoomOut}
                />

                <span className="toolbar-label" >
                    {zoomPrecentage}
                </span>
                <ToolbarItem
                    className="toolbar-icons"
                    img='assets/pdfReaderIcons/plus.svg'
                    clickHandler={zoomIn}
                />
            </span>

            <span className="item-parent">

                <ToolbarItem
                    className="toolbar-icons"
                    img='assets/pdfReaderIcons/page-fit.svg'
                    clickHandler={fitPage}
                />
                <ToolbarItem
                    className="toolbar-icons"
                    img='assets/pdfReaderIcons/page-width.svg'
                    clickHandler={widthPage}
                />
            </span>
            <span className="item-parent">
                <ToolbarItem
                    className="toolbar-icons-rotate"
                    img='assets/pdfReaderIcons/left.svg'
                    clickHandler={rotateNext}
                />
                <ToolbarItem
                    className="toolbar-icons-rotate"
                    img='assets/pdfReaderIcons/right.svg'
                    clickHandler={rotate}
                />

            </span>
        </div>
    );
}

