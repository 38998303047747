/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { getHeaderToken } from '../../../api/index'
import axios from 'axios'
import { Button } from 'primereact/button';
//import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import moment from 'moment';
//import { Link } from 'react-router-dom';
//import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from 'primereact/radiobutton';
import { APP_PROPERTIES } from '../../../properties/index';
import { saveAs } from 'file-saver';
import { videos } from '../../../properties/videos'
//import pageLogo from '../../../assets/images/page_icons/illustration helix export@2x.png'
//import pageLogo from '../../../assets/images/page_icons/helix_export.png'

//import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
//import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
//import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'

class SequenceExport extends Component {
    constructor(props) {
        super(props);
        this.formats = [{ name: 'FASTA (.zip) nucleotide', key: 'nucleotide' }, { name: 'FASTA (.zip) protein', key: 'protein' }];
        let columnsCollection = [
            { field: "check", selectionMode: 'single', body: this.selectTemplate, style: { width: '4em', fontWeight: 'normal' } },
            { field: "name", header: "Collection name", body: this.collectionNameTemplate, style: {} },
            { field: "description", header: "Description", style: {}, body: this.descriptionTemplate },
            { field: "numberDocuments", header: 'Documents', sortable: true, style: { textAlign: 'left' }, body: this.documentsTemplate },
            { field: "created", header: 'Created', body: this.createdTemplate, sortable: true, style: { textAlign: 'left' } },
            { field: "modified", header: 'Last modified', body: this.modifiedTemplate, sortable: true, style: { textAlign: 'left' } },
            { field: "shared", header: "Sharing", body: this.sharedOwnerTemplateCollection, style: { textAlign: 'left' } }
        ]
        let columns = [
            { field: "title", header: "Title", body: this.titleTemplate, sortable: true },
            { field: "repository", header: "Repository", body: this.repositoryTemplate, sortable: true, style: { textAlign: 'left' } },
            { field: "insertionTime", header: "Added to collection", body: this.insertionTimeTemplate, sortable: true, style: { textAlign: 'left' } }

        ]
        this.state = {
            fetchingExportResults: false,
            colsCollections: columnsCollection,
            cols: columns,
            firstDocuments: 0,
            rowsDocuments: 5,
            firstCollections: 0,
            rowsCollections: 10,
            displayDocuments: false,
            selectedCollectionName: '',
            selectedCollections: null,
            collectionDocuments: [],
            collectionName: '',
            selectedFormat: this.formats[0],
            pageTutorials: [],
            section: '',
            sectionLabel: ''
        }
        this.op = createRef();
    }

    componentDidMount() {

        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('sequenceExp')) });
        }

        /*let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/page/0/count/1000/list?onlyWithPatents=true`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    documentCollections: response.data.content
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })*/
    }

    collectionNameTemplate = (rowData) => {
        if (rowData.hasOwnProperty('name')) {
            let name = rowData.name
            return <React.Fragment>
                <span className="p-column-title">Collection name</span>
                <a id="collectionName"
                    onClick={(e) => this.showDocuments(rowData, e)}>{name}</a>
            </React.Fragment>
        }
    }

    descriptionTemplate = (rowData) => {
        if (rowData.hasOwnProperty('description')) {
            let description = rowData.description
            return <React.Fragment>
                <span className="p-column-title">Description</span>
                {description}
            </React.Fragment>
        }
    }

    documentsTemplate = (rowData) => {
        if (rowData.hasOwnProperty('numberDocuments')) {
            let numberDocuments = rowData.numberDocuments
            return <React.Fragment>
                <span className="p-column-title">Documents</span>
                {numberDocuments}
            </React.Fragment>
        }
    }

    createdTemplate(rowData) {
        if (rowData.hasOwnProperty('created')) {
            return <React.Fragment>
                <span className="p-column-title">Created</span>
                {moment(rowData.created + "Z").format("YYYY/MM/DD, LT")}
            </React.Fragment>
        }
    }

    modifiedTemplate(rowData) {
        if (rowData.hasOwnProperty('modified')) {
            return <React.Fragment>
                <span className="p-column-title">Modified</span>
                {moment(rowData.modified + "Z").format("YYYY/MM/DD, LT")}
            </React.Fragment>
        }
    }

    insertionTimeTemplate(rowData) {
        if (rowData.hasOwnProperty('insertionTime')) {
            return <React.Fragment>
                <span className="p-column-title">Added</span>
                {moment(rowData.insertionTime + "Z").format("YYYY/MM/DD, LT")}
            </React.Fragment>
        }
    }

    sharedOwnerTemplateCollection(rowData) {

        let iAmTheOwner = !rowData.shared;
        let sharedByMe = (rowData.sharedForDepartment || (rowData.usersSharedWith && rowData.usersSharedWith.length > 0));

        if (!APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons) {

            return (<React.Fragment>
                <span className="p-column-title">Sharing</span>
                <span>
                    {iAmTheOwner ?
                        sharedByMe ?
                            <span title={`You share this search collection with other users`}>
                                {'Shared by you'}
                            </span>
                            :
                            <span title={`This search collection can only be seen by you`}>
                                {'Private'}
                            </span>
                        :
                        <span title={`This search collection is shared with you by another user`}>
                            {'Shared with you'}
                        </span>
                    }
                </span>
            </React.Fragment>
            );
        }

        return (
            <span>
                <span className="material-icons"
                    title={!iAmTheOwner ? 'This search collection is shared with you by another user' : 'You are the owner of this search collection'}>
                    {!iAmTheOwner ? 'person_outline' : 'person'}
                </span>
                {iAmTheOwner ? sharedByMe ?
                    <span className="material-icons"
                        title={`You share this search collection with other users`}>
                        {'visibility'}
                    </span>
                    :
                    <span className="material-icons"
                        title={`This search collection can only be seen by you`}>
                        {'visibility_off'}
                    </span>
                    : null
                }
            </span>
        );
    }

    titleTemplate = (rowData) => {
        if (rowData.hasOwnProperty('title')) {
            let title = rowData.title
            return <React.Fragment>
                <span className="p-column-title">Title</span>
                <a style={{ cursor: 'default' }}>{title}</a>
            </React.Fragment>
        }
    }

    repositoryTemplate = (rowData) => {
        if (rowData.hasOwnProperty('repository')) {
            let repLabel = ''
            if (rowData.repositoryAvailable === false) {
                repLabel = rowData.repository //+ ' (Repository not available)'
            } else {
                this.props.userData.userDetails.department?.selectedRepositories &&
                    this.props.userData.userDetails.department?.selectedRepositories.filter(rep => rep.name === rowData.repository).forEach(repo => {
                        repLabel = repo.label
                    })
            }
            return <React.Fragment>
                <span className="p-column-title">Repository</span>
                {repLabel}
            </React.Fragment>
        }
    }

    showDocuments = (rowData) => {
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${rowData.id}/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    collectionDocuments: response.data.content,
                    collectionName: rowData.name,
                    displayDocuments: true
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleExport = () => {
        let self = this
        if (self.state.selectedFormat.key === "nucleotide") {
            self.setState({
                fetchingExportResults: true
            })
            //axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${self.state.selectedCollections.id}/sequences/nucleotide/fasta`, { headers: getHeaderToken(), responseType: 'blob' })
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${self.props.selectedCollection.id}/sequences/nucleotide/fasta`, { headers: getHeaderToken(), responseType: 'blob' })
                .then(function (response) {
                    //console.log(response)
                    if (response.status !== 204) {
                        self.setState({
                            fetchingExportResults: false
                        })
                        //saveAs(new Blob([response.data]), `nucleotide_sequences_${self.state.selectedCollections.name}.fasta.zip`)
                        saveAs(new Blob([response.data]), `nucleotide_sequences_${self.props.selectedCollection.name}.fasta.zip`)
                    } else {
                        self.showExportEmptyGrowl(response.statusText)
                        self.setState({
                            fetchingExportResults: false
                        })
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showExportFailGrowl()
                        self.setState({
                            fetchingExportResults: false
                        })
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (self.state.selectedFormat.key === "protein") {
            self.setState({
                fetchingExportResults: true
            })
            //axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${self.state.selectedCollections.id}/sequences/protein/fasta`, { headers: getHeaderToken(), responseType: 'blob' })
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${self.props.selectedCollection.id}/sequences/protein/fasta`, { headers: getHeaderToken(), responseType: 'blob' })
                .then(function (response) {
                    if (response.status !== 204) {
                        self.setState({
                            fetchingExportResults: false
                        })
                        //saveAs(new Blob([response.data]), `protein_sequences_${self.state.selectedCollections.name}.fasta.zip`)
                        saveAs(new Blob([response.data]), `protein_sequences_${self.props.selectedCollection.name}.fasta.zip`)
                    } else {
                        self.showExportEmptyGrowl(response.statusText)
                        self.setState({
                            fetchingExportResults: false
                        })
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showExportFailGrowl()
                        self.setState({
                            fetchingExportResults: false
                        })
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    showExportFailGrowl = (e) => {
        //console.log(e)
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Sequences could not be exported. Check if repository Patents is active.', life: 6000 };
        this.growl.show(msg)
    }

    showExportEmptyGrowl = (e) => {
        //console.log(e)
        let msg = { severity: 'info', summary: 'No sequences.', detail: 'This collection contains no sequences.', life: 6000 };
        this.growl.show(msg)
    }

    handleCollectionSelectionChange = (e) => {
        //console.log(e.value)
        this.setState({
            selectedCollections: e.value
        })
    }

    onCustomPage = (e) => {
        this.setState({
            firstCollections: e.first,
            rowsCollections: e.rows
        })
    }

    render() {

        const fetchingData = this.state.fetchingExportResults

        //console.log(this.state.containsPatents)
        //console.log(this.state.documentCollections)

        if (this.props.userData.userDetails.department?.selectedRepositories) {
            this.patentRepo = this.props.userData.userDetails.department?.selectedRepositories.find(obj => {
                return obj.name === 'patents'
            })
        }

        let collectionDocuments = []

        this.state.collectionDocuments && this.state.collectionDocuments.forEach(doc => {
            collectionDocuments = [...collectionDocuments, Object.assign(doc.document, { id: doc.id, insertionTime: doc.insertionTime, relevance: doc.relevance })]
        })

        /*let documentMessage
        if (collectionDocuments) {
            collectionDocuments.length === 1 ? documentMessage = `${collectionDocuments.length} document` : documentMessage = `${collectionDocuments.length} documents`
        }*/

        let collections = []

        this.state.documentCollections && this.state.documentCollections.forEach((collection, i) => {
            if (collection.numberDocuments > 0) {
                collections = [...collections, collection]
            }
        })

        let columnDataCollections = this.state.colsCollections.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} sortFunction={col.sortFunction} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        let columnData = this.state.cols.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        /*let collectionMessage
        if (collections) {
            collections.length === 1 ? collectionMessage = `${collections.length} collection` : collectionMessage = `${collections.length} collections`
        }*/

        let footerCollection = <div style={{ textAlign: 'right', marginRight: 3 }}>
            <div>
                <div style={{ display: 'inline', verticalAlign: 'super' }}>
                    <label style={{ color: 'black', marginRight: 10 }}>Export format:</label>
                </div>
                {
                    this.formats.map((format) => {
                        return (
                            <div style={{ display: 'inline', verticalAlign: 'super' }} key={format.key} className="p-field-radiobutton">
                                <RadioButton inputId={format.key} name="format" value={format} onChange={(e) => this.setState({ selectedFormat: e.value })} checked={this.state.selectedFormat.key === format.key} />
                                <label style={{ color: 'black', marginRight: 10 }} htmlFor={format.key}>{format.name}</label>
                            </div>
                        )
                    })
                }
                <Button style={{ marginTop: 5, marginLeft: 10, marginRight: -10 }}
                    className='p-button-sm primaryButton'
                    label="Export" tooltip='Export sequences' tooltipOptions={{ position: "bottom" }}
                    onClick={this.handleExport}
                //disabled={this.state.selectedCollections !== null ? false : true}
                />
            </div>
        </div>

        return (
            <div className="grid" style={{ height: 173, marginRight: -12 }}>
                <Toast ref={(el) => { this.growl = el }} />

                {/* 
                <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                    {!isArrayEmpty(this.state.pageTutorials) ?
                      <span style={{ marginLeft: 30 }}>
                        <TutorialVideos
                          pageTutorials={this.state.pageTutorials}
                          section='sequenceExp'
                          sectionLabel='Sequence Exporter'
                        /></span> : null}
                  </div>
                  */}

                {/*APP_PROPERTIES.APP_ID === 'dimensions' && this.state.pageTutorials && this.state.pageTutorials.length > 0 ?
                    <OverlayPanel className='tutorialOverlay' ref={(el) => { this.op = el }}>
                        {panelContentVideos}
                    </OverlayPanel>
                    : null
        */}

                {/*<div className='col-12' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1 style={{ marginTop: 0, marginLeft: -5 }} className="pageHeader">Sequence Exporter</h1>
                        <img src={pageLogo} alt={`library logo`} style={{ height: 120, marginRight: 15, marginTop: -10 }} />
        </div>*/}
                {/*<div className='col-12' style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <div className='grid'>
                        <div className="col textAlignLeft">
                                <h1 style={{ marginBottom: 0 }} className="pageHeader">Sequence Exporter</h1>
    </div>
                        {/*APP_PROPERTIES.APP_ID === 'dimensions' && this.state.pageTutorials && this.state.pageTutorials.length > 0 ?
                            <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                                <Button className="youtube p-p-0" style={{ backgroundColor: 'white', border: 'none' }}
                                    onClick={(e) => this.op.toggle(e)}>
                                    <i className="pi pi-youtube p-px-2" style={{ color: 'black', paddingRight: 0 }}></i>
                                    <span style={{ color: 'black', paddingLeft: "0px !important" }}>{this.state.pageTutorials && this.state.pageTutorials.length > 1 ? "Tutorials available" : "Tutorial available"}</span>
                                </Button>
                            </div>
                            : null

                    </div>
</div>*/}
                <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-12" style={{
                    paddingLeft: 6, paddingTop: 5, height: 160, borderBottom: '1px solid #d6d6d6'
                    //paddingLeft: 0, paddingBottom: 25, marginTop: -85 
                }}>
                    <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', lineHeight: "20px" }}>
                        Use the Sequence Exporter in order to quickly bulk-export sequences from many documents of the repository {`${this.patentRepo !== undefined ? this.patentRepo.label : "Patents"}`} at the same time.
                        {/*Create a new document collection or use an existing one and add all documents of interest to it.
                Just choose the document collection and the output format you need.*/}
                    </label>
                </div>
                {/*collections.length > 0 ?
                        <div className="datatable-responsive-demo-smaller" style={{ marginTop: 20 , paddingLeft: 5}}>
                            <DataTable ref={(el) => this.dt = el}
                                selection={this.state.selectedCollections}
                                onSelectionChange={e => this.handleCollectionSelectionChange(e)}
                                selectionMode="radiobutton"
                                //className="dataTableQueries standardTableHeader"
                                className="p-datatable-responsive-demo-smaller"
                                removableSort={true}
                                value={collections}
                                responsive={true}
                                paginator={true}
                                paginatorPosition="bottom"
                                rows={this.state.rowsCollections}
                                footer={footerCollection}
                                first={this.state.firstCollections}
                                rowsPerPageOptions={[10, 20, 50]}
                                onPage={(e) => this.onCustomPage(e)}
                                //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                paginatorTemplate={CustomPaginatorTemplate}
                                paginatorLeft={<CustomPaginatorLeftSide
                                    first={this.state.firstCollections}
                                    contentLength={collections.length}
                                    rows={this.state.rowsCollections}>
                                </CustomPaginatorLeftSide>}
                                paginatorRight={<CustomPaginatorRightSide
                                    rows={this.state.rowsCollections}
                                    onChange={(e) => {
                                        this.setState({
                                            rowsCollections: e.value
                                        })
                                    }}
                                ></CustomPaginatorRightSide>}
                            //currentPageReportTemplate={collectionMessage} 
                            >
                                {columnDataCollections}
                            </DataTable>
                        </div>
                        :
                        <div className="dataTable" style={{ marginTop: 20 }}>
                            <label style={{ marginLeft: 5, marginRight: 10, marginBottom: 20, verticalAlign: 'bottom', fontWeight: 'bold' }}>
                                No document collection available that contains patents.</label>
                        </div>
                                */}
                <div className='col-12' style={{}}>
                    {footerCollection}
                </div>
                <Dialog focusOnShow={false} visible={this.state.displayDocuments} style={{ 'width': "60vw" }} header={`Documents of ${this.state.collectionName}`}
                    modal={true} dismissableMask={true}
                    onHide={() => this.setState({ displayDocuments: false })}>
                    <div className="datatable-responsive-demo-smaller" style={{ marginTop: 15, marginBottom: 15 }}>
                        <DataTable ref={(el) => this.dt = el}
                            appendTo={document.body}
                            removableSort={true}
                            //scrollable
                            //scrollHeight='400px'
                            //className="dataTableQueries checkboxCircle standardTableHeader"
                            className="p-datatable-responsive-demo-smaller"
                            value={collectionDocuments}
                            responsive="true"
                            paginator={true}
                            paginatorPosition="bottom"
                            rows={this.state.rowsDocuments}
                            first={this.state.firstDocuments}
                            rowsPerPageOptions={[5, 10]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        //currentPageReportTemplate={documentMessage}
                        >
                            {columnData}
                        </DataTable>
                    </div>
                </Dialog>
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                </LoadingOverlay>
            </div>
        )
        {/*<div className="col-12 grid-nogutter">
                <Toast ref={(el) => { this.growl = el }}  />
                    <h1 className="pageHeader">Sequence search</h1>
                    <div className='grid'>
                        <div className='col-12'>
                            <label style={{ marginRight: 10, marginBottom: 20, verticalAlign: 'bottom', fontWeight: 'normal' }}>
                                You can bulk export sequences from patents. You can use <Link to='/my/library'>My Library</Link> to create a collection of documents that you
                                   want to export all sequences from. Just add documents to an existing collection or create a new one. You can choose the desired output format.
                                   <br></br> In both cases you will download a ZIP archive containing the desired sequences.<br /><br /></label>
                        </div>
                        <div className='col-4'>
                            <Card>
                                <div style={{ marginBottom: 15 }}>
                                    <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'bold' }}>Choose collection you want to export from</label>
                                    <div>
                                        <Dropdown value={this.state.collection} options={collections} onChange={this.onCollectionChange} style={{ width: '20em', marginTop: 10 }}
                                            filter={true} placeholder={collections.length > 0 ? "Choose collection" : "No valid collection available"} filterPlaceholder="Filter Collections" filterBy="label" showClear={true} disabled={collections.length > 0 && this.state.patentNumber === "" ? false : true} />
                                    </div>
                                </div>
                                <div style={{ textAlign: 'left', marginBottom: 10, marginTop: 10 }}>
                                    <label style={{ marginRight: 10, fontWeight: 'bold' }}>Choose output format</label>
                                </div>
                                <div style={{ marginTop: 0, textAlign: 'left' }}>
                                    <Button label="FASTA (.zip) nucleotide"
                                        className='primaryButton p-button-sm'
                                        //disabled={this.state.query.length > 0 ? false : true}
                                        icon='pi-md-file-download'
                                        onClick={this.handleExportFASTANucleotide}
                                        disabled={this.state.collection !== null || this.state.patentNumber !== "" ? false : true}
                                        style={{ marginLeft: 0, marginTop: 0 }} />
                                    <Button label="FASTA (.zip) protein"
                                        className='primaryButton p-button-sm'
                                        //disabled={this.state.query.length > 0 ? false : true}
                                        icon='pi-md-file-download'
                                        onClick={this.handleExportFASTAProtein}
                                        disabled={this.state.collection !== null || this.state.patentNumber !== "" ? false : true}
                                        style={{ marginLeft: 0, marginTop: 0 }} />
                                </div>
                            </Card>
                        </div>
                    </div>
        </div>*/}
    }
}

export default SequenceExport
