import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
//import {Lightbox} from 'primereact/lightbox';
import { Dialog } from 'primereact/dialog';

import { deleteJobs, getJobErrors } from '../../../../redux/actions';

class Job extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // is this job about to be deleted? (-> for displaying a loading sign)
      deleting: false,
      errorVisible: {},
    };
  }

  isErrorVisible(filename) {
    return this.state.errorVisible[filename]
  }

  setErrorVisible(filename, visible) {
    const nv = { ...this.state.errorVisible };
    nv[filename] = visible;
    this.setState({ errorVisible: nv });
  }

  render() {

    let className = this.props.job.status;
    if (className === "FINISHED") {
      if (this.props.job.failedDocuments === 0)
        className += "-success";
      else
        className += "-warn";
    }

    let deleteJob = "";
    let progressBar = <React.Fragment></React.Fragment>
    if (this.props.job.status === "PROCESSING") {
      const progress = 100 * (this.props.job.failedDocuments + this.props.job.processedDocuments) / this.props.job.totalDocuments

      progressBar = <ProgressBar className="job-progress" value={Math.round(progress)} label={progress} unit="% done" />
    }
    else {
      deleteJob = <Button label={this.state.deleting ? "Deleting" : "Delete"}
        className="p-button-text dangerButtonLink" title={`Remove this job`}
        onClick={() => this.deleteJob()}
      />
      {/*<Button icon={this.state.deleting ? "pi-spin pi-spinner" : "pi-md-trash" } 
        className="p-button-danger p-button-sm" onClick={() => this.deleteJob ()} tooltip={"Remove this job"}/>*/}
    }

    //console.log('this.props.jobErros: ', this.props.jobErros);
    //console.log('this.props.jobErrors: ', this.props.jobErrors);

    let errors = "";
    let failButton = "";
    if (this.props.job.failedDocuments > 0 && this.props.job.status !== "PROCESSING") {
      failButton = <Button onClick={() => this.getJobErrors()} className="p-button-text primaryButtonLink"
        label="Show" tooltip={"Check what failed"} />
      if (this.props.jobErros[this.props.job.id]) {
        errors = <React.Fragment>
          <strong>The following list shows the first 10 files that failed to process. Klick the file name to see the error:</strong>
          <ul>
            {
              Object.keys(this.props.jobErros[this.props.job.id]).map((e) =>
                <li key={this.props.job.id + "-" + e}>

                  <Dialog header={e} visible={this.state.errorVisible[e]} style={{ width: '50vw' }} modal={true} onHide={() => this.setErrorVisible(e, false)}>
                    <p>The following error occurred while processing <code>{e}</code>:</p>
                    <iframe className="jobFileError" srcDoc={this.props.jobErros[this.props.job.id][e]} />
                  </Dialog>

                  <Button label={e} className="p-button-text primaryButtonLink" onClick={(evt) => this.setErrorVisible(e, true)} />

                </li>
              )
            }
          </ul>
        </React.Fragment>
      }
    }


    return (
      <div className={"grid p-align-center job-status-" + className}>
        <div className="col-12 md:col-2 lg:col-2"><strong>{this.props.job.id} by {this.props.job.user}</strong></div>
        <div className="col-12 md:col-3 lg:col-3">{this.props.job.description}</div>
        <div className="col-12 md:col-2 lg:col-2"><code>{this.props.job.filePath}</code></div>
        <div className="col-12 md:col-4 lg:col-4">
          Status: <code>{this.props.job.status}</code><br />
            Created: <code>{new Date(this.props.job.creationTime).toLocaleString()}</code> <br />
            Last Change: <code>{new Date(this.props.job.statusChange).toLocaleString()}</code> <br />
            Processed: <code>{this.props.job.processedDocuments}/{this.props.job.totalDocuments}</code> <br />
            Failed: <code>{this.props.job.failedDocuments}</code> {failButton}<br />
          {errors}
        </div>
        <div className="col-12 md:col-1 lg:col-1">
          {deleteJob}
        </div>
        { progressBar}
      </div>
    );
  }

  deleteJob() {
    this.props.deleteJobs(this.props.jobsRows, this.props.jobsPage, "job id " + this.props.job.id, this.props.job.id + "")
  }

  getJobErrors() {
    this.props.getJobErrors(this.props.job.id);
  }
}


const mapStateToProps = state => {
  return {
    jobsPage: state.managerReducer.jobsPage,
    jobsRows: state.managerReducer.jobsRows,
    jobErros: state.managerReducer.jobErros,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteJobs: (count, page, jobDescriptions, annotationJobIds) => dispatch(deleteJobs(count, page, jobDescriptions, annotationJobIds)),
    getJobErrors: (jobId) => dispatch(getJobErrors(jobId)),
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Job);
