import { Checkbox } from "primereact/checkbox";
import Badge from "../../../../../../common/queryBuilder/common/Badge";
import SearchGroupStatus from "../../../../../../common/queryBuilder/searchForm/searchGroup/SearchGroupStatus";


const GroupInfoColumn = ({
  data,
  isStartGroup,
  isReachable,
  isCheckboxVisible,
  isCheckboxDisabled,
  errorMessage,
  hasSearchError,
  batchValue,
  batchStyles = {},
  onSelect,
  selectedSearchGroups
}) => {

  return (
    <div className="inline-flex align-items-center h-full">
      <SearchGroupStatus
        isStartGroup={isStartGroup}
        isReachable={isReachable}
        showErrorsOnly={hasSearchError}
        errorMessage={errorMessage}
      />
      <span title={data.id}>
        <Badge
          value={batchValue}
          className={data.type}
          styles={{ ...batchStyles }}
        />
      </span>
      {isCheckboxVisible && (
          <Checkbox
            onChange={(e) => onSelect(data, e.checked)}
            checked={selectedSearchGroups?.includes(data.id)}
            disabled={isCheckboxDisabled}
            style={{ marginLeft: 10 }}
            title={data.id}
          ></Checkbox>
        )}
    </div>
  );
}

export default GroupInfoColumn;