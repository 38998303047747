export const BASE_URL_PUBMED = 'https://pubmed.ncbi.nlm.nih.gov/';
export const BASE_URL_DOI = 'https://doi.org/';
export const BASE_URL_OAI = 'https://oai.core.ac.uk/';


export const createPubmedLink = (pmid) => {
    return pmid ? `${BASE_URL_PUBMED}${pmid}` : '';
}

export const createDOILink = (doi) => {
    return doi ? `${BASE_URL_DOI}${doi}` : '';
}

export const createOAILink = (oai) => {
    return oai ? `${BASE_URL_OAI}${oai}` : '';
}