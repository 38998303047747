import React, { Component } from 'react';
import { roundNumber } from '../../../util';
import ReactionExtraInfo from './ReactionExtraInfo';

class ReactionExtraInfos extends Component {

    getExtraInfosFromReaction = () => {

        const { reaction, mculeLinks, showCompoundInfo } = this.props;

        if (!reaction) { return []; }

        //reaction.productName = "My product";
        //reaction.productOcid = 190000005342;

        const extraInfos = [];
        if (reaction.transformName) { extraInfos.push({ key: 'transName', value: reaction.transformName, label: reaction.transformName, copyOnClick: true }); }
        //if (reaction.yield) { extraInfos.push({ key: 'yield', value: roundNumber(reaction.yield, 1), label: `Yield: ${roundNumber(reaction.yield, 1)} %`, copyOnClick: true }); }
        if (reaction.molWeight) { extraInfos.push({ key: 'molWeight', value: `${roundNumber(reaction.molWeight, 1)}`, label: `Mol. weight: ${roundNumber(reaction.molWeight, 1)}`, copyOnClick: true }); }
        if (reaction.numOfHydrogenDonors) { extraInfos.push({ key: 'hDon', value: `${roundNumber(reaction.numOfHydrogenDonors, 0)}`, label: `H-donors: ${roundNumber(reaction.numOfHydrogenDonors, 0)}`, copyOnClick: true }); }
        if (reaction.numOfHydrogenAcceptors) { extraInfos.push({ key: 'hAcc', value: `${roundNumber(reaction.numOfHydrogenAcceptors, 0)}`, label: `H-acceptors: ${roundNumber(reaction.numOfHydrogenAcceptors, 0)}`, copyOnClick: true }); }
        if (reaction.numOfRotatableBonds) { extraInfos.push({ key: 'rotBonds', value: `${roundNumber(reaction.numOfRotatableBonds, 0)}`, label: `Rot. bonds: ${roundNumber(reaction.numOfRotatableBonds, 0)}`, copyOnClick: true }); }
        if (reaction.logP) { extraInfos.push({ key: 'logP', value: `${roundNumber(reaction.logP, 1)}`, label: `logP: ${roundNumber(reaction.logP, 1)}`, copyOnClick: true }); }
        if (reaction.rsmi) { extraInfos.push({ key: 'rsmi', value: reaction.rsmi, label: 'RSMI', copyOnClick: true, tooltip: reaction.rsmi }); }
        if (reaction.rinchi) { extraInfos.push({ key: 'rinchi', value: reaction.rinchi, label: 'RInChI', copyOnClick: true, tooltip: reaction.rinchi }); }
        if (reaction.rinchikey) { extraInfos.push({ key: 'rinchiKey', value: reaction.rinchikey, label: 'RInChI Key', copyOnClick: true, tooltip: reaction.rinchikey }); }
        //if (reaction.sourcesection) { extraInfos.push({ key: 'srcSection', value: reaction.sourcesection, label: `Section: ${reaction.sourcesection}`, copyOnClick: false, tooltip: reaction.context }); }
        // Product name reaction.productName 
        // Product SMILES reaction.productSmiles
        // Product OCID reaction.productOcid

        if (reaction.productName && showCompoundInfo) {
            //const self = this;
            extraInfos.push({
                key: 'product', value: reaction.productOcid, label: `Product: ${reaction.productName}`, tooltip: 'Load product details',
                onClickFunction: reaction.productOcid ? function (productOcid) { showCompoundInfo(productOcid); } : null
            });
        }

        if (mculeLinks && mculeLinks[reaction.productSmiles]) {
            //const self = this;
            extraInfos.push({
                key: 'product', value: reaction.productOcid,
                label: `Product Mcule link: ${mculeLinks[reaction.productSmiles].replace('https://mcule.com/', '').replace(/\/$/, '')}`,
                onClickFunction: function () { window.open(`${mculeLinks[reaction.productSmiles]}`, '_blank'); }
            });
        }

        return extraInfos;
    }

    render() {
        const extraInfos = this.getExtraInfosFromReaction();
        //console.log('extraInfos: ', extraInfos);
        const length = extraInfos.length;

        return (
            extraInfos?.map((extraInfo, index) => {
                return <React.Fragment key={extraInfo.key}>
                    <ReactionExtraInfo
                        extraInfo={extraInfo}
                        addSeparator={index < length - 1}
                    />
                </React.Fragment>
            })
        )
    }
}
export default ReactionExtraInfos