import React, {useRef} from 'react';
import styles from "./TitleWithTooltip.module.scss";
import iconInfo from "../../../assets/images/icons/info/dimensions-icon-info.png";
import {OverlayPanel} from "primereact/overlaypanel";
import classnames from "classnames";

const TitleWithTooltip = ({children, tooltipText, altImageText, tooltipExtraClassNames}) => {
    const tooltipClassnames = classnames(styles.tooltip, tooltipExtraClassNames)

    const overlayPanel = useRef(null);

    const toggleOverlayPanel = (event) => {
        overlayPanel.current.toggle(event);
    };

    return (
        <div className={styles.title}>

            {children}

            <img onClick={toggleOverlayPanel}
                 src={iconInfo} alt={altImageText}/>

            <OverlayPanel ref={overlayPanel}
                          className={tooltipClassnames}
                          dismissable={true}
            >


                {tooltipText}

            </OverlayPanel>
        </div>
    );
};

export default TitleWithTooltip;
