import { Component } from "react";
import { createGoogleSearchUrl } from '../../webapi/util';

class GoogleLinks extends Component {

    render() {
        const { smiles } = this.props;

        return (
            smiles && smiles.length > 0 ?
                <ul className="paddingSmall increasedLineHeight noStyleList">
                    <li>
                        Exact search:
                        <br />
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('SMILES', smiles[0], 'PATENTS')}>Patents</a>
                        <span> | </span>
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('SMILES', smiles[0], 'NPL')}>Non-Patent Literature</a>
                        <span> | </span>
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('SMILES', smiles[0], 'BOTH')}>Both</a>
                    </li>
                    <li>
                        Substructure search:
                        <br />
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('SSS', smiles[0], 'PATENTS')}>Patents</a>
                        <span> | </span>
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('SSS', smiles[0], 'NPL')}>Non-Patent Literature</a>
                        <span> | </span>
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('SSS', smiles[0], 'BOTH')}>Both</a>
                    </li>
                    <li>
                        Similarity search:
                        <br />
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('~SMILES', smiles[0], 'PATENTS')}>Patents</a>
                        <span> | </span>
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('~SMILES', smiles[0], 'NPL')}>Non-Patent Literature</a>
                        <span> | </span>
                        <a target="_blank" rel="noopener noreferrer"
                            href={createGoogleSearchUrl('~SMILES', smiles[0], 'BOTH')}>Both</a>
                    </li>
                </ul> : null
        );
    }
}

export default GoogleLinks;
