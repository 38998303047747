import React, { useMemo, useState } from "react";
import TruncateEntriesLink from "../../../common/TruncateEntriesLink";

const FilterGroupCompact = ({ queryTerms, operator, filterTermsListMax }) => {
  const [showAll, setShowAll] = useState(false);
  const totalNumberOfTerms = queryTerms.length;
  const queryTermsTruncated = useMemo(
    () => (showAll ? queryTerms : queryTerms.slice(0, filterTermsListMax)),
    [showAll, queryTerms, filterTermsListMax]
  );
  return (
    <div className="flex align-items-center flex-wrap line-height-3">
      {queryTermsTruncated.map(({ filter, label }, index) => {
        return (
          <React.Fragment key={label}>
            <div>
              <b>
                {filter?.label || "Filter"}: {label}
              </b>
            </div>
            {index < totalNumberOfTerms - 1 && (
              <div>
                <span className="search-group-operator">
                  {operator?.label || operator}
                </span>
              </div>
            )}
          </React.Fragment>
        );
      })}
      <TruncateEntriesLink
        visible={filterTermsListMax < queryTerms.length}
        numberOfEntries={totalNumberOfTerms}
        showAll={showAll}
        onClick={setShowAll}
      />
    </div>
  );
};

export default FilterGroupCompact;
