import "./ExportCenterSuccessMessage.css";
import { Link } from 'react-router-dom';


const ExportCenterSuccessMessage = (props) => {
    return <>
        <div>You will receive an e-mail as soon as your export is finished. Just visit</div>
        <div className="export-center-link-container">
            <Link
                to={props.toReadcubeExports ? "/my/export-center?tab=readcube" : "/my/export-center"}
                target="_blank"
                title='Visit "My Exports"'
                className="export-center-message-link">
                My Research &rarr; My Exports
            </Link>
        </div>
    </>
}

export default ExportCenterSuccessMessage;