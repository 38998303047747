import loginBGImage from '../../assets/images/bg/shutterstock_40856230_min.jpg';
import appLogo from '../../assets/images/logos/boehringer/BI.png';

export const APP_PROPERTIES = {
  // --- logos for login page and header --- //
  LOGIN_BG_IMAGE: loginBGImage,

  HEADER_LOGO_APP: appLogo,
  HEADER_LOGO_APP_STYLE: {
    height: 46,
    margin: '2px 5px',
    position: 'relative',
    top: -5,
  },

  // --- feedback and support --- //
  SUPPORT_FORM_URL:
    'https://forms.monday.com/forms/312f55bdb5829b7c7f342002021f19b7',

  // --- active pages --- //
  ACTIVE_PAGES: {},

  // --- active modules or functionalities --- //
  ACTIVE_FUNCTIONALITIES: {
    showDocumentsOnStart: true,
    showIcons: true,
    fetchSortableFields: true,
  },

  // --- chemistry-related settings --- //
  CHEMISTRY: {
    showGoogleLinks: true,
  },
} as const;
