import { useSelector } from "react-redux";
import { sortObjectArrayByAttribute } from "../../util";


const useMatchingDocumentRepositoriesData = (repositoryNames = []) => {

    const repositoriesList = useSelector((state) => state.user.data.userDetails.department.selectedRepositories);
    const repositoriesListFiltered = repositoriesList?.filter(repo => repositoryNames.includes(repo.name) && repo.features?.includes('DOCUMENT_SEARCH'));
    return sortObjectArrayByAttribute(repositoriesListFiltered, 'orderPriority', false);
}

export default useMatchingDocumentRepositoriesData;