/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea'
import { getHeaderToken } from '../../../api/index'
import axios from 'axios'
import { APP_PROPERTIES } from '../../../properties/index';
import { addThousandsSeparatorToNumber } from '../util';


class BulkExport extends Component {
    constructor(props) {
        super(props);
        //this.formats = [{ name: 'ZIP', key: 'zip' }, { name: 'XLS', key: 'xls' }, { name: 'RIS', key: 'ris' }]
        this.formats = [{ label: 'ZIP', value: 'zip' }, { label: 'XLS', value: 'xls' }, { label: 'RIS', value: 'ris' }]
        this.documentContentParts = [{ label: 'Full document', value: "full" }, { label: 'Title + abstract', value: 'title,abstract' }
            /*, { label: 'Section', value: 'section' },
            { label: 'Heading', value: 'heading' }, { label: 'Title and abstract', value: 'title,abstract' }, { label: 'Title and section', value: 'title,section' },
                , { label: 'Title and heading', value: 'title,heading' }, { label: 'Abstract and section', value: 'abstract,section' },
            { label: 'Abstract and heading', value: 'abstract,heading' }, { label: 'Section and heading', value: 'section,heading' },
            { label: 'Title, abstract and section', value: 'title,abstract,section' }, { label: 'Title, abstract and heading', value: 'title,abstract,heading' },
            { label: 'Title, section and heading', value: 'title,section,heading' }, { label: 'Abstract, section and heading', value: 'abstract,section,heading' }*/
        ]
        this.documentContentPartsPatents = [{ label: 'Full document', value: "full" },
        { label: 'Title + abstract', value: 'title,abstract' }, { label: 'Title + claims + abstract', value: 'title,claims,abstract' }
            /*, { label: 'Section', value: 'section' },
            { label: 'Heading', value: 'heading' }, { label: 'Title and abstract', value: 'title,abstract' }, { label: 'Title and section', value: 'title,section' },
                , { label: 'Title and heading', value: 'title,heading' }, { label: 'Abstract and section', value: 'abstract,section' },
            { label: 'Abstract and heading', value: 'abstract,heading' }, { label: 'Section and heading', value: 'section,heading' },
            { label: 'Title, abstract and section', value: 'title,abstract,section' }, { label: 'Title, abstract and heading', value: 'title,abstract,heading' },
            { label: 'Title, section and heading', value: 'title,section,heading' }, { label: 'Abstract, section and heading', value: 'abstract,section,heading' }*/
        ]
        this.getFullDocumentsOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
        this.state = {
            selectedFormat: this.formats[0].value,
            panelCollapsed: true,
            selectedDocumentPart: "full",
            selectedDocumentPartPatents: "full",
            getFullDocuments: true
        }
    }

    handleDocumentPartChange = (e) => {
        if (this.props.repositoryInfo.name === 'patents') {
            this.setState({
                selectedDocumentPartPatents: e.value
            })
        } else {
            this.setState({
                selectedDocumentPart: e.value
            })
        }
    }

    handleExport = async () => {
        console.log(this.props.starQuery)
        if (this.props.numberDocuments > 50000) {
            this.showBulkExportWarnGrowl()
        } else {
            let self = this
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/bulk-export/v1/query/archive?repositoryId=${self.props.repository}`, {
                documentContentParts: self.state.selectedFormat !== 'zip' ? null : (self.props.repositoryInfo.name === 'patents' && self.state.selectedDocumentPartPatents !== "full") ?
                    self.state.selectedDocumentPartPatents : (self.props.repositoryInfo.name !== 'patents' && self.state.selectedDocumentPart !== "full") ? self.state.selectedDocumentPart
                        : null,
                format: self.state.selectedFormat,
                getFullDocumentIfDocumentPartsMissing: (self.props.repositoryInfo.name === 'patents' && this.state.selectedDocumentPartPatents === "full")
                    || (self.props.repositoryInfo.name !== 'patents' && this.state.selectedDocumentPart === "full") ||
                    (self.state.selectedFormat !== 'zip') ? null : self.state.getFullDocuments,
                query: this.props.starQuery ? 'all:"*" dis:* proteins:* drugs:*' : self.props.internalQuery.replace('+facet:"DOMAIN__chem,DOMAIN__species,DOMAIN__substances,DOMAIN__drugs,DOMAIN__companies,Inventor,Assignee,Author"^20', ''),
                repositoryId: self.props.repository,
                email: self.props.userData.userDetails.email
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.showBulkExportSuccessGrowl()
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showBulkExportFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        //self.showEditCompanyFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    showBulkExportSuccessGrowl = () => {
        let msg = { life: 5000, severity: 'success', summary: 'Success!', detail: `Request successfully sent. You will receive an email containing a link that you can use to download the requested documents.` };
        this.growl.show(msg)
    }

    showBulkExportWarnGrowl = () => {
        let msg = { life: 5000, severity: 'warn', summary: 'Warning!', detail: `Bulk export is limited to ${addThousandsSeparatorToNumber(50000)} documents. Current number of documents: ${addThousandsSeparatorToNumber(this.props.numberDocuments)}.` };
        this.growl.show(msg)
    }

    showBulkExportFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    render() {

        //console.log(this.props)

        return (
            <React.Fragment>
                <div className="col-12 grid-nogutter" style={{ paddingBottom: 0, height: 227, borderBottom: '1px solid #d6d6d6' }}>
                    <Toast ref={(el) => { this.growl = el }} />
                    {/*<Fieldset legend="Bulk export" toggleable collapsed={this.state.panelCollapsed} onToggle={(e) => this.setState({ panelCollapsed: e.value })} style={{ marginBottom: 20 }}>*/}
                    <div className="grid p-fluid">
                        {/*<div className="col-12 sm:col-6 md:col-6 lg:col-4 xl:col-5" style={{ padding: '.5em', paddingBottom: 0 }}>*/}
                        <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" style={{ padding: '.5em', paddingBottom: 10 }}>
                            <label>Search</label>
                            <InputTextarea cols={30}
                                readOnly={true}
                                value={this.props.internalQuery && !this.props.starQuery ? this.props.internalQuery.replace('+facet:"DOMAIN__chem,DOMAIN__species,DOMAIN__substances,DOMAIN__drugs,DOMAIN__companies,Inventor,Assignee,Author"^20', '')
                                    : 'all:"*" dis:* proteins:* drugs:*'}
                                style={{ marginTop: 10 }} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" style={{ padding: '.5em', paddingBottom: 10 }}>
                            <label>EMail</label>
                            <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 10 }} id="userEmail"
                                disabled={true} value={this.props.userData.userDetails.email} />
                        </div>
                        {/*<div className="col-12 sm:col-6 md:col-6 lg:col-2 xl:col-2" style={{ padding: '.5em', paddingBottom: 0 }}>*/}
                        <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" style={{ padding: '.5em', paddingBottom: 10 }}>
                            <label>Document parts</label>
                            <Dropdown
                                className='switch'
                                appendTo={document.body}
                                value={this.props.repositoryInfo.name === 'patents' ? this.state.selectedDocumentPartPatents : this.state.selectedDocumentPart}
                                options={this.props.repositoryInfo.name === 'patents' ? this.documentContentPartsPatents : this.documentContentParts}
                                disabled={this.state.selectedFormat !== 'zip' ? true : false}
                                onChange={(e) => { this.handleDocumentPartChange(e) }}
                                style={{ marginTop: 10 }} />
                        </div>
                        {/*<div className="col-12 sm:col-6 md:col-6 lg:col-2 xl:col-2" style={{ padding: '.5em', paddingBottom: 0 }}>*/}
                        <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" style={{ padding: '.5em', paddingBottom: 10 }}>
                            <label title='Get full documents if parts are missing' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}>Get full documents if parts are missing</label>
                            <Dropdown
                                className='switch'
                                appendTo={document.body}
                                value={this.state.getFullDocuments}
                                options={this.getFullDocumentsOptions}
                                disabled={(this.props.repositoryInfo.name === 'patents' && this.state.selectedDocumentPartPatents === "full")
                                    || (this.props.repositoryInfo.name !== 'patents' && this.state.selectedDocumentPart === "full")
                                    || (this.state.selectedFormat !== 'zip') ? true : false}
                                onChange={(e) => { this.setState({ getFullDocuments: e.value }) }}
                                style={{ marginTop: 10 }} />
                        </div>
                        {/*<div className="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3" style={{ padding: '.5em', paddingBottom: 0 }}>*/}

                    </div>
                    {/*</div></Fieldset>*/}
                </div>
                <div style={{ width: '100%', paddingTop: 5, textAlign: 'right' }}>
                    <div style={{ display: 'inline' }}>
                        {/*<label style={{ color: 'black', marginRight: 10 }}>Export format:</label>*/}
                        <label style={{ color: 'black', marginRight: 10, marginLeft: 30 }}>Export format:</label>
                        <Dropdown
                            id='format'
                            value={this.state.selectedFormat}
                            options={this.formats}
                            onChange={e => this.setState({ selectedFormat: e.value })}
                            //showClear={true}
                            placeholder="Select file format"
                            appendTo={document.body}
                            style={{ marginBottom: -11 }}
                        />
                    </div>
                    {/*
                this.formats.map((format) => {
                    return (
                        <div style={{ display: 'inline' }} key={format.key} className="p-field-radiobutton">
                            <RadioButton inputId={format.key} name="format" value={format} onChange={(e) => this.setState({ selectedFormat: e.value })} checked={this.state.selectedFormat.key === format.key} />
                            <label style={{ color: 'black', marginRight: 10 }} htmlFor={format.key}>{format.name}</label>
                        </div>
                    )
                })
            */}
                    <Button className='p-button-sm primaryButton' style={{ marginLeft: 10, marginTop: -5, marginBottom: -11 }} label="Export"
                        title={`Bulk export (limited to ${addThousandsSeparatorToNumber(50000)} documents)`} onClick={this.handleExport}
                    //disabled={this.props.numberDocuments > 50000 ? true : false} 
                    />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.data
})
const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkExport))
