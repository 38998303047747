import { Component } from "react";
import LoadingAnimationDots from "../../loader/LoadingAnimationDots";
import { findMatchIgnoreCase, isArrayEmpty } from "../../util";
import { CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX, CHEMISTRY_QUERY_TERM_TYPE_CONCEPT } from "../utils/chemistrySearchConstants";
import './ChemistryAutocomplete.css';

class ChemistryAutocompleteSuggestionListItem extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {

        const showOtherTerms = false;
        const item = this.props.item ? this.props.item : {};
        //console.log('item: ', item);

        if (item.loader) {
            return <span style={{ color: '#757575' }}>
                {item.term}
                <span style={{ float: 'right' }}><LoadingAnimationDots /></span>
            </span>
        }

        let term = item.term;
        //let info = ``;

        let isKnown = false;
        if (item.type === CHEMISTRY_QUERY_TERM_TYPE_CONCEPT) {
            isKnown = true;
        }
        const isChemClassCompound = item.type === CHEMISTRY_QUERY_TERM_TYPE_CONCEPT && item.subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX;
        if (isChemClassCompound) {
            //info = 'Class / Compound';
            isKnown = true;
        }
        else if (item.subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND) {
            //info = 'Compound';
            isKnown = true;
        }
        else if (item.subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS) {
            //info = 'Class';
            isKnown = true;
        }

        /*let prefName = '';
        const prefnamesUnique = item.prefnames ? deduplicateTerms(item.prefnames) : [];
        if (prefnamesUnique && prefnamesUnique.length === 1 && normalizeConceptTerm(prefnamesUnique[0], true) !== normalizeConceptTerm(term, true)) {
            //prefName = ` (${prefnamesUnique[0]})`;
            prefName = ` ${prefnamesUnique[0]}`;
        }*/

        let termFormatted = term;
        const match = findMatchIgnoreCase(term, this.props.queryPart);
        if (match) {
            termFormatted = termFormatted.replace(match, `<span class="matching-fragment">${match}</span>`);
        }
        //termFormatted += `<span style="font-weight: normal">${prefName}</span>`;

        const cutOffOtherTerms = item.otherTerms ? [...item.otherTerms].splice(0, 2) : [];

        return <div className='grid suggestion-entry width100perc' style={{ width: '100%' }}>

            <div className={`col-12 clampingContainer line-clamp line-clamp-1-line term-column ${!isKnown ? 'unknown-term' : ''}`}
                title={`Matching term: ${term}`}>
                <span className='suggestion'>
                    <span dangerouslySetInnerHTML={{ __html: termFormatted }}></span>
                    {showOtherTerms && !isArrayEmpty(item.otherTerms) ?
                        <span className="secondaryInfo clampingContainer line-clamp line-clamp-1-line"
                            style={{ fontWeight: 'normal', display: 'block' }}
                            title={item.otherTerms.join(' · ')}
                        >{cutOffOtherTerms.join(' · ')}{item.otherTerms.length > 2 ? ' ...' : ''}
                        </span> : null
                    }
                </span>
            </div>
            {/* 
            <div className="col clampingContainer line-clamp line-clamp-1-line"
                style={{ width: 140, padding: '0 5px 0 20px', color: isKnown ? '' : '#777' }}
                title={prefName ? `Concept main term (differs from matching term): ${prefName}` : ''}>
                {prefName}
            </div>
            */}
            {/*
            <div className={`col-6 clampingContainer line-clamp line-clamp-1-line subtype-column ${!isKnown ? 'unknown-term' : ''}`}
                title={`Type: ${info}`}>
                {info ?
                    <div>
                        {item.subType ? <div className={`info-bar info-bar-${item.subType}`}></div> : null}
                        {info}
                    </div> : null
                }
            </div>
            */}
            <div className="breakRow" />
        </div>
    }
}

export default ChemistryAutocompleteSuggestionListItem;