import * as am4core from "@amcharts/amcharts4/core";

export const changeTitle = (chart, chartTitle) => {
    const isLabel = chart.current.chartContainer.children.values.map(item => item instanceof am4core.Label)
        .some(item => item);

    if (!isLabel) {
        let label = chart.current.chartContainer.createChild(am4core.Label);
        label.text = '';
        label.align = "center";
        label.fill = am4core.color('#757575');
        label.dy = 0;
    } else {
        const length = chart.current.chartContainer.children.values.length
        const label = chart.current.chartContainer.children.values[length - 1];
        label.text = chartTitle;
    }

    chart.current.chartContainer.invalidateLabels();
}
