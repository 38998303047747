import "./QueryEditor.css";
import React, { Component, createRef } from 'react';
import { Button } from 'primereact/button';
import ReactQuill from 'react-quill';
import { checkQuerySyntax, translateToHumanReadableQuery, simplifyQuery } from '../../../../api/content/QueryApi';
import { CustomToolbar } from './CustomToolbar';
import FilterQueryTabs from './toolbarFilterQueries/FilterQueryTabs';
import { isArrayEmpty, stopEventPropagation } from '../../util';
import QueryEditorToolbar from './toolbarEditor/QueryEditorToolbar';
import SeparatorPoint from '../../general/SeparatorPoint';
import SearchTermsHelp from "./help/SearchTermsHelp";
import LogicHelp from "./help/LogicHelp";
import { getMatchingParenthesesPairs } from "../advancedSearchUtil";
import { MdError, MdCheckCircle, MdInfo } from "react-icons/md";
import SearchCriteriaHelp from "./help/SearchCriteriaHelp";
import PropertiesHelp from "./help/PropertiesHelp";
import HighlightingHelp from "./help/HighlightingHelp";
import { Toast } from "primereact/toast";
//import { FaHighlighter } from "react-icons/fa";
//import { TbHighlight, TbHighlightOff } from "react-icons/tb";


const CONCEPT_TO_OCID = true;

const HIGHLIGHTING_BG_COLORS = ['#ffec99', '#A5D8FF', '#FFB9B9', '#C0EB75', '#EBBBF7', '#72E8C4', '#D7BDA4'];
//const HIGHLIGHTING_BG_COLORS = ['#ffff02', '#ffdddd', '#dbfc9f', '#ddddff', '#c4fcfc', '#ffddff'];

const HIGHLIGHT_UNKNOWN = 'unknown';
const HIGHLIGHT_NONE = 'none';
const HIGHLIGHT_UNMATCHED_PARENTHESES = 'unmParen';
const HIGHLIGHT_ALL_MAIN_GROUP = 'allMain';
const HIGHLIGHT_ALL_SUBGROUPS = 'allSub';
const HIGHLIGHT_GROUP_MAIN = 'groupMain';
const HIGHLIGHT_GROUP_SUBGROUPS = 'groupSub';


/* complex functions */
function insertConcepts(quill, concepts, range) {
    //console.log("insert ontology term");

    let text = "";
    const cons = Object.values(concepts);

    // --- add all concepts, OR connected --- //
    for (const concept of cons) {
        if (CONCEPT_TO_OCID) {
            text += ` ocid:"${concept.ocid}"`;
        }
        else {
            text += ` ${concept.domain}:"${concept.preferredName}"`;
        }
    }
    text = text.trim();

    // --- add (...) if there is more than one concept --- //
    if (cons.length > 1) {
        text = `(${text})`;
    }
    text = `+${text}`;

    const { textWithSpaces } = addSpacesToText(quill, range, text);

    // --- add text to current cursor position --- //
    addTextToInputField(quill, textWithSpaces, range);
}

function encloseInParentheses(quill) {

    var text = '()';

    // --- get current cursor range --- //
    const range = getSelectionRange(quill);

    if (range) {
        if (range.length === 0) {
            quill.insertText(range.index, text);
        }
        else {
            text = '(' + quill.getText(range.index, range.length) + ')';
            quill.deleteText(range.index, range.length);
            quill.insertText(range.index, text);
        }
    }
    else {
        const origText = quill.getText() ? quill.getText() : "";
        quill.insertText(origText.length, text);
    }

    quill.setSelection(range.index + text.length - 1);
}

/**
 * 
 * @param {Object} quill 
 * @param {string} value 
 */
function insertAsPrefix(quill, prefix) {
    // --- get current cursor range --- //
    const range = getSelectionRange(quill);

    // --- add text to current cursor position --- //
    const text = `+${prefix}:""`;

    const { after, textWithSpaces } = addSpacesToText(quill, range, text);
    const cursorPosition = range.index + textWithSpaces.length + (after ? -2 : -1);

    addTextToInputField(quill, textWithSpaces, range);
    quill.setSelection(cursorPosition);
}

/**
 * 
 * @param {Object} quill 
 * @param {string} value 
 * @param {string} prefix 
 */
function insertAsValue(quill, prefix, value, addQuotes = true) {
    // --- get current cursor range --- //
    const range = getSelectionRange(quill);

    // --- add text to current cursor position --- //
    const val = addQuotes ? `"${value}"` : value;
    const text = `${prefix}:${val}`; // '+' + prefix + ':"' + value + '"';

    const { after, textWithSpaces } = addSpacesToText(quill, range, text);
    const cursorPosition = range.index + textWithSpaces.length + (after ? 1 : 0);

    addTextToInputField(quill, textWithSpaces, range);
    quill.setSelection(cursorPosition);
}

/**
 * 
 * @param {Object} quill 
 * @param {string} text 
 * @param {Number} cursorOffset 
 */
function insertScaffold(quill, text, cursorOffset) {
    // --- get current cursor range --- //
    const range = getSelectionRange(quill);

    const { textWithSpaces } = addSpacesToText(quill, range, text || "");
    const cursorPosition = range.index + textWithSpaces.length - cursorOffset;

    // --- add text to current cursor position --- //
    addTextToInputField(quill, textWithSpaces, range);
    quill.setSelection(cursorPosition);
}

/**
 * 
 * @param {Object} quill 
 * @param {string} text 
 */
function insertComplete(quill, text) {
    // --- get current cursor range --- //
    const range = getSelectionRange(quill);

    const { after, textWithSpaces } = addSpacesToText(quill, range, text || "");
    const cursorPosition = range.index + textWithSpaces.length + (after ? 2 : 1);

    // --- add text to current cursor position --- //
    addTextToInputField(quill, textWithSpaces, range);
    quill.setSelection(cursorPosition);
}

function addTextToInputField(quill, text, range) {
    if (range) {
        /*
        const { before, after } = addSpacesToText(quill, range);
        console.log('add spaces before: ', before);
        console.log('add spaces after: ', after);
        const textWithSpaces = `${before ? ' ' : ''}${text}${after ? ' ' : ''}`;
        */
        // --- no text is marked --- //
        if (range.length === 0) {
            //console.log('User cursor is at index', range.index);
            quill.insertText(range.index, text);
            quill.setSelection(range.index + text.length);
        }
        // --- characters are marked --- //
        else {
            //var text1 = quill.getText(range.index, range.length);
            //console.log('User has highlighted: ', text1);
            quill.deleteText(range.index, range.length);
            quill.insertText(range.index, text);
            quill.setSelection(range.index + text.length);
        }
    }
    else {
        //console.log('User cursor is not in editor');
        const origText = quill.getText() ? quill.getText() : "";
        quill.insertText(origText.length, text);
        quill.setSelection(origText.length);
    }
}

function addSpacesToText(quill, range, text) {

    let addSpaces = { before: false, after: false };

    if (range) {
        //console.log('range: ', range);
        const prevChar = quill.getText(range.index - 1, 1);
        const nextChar = quill.getText(range.index + range.length, 1);
        //console.log(`prev char: *${prevChar}*`);
        //console.log(`next char: *${nextChar}*`);

        if (prevChar && !text.startsWith('^') && !text.startsWith('~')) {
            var re = /\s|\(|\+|-/g;
            var found = prevChar.match(re);
            //console.log('found: ', found);
            addSpaces.before = !found;
        }
        if (nextChar && text !== '+' && text !== '-') {
            var re2 = /\s|\)/g;
            var found2 = nextChar.match(re2);
            var reLinebreak = /\n|\r/g;
            var foundLinebreak = nextChar.match(reLinebreak);
            //console.log('found: ', found, foundLinebreak);
            addSpaces.after = !found2 || !!foundLinebreak;
        }
    }
    //console.log('add space before', addSpaces.before);
    //console.log('add space after', addSpaces.after);

    addSpaces.textWithSpaces = `${addSpaces.before ? ' ' : ''}${text}${addSpaces.after ? ' ' : ''}`;

    return addSpaces;
}

/**
 * 
 * @param {Object} quill 
 */
function getSelectionRange(quill) {
    quill.focus();
    // TODO: nicht selbst erzeugen?
    const range = quill?.getSelection() ? quill.getSelection() : { index: 0, length: 0 };
    //console.log(range);
    return range;
}


class QueryEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editorHtml: '', //props.initialInput || '',
            toolbarHeight: 0,
            toolbarItems: [],
            queryInfo: {
                text: '',
                type: 'none',
                allowReplace: false,
                replace: ''
            }
        };

        this.myRefTest = React.createRef();

        this.modules = {
            toolbar: {
                container: "#queryEditorToolbar"
            },
            clipboard: {
                matchVisual: false
            }
        }
        //this.toolbarItems = [];

        this.growl = createRef();
        this.quillRef = null;
        this.reactQuillRef = null;
        this.reactQuillToolbarRef = null;
        this.attachQuillRefs = this.attachQuillRefs.bind(this);
    }


    componentDidMount() {
        this.attachQuillRefs();

        //console.log('componentDidMount', this.props);

        // add all available domains to toolbar
        const domains = this.props.domains ? this.props.domains
            .map(dom => { return { label: dom.label, command: () => insertAsPrefix(this.quillRef, dom.value) } }) : [];

        this.setState({ toolbarItems: this.createToolbarItems(domains) });

        const height = this.reactQuillToolbarRef.clientHeight;
        //console.log('height???', height);
        this.setState({ toolbarHeight: height });

        if (this.props.advancedQueries) {
            //this.setState({ editorHtml: this.props.advancedQueries && this.props.advancedQueries[this.props.activeQueryTabIndex?.active]?.query || "" })
            const advQuery = this.props.advancedQueries && this.props.advancedQueries[this.props.activeQueryTabIndex?.active] ?
                this.props.advancedQueries[this.props.activeQueryTabIndex?.active] : null;
            this.setState({
                editorHtml: advQuery?.htmlEditorContent || advQuery?.query || "",
                highlight: HIGHLIGHT_UNKNOWN
            });
        }

        // --- reset query editor? --- //
        if (this.props.reset) {
            this.onClearAll();
        }
    }

    componentDidUpdate(prevProps) {
        //console.log('componentDidUpdate', this.props);

        // add all available domains to toolbar
        if (this.props.domains !== prevProps.domains) {
            const domains = this.props.domains ? this.props.domains
                .map(dom => { return { label: dom.label, command: () => insertAsPrefix(this.quillRef, dom.value) } }) : [];

            this.setState({ toolbarItems: this.createToolbarItems(domains) });
        }

        if (/*this.props.advancedQueries !== prevProps.advancedQueries ||*/
            this.props.activeQueryTabIndex !== prevProps.activeQueryTabIndex) {
            //this.setState({ editorHtml: this.props.advancedQueries[this.props.activeQueryTabIndex?.active]?.query || "" })
            const advQuery = this.props.advancedQueries && this.props.advancedQueries[this.props.activeQueryTabIndex?.active] ?
                this.props.advancedQueries[this.props.activeQueryTabIndex?.active] : null;
            this.setState({
                editorHtml: advQuery?.htmlEditorContent || advQuery?.query || "",
                highlight: HIGHLIGHT_UNKNOWN
            });
        }
        // --- reset query editor? --- //
        if (this.props.reset) {
            this.onClearAll();
        }
    }


    createToolbarItems = (domains) => {
        const toolbarItems = [{
            label: "Search terms",
            help: <SearchTermsHelp />,
            options: [{
                label: "Concepts",
                items: [
                    {
                        label: 'Search in ontologies',
                        items: [{ label: 'Open Domain Explorer', command: () => this.openOntologyBrowser() }]
                    }, {
                        label: 'Concept ID',
                        items: [{ label: 'OCID', command: () => insertAsPrefix(this.quillRef, 'ocid') }]
                    }, {
                        label: 'Domain and term',
                        items: domains
                    }
                ]
            }, {
                label: "Chemistry",
                items: [
                    { label: 'InChI', command: () => insertAsPrefix(this.quillRef, 'inchi') },
                    { label: 'InChI key', command: () => insertAsPrefix(this.quillRef, 'inchikey') }]
            }, {
                label: "Text",
                items: [
                    { label: 'Exact', command: () => insertAsPrefix(this.quillRef, 'tr') },
                    { label: 'With variants', command: () => insertAsPrefix(this.quillRef, 't') }]
            }]
        }, {
            label: "Search criteria",
            help: <SearchCriteriaHelp />,
            options: [{
                label: "Search mode",
                items: [{
                    label: 'Globally, valid for all concepts in query (smode)',
                    items: [
                        { label: 'Ontologically, with synonyms (default)', command: () => insertComplete(this.quillRef, 'smode:"o"') },
                        { label: 'Concept only, with synonyms', command: () => insertComplete(this.quillRef, 'smode:"s"') }]
                }, {
                    label: 'Locally, valid for all following concepts within the same group (cmode)',
                    items: [
                        { label: 'Ontologically, with synonyms', command: () => insertComplete(this.quillRef, 'cmode:"o"') },
                        { label: 'Concept only, with synonyms', command: () => insertComplete(this.quillRef, 'cmode:"s"') }]
                }]
            }, {
                label: "Search in",
                items: [{
                    label: 'General',
                    items: [
                        { label: 'Whole document', command: () => insertAsValue(this.quillRef, 'termloc', 'doc') },
                        { label: 'Title', command: () => insertAsValue(this.quillRef, 'termloc', 'title') },
                        { label: 'Abstract', command: () => insertAsValue(this.quillRef, 'termloc', 'abstract') },
                        { label: 'Title and abstract', command: () => insertAsValue(this.quillRef, 'termloc', 'ta') }]
                }, {
                    label: 'Patents',
                    items: [
                        { label: 'Title, abstract and claim', command: () => insertAsValue(this.quillRef, 'termloc', 'tac') },
                        { label: 'Claim', command: () => insertAsValue(this.quillRef, 'termloc', 'claims') },
                        { label: 'Experimental section', command: () => insertAsValue(this.quillRef, 'termloc', 'experimental') },
                        { label: 'Labeled compounds', command: () => insertAsValue(this.quillRef, 'termloc', 'labeledcomp') }]
                }, {
                    label: 'Publications',
                    items: [
                        { label: 'Background', command: () => insertAsValue(this.quillRef, 'termloc', 'background') },
                        { label: 'Introduction', command: () => insertAsValue(this.quillRef, 'termloc', 'introduction') },
                        { label: 'Methods', command: () => insertAsValue(this.quillRef, 'termloc', 'methods') },
                        { label: 'Experimental section', command: () => insertAsValue(this.quillRef, 'termloc', 'experimental') },
                        { label: 'Results', command: () => insertAsValue(this.quillRef, 'termloc', 'results') },
                        { label: 'Discussion', command: () => insertAsValue(this.quillRef, 'termloc', 'discussion') },
                        { label: 'Conclusion', command: () => insertAsValue(this.quillRef, 'termloc', 'conclusion') },
                        { label: 'Perspectives', command: () => insertAsValue(this.quillRef, 'termloc', 'perspectives') },
                        { label: 'Supplement', command: () => insertAsValue(this.quillRef, 'termloc', 'supplement') },
                        { label: 'References', command: () => insertAsValue(this.quillRef, 'termloc', 'references') }]
                }, {
                    label: 'Clinical trials',
                    items: [
                        { label: 'Basic data', command: () => insertAsValue(this.quillRef, 'termloc', 'basicdata') },
                        { label: 'Design', command: () => insertAsValue(this.quillRef, 'termloc', 'design') },
                        { label: 'Description', command: () => insertAsValue(this.quillRef, 'termloc', 'description') },
                        { label: 'Eligibility', command: () => insertAsValue(this.quillRef, 'termloc', 'eligibility') },
                        { label: 'Endpoints', command: () => insertAsValue(this.quillRef, 'termloc', 'endpoints') },
                        { label: 'References', command: () => insertAsValue(this.quillRef, 'termloc', 'references') }]
                }]
            }, {
                label: "Distance",
                items: [{
                    label: "Concepts",
                    items: [
                        /*{ label: 'Influence on ranking', command: () => insertAsValue(this.quillRef, 'near', '"sort"', false) },
                        { label: 'Max. ~7000 characters', command: () => insertAsValue(this.quillRef, 'near', '"filter"^1000', false) },
                        { label: 'Max. ~3500 characters', command: () => insertAsValue(this.quillRef, 'near', '"filter"^500', false) },
                        { label: 'Max. ~700 characters', command: () => insertAsValue(this.quillRef, 'near', '"filter"^100', false) },
                        { label: 'Max. ~70 characters', command: () => insertAsValue(this.quillRef, 'near', '"filter"^10', false) },
                        { label: 'Max. ~2 characters', command: () => insertAsValue(this.quillRef, 'near', '"filter"^0', false) }*/
                        { label: 'Max. ~ 1 word', command: () => insertAsValue(this.quillRef, 'nearby', '1') },
                        { label: 'Max. ~ 20 words', command: () => insertAsValue(this.quillRef, 'nearby', '20') },
                        { label: 'Max. ~ 50 words', command: () => insertAsValue(this.quillRef, 'nearby', '50') },
                    ]
                }, {
                    label: 'Text exact',
                    items: [
                        { label: 'No distance', command: () => insertScaffold(this.quillRef, 'tr:""~0', 4) },
                        { label: 'Lower distance, variable order', command: () => insertScaffold(this.quillRef, 'tr:""~3', 4) },
                        { label: 'Higher distance, variable order', command: () => insertScaffold(this.quillRef, 'tr:""~10', 5) }]
                }, {
                    label: 'Text with variants',
                    items: [
                        { label: 'No distance', command: () => insertScaffold(this.quillRef, 't:""~0', 4) },
                        { label: 'Lower distance, variable order', command: () => insertScaffold(this.quillRef, 't:""~3', 4) },
                        { label: 'Higher distance, variable order', command: () => insertScaffold(this.quillRef, 't:""~10', 5) }]
                }]
            }]
        }, {
            label: "Properties",
            help: <PropertiesHelp />,
            options: [{
                label: "Metadata",
                items: [
                    { label: 'Publication date', command: () => insertAsPrefix(this.quillRef, 'pdate') },
                    { label: 'Author', command: () => insertAsPrefix(this.quillRef, 'auth') },
                    { label: 'Author affiliation', command: () => insertAsPrefix(this.quillRef, 'xt-dn_authorsaff') }]
            }, {
                label: "Document IDs",
                items: [{
                    label: 'External',
                    items: [
                        { label: 'All external IDs', command: () => insertAsPrefix(this.quillRef, 'mdid') },
                        { label: 'DOI', command: () => insertAsPrefix(this.quillRef, 'doi') },
                        { label: 'ISSN', command: () => insertAsPrefix(this.quillRef, 'issn') },
                        { label: 'PMID', command: () => insertAsPrefix(this.quillRef, 'pmid') },
                        { label: 'PMCID', command: () => insertAsPrefix(this.quillRef, 'pmc') },
                        { label: 'Patent publication number', command: () => insertAsPrefix(this.quillRef, 'pdid') },
                        { label: 'Clinical trial ID', command: () => insertAsPrefix(this.quillRef, 'xi-dn_trialid') }]
                }, {
                    label: 'Internal',
                    items: [
                        { label: 'Internal document ID', command: () => insertAsPrefix(this.quillRef, 'docid') }]
                }]
            }]
        }, {
            label: "Source-specific properties",
            help: <PropertiesHelp />,
            options: [{
                label: "Patents",
                items: [
                    { label: 'Patent publication number', command: () => insertAsPrefix(this.quillRef, 'pdid') },
                    { label: 'Patent office', command: () => insertAsPrefix(this.quillRef, 'pcntry') },
                    { label: 'Assignee', command: () => insertAsPrefix(this.quillRef, 'pass') },
                    { label: 'Inventor', command: () => insertAsPrefix(this.quillRef, 'pinv') },
                    { label: 'Legal status', command: () => insertAsPrefix(this.quillRef, 'xt-dn_patlegalstatus') },
                    { label: 'CPC class', command: () => insertAsPrefix(this.quillRef, 'pclssc') },
                    { label: 'IPC class', command: () => insertAsPrefix(this.quillRef, 'pclssi') },
                    { label: 'Priority claim date', command: () => insertAsPrefix(this.quillRef, 'ppriodate') },
                    { label: 'Filing date', command: () => insertAsPrefix(this.quillRef, 'pfildate') },
                    { label: 'Application date', command: () => insertAsPrefix(this.quillRef, 'pappdate') },
                ]
            }, {
                label: "Publications",
                items: [{
                    label: "DS Publications",
                    items: [
                        { label: 'PMID', command: () => insertAsPrefix(this.quillRef, 'pmid') },
                        { label: 'PMCID', command: () => insertAsPrefix(this.quillRef, 'pmc') },
                        { label: 'DOI', command: () => insertAsPrefix(this.quillRef, 'doi') },
                        { label: 'Open access status', command: () => insertAsPrefix(this.quillRef, 'xt-dn_openaccesscategories') },
                        { label: 'Publication type', command: () => insertAsPrefix(this.quillRef, 'pubtype') },
                        { label: 'Publisher', command: () => insertAsPrefix(this.quillRef, 'publisher') },
                        { label: 'Journal/Source', command: () => insertAsPrefix(this.quillRef, 'xt-dn_sourcetitle') }
                    ]
                }, {
                    label: "Other Publications",
                    items: [
                        { label: 'Publisher', command: () => insertAsPrefix(this.quillRef, 'publisher') },
                        { label: 'Journal', command: () => insertAsPrefix(this.quillRef, 'journal') },
                        { label: 'Volume', command: () => insertAsPrefix(this.quillRef, 'vol') },
                        { label: 'Issue', command: () => insertAsPrefix(this.quillRef, 'issue') },
                    ]
                }]
            }, {
                label: "Clinical trials",
                items: [
                    { label: 'Clinical trial ID', command: () => insertAsPrefix(this.quillRef, 'xi-dn_trialid') },
                    { label: 'Sponsor', command: () => insertAsPrefix(this.quillRef, 'xt-dn_sponsors') },
                    { label: 'Investigator', command: () => insertAsPrefix(this.quillRef, 'xt-dn_investigators') },
                    { label: 'Investigator affiliation', command: () => insertAsPrefix(this.quillRef, 'xt-dn_investigatorsaff') },
                    { label: 'Status', command: () => insertAsPrefix(this.quillRef, 'xt-dn_status') },
                    { label: 'Study type', command: () => insertAsPrefix(this.quillRef, 'xt-dn_studytype') },
                    { label: 'Clinical phase', command: () => insertAsPrefix(this.quillRef, 'xt-dn_clinicalphase') },
                    { label: 'Start date', command: () => insertAsPrefix(this.quillRef, 'xd-dn_startdate') },
                    { label: 'End date', command: () => insertAsPrefix(this.quillRef, 'xd-dn_enddate') },
                ]
            }, {
                label: "Grants",
                items: [
                    { label: 'Investigator', command: () => insertAsPrefix(this.quillRef, 'xt-dn_grantees') },
                    { label: 'Funder', command: () => insertAsPrefix(this.quillRef, 'xt-dn_funder') },
                    { label: 'Funder country', command: () => insertAsPrefix(this.quillRef, 'xt-dn_fundercountry') },
                    { label: 'Research organization', command: () => insertAsPrefix(this.quillRef, 'xt-dn_researchorgs') },
                    { label: 'Country', command: () => insertAsPrefix(this.quillRef, 'xt-dn_researchorgcountries') },
                    { label: 'State', command: () => insertAsPrefix(this.quillRef, 'xt-dn_researchorgstate') },
                    { label: 'Start date', command: () => insertAsPrefix(this.quillRef, 'xd-dn_startdate') },
                    { label: 'End date', command: () => insertAsPrefix(this.quillRef, 'xd-dn_enddate') }
                ]
            }]
        }, {
            label: "Logic",
            help: <LogicHelp />,
            options: [{
                label: "Operators",
                items: [{
                    label: "Occurrence",
                    items: [
                        { label: 'MUST occur (+TERM)', command: () => insertComplete(this.quillRef, '+') },
                        { label: 'MUST NOT occur (-TERM)', command: () => insertComplete(this.quillRef, '-') }],
                }, {
                    label: "Boost value",
                    items: [
                        { label: 'Increase importance (CONCEPT^2)', command: () => insertComplete(this.quillRef, '^2') },
                        { label: 'Decrease importance (CONCEPT^0.5)', command: () => insertComplete(this.quillRef, '^0.5') }]
                }, {
                    label: "Text distance",
                    items: [
                        { label: 'Max. 2 words (TEXT~2)', command: () => insertComplete(this.quillRef, '~2') },
                        { label: 'Max. 5 words (TEXT~5)', command: () => insertComplete(this.quillRef, '~5') }]
                }]
            },
            {
                label: 'Add parentheses',
                tooltip: 'Enclose marked text in parentheses',
                command: () => encloseInParentheses(this.quillRef)
            }]
        }, {
            label: "Highlighting", //Editor
            help: <HighlightingHelp />,
            options: [
                {
                    label: 'Groups',
                    tooltip: 'Highlight groups',
                    items: [{
                        label: 'Group at cursor position',
                        tooltip: 'Highlight group enclosed in parentheses. Place your cursor somewhere within the group to highlight the surrounding parentheses and the text in between.',
                        items: [{
                            label: 'Outer group only',
                            //tooltip: 'Highlight group enclosed in parentheses. Place your cursor somewhere within the group to highlight the surrounding parentheses and the text in between.',
                            command: () => this.highlightGroup(false)
                        }, {
                            label: 'Including all sub groups',
                            //tooltip: 'Highlight group enclosed in parentheses. Place your cursor somewhere within the group to highlight the surrounding parentheses and the text in between.',
                            command: () => this.highlightGroup(true)
                        }]
                    }, {
                        label: 'All groups',
                        items: [{
                            label: 'Outer groups only',
                            //tooltip: 'Click to highlight all groups enclosed in parentheses and the text in between.',
                            command: () => this.highlightAllGroups(false)
                        }, {
                            label: 'Including all sub groups',
                            //tooltip: 'Click to highlight all groups enclosed in parentheses and the text in between.',
                            command: () => this.highlightAllGroups(true)
                        }]
                    }, {
                        label: 'Unmatched parentheses',
                        items: [{
                            label: 'Unmatched parentheses only',
                            tooltip: 'Highlight unmatched parentheses',
                            command: () => this.highlightUnmatchedParentheses()
                        }, {
                            label: 'All groups, including all sub groups and unmatched parentheses',
                            command: () => this.highlightAllGroups(true, true)
                        }]
                    }]
                }, {
                    label: 'Remove',
                    tooltip: 'Remove all highlighting',
                    command: () => this.removeHighlighting()
                }
            ]
        }];
        return toolbarItems;
    }

    attachQuillRefs() {
        // Ensure React-Quill reference is available:
        if (!this.reactQuillRef || typeof this.reactQuillRef.getEditor !== 'function') return;
        // Skip if Quill reference is defined:
        if (this.quillRef != null) return;

        const quillRef = this.reactQuillRef.getEditor();
        if (quillRef != null) this.quillRef = quillRef;
    }


    handleChange = (html) => {

        /*if (this.state.editorHtml !== html) {
            console.log('handleChange');
            this.setState({ highlight: HIGHLIGHT_UNKNOWN });
        }*/
        //console.log('handleChange', (this.state.editorHtml !== html ? HIGHLIGHT_UNKNOWN : this.state.highlight));

        this.setState({
            editorHtml: html,
            highlight: this.state.editorHtml !== html ? HIGHLIGHT_UNKNOWN : this.state.highlight
        });

        const queryRaw = this.getQueryRaw(this.quillRef);

        this.props.onQueryChange(queryRaw, html);
    }

    handleTabChange = (index) => {
        this.props.onActiveQueryTabIndexChange(index);
    }

    handleFilterModeChange = (index, filterMode, e) => {
        //console.log('handleFilterModeChange');

        stopEventPropagation(e);
        e.preventDefault();

        const advancedQueriesNew = [...this.props.advancedQueries];
        advancedQueriesNew[index].filterMode = filterMode;

        this.props.onQueryChange(advancedQueriesNew);
    }


    getQueryRaw = (quill) => {
        //return (queryHtml) ? queryHtml.replace(/<p>/g, "").replace(/<\/p>/g, "").replace(/<br>/g, "") : queryHtml;
        let textRaw = quill ? quill.getText() : '';
        //textRaw = textRaw.replace(/[\\r\\n\\s ]+/g, " ").trim();
        textRaw = textRaw.replace(/[\r\n\s ]+/g, " ").trim();
        return textRaw;
    }


    handleChangeSelection = (e) => {
        //console.log('handleChangeSelection', e);
        //this.setState({ range: e });
    }


    // ----------------------------------------------------------------------- //
    // --- domain explorer --------------------------------------------------- //
    // ----------------------------------------------------------------------- // 
    /**
     * 
     */
    openOntologyBrowser = () => {
        //const range = this.quillRef.getSelection();
        const range = getSelectionRange(this.quillRef);

        this.setState({
            cursorRange: range
        });

        this.props.onOpenOntologyBrowser();
    }

    addConcepts = (concepts) => {
        const range = this.state.cursorRange;
        insertConcepts(this.quillRef, concepts, range);
    }


    removeHighlighting = () => {
        const str = this.quillRef.getText();
        const length = str ? str.length : 0;
        this.quillRef.removeFormat(0, length);

        this.setState({ highlight: HIGHLIGHT_NONE });
    }

    highlightGroup = (highlightSubgroups) => {

        const range = getSelectionRange(this.quillRef);
        const text = this.quillRef.getText();
        const length = text ? text.length : 0;

        if (length > 0) {
            let group;
            let closestGroupStartIndex = -1;
            const { groups, hasError } = getMatchingParenthesesPairs(text);
            groups?.forEach(parenthesisPair => {
                if (range.index >= parenthesisPair.openingParenthesis.index && range.index <= parenthesisPair.closingParenthesis.index) {
                    if (parenthesisPair.openingParenthesis.index > closestGroupStartIndex) {
                        group = parenthesisPair;
                    }
                }
            });

            if (!isArrayEmpty(groups) && group &&
                (this.state.group?.openingParenthesis.index !== group?.openingParenthesis.index ||
                    this.state.group?.closingParenthesis.index !== group?.closingParenthesis.index ||
                    (!highlightSubgroups && this.state.highlight !== HIGHLIGHT_GROUP_MAIN) ||
                    (highlightSubgroups && this.state.highlight !== HIGHLIGHT_GROUP_SUBGROUPS))) {

                this.quillRef.removeFormat(0, length);

                //console.log('group', group);
                const groupsToHighlight = [];
                groups?.forEach(parenthesisPair => {
                    if (parenthesisPair.openingParenthesis.index >= group.openingParenthesis.index && parenthesisPair.closingParenthesis.index <= group.closingParenthesis.index) {
                        groupsToHighlight.push(parenthesisPair);
                    }
                });
                //console.log('groupsToHighlight', groupsToHighlight);

                this.addHighlightingToGroups(groupsToHighlight, highlightSubgroups);

                const highlight = highlightSubgroups ? HIGHLIGHT_GROUP_SUBGROUPS : HIGHLIGHT_GROUP_MAIN;

                this.setState({ highlight: highlight, text: text, group: group });
            }
            else {
                this.removeHighlighting();
            }
        }
    }


    highlightAllGroups = (highlightSubgroups, highlightUnmatchedParentheses) => {

        //console.log('highlightAllGroups highlightSubgroups', highlightSubgroups);

        const text = this.quillRef.getText();
        const length = text ? text.length : 0;
        //var contents = this.quillRef.getContents();
        //console.log('contents', contents);

        if (//this.state.text !== text ||
            length > 0 &&
            ((!highlightSubgroups && this.state.highlight !== HIGHLIGHT_ALL_MAIN_GROUP) ||
                (highlightSubgroups && this.state.highlight !== HIGHLIGHT_ALL_SUBGROUPS))) {
            //console.log('highlightAllGroups');

            this.quillRef.removeFormat(0, length);

            const { groups, hasError, unmatchedParenthesesIndices } = getMatchingParenthesesPairs(text);
            this.addHighlightingToGroups(groups, highlightSubgroups, highlightUnmatchedParentheses ? unmatchedParenthesesIndices : null);

            const highlight = highlightSubgroups ? HIGHLIGHT_ALL_SUBGROUPS : HIGHLIGHT_ALL_MAIN_GROUP;

            this.setState({ highlight: highlight, text: text });
        }
    }

    highlightUnmatchedParentheses = () => {

        if (this.state.highlight === HIGHLIGHT_UNMATCHED_PARENTHESES) {
            this.removeHighlighting();
        }
        else {
            const text = this.quillRef.getText();
            const length = text ? text.length : 0;
            this.quillRef.removeFormat(0, length);

            const { unmatchedParenthesesIndices } = getMatchingParenthesesPairs(text);
            this.addHighlightingToGroups([], false, unmatchedParenthesesIndices);

            this.setState({ highlight: HIGHLIGHT_UNMATCHED_PARENTHESES });
        }
    }


    addHighlightingToGroups = (origGroups = [], highlightSubgroups = true, unmatchedParenthesesIndices = []) => {
        //console.log('unmatchedParenthesesIndices', unmatchedParenthesesIndices);
        //console.log('groups', groups);
        //groups.push({ openingParenthesis: 0, closingParenthesis: 0, level: 0 });
        //console.log('ADD HIGHLIGHTING', this.state.highlight);

        let groups = origGroups;
        if (!highlightSubgroups) {
            groups = [...origGroups];
            groups.reverse();
        }
        //console.log('groups', groups);

        groups.forEach(group => {
            //console.log('group: ', group);
            const level = group.level;
            const leftIndex = group.openingParenthesis.index;
            const rightIndex = group.closingParenthesis.index;

            const bgColor = HIGHLIGHTING_BG_COLORS[level];

            this.quillRef.formatText(leftIndex, rightIndex - leftIndex + 1, {
                'background': bgColor,
            });
        });

        unmatchedParenthesesIndices.forEach(ubIndex => {
            this.quillRef.formatText(ubIndex, 1, {
                'background': 'red',
            });
        })
    }


    /**
     * 
     */
    checkQuerySyntax = async () => {
        let queryRaw = this.getQueryRaw(this.quillRef);

        // +ocid:"2367876595" +ocid:"87215324399" +ocid:"365876846" +ocid:"8711242120"
        // +(ocid:"206010003504" ocid:"238000001660"
        const response = await checkQuerySyntax(queryRaw);

        const queryInfo = {
            text: '',
            type: 'none',
            allowReplace: false,
            replace: ''
        };

        if (response.status === 'SUCCESS') {
            if (response.payload?.hasErrors && response.payload.errors?.errors) {
                let errorMsg = '';
                Object.values(response.payload.errors.errors).forEach(error => {
                    errorMsg += '"' + error.queryFragment + '" (' + error.type + '); ';
                    //errorMsg += '"' + error.f + '" (' + error.et + '); ';
                    //console.log('error: ', error);
                });
                queryInfo.text = 'Syntax errors found in your query: ' + errorMsg;
                queryInfo.type = 'error';
            }
            else {
                queryInfo.text = 'Your query is syntactically correct. A semantic check cannot be done as easily, ' +
                    'but you can run a search to see if the results match your expectations.';
                queryInfo.type = 'success';
            }
        }
        else {
            this.growl.show({ severity: 'error', summary: 'Error occurred', detail: response.message });
        }

        this.setState({ queryInfo: queryInfo });
    }


    /**
     * // +(ocid:"206010028997" ocid:"206010078022") +ocid:"210000003240"
     
     *   diseases:"neoplasm malignant"[1234567890]
     *   [1234567890:diseases:"neoplasm malignant"]
     *   (+(ocid:"206010003504" ocid:"206010038716"))
     */
    translateToHumanReadableQuery = async () => {
        let queryRaw = this.getQueryRaw(this.quillRef);

        const queryInfo = {
            text: '',
            type: 'none',
            allowReplace: false,
            replace: ''
        };

        const responseCheckQuery = await simplifyQuery(queryRaw, true);
        if (responseCheckQuery.status === 'SUCCESS' &&
            responseCheckQuery.payload?.hasErrors && responseCheckQuery.payload.errors?.errors) {
            let errorMsg = '';
            Object.values(responseCheckQuery.payload.errors.errors).forEach(error => {
                errorMsg += '"' + error.queryFragment + '" (' + error.type + '); ';
            });
            queryInfo.text = 'Syntax errors found in your query: ' + errorMsg;
            queryInfo.type = 'error';
        }
        else {
            const response = await translateToHumanReadableQuery(queryRaw);

            if (response.status === 'SUCCESS') {
                if (response.payload?.hasErrors && response.payload.errors?.errors) {
                    let errorMsg = '';
                    Object.values(response.payload.errors.errors).forEach(error => {
                        //errorMsg += '"' + error.f + '" (' + error.et + '); ';
                        errorMsg += '"' + error.queryFragment + '" (' + error.type + '); ';
                        //console.log('error: ', error);
                    });
                    queryInfo.text = 'Syntax errors found in your query: ' + errorMsg;
                    queryInfo.type = 'error';
                }
                else {
                    queryInfo.text = response.payload.query;
                    queryInfo.type = 'success';
                }
            }
            else {
                this.growl.show({ severity: 'error', summary: 'Error occurred', detail: response.message });
            }
        }

        this.setState({ queryInfo: queryInfo });
    }


    /**
     * // +(ocid:"206010028997" ocid:"206010078022") +ocid:"210000003240"

     */
    simplifyQuery = async () => {
        let queryRaw = this.getQueryRaw(this.quillRef);

        const response = await simplifyQuery(queryRaw, true);

        const queryInfo = {
            text: '',
            type: 'none',
            allowReplace: false,
            replace: ''
        };

        if (response.status === 'SUCCESS') {
            if (response.payload?.hasErrors && response.payload.errors?.errors) {
                let errorMsg = '';
                //console.log('response.payload.errors: ', response.payload.errors);
                Object.values(response.payload.errors.errors).forEach(error => {
                    errorMsg += '"' + error.queryFragment + '" (' + error.type + '); ';
                    //errorMsg += '"' + error.f + '" (' + error.et + '); ';
                    //console.log('error: ', error);
                });

                queryInfo.text = 'Syntax errors found in your query: ' + errorMsg;
                queryInfo.type = 'error';
            }
            else {
                queryInfo.text = 'Your query is syntactically correct. A semantic check cannot be done as easily, ' +
                    'but you can run a search to see if the results match your expectations.';
                /*if (queryRaw !== response.payload.query) {
                    queryInfo.text += '\n\nThe processed version of the query differs from your input. You can either keep yours or replace it with the proposed version: \n\n' + response.payload.query;
                    queryInfo.type = 'replace';
                    queryInfo.allowReplace = true;
                    queryInfo.replace = response.payload.query;
                    // repoDescription
                }
                else {
                */    queryInfo.type = 'success';
                /*}*/
            }
        }
        else {
            this.growl.show({ severity: 'error', summary: 'Error occurred', detail: response.message });
        }

        this.setState({
            queryInfo: queryInfo
        });
    }


    replaceQuery = () => {
        const replaceWithText = this.state.queryInfo && this.state.queryInfo.allowReplace ? this.state.queryInfo.replace : null;

        if (replaceWithText) {
            this.quillRef.setText(replaceWithText);
        }

        this.setState({
            queryInfo: {
                text: '',
                type: 'none',
                allowReplace: false,
                replace: ''
            }
        });
    }

    onClearAll = () => {
        this.setState({
            editorHtml: '',
            queryInfo: {
                text: '',
                type: 'none',
                allowReplace: false,
                replace: ''
            }
        }, () => {
            this.props.onClearAll();
        })
    }


    render() {

        let borderColor = '#ccc';
        let borderSize = '2';

        switch (this.state.queryInfo.type) {
            case 'success': {
                borderColor = '#4CAF50';
                //borderSize = '3';
                break;
            }
            case 'replace': {
                borderColor = '#005594';
                //borderSize = '3';
                break;
            }
            case 'error': {
                borderColor = '#E91E63';
                //borderSize = '3';
                break;
            }
            default: {
                break;
            }
        }

        return (
            <React.Fragment>
                <Toast ref={this.growl} />

                <div>
                    <div className="grid">
                        <div className="col-12" style={{ padding: '5px 7px 5px 5px', height: 'auto' }}>
                            <div className="text-editor">

                                <div style={{ marginTop: 10, paddingLeft: 7, marginBottom: -1 }}>
                                    <FilterQueryTabs
                                        activeIndex={this.props.activeQueryTabIndex?.active}
                                        tabWidth={300}
                                        queries={this.props.advancedQueries}
                                        maxNumOfFilterQueries={4}
                                        onClick={(index) => {
                                            this.handleTabChange(index);
                                            //this.props.onActiveQueryTabIndexChange(index)
                                            //console.log('active index', index);
                                        }}
                                        onFilterModeChange={this.props.onFilterModeChange}
                                        onAddFilterQuery={this.props.onAddFilterQuery}
                                        onRemoveFilterQuery={this.props.onRemoveFilterQuery}
                                    />
                                </div>

                                <QueryEditorToolbar toolbarItems={this.state.toolbarItems} />

                                <div ref={(el) => { this.reactQuillToolbarRef = el }}
                                    style={{ marginBottom: -1, display: 'none' }}>
                                    <CustomToolbar />
                                </div>

                                <ReactQuill
                                    ref={(el) => { this.reactQuillRef = el }}
                                    id='expquery'
                                    theme="snow"
                                    value={this.state.editorHtml}
                                    style={{ height: this.props.height - this.state.toolbarHeight || 400, fontSize: '14px !important' }} // , letterSpacing: '1.5px'
                                    onChange={(e) => this.handleChange(e)}
                                    onChangeSelection={(e) => this.handleChangeSelection(e)}
                                    modules={this.modules}
                                    readOnly={false} />
                            </div>

                            <div className="col-12"
                                style={{ textAlign: 'left', paddingTop: 15, position: 'relative' }}>
                                <>
                                    <Button
                                        className='p-button-text primaryButtonAsLink'
                                        label="Translate OCIDs"
                                        title="Replace OCIDs with domain and preferred name (this will not affect your original search)"
                                        onClick={this.translateToHumanReadableQuery}
                                        disabled={this.state.editorHtml.length === 0} />

                                    <SeparatorPoint />

                                    <Button
                                        className='p-button-text primaryButtonAsLink'
                                        label="Check syntax"
                                        title="Check and simplify syntax (this will not check semantics)"
                                        onClick={this.simplifyQuery}
                                        disabled={this.state.editorHtml.length === 0} />

                                    {/*<SeparatorPoint />

                                    <Button
                                        className='p-button-text primaryButtonAsLink'
                                        label="Simplify"
                                        title="Simplify syntax by removing unnecessary + and parenthesis (this will not affect your original query, but you can replace it later)"
                                        onClick={this.simplifyQuery}
                                        disabled={this.state.editorHtml.length === 0} />
                                    */}
                                </>
                            </div>
                            {this.state.queryInfo?.text && <div className="col-12" style={{ padding: 0, marginTop: 10 }}>
                                <div style={{
                                    /*maxWidth: 600, margin: 'auto', */
                                    padding: '5px 10px',
                                    lineHeight: 2,
                                    border: `${borderSize}px solid ${borderColor}`
                                }}>
                                    <div className="grid" style={{ alignItems: 'center', margin: 'auto' }}>
                                        <div className="col-fixed" style={{ fontSize: 20, display: 'flex', paddingTop: 5 }}>
                                            {this.state.queryInfo.type === 'error' ?
                                                <MdError color={borderColor} /> :
                                                this.state.queryInfo.type === 'success' ?
                                                    <MdCheckCircle color={borderColor} />
                                                    :
                                                    <MdInfo color={borderColor} />}
                                        </div>
                                        <div className="col">
                                            {this.state.queryInfo.text}
                                            {this.state.queryInfo.allowReplace && this.state.editorHtml.length > 0 && <div>
                                                <Button
                                                    className='p-button-text primaryButtonAsLink'
                                                    label="Replace query"
                                                    title="Replace original search with simplified search"
                                                    onClick={this.replaceQuery} />
                                            </div>}
                                        </div>
                                        <div className="breakRow"></div>

                                        {/*this.state.queryInfo.allowReplace && this.state.editorHtml.length > 0 &&
                                            <div className="col-12 textAlignRight" style={{ marginLeft: 'auto', marginRight: 0 }}>
                                                <Button
                                                    className='p-button-text primaryButtonAsLink'
                                                    label="Replace query"
                                                    title="Replace original search with simplified search"
                                                    onClick={this.replaceQuery}
                                                    disabled={!this.state.queryInfo.allowReplace || this.state.editorHtml.length === 0} />

                                            </div>
                                        */}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default QueryEditor;