import { Button } from 'primereact/button';
import { Component } from 'react';
import SeparatorPoint from '../../webapi/general/SeparatorPoint';
import { hasUserRole } from '../../webapi/util';


class SaveSearchButtons extends Component {

    render() {

        const { savedSearchExists, editableQuery, onSaveSearch, savedSearchName, noVerticalAlignedButtons, disabled } = this.props;

        return <>
            {hasUserRole('ROLE_WATCHLISTS') ?
                savedSearchExists ?
                    <span>
                        <Button
                            className={noVerticalAlignedButtons ? "p-button-text primaryButtonAsLink" : "p-button-text primaryButtonAsLink valignMiddle"}
                            title={editableQuery ? `Save modified search ${savedSearchName ? '"' + savedSearchName + '"' : ''}` : 'Editing not allowed for shared searches'}
                            style={{ padding: 0 }}
                            onClick={() => onSaveSearch(true)}
                            disabled={disabled || !editableQuery} >
                            Save changes {savedSearchName ? `to "${savedSearchName.length > 15 ? savedSearchName.substring(0, 15) + '...' : savedSearchName}"` : ''}
                        </Button>
                        <SeparatorPoint />
                        <Button
                            className={noVerticalAlignedButtons ? "p-button-text primaryButtonAsLink" : "p-button-text primaryButtonAsLink valignMiddle"}
                            style={{ padding: 0 }}
                            title="Save new search and add it to collections"
                            onClick={() => onSaveSearch(false)}
                            disabled={disabled} >
                            Save as new
                        </Button>
                    </span>
                    :
                    <Button
                        className={noVerticalAlignedButtons ? "p-button-text primaryButtonAsLink" : "p-button-text primaryButtonAsLink valignMiddle"}
                        title="Save new search and add it to collections"
                        onClick={() => onSaveSearch(false)}
                        disabled={disabled}                        >
                        Save search
                        {/*hasUserRole('ROLE_ALERTS') ? 'Save & Alert' : 'Save'*/}
                    </Button>
                : null}
        </>
    }
}
export default SaveSearchButtons