/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import {
    useState, useRef //, useEffect 
} from 'react';
import AutoComplete from '../../../common/AutoComplete/AutoComplete';
import './style/main.css'
import _ from 'lodash';
import AutocompleteSuggestionListItem from '../../general/autocomplete/AutocompleteSuggestionListItem';
import { fetchMergedTermSuggestions } from '../../../../api/content/ConceptApi';
import { checkResultAndGetPayload } from '../../../../api';
import { QUERY_TERM_TYPE_CONCEPT } from '../../general/docsearch/searchConstants';
import { DOMAIN_ALIASES, FREE_TEXT_DOMAINS, FREE_TEXT_DOMAIN_ALIASES } from './constants/general';
import { ColorPicker } from './components/ColorPicker';
import { MatchNavigator } from './components/MatchNavigator';
import { totalHiddenMatches, totalMatches } from '../util/general';
import { addThousandsSeparatorToNumber, isArrayEmpty } from '../../util';
import InputFieldWithClear from '../../../webapi/general/inputfields/InputFieldWithClear';

let latestTermFragment;

export const SemanticAutoComplete = ({
    annotations,
    activateOcidHighlighting,
    activeSearchTerms,
    changeOcidAnnotationColor,
    domainLabelsMap = {},
    domainColors = {},
    jumpToMatchGenrally,
    clearSemanticSearch,
    domains,
    activeSearchTermsCount = {},
    onSpecifyQueryTerm,
    abstractOnly = false,
    isSemanticOn,
    hiddenExactTextMatches,
    showFooter,
    noCounting,
    addTerm,
    removeTerm,
    placeholder
}) => {


    const ref = useRef()
    const [selectedTermsIndex, setSelectedTermsIndex] = useState([]);
    const [filteredTerms, setFilteredTerms] = useState(null);
    const [index, setIndex] = useState(-1);
    const [autoCompleteText, setAutoCompleteText] = useState('');
    const [clickedItem, setClickedItem] = useState('');

    const itemTemplate = (item) => {
        return <AutocompleteSuggestionListItem
            item={item}
            queryPart={autoCompleteText}
            domainLabelsMap={domainLabelsMap}
            acRef={ref}
            domainColors={domainColors}
        />;
    }


    const selectedItemTemplate = (item) => {
        const uniqeId = generateUID(item);
        const bgColor = getExactColor(uniqeId, item?.domains, item.ocids)
        const selectedTermIndex = getSelectedTermIndex(uniqeId)
        const matches = addThousandsSeparatorToNumber(abstractOnly ? filterAnnotations(uniqeId)?.length : (activeSearchTermsCount[uniqeId] - (hiddenExactTextMatches[uniqeId] ? hiddenExactTextMatches[uniqeId]?.length : 0)))
        return (
            <div key={uniqeId} style={{ backgroundColor: `#${bgColor}` }}>
                <div className={`selected-item-semantic`} style={{ border: `3px solid #${bgColor}` }} >
                    {!noCounting ?
                        <div className={`item-icons ${!activeSearchTermsCount[uniqeId] ? 'disable-button' : ''}`} style={{ backgroundColor: `#${bgColor}` }} >
                            <MatchNavigator findAnnId={findAnnId} uniqueId={uniqeId} />
                        </div>
                        : null
                    }
                    <div className='item-text'>
                        <div className='item-title'>
                            <span title={item.term}>
                                {item.term}
                            </span>
                            {!noCounting ?
                                <span className={`${(clickedItem !== uniqeId && clickedItem !== '') ? 'graycolor' : ''}`}>
                                    {`${matches ?
                                        ` ( ${selectedTermIndex > -1 ? (selectedTermIndex + 1) + ' / ' : ''}
                                  ${matches || ''} )` : ' (0) '}`}
                                </span>
                                : null
                            }
                        </div>
                        <div>
                            <div className='item-sub-title domain-grid' >
                                <div className='tooltip-description domain-col' title={`${concatDomais(item)}`} >
                                    {
                                        getHighPiriortyDomain(item.domains, item.ocids)?.map((domain, index) => {
                                            return <span className='capitalize mt-2'>
                                                {
                                                    formatDomainNames(domain.name, item.ocids) + ((item.domains.length !== index + 1) ? ',' : '')
                                                }
                                            </span>
                                        })
                                    }
                                </div>
                                <div className='edit-col' >
                                    <ColorPicker
                                        changeColor={changeColor}
                                        bgColor={bgColor}
                                        item={item}
                                        onSpecifyQueryTerm={onSpecifyQueryTerm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='close-icon-space'>
                    </div>
                </div>
            </div>
        );
    }

    const getExactColor = (id, domain, ocids) => {
        let temp = activeSearchTerms?.find((term) => term?.id === id)
        if (temp) {
            return temp?.color
        } else {
            if (ocids) {
                const tempDomain = getHighPiriortyDomain(domain, ocids)
                return tempDomain.length > 0 ? tempDomain[0]?.color : 'dee2e6'
            } else {
                return 'dee2e6'
            }
        }
    }

    const getHighPiriortyDomain = (domain, ocids = null) => {
        if (!ocids && FREE_TEXT_DOMAINS.includes(domain[0])) {
            return domain.map((item) => {
                return {
                    name: DOMAIN_ALIASES[item]
                }
            })
        }
        return domains.filter((dom) => domain.some((item) => item === dom.name));
    }




    const concatDomais = (item) => {
        let domainsText = ''
        item.domains?.forEach((domain, index) => {
            domainsText += formatDomainNames(domain, item.ocids) + ((item.domains.length !== index + 1) ? ',' : '')
        })
        return domainsText
    }


    const formatDomainNames = (domain, ocids) => {
        if (!ocids && FREE_TEXT_DOMAIN_ALIASES.includes(domain)) {
            return domain;
        } else {
            return domainLabelsMap[domain]
        }
    }


    const filterAnnotations = (querId = null) => {
        let filteredAnnotations = annotations?.filter(item => item.annotations[0].queryId !== 'ocFuzzyText:readcube_text')
        return _.orderBy(filteredAnnotations?.filter((item) => item?.annotations?.find((obj) => querId ? obj?.queryId === querId : obj?.queryId)), [function (o) { return parseInt(o?.annId?.split('d')[1]) }])
    }


    const onSelectTerm = (e) => {
        //console.log(e.value)
        const uniqeId = generateUID(e.value)
        if (!activeSearchTerms.find((obj) => obj.id === uniqeId)) {
            setSelectedTermsIndex((selectedTermsIndex) => [...selectedTermsIndex,
            {
                id: uniqeId,
                lastIndex: -1
            }])
            if (!noCounting) {
                activateOcidHighlighting({ ...e.value, id: uniqeId, color: getExactColor(uniqeId, e.value.domains, e.value.ocids) }, false)
            }
            if (noCounting) {
                addTerm(e.value, uniqeId)
            }
        }
    }

    const generateUID = (term) => {
        return `q${term.term.trim().split(' ').join('') + (term.ocids ? term.ocids?.length : term.domains[0]) + term.domains?.length}`
    }

    const onUnselectTerm = (e) => {
        const uniqeId = generateUID(e.value)
        setSelectedTermsIndex(selectedTermsIndex.filter((item) => (item.id !== uniqeId)))
        setIndex(-1)
        if (!noCounting) {
            activateOcidHighlighting({ ...e.value, id: uniqeId }, true)
        } else {
            removeTerm(e.value)
        }
    }

    const changeColor = (value, selectedConcept) => {
        if (noCounting) {
            changeOcidAnnotationColor(generateUID(selectedConcept), value, selectedConcept)
        } else {
            changeOcidAnnotationColor(generateUID(selectedConcept), value)
        }
    }

    /**
   * Fetches suggestions for term fragment. Suggestions will be merged if they have
   * the same OCID. Free text option will be added if there are no direct matches.
   * @param {*} event 
   */
    const autocomplete = (termFragment) => {
        latestTermFragment = termFragment;
        setTimeout(async () => {
            // --- fetch term suggestions --- //
            let terms = [];
            const response = await fetchMergedTermSuggestions(termFragment, null, null, null, true, false, true); // this.state.domains
            terms = checkResultAndGetPayload(response, null);

            if (termFragment === latestTermFragment && terms) {
                terms = terms ? terms : [];

                terms.forEach(term => {
                    term.type = QUERY_TERM_TYPE_CONCEPT;
                    term.queryValues = [term.term];
                });
                setFilteredTerms(terms)
                setAutoCompleteText(termFragment)
            }
        }, 200);
    }


    const findAnnId = (isNext, querId = null) => {

        if (!isSemanticOn)
            return;


        setClickedItem(querId);

        let ann = filterAnnotations(querId)

        if (!querId)
            ann = ann?.filter(item => !Object.values(hiddenExactTextMatches).flat(1)?.includes(item.annId))

        if (hiddenExactTextMatches[querId])
            ann = ann?.filter(item => !hiddenExactTextMatches[querId]?.includes(item.annId))

        const lastIndex = querId ? getSelectedTermIndex(querId) : index
        const annLength = ann?.length || 0
        if (annLength > 0) {
            let index = 0;
            if (isNext) {
                index = lastIndex + 1 === annLength ? 0 : lastIndex + 1;
            } else {
                index = lastIndex === -1 || lastIndex === 0 ? 0 : lastIndex - 1;
            }
            if (!querId) {
                setIndex(index)
            } else {
                setSelectedTermsIndex(selectedTermsIndex.map((item) => (item.id === querId ? { ...item, lastIndex: index } : item)))
            }
            jumpToMatchGenrally(ann[index]?.annId)

        }
    }

    const getSelectedTermIndex = (querId) => {
        const temp = selectedTermsIndex.find((item) => item.id === querId)
        if (!temp) {
            setSelectedTermsIndex((selectedTermsIndex) => [...selectedTermsIndex,
            {
                id: querId,
                lastIndex: -1
            }])
            return -1;
        } else {
            return temp.lastIndex
        }
    }


    const clearAll = () => {
        if (activeSearchTerms.length > 0) {
            setSelectedTermsIndex([])
            clearSemanticSearch()
        }
    }

    return (
        <div className='semantic-container'>
            <div className='search-header'>
                <a onClick={clearAll}>
                    Clear all
                </a>
            </div>
            <InputFieldWithClear
                onClear={clearAll}
                showIcon={!isArrayEmpty(activeSearchTerms)}>
                <AutoComplete
                    className='autocomplete-input-field width100perc'
                    innerRef={ref}
                    value={activeSearchTerms}
                    itemTemplate={itemTemplate}
                    suggestions={filteredTerms}
                    selectedItemTemplate={selectedItemTemplate}
                    completeMethod={(e) => autocomplete(e.query)}
                    field="name"
                    onSelect={onSelectTerm}
                    onUnselect={onUnselectTerm}
                    // onClear={clearAll}
                    multiple
                    // onChange={(e) => setSelectedTerms(e.value)}
                    autoHighlight={true}
                    placeholder={placeholder ? placeholder : ""}
                    aria-label="terms" />
            </InputFieldWithClear>

            {showFooter ? <div className='search-footer'>
                <div className={`footer-icons ${totalMatches(activeSearchTermsCount) ? '' : ' disable-button'}`} >
                    <label>Overall matches in document:  {`( ${index === -1 ? 0 : index + 1} /
                     ${addThousandsSeparatorToNumber((abstractOnly ? filterAnnotations()?.length :
                        totalMatches(activeSearchTermsCount)) - totalHiddenMatches(hiddenExactTextMatches))} )`}</label>
                    <MatchNavigator findAnnId={findAnnId} />
                </div>
            </div>


                : null}
        </div>
    )
}

SemanticAutoComplete.propTypes = {
    annotations: PropTypes.array,
    activateDomainHighlighting: PropTypes.func,
    jumpToMatchGenrally: PropTypes.func,
    activeSearchTerms: PropTypes.array,
    changeOcidAnnotationColor: PropTypes.func,
    clearSemanticSearch: PropTypes.func,
    domainLabelsMap: PropTypes.object,
    domainColors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    activeSearchTermsCount: PropTypes.object,
    domains: PropTypes.array,
    onSpecifyQueryTerm: PropTypes.func,
    abstractOnly: PropTypes.bool,
    isSemanticOn: PropTypes.bool,
    hiddenExactTextMatches: PropTypes.any,
    showFooter: PropTypes.bool,
    noCounting: PropTypes.bool,
    addTerm: PropTypes.func,
    removeTerm: PropTypes.func,
    placeholder: PropTypes.string
}
