/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { isArrayEmpty } from "../util";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AutocompleteInputField from "../general/autocomplete/AutocompleteInputField";
import { getNumberOfTermsAndConceptsInQuery } from "../general/docsearch/searchUtil";
import { Checkbox } from "primereact/checkbox";
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
import { OverlayPanel } from "primereact/overlaypanel";
import SaveSearchButtons from "../../common/SaveSearch/SaveSearchButtons";
import SavedSearchesLink from "../general/SavedSearches/SavedSearchesLink";


class SearchFormBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    onSaveQuery = (editQuery) => {
        this.props.onSaveQuery(editQuery, this.props.savedSearchID, this.props.savedSearchName);
    }

    render() {

        const { queryTerms, onQueryTermsChange, inclDomainBrowser, inclBulkImport, editableQuery, blockSearchButton,
            onAddQueryTermsWithDomainExplorer, onBulkImport, onSpecifyQueryTerm,
            logicalOperator, logicalOperators, onLogicalOperatorChange, increaseRecall, onIncreaseRecallChange, onSubmitSearch,
            onSelectSavedSearch, savedSearchID, savedSearchName, allowedQueryTypes, queryChanged } = this.props;

        const { numOfTermsInQuery, numOfConceptsInQuery } = getNumberOfTermsAndConceptsInQuery(queryTerms);

        return (
            <>
                <div className="grid">
                    <div className="col" style={{ paddingBottom: 0 }}>
                        {inclDomainBrowser ?
                            <Button
                                className="p-button-text primaryButtonAsLink valignMiddle"
                                style={{ marginRight: 30 }}
                                title="Browse domains and add concepts to your search"
                                onClick={() => onAddQueryTermsWithDomainExplorer()}>
                                Domain explorer
                            </Button> : null
                        }
                        {inclBulkImport ?
                            <Button
                                className="p-button-text primaryButtonAsLink valignMiddle"
                                title="Add multiple IDs to your search"
                                style={{ marginRight: 30 }}
                                onClick={() => onBulkImport()}>
                                Import IDs
                            </Button> : null
                        }
                        <SavedSearchesLink
                            allowedQueryTypes={allowedQueryTypes}
                            onSearchSelect={(savedSearch) => onSelectSavedSearch(savedSearch)}
                        />
                    </div>
                    <div className="col-fixed textAlignRight" style={{ paddingBottom: 0 }}>
                        <Button
                            className="p-button-text primaryButtonAsLink valignMiddle"
                            style={{ marginLeft: 30, padding: 0 }}
                            title="Reset page"
                            onClick={() => this.props.onClearAll()}>
                            Clear all
                        </Button>
                    </div>
                    <div className="col-12">
                        <AutocompleteInputField
                            filterDefinitions={this.props.filterDefinitions}
                            queryTerms={queryTerms}
                            onQueryTermsChange={onQueryTermsChange}
                            onSpecifyQueryTerm={onSpecifyQueryTerm}
                        />
                    </div>
                    <div className="col-fixed" style={{ paddingTop: 0 }}>
                        {!isArrayEmpty(queryTerms) ?
                            <SaveSearchButtons
                                savedSearchExists={!!savedSearchID}
                                editableQuery={editableQuery}
                                onSaveSearch={this.onSaveQuery}
                                savedSearchName={savedSearchName}
                            /> : null}
                    </div>
                    <div className="col textAlignRight" style={{ paddingTop: 0 }}>
                        {numOfTermsInQuery >= 2 ?
                            <span style={{ marginLeft: 30 }} title="OR-combining can only be applied to concepts and text, but not filters.">
                                <label style={{ verticalAlign: '-moz-middle-with-baseline' }} htmlFor="logic">Combine search terms:</label>
                                <Dropdown
                                    id="logic"
                                    value={logicalOperator}
                                    options={logicalOperators}
                                    onChange={(e) => { onLogicalOperatorChange(e.value) }}
                                    className='switch valignMiddle'
                                    style={{ textAlign: 'left', minWidth: 70 }}
                                    placeholder='logical operator' />
                            </span> : null}
                        {numOfConceptsInQuery >= 1 ?
                            <span style={{ marginLeft: 30 }}>
                                <Checkbox
                                    inputId="recall"
                                    onChange={(e) => onIncreaseRecallChange(e.checked)}
                                    checked={increaseRecall}
                                    className='valignMiddle'
                                >
                                </Checkbox>
                                <label htmlFor="recall"
                                    //title={isManuallyAdded ? `"${filterEntry.term}" with variants` : filterEntry.term}
                                    className="p-checkbox-label valignMiddle">
                                    Fuzzy search
                                </label>
                                <a className="infoIconLink valignMiddle"
                                    style={{ marginTop: -1 }}
                                    title="Click for information about fuzzy search"
                                    onClick={e => this.recallInfoOverlay.toggle(e)}>
                                    <img src={infoIcon} alt="Fuzzy search information"
                                        style={{ marginLeft: 10 }} />
                                </a>
                                <OverlayPanel
                                    //className="removeArrow"
                                    ref={(el) => this.recallInfoOverlay = el}
                                    dismissable={true}>
                                    <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                                        <p>
                                            Semantic search is very powerful in finding relevant concepts and distinguishing wrong from right
                                            homonyms in texts, e.g. cancer (disease) vs. cancer (species).
                                            Sometimes it is necessary though to find more hits while accepting a decrease in precision.
                                        </p>
                                        <p>
                                            Clicking this checkbox will add the exact text you typed into the search field to your semantic query
                                            and will therefore also find occurrences which were not recognized by our system.
                                        </p>
                                    </div>
                                </OverlayPanel>

                            </span> : null}
                        {!isArrayEmpty(queryTerms) ?
                            <Button
                                className="p-button-text primaryButtonAsLink valignMiddle"
                                style={{ marginLeft: 30 }}
                                title="Send search to advanced search to use more powerful syntax"
                                onClick={() => this.props.onSendToAdvancedSearch()}>
                                Advanced search
                            </Button> : null}
                        <Button
                            label='Search'
                            className={`p-button-sm primaryButton valignMiddle ${queryChanged ? 'attention' : ''}`}
                            disabled={blockSearchButton}
                            onClick={onSubmitSearch}
                            style={{ marginLeft: 30 }} />
                    </div>
                </div>
            </>
        );
    }
}

export default SearchFormBlock;