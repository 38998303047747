import _ from "lodash";
import { createTopHitDocumentsRequest, fetchTopHitDocuments } from "../../../../api/content/DocumentApi";
import { MAX_CHUNK_SIZE } from "./constants";
import { getDocumentsMapFromResponses, getUnmatchedDocumentIDsForRepo } from "./results";
import { isArrayEmpty } from "../../util";


/* === patent ID normalization === */

export const removeKindCodeFromPatentID = (patentID) => {
    return patentID ? patentID.replace(/(-| )?\(?[A-Z][1-9]*\)?$/, '') : '';
}


/* === searching === */

export const createPromisesToFetchDocuments = (inputIDChunks, repository, searchData, isExact, sortOption) => {

    const promises = [];
    inputIDChunks.forEach(inputIDChunk => {
        const queries = {};
        let totalHitCountThreshold = 0;
        inputIDChunk.forEach(docID => {
            if (isExact) {
                const id = docID;
                queries[docID] = { query: `${searchData.queryPrefix}:"${id}" sortBy:"${sortOption}"` }
            }
            else {
                totalHitCountThreshold = 1;
                const id = removeKindCodeFromPatentID(docID);
                queries[docID] = { query: `${searchData.queryPrefix}:"${id}" sortBy:"${sortOption}"` }
            }
        });
        const request = createTopHitDocumentsRequest(queries, totalHitCountThreshold);
        //console.log('request', request);
        promises.push(fetchTopHitDocuments(repository.id, request));
    });

    return promises.map(promise => promise.catch(error => error));
}


export const runDocumentMatchingForAllRepos = async (ids, repositories, searchData, sortOption) => {

    const results = [];
    for (let repo of repositories) {
        const documentsMap = await runDocumentMatchingForRepo(ids, repo, searchData, true, sortOption);
        results.push(documentsMap);
    }
    const resultsMapAll = _.merge(...results);

    return resultsMapAll;
}


export const runSimilarDocumentMatchingForAllRepos = async (resultsExact, ids, repositories, searchData, sortOption) => {

    const results = [];
    for (let repo of repositories) {
        const unmatchedIDs = getUnmatchedDocumentIDsForRepo(resultsExact, ids, repo.id);
        const documentsMap = await runDocumentMatchingForRepo(unmatchedIDs, repo, searchData, false, sortOption);
        results.push(documentsMap);
    }
    const resultsMapAll = _.merge(...results);

    return resultsMapAll;
}


export const runDocumentMatchingForRepo = async (documentIDs, repository, searchData, isExact, sortOption) => {

    const inputIDChunks = _.chunk(documentIDs, MAX_CHUNK_SIZE);
    const promises = createPromisesToFetchDocuments(inputIDChunks, repository, searchData, isExact, sortOption);
    const documentsMap = await fetchMatchingDocuments(promises, repository, isExact);

    return documentsMap;
}


export const fetchMatchingDocuments = async (promises, repository, isExact) => {

    //console.log('promises', promises);
    const allPromises = Promise.all(promises);
    try {
        const responses = await allPromises;
        // @todo: extra step necessary?
        const documentsMap = getDocumentsMapFromResponses(responses, repository.id, isExact);
        return documentsMap;
    }
    catch (error) {
        // @todo
        console.log('error occurred', error);
    }
}


export const isDataLoaded = (searchData, values, repositories) => {
    return (searchData && !isArrayEmpty(values) && !isArrayEmpty(repositories));
}