import { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { Slider } from "primereact/slider";
import { InputNumber } from "primereact/inputnumber";


class FilterSectionNumSlider extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }

    render() {

        const { filterID, minValue = 0, maxValue = 100, value, unit = '%', filterInfo, onFilterValuesChange } = this.props;

        //console.log('this.props: ', this.props);
        //console.log('value: ', this.props.value);
        //console.log('min: ', this.props.minValue);
        //console.log('max: ', this.props.maxValue);

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className="grid" style={{ marginLeft: 0 }}>
                    <div className="col-fixed labelColumn">
                        <label htmlFor="minValue" className="valignMiddle">At least</label>
                    </div>
                    <div className="col">
                        <InputNumber
                            value={value}
                            onValueChange={(e) => {
                                const filterObject = {
                                    filterID: filterID,
                                    value: e.value
                                };
                                onFilterValuesChange(filterObject);
                            }}
                            min={minValue}
                            max={maxValue}
                            className="valignMiddle"
                            style={{ marginRight: 10 }}
                            size={3}
                        />
                        {/*
                        <InputText
                            id='value'
                            value={value}
                            onChange={(e) => {
                                //if (e.target.value !== value) {
                                console.log('e.target.value: ', e.target.value);
                                let minSim = e.target.value;
                                if (minSim) {
                                    if (minSim < minValue) {
                                        minSim = minValue;
                                    }
                                    else if (minSim > maxValue) {
                                        minSim = maxValue;
                                    }
                                }
                                console.log('minSim: ', minSim);
                                const filterObject = {
                                    filterID: filterID,
                                    value: minSim
                                };
                                onFilterValuesChange(filterObject);
                                //
                            }}
                            //placeholder={`2021-03-17`}
                            className="valignMiddle"
                            style={{ width: 50, marginRight: 10 }}>
                        </InputText>
                        */}
                        <span className="valignMiddle">{unit}</span>
                    </div>
                    {filterInfo ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {filterInfo}
                        </div> : null}
                    <div className="col-12">
                        <Slider
                            value={value}
                            min={minValue}
                            max={maxValue}
                            onSlideEnd={(e) => { }}
                            onChange={(e) => {
                                //if (e.value !== value) {
                                const filterObject = {
                                    filterID: filterID,
                                    value: e.value
                                };
                                onFilterValuesChange(filterObject);
                                //}
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default FilterSectionNumSlider;