import {connect} from 'react-redux'
import {fetchTermSuggestions} from '../../../../redux/actions';
import {TrendAnalysisQuickSearch} from "./TrendAnalysisQuickSearch";

const mapStateToProps = state => ({
    terms: state.webAPI.terms, userData: state.user.data,
})

const mapDispatchToProps = dispatch => ({
    fetchTermSuggestions: (history, search, showFailGrowlSuggestions) => dispatch(fetchTermSuggestions(history, search, showFailGrowlSuggestions)),
})

export const TrendAnalysisSearchContainer = connect(mapStateToProps, mapDispatchToProps)(TrendAnalysisQuickSearch);
