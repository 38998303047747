import React, { Component } from 'react';
import PhraseTokenDisplay from '../editor/PhraseTokenDisplay';
import { Button } from 'primereact/button';
import { cloneDeep } from '../../../util';


class PhraseTaggerOutput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTokens: {}
        };
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.phraseTaggerOutput !== this.props.phraseTaggerOutput) {

            const selectedTokens = {};
            if (nextProps.phraseTaggerOutput) {
                for (const token of Object.keys(nextProps.phraseTaggerOutput).map(e => nextProps.phraseTaggerOutput[e])) {
                    selectedTokens[token.key] = token;
                }
            }

            this.setState({
                selectedTokens: selectedTokens
            });
        }
    }


    onTokenClick = (token) => {
        const selectedTokens = this.state.selectedTokens ? { ...this.state.selectedTokens } : {};
        if (selectedTokens[token.key]) {
            delete selectedTokens[token.key];
        }
        else {
            selectedTokens[token.key] = token;
        }

        this.setState({
            selectedTokens: selectedTokens
        });
    }

    onSelectAllTokens = () => {
        const selectedTokens = {};
        if (this.props.phraseTaggerOutput && Object.keys(this.state.selectedTokens).length < Object.keys(this.props.phraseTaggerOutput).length) {
            for (const token of Object.keys(this.props.phraseTaggerOutput).map(e => this.props.phraseTaggerOutput[e])) {
                selectedTokens[token.key] = token;
            }
        }

        this.setState({
            selectedTokens: selectedTokens
        });
    }

    onReplaceTokens = () => {
        const selTokens = [];
        if (this.props.phraseTaggerOutput && this.state.selectedTokens) {
            for (const token of Object.keys(this.props.phraseTaggerOutput).map(e => this.props.phraseTaggerOutput[e])) {
                if (this.state.selectedTokens[token.key]) {
                    selTokens.push(cloneDeep(token));
                }
            }
        }
        this.props.onReplaceTokens(selTokens);
    }

    onAddTokens = () => {
        const selTokens = [];
        if (this.props.phraseTaggerOutput && this.state.selectedTokens) {
            for (const token of Object.keys(this.props.phraseTaggerOutput).map(e => this.props.phraseTaggerOutput[e])) {
                if (this.state.selectedTokens[token.key]) {
                    selTokens.push(cloneDeep(token));
                }
            }
        }
        this.props.onAddTokens(selTokens);
    }

    render() {

        const { phraseTaggerOutput, contentBoxHeight } = this.props;

        return (
            <>
                <div className="col-12">
                    <div className="task-box">
                        <div className="task-box-header" style={{ padding: '10px 0' }}>
                            <span>Phrase tagger output</span>
                            <Button label={`Select/Deselect all tokens`}
                                className="p-button-text primaryButtonLink"
                                title="Select/Deselect all tokens"
                                onClick={this.onSelectAllTokens}
                                style={{ float: 'right' }} />
                        </div>
                        <div className="task-box-content"
                            style={{ padding: '10px', height: contentBoxHeight, overflow: 'auto', border: '1px solid #ddd' }}>
                            {phraseTaggerOutput ?
                                <PhraseTokenDisplay
                                    phraseTokens={phraseTaggerOutput}
                                    selectedTokens={this.state.selectedTokens}
                                    onTokenClick={this.onTokenClick}
                                    groupIndex={0}
                                    showAdditionalData={true}
                                    showProperties={true} />
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12" style={{ textAlign: 'right' }}>
                    {this.props.allowAppend ?
                        <>
                            <Button label="Replace"
                                className="primaryButton p-button-sm"
                                style={{ marginLeft: ".5em" }}
                                disabled={!this.state.selectedTokens || Object.keys(this.state.selectedTokens).length === 0}
                                onClick={(e) => this.onReplaceTokens()} >
                            </Button>
                            <Button label="Append"
                                className="primaryButton p-button-sm"
                                style={{ marginLeft: "10px" }}
                                disabled={!this.state.selectedTokens || Object.keys(this.state.selectedTokens).length === 0}
                                onClick={(e) => this.onAddTokens()} >
                            </Button>
                        </>
                        :
                        <Button label="Add"
                            className="primaryButton p-button-sm"
                            style={{ marginLeft: ".5em" }}
                            disabled={!this.state.selectedTokens || Object.keys(this.state.selectedTokens).length === 0}
                            onClick={(e) => this.onReplaceTokens()} >
                        </Button>
                    }
                </div>
            </>
        );
    }
}

export default PhraseTaggerOutput;