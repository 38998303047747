import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { fetchMatchingCompoundStructuresV2 } from "../../../../../api/content/ChemistryApi";
import { APP_PROPERTIES, RESPONSE_STATUS_SUCCESS } from "../../../../../properties";
import { COMPOUND_PROPERTIES } from "../../../chemistry/results/compounds/compoundProperties";
import { CHEM_DB_ITEMS, SEARCH_TYPE_OCID } from "../../../chemistry/utils/chemistryDBs";
import { isArrayEmpty, roundNumber } from "../../../util";
import CellData from "../../../general/tabledata/CellData";
import LoadingOverlay from "@speedy4all/react-loading-overlay";


const PhysicoChemicalProperties = ({ ocid }) => {

  const growl = useRef(null);

  const [physChemProperties, setPhysChemProperties] = useState(null);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setPhysChemProperties(null);
    // fetch physico-chemical properties for given OCID
    if (ocid) {
      setLoading(true);

      // TODO: createRequest(...)
      const fetchProperties = async () => {
        const request = {
          searchType: SEARCH_TYPE_OCID,
          maxNumOfResults: 1,
          searchParameters: {
            ocids: [ocid],
            includeMixtures: false,
          }
        };
        const response = await fetchMatchingCompoundStructuresV2(request, CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.OC]);
        if (response.status === RESPONSE_STATUS_SUCCESS) {
          const pcProps = !isArrayEmpty(response.payload.hits) ? response.payload.hits[0] : null;
          setPhysChemProperties(pcProps);
        }
        else {
          growl.current.show({ severity: 'error', summary: 'Error fetching physico-chemical properties', detail: response.message });
        }
        setLoading(false);
      };
      fetchProperties();
    }
  }, [ocid]);

  const physChemPropsDefinitions = COMPOUND_PROPERTIES.filter(propDef =>
    propDef.isPhysChemProperty && physChemProperties && physChemProperties[propDef.id]);

  return <>
    <LoadingOverlay
      active={loading}
      text="Loading properties..." >
      {loading || physChemProperties ?
        <div className="grid small-padding-tb">
          {physChemPropsDefinitions.map(propDef => {
            const value = propDef.roundNumber ? roundNumber(physChemProperties[propDef.id], 1) : physChemProperties[propDef.id];
            return <React.Fragment key={propDef.label}>
              <div className={`col-fixed breakWord`}
                style={{ width: 147 }}>
                {propDef.label}
              </div>
              <div className='col'>
                <CellData
                  cropContent={true}
                  allowCopy={true}
                  content={value}
                  noPaddingLeft={false}
                />
              </div>
              <div className="breakRow"></div>
            </React.Fragment>
          })}
        </div>
        :
        <span>No entries</span>
      }
    </LoadingOverlay>

    <Toast ref={growl} />
  </>
}

export default PhysicoChemicalProperties;