import { QUERY_TYPE_CHEM_SEARCH, QUERY_FORM_CHEM_ATTRIBUTE } from "../../../properties";
import { getDataFromLocalStorage, removeDataFromLocalStorage } from "../util/localStorage";
import { CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND, CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, CHEMISTRY_QUERY_TERM_TYPE_SMILES } from "./utils/chemistrySearchConstants";


/**
 * Loads a chemistry search from the local storage and converts it to a state object.
 * @returns 
 */
export const getChemistrySearchFromStorage = () => {
    let savedSearchState = null;
    const savedSearchFromStorage = getDataFromLocalStorage(QUERY_TYPE_CHEM_SEARCH.id);
    if (savedSearchFromStorage) {
        savedSearchState = savedSearchFromStorage.data?.fromShortcut ?
            savedSearchFromStorage.data : convertSavedChemistrySearchToState(savedSearchFromStorage);
        removeDataFromLocalStorage(QUERY_TYPE_CHEM_SEARCH.id);
    }
    return savedSearchState;
}

/**
 * Converts a saved chemistry search object to a state object.
 * @param {*} savedSearch 
 * @returns 
 */
export const convertSavedChemistrySearchToState = (savedSearch) => {
    if (savedSearch?.formContent && savedSearch.form) {
        //console.log('savedSearch: ', savedSearch);
        //savedSearch.fromShortcut
        let formContent = JSON.parse(savedSearch.formContent);
        //console.log('formContent: ', formContent);

        const isAttributeSearch = savedSearch.form === QUERY_FORM_CHEM_ATTRIBUTE.id;
        const structure = !isAttributeSearch ? atob(formContent.structure) : null;

        let editable = true

        /*if (savedSearch.queryCollectionList && savedSearch.queryCollectionList.length > 0) {
            savedSearch.queryCollectionList.every(list => {
                if (list.shared && !list.writable) {
                    editable = false
                    return false
                }
                return true
            })
        }*/
        if (!savedSearch.isCurrentUserOwner) {
            editable = false
        }

        return {
            // new: store ID and name only, fetch saved search data when dialog is opened
            savedSearchID: savedSearch.id,
            savedSearchName: savedSearch.fullName,
            //
            selectedSavedSearch: savedSearch,
            isAttributeSearch: isAttributeSearch,
            queryTerms: formContent.queryTerms,
            structure: structure,
            withMixtures: formContent.withMixtures,
            filterInputValues: formContent.filters,
            selectedTargetOption: formContent.targetOption,
            editableQuery: editable, //!savedSearch.queryCollectionList[0] || !savedSearch.queryCollectionList[0].shared, //|| (savedSearch.queryCollectionList[0].shared && savedSearch.queryCollectionList[0].writable),
            //queryID: savedSearch.id,
            //queryFullName: savedSearch.fullName,
            //queryName: savedSearch.name,
            //queryDescription: savedSearch.description,
            queryString: savedSearch.val,
            //selectedQueryCollectionIDs: savedSearch.queryCollectionList ? savedSearch.queryCollectionList.map(coll => coll.id) : []
        };
    }
    else {
        return {
            // new: store ID and name only, fetch saved search data when dialog is opened
            savedSearchID: null,
            savedSearchName: '',
            //
            selectedSavedSearch: null,
            editableQuery: false,
            //queryID: null,
            //queryFullName: '',
            //queryName: '',
            //queryDescription: '',
            queryString: '',
            //selectedQueryCollectionIDs: []
        };
    }
    //return null;
}


/**
 * 
 * @param {*} request 
 * @returns 
 */
export const createChemistrySearchObjectFromConcept = (request) => {

    let queryTerms;

    if (request?.ocid && request?.domain && request?.name) {
        queryTerms = [{
            ocids: [request.ocid],
            domains: [request.domain],
            label: request.name,
            queryValues: [request.name],
            term: request.name,
            type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
            subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
        }];
    }
    else if (request?.smiles) {
        queryTerms = [{
            label: request.smiles,
            queryValues: [request.smiles],
            term: request.smiles,
            type: CHEMISTRY_QUERY_TERM_TYPE_SMILES,
            subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
        }];
    }

    if (queryTerms) {
        const req = {
            fromShortcut: true,
            isAttributeSearch: true,
            queryTerms: queryTerms,
        };
        if (request.searchTargetOption) {
            req.selectedTargetOption = request.searchTargetOption;
        }
        if (request.searchType) {
            req.filterInputValues = { ...request.searchType };
        }
        return req;
    }

    return null;
}


/**
 * 
 * @param {Number} ocid 
 * @returns 
 */
/*
export const createChemistrySearchObjectFromConceptOcid = (ocid) => {
    if (ocid) {
        const queryTerms = [{
            queryValues: [ocid],
            term: ocid,
            type: CHEMISTRY_QUERY_TERM_TYPE_OCID
        }];

        const req = {
            fromShortcut: true,
            isAttributeSearch: true,
            queryTerms: queryTerms,
        };
        if (request.searchTargetOption) {
            req.selectedTargetOption = request.searchTargetOption;
        }
        if (request.searchType) {
            req.filterInputValues = { ...request.searchType };
        }
        return req;
    }
    return null;
}
*/

/*
export const createChemistrySearchObjectFromStructure = (structure) => {
    if (concept) {
        //console.log('savedSearch: ', savedSearch);
        //savedSearch.fromShortcut
        let formContent = JSON.parse(savedSearch.formContent);
        //console.log('formContent: ', formContent);

        const isAttributeSearch = savedSearch.form === QUERY_FORM_CHEM_ATTRIBUTE.id;
        const structure = !isAttributeSearch ? base64.decode(formContent.structure) : null;

        const queryTerms = [{
            queryValues: [concept.ocid],
            term: concept.ocid,
            type: CHEMISTRY_QUERY_TERM_TYPE_OCID
        }];

        return {
            isAttributeSearch: true,
            queryTerms: queryTerms,
            structure: structure,
            filterInputValues: formContent.filters,
            selectedTargetOption: formContent.targetOption,
        };
    }
    //return null;
}
*/