import {
    BIOACTIVITIES_RESET_STATE, BIOACTIVITIES_SET_CHECKED_RESULTS,
    BIOACTIVITIES_SET_FIRST_RESULT_INDEX,
    BIOACTIVITIES_SET_HEADERS_STRUCTURE_SKELETON, BIOACTIVITIES_SET_INPUT_TEXT, BIOACTIVITIES_SET_IS_FIRST_RENDER,
    BIOACTIVITIES_SET_LEFT_ASIDE_EXPANDED_KEYS,
    BIOACTIVITIES_SET_METADATA, BIOACTIVITIES_SET_NODES,
    BIOACTIVITIES_SET_QUERY_TERMS,
    BIOACTIVITIES_SET_REPOS_WITH_BIOACTIVITIES_SEARCH_FEATURE,
    BIOACTIVITIES_SET_RESULT_COUNT, BIOACTIVITIES_SET_ROLE_MAPPING,
    BIOACTIVITIES_SET_SEARCH_RESULTS,
    BIOACTIVITIES_SET_SELECTED_REPOS_FOR_SEARCH, BIOACTIVITIES_SET_SHOW_CONCEPT_POPUP,
    BIOACTIVITIES_SET_SORT_DIRECTION,
    BIOACTIVITIES_SET_SORT_FIELD,
    BIOACTIVITIES_SET_SUGGESTIONS, BIOACTIVITIES_SET_TARGET_OCID,
    BIOACTIVITIES_TOGGLE_IS_FETCHING
} from "../actions";

// export const repositoriesNode = { // initial state for left aside
//     "key": "0",
//     "label": "repositories",
//     "children": [{"key": "0-0", "label": "multiselect"}]
// };

export const initialLeftAsideState = {0: true}; // repositories header should be expanded on default

const initialState = {
    isFetching: false,
    queryTerms: [],
    searchResults: {},
    checkedResults: [],
    reposWithBioactivitiesSearchFeature: [],
    selectedReposForSearch: [],
    // leftAsideExpandedKeys: initialLeftAsideState,
    leftAsideExpandedKeys: {},
    metaData: [],
    roleMapping: [], // used for name-label role mapping
    nodes: [],
    headersStructureSkeleton: [],
    suggestions: [],
    inputText: '', //@todo find the reason why the text in the AC is not cleared after 'Clear all'
    sortField: 'target',
    sortDirection: 'ascending',
    firstResultIndex: 0,
    resultCount: 10,
    isFirstRender: true,
    targetOcid: null,
    showConceptPopup: false
};

const biomarkerReducer = (state = initialState, action) => {
    const {payload, type} = action;

    switch (type) {
        case BIOACTIVITIES_SET_QUERY_TERMS:
            return {...state, queryTerms: payload}
        case BIOACTIVITIES_SET_HEADERS_STRUCTURE_SKELETON:
            return {...state, headersStructureSkeleton: payload}
        case BIOACTIVITIES_SET_LEFT_ASIDE_EXPANDED_KEYS:
            return {...state, leftAsideExpandedKeys: payload}
        case BIOACTIVITIES_SET_REPOS_WITH_BIOACTIVITIES_SEARCH_FEATURE:
            return {...state, reposWithBioactivitiesSearchFeature: payload}
        case BIOACTIVITIES_SET_SELECTED_REPOS_FOR_SEARCH:
            return {...state, selectedReposForSearch: payload}
        case BIOACTIVITIES_SET_SORT_FIELD:
            return {...state, sortField: payload}
        case BIOACTIVITIES_SET_SORT_DIRECTION:
            return {...state, sortDirection: payload}
        case BIOACTIVITIES_RESET_STATE:
            const stateForReset = Object.fromEntries(
                Object.entries(initialState).filter(([key]) => {
                    return !(key === 'reposWithBioactivitiesSearchFeature' || key === 'selectedReposForSearch');
                }))

            return {...state, selectedReposForSearch: [...state.reposWithBioactivitiesSearchFeature], ...stateForReset}
        case BIOACTIVITIES_SET_IS_FIRST_RENDER:
            return {...state, isFirstRender: payload}
        case BIOACTIVITIES_SET_ROLE_MAPPING:
            return {...state, roleMapping: payload}
        case BIOACTIVITIES_SET_NODES:
            return {...state, nodes: payload}
        case BIOACTIVITIES_SET_SUGGESTIONS:
            return {...state, suggestions: payload}
        case BIOACTIVITIES_SET_INPUT_TEXT:
            return {...state, inputText: payload}
        case BIOACTIVITIES_SET_FIRST_RESULT_INDEX:
            return {...state, firstResultIndex: payload}
        case BIOACTIVITIES_SET_RESULT_COUNT:
            return {...state, resultCount: payload}
        case BIOACTIVITIES_TOGGLE_IS_FETCHING:
            return {...state, isFetching: payload}
        case BIOACTIVITIES_SET_METADATA:
            return {...state, metaData: payload}
        case BIOACTIVITIES_SET_SEARCH_RESULTS:
            return {...state, searchResults: payload}
        case BIOACTIVITIES_SET_TARGET_OCID:
            return {...state, targetOcid: payload}
        case BIOACTIVITIES_SET_SHOW_CONCEPT_POPUP:
            return {...state, showConceptPopup: payload}
        case BIOACTIVITIES_SET_CHECKED_RESULTS:
            return {...state, checkedResults: payload}
        default:
            return state
    }
};

export default biomarkerReducer;
