import "./StructureImage.css";

const StructureImage = ({
  image,
  name
}) => {

  return image ?
    <>
      <div className="structure-image-container">
        <img src={`data:image/svg+xml;base64,${image}`}
          alt={`Chemical structure of ${name}`}
          height="250" width="100%" />
      </div>
    </> 
    : 
    <span>No image</span>;
}

export default StructureImage;