import { STRUCTURE_FORMAT_MOL } from "./chemistrySearchConstants";
import { convertAttributeSearchToStructures, convertAttributeSearchToOcids, extractChemClassOcidsFromAttributeSearch } from "./searchFormUtil";
import { runStructureSearch } from "./runStructureSearchUtil";
import { fetchMatchingCompoundStructuresV2 } from "../../../../api/content/ChemistryApi";
import { SEARCH_RESULT_TYPE_COMPOUNDS, SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE, SEARCH_TYPE_CHEM_CLASS, SEARCH_TYPE_EXACT, SEARCH_TYPE_OCID } from "./chemistryDBs";
import { extractFilterValues, getSearchTypeFromFilterValues } from "./filtersUtil";
import { isArrayEmpty } from "../../util";


export const runAttributeSearch = async (searchTarget, chemDB, queryTerms, filterInputValues, count, nextPageKey, isExport, filterDefinitions, withMixtures, sortByField, sortDirection) => {

    // IF IS EXACT SEARCH -> always
    //const isExactSearch = getSearchTypeFromFilterValues(filterInputValues) === SEARCH_TYPE_EXACT;
    //console.log('isExactSearch: ', isExactSearch);
    //console.log('queryTerms: ', queryTerms);

    // --- is chem class search? --- //
    const chemClassOcids = extractChemClassOcidsFromAttributeSearch(queryTerms);
    //console.log('chemClassOcids: ', chemClassOcids);
    if (chemDB.allowSearchByChemClass && !isArrayEmpty(chemClassOcids)) {
        const { filters } = extractFilterValues(filterInputValues, filterDefinitions);

        const req = {
            searchType: SEARCH_TYPE_CHEM_CLASS,
            maxNumOfResults: 12,
            searchParameters: filters ? { ...filters } : {},
        };
        req.searchParameters.ocids = chemClassOcids;
        if (nextPageKey) {
            req.searchParameters.nextPageKey = nextPageKey;
        }
        if (sortByField) {
            req.searchParameters.orderBy = sortByField;
        }
        if (sortDirection) {
            req.searchParameters.orderDirection = sortDirection;
        }
        if (withMixtures) {
            req.searchParameters.includeMixtures = withMixtures;
        }

        const resp = await fetchMatchingCompoundStructuresV2(req, chemDB, isExport);
        if (resp.payload) {
            resp.payload.type = SEARCH_RESULT_TYPE_COMPOUNDS;
        }
        return resp;
    }

    // --- is chem compound exact search? --- //
    //console.log('queryStructureCount: ', queryStructureCount);
    // > 1 should not happen anymore??? do only for exact searches???
    if (chemDB.allowSearchByOcids && getSearchTypeFromFilterValues(filterInputValues) === SEARCH_TYPE_EXACT) {
        const ocids = await convertAttributeSearchToOcids(queryTerms);
        //console.log('ocids: ', ocids);
        if (!isArrayEmpty(ocids)) {
            const req = {
                searchType: SEARCH_TYPE_OCID,
                maxNumOfResults: 500,
                searchParameters: {} //filters ? { ...filters } : {}, // filters not allowed for exact search
            };
            req.searchParameters.ocids = ocids;
            if (sortByField) {
                req.searchParameters.orderBy = sortByField;
            }
            if (sortDirection) {
                req.searchParameters.orderDirection = sortDirection;
            }
            if (withMixtures) {
                req.searchParameters.includeMixtures = withMixtures;
            }
            //console.log('req: ', req);
            const resp = await fetchMatchingCompoundStructuresV2(req, chemDB, isExport);
            //console.log('resp: ', resp);
            if (resp.payload) {
                resp.payload.type = SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE;
                resp.payload.isCompleteResult = true;
            }
            return resp;
        }
        else {
            return { status: 'FAILED', message: 'Sorry, we could not convert your input into a valid structure.' };
        }
    }

    // --- attribute search should only be available for compounds, not for reactions or query structures --- //
    const format = STRUCTURE_FORMAT_MOL;
    const structure = await convertAttributeSearchToStructures(queryTerms, format);
    //console.log('===> structure: ', structure);
    if (structure) {
        const response = await runStructureSearch(searchTarget, chemDB, structure, filterInputValues, format, count, nextPageKey, isExport, filterDefinitions, withMixtures, sortByField, sortDirection);
        response.queryStructure = structure;
        //console.log('response.payload: ', response?.payload);
        return response;
    }
    else {
        return { status: 'FAILED', message: 'Sorry, we could not convert your input into a valid structure.' };
    }
}