import React, { Component } from 'react'
import { getHeaderTokenText } from '../../api/index'
import axios from 'axios'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';
import { APP_PROPERTIES } from '../../properties/index';
import { Dialog } from 'primereact/dialog'
//import './User.css'

class PasswordUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newPasswordSuccess: false,
            newUserPassword: '',
            newUserPasswordRepeated: '',
            type: 'password',
            typeRepeated: 'password'
        }
    }

    componentDidMount() {
        let showWelcomePassword = localStorage.getItem("showWelcomePassword")
        if(showWelcomePassword !== null){
            localStorage.setItem("showWelcomePassword", false)
        }
    }


    handleShowHide = (e) => {
        e.preventDefault();
        if (this.state.type === 'input') {
            this.setState({
                type: 'password'
            })
        } else {
            this.setState({
                type: 'input'
            })
        }
    }

    handleShowHideRepeated = (e) => {
        e.preventDefault();
        if (this.state.typeRepeated === 'input') {
            this.setState({
                typeRepeated: 'password'
            })
        } else {
            this.setState({
                typeRepeated: 'input'
            })
        }
    }

    onInfoClick = (e) => {
        e.preventDefault();
    }

    onLogout = (e) => {
        e.preventDefault()
        localStorage.clear()
        this.props.history.push('/')
    }

    onChangePW = async (e) => {
        e.preventDefault()
        if (this.state.newUserPassword !== this.state.newUserPasswordRepeated) {
            this.showPasswordWarnGrowl()
        } else {
            var self = this;
            let encodedPassword = btoa(self.state.newUserPassword);
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/user/password`, encodedPassword, { headers: getHeaderTokenText() })
                .then(function (response) {
                    self.setState({
                        userPassword: '',
                        userPasswordRepeated: '',
                        newPasswordSuccess: true
                    }, () => {
                        setTimeout(() => {
                            //console.log("Login")
                            localStorage.clear()
                            self.props.history.push('/')
                        }, 4000)
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEditUserFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showEditUserFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    showPasswordWarnGrowl = () => {
        let msg = { severity: 'error', summary: 'Error!', detail: `The entered passwords are not identical.`, life: 6000 };
        this.growl.show(msg)
    }

    showEditUserFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    render() {
        return (
            <Dialog visible={true} modal={true} closable={false} closeOnEscape={false} style={{ width: "20vw", top: '-5%' }} breakpoints={{ '1625px': "30%", '1050px': "35%", '860px': "40%", '744px': '50%', '655px': "70%" }}>
                <div style={{
                    background: 'white', width: '100%'
                }}>
                    <Toast ref={(el) => { this.growl = el }} />
                    <div style={{}}>
                        {this.state.newPasswordSuccess === false ?
                            <React.Fragment>
                                <form style={{}} onSubmit={this.handleLogin}>
                                    <div >
                                        <div className="grid">
                                            <div className='col-12' style={{ paddingTop: 0 }}>
                                                <div className="col-12" style={{ textAlign: 'center' }}>
                                                    {/*<h1>Sign in to {APP_PROPERTIES.APP_NAME}</h1>*/}
                                                    <label style={{ fontSize: 30, color: 'black' }}>Reset password</label>
                                                </div>
                                                <div className="col-12" style={{ marginTop: 0, textAlign: 'left', paddingBottom: 4 }}>
                                                    <label style={{ color: 'black', paddingLeft: 5 }} htmlFor="companyName">New password</label>
                                                    <Button icon='pi pi-info-circle'
                                                        onClick={(e) => this.onInfoClick(e)}
                                                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }}
                                                        title='Password needs to contain at least 10 characters with one uppercase character and with one number.'>
                                                    </Button>
                                                </div>
                                                <div className="col-12" style={{ padding: '.5em', marginBottom: 10, marginTop: -10 }}>
                                                    <InputText style={{ marginLeft: 5, padding: 5, width: '86%', backgroundColor: 'white' }}
                                                        id="userPassword"
                                                        autoComplete="off"
                                                        type={this.state.type === 'input' ? 'input' : 'password'}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.setState({ newUserPassword: e.target.value })
                                                        }} value={this.state.newUserPassword} />
                                                    <Button
                                                        icon={this.state.type === 'input' ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                                        onClick={(e) => this.handleShowHide(e)}
                                                        style={{ marginBottom: -5, backgroundColor: 'white', color: 'black', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }} >
                                                    </Button>
                                                </div>
                                                <div className="col-12" style={{ marginTop: -20, textAlign: 'left', paddingBottom: 4 }}>
                                                    <label style={{ color: 'black', paddingLeft: 5 }} htmlFor="companyName">Repeat password</label>
                                                    <Button icon='pi pi-info-circle'
                                                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }}
                                                        onClick={(e) => this.onInfoClick(e)}
                                                        title='Password needs to contain at least 10 characters with one uppercase character and with one number.'>
                                                    </Button>
                                                </div>
                                                <div className="col-12" style={{ padding: '.5em', marginTop: -10 }}>
                                                    <InputText style={{ marginLeft: 5, padding: 5, width: '86%', backgroundColor: 'white' }}
                                                        id="userPasswordRepeated"
                                                        autoComplete="off"
                                                        type={this.state.typeRepeated === 'input' ? 'input' : 'password'}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.setState({ newUserPasswordRepeated: e.target.value })
                                                        }} value={this.state.newUserPasswordRepeated} />
                                                    <Button
                                                        icon={this.state.typeRepeated === 'input' ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                                        onClick={(e) => this.handleShowHideRepeated(e)}
                                                        style={{ marginBottom: -5, backgroundColor: 'white', color: 'black', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }} >
                                                    </Button>
                                                </div>
                                                <div className='col-12' style={{ marginBottom: 5, marginRight: 5, marginTop: 5 }}>
                                                    <Button label="Reset"
                                                        style={{ float: "right", marginRight: 20 }}
                                                        className="primaryButton p-button-sm"
                                                        title="Reset"
                                                        icon='pi pi-refresh'
                                                        iconPos='right'
                                                        disabled={this.state.newUserPasswordRepeated !== '' && this.state.newUserPassword !== '' ? false : true}
                                                        onClick={(e) => this.onChangePW(e)} >
                                                    </Button>
                                                    <Button label="Sign out"
                                                        style={{ float: "left", marginLeft: 5 }}
                                                        className="p-button-secondary p-button-sm"
                                                        title="Sign out"
                                                        onClick={(e) => this.onLogout(e)} >
                                                    </Button>
                                                </div>
                                                {/*<div className="col-12">
									<Captcha siteKey='null' onResponse={this.showResponse}></Captcha>
		</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </React.Fragment>
                            :
                            <form //onSubmit={this.handleLogin}
                            >
                                <div>
                                    <div className="grid">
                                        <div className="col-12" style={{ textAlign: 'center' }}>
                                            <label style={{ fontSize: 20, color: 'black' }}>Password successfully reset. You will be redirected to the login page automatically.</label>
                                        </div>
                                        {/*<div className="col-12">
									<Captcha siteKey='null' onResponse={this.showResponse}></Captcha>
		</div>*/}
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default PasswordUpdate
