import "./Infos.css";
import InfoOverlayWithIcon from "../../../webapi/general/InfoOverlayWithIcon";

const ConceptDistanceInfo = () => {
  return (
    <InfoOverlayWithIcon
      infoID="conceptDistanceInfo"
      title="Click for information about concept distance"
      imageAltText="Information about concept distance"
      overlayContent={
        <div className="info-text-container">
          <h1>Concept distance</h1>
          <p>
            You can define a maximum distance between concepts, i.e. it
            restricts the number of non-matching terms between matching
            concepts. The distance is applied to all concepts within this group
            and all of its subgroups.
          </p>
          <p>
            If you set a distance, please follow these rules:
            <ul>
              <li>Only concepts can be specified, no text terms.</li>
              <li>Only AND or NOT operators are allowed.</li>
              <li>
                Within NOT-combined groups only OR-combined concept terms are
                allowed.
              </li>
              <li>It is allowed to change the concept search mode.</li>
            </ul>
          </p>
          <hr />
          <h2>Example</h2>
          <p>
            Concept: Cancer (Disease)
            <br />
            Concept: Monoterpenes (Chemistry)
            <br />
            Operator: AND
            <br />
            Concept distance: 20
            <br />
            <br />
            This will find occurrences of "cancer" and "monoterpenes" (or any
            synonym), within a distance of ~20 words.
          </p>
        </div>
      }
    />
  );
};

export default ConceptDistanceInfo;
