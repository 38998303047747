import { Component } from "react";
import { Toast } from 'primereact/toast';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { APP_PROPERTIES, QUERY_TYPE_QUICK_SEARCH_2 } from '../../../properties/index';
import OntologyBrowser from './OntologyBrowser';
import { videos } from '../../../properties/videos'
import { createQuickSearchObjectFromConcepts } from "../general/docsearch/searchUtil";
import { addDataToLocalStorage } from "../util/localStorage";
import TutorialVideos from "../general/TutorialVideos";
import { isArrayEmpty } from "../util";
import "./OntologyBrowser.css";


class OntologyBrowserView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      setInitialSearchTerm: true,
      pageTutorials: [],
      section: '',
      sectionLabel: ''
    }
  }

  componentDidMount() {
    if (APP_PROPERTIES.APP_ID === 'dimensionss') {
      this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('domains')) });
    }
  }

  onSearchConcept = () => {
    this.setState({
      setInitialSearchTerm: false
    });
  }

  onSubmit = async (concepts) => {
    if (concepts && Object.keys(concepts).length > 0) {
      addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id,
        { data: createQuickSearchObjectFromConcepts(concepts) });
      window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank");
    }
  }


  render() {
    const loading = false;

    return (
      <>
        <Toast ref={(el) => this.growl = el} />

        <LoadingOverlay
          active={loading}
          spinner={true} >
          {!this.props.fetchingAvailableDomains ?
            !isArrayEmpty(this.props.availableDomains) ?
              <>

                <div className='grid'>
                  <div className="col textAlignLeft" style={{ paddingBottom: 0, paddingTop: 10 }}>
                    <h1 style={{ marginBottom: 0 }} className="pageHeader">Domain Explorer</h1>
                  </div>
                  <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                    {!isArrayEmpty(this.state.pageTutorials) ?
                      <span style={{ marginLeft: 30 }}>
                        <TutorialVideos
                          pageTutorials={this.state.pageTutorials}
                          section='domains'
                          sectionLabel='Domain Explorer'
                        /></span> : null}
                  </div>
                </div>

                <div className='col-12' style={{ paddingLeft: 0, marginBottom: 5 }}>
                  <label style={{ marginRight: 10, marginBottom: 20, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}>Use the Domain Explorer to navigate our
                    semantic knowledge domains. Check for synonyms present and select the right concepts for semantic searches.
                  </label>
                </div>
                <div id="domBrowserContainer" style={{ height: 'calc(100vh - 170px)', background: 'white' }}>
                  <OntologyBrowser
                    containerID="domBrowserContainer"
                    selectionMode={"multiple"}
                    domains={this.props.availableDomains}
                    initialSearchTerm={this.state.setInitialSearchTerm ? this.props.match.params.ocid : null}
                    numOfChildNodes={10}
                    allowConceptSearchByClick={true}
                    allowSearchInOntologies={true}
                    placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                    placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                    loadOntologiesOnStart={true}
                    submitBtnLabel={"Search in documents"}
                    submitBtnCssClass="primaryButton p-button-sm"
                    onSearch={this.onSearchConcept}
                    onSubmit={this.onSubmit}
                    resetBrowser={false} />
                </div>
              </>
              :
              <div>{'No domains available'}</div>
            :
            <div>{'Loading domains'}</div>
          }
        </LoadingOverlay>
      </>
    )
  }
}

export default OntologyBrowserView;