import { isArrayEmpty } from "../../util";
import {
    CHEMISTRY_BULK_IMPORT_TYPE_OCID, CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND, CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, CHEMISTRY_QUERY_TERM_TYPE_INCHI,
    CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY, CHEMISTRY_QUERY_TERM_TYPE_OCID, CHEMISTRY_QUERY_TERM_TYPE_SMILES, SEARCH_TYPE_FILTER_DUPLICATE
} from "./chemistrySearchConstants";
import { isChemClassOcid, isChemCompoundOcid } from "./compoundClassGroup";
import { SEARCH_TARGET_COMPOUNDS, SEARCH_TYPE_EXACT } from "./chemistryDBs";
import {
    ERROR_TYPE_AMBIGUOUS_COMPOUNDS, ERROR_TYPE_CHEM_CLASS_SEARCH_NOT_SUPPORTED, ERROR_TYPE_CHEM_CLASS_SEARCH_SETTINGS_NOT_SUPPORTED,
    ERROR_TYPE_CLASS_AND_COMPOUND_SEARCH_MIXED, ERROR_TYPE_EMPTY_SEARCH
} from "../results/inputerrors/errors";
import { getSearchTypeFromFilterValues } from "./filtersUtil";


export const isSearchEmpty = (isAttributeSearch, queryTerms, structure) => {

    return (isAttributeSearch && isArrayEmpty(queryTerms)) || (!isAttributeSearch && !structure);
}

export const isAmbiguousInput = (isAttributeSearch, queryTerms, selectedTargetOption, filterInputValues) => {

    return isAttributeSearch &&
        (selectedTargetOption !== SEARCH_TARGET_COMPOUNDS ||
            filterInputValues?.searchType?.value?.filterValue !== SEARCH_TYPE_FILTER_DUPLICATE.value.filterValue) &&
        countQueryStructures(queryTerms) > 1;
}

export const checkForIncorrectQueryTerm = (queryTerms) => {
    const { numOfChemClassAndCompoundQueryTerms } = analyzeQuery(queryTerms);
    return (numOfChemClassAndCompoundQueryTerms > 0);
}

export const checkForIncorrectInput = (isAttributeSearch, queryTerms, structure, selectedTargetOption, filterInputValues, chemDB) => {

    if (isSearchEmpty(isAttributeSearch, queryTerms, structure)) {
        return ERROR_TYPE_EMPTY_SEARCH;
    }
    if (isAttributeSearch) {

        const { numOfChemCompoundsInQuery, numOfChemClassesInQuery } = analyzeQuery(queryTerms);

        // --- chem class search not allowed for chem DB --- //
        if (!chemDB?.allowSearchByChemClass && numOfChemClassesInQuery > 0) {
            return ERROR_TYPE_CHEM_CLASS_SEARCH_NOT_SUPPORTED;
        }

        const searchType = getSearchTypeFromFilterValues(filterInputValues);
        const isNonExactSearch = searchType !== SEARCH_TYPE_EXACT || selectedTargetOption !== SEARCH_TARGET_COMPOUNDS;

        // --- non-exact search with chem classes not allowed --- //
        if (/*chemDB?.multipleStructuresAllowed && */isNonExactSearch && numOfChemClassesInQuery > 0) {
            return ERROR_TYPE_CHEM_CLASS_SEARCH_SETTINGS_NOT_SUPPORTED;
        }

        const queryStructureCount = countQueryStructures(queryTerms);
        const isBulkImport = containsBulkImport(queryTerms);

        // --- ambiguous input not allowed for chem DB --- //
        if ((queryStructureCount > 1 || isBulkImport) && (!chemDB?.multipleStructuresAllowed || isNonExactSearch)) {
            return ERROR_TYPE_AMBIGUOUS_COMPOUNDS;
        }
        // --- chem compounds and chem classes mixed or input term is both --- //
        if (numOfChemClassesInQuery > 0 && numOfChemCompoundsInQuery > 0) {
            return ERROR_TYPE_CLASS_AND_COMPOUND_SEARCH_MIXED;
        }
        return null;
    }
    else {
        return null;
    }
}

export const isChemClassInput = (queryTerms) => {

    const { numOfChemClassesInQuery } = analyzeQuery(queryTerms);
    return (numOfChemClassesInQuery > 0); // && numOfChemCompoundsInQuery === 0;???
}

export const countQueryStructures = (queryTerms) => {

    let queryStructureCount = 0;

    if (queryTerms) {
        for (let queryTerm of queryTerms) {

            const type = queryTerm.type;
            if (type) {
                switch (type) {
                    case CHEMISTRY_QUERY_TERM_TYPE_OCID:
                    case CHEMISTRY_QUERY_TERM_TYPE_SMILES:
                    case CHEMISTRY_QUERY_TERM_TYPE_INCHI:
                    case CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY: {
                        queryStructureCount += 1;
                        break;
                    }
                    case CHEMISTRY_QUERY_TERM_TYPE_CONCEPT: {
                        if (queryTerm.ocids) {
                            for (let ocid of queryTerm.ocids) {
                                if (ocid && isChemCompoundOcid(ocid)) {
                                    queryStructureCount += 1;
                                }
                            }
                        }
                        else {
                            queryStructureCount += 1;
                        }
                        break;
                    }
                    case CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS: {
                        queryStructureCount += queryTerm.bulkIDs ? queryTerm.bulkIDs.length : 0;
                        break;
                    }
                    default: // do nothing
                }
            }
        }
    }
    return queryStructureCount;
}
export const containsBulkImport = (queryTerms) => {
    if (queryTerms) {
        for (let queryTerm of queryTerms) {
            const type = queryTerm.type;
            if (type === CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS) {
                return true;
            }
        }
    }
    return false;
}
/**
 * Returns number of terms and concepts found in list of query terms,
 * as well as info if query terms contain concept distance, search mode or search mode filters.
 * @param {*} queryTerms
 * @returns
 */
export const analyzeQuery = (queryTerms) => {

    let numOfTermsInQuery = 0;
    let numOfConceptsInQuery = 0;
    let numOfChemCompoundsInQuery = 0;
    let numOfChemClassesInQuery = 0;
    let numOfChemClassAndCompoundQueryTerms = 0;
    let doAllClassesHaveOcids = true;
    //let numOfCompoundOcids = 0;

    queryTerms?.forEach(queryTerm => {
        numOfTermsInQuery++;

        let isChemCompound = false;
        let isChemClass = false;

        let ocids;

        if (queryTerm.type === CHEMISTRY_QUERY_TERM_TYPE_CONCEPT) {
            ocids = queryTerm.ocids;
        }
        else if (queryTerm.type === CHEMISTRY_QUERY_TERM_TYPE_OCID) {
            ocids = [queryTerm.term];
        }
        else if (queryTerm.type === CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS && queryTerm.bulkIDType === CHEMISTRY_BULK_IMPORT_TYPE_OCID) {
            ocids = queryTerm.bulkIDs;
        }
        // Chemical compound other than concept, e.g. InChI, SMILES etc.
        else if (queryTerm.subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND) {
            numOfConceptsInQuery++;
            numOfChemCompoundsInQuery++;
            isChemCompound = true;
        }

        ocids?.forEach(ocid => {
            numOfConceptsInQuery++;
            if (isChemCompoundOcid(ocid)) {
                numOfChemCompoundsInQuery++;
                isChemCompound = true;
            }
            else if (isChemClassOcid(ocid)) {
                isChemClass = true;
                numOfChemClassesInQuery++;
            }
        });

        if (isChemCompound && isChemClass) {
            numOfChemClassAndCompoundQueryTerms++;
        }
    });

    return {
        numOfTermsInQuery, numOfConceptsInQuery, numOfChemCompoundsInQuery,
        numOfChemClassesInQuery, numOfChemClassAndCompoundQueryTerms, doAllClassesHaveOcids
    };
}