
export const QuickSearchActions = {
  SetQuickSearchSelectedTerms: 'SET_QUICK_SEARCH_SELECTED_TERMS',
  SetDocumentTitle: 'SET_DOCUMENT_TITLE',
};


export const setQuickSearchSelectedTerms = ({ newTerms, rewrite = false }) => ({
  type: QuickSearchActions.SetQuickSearchSelectedTerms,
  payload: { newTerms, rewrite },
});

export const setDocumentTitle = ({ terms, rewrite = false }) => ({
  type: QuickSearchActions.SetDocumentTitle,
  payload: { terms, rewrite },
});
