import { Dropdown } from 'primereact/dropdown';

const dropdownOptions = [
    { label: `3 entries per page`, value: 3 },
    { label: `5 entries per page`, value: 5 },
    { label: `10 entries per page`, value: 10 },
    { label: `20 entries per page`, value: 20 },
    { label: `50 entries per page`, value: 50 }
]

const CustomPaginatorRightSide = ({ rows, onChange, style, pageSizeOptions }) => {
    const options = pageSizeOptions ? pageSizeOptions : dropdownOptions;
    return (
        <Dropdown style={ style !== undefined ? style :{ marginRight: -20, marginTop: 1 }} className='dropdownNoBorder' value={rows} options={options} onChange={onChange} />
    );
};

export default CustomPaginatorRightSide;