import React from 'react';

const LabelTermMenuHeader = ({occurrence}) => {
    return (
        <div style={{
            paddingBottom: 3, paddingLeft: 5, paddingTop: occurrence === "MUST" ? 3 : 0
        }}>
            <div style={{fontWeight: 'bold'}}> {occurrence === 'MUST' ? 'Filter' : 'Exclude'}
                {/*<span className="material-icons-outlined"
                      style={{fontSize: '1.3em', paddingLeft: 5, verticalAlign: 'sub'}}>
    {occurrence === 'MUST' ? 'filter_alt' : 'remove_circle_outline'} </span>*/}
            </div>
        </div>
    );
};

export default LabelTermMenuHeader;
