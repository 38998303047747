import "./GoogleLinkouts.css";
import { APP_PROPERTIES } from "../../../../../properties";
import { isArrayEmpty } from "../../../util";
import googleLogo from '../../../../../assets/images/icons/google_G.png';
import GoogleLinks from "../../../../common/Link/GoogleLinks";


const GoogleLinkouts = ({ smiles }) => {

  return APP_PROPERTIES.CHEMISTRY.showGoogleLinks && !isArrayEmpty(smiles) ?
    <div className="noStyleList">
      <h3>
        <img
          alt="Google search in Patents and/or Non-Patent Literature"
          src={googleLogo}
          className="google-logo"
        />
        <span>Google Patents+</span>
      </h3>
      <GoogleLinks smiles={smiles} />
    </div> : null;
}

export default GoogleLinkouts