/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button } from 'primereact/button';
//import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';

class EditBackend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAbbreviation: '',
            selectedDefault: false,
            selectedName: '',
            selectedType: '',
            selectedURL: '',
            selectedLabel: '',
            selectedPort: '',
            selectedUsername: '',
            selectedNumberParallelConnections: '',
            selectedCertFolder: '',
            newPassword: '',
            updatePassword: false,
            useSSL: false
        }
    }

    onUpdatePasswordChange = (e) => {
        if (e.checked) {
            this.setState({
                updatePassword: true
            })
        } else {
            this.setState({
                updatePassword: false
            })
        }
    }

    componentDidMount() {

        //console.log(this.props)

        let selectedAbbreviation = this.props.selectedAbbreviation ? this.props.selectedAbbreviation : ''
        let selectedDefault = this.props.selectedDefault ? this.props.selectedDefault : false
        let selectedLabel = this.props.selectedLabel ? this.props.selectedLabel : ''
        let selectedUsername = this.props.selectedUsername ? this.props.selectedUsername : ''
        let selectedNumberParallelConnections = this.props.selectedNumberParallelConnections ? this.props.selectedNumberParallelConnections : ''
        let selectedCertFolder = this.props.selectedCertFolder ? this.props.selectedCertFolder : ''
        let selectedName = this.props.selectedName ? this.props.selectedName : ''
        let selectedURL = this.props.selectedURL ? this.props.selectedURL : ''
        let selectedPort = this.props.selectedPort ? this.props.selectedPort : ''
        let selectedType = this.props.selectedType ? this.props.selectedType : ''
        let useSSL = this.props.selectedUseSSL === undefined ? 'No info' : (this.props.selectedUseSSL !== undefined && this.props.selectedUseSSL === false) ? 'no' : 'yes'

        this.setState({
            selectedAbbreviation: selectedAbbreviation,
            selectedDefault: selectedDefault,
            selectedLabel: selectedLabel,
            selectedUsername: selectedUsername,
            selectedNumberParallelConnections: selectedNumberParallelConnections,
            selectedCertFolder: selectedCertFolder,
            selectedName: selectedName,
            selectedURL: selectedURL,
            selectedPort: selectedPort,
            selectedType: selectedType,
            useSSL: useSSL
        })
    }

    onEditBackend = () => {
        this.props.editBackend(this.state.selectedAbbreviation, this.state.selectedDefault, this.state.selectedLabel, this.state.selectedUsername,
            this.state.selectedNumberParallelConnections, this.state.selectedCertFolder, this.state.newPassword, this.state.updatePassword)
    }

    render() {

        /*const footerEditBackend = (
            <div>
                <Button label="Cancel"
                    className='p-button-sm p-button-secondary'
                    onClick={this.props.onCancelEditBackend}
                />
                <Button label="Save"
                    className='p-button-sm primaryButton'
                    onClick={this.onEditBackend}
                    disabled={this.state.selectedAbbreviation !== '' && this.state.selectedLabel !== '' && this.state.selectedNumberParallelConnections !== '' && (!this.state.updatePassword || this.state.newPassword !== '') ? false : true}
                />
            </div>
        )*/

        return (
            <Dialog visible={this.props.displayDialogEditBackend} style={{ 'width': "70vw" }} header="Backend details" focusOnShow={false}
                modal={true}
                //footer={footerEditBackend}
                dismissableMask={false}
                onHide={() => this.props.onCancelEditBackend()} className='styledDialog'>
                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend name</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ selectedName: e.target.value }) }} disabled={true} value={this.state.selectedName} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend type</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ selectedType: e.target.value }) }} disabled={true} value={this.state.selectedType} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend URL</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ selectedURL: e.target.value }) }} disabled={true} value={this.state.selectedURL} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend port</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ selectedPort: e.target.value }) }} disabled={true} value={this.state.selectedPort} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend label (for displaying)</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ selectedLabel: e.target.value }) }} value={this.state.selectedLabel} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Backend abbreviation</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ selectedAbbreviation: e.target.value }) }} value={this.state.selectedAbbreviation} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label># Parallel connections</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} keyfilter='pnum'
                            onChange={(e) => { this.setState({ selectedNumberParallelConnections: e.target.value }) }} value={this.state.selectedNumberParallelConnections} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Is default</label>
                        <div>
                            <InputSwitch style={{
                                marginRight: 10, marginTop: 10 //, verticalAlign: 'sub'
                            }}
                                checked={this.state.selectedDefault}
                                onChange={(e) => this.setState({ selectedDefault: e.value })}
                                disabled={this.state.selectedDefault ? true : false} />
                            <label>{this.state.selectedDefault ? 'Yes' : 'No'}</label>
                        </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Certificate folder</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ selectedCertFolder: e.target.value }) }} value={this.state.selectedCertFolder} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Username</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ selectedUsername: e.target.value }) }} value={this.state.selectedUsername} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Password</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ newPassword: e.target.value }) }} disabled={this.state.updatePassword ? false : true} value={this.state.newPassword} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Update password</label>
                        <div>
                            <Checkbox inputId='activateUserChecked'
                                onChange={(e) => this.onUpdatePasswordChange(e)}
                                checked={this.state.updatePassword ? true : false}
                                value='Check to update password' style={{ 'cursor': 'pointer', paddingTop: 5, marginTop: 5 }} />
                        </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Use SSL</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }}
                            onChange={(e) => { this.setState({ newPassword: e.target.value }) }} disabled={true} value={this.state.useSSL} />
                    </div>
                </div>
                <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                    <Button label="Save"
                        className='p-button-sm primaryButton'
                        onClick={this.onEditBackend}
                        disabled={this.state.selectedAbbreviation !== '' && this.state.selectedLabel !== '' && this.state.selectedNumberParallelConnections !== '' && (!this.state.updatePassword || this.state.newPassword !== '') ? false : true}
                        style={{ marginRight: 5, float: 'right' }} />
                    <Button label="Cancel"
                        className='p-button-sm p-button-secondary'
                        onClick={this.props.onCancelEditBackend}
                        style={{ float: 'right', marginRight: 5 }} />
                </div>
            </Dialog>
        )
    }
}

export default EditBackend