/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
//import { Button } from 'primereact/button';
//import dateFormat from 'dateformat';
//import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
//import { Paginator } from 'primereact/paginator';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import moment from 'moment';
import { hasUserRole } from '.././util'
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip'

class Watchlists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilterWatchlists: ''
        }
    }

    addNewWatchlist = () => {
        this.setState({
        }, () => {
            this.props.onAddWatchlist()
        });
    }

    onSortWatchlists = (e) => {
        this.props.onSortWatchlists(e.value)
    }

    handleWatchlistChange = (e, list) => {
        //console.log(list)
        e.stopPropagation()
        this.props.handleWatchlistChange(list)
    }

    watchlistAlertChange = (list, status, e) => {
        //this.props.watchlistAlertChange(list, status, e)
        this.props.openAddAlertDialog(list, 'watchlist')
    }

    render() {

        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults
        //const showIcons = APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons;

        let headerWatchlists = <div className='grid' style={{
            marginRight: 0,
            //borderBottom: '1px solid #d6d6d6',
            paddingTop: 15, paddingBottom: 10, borderBottom: '1px solid #727272'
        }}>
            <div className="col-12" style={{ padding: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title='Search collections'>
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', width: '82%' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterWatchlists: e.target.value })}
                    placeholder="Search collections"
                />
            </div>
        </div>

        let sortOptions = [
            { label: 'Created', value: 'created' },
            { label: 'Entries', value: 'numberQueries' },
            { label: 'Modified', value: 'modified' },
            { label: 'Name', value: 'name' }
        ]

        let numberAllQueries = this.props.allQueries ? this.props.allQueries.length : 0

        let filtered

        /*let privateLists = []
        let sharedByLists = []
        let sharedWithLists = []

        this.props.allWatchlists && this.props.allWatchlists.length > 0 && this.props.allWatchlists.forEach(list => {
            if (!list.shared && !list.sharedForDepartment && list.usersSharedWith === undefined && list.userSharesWrite === undefined) {
                privateLists = [...privateLists, list]
            } else if (!list.shared && (list.sharedForDepartment || list.usersSharedWith !== undefined || list.userSharesWrite !== undefined)) {
                sharedByLists = [...sharedByLists, list]
            } else if (list.shared) {
                sharedWithLists = [...sharedWithLists, list]
            }
        })

        let allListsSeparated = privateLists.concat(sharedByLists).concat(sharedWithLists)*/

        //console.log("Private: ", privateLists)
        //console.log("Shared by me: ", sharedByLists)
        //console.log("Shared with: ", sharedWithLists)
        //console.log("All: ", allListsSeparated)

        let globalFilter = ''
        if (this.state.globalFilterWatchlists && this.state.globalFilterWatchlists !== '') {
            globalFilter = this.state.globalFilterWatchlists
        }

        if (this.state.globalFilterWatchlists && globalFilter !== '') {
            if (this.props.allWatchlists && globalFilter) {
                filtered = this.props.allWatchlists.filter(function (el) {
                    //filtered = allListsSeparated.filter(function (el) {
                    return el.name.includes(globalFilter) || (el.description && el.description.includes(globalFilter))
                })
            }
        } else {
            filtered = this.props.allWatchlists
            //filtered = allListsSeparated
        }

        /*filtered && filtered.forEach(element => {
            console.log(element)
        });*/

        //console.log(this.props.selectedWatchlist)


        let watchlists = Object.entries(_.groupBy(filtered?.map(col => ({
            ...col, groupName: !col?.shared ?
                (((col?.departmentSharesRead && col?.departmentSharesRead?.length > 0) || (col?.userSharesRead && col?.userSharesRead?.length > 0) || (col?.userSharesWrite && col?.userSharesWrite?.length > 0)) ? 'Shared by you' : 'private') : 'Shared with you'
        })), 'groupName'))?.sort((x, y) => x[0].toString().length - y[0].toString().length)?.map((item) => {

            return <div key={item[0]}>
                <ReactTooltip place='right' isMultiline={true} type='light' delayShow={300}
                    desiredPlace={"right"} className='tooltip-custom' />
                <div className='col-group-title'>{item[0]}</div>
                {item[1].map((list, index) => {
                    return <div key={list.id} className='col-12' style={{
                        display: 'inline-block', paddingBottom: 10, paddingLeft: 0,
                        borderBottom: index + 1 === item[1]?.length ? 'none' : '1px solid #d8d8d8',
                        backgroundColor: this.props.selectedWatchlist !== undefined && this.props.selectedWatchlist.id === list.id && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                    }} onClick={(e) => this.handleWatchlistChange(e, list)}>
                        {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>{list.name}</label>*/}
                        <div style={{ display: 'flow-root' }}>
                            <a onClick={(e) => this.handleWatchlistChange(e, list)}
                                title={list.name}
                                style={{
                                    marginTop: 0, paddingLeft: 8, float: 'left', marginBottom: 5
                                    , color: 'black'
                                }} className='tooltip-title'> {list.name}</a>
                            {hasUserRole("ROLE_ALERTS") ?
                                ((list.notifications && list.notifications.length === 0) || list.notifications === undefined) ?
                                    <div style={{ display: 'inline-block', float: 'right', marginTop: -4 }}>
                                        <i className="material-icons-outlined" id='read'
                                            title={'Add alert to this search collection'}
                                            onClick={(e) => this.watchlistAlertChange(list, 'add', e)}>
                                            {'notification_add'}
                                        </i>
                                    </div>
                                    : (list.notifications && list.notifications.length > 0 && list.notifications[0].active) ?
                                        <div style={{ display: 'inline-block', float: 'right', marginTop: -4 }}>
                                            <i className="material-icons" id='read'
                                                title={'You receive alerts for this search collection'}
                                                onClick={(e) => this.watchlistAlertChange(list, 'pause', e)}>
                                                {'notifications_active'}
                                            </i>
                                        </div>
                                        : (list.notifications && list.notifications.length > 0 && !list.notifications[0].active) ?
                                            <div style={{ display: 'inline-block', float: 'right', marginTop: -4 }}>
                                                <i className="material-icons" id='read'
                                                    title={'Alerts for this search collection are paused'}
                                                    onClick={(e) => this.watchlistAlertChange(list, 'activate', e)}>
                                                    {'notifications_paused'}
                                                </i>
                                            </div>
                                            : null
                                : null
                            }
                            <div style={{ display: 'inline-block', float: 'right', marginRight: hasUserRole("ROLE_ALERTS") ? 22 : 0, cursor: 'pointer' }} onClick={(e) => this.handleWatchlistChange(e, list)}>
                                <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleWatchlistChange(e, list)}>{list.numberQueries}</label>
                            </div>
                        </div>
                        <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 8, paddingTop: 0, paddingBottom: 0, marginTop: -5 }}>
                            {/* <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleWatchlistChange(e, list)}>{list.description}</label> */}

                            {list.description && <div className='tooltip-description' title={list.description} onClick={(e) => this.handleWatchlistChange(e, list)}>{list.description}</div>}

                            <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleWatchlistChange(e, list)}>{list.modified !== undefined && "  Last modified: " + moment(list.modified + "Z").format("YYYY-MM-DD")}  </label>
                            {
                                <span style={{ cursor: 'pointer' }} onClick={(e) => this.handleWatchlistChange(e, list)}>
                                    {list.shared &&
                                        <span style={{ color: '#757575' }} title={`This search collection is shared with you by another user`}>
                                            {`- Shared with you ${list.owner && list.owner.forename && list.owner.lastName && `(by ${list.owner.forename} ${list.owner.lastName})`}`}
                                        </span>
                                    }
                                    {list.shared && list.writable &&
                                        <span style={{ color: '#757575' }} title={`This search collection is editable for shared users.`}>
                                            {' - Editable for shared users'}
                                        </span>
                                    }
                                    {list.shared && !list.writable &&
                                        <span style={{ color: '#757575' }} title={`This search collection is not editable for shared users.`}>
                                            {' - Not editable for shared users'}
                                        </span>
                                    }
                                </span>
                            }
                            {
                                list.userSharesWrite && list.userSharesWrite.length > 0 ?
                                    <span style={{ color: '#757575' }} title={`This search collection is editable for shared users.`}>
                                        {' - Editable for shared users'}
                                    </span>
                                    : list.userSharesRead && list.userSharesRead.length > 0 ?
                                        <span style={{ color: '#757575' }} title={`This search collection is not editable for shared users.`}>
                                            {' - Not editable for shared users'}
                                        </span>
                                        : null
                            }
                        </div>
                    </div>
                }
                )}

            </div>




        })


        //console.log(this.props.allWatchlists)

        //console.log(this.props.allWatchlists)

        return (
            <div className="col-12 grid-nogutter" style={{ paddingTop: 0, paddingRight: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                        <div className='col-12' style={{
                            padding: 0
                            //, borderRight: '1px solid #d6d6d6' 
                        }}>
                            <div className='col-12'>
                                <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, paddingRight: 0, marginLeft: -4 }}>
                                    <h1 style={{ marginTop: 0, fontSize: '1.1em', float: 'left', marginBottom: 5 }} className="pageHeader">Search collections</h1>
                                    {this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN") ?
                                        <a onClick={(e) => this.props.onImportClick(e)}
                                            style={{ display: 'inline-block', float: 'right', marginRight: -5, marginTop: 1 }}>Import</a>
                                        : null
                                    }
                                    <a onClick={(e) => this.addNewWatchlist(e)}
                                        style={{
                                            display: 'inline-block', float: 'right',
                                            marginRight: this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN") ? 15 : -5, marginTop: 1
                                        }}>New collection</a>
                                </div>
                                {headerWatchlists}
                            </div>
                            <div className='col-12' style={{ paddingTop: 0, paddingRight: 10, paddingBottom: 0, paddingLeft: 0 }}>
                                <div className='col-12' style={{ marginTop: -7, display: 'inline-block', paddingTop: 0, paddingBottom: 0, paddingLeft: 0, borderBottom: '1px solid #727272' }}>
                                    <label style={{ marginTop: 7, float: 'left', marginBottom: 0, paddingRight: 5 }}>Name</label>
                                    <div style={{ display: 'inline-block', float: 'right', marginRight: -25, marginTop: 0 }}>
                                        <label style={{ marginRight: 0, verticalAlign: '-moz-middle-with-baseline' }}>Sort by: </label>
                                        <Dropdown
                                            className='dropdownNoBorder'
                                            appendTo={document.body}
                                            value={this.props.sortWatchlistsBy}
                                            options={sortOptions}
                                            disabled={this.props.allWatchlists && this.props.allWatchlists.length > 1 ? false : true}
                                            onChange={(e) => this.onSortWatchlists(e)}
                                            style={{ marginRight: 5 }} />
                                        <span style={{ marginLeft: 0 }}>
                                            <label style={{ marginRight: 15, verticalAlign: '-moz-middle-with-baseline' }}>Entries</label>
                                        </span>
                                        {hasUserRole("ROLE_ALERTS") &&
                                            <span>
                                                <label style={{ marginRight: 17, verticalAlign: '-moz-middle-with-baseline' }}>Alert</label>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-12' style={{ paddingTop: 0, paddingRight: 10, paddingLeft: 0 }}>
                                <div className='col-12' style={{
                                    display: 'inline-block', paddingBottom: 10, paddingLeft: 8, borderBottom: '1px solid #757575',
                                    backgroundColor: this.props.selectedWatchlist === undefined && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                                }} onClick={(e) => this.handleWatchlistChange(e, 'All saved searches')}>
                                    {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>All saved queries</label>*/}
                                    <a onClick={(e) => this.handleWatchlistChange(e, 'All saved searches')}
                                        style={{
                                            marginTop: 0, float: 'left', marginBottom: 5

                                            , color: 'black'
                                        }}>All saved searches</a>
                                    <div style={{ display: 'inline-block', float: 'right', marginRight: hasUserRole("ROLE_ALERTS") ? 46 : 0, cursor: 'pointer' }} onClick={(e) => this.handleWatchlistChange(e, 'All saved searches')}>
                                        <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleWatchlistChange(e, 'All saved searches')}>{numberAllQueries}</label>
                                    </div>
                                    <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }}>
                                        <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleWatchlistChange(e, 'All saved searches')}>All searches</label>
                                    </div>
                                </div>
                                {watchlists}
                                {/*<PagPaginator
                                first={this.state.first}
                                rows={this.state.rows}
                                template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                totalRecords={this.props.docCollections ? this.props.docCollections.length + 1 : 0}
                                onPageChange={(e) => this.pageChange(e)}
                            rowsPerPageOptions={[10, 20, 50]}></Paginator>*/}
                            </div>
                            {this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN") && this.props.allWatchlists && this.props.allWatchlists.length > 0 ?
                                <div className='col-12' style={{ paddingTop: 0, paddingRight: 12 }}>
                                    <a onClick={(e) => this.props.onExportClick(e)}
                                        style={{
                                            marginTop: 0, float: 'right'
                                        }}>Export</a>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </LoadingOverlay>
            </div >
        )
    }
}

export default Watchlists
