import { NotificationsService } from './NotificationsService';

export class NotificationsServiceImpl extends NotificationsService {
  async getNotifications() {
    const result = await this.repository.getNotifications();
    if (!result.error) {
      const groups = result?.payload ?? [];
      return groups;
    }

    return result;
  }

  async getEnabledNotifications() {
    const result = await this.repository.getEnabledNotifications();
    if (!result.error) {
      const groups = result?.payload ?? [];
      return groups;
    }

    return result;
  }

  async createNotification(notificationText) {
    const result = await this.repository.createNotification(notificationText);
    if (!result.error) {
      const payloadData = result?.payload ?? [];
      return payloadData;
    }

    return result;
  }

  async toggleNotificationState(notificationId, notificationState) {
    const result = await this.repository.toggleNotificationState(notificationId, notificationState);
    if (!result.error) {
      const payloadData = result?.payload ?? [];
      return payloadData;
    }

    return result;
  }

  async updateNotification(notification) {
    const result = await this.repository.updateNotification(notification);
    if (!result.error) {
      const payloadData = result?.payload ?? [];
      return payloadData;
    }

    return result;
  }

  async deleteNotification(notificationId) {
    const result = await this.repository.deleteNotification(notificationId);
    if (!result.error) {
      const payloadData = result?.payload ?? [];
      return payloadData;
    }

    return result;
  }
}
