/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dialog } from 'primereact/dialog';
import { Component } from 'react';
import { isChemCompoundOcid } from '../utils/compoundClassGroup';



class CompoundImage extends Component {

    constructor(props) {
        super(props);

        this.state = { displayImageLarge: false };
    }

    render() {

        const { image, ocid, smiles, height, width, maxHeight, maxWidth, maxImageHeight, maxImageWidth,
            onReplaceStructure, showCompoundInfo, showMoreInfoIcon, moreInfoIconStyle } = this.props;

        return (
            <div className="structureImageContainer">
                <div className='visibleOnHover' style={{
                    position: 'relative',
                    top: maxImageHeight / 2 - 47,
                    textAlign: 'center',
                    cursor: 'pointer',
                    lineHeight: 2,
                    background: 'white',
                    zIndex: 2
                    //visible: 'visible'
                    //border: '1px solid gray'
                    //display: 'flex', justifyContent: 'center', alignItems: 'center', //
                }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ background: 'white', padding: '5px 10px', border: '1px solid #ddd' }}>

                            <a className="primaryLink"
                                onClick={(e) => { this.setState({ displayImageLarge: true, image: image }); }}
                                title={`Enlarge structure image`}>
                                <div style={{ padding: 0 }}>
                                    Enlarge image
                                </div>
                            </a>
                            <Dialog visible={this.state.displayImageLarge}
                                style={{}}
                                closeOnEscape={true}
                                onClick={e => this.setState({ displayImageLarge: false })}
                                onHide={e => this.setState({ displayImageLarge: false })}>
                                <img
                                    style={{ height: '70vh', width: '70vw' }}
                                    src={`data:image/svg+xml;base64,${this.state.image}`}
                                    //src={`data:image/jpeg;base64,${this.state.image}`}
                                    alt={`Chemical structure`} />
                            </Dialog>

                            {showCompoundInfo && isChemCompoundOcid(ocid) ?
                                <div style={{ marginBottom: 0 }}>
                                    <a className="primaryLink"
                                        key={ocid}
                                        onClick={() => showCompoundInfo(ocid)}
                                        title={`Show compound details`}>Show details</a>
                                </div> : null}
                            {onReplaceStructure && smiles ?
                                <a className="primaryLink"
                                    onClick={(e) => {
                                        onReplaceStructure(smiles, false)
                                    }}
                                    title={`Load structure into editor`}>
                                    <div style={{ padding: 0 }}>
                                        <span>Load into editor</span>
                                    </div>
                                </a>
                                : null}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: maxImageHeight, width: maxImageWidth, margin: 'auto',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                    {image ?
                        <>
                            <img
                                className="structureImage opaqueOnHover"
                                style={{ verticalAlign: 'middle', height: height, maxHeight: maxHeight, width: width, maxWidth: maxWidth }}
                                src={`data:image/svg+xml;base64,${image}`}
                                //src={`data:image/jpeg;base64,${image}`}
                                alt={`Chemical structure`} />
                            <span className={`structureIconMoreInfo material-icons opaqueOnHover ${showMoreInfoIcon ? '' : 'displayNone'}`} style={moreInfoIconStyle}>
                                zoom_in
                            </span>
                        </>
                        :
                        <div style={{ verticalAlign: 'middle', height: height, width: width }}>
                            No image
                        </div>
                    }
                </div>
            </div>
        )
    }
}
export default CompoundImage