import { Toast } from "primereact/toast";
import React, { Component } from "react";
import { createRef } from "react";


class TableRowData extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }


    render() {
        const { label, content, colSizeLeft, colSizeRight, colAlignRight, colStyleLeft, colStyleRight } = this.props;

        let rowStyleClass = this.props.rowStyleClass ? this.props.rowStyleClass : '';

        const pColSizeLeft = colSizeLeft && colSizeRight ? 'col-' + colSizeLeft : 'col-fixed';
        const pColSizeRight = colSizeLeft && colSizeRight ? 'col-' + colSizeRight : 'col';
        const pColAlignRight = colAlignRight ? colAlignRight : 'left';

        const pColStyleLeft = colStyleLeft ? colStyleLeft : { fontWeight: 'bold' };
        const pColStyleRight = colStyleRight ? colStyleRight : { lineHeight: '1.5em', textAlign: pColAlignRight };

        return (
            <React.Fragment key={label}>
                {
                    content ?
                        <>
                            <Toast ref={this.growl} />

                            <div className={`${pColSizeLeft} ${rowStyleClass} breakWord`} style={pColStyleLeft}>
                                {label}
                            </div>
                            <div className={`${pColSizeRight} ${rowStyleClass} breakWord`} style={pColStyleRight}>
                                {content}
                            </div>
                            {pColSizeLeft === 'col-fixed' ? <div className="breakRow"></div> : null}
                        </> : null
                }
            </React.Fragment>
        );
    }
}

export default TableRowData;
