import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { TreeSelect } from "primereact/treeselect";
import { ADVANCED_SEARCH_3_FILTERS } from "../filters";
import { SEARCH_FILTER_TYPE_DATE_RANGE } from "../../../../../../../../general/docsearch/searchConstants";
import { useEffect, useState, useMemo } from "react";
import { isArrayEmpty, isObjectEmpty } from "../../../../../../../../util";
import { renderList } from "../../../../../../../helpers/advancedSearch";
import FilterTermDateRangeInput from "../../../../../../../../../common/queryBuilder/searchForm/searchGroup/editMode/groupTypes/filter/FilterTermDateRangeInput";
import FilterTermTextInput from "../../../../../../../../../common/queryBuilder/searchForm/searchGroup/editMode/groupTypes/filter/FilterTermTextInput";
import { filterDefinitionsSelector } from "../../../../../../../../../../redux/common/filters/selectors/filters";
import { addFilterData } from "../../../../../../../helpers/addFilterData";
import { expandNode } from "./FilterTermsInput.helpers";
import "./FilterTermsInput.css";

const FilterTermsInput = ({
  onAddFilterTerm,
  onAddFilterTerms,
  bulkImportValues,
  onBulkImportValuesClear,
  onBulkImportValues,
}) => {
  const filterDefinitions = useSelector(filterDefinitionsSelector);

  const [filterKey, setFilterKey] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedFilterField, setSelectedFilterField] = useState(null);
  const [filterValues, setFilterValues] = useState(null);

  const filterOptions = useMemo(
    () =>
      ADVANCED_SEARCH_3_FILTERS.map((filter) =>
        addFilterData(filter, filterDefinitions)
      ),
    [filterDefinitions]
  );

  // === NOTE: hack, because ID cannot be set if tree selection component is attached to body === //
  useEffect(() => {
    const [filterTreeSelect = null] =
      document.getElementsByClassName("p-treeselect-panel");
    if (filterTreeSelect && !filterTreeSelect.id) {
      filterTreeSelect.id = "filterTreeSelect";
    }
  });

  const expandDefaultNodes = () => {
    let expandedKeysNew = {};
    for (let node of filterOptions) {
      if (node.expand) {
        expandNode(node, expandedKeysNew, true);
      }
    }
    setExpandedKeys(expandedKeysNew);
  };

  const filterID = filterKey?.replaceAll(/.*:/g, "");
  const filter = filterID ? filterDefinitions[filterID] : null;

  const disableForm =
    !filter ||
    (isArrayEmpty(bulkImportValues) &&
      (!filterKey || isObjectEmpty(filterValues)));
  const disableAddValues =
    !filter || filter?.type === SEARCH_FILTER_TYPE_DATE_RANGE;

  return (
    <>
      <div className="flex align-items-center justify-content-between p-3">
        <div className="flex align-items-center justify-content-start w-full gap-3">
          <div>
            <TreeSelect
              id="filterTreeSelect"
              value={filterKey}
              onShow={expandDefaultNodes}
              onChange={(e) => {
                setFilterKey(e.value);
                setSelectedFilterField("");
              }}
              options={filterOptions}
              filter
              filterBy="filterField"
              filterValue={selectedFilterField}
              onFilterValueChange={(e) => setSelectedFilterField(e.value)}
              expandedKeys={expandedKeys}
              onToggle={(e) => setExpandedKeys(e.value)}
              className="filter-tree-select"
              placeholder="Select filter"
              appendTo={document.body}
            />
          </div>
          {filter?.type === SEARCH_FILTER_TYPE_DATE_RANGE ? (
            <FilterTermDateRangeInput
              values={filterValues}
              onValuesChange={(vals) => setFilterValues(vals)}
            />
          ) : !isArrayEmpty(bulkImportValues) ? (
            <>
              <span style={{ marginRight: 20 }}>
                {renderList(bulkImportValues, 3, true)}
              </span>
              <a onClick={() => onBulkImportValuesClear()}>Clear</a>
            </>
          ) : (
            <FilterTermTextInput
              values={filterValues}
              onValuesChange={(vals) => setFilterValues(vals)}
              style={{ width: "100%", marginRight: 15 }}
              placeholder={filter?.placeholder}
            />
          )}
        </div>
        <Button
          label="Add filter"
          className="p-button-sm primaryButton"
          style={{ width: 100, whiteSpace: "nowrap" }}
          onClick={() => {
            const filterID = filter.id;

            // TODO: move and clean up!!!
            if (!isArrayEmpty(bulkImportValues)) {
              const filterTermsNew = [];
              bulkImportValues.forEach((term) => {
                filterTermsNew.push({
                  filterID: filterID,
                  filter: filter,
                  val: { term: term },
                  label: term,
                });
              });
              onAddFilterTerms(filterTermsNew);
            } else {
              onAddFilterTerm({
                filterID: filterID,
                filter: filter,
                ...filterValues,
              });
              setFilterValues(null);
              setFilterKey(null);
            }
          }}
          disabled={disableForm}
        />
      </div>
      <div className="flex align-items-center mt-3 mr-0 mb-5 ml-3">
        <div style={{ width: 70 }}>or</div>
        <div>
          <Button
            className="p-button-text primaryButtonAsLink"
            onClick={onBulkImportValues}
            disabled={disableAddValues}
          >
            Add list of filter values
          </Button>
        </div>
      </div>
    </>
  );
};

export default FilterTermsInput;
