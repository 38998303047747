import React, { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";


class PublicationDateFilterInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="searchModeInfo"
                title="Click for information about different search modes"
                imageAltText="Search modes information"
                overlayContent={
                    <>
                        <h3>Search mode</h3>
                        Determines how search concepts will be searched.
                        <ul>
                            <li>Ontologically, with synonyms: finds the search term, as well as all synonyms of this concept and all synonyms of ontological subclasses</li>
                            <li>Concept only, with synonyms: finds the search term, as well as all synonyms of this concept, but no ontological subclasses</li>
                        </ul>
                    </>
                }
            />
        );
    }
}

export default PublicationDateFilterInfo;