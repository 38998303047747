/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import CellData from "../general/tabledata/CellData";


class PagingList extends Component {

  render() {
    const { conceptList, hasPrev, hasNext, data, numOfEntries, onConceptClick, allowConceptClick,
      allowCopy, cropContent, loadPrev, loadNext } = this.props;

    let maxNum = 0;
    if (data) {
      maxNum = data.offset + numOfEntries;
      maxNum = maxNum > data.count ? data.count : maxNum;
    }

    return (
      <>
        {
          conceptList && conceptList.length > 0 ?
            <>
              {allowConceptClick ?
                conceptList.map(concept => {
                  return (
                    <div className="grid small-padding" key={concept.ocid}>
                      <div className="col-12 breakWord">
                        <CellData
                          cropContent={cropContent}
                          allowCopy={allowCopy}
                          content={concept.preferredName}
                          noPaddingLeft={true}
                          onContentClick={() => onConceptClick(concept.ocid)}
                          contentTooltip={`Load concept details for ${concept.preferredName}`}
                          contentStyleClass={"secondaryLink"}
                        />
                      </div>
                    </div>
                  )
                })
                :
                <div className="breakWord">
                  <div className="paddingSmall noStyleList increasedLineHeight">
                    {
                      conceptList.map(concept => {
                        return <React.Fragment key={concept.ocid}>
                          <CellData
                            cropContent={cropContent}
                            allowCopy={allowCopy}
                            content={concept.preferredName}
                            noPaddingLeft={true}
                          />
                        </React.Fragment>
                      })
                    }
                  </div>
                </div>
              }
              {
                hasPrev || hasNext ?
                  <div className="grid p-justify-between" style={{ marginTop: 0 }}>
                    {hasPrev ?
                      <div className="col-fixed">
                        <a
                          //className="link"
                          onClick={() => loadPrev()} >
                          {"<< Previous"}
                        </a></div> : null
                    }
                    {hasPrev || hasNext ?
                      <div className="col-fixed textAlignCenter">
                        <span>{`${(data.offset + 1)}-${maxNum}/${data.count}`}</span>
                      </div> : null
                    }
                    {hasNext ?
                      <div className="col-fixed textAlignRight"><a
                        //className="link"
                        onClick={() => loadNext()} >
                        {"Next >>"}
                      </a></div> : null
                    }
                    <div className="col"></div>
                  </div> : null
              }
            </> : null
        }
      </>
    )
  }
}

export default PagingList;