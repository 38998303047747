/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component, createRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { isArrayEmpty, isObjectEmpty } from '../../util';
import { Toast } from 'primereact/toast';
import BulkImportIDCheck from './check/BulkImportIDCheck';
import { TabPanel, TabView } from 'primereact/tabview';
import BulkImportSettings from './import/BulkImportSettings';
import BulkImportInput from './import/BulkImportInput';
import BulkImportOutput from './import/BulkImportOutput';
import SeparatorPoint from '../../general/SeparatorPoint';
import { BULK_IMPORT_TYPE_INT_DOC_ID } from '../../general/docsearch/searchConstants';
import AddRemoveDocuments from '../../docCollection/AddRemoveDocuments';
import { addDocumentsToCollections } from '../../../../api/content/DocumentApi';
import { checkResultAndGetPayload } from '../../../../api';
import { exportDocumentIDs, exportMetadata, exportNonSelectedDocumentIDs } from '../helpers/export';
import ExportCenterSuccessMessage from '../../export/ExportCenterSuccessMessage';
import ButtonWithMenu from './check/ButtonWithMenu';
import { analyzeSelectedDocuments, sortSelectedDocumentsByOriginalOrder } from '../helpers/selections';
import './BulkImportDialog.css';


const SEPARATORS = [
    // regex vs expression?
    { id: 'linebreak', label: 'Line break', expression: /\r?\n|\r/, char: '\n', regex: /\r?\n|\r/g, prio: 2 },
    { id: 'comma', label: 'Comma', expression: ',', char: ',', regex: /,/g, prio: 1 },
    //{ id: 'dot', label: 'Dot', expression: '.', char: '.', regex: /\./g, prio: 1 },
    { id: 'semicolon', label: 'Semicolon', expression: ';', char: ';', regex: /;/g, prio: 1 },
    { id: 'tab', label: 'Tab', expression: '\t', char: '\t', regex: /\t/g, prio: 2 },
    { id: 'whitespace', label: 'Whitespace', expression: /\s+/, char: ' ', regex: /\s+/g, prio: 0 },
];
//const EXTRACT_FROM_SMI_FILE = { id: 'smifile', label: '[Extract SMILES from smiles file]', expression: /\r?\n|\r/, char: '\n' };

export const DOC_SELECTION_OPTIONS = {
    selectAll: 'Select all',
    deselectAll: 'Deselect all'
}

class BulkImportDialog extends Component {

    constructor() {

        super();

        this.state = {
            selectedValueType: null,
            selectedSeparator: null,

            separators: [...SEPARATORS],
            inputText: '',
            inputEntries: [],
            cnt: 0,
            truncated: false,

            activeIndex: 0
        };

        this.growl = createRef();
        this.exportMenu = createRef();
        this.importMenu = createRef();
    }


    getCurrentlySelectedValueType = () => {
        return this.state.selectedValueType?.id || this.state.autoRecValueType?.id;
    }


    onSubmit = () => {
        this.props.onSubmit(this.getCurrentlySelectedValueType(), this.state.inputEntries);
    }


    handleImportSelectedIDsByOriginalType = () => {

        if (!isObjectEmpty(this.state.selectedDocuments)) {
            let ids = [];
            const selDocsListSorted = sortSelectedDocumentsByOriginalOrder(this.state.selectedDocuments);
            selDocsListSorted.forEach(res => { ids.push(res.mdID); });
            this.props.onSubmit(this.getCurrentlySelectedValueType(), ids);
        }
        else {
            this.growl.current.show({ sticky: false, closable: true, severity: 'error', summary: 'Missing data', detail: 'No documents selected.' });
        }
    }

    handleImportSelectedIDsByInternalIDs = () => {

        if (!isObjectEmpty(this.state.selectedDocuments)) {
            let ids = [];
            const selDocsListSorted = sortSelectedDocumentsByOriginalOrder(this.state.selectedDocuments);
            selDocsListSorted.forEach((res) => { ids.push(res.documentID) });
            this.props.onSubmit(BULK_IMPORT_TYPE_INT_DOC_ID, ids);
        }
        else {
            this.growl.current.show({ sticky: false, closable: true, severity: 'error', summary: 'Missing data', detail: 'No documents selected.' });
        }
    }

    handleExportMetadata = async (selectedDocuments) => {

        let response;
        if (selectedDocuments) {
            response = await exportMetadata(selectedDocuments);
        }
        checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage />, null, true);
    }

    checkIDs = async () => {
        this.setState({ activeIndex: 1 });
    }

    determineIDType = (identifiers = []) => {

        //console.log('valueTypes', this.state.valueTypes);
        //console.log('identifieres', identifiers);

        const typeCounts = {};
        // const typeIDs = {};
        // let maxCount = 0;
        // let idCount = 0;
        let potType;

        identifiers.forEach(id => {
            //console.log('ID', id);
            // idCount++;
            this.state.valueTypes?.forEach(valTypeDef => {
                //console.log('valTypeDef', valTypeDef);
                if (valTypeDef.isOfType && valTypeDef.isOfType(id)) {
                    // console.log(`--> ${id} IS ${valTypeDef.label}`);
                    if (!typeCounts[valTypeDef.id]) {
                        typeCounts[valTypeDef.id] = 0;
                    }
                    typeCounts[valTypeDef.id]++;

                    // if (!typeIDs[valTypeDef.id])
                    //     typeIDs[valTypeDef.id] = [];
                    // typeIDs[valTypeDef.id].push(id);

                    /*if ((typeCounts[valTypeDef.id] > maxCount) ||
                        ((typeCounts[valTypeDef.id] === maxCount) && (valTypeDef.prio > potType?.prio))) {
                        maxCount = typeCounts[valTypeDef.id];
                        potType = valTypeDef;
                    }*/
                }
            });
        });

        // --- find potential type with at least one match and highest priority --- //
        this.state.valueTypes?.forEach(valTypeDef => {
            if ((typeCounts[valTypeDef.id] > 0) && (valTypeDef.prio > (potType?.prio || -1))) {
                potType = valTypeDef;
            }
        });

        // console.log('ID Count', idCount);
        // console.log('typeCounts', typeCounts);
        // console.log('potType', (potType || this.state.valueTypes[0]));
        // console.log('typeIDs', typeIDs);
        //console.log('maxCount', maxCount);
        return potType || this.state.valueTypes[0];
    }

    determineSeparator = (text) => {

        let maxCount = 0;
        let potSep;
        let lineBreaksCount = 0;
        let lineBreaksSep;

        SEPARATORS.forEach(sepDef => {
            const numOfMatches = (text.match(sepDef.regex) || []).length;
            //console.log(`${sepDef.label} -> ${numOfMatches}`);

            if (sepDef.id === 'linebreak') {
                lineBreaksCount = numOfMatches;
                lineBreaksSep = sepDef;
            }

            if ((numOfMatches > maxCount) ||
                ((numOfMatches === maxCount) && (sepDef.prio > potSep?.prio))) {
                maxCount = numOfMatches;
                potSep = sepDef;
            }
        });

        //console.log('potSep', potSep?.label);
        //console.log('maxCount', maxCount);
        //console.log('lineBreaksCount', lineBreaksCount);

        potSep = lineBreaksCount > 0 ? lineBreaksSep : potSep;

        return potSep || SEPARATORS[0];
    }


    /**
     * Parses input string and extracts entries using the given separator to split items.
     *
     * @param {string} inputText original text
     * @param {*} separator separator to split items
     * @returns list of entries
     */
    extractEntries = (inputText = '', sep, type) => {

        //console.log('inputText', inputText.length);
        //console.log('sep', sep);
        //console.log('type', type);

        this.setState({ truncated: false });

        let valueType = null;
        let separator = null;

        const entriesMap = {};
        let duplicatesCount = 0;
        const values = [];

        if (inputText) {
            // --- handle SMILES file with data and possible header --- //
            //if (separator.id === 'smifile') {
            if (this.state.isSmiFile) {
                const smiType = this.state.valueTypes.find(type => 'smiles' === type.id);
                const lines = inputText.split(/\r?\n/g);
                let count = 1;
                for (var ln of lines) {
                    const line = ln.trim();
                    if (line && !line.startsWith('#') && !line.toLowerCase().startsWith('smiles') &&
                        !line.toLowerCase().startsWith('structure')) {
                        if (this.props.maxValues && count > this.props.maxValues) {
                            this.setState({ truncated: true });
                            break;
                        }
                        const firstEntry = line.replace(/\t.*/g, '');
                        entriesMap[firstEntry] = true;
                        values.push(firstEntry);
                        count++;
                    }
                }
                this.setState({
                    autoRecSeparator: SEPARATORS[0],
                    autoRecValueType: smiType || null,
                    selectedSeparator: null,
                    selectedValueType: null,
                    //duplicatesCount: duplicatesCount,
                });
            }
            // --- everything else has a simple single separator --- //
            else {
                separator = sep ? sep : this.determineSeparator(inputText);
                const entries = inputText.split(separator.expression)?.map(entry => entry.trim());
                let count = 1;
                for (var entry of entries) {
                    //const entryTrimmed = entry.trim();
                    if (entry) {
                        if (!entriesMap[entry]) {
                            if (this.props.maxValues && count > this.props.maxValues) {
                                this.setState({ truncated: true });
                                break;
                            }
                            entriesMap[entry] = true;
                            values.push(entry);
                            count++;
                        }
                        else {
                            duplicatesCount++;
                        }
                    }
                };
                valueType = type ? type : this.determineIDType(values); // .slice(0, 10)

                this.setState({
                    autoRecSeparator: !sep ? separator : null,
                    autoRecValueType: !type ? valueType : null,
                    selectedSeparator: sep ? sep : null,
                    selectedValueType: type ? type : null,
                    duplicatesCount: duplicatesCount,
                });
            }
        }

        return values;
    }

    autoRecognizeValueType = (values) => {

        const valueType = this.determineIDType(values);

        this.setState({
            autoRecValueType: valueType,
            selectedValueType: null
        });
    }

    /**
     * Reads a file and extracts entries from the file content.
     */
    uploadHandler = ({ files }) => {
        // --- hack: increase counter to change key of file upload component --- //
        // --- otherwise file upload works only once --- //
        this.setState({
            cnt: this.state.cnt + 1
        });

        const self = this;

        // --- check file type --- //
        const [file] = files;
        let allowedFileType = false;
        // let inputFileFormat = '';
        if (!isArrayEmpty(this.props.acceptedFileTypes)) {
            for (var fileType of this.props.acceptedFileTypes) {
                if (file.name.endsWith(fileType)) {
                    allowedFileType = true;
                    // inputFileFormat = fileType;
                    break;
                }
            }
        }
        else {
            allowedFileType = true;
        }

        if (allowedFileType) {

            var reader = new FileReader();
            reader.onload = function (event) {
                // --- NOTE: event.target points to FileReader --- //
                var contents = event.target.result?.trim();
                const entries = self.extractEntries(contents, self.state.selectedSeparator, self.state.selectedValueType);
                self.setState({
                    processInputText: true,
                    inputText: contents,
                    inputEntries: entries
                });
            };

            reader.readAsText(file);
        }
        else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'warn',
                summary: 'File format not supported.',
                detail: `Please select one of the following file formats: ${this.props.acceptedFileTypes}`
            });
        }
    };


    loadValuesIntoEditor = () => {

        const separator = SEPARATORS[0];
        if (this.state.inputEntries && separator) {
            this.setState({
                processInputText: true,
                inputText: this.state.inputEntries.join(separator.char),
                autoRecSeparator: separator,
                selectedSeparator: null
            });
        }
    }


    handleDocumentSelectionChange = (selectedDocuments = {}) => {
        //console.log('selectedDocuments', selectedDocuments);
        this.setState({ selectedDocuments: selectedDocuments });
    }

    handleOpenAddToCollectionsDialog = () => {
        this.setState({ displayDialogAddToCollections: true });
    }

    handleCloseAddToCollectionsDialog = () => {
        this.setState({ displayDialogAddToCollections: false });
    }

    handleSubmitAddToCollections = async (collections) => {

        const docs = [];
        if (!isObjectEmpty(this.state.selectedDocuments)) {
            // const selDocsListSorted = sortSelectedDocumentsByOriginalOrder(this.state.selectedDocuments);
            Object.entries(this.state.selectedDocuments)?.forEach(([repoID, doc]) => {
                docs.push({
                    docId: doc.documentID,
                    repository: doc.repositoryID,
                    repositoryId: doc.repositoryID,
                    title: doc.title
                });
            });

            let ids = collections?.map(col => col.id);
            if (!isArrayEmpty(docs.length) && !isArrayEmpty(ids)) {
                const response = await addDocumentsToCollections(ids, docs);
                checkResultAndGetPayload(response, this.growl, 'Success!',
                    `${docs.length === 1 ? 'Document' : 'Documents'} successfully added to ${ids.length === 1 ? 'collection.' : 'collections.'}`);
            }
        }
        else {
            this.growl.current.show({ sticky: false, closable: true, severity: 'error', summary: 'Missing data', detail: 'No documents selected.' });
        }

        this.setState({ displayDialogAddToCollections: false });
    }


    renderBulkImport = (hasNoInput) => {

        const acceptedFileTypesString = !isArrayEmpty(this.props.acceptedFileTypes) ? this.props.acceptedFileTypes.join(',') : '';

        return <>
            <div className="grid">

                <BulkImportSettings
                    inputEntries={this.state.inputEntries}
                    entries={this.state.entries}
                    inputText={this.state.inputText}
                    isSmiFile={this.state.isSmiFile}
                    selectedSeparator={this.state.selectedSeparator}
                    autoRecSeparator={this.state.autoRecSeparator}
                    autoRecValueType={this.state.autoRecValueType}
                    selectedValueType={this.state.selectedValueType}
                    separators={this.state.separators}
                    processInputText={this.state.processInputText}
                    valueTypes={this.state.valueTypes}
                    hasSmiType={this.state.hasSmiType}
                    autoRecognizeValueType={this.autoRecognizeValueType}
                    extractEntries={this.extractEntries}
                    onChangeInputEntries={(entries) => { this.setState({ inputEntries: entries }) }}
                    onChangeSelectedSeparator={(separator) => { this.setState({ selectedSeparator: separator }) }}
                    onChangeSelectedValueType={(valueType) => { this.setState({ selectedValueType: valueType }) }}
                    onChangeSmiFile={(isSmiFile) => {
                        this.setState({ isSmiFile: isSmiFile },
                            () => {
                                const entriesUpdated = this.extractEntries(this.state.inputText);
                                this.setState({ inputEntries: entriesUpdated });
                            });
                    }}
                    cnt={this.state.cnt}
                    acceptedFileTypesString={acceptedFileTypesString}
                    uploadHandler={this.uploadHandler}
                    fileUploadInfo={this.state.fileUploadInfo}
                    maxValues={this.props.maxValues}
                />

                <div className="col-4">
                    <h4 style={{ margin: 0 }}>Input</h4>
                </div>
                <div className="col-8">
                    <div className="inline-flex-right-center width100perc">
                        <span>
                            <h4 style={{ margin: 0 }}>Found: {this.state.inputEntries ? this.state.inputEntries.length : '0'} {this.state.selectedValueType?.label || this.state.autoRecValueType?.label || 'values'}
                                {this.state.truncated || this.state.duplicatesCount ?
                                    <span className="secondaryInfo" style={{ marginLeft: 20 }}>
                                        {this.state.truncated ? 'truncated' : ''}
                                        {this.state.truncated && this.state.duplicatesCount ? <SeparatorPoint /> : null}
                                        {this.state.duplicatesCount ? `${this.state.duplicatesCount} duplicates removed` : ''}
                                    </span> : null}
                            </h4>
                        </span>

                        <span>
                            {!hasNoInput ? <div style={{ marginLeft: 20 }}>
                                <a onClick={e => this.loadValuesIntoEditor()}>Edit values in input field</a>
                            </div> : null}
                        </span>
                    </div>
                </div>
            </div>

            <div style={{
                height: 'calc(100% - 120px)', width: '100%',
                display: 'inline-flex', alignItems: 'stretch', alignContent: 'stretch'
            }}>
                <div style={{ flexGrow: 1, paddingRight: 5 }}>
                    <BulkImportInput
                        inputText={this.state.inputText}
                        onChangeInputText={(e) => {
                            const entries = this.extractEntries(e.target.value, this.state.selectedSeparator, this.state.selectedValueType);
                            this.setState({
                                processInputText: true,
                                inputText: e.target.value,
                                inputEntries: entries
                            });
                        }}
                    />
                </div>
                <div style={{ flexGrow: 1, paddingLeft: 5 }}>
                    <BulkImportOutput
                        inputEntries={this.state.inputEntries}
                        loadValuesIntoEditor={this.loadValuesIntoEditor}
                    />
                </div>
            </div>
        </>
    }

    renderFooter = (hasNoInput) => {


    }


    render() {

        const { valueTypes, initialValueType, initialValues, includeIDCheck, displayDialog, onHide } = this.props;
        //console.log('this.props: ', this.props);

        const hasNoInput = isArrayEmpty(this.state.inputEntries);
        const hasNoSelections = isObjectEmpty(this.state.selectedDocuments);
        const { documentCount } = analyzeSelectedDocuments(this.state.selectedDocuments);

        const currValueTypeID = this.getCurrentlySelectedValueType();
        const currValueTypeLabel = this.state.selectedValueType?.label || this.state.autoRecValueType?.label || 'values';

        const exportItems = [{
            label: `${currValueTypeLabel}`,
            command: () => { exportDocumentIDs(this.state.selectedDocuments, 'mdID') }
        }, {
            label: 'Internal IDs',
            command: () => { exportDocumentIDs(this.state.selectedDocuments, 'documentID') }
        }, {
            label: 'Document metadata',
            command: () => { this.handleExportMetadata(this.state.selectedDocuments) }
        }, {
            label: <span className='whiteSpaceNoWrap'>Input IDs without selections</span>,
            command: () => { exportNonSelectedDocumentIDs(this.state.inputEntries, this.state.selectedDocuments) }
        }];

        const importItems = [{
            label: `${currValueTypeLabel}`,
            command: () => { this.handleImportSelectedIDsByOriginalType() }
        }, {
            label: 'Internal IDs',
            command: () => { this.handleImportSelectedIDsByInternalIDs() }
        }];

        const footer = (
            <div>
                {this.state.activeIndex > 0 &&
                    <Button label="&laquo; Back"
                        className='p-button-secondary p-button-sm'
                        style={{ float: 'left' }}
                        onClick={e => this.setState({ activeIndex: this.state.activeIndex - 1 })}
                    />}
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={e => this.props.onHide()}
                    style={{ float: 'left' }}
                />
                {this.state.activeIndex === 0 &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {includeIDCheck &&
                            <>
                                {currValueTypeID === 'mdid' &&
                                    <span style={{ marginRight: 20 }}>* for specific value types only, e.g. Patent numbers</span>
                                }
                                <Button label={`Check for matching documents${currValueTypeID === 'mdid' ? '*' : ''}`}
                                    disabled={hasNoInput || currValueTypeID === 'mdid'}
                                    className='p-button-sm p-button-secondary'
                                    onClick={e => this.checkIDs()}
                                />
                            </>}
                        <Button label="Import into search"
                            disabled={hasNoInput}
                            className='p-button-sm primaryButton'
                            onClick={e => this.onSubmit()}
                        />
                    </div>
                }
                {this.state.activeIndex === 1 &&
                    <div className='inline-flex-center'>
                        <div style={{ marginRight: 15 }}>
                            {`Selected documents: ${documentCount}`}
                        </div>
                        <Button
                            label="Add to library"
                            disabled={hasNoSelections}
                            className='p-button-sm p-button-secondary'
                            onClick={e => this.handleOpenAddToCollectionsDialog()}
                        />
                        <div>
                            <ButtonWithMenu
                                label='Export to file'
                                disabled={hasNoSelections}
                                items={exportItems}
                                icon="pi pi-bars"
                                className="p-button-sm p-button-secondary"
                            />
                        </div>
                        <div>
                            <ButtonWithMenu
                                label='Import into search'
                                disabled={hasNoSelections}
                                items={importItems}
                                icon="pi pi-bars"
                                className="p-button-sm primaryButton"
                            />
                        </div>
                    </div>
                }
            </div>
        );


        return (
            <>
                <Toast ref={this.growl} />

                <Dialog
                    id="bulkImportDialog"
                    focusOnShow={false}
                    header={'Bulk import'}
                    footer={footer}
                    visible={displayDialog}
                    //style={{ width: '65vw', maxWidth: '90vw', minHeight: '40vh', maxHeight: '90vh' }}
                    style={{ width: '90vw', height: '90vh' }}
                    modal={true}
                    onShow={e => {
                        const initialType = valueTypes.find(type => type.id === initialValueType);
                        const separators = [...SEPARATORS];
                        const hasSmiType = valueTypes.find(type => 'smiles' === type.id);

                        this.setState({
                            selectedValueType: initialType,
                            autoRecValueType: null,
                            autoRecSeparator: null,
                            duplicatesCount: 0,
                            inputText: '',
                            separators: separators,
                            valueTypes: valueTypes,
                            inputEntries: initialValues ? initialValues : [],
                            processInputText: false,
                            isSmiFile: false,
                            hasSmiType: hasSmiType,
                            activeIndex: 0
                        });
                    }}
                    onHide={e => { onHide(e) }}
                    blockScroll
                //className='bulk-import-dialog'
                >
                    {includeIDCheck ?
                        <TabView activeIndex={this.state.activeIndex}
                            onTabChange={(e) => {
                                if (e.index < this.state.activeIndex) {
                                    this.setState({ activeIndex: e.index });
                                }
                            }}>

                            <TabPanel header="1) Import">
                                {this.renderBulkImport(hasNoInput)}
                            </TabPanel>

                            <TabPanel header="2) Check IDs">
                                <BulkImportIDCheck
                                    type={currValueTypeID}
                                    values={this.state.inputEntries}
                                    selectedDocuments={this.state.selectedDocuments}
                                    onSelectionChange={this.handleDocumentSelectionChange}
                                />
                            </TabPanel>
                        </TabView>
                        :
                        this.renderBulkImport(hasNoInput)
                    }

                </Dialog>

                <AddRemoveDocuments
                    //displayDialogRemoveDocument={this.state.displayDialogRemoveDocument}
                    displayDialogAddToCollections={this.state.displayDialogAddToCollections}
                    onSubmitAddToCollections={this.handleSubmitAddToCollections}
                    onCloseAddToCollectionsDialog={this.handleCloseAddToCollectionsDialog}
                    onCancelAddToCollections={this.handleCloseAddToCollectionsDialog}
                />
            </>
        );
    }
}

export default BulkImportDialog;