import { Component, createRef } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP_PROPERTIES } from '../../../properties/index';
import { Button } from 'primereact/button';
import { createConceptDataRequest, fetchConceptData } from "../../../api/content/ConceptApi";
import PagingList from "./PagingList";
import { checkResultAndGetPayload } from '../../../api';
import GoogleLinks from "../../common/Link/GoogleLinks";
import googleLogo from '../../../assets/images/icons/google_G.png';
import { Element } from 'react-scroll';
import { Toast } from "primereact/toast";
import { isArrayEmpty } from "../util";
import ExternalLinkIcon from "../general/ExternalLinkIcon/ExternalLinkIcon";
import { truncateList } from "../conceptdetails/util/conceptDetails";
import ExternalSources from "../conceptdetails/components/linkouts/ExternalSources";
import TruncateToggleButton from "../conceptdetails/common/TruncateToggleButton";
import Synonyms from "../conceptdetails/components/conceptData/Synonyms";
import { isChemCompoundOcid } from "../chemistry/utils/compoundClassGroup";
import Identifiers from "../conceptdetails/components/compounds/Identifiers";
import PhysicoChemicalProperties from "../conceptdetails/components/compounds/PhysicoChemicalProperties";
import CellData from "../general/tabledata/CellData";
import DomainColorBar from "../../common/DomainColorBar/DomainColorBar";

const NUM_OF_TRUNCATED_SYNONYMS = 10;
const NUM_OF_TRUNCATED_SOURCES = 10;

class OntologyClassDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ocid: null,
      childrenData: {
        list: [],
        count: 0,
        offset: 0,
        hasPrev: false,
        hasNext: false
      },
      truncateSynonyms: true,
      truncateSources: true,
    }

    this.growl = createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.ontClass?.ocid && prevProps.ontClass?.ocid !== this.props.ontClass?.ocid) {
      this.loadChildren(this.props.ontClass.ocid, 0);
    }
  }

  // ----------------------------------------------------------------------- //
  // --- paging children --------------------------------------------------- //
  // ----------------------------------------------------------------------- //
  loadPrevChildren = () => {
    this.loadChildren(this.props.ontClass?.ocid, this.state.childrenData.offset - this.props.numOfEntries);
  }

  loadNextChildren = () => {
    this.loadChildren(this.props.ontClass?.ocid, this.state.childrenData.offset + this.props.numOfEntries);
  }

  loadChildren = async (ocid, offset) => {
    // --- request children of current concept with given offset --- //
    const request = createConceptDataRequest([ocid], true, false, false, true, offset, this.props.numOfEntries, true);
    const response = await fetchConceptData(request, false, false, false, null, this.props.rangeOcids);
    const concept = checkResultAndGetPayload(response, this.growl);
    const children = concept && concept.children ? concept.children : [];

    // --- store new properties in component state --- //
    this.setState({
      childrenData: {
        list: children,
        count: concept?.childrenCount,
        offset: offset,
        hasPrev: (offset > 0),
        hasNext: concept && (concept.childrenCount > (this.props.numOfEntries + offset))
      }
    });
  }


  /**
   * 
   */
  render() {
    /*const { ontClass, onConceptSearchSubmit, onConceptSelect, numOfEntries, selectionMode,
      allowConceptSearchByClick, selectedConcepts, loading, loadingMessage, emptyMessage } = this.props;*/
    const { ontClass, onConceptSearchSubmit, onConceptSelect, numOfEntries,
      allowConceptSearchByClick, selectedConcepts, loading, loadingMessage, emptyMessage } = this.props

    const isSelected = ontClass && ontClass.ocid ? selectedConcepts && selectedConcepts[ontClass.ocid] : false;
    //const isSingleSelect = selectionMode === 'single';

    let domainColors = {};
    this.props.domainColors && this.props.domainColors.forEach(col => {
      domainColors[col.name] = col.color
    });
    const domainColorHex = domainColors[ontClass?.domain] ? `#${domainColors[ontClass.domain]}` : null;

    //console.log(domainColors)
    const sourcesToDisplay = ontClass ? this.state.truncateSources ? truncateList(ontClass.sourceReferences, NUM_OF_TRUNCATED_SOURCES) : ontClass.sourceReferences : [];
    const synonymsToDisplay = ontClass ? this.state.truncateSynonyms ? truncateList(ontClass.synonyms, NUM_OF_TRUNCATED_SYNONYMS) : ontClass.synonyms : [];
    const isChemCompound = ontClass && isChemCompoundOcid(ontClass.ocid);

    return (
      <>
        <Toast ref={this.growl} />

        {ontClass ?
          <>
            <div className="grid" style={{ overflow: 'auto', maxHeight: '100%' }}>

              {/* --- preferred name, OCID --- */}
              <div className="col" style={{ marginBottom: 0 }}>
                <CellData
                  cropContent={true}
                  allowCopy={true}
                  content={<span style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: 0, marginBottom: 5 }}>{ontClass.preferredName}</span>}
                  copyContent={ontClass.preferredName}
                  noPaddingLeft={false}
                />
              </div>
              <div className="col-fixed">
                <Button
                  style={{}}
                  className={`${isSelected ? 'p-button-secondary p-button-sm' : 'primaryButton p-button-sm'}`}
                  label={isSelected ? 'Deselect concept' : 'Select concept'}
                  onClick={(e) => onConceptSelect({ ocid: ontClass.ocid, domain: ontClass.domain, preferredName: ontClass.preferredName })}
                  title={selectedConcepts && (ontClass.ocid in selectedConcepts) ? `Remove ${ontClass.ocid} from selection` : `Add ${ontClass.ocid} to selection`}
                />
              </div>

              <div className="col-12" style={{ marginTop: 0, paddingTop: 0 }}>
                <div className="grid">
                  <DomainColorBar color={domainColorHex} size='medium' style={{ marginLeft: 5, marginRight: 6 }} />
                  <label className="_secondaryInfo" htmlFor="compoundName">
                    {this.props.domainLabelsMap && this.props.domainLabelsMap[ontClass.domain] ? this.props.domainLabelsMap[ontClass.domain] : ontClass.domain}
                  </label>
                  <span className="_secondaryInfo" style={{ marginLeft: 30 }}>{`OCID: ${ontClass.ocid}${ontClass.obsolete ? ' (obsolete concept)' : ''}`}</span>
                </div>
              </div>

              {// --- properties --- //
                ontClass.extendedData ?
                  <>
                    {!isArrayEmpty(ontClass.extendedData?.definition) ?
                      <div className="col-12">
                        <h3>Definition</h3>
                        {ontClass.extendedData.definition.map(def => {
                          return <div key={def}>{def}</div>
                        })}</div> : null
                    }
                    {isChemCompound &&
                      <>
                        <div className="col-12">
                          <h3>Identifiers</h3>
                          <Identifiers extendedConceptData={ontClass.extendedData} />
                        </div>
                        <div className="col-12">
                          <h3>Properties</h3>
                          <PhysicoChemicalProperties ocid={ontClass.ocid} />
                        </div>
                      </>
                    }
                  </> : null
              }

              {// --- structure image --- //
                ontClass.image ?
                  <div className="col-12">
                    <h3>Structure</h3>
                    <div style={{ textAlign: 'center' }}>
                      <img src={`data:image/svg+xml;base64,${ontClass.image}`}
                        alt={`Chemical structure of ${ontClass.preferredName}`}
                        style={{ width: "100%", height: 250 }} />
                    </div>
                  </div> : null
              }

              {// --- synonyms --- //
                ontClass.synonyms && ontClass.synonyms.length > 0 ?
                  <div className="col-12">
                    <Element name="synonymsElement">
                      <h3>{`Synonyms (${Object.keys(ontClass.synonyms).length})`}</h3>
                      <Synonyms
                        synonyms={synonymsToDisplay}
                        showTwoColumns={false}
                      />
                      {(ontClass.synonyms?.length > NUM_OF_TRUNCATED_SYNONYMS) &&
                        <div><TruncateToggleButton
                          truncated={this.state.truncateSynonyms}
                          handleChange={(toggle) => this.setState({ truncateSynonyms: toggle })}
                        //scrollToContainerID={CONTAINER_ID}
                        //scrollTargetElement={CONCEPT_DETAILS_NAVIGATION_SYNONYMS_ELEMENT}
                        /></div>}
                    </Element>
                  </div> : null
              }

              {// --- parents --- //
                ontClass.parents && ontClass.parents.length > 0 ?
                  <div className="col-12">
                    <h3>{`Parents (${ontClass.parents.length})`}</h3>
                    <PagingList
                      conceptList={ontClass.parents}
                      hasPrev={false}
                      hasNext={false}
                      onConceptClick={onConceptSearchSubmit}
                      allowConceptClick={allowConceptSearchByClick}
                      allowCopy={true}
                      cropContent={true}
                    />
                  </div> : null
              }

              {// --- children --- //
                this.state.childrenData && this.state.childrenData.list.length > 0 ?
                  <div className="col-12">
                    <h3>{`Children (${this.state.childrenData.count})`}</h3>
                    <PagingList
                      data={this.state.childrenData}
                      numOfEntries={numOfEntries}
                      conceptList={this.state.childrenData.list}
                      hasPrev={this.state.childrenData.hasPrev}
                      hasNext={this.state.childrenData.hasNext}
                      loadPrev={this.loadPrevChildren}
                      loadNext={this.loadNextChildren}
                      onConceptClick={onConceptSearchSubmit}
                      allowConceptClick={allowConceptSearchByClick}
                      allowCopy={true}
                      cropContent={true}
                    />
                  </div> : null
              }

              {// --- source references --- //
                ontClass.sourceReferences && Object.keys(ontClass.sourceReferences).length > 0 ?
                  <div className="col-12">
                    <h3 className="flex-vertical-align-middle">
                      {`External sources (${ontClass.sourceReferences?.length || '0'})`}
                      <ExternalLinkIcon style={{ fontSize: 15, marginLeft: 5 }} />
                    </h3>
                    <ExternalSources
                      sourceReferences={sourcesToDisplay}
                      style={{ marginLeft: 5 }}
                    />
                    {(ontClass.sourceReferences?.length > NUM_OF_TRUNCATED_SOURCES) &&
                      <div><TruncateToggleButton
                        truncated={this.state.truncateSources}
                        handleChange={(toggle) => this.setState({ truncateSources: toggle })}
                      //scrollToContainerID={CONTAINER_ID}
                      //scrollTargetElement={CONCEPT_DETAILS_NAVIGATION_LINKOUTS_ELEMENT}
                      /></div>}
                  </div> : null
              }

              {// --- Google structure search --- //
                APP_PROPERTIES.CHEMISTRY.showGoogleLinks &&
                  ontClass.extendedData && ontClass.extendedData.smiles &&
                  ontClass.extendedData.smiles.length > 0 ?
                  <div className="col-12">
                    <div className="noStyleList">
                      <h3>
                        <img alt="Google search in Patents and/or Non-Patent Literature" src={googleLogo} style={{ position: 'relative', top: '4px', height: '18px', marginRight: 10 }} />
                        <span>Google Patents+</span>
                      </h3>
                      <GoogleLinks smiles={ontClass.extendedData.smiles} />
                    </div>
                  </div> : null
              }

            </div>
          </>
          :
          <div>
            {loading ? loadingMessage : emptyMessage}
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap
})

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OntologyClassDetails))