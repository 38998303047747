import React from "react";
import { isEmpty } from "lodash";
import SearchExample from "./SearchExample";


const SearchExampleSection = ({
    exampleSection,
    onExampleClick,
}) => {
    return <>
        {exampleSection && <div className="search-example-section">
            {exampleSection.label &&
                <div className="search-example-section-label">
                    {exampleSection.label}
                </div>}
            {exampleSection.description &&
                <div className="search-example-section-description">
                    <span className="secondaryInfo">
                        {exampleSection.description}
                    </span>
                </div>}
            {!isEmpty(exampleSection.examples) &&
                <div>
                    {exampleSection.examples.map(example => {
                        return <React.Fragment key={example.label}>
                            <SearchExample
                            example={example}
                            onClick={onExampleClick}
                        />
                        </React.Fragment>
                    })}
                </div>}
        </div>}
    </>
}


export default SearchExampleSection;