const Text = {
  DefaultMethodNotImplemented: 'The method is not implemented',
  MethodNotImplementedWithParams: (methodName, className) =>
    `The method ${methodName} is not implemented in ${className}`,
};

export const getMethodIsNotImplementedMessage = (methodName, className) =>
  methodName && className
    ? Text.MethodNotImplementedWithParams(methodName, className)
    : Text.DefaultMethodNotImplemented;
