import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
//import {Message} from 'primereact/message';
import { saveAs } from 'file-saver';
import { TabView, TabPanel } from 'primereact/tabview';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { InputText } from 'primereact/inputtext';
import Job from '../jobs/Job';


import {
  annotateDocument,
  annotateResource
} from '../../../../redux/actions';

/**
 * Annotating documents
 */
class Annotator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // shared dir for available cartridges
      availableDirHost: undefined,
      // text in UI field
      pasteValue: "",
      // which tab is being shown in the results
      activeResultIndex: 0,
      // job name
      jobName: ""
    };

    this.upload = this.upload.bind(this);
    this.submitPastedContent = this.submitPastedContent.bind(this);
    this.annotateDirectory = this.annotateDirectory.bind(this);
  }

  /**
   * initialise stuff, once the component is mounted
   * 
   * will fetch repositories and download the `environment.json` to tell the user where to put documents
   */
  componentDidMount() {
    fetch("../environment.json")
      .then((r) => r.json())
      .then((data) => {
        let dir = data.availableRepositoriesHost;
        if (data.os === "windows")
          dir = dir.replace(/\//g, "\\")
        this.setState({ availableDirHost: dir });
      });
  }

  /**
   * render the component
   */
  render() {
    // should we display buttons - or is there already an annotation progress running?
    let annotatePasted = <Button label="Annotate" className="primaryButton p-button-success" onClick={() => this.submitPastedContent()} />;
    let uploading = <FileUpload name="annotate-doc[]" url="./upload" mode="basic" auto={true} customUpload={true} multiple={false} uploadHandler={this.upload} maxFileSize={200000000}></FileUpload>;
    if (this.props.annotationUploading) {
      annotatePasted = uploading = <Button label={"Processing " + this.props.annotationFilename} icon="pi-spin pi-spinner" className="p-button-info" disabled="disabled" />
    }


    // tell the user where to share available cartridges, if we know it from the `environment.json`
    let available = "";
    if (this.state.availableDirHost)
      available = <>Copy your document collections to <code>{this.state.availableDirHost}</code> and fire a new annotation job to process them.</>

    // are there results to display?
    let results = "";
    if (Object.keys(this.props.annotationResults).length > 0) {

      const r = Object.keys(this.props.annotationResults).map((result) => {
        const ari = Annotator.annotationResultInformation(result);
        let content = this.props.annotationResults[result];
        let cssClass = "annotation-result-non-empty";
        if (!content || content.length < 1) {
          content = "This result is empty";
          cssClass = "annotation-result-empty";
        }

        return <TabPanel header={ari.name} key={result}>
          <SyntaxHighlighter language="xml" style={docco} className={cssClass}>
            {content}
          </SyntaxHighlighter>
          <br />
          <Button icon="pi-md-file-download" onClick={() => this.saveDocument(result + "." + ari.extension, ari.mime, this.props.annotationResults[result])} label={"Download " + ari.name} />
        </TabPanel>
      });

      results = <>
        <h3>Annotation Results for <em>{this.props.annotationFilename}</em></h3>
        <TabView className="annotation-results" activeIndex={this.state.activeResultIndex} onTabChange={(e) => this.setState({ activeResultIndex: e.index })}>
          {r}
        </TabView>
      </>
    }


    let job = <>
      <h4>Create new Job</h4>
      <p>
        <span className="p-float-label">
          <InputText id="in" value={this.state.jobName} onChange={(e) => this.setState({ jobName: e.target.value })} />
          <label htmlFor="in">Job Name</label>
        </span>
      </p>
      <Button label="Annotate collection" className="primaryButton p-button-annotate-directory" onClick={() => this.annotateDirectory()} /><br />
      <p>
        <strong>Please be aware, that this will overwrite previous annotation results for documents stored at the same path in your collection.</strong>
      </p>
    </>

    if (this.props.jobs.length > 0) {
      job = <>
        <p>
          <strong>You need to wait for currently running jobs to finish before you can fire a new one:</strong>
        </p>
        <div className="jobsTable">
          <div className="grid">
            <div className="col-12 md:col-2 lg:col-2"><strong>Job</strong></div>
            <div className="col-12 md:col-3 lg:col-3"><strong>Description</strong></div>
            <div className="col-12 md:col-3 lg:col-3"><strong>File Path</strong></div>
            <div className="col-12 md:col-4 lg:col-4"><strong>Status</strong></div>
          </div>
          {
            this.props.jobs.map((job) =>
              <Job job={job} key={job.id} />
            )
          }
        </div>
      </>
    }

    return (
      <div id="annotator">

        <div className="grid">
          <div className="col-12 md:col-6 lg:col-6 singleDoc">
            <h2>Annotate a Single Document</h2>
            <Fieldset legend="Upload a Document">
              <p>Choose a document and annotate it immediately:</p>
              {uploading}
            </Fieldset>
            <Fieldset legend="Paste a Document">
              <p>Type some text to test what can be annotated:</p>
              <InputTextarea className="paste-doc" value={this.state.pasteValue} onChange={(e) => this.setState({ pasteValue: e.target.value })} autoResize={true} placeholder="Paste some document here to try annotation" /><br />
              {annotatePasted}
            </Fieldset>
          </div>
          <div className="col-12 md:col-6 lg:col-6 docCollection">
            <h2>Batch Annotation of Documents</h2>
            <Fieldset legend="Annotate Documents from Shared Directory">
              <p>
                Here you have the chance to annotate many documents at once.
                {available}
              </p>
              {job}
            </Fieldset>
          </div>
        </div>
        {results}
      </div>
    )
  }

  /**
   * upload some content that was pasted into the text field
   */
  submitPastedContent() {
    //console.log ("test")
    this.props.annotateDocument("text_input_" + Date.now() + ".txt", this.state.pasteValue)
  }

  /**
   * upload a document
   * 
   * @param event event that triggered this call
   * @return true
   */
  upload(event) {
    // iterating files if upload was done using advanced FileUpload
    for (var f = 0; f < event.files.length; f++) {
      //const fileName = event.files[f];
      this.props.annotateDocument(event.files[f].name, event.files[f])
    }
    return true;
  }

  annotateDirectory() {
    let jobName = this.state.jobName;
    if (jobName === "")
      jobName = "annotation job " + Date.now();
    this.props.annotateResource(jobName, ".", this.props.jobsRows, this.props.jobsPage);
    this.setState({ jobName: "" });
  }

  /**
   * offer a document to save to disk
   */
  saveDocument(filename, mime, content) {
    saveAs(new Blob([content], { type: mime }), filename);
  }

  /**
   * mapper for ontochem's annotation results
   * 
   * given a key of an annotation result, it will return
   * 
   * * name: a human readable name of that result
   * * mime: the mime time of that format, to be used for download etc
   * * extension: proposed file extension for download
   * * language: the language we'll use for syntax highlighting
   * 
   * @param {string} resultIdentifier the key of an annotation result
   * @return a dictionary of information about that format
   * 
   */
  static annotationResultInformation(resultIdentifier) {
    switch (resultIdentifier) {
      case 'ocxhtml': return {
        "name": "OC XHTML",
        "mime": "application/xhtml+xml",
        "extension": "xhtml",
        "language": "html"
      };
      case 'frames-xml': return {
        "name": "Frames XML",
        "mime": "application/xml",
        "extension": "xml",
        "language": "xml"
      };
      case 'frames-rdf': return {
        "name": "Frames RDF",
        "mime": "text/turtle",
        "extension": "ttl",
        "language": "xml"
      };
      // case 'frames-csv': return {
      //   "name": "Frames CSV",
      //   "mime": "text/comma-separated-values",
      //   "extension": "csv",
      //   "language": "text"
      // };
      case 'frames-csv': return {
        "name": "Frames TSV",
        "mime": "text/tab-separated-values",
        "extension": "tsv",
        "language": "text"
      };
      case 'frames-json': return {
        "name": "Frames JSON",
        "mime": "application/json	",
        "extension": "json",
        "language": "json"
      };
      case 'tagged-phrases': return {
        "name": "Tagged Phrases",
        "mime": "text/plain",
        "extension": "txt",
        "language": "text"
      };
      case 'standoff': return {
        "name": "Standoff",
        "mime": "application/json	",
        "extension": "json",
        "language": "json"
      };
      case 'doc2struc': return {
        "name": "Doc-2-Struc",
        "mime": "text/comma-separated-values",
        "extension": "csv",
        "language": "text"
      };
      case 'img2struc': return {
        "name": "Img-2-Struc",
        "mime": "text/comma-separated-values",
        "extension": "csv",
        "language": "text"
      };
      case 'doc2db': return {
        "name": "Doc-2-DB",
        "mime": "text/comma-separated-values",
        "extension": "csv",
        "language": "text"
      };
    }

    return {
      "name": resultIdentifier,
      "mime": "application/octet-stream",
      "extension": "unknown",
      "language": "text"
    };
  }
}


const mapStateToProps = state => {
  return {
    annotationResults: state.managerReducer.annotationResults,
    annotationFilename: state.managerReducer.annotationFilename,
    annotationUploading: state.managerReducer.annotationUploading,
    jobsPage: state.managerReducer.jobsPage,
    jobsRows: state.managerReducer.jobsRows,
    jobs: state.managerReducer.activeJobs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    annotateDocument: (name, file) => dispatch(annotateDocument(name, file)),
    annotateResource: (description, filePath, jobsCount, jobsPage) => dispatch(annotateResource(description, filePath, jobsCount, jobsPage)),
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Annotator);
