import React from 'react';
import {Tree} from "primereact/tree";
import styles from "./LeftAsideTree.module.scss";

const LeftAsideTree = React.forwardRef(({nodeTemplate, headersStructureSkeleton, leftAsideExpandedKeys, onToggle}, ref) => {
    return (
        <div ref={ref}>
            {/*div is used to force repositories bar chart has 100% width*/}
            <Tree className={styles.leftAsideTree} value={headersStructureSkeleton} nodeTemplate={nodeTemplate}
                  onToggle={onToggle}
                  expandedKeys={leftAsideExpandedKeys}/>
        </div>
    );
});

export default LeftAsideTree;
