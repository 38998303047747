import SeparatorPoint from "../../../../../../../../../general/SeparatorPoint";
import { renderList } from "../../../../../../../../helpers/advancedSearch";


const InputTermsColumn = ({ terms }) => {

    return (
        <div className="flex align-items-center h-full"
            title={terms?.join(", ")}>
            <div>
                {renderList(terms, 1, false, <SeparatorPoint />)}
            </div>
        </div>
    );
}

export default InputTermsColumn;