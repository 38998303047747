/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_theme from "@amcharts/amcharts4/themes/spiritedaway";
//import am4themes_material from "@amcharts/amcharts4/themes/material";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { APP_PROPERTIES } from '../../../properties';
import { hasUserRole } from '.././util'
import ConceptDetailsDialog from '../conceptdetails/ConceptDetailsDialog';

class CorrelationsChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedEntries: [],
            clickedEntry: null,
            isLeft: undefined,
        }
    }

    /**
     * Initialize sankey diagramm.
     */
    componentDidMount() {

        var self = this;

        this.clickedLinks = {};

        //am4core.useTheme(am4themes_animated);
        am4core.useTheme(am4themes_theme);
        //am4core.useTheme(am4themes_material);

        /**
         * Create chart and defines settings.
         */
        // --- create chart instance --- //
        this.chart = am4core.create(`chartdiv_${this.props.view.id}`, am4charts.SankeyDiagram);
        this.chart.data = [];

        this.chart.animationDuration = 0;
        this.chart.transitionDuration = 0;
        this.chart.interpolationDuration = 0;

        this.chart.defaultState.animationDuration = 0;
        this.chart.defaultState.transitionDuration = 0;
        this.chart.defaultState.interpolationDuration = 0;

        this.chart.hiddenState.animationDuration = 0;
        this.chart.hiddenState.transitionDuration = 0;
        this.chart.hiddenState.interpolationDuration = 0;

        this.chart.sortBy = "value";

        this.chart.dataFields.fromName = "fromLabel";
        this.chart.dataFields.toName = "toLabel";
        this.chart.dataFields.value = "normValue";

        // --- add title --- //
        //this.chart.titles.template.fontSize = 10;
        //this.chart.titles.create().text = "Correlations graph";

        this.chart.padding(10, 250, 70, 250);

        //this.chart.preloader.disabled = false;
        //this.chart.preloader.hiddenState.transitionDuration = 0;

        // --- export graph --- //
        this.chart.exporting.menu = new am4core.ExportMenu();
        this.chart.exporting.menu.align = "left";
        this.chart.exporting.menu.verticalAlign = "bottom";
        //this.chart.exporting.menu.items[0].label = "Download";
        this.chart.exporting.menu.items = [
            {
                "label": "Download",
                "menu": [
                    {
                        "label": "Image",
                        "menu": [
                            { "type": "png", "label": "PNG" },
                            { "type": "jpg", "label": "JPG" },
                            { "type": "svg", "label": "SVG" },
                            { "type": "pdf", "label": "PDF" }
                        ]
                    }, {
                        "label": "Print", "type": "print"
                    }
                ]
            }
        ];

        //if (APP_PROPERTIES.APP_ID === 'dimensions') {
            // --- add watermark on export --- //
            var watermark = this.chart.createChild(am4core.Label);
            watermark.text = `© ${new Date().getFullYear()} Digital Science & Research Solutions Inc. All rights reserved. Non-commercial\nredistribution / external re-use of this work is permitted subject to appropriate\nacknowledgement. This work is sourced from Dimensions® at www.dimensions.ai.`; //"Source: [bold]amcharts.com[/]";
            watermark.align = "center";
            watermark.fillOpacity = 0.7;
            watermark.valign = "bottom";
            watermark.position = 'absolute';
            watermark.bottom = 0;
            watermark.disabled = true;
            // --- add to container --- //
            this.chart.tooltipContainer.children.push(watermark); // chartContainer  tooltipContainer
            // --- enable watermark on export --- //
            this.chart.exporting.events.on("exportstarted", function (ev) {
                self.setState({
                    exportingGraph: true
                });
                watermark.disabled = false;
            });
            // --- disable watermark when export finishes --- //
            this.chart.exporting.events.on("exportfinished", function (ev) {
                watermark.disabled = true;
                self.setState({
                    exportingGraph: false
                });
            });
            // --- add watermark to validated sprites --- //
            this.chart.exporting.validateSprites.push(watermark);
        //}

        const linksTemplate = this.chart.links.template;
        const nodesTemplate = this.chart.nodes.template;

        //nodesTemplate.nameLabel.label.truncate = true;
        //nodesTemplate.nameLabel.label.wrap = false;
        //nodesTemplate.nameLabel.height = undefined;
        //nodesTemplate.nameLabel.label.hideOversized = true;
        //nodesTemplate.marginTop = 30;
        //nodesTemplate.minHeight = 20;
        //nodesTemplate.nameLabel.label.minHeight = 20;
        //linksTemplate.minWidth = 20;
        //nodesTemplate.nonScaling = true;
        //nodesTemplate.nameLabel.nonScaling = true;
        //nodesTemplate.nameLabel.label.nonScaling = true;
        //nodesTemplate.scale = 0.5;
        //nodesTemplate.nameLabel.scale = 0.5;

        // ----------------------------------------------------------------------- //
        // --- configure links --------------------------------------------------- //
        // ----------------------------------------------------------------------- //

        linksTemplate.colorMode = "gradient";
        //linksTemplate.tooltipText = "Load sentences for '{fromLabel}' together with '{toLabel}'";
        //linksTemplate.tooltipText = "Correlation count: {value}";
        linksTemplate.tooltipText = '';
        linksTemplate.clickable = true;
        linksTemplate.toggable = false;

        let hoverState = linksTemplate.states.create("hover");
        hoverState.properties.fillOpacity = 1.0;
        //hoverState.properties.colorMode = 'solid';
        //hoverState.properties.fill = am4core.color("#ff0000");

        linksTemplate.propertyFields.id = "id";
        //linksTemplate.propertyFields.fill = "linkColor";
        //linksTemplate.propertyFields.fillOpacity = "linkOpacity";
        //linksTemplate.propertyFields.colorMode = "colorMode";

        // ----------------------------------------------------------------------- //
        // --- configure nodes --------------------------------------------------- //
        // ----------------------------------------------------------------------- //

        let hoverStateNode = nodesTemplate.states.create("hover");
        hoverStateNode.properties.fillOpacity = 1.0;//0.8;

        nodesTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        nodesTemplate.tooltipText = "Click to see options for " + nodesTemplate.nameLabel.label.text;
        nodesTemplate.width = 20; // 10
        //nodesTemplate.margin(0, 0, 0, 0, 0);
        nodesTemplate.nameLabel.label.width = 240; // 250
        //nodesTemplate.nameLabel.label.text = "{value} | " + nodesTemplate.nameLabel.label.text;
        nodesTemplate.draggable = false;
        nodesTemplate.clickable = false;
        nodesTemplate.events.off("hit");

        // ----------------------------------------------------------------------- //
        // --- nodes adapters and events ----------------------------------------- //
        // ----------------------------------------------------------------------- //
        /**
         * Arranges node labels depending on whether it is left or right label.
         */
        nodesTemplate.nameLabel.adapter.add("locationX", function (location, target) {
            switch (target.parent.level) {
                case 1:
                    return 1;
                case 2:
                    return 1;
                default:
                    return -12;
            }
        });

        /**
         * Adds value to label.
         */
        nodesTemplate.nameLabel.label.adapter.add("text", function (label, target, adapterKey) {
            //console.log('arguments text: ', arguments);
            //console.log('label: ', label);
            //console.log('target: ', target);

            let newLabel = label;

            const isLeftEntity = self.isLeftEntity(target);
            if (isLeftEntity === true) {
                const count = target.dataItem.dataContext.fromValueLabel ? `${target.dataItem.dataContext.fromValueLabel} · ` : '';
                newLabel = `${count}${target.dataItem.dataContext.fromLabel}`; //  | ${selLabel}
            }
            else if (isLeftEntity === false) {
                const count = target.dataItem.dataContext.toValueLabel ? `${target.dataItem.dataContext.toValueLabel} · ` : '';
                newLabel = `${count}${target.dataItem.dataContext.toLabel}`; //  | ${selLabel}
            }

            return newLabel;
        });


        /**
         * Determines which node was clicked node and stores it in the state.
         */
        nodesTemplate.events.on("hit", function (event) {

            //console.log('***nodes event.target: ', event.target);
            //console.log('nodes clicked? ', event.target.properties.name);
            //console.log('nodes context: ', event.target.dataItem.dataContext);

            let clickedLabel = event.target.properties.name;
            let clickedPrefname = null;
            let clickedOcid = null;
            let clickedDomain = null;
            let clickedLeft = undefined;
            if (clickedLabel === event.target.dataItem.dataContext.fromLabel) {
                clickedPrefname = event.target.dataItem.dataContext.fromPrefname;
                clickedOcid = event.target.dataItem.dataContext.from;
                clickedDomain = event.target.dataItem.dataContext.fromDomain;
                clickedLeft = true;
            }
            else if (clickedLabel === event.target.dataItem.dataContext.toLabel) {
                clickedPrefname = event.target.dataItem.dataContext.toPrefname;
                clickedOcid = event.target.dataItem.dataContext.to;
                clickedDomain = event.target.dataItem.dataContext.toDomain;
                clickedLeft = false;
            }

            //console.log('event.target.dataItem.dataContext: ', event.target.dataItem.dataContext);

            self.setState({
                clickedEntry: {
                    ocid: clickedOcid, label: clickedLabel,
                    prefname: clickedPrefname, domain: clickedDomain,
                    isLeft: clickedLeft
                },
                overlayLinkX: event.target.pixelX,
                overlayLinkY: event.target.pixelY,
                visibleConceptOptions: true
            });
        });

        /**
         * Highlights all links and nodes that are connected to the hovered node.
         */
        nodesTemplate.events.on("over", function (event) {

            let hoveredLabel = event.target.properties.name;
            const hoveredNodes = {};

            self.chart.links.each(function (link) {
                // --- first, decrease opacity of all nodes --- //
                if (self.clickedLinks && self.clickedLinks[link.dataItem.dataContext.key]) {
                    //console.log('link.key: ', link.dataItem.dataContext.key);
                    //link.isHover = true;
                    link.dataItem.fromNode.opacity = 0.3;
                    link.dataItem.toNode.opacity = 0.3;
                    link.fillOpacity = 0.3;
                }
                else {
                    //link.isHover = false;
                    link.dataItem.fromNode.opacity = 0.2;
                    link.dataItem.toNode.opacity = 0.2;
                    link.fillOpacity = 0.2;
                }
                // --- collect all nodes that are somehow linked to the hovered node --- //
                if (hoveredLabel === link.dataItem.dataContext.fromLabel || hoveredLabel === link.dataItem.dataContext.toLabel) {
                    link.isHover = true;
                    link.fillOpacity = 1.0;
                    //link.opacity = 1.0;
                    hoveredNodes[`from_${link.dataItem.dataContext.from}`] = link.dataItem.fromNode;
                    hoveredNodes[`to_${link.dataItem.dataContext.to}`] = link.dataItem.toNode;
                }
            });
            // --- increase opacity of all nodes which have connections to the hovered node --- //
            Object.values(hoveredNodes).forEach(node => {
                node.opacity = 1.0;
            });
        });

        /**
         * Resets highlighting of nodes and links after mouse stops hovering node.
         */
        nodesTemplate.events.on("out", function (event) {

            let ancestorLinkIDs = self.state.ancestorLinkIDs ? self.state.ancestorLinkIDs : [];

            self.chart.links.each(function (link) {
                link.isHover = (ancestorLinkIDs.includes(link.id));
                // --- reset opacity of all nodes --- //
                link.dataItem.fromNode.opacity = 1.0;
                link.dataItem.toNode.opacity = 1.0;

                if (self.clickedLinks && self.clickedLinks[link.dataItem.dataContext.key]) {
                    link.isHover = true;
                    link.fillOpacity = 1.0;
                }
                else {
                    //link.isHover = false;
                    link.fillOpacity = 0.2;
                }
            });
        });


        // ----------------------------------------------------------------------- //
        // --- links adapters and events ----------------------------------------- //
        // ----------------------------------------------------------------------- //
        /**
         * Highlights selected links.
         */
        linksTemplate.adapter.add("fill", function (gradient, target, adapterKey) {

            const link = target;
            const key = link.dataItem && link.dataItem.dataContext ? link.dataItem.dataContext.key : '';
            if (self.clickedLinks && self.clickedLinks[key]) {
                link.fillOpacity = 1.0;
            }
            else {
                link.fillOpacity = 0.2;
            }

            return gradient;
        });

        /**
         * Loads sentences for clicked link and updates selected pair.
         */
        linksTemplate.events.on("hit", function (event) {

            let targetLink = event.target;
            const fromOcid = targetLink.dataItem.dataContext.from;
            const toOcid = targetLink.dataItem.dataContext.to;

            self.clickedLinks = {};
            self.chart.links.each(function (link) {
                //console.log('targetLink.dataItem.dataContext.key: ', targetLink.dataItem.dataContext.key);
                //console.log('link.dataItem.dataContext.key: ', link.dataItem.dataContext.key);
                if (fromOcid === link.dataItem.dataContext.from &&
                    toOcid === link.dataItem.dataContext.to) {
                    self.clickedLinks[link.dataItem.dataContext.key] = true;
                }
            });

            // --- update selected pair --- //
            self.props.onSelectPairs([targetLink.dataItem.dataContext]);
            // --- load sentences for clicked link --- //
            self.props.onLoadSentences([fromOcid], [toOcid], targetLink.dataItem.dataContext.fromLabel, targetLink.dataItem.dataContext.toLabel)
        });

        /**
         * Highlights nodes connected to hovered link as well as link itself.
         */
        linksTemplate.events.on("over", function (event) {

            //console.log(`### over: link`);

            // --- first, decrease opacity of all nodes --- //
            self.chart.links.each(function (link) {
                if (self.clickedLinks && self.clickedLinks[link.dataItem.dataContext.key]) {
                    //console.log('link.key: ', link.dataItem.dataContext.key);
                    //link.isHover = true;
                    link.dataItem.fromNode.opacity = 0.3;
                    link.dataItem.toNode.opacity = 0.3;
                    link.fillOpacity = 0.3;
                }
                else {
                    //link.isHover = false;
                    link.dataItem.fromNode.opacity = 0.2;
                    link.dataItem.toNode.opacity = 0.2;
                    link.fillOpacity = 0.2;
                }
            });

            // --- increase opacity of currently hovered link and connected nodes --- //
            let targetLink = event.target;
            targetLink.dataItem.fromNode.opacity = 1.0;
            targetLink.dataItem.toNode.opacity = 1.0;
            targetLink.fillOpacity = 1.0;
            targetLink.opacity = 1.0;
        });

        /**
         * Resets highlighting of nodes and links after mouse stops hovering link.
         */
        linksTemplate.events.on("out", function (event) {

            //console.log(`### out: link`);

            self.chart.links.each(function (link) {
                link.dataItem.fromNode.opacity = 1.0;
                link.dataItem.toNode.opacity = 1.0;
                if (self.clickedLinks && self.clickedLinks[link.dataItem.dataContext.key]) {
                    //console.log('link.key: ', link.dataItem.dataContext.key);
                    link.isHover = true;
                    link.fillOpacity = 1.0;
                }
                else {
                    link.isHover = false;
                    link.fillOpacity = 0.2;
                }
            });

            let targetLink = event.target;
            targetLink.fillOpacity = 1.0;
        });


        this.chart.data = [];
        if (this.props.correlationPairs) {
            //console.log('SET this.props.selectedPairsKeys: ', this.props.selectedPairsKeys);
            this.clickedLinks = this.props.selectedPairsKeys ? { ...this.props.selectedPairsKeys } : {};
            this.chart.data = this.props.correlationPairs;
        }
    }

    isLeftEntity = (label) => {
        //console.log('isLeftEntity: ', label);
        let isLeftEntity;
        if (label && label.currentText) {
            let currText = label.currentText.replace(/^.* · /g, '');
            //console.log('curr label: ', currText);
            if (currText === label.dataItem.dataContext.fromLabel) {
                isLeftEntity = true;
            }
            else if (currText === label.dataItem.dataContext.toLabel) {
                isLeftEntity = false;
            }
        }
        return isLeftEntity;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.correlationPairs !== prevProps.correlationPairs) {
            this.chart.data = this.props.correlationPairs;
        }
    }

    onLoadSentences = (clickedEntry) => {

        this.clickedLinks = {};
        var self = this;
        this.chart.links.each(function (link) {
            if ((clickedEntry.isLeft && clickedEntry.ocid === link.dataItem.dataContext.from)
                || (!clickedEntry.isLeft && clickedEntry.ocid === link.dataItem.dataContext.to)) {
                self.clickedLinks[link.dataItem.dataContext.key] = true;
                link.fillOpacity = 1.0;
                //link.opacity = 1.0;
            }
            else {
                link.fillOpacity = 0.2;
                //link.opacity = 0.2;
            }
        });

        //console.log('clicked links: ',this.clickedLinks);

        //console.log('this.state.clickedEntry.ocid: ', this.state.clickedEntry.ocid);

        if (this.state.clickedEntry.isLeft) {
            const queryOcidsRight = [];
            if (this.props.resultTermListRight) {
                this.props.resultTermListRight.forEach(term => {
                    if (term.ocid) {
                        queryOcidsRight.push(term.ocid);
                    }
                });
            }
            this.props.onLoadSentences([this.state.clickedEntry.ocid], queryOcidsRight, clickedEntry.label, null);
        }
        else {
            const queryOcidsLeft = [];
            if (this.props.resultTermListLeft) {
                this.props.resultTermListLeft.forEach(term => {
                    if (term.ocid) {
                        queryOcidsLeft.push(term.ocid);
                    }
                });
            }
            this.props.onLoadSentences(queryOcidsLeft, [this.state.clickedEntry.ocid], null, clickedEntry.label);
        }

        this.setState({
            visibleConceptOptions: false,
        });
    }

    onSelectConcept = () => {

        const selectedEntries = { ...this.state.selectedEntries };
        if (selectedEntries[this.state.clickedEntry.ocid]) {
            delete selectedEntries[this.state.clickedEntry.ocid];
        }
        else {
            selectedEntries[this.state.clickedEntry.ocid] = {
                label: this.state.clickedEntry.label,
                prefname: this.state.clickedEntry.prefname
            }
        }
        this.setState({
            visibleConceptOptions: false,
            selectedEntries: selectedEntries
        }, () => { this.chart.data = [...this.chart.data] });
    }

    onShowConceptDetails = () => {

        this.setState({
            visibleConceptOptions: false,
            visibleConceptInfo: true
        });
    }

    showConceptInfo = (ocid) => {

        const clickedEntry = { ocid: ocid };
        this.setState({
            visibleConceptInfo: true,
            clickedEntry: clickedEntry
        })
    }

    onRefineSearch = () => {

        //console.log('this.state.clickedEntry: ', this.state.clickedEntry);

        this.setState({
            visibleConceptOptions: false,
            //visibleConceptInfo: true
        });
        this.props.onReplaceQueryTerms([{
            ocids: [this.state.clickedEntry.ocid],
            domains: [this.state.clickedEntry.domain],
            term: this.state.clickedEntry.prefname,
            prefnames: [this.state.clickedEntry.prefname]
        }], this.state.clickedEntry.isLeft);
    }


    render() {

        //console.log('this.props: ', this.props);

        //console.log('queryTermsLeft: ', this.props.queryTermsLeft);

        /*
        const { queryTermsLeft, queryTermsRight } = this.props;
        const { clickedEntry } = this.state;
    
        console.log('queryTermsLeft: ', queryTermsLeft);
        console.log('queryTermsRight: ', queryTermsRight);
    
        let partnerQueryTermLabel = '-';
        if (clickedEntry && clickedEntry.isLeft && queryTermsRight) {
            partnerQueryTermLabel = queryTermsRight.label;
        }
        else if (queryTermsLeft) {
            partnerQueryTermLabel = queryTermsLeft.label;
        }
    
        console.log('partnerQueryTermLabel: ', partnerQueryTermLabel);
        */

        return (
            <>
                <div className={`chartdiv_${this.props.view.id}`}
                    style={{ width: '100%', height: 'calc(100% + 100px)', position: 'relative' }} />
                {this.state.selectedEntries && Object.keys(this.state.selectedEntries).length > 0 ?
                    <div className="textAlignLeft">
                        <Button className="primaryButton p-button-sm"
                            label="Exclude temporarily"
                            onClick={() => {
                                this.props.onExcludeConcepts(this.state.selectedEntries);
                                this.setState({
                                    selectedEntries: {}
                                });
                            }} >
                        </Button>
                        {hasUserRole('ROLE_BLACKLISTS') &&
                            <Button className="primaryButton p-button-sm" label="Add to blocklist"
                                onClick={() => this.props.onAddToBlacklists(this.state.selectedEntries)}></Button>
                        }
                    </div>
                    : null}

                <Dialog focusOnShow={false}
                    dismissableMask={true}
                    header={this.state.clickedEntry ? this.state.clickedEntry.label : 'Concept options'}
                    visible={this.state.visibleConceptOptions}
                    className="textAlignLeft styledDialogPadding"
                    style={{ maxWidth: '90vw', maxHeight: '90vh', overflow: 'auto' }}
                    //style={{ width: '90vw', maxWidth: '90vw', height: '90vh', maxHeight: '90vh', overflow: 'auto' }}
                    modal={true}
                    onHide={() => this.setState({ visibleConceptOptions: false })}>
                    {this.state.clickedEntry ?
                        <div>
                            {/* <h3>{this.state.clickedEntry.label}</h3>*/}
                            <p> <a onClick={(e) => this.onLoadSentences(this.state.clickedEntry)}
                                title={`Load sentences containing ${this.state.clickedEntry.label} together with ${this.state.clickedEntry.isLeft ? this.props.queryTermsLabelRight : this.props.queryTermsLabelLeft}`}>
                                {`Load sentences`}
                            </a></p>
                            <p><a onClick={(e) => this.onRefineSearch()}
                                title={`Search for correlations with ${this.state.clickedEntry.label}`}>Refine search</a></p>
                            <p><a onClick={(e) => this.onShowConceptDetails()}
                                title="Show concept details">Show concept details</a></p>
                            <p><a onClick={(e) => {
                                const ocid = this.state.clickedEntry.ocid;
                                if (!!ocid) {
                                    const exclude = {};
                                    exclude[ocid] = {
                                        label: this.state.clickedEntry.label,
                                        prefname: this.state.clickedEntry.prefname
                                    }
                                    this.setState({ visibleConceptOptions: false });
                                    this.props.onExcludeConcepts(exclude);
                                }
                            }}
                                title="Block concept temporarily">Block</a></p>
                            {hasUserRole('ROLE_BLACKLISTS') &&
                                <p><a onClick={(e) => {
                                    const ocid = this.state.clickedEntry.ocid;
                                    if (!!ocid) {
                                        const exclude = {};
                                        exclude[ocid] = {
                                            label: this.state.clickedEntry.label,
                                            prefname: this.state.clickedEntry.prefname
                                        }
                                        this.setState({ visibleConceptOptions: false });
                                        this.props.onAddToBlacklists(exclude);
                                    }
                                }}
                                    title="Add concept to blocklists">Add to blocklist</a></p>
                            }
                        </div> : 'No options available'
                    }
                </Dialog>

                <ConceptDetailsDialog
                    visible={this.state.visibleConceptInfo}
                    includeQuickSearchShortcut={true}
                    includeChemSearchShortcut={true}
                    includeCoocSearchShortcut={false}
                    ocid={this.state.clickedEntry?.ocid}
                    onConceptClick={this.showConceptInfo}
                    onHide={() => this.setState({ visibleConceptInfo: false })}
                />
            </>
        );
    }
}

export default CorrelationsChart;