import React, { useEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import './YearStatisticsChart.css';
import { useDispatch, useSelector } from "react-redux";
import { APP_PROPERTIES } from "../../../../../properties";
import { ChartBox } from "./ChartBox";
import { createXaxis } from "./chartFunctions/createXaxis";
import { createYaxis } from "./chartFunctions/createYaxis";
import { addExportFeature } from "./chartFunctions/addExportFeature";
import { changeTitle } from "./chartFunctions/changeTitle";
// import {addMessageContainer} from "./chartFunctions/addMessageContainer";
import { addWatermarkSubscription } from "./chartFunctions/addWatermarkSubscription";
import { createSeries } from "./chartFunctions/createSeries";
import { createChartData } from "./chartFunctions/createChartData";
import { findChartRatioBetweenMinAndMax } from "./chartFunctions/findChartRatioBetweenMinAndMax";
import { setChartType } from "../../../../../redux/actions/YearStatisticsActions";
import { findNonZeroMinInChartData } from "./chartFunctions/findNonZeroMinInChartData";
import { createNumberFormat } from "./chartFunctions/createNumberFormat";

export const YearStatisticsChart = ({ height }) => {
    const dataForChart = useSelector(({ yearStatistics: { chartData } }) => chartData);
    const selectedSearches = useSelector(({ yearStatistics: { selectedSearches } }) => selectedSearches);
    const chartType = useSelector(({ yearStatistics: { chartType } }) => chartType);
    const valueType = useSelector(({ yearStatistics: { valueType } }) => valueType);
    const dataIsFetching = useSelector(({ yearStatistics: { dataIsFetching } }) => dataIsFetching);
    const update = useSelector(({ yearStatistics: { update } }) => update);
    const repositories = useSelector(({ yearStatistics: { repositories } }) => repositories);
    const period = useSelector(({ yearStatistics: { period } }) => period);
    const endDate = useSelector(({ yearStatistics: { endDate } }) => endDate);

    const chart = useRef(null);
    const valueAxis = useRef(null);
    const categoryAxis = useRef(null);
    // const message = useRef(null);
    // const messageLabel = useRef(null);
    // const series = useRef(null);

    const dispatch = useDispatch();

    // const chartBackgroundColor = '#d1d1d1';
    const noSelectedSearches = selectedSearches.every(({ searchName }) => !searchName);
    const hitsCount = dataForChart[0].data[0].statistics.length;
    const reservedHeight = 300//height + 370; // 370 found experimentally

    const checkIsAllReposEmpty = () => {
        if (!repositories.length) {
            return false;
        }

        if (Array.isArray(repositories)) {
            return repositories.every(({ disabled }) => disabled);
        }

        return true;
    }

    const isAllReposEmpty = checkIsAllReposEmpty();

    useEffect(() => {
        if (!chart.current) {
            // themes
            // am4core.useTheme(am4themes_animated);

            // create chart instance
            chart.current = am4core.create("statistics-chart", am4charts.XYChart);
            chart.current.width = am4core.percent(100);
            chart.current.height = am4core.percent(90);
            chart.current.maskBullets = false;

            // create axes
            createXaxis(chart, categoryAxis);
            createYaxis(chart, valueAxis);

            // export feature
            addExportFeature(chart);

            // // create message for empty data
            // addMessageContainer(chart, message, messageLabel, chartBackgroundColor);

            //if (APP_PROPERTIES.APP_ID === 'dimensions') {
            // --- add watermark on export --- //
            addWatermarkSubscription(chart);
            //}

            return () => {
                chart.current && chart.current.dispose();
            };
        }
    }, []);

    useEffect(() => {
        // create chart title
        const searchNames = [...new Set(selectedSearches.filter(({ searchName }) => searchName)
            .map(({ searchName }) => `"${searchName}"`))];

        if (searchNames.length) {
            let searchNameList;

            if (searchNames.length > 1) {
                const searchNamesWithCommas = searchNames.slice(0, -1).join(', ');
                const lastName = searchNames[searchNames.length - 1];
                searchNameList = `${searchNamesWithCommas} and ${lastName}`;
            } else {
                searchNameList = searchNames[0];
            }

            const searchWord = searchNames.length > 1 ? 'searches' : 'search';
            const chartTitle = valueType === 'absolute' ? `Number of search hits related to ${searchWord} ${searchNameList}`
                : `Percentage of search hits related to ${searchWord} ${searchNameList} compared to all documents published in the given time frames`;

            if (chart.current) {
                changeTitle(chart, chartTitle);
            }
        }
    }, [selectedSearches, valueType]);

    useEffect(() => {
        if (chart.current) {
            // change Yaxis type
            const chartData = createChartData(dataForChart, repositories, selectedSearches, valueType);
            const minNonZeroValue = findNonZeroMinInChartData(chartData);
            const roundMinValue = minNonZeroValue?.toFixed(20).match(/^-?\d*\.?0*\d{0,1}/)[0].slice(0, -1) + '1';
            const treatZeroAs = minNonZeroValue ? roundMinValue / 10 : 0.01;
            const digitsCount = roundMinValue.toString().length - 2;
            const numberFormat = minNonZeroValue ? createNumberFormat(digitsCount) : "#,###.#";

            chart.current.yAxes.values[0].treatZeroAs = valueType === 'absolute' ? 0.1 : treatZeroAs;
            valueAxis.current.maxPrecision = valueType === 'absolute' || !minNonZeroValue ? 0 : undefined;
            valueAxis.current.numberFormatter.numberFormat = valueType === 'absolute' ? "#,###." : numberFormat;

            if (chartType === 'logarithmic') {
                chart.current.yAxes.values[0].logarithmic = true;
                valueAxis.current.min = undefined;
            } else {
                chart.current.yAxes.values[0].logarithmic = false;

                if (!minNonZeroValue) {
                    valueAxis.current.min = 0;
                } else {
                    valueAxis.current.min = undefined;
                }
            }
        }
    }, [chartType, valueType, selectedSearches, update]);

    useEffect(() => {
        switch (valueType) {
            case 'absolute':
                valueAxis.current.title.text = "Hits"
                break
            case 'relative':
                valueAxis.current.title.text = "Hits (%)"
                break
            default:
        }

    }, [update, valueType])

    useEffect(() => {
        const isSelected = !!selectedSearches.filter(({ searchName }) => searchName).length;

        if (chart.current && isSelected && Object.keys(repositories).length) {
            chart.current.series.clear();
            chart.current.data = [];

            const chartData = createChartData(dataForChart, repositories, selectedSearches, valueType);

            chartData.forEach((data) => {
                createSeries(chart, categoryAxis, data);
            })

            chart.current.series.each(function (series) {
                series.appear();
            })
        }

    }, [dataForChart, valueType, repositories, noSelectedSearches, selectedSearches, update,
        dispatch, endDate, period]
    );

    useEffect(() => {
        const chartData = createChartData(dataForChart, repositories, selectedSearches, valueType);

        const ratio = findChartRatioBetweenMinAndMax(chartData);

        const ratioLimit = 10;

        if (ratio > ratioLimit) {
            dispatch(setChartType('logarithmic'));
        } else {
            dispatch(setChartType('linear'));
        }
    }, [selectedSearches, dataForChart, repositories, valueType])

    // useEffect(() => {
    //     if (message.current && !dataIsFetching) {
    //         chart.current.data = [];
    //         message.current.disabled = !noSelectedSearches;
    //         message.current.disabled = !isAllReposEmpty;
    //     }
    // }, [isAllReposEmpty, noSelectedSearches, dataIsFetching]);

    useEffect(() => {
        // There's no way to set empty space left and right from chart using pixels. https://github.com/amcharts/amcharts4/issues/3954
        // but we can control relative space. my logic is based on chart hits amounts. values in hitsCountAndPaddingMap are
        // number of empty sections before and after chart area.

        // Sometimes last tooltips don't appear. This behaviour connected with categoryAxis.current.endLocation property.
        // So I've found optimal values for this property. It is values in hitsCountAndPaddingMap

        const hitsCountAndPaddingMap = {
            20: 0.5,
            50: 2,
            100: 4,
            150: 6,
            300: 10,
            500: 20
        }

        const counts = Object.keys(hitsCountAndPaddingMap);
        const closest = counts.reduce(function (prev, curr) {
            return (Math.abs(curr - hitsCount) < Math.abs(prev - hitsCount) ? curr : prev);
        });

        const padding = hitsCountAndPaddingMap[closest];

        categoryAxis.current.startLocation = -padding;
        categoryAxis.current.endLocation = padding + 1;

        // dispatch(setUpdate({}));
    }, [hitsCount, update])

    return (
        <div style={{ height: `calc(100vh - ${reservedHeight}px)`, marginBottom: 30 }}>
            <ChartBox id="statistics-chart" dataIsFetching={dataIsFetching} isAllReposEmpty={isAllReposEmpty}
                style={{ width: '100%', height: '100%', position: 'relative' }} />
        </div>
    );
}
    ;
