import "./FilterGroupEdit.css";
import { Fragment, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { isArrayEmpty } from "../../../../../../../util";
import { useDispatch, useSelector } from "react-redux";
import {
  addIDsToQueryTerms,
  generateQueryTermID,
} from "../../../../../../helpers/advancedSearch";
import { toggleFilterGroupDialog } from "../../../../../../../../../redux/features/queryBuilder/actions";
import FilterTermsTable from "./FilterTermsTable/FilterTermsTable";
import FilterTermsInput from "./FilterTermsInput/FilterTermsInput";
import BulkImportTermsDialog from "../term/BulkImportTermsDialog";
import { LIST_CUTOFF_FILTER_TERMS } from "../../../../../../helpers/constants";
import TruncateEntriesLink from "../../../../../../../../common/queryBuilder/searchForm/searchGroup/common/TruncateEntriesLink";

const FilterGroupEdit = ({ filterTerms, onValuesChange }) => {
  const toggleDialog = useSelector(
    (state) => state.advancedSearch3Reducer.toggleFilterGroupDialog
  );
  const dispatch = useDispatch();

  const [displayBulkImportValuesDialog, setDisplayBulkImportValuesDialog] =
    useState();

  const [bulkImportValues, setBulkImportValues] = useState();
  const [filterTermsUpdated, setFilterTermsUpdated] = useState([]);

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const filterTermsNew = addIDsToQueryTerms(filterTerms);
    setFilterTermsUpdated(filterTermsNew || []);
  }, [filterTerms]);

  const handleFilterTermChange = (filterTermOld, filterTermNew) => {
    filterTermNew.id = generateQueryTermID();
    const idx = filterTermsUpdated.indexOf(filterTermOld);
    const filterTermsUpdatedNew = [...filterTermsUpdated];
    filterTermsUpdatedNew[idx] = filterTermNew;
    setFilterTermsUpdated(filterTermsUpdatedNew);
  };

  const handleAddFilterTerm = (filterTermNew) => {
    filterTermNew.id = generateQueryTermID();
    const queryTermsUpdatedNew = [...filterTermsUpdated, filterTermNew];
    setFilterTermsUpdated(queryTermsUpdatedNew);
  };

  const handleAddFilterTerms = (filterTermsNew) => {
    filterTermsNew = addIDsToQueryTerms(filterTermsNew);
    const queryTermsUpdatedNew = [...filterTermsUpdated, ...filterTermsNew];
    setFilterTermsUpdated(queryTermsUpdatedNew);
  };

  const handleBulkImportValues = () => {
    setDisplayBulkImportValuesDialog(true);
  };

  const handleSubmitBulkImportValues = (bulkImportValuesNew) => {
    setBulkImportValues(bulkImportValuesNew);
  };

  const handleDelete = (filterTerm) => {
    const idx = filterTermsUpdated.indexOf(filterTerm);
    let filterTermsUpdatedNew = [...filterTermsUpdated];
    filterTermsUpdatedNew.splice(idx, 1);
    setFilterTermsUpdated(filterTermsUpdatedNew);
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          className="p-button-sm p-button-secondary"
          onClick={() => {
            dispatch(toggleFilterGroupDialog(false));
            setFilterTermsUpdated(filterTerms);
          }}
        />
        <Button
          label="OK"
          className="p-button-sm primaryButton"
          onClick={() => {
            dispatch(toggleFilterGroupDialog(false));
            const queryTermsUpdatedNew = [...filterTermsUpdated];
            onValuesChange(queryTermsUpdatedNew);
          }}
        />
      </>
    );
  };

  const groupFilterTerms = filterTerms ?? [];
  const filterTermsTruncated = showAll
    ? groupFilterTerms
    : [...groupFilterTerms].slice(0, LIST_CUTOFF_FILTER_TERMS);

  return (
    <>
      <div className="grid">
        <div className="col-12">
          <div className="inline-flex align-items-center flex-wrap gap-2">
            {filterTermsTruncated?.map((queryTerm) => {
              return (
                <Fragment key={queryTerm.filterID + queryTerm.label}>
                  <div className="filter-label">
                    <div>
                      <b>{queryTerm.label}</b>
                    </div>
                    <div>{queryTerm.filter?.label || queryTerm.filterID}</div>
                  </div>
                </Fragment>
              );
            })}
            <TruncateEntriesLink
              visible={LIST_CUTOFF_FILTER_TERMS < groupFilterTerms.length}
              numberOfEntries={groupFilterTerms.length}
              showAll={showAll}
              onClick={setShowAll}
            />
            <div>
              {!isArrayEmpty(filterTermsTruncated) ? (
                <span style={{ marginLeft: 15 }}>
                  <a onClick={() => dispatch(toggleFilterGroupDialog(true))}>
                    Edit filters
                  </a>
                </span>
              ) : (
                <a onClick={() => dispatch(toggleFilterGroupDialog(true))}>
                  Add filters
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        id="filterTermsTableDialog"
        visible={toggleDialog}
        onHide={() => dispatch(toggleFilterGroupDialog(false))}
        //onShow={() => { setQueryTermsAutocomplete([]) }}
        blockScroll
        style={{ minWidth: "70vw", maxWidth: "90vw", maxHeight: "90vh" }}
        header="Metadata filters"
        footer={renderFooter}
        appendTo="self"
        className="styled-dialog"
      >
        <div>
          <FilterTermsInput
            onAddFilterTerm={handleAddFilterTerm}
            onAddFilterTerms={handleAddFilterTerms}
            bulkImportValues={bulkImportValues}
            onBulkImportValuesClear={() => handleSubmitBulkImportValues([])}
            onBulkImportValues={handleBulkImportValues}
          />
          <hr />
        </div>
        {!isArrayEmpty(filterTermsUpdated) ? (
          <div className="overflow-auto mb-3">
            <FilterTermsTable
              queryFilters={filterTermsUpdated}
              onFilterChange={handleFilterTermChange}
              onDelete={handleDelete}
            />
          </div>
        ) : (
          "No metadata filters added, yet."
        )}
      </Dialog>
      <BulkImportTermsDialog
        visible={displayBulkImportValuesDialog}
        onSubmit={handleSubmitBulkImportValues}
        onHide={() => setDisplayBulkImportValuesDialog(false)}
      />
    </>
  );
};

export default FilterGroupEdit;
