import React, { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";


class ConceptDistanceFilterInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="conceptDistanceInfo"
                title="Click for information about concept distance"
                imageAltText="Concept distance information"
                overlayContent={
                    <>
                        <h3>Concept distance</h3>
                        There must be at least two search concepts (no text - exact or with variants) in the input field.
                        It is used either to influence ranking according to the proximity of search concepts or
                        to filter documents by the search concept distance.
                        <h5>Rank documents</h5>
                        Documents are ranked, but not filtered.
                        <ul>
                            <li>Off (no influence): no influence on ranking</li>
                            <li>Influence on ranking: closer search concepts push document</li>
                        </ul>
                        <h5>Filter documents</h5>
                        Only documents with search concepts within specified distance are returned.
                        <ul>
                            <li>Max. ~7000 characters: maximum distance of ~7000 characters</li>
                            <li>...</li>
                            <li>Max. ~2 characters: maximum distance of 2 characters, e.g. , . ; : or whitespace</li>
                        </ul>
                    </>
                }
            />
        );
    }
}

export default ConceptDistanceFilterInfo;