import React, {useRef} from 'react';
import {OverlayPanel} from "primereact/overlaypanel";
import TermMenu from "../../../../../TermMenu/TermMenu";
import styles from './BiomarkerLabelTerm.module.scss';
import './BiomarkerLabelTerm.css';

const BiomarkerLabelTerm = ({term, ocids, normalizedTerms, originalTerms, setBiomarkerQueryTerms, showToast, fieldName, queryTerms}) => {
    const overlayPanel = useRef(null);
    const showOverlayPanel = (event) => {
        overlayPanel.current.show(event);
    }
    const hideOverlayPanel = (event) => {
        overlayPanel.current.hide(event);
    }

    return (
        <>
            <OverlayPanel ref={overlayPanel} id='search-option-overlaypanel'>
                <TermMenu ocids={ocids} normalizedTerms={normalizedTerms} originalTerms={originalTerms}
                          setBiomarkerQueryTerms={setBiomarkerQueryTerms}
                          hideOverlayPanel={hideOverlayPanel}
                          showToast={showToast}
                          fieldName={fieldName}
                          queryTerms={queryTerms}
                />
            </OverlayPanel>
            <p className={styles.term} onClick={showOverlayPanel}>{term}</p>
        </>
    );
};

export default BiomarkerLabelTerm;





