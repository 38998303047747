// --- Dimensions LC YouTube videos --- //

export const topics = [
    { label: "All topics", value: "all", numberVideos: 15 },
    { label: "My Dashboard", value: "dashboard", numberVideos: 2 },
    { label: "Quick Search", value: "quick", numberVideos: 7 },
    { label: "Advanced Search", value: "advanced", numberVideos: 4 },
    { label: "Sequence Search", value: "sequence", numberVideos: 4 },
    { label: "Sequence Exporter", value: "sequenceExp", numberVideos: 2 },
    //{ label: "Chemistry Search", value: "chemsearch", numberVideos: 4 },
    { label: "Domain Explorer", value: "domains", numberVideos: 4 },
    { label: "Co-occurence Analysis", value: "coocs", numberVideos: 5 },
    { label: "Semantic Exporter", value: "semanticExp", numberVideos: 2 },
    { label: "My Library", value: "library", numberVideos: 4 },
    { label: "My Saved Searches", value: "searches", numberVideos: 4 },
    { label: "My Alerts", value: "alerts", numberVideos: 3 }
]

// --- topics map with value as key --- //
export const topicsMap = {};
topics.forEach(topic => {
    topicsMap[topic.value] = topic;
});

const videoDefinitions = [
    { url: "https://www.youtube.com/embed/3VMxvcnGSNY", title: "Getting started with Dimensions L&C", section: ["all", "quick", "domains"], time: "(6:16 min)" },
    { url: "https://www.youtube.com/embed/6KgQul5DCJ8", title: "How to understand drug landscape around your target", section: ["all", "coocs"], time: "(4:57 min)" },
    { url: "https://www.youtube.com/embed/BUeOdmlOB6Y", title: "Hot to understand drug candidate mechanisms of action with Dimensions L&C", section: ["all", "chemsearch"], time: "(4:40 min)" },
    { url: "https://www.youtube.com/embed/ruwdJTEgarM", title: "How to understand disease mechanisms with Dimensions L&C", section: ["all", "quick", "coocs"], time: "(6:23 min)" },
    { url: "https://www.youtube.com/embed/ht5vHWytspY", title: "Identify potential drug targets for a disease", section: ["all", "quick", "coocs"], time: "(7:10 min)" },
    { url: "https://www.youtube.com/embed/vz8kBfwSh30", title: "How to run a sequence search", section: ["all", "sequence", "sequenceExp"], time: "(5:41 min)" },
    { url: "https://www.youtube.com/embed/MtkassDxc00", title: "How to run a chemical structure search", section: ["all", "chemsearch"], time: "(5:26 min)" },
    { url: "https://www.youtube.com/embed/GLjPecXa1sY", title: "How to run a chemical reaction search", section: ["all", "chemsearch"], time: "(2:47 min)" },
    { url: "https://www.youtube.com/embed/1wM8zdxUBi8", title: "Get very specific results using Advanced Search", section: ["all", "advanced", "sequence", "domains", "library", "searches", "alerts"], time: "(5:25 min)" },
    { url: "https://www.youtube.com/embed/lNWrmKvjZuY", title: "Get the most out of Quick Search", section: ["all", "quick", "sequence", "domains", "library", "searches", "alerts"], time: "(7:35 min)" },
    { url: "https://www.youtube.com/embed/GzKCJD39ao0", title: "Generate insights quicker using Co-occurrence Analysis", section: ["all", "coocs", "domains"], time: "(8:11 min)" },
    { url: "https://www.youtube.com/embed/V5xMwyUG2xg", title: "Drug repositioning for a novel indication", section: ["all", "coocs"], time: "(7:47 min)" },
    { url: "https://www.youtube.com/embed/RXqscYdigP8", title: "Create libraries, save searches and set alerts", section: ["all", "dashboard", "library", "searches", "alerts", "quick", "advanced", "semanticExp"], time: "(5:53 min)" },
    { url: "https://www.youtube.com/embed/QgS6V84_WJM", title: "Automate your searches and track what's new", section: ["all", "dashboard", "searches", "quick", "advanced"], time: "(3:49 min)" },
    { url: "https://www.youtube.com/embed/qK1s4IsE1mA", title: "Analyze semantic concepts in your document collections", section: ["all", "semanticExp", "quick", "advanced", "sequence", "chemsearch", "sequenceExp", "library"], time: "(3:20 min)" },
]

// --- remove sections which are not defined in topics --- //
videoDefinitions.forEach(video => {
    video.section = video.section.filter(section => {
        return (topicsMap[section]);
    });
});

export const videos = videoDefinitions;