/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, PureComponent } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getHeaderToken } from '../../../api/index'
import axios from 'axios'
import { saveAs } from 'file-saver';
import { APP_PROPERTIES, REPOSITORY_INFO_NOT_AVAILABLE, RESPONSE_STATUS_SUCCESS } from '../../../properties/index';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import AnnotatedText from './AnnotatedText';
import PDFDocument from "./pdfview/PDFDocument";
import { checkResultAndGetPayload } from '../../../api';
import { sleep, isArrayEmpty, hasUserRole, createDocViewUrl, getMonthNameFromDate, getYearFromDate, } from '../util';
import { exportCompoundsFromDocument, exportNucleotideSequencesFromDocument, exportProteinSequencesFromDocument, createSemanticSearchRequest, fetchAnnotatedDocument, fetchAnnotatedReadCubeDocument } from '../../../api/content/DocumentApi'
import { createConceptDataRequest, fetchConceptsData } from '../../../api/content/ConceptApi';
import _ from 'lodash';
import $ from 'jquery';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { Rating } from "primereact/rating";
import { Dialog } from "primereact/dialog";
import StructuredDocumentData from "./sidebar/StructuredDocumentData";
import AddRemoveDocumentsDropdown from "../docCollection/AddRemoveDocumentsDropdown";
import { Toast } from 'primereact/toast';
import DocumentLinkoutButton from "./sidebar/DocumentLinkoutButton";
import HighlightButtons from "./sidebar/HighlightButtons";
import MatchesButtons from "./sidebar/MatchesButtons";
import ToggleSections from "./sidebar/ToggleSections";
import Batches from "./sidebar/Batches";
import { createConceptTreeData, createOcidToAnnIdMap, extractAnnotatedCompounds, filterSentences } from "./util/annotations";
import { replaceImagePlaceholders, updateHtml } from "./util/htmlManipulation";
import { addActiveHighlighting, changeActiveHighlighting, determineActiveHighlighting, determineActiveHighlightOptions, determineNextActiveAnnId } from "./util/documentView";
import { openAllSections, scrollToElementViaScrollToButton } from "./util/htmlHandling";
import {
    setLoaderSentenceAnalysis,
    setSearchLoadedSentenceAnalysis
} from '../../../redux/actions/SentenceAnalysisActions';
import References from "./htmlview/References";
import SequenceDetailsDialog from "./sidebar/SequenceDetailsDialog";
import { extractSequenceData } from "./util/sequences";
import "./DocumentView.css";
import "./sidebar/Sidebar.css";
import "./doc.v3.css";
import "./docText.v2.css";
import "./oc_spl.css";
import OntologyBrowserDialog from "../ontbrowser/OntologyBrowserDialog";
import { replaceQueryTermWithConcepts } from "../general/docsearch/searchUtil";
import BILinkResolver, { hasBILinkResolver } from "../general/DocumentResult/BILinkResolver";
import ConceptDetailsDialog from "../conceptdetails/ConceptDetailsDialog";
import CopyToClipboard from "react-copy-to-clipboard";
import ReadCubePapersLink from "../../common/ReadCube/ReadCubePapersLink";
import { getReadcubePapersIdentifier } from "../../common/ReadCube/readCubePapersUtil";
import { fetchAdditionalDocumentData, updateDocumentRating, updateDocumentReadStatus, addDocumentToCollections, removeDocumentFromCollection } from "../../../redux/actions/DocumentActions";
import DocumentBookmark from "../general/DocumentResult/DocumentBookmark";
import { createDOILink } from "../util/externalLinks";

const ANN_TYPE_CHEM_STRUCTURE = 'chemCompound';
const IMAGE_MAX_COUNT = 500;


class AnnotatedDocumentView extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            // --- annotated text view --- //
            docHtml: '',
            docPartsHtml: {},
            annotations: null,
            conceptsData: null,
            // --- all annotation types found in text incl. OCIDs (annType => [ocids]) --- //
            completeAnnTypesMap: [],
            activeSearchTerms: props.defaultSearchTerms || [],
            highlights: [],
            // --- highlighting --- //
            activeAnnotations: ['queryTerm'],
            highlightVals: ['qry'],
            // --- extra data view --- //
            annTextDetailsIndex: props.openDetailsTab ? parseInt(props.openDetailsTab) : 0,
            // --- concept tree data structure --- //
            conceptTreeData: null,
            // --- document meta data --- //
            docMetadata: {},
            // --- chemical structures --- //
            compounds: null,
            // --- compound annotation icons loaded --- //
            compoundIconsLoaded: false,
            displayNucleotideSequenceDetails: false,
            displayProteinSequenceDetails: false,
            minSequences: 0,
            maxSequences: 20,
            requestLoader: false,
            doneLoadingDocumentData: false,
            activeSearchTermsCount: {},
            domainBrowserVisible: false,
            domainExplorerQuery: null,
            domainExplorerOcids: null,
            queryTermToReplace: null,
            isSemanticOn: true,
            isAnnotationOn: false,
            isMatchesSelected: false,
            hiddenExactTextMatches: {}
        }
    }

    componentDidMount() {
        this.updateData(this.props);
        // this.updateAnnText(this.props);

        if (this.props.repID && this.props.ocDocID) {
            //console.log('FETCH ADDITIONAL DOC DATA 0', this.props.additionalDocumentData);
            this.props.fetchAdditionalDocumentData(this.props.repID, this.props.ocDocID);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("componentWillReceiveProps AnnotatedDocumentView");

        if (this.props.intQuery !== nextProps.intQuery ||
            this.props.docMetadata !== nextProps.docMetadata ||
            this.props.images !== nextProps.images) {
            this.updateData(nextProps);
        }

        if (this.props.annotationRanges !== nextProps.annotationRanges ||
            this.props.conceptDataMap !== nextProps.conceptDataMap ||
            this.props.origPartsHtml !== nextProps.origPartsHtml ||
            // TODO: remove:
            this.props.origHtml !== nextProps.origHtml) {
            this.setState({
                docHtml: '',
                docPartsHtml: {},
                annotations: null,
                conceptsData: null,
                // --- all annotation types found in text incl. OCIDs (annType => [ocids]) --- //
                completeAnnTypesMap: [],
                highlights: [],
                // --- highlighting --- //
                activeAnnotations: nextProps.isSemanticSearch && nextProps.defaultSearchTerms?.length > 0 ? [] : ['queryTerm'],
                highlightVals: nextProps.isSemanticSearch && nextProps.defaultSearchTerms?.length > 0 ? [] : ['qry'],
                // --- extra data view --- //
                activeSearchTerms: nextProps.defaultSearchTerms || [],
                annTextDetailsIndex: nextProps.defaultSearchTerms?.length > 0 ? 0 : (this.props.openDetailsTab ? parseInt(this.props.openDetailsTab) : null),
                // --- concept tree data structure --- //
                conceptTreeData: null,
                // --- chemical structures --- //
                compounds: null,

                // --- compound annotation icons loaded --- //
                compoundIconsLoaded: false,
                displayNucleotideSequenceDetails: false,
                displayProteinSequenceDetails: false,
                minSequences: 0,
                maxSequences: 20,
                requestLoader: false,
                activeSearchTermsCount: nextProps.defaultActiveSearchTermsCount,
                domainBrowserVisible: false,
                domainExplorerQuery: null,
                domainExplorerOcids: null,
                queryTermToReplace: null,
                isSemanticOn: nextProps.defaultSearchTerms?.length > 0 ? true : false,
                isAnnotationOn: false,
                isQAModeOn: true,
                isMatchesSelected: !nextProps.isSemanticSearch,
                qaModeTickets: [],
                doneLoadingDocumentData: false,
                hiddenExactTextMatches: {}

            })
            setTimeout(() => {
                this.updateAnnText(nextProps);
            }, 100)
        }
    }


    componentDidUpdate(prevProps, prevState) {

        if (this.props.repID && this.props.ocDocID &&
            (prevProps.repID !== this.props.repID || prevProps.ocDocID !== this.props.ocDocID)) {
            //console.log('FETCH ADDITIONAL DOC DATA 1', this.props.additionalDocumentData);
            this.props.fetchAdditionalDocumentData(this.props.repID, this.props.ocDocID);
        }
        else if (prevProps.additionalDocumentData !== this.props.additionalDocumentData &&
            this.props.additionalDocumentData?.repositoryId == this.props.repID &&
            this.props.additionalDocumentData?.docId === this.props.ocDocID) {
            //console.log('UPDATE ADDITIONAL DOC DATA 2', this.props.additionalDocumentData);

            this.setState({ addDocData: this.props.additionalDocumentData || {} });
        }

        // --- highlight last active named entity (if it exists) --- //
        if (this.state.lastActiveAnnId) {
            $('#annId' + this.state.lastActiveAnnId).addClass('highlightNE');
        }
    }


    embedBatches = () => {

        if (!!window._altmetric_embed_init && !!window.__dimensions_embed && !!window.__dimensions_embed.addBadges) {
            window._altmetric_embed_init();
            window.__dimensions_embed.addBadges();
        }
    }


    updateData = (props) => {
        // --- if there is no query or just the *-query -> remove option to highlight query terms --- //
        const highlightOptions = determineActiveHighlightOptions(props.intQuery && props.intQuery !== '+all:"*"', false);
        this.setState({
            query: props.intQuery,
            docMetadata: props.docMetadata,
            images: props.images,
            highlightOptions: this.props.isSemanticSearch ? [] : highlightOptions,
        });
    }

    getPreferrredData = (unstructuredData, structuredData) => {
        let data = null;
        let isStructuredData = false;
        if (!isArrayEmpty(structuredData)) {
            isStructuredData = true;
            data = structuredData.map(dt => { return JSON.parse(dt) });
        }
        else if (!isArrayEmpty(unstructuredData)) {
            data = unstructuredData;
        }
        return { data, isStructuredData };
    }


    updateAnnText = async (props) => {
        //console.log(props.annotationRanges)
        //console.log(props.annotationRanges)
        //console.log(props.conceptDataMap)
        //console.log(this.props.annTypeLabelsMap)

        this.setState({ processingDocument: true }, () => this.props.onLoading(true));

        await sleep(0);

        const conceptTreeDataComplete = createConceptTreeData(props.annotationRanges, props.conceptDataMap, this.props.annTypeLabelsMap);
        const ocidToAnnIdsMap = createOcidToAnnIdMap(props.annotationRanges, props.conceptDataMap);

        let enrichedPartsHtml = { ...props.origPartsHtml };
        if (!enrichedPartsHtml.createFromDocParts) {
            enrichedPartsHtml.enrichedDocument = this.updateHtml(props.origPartsHtml.document, props);
        } else if ((enrichedPartsHtml.createFromDocParts //&& !this.props.abstractOnly
        ) && props.renderDocumentOnly) {
            let sortedRanges = []
            for (let part in props.origPartsHtml.namedDocumentRanges) {
                sortedRanges.push([part, props.origPartsHtml.namedDocumentRanges[part]])
            }
            sortedRanges.sort((a, b) => a[1][0].startOffset - b[1][0].startOffset)
            let objSorted = {}
            sortedRanges.forEach(item => {
                objSorted[item[0]] = item[1]
            })
            let partsString = ""
            Object.values(objSorted).forEach((parts, i) => {
                parts.forEach((part, j) => {
                    partsString += part.rangeDocument
                    if (i === 0 && j === parts.length - 1 && props?.repositoryInfo?.name === 'dsgrants') {
                        const metaData = props.docMetadata ? props.docMetadata : {};
                        let preferredDataForGrantees = this.getPreferrredData(metaData?.grantees, metaData['grantees.json']);
                        let isStructuredGranteesData = preferredDataForGrantees.isStructuredData;
                        const grantees = preferredDataForGrantees.data;

                        let preferredDataForResearchOrgs = this.getPreferrredData(metaData.research_orgs, metaData['research_orgs.json']);
                        const researchOrgs = preferredDataForResearchOrgs.data;
                        let isStructuredResearchOrgsData = preferredDataForResearchOrgs.isStructuredData

                        if (!isArrayEmpty(metaData?.funder)) {
                            partsString += `<div className="primaryInfo" style='padding-top: 10px'>
                                Funder: ${metaData?.funder}
                                ${!isArrayEmpty(metaData?.funder_country) ? `, ${metaData?.funder_country[0]}` : ''}
                            </div>`
                        }
                        if (!isArrayEmpty(metaData?.project_num)) {
                            partsString += `<div className="secondaryInfo" style='padding-top: 10px'>
                                <span style='color:#818181;'>Grant number: </span>
                                ${metaData.url ?
                                    `<a href=${metaData.url} target="_blank" rel="noreferrer">${metaData.project_num}
                                    <span style='font-family:Material Icons; top: 2px; position: relative; margin-left: 2px; font-size: 13px;' className='material-icons ext-link-standard'>launch</span>
                                    </a>`
                                    :
                                    `<span style='color:#818181;'>${metaData.project_num}</span>`
                                }
                            </div>`
                        }
                        if (!isArrayEmpty(grantees)) {
                            partsString += `<div>
                            <h2 style='margin: 1.5rem 0rem 0.5rem; font-size: 15px; font-weight: 500;' className="ocTextHeading_2">Investigators</h2>
                                ${grantees.map(grantee => {
                                if (isStructuredGranteesData) {
                                    return (
                                        `<React.Fragment key={${grantee.first_name}_${grantee.last_name}}>
                                            <div>
                                                    ${grantee.first_name} ${grantee.last_name}
                                                     ${!isArrayEmpty(grantee.affiliations) && grantee.affiliations[0].affiliation ? `
                                                          - ${grantee.affiliations[0].affiliation} ` : ''}
                                                    <span className="secondaryInfo"
                                                        style='color:#818181;'>${grantee.grantee_role ? `- ${grantee.grantee_role}` : ''}</span>
                                                </div>
                                            </React.Fragment>`
                                    )
                                }
                                else {
                                    return `<li key={${grantee}}>${grantee}</li>`;
                                }
                            }).join('')
                                }
                            </div> `
                        }
                        if (!isArrayEmpty(researchOrgs)) {
                            partsString += `<div>
                            <h2 style='margin: 1.5rem 0rem 0.5rem; font-size: 15px; font-weight: 500; ' className="ocTextHeading_2">Research organization</h2>
                                ${researchOrgs.map(resorg => {
                                if (isStructuredResearchOrgsData) {
                                    return (
                                        `<React.Fragment key={${resorg.name}_${resorg.state}_${resorg.country}}>
                                                <div>
                                                    ${resorg.name}
                                                    ${resorg.state ? `, ${resorg.state}` : ''}
                                                    ${resorg.country ? `, ${resorg.country}` : ''}
                                                </div>
                                            </React.Fragment>`
                                    )
                                }
                                else {
                                    return `<li key={${resorg}}>{resorg}</li>`
                                }
                            }).join('')
                                }
                            </div> `
                        }
                    } else if (i === 0 && j === parts.length - 1 && props?.repositoryInfo?.name === 'dspub') {
                        const metaData = props.docMetadata ? props.docMetadata : {};
                        let preferredDataForAuthors = this.getPreferrredData(metaData.authors, metaData['authors.json']);
                        const authors = preferredDataForAuthors.data;
                        let isStructuredAuthorsData = preferredDataForAuthors.isStructuredData;

                        if (!isArrayEmpty(metaData.source_title)) {
                            partsString += `<div className = "primaryInfo" style = 'padding-top: 10px'
                        title = ${!isArrayEmpty(metaData.source_original_title) && metaData.source_original_title[0] !== metaData.source_title[0] ? `${metaData.source_original_title[0]}` : ''}>
                            ${metaData.source_title}
                                ${!isArrayEmpty(metaData.volume) ? `, ${metaData.volume[0]} ` : ''}
                                ${!isArrayEmpty(metaData.issue) ? `(${metaData.issue[0]})` : ''}
                                ${!isArrayEmpty(metaData.pages) ? `, ${metaData.pages[0]} ` : ''}
                                ${!isArrayEmpty(metaData.pubdate) ? ` - ${getMonthNameFromDate(metaData.pubdate[0]) ? getMonthNameFromDate(metaData.pubdate[0]) : ''} ` : ''}
                                ${!isArrayEmpty(metaData.pubdate) ? ` ${getYearFromDate(metaData.pubdate[0])} ` : ''}
                            </div> `
                        }
                        if (!isArrayEmpty(metaData.doi)) {
                            partsString += `<a className = "secondaryLink"
                        style = 'color: #757575'
                        target = "_blank"
                        rel = "noopener noreferrer"
                        href = ${createDOILink(metaData.doi[0])}>
                            ${createDOILink(metaData.doi[0])}
                            <span style='font-family:Material Icons; top: 2px; position: relative; margin-left: 2px; font-size: 13px;' className='material-icons ext-link-standard'>launch</span>
                            </a> `
                        }
                        if (!isArrayEmpty(authors)) {
                            partsString += `<div>
                                <h2 style='font-size: 15px; font-weight: 500; padding-top: 10px;' className="ocTextHeading_2">Authors</h2>
                                <ul className="noStyleList" style='line-height: 2; padding-left: 0px;}'>
                                    ${authors
                                    .filter((author, index) => (this.state.showAllAuthors || index < 3))
                                    .map((author, index) => {
                                        if (isStructuredAuthorsData) {
                                            return (
                                                `<li key={${author.first_name}_${author.last_name}}>
                            ${author.first_name} ${author.last_name}
                                            ${!isArrayEmpty(author.affiliations) && author.affiliations[0].affiliation ? ` - ${author.affiliations[0].affiliation} ` : ''}
                                            <span style='color:#818181;' className="secondaryInfo">${author.corresponding ? " - Corresponding author" : ''}</span>
                                                    </li>`
                                            );
                                        }
                                        else {
                                            return `<li key=${author}>${author}</li>`
                                        }
                                    }).join('')
                                }
                                ${!this.state.showAllAuthors && authors.length > 3 ?
                                    `<span style='color:#818181;'>${authors.length - 3} more</span>` : ''
                                }

                                </ul>
                            </div> `
                        }
                        if (!isArrayEmpty(metaData.research_org)) {
                            partsString += `<div>
                                <h2 style='font-size: 15px; font-weight: 500; padding-top: 10px;' className="ocTextHeading_2">Research organization</h2>
                                <div>${metaData.research_org[0]}
                                    ${!isArrayEmpty(metaData.research_org_country) ? `, ${metaData.research_org_country[0]} ` : ''}</div>
        
                            </div> `
                        }
                    }
                });
            });

            let completeHTML = '<body>' + '<div ocLang=\'en\'>' + partsString + '</div>' + '</body>'
            //console.log(completeHTML)
            enrichedPartsHtml.enrichedDocument = this.updateHtml(completeHTML, props);
        }

        let highlights = []

        highlights = await this.filterBasedOnMatches(props.annotationRanges, conceptTreeDataComplete.queryMatchesCount)

        if (this.props.pdfUrl !== "") {
            let filteredSentences = filterSentences(props.annotationRanges)
            highlights = filteredSentences.concat(highlights)
        }

        this.setState({
            docHtml: enrichedPartsHtml.enrichedDocument, //html,
            docPartsHtml: enrichedPartsHtml,
            annotations: props.annotationRanges,
            conceptsData: props.conceptDataMap,
            conceptTreeData: conceptTreeDataComplete.conceptTreeData,
            annotationsCount: conceptTreeDataComplete.annotationsCount,
            completeAnnTypesMap: conceptTreeDataComplete.completeAnnTypesMap,
            queryMatchesCount: conceptTreeDataComplete.queryMatchesCount,
            ocidToAnnIdsMap: ocidToAnnIdsMap,
            processingDocument: false,
            doneLoadingDocumentData: true,
            highlights: highlights,
            isMatchesSelected: conceptTreeDataComplete.queryMatchesCount > 0 && !this.props.isSemanticSearch
        }, () => {
            if (this.props.renderDocumentOnly) {
                this.props.onLoading(false)
                if (this.props.searchLoaded) {
                    this.props.onSetSearchLoaded(false)
                }
            }
        });

        setTimeout(() => {
            this.embedBatches();
        }, 500)

    }

    updateHtml = (html, props) => {
        //console.log(html)
        //console.log(props)
        // --- manipulate elements in html string, e.g. links 
        html = updateHtml(html, this.props.containerID);
        // --- replace image placeholders with actual image tags --- //
        if (props.images) {
            html = replaceImagePlaceholders(html, IMAGE_MAX_COUNT);

            if (props.images.length > IMAGE_MAX_COUNT) {
                this.growl.show({
                    life: 5000, severity: 'info', summary: 'Too many images',
                    detail: `Only the first ${IMAGE_MAX_COUNT} out of ${props.images.length} are shown in the text.`
                });
            }
        }
        return html;
    }

    // ----------------------------------------------------------------------- //
    // --- highlight query terms and annotations ----------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Changes highlighting of annotations or query terms. 
     * Domains/Query terms. On/off.
     */
    changeHighlighting = async (event) => {
        this.setState({ loadingAnnotations: true });
        await sleep(0);

        const newHighlightVals = event.value;
        const activeAnnotations = determineActiveHighlighting(this.state.highlightVals, newHighlightVals,
            this.state.activeAnnotations, this.state.completeAnnTypesMap);

        this.setState({
            highlightVals: newHighlightVals,
            activeAnnotations: activeAnnotations,
            isMatchesSelected: event.value.includes('qry')
        });

        if (!event.value.includes('qry') && !this.props.pdfUrl) {
            $('#annId' + this.state.lastActiveAnnId).removeClass('highlightNE');
            this.setState({
                lastActiveAnnId: 0
            })
        }
        // mbshaban
        setTimeout(() => {
            this.switchHighlighting(event, this.state.isSemanticOn, 'isSemanticOn')
        }, 20)
    }


    // filter based on switche 

    switchHighlighting = async (event, isOn, type) => {

        if (type === 'isSemanticOn') {
            if (this.state.activeSearchTerms.length > 0
                || this.state.activeAnnotations.length > 0
                || this.state.queryMatchesCount > 0) {
                this.setState({ loadingAnnotations: true });
                await sleep(0);
                this.setState({
                    highlights: this.filterAnnotationsClientSide(isOn),
                    loadingAnnotations: false,
                })
            }
            else {
                this.setState({
                    loadingAnnotations: false,
                })
            }
        } else {
            this.changeHighlighting(event)
        }

    }

    // filter annotions in client side
    filterAnnotationsClientSide = (isOn) => {
        return this.state.annotations.filter((item) => this.switchesConditions(item?.annotations, isOn))
    }

    // semantic, annotationsa and matches condition
    switchesConditions = (item, isOn) => {
        return item.find((obj) => {
            const temp = this.state.activeAnnotations?.find((domain) => domain === obj?.domain);
            if (isOn) {
                if (this.state.isMatchesSelected) {
                    return (temp || obj.queryTerm);
                } else {
                    return (temp || (obj.queryId && obj.queryId !== 'ocFuzzyText:readcube_text'));
                }
            } else {
                if (this.state.isMatchesSelected) {
                    return (temp || (obj.queryTerm && !obj.queryId) || (obj.queryTerm && obj.queryId === 'ocFuzzyText:readcube_text'));
                } else {
                    return (temp && !obj.queryTerm);
                }
            }
        })
    }


    /**
     * Toggles domain highlighting for all named entities of a given domain.
     */
    toggleDomainHighlighting = async (domain) => {
        this.setState({ loadingAnnotations: true });

        await sleep(0);
        const activeAnnotations = changeActiveHighlighting(this.state.activeAnnotations, domain);
        const isQueryTerm = activeAnnotations.includes('queryTerm')
        this.setState({
            activeAnnotations: activeAnnotations,
            isAnnotationOn: isQueryTerm ? activeAnnotations.length > 1 : activeAnnotations.length > 0,
            isMatchesSelected: isQueryTerm
        });
        // mbshaban
        setTimeout(() => {
            this.switchHighlighting(null, this.state.isSemanticOn, 'isSemanticOn')
        }, 20)
    }

    /**
     * Activates domain highlighting for all named entities of a given domain.
     */
    activateDomainHighlighting = async (domain) => {
        if (domain && !this.state.activeAnnotations.includes(domain)) {
            this.setState({ loadingAnnotations: true });

            await sleep(0);
            const activeAnnotations = addActiveHighlighting(this.state.activeAnnotations, domain);

            const isQueryTerm = activeAnnotations.includes('queryTerm')
            this.setState({
                activeAnnotations: activeAnnotations,
                isAnnotationOn: isQueryTerm ? activeAnnotations.length > 1 : activeAnnotations.length > 0,
                isMatchesSelected: isQueryTerm
            });


            // mbshaban

            setTimeout(() => {
                this.switchHighlighting(null, this.state.isSemanticOn, 'isSemanticOn')
            }, 20)
        }
    }

    /**
    * return the matches only
    * @param {} highlights 
    * @param {*} queryMatchesCount 
    * @returns 
    */
    filterBasedOnMatches = (highlights, queryMatchesCount) => {
        if (queryMatchesCount > 0) {
            return Array.isArray(highlights) ?
                highlights?.filter((item) => item?.annotations?.find(value => value?.queryTerm)) : []
        } else {
            return highlights.filter((item) => item?.annotations?.find((obj) =>
                this.state.activeAnnotations?.find((domain) => domain === obj?.domain) || (
                    this.state.queryMatchesCount > 0 ? (obj?.queryId || obj?.queryTerm) :
                        obj?.queryId)));
        }
    }

    /**
    * MBshaban filter annotaions
    * @param {*} isDomain 
    * @returns 
    */


    filterAnnotations = async (ocids) => {
        const { repID, ocDocID, intQuery, readcubeData } = this.props;
        this.setState({ requestLoader: true })

        const repos = this.props.repositories ? this.props.repositories.filter(repo => repo.id == repID) : null;
        let repositoryInfo = REPOSITORY_INFO_NOT_AVAILABLE;
        if (repos && repos.length > 0) {
            repositoryInfo = repos[0];
        }
        let abstractOnly = false;
        let reqDocumentParts = null;
        if (repositoryInfo.name === 'dspub') {
            abstractOnly = true;
            reqDocumentParts = [{ "name": "title", "onlyFirst": true }, { "name": "dsabstract" }];
        }
        else if (repositoryInfo.name === 'dsgrants') {
            abstractOnly = true;
            reqDocumentParts = [{ "name": "title" }, { "name": "abstract" }];
        } else if (this.props.renderDocumentOnly && this.props.selectedSections?.filterValue !== 'all') {
            abstractOnly = false;
            if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'tac') {
                reqDocumentParts = [{ "name": "title" }, { "name": "claims" }, { "name": "abstract" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'ta') {
                reqDocumentParts = [{ "name": "title" }, { "name": "abstract" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'title') {
                reqDocumentParts = [{ "name": "title" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'claims') {
                reqDocumentParts = [{ "name": "claims" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'experimental') {
                reqDocumentParts = [{ "name": "experimental" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'references') {
                reqDocumentParts = [{ "name": "references" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'basicdata') {
                reqDocumentParts = [{ "name": "basicdata" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && (this.props.selectedSections.filterValue === 'design' || this.props.selectedSections.filterValue === 'methods')) {
                reqDocumentParts = [{ "name": "methods" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'description') {
                reqDocumentParts = [{ "name": "description" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'eligibility') {
                reqDocumentParts = [{ "name": "eligibility" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'endpoints') {
                reqDocumentParts = [{ "name": "endpoints" }];
            }
        }

        const request = createSemanticSearchRequest(repID, ocDocID, intQuery, ocids, reqDocumentParts, this.props.renderDocumentOnly);
        const response = readcubeData?.available ? await fetchAnnotatedReadCubeDocument(request, readcubeData) : await fetchAnnotatedDocument(request);


        if (response.status === 'SUCCESS') {
            const payload = response.payload;
            let temp = {}
            if (!this.props.pdfUrl) {
                let origPartsHtml = {};
                if (abstractOnly && payload.annotatedDocument && payload.annotatedDocument.namedDocumentRanges) {
                    origPartsHtml.namedDocumentRanges = payload.annotatedDocument.namedDocumentRanges;
                    origPartsHtml.createFromDocParts = true;
                }
                else {
                    origPartsHtml.document = payload.annotatedDocument.document;
                    origPartsHtml.createFromDocParts = false;
                }
                let enrichedPartsHtml = { ...origPartsHtml };
                if (!enrichedPartsHtml.createFromDocParts) {
                    enrichedPartsHtml.enrichedDocument = this.updateHtml(payload.annotatedDocument.document, this.props);
                }
                temp.docHtml = enrichedPartsHtml.enrichedDocument;
                temp.docPartsHtml = enrichedPartsHtml;
            }
            const coll = Object.values(payload.annotatedDocument.annotationRanges);
            const ocidToAnnIdsMap = createOcidToAnnIdMap(coll, payload.annotatedDocument.conceptDataMap);
            //console.log(payload.annotatedDocument)
            temp = {
                ...temp,
                annotations: coll,
                highlights: coll.filter((item) => item?.annotations?.find((obj) =>
                    ocids?.find((domain) => domain === obj?.domain) || this.state.activeAnnotations?.find((domain) => domain === obj?.domain) || (
                        this.state.queryMatchesCount > 0 ? (obj?.queryId || obj?.queryTerm) :
                            obj?.queryId)))
                ,
                requestLoader: false,
                ocidToAnnIdsMap: ocidToAnnIdsMap,
                activeSearchTermsCount: payload.annotatedDocument.namedQueriesMatchesCount,
                activeSearchTerms: ocids
            }

            this.setState(temp)
        }

    }



    // clear active search terms
    clearSemanticSearch = () => {
        this.setState({
            activeSearchTerms: []
        })
        setTimeout(() => {
            this.filterAnnotations([])
        }, 10)
    }

    /**
    * MBshaban change highligt color
    * @param {*} isDomain 
    * @returns 
    */

    changeOcidAnnotationColor = async (id, color) => {
        this.setState({
            loadingAnnotations: true
        })
        await sleep(0)
        this.setState({
            activeSearchTerms: this.state.activeSearchTerms?.map((item) => {
                if (item?.id === id) {
                    return {
                        ...item,
                        color: color
                    }
                } else {
                    return item;
                }
            }),
            loadingAnnotations: false

        })
    }

    /**
    *    mbshaban Activates domain highlighting for all named entities of a given domain.
    */
    activateOcidHighlighting = async (selectedConcept, isUnselect = false) => {
        let ocids = [...this.state.activeSearchTerms];
        const activeTerm = ocids.find(item => item?.id === selectedConcept.id)
        if (activeTerm && isUnselect) {
            _.remove(ocids, function (item) {
                return (item?.id === selectedConcept.id)
            })
        }
        else if (!activeTerm) {
            if (!this.state.isSemanticOn)
                this.setState({ isSemanticOn: true })

            ocids.push(selectedConcept);
        }
        this.props.setActiveSearchTerm(ocids)

        if (!isUnselect) {
            setTimeout(() => {
                this.filterAnnotations(ocids)
            }, 20)
        } else {
            if (this.state.activeSearchTermsCount[selectedConcept.id] !== 0) {
                setTimeout(() => {
                    this.filterAnnotations(ocids)
                }, 20)
            } else {
                this.setState({
                    activeSearchTerms: ocids
                })
            }
        }
    }




    // ----------------------------------------------------------------------- //
    // --- emphasize and jump to query term or annotation -------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Jumps to the next or previous named entity of a given domain and emphasizes 
     * it with styling.
     */
    jumpToMatchGenrally = (annId) => {
        if (!annId) {
            return;
        }
        openAllSections();
        // --- remove styling from last active NE, hightlight named entity in text --- //
        $('#' + this.state.lastActiveAnnId).removeClass('highlightNE');
        $('#' + annId).addClass('highlightNE');
        // --- scroll to element that is currently highlighted (hack) --- //
        if (!this.props.pdfUrl) {
            scrollToElementViaScrollToButton('#scrollToBtn', '#' + annId);
        }
        else {
            document.location.hash = `highlight-${annId}`;
        }
        this.setState({ lastActiveAnnId: annId });
    }


    // ----------------------------------------------------------------------- //
    // --- emphasize and jump to query term or annotation -------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Jumps to the next or previous named entity of a given domain and emphasizes 
     * it with styling.
     */
    jumpToMatch = (ocid, next, domain) => {
        if (!this.state.ocidToAnnIdsMap) {
            return;
        }
        // --- open all sections in case matching concept is in closed section --- //
        openAllSections();
        // --- activate domain highlighting ---- //
        this.activateDomainHighlighting(domain);
        // --- find next matching concept --- //
        this.jumpToMatchOperation(this.state.ocidToAnnIdsMap[ocid], next)
    }



    jumpToMatchOperation = (temp, next) => {
        const lastAnnId = this.state.lastActiveAnnId;
        const nextAnnId = determineNextActiveAnnId(temp, this.state.lastActiveAnnId, next);
        if (!this.props.pdfUrl) {
            // --- remove styling from last active NE, hightlight named entity in text --- //
            $('#annId' + lastAnnId).removeClass('highlightNE');
            $('#annId' + nextAnnId).addClass('highlightNE');
            // --- scroll to element that is currently highlighted (hack) --- //
            scrollToElementViaScrollToButton('#scrollToBtn', '#annId' + nextAnnId);
        }
        else {
            document.location.hash = `highlight-annId${nextAnnId}`;
        }
        this.setState({ lastActiveAnnId: nextAnnId });

    }



    /**
     * Jumps to the image or span with src attribute and emphasizes 
     * it with styling.
     */
    jumpToImage = (src) => {
        // --- open all sections in case matching image is in closed section --- //
        openAllSections();

        const lastImgSrc = this.state.lastActiveImgSrc;
        // --- remove styling from last active image --- //
        let prevImgObj = $('span[img-src="' + lastImgSrc + '"] img');
        prevImgObj = prevImgObj && prevImgObj.length > 0 ? prevImgObj : $('span[img-src="' + lastImgSrc + '"]');
        prevImgObj.removeClass('highlightNE');
        // --- hightlight image in text --- //
        let newImgObj = $('span[img-src="' + src + '"] img'); // || $('span[img-src="' + src + '"]');
        newImgObj = newImgObj && newImgObj.length > 0 ? newImgObj : $('span[img-src="' + src + '"]');
        newImgObj.addClass('highlightNE');
        // --- scroll to image with attribute img-src == src --- //
        scrollToElementViaScrollToButton('#scrollToImg', src);

        this.setState({ lastActiveImgSrc: src });
    }


    // ----------------------------------------------------------------------- //
    // --- annotation feedback ----------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Opens monday feedback form in new tab/window.
     */
    onReportFeedback = () => {
        window.open(APP_PROPERTIES.ANNOTATION_FEEDBACK_FORM_URL, '_blank')
    }


    // switch on and of 
    setSwitchOnOFValue = (part, value) => {
        if (part === 'isSemanticOn') {
            this.setState({ [part]: value, annTextDetailsIndex: value ? 0 : null });
        } else {
            this.setState({ [part]: value });
        }
    }


    // ----------------------------------------------------------------------- //
    // --- details view ------------------------------------------------------ //
    // ----------------------------------------------------------------------- //
    /**
     * Toggles details view. For certain tabs a specific action will be executed.
     * E.g. displaying structure images of all annotated compounds.
     */
    toggleAnnTextDetailsView = async (event) => {
        // --- some views should be lazy loaded --- //
        // --- e.g. compounds view ---------------- //
        let compoundsIndex = 3;
        setTimeout(() => {
            this.setState({ annTextDetailsIndex: event.index });
            if (event.index === compoundsIndex) {

                this.setState({ fetchingCompounds: true });
                // --- retrieve compound info incl. structure image --- //
                const { compoundOcidData, filteredMap } = extractAnnotatedCompounds(
                    this.state.completeAnnTypesMap, ANN_TYPE_CHEM_STRUCTURE, this.props.conceptDataMap, 'relevanceScore', false);

                this.setState({
                    compounds: [],
                    compoundOcidData: Object.values(compoundOcidData),
                    labeledCompoundOcidData: Object.values(filteredMap),
                    fetchingCompounds: false,
                    showLabeledCompounds: Object.keys(filteredMap).length > 0,
                });
            }
        }, 10)
    }


    // ----------------------------------------------------------------------- //
    // --- load annotation info window --------------------------------------- //
    // ----------------------------------------------------------------------- //
    loadAnnotationInfo = async () => {
        this.setState({ annotationInfos: null });

        // --- get annotation ID from hidden field --- //
        const annId = $('#annotationID').val();

        const temp = this.state.highlights?.find(item => item?.annId === annId);
        // --- merge annotation infos and concept infos into one object for each annotation --- //
        const annotationInfosAll = temp || {};
        const annotationInfos = annotationInfosAll?.annotations?.filter(ann => !ann.queryTerm);
        let annotationInfosEnriched = annotationInfos;
        if (this.state.conceptsData) {
            annotationInfosEnriched = annotationInfos?.map(ann => {
                const concData = this.state.conceptsData[ann.ocid];
                return { ...ann, ...concData };
            });
        }

        this.setState({ annotationInfos: annotationInfosEnriched },
            function () {
                $("#conceptInfoDetails").css('display', 'inline');
            });
    }

    showConceptInfo = (ocid) => {
        const clickedEntry = { ocid: ocid };
        this.setState({
            displayConceptInfoDialog: true,
            clickedEntry: clickedEntry
        })
    }


    // ----------------------------------------------------------------------- //
    // --- retrieve extra data for given OCIDs ------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Retrieves concept info incl. structure image about compound with given OCIDs.
     */
    retrieveCompoundInfos = async (ocids) => {
        if (ocids) {
            const request = createConceptDataRequest(ocids, true, true, false, false, 0, 0, true);
            const response = await fetchConceptsData(request, true);
            return checkResultAndGetPayload(response, this.growl);
        }
        return [];
    }


    exportCompoundsFromDocument = async (repID, ocDocID, onlyLabeled) => {
        this.setState({ exportingCompounds: true });
        await exportCompoundsFromDocument(repID, ocDocID, onlyLabeled);
        this.setState({ exportingCompounds: false });
    }

    exportNucleotideSequencesFromDocument = async (ocDocID) => {
        this.setState({ exportingNucleotideSequences: true });
        await exportNucleotideSequencesFromDocument(ocDocID);
        this.setState({ exportingNucleotideSequences: false });
    }

    exportProteinSequencesFromDocument = async (ocDocID) => {
        this.setState({ exportingProteinSequences: true });
        await exportProteinSequencesFromDocument(ocDocID);
        this.setState({ exportingProteinSequences: false });
    }

    handleShowSequenceDetailsNucleotide = (e, sequenceID) => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL} /api/v1 / sequence - data / nucleotide ? ocids = ${sequenceID} `, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response.data.sequences[0])
                self.setState({
                    displayNucleotideSequenceDetails: true,
                    singleNucleotideSequenceDetails: response.data.sequences[0],
                    singleNucleotideSequenceOCID: response.data.sequences[0].ocid
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleShowSequenceDetailsProtein = (e, sequenceID) => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL} /api/v1 / sequence - data / protein ? ocids = ${sequenceID} `, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response.data.sequences[0])
                self.setState({
                    displayProteinSequenceDetails: true,
                    singleProteinSequenceDetails: response.data.sequences[0],
                    singleProteinSequenceOCID: response.data.sequences[0].ocid
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleExportSingleFASTANucleotide = () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL} /api/v1 / sequence - data / nucleotide / fasta ? seqId = ${self.state.singleNucleotideSequenceOCID} `, { headers: getHeaderToken(), responseType: 'blob' })
            .then(function (response) {
                //console.log(response)
                saveAs(new Blob([response.data]), `nucleotide_${self.state.singleNucleotideSequenceOCID}.fasta.zip`)
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleExportSingleFASTAProtein = () => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL} /api/v1 / sequence - data / protein / fasta ? seqId = ${self.state.singleProteinSequenceOCID} `, { headers: getHeaderToken(), responseType: 'blob' })
            .then(function (response) {
                //console.log(response)
                saveAs(new Blob([response.data]), `protein_${self.state.singleProteinSequenceOCID}.fasta.zip`)
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    increaseNumberOfSequences = () => {
        this.setState({
            minSequences: this.state.minSequences + 20,
            maxSequences: this.state.maxSequences + 20
        })
    }

    decreaseNumberOfSequences = () => {
        this.setState({
            minSequences: this.state.minSequences - 20,
            maxSequences: this.state.maxSequences - 20
        })
    }

    showDocument = (doc) => {
        let link = createDocViewUrl(this.props.repID, doc.ocDocId);
        window.open(link, "_blank");
    }

    openExternalLink = (url) => {
        window.open(url, "_blank");
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    // domain exp

    onSpecifyQueryTermWithDomainExplorer = (queryTerm) => {
        //console.log(queryTerm)
        this.setState({
            queryTermToReplace: queryTerm,
            domainExplorerQuery: isArrayEmpty(queryTerm?.ocids) && queryTerm?.term,
            domainExplorerOcids: !isArrayEmpty(queryTerm?.ocids) && queryTerm.ocids,
        }, () => {
            //sleep(1000); // test
            this.setState({ domainBrowserVisible: true })
        });
    }

    onDomainExplorerClose = () => {
        this.setState({ domainBrowserVisible: false });
    }

    onDomainExplorerSubmit = (concepts) => {
        if (this.state.queryTermToReplace) {
            const queryTerms = replaceQueryTermWithConcepts(concepts, this.state.activeSearchTerms, this.state.queryTermToReplace, true, this.getDomainColorTopPeriority());
            this.setState({
                // activeSearchTerms: queryTerms,
                queryTermToReplace: null,
                domainBrowserVisible: false
            }
            );
            setTimeout(() => {
                this.filterAnnotations(queryTerms)
            }, 10)
        }
    }

    getDomainColorTopPeriority = () => {
        return _.orderBy(this.props.domains, ['orderPriority'], 'desc')
    }


    setHiddenExactTextMisMatch = (matches) => {
        this.setState({
            hiddenExactTextMatches: matches
        })
    }



    handleAddDocumentToCollections = async (collections) => {
        if (!isArrayEmpty(collections)) {
            const ids = collections.map(coll => coll.id);
            const result = await this.props.addDocumentToCollections(this.props.repID, this.props.ocDocID, this.props.title, ids);
            if (result.status !== RESPONSE_STATUS_SUCCESS) {
                this.growl.show({
                    sticky: false, closable: true, severity: 'error',
                    summary: 'Error', detail: result.message
                });
            }
        }
        else {
            this.growl.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'No collections were selected.'
            });
        }
    }

    handleRemoveDocumentFromCollections = async (collections) => {
        if (!isArrayEmpty(collections)) {
            collections.forEach(coll => {
                // @todo: use single call if possible
                this.props.removeDocumentFromCollection(this.props.repID, this.props.ocDocID, coll.id, coll.documentLink);
            });
            /*if (result.status !== RESPONSE_STATUS_SUCCESS) {
                this.growl.show({
                    sticky: false, closable: true, severity: 'error',
                    summary: 'Error', detail: result.message
                });
            }*/
        }
        else {
            this.growl.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'No collections were selected.'
            });
        }
    }


    renderHTMLView = () => {
        //console.log(this.state.highlights)
        return <div id="oc_doc_unit" className={!this.props.renderDocumentOnly ? "col-12 md:col-7 lg:col-8" : ""}
            style={{
                height: !this.props.renderDocumentOnly ? `calc(100vh - ${170 - this.props.documentHeight}px)` : `calc(${this.props.availableContentHeight + 145}px)`,
                //height: `calc(100vh - ${ 170 - this.props.documentHeight }px)`,
                overflowY: 'auto',
                marginRight: this.props.renderDocumentOnly ? 7 : 0,
                display: 'block',
                visibility: 'visible'
            }}>
            <div id={this.props.containerID} style={{
                height: '100%', overflow: 'auto',
                display: this.props.renderDocumentOnly && this.props?.containerID === 'oc_doc' && this.props?.repositoryInfo?.name !== 'dspub' ? 'flex' : ''
            }}>
                {this.props.annotationRanges && this.state.doneLoadingDocumentData &&
                    <AnnotatedText
                        activeIndex={this.props.activeIndex}
                        activeIndexChange={this.props.activeIndexChange}
                        activeIndexIncrease={this.props.activeIndexIncrease}
                        abortLoadingClicked={this.props.abortLoadingClicked}
                        setAbortLoadingClicked={this.props.setAbortLoadingClicked}
                        annotationInfos={this.state.annotationInfos}
                        abstractOnly={this.props.abstractOnly}
                        loadAnnotationInfo={this.loadAnnotationInfo}
                        onShowConceptInfo={this.showConceptInfo}
                        annTypeLabelsMap={this.props.annTypeLabelsMap ? this.props.annTypeLabelsMap : {}}
                        domainColors={this.props.domainColors ? this.props.domainColors : {}}
                        repositoryInfo={this.props.repositoryInfo}
                        docHtml={this.state.docHtml}
                        docPartsHtml={this.state.docPartsHtml}
                        annotations={this.state.highlights}
                        conceptsData={this.state.conceptsData}
                        activeAnnotations={this.state.activeAnnotations}
                        docMetadata={this.state.docMetadata}
                        activeSearchTerms={this.state.activeSearchTerms}
                        domains={this.props.domains}
                        isSemanticOn={this.state.isSemanticOn}
                        isMatchesSelected={this.state.isMatchesSelected}
                        setHiddenExactTextMisMatch={this.setHiddenExactTextMisMatch}
                        renderDocumentOnly={this.props.renderDocumentOnly}
                        selectedSentence={this.props.selectedSentence}
                        openAllSections={openAllSections}
                        selectedSentenceOption={this.props.selectedSentenceOption}
                        threshold={this.props.threshold}
                        selectedQueryTerms={this.props.selectedQueryTerms}
                        annotationCoordinates={this.props.annotationCoordinates}
                        availableContentHeight={this.props.availableContentHeight}
                        documentHeight={this.props.documentHeight}
                        showSentenceEntities={this.props.showSentenceEntities}
                        selectedSections={this.props.selectedSections}
                        searchLoaded={this.props.searchLoaded}
                        loader={this.props.loader}
                    />
                }
                {!isArrayEmpty(this.state.docMetadata?.referencedDocuments) ?
                    <References
                        referencedDocuments={this.state.docMetadata.referencedDocuments}
                        showAllReferences={this.state.showAllReferences}
                        onShowAllReferencesChange={(showAll) => this.setState({ showAllReferences: showAll })}
                        onShowDocument={this.showDocument}
                        repositoryInfo={this.props.repositoryInfo}
                        label={'References'}
                    /> : null}
                {!isArrayEmpty(this.state.docMetadata?.citedDocuments) ?
                    <References
                        referencedDocuments={this.state.docMetadata.citedDocuments}
                        showAllReferences={this.state.showAllCitations}
                        onShowAllReferencesChange={(showAll) => this.setState({ showAllCitations: showAll })}
                        onShowDocument={this.showDocument}
                        repositoryInfo={this.props.repositoryInfo}
                        label={'Citations'}
                    /> : null}
            </div>
        </div>
    }


    renderPDFView = () => {
        //console.log(this.props.annotationRanges)
        return <div id="oc_pdfdoc_unit" className={!this.props.renderDocumentOnly ? "col-12 md:col-7 lg:col-8" : ""}
            style={{
                height: !this.props.renderDocumentOnly ? `calc(100vh - ${156 - this.props.documentHeight}px)` : `calc(${this.props.availableContentHeight + 136}px)`,
                display: 'block',
                visibility: 'visible',
            }}>
            <div id={this.props.containerID} style={{ height: '100%', overflow: 'auto', paddingTop: 32 }}>

                {this.props.annotationRanges && this.state.doneLoadingDocumentData &&
                    <PDFDocument
                        annotations={this.state.highlights}
                        conceptsData={this.props.conceptDataMap}
                        activeAnnotations={this.state.activeAnnotations}
                        activeSearchTerms={this.state.activeSearchTerms}
                        pdf={this.props.pdfUrl}
                        initQuery={this.state.query}
                        annTypeLabelsMap={this.props.annTypeLabelsMap}
                        domainColors={this.props.domainColors}
                        domains={_.orderBy(this.props.domains, ['orderPriority'], 'desc')}
                        isMatchOn={this.state.isMatchesSelected}
                        selectedSentence={this.props.selectedSentence}
                        activeSentenceChange={this.props.activeSentenceChange}
                        availableContentHeight={this.props.availableContentHeight}
                        renderDocumentOnly={this.props.renderDocumentOnly}
                        readcubeData={this.props.readcubeData}
                    />
                }
            </div>
        </div>
    }

    renderSidebar = () => {
        const { repID, ocDocID, docAdditionalOrganisms } = this.props;

        //console.log(this.props)

        const { type, identifier } = getReadcubePapersIdentifier(this.props.docMetadata);

        const rating = this.state.addDocData?.rating || 0;
        const read = !!this.state.addDocData?.read;
        const docCollections = this.state.addDocData?.documentCollectionList || [];

        return <div className="col-12 md:col-5 lg:col-4 docview-sidebar" style={{
            height: `calc(100vh - ${170 - this.props.documentHeight}px)`
        }}>
            {(this.props.repositoryInfo?.name === 'dspub' && !this.props.readcubeData?.available) &&
                <div className="full-text-message">
                    We are able to search the full text of this document even if we are not able to show it, due to legal reasons.
                    Matching query terms or sentences may have been found in the full text but are not shown here.
                </div>
            }

            {!this.props.readcubeData?.available &&

                <div className="grid docview-sidebar-section-linkouts textAlignLeft width100perc">


                    {hasBILinkResolver(this.state.docMetadata) ?
                        <div className="col-12" style={{ marginBottom: 0, paddingBottom: 0 }}>
                            <BILinkResolver
                                documentData={this.state.docMetadata}
                                useIcon={false}
                                buttonStyleClass='primaryButton linkout-button p-button-sm width100perc'
                            />
                        </div> : null}
                    <div className="col-12" style={{ marginBottom: 0 }}>
                        <DocumentLinkoutButton
                            docMetadata={this.state.docMetadata}
                            ocDocID={ocDocID}
                            repositoryInfo={this.props.repositoryInfo}
                        />
                    </div>
                    <div className="col-6">
                        <a title="Open annotated document in new tab"
                            className="primaryButtonAsLink"
                            onClick={e => {
                                let link = createDocViewUrl(repID, ocDocID);
                                window.open(link, "_blank");
                            }}>
                            Open document view in new tab
                        </a>
                    </div>
                    <div className="col-6 textAlignRight">
                        <CopyToClipboard text={createDocViewUrl(repID, ocDocID)}>
                            <a title={`Copy document view URL to clipboard`}
                                onClick={(e) => this.growl.show({ sticky: false, severity: 'success', summary: 'Success', detail: `Copied to clipboard.` })}>
                                Copy document view URL
                            </a>
                        </CopyToClipboard>
                    </div>
                </div>
            }
            <hr className='docview-sidebar-separator' />

            <div className="grid docview-sidebar-section-myresearch textAlignLeft width100perc">


                {this.state.doneLoadingDocumentData && hasUserRole('ROLE_LIBRARY') &&
                    <div className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 pt-15" style={{ marginBottom: -4 }}>
                        <label className="valignMiddle" style={{ marginRight: 5 }}>In collections:</label>
                        <AddRemoveDocumentsDropdown
                            userData={this.props.userData}
                            inCollections={docCollections}
                            placeholder="none"
                            onAddToCollections={this.handleAddDocumentToCollections}
                            onRemoveFromCollections={this.handleRemoveDocumentFromCollections}
                        />
                    </div>
                }

                <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-4" style={{ paddingTop: 5, paddingBottom: 0, marginRight: '-3vw' }}>
                    <DocumentBookmark
                        isRead={read}
                        repository={repID}
                        ocDocID={ocDocID}
                        title={this.props.title}
                        onChangeReadStatus={this.props.updateDocumentReadStatus}
                    />
                </div>

                <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-5" style={{ marginLeft: 20, paddingTop: 8, paddingBottom: 0 }}>
                    <Rating value={rating} readOnly={false} cancel={true}
                        className='docview-sidebar-rating'
                        onChange={(e) => {
                            this.props.updateDocumentRating(repID, ocDocID, this.props.title, e.value || 0);
                        }}
                        title="Give this document a rating between 0 and 5 stars"
                        aria-label="Give this document a rating between 0 and 5 stars" />
                </div>

                {identifier && APP_PROPERTIES.INCLUDE_READCUBE ?
                    <div className="col-12" style={{ paddingTop: 10, paddingBottom: 0, marginLeft: -10 }}>

                        {identifier && APP_PROPERTIES.INCLUDE_READCUBE &&
                            <span style={{ marginLeft: 10 }}>
                                <ReadCubePapersLink type={type} identifier={identifier} />
                            </span>}
                    </div>
                    : null
                }

            </div>
            {
                (!this.props.isSemanticSearch && !isArrayEmpty(this.state.highlightOptions)) &&

                <hr className='docview-sidebar-separator' />
            }
            <div className="grid docview-sidebar-section-highlighting textAlignLeft width100perc">
                {
                    (!this.props.isSemanticSearch && !isArrayEmpty(this.state.highlightOptions)) &&
                    <Fragment>
                        <div className="col-12 pt-15">
                            <HighlightButtons
                                highlightOptions={this.state.highlightOptions}
                                highlightValues={this.state.highlightVals}
                                changeHighlighting={this.changeHighlighting}
                            />
                            {this.state.query && this.state.queryMatchesCount > 0 ?
                                <MatchesButtons
                                    matchCount={this.state.queryMatchesCount}
                                    jumpToMatch={this.jumpToMatch}
                                /> : null
                            }
                        </div>

                        <div className="breakRow"></div>
                    </Fragment>
                }
                {this.props.repositoryInfo &&
                    (this.props.repositoryInfo.name === 'patents' || this.props.repositoryInfo.name === 'ifipatents') ?
                    <div className="col-12">
                        <ToggleSections />
                    </div> : null}

                {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.allowAnnotationFeedback ?
                    <div className="col-12">
                        <a title='Report errors or suggestions for annotation'
                            onClick={() => this.onReportFeedback()}
                            style={{ marginLeft: 0 }}
                        >Send annotation feedback</a>
                    </div> : null
                }
            </div>

            <hr className='docview-sidebar-separator' />

            {

                this.state.docMetadata.doi || this.state.docMetadata.pmid || this.state.docMetadata.pmc ?
                    <Batches
                        doi={this.state.docMetadata.doi ? this.state.docMetadata.doi[0] : ''}
                        pmid={this.state.docMetadata.pmid ? this.state.docMetadata.pmid[0] : ''}
                        pmcid={this.state.docMetadata.pmc ? this.state.docMetadata.pmc[0] : ''}
                    /> : ''
            }

            <StructuredDocumentData
                ocDocID={ocDocID}
                annTextDetailsIndex={this.state.annTextDetailsIndex}
                toggleAnnTextDetailsView={(e) => this.toggleAnnTextDetailsView(e)}
                docMetadata={this.state.docMetadata}
                repositoryInfo={this.props.repositoryInfo}
                conceptTreeData={this.state.conceptTreeData}
                annotationsCount={this.state.annotationsCount}
                completeAnnTypesMap={this.state.completeAnnTypesMap}
                highlightVals={this.state.highlightVals}
                highlightOptions={this.state.highlightOptions}
                changeHighlighting={this.changeHighlighting}
                activeAnnotations={this.state.activeAnnotations}
                jumpToMatch={this.jumpToMatch}
                toggleDomainHighlighting={this.toggleDomainHighlighting}
                compoundOcidData={this.state.compoundOcidData}
                labeledCompoundOcidData={this.state.labeledCompoundOcidData}
                showLabeledCompounds={this.state.showLabeledCompounds}
                onShowLabeledCompoundsChange={e => { this.setState({ showLabeledCompounds: e.checked }); }}
                ocidToAnnIdsMap={this.state.ocidToAnnIdsMap}
                exportCompoundsFromDocument={this.exportCompoundsFromDocument}
                fetchingCompounds={this.state.fetchingCompounds}
                conceptsData={this.state.conceptsData}
                annTypeChemStructure={ANN_TYPE_CHEM_STRUCTURE}
                images={this.state.images}
                jumpToImage={this.jumpToImage}
                patFamilyMembers={this.props.patFamilyMembers}
                sequenceID={this.props.sequenceID}
                docAdditionalOrganisms={docAdditionalOrganisms}
                handleShowSequenceDetailsNucleotide={this.handleShowSequenceDetailsNucleotide}
                handleShowSequenceDetailsProtein={this.handleShowSequenceDetailsProtein}
                exportNucleotideSequencesFromDocument={this.exportNucleotideSequencesFromDocument}
                exportProteinSequencesFromDocument={this.exportProteinSequencesFromDocument}
                decreaseNumberOfSequences={this.decreaseNumberOfSequences}
                increaseNumberOfSequences={this.increaseNumberOfSequences}
                minSequences={this.state.minSequences}
                maxSequences={this.state.maxSequences}
                domains={_.orderBy(this.props.domains, ['orderPriority'], 'desc')}
                highlights={this.state.highlights}
                conceptDataMap={this.props.conceptDataMap}
                activateOcidHighlighting={this.activateOcidHighlighting}
                activeSearchTerms={this.state.activeSearchTerms}
                changeOcidAnnotationColor={this.changeOcidAnnotationColor}
                domainLabelsMap={this.props.domainLabelsMap}
                jumpToMatchGenrally={this.jumpToMatchGenrally}
                clearSemanticSearch={this.clearSemanticSearch}
                activeSearchTermsCount={this.state.activeSearchTermsCount}
                onSpecifyQueryTerm={this.onSpecifyQueryTermWithDomainExplorer}
                switchHighlighting={this.switchHighlighting}
                isSemanticOn={this.state.isSemanticOn}
                isAnnotationOn={this.state.isAnnotationOn}
                setSwitchOnOFValue={this.setSwitchOnOFValue}
                domainColors={this.props.domainColors}
                abstractOnly={this.props.abstractOnly}
                onAnnotationEntryClick={this.showConceptInfo}
                onCompoundEntryClick={this.showConceptInfo}
                hiddenExactTextMatches={this.state.hiddenExactTextMatches}
                readcubeData={this.props.readcubeData}
            />
        </div>
    }


    render() {
        const { status } = this.props;

        const loading = this.state.requestLoader || this.state.fetchingCompounds || this.state.fetchingImages || this.state.downloadingFile ||
            this.state.loadingAnnotations || this.state.exportingCompounds || this.state.exportingNucleotideSequences || this.state.exportingProteinSequences;

        // --- handle nucleotide and protein sequence data --- //
        const singleNucleotideSequence = extractSequenceData(this.state.singleNucleotideSequenceDetails);
        const singleProteinSequence = extractSequenceData(this.state.singleProteinSequenceDetails);

        const isHTMLView = !this.props.pdfUrl;

        //console.log(this.props.selectedSentence)

        return (
            <>
                <Toast ref={(el) => { this.growl = el }} />
                {status.showMessage ?
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        {status.fetchingDocument ? <><ProgressBar mode="indeterminate" /><br /><br /></> : null}
                        <div style={{ color: status.error ? 'red' : '#607D8B' }}>{status.message}</div>
                    </div>
                    :
                    <>
                        {this.state.processingDocument ?
                            <div style={{ textAlign: 'center', marginTop: 20 }}>
                                <ProgressBar mode="indeterminate" /><br /><br />
                                <div style={{ color: '#607D8B' }}>Processing document...</div>
                            </div>
                            :
                            <LoadingOverlay
                                active={loading}
                                spinner={true}
                                text="" >
                                {isHTMLView ?
                                    this.props.renderDocumentOnly ?
                                        <div className="grid" style={{ background: 'white', marginRight: 0, paddingRight: 0, maxWidth: 'calc(100vw - 100px)' }}>
                                            {this.renderHTMLView()}
                                        </div>
                                        :
                                        <div className="grid" style={{ background: 'white', maxWidth: 'calc(100vw - 20px)' }}>
                                            {this.renderHTMLView()}
                                            {this.renderSidebar()}
                                        </div>
                                    :
                                    this.props.renderDocumentOnly ?
                                        <div style={{ background: 'white', marginRight: 7, paddingRight: 0, maxWidth: 'calc(100vw - 100px)', marginLeft: 5 }}>
                                            {this.renderPDFView()}
                                        </div>
                                        :
                                        <div className="grid" style={{ background: 'white', marginLeft: '-1.2em' }}>
                                            {this.renderPDFView()}
                                            {this.renderSidebar()}
                                        </div>
                                }
                            </LoadingOverlay>
                        }
                    </>
                }

                <ConceptDetailsDialog
                    visible={this.state.displayConceptInfoDialog}
                    includeQuickSearchShortcut={true}
                    includeChemSearchShortcut={true}
                    includeCoocSearchShortcut={true}
                    ocid={this.state.clickedEntry?.ocid}
                    onConceptClick={this.showConceptInfo}
                    onHide={() => this.setState({ displayConceptInfoDialog: false })}
                />

                <SequenceDetailsDialog
                    displayDialog={this.state.displayNucleotideSequenceDetails}
                    onDisplayDialogChange={(showDialog) => this.setState({ displayNucleotideSequenceDetails: showDialog })}
                    sequenceOcid={singleNucleotideSequence.singleSequenceOCID}
                    sequenceLength={singleNucleotideSequence.singleSequenceLength}
                    sequence={singleNucleotideSequence.singleSequenceWrapped}
                    onExport={this.handleExportSingleFASTANucleotide}
                />

                <SequenceDetailsDialog
                    displayDialog={this.state.displayProteinSequenceDetails}
                    onDisplayDialogChange={(showDialog) => this.setState({ displayProteinSequenceDetails: showDialog })}
                    sequenceOcid={singleProteinSequence.singleSequenceOCID}
                    sequenceLength={singleProteinSequence.singleSequenceLength}
                    sequence={singleProteinSequence.singleSequenceWrapped}
                    onExport={this.handleExportSingleFASTAProtein}
                />
                <OntologyBrowserDialog
                    ontBrowserDialogID="docViewDomBrowserDlg"
                    headerLabel="Domain explorer"
                    selectionMode="multiple"
                    placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                    placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                    domains={this.props.availableDomains}
                    initialSearchTerm={this.state.domainExplorerQuery}
                    initialOcids={this.state.domainExplorerOcids}
                    numOfChildNodes={10} // not used for preloaded ontology
                    allowConceptSearchByClick={true}
                    allowSearchInOntologies={true}
                    loadOntologiesOnStart={true}
                    ontBrowserVisible={this.state.domainBrowserVisible}
                    onOntBrowserClose={this.onDomainExplorerClose}
                    onOntBrowserSubmit={this.onDomainExplorerSubmit}
                    onOntBrowserShow={() => { }}
                    width="90vw"
                    height="90vh"
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    annTypeLabelsMap: state.user.data.userDetails.department.annTypeLabelsMap,
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
    domainColors: state.user.data.userDetails.department.selectedDomains,
    domains: state.user.data.userDetails.department.selectedDomains,
    availableDomains: state.webAPI.availableDomains,
    additionalDocumentData: state.document.additionalDocumentData
})
const mapDispatchToProps = (dispatch) => ({
    onLoading: (loader) => dispatch(setLoaderSentenceAnalysis(loader)),
    onSetSearchLoaded: (loaded) => dispatch(setSearchLoadedSentenceAnalysis(loaded)),
    fetchAdditionalDocumentData: (repoID, docID) => dispatch(fetchAdditionalDocumentData(repoID, docID)),
    updateDocumentRating: (repoID, docID, title, stars) => dispatch(updateDocumentRating(repoID, docID, title, stars)),
    updateDocumentReadStatus: (repoID, docID, title, isRead) => dispatch(updateDocumentReadStatus(repoID, docID, title, isRead)),
    addDocumentToCollections: (repoID, docID, title, docCollectionIDs) => dispatch(addDocumentToCollections(repoID, docID, title, docCollectionIDs)),
    removeDocumentFromCollection: (repoID, docID, docCollID, docCollDocLink) => dispatch(removeDocumentFromCollection(repoID, docID, docCollID, docCollDocLink)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnnotatedDocumentView))
