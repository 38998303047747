export const fieldListForExportBiomarkerInXLSX = [
    {
        "name": "srcsent",
        "type": "termFields"
    },
    {
        "name": "srcrep",
        "type": "termFields"
    },
    {
        "name": "source_link",
        "type": "termFields"
    },
    {
        "name": "source_date",
        "type": "termFields"
    },
    {
        "name": "docView",
        "type": "termFields"
    },
    {
        "name": "details",
        "type": "conceptFields"
    },
    {
        "name": "compound",
        "type": "conceptFields"
    },
    {
        "name": "target",
        "type": "conceptFields"
    },
    {
        "name": "property",
        "type": "conceptFields"
    },
    {
        "name": "unit",
        "type": "conceptFields"
    }
]
