import React from 'react';
import { Button } from "primereact/button";
import './TermMenuButtonGroup.css';
import {useDispatch} from "react-redux";

const TermMenuButtonGroup = ({
                                 occurrence,
                                 ocids,
                                 normalizedTerms,
                                 originalTerms,
                                 hideOverlayPanel, showToast, fieldName, queryTerms, setQueryTerms
                             }) => {
    const dispatch = useDispatch();

    // the entry is a concept (you have normalizedTerms) then you need all 8 filter/exclude possibilities like in the dropdown
    const handleClick = (lookupType) => {
        try {
            const terms = normalizedTerms || originalTerms;
            const item = { fieldName, occurrence, term: [terms] };

            // conceptLookupType is available only for concepts (with ocids)
            // termLookupType is available only for terms (without ocids, only terms)

            if (ocids?.length && (lookupType === 'ONTOLOGICAL' || lookupType === 'EXACT')) {
                item.ocids = ocids;
                item.conceptLookupType = lookupType;
            } else {
                item.ocids = ocids;
                item.termLookupType = lookupType;
            }

            if (queryTerms && queryTerms.length > 0 && item.occurrence === "MUST") {
                let filteredObject = queryTerms.filter(function (entry) {
                    return entry.term[0] === "Any" && entry.domainTerms
                })
                let filteredItemObject = filteredObject.filter(function (entryItem) {
                    return entryItem.fieldName === item.fieldName
                })
                if (filteredItemObject.length > 0) {
                    showToast(`You already used the "Any" filter for this role. The specific term (${terms}) you want to filter will be ignored for your search.`, 'Warning', 'warn');
                } else {
                    showToast(`Term ${terms} successfully added to your search.`);
                }
            } else {
                showToast(`Term ${terms} successfully added to your search.`);
            }


            hideOverlayPanel();
            dispatch(setQueryTerms([...queryTerms, item]))

            //showToast(`Term ${terms} successfully added to your search.`);
        } catch (e) {
            console.error(e);
            showToast(`Something went wrong`, 'Error occurred', 'error');
        }
    }

    return (
        <div id='biomarker-label-term-menu-button'>
            {!!ocids?.length && <>
                <Button label='With Subtree' onClick={() => handleClick('ONTOLOGICAL')} />
                <Button label='Without Subtree' onClick={() => handleClick('EXACT')} />
            </>}
            <Button label='Original Term' onClick={() => handleClick('TERM_NORMALIZED')} />
            {/*<Button label='Text With Variants' onClick={() => handleClick('TEXT_STEMMED')}/>*/}
        </div>
    );
};

export default TermMenuButtonGroup;
