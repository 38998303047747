import { convertStructureToFormatWS, createMatchingCompoundStructuresRequest, createMatchingMergedReactionsRequest, fetchMatchingCompoundStructuresV2, fetchMatchingMergedReactions } from "../../../../api/content/ChemistryApi";
import { APP_PROPERTIES } from "../../../../properties";
import { SEARCH_TYPE_EXACT, SEARCH_TYPE_FULL_MATCH, SEARCH_TYPE_SIMILARITY, SEARCH_TYPE_SUBREACTION, SEARCH_TYPE_SUBSTRUCTURE } from "./chemistryDBs";
import { STRUCTURE_FORMAT_MOL, STRUCTURE_FORMAT_RXN } from "./chemistrySearchConstants";
import { determineStructureFormat } from "./chemistrySearchUtil";
import { extractFilterValues, getSearchTargetParameter } from "./filtersUtil";


export const runStructureSearch = async (searchTarget, chemDB, structure, filterInputValues, format, hitCount, nextPageKey, isExport, filterDefinitions, withMixtures, sortByField, sortDirection) => {
    //console.log('chemDB.service: ', chemDB.service);
    //console.log('456 format: ', format);
    //console.log('456 inp structure: ', structure);

    const { origSearchType, filters } = extractFilterValues(filterInputValues, filterDefinitions);

    switch (chemDB.service) {

        case APP_PROPERTIES.CHEMISTRY.OC:
        case APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI_PRODUCTS: {

            let searchStructure = structure;
            let searchFormat = format;
            switch (format) {
                case STRUCTURE_FORMAT_MOL: {
                    break; // OK
                }
                // mrv
                default: {
                    searchFormat = STRUCTURE_FORMAT_MOL;
                    const response = await convertStructureToFormatWS(structure, format.toLowerCase(), searchFormat);
                    searchStructure = response?.payload;
                    if (!searchStructure) {
                        return response;
                    }
                }
            }

            const request = createMatchingCompoundStructuresRequest(searchStructure, filters, searchFormat, origSearchType, hitCount, nextPageKey, withMixtures, sortByField, sortDirection);
            const response = await fetchMatchingCompoundStructuresV2(request, chemDB, isExport);
            response.queryStructure = searchStructure;
            return response;
        }

        case APP_PROPERTIES.CHEMISTRY.CHEMAXON: {

            const maxNumOfResults = 1000;
            let caSearchType = 'DUPLICATE';
            switch (origSearchType) {
                // CA types: DUPLICATE, FULL, FULL_FRAGMENT, SIMILARITY, SUBSTRUCTURE, SUPERSTRUCTURE
                case SEARCH_TYPE_EXACT: { caSearchType = 'DUPLICATE'; break; }
                case SEARCH_TYPE_FULL_MATCH: { caSearchType = 'FULL'; break; }
                case SEARCH_TYPE_SUBSTRUCTURE: { caSearchType = 'SUBSTRUCTURE'; break; }
                case SEARCH_TYPE_SUBREACTION: { caSearchType = 'FULL_FRAGMENT'; break; } // ???
                case SEARCH_TYPE_SIMILARITY: { caSearchType = 'SIMILARITY'; break; }
                default: // do nothing
            }

            const request = { structure: structure, format: format.toLowerCase(), structureSearchType: caSearchType, maxNumOfResults: maxNumOfResults };
            const response = await fetchMatchingCompoundStructuresV2(request, chemDB); // isExport
            response.queryStructure = structure;
            return response;
        }

        case APP_PROPERTIES.CHEMISTRY.XFACT_DB_RXN:
        case APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI: {

            const { origSearchType, filters } = extractFilterValues(filterInputValues, filterDefinitions);
            const target = getSearchTargetParameter(searchTarget);
            const reactionParts = target ? [target] : null;

            let searchStructure = structure;
            let searchFormat = format;
            switch (format) {
                case STRUCTURE_FORMAT_RXN: {
                    break;
                }
                case STRUCTURE_FORMAT_MOL: {
                    if (origSearchType === SEARCH_TYPE_SUBREACTION) {
                        return { status: 'FAILED', message: 'Please draw a reaction to use subreaction search.' };
                    }
                    break;
                }
                // mrv
                default: {
                    const response = await convertStructureToFormatWS(structure, format.toLowerCase(), STRUCTURE_FORMAT_RXN);
                    searchStructure = response?.payload;
                    if (!searchStructure) {
                        return response;
                    }
                    searchFormat = determineStructureFormat(searchStructure);
                }
            }

            const request = createMatchingMergedReactionsRequest(searchStructure, filters, searchFormat, origSearchType, reactionParts, hitCount, nextPageKey);
            const response = await fetchMatchingMergedReactions(request, chemDB);
            response.queryStructure = structure;
            return response;
        }

        default: // do nothing
    }
}