import { InputText } from "primereact/inputtext";

const FilterTermTextInput = ({
  values,
  onValuesChange,
  style = {},
  placeholder = "",
}) => {
  const value = values?.val?.term || "";

  return (
    <InputText
      value={value}
      style={{ ...style }}
      autoComplete="off"
      onInput={(e) => {
        const valueNew = e.target.value || "";
        const valuesNew = {};
        if (valueNew) {
          valuesNew.term = valueNew;
        }
        onValuesChange({
          val: valuesNew,
          queryPart: valueNew,
          label: valueNew,
        });
      }}
      placeholder={placeholder}
    />
  );
};

export default FilterTermTextInput;
