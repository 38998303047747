/* eslint-disable jsx-a11y/anchor-is-valid */
import { Checkbox } from 'primereact/checkbox';
import { PureComponent } from 'react';
import { isArrayEmpty } from '../../util';
import CompoundsList from '../CompoundsList';


class AnnotatedCompounds extends PureComponent {

    render() {
        const { compoundOcidData, labeledCompoundOcidData, showLabeledCompounds, onShowLabeledCompoundsChange,
            ocidToAnnIdsMap, conceptsData, exportCompoundsFromDocument, fetchingCompounds, annTypeChemStructure,
            jumpToMatch, repID, ocDocID, onClick } = this.props;

        return (
            <>
                {!isArrayEmpty(compoundOcidData) ?
                    <div className="grid">
                        <div className="col">
                            {!isArrayEmpty(labeledCompoundOcidData) ?
                                <div style={{ marginBottom: 5 }}>
                                    <Checkbox
                                        className='valignMiddle'
                                        onChange={onShowLabeledCompoundsChange}
                                        checked={showLabeledCompounds}>
                                    </Checkbox>
                                    <label
                                        className="p-checkbox-label valignMiddle"
                                        htmlFor='labeledCompounds'>
                                        Labeled compounds only ({labeledCompoundOcidData.length})
                                    </label>
                                </div> : null
                            }
                            <div>Sorted by relevance</div>
                        </div>
                        <div className="col textAlignRight" style={{ paddingRight: 10 }}>
                            <a onClick={() => exportCompoundsFromDocument(repID, ocDocID, showLabeledCompounds)}>
                                Download SMI file
                            </a>
                        </div>
                    </div> : null}

                <CompoundsList
                    loading={fetchingCompounds}
                    conceptsData={conceptsData}
                    // test
                    compoundOcidData={showLabeledCompounds ? labeledCompoundOcidData : compoundOcidData}
                    ocidToAnnIdsMap={ocidToAnnIdsMap}
                    annTypeChemStructure={annTypeChemStructure}
                    jumpToMatch={jumpToMatch}
                    loadingMessage="Loading..."
                    emptyMessage="No chemical structures in this document." 
                    onClick={onClick}/>
            </>
        );
    }
}

export default AnnotatedCompounds;