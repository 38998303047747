import { Button } from "primereact/button";
import SeparatorPoint from "../../../../../general/SeparatorPoint";
import {
  SEARCH_GROUP_TYPE_API_SYNTAX,
  SEARCH_GROUP_TYPE_SAVED_SEARCH,
} from "../../../../helpers/constants";
import "./SearchGroupToolbar.css";

const SearchGroupToolbar = ({
  group,
  onSubmitEditSearchGroup,
  onCancelEditSearchGroup,
  onClearSearchGroup,
  onRemoveSearchGroup,
  onTranslate,
  onRunPartialSearch,
  onSavePartialSearch,
  hasError
}) => {
  return (
    <div className="search-group-toolbar">
      {group.type !== SEARCH_GROUP_TYPE_SAVED_SEARCH && (
        <>
          <a onClick={onClearSearchGroup}>Clear</a>
          <SeparatorPoint />
        </>
      )}
      {group.type !== SEARCH_GROUP_TYPE_API_SYNTAX && (
        <>
          <Button
            className="p-button-text primaryButtonAsLink toolbar-link"
            onClick={onTranslate}
            disabled={hasError}
          >
            Show API syntax
          </Button>
          <SeparatorPoint />
        </>
      )}
      <Button
        className="p-button-text primaryButtonAsLink"
        onClick={() => onRunPartialSearch(group)}
        disabled={hasError}
      >
        Run
      </Button>
      <SeparatorPoint />
      {group.type !== SEARCH_GROUP_TYPE_SAVED_SEARCH && (
        <>
          <Button
            className="p-button-text primaryButtonAsLink toolbar-link"
            onClick={onSavePartialSearch}
          >
            Save search
          </Button>
          <SeparatorPoint />
        </>
      )}
      <Button
        className="p-button-text primaryButtonAsLink toolbar-link"
        onClick={onRemoveSearchGroup}
      >
        Remove
      </Button>
      <Button
        label="Cancel"
        className="p-button-sm p-button-secondary toolbar-button ml-5"
        onClick={onCancelEditSearchGroup}
      />
      <Button
        label="Accept changes"
        className="p-button-sm primaryButton toolbar-button ml-3"
        onClick={onSubmitEditSearchGroup}
      />
    </div>
  );
};

export default SearchGroupToolbar;
