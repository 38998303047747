import ConceptDetailsDialog from '../../../conceptdetails/ConceptDetailsDialog';

const BioactivitiesConceptInfoPopup = ({ visible, hideCompoundInfo, conceptOcid, showCompoundInfo }) => {

    return (
        <>
           <ConceptDetailsDialog
                visible={visible}
                includeQuickSearchShortcut={true}
                includeChemSearchShortcut={true}
                includeCoocSearchShortcut={true}
                ocid={conceptOcid}
                onConceptClick={showCompoundInfo}
                onHide={hideCompoundInfo}
            />
        </>
    );
};

export default BioactivitiesConceptInfoPopup;
