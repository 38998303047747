/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { InputText } from 'primereact/inputtext';
import ExportsTable from './components/DataTable';
import { EXPORT_TITLE } from './core/constant';

const ExportList = (props) => {
    const [exports, setExports] = useState(props?.exports)
    const [search, setSearch] = useState('')

    useEffect(() => {
        if (props.selectedCategory === EXPORT_TITLE) {
            setExports(props.exports)
        } else {
            setExports(props.exports?.filter((item) => item?.category === props.selectedCategory))
        }
    }, [props.exports, props.selectedCategory])

    const searchExport = (e) => {
        const searchData = e.target.value;
        if (props.selectedCategory === EXPORT_TITLE) {
            setExports(props.exports?.filter(item => item?.name?.toLowerCase()?.includes(searchData?.toLowerCase())));
        } else {
            setExports(props.exports?.filter(item => item?.name?.toLowerCase()?.includes(searchData?.toLowerCase()) && item?.category === props.selectedCategory));
        }
        setSearch(searchData);
    }



    const updateExports = (exportId) => {
        props.onReload()
    }


    return (
        <div className="col-12 grid-nogutter" style={{ paddingTop: 5 }}>
            <div className={props.showHeader ? 'col-12 pl-0 pt-0' : 'col-12 sm:col-12 md:col-12 lg:col-10 xl:col-7 pl-0 pt-0'} >
                {props.showHeader ?
                    <>
                        <div className='col-12 pl-0 py-0' >
                            <div className='col-12 changeflex ex-list-header-wrapper pb-0 pl-0 pt-0'>
                                <label className='ex-list-header pt-0'
                                    title={props.selectedCategory === EXPORT_TITLE || (exports?.length === 0) ? 'All exports' : `Export collection: ${props.selectedCategory?.charAt(0) + props.selectedCategory?.toLowerCase().slice(1)}`}>
                                    {props.selectedCategory === EXPORT_TITLE || (exports?.length === 0) ? 'All exports' : `Export collection: ${props.selectedCategory?.charAt(0) + props.selectedCategory?.toLowerCase().slice(1)}`}</label>

                            </div>
                        </div>
                        <div className='col-12 sub-label-wrapper ' >
                            <label className='sub-label' title={props.selectedCategory === EXPORT_TITLE || (exports?.length === 0) ? 'All exports in all collections' : `All exports in: ${props.selectedCategory?.charAt(0) + props.selectedCategory?.toLowerCase().slice(1)}`}>
                                {props.selectedCategory === EXPORT_TITLE || (exports?.length === 0) ? 'All exports in all collections' : `All exports in: ${props.selectedCategory?.charAt(0) + props.selectedCategory?.toLowerCase().slice(1)}`}
                            </label>
                        </div>
                    </>
                    : null
                }
                {
                    exports?.length > 0 ?
                        <div className="datatable-responsive-demo-l">
                            <div className={props.showHeader ? 'grid ex-list-search-wrapper' : 'grid ex-list-search-wrapper-noheader'}>
                                <div className="ex-list-search-con">
                                    <i className="material-icons search-icon"
                                        title={props.selectedCategory === EXPORT_TITLE ? "Export in collection" : "Export"}
                                    >
                                        {'search'}
                                    </i>
                                    <InputText
                                        type="search"
                                        value={search}
                                        autoComplete="off"
                                        className="collectionFilter ex-list-search"
                                        onInput={searchExport}
                                        placeholder={props.selectedCategory !== EXPORT_TITLE ? "Export in collection" : "Search"}
                                    />
                                </div>
                            </div>

                            <ExportsTable
                                updateExports={updateExports}
                                exports={exports}
                                onReload={props.onReload}
                                onErrorToast={props.onErrorToast}
                                showReadcubeExports={!props.showHeader}
                            />
                        </div> :
                        <div style={{ paddingTop: 10 }}  >
                            <label style={{ fontWeight: 'bold' }}>
                                {props.selectedCategory === EXPORT_TITLE ? 'No exports available.' : ` No exports in : ${props.selectedCategory}.`}
                            </label>
                        </div>
                }
            </div>

        </div >
    )
}

export default ExportList;
