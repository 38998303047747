import "./Infos.css";
import InfoOverlayWithIcon from "../../../webapi/general/InfoOverlayWithIcon";

const TextProximityInfo = () => {
  return (
    <InfoOverlayWithIcon
      infoID="textProximityInfo"
      title="Click for information about text proximity"
      imageAltText="Information about text proximity"
      overlayContent={
        <div className="info-text-container">
          <h1>Text proximity</h1>
          <p>
            The text proximity value defines the maximum distance of words.
            Without a text proximity value the text matches only in the exact
            word order.
          </p>
          <hr />
          <h2>Example</h2>
          <p>
            Exact text: progressive fibrosis
            <br />
            Text proximity: 5
            <br />
            <br />
            This will find e.g. "progressive fibrosis", "pregressive renal
            fibrosis", "progressive renal and pulmonary fibrosis" or "fibrosis
            being progressive".
          </p>
          <hr />
          <h2>Example</h2>
          <p>
            Text with variants: matrix amorph
            <br />
            Text proximity: 2
            <br />
            <br />
            This will find "amorphous matrix", "amorphous ceramic matrix",
            "amorphous host matrix" and "matrix was amorphous".
          </p>
        </div>
      }
    />
  );
};

export default TextProximityInfo;
