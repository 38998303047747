import { NotificationsActions } from '../actions/NotificationsAdtions'
const initialState ={}

const  notificationReducer = (state = initialState, action) => {
  switch (action.type) {
 
  
    case NotificationsActions.SetNotifications:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          allNotifications: action.payload.notifications,
        },
      };
    case NotificationsActions.SetActiveNotification:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          activeNotification: action.payload.activeNotification,
        },
      };
      default:
      return state;
  }
};

export default notificationReducer;
