import { BiSolidMessageError } from "react-icons/bi";
import { PiPlayFill, PiCircleFill } from "react-icons/pi";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef, useState } from "react";

const SearchGroupStatus = ({
  isStartGroup,
  isReachable,
  showErrorsOnly,
  errorMessage,
  size = 20,
}) => {
  const op = useRef(null);

  const [message, setMessage] = useState("");

  return (
    <>
      <span className="search-group-status">
        {/*{ TODO: simplify code by removing nested ternary expression }*/}
        {showErrorsOnly ? (
          errorMessage ? (
            <span
              className="error-message-icon"
              onClick={(e) => {
                setMessage(errorMessage);
                op.current.show(e);
              }}
              onMouseOut={(e) => {
                op.current.hide(e);
              }}
            >
              <BiSolidMessageError
                size={size}
                title="Click to see error message"
              />
            </span>
          ) : null
        ) : (
          <span style={{ color: "#444444" }}>
            {isStartGroup ? (
              <PiPlayFill
                size={size}
                title="Starting group for search. Search groups that are not reachable from this group, will not be part of the search."
                style={{ width: 16, height: 16, marginLeft: 2 }}
              />
            ) : isReachable ? (
              <PiCircleFill
                size={size}
                title="Search group is part of your search."
                style={{ height: 10, width: 10, marginLeft: 5 }}
              />
            ) : (
              <span
                className="info-message-icon"
                onClick={(e) => {
                  setMessage(
                    "Search group is defined, but never used. You can use a logic group to reference this group in your search."
                  );
                  op.current.show(e);
                }}
                onMouseOut={(e) => {
                  op.current.hide(e);
                }}
              >
                <BiSolidMessageError
                  size={size}
                  title="Unreachable group. Click to see details."
                  style={{ height: 20, width: 20, color: "#222222" }}
                />
              </span>
            )}
          </span>
        )}
      </span>
      <OverlayPanel
        ref={(el) => {
          op.current = el;
        }}
      >
        <span>{message}</span>
      </OverlayPanel>
    </>
  );
};

export default SearchGroupStatus;
