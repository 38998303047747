import { TabPanel, TabView } from "primereact/tabview";

const PropertiesHelp = () => {
    return (
        <TabView>
            <TabPanel header="Properties">
                <div className="query-examples">
                    <h4>Date ranges</h4>
                    <div className="paragraph">
                        Supported date formats are:
                        <div className="query-note">YYYY-MM-DD or YYYYMMDD or YYYY-MM or YYYYMM or YYYY</div>
                        Date ranges can have a start and an end date separated by a "_". Either start or end date are optional.
                        <div className="query-note">STARTDATE_ENDDATE or STARTDATE or _ENDDATE</div>
                        A specific date or year can be prefixed with "#".
                        <div className="query-note">#DATE</div>
                    </div>
                    <div className="paragraph">
                        Examples:
                        <div className="query-example">ppriodate:"2010_2012"</div>
                        <div className="query-example-info">finds documents (in this case only patents) with a priority claim date between 2010 and 2012.</div>
                        <div className="query-example">pdate:"#2021"</div>
                        <div className="query-example-info">finds documents published in the year 2021.</div>
                    </div>
                    <h5>Publication date</h5>
                    <div className="paragraph">
                        Publication date is the earliest date a document was published. Usually it is the E-publication date.
                        <div className="query-example">pdate:"2021-11-29"</div>
                        <div className="query-example-info">finds documents from 2021-11-29 until now.</div>
                    </div>
                    <h5>Insertion date</h5>
                    <div className="paragraph">
                        Insertion date is the date a document was processed and first added to our system.
                        It can be very different from the publication date, e.g. if a new repository was just added to the application.
                        <div className="query-example">instime:"_2022-06"</div>
                        <div className="query-example-info">finds documents which were added to our system until June 2022.</div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    )
}

export default PropertiesHelp;