/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class ClearQuerycache extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAbbreviation: '',
            selectedName: '',
            selectedLabel: '',
            clearQCChecked: false
        }
    }

    componentDidMount() {

        //console.log(this.props)

        let selectedAbbreviation = this.props.selectedAbbreviation ? this.props.selectedAbbreviation : ''
        let selectedLabel = this.props.selectedLabel ? this.props.selectedLabel : ''
        let selectedName = this.props.selectedName ? this.props.selectedName : ''

        this.setState({
            selectedAbbreviation: selectedAbbreviation,
            selectedLabel: selectedLabel,
            selectedName: selectedName,
        })
    }

    onClearQC = () => {
        this.props.clearQC(this.state.selectedName)
    }

    onCheckClearQC = (e) => {
        if (e.checked) {
            this.setState({
                clearQCChecked: true
            })
        } else {
            this.setState({
                clearQCChecked: false
            })
        }
    }

    onCancelClearQC = () => {
        this.setState({
            clearQCChecked: false
        }, () => {
            this.props.onCancelClearQC()
        })
    }

    render() {

        const footerClearQC = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Clear"
                    className='p-button-sm p-button-danger'
                    disabled={this.state.clearQCChecked ? false : true}
                    onClick={this.onClearQC}
                />
                <Button label="Cancel"
                    className='p-button-sm p-button-secondary'
                    onClick={this.onCancelClearQC}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayDialogClearQC} style={{ 'width': "40vw" }} header="Clear querycache" focusOnShow={false}
                modal={true}
                footer={footerClearQC}
                dismissableMask={false}
                onHide={() => this.onCancelClearQC()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div style={{ marginBottom: 10 }}>
                        <label>{`This will clear the querycache of the selected backend : ${this.state.selectedLabel} (${this.state.selectedName}, ${this.state.selectedAbbreviation}).`}</label>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <Checkbox inputId='clearQCChecked'
                            onChange={(e) => this.onCheckClearQC(e)}
                            checked={this.state.clearQCChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                        />
                        <label htmlFor='clearQCChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default ClearQuerycache