import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { RESPONSE_STATUS_FAILED, RESPONSE_STATUS_LOGGED_OUT, RESPONSE_STATUS_SUCCESS } from '../../../properties';


const ResponseGrowl = ({
  response,
  showErrorMessage = true,
  showSuccessMessage = false,
  errorSummary,
  errorMessage,
  successSummary,
  successMessage,
  life,
  sticky,
  closable,
}) => {

  const growl = useRef(null);

  useEffect(() => {

    const growlMessage = {
      sticky: sticky,
      closable: closable
    };
    if (life) { growlMessage.life = life }

    if (response?.status === RESPONSE_STATUS_LOGGED_OUT) {
      const summary = 'Not authenticated';
      const message = 'You are not logged in.';
      growl.current.show({ ...growlMessage, severity: 'warn', summary: summary, detail: message });
    }
    else if (showErrorMessage && response?.status === RESPONSE_STATUS_FAILED) {
      const summary = errorSummary || 'Error occurred';
      const message = errorMessage || response.message || 'Unknown error occurred';
      growl.current.show({ ...growlMessage, severity: 'error', summary: summary, detail: message });
    }
    else if (showSuccessMessage && response?.status === RESPONSE_STATUS_SUCCESS) {
      const summary = successSummary || 'Success';
      const message = successMessage || 'Action successful';
      growl.current.show({ ...growlMessage, severity: 'success', summary: summary, detail: message });
    }

  }, [response]);

  return <Toast ref={growl} />;
}

export default ResponseGrowl;