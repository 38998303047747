import {convertInitialLetterToUpperCase, isArrayEmpty} from "../../../../../util";
import ExternalLinkIcon from '../../../../../general/ExternalLinkIcon/ExternalLinkIcon';

const Publication = ({documentData}) => (
    <div style={{marginTop: 5, marginRight: 15}}>
            <span style={{marginRight: 0}}>
                {!!documentData.journal &&
                <span title="Journal">
                        {documentData.journal}
                    </span>
                }

                {!documentData.journal && !isArrayEmpty(documentData.source_title) &&
                <span title="Source">
                        {`${documentData.pubdate && !documentData.startdate && !!documentData.journal ? ', ' : ''}${documentData.source_title[0]}`}
                    </span>
                }

                {!isArrayEmpty(documentData.pubType) &&
                <span title="Publication type">
                        {`${isArrayEmpty(documentData.source_title) ? '' : ' - '}${convertInitialLetterToUpperCase(documentData.pubType[0])}`}
                    </span>
                }
            </span>

        {!isArrayEmpty(documentData.doi) &&
        <span style={{marginRight: 15}}>
                                <span> · </span>
                                <a className="secondaryLink"
                                   style={{color: '#757575'}}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   href={`https://doi.org/${documentData.doi[0]}`}>
                                    {`https://doi.org/${documentData.doi[0]}`} 
                                    <ExternalLinkIcon standardLinkIcon={true} />
                                </a>
                            </span>
        }
    </div>
);


export default Publication;
