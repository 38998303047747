import React, { Component, createRef } from 'react';
import { Button } from 'primereact/button';
import SyntaxEditor from './SyntaxEditor';
import * as OntologyClassUtil from '../../util/OntologyClassUtil';
import * as PhraseTokenUtil from '../../util/PhraseTokenUtil';
import { cloneDeep } from '../../../util';
import { checkResultAndGetPayload } from '../../../../../api';
import { translatePhraseTokensToSyntaxPatternString } from '../../../../../api/content/FrameEditorApi';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

/*const levelSelectItems = [
    { label: "1 (default)", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
];*/


class EditSyntaxTagBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.growl = createRef();
        //this.receivedNewInputTag(props.tag);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.tag !== nextProps.tag) {

            let newTag;
            if (nextProps.tag) {
                newTag = cloneDeep(nextProps.tag);
                newTag.phraseTokens = newTag.phraseTokens ? newTag.phraseTokens : [];
            }
            else {
                newTag = OntologyClassUtil.createSyntaxTag();
                newTag.phraseTokens = [];
            }
            this.syntaxEditor.setPhraseTokens(newTag.phraseTokens);

            this.setState({
                tag: newTag
            });
        }
    }


    onEditTagSubmit = async () => {

        const newTag = this.state.tag;
        const phraseTokens = this.syntaxEditor.getPhraseTokens();

        if (!phraseTokens || phraseTokens.length === 0) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: 'Empty syntax not allowed.' });
        }
        else {
            // --- check if syntax has at least one role --- //
            let hasRole = false;
            for (const token of phraseTokens) {
                if (PhraseTokenUtil.hasTokenType(token, PhraseTokenUtil.PHRASE_TOKEN_TYPE_ROLE_REFERENCE) ||
                    PhraseTokenUtil.hasTokenAttribute(token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ROLE)) {
                    hasRole = true;
                    break;
                };
            }

            if (!hasRole) {
                this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'No role defined.', detail: 'Each syntax must have at least one role. Add a role reference to your syntax or a role to an existing token.' });
                return;
            }

            const response = await translatePhraseTokensToSyntaxPatternString(phraseTokens, this.props.history);
            //console.log('response: ', response);
            const parsedSyntaxObj = checkResultAndGetPayload(response, this.growl);

            if (parsedSyntaxObj) {
                const syntaxStrg = (parsedSyntaxObj && parsedSyntaxObj.parsedSyntax) ? parsedSyntaxObj.parsedSyntax : '';
                //console.log('syntaxStrg: ', syntaxStrg);

                newTag.phraseTokens = phraseTokens;
                newTag.value = syntaxStrg;

                this.props.onEditTagSubmit(newTag);
            }
        }
    }


    render() {

        const { contentBoxHeight } = this.props;

        //console.log('tag: ', tag);

        return (
            <>
                <Toast ref={this.growl} appendTo={document.body} />

                <div className="task-box task-box-dark-blue" style={{ padding: 0, background: 'white' }}> {/*20 #676767*/}
                    <div className="task-box-header">
                        Edit syntax entry
                </div>
                    <div className="task-box-content"
                        style={{
                            padding: '10px', height: contentBoxHeight, overflow: 'auto'
                        }}>
                        <div className="grid" style={{ padding: '10px' }}> {/*  style={{ minWidth: '60wv', minHeight: '70vh',  maxHeight: '90vh',  overflow: 'auto' }} */}

                            <div className="col-12" style={{ marginBottom: 5, paddingBottom: 0, paddingTop: 10 }}>
                                <div className="grid">
                                    <div className="col-fixed" style={{ width: 80 }}>
                                        Level:
                                </div>
                                    <div className="col">
                                        {/* <Dropdown
                                        value={OntologyClassUtil.getLevelFromTag(this.state.tag)}
                                        options={levelSelectItems}
                                        onChange={(e) => {
                                            const newTag = { ...this.state.tag };
                                            OntologyClassUtil.setLevelInTag(newTag, e.value);
                                            this.setState({ tag: newTag })
                                        }}
                                        placeholder="Select level (optional)"
                                        filter={true}
                                        filterBy="label"
                                    />
                                    */}
                                        <InputText
                                            value={OntologyClassUtil.getLevelFromTag(this.state.tag)}
                                            onChange={(e) => {
                                                const newTag = { ...this.state.tag };
                                                OntologyClassUtil.setLevelInTag(newTag, e.target.value);
                                                this.setState({ tag: newTag })
                                            }}
                                            keyfilter={/^[0-9]*$/}
                                            placeholder="1 (default)"
                                        />
                                    </div>
                                    <div className="col-12">
                                    </div>
                                    <div className="col-fixed" style={{ width: 80 }}>
                                        Syntax:
                                </div>
                                    <div className="col">
                                        <SyntaxEditor
                                            //style={{ margin: 15 }}
                                            ref={(ref) => { this.syntaxEditor = ref; }}

                                            type={OntologyClassUtil.TAG_TYPE_SYNTAX}

                                            domains={this.props.domains}
                                            roles={this.props.roles}

                                            roleSelectItems={this.props.roleSelectItems}
                                            synsetSelectItems={this.props.synsetSelectItems}
                                            macroSelectItems={this.props.macroSelectItems}
                                            frameSelectItems={this.props.frameSelectItems}

                                            contentBoxHeight={this.props.editorHeight}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12" style={{ textAlign: 'right' }}>
                                <Button label="Cancel"
                                    className="p-button-secondary p-button-sm"
                                    onClick={() => {
                                        this.syntaxEditor.onClearSyntaxEditor();
                                        this.props.onEditTagCancel()
                                    }} >
                                </Button>
                                <Button label="Confirm"
                                    className="primaryButton p-button-sm"
                                    onClick={(e) => this.onEditTagSubmit()}>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EditSyntaxTagBox;