import { Button } from "primereact/button";

const SearchExample = ({
    example,
    onClick,
}) => {
    return <div>
        {example &&
            <>
                <Button
                    className="search-example-link p-button-text primaryButtonAsLink"
                    onClick={(e) => onClick(example, e)}>
                    {example.label}
                </Button>
                {example.description &&
                    <div className="search-example-description">
                        <span className="secondaryInfo">
                            {example.description}
                        </span>
                    </div>}
            </>
        }
    </div>
}

export default SearchExample;