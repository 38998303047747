/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Chips } from 'primereact/chips';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { OverlayPanel } from "primereact/overlaypanel";
import infoIcon from "../../assets/images/icons/info/dimensions-icon-info.png";
import { APP_PROPERTIES } from '../../properties/index';
import LogoUpload from '../common/LogoUpload/LogoUpload';

class AddCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFeatures: [],
            allRepositoriesChecked: false,
            allHighlightedDomainsChecked: false,
            allSearchableDomainsChecked: false,
            selectedRepositories: [],
            selectedHighlightDomains: [],
            selectedSearchDomains: [],
            mouseOverRepositoryButton: false,
            newCompanyName: '',
            newCompanyApplicationName: '',
            newCompanyChecked: true,
            maxUsers: '',
            maxDownloads: null,
            featName: '',
            companyLandingPage: 'docsearch',
            applicationHeader1: '',
            applicationHeader2: '',
            applicationHeader3: '',
            backgroundColor: '',
            fontColor: '',
            logo: '',
            customHeaderChecked: false,
            headerImage: APP_PROPERTIES.HEADER_LOGO_APP,
            pictures: [],
            emailDomains: [],
            displayDefaultFeatures: false
        }
        this.hiddenRepoStates = {}
    }

    componentDidMount() {
        let selected = [...this.state.selectedFeatures]
        this.props.allAvailableFeatures && this.props.allAvailableFeatures.forEach(feat => {
            if (feat.isDefault) {
                selected.push(feat.name)
            }
        })
        this.setState({
            selectedFeatures: selected
        })
    }

    updateCompanyName = (name) => {
        this.setState({
            newCompanyName: name
        })
    }

    updateCompanyApplicationName = (name) => {
        this.setState({
            newCompanyApplicationName: name
        })
    }

    updateCompanyEmailDomains = (domains) => {
        this.setState({
            emailDomains: domains
        })
    }

    updateMaxUsers = (max) => {
        this.setState({
            maxUsers: max
        })
    }

    updateMaxDownloads = (max) => {
        this.setState({
            maxDownloads: max
        })
    }

    updateApplicationHeader1 = (name) => {
        this.setState({
            applicationHeader1: name
        })
    }

    updateApplicationHeader2 = (name) => {
        this.setState({
            applicationHeader2: name
        })
    }

    updateApplicationHeader3 = (name) => {
        this.setState({
            applicationHeader3: name
        })
    }

    updateBackgroundColor = (color) => {
        this.setState({
            backgroundColor: color
        })
    }

    updateFontColor = (color) => {
        this.setState({
            fontColor: color
        })
    }

    onSaveNewCompany = () => {
        this.props.onAddCompany(this.state.selectedHighlightDomains, this.state.newCompanyApplicationName, this.state.selectedRepositories, this.state.newCompanyChecked,
            this.state.selectedSearchDomains, this.state.selectedFeatures, this.state.maxUsers, this.state.newCompanyName, this.state.companyLandingPage, this.state.customHeaderChecked, this.state.applicationHeader1,
            this.state.applicationHeader2, this.state.applicationHeader3, this.state.backgroundColor, this.state.fontColor, this.state.headerImage, APP_PROPERTIES.HEADER_LOGO_APP, this.state.emailDomains,
            this.state.maxDownloads)
        this.onCancelAddCompany()
    }

    onAvailableRepositoriesChange = (e, id) => {
        let selected = [...this.state.selectedRepositories]
        if (e.checked) {
            selected.push(id)
        } else {
            selected.splice(selected.indexOf(id), 1)
        }
        this.setState({
            selectedRepositories: selected
        })
    }

    onAvailableFeaturesChange = (e, id) => {
        if ((id === this.state.companyLandingPage) && e.checked === false) {
            this.setState({
                companyLandingPage: 'docsearch'
            })
        }

        let selected = [...this.state.selectedFeatures]
        if (e.checked) {
            selected.push(id)
        } else {
            selected.splice(selected.indexOf(id), 1)
        }
        this.setState({
            selectedFeatures: selected
        })
    }

    onHighlightDomainsChange = (e, value) => {
        let selected = [...this.state.selectedHighlightDomains]
        if (e.checked) {
            selected.push(value)
        } else {
            selected.splice(selected.indexOf(value), 1)
        }
        this.setState({
            selectedHighlightDomains: selected
        })
    }

    onSearchDomainsChange = (e, value) => {
        let selected = [...this.state.selectedSearchDomains]
        if (e.checked) {
            selected.push(value)
        } else {
            selected.splice(selected.indexOf(value), 1)
        }
        this.setState({
            selectedSearchDomains: selected
        })
    }

    onCheckCustomHeader = (e) => {
        if (e.checked) {
            this.setState({
                customHeaderChecked: true
            })
        } else {
            this.setState({
                customHeaderChecked: false
            })
        }
    }

    onCheckAllRepos = (e) => {
        if (e.checked) {
            let allCheckedRepositories = []
            this.props.allAvailableRepositories.forEach(rep => {
                allCheckedRepositories = [...allCheckedRepositories, rep.id]

            })
            this.setState({
                allRepositoriesChecked: true,
                selectedRepositories: allCheckedRepositories
            })
        } else {
            this.setState({
                allRepositoriesChecked: false,
                selectedRepositories: []
            })
        }
    }

    onCheckAllFeatures = (e) => {
        if (e.checked) {
            let allCheckedFeatures = []
            this.props.allAvailableFeatures.forEach(feat => {
                allCheckedFeatures = [...allCheckedFeatures, feat.name]

            })
            this.setState({
                allRepositoriesChecked: true,
                selectedFeatures: allCheckedFeatures
            })
        } else {
            this.setState({
                allRepositoriesChecked: false,
                selectedFeatures: []
            })
        }
    }

    onCheckAllHighlightedDomains = (e) => {
        if (e.checked) {
            let allSelectedHighlightDomains = []
            this.props.allDomainsHighlightItems.forEach(dom => {
                allSelectedHighlightDomains = [...allSelectedHighlightDomains, dom.name]

            })
            this.setState({
                allHighlightedDomainsChecked: true,
                selectedHighlightDomains: allSelectedHighlightDomains
            })
        } else {
            this.setState({
                allHighlightedDomainsChecked: false,
                selectedHighlightDomains: []
            })
        }
    }

    onCheckAllSearchableDomains = (e) => {
        if (e.checked) {
            let allSelectedSearchDomains = []
            this.props.allDomainsSearch.forEach(dom => {
                allSelectedSearchDomains = [...allSelectedSearchDomains, dom.name]

            })
            this.setState({
                allSearchableDomainsChecked: true,
                selectedSearchDomains: allSelectedSearchDomains
            })
        } else {
            this.setState({
                allSearchableDomainsChecked: false,
                selectedSearchDomains: []
            })
        }
    }

    enterButtonFeature = (name) => {
        this.setState({
            mouseOverFeatureButton: true,
            featName: name
        })
    }

    leaveButtonFeature = () => {
        this.setState({
            mouseOverFeatureButton: false
        })
    }

    setLandingPage = (name) => {
        this.setState({
            companyLandingPage: name
        })
    }

    handleImageUpload = (image) => {
        this.setState({ headerImage: image })
    }

    onCancelAddCompany = () => {
        this.setState({
            selectedFeatures: [],
            allRepositoriesChecked: false,
            allHighlightedDomainsChecked: false,
            allSearchableDomainsChecked: false,
            selectedRepositories: [],
            selectedHighlightDomains: [],
            selectedSearchDomains: [],
            mouseOverRepositoryButton: false,
            newCompanyName: '',
            newCompanyApplicationName: '',
            newCompanyChecked: true,
            maxUsers: '',
            maxDownloads: null,
            featName: '',
            companyLandingPage: 'docsearch',
            applicationHeader1: '',
            applicationHeader2: '',
            applicationHeader3: '',
            backgroundColor: '',
            fontColor: '',
            logo: '',
            customHeaderChecked: false,
            headerImage: APP_PROPERTIES.HEADER_LOGO_APP,
            pictures: [],
            emailDomains: [],
            displayDefaultFeatures: false
        }, () => {
            this.props.onCancelAddCompany()
        })
    }

    render() {

        let allBackends = {}
        APP_PROPERTIES.ACTIVE_PAGES.backendAdministration && this.props.allBackends && this.props.allBackends.forEach(be => {
            if (be.type === 'webapi' || be.type === 'chemistry') {
                allBackends[be.id] = []
                this.props.allAvailableRepositories && this.props.allAvailableRepositories.forEach(rep => {
                    if (rep.backendId === be.id && !rep.active) {
                        allBackends[be.id] = [...allBackends[be.id], rep]
                    }
                })
                if (allBackends[be.id] && allBackends[be.id].length > 0) {
                    if (this.hiddenRepoStates[be.id] === undefined) {
                        this.hiddenRepoStates[be.id] = false
                    }
                }
            }
        })

        let allAvailableRepositories = this.props.allBackends && APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ? this.props.allBackends.map(be =>
            (be.type === 'webapi' || be.type === 'chemistry') &&
            <div key={`${be.name}`}>
                <div style={{ marginTop: 25, marginBottom: 10, borderTop: be.type === 'chemistry' ? '1px solid #d6d6d6' : 'none', paddingTop: be.type === 'chemistry' ? 25 : 0 }}>
                    <label
                        title={`Backend label: ${be.label} | Backend name: ${be.name} | Backend ID: ${be.id}`}
                        style={{ fontWeight: 'bold' }}>{`${be.label} (${be.name}, ${be.id})`}</label>
                </div>
                {this.props.allAvailableRepositories && this.props.allAvailableRepositories.map(rep =>
                    rep.backendId === be.id && ((this.hiddenRepoStates[be.id] === false && rep.active) || this.hiddenRepoStates[be.id] === true || this.hiddenRepoStates[be.id] === undefined) &&
                    <div className='col-12'
                        key={`${rep.id}`} style={{ paddingLeft: 0 }}>
                        <Checkbox inputId={`${rep.id}`}
                            onChange={(e) => this.onAvailableRepositoriesChange(e, rep.id)}
                            checked={this.state.selectedRepositories.includes(rep.id) ? true : false}
                            value={rep.id} style={{ 'cursor': 'pointer' }}
                        />
                        <label htmlFor={`${rep.id}`}
                            className={`p-checkbox-label ${!rep.active ? 'inactiveEntry' : ''}`}
                            title={`Repository label: ${rep.label} | Repository name: ${rep.name} | Repository ID: ${rep.id}`}>
                            {`${rep.label} (${rep.name}, ${rep.id})`} {!rep.active ? '(inactive)' : ''}</label>
                    </div>
                )
                }
                {
                    allBackends[be.id].length > 0 && this.hiddenRepoStates[be.id] === false ?
                        <div className='col-12'
                            key={`${be.id}`} style={{ paddingLeft: 0 }}>
                            <a onClick={() => this.hiddenRepoStates[be.id] = true}>Show inactive repositories ({allBackends[be.id].length})</a>
                        </div>
                        : allBackends[be.id].length > 0 && this.hiddenRepoStates[be.id] === true ?
                            <div className='col-12'
                                key={`${be.id}`} style={{ paddingLeft: 0 }}>
                                <a onClick={() => this.hiddenRepoStates[be.id] = false}>Hide inactive repositories ({allBackends[be.id].length})</a>
                            </div>
                            : null
                }
            </div>
        )
            :
            this.props.allAvailableRepositories && this.props.allAvailableRepositories.map(rep =>
                <div className='col-12'
                    key={`${rep.id}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${rep.id}`}
                        onChange={(e) => this.onAvailableRepositoriesChange(e, rep.id)}
                        checked={this.state.selectedRepositories.includes(rep.id) ? true : false}
                        value={rep.id} style={{ 'cursor': 'pointer' }}
                    />
                    <label htmlFor={`${rep.id}`}
                        className={`p-checkbox-label ${!rep.active ? 'inactiveEntry' : ''}`}
                        title={`Repository label: ${rep.label} | Repository name: ${rep.name} | Repository ID: ${rep.id}`}>
                        {`${rep.label} (${rep.name}, ${rep.id})`} {!rep.active ? '(inactive)' : ''}</label>
                </div>
            )


        let allAvailableFeatures = this.props.allAvailableFeatures && this.props.allAvailableFeatures.map(feat =>
            (feat.name === 'bulkexport' || feat.name === 'docview' || feat.name === 'support' || feat.name === 'feedback' || feat.name === 'readcubeview')
                && (!feat.isDefault)
                ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label className='p-checkbox-label'>{`${feat.label}`}</label>
                </div>
                : null
        )

        let generalApps = this.props.allAvailableFeatures && this.props.allAvailableFeatures.map(feat =>
            (feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'reactionsearch' && feat.name !== 'support' && feat.name !== 'feedback')
                && (feat.name === 'querybuilder' || feat.name === 'dashboard' || feat.name === 'changelog' || feat.name === 'docsearch'
                    || feat.name === 'manage') && !feat.isDefault
                ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label onMouseLeave={() => this.leaveButtonFeature()} onMouseEnter={() => this.enterButtonFeature(feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.companyLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.companyLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback'
                            && feat.name !== 'support' && feat.name !== this.state.companyLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={() => this.setLandingPage(feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }</label>
                </div>
                : null
        )

        let analyticApps = this.props.allAvailableFeatures && this.props.allAvailableFeatures.map(feat =>
            (feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'reactionsearch' && feat.name !== 'support' && feat.name !== 'feedback')
                && (feat.name === 'sentenceanalysis' || feat.name === 'domainexplorer' || feat.name === 'pathsearch' || feat.name === 'semanticexport' || feat.name === 'coocs' || feat.name === 'biomarker' || feat.name === 'trendanalysis' || feat.name === 'bioactivity')
                && !feat.isDefault ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label onMouseLeave={() => this.leaveButtonFeature()} onMouseEnter={() => this.enterButtonFeature(feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.companyLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.companyLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback' && feat.name !== 'semanticexport'
                            && feat.name !== 'support' && feat.name !== this.state.companyLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={() => this.setLandingPage(feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }</label>
                </div>
                : null
        )

        let chemApps = this.props.allAvailableFeatures && this.props.allAvailableFeatures.map(feat =>
            (feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'support' && feat.name !== 'feedback')
                && (feat.name === 'chemsearch' || feat.name === 'compoundsearch' || feat.name === 'sequencesearch'
                    || feat.name === 'sequenceexport' || feat.name === 'reactionsearch') && !feat.isDefault
                ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label onMouseLeave={() => this.leaveButtonFeature()} onMouseEnter={() => this.enterButtonFeature(feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.companyLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.companyLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback' && feat.name !== 'sequenceexport'
                            && feat.name !== 'support' && feat.name !== this.state.companyLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={() => this.setLandingPage(feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }</label>
                </div>
                : null
        )

        let userApps = this.props.allAvailableFeatures && this.props.allAvailableFeatures.map(feat =>
            (feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'reactionsearch' && feat.name !== 'support' && feat.name !== 'feedback')
                && (feat.name === 'alerts' || feat.name === 'watchlists' || feat.name === 'blacklists' || feat.name === 'library'
                    || feat.name === 'readdocuments' || feat.name === 'apps') && !feat.isDefault
                ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'pointer' }}
                    />
                    <label onMouseLeave={() => this.leaveButtonFeature()} onMouseEnter={() => this.enterButtonFeature(feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.companyLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.companyLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback'
                            && feat.name !== 'support' && feat.name !== this.state.companyLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={() => this.setLandingPage(feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }</label>
                </div>
                : null
        )

        let defaultApps = this.props.allAvailableFeatures && this.props.allAvailableFeatures.map(feat =>
            (feat.name !== 'tutorials' && feat.name !== 'history' && feat.name !== 'manual' && feat.name !== 'infopage' && feat.name !== 'bulkexport' && feat.name !== 'docview' && feat.name !== 'reactionsearch' && feat.name !== 'support' && feat.name !== 'feedback')
                && feat.isDefault
                ?
                <div className='col-12'
                    key={`${feat.name}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${feat.name}`}
                        onChange={(e) => this.onAvailableFeaturesChange(e, feat.name)}
                        checked={this.state.selectedFeatures.includes(feat.name) || feat.isDefault ? true : false}
                        disabled={feat.isDefault ? true : false}
                        value={feat.label} style={{ 'cursor': 'default' }}
                    />
                    <label onMouseLeave={() => this.leaveButtonFeature()} onMouseEnter={() => this.enterButtonFeature(feat.name)}
                        className='p-checkbox-label' style={{ marginRight: 10, fontWeight: feat.name === this.state.companyLandingPage ? 'bold' : 'normal' }}>{feat.name === this.state.companyLandingPage ? `${feat.label} (Landing page)` : `${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && feat.name !== 'docview' && feat.name !== 'feedback'
                            && feat.name !== 'support' && feat.name !== this.state.companyLandingPage && (this.state.selectedFeatures.includes(feat.name) || feat.isDefault) &&
                            <a id="featLanding"
                                onClick={() => this.setLandingPage(feat.name)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Set landing page</a>
                        }</label>
                </div>
                : null
        )

        let allDomainsHighlight = this.props.allDomainsHighlightItems && this.props.allDomainsHighlightItems.map(dom =>
            <div className='col-12'
                key={`${dom.name}`} style={{ paddingLeft: 0 }}>
                <Checkbox inputId={`${dom.name}`}
                    onChange={(e) => this.onHighlightDomainsChange(e, dom.name)}
                    checked={this.state.selectedHighlightDomains.includes(dom.name) ? true : false}
                    value={dom.name} style={{ 'cursor': 'pointer' }}
                />
                <label htmlFor={`${dom.name}`} className={`p-checkbox-label ${!dom.active ? 'inactiveEntry' : ''}`}>
                    {`${dom.label}`} {!dom.active ? '(inactive)' : ''}</label>
            </div>
        )

        let allDomainsSearch = this.props.allDomainsSearch && this.props.allDomainsSearch.map(dom =>
            <div className='col-12'
                key={`${dom.name}`} style={{ paddingLeft: 0 }}>
                <Checkbox inputId={`${dom.name}`}
                    onChange={(e) => this.onSearchDomainsChange(e, dom.name)}
                    checked={this.state.selectedSearchDomains.includes(dom.name) ? true : false}
                    value={dom.name} style={{ 'cursor': 'pointer' }}
                />
                <label htmlFor={`${dom.name}`} className={`p-checkbox-label ${!dom.active ? 'inactiveEntry' : ''}`}>
                    {`${dom.label}`} {!dom.active ? '(inactive)' : ''}</label>
            </div>
        )

        let numberApplicationsHeaders = ((this.state.applicationHeader1 !== '' && this.state.applicationHeader2 === '' && this.state.applicationHeader3 === '')
            || (this.state.applicationHeader2 !== '' && this.state.applicationHeader1 === '' && this.state.applicationHeader3 === '')
            || (this.state.applicationHeader3 !== '' && this.state.applicationHeader1 === '' && this.state.applicationHeader2 === '')) ? 1 :
            ((this.state.applicationHeader1 !== '' && this.state.applicationHeader2 !== '' && this.state.applicationHeader3 === '')
                || (this.state.applicationHeader2 !== '' && this.state.applicationHeader1 === '' && this.state.applicationHeader3 !== '')
                || (this.state.applicationHeader3 !== '' && this.state.applicationHeader1 !== '' && this.state.applicationHeader2 === '')) ? 2 :
                (this.state.applicationHeader3 !== '' && this.state.applicationHeader1 !== '' && this.state.applicationHeader2 !== '') ? 3 :
                    0

        return (
            <Dialog visible={this.props.displayAddCompany} style={{ 'width': "70vw" }} header="Add new organization" focusOnShow={false}
                modal={true}
                dismissableMask={false} blockScroll
                onHide={() => this.onCancelAddCompany()} className='styledDialog'>
                <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 10, paddingBottom: 15, paddingTop: 20, marginRight: 0 }}>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-10" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Organization name *</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} id="companyName" onChange={(e) => { this.updateCompanyName(e.target.value) }} value={this.state.newCompanyName} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Status</label>
                        <div>
                            <InputSwitch style={{
                                marginRight: 10, marginTop: 10
                            }} checked={this.state.newCompanyChecked ? true : false} onChange={(e) => this.setState({ newCompanyChecked: e.value })} />
                            <label>{this.state.newCompanyChecked ? 'Active' : 'Inactive'}</label>
                        </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-8" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Application name</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} id="applicationName" onChange={(e) => { this.updateCompanyApplicationName(e.target.value) }} value={this.state.newCompanyApplicationName} />
                    </div>


                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Max. users</label>
                        <InputText placeholder='e.g. 100' style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} keyfilter='pint' id="maxUsers" onChange={(e) => { this.updateMaxUsers(e.target.value) }} value={this.state.maxUsers} />
                    </div>

                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Export limit</label>
                        <Button icon='pi pi-info-circle'
                            style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none', paddingBottom: 0, paddingTop: 0, marginBottom: -1, marginTop: -2 }}
                            title={"Export limit is currently only used for exports from the Chemistry Search."}>
                        </Button>
                        <InputNumber placeholder='e.g. 10.000' style={{ marginTop: 5 }} id="maxDownloads" onValueChange={(e) => { this.updateMaxDownloads(e.value) }} value={this.state.maxDownloads} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label>Restrict users to email domains</label>
                        <Button icon='pi pi-info-circle'
                            style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none', paddingBottom: 0, paddingTop: 1, marginBottom: 4 }}
                            title={"Enter email domains (e.g. xyz.com) without '@'.\nHit enter after each domain to check it in."}>
                        </Button>
                        <Chips className="adminChip" style={{ marginTop: 0 }} placeholder='Enter email domains (e.g. xyz.com)'
                            tooltipOptions={{ position: 'bottom' }} allowDuplicate={false} id="emailDomains" onChange={(e) => { this.updateCompanyEmailDomains(e.value) }} value={this.state.emailDomains} />
                    </div>

                    <div className="col-12" style={{ padding: '.5em' }}>
                        <Checkbox inputId='customHearder'
                            onChange={(e) => this.onCheckCustomHeader(e)}
                            checked={this.state.customHeaderChecked ? true : false}
                            value='' style={{ 'cursor': 'pointer' }}
                        />
                        <label style={{ fontWeight: 'bolder' }} className='p-checkbox-label'>Use custom organization application header - replaces default settings</label>
                    </div>
                    {this.state.customHeaderChecked &&
                        <React.Fragment>
                            <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingLeft: 8, paddingBottom: 15 }}>
                                <LogoUpload
                                    onImageUpload={this.handleImageUpload}
                                />
                            </div>
                            <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-7" style={{ padding: '.5em', paddingBottom: 15 }}>
                                <label >Custom text in application header</label>
                                <div>
                                    <InputText placeholder='Line 1' style={{ padding: 5, border: '1px solid #d6d6d6', textAlign: 'left', marginTop: 5 }} id="appHeader1" onChange={(e) => { this.updateApplicationHeader1(e.target.value) }} value={this.state.applicationHeader1} />
                                </div>
                                <div style={{ paddingTop: 5 }}>
                                    <InputText placeholder='Line 2' style={{ padding: 5, border: '1px solid #d6d6d6', textAlign: 'left', marginTop: 5 }} id="appHeader2" onChange={(e) => { this.updateApplicationHeader2(e.target.value) }} value={this.state.applicationHeader2} />
                                </div>
                                <div style={{ paddingTop: 5 }}>
                                    <InputText placeholder='Line 3' style={{ padding: 5, border: '1px solid #d6d6d6', textAlign: 'left', marginTop: 5 }} id="appHeader3" onChange={(e) => { this.updateApplicationHeader3(e.target.value) }} value={this.state.applicationHeader3} />
                                </div>
                            </div>
                            <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" style={{ padding: '.5em', paddingBottom: 15 }}>
                                <label>Background color</label>
                                <div>
                                    <InputText placeholder='e.g. #0d4667' style={{ padding: 5, border: '1px solid #d6d6d6', textAlign: 'left', marginTop: 5 }} id="backColor" onChange={(e) => { this.updateBackgroundColor(e.target.value) }} value={this.state.backgroundColor} />
                                </div>
                                <div style={{ marginTop: 28 }}>
                                    <label>Text color</label>
                                </div>
                                <div style={{ marginTop: 3 }}>
                                    <InputText placeholder='e.g. #FFFFFF' style={{ padding: 5, border: '1px solid #d6d6d6', textAlign: 'left', marginTop: 5 }} id="backColor" onChange={(e) => { this.updateFontColor(e.target.value) }} value={this.state.fontColor} />
                                </div>
                            </div>
                            <div className="col-12" style={{ paddingTop: 15 }}>
                                <label>Preview</label>
                                <div style={{ marginTop: 5, height: 60, backgroundColor: this.state.backgroundColor !== '' ? this.state.backgroundColor : '#0d4667' }}>
                                    <img
                                        height='40px'
                                        alt="Preview"
                                        src={this.state.headerImage}
                                        style={{ marginTop: 10, marginLeft: 10 }} />
                                    <div className='col-3 sm:col-3 md:col-3 lg:col-6 xl:col-6' style={{ display: 'inline-block', paddingBottom: numberApplicationsHeaders === 1 ? 7 : 2, verticalAlign: 'bottom', paddingLeft: 15 }}>
                                        {this.state.applicationHeader1 !== '' &&
                                            <div>
                                                <label style={{ color: this.state.fontColor !== '' ? this.state.fontColor : '#FFFFFF', fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 11.5 }}>{this.state.applicationHeader1}</label>
                                            </div>
                                        }
                                        {this.state.applicationHeader2 !== '' &&
                                            <div>
                                                <label style={{ color: this.state.fontColor !== '' ? this.state.fontColor : '#FFFFFF', fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 11.5 }}>{this.state.applicationHeader2}</label>
                                            </div>
                                        }
                                        {this.state.applicationHeader3 !== '' &&
                                            <div>
                                                <label style={{ color: this.state.fontColor !== '' ? this.state.fontColor : '#FFFFFF', fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 11.5 }}>{this.state.applicationHeader3}</label>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }

                </div>
                <div className="grid p-fluid" style={{ borderBottom: '1px solid #d6d6d6', paddingLeft: 20, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0 }}>
                    <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3'>
                        <div style={{ marginBottom: 5 }}>
                            <label style={{ fontWeight: 'bold' }}>Repositories</label>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <label>Text, database or chemistry sources available</label>
                        </div>
                        <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 5 }}>
                            <Checkbox inputId='allRepositoriesChecked'
                                onChange={(e) => this.onCheckAllRepos(e)}
                                checked={(this.state.allRepositoriesChecked && this.props.allAvailableRepositories && this.props.allAvailableRepositories.length === this.state.selectedRepositories.length) || (this.props.allAvailableRepositories && this.props.allAvailableRepositories.length === this.state.selectedRepositories.length) ? true : false}
                                value='All' style={{ 'cursor': 'pointer' }}
                            />
                            <label className='p-checkbox-label'>All</label>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            {allAvailableRepositories}
                        </div>
                    </div>
                    <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3'>
                        <div style={{ marginBottom: 5 }}>
                            <label style={{ fontWeight: 'bold' }}>Features</label>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <label>GUI features and apps available</label>
                        </div>
                        <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 5 }}>
                            <Checkbox inputId='allRepositoriesChecked'
                                onChange={(e) => this.onCheckAllFeatures(e)}
                                disabled={false}
                                checked={(this.state.selectedFeatures.length === this.props.allAvailableFeatures.length) ? true : false}
                                value='All' style={{ 'cursor': 'pointer' }}
                            />
                            <label className='p-checkbox-label'>All</label>
                        </div>
                        <div>
                            <div style={{ marginTop: 25, marginBottom: 10 }}>
                                <label style={{
                                    fontWeight: 'bold'
                                }}>GENERAL</label>
                            </div>
                            {generalApps}
                        </div>
                        <div>
                            <div style={{ marginTop: 20, marginBottom: 10 }}>
                                <label style={{
                                    fontWeight: 'bold'
                                }}>CHEMISTRY FINDER</label>
                            </div>
                            {chemApps}
                        </div>
                        <div>
                            <div style={{ marginTop: 20, marginBottom: 10 }}>
                                <label style={{
                                    fontWeight: 'bold'
                                }}>ANALYTICS</label>
                            </div>
                            {analyticApps}
                        </div>
                        <div>
                            <div style={{ marginTop: 20, marginBottom: 10 }}>
                                <label style={{
                                    fontWeight: 'bold'
                                }}>USER RESEARCH</label>
                            </div>
                            {userApps}
                        </div>
                        <div style={{ marginTop: 20, marginBottom: 10 }}>
                            <label style={{
                                fontWeight: 'bold'
                            }}>ADDITIONAL FEATURES</label>
                        </div>
                        {allAvailableFeatures}
                        <div>
                            <div style={{ marginTop: 20, marginBottom: 10 }}>
                                <label style={{
                                    fontWeight: 'bold'
                                }}>DEFAULT FEATURES</label>
                                <a className="infoIconLink valignMiddle"
                                    style={{ marginTop: -1 }}
                                    title="Click for information about default features"
                                    onClick={e => this.recallInfoOverlay.toggle(e)}>
                                    <img src={infoIcon} alt="Default features information"
                                        style={{ marginLeft: 10 }} />
                                </a>
                                <OverlayPanel
                                    ref={(el) => this.recallInfoOverlay = el}
                                    dismissable={true}>
                                    <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                                        <p>
                                            Default features are automatically available for every organization and suborganization.
                                        </p>
                                    </div>
                                </OverlayPanel>
                            </div>
                            {defaultApps}
                        </div>
                    </div>
                    {APP_PROPERTIES.APP_ID !== 'sciwalker_studio' &&
                        <>
                            <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3'>
                                <div style={{ marginBottom: 5 }}>
                                    <label style={{ fontWeight: 'bold' }}>Domains highlighting</label>
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <label>Domains highlighted in annotated text</label>
                                </div>
                                <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 5 }}>
                                    <Checkbox inputId='allHighlightedDomainsChecked'
                                        onChange={(e) => this.onCheckAllHighlightedDomains(e)}
                                        checked={(this.state.allHighlightedDomainsChecked && this.props.allDomainsHighlightItems.length === this.state.selectedHighlightDomains.length) || (this.props.allDomainsHighlightItems.length === this.state.selectedHighlightDomains.length) ? true : false}
                                        value='All' style={{ 'cursor': 'pointer' }}
                                    />
                                    <label className='p-checkbox-label'>All</label>
                                </div>
                                <div style={{ marginTop: 15 }}>
                                    {allDomainsHighlight}
                                </div>
                            </div>
                            <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-3'>
                                <div style={{ marginBottom: 5 }}>
                                    <label style={{ fontWeight: 'bold' }}>Searchable domains</label>
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <label>Domains available in autocomplete</label>
                                </div>
                                <div style={{ borderBottom: '1px solid #d6d6d6', paddingBottom: 5 }}>
                                    <Checkbox inputId='allSearchableDomainsChecked'
                                        onChange={(e) => this.onCheckAllSearchableDomains(e)}
                                        checked={(this.state.allSearchableDomainsChecked && this.props.allDomainsSearch.length === this.state.selectedSearchDomains.length) || (this.props.allDomainsSearch.length === this.state.selectedSearchDomains.length) ? true : false}
                                        value='All' style={{ 'cursor': 'pointer' }}
                                    />
                                    <label className='p-checkbox-label'>All</label>
                                </div>
                                <div style={{ marginTop: 15 }}>
                                    {allDomainsSearch}
                                </div>
                            </div>
                        </>}
                </div>
                <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                    <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 15 }}>* required</label>
                    <Button label="Save"
                        className='p-button-sm primaryButton'
                        disabled={this.state.newCompanyName !== '' ? false : true}
                        onClick={this.onSaveNewCompany}
                        style={{ float: 'right' }}
                    />
                    <Button label="Cancel"
                        className='p-button-secondary p-button-sm'
                        onClick={() => this.onCancelAddCompany()}
                        style={{ float: 'right', marginRight: 5 }}
                    />
                </div>
            </Dialog>
        )
    }
}

export default AddCompany