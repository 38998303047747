/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { getHeaderToken } from '../../../api/index'
import axios from 'axios'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import ToastContent from '../../common/CustomToast/ToastContent';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { TabView, TabPanel } from 'primereact/tabview'
//import { MultiSelect } from 'primereact/multiselect';
//import { OverlayPanel } from 'primereact/overlaypanel';
import { APP_PROPERTIES, EXPORTER_DOC_SELECTIONS_LIBRARY, EXPORTER_NUM_OF_DOCS_LIMIT, EXPORTER_TARGET_ID_FILE, EXPORTER_FORMATS, GLOBAL_METADATA_DOWNLOAD_LIMIT, EXPORTER_TARGETS, EXPORTER_TARGET_ID_BIGQUERY } from '../../../properties/index';
import { exportDocumentsMetaDataByDocIDs } from "../../../api/content/DocumentApi";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
//import { Link } from 'react-router-dom';
import DocCollections from './DocCollections'
import CollectionDocuments from './CollectionDocuments'
import ReadDocumentsContainer from '../ReadDocuments/container/ReadDocumentsContainer'
import CreateCollection from "../docCollection/CreateCollection";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import pageLogo from '../../../assets/images/page_icons/illustration document stack@2x.png'
//import pageLogo from '../../../assets/images/page_icons/illustration_document_stack.png'
import moment from 'moment';
import { addDynamicPanel, hasUserRole, isArrayEmpty, createUserArray, } from '../util';
import { checkResultAndGetPayload } from '../../../api';
import { semanticExportFromDocumentsByLibrary, semanticExportFromDocumentsByDocIDs, exportDocumentsToReadcube } from '../../../api/content/DocumentApi';
import { videos } from '../../../properties/videos'
import { saveAs } from 'file-saver';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import AddEditAlert from '../Alerts/AddEditAlert'
import Extractor from '../SemanticExtractor/Extractor';
import SequenceExportContainer from '../SequenceExport/container/SequenceExportContainer'
import { DocViewModal } from '../docview/common/DocviewModal/DocviewModal';
import { setQuickSearchSelectedTerms } from '../../../redux/actions/QuickSearchActions';
import TutorialVideos from '../general/TutorialVideos';
import ExportLimit from '../export/ExportLimit';
import ExportCenterSuccessMessage from "../export/ExportCenterSuccessMessage";
import AddReadcubeDialog from "../../common/ReadCube/AddReadcubeDialog";

class Library extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);
        let columnsCollections = [
            { field: "check", selectionMode: 'multiple', style: { width: '4em', fontWeight: 'normal' } },
            { field: "name", header: "Name", sortable: true, style: {}, body: this.nameTemplate },
            {
                field: "numberDocuments",
                header: "Documents",
                sortable: true,
                style: { fontWeight: 'normal', textAlign: 'left' },
                body: this.numberDocumentsTemplate
            },
            { field: "", header: "Sharing", body: this.sharedTemplate, sortable: true, style: { textAlign: 'left' } }
        ]

        const exporterTargets = EXPORTER_TARGETS.filter(target => target.id !== EXPORTER_TARGET_ID_BIGQUERY || props.allowBigQueryExport);
        const selectedTarget = !isArrayEmpty(exporterTargets) ? exporterTargets[0] : null;

        this.state = {
            selectedTarget: selectedTarget,
            displayCustomToast: false,
            colsCollections: columnsCollections,
            collectionName: 'All documents',
            collectionDescription: 'All documents contained in your library',
            displayDialogAddCollection: false,
            displayDialogRemoveCollection: false,
            displayDialogModifyCollection: false,
            displayDialogCopyDocument: false,
            displayDialogMoveDocument: false,
            displayDialogRemoveDocuments: false,
            displayAddReadcubeDialog: false,
            newCollectionName: '',
            newCollectionDescription: '',
            sortCollectionBy: 'created',
            canEdit: true,
            selectedDocumentsAction: [],
            selectedDocuments: [],
            selectedCollectionID: undefined,
            selectedCollectionsAction: null,
            collectionID: undefined,
            startTime: null,
            pageTutorials: [],
            section: '',
            sectionLabel: '',
            docIntervals: [],
            displayExportCollections: false,
            displayImportCollections: false,
            globalFilterCollections: '',
            fetching: false,
            firstDocuments: 0,
            rowsDocuments: 10,
            displayAddEditAlert: false,
            selectedCollectionAddEdit: undefined,
            displayConfirmationDialog: false,
            displaySemanticExport: false,
            containsPatents: false,
            isDocviewModalVisible: false,
            ocDocId: '',
            selectedDocument: null,
            repositoryId: '',
            activeIndex: 0,
            allNotAllowedUsersRead: [],
            allNotAllowedUsersWrite: [],
            allNotAllowedSuborgs: [],
        }
        this.growl = createRef();
        this.op = createRef();
    }



    onShowDocviewModal = (repId, docId, doc) => {
        const { setIsLoaderActive } = this.props
        if (setIsLoaderActive)
            setIsLoaderActive(true)

        this.setState({
            ocDocId: docId,
            repositoryId: repId,
            selectedDocument: doc,
            isDocviewModalVisible: true
        })
    };

    onCloseDocviewModal = () => {
        const { setIsLoaderActive } = this.props
        if (setIsLoaderActive)
            setIsLoaderActive(false)

        this.setState({
            ocDocId: '',
            repositoryId: '',
            selectedDocument: null,
            isDocviewModalVisible: false
        })
    };


    componentDidMount() {

        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('library')) });
        }

        this.props.onItemSelectedClicked([])

        var self = this;

        /*axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications/email-check`, { headers: getHeaderToken() })
            .then(function (response) {
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })*/

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications/intervals`, { headers: getHeaderToken() })
            .then(function (response) {
                let docIntervals = []
                response.data.forEach(entry => {
                    docIntervals = [...docIntervals, { label: entry, value: entry }]
                })
                self.setState({ docIntervals: docIntervals })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({ docCollections: response.data.content })
                const urlParams = new URLSearchParams(window.location.search)
                //let watchlistId = parseInt(urlParams.get('watchlistId'))
                let collectionId = urlParams.get('doccol')
                let date = urlParams.get('date')
                let filteredCollection = response.data.content.find(obj =>
                    obj.id.toString() === collectionId)
                if (filteredCollection !== undefined) {
                    self.setState({
                        selectedCollection: filteredCollection
                        //startTime: date
                    }, () => self.handleCollectionChange(filteredCollection, date)
                    )
                } else if (filteredCollection === undefined && collectionId !== null) {
                    let msg = { severity: 'error', summary: 'Error!', detail: 'Document collection not found. Maybe the collection was deleted after you received the alert for it.', life: 6000 };
                    self.growl?.current?.show(msg);
                }
                window.history.replaceState(null, null, window.location.pathname)
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    selectedDocuments: response.data.content,
                    allDocuments: response.data.content
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/users`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    departmentMembers: response.data
                }, () => {
                    let userArray = createUserArray(response.data, self.props.userData, 'user')
                    let orgArray = createUserArray(response.data, self.props.userData, 'org')
                    let allPossibleUsers = []
                    userArray && userArray.forEach(org => {
                        org.children.forEach(dep => {
                            dep.children.forEach(user => {
                                allPossibleUsers = [...allPossibleUsers, user.id]
                            })
                        })
                    })
                    let allPossibleSuborgs = []
                    orgArray && orgArray.forEach(org => {
                        org.children.forEach(dep => {
                            allPossibleSuborgs = [...allPossibleSuborgs, dep.id]
                        })
                    })
                    self.setState({
                        userArray: userArray,
                        orgArray: orgArray,
                        allPossibleUsers: allPossibleUsers,
                        allPossibleSuborgs: allPossibleSuborgs
                    })
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userData.userDetails !== prevProps.userData.userDetails) {
            var self = this;
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/users`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        departmentMembers: response.data
                    }, () => {
                        let userArray = createUserArray(response.data, self.props.userData, 'user')
                        let orgArray = createUserArray(response.data, self.props.userData, 'org')
                        let allPossibleUsers = []
                        userArray && userArray.forEach(org => {
                            org.children.forEach(dep => {
                                dep.children.forEach(user => {
                                    allPossibleUsers = [...allPossibleUsers, user.id]
                                })
                            })
                        })
                        let allPossibleSuborgs = []
                        orgArray && orgArray.forEach(org => {
                            org.children.forEach(dep => {
                                allPossibleSuborgs = [...allPossibleSuborgs, dep.id]
                            })
                        })
                        self.setState({
                            userArray: userArray,
                            orgArray: orgArray,
                            allPossibleUsers: allPossibleUsers,
                            allPossibleSuborgs: allPossibleSuborgs
                        })
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    openAddAlertDialog = (col) => {
        //console.log(col)
        this.setState({
            displayAddEditAlert: true,
            selectedCollectionAddEdit: col
        })
    }

    closeAddEditAlert = () => {
        this.setState({
            displayAddEditAlert: false
        })
    }

    onHideConfirmationDialog = () => {
        this.setState({
            displayConfirmationDialog: false
        })
    }

    handleCollectionChange = (collection, date) => {
        //console.log(collection)
        if (!collection.id) {
            this.toastBC.current.clear()
            this.setState({
                collectionName: 'All documents',
                collectionDescription: 'All documents contained in your library',
                selectedDocuments: this.state.allDocuments,
                collectionID: undefined,
                selectedCollectionID: undefined,
                selectedDocumentsAction: [],
                selectedCollectionsAction: null,
                startTime: null,
                firstDocuments: 0,
                displayCustomToast: false,
                allNotAllowedUsersRead: [],
                allNotAllowedUsersWrite: [],
                allNotAllowedSuborgs: [],
            }, () => {
                this.refreshAllDocuments()
                this.refreshDocumentCollections()
            })
        } else {
            this.toastBC.current.clear()
            let canEdit
            if (collection.writable) {
                canEdit = true
            } else if (!collection.writable) {
                canEdit = false
            } else {
                canEdit = true
            }
            var self = this;
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${collection.id}/page/0/count/1000/list`, { headers: getHeaderToken() })
                .then(function (response) {
                    let containsPatents = false
                    let allNotAllowedUsersRead = []
                    let allNotAllowedUsersWrite = []
                    let allNotAllowedSuborgs = []
                    self.state.docCollections.forEach(col => {
                        if (col.id === collection.id) {
                            containsPatents = col.containsPatent
                        }
                    })
                    if (collection.userSharesRead && collection.userSharesRead.length > 0 && self.state.allPossibleUsers && self.state.allPossibleUsers.length > 0) {
                        collection.userSharesRead.forEach(user => {
                            if (!self.state.allPossibleUsers.includes(user.id)) {
                                allNotAllowedUsersRead = [...allNotAllowedUsersRead, user.id]
                            }
                        })
                    }
                    if (collection.userSharesWrite && collection.userSharesWrite.length > 0 && self.state.allPossibleUsers && self.state.allPossibleUsers.length > 0) {
                        collection.userSharesWrite.forEach(user => {
                            if (!self.state.allPossibleUsers.includes(user.id)) {
                                allNotAllowedUsersWrite = [...allNotAllowedUsersWrite, user.id]
                            }
                        })
                    }
                    if (collection.departmentSharesRead && collection.departmentSharesRead.length > 0 && self.state.allPossibleSuborgs && self.state.allPossibleSuborgs.length > 0) {
                        collection.departmentSharesRead.forEach(dep => {
                            if (!self.state.allPossibleSuborgs.includes(dep.id)) {
                                allNotAllowedSuborgs = [...allNotAllowedSuborgs, dep.id]
                            }
                        })
                    }
                    self.setState({
                        containsPatents: containsPatents,
                        selectedDocuments: response.data.content,
                        collectionID: collection.id,
                        selectedCollectionID: collection.id,
                        collectionName: collection.name,
                        collectionNameSelected: collection.name,
                        collectionDescription: collection.description,
                        selectedCollection: collection,
                        canEdit: canEdit,
                        selectedDocumentsAction: [],
                        selectedCollectionsAction: null,
                        startTime: date !== undefined ? date : null,
                        firstDocuments: 0,
                        displayCustomToast: false
                    }, () => {
                        self.refreshAllDocuments()
                        self.refreshDocumentCollections()
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    onSortCollection = (value) => {
        let sortBy = value
        let allCollections = JSON.parse(JSON.stringify(this.state.docCollections))
        let allCollectionsOrdered
        if (this.state.docCollections && this.state.docCollections.length > 0 && sortBy === "numberDocuments") {
            allCollectionsOrdered = allCollections.sort(function (a, b) { return b[sortBy] - a[sortBy] })
        } else if (this.state.docCollections && this.state.docCollections.length > 0 && sortBy === "name") {
            allCollectionsOrdered = allCollections.sort((a, b) => a.name.localeCompare(b.name))
        } else if (this.state.docCollections && this.state.docCollections.length > 0 && sortBy === "created" || sortBy === "modified") {
            allCollectionsOrdered = allCollections.sort(function (a, b) { return new Date(b[sortBy]) - new Date(a[sortBy]) })
        }
        this.setState({
            sortCollectionBy: value,
            docCollections: allCollectionsOrdered
        })
    }

    onDeleteAlert = () => {
        this.setState({
            displayConfirmationDialog: true
        })
    }

    callbackFromSave = async (collection, activeOption, interval, startTime) => {
        /*console.log(activeOption)
        console.log(interval)
        console.log(startTime)*/

        var self = this;
        if (activeOption === 'active' && ((collection.notifications && collection.notifications.length === 0) ||
            collection.notifications === undefined)) {
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications`, {
                active: true,
                documentCollectionId: collection.id,
                interval: interval,
                nextSendingDate: moment(startTime).format("YYYY-MM-DD")
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        displayAddEditAlert: false
                    })
                    self.refreshDocumentCollections(collection.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if ((activeOption === 'active' || activeOption === 'paused') && (collection.notifications && collection.notifications.length !== 0)) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${collection.notifications[0].id}`, {
                active: activeOption === 'active' ? true : false,
                documentCollectionId: collection.id,
                interval: interval,
                nextSendingDate: moment(startTime).format("YYYY-MM-DD")
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        displayAddEditAlert: false
                    })
                    self.refreshDocumentCollections(collection.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (activeOption === 'remove' && (collection.notifications && collection.notifications.length !== 0)) {
            await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${collection.notifications[0].id}`,
                { headers: getHeaderToken(), data: collection.notifications[0].id })
                .then(function (response) {
                    if (response.status === 200) {
                        self.setState({
                            displayAddEditAlert: false,
                            displayConfirmationDialog: false
                        })
                        self.refreshDocumentCollections(collection.id)
                    }
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    collectionAlertChange = async (col, status, e) => {
        //console.log(e.value)
        let today = new Date()
        let week = new Date(today)
        let tomorrow = new Date(today)
        let month = new Date(today)
        week.setDate(tomorrow.getDate() + 7)
        tomorrow.setDate(tomorrow.getDate() + 1)
        month.setMonth(month.getMonth() + 1)
        //console.log( moment(tomorrow).format("YYYY-MM-DD"))
        //let newDate = new Date(tomorrow).toLocaleDateString('fr-CA')
        //let newDate = moment(week).format("YYYY-MM-DD")
        //let newDateTomorrow = moment(tomorrow).format("YYYY-MM-DD")
        //let newDateMonth = moment(month).format("YYYY-MM-DD")

        var self = this;
        if (e.value === 'active') {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${col.notifications[0].id}`, {
                active: true,
                documentCollectionId: col.id,
                interval: col.notifications[0].interval,
                nextSendingDate: col.notifications[0].nextSendingDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshDocumentCollections(col.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (e.value === 'paused') {
            //console.log(col.notifications[0].nextSendingDate)
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${col.notifications[0].id}`, {
                active: false,
                documentCollectionId: col.id,
                interval: col.notifications[0].interval,
                nextSendingDate: col.notifications[0].nextSendingDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshDocumentCollections(col.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (e.value === 'remove') {
            await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${col.notifications[0].id}`,
                { headers: getHeaderToken(), data: col.notifications[0].id })
                .then(function (response) {
                    if (response.status === 200) {
                        self.refreshDocumentCollections(col.id)
                    }
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
        /*{
            //console.log(col.notifications[0].interval)
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${col.notifications[0].id}`, {
                active: true,
                documentCollectionId: col.id,
                interval: col.notifications[0].interval,
                nextSendingDate: col.notifications[0].interval === 'WEEKLY' ? newDate : col.notifications[0].interval === 'DAILY' ? newDateTomorrow : newDateMonth//col.notifications[0].nextSendingDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshDocumentCollections(col.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }*/
    }

    onAddCollection = () => {
        this.setState({
            displayDialogAddCollection: true,
        })
    }

    onRemoveCollection = () => {
        this.setState({
            displayDialogRemoveCollection: true
        })
    }

    hideCreateCollection = () => {
        this.setState({
            displayDialogAddCollection: false
        })
    }

    onModifyCollection = () => {
        this.setState({
            displayDialogModifyCollection: true
        })
    }

    saveCollection = async (newCollectionName, newCollectionDescription, sharedForDepartment, usersRead, usersWrite, orgShared, editableForSharedUsers) => {
        //console.log(usersShared)
        let departments = []
        orgShared.forEach(dep => {
            departments = [...departments, Number(dep)]
        })
        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections`,
            {
                name: newCollectionName,
                description: newCollectionDescription,
                departmentSharesRead: departments,
                //sharedForDepartment: sharedForDepartment,
                //userSharesRead: editable ? [] : usersShared,
                //userSharesWrite: editable ? usersShared : [],
                userSharesRead: usersRead,
                userSharesWrite: usersWrite
            }, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showCreateCollectionSuccessGrowl()
                self.setState({
                    displayDialogAddCollection: false
                }, () => self.refreshDocumentCollections())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showCreateCollectionFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.setState({
                        displayDialogAddCollection: false
                    })
                    console.log("not found")
                }
            })
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.current.show(msg);
    }

    showNameLengthWarnGrowl = (name) => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: `Document collection name "${name}" is too long. Please limit the name to 50 characters within the input field.`, life: 10000 };
        this.growl.current.show(msg);
    }

    showCreateCollectionSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Collection successfully added to database.' };
        this.growl.current.show(msg);
    }

    showCreateCollectionFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.current.show(msg);
    }

    onHideRemoveCollection = () => {
        this.setState({
            displayDialogRemoveCollection: false
        })
    }

    onHideModifyCollection = () => {
        this.setState({
            displayDialogModifyCollection: false
        })
    }

    modifyCollection = async (selectedCollectionName, selectedCollectionDescription, sharedForDepartment, usersRead, usersWrite, orgShared, editableForSharedUsers) => {
        let departments = []
        orgShared.forEach(dep => {
            departments = [...departments, Number(dep)]
        })
        var self = this;
        //if (canModifyCollection) {
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${self.state.selectedCollection.id}`,
            {
                name: selectedCollectionName,
                description: selectedCollectionDescription,
                departmentSharesRead: departments,
                //sharedForDepartment: sharedForDepartment,
                //userSharesRead: editable ? [] : usersShared,
                //userSharesWrite: editable ? usersShared : [],
                userSharesRead: usersRead,
                userSharesWrite: usersWrite
            }, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    //self.showModifyCollectionSuccessGrowl()
                    self.refreshDocumentCollections(self.state.selectedCollection.id)
                }
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showModifyCollectionFailGrowl()
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
        //}
        //self.showModifyCollectionSuccessGrowl()

    }

    showModifyCollectionFailGrowl = () => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection could not be edited.', life: 6000 };
        this.growl.current.show(msg);
    }


    changeCollectionMembers = async (collectionID, members) => {
        var self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${collectionID}/users?ids=${members}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showChangeMembersSuccessGrowl()
                self.refreshDocumentCollections(collectionID)
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showChangeMembersFailGrowl()
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }


    showModifyCollectionSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Collection successfully modified.' };
        this.growl.current.show(msg);
    }

    showModifyCollectionFailGrowl = (e) => {
        //console.log(e.response)
        let msg = { severity: 'error', summary: 'Failed!', detail: e.response.data, life: 6000 };
        this.growl.current.show(msg);
    }

    showShareCollectionFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection shareability could not be set.', life: 6000 };
        this.growl.current.show(msg);
    }

    showChangeMembersFailGrowl = () => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Members of collection could not be changed.', life: 6000 };
        this.growl.current.show(msg);
    }

    deleteCollection = async (e) => {
        let id = this.state.selectedCollection.id
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections?documentCollectionIds=${id}`, { headers: getHeaderToken(), data: { id } })
            .then(function (response) {
                if (response.status === 200) {
                    //self.showCollectionDeleteSuccessGrowl()
                    self.refreshDocumentCollections()
                    self.refreshAllDocuments()
                    self.setState({
                        collectionName: 'All documents',
                        collectionDescription: 'All documents contained in your library',
                        selectedDocuments: self.state.allDocuments,
                        displayDialogRemoveCollection: false,
                        collectionID: undefined,
                        selectedCollectionID: undefined,
                        selectedDocumentsAction: [],
                        selectedCollectionsAction: null
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showCollectionDeleteFailGrowl()
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showCollectionDeleteSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Collection deleted from database.' };
        this.growl.current.show(msg);
    }

    showCollectionDeleteFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection could not be deleted from database.', life: 6000 };
        this.growl.current.show(msg);
    }

    refreshDocumentCollections = async (collectionID, refreshDocuments) => {
        //console.log(collectionID)
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                let docCollections = response.data.content
                let allCollectionsOrdered
                if (docCollections && docCollections.length > 0 && self.state.sortCollectionBy === "numberDocuments") {
                    allCollectionsOrdered = docCollections.sort(function (a, b) { return b[self.state.sortCollectionBy] - a[self.state.sortCollectionBy] })
                } else if (docCollections && docCollections.length > 0 && self.state.sortCollectionBy === "name") {
                    allCollectionsOrdered = docCollections.sort((a, b) => a.name.localeCompare(b.name))
                } else if (docCollections && docCollections.length > 0 && self.state.sortCollectionBy === "created" || self.state.sortCollectionBy === "modified") {
                    allCollectionsOrdered = docCollections.sort(function (a, b) { return new Date(b[self.state.sortCollectionBy]) - new Date(a[self.state.sortCollectionBy]) })
                }
                self.setState({
                    docCollections: allCollectionsOrdered,
                    displayDialogModifyCollection: false
                }, () => {
                    if (collectionID !== undefined) {
                        self.state.docCollections.forEach(col => {
                            if (col.id === collectionID && self.state.selectedCollection && col.id == self.state.selectedCollection.id) {
                                let canEdit
                                if (col.writable) {
                                    canEdit = true
                                } else if (!col.writable) {
                                    canEdit = false
                                } else {
                                    canEdit = true
                                }
                                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${col.id}/page/0/count/1000/list`, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        self.setState({
                                            selectedDocuments: response.data.content,
                                            collectionID: col.id,
                                            selectedCollectionID: col.id,
                                            collectionName: col.name,
                                            collectionNameSelected: col.name,
                                            collectionDescription: col.description,
                                            selectedCollection: col,
                                            canEdit: canEdit
                                        }, () => {
                                            if (Array.isArray(self.state.selectedDocumentsAction)) {
                                                let selectedDocuments = []
                                                self.state.selectedDocumentsAction.forEach(selDoc => {
                                                    self.state.selectedDocuments.forEach(doc => {
                                                        if (selDoc.docId === doc.document.docId) {
                                                            selectedDocuments = [...selectedDocuments, doc.document]
                                                        }
                                                    })
                                                })
                                                self.setState({ selectedDocumentsAction: selectedDocuments })
                                            }
                                        })
                                    })
                                    .catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showFailGrowl(error.response.data)
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            }
                        })
                    } else if (collectionID === undefined && refreshDocuments) {
                        self.refreshCollectionDocuments(self.state.selectedCollection)
                    }
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshCollectionDocuments = (collection) => {
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${collection.id}/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                let containsPatents = false
                //console.log(self.state.docCollections)
                self.state.docCollections.forEach(col => {
                    if (col.id === collection.id) {
                        containsPatents = col.containsPatent
                    }
                })
                //console.log(containsPatents)
                self.setState({
                    containsPatents: containsPatents,
                    selectedDocuments: response.data.content
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshAllDocuments = () => {
        var self = this
        //console.log(self.state)
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showQueryRefreshSuccessGrowl()
                self.setState({ allDocuments: response.data.content })
                if (self.state.selectedCollection === undefined) {
                    self.setState({
                        selectedDocuments: response.data.content
                    })
                }
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showQueryRefreshFailGrowl()
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleIntervalChange = async (collection, value) => {
        let self = this
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${collection.notifications[0].id}`, {
            active: collection.notifications[0].active,
            documentCollectionId: collection.id,
            interval: value,
            nextSendingDate: collection.notifications[0].nextSendingDate
        }, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response)
                self.refreshDocumentCollections(collection.id)
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    ratingChange = async (api, docId, repository, val, title, repositoryId) => {
        var self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, [{ docId: docId, repository: repository, repositoryId: repositoryId, rating: val, title: title }], { headers: getHeaderToken() })
            .then(function (response) {
                let newArray = self.state.selectedDocuments
                let newDocInfo = response.data[0]
                newArray.forEach(doc => {
                    if (doc.document) {
                        if (doc.document.docId === newDocInfo.docId) {
                            Object.assign(doc.document, newDocInfo)
                        }
                    } else {
                        if (doc.docId === newDocInfo.docId) {
                            Object.assign(doc, newDocInfo)
                        }
                    }
                })
                self.setState({
                    selectedDocuments: newArray
                }, () => {
                    self.refreshAllDocuments()
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    readStatusChange = async (api, docId, repository, readStatus, title, repositoryId) => {
        if (hasUserRole('ROLE_READDOCUMENTS')) {
            var self = this;
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, [{ docId: docId, repository: repository, repositoryId: repositoryId, read: readStatus, title: title }], { headers: getHeaderToken() })
                .then(function (response) {
                    let newArray = self.state.selectedDocuments
                    let newDocInfo = response.data[0]
                    newArray.forEach(doc => {
                        if (doc.document) {
                            if (doc.document.docId === newDocInfo.docId) {
                                Object.assign(doc.document, newDocInfo)
                            }
                        } else {
                            if (doc.docId === newDocInfo.docId) {
                                Object.assign(doc, newDocInfo)
                            }
                        }
                    })
                    self.setState({
                        selectedDocuments: newArray
                    }, () => {
                        self.refreshAllDocuments()
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showChangeReadFailGrowl()
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    copyDocuments = async (ColIds, ids) => {
        var self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/documents/copying?collectionIds=${ColIds}&documentLinkIds=${ids} `, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    self.showDocumentCopySuccessGrowl()
                    self.refreshDocumentCollections()
                    self.refreshAllDocuments()
                    self.setState({
                        //selectedDocumentsAction: [],
                        selectedCollectionsAction: null,
                        displayDialogCopyDocument: false
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showDocumentCopyFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    moveDocuments = async (ColIds, ids) => {
        var self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/documents/moving?collectionIds=${ColIds}&documentLinkIds=${ids} `, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    self.toastBC.current.clear()
                    self.showDocumentMoveSuccessGrowl()
                    self.refreshDocumentCollections(undefined, true)
                    //self.refreshCollectionDocuments(self.state.selectedCollection)
                    self.refreshAllDocuments()
                    self.setState({
                        displayDialogMoveDocument: false,
                        selectedDocumentsAction: [],
                        selectedCollectionsAction: null,
                        displayCustomToast: false
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showDocumentMoveFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showDocumentCopySuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Documents successfully copied.' };
        this.growl.current.show(msg);
    }

    showDocumentCopyFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Documents could not be copied.', life: 6000 };
        this.growl.current.show(msg);
    }

    showDocumentMoveSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Documents successfully moved.' };
        this.growl.current.show(msg);
    }

    showDocumentMoveFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Documents could not be moved.', life: 6000 };
        this.growl.current.show(msg);
    }

    handleUnselect = () => {
        this.toastBC.current.clear()
        this.setState({
            selectedDocumentsAction: [],
            selectedCollectionsAction: null,
            displayCustomToast: false
        })
    }

    removeDocuments = async (ids) => {
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${self.state.selectedCollection.id}/document?ids=${ids}`, { headers: getHeaderToken(), data: {} })
            .then(function (response) {
                if (response.status === 200) {
                    self.toastBC.current.clear()
                    //self.showDocumentRemoveSuccessGrowl()
                    self.refreshDocumentCollections(undefined, true)
                    //self.refreshCollectionDocuments(self.state.selectedCollection)
                    self.refreshAllDocuments()
                    self.setState({
                        displayDialogRemoveDocuments: false,
                        selectedDocumentsAction: [],
                        selectedCollectionsAction: null,
                        displayCustomToast: false
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showDocumentRemoveFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    selectDocuments = (documents) => {
        //console.log(documents)
        if (documents.length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (documents.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }

        /*if (this.toastBC.current.state.messages.length === 0) {
            this.toastBC.current.show({
                closable: false, severity: '', sticky: true, content: (
                    <div className='col-12' style={{ display: 'block', padding: 0 }}>
                        <div className='col-12' style={{ textAlign: 'center', padding: 0, paddingBottom: 5 }}>
                            <label style={{ color: 'black', fontWeight: 'bold' }}>{documents.length} {documents.length !== 1 ? 'documents' : 'document'} selected</label>
                        </div>
                        <div className='col-12 grid p-fluid' style={{ justifyContent: 'space-between', padding: 0 }}>
                            <div className='col-3' style={{}}>
                                <a style={{}}
                                    title={documents.length && documents.length === 1 ? `Copy selected document` : `Copy ${documents.length} selected documents`}
                                    onClick={this.handleCopyDocumentDialog} >Copy</a>
                            </div>
                            {this.state.canEdit && this.state.collectionID !== undefined ?
                                <React.Fragment>
                                    <div className='col-3' style={{}}>
                                        <a style={{}}
                                            title={documents.length && documents.length === 1 ? `Move selected document` : `Move ${documents.length} selected documents`}
                                            onClick={this.handleMoveDocumentDialog}>Move</a>
                                    </div>
                                    <div className='col-3' style={{}}>
                                        <a style={{}}
                                            title={documents.length && documents.length === 1 ? `Remove selected document` : `Remove ${documents.length} selected documents`}
                                            onClick={this.handleRemoveDocumentsDialog}>Remove</a>
                                    </div>
                                </React.Fragment>
                                : null
                            }
                            <div className='col-3' style={{ textAlign: 'end' }}>
                                <a style={{}}
                                    title='Unselect'
                                    onClick={this.handleUnselect}>Unselect</a>
                            </div>
                        </div>
                    </div>
                )
            });
        } else if (this.toastBC.current.state.messages.length !== 0) {
            this.toastBC.current.state.messages = [{
                id: this.state.toastID,
                closable: false, severity: '', sticky: true, content: (
                    <div className='col-12' style={{ display: 'block', padding: 0 }}>
                        <div className='col-12' style={{ textAlign: 'center', padding: 0, paddingBottom: 5 }}>
                            <label style={{ color: 'black', fontWeight: 'bold' }}>{documents.length} {documents.length !== 1 ? 'documents' : 'document'} selected</label>
                        </div>
                        <div className='col-12 grid p-fluid' style={{ justifyContent: 'space-between', padding: 0 }}>
                            <div className='col-3' style={{}}>
                                <a style={{}}
                                    title={documents.length && documents.length === 1 ? `Copy selected document` : `Copy ${documents.length} selected documents`}
                                    onClick={this.handleCopyDocumentDialog} >Copy</a>
                            </div>
                            {this.state.canEdit && this.state.collectionID !== undefined ?
                                <React.Fragment>
                                    <div className='col-3' style={{}}>
                                        <a style={{}}
                                            title={documents.length && documents.length === 1 ? `Move selected document` : `Move ${documents.length} selected documents`}
                                            onClick={this.handleMoveDocumentDialog}>Move</a>
                                    </div>
                                    <div className='col-3' style={{}}>
                                        <a style={{}}
                                            title={documents.length && documents.length === 1 ? `Remove selected document` : `Remove ${documents.length} selected documents`}
                                            onClick={this.handleRemoveDocumentsDialog}>Remove</a>
                                    </div>
                                </React.Fragment>
                                : null
                            }
                            <div className='col-3' style={{ textAlign: 'end' }}>
                                <a style={{}}
                                    title='Unselect'
                                    onClick={this.handleUnselect}>Unselect</a>
                            </div>
                        </div>
                    </div>
                )
            }]
        }*/
        this.setState({
            selectedDocumentsAction: documents
        }/*, () => {
            if (this.toastBC.current.state.messages[0]) {
                this.setState({
                    toastID: this.toastBC.current.state.messages[0].id
                })
            }
        }*/
        )
    }

    selectCollections = (collections) => {
        this.setState({
            selectedCollectionsAction: collections
        })
    }

    showDocumentRemoveSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Document removed from collection.' };
        this.growl.current.show(msg);
    }


    showDocumentRemoveFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.current.show(msg);
    }

    showRefreshCollectionDocumentsFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection documents could not be updated.', life: 6000 };
        this.growl.current.show(msg);
    }

    handleCopyDocumentDialog = () => {
        this.setState({
            displayDialogCopyDocument: true
        })
    }

    handleMoveDocumentDialog = () => {
        this.setState({
            displayDialogMoveDocument: true
        })
    }

    handleRemoveDocumentsDialog = () => {
        this.setState({
            displayDialogRemoveDocuments: true
        })
    }

    handleSemanticExportDialog = () => {
        this.setState({
            displaySemanticExport: true
        })
    }

    handleCloseSemanticExportDialog = () => {
        this.setState({
            displaySemanticExport: false
        })
    }

    handleCloseCopyDocumentDialog = () => {
        this.setState({
            displayDialogCopyDocument: false,
            selectedCollectionsAction: null
        })
    }

    handleCloseMoveDocumentDialog = () => {
        this.setState({
            displayDialogMoveDocument: false,
            selectedCollectionsAction: null
        })
    }

    handleCloseRemoveDocumentDialog = () => {
        this.setState({
            displayDialogRemoveDocuments: false,
            selectedCollectionsAction: null
        })
    }

    onTabChange = (e) => {
        if (e.index === 0) {
            //console.log(this.state)
            if (this.state.collectionID === undefined) {
                this.refreshAllDocuments()
                this.refreshDocumentCollections()
            } else {
                this.refreshAllDocuments()
                this.refreshDocumentCollections(undefined, true)
                //this.refreshCollectionDocuments(this.state.selectedCollection)
            }
        }

        this.toastBC.current.clear()
        this.setState({
            selectedCollectionsAction: null,
            selectedDocumentsAction: [],
            activeIndex: e.index,
            displayCustomToast: false
        })
    }

    handleShareWithDepartment = (share) => {
        this.setState({ sharedForDepartment: share })
    }

    handleEditable = (editable) => {
        this.setState({ editableForSharedUsers: editable })
    }

    handleMemberChange = (members) => {
        this.setState({
            usersShared: members
        })
    }

    callbackFromTimeChange = (date) => {
        this.toastBC.current.clear()
        this.setState({
            startTime: date,
            selectedDocumentsAction: [],
            selectedCollectionsAction: null,
            displayCustomToast: false
        })
    }

    onExportClick = () => {
        this.toastBC.current.clear()
        this.setState({
            displayExportCollections: true,
            selectedDocumentsAction: [],
            globalFilterCollections: '',
            selectedCollectionsExport: [],
            displayCustomToast: false
        })
    }

    onImportClick = () => {
        this.toastBC.current.clear()
        this.setState({
            displayImportCollections: true,
            selectedDocumentsAction: [],
            inputText: '',
            displayCustomToast: false
        })
    }

    handleCloseExportDialog = () => {
        this.setState({
            displayExportCollections: false,
            globalFilterCollections: '',
            selectedCollectionsExport: []
        })
    }

    handleCloseImportDialog = () => {
        this.setState({
            displayImportCollections: false
        })
    }

    uploadHandler = ({ files }) => {
        // --- hack: increase counter to change key of file upload component --- //
        // --- otherwise file upload works only once --- //
        const self = this;

        // --- check file type --- //
        const [file] = files;

        var reader = new FileReader();
        reader.onload = function (event) {
            // --- NOTE: event.target points to FileReader --- //
            var contents = event.target.result;
            self.setState({
                processInputText: true,
                inputText: contents
            });
        };

        reader.readAsText(file);

    };

    handleImportCollections = async () => {
        function IsJsonString(str) {
            try {
                let json = JSON.parse(str)
                return (typeof json === 'object')
            } catch (e) {
                return false
            }
        }
        if (IsJsonString(this.state.inputText)) {
            let importedCollections = JSON.parse(this.state.inputText)
            let allNewCollectionNames = []
            importedCollections.forEach(col => {
                allNewCollectionNames = [...allNewCollectionNames, col.name]
            })
            let exists = false

            allNewCollectionNames.forEach(name => {
                if (name.length > 50) {
                    exists = true
                    this.showNameLengthWarnGrowl(name)
                }
            })

            if (!exists) {
                this.setState({
                    fetching: true,
                    loadingMessage: 'Exporting document collections'
                })
                let operationsCompleted = 0;
                let documentCollections = []
                function operation(name, oldID, newID) {

                    ++operationsCompleted;
                    if (name !== undefined) {
                        documentCollections = [...documentCollections, { name: name, old: oldID, new: newID }]
                    }
                    if (operationsCompleted === importedCollections.length) {
                        let defaultID = ''
                        self.state.docCollections.forEach(col => {
                            if (col.defaultCollection) {
                                defaultID = col.id
                            }
                        })
                        allDocuments.forEach(doc => {
                            let newCollections = []
                            doc.document.documentCollectionList.forEach(list => {
                                documentCollections.forEach(collection => {
                                    if (!list.defaultCollection && (list.id === collection.old)) {
                                        newCollections = [...newCollections, collection.new]
                                    } else if (list.defaultCollection && collection.old === undefined) {
                                        newCollections = [...newCollections, defaultID]
                                    }
                                })
                            })
                            doc.newCollections = newCollections
                        })

                        let operationsCompletedDocuments = 0;

                        function operationDocuments() {
                            ++operationsCompletedDocuments;
                            if (operationsCompletedDocuments === allDocuments.length) {
                                self.setState({
                                    displayImportCollections: false,
                                    inputText: '',
                                    fetching: false
                                }, () => {
                                    self.refreshAllDocuments()
                                    self.refreshDocumentCollections()
                                    if (self.state.collectionID !== undefined) {
                                        self.refreshCollectionDocuments(self.state.selectedCollection)
                                    }
                                })
                            }
                        }

                        let allRepositories = []

                        if (self.props.availableRepositories) {
                            self.props.availableRepositories.forEach(rep => {
                                allRepositories = [...allRepositories, { name: rep.name, repositoryId: rep.id }]
                            })
                        }

                        allDocuments.forEach(doc => {
                            allRepositories.forEach(rep => {
                                if (rep.name === doc.document.repository) {
                                    doc.document.repositoryId = rep.repositoryId
                                }
                            })
                            if (doc.document.repositoryId !== undefined) {
                                let collectionIDs = ''
                                doc.newCollections.forEach(newCol => {
                                    collectionIDs += newCol + ','
                                })
                                collectionIDs = collectionIDs.substring(0, collectionIDs.length - 1)
                                axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/documents?collectionIds=${collectionIDs}`, [{
                                    docId: doc.document.docId,
                                    title: doc.document.title,
                                    repositoryId: doc.document.repositoryId,
                                    repository: doc.document.repositoryId
                                }], { headers: getHeaderToken() })
                                    .then(function (response) {
                                        operationDocuments()
                                    })
                                    .catch(function (response) {
                                        if (response.response.status === 400) {
                                            self.showFailGrowl(response.response.data)
                                            operationDocuments()
                                        } else if (response.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (response.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            } else {
                                self.showFailGrowl("Repository of document not available. Document could not be imported.")
                                operationDocuments()
                            }
                        })
                    }
                }

                let self = this
                let allDocuments = []
                importedCollections.forEach(async col => {
                    //console.log(col)
                    if (col.documents && col.documents.length > 0) {
                        col.documents.forEach(doc => {
                            //console.log(doc)
                            if (!(allDocuments.some(el => el.document.docId === doc.document.docId)) || allDocuments.length === 0) {
                                allDocuments = [...allDocuments, doc]
                            }
                        })
                    }

                    if (!col.defaultCollection) {
                        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections`,
                            {
                                name: col.name,
                                description: col.description,
                                sharedForDepartment: false,
                                editableForSharedUsers: false,
                                usersShared: []
                            }, { headers: getHeaderToken() })
                            .then(function (response) {
                                //console.log(response)
                                operation(col.name, col.id, response.data)
                            })
                            .catch(function (error) {
                                if (error.response.status === 400) {
                                    self.showCreateCollectionFailGrowl(error.response.data)
                                    operation()
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    self.setState({
                                        displayDialogAddCollection: false
                                    })
                                    console.log("not found")
                                }
                            })
                    } else {
                        operation(col.name, undefined, undefined)
                    }
                })
                //console.log(allDocuments)
            }
        } else {
            this.showFailGrowl("No valid JSON object.")
        }
    }

    onExportCollectionsChange = (e) => {
        this.setState({
            selectedCollectionsExport: e.value
        })
    }

    handleExportCollections = () => {
        let filteredCollectionsExport = []
        let allCollectionsExport = JSON.parse(JSON.stringify(this.state.selectedCollectionsExport))
        let operationsCompleted = 0;
        function operation() {
            ++operationsCompleted;
            if (operationsCompleted === allCollectionsExport.length) {
                allCollectionsExport.forEach(list => {
                    /*delete list.editableForSharedUsers
                    delete list.id
                    delete list.numberQueries
                    delete list.owner
                    delete list.sharedForDepartment
                    delete list.shared
                    delete list.created
                    delete list.modified*/
                    delete list.created
                    delete list.modified
                    delete list.sharedForDepartment
                    delete list.shared
                    delete list.notifications
                    delete list.editableForSharedUsers
                    delete list.usersSharedWith
                    delete list.departmentSharesRead
                    delete list.userSharesRead
                    delete list.userSharesWrite
                    filteredCollectionsExport = [...filteredCollectionsExport, list]
                })
                saveAs(new Blob([JSON.stringify(filteredCollectionsExport, undefined, 4)]), `document_collections.txt`)
                self.setState({
                    displayExportCollections: false,
                    globalFilterCollections: '',
                    selectedCollectionsExport: []
                })
            }
        }
        let self = this
        allCollectionsExport.forEach(list => {
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${list.id}/page/0/count/1000/list`, { headers: getHeaderToken() })
                .then(function (response) {
                    let documents = response.data.content
                    documents.forEach(doc => {
                        delete doc.id
                        delete doc.insertionTime
                        delete doc.user
                        delete doc.document.rating
                        delete doc.document.repositoryAvailable
                        delete doc.document.repositoryId
                        doc.document.documentCollectionList && doc.document.documentCollectionList.forEach(list => {
                            delete list.notifications
                            delete list.created
                            delete list.modified
                            delete list.sharedForDepartment
                            delete list.shared
                            delete list.editableForSharedUsers
                            delete list.usersSharedWith
                            delete list.sharedComment
                            delete list.documentLink
                            delete list.owner
                            delete list.departmentSharesRead
                            delete list.userSharesRead
                            delete list.userSharesWrite
                        })
                    })
                    list.documents = documents
                    operation()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        })
    }

    nameTemplate = (rowData) => {
        if (rowData.hasOwnProperty('name')) {
            return <React.Fragment>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </React.Fragment>
        }
    }

    numberDocumentsTemplate = (rowData) => {
        if (rowData.hasOwnProperty('numberDocuments')) {
            return <React.Fragment>
                <span className="p-column-title">Documents</span>
                {rowData.numberDocuments}
            </React.Fragment>
        }
    }

    sharedTemplate(rowData) {
        return <div style={{ marginBottom: 5 }}>
            <span className="p-column-title">Sharing</span>
            {!rowData.shared ?
                ((rowData.departmentSharesRead && rowData.departmentSharesRead.length > 0) || (rowData.userSharesRead && rowData.userSharesRead.length > 0) || (rowData.userSharesWrite && rowData.userSharesWrite.length > 0)) ?
                    <span title={`You share this search collection with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This search collection can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This search collection is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }

    onCustomPage = (e, isPaginator) => {
        if (isPaginator) {
            this.setState({
                firstDocuments: e.first,
                rowsDocuments: e.rows
            })
        } else {
            this.setState({
                rowsDocuments: e.value
            })
        }
    }

    onSemanticExportSubmit = async (queryTerms, target, format) => {

        //console.log('export queryTerms: ', queryTerms);
        //console.log('export target: ', target);
        //console.log('export format: ', format);

        this.setState({
            fetchingData: true,
            loadingMessage: 'Exporting semantic concepts'
        });

        let targetID;
        let formatID;
        let category;
        let ocids = [];
        if (target && target.id) {
            targetID = target.id;
        }
        if (format && format.id) {
            formatID = format.id;
        }
        if (!isArrayEmpty(queryTerms)) {
            if (queryTerms[0].specialCase) {
                category = queryTerms[0].specialCase;
            }
            else if (queryTerms.length === 1 && queryTerms[0].isPreset &&
                queryTerms[0].domains && queryTerms[0].domains[0]) {
                category = queryTerms[0].domains[0];
            }
            /*
            else if (queryTerms[0].domains && queryTerms[0].domains[0]) {
                category = queryTerms[0].domains[0];
            }
            */
            else {
                category = 'ocids';
                queryTerms.forEach(qt => {
                    ocids.push(...qt.ocids);
                });
            }
        } else {
            category = '_ALL'
        }


        /*this.growl.current.show({
            sticky: true, closable: true, severity: 'info',
            summary: 'Not available yet.', detail: `This feature will be available soon. Please come back later.`
        });
        /**/

        let response

        if (this.state.selectedCollectionID !== undefined && this.state.selectedDocumentsAction && this.state.selectedDocumentsAction.length === 0 && targetID && formatID && category) {
            response = await semanticExportFromDocumentsByLibrary(targetID, formatID, this.state.selectedCollectionID, category, ocids);
        } else if (targetID && formatID && category) {
            let docIDs = []
            if (this.state.selectedCollectionID !== undefined && this.state.selectedDocumentsAction && this.state.selectedDocumentsAction.length === 0 && this.state.selectedDocuments && this.state.selectedDocuments.length > 0) {
                docIDs = this.state.selectedDocuments.filter(doc => doc.document.repositoryAvailable).map(doc => {
                    return {
                        documentIdentifier: doc.document.docId,
                        repositoryIdentifier: doc.document.repositoryId
                    };
                })
            } else if (this.state.selectedCollectionID === undefined && this.state.selectedDocumentsAction && this.state.selectedDocumentsAction.length === 0 && this.state.selectedDocuments && this.state.selectedDocuments.length > 0) {
                docIDs = this.state.selectedDocuments.filter(doc => doc.repositoryAvailable).map(doc => {
                    return {
                        documentIdentifier: doc.docId,
                        repositoryIdentifier: doc.repositoryId
                    };
                })
            } else if (this.state.selectedDocumentsAction && this.state.selectedDocumentsAction.length > 0) {
                docIDs = this.state.selectedDocumentsAction.filter(doc => doc.repositoryAvailable).map(doc => {
                    return {
                        documentIdentifier: doc.docId,
                        repositoryIdentifier: doc.repositoryId
                    };
                })
            }
            response = await semanticExportFromDocumentsByDocIDs(targetID, formatID, docIDs, category, ocids);
        }


        if (response && targetID === 'looker') {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                const datasetExportIdentifier = result.datasetExportIdentifier;
                const forwardUrl = result.forwardUrl;
                if (forwardUrl) {
                    window.open(forwardUrl, '_blank');
                }
                else {
                    this.growl.current.show({
                        sticky: true, closable: true, severity: 'success',
                        summary: 'Export successful', detail: `Dataset export identifier: ${datasetExportIdentifier}`
                    });
                }
            }
        }
        else {
            checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage />, null, true);
        }

        this.setState({
            fetchingData: false,
            loadingMessage: '',
            displaySemanticExport: false
        });
    }

    onExportSubmit = async () => {
        this.setState({ exportRunning: true });

        let docIDs;
        if (this.state.selectedExportDocSelection.id === 'all') {
            if (this.state.selectedCollectionID !== undefined && this.state.selectedDocuments && this.state.selectedDocuments) {
                docIDs = this.state.selectedDocuments.filter(doc => doc.document.repositoryAvailable).map(doc => {
                    return {
                        // documentIdentifier: parseInt(doc.ocDocId),

                        documentIdentifier: doc.document.docId,
                        repositoryIdentifier: doc.document.repositoryId
                    };
                })
            } else if (this.state.selectedCollectionID === undefined && this.state.selectedDocuments && this.state.selectedDocuments) {
                docIDs = this.state.selectedDocuments.filter(doc => doc.repositoryAvailable).map(doc => {
                    return {
                        // documentIdentifier: parseInt(doc.ocDocId),
                        documentIdentifier: doc.docId,
                        repositoryIdentifier: doc.repositoryId
                    };
                })
            }
        }
        else if (this.state.selectedExportDocSelection.id === 'selDocs') {
            if (this.state.selectedDocumentsAction && !isArrayEmpty(this.state.selectedDocuments)) {
                docIDs = this.state.selectedDocumentsAction.filter(doc => doc.repositoryAvailable).map(doc => {
                    return {
                        // documentIdentifier: parseInt(docID),
                        documentIdentifier: doc.docId,
                        repositoryIdentifier: doc.repositoryId
                    };
                });
            }
        }

        this.onMetadataExport({ docIDs: docIDs }, this.state.selectedTarget?.id, this.state.selectedFormat?.id);

        this.setState({
            displaySemanticExport: false,
            exportRunning: false
        });
    }

    onMetadataExport = async (exportData = {}, targetID, outputFormat) => {
        this.setState({
            fetchingData: true,
            fetchingInfo: 'Exporting results'
        });

        let response;
        if (exportData.docIDs) {
            response = await exportDocumentsMetaDataByDocIDs(exportData.docIDs, targetID, outputFormat);
        }
        if (response && targetID === EXPORTER_TARGET_ID_BIGQUERY) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                const datasetExportIdentifier = result.datasetExportIdentifier;
                const forwardUrl = result.forwardUrl;
                if (forwardUrl) {
                    window.open(forwardUrl, '_blank');
                }
                else {
                    this.growl.current.show({
                        sticky: true, closable: true, severity: 'success',
                        summary: 'Export successful', detail: `Dataset export identifier: ${datasetExportIdentifier}`
                    });
                }
            }
        }
        else {
            //console.log('***response: ', response);
            //checkResultAndGetPayload(response, this.growl);
            // TODO: add success message
            checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage />, null, true);
        }

        this.setState({ fetchingData: false });
    }


    getModifiedDocuments = docs => (docs?.map((item, index) => {
        if (item.document) {
            return {
                number: index + 1,
                ...item.document,
                user: item.user
            }
        } else {
            return {
                number: index + 1,
                ...item
            }
        }

    }))

    onAddToReadcube = () => {
        this.setState({
            displayAddReadcubeDialog: true
        })
    }

    onSubmitReadcubeLibrary = async (lib) => {

        let docIds = []

        if (this.state.selectedDocumentsAction && this.state.selectedDocumentsAction.length > 0) {
            this.state.selectedDocumentsAction.forEach(doc => {
                docIds = [...docIds, { documentIdentifier: doc.docId, repositoryIdentifier: doc.repositoryId }]
            });
            let response;
            response = await exportDocumentsToReadcube(docIds, 'readcube', lib.id);
            if (response) {
                const result = checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage toReadcubeExports={true} />, null, true);
                if (response.status === 'SUCCESS') {
                    this.setState({
                        displayAddReadcubeDialog: false
                    })
                }
            }
        }
        else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'No document selected.'
            });
        }
    }
    render() {
        const fetchingData = this.state.fetching || this.state.fetchingData
        if (this.props.availableRepositories) {
            this.patentRepo = this.props.availableRepositories.find(obj => {
                return obj.name === 'patents'
            })
        }

        const hasSelectedDocuments = this.state.selectedDocumentsAction && !isArrayEmpty(this.state.selectedDocumentsAction);
        //&& (EXPORTER_DOC_SELECTIONS.some(docSel => docSel.id === 'selDocs'))
        const exporterDocSelectionOptions = EXPORTER_DOC_SELECTIONS_LIBRARY.filter(docSel =>
            (hasSelectedDocuments || docSel.id !== 'selDocs') && (!docSel.onlyViaExportCenter)
        );

        /*const footerCreateCollection = (
            <div>
                <label style={{ float: 'left', paddingTop: 20 }}>* required</label>
                <div>
                    <Button label="Cancel" onClick={(e) => this.hideCreateCollection(e)} className="p-button-secondary p-button-sm" />
                    <Button
                        label="Create"
                        className='p-button-sm primaryButton'
                        onClick={this.onSaveCollection}
                        disabled={this.state.newCollectionName && this.state.newCollectionName.length > 0 ? false : true}
                        style={{ float: 'right', marginRight: -5 }} />
                </div>
            </div>
        )*/

        const sharedWithDepartmentOptions = [
            { label: 'yes', value: true },
            { label: 'no', value: false }
        ]

        let members = []

        this.state.departmentMembers && this.state.departmentMembers.forEach(member => {
            members = [...members, { label: `${member.lastName}, ${member.forename} (${member.username})`, value: member.id }]
        })

        let headerCollections = <div className='grid' style={{
            marginRight: 0, paddingLeft: 10,
            paddingTop: 18, paddingBottom: 10
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search collections">
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', width: '83%', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap' }}
                    type="search"
                    autoComplete="off"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterCollections: e.target.value })}
                    placeholder="Search collections" />
            </div>
        </div>

        let columnDataCollections = this.state.colsCollections.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        const acceptedFileTypes = ['.txt']
        const acceptedFileTypesString = acceptedFileTypes.join(',')

        let filteredCollections = []

        if (this.state.docCollections && this.state.docCollections.length > 0) {
            filteredCollections = this.state.docCollections.filter(obj => {
                return !obj.sharedComment && obj.numberDocuments > 0
            })
        }

        let activeToastOptions = ['copyElements', 'moveElements', 'deleteElements', 'export'] //, 'export', 'addToReadcube']
        if (hasUserRole('ROLE_READCUBEVIEW')) {
            activeToastOptions = [...activeToastOptions, 'addToReadcube']
        }

        return (
            <div className="grid">
                <Toast ref={this.growl} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >

                    <div className='col-12' style={{ float: 'left', padding: 0 }}>
                        {/*<div className='col-12' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 style={{ marginTop: 0, marginLeft: -5 }} className="pageHeader">My Library</h1>
                            <img src={pageLogo} alt={`library logo`} style={{ height: 120, marginTop: -10, paddingRight: 20 }} />
        </div>*/}

                        <div className='col-12' style={{ paddingBottom: 0 }}>
                            <div className='grid'>
                                <div className="col textAlignLeft" style={{ paddingBottom: 0 }}>
                                    <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader">My Library</h1>
                                </div>
                                <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                                    {!isArrayEmpty(this.state.pageTutorials) ?
                                        <span style={{ marginLeft: 30 }}>
                                            <TutorialVideos
                                                pageTutorials={this.state.pageTutorials}
                                                section='library'
                                                sectionLabel='My Library'
                                            /></span> : null}
                                </div>
                            </div>
                        </div>


                        <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10" style={{
                            paddingLeft: 7 //paddingLeft: 0, paddingBottom: 25, marginTop: -60 
                        }}>
                            <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}>The library is a place to collect documents of
                                interest by sorting them into document collections that can be created, edited and even shared here. It also contains a list of your bookmarked documents.
                            </label>
                        </div>
                    </div>
                    <div className='col-12 tabview-demo' style={{ display: 'inline-block', paddingTop: 0 }}>
                        <TabView style={{ paddingRight: 0 }} className="tabview-custom" activeIndex={this.state.activeIndex} onTabChange={(e) => this.onTabChange(e)}>
                            <TabPanel header="Collections">
                                <div className='grid col-12' style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                                    <div className='col-6 sm:col-6 md:col-5 lg:col-4 xl:col-3' style={{ paddingLeft: 0, borderRight: '1px solid #d6d6d6', paddingTop: 0, paddingRight: 10 }}>
                                        <DocCollections
                                            history={this.props.history}
                                            userData={this.props.userData}
                                            allDocuments={this.state.allDocuments}
                                            docCollections={this.state.docCollections}
                                            sortCollectionBy={this.state.sortCollectionBy}
                                            onSortCollection={this.onSortCollection}
                                            handleCollectionChange={this.handleCollectionChange}
                                            collectionAlertChange={this.collectionAlertChange}
                                            openAddAlertDialog={this.openAddAlertDialog}
                                            onAddCollection={this.onAddCollection}
                                            collectionID={this.state.collectionID}
                                            addNewCollection={true}
                                            onExportClick={this.onExportClick}
                                            onImportClick={this.onImportClick}>
                                        </DocCollections>
                                    </div>
                                    <div className='col-6 sm:col-6 md:col-7 lg:col-8 xl:col-9' style={{ paddingTop: 0, paddingLeft: 15, paddingRight: 0 }}>
                                        <CollectionDocuments
                                            availableRepositories={this.props.availableRepositories}
                                            onShowDocviewModal={this.onShowDocviewModal}
                                            departmentMembers={this.state.departmentMembers}
                                            history={this.props.history}
                                            userData={this.props.userData}
                                            docCollections={this.state.docCollections}
                                            selectedCollection={this.state.selectedCollection}
                                            collectionName={this.state.collectionName}
                                            collectionDescription={this.state.collectionDescription}
                                            selectedDocuments={this.state.selectedDocuments}
                                            deleteCollection={this.deleteCollection}
                                            modifyCollection={this.modifyCollection}
                                            onRemoveCollection={this.onRemoveCollection}
                                            onModifyCollection={this.onModifyCollection}
                                            displayDialogModifyCollection={this.state.displayDialogModifyCollection}
                                            displayDialogRemoveCollection={this.state.displayDialogRemoveCollection}
                                            onHideRemoveCollection={this.onHideRemoveCollection}
                                            onHideModifyCollection={this.onHideModifyCollection}
                                            collectionAlertChange={this.collectionAlertChange}
                                            openAddAlertDialog={this.openAddAlertDialog}
                                            handleIntervalChange={this.handleIntervalChange}
                                            ratingChange={this.ratingChange}
                                            readStatusChange={this.readStatusChange}
                                            canEdit={this.state.canEdit}
                                            copyDocuments={this.copyDocuments}
                                            moveDocuments={this.moveDocuments}
                                            selectedDocumentsAction={this.state.selectedDocumentsAction}
                                            selectedCollectionsAction={this.state.selectedCollectionsAction}
                                            handleUnselect={this.handleUnselect}
                                            removeDocuments={this.removeDocuments}
                                            selectDocuments={this.selectDocuments}
                                            selectCollections={this.selectCollections}
                                            handleCopyDocumentDialog={this.handleCopyDocumentDialog}
                                            handleCloseCopyDocumentDialog={this.handleCloseCopyDocumentDialog}
                                            handleRemoveDocumentsDialog={this.handleRemoveDocumentsDialog}
                                            displayDialogCopyDocument={this.state.displayDialogCopyDocument}
                                            handleMoveDocumentDialog={this.handleMoveDocumentDialog}
                                            handleCloseMoveDocumentDialog={this.handleCloseMoveDocumentDialog}
                                            handleCloseRemoveDocumentDialog={this.handleCloseRemoveDocumentDialog}
                                            displayDialogMoveDocument={this.state.displayDialogMoveDocument}
                                            displayDialogRemoveDocuments={this.state.displayDialogRemoveDocuments}
                                            onAddCollection={this.onAddCollection}
                                            collectionID={this.state.collectionID}
                                            closeModifyCollection={this.onHideModifyCollection}
                                            startTime={this.state.startTime}
                                            callbackFromTimeChange={this.callbackFromTimeChange}
                                            docIntervals={this.state.docIntervals}
                                            firstDocuments={this.state.firstDocuments}
                                            rowsDocuments={this.state.rowsDocuments}
                                            onCustomPage={this.onCustomPage}
                                            handleSemanticExportDialog={this.handleSemanticExportDialog}
                                            containsPatents={this.state.containsPatents}
                                            allPossibleUsers={this.state.allPossibleUsers}
                                            allPossibleSuborgs={this.state.allPossibleSuborgs}
                                            allNotAllowedUsersRead={this.state.allNotAllowedUsersRead}
                                            allNotAllowedUsersWrite={this.state.allNotAllowedUsersWrite}
                                            allNotAllowedSuborgs={this.state.allNotAllowedSuborgs}
                                            userArray={this.state.userArray}
                                            orgArray={this.state.orgArray}>
                                        </CollectionDocuments>
                                        {this.state.displayAddEditAlert ?
                                            <AddEditAlert
                                                displayAddEditAlert={this.state.displayAddEditAlert}
                                                displayConfirmationDialog={this.state.displayConfirmationDialog}
                                                onHide={this.closeAddEditAlert}
                                                onHideConfirmationDialog={this.onHideConfirmationDialog}
                                                type="document collection"
                                                selectedCollectionAddEdit={this.state.selectedCollectionAddEdit}
                                                intervals={this.state.docIntervals}
                                                callbackFromSave={this.callbackFromSave}
                                                onDeleteAlert={this.onDeleteAlert}
                                                explanationText="Emails are sent if i) the owner of a document collection that is shared with you adds new
                                                 documents to this document collection or ii) new documents are added by your colleagues to a document
                                                  collection that you share.">
                                            </AddEditAlert>
                                            : null
                                        }
                                        <CreateCollection
                                            displayDialog={this.state.displayDialogAddCollection}
                                            onSubmit={this.saveCollection}
                                            userArray={this.state.userArray}
                                            orgArray={this.state.orgArray}
                                            departmentMembers={this.state.departmentMembers}
                                            onHide={(e) => this.hideCreateCollection(e)}
                                        />
                                        <Dialog focusOnShow={false} visible={this.state.displayExportCollections} style={{ 'width': "50vw" }}
                                            header="Export document collections"
                                            modal={true} dismissableMask={true} onHide={() => this.handleCloseExportDialog()} className='styledDialog'>
                                            <React.Fragment>
                                                {this.state.docCollections && this.state.docCollections.length > 0 &&
                                                    <React.Fragment>
                                                        <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 25, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>

                                                            <div style={{ width: '100%' }} className="datatable-responsive-demo">
                                                                {headerCollections}
                                                                <DataTable ref={(el) => this.dt = el}
                                                                    //scrollable
                                                                    //scrollHeight='190px'
                                                                    selection={this.state.selectedCollectionsExport}
                                                                    selectionMode="checkbox"
                                                                    removableSort={true}
                                                                    onSelectionChange={e => this.onExportCollectionsChange(e)}
                                                                    //className="dataTableQueries checkboxCircle standardTableHeader"
                                                                    className="p-datatable-responsive-demo"
                                                                    value={filteredCollections}
                                                                    responsive="true"
                                                                    paginator={true}
                                                                    rows={5}
                                                                    globalFilter={this.state.globalFilterCollections}>
                                                                    {columnDataCollections}
                                                                </DataTable>
                                                            </div>
                                                        </div>
                                                        <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                                                            {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                            title="Create new watchlist" onClick={this.addNewWatchlist}>
                        Create collection</a>*/}
                                                            <Button label="Export"
                                                                disabled={this.state.selectedCollectionsExport && this.state.selectedCollectionsExport.length > 0 ? false : true}
                                                                onClick={this.handleExportCollections}
                                                                className='p-button-sm primaryButton'
                                                                style={{ marginRight: 0, float: 'right', marginTop: 0 }}
                                                            //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                                            />
                                                            {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                                            <Button label="Cancel" onClick={() => this.handleCloseExportDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 0, marginRight: '0.5rem' }} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        </Dialog>
                                        <Dialog focusOnShow={false} visible={this.state.displayImportCollections} style={{ 'width': "50vw" }}
                                            header="Import document collections"
                                            modal={true} dismissableMask={true} onHide={() => this.handleCloseImportDialog()}
                                            className='styledDialog'>
                                            <React.Fragment>
                                                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                                    <div className="col-12">
                                                        <FileUpload
                                                            name="upload"
                                                            className="primaryButton"
                                                            accept={acceptedFileTypesString}
                                                            customUpload={true}
                                                            uploadHandler={e => this.uploadHandler(e)}
                                                            mode="basic"
                                                            auto={true}
                                                            chooseLabel="Upload file" />
                                                    </div>
                                                    <div className="col-12" style={{ paddingRight: 0, paddingBottom: 10 }}>
                                                        <InputTextarea
                                                            id="content"
                                                            autoResize={true}
                                                            readOnly={false}
                                                            //rows={5}
                                                            //cols={30}
                                                            className="width100perc"
                                                            value={this.state.inputText}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    inputText: e.target.value
                                                                });
                                                            }}
                                                            style={{ height: '50vh', maxHeight: '50vh' }}
                                                        />
                                                    </div>
                                                </div>
                                                <React.Fragment>
                                                    <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                                                        {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                            title="Create new watchlist" onClick={this.addNewWatchlist}>
                        Create collection</a>*/}
                                                        <Button label="Import"
                                                            onClick={this.handleImportCollections}
                                                            disabled={this.state.inputText && this.state.inputText !== "" ? false : true}
                                                            className='p-button-sm primaryButton'
                                                            style={{ marginRight: 0, float: 'right' }}
                                                        //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                                        />
                                                        {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                                        <Button label="Cancel" onClick={() => this.handleCloseImportDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginRight: '0.5rem' }} />
                                                    </div>
                                                </React.Fragment>
                                            </React.Fragment>
                                        </Dialog>
                                    </div>
                                </div>
                            </TabPanel>
                            {addDynamicPanel(hasUserRole('ROLE_READDOCUMENTS')).map(hack => {
                                return <TabPanel key={hack} header="Bookmarked documents">
                                    <ReadDocumentsContainer>
                                    </ReadDocumentsContainer>
                                </TabPanel>
                            })}
                        </TabView>
                    </div>
                    <Toast ref={this.toastBC} position="bottom-center" style={{ backgroundColor: 'white', opacity: '1', bottom: 55, boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }} />
                    <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                        onHide={this.handleUnselect} style={{
                            width: //this.state.canEdit && this.state.collectionID !== undefined ? '41rem' : '33rem', bottom: 55 
                                activeToastOptions?.length === 5 && (this.state.collectionID !== undefined) ? '42rem' :
                                    activeToastOptions?.length === 5 && (this.state.collectionID === undefined) ? '36rem'
                                        : activeToastOptions?.length === 4 && (this.state.collectionID !== undefined) ? '33rem'
                                            : activeToastOptions?.length === 4 && (this.state.collectionID === undefined) ? '28rem'
                                                : activeToastOptions?.length === 3 ? '28rem' : '20rem'
                        }}
                        draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                        <ToastContent selectedElements={this.state.selectedDocumentsAction}
                            elementLabel='document'
                            onHide={this.handleUnselect}
                            onCopyElements={this.handleCopyDocumentDialog}
                            onMoveElements={this.handleMoveDocumentDialog}
                            onDelete={this.handleRemoveDocumentsDialog}
                            onExport={this.handleSemanticExportDialog}
                            userDetails={this.props.userData.userDetails}
                            canEdit={this.state.canEdit}
                            collectionID={this.state.collectionID}
                            onAddToReadcube={this.onAddToReadcube}
                            activeOptions={//['copyElements', 'moveElements', 'deleteElements', 'export', 'addToReadcube']
                                activeToastOptions} />
                    </Dialog>
                    <Dialog focusOnShow={false}
                        visible={this.state.displaySemanticExport}
                        style={{ 'width': "60vw", height: 325 }}
                        header={`Export`}
                        modal={true}
                        blockScroll
                        dismissableMask={true}
                        onHide={() => this.handleCloseSemanticExportDialog()}
                        className='styledDialog'
                        onShow={e => {
                            this.setState({
                                selectedExportDocSelection: exporterDocSelectionOptions[0],
                                selectedFormat: EXPORTER_FORMATS[0]
                            });
                        }}>
                        <LoadingOverlay
                            active={fetchingData}
                            spinner={true}
                            text="Export in progress..." >
                            <div className='col-12' style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 10, marginRight: 0 }}>
                                <TabView>
                                    <TabPanel
                                        key="metadata"
                                        header="Metadata">
                                        <div style={{ borderBottom: '1px solid #d6d6d6', marginRight: -12, height: 142 }}>
                                            <div style={{ margin: '5px 0px 30px 0px', lineHeight: 1.5 }}>
                                                Metadata export will help you extract metadata information from documents like title,
                                                author/assignee, publication dates and various other fields that might be general or more
                                                specific for certain document types.
                                            </div>
                                            <div className="grid">

                                                <div className="col-fixed" style={{ width: 130 }}>Source documents</div>
                                                <div className="col">
                                                    {exporterDocSelectionOptions
                                                        .filter(docSel => (docSel.id !== 'selDocs' || hasSelectedDocuments))
                                                        .map(docSel => {
                                                            if (docSel.id === 'custom') {
                                                                return <div key={docSel.id}
                                                                    style={{ display: 'inline-block', marginBottom: 10, marginTop: 0, marginRight: 10, top: 0 }}
                                                                    className="p-field-radiobutton inline whiteSpaceNoWrap">
                                                                    <RadioButton
                                                                        inputId={docSel.id}
                                                                        name="docSel"
                                                                        value={docSel}
                                                                        onChange={(e) => this.setState({ selectedExportDocSelection: e.value })}
                                                                        checked={this.state.selectedExportDocSelection && this.state.selectedExportDocSelection.id === docSel.id}
                                                                    />
                                                                    <label style={{ marginRight: 10 }} htmlFor={docSel.id}>{docSel.label}</label>
                                                                    <InputText
                                                                        style={{ width: 100 }}
                                                                        disabled={!this.state.selectedExportDocSelection || this.state.selectedExportDocSelection.id !== docSel.id}
                                                                        onInput={(e) => this.setState({ exportLimit: e.target.value })}
                                                                        placeholder={`max. ${EXPORTER_NUM_OF_DOCS_LIMIT}`}
                                                                    />
                                                                </div>
                                                            }
                                                            //if (docSel.id !== 'selDocs' || hasSelectedDocuments) {
                                                            return <div key={docSel.id}
                                                                style={{
                                                                    display: 'inline-block', marginBottom: 10, marginTop: 0, marginRight: 10, top: 0,
                                                                    opacity: (docSel.potSlowPerformance) ? 0.5 : 1
                                                                }}
                                                                className="p-field-radiobutton inline whiteSpaceNoWrap">
                                                                <RadioButton
                                                                    inputId={docSel.id}
                                                                    name="docSel"
                                                                    value={docSel}
                                                                    onChange={(e) => this.setState({ selectedExportDocSelection: e.value })}
                                                                    checked={this.state.selectedExportDocSelection && this.state.selectedExportDocSelection.id === docSel.id}
                                                                    disabled={docSel.potSlowPerformance}
                                                                    style={{ cursor: (docSel.potSlowPerformance) ? 'default' : '' }}
                                                                />
                                                                <label style={{ marginRight: 10 }} htmlFor={docSel.id}>
                                                                    {docSel.label}{docSel.id === 'selDocs' ? ` (${this.state.selectedDocumentsAction ? this.state.selectedDocumentsAction.length : ''})` : ''}
                                                                </label>
                                                            </div>
                                                            //}
                                                        })}
                                                    {/*<span style={{ whiteSpace: 'nowrap' }}>
                                        {starQuery ? '* export options not available for empty search' : '* export may take a while'}
                                        </span>*/}
                                                </div>
                                                <div className="breakRow"></div>

                                                <div className="col-12">
                                                    {this.state.exporterTargets?.length > 1 ?
                                                        <>
                                                            <label className="valignMiddle" htmlFor="exportTo">Export to:</label>
                                                            <Dropdown
                                                                id='exportTo'
                                                                value={this.state.selectedTarget}
                                                                options={this.state.exporterTargets}
                                                                onChange={e => this.setState({ selectedTarget: e.value })}
                                                                placeholder="Select target"
                                                                appendTo={document.body}
                                                            />
                                                        </> : null}
                                                    {this.state.selectedTarget?.type === EXPORTER_TARGET_ID_FILE ?
                                                        <>
                                                            <label htmlFor="format">File format:</label>
                                                            <Dropdown
                                                                id='format'
                                                                value={this.state.selectedFormat}
                                                                options={EXPORTER_FORMATS}
                                                                onChange={e => this.setState({ selectedFormat: e.value })}
                                                                placeholder="Select file format"
                                                                appendTo={document.body}
                                                            />
                                                        </> : null}
                                                </div>
                                                <div className="breakRow"></div>

                                                <div className="col">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div style={{ paddingTop: 5, float: 'left', paddingLeft: 0 }}>
                                                <ExportLimit maximumEntriesToExport={GLOBAL_METADATA_DOWNLOAD_LIMIT} />
                                            </div>
                                            <Button style={{ marginTop: 4, float: 'right', marginBottom: 4, marginRight: -20 }}
                                                className='p-button-sm primaryButton'
                                                label="Export"
                                                //tooltip='Export semantic concepts'
                                                onClick={e => {
                                                    //this.setState({ displayExportDialog: false });
                                                    this.onExportSubmit();
                                                }}
                                            />
                                        </div>
                                    </TabPanel>
                                    {addDynamicPanel(hasUserRole("ROLE_SEMANTICEXPORT")).map(hack => {
                                        return <TabPanel key='semantic' header='Semantic export'>
                                            <Extractor
                                                allowCustomInput={true}
                                                onExport={this.onSemanticExportSubmit}
                                                appendSuggestionsTo={document.body}>
                                            </Extractor>
                                        </TabPanel>
                                    })}
                                    {addDynamicPanel(hasUserRole("ROLE_SEQUENCEEXPORT")// && this.state.containsPatents
                                    ).map(hack => {
                                        return <TabPanel key='sequence' header='Sequence export'>
                                            {this.state.containsPatents && this.patentRepo !== undefined ?
                                                <SequenceExportContainer
                                                    selectedCollection={this.state.selectedCollection}>
                                                </SequenceExportContainer>
                                                :
                                                !this.state.containsPatents && this.patentRepo !== undefined ?
                                                    <div>
                                                        {`This document collection does not contain documents from the repository ${this.patentRepo.label}. Exporting sequences is only possible
                                                    for documents from this repository.`}
                                                    </div>
                                                    :
                                                    <div>
                                                        {`Sequence export not available.`}
                                                    </div>
                                            }
                                        </TabPanel>
                                    })}
                                </TabView>
                            </div>
                        </LoadingOverlay>
                    </Dialog>

                    <DocViewModal
                        isVisible={this.state.isDocviewModalVisible}
                        docId={this.state.ocDocId}
                        repId={this.state.repositoryId}
                        selectedDoc={this.state.selectedDocument}
                        documents={{ '1': this.getModifiedDocuments(this.state.selectedDocuments) }}
                        onCloseDocviewModal={this.onCloseDocviewModal}
                        onPageChange={() => { }}
                        first={0}
                        rows={this.state.selectedDocuments?.length}
                        documentCount={this.state.selectedDocuments?.length}
                    />
                    <AddReadcubeDialog
                        displayDialog={this.state.displayAddReadcubeDialog}
                        onHide={() => this.setState({ displayAddReadcubeDialog: false })}
                        onSubmitReadcubeLibrary={this.onSubmitReadcubeLibrary}
                        readcubeRequests={null}>
                    </AddReadcubeDialog>
                </LoadingOverlay>
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        onItemSelectedClicked: (value) => {
            dispatch(setQuickSearchSelectedTerms({ newTerms: [value?.filter((item) => item.domains)] }))
        }
    }
}
export default withRouter(connect(null, mapDispatchToProps)(Library))