import { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
//import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";


class FilterSectionRadioSelection extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }

    render() {

        const { filterID, filterEntries, //placeholder,
            selectedEntry, filterInfo, onFilterInputValuesChange, searchTarget } = this.props;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className="grid">
                    <div className="col">
                        {filterEntries ?
                            <div key={filterID} style={{ marginLeft: 0 }}>

                                {filterEntries.filter(filterEntry => filterEntry.attrAllowMap && filterEntry.attrAllowMap[searchTarget]).map((category) => {
                                    if (category.hide) return null;
                                    return (
                                        <div key={category.filterValue} className="field-radiobutton" style={{ padding: 5, margin: 'auto auto' }}>
                                            <RadioButton
                                                inputId={category.filterValue}
                                                name="category"
                                                value={category}
                                                onChange={(e) => {
                                                    //console.log('e: ', e.value);
                                                    const filterObject = e.value ? {
                                                        filterID: filterID,
                                                        value: e.value
                                                    } : {};
                                                    onFilterInputValuesChange(filterObject);
                                                }}
                                                checked={selectedEntry?.filterValue === category.filterValue}
                                            //disabled={category.hide === true} 
                                            //style={{ position: 'relative', top: 3 }}
                                            />
                                            <label htmlFor={category.filterValue} style={{ marginLeft: 10 }}>{category.filterValueLabel}</label>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                    </div>
                    {filterInfo ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {filterInfo}
                        </div> : null}
                </div>
            </>
        );
    }
}

export default FilterSectionRadioSelection;