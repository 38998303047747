import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    fetchPublicationListXLSX
} from '../../../../redux/actions/WebApiActions'
import SequenceExport from '../SequenceExport'

const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    userData: state.user.data,
    publicationListXLSX: state.webAPI.publicationListXLSX,
})

const mapDispatchToProps = dispatch => ({
    fetchPublicationListXLSX: (history, search) => dispatch(fetchPublicationListXLSX(history, search))
})

const SequenceExportContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SequenceExport))

export default SequenceExportContainer