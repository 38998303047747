export const biomarkerPopupTreeName = ({biomarker: {popupTreeName}}) => popupTreeName;
export const biomarkerIsFetching = ({biomarker: {isFetching}}) => isFetching;
export const biomarkerIsFirstRender = ({biomarker: {isFirstRender}}) => isFirstRender;
export const biomarkerAggregationResults = ({biomarker}) => biomarker?.searchResults?.aggregationResults;
export const biomarkerRoleMapping = ({biomarker: {roleMapping}}) => roleMapping;
export const biomarkerHeadersStructureSkeleton = ({biomarker: {headersStructureSkeleton}}) => headersStructureSkeleton;
export const biomarkerLeftAsideExpandedKeys = ({biomarker: {leftAsideExpandedKeys}}) => leftAsideExpandedKeys;
export const biomarkerNodes = ({biomarker: {nodes}}) => nodes;
export const biomarkerReposWithBiomarkerSearchFeature = ({biomarker: {reposWithBiomarkerSearchFeature}}) => reposWithBiomarkerSearchFeature;
export const biomarkerQueryTerms = ({biomarker: {queryTerms}}) => queryTerms;
export const biomarkerSelectedReposForSearch = ({biomarker: {selectedReposForSearch}}) => selectedReposForSearch;
export const biomarkerPopupNodes = ({biomarker: {popupNodes}}) => popupNodes;
export const biomarkerPopupExpandedKeys = ({biomarker: {popupExpandedKeys}}) => popupExpandedKeys;
export const biomarkerSearchResults = ({biomarker: {searchResults}}) => searchResults;
export const biomarkerSortField = ({biomarker: {sortField}}) => sortField;
export const biomarkerSortDirection = ({biomarker: {sortDirection}}) => sortDirection;
export const biomarkerFirstResultIndex = ({biomarker: {firstResultIndex}}) => firstResultIndex;
export const biomarkerResultCount = ({biomarker: {resultCount}}) => resultCount;
export const biomarkerCheckedResults = ({biomarker: {checkedResults}}) => checkedResults;
export const biomarkerMetaData = ({biomarker: {metaData}}) => metaData;
export const biomarkerRelationResults = ({biomarker: {searchResults}}) => searchResults?.relationResults;
export const biomarkerSuggestions = ({biomarker: {suggestions}}) => suggestions;
export const biomarkerInputText = ({biomarker: {inputText}}) => inputText;

export const bioactivitiesIsFirstRender = ({bioactivities: {isFirstRender}}) => isFirstRender;
export const bioactivitiesSelectedReposForSearch = ({bioactivities: {selectedReposForSearch}}) => selectedReposForSearch;
export const bioactivitiesQueryTerms = ({bioactivities: {queryTerms}}) => queryTerms;
export const bioactivitiesRoleMapping = ({bioactivities: {roleMapping}}) => roleMapping;
export const bioactivitiesSuggestions = ({bioactivities: {suggestions}}) => suggestions;
export const bioactivitiesInputText = ({bioactivities: {inputText}}) => inputText;
export const bioactivitiesFirstResultIndex = ({bioactivities: {firstResultIndex}}) => firstResultIndex;
export const bioactivitiesResultCount = ({bioactivities: {resultCount}}) => resultCount;
export const bioactivitiesIsFetching = ({bioactivities: {isFetching}}) => isFetching;
export const bioactivitiesSearchResults = ({bioactivities: {searchResults}}) => searchResults;
export const bioactivitiesNodes = ({bioactivities: {nodes}}) => nodes;
export const bioactivitiesSortField = ({bioactivities: {sortField}}) => sortField;
export const bioactivitiesSortDirection = ({bioactivities: {sortDirection}}) => sortDirection;
export const bioactivitiesCheckedResults = ({bioactivities: {checkedResults}}) => checkedResults;
export const bioactivitiesShowConceptPopup = ({bioactivities: {showConceptPopup}}) => showConceptPopup;
export const bioactivitiesTargetOcid = ({bioactivities: {targetOcid}}) => targetOcid;
export const bioactivitiesHeadersStructureSkeleton = ({bioactivities: {headersStructureSkeleton}}) => headersStructureSkeleton;
export const bioactivitiesLeftAsideExpandedKeys = ({bioactivities: {leftAsideExpandedKeys}}) => leftAsideExpandedKeys;
export const bioactivitiesAggregationResults = ({bioactivities}) => bioactivities?.searchResults?.aggregationResults;

export const documentInfo = ({quickSearchReducer}) => quickSearchReducer.documentInfo;