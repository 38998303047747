import { sendApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';


// ----------------------------------------------------------------------- //
// --- run requests ------------------------------------------------------ //
// ----------------------------------------------------------------------- //
/**
 * 
 */
export const fetchBlacklists = async () => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`);

    return result;
}

/**
 * 
 * @param {string} blacklistName 
 */
export const saveNewBlacklist = async (name, description, departments, usersRead, usersWrite) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, {
        title: name,
        description: description,
        departmentSharesRead: departments,
        userSharesRead: usersRead,
        userSharesWrite: usersWrite
    });

    return result;
}

/**
 * 
 * @param {string} blacklistID 
 * @param {string} ocid 
 * @param {string} prefName 
 * @param {string} type 
 * @param {string} scope 
 */
export const addTermsToBlacklist = async (blacklistID, terms, type, scope) => {

    //[{"ocid":"190000007700","conceptLookupType":"EXACT","scope":"entity","prefName":"Staurosporine"},{"ocid":"190000008384","conceptLookupType":"EXACT","scope":"entity","prefName":"Sirolimus"},{"ocid":"190000005307","conceptLookupType":"EXACT","scope":"entity","prefName":"Intaxel"}]

    const request = [];
    for (var ocid of Object.keys(terms)) {
        request.push({
            conceptLookupType: type, // "EXACT"
            ocid: ocid, // "108010076950"
            prefName: terms[ocid].prefname, // "Adenylyl Cyclases"
            scope: scope //"entity"
        });
    }

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${blacklistID}`, request);

    return result;
}