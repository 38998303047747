import React, { Component, createRef } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import ClassDetails from './details/ClassDetails';
import * as OntologyClassUtil from '../util/OntologyClassUtil';
import * as OntologyUtil from '../util/OntologyUtil';
import * as PhraseTokenUtil from '../util/PhraseTokenUtil';
import * as IDGeneratorUtil from '../util/IDGeneratorUtil';
import {
    saveFrameOntologyV2,
    retrieveFrameOntologyBackups, retrieveFrameOntologyBackup,
    translateSyntaxPatternStringToPhraseTokens,
    checkFrameOntology,
    exportKnowledgeCartridge,
    fetchFrameOntologyV2,
} from '../../../../api/content/FrameEditorApi';
import {
    cloneDeep,
    getNumberOfObjectsWithValueInArray,
    removeValueFromArray,
} from '../../util';
import { checkResultAndGetPayload } from '../../../../api';
import { TabView, TabPanel } from 'primereact/tabview';
import FrameOntologyTree from './tree/FrameOntologyTree';
import EditRoleTagBox from './editor/EditRoleTagBox';
import EditSyntaxTagBox from './editor/EditSyntaxTagBox';
import EditMacroTagBox from './editor/EditMacroTagBox';


const MINUS_HEIGHT = 285;

const VIEW_TYPES = [
    { label: 'Frames', value: OntologyUtil.SUBTREE_FRAME },
    { label: 'Roles', value: OntologyUtil.SUBTREE_ROLE },
    { label: 'Synsets', value: OntologyUtil.SUBTREE_SYNSET },
    { label: 'Macros', value: OntologyUtil.SUBTREE_MACRO },
];


class DevelopmentView extends Component {

    constructor(props) {
        super(props);

        const saveItems = [
            {
                label: 'Save with comment',
                command: (e) => { this.setState({ storeBackupVisible: true }); }
            },
            {
                label: 'Export cartridge',
                command: (e) => { exportKnowledgeCartridge(); } //window.location.hash = "/fileupload"
            }
        ];

        this.state = {
            frameOntology: { ...OntologyUtil.EMPTY_FRAME_ONTOLOGY },
            backups: [],

            activeViewType: OntologyUtil.SUBTREE_FRAME,
            activeIndex: 0,

            ontologyHeight: `calc((100vh - ${MINUS_HEIGHT}px))`,
            syntaxEditorHeight: `calc(100vh - ${MINUS_HEIGHT + 235}px)`,
            editBoxHeight: `calc((100vh - ${MINUS_HEIGHT + 40}px))`,
            viewSizeState: 1,

            saveItems: saveItems
        };

        this.growl = createRef();
    }

    /**
     * Fetches ontology data.
     * Adds event listeners.
     * @returns 
     */
    componentDidMount() {
        // --- fetch current frame ontology and ontology backups --- //
        this.fetchFrameOntology();
        this.fetchFrameOntologyBackups();

        // --- catch user leaving the page to send a warning --- //
        window.addEventListener('beforeunload', this.preventDefault)
        window.addEventListener('unload', this.alertUser)
        return () => {
            window.removeEventListener('beforeunload', this.preventDefault)
            window.removeEventListener('unload', this.alertUser)
        }
    }

    /**
     * Prevents default action.
     * @param {*} e 
     */
    preventDefault = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    /**
     * Alerts user with a prompt. 
     * E.g. for a warning when user wants to leave the page.
     */
    alertUser = async () => {
        this.setState({
            isPrompt: true
        });
    }

    // ----------------------------------------------------------------------- //
    // --- frame ontology ---------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Fetches last stored frame ontology.
     */
    fetchFrameOntology = async () => {

        this.setState({
            frameOntology: { ...OntologyUtil.EMPTY_FRAME_ONTOLOGY },
            busy: true,
            busyText: 'Fetching frame ontology...'
        });

        const response = await fetchFrameOntologyV2();
        const origFrameOntology = checkResultAndGetPayload(response, this.growl);
        //console.log('origFrameOntology: ', JSON.stringify(origFrameOntology));
        //console.log('frame ontology: ', origFrameOntology);

        /*
        const response1 = await saveFrameOntologyV2(origFrameOntology);
        const origFrameOntology1 = checkResultAndGetPayload(response1, this.growl);
        //console.log('origFrameOntology1: ', JSON.stringify(origFrameOntology1));

        if (1 == 1) {
            this.setState({
                busy: false,
            });
            return;
        }
        */


        // --- load frame ontology into editor --- //
        if (origFrameOntology) {
            this.loadFrameOntology(origFrameOntology); // 
        }
        else {
            this.setState({
                busy: false,
            });
        }
    }

    /**
     * Loads ontology into editor.
     * @param {*} origFrameOntology 
     */
    loadFrameOntology = (origFrameOntology) => { // 

        // --- adjust frame ontology for easier handling by frontend --- //
        const frameOntology = OntologyUtil.convertOntologyToFrontendFormat(origFrameOntology);
        //console.log('frameOntology: ', JSON.stringify(frameOntology));

        // --- get all select items from ontology --- //
        const frameSelectItems = OntologyUtil.extractClasses(frameOntology.frames, frameOntology.knownTermStanzas, true);
        const roleSelectItems = OntologyUtil.extractClasses(frameOntology.roles, frameOntology.knownTermStanzas, false);
        const synsetSelectItems = OntologyClassUtil.extractSynsets(frameOntology, false);
        const macroSelectItems = OntologyClassUtil.extractMacros(frameOntology);

        // --- expand first levels of subtrees --- //
        if (frameOntology.frames.length > 0) {
            const expKeys = {};
            expKeys[frameOntology.frames[0].key] = true;
            this.framesSubOntology.expandClasses(expKeys, false);
        }
        if (frameOntology.roles.length > 0) {
            const expKeys = {};
            expKeys[frameOntology.roles[0].key] = true;
            this.rolesSubOntology.expandClasses(expKeys, false);
        }
        if (frameOntology.synsets.length > 0) {
            const expKeys = {};
            expKeys[frameOntology.synsets[0].key] = true;
            this.synsetsSubOntology.expandClasses(expKeys, false);
        }

        this.setState({
            frameOntology: frameOntology,

            busy: false,

            activeViewType: OntologyUtil.SUBTREE_FRAME,
            activeIndex: 0,

            frameSelectItems: frameSelectItems,
            roleSelectItems: roleSelectItems,
            synsetSelectItems: synsetSelectItems,
            macroSelectItems: macroSelectItems,

            selectedFrame: null,
            selectedRole: null,
            selectedSynset: null,
            selectedMacro: null,
            selectedTag: null
        });
    }

    /**
     * Stores ontology, optionally with a user comment.
     */
    onSaveFrameOntology = async (comment) => {

        //const frameOntologyOldFormat = { ...this.state.frameOntology };
        const frameOntologyOldFormat = cloneDeep(this.state.frameOntology);
        //console.log('frameOntologyOldFormat: ', JSON.stringify(frameOntologyOldFormat));

        // --- remove redundant syntaxes --- //
        const numOfRemovedSyntaxes = OntologyUtil.removeRedundantSyntaxesFromFrameOntology(frameOntologyOldFormat.frames);
        if (numOfRemovedSyntaxes > 0) {
            this.growl.current.show({ severity: 'info', life: 10000, summary: 'Duplicate syntaxes removed.', detail: `${numOfRemovedSyntaxes} duplicate syntaxes were removed.` });
        }

        // --- convert ontology to middleware format --- //
        const frameOntology = OntologyUtil.convertOntologyToMiddlewareFormat(frameOntologyOldFormat, this.state.roleSelectItems);

        // json to string -> compare
        //console.log('frameOntology: ', frameOntology);
        //console.log('save frameOntology converted: ', JSON.stringify(frameOntology));
        //console.log('save frameOntology state: ', this.state.frameOntology);

        this.setState({
            busy: true,
            busyText: 'Saving frame ontology...'
        });

        // --- save ontology ----//
        const response = await saveFrameOntologyV2(frameOntology, comment);
        //console.log('save - response: ', response);
        if (response.status === 'SUCCESS') {
            this.growl.current.show({ severity: 'success', summary: 'Frame ontology saved.', detail: "Frame ontology successfully saved. Services are restarting." });
            // --- retrieve backups again, new ontology will be part of the list --- //
            await this.fetchFrameOntologyBackups();
        }
        else {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error saving frame ontology', detail: response.message });
        }

        this.setState({
            busy: false
        }, () => {
            console.log('this.state.frameOntology: ', this.state.frameOntology);
        });
    }

    /**
     * Stores ontology with a user comment.
     */
    onSaveFrameOntologyWithComment = async () => {

        this.setState({
            storeBackupVisible: false
        });
        await this.onSaveFrameOntology(this.state.backupComment);
    }

    // ----------------------------------------------------------------------- //
    // --- frame ontology backups -------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Fetches ontology backups.
     */
    fetchFrameOntologyBackups = async () => {

        let backupsNew = [];

        const response = await retrieveFrameOntologyBackups();
        const backups = checkResultAndGetPayload(response, this.growl);

        if (backups) {
            for (const backup of backups) {
                backup.label = `${new Date(backup.created).toLocaleString()} ${backup.user ? 'By ' + backup.user : ''}:  ${backup.comment}`;
            }
            backupsNew = backups;
        }

        this.setState({
            backups: backupsNew
        });
    }

    /**
     * Restores chosen ontology from backups.
     */
    restoreFrameOntologyBackup = async () => {

        if (this.state.selBackup && this.state.selBackup.id) {
            this.setState({
                frameOntology: { ...OntologyUtil.EMPTY_FRAME_ONTOLOGY },
                busy: true,
                busyText: 'Restoring frame ontology...'
            });

            const response = await retrieveFrameOntologyBackup(this.state.selBackup.id);
            const origFrameOntology = checkResultAndGetPayload(response, this.growl);

            //console.log('frame ontology: ', origFrameOntology);

            if (origFrameOntology) {
                this.loadFrameOntology(origFrameOntology);

                this.growl.current.show({
                    severity: 'success', summary: 'Ontology restored', life: 15000,
                    detail: `Ontology backup from ${new Date(this.state.selBackup.created).toLocaleString()} loaded.` 
                    + `${this.state.selBackup.comment}. Click "Save ontology" to overwrite the current version in the system.`
                });
            }
        }
        else {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error restoring frame ontology', detail: 'No valid backup selected.' });
        }

        this.setState({
            busy: false,
        });
    }

    // ----------------------------------------------------------------------- //
    // --- frame ontology check ---------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Checks ontology for errors.
     */
    onCheckFrameOntology = async () => {

        this.setState({
            busy: true,
            busyText: 'Checking frame ontology...'
        });

        // --- convert ontology to middleware format --- //
        const frameOntology = OntologyUtil.convertOntologyToMiddlewareFormat({ ...this.state.frameOntology }, this.state.roleSelectItems);
        //console.log('check frameOntology converted: ', frameOntology);

        // --- check ontology ----//
        const response = await checkFrameOntology(frameOntology);
        //console.log('check - response: ', response);

        if (response.status === 'SUCCESS') {
            this.growl.current.show({ severity: 'success', summary: 'Frame ontology ok.', detail: "Good job. No errors found in your frame ontology." });
        }
        else {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error(s) found in frame ontology', detail: response.message });
        }

        this.setState({
            busy: false
        });
    }

    // ----------------------------------------------------------------------- //
    // --- select ontology class --------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Selects frame node, displays respective class data.
     * Also selects tag from this frame if one is selected.
     * Translates defined roles and syntaxes to phrase tokens to display them.
     * @param {*} node 
     * @param {*} selTag 
     * @returns 
     */
    onSelectFrame = async (node, selTag = null) => {

        const ontClass = OntologyUtil.getClassForNode(this.state.frameOntology, node);
        //console.log('sel node: ', node);

        // --- no class selected -> reset selected frame and tag --- //
        if (!node || !ontClass) {
            this.setState({
                selectedFrame: null,
                selectedTag: null,
            });
            return;
        }

        // --- translate roles to phrase tokens --- //
        ontClass.roles = [];
        const roles = OntologyClassUtil.getTagsFromClass(ontClass, OntologyClassUtil.TAG_TYPE_ROLE);
        if (roles) {
            for (const roleTag of roles) {
                roleTag.key = roleTag.key ? roleTag.key : IDGeneratorUtil.generateNewRoleTagID();
                const { name, syntax } = OntologyClassUtil.extractNameAndSyntaxFromRoleTagValue(roleTag.value);
                const response = await translateSyntaxPatternStringToPhraseTokens(syntax);
                const rolePhraseTokens = checkResultAndGetPayload(response, this.growl);
                if (rolePhraseTokens) {
                    PhraseTokenUtil.generateNewTokenIDs(rolePhraseTokens);
                    roleTag.roleName = name;
                    roleTag.roleSyntax = syntax;
                    roleTag.phraseTokens = rolePhraseTokens;
                }

                ontClass.roles.push({
                    label: name,
                    value: name
                });
            }
        }

        // --- translate syntaxes to phrase tokens --- //
        const syntaxes = OntologyClassUtil.getTagsFromClass(ontClass, OntologyClassUtil.TAG_TYPE_SYNTAX);
        if (syntaxes) {
            for (const syntaxTag of syntaxes) {
                syntaxTag.key = syntaxTag.key ? syntaxTag.key : IDGeneratorUtil.generateNewSyntaxTagID();
                const response = await translateSyntaxPatternStringToPhraseTokens(syntaxTag.value);
                const syntaxPhraseTokens = checkResultAndGetPayload(response, this.growl);
                if (syntaxPhraseTokens) {
                    PhraseTokenUtil.generateNewTokenIDs(syntaxPhraseTokens);
                    syntaxTag.phraseTokens = syntaxPhraseTokens;
                }
            }
        }

        this.setState({
            selectedFrame: node,
            selectedTag: selTag,
        }, () => { if (selTag) { this.scrollToSelectedTag(`jumpToSelectedTag_${OntologyUtil.SUBTREE_FRAME}`); } });
    }

    /**
     * Selects role node, displays respective class data.
     * Also selects tag from this role if one is selected.
     * @param {*} node 
     * @param {*} selTag 
     * @returns 
     */
    onSelectRole = async (node, selTag = null) => {

        //console.log('sel node: ', node);

        const ontClass = OntologyUtil.getClassForNode(this.state.frameOntology, node);

        // --- no class selected -> reset selected role and tag --- //
        if (!node || !ontClass) { //  || node.isRoot
            this.setState({
                selectedRole: null,
                selectedTag: null,
            });
            return;
        }

        this.setState({
            selectedRole: node,
            selectedTag: selTag,
        }, () => { if (selTag) { this.scrollToSelectedTag(`jumpToSelectedTag_${OntologyUtil.SUBTREE_ROLE}`); } });
    }

    /**
     * Selects synset node, displays respective class data.
     * Also selects tag from this synset if one is selected.
     * @param {*} node 
     * @param {*} selTag 
     * @returns 
     */
    onSelectSynset = async (node, selTag = null) => {

        const ontClass = OntologyUtil.getClassForNode(this.state.frameOntology, node);

        // --- no class selected -> reset selected synset and tag --- //
        if (!node || !ontClass) { //  || node.isRoot
            this.setState({
                selectedSynset: null,
                selectedTag: null,
            });
            return;
        }

        this.setState({
            selectedSynset: node,
            selectedTag: selTag,
        }, () => { if (selTag) { this.scrollToSelectedTag(`jumpToSelectedTag_${OntologyUtil.SUBTREE_SYNSET}`); } });
    }

    /**
     * If class is selected all tags with syntaxes are translated to phrase tokens and
     * stored within the object.
     */
    onSelectMacro = async (node, selTag = null) => {

        // --- translate macros to phrase tokens --- //
        const macros = OntologyClassUtil.getTagsFromClass(node, OntologyClassUtil.TAG_TYPE_MACRO);
        if (macros) {
            for (const macroTag of macros) {
                macroTag.key = macroTag.key ? macroTag.key : IDGeneratorUtil.generateNewRoleTagID();
                const { name, syntax } = OntologyClassUtil.extractNameAndSyntaxFromMacroTagValue(macroTag.value);
                const response = await translateSyntaxPatternStringToPhraseTokens(syntax);
                const macroPhraseTokens = checkResultAndGetPayload(response, this.growl);
                if (macroPhraseTokens) {
                    PhraseTokenUtil.generateNewTokenIDs(macroPhraseTokens);
                    macroTag.macroName = name;
                    macroTag.macroSyntax = syntax;
                    macroTag.phraseTokens = macroPhraseTokens;
                }
            }
        }

        this.setState({
            selectedMacro: node,
            selectedTag: selTag,
        }, () => { if (selTag) { this.scrollToSelectedTag(`jumpToSelectedTag_${OntologyUtil.SUBTREE_MACRO}`); } });
    }

    /**
     * Scrolls to selected tag by clicking predefined link handling scroll to action.
     */
    scrollToSelectedTag = (tagID) => {
        if (document.getElementById(tagID)) {
            //console.log('jumpToSelectedTag: ', tagID);
            document.getElementById(tagID).click();
        }
    }

    // ----------------------------------------------------------------------- //
    // --- add, delete, clone ontology class --------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Adds new frame to parent and updates respective select items.
     */
    addFrameToParent = (newClass, newClassChildren, parentNode, nextOcid) => {

        const frameOntology = { ...this.state.frameOntology };

        const newNode = OntologyUtil.addClassToSubtree(frameOntology.frames, frameOntology.knownTermStanzas, newClassChildren, newClass, parentNode);
        // --- update select items and next OCID --- //
        const frameSelectItems = OntologyUtil.extractClasses(frameOntology.frames, frameOntology.knownTermStanzas, false);
        frameOntology.maxOcidFrames = nextOcid;

        this.setState({
            frameOntology: frameOntology,
            selectedFrame: newNode,
            selectedTag: null,
            frameSelectItems: frameSelectItems,
        });
    }

    /**
     * Adds new role to parent and updates respective select items.
     */
    addRoleToParent = (newClass, newClassChildren, parentNode, nextOcid) => {

        const frameOntology = { ...this.state.frameOntology };

        const newNode = OntologyUtil.addClassToSubtree(frameOntology.roles, frameOntology.knownTermStanzas, newClassChildren, newClass, parentNode);
        // --- update select items and next OCID --- //
        const roleSelectItems = OntologyUtil.extractClasses(frameOntology.roles, frameOntology.knownTermStanzas, false);
        frameOntology.maxOcidRoles = nextOcid;

        this.setState({
            frameOntology: frameOntology,
            selectedRole: newNode,
            selectedTag: null,
            roleSelectItems: roleSelectItems
        });
    }

    /**
     * Adds new synset to parent and updates respective select items.
     */
    addSynsetToParent = (newClass, newClassChildren, parentNode, nextOcid) => {

        const frameOntology = { ...this.state.frameOntology };

        const newNode = OntologyUtil.addClassToSubtree(frameOntology.synsets, frameOntology.knownTermStanzas, newClassChildren, newClass, parentNode);
        // --- update select items and next OCID --- //
        const synsetSelectItems = OntologyClassUtil.extractSynsets(frameOntology, false);
        frameOntology.maxOcidSynsets = nextOcid;

        this.setState({
            frameOntology: frameOntology,
            selectedSynset: newNode,
            selectedTag: null,
            synsetSelectItems: synsetSelectItems
        });
    }

    /**
     * Clones a frame, generates a new ID and adds it as child to the original class.
     */
    onCloneFrame = (node) => {

        const ontClass = OntologyUtil.getClassForNode(this.state.frameOntology, node);
        if (!node || !ontClass) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: "No class selected." });
            return;
        }

        let maxOcid = this.state.frameOntology.maxOcidFrames;
        if (!maxOcid) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'OCID range error', detail: "No OCID range found. Cannot create new classes without valid OCID range." });
            return;
        }
        const nextOcid = ++maxOcid;
        const newClass = OntologyClassUtil.cloneClass(ontClass, nextOcid + '');

        if (newClass) {
            this.addFrameToParent(newClass, null, node, nextOcid);
        }
    }

    /**
    * Clones a role, generates a new ID and adds it as child to the original class.
    */
    onCloneRole = (node) => {

        const ontClass = OntologyUtil.getClassForNode(this.state.frameOntology, node);
        if (!node || !ontClass) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: "No class selected." });
            return;
        }

        let maxOcid = this.state.frameOntology.maxOcidRoles;
        if (!maxOcid) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'OCID range error', detail: "No OCID range found. Cannot create new classes without valid OCID range." });
            return;
        }
        const nextOcid = ++maxOcid;
        const newClass = OntologyClassUtil.cloneClass(ontClass, nextOcid + '');

        if (newClass) {
            this.addRoleToParent(newClass, null, node, nextOcid);
        }
    }

    /**
     * Clones a synset, generates a new ID and adds it as child to the original class.
     */
    onCloneSynset = (node) => {

        const ontClass = OntologyUtil.getClassForNode(this.state.frameOntology, node);
        if (!node || !ontClass) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: "No class selected." });
            return;
        }

        let maxOcid = this.state.frameOntology.maxOcidSynsets;
        if (!maxOcid) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'OCID range error', detail: "No OCID range found. Cannot create new classes without valid OCID range." });
            return;
        }
        const nextOcid = ++maxOcid;
        const newClass = OntologyClassUtil.cloneClass(ontClass, nextOcid + '');

        if (newClass) {
            this.addSynsetToParent(newClass, null, node, nextOcid);
        }
    }

    /**
     * Add new frame to parent class in ontology.
     */
    onAddNewFrame = (parentNode) => {

        if (!parentNode) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: "No parent class selected." });
            return;
        }

        let maxOcid = this.state.frameOntology.maxOcidFrames;
        if (!maxOcid) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'OCID range error', detail: "No OCID range found. Cannot create new classes without valid OCID range." });
            return;
        }
        const nextOcid = ++maxOcid;
        const newClass = OntologyClassUtil.createFrameClass(nextOcid + '', nextOcid + '');

        if (newClass) {
            this.addFrameToParent(newClass, null, parentNode, nextOcid);
        }
    }

    /**
     * Add new role to parent class in ontology.
     */
    onAddNewRole = (parentNode) => {

        if (!parentNode) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: "No parent class selected." });
            return;
        }

        let maxOcid = this.state.frameOntology.maxOcidRoles;
        if (!maxOcid) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'OCID range error', detail: "No OCID range found. Cannot create new classes without valid OCID range." });
            return;
        }
        const nextOcid = ++maxOcid;
        const newClass = OntologyClassUtil.createRoleClass(nextOcid + '', nextOcid + '');

        if (newClass) {
            this.addRoleToParent(newClass, null, parentNode, nextOcid);
        }
    }

    /**
     * Add new synset to parent class in ontology.
     */
    onAddNewSynset = (parentNode) => {

        if (!parentNode) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: "No parent class selected." });
            return;
        }

        let maxOcid = this.state.frameOntology.maxOcidSynsets;
        if (!maxOcid) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'OCID range error', detail: "No OCID range found. Cannot create new classes without valid OCID range." });
            return;
        }
        const nextOcid = ++maxOcid;
        const newClass = OntologyClassUtil.createSynsetClass(nextOcid + '', nextOcid + '');

        if (newClass) {
            this.addSynsetToParent(newClass, null, parentNode, nextOcid);
        }
    }

    /**
     * Deletes frame from ontology. Updates frame select items.
     * @param {*} node 
     */
    onDeleteFrame = async (node) => {

        const frameOntology = { ...this.state.frameOntology };

        // --- before deleting a frame -> check if it is referenced anywhere in the ontology --- //          
        const referencedClasses = await PhraseTokenUtil.checkForNamesInTags(OntologyUtil.SUBTREE_FRAME, node.name, frameOntology);

        if (Object.keys(referencedClasses).length > 0) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'References found.',
                detail: 'Cannot delete entry because it is referenced in the following classes: ' +
                    Object.keys(referencedClasses).join(', ')
            });
        }
        else {
            OntologyUtil.deleteClassFromSubtree(frameOntology.frames, frameOntology.knownTermStanzas, node);
            const frameSelectItems = OntologyUtil.extractClasses(frameOntology.frames, frameOntology.knownTermStanzas, true);

            this.setState({
                frameOntology: frameOntology,
                selectedFrame: null,
                frameSelectItems: frameSelectItems
            });
        }
    }

    /**
     * Deletes role from ontology. Updates role select items.
     * 
     * @param {*} node 
     */
    onDeleteRole = async (node) => {

        const frameOntology = { ...this.state.frameOntology };

        // --- before deleting a role -> check if it is referenced anywhere in the ontology --- //     
        const referencedClasses = await PhraseTokenUtil.checkForNamesInTags(OntologyUtil.SUBTREE_ROLE, node.name, frameOntology);

        if (Object.keys(referencedClasses).length > 0) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'References found.',
                detail: 'Cannot delete entry because it is referenced in the following classes: ' +
                    Object.keys(referencedClasses).join(', ')
            });
        }
        else {
            OntologyUtil.deleteClassFromSubtree(frameOntology.roles, frameOntology.knownTermStanzas, node);
            const roleSelectItems = OntologyUtil.extractClasses(frameOntology.roles, frameOntology.knownTermStanzas, false);

            this.setState({
                frameOntology: frameOntology,
                selectedRole: null,
                roleSelectItems: roleSelectItems
            });
        }
    }

    /**
     * Deletes synset from ontology. Updates synset select items.
     * 
     * @param {*} node 
     */
    onDeleteSynset = async (node) => {

        const frameOntology = { ...this.state.frameOntology };

        // --- before deleting a synset -> check if it is referenced anywhere in the ontology --- //     
        const referencedClasses = await PhraseTokenUtil.checkForNamesInTags(OntologyUtil.SUBTREE_SYNSET, node.name, frameOntology);

        if (Object.keys(referencedClasses).length > 0) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'References found.',
                detail: 'Cannot delete entry because it is referenced in the following classes: ' +
                    Object.keys(referencedClasses).join(', ')
            });
        }
        else {
            OntologyUtil.deleteClassFromSubtree(frameOntology.synsets, frameOntology.knownTermStanzas, node);
            const synsetSelectItems = OntologyClassUtil.extractSynsets(frameOntology, false);

            this.setState({
                frameOntology: frameOntology,
                selectedSynset: null,
                synsetSelectItems: synsetSelectItems
            });
        }
    }

    /**
     * Removes relationship between frame node and its parent in the tree. 
     * If there are no other parents, the node will be added as a root node.
     * @param {*} node 
     */
    onRemoveRelationToParentFrame = (node) => {

        const frameOntology = { ...this.state.frameOntology };
        // --- check if there would be a parent node left if relationship was removed --- //
        const allNodesWithNodeID = OntologyUtil.getNodesWithID(frameOntology.frames, node.id);
        const uniqueParentIDs = OntologyClassUtil.extractParentIDsFromNodes(allNodesWithNodeID);
        // --- if no parent node would be left -> dismiss removal of relation --- //
        if (Object.keys(uniqueParentIDs).length < 2) {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'warn', summary: 'Relationship cannot be removed.',
                detail: 'Node needs to be attached to at least one parent node.',
                life: 10000
            });
            return;
        }
        // --- otherwise remove node from child array of its parents --- //
        const sourceParentID = OntologyClassUtil.extractParentIDFromKey(node.key);
        // --- get all parent nodes with the same ID as the parent of the chosen node --- //
        const sourceParentIDNodes = OntologyUtil.getNodesWithID(frameOntology.frames, sourceParentID);
        for (var parentNode of sourceParentIDNodes) {
            OntologyClassUtil.removeChildFromParentNode(parentNode, node.id);
        }

        this.setState({
            frameOntology: frameOntology,
            selectedFrame: null
        });
    }

    /**
     * Removes relationship between role node and its parent in the tree. 
     * If there are no other parents, the node will be added as a root node.
     * @param {*} node 
     */
    onRemoveRelationToParentRole = (node) => {

        const frameOntology = { ...this.state.frameOntology };
        // --- check if there would be a parent node left if relationship was removed --- //
        const allNodesWithNodeID = OntologyUtil.getNodesWithID(frameOntology.roles, node.id);
        const uniqueParentIDs = OntologyClassUtil.extractParentIDsFromNodes(allNodesWithNodeID);
        // --- if no parent node would be left -> dismiss removal of relation --- //
        if (Object.keys(uniqueParentIDs).length < 2) {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'warn', summary: 'Relationship cannot be removed.',
                detail: 'Node needs to be attached to at least one parent node.',
                life: 10000
            });
            return;
        }
        // --- otherwise remove node from child array of its parents --- //
        const sourceParentID = OntologyClassUtil.extractParentIDFromKey(node.key);
        // --- get all parent nodes with the same ID as the parent of the chosen node --- //
        const sourceParentIDNodes = OntologyUtil.getNodesWithID(frameOntology.roles, sourceParentID);
        for (var parentNode of sourceParentIDNodes) {
            OntologyClassUtil.removeChildFromParentNode(parentNode, node.id);
        }

        this.setState({
            frameOntology: frameOntology,
            selectedRole: null
        });
    }

    /**
     * Removes relationship between synset node and its parent in the tree. 
     * If there are no other parents, the node will be added as a root node.
     * @param {*} node 
     */
    onRemoveRelationToParentSynset = (node) => {

        const frameOntology = { ...this.state.frameOntology };
        // --- check if there would be a parent node left if relationship was removed --- //
        const allNodesWithNodeID = OntologyUtil.getNodesWithID(frameOntology.synsets, node.id);
        const uniqueParentIDs = OntologyClassUtil.extractParentIDsFromNodes(allNodesWithNodeID);
        // --- if no parent node would be left -> dismiss removal of relation --- //
        if (Object.keys(uniqueParentIDs).length < 2) {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'warn', summary: 'Relationship cannot be removed.',
                detail: 'Node needs to be attached to at least one parent node.',
                life: 10000
            });
            return;
        }
        // --- otherwise remove node from child array of its parents --- //
        const sourceParentID = OntologyClassUtil.extractParentIDFromKey(node.key);
        // --- get all parent nodes with the same ID as the parent of the chosen node --- //
        const sourceParentIDNodes = OntologyUtil.getNodesWithID(frameOntology.synsets, sourceParentID);
        for (var parentNode of sourceParentIDNodes) {
            OntologyClassUtil.removeChildFromParentNode(parentNode, node.id);
        }

        this.setState({
            frameOntology: frameOntology,
            selectedSynset: null
        });
    }

    /**
     * Finishes drag and drop action by updating the respective subtree.
     */
    onDragAndDropClass = (nodes, e) => {

        // --- dragged class already is a child of the target class --- //
        if (e.dropNode.children && getNumberOfObjectsWithValueInArray(e.dropNode.children, 'id', e.dragNode.id) > 1) {
            this.growl.current.show({ sticky: false, closable: true, severity: 'info', summary: 'Cannot add class here.', detail: "Chosen class already is a child of target class." });
            return;
        }
        /*
        if (nodes.length > 1) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Multiple roots', detail: "Drag & drop not allowed here since it would result in multiple roots." });
        }
        */
        //else {
        const sourceNode = e.dragNode;
        const targetParentNode = e.dropNode;
        const sourceParentKey = OntologyClassUtil.extractParentKeyFromKey(e.dragNode.key); //e.dragNode.key.replace(/-[0-9]+$/, '');

        // --- check if dragged node would cause a cycle ---- //
        const parentClasses = OntologyUtil.getNodesWithID(nodes, targetParentNode.id);
        const doesCauseCycle = OntologyUtil.doesAddingNodeAsChildCauseCycle(parentClasses, sourceNode);
        if (doesCauseCycle) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Cycles not allowed', detail: "Cannot add class here, since it would cause cycles." });
            return;
        }

        const frameOntology = { ...this.state.frameOntology };

        // --- source node cannot be root if it is dropped under a target node --- //
        delete sourceNode.isRoot;
        // --- sort children of target parent again --- //
        targetParentNode.children = OntologyUtil.sortNodesByName(targetParentNode.children, frameOntology.knownTermStanzas, false);

        // --- d&d with ctrl key == add node to new parent, but keep relation to old parent --- //
        // --- nodes contains tree with dragged node under new parent, ------------------------ //
        // --- relation to old parent is already removed -> add again (clone object!) --------- //
        if (e.originalEvent.ctrlKey) {
            const sourceNodeClone = cloneDeep(sourceNode);
            const sourceParent = OntologyUtil.getClassWithKey(nodes, sourceParentKey);
            if (!sourceParent.children) {
                sourceParent.children = [];
            }
            sourceParent.children.push(sourceNodeClone);
            // --- sort children of source parent again --- //
            sourceParent.children = OntologyUtil.sortNodesByName(sourceParent.children, frameOntology.knownTermStanzas, false);
        }
        // --- dragged node is automatically removed from source parent, --- //
        // --- but not other occurrences of the parent class in the tree --- //
        else {
            const sourceParentID = OntologyClassUtil.extractParentIDFromKey(e.dragNode.key);
            // --- get all parent nodes with the same ID as the parent of the dragged node --- //
            const sourceParentIDNodes = OntologyUtil.getNodesWithID(nodes, sourceParentID);
            for (var parentNode of sourceParentIDNodes) {
                OntologyClassUtil.removeChildFromParentNode(parentNode, e.dragNode.id);
            }
        }

        const sourceNodeData = frameOntology.knownTermStanzas[e.dragNode.id];
        //console.log('targetParentNode: ', targetParentNode);
        const ontClass = OntologyUtil.addClassToSubtree(nodes, frameOntology.knownTermStanzas, sourceNode.children, sourceNodeData, targetParentNode);
        OntologyUtil.addKeysToNodes(nodes);

        switch (this.state.activeViewType) {
            case OntologyUtil.SUBTREE_FRAME: {
                frameOntology.frames = nodes;
                break;
            }
            case OntologyUtil.SUBTREE_ROLE: {
                frameOntology.roles = nodes;
                break;
            }
            case OntologyUtil.SUBTREE_SYNSET: {
                frameOntology.synsets = nodes;
                break;
            }
            default: return;
        }

        this.setState({
            frameOntology: frameOntology
        });
        //}
    }

    /**
     * If name of a frame is edited every reference in the  ontology needs to be updated.
     */
    onEditFrameName = async (newName) => {

        if (!newName) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing name.', detail: 'Empty names are not allowed.' });
            return false;
        }

        if (!this.state.selectedFrame) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.', detail: 'No valid frame selected.' });
        }
        else {
            const frameClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedFrame);
            // --- data for selected class id not found --- //
            if (!frameClass) {
                this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing name.', detail: 'No data found for selected class.' });
                return false;
            }
            // -- get normalized version of new name (we need normalized names for duplicate check) --- //
            const newNameNorm = OntologyClassUtil.normalizeClassName(newName);
            // --- check if normalized new name already exists --- //
            if (OntologyUtil.doesNameExistInItemList(newNameNorm, this.state.selectedFrame.id, this.state.frameSelectItems)) {
                this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.', detail: 'Name already exists.' });
                return false;
            }
            // --- create new ontology object to update views --- //
            let frameOntology = { ...this.state.frameOntology };
            // -- get normalized old name --- //
            const oldNameNorm = OntologyClassUtil.normalizeClassName(frameClass.name);
            // --- update label in class --- //
            const updFrame = OntologyClassUtil.updateClassLabel(frameClass, newName);
            // --- update frame name in tags --- //
            await PhraseTokenUtil.updateNamesInTags(OntologyUtil.SUBTREE_FRAME, oldNameNorm, newNameNorm, frameOntology);
            // --- get updated frame select items --- //
            const frameSelectItems = OntologyUtil.extractClasses(frameOntology.frames, frameOntology.knownTermStanzas, true);
            // --- sort nodes --- //
            const allNodesWithNodeID = OntologyUtil.getNodesWithID(frameOntology.frames, this.state.selectedFrame.id);
            const uniqueParentIDs = OntologyClassUtil.extractParentIDsFromNodes(allNodesWithNodeID);
            if (uniqueParentIDs) {
                for (var parentID of Object.keys(uniqueParentIDs)) {
                    const parentNodes = OntologyUtil.getNodesWithID(frameOntology.frames, parentID);
                    if (parentNodes) {
                        for (var parentNode of parentNodes) {
                            parentNode.children = OntologyUtil.sortNodesByName(parentNode.children, frameOntology.knownTermStanzas, false);
                        }
                    }
                }
            }
            // --- update view items --- //
            this.setState({
                frameOntology: frameOntology,
                frameSelectItems: frameSelectItems
            });
            // TODO???
            // --- update frame in case it references itself --- //
            //this.onSelectFrame(updFrame, this.state.selectedTag);
            //this.onSelectFrame(this.state.selectedFrame, this.state.selectedTag);???
        }
        return true;
    }

    /**
    * If name of a frame is edited every reference in the  ontology needs to be updated.
    */
    onEditRoleName = async (newName) => {

        if (!newName) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing name.', detail: 'Empty names are not allowed.' });
            return false;
        }

        if (!this.state.selectedRole) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.', detail: 'No valid role selected.' });
        }
        else {
            const roleClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedRole);
            // --- data for selected class id not found --- //
            if (!roleClass) {
                this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing name.', detail: 'No data found for selected class.' });
                return false;
            }
            // -- get normalized version of new name (we need normalized names for duplicate check) --- //
            const newNameNorm = OntologyClassUtil.normalizeClassName(newName);
            // --- check if normalized new name already exists --- //
            if (OntologyUtil.doesNameExistInItemList(newNameNorm, this.state.selectedRole.id, this.state.roleSelectItems)) {
                this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.', detail: 'Name already exists.' });
                return false;
            }
            // --- create new ontology object to update views --- //
            let frameOntology = { ...this.state.frameOntology };
            // -- get normalized old name --- //
            const oldNameNorm = OntologyClassUtil.normalizeClassName(roleClass.name);
            // --- update label in class --- //
            const updRole = OntologyClassUtil.updateClassLabel(roleClass, newName);
            // --- update role name in tags --- //
            await PhraseTokenUtil.updateNamesInTags(OntologyUtil.SUBTREE_ROLE, oldNameNorm, newNameNorm, frameOntology);
            // --- get updated role select items --- //
            const roleSelectItems = OntologyUtil.extractClasses(frameOntology.roles, frameOntology.knownTermStanzas, false);
            // --- sort nodes --- //
            const allNodesWithNodeID = OntologyUtil.getNodesWithID(frameOntology.roles, this.state.selectedRole.id);
            const uniqueParentIDs = OntologyClassUtil.extractParentIDsFromNodes(allNodesWithNodeID);
            if (uniqueParentIDs) {
                for (var parentID of Object.keys(uniqueParentIDs)) {
                    const parentNodes = OntologyUtil.getNodesWithID(frameOntology.roles, parentID);
                    if (parentNodes) {
                        for (var parentNode of parentNodes) {
                            parentNode.children = OntologyUtil.sortNodesByName(parentNode.children, frameOntology.knownTermStanzas, false);
                        }
                    }
                }
            }
            // --- update view items --- //
            this.setState({
                frameOntology: frameOntology,
                // TODO???
                //selectedRole: updRole,
                roleSelectItems: roleSelectItems
            });
        }
        return true;
    }

    /**
     * If name of a synset is edited every reference in the  ontology needs to be updated.
     */
    onEditSynsetName = async (newName) => {

        //console.log('phraseTokens: ', phraseTokens);
        //console.log('selectedTag: ', this.state.selectedTag);

        if (!newName) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing name.', detail: 'Empty names are not allowed.' });
            return false;
        }

        if (!this.state.selectedSynset) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.', detail: 'No valid synset selected.' });
        }
        else {
            const synsetClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedSynset);
            // --- data for selected class id not found --- //
            if (!synsetClass) {
                this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing name.', detail: 'No data found for selected class.' });
                return false;
            }
            // -- get normalized version of new name (we need normalized names for duplicate check) --- //
            const newNameNorm = OntologyClassUtil.normalizeClassName(newName);
            // --- check if normalized new name already exists --- //
            const synsetsArray = this.state.synsetSelectItems ? Object.values(this.state.synsetSelectItems) : [];
            if (OntologyUtil.doesNameExistInItemList(newNameNorm, synsetClass.id, synsetsArray)) {
                this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.', detail: 'Name already exists.' });
                return false;
            }
            // --- create new ontology object to update views --- //
            let frameOntology = { ...this.state.frameOntology };
            // -- get normalized old name --- //
            const oldNameNorm = OntologyClassUtil.normalizeClassName(synsetClass.name);
            // --- update label in class --- //
            const updSynset = OntologyClassUtil.updateClassLabel(synsetClass, newName);
            // --- update synset name in tags --- //
            await PhraseTokenUtil.updateNamesInTags(OntologyUtil.SUBTREE_SYNSET, oldNameNorm, newNameNorm, frameOntology);
            // --- get updated synset select items --- //
            const synsetSelectItems = OntologyClassUtil.extractSynsets(frameOntology, false);
            // --- sort nodes --- //
            const allNodesWithNodeID = OntologyUtil.getNodesWithID(frameOntology.synsets, this.state.selectedSynset.id);
            const uniqueParentIDs = OntologyClassUtil.extractParentIDsFromNodes(allNodesWithNodeID);
            if (uniqueParentIDs) {
                for (var parentID of Object.keys(uniqueParentIDs)) {
                    const parentNodes = OntologyUtil.getNodesWithID(frameOntology.synsets, parentID);
                    if (parentNodes) {
                        for (var parentNode of parentNodes) {
                            parentNode.children = OntologyUtil.sortNodesByName(parentNode.children, frameOntology.knownTermStanzas, false);
                        }
                    }
                }
            }
            // --- update view items --- //
            this.setState({
                // TODO???
                //selectedSynset: updSynset,
                frameOntology: frameOntology,
                synsetSelectItems: synsetSelectItems
            });
        }
        return true;
    }

    /**
     * Set relation type of a class.
     */
    onSetRelType = (relType) => {

        const frameClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedFrame);
        // --- data for selected class id not found --- //
        if (!frameClass) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error changing relation type.', detail: 'No data found for selected class.' });
            return;
        }
        OntologyClassUtil.removeTagFromClass(frameClass, OntologyClassUtil.TAG_TYPE_RELTYPE);
        if (relType) {
            OntologyClassUtil.addTagToClass(frameClass, OntologyClassUtil.TAG_TYPE_RELTYPE, OntologyClassUtil.createRelationTypeTag(relType));
        }
        const selectedFrame = { ...this.state.selectedFrame };
        this.onSelectFrame(selectedFrame, null);
    }

    /**
     * Set level of a class.
     */
    onSetLevel = (level) => {

        const frameClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedFrame);
        // --- data for selected class id not found --- //
        if (!frameClass) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error changing level.', detail: 'No data found for selected class.' });
            return;
        }
        OntologyClassUtil.removeTagFromClass(frameClass, OntologyClassUtil.TAG_TYPE_LEVEL);
        if (level) {
            OntologyClassUtil.addTagToClass(frameClass, OntologyClassUtil.TAG_TYPE_LEVEL, OntologyClassUtil.createLevelTag(level));
        }
        const selectedFrame = { ...this.state.selectedFrame };
        this.onSelectFrame(selectedFrame, null);
    }

    // ----------------------------------------------------------------------- //
    // --- handle tags, e.g. role, syntax, macro? ---------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Select/deselect tag.
     */
    onSelectTag = (tag) => {

        this.setState({
            selectedTag: (tag !== this.state.selectedTag) ? tag : null
        });
    }

    /**
     * Deletes a tag from the selected frame.
     * @param {*} tag 
     * @returns 
     */
    onDeleteFrameTag = async (tag) => {

        if (!tag) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error deleting tag.', detail: 'No valid tag selected.' });
            return false;
        }

        const tagType = tag.tag;
        const frameClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedFrame);
        if (!frameClass || !OntologyClassUtil.classContainsTag(frameClass, tagType, tag)) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Error deleting tag.',
                detail: 'No valid class, frame or tag selected to be deleted.'
            });
            return false;
        }
        else {
            // --- if role tag should be removed from frame -> check for references in syntaxes --- //
            if (tagType === OntologyClassUtil.TAG_TYPE_ROLE) {
                const { name } = OntologyClassUtil.extractNameAndSyntaxFromRoleTagValue(tag.value);
                // --- check syntax tags for role references --- //
                if (frameClass.tags[OntologyClassUtil.TAG_TYPE_SYNTAX]) {
                    for (const tag of frameClass.tags[OntologyClassUtil.TAG_TYPE_SYNTAX]) {
                        const refFound = await PhraseTokenUtil.checkForRoleNameInSyntax(tag, name);
                        if (refFound) {
                            this.growl.current.show({
                                sticky: true, closable: true, severity: 'error', summary: 'References found.',
                                detail: 'Cannot delete entry because it is referenced in at least one of the syntaxes in this frame.'
                            });
                            return false;
                        }
                    }
                }
            }
            // --- no references found -> delete role tag in frame class --- //
            removeValueFromArray(frameClass.tags[tagType], tag);

            this.setState({
                selectedTag: null,
            });
        }
    }

    /**
     * Deletes a tag from the selected role.
     * @param {*} tag 
     * @returns 
     */
    onDeleteRoleTag = async (tag) => {

        if (!tag) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error deleting tag.', detail: 'No valid tag selected.' });
            return false;
        }

        const tagType = tag.tag;
        const roleClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedRole);
        if (!roleClass || !OntologyClassUtil.classContainsTag(roleClass, tagType, tag)) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Error deleting tag.',
                detail: 'No valid class, role or tag selected to be deleted.'
            });
            return false;
        }
        else {
            // --- delete tag in role class --- //
            removeValueFromArray(roleClass.tags[tagType], tag);

            this.setState({
                selectedTag: null,
            });
        }
    }

    /**
     * Deletes a tag from the synset frame.
     * @param {*} tag 
     * @returns 
     */
    onDeleteSynsetTag = async (tag) => {

        if (!tag) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error deleting tag.', detail: 'No valid tag selected.' });
            return false;
        }

        const tagType = tag.tag;
        const synsetClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedSynset);
        if (!synsetClass || !OntologyClassUtil.classContainsTag(synsetClass, tagType, tag)) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Error deleting tag.',
                detail: 'No valid class, synset or tag selected to be deleted.'
            });
            return false;
        }
        else {
            // --- delete tag in synset class --- //
            removeValueFromArray(synsetClass.tags[tagType], tag);
            // --- update synset select items in case a synonym type was removed --- //
            const synsetSelectItems = OntologyClassUtil.extractSynsets(this.state.frameOntology, false);

            this.setState({
                selectedTag: null,
                synsetSelectItems: synsetSelectItems
            });
        }
    }

    /**
     * Deletes a macro tag.
     */
    onDeleteMacroTag = async (tag) => {

        if (!tag) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error deleting tag.', detail: 'No valid tag selected.' });
            return false;
        }

        const tagType = tag.tag;
        const { name } = OntologyClassUtil.extractNameAndSyntaxFromMacroTagValue(tag.value);

        const referencedClasses = await PhraseTokenUtil.checkForNamesInTags(OntologyUtil.SUBTREE_MACRO, name, this.state.frameOntology);

        if (Object.keys(referencedClasses).length > 0) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'References found.',
                detail: 'Cannot delete entry because it is referenced in the following classes: ' +
                    Object.keys(referencedClasses).join(', ')
            });
            return false;
        }
        else {
            const selectedMacro = { ...this.state.selectedMacro };
            // --- delete tag in macro class --- //
            removeValueFromArray(selectedMacro.tags[tagType], tag);
            // --- update macro select items --- //
            //const macroSelectItems = OntologyClassUtil.extractMacros(this.state.frameOntology.macros);
            const macroSelectItems = OntologyClassUtil.extractMacros(this.state.frameOntology);

            this.setState({
                selectedMacro: selectedMacro,
                selectedTag: null,
                macroSelectItems: macroSelectItems
            });
        }
    }

    /**
     * Saves a new tag.
     * @param {*} newTag 
     * @param {*} tagType 
     * @returns 
     */
    onSaveTagAsNew = async (newTag, tagType) => {

        switch (this.state.activeViewType) {

            case OntologyUtil.SUBTREE_FRAME: {

                const selectedFrame = { ...this.state.selectedFrame };
                const frameClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedFrame);
                OntologyClassUtil.addTagToClass(frameClass, tagType, newTag);
                this.onSelectFrame(selectedFrame, newTag);
                break;
            }

            case OntologyUtil.SUBTREE_ROLE: {

                const selectedRole = { ...this.state.selectedRole };
                const roleClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedRole);
                OntologyClassUtil.addTagToClass(roleClass, tagType, newTag);
                this.onSelectRole(selectedRole, newTag);
                break;
            }

            case OntologyUtil.SUBTREE_SYNSET: {

                const selectedSynset = { ...this.state.selectedSynset };
                const synsetClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedSynset);
                OntologyClassUtil.addTagToClass(synsetClass, tagType, newTag);
                this.onSelectSynset(selectedSynset, newTag);
                break;
            }

            case OntologyUtil.SUBTREE_MACRO: {

                const selectedMacro = { ...this.state.selectedMacro };
                OntologyClassUtil.addTagToClass(selectedMacro, tagType, newTag);
                const macroSelectItems = OntologyClassUtil.extractMacros(this.state.frameOntology);
                this.setState({
                    macroSelectItems: macroSelectItems,
                });
                this.onSelectMacro(selectedMacro, newTag);
                break;
            }
        }

        return true;
    }

    /**
     * Replaces a tag with a new one.
     * @param {*} origTag 
     * @param {*} newTag 
     * @returns 
     */
    onReplaceTag = async (origTag, newTag) => {

        // TODO: duplicate code -> clean!!!
        //console.log('origTag: ', origTag);
        //console.log('newTag: ', newTag);

        if (!origTag || !origTag.tag) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.', detail: 'No valid entry selected to be replaced.' });
            return false;
        }

        const tagType = origTag.tag;

        switch (this.state.activeViewType) {

            case OntologyUtil.SUBTREE_FRAME: {

                const frameClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedFrame);
                if (!frameClass || !OntologyClassUtil.classContainsTag(frameClass, tagType, origTag)) {
                    this.growl.current.show({
                        sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.',
                        detail: 'No valid frame or entry selected to be replaced.'
                    });
                    return false;
                }
                else {
                    if (tagType === OntologyClassUtil.TAG_TYPE_ROLE) {

                        const origName = OntologyClassUtil.extractNameAndSyntaxFromRoleTagValue(origTag.value).name;
                        const newName = OntologyClassUtil.extractNameAndSyntaxFromRoleTagValue(newTag.value).name;

                        if (newName !== origName) {
                            // --- check syntax tags --- //
                            if (frameClass.tags[OntologyClassUtil.TAG_TYPE_SYNTAX]) {
                                for (const tag of frameClass.tags[OntologyClassUtil.TAG_TYPE_SYNTAX]) {
                                    const refFound = await PhraseTokenUtil.checkForRoleNameInSyntax(tag, origName);
                                    if (refFound) {
                                        this.growl.current.show({
                                            sticky: true, closable: true, severity: 'error', summary: 'References found.',
                                            detail: 'Cannot replace role because it is referenced in at least one of the syntaxes of this frame.'
                                        });
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                    // --- replace old tag with new one --- //
                    const index = frameClass.tags[tagType].indexOf(origTag);
                    OntologyClassUtil.addTagToClass(frameClass, tagType, newTag, index);
                }
                break;
            }

            case OntologyUtil.SUBTREE_ROLE: {

                const tagType = origTag.tag;
                const roleClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedRole);
                if (!roleClass || !OntologyClassUtil.classContainsTag(roleClass, tagType, origTag)) {
                    this.growl.current.show({
                        sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.',
                        detail: 'No valid role or entry selected to be replaced.'
                    });
                    return false;
                }
                else {
                    // --- replace old tag with new one --- //
                    const index = roleClass.tags[tagType].indexOf(origTag);
                    OntologyClassUtil.addTagToClass(roleClass, tagType, newTag, index);
                }
                break;
            }

            case OntologyUtil.SUBTREE_SYNSET: {

                const tagType = origTag.tag;
                const synsetClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedSynset);
                if (!synsetClass || !OntologyClassUtil.classContainsTag(synsetClass, tagType, origTag)) {
                    this.growl.current.show({
                        sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.',
                        detail: 'No valid synset or entry selected to be replaced.'
                    });
                    return false;
                }
                else {
                    // --- replace old tag with new one --- //
                    const index = synsetClass.tags[tagType].indexOf(origTag);
                    OntologyClassUtil.addTagToClass(synsetClass, tagType, newTag, index);
                    // TODO: update
                    const synsetSelectItems = OntologyClassUtil.extractSynsets(this.state.frameOntology, false);
                    //console.log('synsetSelectItems: ', synsetSelectItems);
                    this.setState({
                        //frameOntology: frameOntology,
                        synsetSelectItems: synsetSelectItems,
                    });
                }
                break;
            }

            case OntologyUtil.SUBTREE_MACRO: {

                const tagType = origTag.tag;
                if (!this.state.selectedMacro || !OntologyClassUtil.classContainsTag(this.state.selectedMacro, tagType, origTag)) {
                    this.growl.current.show({
                        sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.',
                        detail: 'No valid entry selected to be replaced.'
                    });
                    return false;
                }
                else {
                    const selectedMacro = { ...this.state.selectedMacro };
                    const frameOntology = { ...this.state.frameOntology };

                    const oldName = OntologyClassUtil.extractNameAndSyntaxFromMacroTagValue(origTag.value).name;
                    const newName = OntologyClassUtil.extractNameAndSyntaxFromMacroTagValue(newTag.value).name;

                    if (oldName !== newName) {

                        // --- update macro name in tags --- //
                        await PhraseTokenUtil.updateNamesInTags(OntologyUtil.SUBTREE_MACRO, oldName, newName, frameOntology);

                        const macroSelectItems = OntologyClassUtil.extractMacros(frameOntology);
                        //console.log('macroSelectItems: ', macroSelectItems);

                        this.setState({
                            frameOntology: frameOntology,
                            macroSelectItems: macroSelectItems,
                        });
                    }

                    // --- replace old tag with new one --- //
                    const index = selectedMacro.tags[tagType].indexOf(origTag);
                    OntologyClassUtil.addTagToClass(selectedMacro, tagType, newTag, index);

                    this.onSelectMacro(selectedMacro, newTag);
                }
                break;
            }

            default: return true;
        }

        return true;
    }


    /**
     * Copies a tag to a class.
     * @param {*} className 
     * @param {*} tagType 
     * @param {*} tag 
     */
    onCopyTagToFrame = async (className, tagType, tag) => {

        // --- get ontology class data as well as a tree node matching the class name --- //
        const ontClassData = OntologyUtil.getClassWithName(this.state.frameOntology.knownTermStanzas, className);
        const node = OntologyUtil.getNodeWithID(this.state.frameOntology.frames, ontClassData.id);
        if (ontClassData && node) {
            // --- add as new tag to class --- //
            const newTag = cloneDeep(tag);
            // --- generate new key --- //
            if (tagType === OntologyClassUtil.TAG_TYPE_ROLE) {
                newTag.key = IDGeneratorUtil.generateNewRoleTagID();
            }
            else if (tagType === OntologyClassUtil.TAG_TYPE_SYNTAX) {
                newTag.key = IDGeneratorUtil.generateNewSyntaxTagID();
                // --- extract referenced roles from syntax to show message to user that roles have to be defined in target class --- //
                const roleNames = await PhraseTokenUtil.extractRolesFromSyntax(newTag.value);
                if (Object.keys(roleNames).length > 0)
                    this.growl.current.show({
                        sticky: false, closable: true, severity: 'info', summary: 'Check roles', life: 15000,
                        detail: 'Please check if all roles used in this syntax are also defined in this frame (otherwise just add the respective roles via "Add role"). Roles found in syntax: ' +
                            Object.keys(roleNames).join(', ')
                    });
                //PhraseTokenUtil.hasTokenAttributeValue();
                // if syntax contains role, but role is not defined -> add?
            }

            //OntologyClassUtil.addTagToClass(ontClassData, tagType, newTag);
            OntologyClassUtil.addTagToClass(ontClassData, tagType, newTag);
            // --- select target class --- //
            this.onSelectFrame(node, newTag);

            // --- expand path to target class --- //
            if (node !== this.state.selectedFrame) {
                const pathKeys = OntologyUtil.getNodeKeysOnPathToNode(node);
                this.framesSubOntology.expandClasses(pathKeys, false);
            }
        }
        else {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Error copying entry.',
                detail: 'Unkown target class.'
            });
        }
    }

    /**
     * Copies a macro tag.
     * @param {*} className 
     * @param {*} tagType 
     * @param {*} tag 
     */
    onCopyTagToMacro = async (className, tagType, tag) => {

        const treenodeWithData = this.state.selectedMacro;
        if (treenodeWithData) {
            // --- add as new tag to class, generate new ID --- //
            const newTag = cloneDeep(tag);
            newTag.key = IDGeneratorUtil.generateNewMacroTagID();

            // --- update macro name (ID) of new tag --- //
            const { name } = OntologyClassUtil.extractNameAndSyntaxFromMacroTagValue(tag.value);
            const newName = `${name}_COPY`;
            const newValue = OntologyClassUtil.changeNameInMacroTagValue(tag.value, newName);
            newTag.value = newValue;
            newTag.macroName = newName;
            // --- add tag to macro class --- //
            OntologyClassUtil.addTagToClass(treenodeWithData, tagType, newTag);

            // --- select target class --- //
            this.onSelectMacro(treenodeWithData, newTag);
        }
        else {
            // should not happen!
        }
    }


    // ----------------------------------------------------------------------- //
    // --- edit/add syntax tag ----------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onEditSyntaxTag = (tag) => {

        this.setState({
            selectedTag: tag,
            replaceTag: true,
            editSyntaxTagBoxVisible: true,
        });
    }

    onAddSyntaxTag = () => {

        this.setState({
            selectedTag: null,
            replaceTag: false,
            editSyntaxTagBoxVisible: true,
        });
    }

    onEditSyntaxTagSubmit = async (tag) => {

        if (this.state.replaceTag) {
            const ok = await this.onReplaceTag(this.state.selectedTag, tag);
            if (ok) {
                this.setState({
                    editSyntaxTagBoxVisible: false
                });
            }
        }
        else {
            const ok = this.onSaveTagAsNew(tag, OntologyClassUtil.TAG_TYPE_SYNTAX);
            if (ok) {
                this.setState({
                    editSyntaxTagBoxVisible: false
                });
            }
        }
    }

    onEditSyntaxTagCancel = () => {
        this.setState({
            editSyntaxTagBoxVisible: false,
            selectedTag: null
        });
    }

    // ----------------------------------------------------------------------- //
    // --- edit/add role tag ------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onEditRoleTag = (tag) => {

        this.setState({
            selectedTag: tag,
            replaceTag: true,
            editRoleTagBoxVisible: true
        });
    }

    onAddRoleTag = () => {

        this.setState({
            selectedTag: null,
            replaceTag: false,
            editRoleTagBoxVisible: true
        });
    }

    onEditRoleTagSubmit = async (tag) => {

        if (this.state.replaceTag) {
            const ok = await this.onReplaceTag(this.state.selectedTag, tag);
            if (ok) {
                this.setState({
                    editRoleTagBoxVisible: false
                });
            }
        }
        else {
            const ok = await this.onSaveTagAsNew(tag, OntologyClassUtil.TAG_TYPE_ROLE);
            if (ok) {
                this.setState({
                    editRoleTagBoxVisible: false
                });
            }
        }
    }

    onEditRoleTagCancel = () => {
        this.setState({
            editRoleTagBoxVisible: false,
            selectedTag: null
        });
    }

    // ----------------------------------------------------------------------- //
    // --- edit/add macro tag ------------------------------------------------ //
    // ----------------------------------------------------------------------- //
    onEditMacroTag = (tag) => {

        this.setState({
            selectedTag: tag,
            replaceTag: true,
            editMacroTagBoxVisible: true
        });
    }

    onAddMacroTag = () => {

        this.setState({
            selectedTag: null,
            replaceTag: false,
            editMacroTagBoxVisible: true
        });
    }

    onEditMacroTagSubmit = async (tag) => {

        if (this.state.replaceTag) {
            const ok = await this.onReplaceTag(this.state.selectedTag, tag);
            if (ok) {
                this.setState({
                    editMacroTagBoxVisible: false
                });
            }
        }
        else {
            const ok = this.onSaveTagAsNew(tag, OntologyClassUtil.TAG_TYPE_MACRO);
            if (ok) {
                this.setState({
                    editMacroTagBoxVisible: false
                });
            }
        }
    }

    onEditMacroTagCancel = () => {
        this.setState({
            editMacroTagBoxVisible: false,
            selectedTag: null
        });
    }


    // ----------------------------------------------------------------------- //
    // --- edit/add synset tag ----------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onAddSynonymTag = (tag) => {

        this.setState({
            selectedTag: tag
        });

        this.onSaveTagAsNew(tag, OntologyClassUtil.TAG_TYPE_SYNONYM);
    }


    // ----------------------------------------------------------------------- //
    // --- ontology sub tree views ------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Changes between different views: frames, roles, synsets, macros
     * @param {*} viewType 
     * @returns 
     */
    changeTabView = (viewType) => {

        // --- click on active tab -> do nothing --- //
        if (!viewType) {
            return;
        }

        let activeIndex;

        switch (viewType) {

            case OntologyUtil.SUBTREE_FRAME:
                activeIndex = 0;
                break;

            case OntologyUtil.SUBTREE_ROLE:
                activeIndex = 1;
                break;

            case OntologyUtil.SUBTREE_SYNSET:
                activeIndex = 2;
                break;

            case OntologyUtil.SUBTREE_MACRO:
                activeIndex = 3;
                break;

            default:
                activeIndex = 0;
        }

        // --- if tab did not change -> do nothing --- //
        if (activeIndex === this.state.activeIndex) {
            return;
        }

        this.setState({
            activeViewType: viewType,
            activeIndex: activeIndex,
            // ???
            selectedFrame: null,
            selectedRole: null,
            selectedTag: null
        }, () => {
            // --- if macro view is active -> select the only macro class if it exists --- //
            if (activeIndex === 3) {
                const macroClass = !!this.state.frameOntology && !!this.state.frameOntology.macros && !!this.state.frameOntology.macros[0] ?
                    this.state.frameOntology.macros[0] : null;
                this.onSelectMacro(macroClass);
            }
        })
    }


    render() {

        const frameClass = OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedFrame);

        return (
            <>
                <Toast ref={this.growl} appendTo={document.body} />

                <Prompt
                    when={this.state.isPrompt}
                    message="Do you have any unsaved changes? They would be lost after leaving the page. If so, just click cancel and save your ontology first. Otherwise just click ok and proceed."
                />

                <Toolbar left={
                    <React.Fragment>
                        <SelectButton
                            className="primaryButtonSet p-button-sm"
                            value={this.state.activeViewType}
                            options={VIEW_TYPES}
                            onChange={(e) => this.changeTabView(e.value)}>
                        </SelectButton>
                    </React.Fragment>}
                    right={
                        <>
                            <Dropdown value={this.state.selBackup}
                                options={this.state.backups}
                                optionLabel="label"
                                onChange={(e) => this.setState({ selBackup: e.value })}
                                placeholder="Ontology backups"
                                filter={true}
                                filterBy="label"
                                style={{ marginRight: 0 }}
                            />
                            <Button label="Load into editor"
                                //icon="pi pi-md-restore"
                                className="p-button-secondary p-button-sm"
                                style={{ marginRight: 35 }}
                                disabled={!this.state.selBackup}
                                onClick={(e) => this.restoreFrameOntologyBackup()}>
                            </Button>
                            <Button label="Check ontology"
                                //icon="pi pi-check"
                                className="p-button-secondary p-button-sm"
                                style={{ marginRight: 35 }}
                                onClick={(e) => this.onCheckFrameOntology()}
                            >
                            </Button>
                            <SplitButton
                                className="primaryButton " // p-button-sm
                                style={{ top: 0 }}
                                label="Save ontology"
                                //icon="pi pi-md-save"
                                onClick={() => this.onSaveFrameOntology()}
                                model={this.state.saveItems}>
                            </SplitButton>
                        </>}
                />

                <div className="grid dashboard" style={{ background: 'white' }}>

                    <div className="col-12">
                        <TabView
                            className="hideHeaderTabview"
                            activeIndex={this.state.activeIndex}
                            onTabChange={(e) => this.setState({ activeIndex: e.index })}
                            renderActiveOnly={false}>

                            <TabPanel header="Frames">

                                <div className="grid" style={{ display: this.state.editRoleTagBoxVisible ? '' : 'none' }}>
                                    <div className="col-12">
                                        <EditRoleTagBox
                                            tag={this.state.selectedTag}

                                            domains={this.props.domains}
                                            //roles={this.state.selectedFrame ? this.state.selectedFrame.roles : []}
                                            roles={frameClass ? frameClass.roles : []}

                                            roleSelectItems={this.state.roleSelectItems}
                                            synsetSelectItems={this.state.synsetSelectItems}
                                            macroSelectItems={this.state.macroSelectItems}
                                            frameSelectItems={this.state.frameSelectItems}

                                            contentBoxHeight={this.state.editBoxHeight}
                                            editorHeight={this.state.syntaxEditorHeight}

                                            onEditTagSubmit={this.onEditRoleTagSubmit}
                                            onEditTagCancel={this.onEditRoleTagCancel}
                                        />
                                    </div>
                                </div>

                                <div className="grid" style={{ display: this.state.editSyntaxTagBoxVisible ? '' : 'none' }}>
                                    <div className="col-12">
                                        <EditSyntaxTagBox
                                            tag={this.state.selectedTag}

                                            domains={this.props.domains}
                                            //roles={this.state.selectedFrame ? this.state.selectedFrame.roles : []}
                                            roles={frameClass ? frameClass.roles : []}

                                            roleSelectItems={this.state.roleSelectItems}
                                            synsetSelectItems={this.state.synsetSelectItems}
                                            macroSelectItems={this.state.macroSelectItems}
                                            frameSelectItems={this.state.frameSelectItems}

                                            contentBoxHeight={this.state.editBoxHeight}
                                            editorHeight={this.state.syntaxEditorHeight}

                                            onEditTagSubmit={this.onEditSyntaxTagSubmit}
                                            onEditTagCancel={this.onEditSyntaxTagCancel}
                                        />
                                    </div>
                                </div>

                                <div className="grid"
                                    style={{
                                        display: (this.state.editRoleTagBoxVisible || this.state.editSyntaxTagBoxVisible) ? 'none' : '',
                                        background: 'white'
                                    }}>
                                    <div className="col-4">
                                        <FrameOntologyTree
                                            ref={(ref) => { this.framesSubOntology = ref; }}
                                            ontClasses={this.state.frameOntology.knownTermStanzas}
                                            ontology={this.state.frameOntology.frames}
                                            selectedClass={this.state.selectedFrame}
                                            onSelectClass={this.onSelectFrame}
                                            onAddNewClass={this.onAddNewFrame}
                                            onCloneClass={this.onCloneFrame}
                                            onDeleteClass={this.onDeleteFrame}
                                            onRemoveRelationToParent={this.onRemoveRelationToParentFrame}
                                            onDragDrop={this.onDragAndDropClass}
                                            viewSizeState={this.state.viewSizeState}
                                            onViewResize={this.onViewResize}
                                            dndScope="framesont"
                                            contentBoxHeight={this.state.ontologyHeight}
                                        />
                                    </div>
                                    <div className="col-8">
                                        <div className="grid">
                                            <div className="col-12">
                                                <ClassDetails
                                                    subTree={OntologyUtil.SUBTREE_FRAME}

                                                    classSelectItems={this.state.frameSelectItems}
                                                    //ontClass={this.state.selectedFrame}
                                                    ontClass={frameClass}
                                                    selectedTag={this.state.selectedTag}

                                                    onEditName={this.onEditFrameName}
                                                    onSetLevel={this.onSetLevel}
                                                    onSetRelType={this.onSetRelType}
                                                    onCopyTagToClass={this.onCopyTagToFrame}
                                                    onAddSyntaxTag={this.onAddSyntaxTag}
                                                    onEditSyntaxTag={this.onEditSyntaxTag}
                                                    onAddRoleTag={this.onAddRoleTag}
                                                    onEditRoleTag={this.onEditRoleTag}
                                                    onDeleteTag={this.onDeleteFrameTag}
                                                    onSelectTag={this.onSelectTag}

                                                    contentBoxHeight={this.state.ontologyHeight}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel header="Roles">
                                <div className="grid">
                                    <div className="col-4">
                                        <FrameOntologyTree
                                            ref={(ref) => { this.rolesSubOntology = ref; }}
                                            ontClasses={this.state.frameOntology.knownTermStanzas}
                                            ontology={this.state.frameOntology.roles}
                                            selectedClass={this.state.selectedRole}
                                            onSelectClass={this.onSelectRole}
                                            onAddNewClass={this.onAddNewRole}
                                            onCloneClass={this.onCloneRole}
                                            onDeleteClass={this.onDeleteRole}
                                            onRemoveRelationToParent={this.onRemoveRelationToParentRole}
                                            onDragDrop={this.onDragAndDropClass}
                                            viewSizeState={this.state.viewSizeState}
                                            onViewResize={this.onViewResize}
                                            dndScope="rolesont"
                                            contentBoxHeight={this.state.ontologyHeight}
                                        />
                                    </div>
                                    <div className="col-8">
                                        <div className="grid">
                                            <div className="col-12">
                                                <ClassDetails
                                                    subTree={OntologyUtil.SUBTREE_ROLE}
                                                    //ontClass={this.state.selectedRole}
                                                    ontClass={OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedRole)}
                                                    selectedTag={this.state.selectedTag}

                                                    onEditName={this.onEditRoleName}
                                                    //onCopyTagToClass={this.onCopyTagToClass}
                                                    onAddSynonymTag={this.onAddSynonymTag}
                                                    onEditSynonymTag={this.onReplaceTag}
                                                    onDeleteTag={this.onDeleteRoleTag}
                                                    onSelectTag={this.onSelectTag}

                                                    contentBoxHeight={this.state.ontologyHeight}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel header="Synsets">
                                <div className="grid">
                                    <div className="col-4">
                                        <FrameOntologyTree
                                            ref={(ref) => { this.synsetsSubOntology = ref; }}
                                            ontClasses={this.state.frameOntology.knownTermStanzas}
                                            ontology={this.state.frameOntology.synsets}
                                            selectedClass={this.state.selectedSynset}
                                            onSelectClass={this.onSelectSynset}
                                            onAddNewClass={this.onAddNewSynset}
                                            onCloneClass={this.onCloneSynset}
                                            onDeleteClass={this.onDeleteSynset}
                                            onRemoveRelationToParent={this.onRemoveRelationToParentSynset}
                                            onDragDrop={this.onDragAndDropClass}
                                            viewSizeState={this.state.viewSizeState}
                                            onViewResize={this.onViewResize}
                                            dndScope="synsetsont"
                                            contentBoxHeight={this.state.ontologyHeight}
                                        />
                                    </div>
                                    <div className="col-8">
                                        <div className="grid">
                                            <div className="col-12">
                                                <ClassDetails
                                                    subTree={OntologyUtil.SUBTREE_SYNSET}
                                                    //ontClass={this.state.selectedSynset}
                                                    ontClass={OntologyUtil.getClassForNode(this.state.frameOntology, this.state.selectedSynset)}
                                                    selectedTag={this.state.selectedTag}

                                                    onEditName={this.onEditSynsetName}
                                                    //onCopyTagToClass={this.onCopyTagToClass}
                                                    onAddSynonymTag={this.onAddSynonymTag}
                                                    onEditSynonymTag={this.onReplaceTag}
                                                    onDeleteTag={this.onDeleteSynsetTag}
                                                    onSelectTag={this.onSelectTag}

                                                    contentBoxHeight={this.state.ontologyHeight}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel header="Macros">

                                <div className="grid" style={{ display: this.state.editMacroTagBoxVisible ? '' : 'none' }}>
                                    <div className="col-12">
                                        <EditMacroTagBox
                                            tag={this.state.selectedTag}

                                            domains={this.props.domains}
                                            // weg?
                                            roles={[]}

                                            roleSelectItems={this.state.roleSelectItems}
                                            synsetSelectItems={this.state.synsetSelectItems}
                                            macroSelectItems={this.state.macroSelectItems}
                                            frameSelectItems={this.state.frameSelectItems}

                                            contentBoxHeight={this.state.editBoxHeight}
                                            editorHeight={this.state.syntaxEditorHeight}

                                            onEditTagSubmit={this.onEditMacroTagSubmit}
                                            onEditTagCancel={this.onEditMacroTagCancel}
                                        />
                                    </div>
                                </div>

                                <div className="grid"
                                    style={{ display: (this.state.editMacroTagBoxVisible) ? 'none' : '' }}>
                                    <div className="col-12">
                                        <div className="grid">
                                            <div className="col-12">
                                                <ClassDetails
                                                    subTree={OntologyUtil.SUBTREE_MACRO}

                                                    ontClass={this.state.selectedMacro}
                                                    selectedTag={this.state.selectedTag}

                                                    //onEditName={this.onEditName}
                                                    onCopyTagToClass={this.onCopyTagToMacro}
                                                    onAddMacroTag={this.onAddMacroTag}
                                                    onEditMacroTag={this.onEditMacroTag}
                                                    onDeleteTag={this.onDeleteMacroTag}
                                                    onSelectTag={this.onSelectTag}

                                                    contentBoxHeight={this.state.ontologyHeight}
                                                    headerLabel="Macros"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>

                <Dialog header="Save ontology with comment"
                    visible={this.state.storeBackupVisible}
                    style={{ width: '50vw' }}
                    modal={true}
                    onHide={() => this.setState({ storeBackupVisible: false })}
                    focusOnShow={false} >
                    <div className="grid" style={{ paddingTop: 15 }}>
                        <div className="col-fixed" style={{ width: 100 }}>
                            Comment
                        </div>
                        <div className="col" style={{ color: 'gray' }}>
                            <InputText
                                defaultValue={this.state.backupComment}
                                onChange={(e) => this.setState({ backupComment: e.target.value })}
                                style={{ width: '100%' }}
                            />
                            {/*<InputTextarea
                                rows={3}
                                cols={30}
                                placeholder="Your Message"
                            autoResize={true} />*/}

                        </div>
                        <div className="col-12" style={{ textAlign: 'right' }}>
                            <Button label="Confirm"
                                className="primaryButton p-button-sm"
                                //style={{ marginLeft: ".5em" }}
                                onClick={(e) => this.onSaveFrameOntologyWithComment()} >
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <LoadingOverlay
                    active={this.state.busy}
                    spinner={true}
                    className="fullPage"
                    text={this.state.busyText} >
                </LoadingOverlay>
            </>
        );
    }
}

export default withRouter(DevelopmentView);