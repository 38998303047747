import { PureComponent } from "react";

import type { ToolbarZoomSelect, T_PDFJS_Viewer } from "../../types";
import { ZOOM_COMPARE, ZOOM_SELECT } from "../../constant";
import { SharedToolbar } from "./shared/Toolbar";

interface Props {
    viewer: T_PDFJS_Viewer;
    numPages: number;
    pageNumber: number;
    nextPage:any;
    prevPage:any;
}


interface State {
    zoomStep: number;
    pageNumber: number;
    currentRotate: number;
    zoomPrecentage: string;
    zoomSelectLabel: string;
    numPages: number;
    currentScale: number;
    zoomSelect: Array<ToolbarZoomSelect>
}

export class ToolbarContainer extends PureComponent<Props, State> {

    state: State = {
        zoomStep: 7,
        pageNumber: 1,
        currentRotate: 0,
        zoomPrecentage: 'auto',
        zoomSelectLabel: 'auto',
        numPages: 0,
        currentScale: 1.25,
        zoomSelect: ZOOM_SELECT
    };



    handleScaleValue = (scale: any = 'auto') => {
        if (this.props.viewer) {
            if (typeof scale === 'string') {
                this.props.viewer.currentScaleValue = scale //"page-width";
            }
        }
    };

    changeScale = (item: any) => {
        const zoomItem = ZOOM_SELECT.find(val => val.value.toString() === item.target.value)
        this.setState({
            zoomSelectLabel: zoomItem?.label.toString() || '100%',
            zoomStep: zoomItem?.step || 7
        })
        this.handleScaleValue(item.target?.value.toString())
    }



    zoomIn = () => {
        this.zoomChanger((this.state.zoomStep + 1 > 13 ? this.state.zoomStep : this.state.zoomStep + 1))
    }

    zoomOut = () => {
        this.zoomChanger((this.state.zoomStep - 1 === 0 ? this.state.zoomStep : this.state.zoomStep - 1))
    }


    zoomChanger = (zoomStepTemp: number) => {
        const temp = ZOOM_COMPARE.find(item => item.step === zoomStepTemp)
        if (temp) {
            this.handleScaleValue((temp?.value).toString())
            this.setState({
                zoomStep: zoomStepTemp,
                zoomPrecentage: temp?.label,
                zoomSelectLabel: temp?.value.toString(),
                currentScale: temp?.value
            })
        }
    }


    rotate = () => {
        if (this.state.currentRotate !== 360) {
            const rotate = this.state.currentRotate + 90
            this.props.viewer.pagesRotation = rotate
            this.setState({
                currentRotate: rotate,
            })
        } else if (this.state.currentRotate === 360) {
            this.props.viewer.pagesRotation = 90
            this.setState({
                currentRotate: 90
            })
        }
        this.props.viewer.currentPageNumber = this.props.pageNumber;
    }

    rotateNext = () => {
        if (this.state.currentRotate !== 360) {
            const rotate = this.state.currentRotate - 90
            this.props.viewer.pagesRotation = rotate
            this.setState({
                currentRotate: rotate,
            })
        } else if (this.state.currentRotate === 360) {
            this.props.viewer.pagesRotation = 90
            this.setState({
                currentRotate: 90
            })
        }
        this.props.viewer.currentPageNumber = this.props.pageNumber;
    }

    fitPage = () => {
        const scale = Math.min(this.pageWidthScale(), this.pageHeightScale());
        const currentStep = this.stepBasedOnScale(scale)
        this.props.viewer.currentScaleValue = 'page-fit'
        this.setState({
            zoomPrecentage: 'page-fit',
            zoomStep: currentStep?.step || 4,
            currentScale: currentStep?.value || 0.8
        })
        this.props.viewer.currentPageNumber = this.props.pageNumber
    }


    pageWidthScale = () => {
        return (((this.props.viewer.container.clientWidth) / this.props.viewer.getPageView(this.props.pageNumber - 1)?.viewport.width) *
            this.state.currentScale);
    }


    pageHeightScale = () => {
        return (((this.props.viewer.container.clientHeight) / this.props.viewer.getPageView(this.props.pageNumber - 1)?.viewport.height) *
            this.state.currentScale)
    }


    stepBasedOnScale = (scale: number) => {
        const closest = ZOOM_COMPARE.map((item) => item?.value).reduce((a, b) => {
            return Math.abs(b - scale) < Math.abs(a - scale) ? b : a;
        });
        return ZOOM_COMPARE.find((item) => item?.value === closest)
    }



    widthPage = () => {
        const scale = this.pageWidthScale()
        const currentStep = this.stepBasedOnScale(scale)
        this.props.viewer.currentScaleValue = 'page-width'
        this.setState({
            zoomPrecentage: 'page-width',
            zoomStep: currentStep?.step || 7,
            currentScale: currentStep?.value || 1.25
        })
        this.props.viewer.currentPageNumber = this.props.pageNumber
    }


    pageNumberChange = (e: any) => {
        const value = e?.target.value
        if (value === '') {
            this.setState({
                pageNumber: value
            })
        }
        if (this.isNumber(value)) {
            if (value && value <= this.props.numPages && value >= 0) {
                this.props.viewer.currentPageNumber = parseInt(value)
                this.setState({
                    pageNumber: value
                })
            }
        }

    }

   
    isNumber = (n: any) => {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    }




    render() {
        return (
            <div className="toolbar-container">
                        <SharedToolbar
                            numPages={this.props.numPages}
                            prevPage={this.props.prevPage}
                            nextPage={this.props.nextPage}
                            pageNumberChange={this.pageNumberChange}
                            pageNumber={this.props.pageNumber}
                            zoomIn={this.zoomIn}
                            zoomOut={this.zoomOut}
                            fitPage={this.fitPage}
                            widthPage={this.widthPage}
                            rotate={this.rotate}
                            rotateNext={this.rotateNext}
                            zoomPrecentage={this.state.zoomPrecentage}
                        />
            </div>
        );
    }
}
