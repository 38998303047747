import { APP_PROPERTIES } from "../../../../properties";

///////////// Quill
//const CustomButtonOntology = () => <span>Concepts</span>
//const CustomButtonTitle = () => <span>Title</span>
const CustomButtonHighlightParentheses = () => <span title="Highlight the matching parentheses for current cursor position">
    Highlight parenthesis pair
</span>
/*const CustomButtonTime = () => <span>Publ. date</span>
const CustomButtonSearchModeSynonyms = () => <span title="Search synonyms only (without children)">Synonyms only</span>*/
const CustomButtonParentheses = () => <span title="Enclose selected search part in parentheses">Enclose in parentheses: ("marked text")</span>
//const CustomButtonOCID = () => <span>OCID</span>


export const CustomToolbar = () => (
    <div id="toolbar">
        <button className="ql-add" disabled={true} style={{ color: 'black', cursor: 'default' }}>
            <span>Add...</span>
        </button>
        <select className="ql-insertOntology" defaultValue="none">
            <option value="dombrowser">via Domain Explorer</option>
            <option value="ocid">via OCID</option>
            {/*<option value="help">Help</option> */}
            <option value="none">X</option>
        </select>
        <select className="ql-insertChemistry" defaultValue="none">
            {/*<option value="ocid">via OCID</option>
            <option value="smiles">via SMILES</option>*/}
            <option value="inchi">via InChI</option>
            <option value="inchikey">via InChI Key</option>
            {/*<option value="help">Help</option>*/}
            <option value="none">X</option>
        </select>
        <select className="ql-insertFreeText" defaultValue="none">
            <option value="tr">Exact</option>
            <option value="t">With variants</option>
            {/*<option value="help">Help</option>*/}
            <option value="none">X</option>
        </select>
        <select className="ql-insertSearchMode" defaultValue="none">
            <option value="info_smode_global" className="optionInfo">- Globally, valid for all concepts in query (smode) -</option>
            <option value="smode:o">Ontologically, with synonyms (default)</option>
            <option value="smode:s">Concept only, with synonyms</option>
            <option value="info_smode_local" className="optionInfo">- Locally, valid for all following concepts within the same group (cmode) -</option>
            <option value="cmode:o">Ontologically, with synonyms</option>
            <option value="cmode:s">Concept only, with synonyms</option>
            <option value="info_example" className="optionInfo">- Examples -</option>
            <option value="info_smode_ex" className="optionInfo">e.g. +OCID1 +(cmode:s OCID2 OCID3 cmode:o OCID4)</option>
            <option value="info_cmode_ex" className="optionInfo">e.g. smode:s +OCID1 +(OCID2 OCID3 cmode:o OCID4)</option>
            <option value="none">X</option>
        </select>
        {/*<button className="ql-insertSearchModeSynonyms">
            <CustomButtonSearchModeSynonyms />
        </button>*/}
        <select className="ql-insertLocation" defaultValue="none">

            <option value="info_general" className="optionInfo">- General -</option>
            <option value="doc">Whole document</option>
            <option value="title">Title</option>
            <option value="abstract">Abstract</option>
            <option value="ta">Title and abstract</option>

            <option value="info_patents_only" className="optionInfo">- Patents -</option>
            <option value="tac">Title, abstract and claim</option>
            <option value="claims">Claim</option>
            <option value="experimental">Experimental section</option>
            <option value="labeledcomp">Labeled compounds</option>

            <option value="info_publications_only" className="optionInfo">- Publications -</option>
            {/*<option value="info_all" className="optionInfo">Use "whole document" for original + referenced documents</option>*/}
            <option value="background">Background</option>
            <option value="introduction">Introduction</option>
            <option value="methods">Methods</option>
            <option value="experimental">Experimental section</option>
            <option value="results">Results</option>
            <option value="discussion">Discussion</option>
            <option value="conclusion">Conclusion</option>
            <option value="perspectives">Perspectives</option>
            <option value="supplement">Supplement</option>
            <option value="references">References</option>
            <option value="none">X</option>

            <option value="info_clintrials_only" className="optionInfo">- Clinical trials -</option>
            {/*<option value="info_all" className="optionInfo">Use "whole document" for original + referenced documents</option>*/}
            <option value="basicdata">Basic data</option>
            <option value="design">Design</option>
            <option value="description">Description</option>
            <option value="eligibility">Eligibility</option>
            <option value="endpoints">Endpoints</option>
            <option value="references">References</option>
            <option value="none">X</option>
            {/*  
            <option value="maincontent">Original document</option>
            <option value="description">Description</option>
            <option value="treatment">Treatment</option>
            <option value="eligibility">Eligibility</option>
            <option value="description">Description</option>
            <option value="references">References</option>
            <option value="refcontent">Referenced documents</option>
            <option value="none">X</option>
            */}
        </select>
        <select className="ql-insertQueryDistance" defaultValue="none">
            <option value='"sort"'>Influence on ranking</option>
            <option value='"filter"^1000'>Max. ~7000 characters</option>
            <option value='"filter"^500'>Max. ~3500 characters</option>
            <option value='"filter"^100'>Max. ~700 characters</option>
            <option value='"filter"^10'>Max. ~70 characters</option>
            <option value='"filter"^0'>Max. ~2 characters</option>
            <option value="none">X</option>
        </select>
        <select className="ql-insertTextDistance" defaultValue="none">
            {/*<option value="info_stopwords" className="optionInfo">Note: stopwords, punctuation marks etc. are not counted</option>*/}
            <option value="info_exact" className="optionInfo">- Text exact -</option>
            <option value='tr:"TERMS_OR_PHRASE"~0'>No distance</option>
            <option value='tr:"TERMS_OR_PHRASE"~3'>Lower distance, variable order</option>
            <option value='tr:"TERMS_OR_PHRASE"~10'>Higher distance, variable order</option>
            <option value="info_variants" className="optionInfo">- Text with variants -</option>
            <option value='t:"TERMS_OR_PHRASE"~0'>No distance</option>
            <option value='t:"TERMS_OR_PHRASE"~3'>Lower distance, variable order</option>
            <option value='t:"TERMS_OR_PHRASE"~10'>Higher distance, variable order</option>
            {/*<option value="info_example" className="optionInfo">- Example -</option>
            <option value='t:"aspirin headache"~2'>Max. 2 words in between aspirin and headache; with variants</option>*/}
            <option value="none">X</option>
        </select>
        <select className="ql-insertAdvanced" defaultValue="none">
            <option value="pdate"
                title='Time frame containing start and end: e.g. 2019-07_2019-10. Time frame containing just start:  e.g. 2019. Time frame containing just end: e.g. _2019-07-11.'>
                Publication date
            </option>
            {/* <option value="title">Search in title</option> */}
            <option value="auth">Author</option>
            <option value="xt-dn_authorsaff">Author affiliation</option>
            <option value="none">X</option>
        </select>
        <select className="ql-insertDocIDs" defaultValue="none">
            <option value="mdid">All IDs (except "Internal document ID")</option>
            <option value="doi">DOI</option>
            <option value="issn">ISSN</option>
            <option value="pmid">PMID</option>
            <option value="pmc">PMCID</option>
            <option value="pdid">Patent publication number</option>
            <option value="xi-dn_trialid">Clinical trial ID</option>
            <option value="docid">Internal document ID</option>
            <option value="none">X</option>
        </select>
        <select className="ql-insertPatentSpecifics" defaultValue="none">
            <option value="pdid">Patent publication number</option>
            <option value="pcntry">Patent office</option>
            <option value="pass">Assignee</option>
            <option value="pinv">Inventor</option>
            <option value="xt-dn_patlegalstatus">Legal status</option>
            <option value="pclssc">CPC class</option>
            <option value="pclssi">IPC class</option>
            <option value="ppriodate"
                title='Time frame containing start and end: e.g. 2019-07_2019-10. Time frame containing just start:  e.g. 2019. Time frame containing just end: e.g. _2019-07-11.'>
                Priority claim date
            </option>
            <option value="none">X</option>
        </select>
        <select className="ql-insertJournalSpecifics" defaultValue="none"
            style={{ display: `${!APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showJournalFilters ? 'none' : ''}` }}>
            <option value="publisher">Publisher</option>
            <option value="journal">Journal</option>
            <option value="vol">Volume</option>
            <option value="issue">Issue</option>
            <option value="none">X</option>
        </select>
        <select className="ql-insertCTSpecifics" defaultValue="none"
            style={{ display: `${!APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showClinicalTrialFilters ? 'none' : ''}` }}>
            <option value="xi-dn_trialid">Clinical trial ID</option>
            <option value="xt-dn_sponsors">Sponsor</option>
            <option value="xt-dn_investigators">Investigators</option>
            <option value="xt-dn_investigatorsaff">Investigator affiliation</option>
            <option value="xt-dn_status">Status</option>
            <option value="xt-dn_studytype">Study type</option>
            <option value="xt-dn_clinicalphase">Clinical phase</option>
            <option value="xd-dn_startdate"
                title='Time frame containing start and end: e.g. 2019-07_2019-10. Time frame containing just start:  e.g. 2019. Time frame containing just end: e.g. _2019-07-11.'>
                Start date
            </option>
            <option value="xd-dn_enddate"
                title='Time frame containing start and end: e.g. 2019-07_2019-10. Time frame containing just start:  e.g. 2019. Time frame containing just end: e.g. _2019-07-11.'>
                End date
            </option>
            <option value="none">X</option>
        </select>
        <select className="ql-insertGrantsDSSpecifics" defaultValue="none"
            style={{ display: `${!APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showGrantsDSFilters ? 'none' : ''}` }}>
            <option value="xt-dn_grantees">Investigator</option>
            <option value="xt-dn_funder">Funder</option>
            <option value="xt-dn_fundercountry">Funder country</option>
            <option value="xt-dn_researchorgs">Research organization</option>
            <option value="xt-dn_researchorgcountries">Country</option>
            <option value="xt-dn_researchorgstate">State</option>
            <option value="xd-dn_startdate"
                title='Time frame containing start and end: e.g. 2019-07_2019-10. Time frame containing just start:  e.g. 2019. Time frame containing just end: e.g. _2019-07-11.'>
                Start date
            </option>
            <option value="xd-dn_enddate"
                title='Time frame containing start and end: e.g. 2019-07_2019-10. Time frame containing just start:  e.g. 2019. Time frame containing just end: e.g. _2019-07-11.'>
                End date
            </option>
            <option value="none">X</option>
        </select>
        <select className="ql-insertPublicationsDSSpecifics" defaultValue="none"
            style={{ display: `${!APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showPublicationsDSFilters ? 'none' : ''}` }}>
            <option value="pmid">PMID</option>
            <option value="pmc">PMCID</option>
            <option value="doi">DOI</option>
            <option value="xt-dn_openaccesscategories">Open access status</option>
            <option value="pubtype">Publication type</option>
            <option value="publisher">Publisher</option>
            <option value="xt-dn_sourcetitle">Journal/Source</option>
            <option value="none">X</option>
        </select>
        {/*
        <button className="ql-insertTitle">
            <CustomButtonTitle />
        </button>
        */}
        {/*<button className="ql-insertTime" title='Time frame containing start and end: e.g. 2019-09-10_2019-09-11. Time frame containing just start:  e.g. 2019-09-10_. Time frame containing just end:  e.g. _2019-09-11.'>
            <CustomButtonTime />
        </button>
        */}
        <select className="ql-insertTermOcc" defaultValue="none">
            {/*<option value="">none (SHOULD occur: TERM)</option>*/}
            <option value="+">+ (MUST occur)</option>
            <option value="-">- (MUST NOT occur)</option>
            <option value="none">X</option>
        </select>
        <button className="ql-insertBrackets">
            <CustomButtonParentheses />
        </button>
        <button className="ql-highlightBrackets">
            <CustomButtonHighlightParentheses />
        </button>{/**/}
    </div>
);