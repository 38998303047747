import * as TestApi from '../../api/TestApi';
import * as OntologyApi from '../../api/content/OntologyApi';
import * as DocumentApi from '../../api/content/DocumentApi';
import * as FrameEditorApi from '../../api/content/FrameEditorApi';
import {
    startFetching, doneFetching,
    startFetchingCompounds, doneFetchingCompounds
} from './index';
import * as ActionTypes from './index';
import { addDirectMatch, addMergedSuggestions } from '../../components/webapi/util';


export const fetchTermSuggestions = (history, search, showFailGrowlSuggestions) => dispatch => {
    //dispatch(startFetching())
    TestApi.fetchAllTermSuggestions(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                const result = answer.payload;
                //console.log('result: ', result);
                const term = result.termFragment;
                const terms = [];
                const directMatch = addDirectMatch(result.directFragmentMatches, term, false, terms);
                addMergedSuggestions(result.mergedSuggestedTerms, term, false, terms, directMatch);
                result.suggestedTerms = terms;
                dispatch(addTermSuggestions(result))

                //dispatch(addTermSuggestions(answer.payload))
                //dispatch(doneFetching())
                break
            case 'FAILED':
                showFailGrowlSuggestions(answer.payload)
                dispatch(addTermSuggestions(answer.payload))
                //dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch term suggestions!')
            //dispatch(doneFetching())
        }
    })
}

export const fetchEveryRelation = (history, search, showFailGrowl) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchEveryRelation(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addEveryRelation(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                showFailGrowl(answer.payload)
                dispatch(addEveryRelation(answer.payload))
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch relations!')
                dispatch(doneFetching())
        }
    })
}

export const addEveryRelation = everyRelation => ({
    type: ActionTypes.FETCH_EVERY_RELATION,
    everyRelation
})

export const addTermSuggestions = terms => ({
    type: ActionTypes.FETCH_TERM_SUGGESTIONS,
    terms
})

export const fetchRelationSearchResults = (search, showFailGrowl) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchAllRelations(search).then(answer => {
        switch (answer.status) {
            case 'SUCCESS':
                dispatch(addRelations(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                showFailGrowl(answer.payload)
                dispatch(addRelations(answer.payload))
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch relations!')
                dispatch(doneFetching())
        }
    })
}

export const addRelations = relations => ({
    type: ActionTypes.FETCH_RELATIONS,
    relations
})

export const fetchDocumentSearchResults = (history, search, showFailGrowl) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchAllDocuments(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addDocuments(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                showFailGrowl(answer.payload)
                dispatch(addDocuments(answer.payload))
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch documents!')
                dispatch(doneFetching())
        }
    })
}

export const addDocuments = documents => ({
    type: ActionTypes.FETCH_DOCUMENTS,
    documents
})

export const fetchSortFields = (history, search, showFailGrowl) => dispatch => {
    //dispatch(startFetching())
    TestApi.fetchAllSortFields(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addSortFields(answer.payload))
                //dispatch(doneFetching())
                break
            case 'FAILED':
                //showFailGrowl(answer.payload)
                dispatch(addSortFields(answer.payload))
                //dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch sort fields!')
            // dispatch(doneFetching())
        }
    })
}

export const addSortFields = sortFields => ({
    type: ActionTypes.FETCH_SORTFIELDS,
    sortFields
})

export const fetchPDF = (search) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchPDFContent(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                //history.push('/')
                break
            case 'SUCCESS':
                dispatch(addPDF(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(addPDF(answer.payload))
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch documents!')
                dispatch(doneFetching())
        }
    })
}

export const addPDF = pdf => ({
    type: ActionTypes.FETCH_PDF,
    pdf
})

export const fetchPropertyTree = (history) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchPropertiesTrees().then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addPropertyTree(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch property tree!')
                dispatch(doneFetching())
        }
    })
}

export const addPropertyTree = propertyTree => ({
    type: ActionTypes.FETCH_PROPERTY_TREE,
    propertyTree
})

export const fetchRelationDocumentSearchResults = (history, search, showFailGrowl) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchAllRelationDocuments(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addRelationDocuments(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                showFailGrowl(answer.payload)
                dispatch(addRelationDocuments(answer.payload))
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch documents!')
                dispatch(doneFetching())
        }
    })
}

export const addRelationDocuments = relationDocuments => ({
    type: ActionTypes.FETCH_RELATIONS_DOCUMENTS,
    relationDocuments
})

export const fetchFormQueries = (search, history) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchFormsQueries(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addFormQueries(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch property tree!')
                dispatch(doneFetching())
        }
    })
}

export const addFormQueries = formQueries => ({
    type: ActionTypes.FETCH_FORM_QUERIES,
    formQueries
})

export const fetchSimilarDocumentSearchResults = (history, search, showFailGrowl) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchAllSimilarDocuments(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addSimilarDocuments(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                showFailGrowl(answer.payload)
                dispatch(addSimilarDocuments(answer.payload))
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch documents!')
                dispatch(doneFetching())
        }
    })
}

export const addSimilarDocuments = similarDocuments => ({
    type: ActionTypes.FETCH_SIMILARDOCUMENTS,
    similarDocuments
})

export const fetchPublicationListXLSX = (history, search) => dispatch => {
    dispatch(startFetching())
    TestApi.getPublicationListXLSX(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default: console.log('Was not able to fetch publications list!')
                dispatch(doneFetching())
        }
    })
}

export const addPublicationListXLSX = publicationListXLSX => ({
    type: ActionTypes.ADD_PUBLICATIONLIST_XLSX,
    publicationListXLSX
})

export const fetchSimilarPublicationListXLSX = (history, search) => dispatch => {
    dispatch(startFetching())
    TestApi.getSimilarPublicationListXLSX(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default: console.log('Was not able to fetch publications list!')
                dispatch(doneFetching())
        }
    })
}

export const addSimilarPublicationListXLSX = publicationListSimilarXLSX => ({
    type: ActionTypes.ADD_PUBLICATIONLIST_SIMILAR_XLSX,
    publicationListSimilarXLSX
})

export const fetchRelationListXLSX = (search) => dispatch => {
    dispatch(startFetching())
    TestApi.getRelationListXLSX(search).then(answer => {
        switch (answer.status) {
            case 'SUCCESS':
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default: console.log('Was not able to fetch relations list!')
                dispatch(doneFetching())
        }
    })
}

export const addRelationListXLSX = relationListXLSX => ({
    type: ActionTypes.ADD_RELATIONLIST_XLSX,
    relationListXLSX
})

export const fetchIPCClasses = () => dispatch => {
    dispatch(startFetching())
    TestApi.fetchAllIPCClasses().then(answer => {
        switch (answer.status) {
            case 'SUCCESS':
                dispatch(addIPCClasses(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch IPC classes!')
                dispatch(doneFetching())
        }
    })
}

export const addIPCClasses = ipcClasses => ({
    type: ActionTypes.FETCH_IPC_CLASSES,
    ipcClasses
})

export const addQuery = (history, search, showQueryAddSuccessGrowl, showQueryAddFailGrowl) => dispatch => {
    dispatch(startFetching())
    TestApi.addNewQuery(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addQueries())
                dispatch(doneFetching())
                showQueryAddSuccessGrowl()
                break
            case 'FAILED':
                dispatch(doneFetching())
                showQueryAddFailGrowl(answer.payload)
                break
            default:
                console.log('Was not able to add search!')
                dispatch(doneFetching())
        }
    })
}

export const addQueries = newQueries => ({
    type: ActionTypes.ADD_NEW_QUERY,
    newQueries
})

export const fetchQueryString = (history, search, showFailGrowlQueryString) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchAllQueryStrings(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addQueryString(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                showFailGrowlQueryString(answer.payload)
                dispatch(addQueryString(answer.payload))
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch search string!')
                dispatch(doneFetching())
        }
    })
}

export const addQueryString = queryString => ({
    type: ActionTypes.FETCH_QUERY_STRING,
    queryString
})

export const fetchStatistics = (history, search) => dispatch => {
    dispatch(startFetching())
    TestApi.getAllStatistics(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addAllStatistics(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch statistics!')
                dispatch(doneFetching())
        }
    })
}

export const addAllStatistics = statistics => ({
    type: ActionTypes.ADD_ALL_STATISTICS,
    statistics
})

export const fetchRelationDocumentsListXLSX = (history, search) => dispatch => {
    dispatch(startFetching())
    TestApi.getRelationDocumentsListXLSX(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default: console.log('Was not able to fetch relation documents list!')
                dispatch(doneFetching())
        }
    })
}

export const modifyFormQueries = (history, search, showFailGrowlModify) => dispatch => {
    dispatch(startFetching())
    TestApi.modifyFormsQueries(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                //dispatch(addModifiedFormQueries(answer.payload))
                showFailGrowlModify(answer.payload)
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch property tree!')
                dispatch(doneFetching())
        }
    })
}

export const removeFormQueries = (history, search, showFailGrowlRemove) => dispatch => {
    dispatch(startFetching())
    TestApi.removeFormsQueries(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                //dispatch(addRemovedFormQueries(answer.payload))
                showFailGrowlRemove(answer.payload)
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch property tree!')
                dispatch(doneFetching())
        }
    })
}

export const saveFormQueries = (history, search, showFailGrowlSave) => dispatch => {
    dispatch(startFetching())
    TestApi.saveFormsQueries(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                //dispatch(addSavedFormQueries(answer.payload))
                showFailGrowlSave(answer.payload)
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch property tree!')
                dispatch(doneFetching())
        }
    })
}

export const fetchCompoundHits = (history, search) => dispatch => {
    dispatch(startFetchingCompounds())
    TestApi.getAllCompoundHits(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addAllCompoundHits(answer.payload))
                dispatch(doneFetchingCompounds())
                break
            case 'FAILED':
                dispatch(doneFetchingCompounds())
                break
            default:
                console.log('Was not able to fetch compound hits!')
                dispatch(doneFetchingCompounds())
        }
    })
}

export const addAllCompoundHits = compoundHits => ({
    type: ActionTypes.ADD_ALL_COMPOUND_HITS,
    compoundHits
})

export const fetchPublicationListRIS = (history, search) => dispatch => {
    dispatch(startFetching())
    TestApi.getPublicationListRIS(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default: console.log('Was not able to fetch publications list!')
                dispatch(doneFetching())
        }
    })
}

export const addPublicationListRIS = publicationListRIS => ({
    type: ActionTypes.ADD_PUBLICATIONLIST_RIS,
    publicationListRIS
})

export const fetchPublications = (history, search) => dispatch => {
    dispatch(startFetching())
    TestApi.getAllPublications(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addAllPublications(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch publications!')
                dispatch(doneFetching())
        }
    })
}

export const addAllPublications = publications => ({
    type: ActionTypes.ADD_PUBLICATIONS,
    publications
})

export const editQuery = (history, search, showQueryEditSuccessGrowl, showQueryEditFailGrowl) => dispatch => {
    dispatch(startFetching())
    TestApi.editSelectedQuery(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(editQueries(answer.payload))
                dispatch(doneFetching())
                showQueryEditSuccessGrowl()
                break
            case 'FAILED':
                dispatch(doneFetching())
                showQueryEditFailGrowl(answer.payload)
                break
            default:
                console.log('Was not able to edit search!')
                dispatch(doneFetching())
        }
    })
}

export const editQueries = editedQueries => ({
    type: ActionTypes.EDIT_QUERY,
    editedQueries
})

export const fetchQueries = (history, showFailGrowlQueries) => dispatch => {
    dispatch(startFetching())
    TestApi.fetchAllQueries().then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(addAllQueries(answer.payload))
                dispatch(doneFetching())
                break
            case 'FAILED':
                showFailGrowlQueries(answer.payload)
                dispatch(addAllQueries(answer.payload))
                dispatch(doneFetching())
                break
            default:
                console.log('Was not able to fetch queries!')
                dispatch(doneFetching())
        }
    })
}

export const addAllQueries = queries => ({
    type: ActionTypes.FETCH_QUERIES,
    queries
})

export const removeQuery = (history, search, showQueryDeleteSuccessGrowl, showQueryDeleteFailGrowl) => dispatch => {
    dispatch(startFetching())
    TestApi.removeSelectedQuery(search).then(answer => {
        switch (answer.status) {
            case 'LOGGEDOUT':
                history.push('/')
                break
            case 'SUCCESS':
                dispatch(removeQueries())
                dispatch(doneFetching())
                showQueryDeleteSuccessGrowl()
                break
            case 'FAILED':
                dispatch(doneFetching())
                showQueryDeleteFailGrowl(answer.payload)
                break
            default:
                console.log('Was not able to remove search!')
                dispatch(doneFetching())
        }
    })
}

export const removeQueries = deletedQueries => ({
    type: ActionTypes.REMOVE_QUERY,
    deletedQueries
})




/**
 * Fetches all available domains.
 */
export const fetchAvailableDomains = () => (dispatch) => {

    dispatch(ActionTypes.startFetchingAvailableDomains());

    return OntologyApi.fetchAvailableDomains()
        .then(answer => {
            //console.log('fetch available domains: answer: ', answer);

            switch (answer.status) {
                case 'SUCCESS': {
                    dispatch(availableDomains(answer.payload));
                    dispatch(ActionTypes.doneFetchingAvailableDomains());
                    break;
                }

                default: {
                    dispatch(ActionTypes.doneFetchingAvailableDomains());
                }
            }
        })
}

export const availableDomains = (availableDomains) => ({
    type: ActionTypes.AVAILABLE_DOMAINS,
    availableDomains
})


export const fetchAvailableUserRepositories = () => (dispatch) => {

    dispatch(ActionTypes.startFetchingAvailableRepositories());

    return TestApi.fetchAvailableUserRepositories()
        .then(answer => {
            //console.log('fetch available domains: answer: ', answer);

            switch (answer.status) {
                case 'SUCCESS': {
                    dispatch(availableRepositories(answer.payload));
                    dispatch(ActionTypes.doneFetchingAvailableRepositories());
                    break;
                }

                default: {
                    dispatch(ActionTypes.doneFetchingAvailableRepositories());
                }
            }
        })
}

export const availableRepositories = (availableRepositories) => ({
    type: ActionTypes.AVAILABLE_REPOSITORIES,
    availableRepositories
})



/**
 * Fetches repository overview incl. number of documents each repository contains.
 */
export const fetchRepositoryOverview = (repositories, instime, facetTypes) => (dispatch) => {

    dispatch(ActionTypes.startFetchingRepositoryOverview());

    return collectAllRepositoryData(repositories, instime, facetTypes)
        .then(answer => {
            //console.log('fetch repository overview: answer: ', answer);

            switch (answer.status) {
                case 'SUCCESS': {
                    dispatch(repositoryOverview(answer.payload));
                    dispatch(ActionTypes.doneFetchingRepositoryOverview());
                    break;
                }

                default: {
                    dispatch(ActionTypes.doneFetchingRepositoryOverview());
                }
            }
        })
}


export const repositoryOverview = (repositoryOverview) => ({
    type: ActionTypes.REPOSITORY_OVERVIEW,
    repositoryOverview
})


/**
 * 
 */
const collectAllRepositoryData = async (repositories, instime, facetTypes) => {

    // --- fetch result count for each repository --- //
    let hitCountsMap = {};
    let errorsMap = {};

    let response = await DocumentApi.fetchDocumentResultsStatistics('all:*');
    //console.log('***fetchDocumentResultsStatistics all: ', response);

    if (response.status === 'SUCCESS' && response.payload.results[0] && response.payload.results[0].repData) {
        for (var repsData of Object.values(response.payload.results[0].repData)) {
            //console.log('repData: ', repData);
            hitCountsMap[repsData.name] = repsData.qryCounts.all;
        }
    }

    /*
    for (const repo of Object.values(repositories)) {
        await getHitsForRepository(repo.name, 'all:*', 0, hitCountsMap, errorsMap, chartsDataAbs);
    }
    */


    // --- fetch result count since instime for each repository --- //
    let hitCountsTimeRange = {};
    let errorsMapTimeRange = {};
    let facetResults = {};
    const dateLastWeek = 'instime:' + instime;

    const facetRepos = ['patents', 'pmc', 'med'];
    for (const repo of facetRepos) {
        await getHitsForRepositoryWithFacetData(repo, 'all:* ' + dateLastWeek, 0, facetTypes, hitCountsTimeRange, errorsMapTimeRange, facetResults);
    }

    const responseTimeRange = await DocumentApi.fetchDocumentResultsStatistics('all:*', instime);
    //console.log('***fetchDocumentResultsStatistics last week: ', response);

    if (responseTimeRange.status === 'SUCCESS' && responseTimeRange.payload.results[0] && responseTimeRange.payload.results[0].repData) {
        for (var repData of Object.values(responseTimeRange.payload.results[0].repData)) {
            //console.log('repData: ', repData);
            hitCountsTimeRange[repData.name] = repData.qryCounts.cust;
        }
    }

    return {
        status: 'SUCCESS',
        payload: {
            repositories: repositories,

            hitCounts: hitCountsMap,
            fetchingErrors: errorsMap, //errorsMapTimeRange

            hitCountsTimeRange: hitCountsTimeRange,
            facetResultsTimeRange: facetResults
        }
    };
}


/**
 * 
 */
const getHitsForRepositoryWithFacetData = async (repID, query, hitCount, facetTypes, hitCountsMap = {}, errorsMap = {}, facetResultsMerged = {}) => {

    // --- number of documents published last month --- //
    const request = DocumentApi.createDocumentSearchRequest(repID, query, facetTypes);
    const response = await DocumentApi.fetchDocuments(0, hitCount, request);

    if (response.status === 'SUCCESS') {
        const result = response.payload;
        const count = result.documentCount ? result.documentCount : 0;
        hitCountsMap[repID] = count;

        if (repID === 'patents') {
            facetResultsMerged["patents:Assignee"] = null;
            if (result.facetResults && result.facetResults["Assignee"]) {
                facetResultsMerged["patents:Assignee"] = result.facetResults["Assignee"];
            }
        }
        else if (repID === 'med') {
            facetResultsMerged["med:Author"] = null;
            if (result.facetResults && result.facetResults["Author"]) {
                facetResultsMerged["med:Author"] = result.facetResults["Author"];
            }
        }
        else if (repID === 'pmc') {
            facetResultsMerged["pmc:DOMAIN__companies"] = null;
            if (result.facetResults && result.facetResults["DOMAIN__companies"]) {
                facetResultsMerged["pmc:DOMAIN__companies"] = result.facetResults["DOMAIN__companies"];
            }
            facetResultsMerged["pmc:Author"] = null;
            if (result.facetResults && result.facetResults["Author"]) {
                facetResultsMerged["pmc:Author"] = result.facetResults["Author"];
            }
        }

        return count;
    }
    else {
        errorsMap[repID] = response.message;
    }

    return 0;
};

/**
 * Action for checking status of middleware and backend services.
 */
export const checkServerStatus = () => (dispatch) => {

    dispatch(ActionTypes.startFetchingServerStatus());

    return FrameEditorApi.checkStatusOfBackendServices()
        .then(answer => {
            //console.log('checkServerStatus: answer: ', answer);

            switch (answer.status) {
                case 'SUCCESS': {
                    dispatch(serverStatus(answer.payload));
                    dispatch(ActionTypes.doneFetchingServerStatus());
                    break;
                }

                default: {
                    dispatch(ActionTypes.doneFetchingServerStatus());
                }
            }
        })
}

export const serverStatus = (serverStatus) => ({
    type: ActionTypes.SERVER_STATUS,
    serverStatus
})