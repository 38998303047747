import { isArrayEmpty } from "../../util";
import { CHEMISTRY_QUERY_TERM_TYPE_PROPERTY, CHEMISTRY_BULK_IMPORT_TYPES, CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS, CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, CHEMISTRY_QUERY_TERM_TYPE_SMILES, CHEMISTRY_QUERY_TERM_TYPE_OCID } from "./chemistrySearchConstants";


export const createQueryTerm = (name, ocid) => {

    if (name && ocid) {
        const filterTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
            queryValues: [name],
            term: name,
            ocids: [ocid],
            domains: ['chem']
        }
        return filterTerm;
    }
    return null;
}

export const createQueryTermForOcid = (ocid) => {

    if (ocid) {
        const filterTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_OCID,
            queryValues: [ocid],
            term: ocid,
            ocids: [ocid],
            domains: ['chem']
        }
        return filterTerm;
    }
    return null;
}

export const createQueryTermForSmiles = (smiles) => {

    if (smiles) {
        const filterTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_SMILES,
            queryValues: [smiles],
            term: smiles,
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForNumRanges = (filter, startDate, endDate) => {

    if (filter && (startDate || endDate)) {
        const startDateStrg = startDate ? startDate : '';
        const endDateStrg = endDate ? endDate : '';
        const dateStrg = `${startDateStrg}${endDateStrg ? `_${endDateStrg}` : ''}`;
        const dateLabelStrg = `${startDateStrg} - ${endDateStrg}`;

        const filterTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_PROPERTY,
            filterID: filter.id,
            queryPrefix: filter.queryPrefix,
            term: dateLabelStrg,
            queryValues: [dateStrg],
        };
        return filterTerm;
    }
    return null;
}

export const createFilterTermForChemProperty = (filter, value, termLabel, booster, fuzziness) => {

    if (filter && value) {
        const filterTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_PROPERTY,
            filterID: filter.id,
            extraFieldID: filter.extraFieldID,
            queryPrefix: filter.queryPrefix,
            queryValues: [value],
            term: termLabel ? termLabel : value,
        }
        if (booster || filter.booster) {
            filterTerm.booster = booster ? booster : filter.booster
        }
        if (fuzziness || filter.fuzziness) {
            filterTerm.fuzziness = fuzziness ? fuzziness : filter.fuzziness
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForChemPropertyMultipleStructuredValues = (filter, termObjects) => {

    if (filter && !isArrayEmpty(termObjects)) {

        const addFuzzinessToSpecificTermsOnly = {};
        const termsDeduplicated = {};
        termObjects.forEach(termVal => {
            termsDeduplicated[termVal.term] = true;
            if (termVal.isManuallyAdded) {
                addFuzzinessToSpecificTermsOnly[termVal.term] = true;
            }
        });
        const terms = Object.keys(termsDeduplicated); //termObjects.map(val => val.term);
        const termsLabel = `${terms.slice(0, 10).map(t => addFuzzinessToSpecificTermsOnly[t] ? `~ ${t}` : t).join(' OR ')}${terms.length > 10 ? ' OR ...' : ''}`;

        const filterTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_PROPERTY,
            filterID: filter.id,
            facetID: filter.facetID,
            extraFieldID: filter.extraFieldID,
            queryPrefix: filter.queryPrefix,
            queryValues: terms,
            term: termsLabel,
            addFuzzinessToSpecificTermsOnly: addFuzzinessToSpecificTermsOnly
        }
        if (filter.booster) {
            filterTerm.booster = filter.booster
        }
        if (filter.fuzziness) {
            filterTerm.fuzziness = filter.fuzziness
        }
        return filterTerm;
    }
    return null;
}

export const createFilterTermForBulkImport = (filter, bulkIDType, bulkIDs) => {

    if (filter && bulkIDType && bulkIDs) {
        // TODO: pot error: bulk ID not defined
        const label = CHEMISTRY_BULK_IMPORT_TYPES[bulkIDType] ? CHEMISTRY_BULK_IMPORT_TYPES[bulkIDType].label : bulkIDType;

        const filterTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS,
            filterID: filter.id, //'bulkImport',
            bulkIDType: bulkIDType,
            bulkIDs: bulkIDs,
            term: `${bulkIDs?.length} ${label}`
        }
        return filterTerm;
    }
    return null;
}