import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import {
  FREETEXT_EXACT,
  FREETEXT_WITH_VARIANTS,
} from "../../../../../../../general/docsearch/searchConstants";
import { useEffect, useState } from "react";
import {
  getDomainSelectionType,
  isFreeText,
} from "../../../../../../helpers/advancedSearch";
import { Button } from "primereact/button";

const SpecifyDomainsDialog = ({ domains, onValuesChange, visible, onHide }) => {
  const [selectedDomainSelectionType, setSelectedDomainSelectionType] =
    useState();
  const [selectedDomains, setSelectedDomains] = useState([]);

  useEffect(() => {
    const domainType = getDomainSelectionType(domains);
    setSelectedDomainSelectionType(domainType);
    setSelectedDomains(domains);
  }, [domains]);

  const isText = isFreeText(domains);

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          className="p-button-sm p-button-secondary"
          onClick={() => {
            onHide();
          }}
        />
        <Button
          label="OK"
          className="p-button-sm primaryButton"
          onClick={() => {
            onHide();
            if (selectedDomainSelectionType === "domains") {
              onValuesChange([...selectedDomains]);
            } else {
              onValuesChange([selectedDomainSelectionType]);
            }
          }}
        />
      </>
    );
  };

  return (
    <>
      <Dialog
        id="specifyTextDialog"
        visible={visible}
        onHide={onHide}
        onShow={() => {}}
        blockScroll
        style={{ minWidth: 500, maxWidth: "90vw" }}
        header={"Specify text type"}
        footer={renderFooter}
        appendTo="self"
        className="styled-dialog"
      >
        <div className="flex flex-wrap gap-3">
          <div className="flex align-items-center">
            <RadioButton
              inputId="selTextExact"
              name="domains"
              value={FREETEXT_EXACT.prefix}
              onChange={(e) => setSelectedDomainSelectionType(e.value)}
              checked={selectedDomainSelectionType === FREETEXT_EXACT.prefix}
            />
            <label htmlFor="selTextExact" className="ml-2">
              {FREETEXT_EXACT.label}
            </label>
          </div>
          <div className="flex align-items-center">
            <RadioButton
              inputId="selTextWithVariants"
              name="domains"
              value={FREETEXT_WITH_VARIANTS.prefix}
              onChange={(e) => setSelectedDomainSelectionType(e.value)}
              checked={
                selectedDomainSelectionType === FREETEXT_WITH_VARIANTS.prefix
              }
            />
            <label htmlFor="selTextWithVariants" className="ml-2">
              {FREETEXT_WITH_VARIANTS.label}
            </label>
          </div>
        </div>

        {!isText && (
          <div style={{ marginTop: 30 }}>
            <div className="query-note">
              Please note: Setting a term to text will remove all
              concept-related data, such as domains and OCIDs from this search
              term. This cannot be reversed. If you would like to add certain
              domains or OCIDs again, please remove this entry and add a new one
              by using the autocomplete field or Domain explorer.
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default SpecifyDomainsDialog;
