import * as ActionTypes from '../actions'

export const repositoriesNode = { // initial state for left aside
    "key": "0",
    "label": "repositories",
    "children": [{"key": "0-0", "label": "multiselect"}]
};

export const initialLeftAsideState = {0: true}; // repositories header should be expanded on default
export const defaultResultCount = 10;

const initialState = {
    isFetching: false,
    queryTerms: [],
    searchResults: {},
    reposWithBiomarkerSearchFeature: [],
    selectedReposForSearch: [],
    popupExpandedKeys: {},
    leftAsideExpandedKeys: initialLeftAsideState,
    checkedResults: [],
    metaData: [],
    roleMapping: [], // used for name-label role mapping
    nodes: [],
    popupNodes: null,
    headersStructureSkeleton: [],
    popupTreeName: '',
    suggestions: [],
    inputText: '', //@todo find the reason why the text in the AC is not cleared after 'Clear all'
    sortField: 'biomarker',
    sortDirection: 'ascending',
    firstResultIndex: 0,
    resultCount: 10,
    isFirstRender: true,
};

const biomarkerReducer = (state = initialState, action) => {
    const {payload, type} = action;

    switch (type) {
        case ActionTypes.BIOMARKER_SET_QUERY_TERMS:
            return {...state, queryTerms: payload}
        case ActionTypes.BIOMARKER_SET_SEARCH_RESULTS:
            return {...state, searchResults: payload}
        case ActionTypes.BIOMARKER_TOGGLE_IS_FETCHING:
            return {...state, isFetching: payload}
        case ActionTypes.BIOMARKER_SET_REPOS_WITH_BIOMARKER_SEARCH_FEATURE:
            return {...state, reposWithBiomarkerSearchFeature: payload}
        case ActionTypes.BIOMARKER_SET_SELECTED_REPOS_FOR_SEARCH:
            return {...state, selectedReposForSearch: payload}
        case ActionTypes.BIOMARKER_SET_METADATA:
            return {...state, metaData: payload}
        case ActionTypes.BIOMARKER_SET_POPUP_EXPANDED_KEYS:
            return {...state, popupExpandedKeys: payload}
        case ActionTypes.BIOMARKER_SET_POPUP_TREE_NAME:
            return {...state, popupTreeName: payload}
        case ActionTypes.BIOMARKER_SET_LEFT_ASIDE_EXPANDED_KEYS:
            return {...state, leftAsideExpandedKeys: payload}
        case ActionTypes.BIOMARKER_SET_CHECKED_RESULTS:
            return {...state, checkedResults: payload}
        case ActionTypes.BIOMARKER_SET_ROLE_MAPPING:
            return {...state, roleMapping: payload}
        case ActionTypes.BIOMARKER_SET_NODES:
            return {...state, nodes: payload}
        case ActionTypes.BIOMARKER_SET_POPUP_NODES:
            return {...state, popupNodes: payload}
        case ActionTypes.BIOMARKER_SET_HEADERS_STRUCTURE_SKELETON:
            return {...state, headersStructureSkeleton: payload}
        case ActionTypes.BIOMARKER_SET_SUGGESTIONS:
            return {...state, suggestions: payload}
        case ActionTypes.BIOMARKER_SET_INPUT_TEXT:
            return {...state, inputText: payload}
        case ActionTypes.BIOMARKER_SET_SORT_FIELD:
            return {...state, sortField: payload}
        case ActionTypes.BIOMARKER_SET_SORT_DIRECTION:
            return {...state, sortDirection: payload}
        case ActionTypes.BIOMARKER_SET_FIRST_RESULT_INDEX:
            return {...state, firstResultIndex: payload}
        case ActionTypes.BIOMARKER_SET_RESULT_COUNT:
            return {...state, resultCount: payload}
        case ActionTypes.BIOMARKER_SET_IS_FIRST_RENDER:
            return {...state, isFirstRender: payload}
        case ActionTypes.BIOMARKER_RESET_STATE:
            const stateForReset = Object.fromEntries(
                Object.entries(initialState).filter(([key]) => {
                    return !(key === 'reposWithBiomarkerSearchFeature' || key === 'selectedReposForSearch');
                }))

            return {...state, selectedReposForSearch: [...state.reposWithBiomarkerSearchFeature], ...stateForReset}
        default:
            return state
    }
}

export default biomarkerReducer
