import { useSelector } from "react-redux";


const useDomainLabel = (domainID) => {
    const domainLabelsMap = useSelector((state) => state.user.data.userDetails.department.domainLabelsMap);
    const domainLabel = domainLabelsMap && domainID && domainLabelsMap[domainID];

    return domainLabel;
}

export default useDomainLabel;