export const  createPastDate = (count, period, targetDate = new Date()) => {
    const copiedTargetDate = new Date(targetDate);

switch (period) {
    case 'year':
      return   new Date(copiedTargetDate.setFullYear(new Date(targetDate).getFullYear() - count));
    case 'month':
        return   new Date(copiedTargetDate.setFullYear(new Date(targetDate).getMonth() - count));

    default:
        throw new Error('Unknown period');
}
}
