import { Component, } from 'react';
import { Dialog } from 'primereact/dialog';
import ToastContent from '../../../common/CustomToast/ToastContent';
import './ChemistrySearchToast.css'


class ChemistrySearchToast extends Component {

    constructor(props) {
        super(props);

        this.state = { activeIndex: null }
    }

    render() {

        const { displayToast, onHide, onExport, onSendToSearch, onSelectAllOnPage, selectedEntries, elementLabel } = this.props;

        return <>
            <Dialog visible={displayToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                onHide={onHide} className='chemistry-search-toast'
                draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                <ToastContent
                    selectedElements={selectedEntries}
                    elementLabel={elementLabel}
                    onHide={onHide}
                    onExport={onExport}
                    activeOptions={['export']}
                    entries={[{
                        onClick: onSendToSearch,
                        label: 'In documents',
                        materialIcon: 'search'
                    }, {
                        onClick: onSelectAllOnPage,
                        label: 'All on page',
                        materialIcon: 'check_box'
                    },
                    /*{
                            onClick: onSendToSearch,
                            label: 'Search in',
                            materialIcon: 'search'
                        }*/]}
                />
            </Dialog>
        </>
    }
}
export default ChemistrySearchToast