import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
//import {FileUpload} from 'primereact/fileupload';
import CartridgeManager from './cartridges/CartridgeManager';
import RepositoryManager from './repositories/RepositoryManager';
import Annotator from './annotator/Annotator';
import Jobs from './jobs/Jobs';
import Message from './messages/Message';
import './PipelineManager.css';
//import {Button} from 'primereact/button';
import LoadingOverlay from "@speedy4all/react-loading-overlay";


import {
    fetchAvailableRepositories,
    checkServerStatus,
    showGrowl,
    fetchCartridges,
    lockFrontend,
    changeManagerTab
} from '../../../redux/actions';



class PipelineManager extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lastGrowlMessage: 0,
            frontendLocked: false
        };
        this.backendChecker = this.backendChecker.bind(this);
    }

    onTabChange = (index) => {
        this.props.changeManagerTab (index);
    }
    
    backendChecker () {
      if (this.props.lockFrontend){
        this.props.checkServerStatus ();
      }
      setTimeout (this.backendChecker, 3*60000);
    }
    
    componentDidMount() {
        this.backendChecker ();
        this.setState({lastGrowlMessage: this.props.growlmessages.length})
    }
    
    
    UNSAFE_componentWillReceiveProps(nextProps) {
      let ngm = 0
      if (nextProps.growlmessages) {
        while (nextProps.growlmessages && nextProps.growlmessages.length > this.state.lastGrowlMessage + ngm) {
          this.growl.show(nextProps.growlmessages[this.state.lastGrowlMessage + ngm]);
          ngm++;
        }
      
        this.setState({lastGrowlMessage: this.state.lastGrowlMessage + ngm})
      }      
      
      if (this.props.lockFrontend && this.props.backendUp) {
        // backend seems to be up again
        this.props.unlockFrontend ();
        this.props.updateCartridges ();
        this.props.updateRepositories ();
      }
      
    }
    
    render() {
        //console.log ("lock frontend 2:", this.props.lockFrontend);
        return (
            <>
                <TabView className="tabbedManagement"
                    activeIndex={this.props.managerTab}
                    renderActiveOnly={false}
                    onTabChange={(e) => this.onTabChange(e.index)} >
                    <TabPanel header="Cartridges">
                        <CartridgeManager />
                    </TabPanel>
                    <TabPanel header="Repositories">
                        <RepositoryManager />
                    </TabPanel>
                    <TabPanel header="Annotator">
                        <Annotator />
                    </TabPanel>
                    <TabPanel header="Jobs">
                        <Jobs />
                    </TabPanel>
                    <TabPanel header="Messages">
                        <Message />
                    </TabPanel>
                </TabView>
                <Toast ref={(el) => { this.growl = el }} appendTo={document.body} />

                <LoadingOverlay
                    active={this.props.lockFrontend}
                    spinner={true}
                    className="fullPage"
                    text={this.props.backendUp ? 'Pipeline setup in progress...' : 'Pipeline setup in progress. Services stopped...'} ></LoadingOverlay>
            </>
        );
    }
    
    
}



const mapStateToProps = state => {
  return {
    managerTab: state.managerReducer.managerTab,
    growlmessages: state.managerReducer.growlmessages,
    backendUp: state.managerReducer.backendUp,
    lockFrontend: state.managerReducer.lockFrontend
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showGrowl: (summary, detail, severity = "info", debug = "") => dispatch(showGrowl(summary, detail, severity, debug)),
    unlockFrontend: () => dispatch(lockFrontend(false)),
    updateCartridges: () => dispatch(fetchCartridges ()),
    updateRepositories: () => dispatch(fetchAvailableRepositories()),
    checkServerStatus: () => dispatch (checkServerStatus ()),
    changeManagerTab: (tabNum) => dispatch (changeManagerTab (tabNum)),
  }
}

export default connect (
  mapStateToProps,
  mapDispatchToProps
) (PipelineManager);
