const TruncateEntriesLink = ({
  prefix = " ...",
  numberOfEntries,
  visible,
  showAll,
  onClick,
}) => {
  return visible ? (
    <div>
      {!showAll && prefix}
      <a onClick={() => onClick(!showAll)} style={{ marginLeft: 10 }}>
        {showAll ? `Show less` : `Show all (${numberOfEntries})`}
      </a>
    </div>
  ) : null;
};

export default TruncateEntriesLink;
