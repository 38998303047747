import { sendApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';
import { fetchMculeData } from './ChemistryApi';
import { fetchAvailableDomains } from './OntologyApi';
import { sortDomainsToConceptsMapByDomainLabels, addMergedSuggestions, addSuggestions, addDirectMatch, isArrayEmpty, addFreetextOptions, createFreetextOption } from '../../components/webapi/util';
import { convertStructuresToImagesV2, convertStructureToImageV2, createStructuresToImagesRequestV2, createStructureToImageRequestV2 } from './ChemistryApi';
import { filterChildrenAndParents, isConceptWithinDomainFilters, isOcid, isOcidWithinFilterRanges } from '../../components/webapi/util/concepts';
import { QUERY_TERM_TYPE_CONCEPT } from '../../components/webapi/general/docsearch/searchConstants';


// ----------------------------------------------------------------------- //
// --- create requests from data ----------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Creates an object for a concept data request.
 * @param {Number[]} ocids array of OCIDs for which concept data will be requested
 * @param {Boolean} withBaseData response should include basic data, e.g. synonyms
 * @param {Boolean} withExtendedData response should include extended data, e.g. SMILES, molar mass etc.
 * @param {Boolean} withParents response should include parents
 * @param {Boolean} withChildren response should include children
 * @param {Number} childrenOffset offset for paging children
 * @param {Number} childrenMaxCount number of children to be returned
 * @param {Boolean} withSourceReferences response should include source references
 */
export const createConceptDataRequest = (ocids, withBaseData, withExtendedData, withParents,
    withChildren, childrenOffset, childrenMaxCount, withSourceReferences) => {
    const req = {
        ocids: ocids,
        withBaseData: withBaseData,
        withExtendedData: withExtendedData,
        withSourceReferences: withSourceReferences,
        withParents: withParents,
        withChildren: withChildren,
        childrenOffset: childrenOffset,
        childrenMaxCount: childrenMaxCount
    };

    if (withChildren && childrenMaxCount > 0) {
        req.childrenOrder = {
            havingChildrenPosition: 'INLINE',
            byValue: 'PREFNAME_1000'
        };
    }

    return req;
}

/**
 * Creates an object for a term suggestion request.
 * @param {string} term term fragment
 * @param {String[]} filterDomains array of active domain prefixes 
 * @param {Number} maxSuggestTerms maximum number of returned suggestions
 * @param {Boolean} withPrefname response should include prefnames
 * @param {Boolean} mergeDomains duplicate terms from different domains should be merged
 * @param {Boolean} annotatedTermsOnly return annotated terms only
 * @param {Array} filterOcids list of allowed subtree OCIDs
 * @param {Object} rangeOcids object containing range of OCIDs: { start: OCID, end: OCID, exclude: BOOLEAN }
 */
export const createTermSuggestionsRequest = (term, filterDomains, maxSuggestTerms, withPrefname,
    mergeDomains, annotatedTermsOnly = true, filterOcids = null, rangeOcids = null) => {
    const req = {
        termFragment: term,
        domains: (!isArrayEmpty(filterDomains) ? filterDomains : null),
        maxSuggestTerms: maxSuggestTerms,
        withPrefname: withPrefname,
        mergeDomains: mergeDomains,
        withDirectMatches: true
    };
    if (!annotatedTermsOnly) {
        //req.repository = "_domains";
        req.useDomainIndex = !annotatedTermsOnly;
    }
    if (!isArrayEmpty(filterOcids)) {
        req.namedFilter = 'ocidSubtrees';
        req.namedFilterParameters = {
            allowedSubtreesRootOcids: `${filterOcids.join(', ')}`
        };
    }
    if (rangeOcids?.start && rangeOcids?.end) {
        req.namedFilter = 'ocidRanges';
        req.namedFilterParameters = {
            allowedOcidRanges: `${rangeOcids.exclude ? '!' : ''}${rangeOcids.start}-${rangeOcids.end}`
        };
    }

    return req;
}

// ----------------------------------------------------------------------- //
// --- run API requests -------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Fetches data for OCIDs with or without structure image.
 * @param {Object} request request object created by function createConceptDataRequest
 * @param {Boolean} withStructImage response should include structure image if a valid SMILES is part of the concept
 * @param {Boolean} withMculeLink if true the Mcule link will be part of the result
 * @param {Boolean} includeNonHits if true the result map will also contain OCIDs that are unknown
 * @param {Object} extraData object containing extra data about OCIDs, which will be added to the result
 * @param {Object} rangeOcids object containing range of OCIDs: { start: OCID, end: OCID, exclude: BOOLEAN }
 * @returns an object containing data about a list of OCIDs
 */
export const fetchConceptsData = async (request, withStructImage = false, withMculeLink = false, includeNonHits = false,
    extraData = null, structImageSettings = {}, queryMol, rangeOcids = null) => {
    // --- fetch data for concept with specific OCID --- //
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/concepts`, request);

    if (result.status === 'SUCCESS') {

        const conceptsMap = {};
        if (result.payload?.concepts) {
            for (var concept of Object.values(result.payload.concepts)) {
                const ocid = concept.ocid + " ";
                conceptsMap[ocid.trim()] = concept;
            }
        }

        const concepts = [];
        for (var i = 0; i < request.ocids.length; i++) {
            const ocid = request.ocids[i];
            const concept = !!conceptsMap[ocid] ? conceptsMap[ocid] : {};
            //console.log('conceptsMap[ocid]: ', concept);

            if (concept && isOcidWithinFilterRanges(ocid, rangeOcids)) {
                // --- remove children and parents that should be filtered out --- //
                filterChildrenAndParents(concept, rangeOcids);
                concepts.push(concept);
            }
            else if (includeNonHits) {
                concepts.push({ ocid: ocid });
            }

            if (extraData && extraData[ocid]) {

                if (!concept.extendedData) {
                    concept.extendedData = {};
                }

                for (var edKey of Object.keys(extraData[ocid])) {
                    if (edKey === 'smiles') {
                        concept.extendedData.smiles = [extraData[ocid].smiles];
                    }
                    /*
                    else if (edKey === 'urls') {
                        concept.extendedData = {
                            sourceReferences: [extraData[ocid].urls]
                        };
                        //{source.conceptUrl}>{source.sourceName}: {source.conceptRefId}
                    } 
                    */
                }
                concept.extraData = extraData[ocid];
            }
        }

        if (withStructImage || withMculeLink) {
            // --- extract smiles from concepts --- //
            const smiles = [];
            for (var conc1 of concepts) {
                if (conc1.extendedData?.smiles && conc1.extendedData.smiles.length > 0) {
                    smiles.push(conc1.extendedData.smiles[0]);
                }
            }

            if (withStructImage) {
                // --- convert structures to images and add them to concepts --- //
                const request = createStructuresToImagesRequestV2(smiles, structImageSettings.height, structImageSettings.width, structImageSettings.format, queryMol);
                const response = await convertStructuresToImagesV2(request);
                if (response && response.status === 'SUCCESS') {
                    const imagesMap = response.payload.images;
                    if (imagesMap) {
                        for (var conc2 of Object.values(concepts)) {
                            if (conc2.extendedData?.smiles && conc2.extendedData.smiles.length > 0) {
                                const smiles = conc2.extendedData.smiles[0];
                                if (imagesMap[smiles]) {
                                    conc2.image = imagesMap[smiles];
                                }
                            }
                        }
                    }
                }
            }

            if (withMculeLink && !isArrayEmpty(smiles)) {
                // --- request mcule links for unique smiles --- //
                const request = { smiles: smiles };
                const response = await fetchMculeData(request);
                //console.log('links: ', result);

                if (response?.status === 'SUCCESS' && response?.payload?.mculeLinks) {
                    const mculeLinks = response.payload.mculeLinks;
                    for (let conc of Object.values(concepts)) {
                        if (conc.extendedData?.smiles && conc.extendedData.smiles.length > 0) {
                            const smiles = conc.extendedData.smiles[0];
                            if (mculeLinks[smiles]) {
                                if (!conc.sourceReferences) {
                                    conc.sourceReferences = [];
                                }
                                const mculeLink = {
                                    sourceName: 'Mcule',
                                    sourceUrl: 'https://mcule.com/',
                                    conceptUrl: mculeLinks[smiles],
                                    conceptRefId: mculeLinks[smiles].replace('https://mcule.com/', '').replace(/\/$/, '')
                                };
                                conc.sourceReferences.push(mculeLink);
                                conc.extendedData.mculeLink = mculeLink;
                            }
                        }
                    }
                }
            }
        }

        //console.log('concepts: ', concepts);

        return ({ status: 'SUCCESS', payload: concepts });
    }
    return result;
}

/**
 * Fetches data for single OCID with or without structure image.
 * @param {Object} request request object created by function createConceptDataRequest
 * @param {Boolean} withStructImage response should include structure image if a valid SMILES is part of the concept
 * @param {Boolean} withMculeLink if true the Mcule link will be part of the result
 * @param {Boolean} includeNonHits if true the result map will also contain OCIDs that are unknown
 * @param {Object} extraData object containing extra data about OCIDs, which will be added to the result
 * @param {Object} rangeOcids object containing range of OCIDs: { start: OCID, end: OCID, exclude: BOOLEAN }
 * @returns an object containing data about an OCID
 */
export const fetchConceptData = async (request, withStructImage = false, withMculeLink = false, includeNonHits = false,
    extraData = null, rangeOcids = null) => {

    // --- fetch data for concept with specific OCID --- //
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/concepts`, request);

    if (result.status === 'SUCCESS') {
        let concept = null;
        const ocid = !isArrayEmpty(request.ocids) ? request.ocids[0] : null;
        if (!isArrayEmpty(result.payload?.concepts) && isOcidWithinFilterRanges(ocid, rangeOcids)) {
            concept = result.payload.concepts[0];
        }
        else if (includeNonHits && ocid) {
            concept = { ocid: ocid };
        }
        // --- remove children and parents that should be filtered out --- //
        filterChildrenAndParents(concept, rangeOcids);

        if (ocid && extraData && extraData[ocid]) {

            if (!concept.extendedData) {
                concept.extendedData = {};
            }

            for (var edKey of Object.keys(extraData[ocid])) {
                if (edKey === 'smiles') {
                    concept.extendedData.smiles = [extraData[ocid].smiles];
                }
                /*
                else if (edKey === 'urls') {
                    concept.extendedData = {
                        sourceReferences: [extraData[ocid].urls]
                    };
                    //{source.conceptUrl}>{source.sourceName}: {source.conceptRefId}
                } 
                */
            }
            concept.extraData = extraData[ocid];
        }

        // --- if smiles exists convert structure to image and add it to the concept and add mcule link (if wanted) --- //
        if (concept && (withStructImage || withMculeLink) && !isArrayEmpty(concept.extendedData?.smiles)) {
            const smiles = concept.extendedData.smiles[0];

            if (withStructImage) {
                let image = null;
                const request = createStructureToImageRequestV2(concept.extendedData.smiles[0]);
                const response = await convertStructureToImageV2(request);
                if (response && response.status === 'SUCCESS') {
                    image = response.payload;
                }
                concept.image = image;
            }

            if (withMculeLink && !isArrayEmpty(smiles)) {
                // --- request mcule links for unique smiles --- //
                const request = { smiles: [smiles] };
                const response = await fetchMculeData(request);
                //console.log('link: ', result);
                if (response?.status === 'SUCCESS' && response?.payload?.mculeLinks) {
                    const mculeLinks = response.payload.mculeLinks;
                    if (mculeLinks[smiles]) {
                        if (!concept.sourceReferences) {
                            concept.sourceReferences = [];
                        }
                        const mculeLink = {
                            sourceName: 'Mcule',
                            sourceUrl: 'https://mcule.com/',
                            conceptUrl: mculeLinks[smiles],
                            conceptRefId: mculeLinks[smiles].replace('https://mcule.com/', '').replace(/\/$/, '')
                        };
                        concept.sourceReferences.push(mculeLink);
                        concept.extendedData.mculeLink = mculeLink;
                    }
                }
            }
        }
        return ({ status: 'SUCCESS', payload: concept });
    }
    return result;
}

/**
 * Fetches term suggestions for the given term within given filter domains.
 * @param {string} term term fragment
 * @param {String[]} filterDomains array of active domain prefixes 
 * @param {Boolean} annotatedTermsOnly true if only annotated terms should be returned
 * @param {Boolean} termsOnly true if only terms should be returned, not OCIDs and other data
 * @param {Boolean} addFreetext if true a free text option is added to the list of suggestions
 * @returns a list of suggestions
 */
export const fetchTermSuggestions = async (term, filterDomains, annotatedTermsOnly = true, termsOnly = true, addFreetext = false) => {

    // --- fetch term suggestions --- //
    const request = createTermSuggestionsRequest(term, filterDomains, 20, true, true, annotatedTermsOnly);
    const useIndexStrg = !request.useDomainIndex ? '?useDomainIndex=true&useCombinedIndex=true' : '';
    delete request.useDomainIndex;
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions${useIndexStrg}`, request);
    //console.log('result: ', result);

    if (result.status === 'SUCCESS') {

        const terms = [];
        if (addFreetext) {
            addFreetextOptions(term, terms);
        }
        const directMatch = addDirectMatch(result.payload.directFragmentMatches, term, termsOnly, terms);
        addSuggestions(result.payload.suggestedTerms, term, termsOnly, terms, directMatch);

        //console.log('terms: ', terms);
        return ({ status: 'SUCCESS', payload: terms });  // payload: { terms: terms, termSnippet: term } 
    }

    return result;
}

/**
 * Fetches term suggestions for the given term fragment.
 * @param {String|Number} term term fragment
 * @param {String[]} filterDomains array of domain prefixes
 * @param {Array} filterOcids list of allowed subtree OCIDs
 * @param {Object} rangeOcids object containing range of OCIDs: { start: OCID, end: OCID, exclude: BOOLEAN }
 * @param {Boolean} annotatedTermsOnly true if only annotated terms should be returned
 * @param {Boolean} termsOnly true if only terms should be returned, not OCIDs and other data
 * @param {Boolean} addFreetext if true a free text option is added to the list of suggestions
 * @param {Number} maxNumOfSuggestions maximum number of returned suggestions
 * @returns a list of suggestions and the original term fragment in the payload
 */
export const fetchMergedTermSuggestionsV2 = async (term, filterDomains, filterOcids = null, rangeOcids = null,
    annotatedTermsOnly = true, termsOnly = true, addFreetext = false, maxNumOfSuggestions = 20) => {

    const result = await fetchMergedTermSuggestions(term, filterDomains, filterOcids, rangeOcids, annotatedTermsOnly, termsOnly, addFreetext, maxNumOfSuggestions);

    if (result?.payload) {
        const suggestions = result.payload;
        result.payload = {
            suggestions, termFragment: term
        };
    }
    return result;
}

/**
 * Fetches term suggestions for the given term fragment.
 * @param {String|Number} term term fragment
 * @param {String[]} filterDomains array of domain prefixes
 * @param {Array} filterOcids list of allowed subtree OCIDs
 * @param {Object} rangeOcids object containing range of OCIDs: { start: OCID, end: OCID, exclude: BOOLEAN }
 * @param {Boolean} annotatedTermsOnly true if only annotated terms should be returned
 * @param {Boolean} termsOnly true if only terms should be returned, not OCIDs and other data
 * @param {Boolean} addFreetext if true a free text option is added to the list of suggestions
 * @param {Number} maxNumOfSuggestions maximum number of returned suggestions
 * @returns a list of suggestions
 */
export const fetchMergedTermSuggestions = async (term, filterDomains, filterOcids = null, rangeOcids = null,
    annotatedTermsOnly = true, termsOnly = true, addFreetext = false, maxNumOfSuggestions = 20) => {

    // --- fetch term suggestions --- //
    const request = createTermSuggestionsRequest(term, filterDomains, maxNumOfSuggestions, true, true, annotatedTermsOnly, filterOcids, rangeOcids);
    //delete request.repository;
    const useIndexStrg = !annotatedTermsOnly ? '&useDomainIndex=true&useCombinedIndex=true' : '';
    delete request.useDomainIndex;

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions?mergeResults=true${useIndexStrg}`, request);
    //console.log('result: ', result);

    // --- convert results for better handling --- // 
    if (result.status === 'SUCCESS') {

        const terms = [];
        const directMatches = result.payload?.directFragmentMatches;
        // --- add free text option --- //
        if (addFreetext && directMatches !== undefined && isArrayEmpty(Object.keys(directMatches))) {
            const freeTextOption = createFreetextOption(term);
            if (freeTextOption) {
                terms.push(freeTextOption);
            }
        }
        // --- add direct match --- //
        const directMatch = addDirectMatch(directMatches, term, termsOnly, terms);
        addMergedSuggestions(result.payload.mergedSuggestedTerms, term, termsOnly, terms, directMatch);

        //console.log('terms: ', terms);
        return ({ status: 'SUCCESS', payload: terms });  // payload: { terms: terms, termSnippet: term } 
    }

    return result;
}


/**
 * Returns suggestion for a given OCID.
 * @param {Number} ocid ID of the requested concept
 * @param {String[]} filterDomains array of active domain prefixes 
 * @param {Object} rangeOcids object containing range of OCIDs { start: OCID, end: OCID, exclude: BOOLEAN }
 * @returns 
 */
export const fetchOcidTermSuggestions = async (ocid, filterDomains, rangeOcids = null) => {

    let suggestion;
    if (isOcidWithinFilterRanges(ocid, rangeOcids)) {
        // --- request concept data for this OCID --- //
        const request = createConceptDataRequest([ocid], true, false, true, true, 0, 10, true);
        const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/concepts`, request);

        if (result.status === 'SUCCESS' && result.payload && result.payload.concepts) {
            // --- if concept with this OCID is known and belongs to an active domain add it to results --- //
            const concept = (result.payload && result.payload.concepts && result.payload.concepts.length > 0) ? result.payload.concepts[0] : null;
            if (isConceptWithinDomainFilters(concept, filterDomains)) {
                suggestion = {
                    ocids: [concept.ocid],
                    domains: [concept.domain],
                    prefnames: [concept.preferredName],
                    term: concept.preferredName,
                    label: concept.preferredName,
                    queryValues: [concept.preferredName],
                    type: QUERY_TERM_TYPE_CONCEPT
                };
            }
        }
    }
    return ({ status: 'SUCCESS', payload: suggestion });
}

/**
 * Fetches concepts matching the given term either as OCID or synonym within given filter domains.
 * @param {String|Number} term synonym or OCID which will be searched in domains
 * @param {String[]} filterDomains array of active domain prefixes 
 * @param {Object} rangeOcids object containing range of OCIDs { start: OCID, end: OCID, exclude: BOOLEAN }
 * @param {Boolean} annotatedTermsOnly if true only annotated terms will be returned
 * @param {Boolean} sortByDomainLabels if true direct matches will be sorted by domain labels
 * @returns a map of direct matches, with domain IDs as keys
 */
export const fetchDirectMatches = async (term, filterDomains, rangeOcids = null, annotatedTermsOnly = true, sortByDomainLabels = true) => {
    // --- collect all matching concepts --- //
    let matchingConcepts = null;
    let matchingConceptsSorted = null;

    // --- input is an OCID -> fetch concept data --- //
    const termStrg = term + "";
    if (isOcid(termStrg) && isOcidWithinFilterRanges(term, rangeOcids)) {
        // --- request concept data for this OCID --- //
        const request = createConceptDataRequest([term], true, false, true, true, 0, 10, true);
        const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/concepts`, request);

        if (result.status === 'SUCCESS' && result.payload && result.payload.concepts) {
            // --- if concept with this OCID is known and belongs to an active domain add it to results --- //
            const concept = (result.payload && result.payload.concepts && result.payload.concepts.length > 0) ? result.payload.concepts[0] : null;
            if (isConceptWithinDomainFilters(concept, filterDomains)) {
                matchingConcepts = {};
                matchingConcepts[concept.domain] = [concept];
            }
        }
    }
    // --- no results for OCID -> treat input as a term --- //
    // @todo always execute? number could also be a synonym! results must be merged then!
    if (!matchingConcepts) {
        // --- fetch term suggestions --- //
        const request = createTermSuggestionsRequest(term, filterDomains, 20, true, true, annotatedTermsOnly, null, rangeOcids);

        const useIndexStrg = !annotatedTermsOnly ? '?useDomainIndex=true&useCombinedIndex=true' : '';
        delete request.useDomainIndex;

        if (rangeOcids?.start && rangeOcids?.end) {
            request.namedFilter = 'ocidRanges';
            request.namedFilterParameters = {
                allowedOcidRanges: `${rangeOcids.exclude ? '!' : ''}${rangeOcids.start}-${rangeOcids.end}`
            };
        }

        const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions${useIndexStrg}`, request);

        if (result.status === 'SUCCESS') {
            // --- add only direct matches to results --- //
            matchingConcepts = result.payload.directFragmentMatches;
        }
        else {
            return result;
        }
    }

    // --- remove children and parents that should be filtered out --- //
    if (matchingConcepts) {
        Object.values(matchingConcepts).forEach(conc => {
            filterChildrenAndParents(conc, rangeOcids);
        });
    }

    if (sortByDomainLabels) {
        const result = await fetchAvailableDomains();
        if (result.status === 'SUCCESS' && result.payload && result.payload.domainLabelsMap) {
            matchingConceptsSorted = sortDomainsToConceptsMapByDomainLabels(matchingConcepts, result.payload.domainLabelsMap);
            return ({ status: 'SUCCESS', payload: matchingConceptsSorted });
        }
    }

    return ({ status: 'SUCCESS', payload: matchingConcepts });
}


export const fetchTermConcepts = async (term, useDomainIndex = true) => {

    // @todo: filterDomains

    // --- fetch term suggestions --- //
    const request = createTermSuggestionsRequest(term, null, 1, true, true, false);
    const useIndexStrg = !useDomainIndex ? '?useDomainIndex=true&useCombinedIndex=true' : '';
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions${useIndexStrg}`, request);

    if (result.status === 'SUCCESS') {
        return ({ status: 'SUCCESS', payload: result.payload.directFragmentMatches });
    }

    return result;
}