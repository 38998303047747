import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import AutoComplete from "../../../common/AutoComplete/AutoComplete";
import { fetchMergedTermSuggestions, fetchOcidTermSuggestions } from "../../../../api/content/ConceptApi";
import { checkResultAndGetPayload } from "../../../../api";
import AutocompleteSuggestionListItem from "./AutocompleteSuggestionListItem";
import AutocompleteSearchFieldItem from "./AutocompleteSearchFieldItem";
import { QUERY_TERM_TYPE_CONCEPT } from "../docsearch/searchConstants";
import InputFieldWithClear from "../inputfields/InputFieldWithClear";
import { isArrayEmpty } from "../../util";
import { isOcid } from "../../util/concepts";


class AutocompleteInputField extends Component {

    constructor(props) {
        super(props);

        this.state = { key: 'inputField' };

        this.growl = createRef();
        this.searchFieldRef = createRef();

        this.selItemTemplate = this.selItemTemplate.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.queryTerms !== this.props.queryTerms) {
            // --- update background colors of query terms --- //
            // this.searchFieldRef?.current?.multiContainer?.children
            const boxes = document.querySelectorAll('#searchInput ul li.p-autocomplete-token.p-highlight');
            if (!isArrayEmpty(boxes)) {
                for (var child of boxes) {
                    if (child.querySelector('.type-filter')) {
                        child.style.backgroundColor = '#ffffff';
                    }
                    else if (child.querySelector('.type-concept')) {
                        child.style.backgroundColor = '#E8F3FC';
                    }
                    else if (child.querySelector('.type-freetext')) {
                        child.style.backgroundColor = '#f3f3f3';
                    }
                }
            }
        }
    }

    selItemTemplate(item) {
        return <AutocompleteSearchFieldItem
            item={item}
            filterDefinitions={this.props.filterDefinitions}
            domainLabelsMap={this.props.domainLabelsMap}
            onClickItem={(term) => this.props.onSpecifyQueryTerm(term)}
            domainColors={this.props.domainColors}
        />
    }

    itemTemplate(item) {
        return <AutocompleteSuggestionListItem
            item={item}
            queryPart={this.state.part}
            domainLabelsMap={this.props.domainLabelsMap}
            onSpecify={(term) => this.props.onSpecifyQueryTerm(term)}
            acRef={this.searchFieldRef}
            domainColors={this.props.domainColors}
        />;
    }


    /**
     * Fetches suggestions for term fragment. Suggestions will be merged if they have
     * the same OCID. Free text option will be added if there are no direct matches.
     * @param {*} event 
     */
    autocomplete = (termFragment) => {

        this.latestTermFragment = termFragment;
        setTimeout(async () => {
            // --- fetch term suggestions --- //
            let terms = [];
            // old:
            // const response = await fetchMergedTermSuggestions(termFragment, null, null, null, true, false, true); // this.state.domains
            // terms = checkResultAndGetPayload(response, this.growl);

            // new:
            let response;
            let ocidSuggestion;
            if (isOcid(termFragment)) {
                response = await fetchOcidTermSuggestions(termFragment, null, null);
                ocidSuggestion = checkResultAndGetPayload(response);
                if (ocidSuggestion) {
                    terms.push(ocidSuggestion);
                }
            }

            const addFreeText = !ocidSuggestion;
            response = await fetchMergedTermSuggestions(termFragment, null, null, null, true, false, addFreeText); // this.state.domains
            const termSuggs = checkResultAndGetPayload(response, this.growl) || [];
            terms = [...terms, ...termSuggs];
      
            if (termFragment === this.latestTermFragment && terms) {
                terms.forEach(term => {
                    term.type = QUERY_TERM_TYPE_CONCEPT;
                    term.queryValues = [term.term];
                });

                this.setState({
                    termSuggestions: terms,
                    part: termFragment
                });
            }
        }, 200);
    }

    /**
     * Hack: Updates key of input field. Otherwise it can't be used after removing boxes or clearing the field. 
     */
    updateInputFieldKey = () => {
        this.setState({ key: `inputField_${Math.floor(Math.random() * 10000)}` });
    }

    render() {

        //console.log(this.state.termSuggestions)
        const { queryTerms, onQueryTermsChange } = this.props;

        return (
            <div style={{ width: '100%' }}>
                <Toast ref={(el) => this.growl = el} />

                <InputFieldWithClear
                    onClear={() => onQueryTermsChange([])}
                    showIcon={!isArrayEmpty(queryTerms)}>
                    <AutoComplete
                        key={this.state.key}
                        innerRef={this.searchFieldRef}
                        id="searchInput"
                        field="term"
                        multiple={true}
                        value={queryTerms}
                        suggestions={this.state.termSuggestions}
                        completeMethod={(e) => this.autocomplete(e.query)}
                        onChange={(e) => {
                            this.updateInputFieldKey();
                            onQueryTermsChange(e.value);
                        }}
                        onUnselect={this.updateInputFieldKey}
                        minLength={1}
                        //placeholder="Type search (e.g. liver disease)"
                        className="withLogic width100perc" // unsetSuggestionListHeight
                        style={{ display: 'grid' }}
                        selectedItemTemplate={this.selItemTemplate}
                        itemTemplate={this.itemTemplate}
                        //forceSelection={true}
                        autoHighlight={true}
                        //scrollHeight="500px"
                        appendTo={document.body}
                    />
                </InputFieldWithClear>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
    domainColors: state.user.data.userDetails.department.selectedDomains
})
const mapDispatchToProps = (dispatch) => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutocompleteInputField));