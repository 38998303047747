import { Button } from "primereact/button";
import FilterTermDateRangeInput from "../../../../../../../../../../common/queryBuilder/searchForm/searchGroup/editMode/groupTypes/filter/FilterTermDateRangeInput";
import FilterTermTextInput from "../../../../../../../../../../common/queryBuilder/searchForm/searchGroup/editMode/groupTypes/filter/FilterTermTextInput";
import { isEmpty } from "lodash";


const ValueColumn = ({
    data,
    filterValue,
    onEdit,
    onFilterEntryChange,
    isEditMode,
    isDateRangeFilter
}) => {

    return isEditMode ? (
        <div className="p-inputgroup flex-1 align-items-center">
            {isDateRangeFilter ? (
                <FilterTermDateRangeInput
                    values={filterValue}
                    onValuesChange={(vals) => {
                        const { id, filterID, filter } = filterValue;
                        const filterValuesToEditNew = { id, filterID, filter, ...vals };
                        onEdit(filterValuesToEditNew);
                    }}
                />
            ) : (
                <FilterTermTextInput
                    values={filterValue}
                    onValuesChange={(vals) => {
                        const { id, filterID, filter } = filterValue;
                        const filterValuesToEditNew = { id, filterID, filter, ...vals };
                        onEdit(filterValuesToEditNew);
                    }}
                    placeholder={filterValue.filter?.placeholder}
                />
            )}
            <div className="ml-2">
                <Button
                    icon="pi pi-times"
                    className="p-button-secondary inline-edit-button"
                    onClick={() => onEdit(null)}
                />
            </div>
            <div className="ml-1">
                <Button
                    icon="pi pi-check"
                    className="primaryButton inline-edit-button"
                    disabled={isEmpty(filterValue?.val)}
                    onClick={() => {
                        onFilterEntryChange(data, filterValue);
                        onEdit(null);
                    }}
                />
            </div>
        </div>
    ) : (
        <div onDoubleClick={() => onEdit(data)}>
            {data.label}
        </div>
    );
}

export default ValueColumn;