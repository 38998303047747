import { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";

class IncludeMixturesSearchInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="inclMixtureInfo"
                title="Click for information about including mixtures"
                imageAltText="Include mixtures information"
                overlayContent={
                    <>
                        <h3>Include mixtures</h3>

                        <p>
                            If the box is checked, the result will also contain structures consisting of multiple fragments.
                        </p>
                    </>
                }
            />
        );
    }
}

export default IncludeMixturesSearchInfo;