import { sendApiRequest } from './index'
import { APP_PROPERTIES } from '../properties/index';


const BACKEND_TYPE_WEBAPI = 'webapi';
const BACKEND_TYPE_BIOMARKER = 'biomarker';


export const fetchRepositoryCatalog = async (backendID) => {
    const response = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/repository/catalogue?backendId=${backendID}`, {});
    return response;
}


export const fetchRepositoryCatalogs = async (backends) => {

    const map = {};
    let promises = [];
    let checkedBackends = [];

    for (let backend of backends) {
        if (backend.type === BACKEND_TYPE_WEBAPI || backend.type === BACKEND_TYPE_BIOMARKER) {

            checkedBackends.push(backend);
            promises.push(fetchRepositoryCatalog(backend.id));
            promises = promises.map(promise => promise.catch(error => error));

            const allPromises = Promise.all(promises);
            try {
                const responses = await allPromises;
                let index = 0;
                for (let resp of responses) {
                    if (resp.status === 'SUCCESS') {
                        map[backend.id] = {
                            error: false,
                            repositoryData: resp.payload?.repositories ? Object.values(resp.payload.repositories) : []
                        };
                    }
                    else {
                        map[backend.id] = {
                            error: true,
                            errorMessage: resp.message || 'Error occurred'
                        }
                    }
                }
            }
            catch (error) {
                // @todo
                //console.log(error);
            }
        }
    };
    return { status: 'SUCCESS', payload: map };
}


export const fetchRepositoryInfo = async (repositoryID) => {
    const response = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/repository/catalogue/rid?repositoryId=${repositoryID}`, {});
    return response;
}