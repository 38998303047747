import React, { Component, createRef } from 'react';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { checkResultAndGetPayload } from '../../../../../api';
import { convertStructuresToImagesV2, createStructuresToImagesRequestV2, fetchMculeData } from '../../../../../api/content/ChemistryApi';
import { getRepositoryForName } from '../../../../../api/content/ContentApi';
import { REPOSITORY_INFO_NOT_AVAILABLE } from '../../../../../properties';
import ConceptDetailsDialog from '../../../conceptdetails/ConceptDetailsDialog';
import { addThousandsSeparatorToNumber, isArrayEmpty, roundNumberUpToNextLevel, shortenLargeNumber, sortObjectArrayByAttribute } from '../../../util';
import FingerprintHitsInfo from '../../infos/FingerprintHitsInfo';
import { getNumberOfResults } from '../../utils/chemistryResults';
import ReactionResultComplete from './ReactionResultComplete';

const SOURCE_REPOSITORY_NAME = 'patents';
const REACTION_COMPOUND_IMAGE_HEIGHT = 400;//200; // 100
const REACTION_COMPOUND_IMAGE_WIDTH = 800;//400; // 150


class ReactionResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            repositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE,

            fetchingMatchingCompounds: false,
            // --- results --- //
            results: [],
            // --- additional reaction data --- //
            reactions: [],
            sourceDocuments: {},
            compoundImages: {},
            compoundData: {},
            // ---table, paging --- //
            totalRecords: 0,
            first: 0,
            rows: 10,
            sortOptions: [],

            selectedCompoundOcids: {},
            // --- compound info --- //
            displayCompoundInfo: false,
            compound: {},
        };

        // --- bind templates --- //
        this.itemTemplate = this.itemTemplate.bind(this);

        this.growl = createRef();
    }


    async componentDidMount() {
        //console.log('DID MOUNT');
        //console.log('componentDidMount');
        // --- get default repository --- //
        const response = await getRepositoryForName(SOURCE_REPOSITORY_NAME, false);
        const defRepository = checkResultAndGetPayload(response);
        const defaultRepository = defRepository ? defRepository : REPOSITORY_INFO_NOT_AVAILABLE;
        //console.log('-> defaultRepository: ', defaultRepository);
        this.setState({
            repositoryInfo: defaultRepository,
            fetchingReactionsData: true
        });


        let sortOptions = [];
        let reactions = [];

        if (this.props.reactions) {
            /*
            reactions = this.props.reactions;
            const pageReactions = [...this.props.reactions].splice(0, this.state.rows);
            console.log('pageReactions: ', pageReactions);
            this.enrichReactionData(pageReactions);
            */
            this.enrichReactionData(this.props.reactions);

            sortOptions = this.updateSortOptions(this.props.reactions);
        }

        this.setState({
            first: 0,
            sortOptions: sortOptions,
            reactions: reactions,
            totalRecords: reactions.length,
            fetchingReactionsData: false
        });
    }

    async componentDidUpdate(prevProps) {
        //console.log('DID UPDATE');

        //console.log('prevProps: ', prevProps);
        //console.log('this.props: ', this.props);

        if (this.props.reactions !== prevProps.reactions) {

            //let sortOptions = [];
            //let reactions = [];

            if (this.props.reactions) {
                /*
                reactions = this.props.reactions;

                const pageReactions = [...this.props.reactions].splice(this.state.first, this.state.rows);
                console.log('pageReactions: ', pageReactions);
                this.enrichReactionData(pageReactions);

                sortOptions = this.updateSortOptions(this.props.reactions);
                */
                this.enrichReactionData(this.props.reactions);
            }

            this.setState({
                //first: 0,
                //sortOptions: sortOptions,
                //reactions: reactions,
                //totalRecords: reactions.length
            });
        }
    }

    // ----------------------------------------------------------------------- //
    // --- table cell templates ---------------------------------------------- //
    // ----------------------------------------------------------------------- //

    updateSortOptions = (reactions) => {

        const sortOptions = [];

        if (reactions) {

            let hasPatentNo = false;
            let hasSection = false;
            let hasYield = false;
            let hasReactionName = false;
            let hasMolWeight = false;
            let hasNumOfHydrogenDonors = false;
            let hasNumOfHydrogenAcceptors = false;
            let hasNumOfRotatableBonds = false;
            let hasLogP = false;

            reactions.forEach(reaction => {
                if (reaction.document) { hasPatentNo = true; }
                if (reaction.transformName) { hasReactionName = true; }
                if (reaction.sourcesection) { hasSection = true; }
                //if (reaction.yield) { hasYield = true; }
                if (reaction.molWeight) { hasMolWeight = true }
                if (reaction.numOfHydrogenDonors) { hasNumOfHydrogenDonors = true }
                if (reaction.numOfHydrogenAcceptors) { hasNumOfHydrogenAcceptors = true }
                if (reaction.numOfRotatableBonds) { hasNumOfRotatableBonds = true }
                if (reaction.logP) { hasLogP = true }
            });

            if (hasPatentNo) {
                sortOptions.push(
                    { label: '↑ Patent No.', value: 'document' },
                    { label: '↓ Patent No. ', value: '!document' });
            }
            if (hasReactionName) {
                sortOptions.push(
                    { label: '↑ Reaction name', value: 'transformName' },
                    { label: '↓ Reaction name ', value: '!transformName' });
            }
            if (hasSection) {
                sortOptions.push(
                    { label: '↑ Section', value: 'sourcesection' },
                    { label: '↓ Section', value: '!sourcesection' });
            }
            if (hasYield) {
                sortOptions.push(
                    { label: '↑ Yield', value: 'yield' },
                    { label: '↓ Yield', value: '!yield' });
            }
            if (hasMolWeight) {
                sortOptions.push(
                    { label: '↑ Mol. weight', value: 'molWeight' },
                    { label: '↓ Mol. weight', value: '!molWeight' });
            }
            if (hasNumOfHydrogenDonors) {
                sortOptions.push(
                    { label: '↑ H-donors', value: 'numOfHydrogenDonors' },
                    { label: '↓ H-donors', value: '!numOfHydrogenDonors' });
            }
            if (hasNumOfHydrogenAcceptors) {
                sortOptions.push(
                    { label: '↑ H-acceptors', value: 'numOfHydrogenAcceptors' },
                    { label: '↓ H-acceptors', value: '!numOfHydrogenAcceptors' });
            }
            if (hasNumOfRotatableBonds) {
                sortOptions.push(
                    { label: '↑ Rot. bonds', value: 'numOfRotatableBonds' },
                    { label: '↓ Rot. bonds', value: '!numOfRotatableBonds' });
            }
            if (hasLogP) {
                sortOptions.push(
                    { label: '↑ logP', value: 'logP' },
                    { label: '↓ logP', value: '!logP' });
            }
        }

        return sortOptions;
    }

    showCompoundInfo = (ocid) => {

        this.setState({
            displayCompoundInfo: true,
            conceptOcid: ocid,
            activeIndex: 0
        })
    }


    onPaging = async (event) => {

        //console.log('onPaging: ', event);
        //console.log('this.state.reactions: ', this.state.reactions);
        /*
        if (!!this.state.reactions) {

            const pageReactions = [...this.state.reactions].splice(event.first, event.rows);
            //console.log('pageReactions: ', pageReactions);
            this.enrichReactionData(pageReactions);
        }
        */
        this.setState({
            first: event.first,
        });
    }

    /**
     * Retrieves additional data for all reactions, e.g. OCDocIDs for patents
     * and images of compounds that are part of the reactions. Stored in state.
     */
    enrichReactionData = async (reactions) => {

        //console.log('-------- enrich reactions: ', reactions);

        this.setState({
            fetchingCompoundData: true
        });

        //let documents = {};
        const smiles = {};
        const smilesProducts = {};
        let images = {};
        let reactionCompoundData = {};
        let mculeLinks = {};

        const documentIDs = {};

        if (reactions) {
            for (var reaction of reactions) {

                reaction.documentRepository = this.state.repositoryInfo.id;

                // --- retrieve source document IDs --- //
                reaction.documents?.forEach(docID => { documentIDs[docID] = true; });

                // --- collect all smiles --- //
                if (reaction.reactants) {
                    Object.values(reaction.reactants).forEach(smile => { smiles[smile] = true; });
                }
                if (reaction.reagents) {
                    Object.values(reaction.reagents).forEach(smile => { smiles[smile] = true; });
                }
                if (reaction.products) {
                    Object.values(reaction.products).forEach(smile => { smiles[smile] = true; });
                }
                /*if (reaction.productSmiles && APP_PROPERTIES.CHEMISTRY.INCLUDE_MCULE_LINKS) {
                    smilesProducts[reaction.productSmiles] = true;
                }*/
            }

            //console.log('Unique documents: ', Object.keys(documents).length);
            if (Object.keys(smiles).length > 0) {
                // --- request images for unique smiles --- //
                const request = createStructuresToImagesRequestV2(Object.keys(smiles), REACTION_COMPOUND_IMAGE_HEIGHT, REACTION_COMPOUND_IMAGE_WIDTH, 'svg', this.props.queryMol);
                const response = await convertStructuresToImagesV2(request);
                const result = checkResultAndGetPayload(response, this.props.growl);

                if (result && result.images) {
                    images = result.images;
                    reactionCompoundData = result.images;
                }
            }

            // --- fetch mcule links --- //
            if (!isArrayEmpty(Object.keys(smilesProducts))) {
                //console.log('Object.keys(smilesProducts): ', Object.keys(smilesProducts));
                const requestMcule = { smiles: Object.keys(smilesProducts) };
                const responseMcule = await fetchMculeData(requestMcule);
                //console.log('responseMcule: ', responseMcule);
                if (responseMcule?.status === 'SUCCESS' && responseMcule?.payload?.mculeLinks) {
                    mculeLinks = responseMcule.payload.mculeLinks;
                }
            }
            //console.log('images keys', (images ? Object.keys(images) : {}));
        }

        //console.log('reactionResults: ', reactionResults);
        //console.log('+++documents: ', documents);
        //console.log('images: ', images);
        //console.log('mculeLinks: ', mculeLinks);

        this.setState({
            fetchingCompoundData: false,
            //reactions: reactions,
            //sourceDocuments: documents,
            compoundImages: images,
            compoundData: reactionCompoundData,
            mculeLinks: mculeLinks
        });
    }

    onSortChange = async (event) => {

        let value = event.value;

        //console.log('[...this.state.reactions] 1: ', [...this.state.reactions]);

        if (value.indexOf('!') === 0) {
            this.setState({
                //sortOrder: -1,
                //sortField: value.substring(1, value.length),
                sortKey: value,
                first: 0
            }, () => {

                const reactionsSorted = sortObjectArrayByAttribute([...this.state.reactions], value.substring(1, value.length), false);
                //console.log('reactionsSorted 2: ', reactionsSorted);
                const pageReactions = [...reactionsSorted].splice(0, this.state.rows);
                //console.log('pageReactions: ', pageReactions);
                this.enrichReactionData(pageReactions);

                this.setState({
                    reactions: reactionsSorted
                });
            });
        }
        else {
            this.setState({
                //sortOrder: 1,
                //sortField: value,
                sortKey: value,
                first: 0
            }, () => {

                const reactionsSorted = sortObjectArrayByAttribute([...this.state.reactions], value, true);
                //console.log('reactionsSorted 3: ', reactionsSorted);
                const pageReactions = [...reactionsSorted].splice(0, this.state.rows);
                //console.log('pageReactions: ', pageReactions);
                this.enrichReactionData(pageReactions);

                this.setState({
                    reactions: reactionsSorted
                });
            });
        }
    }

    sendCompoundTo = (compound) => {
        //console.log('compound: ', compound);
        if (compound && compound.extendedData && compound.extendedData.smiles && compound.extendedData.smiles[0]) {
            this.props.onReplaceStructure(compound.extendedData.smiles[0], false);
            this.setState({
                displayCompoundInfo: false
            });
        }
        else {
            this.growl.current.show({
                sticky: false, severity: 'error',
                summary: 'Could not load structure',
                detail: `No structure found for this compound.`
            });
        }
    }

    renderListItem(data) {
        return (
            <div className="col-12">
                <ReactionResultComplete
                    repositoryInfo={this.state.repositoryInfo}
                    data={data}
                    compoundImages={this.state.compoundImages}
                    onReplaceStructure={this.props.onReplaceStructure}
                    showCompoundInfo={this.showCompoundInfo}
                />
            </div>
        );
    }

    renderGridItem(data) {
        return (
            <div className="col-6">
                <ReactionResultComplete
                    repositoryInfo={this.state.repositoryInfo}
                    data={data}
                    compoundImages={this.state.compoundImages}
                    onReplaceStructure={this.props.onReplaceStructure}
                    showCompoundInfo={this.showCompoundInfo}
                />
            </div>
        );
    }

    itemTemplate(product, layout) {
        if (!product) {
            return;
        }
        if (layout === 'list')
            return this.renderListItem(product);
        else if (layout === 'grid')
            return this.renderGridItem(product);
        else {
            return this.renderListItem(product);
        }
    }

    renderHeader() {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{ textAlign: 'left' }}>
                    <Dropdown options={this.sortOptions} value={this.state.sortKey} optionLabel="label" placeholder="Sort By Price" onChange={this.onSortChange} />
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value })} />
                </div>
            </div>
        );
    }

    render() {

        const { result, reactions, nextPageKey, start, pageSize, onLoadMore, loading } = this.props;

        let totalRecords = reactions?.length;
        totalRecords += !!nextPageKey ? pageSize : 0;

        const itemTemplateTest = (product, layout) => {
            if (!product) {
                return;
            }
            if (layout === 'list')
                return this.renderListItem(product);
            else if (layout === 'grid')
                return this.renderGridItem(product);
            else {
                return this.renderListItem(product);
            }
        }

        const { numberOfHits, isFPHitCount } = getNumberOfResults(result, start);
        let preText = isFPHitCount ? 'Found less than ' : 'Found ';
        let numOfHits = isFPHitCount
          ? shortenLargeNumber(roundNumberUpToNextLevel(numberOfHits))
          : addThousandsSeparatorToNumber(numberOfHits);
        let text =
          numberOfHits > 0 && result.numberTotalHitsInRequestedIndex
            ? `${preText}${numOfHits} ${numberOfHits === 1 ? 'match' : 'matches'} in this database with ${addThousandsSeparatorToNumber(result.numberTotalHitsInRequestedIndex)} total entries.`
            : 'No hits';

        //console.log('result: ', result);

        return (
            <>
                <LoadingOverlay
                    active={this.state.fetchingReactionsData || this.state.fetchingCompoundData}
                    spinner={true}
                    text='Loading images etc.' >
                    {this.state.fetchingReactionsData ?
                        <div></div>
                        :
                        totalRecords === 0 ?
                            <span>
                                Current search returned no hits.
                                <br />
                                Please note: It is possible that the maximum number of expected hits is greater than 0, but the atom-by-atom search returned no actual matches.
                            </span>
                            :
                            <>
                                <span className="flex align-items-center gap-3" style={{ marginLeft: 10 }}>
                                    {text}
                                    {isFPHitCount && <FingerprintHitsInfo />}
                                </span>
                                <DataView
                                    id='reactionSearchResults'
                                    className="chemSearchResults"
                                    value={reactions}
                                    layout={this.state.layout}
                                    //header={header}
                                    itemTemplate={itemTemplateTest}
                                    paginator={true}
                                    pageLinkSize={3}
                                    first={start}
                                    rows={pageSize}
                                    totalRecords={totalRecords}
                                    onPage={(e) => onLoadMore(e.rows, nextPageKey, e, e.first, e.page)}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink"
                                    alwaysShowPaginator={false}
                                    loading={loading}
                                    sortOrder={this.state.sortOrder}
                                    sortField={this.state.sortField}
                                //emptyMessage='Current search returned no hits. It is possible that the maximum number of expected hits is greater than 0, but the atom-by-atom search returned no actual matches.' 
                                />
                            </>
                    }
                </LoadingOverlay>

                <ConceptDetailsDialog
                    visible={this.state.displayCompoundInfo}
                    includeQuickSearchShortcut={true}
                    includeChemSearchShortcut={true}
                    includeCoocSearchShortcut={true}
                    ocid={this.state.conceptOcid}
                    onConceptClick={this.showCompoundInfo}
                    onHide={() => this.setState({ displayCompoundInfo: false })}
                />
            </>
        )
    }
}
export default ReactionResults