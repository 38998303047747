import { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from 'primereact/tabview';
import PatentFamilyMembers from '../docview/PatentFamilyMembers';

const PatFamilyMembersOverlay = ({
    patFamilyMembers,
    showFamilyMembersDialog,
    onCloseFamilyMembersDialog,
    selectedPatent,
    selectedFamilyDocuments,
    selectedFamilyDocumentsRemove,
    handleSelectionChange,
    onAddFamilyMembers,
    onRemoveFamilyMembers,
    allDocuments
}) => {

    const [activeIndex, setActiveIndex] = useState(0)

    const handleDocumentSelection = (e, rowData, remove) => {
        handleSelectionChange(e, rowData, selectedPatent, remove)
    }

    const activeIndexChange = (index) => {
        setActiveIndex(index)
    }

    let possibleFamilyMembers = JSON.parse(JSON.stringify(patFamilyMembers))
    possibleFamilyMembers?.forEach(family => {
        family.members = family.members?.filter(member => allDocuments?.filter(doc => doc.metadata?.hasOwnProperty('Pat_document-id') ? doc.metadata['Pat_document-id'][0] == member?.extId : null).length > 0)
    })

    const onSubmit = (selectedFamilyDocuments, selectedPatent, method) => {
        if (method === 'add') {
            onAddFamilyMembers(selectedFamilyDocuments, selectedPatent)
        } else {
            onRemoveFamilyMembers(selectedFamilyDocumentsRemove, selectedPatent)
        }
    }

    return (
        <Dialog focusOnShow={false}
            visible={showFamilyMembersDialog}
            className='styledDialog'
            style={{ 'width': "50vw" }}
            header={`Patent family members of ${selectedPatent}`}
            onHide={() => onCloseFamilyMembersDialog()}
            onShow={() => setActiveIndex(0)}>
            <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 10, paddingBottom: 15, paddingTop: 10, marginRight: 0 }}>
                <TabView activeIndex={activeIndex} onTabChange={(e) => activeIndexChange(e.index)}>
                    <TabPanel header='Add family members'>
                        <PatentFamilyMembers
                            familyMembers={patFamilyMembers}
                            showTitleOnly={true}
                            selectedPatent={selectedPatent}
                            handleDocumentSelection={handleDocumentSelection}
                            selectedDocuments={selectedFamilyDocuments}
                            allDocuments={allDocuments}
                            allowSelection={true}
                            remove={false}>
                        </PatentFamilyMembers>
                    </TabPanel>
                    <TabPanel header='Remove family members'>
                        <PatentFamilyMembers
                            familyMembers={possibleFamilyMembers}
                            showTitleOnly={true}
                            selectedPatent={selectedPatent}
                            handleDocumentSelection={handleDocumentSelection}
                            selectedDocumentsRemove={selectedFamilyDocumentsRemove}
                            allDocuments={allDocuments}
                            allowSelection={true}
                            remove={true}>
                        </PatentFamilyMembers>
                    </TabPanel>
                </TabView>
            </div>
            <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                <Button label={activeIndex === 0 ? 'Add' : 'Remove'}
                    className='p-button-sm primaryButton'
                    disabled={activeIndex === 0 && selectedFamilyDocuments.length > 0 || activeIndex === 1 && selectedFamilyDocumentsRemove.length > 0 ? false : true}
                    onClick={() => onSubmit(selectedFamilyDocuments, selectedPatent, activeIndex === 0 ? 'add' : 'remove')}
                    style={{ float: 'right' }}
                />
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={() => onCloseFamilyMembersDialog()}
                    style={{ float: 'right', marginRight: 5 }}
                />
            </div>
        </Dialog>
    );
}
export default PatFamilyMembersOverlay