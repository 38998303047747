import React from 'react';
import styles from "./SearchControls.module.scss";
import Link from "../Link/Link";
import {Button} from "primereact/button";

const SearchControls = ({onClearAll, onSearchClick, searchIsDisabled, saveAndAlert}) => {
    return (
        <div className={styles.searchControls}>
            <div className={styles.controlsWrapper}>
                <Link title='Clear all' onClick={onClearAll}/>

                {/*{hasUserRole('ROLE_WATCHLISTS') &&*/}
                {/*<Link title='Save & Alert' onCLick={saveAndAlert}/>*/}
                {/*}*/}

                <Button label="Search"
                        style={{verticalAlign: "50%"}}
                        className='p-button-sm primaryButton'
                        onClick={onSearchClick}
                        disabled={searchIsDisabled}
                />
            </div>
        </div>
    );
};

export default SearchControls;
