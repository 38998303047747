import { toInteger } from "lodash";
import { addThousandsSeparatorToNumber } from "../../webapi/util";

const CustomPaginatorLeftSide = ({ first, contentLength, rows, style }) => {
    //console.log(first)
    //console.log(rows)
    //console.log(contentLength)
    return (
        <div style={style || { userSelect: 'none', marginLeft: -10, marginTop: -1 }}>
            Page {addThousandsSeparatorToNumber(toInteger(first / rows) + 1)} of {addThousandsSeparatorToNumber(Math.ceil(contentLength / rows))}
        </div>
    );
};

export default CustomPaginatorLeftSide;