/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component, createRef } from 'react';
import SeparatorPoint from '../../../general/SeparatorPoint';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Toast } from 'primereact/toast';

class ReactionExtraInfo extends Component {

    constructor(props) {
        super(props);

        this.growl = createRef();
    }

    render() {

        const { extraInfo, addSeparator } = this.props;

        const displayLabel = <span className='secondaryInfo'>{extraInfo.label}</span>;
        return <>
            <Toast ref={this.growl} />

            <span title={extraInfo.tooltip}>
                {extraInfo.copyOnClick ?
                    <>
                        <CopyToClipboard
                            text={extraInfo.value}>
                            <a title={`Copy ${extraInfo.label} to clipboard`}
                                className='secondaryLink secondaryInfo'
                                onClick={(e) => {
                                    this.growl.current.show({
                                        sticky: false, severity: 'success',
                                        summary: 'Success',
                                        detail: `Copied to clipboard.`
                                    });
                                }}>{displayLabel}</a>
                        </CopyToClipboard>
                        {/*<a className='secondaryLink'>{displayLabel}</a> */}
                    </>
                    :
                    extraInfo.onClickFunction ?
                        <a className='secondaryLink' onClick={() => extraInfo.onClickFunction(extraInfo.value)}>{displayLabel}</a>
                        :
                        <span className='secondaryInfo'>{displayLabel}</span>
                }
                {addSeparator ? <SeparatorPoint /> : null}
            </span>
        </>
    }
}
export default ReactionExtraInfo