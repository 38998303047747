/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import CorrelationsInputField from '../coocs/CorrelationsInputField';
import { isArrayEmpty } from '../util';
import { Dropdown } from 'primereact/dropdown';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';
import { SEMANTIC_EXPORTER_PRESETS, SEMANTIC_EXPORTER_FORMATS, SEMANTIC_EXPORTER_TARGETS, APP_PROPERTIES } from '../../../properties/index';
import { EXPORTER_TARGET_ID_FILE, EXPORTER_TARGET_ID_BIGQUERY } from '../../../properties';


class Extractor extends Component {
    constructor(props) {
        super(props);

        let semanticExporterTargets = SEMANTIC_EXPORTER_TARGETS;
        if (!APP_PROPERTIES.CHEMISTRY.INCLUDE_BQ_COMPOUND_EXPORT) {
            semanticExporterTargets = SEMANTIC_EXPORTER_TARGETS.filter(target => target.id !== EXPORTER_TARGET_ID_BIGQUERY);
        }

        this.state = {
            queryTerms: [],
            semanticExporterTargets: semanticExporterTargets
        }
    }


    componentDidMount() {

        //console.log('componentDidMount this.props.userData: ', this.props.userData);

        let restrictions;
        if (this.props.userData && this.props.userData.userDetails.department.selectedDomains) {
            const domIDs = {};
            this.props.userData.userDetails.department.selectedDomains.forEach(dom => {
                if (dom.active) {
                    domIDs[dom.name] = true;
                }
            })
            restrictions = { ...SEMANTIC_EXPORTER_PRESETS };
            restrictions.subtrees = SEMANTIC_EXPORTER_PRESETS.subtrees.filter(subtree => {
                return (!isArrayEmpty(subtree.domains) && (domIDs[subtree.domains[0]]) || subtree.domains[0] === '_ALL');
            });
        }

        this.setState({
            restrictions: restrictions
        });

        // --- default input --- //
        let defaultInput = [];
        let showSingleInputMessage = false;
        if (restrictions && restrictions.subtrees) {
            // --- get default query terms --- //
            defaultInput = [restrictions.subtrees[0]];
            // --- check if there is a query term entry that can only be used without other terms --- //
            if (this.props.allowCustomInput) {
                for (var subtree of restrictions.subtrees) {
                    if (subtree.isSingleInput) {
                        showSingleInputMessage = true;
                        break;
                    }
                }
            }
        }

        this.updateDefaultData(defaultInput, SEMANTIC_EXPORTER_FORMATS, this.state.semanticExporterTargets, showSingleInputMessage);
    }


    updateDefaultData = (queryTerms, exportFormats, exportTargets, showSingleInputMessage) => {

        /*console.log('queryTerms: ', queryTerms);
        if (!isArrayEmpty(queryTerms)) {
            console.log('queryTerms[0]: ', queryTerms[0]);
            console.log('queryTerms[0].specialCase: ', queryTerms[0].specialCase);
        }
        console.log('exportFormats: ', exportFormats);
        */
        // --- default export target --- //
        const selectedTarget = this.state.selectedTarget ? this.state.selectedTarget : !isArrayEmpty(exportTargets) ? exportTargets[0] : null;

        // --- filter export file formats based on default input --- //
        let formats = null;
        let selectedFormat;
        if (//!isArrayEmpty(queryTerms) && 
            !isArrayEmpty(exportFormats)) {
            formats = exportFormats.filter(format => !format.specialCase || queryTerms[0].specialCase === format.specialCase);
            selectedFormat = formats.filter(format => this.state.selectedFormat && this.state.selectedFormat.id === format.id);
        }

        this.setState({
            queryTerms: queryTerms,
            formats: formats,
            selectedFormat: !isArrayEmpty(selectedFormat) ? selectedFormat[0] : !isArrayEmpty(formats) ? formats[0] : null,
            selectedTarget: selectedTarget,
            showSingleInputMessage: showSingleInputMessage
        })
    }

    componentDidUpdate(prevProps) {

        //console.log('componentDidUpdate this.props.userData: ', this.props.userData);

        let restrictions = this.state.restrictions;
        if (prevProps.userData !== this.props.userData && this.props.userData &&
            this.props.userData.userDetails.department.selectedDomains) {
            const domIDs = {};
            this.props.userData.userDetails.department.selectedDomains.forEach(dom => {
                if (dom.active) {
                    domIDs[dom.name] = true;
                }
            })
            restrictions = { ...SEMANTIC_EXPORTER_PRESETS };
            restrictions.subtrees = SEMANTIC_EXPORTER_PRESETS.subtrees.filter(subtree => {
                return (!isArrayEmpty(subtree.domains) && (domIDs[subtree.domains[0]] || subtree.domains[0] === '_ALL'));
            });
        }

        if (restrictions !== this.state.restrictions) {

            let defaultInput = this.state.queryTerms;
            let showSingleInputMessage = this.state.showSingleInputMessage;

            // --- default input --- //
            if (restrictions && restrictions.subtrees) {
                // --- get default query terms --- //
                defaultInput = [restrictions.subtrees[0]];
                // --- check if there is a query term entry that can only be used without other terms --- //
                if (this.props.allowCustomInput) {
                    for (var subtree of restrictions.subtrees) {
                        if (subtree.isSingleInput) {
                            showSingleInputMessage = true;
                            break;
                        }
                    }
                }
            }

            this.setState({
                restrictions: restrictions
            });

            this.updateDefaultData(defaultInput, SEMANTIC_EXPORTER_FORMATS, this.state.semanticExporterTargets, showSingleInputMessage);
        }
    }


    handleInputChange = (value) => {
        //console.log('handleInputChangeLeft: ', value);

        this.updateDefaultData(value, SEMANTIC_EXPORTER_FORMATS, this.state.semanticExporterTargets, this.state.showSingleInputMessage);

        this.setState({
            queryTerms: value
        });
    }



    handleExport = () => {

        //this.props.onExport(this.state.selectedCategory, this.state.selectedFormat);
        this.props.onExport(this.state.queryTerms, this.state.selectedTarget, this.state.selectedFormat);

        /*
        this.setState({
            displayExportDialog: true
        })
        */
    }


    render() {

        return (
            <div className='grid' style={{ marginRight: -12, borderBottom: '1px solid #d6d6d6' }}>
                <div className='col-12' style={{ paddingRight: 0, paddingTop: 0 }}>

                    {this.props.allowCustomInput ?
                        <CorrelationsInputField
                            //placeholder={"Type term"}
                            placeholder={this.state.queryTerms.length === 0 ? 'Empty input exports all semantic concepts.' : null}
                            queryTerms={this.state.queryTerms}
                            //domains={this.props.domainsEntity1}
                            domainLabelsMap={this.props.domainLabelsMap}
                            availableDomains={this.props.availableDomains}
                            restrictions={this.state.restrictions}
                            handleInputChange={this.handleInputChange}
                            appendSuggestionsTo={this.props.appendSuggestionsTo}
                            domainColors={this.props.domainColors}
                            addPresetsToInput={true}
                        //disableOnPreset={true}
                        />
                        :
                        <>
                            {/*<hr style={{ marginTop: 0 }} />*/}
                            {this.state.restrictions ? this.state.restrictions.subtrees.map((subtree) => {
                                return (
                                    <div style={{ display: 'inline-block', marginBottom: 10, marginTop: 0, marginRight: 10, top: 0 }}
                                        key={subtree.label}
                                        className="p-field-radiobutton inline whiteSpaceNoWrap">
                                        <RadioButton
                                            inputId={subtree.label}
                                            name="subtree"
                                            value={subtree}
                                            onChange={(e) => this.handleInputChange([e.value])}
                                            checked={!isArrayEmpty(this.state.queryTerms) && this.state.queryTerms[0].label === subtree.label}
                                        />
                                        <label style={{ color: 'black', marginRight: 10 }} htmlFor={subtree.label}>{subtree.label}</label>
                                    </div>
                                )
                            }) : 'No presets available.'}
                            {/*<hr style={{ marginBottom: 0 }} />*/}
                        </>
                    }
                    {/*
                                <TabView className="tabview-custom" activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                                    <TabPanel header="Quick select">
                                        <div className='grid' style={{ marginTop: 15 }}>
                                            {selectedCategories ? selectedCategories : 'No categories available.'}
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Search">
                                    </TabPanel>
                                </TabView>
                                */}
                </div>
                <div className='col-12' style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0, textAlign: 'right' }}>
                    {this.state.showSingleInputMessage ? '* cannot be used with additional search terms' : null}
                </div>
                <div className='col-12' style={{ paddingTop: 0, paddingBottom: 0, marginTop: -5 }}>
                    <label className="valignMiddle" htmlFor="exportTo" style={{ marginRight: 10 }}>Export to:</label>
                    <Dropdown
                        id='exportTo'
                        value={this.state.selectedTarget}
                        options={this.state.semanticExporterTargets}
                        onChange={e => this.setState({ selectedTarget: e.value })}
                        //autoWidth={false}
                        //showClear={true}
                        placeholder="Select target"
                        appendTo={document.body}
                    //style={{ width: '100%' }}
                    />
                    {!isArrayEmpty(this.state.formats) && this.state.selectedTarget && this.state.selectedTarget.type === EXPORTER_TARGET_ID_FILE ?
                        <>
                            <label className="valignMiddle" style={{ color: 'black', marginRight: 10, marginLeft: 30 }}>Export format:</label>
                            <Dropdown
                                id='format'
                                value={this.state.selectedFormat}
                                options={this.state.formats}
                                onChange={e => this.setState({ selectedFormat: e.value })}
                                //showClear={true}
                                placeholder="Select file format"
                                appendTo={document.body}
                            //style={{ width: '100%' }}
                            />
                        </> : null
                    }
                    {/*this.props.formats ? this.props.formats.map((format) => {
                                        return (
                                            <div style={{ display: 'inline' }} key={format.key} className="p-field-radiobutton">
                                                <RadioButton
                                                    inputId={format.key}
                                                    name="format"
                                                    value={format}
                                                    onChange={(e) => this.setState({ selectedFormat: e.value })}
                                                    checked={this.state.selectedFormat && this.state.selectedFormat.key === format.key}
                                                />
                                                <label style={{ color: 'black', marginRight: 10 }} htmlFor={format.key}>{format.name}</label>
                                            </div>
                                        )
                                    }) : null
                         */}
                </div>
                <div className='col-12 textAlignRight' style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
                    <Button style={{ marginLeft: 30, marginTop: 5, marginBottom: -38 }}
                        className='p-button-sm primaryButton'
                        label="Export"
                        //tooltip='Export semantic concepts'
                        onClick={this.handleExport}
                    //disabled={this.state.selectedCollections !== null ? false : true} 
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.data,
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
    availableDomains: state.webAPI.availableDomains,
    domainColors: state.user.data.userDetails.department.selectedDomains
})

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Extractor))