export * from './UserActions'
export * from './WebApiActions'
export * from './ManagerActions'
export * from './TutorialActions'
export * from '../common/filters/actions/FilterActions'
export * from '../features/queryBuilder/actions'
export * from './NotificationsAdtions'

// Login Actions
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
export const IS_NOT_AUTHENTICATED = 'IS_NOT_AUTHENTICATED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGIN_EMPTY = 'LOGIN_EMPTY'

// changing user details
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const UPDATE_USER_APPS = 'UPDATE_USER_APPS'
export const UPDATE_USER_REPOSITORIES = 'UPDATE_USER_REPOSITORIES'
export const UPDATE_ADMIN_APPS = 'UPDATE_ADMIN_APPS'
// admin actions
export const ADD_ALL_USERS = 'ADD_ALL_USERS'
export const ADD_ALL_COMPANIES = 'ADD_ALL_COMPANIES'
export const ADD_ALL_REPOSITORIES = 'ADD_ALL_REPOSITORIES'
export const ADD_ADMIN_ROLES = 'ADD_ADMIN_ROLES'

// management actions
export const CHANGE_MANAGER_TAB = "CHANGE_MANAGER_TAB"
export const GET_CARTRIDGES = 'GET_CARTRIDGES'
export const ADD_ALL_CARTRIDGES = 'ADD_ALL_CARTRIDGES'
export const INSTALL_AVAILABLE_CARTRIDGE = 'INSTALL_AVAILABLE_CARTRIDGE'
export const SET_CARTRIDGE_UPLOADING = 'SET_CARTRIDGE_UPLOADING'
export const SET_CARTRIDGE_DELETING = 'SET_CARTRIDGE_DELETING';
export const SET_CARTRIDGE_INSTALL_AVAILABLE = 'SET_CARTRIDGE_INSTALL_AVAILABLE';
export const SET_CARTRIDGE_DOMAIN_ADDING = 'SET_CARTRIDGE_DOMAIN_ADDING';
export const SET_CARTRIDGE_DOMAIN_EDITING = 'SET_CARTRIDGE_DOMAIN_EDITING';
export const ADD_ALL_REPOSITORIES_MANAGER = 'ADD_ALL_REPOSITORIES_MANAGER'
export const SET_REPOSITORY_DELETING = 'SET_REPOSITORY_DELETING';
export const DISPLAY_ANNOTATION_RESULTS = 'DISPLAY_ANNOTATION_RESULTS'
export const SET_ANNOTATION_SUBMISSION = 'SET_ANNOTATION_SUBMISSION'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const UPDATE_JOBS = 'UPDATE_JOBS'
export const UPDATE_ACTIVE_JOBS = 'UPDATE_ACTIVE_JOBS'
export const UPDATE_JOB_TABLE_DEFAUTLS = 'UPDATE_JOB_TABLE_DEFAUTLS'
export const ADD_JOB_ERRORS = 'ADD_JOB_ERRORS'
export const JOBS_LOADING = 'JOBS_LOADING'
export const LOCK_FRONTEND = 'LOCK_FRONTEND'

// Biomarker actions
export const BIOMARKER_SET_SEARCH_RESULTS = 'BIOMARKER_SET_SEARCH_RESULTS';
export const BIOMARKER_SET_QUERY_TERMS = 'BIOMARKER_SET_QUERY_TERMS';
export const BIOMARKER_TOGGLE_IS_FETCHING = 'BIOMARKER_TOGGLE_IS_FETCHING';
export const BIOMARKER_SET_REPOS_WITH_BIOMARKER_SEARCH_FEATURE = 'BIOMARKER_SET_REPOS_WITH_BIOMARKER_SEARCH_FEATURE';
export const BIOMARKER_SET_SELECTED_REPOS_FOR_SEARCH = 'BIOMARKER_SET_SELECTED_REPOS_FOR_SEARCH';
export const BIOMARKER_SET_METADATA = 'BIOMARKER_SET_METADATA';
export const BIOMARKER_SET_POPUP_EXPANDED_KEYS = 'BIOMARKER_SET_EXPANDED_KEYS';
export const BIOMARKER_SET_POPUP_TREE_NAME = 'BIOMARKER_SET_POPUP_TREE_NAME';
export const BIOMARKER_SET_LEFT_ASIDE_EXPANDED_KEYS = 'BIOMARKER_SET_LEFT_ASIDE_EXPANDED_KEYS';
export const BIOMARKER_SET_CHECKED_RESULTS = 'BIOMARKER_SET_CHECKED_RESULTS';
export const BIOMARKER_SET_ROLE_MAPPING = 'BIOMARKER_SET_ROLE_MAPPING';
export const BIOMARKER_SET_NODES = 'BIOMARKER_SET_NODES';
export const BIOMARKER_SET_POPUP_NODES = 'BIOMARKER_SET_POPUP_NODES';
export const BIOMARKER_SET_HEADERS_STRUCTURE_SKELETON = 'BIOMARKER_SET_HEADERS_STRUCTURE_SKELETON';
export const BIOMARKER_SET_SUGGESTIONS = 'BIOMARKER_SET_SUGGESTIONS';
export const BIOMARKER_SET_INPUT_TEXT = 'BIOMARKER_SET_INPUT_TEXT';
export const BIOMARKER_SET_SORT_FIELD = 'BIOMARKER_SET_SORT_FIELD';
export const BIOMARKER_SET_SORT_DIRECTION = 'BIOMARKER_SET_SORT_DIRECTION';
export const BIOMARKER_SET_FIRST_RESULT_INDEX = 'BIOMARKER_SET_FIRST_RESULT_INDEX';
export const BIOMARKER_SET_RESULT_COUNT = 'BIOMARKER_SET_RESULT_COUNT';
export const BIOMARKER_RESET_STATE = 'BIOMARKER_RESET_STATE';
export const BIOMARKER_SET_IS_FIRST_RENDER = 'BIOMARKER_SET_IS_FIRST_RENDER';

// Bioactivities actions
export const BIOACTIVITIES_SET_QUERY_TERMS = 'BIOACTIVITIES_SET_QUERY_TERMS';
export const BIOACTIVITIES_SET_HEADERS_STRUCTURE_SKELETON = 'BIOACTIVITIES_SET_HEADERS_STRUCTURE_SKELETON';
export const BIOACTIVITIES_SET_LEFT_ASIDE_EXPANDED_KEYS = 'BIOACTIVITIES_SET_LEFT_ASIDE_EXPANDED_KEYS';
export const BIOACTIVITIES_SET_SUGGESTIONS = 'BIOACTIVITIES_SET_SUGGESTIONS';
export const BIOACTIVITIES_SET_INPUT_TEXT = 'BIOACTIVITIES_SET_INPUT_TEXT';
export const BIOACTIVITIES_SET_REPOS_WITH_BIOACTIVITIES_SEARCH_FEATURE = 'BIOACTIVITIES_SET_REPOS_WITH_BIOACTIVITIES_SEARCH_FEATURE';
export const BIOACTIVITIES_SET_SELECTED_REPOS_FOR_SEARCH = 'BIOACTIVITIES_SET_SELECTED_REPOS_FOR_SEARCH';
export const BIOACTIVITIES_SET_SORT_FIELD = 'BIOACTIVITIES_SET_SORT_FIELD';
export const BIOACTIVITIES_SET_SORT_DIRECTION = 'BIOACTIVITIES_SET_SORT_DIRECTION';
export const BIOACTIVITIES_RESET_STATE = 'BIOACTIVITIES_RESET_STATE';
export const BIOACTIVITIES_SET_IS_FIRST_RENDER = 'BIOACTIVITIES_SET_IS_FIRST_RENDER';
export const BIOACTIVITIES_SET_ROLE_MAPPING = 'BIOACTIVITIES_SET_ROLE_MAPPING';
export const BIOACTIVITIES_SET_NODES = 'BIOACTIVITIES_SET_NODES';
export const BIOACTIVITIES_SET_FIRST_RESULT_INDEX = 'BIOACTIVITIES_SET_FIRST_RESULT_INDEX';
export const BIOACTIVITIES_SET_RESULT_COUNT = 'BIOACTIVITIES_SET_RESULT_COUNT';
export const BIOACTIVITIES_TOGGLE_IS_FETCHING = 'BIOACTIVITIES_TOGGLE_IS_FETCHING';
export const BIOACTIVITIES_SET_METADATA = 'BIOACTIVITIES_SET_METADATA';
export const BIOACTIVITIES_SET_SEARCH_RESULTS = 'BIOACTIVITIES_SET_SEARCH_RESULTS';
export const BIOACTIVITIES_SET_TARGET_OCID = 'BIOACTIVITIES_SET_TARGET_OCID';
export const BIOACTIVITIES_SET_SHOW_CONCEPT_POPUP = 'BIOACTIVITIES_SET_SHOW_CONCEPT_POPUP';
export const BIOACTIVITIES_SET_CHECKED_RESULTS = 'BIOACTIVITIES_SET_CHECKED_RESULTS';

export const YEAR_STATISTICS_SHOW_MODAL = 'YEAR_STATISTICS_SHOW_MODAL';
export const YEAR_STATISTICS_SET_SEARCH_PARAMETERS = 'YEAR_STATISTICS_SET_SEARCH_PARAMETERS';
export const YEAR_STATISTICS_SET_CHART_DATA = 'YEAR_STATISTICS_SET_CHART_DATA';
export const YEAR_STATISTICS_SET_SELECTED_SEARCHES = 'YEAR_STATISTICS_SET_SELECTED_SEARCHES';
export const YEAR_STATISTICS_SET_REPOSITORIES = 'YEAR_STATISTICS_SET_REPOSITORIES';
export const YEAR_STATISTICS_SET_CHART_TYPE = 'YEAR_STATISTICS_SET_CHART_TYPE';
export const YEAR_STATISTICS_SET_VALUE_TYPE = 'YEAR_STATISTICS_SET_VALUE_TYPE';
export const YEAR_STATISTICS_SET_CHART_TITLE = 'YEAR_STATISTICS_SET_CHART_TITLE';
export const YEAR_STATISTICS_SET_PERIOD = 'YEAR_STATISTICS_SET_PERIOD';
export const YEAR_STATISTICS_SET_DATA_DATA_IS_FETCHING = 'YEAR_STATISTICS_SET_DATA_DATA_IS_FETCHING';
export const YEAR_STATISTICS_SET_MODAL_VISIBLE = 'YEAR_STATISTICS_SET_MODAL_VISIBLE';
export const YEAR_STATISTICS_SET_MODAL_MESSAGE = 'YEAR_STATISTICS_SET_MODAL_MESSAGE';
export const YEAR_STATISTICS_RESET = 'YEAR_STATISTICS_RESET';
export const YEAR_STATISTICS_CLEAR_CHART = 'YEAR_STATISTICS_CLEAR_CHART';
export const YEAR_STATISTICS_SET_START_DATE = 'YEAR_STATISTICS_SET_START_DATE';
export const YEAR_STATISTICS_SET_END_DATE = 'YEAR_STATISTICS_SET_END_DATE';
export const YEAR_STATISTICS_SET_UPDATE = 'YEAR_STATISTICS_SET_UPDATE';
export const YEAR_STATISTICS_SET_TIME_RANGE = 'YEAR_STATISTICS_SET_TIME_RANGE';
// export const YEAR_STATISTICS_SET_ERROR_MESSAGE = 'YEAR_STATISTICS_SET_ERROR_MESSAGE';
// export const YEAR_STATISTICS_SET_ERROR_MESSAGE_VISIBLE = 'YEAR_STATISTICS_SET_ERROR_MESSAGE_VISIBLE';
export const YEAR_STATISTICS_SET_IS_OUT_OF_LIMIT = 'YEAR_STATISTICS_SET_IS_OUT_OF_LIMIT';


// growl
export const GROWL_SUCCESS = "success"
export const GROWL_INFO = "info"
export const GROWL_WARN = "warn"
export const GROWL_ERROR = "error"

// tutorials
export const STAR_TUTORIAL = "STAR_TUTORIAL"

// WebApi actions
export const FETCH_EVERY_RELATION = 'FETCH_EVERY_RELATION'
export const ADD_ALL_COMPOUND_HITS = 'ADD_ALL_COMPOUND_HITS'
export const FETCH_TERM_SUGGESTIONS = 'FETCH_TERM_SUGGESTIONS'
export const FETCH_RELATIONS = 'FETCH_RELATIONS'
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS'
export const FETCH_RELATIONS_DOCUMENTS = 'FETCH_RELATIONS_DOCUMENTS'
export const FETCH_SIMILARDOCUMENTS = 'FETCH_SIMILARDOCUMENTS'
export const ADD_PUBLICATIONLIST_XLSX = 'ADD_PUBLICATIONLIST_XLSX'
export const ADD_PUBLICATIONLIST_RIS = 'ADD_PUBLICATIONLIST_RIS'
export const ADD_PUBLICATIONLIST_SIMILAR_XLSX = 'ADD_PUBLICATIONLIST_SIMILAR_XLSX'
export const ADD_PUBLICATIONS = 'ADD_PUBLICATIONS'
export const ADD_RELATIONLIST_XLSX = 'ADD_RELATIONLIST_XLSX'
export const FETCH_IPC_CLASSES = 'FETCH_IPC_CLASSES'
export const ADD_NEW_QUERY = 'ADD_NEW_QUERY'
export const EDIT_QUERY = 'EDIT_QUERY'
export const REMOVE_QUERY = 'REMOVE_QUERY'
export const FETCH_QUERIES = 'FETCH_QUERIES'
export const FETCH_QUERY_STRING = 'FETCH_QUERY_STRING'
export const ADD_ALL_STATISTICS = 'ADD_ALL_STATISTICS'
export const FETCH_FORM_QUERIES = 'FETCH_FORM_QUERIES'
export const FETCH_PROPERTY_TREE = 'FETCH_PROPERTY_TREE'
export const FETCH_PDF = 'FETCH_PDF'
export const FETCH_SORTFIELDS = 'FETCH_SORTFIELDS'
export const START_FETCHING_COMPOUNDS = 'START_FETCHING_COMPOUNDS'
export const DONE_FETCHING_COMPOUNDS = 'DONE_FETCHING_COMPOUNDS'
// --- available domains --- //
export const AVAILABLE_DOMAINS = 'AVAILABLE_DOMAINS'
export const START_FETCHING_AVAILABLE_DOMAINS = 'START_FETCHING_AVAILABLE_DOMAINS'
export const DONE_FETCHING_AVAILABLE_DOMAINS = 'DONE_FETCHING_AVAILABLE_DOMAINS'

export const AVAILABLE_REPOSITORIES = 'AVAILABLE_REPOSITORIES'
export const START_FETCHING_AVAILABLE_REPOSITORIES = 'START_FETCHING_AVAILABLE_REPOSITORIES'
export const DONE_FETCHING_AVAILABLE_REPOSITORIES = 'DONE_FETCHING_AVAILABLE_REPOSITORIES'
// --- repository overview --- //
export const REPOSITORY_OVERVIEW = 'REPOSITORY_OVERVIEW'
export const START_FETCHING_REPOSITORY_OVERVIEW = 'START_FETCHING_REPOSITORY_OVERVIEW'
export const DONE_FETCHING_REPOSITORY_OVERVIEW = 'DONE_FETCHING_REPOSITORY_OVERVIEW'
// --- additional document data (rating, bookmark) --- //
export const ADDITIONAL_DOCUMENT_DATA = 'ADDITIONAL_DOCUMENT_DATA'
export const START_FETCHING_ADDITIONAL_DOCUMENT_DATA = 'START_FETCHING_ADDITIONAL_DOCUMENT_DATA'
export const DONE_FETCHING_ADDITIONAL_DOCUMENT_DATA = 'DONE_FETCHING_ADDITIONAL_DOCUMENT_DATA'
export const START_UPDATING_DOCUMENT_RATING = 'START_UPDATING_DOCUMENT_RATING'
export const DONE_UPDATING_DOCUMENT_RATING = 'DONE_UPDATING_DOCUMENT_RATING'
export const START_UPDATING_DOCUMENT_READ_STATUS = 'START_UPDATING_DOCUMENT_READ_STATUS'
export const DONE_UPDATING_DOCUMENT_READ_STATUS = 'DONE_UPDATING_DOCUMENT_READ_STATUS'
export const START_UPDATING_DOCUMENT_COLLECTIONS = 'START_UPDATING_DOCUMENT_COLLECTIONS'
export const DONE_UPDATING_DOCUMENT_COLLECTIONS = 'DONE_UPDATING_DOCUMENT_COLLECTIONS'


// --- general fetching actions (beware: global actions) --- //
export const START_FETCHING = 'START_FETCHING'
export const DONE_FETCHING = 'DONE_FETCHING'

export const startFetching = () => ({
    type: START_FETCHING,
    fetching: true
})

export const doneFetching = () => ({
    type: DONE_FETCHING,
    fetching: false
})

export const startFetchingCompounds = () => ({
    type: START_FETCHING_COMPOUNDS,
    fetchingCompounds: true
})

export const doneFetchingCompounds = () => ({
    type: DONE_FETCHING_COMPOUNDS,
    fetchingCompounds: false
})

export const startFetchingAvailableDomains = () => ({
    type: START_FETCHING_AVAILABLE_DOMAINS,
    fetchingAvailableDomains: true
})

export const doneFetchingAvailableDomains = () => ({
    type: DONE_FETCHING_AVAILABLE_DOMAINS,
    fetchingAvailableDomains: false
})

export const startFetchingAvailableRepositories = () => ({
    type: START_FETCHING_AVAILABLE_REPOSITORIES,
    fetchingAvailableRepositories: true
})

export const doneFetchingAvailableRepositories = () => ({
    type: DONE_FETCHING_AVAILABLE_REPOSITORIES,
    fetchingAvailableRepositories: false
})

export const startFetchingRepositoryOverview = () => ({
    type: START_FETCHING_REPOSITORY_OVERVIEW,
    fetchingRepositoryOverview: true
})

export const doneFetchingRepositoryOverview = () => ({
    type: DONE_FETCHING_REPOSITORY_OVERVIEW,
    fetchingRepositoryOverview: false
})
// --- additional document data (rating, bookmark) --- //
export const startFetchingAdditionalDocumentData = () => ({
    type: START_FETCHING_ADDITIONAL_DOCUMENT_DATA,
    fetchingAdditionalDocumentData: true
})
export const doneFetchingAdditionalDocumentData = () => ({
    type: DONE_FETCHING_ADDITIONAL_DOCUMENT_DATA,
    fetchingAdditionalDocumentData: false
})
export const startUpdatingDocumentRating = () => ({
    type: START_UPDATING_DOCUMENT_RATING,
    updatingDocumentRating: true
})
export const doneUpdatingDocumentRating = () => ({
    type: DONE_UPDATING_DOCUMENT_RATING,
    updatingDocumentRating: false
})
export const startUpdatingDocumentReadStatus = () => ({
    type: START_UPDATING_DOCUMENT_READ_STATUS,
    updatingDocumentReadStatus: true
})
export const doneUpdatingDocumentReadStatus = () => ({
    type: DONE_UPDATING_DOCUMENT_READ_STATUS,
    updatingDocumentReadStatus: false
})
export const startUpdatingDocumentCollections = () => ({
    type: START_UPDATING_DOCUMENT_COLLECTIONS,
    updatingDocumentCollections: true
})
export const doneUpdatingDocumentCollections = () => ({
    type: DONE_UPDATING_DOCUMENT_COLLECTIONS,
    updatingDocumentCollections: false
})

// --- fetching token --- //
export const START_FETCHING_TOKEN = 'START_FETCHING_TOKEN'
export const DONE_FETCHING_TOKEN = 'DONE_FETCHING_TOKEN'
export const MINUTES_LEFT_IN_SESSION = 'MINUTES_LEFT_IN_SESSION'

export const startFetchingToken = () => ({
    type: START_FETCHING_TOKEN,
    fetchingToken: true
})

export const doneFetchingToken = () => ({
    type: DONE_FETCHING_TOKEN,
    fetchingToken: false
})


// --- fetching server status --- //
export const START_FETCHING_SERVER_STATUS = 'START_FETCHING_SERVER_STATUS'
export const DONE_FETCHING_SERVER_STATUS = 'DONE_FETCHING_SERVER_STATUS'
export const SERVER_STATUS = 'SERVER_STATUS'

export const startFetchingServerStatus = () => ({
    type: START_FETCHING_SERVER_STATUS,
    fetchingServerStatus: true
})

export const doneFetchingServerStatus = () => ({
    type: DONE_FETCHING_SERVER_STATUS,
    fetchingServerStatus: false
})


// --- pdfviewer semantic search
export const CHANGE_ACTIVE_SEARCH_TERM_COLOR = 'CHANGE_ACTIVE_SEARCH_TERM_COLOR'

