export const getQueryTermID = (queryTerm?: {
  id?: string;
  term: string;
  domains?: string[];
}) => {
  if (!queryTerm) {
    return "qt";
  }
  if (queryTerm.id) {
    return queryTerm.id;
  }
  return `qt_${queryTerm.term}-${queryTerm.domains?.join("-")}`.replace(
    /[^a-zA-Z0-9_-]/g,
    "_"
  );
};
