import React from 'react';
import {CLINICAL_TRIAL_REPOS} from "../../../../../../../properties";

const Clinical = ({documentData, repositoryInfo}) => (
    <>
        <div style={{marginTop: 5}}>
            {!!documentData.startdate &&
            <span style={{marginRight: 15}} title="Start date">
                                {`Start date: ${documentData.startdate}`}
                            </span>
            }

            {!!documentData.clinicalphase &&
            <span style={{marginRight: 15}} title="Phase">
                                {`Phase: ${documentData.clinicalphase.slice(0, 5).join('; ')}${documentData.clinicalphase.length > 5 ? ', ...' : ''}`}
                            </span>
            }

            {!!documentData.status &&
            <span style={{marginRight: 15}} title="Status">
                                {`Status: ${documentData.status}`}
                            </span>
            }
        </div>

        {CLINICAL_TRIAL_REPOS[repositoryInfo.name] && !!documentData.sponsors &&
        <div style={{marginTop: 5}} title="Sponsor">
            {`Sponsor: ${documentData.sponsors.slice(0, 5).join('; ')}${documentData.sponsors.length > 5 ? ', ...' : ''}`}
        </div>
        }
    </>
);


export default Clinical;
