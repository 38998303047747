import { TabPanel, TabView } from "primereact/tabview";

const LogicHelp = () => {
    return (
        <TabView>
            <TabPanel header="Operators">
                <div className="query-examples">
                    <h4>Occurrence</h4>

                    <h5>SHOULD</h5>
                    <div className="paragraph">
                        If a concept should occur, no operator is needed.
                        <div className="query-example">ocid:"201000011510" ocid:"206010028980"</div>
                        <div className="query-example-info">will find documents mentioning either the first concept or the second one or both.</div>
                    </div>
                    <h5>MUST (+)</h5>
                    <div className="paragraph">
                        If concepts must occur, add a <b>+</b> in front of these concepts, e.g.
                        <div className="query-example">+ocid:"201000011510" +ocid:"206010028980"</div>
                        <div className="query-example-info">will only find documents mentioning both concepts.</div>
                    </div>
                    <h5>MUST NOT (-)</h5>
                    <div className="paragraph">
                        If a concept must not occur, add a <b>-</b> in front of the concept, e.g.
                        <div className="query-example">-ocid:"206010028980"</div>
                        <div className="query-example-info">will only find documents not mentioning this concept.</div>
                    </div>

                    <h4>Boost value (^FACTOR)</h4>
                    <div className="paragraph">
                        You can add a factor by which the scoring for a search term is altered, i.e.
                        it will change its influence on the ranking of the documents.
                        It has to be greater than 0. The default value is 1.
                        If it is larger than 1 it increases the importance of the term.
                        If it is lower than 1 it decreases the importance of the term.
                        <div className="query-example">+chem:"monoterpenes"^2 +chem:"steroids"^0.5</div>
                        <div className="query-example-info">will increase the importance of "monoterpenes" and decrease the importance of "steroids".</div>
                    </div>

                    <h4>Text distance (~DISTANCE)</h4>
                    <div className="paragraph">
                        The text distance function works on stemmed text using the
                        syntax: <span className="whiteSpaceNoWrap">t:"TERM_OR_PHRASE"~DISTANCE</span>. The distance defines the maximum distance of words.
                        Without the ~ it matches only in the exact word order.
                        <div className="query-example">t:"matrix amorph"~2</div>
                        <div className="query-example-info">will find e.g. "amorphous matrix", "amorphous ceramic matrix", "amorphous host matrix" and
                            "matrix was amorphous".</div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    )
}

export default LogicHelp;