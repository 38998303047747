/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { deduplicateTerms, findMatchIgnoreCase, //isArrayEmpty,
 normalizeConceptTerm } from "../../util";
import { FREETEXT_EXACT, FREETEXT_WITH_VARIANTS } from "../docsearch/searchConstants";
import DomainColorBar from "../../../common/DomainColorBar/DomainColorBar";


class AutocompleteSuggestionListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        if (this.props.onClick) {
            this.props.onClick(event, this.props.item);
        }
    }

    render() {

        const item = this.props.item ? this.props.item : {};

        //console.log('item: ', item);
        let term = item.term;
        let info = `${FREETEXT_WITH_VARIANTS.label} (not found in any domain)`;
        let coloredDomains

        let hasDomains = false;
        let isTextExact = false;
        let isTextWithVariants = false;
        if (item.domains) {
            isTextExact = item.domains.includes(FREETEXT_EXACT.prefix);
            isTextWithVariants = item.domains.includes(FREETEXT_WITH_VARIANTS.prefix);
            if (!isTextExact && !isTextWithVariants) {
                hasDomains = true;
                const uniqueLabels = item.domains.map(dom =>
                    this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                        this.props.domainLabelsMap[dom] : dom);
                coloredDomains = uniqueLabels.sort().map((lab, i) => {
                    let color = this.props.domainColors && this.props.domainColors.find(obj => {
                        return obj.label === lab
                    })
                    const col = color?.color ? `#${color.color}` : '';
                    return <React.Fragment key={`${lab}-${i}`}>
                        <DomainColorBar color={col} />
                        <span style={{ marginLeft: 4, paddingRight: 10 }}>{lab}</span>
                    </React.Fragment>
                })
                info = uniqueLabels.sort().join(', ');
            }
        }

        let prefName = '';
        const prefnamesUnique = item.prefnames ? deduplicateTerms(item.prefnames) : [];
        if (prefnamesUnique && prefnamesUnique.length === 1 && normalizeConceptTerm(prefnamesUnique[0], true) !== normalizeConceptTerm(term, true)) {
            prefName = ` (${prefnamesUnique[0]})`;
        }

        let termFormatted = term;
        const match = findMatchIgnoreCase(term, this.props.queryPart);
        if (match) {
            termFormatted = termFormatted.replace(match, `<span style="font-weight: normal">${match}</span>`);
        }
        termFormatted += `<span style="font-weight: normal">${prefName}</span>`;

        //const cutOffOtherTerms = item.otherTerms ? [...item.otherTerms].splice(0, 2) : [];


        const menuWidth = this.props.isHovered === undefined ? 180 : 230;


        let clientWidth;
        //const maxWidth = this.props.acRef?.current?.container?.clientWidth;

        if (this.props.acRef?.current?.getElement)
        clientWidth = this.props.acRef?.current?.getElement()?.getBoundingClientRect()?.width;
        if (this.props.acRef?.current?.getBoundingClientRect) clientWidth = this.props.acRef?.current?.getBoundingClientRect()?.width;

        const maxWidth = clientWidth ? clientWidth - 30 : '';
    
        return <div key={term} className='grid'
            style={{ width: '100%', display: 'flex', flexDirection: 'row', maxWidth: maxWidth }}
            // onClick={this.handleClick}
            >
            <div style={{ display: 'flex', flexDirection: 'row', width: `calc(100% - ${menuWidth}px)` }}>
                <div style={{
                    padding: '8px 0px 0px 5px',
                    color: hasDomains ? '' : '#777',
                    width: '50%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}
                    title={term}>
                    <span style={{ fontWeight: 'normal' }}>
                        <span dangerouslySetInnerHTML={{ __html: termFormatted }}></span>
                        {/*!isArrayEmpty(item.otherTerms) ?
                            <span className="secondaryInfo clampingContainer line-clamp line-clamp-1-line"
                                style={{ fontWeight: 'normal', display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', paddingBottom: 0 }}
                                title={item.otherTerms.join(' · ')}
                            >{cutOffOtherTerms.join(' · ')}{item.otherTerms.length > 2 ? ' ...' : ''}
                            </span> : null
                        */}
                    </span>
                </div>
                <div className="col-4 clampingContainer line-clamp line-clamp-1-line"
                    style={{
                        padding: '8px 0px 0px 5px', color: hasDomains ? '' : '#777', width: '50%', overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                    {info ?
                        <div className="grid" style={{
                            //opacity: 0.7,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis', margin: 0, color: '#818181'
                        }}
                            title={info}>
                            {coloredDomains !== undefined ? coloredDomains : info}</div>
                        : null
                    }
                </div>
            </div>
            {/* 
            <div className="col-1 textAlignCenter"
                style={{ padding: '0 5px 0 10px' }}>
                {hasDomains ?
                    <a title="Specify concepts"
                        className="primaryLink showOnHover"
                        onClick={(e) => {
                            //stopEventPropagation(e);
                            this.props.onSpecify(item);
                        }}
                    >Specify
                    </a> : null
                }
            </div>
            */}
            <div style={{
                display: 'flex', width: menuWidth, flexDirection: 'row', justifyContent: 'space-between',
            }}>
                <div style={{ padding: '0 5px' }}>
                    {hasDomains || isTextExact ?
                        <a title="Search as text with variants"
                            className={(this.props.isHovered === undefined || this.props.isHovered === false) ? "primaryLink showOnHover" : "primaryLink hideOnHover"}
                            onClick={(e) => {
                                item.domains = [FREETEXT_WITH_VARIANTS.prefix];
                                //item.term = item.term;
                                item.ocids = null;
                            }}
                        >
                            {FREETEXT_WITH_VARIANTS.label}
                        </a> : null
                    }
                </div>
                <div className="col-fixed textAlignCenter"
                    style={{ padding: '0 5px' }}>
                    {hasDomains || isTextWithVariants ?
                        <a title="Search as exact text"
                            className={(this.props.isHovered === undefined || this.props.isHovered === false) ? "primaryLink showOnHover" : "primaryLink hideOnHover"}
                            onClick={(e) => {
                                item.domains = [FREETEXT_EXACT.prefix];
                                //item.term = item.term;
                                item.ocids = null;
                            }}
                        >
                            {FREETEXT_EXACT.label}
                        </a> : null
                    }
                </div>
            </div>
        </div >
    }
}

export default AutocompleteSuggestionListItem;