import {
    fetchSortFields
} from '../../../../redux/actions/WebApiActions'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ExportContainer from '../ExportContainer'



const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    userData: state.user.data,
    sortFields: state.webAPI.sortFields
})

const mapDispatchToProps = dispatch => ({
    fetchSortFields: (history, search, showFailGrowl) => dispatch(fetchSortFields(history, search, showFailGrowl))
})


const ExportCenterContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportContainer))

export default ExportCenterContainer