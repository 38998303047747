import { fetchMculeData } from "../../../../api/content/ChemistryApi";
import { createConceptDataRequest, fetchConceptsData } from "../../../../api/content/ConceptApi";
import { RESPONSE_STATUS_FAILED, RESPONSE_STATUS_SUCCESS } from "../../../../properties";
import { isArrayEmpty } from "../../util";


export const fetchConceptDataForOcid = async (ocid) => {

    const request = createConceptDataRequest([ocid], true, true, true, true, 0, 1, true);
    const response = await fetchConceptsData(request, true, false); // APP_PROPERTIES.CHEMISTRY.INCLUDE_MCULE_LINKS
    let conceptData = {};
    if (response.status === RESPONSE_STATUS_SUCCESS) {
        conceptData = !isArrayEmpty(response?.payload) ? response?.payload[0] : {};
        return { ...response, payload: conceptData };
    }
    return response;
}

export const fetchMculeUrlForSmiles = async (smiles) => {

    if (!isArrayEmpty(smiles)) {
        const request = { smiles: [smiles] };
        const response = await fetchMculeData(request);

        if (response?.status === RESPONSE_STATUS_SUCCESS && !isArrayEmpty(response?.payload?.mculeLinks)) {
            const mculeLinks = response.payload.mculeLinks;
            return { ...response, payload: mculeLinks[smiles] };
        }
        return response;
    }
    return { status: RESPONSE_STATUS_FAILED, message: 'No smiles found.' };
}