import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { TERMS_BULK_IMPORT_FILE_TYPES } from "../../../../../../helpers/constants";

const BulkImportTermsDialog = ({ visible, onSubmit, onHide }) => {
  const [fileUploadKey, setFileUploadKey] = useState(0);
  const [inputTerms, setInputTerms] = useState([]);

  const handleUpload = ({ files }) => {
    // --- hack: increase counter to change key of file upload component --- //
    // --- otherwise file upload works only once --- //
    setFileUploadKey(fileUploadKey + 1);

    // // --- check file type --- //
    const [file] = files;
    let allowedFileType = false;
    for (var fileType of TERMS_BULK_IMPORT_FILE_TYPES) {
      if (file.name.endsWith(fileType)) {
        allowedFileType = true;
        break;
      }
    }

    if (allowedFileType) {
      var reader = new FileReader();
      reader.onload = function (event) {
        // --- NOTE: event.target points to FileReader --- //
        var contents = event.target.result?.trim();
        const entries = contents?.split(/\r?\n/g);
        setInputTerms(entries);
      };
      reader.readAsText(file);
    } else {
      // TODO: use global growl
      // growl.current.show({
      //     sticky: false, closable: true, severity: 'warn',
      //     summary: 'File format not supported.',
      //     detail: `Please select one of the following file formats: ${this.props.acceptedFileTypes}`
      // });
    }
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          className="p-button-sm p-button-secondary"
          onClick={() => onHide()}
        />
        <Button
          label="OK"
          className="p-button-sm primaryButton"
          onClick={() => {
            onSubmit(inputTerms);
            onHide();
          }}
        />
      </>
    );
  };

  return (
    <>
      <Dialog
        id="bulkImportTermsDialog"
        visible={visible}
        onHide={onHide}
        onShow={() => {}}
        blockScroll
        style={{ minWidth: "50vw", maxWidth: "30vw" }}
        header="Upload terms"
        footer={renderFooter}
        appendTo="self"
        className="styled-dialog"
      >
        <FileUpload
          key={fileUploadKey}
          name="upload"
          maxFileSize={1000000}
          emptyTemplate={
            <p className="m-0">Drag and drop files to here to upload.</p>
          }
          className="p-button-secondary p-button-sm"
          accept={TERMS_BULK_IMPORT_FILE_TYPES.join(",")}
          customUpload={true}
          uploadHandler={(e) => handleUpload(e)}
          mode="basic"
          auto={true}
          chooseLabel="Upload file"
        />
        <div style={{ background: "#f8f8f8", padding: 20, marginTop: 20 }}>
          {inputTerms?.map((term) => {
            return <div key={term}>{term}</div>;
          })}
        </div>
      </Dialog>
    </>
  );
};

export default BulkImportTermsDialog;
