import { z } from 'zod';

import { APP_IDS } from './constants';

export const ApplicationProperties = z.object({
  APP_ID: z.enum(APP_IDS),
  APP_NAME: z.string(),
  INCLUDE_BATCHES: z.boolean().optional(),
  INCLUDE_READCUBE: z.boolean().optional(),
  INCLUDE_FRESHDESK: z.boolean().optional(),
  INCLUDE_ONETRUST: z.boolean().optional(),
  LOGIN_BG_IMAGE: z.string(),
  LOGIN_LOGO_APP: z.string().optional(),
  LOGIN_LOGO_APP_STYLE: z.object({}).passthrough().optional(),
  HEADER_LOGO_APP: z.string(),
  HEADER_LOGO_APP_STYLE: z.object({}).passthrough().optional(),
  ROUTER_BASE_NAME: z.string().startsWith('/').endsWith('/').default('/'),
  FRONTEND_URL: z.string().url(),
  HOME_PAGE: z.string().startsWith('/'),
  MIDDLEWARE_BASE_URL: z.string().url(),
  BRAT_BASE_URL: z.string().optional(),
  SAML_URL: z.string().url().optional(),
  SUPPORT_FORM_URL: z.string().url(),
  RECAPTCHA_KEY: z.string(),
  ACTIVE_PAGES: z.object({
    frameEditor: z.boolean().optional(),
    pipelineManager: z.boolean().optional(),
    servicesStatus: z.boolean().optional(),
    docSearch: z.boolean().optional(),
    history: z.boolean().optional(),
    verificationPage: z.boolean().optional(),
    backendAdministration: z.boolean().optional(),
  }),
  ACTIVE_FUNCTIONALITIES: z.object({
    allowAnnotationFeedback: z.boolean().optional(),
    showServerStatus: z.boolean().optional(),
    showSessionTimeLeft: z.boolean().optional(),
    autoRefreshToken: z.boolean().optional(),
    showDocumentsOnStart: z.boolean().optional(),
    singleUser: z.boolean().optional(),
    addSSOUser: z.boolean().optional(),
    showIcons: z.boolean().optional(),
    fetchSortableFields: z.boolean().optional(),
    ignoreQueryCache: z.boolean().optional(),
    showJournalFilters: z.boolean().optional(),
    showClinicalTrialFilters: z.boolean().optional(),
    showGrantsDSFilters: z.boolean().optional(),
    showPublicationsDSFilters: z.boolean().optional(),
    clearQueryCache: z.boolean().optional(),
    showAnnotatedPDFLink: z.boolean().optional(),
  }),
  CHEMISTRY: z.object({
    CHEMAXON: z.literal('ca'),
    OC: z.literal('oc'),
    OC_BIG_QUERY: z.literal('ocbq'),
    BIG_QUERY: z.literal('bq'),
    BIG_QUERY_SAVI: z.literal('bqsavi'),
    BIG_QUERY_SAVI_PRODUCTS: z.literal('bqsavi_products'),
    XFACT_DB: z.literal('xfactdb'),
    XFACT_DB_RXN: z.literal('xfactdb_rxn'),
    INCLUDE_BQ_COMPOUND_EXPORT: z.boolean().optional(),
    INCLUDE_MCULE_LINKS: z.boolean().optional(),
    showGoogleLinks: z.boolean().optional(),
    INCLUDE_CHEMAXON_MARVIN4JS: z.boolean().optional(),
    INCLUDE_CHEMAXON_WEBSERVICES: z.boolean().optional(),
    CHEMAXON_MARVIN4JS_URL: z.string().optional(),
    CHEMAXON_WEBSERVICES_URL: z.string().optional(),
    CHEMAXON_WEBSERVICES_VERSION: z.string().optional(),
  }),
});

export const validateApplicationProperties = (properties: object) => {
  const result = ApplicationProperties.safeParse(properties);
  if (!result.success) {
    throw new Error(
      `Application properties are invalid. ${result.error}`
    );
  }
  return result.data;
};
