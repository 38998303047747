import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class InactivateUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inactivateUsersChecked: false
        }
    }

    onCheckInactivateUsers = (e) => {
        if (e.checked) {
            this.setState({
                inactivateUsersChecked: true
            })
        } else {
            this.setState({
                inactivateUsersChecked: false
            })
        }
    }

    onInactivateUsers = () => {
        this.props.onInactivateUsers()
    }

    render() {

        let userDetails = this.props.selectedUserDetails && this.props.selectedUserDetails.map(user => {
            return <div key={user.userName} style={{ marginBottom: 5 }}>
                <label>{`${user.lastName}, ${user.foreName} (${user.userName})`}</label>
            </div>
        })

        const footerInactivateUsers = (
            <div>
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelInactivateUsers}
                />
                <Button label="Deactivate"
                    className='p-button-sm primaryButton'
                    disabled={this.state.inactivateUsersChecked ? false : true}
                    onClick={this.onInactivateUsers}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayInactivateUsers} style={{ 'width': "40vw" }} header="Deactivate users" focusOnShow={false}
                modal={true} footer={footerInactivateUsers} dismissableMask={false}
                onHide={() => this.props.onCancelInactivateUsers()} className='styledDialog'>
                <div style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 25, paddingBottom: 15, paddingTop: 15 }}>
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <label>{`This will inactivate the following users:`}</label>
                    </div>
                    {userDetails}
                    <div style={{ marginBottom: 15, marginTop: 15, marginLeft: -2 }}>
                        <Checkbox inputId='activateUserChecked'
                            onChange={(e) => this.onCheckInactivateUsers(e)}
                            checked={this.state.inactivateUsersChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer' }}
                        />
                        <label htmlFor='activateUserChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default InactivateUsers