/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { isArrayEmpty } from "../../webapi/util";
import { fetchAllReadcubeLibraries } from "../../../api/TestApi"

class AddReadcubeDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            globalFilterLibraries: '',
            allReadcubeLibraries: []
        };

        this.growl = React.createRef();
    }

    fetchReadcubeLibraries = async () => {
        this.setState({
            fetchingReadcubeLibraries: true
        }, async () => {
            try {
                let readcubeLibraries = []
                const response = await fetchAllReadcubeLibraries();
                if (response?.payload) {
                    readcubeLibraries = response?.payload
                }
                this.setState({
                    allReadcubeLibraries: readcubeLibraries,
                    //selectedSearch: null, //,!isArrayEmpty(filteredSearches) ? filteredSearches[0] : null,
                    globalFilterLibraries: '',
                    fetchingReadcubeLibraries: false
                });
            } catch (e) {
                console.error(e);
            }
        })
    }

    nameTemplate = (rowData) => {
        return <div>
            <span className="clampingContainer line-clamp line-clamp-1-line">
                {rowData.label}
            </span>
        </div>
    }

    numberDocTemplate = (rowData) => {
        return <div>
            <span className="clampingContainer line-clamp line-clamp-1-line">
                {rowData.numberDocuments}
            </span>
        </div>
    }

    render() {

        const readcubeLibraries = this.state.allReadcubeLibraries;

        const headerReadcubeLibraries = <div className='grid' style={{
            marginRight: 0,
            paddingTop: 5, paddingBottom: 10
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search libraries">
                    {'search'}
                </i>
                <InputText
                    style={{ width: '90%', border: 'none', float: 'left' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterLibraries: e.target.value })}
                    value={this.state.globalFilterLibraries}
                    placeholder="Search libraries" />
            </div>
        </div>

        const isSelectable = (data) => {
            if (this.props.readcubeRequests) {
                return this.props.readcubeRequests?.filter(req => req.collectionId === data.id).length === 0
            } else {
                return true
            }
        }

        const rowClassName = (data) => {
            return isSelectable(data) ? '' : 'p-disabled'
        }

        return (
            <React.Fragment>
                <Toast ref={this.growl} />
                <Dialog focusOnShow={false}
                    visible={this.props.displayDialog}
                    breakpoints={{ '960px': '45vw', '640px': '55vw' }}
                    style={{ width: "35vw" }}
                    header={this.props.readcubeRequests ? "Add ReadCube library" : "Add documents to ReadCube library"}
                    modal={true}
                    dismissableMask={true}
                    onHide={(e) => this.props.onHide(e)}
                    onShow={() => {
                        this.setState({ allReadcubeLibraries: [], selectedReadcubeLibrary: null }, () => this.fetchReadcubeLibraries());
                    }}
                    blockScroll={true}
                    className='styledDialog'>
                    <LoadingOverlay
                        active={this.state.fetchingReadcubeLibraries}
                        spinner={true}
                        text="Fetching ReadCube libraries..." >
                        <div className="grid p-fluid" style={{ paddingLeft: 35, paddingRight: 20, paddingBottom: this.props.readcubeRequests ? 15 : 25, paddingTop: 25, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            <div style={{ width: '100%' }}>
                                {!isArrayEmpty(readcubeLibraries) ?
                                    <React.Fragment>
                                        {headerReadcubeLibraries}
                                        <DataTable ref={(el) => this.dt = el}
                                            isDataSelectable={isSelectable}
                                            rowClassName={rowClassName}
                                            scrollable
                                            scrollHeight='400px'
                                            selection={this.state.selectedReadcubeLibrary}
                                            selectionMode="radiobutton"
                                            removableSort={true}
                                            onSelectionChange={e => this.setState({ selectedReadcubeLibrary: e.value })}
                                            className="dataTableQueries standardTableHeader"
                                            value={readcubeLibraries}
                                            responsive='true'
                                            paginator={false}
                                            globalFilter={this.state.globalFilterLibraries}
                                            emptyMessage="No ReadCube libraries found">
                                            <Column field="check" selectionMode='single' style={{ width: '4em', textAlign: 'center', fontWeight: 'normal' }} />
                                            <Column field="label" header="Name" sortable={true} body={this.nameTemplate} />
                                            {/*<Column field="numberDocuments" header="Number documents" sortable={true} body={this.numberDocTemplate} />*/}
                                        </DataTable>
                                    </React.Fragment>
                                    : <label style={{ fontWeight: 'bold' }}>No ReadCube libraries found.</label>
                                }
                            </div>
                            {!isArrayEmpty(readcubeLibraries) && this.props.readcubeRequests ?
                                <div style={{ width: '100%' }}>
                                    <div className="full-text-message col-12" style={{ marginLeft: 0, marginRight: 0, marginTop: 15, }}>
                                        Note: For importing only documents of your ReadCube library with attached PDF will be considered.
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                        <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                            <Button label="Add"
                                className='p-button-sm primaryButton'
                                disabled={this.state.selectedReadcubeLibrary === null}
                                onClick={() => this.props.onSubmitReadcubeLibrary(this.state.selectedReadcubeLibrary)}
                                style={{ marginRight: 5, float: 'right' }} />
                            <Button label="Cancel"
                                className='p-button-secondary p-button-sm'
                                onClick={this.props.onHide}
                                style={{ float: 'right', marginRight: 5 }} />
                        </div>
                    </LoadingOverlay>
                </Dialog>

            </React.Fragment>
        );
    }
}

export default AddReadcubeDialog;