import './BulkImportIDResultsTable.css';
import { DataTable } from "primereact/datatable";
import { isArrayEmpty } from "../../../util";
import { Column } from "primereact/column";
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import OptionsCheckBox from './OptionsCheckBox';
import { countMatchingDocumentsForRepo, countSimilarDocumentsForRepo, generateDuplicateIDKey, hasExactMatch, hasSimilarMatch } from '../../helpers/results';
import { createSelectItems, filterDocuments, generateSelectedDocumentKey } from '../../helpers/selections';
import MatchingDocumentsStatistic from './MatchingDocumentsStatistic';
import { GoCheckCircleFill, GoIssueClosed } from 'react-icons/go';
import { MdDone, MdDoneAll } from 'react-icons/md';
import { DocViewModal } from '../../../docview/common/DocviewModal/DocviewModal';
import { useMemo, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import SeparatorPoint from '../../../general/SeparatorPoint';


const BulkImportIDResultsTable = ({
    documents,
    duplicateDocuments,
    repositories,
    selectedDocuments,
    onSelectionChange,
    onSelectionOptionChange,
    documentIDMetadataProp,
    includeSimilarDocuments,
    hideSelected
}) => {

    const [displayDocViewDialog, setDisplayDocViewDialog] = useState(false);
    const [displayDuplicatesDialog, setDisplayDuplicatesDialog] = useState(false);
    const [documentID, setDocumentID] = useState();
    const [repositoryID, setRepositoryID] = useState();
    const [duplicates, setDuplicates] = useState();


    const handleOpenDocument = (repoID, docID) => {

        setRepositoryID(repoID);
        setDocumentID(docID);
        setDisplayDocViewDialog(true);
    }


    const indexTemplate = (rowData, column) => {
        return <div>{column.rowIndex + 1}</div>
    }


    const doneTemplate = (rowData, column) => {

        return rowData.isSelected ?
            <div className='done-column'>
                <MdDone
                    fontSize={16}
                    title='At least one matching document selected for this ID' />
            </div> : null;
    }


    const originalIDTemplate = (rowData) => {

        if (hasExactMatch(rowData.results)) {
            return <div style={{ fontWeight: rowData.isSelected ? 'bold' : '' }}>{rowData.id}</div>
        }
        else if (!hasSimilarMatch(rowData.results)) {
            return <div style={{ color: 'gray' }}>{rowData.id}</div>
        }
        return <div>{rowData.id}</div>;
    }


    const documentTemplate = (rowData, column) => {

        const repoID = column.field;
        const data = rowData?.results ? rowData.results[repoID] : null;

        if (data?.exact) {
            const doc = data.exact;
            return createDocumentInfo(repoID, doc, true);
        }
        else if (data?.similar) {
            const doc = data.similar;
            return createDocumentInfo(repoID, doc, false);
        }

        return <div className='matching-document-column none'>
            <Checkbox />
            <div className="document-match-info-box none">-</div>
        </div>
    }


    const createDocumentInfo = (repoID, doc, isExact) => {

        const key = generateSelectedDocumentKey(repoID, doc.qualifiedOcDocId, isExact);
        const keyDupl = generateDuplicateIDKey(repoID, doc.qualifiedOcDocId);

        const isSelected = !!selectedDocuments[key];
        const metadataDocID = !isArrayEmpty(doc.docMetadata[documentIDMetadataProp]) ? doc.docMetadata[documentIDMetadataProp][0] : doc.qualifiedOcDocId;

        return <div className='matching-document-column inline-flex-center'>

            <Checkbox
                onChange={(e) => onSelectionChange(repoID, doc.qualifiedOcDocId, metadataDocID, doc, isExact, e.checked)}
                checked={isSelected}
            ></Checkbox>

            {isExact ?
                <GoCheckCircleFill
                    fontSize={16}
                    className="documents-color exact"
                    title='Exact match' />
                :
                <GoIssueClosed
                    fontSize={16}
                    className="documents-color similar"
                    title='Similar document' />}

            <span>
                <Button
                    className="p-button-text primaryButtonAsLink valignMiddle"
                    title="Click to open document in new tab"
                    onClick={() => handleOpenDocument(repoID, doc.qualifiedOcDocId)}>
                    {metadataDocID}
                </Button>
            </span>

            {duplicateDocuments[keyDupl]?.length > 1 &&
                <>
                    <SeparatorPoint />
                    <a className='secondaryLink secondaryInfo'
                        onClick={() => {
                            setDuplicates(duplicateDocuments[keyDupl]);
                            setDisplayDuplicatesDialog(true);
                        }}
                        title={`This document matches ${duplicateDocuments[keyDupl].length} separate input IDs in this repository.`}>
                        duplicate
                    </a>
                </>}

            {doc.hitCount > 1 &&
                <>
                    <SeparatorPoint />
                    <span className='secondaryLink secondaryInfo'
                        title={`The original ID matches ${doc.hitCount} documents in this repository, probably family members.`}>
                        {doc.hitCount - 1} more
                    </span>
                </>}
        </div>
    }


    const createInputIDHeader = () => {

        return <div>
            <div style={{ marginBottom: 8 }}>Input ID</div>
            <div>{filteredDocuments?.length || 0} entries</div>
        </div>
    }

    const createDoneColumnHeader = (selectedIDCount, totalCount) => {

        return <div>
            <div className='done-column-label flex-center whiteSpaceNoWrap'>
                <MdDoneAll fontSize={16} />
                <span>Completed</span>
            </div>
            <div className='done-column-statistics flex-center whiteSpaceNoWrap'>
                <span>{Math.floor(selectedIDCount / totalCount * 100)}% done</span>
            </div>
        </div>;
    }


    const createDocumentColumnHeader = (repo, documents) => {

        const matchingDocumentCount = countMatchingDocumentsForRepo(documents, repo.id);
        const similarDocumentCount = countSimilarDocumentsForRepo(documents, repo.id);
        const selectItems = createSelectItems(repo.id, onSelectionOptionChange, documentIDMetadataProp, matchingDocumentCount > 0, includeSimilarDocuments && similarDocumentCount > 0);

        return <div style={{ marginRight: 20 }}>
            <div className='matching-document-column-label flex-center' style={{ marginLeft: 30 }}>
                <span>{`${repo.label}`}</span>
            </div>
            <div className='flex-center'>
                <OptionsCheckBox
                    selected={false}
                    selectItems={selectItems}
                    menuStyle={{ width: 'auto' }}
                    checkboxStyle={{ marginRight: 15 }}
                    tooltip='Select/deselect documents'
                />
                <MatchingDocumentsStatistic
                    id={repo.id}
                    const totalCount={documents?.length || 0}
                    matchingDocumentCount={matchingDocumentCount}
                    similarDocumentCount={similarDocumentCount}
                    includeSimilarDocuments={includeSimilarDocuments}
                />
            </div>
        </div>;
    }


    const { filteredDocuments, selectedIDCount } = useMemo(() => filterDocuments(documents, selectedDocuments, hideSelected),
        [documents, selectedDocuments, hideSelected]);

    return <>
        <DataTable
            id='matchingDocumentsTable'
            value={filteredDocuments}
            showGridlines={false}
            paginator={true}
            rows={10}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            emptyMessage='No entries'
        // scrollable
        // scrollHeight="flex"
        >
            <Column
                field="index"
                header="#"
                body={indexTemplate}
                style={{ width: 50 }}
            ></Column>

            <Column
                field="original"
                header={createInputIDHeader()}
                body={originalIDTemplate}
            ></Column>

            <Column
                field="isSelected"
                header={createDoneColumnHeader(selectedIDCount, documents.length)}
                body={doneTemplate}
                style={{ width: 110 }}
                dataType="boolean"
                sortable
            ></Column>

            {repositories?.map(repo => {
                return <Column
                    key={repo.id}
                    field={`${repo.id}`}
                    header={createDocumentColumnHeader(repo, documents)}
                    body={documentTemplate}
                    sortable
                    sortField={`${repo.id}_matchType`}
                ></Column>
            })}
        </DataTable>

        <DocViewModal
            isVisible={displayDocViewDialog}
            docId={documentID}
            repId={repositoryID}
            onCloseDocviewModal={() => { setDisplayDocViewDialog(false) }}
            isSemanticSearch={false}
            documents={{}}
        />

        <Dialog
            visible={displayDuplicatesDialog}
            header="Duplicates"
            focusOnShow={false}
            modal={true}
            dismissableMask={true}
            onHide={() => setDisplayDuplicatesDialog(false)}
        >
            {duplicates &&
                <div style={{ padding: '10px 10px 0px 10px' }}>
                    <strong>{duplicates.length} input IDs are matching this document:</strong>
                    <ul style={{ lineHeight: 1.5 }}>
                        {duplicates.map(duplicate => {
                            return <li key={duplicate}>{duplicate}</li>;
                        })}
                    </ul>
                </div>}
        </Dialog>
    </>;
}

export default BulkImportIDResultsTable;