import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';

class EditRepository extends Component {

    constructor(props) {
        super(props);
        this.state = {
            repositoryLabel: '',
            repositoryDescription: '',
            repositoryFeatures: []
        }
    }

    componentDidMount() {
        let repositoryLabel = this.props.selectedRepositoryLabel ? this.props.selectedRepositoryLabel : ''
        let repositoryDescription = this.props.selectedRepositoryDescription ? this.props.selectedRepositoryDescription : '';
        let repositoryFeatures = this.props.selectedRepositoryFeatures ? Object.values(this.props.selectedRepositoryFeatures) : []

        this.setState({
            repositoryLabel: repositoryLabel,
            repositoryDescription: repositoryDescription,
            repositoryFeatures: repositoryFeatures
        })
    }

    onEditRepository = () => {
        this.props.onEditRepository(this.props.selectedRepositoryID, this.state.repositoryLabel, this.props.selectedRepositoryName, this.state.repositoryFeatures, this.state.repositoryDescription)
    }

    render() {

        const footerEditRepository = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelEditRepository}
                    style={{ marginRight: 5 }} />
                <Button label="Edit"
                    className='p-button-sm primaryButton'
                    disabled={this.state.repositoryLabel !== '' ? false : true}
                    onClick={this.onEditRepository}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayEditRepository} style={{ 'width': "60vw" }} header="Edit repository" focusOnShow={false}
                modal={true} footer={footerEditRepository} dismissableMask={false}
                onHide={() => this.props.onCancelEditRepository()} className='styledDialog'>
                <div style={{ marginBottom: 20, paddingTop: 20, paddingLeft: 25 }}>
                    <span>Repository ID: {this.props.selectedRepositoryID}</span>
                    <span style={{ marginLeft: 30 }}>Repository name: {this.props.selectedRepositoryName}</span>
                </div>
                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 15, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="col-12 grid p-fluid">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="repositoryLabel">Repository label (for displaying)</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="repositoryLabel"
                                onChange={(e) => { this.setState({ repositoryLabel: e.target.value }) }} value={this.state.repositoryLabel} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="repositoryFeatures">Repository features</label>
                            <MultiSelect
                                pt={{
                                    checkboxIcon: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            if (e.target.className.baseVal !== "") {
                                                e.target.parentNode.click();
                                            } else {
                                                e.target.parentNode.parentNode.click();
                                            }
                                        },
                                    },
                                    headerCheckbox: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            e.target.parentNode.click();
                                        },
                                    },
                                }}
                                maxSelectedLabels={3}
                                id="repositoryFeatures"
                                style={{ width: '100%', marginTop: 8 }}
                                value={this.state.repositoryFeatures}
                                options={this.props.allAvailableRepositoryFeatures ? Object.values(this.props.allAvailableRepositoryFeatures) : []}
                                optionValue="id"
                                optionLabel="label"
                                onChange={(e) => this.setState({
                                    repositoryFeatures: e.value
                                })}
                                showClear={true}
                                placeholder='No features selected'
                                scrollHeight="400px"
                            />
                        </div>
                        <div className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="repositoryDescription">Repository description</label>
                            <InputTextarea style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="repositoryDescription" maxLength={300}
                                onChange={(e) => { this.setState({ repositoryDescription: e.target.value }) }} value={this.state.repositoryDescription} />
                            <label style={{ color: 'darkgray' }}>Max. 300 characters</label>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default EditRepository