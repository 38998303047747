import { AdvancedSearch3Actions } from "../actions";


const initialState = {
    searchGroups: [],
    searchGroupToEdit: null,
    selectedSearchGroups: [],
    savedSearchData: {},
    numberOfResults: {},
    numberOfResultsPreview: null,
    errors: null,
    errorsPreview: null
};


const advancedSearch3Reducer = (state = initialState, action) => {

    switch (action.type) {

        // === search groups === //

        case AdvancedSearch3Actions.AddSearchGroup: {
            const searchGroup = action.payload;
            return {
                ...state,
                searchGroups: [...state.searchGroups, searchGroup]
            };
        }

        case AdvancedSearch3Actions.EditSearchGroup: {
            const searchGroup = action.payload;
            const searchGroupsNew = state.searchGroups.map(group => group.id === searchGroup.id ? searchGroup : group);
            return {
                ...state,
                searchGroups: searchGroupsNew
            };
        }

        case AdvancedSearch3Actions.RemoveSearchGroup: {
            const searchGroup = action.payload;
            const searchGroupsNew = state.searchGroups.filter(group => group.id !== searchGroup.id);
            return {
                ...state,
                searchGroups: searchGroupsNew,
                searchGroupToEdit: null
            };
        }

        case AdvancedSearch3Actions.SearchGroupToEdit: {
            const searchGroup = action.payload;
            return {
                ...state,
                searchGroupToEdit: searchGroup
            };
        }

        case AdvancedSearch3Actions.SelectedSearchGroups: {
            const selectedSearchGroups = action.payload;
            return {
                ...state,
                selectedSearchGroups: selectedSearchGroups
            };
        }


        // === dialogs === //

        case AdvancedSearch3Actions.ToggleTermGroupDialog: {
            const show = action.payload;
            return {
                ...state,
                toggleTermGroupDialog: show
            };
        }

        case AdvancedSearch3Actions.ToggleLogicGroupDialog: {
            const show = action.payload;
            return {
                ...state,
                toggleLogicGroupDialog: show
            };
        }

        case AdvancedSearch3Actions.ToggleFilterGroupDialog: {
            const show = action.payload;
            return {
                ...state,
                toggleFilterGroupDialog: show
            };
        }

        case AdvancedSearch3Actions.ToggleSavedSearchGroupDialog: {
            const show = action.payload;
            return {
                ...state,
                toggleSavedSearchGroupDialog: show
            };
        }

        case AdvancedSearch3Actions.ShowGlobalGrowl: {
            const growlMessage = action.payload;
            return {
                ...state,
                growlMessage
            };
        }


        // === advanced search form === //

        case AdvancedSearch3Actions.AdvancedSearch3FormContent: {
            const searchGroups = action.payload ?? [];
            return {
                ...state,
                searchGroups
            };
        }


        // === preview results for search groups === //

        case AdvancedSearch3Actions.PreviewRepository: {
            const previewRepository = action.payload;
            return {
                ...state,
                previewRepository
            };
        }

        case AdvancedSearch3Actions.SearchGroupsQueryStrings: {
            const searchGroupsQueryStrings = action.payload ?? {};
            return {
                ...state,
                searchGroupsQueryStrings
            };
        }

        case AdvancedSearch3Actions.FetchingNumberOfResults: {
            const fetchingNumberOfResults = action.payload;
            return {
                ...state,
                fetchingNumberOfResults
            };
        }

        case AdvancedSearch3Actions.SearchGroupsNumberOfResults: {
            const numberOfResults = action.payload ?? {};
            return {
                ...state,
                numberOfResults
            };
        }

        case AdvancedSearch3Actions.SearchGroupsNumberOfResultsPreview: {
            const numberOfResultsPreview = action.payload;
            return {
                ...state,
                numberOfResultsPreview
            };
        }


        // === preview results for query terms === //

        case AdvancedSearch3Actions.TermsQueryStrings: {
            const termsQueryStrings = action.payload ?? {};
            return {
                ...state,
                termsQueryStrings
            };
        }


        // === consistency checks and errors === //

        case AdvancedSearch3Actions.SearchGroupsReachable: {
            const reachableSearchGroups = action.payload;
            return {
                ...state,
                reachableSearchGroups
            };
        }

        case AdvancedSearch3Actions.SearchGroupsErrors: {
            const errors = action.payload;
            return {
                ...state,
                errors
            };
        }

        case AdvancedSearch3Actions.SearchGroupsErrorsPreview: {
            const errorsPreview = action.payload;
            return {
                ...state,
                errorsPreview
            };
        }


        // === saved search === //

        case AdvancedSearch3Actions.SavedAdvancedSearch: {
            const savedSearchData = action.payload ?? {};
            return {
                ...state,
                savedSearchData
            };
        }


        default:
            return state;
    }
};

export default advancedSearch3Reducer;