/** Copyright Ontochem GmbH
 *  author: Timo Boehme (timo.boehme (a) ontochem.com)
 *          apueschel
 *
 *  modified 2012-03-21 for display of test_ontologies
 *           2014-06-16 annotation type settings retrieved from server
 *
 **/

export const ANN_INFO_ORDER = {
   'annTypeLabel': 'Annotation type',
   //'aType': 'Annotation type', 
   //'domain': 'Annotation type', 
   //'ont': 'Type',
   //'subDomain': 'Sub domain',
   'ocid': 'OCID',
   'preferredName': 'Main term',
   'synonyms': 'Synonyms',
   'pcCId': 'PubChem CId',
   'pcSId': 'PubChem SId',
   'ikey': 'InChI Key',
   'inchi': 'InChI',
   'iupac': 'IUPAC',
   'smiles': 'SMILES',
   'structImage': 'Structure',
   'formula': 'Formula',
   'molWeight': 'Mol weight',
   'logd': 'logD',
   'lipinski': "Lipinski's rule of five",
   'leadlikeliness': 'Bioavailability',
   'bioavailability': 'Lead likeliness',
   'relEnt': 'Related entities',
   'partOf': 'Contained compounds',
   'src': 'Source',
   'confidence': 'Confidence',
   'relevanceScore': 'Relevance',
}

export const ANN_INFO_EXPLANATION = {
   'confidence': 'The confidence score gives an estimation about the correctness of a specific annotation at a certain position in a document. The confidence score of a specific concept can therefore vary at different locations in a document. The higher the confidence score the more likely this annotation is correct.',
   'relevanceScore': 'The relevance score gives an estimation of how relevant a concept is in the context of one individual document. The higher the relevance score the more relevant this concept is for the topic of this document. The relevance score is calculated for chemical entities only.',
}

export const SOURCE_EXPLANATION = {
   'dict': 'This term has been semantically recognized by one of the dictionaries.',
   'deconv': 'This term has been identified by the document specific label deconvolution that resolves terms like "compound 1" etc. to the actual chemical compound that is meant by that label.',
   'chemFormula': 'Chemical formulas which are detected by the chemFormula detector.',
   'abbr': 'Abbreviations recognized by detecting the short form belonging to a specific long form in the document (abbreviation resolution tool).',
   'n2sOpsin': 'New compounds which are recognized by chemical name to structure resolution using n2sOpsin.',
   'chemCGC': 'For chemical entities the chemical type, e.g. chemical class, group or compound, is detected in ambiguous cases by the surrounding context.',
   'chemAcc': 'Automatic combination of contiguous chemical entities by the chemistry accumulator.',
   'CH': 'Modification based on the context by the ContextHandler.',
   'AccComb': 'Combines an accumulation/sequence of named entities, e.g. proteins, but also species or cells, to a single annotation. The most specific named entity is expanded to include the range of the other named entity.'
}
