import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchGroupCompact from "../../../../../common/queryBuilder/searchForm/searchGroup/compactMode/SearchGroupCompact";
import {
  removeSearchGroup,
  setSearchGroupToEdit,
  setSelectedSearchGroups,
} from "../../../../../../redux/features/queryBuilder/actions";

import { filterDefinitionsSelector } from "../../../../../../redux/common/filters/selectors/filters";
import SearchGroupItem from './compactMode/SearchGroupItem';
import { createPartialQueryString } from "../../../helpers/queryConverter";
import SearchGroupEditor from "./editMode/SearchGroupEditor";
import "./SearchGroup.css";

const SearchGroup = ({ group, onRunPartialSearch, hasError, fetching }) => {
  const dispatch = useDispatch();
  const groupToEdit = useSelector(
    (state) => state.advancedSearch3Reducer.searchGroupToEdit
  );
  const searchGroups = useSelector(
    (state) => state.advancedSearch3Reducer.searchGroups
  );
  const filterDefinitions = useSelector(filterDefinitionsSelector);

  const handleRunPartialSearch = useCallback(async () => {
    const queryString = await createPartialQueryString(
      group,
      searchGroups,
      filterDefinitions
    );
    onRunPartialSearch(queryString);
  }, [group, filterDefinitions, onRunPartialSearch, searchGroups]);

  const renderSearchGroupItem = useCallback(
    () => <SearchGroupItem group={group} searchGroups={searchGroups} />,
    [group, searchGroups]
  );

  const handleEditGroup = () => {
    dispatch(setSelectedSearchGroups([]));
    dispatch(setSearchGroupToEdit(group));
  };

  const handleRemoveSearchGroup = () => {
    dispatch(setSelectedSearchGroups([]));
    dispatch(removeSearchGroup(group));
  };

  const isEditing = group.id === groupToEdit?.id;

  if (isEditing) {
    return (
      <SearchGroupEditor
        groupToEdit={groupToEdit}
        onRunPartialSearch={handleRunPartialSearch}
        onRemoveSearchGroup={handleRemoveSearchGroup}
        hasError={hasError}
        fetching={fetching}
      />
    );
  }
  return (
    <SearchGroupCompact
      group={group}
      onEdit={handleEditGroup}
      renderSearchGroupItem={renderSearchGroupItem}
      onRunPartialSearch={handleRunPartialSearch}
      onRemoveSearchGroup={handleRemoveSearchGroup}
      hasError={hasError}
      fetching={fetching}
    />
  );
};

export default SearchGroup;
