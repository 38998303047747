import React, { Component, createRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import SyntaxEditor from './SyntaxEditor';
import * as OntologyClassUtil from '../../util/OntologyClassUtil';
import { cloneDeep } from '../../../util';
import { checkResultAndGetPayload } from '../../../../../api';
import { translatePhraseTokensToSyntaxPatternString } from '../../../../../api/content/FrameEditorApi';
import { Toast } from 'primereact/toast';


class EditRoleTagBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            role: null
        }

        this.growl = createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        //console.log('nextProps: ', nextProps);

        // dropdown currently not working correctly: 
        // if value is defined -> event.value is string, not object 
        // -> create copy of role and delete value

        let roleSelectItems = this.state.roleSelectItems;
        if (this.props.roleSelectItems !== nextProps.roleSelectItems) {

            const roleSelectItems = [];
            if (nextProps.roleSelectItems) {
                for (const role of nextProps.roleSelectItems) {
                    const roleCopy = { ...role };
                    roleCopy.name = `${role.label}`;
                    delete roleCopy.value;
                    roleSelectItems.push(roleCopy);
                }
            }

            this.setState({
                roleSelectItems: roleSelectItems
            });
        }

        if (this.props.tag !== nextProps.tag) {

            if (nextProps.tag && nextProps.tag.value) {

                const { name } = OntologyClassUtil.extractNameAndSyntaxFromRoleTagValue(nextProps.tag.value);

                let selRole = null;
                if (roleSelectItems) {
                    for (const role of roleSelectItems) {
                        if (role.name === name) {
                            selRole = role;
                        }
                    }
                }

                this.setState({
                    role: selRole,
                });
            }
            else {
                this.setState({
                    role: null
                });
            }

            if (nextProps.tag && nextProps.tag.phraseTokens) {
                this.syntaxEditor.setPhraseTokens(cloneDeep(nextProps.tag.phraseTokens));
            }
            else {
                this.syntaxEditor.setPhraseTokens([]);
            }
        }
    }

    onEditTagSubmit = async () => {

        const newTag = OntologyClassUtil.createRoleTag();
        const phraseTokens = this.syntaxEditor.getPhraseTokens();
        //console.log('phraseTokens: ', phraseTokens);
        if (!this.state.role) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: 'Empty role not allowed.' });
        }
        else if (!phraseTokens || phraseTokens.length === 0) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: 'Empty syntax not allowed.' });
        }
        else {
            const response = await translatePhraseTokensToSyntaxPatternString(phraseTokens, this.props.history);
            const parsedSyntaxObj = checkResultAndGetPayload(response, this.growl);

            if (parsedSyntaxObj) {
                const syntaxStrg = (parsedSyntaxObj && parsedSyntaxObj.parsedSyntax) ? parsedSyntaxObj.parsedSyntax : '';

                newTag.value = `${this.state.role.name} ${syntaxStrg}`;
                // --- additional data --- //
                newTag.roleName = this.state.role.name;
                newTag.roleID = this.state.role.id;
                newTag.roleSyntax = syntaxStrg;
                newTag.phraseTokens = phraseTokens;

                this.props.onEditTagSubmit(newTag);
            }
        }
    }


    render() {

        const { contentBoxHeight } = this.props;

        return (
            <>
                <Toast ref={this.growl} appendTo={document.body} />

                <div className="task-box task-box-dark-blue" style={{ padding: 0, background: 'white' }}> {/*20 #676767*/}
                    <div className="task-box-header">
                        Edit role entry
                </div>
                    <div className="task-box-content"
                        style={{
                            padding: '10px', height: contentBoxHeight, overflow: 'auto'
                        }}>
                        <div className="grid" style={{ padding: '10px' }}> {/*  style={{ minWidth: '60wv', minHeight: '70vh',  maxHeight: '90vh',  overflow: 'auto' }} */}

                            <div className="col-12" style={{ marginBottom: 5, paddingBottom: 0, paddingTop: 10 }}>
                                <div className="grid">
                                    <div className="col-fixed" style={{ width: 80 }}>
                                        Role:
                                </div>
                                    <div className="col">
                                        {/* 
                                    <Dropdown
                                        value={this.state.role}
                                        options={this.props.roleSelectItems}
                                        //optionLabel="label"
                                        onChange={(e) => { console.log('sel role val: ', e.value); this.setState({ role: e.value }) }}
                                        placeholder="Select role"
                                        filter={true}
                                        filterBy="label"
                                    />
                                    */}
                                        <Dropdown value={this.state.role}
                                            options={this.state.roleSelectItems}
                                            onChange={(e) => { this.setState({ role: e.value }) }}
                                            placeholder="Select a role"
                                            optionLabel="name"
                                            style={{ width: 'auto' }} />
                                    </div>
                                    <div className="col-12">
                                    </div>
                                    <div className="col-fixed" style={{ width: 80 }}>
                                        Syntax:
                                </div>
                                    <div className="col">
                                        <SyntaxEditor
                                            ref={(ref) => { this.syntaxEditor = ref; }}

                                            type={OntologyClassUtil.TAG_TYPE_ROLE}

                                            domains={this.props.domains}
                                            roles={this.props.roles}

                                            roleSelectItems={this.props.roleSelectItems}
                                            synsetSelectItems={this.props.synsetSelectItems}
                                            macroSelectItems={this.props.macroSelectItems}
                                            frameSelectItems={this.props.frameSelectItems}

                                            contentBoxHeight={this.props.editorHeight}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12" style={{ textAlign: 'right' }}>
                                <Button label="Cancel"
                                    className="p-button-secondary p-button-sm"
                                    onClick={() => {
                                        this.syntaxEditor.onClearSyntaxEditor();
                                        this.props.onEditTagCancel()
                                    }}>
                                </Button>
                                <Button label="Confirm"
                                    className="primaryButton p-button-sm"
                                    //disabled={!this.state.role}
                                    onClick={(e) => this.onEditTagSubmit()}>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EditRoleTagBox;