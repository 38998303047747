import { SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL } from "../../general/docsearch/searchConstants";
import { isArrayEmpty, isObjectEmpty } from "../../util";
import { hasFreeText } from "./advancedSearch";
import { SEARCH_GROUP_TYPE_SAVED_SEARCH } from "./constants";

export const checkSearchGroups = (searchGroups) => {};

// === check for reachable groups === //

export const filterReachableGroups = (searchGroups, startSearchGroup) => {
  const reachableGroupIDs = filterReachableGroupIDs(
    searchGroups,
    startSearchGroup
  );
  const reachableGroups = !isObjectEmpty(reachableGroupIDs)
    ? searchGroups.filter((group) => reachableGroupIDs[group.id])
    : [];

  return reachableGroups;
};

export const filterReachableGroupIDs = (searchGroups, startSearchGroup) => {
  if (isArrayEmpty(searchGroups)) {
    return [];
  }

  const startGroup = startSearchGroup || searchGroups[searchGroups.length - 1];
  const reachableGroupIDs = {};
  findReachableGroupIDs(searchGroups, startGroup, reachableGroupIDs);

  return reachableGroupIDs;
};

const findReachableGroupIDs = (
  searchGroups = [],
  searchGroup,
  reachableGroupIDs = {}
) => {
  if (searchGroup) {
    reachableGroupIDs[searchGroup.id] = true;
    if (!isArrayEmpty(searchGroup.searchGroupIDRefs)) {
      for (let groupIDRef of searchGroup.searchGroupIDRefs) {
        const refGroup = searchGroups.find((sg) => sg.id === groupIDRef);
        findReachableGroupIDs(searchGroups, refGroup, reachableGroupIDs);
      }
    }
  }
  return reachableGroupIDs;
};

// === check for all errors === //

export const checkForErrors = (searchGroups) => {
  const forwardRefs = checkForForwardReferences(searchGroups);
  const nearbys = checkForConceptDistanceErrors(searchGroups);
  const savedSearches = checkForMissingSavedSearchData(searchGroups);

  const errors = { ...forwardRefs, ...nearbys, ...savedSearches };

  if (!isObjectEmpty(forwardRefs)) {
    errors.hasForwardReferences = true;
  }
  if (!isObjectEmpty(nearbys)) {
    errors.hasConceptDistanceErrors = true;
  }
  if (!isObjectEmpty(savedSearches)) {
    errors.hasMissingSavedSearchData = true;
  }

  return errors;
};

// === check for forward references === //

export const checkForForwardReferences = (searchGroups) => {
  if (isArrayEmpty(searchGroups)) {
    return [];
  }

  const indices = {};
  searchGroups.forEach((group, index) => {
    indices[group.id] = index;
  });

  const references = {};
  const done = {};
  const forwardReferences = {};

  [...searchGroups].reverse().forEach((startGroup) => {
    if (!done[startGroup.id]) {
      collectReferences(searchGroups, startGroup, indices, references, done);
      Object.entries(references).forEach(([groupIndex, refIndices]) => {
        if (refIndices.some((refIndex) => refIndex >= groupIndex)) {
          forwardReferences[searchGroups[groupIndex].id] =
            "References to groups found, that are not defined at this point. Please move this group accordingly.";
        }
      });
    }
  });

  return forwardReferences;
};

const collectReferences = (
  searchGroups = [],
  searchGroup,
  indices = {},
  references = {},
  done = {}
) => {
  if (searchGroup && !done[searchGroup.id]) {
    done[searchGroup.id] = true;
    const searchGreoupIndex = indices[searchGroup.id];
    references[searchGreoupIndex] = [];

    if (!isArrayEmpty(searchGroup.searchGroupIDRefs)) {
      for (let groupIDRef of searchGroup.searchGroupIDRefs) {
        references[searchGreoupIndex].push(indices[groupIDRef]);
        const refGroup = searchGroups.find((sg) => sg.id === groupIDRef);
        collectReferences(searchGroups, refGroup, indices, references, done);
      }
    }
  }
  return references;
};

// === check for text terms === //

export const checkForConceptDistanceErrors = (searchGroups) => {
  const conceptDistanceErrors = {};

  const nearbyGroups = searchGroups.filter((group) => {
    const concDist =
      group?.searchCriteria &&
      group.searchCriteria[SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL]
        ?.filterValue;
    return !!concDist;
  });

  nearbyGroups?.forEach((nearbyGroup) => {
    // console.log('nearbyGroup', nearbyGroup);
    // if (nearbyGroup.operator === LOGICAL_OPERATOR_OR.value) {
    //     conceptDistanceErrors[nearbyGroup.id] = "Concept distance cannot be applied to OR-combined terms or groups. Please use concept distance only for AND or NOT groups.";
    // }
    // else {
    const reachableGroupIDs = filterReachableGroupIDs(
      searchGroups,
      nearbyGroup
    );
    searchGroups.forEach((group) => {
      if (reachableGroupIDs[group.id] && hasFreeText(group.queryTerms)) {
        conceptDistanceErrors[nearbyGroup.id] =
          "Concept distance cannot be applied to text terms. Please check this group and all referenced groups for text terms.";
      }
    });
    // }
  });

  return conceptDistanceErrors;
};

// === check for unknown saved search IDs === //

export const checkForMissingSavedSearchData = (searchGroups) => {
  const missingSavedSearchData = {};
  searchGroups.forEach((group) => {
    if (
      group.type === SEARCH_GROUP_TYPE_SAVED_SEARCH &&
      !group.savedSearchData
    ) {
      missingSavedSearchData[
        group.id
      ] = `No data found for saved search with internal ID ${group.savedSearchID}. Either the search does not exist anymore or you don't have the rights to access it.`;
    }
  });

  return missingSavedSearchData;
};
