import React from "react";


function DateRangeFilterText() {

    return (
        <>
            <h5>Date ranges</h5>
            <p>
                Supported date formats: YYYY-MM-DD or YYYY-MM or YYYY,
                e.g. 2021-11-29 or 2021-11 or 2021.
            </p>
            <p>
                Fill the "from" field to find documents from this date until now.
            </p>
            <p>
                Fill the "to" field to find documents until this date.
            </p>
            <p>
                Fill both fields to find documents from within this date range.
            </p>
        </>
    );
}

export default DateRangeFilterText;