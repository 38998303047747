import React from 'react';
import CheckboxWithLabel from "../../webapi/biomarker/CheckboxWithLabel/CheckboxWithLabel";
import styles from "./LeftAsideItem.module.scss";
import TitleWithTooltip from "../TitleWithTooltip/TitleWithTooltip";

const LeftAsideItem = ({node, checkboxAnyIsChecked, handleCheckboxAny, checkboxAnyIsDisabled, isFacettes, tooltipText}) => {
    const {name, label, key} = node;
    const handleCheckBox = () => {
        handleCheckboxAny(name);
    }

    // add "any" checkbox to each filter except 'repositories'
    const isCheckbox = (!(label === 'repositories' || label === 'list') && isFacettes);
    const text = <span>{label}</span>;

    return (
        <div className={styles.leftAsideItem}>


            <div className={styles.leftAsideItemHeader}>
                <div className={styles.textContainer}>
                    {label === 'repositories' ?
                        <TitleWithTooltip tooltipText={tooltipText} altImageText='Repositories icon'
                                          tooltipExtraClassNames="whiteSpaceBreakSpaces">
                            {text}
                        </TitleWithTooltip>

                        : text}
                </div>


                {isCheckbox &&
                <CheckboxWithLabel checked={checkboxAnyIsChecked} handleCheckBox={handleCheckBox} labelText='Any'
                                   inputId={key} disabled={checkboxAnyIsDisabled}/>}
            </div>
        </div>

    );
};

export default LeftAsideItem;
