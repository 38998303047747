/* eslint-disable jsx-a11y/anchor-is-valid */
import "./TopInfo.css";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import SeparatorPoint from "../../../general/SeparatorPoint";
import { isArrayEmpty } from "../../../util";
import {
  createChemSearchRequestFromConceptData, createCoocSearchRequestFromConceptData, createQuickSearchRequestFromConceptData,
  executeChemSearchShortcut, executeCoocShortcut, executeQuickSearchShortcut
} from "../../../util/shortcuts";
import DomainColorBar from "../../../../common/DomainColorBar/DomainColorBar";


const TopInfo = ({
  conceptData,
  domainColor,
  domainLabel,
  includeQuickSearchShortcut,
  includeChemSearchShortcut,
  includeCoocSearchShortcut,
}) => {

  const growl = useRef(null);

  const onSendToQuickSearch = (conceptData) => {
    if (conceptData) {
      const req = createQuickSearchRequestFromConceptData(conceptData.ocid, conceptData.domain, conceptData.preferredName);
      executeQuickSearchShortcut(req, null, true);
    }
  }

  const onSendToCoocSearch = (conceptData) => {
    if (conceptData) {
      const req = createCoocSearchRequestFromConceptData(conceptData.ocid, conceptData.domain, conceptData.preferredName);
      executeCoocShortcut(req, true);
    }
  }

  const onSendToChemSearch = (conceptData) => {
    if (conceptData) {
      const req = createChemSearchRequestFromConceptData(conceptData.ocid, conceptData.domain, conceptData.preferredName);
      executeChemSearchShortcut(req, true);
    }
  }

  const domainColorHex = domainColor ? `#${domainColor}` : null;

  return conceptData ? <>
    <Toast ref={growl} />

    <div className="top-info-container">
      <div className="grid">
        <DomainColorBar color={domainColorHex} size='medium' style={{ marginLeft: 5, marginRight: 6 }} />
        <label className="secondaryInfo" htmlFor="prefName">
          {domainLabel ? domainLabel : conceptData.domain}
        </label>
      </div>
      <div className="prefname-container clampingContainer line-clamp line-clamp-1-line"
        title={conceptData.preferredName}>
        <h1 id="prefName" className="prefname wordBreakBreakAll">
          {conceptData.preferredName}
        </h1>
      </div>
      <span className="secondaryInfo">OCID: {conceptData.ocid}</span>
      <div>
        <CopyToClipboard
          text={conceptData.preferredName}>
          <a title='Copy structure name to clipboard'
            onClick={(e) => {
              growl.current.show({
                sticky: false, severity: 'success',
                summary: 'Success',
                detail: `Copied to clipboard.`
              });
            }}>Copy name</a>
        </CopyToClipboard>
        {includeQuickSearchShortcut &&
          <>
            <SeparatorPoint />
            <a title="Copy concept to Quick Search to perform a document search."
              onClick={() => onSendToQuickSearch(conceptData)}>Send to document search</a>
          </>
        }
        {includeChemSearchShortcut && conceptData.extendedData && !isArrayEmpty(conceptData.extendedData.smiles) &&
          <>
            <SeparatorPoint />
            <a title="Copy concept to chemistry search to perform a compound or reaction search."
              onClick={() => onSendToChemSearch(conceptData)}>Send to chemistry search</a>
          </>
        }
        {includeCoocSearchShortcut &&
          <>
            <SeparatorPoint />
            <a title="Copy concept to Co-occurrence Analysis to perform a correlation search."
              onClick={() => onSendToCoocSearch(conceptData)}>Send to co-occurrence analysis</a>
          </>
        }
      </div>
    </div>
  </> : null;
}

export default TopInfo;