import "./DocumentAdditionalData.css";
import { Rating } from "primereact/rating";
import DocumentBookmark from "../general/DocumentResult/DocumentBookmark";
import { hasUserRole } from "../util";
import { APP_PROPERTIES } from "../../../properties";
import ReadCubePapersLink from "../../common/ReadCube/ReadCubePapersLink";
import DocumentQuickInfoLinks from "../general/DocumentResult/DocumentQuickInfoLinks";
import RectangleBatches from "../general/DocumentResult/RectangleBatches";
import BILinkResolver from "../general/DocumentResult/BILinkResolver";
import DocumentIDLinks from "../general/DocumentResult/DocumentIDLinks";
import { getReadcubePapersIdentifier } from "../../common/ReadCube/readCubePapersUtil";


const DocumentAdditionalData = ({
    documentData,
    additionalDocumentInfo,
    activeDocumentParts,
    documentPartsInfo,
    onLoadDocumentPart,
    patentFamilyLabel
}) => {

    const additionalInfo = !!additionalDocumentInfo && !!additionalDocumentInfo[documentData.ocDocId] ? additionalDocumentInfo[documentData.ocDocId] : null;
    const isRead = additionalInfo?.read;
    const rating = additionalInfo?.rating ? additionalInfo.rating : 0;
    const collections = additionalInfo?.documentCollectionList ? additionalInfo.documentCollectionList : null;
    const { type, identifier } = getReadcubePapersIdentifier(documentData);

    return (
        documentData ?
            <div className="doc-additional-data-container">

                {isRead && hasUserRole("ROLE_READDOCUMENTS") ?
                    <span id={`bookmark_${documentData.ocDocId}`}
                        className="doc-additional-data-entry">
                        <DocumentBookmark
                            isRead={isRead}
                            readOnly={true}
                        />
                    </span> : null}

                {rating > 0 ?
                    <span id={`rating_${documentData.ocDocId}`}
                        className="doc-additional-data-entry">
                        <Rating
                            value={rating}
                            title="Your rating for this document"
                            readOnly={true}
                            cancel={false}
                            style={{ fontSize: '0.6em', display: 'inline' }}
                        //style={{ fontSize: '0.7em', display: 'inline-block', verticalAlign: 'middle', marginRight: 15 }}
                        /></span> : null}

                {documentData.groupedDocsTotalCount > 0 ?
                    <span id={`famMembers_${documentData.ocDocId}`}
                        className="doc-additional-data-entry"
                        title="Matching members in this patent family">
                        <span style={{ marginRight: 8 }}>{patentFamilyLabel} members:</span>
                        <span id={`numOfFamMembers_${documentData.ocDocId}`}>{documentData.groupedDocsTotalCount}</span>
                    </span> : null}

                {!!collections && Object.keys(collections).length > 0 && hasUserRole('ROLE_LIBRARY') ?
                    <span id={`collections_${documentData.ocDocId}`}
                        className="doc-additional-data-entry"
                        title="Document is part of collections">
                        In collections:
                        <span id={`collectionNames_${documentData.ocDocId}`}>{collections.map(coll => coll.name).join(', ')}</span>
                    </span>
                    : null}

                {activeDocumentParts && documentPartsInfo &&
                    <span id={`docParts_${documentData.ocDocId}`}
                        className="doc-additional-data-entry">
                        <DocumentQuickInfoLinks
                            documentData={documentData}
                            activeDocParts={activeDocumentParts}
                            docPartsInfo={documentPartsInfo}
                            onLoadDocumentPart={onLoadDocumentPart}
                        />
                    </span>}

                <span id={`identifiers_${documentData.ocDocId}`}
                    className="doc-additional-data-entry">
                    <DocumentIDLinks documentData={documentData} />
                </span>

                {identifier && APP_PROPERTIES.INCLUDE_READCUBE &&
                    <span id={`readcubeLinks_${documentData.ocDocId}`}
                        className="doc-additional-data-entry">
                        <ReadCubePapersLink
                            type={type}
                            identifier={identifier}
                            compactView={true}
                        />
                    </span>}

                <span id={`biLinks_${documentData.ocDocId}`}
                    className="doc-additional-data-entry">
                    <BILinkResolver
                        documentData={documentData}
                        useIcon={true}
                    //iconStyle={{ position: 'relative', top: 4, marginRight: 15 }}
                    />
                </span>

                {documentData.doi && APP_PROPERTIES.INCLUDE_BATCHES ?
                    <span id={`batches_${documentData.ocDocId}`}
                        style={{ marginRight: 0 }}>
                        <RectangleBatches documentData={documentData} />
                    </span> : null}

            </div> : null
    )
}

export default DocumentAdditionalData;