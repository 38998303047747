import React, { useMemo, useState } from 'react';
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import classnames from "classnames";
import { addThousandsSeparatorToNumber } from "../../webapi/util";
import styles from './ListHeader.module.scss'
import { sortByIndex } from "../helpers/sortByIndex";

const ListHeader = React.forwardRef(({
    hitCount,
    sortField,
    handleSortFieldChange,
    sortDirection,
    handleSortDirectionChange,
    exportTopResults, checkedResults, exportSelectedResults, allRelationIds, nodes
}, ref) => {
    const [displayExportDialog, setDisplayExportDialog] = useState(false);
    const exportFormats = [{ label: 'XLSX - Excel', value: 'xlsx' }];
    const [exportFormat, setExportFormat] = useState('xlsx');
    const [selectedIds, setSelectedIds] = useState(null);

    const exportVariants = [{
        name: `${checkedResults.length} selected items`,
        value: 'selected',
        disabled: !checkedResults.length
    }, {
        name: 'All items from this page', value: 'all'
    }, { name: 'Top 50', value: 50 }, { name: 'Top 100', value: 100 }, {
        name: 'Top 250', value: 250
    }, { name: 'Top 500', value: 500 }];

    const sortDirections = ['ascending', 'descending'];


    const exportOptions = exportVariants.map((option) => (
        <div style={{paddingBottom: 5}} key={option.name} className="p-field-radiobutton">
            <RadioButton inputId={option.name} name="category" value={option}
                onChange={(e) => setSelectedIds(e.value)}
                checked={selectedIds?.name === option.name} disabled={option.disabled} />
            <label style={{paddingLeft: 5}} className={option.disabled ? styles.disabled : ''} htmlFor={option.name}>{option.name}</label>
        </div>
    ));

    // create labels for dropdown and sort it. to prevent useless recreation of object, wrap it to useMemo
    // exclude 'repositories' from array - it is used only in left aside on the page
    const sortFields = useMemo(() => nodes.filter(label => label.label !== 'repositories')
        .map(item => ({
            // value and label are required fields
            label: item.label,
            value: item.name,
            index: item.key
        }))
        .sort(sortByIndex), [nodes]);

    const header = classnames("grid", styles.header);
    const hitsContainer = classnames("col-fixed textAlignLeft", styles.hitsContainer);

    const onExport = () => {
        switch (selectedIds.value) {
            case 'all':
                exportSelectedResults(allRelationIds);
                break;
            case 'selected':
                exportSelectedResults(checkedResults);
                break;
            default:
                exportTopResults(selectedIds.value);
        }
    }

    const showExportDialog = () => {
        // set default options
        setDisplayExportDialog(true);
        if (!selectedIds) {
            if (!!checkedResults.length) {
                setSelectedIds(exportVariants[0]); // there are selected search results for export.
            } else {
                setSelectedIds(exportVariants[1]); // there are not selected search results. all results are selected for export
            }
        }
    }

    const onHideExportDialog = () => {
        setDisplayExportDialog(false);
        setSelectedIds(null);
    }

    const handleFormatChange = (event) => {
        setExportFormat(event.value);
    }

    const popupFooter = (
        <div className={styles.buttonContainer}>
            <Button label="Close" className="p-button-secondary p-button-sm" onClick={onHideExportDialog} />
            <Button label="Export" className='primaryButton p-button-sm' onClick={onExport} />
        </div>
    );
    //const dropdown = classnames(styles.bottomBorder, styles.dropdown);

    return (
        <>
            <Dialog className='styledDialog' header='Export' visible={displayExportDialog} onHide={onHideExportDialog}
                dismissableMask
                breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw' }} footer={popupFooter}
                appendTo='self' blockScroll baseZIndex={500}>
                <div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className={styles.bottomBorder}>{exportOptions}</div>

                    <div style={{ paddingTop: 10, paddingLeft: 4}}>
                        File format:
                        <Dropdown
                            className={styles.dropdownList}
                            options={exportFormats}
                            value={exportFormat}
                            onChange={handleFormatChange} />
                    </div>
                </div>
            </Dialog>

            {!!hitCount && <div className={header} ref={ref}>
                <div className={hitsContainer}>
                    <div style={{ paddingTop: 7 }} className="secondaryInfo">
                        <span>{`${addThousandsSeparatorToNumber(hitCount)} hits`}</span>
                    </div>
                </div>

                <div className="col textAlignRight" style={{ padding: 0 }}>
                    <div style={{ marginLeft: 10, display: 'inline', textAlign: 'left' }}>
                        <label className="valignMiddle" htmlFor="sortBy">Sort by:</label>
                        <Dropdown
                            className="dropdownNoBorder"
                            options={sortFields}
                            value={sortField}
                            placeholder="Sort By"
                            onChange={handleSortFieldChange}
                        />

                        <Dropdown
                            className="dropdownNoBorder"
                            options={sortDirections}
                            value={sortDirection}
                            onChange={handleSortDirectionChange}
                        />

                        <div style={{ marginLeft: 10, display: 'inline' }}>
                            <a onClick={showExportDialog}
                                className="valignMiddle"
                                style={{ marginLeft: 10 }}
                            >
                                Export
                            </a>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
});

export default ListHeader;
