import "./DocumentIDLinks.css";
import { useRef } from "react";
import SeparatorPoint from '../SeparatorPoint';
import { isArrayEmpty, isExternalURL } from '../../util';
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import ExternalLinkIcon from "../ExternalLinkIcon/ExternalLinkIcon";
import { createDOILink, createPubmedLink } from "../../util/externalLinks";


const DocumentIDLinks = ({ documentData, showCustomPatentLinks }) => {

    const growl = useRef(null);

    const menuInt = useRef(null);
    const menuDoi = useRef(null);
    const menuPat = useRef(null);
    const menuPmid = useRef(null);
    const menuPmc = useRef(null);
    const menuCt = useRef(null);
    const menuGrant = useRef(null);
    const menuOai = useRef(null);
    const menuExtLink = useRef(null);


    const createLinkOptions = (label, id, url) => {
        const options = [];

        if (url) {
            options.push({
                label: <span title={url}>
                    Open external link
                    <ExternalLinkIcon standardLinkIcon={true} />
                </span>,
                command: (e) => window.open(url, '_blank')
            });
            if (showCustomPatentLinks && (documentData['pat_document-id'] || documentData['Pat_document-id'])) {
                let splittedPatentNumber = []
                if (documentData['pat_document-id']) {
                    if (!Array.isArray(documentData['pat_document-id'])) {
                        splittedPatentNumber = documentData['pat_document-id'].split('-')
                    } else {
                        splittedPatentNumber = documentData['pat_document-id'][0].split('-')
                    }
                } else if (documentData['Pat_document-id']) {
                    if (!Array.isArray(documentData['Pat_document-id'])) {
                        splittedPatentNumber = documentData['Pat_document-id'].split('-')
                    } else {
                        splittedPatentNumber = documentData['Pat_document-id'][0].split('-')
                    }
                }
                let CC = ''
                let NR = ''
                let KC = ''
                if (splittedPatentNumber[0] && splittedPatentNumber[0] !== '') {
                    CC = splittedPatentNumber[0]
                }
                if (splittedPatentNumber[1] && splittedPatentNumber[1] !== '') {
                    NR = splittedPatentNumber[1]
                }
                if (splittedPatentNumber[2] && splittedPatentNumber[2] !== '') {
                    KC = splittedPatentNumber[2]
                }

                let urlWithoutHyphens = ''
                if (documentData['pat_document-id']) {
                    if (!Array.isArray(documentData['pat_document-id'])) {
                        urlWithoutHyphens = documentData['pat_document-id'].replace(/-/g, '')
                    } else {
                        urlWithoutHyphens = documentData['pat_document-id'][0].replace(/-/g, '')
                    }
                } else if (documentData['Pat_document-id']) {
                    if (!Array.isArray(documentData['Pat_document-id'])) {
                        urlWithoutHyphens = documentData['Pat_document-id'].replace(/-/g, '')
                    } else {
                        urlWithoutHyphens = documentData['Pat_document-id'][0].replace(/-/g, '')
                    }
                }

                let USPTOapplicationURL = ''
                if (documentData['Pat_document-id'] || documentData['pat_document-id']) {
                    if (documentData['Pat_application-reference'] && documentData['Pat_application-reference'].length > 0) {
                        if (documentData['Pat_application-reference'][0] && documentData['Pat_application-reference'][0].startsWith("US")) {
                            USPTOapplicationURL = `https://patentcenter.uspto.gov/applications/${documentData['Pat_application-reference'][0].slice(-8)}`
                        }
                    }
                }

                let newUrl = `https://worldwide.espacenet.com/publicationDetails/biblio?CC=${CC}&NR=${NR}&KC=${KC}`
                options.push({
                    label: <span title={newUrl}>
                        Espacenet
                        <ExternalLinkIcon standardLinkIcon={true} />
                    </span>,
                    command: (e) => window.open(newUrl, '_blank')
                });

                let newPatUrl = `http://www.patbase.com/getimg/vvpdf.asp?ID=2&vpdf=1&F=1&PGN=1&KD=AA&PN=${CC + NR}`
                options.push({
                    label: <span title={newPatUrl}>
                        PatBase - PatentOrder
                        <ExternalLinkIcon standardLinkIcon={true} />
                    </span>,
                    command: (e) => window.open(newPatUrl, '_blank')
                });

                let googleUrl = `https://patents.google.com/patent/${urlWithoutHyphens}/en?oq=${urlWithoutHyphens}`
                options.push({
                    label: <span title={googleUrl}>
                        Google Patents
                        <ExternalLinkIcon standardLinkIcon={true} />
                    </span>,
                    command: (e) => window.open(googleUrl, '_blank')
                });

                {/*let WIPOUrl = `https://patentscope.wipo.int/search/en/detail.jsf?docId=${CC + NR}`
                options.push({
                    label: <span title={WIPOUrl}>
                        WIPO
                        <ExternalLinkIcon standardLinkIcon={true} />
                    </span>,
                    command: (e) => window.open(WIPOUrl, '_blank')
                });*/}

                if (USPTOapplicationURL !== '') {
                    options.push({
                        label: <span title={USPTOapplicationURL}>
                            USPTO
                            <ExternalLinkIcon standardLinkIcon={true} />
                        </span>,
                        command: (e) => window.open(USPTOapplicationURL, '_blank')
                    });
                }
            }
            if (!showCustomPatentLinks) {
                options.push({
                    label: <span title={url}>Copy URL</span>,
                    command: (e) => {
                        navigator.clipboard.writeText(url);
                        showSuccessGrowl(url);
                    }
                });
            }
        }
        if (id) {
            options.push({
                label: <span title={id}>Copy {label}</span>,
                command: (e) => {
                    navigator.clipboard.writeText(id);
                    showSuccessGrowl(id);
                }
            });
        }

        return options;
    }

    const createIDEntry = (label, menuRef, optionItems, id, elemID) => {
        return <>
            <a id={`identifier_${documentData.ocDocId}_${elemID}`}
                className={!showCustomPatentLinks ? "secondaryLink document-id-link" : 'secondaryLink document-id-link-regular'}
                title={`${id} - Click to see options.`}
                onClick={(e) => menuRef.current.show(e)}>
                {label}
            </a>
            <Menu
                className="small-padding-tb whiteSpaceNoWrap widthAuto"
                model={optionItems}
                popup
                ref={menuRef} />
        </>
    }

    const showSuccessGrowl = (text) => {
        growl.current.show({
            sticky: false, severity: 'success', summary: 'Success',
            detail: <div>
                <div>Copied to clipboard:</div>
                <div><b>{text}</b></div>
            </div>
        })
    }

    const createDocumentIDLinks = (documentData) => {

        const entriesIDs = [];

        const url = documentData.url;
        //const extLink = documentData.url;
        /*console.log('documentData', documentData);
        console.log('url', url);*/

        // OC document ID
        const ocDocID = documentData.ocDocId || documentData.ocDocID;
        if (ocDocID) {
            let label = 'Internal ID';
            let opts = createLinkOptions(label, ocDocID);
            let entry = createIDEntry(label, menuInt, opts, ocDocID, 'docid');
            entriesIDs.push(entry);
        }
        // DOI
        if (!isArrayEmpty(documentData.doi)) {
            let label = 'DOI';
            let opts = createLinkOptions(label, documentData.doi[0], createDOILink(documentData.doi[0]));
            let entry = createIDEntry(label, menuDoi, opts, documentData.doi[0], 'doi');
            entriesIDs.push(entry);
        }
        // Patent ID
        if (!isArrayEmpty(documentData['Pat_document-id'])) {
            let label = 'Patent publication No.';
            let opts = createLinkOptions(label, documentData['Pat_document-id'][0]);
            let entry = createIDEntry(label, menuPat, opts, documentData['Pat_document-id'][0], 'patid');
            entriesIDs.push(entry);
        }
        // PMID
        if (!isArrayEmpty(documentData.pmid)) {
            let label = 'PMID';
            let opts = createLinkOptions(label, documentData.pmid[0], createPubmedLink(documentData.pmid[0]));
            let entry = createIDEntry(label, menuPmid, opts, documentData.pmid[0], 'pmid');
            entriesIDs.push(entry);
        }
        // PMCID
        if (!isArrayEmpty(documentData.pmc)) {
            let label = 'PMCID';
            let opts = createLinkOptions(label, documentData.pmc[0]);
            let entry = createIDEntry(label, menuPmc, opts, documentData.pmc[0], 'pmcid');
            entriesIDs.push(entry);
        }
        // OAI
        if (!isArrayEmpty(documentData.oai)) {
            let label = 'OAI';
            //let opts = createLinkOptions(label, documentData.oai[0], createOAILink(documentData.oai[0]));
            /*let opts = (isExternalURL(url)) ?
                createLinkOptions(label, documentData.oai[0], url) :
                createLinkOptions(label, documentData.oai[0]);*/
            let opts = createLinkOptions(label, documentData.oai[0]);
            let entry = createIDEntry(label, menuOai, opts, documentData.oai[0], 'oai');
            entriesIDs.push(entry);
        }
        // Clinical trial ID
        if (!isArrayEmpty(documentData.trialids)) {
            let label = 'Clinical trial ID';
            /*let opts = (isExternalURL(url)) ?
                createLinkOptions(label, documentData.trialids[0], url) :
                createLinkOptions(label, documentData.trialids[0]);*/
            let opts = createLinkOptions(label, documentData.trialids[0]);
            let entry = createIDEntry(label, menuCt, opts, documentData.trialids[0], 'ctid');
            entriesIDs.push(entry);
        }
        // Grant number ????
        if (!isArrayEmpty(documentData.project_num)) {
            let label = 'Grant number';
            /*let opts = (isExternalURL(url)) ?
                createLinkOptions(label, documentData.project_num[0], url) :
                createLinkOptions(label, documentData.project_num[0]);*/
            let opts = createLinkOptions(label, documentData.project_num[0]);
            let entry = createIDEntry(label, menuGrant, opts, documentData.project_num[0], 'grantno');
            entriesIDs.push(entry);
        }
        // External link
        if (isExternalURL(url)) {
            let label = 'Link';
            let opts = createLinkOptions(label, null, url);
            let entry = createIDEntry(label, menuExtLink, opts, url, 'link');
            entriesIDs.push(entry);
        }
        // CORE ID - coreid
        // external link - url/uri
        // ExtID - extId
        // ISSN - issn

        return entriesIDs;
    }

    const renderMetadataBlock = (metadata, compactView) => {

        return <>
            {metadata?.map((md, index) => {
                if (compactView) {
                    return <span key={`md${index}`}>
                        {md}
                        {index < metadata.length - 1 ? <SeparatorPoint margin={4} /> : null}
                    </span>
                }
                else {
                    return <div key={`md${index}`}>
                        {md}
                    </div>
                }
            })}
        </>
    }

    const documentIDEntries = createDocumentIDLinks(documentData);

    return (
        <>
            <Toast ref={growl} style={{ marginTop: 90 }} />

            {!isArrayEmpty(documentIDEntries) ?
                <>
                    <span style={{ marginRight: 8 }}>Identifiers:</span>
                    {renderMetadataBlock(documentIDEntries, true)}
                </> : null}
        </>
    )
}

export default DocumentIDLinks;

