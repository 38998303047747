// --- repository colors for bar charts --- //
export const REPOSITORY_COLORS = [
    "#5495D6",
    "#70A845",
    "#C55CA2",
    "#EF944E",
    "#4BAE93",
    "#CB584D",
    "#B78F41",
    "#9D5EE0",
    "#5C66EC",
    "#378C95",

    "#3B6997",
    "#4F7630",
    "#8B4072",
    "#A86837",
    "#347A67",
    "#8F3E36",
    "#81642D",
    "#6E429E",
    "#4048A6",
    "#266269",
];

// --- repository roles --- //
export const REPO_ROLE_BIOMARKER = { id: "BIOMARKER_SEARCH", label: "Biomarker Search", abbreviation: "Biom" };
export const REPO_ROLE_BIOACTIVITY = { id: "BIOACTIVITY_SEARCH", label: "Bioactivity Search", abbreviation: "Bioa" };
export const REPO_ROLE_COOCS = { id: "COOC_SEARCH", label: "Co-occurrence Analysis", abbreviation: "Cooc" };
export const REPO_ROLE_DOC_SEARCH = { id: "DOCUMENT_SEARCH", label: "Document Search", abbreviation: "Doc" };
export const REPO_ROLE_FACT_FINDER = { id: "FACT_FINDER", label: "Fact Finder", abbreviation: "Fact" };
export const REPO_ROLE_NAV_RELS = { id: "NAVIGATIONAL_RELATION", label: "Navigational Relations", abbreviation: "Nav" };
export const REPO_ROLE_CHEMISTRY = { id: "CHEMISTRY_SEARCH", label: "Chemistry Search", abbreviation: "Chem" };

export const REPO_ROLES = {
    [REPO_ROLE_BIOMARKER.id]: REPO_ROLE_BIOMARKER,
    [REPO_ROLE_BIOACTIVITY.id]: REPO_ROLE_BIOACTIVITY,
    [REPO_ROLE_COOCS.id]: REPO_ROLE_COOCS,
    [REPO_ROLE_DOC_SEARCH.id]: REPO_ROLE_DOC_SEARCH,
    [REPO_ROLE_FACT_FINDER.id]: REPO_ROLE_FACT_FINDER,
    [REPO_ROLE_NAV_RELS.id]: REPO_ROLE_NAV_RELS,
    [REPO_ROLE_CHEMISTRY.id]: REPO_ROLE_CHEMISTRY
}

// --- pseudo repository to avoid undefined values --- //
export const REPOSITORY_INFO_NOT_AVAILABLE = {
    unavailable: true,
    id: 0,
    name: 'na',
    label: 'N/A'
};

export const FILE_LINK_REPOS = {
};

export const DS_REPOS = {
    'dsgrants': true,
    'dspub': true,
}

export const PATENT_REPOS = {
    'patents': true,
    'patdocdb': true,
    'ifipatents': true
};

export const GRANT_REPOS = {
    'nihgrants': true,
    'eugrants': true,
};

export const GRANT_DS_REPOS = {
    'dsgrants': true,
};

export const JOURNAL_REPOS = {
    'med': true,
    'pmc': true,
    'dspub': true,
};

export const PUBLICATIONS_REPOS = {
    'med': true,
    'pmc': true,
    'dspub': true,
    'oacore': true,
    'biorxiv': true,
    'chemrxiv': true,
    'medrxiv': true,
    'embase': true,
    'biembase': true,
};

export const CLINICAL_TRIAL_REPOS = {
    'ctcoll': true,
    'ctaact': true,
    'ctciteline': true,
    'cteuct': true,
    'ctictrp': true,
};

export const WEB_REPOS = {
    'web': true,
    'webregenold': true,
}

export const HOT_FOLDER_REPOS = {
    'cvg_hot_folder': true,
}

export const MEETING_ABSTRACTS_REPOS = {
    'medmeme': true,
    'bimedmeme': true,
}

export const DRUG_LABELS_REPO = {
    'druglabels': true,
}

export const CORE_REPO = {
    'oacore': true,
}

export const CLINICAL_TRIAL_REPO_OLD = {
    'aact': true
};

export const PDF_REPOS = {
    'biorxiv': true,
    'chemrxiv': true,
    'medrxiv': true,
    'dsm_labdocs': true,
};


export const getAnnotationTypeForRepoName = (repositoryName) => {
    return repositoryName === 'dspub' ? 'INLINE' : 'PDF_OR_INLINE';
}

export const getAnnotationTypeForDocID = (documentID) => {
    return documentID?.startsWith('dspub.') ? 'INLINE' : 'PDF_OR_INLINE';
}