import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { isArrayEmpty } from "../../../../../../../util";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import {
  setSelectedSearchGroups,
  toggleLogicGroupDialog,
} from "../../../../../../../../../redux/features/queryBuilder/actions";
import {
  LOGICAL_OPERATORS_AND_OR_NOT,
  LOGICAL_OPERATOR_NOT,
} from "../../../../../../helpers/constants";
import ReferencedGroups from "../../../../../../../../common/queryBuilder/searchForm/searchGroup/ReferencedGroups";
import { useEffect, useState } from "react";

const LogicGroupEdit = ({
  groupReferences,
  logicalOperator,
  onValuesChange,
}) => {
  const searchGroups = useSelector(
    (state) => state.advancedSearch3Reducer.searchGroups
  );
  const selectedSearchGroups = useSelector(
    (state) => state.advancedSearch3Reducer.selectedSearchGroups
  );
  const toggleDialog = useSelector(
    (state) => state.advancedSearch3Reducer.toggleLogicGroupDialog
  );
  const dispatch = useDispatch();

  const [operator, setOperator] = useState(
    LOGICAL_OPERATORS_AND_OR_NOT[0].value
  );

  useEffect(() => {
    setOperator(logicalOperator);
  }, [logicalOperator]);

  const handleChangeMainGroup = (refGroupID) => {
    let selectedSearchGroupsNew = [...selectedSearchGroups];
    selectedSearchGroupsNew.unshift(refGroupID);
    dispatch(setSelectedSearchGroups(selectedSearchGroupsNew));
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          className="p-button-sm p-button-secondary"
          onClick={() => {
            dispatch(toggleLogicGroupDialog(false));
          }}
        />
        <Button
          label="OK"
          className="p-button-sm primaryButton"
          onClick={() => {
            dispatch(toggleLogicGroupDialog(false));
            const groupReferencesUpdatedNew = [...selectedSearchGroups];
            onValuesChange(operator, groupReferencesUpdatedNew);
          }}
        />
      </>
    );
  };

  return (
    <>
      <div className="flex align-items-center">
        {logicalOperator === LOGICAL_OPERATOR_NOT.value ? (
          <div className="flex align-items-center">
            <ReferencedGroups
              groupReferences={groupReferences?.slice(0, 1)}
              groups={searchGroups}
            />
            <span style={{ marginRight: 10 }}>&mdash;</span>
            <ReferencedGroups
              groupReferences={groupReferences?.slice(1)}
              groups={searchGroups}
              sort={true}
            />
          </div>
        ) : (
          <div>
            <ReferencedGroups
              groupReferences={groupReferences}
              groups={searchGroups}
              sort={true}
            />
          </div>
        )}

        <div>
          {!isArrayEmpty(groupReferences) ? (
            <span style={{ marginLeft: 15 }}>
              <a
                onClick={() => {
                  dispatch(setSelectedSearchGroups([...groupReferences]));
                  dispatch(toggleLogicGroupDialog(true));
                }}
              >
                Edit groups
              </a>
            </span>
          ) : (
            <a
              onClick={() => {
                dispatch(setSelectedSearchGroups([]));
                dispatch(toggleLogicGroupDialog(true));
              }}
            >
              Add groups
            </a>
          )}
        </div>
      </div>
      <Dialog
        id="logicGroupDialog"
        visible={toggleDialog}
        onHide={() => dispatch(toggleLogicGroupDialog(false))}
        modal={false}
        style={{ width: 600, minHeight: 310 }}
        header="Logic group"
        footer={renderFooter}
        appendTo="self"
        className="styled-dialog"
      >
        <div className="grid">
          <div className="col-12">
            <span className="secondaryInfo">
              To add (or remove) search groups to this logic group simply use
              the checkboxes in the table rows.
            </span>
          </div>
          <div className="col">
            <label>Combine with:</label>
            <Dropdown
              id="logic"
              value={operator}
              options={LOGICAL_OPERATORS_AND_OR_NOT}
              optionValue="value"
              onChange={(e) => {
                setOperator(e.value);
              }}
              style={{ minWidth: 70 }}
              placeholder="logical operator"
            />
          </div>
          <div className="col-12">
            {!isArrayEmpty(selectedSearchGroups) &&
            operator === LOGICAL_OPERATOR_NOT.value ? (
              <div className="flex gap-4">
                <div>
                  <div>Main group (must match):</div>
                  <div className="my-3 mx-0">
                    <ReferencedGroups
                      groupReferences={selectedSearchGroups.slice(0, 1)}
                      groups={searchGroups}
                      sort={true}
                    />
                  </div>
                </div>
                <div>
                  <div>Filter groups (must not match):</div>
                  <div
                    className="my-3 mx-0 cursor-pointer"
                    title="Click to set as main group"
                  >
                    <ReferencedGroups
                      groupReferences={selectedSearchGroups.slice(1)}
                      groups={searchGroups}
                      sort={true}
                      onGroupReferenceClick={handleChangeMainGroup}
                    />
                  </div>
                  <div className="secondaryInfo">
                    Click one of the filter groups to set it as the main group.
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <ReferencedGroups
                  groupReferences={selectedSearchGroups}
                  groups={searchGroups}
                  sort={true}
                />
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default LogicGroupEdit;
