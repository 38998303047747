import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

class AddDomain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            domainLabel: '',
            domainName: '',
            domainColor: 'CCCCCC'
        }
    }

    onAddDomain = () => {
        this.props.onAddDomain(this.state.domainLabel, this.state.domainName, this.state.domainColor)
    }

    render() {

        return (
            <Dialog visible={this.props.displayAddDomain} style={{ 'width': "60vw" }} header="Add new searchable/highlighted domain" focusOnShow={false}
                modal={true}
                dismissableMask={false}
                onHide={() => this.props.onCancelAddDomain()} className='styledDialog'>
                <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6', }}>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="domainLabel">Domain label (for displaying) *</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} id="domainLabel"
                            onChange={(e) => { this.setState({ domainLabel: e.target.value }) }} value={this.state.domainLabel} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="domainName">Domain name *</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} id="domainName"
                            onChange={(e) => { this.setState({ domainName: e.target.value }) }} value={this.state.domainName} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="domainColor">Color</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 7 }}>#</div>
                            <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5, backgroundColor: this.state.domainColor ? `#${this.state.domainColor}` : '' }} id="domainColor"
                                onChange={(e) => { this.setState({ domainColor: e.target.value }) }} value={this.state.domainColor} />
                        </div>
                    </div>
                </div>
                <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                    <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 15 }}>* required</label>
                    <Button label="Save"
                        className='p-button-sm primaryButton'
                        disabled={(this.state.domainLabel !== '' && this.state.domainName !== '') ? false : true}
                        onClick={this.onAddDomain}
                        style={{ marginRight: 5, float: 'right' }} />
                    <Button label="Cancel"
                        className='p-button-secondary p-button-sm'
                        onClick={this.props.onCancelAddDomain}
                        style={{ float: 'right', marginRight: 5 }} />
                </div>
            </Dialog>
        )
    }
}

export default AddDomain