export const ColorBox = ({ color }) => {
  return (
    <div
      style={{
        backgroundColor: color,
        width: '15px',
        height: '15px',
        boxSizing: 'border-box',
        marginRight: '0.5rem',
      }}
    ></div>
  );
};
