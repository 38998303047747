import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class DeleteDomain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteDomainChecked: false
        }
    }

    onCheckDeleteDomain = (e) => {
        if (e.checked) {
            this.setState({
                deleteDomainChecked: true
            })
        } else {
            this.setState({
                deleteDomainChecked: false
            })
        }
    }

    onDeleteDomain = () => {
        this.props.onDeleteDomain(this.props.selectedDomainID)
    }

    render() {

        const footerDeleteDomain = (
            <div style={{ paddingTop: 5 }}>
            <Button label="Delete"
            className='p-button-sm p-button-danger'
                disabled={this.state.deleteDomainChecked ? false : true}
                onClick={this.onDeleteDomain}
                style={{marginRight: 5}}
            />
                <Button label="Cancel"
                className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelDeleteDomain}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayDeleteDomain} style={{ 'width': "40vw" }} header="Delete domain" focusOnShow={false}
                modal={true} footer={footerDeleteDomain} dismissableMask={false}
                onHide={() => this.props.onCancelDeleteDomain()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div style={{ marginBottom: 10 }}>
                        <label>{`Warning! This will delete domain '${this.props.selectedDomainLabel} (${this.props.selectedDomainName})'.`}</label>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <Checkbox inputId='deleteDomainChecked'
                            onChange={(e) => this.onCheckDeleteDomain(e)}
                            checked={this.state.deleteDomainChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                        />
                        <label htmlFor='deleteDomainChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default DeleteDomain