import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Login from '../auth/Login';
import { NotFound } from '../auth/NotFound';
import { APP_PROPERTIES } from '../../properties';
import EditRegularUser from '../Administration/EditRegularUser';
import AdministrationContainer from '../Administration/container/AdministrationContainer';
import BackendAdminContainer from '../BackendAdmin/container/BackendAdminContainer';
import PasswordUpdate from '../user/PasswordUpdate';
import AlertsContainer from '../webapi/Alerts/container/AlertsContainer';
import BlacklistContainer from '../webapi/Blacklists/container/BlacklistContainer';
import LibraryContainer from '../webapi/Library/container/LibraryContainer';
import SequenceSearchContainer from '../webapi/SequenceSearch/container/SequenceSearchContainer';
import WatchlistsContainer from '../webapi/Watchlists/container/WatchlistsContainer';
import AppContainer from '../webapi/apps/AppContainer';
import DynamicApps from '../webapi/apps/DynamicApps';
import Bioactivities from "../webapi/bioactivities/Bioactivities";
import AcknowledgementContainer from '../webapi/container/AcknowledgementContainer';
import BiomarkerContainer from '../webapi/container/BiomarkerContainer';
import ChangelogContainer from '../webapi/container/ChangelogContainer';
import ChemistrySearchContainer from "../webapi/container/ChemistrySearchContainer";
import CooccurrenceAnalysisContainer from '../webapi/container/CooccurrenceAnalysisContainer';
import DocumentFinderContainer from '../webapi/container/DocumentFinderContainer';
import AdvancedSearchContainer from '../webapi/container/AdvancedSearchContainer';
import DocumentViewContainer from "../webapi/container/DocumentViewContainer";
import FrameEditorContainer from '../webapi/container/FrameEditorContainer';
import InferenceSearchContainer from '../webapi/container/InferenceSearchContainer';
import OntologyBrowserContainer from "../webapi/container/OntologyBrowserContainer";
import PipelineManagerContainer from '../webapi/container/PipelineManagerContainer';
import QuickSearchContainer from '../webapi/container/QuickSearchContainer';
import ServicesStatusContainer from '../webapi/container/ServicesStatusContainer';
import TutorialsContainer from '../webapi/container/TutorialsContainer';
import ExportCenterContainer from '../webapi/exportCenter/container/ExportCenterContainer';
import SentenceContainer from '../webapi/SentenceAnalysis/container/SentenceContainer';
import { TrendAnalysis } from "../webapi/trendAnalysis/TrendAnalysis";
import { hasUserRole } from '../webapi/util';


const Routes = () => {

    const userData = useSelector((state) => state.user.data);

    return (
        <Switch>

            {hasUserRole('ROLE_BLACKLISTS') && <Route path="/my/blocklists" component={BlacklistContainer} />}

            {APP_PROPERTIES.ACTIVE_PAGES.docSearch && <Route path="/docfinder/quicksearch" render={() => <QuickSearchContainer />} />}

            {APP_PROPERTIES.ACTIVE_PAGES.docSearch && <Route path="/docfinder/advancedsearch" render={() => <DocumentFinderContainer />} />}

            {APP_PROPERTIES.ACTIVE_PAGES.docSearch && hasUserRole('ROLE_QUERYBUILDER') && <Route path="/docfinder/querybuilder" render={() => <AdvancedSearchContainer />} />}

            <Route path="/docview/:repID?/:ocDocID?/:tab?" component={DocumentViewContainer} />

            {(hasUserRole('ROLE_COMPOUNDSEARCH') || hasUserRole('ROLE_REACTIONSEARCH')) && <Route path="/chemfinder/chemsearch" component={ChemistrySearchContainer} />}

            {hasUserRole('ROLE_SEQUENCESEARCH') && <Route path="/chemfinder/sequencesearch" component={SequenceSearchContainer} />}

            {hasUserRole('ROLE_PATHSEARCH') && <Route path="/analytics/factfinder" component={InferenceSearchContainer} />}

            <Route path="/analytics/domainexplorer/:ocid?" component={OntologyBrowserContainer} />

            {hasUserRole('ROLE_BIOMARKER') && <Route path="/analytics/biomarker" component={BiomarkerContainer} />}

            {hasUserRole('ROLE_BIOACTIVITY') && <Route path="/analytics/bioactivities" component={Bioactivities} />}

            {hasUserRole('ROLE_COOCS') && <Route path="/analytics/cooccurrences" component={CooccurrenceAnalysisContainer} />}

            {hasUserRole('ROLE_WATCHLISTS') && <Route path="/my/savedsearches" component={WatchlistsContainer} />}

            {hasUserRole('ROLE_SENTENCEANALYSIS') && <Route path="/analytics/sentenceanalysis" component={SentenceContainer} />}

            <Route path="/my/export-center" component={ExportCenterContainer} />

            {hasUserRole('ROLE_APPS') && (userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" || userData.userDetails.highestAdminType === "ROLE_COMPANY_ADMIN") && <Route path="/my/apps" component={AppContainer} />}

            {hasUserRole('ROLE_ALERTS') && <Route path="/my/alerts" component={AlertsContainer} />}

            {hasUserRole("ROLE_LIBRARY") && <Route path="/my/library" component={LibraryContainer} />}

            <Route path="/apps/:id" component={DynamicApps} />

            {APP_PROPERTIES.ACTIVE_PAGES.frameEditor && <Route path="/ocprocessor/editor" component={FrameEditorContainer} />}

            {APP_PROPERTIES.ACTIVE_PAGES.pipelineManager && <Route path="/ocprocessor/manager" component={PipelineManagerContainer} />}

            {APP_PROPERTIES.ACTIVE_PAGES.servicesStatus && <Route path="/ocprocessor/services" component={ServicesStatusContainer} />}

            {hasUserRole('ROLE_CHANGELOG') && <Route path="/changelog" component={ChangelogContainer} />}

            <Route path="/acknowledgements" component={AcknowledgementContainer} />

            {APP_PROPERTIES.APP_ID === "dimensions" && <Route path="/tutorials" component={TutorialsContainer} />}

            <Route path="/administration" component={AdministrationContainer} />

            <Route path="/my/account" component={EditRegularUser} />

            {userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN") && <Route path="/backendadmin" component={BackendAdminContainer} />}

            <Route path="/login" component={Login} />

            <Route exact path='/newPassword' component={PasswordUpdate} />

            {hasUserRole('ROLE_TRENDANALYSIS') && <Route exact path='/analytics/trendanalysis' component={TrendAnalysis} />}

            <Route path="*" component={NotFound} />
        </Switch>
    );
}
export default Routes;