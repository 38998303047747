import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { MAX_NUM_OF_FACETS } from "../docsearch/searchConstants";
import { Button } from "primereact/button";
import { isArrayEmpty, shortenLargeNumber } from "../../util";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";


class FacetsSelectionDialog extends Component {

    constructor(props) {
        super(props);

        this.growl = createRef();
    }

    includesFacet = (selectedFacets, entry) => {
        const contains = selectedFacets?.some(selEntry => entry.term === selEntry.term && entry.occurrence === selEntry.occurrence); //  && facet.occurrence === entry.occurrence
        return contains;
    }

    checkboxTemplate = (facet, layout) => {
        return (
            <div>
                <Checkbox
                    inputId={facet.term}
                    value={facet}
                    onChange={(e) => this.props.onSelectionChange(e, this.props.filterID)}
                    checked={this.includesFacet(this.props.selectedFacets, facet)}
                >
                </Checkbox>
            </div>
        );
    }

    facetTemplate = (facet, layout) => {
        return (
            <div>
                {facet.term}
            </div>
        );
    }

    occurrenceTemplate = (facet, layout) => {
        return (
            <div className="textAlignRight" title="Approximated number of documents">
                {shortenLargeNumber(facet.occurrence)}
            </div>
        );
    }


    render() {

        const { filter, selectedFacets, exampleValues, displayDialog, onHide, showOccurrences } = this.props;

        const footer = <div className="grid">
            <div className="col-12 textAlignRight" style={{ paddingRight: 25 }}>
                <Button
                    type="button"
                    label={`Add filter`}
                    className="p-button-sm primaryButton"
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                    disabled={isArrayEmpty(selectedFacets)}
                    onClick={(e) => {
                        this.props.onAddFacetsToQuery(this.props.filter);
                    }} />
            </div>
        </div>

        const headerText = exampleValues?.facets?.length < MAX_NUM_OF_FACETS ?
            `All ${exampleValues?.facets?.length} entries` : `Top ${MAX_NUM_OF_FACETS}`;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                {filter ?
                    <Dialog
                        focusOnShow={false}
                        header={`${headerText} of ${filter.label}`}
                        footer={footer}
                        visible={displayDialog}
                        className="filtersDialog styledDialog"
                        style={{ minWidth: '30vw', maxWidth: '80vw', height: '80vh' }}
                        //style={{ minWidth: '65vw', maxWidth: '90vw', minHeight: '40vh', maxHeight: '90vh' }}
                        modal={true}
                        onShow={e => {
                        }}
                        onHide={onHide}>
                        <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 30, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            {exampleValues?.facets ?
                                <DataTable
                                    className="bigFilterField"
                                    value={exampleValues.facets}
                                    responsiveLayout="scroll"
                                    showGridlines={false}
                                >
                                    <Column header="" body={this.checkboxTemplate} style={{ width: 30 }}></Column>
                                    <Column field="term" //style={{ width: '100%' }}
                                        header={filter.label} body={this.facetTemplate}
                                    filter filterField="term"
                                    filterPlaceholder={`Filter entries in this list`}
                                    ></Column>
                                    {showOccurrences &&
                                        <Column field="occurrence" header="Number of documents"
                                            body={this.occurrenceTemplate} style={{ width: 160, textAlign: 'right' }}></Column>
                                    }
                                </DataTable>
                                :
                                `Loading facets...`}
                        </div>
                    </Dialog> : null}
            </>
        );
    }
}

export default FacetsSelectionDialog;