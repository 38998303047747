/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { InputText } from 'primereact/inputtext';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import AppsTable from './components/DataTable';
import { APP_TITLE } from './core/constants';
import CreateEditApp from '../../common/App/CreateEditApp';

const AppList = (props) => {
    const { selectedCategory, onReload, onShowToast, userData, changeAlertStatus, changeActiveStatus, refreshApp } = props
    const [displayCreateAppDialog, setDisplayCreateAppDialog] = useState(false)
    const [initialData, setInitialData] = useState(null)
    const [search, setSearch] = useState('')
    const [apps, setApps] = useState(props?.apps)

    const editApp = (data) => {
        setDisplayCreateAppDialog(true)
        setInitialData(data)
    }

    useEffect(() => {
        setApps(props.apps)
    }, [props.apps])

    const searchApp = (e) => {
        const searchData = e.target.value;
        setApps(props.apps?.filter(item => item?.name?.toLowerCase()?.includes(searchData?.toLowerCase()) || item?.group?.toLowerCase()?.includes(searchData?.toLowerCase())));
        setSearch(searchData);
    }

    return (
        <div className="col-12 grid-nogutter">
            <LoadingOverlay>
                <div className='col-12 pl-0 pt-0' >
                    <div className='col-12 pl-0 py-0' >
                        {/*<div className='col-12 changeflex ex-list-header-wrapper pb-0 pl-0'>
                            <label className='ex-list-header' style={{}}
                                title={selectedCategory === APP_TITLE || (apps?.length === 0) ? 'All apps' : `Export collection: ${selectedCategory}`}>
                                {selectedCategory === APP_TITLE || (apps?.length === 0) ? 'All apps' : `Export collection: ${selectedCategory}`}</label>

    </div>*/}
                    </div>
                    <div className='col-12 sub-label-wrapper ' >
                        <label className='sub-label' title={selectedCategory === APP_TITLE || (apps?.length === 0) ? 'All available apps' : `All apps in: ${selectedCategory}`}>
                            {selectedCategory === APP_TITLE || (apps?.length === 0) ? 'All available apps' : `All apps in: ${selectedCategory}`}
                        </label>
                        {userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' ?
                            <a style={{ float: 'right' }} onClick={() => {
                                setDisplayCreateAppDialog(true)
                                setInitialData({ start: true })
                            }}>New app</a>
                            : null
                        }
                    </div>
                    {

                        apps?.length > 0 || (apps?.length === 0 && search !== '') ?
                            <>
                                <div className='grid ex-list-search-wrapper' >
                                    <div className="ex-list-search-con">
                                        <i className="material-icons search-icon"
                                            title={selectedCategory === APP_TITLE ? "Search app name" : "Search app name"}
                                        >
                                            {'search'}
                                        </i>
                                        <InputText
                                            type="search"
                                            value={search}
                                            autoComplete="off"
                                            className="collectionFilter ex-list-search"
                                            onInput={searchApp}
                                            placeholder={selectedCategory !== APP_TITLE ? "Search app name" : "Search app name"}
                                        />
                                    </div>
                                </div>
                                <div className="datatable-responsive-demo-l">
                                    <AppsTable
                                        //updateExports={updateExports}
                                        apps={apps}
                                        onReload={onReload}
                                        onErrorToast={onShowToast}
                                        editApp={editApp}
                                        changeAlertStatus={changeAlertStatus}
                                        changeActiveStatus={changeActiveStatus}
                                        refreshApp={refreshApp}
                                        userData={userData}

                                    />
                                </div>
                            </> :
                            <div style={{ paddingTop: 10 }}  >
                                <label style={{ fontWeight: 'bold' }}>
                                    {selectedCategory === APP_TITLE ? 'No apps available.' : ` No apps in : ${selectedCategory}.`}
                                </label>
                            </div>
                    }
                </div>

            </LoadingOverlay>


            <CreateEditApp
                userData={userData}
                displayDialog={displayCreateAppDialog}
                onHide={(e) => {
                    setDisplayCreateAppDialog(false)
                    setTimeout(() => {
                        setInitialData(null)
                    }, 500);
                }}
                onShowToast={onShowToast}
                onReload={onReload}
                initialData={initialData}
                isEdit={!!initialData && initialData?.start === undefined}
            />

        </div >
    )
}

export default AppList;
