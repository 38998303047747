import { addThousandsSeparatorToNumber } from "../util";


const ExportLimit = ({ maximumEntriesToExport }) => {

    return (
        maximumEntriesToExport ?
            <span>
                {`* The current limit is set to `}
                <b>{addThousandsSeparatorToNumber(maximumEntriesToExport)}</b>
                {` ${maximumEntriesToExport === 1 ? 'entry' : 'entries'}`}
            </span> : null
    );
}

export default ExportLimit;