import { SEARCH_TARGET_OPTIONS } from "./chemistryDBs";
import { CHEMISTRY_SEARCH_FILTER_ID_ABS_MOL_WEIGHT, CHEMISTRY_SEARCH_FILTER_ID_C_LOG_P, CHEMISTRY_SEARCH_FILTER_ID_H_ACCEPTORS, CHEMISTRY_SEARCH_FILTER_ID_H_DONORS, CHEMISTRY_SEARCH_FILTER_ID_MIN_SIMILARITY, CHEMISTRY_SEARCH_FILTER_ID_MIN_YIELD, CHEMISTRY_SEARCH_FILTER_ID_MOL_WEIGHT, CHEMISTRY_SEARCH_FILTER_ID_REL_MOL_WEIGHT, CHEMISTRY_SEARCH_FILTER_ID_ROTATABLE_BOND_COUNT, CHEMISTRY_SEARCH_FILTER_ID_SOLUBILITY, CHEMISTRY_SEARCH_FILTER_ID_SURFACE_AREA } from "./chemistrySearchConstants";


export const extractFilterValues = (filterInputValues, filterDefinitions) => {

    const filters = {};
    if (filterInputValues) {
        const type = getSearchTypeFromFilterValues(filterInputValues);
        //console.log('type: ', type);
        if (type === 'similarity') {
            addNumFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_MIN_SIMILARITY], 'similarityScore', filters);
            if (filters['similarityScore']) {
                filters['similarityScore'] = filters['similarityScore'] / 100;
            }
            else if (filterDefinitions && filterDefinitions[CHEMISTRY_SEARCH_FILTER_ID_MIN_SIMILARITY]?.defaultValue) {
                filters['similarityScore'] = filterDefinitions[CHEMISTRY_SEARCH_FILTER_ID_MIN_SIMILARITY].defaultValue / 100;
            }
        }
        addNumFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_MIN_YIELD], 'minYield', filters);

        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_MOL_WEIGHT], 'minMolWeight', 'maxMolWeight', filters);
        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_ABS_MOL_WEIGHT], 'minAbsoluteMolWeight', 'maxAbsoluteMolWeight', filters);
        //addNumRangeFilter(filterInputValues['logP'], 'minLogP', 'maxLogP', filters);
        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_C_LOG_P], 'minCLogP', 'maxCLogP', filters);
        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_H_DONORS], 'minNumOfHydrogenDonors', 'maxNumOfHydrogenDonors', filters);
        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_H_ACCEPTORS], 'minNumOfHydrogenAcceptors', 'maxNumOfHydrogenAcceptors', filters);
        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_ROTATABLE_BOND_COUNT], 'minNumOfRotatableBonds', 'maxNumOfRotatableBonds', filters);
        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_REL_MOL_WEIGHT], 'minRelativeMolWeight', 'maxRelativeMolWeight', filters);
        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_SOLUBILITY], 'minSolubility', 'maxSolubility', filters);
        addNumRangeFilter(filterInputValues[CHEMISTRY_SEARCH_FILTER_ID_SURFACE_AREA], 'minTotalSurfaceArea', 'maxTotalSurfaceArea', filters);

        return { origSearchType: type, filters };
    }
    return filters;
}

export const getSearchTypeFromFilterValues = (filterInputValues) => {
    return filterInputValues?.searchType?.value?.filterValue;
}

export const includeWithMixtures = (filterInputValues, selChemDB) => {
    const includeWithMixtures = selChemDB?.allowIncludeMixtures;
    return includeWithMixtures;
}

export const getSearchTargetParameter = (searchTarget) => {
    return SEARCH_TARGET_OPTIONS[searchTarget]?.queryParameter;
}

const addNumFilter = (filterValues, paramName, filters = {}) => {
    if (filterValues?.value) {
        filters[paramName] = parseFloat(filterValues.value);
    }
}

const addNumRangeFilter = (filterValues, minParamName, maxParamName, filters = {}) => {
    if (filterValues?.minValue) {
        filters[minParamName] = parseFloat(filterValues.minValue);
    }
    if (filterValues?.maxValue) {
        filters[maxParamName] = parseFloat(filterValues.maxValue);
    }
}