import { isArrayEmpty } from "../util";


/**
 * 
 * @param {*} sourceReferences 
 * @returns 
 */
export const createSourcesLinkoutMenuItems = (sourceReferences, openInNewTab = true) => {

    if (isArrayEmpty(sourceReferences)) {
        return [];
    }

    const items = [];

    const sourceReferencesItems = {
        label: 'Concept sources',
        items: sourceReferences.map(srcRef => {
            return {
                label: srcRef.sourceName,
                command: () => { window.open(srcRef.conceptUrl, openInNewTab ? '_blank' : undefined) }
            }
        })
    }
    items.push(sourceReferencesItems);

    return items;
}