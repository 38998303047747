import React, { useMemo, useState } from "react";
import {
  abbreviateText,
  createDomainLabelsArray,
  isFreeText,
} from "../../../../../../../webapi/queryBuilder/helpers/advancedSearch";
import { getQueryTermID } from '../../../../../../../webapi/queryBuilder/helpers/getQueryTermID';
import SearchGroupCriteriaCompact from "../../SearchGroupCriteriaCompact";
import TruncateEntriesLink from "../../../common/TruncateEntriesLink";

const TermGroupCompact = ({
  domainLabelsMap,
  filterTermsListMax,
  queryTerms,
  operator,
  searchCriteria,
}) => {
  const [showAll, setShowAll] = useState(false);

  const totalNumberOfTerms = queryTerms.length;
  const queryTermsTruncated = useMemo(
    () => (showAll ? queryTerms : queryTerms.slice(0, filterTermsListMax)),
    [showAll, queryTerms, filterTermsListMax]
  );

  return (
    <div>
      {searchCriteria && <SearchGroupCriteriaCompact searchCriteria={searchCriteria} />}
      <div className="flex align-items-center flex-wrap line-height-3">
        {queryTermsTruncated.map((queryTerm, index) => {
          const domainLabels = createDomainLabelsArray(
            queryTerm.domains,
            domainLabelsMap
          );
          const isText = isFreeText(queryTerm.domains);
          const domainLabelsString = domainLabels?.join(", ") || "";
          const term = abbreviateText(queryTerm.term);
          const key = getQueryTermID(queryTerm);

          return (
            <React.Fragment key={key}>
              <div title={`${queryTerm.term} (${domainLabelsString})`}>
                <span style={{ fontWeight: isText ? "normal" : "bold" }}>
                  {term}
                </span>
              </div>
              {index < totalNumberOfTerms - 1 && (
                <div>
                  <span className="search-group-operator">
                    {operator?.label || operator}
                  </span>
                </div>
              )}
            </React.Fragment>
          );
        })}
        <TruncateEntriesLink
          visible={filterTermsListMax < queryTerms.length}
          numberOfEntries={queryTerms.length}
          showAll={showAll}
          onClick={setShowAll}
        />
      </div>
    </div>
  );
};

export default TermGroupCompact;
