import './Filters.css';
import { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { TreeSelect } from "primereact/treeselect";
import { isArrayEmpty } from '../../util';


class FilterSectionTreeSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }


    componentDidUpdate() {
        const termlocTreeSelect = !isArrayEmpty(document.getElementsByClassName('p-treeselect-panel')) ?
            document.getElementsByClassName('p-treeselect-panel')[0] : null;
        if (termlocTreeSelect && !termlocTreeSelect.id) {
            termlocTreeSelect.id = 'termlocTreeSelect';
        }
    }


    render() {

        const { filterID, filterEntries, filterEntriesData, placeholder, selectedEntry, filterInfo, onFilterInputValuesChange, showClear = true } = this.props;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className="grid">
                    <div className="col">
                        {filterEntries ?
                            <div key={filterID} style={{ marginLeft: 0 }}>

                                <TreeSelect
                                    id="termlocTreeSelect"
                                    value={selectedEntry?.filterValue || ''}
                                    onChange={(e) => {
                                        const filterObject = e.value && e.value !== 'clear' ? {
                                            filterID: filterID,
                                            value: filterEntriesData[e.value],
                                        } : {};
                                        onFilterInputValuesChange(filterObject);
                                    }}
                                    options={filterEntries}
                                    filter
                                    filterBy="filterField"
                                    className="termloc-tree-select"
                                    placeholder="No restriction (whole document)"
                                    appendTo={document.body}
                                >
                                </TreeSelect>
                            </div> : null
                        }
                    </div>
                    {filterInfo ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {filterInfo}
                        </div> : null}
                </div>
            </>
        );
    }
}

export default FilterSectionTreeSelect;