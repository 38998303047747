import dateFormat from 'dateformat';


export const EXPORT_CENTER_CATEGORY_CHEMISTRY = "chem_export";
export const EXPORT_CENTER_CATEGORY_DOCUMENT_METADATA = "metadata_export";
export const EXPORT_CENTER_CATEGORY_SEMANTIC = "semantic_export";

const ACTIVE_CATEGORIES = {
    [EXPORT_CENTER_CATEGORY_CHEMISTRY]: true,
    [EXPORT_CENTER_CATEGORY_DOCUMENT_METADATA]: true,
    [EXPORT_CENTER_CATEGORY_SEMANTIC]: true,
};

/**
 * Creates the name for a download in the export center. 
 * Consisting of a prefix, depending on the download category as well as the current time.
 * @param {*} category export category; use constants, e.g EXPORT_CENTER_CATEGORY_CHEMISTRY, EXPORT_CENTER_CATEGORY_DOCUMENT_METADATA
 * @returns export center entry name
 */
export const createExportCenterEntryName = (category) => {
    const type = ACTIVE_CATEGORIES[category] ? category : "export";
    return `${type}_${dateFormat(new Date(), 'yyyy-mm-dd_HH-MM-ss')}`;
}