import * as ActionTypes from './index'
import * as ManagerApi from '../../api/ManagerApi'
import * as Actions from '../actions';


export const fetchCartridges = () => dispatch => {
  ManagerApi.fetchCartridges().then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(addAllCartridges(answer.payload))
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Failed to fetch cartdiges", "Was not able to fetch cartridges. Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not fetch cartridges.', answer)
    }
  })
}

export const fetchCartridge = (name) => dispatch => {
  ManagerApi.fetchCartridge(name).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Failed to download cartdige", "Was not able to download cartridge " + name + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not download cartridge ' + name + '.', answer)
    }
  });
};

export const rmCartridge = (name) => dispatch => {
  dispatch(lockFrontend(true));
  dispatch(setCartridgeDeleting(true));
  ManagerApi.rmCartridge(name).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(fetchCartridges());
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Could not delete cartridge", "Was not able to delete cartridge " + name + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not delete cartridge ' + name, answer)
    }
    dispatch(setCartridgeDeleting(false));
  })
};

export const uploadCartridge = (file) => dispatch => {
  dispatch(lockFrontend(true));
  dispatch(setCartridgeUploading(file.name));
  ManagerApi.uploadCartridge(file).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(fetchCartridges());
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Could not upload cartridge", "Was not able to upload cartridge from given file. Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not upload cartridge', answer)
    }
    dispatch(setCartridgeUploading(""));
  })
};

export const installAvailableCartridge = (url) => dispatch => {
  dispatch(lockFrontend(true));
  dispatch(setCartridgeInstallingAvailable(true));
  ManagerApi.installAvailableCartridge(url).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(fetchCartridges());
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Could not install cartridge", "Was not able to install cartridge " + url + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not install cartridge ' + url, answer)
    }
    dispatch(setCartridgeInstallingAvailable(false));
  })
};

export const addCartridgeDomain = (cartDomain) => dispatch => {
  //dispatch(lockFrontend(true));
  dispatch(setCartridgeDomainAdding(true));
  ManagerApi.addCartridgeDomain(cartDomain).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(Actions.isLoggedIn()); // history
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Could not load domain information", "Was not able to load domain information for " + cartDomain?.name + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not load domain information ' + cartDomain?.name, answer)
    }
    dispatch(setCartridgeDomainAdding(false));
  })
}

export const editCartridgeDomain = (cartDomain) => dispatch => {
  //dispatch(lockFrontend(true));
  dispatch(setCartridgeDomainEditing(true));
  ManagerApi.editCartridgeDomain(cartDomain).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(Actions.isLoggedIn()); // history
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Could not load domain information", "Was not able to load domain information for " + cartDomain?.name + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not load domain information ' + cartDomain?.name, answer)
    }
    dispatch(setCartridgeDomainEditing(false));
  })
}

export const addAllCartridges = cartridges => ({
  type: ActionTypes.ADD_ALL_CARTRIDGES,
  cartridges
});
export const setCartridgeUploading = (name) => ({
  type: ActionTypes.SET_CARTRIDGE_UPLOADING,
  cartridgeName: name
});
export const setCartridgeDeleting = (deleting) => ({
  type: ActionTypes.SET_CARTRIDGE_DELETING,
  cartridgeDeleting: deleting
});
export const setCartridgeInstallingAvailable = (deleting) => ({
  type: ActionTypes.SET_CARTRIDGE_INSTALL_AVAILABLE,
  cartridgeInstallingAvailable: deleting
});
export const setCartridgeDomainAdding = (adding) => ({
  type: ActionTypes.SET_CARTRIDGE_DOMAIN_ADDING,
  cartridgeDomainAdding: adding
});
export const setCartridgeDomainEditing = (editing) => ({
  type: ActionTypes.SET_CARTRIDGE_DOMAIN_EDITING,
  cartridgeDomainEditing: editing
});


export const fetchAvailableRepositories = () => dispatch => {
  ManagerApi.fetchRepositories().then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(addAllAvailableRepositories(answer.payload))
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Failed to fetch repositories", "Was not able to fetch repositories. Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not fetch repositories.', answer)
    }
  })
};

export const deleteRepository = (name) => dispatch => {
  dispatch(lockFrontend(true));
  dispatch(setRepositoryDeleting(true));
  ManagerApi.deleteRepository(name).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        setTimeout(() => {
          dispatch(fetchAvailableRepositories())
          dispatch(showMessage("Success", "Deleted repository " + name + ".", ActionTypes.GROWL_SUCCESS))
        }, 1000);
        break
      case 'FAILED':
      default:
        dispatch(showMessage("Failed to delete repository", "Was not able to delete repository " + name + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not delete repository ' + name + '.', answer)
    }
    dispatch(setRepositoryDeleting(false));
  });
};


export const addAllAvailableRepositories = repositories => ({
  type: ActionTypes.ADD_ALL_REPOSITORIES_MANAGER,
  repositories
});
export const setRepositoryDeleting = (deleting) => ({
  type: ActionTypes.SET_REPOSITORY_DELETING,
  repositoryDeleting: deleting
});




export const annotateDocument = (name, file) => dispatch => {
  dispatch(displayAnnotationProcessing(name));
  const annotationStart = Date.now();
  ManagerApi.annotateDocument(name, file).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        if (Date.now() - annotationStart < 500)
          setTimeout(() => dispatch(displayAnnotationResults(answer.payload)), 500 - (Date.now() - annotationStart));
        else
          dispatch(displayAnnotationResults(answer.payload))
        setTimeout(() => dispatch(fetchAvailableRepositories()), 5500);
        break
      case 'FAILED':
      default:
        dispatch(displayAnnotationResults({}))
        dispatch(showMessage("Failed to annotate document", "Was not able to annotate " + name + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
        console.log('Could not annotate ' + name + '.', answer)
    }
  })
};


export const displayAnnotationProcessing = (name) => ({
  type: ActionTypes.SET_ANNOTATION_SUBMISSION,
  filename: name
});


export const displayAnnotationResults = annotationResults => ({
  type: ActionTypes.DISPLAY_ANNOTATION_RESULTS,
  annotationResults: annotationResults
});




export const annotateResource = (description, filePath = ".", jobsCount, jobsPage) => dispatch => {
  ManagerApi.annotateResource({ description: description, filePath: filePath }).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(showMessage("Job submitted", "Your annotation job was submitted to the backend.", ActionTypes.GROWL_SUCCESS))
        console.log("fetching", jobsCount, jobsPage)
        dispatch(fetchJobs(jobsCount, jobsPage));
        break;
      case 'FAILED':
      default:
        dispatch(showMessage("Failed to annotate resources", "Was not able to submit your annotation job. Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify({ description: description, filePath: filePath, answer: answer }, null, 2)));
        console.log('Could not annotate resources.', answer)
    }
  })
};





export const showMessage = (summary, detail, severity, debug) => ({
  type: ActionTypes.SHOW_NOTIFICATION,
  severity: severity,
  summary: summary,
  detail: detail,
  debug: debug,
  // not necessary?
  sticky: severity === 'error',
  closable: severity === 'error'
});


/**
  * Show and log growl messages.
  * 
  * You can provide an additional debug message, that will only be shown in the messages' log, but not via growl
  *
  * @param summary, brief description of the notification
  * @param detail, the notification message
  * @param severity, one of `["success", "info", "warn", "error"]`, defaults to "info"
  * @param debug, optional string with debug information
  * 
  */
export function showGrowl(summary, detail, severity = "info", debug = "") {
  return showMessage(summary, detail, severity, debug);
};




export const fetchJobs = (count, page, delay = 0) => dispatch => {
  if (delay > 0)
    dispatch(setJobsLoading(true));
  const loadingStart = Date.now();
  ManagerApi.fetchJobs(count, page).then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        if (Date.now() - loadingStart < delay)
          setTimeout(() => dispatch(setJobsLoading(false)), delay - (Date.now() - loadingStart));
        else
          dispatch(setJobsLoading(false))
        dispatch(updateJobs(answer.payload))
        break
      case 'FAILED':
      default:
        if (answer.statusCode !== 401) {
          dispatch(showMessage("Failed to retrieve jobs", "Was not able to retrieve jobs from middleware. Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
          console.log('Could not retrieve jobs.', answer)
        }
    }
  });


  ManagerApi.fetchActiveJobs().then(answer => {
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(updateActiveJobs(answer.payload))
        break
      case 'FAILED':
      default:
        if (answer.statusCode !== 401) {
          dispatch(showMessage("Failed to retrieve active jobs", "Was not able to retrieve active jobs from middleware. Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
          console.log('Could not retrieve active jobs.', answer)
        }
    }
  });

};

export const deleteJobs = (jobsCount, jobsPage, jobDescriptions, annotationJobIds = "") => dispatch => {
  ManagerApi.deleteJobs(annotationJobIds).then(answer => {
    dispatch(fetchJobs(jobsCount, jobsPage));
    switch (answer.status) {
      case 'SUCCESS':
        dispatch(showMessage("Successfully deleted jobs", "Deleted " + jobDescriptions, ActionTypes.GROWL_SUCCESS))
        break
      case 'FAILED':
      default:
        if (answer.statusCode !== 401) {
          dispatch(showMessage("Failed to delete jobs", "Was not able to delete " + jobDescriptions + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
          console.log('Could not delete jobs: ' + jobDescriptions, answer)
        }
    }
  });
};

export const getJobErrors = (jobId) => dispatch => {
  ManagerApi.getJobErrors(jobId).then(answer => {
    console.log(answer)
    switch (answer.status) {
      case 'SUCCESS':
        //dispatch (showMessage ("Successfully deleted jobs", "Deleted " + jobDescriptions, ActionTypes.GROWL_SUCCESS))
        dispatch(addJobErrors(answer.payload))
        break
      case 'FAILED':
      default:
        if (answer.statusCode !== 401) {
          dispatch(showMessage("Failed to get errors for job " + jobId, "Was not able to get errors for job " + jobId + ". Response: " + answer.statusCode + " - " + answer.message, ActionTypes.GROWL_ERROR, JSON.stringify(answer, null, 2)))
          console.log('Could not get errors for job ' + jobId, answer)
        }
    }
  });
};



export const setJobsLoading = loading => ({
  type: ActionTypes.JOBS_LOADING,
  jobsLoading: loading
});

export const updateJobs = jobs => ({
  type: ActionTypes.UPDATE_JOBS,
  jobsResult: jobs
});

export const updateActiveJobs = jobs => ({
  type: ActionTypes.UPDATE_ACTIVE_JOBS,
  jobsResult: jobs
});

export const addJobErrors = err => ({
  type: ActionTypes.ADD_JOB_ERRORS,
  jobsResult: err
});


export const changeManagerTab = managerTab => ({
  type: ActionTypes.CHANGE_MANAGER_TAB,
  managerTab: managerTab
});


export const lockFrontend = lock => ({
  type: ActionTypes.LOCK_FRONTEND,
  lockFrontend: lock
});
