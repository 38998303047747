import React from 'react';
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";

const SearchTemplate = ({
    toolTip,
    hasTooltip,
    queryTerms,
    truncatedTerm,
    info,
    hasDropDown,
    dropdownValue,
    dropdownClassName,
    dropdownOptions,
    item, handleInputChange,
    showToast
}) => {

    const groupedItemTemplate = (option) => {
        return (
            <div style={{
                paddingBottom: 3, paddingLeft: 5, paddingTop: option.label === "Filter" ? 3 : 0
            }}>
                <div style={{ fontWeight: 'bold', fontSize: 13 }}>{option.label}</div>
            </div>
        );
    }

    const itemTemplate = (item) => {
        return (
            <div className="grid" style={{ paddingLeft: 10 }}>
                <div style={{ fontSize: 13 }} title={item.tooltip}>{item.label}</div>
            </div>
        );
    }

    const onDropdownFieldChange = (e, item) => {
        //const { showToast } = this.props;
        const terms = [...queryTerms];
        const targetIndex = queryTerms.findIndex(term => term === item);
        const targetCopy = { ...terms[targetIndex] };
        const { conceptLookupType, termLookupType } = e.value;

        if (conceptLookupType) {
            targetCopy.conceptLookupType = e.value.conceptLookupType;
            delete targetCopy.termLookupType;
        }

        if (termLookupType) {
            targetCopy.termLookupType = e.value.termLookupType;
            delete targetCopy.conceptLookupType;
        }

        targetCopy.occurrence = e.value.occurrence;
        terms[targetIndex] = targetCopy;

        if (queryTerms && queryTerms.length > 0 && e.value.occurrence === "MUST") {
            let filteredObject = queryTerms.filter(function (entry) {
                return entry.term[0] === "Any" && entry.domainTerms
            })
            let filteredItemObject = filteredObject.filter(function (entryItem) {
                return entryItem.fieldName === item.fieldName
            })
            if (filteredItemObject.length > 0) {
                showToast(`You already used the "Any" filter for this role. The specific term you want to filter will be ignored for your search.`, 'Warning', 'warn');
            }
        }


        handleInputChange(terms);
    }

    return  <div >


    <a className="noStyleLink"
    // title="Click to specify concepts"
    >
        <div style={{ fontWeight: 'bold', marginTop: 3, cursor: "default" }} id='search-item' className='search-item' data-pr-tooltip={toolTip} data-pr-position="top">
            {hasTooltip && <Tooltip target='.search-item' />}
            {truncatedTerm}</div>
        {!!info &&
            <div style={{ opacity: 0.7, fontSize: '0.8em', marginTop: 2, cursor: "default" }}>{info}</div>
        }
    </a>


        {hasDropDown && <div>
            <Dropdown
                className={dropdownClassName}
                value={dropdownValue}
                options={dropdownOptions}
                onChange={(e) => onDropdownFieldChange(e, item)}
                placeholder="Select variant"
                optionLabel='label'
                optionGroupChildren='items'
                optionGroupLabel='label'
                optionGroupTemplate={groupedItemTemplate}
                itemTemplate={itemTemplate}
                scrollHeight="250px"
                appendTo='self'
                style={{
                    fontSize: '0.8em', marginTop: -5, marginLeft: -7,
                    marginBottom: -4
                }}>
            </Dropdown>
        </div>}
    </div>;
};

export default SearchTemplate;
