import React, { Component, createRef } from 'react';
import { Button } from 'primereact/button';
//import { Dropdown } from 'primereact/dropdown';
import { ContextMenu } from 'primereact/contextmenu';
import { Dialog } from 'primereact/dialog';
import PhraseTaggerOutput from '../tagger/PhraseTaggerOutput';
import SampleText from '../../general/SampleText';
import PhraseTokenDisplay from './PhraseTokenDisplay';
import EditTokenDialog from './EditTokenDialog';
import {
    runPhraseTagger,
} from '../../../../../api/content/FrameEditorApi';
import { removeValueFromArray, cloneDeep, isArrayEmpty } from '../../../util';
import { checkResultAndGetPayload } from '../../../../../api';
import * as OntologyClassUtil from '../../util/OntologyClassUtil';
import * as PhraseTokenUtil from '../../util/PhraseTokenUtil';
import * as IDGeneratorUtil from '../../util/IDGeneratorUtil';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { Toast } from 'primereact/toast';
//import { SelectButton } from 'primereact/selectbutton';


class SyntaxEditor extends Component {

    constructor(props) {

        //console.log('constructor: ', props.type);

        super(props);

        const tokenMenuItemEditPhraseToken = {
            label: 'Edit phrase token', value: 'token',
            command: (e) => { this.onEditPhraseToken(this.state.selToken); }
        };
        const tokenMenuItemDeleteToken = {
            label: 'Delete token', value: 'delete',
            command: (e) => {
                const newSyntaxEditorContent = this.doDeleteToken(this.state.syntaxEditorContent, this.state.selToken);
                this.setState({
                    syntaxEditorContent: newSyntaxEditorContent,
                    selToken: null
                });
            }
        };
        /*const tokenMenuItemClearGroup = {
            label: 'Delete tokens in group', value: 'clear',
            command: (e) => {
                const newSyntaxEditorContent = this.doDeleteGroup(this.state.syntaxEditorContent, this.state.selToken);
                this.setState({
                    syntaxEditorContent: newSyntaxEditorContent,
                    selToken: null
                });
            }
        };*/
        const tokenMenuItemEditGroup = {
            label: 'Edit group', value: 'edit',
            command: (e) => { this.onEditGroup(); }
        };
        const tokenMenuItemDeleteGroupAndTokens = {
            label: 'Delete group & tokens', value: 'delete',
            command: (e) => {
                //console.log(event);
                const newSyntaxEditorContent = this.doDeleteGroup(this.state.syntaxEditorContent, this.state.selToken);
                this.setState({
                    syntaxEditorContent: newSyntaxEditorContent,
                    selToken: null
                });
            }
        };
        /*const tokenMenuItemRemoveSurroundingGroup = {
            label: 'Delete group & keep tokens', value: 'delete',
            command: (e) => {
                const newSyntaxEditorContent = this.doDeleteGroup(this.state.syntaxEditorContent, this.state.selToken);
                this.setState({
                    syntaxEditorContent: newSyntaxEditorContent,
                    selToken: null
                });
            }
        };*/

        this.itemsTypeOther = [
            tokenMenuItemEditPhraseToken,
            tokenMenuItemDeleteToken
        ];
        this.itemsTypeGroup = [
            tokenMenuItemEditGroup,
            tokenMenuItemDeleteGroupAndTokens,
        ];

        this.state = {
            sampleText: 'The cathode material has a thickness of 1.1 nm and the thermal expansion coefficient (TEC) of YSZ is 10 °C-1.',
            saveItems: [],
            cmItems: [],
            activeTagType: OntologyClassUtil.TAG_TYPE_SYNTAX,

            syntaxEditorContent: [],
            selToken: null
        };

        this.moveData = {};

        this.growl = createRef();
    }


    // ----------------------------------------------------------------------- //
    // --- clear & more ------------------------------------------------------ //
    // ----------------------------------------------------------------------- //
    onCheckSyntax = () => {
        this.props.onCheckSyntax();
    }

    onClearSyntaxEditor = () => {
        this.setState({
            syntaxEditorContent: [],
            selToken: null
        });
    }


    // ----------------------------------------------------------------------- //
    // --- token/group clicked ----------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onTokenDoubleClick = (token, event) => {

        //console.log('onTokenDoubleClick: ', event);

        this.setState({
            selToken: token
        }, () => this.onEditPhraseToken(token));

        // --- do not pass event to outer groups -> select inner group --- //
        event.cancelBubble = true;
        if (event.stopPropagation)
            event.stopPropagation();
    }


    onTokenClick = (token, event) => {

        //console.log('onTokenClick: ', event);

        let selToken = null;
        if (!this.state.selToken || this.state.selToken !== token) {
            selToken = token;
        }

        this.setState({
            selToken: selToken
        });

        // --- do not pass event to outer groups -> select inner token --- //
        event.cancelBubble = true;
        if (event.stopPropagation)
            event.stopPropagation();
    }

    onTokenRightClick = (token, event) => {
        //console.log('onTokenRightClick: ', token);

        let items = this.itemsTypeOther;

        this.setState({
            cmItems: items,
            selToken: token
        });

        this.cm.show(event);
    }

    onGroupClick = (token, event) => {

        //console.log('onGroupClick: ', token);

        let selToken = null;
        if (!this.state.selToken || this.state.selToken !== token) {
            selToken = token;
        }

        this.setState({
            selToken: selToken
        });

        // --- do not pass event to outer groups -> select inner group --- //
        event.cancelBubble = true;
        if (event.stopPropagation)
            event.stopPropagation();
    }

    onGroupRightClick = (token, event) => {
        //console.log('onGroupRightClick: ', token);

        let items = [];
        switch (token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE][0].type) {
            case 'GROUP': items = this.itemsTypeGroup; break;
        }

        this.setState({
            cmItems: items,
            selToken: token
        });

        this.cm.show(event);
    }

    onGroupDoubleClick = (token, event) => {

        this.setState({
            selToken: token
        }, () => this.onEditPhraseToken(token, true));
    }


    // ----------------------------------------------------------------------- //
    // --- move token -------------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Moves token object (phrase token or group) one position to the left or right.
     */
    onMoveToken = (tokenToMove, moveLeft) => {
        const newSyntaxEditorContent = [...this.state.syntaxEditorContent];

        this.moveToken(newSyntaxEditorContent, tokenToMove, null, moveLeft, newSyntaxEditorContent, true);

        this.setState({
            syntaxEditorContent: newSyntaxEditorContent,
            selToken: tokenToMove
        });
    }

    /**
     * Moves token object (phrase token or group) one position to the left or right.
     */
    moveToken = (phraseTokens, tokenToMove, parentGroup, moveLeft, syntaxEditorContent, go) => {

        if (phraseTokens) {
            for (let index of Object.keys(phraseTokens)) {

                if (!go) { return false; }

                const token = phraseTokens[index];
                if (token === tokenToMove) {
                    if (moveLeft) {
                        const newIndex = --index;
                        // --- token has left neighbor --- //
                        if (index >= 0) {
                            const leftNeighbor = phraseTokens[newIndex];

                            // --- remove token first --- //
                            removeValueFromArray(phraseTokens, tokenToMove);

                            // --- left neighbor is a group --- //
                            if (PhraseTokenUtil.isGroupToken(leftNeighbor)) {
                                if (!leftNeighbor.groupMembers) {
                                    leftNeighbor.groupMembers = [];
                                }
                                leftNeighbor.groupMembers.push(tokenToMove);
                            }
                            // --- left neighbor is a token --- //
                            else {
                                this.addTokenAtPositionOfToken(syntaxEditorContent, tokenToMove, leftNeighbor);
                            }
                        }
                        // --- token has NO left neighbor but a parent group --- //
                        else if (index < 0 && parentGroup) {
                            // --- remove token first --- //
                            removeValueFromArray(phraseTokens, tokenToMove);

                            // add at index of parent group
                            this.addTokenAtPositionOfToken(syntaxEditorContent, tokenToMove, parentGroup);
                        }
                    }
                    else {
                        const newIndex = ++index;
                        // --- token has right neighbor --- //
                        if (index < phraseTokens.length) {
                            const rightNeighbor = phraseTokens[newIndex];

                            // --- remove token first --- //
                            removeValueFromArray(phraseTokens, tokenToMove);

                            // --- right neighbor is a group --- //
                            if (PhraseTokenUtil.isGroupToken(rightNeighbor)) {
                                if (!rightNeighbor.groupMembers) {
                                    rightNeighbor.groupMembers = [];
                                }
                                rightNeighbor.groupMembers.unshift(tokenToMove);
                            }
                            // --- right neighbor is a token --- //
                            else {
                                this.addTokenAfterToken(syntaxEditorContent, tokenToMove, rightNeighbor);
                            }
                        }
                        // --- token has NO right neighbor but a parent group --- //
                        else if (index >= phraseTokens.length && parentGroup) {
                            // --- remove token first --- //
                            removeValueFromArray(phraseTokens, tokenToMove);

                            // add at index of parent group
                            this.addTokenAfterToken(syntaxEditorContent, tokenToMove, parentGroup);
                        }
                    }
                    // --- after updating token position -> stop immidiately --- //
                    return false;
                }
                else if (PhraseTokenUtil.isGroupToken(token)) {
                    go = this.moveToken(token.groupMembers, tokenToMove, token, moveLeft, syntaxEditorContent, go);
                }
            }
        }
        return true;
    }


    // ----------------------------------------------------------------------- //
    // --- add, replace or delete token -------------------------------------- //
    // ----------------------------------------------------------------------- //
    addTokenAtPositionOfToken(phraseTokens, tokenToAdd, tokenToFind) {
        if (phraseTokens) {
            for (const index of Object.keys(phraseTokens)) {
                const token = phraseTokens[index];
                if (token === tokenToFind) {
                    phraseTokens.splice(index, 0, tokenToAdd);
                    return;
                } else if (PhraseTokenUtil.isGroupToken(token)) {
                    this.addTokenAtPositionOfToken(token.groupMembers, tokenToAdd, tokenToFind);
                }
            }
        }
    }


    /**
     * Adds a token to the position after a certain token.
     */
    doAddTokenAfterToken = (syntaxEditorContent, token, tokenToFind) => {
        const newSyntaxEditorContent = syntaxEditorContent ? [...syntaxEditorContent] : [];
        if (token) {
            if (tokenToFind) {
                this.addTokenAfterToken(newSyntaxEditorContent, token, tokenToFind);
            }
            else {
                newSyntaxEditorContent.push(token);
            }
            return newSyntaxEditorContent;
        }
        return syntaxEditorContent;
    }

    addTokenAfterToken = (phraseTokens, tokenToAdd, tokenToFind) => {
        if (phraseTokens) {
            for (let index of Object.keys(phraseTokens)) {
                const token = phraseTokens[index];
                if (token === tokenToFind) {
                    const newIndex = ++index;
                    phraseTokens.splice(newIndex, 0, tokenToAdd);
                    return;
                } else if (PhraseTokenUtil.isGroupToken(token)) {
                    this.addTokenAfterToken(token.groupMembers, tokenToAdd, tokenToFind);
                }
            }
        }
    }


    /**
     * Replaces a token with another token.
     */
    doReplaceToken = (syntaxEditorContent, tokenNew, tokenToReplace) => {
        if (tokenNew && tokenToReplace) {
            const newSyntaxEditorContent = syntaxEditorContent ? [...syntaxEditorContent] : [];
            this.replaceToken(newSyntaxEditorContent, tokenNew, tokenToReplace);
            return newSyntaxEditorContent;
        }
        return syntaxEditorContent;
    }

    replaceToken = (phraseTokens, tokenNew, tokenToReplace) => {
        if (phraseTokens) {
            for (const index of Object.keys(phraseTokens)) {
                const token = phraseTokens[index];
                if (token === tokenToReplace) {
                    phraseTokens[index] = tokenNew;
                    return;
                } else if (PhraseTokenUtil.isGroupToken(token)) {
                    this.replaceToken(token.groupMembers, tokenNew, tokenToReplace);
                }
            }
        }
    }


    /**
     * Deletes a token.
     */
    doDeleteToken = (syntaxEditorContent, tokenToDelete) => {
        if (tokenToDelete) {
            const newSyntaxEditorContent = syntaxEditorContent ? [...syntaxEditorContent] : [];
            this.deleteToken(newSyntaxEditorContent, tokenToDelete);
            return newSyntaxEditorContent;
        }
        return syntaxEditorContent;
    }

    deleteToken = (syntaxEditorContent, tokenToDelete) => {
        if (syntaxEditorContent) {
            for (const index of Object.keys(syntaxEditorContent)) {
                const token = syntaxEditorContent[index];
                if (token === tokenToDelete) {
                    removeValueFromArray(syntaxEditorContent, tokenToDelete);
                    return;
                } else if (PhraseTokenUtil.isGroupToken(token)) {
                    this.deleteToken(token.groupMembers, tokenToDelete);
                }
            }
        }
    }

    doDeleteGroup = (syntaxEditorContent, groupToDelete) => {
        if (groupToDelete) {
            const newSyntaxEditorContent = syntaxEditorContent ? [...syntaxEditorContent] : [];
            this.deleteGroup(newSyntaxEditorContent, groupToDelete);
            return newSyntaxEditorContent;
        }
        return syntaxEditorContent;
    }

    deleteGroup = (syntaxEditorContent, groupToDelete) => {
        if (syntaxEditorContent) {
            for (const index of Object.keys(syntaxEditorContent)) {
                const token = syntaxEditorContent[index];
                if (PhraseTokenUtil.isGroupToken(token)) {
                    if (token === groupToDelete) {
                        removeValueFromArray(syntaxEditorContent, groupToDelete);
                        return;
                    } else
                        this.deleteGroup(token.groupMembers, groupToDelete);
                }
            }
        }
    }

    // ---------------------------------------------------- //
    // --- add phrase token ------------------------------- //
    // ---------------------------------------------------- //
    getAttributesFromTokens = (syntaxEditorContent, attrId, attributes = {}) => {
        if (syntaxEditorContent) {
            for (const index of Object.keys(syntaxEditorContent)) {
                const token = syntaxEditorContent[index];
                if (PhraseTokenUtil.isGroupToken(token)) {
                    this.getAttributesFromTokens(token.groupMembers, attrId, attributes);
                }
                else {
                    const id = PhraseTokenUtil.getTokenAttribute(token, attrId);
                    if (id && !attributes[id]) {
                        attributes[id] = { label: id, value: id };
                    }
                }
            }
        }
    }


    /**
     * Opens edit phrase token input dialog to create new phrase token.
     */
    onAddPhraseToken = (isGroup) => {

        const ids = {};
        this.getAttributesFromTokens(this.state.syntaxEditorContent, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID, ids);

        const newToken = isGroup ? PhraseTokenUtil.createPhraseTokenGroup() : PhraseTokenUtil.createDefaultPhraseToken();

        this.setState({
            editTokenVisible: true,
            newToken: newToken,
            replaceExistingToken: false,
            targetIds: ids,
            isGroup: isGroup
        });
    }

    /**
     * Opens edit phrase token input dialog to edit existing phrase token.
     */
    onEditPhraseToken = (token) => {

        const ids = {};
        this.getAttributesFromTokens(this.state.syntaxEditorContent, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID, ids);

        const id = PhraseTokenUtil.getTokenAttribute(token, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID);
        if (id && ids[id]) {
            delete ids[id];
        }

        // --- add type IDs if they don't exist yet --- //
        if (!!token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE]) {
            token[PhraseTokenUtil.PHRASE_TOKEN_DATA_TYPE].map((type, index) => {
                type.key = type.key ? type.key : IDGeneratorUtil.generateNewTypeID();
                return type;
            });
        }

        this.setState({
            editTokenVisible: true,
            newToken: token,
            replaceExistingToken: true,
            targetIds: ids,
            isGroup: PhraseTokenUtil.isGroupToken(token)
        });
    }

    /**
     * Adds new or replaces edited phrase token.
     */
    onEditPhraseTokenSubmit = (token) => {

        //console.log('save token: ', token);
        //console.log('replaceExistingToken: ', this.state.replaceExistingToken);

        let newSyntaxEditorContent;
        if (this.state.replaceExistingToken) {
            newSyntaxEditorContent = this.doReplaceToken(this.state.syntaxEditorContent, token, this.state.selToken);
        }
        else {
            newSyntaxEditorContent = this.doAddTokenAfterToken(this.state.syntaxEditorContent, token, this.state.selToken);
        }

        this.setState({
            editTokenVisible: false,
            syntaxEditorContent: newSyntaxEditorContent,
            selToken: token,
            replaceExistingToken: false
        });
    }


    onHideEditPhraseToken = () => {

        this.setState({
            editTokenVisible: false,
            newToken: { ...this.state.selToken },
        });
    }


    // ---------------------------------------------------- //
    // --- add group -------------------------------------- //
    // ---------------------------------------------------- //
    onEditGroup = () => {

        /*
        const ids = {};
        this.getAttributesFromTokens(this.state.syntaxEditorContent, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID, ids);

        const id = PhraseTokenUtil.getTokenAttribute(this.state.selToken, PhraseTokenUtil.PHRASE_TOKEN_ATTRIBUTE_ID);
        if (id && ids[id]) {
            delete ids[id];
        }
        */

        this.setState({
            editTokenVisible: true,
            newToken: this.state.selToken,
            replaceExistingToken: true,
            //targetIds: ids,
            isGroup: true
        });
    }


    // obsolete???
    onAddGroupSubmit = (selGroupType) => {
        //console.log('selGroupType: ', selGroupType);
        const newToken = PhraseTokenUtil.createPhraseTokenGroup(selGroupType.attr);
        const newSyntaxEditorContent = this.doAddTokenAfterToken(this.state.syntaxEditorContent, newToken, this.state.selToken);

        this.setState({
            syntaxEditorContent: newSyntaxEditorContent
        });
    }


    // ----------------------------------------------------------------------- //
    // --- sample text development ------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * 
     */
    onSampleTextChange = (text) => {
        this.setState({
            sampleText: text
        });
    }

    /**
     * 
     */
    onSampleTextClear = () => {
        this.setState({
            sampleText: ''
        });
    }

    // ----------------------------------------------------------------------- //
    // --- run phrase tagger ------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onRunPhraseTagger = async () => {

        this.setState({
            busy: true,
            busyText: 'Running phrase tagger...'
        });

        const response = await runPhraseTagger(this.state.sampleText, this.props.history);
        const phraseTaggerOutput = checkResultAndGetPayload(response, this.growl);

        //console.log('phraseTaggerOutput: ', phraseTaggerOutput);

        // --- add IDs - phrase tagger output will not be changed anymore --- //
        if (phraseTaggerOutput) {
            //let localTokenIdIndex = 1;
            for (const token of Object.keys(phraseTaggerOutput).map(e => phraseTaggerOutput[e])) {
                token.key = IDGeneratorUtil.generateNewTokenID();
            }
        }

        this.setState({
            phraseTaggerOutput: phraseTaggerOutput,
            resetPhraseTaggerSelection: true,
            busy: false,
            busyText: '...'
        });
    }


    // --- getter/setter/adder for phrase tokens --- //
    addPhraseTokens = (phraseTokens) => {

        // --- keep current content --- //
        const newPhraseTokens = [].concat(this.state.syntaxEditorContent);

        // --- generate IDs for new phrase tokens and add to current content --- //
        for (const token of phraseTokens) {
            const newToken = cloneDeep(token);
            newToken.key = IDGeneratorUtil.generateNewTokenID();
            newPhraseTokens.push(newToken);
        }

        this.setState({
            syntaxEditorContent: newPhraseTokens,
            selToken: null
        });
    }

    setPhraseTokens = (phraseTokens) => {

        //console.log('setPhraseTokens: ', phraseTokens);

        let newPhraseTokens = [];

        // --- generate IDs for new phrase tokens and add to current content --- //
        for (const token of phraseTokens) {
            const newToken = cloneDeep(token);
            newToken.key = IDGeneratorUtil.generateNewTokenID();
            newPhraseTokens.push(newToken);
        }

        this.setState({
            syntaxEditorContent: newPhraseTokens,
            selToken: null
        });
    }

    getPhraseTokens = () => {
        return this.state.syntaxEditorContent;
    }


    render() {
        const { contentBoxHeight, roles, roleSelectItems } = this.props;

        const selectedTokens = {};
        if (this.state.selToken) {
            selectedTokens[this.state.selToken.key] = this.state.selToken;
        }

        //console.log('selectedTokens: ', selectedTokens);

        return (
            <>
                <Toast ref={this.growl} appendTo={document.body} />

                <div className="task-box task-box-dark-blue shadowMedium" style={{ boxShadow: 'none', border: '1px solid #607D8B' }}>
                    <div className="task-box-header">
                        Syntax editor
                        <Button label="Clear"
                            className="p-button-text dangerButtonLink"
                            title="Clear sample"
                            onClick={this.onClearSyntaxEditor}
                            style={{ float: 'right' }}
                        />
                    </div>
                    <div className="task-box-content"
                        style={{ padding: '10px', height: contentBoxHeight, overflow: 'auto' }}>
                        <div className="grid" style={{ marginBottom: '10px', borderBottom: '1px solid #607D8B' }}>
                            <div className="col-12" style={{ textAlign: 'left' }}>
                                <div style={{ display: 'inline', marginRight: '10px', position: 'relative', bottom: 3 }}>
                                    Add:
                                </div>
                                <Button
                                    className="primaryButton buttonSmall"
                                    label="From phrase tagger"
                                    style={{ marginRight: 1 }}
                                    onClick={(e) => this.setState({ phraseTaggerVisible: true, phraseTaggerOutput: [] })}
                                //style={{ marginRight: 10, marginBottom: 3, marginTop: 3 }}
                                />
                                <Button
                                    className="primaryButton buttonSmall"
                                    label="Phrase token"
                                    style={{ marginRight: 2 }}
                                    onClick={(e) => this.onAddPhraseToken(false)}
                                //style={{ marginRight: 10, marginBottom: 3, marginTop: 3 }}
                                />
                                <Button
                                    className="primaryButton buttonSmall"
                                    label="Group"
                                    onClick={(e) => this.onAddPhraseToken(true)}
                                //style={{ marginRight: 10, marginBottom: 3, marginTop: 3 }}
                                />
                                <div style={{ display: 'inline', marginRight: 10, marginLeft: 30, position: 'relative', bottom: 3 }}>Selected token:</div>
                                <Button
                                    label="< Move left"
                                    className="buttonSmall p-button-secondary"
                                    //className="p-button-text primaryButtonLink"
                                    style={{ marginRight: 1 }}
                                    title="Move selected token to the left"
                                    disabled={!this.state.selToken}
                                    onClick={(e) => this.onMoveToken(this.state.selToken, true)}
                                />
                                <Button
                                    label="Move right >"
                                    className="buttonSmall p-button-secondary"
                                    //className="p-button-text primaryButtonLink"
                                    style={{ marginRight: 30 }}
                                    title="Move selected token to the right"
                                    disabled={!this.state.selToken}
                                    onClick={(e) => this.onMoveToken(this.state.selToken, false)}
                                />
                                <Button
                                    label="Edit"
                                    className="buttonSmall p-button-secondary"
                                    //className="p-button-text primaryButtonLink"
                                    style={{ marginRight: 30 }}
                                    title="Edit selected token"
                                    disabled={!this.state.selToken}
                                    onClick={(e) => this.onEditPhraseToken(this.state.selToken)}
                                />
                                <Button
                                    label="Delete"
                                    className="buttonSmall p-button-danger"
                                    //className="p-button-text dangerButtonLink"
                                    title="Delete selected token"
                                    disabled={!this.state.selToken}
                                    onClick={(e) => {
                                        const newSyntaxEditorContent = this.doDeleteToken(this.state.syntaxEditorContent, this.state.selToken);
                                        this.setState({
                                            syntaxEditorContent: newSyntaxEditorContent,
                                            selToken: null
                                        });
                                    }}
                                />
                            </div>
                            {/*
                            <div className="col-fixed" style={{ textAlign: 'right', width: 200 }}>
                                <Button
                                    className="iconOnlySmall"
                                    icon="pi pi-check" style={{ fontSize: '12px', marginLeft: 10 }}
                                    onClick={(e) => {
                                        this.setState({
                                        });
                                    }} />
                                <Button
                                    className="iconOnlySmall"
                                    icon="pi pi-md-translate" style={{ fontSize: '12px', marginLeft: 10 }}
                                    title="Translate syntax to internal string"
                                    onClick={(e) => this.setState({
                                        selSyntaxPhraseTokens: this.state.syntaxEditorContent,
                                        selSyntaxOrig: 'TODO',
                                        origSyntaxVisible: true
                                    })} />
                                <Button
                                    className="iconOnlySmall"
                                    icon="delete_forever" style={{ fontSize: '12px', marginLeft: 20, background: 'red' }}
                                    title="Clear syntax editor"
                                    onClick={this.onClearSyntaxEditor} />
                            </div>
                            */}
                        </div>

                        <div id="phraseTokenContainer">
                            {this.state.syntaxEditorContent ?
                                <>
                                    <PhraseTokenDisplay
                                        phraseTokens={this.state.syntaxEditorContent}
                                        selectedTokens={selectedTokens}
                                        onTokenClick={this.onTokenClick}
                                        onTokenDoubleClick={this.onTokenDoubleClick}
                                        onTokenRightClick={this.onTokenRightClick}
                                        onGroupClick={this.onGroupClick}
                                        onGroupDoubleClick={this.onGroupDoubleClick}
                                        onGroupRightClick={this.onGroupRightClick}
                                        groupIndex={0}
                                        allowRightClickMenu={true}
                                        showAdditionalData={true}
                                        showProperties={true}
                                        editable={true}
                                    />
                                    <ContextMenu model={this.state.cmItems} ref={el => this.cm = el}></ContextMenu>
                                </> : null
                            }
                        </div>
                    </div>
                </div>

                <EditTokenDialog
                    editTokenVisible={this.state.editTokenVisible}
                    token={this.state.newToken}
                    type={this.props.type}
                    isGroup={this.state.isGroup}
                    roles={roles}
                    roleSelectItems={roleSelectItems}
                    frameSelectItems={this.props.frameSelectItems}
                    macroSelectItems={this.props.macroSelectItems}
                    synsetSelectItems={this.props.synsetSelectItems}
                    targetIds={this.state.targetIds}
                    domains={this.props.domains}
                    onEditPhraseTokenSubmit={this.onEditPhraseTokenSubmit}
                    onHideEditPhraseToken={this.onHideEditPhraseToken}
                />

                <Dialog header="Phrase tagger"
                    visible={this.state.phraseTaggerVisible}
                    style={{ width: '80vw' }}
                    modal={true}
                    onHide={() => this.setState({ phraseTaggerVisible: false })}
                    focusOnShow={false} >
                    <div className="grid" style={{ padding: 10 }}>
                        <div className="col-12">
                            <SampleText
                                sampleText={this.state.sampleText}
                                onSampleTextChange={this.onSampleTextChange}
                                onSampleTextClear={this.onSampleTextClear}
                                contentBoxHeight={`20vh`}
                            />
                        </div>
                        <div className="col-12" style={{ textAlign: 'right' }}>
                            <Button label="Run phrase tagger"
                                className="primaryButton p-button-sm"
                                style={{ marginLeft: ".5em" }}
                                onClick={(e) => this.onRunPhraseTagger()} >
                            </Button>
                        </div>
                        <PhraseTaggerOutput
                            phraseTaggerOutput={this.state.phraseTaggerOutput}
                            onReplaceTokens={(tokens) => {
                                this.setState({ phraseTaggerVisible: false });
                                this.setPhraseTokens(tokens);
                            }}
                            onAddTokens={(tokens) => {
                                this.setState({ phraseTaggerVisible: false });
                                this.addPhraseTokens(tokens);
                            }}
                            contentBoxHeight={`30vh`}
                            allowAppend={!isArrayEmpty(this.state.syntaxEditorContent)}
                        />
                    </div>
                </Dialog>

                <LoadingOverlay
                    active={this.state.busy}
                    spinner={true}
                    text={this.state.busyText} >
                </LoadingOverlay>
            </>
        );
    }
}

export default SyntaxEditor;