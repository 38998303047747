import React from "react";
import FilterSectionDropdown from "../../../../../webapi/general/filters/FilterSectionDropdown";
import SearchModeInfo from "../../../infos/SearchModeInfo";

const SearchFilterIDSearchModeLocal = ({ filter, onChange, selectedEntry }) => {
  const { id: filterID, filterValues, placeholder } = filter;
  return (
    <div className="flex align-items-center justify-content-between">
      <div className="w-full mr-3">
        <FilterSectionDropdown
          filterID={filterID}
          filterEntries={filterValues}
          selectedEntry={selectedEntry}
          onFilterInputValuesChange={onChange}
          placeholder={placeholder}
        />
      </div>
      <SearchModeInfo />
    </div>
  );
};

export default SearchFilterIDSearchModeLocal;
