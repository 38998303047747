import { Tooltip } from "primereact/tooltip";
import { Component } from "react";
import { CHEMISTRY_QUERY_TERM_TYPES, CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX, CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, CHEMISTRY_QUERY_TERM_TYPE_UNKNOWN, CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT } from "../utils/chemistrySearchConstants";


class ChemistryAutocompleteSearchFieldItem extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        const item = this.props.item ? this.props.item : {};

        //console.log('item: ', item);
        let term = item.term;
        let info = 'Other';
        let css = '';
        const isChemClassCompound = item.type === CHEMISTRY_QUERY_TERM_TYPE_CONCEPT && item.subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX;

        const chemQueryType = CHEMISTRY_QUERY_TERM_TYPES[item.type];
        if (item.type === CHEMISTRY_QUERY_TERM_TYPE_UNKNOWN) {
            info = 'Unknown';
            css = 'unknown';
        }
        else if (isChemClassCompound) {
            info = 'Chemical class and compound';
            css = 'unknown';
        }
        else if (chemQueryType) {
            const chemQuerySubType = CHEMISTRY_QUERY_TERM_TYPES[item.subType];
            info = `${chemQueryType.label}${chemQuerySubType?.label ? ` ${chemQuerySubType.label}` : ''}`;
            css = 'concept';
            if (item.subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND) {
                css = 'compound';
            }
            else if (item.subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS) {
                css = 'class';
            }
        }

        const isBulkImport = item.filterID === CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT;
        const allowClick = true; // isBulkImport || isChemClassCompound; // || true;

        const typeCssClassName = `type-${css}`;
        const tooltipID = `tt-${term}-${item.domains?.join('-')}`.replace(/[^a-zA-Z0-9_-]/g, '_');

        return (
            <div className={`${tooltipID} clickableSearchFieldItem ${typeCssClassName}`}>
                {allowClick ?
                    <>
                        <Tooltip target={`.${tooltipID}`}>
                            <div>
                                <div>
                                    {isBulkImport ? 'Click to open in bulk importer:' : 'Click to specify:'}
                                </div>
                                <div>{info}</div>
                            </div>
                        </Tooltip>
                        <span style={{ cursor: 'pointer' }} //className="noStyleLink"
                            onClick={(e) => { this.props.onClickItem(item) }}>
                            <div className="grid grid-nogutter" style={{ marginTop: 1 }}>
                                {/*
                                <div className="col-fixed" style={{ margin: 'auto auto', marginRight: 5 }}>
                                    <a className="secondaryLink"><span className="material-icons" style={{ fontSize: 15 }}>
                                        mode_edit
                                    </span></a>
                                </div>
                               */}
                                <div className="col">
                                    <div className="croppedTableCell" style={{ fontWeight: 'bold', marginLeft: 0, maxWidth: 300 }}>{term}</div>
                                    <div className="grid grid-nogutter" style={{ fontSize: '0.8em' }}>
                                        <div className="col-fixed" >
                                            <span className="croppedTableCell" style={{ opacity: 0.7, maxWidth: 225 }}>{info}</span>
                                        </div>
                                        <div className="col-fixed"> {/*  style={{ width: 25 }} */}
                                            <span style={{ opacity: 0.7 }}>&nbsp;|&nbsp;</span>
                                            <a className='primaryLink'>{isChemClassCompound ? 'Specify' : 'Edit'}</a>
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                            </div>
                            {/*content - anodic stripping pot*/}
                        </span>
                    </>
                    :
                    <>
                        <Tooltip target={`.${tooltipID}`}>
                            <div>
                                <div>{term}</div>
                                <div>{info}</div>
                            </div>
                        </Tooltip>
                        {/*content*/}
                        <div className="clampingContainer line-clamp line-clamp-1-line" style={{ fontWeight: 'bold' }}>{term}</div>
                        <div className="clampingContainer line-clamp line-clamp-1-line" style={{ opacity: 0.7, fontSize: '0.8em' }}>{info}</div>
                    </>
                }
            </div>
        );
    }
}

export default ChemistryAutocompleteSearchFieldItem;