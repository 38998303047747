/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { Tooltip } from 'primereact/tooltip';
import { FREETEXT_EXACT, FREETEXT_WITH_VARIANTS, QUERY_TERM_TYPE_CONCEPT, SEARCH_FILTER_ID_BULK_IMPORT, WILDCARD_ASTERISK } from "../docsearch/searchConstants";
import DomainColorBar from "../../../common/DomainColorBar/DomainColorBar";
import { isArrayEmpty } from "../../util";


class AutocompleteSearchFieldItem extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        const item = this.props.item ? this.props.item : {};
        let isAnyTerm = !isArrayEmpty(item?.queryValues) && item.queryValues[0] === WILDCARD_ASTERISK;
        let term = item.term;
        let info = 'Text';
        let isFreetext = false;
        let coloredDomains = []
        let coloredInfo

        if (item.type !== QUERY_TERM_TYPE_CONCEPT) {
            info = this.props.filterDefinitions && this.props.filterDefinitions[item.filterID]?.label ?
                this.props.filterDefinitions[item.filterID]?.label : item.filterID;
        }
        else if (item.domains) {
            if (item.domains.includes(FREETEXT_EXACT.prefix)) {
                info = FREETEXT_EXACT.label;
                isFreetext = true;
            }
            else if (item.domains.includes(FREETEXT_WITH_VARIANTS.prefix)) {
                info = FREETEXT_WITH_VARIANTS.label;
                isFreetext = true;
            }
            else {
                const uniqueLabels = item.domains.map(dom =>
                    this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                        this.props.domainLabelsMap[dom] : dom);
                uniqueLabels.sort().forEach(lab => {
                    let color = this.props.domainColors && this.props.domainColors.find(obj => {
                        return obj.label === lab
                    })
                    if (color !== undefined) {
                        coloredDomains = [...coloredDomains, '#' + color.color]
                    } else {
                        coloredDomains = [...coloredDomains, 'black']
                    }
                })

                coloredInfo = uniqueLabels.sort().map((lab, i) => {
                    let color = this.props.domainColors && this.props.domainColors.find(obj => {
                        return obj.label === lab
                    })
                    const col = color?.color ? `#${color.color}` : '';
                    return <div key={`${lab}-${i}`} className="grid" style={{ margin: 0, paddingBottom: 5 }}>
                        <DomainColorBar color={col} />
                        <span style={{ marginLeft: 4, paddingRight: 10 }}>{lab}</span>
                    </div>
                })

                info = uniqueLabels.sort().join(', ');
            }
        }

        let gradientString = ''
        let size = 100 / coloredDomains.length
        coloredDomains.length > 0 && coloredDomains.forEach((col, i) => {
            gradientString += `${col} ${size * i}%,${col} ${size * (i + 1)}% `
            if (i < coloredDomains.length - 1) {
                gradientString += ','
            }
        })

        const isConcept = item.type === QUERY_TERM_TYPE_CONCEPT;
        const isBulkImport = item.filterID === SEARCH_FILTER_ID_BULK_IMPORT;
        const allowClick = !isFreetext && !isAnyTerm && (isConcept || isBulkImport);

        const typeCssClassName = `type-${item.type !== QUERY_TERM_TYPE_CONCEPT ? 'filter' : isFreetext ? 'freetext' : 'concept'}`;
        const tooltipID = `tt-${term}-${item.domains?.join('-')}`.replace(/[^a-zA-Z0-9_-]/g, '_');

        return (
            <div className="grid search-field-item-with-domain-bar">
                {coloredDomains.length > 0 ?
                    <div className="search-field-item-domain-bar"
                        style={{ background: `linear-gradient(${gradientString})` }}
                    >
                    </div>
                    : null
                }
                <div className={`search-field-item-content ${tooltipID} clickableSearchFieldItem ${typeCssClassName}`}>
                    {allowClick ?
                        <>
                            <Tooltip className="whiteTooltip" target={`.${tooltipID}`} position={'bottom'}>
                                <div>
                                    <div>
                                        {isConcept ? 'Click to open in domain explorer:' :
                                            isBulkImport ? 'Click to open in bulk importer:' : 'Click to specify:'}
                                    </div>
                                    <div style={{ paddingTop: 4, paddingBottom: 7, fontWeight: 'bold' }}>{term}</div>
                                    <div>{coloredInfo}</div>
                                </div>
                            </Tooltip>
                            <a className="noStyleLink"
                                onClick={(e) => { this.props.onClickItem(item) }}>
                                <div className="grid grid-nogutter" style={{ marginTop: 1 }}>
                                    <div className="col">
                                        <div className="clampingContainer line-clamp line-clamp-1-line" style={{ fontWeight: 'bold', marginLeft: 0 }}>{term}</div>
                                        <div className="grid grid-nogutter" style={{ fontSize: '0.8em' }}>
                                            <div className="col-fixed" style={{ maxWidth: 220 }}>
                                                <span className="clampingContainer line-clamp line-clamp-1-line" style={{ opacity: 0.7 }}><span>{info}</span></span>
                                            </div>
                                            <div className="col-fixed" style={{ width: 28 }}>
                                                <span style={{ opacity: 0.7 }}>&nbsp;|&nbsp;</span>
                                                <a className='primaryLink'>Edit</a>
                                            </div>
                                            <div className="col"></div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </>
                        :
                        <>
                            <Tooltip className="whiteTooltip" target={`.${tooltipID}`} position={'bottom'}>
                                <div>
                                    <div style={{ paddingTop: 0, paddingBottom: 7, fontWeight: 'bold' }}>{term}</div>
                                    <div>{info}</div>
                                </div>
                            </Tooltip>
                            <div className="clampingContainer line-clamp line-clamp-1-line" style={{ fontWeight: 'bold' }}>{term}</div>
                            <div className="clampingContainer line-clamp line-clamp-1-line" style={{ overflow: 'hidden', textOverflow: 'ellipsis', color: '#818181', fontSize: '0.8em' }}>{info}</div>
                        </>
                    }
                </div>
            </div >
        );
    }
}

export default AutocompleteSearchFieldItem;