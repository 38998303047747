/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Component } from 'react';
import { isArrayEmpty, roundNumber } from '../../../util';
import CompoundExtraData from './CompoundExtraData';
import CompoundMentions from './CompoundMentions';
import CopyToClipboard from 'react-copy-to-clipboard';
import CompoundImage from '../CompoundImage';
import { RadioButton } from 'primereact/radiobutton';
import { TieredMenu } from 'primereact/tieredmenu';
import { createAddToSearchMenuItems } from '../../utils/chemistryDisplayUtil';
import CompoundProperties, { getFilteredProperties } from './CompoundProperties';


class CompoundResult extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    renderImageV2 = (data, hasAdditionalProperties) => {

        const smiles = data.extendedData?.smiles && data.extendedData?.smiles[0] ? data.extendedData.smiles[0] : null;

        return <CompoundImage
            image={data.image}
            ocid={`${data.ocid}`}
            smiles={smiles}
            //maxHeight={200}
            //width={'100%'}
            //maxImageHeight={220}
            maxImageWidth={200}
            maxImageHeight={200}
            width={'100%'}
            height={'100%'}
            showCompoundInfo={this.props.showCompoundInfo}
            moreInfoIconStyle={!hasAdditionalProperties ? { bottom: 20 } : {}}
            showMoreInfoIcon={true}
        />
    }


    render() {

        const { data, showCompoundInfo, isSelected, onSelectCompound, singleSelection, onReplaceQuery, onReplaceStructure, includeAddToSearchFieldOption } = this.props;

        const hasAdditionalProperties = !isArrayEmpty(getFilteredProperties(data.extendedData));
        const smiles = data.extendedData?.smiles && data.extendedData?.smiles[0] ? data.extendedData.smiles[0] : null;
        //TODO (where? in API file??? BE?? MW??): const label = data.preferredName === smiles ? data.ocid : data.preferredName;
        const label = data.preferredName;
        const hasSimilarityScore = data.extendedData?.tanimotoScore;

        const addToSearchItems = createAddToSearchMenuItems(data.ocid, smiles, onReplaceStructure, onReplaceQuery, includeAddToSearchFieldOption);

        return (
            <div id='chemResultCard' className="grid card" style={{ border: '1px solid #ddd', padding: '10px 10px' }}>

                {data.ocid ?
                    <div className="col-fixed" style={{ width: 30 }}>
                        {singleSelection ?
                            <RadioButton
                                onChange={e => onSelectCompound(data.ocid, e.checked, data)}
                                checked={isSelected}
                            />
                            :
                            <Checkbox
                                onChange={e => onSelectCompound(data.ocid, e.checked, data)}
                                checked={isSelected}
                            />}
                    </div>
                    :
                    null
                }

                <div className="col product-grid-item">

                    <div className="product-grid-item-top">
                        <div className="grid grid-nogutter">
                            <div className="col-fixed textAlignLeft" style={{ width: hasSimilarityScore ? 'calc(100% - 120px)' : '100%', overflow: 'hidden' }}> {/*width: 'calc(100% - 30px)' 'calc(100% - 80px)'*/}
                                {data.ocid ?
                                    <Button
                                        className="p-button-text primaryButtonAsLink textAlignLeft valignMiddle"
                                        title={`Click to load details for ${data.preferredName}`}
                                        onClick={() => showCompoundInfo(data.ocid)}>
                                        <span className="clampingContainer textAlignLeft prefnameLink wordBreakBreakAll line-clamp line-clamp-1-line">
                                            {data.key ? `${data.key}. ` : ''}{label}
                                        </span>
                                    </Button>
                                    :
                                    <span className="clampingContainer textAlignLeft prefnameLink wordBreakBreakAll line-clamp line-clamp-1-line">
                                        <span>{data.key}. </span>
                                        <CopyToClipboard
                                            text={label}>
                                            <a title='Copy to clipboard'
                                                className='secondaryLink'
                                            //onClick={(e) => this.showSuccessGrowl()}
                                            >{label}</a>
                                        </CopyToClipboard>
                                    </span>
                                }
                            </div>
                            {hasSimilarityScore ?
                                <>
                                    <div className="col"></div>
                                    <div className="col-fixed textAlignRight" style={{ width: 100 }}>
                                        <span title='Tanimoto similarity score'>
                                            Similarity: <b>{roundNumber(data.extendedData.tanimotoScore * 100, 0)}%</b></span>
                                    </div>
                                </>
                                : null}

                            {/*
                        <div className="col-fixed textAlignRight detailsLink" style={{ width: 50 }}>
                            <Button
                                className="p-button-text primaryButtonAsLink valignMiddle"
                                title={`Click to load details for ${label}`}
                                onClick={() => showCompoundInfo(data.ocid)}>
                                Details
                            </Button>
                        </div>
                        */}
                            <div className="breakRow"></div>
                            <div className="col-12 secondaryInfo" style={{ marginTop: 10, height: 40 }}>
                                <CompoundExtraData
                                    extendedCompoundData={data.extendedData} />
                            </div>
                        </div>
                    </div>
                    <div className="product-grid-item-content" style={{ marginTop: 10, marginBottom: hasAdditionalProperties ? 60 : 0 /*10 60*/ }}>
                        <div className="grid">
                            <div className="col-5">
                                <CompoundMentions
                                    compoundData={data}
                                    docCountPatents={this.props.docCountPatents}
                                    docCountPatentClaims={this.props.docCountPatentClaims}
                                    docCountPublications={this.props.docCountPublications}
                                    patentsRepository={this.props.patentsRepository}
                                    publicationsRepository={this.props.publicationsRepository}
                                    includePreparationsSearch={this.props.includePreparationsSearch}
                                    preparationsTarget={this.props.preparationsTarget}
                                />

                                <div style={{ textAlign: 'left', marginTop: 15, marginBottom: 10 }}>
                                    <Button
                                        className="p-button-text primaryButtonAsLink valignMiddle"
                                        title='Load this compound into the chemistry search form'
                                        onClick={(e) => this.addToSearchMenu.toggle(e)}
                                    >Add to search</Button>
                                    <TieredMenu
                                        className='chemAdditionalInfoMenu'
                                        model={addToSearchItems}
                                        popup={true}
                                        ref={(el) => this.addToSearchMenu = el} />
                                </div>

                            </div>
                            <div className="col-7">
                                {this.renderImageV2(data, hasAdditionalProperties)}
                            </div>
                        </div>
                    </div>

                    {hasAdditionalProperties ?
                        <div className="product-grid-item-bottom" style={{
                            marginTop: 5, paddingTop: 5, marginRight: 10, borderTop: '1px solid #e3e3e3', width: 'calc(100% - 20px)'
                        }}>
                            <div style={{ /*height: 200, position: 'relative', bottom: 0*/ }}>
                                <div style={{ /*position: 'absolute', bottom: 0*/ }}>
                                    <CompoundProperties
                                        extendedCompoundData={data.extendedData}
                                    />
                                </div>
                            </div>
                        </div> : null}
                </div>
            </div>
        )
    }
}
export default CompoundResult