import { QUERY_PREFIX_FACETFILTER, QUERY_TERM_TYPE_CONCEPT, QUERY_TERM_TYPE_METADATA, QUERY_TERM_TYPE_TERMLOC, SEARCH_FILTER_ID_AUTHOR, SEARCH_FILTER_ID_CONCEPT_DISTANCE, SEARCH_FILTER_ID_PATENT_TYPE, SEARCH_FILTER_ID_PUBDATE, SEARCH_FILTER_ID_TERMLOC } from "../../general/docsearch/searchConstants";
import dateFormat from 'dateformat';


const date = new Date();
//const yearFormatted = dateFormat(date, 'yyyy');
date.setMonth(date.getMonth() - 6);
const dateFormatted = dateFormat(date, 'yyyy-mm-dd');

export const QUICK_SEARCH_EXAMPLES = [
    {
        // id is mandatory, it will be used as key,
        // since there can be multiple sections
        id: "quick",
        //label: 'Search our database of millions of documents',
        //description: 'blablubb',
        examples: [{
            label: 'Recent documents about COVID-19',
            description: 'Find documents about COVID-19, published in the last 6 months.',
            queryTerms: [{
                type: QUERY_TERM_TYPE_CONCEPT,
                term: 'COVID-19',
                domains: ['diseases'],
                ocids: [208000025721],
                queryValues: ['covid-19'],
                label: 'covid-19',
            }, {
                type: QUERY_TERM_TYPE_METADATA,
                filterID: SEARCH_FILTER_ID_PUBDATE,
                term: `${dateFormatted} - `,
                queryValues: [dateFormatted],
                queryPrefix: 'pdate',
            }
            ],
        }, {
            label: 'Recent publications about H. pylori by M. Piazuelo',
            description: 'Find publications from the year 2022 or later about "Helicobacter pylori" and "inflammation" by the author "M. Piazuelo".',
            queryTerms: [{
                type: QUERY_TERM_TYPE_CONCEPT,
                term: 'helicobacter pylori',
                domains: ['species', 'drugs'],
                ocids: [241000590002, 229940037467],
                queryValues: ['helicobacter pylori'],
                label: 'helicobacter pylori',
            }, {
                type: QUERY_TERM_TYPE_CONCEPT,
                term: 'inflammation',
                domains: ['diseases'],
                ocids: [206010061218],
                queryValues: ['inflammation'],
                label: 'inflammation',
            }, {
                type: QUERY_TERM_TYPE_METADATA,
                filterID: SEARCH_FILTER_ID_CONCEPT_DISTANCE,
                term: 'Max. ~70 characters',
                queryPrefix: 'near',
                queryValues: ['filter'],
                booster: '10'
            }, {
                type: QUERY_TERM_TYPE_METADATA,
                filterID: SEARCH_FILTER_ID_PUBDATE,
                queryPrefix: 'pdate',
                term: `2022 - `,
                queryValues: ['2022']
            }, {
                type: QUERY_TERM_TYPE_METADATA,
                filterID: SEARCH_FILTER_ID_AUTHOR,
                facetID: 'Author',
                queryPrefix: 'auth',
                queryValues: ['M. Blanca Piazuelo'],
                term: '~ M. Blanca Piazuelo',
                fuzziness: '0'
            }
            ],
        }, {
            label: 'Advair Diskus in patent applications',
            description: 'Find patents mentioning the chemical compound "Advair Diskus".',
            queryTerms: [{
                type: QUERY_TERM_TYPE_CONCEPT,
                term: "ADVAIR DISKUS",
                domains: ["chem"],
                ocids: [190037705911],
                queryValues: ["ADVAIR DISKUS"],
                label: "ADVAIR DISKUS",
            }, {
                type: QUERY_TERM_TYPE_METADATA,
                filterID: SEARCH_FILTER_ID_PATENT_TYPE,
                facetID: "PatentType",
                term: "application",
                queryValues: ["application"],
                queryPrefix: QUERY_PREFIX_FACETFILTER,
            }, {
                type: QUERY_TERM_TYPE_TERMLOC,
                filterID: SEARCH_FILTER_ID_TERMLOC,
                term: "Labeled compounds",
                queryPrefix: "termloc",
                termloc: "labeledcomp"
            }
            ]
        }]
    }
]