export const DOMAIN_ALIASES = {
   tr: 'Text exact',
   t: 'Text with variants'
}

export const FREE_TEXT_DOMAINS = [
   'tr',
   't'
]

export const FREE_TEXT_DOMAIN_ALIASES = [
   'Text exact',
   'Text with variants'
]