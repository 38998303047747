import { sendApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';

/**
 * Creates a new App
 * @param {} collectionID ID of the selected document or search collection
 * @param {} appName name of the App
 * @param {} selectedRepos selected repos to build the dashboard for
 * @param {} dashboardType type of the dashboard
 * @returns 
 */
export const createNewApp = async (data) => {
    return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards`, data);
}

/**
 * Edits an App
 * @param {} collectionID ID of the selected document or search collection
 * @param {} appName name of the App
 * @param {} selectedRepos selected repos to build the dashboard for
 * @param {} dashboardType type of the dashboard
 * @returns 
 */
 export const editApp = async (data) => {
    let dashboardId = data.appID
    delete data.appID
    return await sendApiRequest('PUT', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards?dashboardId=${dashboardId}`, data);
}