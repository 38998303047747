/* eslint-disable jsx-a11y/anchor-is-valid */
import { CgChevronDown } from "react-icons/cg";
import { Menu } from 'primereact/menu';
import { useRef } from "react";


export default function ToolbarOption({ option }) {

    const menu = useRef(null);

    return <>
        {option.items ?
            <>
                <a className="query-editor-selection"
                    title={option.tooltip || ""}
                    onClick={(e) => menu.current.toggle(e)}>
                    {option.label} <CgChevronDown />
                </a>

                <Menu
                    id="query-editor-menu"
                    className="query-editor-dropdown-list"
                    model={option.items}
                    popup
                    ref={menu} />
            </>
            :
            <a className="query-editor-selection"
                title={option.tooltip || ""}
                onClick={option.command}>
                {option.label}
            </a>}
    </>
}