import { sendApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';
import { removeQuotesAndTrimText } from '../../components/webapi/docsearch/utils/queryUtil';

/**
 * @deprecated This method is obsolete and may not work with the current query format (query string and filter queries)
 */
export const convertQueryConceptsToQueryString = (queryConcepts, combineGroupsOperator) => {

    let queryString = '';

    if (!!queryConcepts && queryConcepts.length > 0) {
        const conceptsGroups = [];
        // --- concepts within a group will be OR-combined (separated by blank) --- //
        // --- groups of concepts will be either AND- or OR-combined -------------- //
        // --- (separated by blank, preceeded by +) ------------------------------- //
        queryConcepts.forEach(conceptsGroup => {
            // --- if there are OCIDs -> use OCIDs
            if (conceptsGroup.ocids) {
                let conceptsGroupString = conceptsGroup.ocids.map(ocid => {
                    return `ocid:${ocid}`;
                }).join(' ');
                conceptsGroupString = conceptsGroup.ocids.length > 0 ? `(${conceptsGroupString})` : `${conceptsGroupString}`;
                conceptsGroups.push(conceptsGroupString);
            }
            // --- otherwise check if there are free text domains --- //
            else if (conceptsGroup.domains) {
                if (conceptsGroup.domains.includes('tr')) {
                    conceptsGroups.push(`tr:"${conceptsGroup.term.replace('"', '')}"`);
                }
                else if (conceptsGroup.domains.includes('t')) {
                    conceptsGroups.push(`t:"${conceptsGroup.term.replace('"', '')}"`);
                }
            }
        })
        // --- groups will be AND-combined --- //
        if (combineGroupsOperator === "and") {
            queryString = `+${conceptsGroups.join(' +')}`;
        }
        // --- by default groups will be OR-combined --- //
        else {
            queryString = conceptsGroups.join(' ');
        }
    }

    return queryString;
}


/**
 * @deprecated This method is obsolete and may not work with the current query format (query string and filter queries)
 */
export const convertQueriesToQueryString = (queries) => {

    let queryString = '';

    if (!!queries) {
        const conceptsGroups = [];
        if (queries.ocids) {
            let conceptsGroupString = queries.ocids.map(ocid => {
                return `ocid:${ocid}`;
            }).join(' ');
            conceptsGroupString = queries.ocids.length > 0 ? `(${conceptsGroupString})` : `${conceptsGroupString}`;
            conceptsGroups.push(conceptsGroupString);
        }
        // --- otherwise check if there are free text domains --- //
        else if (queries.domains) {
            if (queries.domains.includes('tr')) {
                conceptsGroups.push(`tr:"${queries.term.replace('"', '')}"`);
            }
            else if (queries.domains.includes('t')) {
                conceptsGroups.push(`t:"${queries.term.replace('"', '')}"`);
            } else {
                let termsGroupString = queries.queryValues.map(t => { return `${queries.domains[0]}:"${removeQuotesAndTrimText(t)}"`; }).join(' ');
                termsGroupString = queries.queryValues.length > 1 ? `(${termsGroupString})` : `${termsGroupString}`;
                conceptsGroups.push(termsGroupString);
            }
        }
        queryString = conceptsGroups.join(' ');
    }

    return queryString;
}

/**
 * Converts a query string and a filter object to a combined query string in backend syntax.
 * @deprecated This method is obsolete and will not work with the current query format (query string and filter queries).
 */
export const combineQueryAndFilters = async (query, filters) => {

    return { status: 'FAILED', message: 'This method is obsolete and will not work with the current query format.' };
}

/**
 * 
 * @param {string} queryString  
 */
export const checkQuerySyntax = async (queryString) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/check`, {
        query: queryString
    });

    return result;
}

/**
 * 
 * @param {string} queryString  
 */
export const translateToHumanReadableQuery = async (queryString) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/translation`, {
        query: queryString
    });

    return result;
}

/**
 * 
 * @param {string} queryString  
 * @param {Boolean} removeNonQueryParts  
 */
export const simplifyQuery = async (queryString, removeNonQueryParts) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/simplification`, {
        query: queryString,
        removeNonQueryParts: removeNonQueryParts
    });

    return result;
}