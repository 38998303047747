import './CooccurrenceAnalysis.css';
import { Component, createRef } from 'react';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { fetchBlacklists, addTermsToBlacklist } from '../../../api/content/BlacklistApi';
import { checkResultAndGetPayload } from '../../../api';
import AddToBlacklist from '../blacklist/AddToBlacklist';
import CorrelationsView from './CorrelationsView';
import { QUERY_FORM_CORRELATIONS, QUERY_TYPE_COOC_SEARCH, REPOSITORY_INFO_NOT_AVAILABLE } from '../../../properties';
import { APP_PROPERTIES } from '../../../properties/index';
import { isArrayEmpty, hasUserRole, stopEventPropagation } from '../util';
import { OverlayPanel } from 'primereact/overlaypanel';
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
import { videos } from '../../../properties/videos'
import SeparatorPoint from '../general/SeparatorPoint';
import SearchExamples from '../general/searchexamples/SearchExamples';
import { CORRELATION_VIEWS } from './views';
import { MAX_COOC_PAIRS, MAX_COOC_SENTENCES, MAX_COOC_TERMS } from './helper';
import { addDataToLocalStorage, getDataFromLocalStorage } from '../util/localStorage';
import { CORRELATIONS_EXAMPLES } from './examples';
import TutorialVideos from '../general/TutorialVideos';
import SavedSearchesLink from '../general/SavedSearches/SavedSearchesLink';
import { getRepositoryForName } from '../../../api/content/ContentApi';

const PATENTS_REPOSITORY_NAME = 'patents';
const CT_REPOSITORY_NAME = 'ctcoll'; // list???


class CooccurrencesAnalysis extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            views: CORRELATION_VIEWS,
            viewsUpToDate: false,

            pageTutorials: [],
            section: '',
            sectionLabel: '',

            patRepositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE,
            ctRepositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE
        };

        this.op = createRef();
        this.growl = createRef();
    }

    componentDidMount = async () => {

        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('coocs')) });
        }

        // deprecated?
        if (this.props.location && this.props.location.state && this.props.location.state.form) {
            const index = this.state.views.map(e => e.id).indexOf(this.props.location.state.form);
            this.setState({ activeIndex: index >= 0 ? index : 0 });
        }
        // --- get active form from local storage --- //
        const dataFromStorage = getDataFromLocalStorage(QUERY_TYPE_COOC_SEARCH.id);
        this.onViewChangeByViewID(dataFromStorage?.form);

        if (hasUserRole('ROLE_BLACKLISTS')) {
            await this.fetchBlacklists();
        }
        // --- get default patents repository --- //
        const patResponse = await getRepositoryForName(PATENTS_REPOSITORY_NAME, false);
        const patDefRepository = checkResultAndGetPayload(patResponse);
        const patDefaultRepository = patDefRepository ? patDefRepository : REPOSITORY_INFO_NOT_AVAILABLE;
        //console.log('-> patDefaultRepository: ', patDefaultRepository);
        this.setState({
            patRepositoryInfo: patDefaultRepository
        });

        // --- get default CT repository --- //
        const ctResponse = await getRepositoryForName(CT_REPOSITORY_NAME, false);
        const ctDefRepository = checkResultAndGetPayload(ctResponse);
        const ctDefaultRepository = ctDefRepository ? ctDefRepository : REPOSITORY_INFO_NOT_AVAILABLE;
        //console.log('-> ctDefaultRepository: ', ctDefaultRepository);
        this.setState({
            ctRepositoryInfo: ctDefaultRepository
        });
    }


    // replaces componentWillReceiveProps
    /**
     * For each defined view check if query terms are restricted to certain domains.
     * If yes store OCIDs of root concepts for these domains in default query term object.
     */
    static getDerivedStateFromProps = (nextProps, prevState) => {

        //console.log('***availableDomains: ', nextProps.availableDomains);

        if (nextProps.availableDomains && !prevState.viewsUpToDate) {

            const domainsMap = {};
            nextProps.availableDomains.forEach(dom => {
                domainsMap[dom.value] = dom;
            });

            // --- create new objects to update state correctly --- //
            let updatedViews = [...prevState.views].map(view => {
                return { ...view };
            });

            // --- remove presets of inactive domains --- //
            //const removeViews = [];
            for (let view of updatedViews) {

                let removeView = false;
                if (view.restrictions.left.subtrees) {
                    // --- filter valid domains --- //
                    view.restrictions.left.subtrees = view.restrictions.left.subtrees.filter(subtree => {
                        return !!domainsMap[subtree.domains[0]];
                    });
                    // --- if no presets are left and autocomplete depends on these presets -> remove subtree altogether --- //
                    if (view.restrictions.left.subtrees.length === 0) {
                        if (!!view.restrictions.left.restrictAutocomplete) {
                            removeView = true;
                        }
                        view.restrictions.left = {};
                    }
                }
                else {
                    removeView = true;
                    view.restrictions.left = {};
                }
                if (view.restrictions.right.subtrees) {
                    // --- filter valid domains --- //
                    view.restrictions.right.subtrees = view.restrictions.right.subtrees.filter(subtree => {
                        return !!domainsMap[subtree.domains[0]];
                    });
                    // --- if no presets are left and autocomplete depends on these presets -> remove subtree altogether --- //
                    if (view.restrictions.right.subtrees.length === 0) {
                        if (!!view.restrictions.right.restrictAutocomplete) {
                            removeView = true;
                        }
                        view.restrictions.right = {};
                    }
                }
                else {
                    removeView = true;
                    view.restrictions.right = {};
                }
                // --- if no presets and no autocomplete are left -> tool is not useable --- //
                if (removeView && view.id !== QUERY_FORM_CORRELATIONS.id) {
                    //removeViews.push(view);
                    view.disabled = true;
                }
            }

            // --- if no presets and no autocomplete are left -> remove tool altogether --- //
            // TODO: make sure that saved queries go to the right tool if one is missing!!!
            //updatedViews = removeArrayOfValuesFromArray(updatedViews, removeViews);

            for (let view of updatedViews) {
                // --- if static query term is not yet defined and there are static domains defined --- //
                if (!view.staticQueryTerm && view.domainsEntity2) {
                    // --- query term consists of OCIDs and label (domains not neccessary anymore) --- //
                    let staticQueryTerm = [];
                    let domains = view.domainsEntity2;
                    let ocids = [];
                    // --- add root concept OCIDs to static query term --- //
                    for (let dom of nextProps.availableDomains) {
                        if (domains.includes(dom.value) && dom.isaRootConcepts) {
                            for (let rootConcept of dom.isaRootConcepts) {
                                if (rootConcept.ocid) {
                                    ocids.push(rootConcept.ocid);
                                }
                            }
                        }
                    }

                    staticQueryTerm.push({ domains: domains, ocids: ocids, term: "All" });
                    //console.log('---staticQueryTerm NEW: ', staticQueryTerm);
                    view.staticQueryTerm = staticQueryTerm;
                }

                if (!view.restrictions.left.domains || !view.restrictions.right.domains) {

                    // --- left --- //
                    if (!isArrayEmpty(view.restrictions.left.subtrees)) {
                        view.restrictions.left.domains = [];
                        view.restrictions.left.ocids = [];
                        view.restrictions.left.subtrees = view.restrictions.left.subtrees.filter(subtree => {
                            return domainsMap.hasOwnProperty(subtree.domains[0]);
                        });
                        view.restrictions.left.subtrees
                            .forEach(rest => {
                                if (!isArrayEmpty(rest.domains)) {
                                    view.restrictions.left.domains.push(...rest.domains);
                                    if (!rest.label) {
                                        for (let dom of nextProps.availableDomains) {
                                            if (rest.domains[0] === dom.value) {
                                                rest.label = dom.label;
                                            }
                                        }
                                    }
                                    if (isArrayEmpty(rest.ocids)) {
                                        for (let dom of nextProps.availableDomains) {
                                            if (rest.domains[0] === dom.value && dom.isaRootConcepts) {
                                                rest.ocids = [];
                                                for (let rootConcept of dom.isaRootConcepts) {
                                                    if (rootConcept.ocid) {
                                                        rest.ocids.push(rootConcept.ocid);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if (!isArrayEmpty(rest.ocids)) {
                                    view.restrictions.left.ocids.push(...rest.ocids);
                                }
                                if (!rest.term) {
                                    rest.term = rest.label;
                                }
                            });
                    }
                    // --- right --- //
                    if (!isArrayEmpty(view.restrictions.right.subtrees)) {
                        view.restrictions.right.domains = [];
                        view.restrictions.right.ocids = [];
                        view.restrictions.right.subtrees = view.restrictions.right.subtrees.filter(subtree => {
                            return domainsMap.hasOwnProperty(subtree.domains[0]);
                        });
                        view.restrictions.right.subtrees
                            .forEach(rest => {
                                if (!isArrayEmpty(rest.domains)) {
                                    view.restrictions.right.domains.push(...rest.domains);
                                    if (!rest.label) {
                                        for (let dom of nextProps.availableDomains) {
                                            if (rest.domains[0] === dom.value) {
                                                rest.label = dom.label;
                                            }
                                        }
                                    }
                                    if (isArrayEmpty(rest.ocids)) {
                                        for (let dom of nextProps.availableDomains) {
                                            if (rest.domains[0] === dom.value && dom.isaRootConcepts) {
                                                rest.ocids = [];
                                                for (let rootConcept of dom.isaRootConcepts) {
                                                    if (rootConcept.ocid) {
                                                        rest.ocids.push(rootConcept.ocid);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if (!isArrayEmpty(rest.ocids)) {
                                    view.restrictions.right.ocids.push(...rest.ocids);
                                }
                                if (!rest.term) {
                                    rest.term = rest.label;
                                }
                            });
                    }
                }
            }

            /*
            let activeIndex = prevState.activeIndex;
            if (nextProps.location && nextProps.location.state && nextProps.location.state.form) {
                const index = updatedViews.map(e => e.id).indexOf(nextProps.location.state.form);
                activeIndex = index >= 0 ? index : 0;
            }
            */

            // --- return new state -> replaces setState --- //
            return {
                views: updatedViews,
                viewsUpToDate: true,
                domainsMap: domainsMap,
                //activeIndex: activeIndex
            };
        }
        return null;
    }


    fetchBlacklists = async () => {

        const response = await fetchBlacklists();
        const blacklists = checkResultAndGetPayload(response, this.growl);

        this.setState({
            blacklists: blacklists
        });
    }

    // --- view change --- //
    onViewChange = (index) => {
        this.setState({ activeIndex: parseInt(index) });
    }

    onViewChangeByViewID = (viewID) => {
        let index = 0;
        if (viewID) {
            index = this.state.views.map(e => e.id).indexOf(viewID);
        }
        index = index > 0 ? index : 0;
        this.onViewChange(index);
    }

    renderContent = (view) => {

        // all the same???

        return (
            <>

                <div style={{ marginBottom: 10 }}></div>
                {/*
                    <div style={{ fontSize: 13, color: '#757575', marginBottom: 30 }}>
                        {view.info}
                    </div>
                */}

                <CorrelationsView
                    view={view}
                    maxNumOfPairs={MAX_COOC_PAIRS}
                    maxNumOfTerms={MAX_COOC_TERMS}
                    maxNumOfSentences={MAX_COOC_SENTENCES}
                    staticQueryTerm={view.staticQueryTerm}
                    //domainsEntity1={domainsEntity1}
                    //domainsEntity2={domainsEntity2}
                    restrictionsLeft={view.restrictions.left}
                    restrictionsRight={view.restrictions.right}
                    defaultPresetRight={view.restrictions.right && !isArrayEmpty(view.restrictions.right.subtrees) ? view.restrictions.right.subtrees[0] : null}
                    availableDomains={this.props.availableDomains}
                    domainColors={this.props.domainColors}
                    domainLabelsMap={this.props.domainLabelsMap}
                    domainsMap={this.state.domainsMap}
                    domains={this.props.availableDomains}
                    repositories={this.props.repositories}
                    blacklists={this.state.blacklists}
                    onAddToBlacklists={this.onAddTermsToBlacklists}
                    onBlacklistsChanged={this.onBlacklistsChanged}
                    userData={this.props.userData}

                    changedBlacklists={this.state.changedBlacklists}

                    patRepositoryInfo={this.state.patRepositoryInfo}
                    ctRepositoryInfo={this.state.ctRepositoryInfo}
                />
            </>);
    }

    // ----------------------------------------------------------------------- //
    // --- blacklisting ------------------------------------------------------ //
    // ----------------------------------------------------------------------- //
    onAddTermsToBlacklists = (terms) => {

        //console.log('blacklist terms: ', terms);
        this.setState({
            addToBlacklistVisible: true,
            termsToAddToBlacklist: terms
        });
    }

    onAddTermsToBlacklistsSubmit = async (blacklistIDs, type, scope) => {
        //console.log('onAddTermsToBlacklistsSubmit blacklists: ', blacklists);
        if (hasUserRole('ROLE_BLACKLISTS')) {
            const actualChangedBlacklists = [];
            const failedBlacklists = [];
            for (let blacklistID of blacklistIDs) {
                //console.log('blacklistID: ', blacklistID);
                const result = await addTermsToBlacklist(blacklistID, this.state.termsToAddToBlacklist, type, scope);
                //console.log('onAddTermsToBlacklistsSubmit result: ', result);
                if (result?.status === 'SUCCESS') {
                    actualChangedBlacklists.push(blacklistID);
                }
                else {
                    checkResultAndGetPayload(result);
                    failedBlacklists.push(blacklistID);
                }
            };
            //console.log('onAddTermsToBlacklistsSubmit failedBlacklists: ', failedBlacklists);
            //console.log('onAddTermsToBlacklistsSubmit actualChangedBlacklists: ', actualChangedBlacklists);
            if (isArrayEmpty(failedBlacklists)) {
                this.growl.current.show({
                    severity: 'success', summary: `Concepts added to blocklists`,
                    detail: `Added ${Object.keys(this.state.termsToAddToBlacklist).length} ${Object.keys(this.state.termsToAddToBlacklist).length === 1 ? 'entry' : 'entries'} to blocklists.`
                });
            }
            else {
                this.growl.current.show({
                    severity: 'warn', summary: `Concepts not added to all blocklists`,
                    detail: `Concepts could not be added to ${this.state.failedBlacklists.length} ${this.state.failedBlacklists.length === 1 ? 'blocklist' : 'blocklists'}.`
                });
            }

            if (!isArrayEmpty(actualChangedBlacklists)) {
                await this.fetchBlacklists();

                this.setState({ changedBlacklists: actualChangedBlacklists });
            }
        }

        this.setState({
            addToBlacklistVisible: false
        });
    }

    onCloseAddTermsToBlacklists = () => {

        this.setState({
            addToBlacklistVisible: false
        });
    }

    onBlacklistsChanged = async () => {
        //console.log('onBlacklistsChanged');
        if (hasUserRole('ROLE_BLACKLISTS')) {
            await this.fetchBlacklists();
        }
    }

    onSelectExample = (example) => {
        this.onViewChange(0);
        addDataToLocalStorage(QUERY_TYPE_COOC_SEARCH.id, example.queryObject);
    }

    onSelectSavedSearch = (savedSearch, noAutoRun) => {
        let editable = true

        /*if (savedSearch.queryCollectionList && savedSearch.queryCollectionList.length > 0) {
            savedSearch.queryCollectionList.every(list => {
                if (list.shared && !list.writable) {
                    editable = false
                    return false
                }
                return true
            })
        }*/
        if (!savedSearch.isCurrentUserOwner) {
            editable = false
        }
        savedSearch.edit = editable;//isArrayEmpty(savedSearch.queryCollectionList) || !savedSearch.queryCollectionList[0] || !savedSearch.queryCollectionList[0].shared;
        addDataToLocalStorage(QUERY_TYPE_COOC_SEARCH.id, savedSearch);
        this.onViewChangeByViewID(savedSearch?.form);
    }

    onTutorialClick = (section, label, videoTitle) => {
        let tutorialObject = { section: section, label: label, title: videoTitle }
        localStorage.setItem('tutorialData', JSON.stringify(tutorialObject))
        window.open(`${APP_PROPERTIES.FRONTEND_URL}tutorials`, "_blank")
        this.op.hide()
    }

    render() {
        //console.log('repositories: ', this.props.repositories);
        //console.log('views: ', this.state.views);
        //console.log('activeIndex: ', this.state.activeIndex);
        const activeIndex = this.state.activeIndex;

        return (
            <>
                <Toast ref={this.growl} />

                <div className='grid'>
                    <div className="col textAlignLeft" style={{ paddingBottom: 0 }}>
                        <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader">Co-occurrence Analysis</h1>
                    </div>
                    <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                        {!isArrayEmpty(this.state.pageTutorials) ?
                            <span style={{ marginLeft: 30 }}>
                                <TutorialVideos
                                    pageTutorials={this.state.pageTutorials}
                                    section='coocs'
                                    sectionLabel='Co-occurrence Analysis'
                                /></span> : null}
                    </div>
                </div>


                <div className='col-12 pageInfo' style={{ marginLeft: 0, paddingLeft: 0, paddingBottom: 10 }}>
                    <span>The co-occurrence analysis makes use of all the named entities that the semantic processing of documents identifies and statistically correlates them in order to drive insights. Concepts must occur within close textual proximity and within one sentence in order to count as related.</span>

                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <SeparatorPoint />

                        <SearchExamples
                            buttonLabel="Examples"
                            //panelStyle={{ maxWidth: 400, maxHeight: 600, height: "100vh" }}
                            examples={CORRELATIONS_EXAMPLES}
                            onExampleClick={(example) => this.onSelectExample(example)}
                        />

                        <SeparatorPoint />

                        <SavedSearchesLink
                            allowedQueryTypes={[QUERY_TYPE_COOC_SEARCH.id]}
                            onSearchSelect={(savedSearch) => this.onSelectSavedSearch(savedSearch, true)}
                        />
                    </span>
                </div>
                <br />
                <div className="grid" style={{ padding: '0 10px' }}>
                    {Object.keys(this.state.views).map(i => {
                        const index = parseInt(i);
                        const view = this.state.views[i];
                        const disabled = view.disabled;
                        //console.log('view: ', view);
                        return <div key={view.id}
                            // col-12 md:col-4 lg:col-4 xl:col-4
                            title={disabled ? 'This tool is disabled, because none of the neccessary domains are activated.' : ''}
                            className={`tile-container col ${activeIndex === index ? 'active' : ''} ${index === 0 ? 'first' : ''}`}>
                            <div className="tile-top"></div>
                            <div className={`tile ${disabled ? 'disabledCoocTool' : ''}`}
                                onClick={(e) => this.onViewChange(index)}>
                                <div className="tile-content">
                                    <div>
                                        <b>{view.name}</b>
                                        <a //onMouseOver={e => this[view.id].show(e)}
                                            //onMouseOut={e => this[view.id].hide(e)}
                                            title="Click for information about this co-occurrence search"
                                            onClick={e => {
                                                // -- do not switch cooc tool --- //
                                                stopEventPropagation(e);
                                                // -- change info text for overlay --- //
                                                this.setState({
                                                    viewInfoText: view.info,
                                                    viewInfoCss: index === this.state.views.length - 1 ? 'moveRightArrow' : ''
                                                });
                                                this.infoOverlay.toggle(e);
                                            }}
                                            style={{ width: 'auto', marginLeft: 25, float: 'right' }}>
                                            <img src={infoIcon} alt="Cooccurrence search information" />
                                        </a>
                                    </div>
                                    <div className="tile-teaser">
                                        {view.teaser}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>

                <TabView
                    className="hideHeaderTabview"
                    activeIndex={this.state.activeIndex}
                    renderActiveOnly={false}
                    onTabChange={(e) => this.onViewChange(e.index)} >
                    {Object.values(this.state.views).map(view => {
                        return <TabPanel key={view.id}>
                            {this.renderContent(view)}
                        </TabPanel>
                    })}
                </TabView>
                {hasUserRole('ROLE_BLACKLISTS') &&
                    <AddToBlacklist
                        visible={this.state.addToBlacklistVisible}
                        blacklists={this.state.blacklists}
                        onClose={this.onCloseAddTermsToBlacklists}
                        onBlacklistsChanged={this.onBlacklistsChanged}
                        onAddTermsToBlacklistSubmit={this.onAddTermsToBlacklistsSubmit}
                        userData={this.props.userData} />
                }
                <OverlayPanel
                    ref={(el) => this.infoOverlay = el}
                    style={{ /*maxWidth: '40vw',*/ maxWidth: 300, fontSize: 13 }}
                    className={`whiteSpaceBreakSpaces ${this.state.viewInfoCss}`}
                    dismissable={true}>
                    {this.state.viewInfoText}
                </OverlayPanel>
            </>
        );
    }
}

export default CooccurrencesAnalysis;