import './ExternalLinkIcon.css';


const ExternalLinkIcon = ({
    standardLinkIcon,
    styleClass = '',
    style = {},
}) => {
    return <span
        style={{ ...style }}
        className={`material-icons ${standardLinkIcon ? 'ext-link-standard' : ''} ${styleClass}`}>
        launch
    </span>;
}

export default ExternalLinkIcon;