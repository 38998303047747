import { useDispatch, useSelector } from 'react-redux';
import styles from './NotificationTopbar.module.scss'
import { useEffect, useState } from 'react';
import { selectActiveNotification } from '../../../redux/selectors/notifications';
import { getNotifications } from '../../../redux/actions';
import classNames from 'classnames';

const NotificationTopbar = (userData) => {
    const [isNotificationBarShow, setIsNotificationBarShow] = useState(false);
    const notification = useSelector(selectActiveNotification);
    const dispatch= useDispatch()

    useEffect(() => {
        const hasNotification = !!notification?.text;
        setIsNotificationBarShow(hasNotification);
    }, [notification]);

    useEffect(() => {
        const isNoSuperAdmin = userData?.userData?.userDetails?.highestAdminType !== 'ROLE_SUPER_ADMIN'
        dispatch(getNotifications(isNoSuperAdmin));
      }, []);
    
    const handleCloseNotificationBar = () => {
        setIsNotificationBarShow(false);
    };

    return <div>
        {isNotificationBarShow && (
            <>
                <div className={styles.notificationBar}>
                    <div className={styles.notificationText}>{notification?.text}</div>
                    <i
                        aria-hidden
                        onClick={handleCloseNotificationBar}
                        className={classNames('pi pi-times', styles.closeNotificationBarButton)}
                    />
                </div>
            </>
        )}
    </div>
}

export default NotificationTopbar;