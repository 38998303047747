// === number of hits === //
import { createTopHitDocumentsRequest, fetchTopHitDocuments } from "../../../../api/content/DocumentApi";
import { RESPONSE_STATUS_SUCCESS } from "../../../../properties";
import { isArrayEmpty, isObjectEmpty } from "../../util";
import {
  createAllQueryStrings,
  createAllTermQueryStrings,
} from "./queryConverter";

export const fetchNumberOfHitsForSearchGroups = async (
  searchGroups,
  filterDefinitions,
  previewRepository,
  numberOfResultsPrevious,
  queryStringResultsMap = {},
) => {
  const numberOfResultsNew = numberOfResultsPrevious
    ? { ...numberOfResultsPrevious }
    : {};
  const queryStringResultsMapNew = {};

  let response;

  if (!isArrayEmpty(searchGroups)) {
    const queryStringsNew = await createAllQueryStrings(
      searchGroups,
      filterDefinitions
    );
    const queryStringsChanged = {};
    Object.entries(queryStringsNew).forEach(([groupID, queryObject]) => {
      if (queryStringResultsMap[queryObject.query]) {
        numberOfResultsNew[groupID] = queryStringResultsMap[queryObject.query];
        queryStringResultsMapNew[queryObject.query] =
          queryStringResultsMap[queryObject.query];
      } else {
        queryStringsChanged[groupID] = queryObject;
      }
    });

    if (!isObjectEmpty(queryStringsChanged)) {
      const request = createTopHitDocumentsRequest(queryStringsChanged);
      response = await fetchTopHitDocuments(previewRepository, request); // 2

      if (response.status === RESPONSE_STATUS_SUCCESS) {
        const results = response?.payload?.results || {};
        Object.entries(results).forEach(([groupID, resultObject]) => {
          numberOfResultsNew[groupID] = resultObject;
          const qry = queryStringsChanged[groupID]?.query;
          queryStringResultsMapNew[qry] = resultObject;
        });
        response.payload = {
          numberOfResults: numberOfResultsNew,
          queryStringResultsMap: queryStringResultsMapNew,
        };
        return response;
      }
      // TODO: error handling
    }
  }

  response = {
    status: RESPONSE_STATUS_SUCCESS,
    payload: {
      numberOfResults: numberOfResultsNew,
      queryStringResultsMap: queryStringResultsMapNew,
    },
  };
  return response;
};

export const fetchNumberOfHitsForQueryTerms = async (
  queryTerms,
  filterDefinitions,
  previewRepository,
  numberOfResultsPrevious,
  queryStringResultsMap = {},
) => {
  const numberOfResultsNew = numberOfResultsPrevious
    ? { ...numberOfResultsPrevious }
    : {};
  const queryStringResultsMapNew = {};

  if (!isArrayEmpty(queryTerms)) {
    const queryStringsNew = createAllTermQueryStrings(queryTerms, filterDefinitions);
    const queryStringsChanged = {};
    Object.entries(queryStringsNew).forEach(([qtID, queryObject]) => {
      if (queryStringResultsMap[queryObject.query]) {
        numberOfResultsNew[qtID] = queryStringResultsMap[queryObject.query];
        queryStringResultsMapNew[queryObject.query] =
          queryStringResultsMap[queryObject.query];
      } else {
        queryStringsChanged[qtID] = queryObject;
      }
    });

    if (!isObjectEmpty(queryStringsChanged)) {
      const request = createTopHitDocumentsRequest(queryStringsChanged);
      const response = await fetchTopHitDocuments(previewRepository, request); // 2
      const results = response?.payload?.results || {};

      Object.entries(results).forEach(([qtID, resultObject]) => {
        numberOfResultsNew[qtID] = resultObject;
        const qry = queryStringsChanged[qtID]?.query;
        queryStringResultsMapNew[qry] = resultObject;
      });
    }
  }

  // TODO: rename props, error handling, return status and payload

  return { numberOfResultsNew, queryStringResultsMapNew };
};
