import { APP_PROPERTIES } from "../../../../properties";


const readChemistryFilterValuesDefinitions = async () => {

    const FILTER_DEFINITIONS_FILE = `${APP_PROPERTIES.FRONTEND_URL}data/chem_filter_value_definitions.csv?${Math.random()}`;
    //console.log('readFilterDefinitions');
    const filterValues = {};
    await fetch(FILTER_DEFINITIONS_FILE).then($ => $.text()).then(file => {
        //console.log('*file: ', file);
        const allLines = file.split(/\r\n|\n/);
        allLines.forEach((line) => {
            //console.log('line: ', line);
            if (line && !line.startsWith('#')) {
                const elements = line.split('\t');

                let [field, type, map_key, map_value, value, booster, label, description,
                    isDefault, url, repoAllowList, attrAllowList, molAllowList, rxnAllowList] = elements;
                isDefault = isDefault == 'true';
                repoAllowList = repoAllowList ? repoAllowList.split(',') : null;
                repoAllowList = repoAllowList?.map(repo => repo.trim());
                const attrAllowMap = {}, molAllowMap = {}, rxnAllowMap = {};

                if (type === 'KEY_VALUE_MAP') {
                    if (!filterValues.hasOwnProperty(field)) {
                        filterValues[field] = {};
                    }
                    filterValues[field][map_key] = map_value;
                }
                else if (type === 'OBJECT_MAP' || type === 'OBJECT_ARRAY') {
                    const obj = {
                        filterValue: value ? value : map_key,
                        filterValueLabel: label,
                    }
                    if (booster) {
                        obj.booster = booster;
                    }
                    if (description) {
                        obj.description = description;
                    }
                    if (isDefault) {
                        obj.isDefault = isDefault;
                    }
                    if (url) {
                        obj.url = url;
                    }
                    if (repoAllowList) {
                        obj.repoAllowList = repoAllowList;
                    }
                    if (attrAllowList) {
                        attrAllowList.split(',').forEach(allow => { attrAllowMap[allow] = true });
                        obj.attrAllowMap = attrAllowMap;
                    }
                    if (molAllowList) {
                        molAllowList.split(',').forEach(allow => { molAllowMap[allow] = true });
                        obj.molAllowMap = molAllowMap;
                    }
                    if (rxnAllowList) {
                        rxnAllowList.split(',').forEach(allow => { rxnAllowMap[allow] = true });
                        obj.rxnAllowMap = rxnAllowMap;
                    }
                    if (type === 'OBJECT_MAP') {
                        if (!filterValues.hasOwnProperty(field)) {
                            filterValues[field] = {};
                        }
                        filterValues[field][map_key] = obj;
                    }
                    else if (type === 'OBJECT_ARRAY') {
                        if (!filterValues.hasOwnProperty(field)) {
                            filterValues[field] = [];
                        }
                        filterValues[field].push(obj);
                    }
                }
            }
        });
    });
    //console.log('text: ', text);
    //FILTERS.filters = filterValues;

    return filterValues;
}

export const readChemistryFilterDefinitions = async () => {

    const FILTER_VALUE_DEFINITIONS_FILE = `${APP_PROPERTIES.FRONTEND_URL}data/chem_filter_definitions.csv?${Math.random()}`;
    const filterValuesDefinitions = await readChemistryFilterValuesDefinitions();
    const activeFacets = {};

    //console.log('readFilterDefinitions');
    const filterValues = {};
    await fetch(FILTER_VALUE_DEFINITIONS_FILE).then($ => $.text()).then(file => {
        //console.log('*file: ', file);
        const allLines = file.split(/\r\n|\n/);
        allLines.forEach((line) => {
            //console.log('line: ', line);
            if (line && !line.startsWith('#')) {
                const elements = line.split('\t');
                // --- remove first column (active status) --- //
                elements.shift();

                let [id, type, label, placeholder, placeholder2, def_valueLabels, def_values, minValue, maxValue, defaultValue, unit, maxNumOfOcc, openOnDefault, isAdditionalFilter] = elements;
                openOnDefault = openOnDefault == 'true';
                isAdditionalFilter = isAdditionalFilter == 'true';

                if (id && type && label) {
                    filterValues[id] = {
                        id: id,
                        type: type,
                        label: label,
                        openOnDefault: openOnDefault,
                        isAdditionalFilter: isAdditionalFilter
                    };
                    if (placeholder) {
                        filterValues[id].placeholder = placeholder;
                    }
                    if (placeholder2) {
                        filterValues[id].placeholder2 = placeholder2;
                    }
                    if (def_valueLabels && filterValuesDefinitions && filterValuesDefinitions[def_valueLabels]) {
                        filterValues[id].filterValueLabels = filterValuesDefinitions[def_valueLabels]
                    }
                    if (def_values && filterValuesDefinitions && filterValuesDefinitions[def_values]) {
                        filterValues[id].filterValues = filterValuesDefinitions[def_values]
                    }
                    if (minValue) {
                        filterValues[id].minValue = parseInt(minValue);
                    }
                    if (maxValue) {
                        filterValues[id].maxValue = parseInt(maxValue);
                    }
                    if (defaultValue) {
                        filterValues[id].defaultValue = parseInt(defaultValue);
                    }
                    if (maxNumOfOcc) {
                        filterValues[id].maxNumOfOcc = maxNumOfOcc;
                    }
                    if (unit) {
                        filterValues[id].unit = unit;
                    }
                    // --- collect active facets --- //
                    /*
                    if ((type === SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY || type === SEARCH_FILTER_TYPE_FACETS_WITH_INPUT) && facetID) {
                        activeFacets[facetID] = true;
                    }
                    */
                }
            }
        });
    });
    return { filterDefinitions: filterValues, activeFacets: activeFacets };
}

export const readChemistryDBFilterMapping = async () => {

    const FILTER_REPOSITORY_MAPPING_FILE = `${APP_PROPERTIES.FRONTEND_URL}data/chem_filter_chemdb_map.csv?${Math.random()}`;
    //console.log('readFilterDefinitions');
    const repositoryFilterMap = {};
    let repoIDs = [];
    await fetch(FILTER_REPOSITORY_MAPPING_FILE).then($ => $.text()).then(file => {
        //console.log('*file: ', file);
        const allLines = file.split(/\r\n|\n/);
        let lineCounter = 0;
        allLines.forEach((line) => {
            // --- extract repositories from row --- //
            if (lineCounter === 0) {
                //console.log('repo line: ', line);
                let repos = line.split('\t');
                //console.log('repos array: ', repos);
                if (repos) {
                    repos.shift();
                    repoIDs = repos;
                    repos.forEach(repoID => {
                        repositoryFilterMap[repoID] = [];
                    });
                }
                lineCounter++;
            }
            else if (!line.startsWith('#')) {
                const activeStatus = line.split('\t');
                const filterID = activeStatus.shift();
                activeStatus.forEach((activeStat, index) => {
                    if (activeStat === '1' && filterID && repoIDs[index] && repositoryFilterMap[repoIDs[index]]) {
                        repositoryFilterMap[repoIDs[index]].push(filterID);
                    }
                });
            }
        });
    });
    //console.log('repositoryFilterMap: ', repositoryFilterMap);
    //FILTERS.filters = filterValues;
    return repositoryFilterMap;
}