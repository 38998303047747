/**
 * Creates request objects containing queries for a list of concepts, using their OCIDs.
 * Creates queries for patents, patent claims and publications.
 * @param {*} concepts 
 * @param {*} patentsRepository 
 * @param {*} publicationsRepository 
 * @returns 
 */
 export const createDocCountRequestsForConcepts = (concepts, patentsRepository, publicationsRepository) => {
    const patentRequest = { queries: {} };
    const pubRequest = { queries: {} };
    for (var concept of concepts) {
        const ocid = concept.ocid;

        // --- add query for patents for each OCID --- //
        if (patentsRepository) {
            const query = `+ocid:${ocid} +smode:s`;
            patentRequest.queries[`patent:${ocid}`] = {
                "query": query,
                "repository": patentsRepository.name
            }
        }
        // --- add query for patent claims for each OCID --- //
        if (patentsRepository) {
            const query = `+termloc:claims +ocid:${ocid} +smode:s`;
            patentRequest.queries[`claim:${ocid}`] = {
                "query": query,
                "repository": patentsRepository.name
            }
        }
        // --- add query for publications for each OCID --- //
        if (publicationsRepository) {
            const query = `ocid:${ocid} +smode:s`;
            pubRequest.queries[ocid] = {
                "query": query,
                "repository": publicationsRepository.name
            }
        }
    }
    return { patentRequest, pubRequest };
}

/**
 * Determines the repository to use for retrieving number of document hits.
 * Returns the first active repository from a list of available repositories.
 * @param {*} repositories 
 * @param {*} allowedRepos 
 * @returns 
 */
 export const determineRepositoryForDocCount = (repositories, allowedRepos) => {
    let docCountRepository;
    if (allowedRepos) {
        for (let patRepo of allowedRepos) {
            docCountRepository = repositories?.find(rep => rep.active === true && rep.name === patRepo);
            if (docCountRepository) {
                return docCountRepository;
            }
        }
    }
    return docCountRepository;
}

/**
 * Extracts document hit counts from response object and puts them in a map
 * with OCIDs as keys and hit counts as values. 
 * @param {*} response 
 * @param {*} repID 
 * @param {*} occurrencesMap 
 * @returns 
 */
export const extractHitCountsFromResult = (response, repID, occurrencesMap = {}) => {
    if (response.status === 'SUCCESS') {
        //console.log('ctResp: ', ctResp);
        if (response.payload && response.payload[repID]) {
            Object.entries(response.payload[repID].results).forEach(([ocid, res]) => {
                if (res) { occurrencesMap[ocid] = res.hitCount ? res.hitCount : 0; }
            });
        }
    }
    return occurrencesMap;
}

/**
 * Extracts patents-specific document hit counts from response object and puts them in a map
 * with OCIDs as keys and hit counts as values. 
 * @param {*} response 
 * @param {*} repID 
 * @param {*} patentsMap 
 * @param {*} claimsMap 
 * @returns 
 */
export const extractPatentsHitCountsFromResults = (response, repID, patentsMap = {}, claimsMap = {}) => {
    if (response.status === 'SUCCESS') {
        //console.log('ctResp: ', ctResp);
        if (response.payload && response.payload[repID]) {
            Object.entries(response.payload[repID].results).forEach(([key, res]) => {
                if (res) {
                    if (key.startsWith('claim:')) {
                        const ocid = key.replace('claim:', '');
                        claimsMap[ocid] = res.hitCount ? res.hitCount : 0;
                    }
                    else if (key.startsWith('patent:')) {
                        const ocid = key.replace('patent:', '');
                        patentsMap[ocid] = res.hitCount ? res.hitCount : 0;
                    }
                }
            });
            //this.setState({ numOfDocsPublications: pubOcc });
            //console.log('test occurrencesMap: ', occurrencesMap);
        }
    }
    return { patentsMap, claimsMap };
}