import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'primereact/button';
const ButtonAndDropdown = ({ buttonClickHandler, dropDownClickHandler, isDisabled, extraStyles = [], text = '', ...rest }) => {
    return <span className="p-buttonset">
        <Button
            className={classNames('primaryButton p-button-sm', ...extraStyles)}
            onClick={buttonClickHandler}
            disabled={isDisabled}
            label={text}
            {...rest}

        />
        <Button icon=" pi pi-angle-down "
            disabled={isDisabled}
            onClick={dropDownClickHandler}
            className='primaryButton p-button-sm' />
    </span>
}


ButtonAndDropdown.propTypes = {
    buttonClickHandler: PropTypes.func,
    dropDownClickHandler: PropTypes.func,
    text: PropTypes.object,
    extraStyles: PropTypes.arrayOf(PropTypes.string),
    isDisabled: PropTypes.bool
};
export default ButtonAndDropdown