import React from 'react';
import Link from "../../../../../common/Link/Link";
import {
    setBioactiviesTargetOcid,
    setBioactivitiesShowConceptPopup
} from "../../../../../../redux/actions/BioactivitiesActions";
import {useDispatch} from "react-redux";

export const ShowDetailsLink = ({ocid}) => {
    const dispatch = useDispatch();

    const showDetails = () => {
        dispatch(setBioactiviesTargetOcid(ocid));
        dispatch(setBioactivitiesShowConceptPopup(true));
    }

    return (
        <div className='bioactivities-structure'>
            <Link title='Show details' onClick={showDetails}/>
        </div>
    );
};
