import { Menu } from 'primereact/menu';
import { Button } from "primereact/button";
import { useRef } from "react";
import { exportCompoundsToSdfFile, exportCompoundsToSmiFile } from "../../../../../api/content/ChemistryApi";


const DownloadButton = ({
  ocid,
  popupStyle = {}
}) => {

  const menu = useRef(null);

  const OPTIONS = [
    { label: 'SMI file', command: (e) => onExportSMI() },
    { label: 'SDF file', command: (e) => onExportSDF() }
  ];

  const onExportSMI = () => {
    exportCompoundsToSmiFile([ocid]);
  }

  const onExportSDF = () => {
    exportCompoundsToSdfFile([ocid]);
  }

  return <>
    <Menu
      model={OPTIONS}
      popup
      ref={menu}
      style={popupStyle}
    />
    <Button
      className="primaryButton width100perc"
      label="Download structure"
      icon="pi pi-bars"
      onClick={(e) => menu.current.toggle(e)} />
  </>
}

export default DownloadButton;