import React, { //Children, 
  Component
} from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { isArrayEmpty, hasUserRole } from '../../../../../../util';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ANN_INFO_EXPLANATION } from '../../../../../../../../properties/ocmShowDocConf'
import { SOURCE_EXPLANATION } from '../../../../../../../../properties/ocmShowDocConf'
import { createChemSearchRequestFromConceptData, createCoocSearchRequestFromConceptData, createQuickSearchRequestFromConceptData, executeChemSearchShortcut, executeCoocShortcut, executeQuickSearchShortcut } from "../../../../../../util/shortcuts";
import './Popup.css'
//import { string } from "@amcharts/amcharts4/core";
import ExternalLinkIcon from "../../../../../../general/ExternalLinkIcon/ExternalLinkIcon";

interface Props {
  children: JSX.Element;
  onShowConcept: (ocid: any) => void;
  annTypeLabelsMap: any;
  domainColors: any;
  text: string;
}

interface State {
  activeIndex: number | 0;
  selectedLink: string;
  mouseOverLink: boolean;
  mouseOverIKey: boolean;
  mouseOverInchi: boolean;
  mouseOverSmiles: boolean;
  mouseOverFormula: boolean;
  mouseOverWeight: boolean;
}

class SharedPopup extends Component<Props, State> {
  container: HTMLDivElement | null = null;
  growl: Toast | null | undefined;
  op: OverlayPanel | null | undefined;
  state: State = {
    //domain: 'green',
    selectedLink: '',
    mouseOverLink: false,
    mouseOverIKey: false,
    mouseOverInchi: false,
    mouseOverSmiles: false,
    mouseOverFormula: false,
    mouseOverWeight: false,
    activeIndex: 0
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.children.props.children !== prevProps.children.props.children) {
      setTimeout(() => {
        this.setState({
          activeIndex: 0
        })
      }, 200)
    }
  }

  rowClassName = (rowData: any) => {
    if (rowData?.key === 'ocid') {
      return { 'title': true };
    } else {
      return { 'title': false }
    }
  }


  onShowConceptDetails = (ocid: any) => {
    this.props.onShowConcept(ocid)
  }

  enterButtonLink = (name: string) => {
    this.setState({
      mouseOverLink: true,
      selectedLink: name
    })
  }

  leaveButtonLink = () => {
    this.setState({
      mouseOverLink: false
    })
  }

  copyToClipboard = (content: string) => {
    const el = document.createElement('textarea');
    //el.style.visibility = "hidden";
    el.value = content;
    document.body.appendChild(el);
    el.select();
    //el.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(el);
    this.showCopySuccessGrowl(content)
  }

  showCopySuccessGrowl = (content: string) => {
    let msg = { summary: 'Success!', detail: `Successfully copied "${content}" to clipboard.` };
    this.growl?.show(msg);
  }

  onForwardClick = () => {
    let index = this.state.activeIndex
    index += 1
    this.setState({
      activeIndex: index
    })
  }

  onBackwardClick = () => {
    let index = this.state.activeIndex
    index -= 1
    this.setState({
      activeIndex: index
    })
  }


  extractChemCompound = (domain: string, isPdf: any, preferredName: string) => {
    if (domain === 'chemCompound') {
      const annId = localStorage.getItem('clickedAnnId') || ''
      return !isPdf ? document.getElementById(annId)?.innerText || '' : this.props.text
    } else {
      return preferredName
    }
  }


  setDomainName = (ocid: any, domain: any) => {
    if (domain === 'chemCompound') {
      return ocid?.length > 0 ? domain : 'tr'
    } else {
      return domain
    }
  }

  onSearch = (parameter: any, method: string) => {
    if (method === "chem") {
      //parameter.extendedData.smiles[0]
      const req = createChemSearchRequestFromConceptData(parameter.ocid, this.setDomainName(parameter.ocid, parameter.domain), this.extractChemCompound(parameter.domain, this.props.text ? true : false, parameter.preferredName));
      executeChemSearchShortcut(req, true);
    }
    else if (method === "quick") {
      const req = createQuickSearchRequestFromConceptData(parameter.ocid, this.setDomainName(parameter.ocid, parameter.domain), this.extractChemCompound(parameter.domain, this.props.text ? true : false, parameter.preferredName));
      executeQuickSearchShortcut(req, null, true);
    }
    else if (method === "cooc") {
      const req = createCoocSearchRequestFromConceptData(parameter.ocid, this.setDomainName(parameter.ocid, parameter.domain), this.extractChemCompound(parameter.domain, this.props.text ? true : false, parameter.preferredName));
      executeCoocShortcut(req, true);
    }
    this.op?.hide()
  }

  changeActiveIndex = (index: number) => {
    if (index !== this.state.activeIndex) {
      this.setState({ activeIndex: index });
    }
  }

  headerTemplate = (child: string, domain: string, counter: number) => {
    //console.log(this.props)
    let color = this.props.domainColors && this.props.domainColors.filter((obj: { name: string; }) => {
      return obj.name === domain
    })
    return (
      <div className="flex align-items-center px-3" style={{
        cursor: 'pointer', display: 'flex', overflow: 'hidden', textOverflow: 'ellipsis',
        whiteSpace: 'nowrap', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem'
      }} onClick={(e) => {
        this.setState({ activeIndex: counter })
      }}>
        {color.length !== 0 ?
          <div style={{
            width: 3, height: 11, border: '1px solid', borderColor: color[0].color ? '#' + color[0].color : 'black',
            background: color[0].color ? '#' + color[0].color : 'black', marginRight: 5, cursor: 'pointer'
          }} onClick={(e) => {
            this.setState({ activeIndex: counter })
          }}></div>
          : null
        }
        <label style={{ color: 'black', cursor: 'pointer', fontWeight: this.state.activeIndex === counter ? 'bold' : 'normal' }} onClick={(e) => {
          this.setState({ activeIndex: counter })
        }}>{child}</label>
      </div>
    )
  }

  render() {
    // eslint-disable-next-line
    const { children, annTypeLabelsMap, domainColors, ...restProps } =
      this.props;

    const prefOrder = ["chem", "drugs", "substances", "natprod", "inorgmat", "nutrition"]

    let sortedChildren: any[] = []

    let filteredChildren = children.props.children?.filter((child: { additionalData: { hidden: any; }; }) => child.additionalData !== undefined && !child.additionalData.hidden)

    if (filteredChildren.length > 0) {
      prefOrder.forEach(dom => {
        filteredChildren.forEach((child: { domain: string; }) => {
          if (child.domain === dom) {
            sortedChildren = [...sortedChildren, child]
          }
        })
      })
    }

    if (filteredChildren.length > 0 && filteredChildren.length !== sortedChildren.length) {
      filteredChildren.forEach((child: { domain: string; }) => {
        if (!prefOrder.includes(child.domain)) {
          sortedChildren = [...sortedChildren, child]
        }
      })
    }

    let childrenWithNumbers = sortedChildren.reduce(function (obj: any, v: any) {
      obj[v.domain] = (obj[v.domain] || 0) + 1
      return obj
    }, {})

    let newChildren: { child: any; count: number }[] = []
    Object.keys(childrenWithNumbers).forEach(child => {
      if (childrenWithNumbers[child] < 2) {
        newChildren.push({ child: child, count: 0 })
      } else {
        for (let i = 1; i <= childrenWithNumbers[child]; i++) {
          newChildren.push({ child: child, count: i })
        }
      }
    })

    const shortCuts = [{ label: 'Quick Search', shortLabel: 'quick' }]
    if (hasUserRole("ROLE_COMPOUNDSEARCH")) {
      shortCuts.push({ label: 'Chemistry Search', shortLabel: 'chem' })
    }
    if (hasUserRole("ROLE_COOCS")) {
      shortCuts.push({ label: 'Co-occurrence Analysis', shortLabel: 'cooc' })
    }

    return (
      <div>
        <Toast ref={(el) => { this.growl = el }} position='bottom-center' />
        {filteredChildren.length !== 0 ?
          <React.Fragment>
            <div style={{ textAlign: 'center', marginBottom: -8 }}>
              <Button style={{ background: 'transparent', border: 'none', verticalAlign: 'middle' }} icon="pi pi-angle-left" className="switchButton p-button-rounded  p-button-secondary" onClick={() => this.onBackwardClick()} disabled={this.state.activeIndex === 0 ? true : false} />
              <span style={{ paddingLeft: 8, paddingRight: 8, color: filteredChildren.length === 1 ? "black" : "black", fontWeight: 'bold' }}>{`Concept (${this.state.activeIndex + 1}/${filteredChildren.length})`}</span>
              <Button style={{ background: 'transparent', border: 'none' }} icon="pi pi-angle-right" className="switchButton p-button-rounded  p-button-secondary" onClick={() => this.onForwardClick()} disabled={this.state.activeIndex < filteredChildren.length - 1 ? false : true} />
            </div>
            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
              {sortedChildren.map((child: any, counter: number) => {
                return <TabPanel closeIcon='' nextButton={''} prevButton={''} key={`${counter}-${annTypeLabelsMap[child.domain]}`}
                  header={annTypeLabelsMap[child.domain] ? newChildren[counter].count !== 0 ?
                    `${annTypeLabelsMap[newChildren[counter].child]} (${newChildren[counter].count}/${childrenWithNumbers[child.domain]})` :
                    `${annTypeLabelsMap[newChildren[counter].child]}` : ''}
                  headerTemplate={this.headerTemplate(annTypeLabelsMap[child.domain] ? newChildren[counter].count !== 0 ?
                    `${annTypeLabelsMap[newChildren[counter].child]} (${newChildren[counter].count}/${childrenWithNumbers[child.domain]})` :
                    `${annTypeLabelsMap[newChildren[counter].child]}` : '', child.domain, counter)}
                  headerClassName="flex align-items-center">
                  <span style={{ color: "#757575" }}>Main term</span>
                  <span style={{ color: "#757575", paddingLeft: 5, paddingRight: 5 }}>|</span>
                  <span style={{ color: "#757575" }}>{`OCID: ${child.ocid ? child.ocid : ""}`}</span>
                  <span style={{ float: 'right', marginRight: -18, marginBottom: -8 }}>

                    <Button style={{ backgroundColor: 'white', border: 'none', float: 'right', marginTop: -9, paddingRight: 0 }}
                      onClick={(e) => this.op?.toggle(e, null)}>
                      <span style={{ color: 'black', paddingLeft: "0px !important" }}>Search in ...</span>
                      <i className="pi pi-angle-down p-px-2" style={{ color: 'black', paddingRight: 5 }}></i>
                    </Button>
                    <OverlayPanel className='tutorialOverlay' ref={(el) => { this.op = el }} style={{ borderRadius: '1px', marginTop: 0 }}>
                      {child.extendedData && !isArrayEmpty(child.extendedData.smiles)
                        ?
                        shortCuts.map((cut, i) => {
                          return <div key={`${counter}-${cut.shortLabel}`} style={{}}>
                            <a key={cut.shortLabel} title={`${cut.label}`}
                              style={{ display: 'inline-block', color: 'black', paddingBottom: 5, paddingTop: 5 }}
                              onClick={() => this.onSearch(child, cut.shortLabel)}>
                              {`${cut.label}`}</a>
                          </div>
                        })
                        :
                        shortCuts.filter(cut => cut.shortLabel !== 'chem').map((cut, i) => {
                          return <div key={`${counter}-${cut.shortLabel}`} style={{}}>
                            <a key={cut.shortLabel} title={`${cut.label}`}
                              style={{ display: 'inline-block', color: 'black', paddingBottom: 5, paddingTop: 5 }}
                              onClick={() => this.onSearch(child, cut.shortLabel)}>
                              {`${cut.label}`}</a>
                          </div>
                        })
                      }
                    </OverlayPanel>
                  </span>
                  <div key={`${counter}-${child.preferredName}`} style={{ color: '#0973c0', fontWeight: 'bold', fontSize: 16, paddingTop: 0, paddingBottom: 10 }}>
                    <a title={child.preferredName ? child.preferredName : ""} style={{ width: 270, display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => this.onShowConceptDetails(child.ocid)}>{child.preferredName ? child.preferredName : ""}</a>
                  </div>
                  {!isArrayEmpty(child.extendedData?.definition) ?
                    <div className="_croppedTableCell"
                      style={{ marginBottom: 15 }}>
                      <div style={{ fontWeight: 'bold', paddingBottom: 5 }}>Definition</div>
                      {child.extendedData.definition.map((def: string) => {
                        return <div key={def}>{def}</div>
                      })}</div> : null
                  }
                  {child.synonyms && child.synonyms.length > 0 ?
                    <div style={{ paddingBottom: 15 }}>
                      <div style={{ fontWeight: 'bold', paddingBottom: 5 }}>Synonyms</div>
                      {
                        child.synonyms.map((syn: any, counter: number) => {
                          return counter < 4 && <div style={{
                            paddingBottom: 0,
                            display: 'block',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: 330
                          }} key={`${syn}`} title={syn}>{syn}</div>
                        })
                      }
                      {child.synonyms.length > 4 &&
                        <div style={{
                          paddingBottom: 3
                        }}>
                          <a onClick={() => this.onShowConceptDetails(child.ocid)}>{`See ${child.synonyms.length - 4} more on concept details page`}</a>
                        </div>
                      }
                    </div>
                    : <div style={{ paddingBottom: 15 }}>
                      <div style={{ fontWeight: 'bold', paddingBottom: 5 }}>Synonyms</div>
                      <div style={{ paddingBottom: 3, color: '#757575' }}>None</div>
                    </div>
                  }
                  {child.sourceReferences && child.sourceReferences.length > 0 ?
                    <div style={{ paddingBottom: 15 }}>
                      <div style={{ fontWeight: 'bold', paddingBottom: 5 }}>
                        External sources
                        <ExternalLinkIcon standardLinkIcon={true} />
                      </div>
                      {
                        child.sourceReferences.map((src: any, counter: number) => {
                          return counter < 3 && <div key={`${src.conceptUrl}`} style={{ paddingBottom: 0 }}>
                            <a
                              style={{ color: "black", width: 300, display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                              rel="noopener noreferrer"
                              target="_blank"
                              href={src.conceptUrl}
                              onMouseLeave={() => this.leaveButtonLink()}
                              onMouseEnter={() => this.enterButtonLink(src.conceptUrl)}>
                              {src.sourceName}
                            </a>
                          </div>
                        })
                      }
                      {child.sourceReferences.length > 3 &&
                        <div style={{
                          paddingBottom: 3
                        }}>
                          <a onClick={() => this.onShowConceptDetails(child.ocid)}>{`See ${child.sourceReferences.length - 3} more on concept details page`}</a>
                        </div>
                      }
                    </div>
                    : <div style={{ paddingBottom: 15 }}>
                      <div style={{ fontWeight: 'bold', paddingBottom: 5 }}>External sources</div>
                      <div style={{ paddingBottom: 3, color: '#757575' }}>None</div>
                    </div>
                  }
                  {child.extendedData && child.extendedData.ikey ?
                    <div style={{ paddingTop: 0, display: 'flex', paddingBottom: 3 }}>
                      <span style={{ fontWeight: 'bold', width: 75, display: 'block' }}>InChi Key</span>
                      <span style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 280
                      }}
                        title={child.extendedData.ikey}
                        onMouseLeave={() => this.setState({ mouseOverIKey: false })}
                        onMouseEnter={() => this.setState({ mouseOverIKey: true })}>{`${child.extendedData.ikey[0].substring(0, 33)} ${child.extendedData.ikey[0].length > 33 ? "..." : ""}`}
                        {this.state.mouseOverIKey &&
                          <a id="detailSbjct"
                            title='Copy to clipboard'
                            onClick={() => this.copyToClipboard(child.extendedData.ikey[0])}
                            style={{ display: 'inline-block', float: 'right', marginRight: 0 }}>Copy</a>
                        }</span>
                    </div>
                    :
                    null
                  }
                  {child.extendedData && child.extendedData.inchi ?
                    <div style={{ paddingTop: 0, display: 'flex', paddingBottom: 3 }}>
                      <span style={{ fontWeight: 'bold', width: 75, display: 'block' }}>InChi</span>
                      <span style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 280
                      }}
                        title={child.extendedData.inchi}
                        onMouseLeave={() => this.setState({ mouseOverInchi: false })}
                        onMouseEnter={() => this.setState({ mouseOverInchi: true })}>{`${child.extendedData.inchi[0].substring(0, 33)} ${child.extendedData.inchi[0].length > 33 ? "..." : ""}`}
                        {this.state.mouseOverInchi &&
                          <a id="detailSbjct"
                            title='Copy to clipboard'
                            onClick={() => this.copyToClipboard(child.extendedData.inchi[0])}
                            style={{ display: 'inline-block', float: 'right', marginRight: 0 }}>Copy</a>
                        }</span>
                    </div>
                    :
                    null
                  }
                  {child.extendedData && child.extendedData.smiles ?
                    <div style={{ paddingTop: 0, display: 'flex', paddingBottom: 3 }}>
                      <span style={{ fontWeight: 'bold', width: 75, display: 'block' }}>SMILES</span>
                      <span style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 280
                      }}
                        title={child.extendedData.smiles}
                        onMouseLeave={() => this.setState({ mouseOverSmiles: false })}
                        onMouseEnter={() => this.setState({ mouseOverSmiles: true })}>{`${child.extendedData.smiles[0].substring(0, 33)} ${child.extendedData.smiles[0].length > 33 ? "..." : ""}`}
                        {this.state.mouseOverSmiles &&
                          <a id="detailSbjct"
                            title='Copy to clipboard'
                            onClick={() => this.copyToClipboard(child.extendedData.smiles[0])}
                            style={{ display: 'inline-block', float: 'right', marginRight: 0 }}>Copy</a>
                        }</span>
                    </div>
                    :
                    null
                  }
                  {child.extendedData && child.extendedData.formula ?
                    <div style={{ paddingTop: 0, display: 'flex', paddingBottom: 3 }}>
                      <span style={{ fontWeight: 'bold', width: 75, display: 'block' }}>Formula</span>
                      <span style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 280
                      }} title={child.extendedData.formula}
                        onMouseLeave={() => this.setState({ mouseOverFormula: false })}
                        onMouseEnter={() => this.setState({ mouseOverFormula: true })}>{`${child.extendedData.formula[0].substring(0, 33)} ${child.extendedData.formula[0].length > 33 ? "..." : ""}`}
                        {this.state.mouseOverFormula &&
                          <a id="detailSbjct"
                            title='Copy to clipboard'
                            onClick={() => this.copyToClipboard(child.extendedData.formula[0])}
                            style={{ display: 'inline-block', float: 'right', marginRight: 0 }}>Copy</a>
                        }</span>
                    </div>
                    :
                    null
                  }
                  {child.extendedData && child.extendedData.molWeight ?
                    <div style={{ paddingTop: 0, display: 'flex', paddingBottom: 10 }}>
                      <span style={{ fontWeight: 'bold', width: 75, display: 'block' }}>Mol weight</span>
                      <span style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 280
                      }} title={child.extendedData.molWeight}
                        onMouseLeave={() => this.setState({ mouseOverWeight: false })}
                        onMouseEnter={() => this.setState({ mouseOverWeight: true })}>{`${child.extendedData.molWeight[0].substring(0, 33)} ${child.extendedData.molWeight[0].length > 33 ? "..." : ""}`}
                        {this.state.mouseOverWeight &&
                          <a id="detailSbjct"
                            title='Copy to clipboard'
                            onClick={() => this.copyToClipboard(child.extendedData.molWeight[0])}
                            style={{ display: 'inline-block', float: 'right', marginRight: 0 }}>Copy</a>
                        }</span>
                    </div>
                    :
                    null
                  }
                  <div style={{ paddingTop: child.extendedData?.molWeight || child.extendedData?.formula ? 10 : 0 }}>
                    {child.additionalData && child.additionalData.src &&
                      <React.Fragment>
                        <span style={{ color: "#757575" }} title={SOURCE_EXPLANATION[child.additionalData.src as keyof typeof SOURCE_EXPLANATION] ? SOURCE_EXPLANATION[child.additionalData.src as keyof typeof SOURCE_EXPLANATION] : child.additionalData.src}>{`Source: ${child.additionalData && child.additionalData.src ? child.additionalData.src : "-"}`}</span>
                        <span style={{ color: "#757575", paddingLeft: 5, paddingRight: 5 }}>|</span>
                      </React.Fragment>
                    }
                    <span style={{ color: "#757575" }} title={ANN_INFO_EXPLANATION["confidence"] ? ANN_INFO_EXPLANATION["confidence"] : "Confidence"}>{`Confidence: ${child.confidence ? child.confidence : "-"}`}</span>
                    {child.relevanceScore ?
                      <React.Fragment>
                        <span style={{ color: "#757575", paddingLeft: 5, paddingRight: 5 }}>|</span>
                        <span style={{ color: "#757575" }} title={ANN_INFO_EXPLANATION["relevanceScore"] ? ANN_INFO_EXPLANATION["relevanceScore"] : "Relevance"}>{`Relevance: ${child.relevanceScore}`}</span>
                      </React.Fragment>
                      : null
                    }
                  </div>
                  <div style={{ paddingTop: 5 }}>
                    <span style={{ color: "#757575" }}>{`Annotation type label: ${child.annTypeLabel ? child.annTypeLabel : "-"}`}</span>
                  </div>
                </TabPanel>
              })}
            </TabView>
          </React.Fragment>
          :
          <div style={{ textAlign: 'center', height: 50 }}>
            <p style={{ marginTop: 30 }}>No concept information available.</p>
          </div>
        }
      </div>
    )
  }
}

export default SharedPopup
