import { InputText } from "primereact/inputtext";
import DateRangeInfo from "../../../../../infos/DateRangeInfo";

const FilterTermDateRangeInput = ({ values, onValuesChange }) => {
  const startDate = values?.val?.startDate || "";
  const endDate = values?.val?.endDate || "";

  return (
    <div className="flex align-items-center justify-content-start flex-wrap gap-2">
      <label className="date-filter-label" htmlFor="startDate">
        From:
      </label>
      <InputText
        id="startDate"
        value={startDate}
        style={{ width: 110 }}
        autoComplete="off"
        onInput={(e) => {
          const startDateNew = e.target.value || "";
          const valuesNew = {};
          if (startDateNew) {
            valuesNew.startDate = startDateNew;
          }
          if (endDate) {
            valuesNew.endDate = endDate;
          }
          onValuesChange({
            val: valuesNew,
            queryPart: !endDate ? startDateNew : `${startDateNew}_${endDate}`,
            label: `${startDateNew} - ${endDate}`,
          });
        }}
        placeholder={`e.g. 2021-11-17`}
      />
      <label className="date-filter-label" htmlFor="endDate">
        to:
      </label>
      <InputText
        id="endDate"
        value={endDate}
        style={{ width: 110 }}
        autoComplete="off"
        onInput={(e) => {
          const endDateNew = e.target.value || "";
          const valuesNew = {};
          if (startDate) {
            valuesNew.startDate = startDate;
          }
          if (endDateNew) {
            valuesNew.endDate = endDateNew;
          }
          onValuesChange({
            val: valuesNew,
            queryPart: !endDateNew ? startDate : `${startDate}_${endDateNew}`,
            label: `${startDate} - ${endDateNew}`,
          });
        }}
        placeholder={`e.g. 2021`}
      />
      <DateRangeInfo />
    </div>
  );
};

export default FilterTermDateRangeInput;
