import { sendApiRequest, sendExportToFileApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';


/*
PUT /api/v2/obo/ontology-tree/frames
GET /api/v2/obo/ontology-tree/frames
POST /api/v2/obo/ontology-tree/frames/check
GET /api/v2/obo/ontology-tree/frames/backups/{id}
*/

/**
 * Returns currently active frame ontology from pipeline.
 */
export const fetchFrameOntology = async () => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/obo/ontology-tree/frames`);
    //console.log('ontology: ', result);
    return result;
}
export const fetchFrameOntologyV2 = async () => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/obo/ontology-tree/frames`);
    //console.log('ontology: ', result);
    return result;
}

/**
 * Overwrites currently active frame ontology in pipeline with new version.
 * 
 * @param {Object} frameOntology frame ontology object to save
 */
export const saveFrameOntology = async (frameOntology, comment = null) => {

    if (comment) {
        frameOntology.comment = comment;
    }
    else {
        delete frameOntology.comment;
    }

    const result = await sendApiRequest('PUT', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/obo/ontology-tree/frames`, frameOntology);
    //console.log('ontology: ', result);
    return result;
}
export const saveFrameOntologyV2 = async (frameOntology, comment = null) => {

    if (comment) {
        frameOntology.comment = comment;
    }
    else {
        delete frameOntology.comment;
    }

    const result = await sendApiRequest('PUT', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/obo/ontology-tree/frames`, frameOntology);
    //console.log('ontology: ', result);
    return result;
}



/**
 * Exports the current knowledge cartridge.
 * 
 */
export const exportKnowledgeCartridge = async () => {
    await sendExportToFileApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/cartridges/knowledge`, 'cartridge.zip');
}


/**
 * Returns all stored frame ontology backups.
 */
export const retrieveFrameOntologyBackups = async () => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/obo/ontology-tree/frames/backups`);
    //console.log('backups: ', result);

    return result;
}

/**
 * Returns frame ontology from backup.
 * 
 * @param {string} id the ID of the backup ontology
 */
export const retrieveFrameOntologyBackup = async (id) => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/obo/ontology-tree/frames/backups/${id}`);
    //console.log('restore backup: ', result);

    return result;
}


export const checkFrameOntology = async (frameOntology) => {

    //console.log('check ontology: ', frameOntology);
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/obo/ontology-tree/frames/check`, frameOntology);
    //console.log('check result: ', result);

    return result;
}


/**
 * Generates phrase tagger output for text string.
 * 
 * @param {string} request text string for which phrase tagger output should be generated 
 */
export const runPhraseTagger = async (text) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/annotation/text`, { text: text });

    // --- error occurred --- //
    if (result.status === 'FAILED') {
        return result;
    }

    if (!result.payload || !result.payload.result || !result.payload.result['tagged-phrases']) {
        return { status: 'FAILED', message: 'No tagged phrases found. Consumer may be inactive or phrase is ignored by tagger.' };
    }

    const phraseTaggerSyntax = result.payload.result['tagged-phrases'];
    const result2 = await translatePhraseTaggerSyntaxStringToPhraseTokens(phraseTaggerSyntax);

    return result2;
}

/**
 * Generates BRAT output for text string.
 * 
 * @param {string} text text string for which BRAT output should be generated 
 */
export const runBRATGenerator = async (text) => {

    const req = { text: text };
    if (APP_PROPERTIES.BRAT_BASE_URL && APP_PROPERTIES.BRAT_BASE_URL !== '') {
        req.baseUrl = APP_PROPERTIES.BRAT_BASE_URL;
    }

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/annotation/text/brat`, req);

    return result;
}

/**
 * Translates phrase tagger syntax string to phrase tokens object.
 * 
 * @param {string} request syntax string which should be translated to list of phrase tokens 
 */
export const translatePhraseTaggerSyntaxStringToPhraseTokens = async (syntax) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/parser/tagged-syntax`, { syntax: syntax });

    return result;
}

/**
 * Translates syntax pattern string to phrase tokens object.
 * 
 * @param {string} request syntax string which should be translated to list of phrase tokens 
 */
export const translateSyntaxPatternStringToPhraseTokens = async (syntax) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/parser/pattern-syntax`, { syntax: syntax });

    //console.log('phrase tokens: ', result);

    return result;
}


/**
 * 
 * @param {Object} request phrase tokens which should be translated to syntax string
 */
export const translatePhraseTokensToSyntaxPatternString = async (phraseTokens) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/parser/phrase-tokens`, phraseTokens);
    //console.log('syntax: ', result);

    return result;
}


/**
 * Checks status of backend services. Middleware status is added to response depending on success/failure of request.
 */
export const checkStatusOfBackendServices = async () => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/services/status`);
    //console.log('services: ', result);

    if (result.status === 'SUCCESS') {
        // --- check if any service is unavailable --- //
        let error = false;
        for (const status of Object.values(result.payload)) {
            if (!status.available) {
                error = true;
                break;
            }
        }
        // --- add middleware status --- //
        result.payload['Middleware'] = {
            available: true,
            message: 'OK'
        };
        return { status: 'SUCCESS', payload: { services: result.payload, error: error } };
    }

    return {
        status: 'SUCCESS',
        payload: {
            services: {
                'Middleware': {
                    available: false,
                    message: 'Could not connect to middleware. ' + result.message
                }
            },
            error: true
        }
    }
}


/**
 * Response contains new token for user. Token has to be put in localStorage!
 */
export const refreshToken = async () => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/token`);
    //console.log('new token: ', result);

    return result;
}
