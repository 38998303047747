import loginBGImage from '../../assets/images/bg/shutterstock_707879404_small.44d88a51.44d88a51_min.jpg';
import appLogo from '../../assets/images/logos/boehringer/BI.png';

export const APP_PROPERTIES = {
  // --- logos for login page and header --- //
  LOGIN_BG_IMAGE: loginBGImage,

  LOGIN_LOGO_APP: appLogo,
  LOGIN_LOGO_APP_STYLE: { height: 40, width: 'auto', margin: '2px 5px' },

  HEADER_LOGO_APP: appLogo,
  HEADER_LOGO_APP_STYLE: { height: 50, padding: '5px 15px', marginLeft: -20 },

  // --- feedback and support --- //
  SUPPORT_FORM_URL:
    'https://forms.monday.com/forms/312f55bdb5829b7c7f342002021f19b7',

  // --- active pages --- //
  ACTIVE_PAGES: {
    docSearch: true,
    verificationPage: true,
  },

  // --- active modules or functionalities --- //
  ACTIVE_FUNCTIONALITIES: {
    showDocumentsOnStart: true,
    showIcons: true,
    fetchSortableFields: true,
    showJournalFilters: true,
  },

  // --- chemistry-related settings --- //
  CHEMISTRY: {
    showGoogleLinks: true,
  },
} as const;
