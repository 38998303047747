import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

class AddFeature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            featureLabel: '',
            featureName: '',
            featureCanBeDeleted: true,
            featureIsDefault: false
        }
    }


    onAddFeature = () => {
        this.props.onAddFeature(this.state.featureLabel, this.state.featureName, this.props.featureCanBeDeleted, this.props.featureIsDefault)
    }

    onCanBeDeletedChange = (e) => {
        if (e.checked) {
            this.setState({
                featureCanBeDeleted: true
            })
        } else {
            this.setState({
                featureCanBeDeleted: false
            })
        }
    }

    onIsDefaultChange = (e) => {
        if (e.checked) {
            this.setState({
                featureIsDefault: true
            })
        } else {
            this.setState({
                featureIsDefault: false
            })
        }
    }

    render() {

        const footerAddFeature = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelAddFeature}
                    style={{ marginRight: 5 }} />
                <Button label="Add"
                    className='p-button-sm primaryButton'
                    disabled={this.state.featureLabel !== '' && this.state.featureName !== '' ? false : true}
                    onClick={this.onAddFeature}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayAddFeature} style={{ 'width': "60vw" }} header="Add feature" focusOnShow={false}
                modal={true} footer={footerAddFeature} dismissableMask={false}
                onHide={() => this.props.onCancelAddFeature()} className='styledDialog'>
                <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="departmentName">Feature label (for displaying)</label>
                        <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="featureLabel"
                            onChange={(e) => { this.setState({ featureLabel: e.target.value }) }} value={this.state.featureLabel} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="departmentName">Feature name</label>
                        <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="featureName"
                            onChange={(e) => { this.setState({ featureName: e.target.value }) }} value={this.state.featureName} />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="departmentName">Can be deleted</label>
                        <div>
                            <Checkbox inputId='activateUserChecked'
                                onChange={(e) => this.onCanBeDeletedChange(e)}
                                checked={this.state.featureCanBeDeleted ? true : false}
                                value='Check to set if the feature can be deleted' style={{ 'cursor': 'pointer', marginTop: 5, paddingTop: 5 }} />
                        </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3" style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="departmentName">Is default</label>
                        <div>
                            <Checkbox inputId='activateUserChecked'
                                onChange={(e) => this.onIsDefaultChange(e)}
                                checked={this.state.featureIsDefault ? true : false}
                                value='Check to set if feature is default' style={{ 'cursor': 'pointer', marginTop: 5, paddingTop: 5 }} />
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default AddFeature