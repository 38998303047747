export const addSearchParamsToQueryTerms = (queryTerms) => {
   return queryTerms.map(term => {
        const termCopy = {...term}
        // checking simple text search
        if (termCopy.fieldName === 'simplesearch' && !termCopy.termLookupType) {
            termCopy.termLookupType = 'TERM_NORMALIZED';
            termCopy.occurrence = 'MUST';
        }

        if (!termCopy.conceptLookupType && !termCopy.termLookupType) {
            termCopy.conceptLookupType = 'ONTOLOGICAL';
            termCopy.occurrence = 'MUST';
        }

        return termCopy;
    })
}
