/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
//import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { OverlayPanel } from "primereact/overlaypanel";
//import { APP_PROPERTIES } from '../../../properties/index';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
//import dateFormat from 'dateformat';
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
import moment from 'moment';

import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'
import { REPO_ROLE_DOC_SEARCH } from '../../../properties';

class AlertList extends Component {
    constructor(props) {
        super(props);
        let columns = [
            //{ field: "check", selectionMode: 'multiple', style: { width: '4em', textAlign: 'center', fontWeight: 'normal' } },
            { field: "", header: "Entry", body: this.nameTemplate, sortable: true, style: { width: '25%' } },
            { field: "type", header: "Type", body: this.typeTemplate, sortable: true, style: { width: '10em' } },
            { field: "active", header: 'Status', body: this.alertStatusTemplate, style: { width: '10em' } },
            { field: "interval", header: 'Interval', body: this.intervalTemplate, style: { width: '10em' } },
            //{ field: "", header: "Repositories", body: this.repositoryTemplate, sortable: true, style: { width: '10%'}},
            { field: "nextSendingDate", header: 'Next email', body: this.sendingDateTemplate, style: { width: '10em' } },
            { field: "created", header: 'Created', body: this.insertionTimeTemplate, sortable: true, style: { width: '10em' } },
        ]
        this.state = {
            colsAlerts: columns,
            activeOptions: [
                { label: 'Active', value: 'active' },
                { label: 'Paused', value: 'paused' }
            ],
            selectedAlerts: []
        }
    }

    componentDidMount() {
        let selectedRepositoriesData = []
        let selectedRepositoriesRawData = []
        this.props.userData.userDetails.department?.selectedRepositories &&
            this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
                //console.log(rep)
                if (rep.active && rep.features?.includes(REPO_ROLE_DOC_SEARCH.id)) {
                    selectedRepositoriesData = [...selectedRepositoriesData, { label: rep.label, value: rep.id }]
                    selectedRepositoriesRawData = [...selectedRepositoriesRawData, rep]
                }
            })

        //console.log(this.props)
        this.setState({
            selectedRepositoriesData: selectedRepositoriesData,
            selectedRepositoriesRawData: selectedRepositoriesRawData
        })
    }


    componentDidUpdate(prevProps) {
        if (this.props.userData.userDetails !== prevProps.userData.userDetails) {
            let selectedRepositoriesData = []
            let selectedRepositoriesRawData = []
            this.props.userData.userDetails.department?.selectedRepositories &&
                this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
                    //console.log(rep)
                    if (rep.active && rep.features?.includes(REPO_ROLE_DOC_SEARCH.id)) {
                        selectedRepositoriesData = [...selectedRepositoriesData, { label: rep.label, value: rep.id }]
                        selectedRepositoriesRawData = [...selectedRepositoriesRawData, rep]
                    }
                })

            //console.log(this.props)
            this.setState({
                selectedRepositoriesData: selectedRepositoriesData,
                selectedRepositoriesRawData: selectedRepositoriesRawData
            })
        }
    }

    alertStatusTemplate = (rowData) => {
        if (rowData.active) {
            return <React.Fragment>
                <span className="p-column-title">Status</span>
                <span style={{}}>
                    <i className="material-icons" id={rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false ? '' : 'read'}
                        title={'Active alarm'}
                        onClick={(e) => this.props.alertStatusChange(rowData, e)}
                        style={{
                            marginTop: -1, verticalAlign: 'middle', color: rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false ? 'grey' : 'black'
                        }}>
                        {'notifications_active'}
                    </i>
                </span>
                <span className="p-column-title-invisible">
                    <Dropdown className='dropdownNoBorder'
                        style={{ marginTop: -3 }}
                        value={'active'}
                        disabled={rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false}
                        //onMouseDown={(e) => this.onDropdownMouseDown(e)}
                        //onFocus={(e) => this.onDropdownMouseDown(e)}
                        //onBlur={(e) => this.onDropdownMouseDown(e)}
                        options={this.state.activeOptions}
                        onChange={(e) => { this.props.alertStatusChange(rowData, e) }} />
                </span>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <span className="p-column-title">Status</span>
                <span style={{}}>
                    <i className="material-icons" id={rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false ? '' : 'read'}
                        title={'Paused alarm'}
                        onClick={(e) => this.props.alertStatusChange(rowData, e)}
                        style={{
                            marginTop: -1, verticalAlign: 'middle', color: rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false ? 'grey' : 'black'
                        }}>
                        {'notifications_paused'}
                    </i>
                </span>
                <span className="p-column-title-invisible">
                    <Dropdown className='dropdownNoBorder'
                        style={{ marginTop: -3 }}
                        value={'paused'}
                        options={this.state.activeOptions}
                        disabled={rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false}
                        //onMouseDown={(e) => this.onDropdownMouseDown(e)}
                        //onFocus={(e) => this.onDropdownMouseDown(e)}
                        //onBlur={(e) => this.onDropdownMouseDown(e)}
                        onChange={(e) => { this.props.alertStatusChange(rowData, e) }} />
                </span>
            </React.Fragment>
        }
    }

    intervalTemplate = (rowData) => {
        return <React.Fragment>
            <span className="p-column-title">Interval</span>
            <Dropdown className='dropdownNoBorder'
                value={rowData.interval}
                //disabled={rowData.active ? false : true}
                options={this.props.docIntervals}
                disabled={rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false}
                onChange={(e) => { this.props.handleIntervalChange(rowData, e.value) }}
                style={{ marginTop: -3, marginLeft: -7 }} />
        </React.Fragment>
    }

    nameTemplate = (rowData) => {
        //console.log(rowData.documentCollectionPermittedForUser === false)
        if (rowData.queryCollectionDto) {
            //console.log(rowData.queryCollectionDto)
            /*let usersShared = ''
            let usersWrite = ''
            let suborgsShared = ''
            rowData.queryCollectionDto.userSharesRead && rowData.queryCollectionDto.userSharesRead.forEach(user => {
                usersShared += `${user.lastName}, ${user.forename} (${user.name})` + ', '
            })
            rowData.queryCollectionDto.userSharesWrite && rowData.queryCollectionDto.userSharesWrite.forEach(user => {
                usersShared += `${user.lastName}, ${user.forename} (${user.name})` + ', '
                usersWrite += `${user.lastName}, ${user.forename} (${user.name})` + ', '
            })
            rowData.queryCollectionDto.departmentSharesRead && rowData.queryCollectionDto.departmentSharesRead.forEach(dep => {
                suborgsShared += `${dep.name}` + ', '
            })

            usersShared = usersShared.substring(0, usersShared.length - 2)
            usersWrite = usersWrite.substring(0, usersWrite.length - 2)
            suborgsShared = suborgsShared.substring(0, suborgsShared.length - 2)
            */

            return <React.Fragment>
                <span className="p-column-title">Entry</span>
                <span style={{ marginBottom: 5 }}>
                    <label style={{
                        //color: '#007fdb'
                        color: 'black'
                    }}>{rowData.queryCollectionDto.name}</label>
                </span>
                <span style={{ display: 'block', paddingTop: 5 }}>
                    <label style={{ marginTop: 0 }}>{rowData.queryCollectionDto.description}</label>
                </span>
                <span style={{ display: 'block', paddingTop: 5 }}>
                    <label style={{ marginTop: 0, marginBottom: 0 }}>
                        {rowData.queryCollectionDto.created ? `Created: ${moment(rowData.queryCollectionDto.created + "Z").format("YYYY-MM-DD")}` : ''}
                        {rowData.queryCollectionDto.modified !== undefined ? ` - Modified: ${moment(rowData.queryCollectionDto.modified + "Z").format("YYYY-MM-DD")}` : ''}
                        {(rowData.queryCollectionDto.departmentSharesRead && rowData.queryCollectionDto.departmentSharesRead.length > 0) ? ` - Shared with suborganizations` : ''}
                        {(rowData.queryCollectionDto.userSharesRead && rowData.queryCollectionDto.userSharesRead.length > 0) || (rowData.queryCollectionDto.userSharesWrite && rowData.queryCollectionDto.userSharesWrite.length > 0) ? ` - Shared with users` : ''}
                        {(rowData.queryCollectionDto.userSharesWrite && rowData.queryCollectionDto.userSharesWrite.length > 0) || (rowData.queryCollectionDto.userSharesRead && rowData.queryCollectionDto.userSharesRead.length > 0) ?
                            rowData.queryCollectionDto.userSharesWrite && rowData.queryCollectionDto.userSharesWrite.length > 0 ? ` - Editable for users` : ' - Not editable for shared users'
                            : ''
                        }
                    </label>
                </span>
            </React.Fragment>
            //rowData.queryCollectionDto.name
        } else if (rowData.queryDto) {
            if (rowData.queryDto.fullName) {
                //console.log(rowData.queryDto)
                let title = rowData.queryDto.fullName
                /*let collectionList = ''
                if (rowData.queryDto.queryCollectionList) {
                    rowData.queryDto.queryCollectionList.forEach(list => {
                        collectionList += list.name + ', '
                    })
                    collectionList = collectionList.substring(0, collectionList.length - 2)
                }*/

                let queryRepositories = []
                if (rowData.repositories.length > 0) {
                    rowData.repositories.forEach(rep => {
                        if (rep.active && rep.features?.includes(REPO_ROLE_DOC_SEARCH.id)) {
                            queryRepositories.push(rep.id);
                        }
                    })
                } else {
                    this.state.selectedRepositoriesRawData && this.state.selectedRepositoriesRawData.forEach(rep => {
                        if (rep.active && rep.features?.includes(REPO_ROLE_DOC_SEARCH.id)) {
                            queryRepositories.push(rep.id);
                        }
                    })
                }

                return <React.Fragment>
                    <span className="p-column-title">Entry</span>
                    <span style={{ marginBottom: 5 }}>
                        <label style={{
                            //color: '#007fdb' 
                            color: 'black'
                        }}>{title}</label>
                    </span>
                    {/*
                        rowData.queryDto.fullName ?
                            <span style={{ display: 'block', paddingTop: 5 }}>
                                <label >{rowData.queryDto.fullName}</label>
                                {/*
                                {!rowData.queryDto.shared ?
                                    (rowData.queryDto.sharedForDepartment || (rowData.queryDto.usersSharedWith && rowData.queryDto.usersSharedWith.length > 0)) ?
                                        <span title={`You share this search collection with other users`}>
                                            {' - Shared by you'}
                                        </span>
                                        :
                                        <span title={`This search collection can only be seen by you`}>
                                            {' - Private'}
                                        </span>
                                    :
                                    <span title={`This search collection is shared with you by another user`}>
                                        {' - Shared with you'}
                                    </span>
                                }
                                {rowData.queryDto.editableForSharedUsers ?
                                    <span>{' - Editable for shared users'}</span>
                                    : null
                                }
                            </span>
                            : <span style={{ display: 'block', paddingTop: 5 }}>
                                {!rowData.queryDto.shared ?
                                    (rowData.queryDto.sharedForDepartment || (rowData.queryDto.usersSharedWith && rowData.queryDto.usersSharedWith.length > 0)) ?
                                        <span title={`You share this search collection with other users`}>
                                            {' - Shared by you'}
                                        </span>
                                        :
                                        <span title={`This search collection can only be seen by you`}>
                                            {' - Private'}
                                        </span>
                                    :
                                    <span title={`This search collection is shared with you by another user`}>
                                        {' - Shared with you'}
                                    </span>
                                }*/}
                    {/*</span>
                            : null
                            */}
                    {
                        rowData.queryDto.description !== '' &&
                        <span style={{ display: 'block', paddingTop: 5 }}>
                            <label >{rowData.queryDto.description}</label>
                        </span>
                    }
                    {
                        rowData.repositories &&
                        <div style={{ display: 'block', paddingTop: 5 }}>
                            <label style={{ paddingRight: 5 }}>For repositories:</label>
                            <MultiSelect
                                //disabled={this.state.addAlert ? false : true}
                                //filter
                                pt={{
                                    checkboxIcon: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            if (e.target.className.baseVal !== "") {
                                                e.target.parentNode.click();
                                            } else {
                                                e.target.parentNode.parentNode.click();
                                            }
                                        },
                                    },
                                    headerCheckbox: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            e.target.parentNode.click();
                                        },
                                    },
                                }}
                                id="multiselectTable"
                                maxSelectedLabels={2}
                                value={queryRepositories}
                                options={this.state.selectedRepositoriesData ? this.state.selectedRepositoriesData : []}
                                onChange={(e) => this.handleRepositoryChange(e.value, rowData)}
                                appendTo={document.body}
                                placeholder="Select repositories"
                                style={{ border: 'none', marginTop: -2, marginLeft: -5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 150 }} />
                        </div>
                    }
                    {/*<span style={{ marginBottom: 5 }}>
                        <label style={{ color: 'darkgrey' }}>In watchlists: {collectionList}</label>
                </span>*/}
                </React.Fragment>
            }
            //return rowData.queryDto.name
        } else if (rowData.documentCollectionDto) {
            let usersShared = ''
            rowData.documentCollectionDto.usersSharedWith && rowData.documentCollectionDto.usersSharedWith.forEach(user => {
                usersShared += user.name + ', '
            })
            usersShared = usersShared.substring(0, usersShared.length - 2)
            return <React.Fragment>
                <span className="p-column-title">Entry</span>
                <span style={{ marginBottom: 5 }}>
                    <label style={{
                        //color: '#007fdb'
                        color: 'black'
                    }}>{rowData.documentCollectionDto.name}</label>
                </span>
                <span style={{ display: 'block', paddingTop: 5 }}>
                    <label style={{ marginTop: 0 }}>{rowData.documentCollectionDto.description}</label>
                </span>
                <span style={{ display: 'block', paddingTop: 5 }}>
                    <label style={{ marginTop: 0, marginBottom: 0 }}>
                        {rowData.documentCollectionDto.created ? `Created: ${moment(rowData.documentCollectionDto.created + "Z").format("YYYY-MM-DD")}` : ''}
                        {rowData.documentCollectionDto.modified !== undefined ? ` - Modified: ${moment(rowData.documentCollectionDto.modified + "Z").format("YYYY-MM-DD")}` : ''}
                        {rowData.documentCollectionDto.sharedForDepartment ? ' - Shared with suborganization' : ''}
                        {rowData.documentCollectionDto.usersSharedWith && rowData.documentCollectionDto.usersSharedWith.length > 0 ? ` - Shared for: ${usersShared}` : ''}
                        {rowData.documentCollectionDto.editableForSharedUsers ? ' - Editable for shared users' : ''}</label>
                </span>
            </React.Fragment>
        } else if (rowData.documentCollectionPermittedForUser === false) {
            return <React.Fragment>
                <span className="p-column-title">Entry</span>
                <span style={{ marginBottom: 5 }}>
                    <label style={{
                        //color: '#007fdb'
                        color: 'black'
                    }}>No information available</label>
                    <a className="infoIconLink valignMiddle"
                        style={{ marginTop: -1 }}
                        title="Click for information about this alert"
                        onClick={e => this.recallInfoOverlayDoc.toggle(e)}>
                        <img src={infoIcon} alt="Alert information"
                            style={{ marginLeft: 10 }} />
                    </a>
                    <OverlayPanel
                        //className="removeArrow"
                        ref={(el) => this.recallInfoOverlayDoc = el}
                        dismissable={true}>
                        <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                            <p>
                                This alert was set for a document collection that was shared with you by another user that no longer is a member of your suborganization.
                            </p>
                        </div>
                    </OverlayPanel>
                </span>
            </React.Fragment>
        } else if (rowData.queryCollectionPermittedForUser === false) {
            return <React.Fragment>
                <span className="p-column-title">Entry</span>
                <span style={{ marginBottom: 5 }}>
                    <label style={{
                        //color: '#007fdb'
                        color: 'black'
                    }}>No information available</label>
                    <a className="infoIconLink valignMiddle"
                        style={{ marginTop: -1 }}
                        title="Click for information about this alert"
                        onClick={e => this.recallInfoOverlaySearchCol.toggle(e)}>
                        <img src={infoIcon} alt="Alert information"
                            style={{ marginLeft: 10 }} />
                    </a>
                    <OverlayPanel
                        //className="removeArrow"
                        ref={(el) => this.recallInfoOverlaySearchCol = el}
                        dismissable={true}>
                        <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                            <p>
                                This alert was set for a search collection that was shared with you by another user that no longer is a member of your suborganization.
                            </p>
                        </div>
                    </OverlayPanel>
                </span>
            </React.Fragment>
        } else if (rowData.queryPermittedForUser === false) {
            return <React.Fragment>
                <span className="p-column-title">Entry</span>
                <span style={{ marginBottom: 5 }}>
                    <label style={{
                        //color: '#007fdb'
                        color: 'black'
                    }}>No information available</label>
                    <a className="infoIconLink valignMiddle"
                        style={{ marginTop: -1 }}
                        title="Click for information about this alert"
                        onClick={e => this.recallInfoOverlaySearch.toggle(e)}>
                        <img src={infoIcon} alt="Alert information"
                            style={{ marginLeft: 10 }} />
                    </a>
                    <OverlayPanel
                        //className="removeArrow"
                        ref={(el) => this.recallInfoOverlaySearch = el}
                        dismissable={true}>
                        <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                            <p>
                                This alert was set for a search that was shared with you by another user that no longer is a member of your suborganization.
                            </p>
                        </div>
                    </OverlayPanel>
                </span>
            </React.Fragment>
        }
    }

    handleRepositoryChange = (reps, rowData) => {
        //console.log(reps)
        if (reps.length === 0) {
            this.showRepoWarnGrowl()
        } else {
            this.props.handleRepositoryChange(reps, rowData, this.state.selectedRepositoriesRawData)
        }
    }

    showRepoWarnGrowl = () => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: `Please select at least one repository for your alert of this search.` };
        this.growl.show(msg)
    }

    typeTemplate = (rowData) => {
        let type = ''
        if (rowData.queryCollectionDto) {
            type = 'Search collection'
        } else if (rowData.queryDto) {
            type = 'Search'
        } else if (rowData.documentCollectionDto) {
            type = 'Document collection'
        } else if (rowData.documentCollectionPermittedForUser === false) {
            type = 'Document collection'
        } else if (rowData.queryCollectionPermittedForUser === false) {
            type = 'Search collection'
        } else if (rowData.queryPermittedForUser === false) {
            type = 'Search'
        }
        return <React.Fragment>
            <span className="p-column-title">Type</span>
            <span style={{
                verticalAlign: 'bottom',
                color: rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false ? 'grey' : 'black'
            }}
                title={rowData.queryCollectionDto || rowData.queryCollectionPermittedForUser === false ? "Emails are sent if there are new documents for at least one search of this search collection."
                    : rowData.queryDto || rowData.queryPermittedForUser === false ? "Emails are sent if there are new documents for this search."
                        : "Emails are sent if i) the owner of a document collection that is shared with you adds new documents to this document collection or ii) new documents are added by your colleagues to a document collection that you share."}>
                {type}
            </span>
        </React.Fragment>
    }

    sendingDateTemplate = (rowData) => {
        let today = new Date()
        let sendingDate = new Date(rowData.nextSendingDate)
        let value = new Date(sendingDate.getFullYear(), sendingDate.getMonth(), sendingDate.getDate())
        let minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        return <React.Fragment>
            <span className="p-column-title">Next mail</span>
            <span>
                <Calendar
                    value={value}
                    //className='adminCalendar'
                    minDate={minDate}
                    appendTo={document.body}
                    readOnlyInput={true}
                    showIcon={true}
                    dateFormat='yy-mm-dd'
                    disabled={rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false}
                    onChange={(e) => {
                        this.props.handleSendingTimeChange(rowData, e.value)
                    }}
                    aria-label='Select next sending date'
                    title='Select next sending date'
                    style={{ marginTop: -13, border: 'none', marginLeft: -6, width: 105 }} />
            </span>
        </React.Fragment>
    }

    insertionTimeTemplate = (rowData) => {
        //console.log(rowData)
        if (rowData.created) {
            return <React.Fragment>
                <span className="p-column-title">Created</span>
                <label style={{ color: rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false ? 'grey' : 'black' }}
                    title={moment(rowData.created + "Z").format("YYYY-MM-DD")}>{moment(rowData.created + "Z").format("YYYY-MM-DD")}</label>
            </React.Fragment>
        }
    }

    handleSelectionChange = (e) => {
        this.props.selectAlerts(e.value)
    }

    handleRemoveAlertsDialog = () => {
        this.props.handleRemoveAlertsDialog()
    }

    handleActivateAlertsDialog = () => {
        this.props.handleActivateAlertsDialog()
    }

    handlePauseAlertsDialog = () => {
        this.props.handlePauseAlertsDialog()
    }

    handleSendingAlertsDialog = () => {
        this.props.handleSendingAlertsDialog()
    }

    handleIntervalAlertsDialog = () => {
        this.props.handleIntervalAlertsDialog()
    }

    handleUnselect = (e) => {
        this.props.handleUnselect()
    }

    handleRemoveAlerts = async () => {
        this.props.removeAlerts(this.props.selectedAlerts)
    }

    handleActivateAlerts = async () => {
        this.props.modifyAlerts(this.props.selectedAlerts, 'activate')
    }

    handlePauseAlerts = async () => {
        this.props.modifyAlerts(this.props.selectedAlerts, 'pause')
    }

    handleIntervalAlerts = async () => {
        this.props.modifyAlerts(this.props.selectedAlerts, 'interval')
    }

    handleSendingAlerts = async () => {
        this.props.modifyAlerts(this.props.selectedAlerts, 'sending')
    }

    checkAlertTemplate = (rowData) => {
        return <div key={rowData.id}>
            <span className="p-column-title">Select alert</span>
            <span>
                <Checkbox
                    key={rowData.username}
                    disabled={rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false}
                    style={{
                        cursor: rowData.documentCollectionPermittedForUser === false || rowData.queryCollectionPermittedForUser === false || rowData.queryPermittedForUser === false ? 'default' : "pointer"
                    }}
                    onChange={(e) => this.selectAlerts(rowData, e)}
                    checked={this.props.selectedAlerts && this.props.selectedAlerts.includes(rowData)}>
                </Checkbox>
            </span>
        </div>
    }

    selectAlerts = (alert, e) => {

        let selectedAlerts = [...this.props.selectedAlerts]

        if (e.checked)
            selectedAlerts.push(alert);
        else
            selectedAlerts.splice(selectedAlerts.indexOf(alert), 1);

        this.props.selectAlerts(selectedAlerts)
    }

    handleSelectAllAlerts = (e) => {
        //console.log(e)
        if (e.checked) {
            //console.log(this.state.filteredUsers)
            //console.log(this.allUsers)
            this.props.selectAlerts(this.possibleAlerts)
        } else {
            this.props.selectAlerts([])
        }
    }

    render() {

        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults
        //const showIcons = APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons;

        //console.log(this.props)

        let allAlerts = this.props.watchlistAlerts && this.props.queryAlerts && this.props.documentAlerts ? this.props.watchlistAlerts.concat(this.props.queryAlerts, this.props.documentAlerts) : []

        //console.log("all: ", allAlerts)

        let columnDataAlerts = this.state.colsAlerts.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        let selectedAlerts = []

        selectedAlerts = this.props.alertTypeName === 'All alerts' ? allAlerts : this.props.alertTypeName === 'Library alerts' ? this.props.documentAlerts :
            this.props.alertTypeName === 'Search collection alerts' ? this.props.watchlistAlerts : this.props.queryAlerts

        this.possibleAlerts = []

        selectedAlerts && selectedAlerts.forEach(alert => {
            if ((alert.documentCollectionPermittedForUser !== undefined && alert.documentCollectionPermittedForUser) ||
                (alert.queryCollectionPermittedForUser !== undefined && alert.queryCollectionPermittedForUser)
                || (alert.queryPermittedForUser !== undefined && alert.queryPermittedForUser)) {
                //console.log("if")
                this.possibleAlerts = [...this.possibleAlerts, alert]
            }
        })

        //console.log(this.possibleAlerts)

        const checkAlertsTemplateHeader = () => {
            return <Checkbox
                disabled={(this.props.alertTypeName === 'All alerts' && ((this.props.documentAlerts && this.props.documentAlerts.length !== 0) ||
                    (this.props.queryAlerts && this.props.queryAlerts.length !== 0) || (this.props.watchlistAlerts && this.props.watchlistAlerts.length !== 0))
                    || (this.props.alertTypeName === 'Library alerts' && (this.props.documentAlerts && this.props.documentAlerts.length !== 0))
                    || (this.props.alertTypeName === 'Search collection alerts' && (this.props.watchlistAlerts && this.props.watchlistAlerts.length !== 0))
                    || (this.props.alertTypeName === 'Search alerts' && (this.props.queryAlerts && this.props.queryAlerts.length !== 0))) && this.possibleAlerts.length > 0 ? false : true}
                style={{
                    cursor: (this.props.alertTypeName === 'All alerts' && ((this.props.documentAlerts && this.props.documentAlerts.length !== 0) ||
                        (this.props.queryAlerts && this.props.queryAlerts.length !== 0) || (this.props.watchlistAlerts && this.props.watchlistAlerts.length !== 0))
                        || (this.props.alertTypeName === 'Library alerts' && (this.props.documentAlerts && this.props.documentAlerts.length !== 0))
                        || (this.props.alertTypeName === 'Search collection alerts' && (this.props.watchlistAlerts && this.props.watchlistAlerts.length !== 0))
                        || (this.props.alertTypeName === 'Search alerts' && (this.props.queryAlerts && this.props.queryAlerts.length !== 0))) && this.possibleAlerts.length > 0 ? "pointer" : "default"
                }}
                checked={this.props.selectedAlerts && this.props.selectedAlerts.length === this.possibleAlerts.length}
                onChange={(e) => this.handleSelectAllAlerts(e)}>
            </Checkbox>
        }

        columnDataAlerts.unshift(
            <Column
                key="check"
                field="check"
                header={checkAlertsTemplateHeader()}
                body={this.checkAlertTemplate}
                style={{ width: '4em' }}>
            </Column>
        )

        /*let headerAlerts = <div className='grid' style={{
            marginRight: 0,
            borderBottom: '1px solid #757575',
            paddingTop: 18, paddingBottom: 10,
            paddingLeft: 5
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title={this.props.alertTypeName === 'All alerts' ? "Search all alerts" : this.props.alertTypeName === 'Library alerts' ? "Search all library alerts" :
                        this.props.alertTypeName === 'Watchlist alerts' ? "Search all watchlist alerts" : "Search all search alerts"}>
                    {'search'}
                </i>
                <InputText
                    style={{ width: '81%', border: 'none', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterAlerts: e.target.value })}
                    placeholder={this.props.alertTypeName === 'All alerts' ? "Search all alerts" : this.props.alertTypeName === 'Library alerts' ? "Search all library alerts" :
                        this.props.alertTypeName === 'Watchlist alerts' ? "Search all watchlist alerts" : "Search all search alerts"}
                />
            </div>
        </div>*/

        {/*let footerAlerts = <div className='col-12' style={{ paddingTop: 5, paddingBottom: 5, justifyContent: 'center', display: 'flex' }}>
            {this.props.selectedAlerts && this.props.selectedAlerts.length > 0 &&
                <div className='col-7' style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #d6d6d6', padding: '2em' }}>
                    <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{this.props.selectedAlerts.length} {this.props.selectedAlerts.length !== 1 ? 'items' : 'item'} selected</label>
                    //(this.props.selectedWatchlist !== undefined && !this.props.selectedWatchlist.defaultCollection) || (this.props.selectedWatchlist !== undefined) ?
                        <a style={{ marginTop: 5, marginRight: 5 }}
                            title={this.props.selectedAlerts.length && this.props.selectedAlerts.length === 1 ? `Copy selected document` : `Copy ${this.props.selectedAlerts.length} selected documents`}
                            onClick={this.handleCopyQueryDialog} >Copy</a>
                        //: null
                    }
                    {/*<a style={{ marginTop: 5 }}
                    title={this.props.selectedQueriesAction.length && this.props.selectedQueriesAction.length === 1 ? `Move selected document` : `Move ${this.props.selectedQueriesAction.length} selected documents`}
        onClick={this.handleMoveDocumentDialog}>Move</a>
                    {<a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedAlerts.length && this.props.selectedAlerts.length === 1 ? `Activate selected alert` : `Activate ${this.props.selectedAlerts.length} selected alerts`}
                        onClick={this.handleActivateAlertsDialog}>Activate</a>}
                    {<a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedAlerts.length && this.props.selectedAlerts.length === 1 ? `Pause selected alert` : `Pause ${this.props.selectedAlerts.length} selected alerts`}
                        onClick={this.handlePauseAlertsDialog}>Pause</a>}
                    {<a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedAlerts.length && this.props.selectedAlerts.length === 1 ? `Set interval of selected alert` : `Set interval of ${this.props.selectedAlerts.length} selected alerts`}
                        onClick={this.handleIntervalAlertsDialog}>Set interval</a>}
                    {<a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedAlerts.length && this.props.selectedAlerts.length === 1 ? `Set next sending date of selected alert` : `Set next sending date of ${this.props.selectedAlerts.length} selected alerts`}
                        onClick={this.handleSendingAlertsDialog}>Set next sending date</a>}
                    {<a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedAlerts.length && this.props.selectedAlerts.length === 1 ? `Remove selected alert` : `Remove ${this.props.selectedAlerts.length} selected alerts`}
                        onClick={this.handleRemoveAlertsDialog}>Remove</a>}
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title='Unselect'
                        onClick={this.handleUnselect}>Unselect</a>
                </div>
            }
        </div>;*/}

        const footerRemoveAlert = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete" onClick={this.handleRemoveAlerts} className='p-button-sm primaryButton' />
                <Button label="Cancel" onClick={this.props.handleCloseRemoveAlertDialog} className="p-button-secondary p-button-sm" />
            </div>
        )

        const footerActivateAlert = (
            <div style={{ paddingTop: 5, paddingRight: 15 }}>
                <Button label="Cancel" onClick={this.props.handleCloseActivateAlertDialog} className="p-button-secondary p-button-sm" />
                <Button label="Activate" onClick={this.handleActivateAlerts} className='p-button-sm primaryButton' style={{ marginRight: -5 }} />
            </div>
        )

        const footerPauseAlert = (
            <div style={{ paddingTop: 5, paddingRight: 15 }}>
                <Button label="Cancel" onClick={this.props.handleClosePauseAlertDialog} className="p-button-secondary p-button-sm" />
                <Button label="Pause" onClick={this.handlePauseAlerts} className='p-button-sm primaryButton' style={{ marginRight: -5 }} />
            </div>
        )

        const footerIntervalAlert = (
            <div style={{ paddingTop: 5, paddingRight: 15 }}>
                <Button label="Cancel" onClick={this.props.handleCloseIntervalAlertDialog} className="p-button-secondary p-button-sm" />
                <Button label="Set interval" onClick={this.handleIntervalAlerts} className='p-button-sm primaryButton' style={{ marginRight: -5 }} />
            </div>
        )

        const footerSendingAlert = (
            <div style={{ paddingTop: 5, paddingRight: 15 }}>
                <Button label="Cancel" onClick={this.props.handleCloseSendingAlertDialog} className="p-button-secondary p-button-sm" />
                <Button label="Set sending date" onClick={this.handleSendingAlerts} className='p-button-sm primaryButton' style={{ marginRight: -5 }} />
            </div>
        )

        let today = new Date()
        let minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)

        //console.log(this.props)

        return (
            <div className="col-12 grid-nogutter" style={{ paddingRight: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0, marginBottom: this.props.selectedAlerts && this.props.selectedAlerts.length > 0 ? 70 : 0 }}>
                        <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0 }}>
                            <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0 }}>
                                <h1 style={{ marginTop: 0, fontSize: '1.1em', float: 'left' }} className="pageHeader">
                                    {this.props.alertTypeName}</h1>
                            </div>
                            <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, paddingTop: 0, marginTop: -2 }}>
                                <label style={{ marginTop: 0, float: 'left', marginBottom: 0, color: '#757575' }}>{this.props.alertTypeDescription}</label>
                            </div>
                        </div>
                        {(this.props.alertTypeName === 'All alerts' && ((this.props.documentAlerts && this.props.documentAlerts.length !== 0) ||
                            (this.props.queryAlerts && this.props.queryAlerts.length !== 0) || (this.props.watchlistAlerts && this.props.watchlistAlerts.length !== 0))
                            || (this.props.alertTypeName === 'Library alerts' && (this.props.documentAlerts && this.props.documentAlerts.length !== 0))
                            || (this.props.alertTypeName === 'Search collection alerts' && (this.props.watchlistAlerts && this.props.watchlistAlerts.length !== 0))
                            || (this.props.alertTypeName === 'Search alerts' && (this.props.queryAlerts && this.props.queryAlerts.length !== 0))) ?
                            <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 15, paddingRight: 0 }}>
                                {/* <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0 }}>*/}
                                <div className="datatable-responsive-demo-bigger">
                                    {/*headerAlerts*/}
                                    <DataTable ref={(el) => this.dt = el}
                                        //className="dataTableQueries checkboxCircle standardTableHeader"
                                        className="p-datatable-responsive-demo-bigger"
                                        selection={this.props.selectedAlerts}
                                        //selectionMode="checkbox"
                                        removableSort={true}
                                        onSelectionChange={e => this.handleSelectionChange(e)}
                                        //className="dataTableQueries"
                                        value={this.props.alertTypeName === 'All alerts' ? allAlerts : this.props.alertTypeName === 'Library alerts' ? this.props.documentAlerts :
                                            this.props.alertTypeName === 'Search collection alerts' ? this.props.watchlistAlerts : this.props.queryAlerts}
                                        responsive="true"
                                        paginator={true}
                                        rows={this.props.rows}
                                        //header={headerAlerts}
                                        //globalFilter={this.state.globalFilterAlerts}
                                        //footer={footerAlerts}
                                        first={this.props.first}
                                        rowsPerPageOptions={[10, 20, 50]}
                                        onPage={(e) => this.props.onCustomPage(e, true)}
                                        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        //currentPageReportTemplate={watchlistAlertMessage}
                                        paginatorTemplate={CustomPaginatorTemplate}
                                        paginatorLeft={<CustomPaginatorLeftSide
                                            first={this.props.first}
                                            contentLength={this.props.alertTypeName === 'All alerts' ? allAlerts.length : this.props.alertTypeName === 'Library alerts' ? this.props.documentAlerts.length :
                                                this.props.alertTypeName === 'Search collection alerts' ? this.props.watchlistAlerts.length : this.props.queryAlerts.length}
                                            rows={this.props.rows}>
                                        </CustomPaginatorLeftSide>}
                                        paginatorRight={<CustomPaginatorRightSide
                                            rows={this.props.rows}
                                            onChange={(e) => this.props.onCustomPage(e, false)}>
                                        </CustomPaginatorRightSide>}>
                                        {columnDataAlerts}
                                    </DataTable>
                                    {/*</div>*/}
                                </div>
                            </div>
                            :
                            <div className="dataTable" style={{ marginTop: 20 }}>
                                <label style={{ marginRight: 10, marginBottom: 20, verticalAlign: 'bottom', fontWeight: 'bold' }}>
                                    No alerts available.</label>
                            </div>
                        }
                        <Dialog focusOnShow={false} visible={this.props.displayDialogRemoveAlerts} style={{ 'width': "50vw" }} header={(this.props.selectedAlerts && this.props.selectedAlerts.length > 1) ? "Delete alerts" : (this.props.selectedAlerts && this.props.selectedAlerts.length === 1) ? "Delete alert" : null}
                            modal={true} footer={footerRemoveAlert} dismissableMask={true}
                            onHide={() => this.props.handleCloseRemoveAlertDialog()} className='styledDialog'>
                            <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                {this.props.selectedAlerts && this.props.selectedAlerts.length === 1 ?
                                    `Warning! This alert will be deleted! Do you really want to delete this alert?`
                                    : this.props.selectedAlerts && this.props.selectedAlerts.length > 1 ?
                                        `Warning! These alerts will be deleted! Do you really want to delete these alerts?`
                                        : null
                                }
                            </div>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.props.displayDialogActivateAlerts} style={{ 'width': "50vw" }} header={(this.props.selectedAlerts && this.props.selectedAlerts.length > 1) ? "Activate alerts" : (this.props.selectedAlerts && this.props.selectedAlerts.length === 1) ? "Activate alert" : null}
                            modal={true} footer={footerActivateAlert} dismissableMask={true}
                            onHide={() => this.props.handleCloseActivateAlertDialog()} className='styledDialog'>
                            <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                {this.props.selectedAlerts && this.props.selectedAlerts.length === 1 ?
                                    `This alert will be activated! Do you really want to activate this alert?`
                                    : this.props.selectedAlerts && this.props.selectedAlerts.length > 1 ?
                                        `These alerts will be activated! Do you really want to activate these alerts?`
                                        : null
                                }
                            </div>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.props.displayDialogPauseAlerts} style={{ 'width': "50vw" }} header={(this.props.selectedAlerts && this.props.selectedAlerts.length > 1) ? "Pause alerts" : (this.props.selectedAlerts && this.props.selectedAlerts.length === 1) ? "Pause alert" : null}
                            modal={true} footer={footerPauseAlert} dismissableMask={true}
                            onHide={() => this.props.handleClosePauseAlertDialog()} className='styledDialog'>
                            <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                {this.props.selectedAlerts && this.props.selectedAlerts.length === 1 ?
                                    `This alert will be paused! Do you really want to pause this alert?`
                                    : this.props.selectedAlerts && this.props.selectedAlerts.length > 1 ?
                                        `These alerts will be paused! Do you really want to pause these alerts?`
                                        : null
                                }
                            </div>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.props.displayDialogIntervalAlerts} style={{ 'width': "50vw" }} header={(this.props.selectedAlerts && this.props.selectedAlerts.length > 1) ? "Set alerts interval" : (this.props.selectedAlerts && this.props.selectedAlerts.length === 1) ? "Set alert interval" : null}
                            modal={true} footer={footerIntervalAlert} dismissableMask={true} blockScroll
                            onHide={() => this.props.handleCloseIntervalAlertDialog()} className='styledDialog'>
                            <div style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                <div style={{ paddingBottom: 5 }}>
                                    <label>Interval:</label>
                                    <Dropdown className='dropdownNoBorder'
                                        value={this.props.allInterval}
                                        appendTo={document.body}
                                        options={this.props.docIntervals}
                                        onChange={(e) => { this.props.handleIntervalChangeAll(e.value) }}
                                        style={{}} />
                                </div>
                                {this.props.selectedAlerts && this.props.selectedAlerts.length === 1 ?
                                    `Change interval of this alert?`
                                    : this.props.selectedAlerts && this.props.selectedAlerts.length > 1 ?
                                        `Change interval of these alerts?`
                                        : null
                                }
                            </div>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.props.displayDialogSendingAlerts} style={{ 'width': "50vw" }} header={(this.props.selectedAlerts && this.props.selectedAlerts.length > 1) ? "Set alerts sending date" : (this.props.selectedAlerts && this.props.selectedAlerts.length === 1) ? "Set alert sending date" : null}
                            modal={true} footer={footerSendingAlert} dismissableMask={true}
                            onHide={() => this.props.handleCloseSendingAlertDialog()} className='styledDialog'>
                            <div style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                <div style={{ marginBottom: 5 }}>
                                    <label>Next sending date:</label>
                                    <Calendar
                                        value={this.props.nextSendingDateAll}
                                        //className='adminCalendar'
                                        minDate={minDate}
                                        appendTo={document.body}
                                        readOnlyInput={true}
                                        showIcon={true}
                                        width
                                        dateFormat='yy-mm-dd'
                                        onChange={(e) => {
                                            this.props.handleSendingTimeChangeAll(e.value)
                                        }}
                                        aria-label='Select next sending date'
                                        title='Select next sending date'
                                        style={{ width: 105, marginLeft: 5, border: 'none' }} />
                                </div>
                                {this.props.selectedAlerts && this.props.selectedAlerts.length === 1 ?
                                    `Change sending date of this alert?`
                                    : this.props.selectedAlerts && this.props.selectedAlerts.length > 1 ?
                                        `Change sending date of these alerts?`
                                        : null
                                }
                            </div>
                        </Dialog>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default AlertList
