import { useState } from "react";

const DeleteModalText = {
  DeleteItemModalTitle: (itemName) => `Delete ${itemName}`,
  DeleteItemModalText: (itemName) => `Are you sure you want to delete this ${itemName} ?`,
  DeleteButtonTitle: 'Delete',
  CancelButtonTitle: 'Cancel',
};

export const useDeleteItem = () => {
  const [selectedItemIdForDelete, setSelectedItemIdForDelete] = useState(0);
  const [isDeleteItemModalVisible, setIsDeleteItemModalVisible] = useState(false);

  const onOpenDeleteItemModal = (ticketIdForDelete) => {
    setSelectedItemIdForDelete(ticketIdForDelete);
    setIsDeleteItemModalVisible(true);
  };

  const onCloseDeleteItemModal = () => {
    setIsDeleteItemModalVisible(false);
  };

  return {
    onOpenDeleteItemModal,
    onCloseDeleteItemModal,
    isDeleteItemModalVisible,
    DeleteModalText,
    selectedItemIdForDelete,
  };
};
