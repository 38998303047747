import React, { Component } from 'react'
//import { Checkbox } from 'primereact/checkbox';
//import './InferenceSearch.css'
import { Fieldset } from 'primereact/fieldset';
//import { ToggleButton } from 'primereact/togglebutton';
import { SelectButton } from 'primereact/selectbutton';

class Options extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    onStartToggleChange = (e) => {
        if (this.props.startTerm.length !== 0 && this.props.pathNumber === 0 && e.value !== null) {
            this.setState({
                startMode: e.value
            }, () => this.props.callbackFromStartToggleChange(this.state.startMode, this.props.pathNumber))
        }
        /*if (this.props.startTerm !== '' && this.props.pathNumber === 0) {
            this.setState({
                checked1: e.value
            }, () => this.props.callbackFromStartToggleChange(this.state.checked1, this.props.pathNumber))
        }*/
    }

    onEndToggleChange = (e) => {
        if (this.props.endTerm.length !== 0 && e.value !== null) {
            this.setState({
                endMode: e.value
            }, () => this.props.callbackFromEndToggleChange(this.state.endMode, this.props.pathNumber))
        }
        /*if (this.props.endTerm !== '') {
            this.setState({
                checked2: e.value
            }, () => this.props.callbackFromEndToggleChange(this.state.checked2, this.props.pathNumber))
        }*/

    }

    render() {

        //const { startTerm, endTerm, domain, pathNumber } = this.props
        const { startTerm, endTerm, pathNumber } = this.props

        let endTerms = ''

        endTerm && endTerm.length > 0 && endTerm.forEach(term => {
            endTerms += term + ", "
        })

        endTerms = endTerms.slice(0, -2)

        let startTerms = ''

        startTerm && startTerm.length > 0 && startTerm.forEach(term => {
            startTerms += term + ", "
        })

        startTerms = startTerms.slice(0, -2)

        let startModes = [
            { label: 'ontological', value: 'ontological' },
            { label: 'exact', value: 'exact' }
        ]

        let leftDomains = ''

        if (this.props.leftDomains && this.props.leftDomains.length > 0 && this.props.leftDomains[0] !== 'all domains') {
            this.props.leftDomains.forEach(term => {
                leftDomains += term + ", "
            })
            leftDomains = leftDomains.slice(0, -2)
        }

        let rightDomains = ''

        if (this.props.rightDomains && this.props.rightDomains.length > 0 && this.props.rightDomains[0] !== 'all domains') {
            this.props.rightDomains.forEach(term => {
                rightDomains += term + ", "
            })
            rightDomains = rightDomains.slice(0, -2)
        }

        return (
            <Fieldset legend={'Settings'}
                key={'options'}
                toggleable={true}
                collapsed={false}>
                <h4 style={{ marginTop: "5px", marginBottom: "5px" }}>Entity search mode</h4>
                <div className="col-12 grid">
                    <div className="md:col-4" style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            startTerm.length !== 0 ?
                                <label style={{ marginRight: 10 }}>{startTerms}:</label>
                                :
                                this.props.domain === 'ctd' || this.props.domain === 'cip' || this.props.domain === 'cid' || this.props.domain === 'cic' ||
                                    this.props.domain === 'cipp' || this.props.domain === 'caa' ?
                                    <label style={{ marginRight: 10 }}>{`Domains chemistry, natural products, substances and drugs:`}</label>
                                    : this.props.domain === 'pid' || this.props.domain === 'pmc' || this.props.domain === 'pmpp' || this.props.domain === 'paa' ?
                                        <label style={{ marginRight: 10 }}>{`Domain protein:`}</label>
                                        : this.props.domain === 'ppmp' || this.props.domain === 'pppd' || this.props.domain === 'ppipp' || this.props.domain === 'ppaa' ?
                                            <label style={{ marginRight: 10 }}>{`Domain effects or process:`}</label>
                                            : this.props.domain === 'dmp' || this.props.domain === 'daa' ?
                                                <label style={{ marginRight: 10 }}>{`Domain disease:`}</label> :
                                                (this.props.domain === 'aaa' || this.props.domain === 'aac' || this.props.domain === 'aad' || this.props.domain === 'aapp' || this.props.domain === 'aap') && this.props.leftDomains[0] === 'all domains' ?
                                                    <label style={{ marginRight: 10 }}>{`Free:`}</label> :
                                                    (this.props.domain === 'aaa' || this.props.domain === 'aac' || this.props.domain === 'aad' || this.props.domain === 'aapp' || this.props.domain === 'aap') && this.props.leftDomains[0] !== 'all domains' ?
                                                        <label style={{ marginRight: 10 }}>{`${leftDomains}:`}</label>
                                                        : null
                        }
                    </div>
                    <div className="md:col-3">
                        {/* <ToggleButton style={{ width: '150px' }} disabled={this.props.startTerm === '' || pathNumber > 0 ? true : false} onLabel='ontological' offLabel='exact' checked={this.props.startChecked} onChange={(e) => this.onStartToggleChange(e)} /> */}
                        <SelectButton disabled={this.props.startTerm.length === 0 || pathNumber > 0 ? true : false} tooltipOptions={{ position: 'bottom' }} tooltip={this.props.startTerm.length === 0 || pathNumber > 0 ? 'Not editable' : 'Click to change search mode'} id='triple' value={this.props.startChecked} options={startModes} onChange={(e) => this.onStartToggleChange(e)} />
                    </div>
                </div>
                <div className="col-12 grid">
                    <div className="md:col-4" style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            endTerm.length !== 0 ?
                                <label style={{ marginRight: 10 }}>{endTerms}:</label>
                                : this.props.domain === 'ctd' || this.props.domain === 'cid' || this.props.domain === 'pid' || this.props.domain === 'pppd' || this.props.domain === 'aad' ?
                                    <label style={{ marginRight: 10 }}>{`Domain disease:`}</label>
                                    : this.props.domain === 'cip' || this.props.domain === 'ppmp' || this.props.domain === 'dmp' || this.props.domain === 'aap' ?
                                        <label style={{ marginRight: 10 }}>{`Domain protein:`}</label>
                                        : this.props.domain === 'cic' || this.props.domain === 'pmc' || this.props.domain === 'aac' ?
                                            <label style={{ marginRight: 10 }}>{`Domains chemistry, natural products, substances and drugs:`}</label>
                                            : this.props.domain === 'cipp' || this.props.domain === 'pmpp' || this.props.domain === 'ppipp' || this.props.domain === 'aapp' ?
                                                <label style={{ marginRight: 10 }}>{`Domain effects or process:`}</label> :
                                                (this.props.domain === 'aaa' || this.props.domain === 'caa' || this.props.domain === 'daa' || this.props.domain === 'ppaa' || this.props.domain === 'paa') && this.props.rightDomains[0] === 'all domains'  ?
                                                    <label style={{ marginRight: 10 }}>{`Free:`}</label>:
                                                    (this.props.domain === 'aaa' || this.props.domain === 'caa' || this.props.domain === 'daa' || this.props.domain === 'ppaa' || this.props.domain === 'paa') && this.props.rightDomains[0] !== 'all domains'  ?
                                                    <label style={{ marginRight: 10 }}>{`${rightDomains}:`}</label>
                                                    : null
                        }
                    </div>
                    <div className="md:col-3">
                        {/*} <ToggleButton style={{ width: '150px' }} disabled={this.props.endTerm === '' ? true : false} onLabel='ontological' offLabel='exact' checked={this.props.endChecked} onChange={(e) => this.onEndToggleChange(e)} /> */}
                        <SelectButton disabled={this.props.endTerm.length === 0 ? true : false} tooltipOptions={{ position: 'bottom' }} tooltip={this.props.endTerm.length === 0 ? 'Not editable' : 'Click to change search mode'} id='triple' value={this.props.endChecked} options={startModes} onChange={(e) => this.onEndToggleChange(e)} />
                    </div>
                </div>
            </Fieldset>
        )
    }
}

export default Options;