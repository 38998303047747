/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { TreeSelect } from 'primereact/treeselect';
class CreateBlacklist extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }
    onSaveBlacklist = async () => {
        this.props.onSaveBlacklist()
    }
    render() {

        const footerAddBlacklist = (
            <div className='col-12' style={{ paddingRight: 15, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 5 }}>* required</label>
                <Button label="Cancel" onClick={() => this.props.hideAddBlacklist()} className="p-button-secondary p-button-sm" />
                <Button label="Create" onClick={this.onSaveBlacklist}
                    className='p-button-sm primaryButton'
                    style={{ float: 'right', marginRight: -5 }}
                    disabled={this.props.newBlacklistName && this.props.newBlacklistName.length > 0 ? false : true} />
            </div>
        )

        return (
            <React.Fragment>

                <Toast ref={this.growl} />

                <Dialog
                    focusOnShow={false}
                    visible={this.props.displayDialogAddBlacklist}
                    style={{ width: '60vw' }}
                    blockScroll
                    header="Create new blocklist"
                    modal={true}
                    footer={footerAddBlacklist}
                    dismissableMask={true}
                    onHide={() => this.props.hideAddBlacklist()}
                    className='styledDialog'>
                    <div className="grid p-fluid" style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 25, paddingBottom: 15, paddingTop: 15, marginRight: 0, paddingRight: 15 }}>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Name *</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <InputText id="blName"
                                maxLength={50}
                                onChange={(e) => this.props.updateBlacklistName(e.target.value)}
                                value={this.props.newBlacklistName} />
                            <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Description</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <InputText id="blDescription"
                                maxLength={200}
                                onChange={(e) => this.props.updateBlacklistDescription(e.target.value)}
                                value={this.props.newBlacklistDescription} />
                            <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ display: 'flex', paddingBottom: 15, paddingRight: 10, paddingTop: 5 }}>
                            <div className="w-full" >
                                <TreeSelect disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.props.departmentMembers?.length !== 0 ? false : true}
                                    id='sharingTreeSelect'
                                    resetFilterOnHide={true}
                                    //appendTo={document.body}
                                    selectionMode='checkbox'
                                    placeholder='Select suborganizations'
                                    value={this.props.checkedOrgKeys}
                                    options={this.props.orgArray}
                                    display='chip'
                                    scrollHeight='200px'
                                    filter
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        this.props.handleMemberChange(e.value, true)
                                    }} style={{
                                        borderBottom: '1px solid gray', maxWidth: '19vw'
                                    }} />
                            </div>
                            <div className="w-full" >
                                <TreeSelect disabled={this.props.departmentMembers?.length !== 0 ? false : true}
                                    id='sharingTreeSelect'
                                    resetFilterOnHide={true}
                                    appendTo={document.body}
                                    selectionMode='checkbox'
                                    placeholder='Select users'
                                    //title='Select colleagues'
                                    value={this.props.checkedKeys}
                                    options={this.props.userArray}
                                    display='chip'
                                    scrollHeight='200px'
                                    //optionLabel='label'
                                    //optionGroupLabel='label'
                                    //optionGroupChildren="items"
                                    //optionGroupTemplate={groupedItemsTemplate}
                                    filter
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        this.props.handleMemberChange(e.value, false)
                                    }} style={{
                                        borderBottom: '1px solid gray', marginLeft: 10, maxWidth: '19vw'
                                    }} />
                            </div>
                        </div>
                        {this.props.usersShared && this.props.usersShared.length !== 0 ?
                            <React.Fragment>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Editable for shared users</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                    <MultiSelect
                                        pt={{
                                            checkboxIcon: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    if (e.target.className.baseVal !== "") {
                                                        e.target.parentNode.click();
                                                    } else {
                                                        e.target.parentNode.parentNode.click();
                                                    }
                                                },
                                            },
                                            headerCheckbox: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    e.target.parentNode.click();
                                                },
                                            },
                                        }}
                                        id='sharingMultiSelect'
                                        display='chip'
                                        placeholder='Select users'
                                        onChange={(e) => this.props.onWriteMembersChange(e.value)}
                                        value={this.props.usersWrite}
                                        options={this.props.sharedUsers}
                                    >
                                    </MultiSelect>
                                </div>
                            </React.Fragment>
                            : null
                        }
                        <div style={{ display: 'flex', justifyContent: 'end', paddingLeft: 17, width: '100%' }}>
                            <div className="full-text-message-collections col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8">
                                Note: Users changing the suborganization will lose access to blocklists shared on suborganization level, but will keep access to blocklists shared on personal level.
                            </div>
                        </div>
                    </div>
                </Dialog>

            </React.Fragment>
        );
    }
}

export default CreateBlacklist;
