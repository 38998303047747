//import React from 'react';
import { Button } from "primereact/button";
//import { useDispatch } from "react-redux";
//import styles from './TrendAnalysisModalFooter.modal.scss';
//import { setShowModal } from "../../../../../actions/YearStatisticsActions";

export const TrendAnalysisModalFooter = (props) => {
    //const dispatch = useDispatch();
    //console.log(props)
       return (
        <div //className={styles.wrapper} 
        style= {{ marginTop: -8, marginBottom: -2, paddingRight: 5}}>
            <Button label="Close" className="p-button p-component p-button-secondary p-button-sm"
                onClick={(e) => props.onHide(e)} />
            <Button label="Add" className="p-button p-component primaryButton p-button-sm"
                onClick={(e) => props.callbackFromAddClick(e)//dispatch(setShowModal(false))
                } 
                disabled={(props.activeIndex === 0 && props.queryTerms.length === 0) || (props.activeIndex === 1 && props.selectedWatchlistsAction === null) ? true : false}/>
        </div>
    );
};
