export const shortText = 'The biomarker app aggregates information about biomarkers, biomarker targets, relevant drugs, ' +
    'target populations etc. from various structured as well as unstructured sources in an automatic way.'

export const fullText = 'The biomarker app aggregates information about biomarkers, biomarker targets, relevant drugs, ' +
    'target populations etc. from various structured as well as unstructured sources in an automatic way. \n' +
    'There are three ways to create a query. You can either enter information manually or you can select shown concepts' +
    'from the facet information on the left or you can click on terms directly in the results list. If what you entered' +
    'or selected is a semantic concept, you can decide if you want to use this concept alone or together with its' +
    'descendant concepts or even as a single term (with and without stemming) only. For terms that have not been' +
    'semantically normalized only those two latter options are available. Instead of adding a concept as a filter you' +
    'can also exclude it from the results. The “Any” checkbox to the left allows to filter for results that contain any' +
    'concept at a certain position.'
