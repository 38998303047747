import "./QueryEditorToolbar.css";
import React from "react";
import ToolbarCategory from "./ToolbarCategory";


export default function QueryEditorToolbar({ toolbarItems }) {

    return <>
        <div id="queryEditorToolbar" className="query-editor-toolbar">
            <div className="grid query-editor-categories width100perc">
                {toolbarItems.map(toolbarItem => {
                    return <React.Fragment key={toolbarItem.label}>
                        <ToolbarCategory
                            toolbarItem={toolbarItem}
                        />
                    </React.Fragment>
                })}
            </div>
        </div>
    </>
}