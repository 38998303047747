import { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ERROR_TYPE_CHEM_CLASS_SEARCH_SETTINGS_NOT_SUPPORTED, BUTTON_OK, BUTTON_CLOSE, BUTTON_RUN_DEFAULT_SEARCH } from './errors';


class IncorrectInputDialog extends Component {

    onHide = () => {
        this.props.onToggleDialog(false);
    }

    // needed???
    renderErrorInfo = (error) => {

        switch (error.id) {

            case ERROR_TYPE_CHEM_CLASS_SEARCH_SETTINGS_NOT_SUPPORTED.id:
                return <span>
                    {error.info}
                </span>

            default:
                return <span>
                    {error.info}
                </span>
        }
    }

    renderFooter = (buttons) => {

        return buttons ? <div style={{ paddingTop: 5 }}>
            {/* <label style={{ float: 'left', paddingTop: 10 }}></label> */}
            {buttons[BUTTON_RUN_DEFAULT_SEARCH] ?
                <Button label="Run exact compound search"
                    className='p-button-sm primaryButton'
                    onClick={() => {
                        this.onHide();
                        this.props.onClick();
                        //onSelectedCompoundSearch(createQueryTermForOcid(Object.keys(this.state.selectedCompoundOcids)[0]));
                    }}
                    //disabled={isObjectEmpty(this.state.selectedCompoundOcids)}
                    style={{ float: 'right', marginRight: 0 }}
                /> : null
            }
            {buttons[BUTTON_OK] ?
                <Button label="OK"
                    className='p-button-sm primaryButton'
                    onClick={this.onHide}
                    style={{ float: 'right', marginRight: 10 }}
                /> : null
            }
            {buttons[BUTTON_CLOSE] ?
                <Button label="Close"
                    className='p-button-secondary p-button-sm'
                    onClick={this.onHide}
                    style={{ float: 'right', marginRight: 10 }}
                /> : null
            }
        </div> : null
    }

    render() {

        const { displayDialog, error } = this.props;
        //console.log('errors: ', errors);

        return (
            <Dialog
                focusOnShow={false}
                visible={displayDialog}
                style={{ minHeight: 50, maxHeight: '50vh', minWidth: 500, maxWidth: '50vw' }}
                header={error?.problem ? error.problem : ''}
                footer={this.renderFooter(error?.buttons)}
                modal={true}
                dismissableMask={true}
                //onShow={() => {}}
                onHide={this.onHide}
                className='styledDialog'
            >
                {error ?
                    <div className="grid" style={{ /**/padding: '10px 15px 10px 25px', width: '100%', lineHeight: 1.5, borderBottom: '1px solid #e5e5e5' }}>
                        <div className="col-12"
                            style={{ /*padding: '10px 20px', marginTop: 10, background: '#FDF7E2'*/ }}> {/**/}
                            {this.renderErrorInfo(error)}
                        </div>
                    </div> : null}

            </Dialog>
        );
    }
}
export default IncorrectInputDialog;