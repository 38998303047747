import { CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND, CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, CHEMISTRY_QUERY_TERM_TYPE_PROPERTY, CHEMISTRY_SEARCH_FILTER_ID_SEARCH_TYPE, STRUCTURE_FORMAT_MOL, STRUCTURE_FORMAT_RXN } from "./chemistrySearchConstants";
import { createFilterTermForBulkImport } from "./chemistryQueryTerms";
import { CHEM_DB_ITEMS, SEARCH_TARGET_COMPOUNDS, SEARCH_TARGET_OPTIONS, SEARCH_TYPE_EXACT, SEARCH_TYPE_FULL_MATCH } from "./chemistryDBs";
import { CHEM_DB_STATE_ERROR, CHEM_DB_STATE_INITIALISED } from "./chemistryResults";
import { isChemClassInput } from "./queryChecker";
import { getSearchTypeFromFilterValues } from "./filtersUtil";
import { isArrayEmpty } from "../../util";
import { isChemClassOcid, isChemCompoundOcid } from "./compoundClassGroup";


export const isReactionFileFormat = (structure) => {
    return structure && structure?.startsWith('$RXN');
}

export const determineAvailableChemDBItems = (repositories) => {

    const availableChemDBItemsList = [];
    const duplicateCounters = {};
    const repos = repositories.filter(repo => repo.active && repo.features?.includes('CHEMISTRY_SEARCH'));
    repos.forEach(repo => {
        Object.entries(CHEM_DB_ITEMS).forEach(([service, chemDB]) => {
            if (chemDB.index === repo.name) {
                const chemDBItem = { ...chemDB };
                const key = `${chemDB.index}:${chemDB.service}`;

                if (!duplicateCounters[key]) {
                    duplicateCounters[key] = 0;
                }
                duplicateCounters[key] = duplicateCounters[key] + 1;
                if (duplicateCounters[key] > 1) {
                    chemDBItem.label = `${chemDBItem.label} ${duplicateCounters[key]}`;
                }

                chemDBItem.repositoryID = repo.id;
                availableChemDBItemsList.push(chemDBItem);
            }
        });
    });
    //console.log('availableChemDBItemsList', availableChemDBItemsList);

    /*
    const allowedChemServices = {};
    const repos = repositories.filter(repo => repo.active && repo.features?.includes('CHEMISTRY_SEARCH'));
    repos.forEach(repo => {
        if (!allowedChemServices[repo.name]) {
            allowedChemServices[repo.name] = repo;
        }
    });
    Object.entries(CHEM_DB_ITEMS).forEach(([service, chemDB]) => {
        if (allowedChemServices[chemDB.index]) {
            const repoInfo = allowedChemServices[chemDB.index];
            const chemDBItem = { ...chemDB };
            if (repoInfo) {
                chemDBItem.repositoryID = repoInfo.id ? repoInfo.id : chemDB.repositoryID;
            }
            availableChemDBItemsList.push(chemDBItem);
        }
    });
    */

    return availableChemDBItemsList;
}

export const determineStructureFormat = (structure) => {
    return isReactionFileFormat(structure) ? STRUCTURE_FORMAT_RXN : STRUCTURE_FORMAT_MOL;
}

export const determineActiveSettings = (selectedTargetOption, availableChemDBItemsList, currSelectedChemDB, chemDBFilterMap,
    isAttributeSearch, filterDefinitions, filterInputValues) => {
    // --- get active target options, e.g. compounds, reactions etc. --- //
    const { activeChemDBs, selectedChemDB } = determineActiveChemDBs(selectedTargetOption, availableChemDBItemsList, currSelectedChemDB);
    const activeFilters = chemDBFilterMap[selectedChemDB?.service];
    const { selectedSearchType } = determineActiveSearchTypes(
        isAttributeSearch, filterDefinitions[CHEMISTRY_SEARCH_FILTER_ID_SEARCH_TYPE], selectedTargetOption, filterInputValues);
    return { activeChemDBs, selectedChemDB, activeFilters, selectedSearchType };
}

export const determineAvailableTargetOptions = (availableChemDBItemsList = [], isAttributeSearch) => {
    const targetOptions = {};
    availableChemDBItemsList.forEach(chemDB => {
        Object.entries(chemDB.allowedSearchTargets).forEach(([key, val]) => {
            if (val) {
                if (!targetOptions[key]) {
                    targetOptions[key] = true;
                }
            }
        });
    });
    const availableTargetOptions = Object.values(SEARCH_TARGET_OPTIONS).filter(targ =>
        targetOptions[targ.value] && (isAttributeSearch || targ.allowedForStructureSearch));
    return { availableTargetOptions };
}

export const determineDefaultTargetOption = (availableTargetOptions, selectedTargetOption) => {
    const selTargetOption = availableTargetOptions.find(targOption => targOption.value === selectedTargetOption);
    const selTargetOptionValue = selTargetOption ? selTargetOption.value : availableTargetOptions[0] ? availableTargetOptions[0].value : null;
    return selTargetOptionValue;
}

export const determineActiveChemDBs = (selectedTargetOption, availableChemDBItemsList, currSelectedChemDB) => {
    let activeChemDBs;
    let selectedChemDB;
    if (selectedTargetOption) {
        activeChemDBs = availableChemDBItemsList?.filter(chemDB => chemDB.allowedSearchTargets[selectedTargetOption] === true);
        selectedChemDB = currSelectedChemDB ? activeChemDBs?.find(chemDB => chemDB.service === currSelectedChemDB.service) : null;
        selectedChemDB = selectedChemDB ? selectedChemDB : activeChemDBs && activeChemDBs[0] ? activeChemDBs[0] : null;
    }
    return { activeChemDBs, selectedChemDB };
}

export const determineActiveSearchTypes = (isAttributeSearch, searchTypes, selectedTargetOption, filterInputValues) => {
    let selectedSearchTypeFilter;
    searchTypes.filterValues?.forEach(filter => {
        const allowMap = filter.attrAllowMap; //isAttributeSearch ? filterValue.attrAllowMap : filterValue.rxnAllowMap; // TODO: molXXX

        if (allowMap && allowMap[selectedTargetOption]) {
            filter.hide = false;
        }
        else {
            filter.hide = true;
        }
        // --- find actual filter object of currently active search type --- //
        if (filterInputValues?.searchType?.value?.filterValue === filter.filterValue) {
            selectedSearchTypeFilter = filter;
        }
    });

    // --- check if currently active filter is allowed for new settings, otherwise switch to default filter --- //
    let selectedSearchType;
    if (!selectedSearchTypeFilter || selectedSearchTypeFilter?.hide) {
        selectedSearchType = searchTypes.filterValues?.find(filterValue => !filterValue.hide && filterValue.isDefault);
        selectedSearchType = selectedSearchType ? selectedSearchType : searchTypes.filterValues[0];
    }
    return { searchTypes, selectedSearchType }
}

export const determineActiveIncludeMixtures = (isAttributeSearch, queryTerms, currWithMixtures, filterInputValues, selectedTargetOption, currSelectedChemDB) => {

    const searchType = getSearchTypeFromFilterValues(filterInputValues);
    let withMixtures = !!currWithMixtures;

    // --- mixtures filter only available for certain chem DBs and compound search --- //
    if (!currSelectedChemDB?.allowIncludeMixtures || selectedTargetOption !== SEARCH_TARGET_COMPOUNDS) {
        withMixtures = false;
    }
    // --- mixtures always included in exact or full match search, except for ontological search --- //
    else if (searchType === SEARCH_TYPE_EXACT || searchType === SEARCH_TYPE_FULL_MATCH) {
        if (!isAttributeSearch || !isChemClassInput(queryTerms)) {
            withMixtures = true;
        }
    }
    // --- for other searches value will be taken from the filter --- //
    return withMixtures;
}

export const determineIncludeMixturesFilterActive = (isAttributeSearch, queryTerms, filterInputValues, selectedTargetOption, currSelectedChemDB) => {

    const searchType = getSearchTypeFromFilterValues(filterInputValues);
    let withMixturesFilterActive = true;

    // --- mixtures filter only available for certain chem DBs and compound search --- //
    if (!currSelectedChemDB?.allowIncludeMixtures || selectedTargetOption !== SEARCH_TARGET_COMPOUNDS) {
        withMixturesFilterActive = false;
    }
    // --- mixtures always included in exact or full match search, except for ontological search --- //
    else if (searchType === SEARCH_TYPE_EXACT || searchType === SEARCH_TYPE_FULL_MATCH) {
        if (!isAttributeSearch || !isChemClassInput(queryTerms)) {
            withMixturesFilterActive = false;
        }
    }
    // --- for other searches value will be taken from the filter --- //
    return withMixturesFilterActive;
}

export const createChemDBKey = (chemDB) => {
    return `${chemDB.service}:${chemDB.repositoryID}`;
}

export const destructureChemDBKey = (chemDBKey) => {
    return chemDBKey.split(':');
}

export const addChemistryResult = (response, chemDB, chemDBSearchResults) => {

    const searchResult = {};
    //let hasError = false;

    const key = createChemDBKey(chemDB);

    if (!response) {
        searchResult[key] = { status: 'FAILED', hasError: true, message: 'Unknown error occurred.' };
        //hasError = true;
    }
    else if (response.status !== 'SUCCESS') {
        searchResult[key] = { status: 'FAILED', hasError: true, message: response?.message };
        //hasError = true;
    }
    else if (!response.payload) {
        searchResult[key] = { status: 'FAILED', hasError: true, message: 'No results.' };
        //hasError = true;
    }
    else {
        const result = response.payload;
        //console.log('result: ', result);
        result.status = 'SUCCESS';
        result.hasError = false;
        searchResult[key] = result;
    }

    const chemDBSearchResultsNew = { ...chemDBSearchResults, ...searchResult };

    return chemDBSearchResultsNew;
}

export const createEmptySearchResult = (activeChemDBs, chemDBWithError) => {
    /*
    const emptyChemResults = {};
    activeChemDBs?.forEach(chemDB => {
        if (chemDB.service === chemDBWithError?.service) {
            emptyChemResults[chemDB.service] = { status: CHEM_DB_STATE_ERROR, info: '' };
        }
        else {
            emptyChemResults[chemDB.service] = { status: CHEM_DB_STATE_INITIALISED, info: '' };
        }
    });
    return emptyChemResults;
    */

    const emptyChemResults = {};
    activeChemDBs?.forEach(chemDB => {
        const key = createChemDBKey(chemDB);
        if (chemDB.service === chemDBWithError?.service) {
            emptyChemResults[key] = { status: CHEM_DB_STATE_ERROR, info: '' };
        }
        else {
            emptyChemResults[key] = { status: CHEM_DB_STATE_INITIALISED, info: '' };
        }
    });
    return emptyChemResults;
}

/**
 * Adds concepts as query terms to query terms array.
 * @param {Object} conceptsMap map of concepts, with OCIDs as keys
 * @param {Array} queryTerms array of query terms
 * @returns new array with updated query terms
 */
export const addPropertyToQueryTerms = (conceptsMap, queryTerms, mergeConcepts = false) => {

    const queryTermsNew = queryTerms ? [...queryTerms] : [];
    const concepts = conceptsMap ? Object.values(conceptsMap) : [];

    if (mergeConcepts) {
        // --- merge all concepts selected in domain domain explorer into one query term --- //
        const ocids = {};
        const domains = {};
        const terms = {};
        const mergedTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_PROPERTY,
        };
        concepts.forEach(concept => {
            ocids[concept.ocid] = true;
            domains[concept.domain] = true;
            terms[concept.preferredName] = true;
        })
        mergedTerm.domains = Object.keys(domains);
        mergedTerm.ocids = Object.keys(ocids);
        mergedTerm.queryValues = Object.keys(terms);
        mergedTerm.term = mergedTerm.queryValues.join(' OR ');
        queryTermsNew.push(mergedTerm);
    }
    else {
        // --- new concepts are added as single query terms --- //
        Object.values(concepts).forEach(concept => {
            queryTermsNew.push({
                type: CHEMISTRY_QUERY_TERM_TYPE_PROPERTY,
                queryValues: [],
                term: concept.preferredName,
                domains: [concept.domain],
                ocids: [concept.ocid]
            });
        })
    }

    return queryTermsNew;
}
/**
 * Adds concepts as query terms to query terms array.
 * @param {Object} conceptsMap map of concepts, with OCIDs as keys
 * @param {Array} queryTerms array of query terms
 * @returns new array with updated query terms
 */
export const addConceptsToQueryTerms = (conceptsMap, queryTerms, mergeConcepts = false) => {

    const queryTermsNew = queryTerms ? [...queryTerms] : [];
    const concepts = conceptsMap ? Object.values(conceptsMap) : [];

    if (mergeConcepts) {
        console.log('merge');
        // --- merge all concepts selected in domain domain explorer into one query term --- //
        const ocids = {};
        const domains = {};
        const terms = {};
        const mergedTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
        };
        concepts.forEach(concept => {
            if (concept.ocid) {
                ocids[concept.ocid] = true;
            }
            domains[concept.domain] = true;
            terms[concept.preferredName] = true;
        })

        if (!isArrayEmpty(ocids)) {
            mergedTerm.ocids = Object.keys(ocids);
            mergedTerm.subType = isChemClassOcid(mergedTerm.ocids[0]) ? CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS : CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND;
        }
        mergedTerm.domains = Object.keys(domains);
        mergedTerm.queryValues = Object.keys(terms);
        mergedTerm.term = mergedTerm.queryValues.join(' OR ');

        queryTermsNew.push(mergedTerm);
    }
    else {
        console.log('do not merge', concepts);
        // --- new concepts are added as single query terms --- //
        Object.values(concepts).forEach(concept => {
            queryTermsNew.push({
                type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                subType: isChemClassOcid(concept.ocid) ? CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS : CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
                queryValues: [],
                term: concept.preferredName,
                domains: [concept.domain],
                ocids: [concept.ocid],
            });
        })
    }

    return queryTermsNew;
}

/**
 * Replaces given query term with new query term created from merged concepts.
 * @param {Object} conceptsMap map of concepts, with OCIDs as keys
 * @param {Array} queryTerms array of query terms
 * @param {Object} queryTermToReplace the query term that should be replaced
 * @returns new array with updated query terms
 */
export const replaceQueryTermWithConcepts = (conceptsMap, queryTerms, queryTermToReplace) => {

    let queryTermsNew = queryTerms ? [...queryTerms] : [];
    const concepts = conceptsMap ? Object.values(conceptsMap) : [];

    // --- replace item with merged concepts --- //
    let index = -1;
    if (queryTermToReplace) {
        //queryTermsNew = removeValueFromArray(queryTermsNew, queryTermToReplace);
        index = queryTermsNew.indexOf(queryTermToReplace);

        // --- merge all concepts selected in domain domain explorer into one query term --- //
        const ocids = {};
        const domains = {};
        const terms = {};
        const mergedTerm = {
            type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
        };
        concepts.forEach(concept => {
            ocids[concept.ocid] = true;
            domains[concept.domain] = true;
            terms[concept.preferredName] = true;
        });
        if (!isArrayEmpty(ocids)) {
            mergedTerm.ocids = Object.keys(ocids);
            mergedTerm.subType = isChemClassOcid(mergedTerm.ocids[0]) ? CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS : CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND;
        }
        mergedTerm.domains = Object.keys(domains);
        mergedTerm.queryValues = Object.keys(terms);
        mergedTerm.term = mergedTerm.queryValues.join(' OR ');
        //console.log('mergedTerm: ', mergedTerm);
        if (index >= 0) {
            queryTermsNew[index] = mergedTerm;
        }
        else {
            queryTermsNew.push(mergedTerm);
        }
    }

    return queryTermsNew;
}

export const addChemistryFiltersToQueryTerms = (filterInputValues, filter, queryTerms) => {

    //console.log('+++filterInputValues: ', filterInputValues);
    //console.log('+++filter: ', filter);
    //console.log('+++queryTerms: ', queryTerms);

    const queryTermsNew = queryTerms ? [...queryTerms] : [];

    if (filterInputValues && filter) {
        switch (filter.type) {

            /*
            case SEARCH_FILTER_TYPE_INPUT_FREE: {
                const filterTerm = createFilterTermForMetadata(filter, filterInputValues.input, filterInputValues.input, filterInputValues.booster, filter.fuzziness);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_DROPDOWN_STATIC: {
                const filterTerm = createFilterTermForMetadata(filter, filterInputValues.value.filterValue, filterInputValues.value.filterValueLabel, filterInputValues.value.booster);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_TERMLOC: {
                const filterTerm = createFilterTermForTermLocation(filter, filterInputValues.value.filterValue);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_IPC_DOMAIN: {
                const filterTerm = createFilterTermForIPCDomain(filter, filterInputValues.value.filterValue);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_DATE_RANGE: {
                const filterTerm = createFilterTermForDateRanges(filter, filterInputValues.startDate, filterInputValues.endDate);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_FACETS_WITH_INPUT:
            case SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY: {
                const filterTerm = createFilterTermForMetadataMultipleStructuredValues(filter, filterInputValues.selectedFilterEntries);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_AUTOCOMPLETE_WITH_TERMLOC: {
                const filterTerm = createFilterTermForAutocompleteTermloc(filter, filterInputValues.termlocQueryTerms);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_INPUT_FREE_TERMLOC: {
                const filterTerm = createFilterTermForFreeInputTermLocation(filter, [filterInputValues.input]);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }
            */
        }
    }

    return queryTermsNew;
}

export const addChemistryBulkIDsToQueryTerms = (filter, bulkIDType, bulkIDs, queryTerms, queryTermToReplace) => {
    let queryTermsNew = queryTerms ? [...queryTerms] : [];
    let index = -1;
    if (queryTermToReplace) {
        index = queryTermsNew.indexOf(queryTermToReplace);
    }
    const filterTerm = createFilterTermForBulkImport(filter, bulkIDType.id, bulkIDs);
    if (filterTerm) {
        if (index >= 0) {
            queryTermsNew[index] = filterTerm;
        }
        else {
            queryTermsNew.push(filterTerm);
        }
    }
    return queryTermsNew;
}

export const mergeQueryTerms = (queryTerms) => {

    const queryTermsNew = [];

    const ocids = {};
    const domains = {};
    const terms = {};
    const mergedTerm = {
        type: CHEMISTRY_QUERY_TERM_TYPE_PROPERTY,
    };
    queryTerms?.forEach(queryTerm => {
        if (queryTerm.ocids) {
            queryTerm.ocids.forEach(ocid => { ocids[ocid] = true });
        }
        if (queryTerm.domains) {
            queryTerm.domains.forEach(domain => { domains[domain] = true });
        }
        terms[queryTerm.term] = true;
    })
    mergedTerm.domains = Object.keys(domains);
    mergedTerm.ocids = Object.keys(ocids);
    mergedTerm.queryValues = Object.keys(terms);
    mergedTerm.term = mergedTerm.queryValues.join(' OR ');
    queryTermsNew.push(mergedTerm);

    return queryTermsNew;
}


/**
 * Converts saved search and its form content to chemistry search 2.0 form object used in state.
 * @param {*} savedSearch 
 * @param {*} formContent 
 * @returns 
 */
export const convertSavedSearchToState = (savedSearch, formContent) => {

    return {
        selectedSavedSearch: savedSearch,
        queryTerms: formContent.queryTerms,
        structure: formContent.structure,
        editableQuery: savedSearch.edit,
        queryID: savedSearch.id,
        queryFullName: savedSearch.fullName,
        queryName: savedSearch.name,
        queryDescription: savedSearch.description,
        queryString: savedSearch.val,
        selectedQueryCollectionIDs: savedSearch.queryCollectionList ? savedSearch.queryCollectionList.map(coll => coll.id) : []
    };
}

/**
 * Adds or removes identifier from a map, depending on select parameter.
 * @param {*} map 
 * @param {*} key 
 * @param {*} select 
 * @returns 
 */
export const changeEntrySelection = (map, key, select, concept) => {
    const newMap = map ? { ...map } : {};
    if (select) {
        newMap[key] = concept;
    }
    else {
        delete newMap[key];
    }
    return newMap;
} 