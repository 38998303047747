import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Accordion, AccordionTab } from "primereact/accordion";
import { isArrayEmpty } from "../../util";
import { Button } from "primereact/button";
import FilterSectionFacetsWithValues from "./FilterSectionFacetsWithValues";
import FacetsSelectionDialog from "./FacetsSelectionDialog";
import { DOMAIN_FACETS, PREFIX_DOMAIN_FACET, SEARCH_FILTER_ID_DOMAIN, SEARCH_FILTER_ID_DOMAIN_ANY } from "../docsearch/searchConstants";
import FilterEntry from "./FilterEntry";
import DomainFacetsInfo from "../../docsearch/infos/DomainFacetsInfo";
import './Filters.css';
import { getFilterDefinitionForRepository } from "../../../../properties";

const OPTION_DOMAIN_ANY = { term: `Any` };


class DomainFiltersBlock extends Component {

    constructor(props) {
        super(props);

        this.state = { activeIndex: 0 };

        this.growl = createRef();
        this.toast = createRef();
    }

    onLoadMoreFacets = (filter, domainID) => {
        this.props.onLoadMoreFacets(filter);
        this.setState({ displayMoreFacetsDialog: true, moreFacetsFilter: filter, moreFacetsDomainID: domainID });
    }

    renderFilterButton = (filter, filterInputValues) => {

        const show = (!this.state.displayMoreFacetsDialog && filter && filterInputValues && filter.id === filterInputValues.filterID);
        return show ? <Button
            type="button"
            label={`Add filter`}
            className="p-button-sm primaryButton width100perc"
            style={{ marginTop: 10 }}
            onClick={(e) => { this.props.onAddFiltersToQuery(filter.queryPrefix) }} />
            : null;
    }

    onSelectionChange = (e, filterID) => {
        this.props.onSelectionChange(e, filterID);
    }

    onAddSelectedFacet = (filterInputValues, domainID) => {
        filterInputValues.domain = domainID;
        this.props.onAddSelectedFacet(filterInputValues);
    }

    renderFilterSections = () => {

        // --- filter for domain facets only and remove blocked terms --- //
        const filtersDomains = {};
        if (this.props.facets) {
            Object.keys(this.props.facets)
                .filter(facetID => facetID.startsWith(PREFIX_DOMAIN_FACET))
                .forEach(facetID => {
                    filtersDomains[facetID] = this.props.facets[facetID];
                    if (this.props.blocklist && this.props.blocklist[facetID] && filtersDomains[facetID]?.facets) {
                        const blocklistedTerms = this.props.blocklist[facetID];
                        filtersDomains[facetID].facets = filtersDomains[facetID].facets.filter(facet => { return !blocklistedTerms.includes(facet.term) });
                    }
                })
        }

        const filterSections = (

            DOMAIN_FACETS
                .filter(domainID => {
                    const entries = filtersDomains[domainID];
                    return !isArrayEmpty(entries);
                })
                .map(domainID => {
                    const entries = filtersDomains[domainID];
                    //console.log('this.props.exampleValues: ', this.props.exampleValues);
                    const domainPrefix = domainID.replace(PREFIX_DOMAIN_FACET, '');
                    const domainLabel = this.props.domainLabelsMap && this.props.domainLabelsMap[domainPrefix] ?
                        this.props.domainLabelsMap[domainPrefix] : domainPrefix;
                    // --- dynamic filters for domain facets --- //
                    const filterDomainAny = {
                        id: SEARCH_FILTER_ID_DOMAIN_ANY,
                        label: domainLabel,
                        queryPrefix: domainPrefix,
                        facetID: domainID
                    };
                    const filterDomain = {
                        id: SEARCH_FILTER_ID_DOMAIN,
                        label: domainLabel,
                        queryPrefix: domainPrefix,
                        facetID: domainID
                    }

                    return <AccordionTab key={domainID} header={domainLabel}>
                        <div style={{ fontWeight: 500, marginBottom: 5 }}>
                            <FilterEntry
                                filterID={domainID}
                                filterEntry={OPTION_DOMAIN_ANY}
                                isSelected={this.props.filterInputValues?.filterID === filterDomainAny.id &&
                                    this.props.filterInputValues.domain === domainID}
                                onSelectionChange={(e) => { this.props.onSelectDomainAnyOption(e, filterDomainAny.id, domainID); }}
                                showOccurrences={false}
                            />
                        </div>
                        <hr style={{ margin: '10px 0 7px 0' }} />
                        <FilterSectionFacetsWithValues
                            filter={filterDomain}
                            filterID={filterDomain.id}
                            facetEntries={entries ? entries : []}
                            selectedFacets={this.props.filterInputValues?.filterID === filterDomain.id ?
                                this.props.filterInputValues.selectedFilterEntries : []}
                            onInputChange={this.props.onFilterInputValuesChange}
                            onSelectionChange={this.onSelectionChange}
                            onAddSelectedFacet={(filterInputValues) => {
                                filterInputValues.domain = domainID;
                                this.onAddSelectedFacet(filterInputValues);
                            }}
                            onLoadMoreFacets={(filterDomain) => this.onLoadMoreFacets(filterDomain, domainID)}
                            displayMoreFacetsDialog={this.state.displayMoreFacetsDialog}
                            onHideMoreFacetsDialog={() => this.setState({ displayMoreFacetsDialog: false })}
                            includeInputField={false}
                            showOccurrences={this.props.showOccurrences}
                        />
                        {this.renderFilterButton(filterDomainAny, this.props.filterInputValues)}
                        {this.renderFilterButton(filterDomain, this.props.filterInputValues)}
                    </AccordionTab>
                })
        );

        return (
            <>
                <Accordion
                    className="simpleAccordion facets"
                    multiple={false}
                    activeIndex={this.state.activeIndex}
                    expandIcon="pi pi-chevron-up"
                    collapseIcon="pi pi-chevron-down"
                    onTabChange={(e) => {
                        this.props.onAddSelectedFacet({});
                        this.setState({ activeIndex: e.index });
                    }} >
                    {filterSections}
                </Accordion>
            </>
        );
    }


    render() {

        const { repositoryFilterMap, fetchingFilters, selectedRepository } = this.props;

        const repoFilters = getFilterDefinitionForRepository(repositoryFilterMap, selectedRepository?.name);
        // const repoFilters = repositoryFilterMap ? repositoryFilterMap[selectedRepository?.name] : [];
        //console.log('repoFilters: ', repoFilters);

        return (
            <>
                <Toast ref={this.growl} />
                <Toast ref={this.toast} position="bottom-left" className="filterToast" />

                <div className="grid grid-nogutter">
                    <div className="col"><h2 className="filterBlockHeading">DOMAINS</h2></div>
                    <div className="col-fixed" style={{ paddingTop: 10 }}>
                        <DomainFacetsInfo />
                    </div>
                </div>

                {fetchingFilters ?
                    <div className="secondaryInfo">Fetching domain facets...</div>
                    :
                    this.props.filterDefinitions && !isArrayEmpty(repoFilters) ? this.renderFilterSections(repoFilters)
                        :
                        <div className="secondaryInfo">No filters</div>
                }

                <FacetsSelectionDialog
                    filter={this.state.moreFacetsFilter}
                    filterID={this.state.moreFacetsFilter?.id}
                    selectedFacets={this.props.filterInputValues?.filterID === this.state.moreFacetsFilter?.id ?
                        this.props.filterInputValues?.selectedFilterEntries : []}
                    onSelectionChange={this.props.onSelectionChange}
                    onAddFacetsToQuery={(filter) => {
                        this.setState({ displayMoreFacetsDialog: false });
                        this.props.onAddFiltersToQuery(filter.queryPrefix);
                    }}
                    exampleValues={this.props.exampleValues && this.props.exampleValues[this.state.moreFacetsFilter?.facetID] ?
                        this.props.exampleValues[this.state.moreFacetsFilter?.facetID] : null}
                    displayDialog={this.state.displayMoreFacetsDialog}
                    onHide={() => this.setState({ displayMoreFacetsDialog: false })}
                    showOccurrences={this.props.showOccurrences}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({ domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap })
const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomainFiltersBlock));