//import { sendApiRequest, sendExportToFileApiRequest } from '../index';
import { sendApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';


// ----------------------------------------------------------------------- //
// --- create requests from data ----------------------------------------- //
// ----------------------------------------------------------------------- //
export const createQueryTermForCorrelationsRequest = (domain, term, synonymsOnly = false, aggregationMode = "NONE", useCoocOccurrence = false) => {
  const req = {
    domain: domain,
    queryTerm: term,
    searchMode: !!synonymsOnly ? 'SYNONYM' : "ONTOLOGICAL",
    aggregationMode: aggregationMode,
  };

  if (useCoocOccurrence) {
    req.useCoocOccurrence = true;
  }

  return req;
}

export const createOCIDForCorrelationsRequest = (ocids, synonymsOnly = false, aggregationMode = "NONE", useCoocOccurrence = false) => {
  const req = {
    queryOcids: ocids,
    searchMode: !!synonymsOnly ? 'SYNONYM' : "ONTOLOGICAL",
    aggregationMode: aggregationMode,
  };

  if (useCoocOccurrence) {
    req.useCoocOccurrence = true;
  }

  return req;
}

export const createCorrelationRequest = (repository, correlationPartnerList,
  maxTopTermsPerCoocPartner, maxDirectCoocEntities, maxExampleSentences, withCoocCountMatrix, synonymsOnly = false,
  skipResultConcepts = null, startInsertionTime = null, endInsertionTime = null, domainWhitelist = null) => {

  const req = {
    apiRevision: 0,
    //repository: 'med',
    coocPartnerList: correlationPartnerList,
    maxDirectCoocEntities: maxDirectCoocEntities,
    maxExampleSentences: maxExampleSentences,
    maxTopTermsPerCoocPartner: maxTopTermsPerCoocPartner,
    coocCountMatrixBuildType: !!synonymsOnly ? 'EXACT' : "ONTOLOGICAL",
    coocCountMatrixRowSource: "DIRECT_COOC_ENTITIES",
    coocCountMatrixColSource: "DIRECT_COOC_ENTITIES",
    withCoocCountMatrix: withCoocCountMatrix,
    withResultTermName: true,
    withResultTermDomain: true,
  };

  if (repository) {
    req.repository = repository;
  }
  if (skipResultConcepts) {
    req.skipResultConcepts = skipResultConcepts
  }
  if (startInsertionTime) {
    req.startInsertionTime = startInsertionTime
  }
  if (endInsertionTime) {
    req.endInsertionTime = endInsertionTime
  }
  if (domainWhitelist) {
    req.domainWhitelist = domainWhitelist;
  }

  return req;
}

export const createCorrelationMatrixRequest = (repository, correlationPartnerList, maxDirectCoocEntities, synonymsOnly = false,
  skipResultConcepts = null, startInsertionTime = null, endInsertionTime = null, domainWhitelist = null) => {

  const req = {
    apiRevision: 0,
    //repository: 'med',
    coocPartnerList: correlationPartnerList,
    maxDirectCoocEntities: maxDirectCoocEntities,
    //maxExampleSentences: 0,
    //maxTopTermsPerCoocPartner: maxTopTermsPerCoocPartner,
    maxTopTermsPerCoocPartner: maxDirectCoocEntities,
    coocCountMatrixBuildType: !!synonymsOnly ? 'EXACT' : "ONTOLOGICAL",
    //coocCountMatrixRowSource: "DIRECT_COOC_ENTITIES",
    //coocCountMatrixColSource: "DIRECT_COOC_ENTITIES",
    withCoocCountMatrix: true,
    withResultTermName: true,
    withResultTermDomain: true,
  };

  if (repository) {
    req.repository = repository;
  }
  if (skipResultConcepts) {
    req.skipResultConcepts = skipResultConcepts
  }
  if (startInsertionTime) {
    req.startInsertionTime = startInsertionTime
  }
  if (endInsertionTime) {
    req.endInsertionTime = endInsertionTime
  }
  if (domainWhitelist) {
    req.domainWhitelist = domainWhitelist;
  }

  return req;
}

export const createCorrelationPairsRequest = (repository, correlationPartnerList, maxDirectCoocEntities,
  skipResultConcepts = null, startInsertionTime = null, endInsertionTime = null, domainWhitelist = null) => {

  const req = {
    apiRevision: 0,
    //repository: 'med',
    coocPartnerList: correlationPartnerList,
    maxDirectCoocEntities: maxDirectCoocEntities,
    //maxExampleSentences: 0,
    //maxTopTermsPerCoocPartner: maxTopTermsPerCoocPartner,
    withResultTermName: true,
    withResultTermDomain: true,
  };

  if (repository) {
    req.repository = repository;
  }
  if (skipResultConcepts) {
    req.skipResultConcepts = skipResultConcepts
  }
  if (startInsertionTime) {
    req.startInsertionTime = startInsertionTime
  }
  if (endInsertionTime) {
    req.endInsertionTime = endInsertionTime
  }
  if (domainWhitelist) {
    req.domainWhitelist = domainWhitelist;
  }

  return req;
}

export const createCorrelationTopTermsRequest = (repository, correlationPartnerList, skipResultConcepts = null,
  startInsertionTime = null, endInsertionTime = null, domainWhitelist = null) => {

  const req = {
    apiRevision: 0,
    //repository: 'med',
    coocPartnerList: correlationPartnerList,
    //maxTopTermsPerCoocPartner: maxTopTermsPerCoocPartner,
    //maxExampleSentences: maxExampleSentences,
    //withResultTermName: true,
    //withResultTermDomain: true,
    withPartnerDomainsInResultData: true
  };

  if (repository) {
    req.repository = repository;
  }
  if (skipResultConcepts) {
    req.skipResultConcepts = skipResultConcepts
  }
  if (startInsertionTime) {
    req.startInsertionTime = startInsertionTime
  }
  if (endInsertionTime) {
    req.endInsertionTime = endInsertionTime
  }
  if (domainWhitelist) {
    req.domainWhitelist = domainWhitelist;
  }

  return req;
}

export const createCorrelationTopTermsRequestV2 = (repository, correlationPartnerList, maxExampleSentences = 0,
  maxReturnTermsPerDomain = 10, withResultTermName = false, withResultTermDomain = false) => {

  const req = {
    apiRevision: 0,
    coocPartnerList: correlationPartnerList,
    maxReturnTermsPerDomain: maxReturnTermsPerDomain,
    maxExampleSentences: maxExampleSentences,
    withResultTermName: withResultTermName,
    withResultTermDomain: withResultTermDomain,
    withPartnerDomainsInResultData: true
  };

  if (repository) {
    req.repository = repository;
  }

  return req;
}

export const createCorrelationSentencesRequest = (repository, correlationPartnerList, maxTopTermsPerCoocPartner, maxExampleSentences,
  skipResultConcepts = null, startInsertionTime = null, endInsertionTime = null, domainWhitelist = null) => {

  const req = {
    apiRevision: 0,
    //repository: 'med',
    coocPartnerList: correlationPartnerList,
    maxExampleSentences: maxExampleSentences,
    //maxDirectCoocEntities: maxDirectCoocEntities,
    //maxTopTermsPerCoocPartner: maxTopTermsPerCoocPartner,
    withResultTermName: true,
    withResultTermDomain: true,
  };

  if (repository) {
    req.repository = repository;
  }
  if (skipResultConcepts) {
    req.skipResultConcepts = skipResultConcepts
  }
  if (startInsertionTime) {
    req.startInsertionTime = startInsertionTime
  }
  if (endInsertionTime) {
    req.endInsertionTime = endInsertionTime
  }
  if (domainWhitelist) {
    req.domainWhitelist = domainWhitelist;
  }

  return req;
}

export const createCorrelationSentencesRequest_bak = (repository, correlationPartnerList, maxDirectCoocEntities, maxExampleSentences,
  skipResultConcepts = null, startInsertionTime = null, endInsertionTime = null, domainWhitelist = null) => {

  const req = {
    apiRevision: 0,
    repository: repository,
    coocPartnerList: correlationPartnerList,
    maxExampleSentences: maxExampleSentences,
    maxDirectCoocEntities: maxDirectCoocEntities,
    //maxTopTermsPerCoocPartner: 0,
    withResultTermName: true,
    withResultTermDomain: true,
  };

  if (skipResultConcepts) {
    req.skipResultConcepts = skipResultConcepts
  }
  if (startInsertionTime) {
    req.startInsertionTime = startInsertionTime
  }
  if (endInsertionTime) {
    req.endInsertionTime = endInsertionTime
  }
  if (domainWhitelist) {
    req.domainWhitelist = domainWhitelist;
  }

  return req;
}


// ----------------------------------------------------------------------- //
// --- run API requests -------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * 
 * @param {Object} request 
 * @param {Array} blacklistIDs 
 * @param {Array} repositoryIDs 
 */
export const fetchCorrelations = async (request, blacklistIDs, repositoryIDs) => {

  let urlParameters = '';
  if (blacklistIDs && blacklistIDs.length > 0) {
    urlParameters = `?blacklistIds=${blacklistIDs}`;
  }
  if (repositoryIDs && repositoryIDs.length > 0) {
    urlParameters = `${!urlParameters ? `?` : `${urlParameters}&`}repositoryIds=${repositoryIDs}`;
  }

  //console.log('urlParameters: ', urlParameters);
  //urlParameters = '';
  const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/co-occurrences${urlParameters}`, request);

  //console.log('result: ', result);
  return result;
}
