import { useSelector } from "react-redux";


const useRepositoryLabel = (repositoryName) => {
    const repositoriesList = useSelector((state) => state.user.data.userDetails.department?.selectedRepositories);
    const repositoryLabel = repositoryName && repositoriesList?.find(repo => repo.name === repositoryName)?.label;

    return repositoryLabel;
}

export default useRepositoryLabel;