import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";

const APISyntaxGroupEdit = ({ queryString = "", onValuesChange }) => {
  const [editQuery, setEditQuery] = useState();
  const [queryStringUpdated, setQueryStringUpdated] = useState();

  useEffect(() => {
    setQueryStringUpdated(queryString);
  }, [queryString]);

  return (
    <div className="flex align-items-center w-full mr-7">
      {editQuery ? (
        <div className="p-inputgroup flex-1 align-items-center">
          <InputTextarea
            autoResize
            value={queryStringUpdated}
            onChange={(e) => setQueryStringUpdated(e.target.value)}
            style={{ width: "100%" }}
          />
          <div className="ml-2">
            <Button
              icon="pi pi-times"
              className="p-button-secondary inline-edit-button"
              onClick={() => {
                setQueryStringUpdated(queryString);
                setEditQuery(false);
              }}
            />
          </div>
          <div className="ml-1">
            <Button
              icon="pi pi-check"
              className="primaryButton inline-edit-button"
              disabled={!queryStringUpdated}
              onClick={() => {
                onValuesChange(queryStringUpdated);
                setEditQuery(false);
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <div>{queryString}</div>
          <div>
            {queryString ? (
              <div style={{ marginLeft: 15 }}>
                <a onClick={() => setEditQuery(true)}>Edit query string</a>
              </div>
            ) : (
              <a onClick={() => setEditQuery(true)}>Add query string</a>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default APISyntaxGroupEdit;
