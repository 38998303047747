import { createGoogleSearchUrl, GOOGLE_SMILES_QUERY_TYPE_EXACT, GOOGLE_SMILES_QUERY_TYPE_SIMILARITY, GOOGLE_SMILES_QUERY_TYPE_SUBSTRUCTURE, GOOGLE_SMILES_SEARCH_MODE_BOTH, GOOGLE_SMILES_SEARCH_MODE_NPL, GOOGLE_SMILES_SEARCH_MODE_PATENTS } from '../util';


/**
 * 
 * @param {*} smiles 
 * @returns 
 */
export const createGoogleLinkoutMenuItems = (smiles, openInNewTab = true) => {

    if (!smiles) {
        return [];
    }

    const items = [];

    const target = openInNewTab ? '_blank' : undefined;

    const googlePatentsLinkouts = {
        label: 'Google Patents',
        items: [{
            label: 'Exact',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_EXACT, smiles, GOOGLE_SMILES_SEARCH_MODE_PATENTS), target) }
        }, {
            label: 'Substructure',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_SUBSTRUCTURE, smiles, GOOGLE_SMILES_SEARCH_MODE_PATENTS), target) }
        }, {
            label: 'Similarity',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_SIMILARITY, smiles, GOOGLE_SMILES_SEARCH_MODE_PATENTS), target) }
        }]
    }
    items.push(googlePatentsLinkouts);

    const googleScholarLinkouts = {
        label: 'Google Scholar',
        items: [{
            label: 'Exact',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_EXACT, smiles, GOOGLE_SMILES_SEARCH_MODE_NPL), target) }
        }, {
            label: 'Substructure',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_SUBSTRUCTURE, smiles, GOOGLE_SMILES_SEARCH_MODE_NPL), target) }
        }, {
            label: 'Similarity',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_SIMILARITY, smiles, GOOGLE_SMILES_SEARCH_MODE_NPL), target) }
        }]
    }
    items.push(googleScholarLinkouts);

    const googlePatentsScholarLinkouts = {
        label: 'Google Patents + Scholar',
        items: [{
            label: 'Exact',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_EXACT, smiles, GOOGLE_SMILES_SEARCH_MODE_BOTH), target) }
        }, {
            label: 'Substructure',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_SUBSTRUCTURE, smiles, GOOGLE_SMILES_SEARCH_MODE_BOTH), target) }
        }, {
            label: 'Similarity',
            command: () => { window.open(createGoogleSearchUrl(GOOGLE_SMILES_QUERY_TYPE_SIMILARITY, smiles, GOOGLE_SMILES_SEARCH_MODE_BOTH), target) }
        }]
    }
    items.push(googlePatentsScholarLinkouts);

    return items;
}