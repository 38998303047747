import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SentenceAnalysisContainer from '../SentenceAnalysisContainer'



const mapStateToProps = state => ({
    userData: state.user.data,
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
    domainColors: state.user.data.userDetails.department.selectedDomains,
    domains: state.user.data.userDetails.department.selectedDomains,
    availableDomains: state.webAPI.availableDomains,
    sentences: state.sentenceAnalysisReducer.sentences,
    selectedSentence: state.sentenceAnalysisReducer.selectedSentenceID,
    documentSentences: state.sentenceAnalysisReducer.documentSentences,
    threshold: state.sentenceAnalysisReducer.threshold,
    sentenceOption: state.sentenceAnalysisReducer.sentenceOption,
    activeSearchTerms: state.sentenceAnalysisReducer.activeSearchTerms,
    annotationCoordinates: state.sentenceAnalysisReducer.coords,
    loader: state.sentenceAnalysisReducer.loading,
    showSentenceEntities: state.sentenceAnalysisReducer.showSentenceEntities,
    documentIsPDF: state.sentenceAnalysisReducer.isPDF,
    documents: state.sentenceAnalysisReducer.documents,
    selectedMetadataOption: state.sentenceAnalysisReducer.selectedMetadataOption,
    sectionOptions: state.sentenceAnalysisReducer.sectionOptions,
    selectedSections: state.sentenceAnalysisReducer.selectedSections,
    sortFields: state.sentenceAnalysisReducer.sortFields,
    selectedSortField: state.sentenceAnalysisReducer.selectedSortField,
    selectedSortDirection: state.sentenceAnalysisReducer.selectedSortDirection,
    sortDirectionDisabled: state.sentenceAnalysisReducer.sortDirectionDisabled,
    familyDocuments: state.sentenceAnalysisReducer.familyDocuments,
    familyDocumentsRemove: state.sentenceAnalysisReducer.familyDocumentsRemove,
    searchLoaded: state.sentenceAnalysisReducer.searchLoaded,
    docHTML: state.sentenceAnalysisReducer.docHTML
})

const mapDispatchToProps = dispatch => ({
})


const SentenceContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SentenceAnalysisContainer))

export default SentenceContainer