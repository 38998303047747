export const addFilterData = (node, filterDef) => {
  const children = Array.isArray(node.children)
    ? node.children.map((child) => addFilterData(child, filterDef))
    : node.children;
  if (node.type === "category") {
    return { ...node, children };
  }
  const filter = filterDef[node.filterID];
  const label = filter ? filter.label : `${node.filterID} (unknown)`;
  const filterField = filter
    ? `${filter.queryPrefix} --- ${filter.label}`
    : node.filterID;
  const selectable = !!filter;

  return { ...node, label, filterField, selectable, children };
};
