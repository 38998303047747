import "./SearchGroupsTable.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSearchGroups } from "../../../../../../redux/features/queryBuilder/actions";
import { removeValueFromArray } from "../../../../util";
import { SEARCH_GROUP_TYPE_LOGIC } from "../../../helpers/constants";
import GroupContentColumn from "./SearchGroupsTableColumns/GroupContentColumn";
import { isEmpty } from "lodash";
import GroupInfoColumn from "./SearchGroupsTableColumns/GroupInfoColumn";

const SearchGroupsTable = ({
  groups,
  reachableGroups,
  onReorderGroups,
  onRunPartialSearch,
}) => {
  const fetchingResults = useSelector(
    (state) => state.advancedSearch3Reducer.fetchingNumberOfResults
  );
  const selectedSearchGroups = useSelector(
    (state) => state.advancedSearch3Reducer.selectedSearchGroups
  );
  const errorsCurrent = useSelector(
    (state) => state.advancedSearch3Reducer.errors
  );
  const errorsPreview = useSelector(
    (state) => state.advancedSearch3Reducer.errorsPreview
  );
  const editGroup = useSelector(
    (state) => state.advancedSearch3Reducer.searchGroupToEdit
  );
  const showLogicGroupDialog = useSelector(
    (state) => state.advancedSearch3Reducer.toggleLogicGroupDialog
  );
  const dispatch = useDispatch();

  const editGroupIndex = groups?.indexOf(editGroup) || -1;
  const errors = !!errorsPreview
    ? errorsPreview
    : !!errorsCurrent
      ? errorsCurrent
      : {};

  const handleToggleSelection = (rowData) => {
    let selectedSearchGroupsNew = [...selectedSearchGroups];
    if (selectedSearchGroupsNew.includes(rowData.id)) {
      removeValueFromArray(selectedSearchGroupsNew, rowData.id);
    } else {
      selectedSearchGroupsNew.push(rowData.id);
    }
    dispatch(setSelectedSearchGroups(selectedSearchGroupsNew));
  };


  const emptyMessage = (
    <div
      className="p-5 mx-0 my-5 mr-3 border-round-md"
      style={{ backgroundColor: "#F5F5F5" }}
    >
      <div>This search is empty.</div>
      <br />
      <div className="font-normal">
        You can either load one of our examples, one of your saved searches or
        backups or start from scratch by adding a new search group...
      </div>
    </div>
  );

  return (
    <DataTable
      id="searchGroupsTable"
      value={groups}
      reorderableRows
      onRowReorder={(e) => onReorderGroups(e.value)}
      selectionMode="single"
      selection={editGroup}
      dataKey="id"
      tableStyle={{ width: "100%" }}
      emptyMessage={emptyMessage}
    >
      <Column
        field="index"
        body={(data, column) => {
          const isStartGroup = column.rowIndex === groups?.length - 1;
          const isReachable = !!reachableGroups[data.id];
          const isCheckboxVisible = editGroup?.id !== data.id && editGroup?.type === SEARCH_GROUP_TYPE_LOGIC;
          const isCheckboxDisabled = !showLogicGroupDialog || editGroupIndex <= column.rowIndex;
          const errorMessage = errors[data.id];
          const hasSearchError = !isEmpty(errors);
          const opacity =
            isReachable &&
              (!editGroup ||
                (!showLogicGroupDialog && editGroupIndex === column.rowIndex) ||
                (showLogicGroupDialog && editGroupIndex > column.rowIndex))
              ? 1
              : 0.6;
          return <GroupInfoColumn
            data={data}
            isStartGroup={isStartGroup}
            isReachable={isReachable}
            isCheckboxVisible={isCheckboxVisible}
            isCheckboxDisabled={isCheckboxDisabled}
            errorMessage={errorMessage}
            hasSearchError={hasSearchError}
            hasError={!isEmpty(errors)}
            loading={fetchingResults}
            onRunSearch={onRunPartialSearch}
            batchValue={column.rowIndex + 1}
            batchStyles={{ opacity }}
            onSelect={handleToggleSelection}
            selectedSearchGroups={selectedSearchGroups}
          />
        }}
        style={{ width: 75, paddingLeft: 10 }}
      />
      {!editGroup && <Column
        rowReorder
        style={{ width: 45, padding: 0 }}
      />}
      <Column
        field="label"
        body={(data) => {
          return <GroupContentColumn
            data={data}
            hasError={!isEmpty(errors)}
            loading={fetchingResults}
            onRunSearch={onRunPartialSearch}
          />
        }}
      />
    </DataTable>
  );
};

export default SearchGroupsTable;
