import { Dialog } from 'primereact/dialog';
import { Component } from 'react';
import SeparatorPoint from '../../../general/SeparatorPoint';
import { isArrayEmpty } from '../../../util';
import ReactionCompoundGroup from './ReactionCompoundGroup';
import ReactionExtraInfos from './ReactionExtraInfos';
import ReactionSources from './ReactionSources';

/*const COMPOUND_IMAGE_HEIGHT_MEDIUM = 100;
const COMPOUND_IMAGE_WIDTH_MEDIUM = 200;
const COMPOUND_IMAGE_HEIGHT_SMALL = 50;
const COMPOUND_IMAGE_WIDTH_SMALL = 100;*/


class ReactionResultComplete extends Component {

    constructor(props) {
        super(props);

        this.state = { maxImageHeight: 0 };
    }

    render() {

        const { data, repositoryInfo } = this.props;

        const reaction = data;
        //console.log('***reaction: ', reaction);
        //console.log('this.state.maxImageHeight: ', this.state.maxImageHeight);

        const relID = reaction.relId ? reaction.relId : reaction.ocid;
        const reactionOcid = reaction.ocid ? reaction.ocid : '';

        // move???
        //const sourceDocuments = [];
        const reactionSourceData = {};
        let cnt = 0;
        reaction.documents?.forEach(docID => {
            //if (doc) {
            const reactData = {};
            if (reaction.relIds && reaction.relIds[cnt]) {
                reactData.relID = reaction.relIds[cnt];
            }
            if (reaction.contexts && reaction.contexts[cnt]) {
                reactData.context = reaction.contexts[cnt];
            }
            if (reaction.sourcesections && reaction.sourcesections[cnt]) {
                reactData.sourcesection = reaction.sourcesections[cnt];
            }
            if (reaction.yields && reaction.yields[cnt]) {
                reactData.yield = reaction.yields[cnt];
            }
            reactionSourceData[docID] = reactData;
            //sourceDocuments.push(doc);
            //}
            cnt++;
        });

        return (
            <>
                <div className="product-grid-item card" style={{ overflow: 'auto', height: 'auto' }}>
                    <div key={relID} className="width100perc">
                        <div className="grid" style={{ padding: 0 }}>
                            <div className={`col-12`}
                                style={{ paddingRight: 20 }}>
                                <b>{`Reaction ${reactionOcid}`}</b>
                                <span>
                                    <SeparatorPoint />
                                    <ReactionExtraInfos
                                        reaction={reaction}
                                        showCompoundInfo={this.props.showCompoundInfo}
                                        mculeLinks={this.state.mculeLinks}
                                    />
                                </span>
                            </div>
                            <div className="col-12" style={{ /*maxWidth: 'calc(100vw - 490px)', overflow: 'auto'*/ }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ReactionCompoundGroup
                                        compounds={reaction.reactants}
                                        compoundImages={this.props.compoundImages}
                                        onReplaceStructure={this.props.onReplaceStructure}
                                        showCompoundInfo={this.props.showCompoundInfo}
                                    />
                                    <div style={{ textAlign: 'center', padding: 0, paddingLeft: 5, paddingRight: 5 }}>
                                        <div className='reactionCompoundOperator arrow' style={{ height: this.state.maxImageHeight }}>
                                            <div style={{ display: 'block' }}>
                                                <div style={{ fontSize: 13 }}>
                                                    {reaction.reagents && !isArrayEmpty(Object.keys(reaction.reagents)) ?
                                                        <a className='secondaryLink' style={{ /*position: 'relative', right: 5*/ }}
                                                            title='Click to load reagents'
                                                            onClick={e => {
                                                                this.setState({ reagents: reaction.reagents, displayReagents: true });
                                                            }}>Reagents</a> : null
                                                    }
                                                </div>
                                                <div>
                                                    &#x27F6;
                                                </div>
                                            </div>
                                            {/*&#8594;*/}
                                        </div>
                                    </div>
                                    <ReactionCompoundGroup
                                        compounds={reaction.products}
                                        compoundImages={this.props.compoundImages}
                                        onReplaceStructure={this.props.onReplaceStructure}
                                        showCompoundInfo={this.props.showCompoundInfo}
                                    />
                                    {/*reaction.reagents && !isArrayEmpty(Object.keys(reaction.reagents)) ? <div className="col-12" style={{ color: 'gray', paddingBottom: 0 }}>Reaction</div> : null*/}
                                </div>
                            </div>

                            <div className="breakRow" />
                            {!isArrayEmpty(reaction.documents) ?
                                <div className="col-12 breakWord" style={{ wordBreak: 'break-word', padding: 0 }}>
                                    <ReactionSources
                                        relID={relID}
                                        repositoryInfo={repositoryInfo}
                                        documentIDs={reaction.documents}
                                        reactionSourceData={reactionSourceData} />
                                </div>
                                : null}
                        </div>
                    </div>
                </div>

                <Dialog header="Reagents"
                    visible={this.state.displayReagents}
                    style={{ minWidth: 320 }}
                    onHide={() => this.setState({ displayReagents: false })} className='styledDialog'
                    dismissableMask>
                    {this.state.reagents && !isArrayEmpty(Object.keys(this.state.reagents)) ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 100 }}>
                            <ReactionCompoundGroup
                                compounds={this.state.reagents}
                                compoundImages={this.props.compoundImages}
                                onReplaceStructure={this.props.onReplaceStructure}
                                showCompoundInfo={this.props.showCompoundInfo}
                            />
                        </div> : null
                    }
                </Dialog>
            </>
        )
    }
}
export default ReactionResultComplete