import { CLINICAL_TRIAL_REPOS, GRANT_DS_REPOS, PATENT_REPOS, PUBLICATIONS_REPOS } from "../../../../properties";
import {
    BULK_IMPORT_TYPE_PMCID, BULK_IMPORT_TYPE_INT_DOC_ID, BULK_IMPORT_TYPE_EXT_DOC_ID, BULK_IMPORT_TYPE_CLINTRIALID,
    BULK_IMPORT_TYPE_GRANT_NO, BULK_IMPORT_TYPE_PATENT_NO, BULK_IMPORT_TYPE_PMID, BULK_IMPORT_TYPE_DOI
} from "../../general/docsearch/searchConstants";

export const MAX_CHUNK_SIZE = 100;

export const MATCH_TYPE_EXACT = 'exact';
export const MATCH_TYPE_SIMILAR = 'similar';


export const DOC_ID_SEARCH_MAP = {

    [BULK_IMPORT_TYPE_PATENT_NO]: {
        // @todo: use PATENT_REPOS, which icludes ifipatents
        repos: Object.keys(PATENT_REPOS),
        queryPrefix: BULK_IMPORT_TYPE_PATENT_NO,
        // addToQuery: 'sortBy:"patPrioDate:asc"',
        //addToQuery: 'sortBy:"pubdate:desc"', // sortBy:"patPrioDate"
        sortingOptions: [
            { value: 'revTime:desc', label: 'Latest revision date' },
            { value: 'revTime:asc', label: 'Oldest revision date' },
            { value: 'pubdate:desc', label: 'Latest publication date' },
            { value: 'pubdate:asc', label: 'Oldest publication date' },
            { value: 'patPrioDate:desc', label: 'Latest priority claim date' },
            { value: 'patPrioDate:asc', label: 'Oldest priority claim date' },
            { value: 'patPrioDate:desc,pubdate:desc', label: 'Latest date (priority claim and publication date)' },
            { value: 'patPrioDate:asc,pubdate:asc', label: 'Oldest date (priority claim and publication date)' },
        ],
        metadataDocID: 'Pat_document-id',
        allowSimilarSearch: true,
        inclSimilarInfoText: 'Removes kind code.'
    },

    // @todo: update properties
    [BULK_IMPORT_TYPE_DOI]: {
        repos: Object.keys(PUBLICATIONS_REPOS),
        queryPrefix: BULK_IMPORT_TYPE_DOI,
        sortingOptions: [
            { value: 'pubdate:desc', label: 'Latest publication date' },
            { value: 'pubdate:asc', label: 'Oldest publication date' },
        ],
        metadataDocID: 'doi'
    },
    [BULK_IMPORT_TYPE_PMID]: {
        repos: Object.keys(PUBLICATIONS_REPOS),
        queryPrefix: BULK_IMPORT_TYPE_PMID,
        sortingOptions: [
            { value: 'pubdate:desc', label: 'Latest publication date' },
            { value: 'pubdate:asc', label: 'Oldest publication date' },
        ],
        metadataDocID: 'pmid'
    },
    [BULK_IMPORT_TYPE_PMCID]: {
        repos: Object.keys(PUBLICATIONS_REPOS),
        queryPrefix: BULK_IMPORT_TYPE_PMCID,
        sortingOptions: [
            { value: 'pubdate:desc', label: 'Latest publication date' },
            { value: 'pubdate:asc', label: 'Oldest publication date' },
        ],
        metadataDocID: 'pmc'
    },
    [BULK_IMPORT_TYPE_GRANT_NO]: {
        repos: Object.keys(GRANT_DS_REPOS),
        queryPrefix: BULK_IMPORT_TYPE_GRANT_NO,
        sortingOptions: [
            { value: 'pubdate:desc', label: 'Latest publication date' },
            { value: 'pubdate:asc', label: 'Oldest publication date' },
        ],
        // metadataDocID: 'xxx'
    },
    [BULK_IMPORT_TYPE_INT_DOC_ID]: {
        repos: Object.keys(PUBLICATIONS_REPOS),
        queryPrefix: BULK_IMPORT_TYPE_INT_DOC_ID,
        sortingOptions: [
            { value: 'pubdate:desc', label: 'Latest publication date' },
            { value: 'pubdate:asc', label: 'Oldest publication date' },
        ],
        metadataDocID: 'ocDocId'
    },
    [BULK_IMPORT_TYPE_CLINTRIALID]: {
        repos: Object.keys(CLINICAL_TRIAL_REPOS),
        queryPrefix: BULK_IMPORT_TYPE_CLINTRIALID,
        sortingOptions: [
            { value: 'pubdate:desc', label: 'Latest publication date' },
            { value: 'pubdate:asc', label: 'Oldest publication date' },
        ],
        metadataDocID: 'trialId'
    },
    [BULK_IMPORT_TYPE_EXT_DOC_ID]: {
        repos: null,
        queryPrefix: BULK_IMPORT_TYPE_EXT_DOC_ID,sortingOptions: [
            { value: 'pubdate:desc', label: 'Latest publication date' },
            { value: 'pubdate:asc', label: 'Oldest publication date' },
        ],
        metadataDocID: 'extId'
    }
}