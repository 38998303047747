/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { sortDomainsToConceptsMapByDomainLabels } from "../util";

class OntologyClassList extends Component {

  constructor(props) {
    super(props);
    this.domainsChecked = {}
  }


  onCheckboxSelectionChange = (match, domain) => {
    match.domain = domain;
    this.props.onConceptSelect(match);
  }


  onRadioSelectionChange = (match, domain) => {
    match.domain = domain;
    this.props.onConceptSelect(match);
  }

  onCheckDomain = (e, dom, allowSelectAll) => {
    //console.log(e)
    //console.log(dom)
    if (e.checked) {
      this.domainsChecked[dom] = true
      const selConcepts = [];
      Object.keys(this.props.conceptsPerDomainMap).forEach((domain, index) => {
          let concepts = this.props.conceptsPerDomainMap[dom];
          concepts.forEach(concept => {
            concept.domain = dom;
            selConcepts.push(concept);
          });
        });
      this.props.onConceptsSelectAll(selConcepts, allowSelectAll);
    } else {
      this.domainsChecked[dom] = false
      let selectedConcepts = this.props.selectedConcepts
      for (let concept in selectedConcepts) {
        if (selectedConcepts.hasOwnProperty(concept) && selectedConcepts[concept].domain === dom) {
          delete selectedConcepts[concept]
        }
      }
    }
  }

  toRGB = (col, valuesOnly = false) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(col);
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    var cssString = '';
    r = r / 255
    g = g / 255
    b = b / 255
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: // do nothing 
      }
      h /= 6;
    }

    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100 - 20);

    cssString = h + ',' + s + '%,' + l + '%';
    cssString = !valuesOnly ? 'hsl(' + cssString + ')' : cssString;

    return cssString;
  }

  /**
   * 
   */
  render() {
    const { conceptsPerDomainMap, highlightOcid, onConceptClick, selectionMode,
      selectedConcepts, domainLabelsMap, loading, loadingMessage, emptyMessage } = this.props;

    let allowSelectAll = false;
    let domainCount = 0;
    let matchCount = 0;
    const selConcepts = selectedConcepts ? selectedConcepts : {};

    const conceptsPerDomainMapSorted = conceptsPerDomainMap ? sortDomainsToConceptsMapByDomainLabels(conceptsPerDomainMap, domainLabelsMap) : {};

    // --- check if all OCIDs of the current search results are already selected ----- //
    // --- if not (at least one concept is unselected) allow the select all option --- //
    if (conceptsPerDomainMapSorted) {
      domainCount = Object.keys(conceptsPerDomainMapSorted).length;
      Object.keys(conceptsPerDomainMapSorted).forEach((domain, index) => {
        let concepts = conceptsPerDomainMapSorted[domain];
        concepts.forEach(concept => {
          if (!selConcepts[concept.ocid]) {
            allowSelectAll = true;
          }
          matchCount++;
        });
      });
    }

    let domainColors = {}

    this.props.domainColors && this.props.domainColors.forEach(col => {
      domainColors[col.name] = col.color
    })


    conceptsPerDomainMapSorted && Object.keys(conceptsPerDomainMapSorted).forEach(key => {
      if (this.domainsChecked !== undefined && this.domainsChecked[key] === undefined) {
        this.domainsChecked[key] = false
      }
    })

    return (
      conceptsPerDomainMapSorted && Object.keys(conceptsPerDomainMapSorted).length > 0 ?
        <>
          {this.props.children ?
            <div className="textAlignRight" style={{ height: 0 }}>
              <div className="domExplorerSelectedConceptsButtons">
                {this.props.children}
              </div>
            </div> : null}
          <div //style={{ overflow: 'auto', height: '100%', marginTop: 5 }}
          >
            <div className="grid" style={{ marginLeft: 0, marginRight: 0, margin: 0 }}>
              {selectionMode === 'multiple' && matchCount > 1 && this.props.onConceptsSelectAll ?
                <>
                  <div className="col-fixed" style={{ width: '35px', paddingBottom: 3 }}
                    title={allowSelectAll ? 'Select all' : 'Deselect all'}>
                    <Checkbox
                      inputId={'selAll'}
                      value={'selAll'}
                      onChange={(e) => {
                        // --- get all current concepts --- //
                        const selConcepts = [];
                        Object.keys(conceptsPerDomainMapSorted).forEach((domain, index) => {
                          let concepts = conceptsPerDomainMapSorted[domain];
                          concepts.forEach(concept => {
                            concept.domain = domain;
                            selConcepts.push(concept);
                          });
                        });
                        // --- select or deselect all current concepts --- //
                        this.props.onConceptsSelectAll(selConcepts, allowSelectAll);
                      }}
                      checked={!allowSelectAll}
                    />
                  </div>
                  <div className="col" style={{ paddingLeft: 0, marginTop: 2 }}>
                    <span className="secondaryInfo">
                      {matchCount} concept{matchCount !== 1 ? 's' : ''} in {domainCount} domain{domainCount !== 1 ? 's' : ''}</span>
                  </div>
                  <div className="col-12" style={{ paddingBottom: 0 }}>
                    <hr style={{ margin: 0 }} />
                  </div>
                </>
                : null
              }
              {
                Object.keys(conceptsPerDomainMapSorted).map((domain, index) => {
                  let concepts = conceptsPerDomainMapSorted[domain];
                  const checkedDomainConcepts = concepts.filter(concept => this.props.selectedConcepts && !!this.props.selectedConcepts[concept.ocid]);
                  /*
                  let checkedDomainConcepts = Object.keys(this.props.selectedConcepts).filter(key => {
                    console.log('key', key);
                    return this.props.selectedConcepts[key].domain === domain
                  })
                  */
                  return (
                    <React.Fragment key={domain}>
                      {domainColors && domainColors[domain] ?
                        <div className="col-fixed" style={{ width: '35px', paddingTop: 2, paddingBottom: 3 }}>
                          <span>
                            {selectionMode === 'multiple' && this.props.onConceptsSelectAll ?
                              <Checkbox className={`coloredCheckbox`} style={{
                                marginTop: 13, marginLeft: 0,
                                "--col-dom": domainColors && domainColors[domain] ? '#' + domainColors[domain] : '#04304a',
                                "--col-dom-border": domainColors && domainColors[domain] ? this.toRGB('#' + domainColors[domain]) : '#04304a'
                              }}
                                onChange={(e) => { this.onCheckDomain(e, domain, allowSelectAll) }}
                                disabled={selectionMode === 'multiple' && this.props.onConceptsSelectAll ? false : true}
                                checked={(!allowSelectAll || conceptsPerDomainMapSorted[domain].length === checkedDomainConcepts.length)
                                  && Object.keys(this.props.selectedConcepts).length > 0 ? true : false}></Checkbox>
                              :
                              <div style={{
                                width: 16, height: 16, border: '1px solid', borderColor: domainColors && domainColors[domain] ? '#' + domainColors[domain] : 'black',
                                background: domainColors && domainColors[domain] ? '#' + domainColors[domain] : 'black',
                                marginTop: 15, marginLeft: 2
                              }}></div>
                            }
                          </span>
                        </div>
                        : null
                      }
                      <div className="col" style={{ marginBottom: 0, marginTop: 10, paddingLeft: 0 }}>
                        <span style={{ fontWeight: 'bold', color: 'black' }} className="secondaryInfo">{this.props.domainLabelsMap && this.props.domainLabelsMap[domain] ? this.props.domainLabelsMap[domain] : domain}</span>
                      </div>
                      <div className="breakRow" />
                      {
                        concepts.map(match => {
                          const aStyleClass = highlightOcid && highlightOcid === match.ocid ? "checkboxLabel match" : "checkboxLabel";
                          return <React.Fragment key={match.ocid}>
                            <div className="col-fixed" style={{
                              width: '28px', paddingTop: 2, paddingBottom: 3, marginLeft: 17
                            }}>
                              {selectionMode === 'multiple' ?
                                <Checkbox
                                  className="checkBoxOverflowFix"
                                  key={match.ocid}
                                  inputId={match.ocid + ''}
                                  value={match.ocid}
                                  onChange={() => this.onCheckboxSelectionChange(match, domain)}
                                  checked={selectedConcepts && (match.ocid in selectedConcepts)}
                                />
                                :
                                selectionMode === 'single' ?
                                  <RadioButton
                                    key={match.ocid}
                                    value={match.ocid}
                                    name={match.ocid + ''}
                                    onChange={() => this.onRadioSelectionChange(match, domain)}
                                    checked={selectedConcepts && (match.ocid in selectedConcepts)} />
                                  :
                                  <></>
                              }
                            </div>
                            <div className={`col breakWord overflowWrapAnywhere ${aStyleClass}`} style={{ paddingTop: 3, paddingBottom: 3 }}>
                              <a
                                className={`secondaryLink`}
                                onClick={() => onConceptClick(match.ocid, true)}
                                title={`Load details for ${match.ocid}`}>
                                {match.preferredName}
                              </a>
                            </div>
                            <div className="breakRow" />
                          </React.Fragment>;
                        })
                      }
                    </React.Fragment>);
                })
              }
            </div>
          </div>
        </>
        :
        <div>
          {loading ? loadingMessage : emptyMessage}
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap
})

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OntologyClassList))
