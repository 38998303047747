import axios from "axios";
import { getHeaderToken } from "../../../../../api";
import { APP_PROPERTIES } from '../../../../../properties/index';

export class ExportService {
    getExports(params) {
        return axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/export-center/v1/export-requests`, { headers: getHeaderToken() });
    }

    deleteExports(exports) {
        return axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/export-center/v1/export-requests`, { headers: getHeaderToken(), data: exports });
    }

    updateExportStatus(exportId) {
        return axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/export-center/v1/export-requests/download-state?exportRequestId=${exportId}`, null, { headers: getHeaderToken() });
    }
}

