import {headerTrees} from "../../webapi/biomarker/leftAsideInitialHeaderConfig";

export const createHeadersSkeleton = (nodes, facettes) => {
    let headersSkeleton = [...nodes];

    for (let facette in facettes) {
        const index = nodes.findIndex(item => item.name === facette);

        if (facettes[facette]) {
            // find target index in skeleton headers array
            const childrenNode = {
                label: 'list',
                name: facette,
            }
            // if there are facettes add children in the node (trick to prevent empty nodes
            // with redundant arrows and margins)
            switch (facette) {
                // these headers have special behavior, specials trees are included
                case 'type':
                case 'population':
                    // url is added to children node for fetching data if no tree
                    // type is added to children to detect parent tree
                    headersSkeleton[index] = {
                        ...headersSkeleton[index],
                        children: [headerTrees[facette], {
                            ...childrenNode,
                            url: headerTrees[facette].url,
                            tree: headerTrees[facette].tree
                        }
                        ]
                    }
                    break;

                default:
                    headersSkeleton[index] = {
                        ...headersSkeleton[index],
                        children: [childrenNode]
                    }
            }
        } else {
            // if no facettes children should be cleared - it need to remove dropdown arrow (it is used for expand tree node)
            delete headersSkeleton[index];
        }
    }

    return headersSkeleton.filter(item => item)
}
