export const addAnyToQueryTerms = (fieldName, queryTerms) => {
    const commonQueries = queryTerms.filter(({domainTerms}) => !domainTerms);
    let anyQueries = queryTerms.filter(({domainTerms}) => domainTerms);
    const isAdded = !!anyQueries.filter(item => item.fieldName === fieldName).length;

    // add or remove 'any' term
    if (isAdded) {
        anyQueries = anyQueries.filter(item => item.fieldName !== fieldName);
    } else {
        anyQueries = [...anyQueries, {
            conceptLookupType: "ONTOLOGICAL",
            fieldName,
            domainTerms: ["*"],
            term: ['Any'],
            occurrence: 'MUST',
        }]
    }

    return [...commonQueries, ...anyQueries]
}
