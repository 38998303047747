import * as OntologyClassUtil from './OntologyClassUtil';
import * as IDGeneratorUtil from './IDGeneratorUtil';
import * as OntologyUtil from './OntologyUtil';
import { removeValueFromArray } from '../../util';
import { translateSyntaxPatternStringToPhraseTokens, translatePhraseTokensToSyntaxPatternString } from '../../../../api/content/FrameEditorApi';

// -- phrase token data, e.g. type, ocid, negative ocid, text --- //
export const PHRASE_TOKEN_DATA_ATTRIBUTE = 'attr';
export const PHRASE_TOKEN_DATA_NEGATIVE_OCID = 'negOcid';
export const PHRASE_TOKEN_DATA_OCID = 'ocid';
export const PHRASE_TOKEN_DATA_TEXT = "text";
export const PHRASE_TOKEN_DATA_TYPE = 'types';

export const PHRASE_TOKEN_TYPE_ATTRIBUTE_TYPE = 'type';
export const PHRASE_TOKEN_TYPE_ATTRIBUTE_SUBTYPE = 'subType';
export const PHRASE_TOKEN_TYPE_ATTRIBUTE_SUBSUBTYPE = 'subSubStype';

// --- phrase token types, e.g. named entity, macro, synset etc. --- //
export const PHRASE_TOKEN_TYPE_ANY = 'ANY';
export const PHRASE_TOKEN_TYPE_ADJECTIVE = 'ADJECTIVE';
export const PHRASE_TOKEN_TYPE_ADVERB = 'ADVERB';
export const PHRASE_TOKEN_TYPE_DETERMINER = 'DETERMINER';
export const PHRASE_TOKEN_TYPE_FRAME = 'FRAME';
export const PHRASE_TOKEN_TYPE_GROUP = 'GROUP';
export const PHRASE_TOKEN_TYPE_MACRO = 'MACRO';
export const PHRASE_TOKEN_TYPE_NE = 'NE';
export const PHRASE_TOKEN_TYPE_NUMBER = 'NUMBER';
export const PHRASE_TOKEN_TYPE_PUNCTUATION = 'PUNCTUATION';
export const PHRASE_TOKEN_TYPE_SYNSET = 'SYNSET';
export const PHRASE_TOKEN_TYPE_TEXT = 'TEXT';
export const PHRASE_TOKEN_TYPE_PRONOUN = 'PRONOUN';
export const PHRASE_TOKEN_TYPE_REFERENCE = 'REFERENCE';
export const PHRASE_TOKEN_TYPE_ROLE_REFERENCE = 'ROLE_REFERENCE';
export const PHRASE_TOKEN_TYPE_NEGATION = 'NEGATION';
export const PHRASE_TOKEN_TYPE_BRACKET = 'BRACKET';
export const PHRASE_TOKEN_TYPE_ROLE = 'ROLE';

// --- phrase token attributes, e.g. role, text, target, optional, etc. --- //
// --- type: all --- //
export const PHRASE_TOKEN_ATTRIBUTE_MIN = 'min';
export const PHRASE_TOKEN_ATTRIBUTE_MAX = 'max';
export const PHRASE_TOKEN_ATTRIBUTE_OPT = '?';
export const PHRASE_TOKEN_ATTRIBUTE_OPT_POSSESSIVE = '?+';
export const PHRASE_TOKEN_ATTRIBUTE_NO_MATCH = '!';
export const PHRASE_TOKEN_ATTRIBUTE_FIRST = '^';
export const PHRASE_TOKEN_ATTRIBUTE_LAST = '$';
export const PHRASE_TOKEN_ATTRIBUTE_INFLECTION = 'infl';
export const PHRASE_TOKEN_ATTRIBUTE_ALTERNATIVE = '/';
export const PHRASE_TOKEN_ATTRIBUTE_VARIABLE = '~';
// --- type: syntax --- //
export const PHRASE_TOKEN_ATTRIBUTE_ROLE = 'role';
export const PHRASE_TOKEN_ATTRIBUTE_TARGET = 'tg';
export const PHRASE_TOKEN_ATTRIBUTE_ID = 'id';
// --- type: role --- //
export const PHRASE_TOKEN_ATTRIBUTE_REQUIRED = 'req';
export const PHRASE_TOKEN_ATTRIBUTE_UNIQUE = 'uniq';
export const PHRASE_TOKEN_ATTRIBUTE_DEFAULT = 'default';
export const PHRASE_TOKEN_ATTRIBUTE_SUBJECT = 'subject';
export const PHRASE_TOKEN_ATTRIBUTE_OBJECT = 'object';


// ----------------------------------------------------------------------- //
// --- select items lists ------------------------------------------------ //
// ----------------------------------------------------------------------- //
// --- punctuation types --- //
export const PUNCTUATION_TYPES = [
    //{ label: 'ANY', text: '*' },
    { label: '. (Full stop)', value: '.' },
    { label: ', (Comma)', value: ',' },
    { label: '; (Semi-colon)', value: ';' },
    { label: ': (Colon)', value: ':' },
    { label: '! (Exclamation mark)', value: '!' },
    { label: '? (Question mark)', value: '?' },
    { label: '° (Degree)', value: '°' },
    //    { label: ''', value: '' (single quotation mark)' },
    //    { label: '"", value: '"" (double quotation mark)' },
    //    { label: '-, value: '- (dash)' },
    { label: '( (Round bracket, opening)', value: '(' },
    { label: ') (Round bracket, closing)', value: ')' },
    { label: '[ (Square bracket, opening)', value: '[' },
    { label: '] (Square bracket, closing)', value: ']' },
    { label: '{ (Curly bracket, opening)', value: '{' },
    { label: '} (Curly bracket, closing)', value: '}' },
    { label: '< (Angle bracket, opening)', value: '<' },
    { label: '> (Angle bracket, closing)', value: '>' },
];
// --- bracket types --- //
export const BRACKET_TYPES = [
    { label: 'ANY', value: '*' },
    { label: '(', value: '(' },
    { label: ')', value: ')' },
    { label: '{', value: '{' },
    { label: '}', value: '}' },
    { label: '<', value: '<' },
    { label: '>', value: '>' },
];
// --- inflection types --- //
export const INFLECTION_TYPES = [
    { label: 'All', value: 'ALL', info: 'All forms' },
    { label: '3rd person singular active present tense', value: 'P3', info: 'e.g. "writes"' },
    { label: 'Active', value: 'ACTIVE', info: 'e.g. "write", "writes", "wrote", "writing"' },
    { label: 'Past participle', value: 'PP', info: 'e.g. "written"' },
    { label: 'Past tense', value: 'PAST', info: 'e.g. "wrote"' },
    { label: 'Present participle (gerund)', value: 'GERUND', info: 'e.g. "writing"' },
];
// --- determiner types --- //
export const DETERMINER_TYPES = [
    { label: 'Demonstratives', value: 'DEMONSTRATIVES', info: 'this, This, that, That,  these, These, those, Those, which, Which' },
    { label: 'Alternative', value: 'ALTERNATIVE', info: 'another, other, else, different' },
    { label: 'Definite article', value: 'DEF_ARTICLE', info: 'the, The' },
    { label: 'Indefinite article', value: 'INDEF_ARTICLE', info: 'a, an, A, An' },
    { label: 'Cardinalum', value: 'CARDINALNUM', info: 'zero, one, two, three, four, five, six, seven, eight, nine, ten, infinite, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty, thirty, forty, fifty, sixty, seventy, eighty, ninety, hundred, I, II, III, IV, V, VI, VII, VIII, IX, X' },
    { label: 'Quantifiers', value: 'QUANTIFIERS', info: 'many, much, few, little, couple, several, various, most, a lot of, every, each, some, any, numerous' },
    { label: 'Disjuntive', value: 'DISJUNCTIVE', info: 'either' },
    { label: 'Equative', value: 'EQUATIVE', info: 'same' },
    { label: 'Evaluative', value: 'EVALUATIVE', info: 'such, that, so' },
    { label: 'Relative', value: 'RELATIVE', info: 'what, whichever, whatever' },
    { label: 'Negative', value: 'NEGATIVE', info: 'no, neither, nor, No' },
    { label: 'Possessive', value: 'POSSESSIVE', info: 'my, My, your, Your, her, Her, his, His, our, Our, their, Their, its, Its' },
    { label: 'Sufficiency', value: 'SUFFICIENCY', info: 'enough, sufficient, plenty' },
    { label: 'Universal', value: 'UNIVERSAL', info: 'all, both' },
];
// --- adjective types --- //
// TODO: add specifics, remove info?
export const ADJECTIVE_TYPES = {
    'STRONG': { label: 'Strong', value: 'STRONG',  info: 'best, better, clear, dramatical, good, high, important, natural, potent, serious, sharp, significant, straight, strong, major, large, larger, higher, great, greater, big, bigger, maximum, exhaustive, tall, excessive, plenty, heavy, intense, powerful, severe, selective, greatest, highest, clearest, largest, biggest, taller, tallest, main, extreme, uttermost, exceeding, outermost, outmost, superlative, veriest, able' },
    'WEAK': { label: 'Weak', value: 'WEAK', specifics: ['moderate'], info: 'slight, smooth, weak, minor, small, modest, low, lower, slighter, smoother, weaker, smaller, faint, fragile, light, pale, poor, docile, infirm, frail, insufficient, marginal, scant, inadequate, deficient, bare,  simple, moderate' },
    'NEAR': { label: 'Near', value: 'NEAR', info: 'near, close, nearby, proximal' },
    'FAR': { label: 'Far', value: 'FAR', info: 'far, further, furthest, farther, wide, vast, broad' },
    'COMMON': { label: 'Common', value: 'COMMON', info: 'common, general, usual' },
    'PART': { label: 'Part', value: 'PART', info: 'partial, whole, complete, full, entire, total, rare' },
    'TIME': { label: 'Time', value: 'TIME', info: 'late, quick, sudden, temporal, previous, young, old, older, younger, elder, frequent, short, new, newer, newest, oldest, recent, next, mature' },
    'REGION': { label: 'Region', value: 'REGION', info: 'Thai, Chinese, marine' },
    'COLOUR': { label: 'Colour', value: 'COLOUR', info: 'red, green, white, blue, yellow, brown, black, orange, purple, coloured, pink, colourless, light green, light red, light blue, light yellow, light brown, dark green, dark blue, dark yellow, dark red, dark brown, cyan, violet' },
    'PLANT_CONDITION': { label: 'Plant condition', value: 'PLANT_CONDITION', info: 'fresh, raw, dried, lyophilised' },
    'PLANTS': { label: 'Plants', value: 'PLANTS', info: 'herbal' },
    'PLANT_DEVELOPMENT': { label: 'Plant development', value: 'PLANT_DEVELOPMENT', info: 'ripening, developing, maturing, maturating, growing' },
    'COMPARE': { label: 'Compare', value: 'COMPARE', specifics: ['like', 'such as', 'similar'], info: 'like, such as, similar' },
    'PROB': { label: 'Probability', value: 'PROB', info: 'probable, eventual, possible, potential, tentative' },
};
/*
export const ADJECTIVE_TYPES_OLD = [
    { label: 'Strong', value: 'STRONG', info: 'best, better, clear, dramatical, good, high, important, natural, potent, serious, sharp, significant, straight, strong, major, large, larger, higher, great, greater, big, bigger, maximum, exhaustive, tall, excessive, plenty, heavy, intense, powerful, severe, selective, greatest, highest, clearest, largest, biggest, taller, tallest, main, extreme, uttermost, exceeding, outermost, outmost, superlative, veriest, able' },
    { label: 'Weak', value: 'WEAK', info: 'slight, smooth, weak, minor, small, modest, low, lower, slighter, smoother, weaker, smaller, faint, fragile, light, pale, poor, docile, infirm, frail, insufficient, marginal, scant, inadequate, deficient, bare,  simple, moderate' },
    { label: 'Near', value: 'NEAR', info: 'near, close, nearby, proximal' },
    { label: 'Far', value: 'FAR', info: 'far, further, furthest, farther, wide, vast, broad' },
    { label: 'Common', value: 'COMMON', info: 'common, general, usual' },
    { label: 'Part', value: 'PART', info: 'partial, whole, complete, full, entire, total, rare' },
    { label: 'Time', value: 'TIME', info: 'late, quick, sudden, temporal, previous, young, old, older, younger, elder, frequent, short, new, newer, newest, oldest, recent, next, mature' },
    { label: 'Region', value: 'REGION', info: 'Thai, Chinese, marine' },
    { label: 'Colour', value: 'COLOUR', info: 'red, green, white, blue, yellow, brown, black, orange, purple, coloured, pink, colourless, light green, light red, light blue, light yellow, light brown, dark green, dark blue, dark yellow, dark red, dark brown, cyan, violet' },
    { label: 'Plant condition', value: 'PLANT_CONDITION', info: 'fresh, raw, dried, lyophilised' },
    { label: 'Plants', value: 'PLANTS', info: 'herbal' },
    { label: 'Plant development', value: 'PLANT_DEVELOPMENT', info: 'ripening, developing, maturing, maturating, growing' },
    { label: 'Compare', value: 'COMPARE', specifics: ['like', 'such as', 'similar'], info: 'like, such as, similar' },
    { label: 'Probability', value: 'PROB', info: 'probable, eventual, possible, potential, tentative' },
];
*/
// --- adverb types --- //
export const ADVERB_TYPES = [
    { label: 'Strong', value: 'STRONG', info: 'best, better, clearly, dramatically, highly, importantly, more, naturally, potently, seriously, sharply, significantly, straightly, strongly, well, mainly, largely, greatly, exhaustively, selectively, extremely, acutely, exceedingly, supremely, utterly, direfully, particularly, mostly, especially, essentially' },
    { label: 'Weak', value: 'WEAK', info: 'least, less, slightly, smoothly, weakly, faintly, lightly, poorly, hardly, insufficiently, rarely, barely, scarcely, marginally, inadequately, deficiently, simply, moderately' },
    { label: 'Near', value: 'NEAR', info: 'almost, nearly, about, closely, approximately' },
    { label: 'Far', value: 'FAR', info: 'widely, afar, broadly, above' },
    { label: 'Common', value: 'COMMON', info: 'commonly, generally, usually, principally, basically, primarily, typically, normally, customarily' },
    { label: 'Part', value: 'PART', info: 'partially, completely, fully, entirely, totally, partly, half, wholly' },
    { label: 'Time', value: 'TIME', info: 'lately, later, latest, quickly, suddenly, temporally, first, second, third, previously, subsequently, permanently, recently, rapidly, slowly, frequently, briefly, finally, recently, after' },
    { label: 'Compare', value: 'COMPARE', info: 'also, besides, too, likewise, as well, as well as, as, similarly' },
    { label: 'Negotiation', value: 'NEGOTIATION', info: 'never, not' },
    { label: 'Yet', value: 'YET', info: 'though, however, nevertheless, still, yet, notwithstanding, anyhow' },
    { label: 'Probability', value: 'PROB', info: 'probably, eventually, possibly, potentially, tentative' },
];
// --- pronoun types --- //
export const PRONOUN_TYPES = [
    { label: 'Personal', value: 'PERSONAL', info: 'it, It, they, They' },
];

// ----------------------------------------------------------------------- //
// --- phrase tokens ----------------------------------------------------- //
// ----------------------------------------------------------------------- //
export const createDefaultPhraseToken = () => {
    const token = {
        key: IDGeneratorUtil.generateNewTokenID(),
        types: [{
            key: IDGeneratorUtil.generateNewTypeID(),
            type: PHRASE_TOKEN_TYPE_ANY
        }]
    }
    return token;
}

export const addDefaultTypeToToken = (token) => {
    if (token) {
        if (!token[PHRASE_TOKEN_DATA_TYPE]) {
            token[PHRASE_TOKEN_DATA_TYPE] = [];
        }
        token[PHRASE_TOKEN_DATA_TYPE].push({
            key: IDGeneratorUtil.generateNewTypeID(),
            type: PHRASE_TOKEN_TYPE_ANY
        });
    }
    return token;
}

export const removeTypeFromToken = (token, type) => {
    if (token) {
        if (token[PHRASE_TOKEN_DATA_TYPE]) {
            removeValueFromArray(token[PHRASE_TOKEN_DATA_TYPE], type);
        }
    }
    return token;
}

// ------------ //
// --- text --- //
// ------------ //
export const hasTokenText = (token) => {
    return token && token.text;
}

export const getTokenText = (token) => {
    if (token && token.text) {
        return token.text;
    }
    return '';
}

export const setTextsInToken = (token, texts) => {
    if (token) {
        token.text = texts ? texts : [];
    }
    return token;
}

export const removeTextFromToken = (token) => {
    if (token) {
        if (token.text) {
            delete token.text;
        }
    }
    return token;
}

// ----------------------- //
// --- allowlist OCIDs --- //
// ----------------------- //
export const hasTokenOcids = (token) => {
    return token && token.ocid;
}

export const getTokenOcids = (token) => {
    if (token && token.ocid) {
        return token.ocid;
    }
    return '';
}

export const setOcidsInToken = (token, ocids) => {
    if (token) {
        token.ocid = ocids ? ocids : [];
    }
    return token;
}

export const removeOcidsFromToken = (token) => {
    if (token) {
        if (token.ocid) {
            delete token.ocid;
        }
    }
    return token;
}

// ---------------------- //
// --- blacklis OCIDs --- //
// ---------------------- //
export const hasTokenNegOcids = (token) => {
    return token && token.negOcid;
}

export const getTokenNegOcids = (token) => {
    if (token && token.negOcid) {
        return token.negOcid;
    }
    return '';
}

export const setNegOcidsInToken = (token, ocids) => {
    if (token) {
        token.negOcid = ocids ? ocids : [];
    }
    return token;
}

export const removeNegOcidsFromToken = (token) => {
    if (token) {
        if (token.negOcid) {
            delete token.negOcid;
        }
    }
    return token;
}

export const setAttributeInToken = (token, attrId, attrValue) => {
    if (token) {
        if (!token.attr) {
            token.attr = {};
        }
        token.attr[attrId] = attrValue ? [attrValue] : [];
    }
    return token;
}

export const removeAttributeFromToken = (token, attrId) => {
    if (token) {
        if (token.attr) {
            delete token.attr[attrId];
        }
    }
    return token;
}

export const removeEmptyDataFromToken = (token) => {
    if (token) {
        if (token.text && token.text.length === 0) {
            delete token.text;
        }

        // --- remove empty subtypes and subsubtypes --- //
        const types = token[PHRASE_TOKEN_DATA_TYPE];
        if (types) {
            for (const type of types) {
                if (type.subType && type.subType.length === 0) {
                    delete type.subType;
                }
                if (type.subSubType && type.subSubType.length === 0) {
                    delete type.subSubType;
                }
            }
        }

        // --- remove empty attributes --- //
        const attributes = token[PHRASE_TOKEN_DATA_ATTRIBUTE];
        if (attributes) {
            const roleAttribute = attributes[PHRASE_TOKEN_ATTRIBUTE_ROLE];
            if (roleAttribute && roleAttribute.length === 0) {
                delete attributes[PHRASE_TOKEN_ATTRIBUTE_ROLE];
            }
            const idAttribute = attributes[PHRASE_TOKEN_ATTRIBUTE_ID];
            if (idAttribute && idAttribute.length === 0) {
                delete attributes[PHRASE_TOKEN_ATTRIBUTE_ID];
            }
            const targetAttribute = attributes[PHRASE_TOKEN_ATTRIBUTE_TARGET];
            if (targetAttribute && targetAttribute.length === 0) {
                delete attributes[PHRASE_TOKEN_ATTRIBUTE_TARGET];
            }
            const minAttribute = attributes[PHRASE_TOKEN_ATTRIBUTE_MIN];
            if (minAttribute && minAttribute.length === 0) {
                delete attributes[PHRASE_TOKEN_ATTRIBUTE_MIN];
            }
            const maxAttribute = attributes[PHRASE_TOKEN_ATTRIBUTE_MAX];
            if (maxAttribute && maxAttribute.length === 0) {
                delete attributes[PHRASE_TOKEN_ATTRIBUTE_MAX];
            }

            if (Object.keys(token[PHRASE_TOKEN_DATA_ATTRIBUTE]).length === 0) {
                delete token[PHRASE_TOKEN_DATA_ATTRIBUTE];
            }
        }

        // --- remove empty text list --- //
        const texts = token[PHRASE_TOKEN_DATA_TEXT];
        if (texts && texts.length === 0) {
            delete token[PHRASE_TOKEN_DATA_TEXT];
        }

        // --- remove empty OCID list --- //
        const ocids = token[PHRASE_TOKEN_DATA_OCID];
        if (ocids && ocids.length === 0) {
            delete token[PHRASE_TOKEN_DATA_OCID];
        }

        // --- remove empty negative OCID list --- //
        const negOcids = token[PHRASE_TOKEN_DATA_NEGATIVE_OCID];
        if (negOcids && negOcids.length === 0) {
            delete token[PHRASE_TOKEN_DATA_NEGATIVE_OCID];
        }
    }
    return token;
}

/**
 * Removes attributes that are not allowed for given type.
 * 
 * @param {Object} token 
 * @param {string} type 
 */
export const removeForbiddenAttributesFromToken = (token, type) => {
    if (token) {
        if (type !== PHRASE_TOKEN_TYPE_SYNSET) {
            removeAttributeFromToken(token, PHRASE_TOKEN_ATTRIBUTE_INFLECTION);
        }
        if (type !== PHRASE_TOKEN_TYPE_NE) { //  && type !== PHRASE_TOKEN_TYPE_FRAME
            delete token[PHRASE_TOKEN_DATA_OCID];
            delete token[PHRASE_TOKEN_DATA_NEGATIVE_OCID];
            delete token.prefName;
        }
        if (type !== PHRASE_TOKEN_TYPE_GROUP) {
            removeAttributeFromToken(token, PHRASE_TOKEN_ATTRIBUTE_ALTERNATIVE);
            removeAttributeFromToken(token, PHRASE_TOKEN_ATTRIBUTE_VARIABLE);
        }
        if (type !== PHRASE_TOKEN_TYPE_NE && type !== PHRASE_TOKEN_TYPE_TEXT
            && type !== PHRASE_TOKEN_TYPE_PUNCTUATION) {
            removeTextFromToken(token);
        }
    }
    return removeEmptyDataFromToken(token);
}


/**
 * Removes attributes that are not allowed for given type.
 * 
 * @param {Object} token 
 * @param {string} type 
 */
export const cleanAttributesOfToken = (token) => {

    if (token) {
        if (hasTokenMultipleTypes(token)) {
            delete token[PHRASE_TOKEN_DATA_TEXT];

            delete token[PHRASE_TOKEN_DATA_OCID];
            delete token[PHRASE_TOKEN_DATA_NEGATIVE_OCID];
            delete token.prefName;

            removeAttributeFromToken(token, PHRASE_TOKEN_ATTRIBUTE_INFLECTION);
            removeAttributeFromToken(token, PHRASE_TOKEN_ATTRIBUTE_ALTERNATIVE);
            removeAttributeFromToken(token, PHRASE_TOKEN_ATTRIBUTE_VARIABLE);
        }
        else {
            removeForbiddenAttributesFromToken(token, getMainTokenType(token));
        }
    }
    //return removeEmptyDataFromToken(token);
    return token;
}


export const addTypeToToken = (token, type) => {
    if (!token[PHRASE_TOKEN_DATA_TYPE]) {
        token[PHRASE_TOKEN_DATA_TYPE] = []; 
    } 
    token[PHRASE_TOKEN_DATA_TYPE].push(type);
    return token;
}


export const hasTokenAttribute = (token, attrId) => {
    return token && token.attr && token.attr[attrId];
}

export const hasTokenOrMembersAttribute = (token, attrId) => {

    let hasAttr = false;
    if (token) {
        if (token.groupMembers) {
            for (const groupToken of token.groupMembers) {
                if (hasTokenOrMembersAttribute(groupToken, attrId)) {
                    hasAttr = true;
                }
            }
        }
        else {
            if (hasTokenAttribute(token, attrId)) {
                hasAttr = true;
            }
        }
    }
    return hasAttr;
}

export const hasTokenOrMembersAttributeValue = (token, attrId, value) => {

    let hasAttr = false;
    if (token) {
        if (token.groupMembers) {
            for (const groupToken of token.groupMembers) {
                if (hasTokenOrMembersAttribute(groupToken, attrId, value)) {
                    hasAttr = true;
                }
            }
        }
        else {
            if (hasTokenAttributeValue(token, attrId, value)) {
                hasAttr = true;
            }
        }
    }
    return hasAttr;
}


export const hasTokenAttributeValue = (token, attrId, value) => {

    if (token && token.attr && token.attr[attrId]) {
        const vals = token.attr[attrId];
        if (vals) {
            for (const val of vals) {
                if (val === value) {
                    return true;
                }
            }
        }
    }

    return false;
}


export const getTokenAttribute = (token, attrId) => {
    if (token && token.attr && token.attr[attrId] && token.attr[attrId].length > 0) {
        return token && token.attr && token.attr[attrId][0];
    }
    return '';
}

export const hasTokenMultipleTypes = (token) => {
    return (token && token[PHRASE_TOKEN_DATA_TYPE] && token[PHRASE_TOKEN_DATA_TYPE].length > 1);
}

export const hasTokenType = (token, type) => {
    if (token && type) {
        for (const tp of token[PHRASE_TOKEN_DATA_TYPE]) {
            if (tp.type === type) {
                return true;
            }
        }
    }
    return false;
}

export const hasTokenMultipleTypesOfSameType = (token, type) => {
    if (token && type) {
        let neCnt = 0;
        for (const tp of token[PHRASE_TOKEN_DATA_TYPE]) {
            if (tp.type === type) {
                neCnt++;
                if (neCnt > 1) {
                    return true;
                }
            }
        }
    }
    return false;
}

/*
export const createPhraseToken = (key, type, text, attr) => {
    const token = {
        key: key,
        text: [text]
    }
    token[PHRASE_TOKEN_DATA_TYPE] = [type];
    if (attr) {
        token.attr = attr
    }
    if (type === PHRASE_TOKEN_TYPE_GROUP) {
        token.groupMembers = []
    }
    return token;
}
*/

export const createPhraseTokenGroup = (groupTypeAttr) => {
    const attr = {};

    if (groupTypeAttr) {
        attr[groupTypeAttr] = [];
    }

    const token = {
        key: IDGeneratorUtil.generateNewTokenID(),
        //text: [text],
        attr: attr,
        groupMembers: [],
        types: [{
            type: PHRASE_TOKEN_TYPE_GROUP
        }]
    }
    return token;
}

export const getMainTokenType = (token) => {
    let tokenType = 'undefined';
    if (token) {
        let tokenTypes = token[PHRASE_TOKEN_DATA_TYPE];
        if (tokenTypes && tokenTypes.length > 0 && tokenTypes[0].type) {
            tokenType = tokenTypes[0].type;
        }
    }
    return tokenType;
}


export const getMainTokenTypeObject = (token) => {
    let tokenType = null;
    if (token) {
        let tokenTypes = token[PHRASE_TOKEN_DATA_TYPE];
        if (tokenTypes && tokenTypes.length > 0) {
            tokenType = tokenTypes[0];
        }
    }
    return tokenType;
}

export const isGroupToken = (token) => {
    return getMainTokenType(token) === PHRASE_TOKEN_TYPE_GROUP;
}


export const generateNewTokenIDs = (phraseTokens) => {
    if (phraseTokens) {
        for (const index of Object.keys(phraseTokens)) {
            const token = phraseTokens[index];
            token.key = IDGeneratorUtil.generateNewTokenID();
            if (isGroupToken(token)) {
                generateNewTokenIDs(token.groupMembers);
            }
        }
    }
}

// ----------------------------------------------------------------------- //
// --- check for names in syntaxes and tags ------------------------------ //
// ----------------------------------------------------------------------- //
export const checkForNamesInTags = async (subtreeType, name, frameOntology, findings = {}) => {

    //console.log('subtreeType: ', subtreeType);
    //console.log('value: ', value);
    const normName = OntologyClassUtil.normalizeClassName(name);

    await checkForNamesInTagsRecursively(subtreeType, normName, frameOntology.frames, findings);
    await checkForNamesInTagsRecursively(subtreeType, normName, frameOntology.roles, findings);
    await checkForNamesInTagsRecursively(subtreeType, normName, frameOntology.macros, findings);
    await checkForNamesInTagsRecursively(subtreeType, normName, frameOntology.synsets, findings);

    return findings;
}
const checkForNamesInTagsRecursively = async (subtreeType, normName, subtree, findings = {}) => {

    for (const index of Object.keys(subtree)) {
        const node = subtree[index];

        if (node && node.tags) {

            //console.log('check node: ', node);

            // --- check syntax tags --- //
            if (node.tags[OntologyClassUtil.TAG_TYPE_SYNTAX]) {
                for (const tag of node.tags[OntologyClassUtil.TAG_TYPE_SYNTAX]) {
                    //console.log('oc_syntax: ', node);
                    switch (subtreeType) {
                        case OntologyUtil.SUBTREE_FRAME: {
                            //console.log('check frame');
                            if (checkForFrameNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                        case OntologyUtil.SUBTREE_ROLE: {
                            const found = await checkForRoleNameInSyntax(tag, normName);
                            if (found) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                        case OntologyUtil.SUBTREE_SYNSET: {
                            if (checkForSynsetNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                        case OntologyUtil.SUBTREE_MACRO: {
                            if (checkForMacroNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                    }
                }
            }

            // --- update role tags --- //
            if (node.tags[OntologyClassUtil.TAG_TYPE_ROLE]) {
                for (const tag of node.tags[OntologyClassUtil.TAG_TYPE_ROLE]) {
                    //console.log('oc_role: ', node);
                    switch (subtreeType) {
                        case OntologyUtil.SUBTREE_FRAME: {
                            if (checkForFrameNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                        case OntologyUtil.SUBTREE_ROLE: {
                            if (checkForRoleNameInValue(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                        case OntologyUtil.SUBTREE_SYNSET: {
                            if (checkForSynsetNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                        case OntologyUtil.SUBTREE_MACRO: {
                            if (checkForMacroNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                    }
                }
            }

            // --- update macro tags --- //
            if (node.tags[OntologyClassUtil.TAG_TYPE_MACRO]) {
                for (const tag of node.tags[OntologyClassUtil.TAG_TYPE_MACRO]) {
                    //console.log('oc_macro: ', node);
                    switch (subtreeType) {
                        case OntologyUtil.SUBTREE_FRAME: {
                            if (checkForFrameNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                        case OntologyUtil.SUBTREE_SYNSET: {
                            if (checkForSynsetNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                        case OntologyUtil.SUBTREE_MACRO: {
                            if (checkForMacroNameInSyntax(tag, normName)) {
                                findings[node.name] = true;
                            }
                            break;
                        }
                    }
                }
            }
        }

        // --- check all children --- //
        if (node.children && node.children.length > 0) {
            await checkForNamesInTagsRecursively(subtreeType, normName, node.children, findings);
        }
    }
    return findings;
}

/**
 * Checks for frame name within the syntax of the given tag.
 * 
 * @param {Object} tag the tag to check
 * @param {string} name frame name
 */
const checkForFrameNameInSyntax = (tag, name) => {
    var regex = new RegExp(`F[/]${name}(?![a-zA-Z0-9_])`);
    return (tag.value.match(regex));
}


/**
 * Checks for role name within the syntax of the given tag.
 * 
 * @param {Object} tag the tag to check
 * @param {string} name frame name
 */
export const checkForRoleNameInSyntax = async (tag, name) => {

    var regex = new RegExp(`@${name}(?![a-zA-Z0-9_])`);
    if (tag.value.match(regex)) {
        //console.log('match@ : ', tag.value);
        return true;
    }

    var regexRole = new RegExp(`role=${name}(?![a-zA-Z0-9_])`);
    if (tag.value.match(regexRole)) {

        //console.log('match role=: ', tag.value);
        var regexTarget = new RegExp(`tg=`);
        if (tag.value.match(regexTarget)) {
            //console.log('match tg=: ', tag.value);
            const response = await translateSyntaxPatternStringToPhraseTokens(tag.value);
            //console.log('resonse: ', response);
            if (response.status === 'SUCCESS' && response.payload) {
                for (const token of response.payload) {
                    //console.log('has target: ', token.attr['tg']); //PHRASE_TOKEN_ATTRIBUTE_TARGET
                    if (hasTokenOrGroupMembersRoleWithoutTarget(token, name)) {
                        //console.log('token: ', token);
                        //console.log('has role, no target: ', token);
                        return true;
                    }
                }
            }
        }
        else {
            //console.log('no tg=');
            return true;
        }
    }

    return false;
}


/**
 * 
 * @param {*} token 
 * @param {*} roleName 
 */
const hasTokenOrGroupMembersRoleWithoutTarget = (token, roleName) => {
    let hasRoleOnly = false;
    if (token) {
        if (token.groupMembers) {
            for (const groupToken of token.groupMembers) {
                if (hasTokenOrGroupMembersRoleWithoutTarget(groupToken, roleName)) {
                    hasRoleOnly = true;
                }
            }
        }
        else {
            if (hasTokenAttributeValue(token, PHRASE_TOKEN_ATTRIBUTE_ROLE, roleName) &&
                !hasTokenAttribute(token, PHRASE_TOKEN_ATTRIBUTE_TARGET)) {
                hasRoleOnly = true;
            }
        }
    }
    return hasRoleOnly;
}


/**
 * Checks for role name within the tag value of the given role tag.
 * 
 * @param {Object} tag the role tag to check
 * @param {string} name role name
 */
const checkForRoleNameInValue = (tag, name) => {

    var regex = new RegExp(`${name}(?= .+)`);
    return (tag.value.match(regex));
}

/**
 * Checks for synset name within the syntax of the given tag.
 * 
 * @param {Object} tag the tag to check
 * @param {string} name synset name
 */
const checkForSynsetNameInSyntax = (tag, name) => {
    var regex = new RegExp(`SYN[/]${name}(?![a-zA-Z0-9_])`);
    return (tag.value.match(regex));
}

/**
 * Checks for macro name within the syntax of the given tag.
 * 
 * @param {Object} tag the tag to check
 * @param {string} name macro name
 */
const checkForMacroNameInSyntax = (tag, name) => {

    var regex = new RegExp(`\\$${name}(?![a-zA-Z0-9_])`);
    if (tag.value.match(regex)) {
        return true;
    }

    var regex2 = new RegExp(`MACRO[/]${name}(?![a-zA-Z0-9_])`);
    if (tag.value.match(regex2)) {
        return true;
    }
}


// ----------------------------------------------------------------------- //
// --- replace names in syntaxes and tags -------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Replaces all mentions of old name with new one within tags containing syntaxes (role, syntax, macro).
 * 
 * @param {string} subtreeType 
 * @param {string} valueOld 
 * @param {string} valueNew 
 * @param {Object} frameOntology 
 * @param {Number} counter 
 */
export const updateNamesInTags = async (subtreeType, valueOld, valueNew, frameOntology, counter = 0) => {

    //console.log('subtreeType: ', subtreeType);
    //console.log('valueOld: ', valueOld);
    //console.log('valueNew: ', valueNew);

    // --- replace in every part of ontology --- //
    await updateNamesInTagsRecursively(subtreeType, valueOld, valueNew, frameOntology.frames, OntologyUtil.SUBTREE_FRAME, counter);
    await updateNamesInTagsRecursively(subtreeType, valueOld, valueNew, frameOntology.roles, OntologyUtil.SUBTREE_ROLE, counter);
    await updateNamesInTagsRecursively(subtreeType, valueOld, valueNew, frameOntology.synsets, OntologyUtil.SUBTREE_SYNSET, counter);
    await updateNamesInTagsRecursively(subtreeType, valueOld, valueNew, frameOntology.macros, OntologyUtil.SUBTREE_MACRO, counter);

    //console.log('frameOntology after: ', frameOntology);

    return counter;
}

const updateNamesInTagsRecursively = async (subtreeType, valueOld, valueNew, subtree, relType, counter = 0) => {

    for (const index of Object.keys(subtree)) {
        const node = subtree[index];
        //console.log('node: ', node);

        if (node && node.tags) {

            // --- update syntax tags --- //
            if (node.tags[OntologyClassUtil.TAG_TYPE_SYNTAX]) {
                for (const tag of node.tags[OntologyClassUtil.TAG_TYPE_SYNTAX]) {
                    //console.log('oc_syntax: ', tag);
                    switch (subtreeType) {
                        case OntologyUtil.SUBTREE_FRAME: {
                            replaceFrameNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                        case OntologyUtil.SUBTREE_ROLE: {
                            await replaceRoleNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                        case OntologyUtil.SUBTREE_SYNSET: {
                            replaceSynsetNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                        case OntologyUtil.SUBTREE_MACRO: {
                            replaceMacroNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                    }
                }
            }

            // --- update role tags --- //
            if (node.tags[OntologyClassUtil.TAG_TYPE_ROLE]) {
                for (const tag of node.tags[OntologyClassUtil.TAG_TYPE_ROLE]) {
                    //console.log('oc_role: ', tag);
                    switch (subtreeType) {
                        case OntologyUtil.SUBTREE_FRAME: {
                            replaceFrameNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                        case OntologyUtil.SUBTREE_ROLE: {
                            replaceRoleNameInValue(tag, valueOld, valueNew);
                            break;
                        }
                        case OntologyUtil.SUBTREE_SYNSET: {
                            replaceSynsetNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                        case OntologyUtil.SUBTREE_MACRO: {
                            replaceMacroNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                    }
                }
            }

            // --- update macro tags --- //
            if (node.tags[OntologyClassUtil.TAG_TYPE_MACRO]) {
                for (const tag of node.tags[OntologyClassUtil.TAG_TYPE_MACRO]) {
                    //console.log('oc_macro: ', tag);
                    switch (subtreeType) {
                        case OntologyUtil.SUBTREE_FRAME: {
                            replaceFrameNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                        case OntologyUtil.SUBTREE_SYNSET: {
                            replaceSynsetNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                        case OntologyUtil.SUBTREE_MACRO: {
                            replaceMacroNameInSyntax(tag, valueOld, valueNew);
                            break;
                        }
                    }
                }
            }
        }

        // --- check all children --- //
        if (node.children && node.children.length > 0) {
            await updateNamesInTagsRecursively(subtreeType, valueOld, valueNew, node.children, relType, counter);
        }


        // update relationships
        /*
        if (subtreeType === OntologyUtil.SUBTREE_ROLE && relType === OntologyUtil.SUBTREE_FRAME) {
            console.log('node frame?: ', node);
            OntologyClassUtil.updateRelationshipNameInFrame(node, valueOld, valueNew);
            console.log('update relationship: ', node);
        }
        */
    }
    return counter;
}

/**
 * Replaces the old frame name with the new one within the syntax of the given tag.
 * 
 * @param {Object} tag the tag containing the syntax string
 * @param {string} nameOld old frame name
 * @param {string} nameNew new frame name
 */
const replaceFrameNameInSyntax = (tag, nameOld, nameNew) => {

    // --- replace F/FRAME_NAME in syntax --- //
    var regex = new RegExp(`F[/]${nameOld}(?![a-zA-Z0-9_])`, 'g');
    tag.value = tag.value.replace(regex, `F/${nameNew}`);
}

/**
 * Replaces the old role name with the new one within the syntax of the given tag.
 * 
 * @param {Object} tag the tag containing the syntax string
 * @param {string} nameOld old role name
 * @param {string} nameNew new role name
 */
const replaceRoleNameInSyntax = async (tag, nameOld, nameNew) => {

    // --- replace @ROLE_NAME in syntax --- //
    var regex = new RegExp(`@${nameOld}(?![a-zA-Z0-9_])`, 'g');
    tag.value = tag.value.replace(regex, `@${nameNew}`);

    // --- replace role=ROLE_NAME in syntax --- //
    var regexRole = new RegExp(`role=${nameOld}(?![a-zA-Z0-9_])`, 'g');
    // --- role found in syntax --- //
    if (tag.value.match(regexRole)) {

        // --- find target in syntax --- //
        var regexTarget = new RegExp(`tg=`);
        // --- no target found in syntax -> replace role --- //
        if (!tag.value.match(regexTarget)) {
            tag.value = tag.value.replace(regexRole, `role=${nameNew}`);
        }
        // --- target found in syntax -> only replace roles in tokens without target --- //
        else {
            const response = await translateSyntaxPatternStringToPhraseTokens(tag.value);
            //console.log('response: ', response);
            if (response.status === 'SUCCESS' && response.payload) {
                if (response.payload) {
                    for (const token of response.payload) {
                        // --- role found in token --- //
                        replaceRolesWithoutTargetInTokenOrGroupMembers(token, nameOld, nameNew);
                    }
                    const response2 = await translatePhraseTokensToSyntaxPatternString(response.payload);
                    if (response2.status === 'SUCCESS' && response2.payload && response2.payload.parsedSyntax) {
                        tag.value = response2.payload.parsedSyntax;
                    }
                }
            }
        }
    }
}


/**
 * 
 * @param {*} token 
 * @param {*} roleNameOrig 
 * @param {*} roleNameNew
 */
const replaceRolesWithoutTargetInTokenOrGroupMembers = (token, roleNameOrig, roleNameNew) => {

    if (token) {
        if (token.groupMembers) {
            for (const groupToken of token.groupMembers) {
                replaceRolesWithoutTargetInTokenOrGroupMembers(groupToken, roleNameOrig, roleNameNew)
            }
        }
        else {
            if (hasTokenAttributeValue(token, PHRASE_TOKEN_ATTRIBUTE_ROLE, roleNameOrig) &&
                !hasTokenAttribute(token, PHRASE_TOKEN_ATTRIBUTE_TARGET)) {

                removeAttributeFromToken(token, PHRASE_TOKEN_ATTRIBUTE_ROLE);
                setAttributeInToken(token, PHRASE_TOKEN_ATTRIBUTE_ROLE, roleNameNew);
            }
        }
    }
    //return hasRoleOnly;
}


/**
 * Replaces the old role name with the new one within the tag value of the given role tag.
 * 
 * @param {Object} tag the role tag containing the value
 * @param {string} nameOld old role name
 * @param {string} nameNew new role name
 */
const replaceRoleNameInValue = (tag, nameOld, nameNew) => {

    // --- replace ROLE_NAME in syntax --- //
    var regex = new RegExp(`${nameOld}(?= .+)`);
    tag.value = tag.value.replace(regex, `${nameNew}`);
}

/**
 * Replaces the old synset name with the new one within the syntax of the given tag.
 * 
 * @param {Object} tag the tag containing the syntax string
 * @param {string} nameOld old synset name
 * @param {string} nameNew new synset name
 */
const replaceSynsetNameInSyntax = (tag, nameOld, nameNew) => {

    // --- replace SYN/SYNSET_NAME in syntax --- //
    var regex = new RegExp(`SYN[/]${nameOld}(?![a-zA-Z0-9_])`, 'g');
    tag.value = tag.value.replace(regex, `SYN/${nameNew}`);
}

/**
 * Replaces the old macro name with the new one within the syntax of the given tag.
 * 
 * @param {Object} tag the tag containing the syntax string
 * @param {string} nameOld old macro name
 * @param {string} nameNew new macro name
 */
const replaceMacroNameInSyntax = (tag, nameOld, nameNew) => {

    // --- replace MACRO_NAME in macro definition --- //
    if (tag.tag === OntologyClassUtil.TAG_TYPE_MACRO) {
        const regex2 = new RegExp(`^${nameOld} `, 'g');
        tag.value = tag.value.replace(regex2, `${nameNew} `);
    }

    // --- replace $MACRO_NAME in syntax --- //
    const regex = new RegExp(`\\$${nameOld}(?![a-zA-Z0-9_])`, 'g');
    tag.value = tag.value.replace(regex, `$${nameNew}`);

    // --- replace MACRO/MACRO_NAME in syntax --- //
    const regex2 = new RegExp(`MACRO[/]${nameOld}(?![a-zA-Z0-9_])`, 'g');
    tag.value = tag.value.replace(regex2, `MACRO/${nameNew}`);
}




/**
 * 
 * @param {*} syntax 
 * @param {*} inclSyntaxSpecificRoles 
 */
export const extractRolesFromSyntax = async (syntax, inclSyntaxSpecificRoles = false) => {

    const roleNames = {};

    /*
    var regex = new RegExp(`@[a-zA-Z0-9_]()(?![a-zA-Z0-9_])`, 'g');
    while (syntax.match(regex)) {

    }
    */

    const response = await translateSyntaxPatternStringToPhraseTokens(syntax);
    //console.log('resonse: ', response);
    if (response.status === 'SUCCESS' && response.payload) {
        for (const token of response.payload) {
            //console.log('has target: ', token.attr['tg']); //PHRASE_TOKEN_ATTRIBUTE_TARGET
            getRolesWithoutTarget(token, inclSyntaxSpecificRoles, roleNames);
        }

        return roleNames;
    }
}

/**
 * 
 * @param {*} token 
 * @param {*} roleNames 
 */
const getRolesWithoutTarget = (token, inclSyntaxSpecificRoles, roleNames) => {

    if (token) {
        if (token.groupMembers) {
            for (const groupToken of token.groupMembers) {
                getRolesWithoutTarget(groupToken, inclSyntaxSpecificRoles, roleNames);
            }
        }
        else {
            if (hasTokenType(token, PHRASE_TOKEN_TYPE_ROLE_REFERENCE)) {
                //console.log('role ref token: ', token);
                const type = getMainTokenTypeObject(token);
                //console.log('type: ', type);
                roleNames[type.subType] = true;
            }
            else if (hasTokenAttribute(token, PHRASE_TOKEN_ATTRIBUTE_ROLE)) {
                if (inclSyntaxSpecificRoles || !hasTokenAttribute(token, PHRASE_TOKEN_ATTRIBUTE_TARGET)) {
                    const roleName = getTokenAttribute(token, PHRASE_TOKEN_ATTRIBUTE_ROLE);
                    roleNames[roleName] = true;
                }
            }
        }
    }
}
