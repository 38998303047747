import { PureComponent } from "react";
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import './AnnotationsTree.css';


class AnnotationsTree extends PureComponent {

    constructor(props) {
        super(props);

        this.nodeTemplate = this.nodeTemplate.bind(this);
    }

    /**
     * Tree node template. Nodes can either be of type "domain" (upper level node, i.e. a knowledge domain) or 
     * "ne" (named entity, i.e. a concrete concept of a domain)
     */
    nodeTemplate(node) {
        // --- domain node --- //
        if (node.type === 'domain') {
            return (
                <span
                    className={`ann-tree-node-domain ne ${this.props.activeAnnotations.includes(node.domain) ? node.css : 'legend'}`}
                    onClick={(e) => this.props.toggleDomainHighlighting(node.domain)}
                    style={{ background: `${this.props.activeAnnotations.includes(node.domain) ? `#${node.color}` : ''}` }}
                    title="Click to (de)activate highlighting for this domain">
                    <span className='ann-tree-node-label'>{node.label}</span>
                    <span className='ann-tree-node-hitcount'
                        title={`${node.children.length} concepts | ${node.count} hits`}>
                        {node.children.length} | {node.count}x</span>
                </span >
            )
        }
        // --- named entity node (concept of certain domain) --- //
        else if (node.type === 'ne') {
            return (
                <span className='ann-tree-node-ne'>
                    <div className="grid ann-tree-node-entry">
                        <div className="col-fixed ann-tree-node-counter">
                            <span>{node.childNo})</span>
                        </div>
                        <div className="col ann-tree-node-label breakWord croppedTableCell"
                            title={`Show details for ${node.label}`}
                            onClick={() => this.props.onClick(node.ocid)}>
                            <a className="secondaryLink">{node.label}</a>
                        </div>
                        <div className="col-fixed ann-tree-node-hitcount">
                            {node.count}x
                        </div>
                        <div className="col-fixed ann-tree-node-jumptohits">
                            <Button
                                className="minorButton p-button-sm buttonSmall ann-tree-node-button"
                                type="button"
                                label="<"
                                title="Jump to previous occurrence"
                                onClick={() => this.props.jumpToMatch(node.ocid, false, node.domain)} >
                            </Button>
                            <Button
                                className="minorButton p-button-sm buttonSmall ann-tree-node-button"
                                type="button"
                                label=">"
                                title="Jump to next occurrence"
                                onClick={() => this.props.jumpToMatch(node.ocid, true, node.domain)} >
                            </Button>
                        </div>
                    </div>
                </span>
            )
        }
        // --- default node --- //
        else {
            return (
                <span>{node.label}</span>
            )
        }
    }

    render() {
        const { conceptTreeData } = this.props;
        return (
            <div className="ontTree compactTree">
                {conceptTreeData ?
                    <Tree
                        value={conceptTreeData}
                        nodeTemplate={this.nodeTemplate}
                        className='ann-tree width100perc'
                    />
                    : null
                }
            </div>
        );
    }
}

export default AnnotationsTree;