import ReferencedSavedSearch from "../../../ReferencedSavedSearch";

const SavedSearchGroupCompact = ({ savedSearchID, savedSearchData }) => {
  return (
    <div className="flex align-items-center flex-wrap line-height-3">
      <ReferencedSavedSearch
        savedSearchID={savedSearchID}
        savedSearchData={savedSearchData}
      />
    </div>
  );
};

export default SavedSearchGroupCompact;
