/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { DataScroller } from 'primereact/datascroller';
import { isArrayEmpty } from '../util';
import LoadingOverlay from "@speedy4all/react-loading-overlay";


class ImagesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numOfImagesShown: 0,
            images: [],
            first: 0,
            rows: 25
        };
        this.imgTemplate = this.imgTemplate.bind(this);
    }

    /**
     * Renders image with image path in header.
     * @param {Object} img 
     */
    imgTemplate(img) {
        return (
            <div className="task-box"
                style={{ boxShadow: 'none', border: '1px solid #ddd', marginBottom: '10px', cursor: 'pointer' }}
                onClick={(e) => this.props.jumpToImage(img.url)}
                title={img.path}>
                <div className="task-box-header small clampingContainer line-clamp line-clamp-1-line">{img.number}. {img.path}</div>
                <div className="task-box-content">
                    <div style={{ margin: '0 auto', textAlign: 'center', maxWidth: '100%' }}>
                        <img src={img.url} alt={img.path} width="100%" />
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Loads next images from list.
     */
    loadData = () => {

        this.setState({
            fetchingImages: true
        });

        const images = [...this.state.images];

        if (this.props && this.props.images) {
            for (var i = this.state.first; i < this.props.images.length && i < this.state.first + this.state.rows; i++) {
                const img = this.props.images[i];
                img.number = i + 1;
                images.push(img);
            }
        }

        this.setState({
            numOfImagesShown: this.state.first + this.state.rows <= this.props.images?.length ? this.state.first + this.state.rows : this.props.images?.length,
            images: images,
            fetchingImages: false,
            hasMoreImages: this.props.images && this.props.images.length > this.state.first + this.state.rows,
            first: this.state.first + this.state.rows
        });
    }

    render() {
        const { images, emptyMessage } = this.props;

        //const footer = <div style={{ display: this.state.hasMoreImages ? 'block' : 'none', padding: 10 }}><a ref={el => this.loadButton = el}>Load more images</a></div>;
        const footer = this.state.hasMoreImages ? <div style={{ padding: 5 }}>
            <a onClick={() => this.ds.load()}>Load more images</a>
        </div> : null;

        return (
            <LoadingOverlay
                active={this.state.fetchingImages}
                spinner={true} >
                <div className="dashboard">
                    {!isArrayEmpty(images) ?
                        <DataScroller
                            ref={(el) => this.ds = el}
                            value={this.state.images}
                            itemTemplate={this.imgTemplate}
                            rows={this.state.rows}
                            loader
                            footer={footer}
                            header={`${this.state.numOfImagesShown} of ${images.length} images loaded`}
                            lazy={true}
                            onLazyLoad={this.loadData}
                            scrollHeight="calc(100vh - 400px)"
                            inline={true}
                        />
                        :
                        <div>{emptyMessage}</div>
                    }
                </div>
            </LoadingOverlay>
        );
    }
}

export default ImagesList;