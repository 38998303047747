import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import SearchGroupFooter from "./SearchGroupFooter/SearchGroupFooter";
import SearchGroupCriteriaLabels from "./SearchGroupCriteriaLabels/SearchGroupCriteriaLabels";
import SearchGroupRow from "./SearchGroupRow/SearchGroupRow";

const SearchGroupCriteriaEditor = ({
  filterDefinitions,
  logicalOperators,
  logicalOperator,
  searchCriteria,
  searchCriteriaFilters,
  onValuesChange,
  hideSearchCriteria,
  renderFilter,
}) => {
  const [displaySearchCriteriaDialog, setDisplaySearchCriteriaDialog] =
    useState();
  const [criteriaInput, setCriteriaInput] = useState(searchCriteria || {});
  const [operator, setOperator] = useState(logicalOperator);

  const updateSearchCriteria = (filterId, partCriteriaInput) => {
    const criteriaInputNew = { ...criteriaInput };
    if (!partCriteriaInput || !Object.keys(partCriteriaInput).length) {
      delete criteriaInputNew[filterId];
    } else {
      criteriaInputNew[partCriteriaInput.filterID] = partCriteriaInput.value;
    }
    setCriteriaInput(criteriaInputNew);
  };

  const closeHandler = () => setDisplaySearchCriteriaDialog(false);
  const acceptHandler = () => {
    onValuesChange(operator, criteriaInput);
    closeHandler();
  };
  const editCriteriaLabel = () => setDisplaySearchCriteriaDialog(true);

  const selectedOperator = logicalOperators.find(
    (op) => op.value === logicalOperator
  );

  return (
    <>
      <SearchGroupCriteriaLabels
        selectedOperatorLabel={selectedOperator.label}
        searchCriteria={searchCriteria}
        filterDefinitions={filterDefinitions}
        onEdit={editCriteriaLabel}
      />
      <Dialog
        visible={displaySearchCriteriaDialog}
        onHide={closeHandler}
        blockScroll
        style={{ width: "50vw" }}
        header="Search criteria"
        footer={
          <SearchGroupFooter
            onAccept={acceptHandler}
            onCancel={closeHandler}
          />
        }
        appendTo="self"
        className="styled-dialog"
      >
        <div
          className="grid width100perc"
          style={{ padding: "20px 10px 10px 25px" }}
        >
          <div className="col-fixed" style={{ width: 150 }}>
            Combine with
          </div>
          <div className="col">
            <Dropdown
              id="logic"
              value={operator}
              options={logicalOperators}
              optionValue="value"
              onChange={(e) => {
                setOperator(e.value);
              }}
              style={{ minWidth: 70 }}
              placeholder="logical operator"
            />
          </div>
          <div className="breakRow" />
          {!hideSearchCriteria &&
            filterDefinitions &&
            searchCriteriaFilters?.map((filterID) => {
              const filter = filterDefinitions[filterID];
              return filter ? (
                <React.Fragment key={filter.id}>
                  <SearchGroupRow
                    label={filter.label}>
                    {renderFilter({
                      filter,
                      selectedEntry: criteriaInput[filterID],
                      updateSearchCriteria,
                    })}
                  </SearchGroupRow>
                </React.Fragment>
              ) : null;
            })}
        </div>
      </Dialog>
    </>
  );
};

export default SearchGroupCriteriaEditor;
