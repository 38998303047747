import "./ExternalSources.css";
import { isArrayEmpty } from "../../../util";


const ExternalSources = ({ sourceReferences = [] }) => {

  return !isArrayEmpty(sourceReferences) ?
    <ul className='paddingSmall noStyleList external-sources-list'>
      {sourceReferences.map(source => {
        return <li key={source.conceptUrl}>
          <span className="external-sources-entry">
            <a className="secondaryLink clampingContainer line-clamp line-clamp-1-line"
              target="_blank"
              rel="noopener noreferrer"
              title={`${source.sourceName}: ${source.conceptRefId}`}
              href={source.conceptUrl}>{source.sourceName}: {source.conceptRefId}</a>
          </span></li>
      })}
    </ul>
    :
    <span>No sources</span>;
}

export default ExternalSources;