import _ from "lodash"

/**
 * 
 * @returns total of the semantic search
 */
export const totalMatches = (activeSearchTermsCount) => {
    let filteredActiveSearchTermsCount 
    if(Object.keys(activeSearchTermsCount).length > 0 ){
        filteredActiveSearchTermsCount  = Object.keys(activeSearchTermsCount).filter(item => item !== 'ocFuzzyText:readcube_text').reduce((obj, key) => {
            obj[key]= activeSearchTermsCount[key]
            return obj
        }, {})
    } else {
        filteredActiveSearchTermsCount  = activeSearchTermsCount
    }
    return Object.values(filteredActiveSearchTermsCount).reduce((partialSum, a) => partialSum + a, 0)
}




/**
 * 
 * @returns total of the semantic search
 */
export const totalHiddenMatches = (hiddenMatches) => {
    return Object.values(hiddenMatches).reduce((partialSum, a) => partialSum + a?.length, 0) || 0
}

/**
 * 
 * @param {*} querId 
 * @param {*} annotations 
 * @returns 
 */
export const getFilteredAnnotatioin = (annotations, querId = null) => {
    return _.orderBy(annotations?.filter((item) => item?.annotations?.find((obj) => querId ? obj?.queryId === querId : obj?.queryId)), [function (o) { return parseInt(o?.annId?.split('d')[1]) }])?.length
}