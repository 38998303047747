// url - source to fetch data for biomarker popup
// name - key in search result to render facetts for left side menu

export const headerTrees = {
    type: {
        "key": "5-0",
        "label": "link",
        "name": "type",
        "text": "Show tree",
        "tree": "Biomarker",
        "url": "api/biomarker/filters/biomarker"
    },
    population: {
        "key": "7-0",
        "label": "link",
        "name": "population",
        "text": "Show tree",
        "tree": "Population",
        "url": "api/biomarker/filters/population"
    }
}

