import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FrameEditor from '../frameeditor/FrameEditor';

const mapStateToProps = state => ({
    userData: state.user.data,
    serverStatus: state.webAPI.serverStatus
})
const mapDispatchToProps = dispatch => ({})

const FrameEditorContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FrameEditor))

export default FrameEditorContainer