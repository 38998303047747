import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class DeleteDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteDepartmentChecked: false
        }
    }

    onCheckDeleteDepartment = (e) => {
        if (e.checked) {
            this.setState({
                deleteDepartmentChecked: true
            })
        } else {
            this.setState({
                deleteDepartmentChecked: false
            })
        }
    }

    render() {


        const footerDeleteDepartment = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete"
                    className='p-button-sm p-button-danger'
                    disabled={this.state.deleteDepartmentChecked ? false : true}
                    onClick={this.props.onDeleteDepartmentAndUsers}
                />
                <Button label="Cancel"
                    className='p-button-sm p-button-secondary'
                    onClick={this.props.onCancelDeleteDepartment}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayDeleteDepartment} style={{ 'width': "40vw" }} header="Delete suborganization" focusOnShow={false}
                modal={true} footer={footerDeleteDepartment} dismissableMask={false}
                onHide={() => this.props.onCancelDeleteDepartment()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div style={{ marginBottom: 10 }}>
                        <label>{`This will delete suborganization '${this.props.selectedDepartmentName}' and all its users.`}</label>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <Checkbox inputId='deleteDepartmentChecked'
                            onChange={(e) => this.onCheckDeleteDepartment(e)}
                            checked={this.state.deleteDepartmentChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                        />
                        <label htmlFor='deleteDepartmentChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default DeleteDepartment