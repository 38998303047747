import React, { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";
import { hasUserRole } from "../../util";


class YearStatisticsInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="yearStatsInfo"
                title="Click for information about 30-year trend"
                imageAltText="30-year trend information"
                overlayContent={
                    <>
                        <h3>30-year trend</h3>
                        <p>
                            These charts show the number of documents per year for the last 30 years.
                            The statistics are based on the publication dates of the documents.
                            You will find the absolute number of documents as well as the
                            relative number of documents per year.
                        </p>
                        <h5>Number of documents</h5>
                        <p>
                            The absolute number of documents per year
                            matching your search.
                        </p>
                        <h5>Percentage of overall documents</h5>
                        <p>
                            The relative number of documents per year
                            matching your search. Only available if performing a search.
                        </p>
                        {hasUserRole("ROLE_TRENDANALYSIS") ?
                            <>
                                <h5>Trend analysis</h5>
                                <p>
                                    Click to further analyze your search, e.g. detect trends, compare different repositories
                                    or set it against other searches. Only available if performing a search.
                                </p>
                            </> : null
                        }
                    </>
                }
            />
        );
    }
}

export default YearStatisticsInfo;