export const createQueryList = (queryTerms, roleMapping) => {

    return queryTerms.map(query => {

        const term = {
            fieldQuery: {
                fieldName: query.fieldName ||
                    roleMapping.find(role => role.label === query.roles?.[0])?.name
            },
            occurrence: query.occurrence
        };

        if (query.conceptLookupType) {
            // concept search case (with ocids and 'with subtree' or 'term only' option)

            if (query.ocids) {
                term.fieldQuery.ocids = query.ocids;
            }

            if (query.domainTerms) {
                //'any' case
                term.fieldQuery.domainTerms = query.domainTerms;
            }

            term.fieldQuery.conceptLookupType = query.conceptLookupType;
        } else if (query.term && query.termLookupType && !query.domainTerms) {
            //1) term search case
            //2) concept + 'text exact' or 'text with variants' case
            //3) simplesearch case

            // case for simplesearch when query.term is string
            if (typeof query.term === 'string') {
                term.fieldQuery.terms = [query.term];
            } else {
                term.fieldQuery.terms = query.term;
            }

            term.fieldQuery.termLookupType = query.termLookupType;
        }

        return term;
    });
};
