import axios from 'axios'
import utf8 from 'utf8'
import { getHeaderNoToken, getHeaderToken } from './index'
import { APP_PROPERTIES } from '../properties/index';


export const updatePassword = (userInfos) => {
    let status = 'FAILED'
    if (userInfos.username) {
        return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/user/${userInfos.username}/newPassword`, { method: 'POST', headers: getHeaderToken(), body: userInfos.password })
            .then(response => {
                console.log(response)
                if (response.ok) {
                    status = 'SUCCESS'
                    return response
                } else {
                    status = 'FAILED'
                    return response.text()
                }
            })
            .then(payload => ({ status, payload }))
            .catch(error => ({ status: 'FAILED', message: error }))
    } else {
        return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/user/newPassword`, { method: 'POST', headers: getHeaderToken(), body: userInfos.password })
            .then(response => {
                console.log(response)
                if (response.ok) {
                    status = 'SUCCESS'
                    return response
                } else {
                    status = 'FAILED'
                    return response.text()
                }
            })
            .then(payload => ({ status, payload }))
            .catch(error => ({ status: 'FAILED', message: error }))
    }
}

export const fetchUserInformation = () => axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/user`, { headers: getHeaderToken() })
    .then(response => {
        //console.log(response.data)
        // --- convert domains and annotation types to map ---- //
        if (response.data && response.data.userDetails && response.data.userDetails.department) {
            // --- store domain -> label map --- //
            const domainLabelsMap = {};
            if (response.data.userDetails.department.selectedDomains) {
                response.data.userDetails.department.selectedDomains.forEach(domain => {
                    domainLabelsMap[domain.name] = domain.label;
                });
            }
            response.data.userDetails.department.domainLabelsMap = domainLabelsMap;

            // --- store annotation type -> label map --- //
            const annTypeLabelsMap = {};
            if (response.data.userDetails.department.selectedAnnotationTypes) {
                response.data.userDetails.department.selectedAnnotationTypes.forEach(annType => {
                    annTypeLabelsMap[annType.name] = annType.label;
                });
            }
            response.data.userDetails.department.annTypeLabelsMap = annTypeLabelsMap;


        }

        return ({ status: 'SUCCESS', payload: response.data })
    })
    .catch(error => ({ status: 'FAILED', message: error.message }))

export const fetchUserApps = () => axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards`, { headers: getHeaderToken() })
    .then(response => {
        //console.log(response)
        // --- fetch apps for user ---- //

        return ({ status: 'SUCCESS', payload: response.data })
    })
    .catch(error => ({ status: 'FAILED', message: error.message }))

export const fetchAdminApps = () => axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards/admin`, { headers: getHeaderToken() })
    .then(response => {
        //console.log(response)
        // --- fetch apps for user ---- //

        return ({ status: 'SUCCESS', payload: response.data })
    })
    .catch(error => ({ status: 'FAILED', message: error.message }))


export const fetchUserLoginStatus = () => axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/login-status`, { headers: getHeaderToken() })
    .then(response => {
        //console.log('response: ', response);
        if (response.status === 200) {
            return { status: 'SUCCESS', payload: true }
        }
        else {
            return { status: 'SUCCESS', payload: true, message: 'Not authenticated' }
        }
    })
    .catch(error => ({ status: 'FAILED', message: error.message }))



export const updateUserDetails = (userDetails) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/user/update`, { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(userDetails) })
        .then(response => {
            console.log(response)
            if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const doLogin = (username, password, forceLogin = false) => {
    let status = 'FAILED';
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/authentication?username=${username}&password=${btoa(utf8.encode(password))}${forceLogin ? '&forceLogin=true' : ''}`,
        { method: 'POST', headers: getHeaderNoToken() })
        .then(response => {
            //console.log('response: ', response);
            if (response.ok) {
                status = 'SUCCESS'
                return response.json();
            } else {
                status = 'FAILED'
                return response.json();
            }
        })
        .then(payload => {
            //console.log('payload: ', payload);
            if (status === 'FAILED') {
                if (payload.status === 401) {
                    //return { status, message: 'Unknown username and/or password.' };
                    return { status, message: payload.message };
                }
                if (payload.status === 4002) {
                    return { status, message: payload.message + " If you know what you are doing use 'Force sign in' to terminate the active session and log in." };
                }
                return { status, message: 'Unexpected error occured. Please contact an admin.' };
            }
            return { status, payload };
        })
        .catch(error => ({ status: 'FAILED', message: error }))
}

/**
 * Delete token in middleware.
 */
export const logout = () => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/token`,
        { method: 'DELETE', headers: getHeaderToken() })
        .then(response => {
            if (response.ok) {
                status = 'SUCCESS'
                // return ?
            } else
                status = 'FAILED'
            // return ?
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

/**
 * Refresh token.
 */
export const refreshToken = () => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/token`,
        { method: 'POST', headers: getHeaderToken() })
        .then(response => {
            //console.log('response: ', response);
            if (response.ok) {
                status = 'SUCCESS';
                return response.json();
            } else {
                status = 'FAILED';
                return response.json();
            }
        })
        .then(payload => {
            return { status, payload };
        })
        .catch(error => ({ status: 'FAILED', message: error }))
}