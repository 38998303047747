import $ from 'jquery';


/**
 * Manipulates elements in html string, e.g. lists, links etc. 
 * @param {*} html 
 * @param {*} htmlDocContainer 
 * @returns 
 */
export const updateHtml = (html, htmlDocContainer) => {
    // --- adds a hyphen before all br elements of class ocTextHyphen --- //
    html = addLineBreakHyphens(html);
    // --- adjust external links so they will be opened in new tabs/windows --- //
    html = addOpenExtLinksInNewTab(html);
    // --- adjust anchor links --- //
    html = adjustAnchorLinks(html);
    // --- add scroll functionality (hack) --- //
    html = addScrollToButtons(html, htmlDocContainer);

    return html;
}

/** 
 * Adds a hyphen before all br elements of class ocTextHyphen. 
 * @param {string} html 
 * @returns 
 */
const addLineBreakHyphens = (html) => {
    // --- enclose original html string in temporary div --- //
    var htmlObj = $('<div>' + html + '</div>');
    // --- add hyphen --- //
    htmlObj.find("br.ocTextHyphen").before("-");
    // --- return html content of enclosing div --- //
    return htmlObj.html();
};

/**
 * Adds target=_blank to external links to open in new tab/window.
 * @param {string} html 
 * @returns 
 */
const addOpenExtLinksInNewTab = (html) => {
    // --- enclose original html string in temporary div --- //
    var htmlObj = $('<div>' + html + '</div>');
    // --- add target=_blank to each external link --- //
    htmlObj.find("a[href^='http://']").attr("target", "_blank");
    htmlObj.find("a[href^='https://']").attr("target", "_blank");
    // --- return html content of enclosing div --- //
    return htmlObj.html();
}

/**
 * Add frontend URL as prefix to anchor links, otherwise won't work otherwise.
 * @param {string} html 
 * @returns 
 */
const adjustAnchorLinks = (html) => {
    // --- enclose original html string in temporary div --- //
    var htmlObj = $('<div>' + html + '</div>');
    // --- add frontend URL as prefix to each anchor link --- //
    for (var elem of htmlObj.find("a[href^='#']")) {
        var hrefOrig = $(elem).attr("href");
        $(elem).attr("href", `${window.location.origin}${window.location.pathname}${hrefOrig}`);
    }
    // --- return html content of enclosing div --- //
    return htmlObj.html();
}

/**
 * Replaces image placholders with actual images. 
 * Moves img-src attribute to src attribute.
 * @param {string} html 
 * @returns 
 */
export const replaceImagePlaceholders = (html, imagesCutoff) => {
    // --- enclose original html string in temporary div --- //
    var htmlObj = $('<div>' + html + '</div>');
    // --- wrap first image in abstract in a div --- //
    var firstImage = htmlObj.find('div.ocTextAbstract span.ocTextImage').first();
    if (firstImage) {
        firstImage.wrap('<div class="test"></div>');
        //$('<br/>').insertBefore(firstImage);
    }
    // --- move value from data-src attribute to src attribute (img) --- //
    htmlObj = setImageSourceFromDataSource(htmlObj, "img[data-src]");
    // --- move value from img-src attribute to src attribute (span) --- //
    const imagesCounter = { counter: 0, cutoff: imagesCutoff };
    htmlObj = replaceSpansWithImageTags(htmlObj, "span.ocTextChemicalImage[img-src]", imagesCounter);
    htmlObj = replaceSpansWithImageTags(htmlObj, "span.ocTextImage[img-src]", imagesCounter);
    // --- return html content of enclosing div ---------------------- //
    // --- (the temporary div will not be part of the html string) --- //
    return htmlObj.html();
}

/**
 * Replaces image placholders with actual images. 
 * Moves data-src attribute to src attribute.
 * @param {*} htmlObj 
 * @param {*} selector 
 * @returns 
 */
const setImageSourceFromDataSource = (htmlObj, selector) => {
    htmlObj.find(selector).each(function () {
        var newSrc = $(this).attr('data-src');
        $(this).attr('src', newSrc);
        $(this).removeAttr('data-src');
    });
    return htmlObj;
}

/**
 * Replaces span tags with image tags.
 * @param {*} htmlObj 
 * @param {*} selector 
 */
const replaceSpansWithImageTags = (htmlObj, selector, imagesCounter) => {

    htmlObj.find(selector).each(function () {
        if (imagesCounter.counter < imagesCounter.cutoff) {
            imagesCounter.counter = imagesCounter.counter + 1;
            // --- if image span has no siblings and is surrounded by a div --- //
            // --- -> display div as inline-block element --------------------- //
            if ($(this).siblings().length === 0 && $(this).parent('div')) {
                $(this).parent('div').css('display', 'inline-block');
            }

            // --- hide image tag spans and insert actual images --- //
            var srcUrl = $(this).attr('img-src');
            var imgTagHtml =
                "<a target='_blank' href='" + srcUrl + "'>"
                + "<img class='thumbnail' src='" + srcUrl + "' title='Click to enlarge'>"
                + "</a>";

            if ($(this).children("span[id^='annId']").length > 0) {
                imgTagHtml += "<span class='conceptInfoLabel'>Concept info</span>";
            }

            // --- if there are multiple spans within this container --- //
            // --- display them inline --------------------------------- //
            if ($(this).children('span') && $(this).children('span').length > 0) {
                $(this).children('span').first().html(imgTagHtml);
                $(this).children('span').css('display', 'inline-block');
            }
            else {
                $(this).html(imgTagHtml);
            }
        }
    });
    return htmlObj;
}

/**
 * Adds an invisible scroll-to button to html (hack). 
 * This button can be clicked programmatically to initiate scrolling to an element.
 * @param {*} html 
 * @param {*} htmlDocContainer 
 * @returns 
 */
const addScrollToButtons = (html, htmlDocContainer) => {
    // --- enclose original html string in temporary div --- //
    var htmlObj = $('<div>' + html + '</div>');
    // --- append clickable span for NE scrolling --- //
    htmlObj.append('<span id="scrollToBtn" onclick="scrollToHtmlElement(\'#scrollToBtn\', \'#' + htmlDocContainer + '\')" style="display:none;"></span>');
    // --- append clickable span for image scrolling --- //
    htmlObj.append('<span id="scrollToImg" onclick="scrollToImageElement(\'#scrollToImg\', \'#' + htmlDocContainer + '\')" style="display:none;"></span>');
    // --- return html content of enclosing div (the temporary div will not be part of the html string) --- //
    return htmlObj.html();
}