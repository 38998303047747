import { PureComponent } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import SequenceDetails from './SequenceDetails';


class SequenceDetailsDialog extends PureComponent {

    render() {
        const { displayDialog, onDisplayDialogChange, sequenceOcid, sequenceLength, sequence, onExport } = this.props;

        const footer = (
            <div style={{ paddingTop: 5 }}>
                <Button label="FASTA (.zip)"
                    className='primaryButton p-button-sm'
                    icon='pi-md-file-download'
                    onClick={onExport}
                    style={{ marginLeft: 0, marginTop: 0 }} />
            </div>
        )

        return (
            <Dialog
                visible={displayDialog}
                style={{ 'width': "70vw" }}
                header="Sequence details"
                focusOnShow={false}
                modal={true}
                footer={footer}
                dismissableMask={true}
                onHide={() => onDisplayDialogChange(false)} className='styledDialog'>
                <SequenceDetails
                    sequenceOcid={sequenceOcid}
                    sequenceLength={sequenceLength}
                    sequence={sequence}
                />
            </Dialog>
        );
    }
}

export default SequenceDetailsDialog;