import "./SearchGroupsToolbar.css";
import { Dropdown } from "primereact/dropdown";
import SavedSearchesLink from "../../../general/SavedSearches/SavedSearchesLink";
import { QUERY_TYPE_ADVANCED_SEARCH_3 } from "../../../../../properties";
import PreviewRepositoryInfo from '../../../../common/queryBuilder/infos/PreviewRepositoryInfo';
import BackupInfo from '../../../../common/queryBuilder/infos/BackupInfo';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { fetchAllAutoSavedSearches, storeBackupSearch } from "../../helpers/savedSearches";
import { checkResultAndGetPayload } from "../../../../../api";
import { isArrayEmpty } from "../../../util";
import { Toast } from "primereact/toast";
import { DEFAULT_REPO_NAME } from "../../helpers/constants";
import { setAdvancedSearch3FormContent, setPreviewRepository } from "../../../../../redux/features/queryBuilder/actions";
import { filterDefinitionsSelector } from "../../../../../redux/common/filters/selectors/filters";


const SearchGroupsHeader = ({
    repositories,
    onSelectSavedSearch
}) => {

    const growl = useRef(null);

    const searchGroups = useSelector(state => state.advancedSearch3Reducer.searchGroups);
    const editGroup = useSelector(state => state.advancedSearch3Reducer.searchGroupToEdit);
    const filterDefinitions = useSelector(filterDefinitionsSelector);
    const previewRepository = useSelector(state => state.advancedSearch3Reducer.previewRepository);
    const dispatch = useDispatch();

    const [backupSearches, setBackupSearches] = useState();
    const [backupSearchID, setBackupSearchID] = useState();


    useEffect(() => {
        fetchAllBackupSearches();
    }, []);

    useEffect(() => {
        const defaultRepoID = !isArrayEmpty(repositories) && repositories.find(repo => repo.name === DEFAULT_REPO_NAME)?.id || repositories[0]?.id;
        dispatch(setPreviewRepository(defaultRepoID));
    }, [repositories]);

    useEffect(() => {
        // TODO: save search if it contains errors? empty search should not be saved -> also check for empty queryTerms?
        if (!isArrayEmpty(searchGroups)) {
            updateBackupSearch();
        }
    }, [searchGroups]);


    const fetchAllBackupSearches = async () => {
        const response = await fetchAllAutoSavedSearches();
        const allBackupSearches = checkResultAndGetPayload(response, growl);

        setBackupSearches(allBackupSearches);
    }

    const updateBackupSearch = async () => {
        const response = await storeBackupSearch(searchGroups, filterDefinitions, backupSearchID);
        const searchID = checkResultAndGetPayload(response, growl);

        setBackupSearchID(searchID);
    }


    return <>
        <Toast ref={growl} />

        <div className="flex align-items-center justify-content-between">

            <div className="flex align-items-center">
                <SavedSearchesLink
                    label="Load a saved search"
                    allowedQueryTypes={[QUERY_TYPE_ADVANCED_SEARCH_3.id]}
                    onSearchSelect={(savedSearch) => onSelectSavedSearch(savedSearch, true)}
                />

                <Dropdown
                    value={null}
                    options={backupSearches}
                    optionLabel="label"
                    onChange={(e) => { dispatch(setAdvancedSearch3FormContent(e.value?.searchGroups || [])) }}
                    showClear={false}
                    placeholder='Load from backup history'
                    appendTo={document.body}
                    className='dropdownNoBorder ml-5'
                    emptyMessage='No backups found'
                />
                <BackupInfo />
            </div>

            <div className="flex align-items-center justify-content-end">
                <label htmlFor='previewRepo'>Preview number of results from:</label>
                <Dropdown
                    id='previewRepo'
                    value={previewRepository}
                    options={repositories}
                    optionValue="id"
                    onChange={(e) => { dispatch(setPreviewRepository(e.value)) }}
                    filter
                    disabled={!!editGroup || isArrayEmpty(searchGroups)}
                    placeholder='Preview repository' />
                <PreviewRepositoryInfo />
            </div>
        </div>
    </>
}

export default SearchGroupsHeader;