/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
//import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import { videos, topics } from '../../../properties/videos'
// TODO: remove or replace with other library
//import { pdfjs } from 'react-pdf/';

class TutorialsContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTopic: 'all',
      selectedTopicLabel: 'All topics',
      activeVideo: undefined,
      activeIndex: 0,
      numPages: null,
      pageNumber: 1,
      loaded: false
    }
    this.videoRefs = []
  }

  componentDidMount() {
    // TODO: remove or replace with other library
    //pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    this.tutorialData = {}
    if (localStorage && localStorage.tutorialData !== undefined) {
      //console.log(localStorage.tutorialData)
      this.tutorialData = JSON.parse(localStorage.tutorialData)
      //console.log(this.tutorialData)
      if (this.tutorialData.section === "advanced" && this.tutorialData.title === "manual") {
        this.setState({
          selectedTopic: this.tutorialData.section,
          selectedTopicLabel: this.tutorialData.label,
          activeIndex: 1
        })
      }
      else {
        this.setState({
          selectedTopic: this.tutorialData.section,
          selectedTopicLabel: this.tutorialData.label,
          activeVideo: this.tutorialData.title
        })
      }
      localStorage.removeItem('tutorialData')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedTopic !== prevState.selectedTopic && this.state.activeVideo !== undefined) {
      // scrollToComponent(this.videoRefs[this.state.activeVideo], { align: 'top', offset: -110, duration: 1000 });
    }
  }

  handleSectionChange = (e, section) => {
    this.setState({
      selectedTopic: section.value,
      selectedTopicLabel: section.label,
      activeVideo: undefined,
      activeIndex: 0
    }, () => {
      this.stopAllYouTubeVideos()
      //scrollToComponent(this.top, { align: 'top', offset: -110, duration: 500 })
    })
  }

  stopAllYouTubeVideos = () => {
    var iframes = document.querySelectorAll('iframe');
    Array.prototype.forEach.call(iframes, iframe => {
      iframe.contentWindow.postMessage(JSON.stringify({
        event: 'command',
        func: 'stopVideo'
      }), '*');
    });
  }

  setActiveIndex = (index) => {
    this.setState({
      activeIndex: index,
      activeVideo: undefined
    }, () => {
      this.stopAllYouTubeVideos()
      //scrollToComponent(this.top, { align: 'top', offset: -110, duration: 500 })
    })
  }

  onLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages: numPages,
      loaded: true
    })
  }

  changePage = offset => this.setState(prevState => ({
    pageNumber: prevState.pageNumber + offset,
  }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  render() {

    let sections = topics && topics.map(top => {
      return <div key={top.value} className='col-12' style={{
        display: 'inline-block', paddingBottom: 10, paddingLeft: 0, borderBottom: this.state.selectedTopic === top.value ? '1px solid #757575' : '1px solid #d8d8d8',
        backgroundColor: this.state.selectedTopic === top.value && '#e8f3fc', marginTop: -3, cursor: 'pointer'
      }} onClick={(e) => this.handleSectionChange(e, top)}>
        <a onClick={(e) => this.handleSectionChange(e, top)}
          style={{
            float: 'left', marginBottom: 5, marginLeft: 5, marginTop: 7,
            color: 'black', fontWeight: this.state.selectedTopic === top.value ? 'bold' : 'normal'
          }}>{top.label}</a>
        <div style={{ display: 'inline-block', float: 'right', marginRight: 0, cursor: 'pointer', marginTop: 5 }} onClick={(e) => this.handleSectionChange(e, top)}>
          <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleSectionChange(e, top)}>{top.numberVideos}</label>
        </div>
      </div>
    })


    let renderedVideos = videos && videos.map(vid => {
      if (vid.section.includes(this.state.selectedTopic)) {
        return <div ref={videoRef => this.videoRefs[vid.title] = videoRef} key={vid.title} className='col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6' style={{ paddingLeft: 0, height: "55vh" }}>
          <Card className='YTcard' title={vid.title} style={{ border: '1px solid #e4e4e4', height: '100%' }} footer={vid.time}>
            <iframe width="100%" height="100%" src={vid.title === this.state.activeVideo ? `${vid.url}?enablejsapi=1&&autoplay=1` : `${vid.url}?enablejsapi=1`} title="YouTube video player" onClick={(e) => this.onCardClick(e, vid)}
              frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </Card>
        </div>
      }
    })

    // console.log(this.state.selectedTopic)

    return (
      <div className="grid">
        <div className='col-12' style={{ float: 'left', padding: 0 }}>
          <div className='col-12' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 style={{ marginTop: 0, marginLeft: -5 }} className="pageHeader">Video Tutorials</h1>
          </div>
          <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10" style={{ paddingLeft: 0, paddingBottom: 25, marginTop: -10 }}>
            <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal' }}>This page contains introductory video tutorials meant to support onboarding as well
              as advanced materials demonstrating specific use cases.
            </label>
          </div>
          <div ref={top => this.top = top} className='grid col-12' style={{ paddingLeft: 0, paddingTop: 0 }}>
            <div className='col-6 sm:col-6 md:col-4 lg:col-3 xl:col-2' style={{ paddingLeft: 0, borderRight: '1px solid #d6d6d6', paddingTop: 0, paddingRight: 10 }}>
              <div className='col-12'>
                <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0 }}>
                  <h1 style={{ marginTop: 0, fontSize: '1.1em', float: 'left', marginBottom: 10 }} className="pageHeader">Topics</h1>
                </div>
                <div className='col-12' style={{ paddingTop: 0, paddingRight: 15, paddingLeft: 0 }}>
                  {sections}
                </div>
              </div>
            </div>
            <div className='col-6 sm:col-6 md:col-8 lg:col-9 xl:col-10' style={{ paddingTop: 0, paddingLeft: 15 }}>
              <div className="col-12 grid-nogutter">
                <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0, marginBottom: 0 }}>
                  <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 5, paddingTop: 0 }}>
                    <div className='col-12 changeflex' style={{ display: 'flex', paddingBottom: 0, paddingLeft: 0 }}>
                      <div style={{}}>
                        <label style={{
                          marginTop: -1, fontSize: '1.1em', marginRight: 25, fontWeight: 'bold',
                          overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', width: '100%'
                        }}
                          title={this.state.selectedTopicLabel}>
                          {this.state.selectedTopicLabel}</label>
                      </div>
                    </div>
                    {/*this.state.selectedTopic !== "advanced" ?*/}
                    <div className='grid' style={{ paddingLeft: 5, marginTop: 2 }}>
                      {renderedVideos}
                    </div>
                    {/*:
                      <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setActiveIndex(e.index)}>
                        <TabPanel header="Tutorials">
                          <div className='grid' style={{ paddingLeft: 5, marginTop: 2 }}>
                            {renderedVideos}
                          </div>
                        </TabPanel>
                        <TabPanel header="Manual">
                          <div className='grid col-12' style={{ paddingLeft: 5, marginTop: 2, justifyContent: 'center' }}>
                            <Document
                              file={pdfManual}
                              onLoadSuccess={this.onLoadSuccess}>
                              <Page pageNumber={this.state.pageNumber} />
                            </Document>
                            {this.state.loaded ?
                              <div className='col-12 grid' style={{paddingTop: 0, justifyContent: 'center' }}>
                                <p>Page {this.state.pageNumber} of {this.state.numPages}</p>
                                <div style={{ paddingLeft: 10, marginTop: 9 }}>
                                  <Button
                                    className="p-button-text primaryButtonLink"
                                    //type="button"
                                    icon="pi pi-angle-left"
                                    label='Previous'
                                    disabled={this.state.pageNumber <= 1}
                                    onClick={this.previousPage}>
                                  </Button>
                                  <Button
                                    //type="button"
                                    className="p-button-text primaryButtonLink"
                                    icon="pi pi-angle-right"
                                    iconPos="right"
                                    label='Next'
                                    disabled={this.state.pageNumber >= this.state.numPages}
                                    onClick={this.nextPage}>
                                  </Button>
                                </div>
                              </div>
                              : null}
                          </div>
                            </TabPanel>
                      </TabView>}*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TutorialsContainer;