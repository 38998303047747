import { TabPanel, TabView } from "primereact/tabview";

const SearchCriteriaHelp = () => {
    return (
        <TabView>
            <TabPanel header="Search mode">
                <div className="query-examples">
                    <div className="paragraph">
                        There are two different search modes for concepts:
                        <ul>
                            <li>ontologically, with synonyms: includes synonyms and subclasses</li>
                            <li>concept only, with synonyms: includes synonyms only</li>
                        </ul>
                        There are two different search mode visibility ranges:
                        <ul>
                            <li>globally: valid for all concepts in the query</li>
                            <li>locally: valid for query terms within same group and subgroups</li>
                        </ul>
                    </div>
                    <h4>Globally (smode)</h4>
                    <div className="paragraph">
                        Setting is valid for all concepts in the query and should be defined only once within a query.
                        It can be placed anywhere and may be used together with local search mode (cmode).
                        <div className="query-example">smode:"o" chem:"monoterpenes"</div>
                        <div className="query-example-info">finds "monoterpenes" and all synonyms of this concept, as well as its ontological subclasses (default).</div>
                        <div className="query-example">chem:"monoterpenes" smode:"s"</div>
                        <div className="query-example-info">finds "monoterpenes" and all synonyms of this concept, but no ontological subclasses.</div>
                    </div>
                    <h4>Locally (cmode)</h4>
                    <div className="paragraph">
                        It can be defined multiple times and is valid for the following query terms within the same group
                        and for subgroups. It ranges until another "cmode" is defined or the group where it is defined ends.
                        <div className="query-example">+smode:"s" +diseases:"inflammation" +(cmode:"o" chem:"steroids")</div>
                        <div className="query-example-info">finds "inflammation" and its synonyms, but no subclasses (global setting)
                            together with "steroids" and all of its synonyms, as well as all concepts classified as steroids (group setting).</div>
                    </div>
                    <h4>Ontologically, with synonyms ("o", default)</h4>
                    <div className="paragraph">
                        Finds the search term, as well as all synonyms of this concept and all synonyms of ontological subclasses.
                        <div className="query-example">+smode:"o" +chem:"steroids" +diseases:"inflammation"</div>
                        <div className="query-example-info">finds "steroids" together with "inflammation", all of their synonyms, as well as all concepts classified as steroids or inflammation.</div>
                        <div className="query-example">+cmode:"o" +chem:"steroids" +cmode:"s" +diseases:"inflammation"</div>
                        <div className="query-example-info">finds "steroids", its synonyms, as well as all concepts classified as steroids together with "inflammation" and all of its synonyms,
                            but no ontological subclasses (ontological search mode ends with next search mode occurrence).</div>
                    </div>
                    <h4>Concept only, with synonyms ("s")</h4>
                    <div className="paragraph">
                        Finds the search term, as well as all synonyms of this concept, but no ontological subclasses.
                        <div className="query-example">+smode:"s" +chem:"steroids" +diseases:"inflammation"</div>
                        <div className="query-example-info">finds "steroids" together with "inflammation" and all of their synonyms, but no ontological subclasses.</div>
                        <div className="query-example">+chem:"steroids" +(cmode:"s" diseases:"inflammation")</div>
                        <div className="query-example-info">finds "steroids", its synonyms, as well as all concepts classified as steroids together with "inflammation" and its synonyms, but no concepts classified as inflammation.</div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Search in">
                <div className="query-examples">
                    <div className="query-examples-intro">
                        Provides the option to limit the search to certain document sections,
                        e.g. to search in "Title, Abstract and Claims" only.
                        It can be defined multiple times and is valid for the following query terms within same group
                        and for subgroups. It ranges until another "termloc" is defined or the group where it is defined ends.
                    </div>

                    <h4>General sections</h4>
                    <div className="paragraph">
                        General sections are available for different repository types, e.g. tilte or abstract.
                        <div className="query-example">+termloc:"doc" +chem:"steroids" +termloc:"title" +diseases:"inflammation"</div>
                        <div className="query-example-info">searches for "steroids" in whole document (default), but for "inflammation" only in the title.</div>
                        <div className="query-example">+(termloc:"title" t:"thrombosis" termloc:"ta" t:"embolism") -(t:"pulmonary hypertension" t:"catheter")</div>
                        <div className="query-example-info">searches all documents which have "thrombosis" mentioned in the title or "embolism" mentioned in the
                            title or abstract but don't have "pulmonary hypertension" or "catheter mentioned" in the whole document.</div>
                    </div>
                    <h4>Source-specific sections</h4>
                    <div className="paragraph">
                        Some sections are only available for patents, publications, clinical trials etc.
                        <div className="query-example">+termloc:"tac" +diseases:"inflammation" </div>
                        <div className="query-example-info">searches for "inflammation" in the title, abstract or claims section of patents.</div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Distance">
                <div className="query-examples">
                    <h4>Concepts</h4>
                    <div className="paragraph">
                        You can define a maximum distance between concepts,
                        i.e. it restricts the number of non-matching terms between matching concepts.
                        The scope starts right after the distance term and ranges until the end of its group. If it is defined outside of any group,
                        it reaches until the end of the query.
                    </div>
                    <div className="paragraph">
                        Within the distance scope, please follow these rules:
                        <ul>
                            <li>Only concepts can be specified (by OCID or domain/term).</li>
                            <li>Text or any other terms are not allowed.</li>
                            <li>Exception: "Search in" terms (termloc) are allowed directly after the distance term.</li>
                            <li>Only MUST or MUST NOT concept terms and groups are allowed (see help for "Logic").</li>
                            <li>Within MUST NOT groups only SHOULD concept terms are allowed (see help for "Logic").</li>
                            <li>The boost value (^FACTOR) of the nearby query term adjusts the scoring of the nearby expression (see help for "Logic").</li>
                            <li>It is allowed to change the concept search mode (cmode).</li>
                            <li>It is recommended to wrap the nearby and its concept terms within a group (enclosed in parentheses) and specify the occurrence (+, - or nothing) at the opening parenthesis of the group.</li>
                        </ul>
                    </div>
                    <div className="paragraph">
                        <div className="query-example">+(nearby:"50" +chem:"monoterpenes" +(diseases:"cancer" diseases:"Neoplasm malignant") <span className="whiteSpaceNoWrap">-diseases:"breast cancer")</span></div>
                        <div className="query-example-info">matches monoterpenes and cancers (but not breast cancers) within a distance of 20 terms.</div>

                        <div className="query-example">(nearby:"20"^2 +chem:"monoterpenes" +diseases:"inflammation")</div>
                        <div className="query-example-info">will increase the importance of this nearby group for the ranking of the documents.</div>

                        {/*Allows you to define a requirement for the distance between concepts. This distance is
                        expressed rather in approximate characters. Please use the distance criterion before
                        defining the query terms. This is a global search property and accounts for all concepts in a
                        query.
                        <div className="query-example">near:"filter"^10 (+chem:"monoterpenes" +methods:"synthesis" +methods:"extraction")</div>
                        <div className="query-example-info">matches "... carveol ... chemical synthesis ... extraction ..." with a distance of 70 characters between 2 of
                        these 3 concepts in all combinations.</div>*/}
                        <div className="query-note">
                            If the distance should be applied to all concepts the nearby:"DISTANCE" can be replaced by the global near:"filter"^DISTANCE.
                        </div>
                    </div>
                    <h4>Text exact</h4>
                    <div className="paragraph">
                        The text distance function works on text using the
                        syntax: <span className="whiteSpaceNoWrap">tr:"TERM_OR_PHRASE"~DISTANCE</span>. The distance defines the maximum distance of words. 
                        Without the ~ it matches only in the exact word order.
                        <div className="query-example">tr:"progressive fibrosis"~5</div>
                        <div className="query-example-info">will find e.g. "progressive fibrosis", "pregressive renal fibrosis", "progressive renal
                            and pulmonary fibrosis", "fibrosis being progressive"</div>
                    </div>
                    <h4>Text with variants</h4>
                    <div className="paragraph">
                        The text distance function works on stemmed text using the
                        syntax: <span className="whiteSpaceNoWrap">t:"TERM_OR_PHRASE"~DISTANCE</span>. The distance defines the maximum distance of words. 
                        Without the ~ it matches only in the exact word order.
                        <div className="query-example">t:"matrix amorph"~2</div>
                        <div className="query-example-info">will find e.g. "amorphous matrix", "amorphous ceramic matrix", "amorphous host matrix" and
                            "matrix was amorphous".</div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    );
}

export default SearchCriteriaHelp;