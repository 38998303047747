import './RepositoryOverview.css';
import React, { Component } from 'react';
import { addThousandsSeparatorToNumber, hasUserRole, isArrayEmpty, isObjectEmpty } from '../util';
import { REPO_STATE_ERROR, REPO_STATE_LOADING } from '../chemistry/utils/searchResults';
import ProgressBarMini from '../loader/ProgressBarMini';
import readcubeIcon from '../../../assets/images/icons/readcube.png'
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
import moment from 'moment';

class RepositoryOverview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            selectedControledRepo: null
        };
    }

    componentDidMount() {

        this.updateRepositoryData(this.props);
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if ((nextProps.repoStatisticsResults !== this.props.repoStatisticsResults) || (nextProps.readcubeRequests !== this.props.readcubeRequests)) {
            this.updateRepositoryData(nextProps);
        }
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {

        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    updateRepositoryData = (props) => {

        let reposSorted = props.repoStatisticsResults?.repData ? Object.values(props.repoStatisticsResults.repData) : null;

        const emptyResults = [];
        if (reposSorted) {
            Object.values(reposSorted).forEach(repData => {
                if (repData.qryCounts.cust <= 0) {
                    emptyResults.push(repData.label);
                }
            });
        }

        if (props.readcubeRequests && !isArrayEmpty(props.readcubeRequests)) {
            props.readcubeRequests.forEach(req => {
                if (req.status !== 'FINISHED' && req.status !== 'UPDATING') {
                    reposSorted = [...reposSorted, {
                        label: req.label,
                        name: req.repositoryName,
                        numberDocuments: req.numberDocuments,
                        numberDocumentsFinished: req.numberProcessedDocuments,
                        repoDescription: req.status === 'REQUESTED' ? 'Requested' :
                            req.status === 'FAILED' ? `Importing failed: ${req.message}` : '',
                        repositoryId: req.repositoryId,
                        status: req.status,
                        message: req.message
                    }]
                }
            })
        }

        reposSorted?.forEach(repo => {
            if (this.props.readcubeRequests?.some(rep => rep.repositoryName === repo.name && rep.status === 'UPDATING' && rep.numberProcessedDocuments && rep.numberDocuments)) {
                repo.numberDocuments = this.props.readcubeRequests.filter(rep => rep.repositoryName === repo.name).map(rep => rep.numberDocuments)
                repo.numberDocumentsFinished = this.props.readcubeRequests.filter(rep => rep.repositoryName === repo.name).map(rep => rep.numberProcessedDocuments)
            }
        })

        this.setState({
            reposSorted: reposSorted,
            emptyResults: emptyResults,
        });
    }

    showRepoControls = (e, id) => {
        this.setState({
            selectedControledRepo: id
        })
    }

    hideRepoControls = (e) => {
        this.setState({
            selectedControledRepo: null
        })
    }

    render() {
        const { fetching, selectedRepository, headerText } = this.props;

        return (
            <>
                <div className="grid" style={{ marginTop: 0 }}>
                    <div className="col-12 textAlignLeft secondaryInfo"
                        style={{ padding: '10px 0px 0px 0px' }}>
                        {headerText ?
                            <span id="docSearchHits">{`${headerText}`}</span> : null}
                    </div>
                </div>

                <div className='grid _p-justify-between'
                    style={{ marginTop: 5, borderTop: '1px solid #000000' }}>
                    {!isObjectEmpty(this.state.reposSorted) ?
                        <React.Fragment>
                            {Object.values(this.state.reposSorted).map(repData => {
                                const isSelRepo = repData.repositoryId === selectedRepository;
                                const status = repData.status || "";
                                const isFetching = status === REPO_STATE_LOADING;
                                const hasHits = repData.qryCounts?.cust > 0;
                                const updating = this.props.readcubeRequests?.some(rep => rep.repositoryName === repData.name && rep.status === 'UPDATING')
                                const failed = this.props.readcubeRequests?.some(rep => rep.repositoryName === repData.name && rep.status === 'FAILED')
                                const isLoading = this.props.readcubeRequests?.some(rep => rep.repositoryName === repData.name && rep.status !== 'FINISHED' && rep.status !== 'UPDATING' && rep.status !== 'FAILED')
                                const repositoryInfo = {
                                    id: repData.repositoryId,
                                    name: repData.name,
                                    label: repData.label
                                };
                                const clickLabel = hasHits && !isLoading ? ` - Click to load documents.` : '';
                                const tooltip = status === REPO_STATE_ERROR ? repData.errorMessage ? repData.errorMessage : 'Error occurred' :
                                    repData.repoDescription ? `${repData.repoDescription}${clickLabel}` : `${repData.label}${clickLabel}`;

                                return (
                                    <div id={repData.name}
                                        className="col-6 sm:col-6 md:col-4 lg:col-3 xl:col-2" // col-6 sm:col-6 md:col-4 lg:col-3 xl:col-2
                                        key={repData.repositoryId}
                                        style={{
                                            position: 'relative',
                                            top: -1,
                                            paddingLeft: 10,
                                            paddingRight: hasHits && repData?.status === 'READY' ? 0 : '',
                                            background: isSelRepo ? '#E8F3FC' : '#FFFFFF',
                                            borderTop: isSelRepo ? '3px solid #000000' : '1px solid #000000',
                                            paddingTop: isSelRepo ? 0 : 2,
                                            cursor: !isFetching && !isSelRepo && hasHits && !isLoading ? 'pointer' : '',
                                            color: (isSelRepo || hasHits) && !isLoading ? 'black' : '#757575'
                                            //opacity: (isSelRepo || hasHits) && !isLoading ? 1 : 0.3,
                                        }}
                                        onMouseEnter={(e) => this.showRepoControls(e, repData.repositoryId)} onMouseLeave={(e) => this.hideRepoControls(e)}
                                        onClick={() => {
                                            if (!isFetching && !isSelRepo && hasHits && !isLoading && repData?.status !== 'READY') {
                                                this.props.onRepositoryClick(repositoryInfo);
                                            }
                                        }}
                                        title={tooltip}
                                    >
                                        <div style={{ color: `${isLoading || !hasHits ? '#757575' : ''}`, fontWeight: `${isSelRepo ? 'bold' : 'normal'}`, padding: 10, paddingLeft: 0, paddingRight: hasHits && repData?.status === 'READY' ? 0 : '' }}>
                                            <div style={{ textTransform: 'uppercase', fontSize: 15 }}>
                                                {status === REPO_STATE_ERROR ?
                                                    <span style={{ textDecoration: 'line-through' }}>{repData.label}</span>
                                                    :
                                                    <>
                                                        {this.props.readcubeRequests?.some(rep => rep.repositoryName === repData.name) || repData.name?.startsWith("rc_") ?
                                                            <span>
                                                                <img src={readcubeIcon} alt="ReadCube papers"
                                                                    style={{ height: 17, paddingRight: 5 }} />
                                                            </span>
                                                            : null
                                                        }
                                                        <span>{repData.label}</span>
                                                    </>
                                                }
                                            </div>
                                            <div style={{ fontSize: 13 }}>
                                                {status === REPO_STATE_ERROR ?
                                                    <span>Not available.</span>
                                                    :
                                                    status === REPO_STATE_LOADING ?
                                                        <>
                                                            <span className="repo-progress-container">
                                                                <ProgressBarMini height='2px' />
                                                            </span>
                                                            <div>Searching...</div>
                                                        </>
                                                        :
                                                        hasHits && !isLoading && !this.props.readcubeRequests?.some(rep => rep.repositoryName === repData.name) ?
                                                            <span>{addThousandsSeparatorToNumber(repData.qryCounts.cust)}</span>
                                                            : !isLoading && this.props.readcubeRequests?.some(rep => rep.repositoryName === repData.name) ?
                                                                <>
                                                                    <span>{repData.qryCounts?.cust !== -1 ? addThousandsSeparatorToNumber(repData.qryCounts?.cust) : 0}</span>
                                                                    {updating && repData?.numberDocumentsFinished && repData?.numberDocuments ?
                                                                        <span style={{ paddingLeft: 10 }}>{`(Updating ${addThousandsSeparatorToNumber(repData?.numberDocumentsFinished)} of ${addThousandsSeparatorToNumber(repData?.numberDocuments)} documents)`}</span>
                                                                        : updating && (!repData?.numberDocumentsFinished || !repData?.numberDocuments) ?
                                                                            <span style={{ paddingLeft: 10 }}>{`(Updating...)`}</span>
                                                                            : !updating && failed ?
                                                                                <>
                                                                                    <span style={{ paddingLeft: 0 }}>Failed</span>
                                                                                    {repData?.message ?
                                                                                        <a className="infoIconLink"
                                                                                            style={{ marginTop: 0, cursor: 'default' }}
                                                                                            title={`Importing failed: ${repData.message}`}
                                                                                        //onClick={e => this.recallInfoOverlayMaximum.toggle(e)}
                                                                                        >
                                                                                            <img src={infoIcon} alt="Failed message."
                                                                                                style={{ marginLeft: 10, verticalAlign: 'middle' }} />
                                                                                        </a>
                                                                                        : null}
                                                                                </>
                                                                                : null
                                                                    }
                                                                    {this.state.selectedControledRepo === repData.repositoryId && !updating && !failed ?
                                                                        <span style={{ paddingLeft: 10 }}>{`(Updated: ${this.props.readcubeRequests?.filter(rep => rep.repositoryName === repData.name).map((obj) => obj.dataUpdated ? moment(obj.dataUpdated, 'DD-MM-YYYY').format('YYYY-MM-DD') : null)})`}</span>
                                                                        : null
                                                                    }
                                                                </>
                                                                : !hasHits && !isLoading ? '0' :
                                                                    <>
                                                                        <span className="repo-progress-container">
                                                                            <ProgressBarMini height='2px' />
                                                                        </span>
                                                                        {repData?.numberDocumentsFinished && repData?.numberDocuments ?
                                                                            <div>{`Imported ${addThousandsSeparatorToNumber(repData?.numberDocumentsFinished)} of ${addThousandsSeparatorToNumber(repData?.numberDocuments)} documents`}</div>
                                                                            : <div>Importing...</div>
                                                                        }
                                                                    </>
                                                }
                                            </div>
                                            {this.state.selectedControledRepo === repData.repositoryId && this.props.readcubeRequests?.some(rep => rep.repositoryName === repData.name) && !isLoading && !failed
                                                ?
                                                <span style={{ position: 'absolute', paddingTop: 2, bottom: 0, right: 5 }}>
                                                    <a style={{ float: 'right', paddingLeft: 5 }} onClick={(e) => this.props.onSyncReadcubeLibrary(e, repData.name)}
                                                        title={`Sync with ReadCube`}>Sync</a>
                                                    <span style={{ float: 'right', color: '#007fdb' }}>&#183;</span>
                                                    <a style={{ float: 'right', paddingRight: 5 }} onClick={(e) => this.props.onDeleteReadcubeLibrary(e, repData.name, repData)}
                                                        title={isLoading || failed ? `Delete request` : `Delete ReadCube library`}>{isLoading || failed ? `Delete request` : `Delete`}</a>
                                                </span>
                                                : this.state.selectedControledRepo === repData.repositoryId && this.props.readcubeRequests?.some(rep => rep.repositoryName === repData.name) && (failed || isLoading) ?
                                                    <span style={{ position: 'absolute', paddingTop: 2, bottom: 0, right: 5 }}>
                                                        <a style={{ float: 'right', paddingRight: 0 }} onClick={(e) => this.props.onDeleteReadcubeLibrary(e, repData.name, repData)}
                                                            title={isLoading || failed ? `Delete request` : `Delete ReadCube library`}>{isLoading || failed ? `Delete request` : `Delete`}</a>
                                                    </span>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    // : null
                                );
                            })}

                            {/*Add readcube repository*/}
                            {this.props.allowAddReadcubeLibrary && hasUserRole('ROLE_READCUBEVIEW') ?
                                <div className="col-6 sm:col-6 md:col-4 lg:col-3 xl:col-2" // col-6 sm:col-6 md:col-4 lg:col-3 xl:col-2
                                    style={{
                                        position: 'relative',
                                        top: -1,
                                        paddingLeft: 10,
                                        background: '#FFFFFF',
                                        borderTop: '1px solid #000000',
                                        paddingTop: 2,
                                        cursor: '',
                                        marginLeft: 0,
                                        textAlign: 'center'
                                    }}>
                                    <div style={{ padding: 10, paddingLeft: 0 }}>
                                        <span>
                                            <img src={readcubeIcon} alt="ReadCube papers"
                                                style={{ height: 17, paddingRight: 5 }} />
                                        </span>
                                        <span>
                                            <a onClick={(e) => this.props.onAddReadcubeLibrary(e)}
                                                title={`Add ReadCube library`}>Add ReadCube library</a>
                                        </span>
                                    </div>
                                </div>
                                : null}

                        </React.Fragment>
                        :
                        <div style={{ padding: 10 }}>
                            {fetching ? '' :
                                !isArrayEmpty(this.state.emptyResults) ?
                                    `No documents found in ${this.state.emptyResults.join(', ')}.` : ''}
                        </div>
                    }
                </div>
            </>
        )
    }
}
export default RepositoryOverview