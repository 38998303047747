import PropTypes from 'prop-types'

export const MatchNavigator = ({
    findAnnId,
    uniqueId = null
}) => {

    return (
        <>
            <span onClick={() => findAnnId(false, uniqueId)}>
                <i className='pi pi-angle-left'></i>
            </span>
            <span onClick={() => findAnnId(true, uniqueId)}>
                <i className='pi pi-angle-right'></i>
            </span>
        </>

    )
}

MatchNavigator.propTypes = {
    findAnnId: PropTypes.func,
    uniqueId: PropTypes.string
}
