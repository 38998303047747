import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    fetchTermSuggestions
} from '../../../redux/actions/WebApiActions'
import InferenceSearch from '../InferenceSearch/InferenceSearch'

const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    terms: state.webAPI.terms,
    userDetails: state.user.data.userDetails,
    userData: state.user.data,
    availableDomains: state.webAPI.availableDomains
})

const mapDispatchToProps = dispatch => ({
    fetchTermSuggestions: (history, search, showFailGrowlSuggestions) => dispatch(fetchTermSuggestions(history, search, showFailGrowlSuggestions))
})

const InferenceSearchContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(InferenceSearch))

export default InferenceSearchContainer