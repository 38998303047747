import { Checkbox } from "primereact/checkbox";
import { TieredMenu } from "primereact/tieredmenu";
import { useRef } from "react";


const OptionsCheckBox = ({
    selectItems = [],
    menuStyle = {},
    checkboxStyle = {},
    tooltip = ''
}) => {

    const menu = useRef(null);

    return <span>
        <span onClick={(event) => menu.current.toggle(event)}>
            <Checkbox
                id="selectAllMatches"
                // onChange={(e) => setHideSelected(e.checked)}
                // checked={hideSelected}
                checked={true}
                // disabled={true}
                title={tooltip}
                style={{ cursor: 'pointer', ...checkboxStyle }}
            ></Checkbox>
            {/* <i className="pi pi-fw pi-check-square"
                style={{ position: 'relative', top: 2 }}
                title={tooltip}
            ></i> */}
        </span>

        <TieredMenu
            ref={menu}
            model={selectItems}
            popup
            style={{ ...menuStyle }}
        />
    </span>
}

export default OptionsCheckBox;