import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class ResendVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resendVerificationChecked: false
        }
    }

    onCheckResendVerification = (e) => {
        if (e.checked) {
            this.setState({
                resendVerificationChecked: true
            })
        } else {
            this.setState({
                resendVerificationChecked: false
            })
        }
    }

    onResendVerification = () => {
        this.props.onResendVerification(this.props.selectedUserID)
    }

    render() {

        const footerResendVerification = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Cancel"
                className='p-button-sm p-button-secondary'
                    onClick={this.props.onCancelResendVerification}
                />
                <Button label="Send"
                className='p-button-sm primaryButton'
                    disabled={this.state.resendVerificationChecked ? false : true}
                    onClick={this.onResendVerification}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayResendVerification} style={{ 'width': "40vw" }} header="Resend verification email" focusOnShow={false}
                modal={true} footer={footerResendVerification} dismissableMask={false}
                onHide={() => this.props.onCancelResendVerification()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div style={{ marginBottom: 10 }}>
                        <label>{`This will resend a verification email to user '${this.props.selectedUserName}, ${this.props.selectedUserForename}' with email address 
                        '${this.props.selectedUserEmail}'.`}</label>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <Checkbox inputId='resendVerificationChecked'
                            onChange={(e) => this.onCheckResendVerification(e)}
                            checked={this.state.resendVerificationChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                        />
                        <label htmlFor='resendVerificationChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default ResendVerification