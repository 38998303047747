import "./DomainColorBar.css";

const DomainColorBar = ({
    color = '#000000',
    size = '',
    style = {},
    styleClass = '',
}) => {

    let sizeClass;
    switch (size) {
        case 'small':
        case 'sm':
            sizeClass = 'small';
            break;
        case 'medium':
        case 'md':
            sizeClass = 'medium';
            break;
        case 'large':
        case 'lg':
            sizeClass = 'large';
            break;
        default:
            sizeClass = '';
    }

    return <div className={`domain-color-bar ${sizeClass} ${styleClass}`}
        style={{ borderColor: color, background: color, ...style }}>
    </div>;
}
export default DomainColorBar;