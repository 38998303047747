import { Component } from 'react';
import { COMPOUND_PROPERTIES } from './compoundProperties';
import PropertiesList from './PropertiesList';

export function getFilteredProperties(extendedCompoundData) {
    const extraInfoFiltered = COMPOUND_PROPERTIES.filter(ei =>
        ei.isSecondaryProperty &&
        extendedCompoundData &&
        !!extendedCompoundData[ei.id] &&
        (!ei.checkFunction || !!ei.checkFunction(extendedCompoundData[ei.id])));
    return extraInfoFiltered;
}

export function getAllProperties(extendedCompoundData) {
    const extraInfoFiltered = COMPOUND_PROPERTIES.filter(ei =>
        (ei.isPrimaryProperty || ei.isSecondaryProperty) &&
        extendedCompoundData &&
        !!extendedCompoundData[ei.id] &&
        (!ei.checkFunction || !!ei.checkFunction(extendedCompoundData[ei.id])));
    return extraInfoFiltered;
}

class CompoundProperties extends Component {

    render() {

        const { extendedCompoundData } = this.props;
        const extraInfoFiltered = getFilteredProperties(extendedCompoundData);
        const extraInfoAll = getAllProperties(extendedCompoundData);

        return (
            <PropertiesList
                propertiesDefinitionsFiltered={extraInfoFiltered}
                propertiesDefinitionsAll={extraInfoAll}
                compoundProperties={extendedCompoundData}
                cutOff={3}
            //showProperties={['hydrogenDonorCount', 'solubility'] } // filtered properties
            />
        )
    }
}
export default CompoundProperties