import React, { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";
import DateRangeFilterText from "./DateRangeFilterText";


class GeneralDateRangeFilterInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID={this.props.infoID}
                title="Click for information about date range input"
                imageAltText="Date range input information"
                overlayContent={
                    <DateRangeFilterText />
                }
            />
        );
    }
}

export default GeneralDateRangeFilterInfo;