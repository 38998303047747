import React from "react";

const SearchGroupRow = ({ children, label }) => {
  return (
    <>
      <div className="col-fixed" style={{ width: 150 }}>
        {label}
      </div>
      <div className="col">
          {children}
      </div>
      <div className="breakRow" />
    </>
  );
};
export default SearchGroupRow;
