import * as ActionTypes from '../actions'
//import { Router, Route } from 'react-router-dom'

const initialState = {
  activeSearchTerm: []
}

const pdfViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CHANGE_ACTIVE_SEARCH_TERM_COLOR:
            return {
                ...state,
                activeSearchTerm: action.activeSearchTerm
            }
        default:
            return state
    }
}

export default pdfViewReducer
