/* eslint-disable jsx-a11y/anchor-is-valid */
import './SearchInput.css';
import AutoComplete from '../AutoComplete/AutoComplete';
import InputFieldWithClear from '../../webapi/general/inputfields/InputFieldWithClear';
import { isArrayEmpty } from '../../webapi/util';

const SearchInput = ({
    queryTerms,
    handleInputChange,
    suggestions,
    completeMethod,
    searchTermTemplate,
    suggestionTemplate,
    handleInputSelect
}) => {

    return (
        <InputFieldWithClear
            onClear={() => handleInputChange([])}
            showIcon={!isArrayEmpty(queryTerms)}>
            <AutoComplete
                value={queryTerms}
                className="width100perc increasedHeight sciwalker-autocomplete"
                field="term"
                suggestions={suggestions}
                completeMethod={completeMethod}
                placeholder='Enter semantic concept'
                minLength={1}
                multiple
                onChange={e => handleInputChange(e.value)}
                onSelect={e => handleInputSelect(e.value)}
                selectedItemTemplate={searchTermTemplate}
                itemTemplate={suggestionTemplate}
                autoHighlight={true}
            />
        </InputFieldWithClear>
    )
}

export default SearchInput;
