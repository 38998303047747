import React, { Component } from 'react'
import { getHeaderToken, getHeaderTokenText } from '../../api/index'
import axios from 'axios'
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { hasUserRole } from '../../components/webapi/util';
import { APP_PROPERTIES } from '../../properties/index';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as Actions from '../../redux/actions'

class EditRegularUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userForename: '',
            userRole: '',
            userActive: false,
            userEmail: '',
            userUsername: '',
            userPassword: '',
            userPasswordRepeated: '',
            userForceUpdatePassword: false,
            type: 'password',
            typeRepeated: 'password'
        }
    }

    componentDidMount() {
        let userName = this.props.userDetails.lastName ? this.props.userDetails.lastName : ''
        let userForename = this.props.userDetails.forename ? this.props.userDetails.forename : ''
        let userRole = this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") ? 'ROLE_SUPPORT_ADMIN' : this.props.userDetails.highestAdminType
        let userActive = this.props.userDetails.deactivated === false ? true : false
        let userEmail = this.props.userDetails.email ? this.props.userDetails.email : ''
        let userUsername = this.props.userDetails.username ? this.props.userDetails.username : ''

        this.setState({
            userName: userName,
            userForename: userForename,
            userRole: userRole,
            userActive: userActive,
            userEmail: userEmail,
            userUsername: userUsername
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.userDetails !== prevProps.userDetails) {
            let userName = this.props.userDetails.lastName ? this.props.userDetails.lastName : ''
            let userForename = this.props.userDetails.forename ? this.props.userDetails.forename : ''
            let userRole = this.props.userDetails.highestAdminType ? this.props.userDetails.highestAdminType : ''
            let userActive = this.props.userDetails.deactivated === false ? true : false
            let userEmail = this.props.userDetails.email ? this.props.userDetails.email : ''
            let userUsername = this.props.userDetails.username ? this.props.userDetails.username : ''

            this.setState({
                userName: userName,
                userForename: userForename,
                userRole: userRole,
                userActive: userActive,
                userEmail: userEmail,
                userUsername: userUsername
            })
        }
    }

    handleUserRoleChange = (e) => {
        this.setState({
            userRole: e.value
        })
    }

    onCheckForceUpdatePassword = (e) => {
        if (e.checked) {
            this.setState({
                userForceUpdatePassword: true
            })
        } else {
            this.setState({
                userForceUpdatePassword: false
            })
        }
    }

    handleShowHide = () => {
        if (this.state.type === 'input') {
            this.setState({
                type: 'password'
            })
        } else {
            this.setState({
                type: 'input'
            })
        }
    }

    handleShowHideRepeated = () => {
        if (this.state.typeRepeated === 'input') {
            this.setState({
                typeRepeated: 'password'
            })
        } else {
            this.setState({
                typeRepeated: 'input'
            })
        }
    }

    onEditRegularUser = () => {
        this.editRegularUser(this.state.userPassword, this.state.userPasswordRepeated)
    }

    editRegularUser = async (newUserPassword, newUserPasswordRepeated) => {
        if (newUserPassword !== newUserPasswordRepeated) {
            this.showPasswordWarnGrowl()
        } else {
            var self = this;
            let encodedPassword = btoa(newUserPassword);
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/user/password`, encodedPassword, { headers: getHeaderTokenText() })
                .then(function (response) {
                    self.showEditRegularUserSuccessGrowl()
                    self.setState({
                        userPassword: '',
                        userPasswordRepeated: ''
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEditUserFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showEditUserFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    showPasswordWarnGrowl = () => {
        let msg = { severity: 'error', summary: 'Error!', detail: `The entered passwords are not identical.` };
        this.growl.show(msg)
    }

    showEditRegularUserSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: `User details successfully edited.` };
        this.growl.show(msg)
    }

    showEditUserFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg)
    }

    onEditAdminUser = () => {
        this.editAdminUser(this.state.userRole, this.props.userDetails.company.id, this.state.userActive, this.props.userDetails.department.id,
            this.state.userEmail, this.state.userForename, this.state.userName, this.state.userPassword, this.state.userPasswordRepeated,
            this.state.userUsername)
    }

    changeUserEmail = (email) => {
        this.setState({
            userEmail: email.replace(/\s/g, "")
        })
    }

    editAdminUser = async (newUserRole, selectedCompany, newUserActive, selectedDepartment, newUserEmail, newUserForename, newUserName, newUserPassword, newUserPasswordRepeated, newUserUsername) => {
        if (newUserPassword !== newUserPasswordRepeated) {
            this.showPasswordWarnGrowl()
        } else {
            var self = this;
            let encodedPassword = btoa(newUserPassword);
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/users`, {
                adminRole: newUserRole,
                company: selectedCompany,
                deactivated: newUserActive === true ? false : true,
                department: selectedDepartment,
                email: newUserEmail,
                forename: newUserForename,
                lastName: newUserName,
                password: encodedPassword,
                username: newUserUsername,
                forcePassword: null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.props.isLoggedIn(self.props.history)
                    self.showEditRegularUserSuccessGrowl()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEditUserFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.showEditUserFailGrowl(error.response.data)
                        console.log("not found")
                    }
                })
        }
    }

    render() {

        let userRoles = [
            {
                label: 'Super admin', value: 'ROLE_SUPER_ADMIN'
            },
            {
                label: 'Support admin', value: 'ROLE_SUPPORT_ADMIN'
            },
            {
                label: 'Organization admin', value: 'ROLE_COMPANY_ADMIN'
            },
            {
                label: 'Suborganization admin', value: 'ROLE_DEPARTMENT_ADMIN'
            },
            {
                label: 'User', value: 'NONE'
            }
        ]

        userRoles = this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' ? userRoles.slice(2) :
            this.props.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' ? userRoles.slice(3) :
                this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") ? userRoles.slice(1) :
                    userRoles

        return (
            <div className='col-12' style={{ paddingTop: 0, paddingLeft: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />
                <div className='col-12' style={{ padding: 0 }}>
                    <h1 style={{ marginTop: 0 }} className="pageHeader">{this.props.userDetails.samlLogin ? 'User details (SSO)' : 'User details'}</h1>
                </div>
                <div className="grid p-fluid" style={{ marginTop: 10, borderBottom: '1px solid #d6d6d6', paddingBottom: 20 }}>
                    <div className="col-12 grid p-fluid">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>Last name</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userName"
                                onChange={(e) => { this.setState({ userName: e.target.value }) }}
                                disabled={this.props.userDetails.highestAdminType === 'NONE' || this.props.userDetails.samlLogin ? true : false}
                                value={this.state.userName} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>First name</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userForeName"
                                onChange={(e) => { this.setState({ userForename: e.target.value }) }}
                                disabled={this.props.userDetails.highestAdminType === 'NONE' || this.props.userDetails.samlLogin ? true : false}
                                value={this.state.userForename} />
                        </div>
                    </div>
                    <div className="col-12 grid p-fluid">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em' }}>
                            <label>Username (cannot be changed)</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userUserName" disabled={true}
                                onChange={(e) => { this.setState({ userUsername: e.target.value }) }} value={this.state.userUsername} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>EMail</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userEmail"
                                onChange={(e) => { this.changeUserEmail(e.target.value) }} disabled={this.props.userDetails.highestAdminType === 'NONE' || this.props.userDetails.samlLogin ? true : false} value={this.state.userEmail} />
                        </div>
                    </div>
                    <div className="col-12 grid p-fluid">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', marginTop: -10 }}>
                            <label htmlFor="companyName">New password</label>
                            <Button icon='pi pi-info-circle'
                                style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none', paddingBottom: 7, paddingTop: 5 }}
                                title='New password needs to contain at least 10 characters with one uppercase character and with one number.'>
                            </Button>
                            <div>
                                <InputText style={{ padding: 5, border: '1px solid #d6d6d6', width: '91%' }} id="userPassword"
                                    type={this.state.type === 'input' ? 'input' : 'password'}
                                    disabled={this.props.userDetails.samlLogin ? true : false}
                                    onChange={(e) => { this.setState({ userPassword: e.target.value }) }} value={this.state.userPassword} />
                                <Button
                                    icon={this.state.type === 'input' ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                    onClick={this.handleShowHide}
                                    style={{ marginBottom: -5, backgroundColor: 'white', color: 'black', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }} >
                                </Button>
                            </div>
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', marginTop: -10 }}>
                            <label htmlFor="companyName">Repeat new password</label>
                            <Button icon='pi pi-info-circle'
                                style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none', paddingBottom: 7, paddingTop: 5 }}
                                title='New password needs to contain at least 10 characters with one uppercase character and with one number.'>
                            </Button>
                            <div>
                                <InputText style={{ padding: 5, border: '1px solid #d6d6d6', width: '91%' }} id="userPasswordRepeated"
                                    type={this.state.typeRepeated === 'input' ? 'input' : 'password'}
                                    disabled={this.props.userDetails.samlLogin ? true : false}
                                    onChange={(e) => { this.setState({ userPasswordRepeated: e.target.value }) }} value={this.state.userPasswordRepeated} />
                                <Button
                                    icon={this.state.typeRepeated === 'input' ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                    onClick={this.handleShowHideRepeated}
                                    style={{ marginBottom: -5, backgroundColor: 'white', color: 'black', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }} >
                                </Button>
                            </div>
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>User role</label>
                            <div>
                                <Dropdown style={{ width: '100%', border: '1px solid #d6d6d6', marginTop: 5 }}
                                    className='dropdownNoBorder'
                                    value={this.state.userRole}
                                    options={userRoles}
                                    disabled={this.props.userDetails.highestAdminType === 'NONE' ? true : false}
                                    onChange={(e) => this.handleUserRoleChange(e)}
                                    placeholder='Select user role'>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-2" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>Status</label>
                            <div>
                                <InputSwitch style={{
                                    marginRight: 10, marginTop: 10
                                }} disabled={this.props.userDetails.highestAdminType === 'NONE' ? true : false} checked={this.state.userActive} onChange={(e) => this.setState({ userActive: e.value })} />
                                <label>{this.state.userActive ? 'Active' : 'Deactivated'}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 10, marginRight: -7, marginBottom: 100 }}>
                    <Button label="Save"
                        className='p-button-sm primaryButton'
                        style={{ float: 'right' }}
                        disabled={this.props.userDetails.highestAdminType !== 'NONE' || (this.state.userPassword !== '' && this.state.userPasswordRepeated !== '') ? false : true}
                        onClick={this.props.userDetails.highestAdminType === 'NONE' ? this.onEditRegularUser : this.onEditAdminUser} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.user.data.userDetails
})

const mapDispatchToProps = dispatch => ({
    isLoggedIn: (history) => dispatch(Actions.isLoggedIn(history))
})



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRegularUser))