import { Checkbox } from "primereact/checkbox";
import React, { Component } from "react";
import { shortenLargeNumber } from "../../util";


class FilterEntry extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const { filterID, filterEntry, isSelected, isManuallyAdded, onSelectionChange, showOccurrences } = this.props;
        //console.log('filterEntry: ', filterEntry);

        return (
            <>
                {filterEntry ?
                    <>
                        <Checkbox
                            inputId={filterEntry.term}
                            value={filterEntry}
                            onChange={(e) => onSelectionChange(e, filterID)}
                            checked={isSelected}
                        >
                        </Checkbox>
                        <label htmlFor={filterEntry.term}
                            title={isManuallyAdded ? `"${filterEntry.term}" with variants` : filterEntry.term}
                            className="p-checkbox-label"
                            style={{
                                overflow: 'hidden', whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis', width: '100%'
                            }}>
                            {isManuallyAdded ? '~ ' : ''}{filterEntry.term}
                        </label>
                        {showOccurrences ?
                            <span style={{
                                overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 2,
                                textOverflow: 'ellipsis', width: '100px', textAlign: 'right'
                            }}
                                title="Approximated number of documents."
                            >{shortenLargeNumber(filterEntry.occurrence)}</span>
                            : null}
                    </>
                    : null
                }
            </>
        );
    }
}

export default FilterEntry;