import { Dialog } from "primereact/dialog";

import "./TranslationDialog.css";

const TranslationDialog = ({ show, onHide, translation }) => {
  return (
    <Dialog
      id="translationDialog"
      visible={show}
      onHide={onHide}
      blockScroll
      modal={false}
      resizable
      header="Internal API syntax"
      appendTo="self"
      className="styled-dialog"
    >
      <div>{translation}</div>
    </Dialog>
  );
};

export default TranslationDialog;
