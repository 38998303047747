import React, { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";
import DateRangeFilterText from "./DateRangeFilterText";


class InsertionDateFilterInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="insDateInfo"
                title="Click for information about insertion date"
                imageAltText="Insertion date information"
                overlayContent={
                    <>
                        <h3>Insertion date</h3>
                        <p>
                            Insertion date is the date a document was processed and first added to our system.
                            It can be very different from the publication date,
                            e.g. if a new repository was just added to the application.
                        </p>
                        <DateRangeFilterText />
                    </>
                }
            />
        );
    }
}

export default InsertionDateFilterInfo;