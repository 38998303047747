import { Component } from 'react';
import { COMPOUND_PROPERTIES } from './compoundProperties';
import PropertiesList from './PropertiesList';

export function getFilteredProperties(extendedCompoundData) {
    const extraInfoFiltered = COMPOUND_PROPERTIES.filter(ei =>
        ei.isPrimaryProperty &&
        extendedCompoundData &&
        !!extendedCompoundData[ei.id] &&
        (!ei.checkFunction || !!ei.checkFunction(extendedCompoundData[ei.id])));
    return extraInfoFiltered;
}

class CompoundExtraData extends Component {

    render() {

        const { extendedCompoundData } = this.props;
        const extraInfoFiltered = getFilteredProperties(extendedCompoundData);

        return (
            <PropertiesList
                propertiesDefinitionsFiltered={extraInfoFiltered}
                compoundProperties={extendedCompoundData}
            />
        )
    }
}
export default CompoundExtraData