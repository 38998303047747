import { cloneDeep, isArrayEmpty, sortMapByKeys, sortObjectArrayByAttribute } from '../../util';
import * as IDGeneratorUtil from './IDGeneratorUtil';

// --- frame tag types --- //
export const TAG_TYPE_ID = 'id';
export const TAG_TYPE_NAME = 'name';
export const TAG_TYPE_SYNONYM = 'synonym';
export const TAG_TYPE_MACRO = 'oc_macro';
export const TAG_TYPE_RELTYPE = 'oc_reltype';
export const TAG_TYPE_ROLE = 'oc_role';
export const TAG_TYPE_SYNTAX = 'oc_syntax';
export const TAG_TYPE_LEVEL = 'oc_level';
export const TAG_TYPE_RELATIONSHIP = 'relationship';
export const TAG_TYPE_IS_A = 'is_a';

// --- relation types --- //
export const REL_TYPE_FRAME = 'frame';
export const REL_TYPE_UNIDIRECT = 'unidirect';
export const REL_TYPE_SYMMETRIC = 'symmetric';
export const REL_TYPE_ROLE = 'role';
export const REL_TYPE_SYNSET = 'synset';
export const REL_TYPE_MACRO = 'macro';

// --- frame relation types --- //
export const FRAME_REL_TYPES = [
    { label: 'Frame', value: REL_TYPE_FRAME },
    { label: 'Symmetric', value: REL_TYPE_SYMMETRIC },
    { label: 'Unidirect', value: REL_TYPE_UNIDIRECT },
];
export const FRAME_REL_TYPE_DEFAULT = FRAME_REL_TYPES[2];

// --- synonym scopes --- //
export const SYNONYM_SCOPES = [
    { label: 'BROAD', value: 'BROAD' },
    { label: 'EXACT', value: 'EXACT' },
    { label: 'NARROW', value: 'NARROW' },
    { label: 'RELATED', value: 'RELATED' },
];

// --- synonym types --- //
export const SYNONYM_TYPES = [
    { label: 'ADJ', value: 'ADJ' },
    { label: 'ADV', value: 'ADV' },
    { label: 'NOUN', value: 'NOUN' },
    { label: 'PREP', value: 'PREP' },
    { label: 'ROLE', value: 'ROLE' },
    { label: 'VERB', value: 'VERB' },
];


/**
 * Normalizes class name. Removes surrounding brackets [].
 * Replaces whitespace with _. 
 * @param {*} name 
 * @returns 
 */
export const normalizeClassName = (name) => {
    return name ? name.replace(/\[/g, '').replace(/\]/g, '').replace(/ /g, '_') : name;
}

// ----------------------------------------------------------------------- //
// --- frames ------------------------------------------------------------ //
// ----------------------------------------------------------------------- //
/**
 * Creates a frame class, given a unique id, a label and an optional frame relation type.
 * The id is also set as key, but should later be replaced by unique path id.
 * @param {*} id 
 * @param {*} label 
 *@param {*} relType 
 */
export const createFrameClass = (id, label, relType = FRAME_REL_TYPE_DEFAULT) => {

    const newClass = {
        key: id,
        label: label,
        id: id,
        name: label,
        type: 'Term',
        tags: {}
    };

    newClass.tags[TAG_TYPE_ID] = [createIdTag(id)];
    newClass.tags[TAG_TYPE_NAME] = [createNameTag(label)];
    newClass.tags[TAG_TYPE_RELTYPE] = [createRelationTypeTag(relType.value)];
    newClass.tags[TAG_TYPE_IS_A] = [];
    //newClass.tags[TAG_TYPE_ROLE] = [];

    return newClass;
}

// ----------------------------------------------------------------------- //
// --- roles ------------------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Creates a role class, given a unique id and a label.
 * The id is also set as key, but should later be replaced by unique path id.
 * @param {*} id 
 * @param {*} label 
 */
export const createRoleClass = (id, label) => {

    const newClass = {
        key: id,
        label: label,
        id: id,
        name: label,
        type: 'Term',
        tags: {}
    };

    newClass.tags[TAG_TYPE_ID] = [createIdTag(id)];
    newClass.tags[TAG_TYPE_NAME] = [createNameTag(label)];
    newClass.tags[TAG_TYPE_RELTYPE] = [createRelationTypeTag(REL_TYPE_ROLE)];
    newClass.tags[TAG_TYPE_IS_A] = [];

    return newClass;
}

// ----------------------------------------------------------------------- //
// --- synsets ----------------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Creates a synset class, given a unique id and a label.
 * The id is also set as key, but should later be replaced by unique path id.
 * @param {*} id 
 * @param {*} label 
 */
export const createSynsetClass = (id, label) => {

    const newClass = {
        key: id,
        label: label,
        id: id,
        name: label,
        type: 'Term',
        tags: {}
    };

    newClass.tags[TAG_TYPE_ID] = [createIdTag(id)];
    newClass.tags[TAG_TYPE_NAME] = [createNameTag(label)];
    newClass.tags[TAG_TYPE_RELTYPE] = [createRelationTypeTag(REL_TYPE_SYNSET)];
    newClass.tags[TAG_TYPE_IS_A] = [];

    return newClass;
}


// ----------------------------------------------------------------------- //
// --- ontology classes -------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Deep clones an ontology class and returns the cloned class with the given id.
 * Is_a relations will not be cloned.
 * @param {*} ontClass 
 * @param {*} id 
 * @returns 
 */
export const cloneClass = (ontClass, id) => {
    // --- clone original class --- //
    let newClass = cloneDeep(ontClass);
    // --- update ID and label --- //
    newClass = updateClassID(newClass, id);
    let newLabel = ontClass.name.startsWith('[') ? `[${ontClass.name.substring(1)}_COPY` : `${ontClass.name}_COPY`;
    newClass = updateClassLabel(newClass, newLabel);

    // --- reset children --- //
    newClass.children = [];
    // --- add empty is_a tag array --- //
    if (!newClass.tags) {
        newClass.tags = {};
    }
    newClass.tags[TAG_TYPE_IS_A] = [];

    return newClass;
}

/**
 * Updates id of a class.
 * @param {*} ontClass 
 * @param {*} id 
 * @returns 
 */
export const updateClassID = (ontClass, id) => {
    if (ontClass) {
        ontClass.id = id;
        if (!ontClass.tags) {
            ontClass.tags = {};
        }
        ontClass.tags[TAG_TYPE_ID] = [createIdTag(id)];
    }
    return ontClass;
}

/**
 * Updates label of a class.
 * @param {*} ontClass 
 * @param {*} label 
 * @returns 
 */
export const updateClassLabel = (ontClass, label) => {
    if (ontClass) {
        ontClass.label = label;
        ontClass.name = label;
        if (!ontClass.tags) {
            ontClass.tags = {};
        }
        ontClass.tags[TAG_TYPE_NAME] = [createNameTag(label)];
    }
    return ontClass;
}

/**
 * Extracts the parent class id from a node key.
 * @param {*} key 
 * @returns 
 */
export const extractParentIDFromKey = (key) => {

    let parentID = null;
    if (key) {
        var regex = new RegExp('^(?:[0-9]+-)*([0-9]+)(?:-[0-9]+)$');
        var match = regex.exec(key);

        if (key.match(regex)) {
            parentID = match[1];
        }
    }
    return parentID;
}

/**
 * Extracts the parent node key from a node key.
 * @param {*} key 
 * @returns 
 */
export const extractParentKeyFromKey = (key) => {

    let parentKey = null;
    if (key) {
        var regex = new RegExp('^((?:[0-9]+-)*(?:[0-9]+))(?:-[0-9]+)$');
        var match = regex.exec(key);

        if (key.match(regex)) {
            parentKey = match[1];
        }
    }
    return parentKey;
}

/**
 * Extracts parent node IDs from array of nodes.
 * @param {*} nodeArray array of nodes
 * @returns map with parent IDs
 */
export const extractParentIDsFromNodes = (nodeArray) => {

    const uniqueParentIDs = {};
    if (nodeArray) {
        nodeArray.forEach(nd => {
            const parentID = extractParentIDFromKey(nd.key);
            if (parentID) {
                uniqueParentIDs[parentID] = true;
            }
        });
    }
    return uniqueParentIDs;
} 

/**
 * Removes child with a given ID from parent node.
 * @param {*} parentNode 
 * @param {*} childID 
 */
export const removeChildFromParentNode = (parentNode, childID) => {
    if (parentNode && parentNode.children && childID) {
        parentNode.children = parentNode.children.filter(childNode => {
            return childNode.id !== childID;
        });
    }
}

// ----------------------------------------------------------------------- //
// --- tags -------------------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Adds tag of a certain type to a class. 
 * If valid index is given, the tag at the index position will be replaced.
 * Tags will be sorted: Role tags by role name, others by value.
 * @param {*} ontClass 
 * @param {*} tagType 
 * @param {*} tag 
 * @param {*} index 
 * @returns 
 */
export const addTagToClass = (ontClass, tagType, tag, index = -1) => {
    if (ontClass && tag) {
        if (!ontClass.tags) {
            ontClass.tags = {};
        }
        if (!ontClass.tags[tagType]) {
            ontClass.tags[tagType] = [];
        }
        if (index < 0) {
            ontClass.tags[tagType].push(tag);
        }
        else {
            ontClass.tags[tagType][index] = tag;
        }
        // --- sort --- //
        ontClass.tags[tagType] = sortTagsByValue(ontClass.tags[tagType], tagType);
    }
    return ontClass;
}

/**
 * Sorts tags: Role tags by role name, others by value.
 * @param {*} tags 
 * @param {*} tagType 
 * @returns 
 */
const sortTagsByValue = (tags, tagType) => {

    if (tags && tagType) {
        switch (tagType) {
            case TAG_TYPE_ROLE:
                return sortObjectArrayByAttribute(tags, 'roleName', true, true);
            case TAG_TYPE_SYNTAX:
                return sortObjectArrayByAttribute(tags, 'value', true, true);
            case TAG_TYPE_SYNONYM:
                return sortObjectArrayByAttribute(tags, 'value', true, true);
            case TAG_TYPE_MACRO:
                return sortObjectArrayByAttribute(tags, 'value', true, true);
        }
    }
    return tags;
}

/**
 * Sets tags in class. Overwrites existing tags.
 * @param {*} ontClass 
 * @param {*} tagType 
 * @param {*} tags 
 * @returns 
 */
export const setTagsInClass = (ontClass, tagType, tags) => {

    if (ontClass && tagType) {
        if (!ontClass.tags) {
            ontClass.tags = {};
        }
        ontClass.tags[tagType] = tags;
    }
    return ontClass;
}

/**
 * Removes tags of a certain type from class.
 * @param {*} ontClass 
 * @param {*} tagType 
 * @returns 
 */
export const removeTagFromClass = (ontClass, tagType) => {

    if (ontClass && ontClass.tags) {
        delete ontClass.tags[tagType];
    }
    return ontClass;
}

/**
 * Returns tags of a certain type from class. 
 * @param {*} ontClass 
 * @param {*} tagType 
 * @returns 
 */
export const getTagsFromClass = (ontClass, tagType) => {
    if (ontClass && ontClass.tags) {
        return ontClass.tags[tagType];
    }
}

/**
 * Returns tag of a certain type from class. If there are multiple tags,
 * only the first one is returned.
 * @param {*} ontClass 
 * @param {*} tagType 
 * @returns 
 */
export const getTagFromClass = (ontClass, tagType) => {
    if (ontClass && ontClass.tags &&
        ontClass.tags[tagType] && ontClass.tags[tagType].length > 0) {
        return ontClass.tags[tagType][0];
    }
}

/**
 * Checks whether the given class contains the given tag.
 * @param {*} ontClass 
 * @param {*} tagType 
 * @param {*} tag 
 * @returns 
 */
export const classContainsTag = (ontClass, tagType, tag) => {
    return (ontClass && ontClass.tags && ontClass.tags[tagType] && ontClass.tags[tagType].indexOf(tag) >= 0);
}

/**
 * Creates id tag.
 * @param {*} id 
 * @returns 
 */
export const createIdTag = (id) => {
    return {
        tag: TAG_TYPE_ID,
        value: id
    }
}

/**
 * Creates name tag.
 * @param {*} name 
 * @returns 
 */
export const createNameTag = (name) => {
    return {
        tag: TAG_TYPE_NAME,
        value: name
    }
}

/**
 * Creates synonym tag.
 * @param {*} name 
 * @param {*} scope 
 * @param {*} type 
 * @returns 
 */
export const createSynonymTag = (name, scope, type) => {
    return {
        tag: TAG_TYPE_SYNONYM,
        value: name,
        scope: scope,
        type: type
    }
}

/**
 * Creates syntax tag.
 * @param {*} syntax 
 * @returns 
 */
export const createSyntaxTag = (syntax) => {
    return {
        key: IDGeneratorUtil.generateNewSyntaxTagID(),
        tag: TAG_TYPE_SYNTAX,
        value: syntax
    }
}

/**
 * Creates role tag.
 * @param {*} value 
 * @returns 
 */
export const createRoleTag = (value) => {
    return {
        key: IDGeneratorUtil.generateNewRoleTagID(),
        tag: TAG_TYPE_ROLE,
        value: value
    }
}

/**
 * Creates macro tag.
 * @param {*} value 
 * @returns 
 */
export const createMacroTag = (value) => {
    return {
        key: IDGeneratorUtil.generateNewMacroTagID(),
        tag: TAG_TYPE_MACRO,
        value: value
    }
}

/**
 * Creates level tag.
 * @param {*} level 
 * @returns 
 */
export const createLevelTag = (level) => {
    return {
        tag: TAG_TYPE_LEVEL,
        value: level
    }
}

/**
 * Creates relation type tag.
 * @param {*} reltype 
 * @returns 
 */
export const createRelationTypeTag = (reltype) => {
    return {
        tag: TAG_TYPE_RELTYPE,
        value: reltype
    }
}

/**
 * Creates relationship tag.
 * @param {*} value 
 * @param {*} relationshipTypeId 
 * @param {*} comment 
 * @param {*} targetIds 
 * @returns 
 */
export const createRelationshipTag = (value, relationshipTypeId, comment, targetIds) => {
    return {
        tag: TAG_TYPE_RELATIONSHIP,
        value: value,
        relationshipTypeId: relationshipTypeId,
        comment: comment,
        targetIds: targetIds
    }
}

/**
 * Creates has_role tag.
 * @param {*} comment 
 * @param {*} targetIds 
 * @returns 
 */
export const createHasRoleRelationshipTag = (comment, targetIds) => {
    return createRelationshipTag('has_role', 'has_role', comment, targetIds);
}

/**
 * Adds trailing modifier to tag.
 * @param {*} tag 
 * @param {*} name 
 * @param {*} value 
 * @returns 
 */
export const addTrailingModifierToTag = (tag, name, value) => {
    if (tag) {
        if (!tag.trailingModifiers) {
            tag.trailingModifiers = [];
        }
        const tm = {};
        tm[name] = value;
        tag.trailingModifiers.push(tm);
    }
    return tag;
}

/**
 * Returns level from tag.
 * @param {*} tag 
 * @returns 
 */
export const getLevelFromTag = (tag) => {
    if (tag && tag.trailingModifiers) {
        for (const tm of tag.trailingModifiers) {
            if (tm.level) {
                return tm.level;
            }
        }
    }
    //return "1";
    return '';
}

/**
 * Sets level in tag.
 * @param {*} tag 
 * @param {*} level 
 * @returns 
 */
export const setLevelInTag = (tag, level) => {
    if (tag) {

        if (!level || level === '') {
            if (!!tag.trailingModifiers) {
                for (const tm of tag.trailingModifiers) {
                    if (tm.level) {
                        delete tm.level;
                    }
                }
            }
        }
        else {
            if (!tag.trailingModifiers) {
                tag.trailingModifiers = [];
            }

            let found = false;
            for (const tm of tag.trailingModifiers) {
                if (tm.level) {
                    found = true;
                    //if (level === "1") {
                    //    delete tm.level;
                    //}
                    //else {
                    tm.level = level;
                    //}
                }
            }
            if (!found) {
                const tm = {};
                tm.level = level;
                tag.trailingModifiers.push(tm);
            }
        }
    }
    return tag;
}

/**
 * Extract the name and syntax from a role tag value.
 * @param {*} value 
 * @returns object { name, syntax } 
 */
export const extractNameAndSyntaxFromRoleTagValue = (value) => {

    let name = null;
    let syntax = null;

    if (value) {
        var regex = new RegExp('([^ ]+) (.+)');
        var match = regex.exec(value);

        if (value.match(regex)) {
            name = match[1];
            syntax = match[2];
        }
    }

    return { name, syntax };
}

/**
 * Extract the name and syntax from a macro tag value.
 * @param {*} value 
 * @returns 
 */
export const extractNameAndSyntaxFromMacroTagValue = (value) => {

    let name = null;
    let syntax = null;

    if (value) {
        var regex = new RegExp('([^ ]+) (.+)');
        var match = regex.exec(value);

        if (value.match(regex)) {
            name = match[1];
            syntax = match[2];
            syntax = syntax.replace(/^"/, '');
            syntax = syntax.replace(/"$/, '');
        }
    }

    return { name, syntax };
}

/**
 * Changes the name in a macro tag value.
 * @param {*} value 
 * @param {*} newName 
 * @returns 
 */
export const changeNameInMacroTagValue = (value, newName) => {
    if (value) {
        var regex = new RegExp('([^ ]+) (.+)');
        var match = regex.exec(value);

        if (value.match(regex)) {
            const syntax = match[2];
            return `${newName} ${syntax}`;
        }
    }

    return null;
}

/**
 * Extracts macros from ontology.
 * @param {*} frameOntology 
 * @returns 
 */
export const extractMacros = (frameOntology) => {

    if (!frameOntology || isArrayEmpty(frameOntology.macros))
        return [];

    const macroNode = frameOntology.macros[0];
    const macroTags = macroNode && macroNode.tags && macroNode.tags[TAG_TYPE_MACRO] ?
        macroNode.tags[TAG_TYPE_MACRO] : [];

    const macros = [];
    for (var macroTag of Object.values(macroTags)) {
        const { name } = extractNameAndSyntaxFromMacroTagValue(macroTag.value);
        macros.push({
            label: name,
            value: name,
        });
    }
    return macros;
}

// TODO: same as extractCLasses???
/**
 * Extracts synsets from ontology.
 * @param {*} frameOntology 
 * @param {*} inclRoot 
 * @returns 
 */
export const extractSynsets = (frameOntology, inclRoot = true) => {
    const classesList = extractSynsetsRecursively(frameOntology.synsets, frameOntology.knownTermStanzas, inclRoot);
    return sortMapByKeys(classesList);
}
const extractSynsetsRecursively = (synsetSubtree, ontClasses, inclRoot, map = {}) => {
    for (const index of Object.keys(synsetSubtree)) {
        const node = synsetSubtree[index];
        if (inclRoot || !node.isRoot) {
            const ontClass = ontClasses[node.id];
            const name = ontClass ? ontClass.name : node.id;
            map[name] = {
                label: name,
                id: node.id,
                types: []
            };
            const synonymTags = getTagsFromClass(ontClass, TAG_TYPE_SYNONYM);
            if (synonymTags) {
                const types = {};
                for (var synonymTag of synonymTags) {
                    types[synonymTag.type] = true;
                }
                for (var type of Object.keys(types)) {
                    map[name].types.push({ label: type, value: type })
                }
            }
        }
        // --- check all children --- //
        if (node.children && node.children.length > 0) {
            extractSynsetsRecursively(node.children, ontClasses, inclRoot, map);
        }
    }
    return map;
}

/*
export const updateRelationshipsInFrame = (frame) => {

    console.log('update rels in frame: ', frame);

    // --- remove old tags --- // 
    //removeTagFromClass(frame, TAG_TYPE_RELATIONSHIP);

    const relTagsMap = {};
    const relationTags = getTagsFromClass(frame, TAG_TYPE_RELATIONSHIP);
    if (relationTags) {
        for (const relTag of relationTags) {
            console.log('relTag: ', relTag);
            relTagsMap[relTag.comment] = relTag;
        }
    }

    //const roleTagsMap = {};
    const newRelTags = [];
    const roleTags = getTagsFromClass(frame, TAG_TYPE_ROLE);
    if (roleTags) {
        for (const roleTag of roleTags) {
            console.log('roleTag: ', roleTag);
            if (relTagsMap[roleTag.roleName]) { // immer definiert???
                newRelTags.push(relTagsMap[roleTag.roleName]);
            }
            else {
                newRelTags.push(createHasRoleRelationshipTag(roleTag.roleName, [roleTag.roleID]));
            }
            //roleTagsMap[roleTag.roleName] = roleTag;
            //addTagToClass(frame, TAG_TYPE_RELATIONSHIP, createHasRoleRelationshipTag(roleTag.roleName, [roleTag.roleID]));
        }
    }

    console.log('frame with rels: ', frame);
}
*/

/*
export const updateRelationshipNameInFrame = (frame, oldName, newName) => {

    console.log('update rel name in frame: ', frame);

    const relationTags = getTagsFromClass(frame, TAG_TYPE_RELATIONSHIP);
    if (relationTags) {
        for (const relTag of relationTags) {
            console.log('relTag: ', relTag);
            if (relTag.comment === oldName) {
                relTag.comment = newName;
            }
        }
    }

    console.log('frame with rels: ', frame);
}
*/

/**
 * Adds relationship tags to ontology.
 * @param {*} subtree 
 * @param {*} ontClasses 
 * @param {*} roleSelectItems 
 * @returns 
 */
export const addRelationshipTagsToSubtree = (subtree, ontClasses, roleSelectItems) => {

    const rolesMap = convertRoleSelectItemsToMap(roleSelectItems);
    return addRelationshipTagsToOntologyRecursively(subtree, ontClasses, rolesMap);
    //return sortObjectArrayByAttribute(classesList, 'label', true, true);
}
const convertRoleSelectItemsToMap = (roleSelectItems) => {

    const rolesMap = {};
    if (roleSelectItems) {
        for (const roleItem of roleSelectItems) {
            rolesMap[roleItem.value] = [roleItem.id];
        }
    }
    return rolesMap;
}
const addRelationshipTagsToOntologyRecursively = (subtree, ontClasses, rolesMap) => {

    for (const node of Object.values(subtree)) {

        const ontClass = ontClasses[node.id];
        if (ontClass) {
            addRelationshipTagsToFrameClass(ontClass, rolesMap);
            // --- check all children --- //
            if (node.children && node.children.length > 0) {
                node.children = addRelationshipTagsToOntologyRecursively(node.children, ontClasses, rolesMap);
            }
        }
    }

    return subtree;
}

/**
 * Adds relationship tag to frame for each of the roles defined for this frame.
 * @param {*} frameClass 
 * @param {*} rolesMap 
 */
export const addRelationshipTagsToFrameClass = (frameClass, rolesMap) => {

    const relationshipTags = [];
    // --- remove original relationship tags --- //
    removeTagFromClass(frameClass, TAG_TYPE_RELATIONSHIP);
    // --- get role tags of this frame --- //
    const roleTags = getTagsFromClass(frameClass, TAG_TYPE_ROLE);
    //console.log('roleTags: ', roleTags);
    if (roleTags) {
        for (const roleTag of roleTags) {
            const { name } = extractNameAndSyntaxFromRoleTagValue(roleTag.value);
            if (rolesMap[name]) {
                const id = rolesMap[name];
                relationshipTags.push(createHasRoleRelationshipTag(name, id));
            }
        }
    }
    // --- if relationships array contains entries -> add to frame --- //
    if (!isArrayEmpty(relationshipTags)) {
        setTagsInClass(frameClass, TAG_TYPE_RELATIONSHIP, relationshipTags);
    }
    //console.log('frame with rels: ', frame);
}

// version 2 in OntologyUtil.js
/*
export const sortOntology = (nodes) => {
    return sortOntologyRecursively(nodes);
    //return sortObjectArrayByAttribute(classesList, 'label', true, true);
}
const sortOntologyRecursively = (nodes) => {

    const nodesSorted = sortObjectArrayByAttribute(nodes, 'label', true, true);

    for (const index of Object.keys(nodesSorted)) {
        const node = nodesSorted[index];

        //sortNode(node);

        // --- check all children --- //
        if (node.children && node.children.length > 0) {
            node.children = sortOntologyRecursively(node.children);
        }
    }

    return nodesSorted;
}

export const sortNode = (node) => {

    const roleTags = getTagsFromClass(node, TAG_TYPE_ROLE);
    if (roleTags) {
        const rolesSorted = sortObjectArrayByAttribute(roleTags, 'value', true, true);
        setTagsInClass(node, TAG_TYPE_ROLE, rolesSorted);
    }

    //const syntaxTags = getTagsFromClass(node, TAG_TYPE_SYNTAX);
    //if (syntaxTags) {
    //    const syntaxesSorted = sortObjectArrayByAttribute(syntaxTags, 'value', true, true);
    //    setTagsInClass(node, TAG_TYPE_SYNTAX, syntaxesSorted);
    //}
    
    const synonymTags = getTagsFromClass(node, TAG_TYPE_SYNONYM);
    if (synonymTags) {
        const synonymsSorted = sortObjectArrayByAttribute(synonymTags, 'value', true, true);
        setTagsInClass(node, TAG_TYPE_SYNONYM, synonymsSorted);
    }

    const macroTags = getTagsFromClass(node, TAG_TYPE_MACRO);
    if (macroTags) {
        const macrosSorted = sortObjectArrayByAttribute(macroTags, 'value', true, true);
        setTagsInClass(node, TAG_TYPE_MACRO, macrosSorted);
    }
}
*/


// ----------------------------------------------------------------------- //
// --- cleaning steps ---------------------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 * Removes identical syntaxes from frame class.
 * @param {Object} ontClass  frame ontology class
 * @returns number of removed syntaxes
 */
export const removeRedundantSyntaxesFromFrameClass = (ontClass) => {

    let numOfRemovedSyntaxes = 0;
    let uniqueTagsMap, uniqueTags;

    // --- role tags --- //
    const roleTags = getTagsFromClass(ontClass, TAG_TYPE_ROLE);
    if (!isArrayEmpty(roleTags)) {
        uniqueTagsMap = {};
        for (let tag of roleTags) {
            if (!uniqueTagsMap[tag.value]) {
                uniqueTagsMap[tag.value] = tag;
            }
        }
        uniqueTags = Object.values(uniqueTagsMap);
        if (roleTags.length > uniqueTags.length) {
            setTagsInClass(ontClass, TAG_TYPE_ROLE, uniqueTags);
            numOfRemovedSyntaxes += roleTags.length - uniqueTags.length;
        }
    }
    // --- syntax tags --- //
    const syntaxTags = getTagsFromClass(ontClass, TAG_TYPE_SYNTAX);
    if (!isArrayEmpty(syntaxTags)) {
        uniqueTagsMap = {};
        for (let tag of syntaxTags) {
            if (!uniqueTagsMap[tag.value]) {
                uniqueTagsMap[tag.value] = tag;
            }
        }
        uniqueTags = Object.values(uniqueTagsMap);
        if (syntaxTags.length > uniqueTags.length) {
            setTagsInClass(ontClass, TAG_TYPE_SYNTAX, uniqueTags);
            numOfRemovedSyntaxes += syntaxTags.length - uniqueTags.length;
        }
    }

    return numOfRemovedSyntaxes;
}