import React, { Component } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/legacy/build/pdf";
import type { PDFDocumentProxy } from "pdfjs-dist/types/display/api";

// Define the Props interface for the PdfLoader component.
interface Props {
  workerSrc: string;
  url: string;
  beforeLoad: JSX.Element;
  errorMessage?: JSX.Element;
  children: (pdfDocument: PDFDocumentProxy) => JSX.Element;
  onError?: (error: Error) => void;
  cMapUrl?: string;
  cMapPacked?: boolean;
}

// Define the State interface for the PdfLoader component.
interface State {
  pdfDocument: PDFDocumentProxy | null;
  error: Error | null;
  loading: Boolean;
}

export class PdfLoader extends Component<Props, State> {
  // Initialize the component's state.
  state: State = {
    pdfDocument: null,
    error: null,
    loading: false
  };

  // Default props for the PdfLoader component.
  static defaultProps = {
    workerSrc: "https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js",
  };

  // Create a reference to an HTML element.
  documentRef = React.createRef<HTMLElement>();

  // Lifecycle method: runs when the component is mounted.
  componentDidMount() {
    this.load();
  }

  // Lifecycle method: runs when the component is unmounted.
  componentWillUnmount() {
    const { pdfDocument: discardedDocument } = this.state;
    if (discardedDocument) {
      discardedDocument.destroy();
    }
  }

  // Lifecycle method: runs when the component receives new props.
  componentDidUpdate({ url }: Props) {
    if (this.props.url !== url) {
      this.load();
    }
  }

  // Error handling method: runs when an error occurs.
  componentDidCatch(error: Error, info?: any) {
    const { onError } = this.props;

    if (onError) {
      onError(error);
    }

    this.setState({ pdfDocument: null, error, loading: false });
  }

  // Async function to load the PDF document.
  async load() {
    const { ownerDocument = document } = this.documentRef.current || {};
    const { url, cMapUrl, cMapPacked, workerSrc } = this.props;
    const { pdfDocument: discardedDocument } = this.state;
    this.setState({ pdfDocument: null, error: null, loading: true });

    if (typeof workerSrc === "string") {
      GlobalWorkerOptions.workerSrc = workerSrc;
    }

    Promise.resolve()
      .then(() => discardedDocument && discardedDocument.destroy())
      .then(() => {
        if (!url) {
          return;
        }

        // Load the PDF document.
        const pdfLoad = getDocument({
          url: url,
          httpHeaders: { 'Authorization': `Bearer ${localStorage.token}` },
          withCredentials: false,
        });

        // When the PDF is loaded, update the component state.
        pdfLoad.promise.then((pdfDocument) => {
          this.setState({ pdfDocument, loading: false });
        }).catch((e) => this.componentDidCatch(e));
        return pdfLoad;
      }).catch((e) => this.componentDidCatch(e));
  }

  // Render the PdfLoader component.
  render() {
    const { children, beforeLoad } = this.props;
    const { pdfDocument, error, loading } = this.state;
    return (
      <>
        <div>
          <span ref={this.documentRef} />
          {error
            ? this.renderError()
            : !pdfDocument || !children
              ? beforeLoad
              : children(pdfDocument)}
        </div>
      </>
    );
  }

  // Render the error message.
  renderError() {
    const { errorMessage } = this.props;
    if (errorMessage) {
      return React.cloneElement(errorMessage, { error: this.state.error });
    }

    return null;
  }
}

export default PdfLoader;
