import React, { useRef, useState } from 'react';
import { Dropdown } from "primereact/dropdown";
import moment from 'moment';
import styles from './YearStasticsPanel.module.scss';
import './YearStatisticsPanel.css';
import { useDispatch, useSelector } from "react-redux";
import {
    resetYearStatisticsChart,
    setChartType, setEndDate, setIsOutOfLimit,
    setPeriod, setShowModal, setStartDate, setTimeRange, setUpdate,
    setValueType
} from "../../../../../redux/actions/YearStatisticsActions";
import { SearchItem } from "./SearchItem/SearchItem";
import Link from "../../../../common/Link/Link";
import { DatePicker } from "./DatePicker/DatePicker";
import { createPastDate } from "../YearStatisticsChart/chartFunctions/createPastDate";
import { Toast } from "primereact/toast";
import { chartTypes, periods, timeRanges, valueTypes } from "./yearStatisticsPanelConfig";
import { checkRangeIsOutOfLimit } from "../YearStatisticsChart/chartFunctions/checkRangeIsOutOfLimit";
import ConfirmationDialog from "../../../../common/ConfirmDialog/ConfirmationDialog";

export const YearStatisticsPanel = React.forwardRef((props, ref) => {
    const selectedSearches = useSelector(({ yearStatistics: { selectedSearches } }) => selectedSearches);
    const chartType = useSelector(({ yearStatistics: { chartType } }) => chartType);
    const valueType = useSelector(({ yearStatistics: { valueType } }) => valueType);
    const period = useSelector(({ yearStatistics: { period } }) => period);
    const startDate = useSelector(({ yearStatistics: { startDate } }) => startDate);
    const endDate = useSelector(({ yearStatistics: { endDate } }) => endDate);
    const dataIsFetching = useSelector(({ yearStatistics: { dataIsFetching } }) => dataIsFetching);
    const timeRange = useSelector(({ yearStatistics: { timeRange } }) => timeRange);
    const chartData = useSelector(({ yearStatistics: { chartData } }) => chartData);
    const repositories = useSelector(({ yearStatistics: { repositories } }) => repositories);
    const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
    const toast = useRef();
    const dispatch = useDispatch();

    const lengthSearchNameArray = selectedSearches.filter(({ searchName }) => searchName).map(item => item.searchName.length);
    const reposFromSelectedSearches = selectedSearches.filter(({ searchName }) => searchName).map(({ repoName }) => repoName);
    const lengthRepoArray = Object.values(repositories).flat()
        .filter(({ value }) => reposFromSelectedSearches.includes(value))?.map(({ label }) => label?.length);

    //@todo redesign logic of maxWidth calculation
    const maxSearchWidth = Math.max.apply(null, lengthSearchNameArray) * 8 + 60;
    const maxRepoWidth = (Math.max.apply(null, [...lengthRepoArray, 0]) + 13) * 6 + 100;
    const isData = Object.keys(chartData).length;

    const repositoryArray = selectedSearches.filter(({ searchName }) => searchName !== null).map((search, index, array) =>
        <SearchItem repoName={search.repoName} searchName={search.searchName} key={search.searchName + index}
            color={search.color} index={index} last={array[array.length - 1] === search}
            searchWidth={maxSearchWidth} repoWidth={maxRepoWidth} />);


    const chartValueTypes = valueTypes.map(item => {
        if (period !== 'yearly' && item.value === 'relative') {
            return { ...item, disabled: true }
        }

        return item
    })

    const clearChart = () => {
        dispatch(resetYearStatisticsChart());
        setConfirmationDialogVisible(false);
    };

    const changeTimeRange = ({ value }) => {
        dispatch(setTimeRange(value));

        const today = new Date();
        dispatch(setEndDate(today));

        switch (value) {
            case '20y':
            case '10y':
            case '5y':
            case '3y':
            case '1y':
                const count = Number(value.match(/\d/g).join(''));
                const start = createPastDate(count, 'year', today);
                checkLimit(start, endDate, period);

                dispatch(setStartDate(start));
                break;

            case 'lastQuarter':
                const firstQuarterDay = moment(today).subtract(3, 'months').format();
                checkLimit(firstQuarterDay, today, period);
                dispatch(setStartDate(new Date(firstQuarterDay)));
                break;

            case 'lastMonth':
                const firstMonthDay = moment(today).subtract(1, 'months').format();
                checkLimit(firstMonthDay, today, period);
                dispatch(setStartDate(new Date(firstMonthDay)));
                break;

            default:
        }
    }

    const changePeriod = ({ value }) => {
        checkLimit(startDate, endDate, value);

        if (value !== 'yearly') {
            dispatch(setValueType('absolute'));
        }

        dispatch(setPeriod(value));
        dispatch(setUpdate({}));
    }

    const showToast = (detail, summary = 'Warning', severity = 'warn', life = 5000, sticky = false) => {
        const msg = { detail, sticky, summary, severity, life };
        toast.current.show(msg);
    }

    const checkLimit = (start, end, period) => {
        const isOutOfLimit = checkRangeIsOutOfLimit(start, end, period);

        if (isOutOfLimit) {
            dispatch(setIsOutOfLimit(true));
            showToast('Too many hits. Please decrease time range or period.');
        } else {
            dispatch(setIsOutOfLimit(false));
        }
    }

    const changeValueType = (e) => {
        dispatch(setValueType(e.value));
        setTimeout(() => dispatch(setUpdate({})));
    }

    const changeChartType = (e) => {
        dispatch(setChartType(e.value));
        setTimeout(() => dispatch(setUpdate({})));
    }

    const showConfirmationDialog = () => {
        setConfirmationDialogVisible(true);
    }

    const onHideConfirmationDialog = () => {
        setConfirmationDialogVisible(false);
    }

    return (
        <>
            <div id='year-statistcs-panel' className={styles.statisticsPanel}>
                <div className={styles.wrapper}>
                    <Dropdown
                        id='trend-periods'
                        className="dropdownNoBorder"
                        options={periods}
                        value={period}
                        disabled={dataIsFetching}
                        onChange={changePeriod}
                    />

                    <Dropdown
                        id='trend-timeRanges'
                        className="dropdownNoBorder"
                        options={timeRanges}
                        value={timeRange}
                        disabled={dataIsFetching}
                        placeholder='Custom time range'
                        onChange={changeTimeRange}
                    />

                    <div className={styles.wrapper}>
                        <DatePicker showToast={showToast} checkLimit={checkLimit} />
                    </div>

                    <Dropdown style={{ marginLeft: 9 }}
                        id='trend-valueType'
                        className="dropdownNoBorder"
                        options={chartValueTypes}
                        value={valueType}
                        disabled={dataIsFetching}
                        onChange={changeValueType}
                    />

                    <Dropdown
                        id='trend-chartType'
                        className="dropdownNoBorder"
                        options={chartTypes}
                        value={chartType}
                        disabled={dataIsFetching}
                        onChange={changeChartType}
                    />
                </div>

                {!!isData && <div className={styles.linkWrapper}>
                    <Link title='Clear chart' onClick={showConfirmationDialog} />
                </div>}
            </div>

            <div className={styles.repositories} ref={ref}>
                {repositoryArray.length ? repositoryArray :
                    <Link title='Click here to add search to start a trend analysis ' onClick={() => dispatch(setShowModal(true))} />}
            </div>


            <ConfirmationDialog
                displayDialog={confirmationDialogVisible}
                onHide={onHideConfirmationDialog}
                onSubmit={() => {
                    clearChart();
                }}
                headerText="Confirm"
                messageText="Clear chart?"
                submitButtonLabel="Clear"
            />
            <Toast ref={toast} />
        </>
    );
});
