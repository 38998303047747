import { useRef } from 'react';
import styles from "./SearchItem.module.scss";
import { Dropdown } from "primereact/dropdown";
import {
    setRepositories,
    setSelectedSearches,
    setShowModal, setStatisticsChartData, setUpdate,
    setYearStatisticsSearchParameters
} from "../../../../../../redux/actions/YearStatisticsActions";
import { useDispatch, useSelector } from "react-redux";
import Link from "../../../../../common/Link/Link";
import { ColorBox } from "./ColorBox";
import { checkIsPeriodEnded } from "../../YearStatisticsChart/chartFunctions/checkIsPeriodEnded";
import { shortenLargeNumber } from "../../../../util";

export const SearchItem = ({ searchName, repoName, index, color, last, searchWidth, repoWidth }) => {
    const repositories = useSelector(({ yearStatistics: { repositories } }) => repositories);
    const dataIsFetching = useSelector(({ yearStatistics: { dataIsFetching } }) => dataIsFetching);
    const selectedSearches = useSelector(({ yearStatistics: { selectedSearches } }) => selectedSearches);
    const searchParameters = useSelector(({ yearStatistics: { searchParameters } }) => searchParameters);
    const dataForChart = useSelector(({ yearStatistics: { chartData } }) => chartData);
    const endDate = useSelector(({ yearStatistics: { endDate } }) => endDate);
    const period = useSelector(({ yearStatistics: { period } }) => period);
    const ref = useRef();
    const dispatch = useDispatch();

    const isLimited = selectedSearches.every(({ repoName }) => repoName);
    const data = dataForChart.find(({ name }) => name === searchName).data;
    const periodIsEnded = checkIsPeriodEnded(period, endDate);

    const summarize = (prev, curr) => {
        return prev + curr.cnt
    }

    const options = repositories[searchName]?.map(rep => {
        const statistics = data.find(({ name }) => name === rep.value).statistics;
        let hitsCount = statistics.reduce(summarize, 0);

        if (!periodIsEnded) {
            // mutatedValue is estimated. It should not be summarized
            const mutatedValue = statistics[statistics.length - 1]?.cnt || 0;
            hitsCount = hitsCount - mutatedValue;
        }

        return {
            ...rep,
            label: rep.disabled ? rep.label : `${rep.label} (${shortenLargeNumber(hitsCount, 0)} ${hitsCount > 1 ? 'hits' : 'hit'})`
        }
    })

    const changeSearchRepository = (e) => {
        const selected = [...selectedSearches];
        selected[index].repoName = e.value;

        dispatch(setSelectedSearches(selected));
        dispatch(setUpdate({}));
    }

    const duplicate = () => {
        const selected = [...selectedSearches];
        const target = selected.findIndex(repo => repo.searchName === null);
        selected[target].repoName = repoName;
        selected[target].searchName = searchName;

        dispatch(setSelectedSearches(selected));
    }

    const remove = () => {
        const selected = [...selectedSearches];
        const isTheOnlyItem = selectedSearches.filter(param => param.searchName === searchName).length <= 1;
        const _searchParameters = isTheOnlyItem ? searchParameters.filter(param => param.searchName !== searchName) : searchParameters;
        const _dataForChart = isTheOnlyItem ? dataForChart.filter(data => data.name !== searchName) : dataForChart;

        const _repositories = { ...repositories };

        if (isTheOnlyItem) {
            delete _repositories[searchName];
        }

        const target = selected.findIndex(repo => repo.color === color);

        /*selected[target].searchName = null;
        selected[target].repoName = null;*/
        let newObj = {searchName: null, repoName: null, color: selected[target].color}
        selected.splice(target, 1)
        selected.push(newObj)

        dispatch(setSelectedSearches(selected));
        dispatch(setYearStatisticsSearchParameters(_searchParameters));
        dispatch(setStatisticsChartData(_dataForChart));
        dispatch(setRepositories(_repositories));
    }

    return (
        <div className={styles.wrapper}>
            <div ref={ref} className={styles.searchName} style={{ width: searchWidth }} id='trend-searchItem'>
                <ColorBox color={color} />
                <p title={searchName} style={{ maxWidth: `${ref.current?.offsetWidth - 50}px` }}>{searchName}</p>
            </div>

            <div className={styles.repository} style={{ width: repoWidth }}>
                <p>Repository:</p>

                <Dropdown
                    id='trend-repositories'
                    className="dropdownNoBorder"
                    options={options}
                    value={repoName}
                    disabled={dataIsFetching}
                    onChange={changeSearchRepository}
                    filter
                />
            </div>

            {selectedSearches.filter(repo => repo.name).length !== 1 && <Link id='remove-link' title='Remove' onClick={remove} />}

            {!isLimited && <Link id='duplicate-link' title='Duplicate' onClick={duplicate} />}

            {!isLimited && last &&
                <Link id='add-link' title='Add search' onClick={() => dispatch(setShowModal(true))} />
            }
        </div>
    );
};
