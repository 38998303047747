/* eslint-disable jsx-a11y/anchor-is-valid */
import "./FilterQueryTabs.css";
import React from "react";
import FilterQueryTab from "./FilterQueryTab";
import InfoOverlayWithIcon from "../../../general/InfoOverlayWithIcon";


const FilterQueryTabs = ({
    queries = [{ query: "" }],
    activeIndex = 0,
    onClick,
    onFilterModeChange,
    onAddFilterQuery,
    onRemoveFilterQuery,
    maxNumOfFilterQueries = 3,
    tabWidth = 300
}) => {

    return <div className="filter-query-tabs grid">
        {queries.map((query, index) => {
            const styleClasses = `${index === activeIndex ? 'active' : ''} ${index === 0 ? 'first' : ''} ${index === queries.length - 1 ? 'last' : ''}`.trim();
            return <React.Fragment key={query.key}>
                <FilterQueryTab
                    index={index}
                    query={query}
                    styleClasses={styleClasses}
                    tabWidth={tabWidth}
                    onClick={onClick}
                    onFilterModeChange={onFilterModeChange}
                    onRemoveFilterQuery={onRemoveFilterQuery}
                />
            </React.Fragment>
        })}

        {queries?.length <= maxNumOfFilterQueries &&
            <div className="col-fixed tile-add-filter-query">
                <div className="add-filter-items">
                    <a className="add-filter-link" onClick={onAddFilterQuery}>Add filter query</a>
                    <InfoOverlayWithIcon
                        infoID="addFilterQuery"
                        title="Click for quick help about filter queries"
                        imageAltText="More information about filter queries"
                        overlayContent={
                            <div>
                                <h4>Filter queries</h4>
                                <div className="paragraph">
                                    An advanced search always requires a main query. But you can optionally add up to four
                                    filter queries as well.
                                </div>
                                <div className="paragraph">
                                    Each filter query consists of
                                    <ul>
                                        <li>a query producing a result set which is used to filter the result set of the main query</li>
                                        <li>a filter mode which specifies how the filter will be applied</li>
                                    </ul>

                                    The filter mode must be one of the following:
                                    <ul>
                                        <li>intersection (default): only documents returned by main and filter query are in the final result</li>
                                        <li>subtraction: results from filter query are removed from the main query result</li>
                                    </ul>
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>}
    </div>
}

export default FilterQueryTabs;