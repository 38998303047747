import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    fetchTermSuggestions,
    fetchDocumentSearchResults,
    fetchSortFields,
    fetchQueryString,
    addQuery,
    fetchIPCClasses,
    fetchStatistics,
    fetchAvailableUserRepositories
} from '../../../redux/actions/WebApiActions'
import * as Actions from '../../../redux/actions'
//import DocumentFinder from '..//DocumentFinder/DocumentFinder'
import QuickSearch from '../docsearch/QuickSearch'

const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    terms: state.webAPI.terms,
    documents: state.webAPI.documents,
    sortFields: state.webAPI.sortFields,
    queryString: state.webAPI.queryString,
    similarDocuments: state.webAPI.similarDocuments,
    publicationListXLSX: state.webAPI.publicationListXLSX,
    publicationListSimilarXLSX: state.webAPI.publicationListSimilarXLSX,
    ipcClasses: state.webAPI.ipcClasses,
    userData: state.user.data,
    statistics: state.webAPI.statistics
})

const mapDispatchToProps = dispatch => ({
    fetchTermSuggestions: (history, search, showFailGrowlSuggestions) => dispatch(fetchTermSuggestions(history, search, showFailGrowlSuggestions)),
    fetchDocumentSearchResults: (history, search, showFailGrowl) => dispatch(fetchDocumentSearchResults(history, search, showFailGrowl)),
    fetchSortFields: (history, search, showFailGrowl) => dispatch(fetchSortFields(history, search, showFailGrowl)),
    fetchQueryString: (history, search, showFailGrowlQueryString) => dispatch(fetchQueryString(history, search, showFailGrowlQueryString)),
    addQuery: (history, search, showQueryAddSuccessGrowl, showQueryAddFailGrowl) => dispatch(addQuery(history, search, showQueryAddSuccessGrowl, showQueryAddFailGrowl)),
    fetchStatistics: (history, search) => dispatch(fetchStatistics(history, search)),
    fetchIPCClasses: (history) => dispatch(fetchIPCClasses(history)),
    fetchAvailableUserRepositories: (history) => dispatch(fetchAvailableUserRepositories(history))

    //fetchRepositoriesForUser: (history) => dispatch(Actions.fetchRepositoriesForUser(history))
})

const QuickSearchContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickSearch))

export default QuickSearchContainer