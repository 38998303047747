export const dummyPopupData = [
    {
        "id": 1000,
        "preferredName": "WMAI-1",
        "originalName": "wheat monomeric inhibitor WMAI-1",
    },
    {
        "id": 1001,
        "preferredName": "theromyzon",
        "originalName": "therostasin protein",
    },
    {
        "id": 1002,
        "preferredName": "COVID-19",
        "originalName": "COronaVIrus Disease 2019",
    },
]
