/* eslint-disable jsx-a11y/anchor-is-valid */
import { TabPanel, TabView } from "primereact/tabview";

const SearchTermsHelp = () => {
    return (
        <TabView>
            <TabPanel header="Concepts">
                <div className="query-examples">
                    <h4>Search in ontologies</h4>
                    <div className="paragraph">
                        You can use the Domain Explorer to search for terms, see where they are located in our ontologies and select specific
                        concepts to add them to your query. Selected concepts will be added by their internal OCIDs.
                        Multiple concepts will be OR-combined and added as a mandatory group enclosed in parentheses by default.
                        <div className="query-example">+(ocid:"201000011510" ocid:"206010028980")</div>
                        <div className="query-example-info">if you searched for "cancer" in the Domain Explorer and selected the two concepts "cancer" and "Neoplasm" from the domain "Diseases".</div>
                    </div>
                    <h4>Concept ID</h4>
                    <div className="paragraph">
                        You can also add a concept to your query by using its internal OCID directly, e.g.
                        <div className="query-example">ocid:"190000000348"</div>
                        <div className="query-example-info">for the concept "steroids" from the "Chemistry" domain.</div>
                    </div>
                    <h4>Domain and term</h4>
                    <div className="paragraph">
                        You can also add concepts to your query by using the domain identifier and a term.
                        <div className="query-example">diseases:"cancer"</div>
                        <div className="query-example-info">which will match all concepts from the domain "Diseases" which have a synonym "cancer".</div>
                    </div>
                    <h4>Tipp: Translate OCIDs</h4>
                    <div className="paragraph">
                        Use the <a className="not-active">Translate OCIDs</a> button below the search field to show a translation of the "ocid" terms
                        in your query. It will add the domain and preferred name for each "ocid".
                        Your original query will not be altered, but you will see the result with the translated OCIDs in a box below the search field.
                    </div>
                    <div className="paragraph">
                        The translated query for the first example would look like this:
                        <div className="query-note">+([201000011510:diseases:"cancer"] [206010028980:diseases:"Neoplasm"])</div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Chemistry">
                <div className="query-examples">
                    <h4>InChI or InChI key</h4>
                    <div className="paragraph">
                        Instead of using names and ontologies, you may also directly use the InChI or InChI keys for
                        compounds.
                        <div className="query-example">+inchi:"InChI=1S/C6H6/c1-2-4-6-5-3-1/h1-6H"</div>
                        <div className="query-example-info">for "benzene".</div>
                        <div className="query-example">+inchikey:"MVPPADPHJFYWMZ-UHFFFAOYSA-N"</div>
                        <div className="query-example-info">for "chlorobenzene".</div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Text">
                <div className="query-examples">
                    <div className="query-examples-intro">
                        There are two different search indices for text searching available - exact and with variants
                        (also known as "stemming").
                    </div>
                    <h4>Exact</h4>
                    <div className="paragraph">
                        Full text search on nearly raw text (only case-insensitive).
                        <div className="query-example">tr:"amorph"</div>
                        <div className="query-example-info">matches "amorph" or "Amorph".</div>
                    </div>
                    <h5>Text distance</h5>
                    <div className="paragraph">
                        You can also use a distance search for text searches with this
                        syntax: tr:"TERM_OR_PHRASE"~DISTANCE. The distance defines the maximum distance of words. Without the ~ it matches
                        only in the exact word order.
                        <div className="query-example">tr:"progressive fibrosis"~5</div>
                        <div className="query-example-info">will find e.g. "progressive fibrosis", "pregressive renal fibrosis", "progressive renal
                            and pulmonary fibrosis" or "fibrosis being progressive".</div>
                    </div>
                    <h5>Wildcards</h5>
                    <div className="paragraph">
                        We support wildcards for single and multiple characters in single terms or terms within phrases.
                        <br />
                        <h6>"?" is used as a single character wildcard:</h6>
                        <div className="query-example">tr:"te?t"</div>
                        <div className="query-example-info">will find e.g. "text" or "test".</div>
                        <h6>"*" is used as a multiple character wildcard:</h6>
                        <div className="query-example">tr:"test*"</div>
                        <div className="query-example-info">will find e.g. "test" or "tests" or "testing"</div>
                        <div className="query-note">Note: Wildcards <b>must not</b> be used as the first character of a term.</div>
                    </div>
                    <h5>Regular expressions</h5>
                    <div className="paragraph">
                        Single terms or terms within phrases can be described by a regular expression.
                        The typical standard operators of regular expressions are supported:
                        <div className="query-note">
                            <span>{`. ? * + | {MIN, MAX} (GROUPED) [CHAR_SELECTION] [^NEGATED_CHAR_SELECTION]`}</span>
                        </div>
                        The expression has to be wrapped within a pair of slashes: /EXPRESSION/.
                        The terms need to match the regular expression completely.
                        <div className="query-example">tr:"/te.t/"</div>
                        <div className="query-example-info">will find e.g. "test", "text"</div>
                        <div className="query-example">tr:"easy /te[sx]ts?/"</div>
                        <div className="query-example-info">will find e.g. "easy test", "easy texts"</div>
                        <div className="query-note">
                            Note: A broad regular expression matching a very large number of terms will slow down the
                            query execution. It is advisable to not use wildcards for the first character of a term.
                        </div>
                    </div>
                    <h4>With variants</h4>
                    <div className="paragraph">
                        Performs a full text search on English stemmed text and finds variants of the searched term or phrase.
                        <div className="query-example">t:"amorph"</div>
                        <div className="query-example-info">matches "amorph","amorphous", and "amorphization" etc.</div>
                    </div>
                    <h5>Text distance</h5>
                    <div className="paragraph">
                        You can also use a distance search for stemmed text searches with this
                        syntax: <span className="whiteSpaceNoWrap">t:"TERM_OR_PHRASE"~DISTANCE</span>. The distance defines the maximum distance of words. Without the ~ it matches
                        only in the exact word order.
                        <div className="query-example">t:"matrix amorph"~2</div>
                        <div className="query-example-info">will find e.g. "amorphous matrix", "amorphous ceramic matrix", "amorphous host matrix" and
                            "matrix was amorphous".</div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    );
}

export default SearchTermsHelp;