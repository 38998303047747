import { sendApiRequest } from '../index'
import { APP_PROPERTIES } from '../../properties/index';
import { createTermSuggestionsRequest } from './ConceptApi'
//import {addMergedSuggestions, addDirectMatch, isArrayEmpty} from '../../components/webapi/util';
import { isArrayEmpty } from '../../components/webapi/util';

/**
 *
 *
 */
export const fetchSearchResults = async (data) => {
    const { startIndex, count, ...restData } = data;

    return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/biomarker/relationsv3/count/${count}/start/${startIndex}`, restData);
}

export const fetchMetaData = async ({ docIds, repoId }) => {
    const query = docIds.map(item => `docid:"${item}"`);

    // there are same docIds. send to api only unique
    const uniqueQuery = new Set(query);

    const data = {
        // should be sent string "docid:1 docid:2 docid:3 docid:4"
        query: [...uniqueQuery].join(' '),
        api: "oc-webapi",
        repository: repoId,
        sortBy: "pdate",
        sortDirection: "descending"
    }
    return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/documents?repositoryId=${repoId}&count=${docIds.length}&start=1`, data);
}

/**
 * Fetches term suggestions for the given term within given filter domains.
 *
 * @param {string} term term fragment
 */
export const fetchBiomarkerSuggestions = async (term, repositoriesIdArray, filterDomains, filterOcids = null,
    annotatedTermsOnly = true, termsOnly = true, addFreetextOptions = false) => {

    // --- fetch term suggestions --- //
    const request = createTermSuggestionsRequest(term, filterDomains, 20, true, true, annotatedTermsOnly, filterOcids);
    //delete request.repository;
    const useDomainIndex = request.useDomainIndex;
    delete request.useDomainIndex;

    request.repositories = repositoriesIdArray;

    //const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions?mergeResults=true${!!useDomainIndex ? '&useDomainIndex=true' : ''}`, request);
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/biomarker/suggestions/all`, request);
    //console.log('result: ', result);

    if (result.status === 'SUCCESS') {
        //console.log(result)
        let terms = [];
        if (addFreetextOptions) {
            addFreetextOptions(term, terms);
        }
        //const directMatch = addDirectMatch(result.payload.directFragmentMatches, term, termsOnly, terms);
        //console.log(directMatch)
        //addMergedSuggestions(result.payload.mergedSuggestedTerms, term, termsOnly, terms, directMatch);

        //console.log('terms: ', terms);
        terms = result.payload
        return ({ status: 'SUCCESS', payload: terms });  // payload: { terms: terms, termSnippet: term }
    }

    return result;
}

export const fetchBiomarkerRoleMapping = async () => {
    const { payload } = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/biomarker/roleMapping`);
    return payload;
};
