import React, { Component } from "react";
import { Dialog } from 'primereact/dialog';
import OntologyBrowser from './OntologyBrowser';
import "./OntologyBrowser.css";

class OntologyBrowserDialog extends Component {

  constructor(props) {
    super(props);
    // --- set initial search term when ontology browser is opened --- //
    this.state = { setInitialSearchTerm: true }
  }

  onShow = (e) => {
    // --- prevent initial search term from being set after inital load of ontology browser --- //
    this.setState({ setInitialSearchTerm: false });
    if (this.props.onOntBrowserShow) { this.props.onOntBrowserShow(); }
  }

  onHide = () => {
    // --- reset, so initial search term will be set next time ontology browser is opened --- //
    this.setState({ setInitialSearchTerm: true });
    if (this.props.onOntBrowserClose) {
      this.props.onOntBrowserClose();
    }
  }

  onSubmit = (selectedConcepts) => {
    this.props.onOntBrowserSubmit(selectedConcepts);
    this.onHide();
  }

  render() {
    const { ontBrowserDialogID, headerLabel, selectionMode, placeholder, placeholderMatches, numOfChildNodes, domains, rangeOcids,
      loadOntologiesOnStart, allowConceptSearchByClick, allowSearchInOntologies, width, height, initialSearchTerm, initialOcids,
      ontBrowserVisible, resetBrowser } = this.props;

    const dialogID = ontBrowserDialogID || "ontBrowserDialog";

    return (
      <Dialog focusOnShow={false}
        id={dialogID}
        header={headerLabel}
        visible={ontBrowserVisible}
        style={{ width: width, height: height }}
        modal={true}
        onShow={(e) => this.onShow()}
        onHide={(e) => this.onHide()}
        blockScroll
        className='styledDialogPadding'
      >
        <OntologyBrowser
          containerID={dialogID}
          selectionMode={selectionMode}
          containerHeaderHeight={60}
          domains={domains}
          rangeOcids={rangeOcids}
          initialSearchTerm={this.state.setInitialSearchTerm ? initialSearchTerm : null}
          initialOcids={this.state.setInitialSearchTerm ? initialOcids : null}
          numOfChildNodes={numOfChildNodes}
          allowConceptSearchByClick={allowConceptSearchByClick}
          allowSearchInOntologies={allowSearchInOntologies}
          placeholder={placeholder}
          placeholderMatches={placeholderMatches}
          loadOntologiesOnStart={loadOntologiesOnStart}
          onSubmit={this.onSubmit}
          resetBrowser={resetBrowser}
          submitBtnLabel="Confirm"
          submitBtnCssClass="primaryButton p-button-sm" />
      </Dialog>
    )
  }
}

export default OntologyBrowserDialog;
