import React, {useState} from 'react';
import ConfirmationDialog from '../../../../common/ConfirmDialog/ConfirmationDialog';
import {useDispatch, useSelector} from "react-redux";
import {
    setBiomarkerInputText,
    setBiomarkerQueryTerms,
    setBiomarkerSuggestions,
    setBiomarkerFirstResultIndex,
    setBiomarkerResultCount, resetBiomarkerState
} from "../../../../../redux/actions/BiomarkerActions";
import {defaultResultCount} from "../../../../../redux/reducers/biomarker";
import SearchControls from "../../../../common/SearchControls/SearchControls";
import SearchInput from "../../../../common/SearchInput/SearchInput";
import {fetchBiomarkerSuggestions} from "../../../../../api/content/BiomarkerApi";
import {checkResultAndGetPayload} from "../../../../../api";
import {cutString} from "../../../../common/helpers/cutString";
import SearchTemplate from "../../../../common/SearchTemplate/SearchTemplate";
import SuggestionTemplate from "../../../../common/SuggestionTemplate/SuggestionTemplate";
import {
    biomarkerInputText,
    biomarkerQueryTerms, biomarkerRoleMapping,
    biomarkerSelectedReposForSearch,
    biomarkerSuggestions
} from "../../../../../redux/selectors/selectors";
import {addSearchParamsToQueryTerms} from "../../../../common/helpers/addSearchParamsToQueryTerms";
import {createOptionsForSearchTemplate} from "../../../../common/helpers/createOptionsForSearchTemplate";

let latestTermFragment;

export const BiomarkerSearch = ({
                                    repositoriesIdList,
                                    setNeedSearch,
                                    setIsSaveDialogVisible, showToast, clearToast
                                }) => {

    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);

    const dispatch = useDispatch();

    const queryTerms = useSelector(biomarkerQueryTerms);
    const selectedReposForSearch = useSelector(biomarkerSelectedReposForSearch);
    const suggestions = useSelector(biomarkerSuggestions);
    const inputText = useSelector(biomarkerInputText);
    const roleMapping = useSelector(biomarkerRoleMapping);

    const handleInputSelect = (value) => {
        if (queryTerms && queryTerms.length > 0) {
            let filteredObject = queryTerms.filter(function (entry) {
                return entry.term[0] === "Any" && entry.domainTerms
            })
            let filteredItemObject = filteredObject.filter(function (entryItem) {
                return value.biomarkerRoles && roleMapping.find(role => role.name === entryItem.fieldName)?.label === value.biomarkerRoles[0]
            })
            if (filteredItemObject.length > 0) {
                showToast(`You already used the "Any" filter for this role. The specific term (${value.term}) you want to filter will be ignored for your search.`, 'Warning', 'warn');
            }
        }
    }


    const handleInputChange = (values) => {
        const _queryTerms = addSearchParamsToQueryTerms(values)
        dispatch(setBiomarkerQueryTerms(_queryTerms));
    }

    const onHideConfirmationDialog = () => {
        setDisplayConfirmationDialog(false);
    }

    const onClearAll = () => {
        setDisplayConfirmationDialog(true);
    }

    const setInitialState = () => {
        setDisplayConfirmationDialog(false);
        dispatch(resetBiomarkerState());
    }

    const onSearchClick = () => {
        dispatch(setBiomarkerFirstResultIndex(0));
        dispatch(setBiomarkerResultCount(defaultResultCount));
        // hack for handle search with actual parameters. setting empty object and pass it as dependency in useEffect in BiomarkerRightAside
        // @todo redesign this logic
        setNeedSearch({});
    }

    const saveAndAlert = () => {
        setIsSaveDialogVisible(true);
        console.log('Save and alert');
    }

    const completeMethod = (event) => {
        try {
            clearToast();

            latestTermFragment = event.query;
            setTimeout(async () => {
                // --- fetch term suggestions --- //
                let terms;

                const repositoriesIdArray = selectedReposForSearch.map(repo => repo.id);

                if (repositoriesIdArray.length) {
                    const response = await fetchBiomarkerSuggestions(event.query, repositoriesIdArray);

                    terms = checkResultAndGetPayload(response);

                    if (event.query === latestTermFragment && terms) {
                        const textForSearch = {term: event.query, fieldName: 'simplesearch'};
                        const filteredObject = terms.filter(function (entry) {
                            return entry.directMatch === true
                        })

                        dispatch(setBiomarkerSuggestions(filteredObject.length > 0 ?
                            [...terms, textForSearch] : [textForSearch, ...terms]));
                        dispatch(setBiomarkerInputText(event.query));
                    } 

                } else {
                    showToast('Select at least one repository', 'No repositories selected', 'error', 6000);
                }

            }, 200);
        } catch {
            showToast(`Something went wrong while getting suggestions`, 'Error occurred', 'error', 10000);
        }
    };

    const searchTermTemplate = (item) => {
        const toolTip = item.term;
        // if no fieldName, biomarkerRole is used like key in map. fieldName is set from left aside facettes
        const labelFromMap = roleMapping?.find(role => role.name === item.fieldName)?.label;
        const label = item.biomarkerRoles?.[0];
        let info = labelFromMap || label || 'Free Text';

        // term from autocomplete is array with one item. to cut term check it before
        const term = typeof item.term === 'string' ? item.term : item.term?.[0];
        const maxTermLength = 20;

        const truncatedTerm = cutString(term, maxTermLength);

        const {
            hasTooltip,
            hasDropDown,
            dropdownValue,
            dropdownClassName,
            dropdownOptions
        } = createOptionsForSearchTemplate(item, truncatedTerm);

        return <SearchTemplate dropdownClassName={dropdownClassName} dropdownOptions={dropdownOptions}
                               dropdownValue={dropdownValue} info={info}
                               hasDropDown={hasDropDown} hasTooltip={hasTooltip} toolTip={toolTip}
                               truncatedTerm={truncatedTerm} item={item} queryTerms={queryTerms}
                               handleInputChange={handleInputChange} handleInputSelect={handleInputSelect} showToast={showToast}/>
    }

    const suggestionTemplate = (item) => <SuggestionTemplate item={item} inputText={inputText}/>

    return (
        <>
            <SearchInput queryTerms={queryTerms}
                         handleInputChange={handleInputChange}
                         handleInputSelect={handleInputSelect}
                         suggestions={suggestions}
                         completeMethod={completeMethod}
                         searchTermTemplate={searchTermTemplate}
                         suggestionTemplate={suggestionTemplate}
            />

            <ConfirmationDialog
                displayDialog={displayConfirmationDialog}
                onHide={onHideConfirmationDialog}
                onSubmit={setInitialState}
                headerText="Confirm"
                messageText="Clear all input?"
                submitButtonLabel="Clear"
            />

            <SearchControls searchIsDisabled={!repositoriesIdList?.length} onSearchClick={onSearchClick}
                            onClearAll={onClearAll} saveAndAlert={saveAndAlert} completeMethod={completeMethod}/>
        </>
    );
};
