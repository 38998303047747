import React, {useState} from 'react';
import styles from "./StructureTemplate.module.scss";
import {Tooltip} from "primereact/tooltip";
import {ShowDetailsLink} from "./ShowDetailsLink";

export const ImageWithTooltip = ({structure, compound, ocid}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className={styles.container}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
        >

            {isHovered ?
                <>
                    <Tooltip className="bioactivities-structure-tooltip" target='.bioactivities-structure'
                             baseZIndex={10} onHide={() => setIsHovered(false)}>
                        <img src={structure}
                            data-pr-tooltip="PrimeReact-Logo" height="300px" alt='Structure scheme' />
                        <p>{compound}</p>
                    </Tooltip>

                    <ShowDetailsLink ocid={ocid}/>
                </> :

                <img src={structure} alt='Structure scheme' width='100%' height='100%'/>}
        </div>
    );
};
