import { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import InputFieldWithClear from "../../general/inputfields/InputFieldWithClear";
import AutoComplete from "../../../common/AutoComplete/AutoComplete";
import { fetchMergedTermSuggestionsV2 } from "../../../../api/content/ConceptApi";
import { checkResultAndGetPayload } from "../../../../api";
import ChemistryAutocompleteSearchFieldItem from "./ChemistryAutocompleteSearchFieldItem";
import ChemistryAutocompleteSuggestionListItem from "./ChemistryAutocompleteSuggestionListItem";
import { CHEMISTRY_BULK_IMPORT_FILE_FORMATS, CHEMISTRY_BULK_IMPORT_TYPES, CHEMISTRY_QUERY_TERM_TYPES, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND, CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT } from "../utils/chemistrySearchConstants";
import BulkImportDialog from "../../bulkimport/components/BulkImportDialog";
import { createFilterTermForBulkImport } from "../utils/chemistryQueryTerms";
import { addQueryTermTypesToQueryTerms, addQueryTermTypesToSuggestions, determineIdentifierTypeForTerm } from "../utils/autocomplete";
import { isArrayEmpty, isObjectEmpty } from "../../util";
import SeparatorPoint from "../../general/SeparatorPoint";


const TERM_SUGGESTION_DOMAINS = ['chem'];
const TERM_SUGGESTION_FILTER_RANGE_COMPOUNDS_AND_CLASSES = { start: 150000000000, end: 199999999999 };
const TERM_SUGGESTION_FILTER_RANGE_CLASSES_ONLY = { start: 150000000000, end: 159999999999 };
const TERM_SUGGESTION_FILTER_RANGE_COMPOUNDS_ONLY = { start: 190000000000, end: 199999999999 };
const TERM_SUGGESTION_FILTER_RANGE_NONE = { start: 0, end: 0 };

const LOADING_ENTRY = { term: 'Searching synonyms', loader: true };
const MIN_LENGTH = 2;


class ChemistryAutocompleteInputField extends Component {

    constructor(props) {
        super(props);

        const inputTypes = Object.values(CHEMISTRY_QUERY_TERM_TYPES)?.filter(qtType => qtType.allowSelection);
        this.state = {
            inputTypes: inputTypes,
            key: 'chemInputField'
        }; // @todo , acKey: 'acKey1'

        this.growl = createRef();

        this.selItemTemplate = this.selItemTemplate.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.queryTerms !== this.props.queryTerms) {
            // --- update background colors of query terms --- //
            const boxes = document.querySelectorAll('#searchInput ul li.p-autocomplete-token.p-highlight');
            if (!isArrayEmpty(boxes)) {
                for (var child of boxes) {
                    if (child.querySelector('.type-filter')) {
                        child.style.backgroundColor = '#ffffff';
                    }
                    else if (child.querySelector('.type-compound')) {
                        child.style.backgroundColor = '#E8F3FC';
                    }
                    else if (child.querySelector('.type-class')) {
                        child.style.backgroundColor = '#E8F3FC';
                    }
                    else if (child.querySelector('.type-concept')) {
                        child.style.backgroundColor = '#E8F3FC';
                    }
                    else if (child.querySelector('.type-property')) {
                        child.style.backgroundColor = '#ffffff';
                    }
                    else if (child.querySelector('.type-unknown')) {
                        child.style.backgroundColor = '#ffe4e1';
                    }
                }
            }
        }
    }

    selItemTemplate(item) {
        return <ChemistryAutocompleteSearchFieldItem
            item={item}
            filterDefinitions={this.props.filterDefinitions}
            domainLabelsMap={this.props.domainLabelsMap}
            onClickItem={(term) => this.onSpecifyQueryTerm(term)}
        />
    }

    selSingleItemTemplate(item) {
        return <span style={{ background: 'red' }}>*{item.term}</span>
    }

    itemTemplate(item) {
        return <ChemistryAutocompleteSuggestionListItem
            item={item}
            queryPart={this.state.part}
            domainLabelsMap={this.props.domainLabelsMap}
            onSpecify={(term) => this.onSpecifyQueryTerm(term)}
        />;
    }

    onSpecifyQueryTerm = (term) => {
        if (term.filterID === CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT) {
            this.setState({
                bulkIDType: term.bulkIDType,
                bulkIDs: term.bulkIDs,
                bulkImportTermToReplace: term,
                displayBulkImportDialog: true
            });
        }
        else {
            /*this.setState({
                displayEditDialog: true,
                selectedType: term.type,
                editTerm: term
            });*/
            this.props.onReplaceQueryTermWithDomainExplorer(term);
        }
    }

    onSpecifyBulkImportQueryTerm = (bulkIDType, bulkIDs) => {
        const bulkImportQueryTerm = createFilterTermForBulkImport({ id: CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT }, bulkIDType, bulkIDs);
        this.props.onReplaceQueryTerm(this.state.bulkImportTermToReplace, bulkImportQueryTerm);
        this.setState({ displayBulkImportDialog: false });
    }


    determindeRangeFilter = () => {
        let filterRange = TERM_SUGGESTION_FILTER_RANGE_NONE;
        if (this.props.includeChemClasses && this.props.includeChemCompounds) {
            filterRange = TERM_SUGGESTION_FILTER_RANGE_COMPOUNDS_AND_CLASSES;
        }
        else if (this.props.includeChemCompounds) {
            filterRange = TERM_SUGGESTION_FILTER_RANGE_COMPOUNDS_ONLY;
        }
        else if (this.props.includeChemClasses) {
            filterRange = TERM_SUGGESTION_FILTER_RANGE_CLASSES_ONLY;
        }
        return filterRange;
    }

    /**
     * Fetches suggestions for term fragment. Suggestions will be merged if they have
     * the same OCID. Free text option will be added if there are no direct matches.
     * @param {*} event 
     */
    autocomplete = (termFragment) => {

        this.latestTermFragment = termFragment;
        //this.props.onNonValidatedInputChange(termFragment);

        if (!!document.getElementsByClassName('chem-suggestion-list')) {
            //this.setState({ termSuggestions: [LOADING_ENTRY] });
            this.setState({
                termSuggestions: [{
                    "hide": true,
                    "label": "",
                    "items": [LOADING_ENTRY]
                }]
            });
        }

        setTimeout(async () => {
            let termSuggestionsGrouped = [];
            const groupConfig = [];

            const promises = [];
            // request compounds if allowed
            if (this.props.includeChemCompounds) {
                promises.push(this.fetchSuggestions(termFragment, TERM_SUGGESTION_FILTER_RANGE_COMPOUNDS_ONLY, false, 7));
                groupConfig.push({ label: 'Chemical compounds', css: 'chemComp' });
            }
            // request classes if allowed
            if (this.props.includeChemClasses) {
                promises.push(this.fetchSuggestions(termFragment, TERM_SUGGESTION_FILTER_RANGE_CLASSES_ONLY, false, 7));
                groupConfig.push({ label: 'Chemical classes', css: 'chemClass' });
            }
            // send asynchronous requests
            const allPromisesWithErrorHandler = promises.map(promise => promise.catch(error => error));
            // if term is outdated - reject result
            if (!this.isLatestTermFragment(termFragment)) {
                return;
            }
            // all requests are cmpleted -> check results
            Promise.all(allPromisesWithErrorHandler).then(results => {
                // add all results to suggestion list, if they are not empty
                let cnt = 0;
                results.forEach(res => {
                    if (res) {
                        //console.log('res', res);
                        if (!isArrayEmpty(res)) {
                            termSuggestionsGrouped.push({
                                ...groupConfig[cnt],
                                items: res || []
                            });
                        }
                        cnt++;
                    }
                });
                // if compounds were not allowed -> add group with message
                if (!this.props.includeChemCompounds) {
                    termSuggestionsGrouped.push({
                        label: "Chemical compounds",
                        description: "not available for current search input or settings",
                        items: []
                    });
                }
                // if classes were not allowed -> add group with message
                if (!this.props.includeChemClasses) {
                    termSuggestionsGrouped.push({
                        label: "Chemical classes",
                        description: "not available for current search input or settings",
                        items: []
                    });
                }
                // if term is outdated - reject result
                if (!this.isLatestTermFragment(termFragment)) {
                    return;
                }

                // CC(=O)OC1=CC=CC=C1C(O)=O
                // BSYNRYMUTXBXSQ-UHFFFAOYSA-N
                let termSuggestionsNew = termSuggestionsGrouped;
                // send to auto-recognizer to check type
                const fetchIdentifierType = async () => {
                    const identifierType = await determineIdentifierTypeForTerm(termFragment);
                    //console.log('identifierType', identifierType);
                    termSuggestionsNew = [...termSuggestionsGrouped];
                    // if term was recognized -> add to suggestions
                    if (!isObjectEmpty(identifierType)) {
                        if (identifierType.type) {
                            const qt = {
                                term: termFragment,
                                type: identifierType.type,
                                subType: identifierType.subType ? identifierType.subType : CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
                                queryValues: [termFragment],
                                label: termFragment
                            };
                            const cat = {
                                label: `Auto-recognized as ${identifierType.type?.toUpperCase()}`,
                                description: `please select if you would like to proceed with this input`,
                                css: "freeText",
                                items: [qt]
                            };
                            termSuggestionsNew.unshift(cat);
                        }
                    }
                    //console.log('termSuggestionsNew', termSuggestionsNew);

                    if (isArrayEmpty(termSuggestionsNew) || !termSuggestionsNew.some(ts => !isArrayEmpty(ts.items))) {
                        termSuggestionsNew.unshift({
                            "type": "freetext",
                            "label": "Unknown term",
                            "css": "freeText",
                            "items": [] || []
                        });
                    }

                    this.setState({
                        termSuggestions: termSuggestionsNew,
                        part: termFragment
                    });
                }
                fetchIdentifierType();

            });
        }, 200);
    }

    isLatestTermFragment = (termFragment) => {
        return termFragment === this.latestTermFragment;
    }

    fetchSuggestions = async (termFragment, filterRange, addFreetext = false, maxNumOfResults = 5) => {

        let terms;
        const response = await fetchMergedTermSuggestionsV2(termFragment, TERM_SUGGESTION_DOMAINS, null, filterRange, false, false, addFreetext, maxNumOfResults); // false
        const result = checkResultAndGetPayload(response, this.growl);

        if (this.isLatestTermFragment(termFragment) && result?.suggestions) {
            terms = addQueryTermTypesToSuggestions(result.suggestions, termFragment);
            //termSuggestionsMerged = [...termSuggestionsMerged, ...terms];
        }
        return terms;
    }

    onQueryTermsChange = async (queryTerms) => {

        if (queryTerms?.indexOf(LOADING_ENTRY) > -1) {
            //queryTerms = removeValueFromArray(queryTerms, LOADING_ENTRY);
            return;
        }
        //console.log('queryTerms changed: ', queryTerms)
        //const filterRange = this.determindeRangeFilter();
        let queryTermsNew = await addQueryTermTypesToQueryTerms(queryTerms, TERM_SUGGESTION_DOMAINS,
            TERM_SUGGESTION_FILTER_RANGE_COMPOUNDS_ONLY, TERM_SUGGESTION_FILTER_RANGE_CLASSES_ONLY);
        this.props.onQueryTermsChange(queryTermsNew);
    }

    groupedItemTemplate(item) {
        if (item.hide) {
            return null;
        }
        return (
            <div className="suggestion-group-entry">
                <div className={`info-bar info-bar-${item.css || 'default'}`}></div>
                <div className="suggestion-group">
                    <span className="suggestion-group-label">{item.label}</span>
                    {item.description && <span className="suggestion-group-description">
                        <SeparatorPoint />
                        {item.description}
                    </span>}
                </div>
                {item.info && <div className="suggestion-group-info">{item.info}</div>}
            </div>
        );
    }

    /* @todo
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (this.state.termSuggestions && this.state.termSuggestions[0]?.items && this.state.termSuggestions[0].items[0]) {
                // add first entry from list to search field
                this.onQueryTermsChange([...this.props.queryTerms || [], this.state.termSuggestions[0].items[0]]);
                this.setState({ acKey: 'acKey' + Math.random() }); // hack
            }
        }
    }*/

    render() {

        const { queryTerms, onReplaceQueryTerm } = this.props;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                {/* 
                <AutoComplete
                    id="searchInput"
                    field="term"
                    multiple={true}
                    value={queryTerms}
                    suggestions={this.state.termSuggestions}
                    completeMethod={(e) => this.autocomplete(e.query)}
                    onChange={(e) => this.onQueryTermsChange(e.value)}
                    minLength={MIN_LENGTH}
                    //placeholder="Type search (e.g. liver disease)"
                    className="autocomplete-input-field"
                    panelClassName="chem-suggestion-list"
                    selectedItemTemplate={this.selItemTemplate}
                    itemTemplate={this.itemTemplate}
                    // not working:
                    //forceSelection={true}
                    //autoHighlight={true}
                    //scrollHeight="500px"
                />*/}

                {/*
                <AutoComplete
                    id="searchInput"
                    //field="term"
                    multiple={true}
                    value={queryTerms}
                    suggestions={this.state.termSuggestions}
                    onChange={(e) => this.onQueryTermsChange(e.value)}
                    completeMethod={(e) => this.autocomplete(e.query)}
                    minLength={MIN_LENGTH}
                    className="autocomplete-input-field"
                    panelClassName="chem-suggestion-list"
                    selectedItemTemplate={this.selItemTemplate}
                    itemTemplate={this.itemTemplate}
                    autoHighlight={true}
                    // not working
                    //forceSelection={true}
                    //autoFocus={true}
                    //value={this.state.selectedCity}
                    //suggestions={filteredCities}
                    //completeMethod={this.searchCity}
                    field="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionGroupTemplate={this.groupedItemTemplate}
                    //onChange={(e) => this.setState({ selectedCity: e.value })}
                    aria-label="Cities"
                />
                */}

                <InputFieldWithClear
                    onClear={() => this.onQueryTermsChange([])}
                    showIcon={!isArrayEmpty(queryTerms)}>
                    <AutoComplete
                        key={this.state.key}
                        id="searchInput"
                        multiple={true}
                        value={queryTerms}
                        suggestions={this.state.termSuggestions}
                        onChange={(e) => { this.onQueryTermsChange(e.value || []) }}
                        onUnselect={(e) => { this.setState({ key: `chemInputField_${Math.floor(Math.random() * 10000)}` }) }}
                        completeMethod={(e) => this.autocomplete(e.query)}
                        minLength={MIN_LENGTH}
                        className="autocomplete-input-field width100perc"
                        panelClassName="chem-suggestion-list"
                        optionGroupTemplate={this.groupedItemTemplate}
                        itemTemplate={this.itemTemplate}
                        selectedItemTemplate={this.selItemTemplate}
                        field="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        aria-label="Chemistry"
                        autoHighlight={true}
                        // @todo key={this.state.acKey}
                        // @todo onKeyPress={(e) => this.onKeyPress(e)}
                    />
                </InputFieldWithClear>

                {/*
                <EditAutocompleteInputDialog
                    displayEditDialog={this.state.displayEditDialog}
                    onToggleDialog={() => this.setState({ displayEditDialog: false })}
                    originalTerm={this.state.editTerm}
                    onReplaceQueryTerm={onReplaceQueryTerm}
                />
                
                <SpecifyChemClassCompoundDialog
                    displayEditDialog={this.state.displayEditDialog}
                    onToggleDialog={() => this.setState({ displayEditDialog: false })}
                    originalTerm={this.state.editTerm}
                    onReplaceQueryTerm={onReplaceQueryTerm}
                />
                */}

                <BulkImportDialog
                    displayDialog={this.state.displayBulkImportDialog}
                    valueTypes={Object.values(CHEMISTRY_BULK_IMPORT_TYPES)}
                    acceptedFileTypes={CHEMISTRY_BULK_IMPORT_FILE_FORMATS}
                    maxValues={500}
                    initialValueType={this.state.bulkIDType}
                    initialValues={this.state.bulkIDs}
                    onSubmit={this.onSpecifyBulkImportQueryTerm}
                    onHide={e => { this.setState({ displayBulkImportDialog: false }) }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap
})
const mapDispatchToProps = (dispatch) => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChemistryAutocompleteInputField));