export const QUERY_BUILDER_EXAMPLES = [
    // {
    //     // id is mandatory, it will be used as key,
    //     // since there can be multiple sections
    //     id: "diabeticRetinopathies",
    //     examples: [{
    //         label: 'Diabetic Retinopathies',
    //         description: 'simple example',
    //         searchGroups: [
    //             {
    //                 "id": "id_1701941407684",
    //                 "type": SEARCH_GROUP_TYPE_TERM,
    //                 "creationTime": "2023-12-07 10:12",
    //                 "queryTerms": [
    //                     {
    //                         "term": "diabetic retinopathy",
    //                         "label": "diabetic retinopathy",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "Diabetic retinopathy"
    //                         ],
    //                         "otherTerms": [
    //                             "diabetic macular retinopathy",
    //                             "pre-proliferative diabetic retinopathy",
    //                             "preproliferative diabetic retinopathy",
    //                             "retinopathy diabetic"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "diabetic retinopathy"
    //                         ],
    //                         "textDistance": 2
    //                     },
    //                     {
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "term": "diabetic retinopath",
    //                         "label": "diabetic retinopath",
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "diabetic retinopath"
    //                         ],
    //                         "textDistance": 2
    //                     },
    //                     {
    //                         "term": "diabetic macular edema",
    //                         "label": "diabetic macular edema",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "diabetic macular edema",
    //                             "Diabetic retinal oedema"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "diabetic macular edema"
    //                         ],
    //                         "textDistance": 3
    //                     },
    //                     {
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "term": "diabetic macular",
    //                         "label": "diabetic macular",
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "diabetic macular"
    //                         ],
    //                         "textDistance": 2
    //                     },
    //                     {
    //                         "ocids": [
    //                             206010012689
    //                         ],
    //                         "domains": [
    //                             "diseases"
    //                         ],
    //                         "prefnames": [
    //                             "Diabetic retinopathy"
    //                         ],
    //                         "term": "Diabetic retinopathy",
    //                         "label": "Diabetic retinopathy",
    //                         "queryValues": [
    //                             "Diabetic retinopathy"
    //                         ],
    //                         "type": "concept"
    //                     }
    //                 ],
    //                 "searchCriteria": {
    //                     "termloc": {
    //                         "filterValue": "title",
    //                         "filterValueLabel": "Title"
    //                     }
    //                 },
    //                 "operator": "or"
    //             },
    //             {
    //                 "id": "id_1701943026238",
    //                 "type": SEARCH_GROUP_TYPE_TERM,
    //                 "creationTime": "2023-12-07 10:12",
    //                 "queryTerms": [
    //                     {
    //                         "ocids": [
    //                             231200002851
    //                         ],
    //                         "domains": [
    //                             "clinicaltrials"
    //                         ],
    //                         "prefnames": [
    //                             "population group"
    //                         ],
    //                         "term": "population group",
    //                         "label": "population group",
    //                         "queryValues": [
    //                             "population group"
    //                         ],
    //                         "type": "concept"
    //                     },
    //                     {
    //                         "ocids": [
    //                             231200000368
    //                         ],
    //                         "domains": [
    //                             "clinicaltrials"
    //                         ],
    //                         "prefnames": [
    //                             "treatment group"
    //                         ],
    //                         "term": "treatment group",
    //                         "label": "treatment group",
    //                         "queryValues": [
    //                             "treatment group"
    //                         ],
    //                         "type": "concept"
    //                     },
    //                     {
    //                         "ocids": [
    //                             231200000356
    //                         ],
    //                         "domains": [
    //                             "clinicaltrials"
    //                         ],
    //                         "prefnames": [
    //                             "patient"
    //                         ],
    //                         "term": "patient",
    //                         "label": "patient",
    //                         "queryValues": [
    //                             "patient"
    //                         ],
    //                         "type": "concept"
    //                     },
    //                     {
    //                         "ocids": [
    //                             231200000445
    //                         ],
    //                         "domains": [
    //                             "clinicaltrials"
    //                         ],
    //                         "prefnames": [
    //                             "clinical trial"
    //                         ],
    //                         "term": "clinical trial",
    //                         "label": "clinical trial",
    //                         "queryValues": [
    //                             "clinical trial"
    //                         ],
    //                         "type": "concept"
    //                     },
    //                     {
    //                         "ocids": [
    //                             231200003124
    //                         ],
    //                         "domains": [
    //                             "clinicaltrials"
    //                         ],
    //                         "prefnames": [
    //                             "clinical trial by design"
    //                         ],
    //                         "term": "clinical trial by design",
    //                         "label": "clinical trial by design",
    //                         "queryValues": [
    //                             "clinical trial by design"
    //                         ],
    //                         "type": "concept"
    //                     },
    //                     {
    //                         "ocids": [
    //                             231200000502
    //                         ],
    //                         "domains": [
    //                             "clinicaltrials"
    //                         ],
    //                         "prefnames": [
    //                             "cohort study"
    //                         ],
    //                         "term": "cohort study",
    //                         "label": "cohort study",
    //                         "queryValues": [
    //                             "cohort study"
    //                         ],
    //                         "type": "concept"
    //                     }
    //                 ],
    //                 "searchCriteria": {
    //                     "termloc": {
    //                         "filterValue": "ta",
    //                         "filterValueLabel": "Title and abstract"
    //                     }
    //                 },
    //                 "operator": "or"
    //             },
    //             {
    //                 "id": "id_1701943094748",
    //                 "type": SEARCH_GROUP_TYPE_LOGIC,
    //                 "creationTime": "2023-12-07 10:12",
    //                 "searchGroupIDRefs": [
    //                     "id_1701941407684",
    //                     "id_1701943026238"
    //                 ],
    //                 "operator": "and",
    //                 "searchCriteria": {}
    //             },
    //             {
    //                 "id": "id_1701943518939",
    //                 "type": SEARCH_GROUP_TYPE_TERM,
    //                 "creationTime": "2023-12-07 11:12",
    //                 "queryTerms": [
    //                     {
    //                         "ocids": [
    //                             108020004457
    //                         ],
    //                         "domains": [
    //                             "proteins"
    //                         ],
    //                         "prefnames": [
    //                             "RNA"
    //                         ],
    //                         "term": "RNA",
    //                         "label": "RNA",
    //                         "queryValues": [
    //                             "RNA"
    //                         ],
    //                         "type": "concept"
    //                     },
    //                     {
    //                         "ocids": [
    //                             238000002405
    //                         ],
    //                         "domains": [
    //                             "methods"
    //                         ],
    //                         "prefnames": [
    //                             "diagnostic procedure"
    //                         ],
    //                         "term": "diagnostic procedure",
    //                         "label": "diagnostic procedure",
    //                         "queryValues": [
    //                             "diagnostic procedure"
    //                         ],
    //                         "type": "concept"
    //                     },
    //                     {
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "term": "spectrometr",
    //                         "label": "spectrometr",
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "spectrometr"
    //                         ]
    //                     },
    //                     {
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "term": "chromatograph",
    //                         "label": "chromatograph",
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "chromatograph"
    //                         ]
    //                     },
    //                     {
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "term": "herbal",
    //                         "label": "herbal",
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "herbal"
    //                         ]
    //                     },
    //                     {
    //                         "term": "plant",
    //                         "label": "plant",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "Manufacturing Company",
    //                             "Embryophyta",
    //                             "Plant Proteins"
    //                         ],
    //                         "otherTerms": [
    //                             "higher plants"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "plant"
    //                         ]
    //                     },
    //                     {
    //                         "term": "fruit",
    //                         "label": "fruit",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "edible fruits",
    //                             "fruit"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "fruit"
    //                         ]
    //                     },
    //                     {
    //                         "term": "soil",
    //                         "label": "soil",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "soil"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "soil"
    //                         ]
    //                     },
    //                     {
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "term": "racial discrimination",
    //                         "label": "racial discrimination",
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "racial discrimination"
    //                         ]
    //                     },
    //                     {
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "term": "racism",
    //                         "label": "racism",
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "racism"
    //                         ]
    //                     },
    //                     {
    //                         "term": "diagnostic methods",
    //                         "label": "diagnostic methods",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "diagnostic procedure"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "diagnostic methods"
    //                         ]
    //                     },
    //                     {
    //                         "term": "micro-RNA",
    //                         "label": "micro-RNA",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "microRNA",
    //                             "miRNA"
    //                         ],
    //                         "otherTerms": [
    //                             "micro rna",
    //                             "primary micro rna"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "micro-RNA"
    //                         ]
    //                     },
    //                     {
    //                         "term": "RNAi",
    //                         "label": "RNAi",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "gene silencing by RNA",
    //                             "RNAI"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "RNAi"
    //                         ]
    //                     },
    //                     {
    //                         "term": "LncRNA",
    //                         "label": "LncRNA",
    //                         "domains": [
    //                             "t"
    //                         ],
    //                         "ocids": null,
    //                         "prefnames": [
    //                             "Telomeric non-coding RNA",
    //                             "Long Noncoding RNA"
    //                         ],
    //                         "type": "concept",
    //                         "queryValues": [
    //                             "LncRNA"
    //                         ]
    //                     }
    //                 ],
    //                 "searchCriteria": {},
    //                 "operator": "or"
    //             },
    //             {
    //                 "id": "id_1701943902059",
    //                 "type": SEARCH_GROUP_TYPE_LOGIC,
    //                 "creationTime": "2023-12-07 11:12",
    //                 "searchGroupIDRefs": [
    //                     "id_1701943094748",
    //                     "id_1701943518939"
    //                 ],
    //                 "operator": "not"
    //             },
    //             {
    //                 "id": "id_1701943936361",
    //                 "type": SEARCH_GROUP_TYPE_FILTER,
    //                 "creationTime": "2023-12-07 11:12",
    //                 "queryTerms": [
    //                     {
    //                         "filterID": "pubDate",
    //                         "filter": {
    //                             "id": "pubDate",
    //                             "type": "dateRange",
    //                             "label": "Publication date",
    //                             "openOnDefault": false,
    //                             "queryPrefix": "pdate",
    //                             "maxNumOfOcc": "1"
    //                         },
    //                         "val": {
    //                             "startDate": "2022-06-01",
    //                             "endDate": "2022-08-01",
    //                             "date": "",
    //                             "term": ""
    //                         },
    //                         "label": "2022-06-01 - 2022-08-01"
    //                     }
    //                 ],
    //                 "operator": "and"
    //             },
    //             // {
    //             //     "id": "id_1701945243473",
    //             //     "type": SEARCH_GROUP_TYPE_SAVED_SEARCH,
    //             //     "savedSearchID": "2183"
    //             // },
    //             // {
    //             //     "id": "id_1701945845742",
    //             //     "type": SEARCH_GROUP_TYPE_SAVED_SEARCH,
    //             //     "savedSearchID": "2199"
    //             // },
    //             // {
    //             //     "id": "id_92454205629",
    //             //     "type": SEARCH_GROUP_TYPE_SAVED_SEARCH,
    //             //     // "savedSearchID": "1152"
    //             //     "savedSearchID": "9876543210"
    //             // },
    //             // {
    //             //     "id": "id_923671926721",
    //             //     "type": SEARCH_GROUP_TYPE_API_SYNTAX,
    //             //     // "savedSearchID": "1152"
    //             //     "queryString": '(termloc:"ta" (ocid:"208000024891" (ocid:"206010037660" ocid:"236000000862") (ocid:"229930089153" ocid:"101100001138" ocid:"101100173972" ocid:"206010022000") (ocid:"208000000059" ocid:"206010013975")))'
    //             // },
    //             {
    //                 "id": "id_1701945237387",
    //                 "type": SEARCH_GROUP_TYPE_LOGIC,
    //                 "creationTime": "2023-12-07 11:12",
    //                 "searchGroupIDRefs": [
    //                     "id_1701943902059",
    //                     "id_1701943936361",
    //                     // "id_1701945243473",
    //                     // "id_1701945845742",
    //                     // "id_92454205629"
    //                 ],
    //                 "operator": "or"
    //             }
    //         ],
    //     }]
    // },

    {
        id: "insulin",
        examples: [{
            label: 'Insulin Delivery Systems',
            description: 'Find all kinds of documents related to insulin delivery systems.',
            searchGroups: [
                {
                    "id": "id_1708938020645",
                    "type": "term-group",
                    "creationTime": "2024-02-26 09:02",
                    "queryTerms": [
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "drug delivery system",
                            "type": "concept",
                            "queryValues": [
                                "drug delivery system"
                            ],
                            "textDistance": 3,
                            "id": "qt_149654"
                        },
                        {
                            "term": "MiniMed",
                            "label": "MiniMed",
                            "domains": [
                                "tr"
                            ],
                            "ocids": null,
                            "prefnames": [
                                "MiniMed"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "MiniMed"
                            ],
                            "id": "qt_362638"
                        },
                        {
                            "term": "MiniMedTM",
                            "label": "MiniMedTM",
                            "domains": [
                                "tr"
                            ],
                            "ocids": null,
                            "prefnames": [
                                "MiniMedTM"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "MiniMedTM"
                            ],
                            "id": "qt_555692"
                        }
                    ],
                    "searchCriteria": {
                        "termloc": {
                            "filterValue": "ta",
                            "filterValueLabel": "Title and abstract",
                            "categories": [
                                "general"
                            ]
                        }
                    },
                    "operator": "or"
                },
                {
                    "id": "id_1708938212244",
                    "type": "term-group",
                    "creationTime": "2024-02-26 09:02",
                    "queryTerms": [
                        {
                            "ocids": [
                                190000020213
                            ],
                            "domains": [
                                "chem"
                            ],
                            "prefnames": [
                                "insulin"
                            ],
                            "term": "insulin",
                            "label": "insulin",
                            "queryValues": [
                                "insulin"
                            ],
                            "type": "concept",
                            "id": "qt_444508"
                        },
                        {
                            "ocids": [
                                229940069975
                            ],
                            "domains": [
                                "drugs"
                            ],
                            "prefnames": [
                                "insulins and analogues"
                            ],
                            "term": "insulins and analogues",
                            "label": "insulins and analogues",
                            "queryValues": [
                                "insulins and analogues"
                            ],
                            "type": "concept",
                            "id": "qt_137027"
                        },
                        {
                            "ocids": [
                                229940123452
                            ],
                            "domains": [
                                "drugs"
                            ],
                            "prefnames": [
                                "Rapid-acting insulin"
                            ],
                            "term": "Rapid-acting insulin",
                            "label": "Rapid-acting insulin",
                            "queryValues": [
                                "Rapid-acting insulin"
                            ],
                            "type": "concept",
                            "id": "qt_306787"
                        },
                        {
                            "ocids": [
                                239000012582
                            ],
                            "domains": [
                                "substances"
                            ],
                            "prefnames": [
                                "insulin"
                            ],
                            "term": "insulin",
                            "label": "insulin",
                            "queryValues": [
                                "insulin"
                            ],
                            "type": "concept",
                            "id": "qt_549527"
                        },
                        {
                            "term": "regular insulin",
                            "label": "regular insulin",
                            "domains": [
                                "t"
                            ],
                            "ocids": null,
                            "prefnames": [
                                "Insulin",
                                "Insulin"
                            ],
                            "otherTerms": [
                                "insulin, regular"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "regular insulin"
                            ],
                            "id": "qt_974967"
                        }
                    ],
                    "searchCriteria": {
                        "termloc": {
                            "filterValue": "ta",
                            "filterValueLabel": "Title and abstract",
                            "categories": [
                                "general"
                            ]
                        }
                    },
                    "operator": "or"
                },
                {
                    "id": "id_1708938426311",
                    "type": "logic-group",
                    "creationTime": "2024-02-26 09:02",
                    "searchGroupIDRefs": [
                        "id_1708938020645",
                        "id_1708938212244"
                    ],
                    "operator": "and"
                },
                {
                    "id": "id_1708938533675",
                    "type": "term-group",
                    "creationTime": "2024-02-26 09:02",
                    "queryTerms": [
                        {
                            "ocids": [
                                190000020213
                            ],
                            "domains": [
                                "chem"
                            ],
                            "prefnames": [
                                "insulin"
                            ],
                            "term": "insulin",
                            "label": "insulin",
                            "queryValues": [
                                "insulin"
                            ],
                            "type": "concept",
                            "id": "qt_133651"
                        },
                        {
                            "ocids": [
                                229940069975
                            ],
                            "domains": [
                                "drugs"
                            ],
                            "prefnames": [
                                "insulins and analogues"
                            ],
                            "term": "insulins and analogues",
                            "label": "insulins and analogues",
                            "queryValues": [
                                "insulins and analogues"
                            ],
                            "type": "concept",
                            "id": "qt_887553"
                        },
                        {
                            "ocids": [
                                229940123452
                            ],
                            "domains": [
                                "drugs"
                            ],
                            "prefnames": [
                                "Rapid-acting insulin"
                            ],
                            "term": "Rapid-acting insulin",
                            "label": "Rapid-acting insulin",
                            "queryValues": [
                                "Rapid-acting insulin"
                            ],
                            "type": "concept",
                            "id": "qt_451528"
                        },
                        {
                            "ocids": [
                                239000012582
                            ],
                            "domains": [
                                "substances"
                            ],
                            "prefnames": [
                                "insulin"
                            ],
                            "term": "insulin",
                            "label": "insulin",
                            "queryValues": [
                                "insulin"
                            ],
                            "type": "concept",
                            "id": "qt_213269"
                        },
                        {
                            "term": "regular insulin",
                            "domains": [
                                "t"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "regular insulin"
                            ],
                            "id": "qt_49569"
                        }
                    ],
                    "searchCriteria": {
                        "termloc": {
                            "filterValue": "title",
                            "filterValueLabel": "Title",
                            "categories": [
                                "general"
                            ]
                        }
                    },
                    "operator": "or"
                },
                {
                    "id": "id_1708938629736",
                    "type": "term-group",
                    "creationTime": "2024-02-26 09:02",
                    "queryTerms": [
                        {
                            "ocids": [
                                237300009121
                            ],
                            "domains": [
                                "medicaldevices"
                            ],
                            "prefnames": [
                                "drug delivery device"
                            ],
                            "term": "drug delivery device",
                            "label": "drug delivery device",
                            "queryValues": [
                                "drug delivery device"
                            ],
                            "type": "concept",
                            "id": "qt_772101"
                        },
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "delivery device*",
                            "label": "delivery device*",
                            "type": "concept",
                            "queryValues": [
                                "delivery device*"
                            ],
                            "ocids": null,
                            "textDistance": 1,
                            "id": "qt_86766"
                        },
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "delivery system*",
                            "label": "delivery system*",
                            "type": "concept",
                            "queryValues": [
                                "delivery system*"
                            ],
                            "ocids": null,
                            "textDistance": 1,
                            "id": "qt_367540"
                        },
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "delivery option*",
                            "label": "delivery option*",
                            "type": "concept",
                            "queryValues": [
                                "delivery option*"
                            ],
                            "ocids": null,
                            "textDistance": 1,
                            "id": "qt_844412"
                        },
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "delivery method*",
                            "label": "delivery method*",
                            "type": "concept",
                            "queryValues": [
                                "delivery method*"
                            ],
                            "ocids": null,
                            "textDistance": 1,
                            "id": "qt_838128"
                        },
                        {
                            "term": "pen",
                            "label": "pen",
                            "domains": [
                                "t"
                            ],
                            "ocids": null,
                            "prefnames": [
                                "POLYETHYLENE",
                                "polyethylene naphthalate",
                                "Drosophila melanogaster peng gene",
                                "Homo sapiens Pumilio homolog 3",
                                "PEN",
                                "Homo sapiens ProSAAS",
                                "Drosophila melanogaster Pen gene",
                                "Danio rerio llgl2 gene",
                                "Pumilio homolog 3",
                                "Tetraspanin-33",
                                "ProSAAS",
                                "poly(ethylene-2,6-naphthalate)"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "pen"
                            ],
                            "id": "qt_729294"
                        },
                        {
                            "ocids": [
                                237300006357
                            ],
                            "domains": [
                                "medicaldevices"
                            ],
                            "prefnames": [
                                "syringe"
                            ],
                            "term": "syringe",
                            "label": "syringe",
                            "queryValues": [
                                "syringe"
                            ],
                            "type": "concept",
                            "id": "qt_687208"
                        },
                        {
                            "term": "syringe",
                            "label": "syringe",
                            "domains": [
                                "t"
                            ],
                            "ocids": null,
                            "prefnames": [
                                "syringe"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "syringe"
                            ],
                            "id": "qt_693375"
                        },
                        {
                            "ocids": [
                                237300011244
                            ],
                            "domains": [
                                "medicaldevices"
                            ],
                            "prefnames": [
                                "medical pump"
                            ],
                            "term": "medical pump",
                            "label": "medical pump",
                            "queryValues": [
                                "medical pump"
                            ],
                            "type": "concept",
                            "id": "qt_246472"
                        },
                        {
                            "term": "pump",
                            "label": "pump",
                            "domains": [
                                "t"
                            ],
                            "ocids": null,
                            "prefnames": [
                                "cosmetic pump",
                                "pump"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "pump"
                            ],
                            "id": "qt_622609"
                        },
                        {
                            "ocids": [
                                237300007918
                            ],
                            "domains": [
                                "medicaldevices"
                            ],
                            "prefnames": [
                                "inhaler"
                            ],
                            "term": "inhaler",
                            "label": "inhaler",
                            "queryValues": [
                                "inhaler"
                            ],
                            "type": "concept",
                            "id": "qt_269403"
                        },
                        {
                            "ocids": [
                                231200001845
                            ],
                            "domains": [
                                "clinicaltrials"
                            ],
                            "prefnames": [
                                "injection"
                            ],
                            "term": "injection",
                            "label": "injection",
                            "queryValues": [
                                "injection"
                            ],
                            "type": "concept",
                            "id": "qt_637514"
                        },
                        {
                            "ocids": [
                                229940090044
                            ],
                            "domains": [
                                "drugs"
                            ],
                            "prefnames": [
                                "injection"
                            ],
                            "term": "injection",
                            "label": "injection",
                            "queryValues": [
                                "injection"
                            ],
                            "type": "concept",
                            "id": "qt_894191"
                        },
                        {
                            "ocids": [
                                238000002347
                            ],
                            "domains": [
                                "methods"
                            ],
                            "prefnames": [
                                "injection"
                            ],
                            "term": "injection",
                            "label": "injection",
                            "queryValues": [
                                "injection"
                            ],
                            "type": "concept",
                            "id": "qt_69592"
                        },
                        {
                            "ocids": [
                                238000010999
                            ],
                            "domains": [
                                "methods"
                            ],
                            "prefnames": [
                                "medical injection"
                            ],
                            "term": "medical injection",
                            "label": "medical injection",
                            "queryValues": [
                                "medical injection"
                            ],
                            "type": "concept",
                            "id": "qt_550366"
                        },
                        {
                            "ocids": [
                                239000007924
                            ],
                            "domains": [
                                "substances"
                            ],
                            "prefnames": [
                                "injection"
                            ],
                            "term": "injection",
                            "label": "injection",
                            "queryValues": [
                                "injection"
                            ],
                            "type": "concept",
                            "id": "qt_67423"
                        },
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "automated insulin delivery",
                            "label": "automated insulin delivery",
                            "type": "concept",
                            "queryValues": [
                                "automated insulin delivery"
                            ],
                            "ocids": null,
                            "textDistance": 1,
                            "id": "qt_179760"
                        },
                        {
                            "ocids": [
                                237300013006
                            ],
                            "domains": [
                                "medicaldevices"
                            ],
                            "prefnames": [
                                "infusion system"
                            ],
                            "term": "infusion system",
                            "label": "infusion system",
                            "queryValues": [
                                "infusion system"
                            ],
                            "type": "concept",
                            "id": "qt_697286"
                        }
                    ],
                    "searchCriteria": {
                        "termloc": {
                            "filterValue": "title",
                            "filterValueLabel": "Title",
                            "categories": [
                                "general"
                            ]
                        }
                    },
                    "operator": "or"
                },
                {
                    "id": "id_1708938927559",
                    "type": "logic-group",
                    "creationTime": "2024-02-26 09:02",
                    "searchGroupIDRefs": [
                        "id_1708938533675",
                        "id_1708938629736"
                    ],
                    "operator": "and"
                },
                {
                    "id": "id_1708938977122",
                    "type": "term-group",
                    "creationTime": "2024-02-26 09:02",
                    "queryTerms": [
                        {
                            "ocids": [
                                237300011634
                            ],
                            "domains": [
                                "medicaldevices"
                            ],
                            "prefnames": [
                                "Insulin pump"
                            ],
                            "term": "Insulin pump",
                            "label": "Insulin pump",
                            "queryValues": [
                                "Insulin pump"
                            ],
                            "type": "concept",
                            "id": "qt_124064"
                        },
                        {
                            "term": "MiniMed",
                            "label": "MiniMed",
                            "domains": [
                                "tr"
                            ],
                            "ocids": null,
                            "prefnames": [
                                "MiniMed"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "MiniMed"
                            ],
                            "id": "qt_659561"
                        },
                        {
                            "term": "MiniMedTM",
                            "label": "MiniMedTM",
                            "domains": [
                                "tr"
                            ],
                            "ocids": null,
                            "prefnames": [
                                "MiniMedTM"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "MiniMedTM"
                            ],
                            "id": "qt_117830"
                        },
                        {
                            "ocids": [
                                237300013098
                            ],
                            "domains": [
                                "medicaldevices"
                            ],
                            "prefnames": [
                                "insulin pen"
                            ],
                            "term": "insulin pen",
                            "label": "insulin pen",
                            "queryValues": [
                                "insulin pen"
                            ],
                            "type": "concept",
                            "id": "qt_969410"
                        }
                    ],
                    "searchCriteria": {
                        "termloc": {
                            "filterValue": "title",
                            "filterValueLabel": "Title",
                            "categories": [
                                "general"
                            ]
                        }
                    },
                    "operator": "or"
                },
                {
                    "id": "id_1708939066517",
                    "type": "term-group",
                    "creationTime": "2024-02-26 09:02",
                    "queryTerms": [
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "insulin infusion sets",
                            "label": "insulin infusion sets",
                            "type": "concept",
                            "queryValues": [
                                "insulin infusion sets"
                            ],
                            "ocids": null,
                            "textDistance": 1,
                            "id": "qt_360815"
                        },
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "insulin infusion device",
                            "label": "insulin infusion device",
                            "type": "concept",
                            "queryValues": [
                                "insulin infusion device"
                            ],
                            "ocids": null,
                            "textDistance": 1,
                            "id": "qt_976749"
                        },
                        {
                            "domains": [
                                "tr"
                            ],
                            "term": "insulin delivery system",
                            "label": "insulin delivery system",
                            "type": "concept",
                            "queryValues": [
                                "insulin delivery system"
                            ],
                            "ocids": null,
                            "textDistance": 1,
                            "id": "qt_9884"
                        }
                    ],
                    "searchCriteria": {
                        "termloc": {
                            "filterValue": "ta",
                            "filterValueLabel": "Title and abstract",
                            "categories": [
                                "general"
                            ]
                        }
                    },
                    "operator": "or"
                },
                {
                    "id": "id_1708939132391",
                    "type": "logic-group",
                    "creationTime": "2024-02-26 09:02",
                    "searchGroupIDRefs": [
                        "id_1708938426311",
                        "id_1708938927559",
                        "id_1708938977122",
                        "id_1708939066517"
                    ],
                    "operator": "or"
                }
            ],
        }]
    },

    {
        id: "covid19",
        examples: [{
            label: 'COVID-19',
            description: 'Find articles and preprints on COVID-19 symptoms.',
            searchGroups: [
                {
                    "id": "id_1704888641781",
                    "type": "term-group",
                    "isSelected": false,
                    "creationTime": "2024-01-10 12:01",
                    "queryTerms": [
                        {
                            "otherTerms": [
                                "covid-19 disease",
                                "covid-19 infection",
                                "covid-19 pandemic",
                                "covid-19 virus infection"
                            ],
                            "term": "covid-19",
                            "domains": [
                                "diseases"
                            ],
                            "ocids": [
                                208000025721
                            ],
                            "prefnames": [
                                "COVID-19"
                            ],
                            "label": "covid-19",
                            "type": "concept",
                            "queryValues": [
                                "covid-19"
                            ],
                            "id": "qt_59335"
                        }
                    ],
                    "searchCriteria": {
                        "termloc": {
                            "filterValue": "title",
                            "filterValueLabel": "Title"
                        }
                    },
                    "operator": "or"
                },
                {
                    "id": "id_1704888862965",
                    "type": "term-group",
                    "isSelected": false,
                    "creationTime": "2024-01-10 12:01",
                    "queryTerms": [
                        {
                            "term": "symptoms",
                            "label": "symptoms",
                            "domains": [
                                "diseases"
                            ],
                            "ocids": [
                                208000024891
                            ],
                            "prefnames": [
                                "symptom"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "symptoms"
                            ],
                            "id": "qt_703"
                        },
                        {
                            "term": "fever",
                            "label": "fever",
                            "domains": [
                                "diseases",
                                "magnitudes"
                            ],
                            "ocids": [
                                206010037660,
                                236000000862
                            ],
                            "prefnames": [
                                "Pyrexia",
                                "fever"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "fever"
                            ],
                            "id": "qt_889283"
                        },
                        {
                            "term": "flu",
                            "label": "flu",
                            "domains": [
                                "natprod",
                                "proteins",
                                "diseases"
                            ],
                            "ocids": [
                                229930089153,
                                101100001138,
                                101100173972,
                                206010022000
                            ],
                            "prefnames": [
                                "o-biphenylenemethane",
                                "Escherichia coli (strain K12) flu gene",
                                "Arabidopsis thaliana FLU gene",
                                "influenza"
                            ],
                            "type": "concept",
                            "queryValues": [
                                "flu"
                            ],
                            "id": "qt_453054"
                        },
                        {
                            "otherTerms": [],
                            "term": "breathlessness",
                            "domains": [
                                "diseases"
                            ],
                            "ocids": [
                                208000000059,
                                206010013975
                            ],
                            "prefnames": [
                                "Dyspnea",
                                "Dyspnoeas"
                            ],
                            "label": "breathlessness",
                            "type": "concept",
                            "queryValues": [
                                "breathlessness"
                            ],
                            "id": "qt_123327"
                        }
                    ],
                    "searchCriteria": {
                        "termloc": {
                            "filterValue": "ta",
                            "filterValueLabel": "Title and abstract"
                        }
                    },
                    "operator": "or"
                },
                {
                    "id": "id_1704889043762",
                    "type": "logic-group",
                    "isSelected": false,
                    "creationTime": "2024-01-10 12:01",
                    "searchGroupIDRefs": [
                        "id_1704888641781",
                        "id_1704888862965"
                    ],
                    "operator": "and"
                },
                {
                    "id": "id_1704889326907",
                    "type": "filter-group",
                    "isSelected": false,
                    "creationTime": "2024-01-10 12:01",
                    "queryTerms": [
                        {
                            "filterID": "pubDate",
                            "filter": {
                                "id": "pubDate",
                                "type": "dateRange",
                                "label": "Publication date",
                                "openOnDefault": false,
                                "queryPrefix": "pdate",
                                "maxNumOfOcc": "1"
                            },
                            "val": {
                                "startDate": "2022-01-01",
                                "endDate": "2024-01-01",
                                "date": "",
                                "term": ""
                            },
                            "label": "2022-01-01 - 2024-01-01",
                            "id": "qt_206176"
                        }
                    ],
                    "operator": "and"
                },
                {
                    "id": "id_1708935694396",
                    "type": "filter-group",
                    "creationTime": "2024-02-26 08:02",
                    "queryTerms": [
                        {
                            "filterID": "publicationType",
                            "filter": {
                                "id": "publicationType",
                                "type": "facetsExamples",
                                "label": "Publication type",
                                "openOnDefault": false,
                                "queryPrefix": "pubtype",
                                "facetID": "PublicationType",
                                "extraFieldID": "pubtype"
                            },
                            "val": {
                                "term": "article"
                            },
                            "queryPart": "article",
                            "label": "article",
                            "id": "qt_213262"
                        },
                        {
                            "filterID": "publicationType",
                            "filter": {
                                "id": "publicationType",
                                "type": "facetsExamples",
                                "label": "Publication type",
                                "openOnDefault": false,
                                "queryPrefix": "pubtype",
                                "facetID": "PublicationType",
                                "extraFieldID": "pubtype"
                            },
                            "val": {
                                "term": "preprint"
                            },
                            "queryPart": "preprint",
                            "label": "preprint",
                            "id": "qt_530602"
                        }
                    ],
                    "operator": "or"
                },
                {
                    "id": "id_1707301354850",
                    "type": "logic-group",
                    "creationTime": "2024-02-07 10:02",
                    "searchGroupIDRefs": [
                        "id_1704889720146",
                        "id_1704889326907",
                        "id_1708935694396"
                    ],
                    "operator": "and"
                },
                {
                    "id": "id_1708935855586",
                    "type": "logic-group",
                    "creationTime": "2024-02-26 08:02",
                    "searchGroupIDRefs": [
                        "id_1704889043762",
                        "id_1707301354850"
                    ],
                    "operator": "or"
                }
            ],
        }]
    },
]
