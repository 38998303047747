import PropTypes from 'prop-types'
import {
    React, useState, useRef, useEffect
} from 'react';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Checkbox } from 'primereact/checkbox';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ClearIcon from '@mui/icons-material/Clear';
import './style/main.css'
import _, { isUndefined } from 'lodash';
import AutocompleteSuggestionListItem from '../../general/autocomplete/AutocompleteSuggestionListItem';
import { fetchMergedTermSuggestions } from '../../../../api/content/ConceptApi';
import { checkResultAndGetPayload } from '../../../../api';
import { QUERY_TERM_TYPE_CONCEPT } from '../../general/docsearch/searchConstants';
import { DOMAIN_ALIASES, FREE_TEXT_DOMAINS, FREE_TEXT_DOMAIN_ALIASES } from './constants/general';
import { ColorPicker } from './components/ColorPicker';
import { MatchNavigator } from './components/MatchNavigator';
import { totalMatches } from '../util/general';
import { addThousandsSeparatorToNumber, isArrayEmpty } from '../../util';
import InputFieldWithClear from '../../../webapi/general/inputfields/InputFieldWithClear';
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

let latestTermFragment;

export const SemanticAutoCompleteMultipleChoices = ({
    annotations,
    activateOcidHighlighting,
    activeSearchTerms,
    changeOcidAnnotationColor,
    domainLabelsMap = {},
    domainColors = {},
    jumpToMatchGenrally,
    clearSemanticSearch,
    domains,
    activeSearchTermsCount = {},
    onSpecifyQueryTerm,
    abstractOnly = false,
    isSemanticOn,
    showFooter,
    noCounting,
    addTerm,
    editTermTerm,
    editTermImportant,
    removeTerm,
    placeholder,
    openDomainExplorer
}) => {

    const autocompleteRef = useRef(null);
    const [autoCompleteText, setAutoCompleteText] = useState('');
    const [selectedTermsIndex, setSelectedTermsIndex] = useState([]);
    const [filteredTerms, setFilteredTerms] = useState([]);
    const [index, setIndex] = useState(-1);
    const [clickedItem, setClickedItem] = useState('');
    const [hoveredOption, setHoveredOption] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const [regexDialogIsVisible, setRegexDialogIsVisible] = useState(false);
    const [regexInputValue, setRegexInputValue] = useState('');
    const [editingRegex, setEditingRegex] = useState(null);
    const [regexErrorText, setRegexErrorText] = useState('');

    const handleRegexOverlayInputChange = (e) => {
        setRegexInputValue(e.target.value);
        setRegexErrorText('');
    }

    const addRegexToInput = (event) => {
        if (/^\*/.test(regexInputValue) || /^\?/.test(regexInputValue)) {
            setRegexErrorText("Your input can't start with a wildcard.");
        } else if (regexInputValue.trim() === "") {
            setRegexErrorText("Your input can't be empty.");
            setRegexInputValue('');
        } else if (/\s/.test(regexInputValue)) {
            setRegexErrorText("Your input can't contain spaces.");
        } else if (regexInputValue.length < 3) {
            setRegexErrorText("Your input has to contain at least three characters (incl. wildcard).");
        } else {
            setRegexErrorText('');
            if (!editingRegex) {
                onSelectTerm({ term: regexInputValue, regex: regexInputValue, important: false, domains: [FREE_TEXT_DOMAINS[0]] });
            } else {
                onEditTermTerm({ term: regexInputValue, regex: regexInputValue, important: false, domains: [FREE_TEXT_DOMAINS[0]] }, editingRegex.id)
                setEditingRegex(null);
            }
            setRegexDialogIsVisible(false);
            setRegexInputValue('');
        }
    }



    const handleAutoCompleteTextChange = (value) => {
        setAutoCompleteText(value);
        if (value === '') {
            setFilteredTerms([]);
            setOpenDropdown(false);
        }
    }

    const handleCheckboxChange = (option) => {
        const updatedFilteredTerms = filteredTerms.map((o) =>
            o.term === option.term ? { ...o, checked: !o.checked } : o
        );
        setFilteredTerms(updatedFilteredTerms);
    }

    const handleAddSelectedToInput = (option) => {
        handleAddCheckedToInput(option);
        handleAutoCompleteTextChange("");
        setOpenDropdown(false);
    }

    const handleAddCheckedToInput = (option) => {
        onSelectTerm(option);
        setAutoCompleteText("");
    }

    const handleDeleteItemFromInput = (itemToDelete) => {
        onUnselectTerm(itemToDelete);
    }

    const selectedItemTemplate = (item) => {
        const uniqeId = generateUID(item);
        const bgColor = getExactColor(uniqeId, item?.domains, item.ocids)
        const selectedTermIndex = getSelectedTermIndex(uniqeId)
        const matches = addThousandsSeparatorToNumber(abstractOnly ? filterAnnotations(uniqeId)?.length : activeSearchTermsCount[uniqeId])
        return (
            <div key={uniqeId} className={`selected-item-semantic-container`} style={{ backgroundColor: `#${bgColor}` }}>
                <div className={`selected-item-semantic`} style={{ border: `3px solid #${bgColor}` }} >
                    {!noCounting ?
                        <div className={`item-icons ${!activeSearchTermsCount[uniqeId] ? 'disable-button' : ''}`} style={{ backgroundColor: `#${bgColor}` }} >
                            <MatchNavigator findAnnId={findAnnId} uniqueId={uniqeId} />
                        </div>
                        : null
                    }
                    <button className="important-btn" style={{ backgroundColor: item.important ? 'black' : 'transparent', color: item.important ? 'white' : 'black', opacity: item.important ? '1' : '0.3' }}
                        onClick={() => handleImportantClick(item)}>
                        <div className="important-btn-circle">
                            <span>&#33;</span>
                        </div>
                    </button>
                    <div className='item-text-multiple'>
                        <div className='item-title-multiple'>
                            <span title={item.term}>
                                {item.term}
                            </span>
                            {!noCounting ?
                                <span className={`${(clickedItem !== uniqeId && clickedItem !== '') ? 'graycolor' : ''}`}>
                                    {`${matches ?
                                        ` ( ${selectedTermIndex > -1 ? (selectedTermIndex + 1) + ' / ' : ''}
                                  ${matches || ''} )` : ' (0) '}`}
                                </span>
                                : null
                            }
                        </div>
                        <div>
                            <div className='item-sub-title domain-grid' >
                                <div className='tooltip-description domain-col' title={`${concatDomais(item)}`} >
                                    {
                                        getHighPiriortyDomain(item.domains, item.ocids)?.map((domain, index) => {
                                            return <span className='capitalize mt-2'>
                                                {
                                                    formatDomainNames(domain.name, item.ocids) + ((item.domains.length !== index + 1) ? ',' : '')
                                                }
                                            </span>
                                        })
                                    }
                                </div>
                                <div className='edit-col' >
                                    <ColorPicker
                                        changeColor={changeColor}
                                        bgColor={bgColor}
                                        item={item}
                                        onSpecifyQueryTerm={onSpecifyQueryTerm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clear-icon-container">
                        <ClearIcon
                            onClick={() => {
                                handleCheckboxChange(item);
                                handleDeleteItemFromInput(item);
                            }}
                            className={'p-autocomplete-token'}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const handleImportantClick = (itemImportant) => {
        const uniqeId = generateUID(itemImportant);
        if (activeSearchTerms.find((obj) => obj.id === uniqeId)) {
            setSelectedTermsIndex((selectedTermsIndex) => [...selectedTermsIndex,
            {
                id: uniqeId,
                lastIndex: -1,
                important: !itemImportant.important
            }])
            if (!noCounting) {
                const termObject = { ...itemImportant, id: uniqeId, color: getExactColor(uniqeId, itemImportant.domains, itemImportant.ocids) };
                activateOcidHighlighting(termObject, false)
            }
            if (noCounting) {
                editTermImportant(itemImportant);
            }
        }
    }

    const onSpecifyQueryRegex = (regex, event) => {
        setRegexInputValue(regex.regex);
        setEditingRegex(regex);
        setRegexDialogIsVisible(true);
    }

    const selectedRegexTemplate = (regex) => {


        const uniqeId = generateUID(regex);
        const bgColor = getExactColor(uniqeId);
        const colorForUnderlining = getExactBackgroundColor(uniqeId);

        return (
            <div key={uniqeId} className={`selected-regex-semantic-container`} style={{ backgroundColor: `#${bgColor}` }}>
                <div className={`selected-item-semantic`} style={{ border: `3px solid #${bgColor}` }} >
                    <button className="important-btn" style={{ backgroundColor: regex.important ? 'black' : 'transparent', color: regex.important ? 'white' : 'black', opacity: regex.important ? '1' : '0.3' }}
                        onClick={() => handleImportantClick(regex)}>
                        <div className="important-btn-circle">
                            <span>&#33;</span>
                        </div>
                    </button>
                    <div className='item-text-multiple'>
                        <div className='item-title-multiple'>
                            <span title={regex.regex}>
                                {regex.regex}
                            </span>
                        </div>
                        <div>
                            <div className='item-sub-title domain-grid' >
                                <div className='tooltip-description domain-col' title="Wildcard term">
                                    <span className='capitalize mt-2'>
                                        Wildcard
                                    </span>
                                </div>
                                <div className='edit-col' >
                                    <ColorPicker
                                        changeColor={changeColor}
                                        bgColor={bgColor}
                                        item={regex}
                                        onSpecifyQueryRegex={onSpecifyQueryRegex}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clear-icon-container">
                        <ClearIcon
                            onClick={() => {
                                handleDeleteItemFromInput(regex);
                            }}
                            className={'p-autocomplete-token'}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                    <div className="regex-underlining" style={{ background: `repeating-linear-gradient(90deg, #${colorForUnderlining}, #${colorForUnderlining} 8px, #${bgColor} 8px, #${bgColor} 16px)` }}></div>
                </div>
            </div>
        );
    }

    const getExactColor = (id, domain, ocids) => {
        let temp = activeSearchTerms?.find((term) => term?.id === id)
        if (temp) {
            return temp?.color
        } else {
            if (ocids) {
                const tempDomain = getHighPiriortyDomain(domain, ocids)
                return tempDomain.length > 0 ? tempDomain[0]?.color : 'dee2e6'
            } else {
                return 'dee2e6'
            }
        }
    }

    const getExactBackgroundColor = (id) => {
        let temp = activeSearchTerms?.find((term) => term?.id === id)
        return temp?.colorForUnderlining;
    }

    const getHighPiriortyDomain = (domain, ocids = null) => {
        if (!ocids && FREE_TEXT_DOMAINS.includes(domain[0])) {
            return domain.map((item) => {
                return {
                    name: DOMAIN_ALIASES[item]
                }
            })
        }
        return domains.filter((dom) => domain.some((item) => item === dom.name));
    }


    const concatDomais = (item) => {
        let domainsText = ''
        item.domains?.forEach((domain, index) => {
            domainsText += formatDomainNames(domain, item.ocids) + ((item.domains.length !== index + 1) ? ',' : '')
        })
        return domainsText
    }


    const formatDomainNames = (domain, ocids) => {
        if (!ocids && FREE_TEXT_DOMAIN_ALIASES.includes(domain)) {
            return domain;
        } else {
            return domainLabelsMap[domain]
        }
    }


    const filterAnnotations = (querId = null) => {
        return _.orderBy(annotations?.filter((item) => item?.annotations?.find((obj) => querId ? obj?.queryId === querId : obj?.queryId)), [function (o) { return parseInt(o?.annId?.split('d')[1]) }])
    }

    const onSelectTerm = (itemToAdd) => {
        const uniqeId = generateUID(itemToAdd);
        if (!activeSearchTerms.find((obj) => obj.id === uniqeId)) {
            setSelectedTermsIndex((selectedTermsIndex) => [...selectedTermsIndex,
            {
                id: uniqeId,
                lastIndex: -1
            }])
            if (!noCounting) {
                const termObject = { ...itemToAdd, id: uniqeId, color: getExactColor(uniqeId, itemToAdd.domains, itemToAdd.ocids) };
                activateOcidHighlighting(termObject, false)
            }
            if (noCounting) {
                addTerm(itemToAdd, uniqeId);
            }
        }
    }

    const onEditTermTerm = (newItem, previousId) => {
        const uniqeId = generateUID(newItem);
        if (activeSearchTerms.find((obj) => obj.id === previousId)) {
            console.log(selectedTermsIndex)
            let newSelectedTermsIndex = selectedTermsIndex.map((term) => {
                if (term.id === previousId) {
                    return { id: uniqeId, lastIndex: -1 };
                }
                return term;
            })
            setSelectedTermsIndex(newSelectedTermsIndex);
            editTermTerm(newItem, previousId);
        }
    }

    const generateUID = (term) => {
        return `q${term.term.trim().split(' ').join('') + (term.ocids ? term.ocids?.length : term.domains[0]) + term.domains?.length}`
    }

    const onUnselectTerm = (itemToDelete) => {
        const uniqeId = generateUID(itemToDelete)
        setSelectedTermsIndex(selectedTermsIndex.filter((item) => (item.id !== uniqeId)))
        setIndex(-1)
        if (!noCounting) {
            activateOcidHighlighting({ ...itemToDelete, id: uniqeId }, true)
        } else {
            removeTerm(itemToDelete)
        }
    }

    const changeColor = (value, selectedConcept, darkenedColor) => {
        if (noCounting) {
            changeOcidAnnotationColor(generateUID(selectedConcept), value, selectedConcept)
        } else {
            changeOcidAnnotationColor(generateUID(selectedConcept), value)
        }

    }

    /**
   * Fetches suggestions for term fragment. Suggestions will be merged if they have
   * the same OCID. Free text option will be added if there are no direct matches.
   * @param {*} event 
   */

    useEffect(() => {
        if (timeoutId) clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(async () => {
            if (autoCompleteText !== undefined && autoCompleteText !== '') {
                let latestTermAutoCompleteText = autoCompleteText;
                // --- fetch term suggestions --- //
                let terms = [];
                const response = await fetchMergedTermSuggestions(autoCompleteText, null, null, null, true, false, true); // this.state.domains
                terms = checkResultAndGetPayload(response, null);
                if (autoCompleteText === latestTermAutoCompleteText && terms) {
                    terms = terms ? terms : [];

                    terms.forEach(term => {
                        term.type = QUERY_TERM_TYPE_CONCEPT;
                        term.queryValues = [term.term];
                        term.checked = false;
                        term.important = false;
                        selectedTermsIndex.map((selectedTerm) => {
                            if (generateUID(term) === selectedTerm.id) {
                                term.checked = true
                            }
                        });
                    });
                }
                setFilteredTerms(terms);
            }
        }, 300);

        setTimeoutId(newTimeoutId);
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [autoCompleteText]);


    const findAnnId = (isNext, querId = null) => {

        if (!isSemanticOn)
            return;


        setClickedItem(querId);
        const ann = filterAnnotations(querId)
        const lastIndex = querId ? getSelectedTermIndex(querId) : index
        const annLength = ann?.length || 0
        if (annLength > 0) {
            let index = 0;
            if (isNext) {
                index = lastIndex + 1 === annLength ? 0 : lastIndex + 1;
            } else {
                index = lastIndex === -1 || lastIndex === 0 ? 0 : lastIndex - 1;
            }
            if (!querId) {
                setIndex(index)
            } else {
                setSelectedTermsIndex(selectedTermsIndex.map((item) => (item.id === querId ? { ...item, lastIndex: index } : item)))
            }
            jumpToMatchGenrally(ann[index]?.annId)

        }
    }

    const getSelectedTermIndex = (querId) => {
        const temp = selectedTermsIndex.find((item) => item.id === querId)
        if (!temp) {
            setSelectedTermsIndex((selectedTermsIndex) => [...selectedTermsIndex,
            {
                id: querId,
                lastIndex: -1
            }])
            return -1;
        } else {
            return temp.lastIndex
        }
    }


    const clearAll = () => {
        if (activeSearchTerms.length > 0) {
            setSelectedTermsIndex([]);
            setAutoCompleteText('');
            clearSemanticSearch();
        }
    }


    const handleMouseEnter = (option) => {
        setHoveredOption(option);
    };

    const handleMouseLeave = () => {
        setHoveredOption(null);
    };

    return (
        <div className='semantic-container-multiple'>
            <div className='search-header'>
                <Button
                    className="p-button-text primaryButtonAsLink"
                    style={{ marginRight: 30 }}
                    title="Browse domains and add concepts to your search"
                    onClick={() => openDomainExplorer()}>
                    Domain explorer
                </Button>
                <a onClick={() => setRegexDialogIsVisible(true)}>
                    Add wildcard term
                </a>
                <span style={{ opacity: 0.7, paddingLeft: 10, paddingRight: 10 }}>&nbsp;|&nbsp;</span>
                <a onClick={clearAll}>
                    Clear all
                </a>
            </div>
            <InputFieldWithClear
                onClear={clearAll}
                showIcon={!isArrayEmpty(activeSearchTerms)}>
                <Autocomplete
                    multiple
                    options={filteredTerms}
                    value={activeSearchTerms}
                    inputValue={autoCompleteText}
                    onInputChange={(event, value) => handleAutoCompleteTextChange(value)}
                    ref={autocompleteRef}
                    freeSolo
                    className="multiple-autocomplete"
                    open={openDropdown}
                    onOpen={() => {
                        setOpenDropdown(true);
                    }}
                    onClose={() => {
                        setOpenDropdown(false);
                        setFilteredTerms([]);
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField {...params}
                            placeholder="Search term or keyword"
                        />
                    )}
                    renderOption={(props, option, state) => {
                        const oneIsChecked = filteredTerms.some((item) => item.checked === true);

                        return (
                            <ListItem
                                {...props}
                                component='li'
                                onClick={(event) => handleAddSelectedToInput(option)}
                                className={"autocomplete-multiple-option"}
                            >
                                <Grid container
                                    alignItems="center"
                                    onMouseEnter={() => handleMouseEnter(option)}
                                    onMouseLeave={handleMouseLeave}
                                    className={`MuiListItem-root MuiGridRoot ${state.focus ? 'MuiListItem-root' : ''}`}
                                >
                                    <Grid item xs={1} style={{ flex: '0 0 auto' }}>
                                        {((hoveredOption === option) ||
                                            (oneIsChecked)) &&
                                            (option.domains.length !== 0 && option.domains[0] !== 't') ? (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleCheckboxChange(option);
                                                if (!option.checked) {
                                                    handleAddCheckedToInput(option);
                                                }
                                                else if (option.checked) {
                                                    handleDeleteItemFromInput(option);
                                                }
                                            }
                                            }>
                                                <Checkbox
                                                    checked={option.checked}
                                                    className='checkbox-additional'
                                                />
                                            </div>
                                        ) : (
                                            <div className='checkbox-container' />
                                        )
                                        }
                                    </Grid>

                                    <Grid item xs style={{ flexGrow: 1 }}>
                                        <AutocompleteSuggestionListItem
                                            item={option}
                                            isHovered={hoveredOption === option}
                                            queryPart={autoCompleteText}
                                            domainLabelsMap={domainLabelsMap}
                                            acRef={autocompleteRef}
                                            domainColors={domainColors}
                                            onClick={(event, option) => handleAddSelectedToInput(option)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        );
                    }}

                    renderTags={(value) => value.map((item) => (item.regex === undefined) ? selectedItemTemplate(item) : selectedRegexTemplate(item))}
                    clearIcon={null}
                />
            </InputFieldWithClear>

            <Dialog focusOnShow={false}
                visible={regexDialogIsVisible}
                className="styledDialog"
                style={{ width: '30vw' }}
                showHeader={true}
                modal={true}
                header='Add wildcard term'
                onHide={() => {
                    setRegexErrorText('')
                    setRegexDialogIsVisible(false)
                }}
                //footer={footerRegexDialog}
                dismissableMask={true}
            >
                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0, marginBottom: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <TextField
                        multiline
                        rows={2}
                        value={regexInputValue}
                        style={{ width: '100%' }}
                        onChange={handleRegexOverlayInputChange}
                        error={!!regexErrorText}
                        helperText={regexErrorText}
                        placeholder='Add wildcard term, e.g. il-?'
                    />
                    <div className="restrictions-container">
                        <p>Click here for rules and examples.</p>
                        <span style={{ marginLeft: 10, marginTop: 7 }}>
                            <InfoOverlayWithIcon
                                infoID="compoundSearchInfo"
                                title="Click for information about wildcard search"
                                imageAltText="Wildcard search information"
                                overlayContent={
                                    <>
                                        <h3>Rules and examples for wildcard terms</h3>
                                        <p>
                                            A single text term (without whitespace) may be added each time with a minimum character limit being 3.
                                        </p>
                                        <p>
                                            The following standard operators for wildcards are supported: <br></br>
                                            1) '?' Represents a single variable character <br></br>
                                            E.g.  il-? which will find il-2, il-4, il-6, etc. <br></br>
                                            2) '*' Represents non or multiple variable characters <br></br>
                                            E.g. immun* which will find immune, immunity, immunology, etc.
                                            <p>
                                                You can use multiple standard operators in your term e.g. b*nd* which will find bind, binding, binders, bound, etc.
                                            </p>
                                            <p>
                                                Note: Wildcards cannot be added to the beginning of a term. Broad wildcard terms matching a very large number of terms will slow down search execution.
                                            </p>
                                        </p>
                                    </>
                                }
                            />
                        </span>
                    </div>
                </div>
                <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                    <Button className="p-button-sm primaryButton"
                        style={{ float: 'right' }}
                        label={editingRegex === null ? "Add" : "Edit"}
                        disabled={regexErrorText !== '' || regexInputValue === ''}
                        onClick={(regexErrorText === '') ? addRegexToInput : null}
                    />
                    <Button className="p-button-secondary p-button-sm"
                        style={{ float: 'right', marginRight: 5 }}
                        label="Cancel"
                        onClick={() => {
                            setRegexDialogIsVisible(false)
                            setRegexErrorText('')
                            setRegexInputValue('')
                        }
                        }
                    />
                </div>
            </Dialog>
            {showFooter ?
                <div className='search-footer'>
                    <div className={`footer-icons ${totalMatches(activeSearchTermsCount) ? '' : ' disable-button'}`} >
                        <label>Overall matches in document:  {`( ${index === -1 ? 0 : index + 1} / ${addThousandsSeparatorToNumber(abstractOnly ? filterAnnotations()?.length : totalMatches(activeSearchTermsCount))} )`}</label>
                        <MatchNavigator findAnnId={findAnnId} />
                    </div>

                </div>
                : null}
        </div>
    )
}
SemanticAutoCompleteMultipleChoices.propTypes = {
    annotations: PropTypes.array,
    activateDomainHighlighting: PropTypes.func,
    jumpToMatchGenrally: PropTypes.func,
    activeSearchTerms: PropTypes.array,
    changeOcidAnnotationColor: PropTypes.func,
    clearSemanticSearch: PropTypes.func,
    domainLabelsMap: PropTypes.object,
    domainColors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    activeSearchTermsCount: PropTypes.object,
    domains: PropTypes.array,
    onSpecifyQueryTerm: PropTypes.func,
    abstractOnly: PropTypes.bool,
    isSemanticOn: PropTypes.bool,
    showFooter: PropTypes.bool,
    noCounting: PropTypes.bool,
    editTermTerm: PropTypes.func,
    addTerm: PropTypes.func,
    editTermImportant: PropTypes.func,
    removeTerm: PropTypes.func,
    placeholder: PropTypes.string
}
