import { useRef } from 'react';
import ListHeader from "../../../../common/ListHeader/ListHeader";
import { useDispatch, useSelector } from "react-redux";
import { BioactivitiesResultTable } from "./BioactivitiesResultTable";
import {
    setBioactivitiesCheckedResults,
    setBioactivitiesFirstResultIndex,
    setBioactivitiesResultCount,
    setBioactivitiesSortDirection,
    setBioactivitiesSortField
} from "../../../../../redux/actions/BioactivitiesActions";
import {
    bioactivitiesCheckedResults, bioactivitiesFirstResultIndex, bioactivitiesIsFetching,
    bioactivitiesNodes,
    bioactivitiesSearchResults, bioactivitiesSortDirection,
    bioactivitiesSortField
} from "../../../../../redux/selectors/selectors";

const BioactivitiesList = ({ value, hitCount, exportTopResults, exportSelectedResults }) => {
    const searchResults = useSelector(bioactivitiesSearchResults);
    const checkedResults = useSelector(bioactivitiesCheckedResults);
    const nodes = useSelector(bioactivitiesNodes);
    const sortField = useSelector(bioactivitiesSortField);
    const sortDirection = useSelector(bioactivitiesSortDirection);
    const isFetching = useSelector(bioactivitiesIsFetching);
    const allRelationIds = useSelector(({ bioactivities: { searchResults } }) => searchResults?.relationResults
        ?.map(({ relationId }) => relationId));
    const firstResultIndex = useSelector(bioactivitiesFirstResultIndex);
    const ref = useRef();

    const dispatch = useDispatch();

    const results = searchResults?.relationResults;
    const isSelectedAll = results?.length && results.every(({ relationId }) => checkedResults.includes(relationId));

    const handleSortFieldChange = (event) => {
        dispatch(setBioactivitiesFirstResultIndex(0));
        dispatch(setBioactivitiesSortField(event.value));
    };

    const handleSortDirection = (event) => {
        dispatch(setBioactivitiesFirstResultIndex(0));
        dispatch(setBioactivitiesSortDirection(event.value));
    }

    const onPage = (event, fromPaginator) => {
        // prevent sending http request when app is fetching data
        if (!isFetching) {
            dispatch(setBioactivitiesCheckedResults([]));
            dispatch(setBioactivitiesResultCount(fromPaginator ? event.rows : event.value));
            dispatch(setBioactivitiesFirstResultIndex(fromPaginator ? event.first : firstResultIndex));
        }

        //scrollToComponent(ref.current, {duration: 500});
        window.scrollTo(0, ref.current.offsetTop)
    };

    return (
        <>
            <ListHeader hitCount={hitCount}
                isSelectedAll={isSelectedAll} value={value} sortField={sortField}
                handleSortFieldChange={handleSortFieldChange}
                sortDirection={sortDirection} handleSortDirectionChange={handleSortDirection}
                exportTopResults={exportTopResults}
                exportSelectedResults={exportSelectedResults}
                checkedResults={checkedResults}
                nodes={nodes}
                allRelationIds={allRelationIds}
                ref={ref}
            />

            {!!hitCount && <BioactivitiesResultTable hitCount={hitCount} onPage={onPage} />}
            {!hitCount && !isFetching && <p>No documents found in ChEMBL.</p>}
        </>
    );
};

export default BioactivitiesList;
