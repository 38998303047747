import "./ConceptDetails.css";
import { useEffect, useState } from "react";
import { Element } from 'react-scroll';
import { hasUserRole, isArrayEmpty, isObjectEmpty } from "../util";
import { isChemCompoundOcid } from "../chemistry/utils/compoundClassGroup";
import { APP_PROPERTIES, USER_ROLE_COMPOUND_SEARCH } from "../../../properties";
import { addMculeDataToConceptData, truncateList } from "./util/conceptDetails";
import { fetchConceptDataForOcid, fetchMculeUrlForSmiles } from "../docsearch/api/concept_data.service";
import {
  CONCEPT_DETAILS_NAVIGATION_CLASSIFICATIONS_ELEMENT, CONCEPT_DETAILS_NAVIGATION_COOCS_ELEMENT,
  CONCEPT_DETAILS_NAVIGATION_DEFINITION_ELEMENT, CONCEPT_DETAILS_NAVIGATION_LINKOUTS_ELEMENT,
  CONCEPT_DETAILS_NAVIGATION_PROPERTIES_ELEMENT, CONCEPT_DETAILS_NAVIGATION_STRUCTURE_ELEMENT,
  CONCEPT_DETAILS_NAVIGATION_SYNONYMS_ELEMENT, CONCEPT_DETAILS_NAVIGATION_TOP_ELEMENT, filterNavigationItems
} from "./components/navigation/navigationItems";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import Navigation from "./components/navigation/Navigation";
import TopInfo from "./components/conceptData/TopInfo";
import Synonyms from "./components/conceptData/Synonyms";
import ParentClasses from "./components/classification/ParentClasses";
import ChildClasses from "./components/classification/ChildClasses";
import ExternalLinkIcon from "../general/ExternalLinkIcon/ExternalLinkIcon";
import ExternalSources from "./components/linkouts/ExternalSources";
import GoogleLinkouts from "./components/linkouts/GoogleLinkouts";
import Identifiers from "./components/compounds/Identifiers";
import DownloadButton from "./components/compounds/DownloadButton";
import PhysicoChemicalProperties from "./components/compounds/PhysicoChemicalProperties";
import StructureImage from "./components/compounds/StructureImage";
import Definition from "./components/conceptData/Definition";
import Cooccurrences from "./components/related/Cooccurrences";
import useDomainLabel from "../../common/customHooks/domain-label";
import useDomainColor from "../../common/customHooks/domain-color";
import TruncateToggleButton from "./common/TruncateToggleButton";
import ResponseGrowl from "../../common/growls/ResponseGrowl";


const CONTAINER_ID = "conceptDetailsContainer";
const NUM_OF_TRUNCATED_SYNONYMS = 20;
const NUM_OF_TRUNCATED_SOURCES = 10;

const ConceptDetails = ({
  includeQuickSearchShortcut = true,
  includeChemSearchShortcut = true,
  includeCoocSearchShortcut = true,
  ocid,
  onConceptClick,
}) => {

  const [conceptData, setConceptData] = useState({});
  const [apiResponse, setApiResponse] = useState({});
  const [isChemCompound, setIsChemCompound] = useState();
  const [truncateSynonyms, setTruncateSynonyms] = useState(true);
  const [truncateSources, setTruncateSources] = useState(true);
  const [loading, setLoading] = useState();

  useEffect(() => {

    if (ocid) {
      const fetchData = async () => {

        setLoading(true);
        setIsChemCompound(hasUserRole(USER_ROLE_COMPOUND_SEARCH) && isChemCompoundOcid(ocid));

        const conceptData = await handleFetchConceptDataForOcid(ocid);
        setConceptData(conceptData);
        setLoading(false);

        if (APP_PROPERTIES.CHEMISTRY.INCLUDE_MCULE_LINKS && !isArrayEmpty(conceptData?.extendedData?.smiles)) {
          const mculeUrl = await handleFetchMculeUrlForSmiles(conceptData.extendedData.smiles[0]);
          const conceptDataWithMculeUrl = addMculeDataToConceptData({ ...conceptData }, mculeUrl);
          setConceptData(conceptDataWithMculeUrl);
        }
      };
      fetchData();
    }
  }, [ocid]);


  const handleFetchConceptDataForOcid = async (ocid) => {

    const response = await fetchConceptDataForOcid(ocid);
    setApiResponse(response);

    return response?.payload || {};
  }


  const handleFetchMculeUrlForSmiles = async (smiles) => {

    const response = await fetchMculeUrlForSmiles(smiles);
    setApiResponse(response);

    return response?.payload || '';
  }


  const domainLabel = useDomainLabel(conceptData.domain);
  const domainColor = useDomainColor(conceptData.domain);

  const hasDefinition = !isArrayEmpty(conceptData.extendedData?.definition);
  const navigationItems = filterNavigationItems(hasDefinition, isChemCompound);
  const synonymsToDisplay = truncateSynonyms ? truncateList(conceptData.synonyms, NUM_OF_TRUNCATED_SYNONYMS) : conceptData.synonyms;
  const sourcesToDisplay = truncateSources ? truncateList(conceptData.sourceReferences, NUM_OF_TRUNCATED_SOURCES) : conceptData.sourceReferences;

  return <>
    <div id={CONTAINER_ID} className="height100perc overflowAuto">
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading concept data..." >
        {!isObjectEmpty(conceptData) ?
          <div className="grid container-panel height100perc width100perc breakWord">
            <div className="col-fixed left-panel height100perc overflowAuto">
              <Navigation
                navigation={navigationItems}
                scrollToContainerID={CONTAINER_ID}
              />
            </div>
            <div className="col main-panel height100perc">
              <Element name={CONCEPT_DETAILS_NAVIGATION_TOP_ELEMENT}>
                <TopInfo
                  conceptData={conceptData}
                  domainColor={domainColor}
                  domainLabel={domainLabel}
                  includeQuickSearchShortcut={includeQuickSearchShortcut}
                  includeChemSearchShortcut={includeChemSearchShortcut}
                  includeCoocSearchShortcut={includeCoocSearchShortcut}
                />
              </Element>
              <Element name={CONCEPT_DETAILS_NAVIGATION_DEFINITION_ELEMENT}>
                {hasDefinition && <>
                  <h2>Definition</h2>
                  <Definition definitions={conceptData.extendedData?.definition} />
                </>}
              </Element>
              <Element name={CONCEPT_DETAILS_NAVIGATION_STRUCTURE_ELEMENT}>
                {isChemCompound &&
                  <div className='grid'>
                    <div className="col-6">
                      <h2>Structure</h2>
                      <StructureImage
                        image={conceptData.image}
                        name={conceptData.preferredName}
                      />
                    </div>
                  </div>}
              </Element>
              <Element name={CONCEPT_DETAILS_NAVIGATION_PROPERTIES_ELEMENT}>
                {isChemCompound &&
                  <div className='grid'>
                    <div className="col-6">
                      <h2>Identifiers</h2>
                      <Identifiers extendedConceptData={conceptData.extendedData} />
                    </div>
                    <div className="col-6">
                      <h2>Properties</h2>
                      <PhysicoChemicalProperties ocid={ocid} />
                    </div>
                  </div>}
              </Element>
              <Element name={CONCEPT_DETAILS_NAVIGATION_SYNONYMS_ELEMENT}>
                <h2>{`Synonyms (${conceptData.synonyms?.length || '0'})`}</h2>
                <Synonyms synonyms={synonymsToDisplay} />
                {(conceptData.synonyms?.length > NUM_OF_TRUNCATED_SYNONYMS) &&
                  <div><TruncateToggleButton
                    truncated={truncateSynonyms}
                    handleChange={(toggle) => setTruncateSynonyms(toggle)}
                    scrollToContainerID={CONTAINER_ID}
                    scrollTargetElement={CONCEPT_DETAILS_NAVIGATION_SYNONYMS_ELEMENT}
                  /></div>}
              </Element>
              <Element name={CONCEPT_DETAILS_NAVIGATION_COOCS_ELEMENT}>
                <h2>Co-occurrences</h2>
                <Cooccurrences
                  ocid={conceptData.ocid}
                  domain={conceptData.domain}
                  name={conceptData.preferredName}
                />
              </Element>
            </div>
            <div id="rightDiv" className="col-fixed right-panel height100perc">
              {isChemCompound &&
                <DownloadButton
                  ocid={ocid}
                  popupStyle={{ width: 320 }}
                />}
              <Element name={CONCEPT_DETAILS_NAVIGATION_CLASSIFICATIONS_ELEMENT}>
                <h3>{`Parent classes (${conceptData.parents?.length || '0'})`}</h3>
                <ParentClasses
                  concepts={conceptData.parents}
                  onConceptClick={onConceptClick}
                />
                <h3>{`Child classes (${conceptData.childrenCount || '0'})`}</h3>
                <ChildClasses
                  ocid={ocid}
                  onConceptClick={onConceptClick}
                />
              </Element>
              <Element name={CONCEPT_DETAILS_NAVIGATION_LINKOUTS_ELEMENT}>
                <h3 className="flex-vertical-align-middle">
                  {`External sources (${conceptData.sourceReferences?.length || '0'})`}
                  <ExternalLinkIcon style={{ fontSize: 15, marginLeft: 5 }} />
                </h3>
                <ExternalSources
                  sourceReferences={sourcesToDisplay}
                  style={{ marginLeft: 5 }}
                />
                {(conceptData.sourceReferences?.length > NUM_OF_TRUNCATED_SOURCES) &&
                  <div><TruncateToggleButton
                    truncated={truncateSources}
                    handleChange={(toggle) => setTruncateSources(toggle)}
                    scrollToContainerID={CONTAINER_ID}
                    scrollTargetElement={CONCEPT_DETAILS_NAVIGATION_LINKOUTS_ELEMENT}
                  /></div>}
              </Element>
              <GoogleLinkouts smiles={conceptData.extendedData?.smiles} />
            </div>
          </div> : null}
      </LoadingOverlay>
    </div>

    <ResponseGrowl
      response={apiResponse}
      showErrorMessage={true}
    />
  </>
}

export default ConceptDetails;