import { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import {
  installAvailableCartridge,
  fetchCartridge,
  rmCartridge,
  addCartridgeDomain,
  editCartridgeDomain,
  showGrowl
} from '../../../../redux/actions';
import EditCartridgeDialog from './EditCartridgeDialog';
import { MdErrorOutline } from 'react-icons/md';

/**
 * handle a single cartridge
 */
class Cartridge extends Component {

  constructor(props) {
    super(props);

    //console.log (props);

    this.state = {
      // is this installed cartridge about to be deleted? (-> for displaying a loading sign)
      deleting: false,
      // is this available cartridge about to be installed? (-> for displaying a loading sign)
      installing: false,
    };

    this.fetchCartridge = this.fetchCartridge.bind(this);
    this.installAvailableCartridge = this.installAvailableCartridge.bind(this);
    this.rmCartridge = this.rmCartridge.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.cartridgeDeleting)
      this.setState({ deleting: false });
    if (!nextProps.cartridgeInstallingAvailable)
      this.setState({ installing: false });
  }

  /**
   * render the component as a row in the cartridges table
   */
  render() {

    // can this be installed or downloaded?
    /*
    let button = <span>error</span>;
    if (this.props.state === "INSTALLED")
      button = <Button icon="pi-md-file-download" onClick={this.fetchCartridge} tooltip={"Download " + this.props.name}/>
    if (this.props.state === "AVAILABLE")
      button = <Button icon={this.state.installing ? "pi-spin pi-spinner" : "pi-md-plus" } className="p-button-success" onClick={() => this.props.showWarningModal (this.installAvailableCartridge)} tooltip={"Install " + this.props.name}/>
    
    // if this cartridge is installed -> can be deleted
    let deleteButton = <Button icon={this.state.deleting ? "pi-spin pi-spinner" : "pi-md-trash" } className="p-button-danger p-button-sm" onClick={() => this.props.showWarningModal (this.rmCartridge)} tooltip={"Remove " + this.props.name}/>;
    if (this.props.state === "AVAILABLE")
      deleteButton = ""
    */

    let button = <span>error</span>;
    if (this.props.state === "INSTALLED")
      button = <Button label={`Download`} className="p-button-text primaryButtonLink"
        title={`Download ${this.props.name}`} onClick={this.fetchCartridge} />
    if (this.props.state === "AVAILABLE")
      button = <Button label={this.state.installing ? "Installing" : "Install"} className="p-button-text primaryButtonLink"
        title={`Install ${this.props.name}`} onClick={() => this.props.showWarningModal(this.installAvailableCartridge)} />

    // if this cartridge is installed -> can be deleted
    let deleteButton = <Button label={this.state.deleting ? "Deleting" : "Delete"}
      className="p-button-text dangerButtonLink" title={`Remove ${this.props.name}`}
      onClick={() => this.props.showWarningModal(this.rmCartridge)}
    />
    if (this.props.state === "AVAILABLE")
      deleteButton = ""

    let editButton = this.props.type === 'dictionary' ? <Button label={"Edit"}
      className="p-button-text primaryButtonLink" title={`Edit ${this.props.name}`}
      onClick={() => this.setState({
        displayEditDialog: true,
        newLabel: this.props.label,
        newColor: this.props.color
      })}
    /> : null;

    const domainInfoMissing = this.props.type === 'dictionary' && (!this.props.label || !this.props.color);
    const bgColor = domainInfoMissing ? '#fccfcf' : '';
    const tooltip = domainInfoMissing ? 'Label and/or highlighting color are missing. Please edit the cartridge settings.' : this.props.description;
    const label = this.props.label || '-';
    const colorInfo = this.props.color ? <span style={{ background: `#${this.props.color}` }}>Preview</span> : '-';
    const hasMoreInfo = this.props.size > 0 || this.props.uri;

    const name = domainInfoMissing ?
      <span className="flex-center flex-col-gap-small">
        {domainInfoMissing && <MdErrorOutline />}
        <span>{this.props.name}</span>
      </span>
      :
      <span>{this.props.name}</span>

    const more = deleteButton ? deleteButton : hasMoreInfo ? <span title={`Size: ${this.props.size} | URI: ${this.props.uri}`}>More</span> : null

    return (
      <>
        <div className={`grid cartridge-${this.props.state}`} style={{ background: bgColor }} title={tooltip}>
          <div className="col"><strong>{name}</strong></div>
          <div className="col">{label}</div>
          <div className="col-fixed" style={{ width: 120 }}>{colorInfo}</div>
          <div className="col-fixed" style={{ width: 100 }}>{this.props.type}</div>
          <div className="col-fixed" style={{ width: 50 }}>{editButton}</div>
          <div className="col-fixed" style={{ width: 80 }}>{button}</div>
          <div className="col-fixed" style={{ width: 60 }}>{more}</div>
        </div>

        <EditCartridgeDialog
          displayDialog={this.state.displayEditDialog}
          id={this.props.domainID}
          name={this.props.name}
          label={this.props.label}
          color={this.props.color}
          description={this.props.description}
          onHide={() => this.setState({ displayEditDialog: false })}
          onSubmit={this.storeCartridgeDomain}
        />
      </>
    );
  }

  /**
   * delete this cartridge
   */
  rmCartridge() {
    if (this.props.cartridgeInstallingAvailable || this.props.cartridgeDeleting || this.props.cartridgeUploading.length > 0) {
      this.props.showGrowl("Changing cartridges not allowed", "The setup of cartridges is currently in process. Please try again later.");
      return;
    }

    this.setState({ deleting: true });
    this.props.rmCartridge(this.props.name)
  }

  /**
   * download this cartridge as ZIP archive
   */
  fetchCartridge = async () => {
    if (this.props.cartridgeInstallingAvailable || this.props.cartridgeDeleting || this.props.cartridgeUploading.length > 0) {
      this.props.showGrowl("Changing cartridges not allowed", "The setup of cartridges is currently in process. Please try again later.");
      return;
    }

    this.props.fetchCartridge(this.props.name);
  }

  /**
   * install this available cartridge
   */
  installAvailableCartridge = async () => {
    if (this.props.cartridgeInstallingAvailable || this.props.cartridgeDeleting || this.props.cartridgeUploading.length > 0) {
      this.props.showGrowl("Changing cartridges not allowed", "The setup of cartridges is currently in process. Please try again later.");
      return;
    }

    this.setState({ installing: true });
    this.props.installAvailable(this.props.uri);
  }

  /**
   * Stores a new or edits an existing cartridge domain.
   * Domain consists of a label, a name and a color.
   * If the domain already exists, the id is used for identification.
   * @param {Numeric} id numerical domain ID (MW identifier)
   * @param {string} label domain label
   * @param {string} name domain name (BE identifier)
   * @param {string} color color used for NE highlighting
   */
  storeCartridgeDomain = async (id, updatedDomain = {}) => {
    this.setState({ displayEditDialog: false });
    const origDomain = this.props.domain || {};

    if (id) {
      this.props.editCartridgeDomain({ id, ...origDomain, ...updatedDomain });
    }
    else {
      this.props.addCartridgeDomain({ ...origDomain, ...updatedDomain });
    }
  }
}

const mapStateToProps = state => {
  return {
    cartridgeUploading: state.managerReducer.cartridgeUploading,
    cartridgeDeleting: state.managerReducer.cartridgeDeleting,
    cartridgeInstallingAvailable: state.managerReducer.cartridgeInstallingAvailable
  }
}

const mapDispatchToProps = dispatch => {
  return {
    installAvailable: (url, finishFunc) => dispatch(installAvailableCartridge(url, finishFunc)),
    fetchCartridge: (name) => dispatch(fetchCartridge(name)),
    rmCartridge: (name) => dispatch(rmCartridge(name)),
    addCartridgeDomain: (cartDomain) => dispatch(addCartridgeDomain(cartDomain)),
    editCartridgeDomain: (cartDomain) => dispatch(editCartridgeDomain(cartDomain)),
    showGrowl: (summary, detail, severity = "info") => dispatch(showGrowl(summary, detail, severity)),
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cartridge);


