import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

export const createSeries = (chart, categoryAxis, {searchName, data, color}) => {
    //set chart data
    chart.current.data = [...chart.current.data, ...data];

    // create series
    const series = chart.current.series.push(new am4charts.LineSeries());

    series.name = searchName;
    series.dataFields.valueY = 'value';
    series.stroke = am4core.color(color);
    series.strokeWidth = 3;
    series.xAxis = categoryAxis.current;
    series.dataFields.categoryX = 'date';

    // style estimated line
    series.propertyFields.strokeDasharray = "dash";

    series.data = data;

    const bullet = series.bullets.push(new am4charts.Bullet());
    bullet.propertyFields.showTooltipOn = "showTooltip";
    bullet.tooltipText = `{label} ({date}): [bold]{value}[/] {estimated}`;
    bullet.fill = am4core.color(color);

    const circle = bullet.createChild(am4core.Circle);
    circle.width = 5;
    circle.height = 5;
    circle.horizontalCenter = "middle";
    circle.verticalCenter = "middle";
}
