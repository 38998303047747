import * as ErrorHelpers from '../../helpers/error';
import { Service } from '../../Main/service/Service';

export class NotificationsService extends Service {
  async getNotifications() {
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('getNotifications', 'NotificationsService'),
    );
  }

  async createNotification(notificationText) {
    console.error(notificationText)
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('createNotification', 'NotificationsService'),
    );
  }

  async toggleNotificationState(notificationId, notificationState) {
    console.error(notificationId, notificationState)
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('toggleNotificationState', 'NotificationsService'),
    );
  }
  
  async updateNotification(notification) {
    console.error(notification)
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('updateNotification', 'NotificationsService'),
    );
  }

  async deleteNotification(notificationId) {
    console.error(notificationId)
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('deleteNotification', 'NotificationsService'),
    );
  }
}
