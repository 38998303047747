export const DOMAIN_COLORS = {
    anatomy: { red: 1, green: 1, blue: 1, color: 'FCD2B3' },
    biomarker: { red: 1, green: 1, blue: 1, color: 'd2c3db' },
    celllines: { red: 1, green: 1, blue: 1, color: 'FFE7D3' },
    chem: { red: 1, green: 1, blue: 1, color: 'A1CDE2' },
    chemClass: { red: 1, green: 1, blue: 1, color: '85d1c7' },
    chemCompound: { red: 1, green: 1, blue: 1, color: 'A1CDE2' },
    chemGroup: { red: 1, green: 1, blue: 1, color: 'aef2e9' },
    clinicaltrials: { red: 1, green: 1, blue: 1, color: 'FDDFFF' },
    companies: { red: 1, green: 1, blue: 1, color: 'D3AA8B' },
    countries: { red: 1, green: 1, blue: 1, color: 'DFA189' },
    cosmetology: { red: 1, green: 1, blue: 1, color: '9feaf2' },
    diabetes: { red: 1, green: 1, blue: 1, color: 'CCA0FF' },
    diseases: { red: 1, green: 1, blue: 1, color: 'F9BBBB' },
    devices: { red: 1, green: 1, blue: 1, color: 'CCA0FF' },
    drugs: { red: 1, green: 1, blue: 1, color: 'c6d365' },
    effects: { red: 1, green: 1, blue: 1, color: 'D6D6D6' },
    humangenes: { red: 1, green: 1, blue: 1, color: 'e2f986' },
    herbdrugs: { red: 1, green: 1, blue: 1, color: 'E7EFAA' },
    inorgmat: { red: 1, green: 1, blue: 1, color: 'DFDAA7' },
    magnitudes: { red: 1, green: 1, blue: 1, color: 'F99D9D' },
    methods: { red: 1, green: 1, blue: 1, color: 'E3E7EA' },
    natprod: { red: 1, green: 1, blue: 1, color: 'BFDBB1' },
    nutrition: { red: 1, green: 1, blue: 1, color: 'C2E5FF' },
    nybeauty: { red: 1, green: 1, blue: 1, color: 'E9D59A' },
    plantstruc: { red: 1, green: 1, blue: 1, color: 'F7B16F' },
    polymers: { red: 1, green: 1, blue: 1, color: 'C0E2DB' },
    proteins: { red: 1, green: 1, blue: 1, color: '98eac4' },
    regions: { red: 1, green: 1, blue: 1, color: 'DDC2AF' },
    species: { red: 1, green: 1, blue: 1, color: 'C1FFC1' },
    speciessrc: { red: 1, green: 1, blue: 1, color: 'DAEA62' },
    substances: { red: 1, green: 1, blue: 1, color: 'e0f2f9' },
    toxicity: { red: 1, green: 1, blue: 1, color: 'E2D3D3' },
    units: { red: 1, green: 1, blue: 1, color: 'ffffaa' },
    women: { red: 1, green: 1, blue: 1, color: 'E8CDAE' },
    womeness: { red: 1, green: 1, blue: 1, color: 'E9D6A0' },
}
export const QUERY_HIT_COLOR = { red: 1, green: 1, blue: 1, color: 'FFFF02' }

String.prototype.convertToRGB = function () {
    if (this.length != 6) {
        throw new Error ("Only six-digit hex colors are allowed.");
    }

    var aRgbHex = this.match(/.{1,2}/g);
    var aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
    ];
    return aRgb;
}

Object.values(DOMAIN_COLORS).forEach(dom => {
    const rgb = dom.color.convertToRGB();
    if (rgb) {
        dom.red = rgb[0] / 255;
        dom.green = rgb[1] / 255;
        dom.blue = rgb[2] / 255;
        delete dom.color;
    }
})
const rgb = QUERY_HIT_COLOR.color.convertToRGB();
if (rgb) {
    QUERY_HIT_COLOR.red = rgb[0];
    QUERY_HIT_COLOR.green = rgb[1];
    QUERY_HIT_COLOR.blue = rgb[2];
    delete QUERY_HIT_COLOR.color;
}

//console.log('DOMAIN_COLORS: ', DOMAIN_COLORS);


/*
const sortDomainsByLabel = (domains) => {
    // --- sort domains by domain labels --- //
    const domainsSorted = {};
    if (domains) {
        Object.keys(domains).sort(function (a, b) {
            var x = domains[a].label.toLowerCase();
            var y = domains[b].label.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        }).forEach(function (key) {
            domainsSorted[key] = domains[key];
        });
    }
    return domainsSorted;
};

export const DOMAINS_MAP = sortDomainsByLabel({
    womeness: { label: 'Essentials for Women', value: 'womeness' },
    magnitudes: { label: 'Magnitude', value: 'magnitudes' },
    women: { label: 'Women', value: 'women' },
    methods: { label: 'Method', value: 'methods' },
    natprod: { label: 'Natural product', value: 'natprod' },
    toxicity: { label: 'Topic: Toxicology', value: 'toxicity' },
    plantstruc: { label: 'Anatomy (plant/fungi)', value: 'plantstruc' },
    anatomy: { label: 'Anatomy (other)', value: 'anatomy' },
    chem: { label: 'Chemistry', value: 'chem' },
    celllines: { label: 'Cell line', value: 'celllines' },
    chemCompound: { label: 'Chemistry: compound', value: 'chemCompound' },
    chemClass: { label: 'Chemistry: class', value: 'chemClass' },
    chemGroup: { label: 'Chemistry: group', value: 'chemGroup' },
    clinicaltrials: { label: 'Topic: Clinical trial', value: 'clinicaltrials' },
    companies: { label: 'Company', value: 'companies' },
    devices: { label: 'Device', value: 'devices' },
    diseases: { label: 'Disease', value: 'diseases' },
    herbdrugs: { label: 'Herbal drug', value: 'herbdrugs' },
    humangenes: { label: 'Human gene', value: 'humangenes' },
    inorgmat: { label: 'Inorganic material', value: 'inorgmat' },
    proteins: { label: 'Protein', value: 'proteins' },
    polymers: { label: 'Polymer', value: 'polymers' },
    species: { label: 'Species', value: 'species' },
    regions: { label: 'Region', value: 'regions' },
    substances: { label: 'Substance', value: 'substances' },
    drugs: { label: 'Drug', value: 'drugs' },
    cosmetology: { label: 'Topic: Cosmetology', value: 'cosmetology' },
    nutrition: { label: 'Topic: Nutrition', value: 'nutrition' },
    effects: { label: 'Effect or process', value: 'effects' },
    units: { label: 'Unit', value: 'units' },
    properties: { label: 'Property', value: 'properties' }
});

export const getDomainLabel = (domainID) => {
    return DOMAINS_MAP[domainID] && DOMAINS_MAP[domainID].label ? DOMAINS_MAP[domainID].label : domainID;
};

export const isDomainDefined = (domainID) => {
    return DOMAINS_MAP[domainID] !== 'undefined';
};
*/