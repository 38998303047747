import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from "primereact/dialog";
import {
    setSelectedSearches,
    setShowModal,
    setYearStatisticsSearchParameters
} from "../../../../redux/actions/YearStatisticsActions";
import { useDispatch, useSelector } from "react-redux";
import { TrendAnalysisModalFooter } from "./TrendAnalysisModal/TrendAnalysisModalFooter";
import styles from './TrendAnalysisModal.module.scss'
import { TrendAnalysisModalItem } from "./TrendAnalysisModal/TrendAnalysisModalItem/TrendAnalysisModalItem";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
//import { ListBox } from "primereact/listbox";
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import { fetchAllQueries } from "../../../../api/TestApi";
import './TrendAnalysisModal.css';
// { TrendAnalysisSavedSearch } from "./TrendAnalysisModal/TrendAnalysisSavedSearch/TrendAnalysisSavedSearch";
import { selectionLimit } from "../../../../redux/reducers/yearStatistics";
import { TrendAnalysisSearchContainer } from "./TrendAnalysisSearchContainer";
import { convertQueryConceptsToQueryString } from "../../../../api/content/QueryApi";
import { Toast } from "primereact/toast";
import { QUERY_TYPE_ADVANCED_SEARCH, QUERY_TYPE_QUICK_SEARCH, QUERY_TYPE_QUICK_SEARCH_2 } from '../../../../properties';

export const TrendAnalysisModal = () => {
    let growl = useRef(null);
    const dispatch = useDispatch();
    const showModal = useSelector(({ yearStatistics: { showModal } }) => showModal);
    const selectedSearches = useSelector(({ yearStatistics: { selectedSearches } }) => selectedSearches);
    const searchParameters = useSelector(({ yearStatistics: { searchParameters } }) => searchParameters);
    const [savedSearches, setSavedSearches] = useState([]);
    const [selectedWatchlistsAction, setSelectedWatchlistsAction] = useState(null);
    const [globalFilterWatchlists, setGlobalFilterWatchlists] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [queryTerms, setQueryTerms] = useState([]);
    const [fetching, setFetching] = useState(false)

    const getSavedSearches = async () => {
        setFetching(true)
        try {
            const response = await fetchAllQueries();
            const filteredSearches = response.payload.content
                .filter(({ type }) => type === QUERY_TYPE_QUICK_SEARCH.id || type === QUERY_TYPE_QUICK_SEARCH_2.id || type === QUERY_TYPE_ADVANCED_SEARCH.id)

            setSavedSearches(filteredSearches);
            setFetching(false)
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (showModal)
            getSavedSearches();
    }, [showModal])

    const addToChart = (rowData) => {
        const searchNames = searchParameters.map(({ searchName }) => searchName);

        if (searchParameters.length < selectionLimit) {
            const searchName = savedSearches.find(({ id }) => id === rowData.id).fullName;


            let newSearchParameters
            if (rowData.hasOwnProperty("filterQueries")) {
                newSearchParameters = { query: rowData.value, filterQueries: rowData.filterQueries, formContent: rowData.formContent };
            } else {
                newSearchParameters = { query: rowData.value };
            }


            const searchOptions = { searchName, searchParameters: newSearchParameters };


            if (searchNames.includes(searchName)) {
                const selected = [...selectedSearches];
                const firstEmptyIndex = selected.findIndex(repo => repo.searchName === null);
                const target = selected.find(repo => repo.searchName === searchName);

                /*console.log('searchNames', searchNames);
                console.log('searchName', searchName);
                console.log('selected', selected);
                console.log('firstEmptyIndex', firstEmptyIndex);
                console.log('target', target);*/

                selected[firstEmptyIndex].repoName = target.repoName;
                selected[firstEmptyIndex].searchName = target.searchName;

                dispatch(setSelectedSearches(selected));
            } else {
                dispatch(setYearStatisticsSearchParameters([...searchParameters, searchOptions]));
            }

            setActiveIndex(0)
            setSelectedWatchlistsAction(null)
            setGlobalFilterWatchlists("")
            setQueryTerms([])
            dispatch((setShowModal(false)));
        }
    };

    //const titleTemplate = (rowData) => <TrendAnalysisSavedSearch rowData={rowData} addToChart={addToChart} />

    const nameTemplate = (rowData) => {
        //console.log(rowData)
        if (rowData.fullName) {
            //let title = rowData.fullName
            /*let collectionList = ''
            if (rowData.queryCollectionList) {
                rowData.queryCollectionList.forEach(list => {
                    collectionList += list.name + ', '
                })
                collectionList = collectionList.substring(0, collectionList.length - 2)
            }*/
            return <div>
                {/*<span className="p-column-title">Search</span>*/}
                <span style={{ marginBottom: 5 }}>
                    {rowData.fullName}
                </span>
                {/*
                    rowData.fullName ?
                        <span style={{ display: 'block', paddingTop: 5 }}>
                            <label>{rowData.fullName}</label>
                        </span>
                        :
                        null
        */}
                {/*<span style={{ display: 'block', paddingTop: 5 }}>
                            {!rowData.shared ?
                                (rowData.sharedForDepartment || (rowData.usersSharedWith && rowData.usersSharedWith.length > 0)) ?
                                    <span title={`You share this search collection with other users`}>
                                        {' - Shared by you'}
                                    </span>
                                    :
                                    <span title={`This search collection can only be seen by you`}>
                                        {' - Private'}
                                    </span>
                                :
                                <span title={`This search collection is shared with you by another user`}>
                                    {' - Shared with you'}
                                </span>
                            }
                        </span>
                }
                {/*
                    
                */}
                {rowData.description !== '' &&
                    <span style={{ display: 'block', paddingTop: 5, color: '#757575' }}>
                        <label >{rowData.description}</label>
                    </span>
                }
                {rowData.owner ?
                    <span style={{ display: 'block', paddingTop: 5 }}>
                        <label style={{ color: '#757575' }}>{`Owner: ${rowData.owner?.lastName}, ${rowData.owner?.forename}`}</label>
                    </span>
                    : null
                }
                {/*collectionList !== '' ?
                    <span style={{ display: 'block', paddingTop: 5 }}>
                        <label style={{ color: '#757575' }}>In search collections: {collectionList}</label>
                    </span>
                    : rowData.queryCollectionList.length >= 2 ?
                        <span style={{ display: 'block', paddingTop: 5 }}>
                            <label style={{ color: '#757575' }}>In search collections: {collectionList}</label>
                        </span>
                        : null
            */}
            </div>
        }
    }


    const collectionsTemplate = (rowData) => {
        return <div>
            <span style={{ marginBottom: 5, marginLeft: 0 }}>
                {rowData.collections}
            </span>
        </div>
    }

    let headerWatchlists = <div className='grid' style={{
        marginRight: 0,
        paddingTop: 18, paddingBottom: 10
    }}>
        <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
            <i className="material-icons"
                style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                title="Search">
                {'search'}
            </i>
            <InputText
                style={{
                    border: 'none',
                    width: '90%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                    whiteSpace: 'nowrap'
                }}
                type="search"
                autoComplete="off"
                className="collectionFilter"
                onInput={(e) => setGlobalFilterWatchlists(e.target.value)}
                placeholder="Search" />
        </div>
    </div>

    const onWatchlistChange = (e) => {
        setSelectedWatchlistsAction(e.value)
    }


    const callbackFromQueryChange = (values) => {
        setQueryTerms(values)
    }


    const callbackFromAddClick = () => {
        if (activeIndex === 0) {


            if (!queryTerms?.length) {
                let msg = { severity: 'warn', summary: 'Warning!', detail: 'Empty search.', life: 2000 };

                growl.show(msg);
                return
            }

            const outOfLimit = searchParameters.length >= selectionLimit;

            if (outOfLimit) {
                let msg = {
                    severity: 'warn',
                    summary: 'Warning!',
                    detail: `Maximum number of series is ${selectionLimit}`,
                    life: 2000
                };

                growl.show(msg);
                return
            }

            const terms = queryTerms.map(({ term }) => term).join(', ');

            const searchOptions = {
                searchName: terms,
                searchParameters: convertQueryConceptsToQueryString(queryTerms, "and")
            }

            dispatch(setYearStatisticsSearchParameters([...searchParameters, searchOptions]));
            dispatch(setShowModal(false));
            setQueryTerms([])
            setActiveIndex(0)
            setSelectedWatchlistsAction(null)
            setGlobalFilterWatchlists("")
        } else {
            addToChart(selectedWatchlistsAction)
        }
    }

    const onHide = () => {
        dispatch(setShowModal(false));
        setQueryTerms([])
        setActiveIndex(0)
        setSelectedWatchlistsAction(null)
        setGlobalFilterWatchlists("")
    }

    let tableData = [];
    if (savedSearches && savedSearches.length > 0) {
        savedSearches.forEach(search => {
            search.collections = '-';
            if (search.queryCollectionList && search.queryCollectionList.length > 0) {
                search.collections = search.queryCollectionList.map(list => list.name).join(', '); // ·
            }
            tableData.push(search);
        })
    }

    const loading = fetching
    const loadingMessage = "Loading saved searches"

    return (
        <React.Fragment>
            <Toast ref={(el) => growl = el} />
            <LoadingOverlay
                active={loading}
                text={loadingMessage}
                spinner={true}
                className="fullPage_Z">
                <Dialog header='Add search to chart' onHide={() => onHide()} visible={showModal}
                    footer={() => <TrendAnalysisModalFooter
                        callbackFromAddClick={callbackFromAddClick}
                        activeIndex={activeIndex}
                        queryTerms={queryTerms}
                        selectedWatchlistsAction={selectedWatchlistsAction}
                        onHide={(e) => onHide(e)}
                    />}
                    blockScroll
                    breakpoints={{ '960px': '70vw', '640px': '90vw' }}
                    style={{ 'width': "60vw", 'height': '75vh' }}
                    className='styledDialog'>
                    <div style={{ paddingLeft: 25, paddingTop: 10 }}>
                        <p>Create a new search or select one of your saved searches to add to the trend analysis. </p>
                    </div>
                    <div id='trend-analysis-modal' className={styles.wrapper} style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 15, paddingTop: 5, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="New search" style={{ paddingRight: 0 }}>
                                <TrendAnalysisModalItem id='trend-analysis-quick-search' title='' style={{}}>
                                    <TrendAnalysisSearchContainer
                                        queryTerms={queryTerms}
                                        callbackFromQueryChange={callbackFromQueryChange}
                                    />
                                </TrendAnalysisModalItem>
                            </TabPanel>
                            <TabPanel header="Saved searches">
                                <TrendAnalysisModalItem title=''>
                                    {/*<ListBox id='year-statistics-saved-searches' options={savedSearches}
    itemTemplate={titleTemplate} filter optionLabel='name' filterPlaceholder='Search'/>*/}
                                    <div style={{ marginTop: -20 }}>
                                        {headerWatchlists}
                                        <DataTable
                                            scrollable
                                            scrollHeight='350px'
                                            selection={selectedWatchlistsAction}
                                            selectionMode="radiobutton"
                                            removableSort={true}
                                            onSelectionChange={e => onWatchlistChange(e)}
                                            //className="dataTableQueries checkboxCircle standardTableHeader"
                                            //className="p-datatable-responsive-demo"
                                            value={tableData}
                                            responsive="true"
                                            paginator={false}
                                            //rows={5}
                                            globalFilter={globalFilterWatchlists}>
                                            {/*{columnDataWatchlists}*/}
                                            <Column field="check" selectionMode='single' style={{ width: '4em', fontWeight: 'normal' }}>
                                            </Column>
                                            <Column field="fullName" header="Search" sortable={true} body={nameTemplate}>
                                            </Column>
                                            <Column field="collections" header="In collections" sortable={true} body={collectionsTemplate} />
                                        </DataTable>
                                    </div>
                                </TrendAnalysisModalItem>
                            </TabPanel>
                        </TabView>
                    </div>
                </Dialog>
            </LoadingOverlay>
        </React.Fragment>
    )
}
