import { //PointerEventHandler, 
  PureComponent
} from "react";
import debounce from "lodash.debounce";
import { createRoot } from 'react-dom/client';
import {
  EventBus,
  PDFViewer,
  PDFLinkService,
  //PDFFindController
  // @ts-ignore
} from "pdfjs-dist/legacy/web/pdf_viewer";

import "pdfjs-dist/web/pdf_viewer.css";
import "../style/pdf_viewer.css";
import "../style/PdfHighlighter.css";
import getBoundingRect from "../lib/get-bounding-rect";
import {
  findContainerLayer,
  findOrCreateContainerLayer,
} from "../lib/pdfjs-dom";

import TipContainer from "./TipContainer";
import {
  scaledToViewport //, viewportToScaled 
} from "../lib/coordinates";

import type {
  Position,
  ScaledPosition,
  IHighlight,
  //Scaled,
  LTWH,
  T_EventBus,
  T_PDFJS_Viewer,
  T_PDFJS_LinkService,
} from "../types";
import type { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import { ToolbarContainer } from "./toolbar/container";

import { EMPTY_ID, ZOOM_TEXT } from "../constant";
import _ from "lodash";
import "../../../AnnotatedTextSentences.css"

type T_ViewportHighlight<T_HT> = { position: Position } & T_HT;

interface State<T_HT> {
  ghostHighlight: {
    position: ScaledPosition;
    content?: { text?: string; image?: string };
  } | null;
  isCollapsed: boolean;
  range: Range | null;
  tip: {
    highlight: T_ViewportHighlight<T_HT>;
    rectIndex: number,
    callback: (highlight: T_ViewportHighlight<T_HT>) => JSX.Element;
  } | null;
  tipPosition: Position | null;
  tipChildren: JSX.Element | null;
  isAreaSelectionInProgress: boolean;
  scrolledToHighlightId: string;
  zoomStep: number;
  pageNumber: number;
  currentRotate: number;
  zoomPrecentage: string;
  numPages: number;
  rectIndex: number;
  currentScale: number;
  activeSearchTerms: Array<any>,
  pagesRoot: Array<any>
  allImportantArePresent: boolean;
}

interface Props<T_HT> {
  highlightTransform: (
    highlight: T_ViewportHighlight<T_HT>,
    index: number,
    setTip: (
      highlight: T_ViewportHighlight<T_HT>,
      rectIndex: number,
      callback: (highlight: T_ViewportHighlight<T_HT>) => JSX.Element
    ) => void,
    hideTip: () => void,

    isScrolledTo: boolean

  ) => JSX.Element;
  highlights: Array<T_HT>;
  onScrollChange: () => void;
  scrollRef: (scrollTo: (highlight: IHighlight) => void) => void;
  pdfDocument: PDFDocumentProxy;
  pdfScaleValue: string;

  enableAreaSelection: (event: MouseEvent) => boolean;
  activeSearchTerms: Array<any>;
  isMatchOn: boolean;
  availableContentHeight: any;
  renderDocumentOnly: boolean;
  annotationCoords: Array<any>;
}


export class PdfHighlighter<T_HT extends IHighlight> extends PureComponent<
  Props<T_HT>,
  State<T_HT>
> {
  static defaultProps = {
    pdfScaleValue: "auto",
  };

  state: State<T_HT> = {
    ghostHighlight: null,
    isCollapsed: true,
    range: null,
    scrolledToHighlightId: EMPTY_ID,
    isAreaSelectionInProgress: false,
    tip: null,
    tipPosition: null,
    tipChildren: null,
    zoomStep: 7,
    pageNumber: 1,
    currentRotate: 0,
    zoomPrecentage: 'auto',
    numPages: 0,
    rectIndex: 0,
    currentScale: 1.25,
    activeSearchTerms: [],
    pagesRoot: [],
    allImportantArePresent: true
  };

  eventBus: T_EventBus = new EventBus();
  linkService: T_PDFJS_LinkService = new PDFLinkService({
    eventBus: this.eventBus,
    externalLinkTarget: 2,
  });

  // @ts-ignore
  viewer: T_PDFJS_Viewer;
  pdfFindController: any;
  resizeObserver: ResizeObserver | null = null;
  containerNode?: HTMLDivElement | null = null;
  unsubscribe = () => { };

  constructor(props: Props<T_HT>) {
    super(props);
    // if (typeof ResizeObserver !== "undefined") {
    //   this.resizeObserver = new ResizeObserver(this.debouncedScaleValue);
    // }
    this.pageNumberChanged = this.pageNumberChanged.bind(this)
  }

  componentDidMount() {
    this.init();

  }


  attachRef = (ref: HTMLDivElement | null) => {
    const { eventBus, resizeObserver: observer } = this;
    this.containerNode = ref;
    this.unsubscribe();

    if (ref) {
      // const { ownerDocument: doc } = ref;
      eventBus.on("textlayerrendered", this.onTextLayerRendered);
      eventBus.on("pagesinit", this.onDocumentReady);
      eventBus.on("pagechanging", this.pageNumberChanged);
      window?.addEventListener('keydown', (e) => {
        e = e || window.event
        if (e.key === 'PageUp') {
          this.prevPage()
        } else if (e.key === 'PageDown') {
          this.nextPage()
        } else if (e.key === 'ArrowLeft') {
          this.prevPage()
        } else if (e.key === 'ArrowRight') {
          this.nextPage()
        }
      });
      if (observer) observer.observe(ref);

      this.unsubscribe = () => {
        eventBus.off("pagechanging", this.pageNumberChanged);
        eventBus.off("pagesinit", this.onDocumentReady);
        eventBus.off("textlayerrendered", this.onTextLayerRendered);
        if (observer) observer.disconnect();
      };
    }
  };

  componentDidUpdate(prevProps: Props<T_HT>) {
    if (prevProps.pdfDocument !== this.props.pdfDocument) {
      this.init();
      return;
    }
    if (prevProps.highlights.length !== this.props.highlights.length
      || prevProps.isMatchOn !== this.props.isMatchOn
      || this.isDifference(prevProps.activeSearchTerms, this.props.activeSearchTerms)) {
      this.renderHighlights(this.props);
    }
  }



  isDifference(prevArr: Array<any>, newArr: Array<any>) {
    if (prevArr.length !== newArr.length) {
      return true
    } else {
      return !_.isEmpty(prevArr?.filter((item) => (item?.color !== newArr?.find((obj) => obj?.id === item?.id)?.color)));
    }
  }

  pageNumberChanged({ pageNumber, pageLabel }: any) {
    this.setState({
      pageNumber,
    })
  }


  init() {
    const { pdfDocument } = this.props;
    this.setState({
      numPages: pdfDocument.numPages
    })
    this.viewer =
      this.viewer ||
      new PDFViewer({
        container: this.containerNode,
        eventBus: this.eventBus,
        enhanceTextSelection: true,
        removePageBorders: true,
        linkService: this.linkService,
      });
    this.linkService.setDocument(pdfDocument);
    this.linkService.setViewer(this.viewer);
    this.viewer.setDocument(pdfDocument);
    // debug
    (window as any).PdfViewer = this;
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.setState({
      pagesRoot: []
    })
  }

  findOrCreateHighlightLayer(page: number) {
    const { textLayer } = this.viewer.getPageView(page - 1) || {};

    if (!textLayer) {
      return null;
    }

    const pageDetails = this.state.pagesRoot.find((item) => item.pageNumber === page)

    const container = findContainerLayer(textLayer.textLayerDiv,
      "PdfHighlighter__highlight-layer")

    if (pageDetails && !!container) {
      return pageDetails.root
    }
    else if (pageDetails && !container) {
      setTimeout(() => {
        pageDetails.root.unmount()
      }, 10)
    }

    const root = createRoot(findOrCreateContainerLayer(
      textLayer.textLayerDiv,
      "PdfHighlighter__highlight-layer"
    ));
    this.setState((prevState) => ({
      pagesRoot: [...prevState.pagesRoot.filter(item => item.pageNumber !== page), { pageNumber: page, root }]
    }))
    return root

  }

  groupHighlightsByPage(highlights: Array<T_HT>): {
    [pageNumber: string]: Array<T_HT>;
  } {
    const { ghostHighlight } = this.state;

    return [...highlights, ghostHighlight]
      .filter(Boolean)
      .reduce((res, highlight) => {
        const { pageNr } = highlight!.position;

        res[pageNr] = res[pageNr] || [];
        res[pageNr].push(highlight);

        return res;
      }, {} as Record<number, any[]>);
  }

  showTip(highlight: T_ViewportHighlight<T_HT>, rectIndex: number, content: JSX.Element) {
    const { isCollapsed, ghostHighlight, isAreaSelectionInProgress } =
      this.state;

    const highlightInProgress = !isCollapsed || ghostHighlight;

    if (highlightInProgress || isAreaSelectionInProgress) {
      return;
    }
    this.setTip(highlight.position, rectIndex, content);
  }

  scaledPositionToViewport({
    pageNr,
    rects,
    pageHeight,
    pageWidth,
    text
  }: ScaledPosition): Position {

    const viewport = this.viewer.getPageView(pageNr - 1).viewport;
    const scaledRects = (rects || []).map((rect: any) =>
      scaledToViewport(rect, pageHeight, pageWidth, viewport)
    )
    const scaledBoundingRect = getBoundingRect(scaledRects);

    return {
      boundingRect: scaledBoundingRect,
      rects: scaledRects,
      pageNumber: pageNr,
      text
    };
  }

  renderHighlights(nextProps?: Props<T_HT>) {
    const { highlightTransform, highlights } = nextProps || this.props;

    const { pdfDocument, activeSearchTerms } = this.props;

    const { tip, scrolledToHighlightId } = this.state;

    const highlightsByPage = this.groupHighlightsByPage(highlights);

    this.setState({ activeSearchTerms: activeSearchTerms })


    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {

      const highlightLayer = this.findOrCreateHighlightLayer(pageNumber);

      if (highlightLayer) {
        highlightLayer.render(<div >
          {(highlightsByPage[String(pageNumber)] || []).map(
            ({ position, annId, ...highlight }, index) => {
              // @ts-ignore
              const viewportHighlight: T_ViewportHighlight<T_HT> = {
                annId,
                position: position?.rects ? this.scaledPositionToViewport(position) : {},
                ...highlight
              };

              if (tip && tip.highlight.annId === String(annId)) {
                this.showTip(tip.highlight, tip.rectIndex, tip.callback(viewportHighlight));
              }

              const isScrolledTo = Boolean(scrolledToHighlightId === annId);
              return highlightTransform(
                viewportHighlight,
                index,
                (highlight, rectIndex, callback) => {
                  this.setState({
                    tip: { highlight, rectIndex, callback },
                  });

                  this.showTip(highlight, rectIndex, callback(highlight));
                },
                this.hideTipAndSelection,

                isScrolledTo
              );

            }
          )}
        </div>)

      }
    }

  }

  hideTipAndSelection = () => {
    this.setState({
      tipPosition: null,
      tipChildren: null,
    });


    this.setState({ ghostHighlight: null, tip: null }, () =>
      this.renderHighlights()
    );
  };

  setTip(position: Position, rectIndex: number, inner: JSX.Element | null) {
    this.setState({
      tipPosition: position,
      tipChildren: inner,
      rectIndex
    });
  }


  getClosestCoordinataiton = (data: Array<LTWH>, target: number) =>
    data.reduce((acc, obj) =>
      Math.abs(target - obj.left) < Math.abs(target - acc.left) ? obj : acc
    );

  renderTip = () => {
    const { tipPosition, tipChildren, rectIndex } = this.state;
    if (!tipPosition) return null;
    const { pageNumber } = tipPosition;
    const boundingRect = tipPosition.rects[rectIndex || 0];
    const page = {
      node: this.viewer.getPageView(pageNumber - 1).div,
    };
    return (
      <TipContainer
        scrollTop={this.viewer.container.scrollTop}
        pageBoundingRect={page.node.getBoundingClientRect()}
        style={
          {
            left: (page.node.offsetLeft + boundingRect.left + boundingRect.width / 2),
            top: boundingRect.top + page.node.offsetTop,
            bottom: boundingRect.top + page.node.offsetTop + boundingRect.height,
          }
        }
        pageScale={this.isNumber(this.viewer.currentScaleValue) ? parseFloat(this.viewer.currentScaleValue) : ZOOM_TEXT[this.viewer.currentScaleValue]
        }
      >
        {tipChildren}
      </TipContainer>
    );
  };

  onTextLayerRendered = (e: any) => {
    this.setState({
      pageNumber: this.viewer.currentPageNumber
    }, () => {
      this.renderHighlights()
    })
  };


  scrollTo = (highlight: IHighlight) => {
    const { pageNr, pageHeight, pageWidth, rects } = highlight.position;

    this.viewer.container.removeEventListener("scroll", this.onScroll);

    const pageViewport = this.viewer.getPageView(pageNr - 1).viewport;

    const scrollMargin = 10;
    const scaledRects = (rects || []).map((rect: any) =>
      scaledToViewport(rect, pageHeight, pageWidth, pageViewport)
    )

    const boundingRect = getBoundingRect(scaledRects);

    this.viewer.scrollPageIntoView({
      pageNumber: pageNr,
      destArray: [
        null,
        { name: "XYZ" },
        ...pageViewport.convertToPdfPoint(
          0,
          boundingRect.top -
          scrollMargin - 150
        ),
        0,
      ],
    });

    this.setState(
      {
        scrolledToHighlightId: highlight.annId,
      },
      () => this.renderHighlights()
    );

    // wait for scrolling to finish
    setTimeout(() => {
      this.viewer.container.addEventListener("scroll", this.onScroll);
    }, 100);
  };

  onDocumentReady = () => {
    const { scrollRef } = this.props;

    this.handleScaleValue();

    scrollRef(this.scrollTo);
  };


  onScroll = () => {
    const { onScrollChange } = this.props;
    onScrollChange();

    this.setState(
      {
        scrolledToHighlightId: EMPTY_ID,
      }
    );

    this.viewer.container.removeEventListener("scroll", this.onScroll);
  };

  toggleTextSelection(flag: boolean) {
    this.viewer.viewer.classList.toggle(
      "PdfHighlighter--disable-selection",
      flag
    );
  }

  handleScaleValue = (scale: any = 'auto') => {
    if (this.viewer) {
      if (typeof scale === 'string') {
        this.viewer.currentScaleValue = scale //"page-width";
      }
    }
  };


  debouncedScaleValue: () => void = debounce(this.handleScaleValue, 500);

  nextPage = () => {
    if (this.viewer.currentPageNumber + 1 <= this.state.numPages) {
      let value = this.viewer.currentPageNumber + 1;
      // if (isKeyPress || this.viewer.currentScaleValue === "page-fit") {
      this.viewer.currentPageNumber = value
      // }
      this.setState({
        pageNumber: value
      })
    }
  }

  prevPage = () => {
    if (this.viewer.currentPageNumber - 1 > 0) {
      let value = this.viewer.currentPageNumber - 1;
      this.viewer.currentPageNumber = value
      this.setState({
        pageNumber: value
      })
    }
  }
  isNumber = (n: any) => {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  renderCustomScrollbar = () => {
    //console.log(this.props)
    let contextHeight: any;
    contextHeight = document.getElementById('PdfHighlighter')?.clientHeight
    //console.log(contextHeight)
    let height = this.props.availableContentHeight + 78

    let orderedCoords: any[] = []
    let orderedColors: any[] = []
    let uniqueColors: any[] = []

    this.props.activeSearchTerms.forEach((term, i) => {
      if (uniqueColors.length === 0 || !uniqueColors.includes(term.color)) {
        uniqueColors = [...uniqueColors, term.color]
      }
    })

    uniqueColors.forEach((col, i) => {
      this.props.activeSearchTerms.forEach((term) => {
        if (term.color === col) {
          orderedColors = [...orderedColors, { queryId: term.id, colIndex: i }]
        }
      })
    })

    /* this.props.activeSearchTerms.forEach((term, i) => {
         orderedColors = [...orderedColors, { queryId: term.id, colIndex: i }]
     })*/

    this.props.annotationCoords.forEach(coord => {
      //console.log(coord)
      orderedColors.forEach((col) => {
        if ((coord.queryId === col.queryId) && !orderedCoords.some(el => el.id === coord.id) || coord.element === 'section') {
          coord.colIndex = col.colIndex
          orderedCoords = [...orderedCoords, coord]
        }
      })
    })

    orderedCoords = orderedCoords.sort((a, b) => a.top - b.top);

    /*orderedCoords = orderedCoords.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.top === value.top && t.colIndex === value.colIndex && t.element === value.element
        ))
    )*/

    //console.log(orderedCoords)

    let sectionCoords: any[] = []
    orderedCoords.forEach(coord => {
      if (coord.element === 'entity') {
        let label = this.props.activeSearchTerms.find(term => term.id === coord.queryId).label
        //console.log(this.props.activeSearchTerms)
        //console.log(coord.queryId)
        coord.label = label
      }
      if (coord.element === 'section' && !coord.sectionInfo.includes("applicant") && sectionCoords.filter(co => co.top === coord.top).length === 0) {
        sectionCoords = [...sectionCoords, coord]
      }
    })

    let orderedWithoutDuplicates: any[] = []

    orderedCoords.forEach(coord => {
      if ((coord.element === 'section' && !orderedWithoutDuplicates.some(co => co.element === 'section' && co.top === coord.top)) || (coord.element !== 'section' && !orderedWithoutDuplicates.some(co => co.top === coord.top && co.color === coord.color))) {
        orderedWithoutDuplicates = [...orderedWithoutDuplicates, coord]
      } else if ((coord.element !== 'section' && orderedWithoutDuplicates.some(co => co.top === coord.top && co.color === coord.color))) {
        let index = orderedWithoutDuplicates.findIndex(co => co.top === coord.top && co.color === coord.color)
        if (!orderedWithoutDuplicates[index]?.label?.includes(coord.label))
          orderedWithoutDuplicates[index].label += `, ${coord.label}`
      }
    })

    sectionCoords.forEach((sec, i) => {
      if (i < sectionCoords.length - 1) {
        sec.height = ((sectionCoords[i + 1].top - sec.top) / (contextHeight / (height - 6))) - 3
        sec.counter = i
        sec.calcTop = (sec.top - 19) / (contextHeight / (height - 6))
        if (i > 0) {
          if (sectionCoords[i - 1] !== undefined) {
            sec.distance = sec.calcTop - sectionCoords[i - 1].calcTop
          }
        }
      } else {
        sec.height = ((contextHeight - sec.top) / (contextHeight / (height)))
        sec.counter = i
        sec.calcTop = (sec.top - 19) / (contextHeight / (height - 6))
        if (sectionCoords[i - 1] !== undefined) {
          sec.distance = sec.calcTop - sectionCoords[i - 1].calcTop
        }
      }
    })

    sectionCoords.filter(sec => sec.top > 0 && sec.height > 0).forEach((sec, i) => {
      if (sec.height < 0) {
        sec.height = 1
      }
      if (sec.distance && sec.distance < 16 && i > 0) {
        sec.margin = 2
      }
      if (i > 1) {
        if (sectionCoords[i - 1].distance && sectionCoords[i - 1].distance < 16 && sec.distance && sec.distance < 16) {
          sec.margin = 10
        }
      }
      if (i > 2) {
        if (sectionCoords[i - 2].distance && sectionCoords[i - 2].distance < 16 && sectionCoords[i - 1].distance && sectionCoords[i - 1].distance < 16 && sec.distance && sec.distance < 16) {
          sec.margin = 15
        }
      }
    })

    //console.log(_.uniqBy(this.props.activeSearchTerms, 'color').length)

    return (this.state.allImportantArePresent && this.props.activeSearchTerms.length > 0) ? <div className="customBar" id='customBar' style={{
      height: this.props.availableContentHeight ? height : '100%',
      width: `calc(11 * ${uniqueColors.length}px)`,
      //width: `calc(11 * ${this.props.activeSearchTerms.length}px)`,
      border: '1px solid #e7e7e7', backgroundColor: '#f4f4f4', position: 'fixed', right: 0,
      marginRight: 46, marginTop: 15
    }}>
      {orderedWithoutDuplicates && orderedWithoutDuplicates.length > 0 && orderedWithoutDuplicates.map((coord, i) => {
        if ((coord.element === "section" && !coord.sectionInfo.includes("applicant") && coord.top > 0 && coord.height > 0) || coord.element === "entity") {
          return <div id={coord.element === "entity" ? `${coord.queryId}_${coord.top}` : coord.sectionInfo}
            className={coord.element === "entity" ? "customBarContent" : "customBarContentBar"}
            style={{
              marginLeft: coord.element === "entity" ? `calc(${coord.colIndex * 11}px)` : - 8,
              width: coord.element === "entity" ? 8 : 3,
              height: coord.element === "entity" ? 3 : coord.height ? coord.height : 1,
              backgroundColor: coord.element === "entity" ? coord.color : '#757575',
              position: 'absolute',
              top: `calc(${(coord.top - 19) / (contextHeight / (height - 6))}px)`,
              cursor: coord.element === "entity" ? 'pointer' : 'pointer'
            }}
            onClick={() => { coord.element === "entity" ? this.showSectionInfo(coord.id) : this.showSectionInfo(coord.sectionInfo) }}
            title={coord.element === "entity" && coord.label ? coord.label : ''}>
            {coord.element === "section" ?
              !coord.sectionInfo.includes("applicant") && coord.top > 0 && coord.height > 0 ?
                <label id={coord.sectionInfo} title={(coord.sectionInfo.includes('biblio') ? 'Title' : coord.sectionInfo === 'ocTextTitle' ? 'Title' : coord.sectionInfo === 'ocTextAbstract' ? 'Abstract' :
                  coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1)[0].toUpperCase() +
                  (coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1).slice(1))).split(' ', 1)[0]} className="hideLabel"
                  onClick={() => { this.showSectionInfo(coord.sectionInfo) }}
                  style={{
                    zIndex: 1000,
                    maxWidth: 80, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                    marginLeft: -85, marginTop: coord.margin ? coord.margin : -3, color: '#0973c0', fontWeight: 500,
                    position: 'absolute',
                    cursor: 'pointer'
                  }}>{(coord.sectionInfo.includes('biblio') ? 'Title' : coord.sectionInfo === 'ocTextTitle' ? 'Title' : coord.sectionInfo === 'ocTextAbstract' ? 'Abstract' : coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1)[0].toUpperCase() + (coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1).slice(1))).split(' ', 1)[0]}</label>
                : null
              : null
            }
          </div>
        }
      })
      }
      <div className="hideSection" style={{
        border: 'none',
        position: 'fixed',
        fontSize: 12, marginTop: 0, marginLeft: -101, height: this.props.availableContentHeight ? height : '100%',
        backgroundImage: "linear-gradient(to left, #fff 47%, rgba(255, 255, 255, 0.85) 0%)", width: 90
      }}>
      </div>
    </div>
      : null

    /*this.props.activeSearchTerms.forEach((term, i) => {
      orderedColors = [...orderedColors, { queryId: term.id, colIndex: i }]
    })

    this.props.annotationCoords.forEach(coord => {
      //console.log(coord)
      orderedColors.forEach((col) => {
        if ((coord.queryId === col.queryId) && !orderedCoords.some(el => el.id === coord.id) || coord.element === 'section') {
          coord.colIndex = col.colIndex
          orderedCoords = [...orderedCoords, coord]
        }
      })
    })
    orderedCoords = orderedCoords.sort((a, b) => a.top - b.top);

    orderedCoords = orderedCoords.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.top === value.top && t.colIndex === value.colIndex && t.element === value.element
      ))
    )

    let sectionCoords: any[] = []
    orderedCoords.forEach(coord => {
      if (coord.element === 'section') {
        sectionCoords = [...sectionCoords, coord]
      }
    })

    sectionCoords.forEach((sec, i) => {
      if (i < sectionCoords.length - 1) {
        sec.height = ((sectionCoords[i + 1].top - sec.top) / (contextHeight / (height - 6))) - 3
      } else {
        sec.height = ((contextHeight - sec.top) / (contextHeight / (height)))
      }
    })

    //console.log(orderedCoords)

    return <div className="customBar" id='customBar' style={{
      width: `calc(11 * ${this.props.activeSearchTerms.length}px)`, border: '1px solid #e7e7e7', backgroundColor: '#f4f4f4', position: 'fixed', right: 0,
      marginRight: 42, marginTop: 15, height: this.props.availableContentHeight ? height : '100%'
    }}>
      {orderedCoords && orderedCoords.length > 0 && orderedCoords.map((coord, i) => {
        return <div className="customBarContent" style={{
          marginLeft: coord.element === "entity" ? `calc(${coord.colIndex * 11}px)` : - 8,
          width: coord.element === "entity" ? 8 : 3,
          height: coord.element === "entity" ? 3 : coord.height ? coord.height : 1,
          backgroundColor: coord.element === "entity" ? coord.color : 'black',
          position: 'absolute',
          top: `calc(${(coord.top - 19) / (contextHeight / (height - 6))}px)`,
          cursor: coord.element === "entity" ? 'default' : 'pointer'
        }} onClick={() => { this.showSectionInfo(coord.sectionInfo) }}>
          {coord.element === "section" ?
            <div className="hideSection" style={{
              border: '1px solid #0973c0',
              fontSize: 12, marginTop: 0, marginLeft: -80, height: coord.height < 20 ? 20 : coord.height,
              backgroundImage: "linear-gradient(to left, #fff 47%, rgba(255, 255, 255, 0.85) 0%)"
            }}>
              <label onClick={() => { this.showSectionInfo(coord.sectionInfo) }} style={{
                marginLeft: 5, marginTop: 5, color: '#0973c0', fontWeight: 'bold',
                cursor: 'pointer'
              }}>{(coord.sectionInfo === 'ocTextTitle' ? 'Title' : coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1)[0].toUpperCase() + (coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1).slice(1))).split(' ', 1)[0]}</label></div>
            : null
          }
        </div>
      })

      }
    </div>
    */
  }

  showSectionInfo = (info: any) => {
    console.log(info)
  }

  render() {
    return (
      <div >
        <ToolbarContainer viewer={this.viewer} numPages={this.state.numPages} nextPage={this.nextPage} prevPage={this.prevPage} pageNumber={this.state.pageNumber} />
        <div
          ref={this.attachRef}
          className="PdfHighlighter"
          id="PdfHighlighter"
          onContextMenu={(e) => e.preventDefault()}
        >
          <div
            style={{ marginRight: 10 }}
            // onClick={this.printMousePos}
            className="pdfViewer" />
          {this.renderTip()}
        </div>
        {this.props.renderDocumentOnly ?
          //this.renderCustomScrollbar()
          null
          : null}
      </div>
    );
  }
}
