import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import FilterEntry from "./FilterEntry";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";


class FilterSectionFacetsWithValues extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }

    showMore = (filter) => {
        this.props.onLoadMoreFacets(filter);
    }

    includesFacet = (selectedFacets, entry) => {
        const contains = selectedFacets?.some(selEntry => entry.term === selEntry.term); //  && facet.occurrence === entry.occurrence
        return contains;
    }

    /*
    removeDuplicateFacets = (facets, selectedFacets) => {
        const filterEntriesTerms = {};
        facets?.forEach(entry => { filterEntriesTerms[entry.term] = true });
        return [...selectedFacets].filter(selEntry => { return !filterEntriesTerms[selEntry.term] });
    }
    */

    render() {

        const { filter, filterID, placeholder, facetEntries, selectedFacets, input, onSelectionChange,
            onInputChange, onAddSelectedFacet, includeInputField, showOccurrences } = this.props;

        const manuallyAdded = selectedFacets ? selectedFacets.filter(selEntry => selEntry.isManuallyAdded) : [];
        const facetsAdded = selectedFacets ? selectedFacets.filter(selEntry => !selEntry.isManuallyAdded) : [];
        
        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                {facetEntries ?
                    <div key={filterID}>
                        <ul className="noStyleList"
                            style={{ lineHeight: 1.8, width: '100%', marginTop: 0, marginBottom: 5 }}>

                            {manuallyAdded.map(addSelEntry => {
                                //console.log('addSelEntry: ', addSelEntry);
                                return <li key={addSelEntry}
                                    style={{ display: 'flex' }}>
                                    <FilterEntry
                                        filterID={filterID}
                                        filterEntry={addSelEntry}
                                        isSelected={this.includesFacet(manuallyAdded, addSelEntry)}
                                        onSelectionChange={(e, filterID) => {
                                            e.value.isManuallyAdded = true;
                                            onSelectionChange(e, filterID);
                                        }}
                                        isManuallyAdded={true}
                                        showOccurrences={showOccurrences}
                                    />
                                </li>
                            })}

                            {facetEntries.facets?.map(filterEntry => {
                                return <li key={filterEntry.term}
                                    style={{ display: 'flex' }}>
                                    <FilterEntry
                                        filterID={filterID}
                                        filterEntry={filterEntry}
                                        isSelected={this.includesFacet(facetsAdded, filterEntry)}
                                        onSelectionChange={onSelectionChange}
                                        showOccurrences={showOccurrences}
                                    />
                                </li>
                            })}
                        </ul>
                        <div className="grid">
                            {facetEntries?.hasMoreValues ?
                                <div className="col-12">
                                    <Button
                                        className="p-button-text primaryButtonAsLink valignMiddle"
                                        style={{ marginLeft: 30, marginBottom: 0 }}
                                        title="Show more"
                                        //onClick={() => this.setState({ displayDialog: true })}
                                        onClick={() => this.showMore(filter)}
                                    >
                                        More
                                </Button>
                                </div> : null
                            }
                            {includeInputField ?
                                <>
                                    <div className="col valignMiddle">
                                        <InputText
                                            value={input}
                                            onChange={(e) => {
                                                const filterObject = e.target.value ? {
                                                    filterID: filterID,
                                                    selectedFilterEntries: selectedFacets,
                                                    input: e.target.value
                                                } : {};
                                                onInputChange(filterObject);
                                            }}
                                            placeholder={placeholder ? placeholder : `Type away`}
                                            style={{ width: '100%' }}>
                                        </InputText>
                                    </div>
                                    <div className="col-fixed valignMiddle">
                                        <Button className="p-button-secondary p-button-sm"
                                            label="Select"
                                            disabled={!input}
                                            onClick={(e) => {
                                                const filterObject = input ? {
                                                    filterID: filterID,
                                                    selectedFilterEntries: selectedFacets,
                                                    input: input
                                                } : {};
                                                onAddSelectedFacet(filterObject);
                                            }} />
                                    </div>
                                </> : null
                            }
                        </div>
                    </div>
                    : null
                }
            </>
        );
    }
}

export default FilterSectionFacetsWithValues;