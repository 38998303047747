import {
    fetchSortFields
} from '../../../../redux/actions/WebApiActions'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import WatchlistsAndQueries from '../WatchlistsAndQueries'



const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    userData: state.user.data,
    sortFields: state.webAPI.sortFields
})

const mapDispatchToProps = dispatch => ({
    fetchSortFields: (history, search, showFailGrowl) => dispatch(fetchSortFields(history, search, showFailGrowl))
})


const WatchlistsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(WatchlistsAndQueries))

export default WatchlistsContainer