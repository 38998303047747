/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DataView } from 'primereact/dataview';
import { Panel } from 'primereact/panel';
import { addThousandsSeparatorToNumber, createDocViewUrl, hasUserRole } from '../util';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import DocumentPartsDialog from '../docparts/DocumentPartsDialog';
import {
    PATENT_REPOS, CLINICAL_TRIAL_REPOS, REPOSITORY_INFO_NOT_AVAILABLE,
    GRANT_REPOS, GRANT_DS_REPOS, PUBLICATIONS_REPOS, MEETING_ABSTRACTS_REPOS, QUERY_TYPE_QUICK_SEARCH,
    getPatentFamilyDataByPrefix
} from '../../../properties';
import DocumentMetadataInfo from '../docparts/DocumentMetadataInfo';
import { APP_PROPERTIES } from '../../../properties/index';
import { exportPDFDocumentsView } from '../../../api/content/DocumentApi';
import { checkResultAndGetPayload } from '../../../api';
import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplateWithoutLastPage from '../../common/CustomPaginator/CustomPaginatorTemplateWithoutLastPage'
import DocumentTitle from '../docparts/DocumentTitle';
import DocumentAdditionalData from './DocumentAdditionalData';
import SeparatorPoint from '../general/SeparatorPoint';


class DocumentResultList extends Component {

    constructor() {
        super();
        this.state = {
            docsAdditionalInfo: {},
            docPartsInfo: {},
            layout: 'list',
            loading: true,
            first: 0,
            rows: 6,
            totalRecords: 0
        };

        //this.itemTemplate = this.itemTemplate.bind(this);
        this.onPage = this.onPage.bind(this);
    }

    componentDidMount() {

        if (this.props.documents && this.props.documents.documents) {
            this.embedBatches();
        }
    }


    componentDidUpdate = (prevProps) => {

        //console.log('prevProps: ', prevProps);
        //console.log('this.props: ', this.props);

        if (prevProps.documents && prevProps.documents.documents &&
            this.props.documents && this.props.documents.documents &&
            prevProps.documents.documents !== this.props.documents.documents) {
            //console.log('update batches');
            this.embedBatches();
        }

        //console.log('update batches');
        //this.embedBatches();
    }

    embedBatches = () => {
        if (APP_PROPERTIES.INCLUDE_BATCHES) {
            if (!!window._altmetric_embed_init && !!window.__dimensions_embed && !!window.__dimensions_embed.addBadges) {
                window._altmetric_embed_init();
                window.__dimensions_embed.addBadges();
            }
        }
    }

    showDocument = (rowData) => {
        // --- store query in local storage to show matches --- //
        createDocViewUrl(rowData.repositoryId, rowData.ocDocId,
            { query: this.props.documents.internalQuery, sequenceID: this.props.selectedSequenceID });
        // console.log(link)
        this.props.onShowDocviewModal(rowData.repositoryId, rowData.ocDocId, rowData)
        // window.open(link, '_blank');
    }

    openPDFDocument = async (rowData) => {
        this.setState({ openingPDF: true });
        const response = await exportPDFDocumentsView(rowData.repositoryId, rowData.ocDocId, this.props.documents.internalQuery, true);
        const url = checkResultAndGetPayload(response, this.growl);
        if (url) {
            window.open(url, '_blank');
        }
        this.setState({ openingPDF: false });
    }

    onPage = (event, isPaginator) => {
        //console.log(event)
        this.setState({
            loading: true
        });

        this.props.onPageChange(isPaginator ? event.first : this.props.first, isPaginator ? event.rows : event.value);
    }


    // ----------------------------------------------------------------------- //
    // --- document parts preview -------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Loads requested document part in the preview window.
     */
    onLoadDocumentPart = async (ocDocID, docPart, allDocParts, activeDocParts, docData) => {

        //console.log('allDocParts: ', allDocParts);
        //console.log('activeDocParts: ', activeDocParts);

        const docParts = [];
        if (activeDocParts && allDocParts) {
            for (var dp of Object.keys(activeDocParts)) {
                if (!!allDocParts[dp]) {
                    docParts.push(dp);
                }
            }
        }

        // const organisms = this.getOrganisms(parseInt(ocDocID));
        const organisms = this.getOrganisms(ocDocID);

        this.setState({
            docPartVisible: true,
            docPart: docPart,
            docParts: docParts,
            docPartDocID: ocDocID,
            docData: docData,
            docPartInternalQuery: this.props.internalQuery,
            organisms: organisms
        });
    }


    onAddToCollections = (collections) => {

        this.props.onAddToCollections(collections, this.state.docData);
    }

    onRemoveFromCollections = (collections) => {

        this.props.onRemoveFromCollections(collections, this.state.docData);
    }

    getOrganisms = (ocDocID) => {

        const organisms = {};
        if (this.props.addOrganisms) {
            for (var info of this.props.patentInfo) {
                if (!!info.patentSeqs && info.patDocId === ocDocID) {
                    info.patentSeqs.forEach(seq => {
                        organisms[seq.organism] = true;
                    })
                }
            }
        }
        return organisms;
    }

    // ----------------------------------------------------------------------- //
    // --- templates, rendering etc. ----------------------------------------- //
    // ----------------------------------------------------------------------- //
    renderListItem(rowData) {

        const { documents, docsAdditionalInfo, starQuery } = this.props;
        const groupByFamilyLabel = getPatentFamilyDataByPrefix(documents?.groupByField)?.label || 'Family';

        // const organisms = this.getOrganisms(parseInt(rowData.ocDocId));
        const organisms = this.getOrganisms(rowData.ocDocId);

        //console.log('rowdata: ', rowData);

        return (
            <div key={rowData.ocDocId} className="col-12">
                <div style={{ paddingTop: 10, marginLeft: -5 }}>
                    <div>
                        <div className="grid" style={{ lineHeight: 1.5 }}>
                            {hasUserRole('ROLE_LIBRARY') &&
                                <div className="col-fixed" style={{ width: 35 }}>
                                    <Checkbox
                                        id={`checkbox_${rowData.ocDocId}`}
                                        onChange={e => this.props.onToggleDocumentSelection(rowData)}
                                        checked={this.props.selectedDocuments && !!this.props.selectedDocuments[rowData.ocDocId]}
                                        //disabled={this.props.repositoryInfo?.name?.startsWith("rc_") && !(hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer)}
                                    >
                                    </Checkbox>
                                </div>
                            }
                            <div id={`doc_${rowData.ocDocId}`} className="col" >

                                <DocumentTitle
                                    document={rowData}
                                    onOpenDocument={this.showDocument}
                                    onOpenPDFDocument={this.openPDFDocument}
                                />

                                <DocumentMetadataInfo
                                    repositoryInfo={this.props.repositoryInfo}
                                    documentData={rowData}
                                    organisms={organisms}
                                />

                                {!!rowData.queryAbstract && !starQuery ?
                                    <div style={{ color: '#757575', marginTop: 5, wordBreak: 'break-word' }} title="Snippet"
                                        dangerouslySetInnerHTML={{ __html: `${rowData.queryAbstract}` }} />
                                    : !!rowData.queryAbstract && starQuery ?
                                        <div style={{ color: '#757575', marginTop: 5, wordBreak: 'break-word' }} title="Snippet"
                                        >{`${rowData.queryAbstract.replace(/<b>/g, "").replace(/<\/b>/g, "")}`}</div> : null
                                }

                                <DocumentAdditionalData
                                    documentData={rowData}
                                    additionalDocumentInfo={docsAdditionalInfo}
                                    activeDocumentParts={this.props.activeDocParts}
                                    documentPartsInfo={this.props.docPartsInfo}
                                    onLoadDocumentPart={this.onLoadDocumentPart}
                                    patentFamilyLabel={groupByFamilyLabel}
                                />

                            </div>
                            {/* <div className="col-fixed" style={{ width: 'auto', background: 'yellow', textAlign: 'right' }}></div> */}
                            <div className="breakRow" />
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    renderGridItem(item) {
        return (
            <div style={{ padding: '.5em' }} className="col-12 md:col-4">
                <Panel header={item.title} style={{ textAlign: 'center' }}>
                    <div className="car-detail">{item.title} - {item.title}</div>
                </Panel>
            </div>
        );
    }

    renderHeader() {

        const { documents, repositoryInfo, sortFields, sortCriteria, showGroupByPatentFamily, groupByPatentFamily,
            isPatentFamilyMutliple, onPatentFamilyGroupingChange, patentFamilyGrouping, patentFamilyGroupingValues } = this.props;
        const hitCount = documents?.documentCount ? documents.documentCount : 0;
        const groupedDocCount = documents?.groupedDocCount ? documents.groupedDocCount : 0;

        const isPatentFamilyActive = showGroupByPatentFamily && PATENT_REPOS[repositoryInfo.name];

        // --- check if all visibble documents are selected --- //
        let allSelected = true;
        if (documents && documents.documents) {
            for (var doc of documents.documents) {
                if (!this.props.selectedDocuments || !this.props.selectedDocuments[doc.ocDocId]) {
                    allSelected = false;
                    break;
                }
            }
        }

        let resultViewInfo = 'Title · Metadata · Snippet';
        resultViewInfo = PATENT_REPOS[repositoryInfo.name] ? 'Title · Assignee · Inventor · Patent office · Priority claim date · Snippet' : resultViewInfo;
        resultViewInfo = CLINICAL_TRIAL_REPOS[repositoryInfo.name] ? 'Title · Source · Start date · Phase · Status · Sponsor · Snippet' : resultViewInfo;
        resultViewInfo = GRANT_REPOS[repositoryInfo.name] || GRANT_DS_REPOS[repositoryInfo.name] ? 'Title ·​ Funder ·​ Investigator ·​ Funding ·​ Snippet' : resultViewInfo;
        resultViewInfo = PUBLICATIONS_REPOS[repositoryInfo.name] ? 'Title · Author · Publication year · Bibliographic reference · Snippet' : resultViewInfo;
        resultViewInfo = MEETING_ABSTRACTS_REPOS[repositoryInfo.name] ? 'Title · Author · Publication year · Bibliographic reference · Snippet' : resultViewInfo;
        //resultViewInfo = DRUG_LABELS_REPO[repositoryInfo.name] ? 'Title · Author · Publication year · Bibliographic reference · DOI · Snippet' : resultViewInfo;
        resultViewInfo = repositoryInfo.name === 'dsm_labdocs' ? 'Title · Laboratory head · Technician · Original file path · Snippet' : resultViewInfo;

        const showFamilies = isPatentFamilyActive && (groupByPatentFamily || (isPatentFamilyMutliple && patentFamilyGrouping && patentFamilyGrouping.filterValue !== 'none'));

        return (
            <div className="grid" style={{ padding: 0 }}>
                <div className="col-fixed textAlignLeft" style={{ padding: 0, paddingTop: 10 }}>
                    <div className="secondaryInfo" style={{ display: 'inline' }}>
                        {showFamilies ?
                            <span>
                                {addThousandsSeparatorToNumber(hitCount)} {hitCount === 1 ? 'family' : 'families'}
                                <SeparatorPoint />
                                {addThousandsSeparatorToNumber(groupedDocCount)} {groupedDocCount === 1 ? 'document' : 'documents'}
                            </span>
                            :
                            <span>{addThousandsSeparatorToNumber(hitCount)} {hitCount === 1 ? 'document' : 'documents'}</span>
                        }
                        {hasUserRole('ROLE_SENTENCEANALYSIS') && this.props.withSentenceAnalyzer &&
                            <>
                                <span style={{ marginLeft: 10 }}>&#183;</span>
                                <a id="docSearchExport"
                                    onClick={(e) => this.props.onAnalyze(QUERY_TYPE_QUICK_SEARCH.label, documents, repositoryInfo)}
                                    style={{ display: 'inline-block', marginLeft: 10 }}>Analyze (max. 1,500)</a>
                            </>
                        }
                    </div>
                </div>
                <div className="col textAlignRight" style={{ padding: 0 }}>
                    <div style={{ marginLeft: 20, display: 'inline-block', textAlign: 'left', position: 'relative', top: 3 }}>
                        {!isPatentFamilyMutliple && isPatentFamilyActive ?
                            <>
                                <Checkbox
                                    id="groupByPatFam"
                                    className="valignMiddle"
                                    value={groupByPatentFamily}
                                    onChange={(e) => this.props.onGroupByPatentFamilyChange(e.checked)}
                                    checked={groupByPatentFamily}
                                >
                                </Checkbox>
                                <label htmlFor="groupByPatFam"
                                    className="p-checkbox-label valignMiddle"
                                    title="Group result documents by patent family"
                                    style={{ marginRight: 30 }}>
                                    Group by family
                                </label>
                            </> : null}

                        {isPatentFamilyMutliple && isPatentFamilyActive ?
                            <>
                                <label htmlFor="groupByPatFam"
                                    className="valignMiddle"
                                    title="Group result documents by patent family">
                                    Group by:
                                </label>
                                <Dropdown
                                    id="groupByPatFam"
                                    className="dropdownNoBorder" //withClearIcon
                                    options={patentFamilyGroupingValues}
                                    optionLabel="filterValueLabel"
                                    value={patentFamilyGrouping}
                                    placeholder="None"
                                    onChange={(e) => onPatentFamilyGroupingChange(e.value)}
                                    style={{ marginLeft: 10, marginRight: 30 }}
                                //showClear={true}
                                />
                            </> : null
                        }


                        <label className="valignMiddle" htmlFor="sortBy">Sort by:</label>
                        <Dropdown
                            id="sortBy"
                            className="dropdownNoBorder"
                            options={sortFields}
                            value={sortCriteria?.value}
                            placeholder="Sort By"
                            onChange={(e) => this.props.onSortFieldChange(e.value, true)}
                            style={{ marginLeft: 10 }}
                        />
                        <Dropdown
                            className="dropdownNoBorder"
                            options={sortCriteria?.sortModes}
                            value={sortCriteria?.sortMode}
                            disabled={sortCriteria?.disableSortModes}
                            onChange={(e) => this.props.onSortModeChange(e.value, true)}
                            autowidth="true"
                            placeholder="Order"
                            style={{ marginLeft: 0 }} // minWidth: 110, 
                        />
                        <div className="valignMiddle" style={{ marginLeft: 10 }}>
                            <a id="docSearchExport"
                                onClick={(e) => this.props.onSemanticExport()}
                                style={{ display: 'inline-block', marginLeft: 10 }}
                            //title="Export limited to first 200 entries"
                            >
                                Export
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12" style={{ padding: '5px 0' }}><hr style={{ opacity: 0.3, margin: 0, marginBottom: '8px' }} /></div>
                {documents && documents.documents && hitCount > 0 ?
                    <div className="col-12 textAlignLeft" style={{ padding: 0, marginLeft: -5 }}>
                        {hasUserRole('ROLE_LIBRARY') &&
                            <Checkbox
                                id="selectDocsOnPage"
                                title="Select documents on page"
                                style={{ marginRight: 15 }}
                                onChange={(e) => this.props.onToggleAllDocumentSelectionsOnPage(e.checked)}
                                checked={allSelected}
                                //disabled={this.props.repositoryInfo?.name?.startsWith("rc_") && !(hasUserRole("ROLE_SENTENCEANALYSIS") && this.props.withSentenceAnalyzer)}
                            >
                            </Checkbox>
                        }
                        <span className="secondaryInfo" style={{ padding: 0 }}>
                            {resultViewInfo}
                        </span>
                    </div> : null
                }
            </div>
            /*
                <div style={{ textAlign: 'left' }}>
                    <DataViewLayoutOptions layout={this.state.layout} onChange={onOptionChange} />
                </div>
                */
        );
    }


    render() {

        const { fetching, documents, docsAdditionalInfo, first, rows } = this.props;

        const hitCount = documents && documents.documentCount ? documents.documentCount : 0;
        const documentsList = documents && documents.documents ? documents.documents : [];
        const additionalDocumentInfo = docsAdditionalInfo && docsAdditionalInfo[this.state.docPartDocID] ?
            docsAdditionalInfo[this.state.docPartDocID] : null;
        const groupByFamilyLabel = getPatentFamilyDataByPrefix(documents?.groupByField)?.label || 'Family';

        const header = this.renderHeader()

        const itemTemplate = (item, layout) => {
            //console.log('***** item: ', item);
            if (!item) { return null; }
            if (layout === 'list') { return this.renderListItem(item); }
            else if (layout === 'grid') { return this.renderGridItem(item); }
            else { return this.renderListItem(item); }
        }

        return (
            <React.Fragment>
                <div style={{ marginTop: 10, paddingLeft: 2 }}>
                    <DataView
                        id="docResultsList"
                        value={documentsList}
                        layout={this.state.layout}
                        header={header}
                        //footer={footer}
                        itemTemplate={itemTemplate}
                        lazy={true}
                        paginator={true}
                        alwaysShowPaginator={false}
                        paginatorPosition={'bottom'}
                        paginatorTemplate={CustomPaginatorTemplateWithoutLastPage}
                        paginatorLeft={<CustomPaginatorLeftSide
                            first={first}
                            contentLength={hitCount}
                            rows={rows}>
                        </CustomPaginatorLeftSide>}
                        paginatorRight={<CustomPaginatorRightSide
                            rows={rows}
                            onChange={(e) => this.onPage(e, false)}
                        >
                        </CustomPaginatorRightSide>}
                        first={first}
                        rows={rows}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        totalRecords={hitCount}
                        onPage={(e) => this.onPage(e, true)}
                        loading={fetching || this.state.openingPDF} />
                </div>

                <DocumentPartsDialog
                    displayDialog={this.state.docPartVisible}
                    // included in docData!!!
                    repositoryInfo={this.props.repositoryInfo}
                    repository={this.props.repositoryInfo.id}
                    ocDocID={this.state.docPartDocID}
                    //
                    documentPart={this.state.docPart}
                    documentParts={this.state.docParts}
                    documentData={this.state.docData}
                    organisms={this.state.organisms}
                    additionalDocumentInfo={additionalDocumentInfo}
                    onChangeReadStatus={this.props.onChangeReadStatus}
                    onChangeRating={this.props.onChangeRating}

                    onOpenAddToCollectionsDialog={this.props.onOpenAddToCollectionsDialog}
                    onAddToCollections={this.onAddToCollections}
                    onRemoveFromCollections={this.onRemoveFromCollections}

                    //internalQuery={this.state.docPartInternalQuery}
                    internalQuery={this.props.documents ? this.props.documents.internalQuery : null}
                    sequenceID={this.props.selectedSequenceID}

                    //{query: this.props.documents.internalQuery, sequenceID: this.props.selectedSequenceID }
                    onHide={(e) => {
                        this.setState({
                            docPartVisible: false,
                            docData: null
                        })
                    }}
                    patentFamilyLabel={groupByFamilyLabel}
                //growl={this.props.growl}
                >
                </DocumentPartsDialog>
            </React.Fragment>
        );
    }

    // --- set default props --- //
    static defaultProps = {
        repositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE
    }
}

const mapStateToProps = state => ({
    userData: state.user.data
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentResultList))