import React from 'react';
import classNames from "classnames";
import styles from "./PageDivider.module.scss";

export const PageDivider = ({leftAside, rightAside, children}) => {

    const leftAsideStyle = classNames('col-6 sm:col-6 md:col-4 lg:col-4 xl:col-2', styles.leftAside);
    const rightAsideStyle = classNames('col-6 sm:col-6 md:col-8 lg:col-8 xl:col-10', styles.rightAside);

    return (
        <div className='grid col-12'>
            <div id='biomarker-left-aside' className={leftAsideStyle}>
                {leftAside}
            </div>

            <div className={rightAsideStyle}>
                {rightAside}
            </div>

            {children}
        </div>
    );
};
