import {
  SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL,
  SEARCH_FILTER_ID_SEARCH_MODE_LOCAL,
  SEARCH_FILTER_ID_TERMLOC,
} from "../../general/docsearch/searchConstants";

export const SEARCH_GROUP_TYPE_TERM = "term-group";
export const SEARCH_GROUP_TYPE_LOGIC = "logic-group";
export const SEARCH_GROUP_TYPE_FILTER = "filter-group";
export const SEARCH_GROUP_TYPE_SAVED_SEARCH = "saved-search-group";
export const SEARCH_GROUP_TYPE_API_SYNTAX = "api-syntax-group";

export const SEARCH_CRITERIA = {
  label: "Search criteria",
  filters: [
    SEARCH_FILTER_ID_TERMLOC,
    SEARCH_FILTER_ID_SEARCH_MODE_LOCAL,
    SEARCH_FILTER_ID_CONCEPT_DISTANCE_LOCAL,
  ],
};

export const LOGICAL_OPERATOR_AND = { label: "AND", value: "and" };
export const LOGICAL_OPERATOR_OR = { label: "OR", value: "or" };
export const LOGICAL_OPERATOR_NOT = { label: "NOT", value: "not" };

export const LOGICAL_OPERATORS_AND_OR = [
  LOGICAL_OPERATOR_AND,
  LOGICAL_OPERATOR_OR,
];
export const LOGICAL_OPERATORS_AND_OR_NOT = [
  LOGICAL_OPERATOR_AND,
  LOGICAL_OPERATOR_OR,
  LOGICAL_OPERATOR_NOT,
];

export const TERMS_BULK_IMPORT_FILE_TYPES = [".csv", ".txt"];

export const LIST_CUTOFF_QUERY_TERMS = 7;
export const LIST_CUTOFF_FILTER_TERMS = 5;

export const DEFAULT_REPO_NAME = "pmc";

export const MAX_NUM_OF_BACKUPS = 3;
