import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { SEARCH_FILTER_TYPE_DATE_RANGE } from "../../../../../../../../general/docsearch/searchConstants";
import IndexColumn from "../../../../../../../../../common/queryBuilder/common/Table/Column/IndexColumn";
import ButtonColumn from "../../../../../../../../../common/queryBuilder/common/Table/Column/ButtonColumn";
import ValueColumn from "./FilterTermsTableColumns/ValueColumn";


const FilterTermsTable = ({
  queryFilters,
  onFilterChange,
  onDelete
}) => {

  const [filterValueToEdit, setFilterValueToEdit] = useState(null);

  return (
    <DataTable
      id="filterTermsTable"
      value={queryFilters}
    >
      <Column
        field="index"
        header="#"
        body={(_data, cellInfo) => <IndexColumn index={cellInfo.rowIndex + 1} />}
        style={{ width: 25 }}
      />
      <Column
        field="filter"
        header="Filter"
        body={(data) => data.filter?.label || data.filterID}
      />
      <Column
        field="value"
        header="Value"
        body={(data) => {
          const isEditMode = filterValueToEdit?.id === data.id;
          const isDateRangeFilter = filterValueToEdit?.filter?.type === SEARCH_FILTER_TYPE_DATE_RANGE;
          return <ValueColumn
            data={data}
            filterValue={filterValueToEdit}
            onEdit={setFilterValueToEdit}
            onFilterEntryChange={onFilterChange}
            isEditMode={isEditMode}
            isDateRangeFilter={isDateRangeFilter}
          />
        }}
      />
      <Column
        field="edit"
        body={(data) => <ButtonColumn
          data={data}
          label="Edit"
          onClick={setFilterValueToEdit} />}
        style={{ width: 80 }}
      />
      <Column
        field="delete"
        body={(data) => <ButtonColumn
          data={data}
          label="Delete"
          onClick={onDelete} />}
        style={{ width: 80 }} />
    </DataTable>
  );
};

export default FilterTermsTable;