/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Element, Link } from 'react-scroll';
import CustomPaginatorRightSide from '../../../../common/CustomPaginator/CustomPaginatorRightSide';
import CustomPaginatorTemplateOpenEnd from '../../../../common/CustomPaginator/CustomPaginatorTemplateOpenEnd';
import CustomPaginatorTemplateWithoutLastPage from '../../../../common/CustomPaginator/CustomPaginatorTemplateWithoutLastPage';
import InfoOverlayWithIcon from '../../../general/InfoOverlayWithIcon';
import { addThousandsSeparatorToNumber, isArrayEmpty, roundNumberUpToNextLevel, shortenLargeNumber } from '../../../util';
import FingerprintHitsInfo from '../../infos/FingerprintHitsInfo';
import { getNumberOfResults } from '../../utils/chemistryResults';
import CompoundDataView from './CompoundDataView';


class CompoundResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleCompoundInfo: false,
            compound: {}
        };
    }

    onPage = (event) => {
        this.props.onPage(event.first, this.props.pageSize, event.page);
        document.getElementById(`scrollToBtn_compounds`)?.click();
    }

    onLoadMore = (rows, nextPageKey, e) => {
        this.props.onLoadMore(rows, nextPageKey, e);
        document.getElementById(`scrollToBtn_compounds`)?.click();
    }

    showCompoundInfo = (ocid) => {
        this.setState({
            displayCompoundInfo: true,
            conceptOcid: ocid
        })
    }


    render() {
        const { //mixturesIncluded
            result, concepts, resultComplete, nextPageKey, start, pageSize, sortByField, sortDirection, onPageSizeChange,
            inclExport, onExport, sortByFields, sortDirections, onSortByFieldChange, onSortDirectionChange, loading } = this.props;

        let totalRecords = concepts?.length;
        totalRecords += !!nextPageKey ? pageSize : 0;
        let pageLinkSize = resultComplete ? start === 0 ? 2 : 3 : 3;
        let paginatorTemplate = resultComplete ? CustomPaginatorTemplateWithoutLastPage : CustomPaginatorTemplateOpenEnd;

        const customPaginatorRight = <CustomPaginatorRightSide
            rows={pageSize}
            onChange={(e) => { onPageSizeChange(e.value); }}
            pageSizeOptions={[
                { label: `12 entries per page`, value: 12 },
                { label: `24 entries per page`, value: 24 },
                { label: `36 entries per page`, value: 36 },
                { label: `48 entries per page`, value: 48 },
            ]}
        >
        </CustomPaginatorRightSide>;

        const onPage = (e) => {
            if (resultComplete) {
                this.onPage(e);
            }
            else {
                this.onLoadMore(e.rows, nextPageKey, e);
            }
        }

        const { numberOfHits, isFPHitCount } = getNumberOfResults(result, start);
        let preText = isFPHitCount ? 'Found less than ' : 'Found ';
        let numOfHits = isFPHitCount
          ? shortenLargeNumber(roundNumberUpToNextLevel(numberOfHits))
          : addThousandsSeparatorToNumber(numberOfHits);
        let text = numberOfHits > 0 && result.numberTotalHitsInRequestedIndex ?
            `${preText}${numOfHits} ${numberOfHits === 1 ? 'match' : 'matches'} in this database with ${addThousandsSeparatorToNumber(result.numberTotalHitsInRequestedIndex)} total entries.` : 'No hits';

        return (
            <>
                {this.state.fetchingCompoundsData ?
                    <div></div>
                    :
                    <div className='grid'> {/*  className='grid grid-nogutter' */}
                        {result && !isArrayEmpty(concepts) ?
                            <>
                                <div className="col" style={{ paddingBottom: 0, margin: 'auto' }}>
                                    <div className='flex align-items-center gap-3'>
                                        {/*<span>{mixturesIncluded ? '' : 'No mixtures included in result'}</span>*/}
                                        <span>{text}</span>
                                        {isFPHitCount && <FingerprintHitsInfo />}
                                        <span style={{ marginLeft: 5, marginRight: 5 }}>{result.isTruncated ? 'Result is truncated.' : ''}</span>
                                    </div>
                                </div>
                                <div className='col-fixed textAlignRight' style={{ paddingBottom: 0 }}>
                                    {!isArrayEmpty(sortByFields) && !isArrayEmpty(sortDirections) ?
                                        <>
                                            <div className='valignMiddle'>Sort by:</div>
                                            <Dropdown
                                                className='valignMiddle'
                                                options={sortByFields}
                                                value={sortByField}
                                                placeholder="field"
                                                onChange={(e) => onSortByFieldChange(e.value)}
                                                style={{ textAlign: 'left', marginRight: 0 }} />
                                            <Dropdown
                                                className='valignMiddle'
                                                options={sortDirections}
                                                value={sortDirection}
                                                placeholder="direction"
                                                onChange={(e) => onSortDirectionChange(e.value)}
                                                style={{ textAlign: 'left', marginRight: 30 }} />
                                        </> : null
                                    }
                                    {inclExport ? <a style={{ marginRight: 15 }} onClick={e => onExport()}>Export</a> : null}
                                </div>
                            </> : null
                        }
                        <div className='col-12' style={{ marginBottom: 35, paddingTop: 0 }}>
                            {totalRecords === 0 ?
                                <span style={{ lineHeight: 2 }}>
                                    <b>Current search returned no hits.</b>
                                    {!resultComplete ?
                                        <div>
                                            Please note: It is possible that the maximum number of expected hits is greater than 0, but the atom-by-atom search returned no actual matches.
                                        </div> : null}
                                    {/*includeWithMixtures && !withMixtures ?
                                        <div style={{ marginTop: 20 }}>
                                            <UpdateSearchResultsInfo
                                                onClick={e => onWithMixturesChange(true)}
                                                text='By default mixtures will not be included in the results for non-exact searches. If you would like to search for mixtures as well, just check the "Include mixtures" box above or click here.'
                                            />
                                    </div> : null*/}
                                </span>
                                :
                                <Element name={`topElement_compounds`}>

                                    <CompoundDataView
                                        concepts={concepts}
                                        totalRecords={totalRecords}
                                        start={start}
                                        pageSize={pageSize}
                                        loading={loading}
                                        isComplete={resultComplete}
                                        onPage={onPage}
                                        pageLinkSize={pageLinkSize}
                                        paginatorTemplate={paginatorTemplate}
                                        paginatorLeft={<span></span>}
                                        paginatorRight={customPaginatorRight}
                                        patentsRepository={this.props.patentsRepository}
                                        publicationsRepository={this.props.publicationsRepository}
                                        onReplaceStructure={this.props.onReplaceStructure}
                                        onReplaceQuery={this.props.onReplaceQuery}
                                        includePreparationsSearch={this.props.includePreparationsSearch}
                                        includeAddToSearchFieldOption={this.props.includeAddToSearchFieldOption}
                                        preparationsTarget={this.props.preparationsTarget}
                                        onSelectCompound={this.props.onSelectCompound}
                                        selectedCompoundOcids={this.props.selectedCompoundOcids}
                                    />

                                    <Link id={`scrollToBtn_compounds`} to={`topElement_compounds`}
                                        spy={true} smooth={true} offset={-150} duration={100}>
                                    </Link>
                                </Element>
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({ repositories: state.user.data.userDetails.department?.selectedRepositories })
const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompoundResults))