import { isEmpty } from "lodash";
import { AutoComplete as PRAutoComplete } from "primereact/autocomplete";


const AutoComplete = ({
    id = 'autocomplete',
    suggestions,
    innerRef,
    onShow,
    ...props
}) => {

    // TODO: update PR version -> use pass-through
    const onShowSuggestions = () => {
        if (!isEmpty(suggestions)) {
            const element = document.getElementById(`${id}_list`);
            element?.firstChild?.classList?.add('p-highlight');
        }
        if (onShow) {
            onShow();
        }
    }

    return <PRAutoComplete
        {...props}
        id={id}
        suggestions={suggestions}
        onShow={onShowSuggestions}
        ref={innerRef}
    />
}

export default AutoComplete;