//import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
//import React, { useEffect, useRef } from "react";
import { useRef } from "react";
//import { ExportService } from './services/dataTableService';
import readcubeIcon from "../../../assets/images/icons/readcube.png";
import './ToastContent.css';
const ToastContent = ({
    selectedElements,
    onHide,
    onReload,
    onActivate,
    onDeactivate,
    onAddElements,
    onAddToReadcube,
    onCopyElements,
    onMoveUsers,
    onMoveElements,
    onMoveConcepts,
    onRemoveElements,
    onDelete,
    onExport,
    onAnalyze,
    onIntervalChange,
    onNextMailChange,
    onChangeSearchMode,
    onScrollBottom,
    onBlock,
    onAddToBlocklist,
    elementLabel,
    userDetails,
    activeOptions,
    canEdit,
    collectionID,
    foreignQuery,
    foreignQueryCount,
    entries
}) => {
    const customToast = useRef(null);

    return (
        <div className=" toast-container" >
            {selectedElements && (selectedElements.length > 0 || Object.keys(selectedElements).length > 0) &&
                <div>
                    {selectedElements && typeof selectedElements === 'array' ?
                        <div className={foreignQuery !== undefined && foreignQuery === true ? "toast-text-part-withShareInfo" : "toast-text-part"}>
                            {selectedElements?.length} {selectedElements?.length > 1 && elementLabel !== 'search' ? `${elementLabel}s  selected` : selectedElements?.length > 1 && elementLabel === 'search' ? `${elementLabel}es  selected` : `${elementLabel}  selected`}
                        </div>
                        :
                        <div className={foreignQuery !== undefined && foreignQuery === true ? "toast-text-part-withShareInfo" : "toast-text-part"}>
                            {Object.keys(selectedElements).length} {Object.keys(selectedElements).length > 1 && elementLabel !== 'search' ? `${elementLabel}s  selected` : selectedElements?.length > 1 && elementLabel === 'search' ? `${elementLabel}es  selected` : `${elementLabel}  selected`}
                        </div>
                    }
                    <div className="toast-text-part-shareInfo">
                        {foreignQuery !== undefined && foreignQuery === true && ` (${foreignQueryCount} shared with you)`}
                    </div>
                </div>
            }
            {/* <div className="toast-icons">
                <div>
                    <i className='pi pi-download'></i>
                </div>
                <div>Download </div>
            </div> */}
            {activeOptions && activeOptions.includes('activateUsers') &&
                <div className="toast-icons-material pointer" onClick={onActivate}>
                    <div>
                        <span className='material-icons'>{'person'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Activate</div>
                </div>
            }
            {activeOptions && activeOptions.includes('activateAlerts') &&
                <div className="toast-icons-material pointer" onClick={onActivate}>
                    <div>
                        <span className='material-icons'>{'notifications_active'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Activate</div>
                </div>
            }
            {activeOptions && activeOptions.includes('deactivateUsers') &&
                <div className="toast-icons-material pointer" onClick={onDeactivate}>
                    <div>
                        <span className='material-symbols-outlined'>{'person_off'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Deactivate</div>
                </div>
            }
            {activeOptions && activeOptions.includes('pauseAlerts') &&
                <div className="toast-icons-material pointer" onClick={onDeactivate}>
                    <div>
                        <span className='material-icons'>{'notifications_paused'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Pause</div>
                </div>
            }
            {activeOptions && activeOptions.includes('setInterval') &&
                <div className="toast-icons-material pointer" onClick={onIntervalChange}>
                    <div>
                        <span className='material-icons-outlined'>{'event_repeat'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Interval</div>
                </div>
            }
            {activeOptions && activeOptions.includes('setNextMail') &&
                <div className="toast-icons-material pointer" onClick={onNextMailChange}>
                    <div>
                        <span className='material-icons-outlined'>{'schedule_send'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Next mail</div>
                </div>
            }
            {(activeOptions && activeOptions.includes('addElements') && foreignQuery === undefined) ||
                (activeOptions && activeOptions.includes('addElements') && foreignQuery !== undefined && collectionID === undefined) ?
                <div className="toast-icons-material pointer" onClick={onAddElements}>
                    <div>
                        <span className='material-symbols-outlined'>{foreignQuery === undefined ? 'library_add' : 'add_box'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>{foreignQuery === undefined ? 'Add to library' : 'Add'}</div>
                </div>
                : null
            }
            {(activeOptions && activeOptions.includes('copyElements') && foreignQuery === undefined) ||
                (activeOptions && activeOptions.includes('copyElements') && foreignQuery !== undefined && collectionID !== undefined) ?
                <div className="toast-icons-material pointer" onClick={onCopyElements}>
                    <div>
                        <span className='material-symbols-outlined'>{'content_copy'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Copy</div>
                </div>
                : null
            }
            {(activeOptions && activeOptions.includes('moveElements') && canEdit && collectionID !== undefined && foreignQuery === undefined) ||
                (activeOptions && activeOptions.includes('moveElements') && foreignQuery !== undefined && collectionID !== undefined && !collectionID.sharedComment) ?
                <div className="toast-icons-material pointer" onClick={onMoveElements}>
                    <div>
                        <span className='material-symbols-outlined'>{'directions'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Move</div>
                </div>
                : null
            }
            {activeOptions && activeOptions.includes('moveConcepts') && ((collectionID !== undefined && collectionID.owner.id === userDetails.id) || (collectionID.owner.id !== userDetails.id && collectionID.writable === true)) ?
                <div className="toast-icons-material pointer" onClick={onMoveConcepts}>
                    <div>
                        <span className='material-symbols-outlined'>{'directions'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Move</div>
                </div>
                : null
            }
            {activeOptions && activeOptions.includes('moveUsers') && userDetails && userDetails.highestAdminType !== "ROLE_DEPARTMENT_ADMIN" &&
                <div className="toast-icons-material pointer" onClick={onMoveUsers}>
                    <div>
                        <span className='material-symbols-outlined'>{'directions'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Move</div>
                </div>
            }
            {activeOptions && activeOptions.includes('addToReadcube') ?
                <div className="toast-icons-material-readcube pointer" onClick={onAddToReadcube}>
                    <div>
                        <span>  <img src={readcubeIcon} alt="ReadCube papers"
                            style={{ height: 25 }} /> </span>
                    </div>
                    <div style={{ marginTop: 3 }}>Add to ReadCube</div>
                </div>
                : null
            }
            {activeOptions && activeOptions.includes('export') ?
                <div className="toast-icons-material pointer" onClick={onExport}>
                    <div>
                        <span className='material-symbols-outlined'>{'file_download'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Export</div>
                </div>
                : null
            }
            {activeOptions && activeOptions.includes('analyze') ?
                <div className="toast-icons-material pointer" onClick={onAnalyze}>
                    <div>
                        <span className='material-symbols-outlined'>{'manage_search'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Analyze</div>
                </div>
                : null
            }
            {activeOptions && activeOptions.includes('setSearchMode') && ((collectionID !== undefined && collectionID.owner.id === userDetails.id) || (collectionID !== undefined && collectionID.owner.id !== userDetails.id && collectionID.writable === true)) ?
                <div className="toast-icons-material pointer" onClick={onChangeSearchMode}>
                    <div>
                        <span className='material-symbols-outlined'>{'change_circle'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Mode</div>
                </div>
                : null
            }
            {activeOptions && activeOptions.includes('scrollBottom') &&
                <div className="toast-icons-material pointer" onClick={onScrollBottom} style={{}}>
                    <div>
                        <span className='material-symbols-outlined'>{'vertical_align_bottom'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Scroll down</div>
                </div>
            }
            {activeOptions && activeOptions.includes('blockConcept') &&
                <div className="toast-icons-material pointer" onClick={onBlock} style={{}}>
                    <div>
                        <span className='material-symbols-outlined'>{'block'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Block</div>
                </div>
            }
            {activeOptions && activeOptions.includes('addToBlocklist') &&
                <div className="toast-icons-material-addBlocklist pointer" onClick={onAddToBlocklist} style={{ marginRight: 20 }}>
                    <div>
                        <span className='material-symbols-outlined'>{'playlist_add'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Add to blocklists</div>
                </div>
            }
            {activeOptions && activeOptions.includes('deleteUsers') &&
                <div className="toast-icons-material pointer" onClick={onDelete} style={{}}>
                    <div>
                        <span className='material-symbols-outlined'>{'delete_forever'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Delete</div>
                </div>
            }
            {(activeOptions && activeOptions.includes('removeElements') && foreignQuery === undefined && ((collectionID !== undefined && collectionID.owner.id === userDetails.id) || (collectionID !== undefined && collectionID.owner.id !== userDetails.id && collectionID.editableForSharedUsers === true))) ||
                (activeOptions && activeOptions.includes('removeElements') && foreignQuery === undefined && collectionID === undefined) ||
                (activeOptions && activeOptions.includes('removeElements') && foreignQuery !== undefined && collectionID !== undefined && !collectionID.sharedComment) ?
                <div className="toast-icons-material pointer" onClick={onRemoveElements} style={{}}>
                    <div style={{}}>
                        <span className='material-symbols-outlined'>{'delete'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Remove</div>
                </div>
                : null
            }
            {activeOptions && activeOptions.includes('deleteElements') && ((canEdit && collectionID !== undefined) ||
                (foreignQuery !== undefined && foreignQuery === false) || (canEdit === undefined && foreignQuery === undefined))
                ?
                <div className="toast-icons-material pointer" onClick={onDelete} style={{}}>
                    <div>
                        <span className='material-symbols-outlined'>{'delete_forever'}</span>
                    </div>
                    <div style={{ marginTop: 3 }}>Delete</div>
                </div>
                : null
            }

            {entries?.map(entry => {
                return (
                    <div className="toast-icons-material pointer" onClick={entry.onClick} style={{}}>
                        <div>
                            <span className='material-symbols-outlined'>{entry.materialIcon}</span>
                        </div>
                        <div style={{ marginTop: 3 }}>{entry.label}</div>
                    </div>
                )
            })}

            <div className="toast-icons-close pointer" onClick={onHide} >
                <span className='material-symbols-outlined'>{'close'}</span>
            </div>
            <ConfirmDialog />
            <Toast ref={customToast} position="top-right" className="toast-custom" />

        </div>
    );
}

export default ToastContent