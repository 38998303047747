import _ from "lodash";
import { InputTextarea } from "primereact/inputtextarea";



const BulkImportInput = ({
    inputText,
    onChangeInputText
}) => {

    return <>
        <InputTextarea
            autoResize={true}
            className="bulk-import-input-field"
            value={inputText}
            style={{ minHeight: '100%', maxHeight: '100%', width: '100%' }}
            onChange={onChangeInputText}
        />
    </>
}

export default BulkImportInput;