import './ChemistrySearch.css';
import _ from "lodash";
import { Component, createRef } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import ChemistryHeaderBlock from "./header/ChemistryHeaderBlock";
import ChemistrySearchFormBlock from "./searchform/ChemistrySearchFormBlock";
import OntologyBrowserDialog from "../ontbrowser/OntologyBrowserDialog";
import ConfirmationDialog from "../../common/ConfirmDialog/ConfirmationDialog";
import SaveSearchDialog from "../../common/SaveSearch/SaveSearchDialog";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import UpdateSearchResultsInfo from "../docsearch/infos/UpdateSearchResultsInfo";
import { isArrayEmpty, replaceValueInArray } from "../util";
import {
    CHEMISTRY_BULK_IMPORT_TYPES, CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT, CHEMISTRY_SEARCH_FILTER_ID_SEARCH_TYPE, ALLOWED_SAVED_CHEM_SEARCH_TYPES,
    DOC_COUNT_REPOSITORIES_PATENTS, DOC_COUNT_REPOSITORIES_PUBLICATIONS, INITIAL_CHEM_DB_SEARCH_RESULT, INITIAL_STATE, SEARCH_TYPE_FILTER_DUPLICATE,
    EDITOR_STRUCTURE_FORMAT, STRUCTURE_FORMAT_RXN, DEFAULT_ACTIVE_FILTER_INDICES, STRUCTURE_FORMAT_SMILES, STRUCTURE_FORMAT_MRV, STRUCTURE_FORMAT_MOL,
} from "./utils/chemistrySearchConstants";
import {
    SEARCH_RESULT_TYPE_COMPOUNDS, SEARCH_RESULT_TYPE_REACTION_COMPOUNDS, SEARCH_TARGET_COMPOUNDS, SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE,
    SEARCH_TARGET_PRODUCTS, SEARCH_RESULT_TYPE_REACTIONS, SEARCH_TYPE_EXACT, SEARCH_TYPE_SUBSTRUCTURE, SEARCH_TYPE_ALL, SEARCH_TYPE_SUBREACTION, CHEM_DB_ITEMS
} from "./utils/chemistryDBs";
import { readChemistryDBFilterMapping, readChemistryFilterDefinitions } from "./utils/readChemistryFilters";
import ChemistryFiltersBlock from "./filters/ChemistryFiltersBlock";
import { addChemistryBulkIDsToQueryTerms, determineAvailableTargetOptions, determineActiveSettings, addChemistryResult, determineDefaultTargetOption, createEmptySearchResult, changeEntrySelection, determineAvailableChemDBItems, determineStructureFormat, determineActiveIncludeMixtures, determineIncludeMixturesFilterActive, createChemDBKey, replaceQueryTermWithConcepts, addConceptsToQueryTerms } from "./utils/chemistrySearchUtil";
import { runAttributeSearch } from "./utils/runAttributeSearchUtil";
import { runStructureSearch } from "./utils/runStructureSearchUtil";
import { checkResultAndGetPayload } from "../../../api";
import ChemistryDBOverview from "./results/ChemistryDBOverview";
import { QUERY_CATEGORY_CHEM_SEARCH, QUERY_FORM_CHEM_ATTRIBUTE, QUERY_FORM_CHEM_STRUCTURE, QUERY_TYPE_CHEM_SEARCH, VERSION_2_0 } from "../../../properties";
import { exportCompoundsToSdfFile, exportCompoundsToSmiFile } from "../../../api/content/ChemistryApi";
import CompoundResults from "./results/compounds/CompoundResults";
import ReactionResults from "./results/reactions/ReactionResults";
import { storeQuery, updateQuery } from "../../../api/content/WatchlistApi";
import { determineRepositoryForDocCount } from "./results/chemistryResultsUtil";
import ChemistrySearchToast from "./results/ChemistrySearchToast";
import { convertSavedChemistrySearchToState, getChemistrySearchFromStorage } from "./util";
import { countQueryStructures, isChemClassInput, checkForIncorrectInput, isSearchEmpty, analyzeQuery } from "./utils/queryChecker";
import ChemistryExportDialog from "./export/ChemistryExportDialog";
import { determineAcitveExportOptions, EXPORT_FORMAT_SDF, EXPORT_OPTION_ID_ALL, EXPORT_OPTION_ID_MAX, EXPORT_OPTION_ID_SELECTED } from "./utils/export";
import { CHEM_DB_STATE_LOADING, addConceptsData, addImageData, addKeysToEntries, mergeWithPreviousResults, SORT_BY_FIELDS, SORT_DIRECTIONS } from "./utils/chemistryResults";
import { createShortcutDataItemForMultipleConcepts, executeQuickSearchShortcut } from "../util/shortcuts2";
import { getSearchTypeFromFilterValues } from "./utils/filtersUtil";
import { convertStructureToFormatV2, convertStructureToFormatWS, createStructureToFormatRequestV2 } from "../../../api/content/ChemistryApi";
import AmbiguousInputDialog from "./results/inputerrors/AmbiguousInputDialog";
import IncorrectInputDialog from "./results/inputerrors/IncorrectInputDialog";
import { ERROR_TYPE_AMBIGUOUS_COMPOUNDS } from "./results/inputerrors/errors";
import ExportCenterSuccessMessage from "../export/ExportCenterSuccessMessage";


const EXCLUDED_RANGE_OCIDS = { start: 125000000000, end: 125999999999, exclude: true };

class ChemistrySearch extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.growl = createRef();
        this.toast = createRef();
        this.structSearch = createRef();
    }

    componentDidMount = () => {
        if (!isArrayEmpty(this.props.repositories)) {
            this.initSearchForm(this.props.repositories);
        }
    }

    componentDidUpdate(prevProps) {
        if (!isArrayEmpty(this.props.repositories) && !_.isEqual(prevProps.repositories, this.props.repositories)) {
            this.initSearchForm(this.props.repositories);
        }
    }

    initSearchForm = async () => {
        this.setState({ fetchingInitialData: true });

        //console.log('init: ', this.props.repositories);
        const availableChemDBItemsList = determineAvailableChemDBItems(this.props.repositories);
        //console.log('availableChemDBItemsList: ', availableChemDBItemsList);
        // --- get chemistry filter definitions --- //
        const { filterDefinitions } = await readChemistryFilterDefinitions();
        const chemDBFilterMap = await readChemistryDBFilterMapping();
        //console.log('filterDefinitions: ', filterDefinitions);
        //console.log('chemDBFilterMap: ', chemDBFilterMap);
        this.setState({
            availableChemDBItemsList: availableChemDBItemsList,
            filterDefinitions: filterDefinitions ? filterDefinitions : {},
            chemDBFilterMap: chemDBFilterMap ? chemDBFilterMap : {},
        });
        // --- get repositories for document hit counts --- //
        if (this.props.repositories) {
            this.updateRepositoriesForDocCounts(this.props.repositories);
        }
        // --- fill search form with data if available --- //
        const savedSearchState = getChemistrySearchFromStorage();
        console.log(savedSearchState)
        // --- get available target options --- //
        const { availableTargetOptions } = determineAvailableTargetOptions(availableChemDBItemsList, savedSearchState ? savedSearchState.isAttributeSearch : this.state.isAttributeSearch);
        const selectedTargetOption = savedSearchState?.selectedTargetOption ? savedSearchState.selectedTargetOption : determineDefaultTargetOption(availableTargetOptions);

        this.setState({
            availableTargetOptions: availableTargetOptions,
            fetchingInitialData: false,
            showChemDBOverview: true
        }, () => {
            if (savedSearchState) {
                this.setState({ ...savedSearchState, hideStructureChangedNotification: true }, () => { this.onTargetOptionChange(selectedTargetOption, false, true); });
            }
            else {
                this.onTargetOptionChange(selectedTargetOption, false, false);
            }
        });
    }

    updateRepositoriesForDocCounts = (repositories) => {
        const patentsRepository = determineRepositoryForDocCount(repositories, DOC_COUNT_REPOSITORIES_PATENTS);
        const publicationsRepository = determineRepositoryForDocCount(repositories, DOC_COUNT_REPOSITORIES_PUBLICATIONS);
        this.setState({ patentsRepository: patentsRepository, publicationsRepository: publicationsRepository });
    }

    // ----------------------------------------------------------------------- //
    // --- search form changes: query terms, operator, filters --------------- //
    // ----------------------------------------------------------------------- //
    // TODO? - input that was not selected from the suggestion list
    onNonValidatedInputChange = (input) => {
        this.setState({ nonValidatedInput: input });
    }

    onQueryTermsChange = (queryTerms, notifyQueryChanged = true, runSearch = false, checkForErrors = true) => {
        //console.log('onQueryTermsChange queryTerms', queryTerms);
        /*
        console.log('onQueryTermsChange queryTerms: ', queryTerms);

        if (checkForErrors) {
            const hasError = checkForIncorrectQueryTerm(queryTerms);
            if (hasError) {
                this.setState({ queryTerms: queryTerms });
                this.setState({ showChemClassErrorOverlay: hasError, inputError: ERROR_TYPE_CLASS_AND_COMPOUND_QUERY_TERM_MIXED });
                return;
            }
        }*/

        this.setState({ queryTerms: queryTerms }, () => {
            if (notifyQueryChanged) { this.onNotifyQueryChanged(); }
            if (runSearch) { this.runChemistrySearch(); }
        });
    }

    onAddQueryTerm = (queryTerm, notifyQueryChanged = true, runSearch = false) => {
        const queryTermsNew = this.state.queryTerms ? [...this.state.queryTerms] : [];
        queryTermsNew.push(queryTerm);
        this.onQueryTermsChange(queryTermsNew, notifyQueryChanged, runSearch);
    }

    onStructureChange = (structure, notifyQueryChanged = true, runSearch = false) => {
        if (!this.state.isAttributeSearch) {
            this.setState({ structure: structure }, () => {
                if (notifyQueryChanged && !this.state.hideStructureChangedNotification) { this.onNotifyQueryChanged(); }
                if (runSearch) { this.runChemistrySearch(); }
            });
        }
    }

    onTargetOptionChange = (targetOption, notifyQueryChanged = true, runSearch = false, callback) => {
        // --- get active target options (e.g. compounds, reactions), selected chem DB etc. --- //
        const { activeChemDBs, selectedChemDB, activeFilters, selectedSearchType } = determineActiveSettings(
            targetOption, this.state.availableChemDBItemsList, this.state.selectedChemDB, this.state.chemDBFilterMap, this.state.isAttributeSearch,
            this.state.filterDefinitions, this.state.filterInputValues);

        if (!selectedChemDB) {
            this.growl.current.show({ severity: 'warn', summary: 'Warning', detail: 'No suitable database found for your current settings.' });
            return;
        }

        if (selectedSearchType) {
            this.onFilterInputValuesChange({ filterID: CHEMISTRY_SEARCH_FILTER_ID_SEARCH_TYPE, value: selectedSearchType }, notifyQueryChanged, runSearch);
        }

        this.setState({
            selectedTargetOption: targetOption,
            activeChemDBs: activeChemDBs,
            selectedChemDB: selectedChemDB,
            activeFilters: activeFilters,
            // TODO: move to INITIAL...
            //showChemDBOverview: false,
            chemDBStatistics: createEmptySearchResult(activeChemDBs),
            ...INITIAL_CHEM_DB_SEARCH_RESULT
        }, () => {
            if (notifyQueryChanged) { this.onNotifyQueryChanged(); }
            if (runSearch) { this.runChemistrySearch(); }
            if (callback) { callback() }
        });
    }

    onFilterInputValuesChange = (filterInputValues, notifyQueryChanged = true, runSearch = false, callback) => {
        const filterInputValuesNew = { ...this.state.filterInputValues };
        const filterID = filterInputValues.filterID;
        const values = { ...filterInputValues };
        delete values.filterID;
        filterInputValuesNew[filterID] = values;
        this.onFilterInputValuesUpdate(filterInputValuesNew, notifyQueryChanged, runSearch, callback);
    }

    onImmediatelyAddFiltersToQuery = (filterInputValues, notifyQueryChanged = true, runSearch = false, callback) => {
        const filterInputValuesNew = { ...this.state.filterInputValues };
        const filterID = filterInputValues.filterID;
        const values = { ...filterInputValues };
        delete values.filterID;
        filterInputValuesNew[filterID] = values;
        this.onFilterInputValuesUpdate(filterInputValuesNew, notifyQueryChanged, runSearch, callback);
    }

    onRemoveFilter = (filterID, notifyQueryChanged = true, runSearch = false) => {
        const filterInputValuesNew = { ...this.state.filterInputValues };
        delete filterInputValuesNew[filterID];
        this.onFilterInputValuesUpdate(filterInputValuesNew, notifyQueryChanged, runSearch);
    }

    onFilterInputValuesUpdate = (filterInputValues, notifyQueryChanged = true, runSearch = false, callback) => {
        this.setState({ filterInputValues: filterInputValues }, () => {
            if (notifyQueryChanged) { this.onNotifyQueryChanged(); }
            if (runSearch) { this.runChemistrySearch(); }
            if (callback) { callback(); }
        });
    }

    onNotifyQueryChanged = (all) => {
        if (this.state.isSearchInitiated) {
            if (all) {
                this.setState({ structureQueryChanged: true, attributeQueryChanged: true });
            }
            else if (this.state.isAttributeSearch) { this.setState({ attributeQueryChanged: true }); }
            else { this.setState({ structureQueryChanged: true }); }
        }
    }

    onBulkImportSubmit = (idType, ids) => {
        const queryTermsNew = addChemistryBulkIDsToQueryTerms(this.state.filterDefinitions[CHEMISTRY_SEARCH_FILTER_ID_BULK_IMPORT],
            CHEMISTRY_BULK_IMPORT_TYPES[idType], ids, this.state.queryTerms, this.state.bulkImportTermToReplace);
        this.onQueryTermsChange(queryTermsNew);
        this.setState({ displayBulkImportDialog: false });
    }

    onSelectExample = async (example) => {
        if (example.isAttributeSearch) {
            /*
            if (example.searchType) {
                this.onFilterInputValuesChange({ filterID: CHEMISTRY_SEARCH_FILTER_ID_SEARCH_TYPE, value: example.searchType }, false, false, callback);
            }*/
            const { availableTargetOptions } = determineAvailableTargetOptions(this.state.availableChemDBItemsList, this.state.isAttributeSearch);
            const selectedTargetOption = determineDefaultTargetOption(availableTargetOptions, this.state.selectedTargetOption);
            const { queryTerms, ...rest } = example;
            this.setState({
                //...example,
                ...rest,
                availableTargetOptions: availableTargetOptions,
                selectedTargetOption: selectedTargetOption,
            }, //() => this.runChemistrySearch()
                () => this.onQueryTermsChange(queryTerms, false, true)
            )
        }
        else {
            this.setState({ hideStructureChangedNotification: true }, () => {
                this.onReplaceStructure(example.structure, async () => { this.runChemistrySearch(); })
            });
        }
    }

    onSwitchSearchForm = (isAttributeSearch) => {
        const { availableTargetOptions } = determineAvailableTargetOptions(this.state.availableChemDBItemsList, isAttributeSearch);
        const selectedTargetOption = determineDefaultTargetOption(availableTargetOptions, this.state.selectedTargetOption);

        this.setState({
            isAttributeSearch: isAttributeSearch,
            availableTargetOptions: availableTargetOptions,
            // TODO: move to INITIAL...
            //showChemDBOverview: false,
            chemDBStatistics: createEmptySearchResult(this.state.activeChemDBs),
            ...INITIAL_CHEM_DB_SEARCH_RESULT
        }, () => this.onTargetOptionChange(selectedTargetOption, false, false))
    }

    // ----------------------------------------------------------------------- //
    // --- fetch statistics -------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    runChemistrySearch = async () => {
        /*if (this.state.nonValidatedInput) {
            alert(this.state.nonValidatedInput);
        }*/
        this.setState({
            isSearchInitiated: true,
            showResults: false,
            showChemDBOverview: true,
            chemDBStatistics: {},
            selectedCompoundOcidsMap: {},
            multipleQueryStructures: this.state.isAttributeSearch ? countQueryStructures(this.state.queryTerms) > 1 : false,
            start: 0,
            maxLoadedEntry: -1
        });

        this.setState({ infoString: `Fetching statistics` });

        if (this.state.isAttributeSearch) { this.setState({ attributeQueryChanged: false }); }
        else { this.setState({ structureQueryChanged: false }); /*console.log('xxx structureQueryChanged FALSE');*/ }

        this.fetchChemDBResult(this.state.pageSize);
    }

    handleStatisticsResponse = (response, chemDB) => {
        const chemDBSearchResultsNew = addChemistryResult(response, chemDB, this.state.chemDBStatistics);
        this.setState({ chemDBStatistics: chemDBSearchResultsNew });
    }

    // ----------------------------------------------------------------------- //
    // --- fetch results ----------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    fetchChemDBResult = (pageSize, nextPageKey, e) => {
        const inputError = checkForIncorrectInput(this.state.isAttributeSearch, this.state.queryTerms, this.state.structure, this.state.selectedTargetOption, this.state.filterInputValues, this.state.selectedChemDB);
        if (!!inputError) {
            this.setState({
                ...INITIAL_CHEM_DB_SEARCH_RESULT,
                chemDBStatistics: createEmptySearchResult(this.state.activeChemDBs, this.state.selectedChemDB)
            });
            if (inputError === ERROR_TYPE_AMBIGUOUS_COMPOUNDS) {
                this.setState({ showAmbiguousInputOverlay: !!inputError });
            }
            else {
                this.setState({ showInputErrorOverlay: !!inputError, inputError: inputError });
            }
            return;
        }
        // --- concepts were already requested before --- //
        if (e && e.first + pageSize <= this.state.maxLoadedEntry) {
            this.setState({ start: e.first, pageSize: pageSize });
            return;
        }

        const chemDBStatistics = this.state.chemDBStatistics;
        const key = createChemDBKey(this.state.selectedChemDB);
        chemDBStatistics[key] = { status: CHEM_DB_STATE_LOADING, info: 'Searching in database' };
        this.setState({
            fetchingMore: true,
            chemDBStatistics: { ...chemDBStatistics }
        });
        // --- if no nextPageKey is defined, it is a new request -> reset results --- //
        if (!nextPageKey) {
            this.setState({ ...INITIAL_CHEM_DB_SEARCH_RESULT });
        }
        // --- include or exclude mixtures --- //
        const withMixtures = determineActiveIncludeMixtures(this.state.isAttributeSearch, this.state.queryTerms, this.state.withMixtures, this.state.filterInputValues, this.state.selectedTargetOption, this.state.selectedChemDB);
        this.setState({ actualWithMixtures: withMixtures });
        // TODO: move
        if (this.state.isAttributeSearch) {
            runAttributeSearch(this.state.selectedTargetOption, this.state.selectedChemDB, this.state.queryTerms,
                this.state.filterInputValues, pageSize, nextPageKey, false, this.state.filterDefinitions,
                withMixtures, this.state.sortByField?.id, this.state.sortDirection?.id)?.then(
                    response => {
                        this.handleStatisticsResponse(response, this.state.selectedChemDB);
                        this.handleResponse(response, nextPageKey, e);
                    });
        }
        else {
            const format = EDITOR_STRUCTURE_FORMAT === STRUCTURE_FORMAT_RXN ? determineStructureFormat(this.state.structure) : EDITOR_STRUCTURE_FORMAT;
            runStructureSearch(this.state.selectedTargetOption, this.state.selectedChemDB, this.state.structure,
                this.state.filterInputValues, format, pageSize, nextPageKey, false, this.state.filterDefinitions,
                withMixtures, this.state.sortByField?.id, this.state.sortDirection?.id)?.then(
                    response => {
                        this.handleStatisticsResponse(response, this.state.selectedChemDB);
                        this.handleResponse(response, nextPageKey, e);
                    });
        }
    }

    // TODO: move
    handleResponse = async (response, nextPageKey, e) => {
        const result = checkResultAndGetPayload(response, this.growl);
        const searchType = getSearchTypeFromFilterValues(this.state.filterInputValues);
        //const queryStructure = (!this.state.isAttributeSearch && searchType === SEARCH_TYPE_SUBSTRUCTURE) ? this.state.structure : null;
        const queryStructure = searchType === SEARCH_TYPE_SUBSTRUCTURE ? response.queryStructure : null;

        if (result) {
            //console.log('result type: ', result.type);
            let resultEntries;
            const extraInfoMap = {};
            // --- with paging, add data to results if neccessary --- //
            if (result.type === SEARCH_RESULT_TYPE_COMPOUNDS) {
                const extraInfoMap = {};
                result.hits?.forEach(hit => { if (hit.ocid) { extraInfoMap[hit.ocid] = hit; } });
                resultEntries = result.ocids ? await addConceptsData(Object.keys(result.ocids), 0, Object.keys(result.ocids).length, this.state.ocidExtraDataMap, extraInfoMap, queryStructure) : [];
            }
            else if (result.type === SEARCH_RESULT_TYPE_REACTION_COMPOUNDS) {
                resultEntries = result.hits ? await addImageData(result.hits, true, true, queryStructure) : [];
            }
            else if (result.type === SEARCH_RESULT_TYPE_REACTIONS) {
                resultEntries = result.reactions;
            }
            // --- without paging, complete result! add data to current page results --- //
            else if (result.type === SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE) {
                result.hits?.forEach(hit => { if (hit.ocid) { extraInfoMap[hit.ocid] = hit; } });
                const resultEntriesWithData = result.ocids ? await addConceptsData(Object.keys(result.ocids), 0, this.state.pageSize, this.state.ocidExtraDataMap, extraInfoMap, queryStructure) : [];
                result.totalHits = Object.keys(result.ocids) ? Object.keys(result.ocids).length : 0;
                resultEntries = [...result.hits];
                resultEntries.splice(0, this.state.pageSize, ...resultEntriesWithData);
            }
            // --- add keys to results for this page --- //
            resultEntries = addKeysToEntries(resultEntries, this.state.chemDBSearchResultEntries?.length);
            // --- merge with previous results if it was a paging event and there are any --- //
            const { mergedResultEntries, start, maxLoadedPage, maxLoadedEntry } = mergeWithPreviousResults(
                this.state.chemDBSearchResultEntries, resultEntries, this.state.start, this.state.maxLoadedPage, e, nextPageKey, this.state.maxLoadedEntry);

            this.setState({
                chemDBSearchResult: result,
                chemDBSearchResultEntries: mergedResultEntries,
                extraInfoMap: extraInfoMap,
                showResults: true,
                start: start,
                maxLoadedPage: maxLoadedPage,
                maxLoadedEntry: maxLoadedEntry,
                hideStructureChangedNotification: false,
            });
        }

        this.setState({ fetchingMore: false });
    }

    // TODO: should be maxLoadedEntry, in case page size changes!!!
    onPage = async (start, pageSize, page) => {
        //console.log('onPage', [start, pageSize, page, this.state.maxLoadedEntry, this.state.maxLoadedPage]);
        //if (page > this.state.maxLoadedPage) {
        if (start + pageSize > this.state.maxLoadedEntry) {
            const resultEntriesOnPage = this.state.chemDBSearchResult?.ocids ?
                await addConceptsData(Object.keys(this.state.chemDBSearchResult.ocids), start, start + pageSize, this.state.ocidExtraDataMap, this.state.extraInfoMap) : [];
            //console.log('this.state.chemDBSearchResult?.ocids: ', this.state.chemDBSearchResult?.ocids);
            addKeysToEntries(resultEntriesOnPage, start);

            let resultEntries = [...this.state.chemDBSearchResultEntries];
            resultEntries.splice(start, pageSize, ...resultEntriesOnPage);
            //console.log('resultEntries: ', resultEntries);

            this.setState({
                chemDBSearchResultEntries: resultEntries,
                maxLoadedPage: page,
                maxLoadedEntry: start + pageSize,
            });
        }

        this.setState({
            start: start,
            pageSize: pageSize
        });
    }

    onPageSizeChange = (pageSize) => {
        this.setState({
            ...INITIAL_CHEM_DB_SEARCH_RESULT,
            pageSize: pageSize,
        }, () => this.fetchChemDBResult(pageSize)); //this.runChemistrySearch()
    }

    onSortByFieldChange = async (sortByField) => {
        //console.log('sortByField old: ', this.state.sortByField);
        //console.log('sortByField new: ', sortByField);
        //console.log('sortByField ===: ', (this.state.sortByField === sortByField));
        this.setState({
            //...INITIAL_CHEM_DB_SEARCH_RESULT,
            sortByField: sortByField
        }, () => this.fetchChemDBResult(this.state.pageSize));
    }

    onSortDirectionChange = async (sortDirection) => {
        this.setState({
            //...INITIAL_CHEM_DB_SEARCH_RESULT,
            sortDirection: sortDirection
        }, () => this.fetchChemDBResult(this.state.pageSize));
    }

    onReplaceStructure = async (smiles, callback) => {
        this.onSwitchSearchForm(false);
        if (smiles) {
            const response = EDITOR_STRUCTURE_FORMAT === STRUCTURE_FORMAT_MRV ?
                await convertStructureToFormatWS(smiles, STRUCTURE_FORMAT_SMILES, EDITOR_STRUCTURE_FORMAT) :
                await convertStructureToFormatV2(createStructureToFormatRequestV2(smiles, STRUCTURE_FORMAT_SMILES, STRUCTURE_FORMAT_MOL));
            const resStructure = checkResultAndGetPayload(response, this.growl);
            this.setState({
                structureQueryChanged: false,
                structure: resStructure
            }, () => { if (callback) { callback() } });
        }
        // really???
        else {
            this.setState({ structure: null }, () => { if (callback) { callback() } });
        }
        window.scrollTo(0, this.structSearch.current.offsetTop - 300);
    }

    onReplaceQuery = (queryTerm, addToQuery = false, notifyQueryChanged = false, runSearch = false) => {
        if (queryTerm) {
            this.onSwitchSearchForm(true);
            if (addToQuery) { this.onAddQueryTerm(queryTerm, notifyQueryChanged, runSearch); }
            else { this.onQueryTermsChange([queryTerm], notifyQueryChanged, runSearch); }
        }
    }

    onSwitchToExactCompoundSearch = () => {
        const duplicateFilter = this.state.filterDefinitions?.searchType?.filterValues?.find(filterDef => filterDef.filterValue === SEARCH_TYPE_FILTER_DUPLICATE.value.filterValue);
        const searchFilter = { filterID: SEARCH_TYPE_FILTER_DUPLICATE.filterID, value: duplicateFilter }
        this.onTargetOptionChange(SEARCH_TARGET_COMPOUNDS, false, false,
            () => this.onImmediatelyAddFiltersToQuery(searchFilter, false, false,
                () => this.runChemistrySearch())
        );
    }

    specifyChemClassOrCompound = () => {

        const queryTermsNew = this.state.queryTerms ? [...this.state.queryTerms] : [];
        queryTermsNew.forEach(qt => {
            console.log('qt', qt);
        });
        this.onQueryTermsChange(queryTermsNew, false, false);
    }

    onWithMixturesChange = (withMixtures, notifyQueryChanged = true, runSearch = false) => {
        this.setState({ withMixtures: withMixtures }, () => {
            if (notifyQueryChanged && !this.state.hideStructureChangedNotification) { this.onNotifyQueryChanged(); }
            if (runSearch) { this.runChemistrySearch(); }
        });
    }

    onSelectChemDB = (chemDB) => {
        const activeFilters = this.state.chemDBFilterMap[chemDB?.service];
        this.setState({
            selectedChemDB: chemDB,
            activeFilters: activeFilters,
            selectedCompoundOcidsMap: {},
        }, () => this.fetchChemDBResult(this.state.pageSize));
    }

    onSelectCompound = (ocid, select, concept) => {
        const newMap = changeEntrySelection(this.state.selectedCompoundOcidsMap, ocid, select, concept);
        this.setState({ selectedCompoundOcidsMap: newMap });
    }

    onSendToExportCenter = async () => {
        // --- include or exclude mixtures --- //
        //const withMixtures = determineActiveIncludeMixtures(this.state.isAttributeSearch, this.state.queryTerms, this.state.withMixtures, this.state.filterInputValues, this.state.selectedTargetOption, this.state.selectedChemDB);
        let response;
        if (this.state.isAttributeSearch) {
            response = await runAttributeSearch(this.state.selectedTargetOption, this.state.selectedChemDB, this.state.queryTerms,
                this.state.filterInputValues, 500, null, true, this.state.filterDefinitions, this.state.actualWithMixtures);
        }
        else {
            const format = EDITOR_STRUCTURE_FORMAT === STRUCTURE_FORMAT_RXN ? determineStructureFormat(this.state.structure) : EDITOR_STRUCTURE_FORMAT;
            response = runStructureSearch(this.state.selectedTargetOption, this.state.selectedChemDB, this.state.structure,
                this.state.filterInputValues, format, 500, null, true, this.state.filterDefinitions, this.state.actualWithMixtures);
        }
        checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage />, null, true);
    }


    // ----------------------------------------------------------------------- //
    // --- save query -------------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    // TODO: Move to own component???
    openSaveQueryDialog = async (editQuery, savedSearchID, savedSearchName) => {
        this.setState({
            displaySaveQueryDialog: true,
            savedSearchID: savedSearchID,
            savedSearchName: savedSearchName,
            queryString: this.state.isAttributeSearch ? 'Identifier search' : 'Structure search',
            editQuery: editQuery,
        });
    }

    // TODO: move
    onStoreQuery = async (queryString, queryName, queryFullName, queryDescription, watchlistIDs) => {
        //console.log('onStoreQuery this.state.filterInputValues: ', this.state.filterInputValues);
        //console.log('onStoreQuery this.state.structure: ', this.state.structure);
        const formContent = {
            version: VERSION_2_0,
            targetOption: this.state.selectedTargetOption,
            filters: this.state.filterInputValues
        };
        if (this.state.isAttributeSearch) { formContent.queryTerms = this.state.queryTerms; }
        else { formContent.structure = btoa(this.state.structure); }

        if (this.state.withMixtures) {
            formContent.withMixtures = this.state.withMixtures;
        }

        let response;
        let queryID;
        // --- existing query is updated --- //
        if (this.state.editQuery) {
            response = await updateQuery(this.state.savedSearchID,
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_CHEM_SEARCH.id, QUERY_TYPE_CHEM_SEARCH.id,
                this.state.isAttributeSearch ? QUERY_FORM_CHEM_ATTRIBUTE.id : QUERY_FORM_CHEM_STRUCTURE.id, formContent, watchlistIDs);
            queryID = this.state.savedSearchID;
        }
        // --- new query is stored --- //
        else {
            response = await storeQuery(
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_CHEM_SEARCH.id, QUERY_TYPE_CHEM_SEARCH.id,
                this.state.isAttributeSearch ? QUERY_FORM_CHEM_ATTRIBUTE.id : QUERY_FORM_CHEM_STRUCTURE.id, formContent, watchlistIDs);
            queryID = response?.payload?.id;
        }

        checkResultAndGetPayload(response, this.growl, 'Success',
            `${this.state.editQuery ? 'Search successfully updated.' : 'Search successfully stored.'}`);

        if (response.status === 'SUCCESS') {
            this.setState({
                savedSearchID: queryID,
                savedSearchName: queryFullName,
                displaySaveQueryDialog: false,
                editableQuery: true,
            });
        }
    }

    onHideStoreQuery = () => {
        this.setState({ displaySaveQueryDialog: false });
    }

    onSelectSavedSearch = (savedSearch) => {
        //console.log('savedSearch: ', savedSearch);
        const savedSearchState = convertSavedChemistrySearchToState(savedSearch);
        if (savedSearchState) {
            const { availableTargetOptions } = determineAvailableTargetOptions(this.state.availableChemDBItemsList, savedSearchState.isAttributeSearch);
            //console.log('savedSearchState: ', savedSearchState);
            this.setState({ ...savedSearchState, availableTargetOptions, hideStructureChangedNotification: true }, async () => {
                const selectedTargetOption = savedSearchState?.selectedTargetOption ?
                    savedSearchState.selectedTargetOption : determineDefaultTargetOption(this.state.availableTargetOptions, this.state.selectedTargetOption);
                this.onTargetOptionChange(selectedTargetOption, false, true);
            });
        }
    }

    onClearSavedSearch = () => {
        const resetSavedSearchState = convertSavedChemistrySearchToState(null);
        this.setState({ ...resetSavedSearchState });
    }

    onReplaceQueryTerm = (oldTerm, newTerm) => {
        let queryTermsNew = this.state.queryTerms ? [...this.state.queryTerms] : [];
        queryTermsNew = replaceValueInArray(queryTermsNew, oldTerm, newTerm);
        this.onQueryTermsChange(queryTermsNew, true, false);
    }

    // ----------------------------------------------------------------------- //
    // --- domain explorer --------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onAddQueryTermWithDomainExplorer = () => {
        this.setState({
            domainBrowserVisible: true,
            queryTermToReplace: null,
            domainExplorerQuery: null,
            domainExplorerOcids: null,
        });
    }

    onSpecifyQueryTermWithDomainExplorer = (queryTerm) => {
        this.setState({
            queryTermToReplace: queryTerm,
            domainExplorerQuery: isArrayEmpty(queryTerm?.ocids) && queryTerm?.term,
            domainExplorerOcids: !isArrayEmpty(queryTerm?.ocids) && queryTerm.ocids,
        }, () => {
            this.setState({ domainBrowserVisible: true })
        });
    }

    onDomainExplorerClose = () => {
        this.setState({ domainBrowserVisible: false });
    }

    onDomainExplorerSubmit = (concepts) => {
        this.setState({ domainBrowserVisible: false });

        if (this.state.queryTermToReplace) {
            const queryTerms = replaceQueryTermWithConcepts(concepts, this.state.queryTerms, this.state.queryTermToReplace);

            this.setState({
                queryChanged: true,
                queryTerms: queryTerms,
                queryTermToReplace: null
            });
        }
        else {
            const queryTerms = addConceptsToQueryTerms(concepts, this.state.queryTerms, true);

            this.setState({
                queryChanged: true,
                queryTerms: queryTerms,
            });
        }
    }

    // TODO: move
    onExport = async (exportOption, fileFormat) => {
        //console.log('exportOption: ', exportOption);
        //console.log('fileFormat: ', fileFormat);
        this.setState({ exportingToFile: true });

        let compoundOcids = null;
        switch (exportOption.id) {
            case EXPORT_OPTION_ID_SELECTED: {
                compoundOcids = this.state.selectedCompoundOcidsMap && !isArrayEmpty(Object.keys(this.state.selectedCompoundOcidsMap)) ? [...Object.keys(this.state.selectedCompoundOcidsMap)] : [];
                break;
            }
            case EXPORT_OPTION_ID_ALL: {
                compoundOcids = this.state.chemDBSearchResultEntries?.map(entry => entry.ocid);
                break;
            }
            case EXPORT_OPTION_ID_MAX: {
                this.onSendToExportCenter();
                return;
            }
            default: // do nothing
        }
        if (compoundOcids) {
            fileFormat.id === EXPORT_FORMAT_SDF ? await exportCompoundsToSdfFile(compoundOcids) : await exportCompoundsToSmiFile(compoundOcids);
        }

        this.setState({ exportingToFile: false });
    }

    openClearAllDialog = () => {
        this.setState({ displayClearAllConfirmDialog: true });
    }

    onClearAll = () => {
        this.setState({
            // TODO: ...INITIAL_STATE????
            queryTerms: [],
            structure: null,
            sortByField: null,
            sortDirection: null,
            filterInputValues: undefined,
            isAttributeSearch: true,
            attributeQueryChanged: false,
            structureQueryChanged: false,
            selectedCompoundOcidsMap: {},
            activeFilterIndices: DEFAULT_ACTIVE_FILTER_INDICES,
            selectedChemDB: null,
            showAmbiguousInputOverlay: false,
            showInputErrorOverlay: false,
            //showChemClassErrorOverlay: false,
            inputError: null,
            withMixtures: false,
            ...convertSavedChemistrySearchToState(null)
        }, () => {
            const selectedTargetOption = determineDefaultTargetOption(this.state.availableTargetOptions);
            this.onTargetOptionChange(selectedTargetOption, false, false);
        });
    }

    render() {
        //const patFam = this.state.allowGroupByPatentFamily && this.state.groupByPatFamily && this.state.patFamily ? this.state.patFamily : null;
        const blockSearch = this.state.chemDBStatistics && Object.values(this.state.chemDBStatistics).some(result => result?.status === CHEM_DB_STATE_LOADING);
        const queryChanged = (this.state.isAttributeSearch && this.state.attributeQueryChanged) || (!this.state.isAttributeSearch && this.state.structureQueryChanged);
        const isEmptyQuery = isSearchEmpty(this.state.isAttributeSearch, this.state.queryTerms, this.state.structure);
        const showPreparationsSearch = this.state.availableChemDBItemsList?.some(dbItem => dbItem.allowedSearchTargets && dbItem.allowedSearchTargets[SEARCH_TARGET_PRODUCTS])
        const numOfSelectedElements = Object.keys(this.state.selectedCompoundOcidsMap)?.length;
        const exportOptions = determineAcitveExportOptions(this.state.chemDBSearchResult?.type, numOfSelectedElements, this.state.multipleQueryStructures);
        const inclWithMixtures = determineIncludeMixturesFilterActive(this.state.isAttributeSearch, this.state.queryTerms, this.state.filterInputValues, this.state.selectedTargetOption, this.state.selectedChemDB);
        const searchType = getSearchTypeFromFilterValues(this.state.filterInputValues);
        const isExactSearch = searchType === SEARCH_TYPE_EXACT;
        const isChemClassSearch = isChemClassInput(this.state.queryTerms);
        const allowSorting = this.state.selectedChemDB?.allowSorting && (this.state.selectedChemDB.allowSorting[SEARCH_TYPE_ALL] || this.state.selectedChemDB.allowSorting[searchType]);
        const disableAdditionalFilters = !isChemClassSearch && isExactSearch;
        const activeFilterIndices = !disableAdditionalFilters ? this.state.activeFilterIndices : DEFAULT_ACTIVE_FILTER_INDICES;
        const { numOfChemCompoundsInQuery, numOfChemClassesInQuery } = analyzeQuery(this.state.queryTerms);
        const allowSearchByChemClass = this.state.selectedChemDB?.allowSearchByChemClass;
        const preparationsService = this.state.selectedChemDB?.preparationsService && CHEM_DB_ITEMS[this.state.selectedChemDB.preparationsService] ?
            CHEM_DB_ITEMS[this.state.selectedChemDB.preparationsService] : this.state.selectedChemDB;

        return (
            <>
                <Toast ref={this.growl} />
                <Toast ref={this.toast} className="toast quickSearchToast" position="bottom-center" />

                <LoadingOverlay
                    active={blockSearch || this.state.fetchingMore || this.state.fetchingInitialData}
                    spinner={true} >
                    <div className="grid" ref={this.structSearch}>
                        <div className="col-fixed"
                            style={{ padding: '5px 15px 10px 10px', minHeight: 'calc(100vh - 130px)', width: 330, borderRight: '1px solid #ddd' }}>
                            {this.state.showFilters ?
                                <ChemistryFiltersBlock
                                    filterDefinitions={this.state.filterDefinitions}
                                    repositoryFilterMap={this.state.chemDBFilterMap}
                                    onSelectionChange={this.onFacetSelectionChange}
                                    searchTarget={this.state.selectedTargetOption}
                                    selectedChemDB={this.state.selectedChemDB}
                                    activeFilters={this.state.activeFilters}
                                    repositorySchema={this.state.repositorySchema}
                                    filterInputValues={this.state.filterInputValues}
                                    onFilterInputValuesChange={this.onFilterInputValuesChange}
                                    includeWithMixtures={inclWithMixtures}
                                    withMixtures={this.state.withMixtures}
                                    onWithMixturesChange={this.onWithMixturesChange}
                                    onRemoveFilter={this.onRemoveFilter}
                                    onImmediatelyAddFiltersToQuery={this.onImmediatelyAddFiltersToQuery}
                                    queryTerms={this.state.queryTerms}
                                    activeFilterIndices={activeFilterIndices}
                                    onToggleFilters={(indices) => this.setState({ activeFilterIndices: DEFAULT_ACTIVE_FILTER_INDICES.concat(indices) })}
                                    disableAdditionalFilters={disableAdditionalFilters}
                                /> : null}
                        </div>
                        <div className="col" style={{ width: 'calc(100vw - 370px)', padding: '10px 20px' }}>
                            <div className="grid">
                                <div className="col-12" style={{ padding: '10px 0px' }}>
                                    <ChemistryHeaderBlock
                                        onSelectExample={this.onSelectExample} />
                                </div>
                                <div className="col-12" style={{ padding: '0 0px' }}>
                                    <ChemistrySearchFormBlock
                                        //ref={(ref) => { this.chemSearchForm = ref; }}
                                        filterDefinitions={this.state.filterDefinitions}
                                        isAttributeSearch={this.state.isAttributeSearch}
                                        onSearchFormChange={() => this.onSwitchSearchForm(!this.state.isAttributeSearch)}
                                        queryTerms={this.state.queryTerms}
                                        onQueryTermsChange={this.onQueryTermsChange}
                                        onNonValidatedInputChange={this.onNonValidatedInputChange}
                                        queryTerm={this.state.queryTerm}
                                        structure={this.state.structure}
                                        onStructureChange={this.onStructureChange}
                                        structureFormat={EDITOR_STRUCTURE_FORMAT}
                                        searchTargetOptions={this.state.availableTargetOptions}
                                        searchTarget={this.state.selectedTargetOption}
                                        onTargetOptionChange={this.onTargetOptionChange}
                                        onSubmitSearch={this.runChemistrySearch}
                                        onSaveQuery={this.openSaveQueryDialog}
                                        onClearSavedSearch={this.onClearSavedSearch}
                                        onClearAll={this.openClearAllDialog}
                                        editableQuery={this.state.editableQuery}
                                        displaySavedSearchesDialog={this.state.displaySavedSearchesDialog}
                                        allowedQueryTypes={ALLOWED_SAVED_CHEM_SEARCH_TYPES}
                                        savedSearchID={this.state.savedSearchID}
                                        savedSearchName={this.state.savedSearchName}
                                        onSelectSavedSearch={this.onSelectSavedSearch}
                                        queryChanged={queryChanged}
                                        inclBulkImport={this.state.isAttributeSearch}
                                        onBulkImportSubmit={this.onBulkImportSubmit}
                                        inclDomainBrowser={this.state.isAttributeSearch}
                                        onAddQueryTermsWithDomainExplorer={this.onAddQueryTermWithDomainExplorer}
                                        onReplaceQueryTermWithDomainExplorer={this.onSpecifyQueryTermWithDomainExplorer}
                                        onReplaceQueryTerm={this.onReplaceQueryTerm}
                                        blockSearchButton={isEmptyQuery}
                                        includeChemClassesInAutocomplete={numOfChemCompoundsInQuery === 0 && allowSearchByChemClass}
                                        includeChemCompoundsInAutocomplete={numOfChemClassesInQuery === 0}
                                    />
                                </div>
                                {!this.state.fetchingInitialData ?
                                    this.state.showAmbiguousInputMessage ?
                                        <div className="col-12">
                                            <UpdateSearchResultsInfo
                                                onClick={() => this.onSwitchToExactCompoundSearch()}
                                                text='Your input likely matches multiple structures, but non-exact searches only work with one unique structure. So please click here to choose one of the compounds.' /></div>
                                        :
                                        this.state.showChemDBOverview ?
                                            <div ref={this.repoStatsRef}
                                                className="col-12"
                                                style={{ padding: '0 10px' }}>
                                                {this.state.selectedChemDB ?
                                                    <>
                                                        {queryChanged ? isEmptyQuery ?
                                                            <UpdateSearchResultsInfo text='Empty search. Please provide an identifier or a structure.' /> :
                                                            <UpdateSearchResultsInfo onClick={this.runChemistrySearch} text='Run search to see matching results.' /> : null}
                                                        <div className={`${queryChanged ? 'disableContent' : ''}`}>
                                                            <ChemistryDBOverview
                                                                chemDBItems={this.state.availableChemDBItemsList}
                                                                chemDBStatistics={this.state.chemDBStatistics}
                                                                selectedChemDB={this.state.selectedChemDB}
                                                                onSelectChemDB={this.onSelectChemDB}
                                                                startIndex={this.state.start}
                                                                blockSearch={blockSearch}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <span>No suitable database found for your current settings.</span>
                                                }
                                            </div> : null
                                    : null}
                                {!this.state.fetchingInitialData ?
                                    this.state.showResults && this.state.chemDBSearchResultEntries &&
                                        this.state.activeChemDBs && this.state.selectedChemDB?.type ?
                                        <div className="col-12">
                                            <div className={`${queryChanged ? 'outdatedContent' : ''}`}>
                                                {this.state.selectedChemDB.type === SEARCH_RESULT_TYPE_COMPOUNDS ||
                                                    this.state.selectedChemDB.type === SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE ||
                                                    this.state.selectedChemDB.type === SEARCH_RESULT_TYPE_REACTION_COMPOUNDS ?
                                                    <CompoundResults
                                                        concepts={this.state.chemDBSearchResultEntries}
                                                        result={this.state.chemDBSearchResult}
                                                        resultComplete={this.state.chemDBSearchResult?.isCompleteResult}
                                                        nextPageKey={this.state.chemDBSearchResult?.nextPageKey}
                                                        start={this.state.start}
                                                        pageSize={this.state.pageSize}
                                                        onPageSizeChange={this.onPageSizeChange}
                                                        onLoadMore={this.fetchChemDBResult}
                                                        onPage={this.onPage}
                                                        sortByField={this.state.sortByField}
                                                        sortDirection={this.state.sortDirection}
                                                        sortByFields={allowSorting ? SORT_BY_FIELDS : []}
                                                        sortDirections={allowSorting ? SORT_DIRECTIONS : []}
                                                        onSortByFieldChange={this.onSortByFieldChange}
                                                        onSortDirectionChange={this.onSortDirectionChange}
                                                        onSelectCompound={this.onSelectCompound}
                                                        selectedCompoundOcids={this.state.selectedCompoundOcidsMap}
                                                        inclExport={!isArrayEmpty(exportOptions)}
                                                        onSendToExportCenter={this.onSendToExportCenter}
                                                        onExport={e => this.setState({ displayExportDialog: true })}
                                                        onReplaceStructure={this.onReplaceStructure}
                                                        onReplaceQuery={this.onReplaceQuery}
                                                        includePreparationsSearch={showPreparationsSearch}
                                                        includeAddToSearchFieldOption={this.state.isAttributeSearch}
                                                        includeWithMixtures={inclWithMixtures && !queryChanged}
                                                        withMixtures={this.state.withMixtures}
                                                        mixturesIncluded={this.state.actualWithMixtures}
                                                        onWithMixturesChange={this.onWithMixturesChange}
                                                        preparationsTarget={preparationsService}
                                                        patentsRepository={this.state.patentsRepository}
                                                        publicationsRepository={this.state.publicationsRepository}
                                                    />
                                                    :
                                                    <ReactionResults
                                                        reactions={this.state.chemDBSearchResultEntries}
                                                        result={this.state.chemDBSearchResult}
                                                        nextPageKey={this.state.chemDBSearchResult?.nextPageKey}
                                                        start={this.state.start}
                                                        pageSize={this.state.pageSize}
                                                        onLoadMore={this.fetchChemDBResult}
                                                        onSendToExportCenter={this.onSendToExportCenter}
                                                        onExport={e => this.setState({ displayExportDialog: true })}
                                                        onReplaceStructure={this.onReplaceStructure}
                                                        queryMol={(!this.state.isAttributeSearch && searchType !== SEARCH_TYPE_SUBREACTION) ? this.state.structure : null}
                                                    />
                                                }
                                            </div>
                                        </div> : null
                                    : null}
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>

                <OntologyBrowserDialog
                    ontBrowserDialogID="chemSearchDomBrowserDlg"
                    headerLabel="Domain explorer"
                    //selectionMode="multiple"
                    selectionMode="single"
                    placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                    domains={this.props.chemistryDomains}
                    rangeOcids={EXCLUDED_RANGE_OCIDS}
                    initialSearchTerm={this.state.domainExplorerQuery}
                    initialOcids={this.state.domainExplorerOcids}
                    numOfChildNodes={10} // not used for preloaded ontology
                    allowConceptSearchByClick={true}
                    allowSearchInOntologies={true}
                    loadOntologiesOnStart={true}
                    ontBrowserVisible={this.state.domainBrowserVisible}
                    onOntBrowserClose={this.onDomainExplorerClose}
                    onOntBrowserSubmit={this.onDomainExplorerSubmit}
                    onOntBrowserShow={() => { }}
                    width="90vw"
                    height="90vh"
                />

                <AmbiguousInputDialog
                    displayDialog={this.state.showAmbiguousInputOverlay}
                    onToggleDialog={(toggle) => this.setState({ showAmbiguousInputOverlay: toggle })}
                    queryTerms={this.state.queryTerms}
                    onExactCompoundSearch={() => this.onSwitchToExactCompoundSearch()}
                    onSelectedCompoundSearch={(queryTerm) => this.onReplaceQuery(queryTerm, false, false, true)}
                    onReplaceStructure={this.onReplaceStructure}
                    onReplaceQuery={this.onReplaceQuery}
                    //includePreparationsSearch={showPreparationsSearch}
                    //preparationsTarget={this.state.selectedChemDB?.preparationsService}
                    patentsRepository={this.state.patentsRepository}
                    publicationsRepository={this.state.publicationsRepository}
                    exactSearchWithMultipleStructuresAllowed={this.state.selectedChemDB?.multipleStructuresAllowed}
                />

                <IncorrectInputDialog
                    displayDialog={this.state.showInputErrorOverlay}
                    onToggleDialog={(toggle) => this.setState({ showInputErrorOverlay: toggle })}
                    error={this.state.inputError}
                    onClick={this.onSwitchToExactCompoundSearch}
                />

                <SaveSearchDialog
                    displayDialogStoreQuery={this.state.displaySaveQueryDialog}
                    editQuery={this.state.editQuery}
                    queryString={this.state.queryString}
                    savedSearchID={this.state.savedSearchID}
                    allowAlerts={false}
                    onSubmit={this.onStoreQuery}
                    onHide={this.onHideStoreQuery}
                    userData={this.props.userData}
                />

                <ConfirmationDialog
                    displayDialog={this.state.displayClearAllConfirmDialog}
                    onHide={() => this.setState({ displayClearAllConfirmDialog: false })}
                    onSubmit={() => {
                        this.onClearAll();
                        this.setState({ displayClearAllConfirmDialog: false });
                    }}
                    headerText="Confirm"
                    messageText="Clear all input?"
                    submitButtonLabel="Clear"
                />

                <ChemistrySearchToast
                    displayToast={!isArrayEmpty(Object.keys(this.state.selectedCompoundOcidsMap))}
                    onHide={() => this.setState({ displayToast: false, selectedCompoundOcidsMap: {} })}
                    //onExport={this.onSendToExportCenter}
                    onExport={e => this.setState({ displayExportDialog: true })}
                    onSendToSearch={e => {
                        const ocids = Object.keys(this.state.selectedCompoundOcidsMap);
                        const dataItem = createShortcutDataItemForMultipleConcepts(this.state.selectedCompoundOcidsMap, 'chem', `${ocids.length} selected compound(s)`);
                        executeQuickSearchShortcut(dataItem);
                    }}
                    onSelectAllOnPage={e => {
                        const selectedCompoundOcidsMap = this.state.selectedCompoundOcidsMap ? { ...this.state.selectedCompoundOcidsMap } : {};
                        for (var index = this.state.start; index < this.state.start + this.state.pageSize; index++) {
                            const concept = this.state.chemDBSearchResultEntries && this.state.chemDBSearchResultEntries[index] ? this.state.chemDBSearchResultEntries[index] : null;
                            if (concept?.ocid) {
                                selectedCompoundOcidsMap[concept.ocid] = concept;
                            }
                        }
                        this.setState({ selectedCompoundOcidsMap: selectedCompoundOcidsMap });
                    }}
                    selectedEntries={Object.keys(this.state.selectedCompoundOcidsMap)}
                    elementLabel={'compound'}
                />

                <ChemistryExportDialog
                    displayDialog={this.state.displayExportDialog}
                    onToggleDialog={(display) => this.setState({ displayExportDialog: display })}
                    numOfSelectedElements={numOfSelectedElements}
                    maxNumOfElements={this.state.chemDBSearchResultEntries?.length}
                    exportOptions={exportOptions}
                    onExportSubmit={(exportOption, fileFormat) => {
                        this.setState({ displayExportDialog: false });
                        this.onExport(exportOption, fileFormat);
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    chemistryDomains: state.webAPI.availableDomains?.filter(dom => dom.value === 'chem'),
    repositories: state.user.data.userDetails.department?.selectedRepositories,
})
const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChemistrySearch))