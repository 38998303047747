import { useCallback, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { BiomarkerLeftAside } from "./BiomarkerLeftAside/BiomarkerLeftAside";
import { BiomarkerRightAside } from "./BiomarkerRightAside/BiomarkerRightAside";
import { BiomarkerPopup } from "./BiomarkerPopup/BiomarkerPopup";
import { useDispatch, useSelector } from "react-redux";
import {
    setBiomarkerHeadersStructureSkeleton,
    setBiomarkerNodes,
    //setHeadersStructureSkeleton,
    setBiomarkerLeftAsideExpandedKeys,
    //setNodes,
    setReposWithBiomarkerSearchFeature,
    setBiomarkerRoleMapping,
    setBiomarkerSelectedReposForSearch, resetBiomarkerState
} from "../../../redux/actions/BiomarkerActions";
import { fetchBiomarkerRoleMapping } from "../../../api/content/BiomarkerApi";
import { addKeysToRoles } from "../../common/helpers/addKeysToRoles";
import { SaveDialog } from "./SaveDialog/SaveDialog";
import { PageDivider } from "../../common/PageDivider/PageDivider";
//import { fetchRoleMapping } from "../../../api/content/BiomarkerApi";
import { initialLeftAsideState, repositoriesNode } from "../../../redux/reducers/biomarker";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { biomarkerIsFetching, biomarkerIsFirstRender, biomarkerPopupTreeName } from "../../../redux/selectors/selectors";
import { setQuickSearchSelectedTerms } from '../../../redux/actions/QuickSearchActions';

export const Biomarker = ({ selectedRepositories }) => {
    const popupTreeName = useSelector(biomarkerPopupTreeName);
    const isFetching = useSelector(biomarkerIsFetching);
    const isFirstRender = useSelector(biomarkerIsFirstRender);

    const showToast = (detail, summary = 'Term added', severity = 'success', life = 5000, sticky = false) => {
        const msg = { detail, sticky, summary, severity, life };
        toast.current.show(msg);
    }

    const clearToast = () => toast.current.clear();

    const dispatch = useDispatch();

    const getRoleMapping = useCallback(async () => {
        try {
            const roleMapping = await fetchBiomarkerRoleMapping();
            const nodes = addKeysToRoles(roleMapping);
            const nodesWithRepositories = [repositoriesNode, ...nodes];

            dispatch(setBiomarkerRoleMapping(roleMapping));
            dispatch(setBiomarkerNodes(nodesWithRepositories));
            dispatch(setBiomarkerHeadersStructureSkeleton(nodesWithRepositories));
        } catch (e) {
            showToast(`Something went wrong while loading data\n ${e} `, 'Error occurred', 'error', 10000);
        }
    }, [dispatch]
    )

    useEffect(() => {
        // set available repos and select all on default
        const availableRepos = selectedRepositories.filter(repo =>
            repo.active && repo.features.includes("BIOMARKER_SEARCH"));

        if (availableRepos.length) {
            dispatch(setReposWithBiomarkerSearchFeature(availableRepos));
            dispatch(setBiomarkerSelectedReposForSearch(availableRepos));
        }
    }, [dispatch, selectedRepositories]);

    useEffect(() => {
        if (isFirstRender) {
            getRoleMapping();
        }
    }, [getRoleMapping, isFirstRender]);

    // reset state after unmounting
    useEffect(() => {
        dispatch(setQuickSearchSelectedTerms({ newTerms: [] }));
        dispatch(resetBiomarkerState());
    }, [dispatch]);

    // close opened filters after unmounting
    useEffect(() => { dispatch(setBiomarkerLeftAsideExpandedKeys(initialLeftAsideState)) }, [])

    // popup options
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [popupIsLoading, setPopupIsLoading] = useState(false);

    // global options
    const toast = useRef(null);
    const [targetToScroll, setTargetToScroll] = useState(null);

    // save popup
    const [isSaveDialogVisible, setIsSaveDialogVisible] = useState(false);

    const onHide = () => setIsDialogVisible(false);

    const popupFooter = (
        <div style={{ paddingTop: 5 }}>
            <Button label="Close" className="p-button-secondary p-button-sm" onClick={onHide} />
        </div>
    );

    const popupHeader = popupTreeName ? `Select ${popupTreeName}` : '';

    const onHideSaveDialog = () => {
        setIsSaveDialogVisible(false);
    }

    return (
        <LoadingOverlay
            active={isFetching}
            spinner={true} >
            <Toast ref={toast} />

            <PageDivider
                leftAside={<BiomarkerLeftAside
                    setIsDialogVisible={setIsDialogVisible}
                    setPopupIsLoading={setPopupIsLoading}
                    showToast={showToast}
                    setTargetToScroll={setTargetToScroll} />}
                rightAside={
                    <BiomarkerRightAside
                        showToast={showToast}
                        setIsSaveDialogVisible={setIsSaveDialogVisible}
                        clearToast={clearToast}
                    />
                }
            >

                <Dialog header={popupHeader} visible={isDialogVisible} onHide={onHide} dismissableMask
                    breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw' }}
                    footer={popupFooter} blockScroll className='styledDialog'>

                    <BiomarkerPopup
                        loading={popupIsLoading}
                        showToast={showToast}
                        targetToScroll={targetToScroll}
                    />
                </Dialog>

                <SaveDialog isSaveDialogVisible={isSaveDialogVisible} onHideSaveDialog={onHideSaveDialog} />
            </PageDivider>
        </LoadingOverlay>
    );
};
