const SpecifyTermColumn = ({ 
    data,
    isText,
    onSpecifyTextType,
    onSpecifyConcepts
}) => {

    return (
        <div className="flex align-items-center h-full">
            {isText ? (
                <a onClick={() => onSpecifyTextType(data)}>Text type</a>
            ) : (
                <div className="flex flex-column gap-2">
                    <div>
                        <a onClick={() => onSpecifyConcepts(data)}>Concepts</a>
                    </div>
                    <div>
                        <a onClick={() => onSpecifyTextType(data)}>Text type</a>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SpecifyTermColumn;