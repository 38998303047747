import { FilterActions } from '../actions/FilterActions';


const initialState = {
  filterDefinitions: {},
  repositoryFilterMap: {},
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FilterActions.FilterDefinitions: {
      const filterDefinitions = action.payload ?? {};
      return {
        ...state,
        filterDefinitions,
      };
    }

    case FilterActions.RepositoryFilterMap: {
      const repositoryFilterMap = action.payload ?? {};
      return {
        ...state,
        repositoryFilterMap,
      };
    }

    default:
      return state;
  }
};

export default filtersReducer;
