import { sendApiRequest, sendExportToFileApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';
import { STRUCTURE_FORMAT_MOL, STRUCTURE_FORMAT_RXN } from '../../components/webapi/chemistry/utils/chemistrySearchConstants';
import { RESULT_IDENTIFIER_TYPE_OCID } from '../../components/webapi/chemistry/utils/chemistryResults';
import { SEARCH_TYPE_EXACT, SEARCH_TYPE_SIMILARITY } from '../../components/webapi/chemistry/utils/chemistryDBs';
import { isArrayEmpty } from '../../components/webapi/util';
import { createExportCenterEntryName, EXPORT_CENTER_CATEGORY_CHEMISTRY } from '../../components/webapi/util/exportCenter';


/**
 * Creates an object for a compound search request.
 * @param {*} structure input structure in mol format
 * @param {*} filters 
 * @param {*} format 
 * @param {*} searchType search type, e.g. FULL, SUBSTRUCTURE, SIMILARITY
 * @param {*} maxNumOfResults maximum number of returned matches
 * @param {*} nextPageKey 
 * @returns 
 */
export const createMatchingCompoundStructuresRequest = (structure, filters, format, searchType, maxNumOfResults, nextPageKey, withMixtures, sortByField, sortDirection) => {
    const req = {
        searchType: searchType,
        maxNumOfResults: maxNumOfResults,
        searchParameters: filters ? { ...filters } : {},
    };
    if (nextPageKey) {
        req.searchParameters.nextPageKey = nextPageKey;
    }
    if (format === STRUCTURE_FORMAT_MOL) {
        req.searchParameters.mol = btoa(structure);
    }
    if (withMixtures) {
        req.searchParameters.includeMixtures = withMixtures;
    }
    if (sortByField) {
        req.searchParameters.orderBy = sortByField;
    }
    if (sortDirection) {
        req.searchParameters.orderDirection = sortDirection;
    }
    return req;
}

/**
 * Creates an object for a reaction search request.
 * @param {*} structure 
 * @param {*} filters 
 * @param {*} format 
 * @param {*} searchType 
 * @param {*} reactionParts 
 * @param {*} maxNumOfResults 
 * @param {*} nextPageKey 
 * @returns 
 */
export const createMatchingMergedReactionsRequest = (structure, filters, format, searchType, reactionParts, maxNumOfResults, nextPageKey) => {

    const req = {
        searchType: searchType,
        maxNumOfResults: maxNumOfResults,
        searchParameters: filters ? { ...filters } : {}
    };
    if (nextPageKey) {
        req.searchParameters.nextPageKey = nextPageKey;
    }
    if (reactionParts) {
        req.searchParameters.reactionParts = reactionParts;
    }
    if (format === STRUCTURE_FORMAT_RXN) {
        req.searchParameters.rxn = btoa(structure);
    }
    else {
        req.searchParameters.mol = btoa(structure);
    }

    return req;
}

/**
 * Creates an object for a structure to image request.
 * @param {string} structure structure, e.g. in SMILES format
 */
export const createStructureToImageRequestV2 = (structure, height, width, format) => {
    return createStructuresToImagesRequestV2([structure], height, width, format);
}

/**
 * Creates an object for a structures to images request.
 * @param {string} structures structures, e.g. in SMILES format
 */
export const createStructuresToImagesRequestV2 = (structures, height, width, format, queryMol) => {

    const request = {
        structures: structures,
        aromatize: true,
        removeExplicitHydrogens: true,
        clean2D: true,
    };

    if (format) {
        request.format = format;
    }
    if (height) {
        request.height = height;
    }
    if (width) {
        request.width = width;
    }
    if (queryMol) {
        request.queryMol = queryMol;
    }

    return request;
}

/**
 * Creates an object for a structure conversion request.
 * 
 * @param {string} structure input structure
 * @param {string} inFormat input structure format, e.g. SMILES, MRV, MOL
 * @param {string} outFormat 
 */
export const createStructureToFormatRequestV2 = (structure, inFormat, outFormat) => {
    return {
        structure: structure,
        inputStructureFormat: inFormat,
        outputStructureFormat: outFormat
    };
}

/**
 * Searches for matching compounds.
 * @param {*} request 
 * @param {*} compoundService 
 * @param {*} isExport 
 * @returns 
 */
export const fetchMatchingCompoundStructuresV2 = async (request, compoundService, isExport) => {
    //console.log('***request: ', request);
    //console.log('*** compoundService: ', compoundService);
    //console.log('*** isExport: ', isExport);

    let repositoryID;
    if (compoundService?.repositoryID) {
        repositoryID = compoundService.repositoryID;
    }
    else if (compoundService?.index) {
        request.searchParameters.index = compoundService.index;
    }

    switch (compoundService?.service) {

        // --- ChemAxon service --- //
        case APP_PROPERTIES.CHEMISTRY.CHEMAXON: {
            //console.log('-> COMP CA');
            const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/compound/search`, request);

            if (result.status === 'SUCCESS') {
                if (result.payload) {

                    const ocids = result.payload.ocids;

                    const concepts = {};
                    const hits = [];
                    if (ocids) {
                        for (var i = 0; i < ocids.length; i++) {
                            concepts[ocids[i]] = {};
                            hits.push({ ocid: ocids[i] });
                        }
                    }
                    const payload = {
                        ...result.payload,
                        type: compoundService.type, // SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE
                        ocids: concepts,
                        hits: hits,
                        totalHits: result.payload.ocids?.length,
                        numOfEntries: hits.length,
                        isCompleteResult: true,
                        isTruncated: result.payload.ocids?.length === request.maxNumOfResults,
                        identifierType: RESULT_IDENTIFIER_TYPE_OCID // should be checked
                    };
                    return ({ status: 'SUCCESS', payload: payload });
                }
                else {
                    return ({ status: 'FAILED', message: 'Error fetching compound structures.' });
                }
            }
        }
            break;

        // --- OntoChem service --- //
        case APP_PROPERTIES.CHEMISTRY.OC: {

            if (isExport) {
                request.searchParameters.name = createExportCenterEntryName(EXPORT_CENTER_CATEGORY_CHEMISTRY);
            }

            let url = isExport ?
                `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry-export/molecules/${request.searchType}${repositoryID ? `?repositoryId=${repositoryID}` : ''}` :
                `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry-search/${request.searchType}?numberHits=${request.maxNumOfResults}${repositoryID ? `&repositoryId=${repositoryID}` : ''}`;

            const result = await sendApiRequest('POST', url, request.searchParameters);

            if (result?.status === 'SUCCESS') {
                if (isExport) {
                    return result;
                }
                else if (result.payload) {

                    const hits = result.payload.hits;
                    const numOfCurrResults = hits ? hits.length : 0;
                    const concepts = {};

                    hits?.forEach(hit => {
                        hit.identifier = hit.ocid;
                        concepts[hit.ocid] = {};
                    });
                    const payload = {
                        ...result.payload,
                        type: compoundService.type,
                        ocids: concepts,
                        numOfEntries: hits?.length,
                        identifierType: RESULT_IDENTIFIER_TYPE_OCID // should be checked
                    };
                    if (numOfCurrResults === 0) { // check hits?
                        payload.totalHits = 0;
                    }
                    else if (!result.payload.numberFingerprintHits && !result.payload.totalHits && !result.payload.nextPageKey) {
                        payload.totalHits = numOfCurrResults;
                    }
                    else if (result.payload.numberFingerprintHits && (request.searchType === SEARCH_TYPE_EXACT || request.searchType === SEARCH_TYPE_SIMILARITY)) {
                        payload.totalHits = result.payload.numberFingerprintHits;
                    }
                    //console.log('payload: ', payload);
                    return ({ status: 'SUCCESS', payload: payload });
                }
                else {
                    return ({ status: 'FAILED', message: 'Error fetching compound structures.' + result.message });
                }
            } else if (result.status === 'FAILED') {
                return ({ status: 'FAILED', message: 'Error fetching compound structures.' + result.message });
            }
        }
            break;

        case APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI_PRODUCTS: {
            //console.log('-> BQ SAVI COMPOUNDS');
            if (isExport) {
                request.searchParameters.name = createExportCenterEntryName(EXPORT_CENTER_CATEGORY_CHEMISTRY);
            }

            let url = isExport ?
                `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry-export/molecules/${request.searchType}${repositoryID ? `?repositoryId=${repositoryID}` : ''}` :
                `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry-search/${request.searchType}?numberHits=${request.maxNumOfResults}${repositoryID ? `&repositoryId=${repositoryID}` : ''}`;

            const result = await sendApiRequest('POST', url, request.searchParameters);

            if (result.status === 'SUCCESS') {
                if (isExport) {
                    return result;
                }
                else if (result.payload) {

                    const hits = result.payload.hits;
                    const numOfCurrResults = hits ? hits.length : 0;

                    hits?.forEach(hit => {
                        hit.preferredName = hit.ocid;
                        hit.extendedData = { ...hit };
                        hit.extendedData.smiles = [hit.smiles]
                        delete hit.ocid;
                    })

                    result.payload.type = compoundService.type;
                    result.payload.numOfEntries = hits?.length;

                    if (numOfCurrResults === 0) {
                        result.payload.totalHits = 0;
                    }
                    else if (!result.payload.numberFingerprintHits && !result.payload.totalHits && !result.payload.nextPageKey) {
                        result.payload.totalHits = numOfCurrResults;
                    }
                    else if (result.payload.numberFingerprintHits && (request.searchType === SEARCH_TYPE_EXACT || request.searchType === SEARCH_TYPE_SIMILARITY)) {
                        result.payload.totalHits = result.payload.numberFingerprintHits;
                    }
                    return ({ status: 'SUCCESS', payload: result.payload });
                }
                else {
                    return ({ status: 'FAILED', message: 'Error fetching compound structures.' + result.message });
                }
            } else if (result.status === 'FAILED') {
                return ({ status: 'FAILED', message: 'Error fetching compound structures.' + result.message });
            }
        }
            break;

        default: // do nothing
    }

    return { status: 'FAILED', message: 'Error fetching compound structures.' };
}

/**
 * Searches for matching reactions.
 * @param {*} request 
 * @param {*} reactionService 
 * @returns 
 */
export const fetchMatchingMergedReactions = async (request, reactionService) => {

    let repositoryID;
    /*if (reactionService?.repositoryID) {
        repositoryID = reactionService.repositoryID;
    }
    else*/ if (reactionService?.index) {
        request.searchParameters.index = reactionService.index;
    }

    switch (reactionService?.service) {

        case APP_PROPERTIES.CHEMISTRY.XFACT_DB_RXN: {

            let url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry-search/reactions/${request.searchType}?numberHits=${request.maxNumOfResults}${repositoryID ? `&repositoryId=${repositoryID}` : ''}`;
            const result = await sendApiRequest('POST', url, request.searchParameters);
            //console.log('no results: ', result);

            if (result?.payload) {
                result.payload.type = reactionService.type;
                if (!result.payload.reactions) {
                    result.payload.reactions = [];
                }
                if (!result.payload.nextPageKey) {
                    delete result.payload.nextPageKey;
                }
                if (!result.payload.numberFingerprintHits) {
                    delete result.payload.numberFingerprintHits;
                }
                if (result.payload.numberFingerprintHits && result.payload.totalHits) {
                    delete result.payload.totalHits;
                }

                if (isArrayEmpty(result.payload.reactions)) {
                    result.payload.totalHits = 0;
                }
                else if (!result.payload.numberFingerprintHits && !result.payload.totalHits && !result.payload.nextPageKey) {
                    result.payload.totalHits = result.payload.reactions?.length;
                }
                else if (result.payload.numberFingerprintHits && (request.searchType === SEARCH_TYPE_EXACT || request.searchType === SEARCH_TYPE_SIMILARITY)) {
                    result.payload.totalHits = result.payload.numberFingerprintHits;
                }

                result.payload.numOfEntries = result.payload.reactions.length;
            }
            //console.log('result: ', result);
            return result;
        }

        case APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI: {
            //console.log('-> BQ SAVI REACTIONS');
            //request.searchParameters.index = 'savi';

            let url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry-search/reactions/${request.searchType}?numberHits=${request.maxNumOfResults}${repositoryID ? `&repositoryId=${repositoryID}` : ''}`;
            const result = await sendApiRequest('POST', url, request.searchParameters);
            //console.log('no results: ', result);

            if (result?.payload) {
                result.payload.type = reactionService.type;
                if (!result.payload.reactions) {
                    result.payload.reactions = [];
                }
                if (!result.payload.nextPageKey) {
                    delete result.payload.nextPageKey;
                }
                if (!result.payload.numberFingerprintHits) {
                    delete result.payload.numberFingerprintHits;
                }
                if (result.payload.numberFingerprintHits && result.payload.totalHits) {
                    delete result.payload.totalHits;
                }

                if (isArrayEmpty(result.payload.reactions)) {
                    result.payload.totalHits = 0;
                }
                else if (!result.payload.numberFingerprintHits && !result.payload.totalHits && !result.payload.nextPageKey) {
                    result.payload.totalHits = result.payload.reactions?.length;
                }
                else if (result.payload.numberFingerprintHits && (request.searchType === SEARCH_TYPE_EXACT || request.searchType === SEARCH_TYPE_SIMILARITY)) {
                    result.payload.totalHits = result.payload.numberFingerprintHits;
                }

                result.payload.numOfEntries = result.payload.reactions.length;
            }
            //console.log('result: ', result);
            return result;
        }

        default: // do nothing
    }

    return { status: 'FAILED', message: 'Error fetching reactions.' };
}

/**
 * Converts a structure to a different format.
 * @param {*} request 
 * @returns 
 */
export const convertStructureToFormatV2 = async (request) => {

    let structure = request.structure;
    let inFormat = request.inputStructureFormat;
    let outFormat = request.outputStructureFormat;

    let url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry/conversion?from=${inFormat}&to=${outFormat}`;

    const result = await sendApiRequest('POST', url, {
        structure: structure
    });

    if (result.status === 'SUCCESS') {
        if (result.payload) {
            return ({ status: 'SUCCESS', payload: result.payload.structure });
        }
        else {
            return ({ status: 'FAILED', message: 'Could not convert structure.' });
        }
    }

    return { status: 'FAILED', message: 'Could not convert structure.' };
}

/**
 * Converts structure string to byte[] image.
 * @param {string} request 
 */
export const convertStructureToImageV2 = async (request) => {

    const result = await convertStructuresToImagesV2(request);

    if (result.status === 'SUCCESS') {
        const structure = request.structures[0];
        if (result.payload.images && result.payload?.images[structure]) {
            return ({ status: 'SUCCESS', payload: result.payload.images[structure] });
        }
    }

    return ({ status: 'FAILED', message: 'Could not create structure image.' });
}

/**
 * Converts structure string to byte[] image.
 * 
 * @param {string} request 
 */
export const convertStructuresToImagesV2 = async (request) => {

    // TODO: check if this breaks anything
    if (isArrayEmpty(request.structures)) {
        return ({ status: 'SUCCESS', payload: { images: {} } });
    }

    let structures = request.structures;
    let width = request.width ? request.width : 500;
    let height = request.height ? request.height : 500;
    let format = request.format ? request.format : 'svg'; // jpg

    const req = {
        structures: structures,
        width: width,
        height: height,
        format: format
    };
    if (request.queryMol) {
        req.mol = btoa(request.queryMol);
    }

    let url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry/images`;

    const result = await sendApiRequest('POST', url, req);

    if (result.status === 'SUCCESS') {
        if (result.payload) {
            return ({ status: 'SUCCESS', payload: { images: result.payload } });
        }
    }

    return ({ status: 'FAILED', message: 'Could not create structure images.' });
}

/**
 * Uses ChemAxon webservices via middleware. Use for mrv format.
 * @param {*} structure 
 * @param {*} inputFormat 
 * @param {*} outputFormat 
 * @returns 
 */
export const convertStructureToFormatWS = async (structure, inputFormat, outputFormat) => {
    const request = { structure: structure, inputFormat: inputFormat, parameters: outputFormat }
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/capi/MolExport`, request);
    if (result.status === 'SUCCESS' && result?.payload?.structure) {
        return { status: 'SUCCESS', payload: result.payload.structure };
    }
    else {
        return { status: 'FAILED', message: "Could not convert input into valid structure." };
    }
}

/**
 * Returns the type of the identifier: CAS, OCID, InChI, InChIKey, SMILES or unknown.
 * @param {string} identifier 
 * @returns 
 */
export const determineIdentifierType = async (identifier) => {
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/chemistry/identifier-check`, {
        identifier: identifier
    });
    return result;
}

/**
 * Converts structure string to byte[] image.
 * 
 * @param {string} request 
 */
export const fetchMculeData = async (request) => {
    const url = `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/mcule/urls`;
    const result = await sendApiRequest('POST', url, request);
    //console.log('result: ', result);
    if (result.status === 'SUCCESS' && result.payload) {
        return ({ status: 'SUCCESS', payload: { mculeLinks: result.payload } });
    }
    else if (result?.message) {
        return ({ status: 'FAILED', message: 'Could not retrieve Mcule links. ' + result.message });
    }
    return ({ status: 'FAILED', message: 'Could not retrieve Mcule links.' });
}

/**
 * Fetches OCIDs for given SMILES of chemical compounds.
 * @param {string} smiles the SMILES of chemical compounds
 */
export const fetchOcidsForSmiles = async (smiles) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocid/smiles`, {
        "smiles": smiles
    });

    return result;
}

/**
 * Fetches OCIDs for given InChIs of chemical compounds.
 * @param {string} inchis the InChIs of chemical compounds
 */
export const fetchOcidsForInchis = async (inchis) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocid/inchis`, {
        "inchi": inchis
    });

    return result;
}

/**
 * Fetches OCIDs for given InChI keys of chemical compounds.
 * @param {string} inchikeys the InChI keys of chemical compounds
 */
export const fetchOcidsForInchiKeys = async (inchikeys) => {
    // --- fetch OCID --- //
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocid/inchikeys`, {
        "inchikey": inchikeys
    });

    return result;
}

/**
 * Exports all chemical compounds for array of OCIDs to a structure file in .smi format.
 * @param {Array} ocids the compound OCIDs
 */
export const exportCompoundsToSmiFile = async (ocids) => {
    await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/compounds/smi`, 'chemistry_search_structures.smi', {
        ocids: ocids
    });
}

/**
 * Exports all chemical compounds for array of OCIDs to a structure file in .sdf format.
 * @param {Array} ocids the compound OCIDs
 */
export const exportCompoundsToSdfFile = async (ocids) => {
    await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/compounds/sdf`, 'chemistry_search_structures.sdf', {
        ocids: ocids
    });
}