import { Component } from 'react';


class ReactionSourceSnippet extends Component {

    render() {

        const { reactionData } = this.props;

        return reactionData ?
            <div style={{ color: '#757575', lineHeight: 1.5 }}>
                <div>
                    {reactionData.sourcesection ? <b>Section: {reactionData.sourcesection} </b> : null}
                    {reactionData.context ? reactionData.context : null}
                </div>
                {/*<div>
                    Yield: {reactionData.yield}
                 </div>*/}
            </div> : null
    }
}
export default ReactionSourceSnippet