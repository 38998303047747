import "./ReadCubePapersLink.css";
import { APP_PROPERTIES } from "../../../properties";

// @todo use typescript and enums
export const ID_TYPE_DOI = 'data-doi';
export const ID_TYPE_PMID = 'data-pmid';
export const ID_TYPE_PMCID = 'data-pmcid';
export const ID_TYPE_ARXIV = 'data-arxiv';
export const ID_TYPE_PATENT_ID = 'data-patentid';


const ReadCubePapersLink = ({
    type,
    identifier,
    compactView
}) => {

    const attributes =
        APP_PROPERTIES.INCLUDE_READCUBE ?
            type && identifier ?
                { [type]: identifier }
                :
                identifier ?
                    {
                        [ID_TYPE_DOI]: identifier,
                        [ID_TYPE_PMID]: identifier,
                        [ID_TYPE_PMCID]: identifier,
                        [ID_TYPE_ARXIV]: identifier,
                        [ID_TYPE_PATENT_ID]: identifier,
                    }
                    : null
            : null;

    return attributes ?
        <span className={`readcube-buttons-container ${compactView ? 'compact' : ''}`}>
            {compactView && <label className="readcube-buttons-label">ReadCube:</label>}
            <span id={`readcube_access_${identifier}`}
                className="__readcube-access-button"
                {...attributes}>
            </span>
            <span id={`readcube_library_${identifier}`}
                className="__readcube-library-button"
                {...attributes}>
            </span>
        </span> : null;
}

export default ReadCubePapersLink;