import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { fetchAllQueries } from "../../../../api/TestApi";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import { isArrayEmpty } from "../../util";
import { Button } from "primereact/button";
import LoadingOverlay from "@speedy4all/react-loading-overlay";


class SavedSearchesDialog extends Component {

    constructor(props) {
        super(props);

        this.state = { savedSearches: [], selectedSearch: null, globalFilterWatchlists: '', fetching: false };

        this.growl = createRef();
    }

    componentDidMount() {
        //this.fetchSavedSearches();
    }

    fetchSavedSearches = async () => {
        this.setState({
            fetching: true
        }, async () => {
            try {
                const response = await fetchAllQueries();
                //console.log('fetchAllQueries: ', response);
                const filteredSearches = response.payload.content.filter(({ type }) => this.props.allowedQueryTypes?.includes(type));
                filteredSearches.forEach(search => { search.val = search.value; delete search.value; });
                //filteredSearches.forEach(search => { delete search.value;  });
                //console.log('this.props.allowedQueryTypes: ', this.props.allowedQueryTypes);
                //console.log('filteredSearches: ', filteredSearches);
                this.setState({
                    savedSearches: filteredSearches,
                    //selectedSearch: null, //,!isArrayEmpty(filteredSearches) ? filteredSearches[0] : null,
                    globalFilterWatchlists: '',
                    fetching: false
                });
            } catch (e) {
                console.error(e);
            }
        })
    }

    savedSearchTemplate = (rowData) => {
        return <div>
            <span className="clampingContainer line-clamp line-clamp-1-line">
                {rowData.fullName ? rowData.fullName : rowData.name}
            </span>
            {rowData.category && rowData.val && rowData.category === 'cat_sentence_analysis_search' ?
                <span className="clampingContainer line-clamp line-clamp-1-line"
                    style={{ color: 'gray', paddingTop: 5 }}>
                    {rowData.val}
                </span> : null
            }
            {rowData.description ?
                <span className="clampingContainer line-clamp line-clamp-1-line"
                    style={{ color: 'gray', paddingTop: 5 }}>
                    {rowData.description}
                </span> : null
            }
            {rowData.owner ?
                <span style={{ display: 'block', paddingTop: 5 }}>
                    <label style={{ color: '#757575' }}>{`Owner: ${rowData.owner?.lastName}, ${rowData.owner?.forename}`}</label>
                </span>
                : null
            }
        </div>
    }

    createdTemplate = (rowData) => {

        return <div>
            <span style={{ marginBottom: 5 }}>
                {rowData.created ? `${moment(rowData.created + "Z").format("YYYY-MM-DD")}` : ''}
            </span>
        </div>
    }


    modifiedTemplate = (rowData) => {
        return <div>
            <span style={{ marginBottom: 5 }}>
                {rowData.modified ? `${moment(rowData.modified + "Z").format("YYYY-MM-DD")}` : ''}
            </span>
        </div>
    }

    collectionsTemplate = (rowData) => {
        return <div>
            <span style={{ marginBottom: 5 }}>
                {rowData.collections}
            </span>
        </div>
    }

    editableTemplate = (rowData) => {
        //console.log(rowData)
        let editable = true
        /*if (rowData.queryCollectionList && rowData.queryCollectionList.length > 0) {
            rowData.queryCollectionList.every(list => {
                if (list.shared && !list.writable) {
                    editable = false
                    return false
                }
                return true
            })
        }*/
        if (!rowData.isCurrentUserOwner) {
            editable = false
        }
        return <div>
            <span style={{ marginBottom: 5 }}>
                {editable ? 'Editable' : 'Not editable'}
            </span>
        </div>
    }


    renderGlobalSearchField = () => {
        return <div className='grid' style={{
            marginRight: 0,
            paddingTop: 18, paddingBottom: 10
        }}>
            <div style={{ marginLeft: 10, marginRight: 10, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 6, paddingLeft: 5, fontSize: 18 }}
                    title="Filter searches by name or search collection">
                    {'search'}
                </i>
                <InputText
                    style={{
                        border: 'none',
                        width: '83%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'inline-block',
                        whiteSpace: 'nowrap'
                    }}
                    type="search"
                    autoComplete="off"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterWatchlists: e.target.value })}
                    placeholder="Filter searches by name or search collection" />
            </div>
        </div>;
    }

    renderFooter = () => {
        return <div className="textAlignRight" style={{ paddingTop: 5 }}>
            <Button label="Close" className="p-button-secondary p-button-sm" onClick={(e) => {
                this.setState({ selection: null, savedSearches: [] });
                this.props.onHide(e);
            }} />
            <Button label="Load" className="primaryButton p-button-sm"
                onClick={(e) => {
                    this.setState({ savedSearches: [] });
                    this.props.onSelect(this.state.selection)
                }}
                disabled={!this.state.selection} />
        </div>
    }

    render() {

        const { displayDialog, onHide } = this.props;
        const loadingMessage = "Loading saved searches"
        const loading = this.state.fetching

        let tableData = [];
        if (!isArrayEmpty(this.state.savedSearches)) {
            this.state.savedSearches.forEach(search => {
                search.collections = '-';
                if (search.queryCollectionList && !isArrayEmpty(search.queryCollectionList)) {
                    search.collections = search.queryCollectionList.map(list => list.name).join(', '); // ·
                }
                tableData.push(search);
            })
        }

        return (
            <>
                <Toast ref={(el) => this.growl = el} />
                <LoadingOverlay
                    active={loading}
                    spinner={true}
                    className="fullPage_Z"
                    text={loadingMessage} >
                    <Dialog
                        header={!loading ? `Load saved search (${this.state.savedSearches ? this.state.savedSearches.length : 0})` : 'Load saved search'}
                        footer={this.renderFooter()}
                        onShow={() => {
                            this.setState({ savedSearches: [], selection: null }, () => this.fetchSavedSearches());
                        }}
                        onHide={() => this.setState({ savedSearches: [] }, () => onHide())}
                        visible={displayDialog}
                        blockScroll
                        breakpoints={{ '960px': '70vw', '640px': '90vw' }}
                        style={{ width: "80vw" }}
                        className='styledDialog'>
                        {!isArrayEmpty(this.state.savedSearches) && !loading ?
                            <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 30, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                {this.renderGlobalSearchField()}
                                <DataTable
                                    selection={this.state.selection}
                                    selectionMode="radiobutton"
                                    onSelectionChange={e => this.setState({ selection: e.value })}
                                    className="bigFilterField"
                                    scrollable
                                    removableSort={true}
                                    sortField="modified"
                                    sortOrder={-1}
                                    value={tableData}
                                    responsive='true'
                                    paginator={false}
                                    globalFilter={this.state.globalFilterWatchlists}
                                    dataKey="id"
                                    emptyMessage="No saved searches found"
                                >
                                    <Column field="check" selectionMode='single' style={{ width: '4em', textAlign: 'center', fontWeight: 'normal' }} />
                                    <Column field="fullName" header="Search" sortable={true} body={this.savedSearchTemplate}
                                    /* filter filterMatchMode='contains' filterBy="fullName"
                                     filterPlaceholder={`Filter by full name`}*/
                                    //style={{ width: "30%" }} 
                                    />
                                    <Column field="collections" header="In search collections" sortable={true} body={this.collectionsTemplate} />
                                    <Column header="Original search" sortable={false} body={this.editableTemplate} style={{ width: 140 }} />
                                    <Column field="created" header="Added" sortable={true} body={this.createdTemplate} style={{ width: 100, textAlign: 'left' }} />
                                    <Column field="modified" header="Last modified" sortable={true} body={this.modifiedTemplate} style={{ width: 130, textAlign: 'left' }} />
                                </DataTable>
                            </div>

                                :
                                <div style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 30, paddingTop: 15, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                    No saved searches available
                                </div>
                        }
                    </Dialog>
                </LoadingOverlay>
            </>
        );
    }
}

export default SavedSearchesDialog;