import { isArrayEmpty } from "../../util";
import {
    FREETEXT_EXACT, FREETEXT_WITH_VARIANTS,
    SEARCH_FILTER_ID_TERMLOC, SEARCH_FILTER_ID_SEARCH_MODE, SEARCH_FILTER_ID_CONCEPT_DISTANCE, SEARCH_FILTER_TYPE_IPC_DOMAIN,
    SEARCH_FILTER_TYPE_TERMLOC, SEARCH_FILTER_TYPE_INPUT_FREE, SEARCH_FILTER_TYPE_DROPDOWN_STATIC, SEARCH_FILTER_TYPE_DATE_RANGE,
    SEARCH_FILTER_TYPE_FACETS_WITH_INPUT, SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY, SEARCH_FILTER_TYPE_AUTOCOMPLETE_WITH_TERMLOC,
    QUERY_TERM_TYPE_CONCEPT, SEARCH_FILTER_TYPE_INPUT_FREE_TERMLOC, SEARCH_FILTER_ID_INSDATE, WILDCARD_ASTERISK, QUERY_TERM_TYPE_TERMLOC_QUERY_TERMS,
} from "./searchConstants";
import {
    createFilterTermForMetadata, createFilterTermForDateRanges, createFilterTermForTermLocation,
    createFilterTermForIPCDomain, createFilterTermForAutocompleteTermloc, createFilterTermForFreeInputTermLocation,
    createFilterTermForBulkImport, createFilterTermForMetadataMultipleStructuredValues
} from "../../docsearch/utils/queryTerms";
import { convertQuickSearchToLatestFormat } from "../../docsearch/utils/queryConverter";
import _ from "lodash";
import { REPO_STATE_ERROR, REPO_STATE_LOADING, REPO_STATE_SUCCESS } from "../../chemistry/utils/searchResults";
import { RESPONSE_STATUS_SUCCESS } from "../../../../properties";

/**
 * Checks if certain sortField is part of sortCriteria.
 * @param {*} sortCriteria 
 * @param {*} sortField 
 * @returns 
 */
export const hasSortOption = (sortCriteria, sortField) => {
    const hasSortOption = sortCriteria && sortField && sortCriteria.some(option => option.names[0] === sortField);
    return hasSortOption;
}

/**
 * Adds wildcard entry for a given domain to query terms array.
 * @param {*} domain 
 * @param {*} queryTerms 
 * @returns 
 */
export const addAnyDomainTermToQueryTerms = (domain, queryTerms) => {

    const queryTermsNew = queryTerms ? [...queryTerms] : [];

    const mergedTerm = {
        type: QUERY_TERM_TYPE_CONCEPT,
        domains: [domain],
        ocids: null,
        queryValues: [WILDCARD_ASTERISK],
        term: ['Any']
    };
    queryTermsNew.push(mergedTerm);

    return queryTermsNew;
}

/**
 * Adds facet terms as query terms to query terms array.
 * @param {Object} domainFacets map of domain facets
 * @param {Array} queryTerms array of query terms
 * @returns new array with updated query terms
 */
export const addDomainFacetsToQueryTerms = (domainFacets, domain, queryTerms, mergeConcepts = true) => {

    const queryTermsNew = queryTerms ? [...queryTerms] : [];

    if (mergeConcepts) {
        // --- merge all concepts selected in domain domain explorer into one query term --- //
        const terms = {};
        const mergedTerm = {
            type: QUERY_TERM_TYPE_CONCEPT,
            domains: [domain],
            ocids: null,
        };
        domainFacets.forEach(facetEntry => {
            terms[facetEntry.term] = true;
        })
        mergedTerm.queryValues = Object.keys(terms);
        mergedTerm.term = mergedTerm.queryValues.join(' OR ');
        queryTermsNew.push(mergedTerm);
    }
    else {
        // --- new concepts are added as single query terms --- //
        domainFacets.forEach(facetEntry => {
            queryTermsNew.push({
                type: QUERY_TERM_TYPE_CONCEPT,
                domains: [domain],
                ocids: null,
                queryValues: [facetEntry.term],
                term: facetEntry.term,
            });
        })
    }
    //console.log('queryTermsNew: ', queryTermsNew);

    return queryTermsNew;
}

/**
 * Adds concepts as query terms to query terms array.
 * @param {Object} conceptsMap map of concepts, with OCIDs as keys
 * @param {Array} queryTerms array of query terms
 * @returns new array with updated query terms
 */
export const addConceptsToQueryTerms = (conceptsMap, queryTerms, mergeConcepts = false, isSemantic = false, allDomains = []) => {

    const queryTermsNew = queryTerms ? [...queryTerms] : [];
    const concepts = conceptsMap ? Object.values(conceptsMap) : [];

    if (mergeConcepts) {
        // --- merge all concepts selected in domain domain explorer into one query term --- //
        const ocids = {};
        const domains = {};
        const terms = {};
        const mergedTerm = {
            type: QUERY_TERM_TYPE_CONCEPT,
        };
        concepts.forEach(concept => {
            if (concept.ocid)
                ocids[concept.ocid] = true;

            domains[concept.domain] = true;
            terms[concept.preferredName] = true;
        })

        mergedTerm.domains = Object.keys(domains);
        if (!_.isEmpty(ocids))
            mergedTerm.ocids = Object.keys(ocids);

        mergedTerm.queryValues = Object.keys(terms);
        mergedTerm.prefnames = Object.keys(terms);
        mergedTerm.term = mergedTerm.queryValues.join(' OR ');

        if (isSemantic) {
            const pirDomain = getHighPiriortyDomain(allDomains, mergedTerm.domains);
            mergedTerm.id = generateUID(mergedTerm)
            mergedTerm.color = pirDomain.length > 0 ? pirDomain[0].color : null
        }

        queryTermsNew.push(mergedTerm);
    }
    else {
        // --- new concepts are added as single query terms --- //
        Object.values(concepts).forEach(concept => {
            queryTermsNew.push({
                type: QUERY_TERM_TYPE_CONCEPT,
                queryValues: [],
                term: concept.preferredName,
                domains: [concept.domain],
                ocids: [concept.ocid]
            });
        })
    }

    return queryTermsNew;
}


/**
 * 
 * @param {*} response 
 * @param {*} repository 
 * @param {*} repoStats 
 * @returns 
 */
export const addSearchResult = (response, repository, repoStats) => {

    // --- update results data for repo --- //
    const repoStatsNew = repoStats;
    let updatedData;
    if (response.status === RESPONSE_STATUS_SUCCESS && response.payload) {
        const documentData = response.payload;
        repoStatsNew.hits = repoStatsNew.hits + (documentData.documentCount > 0 ? documentData.documentCount : 0);
        updatedData = {
            status: REPO_STATE_SUCCESS,
            qryCounts: { cust: documentData.documentCount }
        }
    }
    else {
        updatedData = {
            status: REPO_STATE_ERROR,
            qryCounts: { cust: 0 },
            errorMessage: response.message || 'Error occurred'
        }
    }

    repoStatsNew.repData[repository.name] = { ...repoStatsNew.repData[repository.name], ...updatedData }
    return repoStatsNew;
}

/**
 * 
 * @param {*} activeRepositories 
 * @returns 
 */
export const createEmptySearchResult = (activeRepositories) => {

    const repoStats = { hits: 0, repData: {} };
    const reposToFetch = {};
    activeRepositories?.forEach(repo => {
        // --- collect all repos that have to be queried --- //
        reposToFetch[repo.name] = true;
        // --- create default result object for each repo --- //
        repoStats.repData[repo.name] = {
            status: REPO_STATE_LOADING,
            repositoryId: repo.id,
            label: repo.label,
            name: repo.name,
            repoDescription: repo.description,
            qryCounts: { cust: 0 }
        };
    });
    return { repoStats, reposToFetch };
}

const generateUID = (term) => {
    return `q${term.term.trim().split(' ').join('') + (term.ocids ? term.ocids?.length : term.domains[0]) + term.domains?.length}`
}

/**
 * Replaces given query term with new query term created from merged concepts.
 * @param {Object} conceptsMap map of concepts, with OCIDs as keys
 * @param {Array} queryTerms array of query terms
 * @param {Object} queryTermToReplace the query term that should be replaced
 * @returns new array with updated query terms
 */
export const replaceQueryTermWithConcepts = (conceptsMap, queryTerms, queryTermToReplace, isSemantic = false, allDomains = []) => {

    let queryTermsNew = queryTerms ? [...queryTerms] : [];
    const concepts = conceptsMap ? Object.values(conceptsMap) : [];

    // --- replace item with merged concepts --- //
    let index = -1;
    if (queryTermToReplace) {
        //queryTermsNew = removeValueFromArray(queryTermsNew, queryTermToReplace);
        index = queryTermsNew.indexOf(queryTermToReplace);

        // --- merge all concepts selected in domain domain explorer into one query term --- //
        const ocids = {};
        const domains = {};
        const terms = {};
        const mergedTerm = {
            type: QUERY_TERM_TYPE_CONCEPT,
        };
        concepts.forEach(concept => {
            ocids[concept.ocid] = true;
            domains[concept.domain] = true;
            terms[concept.preferredName] = true;
        })
        mergedTerm.domains = Object.keys(domains);
        mergedTerm.ocids = Object.keys(ocids);
        mergedTerm.queryValues = Object.keys(terms);
        mergedTerm.prefnames = Object.keys(terms);
        mergedTerm.term = mergedTerm.queryValues.join(' OR ');

        //console.log('mergedTerm: ', mergedTerm);
        if (index >= 0) {
            if (isSemantic) {
                const pirDomain = getHighPiriortyDomain(allDomains, mergedTerm.domains);
                mergedTerm.id = generateUID(mergedTerm)
                mergedTerm.color = pirDomain.length > 0 ? pirDomain[0].color : queryTermsNew[index].color
            }
            queryTermsNew[index] = mergedTerm;
        }
        else {
            queryTermsNew.push(mergedTerm);
        }
    }

    return queryTermsNew;
}

const getHighPiriortyDomain = (allDomains, domain) => {
    return allDomains.filter((dom) => domain.some((item) => item === dom.name));
}
export const addFiltersToQueryTerms = (filterInputValues, filter, queryTerms) => {

    //console.log('+++filterInputValues: ', filterInputValues);
    //console.log('+++filter: ', filter);
    //console.log('+++queryTerms: ', queryTerms);

    const queryTermsNew = queryTerms ? [...queryTerms] : [];

    if (filterInputValues && filter) {
        switch (filter.type) {

            case SEARCH_FILTER_TYPE_INPUT_FREE: {
                const filterTerm = createFilterTermForMetadata(filter, filterInputValues.input, filterInputValues.input, filterInputValues.booster, filter.fuzziness);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_DROPDOWN_STATIC: {
                const filterTerm = createFilterTermForMetadata(filter, filterInputValues.value.filterValue, filterInputValues.value.filterValueLabel, filterInputValues.value.booster);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_TERMLOC: {
                const filterTerm = createFilterTermForTermLocation(filter, filterInputValues.value.filterValue);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_IPC_DOMAIN: {
                const filterTerm = createFilterTermForIPCDomain(filter, filterInputValues.value.filterValue);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_DATE_RANGE: {
                const filterTerm = createFilterTermForDateRanges(filter, filterInputValues.startDate, filterInputValues.endDate);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_FACETS_WITH_INPUT:
            case SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY: {
                const filterTerm = createFilterTermForMetadataMultipleStructuredValues(filter, filterInputValues.selectedFilterEntries);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_AUTOCOMPLETE_WITH_TERMLOC: {
                const filterTerm = createFilterTermForAutocompleteTermloc(filter, filterInputValues.termlocQueryTerms);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            case SEARCH_FILTER_TYPE_INPUT_FREE_TERMLOC: {
                const filterTerm = createFilterTermForFreeInputTermLocation(filter, [filterInputValues.input]);
                if (filterTerm) { queryTermsNew.push(filterTerm); }
                break;
            }

            default: // do nothing
        }
    }

    return queryTermsNew;
}

export const addBulkIDsToQueryTerms = (filter, bulkIDType, bulkIDs, queryTerms, queryTermToReplace) => {
    let queryTermsNew = queryTerms ? [...queryTerms] : [];
    let index = -1;
    if (queryTermToReplace) {
        index = queryTermsNew.indexOf(queryTermToReplace);
    }
    const filterTerm = createFilterTermForBulkImport(filter, bulkIDType.id, bulkIDs);
    if (filterTerm) {
        if (index >= 0) {
            queryTermsNew[index] = filterTerm;
        }
        else {
            queryTermsNew.push(filterTerm);
        }
    }
    return queryTermsNew;
}

export const mergeQueryTerms = (queryTerms) => {

    const queryTermsNew = [];

    const ocids = {};
    const domains = {};
    const terms = {};
    const mergedTerm = {
        type: QUERY_TERM_TYPE_CONCEPT,
    };
    queryTerms?.forEach(queryTerm => {
        if (queryTerm.ocids) {
            queryTerm.ocids.forEach(ocid => { ocids[ocid] = true });
        }
        if (queryTerm.domains) {
            queryTerm.domains.forEach(domain => { domains[domain] = true });
        }
        terms[queryTerm.term] = true;
    })
    mergedTerm.domains = Object.keys(domains);
    mergedTerm.ocids = Object.keys(ocids);
    mergedTerm.queryValues = Object.keys(terms);
    mergedTerm.term = mergedTerm.queryValues.join(' OR ');
    queryTermsNew.push(mergedTerm);

    return queryTermsNew;
}

export const cleanQueryTermsForStorage = (queryTerms) => {

    return queryTerms?.map(qt => {
        //const queryTerm = { ...qt };
        delete qt.otherTerms;
        return qt;
    })
}

/**
 * Returns number of terms and concepts found in list of query terms.
 * @param {*} queryTerms 
 * @returns 
 */
export const getNumberOfTermsAndConceptsInQuery = (queryTerms) => {
    let numOfTermsInQuery = 0;
    let numOfConceptsInQuery = 0;

    queryTerms?.forEach(queryTerm => {
        if (queryTerm.type === QUERY_TERM_TYPE_CONCEPT) {
            numOfTermsInQuery++;
            if (!isArrayEmpty(queryTerm.domains) &&
                queryTerm.domains[0] !== FREETEXT_EXACT.prefix && queryTerm.domains[0] !== FREETEXT_WITH_VARIANTS.prefix) {
                numOfConceptsInQuery++;
            }
        }
    });

    return { numOfTermsInQuery, numOfConceptsInQuery };
}

/**
 * Returns number of terms and concepts found in list of query terms,
 * as well as info if query terms contain concept distance, search mode or search mode filters.
 * @param {*} queryTerms 
 * @returns 
 */
export const analyzeQuery = (queryTerms) => {
    let numOfTermsInQuery = 0;
    let numOfConceptsInQuery = 0;
    let hasTermlocation = false;
    let hasSearchMode = false;
    let hasConceptDistance = false;
    let termlocationsWithConcepts = {};

    queryTerms?.forEach(queryTerm => {
        //console.log('queryTerm: ', queryTerm);
        if (queryTerm.type === QUERY_TERM_TYPE_CONCEPT) {
            numOfTermsInQuery++;
            if (!isArrayEmpty(queryTerm.domains) &&
                queryTerm.domains[0] !== FREETEXT_EXACT.prefix && queryTerm.domains[0] !== FREETEXT_WITH_VARIANTS.prefix) {
                numOfConceptsInQuery++;
            }
        }
        if (!hasConceptDistance && queryTerm.filterID === SEARCH_FILTER_ID_CONCEPT_DISTANCE) {
            hasConceptDistance = true;
        }
        else if (!hasSearchMode && queryTerm.filterID === SEARCH_FILTER_ID_SEARCH_MODE) {
            hasSearchMode = true;
        }
        else if (!hasTermlocation && queryTerm.filterID === SEARCH_FILTER_ID_TERMLOC) {
            hasTermlocation = true;
            termlocationsWithConcepts[queryTerm.termloc] = true;
        }
        else if (queryTerm.type === QUERY_TERM_TYPE_TERMLOC_QUERY_TERMS &&
            queryTerm.termloc && !isArrayEmpty(queryTerm.ocids)) {
            termlocationsWithConcepts[queryTerm.termloc] = true;
        }
    });
    // --- add default termlocation to collection if none is defined --- //
    if (!hasTermlocation && numOfConceptsInQuery > 0) {
        termlocationsWithConcepts['document'] = true;
    }

    return { numOfTermsInQuery, numOfConceptsInQuery, hasConceptDistance, hasSearchMode, hasTermlocation, termlocationsWithConcepts };
}

/**
 * Checks query terms for logical errors, 
 * e.g. if concept distance filter is included, but there are fewer than two concepts.
 * @param {*} queryTerms 
 * @returns 
 */
export const checkQueryForErrors = (queryTerms) => {
    const errors = [];

    if (isArrayEmpty(queryTerms)) {
        return errors;
    }

    //console.log('queryTerms: ', queryTerms);
    const { numOfTermsInQuery, numOfConceptsInQuery, hasConceptDistance, hasSearchMode, hasTermlocation, termlocationsWithConcepts } =
        analyzeQuery(queryTerms);

    if (hasConceptDistance && numOfConceptsInQuery < 2) {
        errors.push('"Concept distance" filter can only be applied if the search contains at least two concepts.');
    }
    if (hasSearchMode && numOfConceptsInQuery < 1) {
        errors.push('"Search mode" filter can only be applied if the search contains at least one concept.');
    }
    if (hasTermlocation && numOfTermsInQuery < 1) {
        errors.push('"Search in" filter can only be applied if the search contains at least one search term.');
    }
    if (hasConceptDistance && Object.keys(termlocationsWithConcepts).length > 1) {
        errors.push('Concepts can only be searched for in the same section when the "Concept distance" filter is applied.');
    }

    return errors;
}

/**
 * Converts concepts to quick search 2.0 form object used in state.
 * @param {*} concepts 
 * @param {*} logicalOperator 
 * @param {*} merge 
 * @returns 
 */
export const createQuickSearchObjectFromConcepts = (concepts, logicalOperator = 'or', merge = true, synonymsOnly = false) => {

    const queryTerms = addConceptsToQueryTerms(concepts, [], merge);
    // TODO: use defined filter!
    if (synonymsOnly) {
        const searchModeFilter = { id: SEARCH_FILTER_ID_SEARCH_MODE, queryPrefix: 'smode' };
        const searchModeQueryTerm = createFilterTermForMetadata(searchModeFilter, 's', 'Concept only, with synonyms');
        queryTerms.unshift(searchModeQueryTerm);
    }

    const queryForm = { queryTerms: queryTerms, logicalOperator: logicalOperator };
    const formContent = JSON.stringify(queryForm);

    return {
        fromShortcut: true,
        formContent: formContent,
    };

    /*
    return {
        category: "cat_doc_search",
        created: "2022-02-17 17:06:09.0",
        description: "",
        edit: true,
        form: "quick",
        formContent: "{\"queryTerms\":[{\"term\":\"pest\",\"domains\":[\"t\"],\"ocids\":null,\"prefnames\":[\"Yersinia pestis\",\"Plague\"],\"type\":\"concept\",\"queryValues\":[\"pest\"]},{\"term\":\"BASF\",\"domains\":[\"t\"],\"ocids\":null,\"prefnames\":[\"BASF\"],\"type\":\"concept\",\"queryValues\":[\"BASF\"]},{\"type\":\"metadata\",\"filterID\":\"pubDate\",\"queryPrefix\":\"pdate\",\"term\":\"2020-07-02 - \",\"queryValues\":[\"2020-07-02\"]}],\"logicalOperator\":\"or\"}",
        fullName: "qs_qs2__10",
        id: 638,
        modified: "2022-02-17 17:06:09.0",
        name: "qs_qs2__10",
        notifications: [],
        queryCollectionList: [{ … }],
        type: QUERY_TYPE_QUICK_SEARCH_2.id,
        val: "+(t:\"pest\" t:\"BASF\") +pdate:\"2020-07-02\"",
    };
    */
}

/**
 * Converts concepts and term location filter to quick search 2.0 form object used in state.
 * @param {*} concepts 
 * @param {*} termloc 
 * @param {*} logicalOperator 
 * @param {*} merge 
 * @returns 
 */
export const createQuickSearchObjectFromConceptsWithTermloc = (concepts, termloc, termlocLabel, logicalOperator = 'or', merge = true, synonymsOnly = false) => {

    const queryTerms = addConceptsToQueryTerms(concepts, [], merge);
    // TODO: use defined filters!
    const termlocFilter = { id: SEARCH_FILTER_ID_TERMLOC, queryPrefix: 'termloc', filterValueLabels: { [termloc]: termlocLabel } };
    const termlocQueryTerm = createFilterTermForTermLocation(termlocFilter, termloc);
    queryTerms.unshift(termlocQueryTerm);

    if (synonymsOnly) {
        const searchModeFilter = { id: SEARCH_FILTER_ID_SEARCH_MODE, queryPrefix: 'smode' };
        const searchModeQueryTerm = createFilterTermForMetadata(searchModeFilter, 's', 'Concept only, with synonyms');
        queryTerms.unshift(searchModeQueryTerm);
    }

    const queryForm = { queryTerms: queryTerms, logicalOperator: logicalOperator };
    const formContent = JSON.stringify(queryForm);

    return {
        fromShortcut: true,
        formContent: formContent,
    };
}

/**
 * Converts cooc query input to quick search 2.0 form object used in state.
 * @param {*} coocQueryTermsLeft 
 * @param {*} coocQueryTermsRight 
 * @returns 
 */
export const createQuickSearchObjectFromCoocQueryTerms = (coocQueryTermsLeft, coocQueryTermsRight) => {

    const hasLeftQueryTerms = !isArrayEmpty(coocQueryTermsLeft);
    const hasRightQueryTerms = !isArrayEmpty(coocQueryTermsRight);

    const queryTermsLeft = hasLeftQueryTerms ? mergeQueryTerms(coocQueryTermsLeft) : [];
    const queryTermsRight = hasRightQueryTerms ? mergeQueryTerms(coocQueryTermsRight) : [];

    const queryTerms = [...queryTermsLeft, ...queryTermsRight];

    if (hasLeftQueryTerms && hasRightQueryTerms) {
        const filter = { id: SEARCH_FILTER_ID_CONCEPT_DISTANCE, queryPrefix: 'near' };
        const conceptDistanceQueryTerm = createFilterTermForMetadata(filter, 'filter', 'Max. ~60 characters ', 9);
        queryTerms.push(conceptDistanceQueryTerm);
    }

    const queryForm = { queryTerms: queryTerms, logicalOperator: 'and' };
    const formContent = JSON.stringify(queryForm);

    return {
        fromShortcut: true,
        formContent: formContent,
    };
}

/**
 * Adds the insertion date filter to the original query of a saved search.
 * @param {*} savedSearch 
 * @param {*} insDate 
 * @param {*} filterDefinitions 
 * @returns 
 */
export const createQuickSearchObjectFromSavedSearchAlert = (savedSearch, insDate, filterDefinitions) => {

    if (savedSearch?.formContent && filterDefinitions[SEARCH_FILTER_ID_INSDATE]) {
        const formContent = JSON.parse(savedSearch.formContent);
        const formContentConverted = convertQuickSearchToLatestFormat(formContent, savedSearch, filterDefinitions);
        if (formContentConverted) {
            const filter = filterDefinitions[SEARCH_FILTER_ID_INSDATE];
            const insDateQueryTerm = createFilterTermForMetadata(filter, insDate);
            if (formContentConverted.queryTerms && insDateQueryTerm) {
                formContentConverted.queryTerms.push(insDateQueryTerm);
                savedSearch.formContent = JSON.stringify(formContentConverted);
                return savedSearch;
            }
        }
    }
    return null;
}

/**
 * Converts saved search and its form content to quick search 2.0 form object used in state.
 * @param {*} savedSearch 
 * @param {*} formContent 
 * @returns 
 */
export const convertSavedSearchToState = (savedSearch, formContent) => {

    return {
        // new: store ID and name only, fetch saved search data when dialog is opened
        savedSearchID: savedSearch.id,
        savedSearchName: savedSearch.fullName,
        //
        queryTerms: formContent.queryTerms,
        logicalOperator: formContent.logicalOperator,
        increaseRecall: formContent.increaseRecall,
        editableQuery: savedSearch.edit,
        queryString: savedSearch.val,
    };
}