import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

class EditFeature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            featureLabel: '',
            featureName: '',
            featureID: ''
        }
    }

    componentDidMount() {
        let featureName = this.props.selectedFeatureName ? this.props.selectedFeatureName : ''
        let featureLabel = this.props.selectedFeatureLabel ? this.props.selectedFeatureLabel : ''
        let featureID = this.props.selectedFeatureID ? this.props.selectedFeatureID : ''

        this.setState({
            featureLabel: featureLabel,
            featureName: featureName,
            featureID: featureID
        })
    }

    onEditFeature = () => {
        this.props.onEditFeature(this.props.selectedFeatureID, this.state.featureLabel, this.state.featureName, this.props.selectedFeatureCanBeDeleted, this.props.selectedFeatureIsDefault)
    }

    render() {

        const footerEditFeature = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelEditFeature}
                    style={{ marginRight: 5 }} />
                <Button label="Edit"
                    className='p-button-sm primaryButton'
                    disabled={this.state.featureLabel !== '' ? false : true}
                    onClick={this.onEditFeature}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayEditFeature} style={{ 'width': "60vw" }} header="Feature details" focusOnShow={false}
                modal={true} footer={footerEditFeature} dismissableMask={false}
                onHide={() => this.props.onCancelEditFeature()} className='styledDialog'>
                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="col-12 grid p-fluid">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="departmentName">Feature label (for displaying)</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="featureLabel"
                                onChange={(e) => { this.setState({ featureLabel: e.target.value }) }} value={this.state.featureLabel} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="departmentName">Feature name</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="featureName" disabled={true}
                                onChange={(e) => { this.setState({ featureName: e.target.value }) }} value={this.state.featureName} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="departmentName">Feature ID</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="featureID" disabled={true}
                                onChange={(e) => { this.setState({ featureID: e.target.value }) }} value={this.state.featureID} />
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default EditFeature