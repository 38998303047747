import {
} from '../../../../redux/actions/WebApiActions'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Alerts from '../Alerts'

const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    userData: state.user.data
})

const mapDispatchToProps = dispatch => ({
})


const AlertsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Alerts))

export default AlertsContainer