
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { COLUMNS, EXPORT_STATUS } from '../core/constant';
import CustomPaginatorLeftSide from '../../../common/CustomPaginator/CustomPaginatorLeftSide';
import CustomPaginatorRightSide from '../../../common/CustomPaginator/CustomPaginatorRightSide';
import CustomPaginatorTemplate from '../../../common/CustomPaginator/CustomPaginatorTemplate';
import ToastContent from './ToastContent';
import { Dialog } from 'primereact/dialog';
import { isbold } from '../core/services/helperFunctions';
import axios from 'axios'
import { saveAs } from 'file-saver';
import './DataTable.css'
import { ExportService } from '../core/services/dataTableService';
import { addThousandsSeparatorToNumber } from "../../../webapi/util";

const ExportsTable = (props) => {
    const [selectedExports, setSelectedExports] = useState(null);
    const [dataTableRows, setDataTableRows] = useState(10);
    const [dataTablePage, setDataTablePage] = useState(0);
    const [isDialogVisible, setIsDialogVisible] = useState(false);


    useEffect(() => {
        if (props.exports?.find(item => item.status === "Processing" || item.status === 'Waiting')) {
            const interval = setTimeout(() => {
                props.onReload();
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [props.exports]);

    const onHide = () => {
        setIsDialogVisible(false);
        setSelectedExports([])

    }

    const onPageChange = (e, isPageChange) => {
        if (isPageChange) {
            setDataTablePage(e.first);
            setDataTableRows(e.rows);
        }
    }

    const selectedRecords = (e) => {
        setSelectedExports(e.value);

        if (e.value?.length > 0) {
            if (!isDialogVisible) {
                setIsDialogVisible(true);
            }
        } else {
            setIsDialogVisible(false);
        }
    }



    const downloadTemplate = (rowData) => {
        return <div>
            {
                (rowData?.status && EXPORT_STATUS[rowData.status]) &&
                <div className={isbold(rowData)}>
                    {
                        rowData?.downloadUrl ? <a
                            onClick={() => download(rowData)}>
                            Download
                        </a> :
                            <label style={{
                                color: EXPORT_STATUS[rowData?.status].color
                            }} >
                                {EXPORT_STATUS[rowData?.status].label}
                            </label>
                    }
                </div>
            }
        </div>

    }

    const statusTemplate = (rowData) => {
        return <div>
            {
                (rowData?.status && EXPORT_STATUS[rowData.status]) &&
                <div className={isbold(rowData)}>
                    {
                        rowData?.downloadUrl ?
                            <a
                                href={`${rowData?.downloadUrl}`} target="_blank" rel="noopener noreferrer">
                                ReadCube
                                <span style={{ color: '#007fdb', fontSize: 13, position: 'relative', top: 2, marginLeft: 5 }} className='material-icons'>launch</span>
                            </a>
                            :
                            <label style={{
                                color: EXPORT_STATUS[rowData?.status].color
                            }} >
                                {EXPORT_STATUS[rowData?.status].label}
                            </label>
                    }
                </div>
            }
        </div>

    }

    const recordsTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Records</span>
            <span>
                <label title="Not available" className={isbold(rowData)}>{addThousandsSeparatorToNumber(rowData?.numberRecords) || '-'}</label>
            </span>
        </div>
    }

    const downloadApiCall = (rowData) => {
        axios.get(rowData?.downloadUrl, { responseType: 'blob' })
            .then(function (response) {
                saveAs(new Blob([response.data]), `${rowData.name}.${rowData.fileFormat}`)
                // props.updateExports(rowData?.id)
            }).catch(function (error) {
                console.log(error.message)
                props.onErrorToast(error?.message)
            });
    }


    const download = (rowData) => {
        const service = new ExportService()
        if (rowData?.downloaded) {
            downloadApiCall(rowData)
        } else {
            service.updateExportStatus(rowData?.id).then(res => {
                downloadApiCall(rowData)
                props.updateExports(rowData?.id)
            }).catch(err => {
                console.log(err.message)
                props.onErrorToast(err?.message)

            });
        }
    }

    const downloadCol = {
        field: "status",
        header: '',
        body: !props.showReadcubeExports ? downloadTemplate : statusTemplate,
        style: { width: '7em', textAlign: 'left', fontWeight: 'normal' }
    };

    const recordsCol = {
        field: "numberRecords",
        header: 'Records',
        body: recordsTemplate,
        style: { width: '7em', textAlign: 'left', fontWeight: 'normal' }
    };

    return (
        <div className={!props.showReadcubeExports ? "datatable-responsive-demo-l" : 'datatable-responsive-demo-s'}>

            <DataTable
                value={props.exports}
                paginator
                className={!props.showReadcubeExports ? "p-datatable-responsive-demo-l" : 'p-datatable-responsive-demo-s'}
                rows={dataTableRows}
                first={dataTablePage}
                paginatorTemplate={CustomPaginatorTemplate}
                paginatorLeft={<CustomPaginatorLeftSide
                    first={dataTablePage}
                    contentLength={props.exports.length}
                    rows={dataTableRows}>
                </CustomPaginatorLeftSide>}
                paginatorRight={<CustomPaginatorRightSide
                    rows={dataTableRows}
                    onChange={(e) => setDataTableRows(e.value)}>
                </CustomPaginatorRightSide>}
                dataKey="id"
                rowHover
                onPage={(e) => onPageChange(e, true)}
                selection={selectedExports}
                selectionMode='checkbox'
                onSelectionChange={selectedRecords}
                filterDisplay="menu"
                responsiveLayout="scroll"
                emptyMessage="No Export found."
            >
                {!props.showReadcubeExports ?
                    [...COLUMNS, downloadCol]?.map((col, i) => {
                        return <Column className="columnheader" key={col.field}
                            field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                            header={col.header} sortable={col.sortable} body={col.body} style={col.style}
                            filter={col.filter} />
                    })
                    :
                    [...COLUMNS.filter(col => col.field !== 'category' && col.field !== 'fileSize' && col.field !== 'fileFormat' && col.field !== 'expiresin'), recordsCol, downloadCol]?.map((col, i) => {
                        return <Column className="columnheader" key={col.field}
                            field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                            header={col.header} sortable={col.sortable} body={col.body} style={col.style}
                            filter={col.filter} />
                    })
                }
            </DataTable>

            <Dialog visible={isDialogVisible} closable={false} showHeader={false} position={'bottom'} modal={false}
                onHide={onHide} style={{ backgroundColor: 'white', opacity: '1', bottom: 55, boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }}
                draggable={false} resizable={false} contentClassName='dialog-content' className='dialog-custom'>
                <ToastContent selectedExports={selectedExports} onReload={props.onReload} onHide={onHide} />
            </Dialog>
        </div>
    );
}


export default ExportsTable