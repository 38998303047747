import SearchGroupsTable from "./SearchGroupsTable/SearchGroupsTable";
import SearchGroupsToolbar from "./SearchGroupsToolbar";
import { useDispatch, useSelector } from "react-redux";
import { createSearchGroup } from "../../helpers/advancedSearch";
import { useEffect, useRef, useState } from "react";
import { SEARCH_GROUP_TYPE_FILTER, SEARCH_GROUP_TYPE_LOGIC, SEARCH_GROUP_TYPE_SAVED_SEARCH, SEARCH_GROUP_TYPE_TERM } from "../../helpers/constants";
import { createQueryString } from "../../helpers/queryConverter";
import { isArrayEmpty } from "../../../util";
import SaveSearchDialog from "../../../../common/SaveSearch/SaveSearchDialog";
import { getAdvancedSearchFromLocalStorage, storeSearch } from "../../helpers/savedSearches";
import { checkResultAndGetPayload } from "../../../../../api";
import ConfirmationDialog from "../../../../common/ConfirmDialog/ConfirmationDialog";
import { filterReachableGroupIDs, checkForErrors } from "../../helpers/checks";
import SearchGroupsHeader from './SearchGroupsHeader';
import { Toast } from 'primereact/toast';
import { isEmpty } from 'lodash';
import { fetchNumberOfResultsForSearchGroups, setAdvancedSearch3FormContent, setSavedAdvancedSearch, setSearchGroupToEdit, setSearchGroupsErrors, setSearchGroupsErrorsPreview, setSearchGroupsNumberOfResults, setSearchGroupsNumberOfResultsPreview, setSearchGroupsQueryStrings, setSearchGroupsReachable, toggleFilterGroupDialog, toggleLogicGroupDialog, toggleSavedSearchGroupDialog, toggleTermGroupDialog } from '../../../../../redux/features/queryBuilder/actions';
import { filterDefinitionsSelector } from '../../../../../redux/common/filters/selectors/filters';

const QueryBuilderForm = ({
  repositories,
  onRunSearch,
  onClearResults,
  onSelectSavedSearch,
}) => {
  const growl = useRef(null);

  const searchGroups = useSelector(state => state.advancedSearch3Reducer.searchGroups);
  const reachableSearchGroups = useSelector(state => state.advancedSearch3Reducer.reachableSearchGroups);
  const editGroup = useSelector(state => state.advancedSearch3Reducer.searchGroupToEdit);
  const savedSearchData = useSelector(state => state.advancedSearch3Reducer.savedSearchData);
  const previewRepository = useSelector(state => state.advancedSearch3Reducer.previewRepository);
  const queryStringsPrevious = useSelector(state => state.advancedSearch3Reducer.searchGroupsQueryStrings);
  const numberOfResultsPrevious = useSelector(state => state.advancedSearch3Reducer.numberOfResults);
  const filterDefinitions = useSelector(filterDefinitionsSelector);
  const userData = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const [displaySaveSearchDialog, setDisplaySaveSearchDialog] = useState();
  const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState();

  useEffect(() => {
    if (!isEmpty(filterDefinitions)) {
      const advancedSearchFromStorage = getAdvancedSearchFromLocalStorage();
      if (advancedSearchFromStorage) {
        const { formContent, ...savedSearchDataFromStorage } = advancedSearchFromStorage;
        dispatch(setSavedAdvancedSearch(savedSearchDataFromStorage));
        dispatch(setAdvancedSearch3FormContent(formContent?.searchGroups || []));
        dispatch(setSearchGroupToEdit(null));
      }
    }
  }, [filterDefinitions]);

  useEffect(() => {
    dispatch(setSearchGroupsReachable([]));
    dispatch(setSearchGroupsNumberOfResults(null));
    dispatch(setSearchGroupsNumberOfResultsPreview(null));
    dispatch(setSearchGroupsErrors(null));
    dispatch(setSearchGroupsErrorsPreview(null));

    if (!isArrayEmpty(searchGroups)) {
      // === check for errors === //
      const errorMessages = checkForErrors(searchGroups);
      dispatch(setSearchGroupsErrors(errorMessages));
      // === if forward references are found -> cancel === //
      if (errorMessages.hasForwardReferences) {
        return;
      }
      // === check which groups are reachable === //
      let reachable = filterReachableGroupIDs(searchGroups) || [];
      dispatch(setSearchGroupsReachable(reachable));
      // === fetch number of preview results === //
      dispatch(
        fetchNumberOfResultsForSearchGroups(
          searchGroups,
          filterDefinitions,
          previewRepository,
          numberOfResultsPrevious,
          queryStringsPrevious
        )
      );
    }
  }, [searchGroups]);

  useEffect(() => {
    dispatch(
      fetchNumberOfResultsForSearchGroups(
        searchGroups,
        filterDefinitions,
        previewRepository
      )
    );
  }, [previewRepository]);

  const handleAddSearchGroup = (type) => {
    const searchGroupNew = createSearchGroup(type);
    const searchGroupsNew = [...searchGroups, searchGroupNew];

    dispatch(setAdvancedSearch3FormContent(searchGroupsNew));
    dispatch(setSearchGroupToEdit(searchGroupNew));

    toggleInitialDialog(type);
  };

  const toggleInitialDialog = (type) => {
    switch (type) {
      case SEARCH_GROUP_TYPE_TERM:
        return dispatch(toggleTermGroupDialog(true));
      case SEARCH_GROUP_TYPE_LOGIC:
        return dispatch(toggleLogicGroupDialog(true));
      case SEARCH_GROUP_TYPE_FILTER:
        return dispatch(toggleFilterGroupDialog(true));
      case SEARCH_GROUP_TYPE_SAVED_SEARCH:
        return dispatch(toggleSavedSearchGroupDialog(true));
      // TODO: case SEARCH_GROUP_TYPE_API_SYNTAX: return
    }
  };

  const handleRunPartialSearch = async (queryString) => {
    onRunSearch({ query: queryString });
  };

  const handleRunSearch = async () => {
    const queryString = await createQueryString(
      searchGroups,
      filterDefinitions
    );
    onRunSearch({ query: queryString });
  };

  const handleClearAll = () => {
    dispatch(setAdvancedSearch3FormContent([]));
    dispatch(setSearchGroupToEdit(null));
    dispatch(setSavedAdvancedSearch({}));
    onClearResults();
  };

  const handleSaveSearch = async (isUpdate) => {
    const queryString = await createQueryString(
      searchGroups,
      filterDefinitions
    );
    dispatch(
      setSavedAdvancedSearch({ ...savedSearchData, isUpdate, queryString })
    );
    setDisplaySaveSearchDialog(true);
  };

  const handleStoreSearch = async (
    queryString,
    queryName,
    queryFullName,
    queryDescription,
    watchlistIDs,
    addAlert,
    alertID,
    alertActive,
    alertInterval,
    alertRepositories
  ) => {
    // TODO: clean up
    const queryData = {
      searchGroups,
      queryString,
      queryName,
      queryFullName,
      queryDescription,
    };
    const alertData = {
      addAlert,
      alertID,
      alertActive,
      alertInterval,
      alertRepositories,
    };
    const response = await storeSearch(
      savedSearchData?.savedSearchID,
      savedSearchData?.isUpdate,
      queryData,
      watchlistIDs,
      alertData
    );

    const result = checkResultAndGetPayload(
      response,
      growl,
      "Success",
      `${savedSearchData?.isUpdate
        ? "Search successfully updated."
        : "Search successfully stored."
      }`
    );

    if (result) {
      dispatch(
        setSavedAdvancedSearch({
          savedSearchID: result.savedSearchID,
          savedSearchName: result.savedSearchName,
          isEditable: result.isEditable,
        })
      );
      setDisplaySaveSearchDialog(false);
    }
  };

  return (
    <>
      <Toast ref={growl} />

      <SearchGroupsHeader
        repositories={repositories}
        onSelectSavedSearch={onSelectSavedSearch}
      />

      <SearchGroupsTable
        groups={searchGroups}
        reachableGroups={reachableSearchGroups}
        onReorderGroups={(groups) =>
          dispatch(setAdvancedSearch3FormContent(groups))
        }
        onRunPartialSearch={handleRunPartialSearch}
      />
      <SearchGroupsToolbar
        onAddGroup={handleAddSearchGroup}
        onRunSearch={handleRunSearch}
        onClearAll={() => setDisplayConfirmationDialog(true)}
        onSaveSearch={handleSaveSearch}
        isEditModeActive={!!editGroup}
        isEmptySearch={isArrayEmpty(searchGroups)}
        savedSearchData={savedSearchData}
      />

      <SaveSearchDialog
        displayDialogStoreQuery={displaySaveSearchDialog}
        savedSearchID={savedSearchData?.savedSearchID}
        editQuery={savedSearchData?.isUpdate}
        queryString={savedSearchData?.queryString}
        // allowAlerts={true}
        onSubmit={handleStoreSearch}
        onHide={() => setDisplaySaveSearchDialog(false)}
        userData={userData}
      />

      <ConfirmationDialog
        displayDialog={displayConfirmationDialog}
        onHide={() => setDisplayConfirmationDialog(false)}
        onSubmit={() => {
          handleClearAll();
          setDisplayConfirmationDialog(false);
        }}
        headerText="Confirm"
        messageText="Clear all input?"
        submitButtonLabel={"Clear"}
      />
    </>
  );
};

export default QueryBuilderForm;
