import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setDocumentTitle } from '../../../redux/actions/QuickSearchActions'
//import { fetchPDF } from '../../../actions/WebApiActions'
import DocumentView from '../docview/DocumentView'
import { parseTermItemsFromQuickSearchBar } from '../docview/util/annotations'

const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    pdf: state.webAPI.pdf,
    documents: state.webAPI.documents,
    repositories: state.user.data.userDetails.department?.selectedRepositories,
    userData: state.user.data,
    defaultSearchTerms: parseTermItemsFromQuickSearchBar(state.quickSearchReducer.quickSearchSelectedTerms, state.user.data.userDetails.department.selectedDomains)
})
const mapDispatchToProps = dispatch => {
    return {
        onSetDocumentTitle: (value) => {
            dispatch(setDocumentTitle({ ...value }))
        }
    }
    /*
        fetchPDF: (search) => dispatch(fetchPDF(search)),
    */
}

const DocumentViewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentView))

export default DocumentViewContainer
