import styles from './Link.module.scss'

const Link = ({onClick, title, style, id}) => {
    return (
        <a style={style} className={styles.link} onClick={onClick} id={id}>
            {title}
        </a>
    );
};

export default Link;
