import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class DeleteCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteCompanyChecked: false
        }
    }

    onCheckDeleteCompany = (e) => {
        if (e.checked) {
            this.setState({
                deleteCompanyChecked: true
            })
        } else {
            this.setState({
                deleteCompanyChecked: false
            })
        }
    }

    render() {


        const footerDeleteCompany = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete"
                    className='p-button-sm p-button-danger'
                    disabled={this.state.deleteCompanyChecked ? false : true}
                    onClick={this.props.onDeleteCompanyAndUsers}
                />
                <Button label="Cancel"
                    className='p-button-sm p-button-secondary'
                    onClick={this.props.onCancelDeleteCompany}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayDeleteCompany} style={{ 'width': "40vw" }} header="Delete organization" focusOnShow={false}
                modal={true} footer={footerDeleteCompany} dismissableMask={false}
                onHide={() => this.props.onCancelDeleteCompany()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div style={{ marginBottom: 10 }}>
                        <label>{`This will delete organization '${this.props.selectedCompanyName}' and all its users.`}</label>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <Checkbox inputId='deleteCompanyChecked'
                            onChange={(e) => this.onCheckDeleteCompany(e)}
                            checked={this.state.deleteCompanyChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                        />
                        <label htmlFor='deleteCompanyChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default DeleteCompany