import { sortGroupReferences } from "../../../../webapi/queryBuilder/helpers/advancedSearch";
import {
  LOGICAL_OPERATORS_AND_OR_NOT,
  LOGICAL_OPERATOR_NOT,
} from "../../../../webapi/queryBuilder/helpers/constants";
import Badge from "../../common/Badge";
import React from "react";

const ReferencedGroups = ({
  groupReferences,
  groups,
  operator,
  onGroupReferenceClick,
  showOperator,
  sort,
}) => {
  const selectedOperator = LOGICAL_OPERATORS_AND_OR_NOT.find(
    (op) => op.value === operator
  );
  const groupReferencesSorted = !sort
    ? groupReferences
    : sortGroupReferences(
        groupReferences,
        groups,
        operator === LOGICAL_OPERATOR_NOT.value
      );

  return (
    <div className="flex align-items-center flex-wrap line-height-3">
      {groupReferencesSorted?.map((groupID, index) => {
        const refGroup = groups.find((qg) => qg.id === groupID);
        const groupIndex = groups.indexOf(refGroup);

        return (
          <React.Fragment key={groupID}>
            <div onClick={() => onGroupReferenceClick(groupID)}>
              {groupIndex >= 0 ? (
                <Badge
                  value={groupIndex + 1}
                  className={refGroup.type}
                />
              ) : (
                <Badge value={"?"} />
              )}
            </div>
            <div>
              {showOperator &&
              selectedOperator &&
              index < groupReferencesSorted.length - 1 ? (
                <span className="search-group-operator">
                  {selectedOperator?.label || selectedOperator}
                </span>
              ) : (
                <span style={{ marginRight: 5 }} />
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ReferencedGroups;
