/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import { normalizeConceptTerm, deduplicateTerms, findMatchIgnoreCase, isArrayEmpty } from '../../util';
import AutoComplete from '../../../common/AutoComplete/AutoComplete';
//import Link from "../../../common/Link/Link";
import styles from './TrendAnalysisSimpleSearch.module.scss';
import { convertQueryConceptsToQueryString } from "../../../../api/content/QueryApi";
import { setShowModal, setYearStatisticsSearchParameters } from "../../../../redux/actions/YearStatisticsActions";
import { selectionLimit } from "../../../../redux/reducers/yearStatistics";
import { Toast } from "primereact/toast";
import { Tooltip } from 'primereact/tooltip';
import InputFieldWithClear from '../../general/inputfields/InputFieldWithClear';
import DomainColorBar from '../../../common/DomainColorBar/DomainColorBar';

class _TrendAnalysisSimpleSearch extends Component {
    constructor() {
        super();
        this.state = {
            part: ''
        };
        this.selItemTemplate = this.selItemTemplate.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.ref = createRef();
    }

    filterParts = (event) => {
        let selectedRepositories = []
        this.props.userData.userDetails.department?.selectedRepositories && this.props.userData.userDetails.department?.selectedRepositories.forEach(rep => {
            selectedRepositories = [...selectedRepositories, rep.id]
        })
        setTimeout(() => {
            this.setState({
                part: event.query
            }, () => this.props.fetchTermSuggestions(this.props.history, {
                termFragment: event.query, mergeDomains: true, withDirectMatches: true, withPrefname: true,
            }, this.showFailGrowlSuggestions))
        }, 0);
    }

    handleInputChange = (e) => {
        this.props.onQueryTermsChanged(e.value);
    }

    showFailGrowlSuggestions = () => {
        let msg = {
            severity: 'error',
            summary: 'Failed!',
            detail: 'No suggestions available. Maybe there is a problem with the backend. Please contact us.',
            life: 6000
        };
        this.growl.show(msg);
    }

    selItemTemplate(item) {
        let term = item.term;
        let info = 'Text';
        let coloredDomains = []
        let coloredInfo

        if (item.domains) {
            if (item.domains.includes('tr')) {
                info = 'Text exact';
            } else if (item.domains.includes('t')) {
                info = 'Text with variants';
            } else {
                const uniqueLabels = item.domains.map(dom => this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                    this.props.domainLabelsMap[dom] : dom);
                info = uniqueLabels.sort().join(', ');
                uniqueLabels.sort().forEach(lab => {
                    let color = this.props.domainColors && this.props.domainColors.find(obj => {
                        return obj.label === lab
                    })
                    if (color !== undefined) {
                        coloredDomains = [...coloredDomains, '#' + color.color]
                    } else {
                        coloredDomains = [...coloredDomains, 'black']
                    }
                })

                coloredInfo = uniqueLabels.sort().map(lab => {
                    let color = this.props.domainColors && this.props.domainColors.find(obj => {
                        return obj.label === lab
                    })
                    const col = color?.color ? `#${color.color}` : '';
                    return <div className="grid" style={{ margin: 0, paddingBottom: 5 }}>
                        <DomainColorBar color={col} />
                        <span style={{ marginLeft: 4, paddingRight: 10 }}>{lab}</span>
                    </div>
                })
            }
        }

        let gradientString = ''
        let size = 100 / coloredDomains.length
        coloredDomains.length > 0 && coloredDomains.forEach((col, i) => {
            gradientString += `${col} ${size * i}%,${col} ${size * (i + 1)}% `
            if (i < coloredDomains.length - 1) {
                gradientString += ','
            }
        })

        const tooltipID = `tt-${term}-${item.domains?.join('-')}`.replace(/[^a-zA-Z0-9_-]/g, '_');

        return <div className="grid search-field-item-with-domain-bar">
            {coloredDomains.length > 0 ?
                <div className="search-field-item-domain-bar"
                    style={{ background: `linear-gradient(${gradientString})` }}
                >
                </div>
                : null
            }
            <div className={`search-field-item-content ${tooltipID}`}>
                <div>
                    <Tooltip className="whiteTooltip" target={`.${tooltipID}`} position={'bottom'}>
                        <div>
                            <div style={{ paddingTop: 4, paddingBottom: 7, fontWeight: 'bold' }}>{term}</div>
                            <div>{coloredInfo}</div>
                        </div>
                    </Tooltip>
                    <div style={{ fontWeight: 'bold' }}>{term}</div>
                    <div style={{ opacity: 0.7, fontSize: '0.8em', backgroundColor: '' }}>{info}</div>
                </div>
            </div>
        </div>;
    }

    itemTemplate(item) {
        let term = item.term;
        let info = 'Text with variants (not found in any domain)';
        let coloredDomains

        let hasDomains = false;
        let isTextExact = false;
        let isTextWithVariants = false;
        if (item.domains) {
            isTextExact = item.domains.includes('tr');
            isTextWithVariants = item.domains.includes('t');
            if (!isTextExact && !isTextWithVariants) {
                hasDomains = true;
                const uniqueLabels = item.domains.map(dom => this.props.domainLabelsMap && this.props.domainLabelsMap[dom] ?
                    this.props.domainLabelsMap[dom] : dom);
                coloredDomains = uniqueLabels.sort().map(lab => {
                    let color = this.props.domainColors && this.props.domainColors.find(obj => {
                        return obj.label === lab
                    })
                    const col = color?.color ? `#${color.color}` : '';
                    return <React.Fragment>
                        <DomainColorBar color={col} />
                        <span style={{ marginLeft: 4, paddingRight: 10 }}>{lab}</span>
                    </React.Fragment>
                })
                info = uniqueLabels.sort().join(', ');
            }
        }

        let prefName = '';
        const prefnamesUnique = item.prefnames ? deduplicateTerms(item.prefnames) : [];
        if (prefnamesUnique && prefnamesUnique.length === 1 && normalizeConceptTerm(prefnamesUnique[0], true) !== normalizeConceptTerm(term, true)) {
            prefName = ` (${prefnamesUnique[0]})`;
        }

        let termFormatted = term;
        const match = findMatchIgnoreCase(term, this.state.part);
        if (match) {
            termFormatted = termFormatted.replace(match, `<span style="font-weight: normal">${match}</span>`);
        }

        termFormatted += `<span style="font-weight: normal">${prefName}</span>`;

        const cutOffOtherTerms = item.otherTerms ? [...item.otherTerms].splice(0, 2) : [];
        //const maxWidth = this.ref.current?.container?.clientWidth;
        const clientWidth = this.props.acRef?.current?.getElement()?.getBoundingClientRect()?.width;
        const maxWidth = clientWidth ? clientWidth - 30 : '';
        const menuWidth = 180;

        return <div className='grid'
            style={{ width: '100%', maxWidth: maxWidth, display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: `calc(100% - ${menuWidth}px)` }}>
                <div
                    style={{
                        padding: '0 5px',
                        color: hasDomains ? '' : '#777',
                        width: '50%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                    title={term}>
                    <span
                        style={{ fontWeight: 'normal' }}>
                        <span dangerouslySetInnerHTML={{ __html: termFormatted }} />
                        {!isArrayEmpty(item.otherTerms) ?
                            <span className="secondaryInfo clampingContainer line-clamp line-clamp-1-line"
                                style={{ fontWeight: 'normal', display: 'block', paddingBottom: 0 }}
                                title={item.otherTerms.join(' · ')}
                            >{cutOffOtherTerms.join(' · ')}{item.otherTerms.length > 2 ? ' ...' : ''}
                            </span> : null}
                    </span>
                </div>

                <div className="col-4 clampingContainer line-clamp line-clamp-1-line"
                    style={{
                        padding: '0 5px', color: hasDomains ? '' : '#777', width: '50%', overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                    {info ? <div className="grid" style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', margin: 0, color: '#818181'
                    }}
                        title={info}>
                        {coloredDomains !== undefined ? coloredDomains : info}</div>
                        : null}
                </div>
            </div>

            <div style={{
                display: 'flex', width: menuWidth, flexDirection: 'row', justifyContent: 'space-between',
            }}>

                <div
                    style={{ padding: '0 5px' }}>
                    {hasDomains || isTextExact ? <a title="Search as text with variants"
                        className="primaryLink showOnHover"
                        onClick={() => {
                            item.domains = ['t'];
                            item.ocids = null;
                        }}
                    >Text with variants
                    </a> : null}
                </div>

                <div
                    style={{ padding: '0 5px' }}>
                    {hasDomains || isTextWithVariants ? <a title="Search as exact text"
                        className="primaryLink showOnHover"
                        onClick={() => {
                            item.domains = ['tr'];
                            item.ocids = null;
                        }}
                    >Text exact
                    </a> : null}
                </div>

                <div className="breakRow" />
            </div>
        </div>;
    }

    onAdd = () => {
        const {
            queryTerms,
            logicalOperator,
            searchParameters,
            setYearStatisticsSearchParameters,
            setShowModal,
        } = this.props;

        if (!queryTerms?.length) {
            let msg = { severity: 'warn', summary: 'Warning!', detail: 'Empty search.', life: 2000 };

            this.growl.show(msg);
            return
        }

        const outOfLimit = searchParameters.length >= selectionLimit;

        if (outOfLimit) {
            let msg = {
                severity: 'warn',
                summary: 'Warning!',
                detail: `Maximum number of series is ${selectionLimit}`,
                life: 2000
            };

            this.growl.show(msg);
            return
        }

        const terms = queryTerms.map(({ term }) => term).join(', ');

        const searchOptions = {
            searchName: terms,
            searchParameters: convertQueryConceptsToQueryString(queryTerms, logicalOperator)
        }

        setYearStatisticsSearchParameters([...searchParameters, searchOptions]);
        setShowModal(false);
    }

    render() {
        const { terms, fetching } = this.props;

        this.data = [{ term: null, domains: null }];

        this.autocompleteDomains = []

        let arr = []
        // --- add input text as free text in case it is not part of any domain --- //
        if (!terms || !terms.directFragmentMatches || Object.keys(terms.directFragmentMatches).length === 0) {
            this.autocompleteDomains.push([])
            arr.push({ term: this.state.part, domains: ['t'] })
        }

        if (terms && terms.suggestedTerms && terms.suggestedTerms.length > 0 && !fetching) {
            arr.push(...terms.suggestedTerms);
        }

        this.data = arr

        return (
            <div className={styles.container}>
                <InputFieldWithClear
                    onClear={() => this.props.onQueryTermsChanged([])}
                    showIcon={!isArrayEmpty(this.props.queryTerms)}>
                    <AutoComplete
                        id="searchInput"
                        value={this.props.queryTerms}
                        field="term"
                        className="withLogic" // unsetSuggestionListHeight
                        suggestions={this.data}
                        completeMethod={this.filterParts}
                        placeholder="Type search (e.g. liver disease)"
                        minLength={1}
                        multiple={true}
                        onChange={this.handleInputChange}
                        style={{ display: 'grid', width: '100%' }}
                        //calc(100% - 50px)
                        selectedItemTemplate={this.selItemTemplate}
                        itemTemplate={this.itemTemplate}
                        innerRef={this.ref}
                        autoHighlight={true}
                    />
                </InputFieldWithClear>

                {/*<div className={styles.add}>
                    <Link title='Add' onClick={this.onAdd}/>
        </div>*/}

                <Toast ref={(el) => this.growl = el} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
    domainColors: state.user.data.userDetails.department.selectedDomains,
    searchParameters: state.yearStatistics.searchParameters
})

export const TrendAnalysisSimpleSearch = connect(mapStateToProps, {
    setYearStatisticsSearchParameters,
    setShowModal
})(_TrendAnalysisSimpleSearch);
