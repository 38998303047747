import { connect }        from 'react-redux'
import { withRouter }     from 'react-router-dom'
import * as Actions from '../../../redux/actions'
import BackendAdministration from '../BackendAdministration'

const mapStateToProps = state => ({
    userDetails: state.user.data.userDetails,
    userData: state.user.data,
})

const mapDispatchToProps = dispatch => ({
    isLoggedIn: (history) => dispatch(Actions.isLoggedIn(history))
})

const BackendAdminContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(BackendAdministration))

export default BackendAdminContainer