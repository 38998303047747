import React, { Component } from 'react';
import { Tree } from 'primereact/tree';
import { ContextMenu } from 'primereact/contextmenu';
import "../../../ontbrowser/OntologyBrowser.css";
import "./FrameOntology.css";
import { forEach } from 'lodash';


class FrameOntologyTree extends Component {

    constructor(props) {
        super(props);

        /*
        const nodeMenuItemEditClass = {
            label: 'Edit class',
            command: (e) => { this.onEditClass(); }
        };
        */
        this.nodeMenuItemAddClass = {
            label: 'Add class',
            command: (e) => { this.onAddClass(); }
        };
        this.nodeMenuItemCloneClass = {
            label: 'Clone class',
            command: (e) => { this.onCloneClass(); }
        };
        this.nodeMenuItemDeleteClass = {
            label: 'Delete class',
            command: (e) => { this.onDeleteClass(); }
        };
        this.nodeMenuItemRemoveClassFromParent = {
            label: 'Remove relationship to parent',
            command: (e) => { this.onRemoveRelationToParent(); }
        };

        this.state = {
            cmItems: []
        };
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            selectedClassKey: nextProps.selectedClass ? nextProps.selectedClass.key : null
        });
    }


    onClassRightClick = (event) => {
        const node = event.node;

        let items = [
            this.nodeMenuItemAddClass,
            this.nodeMenuItemCloneClass
        ];

        if (!node.children || node.children.length === 0) {
            items.push(this.nodeMenuItemDeleteClass);
        }
        if (!node.isRoot) {
            items.push(this.nodeMenuItemRemoveClassFromParent);
        }

        this.setState({
            cmItems: items,
            rightClickSelection: event.node,
            selectedClass: event.node,
            selectedClassKey: event.node.key
        });

        this.props.onSelectClass(event.node);

        this.cm.show(event.originalEvent);
    }

    onAddClass = () => {
        const parentClass = this.state.rightClickSelection;
        this.props.onAddNewClass(parentClass);

        const expandedKeys = { ...this.state.expandedKeys };
        expandedKeys[parentClass.key] = true;
        this.setState({
            expandedKeys: expandedKeys
        });
    }

    onCloneClass = () => {
        const origClass = this.state.rightClickSelection;
        this.props.onCloneClass(origClass);

        const expandedKeys = { ...this.state.expandedKeys };
        expandedKeys[origClass.key] = true;
        this.setState({
            expandedKeys: expandedKeys
        });
    }

    onDeleteClass = () => {
        const node = this.state.rightClickSelection;
        this.props.onDeleteClass(node);

        // TODO: recursivley delete keys of children!!!

        const expandedKeys = { ...this.state.expandedKeys };
        delete expandedKeys[node.key];
        this.setState({
            expandedKeys: expandedKeys
        });
    }


    onRemoveRelationToParent = () => {
        const node = this.state.rightClickSelection;
        this.props.onRemoveRelationToParent(node);

        // TODO: recursivley delete keys of children!!!

        const expandedKeys = { ...this.state.expandedKeys };
        delete expandedKeys[node.key];
        this.setState({
            expandedKeys: expandedKeys
        });
    }


    expandClasses = (expandKeys, closeAllOtherClasses = false) => {

        let expandedKeys;
        if (closeAllOtherClasses) {
            expandedKeys = { ...expandKeys };
        }
        else {
            expandedKeys = { ...this.state.expandedKeys, ...expandKeys };
        }

        this.setState({
            expandedKeys: expandedKeys
        });
    }


    nodeTemplate = (node, options) => {
        const label = this.props.ontClasses && this.props.ontClasses[node.id] ?
            this.props.ontClasses[node.id].name : node.id;
        return (
            <span key={node.key}>{label}</span>
        )
    }


    render() {

        const { ontology, onSelectClass, contentBoxHeight, dndScope } = this.props;
        //console.log('ontology: ', ontology);

        return (
            <div className="task-box task-box-dark-blue shadowMedium">
                <div className="task-box-header">
                    Ontology tree view
                </div>
                <div className="task-box-content"
                    style={{ height: contentBoxHeight, overflow: 'auto' }}>
                    <ContextMenu model={this.state.cmItems} ref={el => this.cm = el} style={{ width: 'auto' }} />
                    <div className=" ontTree">
                        <Tree
                            value={ontology}

                            nodeTemplate={this.nodeTemplate}

                            selectionMode="single"
                            selectionKeys={this.state.selectedClassKey}
                            onSelectionChange={e => this.setState({ selectedClassKey: e.value })}
                            onSelect={e => onSelectClass(e.node)}

                            expandedKeys={this.state.expandedKeys}
                            onToggle={e => this.setState({ expandedKeys: e.value })}

                            dragdropScope={dndScope}
                            onDragDrop={e => {
                                //console.log('e: ', e);
                                this.props.onDragDrop(e.value, e);
                            }}

                            //onContextMenuSelectionChange={event => { this.setState({ rightClickSelectionKey: event.value, rightClickSelection: event.node }); }}
                            onContextMenu={e => this.onClassRightClick(e)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default FrameOntologyTree;