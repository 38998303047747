import _ from "lodash";
import { MATCH_TYPE_EXACT, MATCH_TYPE_SIMILAR } from "./constants";
import { isArrayEmpty, isObjectEmpty, sortObjectArrayByAttribute } from "../../util";


export const SELECT_ALL = 'selAll';
export const DESELECT_ALL = 'deselAll';
export const SELECT_ALL_EXACT = 'selAllExact';
export const DESELECT_ALL_EXACT = 'deselAllExact';
export const SELECT_ALL_SIMILAR = 'selAllSim';
export const DESELECT_ALL_SIMILAR = 'deselAllSim';


export const createSelectItems = (repositoryID, onSelect, documentIDMetadataProp, includeExact, includeSimilar) => {

    const menu = [];

    if (includeSimilar && includeExact) {
        menu.push({
            label: 'Select all',
            icon: 'pi pi-fw pi-check-square',
            command: () => { onSelect(SELECT_ALL, repositoryID, documentIDMetadataProp) }
        });
    }
    if (includeExact) {
        menu.push({
            label: 'Select exact matches',
            icon: 'pi pi-fw pi-check-square',
            command: () => { onSelect(SELECT_ALL_EXACT, repositoryID, documentIDMetadataProp) }
        });
    }
    if (includeSimilar) {
        menu.push({
            label: 'Select similar matches',
            icon: 'pi pi-fw pi-check-square',
            command: () => { onSelect(SELECT_ALL_SIMILAR, repositoryID, documentIDMetadataProp) }
        });
    }
    if (includeSimilar || includeExact) {
        menu.push({
            separator: true
        });
    }
    if (includeSimilar && includeExact) {
        menu.push({
            label: 'Deselect all',
            icon: 'pi pi-fw pi-times',
            command: () => { onSelect(DESELECT_ALL, repositoryID, documentIDMetadataProp) }
        });
    }
    if (includeExact) {
        menu.push({
            label: 'Deselect exact matches',
            icon: 'pi pi-fw pi-times',
            command: () => { onSelect(DESELECT_ALL_EXACT, repositoryID, documentIDMetadataProp) }
        });
    }
    if (includeSimilar) {
        menu.push({
            label: 'Deselect similar matches',
            icon: 'pi pi-fw pi-times',
            command: () => { onSelect(DESELECT_ALL_SIMILAR, repositoryID, documentIDMetadataProp) }
        });
    }

    return menu;
};


export const generateSelectedDocumentKey = (repositoryID, documentID, isExact) => {

    return `${repositoryID}:${documentID}:${isExact ? MATCH_TYPE_EXACT : MATCH_TYPE_SIMILAR}`;
}


export const regenerateSelectedDocumentKey = (key) => {

    const res = key.split(':');
    return { repositoryID: res[0], documentID: res[1], matchType: res[2] };
}


export const documentSelectionByToggle = (repositoryID, documentID, metadataDocumentID, document, isExact, selectedDocuments = {}) => {

    const key = generateSelectedDocumentKey(repositoryID, documentID, isExact);
    const selectedDocumentsUpdated = { ...selectedDocuments };
    if (selectedDocumentsUpdated[key]) {
        delete selectedDocumentsUpdated[key];
    }
    else {
        selectedDocumentsUpdated[key] = { ...document, mdID: metadataDocumentID, repositoryID, documentID, isExact };
    }

    return selectedDocumentsUpdated;
}


export const documentSelectionByOption = (selectionOption, repositoryID, documentIDMetadataProp, selectedDocuments, resultsExact, resultsSimilar) => {

    const selectedDocumentsUpdated = { ...selectedDocuments };

    switch (selectionOption) {

        case SELECT_ALL: {
            selectDocuments(selectedDocumentsUpdated, resultsExact, repositoryID, documentIDMetadataProp, true);
            selectDocuments(selectedDocumentsUpdated, resultsSimilar, repositoryID, documentIDMetadataProp, false);
            break;
        }

        case DESELECT_ALL: {
            deselectDocuments(selectedDocumentsUpdated, repositoryID, MATCH_TYPE_EXACT);
            deselectDocuments(selectedDocumentsUpdated, repositoryID, MATCH_TYPE_SIMILAR);
            break;
        }

        case SELECT_ALL_EXACT: {
            selectDocuments(selectedDocumentsUpdated, resultsExact, repositoryID, documentIDMetadataProp, true);
            break;
        }

        case DESELECT_ALL_EXACT: {
            deselectDocuments(selectedDocumentsUpdated, repositoryID, MATCH_TYPE_EXACT);
            break;
        }

        case SELECT_ALL_SIMILAR: {
            selectDocuments(selectedDocumentsUpdated, resultsSimilar, repositoryID, documentIDMetadataProp, false);
            break;
        }

        case DESELECT_ALL_SIMILAR: {
            deselectDocuments(selectedDocumentsUpdated, repositoryID, MATCH_TYPE_SIMILAR);
            break;
        }
    }

    return selectedDocumentsUpdated;
}


const selectDocuments = (selectedDocuments, results, repositoryID, documentIDMetadataProp, isExact) => {

    Object.values(results)?.forEach(result => {
        Object.entries(result).forEach(([repoID, res]) => {
            if (repoID == repositoryID) {
                const document = isExact ? res.exact : res.similar;
                if (document) {
                    const documentID = document?.qualifiedOcDocId;
                    const metadata = document?.docMetadata;
                    const metadataDocumentID = metadata && metadata[documentIDMetadataProp] && metadata[documentIDMetadataProp][0];

                    const key = generateSelectedDocumentKey(repositoryID, documentID, isExact);
                    selectedDocuments[key] = { ...document, mdID: metadataDocumentID, repositoryID, documentID, isExact: isExact };
                }
            }
        });
    });
}


const deselectDocuments = (selectedDocuments, repositoryID, matchType) => {

    Object.entries(selectedDocuments).forEach(([key, doc]) => {
        if (key.match(`${repositoryID}:.*:${matchType}`)) {
            delete selectedDocuments[key];
        }
    });
}


export const analyzeSelectedDocuments = (selectedDocumentsMap = {}) => {

    let documentCount = 0;
    let repositoryCount = {};
    if (!isObjectEmpty(selectedDocumentsMap)) {
        documentCount = Object.keys(selectedDocumentsMap).length;
        Object.keys(selectedDocumentsMap)?.forEach((key) => {
            const { repositoryID } = regenerateSelectedDocumentKey(key);
            repositoryCount[repositoryID] = repositoryCount[repositoryID] ? repositoryCount[repositoryID] + 1 : 1;
        });
    }
    return { documentCount, repositoryCount };
}


export const filterDocuments = (documents, selectedDocuments, hideSelected) => {

    const alteredDocuments = documents ? [...documents] : [];
    let selectedIDCount = 0;
    alteredDocuments && alteredDocuments?.forEach(document => {
        let isSelected = false;

        document.results && Object.entries(document.results)?.forEach(([repoID, data]) => {
            const docs = Object.entries(data)?.forEach(([matchType, doc]) => {
                document[`${repoID}_matchType`] = matchType;
                if (doc) {
                    const key = generateSelectedDocumentKey(repoID, doc.qualifiedOcDocId, matchType === MATCH_TYPE_EXACT);
                    isSelected = isSelected || !!selectedDocuments[key];
                }
            });
        });
        document.isSelected = isSelected;
        if (isSelected) { selectedIDCount++ }
    });

    const filteredDocuments = !hideSelected ? alteredDocuments || [] :
        !isArrayEmpty(alteredDocuments) ? alteredDocuments.filter(doc => { return !hideSelected || !doc.isSelected }) : [];

    return { filteredDocuments, selectedIDCount };
}


export const sortSelectedDocumentsByOriginalOrder = (selectedDocuments = {}) => {

    const selDocsList = Object.values(selectedDocuments);
    const selDocsListSorted = sortObjectArrayByAttribute(selDocsList, 'origIndex', true);

    return selDocsListSorted;
}