/* eslint-disable jsx-a11y/anchor-is-valid */
import "./OntologyBrowser.css";
import React, { Component, createRef } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import OntologySearch from './OntologySearch';
import OntologyClassList from './OntologyClassList';
import OntologyClassDetails from './OntologyClassDetails';
import OntologyTree from "./OntologyTree";
import { TOGGLE_TREE_COLLAPSE, TOGGLE_TREE_EXPAND, TOGGLE_TREE_NONE } from './OntologyTree';
import { checkResultAndGetPayload } from '../../../api';
import { isArrayEmpty } from '../util';
import { fetchConceptData, fetchDirectMatches, createConceptDataRequest, fetchConceptsData } from '../../../api/content/ConceptApi';
import { fetchOntologyRootNodes, fetchPathsToConcepts } from '../../../api/content/OntologyApi';
import { Dialog } from "primereact/dialog";
import $ from 'jquery';
import { APP_PROPERTIES } from "../../../properties";
import { isOcidWithinFilterRanges } from "../util/concepts";


// --- switch between "matches only" and "complete tree" view --- //
const treeViews = [
  { name: 'Matches only', code: 'sub' },
  { name: 'Complete', code: 'compl' }
];

const initialState = {
  // --- search form --- //
  isSearchInitiated: false,
  searchTerm: null,
  domains: null,
  // --- concept matches --- //
  directMatches: null,
  fetchingDirectMatches: false,
  // 
  selectedConcepts: {},
  selectedConceptsByDomain: {},
  // --- concept details --- //
  selectedConcept: null,
  fetchingConceptDetails: false,
  // --- tree view --- //
  nodes: [],
  toggleAction: TOGGLE_TREE_NONE,
  treeView: treeViews[0],
  family: 'none',
  fetchingOntRootNodes: false,
  fetchingOntNodePaths: false
};

class OntologyBrowser extends Component {

  constructor(props) {
    super(props);

    this.state = initialState;

    this.growl = createRef();
  }


  componentDidMount = async () => {
    if (this.props.domains) {
      const domains = this.props.domains.map(domain => (domain.value));
      if (this.props.initialOcids) {
        await this.onOcidsSearchSubmit(this.props.initialOcids, domains);
      }
      else if (this.props.initialSearchTerm) {
        await this.onSearchSubmit(this.props.initialSearchTerm, domains);
      }
      else if (this.props.loadOntologiesOnStart) {
        await this.onSearchSubmit(null, domains);
      }
    }
  }

  componentDidUpdate = async (nextProps) => {
    if (nextProps.resetBrowser && this.props.resetBrowser !== nextProps.resetBrowser) { // 
      this.setState({
        // --- search form --- //
        searchTerm: null,
        // --- concept matches --- //
        directMatches: null,
        // 
        selectedConcepts: {},
        selectedConceptsByDomain: {},
        // --- concept details --- //
        selectedConcept: null,
        // --- tree view --- //
        toggleAction: TOGGLE_TREE_NONE,
      });
    }
  }


  // ----------------------------------------------------------------------- //
  // --- actions ----------------------------------------------------------- //
  // ----------------------------------------------------------------------- //
  /**
   * Searches for a term in given domains. 
   * Result contains all paths from the root(s) to the matching concepts.
   */
  onSearchSubmit = async (searchTerm, domains) => {
    // --- no domains selected -> show warning --- //
    if (!domains || domains.length === 0) {
      this.growl.current.show({ severity: 'warn', summary: 'No active domains', detail: 'Please select at least one domain.' });
      return;
    }

    // --- update search status and current search term; reset form --- //
    this.setState({
      isSearchInitiated: true,
      searchTerm: searchTerm,
      domains: domains,
      selectedConcept: null,
      directMatches: {}
    });

    // --- if search term is not empty -> fetch all paths to matching concepts --- //
    if (searchTerm) {
      // --- update direct matches --- //
      const directMatches = await this.fetchDirectMatches(searchTerm, domains);

      // --- expand all tree nodes; tree contains only matching paths --- //
      if (directMatches && Object.keys(directMatches).length > 0) {
        await this.fetchNodePaths(directMatches);
        this.setState({
          toggleAction: TOGGLE_TREE_EXPAND
        });
      }
      else {
        //human g protein-coupled receptor for asthma susceptibility
        this.growl.current.show({ severity: 'warn', summary: 'No match.', detail: 'No matching concept found in our ontologies.' });
      }
    }
    // --- if search term is empty -> load root nodes of all given domains --- //
    else {
      await this.fetchRootNodes(domains);
      // --- collapse all tree nodes; tree contains only ontology root nodes --- //
      this.setState({
        toggleAction: TOGGLE_TREE_COLLAPSE
      });
    }

    if (this.props.onSearch) {
      this.props.onSearch();
    }
  }

  /**
   * Searches for a list of OCIDs in given domains. 
   * Result contains all paths from the root(s) to the matching concepts.
   */
  onOcidsSearchSubmit = async (ocids, domains) => {
    // --- update search status and current search term; reset form --- //
    this.setState({
      isSearchInitiated: true,
      searchTerm: 'ocids',
      domains: domains,
      selectedConcept: null,
      directMatches: {}
    });

    // --- if OCIDs array is not empty -> fetch all paths to matching concepts --- //
    if (!isArrayEmpty(ocids)) {
      // --- update direct matches --- //
      const request = createConceptDataRequest(ocids);
      const conceptsData = await fetchConceptsData(request, false, false, false, null, null, null, this.props.rangeOcids);

      const directMatches = {};
      const selConcepts = [];
      conceptsData?.payload?.forEach(concept => {
        if (!directMatches[concept.domain]) {
          directMatches[concept.domain] = [];
        }
        directMatches[concept.domain].push(concept);
        selConcepts.push(concept);
        //this.onConceptSelect(concept);
      });
      // --- select all concepts at once --- //
      if (!isArrayEmpty(selConcepts)) {
        this.onConceptsSelect(selConcepts);
      }

      // --- expand all tree nodes; tree contains only matching paths --- //
      if (Object.keys(directMatches).length > 0) {
        await this.fetchNodePaths(directMatches);
        this.setState({
          toggleAction: TOGGLE_TREE_EXPAND,
          directMatches: directMatches,
        });
        // --- load data of first concept --- //
        await this.fetchConceptDetails(Object.values(directMatches)[0][0]?.ocid);
      }
      else {
        this.growl.current.show({ severity: 'warn', summary: 'No match.', detail: 'No matching concept found in our ontologies.' });
      }
    }
    // --- if OCIDs array is empty -> load root nodes of given domains --- //
    else {
      await this.fetchRootNodes(domains);
      // --- collapse all tree nodes; tree contains only ontology root nodes --- //
      this.setState({
        toggleAction: TOGGLE_TREE_COLLAPSE
      });
    }
  }

  /**
   * Selection state of search domains changed.
   */
  onSelectDomains = (domains) => {
    this.setState({
      domains: domains
    });
  }

  /**
   * Fetches ontology class details if a node is selected.
   */
  onNodeSelect = async (node) => {
    // --- get OCID; ignore paging nodes --- //
    const ocid = (node && node.type !== 'nav' && node.data) ? node.data.ocid : null;
    // --- fetch concept data for selected node's OCID --- //   
    await this.fetchConceptDetails(ocid);
  }

  /**
   * Submits search for OCID and fetches its concept data.
   */
  onConceptSearchSubmit = async (ocid) => {
    if (ocid) {
      // --- submit search for this OCID --- //
      await this.onSearchSubmit(ocid, this.state.domains);
    }
    // --- select concept with this OCID --- //
    await this.fetchConceptDetails(ocid);
  }


  /**
   * Adds/removes concept to/from selection.
   */
  onConceptSelect = async (concept) => {
    let selectedConcepts;

    // --- if concept is currently selected -> remove from selection --- //
    if (this.state.selectedConcepts && concept.ocid in this.state.selectedConcepts) {
      selectedConcepts = { ...this.state.selectedConcepts };
      delete selectedConcepts[concept.ocid];
    }
    // --- otherwise add to selection --- //
    else {
      if (this.props.selectionMode === 'multiple') {
        selectedConcepts = this.state.selectedConcepts ? { ...this.state.selectedConcepts } : {};
        selectedConcepts[concept.ocid] = concept;
      }
      else if (this.props.selectionMode === 'single') {
        selectedConcepts = {};
        selectedConcepts[concept.ocid] = concept;
      }
    }

    // --- store selected concepts in map sorted by domains --- //
    let selectedConceptsByDomain = {};
    if (selectedConcepts) {
      for (const concept of Object.values(selectedConcepts)) {
        if (!(concept.domain in selectedConceptsByDomain)) {
          selectedConceptsByDomain[concept.domain] = [];
        }
        selectedConceptsByDomain[concept.domain].push(concept);
      }
    }

    this.setState({
      selectedConcepts: selectedConcepts,
      selectedConceptsByDomain: selectedConceptsByDomain
    });
  }

  /**
   * Adds/removes multiple concepts to/from selection.
   */
  onConceptsSelect = async (concepts) => {
    let selectedConcepts = this.state.selectedConcepts ? { ...this.state.selectedConcepts } : {};

    concepts?.forEach(concept => {
      // --- if concept is currently selected -> remove from selection --- //
      if (selectedConcepts && selectedConcepts[concept.ocid]) {
        delete selectedConcepts[concept.ocid];
      }
      // --- otherwise add to selection --- //
      else {
        if (this.props.selectionMode === 'multiple') {
          selectedConcepts[concept.ocid] = concept;
        }
        else if (this.props.selectionMode === 'single') {
          selectedConcepts = {};
          selectedConcepts[concept.ocid] = concept;
        }
      }
    });

    // --- store selected concepts in map sorted by domains --- //
    let selectedConceptsByDomain = {};
    if (selectedConcepts) {
      for (const concept of Object.values(selectedConcepts)) {
        if (!(concept.domain in selectedConceptsByDomain)) {
          selectedConceptsByDomain[concept.domain] = [];
        }
        selectedConceptsByDomain[concept.domain].push(concept);
      }
    }

    this.setState({
      selectedConcepts: selectedConcepts,
      selectedConceptsByDomain: selectedConceptsByDomain
    });
  }

  /**
   * Adds/removes concepts to/from selection.
   * @param {*} concepts the current concepts
   * @param {*} select either select or deselect all at once
   */
  onConceptsSelectAll = async (concepts, select = true) => {

    let selectedConcepts = this.state.selectedConcepts ? { ...this.state.selectedConcepts } : {};

    // --- add all concepts to selected concepts --- //
    if (concepts) {
      concepts.forEach(concept => {
        if (select) {
          selectedConcepts[concept.ocid] = concept;
        }
        else {
          delete selectedConcepts[concept.ocid];
        }
      });
    }

    // --- store selected concepts in map sorted by domains --- //
    let selectedConceptsByDomain = {};
    if (selectedConcepts) {
      for (const concept of Object.values(selectedConcepts)) {
        if (!(concept.domain in selectedConceptsByDomain)) {
          selectedConceptsByDomain[concept.domain] = [];
        }
        selectedConceptsByDomain[concept.domain].push(concept);
      }
    }

    this.setState({
      selectedConcepts: selectedConcepts,
      selectedConceptsByDomain: selectedConceptsByDomain
    });
  }


  /**
   * Changes the tree's current toggle status. 
   * E.g. A search for a term will result in an expanded tree. 
   * An empty search will load all ontology root nodes which should be collapsed.
   * After the initial expansion/collapse of the nodes the state should 
   * not be changed until the next search.
   */
  changeToggleAction = (toggleAction) => {
    if (this.state.toggleAction !== toggleAction) {
      this.setState({
        toggleAction: toggleAction
      });
    }
  }


  /**
   * 
   */
  onTreeChanged = (newNodes) => {
    this.setState({
      nodes: newNodes
    });
  }

  // ----------------------------------------------------------------------- //
  // --- fetching data ----------------------------------------------------- //
  // ----------------------------------------------------------------------- //
  /**
   * Fetches root nodes of all available ontologies to fill the tree.
   */
  fetchRootNodes = async (domains) => {

    // --- start fetching --- //
    this.setState({ fetchingOntRootNodes: true });

    // --- fetch root nodes of all available ontologies --- //
    const response = await fetchOntologyRootNodes(domains);
    const nodes = checkResultAndGetPayload(response, this.growl);

    // --- end fetching --- //
    this.setState({
      nodes: nodes,
      fetchingOntRootNodes: false
    });

    return nodes;
  }


  /**
   * Fetches direct concept matches of a term in given domains.
   */
  fetchDirectMatches = async (term, domains) => {
    // --- start fetching --- //
    this.setState({
      directMatches: {},
      fetchingDirectMatches: true
    });

    // --- fetch direct matches of term in given domains --- //
    const resultMatches = await fetchDirectMatches(term, domains, this.props.rangeOcids, false, true);
    const directMatches = checkResultAndGetPayload(resultMatches, this.growl);

    // --- preselect first match in list and load details --- //
    if (directMatches && !isArrayEmpty(Object.values(directMatches)) && Object.values(directMatches)[0]) {
      this.fetchConceptDetails(Object.values(directMatches)[0][0].ocid, true);
    }

    // --- end fetching --- //
    this.setState({
      directMatches: directMatches,
      fetchingDirectMatches: false
    });

    return directMatches;
  }

  /**
   * Fetches paths from all matching nodes up to the root nodes.
   */
  fetchNodePaths = async (directMatches) => {
    // --- start fetching --- //
    this.setState({
      nodes: [],
      fetchingOntNodePaths: true
    });

    // --- fetch tree paths for matching concepts --- //
    const result = await fetchPathsToConcepts(directMatches);
    const nodes = checkResultAndGetPayload(result, this.growl);

    // --- end fetching --- //
    this.setState({
      nodes: nodes,
      fetchingOntNodePaths: false
    });

    //console.log('node paths: ' + JSON.stringify(nodes));

    return nodes;
  }


  /**
   * Fetches concept details for given ocid. if scroll is true 
   * scrolling to the concept will be triggered later.
   */
  fetchConceptDetails = async (ocid, scroll) => {
    // --- start fetching --- //
    this.setState({
      selectedConcept: null,
      fetchingConceptDetails: true
    });

    // --- fetch concept data for OCID --- //
    const concept = await this.ocidToConcept(ocid, true, 0);

    // --- update selected concept and selected nodes in tree --- //
    this.setState({
      selectedConcept: concept,
      fetchingConceptDetails: false,
      scroll: scroll
    });

    return concept;
  }

  /** 
   * Fetches data for an OCID with or without extended data and a given child offset.
   */
  ocidToConcept = async (ocid, withExtendedData, offset) => {
    if (ocid) {
      const request = createConceptDataRequest([ocid], true, withExtendedData, true, true, offset, this.props.numOfChildNodes, true);
      const response = await fetchConceptData(request, true, false, false, null, this.props.rangeOcids); // APP_PROPERTIES.CHEMISTRY.INCLUDE_MCULE_LINKS
      const concept = checkResultAndGetPayload(response, this.growl);
      return concept;
    }
    return null;
  }


  /**
   * Maybe this can be done in the OntologyBrowserView component? 
   * If fromDiseaseMaps|| fromDiseaseMapsBlacklist: onSubmit = this.props.callbackFromSubmit
   * else: onSubmit = this.onSubmit
   */
  onSubmit = () => {
    const selectedConcepts = (this.state.selectedConcepts) ? { ...this.state.selectedConcepts } : [];
    this.props.onSubmit(selectedConcepts);
  }


  /**
   * 
   */
  onClearSelectedConcepts = () => {

    this.setState({
      selectedConcepts: [],
      selectedConceptsByDomain: {}
    })
  }


  /**
   * Computes height for dialog content.
   */
  computeAvailableContentHeight = (containerID) => {
    // --- height of the dialog --- //
    let containerContentHeight = $('#' + containerID).height();

    const searchInputHeight = this.props.allowSearchInOntologies ? 170 : 65;

    // --- compute height left for content --- //
    let availableContentHeight = 0;
    if (containerContentHeight) {
      availableContentHeight = containerContentHeight - searchInputHeight;
    }

    return availableContentHeight;
  }

  computeSelectedConceptsHeight = () => {

    const selConceptsCellHeight = $('#selConceptsCell').height();
    const selConceptsCellPaddingStrg = $('#selConceptsCell').css('padding-top');
    let selConceptsCellPadding = 13;
    if (selConceptsCellPaddingStrg) {
      selConceptsCellPadding = 2 * selConceptsCellPaddingStrg.replace('px', '');
    }
    return (selConceptsCellHeight + selConceptsCellPadding);
  }

  /**
   * 
   */
  render() {
    const isFetchingTreeData = this.state.fetchingOntRootNodes || this.state.fetchingOntNodePaths;
    const numOfSelConcepts = this.state.selectedConcepts ? Object.keys(this.state.selectedConcepts).length : 0;

    const { numOfChildNodes, containerID, submitBtnLabel, submitBtnCssClass, submitBtnIcon,
      containerHeaderHeight, allowConceptSearchByClick, allowSearchInOntologies, placeholderMatches } = this.props;

    const minusHeight = containerHeaderHeight ? containerHeaderHeight : 0;
    const availableContentHeight = this.computeAvailableContentHeight(containerID) - minusHeight - 30;
    const selConceptsCellHeight = this.computeSelectedConceptsHeight();
    //console.log('selConceptsCellPadding: ', selConceptsCellPadding);
    const test = this.state.selectedConcept?.preferredName ? 47 : 0;
    let detailsContentHeight = availableContentHeight - selConceptsCellHeight + test;
    detailsContentHeight = detailsContentHeight ? detailsContentHeight : 0;

    //const baseHeaderLabelSelConcepts = 'Selected'; //this.props.selectionMode === 'single' ? 'Selected' : 'Selected';

    //console.log(this.props)

    //console.log(containerID)

    return (
      <>
        <Toast ref={this.growl} />

        <div className="grid dashboard" style={{ padding: '0px 10px' }}>
          <div className="content-section implementation" style={{ width: "100%" }}>
            <div className="grid" style={{ background: 'white' }}>
              {allowSearchInOntologies ?
                <div className="col-12" style={{ marginTop: "0px" }}>
                  <OntologySearch
                    initialSearchTerm={this.props.initialSearchTerm}
                    domainSelectItems={this.props.domains}
                    placeholder={this.props.placeholder}
                    onSelectDomains={this.onSelectDomains}
                    onSubmitSearch={this.onSearchSubmit}
                    onClear={this.onClearSelectedConcepts}
                    domainColors={this.props.domainColors}
                    domainLabelsMap={this.props.domainLabelsMap}
                    rangeOcids={this.props.rangeOcids}
                  />
                </div> : null
              }
              {
                this.state.isSearchInitiated ?
                  <>
                    {/*  sm:col-12 lg:col-5 xl:col-5 */}
                    <div className="col-5">
                      <div className="task-box task-box-dark-blue">
                        <div className="task-box-header medium">Tree</div>
                        <div id="treeView" className="task-box-content"
                          style={{ height: availableContentHeight, overflow: 'auto' }}>
                          {
                            this.state.nodes && this.state.nodes.length > 0 ?
                              <React.Fragment>

                                {/*this.state.searchTerm ?
                                  <div className="col-12" style={{ textAlign: 'left', padding: "10px 5px 10px 5px" }}>
                                    <RadioButton
                                      value="none"
                                      name={'None'}
                                      onChange={(e) => this.setState({ family: e.value })}
                                      checked={this.state.family === 'none'}
                                    />
                                    <div style={{ display: 'inline-block', position: 'relative', top: 2, marginRight: 10 }}
                                      title="Show paths to matching concepts only.">
                                      Matching paths
                                      </div>
                                    <RadioButton
                                      value="with"
                                      name={`Test`}
                                      onChange={(e) => this.setState({ family: e.value })}
                                      checked={this.state.family === 'with'}
                                    />
                                    <div style={{ display: 'inline-block', position: 'relative', top: 2 }}
                                      title="Show complete ontologies.">
                                      Complete ontology
                                      </div>
                                  </div> : null
                                */}
                                <div style={{ textAlign: 'left', width: 'fit-content', minWidth: '100%' }}>
                                  <OntologyTree
                                    nodes={this.state.nodes}
                                    loading={isFetchingTreeData}
                                    numOfChildNodes={numOfChildNodes}
                                    onNodeSelect={this.onNodeSelect}
                                    toggleAction={this.state.toggleAction}
                                    changeToggleAction={this.changeToggleAction}
                                    ocidToConcept={this.ocidToConcept}
                                    selectedConcept={this.state.selectedConcept}
                                    onTreeChanged={this.onTreeChanged}
                                    jumpToElement={this.state.scroll && this.state.selectedConcept ? this.state.selectedConcept.ocid : null}
                                    domainColors={this.props.domainColors}
                                  />
                                </div>
                              </React.Fragment>
                              :
                              <div>
                                {isFetchingTreeData ? 'Loading...' : 'No matches found.'}
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                    {/*  sm:col-12 lg:col-4 xl:col-4 */}
                    <div className="col-3" style={{ background: 'white' }}>
                      {allowSearchInOntologies ?
                        <div className="task-box task-box-dark-blue">
                          <div className="task-box-header medium">Result list</div>
                          <div className="task-box-content"
                            style={{ height: availableContentHeight, overflow: 'auto', lineHeight: 1.5 }}>
                            {
                              this.state.searchTerm ?
                                <OntologyClassList
                                  conceptsPerDomainMap={this.state.directMatches}
                                  highlightOcid={this.state.selectedConcept ? this.state.selectedConcept.ocid : null}
                                  onConceptClick={this.fetchConceptDetails}
                                  selectionMode={this.props.selectionMode}
                                  selectedConcepts={this.state.selectedConcepts}
                                  onConceptSelect={this.onConceptSelect}
                                  onConceptsSelectAll={this.onConceptsSelectAll}
                                  emptyMessage={'No matches found. Please try a different term or OCID.'}
                                  loading={this.state.fetchingDirectMatches}
                                  loadingMessage={'Loading...'}
                                  domainColors={this.props.domainColors}
                                  domainLabelsMap={this.props.domainLabelsMap}
                                />
                                :
                                <div>
                                  {placeholderMatches || 'Search for a specific term or click one of the search icons in the details view.'}
                                </div>
                            }
                          </div>
                        </div> : null
                      }
                    </div>
                    <div className="col-4">
                      <div className="grid">
                        <div className="col-12" style={{}}>
                          <div className="task-box task-box-dark-blue">
                            {!this.state.selectedConcept?.preferredName && <div className="task-box-header medium">
                              Concept details
                            </div>}
                            <div id="ontClassDetails" className="task-box-content"
                              //style={{ height: (availableContentHeight - 100 - 44), overflow: 'auto', padding: '15px' }}
                              //style={{ height: (availableContentHeight - 150 - 50), padding: '15px' }}
                              style={{ height: detailsContentHeight, padding: '15px', lineHeight: 1.5 }}
                            >
                              <OntologyClassDetails
                                ontClass={this.state.selectedConcept}
                                numOfEntries={numOfChildNodes}
                                allowConceptSearchByClick={allowConceptSearchByClick}
                                onConceptSearchSubmit={this.onConceptSearchSubmit}
                                onConceptSelect={this.onConceptSelect}
                                selectedConcepts={this.state.selectedConcepts}
                                selectionMode={this.props.selectionMode}
                                emptyMessage={'Click an entry from the tree or result list view to get more information about this concept.'}
                                loading={this.state.fetchingConceptDetails}
                                loadingMessage={'Loading...'}
                                domainColors={this.props.domainColors}
                                rangeOcids={this.props.rangeOcids}
                              />
                            </div>
                          </div>
                        </div>
                        <div id="selConceptsCell" className="col-12" style={{ margin: 'auto 0' }}>
                          <div className="task-box task-box-dark-blue" style={{ margin: 'auto 0' }}>
                            {/*<div className="task-box-header medium">{`${baseHeaderLabelSelConcepts}`}</div>*/}
                            <div className="task-box-content" style={{ height: 'auto', margin: 0, padding: '0 0 0 10px' }}>
                              <div className="grid" style={{ margin: 'auto 0' }}>
                                {numOfSelConcepts > 0 ?
                                  <div className="col-fixed textAlignLeft" style={{ margin: 'auto 0' }}>
                                    <a title="Click to see selected concepts"
                                      onClick={(e) => this.setState({ displaySelectedConceptsDialog: true })}
                                    >{`${numOfSelConcepts} ${numOfSelConcepts > 1 ? 'concepts' : 'concept'} selected`}</a>
                                  </div>
                                  :
                                  <div className="col-fixed textAlignLeft" style={{ margin: 'auto 0' }}>
                                    {'No concept selected'}
                                  </div>
                                }
                                <div className="col textAlignRight">
                                  <Button className="p-button-secondary p-button-sm"
                                    disabled={numOfSelConcepts === 0}
                                    onClick={() => this.onClearSelectedConcepts()}
                                    label="Clear"></Button>
                                  {submitBtnIcon ?
                                    <Button
                                      icon={submitBtnIcon}
                                      onClick={() => this.onSubmit()}
                                      className={`${submitBtnCssClass}`}
                                      label={submitBtnLabel}
                                      disabled={numOfSelConcepts === 0}
                                      style={{ width: 'auto', marginLeft: 10 }} />
                                    :
                                    <Button
                                      onClick={() => this.onSubmit()}
                                      className={`${submitBtnCssClass}`}
                                      label={submitBtnLabel}
                                      disabled={numOfSelConcepts === 0}
                                      style={{ width: 'auto', marginLeft: 10 }} />
                                  }
                                </div>
                              </div>
                              {/*
                                <OntologyClassList
                                  conceptsPerDomainMap={this.state.selectedConceptsByDomain}
                                  highlightOcid={this.state.selectedConcept ? this.state.selectedConcept.ocid : null}
                                  onConceptClick={this.fetchConceptDetails}
                                  selectionMode={this.props.selectionMode}
                                  selectedConcepts={this.state.selectedConcepts}
                                  onConceptSelect={this.onConceptSelect}
                                  selectionLabel="Remove"
                                  emptyMessage={'No concept selected.'}
                                >
                                  <Button className="p-button-secondary p-button-sm"
                                    disabled={numOfSelConcepts === 0}
                                    onClick={() => this.setState({ selectedConcepts: [], selectedConceptsByDomain: {} })}
                                    label="Clear"></Button>
                                  {submitBtnIcon ?
                                    <Button
                                      icon={submitBtnIcon}
                                      onClick={() => this.onSubmit()}
                                      className={submitBtnCssClass}
                                      label={submitBtnLabel}
                                      disabled={numOfSelConcepts === 0}
                                      style={{ width: 'auto', marginLeft: 10 }} />
                                    :
                                    <Button
                                      onClick={() => this.onSubmit()}
                                      className={submitBtnCssClass}
                                      label={submitBtnLabel}
                                      disabled={numOfSelConcepts === 0}
                                      style={{ width: 'auto', marginLeft: 10 }} />
                                  }
                                </OntologyClassList>
                                */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </> : null
              }
            </div>
          </div>
        </div>

        <Dialog
          style={{ maxHeight: '50vh', maxWidth: '50vw' }}
          focusOnShow={false}
          header='Selected concepts'
          visible={this.state.displaySelectedConceptsDialog}
          modal={true}
          onHide={(e) => this.setState({ displaySelectedConceptsDialog: false })}
        >
          <OntologyClassList
            conceptsPerDomainMap={this.state.selectedConceptsByDomain}
            highlightOcid={this.state.selectedConcept ? this.state.selectedConcept.ocid : null}
            onConceptClick={this.fetchConceptDetails}
            selectionMode={this.props.selectionMode}
            selectedConcepts={this.state.selectedConcepts}
            onConceptSelect={this.onConceptSelect}
            onConceptsSelectAll={this.onConceptsSelectAll}
            emptyMessage={'No concept selected.'}
            domainColors={this.props.domainColors}
            domainLabelsMap={this.props.domainLabelsMap}
          >
          </OntologyClassList>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
  domainColors: state.user.data.userDetails.department.selectedDomains,
})

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OntologyBrowser))