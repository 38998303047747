import { QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH } from "../../../../properties";
import { getDataFromLocalStorage, removeDataFromLocalStorage } from "../../util/localStorage";

/**
 * Loads a sentence analysis search from the local storage and converts it to a state object.
 * @returns 
 */
export const getSentenceAnalysisSearchFromStorage = () => {
    let savedSearchState = null;
    const savedSearchFromStorage = getDataFromLocalStorage(QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id);
    if (savedSearchFromStorage) {
        savedSearchState = savedSearchFromStorage.data ?
            savedSearchFromStorage.data : null;
        removeDataFromLocalStorage(QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id);
    }
    return savedSearchState;
}