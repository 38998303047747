import _ from "lodash";
import { MATCH_TYPE_EXACT, MATCH_TYPE_SIMILAR } from "./constants";



export const getDocumentsMapFromResponses = (responses, repositoryID, isExact) => {

    const matchType = isExact ? MATCH_TYPE_EXACT : MATCH_TYPE_SIMILAR;
    const documentsMap = {};
    responses?.forEach(response => {
        if (response?.payload?.results) {
            Object.entries(response.payload.results)?.forEach(([docID, res]) => {
                if (res.hitCount > 0) {
                    if (!documentsMap[docID]) {
                        documentsMap[docID] = {};
                    }
                    if (!documentsMap[docID][repositoryID]) {
                        documentsMap[docID][repositoryID] = {};
                    }
                    // if (!documentsMap[docID][repositoryID][matchType]) {
                    //     documentsMap[docID][repositoryID][matchType] = [];
                    // }
                    // documentsMap[docID][repositoryID][matchType].push(res);
                    res.topHit.hitCount = res.hitCount;
                    documentsMap[docID][repositoryID][matchType] = res.topHit;
                }
            });
        }
    });

    return documentsMap;
}


export const convertResults = (originalIDs = [], resultsMapExact = {}, resultsMapSimilar = {}) => {

    let resultsMap = {};
    resultsMap = _.merge(resultsMap, resultsMapExact);
    resultsMap = _.merge(resultsMap, resultsMapSimilar);

    const results = [];
    let index = 0;
    originalIDs.forEach(origID => {
        // necessary, but why??? -> clean up!!!
        addOriginalIDInfoToDocuments(resultsMapExact, origID, index);
        addOriginalIDInfoToDocuments(resultsMapSimilar, origID, index);
        addOriginalIDInfoToDocuments(resultsMap, origID, index);
        index++;

        const res = resultsMap ? resultsMap[origID] : {};
        results.push({ id: origID, results: res });
    });
    return results;
}


const addOriginalIDInfoToDocuments = (resultsMap = {}, origID, index) => {

    const resExact = resultsMap ? resultsMap[origID] : {};
    if (resExact) {
        const allRes = Object.values(resExact);
        if (allRes) {
            const allDocs = Object.values(allRes);
            allDocs?.forEach(specTypeDocs => {
                const typeDocs = Object.values(specTypeDocs);
                typeDocs?.forEach(doc => {
                    doc.origIndex = index;
                    doc.origDocumentID = origID;
                });
            });
        }
    }
}


export const getUnmatchedDocumentIDsForRepo = (resultsMap = {}, documentIDs = [], repoID) => {
    return documentIDs.filter(docID => !resultsMap[docID] || !resultsMap[docID][repoID]);
}


export const analyzeResults = (results, repositoriesData) => {
    // console.log('RESULTS: ', results);

    const duplicateDocuments = {};
    const uniqueDocuments = {};
    let matchingDocumentCount = 0;
    let similarDocumentCount = 0;
    let noDocumentCount = 0;

    results.forEach(result => {
        if (result?.results) {
            countDuplicates(result, duplicateDocuments, uniqueDocuments);

            if (hasExactMatch(result.results)) {
                matchingDocumentCount++;
            }
            else if (hasSimilarMatch(result.results)) {
                similarDocumentCount++;
            }
            else {
                noDocumentCount++;
            }
        }
        else {
            noDocumentCount++;
        }
    });

    const duplicateDocumentTuples = {};
    const duplicateDocumentsFiltered = {};
    Object.entries(duplicateDocuments).forEach(([key, origIDs]) => {
        // console.log(`${key} -> ${origIDs}`);
        if (origIDs?.length > 1) {
            duplicateDocumentsFiltered[key] = origIDs;

            const { repositoryID } = regenerateDuplicateIDKey(key);
            const origIDsSorted = origIDs.sort();
            const origIDsString = `${origIDsSorted.join(':::')}`;

            if (!duplicateDocumentTuples[repositoryID]) {
                duplicateDocumentTuples[repositoryID] = {};
            }
            duplicateDocumentTuples[repositoryID][origIDsString] = origIDsSorted;
        }
    });

    const duplicateDocumentTuplesSorted = [];
    repositoriesData.forEach(repo => {
        if (duplicateDocumentTuples[repo.id]) {
            duplicateDocumentTuplesSorted.push({ repoID: repo.id, repoLabel: repo.label, origDocIDs: duplicateDocumentTuples[repo.id] });
        }
    });

    return {
        matchingDocumentCount, similarDocumentCount, noDocumentCount, uniqueDocuments,
        duplicateDocuments: duplicateDocumentsFiltered, duplicateDocumentTuples: duplicateDocumentTuplesSorted
    };
}

export const generateDuplicateIDKey = (repositoryID, documentID) => {

    return `${repositoryID}:${documentID}`;
}


export const regenerateDuplicateIDKey = (key) => {

    const res = key.split(':');
    return { repositoryID: res[0], documentID: res[1] };
}


export const countDuplicates = (result, duplicateDocuments, uniqueDocuments) => {

    const origID = result.id;
    const results = result.results;

    Object.entries(results).forEach(([repoID, matchType]) => {
        Object.values(matchType)?.forEach(docData => {
            const key = generateDuplicateIDKey(repoID, docData.qualifiedOcDocId);
            if (!duplicateDocuments[key]) {
                duplicateDocuments[key] = [];
            }
            duplicateDocuments[key].push(origID);
            // duplicateDocuments[key].push({originalID: origID, documentData: docData });

            if (!uniqueDocuments[repoID]) {
                uniqueDocuments[repoID] = {};
            }
            uniqueDocuments[repoID][docData.qualifiedOcDocId] = true
        });
    });
}


export const hasExactMatch = (results = []) => {

    let hasMatch = false;
    Object.values(results).forEach(repoRes => {
        if (repoRes?.exact) {
            hasMatch = true;
        }
    });

    return hasMatch;
}


export const hasSimilarMatch = (results = []) => {

    let hasMatch = false;
    Object.values(results).forEach(repoRes => {
        if (repoRes?.similar) {
            hasMatch = true;
        }
    });

    return hasMatch;
}


export const countMatchingDocumentsForRepo = (results = [], repoID) => {

    let counter = 0;
    results.forEach(idResult => {
        if (idResult?.results && idResult.results[repoID] && idResult.results[repoID].exact) {
            counter++;
        }
    });

    return counter;
}


export const countSimilarDocumentsForRepo = (results = [], repoID) => {

    let counter = 0;
    results.forEach(idResult => {
        if (idResult?.results && idResult.results[repoID] && idResult.results[repoID].similar) {
            counter++;
        }
    });

    return counter;
}