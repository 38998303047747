/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import { APP_PROPERTIES } from '../../../properties';


class DocumentTitle extends Component {

    render() {

        const { document, onOpenDocument, onOpenPDFDocument } = this.props;

        return (
            document ?
                <div style={{ fontSize: '16px' }} >
                    <a id={`docTitle_${document.ocDocId}`}
                        onClick={(e) => onOpenDocument(document, e)}>{document.title}</a>
                    {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showAnnotatedPDFLink && onOpenPDFDocument ?
                        <span style={{ marginLeft: 20, float: 'right' }}>
                            <a onClick={(e) => onOpenPDFDocument(document, e)}>Annotated PDF</a>
                        </span> : null
                    }
                </div> : null
        )
    }
}

export default DocumentTitle;