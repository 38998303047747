import { APP_PROPERTIES } from '.';
import { SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY, SEARCH_FILTER_TYPE_FACETS_WITH_INPUT } from '../components/webapi/general/docsearch/searchConstants';

export const PATENT_FAMILIES = {
    OC: {
        groupByPrefix: 'pat_famOC',
        membersQryPrefix: 'pocfam',
        metadataName: 'Pat_oc-family',
        label: 'OC patent family',
        maxNumOfMembers: 100
    },
    DOCDB: {
        groupByPrefix: 'pat_famDOCDB',
        membersQryPrefix: 'pdocdbfam',
        metadataName: 'Pat_families-docdb',
        label: 'EPO patent family',
        maxNumOfMembers: 100
    }
};

export const SORT_FIELDS_BLOCKLIST = {
    'dsgrants': {
        'fundingamount': true,
        'projectnum': true
    }
}

export const DEFAULT_PATENT_FAMILY = PATENT_FAMILIES['OC'];

export const getPatentFamilyDataByPrefix = (groupByPrefix) => {
    return Object.values(PATENT_FAMILIES).find(patFam => patFam.groupByPrefix === groupByPrefix);
}


const readFilterValuesDefinitions = async () => {

    const FILTER_DEFINITIONS_FILE = `${APP_PROPERTIES.FRONTEND_URL}data/filter_value_definitions.csv?${Math.random()}`;
    //console.log('readFilterDefinitions');
    const filterValues = {};
    await fetch(FILTER_DEFINITIONS_FILE).then($ => $.text()).then(file => {
        //console.log('*file: ', file);
        const allLines = file.split(/\r\n|\n/);
        allLines.forEach((line) => {
            //console.log('line: ', line);
            if (line && !line.startsWith('#')) {
                const elements = line.split('\t');

                let [field, type, map_key, map_value, value, booster, label,
                    description, isDefault, url, repoAllowList] = elements;
                isDefault = isDefault == 'true';
                repoAllowList = repoAllowList ? repoAllowList.split(',') : null;
                repoAllowList = repoAllowList?.map(repo => repo.trim());

                if (type === 'KEY_VALUE_MAP') {
                    if (!filterValues.hasOwnProperty(field)) {
                        filterValues[field] = {};
                    }
                    filterValues[field][map_key] = map_value;
                }
                else if (type === 'OBJECT_MAP' || type === 'OBJECT_ARRAY') {
                    const obj = {
                        filterValue: value ? value : map_key,
                        filterValueLabel: label,
                    }
                    if (booster) {
                        obj.booster = booster;
                    }
                    if (description) {
                        obj.description = description;
                    }
                    if (isDefault) {
                        obj.isDefault = isDefault;
                    }
                    if (url) {
                        obj.url = url;
                    }
                    if (repoAllowList) {
                        obj.repoAllowList = repoAllowList;
                    }
                    if (type === 'OBJECT_MAP') {
                        if (!filterValues.hasOwnProperty(field)) {
                            filterValues[field] = {};
                        }
                        filterValues[field][map_key] = obj;
                    }
                    else if (type === 'OBJECT_ARRAY') {
                        if (!filterValues.hasOwnProperty(field)) {
                            filterValues[field] = [];
                        }
                        filterValues[field].push(obj);
                    }
                }
            }
        });
    });
    //console.log('text: ', text);
    //FILTERS.filters = filterValues;

    return filterValues;
}

export const readFilterDefinitions = async () => {

    const FILTER_VALUE_DEFINITIONS_FILE = `${APP_PROPERTIES.FRONTEND_URL}data/filter_definitions.csv?${Math.random()}`;
    const filterValuesDefinitions = await readFilterValuesDefinitions();
    const activeFacets = {};

    //console.log('readFilterDefinitions');
    const filterValues = {};
    await fetch(FILTER_VALUE_DEFINITIONS_FILE).then($ => $.text()).then(file => {
        //console.log('*file: ', file);
        const allLines = file.split(/\r\n|\n/);
        allLines.forEach((line) => {
            //console.log('line: ', line);
            if (line && !line.startsWith('#')) {
                const elements = line.split('\t');
                // --- remove first column (active status) --- //
                elements.shift();

                let [id, type, label, placeholder, def_valueLabels, def_values, facetID, extraFieldID, domains, termloc,
                    queryPrefix, fuzziness, minNumOfTerms, minNumOfConcepts, maxNumOfOcc, openOnDefault] = elements;
                openOnDefault = openOnDefault == 'true';

                if (id && type && label) {
                    filterValues[id] = {
                        id: id,
                        type: type,
                        label: label,
                        openOnDefault: openOnDefault
                    };
                    if (placeholder) {
                        filterValues[id].placeholder = placeholder;
                    }
                    if (queryPrefix) {
                        filterValues[id].queryPrefix = queryPrefix;
                    }
                    if (fuzziness) {
                        filterValues[id].fuzziness = fuzziness;
                    }
                    if (facetID) {
                        filterValues[id].facetID = facetID;
                    }
                    if (extraFieldID) {
                        filterValues[id].extraFieldID = extraFieldID;
                    }
                    if (domains) {
                        filterValues[id].domains = [domains];
                    }
                    if (termloc) {
                        filterValues[id].termloc = termloc;
                    }
                    if (minNumOfTerms) {
                        filterValues[id].minNumOfTerms = minNumOfTerms;
                    }
                    if (minNumOfConcepts) {
                        filterValues[id].minNumOfConcepts = minNumOfConcepts;
                    }
                    if (maxNumOfOcc) {
                        filterValues[id].maxNumOfOcc = maxNumOfOcc;
                    }
                    if (def_valueLabels && filterValuesDefinitions && filterValuesDefinitions[def_valueLabels]) {
                        filterValues[id].filterValueLabels = filterValuesDefinitions[def_valueLabels]
                    }
                    if (def_values && filterValuesDefinitions && filterValuesDefinitions[def_values]) {
                        filterValues[id].filterValues = filterValuesDefinitions[def_values]
                    }
                    // --- collect active facets --- //
                    if ((type === SEARCH_FILTER_TYPE_FACETS_EXAMPLES_ONLY || type === SEARCH_FILTER_TYPE_FACETS_WITH_INPUT) && facetID) {
                        activeFacets[facetID] = true;
                    }
                }
            }
        });
    });
    return { filterDefinitions: filterValues, activeFacets: activeFacets };
}

export const readRepositoryFilterMapping = async () => {

    const FILTER_REPOSITORY_MAPPING_FILE = `${APP_PROPERTIES.FRONTEND_URL}data/filter_repository_map.csv?${Math.random()}`;
    //console.log('readFilterDefinitions');
    const repositoryFilterMap = {};
    let repoIDs = [];
    await fetch(FILTER_REPOSITORY_MAPPING_FILE).then($ => $.text()).then(file => {
        //console.log('*file: ', file);
        const allLines = file.split(/\r\n|\n/);
        let lineCounter = 0;
        allLines.forEach((line) => {
            // --- extract repositories from row --- //
            if (lineCounter === 0) {
                //console.log('repo line: ', line);
                let repos = line.split('\t');
                //console.log('repos array: ', repos);
                if (repos) {
                    repos.shift();
                    repoIDs = repos;
                    repos.forEach(repoID => {
                        repositoryFilterMap[repoID] = [];
                    });
                }
                lineCounter++;
            }
            else if (!line.startsWith('#')) {
                const activeStatus = line.split('\t');
                const filterID = activeStatus.shift();
                activeStatus.forEach((activeStat, index) => {
                    if (activeStat === '1' && filterID && repoIDs[index] && repositoryFilterMap[repoIDs[index]]) {
                        repositoryFilterMap[repoIDs[index]].push(filterID);
                    }
                });
            }
        });
    });
    //console.log('repositoryFilterMap: ', repositoryFilterMap);
    //FILTERS.filters = filterValues;
    return repositoryFilterMap;
}

export const getFilterDefinitionForRepository = (repositoryFilterMap, repositoryName) => {

    if (repositoryFilterMap && repositoryName) {
        if (repositoryFilterMap[repositoryName]) {
            return repositoryFilterMap[repositoryName];
        }

        let repoPrefix = repositoryName.replace(/_.*$/g, '');
        if (repoPrefix && repositoryFilterMap[repoPrefix]) {
            return repositoryFilterMap[repoPrefix];
        }
    }

    return [];
}

export const METADATA_SORT_FIELDS = {
    patid: {
        metadataName: 'pat_document-id',
        type: 'string'
    },
    patAppDate: {
        metadataName: 'pat_application_date',
        type: 'date'
    },
    patPubDate: {
        metadataName: 'pat_publication_date',
        type: 'date'
    },
    pDate: {
        metadataName: 'pubdate',
        type: 'date'
    },
    title: {
        metadataName: 'title',
        type: 'string'
    },
    patFilingDate: {
        metadataName: 'pat_filing_date',
        type: 'date'
    },
    patPrioDate: {
        metadataName: 'pat_priority-claim_date',
        type: 'date'
    },
    startdate: {
        metadataName: 'startdate',
        type: 'date'
    },
    enddate: {
        metadataName: 'enddate',
        type: 'date'
    }
};
