
const SemanticSearchInfo = () => {

    return (
        <>
            <h3>Search in text</h3>

            <div className="grid" style={{padding:10, width: 800, paddingLeft: 7 }}>
                <p>
                    This search allows you to semantically and lexically find any concept or term in the document by typing and selecting from the semantic autocomplete. If a semantic concept is searched the concept itself, all synonyms and semantic child concepts  will get the color of the corresponding domain by default. You can change this color at any time to group different concepts. You can browse through the individual hits one by one or you can browse all hits together.
                </p>
            </div>

            <h5>Examples</h5>

            <div className="grid" style={{ marginTop: 20, width: 800 }}>
                <div className="col-fixed" style={{ width: 110 }}>
                    <span className="ne highlightNE" style={{ backgroundColor: '#F783AC' }}>Lymphoma</span>
                </div>
                <div className="col">
                    Currently highlighted matching term in domain-specific (default) or chosen color,
                    e.g. "Lymphoma" (disease) which is matching the search term "cancer".
                </div>
                <div className="breakRow"></div>
                <div className="col-fixed" style={{ width: 110 }}>
                    <span className="ne" style={{ backgroundColor: '#F783AC', border: '1px solid #F783AC' }}>Lymphoma</span>
                </div>
                <div className="col">
                    Other matching term in domain-specific (default) or chosen color,
                    e.g. "Lymphoma" (disease) which is matching the search term "cancer".
                </div>
            </div>
        </>
    )
}

export default SemanticSearchInfo;