import { isArrayEmpty, sortMapByKeys } from "../util";

/**
 * 
 * @param {*} text 
 * @param {*} matchIndex 
 * @returns 
 */
export const getMatchingParenthesis = (text, matchIndex) => {
    let stack = [];
    let map = {
        '(': ')',
        '[': ']',
        '{': '}'
    }

    for (let i = 0; i < text.length; i++) {

        // If character is an opening brace add it to a stack
        if (text[i] === '(' || text[i] === '{' || text[i] === '[') {
            stack.push({ char: text[i], index: i });
            //console.log('push: ', { par: text[i], index: i });

            //if (i === matchIndex)
            //    console.log('opening index: ', i);
        }
        //  If that character is a closing brace, pop from the stack, which will also reduce the length of the stack each time a closing parenthesis is encountered.
        else if (text[i] === ')' || text[i] === '}' || text[i] === ']') {
            //console.log('stack: ', stack);

            let last = stack.pop();
            //console.log('pop: ', last);

            if (i === matchIndex) {
                return last;
            }
            else if (last.index === matchIndex) {
                const closingParenthesis = { par: text[i], index: i };
                //console.log('closing: ', closingParenthesis);

                if (text[i] === map[last.char]) {
                    return closingParenthesis
                }
                else {
                    return null;
                }
            }

            //If the popped element from the stack, which is the last opening brace doesn’t match the corresponding closing brace in the map, then return false
            //if (text[i] !== map[last.char]) { return false };
        }
    }
    // By the completion of the for loop after checking all the parentheses of the text, at the end, if the stack is not empty then fail
    //if (stack.length !== 0) { return false };

    return null;
}


export const getMatchingParenthesesPairs = (text) => {

    const stack = [];
    let parenthesisPairs = {};
    const unmatchedParenthesesIndices = [];
    const result = {};

    let countOpeningParentheses = 0;
    let countClosingParentheses = 0;

    for (let i = 0; i < text.length; i++) {

        // If character is an opening parenthesis add it to a stack
        if (text[i] === '(' || text[i] === '{' || text[i] === '[') {
            stack.push({ parenthesis: text[i], index: i, level: stack.length });
            countOpeningParentheses++;
        }
        // If that character is a closing parenthesis, pop from the stack, 
        // which will also reduce the length of the stack each time a closing parenthesis is encountered.
        else if (text[i] === ')' || text[i] === '}' || text[i] === ']') {
            countClosingParentheses++;

            //console.log('stack: ', stack);
            let openingParenthesis = stack.pop();
            //console.log('pop: ', last);
            //console.log('***');
            //console.log('opening', openingParenthesis);
            //console.log('closing', { parenthesis: text[i], index: i });

            if (openingParenthesis) {
                parenthesisPairs[openingParenthesis.index] = {
                    openingParenthesis: openingParenthesis,
                    closingParenthesis: { parenthesis: text[i], index: i },
                    level: stack.length
                };
            }
            else {
                unmatchedParenthesesIndices.push(i);
            }
        }
    }

    if (!isArrayEmpty(stack)) {
        stack.forEach(openBr => { unmatchedParenthesesIndices.push(openBr.index) });
    }

    if (!isArrayEmpty(unmatchedParenthesesIndices)) {
        result.hasError = true;
        result.unmatchedParenthesesIndices = unmatchedParenthesesIndices;
    }

    parenthesisPairs = sortMapByKeys(parenthesisPairs);
    result.groups = Object.values(parenthesisPairs);

    //console.log('countOpeningParentheses', countOpeningParentheses);
    //console.log('countClosingParentheses', countClosingParentheses);
    //console.log('parenthesisPairs', Object.values(parenthesisPairs));

    return result;
}