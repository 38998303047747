import React from "react";
import FilterSectionTreeSelect from "../../../../../webapi/general/filters/FilterSectionTreeSelect";

const SearchFilterTypeTermloc = ({
  filter,
  selectedEntry,
  termlocs,
  termlocData,
  onChange,
}) => {
  const { id: filterID, placeholder } = filter;
  return (
    <FilterSectionTreeSelect
      filterID={filterID}
      filterEntries={termlocs}
      filterEntriesData={termlocData}
      selectedEntry={selectedEntry}
      onFilterInputValuesChange={onChange}
      placeholder={placeholder}
    />
  );
};
export default SearchFilterTypeTermloc;
