import { addThousandsSeparatorToNumber } from "../../../../../../../../../util";
import { ProgressSpinner } from "primereact/progressspinner";


const HitsColumn = ({
    numberOfHits,
    loading
}) => {
    return (
        <div className="flex align-items-center justify-content-end h-full">
          {loading ? (
            <div>
              <ProgressSpinner className="results-progress-spinner" />
            </div>
          ) : (
            <div>{addThousandsSeparatorToNumber(numberOfHits)}</div>
          )}
        </div>
      );
}

export default HitsColumn;