/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { Chart } from 'primereact/chart';
import { addThousandsSeparatorToNumber, isArrayEmpty, roundNumber, shortenLargeNumber } from "../../util";
import { APP_PROPERTIES } from "../../../../properties";

const BAR_COLOR_ACTIVE = '#4588c9';
const BAR_COLOR_INACTIVE = '#A3C8EC';

class StatisticsCharts extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        //console.log('props.statistics: ', props.statistics);
        this.labelsAbsolute = [];
        this.labelsRelative = [];
        this.datasetsAbsolute = [{
            label: '',
            //backgroundColor: BAR_COLOR_ACTIVE,
            data: [],
            years: [],
            backgroundColor: []
        }];
        this.datasetsRelative = [{
            label: '',
            //backgroundColor: BAR_COLOR_ACTIVE,
            data: [],
            years: [],
            backgroundColor: []
        }];
        this.maxValueAbsolute = 0;
        this.maxValueRelative = 0;
        //let index = 0;
        if (props.statistics) {
            for (var entry of props.statistics) {
                if (entry.cnt > this.maxValueAbsolute) {
                    this.maxValueAbsolute = entry.cnt;
                }
                //let proportion = Math.round(entry.proportion * 100 * 100) / 100;
                let proportion = this.roundProportion(entry.proportion);
                if (proportion > this.maxValueRelative) {
                    this.maxValueRelative = proportion;
                }
                //this.datasetsAbsolute.push(index === 0 || index === props.statistics.length - 1 ? entry.date : '');
                //this.labelsRelative.push(index === 0 || index === props.statistics.length - 1 ? entry.date : '');
                this.labelsAbsolute.push('');
                this.labelsRelative.push('');

                this.datasetsAbsolute[0].data.push(entry.cnt);
                this.datasetsAbsolute[0].years.push(entry.date);
                this.datasetsAbsolute[0].backgroundColor.push(entry.opaque ? BAR_COLOR_INACTIVE : BAR_COLOR_ACTIVE);

                this.datasetsRelative[0].data.push(proportion);
                this.datasetsRelative[0].years.push(entry.date);
                this.datasetsRelative[0].backgroundColor.push(entry.opaque ? BAR_COLOR_INACTIVE : BAR_COLOR_ACTIVE);
                //index++;
            };
        }
        this.maxValueShortened = shortenLargeNumber(this.maxValueAbsolute, 1);
        this.maxValueRelative = shortenLargeNumber(this.maxValueRelative, 5);

        this.basicDataAbsolute = {
            labels: this.labelsAbsolute,
            datasets: this.datasetsAbsolute
        };
        this.basicDataRelative = {
            labels: this.labelsRelative,
            datasets: this.datasetsRelative
        };

        this.optionsAbsolute = this.getLightThemeAbsolute(this.maxValueAbsolute);
        this.optionsRelative = this.getLightThemeRelative(this.maxValueRelative);

        this.growl = createRef();
    }

    roundProportion = (proportionOrig) => {
        let proportionInPercent = proportionOrig * 100;
        //console.log('proportionInPercent: ', proportionInPercent);
        //console.log('proportionInPercent >= 0: ', (proportionInPercent >= 0));
        let proportion = roundNumber(proportionInPercent, 10);
        //console.log('proportion10: ', proportion);
        if (proportion > 0.00000001 && proportion <= 0.0000001) { proportion = roundNumber(proportionInPercent, 9) }
        else if (proportion > 0.0000001 && proportion <= 0.000001) { proportion = roundNumber(proportionInPercent, 8) }
        else if (proportion > 0.000001 && proportion <= 0.00001) { proportion = roundNumber(proportionInPercent, 7) }
        else if (proportion > 0.00001 && proportion <= 0.0001) { proportion = roundNumber(proportionInPercent, 6) }
        else if (proportion > 0.0001 && proportion <= 0.001) { proportion = roundNumber(proportionInPercent, 5) }
        else if (proportion > 0.001 && proportion <= 0.01) { proportion = roundNumber(proportionInPercent, 4) }
        else if (proportion > 0.01 && proportion <= 0.1) { proportion = roundNumber(proportionInPercent, 3) }
        else if (proportion > 0.1) { proportion = roundNumber(proportionInPercent, 2) }
        //proportionInPercent < 1 ? roundNumber(proportionInPercent, 4) : roundNumber(proportionInPercent, 2);
        return proportion;
    }


    getLightThemeAbsolute(maxY) {
        let basicOptionsAbsolute = {
            maintainAspectRatio: false,
            aspectRatio: 5,
            plugins: {
                legend: {
                    display: false
                    /*labels: {
                      color: '#495057'
                   }*/
                },
                tooltip: {
                    usePointStyle: true,
                    callbacks: {
                        label: function (tooltipItem) {
                            return ` ${tooltipItem?.dataset?.years[tooltipItem.dataIndex]}: ${addThousandsSeparatorToNumber(tooltipItem.raw)}${''}`
                        },
                        labelPointStyle: function (tooltipItem) {
                            return {
                                pointStyle: 'circle',
                                rotation: 0
                            }
                        }
                    }
                }
            },
            scales: {
                x: {
                    //display: false,
                    ticks: { display: false },
                    grid: { display: false }
                    /*
                    ticks: {
                        color: '#757575', // #495057 // #77B0E9
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0
                    },
                    grid: {
                        display: false,
                        color: '#ebedef',
                    }
                    */
                },
                y: {
                    display: false,
                    beginAtZero: true,
                    max: maxY
                }
                /*
                y: {
                    //position: 'left',
                    ticks: {
                        color: '#495057',
                        autoSkip: true,
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
                */
            }
        };

        return {
            basicOptionsAbsolute
        }
    }

    getLightThemeRelative(maxY) {
        let basicOptionsRelative = {
            maintainAspectRatio: false,
            aspectRatio: 5,
            plugins: {
                legend: { display: false },
                tooltip: {
                    usePointStyle: true,
                    callbacks: {
                        label: function (tooltipItem) {
                            return ` ${tooltipItem?.dataset?.years[tooltipItem.dataIndex]}: ${tooltipItem.raw >= 1000 ? addThousandsSeparatorToNumber(tooltipItem.raw) : tooltipItem.raw}${'%'}`
                        },
                        labelPointStyle: function (tooltipItem) {
                            return {
                                pointStyle: 'circle',
                                rotation: 0
                            }
                        }
                    }
                }
            },
            scales: {
                x: {
                    //display: false,
                    ticks: { display: false },
                    grid: { display: false }
                    /*
                    ticks: {
                        color: '#757575', // #495057 // #77B0E9
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0
                    },
                    grid: {
                        display: false,
                        color: '#ebedef',
                    }
                    */
                },
                y: {
                    display: false,
                    beginAtZero: true,
                    max: maxY
                }
            }
        };

        return {
            basicOptionsRelative
        }
    }

    showStatistics = (searchName, query, filterQueries, selectedRepository) => {

        const searchItem = {
            'searchName': searchName,
            'selectedRepository': selectedRepository
        };
        if (!isArrayEmpty(filterQueries)) {
            searchItem.searchParameters = { query, filterQueries };
        } else {
            searchItem.searchParameters = { query };
        }
        localStorage.setItem('searchParameters', JSON.stringify(searchItem));
        window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/trendanalysis`, "_blank")
    }

    render() {

        const { displayName, query, filterQueries, showRelativeValues, showTrendAnalysisLink, selectedRepository } = this.props;
        const { basicOptionsAbsolute } = this.optionsAbsolute;
        const { basicOptionsRelative } = this.optionsRelative;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className="grid" style={{ marginLeft: 0, color: '#757575', marginBottom: 0 }}>

                    {/* 
                    <div className="col" style={{ padding: 0, paddingLeft: 20, margin: '0 auto' }}><hr /></div>
                    <div className="col-fixed"><div title={this.maxValueAbsolute}>Publications per year | max. ~{this.maxValueShortened}</div></div>
                    <div className="col" style={{ padding: 0, paddingRight: 20, margin: '0 auto' }}><hr /></div>
                    <div className="breakRow" />
                    <div className="col-12" style={{ paddingTop: 0, marginTop: 0, top: -10, position: 'relative' }}>
                        <Chart type="bar" data={this.basicDataAbsolute} options={basicOptions} />
                    </div>
                    */}
                    <div className="col-fixed textAlignLeft" style={{ paddingLeft: 10, marginBottom: 0 }}>Last 30 years</div>
                    <div className="col textAlignRight" style={{ paddingRight: 10, marginBottom: 0 }}>
                        {showTrendAnalysisLink ?
                            <a title="Open trend analysis for current search in new tab"
                                onClick={() => this.showStatistics(displayName, query, filterQueries, selectedRepository)}
                            >Trend analysis</a> : null}
                    </div>
                    <div className="breakRow" />

                    <div className="col-12" style={{ paddingTop: 0, marginTop: 10, marginBottom: 0, paddingBottom: 0 }}>
                        <Chart type="bar" data={this.basicDataAbsolute} options={basicOptionsAbsolute} />
                    </div>
                    <div className="col-12" style={{ paddingLeft: 10, paddingTop: 0 }}>
                        <div>Number of documents</div>
                    </div>

                    {showRelativeValues ?
                        <>
                            <div className="col-12" style={{ paddingTop: 0, marginTop: 10, marginBottom: 0, paddingBottom: 0 }}>
                                <Chart type="bar" data={this.basicDataRelative} options={basicOptionsRelative} />
                            </div>
                            <div className="col-12" style={{ paddingLeft: 10, paddingTop: 0 }}>
                                <div>Percentage of overall documents</div>
                            </div>
                        </> : null}


                    {/*
                    <div className="col-fixed textAlignLeft" style={{ paddingLeft: 10 }}>
                        <div>1993</div>
                    </div>
                    <div className="col"></div>
                    <div className="col-fixed textAlignRight" style={{ paddingRight: 10 }}>
                        2022
                    </div>
                    <div className="breakRow" />
                    */}
                </div>
            </>
        );
    }
}

export default StatisticsCharts;