import './InfoOverlayWithIcon.css';
import { Component } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";


class InfoOverlayWithIcon extends Component {

    render() {

        const { infoID, title, imageAltText, overlayContent, moveRightArrow } = this.props;

        return (
            <>
                <span className="infoIconLink"
                    title={title}
                    onClick={e => this[infoID].toggle(e)}>
                    <img src={infoIcon} alt={imageAltText} />
                </span>
                <OverlayPanel
                    ref={(el) => this[infoID] = el}
                    appendTo={document.body}
                    className={`info-icon-overlay-panel whiteSpaceBreakSpaces ${moveRightArrow ? 'moveRightArrow' : ''}`}
                    dismissable={true}>
                    {overlayContent}
                </OverlayPanel>
            </>
        );
    }
}

export default InfoOverlayWithIcon;