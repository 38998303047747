import _ from 'lodash';


export const HIGHLIGHT_OPTIONS = [
    { label: 'Matches', value: 'qry' },
    { label: 'Annotations', value: 'doms' }
];

/**
 * Creates selection object for highlighting options.
 * @param {*} addMatchesOption 
 * @param {*} addAnnotationsOption 
 * @returns 
 */
export const determineActiveHighlightOptions = (addMatchesOption, addAnnotationsOption) => {
    const highlightOptions = [];
    if (addMatchesOption) {
        highlightOptions.push(HIGHLIGHT_OPTIONS[0]);
    }
    if (addAnnotationsOption) {
        highlightOptions.push(HIGHLIGHT_OPTIONS[1]);
    }
    return highlightOptions;
}

/**
 * Determines which highlighting is active at the moment.
 * @param {*} lastHighlightVals 
 * @param {*} newHighlightVals 
 * @param {*} activeAnnotations 
 * @param {*} completeAnnTypesMap 
 * @returns 
 */
export const determineActiveHighlighting = (lastHighlightVals, newHighlightVals, activeAnnotations, completeAnnTypesMap) => {
    let newActiveAnnotations = [];
    // --- add active domains --- //
    // if (!newHighlightVals.includes('doms')) {
        // --- doms still inactive --- //
        // if (!lastHighlightVals.includes('doms')) {
        //     newActiveAnnotations = newActiveAnnotations.concat(activeAnnotations);
        // }
        // --- doms deactivated -> do nothing --- //
    // }
    // else {
    if (newHighlightVals.includes('doms')) {
        // --- doms still active --- //
        if (lastHighlightVals.includes('doms')) {
            newActiveAnnotations = newActiveAnnotations.concat(activeAnnotations);
        }
        // --- doms activated --- //
        else {
            newActiveAnnotations = newActiveAnnotations.concat(Object.keys(completeAnnTypesMap));
        }
    }
    // --- add/remove query --- //
    if (newHighlightVals.includes('qry')) {
        newActiveAnnotations.push('queryTerm');
    }
    else {
        _.pull(newActiveAnnotations, 'queryTerm');
    }
    return newActiveAnnotations;
}

/**
 * Adds/removes a domain to/from the active domains array (toggle).
 * @param {*} activeAnnotations 
 * @param {*} domain 
 * @returns 
 */
export const changeActiveHighlighting = (activeAnnotations, domain) => {
    const newActiveAnnotations = activeAnnotations ? [...activeAnnotations] : [];
    if (newActiveAnnotations.includes(domain)) {
        _.pull(newActiveAnnotations, domain);
    }
    else {
        newActiveAnnotations.push(domain);
    }
    return newActiveAnnotations;
}

/**
 * Adds a domain to the active domains array.
 * @param {*} activeAnnotations 
 * @param {*} domain 
 * @returns 
 */
export const addActiveHighlighting = (activeAnnotations, domain) => {
    const newActiveAnnotations = activeAnnotations ? [...activeAnnotations] : [];
    newActiveAnnotations.push(domain);
    return newActiveAnnotations;
}

/**
 * Determines the next active annId depending on all annIds, the last active annId and 
 * whether we want to go to the previous or next one..
 * @param {*} potAnnIds 
 * @param {*} lastActiveAnnId 
 * @returns 
 */
export const determineNextActiveAnnId = (potAnnIds, lastActiveAnnId, next = true) => {
    if (!potAnnIds || potAnnIds.length === 0) {
        return;
    }
    const lastAnnId = lastActiveAnnId;

    let nextAnnId;
    let index = potAnnIds.indexOf(lastAnnId);
    if (index < 0) {
        index = (next) ? 0 : potAnnIds.length - 1;
        nextAnnId = potAnnIds[index];
    }
    else {
        index = (next) ? index + potAnnIds.length + 1 : index + potAnnIds.length - 1;
        index = (index) % potAnnIds.length;
        nextAnnId = potAnnIds[index];
    }

    return nextAnnId;
}