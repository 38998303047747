/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { TreeSelect } from 'primereact/treeselect';
import ToastContent from '../../common/CustomToast/ToastContent';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { InputText } from 'primereact/inputtext';
import { saveAs } from 'file-saver';
import axios from 'axios'
import { getHeaderToken } from '../../../api/index'
import BlacklistOverview from './BlacklistOverview'
import BlacklistEntries from './BlacklistEntries'
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import OntologyBrowserDialog from "../ontbrowser/OntologyBrowserDialog"
import { APP_PROPERTIES } from '../../../properties/index';
import './Blacklists.css'
import { checkResultAndGetPayload } from '../../../api';
import { fetchUserShareStatusBlocklists } from "../../../api/content/SaveSearch";
import { createUserArray, createCheckedKeys } from "../../webapi/util";
import CreateBlacklist from '../blacklist/CreateBlacklist';

class BlacklistManager extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);
        let columnsBlacklists = [
            { field: "check", selectionMode: 'single', style: { width: '4em', fontWeight: 'normal' } },
            { field: "title", header: "Name", sortable: true, style: {}, body: this.titleTemplate },
            { field: "numberConcepts", header: "Concepts", sortable: true, style: { fontWeight: 'normal', textAlign: 'left' }, body: this.numberConceptsTemplate },
            { field: "", header: "Sharing", body: this.sharedTemplate, style: { textAlign: 'left' } }
        ]
        this.state = {
            colsBlacklists: columnsBlacklists,
            globalFilterBlacklist: '',
            sortBlacklistsBy: 'title',
            blacklistName: 'All blocked concepts',
            blacklistDescription: 'All of your personal blocked concepts',
            displayDialogAddBlacklist: false,
            blacklistEntries: [],
            selectedBlacklist: undefined,
            selectedBlacklistAction: undefined,
            selectedConceptsAction: [],
            displayDialogRemoveBlacklist: false,
            displayDialogModifyBlacklist: false,
            displayDialogRemoveConcepts: false,
            displayDialogSearchModeConcepts: false,
            searchMode: 'EXACT',
            searchModeConcepts: 'EXACT',
            displayDialogAddConcepts: false,
            //domain: ['chem', 'substances', 'proteins', 'natprod', 'diseases', 'effects', 'drugs', 'species'],
            displayDialogCopyConcept: false,
            displayDialogMoveConcept: false,
            displayExportBlocklists: false,
            displayImportBlocklists: false,
            fetching: false,
            firstConcepts: 0,
            rowsConcepts: 20,
            selectedBlacklistsAction: null,
            displayCustomToast: false,
            selectedBlacklistsExport: null,
            departmentMembers: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            usersShared: [],
            orgShared: [],
            userArray: [],
            orgArray: [],
            usersWrite: [],
            newBlacklistName: '',
            newBlacklistDescription: '',
            allBlacklists: [],
            allPossibleUsers: [],
            allPossibleSuborgs: [],
            allNotAllowedUsersRead: [],
            allNotAllowedUsersWrite: [],
            allNotAllowedSuborgs: [],
            selectedBlacklistID: undefined
        }
    };

    componentDidMount() {
        let self = this;

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, { headers: getHeaderToken() })
            .then(function (response) {
                let blacklists = response.data
                let allBlacklistsOrdered = blacklists.sort((a, b) => a.title.localeCompare(b.title))
                self.setState({
                    allBlacklists: allBlacklistsOrdered
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allConcepts: response.data,
                    blacklistEntries: response.data
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        this.updateUserShareStatus();
        //var self = this

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userData.userDetails !== prevProps.userData.userDetails) {
            this.updateUserShareStatus();
        }
    }

    updateUserShareStatus = async () => {

        const response = await fetchUserShareStatusBlocklists();
        const result = checkResultAndGetPayload(response, this.growl);

        if (result) {
            this.setState({
                departmentMembers: result
            }, () => {
                let userArray = createUserArray(result, this.props.userData, 'user')
                let orgArray = createUserArray(result, this.props.userData, 'org')
                let allPossibleUsers = []
                userArray && userArray.forEach(org => {
                    org.children.forEach(dep => {
                        dep.children.forEach(user => {
                            allPossibleUsers = [...allPossibleUsers, user.id]
                        })
                    })
                })
                let allPossibleSuborgs = []
                orgArray && orgArray.forEach(org => {
                    org.children.forEach(dep => {
                        allPossibleSuborgs = [...allPossibleSuborgs, dep.id]
                    })
                })
                this.setState({
                    userArray: userArray,
                    orgArray: orgArray,
                    allPossibleUsers: allPossibleUsers,
                    allPossibleSuborgs: allPossibleSuborgs
                })
            });
        }
    }

    titleTemplate = (rowData) => {
        if (rowData.hasOwnProperty('title')) {
            return <React.Fragment>
                <span className="p-column-title">Name</span>
                {rowData.title}
            </React.Fragment>
        }
    }

    numberConceptsTemplate = (rowData) => {
        if (rowData.hasOwnProperty('numberConcepts')) {
            return <React.Fragment>
                <span className="p-column-title">Concepts</span>
                {rowData.numberConcepts}
            </React.Fragment>
        }
    }

    sharedTemplate = (rowData) => {
        return <div style={{ marginBottom: 5 }}>
            <span className="p-column-title">Sharing</span>
            {rowData.owner && rowData.owner.id === this.props.userData.userDetails.id ?
                rowData.sharedWith && rowData.sharedWith.length > 0 ?
                    <span title={`You share this blocklist with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This blocklist can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This blocklist is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }

    onAddBlacklist = () => {
        this.setState({
            displayDialogAddBlacklist: true,
            newBlacklistName: '',
            newBlacklistDescription: '',
            editableForSharedUsers: false,
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            checkedKeys: {},
            checkedOrgKeys: {}
        })
    }

    updateBlacklistName = (name) => {
        this.setState({
            newBlacklistName: name
        })
    }

    updateBlacklistDescription = (desc) => {
        this.setState({
            newBlacklistDescription: desc
        })
    }

    onSaveBlacklist = async () => {
        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }
        let departments = []
        this.state.orgShared.forEach(dep => {
            departments = [...departments, Number(dep)]
        })
        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, {
            title: self.state.newBlacklistName,
            description: self.state.newBlacklistDescription,
            departmentSharesRead: departments,
            userSharesRead: usersRead,
            userSharesWrite: this.state.usersWrite
        }, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showCreateBlacklistSuccessGrowl()
                self.setState({
                    displayDialogAddBlacklist: false
                }, () => self.refreshBlacklists())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showCreateBlacklistFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.setState({
                        displayDialogAddBlacklist: false
                    })
                    console.log("not found")
                }
            })
    }

    handleModifyBlacklist = async () => {
        var self = this;


        let departments = Array.from(new Set(this.state.orgShared.concat(this.state.allNotAllowedSuborgs)))
        let selectedDepartments = []
        departments.forEach(dep => {
            selectedDepartments = [...selectedDepartments, Number(dep)]
        })
        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }

        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/modify?id=${self.state.selectedBlacklistID}`, {
            title: self.state.newBlacklistName,
            description: self.state.newBlacklistDescription,
            departmentSharesRead: selectedDepartments,
            userSharesRead: Array.from(new Set(usersRead.concat(this.state.allNotAllowedUsersRead))),
            userSharesWrite: Array.from(new Set(this.state.usersWrite.concat(this.state.allNotAllowedUsersWrite)))
        }, { headers: getHeaderToken() })
            .then(async function (response) {
                //self.showCreateBlacklistSuccessGrowl()
                self.setState({
                    displayDialogModifyBlacklist: false
                }, () => {
                    self.refreshBlacklists(self.state.selectedBlacklistID)
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showModifyBlacklistFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showCreateBlacklistFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    showModifyBlacklistFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    refreshBlacklists = async (blacklistID) => {
        var self = this;
        await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showCollectionRefreshSuccessGrowl()
                let allBlacklists = response.data
                let allBlacklistsOrdered
                if (allBlacklists && allBlacklists.length > 0 && self.state.sortBlacklistsBy === "numberConcepts") {
                    allBlacklistsOrdered = allBlacklists.sort(function (a, b) { return b[self.state.sortBlacklistsBy] - a[self.state.sortBlacklistsBy] })
                } else if (allBlacklists && allBlacklists.length > 0 && self.state.sortBlacklistsBy === "title") {
                    allBlacklistsOrdered = allBlacklists.sort((a, b) => a.title.localeCompare(b.title))
                }
                self.setState({
                    allBlacklists: allBlacklistsOrdered
                }, () => {
                    if (blacklistID !== undefined) {
                        self.state.allBlacklists.forEach(list => {
                            if (list.id === blacklistID && self.state.selectedBlacklistAction && list.id === self.state.selectedBlacklistAction.id) {
                                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${list.id}`, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        self.setState({
                                            blacklistEntries: response.data,
                                            selectedBlacklist: list.id,
                                            blacklistID: list.id,
                                            selectedBlacklistID: list.id,
                                            blacklistName: list.title,
                                            blacklistDescription: list.description,
                                            selectedBlacklistAction: list
                                        }, () => {
                                            if (Array.isArray(self.state.selectedConceptsAction)) {
                                                let selectedConcepts = []
                                                self.state.selectedConceptsAction.forEach(selCon => {
                                                    self.state.blacklistEntries.forEach(entry => {
                                                        if (selCon.id === entry.id) {
                                                            selectedConcepts = [...selectedConcepts, entry]
                                                        }
                                                    })
                                                })
                                                self.setState({ selectedConceptsAction: selectedConcepts })
                                            }
                                        })
                                    })
                                    .catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showFailGrowl(error.response.data)
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            }
                        })
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshAllBlacklists = () => {
        //console.log(this.state)
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allConcepts: response.data
                }, () => {
                    //console.log(self.state)
                    if (Array.isArray(self.state.selectedConceptsAction)) {
                        let selectedConceptsAction = []
                        self.state.selectedConceptsAction.forEach(selCon => {
                            self.state.allConcepts.forEach(con => {
                                if (selCon.id === con.id) {
                                    selectedConceptsAction = [...selectedConceptsAction, con]
                                }
                            })
                        })
                        self.setState({ selectedConceptsAction: selectedConceptsAction })
                    }
                })
                if (self.state.selectedBlacklist === undefined) {
                    self.setState({
                        blacklistEntries: response.data
                    })
                }
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    onSortBlacklists = (value) => {
        let sortBy = value
        let allBlacklists = JSON.parse(JSON.stringify(this.state.allBlacklists))
        let allBlacklistsOrdered
        if (this.state.allBlacklists && this.state.allBlacklists.length > 0 && sortBy === "numberConcepts") {
            allBlacklistsOrdered = allBlacklists.sort(function (a, b) { return b[sortBy] - a[sortBy] })
        } else if (this.state.allBlacklists && this.state.allBlacklists.length > 0 && sortBy === "title") {
            allBlacklistsOrdered = allBlacklists.sort((a, b) => a.title.localeCompare(b.title))
        }
        this.setState({
            sortBlacklistsBy: value,
            allBlacklists: allBlacklistsOrdered
        })
    }

    onExportClick = () => {
        this.toastBC.current.clear()
        this.setState({
            displayExportBlocklists: true,
            selectedConceptsAction: [],
            globalFilterBlacklist: '',
            selectedBlacklistsExport: null,
            displayCustomToast: false
        })
    }

    handleCloseExportDialog = () => {
        this.setState({
            displayExportBlocklists: false,
            globalFilterBlacklist: '',
            selectedBlacklistsExport: null
        })
    }

    onExportBlacklistChange = (e) => {
        this.setState({
            selectedBlacklistsExport: e.value
        })
    }

    handleExportBlocklists = () => {
        let filteredBlacklistsExport = []
        let allBlacklistsExport = JSON.parse(JSON.stringify(this.state.selectedBlacklistsExport))
        let operationsCompleted = 0;
        function operation() {
            ++operationsCompleted;
            if (operationsCompleted === 1)//Object.keys(allBlacklistsExport).length) 
            {
                //allBlacklistsExport.forEach(list => {
                delete allBlacklistsExport.editableForSharedUsers
                delete allBlacklistsExport.id
                delete allBlacklistsExport.numberConcepts
                delete allBlacklistsExport.owner
                delete allBlacklistsExport.sharedForDepartment
                delete allBlacklistsExport.sharedWith
                filteredBlacklistsExport = [...filteredBlacklistsExport, allBlacklistsExport]
                // })
                saveAs(new Blob([JSON.stringify(filteredBlacklistsExport, undefined, 4)]), `blocklists.txt`)
                self.setState({
                    displayExportBlocklists: false,
                    globalFilterBlacklist: '',
                    selectedBlacklistsExport: null
                })
            }
        }
        let self = this
        //Object.keys(allBlacklistsExport).forEach(list => {
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${allBlacklistsExport.id}`, { headers: getHeaderToken() })
            .then(function (response) {
                let concepts = response.data
                concepts.forEach(conc => {
                    delete conc.id
                    delete conc.conceptBlackListTitle
                })
                allBlacklistsExport.concepts = concepts
                operation()
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
                //})
            })
    }

    onImportClick = () => {
        this.toastBC.current.clear()
        this.setState({
            displayImportBlocklists: true,
            selectedConceptsAction: [],
            inputText: '',
            displayCustomToast: false
        })
    }

    handleCloseImportDialog = () => {
        this.setState({
            displayImportBlocklists: false
        })
    }

    uploadHandler = ({ files }) => {
        // --- hack: increase counter to change key of file upload component --- //
        // --- otherwise file upload works only once --- //
        const self = this;

        // --- check file type --- //
        const [file] = files;

        var reader = new FileReader();
        reader.onload = function (event) {
            // --- NOTE: event.target points to FileReader --- //
            var contents = event.target.result;
            self.setState({
                processInputText: true,
                inputText: contents
            });
        };

        reader.readAsText(file);

    };

    handleImportBlocklists = async () => {
        function IsJsonString(str) {
            try {
                let json = JSON.parse(str)
                return (typeof json === 'object')
            } catch (e) {
                return false
            }
        }
        if (IsJsonString(this.state.inputText)) {
            let importedBlockLists = JSON.parse(this.state.inputText)
            let allNewBlocklistNames = []
            importedBlockLists.forEach(list => {
                allNewBlocklistNames = [...allNewBlocklistNames, list.title]
            })
            let exists = false
            if (this.state.allBlacklists && this.state.allBlacklists.length > 0) {
                allNewBlocklistNames.forEach(name => {
                    if (this.state.allBlacklists.some(e => e.title === name)) {
                        exists = true
                        this.showExistWarnGrowl(name)
                    } else if (!this.state.allBlacklists.some(e => e.title === name) && name.length > 50) {
                        exists = true
                        this.showNameLengthWarnGrowl(name)
                    }
                })
            }
            if (!exists) {
                this.setState({
                    fetching: true
                })
                let operationsCompleted = 0;
                function operation() {
                    ++operationsCompleted;
                    if (operationsCompleted === importedBlockLists.length) {
                        self.setState({
                            displayImportBlocklists: false,
                            inputText: '',
                            fetching: false
                        }, () => {
                            self.refreshAllBlacklists()
                            self.refreshBlacklistEntries()
                            self.refreshBlacklists()
                        })
                    }
                }
                let self = this
                importedBlockLists.forEach(async list => {
                    await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, {
                        title: list.title,
                        description: list.description,
                        editableForSharedUsers: false,
                        sharedForDepartment: false,
                        usersShared: []
                    }, { headers: getHeaderToken() })
                        .then(function (response) {
                            if (list.concepts && list.concepts.length > 0) {
                                axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${response.data}`, list.concepts, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        operation()
                                    })
                                    .catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showFailGrowl(error.response.data)
                                            operation()
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })

                            }
                        })
                        .catch(function (error) {
                            if (error.response.status === 400) {
                                self.showCreateBlacklistFailGrowl(error.response.data)
                                operation()
                            } else if (error.response.status === 401) {
                                self.props.history.push('/')
                            } else if (error.response.status === 404) {
                                console.log("not found")
                            }
                        })
                })
            }
        } else {
            this.showFailGrowl("No valid JSON object.")
        }
    }

    handleBlacklistChange = (e, list) => {
        var self = this;
        if (list.id) {
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${list.id}`, { headers: getHeaderToken() })
                .then(function (response) {
                    let allNotAllowedUsersRead = []
                    let allNotAllowedUsersWrite = []
                    let allNotAllowedSuborgs = []
                    if (list.userSharesRead && list.userSharesRead.length > 0 && self.state.allPossibleUsers && self.state.allPossibleUsers.length > 0) {
                        list.userSharesRead.forEach(user => {
                            if (!self.state.allPossibleUsers.includes(user.id)) {
                                allNotAllowedUsersRead = [...allNotAllowedUsersRead, user.id]
                            }
                        })
                    }
                    if (list.userSharesWrite && list.userSharesWrite.length > 0 && self.state.allPossibleUsers && self.state.allPossibleUsers.length > 0) {
                        list.userSharesWrite.forEach(user => {
                            if (!self.state.allPossibleUsers.includes(user.id)) {
                                allNotAllowedUsersWrite = [...allNotAllowedUsersWrite, user.id]
                            }
                        })
                    }
                    if (list.departmentSharesRead && list.departmentSharesRead.length > 0 && self.state.allPossibleSuborgs && self.state.allPossibleSuborgs.length > 0) {
                        list.departmentSharesRead.forEach(dep => {
                            if (!self.state.allPossibleSuborgs.includes(dep.id)) {
                                allNotAllowedSuborgs = [...allNotAllowedSuborgs, dep.id]
                            }
                        })
                    }
                    self.setState({
                        blacklistEntries: response.data,
                        selectedBlacklist: list.id,
                        blacklistID: list.id,
                        selectedBlacklistID: list.id,
                        blacklistName: list.title,
                        blacklistDescription: list.description,
                        hits: list.numberConcepts,
                        selectedConceptsAction: [],
                        searchMode: 'EXACT',
                        selectedBlacklistAction: list,
                        firstConcepts: 0,
                        allNotAllowedUsersRead: allNotAllowedUsersRead,
                        allNotAllowedUsersWrite: allNotAllowedUsersWrite,
                        allNotAllowedSuborgs: allNotAllowedSuborgs,
                        displayCustomToast: false
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
            this.toastBC.current.clear()
        } else {
            this.toastBC.current.clear()
            this.setState({
                blacklistName: 'All blocked concepts',
                blacklistDescription: 'All of your personal blocked concepts',
                selectedBlacklist: undefined,
                blacklistID: undefined,
                selectedBlacklistID: undefined,
                selectedConceptsAction: [],
                blacklistEntries: this.state.allConcepts,
                searchMode: 'EXACT',
                selectedBlacklistAction: undefined,
                firstConcepts: 0,
                allNotAllowedUsersRead: [],
                allNotAllowedUsersWrite: [],
                allNotAllowedSuborgs: [],
                displayCustomToast: false
            })
        }
    }

    selectConcepts = (concepts) => {
        if (concepts.length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (concepts.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
        this.setState({
            selectedConceptsAction: concepts
        }, () => {
            if (this.toastBC?.current?.state?.messages[0]) {
                this.setState({
                    toastID: this.toastBC.current.state.messages[0].id
                })
            }
        })
    }

    handleCopyConceptsDialog = () => {
        this.setState({
            displayDialogCopyConcept: true
        })
    }

    handleMoveConceptsDialog = () => {
        this.setState({
            displayDialogMoveConcept: true
        })
    }

    handleCloseCopyConceptDialog = () => {
        this.setState({
            displayDialogCopyConcept: false,
            selectedBlacklistsAction: null,
            globalFilterBlacklist: ''
        })
    }

    handleCloseMoveConceptDialog = () => {
        this.setState({
            displayDialogMoveConcept: false,
            selectedBlacklistsAction: null,
            globalFilterBlacklist: ''
        })
    }

    handleUnselect = () => {
        this.toastBC.current.clear()
        this.setState({
            selectedConceptsAction: [],
            displayCustomToast: false
        })
    }

    onRemoveBlacklist = () => {
        this.setState({
            displayDialogRemoveBlacklist: true
        })
    }

    onModifyBlacklist = () => {

        let canModifyBlocklist
        let sharedWithUsers = []
        let sharedWithUsersData = []
        let checkedKeys = {}
        let usersShared = []
        let checkedOrgKeys = {}
        let orgShared = []
        let usersWrite = []
        let sharedUsers = []


        if (!this.state.selectedBlacklistAction.userSharesRead && !this.state.selectedBlacklistAction.userSharesWrite) {
            sharedWithUsers = []
        } else if (this.state.selectedBlacklistAction.userSharesRead && this.state.selectedBlacklistAction.userSharesRead.length > 0) {
            this.state.selectedBlacklistAction.userSharesRead.forEach(user => {
                sharedWithUsers = [...sharedWithUsers, user.id]
                sharedWithUsersData = [...sharedWithUsersData, {
                    forename: user.forename,
                    lastName: user.lastName,
                    id: user.id,
                    name: user.name
                }]
                if (this.state.allPossibleUsers.includes(user.id)) {
                    checkedKeys[user.id] = { checked: true, partialChecked: false }
                    usersShared = [...usersShared, user.id]
                    sharedUsers = [...sharedUsers, { label: `${user.lastName}, ${user.forename} (${user.name})`, value: user.id }]
                }
            })
        }
        if (this.state.selectedBlacklistAction.userSharesWrite && this.state.selectedBlacklistAction.userSharesWrite.length > 0) {
            this.state.selectedBlacklistAction.userSharesWrite.forEach(user => {
                sharedWithUsers = [...sharedWithUsers, user.id]
                sharedWithUsersData = [...sharedWithUsersData, {
                    forename: user.forename,
                    lastName: user.lastName,
                    id: user.id,
                    name: user.name
                }]
                if (this.state.allPossibleUsers.includes(user.id)) {
                    checkedKeys[user.id] = { checked: true, partialChecked: false }
                    usersShared = [...usersShared, user.id]
                    usersWrite = [...usersWrite, user.id]
                    sharedUsers = [...sharedUsers, { label: `${user.lastName}, ${user.forename} (${user.name})`, value: user.id }]
                }
            })
        }

        if (this.state.selectedBlacklistAction?.departmentSharesRead && this.state.selectedBlacklistAction?.departmentSharesRead.length > 0) {
            this.state.selectedBlacklistAction.departmentSharesRead.forEach(dep => {
                if (this.state.allPossibleSuborgs.includes(dep.id)) {
                    checkedOrgKeys[`${dep.id}-${dep.name}`] = { checked: true, partialChecked: false }
                }
                orgShared = [...orgShared, dep.id]
            })
        }

        //let checkedKeysWithCompaniesAndDepartments = createCheckedKeys(checkedKeys, this.props.departmentMembers, this.props.userArray)

        if (this.state.selectedBlacklistAction.defaultCollection || this.state.selectedBlacklistAction.shared) {
            canModifyBlocklist = false
            //sharedWithUsers = []
        } else {
            canModifyBlocklist = true
        }


        /*if (!this.state.selectedBlacklistAction.sharedWith) {
            sharedWithUsers = []
        } else {
            this.state.selectedBlacklistAction.sharedWith.forEach(user => {
                sharedWithUsers = [...sharedWithUsers, user.id]
                sharedWithUsersData = [...sharedWithUsersData, { forename: user.forename, lastName: user.lastName, id: user.id, name: user.name }]
            })
        }*/

        this.setState({
            newBlacklistName: this.state.selectedBlacklistAction.title,
            newBlacklistDescription: this.state.selectedBlacklistAction.description || '',
            selectedBlacklistID: this.state.selectedBlacklistAction.id,
            selectedMembers: sharedWithUsers,
            oldSelectedMembers: sharedWithUsers,
            selectedMembersData: sharedWithUsersData,
            oldSelectedMembersData: sharedWithUsersData,
            canModifyBlocklist: canModifyBlocklist,
            checkedKeys: checkedKeys,
            checkedOrgKeys: checkedOrgKeys,
            usersShared: usersShared,
            orgShared: orgShared,
            usersWrite: usersWrite,
            sharedUsers: sharedUsers,
            displayDialogModifyBlacklist: true
        })
    }

    onHideRemoveBlacklist = () => {
        this.setState({
            displayDialogRemoveBlacklist: false
        })
    }

    handleRemoveBlacklist = async () => {
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists?blackListIds=${self.state.selectedBlacklist}`, { headers: getHeaderToken(), data: { 'blackListIds': self.state.selectedBlacklist } })
            .then(function (response) {
                if (response.status === 200) {
                    //self.showBlacklistDeleteSuccessGrowl()
                    self.toastBC.current.clear()
                    self.refreshBlacklists()
                    self.refreshAllBlacklists()
                    self.setState({
                        blacklistName: 'All blocked concepts',
                        selectedQueries: [],
                        blacklistID: undefined,
                        selectedBlacklistID: undefined,
                        selectedQueriesAction: [],
                        displayDialogRemoveBlacklist: false,
                        selectedBlacklist: undefined,
                        displayCustomToast: false
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showBlacklistDeleteFailGrowl()
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showBlacklistDeleteFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection not removed from database.', life: 6000 };
        this.growl.show(msg);
    }

    handleRemoveConceptsDialog = () => {
        this.setState({
            displayDialogRemoveConcepts: true
        })
    }

    handleSearchModeConceptsDialog = () => {
        this.setState({
            displayDialogSearchModeConcepts: true,
            searchModeConcepts: 'EXACT'
        })
    }

    handleCloseRemoveConceptsDialog = () => {
        this.setState({
            displayDialogRemoveConcepts: false
        })
    }

    handleCloseSearchModeConceptsDialog = () => {
        this.setState({
            displayDialogSearchModeConcepts: false
        })
    }

    handleCopyConcepts = async () => {
        let conceptIDs = ''
        let blacklistIDs = ''
        this.state.selectedConceptsAction && this.state.selectedConceptsAction.forEach(concept => {
            conceptIDs += concept.id + ","
        })
        conceptIDs = conceptIDs.slice(0, -1)
        /*this.state.selectedBlacklistsAction && this.state.selectedBlacklistsAction.forEach(list => {
            blacklistIDs += list.id + ","
        })
        blacklistIDs = blacklistIDs.slice(0, -1)*/
        if (this.state.selectedBlacklistsAction && this.state.selectedBlacklistsAction !== null) {
            blacklistIDs = this.state.selectedBlacklistsAction.id
        }

        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts/copy?negatedConceptIds=${conceptIDs}&blackListIds=${blacklistIDs}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    //self.showBlacklistDeleteSuccessGrowl()
                    self.refreshBlacklists()
                    self.refreshAllBlacklists()
                    self.refreshBlacklistEntries()
                    self.setState({
                        displayDialogCopyConcept: false,
                        selectedBlacklistsAction: null,
                        globalFilterBlacklist: ''
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleMoveConcepts = async () => {
        let conceptIDs = ''
        let blacklistIDs = ''
        this.state.selectedConceptsAction && this.state.selectedConceptsAction.forEach(concept => {
            conceptIDs += concept.id + ","
        })
        conceptIDs = conceptIDs.slice(0, -1)
        /*this.state.selectedBlacklistsAction && this.state.selectedBlacklistsAction.forEach(list => {
            blacklistIDs += list.id + ","
        })
        blacklistIDs = blacklistIDs.slice(0, -1)*/
        if (this.state.selectedBlacklistsAction && this.state.selectedBlacklistsAction !== null) {
            blacklistIDs = this.state.selectedBlacklistsAction.id
        }

        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts/move?negatedConceptIds=${conceptIDs}&blackListIds=${blacklistIDs}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    //self.showBlacklistDeleteSuccessGrowl()
                    self.toastBC.current.clear()
                    self.refreshBlacklists()
                    self.refreshAllBlacklists()
                    self.refreshBlacklistEntries()
                    self.setState({
                        displayDialogMoveConcept: false,
                        selectedBlacklistsAction: null,
                        globalFilterBlacklist: '',
                        displayCustomToast: false
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    removeConcepts = async (ids) => {
        //console.log(ids)
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?negatedConceptIds=${ids}`, { headers: getHeaderToken(), data: { 'negatedConceptIds': ids } })
            .then(function (response) {
                if (response.status === 200) {
                    //self.showEntryRemoveSuccessGrowl()
                    self.refreshBlacklists()
                    self.refreshBlacklistEntries()
                    self.refreshAllBlacklists()
                    self.toastBC.current.clear()
                    self.setState({
                        displayDialogRemoveConcepts: false,
                        selectedConceptsAction: [],
                        displayCustomToast: false
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showConceptsRemoveFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showExistWarnGrowl = (name) => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: `Blocklist "${name}" already exists. Please change the name within the input field.`, life: 10000 };
        this.growl.show(msg);
    }

    showNameLengthWarnGrowl = (name) => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: `Blocklist name "${name}" is too long. Please limit the name to 50 characters within the input field.`, life: 10000 };
        this.growl.show(msg);
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    showConceptsRemoveFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Entry could not be removed from blocklist.', life: 6000 };
        this.growl.show(msg);
    }

    refreshBlacklistEntries = async () => {
        var self = this;
        if (self.state.selectedBlacklistID !== undefined) {
            await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${self.state.selectedBlacklistID}`, { headers: getHeaderToken() })
                .then(function (response) {
                    //self.showCollectionRefreshSuccessGrowl()
                    let blacklistEntries = response.data
                    self.setState({
                        blacklistEntries: blacklistEntries
                    }, () => {
                        if (Array.isArray(self.state.selectedConceptsAction)) {
                            let selectedConceptsAction = []
                            self.state.selectedConceptsAction.forEach(selCon => {
                                self.state.blacklistEntries.forEach(con => {
                                    if (selCon.id === con.id) {
                                        selectedConceptsAction = [...selectedConceptsAction, con]
                                    }
                                })
                            })
                            //console.log("new sel:", selectedDocuments)
                            self.setState({ selectedConceptsAction: selectedConceptsAction })
                        }
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    onSearchModeChange = (e) => {
        this.setState({
            searchMode: e.value
        })
    }

    handleAddConcepts = () => {
        //console.log(this.state.searchMode)
        this.setState({
            displayDialogAddConcepts: true
        })

    }

    onSearchModeChangeConcept = (e) => {
        this.setState({
            searchModeConcepts: e.value
        })
    }

    onSearchModeConceptChange = async (e, conceptInfo) => {
        //console.log(e)
        let concepts = ''
        if (typeof conceptInfo !== 'object') {
            concepts = conceptInfo
        } else {
            concepts = conceptInfo.id
        }
        let searchMode = ''
        if (typeof conceptInfo !== 'object') {
            searchMode = e
        } else {
            searchMode = e.value
        }
        //console.log(searchMode)
        var self = this
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts/concept-lookup-type?conceptIds=${concepts}&conceptLookupType=${searchMode}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showEditCompanySuccessGrowl()
                //self.refreshBlacklists()
                self.refreshBlacklists()
                self.refreshAllBlacklists()
                self.refreshBlacklistEntries()
                self.setState({
                    displayDialogSearchModeConcepts: false,
                    // selectedConceptsAction: []
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    //self.showEditCompanyFailGrowl(error.response.data)
                    console.log("not found")
                }
            })
    }

    onSubmit = async (concepts) => {
        if (concepts && Object.keys(concepts).length > 0) {
            let conceptArray = []
            Object.keys(concepts).forEach(concept => {
                conceptArray = [...conceptArray, {
                    ocid: concepts[concept].ocid, conceptLookupType: this.state.searchMode === 'ONTOLOGICAL' ?
                        'ONTOLOGICAL' : 'EXACT', scope: 'entity', prefName: concepts[concept].preferredName
                }]
            })
            let self = this
            axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${self.state.selectedBlacklistID}`, conceptArray, { headers: getHeaderToken() })
                .then(function (response) {
                    //self.showEntryAddSuccessGrowl()
                    self.refreshBlacklists()
                    self.refreshAllBlacklists()
                    self.refreshBlacklistEntries()
                    self.setState({
                        displayDialogAddConcepts: false
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.state.orgArray && this.state.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.state.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.state.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.state?.departmentMembers, this.state.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.state.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        //console.log(members)
        this.setState({
            usersWrite: members
        })
    }

    onBlacklistChange = (e) => {
        this.setState({
            selectedBlacklistsAction: e.value
        })
    }

    onCustomPage = (e, isPaginator) => {
        if (isPaginator) {
            this.setState({
                firstConcepts: e.first,
                rowsConcepts: e.rows
            })
        } else {
            this.setState({
                rowsConcepts: e.value
            })
        }
    }

    hideAddBlacklist = () => {
        this.setState({
            displayDialogAddBlacklist: false
        })
    }

    render() {
        const fetchingData = this.state.fetching

        let ontBrowserDialogDomains = []

        this.props.availableDomains && this.props.availableDomains.forEach(item => {
            ontBrowserDialogDomains = [...ontBrowserDialogDomains, item]
        })

        const footerModifyBlacklist = (
            <div className='col-12' style={{ paddingRight: 15, paddingBottom: 0 }}>
                <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 8 }}>* required</label>
                <Button label="Cancel" onClick={() => this.setState({ displayDialogModifyBlacklist: false })} className="p-button-secondary p-button-sm" />
                <Button label="Save" onClick={this.handleModifyBlacklist}
                    className='p-button-sm primaryButton'
                    style={{ float: 'right', marginRight: -5 }}
                    disabled={this.state.newBlacklistName && this.state.newBlacklistName.length > 0 ? false : true} />
            </div>
        )

        let filteredBlacklists = []

        /*if (this.state.selectedBlacklistID !== undefined) {
            this.state.selectedBlacklist && this.state.allBlacklists && this.state.allBlacklists.forEach(list => {
                if (list.id !== this.state.selectedBlacklistID) {
                    if ((list.owner.id === this.props.userData.userDetails.id) || (list.owner.id !== this.props.userData.userDetails.id && list.editableForSharedUsers === true)) {
                        filteredBlacklists = [...filteredBlacklists, list]
                    }
                }
            })
        }*/


        if (this.state.selectedBlacklistID !== undefined) {
            this.state.selectedBlacklist && this.state.allBlacklists && this.state.allBlacklists.forEach(list => {
                if (list.id !== this.state.selectedBlacklistID) {
                    if (list.writable) {
                        filteredBlacklists = [...filteredBlacklists, list]
                    }
                }
            })
        } else {
            this.state.allBlacklists && this.state.allBlacklists.forEach(list => {
                if (list.writable) {
                    filteredBlacklists = [...filteredBlacklists, list]
                }
            })
        }



        let filteredBlacklistsForExport = []

        if (this.state.allBlacklists && this.state.allBlacklists.length > 0) {
            filteredBlacklistsForExport = this.state.allBlacklists.filter(obj => {
                return obj.numberConcepts > 0//obj.owner.id === this.props.userData.userDetails.id && 
            })
        }

        let headerBlacklists = <div className='grid' style={{
            marginRight: 0, paddingLeft: 7,
            paddingTop: 18, paddingBottom: 10
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search blocklists">
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', width: '83%', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap' }}
                    type="search"
                    autoComplete="off"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterBlacklist: e.target.value })}
                    placeholder="Search blocklists" />
            </div>
        </div>

        let columnDataBlacklists = this.state.colsBlacklists.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        const acceptedFileTypes = ['.txt']
        const acceptedFileTypesString = acceptedFileTypes.join(',')

        return (
            <div className="grid">
                <Toast ref={(el) => { this.growl = el }} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true}
                    className='fullWidth'>
                    <div className='col-12' style={{ float: 'left', padding: 0 }}>

                        <div className='col-12' style={{ paddingBottom: 0 }}>
                            <div className='grid'>
                                <div className="col textAlignLeft" style={{ paddingBottom: 0 }}>
                                    <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader">My Blocklists</h1>
                                </div>
                            </div>
                        </div>


                        <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10" style={{
                            paddingLeft: 7 //paddingLeft: 0, paddingBottom: 25, marginTop: -60 
                        }}>
                            <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}> Semantic blocklists are an integral part
                                of a number of this tools analysis apps. Here you can create new blocklists and edit or delete existing ones.
                            </label>
                        </div>
                    </div>

                    <div className='grid col-12' style={{ paddingLeft: 0, paddingTop: 0 }}>
                        <div className='col-6 sm:col-6 md:col-5 lg:col-4 xl:col-3' style={{ paddingLeft: 5, borderRight: '1px solid #d6d6d6', paddingTop: 0, paddingRight: 10 }}>
                            <BlacklistOverview
                                allConcepts={this.state.allConcepts}
                                sortBlacklistsBy={this.state.sortBlacklistsBy}
                                allBlacklists={this.state.allBlacklists}
                                history={this.props.history}
                                userData={this.props.userData}
                                onAddBlacklist={this.onAddBlacklist}
                                // onSortBlacklists={this.onSortBlacklists}
                                handleBlacklistChange={this.handleBlacklistChange}
                                selectedBlacklist={this.state.selectedBlacklist}
                                onExportClick={this.onExportClick}
                                onImportClick={this.onImportClick}>
                            </BlacklistOverview>
                        </div>
                        <div className='col-6 sm:col-6 md:col-7 lg:col-8 xl:col-9' style={{ paddingTop: 0, paddingLeft: 15, paddingRight: 0 }}>
                            <BlacklistEntries
                                history={this.props.history}
                                userData={this.props.userData}
                                allBlacklists={this.state.allBlacklists}
                                selectedBlacklist={this.state.selectedBlacklist}
                                selectedBlacklistAction={this.state.selectedBlacklistAction}
                                blacklistName={this.state.blacklistName}
                                blacklistDescription={this.state.blacklistDescription}
                                blacklistEntries={this.state.blacklistEntries}
                                selectConcepts={this.selectConcepts}
                                handleUnselect={this.handleUnselect}
                                selectedConceptsAction={this.state.selectedConceptsAction}
                                onRemoveBlacklist={this.onRemoveBlacklist}
                                onModifyBlacklist={this.onModifyBlacklist}
                                displayDialogRemoveBlacklist={this.state.displayDialogRemoveBlacklist}
                                onHideRemoveBlacklist={this.onHideRemoveBlacklist}
                                handleRemoveBlacklist={this.handleRemoveBlacklist}
                                handleRemoveConceptsDialog={this.handleRemoveConceptsDialog}
                                handleSearchModeConceptsDialog={this.handleSearchModeConceptsDialog}
                                displayDialogRemoveConcepts={this.state.displayDialogRemoveConcepts}
                                displayDialogSearchModeConcepts={this.state.displayDialogSearchModeConcepts}
                                handleCloseSearchModeConceptsDialog={this.handleCloseSearchModeConceptsDialog}
                                handleCloseRemoveConceptsDialog={this.handleCloseRemoveConceptsDialog}
                                removeConcepts={this.removeConcepts}
                                searchMode={this.state.searchMode}
                                searchModeConcepts={this.state.searchModeConcepts}
                                onSearchModeChange={this.onSearchModeChange}
                                handleAddConcepts={this.handleAddConcepts}
                                onSearchModeConceptChange={this.onSearchModeConceptChange}
                                onSearchModeChangeConcept={this.onSearchModeChangeConcept}
                                firstConcepts={this.state.firstConcepts}
                                rowsConcepts={this.state.rowsConcepts}
                                onCustomPage={this.onCustomPage}
                                allNotAllowedSuborgs={this.state.allNotAllowedSuborgs}
                                allNotAllowedUsersRead={this.state.allNotAllowedUsersRead}
                                allNotAllowedUsersWrite={this.state.allNotAllowedUsersWrite}>
                            </BlacklistEntries>
                        </div>

                        <CreateBlacklist
                            displayDialogAddBlacklist={this.state.displayDialogAddBlacklist}
                            hideAddBlacklist={this.hideAddBlacklist}
                            onSaveBlacklist={this.onSaveBlacklist}
                            newBlacklistName={this.state.newBlacklistName}
                            updateBlacklistName={this.updateBlacklistName}
                            newBlacklistDescription={this.state.newBlacklistDescription}
                            updateBlacklistDescription={this.updateBlacklistDescription}
                            userData={this.props.userData}
                            departmentMembers={this.state.departmentMembers}
                            checkedOrgKeys={this.state.checkedOrgKeys}
                            orgArray={this.state.orgArray}
                            handleMemberChange={this.handleMemberChange}
                            checkedKeys={this.state.checkedKeys}
                            userArray={this.state.userArray}
                            usersShared={this.state.usersShared}
                            onWriteMembersChange={this.onWriteMembersChange}
                            usersWrite={this.state.usersWrite}
                            sharedUsers={this.state.sharedUsers}>
                        </CreateBlacklist>

                        <Dialog focusOnShow={false} visible={this.state.displayDialogModifyBlacklist} style={{ 'width': "60vw" }} header="Blocklist details"
                            modal={true} footer={footerModifyBlacklist} dismissableMask={true} blockScroll
                            onHide={() => this.setState({ displayDialogModifyBlacklist: false })} className='styledDialog'>
                            <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 20, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Name *</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                    <InputText maxLength={50} onChange={(e) => { this.updateBlacklistName(e.target.value) }} value={this.state.newBlacklistName} />
                                    <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Description</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                    <InputText maxLength={200} onChange={(e) => { this.updateBlacklistDescription(e.target.value) }} value={this.state.newBlacklistDescription} />
                                    <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ display: 'flex', paddingBottom: 15, paddingRight: 10, paddingTop: 5 }}>
                                    <div className="w-full" >
                                        <TreeSelect disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.state.departmentMembers?.length !== 0 ? false : true}
                                            id='sharingTreeSelect'
                                            resetFilterOnHide={true}
                                            //appendTo={document.body}
                                            selectionMode='checkbox'
                                            placeholder='Select suborganizations'
                                            value={this.state.checkedOrgKeys}
                                            options={this.state.orgArray}
                                            display='chip'
                                            scrollHeight='200px'
                                            filter
                                            onChange={(e) => {
                                                e.stopPropagation()
                                                this.handleMemberChange(e.value, true)
                                            }} style={{
                                                borderBottom: '1px solid gray', maxWidth: '19vw'
                                            }} />
                                    </div>
                                    <div className="w-full" >
                                        <TreeSelect disabled={this.state.departmentMembers?.length !== 0 ? false : true}
                                            id='sharingTreeSelect'
                                            resetFilterOnHide={true}
                                            appendTo={document.body}
                                            selectionMode='checkbox'
                                            placeholder='Select users'
                                            //title='Select colleagues'
                                            value={this.state.checkedKeys}
                                            options={this.state.userArray}
                                            display='chip'
                                            scrollHeight='200px'
                                            //optionLabel='label'
                                            //optionGroupLabel='label'
                                            //optionGroupChildren="items"
                                            //optionGroupTemplate={groupedItemsTemplate}
                                            filter
                                            onChange={(e) => {
                                                e.stopPropagation()
                                                this.handleMemberChange(e.value, false)
                                            }} style={{
                                                borderBottom: '1px solid gray', marginLeft: 10, maxWidth: '19vw'
                                            }} />
                                    </div>
                                </div>
                                {this.state.usersShared && this.state.usersShared.length !== 0 ?
                                    <React.Fragment>
                                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                            <label style={{ verticalAlign: 'sub' }}>Editable for shared users</label>
                                        </div>
                                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                            <MultiSelect
                                                pt={{
                                                    checkboxIcon: {
                                                        onClick: (e) => {
                                                            e.stopPropagation();
                                                            if (e.target.className.baseVal !== "") {
                                                                e.target.parentNode.click();
                                                            } else {
                                                                e.target.parentNode.parentNode.click();
                                                            }
                                                        },
                                                    },
                                                    headerCheckbox: {
                                                        onClick: (e) => {
                                                            e.stopPropagation();
                                                            e.target.parentNode.click();
                                                        },
                                                    },
                                                }}
                                                id='sharingMultiSelect'
                                                display='chip'
                                                placeholder='Select users'
                                                onChange={(e) => this.onWriteMembersChange(e.value)}
                                                value={this.state.usersWrite}
                                                options={this.state.sharedUsers}
                                            >
                                            </MultiSelect>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null}
                                <div style={{ display: 'flex', justifyContent: 'end', paddingLeft: 17 }}>
                                    <div className="full-text-message-collections col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8">
                                        Note: Users changing the suborganization will lose access to search collections shared on suborganization level, but will keep access to search collections shared on personal level.
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog focusOnShow={false} visible={this.state.displayDialogCopyConcept} style={{ 'width': "50vw" }}
                            header={this.state.selectedConceptsAction && this.state.selectedConceptsAction.length > 1 ?
                                "Copy concepts" : this.state.selectedConceptsAction && this.state.selectedConceptsAction.length === 1 ? "Copy concept" : null}
                            modal={true} dismissableMask={true} blockScroll onHide={() => this.handleCloseCopyConceptDialog()} className='styledDialog'>
                            <React.Fragment>
                                {filteredBlacklists && filteredBlacklists.length === 0 ?
                                    <div style={{ paddingLeft: 25, paddingTop: 10 }}>
                                        <div style={{ paddingBottom: 10 }}>
                                            <label style={{}}>No other blocklist available. Please create a new blocklist.</label>
                                        </div>
                                        <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }} title="Create new blocklist" onClick={this.onAddBlacklist}>
                                            Create blocklist</a>
                                    </div>
                                    :
                                    <div style={{ paddingLeft: 25, paddingTop: 10 }}>
                                        <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }} title="Create new blocklist" onClick={this.onAddBlacklist}>
                                            Create blocklist</a>
                                    </div>
                                }
                                {filteredBlacklists && filteredBlacklists.length > 0 &&
                                    <React.Fragment>
                                        <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 10, paddingTop: 10, marginRight: 0, marginLeft: 0, borderBottom: '1px solid #d6d6d6' }}>

                                            <div style={{ width: '100%' }} className="datatable-responsive-demo">
                                                {headerBlacklists}
                                                <DataTable ref={(el) => this.dt = el}
                                                    //scrollable
                                                    //scrollHeight='190px'
                                                    selection={this.state.selectedBlacklistsAction}
                                                    selectionMode="radiobutton"
                                                    removableSort={true}
                                                    onSelectionChange={e => this.onBlacklistChange(e)}
                                                    //className="dataTableQueries checkboxCircle standardTableHeader"
                                                    className="p-datatable-responsive-demo"
                                                    value={filteredBlacklists}
                                                    responsive="true"
                                                    paginator={true}
                                                    rows={5}
                                                    globalFilter={this.state.globalFilterBlacklist}>
                                                    {columnDataBlacklists}
                                                </DataTable>
                                            </div>
                                        </div>
                                        <div className='col-12' style={{ paddingRight: 25, paddingTop: 8, marginBottom: 33 }}>
                                            {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                            title="Create new watchlist" onClick={this.addNewWatchlist}>
                        Create collection</a>*/}
                                            <Button label={this.state.selectedConceptsAction && this.state.selectedConceptsAction.length > 1 ?
                                                "Copy concepts" : this.state.selectedConceptsAction && this.state.selectedConceptsAction.length === 1 ? "Copy concept" : null}
                                                disabled={this.state.selectedBlacklistsAction && this.state.selectedBlacklistsAction !== null ? false : true}
                                                onClick={this.handleCopyConcepts}
                                                className='p-button-sm primaryButton'
                                                style={{ marginRight: 0, float: 'right', marginTop: 5 }}
                                            //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                            />
                                            {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                            <Button label="Cancel" onClick={() => this.handleCloseCopyConceptDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }} />
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.state.displayDialogMoveConcept} style={{ 'width': "50vw" }}
                            header={this.state.selectedConceptsAction && this.state.selectedConceptsAction.length > 1 ? "Move concepts" : this.state.selectedConceptsAction && this.state.selectedConceptsAction.length === 1 ? "Move concept" : null}
                            modal={true} dismissableMask={true} onHide={() => this.handleCloseMoveConceptDialog()} blockScroll className='styledDialog'>
                            <React.Fragment>
                                {filteredBlacklists && filteredBlacklists.length === 0 ?
                                    <div style={{ paddingLeft: 25, paddingTop: 10 }}>
                                        <div style={{ paddingBottom: 10 }}>
                                            <label style={{}}>No other blocklist available. Please create a new blocklist.</label>
                                        </div>
                                        <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }} title="Create new blocklist" onClick={this.onAddBlacklist}>
                                            Create blocklist</a>
                                    </div>
                                    :
                                    <div style={{ paddingLeft: 25, paddingTop: 10 }}>
                                        <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }} title="Create new blocklist" onClick={this.onAddBlacklist}>
                                            Create blocklist</a>
                                    </div>
                                }
                                {filteredBlacklists && filteredBlacklists.length > 0 &&
                                    <React.Fragment>
                                        <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 10, paddingTop: 10, marginRight: 0, marginLeft: 0, borderBottom: '1px solid #d6d6d6' }}>

                                            <div style={{ width: '100%' }} className="datatable-responsive-demo">
                                                {headerBlacklists}
                                                <DataTable ref={(el) => this.dt = el}
                                                    //scrollable
                                                    //scrollHeight='190px'
                                                    selection={this.state.selectedBlacklistsAction}
                                                    removableSort={true}
                                                    selectionMode="radiobutton"
                                                    onSelectionChange={e => this.onBlacklistChange(e)}
                                                    //className="dataTableQueries checkboxCircle standardTableHeader"
                                                    className="p-datatable-responsive-demo"
                                                    value={filteredBlacklists}
                                                    responsive="true"
                                                    paginator={true}
                                                    rows={5}
                                                    globalFilter={this.state.globalFilterBlacklist}>
                                                    {columnDataBlacklists}
                                                </DataTable>
                                            </div>
                                        </div>
                                        <div className='col-12' style={{ paddingRight: 25, paddingTop: 8, marginBottom: 33 }}>
                                            {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                        title="Create new watchlist" onClick={this.addNewWatchlist}>
                    Create collection</a>*/}
                                            <Button label={this.state.selectedConceptsAction && this.state.selectedConceptsAction.length > 1 ? "Move concepts" : this.state.selectedConceptsAction && this.state.selectedConceptsAction.length === 1 ? "Move concept" : null}
                                                disabled={this.state.selectedBlacklistsAction && this.state.selectedBlacklistsAction !== null ? false : true}
                                                onClick={this.handleMoveConcepts}
                                                className='p-button-sm primaryButton'
                                                style={{ marginRight: 0, float: 'right', marginTop: 5 }}
                                            //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                            />
                                            {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                            <Button label="Cancel" onClick={() => this.handleCloseMoveConceptDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }} />
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.state.displayExportBlocklists} style={{ 'width': "50vw" }}
                            header="Export blocklists" blockScroll
                            modal={true} dismissableMask={true} onHide={() => this.handleCloseExportDialog()} className='styledDialog'>
                            <React.Fragment>
                                {this.state.allBlacklists && this.state.allBlacklists.length > 0 &&
                                    <React.Fragment>
                                        <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 25, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>

                                            <div style={{ width: '100%' }} className="datatable-responsive-demo">
                                                {headerBlacklists}
                                                <DataTable ref={(el) => this.dt = el}
                                                    //scrollable
                                                    //scrollHeight='190px'
                                                    selection={this.state.selectedBlacklistsExport}
                                                    selectionMode="checkbox"
                                                    removableSort={true}
                                                    onSelectionChange={e => this.onExportBlacklistChange(e)}
                                                    //className="dataTableQueries checkboxCircle standardTableHeader"
                                                    className="p-datatable-responsive-demo"
                                                    value={filteredBlacklistsForExport}
                                                    responsive="true"
                                                    paginator={true}
                                                    rows={5}
                                                    globalFilter={this.state.globalFilterBlacklist}>
                                                    {columnDataBlacklists}
                                                </DataTable>
                                            </div>
                                        </div>
                                        <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                                            {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                            title="Create new watchlist" onClick={this.addNewWatchlist}>
                        Create collection</a>*/}
                                            <Button label="Export"
                                                disabled={this.state.selectedBlacklistsExport !== null ? false : true}
                                                onClick={this.handleExportBlocklists}
                                                className='p-button-sm primaryButton'
                                                style={{ marginRight: 0, float: 'right', marginTop: 0 }}
                                            //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                            />
                                            {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                            <Button label="Cancel" onClick={() => this.handleCloseExportDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 0, marginRight: '0.5rem' }} />
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.state.displayImportBlocklists} style={{ 'width': "50vw" }}
                            header="Import blocklists" blockScroll
                            modal={true} dismissableMask={true} onHide={() => this.handleCloseImportDialog()} className='styledDialog'>
                            <React.Fragment>
                                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                    <div className="col-12">
                                        <FileUpload
                                            name="upload"
                                            className="primaryButton"
                                            accept={acceptedFileTypesString}
                                            customUpload={true}
                                            uploadHandler={e => this.uploadHandler(e)}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel="Upload file" />
                                    </div>
                                    <div className="col-12">
                                        <InputTextarea
                                            id="content"
                                            autoResize={true}
                                            readOnly={false}
                                            //rows={5}
                                            //cols={30}
                                            className="width100perc"
                                            value={this.state.inputText}
                                            onChange={(e) => {
                                                this.setState({
                                                    inputText: e.target.value
                                                });
                                            }}
                                            style={{ height: '50vh', maxHeight: '50vh' }}
                                        />
                                    </div>
                                </div>
                                <React.Fragment>
                                    <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                                        {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                            title="Create new watchlist" onClick={this.addNewWatchlist}>
                        Create collection</a>*/}
                                        <Button label="Import"
                                            onClick={this.handleImportBlocklists}
                                            disabled={this.state.inputText && this.state.inputText !== "" ? false : true}
                                            className='p-button-sm primaryButton'
                                            style={{ marginRight: 0, float: 'right', marginTop: 0 }}
                                        //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                        />
                                        {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                        <Button label="Cancel" onClick={() => this.handleCloseImportDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 0, marginRight: '0.5rem' }} />
                                    </div>
                                </React.Fragment>
                            </React.Fragment>
                        </Dialog>
                        <OntologyBrowserDialog
                            ontBrowserDialogID="docSearchDomBrowserDlg"
                            headerLabel="Add concepts"
                            selectionMode="multiple"
                            placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                            placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                            domains={ontBrowserDialogDomains}
                            numOfChildNodes={10} // not used for preloaded ontology
                            allowConceptSearchByClick={true}
                            allowSearchInOntologies={true}
                            loadOntologiesOnStart={true}
                            ontBrowserVisible={this.state.displayDialogAddConcepts}
                            onOntBrowserClose={() => this.setState({ displayDialogAddConcepts: false })}
                            onOntBrowserSubmit={this.onSubmit}
                            onOntBrowserShow={() => { }}
                            width="90vw"
                            height="90vh">
                        </OntologyBrowserDialog>
                    </div>
                    <Toast ref={this.toastBC} position="bottom-center" style={{ width: (this.state.selectedBlacklistAction && this.state.selectedBlacklistAction.owner.id === this.props.userData.userDetails.id) || (this.state.selectedBlacklistAction && this.state.selectedBlacklistAction.owner.id !== this.props.userData.userDetails.id && this.state.selectedBlacklistAction.writable === true) ? "30rem" : "20rem", backgroundColor: 'white', opacity: '1', marginBottom: 45, boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }} />
                    <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                        onHide={this.handleUnselect} style={{ width: (this.state.selectedBlacklistAction && this.state.selectedBlacklistAction.owner.id === this.props.userData.userDetails.id) || (this.state.selectedBlacklistAction && this.state.selectedBlacklistAction.owner.id !== this.props.userData.userDetails.id && this.state.selectedBlacklistAction.writable === true) ? "37rem" : "20rem", bottom: 5 }}
                        draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                        <ToastContent selectedElements={this.state.selectedConceptsAction}
                            elementLabel='concept'
                            onHide={this.handleUnselect}
                            onCopyElements={this.handleCopyConceptsDialog}
                            onMoveConcepts={this.handleMoveConceptsDialog}
                            onChangeSearchMode={this.handleSearchModeConceptsDialog}
                            onDelete={this.handleRemoveConceptsDialog}
                            canEdit={(this.state.selectedBlacklistAction && this.state.selectedBlacklistAction.owner.id === this.props.userData.userDetails.id) || (this.state.selectedBlacklistAction && this.state.selectedBlacklistAction.owner.id !== this.props.userData.userDetails.id && this.state.selectedBlacklistAction.writable === true) ? true : false}
                            activeOptions={['copyElements', 'moveConcepts', 'setSearchMode', 'deleteElements']}
                            userDetails={this.props.userData.userDetails}
                            collectionID={this.state.selectedBlacklistAction} />
                    </Dialog>
                </LoadingOverlay>
            </div>
        );
    }
}

export default BlacklistManager;

