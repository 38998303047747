import _ from 'lodash';
import { sortMapByKeys, sortObjectArrayByAttribute, sortObjectMapByAttribute } from '../../util';
import { DOMAIN_ALIASES, FREE_TEXT_DOMAINS } from '../semantic-search/constants/general';



export const filterSentences = (annotationRanges) => {
    let sentences = []

    sentences = annotationRanges.filter(obj => {
        return obj.annotations.length === 0
    })
    return sentences
}
/**
 * Creates mapping of each OCID to all of its annotation IDs.
 * Annotation IDs will be sorted numerically (e.g. annId9, annId58, annId123).
 * @param {*} annotationRanges 
 * @param {*} conceptsData 
 * @returns 
 */
export const createOcidToAnnIdMap = (annotationRanges, conceptsData) => {
    const ocidToAnnIdsMap = {};
    if (annotationRanges && conceptsData) {
        for (const ne of Object.values(annotationRanges)) {
            const annIdNo = Number(ne.annId.replace(/annId/g, ''));
            for (const anno of Object.values(ne.annotations)) {
                let ocid;
                // --- OCID undefined for query term -> set to 0 --- //
                if (anno.queryTerm && !anno.queryId) {
                    ocid = 0;
                }
                if (anno.queryTerm && anno.queryId === 'ocFuzzyText:readcube_text') {
                    ocid = 0;
                }
                else if (anno.ocid) {
                    ocid = anno.ocid;
                }
                // --- add relevance score to concept data --- //
                if (anno.relevanceScore) {
                    conceptsData[ocid]['relevanceScore'] = anno.relevanceScore;
                }
                if (anno.additionalData?.isExample === 'y') {
                    if (!conceptsData[ocid]) { conceptsData[ocid] = {} }
                    conceptsData[ocid].isLabeledCompound = true;
                }
                else {
                    if (!conceptsData[ocid]) { conceptsData[ocid] = {} }
                    conceptsData[ocid].isLabeledCompound = false;
                }
                // --- add annID to OCID --- //
                if (ocid >= 0) {
                    if (!ocidToAnnIdsMap[ocid]) {
                        ocidToAnnIdsMap[ocid] = [];
                    }
                    else if (anno.ocid) {
                        ocid = anno.ocid;
                    }
                    // --- add relevance score to concept data --- //
                    if (anno.relevanceScore) {
                        conceptsData[ocid]['relevanceScore'] = anno.relevanceScore;
                    }
                    if (anno.additionalData?.isExample == 'y') {
                        if (!conceptsData[ocid]) { conceptsData[ocid] = {} }
                        conceptsData[ocid].isLabeledCompound = true;
                    }
                    else {
                        if (!conceptsData[ocid]) { conceptsData[ocid] = {} }
                        conceptsData[ocid].isLabeledCompound = false;
                    }
                    // --- add annID to OCID --- //
                    if (ocid >= 0) {
                        if (!ocidToAnnIdsMap[ocid]) {
                            ocidToAnnIdsMap[ocid] = [];
                        }
                        if (!ocidToAnnIdsMap[ocid].includes(annIdNo)) {
                            ocidToAnnIdsMap[ocid].push(annIdNo);
                        }
                    }
                }
            }
        }
    }

    // --- sort annotation IDs numerically --- //
    const ocidToAnnIdsSortedMap = {};
    for (const ocid of Object.keys(ocidToAnnIdsMap)) {
        const annIds = ocidToAnnIdsMap[ocid];
        const sorted = _.sortBy(annIds);
        ocidToAnnIdsSortedMap[ocid] = sorted;
    }
    //console.log('ocidToAnnIdsSortedMap');
    //console.log(ocidToAnnIdsSortedMap);

    return ocidToAnnIdsSortedMap;
}

/**
 * Returns data object containing the tree of concepts, the overall annotations count
 * and whether or not a query term is present.
 * @param {*} annotationRanges 
 * @param {*} conceptsData 
 * @param {*} annTypeLabelsMap 
 * @returns 
 */
export const createConceptTreeData = (annotationRanges, conceptsData, annTypeLabelsMap) => {
    //console.log(annotationRanges)
    let annTypesMap = {};
    const ocidCounts = {};
    let queryMatchesFound = false;
    let queryMatchesCount = 0;
    if (annotationRanges) {
        for (const ne of Object.values(annotationRanges)) {
            for (const anno of Object.values(ne.annotations)) {
                // --- if domain and OCID are defined -> add OCID to domain --- //
                if (anno.domain && anno.ocid) {
                    // --- get annotation type label --- //
                    const annTypeLabel = annTypeLabelsMap && annTypeLabelsMap[anno.domain] ? annTypeLabelsMap[anno.domain] : anno.domain;
                    // --- create key that can be used for sorting by label (later replaced by ID) --- //
                    let key = annTypeLabel + '==' + anno.domain;

                    if (!annTypesMap[key]) {
                        annTypesMap[key] = [];
                    }
                    if (!annTypesMap[key].includes(anno.ocid)) {
                        annTypesMap[key].push(anno.ocid);
                    }
                    // --- count number of attached OCIDs --- //
                    if (!ocidCounts[anno.ocid]) {
                        ocidCounts[anno.ocid] = 1;
                    }
                    else {
                        ocidCounts[anno.ocid]++;
                    }
                    // --- add annotation type label --- //
                    anno.annTypeLabel = annTypeLabel;
                }
                else if (anno.queryTerm && !anno.queryId) {
                    queryMatchesFound = true;
                    queryMatchesCount++;
                }
                else if (anno.queryTerm && anno.queryId === 'ocFuzzyText:readcube_text') {
                    queryMatchesFound = true;
                    queryMatchesCount++;
                }
            }
        }
    }

    // --- sort map by keys (annotation type labels) --- //
    annTypesMap = sortMapByKeys(annTypesMap);

    // --- replace temporary keys with annotation type IDs --- //
    var newAnnTypesMap = {};
    Object.keys(annTypesMap).forEach(function (key) {
        var value = annTypesMap[key];
        key = key.replace(/.*==/g, '');
        newAnnTypesMap[key] = value;
    });

    /*
    console.log('ocidCounts');
    console.log(ocidCounts);
    console.log('annTypesMap');
    console.log(annTypesMap);
    */

    // --- create tree object --- // 
    const conceptTreeData = [];
    let annotationsCount = 0;

    for (const domain of Object.keys(newAnnTypesMap)) {
        let children = [];
        let conceptCount = 0;
        for (const ocid of newAnnTypesMap[domain]) {
            if (conceptsData && conceptsData[ocid]) {
                const child = conceptsData[ocid];
                conceptCount += ocidCounts[child.ocid];
                children.push({
                    "key": child.ocid,
                    "ocid": child.ocid,
                    "type": "ne",
                    "domain": domain,
                    "label": child.preferredName,
                    "count": ocidCounts[child.ocid],
                });
            }
        }

        // --- sort children by their number of occurrence --- //
        children = sortObjectArrayByAttribute(children, 'count', false, false);

        // --- add counter to each child --- //
        let childNo = 1;
        for (const child of children) {
            child.childNo = childNo++;
        }

        conceptTreeData.push({
            "key": domain,
            "type": "domain",
            "label": annTypeLabelsMap && annTypeLabelsMap[domain] ? annTypeLabelsMap[domain] : domain,
            "domain": domain,
            "children": children,
            "css": "ann_" + domain,
            "count": conceptCount
        });

        annotationsCount += conceptCount;
    }

    return {
        conceptTreeData: conceptTreeData,
        annotationsCount: annotationsCount,
        queryMatchesFound: queryMatchesFound,
        queryMatchesCount: queryMatchesCount,
        completeAnnTypesMap: newAnnTypesMap
    };
}

/**
 * Returns maps of compound OCIDs as well as filtered map for labeled compounds only.
 * @param {*} completeAnnTypesMap 
 * @param {*} chemAnnType 
 * @param {*} conceptDataMap 
 * @returns 
 */
export const extractAnnotatedCompounds = (completeAnnTypesMap, chemAnnType, conceptDataMap, sortByAttr, ascending) => {
    let compoundOcidData = {};
    const filteredMap = {};
    if (completeAnnTypesMap && completeAnnTypesMap[chemAnnType]) {
        compoundOcidData = completeAnnTypesMap[chemAnnType].reduce(function (obj, ocid) {
            if (conceptDataMap.hasOwnProperty(ocid)) {
                obj[ocid] = conceptDataMap[ocid];
            }
            return obj;
        }, {});

        if (sortByAttr) {
            compoundOcidData = sortObjectMapByAttribute(compoundOcidData, sortByAttr, ascending);
        }

        Object.entries(compoundOcidData).forEach(([key, val]) => {
            if (val.isLabeledCompound) {
                filteredMap[key] = val;
            };
        });
    }
    return { compoundOcidData, filteredMap };
}


export const parseTermItemsFromQuickSearchBar = (selectedTerms, allUserDomains) => {
    const res = selectedTerms.map((item) => {
        const term = _.isArray(item.term) ? item.term[0] : item.term;
        return {
            ...item,
            term: term,
            color: getExactColorForNoColorTerm(item.domains, item.ocids, _.orderBy(allUserDomains, ['orderPriority'], 'desc')),
            id: generateUID(item, term),
        };
    });

    return res;
};

const generateUID = (item, term) => {
    return `q${(term?.trim().split(' ').join('') +
        (item.ocids ? item.ocids?.length : item.domains[0]) +
        item.domains?.length
    )}`;
};


export const getExactColorForNoColorTerm = (domain, ocids, allUserDomains) => {
    if (ocids) {
        const tempDomain = getHighPiriorityDomain(domain, ocids, allUserDomains);
        return tempDomain.length > 0 ? tempDomain[0]?.color : 'dee2e6';
    } else {
        if (FREE_TEXT_DOMAINS.includes(domain)) {
            return 'dee2e6';
        } else {
            const tempDomain = getHighPiriorityDomain(domain, true, allUserDomains);
            return tempDomain.length > 0 ? tempDomain[0]?.color : 'dee2e6';
        }
    }
};

export const getHighPiriorityDomain = (domain, ocids = null, allUserDomains = null) => {
    if (!ocids) {
        return domain.map((item) => {
            return {
                name: DOMAIN_ALIASES[item],
            };
        });
    }
    return allUserDomains.filter((dom) => domain.some((item) => item === dom.name));
};