/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { checkResultAndGetPayload } from '../../../api';
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { TreeSelect } from 'primereact/treeselect';
import { createWatchlist, fetchQueryByID, fetchWatchlists } from "../../../api/content/WatchlistApi";
import { InputTextarea } from "primereact/inputtextarea";
import { hasUserRole, isArrayEmpty, createUserArray, createCheckedKeys } from "../../webapi/util";
import { Checkbox } from "primereact/checkbox";
import { fetchAlertsIntervals, fetchUserShareStatus } from "../../../api/content/SaveSearch";
import { REPO_ROLE_DOC_SEARCH } from "../../../properties";

const activeAlertOptions = [
    { label: 'Active', value: true },
    { label: 'Paused', value: false }
]

const saveOptions = [
    {
        label: 'Search terms, documents, settings and progress',
        value: 'all'
    }, {
        label: 'Search terms only',
        value: 'terms'
    }, {
        label: 'Search terms and highlight settings',
        value: 'searchSettings'
    }, {
        label: 'Documents only',
        value: 'documents'
    }, {
        label: 'Documents and document settings',
        value: 'docSettings'
    }
]

class SaveSearchDialog extends Component {

    constructor(props) {
        super(props);
        let columns = [
            { field: "check", selectionMode: 'multiple', style: { width: '4em', fontWeight: 'normal' } },
            { field: "name", header: "Name", sortable: true, style: {} },
            { field: "numberQueries", header: "Searches", sortable: true, style: { fontWeight: 'normal', textAlign: 'left' } },
            { field: "", header: "Sharing", body: this.sharedTemplate, sortable: true, style: { textAlign: 'left' } }
        ]
        this.state = {
            cols: columns,
            globalFilterWatchlists: '',
            departmentMembers: [],
            selectedMembers: [],
            queryFullName: '',
            queryDescription: '',
            newWatchlistName: '',
            newWatchlistDescription: '',
            editableForSharedUsers: false,
            checkedKeys: {},
            checkedOrgKeys: {},
            usersShared: [],
            orgShared: [],
            userArray: [],
            orgArray: [],
            usersWrite: [],
            selectedSaveOption: {
                label: 'Search terms, documents, settings and progress',
                value: 'all'
            }
        };

        this.growl = React.createRef();
    }

    fetchSavedSearchRelatedData = () => {
        this.fetchSavedSearch();
        this.updateUserShareStatus();
        this.updateAlertsIntervals();
    }

    fetchSavedSearch = async () => {
        this.setState({
            fetchingSavedSearchData: true,
            selectedWatchlists: []
        });

        try {
            const availableRepositories = this.getAvailableRepositories();

            let savedSearchData;
            if (this.props.savedSearchID) {
                const response = await fetchQueryByID(this.props.savedSearchID);
                savedSearchData = checkResultAndGetPayload(response, this.growl);
                //console.log('by query ID', savedSearchData);

                // alert and selected repos
                const alert = !isArrayEmpty(savedSearchData?.notifications) ? savedSearchData.notifications[0] : null;
                const selectedRepos = [];
                const repos = !isArrayEmpty(alert?.repositories) ? alert.repositories :
                    this.props.userData?.userDetails?.department?.selectedRepositories;
                if (!isArrayEmpty(repos)) {
                    repos.forEach(rep => { selectedRepos.push(rep); })
                }
                let formContent = {}
                let selectedSaveOption = {}
                if (this.props.addSaveOptions) {
                    formContent = JSON.parse(savedSearchData.formContent)
                    if (formContent.hasOwnProperty('queryString')) {
                        selectedSaveOption = saveOptions.find(opt => {
                            return opt.label === formContent.queryString
                        })
                    } else {
                        selectedSaveOption = {
                            label: 'Search terms, documents, settings and progress',
                            value: 'all'
                        }
                    }
                }
                //console.log('all repos from alert  ', repos);
                //console.log('all available repos   ', availableRepositories);
                //console.log('alert repos available  ', (repos?.filter(repo => availableRepositories?.some(rep => rep.id === repo.id)) || []));
                //console.log('alert repos unavailable', (repos?.filter(repo => !availableRepositories?.some(rep => rep.id === repo.id)) || []));

                this.setState({
                    queryFullName: savedSearchData?.fullName || '',
                    queryDescription: savedSearchData?.description || '',
                    hasNotification: !!alert,
                    notificationID: alert?.id,
                    isActiveEdit: alert ? alert.active : true,
                    intervalEdit: alert?.interval || 'DAILY',
                    selectedRepos: selectedRepos?.filter(repo => availableRepositories?.some(rep => rep.id === repo.id)) || [],
                    availableRepositories: availableRepositories,
                    selectedSaveOption: selectedSaveOption
                    //activeRepos: selectedRepos?.filter(repo => availableRepositories?.some(rep => rep.id === repo.id)) || [],
                    //nonActiveRepos: selectedRepos?.filter(repo => !availableRepositories.some(rep => rep.id === repo.id)) || [],
                });
            }
            else {
                this.setState({
                    queryFullName: '',
                    queryDescription: '',
                    hasNotification: false,
                    notificationID: null,
                    isActiveEdit: true,
                    intervalEdit: 'DAILY',
                    selectedRepos: availableRepositories ? [...availableRepositories] : [],
                    availableRepositories: availableRepositories
                });
            }

            // watchlists
            const collectionIds = savedSearchData?.queryCollectionList ? savedSearchData.queryCollectionList.map(id => { return id }) : [];
            //this.fetchWatchlists().then(() => this.updateWatchlistSelections(collectionIds));
            this.fetchWatchlists(collectionIds);

            this.setState({ fetchingSavedSearchData: false });
        }
        catch (e) {
            console.error(e);
            //this.setState({ fetchingSavedSearchData: false });
        }
        finally {
            this.setState({ fetchingSavedSearchData: false });
        }
    }

    fetchWatchlists = async (selectedCollectionIDs) => {
        const response = await fetchWatchlists(false, false, true);
        const result = checkResultAndGetPayload(response, this.growl);

        this.setState({ allWatchlists: result || [] },
            () => this.updateWatchlistSelections(selectedCollectionIDs));
    }

    getAvailableRepositories = () => {
        let availableRepositories = [];
        this.props.userData.userDetails?.department?.selectedRepositories?.forEach(rep => {
            if (rep.active && rep.features?.includes(REPO_ROLE_DOC_SEARCH.id)) {
                availableRepositories.push(rep);
            }
        });
        return availableRepositories;
    }

    updateUserShareStatus = async () => {

        const response = await fetchUserShareStatus();
        const result = checkResultAndGetPayload(response, this.growl);

        if (result) {
            this.setState({
                departmentMembers: result
            }, () => {
                let userArray = createUserArray(result, this.props.userData, 'user')
                let orgArray = createUserArray(result, this.props.userData, 'org')
                this.setState({
                    userArray: userArray,
                    orgArray: orgArray
                })
            });
        }
    }

    updateAlertsIntervals = async () => {

        if (this.props.allowAlerts && hasUserRole("ROLE_ALERTS")) {

            const response = await fetchAlertsIntervals();
            const result = checkResultAndGetPayload(response, this.growl);

            this.setState({
                queryIntervals: result ? result.map(entry => { return { label: entry, value: entry } }) : []
            });
        }
    }

    updateWatchlistSelections = (selectedQueryCollectionIDs = []) => {

        let selectedWatchlists = [];
        this.state.allWatchlists?.forEach(list => {
            selectedQueryCollectionIDs.forEach(sel => {
                if (list.id === sel.id) {
                    selectedWatchlists.push(list);
                }
            })
        })
        // put selected watchlists on the top of the list
        const watchlistsSorted = this.sortWatchlistsBySelectionState(this.state.allWatchlists, selectedWatchlists);

        this.setState({
            allWatchlists: watchlistsSorted,
            selectedWatchlists: selectedWatchlists
        });
    }

    onStoreQuery = async () => {

        const watchlistIDs = this.state.selectedWatchlists && this.state.selectedWatchlists.length > 0 ?
            this.state.selectedWatchlists.map(list => list.id) : null;

        if (this.props.addSaveOptions) {
            this.props.onSubmit(this.state.selectedSaveOption.label, "abc", this.state.queryFullName, this.state.queryDescription, watchlistIDs, this.state.selectedSaveOption);
        } else {
            this.props.onSubmit(this.props.queryString, "abc", this.state.queryFullName, this.state.queryDescription, watchlistIDs,
                this.state.hasNotification, this.state.notificationID, this.state.isActiveEdit, this.state.intervalEdit, this.state.selectedRepos)
        }
    }

    handleClearAll = async () => {
        this.setState({
            queryFullName: '',
            queryDescription: '',
            hasNotification: false,
            notificationID: null,
            isActiveEdit: true,
            intervalEdit: 'DAILY',
            selectedRepos: this.state.availableRepositories ? [...this.state.availableRepositories] : [],
            selectedWatchlists: []
        });
    }

    onWatchlistSelectionChange = (e) => {
        this.setState({
            selectedWatchlists: e.value
        })
    }

    onAddNewWatchlist = () => {
        this.setState({
            newWatchlistDescription: '',
            newWatchlistName: '',
            sharedForDepartment: false,
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            displayDialogAddWatchlist: true,
            checkedKeys: {},
            checkedOrgKeys: {},
            editableForSharedUsers: false
        });
    }

    hideCreateWatchlist = () => {
        this.setState({
            displayDialogAddWatchlist: false
        })
    }

    handleShareWithDepartment = (e) => {
        this.setState({
            sharedForDepartment: e.value
        })
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.state.orgArray && this.state.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.state.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.state.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.state?.departmentMembers, this.state.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.state.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        //console.log(members)
        this.setState({
            usersWrite: members
        })
    }

    onSaveWatchlist = async () => {

        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }

        const response = await createWatchlist(this.state.newWatchlistName, this.state.newWatchlistDescription, this.state.sharedForDepartment, usersRead, this.state.usersWrite, this.state.orgShared, this.state.editableForSharedUsers);
        const result = checkResultAndGetPayload(response, this.growl);

        if (result) {
            this.setState({
                displayDialogAddWatchlist: false,
                selectedWatchlists: [...this.state.selectedWatchlists, result]
            });
        }
        this.fetchWatchlists(this.state.selectedWatchlists);
    }

    onHide = () => {
        this.setState({
            queryFullName: '',
            queryDescription: '',
            hasNotification: false,
            notificationID: null,
            isActiveEdit: false,
            intervalEdit: null,
            selectedRepos: null,
            allWatchlists: null
        });
        this.props.onHide();
    }

    sharedTemplate(rowData) {
        return <div style={{ marginBottom: 5 }}>
            {!rowData.shared ?
                ((rowData.departmentSharesRead && rowData.departmentSharesRead.length > 0) || (rowData.userSharesRead && rowData.userSharesRead.length > 0) || (rowData.userSharesWrite && rowData.userSharesWrite.length > 0)) ?
                    <span title={`You share this search collection with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This search collection can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This search collection is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }

    sortWatchlistsBySelectionState = (allWatchlists, selectedWatchlists) => {
        const watchlistsSorted = [];
        const selWatchlistIDs = {};
        selectedWatchlists?.forEach(swl => {
            watchlistsSorted.push(swl);
            selWatchlistIDs[swl.id] = true;
        });
        allWatchlists?.forEach(wl => {
            if (!selWatchlistIDs[wl.id]) {
                watchlistsSorted.push(wl);
            }
        });

        return watchlistsSorted;
    }

    handleEditable = (e) => {
        this.setState({
            editableForSharedUsers: e.value
        })
    }

    render() {
        //console.log(this.state.selectedCollections)
        //console.log(this.state.selectedWatchlists)
        //console.log('props', this.props);
        //console.log('state', this.state)

        //const watchlistsSorted = this.sortWatchlistsBySelectionState(this.state.allWatchlists, this.state.selectedWatchlists);
        const watchlistsSorted = this.state.allWatchlists;

        const sharedWithDepartmentOptions = [
            { label: 'yes', value: true },
            { label: 'no', value: false }
        ]

        let members = []
        this.state.departmentMembers && this.state.departmentMembers.forEach(member => {
            members = [...members, { label: `${member.lastName}, ${member.forename} (${member.username})`, value: member.id }]
        })

        const footerSaveQuery = (
            <div style={{ padding: '5px 5px 0px 5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <span>* required</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        className="primaryButtonAsLink"
                        label="Clear form"
                        onClick={(e) => this.handleClearAll()}
                        style={{ marginRight: 30 }}
                    />
                    <Button
                        className="p-button-secondary p-button-sm"
                        label="Cancel"
                        onClick={(e) => this.onHide()}
                        style={{ marginRight: 10 }}
                    />
                    <Button
                        className="primaryButton p-button-sm"
                        label="Save"
                        disabled={!this.state.queryFullName}
                        onClick={(e) => this.onStoreQuery()}
                    />
                </div>
            </div>
        );

        const footerCreateWatchlist = (
            <div className='col-12' style={{ paddingRight: 3 }}>
                <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 5 }}>* required</label>
                <Button label="Create" onClick={this.onSaveWatchlist} style={{ float: 'right' }} className='primaryButton p-button-sm'
                    disabled={this.state.newWatchlistName && this.state.newWatchlistName.length > 0 ? false : true} />
                <Button
                    className="p-button-secondary p-button-sm"
                    label="Cancel"
                    onClick={(e) => this.hideCreateWatchlist(e)}
                    style={{ float: 'right', marginRight: 10 }}
                />
            </div>
        )

        const headerWatchlists = <div className='grid' style={{
            marginRight: 0,
            paddingTop: 18, paddingBottom: 10
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search collections">
                    {'search'}
                </i>
                <InputText
                    style={{ width: '95%', border: 'none', float: 'left' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterWatchlists: e.target.value })}
                    value={this.state.globalFilterWatchlists}
                    placeholder="Search collections" />
            </div>
        </div>

        let columnData = this.state.cols ? this.state.cols.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        }) : [];

        function IsJsonString(str) {
            try {
                let json = JSON.parse(str)
                return (typeof json === 'object')
            } catch (e) {
                return false
            }
        }

        const isJSON = IsJsonString(this.props.queryString);
        let queryStringToShow = "";
        if (isJSON) {
            const parsedQueryString = JSON.parse(this.props.queryString);
            const query = parsedQueryString?.query;
            const filterQueries = parsedQueryString?.filterQueries;
            const filterQueriesString = filterQueries ? JSON.stringify(filterQueries) : '';

            queryStringToShow = <div style={{ color: '#757575', cursor: 'default', paddingTop: 5 }}>
                {isArrayEmpty(filterQueries) ?
                    <div>`${query}`</div>
                    :
                    <>
                        <div>{`Main search: ${query}`}</div>
                        <div className="clampingContainer line-clamp line-clamp-2-lines"
                            title={filterQueriesString}
                            style={{ paddingTop: 5 }}>Filter search: {filterQueriesString}
                        </div>
                    </>}
            </div>
        }

        const saveButtons = saveOptions.map((option) => (
            <div key={option.label} className="p-field-radiobutton" style={{ paddingRight: 10 }}>
                <RadioButton style={{ verticalAlign: 'unset' }} inputId={option.label} value={this.state.selectedSaveOption}
                    onChange={(e) => this.setState({ selectedSaveOption: option })}
                    checked={this.state.selectedSaveOption?.value === option.value} />
                <label style={{ paddingLeft: 5 }} htmlFor={option.label}>{option.label}</label>
            </div>
        ));

        return (
            <React.Fragment>

                <Toast ref={this.growl} />

                <Dialog visible={this.props.displayDialogStoreQuery}
                    style={{ 'width': "90vw" }}
                    header={this.props.editQuery ? 'Save changes' : `Save as new`}
                    footer={footerSaveQuery}
                    focusOnShow={false}
                    onShow={() => { this.fetchSavedSearchRelatedData() }}
                    modal={true}
                    blockScroll={true}
                    onHide={() => this.onHide()} className='styledDialog'>
                    <LoadingOverlay
                        active={this.state.fetchingSavedSearchData}
                        spinner={true}
                        text="Fetching data..." >
                        <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 grid p-fluid' style={{ marginRight: 20 }}>
                                {this.props.addSaveOptions ?
                                    <>
                                        <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{ padding: '.75em' }}>
                                            <label style={{ verticalAlign: 'sub' }} htmlFor="fullname">Save *</label>
                                        </div>
                                        <div className="grid col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{ padding: '.5em' }}>
                                            {saveButtons}
                                        </div>
                                    </>
                                    : null
                                }
                                <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }} htmlFor="fullname">Name *</label>
                                </div>
                                <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{ padding: '.5em' }}>
                                    <InputText
                                        id="fullname"
                                        autoComplete="off"
                                        maxLength={255}
                                        onChange={(e) => { this.setState({ queryFullName: e.target.value }) }}
                                        value={this.state.queryFullName}
                                    />
                                    <label style={{ color: 'darkgray' }}>Max. 255 characters</label>
                                </div>
                                <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{ padding: '.75em' }}><label style={{ verticalAlign: 'sub' }} htmlFor="description">Description</label></div>
                                <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{ padding: '.5em' }}>
                                    <InputTextarea rows={4} cols={30}
                                        maxLength={200}
                                        onChange={(e) => {
                                            this.setState({ queryDescription: e.target.value })
                                        }}
                                        value={this.state.queryDescription}
                                    />
                                    <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                                </div>
                                {!this.props.addSaveOptions ?
                                    <>
                                        <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{ padding: '.75em' }}><label style={{ verticalAlign: 'sub' }} htmlFor="query">Search</label></div>
                                        <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{ padding: '.5em', paddingTop: '1em', color: 'darkgray' }}>
                                            <div className="clampingContainer line-clamp line-clamp-10-lines" title={isJSON ? queryStringToShow : this.props.queryString}>{isJSON ? queryStringToShow : this.props.queryString}</div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {this.props.allowAlerts && hasUserRole("ROLE_ALERTS") &&
                                    <React.Fragment>
                                        <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{ padding: '.75em', paddingTop: 1 }}>
                                            <label style={{ verticalAlign: 'sub' }} htmlFor="query">Alert</label>
                                        </div>
                                        <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9" style={{ padding: '.5em', paddingTop: 0 }}>
                                            <Checkbox
                                                onChange={(e) => this.setState({ hasNotification: e.checked })}
                                                checked={this.state.hasNotification}
                                                //disabled={this.props.selectedUserSAML ? true : false}
                                                value='Get email updates on new results' style={{ 'cursor': 'pointer', marginTop: 4 }}
                                            />
                                            <label className='p-checkbox-label'>Get email updates on new results</label>
                                        </div>
                                        <div className="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3" style={{ padding: '.75em', paddingTop: 1 }}></div>
                                        {this.state.hasNotification ?
                                            <div className="col-8 sm:col-9 md:col-9 lg:col-9 xl:col-9 grid p-fluid" style={{ padding: '.5em', paddingTop: 0 }}>
                                                <div className='col-12 sm:col-5 md:col-5 lg:col-4 xl:col-4' style={{ paddingLeft: 0, paddingBottom: 0 }}>
                                                    <Dropdown
                                                        appendTo={document.body}
                                                        className='dropdownNoBorder'
                                                        value={this.state.isActiveEdit}
                                                        options={activeAlertOptions}
                                                        onChange={(e) => { this.setState({ isActiveEdit: e.value }) }}
                                                        style={{ //width: 75, minWidth: 75,
                                                            marginTop: -1, marginBottom: -6
                                                        }}
                                                        showClear={false}
                                                        placeholder='Alert status' />
                                                </div>
                                                <div className='col-12 sm:col-7 md:col-7 lg:col-8 xl:col-8' style={{ paddingBottom: 0 }}>
                                                    <label style={{ float: 'left', marginTop: 6 }}>Interval:</label>
                                                    <Dropdown
                                                        appendTo={document.body}
                                                        className='dropdownNoBorder'
                                                        value={this.state.intervalEdit}
                                                        options={this.state.queryIntervals}
                                                        onChange={(e) => { this.setState({ intervalEdit: e.value }) }}
                                                        style={{ //width: 90, minWidth: 90,
                                                            marginTop: -1
                                                        }}
                                                        showClear={false}
                                                        placeholder='Add alert' />
                                                </div>
                                                <div className='col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12' style={{ paddingTop: 0 }}>
                                                    <label style={{ float: 'left', marginTop: 6 }}>From repositories:</label>
                                                    <MultiSelect
                                                        pt={{
                                                            checkboxIcon: {
                                                                onClick: (e) => {
                                                                    e.stopPropagation();
                                                                    if (e.target.className.baseVal !== "") {
                                                                        e.target.parentNode.click();
                                                                    } else {
                                                                        e.target.parentNode.parentNode.click();
                                                                    }
                                                                },
                                                            },
                                                            headerCheckbox: {
                                                                onClick: (e) => {
                                                                    e.stopPropagation();
                                                                    e.target.parentNode.click();
                                                                },
                                                            },
                                                        }}
                                                        value={this.state.selectedRepos}
                                                        options={this.state.availableRepositories}
                                                        onChange={(e) => this.setState({ selectedRepos: e.value })}
                                                        appendTo={document.body}
                                                        placeholder="Select repositories"
                                                        style={{// width: 150, minWidth: 150,
                                                            border: 'none', marginTop: 1
                                                        }}
                                                        maxSelectedLabels={3}
                                                    />
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </React.Fragment>
                                }
                            </div>

                            <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 grid p-fluid'>
                                <div className="col-12" style={{ padding: '.75em', paddingLeft: 40 }}>
                                    <label style={{ verticalAlign: 'sub' }} >Add to collection</label>
                                    {/*- can be shown on the dashboard to easily keep track of new results*/}
                                    <div>
                                        <a onClick={(e) => this.onAddNewWatchlist(e)}
                                            style={{ display: 'inline-block', marginTop: 10 }}>Create new collection</a>
                                    </div>
                                    <div>
                                        {!isArrayEmpty(watchlistsSorted) &&
                                            <React.Fragment>
                                                {headerWatchlists}
                                                <DataTable ref={(el) => this.dt = el}
                                                    scrollable
                                                    scrollHeight='400px'
                                                    selection={this.state.selectedWatchlists}
                                                    selectionMode="checkbox"
                                                    removableSort={true}
                                                    onSelectionChange={e => this.onWatchlistSelectionChange(e)}
                                                    className="dataTableQueries standardTableHeader"
                                                    value={watchlistsSorted}
                                                    responsive='true'
                                                    paginator={false}
                                                    globalFilter={this.state.globalFilterWatchlists}>
                                                    {columnData}
                                                </DataTable>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </Dialog>

                <Dialog focusOnShow={false}
                    visible={this.state.displayDialogAddWatchlist}
                    style={{ 'width': "60vw" }}
                    header="Create new search collection"
                    modal={true}
                    footer={footerCreateWatchlist}
                    dismissableMask={true}
                    onHide={(e) => this.hideCreateWatchlist(e)}
                    blockScroll={true}
                    className='styledDialog'>
                    <div id='createCollectionForm'
                        className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}><label htmlFor="name">Name *</label></div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <InputText maxLength={50} id="name" onChange={(e) => { this.setState({ newWatchlistName: e.target.value }) }} value={this.state.newWatchlistName} />
                            <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}><label htmlFor="description">Description</label></div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <InputText maxLength={200} id="description" onChange={(e) => { this.setState({ newWatchlistDescription: e.target.value }) }} value={this.state.newWatchlistDescription} />
                            <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ display: 'flex', paddingBottom: 15, paddingRight: 10, paddingTop: 5 }}>
                            <div className="w-full" >
                                <TreeSelect disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.state.departmentMembers?.length !== 0 ? false : true}
                                    id='sharingTreeSelect'
                                    resetFilterOnHide={true}
                                    //appendTo={document.body}
                                    selectionMode='checkbox'
                                    placeholder='Select suborganizations'
                                    value={this.state.checkedOrgKeys}
                                    options={this.state.orgArray}
                                    display='chip'
                                    scrollHeight='200px'
                                    filter
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        this.handleMemberChange(e.value, true)
                                    }} style={{
                                        borderBottom: '1px solid gray', maxWidth: '19vw'
                                    }} />
                            </div>
                            <div className="w-full" >
                                <TreeSelect disabled={this.state.departmentMembers?.length !== 0 ? false : true}
                                    id='sharingTreeSelect'
                                    resetFilterOnHide={true}
                                    appendTo={document.body}
                                    selectionMode='checkbox'
                                    placeholder='Select users'
                                    //title='Select colleagues'
                                    value={this.state.checkedKeys}
                                    options={this.state.userArray}
                                    display='chip'
                                    scrollHeight='200px'
                                    //optionLabel='label'
                                    //optionGroupLabel='label'
                                    //optionGroupChildren="items"
                                    //optionGroupTemplate={groupedItemsTemplate}
                                    filter
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        this.handleMemberChange(e.value, false)
                                    }} style={{
                                        borderBottom: '1px solid gray', marginLeft: 10, maxWidth: '19vw'
                                    }} />
                            </div>
                        </div>
                        {this.state.usersShared && this.state.usersShared.length !== 0 ?
                            <React.Fragment>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Editable for shared users</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                    {/*<Dropdown
                                value={this.state.editableForSharedUsers}
                                options={sharedWithDepartmentOptions}
                                appendTo={document.body}
                                className='dropdownNoBorder'
                                onChange={(e) => { this.handleEditable(e); }}
                                style={{ width: '100px' }}
                                disabled={this.state.usersShared && this.state.usersShared.length === 0 ? true : false} />
                        */}
                                    <MultiSelect
                                        pt={{
                                            checkboxIcon: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    if (e.target.className.baseVal !== "") {
                                                        e.target.parentNode.click();
                                                    } else {
                                                        e.target.parentNode.parentNode.click();
                                                    }
                                                },
                                            },
                                            headerCheckbox: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    e.target.parentNode.click();
                                                },
                                            },
                                        }}
                                        id='sharingMultiSelect'
                                        display='chip'
                                        placeholder='Select users'
                                        onChange={(e) => this.onWriteMembersChange(e.value)}
                                        value={this.state.usersWrite}
                                        options={this.state.sharedUsers}
                                    //disabled={this.state.usersShared && this.state.usersShared.length === 0 ? true : false}
                                    >
                                    </MultiSelect>
                                </div>
                            </React.Fragment>
                            : null
                        }
                        <div style={{ display: 'flex', justifyContent: 'end', paddingLeft: 17 }}>
                            <div className="full-text-message-collections col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8">
                                Note: Users changing the suborganization will lose access to search collections shared on suborganization level, but will keep access to search collections shared on personal level.
                            </div>
                        </div>
                    </div>
                </Dialog>

            </React.Fragment>
        );
    }
}

export default SaveSearchDialog;