export const isChemCompoundOcid = (ocid) => {
    if (ocid) {
        const ocidStrg = ocid + "";
        return !!ocidStrg.match(/19[0-9]{10}/);
    }
    return false;
}

export const hasChemCompoundOcid = (ocids) => {
    return ocids?.some(ocid => isChemCompoundOcid(ocid));
}

export const isChemClassOcid = (ocid) => {
    if (ocid) {
        const ocidStrg = ocid + "";
        return !!ocidStrg.match(/15[0-9]{10}/) /*|| !!ocidStrg.match(/125[0-9]{9}/)*/;
    }
    return false;
}

export const hasChemClassOcid = (ocids) => {
    return ocids?.some(ocid => isChemClassOcid(ocid));
}

export const isChemOcid = (ocid) => {
    return isChemCompoundOcid(ocid) || isChemClassOcid(ocid);
}

export const hasChemOcid = (ocids) => {
    return ocids?.some(ocid => isChemCompoundOcid(ocid) || isChemClassOcid(ocid));
}