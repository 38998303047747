
export const SentenceAnalysisActions = {
    SetSentenceAnalysisSentences: 'SET_SENTENCE_ANALYSIS_SENTENCES',
    SetSelectedSentenceAnalysis: 'SET_SELECTED_SENTENCE_ANALYSIS',
    SetDocumentSentencesSentenceAnalysis: 'SET_DOCUMENT_SENTENCES_SENTENCE_ANALYSIS',
    SetQueryTermsThresholdSentenceAnalysis: 'SET_QUERYTERMS_THRESHOLD_SENTENCE_ANALYSIS',
    SetSentenceOptionSentenceAnalysis: 'SET_SENTENCE_OPTION_SENTENCE_ANALYSIS',
    SetActiveSearchTermsSentenceAnalysis: 'SET_ACTIVE_SEARCH_TERMS_SENTENCE_ANALYSIS',
    SetAnnotationCoordinatesSentenceAnalysis: 'SET_ANNOTATION_COORDINATES_SENTENCE_ANALYSIS',
    SetLoaderSentenceAnalysis: 'SET_LOADER_SENTENCE_ANALYSIS',
    SetIsPDFSentenceAnalysis: 'SET_IS_PDF_SENTENCE_ANALYSIS',
    SetShowSentenceEntitiesSentenceAnalysis: 'SET_SHOW_SENTENCE_ENTITIES_SENTENCE_ANALYSIS',
    SetSelectedDocumentsSentenceAnalysis: 'SET_SELECTED_DOCUMENTS_SENTENCE_ANALYSIS',
    SetSelectedMetadataOptionSentenceAnalysis: 'SET_SELECTED_METADATA_OPTION_SENTENCE_ANALYSIS',
    SetSectionOptionsSentenceAnalysis: 'SET_SECTION_OPTIONS_SENTENCE_ANALYSIS',
    SetSelectedSectionsSentenceAnalysis: 'SET_SELECTED_SECTIONS_SENTENCE_ANALYSIS',
    SetSortFieldsSentenceAnalysis: 'SET_SORT_FIELDS_SENTENCE_ANALYSIS',
    SetSelectedSortFieldSentenceAnalysis: 'SET_SELECTED_SORT_FIELD_SENTENCE_ANALYSIS',
    SetSelectedSortDirectionSentenceAnalysis: 'SET_SELECTED_SORT_DIRECTION_SENTENCE_ANALYSIS',
    SetSortDirectionDisabledSentenceAnalysis: 'SET_SORT_DIRECTION_DISABLED_SENTENCE_ANALYSIS',
    SetSelectedFamilyDocumentsSentenceAnalysis: 'SET_SELECTED_FAMILY_DOCUMENTS_SENTENCE_ANALYSIS',
    SetSelectedFamilyDocumentsRemoveSentenceAnalysis: 'SET_SELECTED_FAMILY_DOCUMENTS_REMOVE_SENTENCE_ANALYSIS',
    SetSearchLoadedSentenceAnalysis: 'SET_SEARCH_LOADED_SENTENCE_ANALYSIS',
    SetDocumentHTMLSentenceAnalysis: 'SET_DOCUMENT_HTML_SENTENCE_ANALYSIS'
};


export const setSentenceAnalysisSentences = (sentences) => ({
    type: SentenceAnalysisActions.SetSentenceAnalysisSentences,
    payload: sentences,
});

export const setSelectedSentenceAnalysis = (selectedSentenceID) => ({
    type: SentenceAnalysisActions.SetSelectedSentenceAnalysis,
    payload: selectedSentenceID
});

export const setDocumentSentencesSentenceAnalysis = (documentSentences) => ({
    type: SentenceAnalysisActions.SetDocumentSentencesSentenceAnalysis,
    payload: documentSentences
});

export const setQueryTermsThresholdSentenceAnalysis = (threshold) => ({
    type: SentenceAnalysisActions.SetQueryTermsThresholdSentenceAnalysis,
    payload: threshold
});

export const setSentenceOptionSentenceAnalysis = (sentenceOption) => ({
    type: SentenceAnalysisActions.SetSentenceOptionSentenceAnalysis,
    payload: sentenceOption
});

export const setActiveSearchTermsSentenceAnalysis = (activeSearchTerms) => ({
    type: SentenceAnalysisActions.SetActiveSearchTermsSentenceAnalysis,
    payload: activeSearchTerms
});

export const setAnnotationCoordinatesSentenceAnalysis = (coords) => ({
    type: SentenceAnalysisActions.SetAnnotationCoordinatesSentenceAnalysis,
    payload: coords
});

export const setDocumentHTMLSentenceAnalysis = (docHTML) => ({
    type: SentenceAnalysisActions.SetDocumentHTMLSentenceAnalysis,
    payload: docHTML
});

export const setLoaderSentenceAnalysis = (loading) => ({
    type: SentenceAnalysisActions.SetLoaderSentenceAnalysis,
    payload: loading
});

export const setIsPDFSentenceAnalysis = (isPDF) => ({
    type: SentenceAnalysisActions.SetIsPDFSentenceAnalysis,
    payload: isPDF
});

export const setShowSentenceEntitiesSentenceAnalysis = (showSentenceEntities) => ({
    type: SentenceAnalysisActions.SetShowSentenceEntitiesSentenceAnalysis,
    payload: showSentenceEntities
});

export const setSelectedDocumentsSentenceAnalysis = (documents) => ({
    type: SentenceAnalysisActions.SetSelectedDocumentsSentenceAnalysis,
    payload: documents
});

export const setSelectedMetadataOptionSentenceAnalysis = (selectedMetadataOption) => ({
    type: SentenceAnalysisActions.SetSelectedMetadataOptionSentenceAnalysis,
    payload: selectedMetadataOption
});

export const setSectionOptionsSentenceAnalysis = (options) => ({
    type: SentenceAnalysisActions.SetSectionOptionsSentenceAnalysis,
    payload: options
});

export const setSelectedSectionsSentenceAnalysis = (sections) => ({
    type: SentenceAnalysisActions.SetSelectedSectionsSentenceAnalysis,
    payload: sections
});

export const setSortFieldsSentenceAnalysis = (sortFields) => ({
    type: SentenceAnalysisActions.SetSortFieldsSentenceAnalysis,
    payload: sortFields
});

export const setSelectedSortFieldSentenceAnalysis = (sortField) => ({
    type: SentenceAnalysisActions.SetSelectedSortFieldSentenceAnalysis,
    payload: sortField
});

export const setSelectedSortDirectionSentenceAnalysis = (sortDirection) => ({
    type: SentenceAnalysisActions.SetSelectedSortDirectionSentenceAnalysis,
    payload: sortDirection
});

export const setSortDirectionDisabledSentenceAnalysis = (sortDirectionDisabled) => ({
    type: SentenceAnalysisActions.SetSortDirectionDisabledSentenceAnalysis,
    payload: sortDirectionDisabled
});

export const setSelectedFamilyDocumentsSentenceAnalysis = (familyDocuments) => ({
    type: SentenceAnalysisActions.SetSelectedFamilyDocumentsSentenceAnalysis,
    payload: familyDocuments
});

export const setSelectedFamilyDocumentsRemoveSentenceAnalysis = (familyDocumentsRemove) => ({
    type: SentenceAnalysisActions.SetSelectedFamilyDocumentsRemoveSentenceAnalysis,
    payload: familyDocumentsRemove
});

export const setSearchLoadedSentenceAnalysis = (loaded) => ({
    type: SentenceAnalysisActions.SetSearchLoadedSentenceAnalysis,
    payload: loaded
});