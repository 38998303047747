// to expand all tree we have to find all parents of the node
import {findParents} from "./findParents";

export const findKeysToExpand = (nodes, nodeKey) => {
    const reducer = (acc, curVal) => {
        acc[curVal] = true;
        return acc;
    }

    return findParents(nodes, nodeKey).reduce(reducer, {});
}
