/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { isArrayEmpty } from '../util';

class Changelog extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { changelog } = this.props;

    return (
      !isArrayEmpty(changelog) ?
        <div className='grid' style={{ paddingLeft: 7 }}>
          {changelog.map(cl => {
            return <React.Fragment key={cl.date}>
              <div className='col-6 sm:col-6 md:col-5 lg:col-4 xl:col-3'
                style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 10 }}>
                <span style={{ fontSize: 14, color: "#757575" }}>{cl.date}</span>
              </div>
              <div className='col-6 sm:col-6 md:col-7 lg:col-8 xl:col-9'
                style={{ paddingTop: 0, paddingLeft: 15 }}>
                <ul style={{ marginTop: 0 }}>
                  {cl.changes.map(entry => {
                    const isCurrentEntry = this.state.visible && this.state.entryText === entry.text;
                    return <li key={entry.text}
                      style={{ fontSize: 14, padding: '0 0 20px 0' }}>
                      {entry.text}
                      {!isArrayEmpty(entry.subentries) ?
                        <>
                          <a className='primaryLink'
                            style={{ marginLeft: 15 }}
                            onClick={e => this.setState({ visible: isCurrentEntry ? !this.state.visible : true, entryText: entry.text })}>
                            {isCurrentEntry ? 'Hide details' : 'Show details'}
                          </a>
                          {isCurrentEntry ?
                            <ul style={{ marginTop: 10 }}>
                              {entry.subentries.map(subentry => {
                                return <li key={subentry.text}>
                                  {subentry.text}
                                </li>
                              })}
                            </ul> : null
                          }
                        </> : null
                      }
                    </li>
                  })}
                </ul>
              </div>
            </React.Fragment>
          })}
        </div> : null
    )
  }
}

export default Changelog;