import {
    convertStructureToFormatV2, createStructureToFormatRequestV2,
    fetchOcidsForInchiKeys, fetchOcidsForInchis, fetchOcidsForSmiles
} from "../../../../api/content/ChemistryApi";
import { createConceptDataRequest, fetchConceptsData, fetchDirectMatches } from "../../../../api/content/ConceptApi";
import { isArrayEmpty } from "../../util";
import {
    CHEMISTRY_BULK_IMPORT_TYPE_OCID, CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS, CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS,
    CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, CHEMISTRY_QUERY_TERM_TYPE_INCHI, CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY,
    CHEMISTRY_QUERY_TERM_TYPE_OCID, CHEMISTRY_QUERY_TERM_TYPE_SMILES, STRUCTURE_FORMAT_SMILES
} from "./chemistrySearchConstants";
import { isChemClassOcid, isChemCompoundOcid } from "./compoundClassGroup";


export const extractChemClassOcidsFromAttributeSearch = (queryTerms) => {
    //console.log('queryTerms: ', queryTerms);
    let ocids = [];

    if (queryTerms) {
        for (let queryTerm of queryTerms) {
            const qtType = queryTerm.type;
            const qSubType = queryTerm.subType;

            if (qSubType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS ||
                (qtType === CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS && queryTerm.bulkIDType === CHEMISTRY_BULK_IMPORT_TYPE_OCID)) {

                let qtValue = queryTerm.queryValues; // is array!
                let type = qtType;
                let value = qtValue;
                if (qtType === CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS) {
                    type = queryTerm.bulkIDType;
                    value = queryTerm.bulkIDs;
                }

                // --- filter OCIDs: only 15xxx --- //
                switch (type) {

                    case CHEMISTRY_QUERY_TERM_TYPE_OCID: {
                        if (value) {
                            for (let ocid of value) {
                                if (isChemClassOcid(ocid)) {
                                    ocids.push(ocid);
                                }
                            }
                        }
                        break;
                    }

                    case CHEMISTRY_QUERY_TERM_TYPE_CONCEPT: {
                        if (queryTerm.ocids) {
                            for (let ocid of queryTerm.ocids) {
                                if (isChemClassOcid(ocid)) {
                                    ocids.push(ocid);
                                }
                            }
                        }
                        break;
                    }

                    default: // do nothing
                }
            }
        }
    }

    return ocids;
}

export const convertAttributeSearchToOcids = async (queryTerms) => {
    //console.log('queryTerms: ', queryTerms);
    let ocids = [];

    if (queryTerms) {
        for (let queryTerm of queryTerms) {
            const qtType = queryTerm.type;

            if (qtType) {

                let qtValue = queryTerm.queryValues; // is array!
                let type = qtType;
                let value = qtValue;
                if (qtType === CHEMISTRY_QUERY_TERM_TYPE_BULK_IDS) {
                    type = queryTerm.bulkIDType;
                    value = queryTerm.bulkIDs;
                }

                if (value) {
                    switch (type) {
                        case CHEMISTRY_QUERY_TERM_TYPE_OCID: {
                            // 125000006743,190000005392,100000000000,190000005386 
                            const request = createConceptDataRequest(value, true, true, false, false, 0, 0, false);
                            const response = await fetchConceptsData(request, false, false, false, false); // APP_PROPERTIES.CHEMISTRY.INCLUDE_MCULE_LINKS
                            const concepts = response?.payload;
                            // --- filter OCIDs: only from chemistry domain with existing SMILES --- //
                            if (concepts) {
                                concepts.filter(concept => {
                                    return (concept.ocid > 0 && concept.extendedData && !isArrayEmpty(concept.extendedData.smiles)); //concept.domain === 'chem' && 
                                }).forEach(concept => {
                                    ocids.push(concept.ocid);
                                });
                                //console.log('ocids2: ', ocids);
                            }
                            break;
                        }
                        // TODO _NAME????
                        case CHEMISTRY_QUERY_TERM_TYPE_CONCEPT: {
                            if (value.length > 0 && value[0].trim() !== '') {
                                const val = value[0].trim();
                                const response = await fetchDirectMatches(val, ['chem'], null, false); // ???
                                const concepts = response?.payload;
                                //console.log('concepts: ', concepts);
                                // --- filter OCIDs: only 19xxx --- //
                                for (const domConcepts of Object.values(concepts)) {
                                    for (const concept of Object.values(domConcepts)) {
                                        if (concept?.ocid) {
                                            if (isChemCompoundOcid(concept.ocid)) {
                                                ocids.push(concept.ocid);
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        }

                        case CHEMISTRY_QUERY_TERM_TYPE_SMILES: {
                            const response = await fetchOcidsForSmiles(value);
                            const result = response?.payload;
                            //console.log('result: ', result);
                            if (result && Object.values(result)) {
                                ocids.push(...Object.values(result).filter(ocid => ocid > 0));
                            }
                            break;
                        }

                        case CHEMISTRY_QUERY_TERM_TYPE_INCHI: {
                            for (var index = 0; index < value.length; index++) {
                                const val = value[index];
                                value[index] = val.startsWith('InChI=') ? val : `InChI=${val}`;
                            }
                            const response = await fetchOcidsForInchis(value);
                            const result = response?.payload;
                            //console.log('result: ', result);
                            if (result && Object.values(result)) {
                                ocids.push(...Object.values(result).filter(ocid => ocid > 0));
                            }
                            break;
                        }

                        case CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY: {
                            const response = await fetchOcidsForInchiKeys(value);
                            const result = response?.payload;
                            //console.log('result: ', result);
                            if (result && Object.values(result)) {
                                ocids.push(...Object.values(result).filter(ocid => ocid > 0));
                            }
                            break;
                        }

                        default: // do nothing
                    }
                }
            }
        }
    }

    return ocids;
}

export const convertAttributeSearchToStructures = async (queryTerms, format) => {
    // --- should always be only one query term --- //
    const type = queryTerms[0].type;
    let value = queryTerms[0].queryValues;
    let ocids = [];
    let savedOCIDs = queryTerms[0].ocids;

    if (type && value) {

        let smiles = null;

        switch (type) {

            case CHEMISTRY_QUERY_TERM_TYPE_OCID: {
                // 125000006743,190000005392,100000000000,190000005386 
                const request = createConceptDataRequest(value, true, true, false, false, 0, 0, false);
                const response = await fetchConceptsData(request, false, false, false, false); // APP_PROPERTIES.CHEMISTRY.INCLUDE_MCULE_LINKS
                const concepts = response?.payload;
                // --- filter OCIDs: only from chemistry domain with existing SMILES --- //
                let filteredOcids = [];
                if (concepts) {
                    filteredOcids = concepts.filter(concept => {
                        return (concept.ocid > 0 && concept.extendedData && !isArrayEmpty(concept.extendedData.smiles));
                    }).map(concept => { return concept.ocid });
                }
                smiles = await convertOcidsToSmiles(filteredOcids);
                break;
            }

            case CHEMISTRY_QUERY_TERM_TYPE_CONCEPT: {
                // --- if there is no OCID -> try to get them from BE --- //
                if (isArrayEmpty(queryTerms[0].ocids)) {
                    if (value.length > 0 && value[0].trim() !== '') {
                        const val = value[0].trim();
                        const response = await fetchDirectMatches(val, ['chem'], null, false); // ???
                        const concepts = response?.payload;
                        // --- filter OCIDs: only 19xxx --- //
                        for (const domConcepts of Object.values(concepts)) {
                            for (const concept of Object.values(domConcepts)) {
                                if (concept?.ocid) {
                                    if (isChemCompoundOcid(concept.ocid)) {
                                        ocids.push(concept.ocid);
                                    }
                                }
                            }
                        }
                        //console.log(ocids)
                        smiles = await convertOcidsToSmiles(ocids);
                    }
                }
                // --- otherwise use the ones that are defined --- //
                else {
                    smiles = await convertOcidsToSmiles(savedOCIDs);
                }
                break;
            }

            case CHEMISTRY_QUERY_TERM_TYPE_SMILES: {
                smiles = value;
                break;
            }

            case CHEMISTRY_QUERY_TERM_TYPE_INCHI: {
                for (var index = 0; index < value.length; index++) {
                    const val = value[index];
                    value[index] = val.startsWith('InChI=') ? val : `InChI=${val}`;
                }
                const response = await fetchOcidsForInchis(value);
                const result = response?.payload;
                if (result && Object.values(result)) {
                    ocids.push(...Object.values(result).filter(ocid => ocid > 0));
                }
                smiles = await convertOcidsToSmiles(ocids);
                break;
            }

            case CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY: {
                const response = await fetchOcidsForInchiKeys(value);
                const result = response?.payload;
                if (result && Object.values(result)) {
                    ocids.push(...Object.values(result).filter(ocid => ocid > 0));
                }
                smiles = await convertOcidsToSmiles(ocids);
                break;
            }

            default: // do nothing
        }

        if (smiles) {
            return await convertSmilesToOtherFormat(smiles, format);
        }
    }
    return null;
}

export const convertOcidsToSmiles = async (ocids) => {
    let smiles = [];
    if (!isArrayEmpty(ocids)) {
        const request = createConceptDataRequest(ocids, true, true);
        const response = await fetchConceptsData(request, false, false, false);
        const concepts = response?.payload;
        concepts.forEach(concept => {
            if (!isArrayEmpty(concept?.extendedData?.smiles)) {
                smiles.push(concept.extendedData.smiles[0]);
            }
        });
    }
    return smiles;
}

export const convertSmilesToOtherFormat = async (smiles, format) => {
    let structure;
    if (!isArrayEmpty(smiles)) {
        const request = createStructureToFormatRequestV2(smiles[0], STRUCTURE_FORMAT_SMILES, format);
        let response = await convertStructureToFormatV2(request);
        structure = response?.payload;
    }
    return structure;
}