/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
//import { Captcha } from 'primereact/captcha';
import ReCAPTCHA from 'react-google-recaptcha'
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import axios from 'axios'
import { APP_PROPERTIES } from '../../properties/index';
import './Login.css';
import { getCurrentYear } from '../webapi/util';
import SeparatorPoint from '../webapi/general/SeparatorPoint';


class ForgotPassword extends Component {

	state = {
		email: '',
		captchaResp: '',
		showSuccess: false
	}

	//reCAPTCHA siteKey is dependent from domain!!!
	//for oc122: 6LfLatUZAAAAACW1aOTXwenXo256EGEvaf2JAoIZ, MW webapp-sciwalker on oc91: 6LfLatZUAAAAAJQui9R8fKhxujewCQMwi2y3f5QP
	//for ng.sciwalker.com: 6LePLN0ZAAAAAGUK95sMiUC2AeYCrbqR-EzmvOIN, MW https://ng.sciwalker.com:8443/webapp-sciwalker: 6LePLN0ZAAAAAMLo7SI0N2pmS0muDj-Mt-AL4TsM

	UNSAFE_componentWillMount() {
		this.setState({
			captchaResp: ''
		})
	}

	componentDidMount() {
		this.setState({
			captchaResp: ''
		}, () => {
			//this.recaptcha.reset()
		})
	}

	handleForgotPassword = (e) => {
		e.preventDefault()
		//console.log("forgot")
		//console.log(this.state.email)
		//console.log(this.state.captchaResp)
		var self = this;
		axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/openapi/users/password-reset`, {
			token: self.state.captchaResp,
			email: self.state.email
		}, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } })
			.then(function (response) {
				//console.log(response)
				self.setState({
					showSuccess: true
				})
				//self.recaptcha.reset()
				self.showForgotPWSuccessGrowl()
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					self.recaptcha.reset()
					self.showForgotPWFailGrowl(error.response.data)
				} else if (error.response.status === 401) {
					self.props.history.push('/')
				} else if (error.response.status === 404) {
					self.recaptcha.reset()
					//self.showCreateRepositoryFailGrowl(error.response.data)
					console.log("not found")
				}
			})
	}

	showForgotPWSuccessGrowl = (e) => {
		let msg = { severity: 'success', summary: 'Success!', detail: `You will receive an email containing a link to reset your password.` };
		this.growl.show(msg)
	}

	showForgotPWFailGrowl = (e) => {
		let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
		this.growl.show(msg)
	}

	showResponse = (response) => {
		//console.log(response)
		this.setState({
			captchaResp: response
		})
	}

	onBackClick = (event) => {
		event.preventDefault();
		//console.log(APP_PROPERTIES)
		window.location = APP_PROPERTIES.FRONTEND_URL
	}

	render() {

		//console.log(APP_PROPERTIES)

		return (
			<div className="login-body" style={{
				background: APP_PROPERTIES.APP_ID !== 'dimensions' ?
					`url('${APP_PROPERTIES.LOGIN_BG_IMAGE}') no-repeat scroll center center / cover` : 'white'
				//`url('${APP_PROPERTIES.LOGIN_BG_IMAGE}') no-repeat scroll center center / cover`
			}}>
				<Toast ref={(el) => { this.growl = el }} />
				<div className="login-panel" style={{ border: '1px solid #d1d1d1', top: '40%' }}>
					{APP_PROPERTIES.LOGIN_LOGO_APP ?
						<div className="login-panel-header" style={{ marginTop: 66, marginLeft: 'auto', marginRight: 'auto', background: 'white', padding: 0 }}>
							<img src={APP_PROPERTIES.LOGIN_LOGO_APP} alt={`${APP_PROPERTIES.APP_NAME} logo`} style={APP_PROPERTIES.LOGIN_LOGO_APP_STYLE} />
						</div>
						: null}
					{this.state.showSuccess === false ?
						<React.Fragment>
							<form onSubmit={this.handleLogin}>
								<div className="login-panel-content">
									<div className="grid">
										<div className="col-12" style={{ textAlign: 'center' }}>
											{/*<h1>Sign in to {APP_PROPERTIES.APP_NAME}</h1>*/}
											<label style={{ fontSize: 30, color: 'black' }}>Forgot password</label>
										</div>
										<div className="col-12" style={{ paddingBottom: 0, paddingLeft: 35, paddingRight: 25 }}>
											<span //className="p-float-label"
											>
												<InputText id="username-input"
													placeholder='Enter email address'
													className='form-login-width'
													type="text"
													autoComplete="off"
													style={{ backgroundColor: 'white' }}
													value={this.state.email}
													onChange={(event) => {
														event.preventDefault()
														this.setState({ email: event.target.value })
													}}
													size="30" />
												{/*<label htmlFor="username-input">Enter email address</label>*/}
											</span>
										</div>
										<div className="col-12" style={{ paddingBottom: 0, paddingLeft: 35 }}>
											<div style={{}}>
												<ReCAPTCHA ref={ref => this.recaptcha = ref} sitekey={`${APP_PROPERTIES.RECAPTCHA_KEY}`}
													onChange={this.showResponse}></ReCAPTCHA>
											</div>

										</div>
										<div className='col-12' style={{ marginTop: 0 }}>
											<Button label="Reset"
												className="primaryButtonLogin p-button-sm"
												title="Reset"
												icon="pi pi-arrow-right"
												iconPos='right'
												style={{
													//color: '#757575', background: '#d8d8d8', border: '1px solid #d1d1d1',
													float: "right", marginRight: 13, fontSize: 16
												}}
												disabled={this.state.email !== '' && this.state.captchaResp !== '' ? false : true}
												onClick={(e) => this.handleForgotPassword(e)} >
											</Button>
											<Button className='p-button-text'
												style={{
													//color: '#757575', background: '#d8d8d8', border: '1px solid #d1d1d1',
													float: "right", fontSize: 16, color: 'black'
												}}
												label="Back"
												onClick={(e) => this.onBackClick(e)}
												title="Back">
											</Button>
										</div>
										{//APP_PROPERTIES.APP_ID === 'dimensions' ?
											<div className='col-12' style={{ paddingTop: 110 }}>
												<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
												<div className='col-12'>
													<a style={{ color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
													{APP_PROPERTIES.INCLUDE_ONETRUST &&
														<React.Fragment>
															<SeparatorPoint color='#757575' />
															{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
															<a className="optanon-show-settings" style={{ color: '#757575' }}>Cookie settings</a>
														</React.Fragment>
													}
													<SeparatorPoint color='#757575' />
													<a style={{ color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
												</div>
											</div>
											//:
											//null
										}

									</div>
								</div>
							</form>
						</React.Fragment>
						:
						<form onSubmit={this.handleLogin}>
							<div className="login-panel-content" style={{ paddingTop: 20, paddingBottom: 20 }}>
								<div className="grid">
									<div className="col-12" style={{ textAlign: 'center' }}>
										<label style={{ fontSize: 30, color: 'black' }}>Success</label>
									</div>
									<div className="col-12" style={{ textAlign: 'center' }}>
										<span className="p-float-label">
											<label style={{ color: 'black' }}>You will receive an email containing a link to reset your password.</label>
										</span>
										<Button className='p-button-text'
											style={{
												//color: '#757575', background: '#d8d8d8', border: '1px solid #d1d1d1',
												fontSize: 16, color: 'black', marginTop: 20
											}}
											label="Back to login"
											onClick={(e) => this.onBackClick(e)}
											title="Back to login">
										</Button>
									</div>
									{//APP_PROPERTIES.APP_ID === 'dimensions' ?
										<div className='col-12' style={{ paddingTop: 110 }}>
											<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
											<div className='col-12'>
												<a style={{ color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
												{APP_PROPERTIES.INCLUDE_ONETRUST &&
													<React.Fragment>
														<SeparatorPoint color='#757575' />
														{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
														<a className="optanon-show-settings" style={{ color: '#757575' }}>Cookie settings</a>
													</React.Fragment>
												}
												<SeparatorPoint color='#757575' />
												<a style={{ color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
											</div>
										</div>
										//:
										//null
									}
								</div>
							</div>
						</form>
					}
				</div>
			</div>
		)
	}
}

export default ForgotPassword