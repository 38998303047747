import { isArrayEmpty } from "../../util";
import { QUERY_TYPE_QUICK_SEARCH, QUERY_TYPE_QUICK_SEARCH_2, VERSION_2_1 } from "../../../../properties";
import {
    createFilterTermForAutocompleteTermloc, createFilterTermForBulkImport, createFilterTermForDateRanges,
    createFilterTermForFreeInputTermLocation, createFilterTermForIPCDomain, createFilterTermForMetadata,
    createFilterTermForMetadataMultipleStructuredValues, createFilterTermForTermLocation
} from "./queryTerms";
import {
    QUERY_TERM_TYPE_CONCEPT, QUERY_TERM_TYPE_METADATA, SEARCH_FILTER_ID_ASSIGNEE, SEARCH_FILTER_ID_AUTHOR, SEARCH_FILTER_ID_BULK_IMPORT,
    SEARCH_FILTER_ID_CLIN_PHASE, SEARCH_FILTER_ID_CLIN_TRIAL_ID, SEARCH_FILTER_ID_CONCEPT_DISTANCE,
    SEARCH_FILTER_ID_CONDITION, SEARCH_FILTER_ID_CPC_CLASS, SEARCH_FILTER_ID_DOI, SEARCH_FILTER_ID_DRUG,
    SEARCH_FILTER_ID_END_DATE, SEARCH_FILTER_ID_FUNDER, SEARCH_FILTER_ID_FUNDER_COUNTRY, SEARCH_FILTER_ID_GRANTEE,
    SEARCH_FILTER_ID_GRANT_NO, SEARCH_FILTER_ID_INSDATE, SEARCH_FILTER_ID_INVENTOR, SEARCH_FILTER_ID_IPC_CLASS,
    SEARCH_FILTER_ID_IPC_DOMAIN, SEARCH_FILTER_ID_ISSUE, SEARCH_FILTER_ID_JOURNAL, SEARCH_FILTER_ID_JOURNAL_SOURCE,
    SEARCH_FILTER_ID_OA_STATUS, SEARCH_FILTER_ID_PATENT_NO, SEARCH_FILTER_ID_PATENT_OFFICE, SEARCH_FILTER_ID_PMCID,
    SEARCH_FILTER_ID_PMID, SEARCH_FILTER_ID_PRIO_DATE, SEARCH_FILTER_ID_PUBDATE, SEARCH_FILTER_ID_PUBLISHER,
    SEARCH_FILTER_ID_PUB_TYPE, SEARCH_FILTER_ID_RESEARCH_ORG, SEARCH_FILTER_ID_RESEARCH_ORG_COUNTRY,
    SEARCH_FILTER_ID_SEARCH_MODE, SEARCH_FILTER_ID_SPONSOR, SEARCH_FILTER_ID_START_DATE, SEARCH_FILTER_ID_STATUS,
    SEARCH_FILTER_ID_STUDY_TYPE, SEARCH_FILTER_ID_TERMLOC, SEARCH_FILTER_ID_TITLE_SEARCH, SEARCH_FILTER_ID_VOLUME
} from "../../general/docsearch/searchConstants";


export const convertQuickSearchToLatestFormat = (formContent, savedSarch, filterDefinitions) => {

    //console.log('ORIGINAL: ', cloneDeep(formContent));

    let formContentConverted = formContent;
    if (formContentConverted) {
        if (savedSarch.type === QUERY_TYPE_QUICK_SEARCH.id) {
            if (formContentConverted.queryOCIDS) {
                if (formContentConverted.queryOCIDS[0] && Array.isArray(formContentConverted.queryOCIDS[0])) {
                    formContentConverted = convertQuickSearchV3ToQuickSearchV4Format(formContentConverted);
                    //console.log('VERSION 1.3');
                }
                else {
                    //console.log('VERSION 1.1');
                    return null;
                }
            }
            formContentConverted = convertQuickSearchV4ToQuickSearch2V1Format(formContentConverted, filterDefinitions);
            savedSarch.type = QUERY_TYPE_QUICK_SEARCH_2.id;
            //console.log('VERSION 1.4');
        }
        else {
            //console.log('VERSION 2.1');
            for (var qt of formContentConverted.queryTerms) {
                if ((qt.type === QUERY_TERM_TYPE_CONCEPT || qt.type === QUERY_TERM_TYPE_METADATA) && !qt.queryValues) {
                    return null;
                }
            };
        }
    }
    //console.log('CONVERTED: ', cloneDeep(formContentConverted));
    return formContentConverted;
}

// ----------------------------------------------------------------------- //
// --- quick_search v2 to quick_search_2 v0 ------------------------------ //
// ----------------------------------------------------------------------- //

/**
 * Converts old form content of saved document query to new format 
 * by simply adding query term objects.
 * Old format stores term and domains separate from OCIDs.
 * New format stores complete query term objects. 
 */
export const convertQuickSearchV4ToQuickSearch2V1Format = (formContent, filterDefinitions = {}) => {

    //console.log('OLD formContent: ', formContent);
    //console.log('filterDefinitions: ', filterDefinitions);

    if (formContent) {

        if (!formContent.queryTerms) {
            formContent.queryTerms = [];
        }

        formContent.queryTerms.forEach(qt => {
            qt.type = QUERY_TERM_TYPE_CONCEPT;
            qt.queryValues = qt.term ? [qt.term] : [];
        });

        if (formContent.filters?.generalFilters) {
            // --- date or bulk import filters must be merged into one --- //
            let pubDateFrom = null, pubDateTo = null;
            let insDateFrom = null, insDateTo = null;
            let bulkIDType = null, bulkIDs = null;

            Object.entries(formContent.filters.generalFilters).forEach(([id, val]) => {
                let filterTerm = null;

                switch (id) {
                    case 'location': {
                        filterTerm = createFilterTermForTermLocation(filterDefinitions[SEARCH_FILTER_ID_TERMLOC], val);
                        break;
                    }
                    case 'title': {
                        filterTerm = createFilterTermForFreeInputTermLocation(filterDefinitions[SEARCH_FILTER_ID_TITLE_SEARCH], val);
                        break;
                    }
                    case 'mode': {
                        filterTerm = createFilterTermForSearchMode(filterDefinitions[SEARCH_FILTER_ID_SEARCH_MODE], val);
                        break;
                    }
                    case 'queryDistance': {
                        filterTerm = createFilterTermForConceptDistance(filterDefinitions[SEARCH_FILTER_ID_CONCEPT_DISTANCE], val);
                        break;
                    }
                    case 'author': {
                        // TODD: error: fuzzy search in backend automatically added
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_AUTHOR], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'startDate': {
                        pubDateFrom = val;
                        break;
                    }
                    case 'endDate': {
                        pubDateTo = val;
                        break;
                    }
                    case 'startInsertionDate': {
                        insDateFrom = val;
                        break;
                    }
                    case 'endInsertionDate': {
                        insDateTo = val;
                        break;
                    }
                    case 'bulkIDType': {
                        bulkIDType = val;
                        break;
                    }
                    case 'bulkIDs': {
                        bulkIDs = val;
                        break;
                    }
                    default: // do nothing
                }

                if (filterTerm) {
                    formContent.queryTerms.push(filterTerm);
                }
            });

            const pubDateFilterTerm = createFilterTermForDateRanges(filterDefinitions[SEARCH_FILTER_ID_PUBDATE], pubDateFrom, pubDateTo);
            if (pubDateFilterTerm) {
                formContent.queryTerms.push(pubDateFilterTerm);
            }
            const insDateFilterTerm = createFilterTermForDateRanges(filterDefinitions[SEARCH_FILTER_ID_INSDATE], insDateFrom, insDateTo);
            if (insDateFilterTerm) {
                formContent.queryTerms.push(insDateFilterTerm);
            }
            const bulkImportFilterTerm = createFilterTermForBulkImport(filterDefinitions[SEARCH_FILTER_ID_BULK_IMPORT], bulkIDType, bulkIDs);
            if (bulkImportFilterTerm) {
                formContent.queryTerms.push(bulkImportFilterTerm);
            }
        }

        if (formContent.filters?.additionalFilters) {
            // --- date or bulk import filters must be merged into one --- //
            let prioDateFrom = null, prioDateTo = null;
            let startDateFrom = null, startDateTo = null;
            let endDateFrom = null, endDateTo = null;

            Object.entries(formContent.filters.additionalFilters).forEach(([id, val]) => {
                let filterTerm = null;

                switch (id) {
                    case 'ipcDomain': {
                        filterTerm = createFilterTermForIPCDomain(filterDefinitions[SEARCH_FILTER_ID_IPC_DOMAIN], val);
                        break;
                    }
                    case 'location': {
                        filterTerm = createFilterTermForTermLocation(filterDefinitions[SEARCH_FILTER_ID_TERMLOC], val);
                        break;
                    }
                    case 'diseasesConcepts': {
                        filterTerm = createFilterTermForAutocompleteTermloc(filterDefinitions[SEARCH_FILTER_ID_CONDITION], val);
                        break;
                    }
                    case 'drugsConcepts': {
                        filterTerm = createFilterTermForAutocompleteTermloc(filterDefinitions[SEARCH_FILTER_ID_DRUG], val);
                        break;
                    }
                    case 'assignee': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_ASSIGNEE], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'inventor': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_INVENTOR], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'countryCode': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_PATENT_OFFICE], val, val);
                        break;
                    }
                    case 'ipcClass': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_IPC_CLASS], val, val);
                        break;
                    }
                    case 'cpcClass': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_CPC_CLASS], val, val);
                        break;
                    }
                    case 'patentNo': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_PATENT_NO], val, val);
                        break;
                    }
                    case 'doi': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_DOI], val, val);
                        break;
                    }
                    case 'openAccessStatus': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_OA_STATUS], val, val);
                        break;
                    }
                    case 'pmcID':
                    case 'pmcid': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_PMCID], val, val);
                        break;
                    }
                    case 'pmid': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_PMID], val, val);
                        break;
                    }
                    case 'pubType': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_PUB_TYPE], val, val);
                        break;
                    }
                    case 'publisher': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_PUBLISHER], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'journal': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_JOURNAL], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'volume': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_VOLUME], val, val);
                        break;
                    }
                    case 'issue': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_ISSUE], val, val);
                        break;
                    }
                    case 'sourceTitle': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_JOURNAL_SOURCE], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'ctID': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_CLIN_TRIAL_ID], val, val);
                        break;
                    }
                    case 'sponsor': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_SPONSOR], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'studyTypes': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_STUDY_TYPE], val, val);
                        break;
                    }
                    case 'funder': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_FUNDER], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'funderCountry': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_FUNDER_COUNTRY], val, val);
                        break;
                    }
                    case 'grantNumber': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_GRANT_NO], val, val);
                        break;
                    }
                    // TODO: grantee == investigator???
                    case 'grantee': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_GRANTEE], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'researchOrg': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_RESEARCH_ORG], [{ term: val, isManuallyAdded: true }]);
                        break;
                    }
                    case 'researchOrgCountry': {
                        filterTerm = createFilterTermForMetadata(filterDefinitions[SEARCH_FILTER_ID_RESEARCH_ORG_COUNTRY], val, val); // , null, 2
                        break;
                    }
                    case 'phases': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_CLIN_PHASE], val.map(v => { return { term: v } }));
                        break;
                    }
                    case 'status': {
                        filterTerm = createFilterTermForMetadataMultipleStructuredValues(
                            filterDefinitions[SEARCH_FILTER_ID_STATUS], val.map(v => { return { term: v } }));
                        break;
                    }
                    case 'startPriorityDate': {
                        prioDateFrom = val;
                        break;
                    }
                    case 'endPriorityDate': {
                        prioDateTo = val;
                        break;
                    }
                    case 'grantsStartDateFrom':
                    case 'ctStartDateFrom': {
                        startDateFrom = val;
                        break;
                    }
                    case 'grantsStartDateTo':
                    case 'ctStartDateTo': {
                        startDateTo = val;
                        break;
                    }
                    case 'grantsEndDateFrom':
                    case 'ctEndDateFrom': {
                        endDateFrom = val;
                        break;
                    }
                    case 'grantsEndDateTo':
                    case 'ctEndDateTo': {
                        endDateTo = val;
                        break;
                    }
                    default: // do nothing
                }

                if (filterTerm) {
                    formContent.queryTerms.push(filterTerm);
                }
            });

            const prioDateFilterTerm = createFilterTermForDateRanges(filterDefinitions[SEARCH_FILTER_ID_PRIO_DATE], prioDateFrom, prioDateTo);
            if (prioDateFilterTerm) {
                formContent.queryTerms.push(prioDateFilterTerm);
            }
            const startDateFilterTerm = createFilterTermForDateRanges(filterDefinitions[SEARCH_FILTER_ID_START_DATE], startDateFrom, startDateTo);
            if (startDateFilterTerm) {
                formContent.queryTerms.push(startDateFilterTerm);
            }
            const endDateFilterTerm = createFilterTermForDateRanges(filterDefinitions[SEARCH_FILTER_ID_END_DATE], endDateFrom, endDateTo);
            if (endDateFilterTerm) {
                formContent.queryTerms.push(endDateFilterTerm);
            }
        }

        delete formContent.filters;

        formContent.version = VERSION_2_1;
    }

    return formContent;
}

export const createFilterTermForConceptDistance = (filter, value) => {

    if (filter && value) {
        const key = value.replace(/\^[0-9]*$/, '');
        const booster = value.replace(/^[a-zA-Z]+\^/, '');
        let label = filter.filterValues?.find(fv => fv.filterValue == key &&
            (fv.booster == booster || (!fv.booster && !booster)))?.filterValueLabel;
        label = label ? label : value;

        // TODO: pot error if key or booster or combination not defined
        return createFilterTermForMetadata(filter, key, label, booster);
    }
    return null;
}

export const createFilterTermForSearchMode = (filter, values) => {

    if (filter && !isArrayEmpty(values)) {
        let label = filter.filterValues?.find(fv => fv.filterValue == values[0])?.filterValueLabel;
        label = label ? label : values[0];

        return createFilterTermForMetadata(filter, values, label);
    }
    return null;
}

// ----------------------------------------------------------------------- //
// --- quick_search v1 to quick_search_2 v0 ------------------------------ //
// ----------------------------------------------------------------------- //

/**
 * Converts old form content of saved document query to new format 
 * by simply adding query term objects.
 * Old format stores term and domains separate from OCIDs.
 * New format stores complete query term objects. 
 */
export const convertQuickSearchV3ToQuickSearchV4Format = (formContent) => {

    if (formContent && formContent.queryOCIDS && formContent.queryNames &&
        formContent.queryOCIDS.length === formContent.queryNames.length) {

        formContent.queryTerms = [];
        for (let i = 0; i < formContent.queryNames.length; i++) {
            // -- query term display name --- //
            const qt = formContent.queryNames[i];
            // --- query term domains --- //
            let domains = [];
            if (qt.domains) {
                //domains = typeof qt.domains === 'string' ? [qt.domains] : qt.domains;
                domains = Array.isArray(qt.domains) ? qt.domains : [qt.domains];
            }
            // --- term OCIDs (->collect) OR free text (-> update domains) --- //
            const entries = formContent.queryOCIDS[i];
            let ocids = [];
            for (var entry of entries) {
                //console.log('entry: ', entry);
                if (entry['+ocid']) {
                    ocids.push(entry['+ocid']);
                }
                else if (entry['+tr']) {
                    domains = ['tr'];
                }
                else if (entry['+t']) {
                    domains = ['t'];
                }
            }
            // --- add as new query term --- //
            const term = qt.term?.replace(' (Text exact)', '').replace(' (Text with variants)', '');
            const queryTerm = {
                type: QUERY_TERM_TYPE_CONCEPT,
                term: term,
                queryValues: term ? [term] : [],
                domains: domains,
            };
            if (ocids.length > 0) {
                queryTerm.ocids = ocids;
            }
            formContent.queryTerms.push(queryTerm);
        }

        // --- update filters --- //
        if (formContent.filters) {
            if (formContent.filters.patentFilters && formContent.filters.activeAdditionalFilters === 'patentFilters') {
                formContent.filters.additionalFiltersTab = 'patentFilters';
                formContent.filters.additionalFilters = formContent.filters.patentFilters;
            }
            else if (formContent.filters.journalFilters && formContent.filters.activeAdditionalFilters === 'journalFilters') {
                formContent.filters.additionalFiltersTab = 'journalFilters';
                formContent.filters.additionalFilters = formContent.filters.journalFilters;
            }
            if (formContent.filters.clinicalTrialFilters && formContent.filters.activeAdditionalFilters === 'clinicalTrialFilters') {
                formContent.filters.additionalFiltersTab = 'clinicalTrialFilters';
                formContent.filters.additionalFilters = formContent.filters.clinicalTrialFilters;
            }
            // --- delete old filter objects --- //
            delete formContent.filters.activeAdditionalFilters;
            delete formContent.filters.patentFilters;
            delete formContent.filters.journalFilters;
            delete formContent.filters.clinicalTrialFilters;
        }

        delete formContent.api;
        delete formContent.domains;
        delete formContent.query;
        delete formContent.queryComplete;
        delete formContent.queryNames;
        delete formContent.queryOCIDS;
        delete formContent.repository;
        delete formContent.sortBy;
    }

    return formContent;
}