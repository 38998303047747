//import _ from 'lodash';


/**
 * Extracts detals about a sequence from a sequence object and adjusts the sequence itself.
 * @param {*} singleSequenceDetails 
 * @returns 
 */
export const extractSequenceData = (singleSequenceDetails) => {
    let singleSequenceOCID = singleSequenceDetails?.ocid;
    let singleSequenceLength = singleSequenceDetails?.seqLength ? singleSequenceDetails.seqLength : 'No information available';
    let singleSequenceWrapped;

    if (singleSequenceDetails?.sequence) {
        let originalString = JSON.parse(JSON.stringify(singleSequenceDetails.sequence));
        singleSequenceWrapped = originalString.match(/.{1,10}/g);
        singleSequenceWrapped = singleSequenceWrapped.join(' ').replace(/(.{66})/g, '$1\n');
    } else {
        singleSequenceWrapped = 'No information available';
    }

    return { singleSequenceOCID, singleSequenceLength, singleSequenceWrapped };
}