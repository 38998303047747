import React, { Component, createRef } from 'react';
import { Button } from 'primereact/button';
import SyntaxEditor from './SyntaxEditor';
import * as OntologyClassUtil from '../../util/OntologyClassUtil';
import { cloneDeep } from '../../../util';
import { checkResultAndGetPayload } from '../../../../../api';
import { translatePhraseTokensToSyntaxPatternString } from '../../../../../api/content/FrameEditorApi';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';


class EditMacroTagBox extends Component {

    constructor(props) {
        super(props);

        // ???
        this.state = {
            macroName: '',
            macroSyntax: null
        }

        this.growl = createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        //console.log('nextProps: ', nextProps);

        if (this.props.tag !== nextProps.tag) {
            //console.log('this.props.tag !== nextProps.tag');
            if (nextProps.tag && nextProps.tag.value && nextProps.tag.phraseTokens) {
                //console.log('nextProps.tag && nextProps.tag.value: ', nextProps.tag.value);

                const { name, syntax } = OntologyClassUtil.extractNameAndSyntaxFromMacroTagValue(nextProps.tag.value);
                //console.log('id: ', id);
                //console.log('syntax: ', syntax);
                //console.log('phraseTokens: ', nextProps.tag.phraseTokens);

                this.syntaxEditor.setPhraseTokens(cloneDeep(nextProps.tag.phraseTokens));

                this.setState({
                    macroNameOrig: name,
                    macroName: name,
                    macroSyntax: syntax
                });
            }
            else {
                this.syntaxEditor.setPhraseTokens([]);

                this.setState({
                    macroNameOrig: '',
                    macroName: ''
                });
            }
        }
    }



    onEditTagSubmit = async () => {

        const newTag = OntologyClassUtil.createMacroTag();
        const phraseTokens = this.syntaxEditor.getPhraseTokens();
        //console.log('phraseTokens: ', phraseTokens);

        if (!this.state.macroName || this.state.macroName === '') {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: 'Empty macro name not allowed.' });
        }
        else if (!this.state.macroName.match(/^[A-Za-z0-9_]+$/)) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Forbidden characters in name',
                detail: 'Name contains characters which are not allowed. Allowed are: A-Z, a-z, 0-9 and _ , e.g. MACRO_NAME'
            });
        }
        else if (!phraseTokens || phraseTokens.length === 0) {
            this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error occurred', detail: 'Empty syntax not allowed.' });
        }
        else {
            // --- check if name already exists --- //

            //console.log('macroSelectItems: ', this.props.macroSelectItems);
            //console.log('this.state.macroName: ', this.state.macroName);

            if (this.state.macroNameOrig !== this.state.macroName && this.props.macroSelectItems) {
                for (const macroItem of this.props.macroSelectItems) {
                    if (macroItem.label === this.state.macroName) {
                        this.growl.current.show({ sticky: true, closable: true, severity: 'error', summary: 'Error replacing entry.', detail: 'Name already exists.' });
                        return;
                    }
                }
            }


            const response = await translatePhraseTokensToSyntaxPatternString(phraseTokens, this.props.history);
            const parsedSyntaxObj = checkResultAndGetPayload(response, this.growl);

            if (parsedSyntaxObj) {
                const syntaxStrg = (parsedSyntaxObj && parsedSyntaxObj.parsedSyntax) ? parsedSyntaxObj.parsedSyntax : '';

                //console.log('this.state.macroName: ', this.state.macroName);

                newTag.value = `${this.state.macroName} "${syntaxStrg}"`;
                // --- additional data --- //
                newTag.macroName = this.state.macroName;
                newTag.macroSyntax = syntaxStrg;
                newTag.phraseTokens = phraseTokens;

                this.props.onEditTagSubmit(newTag);
            }
        }
    }


    render() {

        const { contentBoxHeight } = this.props;

        return (

            <>
                <Toast ref={this.growl} appendTo={document.body} />

                <div className="task-box task-box-dark-blue" style={{ padding: 0, background: 'white' }}> {/*20 #676767*/}
                    <div className="task-box-header">
                        Edit macro entry
                </div>
                    <div className="task-box-content"
                        style={{
                            padding: '10px', height: contentBoxHeight, overflow: 'auto'
                        }}>
                        <div className="grid" style={{ padding: '10px' }}> {/*  style={{ minWidth: '60wv', minHeight: '70vh',  maxHeight: '90vh',  overflow: 'auto' }} */}

                            <div className="col-12" style={{ marginBottom: 5, paddingBottom: 0, paddingTop: 10 }}>
                                <div className="grid">
                                    <div className="col-fixed" style={{ width: 100 }}>
                                        Macro name:
                                </div>
                                    <div className="col">
                                        <InputText
                                            value={this.state.macroName}
                                            onChange={(e) => this.setState({ macroName: e.target.value })}
                                            style={{ width: '100%' }}
                                            keyfilter={/^[A-Za-z0-9_]+$/} />
                                        {/*<i class="material-icons" style={{ color: '#009688', fontSize: 20 }}>
                                        info
                                    </i>*/}
                                    </div>
                                    <div className="col-12">
                                    </div>
                                    <div className="col-fixed" style={{ width: 100 }}>
                                        Syntax:
                                </div>
                                    <div className="col">
                                        <SyntaxEditor
                                            ref={(ref) => { this.syntaxEditor = ref; }}

                                            type={OntologyClassUtil.TAG_TYPE_MACRO}

                                            domains={this.props.domains}
                                            roles={[]} //this.props.roles

                                            roleSelectItems={this.props.roleSelectItems}
                                            synsetSelectItems={this.props.synsetSelectItems}
                                            macroSelectItems={this.props.macroSelectItems}
                                            frameSelectItems={this.props.frameSelectItems}

                                            contentBoxHeight={this.props.editorHeight}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12" style={{ textAlign: 'right' }}>
                                <Button label="Cancel"
                                    className="p-button-secondary p-button-sm"
                                    onClick={this.props.onEditTagCancel}>
                                </Button>
                                <Button label="Confirm"
                                    className="primaryButton p-button-sm"
                                    //disabled={!this.state.macroName || this.state.macroName === ''}
                                    onClick={(e) => this.onEditTagSubmit()}>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EditMacroTagBox;