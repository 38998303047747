import $ from 'jquery';
import { scrollToElementViaScrollToButton } from "../docview/util/htmlHandling";

const DocumentInformation = ({
    doc,
    sentences,
    loader,
    selectedSentence,
    onSetSelectedSentence,
    documentIsPDF
}) => {
    //console.log(sentences[0])
    const onSentenceClick = (sent) => {
        onSetSelectedSentence(sent.sentId)
        if (sentences && sentences.length > 0 && documentIsPDF) {
            scrollToElementViaScrollToButton('#scrollToBtnSent', '#' + sent.sentId)
            document.location.hash = `highlight-${sent.sentId}`;
        }
    }

    const createElementFromHTML = (htmlString, id, section) => {
        let newSpan = document.createElement('span')
        newSpan.style.lineHeight = 1.5
        newSpan.className = "sent"
        newSpan.id = id
        newSpan.setAttribute("sect", section)
        newSpan.setAttribute("onclick", `onSetSelectedSentence(event, '${id}')`)
        newSpan.innerHTML = htmlString.trim()
        return newSpan
    }

    let sentenceArray = []

    if (sentences && sentences[0] && !documentIsPDF && sentences[0]) {
        for (const elem of sentences.find(".sent")) {
            //console.log(elem)
            sentenceArray = [...sentenceArray, { content: $(elem).prop('outerHTML'), sentId: $(elem).attr("id"), section: $(elem).attr("sect") }]
        }
    } else if (sentences && sentences.length > 0 && documentIsPDF) {
        sentences.forEach(sent => {
            if (!sent.hasOwnProperty("colors"))
                sentenceArray = [...sentenceArray, { content: createElementFromHTML(sent.text, sent.annId, sent.section), sentId: sent.annId, section: sent.section }]
        })
    }

    if (sentences && sentenceArray && sentenceArray.length > 0 && documentIsPDF) {
        sentenceArray.forEach((sent) => {
            for (const el of ($(sent.content).find("span[id^=annId]"))) {
                if (sentences.find(entry => entry.hasOwnProperty("colors")).colors.filter(col => col.id === el.id)[0] !== undefined) {
                    let color = sentences.find(entry => entry.hasOwnProperty("colors")).colors.filter(col => col.id === el.id)[0].color
                    const aEl = $(el);
                    aEl.attr('style', `background-color: #${color}`)
                }
            }
        })
    }

    //console.log(sentences)

    var htmlObj = $('<div>' + '</div>');
    htmlObj.append('<span id="scrollToBtnSent" onclick="scrollToHtmlElement(\'#scrollToBtnSent\', \'#' + "sentenceScroll" + '\')" style="display:none;"></span>');

    return (
        <div key={doc.metadata?.ocDocId}>
            {!loader ?
                sentences && sentences[0] && sentenceArray.length > 0 ?
                    <div id="sentencesDiv" className='sentenceDiv'>
                        {sentenceArray.map((sent, index) => {
                            return <div id={sent.sentId} style={{
                                marginBottom: 10, marginRight: 10, textAlign: 'justify',
                                paddingLeft: 5, paddingRight: 5,
                                backgroundColor: sent.sentId === selectedSentence ? '#fcf492' : 'white'
                            }}>
                                <span style={{ marginRight: 10, color: '#757575' }}>{index + 1}</span>
                                {sentences && documentIsPDF ?
                                    <span style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => { onSentenceClick(sent) }} dangerouslySetInnerHTML={{ __html: sent.content.outerHTML }}></span>
                                    :
                                    <span style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => { onSentenceClick(sent) }} dangerouslySetInnerHTML={{ __html: sent.content }}></span>
                                }
                                {sent.section ?
                                    <span style={{fontWeight: 'bold'}}>[{sent.section ? sent.section.charAt(0).toUpperCase() + sent.section.slice(1) : ''}]</span>
                                    : null
                                }
                            </div>
                        })
                        }
                        <span dangerouslySetInnerHTML={{ __html: htmlObj.html() }}></span>
                    </div>
                    : null
                : <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <div style={{ color: '#607D8B' }}>Searching sentences...</div>
                </div>}
        </div >
    );
}
export default DocumentInformation