import "./ConceptDetailsDialog.css";
import { Dialog } from "primereact/dialog";
import ConceptDetails from "./ConceptDetails";


const ConceptDetailsDialog = ({
  visible,
  includeQuickSearchShortcut,
  includeChemSearchShortcut,
  includeCoocSearchShortcut,
  ocid,
  onHide,
  onConceptClick,
}) => {

  return <Dialog
    header="Concept details"
    visible={visible}
    onHide={onHide}
    focusOnShow={false}
    dismissableMask={true}
    modal={true}
    blockScroll={true}
    className="styledDialog concept-details-dialog"
  >
    {ocid ?
      <ConceptDetails
        ocid={ocid}
        includeQuickSearchShortcut={includeQuickSearchShortcut}
        includeChemSearchShortcut={includeChemSearchShortcut}
        includeCoocSearchShortcut={includeCoocSearchShortcut}
        onConceptClick={onConceptClick}
      />
      : <div>No concept OCID given.</div>
    }
  </Dialog>
}

export default ConceptDetailsDialog;