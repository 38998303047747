export const valueTypes = [{
    label: 'Absolute',
    value: 'absolute',
    index: 0
},
    {
        label: 'Relative (%)',
        value: 'relative',
        index: 1
    }
];

export const chartTypes = [{
    label: 'Linear',
    value: 'linear',
    index: 0
}, {
    label: 'Logarithmic',
    value: 'logarithmic',
    index: 1
}]

export const periods = [{
    label: 'Yearly',
    value: 'yearly',
    index: 0
},
    {
        label: 'Monthly',
        value: 'monthly',
        index: 1
    },
    {
        label: 'Weekly',
        value: 'weekly',
        index: 2
    },
    // {
    //     label: 'Daily',
    //     value: 'daily',
    //     index: 3
    // },
];

export const timeRanges = [{label: 'Last 20 years', value: '20y', index: 0},
    {label: 'Last 10 years', value: '10y', index: 1},
    {label: 'Last 5 years', value: '5y', index: 2},
    {label: 'Last 3 years', value: '3y', index: 3},
    {label: 'Last 1 year', value: '1y', index: 4},
    {label: 'Last quarter', value: 'lastQuarter', index: 5},
    {label: 'Last month', value: 'lastMonth', index: 6}]
