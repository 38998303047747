import { merge } from 'lodash';

import { APP_PROPERTIES as APP_PROPERTIES_BON } from './applications/bon';
import { APP_PROPERTIES as APP_PROPERTIES_CVG } from './applications/cvg';
import { APP_PROPERTIES as APP_PROPERTIES_DEFAULT } from './applications/default';
import { APP_PROPERTIES as APP_PROPERTIES_DIMENSIONS } from './applications/dimensions';
import { APP_PROPERTIES as APP_PROPERTIES_DOW_DOCKER } from './applications/dow_docker';
import { APP_PROPERTIES as APP_PROPERTIES_IMART } from './applications/imart';
import { APP_PROPERTIES as APP_PROPERTIES_IQNOW } from './applications/iqnow';
import { APP_PROPERTIES as APP_PROPERTIES_SCIWALKER } from './applications/sciwalker';
import { APP_PROPERTIES as APP_PROPERTIES_SCIWALKER_ALPHA } from './applications/sciwalker_alpha';
import { APP_PROPERTIES as APP_PROPERTIES_SCIWALKER_STUDIO } from './applications/sciwalker_studio';
import {
  APP_ID_BON,
  APP_ID_CVG,
  APP_ID_DIMENSIONS,
  APP_ID_DOW,
  APP_ID_IMART,
  APP_ID_IQNOW,
  APP_ID_SCIWALKER,
  APP_ID_SCIWALKER_ALPHA,
  APP_ID_SCIWALKER_STUDIO,
} from './constants';
import { validateApplicationProperties } from './index.validation';

let properties = {};
let appSpecificProps = {};

/**
 * Export properties for active application based on
 * APP_ID defined in .env* file or env-config file.
 */
const appID =
  window.__env__ && window.__env__.hasOwnProperty('REACT_APP_ID')
    ? window.__env__.REACT_APP_ID
    : process.env.REACT_APP_ID;

switch (appID) {
  // --- DSM - BoN --- //
  case APP_ID_BON:
    appSpecificProps = APP_PROPERTIES_BON;
    break;
  // --- Boehringer - Customer Value Group --- //
  case APP_ID_CVG:
    appSpecificProps = APP_PROPERTIES_CVG;
    break;
  // --- Dimensions --- //
  case APP_ID_DIMENSIONS:
    appSpecificProps = APP_PROPERTIES_DIMENSIONS;
    break;
  // --- DOW --- //
  case APP_ID_DOW:
    appSpecificProps = APP_PROPERTIES_DOW_DOCKER;
    break;
  // --- Boehringer - iMart --- //
  case APP_ID_IMART:
    appSpecificProps = APP_PROPERTIES_IMART;
    break;
  // --- Boehringer - iQNow --- //
  case APP_ID_IQNOW:
    appSpecificProps = APP_PROPERTIES_IQNOW;
    break;
  // --- SciWalker --- //
  case APP_ID_SCIWALKER:
    appSpecificProps = APP_PROPERTIES_SCIWALKER;
    break;
  // --- SciWalker Studio --- //
  case APP_ID_SCIWALKER_STUDIO:
    appSpecificProps = APP_PROPERTIES_SCIWALKER_STUDIO;
    break;
  // --- SciWalker - Alpha (experimental version) --- //
  case APP_ID_SCIWALKER_ALPHA:
    appSpecificProps = APP_PROPERTIES_SCIWALKER_ALPHA;
    break;
  // --- Default: SciWalker --- //
  default:
    appSpecificProps = APP_PROPERTIES_SCIWALKER;
    break;
}

properties = merge(APP_PROPERTIES_DEFAULT, appSpecificProps);

// --- overwrite default values with env-config file variables --- //
if (window.__env__) {
  // --- application ID --- //
  properties.APP_ID = appID;
  // --- application name --- //
  if (window.__env__.hasOwnProperty('REACT_APP_NAME')) {
    properties.APP_NAME = window.__env__.REACT_APP_NAME;
  }
  // --- frontend URL --- //
  if (window.__env__.hasOwnProperty('REACT_APP_FRONTEND_URL')) {
    properties.FRONTEND_URL = window.__env__.FRONTEND_URL;
  }
  // --- middleware --- //
  if (window.__env__.hasOwnProperty('REACT_APP_MIDDLEWARE_BASE_URL')) {
    properties.MIDDLEWARE_BASE_URL =
      window.__env__.REACT_APP_MIDDLEWARE_BASE_URL;
  }
  // --- BRAT --- //
  if (window.__env__.hasOwnProperty('REACT_APP_BRAT_BASE_URL')) {
    properties.BRAT_BASE_URL = window.__env__.REACT_APP_BRAT_BASE_URL;
  }
  // --- chemistry settings --- //
  if (window.__env__.hasOwnProperty('REACT_APP_INCLUDE_BQ_COMPOUND_EXPORT')) {
    properties.CHEMISTRY.INCLUDE_BQ_COMPOUND_EXPORT =
      window.__env__.REACT_APP_INCLUDE_BQ_COMPOUND_EXPORT;
  }
  if (window.__env__.hasOwnProperty('REACT_APP_INCLUDE_MCULE_LINKS')) {
    properties.CHEMISTRY.INCLUDE_MCULE_LINKS =
      window.__env__.REACT_APP_INCLUDE_MCULE_LINKS;
  }
  // --- ChemAxon --- //
  if (window.__env__.hasOwnProperty('REACT_APP_INCLUDE_CHEMAXON_MARVIN4JS')) {
    properties.CHEMISTRY.INCLUDE_CHEMAXON_MARVIN4JS =
      window.__env__.REACT_APP_INCLUDE_CHEMAXON_MARVIN4JS;
  }
  if (window.__env__.hasOwnProperty('REACT_APP_INCLUDE_CHEMAXON_WEBSERVICES')) {
    properties.CHEMISTRY.INCLUDE_CHEMAXON_WEBSERVICES =
      window.__env__.REACT_APP_INCLUDE_CHEMAXON_WEBSERVICES;
  }
  if (window.__env__.hasOwnProperty('REACT_APP_CHEMAXON_MARVIN4JS_URL')) {
    properties.CHEMISTRY.CHEMAXON_MARVIN4JS_URL =
      window.__env__.REACT_APP_CHEMAXON_MARVIN4JS_URL;
  }
  if (window.__env__.hasOwnProperty('REACT_APP_CHEMAXON_WEBSERVICES_URL')) {
    properties.CHEMISTRY.CHEMAXON_WEBSERVICES_URL =
      window.__env__.REACT_APP_CHEMAXON_WEBSERVICES_URL;
  }
  if (window.__env__.hasOwnProperty('REACT_APP_CHEMAXON_WEBSERVICES_VERSION')) {
    properties.CHEMISTRY.CHEMAXON_WEBSERVICES_VERSION =
      window.__env__.REACT_APP_CHEMAXON_WEBSERVICES_VERSION;
  }
  // --- SAML --- //
  if (window.__env__.hasOwnProperty('REACT_APP_SAML_URL')) {
    properties.SAML_URL = window.__env__.REACT_APP_SAML_URL;
  }
  // --- batches --- //
  if (window.__env__.hasOwnProperty('REACT_APP_INCLUDE_BATCHES')) {
    properties.INCLUDE_BATCHES = window.__env__.REACT_APP_INCLUDE_BATCHES;
  }
  // --- Readcube --- //
  if (window.__env__.hasOwnProperty('REACT_APP_INCLUDE_READCUBE')) {
    properties.INCLUDE_READCUBE = window.__env__.REACT_APP_INCLUDE_READCUBE;
  }
  // --- Freshdesk --- //
  if (window.__env__.hasOwnProperty('REACT_APP_INCLUDE_FRESHDESK')) {
    properties.INCLUDE_FRESHDESK = window.__env__.REACT_APP_INCLUDE_FRESHDESK;
  }
  // --- Onetrust --- //
  if (window.__env__.hasOwnProperty('REACT_APP_INCLUDE_ONETRUST')) {
    properties.INCLUDE_ONETRUST = window.__env__.REACT_APP_INCLUDE_ONETRUST;
  }
  // --- recaptcha --- //
  if (window.__env__.hasOwnProperty('REACT_APP_RECAPTCHA_FRONTEND_KEY')) {
    properties.RECAPTCHA_KEY = window.__env__.REACT_APP_RECAPTCHA_FRONTEND_KEY;
  }
}

export const APP_PROPERTIES = validateApplicationProperties(properties);

// --- constants --- //
export * from './constants';

// --- repository definitions --- //
export * from './repositories';

// --- metadata definitions --- //
export * from './metadata';

// --- role definitions --- //
export * from './roles';

// --- domain colors --- //
export * from './domains';
