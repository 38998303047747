import { getHeaderToken, sendApiRequest } from './index'
import { saveAs } from 'file-saver';
import { APP_PROPERTIES } from '../../src/properties/index';

export const fetchAllTermSuggestions = (search) => {
    let status = 'FAILED'
    const useDomainIndex = search.useDomainIndex;
    delete search.useDomainIndex;
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/suggestions?mergeResults=true${!!useDomainIndex ? '&useDomainIndex=true&useCombinedIndex=true' : ''}`, { headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search) })
        .then(response => {
            if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchAllRelations = (search) => {
    let status = 'FAILED'
    //console.log(JSON.stringify(search))
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/relationsv2/count/${search.count}/start/${search.startIndex}`, {
        headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search.request)
    })
        .then(response => {
            if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchEveryRelation = (search) => {
    let status = 'FAILED'
    //console.log(JSON.stringify(search.request))
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/umicore/relationsv2/count/${search.count}/start/${search.startIndex}`, {
        headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search.request)
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            } else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchAllDocuments = (search) => {
    let status = 'FAILED'
    //console.log(JSON.stringify(search))
    //return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/documents/list/count/${search.count}/start/${search.startIndex}`, {
    // eslint-disable-next-line
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/documents?count=${search.count}&start=${search.startIndex}`, {
        headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search.request)
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}


export const fetchPDFContent = (search) => {
    let status = 'FAILED'
    //console.log("test", JSON.stringify(search))
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/source-file?repositoryId=${search.repository}&page=${search.page}`, {
        headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search)
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchAllSortFields = (search) => {
    let status = 'FAILED'
    //console.log('search: ', search)
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/schema?repositoryId=${search.repository}`, {
        headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search)
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchAllSimilarDocuments = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/document/similarity/list/${search.docID}/count/${search.count}/start/${search.startIndex}`, {
        //headers: getHeaderToken(), method: 'POST', body: search.request
        headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search.request)
    })
        .then(response => {
            if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const getPublicationListXLSX = (search) => {
    let status = 'FAILED'
    //console.log('search: ', search);

    //return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/xlsx?repositoryId=${search.request.repository}`,
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/xlsx/document-meta-data`,
        { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search.request) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.blob()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(function (blob) {
            saveAs(blob, 'results.xlsx')
        }).then(payload => ({ status, payload }))
    //.catch(error => ({ status: 'FAILED', message: error }))
}

export const getSimilarPublicationListXLSX = (search) => {
    //console.log(JSON.stringify(search.request))
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/similarity/xlsx?docId=${search.docId}`,
        { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search.request) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.blob()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(function (blob) {
            saveAs(blob, 'similar_results.xlsx')
        }).then(payload => ({ status, payload }))
    //.catch(error => ({ status: 'FAILED', message: error }))
}

export const getRelationListXLSX = (search) => fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/relationsv2/xlsx`, {
    method: 'POST',
    headers: getHeaderToken(),
    body: JSON.stringify(search.request)
}).then(function (response) {
    return response.blob();
}).then(function (blob) {
    saveAs(blob, 'relation_results.xlsx');
}).then(payload => ({ status: 'SUCCESS', payload }))

export const fetchAllIPCClasses = () => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ipc/list`, {
        headers: getHeaderToken(), method: 'GET'
    })
        .then(response => {
            if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}


export const addNewQuery = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query/add/new`, { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchAllQueryStrings = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/query`, { headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            } else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const getAllStatistics = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/statistics`, { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchFormsQueries = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/form/layer/page/${search.first}/count/${search.count}/list`, {
        headers: getHeaderToken(), method: 'GET'
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchPropertiesTrees = () => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ontology/tree/properties`, {
        headers: getHeaderToken(), method: 'GET'
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchAllRelationDocuments = (search) => {
    let status = 'FAILED'
    //console.log(JSON.stringify(search.request))
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/documents/count/${search.count}/start/${search.startIndex}`, {
        //headers: getHeaderToken(), method: 'POST', body: search.request
        headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search.request)
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            } else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const getRelationDocumentsListXLSX = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/documents/xlsx`,
        { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search.request) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.blob()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(function (blob) {
            if (blob)
                saveAs(blob, 'relation_documents_results.xlsx')
        }).then(payload => ({ status, payload }))
    //.catch(error => ({ status: 'FAILED', message: error }))
}

export const modifyFormsQueries = (search) => {
    //console.log(JSON.stringify(search))
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${search.id}`, {
        //headers: getHeaderToken(), method: 'POST', body: search.request
        headers: getHeaderToken(), method: 'POST', body: JSON.stringify(search)
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            } else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const removeFormsQueries = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${search.id}`, {
        //headers: getHeaderToken(), method: 'POST', body: search.request
        headers: getHeaderToken(), method: 'DELETE', body: JSON.stringify(search)
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            } else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const saveFormsQueries = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/name/myqueries`, {
        //headers: getHeaderToken(), method: 'POST', body: search.request
        headers: getHeaderToken(), method: 'PUT', body: JSON.stringify(search)
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            } else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const getAllCompoundHits = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/opnme/hitCountForCompounds`, { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const getAllPublications = (search) => {
    //console.log(JSON.stringify(search.request))
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/opnme/abstracts/start/${search.request.startIndex}/count/${search.request.count}`,
        { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search.request) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const getPublicationListRIS = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/opnme/abstracts/ris`,
        { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search.request) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.blob()
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(function (blob) {
            saveAs(blob, 'results.ris')
        }).then(payload => ({ status, payload }))
    //.catch(error => ({ status: 'FAILED', message: error }))
}

export const editSelectedQuery = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query/edit`, { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchAllQueries = () => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/page/0/count/100000/list`, {
        headers: getHeaderToken(), method: 'GET'
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const removeSelectedQuery = (search) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query/remove`, { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(search) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchAllReadcubeLibraries = () => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/user/collections`, {
        headers: getHeaderToken(), method: 'GET'
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const submitReadcubeLibrary = (lib) => {
    let request = {
        collectionId: lib.id,
        repositoryLabel: lib.label
    }
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/annotations`, { method: 'POST', headers: getHeaderToken(), body: JSON.stringify(request) })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response
            } else {
                status = 'FAILED'
                return response.text()
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const fetchReadcubeRequests = () => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/annotations`, {
        headers: getHeaderToken(), method: 'GET'
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            }
            else if (response.ok) {
                status = 'SUCCESS'
                return response.json()
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => ({ status: 'FAILED', message: error }))
}

export const deleteReadcubeLibrary = (uuid) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/annotations?uuid=${uuid}`, {
        //headers: getHeaderToken(), method: 'POST', body: search.request
        headers: getHeaderToken(), method: 'DELETE', data: { uuid }
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            } else if (response.ok) {
                status = 'SUCCESS'
                return response
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => console.log(error)({ status: 'FAILED', message: error }))
}

export const syncReadcubeLibrary = (uuid) => {
    let status = 'FAILED'
    return fetch(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/annotations/refresh?uuid=${uuid}`, {
        //headers: getHeaderToken(), method: 'POST', body: search.request
        headers: getHeaderToken(), method: 'POST', body: {}
    })
        .then(response => {
            if (response.status === 401) {
                status = "LOGGEDOUT"
            } else if (response.ok) {
                status = 'SUCCESS'
                return response
            } else {
                status = 'FAILED'
            }
        })
        .then(payload => ({ status, payload }))
        .catch(error => console.log(error)({ status: 'FAILED', message: error }))
}

export const fetchAvailableUserRepositories = async () => {
    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/user/repositories`, { headers: getHeaderToken() })
    if (result.status === 'SUCCESS') {
        const availRepositories = (result.payload && result.payload) ? result.payload : {};
        return ({
            status: 'SUCCESS',
            payload: availRepositories
        });
    }

    return result;
}