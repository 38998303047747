import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// different jquery than the one included in index.xhtml!!!!
import $ from 'jquery';
import { convertInitialLetterToUpperCase, getMonthNameFromDate, getYearFromDate, isArrayEmpty } from "../util";
import { scrollToElementViaScrollToButton } from "./util/htmlHandling";
import {
    setSentenceAnalysisSentences,
    setSelectedSentenceAnalysis,
    setDocumentSentencesSentenceAnalysis,
    setActiveSearchTermsSentenceAnalysis,
    setAnnotationCoordinatesSentenceAnalysis,
    setLoaderSentenceAnalysis,
    setSearchLoadedSentenceAnalysis,
    setDocumentHTMLSentenceAnalysis
} from '../../../redux/actions/SentenceAnalysisActions';
import AnnotationInfoOverlay from "./AnnotationInfoOverlay";
import "./AnnotatedTextSentences.css"
import { Button } from "primereact/button";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import ExternalLinkIcon from "../general/ExternalLinkIcon/ExternalLinkIcon";
import { createDOILink } from "../util/externalLinks";

const texts = {
    backgroundColor: (color) => `background-color: #${color ?? 'b2b2b2'}; cursor: pointer;`
}

const regexs = {
    backgroundColor: (color, colorForUnderlining) => `background-color: #${color}; cursor: pointer; border-bottom: 3px dashed #${colorForUnderlining};`
}

const activeSentences = {
    backgroundColor: () => `cursor: pointer; background-color: #fcf492;`
    //font-weight: bold;`
    //border: 1px solid black
}

const sentences = {
    backgroundColor: (color) => `background-color: #${color}; cursor: pointer;`
}

const activeSentencesList = {
    backgroundColor: () => `line-height: 1.5;`
    //font-weight: bold;
}

const sentencesList = {
    backgroundColor: (color) => `background-color: '#${color}'; cursor: pointer; line-height: 1.5;`
}


const slidedivs = [
    /*
    ["ocTextAbstract", "h2",
        {
            "en": "Abstract",
            "fr": "Abstract",
            "de": "Abstract"
        }],
    */
    ["ocTextPat_citations", "h2",
        {
            "en": "Citations",
            "fr": "Citations",
            "de": "Citations"
        }],
    ["ocTextPat_description", "h2",
        {
            "en": "Description",
            "fr": "Description",
            "de": "Beschreibung"
        }],
    ["ocTextPat_claims", "h2",
        {
            "en": "Claims",
            "fr": "Revendications",
            "de": "Patentanspr&uuml;che"
        }],
    ["ocTextPat_summary-of-invention", "h3",
        {
            "en": "Summary",
            "fr": "R&eacute;sum&eacute;",
            "de": "Zusammenfassung"
        }],
    ["ocTextPat_detailed_description", "h3",
        {
            "en": "Detailed Description",
            "fr": "Description Pr&eacute;cise",
            "de": "Ausf&auml;hrliche Beschreibung"
        }],
    ["ocTextPat_description-of-drawings", "h3",
        {
            "en": "Description of Drawings",
            "fr": "Description des Dessins",
            "de": "Beschreibung der Zeichnungen"
        }],
    ["ocSem_ArticleContent", "h5",
        {
            "en": "Show/Hide article content",
            "de": "Zeige/Verstecke Inhalt des Artikels"
        }]
];
/* update sd.openCollapsedSurroundingDiv if slidedivs changed!!! */

class AnnotatedText extends PureComponent {

    state = {
        docHtml: '',
        docPartsHtml: {},
        annDataHtml: '',
        loading: false,
        allImportantArePresent: true
    };

    componentDidMount() {
        this.props.onSetSelectedSentence("")

        this.updateText(this.props);
        //window.addEventListener('resize', () => setTimeout(() => this.handleResize(), 1000))
        $(document).ready(function () {
            //console.log("ready")
            setTimeout(() => this.handleResize(), 1000)
        }.bind(this))
    }

    UNSAFE_componentWillReceiveProps = async (nextProps) => {

        if (this.props.docHtml !== nextProps.docHtml ||
            this.props.docPartsHtml !== nextProps.docPartsHtml ||
            this.props.activeAnnotations !== nextProps.activeAnnotations ||
            this.props.annotations !== nextProps.annotations ||
            this.props.conceptsData !== nextProps.conceptsData ||
            this.props.activeSearchTerms !== nextProps.activeSearchTerms ||
            this.props.selectedSentence !== nextProps.selectedSentence ||
            (this.props.threshold !== nextProps.threshold) && !this.props.searchLoaded ||
            (this.props.selectedSentenceOption !== nextProps.selectedSentenceOption) && this.props.threshold !== 1 && !this.props.searchLoaded ||
            this.props.availableContentHeight !== nextProps.availableContentHeight ||
            (this.props.showSentenceEntities !== nextProps.showSentenceEntities) && !this.props.searchLoaded
        ) {
            if (nextProps.renderDocumentOnly) {
                setTimeout(() => this.handleResize(), 1000)
                //await this.props.onSetActiveSearchTerms(nextProps.activeSearchTerms)
                await this.props.onSetSelectedSentence(nextProps.selectedSentence)
            }
            this.updateText(nextProps);
        }
    }
    updateText = (props) => {
        const { docHtml, docPartsHtml, activeAnnotations, annotations, conceptsData, activeSearchTerms } = props;
        //console.log(annotations)
        //let enrichedHtml = '<div id="annText">' + docHtml + '</div>';
        let enrichedHtml = docHtml;
        let annDataHtml = '';

        this.annotationCoords = []
        this.counter = 1
        this.counterTerms = 0
        this.queryTerms = []
        let sentDiv = $("<div></div>")
        let sentenceIDs = []

        if (!docPartsHtml) {
            // 
        }
        else if (!docPartsHtml.createFromDocParts) {
            if (docPartsHtml.enrichedDocument && activeAnnotations && annotations && conceptsData) {
                const resultHtmls = this.enrichAnnotatedText(docPartsHtml.enrichedDocument, activeAnnotations, annotations, conceptsData, activeSearchTerms, undefined, sentDiv, sentenceIDs);
                enrichedHtml = resultHtmls.htmlObj.html();
                //annDataHtml = resultHtmls.annDataObj.html();
            }
        }
        else if (docPartsHtml.namedDocumentRanges && this.props.renderDocumentOnly// && !this.props.abstractOnly
        ) {
            //console.log(docPartsHtml)
            const resultHtmls = this.enrichAnnotatedText(docPartsHtml.enrichedDocument, activeAnnotations, annotations, conceptsData, activeSearchTerms, undefined, sentDiv, sentenceIDs);
            enrichedHtml = resultHtmls.htmlObj.html();
            /*let sortedRanges = []
            for (let part in docPartsHtml.namedDocumentRanges) {
                sortedRanges.push([part, docPartsHtml.namedDocumentRanges[part]])
            }
            sortedRanges.sort((a, b) => a[1][0].startOffset - b[1][0].startOffset)
            let objSorted = {}
            sortedRanges.forEach(item => {
                objSorted[item[0]] = item[1]
            })
            let finished = false
            const fromParts = true
            Object.values(objSorted).forEach((parts, i) => {
                parts.forEach((part, j) => {
                    if ((i === Object.values(objSorted).length - 1) && (j === parts.length - 1)) {
                        finished = true
                    }
                    const resultHtmls = this.enrichAnnotatedText(part.rangeDocument, activeAnnotations, annotations, conceptsData, activeSearchTerms, finished, sentDiv, sentenceIDs, fromParts);
                    part.enrichedRangeDocument = resultHtmls.htmlObj.html();
                    part.key = `${i}_${j}`;
                });
            });*/
        } else if (docPartsHtml.namedDocumentRanges && (!this.props.renderDocumentOnly || this.props.abstractOnly)) {
            Object.values(docPartsHtml.namedDocumentRanges).forEach((parts, i) => {
                parts.forEach((part, j) => {
                    const resultHtmls = this.enrichAnnotatedText(part.rangeDocument, activeAnnotations, annotations, conceptsData, activeSearchTerms);
                    part.enrichedRangeDocument = resultHtmls.htmlObj.html();
                    part.key = `${i}_${j}`;
                });
            });

        }
        this.setState({
            docHtml: enrichedHtml,
            docPartsHtml: docPartsHtml,
            annDataHtml: annDataHtml
        }, () => {
            if (this.props.renderDocumentOnly) {
                this.props.onLoading(false)
                if (this.props.searchLoaded) {
                    this.props.onSetSearchLoaded(false)
                }
            }

            const importantSearchTerms = this.props.activeSearchTerms.filter(term => term.important);
            const allImportantArePresentCheck = importantSearchTerms.every((term) =>
                this.props.annotations.some((annotation) =>
                    annotation.annotations[0].queryId === term.id
                )
            );
            this.setState({allImportantArePresent: allImportantArePresentCheck});
            if (!allImportantArePresentCheck && this.props.abortLoadingClicked === false) {
                let newActiveIndex;
                if (this.props.activeIndexIncrease === undefined || this.props.activeIndexIncrease === true) {
                    newActiveIndex = this.props.activeIndex + 1;
                } else {
                    if (this.props.activeIndex !== 0)
                        newActiveIndex = this.props.activeIndex - 1;
                }
                this.props.activeIndexChange(newActiveIndex)
            } else if (this.props.abortLoadingClicked === true) {
                this.props.setAbortLoadingClicked(undefined);
                this.props.onLoading(false);
            }
        });
    }


    /**
     * Enriches the document html with named entity data, event handlers, styles, headings etc.
     */
    enrichAnnotatedText = (docHtml, activeAnnotations, annotations, conceptsData, activeSearchTerms, finished, sentDiv, sentenceIDs, fromParts) => {

        // --- create jQuery html object --- //
        let htmlObj = $('<div>' + docHtml + '</div>');
        let annDataObj = $('<div></div>');

        if (this.props.renderDocumentOnly)
            this.addClaimNumbers(htmlObj)

        // --- add CSS classes and event handler --- //
        const resultHtmls = this.addNamedEntityData(htmlObj, annDataObj, activeAnnotations, annotations, conceptsData, activeSearchTerms, this.props.renderDocumentOnly, this.props.showSentenceEntities, finished, sentDiv, sentenceIDs, fromParts);

        // --- add headings --- //
        this.addHeadings(resultHtmls.htmlObj);

        // --- add collapsing divs --- //
        this.addDivCollapsingAnchors(htmlObj, this.props.renderDocumentOnly);

        // --- return adjusted html --- //
        return resultHtmls;
    }

    onIndexChange = (newActiveIndex) => {
        this.props.activeIndexChange(newActiveIndex)
    }
    findParentWithClass = (childElement, className = 'ocTextNoShow') => {
        let parent = childElement.parentElement;

        if (!parent || childElement?.id === 'annTextContent')
            return false

        if (parent.classList.contains(className))
            return true;


        return this.findParentWithClass(parent)
    }

    /**
     * Adds concept data, CSS classes and event handler to each named entity of an active domain.
     */
    addNamedEntityData = (htmlObj, annDataObj, activeAnnotations, annotations, conceptsData, activeSearchTerms, renderDocumentOnly, showSentenceEntities, finished, sentDiv, sentenceIDs, fromParts) => {

        this.annotationCoords = []
        let countTextMatch = []
        //const parentPos = document.getElementById("oc_doc").getBoundingClientRect().top
        for (const elem of htmlObj.find("span[id^=annId]")) {
            const namedEntity = annotations?.find((item) => item?.annId === elem.id);
            //console.log(namedEntity)
            const aElem = $(elem);

            if (!namedEntity || !aElem) {
                continue;
            }

            if (this.state.allImportantArePresent && (activeAnnotations?.length > 0 || activeSearchTerms?.length > 0)) {
                //const classNames = {};
                //let isQuery = false;
                let activeAnns = 0;

                for (const domAnnotation of namedEntity.annotations) {
                    const isQueryTerm = (domAnnotation.queryTerm);
                    // --- domain annotation found --- //
                    if (!isQueryTerm) {
                        if (!conceptsData || !activeAnnotations.includes(domAnnotation.domain)) {
                            continue;
                        }
                        aElem.attr('style', texts.backgroundColor(this.props.domains?.find(item => item.name === domAnnotation.domain)?.color))
                    }
                    // --- query term found -> handle later --- //
                    else {
                        const temp = activeSearchTerms?.find((obj) => obj?.id === domAnnotation?.queryId);
                        if (temp && this.props.isSemanticOn) {
                            aElem.attr('style', (temp.regex) ? regexs.backgroundColor(temp.color, temp.colorForUnderlining) : texts.backgroundColor(temp.color));
                            if (temp?.domains.includes('tr') || temp?.domains.includes('t')) {
                                if (this.findParentWithClass(aElem[0])) {
                                    countTextMatch[temp.id] = countTextMatch[temp.id] ? [...countTextMatch[temp.id], namedEntity.annId] : [namedEntity.annId]
                                }
                            }
                            aElem.attr('style', texts.backgroundColor(temp.color))
                            break;
                        }
                        else if (!activeAnnotations.includes('queryTerm')) {
                            continue;
                        } else {
                            if (this.props.isMatchesSelected) {
                                aElem.attr('style', texts.backgroundColor('FFFF02'))
                            }
                        }
                    }
                    //activeAnns++;
                }
                // --- count number of active annotations --- //
                for (const domAnnotation of namedEntity.annotations) {
                    if (activeAnnotations.includes(domAnnotation.domain)) {
                        activeAnns++;
                    }
                }
                // --- if there is more than one active annotation, add the black triangle --- //
                if (activeAnns > 1) {
                    aElem.attr("class", ' ann_mult');
                }
                /*if (activeAnns > 1 && !isQuery || activeAnns > 2 && isQuery) {
                    aElem.attr("class", ' ann_mult');
                }*/
                // --- add event handler --- //
                aElem.attr("onclick", "showAnnotationInfoWindow( event, '" + namedEntity.annId + "')");

                if (namedEntity !== undefined && renderDocumentOnly && !showSentenceEntities) {
                    let newEl = document.getElementById(elem.id)
                    if (newEl !== null) {
                        let selectedAnnotationsIndex = namedEntity.annotations?.findIndex(ann => ann.hasOwnProperty("queryId"))
                        //console.log(newEl.offsetTop)
                        //console.log(newEl.getBoundingClientRect().top)
                        //const parentPos = document.getElementById("oc_doc").getBoundingClientRect().top
                        //console.log(newEl.getBoundingClientRect().top - parentPos)
                        //console.log("parent: ", parentPos)
                        //let topCoordinate = newEl.getBoundingClientRect().top - parentPos
                        //console.log(namedEntity)
                        let topCoordinate = newEl.offsetTop
                        //if (this.annotationCoords.length === 0 //|| (!this.annotationCoords.some(coord => //(coord.top === topCoordinate) && 
                        // (coord.color === aElem.css("backgroundColor"))))
                        //) {
                        this.annotationCoords = [...this.annotationCoords, { top: topCoordinate, color: aElem.css("backgroundColor"), element: 'entity', id: elem.id, queryId: namedEntity.annotations[selectedAnnotationsIndex]?.queryId }]
                        //}
                    }
                }
            }
            else {
                aElem.attr("className", '');
                aElem.attr("class", '');
                aElem.attr("style", '');
            }

        }

        if (renderDocumentOnly) {
            //, div[class^=ocTextHeading_], h2[class^=ocTextPat_],
            for (const elemSection of htmlObj.find("div[class^=ocTextSection_], div[class^=ocTextPat_], h2[class^=ocTextPat_], div[class^=ocTextTitle], h1[class^=ocTextTitle], div[class^=ocTextAbstract], h1[class^=ocTextSection]")) {
                $(elemSection).prop('id', elemSection.className.split(" ")[0])
                let newEl = document.getElementById(elemSection.id)
                if (newEl !== null) {
                    let topCoordinate = newEl.offsetTop
                    //console.log(elemSection.className)
                    //console.log(elemSection.className.split(" ")[0])
                    //console.log(newEl.offsetTop)
                    //let topCoordinate = newEl.getBoundingClientRect().top - parentPos
                    if (!this.annotationCoords.some(coord => (coord.top === topCoordinate && coord.element !== 'entity') || (coord.element !== 'entity' && coord.sectionInfo.includes(elemSection.className.split(" ")[0])))) {
                        this.annotationCoords = [...this.annotationCoords, { top: topCoordinate, color: "", element: "section", sectionInfo: elemSection.className.split(" ")[0] }]
                    }
                }
            }
        }

        //console.log(annotationCoords)

        if (renderDocumentOnly) {
            //console.log($(window).scrollTop())
            //console.log(start)
            //console.log(finished)
            //console.log(sentDiv)
            //console.log(annotationCoords)
            // if (start || start === undefined) {
            let counter = this.counter
            /*let sentDiv = $("<div></div>")
            let sentenceIDs = []*/
            //}

            for (const elem of htmlObj.find(".sent")) {
                //console.log(elem)
                /*let counterTerms = 0
                let queryTerms = []*/
                this.counterTerms = 0
                this.queryTerms = []
                for (const el of ($(elem).find("span[id^=annId]"))) {
                    const namedEntity = annotations?.find((item) => item?.annId === el.id);
                    //console.log(namedEntity)
                    const aEl = $(el);

                    if (!namedEntity || !aEl) {
                        continue;
                    }

                    if (namedEntity !== undefined) {
                        let queryIdIndex = namedEntity.annotations?.findIndex(ann => ann.hasOwnProperty("queryId"))
                        if (!this.queryTerms.includes(namedEntity.annotations[queryIdIndex]?.queryId)) {
                            this.queryTerms = [...this.queryTerms, namedEntity.annotations[queryIdIndex]?.queryId]
                            this.counterTerms++
                        }
                        if (this.state.allImportantArePresent && (this.counterTerms >= this.props.threshold || this.props.selectedSentenceOption === 'all')) {
                            const newElem = $(elem);
                            if ((newElem.attr("id") === this.props.selectedSentence) || (this.props.selectedSentence === "" && counter === 1)) {
                                //console.log(this.props.selectedSentence)
                                newElem.attr('style', activeSentences.backgroundColor())
                                scrollToElementViaScrollToButton('#scrollToBtn', '#' + newElem.attr("id"));
                                scrollToElementViaScrollToButton('#scrollToBtnSent', '#' + newElem.attr("id"))
                            } else {
                                newElem.attr('style', sentences.backgroundColor('faf7d2'))
                            }
                            newElem.attr("onclick", "onSetSelectedSentence( event, '" + newElem.attr("id") + "')");
                            const clone = newElem.clone()
                            if (this.props.selectedSentence === "" && counter === 1) {
                                this.props.onSetSelectedSentence(clone.attr("id"))
                            }
                            sentenceIDs = [...sentenceIDs, clone.attr("id")]
                            //$(`<span id=${clone.attr("id")}>` + counter + "</span>").insertBefore(`#${clone.attr("id")}`)
                            if ((clone.attr("id") === this.props.selectedSentence) || (this.props.selectedSentence === "" && counter === 1)) {
                                clone.attr('style', activeSentencesList.backgroundColor())
                            } else {
                                clone.attr('style', sentencesList.backgroundColor('ffffff'))
                            }
                            for (const el of ($(clone).find("span[id^=annId]"))) {
                                $(el).attr("onclick", "");
                                $(el).attr("id", "");
                                //$(el).css('cursor', 'default')
                            }

                            if (showSentenceEntities) {
                                for (const el of (newElem.find("span[id^=annId]"))) {
                                    const namedEntity = annotations?.find((item) => item?.annId === el.id);
                                    if (namedEntity !== undefined) {
                                        let newEl = document.getElementById(el.id)
                                        let selectedAnnotationsIndex = namedEntity.annotations?.findIndex(ann => ann.hasOwnProperty("queryId"))
                                        //console.log(newEl)
                                        let newAEl = $(el)
                                        if (newEl !== null) {
                                            //console.log(newEl.offsetTop)
                                            //console.log(newEl.getBoundingClientRect().top)
                                            //const parentPos = document.getElementById("oc_doc").getBoundingClientRect().top
                                            //console.log(newEl.getBoundingClientRect().top - parentPos)
                                            //console.log("parent: ", parentPos)
                                            //let topCoordinate = newEl.getBoundingClientRect().top - parentPos
                                            //console.log(namedEntity)
                                            let topCoordinate = newEl.offsetTop
                                            //if (this.annotationCoords.length === 0 
                                            //||
                                            //(!this.annotationCoords.some(coord => //(coord.top === topCoordinate) && 
                                            //(coord.color === newAEl.css("backgroundColor"))))
                                            // ) {
                                            this.annotationCoords = [...this.annotationCoords, { top: topCoordinate, color: newAEl.css("backgroundColor"), element: 'entity', id: el.id, queryId: namedEntity.annotations[selectedAnnotationsIndex]?.queryId }]
                                            // }
                                        }
                                    }
                                }
                            }
                            counter++
                            if (fromParts) {
                                this.counter = counter
                            }
                            //console.log(clone)
                            //console.log(sentDiv)
                            sentDiv.append(clone)
                            break
                        }
                    }
                }
            }
            //console.log(activeSearchTerms)
            //console.log(sentDiv)
            if (finished || finished === undefined) {
                this.props.onCreateSentenceDiv(sentDiv)
                this.props.onSetDocumentSentences(sentenceIDs)
                if(sentenceIDs?.length === 0){
                    scrollToElementViaScrollToButton('#scrollToBtnDoc', '#' + this.props?.docMetadata?.ocDocID[0])
                }
            }
            //console.log("no loading")
            //this.props.onLoading(false)
        }

        // --- add annotations and concept data to html --- //
        $('#annotations').text(JSON.stringify(annotations));
        $('#conceptsData').text(JSON.stringify(conceptsData));
        $('#activeAnnotations').text(JSON.stringify(activeAnnotations));

        this.props.setHiddenExactTextMisMatch(countTextMatch)
        if (renderDocumentOnly) {
            this.props.onCreateAnnotationCoordinates(this.annotationCoords)
        }

        return {
            htmlObj: htmlObj,
            annDataObj: annDataObj,
        }
    }

    /* TEST */
    /**
     * 
     */
    addHeadings = (htmlObj) => {
        // --- remove infoapps div -------------------------------------------- //
        // ???
        //htmlObj.find("div.ocSem_infoAppsId").parent().remove();

        // -------------------------------------------------------------------- //
        // --- insert headline before first abstract if it does not exist ----- //
        // --- and if abstract is not empty ----------------------------------- //
        let firstAbstract = htmlObj.find(".ocTextAbstract").first();
        if (firstAbstract && firstAbstract.text().length > 0) {
            let preFirstAbstractElem = firstAbstract.prev();
            if (!preFirstAbstractElem || !preFirstAbstractElem.is("h2")) {
                $('<h2 class="ocTextHeading_2">Abstract</h2>').insertBefore(firstAbstract);
            }
        }

        // -------------------------------------------------------------------- //
        // --- insert headline before references content ---------------------- //
        $('<hr style="background-color: #B2B2B2"/>').insertBefore(".ocTextReferencedContent");
        $('<h2 class="ocTextHeading_2">Referenced content</h2>').insertBefore(".ocTextReferencedContent");

        /*
         jQuery( '<div style="margin-bottom: 10px"><a style="cursor:pointer" onClick="oc.ocm.showDoc.toggleReferencedContent()">' +
         "Show/Hide content of all referenced articles</a></div>" ).insertBefore( ".ocTextReferencedContent" );
         */

        htmlObj.find(".ocTextRefContentDocument").each(function (index) {
            // --- add "scroll to top" link ------------------------------------ // 
            $(this).attr("id", "doc_ref_content_" + index);
            let article = $(this).children(".ocSem_ArticleContent");
            $('<div class="scrollToTop" style="margin: 15px 0 3px 0; font-weight: bold;" onclick="oc.ocm.showDoc.scrollToElementWithOffset( \'doc_ref_content_' +
                index + '\' )">Scroll to the top of this article &#x2B06;</div>').insertAfter(article);

            // --- add NCBI link with current PMID ----------------------------- // 
            let pmid = $(this).attr("ocRefPmid");
            if (pmid) {
                $(this).children(".ocSem_ArticleMeta").append('<div class="ocSem_SourceLink"><a href="https://pubmed.ncbi.nlm.nih.gov/' + pmid + '" target="_blank" rel="noreferrer">Link to NCBI</a></div>');
            }
        });
    }


    /**
     * Adds anchors to collapsible divs.
     */
    addDivCollapsingAnchors = (htmlObj, renderDocumentOnly) => {

        let rIDSStrg = $('#openedSections').text();
        let rIDs = rIDSStrg ? JSON.parse(rIDSStrg) : [];

        // --- add onclick events to buttons --- //
        // TODO move???
        $('#openAllSectionsBtn').attr("onclick", `openAllSections()`);
        $('#closeAllSectionsBtn').attr("onclick", `closeAllSections()`);

        let englishHeadlines = {};
        let headlineElements = {};

        // --- add div collapsing anchors --- //      
        for (let j = 0; j < slidedivs.length; j++) {
            let sel = "." + slidedivs[j][0];

            htmlObj.find(sel).each(function (i) {

                if (!headlineElements[sel]) { headlineElements[sel] = {}; }
                if (!englishHeadlines[sel]) { englishHeadlines[sel] = {}; }

                let rID = `sl${j}_${i++}`;
                if (!renderDocumentOnly) {
                    $(this).attr("id", rID);
                }

                let isCollapsed = !rIDs.includes(rID);

                // --- hide sections --- //
                if (isCollapsed) {
                    if (!renderDocumentOnly)
                        $(this).attr("style", "display:none");
                }

                // --- get headline in matching language --- //
                let lang = $(this).attr("ocLang");
                let headline = (lang) ? slidedivs[j][2][lang] : undefined;
                if (!headline) {
                    headline = slidedivs[j][2]["en"];
                }

                // --- toggle elements and set headline in correct language --- //
                let preSectionElem = $(this).prev();
                let icon = isCollapsed ? '&#9656;' : '&#9662;';
                let css = isCollapsed ? 'ocTextHeading_2' : 'ocTextHeading_2 active';

                // --- h2 is already defined for this section -> add toggle function --- //
                if (preSectionElem && preSectionElem.is("h2")) {
                    let slideElement1;
                    if (!renderDocumentOnly) {
                        slideElement1 = $(`<a style="cursor:pointer" class="slide" rid="${rID}"></a>`);
                    } else {
                        slideElement1 = $(`<label style="cursor:default" rid="${rID}"></label>`);
                    }
                    if (!renderDocumentOnly)
                        slideElement1.attr("onclick", `toggleSection( event, '${rID}')`);
                    preSectionElem.html(`<span class="headlineIcon">${icon} </span><span class="headlineText">${headline}</span>`);
                    preSectionElem.addClass(css);
                    preSectionElem.wrap(slideElement1);
                    if (lang) {
                        headlineElements[sel][lang] = slideElement1;
                        if (lang === 'en') { englishHeadlines[sel] = headline; }
                    }
                }
                // --- h2 is not defined for this section -> insert and add toggle function --- //
                else {
                    let slideElement2;
                    if (!renderDocumentOnly) {
                        slideElement2 = $(`<a style="cursor:pointer" class="slide" rid="${rID}"><h2 class=${css}><span class="headlineIcon">${icon} </span><span class="headlineText">${headline}</span></h2></a>`);
                    } else {
                        slideElement2 = $(`<label style="cursor:default" rid="${rID}"><h2 class=${css}><span class="headlineIcon">${icon} </span><span class="headlineText">${headline}</span></h2></label>`);
                    }
                    if (!renderDocumentOnly)
                        slideElement2.attr("onclick", `toggleSection( event, '${rID}')`);
                    $(this).before(slideElement2);
                    if (lang) {
                        headlineElements[sel][lang] = slideElement2;
                        if (lang === 'en') { englishHeadlines[sel] = headline; }
                    }
                }
            });
        }

        Object.keys(headlineElements).forEach(selector => {
            const headlineElementsForSelector = headlineElements[selector];
            const englishHeadline = englishHeadlines[selector];
            // --- check if headline exists which is identical to english headline --- //
            let addEnglishTag = false;
            for (let lang of Object.keys(headlineElementsForSelector)) {
                if (lang !== 'en') {
                    const otherHeadline = $(headlineElementsForSelector[lang]).find('span.headlineText')?.text();
                    if (otherHeadline === englishHeadline) {
                        addEnglishTag = true;
                        break;
                    }
                }
            }
            // --- if yes, then add info to english headline --- //
            if (addEnglishTag) {
                const englishElement = headlineElementsForSelector['en'];
                const englishHeadlineSpan = $(englishElement).find('span.headlineText');
                englishHeadlineSpan.text(`${englishHeadlineSpan.text()} (English)`);
            }
        })
    }

    /**
     * @param {*} htmlObj array containing strings
     * @returns html object with added claim numbers if missing in text
     */
    addClaimNumbers = (htmlObj) => {
        for (const elem of htmlObj.find("div[class^=ocTextPat_claim ]")) {
            const aEl = $(elem)
            if (aEl.attr("occlaimnum") !== undefined) {
                const claimEl = aEl.find('.ocTextPat_claim-text')
                if (claimEl.length < 2 && $(claimEl[0].children[0]).length > 0 && !$(claimEl[0].children[0]).text().trim().startsWith(aEl.attr("occlaimnum").replace(/^0+/, ''))) {
                    if ($(claimEl[0].children) && $(claimEl[0].children[0]) && $(claimEl[0].children[0].childNodes) && $(claimEl[0].children[0].childNodes[0])) {
                        $(claimEl[0].children[0]).html(`${aEl.attr("occlaimnum").replace(/^0+/, '')}. ` + $(claimEl[0].children[0]).html().trim())
                    }
                }
            }
        }
    }

    /**
     * Given are two arrays with similar content, e.g. authors. One contains only strings,
     * the other contains structured data. If structured data array is defined, then it is 
     * preferred and will be returned. Otherwise string array will be returned.
     * @param {*} unstructuredData array containing strings
     * @param {*} structuredData array containing structured data
     * @returns array with structured data if it is defined and flag which data was returned
     */
    getPreferrredData = (unstructuredData, structuredData) => {
        let data = null;
        let isStructuredData = false;
        if (!isArrayEmpty(structuredData)) {
            isStructuredData = true;
            data = structuredData.map(dt => { return JSON.parse(dt) });
        }
        else if (!isArrayEmpty(unstructuredData)) {
            data = unstructuredData;
        }
        return { data, isStructuredData };
    }

    // ----------------------------------------------------------------------- //
    // --- render different types of documents ------------------------------- //
    // ----------------------------------------------------------------------- //
    renderDocument = (repositoryName, renderDocumentOnly) => {

        switch (repositoryName) {

            case 'dsgrants':
                if (!renderDocumentOnly)
                    return this.renderGrantsDS();
                else return this.renderWholeDocument();

            case 'dspub':
                if (!renderDocumentOnly)
                    return this.renderPublicationsDS();
                else return this.renderWholeDocument();

            default:
                return this.renderWholeDocument();
        }
    }

    renderGrantsDS = () => {

        const { docPartsHtml, docMetadata } = this.props;
        const metaData = docMetadata ? docMetadata : {};

        let preferredDataForGrantees = this.getPreferrredData(metaData.grantees, metaData['grantees.json']);
        const grantees = preferredDataForGrantees.data;
        let isStructuredGranteesData = preferredDataForGrantees.isStructuredData;

        let preferredDataForResearchOrgs = this.getPreferrredData(metaData.research_orgs, metaData['research_orgs.json']);
        const researchOrgs = preferredDataForResearchOrgs.data;
        let isStructuredResearchOrgsData = preferredDataForResearchOrgs.isStructuredData;

        return (
            <div id={this.props.renderDocumentOnly ? "annTextContentSpace" : "annTextContent"} className={this.props.renderDocumentOnly ? "grid" : ""} style={{ marginLeft: this.props.renderDocumentOnly ? 0 : 0 }}>
                {this.renderInfoOverlay()}

                <div className="secondaryInfo">Grant</div>

                {docPartsHtml.namedDocumentRanges &&
                    !isArrayEmpty(docPartsHtml.namedDocumentRanges.title) ?
                    <div dangerouslySetInnerHTML={{ __html: `${docPartsHtml.namedDocumentRanges.title[0].enrichedRangeDocument}` }} />
                    : null}

                {this.props.renderDocumentOnly ?
                    <Button
                        style={{ display: 'none' }}
                        onClick={() => this.onSetSelectedSentenceFromDocument()}
                        id="loadSelectedSentence"
                        className="buttonSmall"
                    >
                    </Button>
                    : null}

                {!isArrayEmpty(metaData.funder) ?
                    <div className="primaryInfo" style={{ paddingBottom: 0 }}>
                        Funder: {metaData.funder}
                        {!isArrayEmpty(metaData.funder_country) ? `, ${metaData.funder_country[0]}` : ''}
                    </div> : null
                }

                {!isArrayEmpty(metaData.project_num) ?
                    <div className="secondaryInfo">
                        <span>Grant number: </span>
                        {metaData.url ?
                            <a href={metaData.url} target="_blank" rel="noreferrer">{metaData.project_num}
                                <ExternalLinkIcon standardLinkIcon={true} />
                            </a>
                            :
                            <span>{metaData.project_num}</span>
                        }
                    </div> : null
                }

                {!isArrayEmpty(grantees) ?
                    <>
                        <h2 className="ocTextHeading_2">Investigators</h2>
                        {grantees.map(grantee => {
                            if (isStructuredGranteesData) {
                                return (
                                    <React.Fragment key={`${grantee.first_name}_${grantee.last_name}`}>
                                        <div>
                                            {grantee.first_name} {grantee.last_name}
                                            {!isArrayEmpty(grantee.affiliations) && grantee.affiliations[0].affiliation ? ` - ${grantee.affiliations[0].affiliation}` : ''}
                                            <span className="secondaryInfo"
                                                style={{ padding: 0 }}>{grantee.grantee_role ? ` - ${grantee.grantee_role}` : ''}</span>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            else {
                                return <li key={`${grantee}`}>{grantee}</li>;
                            }
                        })}
                    </> : null
                }

                {!isArrayEmpty(researchOrgs) ?
                    <>
                        <h2 className="ocTextHeading_2">Research organization</h2>
                        {researchOrgs.map(resorg => {
                            if (isStructuredResearchOrgsData) {
                                return (
                                    <React.Fragment key={`${resorg.name}_${resorg.state}_${resorg.country}`}>
                                        <div>
                                            {resorg.name}
                                            {resorg.state ? `, ${resorg.state}` : ''}
                                            {resorg.country ? `, ${resorg.country}` : ''}
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            else {
                                return <li key={`${resorg}`}>{resorg}</li>;
                            }
                        })}
                    </> : null
                }

                {docPartsHtml.namedDocumentRanges &&
                    docPartsHtml.namedDocumentRanges.abstract &&
                    !isArrayEmpty(docPartsHtml.namedDocumentRanges.abstract) ?
                    <div dangerouslySetInnerHTML={{ __html: `${docPartsHtml.namedDocumentRanges.abstract[0].enrichedRangeDocument}` }} />
                    : null}
                {this.props.renderDocumentOnly ?
                    this.renderCustomScrollbar()
                    : null}
            </div>
        );
    }

    renderPublicationsDS = () => {

        const { docPartsHtml, docMetadata } = this.props;
        const metaData = docMetadata ? docMetadata : {};

        let preferredDataForAuthors = this.getPreferrredData(metaData.authors, metaData['authors.json']);
        const authors = preferredDataForAuthors.data;
        let isStructuredAuthorsData = preferredDataForAuthors.isStructuredData;

        return (
            <div id={this.props.renderDocumentOnly ? "annTextContentSpace" : "annTextContent"} className={this.props.renderDocumentOnly ? "grid" : ""} style={{ marginLeft: this.props.renderDocumentOnly ? 0 : 0 }}>
                {this.renderInfoOverlay()}

                <div className="secondaryInfo">{`Publication${!isArrayEmpty(docMetadata.pubType) ? ` - ${convertInitialLetterToUpperCase(docMetadata.pubType[0])}` : ''}`}</div>

                {docPartsHtml.namedDocumentRanges &&
                    !isArrayEmpty(docPartsHtml.namedDocumentRanges.title) ?
                    <div dangerouslySetInnerHTML={{ __html: `${docPartsHtml.namedDocumentRanges.title[0].enrichedRangeDocument}` }} />
                    : null}
                {this.props.renderDocumentOnly ?
                    <Button
                        style={{ display: 'none' }}
                        onClick={() => this.onSetSelectedSentenceFromDocument()}
                        id="loadSelectedSentence"
                        className="buttonSmall"
                    >
                    </Button>
                    : null}

                {!isArrayEmpty(metaData.source_title) ?
                    <div className="primaryInfo"
                        title={!isArrayEmpty(metaData.source_original_title) &&
                            metaData.source_original_title[0] !== metaData.source_title[0] ? metaData.source_original_title[0] : ''}
                        style={{ paddingBottom: 0 }}>
                        {metaData.source_title}
                        {!isArrayEmpty(metaData.volume) ? `, ${metaData.volume[0]}` : ''}
                        {!isArrayEmpty(metaData.issue) ? `(${metaData.issue[0]})` : ''}
                        {!isArrayEmpty(metaData.pages) ? `, ${metaData.pages[0]}` : ''}
                        {!isArrayEmpty(metaData.pubdate) ? ` - ${getMonthNameFromDate(metaData.pubdate[0]) ? getMonthNameFromDate(metaData.pubdate[0]) : ''}` : ''}
                        {!isArrayEmpty(metaData.pubdate) ? ` ${getYearFromDate(metaData.pubdate[0])}` : ''}
                    </div> : null
                }

                {!isArrayEmpty(metaData.doi) ?
                    <a className="secondaryLink"
                        style={{ color: '#757575' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={createDOILink(metaData.doi[0])}>
                        {createDOILink(metaData.doi[0])}
                        <ExternalLinkIcon standardLinkIcon={true} />
                    </a> : null
                }

                {!isArrayEmpty(authors) ?
                    <>
                        <h2 className="ocTextHeading_2">Authors</h2>
                        <ul className="noStyleList" style={{ lineHeight: 2, marginTop: 0 }}>
                            {authors
                                .filter((author, index) => (this.state.showAllAuthors || index < 3))
                                .map((author, index) => {
                                    if (isStructuredAuthorsData) {
                                        return (
                                            <li key={`${author.first_name}_${author.last_name}`}>
                                                {author.first_name} {author.last_name}
                                                {!isArrayEmpty(author.affiliations) && author.affiliations[0].affiliation ? ` - ${author.affiliations[0].affiliation}` : ''}
                                                <span className="secondaryInfo"
                                                    style={{ padding: 0 }}>{author.corresponding ? ` - Corresponding author` : ''}</span>
                                            </li>
                                        );
                                    }
                                    else {
                                        return <li key={`${author}`}>{author}</li>;
                                    }
                                })}
                            {!this.state.showAllAuthors && authors.length > 3 ?
                                <a onClick={(e) => this.setState({ showAllAuthors: true })}>{`${authors.length - 3} more`}</a> : null
                            }
                            {this.state.showAllAuthors && authors.length > 3 ?
                                <a onClick={(e) => this.setState({ showAllAuthors: false })}>Less</a> : null
                            }
                        </ul>
                    </> : null
                }

                {!isArrayEmpty(metaData.research_org) ?
                    <>
                        <h2 className="ocTextHeading_2">Research organization</h2>
                        <div>{metaData.research_org[0]}
                            {!isArrayEmpty(metaData.research_org_country) ? `, ${metaData.research_org_country[0]}` : ''}</div>

                    </> : null
                }

                {docPartsHtml.namedDocumentRanges &&
                    docPartsHtml.namedDocumentRanges.dsabstract &&
                    !isArrayEmpty(docPartsHtml.namedDocumentRanges.dsabstract) ?
                    <div dangerouslySetInnerHTML={{ __html: `${docPartsHtml.namedDocumentRanges.dsabstract[0].enrichedRangeDocument}` }} />
                    : null
                }
                {this.props.renderDocumentOnly ?
                    this.renderCustomScrollbar()
                    : null}
            </div>
        );
    }

    onSetSelectedSentenceFromDocument = () => {
        const sentId = localStorage.getItem("clickedSentenceId")
        this.props.onSetSelectedSentence(sentId)
        localStorage.removeItem('clickedSentenceId')
    }

    renderWholeDocument = () => {
        const { docPartsHtml, docMetadata, selectedSections } = this.props;
        //console.log(docPartsHtml)
        //console.log(docPartsHtml)
        //console.log(selectedSections)
        //console.log(this.props.docPartsHtml)
        if (this.props.renderDocumentOnly) {
            this.props.onCreateDocumentHTML(this.state.docHtml)
        }

        return (
            <div id={this.props.renderDocumentOnly ? "annTextContentSpace" : "annTextContent"} className={this.props.renderDocumentOnly ? "grid" : ""} style={{ marginLeft: this.props.renderDocumentOnly ? 0 : 0 }}>
                {this.renderInfoOverlay()}

                <div className="secondaryInfo">{this.props.repositoryInfo.label}</div>

                {/*{selectedSections?.filterValue !== 'all' && selectedSections !== undefined ?
                    <React.Fragment>
                        {docPartsHtml.namedDocumentRanges &&
                            !isArrayEmpty(docPartsHtml.namedDocumentRanges.title) ?
                            <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: `${docPartsHtml.namedDocumentRanges.title[0].enrichedRangeDocument}` }} />
                            :
                            null}
                        {docPartsHtml.namedDocumentRanges &&
                            !isArrayEmpty(docPartsHtml.namedDocumentRanges.abstract) ?
                            <div dangerouslySetInnerHTML={{ __html: `${docPartsHtml.namedDocumentRanges.abstract[0].enrichedRangeDocument}` }} />
                            :
                            null}
                        {docPartsHtml.namedDocumentRanges &&
                            !isArrayEmpty(docPartsHtml.namedDocumentRanges.claims) ?
                            <div dangerouslySetInnerHTML={{ __html: `${docPartsHtml.namedDocumentRanges.claims[0].enrichedRangeDocument}` }} />
                            : null
                        }
                        {docPartsHtml.namedDocumentRanges &&
                            !isArrayEmpty(docPartsHtml.namedDocumentRanges.experimental) ?
                            <div dangerouslySetInnerHTML={{ __html: `${docPartsHtml.namedDocumentRanges.experimental[0].enrichedRangeDocument}` }} />
                            : null
                        }
                    </React.Fragment>                     : */}
                <div dangerouslySetInnerHTML={{ __html: `${this.state.docHtml}` }} />
                {/*}

                {/*<div dangerouslySetInnerHTML={{ __html: `${this.state.docHtml}` }} />*/}

                <Button
                    style={{ display: 'none' }}
                    onClick={() => this.onSetSelectedSentenceFromDocument()}
                    id="loadSelectedSentence"
                    className="buttonSmall"
                >
                </Button>
                {this.props.renderDocumentOnly ?
                    this.renderCustomScrollbar()
                    : null}
            </div>
        );
    }


    renderCustomScrollbar = () => {
        //console.log(this.props)
        let contextHeight = $('#' + "annTextContentSpace").height()
        let height = this.props.availableContentHeight + 115
        let orderedCoords = []
        let orderedColors = []
        let uniqueColors = []
        this.props.activeSearchTerms.forEach((term, i) => {
            if (uniqueColors.length === 0 || !uniqueColors.includes(term.color)) {
                uniqueColors = [...uniqueColors, term.color]
            }
        })

        uniqueColors.forEach((col, i) => {
            this.props.activeSearchTerms.forEach((term) => {
                if (term.color === col) {
                    orderedColors = [...orderedColors, { queryId: term.id, colIndex: i }]
                }
            })
        })

        /* this.props.activeSearchTerms.forEach((term, i) => {
             orderedColors = [...orderedColors, { queryId: term.id, colIndex: i }]
         })*/

        this.props.annotationCoordinates.forEach(coord => {
            //console.log(coord)
            orderedColors.forEach((col) => {
                if ((coord.queryId === col.queryId) && !orderedCoords.some(el => el.id === coord.id) || coord.element === 'section') {
                    coord.colIndex = col.colIndex
                    orderedCoords = [...orderedCoords, coord]
                }
            })
        })

        orderedCoords = orderedCoords.sort((a, b) => a.top - b.top);

        /*orderedCoords = orderedCoords.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.top === value.top && t.colIndex === value.colIndex && t.element === value.element
            ))
        )*/

        //console.log(orderedCoords)

        let sectionCoords = []
        orderedCoords.forEach(coord => {
            if (coord.element === 'entity') {
                let label = this.props.activeSearchTerms.find(term => term.id === coord.queryId).label
                //console.log(this.props.activeSearchTerms)
                //console.log(coord.queryId)
                coord.label = label
            }
            if (coord.element === 'section' && !coord.sectionInfo.includes("applicant") && sectionCoords.filter(co => co.top === coord.top).length === 0) {
                sectionCoords = [...sectionCoords, coord]
            }
        })

        let orderedWithoutDuplicates = []

        orderedCoords.forEach(coord => {
            if ((coord.element === 'section' && !orderedWithoutDuplicates.some(co => co.element === 'section' && co.top === coord.top)) || (coord.element !== 'section' && !orderedWithoutDuplicates.some(co => co.top === coord.top && co.color === coord.color))) {
                orderedWithoutDuplicates = [...orderedWithoutDuplicates, coord]
            } else if ((coord.element !== 'section' && orderedWithoutDuplicates.some(co => co.top === coord.top && co.color === coord.color))) {
                let index = orderedWithoutDuplicates.findIndex(co => co.top === coord.top && co.color === coord.color)
                if (!orderedWithoutDuplicates[index]?.label?.includes(coord.label))
                    orderedWithoutDuplicates[index].label += `, ${coord.label}`
            }
        })

        sectionCoords.forEach((sec, i) => {
            if (i < sectionCoords.length - 1) {
                sec.height = ((sectionCoords[i + 1].top - sec.top) / (contextHeight / (height - 6))) - 3
                sec.counter = i
                sec.calcTop = (sec.top - 19) / (contextHeight / (height - 6))
                if (i > 0) {
                    if (sectionCoords[i - 1] !== undefined) {
                        sec.distance = sec.calcTop - sectionCoords[i - 1].calcTop
                    }
                }
            } else {
                sec.height = ((contextHeight - sec.top) / (contextHeight / (height)))
                sec.counter = i
                sec.calcTop = (sec.top - 19) / (contextHeight / (height - 6))
                if (sectionCoords[i - 1] !== undefined) {
                    sec.distance = sec.calcTop - sectionCoords[i - 1].calcTop
                }
            }
        })

        sectionCoords.filter(sec => sec.top > 0 && sec.height > 0).forEach((sec, i) => {
            if (sec.height < 0) {
                sec.height = 1
            }
            if (sec.distance && sec.distance < 16 && i > 0) {
                sec.margin = 2
            }
            if (i > 1) {
                if (sectionCoords[i - 1].distance && sectionCoords[i - 1].distance < 16 && sec.distance && sec.distance < 16) {
                    sec.margin = 10
                }
            }
            if (i > 2) {
                if (sectionCoords[i - 2].distance && sectionCoords[i - 2].distance < 16 && sectionCoords[i - 1].distance && sectionCoords[i - 1].distance < 16 && sec.distance && sec.distance < 16) {
                    sec.margin = 15
                }
            }
        })

        //console.log(_.uniqBy(this.props.activeSearchTerms, 'color').length)

        return (this.state.allImportantArePresent && this.props.activeSearchTerms.length > 0) ? <div className="customBar" id='customBar' style={{
            height: this.props.availableContentHeight ? height : '100%',
            width: `calc(11 * ${uniqueColors.length}px)`,
            //width: `calc(11 * ${this.props.activeSearchTerms.length}px)`,
            border: '1px solid #e7e7e7', backgroundColor: '#f4f4f4', position: 'fixed', right: 0,
            marginRight: 46, marginTop: 15
        }}>
            {orderedWithoutDuplicates && orderedWithoutDuplicates.length > 0 && orderedWithoutDuplicates.map((coord, i) => {
                if ((coord.element === "section" && !coord.sectionInfo.includes("applicant") && coord.top > 0 && coord.height > 0) || coord.element === "entity") {
                    return <div id={coord.element === "entity" ? `${coord.queryId}_${coord.top}` : coord.sectionInfo}
                        className={coord.element === "entity" ? "customBarContent" : "customBarContentBar"}
                        style={{
                            marginLeft: coord.element === "entity" ? `calc(${coord.colIndex * 11}px)` : - 8,
                            width: coord.element === "entity" ? 8 : 3,
                            height: coord.element === "entity" ? 3 : coord.height ? coord.height : 1,
                            backgroundColor: coord.element === "entity" ? coord.color : '#757575',
                            position: 'absolute',
                            top: `calc(${(coord.top - 19) / (contextHeight / (height - 6))}px)`,
                            cursor: coord.element === "entity" ? 'pointer' : 'pointer'
                        }}
                        onClick={() => { coord.element === "entity" ? this.showSectionInfo(coord.id) : this.showSectionInfo(coord.sectionInfo) }}
                        title={coord.element === "entity" && coord.label ? coord.label : ''}>
                        {coord.element === "section" ?
                            !coord.sectionInfo.includes("applicant") && coord.top > 0 && coord.height > 0 ?
                                <label id={coord.sectionInfo} title={(coord.sectionInfo.includes('biblio') ? 'Title' : coord.sectionInfo === 'ocTextTitle' ? 'Title' : coord.sectionInfo === 'ocTextAbstract' ? 'Abstract' :
                                    coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1)[0].toUpperCase() +
                                    (coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1).slice(1))).split(' ', 1)[0]} className="hideLabel"
                                    onClick={() => { this.showSectionInfo(coord.sectionInfo) }}
                                    style={{
                                        zIndex: 1000,
                                        maxWidth: 80, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                        marginLeft: -85, marginTop: coord.margin ? coord.margin : -3, color: '#0973c0', fontWeight: 500,
                                        position: 'absolute',
                                        cursor: 'pointer'
                                    }}>{(coord.sectionInfo.includes('biblio') ? 'Title' : coord.sectionInfo === 'ocTextTitle' ? 'Title' : coord.sectionInfo === 'ocTextAbstract' ? 'Abstract' : coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1)[0].toUpperCase() + (coord.sectionInfo.slice(coord.sectionInfo.indexOf('_') + 1).slice(1))).split(' ', 1)[0]}</label>
                                : null
                            : null
                        }
                    </div>
                }
            })
            }
            <div className="hideSection" style={{
                border: 'none',
                position: 'fixed',
                fontSize: 12, marginTop: 0, marginLeft: -101, height: this.props.availableContentHeight ? height : '100%',
                backgroundImage: "linear-gradient(to left, #fff 47%, rgba(255, 255, 255, 0.85) 0%)", width: 90
            }}>
            </div>
        </div>
            : null

    }
    showSectionInfo = (sectionInfo) => {
        //console.log('#' + sectionInfo)
        scrollToElementViaScrollToButton('#scrollToBtn', '#' + sectionInfo);
    }

    renderInfoOverlay = () => {
        return <>
            <AnnotationInfoOverlay
                annotationInfos={this.props.annotationInfos}
                loadAnnotationInfo={this.props.loadAnnotationInfo}
                onShowConceptInfo={this.props.onShowConceptInfo}
                //fetchingCompoundImage={this.state.fetchingCompoundImage}
                annTypeLabelsMap={this.props.annTypeLabelsMap ? this.props.annTypeLabelsMap : {}}
                domainColors={this.props.domainColors ? this.props.domainColors : {}}
                emptyMessage='No data found.'
                noImageMessage='No image.'
            />
            <span id="annotationID" className="displayNone"></span>
        </>
    }

    handleResize = () => {
        //console.log("resizee")
        //this.props.onLoading(true)
        this.updateText(this.props)
    }

    render() {
        const { repositoryInfo } = this.props;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner={true}
                    text="" >
                    <input type="hidden" id="selNE" name="selNE" value=""></input>
                    {this.renderDocument(repositoryInfo.name, this.props.renderDocumentOnly)}

                    <div className="displayNone">
                        <div id="annotations" />
                        <div id="conceptsData" />
                        <div id="activeAnnotations" />
                        <div id="openedSections" />
                    </div>
                </LoadingOverlay>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateSentenceDiv: (div) =>
            dispatch(setSentenceAnalysisSentences(div)),
        onSetSelectedSentence: (id) =>
            dispatch(setSelectedSentenceAnalysis(id)),
        onSetDocumentSentences: (documentIDs) =>
            dispatch(setDocumentSentencesSentenceAnalysis(documentIDs)),
        onSetActiveSearchTerms: (searchTerms) =>
            dispatch(setActiveSearchTermsSentenceAnalysis(searchTerms)),
        onCreateAnnotationCoordinates: (coords) =>
            dispatch(setAnnotationCoordinatesSentenceAnalysis(coords)),
        onCreateDocumentHTML: (docHTML) =>
            dispatch(setDocumentHTMLSentenceAnalysis(docHTML)),
        onLoading: (loader) =>
            dispatch(setLoaderSentenceAnalysis(loader)),
        onSetSearchLoaded: (loaded) => dispatch(setSearchLoadedSentenceAnalysis(loaded))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnnotatedText))
