import { Component } from 'react';
import { connect } from 'react-redux';
import Repositories from './Repositories';
import {Fieldset} from 'primereact/fieldset';


import {
    fetchAvailableRepositories,
} from '../../../../redux/actions'

/**
 * Management of Repositories
 */
class RepositoryManager extends Component {
  
  /*constructor(props) {
    super(props);
  }*/
    
  /**
   * initialise stuff, once the component is mounted
   * 
   * will fetch repositories and download the `environment.json` to tell the user where to put documents
   */
  componentDidMount() {
    this.props.fetchRepositories();
  }
  
  /**
   * render the component -> table of repositories
   */
  render() {
      
    return (
      <div id="repositoryManager">
        <h2>Repository Management</h2>
        <p>Here you find information on existing repositories.</p>
        <Fieldset legend="Available Repositories">
          <Repositories repositories={this.props.repositories} fetchRepositories={() => this.props.fetchRepositories ()}/>
        </Fieldset>
      </div>
    )
  }
    
}


const mapStateToProps = state => {
  return {
    repositories: state.managerReducer.repositories,
    repositoriesLoaded: state.managerReducer.repositoriesLoaded
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchRepositories: () => dispatch(fetchAvailableRepositories()),
  }
}


export default connect (
  mapStateToProps,
  mapDispatchToProps
) (RepositoryManager);
