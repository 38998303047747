// Importing necessary types for rectangles
import type { LTWH } from "../types.js";

// Function to calculate the bounding rectangle of an array of client rectangles
const getBoundingRect = (clientRects: Array<LTWH>): LTWH => {
  // Convert each client rectangle into a format with individual X and Y coordinates
  const rects = Array.from(clientRects).map((rect) => {
    const { left, top, width, height } = rect;

    const X0 = left;        // Left boundary (X0)
    const X1 = left + width; // Right boundary (X1)

    const Y0 = top;         // Top boundary (Y0)
    const Y1 = top + height; // Bottom boundary (Y1)

    return { X0, X1, Y0, Y1 };
  });

  // Find the optimal bounding rectangle that encompasses all individual rectangles
  const optimal = rects.reduce((res, rect) => {
    return {
      X0: Math.min(res.X0, rect.X0), // Update the left boundary
      X1: Math.max(res.X1, rect.X1), // Update the right boundary
      Y0: Math.min(res.Y0, rect.Y0), // Update the top boundary
      Y1: Math.max(res.Y1, rect.Y1), // Update the bottom boundary
    };
  }, rects[0]); // Initialize with the first rectangle

  // Extract the X and Y coordinates of the optimal bounding rectangle
  const { X0, X1, Y0, Y1 } = optimal;

  // Calculate and return the bounding rectangle object
  return {
    left: X0,
    top: Y0,
    width: X1 - X0,
    height: Y1 - Y0,
  };
};

export default getBoundingRect; // Export the bounding rectangle calculation function
