/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component, createRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
//import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dialog } from 'primereact/dialog';
import ConceptOptions from './ConceptOptions';
import { stopEventPropagation, addThousandsSeparatorToNumber } from '../util';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import ToastContent from '../../common/CustomToast/ToastContent';

import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'
import ConceptDetailsDialog from '../conceptdetails/ConceptDetailsDialog';

class CorrelationsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedResultTerms: {},
            selectedEntries: {},
            clickedEntry: null,
            displayCustomToast: false
        }

        // --- bind table cell templates --- //
        this.leftConceptTemplate = this.leftConceptTemplate.bind(this);
        this.rightConceptTemplate = this.rightConceptTemplate.bind(this);
        //this.sentencesTemplate = this.sentencesTemplate.bind(this);
        this.countTemplate = this.countTemplate.bind(this);

        this.toast = createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.correlationPairs !== prevProps.correlationPairs) {
            this.setState({
                selectedEntries: {}
            });
        }
    }

    onRowSelect = (pairs) => {

        //console.log('pairs: ', pairs);

        if (pairs.length > 0) {
            const pair = pairs.pop();
            this.props.onSelectPairs([pair]);
            this.props.onLoadSentences([pair.from], [pair.to], pair.fromLabel, pair.toLabel);
        }
    }

    onLoadSentencesForConcept = (ocidsLeft, ocidsRight, labelLeft, labelRight, concept) => {

        //console.log('concept: ', concept);

        const ocidsOther = concept.isLeft ? ocidsRight : ocidsLeft;
        this.props.onSelectConcept(concept, concept.isLeft, ocidsOther);
        this.props.onLoadSentences(ocidsLeft, ocidsRight, labelLeft, labelRight);
    }

    // --- concept options --- //
    updateSelectedConcepts = (selectedConcepts) => {
        this.setState({
            selectedEntries: selectedConcepts
        });
    }

    onShowConceptDetails = () => {
        this.setState({
            visibleConceptInfo: true
        });
    }

    showConceptInfo = (ocid) => {

        const clickedEntry = { ocid: ocid };
        this.setState({
            visibleConceptInfo: true,
            clickedEntry: clickedEntry
        })
    }

    hideConceptOptions = (event) => {
        this.op.hide(event);
    }

    onResultTermSelect = (resultTerm, event) => {

        const selectedResultTerms = { ...this.state.selectedResultTerms };
        if (selectedResultTerms[resultTerm.ocid]) {
            delete selectedResultTerms[resultTerm.ocid];
        }
        else {
            selectedResultTerms[resultTerm.ocid] = resultTerm;
        }

        // --- update content of toast for current result term selections --- //
        this.updateOptionsToast(selectedResultTerms);

        // --- update state with selected result terms and show options toast if neccessary --- //
        this.setState({
            selectedResultTerms: selectedResultTerms
        });
    }


    // ----------------------------------------------------------------------- //
    // --- options for selected result terms --------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * Updates toast content with options for current selection of documents.
     */
    updateOptionsToast = (selectedResultTerms) => {

        const numOfSelectedResultTerms = selectedResultTerms ? Object.keys(selectedResultTerms).length : 0;

        if (numOfSelectedResultTerms === 0) {
            this.setState({
                displayCustomToast: false
            })
        } else if (numOfSelectedResultTerms !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
    }

    /**
     * Closes toast and clears selected result terms.
     */
    rejectSelectedResultTerms = () => {
        this.setState({
            selectedResultTerms: {},
            displayCustomToast: false
        });
    }

    /**
     * Table cell templates.
     */
    conceptTemplate(resultTerm) {
        return (
            <div title="Load sentences containing correlations including synonyms, but without ontological children">
                <div style={{ display: 'inline-block', padding: 0 }}
                    className="col coocResultTerm clampingContainer line-clamp line-clamp-1-line">
                    <a onClick={(e) => { stopEventPropagation(e); }}>
                        <Checkbox
                            title={`Click to select '${resultTerm.label}'`}
                            className="showOnHover1"
                            style={{ marginRight: 10 }}
                            onChange={e => { this.onResultTermSelect(resultTerm, e); }}
                            checked={!!this.state.selectedResultTerms[resultTerm.ocid]}>
                        </Checkbox>
                    </a>
                    <a onClick={(e) => {
                        stopEventPropagation(e);
                        this.setState({ clickedEntry: resultTerm });
                        this.op.toggle(e);
                    }}>
                        <span title={`Click for options | '${resultTerm.label}'`}>{resultTerm.label}</span>
                    </a>
                </div>
            </div>
        );
    }

    /**
     * Table cell templates.
     */
    leftConceptTemplate(rowData, column) {

        const resultTerm = {
            ocid: rowData.from,
            label: rowData.fromLabel,
            prefname: rowData.fromPrefname,
            domain: rowData.fromDomain,
            isLeft: true
        };

        return this.conceptTemplate(resultTerm);
    }

    rightConceptTemplate(rowData, column) {

        const resultTerm = {
            ocid: rowData.to,
            label: rowData.toLabel,
            prefname: rowData.toPrefname,
            domain: rowData.fromDomain,
            isLeft: false
        };

        return this.conceptTemplate(resultTerm);
    }

    countTemplate(rowData, column) {

        return (
            <div title="Load sentences containing correlations including synonyms, but without ontological children">
                {addThousandsSeparatorToNumber(rowData.value)}
            </div>
        );
    }



    onPaging = (event, fromPaginator) => {
        this.props.onNumOfRowsChange(fromPaginator ? event.first : this.props.first, fromPaginator ? event.rows : event.value);
    }


    render() {
        //console.log('children: ', this.props.sentences);

        //const { correlationPairs, ocidDataMap } = this.props;
        const { correlationPairs, queryTermsLabelLeft, queryTermsLabelRight } = this.props;
        const totalRecords = correlationPairs ? correlationPairs.length : 0;

        const isDiseaseTarget = false; //this.props.view.id === 'disease_targets'; // ???

        const headerGroup = <ColumnGroup>
            <Row>
                {/*<Column header="Correlations of" colSpan={4} />*/}
                {/*<Column header="Matches (without children)" colSpan={2} />*/}
                {/*<Column header="Sources" colSpan={1} />*/}
                {/*isDiseaseTarget && <Column header="Gene infos" colSpan={3} style={{}} />*/}
            </Row>
            <Row>
                {/* entity 1 */}
                {!isDiseaseTarget && <Column field="fromLabel" header={queryTermsLabelLeft} sortable sortField="fromLabel" filter filterField="fromLabel" />}
                {isDiseaseTarget && <Column field="fromLabel" header="Disease" sortable sortField="fromLabel" filter filterField="fromLabel" />}
                {/* cooc count */}
                <Column field="value" header="Count" sortable />
                {/* entity 2 */}
                {!isDiseaseTarget && <Column field="toLabel" header={queryTermsLabelRight} sortable sortField="toLabel" filter filterField="toLabel" />}
                {isDiseaseTarget && <Column field="toLabel" header="Gene" sortable sortField="toLabel" filter filterField="toLabel" />}
                {/* sentences */}
                {/*<Column header="Sentences" />*/}
                {/* disease - target only */}
                {/* 
                {isDiseaseTarget && <Column field="value1" header="Claims count" sortable style={{}} />}
                {isDiseaseTarget && <Column field="value2" header="CT count" sortable />}
                {isDiseaseTarget && <Column field="value3" header="GO terms" sortable />}
               */}
            </Row>
        </ColumnGroup>;

        const columns = [
            <Column key="fromLabel" field="fromLabel" body={this.leftConceptTemplate} />,
            <Column key="value" field="value" body={this.countTemplate} style={{ minWidth: "140px" }} />,
            <Column key="toLabel" field="toLabel" body={this.rightConceptTemplate} />,
        ];

        return (
            <>
                <Toast
                    ref={this.toast}
                    className="toast"
                    style={{ minWidth: 550 }}
                    position="bottom-center"
                    closable="true"
                />

                <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                    onHide={this.rejectSelectedResultTerms} style={{ width: '29rem', bottom: 25 }}
                    draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                    <ToastContent selectedElements={this.state.selectedResultTerms}
                        elementLabel='concept'
                        onHide={this.rejectSelectedResultTerms}
                        activeOptions={['blockConcept', 'addToBlocklist']}
                        onBlock={() => this.props.onExcludeConcepts(this.state.selectedResultTerms)}
                        onAddToBlocklist={() => this.props.onAddToBlacklists(this.state.selectedResultTerms)} />
                </Dialog>

                <DataTable
                    className="dataTable highlightSelectedRows breakWord increasedLineHeight standardTableHeader textAlignLeft"
                    sortField="value"
                    sortOrder={-1}
                    value={correlationPairs}
                    headerColumnGroup={headerGroup}
                    autolayout='true'
                    paginator={true}
                    paginatorPosition="bottom"
                    first={this.props.first}
                    rows={this.props.rows}
                    onPage={(e) => this.onPaging(e, true)}
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    totalRecords={totalRecords}
                    //lazy={true}
                    /*
                    selectionMode="single"
                    selection={this.props.selectedPair}
                    onSelectionChange={e => this.onRowSelect(e.value)}
                    */
                    selectionMode="multiple"
                    selection={this.props.selectedPairs}
                    onSelectionChange={e => this.onRowSelect(e.value)}
                    dataKey="key"
                    //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    //currentPageReportTemplate="{totalRecords} entries"
                    paginatorTemplate={CustomPaginatorTemplate}
                    paginatorLeft={<CustomPaginatorLeftSide
                        first={this.props.first}
                        contentLength={correlationPairs.length}
                        rows={this.props.rows}>
                    </CustomPaginatorLeftSide>}
                    paginatorRight={<CustomPaginatorRightSide
                        rows={this.props.rows}
                        onChange={(e) => this.onPaging(e, false)}
                    ></CustomPaginatorRightSide>}
                >
                    {columns}
                </DataTable>

                <OverlayPanel ref={(el) => this.op = el}
                    className="textAlignLeft"
                    style={{ marginTop: 25, maxWidth: 600 }}>
                    <ConceptOptions
                        concept={this.state.clickedEntry}
                        queryTermsLeft={this.props.queryTermsLeft}
                        queryTermsRight={this.props.queryTermsRight}
                        onLoadSentences={this.onLoadSentencesForConcept}
                        selectedConcepts={this.state.selectedEntries}
                        updateSelectedConcepts={this.updateSelectedConcepts}
                        onShowConceptDetails={this.onShowConceptDetails}
                        onReplaceQueryTerms={this.props.onReplaceQueryTerms}
                        hideConceptOptions={this.hideConceptOptions}

                        onExcludeConcepts={this.props.onExcludeConcepts}
                        onAddToBlacklists={this.props.onAddToBlacklists}
                        tempExcludedTerms={this.props.tempExcludedTerms}

                        queryTermsLabelLeft={this.props.queryTermsLabelLeft}
                        queryTermsLabelRight={this.props.queryTermsLabelRight}
                        resultTermListLeft={this.props.resultTermListLeft}
                        resultTermListRight={this.props.resultTermListRight}
                    />
                </OverlayPanel>

                <ConceptDetailsDialog
                    visible={this.state.visibleConceptInfo}
                    includeQuickSearchShortcut={true}
                    includeChemSearchShortcut={true}
                    includeCoocSearchShortcut={false}
                    ocid={this.state.clickedEntry?.ocid}
                    onConceptClick={this.showConceptInfo}
                    onHide={() => this.setState({ visibleConceptInfo: false })}
                />
            </>
        );
    }
}

export default CorrelationsTable;