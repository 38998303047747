/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { APP_TITLE } from "../core/constants";


const AppGroupItem = ({
    handleExportChange,
    category,
    selectedCategory,
    style
}) => {
    return (
        <div key={category.title}
            className={`col-12 pointer ${category?.title !== APP_TITLE ?
                'ex-gr-container ' : 'ex-all-item'} 
        ${category.title === selectedCategory ? ' selected-item' : ''}`}
            onClick={(e) => handleExportChange(e, category?.title)} style={style} >
            <div className='ex-gr-item'>
                <a title={category?.title}
                >{category?.title}</a>

                <div className='float-right ex-gr-item-count'>
                    {category?.count}
                </div>
            </div>
            <div className='col-12 ex-gr-item-second' >
                {
                    category?.description &&
                    <label >  {category?.description} </label>
                }
                {
                    category?.lastModifiedDate &&
                    <label >{" Last modified: " + moment(category.lastModifiedDate + "Z").format("YYYY-MM-DD")}  </label>
                }
            </div>
        </div>
    );
}

export default AppGroupItem