import React, { Component } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';


class SampleText extends Component {

    render() {

        const { sampleText, onSampleTextChange, onSampleTextClear, onButtonClick, buttonLabel, contentBoxHeight } = this.props;

        return (
            <div className="task-box">
                <div className="task-box-header" //style={{ padding: '8px' }}
                >
                    <span>Sample text</span>
                    <Button label="Clear"
                        className="p-button-text dangerButtonLink"
                        title="Clear sample"
                        onClick={onSampleTextClear}
                        style={{ float: 'right' }}
                    />
                    {/*
                    <i className="material-icons"
                        title="Clear sample"
                        onClick={onSampleTextClear}
                        style={{ cursor: 'pointer', marginLeft: 10 }}>
                        delete_forever
                    </i>
                    <i className="material-icons"
                        title="Save sample"
                        //onClick={onSampleTextClear}
                        style={{ cursor: 'pointer' }}>
                        save
                   </i>*/}
                </div>
                <div className="task-box-content"
                    style={{ padding: '10px', height: contentBoxHeight, position: 'relative', border: '1px solid #ddd' }}>

                    <div style={{ width: '100%', minHeight: 'calc(100% - 35px)', height: 'calc(100% - 35px)', border: 'none', overflow: 'auto' }}>
                        <InputTextarea
                            value={sampleText}
                            onChange={(e) => onSampleTextChange(e.target.value)}
                            autoResize={true}
                            style={{ width: '100%', minHeight: 'calc(100% - 5px)', height: 'calc(100% - 5px)', border: 'none', backgroundImage: 'none' }}
                            placeholder="Type away!" />
                    </div>

                    {buttonLabel ? <Button label={buttonLabel}
                        className="orange-btn buttonMedium"
                        icon="pi-md-send"
                        style={{ position: 'absolute', bottom: 10, right: 5 }}
                        onClick={() => onButtonClick()}
                        disabled={!sampleText}>
                    </Button> : null
                    }

                </div>
            </div>
        );
    }
}

export default SampleText;