import axios from 'axios';
import dateFormat from 'dateformat';
import $ from 'jquery';
import _, { isNumber } from 'lodash';
import moment from 'moment-timezone';
import { getHeaderToken } from '../../api/index';
import { APP_PROPERTIES } from '../../properties/index';

window.Buffer = window.Buffer || require("buffer").Buffer;

/**
 * Returns the frontend URL with / at the end of the URL.
 */
export const getFrontendUrl = () => {
    return APP_PROPERTIES.FRONTEND_URL;
}

/**
 * Checks if user has given role by checking the token from the local storage.
 * 
 * @param {string} role 
 * @returns 
 */
export const hasUserRole = (role) => {
    if (localStorage.token) {
        const tokenParts = localStorage.token.split(".");
        const tokenPayloadString = atob(tokenParts[1])
        return tokenPayloadString.includes(`"${role}"`);
    }
    return false;
}

/**
 * Rounds a number to a given number of decimal places.
 * 
 * @param {Number} num 
 * @param {*} roundingPrecision decimal places
 */
export const roundNumber = (num, roundingPrecision = 2) => {
    return _.round(num, roundingPrecision);
}

/**
 * Maps number from one range to another range.
 * 
 * @param {Number} num 
 * @param {Number} in_min 
 * @param {Number} in_max 
 * @param {Number} out_min 
 * @param {Number} out_max 
 * @returns mapped value as floating point number
 */
export const scale = (num, in_min, in_max, out_min, out_max) => {

    if (in_min === in_max) {
        return (out_max - out_min) / 2;
    }
    const scaled = (((num - in_min) * (out_max - out_min)) / (in_max - in_min)) + out_min;
    //console.log('scaled: ', scaled);
    return scaled;
}

/**
 * Converts initial letter of given text to upper case.
 */
export const convertInitialLetterToUpperCase = (text) => {
    return !!text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
}

/**
 * Escapes characters that are special characters in regular expressions.
 * 
 * @param {string} string 
 */
export const escapeRegExCharacters = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Recursively clones value.
 * 
 * @param {*} value to clone 
 */
export const cloneDeep = (value) => {
    return _.cloneDeep(value);
}

/**
 * Sorts map entries by keys.
 * 
 * @param {Object} map to be sorted by its keys
 */
export const sortMapByKeys = (map) => {
    let ordered = {};
    _(map).keys().sort().each(function (key) {
        ordered[key] = map[key];
    });
    return ordered;
}

/**
 * Sorts map entries by attributes.
 * 
 * @param {Object} map to be sorted by specific attribute
 */
export const sortObjectMapByAttribute = (map, attrName, ascending = true) => {

    return map ? sortObjectArrayByAttribute(Object.values(map), attrName, ascending) : [];
}

/**
 * Sorts array of objects by given attribute.
 * 
 * @param {Array} array to be sorted 
 * @param {string} attrName name of the attribute the object array will be sorted by
 * @param {Boolean} ascending if true sort ascending, otherwise descending
 */
export const sortObjectArrayByAttribute = (array, attrName, ascending = true, ignoreCase = false) => {

    let ordered = [...array];
    if (ascending) {
        ordered.sort((a, b) => {
            if (!a[attrName] && a[attrName] !== 0) return 1;
            if (!b[attrName] && b[attrName] !== 0) return -1;
            if (ignoreCase) {
                if (isNumber(a[attrName]) && isNumber(b[attrName])) {
                    return (a[attrName] > b[attrName]) ? 1 : -1;
                }
                return (a[attrName].toLowerCase() > b[attrName].toLowerCase()) ? 1 : -1;
            }
            else
                return (a[attrName] > b[attrName]) ? 1 : -1;
        });
    }
    else {
        ordered.sort((a, b) => {
            if (!a[attrName] && a[attrName] !== 0) return 1;
            if (!b[attrName] && b[attrName] !== 0) return -1;
            if (ignoreCase) {
                if (isNumber(a[attrName]) && isNumber(b[attrName])) {
                    return (a[attrName] < b[attrName]) ? 1 : -1;
                }
                return (a[attrName].toLowerCase() < b[attrName].toLowerCase()) ? 1 : -1;
            }
            else
                return (a[attrName] < b[attrName]) ? 1 : -1;
        });
    }
    return ordered;
}

/**
 * Merges two arrays without producing duplicates.
 * 
 * @param {Array} array1 
 * @param {Array} array2 
 */
export const mergeArraysWithoutDuplicates = (array1, array2) => {
    return _.union(array1, array2);
}

/**
 * Removes duplicate values from array.
 * 
 * @param {Array} array 
 */
export const deduplicateArrayValues = (array) => {
    return _.uniq(array);
}

/**
 * Removes value from a given array.
 * 
 * @param {Array} array original array of values or objects
 * @param {*} valueToRemove value or object that should be removed
 */
export const removeValueFromArray = (array, valueToRemove) => {
    return _.pull(array, valueToRemove);
}

/**
 * Checks how many times an array contains an object with an attribute having a certain value.
 * 
 * @param {Array} array original array of objects
 * @param {*} attribute attribute that should be checked for value
 * @param {*} value value that needs to match
 */
export const getNumberOfObjectsWithValueInArray = (array, attribute, value) => {
    var count = 0;
    for (var arrObject of array) {
        if (arrObject[attribute] === value) {
            count++;
        }
    }
    return count;
}

/**
 * Removes object with certain attribute from a given array.
 * 
 * @param {Array} array original array of objects
 * @param {*} attribute value or object that should be removed
 * @param {*} value value or object that should be removed
 */
/*
export const removeObjectWithValueFromArray = (array, attribute, value) => {
    const removeFromArray = [];
    for (var arrObject of array) {
        if (arrObject[attribute] === value) {
            removeFromArray.push(arrObject);
        }
    }
    return removeArrayOfValuesFromArray(array, removeFromArray);
}
*/

/**
 * Removes values stored in an array from a given array.
 * 
 * @param {Array} array original array of values or objects
 * @param {Array} arrayToRemove array containing values or objects that should be removed
 */
export const removeArrayOfValuesFromArray = (array, arrayToRemove) => {
    return _.pullAll(array, arrayToRemove);
}

/**
 * Replaces a value in an array with a new one. Old value has to be the exact object.
 * @param {*} array 
 * @param {*} valueOld 
 * @param {*} valueNew 
 */
export const replaceValueInArray = (array, valueOld, valueNew) => {
    var index = array.indexOf(valueOld);
    if (index !== -1) {
        array[index] = valueNew;
    }
    return array;
}

/**
 * Checks if target array contains all values of source array.
 * 
 * @param {Array} source 
 * @param {Array} target 
 */
export const isArraySubset = (source, target) => target.every(v => source.includes(v));

/**
 * Returns array of values found in both source arrays. If values are objects, the attribute to compare 
 * must be defined.
 * @param {Array} arr1 array 1
 * @param {Array} arr2 array 2
 * @param {string} attr attribute to compare objects by
 * @returns array of values found in both source arrays
 */
export const getIntersectionArray = (arr1, arr2, attr = null) => {
    if (arr1 && arr2) {
        if (attr) {
            return _.intersectionBy(arr1, arr2, attr);
        }
        else {
            return _.intersectionBy(arr1, arr2);
        }
    }
}


/**
 * Returns true if array is undefined or empty.
 * @param {Array} arr 
 * @returns 
 */
export const isArrayEmpty = (arr) => {
    return !arr || arr.length === 0;
}

/**
 * Returns true if object is undefined or empty.
 * @param {Object} arr 
 * @returns 
 */
export const isObjectEmpty = (obj) => {
    return !obj || Object.keys(obj).length === 0;
}



/**
 * Removes all keys and their values from the map which are present in the array.
 * 
 * @param {Object} map map containing key-value pairs 
 * @param {Array} arrayToRemove array containing the keys which should be removed from the map
 */
export const removeKeysFromMap = (map, arrayToRemove) => {

    if (map) {
        for (var key of arrayToRemove) {
            delete map[key];
        }
    }
    return map;
}

/**
 * Removes all keys and their values from the map if the value is undefined.
 * 
 * @param {Object} map map containing key-value pairs 
 */
export const removeUndefinedEntriesFromMap = (map) => {

    if (map) {
        for (var key in map) {
            if (!map[key]) {
                delete map[key];
            }
        }
    }
    return map;
}

/**
 * Retains all keys (and their value) in the map which are present in the array.
 * 
 * @param {Object} map map containing key-value pairs 
 * @param {Array} arrayToRetain array containing the keys which should be retained in map
 */
export const retainKeysInMap = (map, arrayToRetain) => {

    const newMap = {};
    for (var key of arrayToRetain) {
        newMap[key] = map[key];
    }
    return newMap;
}

/**
 * Adds a decimal seperator to a number.
 * 
 * @param {Number} number original number
 * @param {string} separator the character that will be used as separator, default = ','
 */
export const addThousandsSeparatorToNumber = (number, separator = ",") => {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator) : number;
}

/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 *
 * @param {number} num Number to shorten.
 * @param {number} [digits=0] The number of digits to appear after the decimal point.
 * @returns {string|number}
 *
 * @example
 * // returns '12.5k'
 * shortenLargeNumber(12543, 1)
 *
 * @example
 * // returns '-13k'
 * shortenLargeNumber(-12567)
 *
 * @example
 * // returns '51M'
 * shortenLargeNumber(51000000)
 *
 * @example
 * // returns 651
 * shortenLargeNumber(651)
 *
 * @example
 * // returns 0.12345
 * shortenLargeNumber(0.12345)
 */
export const shortenLargeNumber = (num, digits=0) => {
    //console.log('num: ', num);
    var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
        decimal;

    for (var i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);

        if (Math.abs(num) >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }
    //console.log('return num: ', num);
    return num;
}


export const roundNumberUpToNextLevel = (
  num = 0,
  start = 10,
  end = 10000000000,
  steps = 5
) => {
  for (let limit = start; limit < end; limit *= steps) {
    if (num <= limit) {
      return limit;
    }
  }
  return num;
};


/**
 * Performs deep diff between two objects.
 * 
 * @param  {Object} object object compared
 * @param  {Object} base object to compare with
 * @returns {Object} new object which represents the diff 
 */
export const diffObjects = (object, base) => {
    function changes(object, base) {
        return _.transform(object, function (result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

export const GOOGLE_SMILES_QUERY_TYPE_EXACT = 'SMILES';
export const GOOGLE_SMILES_QUERY_TYPE_SUBSTRUCTURE = 'SSS';
export const GOOGLE_SMILES_QUERY_TYPE_SIMILARITY = '~SMILES';

export const GOOGLE_SMILES_SEARCH_MODE_NPL = 'NPL';
export const GOOGLE_SMILES_SEARCH_MODE_PATENTS = 'PATENTS';
export const GOOGLE_SMILES_SEARCH_MODE_BOTH = 'BOTH';

export const createGoogleQueryForSmilesSearch = (queryType, smiles) => {

    return encodeURIComponent(`(${queryType}=${smiles})`);
}

export const createGoogleSearchUrl = (queryType, smiles, searchMode) => {

    const baseUrl = 'https://patents.google.com/';
    const query = createGoogleQueryForSmilesSearch(queryType, smiles);

    switch (searchMode) {

        case GOOGLE_SMILES_SEARCH_MODE_PATENTS: {
            return `${baseUrl}?q=${query}&oq=${query}`;
        }

        case GOOGLE_SMILES_SEARCH_MODE_NPL: {
            return `${baseUrl}?scholar&patents=false&q=${query}&oq=${query}`;
        }

        case GOOGLE_SMILES_SEARCH_MODE_BOTH: {
            return `${baseUrl}?scholar&q=${query}&oq=${query}`;
        }
        default:
            console.log(searchMode)
    }
}

/**
 * Checks if ULR is external link.
 * 
 * @param {string} url 
 */
export const isExternalURL = (url) => {
    // --- check if URL starts with http or https --- //
    return (url?.startsWith('http://') || url?.startsWith('https://'));
}

/**
 * Creates document view URL for given repository ID and OC document ID 
 * as well as internal query used for highlighting (optional).
 *
 * @param {string} repID repository ID
 * @param {string} ocDocID OC document ID 
 * @param {Object} docViewData object containing additional data which will be stored for this document, e.g. { query: QUERY_STRING, sequenceID: SEQUENCE_ID } 
 */
export const createDocViewUrl = (repID, ocDocID, docViewData) => {
    // --- URL for document view --- //
    let docViewUrl = `${APP_PROPERTIES.FRONTEND_URL}docview`;
    if (repID && ocDocID) {
        // --- add repository ID and OC document ID to URL --- //
        docViewUrl += `/${repID}/${ocDocID}`;

        // --- add additional data to local storage --- //
        //addQueryToLocalStorage(repID, ocDocID, intQry);
        addDocViewDataToLocalStorage(repID, ocDocID, docViewData);
    }
    return docViewUrl;
}

/**
 * Creates an identifier for a certain repository document ID combination. 
 * Used for storage of the highlighting query in the local storage.
 * 
 * @param {string} repID 
 * @param {string} ocDocID 
 */
export const createDocViewKey = (repID, ocDocID) => {
    return `${repID}:${ocDocID}`;
}

/**
 * Adds doc view data to local storage.
 * 
 * @param {string} repID 
 * @param {string} ocDocID 
 * @param {string} data additional data which will be stored for specific document 
 */
export const addDocViewDataToLocalStorage = (repID, ocDocID, data) => {
    if (data) {
        // --- get queries from local storage --- //
        let docViewDataStrg = localStorage.getItem('docViewData');
        let docViewData = docViewDataStrg ? JSON.parse(docViewDataStrg) : {};
        //console.log('docViewData: ', docViewData);

        // --- add doc view data to local storage --- //
        const key = createDocViewKey(repID, ocDocID);
        docViewData[key] = data;

        // --- update doc view data in local storage --- //
        docViewDataStrg = JSON.stringify(docViewData);
        localStorage.setItem('docViewData', docViewDataStrg);
    }
}

/**
 * Removes query from local storage.
 * 
 * @param {string} repID 
 * @param {string} ocDocID 
 */
export const removeDocViewDataFromLocalStorage = (repID, ocDocID) => {

    // --- get doc view data from local storage --- //
    let docViewDataStrg = localStorage.getItem('docViewData');
    let docViewData = docViewDataStrg ? JSON.parse(docViewDataStrg) : {};
    //console.log('docViewData: ', docViewData);

    // --- delete doc view data from local storage --- //
    const key = createDocViewKey(repID, ocDocID);
    delete docViewData[key];

    // --- update doc view data in local storage --- //
    docViewDataStrg = JSON.stringify(docViewData);
    localStorage.setItem('docViewData', docViewDataStrg);
}

/**
 * Returns doc view data from local storage.
 * 
 * @param {string} repID 
 * @param {string} ocDocID 
 */
export const getDocViewDataFromLocalStorage = (repID, ocDocID) => {

    // --- get doc view data from local storage --- //
    let docViewDataStrg = localStorage.getItem('docViewData');
    let docViewData = docViewDataStrg ? JSON.parse(docViewDataStrg) : {};
    //console.log('docViewData: ', docViewData);

    // --- return doc view data from local storage --- //
    const key = createDocViewKey(repID, ocDocID);
    return docViewData[key];
}

/**
 * Sorts map by domain labels. Map contains domain IDs as keys
 * and arrays of concept objects as values.
 * 
 * @param {Object} map 
 */
export const sortDomainsToConceptsMapByDomainLabels = (map, domainsMap = {}) => {

    //console.log('domainsMap: ', domainsMap);
    const mapSorted = {};
    if (map) {
        Object.keys(map).sort(function (a, b) {
            var x = !!domainsMap[a] ? domainsMap[a].toLowerCase() : a;
            var y = !!domainsMap[b] ? domainsMap[b].toLowerCase() : b;
            return x < y ? -1 : x > y ? 1 : 0;
        }).forEach(function (key) {
            mapSorted[key] = map[key];
        });
    }

    return mapSorted;
}

// TODO
/**
 * 
 * @param {*} term 
 * @param {*} removeSpaces 
 */
export const normalizeConceptTerm = (term, removeSpaces = false) => {

    let termNormalized = term;
    if (termNormalized) {
        if (removeSpaces) {
            termNormalized = termNormalized.replace(/\s+/g, '');
            termNormalized = termNormalized.replace(/-/g, '');
        }
        termNormalized = termNormalized.toLowerCase();
    }

    return termNormalized;
}

/**
 * 
 * @param {*} terms 
 */
export const deduplicateTerms = (terms) => {

    return _.uniq(terms);
}

/**
 * 
 * @param {*} completeText 
 * @param {*} textToFind 
 */
export const findMatchIgnoreCase = (completeText, textToFind) => {
    if (completeText && textToFind) {
        var regExp = new RegExp(textToFind.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i");
        var result = completeText.match(regExp);
        if (result && result[0]) {
            return result[0];
        }
    }

    return null;
}

/** 
 * Replaces '\', '"' and ''' by their escape sequences '\\', '\"', '\''. 
 */
export const escapeQuotesAndBackslashes = function (text) {
    var len = text ? text.length : 0;
    var escTxt = "";
    for (var tIdx = 0; tIdx < len; tIdx++) {
        var ch = text.charAt(tIdx);
        switch (ch) {
            case '\\':
                escTxt += '\\\\';
                break;
            case '\'':
                escTxt += '\\\'';
                break;
            case '"':
                escTxt += '\\"';
                break;
            default:
                escTxt += ch;
        }
    }
    return escTxt;
}

/**
 * Removes html tags from string and returns text string.
 * 
 * @param {string} html text with html tags
 */
export const removeHtmlTagsFromText = (html) => {
    return $('<div>').html(html).text();
}

/**
 * For testing. Triggers sleep function for given number of milliseconds.
 * 
 * @param {Number} milliseconds number of milliseconds to wait
 */
export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Prevents the triggering of events in outer elements
 * when inner element is clicked.
 * 
 * @param {Event} event 
 */
export const stopEventPropagation = (event) => {
    event.cancelBubble = true;
    if (event.stopPropagation) {
        event.stopPropagation();
    }
}

/**
 * Returns the number of milliseconds left in a session 
 * extracted from the user session token.
 *
 * @param {string} token - user session token
 */
export const millisecondsLeftInSession = (token) => {

    if (token) {
        // --- split token into different parts --- //
        const tokenArray = token.split('.');

        // --- second part contains expiration time --- //
        if (tokenArray.length > 1) {
            // --- decode base64 encoded content part of token --- //
            const decodedString = atob(tokenArray[1]);
            const json = JSON.parse(decodedString);

            if (json && json.exp) {
                // --- compute minutes left --- //
                const dateExp = new Date(json.exp * 1000).getTime();
                const dateNow = new Date().getTime();
                const msLeft = dateExp - dateNow;
                return msLeft;
            }
        }
    }

    return 0;
}

/**
 * Converts milliseconds to minutes.
 * 
 * @param {Number} timeInMs 
 */
export const convertMillisecondsToMinutes = (timeInMs) => {
    let minutes = timeInMs / 60000;
    minutes = `${minutes}`.split('.')[0];

    return minutes;
}

const DEFAULT_DATE_AND_TIME_FORMAT = 'yyyy-mm-dd HH:MM';
const MOMENTS_DEFAULT_DATE_AND_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
const MOMENTS_SERVER_TIMEZONE = 'Europe/Berlin';

// TODO: We need to add tests for all dates from the
// middleware and convert them as soon as they are fetched. 
// We need to agree on a normalized database timestamp,
// e.g. UTC or milliseconds and format dates in the FE as needed.

/**
 * Converts given date string into local time.
 * @param {string} dateString a date string with timezone Europe/Berlin
 * @returns date string converted to local time
 */
export const convertServerTimeToLocalTime = (dateString) => {
    return moment
    .tz(dateString, MOMENTS_SERVER_TIMEZONE)
    .local()
    .format(MOMENTS_DEFAULT_DATE_AND_TIME_FORMAT);
}

/**
 * Converts given date string into format: yyyy-mm-dd HH:MM.
 * @param {string} dateString a date string
 * @returns string in the format yyyy-mm-dd HH:MM
 */
export const getDateAndTime = (dateString) => {
    return dateFormat(dateString, DEFAULT_DATE_AND_TIME_FORMAT);
}

/**
 * Returns current time in the format: yyyy-mm-dd HH:MM.
 * @returns string of current time in the format yyyy-mm-dd HH:MM
 */
export const getCurrentDateAndTime = () => {
    return dateFormat(new Date(), DEFAULT_DATE_AND_TIME_FORMAT);
}


/**
 * Returns current year.
 * @returns 
 */
export const getCurrentYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    return currentYear;
}

/**
 * Extracts year from date string. 
 * 
 * @param {string} dateString 
 * @returns 
 */
export const getYearFromDate = (dateString) => {

    return new Date(dateString).getFullYear();
}

/**
 * Extracts month name from date string. 
 * 
 * @param {string} dateString 
 * @returns 
 */
export const getMonthNameFromDate = (dateString) => {

    if (dateString && dateString.match(/[1-2][0-9]{3}-[0-9]{2}(-[0-9]{0,2})?/g)) {
        var month = dateFormat(new Date(dateString), "mmmm");
        return month;
    }
    return null;
}

/**
 * Extracts day and month from date string. 
 * 
 * @param {string} dateString 
 * @returns 
 */
export const getDayAndMonthFromDate = (dateString) => {

    var dayAndMonth = dateFormat(new Date(dateString), "d mmm");
    return dayAndMonth;
}

/**
 * Returns year range from number of years back until current year.
 * Format: YYYY_CURRENT_MINUS_NUM_OF_YEARS:YYYY_CURRENT, 
 * e.g. 1921:2021 (if the current year is 2021 and numberOfYears is 100)
 * 
 * @param {Number} years number of years in the range
 */
export const getYearRange = (numberOfYears) => {

    const today = new Date();
    const end = today.getFullYear();
    const start = end - numberOfYears;
    return `${start}:${end}`;
}

/**
 * Returns year range from given year until current year.
 * Format: YYYY_START_YEAR:YYYY_CURRENT, 
 * e.g. 1999:2021 (if the current year is 2021 and startYear is 1999)
 * 
 * @param {Number} startYear start year
 */
export const getYearRangeWithStartYear = (startYear) => {

    const today = new Date();
    const end = today.getFullYear();
    return `${startYear}:${end}`;
}

/**
 * Returns year range from given year until year in future.
 * Format: YYYY_CURRENT_YEAR:YYYY_FUTURE, 
 * e.g. 2021:2031 (if the current year is 2021 and future is 10)
 * 
 * @param {Number} years in future
 */
export const getYearRangeFuture = (future) => {

    const today = new Date();
    const start = today.getFullYear();
    const end = start + future;
    return `${start}:${end}`;
}

/**
 * Returns year range from year in past until year in future.
 * Format: YYYY_PAST:YYYY_FUTURE, 
 * e.g. 2001:2031 (if the current year is 2021, past is 20 and future is 10)
 * 
 * @param {Number} years in past
 * @param {Number} years in future
 */
export const getYearRangePastAndFuture = (past, future) => {

    const today = new Date();
    const start = today.getFullYear() - past;
    const end = today.getFullYear() + future;
    return `${start}:${end}`;
}

/**
 * Returns number of atoms found in smiles string. 
 * May not be exact for structures containing rings, 
 * but is close enough to determine image size for example.
 * 
 * @param {string} smiles 
 * @returns 
 */
export const countAtomsInSmiles = (smiles) => {

    const regex = /[A-Z][a-z]?/g;
    return ((smiles || '').match(regex) || []).length;
}

/**
 * Computes the size of a structure image, given the number of atoms of a molecule.
 * @param {Number} numOfAtoms 
 * @returns 
 */
export const computeStructureImageSize = (numOfAtoms) => {
    //const COMPOUND_IMAGE_HEIGHT = 200;
    //const COMPOUND_IMAGE_WIDTH = 400;

    const maxNumOfConsideredAtoms = 40;
    let noa = numOfAtoms <= maxNumOfConsideredAtoms ? numOfAtoms : maxNumOfConsideredAtoms;
    noa = noa >= 1 ? noa : 1;

    let height = roundNumber(scale(noa, 1, maxNumOfConsideredAtoms, 50, 200), 0);
    let width = roundNumber(scale(noa, 1, maxNumOfConsideredAtoms, 100, 400), 0);

    return { height, width };
}

/**
 * Returns the maximum structure image size for a given set of compounds.
 * @param {Object} compounds 
 * @returns 
 */
export const computeMaxImageHeight = (compounds) => {

    let maxImageHeight = 0;
    if (compounds) {
        Object.keys(compounds).forEach((ocid, index) => {
            const smiles = compounds[ocid];
            const numOfAtoms = smiles ? countAtomsInSmiles(smiles) : -1;
            const { height } = computeStructureImageSize(numOfAtoms);

            if (height > maxImageHeight) {
                maxImageHeight = height;
            }
        });
    }
    return maxImageHeight;
}


/**
 * Creates a free text option for a certain term.
 * @param {*} termFragment 
 * @returns 
 */
export const createFreetextOption = (termFragment, withVariants = true) => {

    if (termFragment) {
        const freetextType = withVariants ? 't' : 'tr';
        return {
            domains: [freetextType],
            term: termFragment,
            label: termFragment
        };
    }
    return null;
}

/**
 * Adds free text entries to terms array. One entry for text with variants
 * and one for text exact.
 * @param {string} term 
 * @param {Array} terms 
 * @returns 
 */
export const addFreetextOptions = (term, terms = []) => {

    terms.push({
        domains: ['tr'],
        term: term,
        label: `${term} (Text exact)`,
    });
    terms.push({
        domains: ['t'],
        term: term,
        label: `${term} (Text with variants)`
    });

    return terms;
}


/**
 * Adds suggestions to terms array. Removes terms which match direct match.
 * 
 * @param {Object} directFragmentMatches 
 * @param {string} term 
 * @param {Boolean} termsOnly 
 * @param {Array} terms 
 * @returns 
 */
export const addDirectMatch = (directFragmentMatches, term, termsOnly, terms = []) => {

    let directMatch;

    if (directFragmentMatches) {
        //console.log('*** suggestedTerms - directFragmentMatches: ', directFragmentMatches);
        if (directFragmentMatches && Object.keys(directFragmentMatches).length > 0) {
            //console.log('Object.values(directFragmentMatches): ', Object.values(directFragmentMatches));
            const domains = [...Object.keys(directFragmentMatches)];
            const ocids = [];
            const prefnames = [];
            Object.values(directFragmentMatches).forEach(dirMatches => {
                dirMatches.forEach(dirMatch => {
                    //console.log('dirMatch: ', dirMatch);
                    ocids.push(dirMatch.ocid);
                    if (dirMatch.preferredName) {
                        prefnames.push(dirMatch.preferredName);
                    }
                });
            });
            directMatch = {
                term: term,
                label: term,
                domains: domains,
                ocids: ocids,
                prefnames: prefnames
            };

            if (directMatch) {
                if (termsOnly) {
                    terms.push(term);
                }
                else {
                    terms.push(directMatch);
                }
            }
        }
    }

    return directMatch;
}

/**
 * Adds suggestions to terms array. 
 * Removes terms which match direct match.
 * 
 * @param {Object} suggestedTerms 
 * @param {string} term 
 * @param {Boolean} termsOnly 
 * @param {Array} terms 
 * @param {Object} directMatch 
 */
export const addSuggestions = (suggestedTerms, term, termsOnly, terms = [], directMatch = null) => {

    if (suggestedTerms) {
        // --- term suggestions --- //
        const suggs = suggestedTerms;
        //console.log('*** suggestedTerms - suggestedTerms: ', suggs);
        if (suggs) {
            for (const concept of suggs) {
                //console.log('*****');
                //console.log('directMatch.ocids: ', (directMatch ? directMatch.ocids : '[]'));
                //console.log('concept.ocids: ', concept.ocids);
                //console.log('subset? ', isArraySubset([1,2], [2]));
                if (!directMatch ||
                    concept.term.toLowerCase() !== term.toLowerCase() ||
                    !isArraySubset(directMatch.ocids, concept.ocids)) {
                    if (termsOnly) {
                        terms.push(concept.term);
                    }
                    else if (concept.term.toLowerCase() !== term.toLowerCase()) {
                        concept.label = concept.term;
                        terms.push(concept);
                    }
                }
            }
        }
    }
}

/**
 * Adds merged suggestions to terms array, one entry per merged concepts. 
 * Removes terms which match direct match.
 * 
 * @param {Object} mergedSuggestedTerms 
 * @param {string} term 
 * @param {Boolean} termsOnly 
 * @param {Array} terms 
 * @param {Object} directMatch 
 * @returns 
 */
export const addMergedSuggestions = (mergedSuggestedTerms, term, termsOnly, terms = [], directMatch = null) => {

    //console.log('*** suggestedTerms - suggestedTerms: ', suggs);
    if (mergedSuggestedTerms) {
        // --- term suggestions --- //
        const suggs = mergedSuggestedTerms;
        const directMatchOtherTerms = {};

        for (const mergedConcepts of suggs) {
            //console.log('*****');
            //console.log('mergedConcepts: ', mergedConcepts);
            //console.log('directMatch.ocids: ', (directMatch ? directMatch.ocids : '[]'));
            //console.log('concept.ocids: ', concept.ocids);
            //console.log('subset? ', isArraySubset([1,2], [2]));
            if (!isArrayEmpty(mergedConcepts.mergedTerms)) {

                const firstTerm = mergedConcepts.mergedTerms[0];
                //console.log('firstTerm.ocids: ', firstTerm.ocids);
                //console.log('!isArraySubset: ', (!isArraySubset(directMatch.ocids, firstTerm.ocids)));

                if (directMatch && isArraySubset(directMatch.ocids, firstTerm.ocids)) {
                    mergedConcepts.mergedTerms.forEach(singleTerm => {
                        if (singleTerm.term.toLowerCase() !== term.toLowerCase()) {
                            directMatchOtherTerms[singleTerm.term] = true;
                        }
                    });
                }
                else {
                    //if (!directMatch ||
                    //    //firstTerm.term.toLowerCase() !== term.toLowerCase() ||
                    //    !isArraySubset(directMatch.ocids, firstTerm.ocids)) {
                    if (termsOnly) {
                        terms.push(firstTerm.term);
                    }
                    else {
                        const concept = {
                            otherTerms: []
                        };
                        let count = 1;
                        mergedConcepts.mergedTerms.forEach(singleTerm => {
                            if (count === 1) {
                                concept.term = singleTerm.term;
                                concept.domains = singleTerm.domains;
                                concept.ocids = singleTerm.ocids;
                                concept.prefnames = singleTerm.prefnames;
                            }
                            else {
                                concept.otherTerms.push(singleTerm.term);
                            }
                            count++;
                        });

                        concept.label = concept.term;
                        terms.push(concept);
                    }
                }
            }
        }

        if (directMatch && Object.keys(directMatchOtherTerms).length > 0) {
            directMatch.otherTerms = Object.keys(directMatchOtherTerms);
        }
    }

    return terms;
}


/**
 * HACK!!! Workaround to dynamically add panels. 
 * Returns an array with one entry (in case panel should be added) or 
 * an empty array (in case panel should not be added).
 * The array should then be used with map() to add/dismiss the panel.
 * 
 * addDynamicPanel(condition).map(xxx => {
 *    return TabPanel...
 * })
 * 
 * @param {*} addPanel 
 * @returns 
 */
export const addDynamicPanel = (addPanel) => {
    return addPanel ? [''] : [];
}

/**
 * Returns string of query terms 
 * 
 * @param {Array} queryTerms
 * @returns 
 */
export const createQueryTermsString = (queryTerms, domainLabelsMap, filterDefinitions) => {
    let termsString = ''
    queryTerms && queryTerms.forEach(term => {
        let identifier = ''
        if (term.domains) {
            let domainsString = ''
            term.domains.forEach(dom => {
                domainsString += domainLabelsMap && domainLabelsMap[dom] ? domainLabelsMap[dom] + ', ' : '-' + ', '
            })
            domainsString = domainsString.slice(0, -2)
            identifier = domainsString
        } else if (term.filterID) {
            identifier = filterDefinitions && filterDefinitions[term.filterID] && filterDefinitions[term.filterID].label ? filterDefinitions[term.filterID].label : '-'
        } else {
            identifier = '-, '
        }
        termsString += `${term.term} (${identifier}), `
    })
    termsString = termsString.slice(0, -2)
    return termsString
}

/* Create user array divided into companies and departments. 
* Substract current user from count values.
* 
* @param {*} users 
* @param {*} userData
* @returns 
*/

export const createUserArray = (users, userData, specify) => {
    let companyKey = `${userData.userDetails.company.id}-${userData.userDetails.company.name}`
    let departmentKey = `${userData.userDetails.department.id}-${userData.userDetails.department.name}`
    let userArray = []
    users && users.forEach((member) => {
        if (member.company && member.company.name && (userArray.length === 0 || !userArray.some(comp => comp.label === member.company.name))) {
            userArray = [...userArray, { expanded: specify === 'user' || (userData.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' || userData.userDetails.highestAdminType === 'NONE') ? true : true, selectable: specify === 'user' || (userData.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' || userData.userDetails.highestAdminType === 'NONE') ? false : false, key: `${member.company.id}-${member.company.name}`, label: member.company.name, children: [], isOrg: true }]
        }
    })


    userArray && userArray.length > 0 && userArray.forEach((comp) => {
        users && users.forEach((member) => {
            if (member.company && member.company.name === comp.label) {
                if (comp.children.length === 0 || !comp.children.some(item => item.label === member.department.name)) {
                    comp.children = [...comp.children, {
                        expanded: specify === 'user' ? true : false, selectable: specify === 'user' ? false : true, id: member.department.id, key: `${member.department.id}-${member.department.name}`, label: member.department.name, //userCount: member.department.userCount, 
                        children: [], isOrg: false
                    }]
                }
            }
        })
    })

    users && specify === 'user' && users.forEach(member => {
        userArray && userArray.forEach((comp, i) => {
            if (member.company.name === comp.label) {
                userArray[i].children.forEach((dep, j) => {
                    if (dep.label === member.department.name) {
                        userArray[i].children[j].children.push({ selectable: specify === 'org' ? false : true, id: member.id, key: member.id, label: `${member.lastName}, ${member.forename} (${member.username})`, value: member.id })
                    }
                })
            }
        })
    })

    /*userArray.forEach(comp => {
        let compCounter = 0
        comp.children.forEach(child => {
            compCounter += child.userCount
        })
        comp.userCount = compCounter
    })

    userArray.forEach(comp => {
        if (comp.key === companyKey) {
            comp.userCount--
        }
        comp.children.forEach(dep => {
            if (dep.key === departmentKey) {
                dep.userCount--
            }
        })
    })*/

    return userArray
}

export const fetchOrgsAndSuborgs = (userData) => {
    let allOrgsAndSuborgs = {}

    if (userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' || userData.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN') {
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies`, { headers: getHeaderToken() })
            .then(function (response) {
                response.data.forEach((comp, i) => {
                    allOrgsAndSuborgs[`${comp.id}-${comp.name}`] = []
                    axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${comp.id}/departments`, { headers: getHeaderToken() })
                        .then(function (response) {
                            let departments = response.data
                            departments.forEach(dep => {
                                allOrgsAndSuborgs[`${comp.id}-${comp.name}`] = [...allOrgsAndSuborgs[`${comp.id}-${comp.name}`], { orgLabel: comp.name, key: `${dep.id}-${dep.name}`, label: dep.name !== 'default' ? dep.name : 'Unassigned / Inactive regular users', id: dep.id, expanded: false, isOrg: false, selectable: true, children: [] }]
                            })
                        }).catch(function (error) {
                            if (error.response.status === 400) {
                            } else if (error.response.status === 401) {
                            } else if (error.response.status === 404) {
                                console.log("not found")
                            }
                        })
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                } else if (error.response.status === 401) {
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }
    return allOrgsAndSuborgs
}


/**
 * Create checked keys for users, companies and departments. 
 * Substract current user from count values.
 * 
 * @param {*} checkedUserKeys
 * @param {*} departmentMembers
 * @param {*} userArray
 * @returns 
 */

export const createCheckedKeys = (checkedUserKeys, departmentMembers, userArray) => {

    let companies = []
    let departments = []
    let checkedDepartmentsAndCompanies = []
    let companyUserCounter = {}
    let departmentUserCounter = {}

    Object.keys(checkedUserKeys).forEach(key => {
        let user = departmentMembers.find(user => user.id === Number(key))
        if (user !== undefined) {
            if (companyUserCounter[userArray.find(comp => comp.label === user.company.name).key] === undefined) {
                companyUserCounter[userArray.find(comp => comp.label === user.company.name).key] = 1
            } else {
                companyUserCounter[userArray.find(comp => comp.label === user.company.name).key]++
            }
            if (companies.length === 0 || !companies.some(comp => comp.id === user.company.id)) {
                companies = [...companies, { key: userArray.find(comp => comp.label === user.company.name).key, name: user.company.name, id: user.company.id, userCount: userArray.find(comp => comp.label === user.company.name).userCount }]
            }
            let selectedCompanyIndex = userArray.findIndex(comp => comp.label === user.company.name)
            let selectedDepartmentIndex = userArray[selectedCompanyIndex].children.findIndex(dep => dep.label === user.department.name)
            if (departmentUserCounter[userArray[selectedCompanyIndex].children[selectedDepartmentIndex].key] === undefined) {
                departmentUserCounter[userArray[selectedCompanyIndex].children[selectedDepartmentIndex].key] = 1
            } else {
                departmentUserCounter[userArray[selectedCompanyIndex].children[selectedDepartmentIndex].key]++
            }
            if (departments.length === 0 || !departments.some(dep => dep.id === user.department.id)) {
                departments = [...departments, { key: userArray[selectedCompanyIndex].children[selectedDepartmentIndex].key, name: user.department.name, id: user.department.id, userCount: userArray[selectedCompanyIndex].children[selectedDepartmentIndex].userCount }]
            }
        }
    })
    let mergedUserCounters = { ...companyUserCounter, ...departmentUserCounter }

    checkedDepartmentsAndCompanies = companies.concat(departments)

    checkedDepartmentsAndCompanies.forEach(entry => {
        Object.keys(mergedUserCounters).forEach(key => {
            if (entry.key === key) {
                /*if (entry.userCount > mergedUserCounters[key]) {
                    entry.checked = false
                    entry.partialChecked = true
                } else {*/
                entry.checked = true
                entry.partialChecked = false
                //}
            }
        })
    })

    checkedDepartmentsAndCompanies.forEach(entry => {
        checkedUserKeys[entry.key] = { checked: entry.checked, partialChecked: entry.partialChecked }
    })

    return checkedUserKeys
}