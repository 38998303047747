export const DOMAIN_COLORS = {

    query: {
        backgroundColor: 'FFFF02',
    },

    anatomy: {
        backgroundColor: 'FCD2B3',
    },

    biomarker: {
        backgroundColor: 'd2c3db',
    },

    celllines: {
        backgroundColor: 'FFE7D3',
    },

    chem: {
        backgroundColor: 'A1CDE2',
    },
    chemClass: {
        backgroundColor: '85d1c7',
    },

    chemCompound: {
        backgroundColor: 'A1CDE2',
    },

    chemGroup: {
        backgroundColor: 'aef2e9',
    },

    clinicaltrials: {
        backgroundColor: 'FDDFFF',
    },

    companies: {
        backgroundColor: 'D3AA8B',
    },

    countries: {
        backgroundColor: 'DFA189',
    },

    cosmetology: {
        backgroundColor: '9feaf2',
    },

    diabetes: {
        backgroundColor: 'CCA0FF',
    },

    diseases: {
        backgroundColor: 'F9BBBB',
    },

    devices: {
        backgroundColor: 'CCA0FF',
    },

    drugs: {
        backgroundColor: 'c6d365',
    },

    effects: {
        backgroundColor: 'D6D6D6',


    },

    humangenes: {
        backgroundColor: 'e2f986',


    },

    herbdrugs: {
        backgroundColor: 'E7EFAA',


    },

    inorgmat: {
        backgroundColor: 'DFDAA7',


    },

    magnitudes: {
        backgroundColor: 'F99D9D',


    },

    methods: {
        backgroundColor: 'E3E7EA',


    },

    natprod: {
        backgroundColor: 'BFDBB1',


    },

    nutrition: {
        backgroundColor: 'C2E5FF',


    },

    nybeauty: {
        backgroundColor: 'E9D59A',


    },

    plantstruc: {
        backgroundColor: 'F7B16F',


    },

    polymers: {
        backgroundColor: 'C0E2DB',
    },

    proteins: {
        backgroundColor: '98eac4',
    },

    regions: {
        backgroundColor: 'DDC2AF',
    },

    species: {
        backgroundColor: 'C1FFC1',
    },

    speciessrc: {
        backgroundColor: 'DAEA62',
    },

    substances: {
        backgroundColor: 'e0f2f9',
    },

    toxicity: {
        backgroundColor: 'E2D3D3',
    },

    units: {
        backgroundColor: 'ffffaa',
    },

    women: {
        backgroundColor: 'E8CDAE',
    },

    womeness: {
        backgroundColor: 'E9D6A0',
    },

}


export const EXTRA_COLORS = [
    '4DABF7',
    '94D82D',
    'F783AC',
    'DB78F2',
    '51CF66',
    'FF6B6B',
    'FCC419',
    '22B8CF',
    '20C997',
    '748FFC',
    'B89471',
    '9876FA',
    'ADB5BD'
]

export const EXTRA_COLORS_CLASSNAME = {
    'FF6B6B': 'color-item1',
    'DB78F2': 'color-item2',
    '9876FA': 'color-item3',
    '748FFC': 'color-item4',
    '4DABF7': 'color-item5',
    '22B8CF': 'color-item6',
    '51CF66': 'color-item7',
    '94D82D': 'color-item8'
}

