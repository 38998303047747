import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ChemistrySearch from '../chemistry/ChemistrySearch'

const mapStateToProps = state => ({
    userData: state.user.data,
    repositories: state.user.data.userDetails.department?.selectedRepositories,
})
const mapDispatchToProps = dispatch => ({})

const ChemistrySearchContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChemistrySearch))

export default ChemistrySearchContainer
