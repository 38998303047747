import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
// import {useResizeDetector} from "react-resize-detector";
import LeftAsideTree from "../../../common/LeftAsideTree/LeftAsideTree";
import {
    setBioactivitiesHeadersStructureSkeleton,
    setBioactivitiesLeftAsideExpandedKeys, setBioactivitiesQueryTerms
} from "../../../../redux/actions/BioactivitiesActions";
import {LeftAsideNodeTemplate} from "../../../common/LeftAsideNodeTemplate/LeftAsideNodeTemplate";
import {
    bioactivitiesAggregationResults,
    bioactivitiesHeadersStructureSkeleton, bioactivitiesLeftAsideExpandedKeys, bioactivitiesNodes,
    bioactivitiesQueryTerms,
    bioactivitiesRoleMapping, bioactivitiesSelectedReposForSearch
} from "../../../../redux/selectors/selectors";
import {createHeadersSkeleton} from "../../../common/helpers/createHeadersSkeleton";
import {addAnyToQueryTerms} from "../../../common/helpers/addAnyToQueryTerms";

const BioactivitiesLeftAside = ({setQueryTerms, showToast}) => {
        const roleMapping = useSelector(bioactivitiesRoleMapping);
        const headersStructureSkeleton = useSelector(bioactivitiesHeadersStructureSkeleton);
        const nodes = useSelector(bioactivitiesNodes);
        const queryTerms = useSelector(bioactivitiesQueryTerms);
        const leftAsideExpandedKeys = useSelector(bioactivitiesLeftAsideExpandedKeys);
        const aggregationResults = useSelector(bioactivitiesAggregationResults);
        const reposWithBioactivitiesSearchFeature = useSelector(bioactivitiesSelectedReposForSearch);
        const dispatch = useDispatch();

        const filters = useMemo(() => roleMapping.map(role => role.name).filter(name => name !== undefined), [roleMapping]);

        // prepare facettes to render and limit it's amount
        const facettes = useMemo(() => {
            const results = {};

            for (let filter of filters) {
                results[filter] = aggregationResults?.[filter]//?.slice(0, maxAmount);
            }
            return results;
        }, [filters, aggregationResults]);

        useEffect(() => {
            const headersSkeleton = createHeadersSkeleton(nodes, facettes);

            dispatch(setBioactivitiesHeadersStructureSkeleton(headersSkeleton));
        }, [dispatch, nodes, facettes]);

        // const {width, ref} = useResizeDetector();

        const setTargetToScroll = () => console.log('SCROLL');
        const handleSelect = () => console.log('SCROLL');

        const handleCheckboxAny = (fieldName) => {
            const _queryTerms = addAnyToQueryTerms(fieldName, queryTerms);
            dispatch(setBioactivitiesQueryTerms(_queryTerms));
        };

        const tooltipText = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.' +
            'Libero, pariatur voluptatibus. Excepturi exercitationem iure natus odio officia ' +
            'quaerat suscipit tempora, totam voluptates voluptatibus? Aperiam atque explicabo ' +
            'sed tempore veritatis? Unde!'

        const nodeTemplate = (node) => <LeftAsideNodeTemplate node={node}
                                                              showToast={showToast} setTargetToScroll={setTargetToScroll}
                                                              repositoriesArray={reposWithBioactivitiesSearchFeature}
                                                              handleSelect={handleSelect}
                                                              handleCheckboxAny={handleCheckboxAny}
                                                              facettes={facettes} queryTerms={queryTerms}
                                                              setQueryTerms={setQueryTerms}
                                                              tooltipText={tooltipText}
        />

        const onToggle = (e) => {
            dispatch(setBioactivitiesLeftAsideExpandedKeys(e.value));
        }

        return (
            <>
                <h2 style={{marginBottom: 0}}>Filters</h2>

                <LeftAsideTree
                    // ref={ref}
                    nodeTemplate={nodeTemplate} headersStructureSkeleton={headersStructureSkeleton}
                    onToggle={onToggle}
                    leftAsideExpandedKeys={leftAsideExpandedKeys}/>
            </>
        );
    }
;

export default BioactivitiesLeftAside;
