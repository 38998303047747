import classNames from 'classnames';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../NotificationEditingTab.module.scss';
import { toggleNotificationState,updateNotification } from '../../../../redux/actions/NotificationsAdtions';

export const NotificationItem = ({ notification, checked, onOpenDeleteNotificationModal }) => {
  const [notificationText, setNotificationText] = useState('');
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const dispatch = useDispatch();

  const handleChangeNotificationText = (event) => {
    setNotificationText(event.target.value);
  };

  const handleToggleEditMode = (notification) => () => {
    if (!isEditModeEnabled) {
      setNotificationText(notification.text);
    }
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const handleUpdateNotificationText = (notification) => () => {
    const requestBody = {
      notificationId: notification.notificationId,
      text: notificationText,
      enabled: notification.enabled,
    };
    dispatch(updateNotification(requestBody));
    setIsEditModeEnabled(false);
  };

  const handleEnableNotification = (event) => {
    dispatch(toggleNotificationState(event.value.notificationId, event.checked));
  };

  const handleOpenDeleteDialog = () => {
    onOpenDeleteNotificationModal(notification.notificationId);
  };

  return (
    <div className={styles.notificationItem}>
      <RadioButton
        inputId={notification.notificationId}
        name={notification.text}
        value={notification}
        onChange={handleEnableNotification}
        checked={checked}
      />
      <i
        aria-hidden
        onClick={handleOpenDeleteDialog}
        className={classNames('pi pi-trash', styles.actionButton)}
      />
      <i
        aria-hidden
        onClick={
          isEditModeEnabled
            ? handleUpdateNotificationText(notification)
            : handleToggleEditMode(notification)
        }
        className={classNames(
          `pi pi-${isEditModeEnabled ? 'check' : 'pencil'}`,
          styles.actionButton,
        )}
      />
      {isEditModeEnabled ? (
        <InputTextarea
          id="notificationText"
          onChange={handleChangeNotificationText}
          value={notificationText}
          className={styles.notificationText}
        />
      ) : (
        <label htmlFor={notification.text}>{notification.text}</label>
      )}
    </div>
  );
};
