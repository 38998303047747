import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as Actions from '../../../redux/actions'
import Administration from '../Administration'

const mapStateToProps = state => ({
    userDetails: state.user.data.userDetails,
    userData: state.user.data,
})

const mapDispatchToProps = dispatch => ({
    isLoggedIn: (history) => dispatch(Actions.isLoggedIn(history)),
    logout: () => dispatch(Actions.logout())
})

const AdministrationContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Administration))

export default AdministrationContainer