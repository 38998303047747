import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

class DeleteRepository extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteRepositoryChecked: false
        }
    }

    onCheckDeleteRepository = (e) => {
        if (e.checked) {
            this.setState({
                deleteRepositoryChecked: true
            })
        } else {
            this.setState({
                deleteRepositoryChecked: false
            })
        }
    }

    onDeleteRepository = () => {
        this.props.onDeleteRepository(this.props.selectedRepositoryID)
    }

    render() {

        const footerDeleteRepository = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete"
                    className='p-button-sm p-button-danger'
                    disabled={this.state.deleteRepositoryChecked ? false : true}
                    onClick={this.onDeleteRepository}
                    style={{ marginRight: 5 }}
                />
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelDeleteRepository}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayDeleteRepository} style={{ 'width': "40vw" }} header="Delete repository" focusOnShow={false}
                modal={true} footer={footerDeleteRepository} dismissableMask={false}
                onHide={() => this.props.onCancelDeleteRepository()} className='styledDialog'>
                <div style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div style={{ marginBottom: 10}}>
                        <label>{`Warning! This will delete repository '${this.props.selectedRepositoryLabel} (${this.props.selectedRepositoryName})'.`}</label>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <Checkbox inputId='deleteRepositoryChecked'
                            onChange={(e) => this.onCheckDeleteRepository(e)}
                            checked={this.state.deleteRepositoryChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer', marginLeft: -2 }}
                        />
                        <label htmlFor='deleteRepositoryChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default DeleteRepository