import "./Cooccurrences.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createCorrelationTopTermsRequestV2, createOCIDForCorrelationsRequest,
  createQueryTermForCorrelationsRequest, fetchCorrelations
} from "../../../../../api/content/CorrelationsApi";
import { addThousandsSeparatorToNumber, isArrayEmpty, isObjectEmpty } from "../../../util";
import { RESPONSE_STATUS_SUCCESS } from "../../../../../properties";
import { getRepositoryForName } from "../../../../../api/content/ContentApi";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import useRepositoryLabel from "../../../../common/customHooks/repository-label";
import { createCoocQueryTermFromConceptDataV2, executeCoocShortcutV2 } from "../../../util/shortcuts";
import CellData from "../../../general/tabledata/CellData";

const COOC_REPOSITORIES = ['dspub', 'med', 'pmc', 'oacore'];
const COOC_DOMAINS = ['companies', 'diseases', 'species', 'proteins'];

const Cooccurrences = ({
  ocid,
  domain,
  name
}) => {

  const [coocs, setCoocs] = useState({});
  const [searchFinished, setSearchFinished] = useState(false);
  const [repository, setRepository] = useState({});
  const [loading, setLoading] = useState();

  const findAvailableRepository = async () => {
    for (var repoID of COOC_REPOSITORIES) {
      const responseRepo = await getRepositoryForName(repoID, false);
      if (responseRepo.status === RESPONSE_STATUS_SUCCESS && responseRepo.payload) {
        return responseRepo.payload;
      }
    }
    return null;
  }

  useEffect(() => {
    // pick first repository that is available for the user from a predefined list,
    // repo is not depending on the given OCID 
    const findRepository = async () => {
      const repo = await findAvailableRepository();
      setRepository(repo);
    };
    findRepository();
  }, []);

  useEffect(() => {
    setCoocs({});
    setSearchFinished(false);
  }, [ocid]);

  const fetchCooccurrences = (ocid, repository) => {
    if (ocid && !isObjectEmpty(repository)) {
      //console.log('===> FETCH COOCS');
      const fetchCoocData = async () => {
        setCoocs({});
        setLoading(true);

        // fetch co-occurrences of given concept with each predefined domain
        const promises = [];
        const conceptPartner = createOCIDForCorrelationsRequest([ocid], false, 'NONE', true);
        for (var domain of COOC_DOMAINS) {
          const domainPartner = createQueryTermForCorrelationsRequest(domain, '*', false, 'NONE', true);
          const request = createCorrelationTopTermsRequestV2(repository.id, [conceptPartner, domainPartner], 0, 10, true);
          const fetchCoocs = fetchCorrelations(request, null, [repository.id]);

          promises.push(fetchCoocs);
        }
        // collect results for each domain
        Promise.all(promises)
          .then((responses) => {
            const coocsNew = {};
            for (var i = 0; i < responses.length; i++) {
              const domain = COOC_DOMAINS[i];
              const response = responses[i];

              if (response.status === RESPONSE_STATUS_SUCCESS) {
                coocsNew[domain] = response?.payload?.coocPartnerList?.length > 1 &&
                  response.payload.coocPartnerList[1].resultData ?
                  response.payload.coocPartnerList[1].resultData : null;
              }
            }
            setCoocs(coocsNew);
            setSearchFinished(true);
          })
          //.catch((error) => { console.error(error.message) })
          .finally(() => { setLoading(false) });
      };
      fetchCoocData();
    }
  }

  const onSendToCoocSearch = (rightOcid, rightDomain, rightName) => {
    if (rightDomain && rightName) {
      const queryTermLeft = createCoocQueryTermFromConceptDataV2(ocid, domain, name);
      const queryTermRight = createCoocQueryTermFromConceptDataV2(rightOcid, rightDomain, rightName);
      executeCoocShortcutV2([queryTermLeft], [queryTermRight], true);
    }
  }

  const domainLabelsMap = useSelector((state) => state.user.data.userDetails.department.domainLabelsMap);
  const repositoryLabel = useRepositoryLabel(repository?.name);

  const hasCoocs = coocs && Object.values(coocs).some(res => !!res);

  return <LoadingOverlay
    active={loading}
    text={'Loading co-occurrences...'} >
    {!loading && hasCoocs ?
      <div className="grid coocs-container">
        <div className="col-12 coocs-repo-info secondaryInfo">
          Top 10 co-occurrences with selected domains found in {repositoryLabel || repository?.name || 'publications'}
        </div>
        {Object.entries(coocs).map(([domain, result]) => {
          let domainLabel = domainLabelsMap[domain] || domain;
          return (
            <div key={domain} className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6">
              <h4>{domainLabel}</h4>
              <div className="grid small-padding-tb cooc-result">
                {!isArrayEmpty(result?.resultTerms) ?
                  result.resultTerms.map(resultTerm => {
                    return (
                      <React.Fragment key={resultTerm.ocid}>
                        <div className="col-fixed cooc-bar-outer-container">
                          <span className="cooc-bar-inner-container">
                            <span className="cooc-bar" style={{ width: `${(resultTerm.weight * 100) / result.maxWeight}%` }} />
                          </span>
                        </div>
                        <div className="col cooc-term">
                          <CellData
                            cropContent={true}
                            allowCopy={true}
                            content={resultTerm.name}
                            noPaddingLeft={true}
                            onContentClick={() => onSendToCoocSearch(resultTerm.ocid, domain, resultTerm.name)}
                            contentTooltip={`Click to send "${name}" and "${resultTerm.name}" to Co-occurrence Analysis`}
                            contentStyleClass={"secondaryLink"}
                          />
                        </div>
                        <div className="col-fixed cooc-weight">
                          <span>{addThousandsSeparatorToNumber(resultTerm.weight)}</span>
                        </div>
                        <div className="breakRow"></div>
                      </React.Fragment>)
                  })
                  :
                  <div className="empty-message">No co-occurrences found.</div>
                }
              </div>
            </div>)
        })}
      </div>
      :
      searchFinished ?
        <span>No co-occurrences found</span>
        :
        !loading ?
          <span>
            Interested in the top co-occurrences with
            domains like {COOC_DOMAINS.map(domain => domainLabelsMap[domain] || domain).join(', ')}?
            <br />
            <a onClick={() => fetchCooccurrences(ocid, repository)}>
              Click here to see co-occurrences</a>
          </span> : null
    }
  </LoadingOverlay>
}

export default Cooccurrences;