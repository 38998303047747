import React, { Component, createRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import PhraseTokenDisplay from '../editor/PhraseTokenDisplay';
import * as OntologyUtil from '../../util/OntologyUtil';
import * as OntologyClassUtil from '../../util/OntologyClassUtil';
import { stopEventPropagation } from '../../../util';
//import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Link, Element } from 'react-scroll';
import { Toast } from 'primereact/toast';


class ClassDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editName: false
        };

        this.growl = createRef();
    }


    // ---------------------------------------------------- //
    // --- edit level ------------------------------------- //
    // ---------------------------------------------------- //
    onEditLevel = (ontClass) => {

        const level = ontClass.tags && ontClass.tags.oc_level
            && ontClass.tags.oc_level.length > 0 ? ontClass.tags.oc_level[0].value : '';

        this.setState({
            editLevel: true,
            editClass: ontClass,
            newLevel: level
        });
    }


    onEditLevelSubmit = (newLevel) => {

        this.setState({
            editLevel: false,
            editClass: null,
            newLevel: null,
        });

        this.props.onSetLevel(newLevel);
    }

    // ---------------------------------------------------- //
    // --- edit name -------------------------------------- //
    // ---------------------------------------------------- //
    onEditName = (ontClass) => {
        this.setState({
            editName: true,
            editClass: ontClass,
            newName: ontClass.name
        });
    }

    onEditNameSubmit = (newName) => {

        if (!newName || newName.length === 0) {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Empty names are not allowed',
                detail: 'Allowed characters are: A-Z a-z 0-9 and _, e.g. synset_name'
            });
            return;
        }
        else if (this.props.subTree === OntologyUtil.SUBTREE_FRAME) {
            if (!newName.match(/^\[?[A-Za-z0-9_]+\]?$/)) {
                this.growl.current.show({
                    sticky: true, closable: true, severity: 'error', summary: 'Name format not allowed',
                    detail: 'Allowed characters are: A-Z a-z 0-9 and _ as well as surrounding square brackets [...], e.g. [frame_name]'
                });
                return;
            }
        }

        this.setState({
            editName: false,
            editClass: null,
            newName: null
        });

        this.props.onEditName(newName);
    }

    // ---------------------------------------------------- //
    // --- edit synonym tag ------------------------------- //
    // ---------------------------------------------------- //
    onAddSynonymTag = () => {

        let newTag;
        if (this.props.subTree === OntologyUtil.SUBTREE_ROLE) {
            newTag = OntologyClassUtil.createSynonymTag(this.props.ontClass.name, 'EXACT', 'ROLE');
        }
        else {
            newTag = OntologyClassUtil.createSynonymTag('', 'EXACT', '');
        }

        this.setState({
            editSynonym: true,
            editSynonymTag: newTag,
            newSynonymValue: newTag.value,
            newSynonymScope: newTag.scope,
            newSynonymType: newTag.type,
        });

        this.props.onAddSynonymTag(newTag);
    }

    onEditSynonymTag = (event, tag) => {
        this.setState({
            editSynonym: true,
            editSynonymTag: tag,
            newSynonymValue: tag.value,
            newSynonymScope: tag.scope,
            newSynonymType: tag.type,
        });

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }

    onEditSynonymTagSubmit = (newSynonymValue, newSynonymScope, newSynonymType) => {

        if (!newSynonymValue || newSynonymValue === '') {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Empty synonyms are not allowed',
                detail: 'Please type in a synonym.'
            });
            return;
        }
        else if (!newSynonymScope || newSynonymScope === '') {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Empty scopes are not allowed',
                detail: 'Please select a scope.'
            });
            return;
        }
        else if (!newSynonymType || newSynonymType === '') {
            this.growl.current.show({
                sticky: true, closable: true, severity: 'error', summary: 'Empty synonym types are not allowed',
                detail: 'Please select a synonym type.'
            });
            return;
        }

        // entweder alles aus state holen oder gar nichts!!!
        const oldTag = this.state.editSynonymTag;
        const newTag = OntologyClassUtil.createSynonymTag(newSynonymValue, newSynonymScope, newSynonymType);

        this.setState({
            editSynonym: false,
            editSynonymTag: null,
            newSynonymValue: null,
            newSynonymScope: null,
            newSynonymType: null,
        });
        this.props.onEditSynonymTag(oldTag, newTag);
    }


    // ---------------------------------------------------- //
    // --- edit role tag ---------------------------------- //
    // ---------------------------------------------------- //
    onEditRoleTag = (event, tag) => {
        // --- edit role tag --- //
        this.props.onEditRoleTag(tag);

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }

    onCopyRoleTag = (event, tag) => {
        this.setState({
            copyToClassVisible: true,
            copyTag: tag,
            copyTagType: tag.tag
        });

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }

    // ---------------------------------------------------- //
    // --- edit syntax tag -------------------------------- //
    // ---------------------------------------------------- //
    onEditSyntaxTag = (event, tag) => {
        // --- edit role tag --- //
        this.props.onEditSyntaxTag(tag, tag.phraseTokens);

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }

    onCopySyntaxTag = (event, tag) => {
        this.setState({
            copyToClassVisible: true,
            copyTag: tag,
            copyTagType: tag.tag
        })

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }


    // ---------------------------------------------------- //
    // --- edit macro tag --------------------------------- //
    // ---------------------------------------------------- //
    onEditMacroTag = (event, tag) => {
        // --- edit macro tag --- //
        this.props.onEditMacroTag(tag);

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }

    onCopyMacroTag = (event, tag) => {
        // --- copy macro tag and add to macro class --- //
        this.props.onCopyTagToClass(this.state.ontClass, tag.tag, tag);

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }

    // ---------------------------------------------------- //
    // --- more... ---------------------------------------- //
    // ---------------------------------------------------- //
    onTranslateToSyntaxString = (event, phraseTokens, syntaxString) => {
        // --- show internal syntax string --- //
        this.setState({
            selSyntaxPhraseTokens: phraseTokens,
            selSyntaxOrig: syntaxString,
            origSyntaxVisible: true
        });

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }

    onDeleteTag = (event, tag) => {
        this.props.onDeleteTag(tag);

        // --- stop event propagation to outer components --- //
        stopEventPropagation(event);
    }

    onCopyTagToClassSubmit = () => {
        this.setState({
            copyToClassVisible: false
        });
        this.props.onCopyTagToClass(this.state.targetClass, this.state.copyTagType, this.state.copyTag);
    }


    render() {

        const { ontClass, subTree, selectedTag, onSelectTag,
            onAddRoleTag, onAddSyntaxTag, onAddMacroTag, contentBoxHeight, headerLabel } = this.props;

        const selectedTokens = {};

        const label = headerLabel ? headerLabel : 'Ontology class';

        const syntaxes = OntologyClassUtil.getTagsFromClass(ontClass, OntologyClassUtil.TAG_TYPE_SYNTAX);
        const roles = OntologyClassUtil.getTagsFromClass(ontClass, OntologyClassUtil.TAG_TYPE_ROLE);
        const synonyms = OntologyClassUtil.getTagsFromClass(ontClass, OntologyClassUtil.TAG_TYPE_SYNONYM);
        const macros = OntologyClassUtil.getTagsFromClass(ontClass, OntologyClassUtil.TAG_TYPE_MACRO);

        const containerID = `classDetails_${subTree}`;

        return (
            <>
                <Toast ref={this.growl} appendTo={document.body} />

                <div className="task-box task-box-dark-blue shadowMedium">
                    <div className="task-box-header">
                        {label}
                    </div>
                    <Element id={containerID} name={containerID}
                        style={{ padding: '15px', height: contentBoxHeight, overflow: 'auto' }} >
                        <div className="task-box-content">

                            <Link id={`jumpToSelectedTag_${subTree}`}
                                to={`selectedTag_${subTree}`}
                                containerId={containerID}
                                activeClass="active"
                                spy={true} smooth={true} offset={-50} duration={100}
                                className="displayNone">
                                Scroll to selected tag
                            </Link>

                            {ontClass ?
                                <div className="grid">

                                    <div className="col-fixed" style={{ width: 100 }}>
                                        OCID:
                                    </div>
                                    <div className="col">
                                        {ontClass.id}
                                    </div>

                                    <div className="breakRow" />

                                    <div className="col-fixed" style={{ width: 100 }}>
                                        Name:
                                </div>
                                    <div className="col">
                                        {this.state.editName && (this.state.editClass === ontClass) ?
                                            <>
                                                {subTree === OntologyUtil.SUBTREE_FRAME ?
                                                    <InputText
                                                        value={this.state.newName}
                                                        onChange={(e) => this.setState({ newName: e.target.value })}
                                                        keyfilter={/^[A-Za-z0-9_\[\]]+$/}
                                                    />
                                                    :
                                                    <InputText
                                                        value={this.state.newName}
                                                        onChange={(e) => this.setState({ newName: e.target.value })}
                                                        keyfilter={/^[A-Za-z0-9_]+$/}
                                                    />
                                                }
                                                <Button className="iconOnlySmall p-button-sm primaryButton"
                                                    icon="pi pi-check" style={{ fontSize: '12px', marginLeft: 10 }}
                                                    onClick={(e) => this.onEditNameSubmit(this.state.newName)} />
                                                <Button
                                                    className="iconOnlySmall p-button-sm p-button-secondary"
                                                    icon="pi pi-times" style={{ fontSize: '12px', marginLeft: 5 }}
                                                    onClick={(e) => this.setState({ editName: false })} />
                                            </>
                                            :
                                            <span onDoubleClick={(e) => this.onEditName(ontClass)}>
                                                {ontClass.name}
                                            </span>
                                        }
                                    </div>
                                    <div className="col-fixed" style={{ textAlign: 'right' }}>
                                        {/* 
                                        <Button
                                            className="iconOnlySmall"
                                            icon="pi pi-md-create" style={{ fontSize: '12px' }}
                                            title="Edit name"
                                            onClick={(e) => this.onEditName(ontClass)} />
                                            */}
                                        {subTree !== OntologyUtil.SUBTREE_MACRO ? <Button
                                            label="Edit name"
                                            className="p-button-text primaryButtonLink"
                                            onClick={(e) => this.onEditName(ontClass)}
                                            title="Edit level"
                                        /> : null}
                                    </div>

                                    <div className="breakRow" />

                                    {subTree === OntologyUtil.SUBTREE_FRAME ?
                                        <>
                                            <div className="col-fixed" style={{ width: 100 }}>
                                                Relation type:
                                        </div>
                                            <div className="col" style={{ paddingTop: 0 }}>
                                                <Dropdown value={OntologyClassUtil.getTagFromClass(ontClass, OntologyClassUtil.TAG_TYPE_RELTYPE) ?
                                                    OntologyClassUtil.getTagFromClass(ontClass, OntologyClassUtil.TAG_TYPE_RELTYPE).value : null}
                                                    options={OntologyClassUtil.FRAME_REL_TYPES}
                                                    onChange={(e) => { this.props.onSetRelType(e.value); }}
                                                    placeholder="Select relation type"
                                                />
                                            </div>
                                            <div className="breakRow" />
                                            <div className="col-fixed" style={{ width: 100 }}>
                                                Level:
                                        </div>
                                            <div className="col">
                                                {this.state.editLevel && (this.state.editClass === ontClass) ?
                                                    <>
                                                        <InputText
                                                            value={this.state.newLevel}
                                                            onChange={(e) => this.setState({ newLevel: e.target.value })}
                                                            keyfilter={/^[0-9]*$/}
                                                            placeholder="1 (default)"
                                                        />
                                                        <Button className="iconOnlySmall p-button-sm primaryButton"
                                                            icon="pi pi-check" style={{ fontSize: '12px', marginLeft: 10 }}
                                                            onClick={(e) => this.onEditLevelSubmit(this.state.newLevel)} />
                                                        <Button
                                                            className="iconOnlySmall p-button-sm p-button-secondary"
                                                            icon="pi pi-times" style={{ fontSize: '12px', marginLeft: 5 }}
                                                            onClick={(e) => this.setState({ editLevel: false })} />
                                                    </>
                                                    :
                                                    <span onDoubleClick={(e) => this.onEditLevel(ontClass)}
                                                        style={{ display: 'inline-block', width: 100 }}>
                                                        {ontClass.tags && ontClass.tags.oc_level
                                                            && ontClass.tags.oc_level.length > 0 ? ontClass.tags.oc_level[0].value : '1 (default)'}
                                                    </span>
                                                }
                                            </div>
                                            <div className="col-fixed" style={{ textAlign: 'right' }}>
                                                <Button
                                                    label="Edit level"
                                                    className="p-button-text primaryButtonLink"
                                                    onClick={(e) => this.onEditLevel(ontClass)}
                                                    title="Edit level"
                                                />
                                                {/* 
                                                <Button
                                                    className="iconOnlySmall"
                                                    icon="pi pi-md-create" style={{ fontSize: '12px' }}
                                                    title="Edit level"
                                                    onClick={(e) => this.onEditLevel(ontClass)} />
                                                    */}
                                            </div>
                                            <div className="breakRow" />
                                        </> : null
                                    }

                                    {subTree === OntologyUtil.SUBTREE_ROLE ||
                                        subTree === OntologyUtil.SUBTREE_SYNSET ?
                                        <>
                                            <div className="col-fixed" style={{ width: 100 }}>
                                                Synonyms:
                                        </div>
                                            <div className="col" style={{ textAlign: 'left' }}>
                                                <Button
                                                    style={{ width: 120 }}
                                                    className="green-btn buttonSmall"
                                                    label="Add synonym"
                                                    onClick={(e) => this.onAddSynonymTag()}
                                                />
                                            </div>
                                            <div className="breakRow" />

                                            {synonyms && synonyms.length > 0 ?
                                                <>
                                                    <div className="col-fixed" style={{ width: 100 }}>

                                                    </div>
                                                    <div className="col">
                                                        <ol style={{ paddingLeft: "0px", marginTop: 0 }}>
                                                            {synonyms.map(syno => {
                                                                const isSelTag = (selectedTag === syno);
                                                                return (
                                                                    <li key={syno.value} className="tagListEntry"
                                                                        style={{
                                                                            backgroundColor: isSelTag ? '#d2f4d2' : 'white',
                                                                            borderColor: isSelTag ? '#96e096' : '#ddd'
                                                                        }}
                                                                        onClick={(e) => { onSelectTag(syno); }}>

                                                                        {isSelTag ? <Element name={`selectedTag_${subTree}`}></Element> : null}

                                                                        <div className="grid">
                                                                            <div className="col" style={{ lineHeight: '2em' }}>
                                                                                {this.state.editSynonym && this.state.editSynonymTag === syno ?
                                                                                    <>
                                                                                        <div className="grid">
                                                                                            <div className="col-fixed" style={{ width: 100 }}>
                                                                                                Value:
                                                                                        </div>
                                                                                            <div className="col">
                                                                                                <InputText
                                                                                                    value={this.state.newSynonymValue}
                                                                                                    onChange={(e) => this.setState({ newSynonymValue: e.target.value })}
                                                                                                    onClick={(e) => stopEventPropagation(e)}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="breakRow" />
                                                                                            <div className="col-fixed" style={{ width: 100 }}>
                                                                                                Scope:
                                                                                        </div>
                                                                                            <div className="col">
                                                                                                <span onClick={(e) => stopEventPropagation(e)}>
                                                                                                    <Dropdown value={this.state.newSynonymScope}
                                                                                                        options={OntologyClassUtil.SYNONYM_SCOPES}
                                                                                                        onChange={(e) => this.setState({ newSynonymScope: e.value })}
                                                                                                        placeholder="Select scope"
                                                                                                    />
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="breakRow" />
                                                                                            <div className="col-fixed" style={{ width: 100 }}>
                                                                                                Type:
                                                                                        </div>
                                                                                            <div className="col">
                                                                                                <span onClick={(e) => stopEventPropagation(e)}>
                                                                                                    <Dropdown value={this.state.newSynonymType}
                                                                                                        options={OntologyClassUtil.SYNONYM_TYPES}
                                                                                                        onChange={(e) => { this.setState({ newSynonymType: e.value }); }}
                                                                                                        placeholder="Select type"
                                                                                                    />
                                                                                                </span>
                                                                                                <Button className="iconOnlySmall p-button-sm primaryButton"
                                                                                                    icon="pi pi-check" style={{ fontSize: '12px', marginLeft: 20 }}
                                                                                                    onClick={(e) => {
                                                                                                        this.onEditSynonymTagSubmit(this.state.newSynonymValue, this.state.newSynonymScope, this.state.newSynonymType);
                                                                                                        stopEventPropagation(e);
                                                                                                    }} />
                                                                                                <Button
                                                                                                    className="iconOnlySmall p-button-sm p-button-secondary"
                                                                                                    icon="pi pi-times" style={{ fontSize: '12px', marginLeft: 5 }}
                                                                                                    onClick={(e) => {
                                                                                                        this.setState({ editSynonym: false });
                                                                                                        stopEventPropagation(e);
                                                                                                    }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <span>{`${syno.value}`}</span>
                                                                                        <br />
                                                                                        <span>{`${syno.scope} ${syno.type}`}</span>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div className="col-fixed" style={{ textAlign: 'right', padding: '10px 5px 0 5px' }}>
                                                                                <Button
                                                                                    label="Edit"
                                                                                    className="p-button-text primaryButtonLink"
                                                                                    title="Edit synonym"
                                                                                    onClick={(e) => this.onEditSynonymTag(e, syno)}
                                                                                />
                                                                                <Button
                                                                                    label="Delete"
                                                                                    className="p-button-text dangerButtonLink"
                                                                                    title="Delete synonym"
                                                                                    onClick={(e) => this.onDeleteTag(e, syno)}
                                                                                />
                                                                                {/* 
                                                                                <Button
                                                                                    className="iconOnlySmall"
                                                                                    icon="pi pi-md-create" style={{ fontSize: '12px', marginLeft: 10 }}
                                                                                    title="Edit synonym"
                                                                                    onClick={(e) => this.onEditSynonymTag(e, syno)}
                                                                                />
                                                                                <Button icon="pi pi-create" className="p-button-raised p-button-rounded" />
                                                                                <Button
                                                                                    className="iconOnlySmall"
                                                                                    icon="pi pi-md-delete" style={{ fontSize: '12px', marginLeft: 20, background: 'red' }}
                                                                                    title="Delete synonym"
                                                                                    onClick={(e) => this.onDeleteTag(e, syno)}
                                                                                />
                                                                               */}

                                                                            </div>
                                                                            <div className="breakRow" />
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ol>
                                                    </div>
                                                </> : null}
                                        </> : null
                                    }


                                    <div className="breakRow" />

                                    {subTree === OntologyUtil.SUBTREE_FRAME ?
                                        <>
                                            <div className="col-fixed" style={{ width: 100 }}>
                                                Roles:
                                        </div>
                                            <div className="col" style={{ textAlign: 'left' }}>
                                                <Button
                                                    style={{ width: 120 }}
                                                    className="green-btn buttonSmall"
                                                    label="Add role"
                                                    onClick={(e) => onAddRoleTag()}
                                                />
                                            </div>
                                            <div className="breakRow" />

                                            {roles && roles.length > 0 ?
                                                <>
                                                    <div className="col-fixed" style={{ width: 100 }}>
                                                    </div>
                                                    <div className="col">
                                                        <ol style={{ paddingLeft: '0px', marginTop: 0 }}>
                                                            {roles.map(tag => {
                                                                const isSelTag = (selectedTag === tag);
                                                                return (
                                                                    <li key={tag.key} className="tagListEntry"
                                                                        style={{
                                                                            backgroundColor: isSelTag ? '#FAEBD7' : 'white',
                                                                            borderColor: isSelTag ? '#fcd199' : '#ddd'
                                                                        }}
                                                                        onClick={(e) => onSelectTag(tag)}>

                                                                        {isSelTag ? <Element name={`selectedTag_${subTree}`}></Element> : null}

                                                                        {tag.value ?
                                                                            <div className="grid">
                                                                                <div className="col">
                                                                                    {tag.roleName}
                                                                                </div>
                                                                                <div className="col-fixed" style={{ textAlign: 'right', padding: '10px 5px 0 5px' }}>
                                                                                    <Button
                                                                                        label="Edit"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        onClick={(e) => this.onEditRoleTag(e, tag)}
                                                                                        title="Edit role"
                                                                                    />
                                                                                    <Button
                                                                                        label="Copy"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        title="Copy role"
                                                                                        onClick={(e) => this.onCopyRoleTag(e, tag)}
                                                                                    />
                                                                                    <Button
                                                                                        label="Translate"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        title="Translate syntax to internal string"
                                                                                        onClick={(e) => this.onTranslateToSyntaxString(e, tag.phraseTokens, tag.roleSyntax)}
                                                                                    />
                                                                                    <Button
                                                                                        label="Delete"
                                                                                        className="p-button-text dangerButtonLink"
                                                                                        title="Delete role"
                                                                                        onClick={(e) => this.onDeleteTag(e, tag)}
                                                                                    />
                                                                                </div>
                                                                                <div className="breakRow" />
                                                                                <div className="col-12" >
                                                                                    <PhraseTokenDisplay
                                                                                        phraseTokens={tag.phraseTokens}
                                                                                        selectedTokens={selectedTokens}
                                                                                        groupIndex={0}
                                                                                        showAdditionalData={true}
                                                                                        showProperties={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ol>
                                                    </div>
                                                </> : null
                                            }

                                            <div className="breakRow" />

                                            <div className="col-fixed" style={{ width: 100 }}>
                                                Syntaxes:
                                                    </div>
                                            <div className="col" style={{ textAlign: 'left' }}>
                                                <Button
                                                    style={{ width: 120 }}
                                                    className="green-btn buttonSmall"
                                                    label="Add syntax"
                                                    onClick={(e) => onAddSyntaxTag()}
                                                />
                                            </div>
                                            <div className="breakRow" />

                                            {syntaxes && syntaxes.length > 0 ?
                                                <>
                                                    <div className="col-fixed" style={{ width: 100 }}>

                                                    </div>
                                                    <div className="col">
                                                        <ol style={{ paddingLeft: '0px', marginTop: 0 }}>
                                                            {syntaxes.map(tag => {
                                                                const isSelTag = (selectedTag === tag);
                                                                const level = OntologyClassUtil.getLevelFromTag(tag);
                                                                //console.log('level: ', OntologyClassUtil.getLevelFromTag(tag));
                                                                return (
                                                                    <li key={tag.key} className="tagListEntry"
                                                                        style={{
                                                                            backgroundColor: isSelTag ? '#E6E6FA' : 'white',
                                                                            borderColor: isSelTag ? '#c2c2fc' : '#ddd'
                                                                        }}
                                                                        onClick={(e) => onSelectTag(tag)}>

                                                                        {isSelTag ? <Element name={`selectedTag_${subTree}`}></Element> : null}

                                                                        {tag.value ?
                                                                            <div className="grid">
                                                                                <div className="col">
                                                                                    {level ? `Level: ${level}` : `Level: 1 (default)`}
                                                                                </div>
                                                                                <div className="col-fixed" style={{ textAlign: 'right', padding: '10px 5px 0 5px' }}>
                                                                                    <Button
                                                                                        label="Edit"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        onClick={(e) => this.onEditSyntaxTag(e, tag)}
                                                                                        title="Edit syntax"
                                                                                    />
                                                                                    <Button
                                                                                        label="Copy"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        title="Copy syntax"
                                                                                        onClick={(e) => this.onCopySyntaxTag(e, tag)}
                                                                                    />
                                                                                    <Button
                                                                                        label="Translate"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        title="Translate syntax to internal string"
                                                                                        onClick={(e) => this.onTranslateToSyntaxString(e, tag.phraseTokens, tag.value)}
                                                                                    />
                                                                                    <Button
                                                                                        label="Delete"
                                                                                        className="p-button-text dangerButtonLink"
                                                                                        title="Delete syntax"
                                                                                        onClick={(e) => this.onDeleteTag(e, tag)}
                                                                                    />
                                                                                    {/*
                                                                                    <Button
                                                                                        className="iconOnlySmall"
                                                                                        icon="pi pi-md-create" style={{ fontSize: '12px', marginLeft: 10 }}
                                                                                        title="Edit syntax"
                                                                                        onClick={(e) => this.onEditSyntaxTag(e, tag)} />
                                                                                    <Button
                                                                                        className="iconOnlySmall"
                                                                                        icon="pi pi-md-content-copy" style={{ fontSize: '12px', marginLeft: 10 }}
                                                                                        title="Copy syntax"
                                                                                        onClick={(e) => this.onCopySyntaxTag(e, tag)} />
                                                                                    <Button
                                                                                        className="iconOnlySmall"
                                                                                        icon="pi pi-md-translate" style={{ fontSize: '12px', marginLeft: 10 }}
                                                                                        title="Translate syntax to internal string"
                                                                                        onClick={(e) => this.onTranslateToSyntaxString(e, tag.phraseTokens, tag.value)} />
                                                                                    <Button
                                                                                        className="iconOnlySmall"
                                                                                        icon="pi pi-md-delete" style={{ fontSize: '12px', marginLeft: 20, background: 'red' }}
                                                                                        title="Delete syntax"
                                                                                        onClick={(e) => this.onDeleteTag(e, tag)} />
                                                                                   */}
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <PhraseTokenDisplay
                                                                                        phraseTokens={tag.phraseTokens}
                                                                                        selectedTokens={selectedTokens}
                                                                                        groupIndex={0}
                                                                                        showAdditionalData={true}
                                                                                        showProperties={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ol>
                                                    </div>
                                                </> : null
                                            }
                                        </> : null
                                    }

                                    <div className="breakRow" />

                                    {subTree === OntologyUtil.SUBTREE_MACRO ?
                                        <>
                                            <div className="col-fixed" style={{ width: 100 }}>
                                                Macros:
                                            </div>
                                            <div className="col" style={{ textAlign: 'left' }}>
                                                <Button
                                                    style={{ width: 120 }}
                                                    className="green-btn buttonSmall"
                                                    label="Add macro"
                                                    onClick={(e) => onAddMacroTag()}
                                                />
                                            </div>
                                            <div className="breakRow" />

                                            {macros && macros.length > 0 ?
                                                <>
                                                    <div className="col-fixed" style={{ width: 100 }}>
                                                    </div>
                                                    <div className="col">
                                                        <ol style={{ paddingLeft: '0px', marginTop: 0 }}>
                                                            {macros.map(tag => {
                                                                const isSelTag = (selectedTag === tag);
                                                                return (
                                                                    <li key={tag.key} className="tagListEntry"
                                                                        style={{
                                                                            backgroundColor: isSelTag ? '#d8efff' : 'white',
                                                                            borderColor: isSelTag ? '#70c5ff' : '#ddd'
                                                                        }}
                                                                        onClick={(e) => onSelectTag(tag)}>

                                                                        {isSelTag ? <Element name={`selectedTag_${subTree}`}></Element> : null}

                                                                        {tag.value ?
                                                                            <div className="grid">
                                                                                <div className="col">
                                                                                    {tag.macroName}
                                                                                </div>
                                                                                <div className="col-fixed" style={{ textAlign: 'right', padding: '10px 5px 0 5px' }}>
                                                                                    <Button
                                                                                        label="Edit"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        onClick={(e) => this.onEditMacroTag(e, tag)}
                                                                                        title="Edit macro"
                                                                                    />
                                                                                    <Button
                                                                                        label="Copy"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        title="Copy macro"
                                                                                        onClick={(e) => this.onCopyMacroTag(e, tag)}
                                                                                    />
                                                                                    <Button
                                                                                        label="Translate"
                                                                                        className="p-button-text primaryButtonLink"
                                                                                        title="Translate syntax to internal string"
                                                                                        onClick={(e) => this.onTranslateToSyntaxString(e, tag.phraseTokens, tag.macroSyntax)}
                                                                                    />
                                                                                    <Button
                                                                                        label="Delete"
                                                                                        className="p-button-text dangerButtonLink"
                                                                                        title="Delete macro"
                                                                                        onClick={(e) => this.onDeleteTag(e, tag)}
                                                                                    />
                                                                                    {/* 
                                                                                    <Button
                                                                                        className="iconOnlySmall"
                                                                                        icon="pi pi-md-create" style={{ fontSize: '12px', marginLeft: 10 }}
                                                                                        title="Edit macro"
                                                                                        onClick={(e) => this.onEditMacroTag(e, tag)} />
                                                                                    <Button
                                                                                        className="iconOnlySmall"
                                                                                        icon="pi pi-md-content-copy" style={{ fontSize: '12px', marginLeft: 10 }}
                                                                                        title="Copy macro"
                                                                                        onClick={(e) => this.onCopyMacroTag(e, tag)} />
                                                                                    <Button
                                                                                        className="iconOnlySmall"
                                                                                        icon="pi pi-md-translate" style={{ fontSize: '12px', marginLeft: 10 }}
                                                                                        title="Translate syntax to internal string"
                                                                                        onClick={(e) => this.onTranslateToSyntaxString(e, tag.phraseTokens, tag.macroSyntax)} />
                                                                                    <Button
                                                                                        className="iconOnlySmall"
                                                                                        icon="pi pi-md-delete" style={{ fontSize: '12px', marginLeft: 20, background: 'red' }}
                                                                                        title="Delete macro"
                                                                                        onClick={(e) => this.onDeleteTag(e, tag)} />
                                                                                        */}
                                                                                </div>
                                                                                <div className="breakRow" />
                                                                                <div className="col-12" >
                                                                                    <PhraseTokenDisplay
                                                                                        phraseTokens={tag.phraseTokens}
                                                                                        selectedTokens={selectedTokens}
                                                                                        groupIndex={0}
                                                                                        showAdditionalData={true}
                                                                                        showProperties={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ol>
                                                    </div>
                                                </> : null
                                            }

                                        </> : null
                                    }

                                </div>
                                : null
                            }
                        </div>
                    </Element>
                </div>

                <Dialog header="Copy tag"
                    visible={this.state.copyToClassVisible}
                    style={{ width: '50vw' }}
                    modal={true}
                    onHide={() => this.setState({ copyToClassVisible: false })}
                    focusOnShow={false} >
                    <div className="grid">
                        <div className="col-4">
                            Add to class:
                        </div>
                        <div className="col-8" style={{ color: 'gray' }}>
                            <Dropdown value={this.state.targetClass}
                                options={this.props.classSelectItems}
                                onChange={(e) => { this.setState({ targetClass: e.value }); }}
                                placeholder="Select class"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Filter classes"
                                appendTo={document.body}
                            />
                        </div>
                        <div className="col-12" style={{ textAlign: 'right' }}>
                            <Button label="Confirm"
                                className="primaryButton p-button-sm"
                                //style={{ marginLeft: ".5em" }}
                                onClick={(e) => this.onCopyTagToClassSubmit()} >
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Internal syntax string"
                    visible={this.state.origSyntaxVisible}
                    modal={true}
                    style={{ width: '90vw' }}
                    onHide={() => this.setState({ origSyntaxVisible: false })}
                    focusOnShow={false} >
                    <div className="grid">
                        {this.state.selSyntaxOrig ?
                            <>
                                <div className="col-12" style={{ padding: 15, lineHeight: 1.5 }}>
                                    {this.state.selSyntaxOrig}
                                </div>
                                {this.state.selSyntaxPhraseTokens ?
                                    <>
                                        <div className="col-12">
                                            <hr></hr>
                                        </div>
                                        <div className="col-12">
                                            <PhraseTokenDisplay
                                                phraseTokens={this.state.selSyntaxPhraseTokens}
                                                selectedTokens={selectedTokens}
                                                groupIndex={0}
                                                showAdditionalData={true}
                                                showProperties={true}
                                            />
                                        </div>
                                    </> : null
                                }
                            </>
                            :
                            <div>
                                No syntax found
                            </div>
                        }
                    </div>
                </Dialog>
            </>
        );
    }
}

export default ClassDetails;