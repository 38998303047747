import { PureComponent } from "react";
import { Button } from 'primereact/button';
import $ from 'jquery';
//import { sleep } from "../util";
import PopupContainer from "./pdfview/pdfViewer/components/popup/container";

class AnnotationInfoOverlay extends PureComponent {

    /*constructor(props) {
        super(props);
        //this.state = { mouseIn: false };
    }*/

    componentDidUpdate(prevProps) {
        const dataChanged = prevProps.annotationInfos !== this.props.annotationInfos;
        if (dataChanged) {
            // this.annInfo.changeActiveIndex(0);
        }
    }
    /**/

    render() {
        const { annotationInfos, loadAnnotationInfo, onShowConceptInfo, annTypeLabelsMap, domainColors } = this.props;

        const popupContent = {
            props: {
                className: "Highlight__popup",
                children: annotationInfos ? annotationInfos : []
            },
        }

        return (
            <div id="annotationInfo"
                style={{
                    position: 'absolute',
                    zIndex: 3,
                    display: 'none',
                }}>
                <PopupContainer
                    ref={(ref) => { this.annInfo = ref; }}
                    onMoveAway={() => {
                        //if (this.state.mouseIn) { return; }
                        $("#annotationInfo").css('display', 'none');
                    }}
                    paddingX={60}
                    paddingY={300}
                    children={popupContent}
                    onShowConcept={onShowConceptInfo}
                    annTypeLabelsMap={annTypeLabelsMap}
                    domainColors={domainColors}
                />

                <Button
                    id="loadAnnotationInfoBtn"
                    onClick={() => loadAnnotationInfo()}
                    className="buttonSmall"
                    label="Load chemical structure"
                    style={{ width: "auto", display: 'none' }} />
            </div>
        );
    }
}

export default AnnotationInfoOverlay;