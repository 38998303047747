import { Button } from 'primereact/button';
import './UserManualLink.css';


function openManual(e, manualFilePath) {
    //stopEventPropagation(e);
    window.open(manualFilePath, '_blank');
}

export default function UserManualLink({
    manualFilePath,
    label,
    tooltip,
    styleClass,
    style
}) {

    return <span
        className='search-manual-link'
        onClick={(e) => openManual(e, manualFilePath)}>

        <span className="search-manual-icon pi pi-file-pdf"></span>
        <Button
            className={`noTextDecoration ${styleClass ? styleClass : ''}`}
            style={style ? style : {}}
            title={tooltip ? tooltip : ''}>
            {label}
        </Button>

    </span>;
}