import { APP_PROPERTIES } from "../../../../properties";
import { CHEMISTRY_FORMAT_MOL, CHEMISTRY_FORMAT_MRV, CHEMISTRY_FORMAT_RXN } from "./chemistrySearchConstants";

// --- prefixes: compounds or reactions --- // 
const PREFIX_COMPOUNDS = 'compounds_';
const PREFIX_REACTIONS = 'reactions_';

// --- 
const COMPOUNDS_OC = PREFIX_COMPOUNDS + APP_PROPERTIES.CHEMISTRY.OC;
const COMPOUNDS_CHEMAXON = PREFIX_COMPOUNDS + APP_PROPERTIES.CHEMISTRY.CHEMAXON;
//export const COMPOUNDS_BIG_QUERY_SAVI = PREFIX_COMPOUNDS + APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI;
//const COMPOUNDS_OC_BIG_QUERY = PREFIX_COMPOUNDS + APP_PROPERTIES.CHEMISTRY.OC_BIG_QUERY;
const COMPOUNDS_BIG_QUERY_SAVI_PRODUCTS = PREFIX_COMPOUNDS + APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI_PRODUCTS;

//const REACTIONS_BIG_QUERY = PREFIX_REACTIONS + APP_PROPERTIES.CHEMISTRY.BIG_QUERY;
const REACTIONS_BIG_QUERY_SAVI = PREFIX_REACTIONS + APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI;
const REACTIONS_XFACT_DB_RXN = PREFIX_REACTIONS + APP_PROPERTIES.CHEMISTRY.XFACT_DB_RXN;
//const REACTIONS_XFACT_DB = PREFIX_REACTIONS + APP_PROPERTIES.CHEMISTRY.XFACT_DB;

const CHEM_DB_INDEX_OC_REGISTRY = 'ocregistry';
const CHEM_DB_INDEX_SAVI_PRODUCTS = 'saviproducts';
const CHEM_DB_INDEX_SAVI = 'savi';
//const CHEM_DB_INDEX_DEFAULT = CHEM_DB_INDEX_OC_REGISTRY;

export const SEARCH_TARGET_COMPOUNDS = 'compounds';
export const SEARCH_TARGET_REACTIONS = 'reactions';
export const SEARCH_TARGET_REACTANTS = 'reactants';
export const SEARCH_TARGET_PRODUCTS = 'products';
export const SEARCH_TARGET_REAGENTS = 'reagents';

export const SEARCH_TARGET_OPTIONS = {
    [SEARCH_TARGET_COMPOUNDS]: { value: SEARCH_TARGET_COMPOUNDS, label: 'Compounds', allowedForStructureSearch: true },
    [SEARCH_TARGET_REACTIONS]: { value: SEARCH_TARGET_REACTIONS, label: 'Reactions', allowedForStructureSearch: true },
    [SEARCH_TARGET_REACTANTS]: { value: SEARCH_TARGET_REACTANTS, label: 'Reactants', queryParameter: 'Reactants' },
    [SEARCH_TARGET_PRODUCTS]: { value: SEARCH_TARGET_PRODUCTS, label: 'Products', queryParameter: 'Products' },
    [SEARCH_TARGET_REAGENTS]: { value: SEARCH_TARGET_REAGENTS, label: 'Reagents', queryParameter: 'Catalysts' }
};

export const SEARCH_RESULT_TYPE_COMPOUNDS = 'compounds';
export const SEARCH_RESULT_TYPE_REACTIONS = 'reactions';
export const SEARCH_RESULT_TYPE_REACTION_COMPOUNDS = 'reactionCompounds';
export const SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE = 'compoundsComplete';

export const SEARCH_TYPE_EXACT = 'duplicate';
export const SEARCH_TYPE_FULL_MATCH = 'full-match';
export const SEARCH_TYPE_SUBSTRUCTURE = 'substructure';
export const SEARCH_TYPE_SIMILARITY = 'similarity';
export const SEARCH_TYPE_SUBREACTION = 'subreaction';
export const SEARCH_TYPE_OCID = 'ocid';
export const SEARCH_TYPE_CHEM_CLASS = 'class';
export const SEARCH_TYPE_ALL = 'all';

export const CHEM_DB_ITEMS = {};
CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.XFACT_DB_RXN] = {
    type: SEARCH_RESULT_TYPE_REACTIONS,
    service: APP_PROPERTIES.CHEMISTRY.XFACT_DB_RXN,
    index: CHEM_DB_INDEX_OC_REGISTRY,
    value: REACTIONS_XFACT_DB_RXN,
    label: 'OC reactions',
    outputFormat: CHEMISTRY_FORMAT_RXN,
    searchFormat: CHEMISTRY_FORMAT_RXN,
    shortInfo: 'Find in reactions in OntoChem database',
    description: 'A reaction shall contain a reaction arrow after the starting materials, before the product or in between the starting materials and products. Reaction agents may be drawn above the reaction arrow. Thus, we are able to search for specific reaction products or starting materials.',
    allowedSearchTargets: { [SEARCH_TARGET_REACTIONS]: true, [SEARCH_TARGET_REACTANTS]: true, [SEARCH_TARGET_PRODUCTS]: true, [SEARCH_TARGET_REAGENTS]: true },
    //allowIncludeMixtures: true,
    multipleStructuresAllowed: false,
    allowSearchByOcids: true,
    sortBy: []
};
CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI] = {
    type: SEARCH_RESULT_TYPE_REACTIONS,
    service: APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI,
    index: CHEM_DB_INDEX_SAVI,
    value: REACTIONS_BIG_QUERY_SAVI,
    label: 'SAVI reactions',
    outputFormat: CHEMISTRY_FORMAT_RXN,
    searchFormat: CHEMISTRY_FORMAT_RXN,
    shortInfo: 'Find reaction products in SAVI database',
    description: 'Draw a compound and search for specific reaction products in the SAVI database.',
    defaultSearchType: 'SUBSTRUCTURE',
    allowedSearchTargets: { [SEARCH_TARGET_REACTIONS]: true, [SEARCH_TARGET_REACTANTS]: true, [SEARCH_TARGET_PRODUCTS]: true },
    multipleStructuresAllowed: false
};
CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.OC] = {
    type: SEARCH_RESULT_TYPE_COMPOUNDS,
    service: APP_PROPERTIES.CHEMISTRY.OC,
    preparationsService: APP_PROPERTIES.CHEMISTRY.XFACT_DB_RXN,
    index: CHEM_DB_INDEX_OC_REGISTRY,
    value: COMPOUNDS_OC,
    label: 'OC compounds',
    outputFormat: CHEMISTRY_FORMAT_MOL,
    searchFormat: CHEMISTRY_FORMAT_MOL,
    shortInfo: 'Find compounds in OntoChem database',
    defaultSearchType: 'DUPLICATE',
    allowedSearchTargets: { [SEARCH_TARGET_COMPOUNDS]: true },
    allowSorting: {
        [SEARCH_TYPE_EXACT]: true,
        [SEARCH_TYPE_FULL_MATCH]: true,
        [SEARCH_TYPE_SUBSTRUCTURE]: true,
        [SEARCH_TYPE_SUBREACTION]: true,
        [SEARCH_TYPE_OCID]: true,
        [SEARCH_TYPE_CHEM_CLASS]: true,
    },
    allowIncludeMixtures: true,
    multipleStructuresAllowed: true,
    allowSearchByOcids: true,
    allowSearchByChemClass: true,
};
CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.CHEMAXON] = {
    type: SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE,
    service: APP_PROPERTIES.CHEMISTRY.CHEMAXON,
    preparationsService: APP_PROPERTIES.CHEMISTRY.XFACT_DB_RXN,
    value: COMPOUNDS_CHEMAXON,
    label: 'OC compounds (CA)',
    searchFormat: CHEMISTRY_FORMAT_MRV,
    shortInfo: 'Find compounds in OntoChem database (CA)',
    defaultSearchType: 'DUPLICATE',
    allowedSearchTargets: { [SEARCH_TARGET_COMPOUNDS]: true },
    multipleStructuresAllowed: true
};
CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI_PRODUCTS] = {
    type: SEARCH_RESULT_TYPE_REACTION_COMPOUNDS,
    service: APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI_PRODUCTS,
    preparationsService: APP_PROPERTIES.CHEMISTRY.BIG_QUERY_SAVI,
    index: CHEM_DB_INDEX_SAVI_PRODUCTS,
    value: COMPOUNDS_BIG_QUERY_SAVI_PRODUCTS,
    label: 'SAVI reaction products',
    outputFormat: CHEMISTRY_FORMAT_MOL,
    searchFormat: CHEMISTRY_FORMAT_MOL,
    shortInfo: 'Find reaction products in SAVI database',
    defaultSearchType: 'DUPLICATE',
    allowedSearchTargets: { [SEARCH_TARGET_COMPOUNDS]: true, [SEARCH_TARGET_PRODUCTS]: true },
    allowSorting: {
        [SEARCH_TYPE_EXACT]: true,
        [SEARCH_TYPE_FULL_MATCH]: true,
        [SEARCH_TYPE_SUBSTRUCTURE]: true,
        [SEARCH_TYPE_SUBREACTION]: true,
        [SEARCH_TYPE_OCID]: true,
        [SEARCH_TYPE_CHEM_CLASS]: true,
    },
    multipleStructuresAllowed: false
};

/*
CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.XFACT_DB] = {
    type: SEARCH_RESULT_TYPE_REACTIONS, service: APP_PROPERTIES.CHEMISTRY.XFACT_DB, value: REACTIONS_XFACT_DB, label: 'OC reactions (mol)', outputFormat: CHEMISTRY_FORMAT_MOL,
    shortInfo: 'Find in OntoChem reactions database',
    description: 'A reaction shall contain a reaction arrow after the starting materials, before the product or in between the starting materials and products. Reaction agents may be drawn above the reaction arrow. Thus, we are able to search for specific reaction products or starting materials.',
    allowedSearchTargets: { 'reactions': true, 'reactants': true, 'products': true },
};
*/
/*CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.BIG_QUERY] = {
    type: SEARCH_RESULT_TYPE_REACTIONS, service: APP_PROPERTIES.CHEMISTRY.BIG_QUERY, value: REACTIONS_BIG_QUERY, label: 'BQ reactions', outputFormat: CHEMISTRY_FORMAT_MOL, 
    shortInfo: 'Find in BigQuery reactions database',
    allowedSearchTargets: { 'reactions: ': true, 'reactants': true, 'products': true },
};*/
/*CHEM_DB_ITEMS[APP_PROPERTIES.CHEMISTRY.OC_BIG_QUERY] = {
    type: SEARCH_RESULT_TYPE_COMPOUNDS, service: APP_PROPERTIES.CHEMISTRY.OC_BIG_QUERY, value: COMPOUNDS_OC_BIG_QUERY, label: 'BQ compounds', outputFormat: CHEMISTRY_FORMAT_MOL, 
    shortInfo: 'Find in BigQuery compounds database',
    defaultSearchType: 'DUPLICATE'
};*/