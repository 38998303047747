import { isArrayEmpty } from "../../../util";
import CellData from "../../../general/tabledata/CellData";


const Synonyms = ({
  synonyms = [],
  showTwoColumns = true,
}) => {

  const renderSingleColumn = () => {
    return <div className="col-12">{renderList(synonyms)}</div>;
  }

  const renderTwoColumns = () => {
    const half = Math.round(synonyms.length / 2);
    const synonymsLeft = synonyms.slice(0, half);
    const synonymsRight = synonyms.slice(half);

    return <>
      <div className="col-6">{renderList(synonymsLeft)}</div>
      <div className="col-6">{renderList(synonymsRight)}</div>
    </>
  }

  const renderList = (synos) => {
    return synos.map(syno => {
      return <div key={syno} className="small-padding-tb">
        <CellData
          cropContent={true}
          allowCopy={true}
          content={syno}
        />
      </div>
    })
  }

  return !isArrayEmpty(synonyms) ?
    <div className="grid small-padding-tb" style={{ marginBottom: 5 }}>
      {showTwoColumns ? renderTwoColumns() : renderSingleColumn()}
    </div>
    :
    <span>No synonyms found</span>;
}

export default Synonyms;