import axios from 'axios';
import { sleep } from '../../../../components/webapi/util';
import { getHeaderToken } from '../../..';
import { APP_PROPERTIES } from '../../../../properties';

export class Repository {
  static #MainUrl = APP_PROPERTIES.MIDDLEWARE_BASE_URL;

  static #DefaultRepeatRequestTimeout = 200;

  static #Urls = {
    AuthToken: (userName = 'oc_adm', userPassword = 'SjNzTkNHOWlIUw==') => `${Repository.#MainUrl}/api/authentication?username=${userName}&password=${userPassword}`,
  }

  static getErrorMessage = (errorMessage) => errorMessage
    || Repository.ErrorMessages.UnknownError

  static Methods = {
    Post: 'POST',
    Get: 'GET',
    Put: 'PUT',
    Delete: 'DELETE',
    Patch: 'PATCH',
  };

  static ResultsStatuses = {
    MinimalErrorStatus: 400,
    Error_401: 401,
    UnknownError: 520,
  };

  static ErrorMessages = {
    UnknownError: 'Unknown error occurred.',
  };

  static ResponseStatuses = {
    Success: 'SUCCESS',
    Failed: 'FAILED',
  };

  static checkIfObjectIsEmpty = (obj) => !obj || Object.keys(obj).length === 0

  static async fetchAuthToken(userName, userPassword) {
    try {
      const response = await fetch(Repository.#Urls.AuthToken(userName, userPassword), {
        method: Repository.Methods.Post,
      });
      const tokenData = (await response.json());
      return tokenData?.token ?? '';
    } catch {
      return '';
    }
  }

  static checkIfResponseFailed = (responseStatus) => responseStatus === Repository.ResponseStatuses.Failed;

  #authToken;

  constructor(authToken = '') {
    this.#authToken = authToken;
  }

  getAuthToken() {
    return this.#authToken;
  }

  setAuthToken(token) {
    if (typeof token === 'string' && token.trim()) {
      this.#authToken = token;
    }
  }

  static async post(url, request, params = {}) {
    const data = await axios.post(url, request, { headers: getHeaderToken(), ...params });
    return data;
  }

  async postApiRequest(url, data = []) {
    const { Methods } = Repository;
    const requestedData = await this.sendApiRequest(Methods.Post, url, data);
    return requestedData;
  }

  static checkIfErrorStatus = (status) => status > Repository.ResultsStatuses.MinimalErrorStatus;

  static #handleSuccessResultRequest = (result) => {
    const { checkIfErrorStatus, ResponseStatuses } = Repository;
    if (result.ok || !checkIfErrorStatus(result.status)) {
      return {
        status: ResponseStatuses.Success,
        statusCode: result.status,
        payload: result.data,
      };
    }
    let message = '';
    if (result.statusText) {
      message = result.statusText;
    } else if (result.message) {
      message = result.message;
    } else if (result.userMessage) {
      message = result.userMessage;
    }
    return {
      status: ResponseStatuses.Failed,
      statusCode: result.status,
      message,
    };
  }

  static #handleErrorResultRequest = ({
    responseStatus, responseData, statusText, responseMessage,
  }) => {
    const { ErrorMessages, ResultsStatuses } = Repository;
    let message = ErrorMessages.UnknownError;
    let statusCode = ResultsStatuses.UnknownError;

    if (responseStatus) {
      statusCode = responseStatus;
    }
    if (responseData) {
      if (typeof responseData === 'string') {
        message = String(responseData);
      } else if (responseData.message) {
        message = responseData.message;
      }
    } else if (statusText) {
      message = statusText;
    } else if (responseMessage) {
      message = responseMessage;
    }

    return {
      message,
      statusCode,
    };
  }

  #resendRequest = async (method, url, data) => {
    await sleep(Repository.#DefaultRepeatRequestTimeout);
    return this.sendApiRequest(method, url, data, false);
  }

  async sendApiRequest(method, url, data = '', tryAgainIf401 = false, customHeaders = null) {
    const { ResponseStatuses, ResultsStatuses } = Repository;
    try {
      const result = await axios({
        method,
        url,
        headers: customHeaders ?? getHeaderToken(),
        data: JSON.stringify(data),
      });
      return Repository.#handleSuccessResultRequest(result);
    } catch (error) {
      const isNeedToResendRequest = tryAgainIf401 && error.response?.status === ResultsStatuses.Error_401;
      if (isNeedToResendRequest) {
        await this.#resendRequest(method, url, data);
      }
      const errorResponse = error.response ?? {};
      const { message, statusCode } = Repository.#handleErrorResultRequest({
        responseStatus: errorResponse?.status,
        responseData: errorResponse?.data,
        statusText: errorResponse?.statusText,
        message: errorResponse?.message,
      });

      return {
        status: ResponseStatuses.Failed,
        statusCode,
        message,
      };
    }
  }
}
