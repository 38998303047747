import React from "react";
import SeparatorPoint from "../../../../../webapi/general/SeparatorPoint";

const SearchGroupCriteriaCompact = ({ searchCriteria }) => {
  return (
    <div className="flex align-items-center mb-3">
      {searchCriteria.map(([searchCriteriaName, searchCriteria], index) => {
        return (
          <React.Fragment key={searchCriteriaName}>
            {index > 0 && <SeparatorPoint />}
            <span>{searchCriteria.filterValueLabel}</span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SearchGroupCriteriaCompact;
