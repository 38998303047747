import {
    BIOACTIVITIES_RESET_STATE, BIOACTIVITIES_SET_CHECKED_RESULTS,
    BIOACTIVITIES_SET_FIRST_RESULT_INDEX,
    BIOACTIVITIES_SET_HEADERS_STRUCTURE_SKELETON, BIOACTIVITIES_SET_INPUT_TEXT, BIOACTIVITIES_SET_IS_FIRST_RENDER,
    BIOACTIVITIES_SET_LEFT_ASIDE_EXPANDED_KEYS,
    BIOACTIVITIES_SET_METADATA, BIOACTIVITIES_SET_NODES,
    BIOACTIVITIES_SET_QUERY_TERMS,
    BIOACTIVITIES_SET_REPOS_WITH_BIOACTIVITIES_SEARCH_FEATURE,
    BIOACTIVITIES_SET_RESULT_COUNT, BIOACTIVITIES_SET_ROLE_MAPPING,
    BIOACTIVITIES_SET_SEARCH_RESULTS,
    BIOACTIVITIES_SET_SELECTED_REPOS_FOR_SEARCH, BIOACTIVITIES_SET_SHOW_CONCEPT_POPUP,
    BIOACTIVITIES_SET_SORT_DIRECTION,
    BIOACTIVITIES_SET_SORT_FIELD, BIOACTIVITIES_SET_SUGGESTIONS, BIOACTIVITIES_SET_TARGET_OCID,
    BIOACTIVITIES_TOGGLE_IS_FETCHING
} from "./index";
import * as BioactivitiesApi from "../../api/content/BioactivitiesApi";
import {prepareMetaData} from "../../components/common/helpers/prepareMetaData";
import {fetchMetaData} from "../../api/content/BiomarkerApi";

export const setBioactivitiesHeadersStructureSkeleton = (skeleton) => ({
    type: BIOACTIVITIES_SET_HEADERS_STRUCTURE_SKELETON,
    payload: skeleton
});

export const setBioactivitiesQueryTerms = (terms) => ({
    type: BIOACTIVITIES_SET_QUERY_TERMS,
    payload: terms
});

export const setBioactivitiesLeftAsideExpandedKeys = (keys) => ({
    type: BIOACTIVITIES_SET_LEFT_ASIDE_EXPANDED_KEYS, payload: keys
});

export const setReposWithBioactivitiesSearchFeature = repoList => ({
    type: BIOACTIVITIES_SET_REPOS_WITH_BIOACTIVITIES_SEARCH_FEATURE,
    payload: repoList
});

export const setBioactivitiesSelectedReposForSearch = repoList => ({
    type: BIOACTIVITIES_SET_SELECTED_REPOS_FOR_SEARCH,
    payload: repoList
});

export const setBioactivitiesSortField = (field) => ({
    type: BIOACTIVITIES_SET_SORT_FIELD,
    payload: field
});

export const setBioactivitiesSortDirection = (direction) => ({
    type: BIOACTIVITIES_SET_SORT_DIRECTION,
    payload: direction
});

export const resetBioactivitiesState = () => ({
    type: BIOACTIVITIES_RESET_STATE,
});

export const setBioactivitiesIsFirstRender = (isFirstRender) => ({
    type: BIOACTIVITIES_SET_IS_FIRST_RENDER,
    payload: isFirstRender
});

export const setBioactivitiesSuggestions = (suggestions) => ({
    type: BIOACTIVITIES_SET_SUGGESTIONS,
    payload: suggestions
});

export const setBioactivitiesInputText = (text) => ({
    type: BIOACTIVITIES_SET_INPUT_TEXT,
    payload: text
});

export const setBioactivitiesFirstResultIndex = (index) => ({
    type: BIOACTIVITIES_SET_FIRST_RESULT_INDEX,
    payload: index
});

export const setBioactivitiesResultCount = (count) => ({
    type: BIOACTIVITIES_SET_RESULT_COUNT,
    payload: count
});

export const setBioactivitiesRoleMapping = (roleMapping) => ({
    type: BIOACTIVITIES_SET_ROLE_MAPPING,
    payload: roleMapping
});

export const toggleBioactivitiesIsFetching = (isFetching) => ({
    type: BIOACTIVITIES_TOGGLE_IS_FETCHING,
    payload: isFetching
})

export const setBioactivitiesMetaData = (metadata) => ({
    type: BIOACTIVITIES_SET_METADATA,
    payload: metadata
})

export const setBioactivitiesSearchResults = results => ({
    type: BIOACTIVITIES_SET_SEARCH_RESULTS,
    payload: results
});

export const setBioactivitiesNodes = (nodes) => ({type: BIOACTIVITIES_SET_NODES, payload: nodes});

export const setBioactiviesTargetOcid = ocid => ({type: BIOACTIVITIES_SET_TARGET_OCID, payload: ocid});

export const setBioactivitiesShowConceptPopup = show => ({type: BIOACTIVITIES_SET_SHOW_CONCEPT_POPUP, payload: show});

export const setBioactivitiesCheckedResults = checkedResults => ({
    type: BIOACTIVITIES_SET_CHECKED_RESULTS,
    payload: checkedResults
});

export const searchBioactivities = (data, repoNameAndIdMap, showToast) => async (dispatch) => {
    try {
        dispatch(toggleBioactivitiesIsFetching(true));

        const bioactivities = await BioactivitiesApi.fetchSearchResults(data);
        const smiles = new Set(bioactivities.payload.relationResults.map(({fields}) => fields['compound.smiles'].storedData));
        const structures = await BioactivitiesApi.fetchSmiles(Array.from(smiles));

        const bioactivitiesWithImages = bioactivities.payload.relationResults.map(result => {
            const smile = result.fields['compound.smiles'].storedData;
            const image = `data:image/svg+xml;base64,${structures.payload[smile]}`;

            return {...result, fields: {...result.fields, image}}
        });

        bioactivities.payload.relationResults = bioactivitiesWithImages;

        // filter results with doc_id and find it's srcrep => [{repoName: docId}, {repoName: docId}, ...]
        const metadata = prepareMetaData(bioactivities.payload.relationResults);

        // add repo id
        for (let key in metadata) {
            metadata[key] = {...metadata[key], repoId: repoNameAndIdMap[key].id};
        }
        const metadataRequests = Object.keys(metadata).map(item => fetchMetaData(metadata[item]));
        const info = await Promise.allSettled(metadataRequests);
        const apiMetadata = info.filter(({status}) => status === 'fulfilled')
            .map(result => result.value.payload.documents);

        // combine all arrays into one
        const combinedApiMetaData = apiMetadata.reduce((acc, curVal) => [...acc, ...curVal], []);

        dispatch(setBioactivitiesMetaData(combinedApiMetaData));
        dispatch(setBioactivitiesSearchResults(bioactivities.payload));
    } catch (e) {
        showToast(`Something went wrong.`, 'Error occurred', 'error', 10000);
    } finally {
        dispatch(toggleBioactivitiesIsFetching(false));
    }
};
