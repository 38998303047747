import React, { Component } from 'react'
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { TreeTable } from 'primereact/treetable'
import ConfirmationDialog from "../common/ConfirmDialog/ConfirmationDialog";

class SetupRepository extends Component {

    constructor(props) {
        super(props);
        this.state = {
            repositoryLabel: '',
            repositoryDescription: '',
            repositoryFeatures: [],
            selectedRepositories: [],
            selectedOrgs: [],
            selectedBackends: [],
            allAvailableRepositories: [],
            allGlobalRepositories: [],
            allBackends: [],
            displayConfirmationDialog: false,
            selectedRep: { label: '' },
            selectedOrg: { label: '' }
        }
    }

    componentDidMount() {
        let allAvailableRepositories = this.props.allAvailableRepositories
        let selectedRepositories = []
        let selectedOrgs = []
        let selectedBackends = []
        let repositoryLabel = this.props.selectedRepositoryLabel ? this.props.selectedRepositoryLabel : ''
        let repositoryDescription = this.props.selectedRepositoryDescription ? this.props.selectedRepositoryDescription : '';
        let repositoryFeatures = this.props.selectedRepositoryFeatures ? Object.values(this.props.selectedRepositoryFeatures) : []
        let allGlobalRepositories = this.props.allGlobalRepositories
        let allBackends = this.props.allBackends

        this.props.allAvailableRepositories.forEach(rep => {
            selectedRepositories = [...selectedRepositories, rep.id]
        })

        this.props.allGlobalRepositories.forEach(org => {
            selectedOrgs = [...selectedOrgs, org.id]
        })

        this.props.allBackends.forEach(be => {
            selectedBackends = [...selectedBackends, be.id]
        })

        this.setState({
            repositoryLabel: repositoryLabel,
            repositoryDescription: repositoryDescription,
            repositoryFeatures: repositoryFeatures,
            allAvailableRepositories: allAvailableRepositories,
            selectedRepositories: selectedRepositories,
            selectedOrgs: selectedOrgs,
            selectedBackends: selectedBackends,
            allGlobalRepositories: allGlobalRepositories,
            allBackends: allBackends
        })
    }

    componentDidUpdate(prevProps) {
        var self = this
        if (self.props.allGlobalRepositories !== prevProps.allGlobalRepositories) {
            let allGlobalRepositories = this.props.allGlobalRepositories
            this.setState({
                allGlobalRepositories: allGlobalRepositories
            })
        }
    }

    onSetupRepository = () => {
        this.props.onSetupRepository(this.props.selectedRepositoryID, this.state.repositoryLabel, this.props.selectedRepositoryName, this.state.repositoryFeatures, this.state.repositoryDescription)
    }

    onOrgClick = (e, org, rep) => {
        if (e.checked) {
            this.setState({
                displayConfirmationDialog: true,
                checked: true,
                selectedOrg: org,
                selectedRep: rep
            })
        } else {
            this.props.onRepositoryOrgClick(false, org, rep)
        }
    }

    onSuborgClick = (e, suborg, rep) => {
        this.props.onRepositorySuborgClick(e, suborg, rep)
    }

    onSelectedRepositoriesChange = (e) => {
        this.setState({
            selectedRepositories: e.value
        })
    }

    onSelectedOrgsChange = (e) => {
        this.setState({
            selectedOrgs: e.value
        })
    }

    onSelectedBackendsChange = (e) => {
        let filteredRepos = []
        this.props.allAvailableRepositories.forEach(rep => {
            if (e.value.includes(rep.backendInfo.id)) {
                filteredRepos = [...filteredRepos, rep.id]
            }
        })
        this.setState({
            selectedBackends: e.value,
            selectedRepositories: filteredRepos
        })
    }

    onHideConfirmationDialog = () => {
        this.setState({
            displayConfirmationDialog: false
        })
    }

    onConfirmClick = (choice) => {
        this.setState({
            displayConfirmationDialog: false
        }, () => {
            this.props.onRepositoryOrgClick(this.state.checked, this.state.selectedOrg, this.state.selectedRep, choice)
        })
    }

    render() {

        let allRepositories = []

        this.state.allAvailableRepositories && this.state.allAvailableRepositories.forEach(rep => {
            if (this.state.selectedBackends?.includes(rep.backendInfo?.id)) {
                allRepositories = [...allRepositories, rep]
            }
        })

        let allOrgs = []

        this.state.allGlobalRepositories && this.state.allGlobalRepositories.forEach(org => {
            allOrgs = [...allOrgs, org]
        })

        let allBackends = []

        this.state.allBackends && this.state.allBackends.forEach(be => {
            allBackends = [...allBackends, be]
        })

        let orgs = []

        this.state.allGlobalRepositories.forEach(org => {
            orgs = [...orgs, org]
        })

        let orgData = []

        orgData = orgs && orgs.filter(org => this.state.selectedOrgs.includes(org.id)).map(org => (
            {
                selectable: false,
                key: org.id,
                label: org.name,
                data: {
                    name: <span style={{
                        color: org.deactivated ? 'gray' : 'black', fontWeight: 'bold', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'break-spaces'
                    }} title={org.name}>{org.name}</span>,
                    selectedRepositories: org.selectedRepositories
                },
                children: org.departments.map(department => (
                    {
                        selectable: false,
                        key: department.id,
                        data: {
                            name: <span style={{
                                color: department.deactivated ? 'gray' : 'black', overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'break-spaces'
                            }} title={department.name === 'default' ? `Unassigned / Inactive regular users` :
                                `${department.name}`}>{department.name === 'default' ? `Unassigned / Inactive regular users` : `${department.name}`}</span>,
                            selectedRepositories: department.selectedRepositories
                        }
                    }))
            }))

        let repositoryOptions = []

        allRepositories && allRepositories.forEach(rep => {
            repositoryOptions = [...repositoryOptions, { label: rep.label, value: rep.id }]
            orgData.forEach(org => {
                org.data[rep.id] = <Checkbox checked={org.data.selectedRepositories.some(el => el.id === rep.id)} onChange={(e) => this.onOrgClick(e, org, rep)}></Checkbox>
                org.children.forEach(suborg => {
                    suborg.data[rep.id] = <Checkbox style={{ marginLeft: 15 }} checked={suborg.data.selectedRepositories.some(el => el.id === rep.id)} onChange={(e) => this.onSuborgClick(e, suborg, rep)}></Checkbox>
                })
            })
        })

        let orgOptions = []

        allOrgs && allOrgs.forEach(org => {
            orgOptions = [...orgOptions, { label: org.name, value: org.id }]
        })

        let backendOptions = []

        allBackends && allBackends.forEach(be => {
            backendOptions = [...backendOptions, { label: be.label, value: be.id }]
        })

        let nameTemplate = (node) => {
            return (
                <React.Fragment>
                    <span>{node.data.name}</span>
                </React.Fragment>
            )
        }

        let repositoryColumns = allRepositories && allRepositories.filter(rep => this.state.selectedRepositories.includes(rep.id)).map(rep => (
            <Column key={rep.id} style={{
                width: '100px'
            }} field={rep.id} header={`${rep.label} (${rep.backendInfo.label})`}></Column>
        ))

        const rowClassName = (node) => {
            return { 'p-highlight': (node.children) }
        }

        return (
            <Dialog visible={this.props.displaySetupRepository} style={{ 'width': "97vw", 'height': '95vh' }} header={this.state.repositoryLabel !== "" ? "Setup repository" : "Setup repositories"} focusOnShow={false}
                modal={true}
                dismissableMask={false}
                onHide={() => this.props.onCancelSetupRepository()} className='styledDialog'>
                {this.state.repositoryLabel !== "" &&
                    <div style={{ marginBottom: 20 }}>
                        <span style={{}}>Repository label: {this.state.repositoryLabel}</span>
                        <span style={{ marginLeft: 30 }}>Repository ID: {this.props.selectedRepositoryID}</span>
                        <span style={{ marginLeft: 30 }}>Repository name: {this.props.selectedRepositoryName}</span>
                    </div>
                }
                <div style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 0, paddingTop: 20, marginRight: 0, marginBottom: 0 }}>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Organizations: </span>
                    <MultiSelect
                        pt={{
                            checkboxIcon: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (e.target.className.baseVal !== "") {
                                        e.target.parentNode.click();
                                    } else {
                                        e.target.parentNode.parentNode.click();
                                    }
                                },
                            },
                            headerCheckbox: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                },
                            },
                        }}
                        maxSelectedLabels={3}
                        filter
                        options={orgOptions}
                        value={this.state.selectedOrgs}
                        onChange={(e) => this.onSelectedOrgsChange(e)}
                        style={{ marginRight: 20, verticalAlign: '-moz-middle-with-baseline' }}
                        placeholder='Select organizations'
                    ></MultiSelect>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Backends: </span>
                    <MultiSelect
                        pt={{
                            checkboxIcon: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (e.target.className.baseVal !== "") {
                                        e.target.parentNode.click();
                                    } else {
                                        e.target.parentNode.parentNode.click();
                                    }
                                },
                            },
                            headerCheckbox: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                },
                            },
                        }}
                        maxSelectedLabels={3}
                        filter
                        options={backendOptions}
                        value={this.state.selectedBackends}
                        onChange={(e) => this.onSelectedBackendsChange(e)}
                        style={{ marginRight: 20, verticalAlign: '-moz-middle-with-baseline' }}
                        placeholder='Select backends'
                    ></MultiSelect>
                    <span style={{ verticalAlign: '-moz-middle-with-baseline' }}>Repositories: </span>
                    <MultiSelect
                        pt={{
                            checkboxIcon: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (e.target.className.baseVal !== "") {
                                        e.target.parentNode.click();
                                    } else {
                                        e.target.parentNode.parentNode.click();
                                    }
                                },
                            },
                            headerCheckbox: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                },
                            },
                        }}
                        maxSelectedLabels={3}
                        filter
                        options={repositoryOptions}
                        value={this.state.selectedRepositories}
                        onChange={(e) => this.onSelectedRepositoriesChange(e)}
                        style={{ verticalAlign: '-moz-middle-with-baseline' }}
                        placeholder='Select repositories'
                    ></MultiSelect>
                </div>
                <div className='treetable-responsive-demo' style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, marginRight: 0 }}>
                    <TreeTable value={orgData}
                        rowClassName={rowClassName}
                        scrollable
                        scrollHeight='65vh'
                        width="95vw"
                        className="treetable-with-header"
                        expandedKeys={this.state.expandedKeys}
                        onToggle={e => this.setState({ expandedKeys: e.value })}>
                        <Column field='name' body={nameTemplate} header='Orgs./Suborgs.' expander style={{ width: "200px" }} />
                        {repositoryColumns}
                    </TreeTable>
                </div>
                <ConfirmationDialog
                    displayDialog={this.state.displayConfirmationDialog}
                    onHide={() => this.onHideConfirmationDialog()}
                    onSubmit={() => this.onConfirmClick('all')}
                    onSubmitSecondChoice={() => this.onConfirmClick('none')}
                    headerText={`Activate repository "${this.state.selectedRep.label}" for "${this.state.selectedOrg.label}"`}
                    messageText={`By clicking "For all suborganizations" this repository will be activated for the organization and all of its suborganizations.
                     Warning: The repository then will be visible for all users of these suborganizations immediately! If you only want to activate this repository for the organization click "Only for organization".
                      The repository then will not be visible for the users of all suborganizations. The repository needs to be activated for a certain suborganization in the next step.`}
                    submitButtonLabel="For all suborganizations"
                    cancelButtonLabel="Only for organization"
                    secondChoice={true}>
                </ConfirmationDialog>
            </Dialog>

        )
    }
}

export default SetupRepository