import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { Toast } from 'primereact/toast';
import { runBRATGenerator } from '../../../../api/content/FrameEditorApi';
import { checkResultAndGetPayload, getHeaderToken, sendExportToFileApiRequest } from '../../../../api';
import SampleText from '../general/SampleText';

const minusHeight = '215px';

class TestingView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 'The cathode material has a thickness of 1.1 nm.\n\nLiu et al. demonstrated a novel SOFC concept comprising a Ni/YSZ anode.\n\nSolomon et al. measured the power density of a SOFC containing Nickel anode.\n\nThe thermal expansion coefficient (TEC) of YSZ is 10 °C-1.'
            sampleText: '',
        };
    }


    componentDidMount() {

        /*
        fetch('/ping')
            .then(function (response) {
                if (response.status == 200) {
                    console.log('%c Server available! ', 'background: #20c997; color: #000');
                }
            })
            .catch(function (err) {
                console.log('fetch failed! ', err);
            });
        */

    /*    window.addEventListener('load', function () {
            //console.log("*** ADD EVENT LISTENER");
            //console.log('navigator', navigator);
            //console.log('navigator.serviceWorker', navigator?.serviceWorker);
            navigator?.serviceWorker?.register('/request-interceptor.js')
                .then(function (registration) {
                    //console.log('registration', registration);
                    console.log('Service worker registered with scope: ', registration.scope);
                }, function (err) {
                    console.log('ServiceWorker registration failed: ', err);
                });
        });
   */ }



    /**
     * 
     */
    onSampleTextChange = (text) => {
        this.setState({
            sampleText: text
        });
    }

    /**
     * 
     */
    onSampleTextClear = () => {
        this.setState({
            sampleText: ''
        });
    }


    /**
     * 
     */
    onRunRelationExtraction = async () => {
        this.setState({
            bratUrl: '',
            fetchingBrat: true
        });

        const response = await runBRATGenerator(this.state.sampleText, this.props.history);
        const result = checkResultAndGetPayload(response, this.growl);
        let bratUrl = result?.bratUrl ? result.bratUrl.replace('DOCBASEDIR', '') : null;
        //let bratUrl = result?.bratUrl ? result.bratUrl : null;

        // http://oc36:8643/brat/index.xhtml#/annotations/custom-ocminer/devel/devel_DOCBASEDIR_text
        // http://oc36:8643/brat/index.xhtml?token=TOKEN#/annotations/custom-ocminer/devel/devel_DOCBASEDIR_text

        //bratUrl = "http://localhost:8642/webapp-ocminer-suite-manager/api/v1/brat";

        //console.log('result.bratUrl', result?.bratUrl);
        //console.log('bratUrl', bratUrl);

        this.setState({
            bratUrl: bratUrl,
            fetchingBrat: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="grid dashboard" style={{ height: '100%', width: '100%' }}> {/*style={{ height: 'calc(100vh - 250px)' }}*/}
                    {/*<div className="col-4" style={{ textAlign: 'left', padding: '15px 7px', color: '#e91e63' }}>
                        <div></div>
                    </div>
                    <div className="col-8" style={{ textAlign: 'right', padding: '10px 3px' }}>
                        <Button label="Run relation extraction"
                            className="secondary-btn"
                            icon="pi-md-send"
                            style={{ marginLeft: "20px" }}
                            onClick={() => this.onRunRelationExtraction()} >
                        </Button>
                    </div>*/}
                    <div className="col-12">
                        <div className="grid">
                            <div className="col-4">
                                <SampleText
                                    sampleText={this.state.sampleText}
                                    onSampleTextChange={this.onSampleTextChange}
                                    onSampleTextClear={this.onSampleTextClear}
                                    onButtonClick={this.onRunRelationExtraction}
                                    buttonLabel="Run relation extraction"
                                    contentBoxHeight={`calc(100vh - ${minusHeight})`}
                                />
                            </div>
                            <div className="col-8">
                                <div className="task-box task-box-dark-blue shadowMedium">
                                    <div className="task-box-header">
                                        BRAT output
                                        <i className="material-icons"
                                            title="Clear BRAT output"
                                            style={{ cursor: 'pointer', marginLeft: 10 }}>
                                            delete_forever
                                        </i>
                                    </div>
                                    <div className="task-box-content"
                                        style={{ padding: '10px', overflow: 'auto', height: `calc(100vh - ${minusHeight})` }}> {/*, height: 445*/}
                                        {/*http://brat/#/annotations/bosch/test/part000/test_test_test_SOFC_WS3_000*/}
                                        <LoadingOverlay
                                            active={this.state.fetchingBrat}
                                            spinner={true} >
                                            {this.state.bratUrl ?
                                                <iframe
                                                    src={this.state.bratUrl}
                                                    title='BRAT'
                                                    width="100%" height="100%">
                                                </iframe>
                                                :
                                                <div>No data.</div>
                                            }
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => { this.growl = el }} appendTo={document.body} />
            </React.Fragment>
        );
    }
}

export default withRouter(TestingView);