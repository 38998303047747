import _ from "lodash";
import { convertStructuresToImagesV2, createStructuresToImagesRequestV2, fetchMculeData } from "../../../../api/content/ChemistryApi";
import { createConceptDataRequest, fetchConceptsData } from "../../../../api/content/ConceptApi";
import { isArrayEmpty } from "../../util";

// --- sorting of results --- //
export const SORT_BY_FIELD_OCID = 'ocid';
export const SORT_BY_FIELD_ABS_WEIGHT = 'AbsoluteWeight';
export const SORT_BY_FIELD_REL_WEIGHT = 'RelativeWeight';
export const SORT_BY_FIELD_C_LOG_P = 'CLogP';
export const SORT_BY_FIELD_SURFACE_AREA = 'TotalSurfaceArea';
export const SORT_BY_FIELD_SOLUBILITY = 'Solubility';
export const SORT_BY_FIELD_ROTATABLE_BOND_COUNT = 'RotatableBondCount';
export const SORT_BY_FIELD_H_ACCEPTORS_COUNT = 'HydrogenAcceptorCount';
export const SORT_BY_FIELD_H_DONORS_COUNT = 'HydrogenDonorCount';
export const SORT_BY_FIELDS = [
    { id: SORT_BY_FIELD_ABS_WEIGHT, label: 'Absolute molecular weight' },
    { id: SORT_BY_FIELD_REL_WEIGHT, label: 'Relative molecular weight' },
    { id: SORT_BY_FIELD_C_LOG_P, label: 'CLogP' },
    { id: SORT_BY_FIELD_H_ACCEPTORS_COUNT, label: 'Number of hydrogen acceptors' },
    { id: SORT_BY_FIELD_H_DONORS_COUNT, label: 'Number of hydrogen donors' },
    { id: SORT_BY_FIELD_ROTATABLE_BOND_COUNT, label: 'Number of rotatable bonds' },
    { id: SORT_BY_FIELD_OCID, label: 'OCID' },
    { id: SORT_BY_FIELD_SOLUBILITY, label: 'Solubility' },
    { id: SORT_BY_FIELD_SURFACE_AREA, label: 'Total surface area' },
];

export const SORT_DIRECTION_ASCENDING = 'asc';
export const SORT_DIRECTION_DESCENDING = 'desc';
export const SORT_DIRECTIONS = [
    { id: SORT_DIRECTION_ASCENDING, label: 'ascending' },
    { id: SORT_DIRECTION_DESCENDING, label: 'descending' },];

export const SORT_BY_FIELD_DEFAULT = SORT_BY_FIELDS[0];
export const SORT_DIRECTION_DEFAULT = SORT_DIRECTIONS[0];

// --- compound properties --- //
// -> TODO: 
export const COMPOUND_PROPERTY_OCID = 'ocid';
export const COMPOUND_PROPERTY_ABS_WEIGHT = 'absoluteWeight';
export const COMPOUND_PROPERTY_SURFACE_AREA = 'totalSurfaceArea';
export const COMPOUND_PROPERTY_SOLUBILITY = 'solubility';
export const COMPOUND_PROPERTY_C_LOG_P = 'clogP';
export const COMPOUND_PROPERTY_ROTATABLE_BOND_COUNT = 'rotatableBondCount';
export const COMPOUND_PROPERTY_H_ACCEPTORS_COUNT = 'hydrogenAcceptorCount';
export const COMPOUND_PROPERTY_H_DONORS_COUNT = 'hydrogenDonorCount';
export const COMPOUND_PROPERTY_FORMULA = 'formula';
export const COMPOUND_PROPERTY_SMILES = 'smiles';
export const COMPOUND_PROPERTY_INCHI = 'inchi';
export const COMPOUND_PROPERTY_INCHI_KEY = 'ikey';

// --- chemistry database search status --- //
export const CHEM_DB_STATE_LOADING = 'LOADING';
export const CHEM_DB_STATE_INITIALISED = 'INITIALISED';
export const CHEM_DB_STATE_ERROR = 'ERROR';
export const CHEM_DB_STATE_SUCCESS = 'SUCCESS';

export const STRUCTURE_IMAGE_SETTINGS = { height: 300, width: 600, format: 'svg' };

export const RESULT_IDENTIFIER_TYPE_OCID = 'ocid';
export const RESULT_IDENTIFIER_TYPE_SAVI_PRODUCT_ID = 'saviProductID';


/**
 * Returns the number of hits for a result object and start index. 
 * If total hits are known, the specific value is returned. 
 * If total hits are unknown, but there are no more results, we can compute the exact number of hits.
 * Otherwise number of fingerprint hits is returned and isFPHits is true.
 * @param {Object} result 
 * @param {Number} startIndex 
 * @returns 
 */
export const getNumberOfResults = (result, startIndex) => {
    let numberOfHits = -1;
    let isFPHitCount = false;
    // --- if result has number of total hits -> we know the exact hit count --- //
    if (result.hasOwnProperty('totalHits')) {
      numberOfHits = result.totalHits;
    }
    // FIXME: not working when paging is used to go back and nextPageKey is ot defined anymore
    // --- if result has no nextPageKey then there are no more results -> we can compute the exact number of results --- //
    // else if (!result.nextPageKey && _.isNumber(startIndex) && _.isNumber(result.numOfEntries)) {
    //     numberOfHits = startIndex + result.numOfEntries;
    // }
    // --- otherwise use number of fingerprint hits as an upper limit --- //
    else if (result.hasOwnProperty('numberFingerprintHits')) {
      numberOfHits = result.numberFingerprintHits;
      isFPHitCount = true;
    }
    return { numberOfHits, isFPHitCount };
}


/**
 * Fetches and adds additional data to concepts.
 * @param {*} ocids 
 * @param {*} startIndex 
 * @param {*} endIndex 
 * @param {*} extraInfoMap 
 * @returns 
 */
export const addConceptsData = async (ocids, startIndex, endIndex, ocidExtraDataMap, extraInfoMap, queryMol) => {
    let concepts = [];
    if (!isArrayEmpty(ocids)) {
        const currOcids = ocids.slice(startIndex, endIndex);
        const request = createConceptDataRequest(currOcids, true, true, true, false, 0, 0, true);
        // TODO: ocidExtraDataMap
        const response = await fetchConceptsData(request, true, false, true, // APP_PROPERTIES.CHEMISTRY.INCLUDE_MCULE_LINKS
            ocidExtraDataMap, STRUCTURE_IMAGE_SETTINGS, queryMol);
        // TODO: growl?
        if (response?.status === 'SUCCESS') {
            concepts = response.payload;
            // --- add additional data to each concept --- //
            if (extraInfoMap) {
                concepts?.forEach(concept => {
                    if (extraInfoMap[concept.ocid]) {
                        if (!concept.extendedData) { concept.extendedData = {} }
                        concept.extendedData = { ...extraInfoMap[concept.ocid], ...concept.extendedData };
                        //concept.chemData = { ...extraInfoMap[concept.ocid], ...concept.extendedData };
                        // delete concept.extendedData
                    }
                });
            }
        }
    }
    return concepts;
}

export const addImageData = async (compounds, addImage, addMculeLink, queryMol) => {
    //console.log('compounds: ', compounds);
    const smiles = compounds
        .filter(comp => (comp?.extendedData?.smiles && comp.extendedData.smiles[0]))
        .map(comp => { return comp.extendedData.smiles[0] });

    if (!isArrayEmpty(smiles)) {

        if (addImage) {
            const request = createStructuresToImagesRequestV2(smiles, STRUCTURE_IMAGE_SETTINGS.height, STRUCTURE_IMAGE_SETTINGS.width, STRUCTURE_IMAGE_SETTINGS.format, queryMol);
            const response = await convertStructuresToImagesV2(request);
            if (response?.status === 'SUCCESS') {
                const imagesMap = response.payload.images;
                if (imagesMap) {
                    for (var compound of compounds) {
                        if (compound.extendedData?.smiles && compound.extendedData.smiles[0]) {
                            const smiles = compound.extendedData.smiles[0];
                            if (imagesMap[smiles]) {
                                compound.image = imagesMap[smiles];
                            }
                        }
                    }
                }
            }
        }

        if (addMculeLink) {
            // --- request mcule links for unique smiles --- //
            const request = { smiles: smiles };
            const response = await fetchMculeData(request);
            //console.log('links: ', result);

            if (response?.status === 'SUCCESS' && response?.payload?.mculeLinks) {
                const mculeLinks = response.payload.mculeLinks;
                for (var compound2 of compounds) {
                    if (compound2.extendedData?.smiles && compound2.extendedData.smiles[0]) {
                        const smiles = compound2.extendedData.smiles[0];
                        if (mculeLinks[smiles]) {
                            if (!compound2.sourceReferences) {
                                compound2.sourceReferences = [];
                            }
                            const mculeLink = {
                                sourceName: 'Mcule',
                                sourceUrl: 'https://mcule.com/',
                                conceptUrl: mculeLinks[smiles],
                                conceptRefId: mculeLinks[smiles].replace('https://mcule.com/', '').replace(/\/$/, '')
                            };
                            compound2.sourceReferences.push(mculeLink);
                            compound2.extendedData.mculeLink = mculeLink;
                        }
                    }
                }
            }
        }
    }

    return compounds;
}

export const addKeysToEntries = (entries, start = 0) => {
    let startKey = start;
    entries?.forEach(entry => entry.key = ++startKey);
    return entries;
}

export const mergeWithPreviousResults = (prevResultEntries, newResultEntries, prevStart, prevMaxLoadedPage, e, prevNextPageKey, prevMaxLoadedEntry) => {

    /*onsole.log('prevResultEntries: ', prevResultEntries);
    console.log('newResultEntries: ', newResultEntries);
    console.log('prevStart: ', prevStart);
    console.log('prevMaxLoadedPage: ', prevMaxLoadedPage);
    console.log('prevNextPageKey: ', prevNextPageKey);*/
    //console.log('event: ', e);

    let mergedResultEntries = newResultEntries;
    let start = prevStart;
    let maxLoadedPage = prevMaxLoadedPage > 0 ? prevMaxLoadedPage : 0;
    let maxLoadedEntry = prevMaxLoadedEntry > 0 ? prevMaxLoadedEntry : 0;
    if (prevNextPageKey && e && prevResultEntries) {
        mergedResultEntries = [...prevResultEntries, ...newResultEntries];
        if (e) {
            start = e.first;
            maxLoadedPage = e.page > maxLoadedPage ? e.page : maxLoadedPage;
            maxLoadedEntry = e.first + e.rows > maxLoadedEntry ? e.first + e.rows : maxLoadedEntry;
        }
    }

    return { mergedResultEntries, start, maxLoadedPage, maxLoadedEntry };
}