//import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ConfirmationDialog from "../../../common/ConfirmDialog/ConfirmationDialog";
import { Toast } from "primereact/toast";
//import { useEffect, useRef, useState } from "react";
import { useRef, useState } from "react";
import { AppsService } from '../core/services/dataTableService';
import './ToastContent.css';
const ToastContent = ({
    selectedApps,
    onHide,
    onReload
}) => {
    const customToast = useRef();
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

    const confirm = () => {
        setDisplayConfirmDialog(true)
    };

    const accept = () => {
        const service = new AppsService();
        const appIds = selectedApps?.map(item => item?.id)
        if (appIds?.length > 0) {
            service.deleteApps(appIds).then(res => {
                customToast.current.show({ severity: 'success', summary: 'Success', detail: 'App successfully deleted', life: 6000 });
                onReload()
                onHide()

            }).catch(err => {
                customToast.current.show({ severity: 'error', summary: 'Error', detail: err?.message, life: 6000 });
            });
        }
    }

    const reject = () => {
        setDisplayConfirmDialog(false)
    }

    return (
        <div className=" toast-container" >
            <Toast ref={customToast} />
            <div className="toast-text-part">
                {selectedApps?.length} {selectedApps?.length > 1 ? `apps selected` : `app selected`}
            </div>
            
            <div className="toast-icons-material pointer" onClick={confirm} style={{}}>
                <div>
                    <span className='material-symbols-outlined'>{'delete_forever'}</span>
                </div>
                <div style={{ marginTop: 3 }}>Delete</div>
            </div>
            <div className="toast-icons-close pointer" onClick={onHide} >
                <span className='material-symbols-outlined'>{'close'}</span>
            </div>
            <ConfirmationDialog
                displayDialog={displayConfirmDialog}
                onHide={reject}
                onSubmit={accept}
                headerText="Confirm"
                messageText={`Would you like to delete ${selectedApps?.length > 1 ? "these apps" : "this app"}?`}
                submitButtonLabel="Delete" />
        </div>
    );
}

export default ToastContent