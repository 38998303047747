import { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";


class FilterSectionInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="searchFiltersInfo"
                title="Click for information about search filters"
                imageAltText="Search filters information"
                overlayContent={
                    <>
                        <h3>Search filters</h3>
                        <p>
                            Some filters are only available for specific input.
                            E.g. the "Search in" and "Search mode" filters can only be used if the search contains at least
                            one concept. The "Concept distance" filter can only be applied if at least two concepts are present.
                            Other filters, like date filters, can only be added once.
                        </p>
                    </>
                }
            />
        );
    }
}

export default FilterSectionInfo;