import React, {useRef} from 'react';
import {OverlayPanel} from "primereact/overlaypanel";
import TermMenu from "../../../common/TermMenu/TermMenu";
import styles from "./BiomarkerPopupNode.module.scss";
import classNames from "classnames";
import {setBiomarkerQueryTerms} from "../../../../redux/actions/BiomarkerActions";
import {useSelector} from "react-redux";
import {biomarkerQueryTerms} from "../../../../redux/selectors/selectors";

export const BiomarkerPopupNode = React.forwardRef(({node, showToast, targetToScroll}, ref) => {
        const overlayPanel = useRef(null);
        const queryTerms = useSelector(biomarkerQueryTerms);

        const treeNode = classNames(styles.item, targetToScroll === node.key ? styles.target : '');
        const ocids = [node.ocid];

        const showOverlayPanel = (event) => {
            overlayPanel.current.show(event);
        }
        const hideOverlayPanel = (event) => {
            overlayPanel.current.hide(event);
        }

        return (
            <>
                <OverlayPanel ref={overlayPanel} id='biomarker-label-term-overlaypanel'>
                    <TermMenu
                        ocids={ocids} originalTerms={node.label}
                        hideOverlayPanel={hideOverlayPanel}
                        showToast={showToast}
                        fieldName={node.fieldName}
                        queryTerms={queryTerms}
                        setQueryTerms={setBiomarkerQueryTerms}
                    />
                </OverlayPanel>
                <p ref={targetToScroll === node.key ? ref : null} onClick={showOverlayPanel}
                   className={treeNode}>{node.label}</p>
            </>
        );
    }
);
