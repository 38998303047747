import { sendApiRequest } from '../index';
import { APP_PROPERTIES } from '../../properties/index';
import { isArrayEmpty } from '../../components/webapi/util';


// ----------------------------------------------------------------------- //
// --- run requests ------------------------------------------------------ //
// ----------------------------------------------------------------------- //
/**
 * 
 */
export const getRepositoryCatalog = async () => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/repositories`);

    return result;
}

/**
 * Fetches repository ID for repository name.
 * 
 * @param {string} repositoryName 
 * @param {Boolean} returnDefaultIfNotDefined 
 */
export const getRepositoryForName = async (repositoryName, returnDefaultIfNotDefined = true) => {

    // --- get repository ID for repository name (if there are multiple the one from default backend is returned) --- //
    const resultID = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/repository-id?repositoryName=${repositoryName}`);
    //console.log('+resultID. ', resultID);

    let repoID = -1;
    if (resultID.status === "SUCCESS") {
        repoID = resultID.payload;
    }

    // --- fetch all available repositories and return the one matching the repository ID --- //
    if (repoID > 0 || returnDefaultIfNotDefined) {
        const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/user/repositories`);

        if (result.status === "SUCCESS" &&
            !isArrayEmpty(result?.payload)) {

            const repositories = result.payload;
            if (!isArrayEmpty(repositories)) {
                //console.log('+repositories. ', repositories);
                const repositoriesWithID = repositories.filter(repo => !!repo.active && repo.id === repoID);
                //console.log('+defaultRepositories. ', defaultRepositories);
                if (!isArrayEmpty(repositoriesWithID)) {
                    result.payload = repositoriesWithID[0];
                    return result;
                }
                else if (returnDefaultIfNotDefined && !!repositories[0].active) {
                    result.payload = repositories[0];
                    return result;
                }
            }
        }
    }

    return { status: 'FAILED', statusCode: 520, message: 'Could not retrieve matching repository data.' };
}

