import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";

const GlobalGrowl = () => {
  const growl = useRef(null);

  const growlMessage = useSelector(
    (state) => state.advancedSearch3Reducer.growlMessage
  );

  // TODO: move to upper component, if it works
  useEffect(() => {
    if (growlMessage) {
      growl.current.show(growlMessage);
    }
  }, [growlMessage]);

  return <Toast ref={growl} />;
};

export default GlobalGrowl;
