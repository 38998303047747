/* eslint-disable jsx-a11y/anchor-is-valid */
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import React, { Component, createRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import SeparatorPoint from '../../../general/SeparatorPoint';
import { roundNumber } from '../../../util';
import PropertiesDetails from './PropertiesDetails';


class PropertiesList extends Component {

    constructor(props) {
        super(props);

        this.growl = createRef();
    }

    showSuccessGrowl = () => {
        this.growl.current.show({
            sticky: false,
            severity: 'success',
            summary: 'Success',
            detail: `Copied to clipboard.`
        });
    }


    render() {

        const { compoundProperties, propertiesDefinitionsFiltered, propertiesDefinitionsAll, cutOff } = this.props;

        let count = 0;
        let stop = false;

        return (
            <>
                <Toast ref={this.growl} />

                {propertiesDefinitionsFiltered.filter(ei => {
                    const extraInfoData = compoundProperties ? compoundProperties[ei.id] : null;
                    if (!!cutOff && count === cutOff) {
                        stop = true;
                    }
                    count++;
                    return (extraInfoData && !stop);
                }).map((ei, index) => {
                    const extraInfoData = compoundProperties ? compoundProperties[ei.id] : null;
                    const value = ei.roundNumber ? roundNumber(extraInfoData, 1) : extraInfoData;

                    return (
                        <span key={ei.id} style={{ lineHeight: 1.5 }}>
                            <span className='whiteSpaceNoWrap'>
                                {ei.hideValue ?
                                    <>
                                        <CopyToClipboard
                                            text={value}>
                                            <a title={`Click to copy ${ei.info} to clipboard: ${value}`}
                                                className='secondaryLink secondaryInfo'
                                                style={{ fontWeight: 'bold' }}
                                                onClick={(e) => this.showSuccessGrowl()}>
                                                {ei.label}
                                            </a>
                                        </CopyToClipboard>
                                    </>
                                    :
                                    <>
                                        <span className='secondaryInfo' style={{ marginRight: 5 }} title={ei.info}>{ei.label} </span>
                                        <CopyToClipboard
                                            text={value}>
                                            <a title={`Click to copy ${ei.info} to clipboard: ${value}`}
                                                className='secondaryLink secondaryInfo'
                                                style={{ fontWeight: 'bold' }}
                                                onClick={(e) => this.showSuccessGrowl()}>
                                                {value}
                                            </a>
                                        </CopyToClipboard>
                                    </>}
                            </span>
                            {compoundProperties[propertiesDefinitionsFiltered[index + 1]?.id] ? <SeparatorPoint /> : null}
                            {/* &#8203; breakable zero-width space */}
                        </span>
                    )
                })}

                {stop &&
                    <React.Fragment key={'more'}>
                        <a title={'Show all properties'}
                            onClick={e => this.showAllOverlay.toggle(e)}>
                            Show all
                        </a>
                        <OverlayPanel
                            ref={(el) => this.showAllOverlay = el}
                            appendTo={document.body}
                            //style={{ maxWidth: '40vw', minWidth: 300, maxHeight: '50vh', fontSize: 13, overflow: 'auto' }}
                            style={{ maxWidth: 500 }}
                            dismissable={true}>
                            <PropertiesDetails
                                compoundProperties={compoundProperties}
                                propertiesDefinitions={propertiesDefinitionsAll ? propertiesDefinitionsAll : propertiesDefinitionsFiltered}
                            />
                        </OverlayPanel>
                    </React.Fragment>
                }
            </>
        )
    }
}
export default PropertiesList