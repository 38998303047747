import React, { Component } from 'react'
import { getHeaderToken } from '../../api/index'
import axios from 'axios'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { APP_PROPERTIES } from '../../properties';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';

class AddRepository extends Component {

    constructor(props) {
        super(props);
        this.state = {
            repositoryLabel: '',
            repositoryName: '',
            repositoryAbbreviation: '',
            repositoryDescription: '',
            repositoryFeatures: [],
            apiLabel: 'OntoChem',
            apiName: 'oc-webapi',
            apiAbbreviation: 'OC',
            backend: null
        }
    }

    componentDidMount() {
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/backend-apis`, { headers: getHeaderToken() })
            .then(function (response) {
                let allBackends = []
                response.data.forEach(backend => {
                    if (backend.type === 'webapi' || backend.type === 'chemistry') {
                        allBackends = [...allBackends, { label: `${backend.label} (${backend.name})`, value: backend.id }]
                    }
                })
                self.setState({
                    allBackends: allBackends
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    onAddRepository = () => {
        this.props.onAddRepository(this.state.repositoryLabel, this.state.repositoryName, this.state.repositoryAbbreviation, this.state.apiLabel,
            this.state.apiName, this.state.apiAbbreviation, this.state.backend, this.state.repositoryFeatures, this.state.repositoryDescription)
    }

    render() {

        return (
            <Dialog visible={this.props.displayAddRepository} style={{ 'width': "70vw" }} header="Add new repository" focusOnShow={false}
                modal={true}
                dismissableMask={false} blockScroll
                onShow={() => {
                    // load initial values into the form (e.g. from a copied repo)
                    this.setState({
                        repositoryLabel: this.props.label || '',
                        repositoryName: this.props.name || '',
                        repositoryAbbreviation: this.props.abbreviation || '',
                        repositoryDescription: this.props.description || '',
                        repositoryFeatures: this.props.features || [],
                        backend: this.props.backend || null
                    })
                }}
                onHide={() => this.props.onCancelAddRepository()} className='styledDialog'>
                <Toast ref={(el) => { this.growl = el }} />
                <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className={APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ? "col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" : "col-12 sm:col-12 md:col-12 lg:col-6 xl:col-4"} style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="repositoryName">Repository name *</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} id="repositoryName"
                            onChange={(e) => { this.setState({ repositoryName: e.target.value }) }} value={this.state.repositoryName} />
                    </div>
                    <div className={APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ? "col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" : "col-12 sm:col-12 md:col-12 lg:col-6 xl:col-4"} style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="repositoryLabel">Repository label (for displaying) *</label>
                        <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} id="repositoryLabel"
                            onChange={(e) => { this.setState({ repositoryLabel: e.target.value }) }} value={this.state.repositoryLabel} />
                    </div>
                    <div className={APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ? "col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12" : "col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12"} style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="repositoryDescription">Repository description</label>
                        <InputTextarea style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5 }} id="repositoryDescription" maxLength={300}
                            onChange={(e) => { this.setState({ repositoryDescription: e.target.value }) }} value={this.state.repositoryDescription} />
                        <label style={{ color: 'darkgray' }}>Max. 300 characters</label>
                    </div>
                    <div className={APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ? "col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" : "col-12 sm:col-12 md:col-12 lg:col-6 xl:col-4"} style={{ padding: '.5em', paddingBottom: 15 }}>
                        <label htmlFor="repositoryFeatures">Repository features</label>
                        <MultiSelect
                            pt={{
                                checkboxIcon: {
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        if (e.target.className.baseVal !== "") {
                                            e.target.parentNode.click();
                                        } else {
                                            e.target.parentNode.parentNode.click();
                                        }
                                    },
                                },
                                headerCheckbox: {
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        e.target.parentNode.click();
                                    },
                                },
                            }}
                            maxSelectedLabels={3}
                            id="repositoryFeatures"
                            style={{ width: '100%', marginTop: 7 }}
                            value={this.state.repositoryFeatures}
                            options={this.props.allAvailableRepositoryFeatures ? Object.values(this.props.allAvailableRepositoryFeatures) : []}
                            optionValue="id"
                            optionLabel="label"
                            onChange={(e) => this.setState({ repositoryFeatures: e.value })}
                            showClear={true}
                            placeholder='No features selected'
                            scrollHeight="400px"
                        />
                    </div>
                    {APP_PROPERTIES.ACTIVE_PAGES.backendAdministration &&
                        <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="backend">Backend *</label>
                            <Dropdown style={{ width: '100%', marginTop: 5 }}
                                className='dropdownNoBorder'
                                appendTo={document.body}
                                value={this.state.backend}
                                options={this.state.allBackends}
                                onChange={(e) => this.setState({ backend: e.value })}
                                placeholder='Select backend'></Dropdown>
                        </div>
                    }
                </div>
                <div className='col-12' style={{ paddingRight: 15, marginTop: 5, marginBottom: 25 }}>
                    <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 15 }}>* required</label>
                    <Button label="Save"
                        className='primaryButton p-button-sm'
                        disabled={(this.state.repositoryLabel !== '' && this.state.repositoryName !== ''
                            && this.state.apiLabel !== '' && this.state.apiName !== '' && this.state.apiAbbreviation !== '') ? false : true}
                        onClick={this.onAddRepository}
                        style={{ marginRight: 5, float: 'right' }} />
                    <Button label="Cancel"
                        className="p-button-secondary p-button-sm"
                        onClick={this.props.onCancelAddRepository}
                        style={{ float: 'right', marginRight: 5 }} />
                </div>
            </Dialog>
        )
    }
}

export default AddRepository