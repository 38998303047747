import * as ActionTypes from '../actions'

const initState = {
    fetching: null,
    fetchingToken: false,
    fetchingCompounds: null,
    terms: [],
    relations: [],
    documents: {},
    sortFields: {},
    similarDocuments: {},
    relationDocuments: {},
    ipcClasses: [],
    publications: [],
    editedQuery: [],
    queries: {},
    removedQuery: [],
    statistics: {
        results: []
    },
    formQueries: {
        content: []
    },
    propertyTree: [],
    pdf: [],
    compoundHits: {
        results: []
    }
}

const webAPI = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_TERM_SUGGESTIONS:
            return {
                ...state,
                terms: action.terms
            }
        case ActionTypes.FETCH_RELATIONS:
            return {
                ...state,
                relations: action.relations
            }
        case ActionTypes.FETCH_DOCUMENTS:
            return {
                ...state,
                documents: action.documents
            }
        case ActionTypes.ADD_PUBLICATIONS:
            return {
                ...state,
                publications: action.publications
            }
        case ActionTypes.FETCH_SORTFIELDS:
            return {
                ...state,
                sortFields: action.sortFields
            }
        case ActionTypes.FETCH_PDF:
            return {
                ...state,
                pdf: action.pdf
            }
        case ActionTypes.FETCH_RELATIONS_DOCUMENTS:
            return {
                ...state,
                relationDocuments: action.relationDocuments
            }
        case ActionTypes.FETCH_SIMILARDOCUMENTS:
            return {
                ...state,
                similarDocuments: action.similarDocuments
            }
        case ActionTypes.ADD_PUBLICATIONLIST_XLSX:
            return {
                ...state,
                publicationListXLSX: action.publicationListXLSX
            }
        case ActionTypes.ADD_RELATIONLIST_XLSX:
            return {
                ...state,
                relationListXLSX: action.relationListXLSX
            }
        case ActionTypes.FETCH_IPC_CLASSES:
            return {
                ...state,
                ipcClasses: action.ipcClasses
            }
        case ActionTypes.FETCH_PROPERTY_TREE:
            return {
                ...state,
                propertyTree: action.propertyTree
            }
        case ActionTypes.ADD_ALL_STATISTICS:
            return {
                ...state,
                statistics: action.statistics
            }
        case ActionTypes.FETCH_QUERIES:
            return {
                ...state,
                queries: action.queries
            }
        case ActionTypes.FETCH_FORM_QUERIES:
            return {
                ...state,
                formQueries: action.formQueries
            }
        case ActionTypes.EDIT_QUERY:
            return {
                ...state,
                editedQuery: action.editedQuery
            }
        case ActionTypes.REMOVE_QUERY:
            return {
                ...state,
                removedQuery: action.removedQuery
            }
        case ActionTypes.ADD_ALL_COMPOUND_HITS:
            return {
                ...state,
                compoundHits: action.compoundHits
            }
        case ActionTypes.START_FETCHING:
            return {
                ...state,
                fetching: true
            }
        case ActionTypes.DONE_FETCHING:
            return {
                ...state,
                fetching: false
            }
        case ActionTypes.START_FETCHING_COMPOUNDS:
            return {
                ...state,
                fetchingCompounds: true
            }
        case ActionTypes.DONE_FETCHING_COMPOUNDS:
            return {
                ...state,
                fetchingCompounds: false
            }
        case ActionTypes.START_FETCHING_AVAILABLE_DOMAINS:
            return {
                ...state,
                fetchingAvailableDomains: true
            }
        case ActionTypes.DONE_FETCHING_AVAILABLE_DOMAINS:
            return {
                ...state,
                fetchingAvailableDomains: false
            }
        case ActionTypes.AVAILABLE_DOMAINS:
            return {
                ...state,
                availableDomains: action.availableDomains.domains
            }
            case ActionTypes.START_FETCHING_AVAILABLE_REPOSITORIES:
            return {
                ...state,
                fetchingAvailableRepositories: true
            }
        case ActionTypes.DONE_FETCHING_AVAILABLE_REPOSITORIES:
            return {
                ...state,
                fetchingAvailableRepositories: false
            }
        case ActionTypes.AVAILABLE_REPOSITORIES:
            return {
                ...state,
                availableRepositories: action.availableRepositories
            }
        case ActionTypes.START_FETCHING_REPOSITORY_OVERVIEW:
            return {
                ...state,
                fetchingRepositoryOverview: true
            }
        case ActionTypes.DONE_FETCHING_REPOSITORY_OVERVIEW:
            return {
                ...state,
                fetchingRepositoryOverview: false
            }
        case ActionTypes.REPOSITORY_OVERVIEW:
            return {
                ...state,
                repositoryOverview: action.repositoryOverview
            }
        case ActionTypes.START_FETCHING_SERVER_STATUS:
            return {
                ...state,
                fetchingServerStatus: true
            }
        case ActionTypes.DONE_FETCHING_SERVER_STATUS:
            return {
                ...state,
                fetchingServerStatus: false
            }
        case ActionTypes.SERVER_STATUS:
            return {
                ...state,
                serverStatus: action.serverStatus
            }

        default:
            return state
    }
}

export default webAPI
