import { Component } from "react";

import "../style/Highlight.css";

import type { LTWH } from "../types.js";

interface Props {
  position: {
    boundingRect: LTWH;
    rects: Array<LTWH>;
  };
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  isScrolledTo: boolean;
  annotations: any;
  activeSearchTerms: Array<any>;
  activeAnnotations: Array<string>;
  domains: Array<any>;
  isMatchOn: boolean;
  selectedSentence: any;
  sentenceId: any;
}

export class Highlight extends Component<Props> {
  
  render() {
    const {
      position,
      onClick,
      onMouseOver,
      onMouseOut,
      isScrolledTo,
      annotations,
      activeSearchTerms,
      activeAnnotations,
      domains,
      isMatchOn,
      selectedSentence,
      sentenceId
    } = this.props;
    const { rects } = position;
    let isDomainActive = false;
    let isSemanticActive = false;

    return (
      <div
        className={`Highlight ${isScrolledTo ? "Highlight--scrolledTo" : ""}`}
      >
        <div className="Highlight__parts">
          {annotations.length > 0 ?
            rects.map((rect, index) => (
              annotations?.map((item: any, i: number) => {
                let temp = activeSearchTerms.find(concept => concept?.id === item?.queryId)
                if (item?.queryId && temp) {
                  isSemanticActive = true;
                  return <div
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onClick={onClick}
                    id={index + '_' + item?.domain + i}
                    key={index + '_' + item?.domain + i}
                    className={`Highlight__part`}
                    style={{ ...rect, zIndex: 100, backgroundColor: `#${temp?.color}` }}
                  />
                }
                else if (activeAnnotations.includes(item.domain)) {
                  isDomainActive = true;
                  return <div
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onClick={onClick}
                    key={index + item?.domain + i}
                    id={index + '_' + item?.domain + i}
                    style={{
                      ...rect,
                      zIndex: 100,
                      backgroundColor: `#${domains?.find(obj => obj.name === item?.domain)?.color}`
                    }}
                    className={`Highlight__part`}
                  />
                }
                else if ((item?.queryTerm && !item.queryId && !isDomainActive && isMatchOn && !isSemanticActive) || (item?.queryTerm && item.queryId === 'ocFuzzyText:readcube_text' && !isDomainActive && isMatchOn && !isSemanticActive)) {
                  return <div
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onClick={onClick}
                    id={index + '_' + item?.domain + i}
                    key={index + '_' + item?.domain + i}
                    style={{ ...rect, zIndex: 100 }}
                    className={`Highlight__part ann_queryTerm_h`}
                  />
                }
              })

            ))
            :
            rects.map((rect, index) => (
              <div
                onClick={onClick}
                id={`${index}`}
                key={index}
                style={{ ...rect, zIndex: 100, backgroundColor: sentenceId === selectedSentence ? '#fcf492' : '#faf7d2' }}
                className={`Highlight__sentence`}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default Highlight;
