/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef, useCallback } from 'react'
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ToastContent from '../../common/CustomToast/ToastContent';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import InferencePathSearchComponent from './InferencePathSearchComponent'
import InferenceResultTable from './InferenceResultTable'
import SaveSearchDialog from '../../common/SaveSearch/SaveSearchDialog';
import SaveSearchButtons from '../../common/SaveSearch/SaveSearchButtons';
import SeparatorPoint from '../general/SeparatorPoint';
import { MultiSelect } from 'primereact/multiselect';
import NumberFormat from 'react-number-format';
import { OverlayPanel } from "primereact/overlaypanel";
import { TabView, TabPanel } from 'primereact/tabview';
import { APP_PROPERTIES } from '../../../properties/index';
import { QUERY_TYPE_FACT_SEARCH, QUERY_CATEGORY_FACT_SEARCH } from '../../../properties';
import { storeQuery, updateQuery } from '../../../api/content/WatchlistApi';
import { checkResultAndGetPayload } from '../../../api';
import { fetchUserShareStatusBlocklists } from "../../../api/content/SaveSearch";
import { createUserArray, createCheckedKeys } from "../../webapi/util";
import axios from 'axios'
import { getHeaderToken } from '../../../api/index'
import { hasUserRole } from '../util';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import infoIcon from "../../../../src/assets/images/icons/info/dimensions-icon-info.png";

class InferenceSearch extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);
        this.formats = [{ name: 'XLSX', key: 'xlsx' }//, { name: 'CSV', key: 'csv' } //, { name: 'GBQ', key: 'gbq' }
        ]
        this.state = {
            showMore: true,
            queryChanged: false,
            displayCustomToast: false,
            selectedFormatPaths: this.formats[0],
            selectedFormatEntities: this.formats[0],
            selectedFormatSentences: this.formats[0],
            pathNumber: 0,
            startTerm: [''],
            startTermShow: [''],
            startTermOCID: [''],
            startChecked: ['ontological'],
            endTerm: [''],
            endTermShow: [''],
            endTermOCID: [''],
            endChecked: ['ontological'],
            type: ['relations'],
            relation: [''],
            relationOCID: [''],
            pathRepositories: [[]],
            blacklists: [],
            pathBlacklists: [],
            selectedBlacklists: [],
            logicals: ['free'],
            queryComplete: [],
            queryOCIDS: [],
            queryNames: [],
            showChildren: false,
            pathOne: false,
            activeIndex: 2,
            disabledFull: true,
            disabledTriple: true,
            disabledSubPath: true,
            subPathNumber: 0,
            fullPathNumber: 0,
            tripleNumber: 0,
            rerenderTable: false,
            queryConceptsStart: [],
            queryConceptsEnd: [],
            selectedRepository: 'relation',
            filterTriple: [],
            filterSub: [],
            filterFull: [],
            relationOCIDMulti: [''],
            relationMode: ['ontological'],
            tripleHits: [''],
            subPathHits: [''],
            fullPathHits: [''],
            leftDomains: [['all domains']],
            rightDomains: [['all domains']],
            leftDomainsShow: [['all domains']],
            rightDomainsShow: [['all domains']],
            filterChecked: false,
            relationsChecked: 'narrow',
            numberOfHops: [1, 4],
            hopsOfTable: 1,
            hopsToShow: 1,
            hopData: [],
            hopRequests: [{}],
            filterZeroHops: [],
            filterOneHop: [],
            filterTwoHops: [],
            filterThreeHops: [],
            filterFourHops: [],
            pathsHops: [[]],
            countsHops: [""],
            preferredNamesHops: [{}],
            disabledHops: true,
            countFullPath: 20,
            startFullPath: 0,
            countSubPath: 20,
            startSubPath: 0,
            countTriplePath: 20,
            startTriplePath: 0,
            countZeroHopsPath: 20,
            startZeroHopsPath: 0,
            countOneHopPath: 20,
            startOneHopPath: 0,
            countTwoHopsPath: 20,
            startTwoHopsPath: 0,
            countThreeHopsPath: 20,
            startThreeHopsPath: 0,
            countFourHopsPath: 20,
            startFourHopsPath: 0,
            startTermDomains: [[]],
            endTermDomains: [[]],
            checkedEntities: [],
            autocompleteOrBrowserStart: [[]],
            autocompleteOrBrowserEnd: [[]],
            displayConfirmationDialog: false,
            displayExportDialog: false,
            rowsPaths: "all",
            rowsEntities: "all",
            rowsSentences: "all",
            activeIndexExport: 0,
            selectedEntityOption: 'unique',
            selectedColumnOption: 1,
            selectedNumberSentences: '1',
            selectedTriple: 1,
            selectedRowsTriple: [],
            selectedRowsSubPath: [],
            selectedRowsZeroHops: [],
            selectedRowsOneHop: [],
            selectedRowsTwoHops: [],
            selectedRowsThreeHops: [],
            selectedRowsFourHops: [],
            selectedRowsPath: [],
            displayDialogAddToBlacklist: false,
            displaySaveQueryDialog: false,
            queryFullName: '',
            queryName: '',
            queryDescription: '',
            queryString: '',
            relationNames: [['All possible relations']],
            relationValues: [[]],
            fromEdit: false,
            editQuery: false,
            departmentMembers: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            usersShared: [],
            orgShared: [],
            userArray: [],
            orgArray: [],
            usersWrite: [],
            displayDialogAddBlacklist: false
            //showFirstRelation: false
        }
        this.growl = createRef();
        this.table = createRef();
    }

    UNSAFE_componentWillMount = async () => {
        if (hasUserRole('ROLE_BLACKLISTS')) {
            let self = this;
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        blacklists: response.data
                    }, () => {
                        let pathBlacklists = []
                        response.data.forEach(blacklist => {
                            pathBlacklists = [...pathBlacklists, { label: blacklist.title, value: blacklist.id }]
                        })
                        self.setState({
                            pathBlacklists: pathBlacklists
                        }, async () => {
                            self.factFinderData = {}
                            if (localStorage && localStorage.factFinderData !== undefined) {
                                self.factFinderData = JSON.parse(localStorage.factFinderData)
                                localStorage.removeItem('factFinderData')
                            }
                            if (self.props.location && self.props.location.factFinderData !== undefined) {
                                self.factFinderData = JSON.parse(self.props.location.factFinderData)
                            }
                            if (Object.keys(self.factFinderData).length !== 0) {
                                const formContent = self.factFinderData.formContent ? JSON.parse(self.factFinderData.formContent) : {}
                                const editQuery = self.factFinderData.editQuery ? self.factFinderData.editQuery : false
                                const editQueryID = self.factFinderData.id ? self.factFinderData.id : null
                                const queryCollectionList = self.factFinderData.queryCollectionList ? self.factFinderData.queryCollectionList : []
                                let selectedQueryCollectionIDs = []
                                if (queryCollectionList.length > 0) {
                                    queryCollectionList.forEach(list => {
                                        selectedQueryCollectionIDs = [...selectedQueryCollectionIDs, list.id]
                                    })
                                }
                                let relationModes = []
                                if (formContent.relationMode === undefined) {
                                    for (let i = 0; i < formContent.pathNumber; i++) {
                                        relationModes = [...relationModes, 'ontological']
                                    }
                                }
                                let selectedBlacklists = [];
                                if (formContent.selectedBlacklists && hasUserRole('ROLE_BLACKLISTS')) {
                                    self.state.blacklists.forEach(bl => {
                                        if (formContent.selectedBlacklists.includes(bl.id)) {
                                            selectedBlacklists = [...selectedBlacklists, bl.id]
                                        }
                                    })
                                    const numOfMissingBlocklists = formContent.selectedBlacklists.length - selectedBlacklists.length;
                                    if (numOfMissingBlocklists > 0) {
                                        self.growl.current.show({
                                            sticky: false, closable: true, severity: 'warn', life: 10000,
                                            summary: 'Unavailable blocklists',
                                            detail: `${numOfMissingBlocklists} ${numOfMissingBlocklists > 1 ? 'blocklists are' : 'blocklist is'} not available`
                                        });
                                    }
                                }
                                await self.myCallbackFromClear()
                                self.setState({
                                    allRightDomainForStart: formContent.allRightDomainForStart,
                                    allLeftDomainForStart: formContent.allLeftDomainForStart,
                                    allLeftDomainForStartShow: formContent.allLeftDomainForStartShow,
                                    autocompleteOrBrowserStart: formContent.autocompleteOrBrowserStart,
                                    autocompleteOrBrowserEnd: formContent.autocompleteOrBrowserEnd,
                                    endChecked: formContent.endChecked,
                                    endTerm: formContent.endTerm,
                                    endTermDomains: formContent.endTermDomains,
                                    endTermOCID: formContent.endTermOCID,
                                    endTermShow: formContent.endTermShow,
                                    leftDomains: formContent.leftDomains,
                                    leftDomainsShow: formContent.leftDomainsShow,
                                    logicals: formContent.logicals,
                                    pathNumber: formContent.pathNumber,
                                    pathRepositories: formContent.pathRepositories,
                                    relation: formContent.relation,
                                    relationOCID: formContent.relationOCID,
                                    relationValues: formContent.relationValues,
                                    relationOCIDMulti: formContent.relationOCIDMulti,
                                    relationNames: formContent.relationNames,
                                    rightDomains: formContent.rightDomains,
                                    rightDomainsShow: formContent.rightDomainsShow,
                                    startChecked: formContent.startChecked,
                                    startTerm: formContent.startTerm,
                                    startTermDomains: formContent.startTermDomains,
                                    startTermOCID: formContent.startTermOCID,
                                    startTermShow: formContent.startTermShow,
                                    type: formContent.type,
                                    selectedBlacklists: selectedBlacklists,
                                    filterChecked: formContent.filterChecked,
                                    relationsChecked: formContent.relationsChecked ? formContent.relationsChecked : "narrow",
                                    relationMode: formContent.relationMode ? formContent.relationMode : relationModes,
                                    fromEdit: true,
                                    editQuery: editQuery,

                                    fromEditQuery: self.factFinderData.edit,
                                    editQueryID: editQueryID,
                                    queryDescriptionEdit: self.factFinderData.description,
                                    queryFullNameEdit: self.factFinderData.fullName,
                                    queryNameEdit: self.factFinderData.name,
                                    queryCollectionListEdit: queryCollectionList,
                                    selectedQueryCollectionIDsEdit: selectedQueryCollectionIDs
                                }, async () => {
                                    if (self.state.pathNumber < 2) {
                                        await self.myCallbackFromExecuteSinglePath(self.state.pathNumber, true)
                                    } else {
                                        for (let i = 1; i <= self.state.pathNumber; i++) {
                                            await self.myCallbackFromExecuteSinglePath(i, true)
                                        }
                                        await self.handleSearch(self.state.pathNumber, true)

                                    }
                                });
                            } else {
                                let unfilteredRepositories = self.props.userDetails.department?.selectedRepositories
                                let filteredReps = []
                                for (let i = 0; i < unfilteredRepositories.length; i++) {
                                    filteredReps = [...filteredReps, unfilteredRepositories[i].name]
                                }
                                await self.myCallbackFromClear()
                                self.myCallbackFromStart(self.state.pathNumber + 1, ['relations'], [filteredReps], ['aaa'],
                                    ['all relations'], [['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588', '232000000879', '232000001070', '232000000120', '232000000317']], [""], [""], [""], ["ontological"],
                                    [""], [""], [""], ["ontological"], self.state.fromEdit, ['ontological'])
                            }
                        })
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }

        this.updateUserShareStatus()
    }

    componentDidUpdate(prevProps) {
        if (this.props.userData.userDetails !== prevProps.userData.userDetails) {
            if (hasUserRole('ROLE_BLACKLISTS')) {
                let self = this;
                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.setState({
                            blacklists: response.data
                        }, () => {
                            let pathBlacklists = []
                            response.data.forEach(blacklist => {
                                pathBlacklists = [...pathBlacklists, { label: blacklist.title, value: blacklist.id }]
                            })
                            self.setState({
                                pathBlacklists: pathBlacklists
                            })
                        })
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
            this.updateUserShareStatus()
        }
    }

    updateUserShareStatus = async () => {

        const response = await fetchUserShareStatusBlocklists();
        const result = checkResultAndGetPayload(response, this.growl);

        if (result) {
            this.setState({
                departmentMembers: result
            }, () => {
                let userArray = createUserArray(result, this.props.userData, 'user')
                let orgArray = createUserArray(result, this.props.userData, 'org')
                this.setState({
                    userArray: userArray,
                    orgArray: orgArray
                })
            });
        }
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.state.orgArray && this.state.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.state.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.state.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.state?.departmentMembers, this.state.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.state.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        this.setState({
            usersWrite: members
        })
    }

    addNewBlacklist = () => {
        this.setState({
            displayDialogAddBlacklist: true,
            newBlacklistName: '',
            newBlacklistDescription: '',
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            checkedKeys: {},
            checkedOrgKeys: {}
        });
    }

    hideAddBlacklist = () => {
        this.setState({
            displayDialogAddBlacklist: false
        })
    }

    updateBlacklistName = (name) => {
        this.setState({
            newBlacklistName: name
        })
    }

    updateBlacklistDescription = (desc) => {
        this.setState({
            newBlacklistDescription: desc
        })
    }

    onSaveBlacklist = async () => {
        if (hasUserRole('ROLE_BLACKLISTS')) {
            let usersRead = []
            if (this.state.usersShared?.length > 0) {
                if (this.state.usersWrite?.length > 0) {
                    usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
                } else {
                    usersRead = this.state.usersShared
                }
            }
            let departments = []
            this.state.orgShared.forEach(dep => {
                departments = [...departments, Number(dep)]
            })
            var self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, {
                title: self.state.newBlacklistName,
                description: self.state.newBlacklistDescription,
                departmentSharesRead: departments,
                userSharesRead: usersRead,
                userSharesWrite: this.state.usersWrite
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    let blacklists = response.data
                    self.setState({
                        displayDialogAddBlacklist: false
                    }, () => self.myCallbackFromAddBlacklist(blacklists))
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showCreateBlacklistFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.setState({
                            displayDialogAddBlacklist: false
                        })
                        console.log("not found")
                    }
                })
        }
    }


    showCreateBlacklistFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl?.current?.show(msg);
    }

    handleSearch = async (number, fromAdd, useFilter, relationsMode) => {
        if (fromAdd === undefined) {
            if (this.toastBC.current) {
                this.toastBC.current.clear()
            }
            this.setState({
                queryChanged: false,
                countFullPath: 20,
                startFullPath: 0,
                filterFull: [],
                checkedEntities: [],
                disabledFull: true,
                disabledTriple: true,
                disabledSubPath: true,
                disabledHops: true,
                selectedFormatPaths: this.formats[0],
                selectedFormatEntities: this.formats[0],
                selectedFormatSentences: this.formats[0],
                //pathNumber: 0,
                activeIndex: 2,
                subPathNumber: 0,
                fullPathNumber: 0,
                tripleNumber: 0,
                numberOfHops: [1, 4],
                hopsOfTable: 1,
                hopsToShow: 1,
                selectedRowsPath: [],
                selectedRowsSubPath: [],
                selectedRowsTriple: [],
                selectedRowsZeroHops: [],
                selectedRowsOneHop: [],
                selectedRowsTwoHops: [],
                selectedRowsThreeHops: [],
                selectedRowsFourHops: [],
                displayCustomToast: false
                //showResults: false
            })
        }

        if (this.state.pathNumber === 1 && this.state.startTerm[0].length !== 0 && this.state.endTerm[0].length !== 0 && (this.state.relationOCID[0] === "" ||
            this.state.relationOCID[0].length === 0) &&
            fromAdd === undefined) {
            this.myCallbackFromSearchWithHops(1, 5, false)
        } else {
            let blacklistEntries = []

            if (this.state.selectedBlacklists && this.state.selectedBlacklists.length > 0) {
                for (let i = 0; i < this.state.selectedBlacklists.length; i++) {
                    if (hasUserRole('ROLE_BLACKLISTS')) {
                        await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${this.state.selectedBlacklists[i]}`, { headers: getHeaderToken() })
                            // eslint-disable-next-line
                            .then(function (response) {
                                let entries = response.data
                                blacklistEntries = [...blacklistEntries, entries]
                            })
                            .catch(function (error) {
                                if (error.response.status === 400) {
                                    this.showFailGrowl(error.response.data)
                                } else if (error.response.status === 401) {
                                    this.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                    }
                }
            }

            let negated = []

            blacklistEntries.forEach(entry => {
                entry.forEach(item => {
                    negated = [...negated, { ocid: item.ocid, negated: true, conceptLookupType: item.conceptLookupType }]
                })
            })

            this.setState({
                fetchingResults: true
            })
            let request = {
                apiRevision: 0,
                skipPathsWithExistingDirectRelation: (fromAdd && !useFilter) ? null : (fromAdd && useFilter) ? this.state.filterChecked : this.state.filterChecked,
                domainWhitelist: [
                ],
                forceCompletePathMatching: true,
                matchedPathsMaxCount: fromAdd === undefined ? 20 : this.state.countFullPath,
                matchedPathsMaxTripleSentences: fromAdd ? 0 : 10,
                matchedPathsOffset: fromAdd === undefined ? 0 : this.state.startFullPath,
                matchedPathsRanking: "TRIPLE_COUNT_AVG",
                matchedPathsWithTripleBaseData: fromAdd ? false : true,
                maxMegaTriplesToProcess: 2,
                maxTopMatchedConceptsPerPathStep: 1000,
                matchedPathsOrdering: "RANKED",
                pathEntitiesFilter: {
                },
                pathRelationsFilter: {
                },
                repository: this.state.selectedRepository,
                topMatchPerPathStepCountingUseTripleCount: true,
                topMatchedEntitiesPerPathStepIndexes: [
                    0
                ],
                topMatchedRelationsPerPathStepIndexes: [
                    0
                ],
                uniqueMatchedEntitiesPerPathStepIndexes: [
                    0
                ],
                uniqueMatchedRelationsPerPathStepIndexes: [
                    0
                ],
                withConceptDomains: true,
                withConceptPrefnames: true
            }

            let entities = {}
            let counter = 0

            let rootOCIDs = [{ ocid: 150000001799 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 }]
            let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
            { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

            for (let i = 0; i < this.state.startTermOCID.length; i++) {
                if ((this.state.startTerm[i] === '' || this.state.startTerm[i].length === 0) && i === 0) {
                    this.state.logicals[i] === 'ctd' || this.state.logicals[i] === 'cip' || this.state.logicals[i] === 'cid' ||
                        this.state.logicals[i] === 'cic' || this.state.logicals[i] === 'cipp' || this.state.logicals[i] === 'caa' ?
                        entities[i + counter] = rootOCIDs
                        :
                        this.state.logicals[i] === 'aaa' || this.state.logicals[i] === 'aac' || this.state.logicals[i] === 'aad' || this.state.logicals[i] === 'aapp' ||
                            this.state.logicals[i] === 'aap' ?
                            entities[i + counter] = rootOCIDsAll
                            :
                            entities[i + counter] = [{
                                domain:
                                    this.state.logicals[i] === 'pid' ? 'proteins' :
                                        this.state.logicals[i] === 'paa' ? 'proteins' :
                                            this.state.logicals[i] === 'pmc' ? 'proteins' :
                                                this.state.logicals[i] === 'pmpp' ? 'proteins' :
                                                    this.state.logicals[i] === 'ppmp' ? '' :
                                                        this.state.logicals[i] === 'ppipp' ? '' :
                                                            this.state.logicals[i] === 'pppd' ? '' :
                                                                this.state.logicals[i] === 'dmp' ? 'diseases' :
                                                                    this.state.logicals[i] === 'daa' ? 'diseases' : '',
                                ocid: this.state.logicals[i] === 'ppmp' || this.state.logicals[i] === 'ppipp' || this.state.logicals[i] === 'pppd'
                                    || this.state.logicals[i] === 'ppaa' ? '230000035790' : ''
                            }
                            ]
                    counter = counter + 1
                } else if (this.state.startTerm[i] !== '' && i === 0) {
                    let allEntities = []
                    for (let j = 0; j < this.state.startTerm[i].length; j++) {
                        let allSubEntities = []
                        for (let k = 0; k < this.state.startTermOCID[i][j].length; k++) {
                            allSubEntities = [...allSubEntities, { ocid: this.state.startTermOCID[i][j][k]['+ocid'], conceptLookupType: this.state.startChecked[i] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                        }
                        allSubEntities.forEach(entity => {
                            allEntities = [...allEntities, (entity)]
                        })
                    }
                    entities[i + counter] = allEntities
                    counter = counter + 1
                }
                if (this.state.endTerm[i] === '' || this.state.endTerm[i].length === 0) {
                    this.state.logicals[i] === 'cic' || this.state.logicals[i] === 'pmc' || this.state.logicals[i] === 'aac' ?
                        entities[i + counter] = rootOCIDs
                        :
                        this.state.logicals[i] === 'aaa' || this.state.logicals[i] === 'caa' || this.state.logicals[i] === 'daa' ||
                            this.state.logicals[i] === 'ppaa' || this.state.logicals[i] === 'paa' ?
                            entities[i + counter] = rootOCIDsAll
                            :
                            entities[i + counter] = [{
                                domain:
                                    this.state.logicals[i] === 'ctd' ? 'diseases' :
                                        this.state.logicals[i] === 'cip' ? 'proteins' :
                                            this.state.logicals[i] === 'cid' ? 'diseases' :
                                                this.state.logicals[i] === 'cipp' ? '' :
                                                    this.state.logicals[i] === 'ppipp' ? '' :
                                                        this.state.logicals[i] === 'pid' ? 'diseases' :
                                                            this.state.logicals[i] === 'pppd' ? 'diseases' :
                                                                this.state.logicals[i] === 'aad' ? 'diseases' :
                                                                    this.state.logicals[i] === 'pmpp' ? '' :
                                                                        this.state.logicals[i] === 'ppmp' ? 'proteins' :
                                                                            this.state.logicals[i] === 'aap' ? 'proteins' :
                                                                                this.state.logicals[i] === 'dmp' ? 'proteins' : '',
                                ocid: this.state.logicals[i] === 'cipp' || this.state.logicals[i] === 'pmpp' || this.state.logicals[i] === 'ppipp' || this.state.logicals[i] === 'aapp' ? '230000035790' : ''
                            }]
                } else {
                    let allEntities = []
                    for (let j = 0; j < this.state.endTerm[i].length; j++) {
                        let allSubEntities = []
                        for (let k = 0; k < this.state.endTermOCID[i][j].length; k++) {
                            allSubEntities = [...allSubEntities, { ocid: this.state.endTermOCID[i][j][k]['+ocid'], conceptLookupType: this.state.endChecked[i] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                        }
                        allSubEntities.forEach(entity => {
                            allEntities = [...allEntities, (entity)]
                        })
                    }
                    entities[i + counter] = allEntities
                }
            }

            if (negated && negated.length > 0) {
                Object.keys(entities).forEach(key => {
                    for (let j = 0; j < negated.length; j++) {
                        entities[key] = [...entities[key], negated[j]]
                    }
                })
            }

            request['pathEntitiesFilter'] = entities

            /*let negated = [
                {
                    ocid: '232000000609',
                    negated: true
                },
                {
                    ocid: '232000000616',
                    negated: true
                },
                {
                    ocid: '232000000628',
    
                    negated: true
                },
                {
                    ocid: '232000000629',
                    negated: true
                },
                {
                    ocid: '232000000630',
                    negated: true
                },
            ]*/

            let allRelations = [[]]
            for (let i = 0; i < this.state.relationOCID.length; i++) {
                allRelations[i] = []
                if (typeof this.state.relationOCID[i] !== 'string') {
                    if (this.state.relationOCID[i] && this.state.relationOCID[i].length > 0) {
                        this.state.relationOCID[i].forEach((ocid, j) => {
                            allRelations[i].push({ ocid: ocid, conceptLookupType: this.state.relationMode[i] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' })
                        })
                    } else {
                        allRelations[i] = this.state.relationsChecked === "narrow" ? [
                            { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                            , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                            { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                            { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
                    } request['pathRelationsFilter'][i + 1] = allRelations[i]
                } else if (typeof this.state.relationOCID[i] === 'string') {
                    if (this.state.relationOCID[i] !== "") {
                        request['pathRelationsFilter'][i + 1] = [{
                            ocid: this.state.relationOCID[i]
                        }]
                    } else {
                        request['pathRelationsFilter'][i + 1] = this.state.relationsChecked === "narrow" ? [
                            { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                            , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                            { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                            { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
                    }
                }
            }

            if (!fromAdd) {
                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            self.setState({
                                showResults: true,
                                matchedPaths: response.data.matchedPaths,
                                prefNames: response.data.ocidToPreferredName,
                                matchedPathsCount: response.data.matchedPathsCount,
                                displayDialogExecute: true,
                                fullPathRequest: request,
                                fetchingResults: false,
                                activeIndex: 1,
                                fullPathNumber: self.state.pathNumber,
                                disabledFull: false,
                                rerenderTable: true
                            }, () => //scrollToComponent(self.table, { offset: -225, align: 'top', duration: 100 })
                                window.scrollTo(0, self.table?.current?.offsetTop)
                            )
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            } else {
                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let newFullPathHits = self.state.fullPathHits.slice()
                            newFullPathHits[number - 1] = response.data.matchedPathsCount
                            self.setState({
                                fullPathHits: newFullPathHits,
                                fetchingResults: false
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            }
        }
    }

    myCallbackFromExecutePath = async (number, fromAdd) => {

        if (fromAdd === undefined) {
            if (this.toastBC.current) {
                this.toastBC.current.clear()
            }
            this.setState({
                countSubPath: 20,
                startSubPath: 0,
                filterSub: [],
                checkedEntities: [],
                displayCustomToast: false
            })
        }

        let blacklistEntries = []

        if (this.state.selectedBlacklists && this.state.selectedBlacklists.length > 0) {
            for (let i = 0; i < this.state.selectedBlacklists.length; i++) {
                if (hasUserRole('ROLE_BLACKLISTS')) {
                    await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${this.state.selectedBlacklists[i]}`, { headers: getHeaderToken() })
                        // eslint-disable-next-line
                        .then(function (response) {
                            //self.showCollectionRefreshSuccessGrowl()
                            let entries = response.data
                            blacklistEntries = [...blacklistEntries, entries]
                        })
                        .catch(function (error) {
                            if (error.response.status === 400) {
                                this.showFailGrowl(error.response.data)
                            } else if (error.response.status === 401) {
                                this.props.history.push('/')
                            } else if (error.response.status === 404) {
                                console.log("not found")
                            }
                        })
                }
            }
        }

        let negated = []

        blacklistEntries.forEach(entry => {
            entry.forEach(item => {
                negated = [...negated, { ocid: item.ocid, negated: true, conceptLookupType: item.conceptLookupType }]
            })
        })

        this.setState({
            fetchingResults: true
        })

        let request = {
            apiRevision: 0,
            domainWhitelist: [
            ],
            forceCompletePathMatching: true,
            matchedPathsMaxCount: fromAdd === undefined ? 20 : this.state.countSubPath,
            matchedPathsMaxTripleSentences: fromAdd ? 0 : 10,
            matchedPathsOffset: fromAdd === undefined ? 0 : this.state.startSubPath,
            matchedPathsRanking: "TRIPLE_COUNT_AVG",
            matchedPathsWithTripleBaseData: fromAdd ? false : true,
            maxMegaTriplesToProcess: 2,
            maxTopMatchedConceptsPerPathStep: 1000,
            matchedPathsOrdering: "RANKED",
            pathEntitiesFilter: {
            },
            pathRelationsFilter: {
            },
            repository: this.state.selectedRepository,
            topMatchPerPathStepCountingUseTripleCount: true,
            topMatchedEntitiesPerPathStepIndexes: [
                0
            ],
            topMatchedRelationsPerPathStepIndexes: [
                0
            ],
            uniqueMatchedEntitiesPerPathStepIndexes: [
                0
            ],
            uniqueMatchedRelationsPerPathStepIndexes: [
                0
            ],
            withConceptDomains: true,
            withConceptPrefnames: true
        }

        let entities = {}
        let counter = 0

        let rootOCIDs = [{ ocid: 150000001799 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 }]
        let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
        { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

        for (let i = 0; i <= number; i++) {
            if ((this.state.startTerm[i] === '' || this.state.startTerm[i].length === 0) && i === 0) {
                this.state.logicals[i] === 'ctd' || this.state.logicals[i] === 'cip' || this.state.logicals[i] === 'cid' ||
                    this.state.logicals[i] === 'cic' || this.state.logicals[i] === 'cipp' || this.state.logicals[i] === 'caa' ?
                    entities[i + counter] = rootOCIDs
                    :
                    this.state.logicals[i] === 'aaa' || this.state.logicals[i] === 'aac' || this.state.logicals[i] === 'aad' ||
                        this.state.logicals[i] === 'aapp' || this.state.logicals[i] === 'aap' ?
                        entities[i + counter] = rootOCIDsAll
                        :
                        entities[i + counter] = [{
                            domain:
                                this.state.logicals[i] === 'pid' ? 'proteins' :
                                    this.state.logicals[i] === 'paa' ? 'proteins' :
                                        this.state.logicals[i] === 'pmc' ? 'proteins' :
                                            this.state.logicals[i] === 'pmpp' ? 'proteins' :
                                                this.state.logicals[i] === 'ppmp' ? '' :
                                                    this.state.logicals[i] === 'ppipp' ? '' :
                                                        this.state.logicals[i] === 'pppd' ? '' :
                                                            this.state.logicals[i] === 'dmp' ? 'diseases' :
                                                                this.state.logicals[i] === 'daa' ? 'diseases' : '',
                            ocid: this.state.logicals[i] === 'ppmp' || this.state.logicals[i] === 'ppipp' || this.state.logicals[i] === 'pppd'
                                || this.state.logicals[i] === 'ppaa' ? '230000035790' : ''
                        }
                        ]
                counter = counter + 1
            } else if (this.state.startTerm[i] !== '' && i === 0) {
                let allEntities = []
                for (let j = 0; j < this.state.startTerm[i].length; j++) {
                    let allSubEntities = []
                    for (let k = 0; k < this.state.startTermOCID[i][j].length; k++) {
                        allSubEntities = [...allSubEntities, { ocid: this.state.startTermOCID[i][j][k]['+ocid'], conceptLookupType: this.state.startChecked[i] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                    }
                    allSubEntities.forEach(entity => {
                        allEntities = [...allEntities, (entity)]
                    })
                }
                entities[i + counter] = allEntities
                counter = counter + 1
            }

            if (this.state.endTerm[i] === '' || this.state.endTerm[i].length === 0) {
                this.state.logicals[i] === 'cic' || this.state.logicals[i] === 'pmc' || this.state.logicals[i] === 'aac' ?
                    entities[i + counter] = rootOCIDs
                    :
                    this.state.logicals[i] === 'aaa' || this.state.logicals[i] === 'caa' || this.state.logicals[i] === 'daa' ||
                        this.state.logicals[i] === 'ppaa' || this.state.logicals[i] === 'paa' ?
                        entities[i + counter] = rootOCIDsAll
                        :
                        entities[i + counter] = [{
                            domain:
                                this.state.logicals[i] === 'ctd' ? 'diseases' :
                                    this.state.logicals[i] === 'cip' ? 'proteins' :
                                        this.state.logicals[i] === 'cid' ? 'diseases' :
                                            this.state.logicals[i] === 'cipp' ? '' :
                                                this.state.logicals[i] === 'ppipp' ? '' :
                                                    this.state.logicals[i] === 'pid' ? 'diseases' :
                                                        this.state.logicals[i] === 'pppd' ? 'diseases' :
                                                            this.state.logicals[i] === 'aad' ? 'diseases' :
                                                                this.state.logicals[i] === 'pmpp' ? '' :
                                                                    this.state.logicals[i] === 'ppmp' ? 'proteins' :
                                                                        this.state.logicals[i] === 'aap' ? 'proteins' :
                                                                            this.state.logicals[i] === 'dmp' ? 'proteins' : '',
                            ocid: this.state.logicals[i] === 'cipp' || this.state.logicals[i] === 'pmpp' || this.state.logicals[i] === 'ppipp'
                                || this.state.logicals[i] === 'aapp' ? '230000035790' : ''
                        }
                        ]
            } else {
                let allEntities = []
                for (let j = 0; j < this.state.endTerm[i].length; j++) {
                    let allSubEntities = []
                    for (let k = 0; k < this.state.endTermOCID[i][j].length; k++) {
                        allSubEntities = [...allSubEntities, { ocid: this.state.endTermOCID[i][j][k]['+ocid'], conceptLookupType: this.state.endChecked[i] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                    }
                    allSubEntities.forEach(entity => {
                        allEntities = [...allEntities, (entity)]
                    })
                }
                entities[i + counter] = allEntities
            }
        }

        if (negated && negated.length > 0) {
            Object.keys(entities).forEach(key => {
                for (let j = 0; j < negated.length; j++) {
                    entities[key] = [...entities[key], negated[j]]
                }
            })
        }

        request['pathEntitiesFilter'] = entities

        /*let negated = [
            {
                ocid: '232000000609',
                negated: true
            },
            {
                ocid: '232000000616',
                negated: true
            },
            {
                ocid: '232000000628',

                negated: true
            },
            {
                ocid: '232000000629',
                negated: true
            },
            {
                ocid: '232000000630',
                negated: true
            },
        ]*/

        let allRelations = [[]]
        for (let i = 0; i < number + 1; i++) {
            allRelations[i] = []
            if (typeof this.state.relationOCID[i] !== 'string') {
                if (this.state.relationOCID[i] && this.state.relationOCID[i].length > 0) {
                    this.state.relationOCID[i].forEach((ocid, j) => {
                        allRelations[i].push({ ocid: ocid, conceptLookupType: this.state.relationMode[i] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' })
                    })
                } else {
                    allRelations[i] = this.state.relationsChecked === "narrow" ? [
                        { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                        , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                        { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                        { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
                } request['pathRelationsFilter'][i + 1] = allRelations[i]
            } else if (typeof this.state.relationOCID[i] === 'string') {
                if (this.state.relationOCID[i] !== "") {
                    request['pathRelationsFilter'][i + 1] = [{
                        ocid: this.state.relationOCID[i]
                    }]
                } else {
                    request['pathRelationsFilter'][i + 1] = this.state.relationsChecked === "narrow" ? [
                        { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                        , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                        { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                        { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
                }
            }
        }

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    if (fromAdd) {
                        let newSubPathHits = self.state.subPathHits.slice()
                        newSubPathHits[number] = response.data.matchedPathsCount
                        self.setState({
                            subPathHits: newSubPathHits
                        })
                    }
                    self.setState({
                        fetchingResults: false
                    }, () => {
                        if (!fromAdd) {
                            self.myCallbackFromSubPathExecute(response.data.matchedPaths, response.data.matchedPathsCount,
                                response.data.ocidToPreferredName, number, request)
                        }
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
    }

    myCallbackFromExecuteSinglePath = async (number, fromAdd) => {

        if (fromAdd === undefined) {
            if (this.toastBC.current) {
                this.toastBC.current.clear()
            }
            this.setState({
                countTriplePath: 20,
                startTriplePath: 0,
                filterTriple: [],
                checkedEntities: [],
                displayCustomToast: false
            })
        }

        let blacklistEntries = []

        if (this.state.selectedBlacklists && this.state.selectedBlacklists.length > 0) {
            for (let i = 0; i < this.state.selectedBlacklists.length; i++) {
                if (hasUserRole('ROLE_BLACKLISTS')) {
                    await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${this.state.selectedBlacklists[i]}`, { headers: getHeaderToken() })
                        // eslint-disable-next-line
                        .then(function (response) {
                            let entries = response.data
                            blacklistEntries = [...blacklistEntries, entries]
                        })
                        .catch(function (error) {
                            if (error.response.status === 400) {
                                this.showFailGrowl(error.response.data)
                            } else if (error.response.status === 401) {
                                this.state.history.push('/')
                            } else if (error.response.status === 404) {
                                console.log("not found")
                            }
                        })
                }
            }
        }

        let negated = []

        blacklistEntries.forEach(entry => {
            entry.forEach(item => {
                negated = [...negated, { ocid: item.ocid, negated: true, conceptLookupType: item.conceptLookupType }]
            })
        })

        this.setState({
            fetchingResults: true
        })

        let request = {
            apiRevision: 0,
            domainWhitelist: [
            ],
            forceCompletePathMatching: true,
            matchedPathsMaxCount: fromAdd === undefined ? 20 : this.state.countTriplePath,
            matchedPathsMaxTripleSentences: fromAdd ? 0 : 10,
            matchedPathsOffset: fromAdd === undefined ? 0 : this.state.startTriplePath,
            matchedPathsRanking: fromAdd ? null : "TRIPLE_COUNT_MIN",
            matchedPathsWithTripleBaseData: fromAdd ? false : true,
            maxMegaTriplesToProcess: 2,
            maxTopMatchedConceptsPerPathStep: 1000,
            matchedPathsOrdering: "RANKED",
            pathEntitiesFilter: {
            },
            pathRelationsFilter: {
            },
            repository: this.state.selectedRepository,
            topMatchPerPathStepCountingUseTripleCount: true,
            topMatchedEntitiesPerPathStepIndexes: fromAdd ? null : [
                0
            ],
            topMatchedRelationsPerPathStepIndexes: fromAdd ? [1] : [
                0
            ],
            uniqueMatchedEntitiesPerPathStepIndexes: fromAdd ? null : [
                0
            ],
            uniqueMatchedRelationsPerPathStepIndexes: fromAdd ? null : [
                0
            ],
            withConceptDomains: fromAdd ? false : true,
            withConceptPrefnames: true
        }

        let entitiesStart = {}
        let entitiesEnd = {}

        let rootOCIDs = [{ ocid: 150000001799 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 }]
        let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
        { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

        if (number === 1) {
            if (this.state.startTerm[number - 1] === '' || this.state.startTerm[number - 1].length === 0) {
                this.state.logicals[number - 1] === 'ctd' || this.state.logicals[number - 1] === 'cip' || this.state.logicals[number - 1] === 'cid' || this.state.logicals[number - 1] === 'cic' ||
                    this.state.logicals[number - 1] === 'cipp' || this.state.logicals[number - 1] === 'caa' ?
                    entitiesStart['0'] = rootOCIDs
                    :
                    this.state.logicals[number - 1] === 'aaa' || this.state.logicals[number - 1] === 'aac' || this.state.logicals[number - 1] === 'aad' || this.state.logicals[number - 1] === 'aapp' ||
                        this.state.logicals[number - 1] === 'aap' ?
                        entitiesStart['0'] = rootOCIDsAll
                        :
                        entitiesStart['0'] = [
                            {
                                domain:
                                    this.state.logicals[number - 1] === 'pid' ? 'proteins' :
                                        this.state.logicals[number - 1] === 'paa' ? 'proteins' :
                                            this.state.logicals[number - 1] === 'pmc' ? 'proteins' :
                                                this.state.logicals[number - 1] === 'pmpp' ? 'proteins' :
                                                    this.state.logicals[number - 1] === 'ppmp' ? '' :
                                                        this.state.logicals[number - 1] === 'ppipp' ? '' :
                                                            this.state.logicals[number - 1] === 'pppd' ? '' :
                                                                this.state.logicals[number - 1] === 'dmp' ? 'diseases' :
                                                                    this.state.logicals[number - 1] === 'daa' ? 'diseases' : '',
                                ocid: this.state.logicals[number - 1] === 'ppmp' || this.state.logicals[number - 1] === 'ppipp' || this.state.logicals[number - 1] === 'pppd'
                                    || this.state.logicals[number - 1] === 'ppaa' ? '230000035790' : ''
                            }
                        ]
            } else {
                entitiesStart['0'] = []
                let allStartEntities = []
                for (let j = 0; j < this.state.startTermOCID[number - 1].length; j++) {
                    let allSubEntities = []
                    for (let k = 0; k < this.state.startTermOCID[number - 1][j].length; k++) {
                        allSubEntities = [...allSubEntities, { ocid: this.state.startTermOCID[number - 1][j][k]['+ocid'], conceptLookupType: this.state.startChecked[number - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                    }
                    allSubEntities.forEach(entity => {
                        allStartEntities = [...allStartEntities, (entity)]
                    })
                }
                entitiesStart['0'] = allStartEntities
            }
        } else {
            if (this.state.endTerm[number - 2] === '' || this.state.endTerm[number - 2].length === 0) {
                this.state.logicals[number - 1] === 'ctd' || this.state.logicals[number - 1] === 'cip' || this.state.logicals[number - 1] === 'cid' || this.state.logicals[number - 1] === 'cic' ||
                    this.state.logicals[number - 1] === 'cipp' || this.state.logicals[number - 1] === 'caa' ?
                    entitiesStart['0'] = rootOCIDs
                    :
                    this.state.logicals[number - 1] === 'aaa' || this.state.logicals[number - 1] === 'aac' || this.state.logicals[number - 1] === 'aad' || this.state.logicals[number - 1] === 'aapp' ||
                        this.state.logicals[number - 1] === 'aap' ?
                        entitiesStart['0'] = rootOCIDsAll
                        :
                        entitiesStart['0'] = [
                            {
                                domain:
                                    this.state.logicals[number - 1] === 'pid' ? 'proteins' :
                                        this.state.logicals[number - 1] === 'paa' ? 'proteins' :
                                            this.state.logicals[number - 1] === 'pmc' ? 'proteins' :
                                                this.state.logicals[number - 1] === 'pmpp' ? 'proteins' :
                                                    this.state.logicals[number - 1] === 'ppmp' ? '' :
                                                        this.state.logicals[number - 1] === 'ppipp' ? '' :
                                                            this.state.logicals[number - 1] === 'pppd' ? '' :
                                                                this.state.logicals[number - 1] === 'dmp' ? 'diseases' :
                                                                    this.state.logicals[number - 1] === 'daa' ? 'diseases' : '',
                                ocid: this.state.logicals[number - 1] === 'ppmp' || this.state.logicals[number - 1] === 'ppipp' || this.state.logicals[number - 1] === 'pppd'
                                    || this.state.logicals[number - 1] === 'ppaa' ? '230000035790' : ''
                            }
                        ]
            } else {
                entitiesStart['0'] = []
                let allStartEntities = []
                for (let j = 0; j < this.state.endTermOCID[number - 2].length; j++) {
                    let allSubEntities = []
                    for (let k = 0; k < this.state.endTermOCID[number - 2][j].length; k++) {
                        allSubEntities = [...allSubEntities, { ocid: this.state.endTermOCID[number - 2][j][k]['+ocid'], conceptLookupType: this.state.endChecked[number - 2] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                    }
                    allSubEntities.forEach(entity => {
                        allStartEntities = [...allStartEntities, (entity)]
                    })
                }
                entitiesStart['0'] = allStartEntities
            }
        }

        if (this.state.endTerm[number - 1] === '' || this.state.endTerm[number - 1].length === 0) {
            this.state.logicals[number - 1] === 'cic' || this.state.logicals[number - 1] === 'pmc' || this.state.logicals[number - 1] === 'aac' ?
                entitiesEnd['1'] = rootOCIDs
                :
                this.state.logicals[number - 1] === 'aaa' || this.state.logicals[number - 1] === 'caa' || this.state.logicals[number - 1] === 'daa' ||
                    this.state.logicals[number - 1] === 'ppaa' || this.state.logicals[number - 1] === 'paa' ?
                    entitiesEnd['1'] = rootOCIDsAll
                    :
                    entitiesEnd['1'] = [{
                        domain:
                            this.state.logicals[number - 1] === 'ctd' ? 'diseases' :
                                this.state.logicals[number - 1] === 'cip' ? 'proteins' :
                                    this.state.logicals[number - 1] === 'cid' ? 'diseases' :
                                        this.state.logicals[number - 1] === 'cipp' ? '' :
                                            this.state.logicals[number - 1] === 'ppipp' ? '' :
                                                this.state.logicals[number - 1] === 'pid' ? 'diseases' :
                                                    this.state.logicals[number - 1] === 'pppd' ? 'diseases' :
                                                        this.state.logicals[number - 1] === 'aad' ? 'diseases' :
                                                            this.state.logicals[number - 1] === 'pmpp' ? '' :
                                                                this.state.logicals[number - 1] === 'ppmp' ? 'proteins' :
                                                                    this.state.logicals[number - 1] === 'aap' ? 'proteins' :
                                                                        this.state.logicals[number - 1] === 'dmp' ? 'proteins' : '',
                        ocid: this.state.logicals[number - 1] === 'cipp' || this.state.logicals[number - 1] === 'pmpp' || this.state.logicals[number - 1] === 'ppipp'
                            || this.state.logicals[number - 1] === 'aapp' ? '230000035790' : ''
                    }
                    ]
        } else {
            entitiesEnd['1'] = []
            let allEndEntities = []
            for (let j = 0; j < this.state.endTermOCID[number - 1].length; j++) {
                let allSubEntities = []
                for (let k = 0; k < this.state.endTermOCID[number - 1][j].length; k++) {
                    allSubEntities = [...allSubEntities, { ocid: this.state.endTermOCID[number - 1][j][k]['+ocid'], conceptLookupType: this.state.endChecked[number - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                }
                allSubEntities.forEach(entity => {
                    allEndEntities = [...allEndEntities, (entity)]
                })
            }
            entitiesEnd['1'] = allEndEntities
        }

        let startEnd = Object.assign(entitiesStart, entitiesEnd)

        if (negated && negated.length > 0) {
            Object.keys(startEnd).forEach(key => {
                for (let j = 0; j < negated.length; j++) {
                    startEnd[key] = [...startEnd[key], negated[j]]
                }
            })
        }

        request['pathEntitiesFilter'] = startEnd

        let allRelations = []
        if (typeof this.state.relationOCID[number - 1] !== 'string') {
            if (this.state.relationOCID[number - 1].length > 0) {
                this.state.relationOCID[number - 1].forEach((ocid, i) => {
                    allRelations = [...allRelations, { ocid: ocid, conceptLookupType: this.state.relationMode[number - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                })
            } else {
                allRelations = this.state.relationsChecked === "narrow" ? [
                    { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                    , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                    { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                    { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
            }
        }

        if (typeof this.state.relationOCID[number - 1] === 'string') {
            if (this.state.relationOCID[number - 1] !== "") {
                allRelations = [{
                    ocid: this.state.relationOCID[number - 1]
                }]
            } else {
                allRelations = this.state.relationsChecked === "narrow" ? [
                    { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                    , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                    { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                    { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
            }
        }

        request['pathRelationsFilter'][1] = allRelations

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    if (fromAdd) {
                        let newTripleHits = self.state.tripleHits.slice()
                        newTripleHits[number - 1] = response.data.matchedPathsCount
                        self.setState({
                            tripleHits: newTripleHits
                        })
                    }
                    self.setState({
                        fetchingResults: false
                    }, () => {
                        if (!fromAdd) {
                            self.myCallbackFromTripleExecute(response.data.matchedPaths, response.data.matchedPathsCount, response.data.ocidToPreferredName,
                                number, request)
                        }
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
    }

    myCallbackRepositories = (filter, pathNumber) => {
        this.setState({
            pathRepositories: filter
        })
    }

    myCallbackFromAddPath = async (pathNumber, type, pathRepositories, logicals, relation, relationOCID, startTerm, startTermShow, startTermOCID, startChecked, endTerm, endTermShow, endTermOCID, endChecked, relationMode) => {
        if (logicals[pathNumber - 1] === 'aaa' || logicals[pathNumber - 1] === 'aac' || logicals[pathNumber - 1] === 'aad' || logicals[pathNumber - 1] === 'aap' ||
            logicals[pathNumber - 1] === 'aapp' || logicals[pathNumber - 1] === 'caa' || logicals[pathNumber - 1] === 'daa' || logicals[pathNumber - 1] === 'paa' || logicals[pathNumber - 1] === 'ppaa') {

            this.setState({
                fetchingResults: true,
                queryChanged: false
            })

            let request = {
                apiRevision: 0,
                domainWhitelist: [
                ],
                forceCompletePathMatching: true,
                //matchedPathsMaxCount: 100,
                matchedPathsMaxTripleSentences: 0,
                matchedPathsOffset: 0,
                //matchedPathsRanking: "TRIPLE_COUNT_AVG",
                matchedPathsWithTripleBaseData: false,
                maxMegaTriplesToProcess: 2,
                maxTopMatchedConceptsPerPathStep: 1000,
                matchedPathsOrdering: "RANKED",
                pathEntitiesFilter: {
                },
                pathRelationsFilter: {
                },
                repository: this.state.selectedRepository,
                topMatchPerPathStepCountingUseTripleCount: true,
                /*topMatchedEntitiesPerPathStepIndexes: [
                    0
                ],*/
                topMatchedRelationsPerPathStepIndexes: [
                    1
                ],
                /*uniqueMatchedEntitiesPerPathStepIndexes: [
                    0
                ],*/
                /*uniqueMatchedRelationsPerPathStepIndexes: [
                    0
                ],*/
                withConceptDomains: false,
                withConceptPrefnames: true
            }

            //let entities = {}

            let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
            { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

            if (logicals[pathNumber - 1] !== 'aaa') {
                request['pathEntitiesFilter'] = logicals[pathNumber - 1] === 'aad' ? { "1": [{ domain: 'diseases' }], "0": rootOCIDsAll } :
                    logicals[pathNumber - 1] === 'aac' ? { "1": [{ domain: 'chem' }, { domain: 'drugs' }, { domain: 'natprod' }, { domain: 'substances' }], "0": rootOCIDsAll } :
                        logicals[pathNumber - 1] === 'aap' ? { "1": [{ domain: 'proteins' }], "0": rootOCIDsAll } :
                            logicals[pathNumber - 1] === 'aapp' ? { "1": [{ domain: 'effects', namedFilter: 'ocidSubtrees', allowedSubtreesRootOcids: '230000035790' }], "0": rootOCIDsAll } :
                                logicals[pathNumber - 1] === 'caa' ? { "0": [{ domain: 'chem' }, { domain: 'drugs' }, { domain: 'natprod' }, { domain: 'substances' }], "1": rootOCIDsAll } :
                                    logicals[pathNumber - 1] === 'daa' ? { "0": [{ domain: 'diseases' }], "1": rootOCIDsAll } :
                                        logicals[pathNumber - 1] === 'ppaa' ? { "0": [{ domain: 'effects', namedFilter: 'ocidSubtrees', allowedSubtreesRootOcids: '230000035790' }], "1": rootOCIDsAll } :
                                            logicals[pathNumber - 1] === 'paa' ? { "0": [{ domain: 'proteins' }], "1": rootOCIDsAll } :
                                                request['pathEntitiesFilter'] = { "0": rootOCIDsAll, "1": rootOCIDsAll }
            } else {
                if (this.state.endTermOCID[pathNumber - 2] === "") {
                    request['pathEntitiesFilter'] = { "0": rootOCIDsAll, "1": rootOCIDsAll }
                } else {
                    let ocidArray = []
                    this.state.endTermOCID[pathNumber - 2].forEach(entry => {
                        entry.forEach(obj => {
                            ocidArray = [...ocidArray, { ocid: obj["+ocid"] }]
                        })
                    })
                    request['pathEntitiesFilter'] = { "0": ocidArray, "1": rootOCIDsAll }
                }
            }

            //request['pathRelationsFilter'] = { "1": [{ domain: 'chem', negated: true }] }
            request['pathRelationsFilter'] = {
                "1": this.state.relationsChecked === "narrow" ? [
                    { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                    , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                    { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                    { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
            }
            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status === 200) {
                        let newRelationsMulti = self.state.relationOCIDMulti.slice()
                        newRelationsMulti[pathNumber - 1] = response.data.ocidToPreferredName

                        let newTripleHits = self.state.tripleHits.slice()
                        newTripleHits[pathNumber - 1] = response.data.matchedPathsCount

                        let newLeftDomains = self.state.leftDomains.slice()
                        newLeftDomains[pathNumber - 1] = ["all domains"]

                        let newLeftDomainsShow = self.state.leftDomainsShow.slice()
                        newLeftDomainsShow[pathNumber - 1] = ["all domains"]

                        let newUniqueRight = self.state.rightDomains.slice()
                        let newUniqueRightShow = self.state.rightDomainsShow.slice()

                        if (response.data.ocidToPreferredName) {
                            if (endTermOCID[pathNumber - 2] !== "" && endTermOCID[pathNumber - 2].length > 0) {
                                let matchesRight = []

                                Object.keys(response.data.ocidToPreferredName).forEach(entry => {
                                    let singleMatch = response.data.ocidToPreferredName[entry].match(/\[(.*?)\]/g)
                                    if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiology' ||
                                        singleMatch[1].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'effect') {
                                        matchesRight.push("effects or process")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'animal disease') {
                                        matchesRight.push("disease")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alloy') {
                                        matchesRight.push("substance")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'chem') {
                                        matchesRight.push("chemistry")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'bacterium'
                                        || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'virus') {
                                        matchesRight.push("species")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'compound') {
                                        matchesRight.push("chemistry")
                                        matchesRight.push("drug")
                                        matchesRight.push("natural products")
                                    } else {
                                        matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                    }
                                    //matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                })
                                let uniqueRight = [...new Set(matchesRight)]
                                newUniqueRight[pathNumber - 1] = uniqueRight
                                newUniqueRightShow[pathNumber - 1] = uniqueRight
                            } else {
                                newUniqueRight[pathNumber - 1] = ['all domains']
                                newUniqueRightShow[pathNumber - 1] = ['all domains']
                            }
                        } else {
                            newUniqueRight[pathNumber - 1] = ['all domains']
                            newUniqueRightShow[pathNumber - 1] = ['all domains']
                        }

                        let newStartTermDomains = self.state.startTermDomains
                        newStartTermDomains[pathNumber - 1] = []

                        let newEndTermDomains = self.state.endTermDomains
                        newEndTermDomains[pathNumber - 1] = []

                        let newAutocompleteOrBrowserStart = self.state.autocompleteOrBrowserStart
                        newAutocompleteOrBrowserStart[pathNumber - 1] = []

                        let newAutocompleteOrBrowserEnd = self.state.autocompleteOrBrowserEnd
                        newAutocompleteOrBrowserEnd[pathNumber - 1] = []

                        let newRelationNames = self.state.relationNames
                        newRelationNames[pathNumber - 1] = ["All possible relations"]

                        let newRelationValues = self.state.relationValues
                        newRelationValues[pathNumber - 1] = []

                        let newRelationOCID = self.state.relationOCID
                        newRelationOCID[pathNumber - 1] = []

                        self.setState({
                            fetchingResults: false,
                            pathNumber: pathNumber,
                            type: type,
                            pathRepositories: pathRepositories,
                            logicals: logicals,
                            relation: relation,
                            relationOCID: newRelationOCID,//relationOCID,
                            startTerm: startTerm,
                            startTermShow: startTermShow,
                            startTermOCID: startTermOCID,
                            startChecked: startChecked,
                            relationMode: relationMode,
                            endTerm: endTerm,
                            endTermShow: endTermShow,
                            endTermOCID: endTermOCID,
                            endChecked: endChecked,
                            relationOCIDMulti: newRelationsMulti,
                            tripleHits: newTripleHits,
                            leftDomains: newLeftDomains,
                            leftDomainsShow: newLeftDomainsShow,
                            rightDomains: newUniqueRight,
                            rightDomainsShow: newUniqueRightShow,
                            rerenderTable: false,
                            startTermDomains: newStartTermDomains,
                            endTermDomains: newEndTermDomains,
                            autocompleteOrBrowserStart: newAutocompleteOrBrowserStart,
                            autocompleteOrBrowserEnd: newAutocompleteOrBrowserEnd,
                            relationNames: newRelationNames,
                            relationValues: newRelationValues
                        }, async () => {
                            await self.myCallbackFromExecutePath(pathNumber - 1, true)
                            await self.handleSearch(pathNumber, true)
                        })
                    }
                }).catch(function (response) {
                    if (response.response.status === 400) {
                    } else if (response.response.status === 401) {
                        self.props.history.push('/')
                    }
                })
        } else {
            let newLeftDomains = this.state.leftDomains.slice()
            newLeftDomains[pathNumber - 1] = [""]

            let newLeftDomainsShow = this.state.leftDomainsShow.slice()
            newLeftDomainsShow[pathNumber - 1] = [""]

            let newRightDomains = this.state.rightDomains.slice()
            newRightDomains[pathNumber - 1] = [""]

            let newRightDomainsShow = this.state.rightDomainsShow.slice()
            newRightDomainsShow[pathNumber - 1] = [""]

            let newStartTermDomains = this.state.startTermDomains
            newStartTermDomains[pathNumber - 1] = []

            let newEndTermDomains = this.state.endTermDomains
            newEndTermDomains[pathNumber - 1] = []

            let newAutocompleteOrBrowserStart = this.state.autocompleteOrBrowserStart
            newAutocompleteOrBrowserStart[pathNumber - 1] = []

            let newAutocompleteOrBrowserEnd = this.state.autocompleteOrBrowserEnd
            newAutocompleteOrBrowserEnd[pathNumber - 1] = []

            let newRelationNames = this.state.relationNames
            newRelationNames[pathNumber - 1] = ["All possible relations"]

            let newRelationValues = this.state.relationValues
            newRelationValues[pathNumber - 1] = []

            let newRelationOCID = this.state.relationOCID
            newRelationOCID[pathNumber - 1] = []

            this.setState({
                pathNumber: pathNumber,
                type: type,
                pathRepositories: pathRepositories,
                logicals: logicals,
                relation: relation,
                relationOCID: newRelationOCID,//relationOCID,
                startTerm: startTerm,
                startTermShow: startTermShow,
                startTermOCID: startTermOCID,
                startChecked: startChecked,
                relationMode: relationMode,
                endTerm: endTerm,
                endTermShow: endTermShow,
                endTermOCID: endTermOCID,
                endChecked: endChecked,
                //tripleHits: newTripleHits,
                rerenderTable: false,
                leftDomains: newLeftDomains,
                leftDomainsShow: newLeftDomainsShow,
                rightDomains: newRightDomains,
                rightDomainsShow: newRightDomainsShow,
                startTermDomains: newStartTermDomains,
                endTermDomains: newEndTermDomains,
                autocompleteOrBrowserStart: newAutocompleteOrBrowserStart,
                autocompleteOrBrowserEnd: newAutocompleteOrBrowserEnd,
                relationNames: newRelationNames,
                relationValues: newRelationValues
            }, async () => {
                await this.myCallbackFromExecuteSinglePath(pathNumber, true)
                await this.myCallbackFromExecutePath(pathNumber - 1, true)
                await this.handleSearch(pathNumber, true)
            })
        }
    }

    myCallbackFromDomainBrowser = (concepts, location, number, replaceItem, replaceIndex) => {
        if (location === 'start') {
            let queryConceptsStart = JSON.parse(JSON.stringify(this.state.queryConceptsStart))
            let newStartTermDomains = JSON.parse(JSON.stringify(this.state.startTermDomains.slice()))
            let queryNamesStart = JSON.parse(JSON.stringify(this.state.startTerm))
            let autocompleteOrBrowserStart = JSON.parse(JSON.stringify(this.state.autocompleteOrBrowserStart))
            let completeConceptArrayStart = JSON.parse(JSON.stringify(this.state.startTermOCID))


            if (replaceItem !== null && replaceIndex !== null) {
                queryNamesStart[number - 1].splice(replaceIndex, 1)
                newStartTermDomains[number - 1].splice(replaceIndex, 1)
                autocompleteOrBrowserStart[number - 1].splice(replaceIndex, 1)
                completeConceptArrayStart[number - 1].splice(replaceIndex, 1)
            }

            Object.values(concepts).forEach((val, i) => {
                let newArr = []
                newArr[0] = val.domain
                queryConceptsStart = [...queryConceptsStart, val]
                if (newStartTermDomains[number - 1].length === 0) {
                    newStartTermDomains[number - 1] = [newArr]
                } else {
                    newStartTermDomains[number - 1].push(newArr)
                }
            })

            //let queryNamesStart = this.state.startTerm
            queryConceptsStart.forEach(concept => {
                queryNamesStart[number - 1] = [...queryNamesStart[number - 1], concept.preferredName]
                autocompleteOrBrowserStart[number - 1] = [...autocompleteOrBrowserStart[number - 1], 'browser']
            })

            //let completeConceptArrayStart = this.state.startTermOCID
            let queryConceptObjectsStart = []

            let ocids = {}

            for (var i = 0; i < queryConceptsStart.length; i++) {
                ocids = {
                    "+ocid": queryConceptsStart[i].ocid.toString()
                }
                if (completeConceptArrayStart.length > 0) {
                    // for (let j = 0; j < completeConceptArrayStart.length; j++) {
                    if (completeConceptArrayStart[0].length > 0) {
                        queryConceptObjectsStart = []
                        for (let k = 0; k < completeConceptArrayStart[0].length; k++) {
                            if (completeConceptArrayStart[0][k]['+ocid'] !== ocids['+ocid'] && k === 0) {
                                queryConceptObjectsStart = [...queryConceptObjectsStart, ocids]
                            }
                        }
                    } else {
                        queryConceptObjectsStart[0] = [ocids]
                    }
                    // }
                } else {
                    queryConceptObjectsStart = [...queryConceptObjectsStart, ocids]
                }
                if (completeConceptArrayStart[number - 1][0] !== undefined) {
                    completeConceptArrayStart[number - 1].push(queryConceptObjectsStart)
                } else {
                    completeConceptArrayStart[number - 1] = queryConceptObjectsStart
                }
            }

            this.setState({
                queryConceptsStart: queryConceptsStart,
                startTerm: queryNamesStart,
                //startTermDomains: newStartTermDomains
            },
                () => {
                    this.myCallbackFromStartTermChange(queryNamesStart, completeConceptArrayStart, completeConceptArrayStart[0], number, newStartTermDomains[0], autocompleteOrBrowserStart[0], false)
                }
            )
        } else if (location === 'end') {
            //let queryConceptsEnd = [...this.state.queryConceptsEnd]
            let queryConceptsEnd = JSON.parse(JSON.stringify(this.state.queryConceptsEnd))
            let newEndTermDomains = JSON.parse(JSON.stringify(this.state.endTermDomains.slice()))
            let queryNamesEnd = JSON.parse(JSON.stringify(this.state.endTerm))
            let autocompleteOrBrowserEnd = JSON.parse(JSON.stringify(this.state.autocompleteOrBrowserEnd))
            let completeConceptArrayEnd = JSON.parse(JSON.stringify(this.state.endTermOCID))

            if (replaceItem !== null && replaceIndex !== null) {
                queryNamesEnd[number - 1].splice(replaceIndex, 1)
                newEndTermDomains[number - 1].splice(replaceIndex, 1)
                autocompleteOrBrowserEnd[number - 1].splice(replaceIndex, 1)
                completeConceptArrayEnd[number - 1].splice(replaceIndex, 1)
            }


            Object.values(concepts).forEach(val => {
                let newArr = []
                newArr[0] = val.domain
                queryConceptsEnd = [...queryConceptsEnd, val]
                if (newEndTermDomains[number - 1].length === 0) {
                    newEndTermDomains[number - 1] = [newArr]
                } else {
                    newEndTermDomains[number - 1].push(newArr)
                }
            })

            //let queryNamesEnd = this.state.endTerm

            queryConceptsEnd.forEach(concept => {
                queryNamesEnd[number - 1] = [...queryNamesEnd[number - 1], concept.preferredName]
                autocompleteOrBrowserEnd[number - 1] = [...autocompleteOrBrowserEnd[number - 1], 'browser']
            })

            //let completeConceptArrayEnd = this.state.endTermOCID

            let queryConceptObjectsEnd = []

            let ocids = {}

            for (var j = 0; j < queryConceptsEnd.length; j++) {
                ocids = {
                    "+ocid": queryConceptsEnd[j].ocid.toString()
                }
                if (completeConceptArrayEnd[number - 1].length > 0) {
                    //for (let j = 0; j < completeConceptArrayEnd.length; j++) {
                    if (completeConceptArrayEnd[number - 1][0].length > 0) {
                        queryConceptObjectsEnd = []
                        for (let k = 0; k < completeConceptArrayEnd[number - 1][0].length; k++) {
                            if (completeConceptArrayEnd[number - 1][0][k]['+ocid'] !== ocids['+ocid'] && k === 0) {
                                queryConceptObjectsEnd = [...queryConceptObjectsEnd, ocids]
                            }
                        }
                    } else {
                        queryConceptObjectsEnd[0] = [ocids]
                    }
                    // }
                } else {
                    queryConceptObjectsEnd = [...queryConceptObjectsEnd, ocids]
                }
                if (completeConceptArrayEnd[number - 1][0] !== undefined) {
                    completeConceptArrayEnd[number - 1].push(queryConceptObjectsEnd)
                } else {
                    completeConceptArrayEnd[number - 1] = [queryConceptObjectsEnd]
                }
            }

            this.setState({
                queryConceptsEnd: queryConceptsEnd,
                endTerm: queryNamesEnd
            }, () => { this.myCallbackFromEndTermChange(queryNamesEnd, completeConceptArrayEnd, completeConceptArrayEnd[number - 1], number, newEndTermDomains[number - 1], autocompleteOrBrowserEnd[number - 1], false) })
        }
    }

    myCallbackFromStartTermChange = async (query, ocids, completeArrayStart, pathNumber, domains, autocompleteOrBrowserStart, run, selectedRelations) => {
        if (run) {
            this.setState({
                fetchingResults: true
            })

            if (this.state.logicals[pathNumber - 1] === 'aaa' || this.state.logicals[pathNumber - 1] === 'aac' || this.state.logicals[pathNumber - 1] === 'aac' || this.state.logicals[pathNumber - 1] === 'aad'
                || this.state.logicals[pathNumber - 1] === 'aapp' || this.state.logicals[pathNumber - 1] === 'aap' || this.state.logicals[pathNumber - 1] === 'caa' || this.state.logicals[pathNumber - 1] === 'daa' ||
                this.state.logicals[pathNumber - 1] === 'ppaa' || this.state.logicals[pathNumber - 1] === 'paa') {

                let request = {
                    apiRevision: 0,
                    domainWhitelist: [
                    ],
                    forceCompletePathMatching: true,
                    //matchedPathsMaxCount: 100,
                    matchedPathsMaxTripleSentences: 0,
                    matchedPathsOffset: 0,
                    //matchedPathsRanking: "TRIPLE_COUNT_AVG",
                    matchedPathsWithTripleBaseData: false,
                    maxMegaTriplesToProcess: 2,
                    maxTopMatchedConceptsPerPathStep: 1000,
                    matchedPathsOrdering: "RANKED",
                    pathEntitiesFilter: {
                    },
                    pathRelationsFilter: {
                    },
                    repository: this.state.selectedRepository,
                    topMatchPerPathStepCountingUseTripleCount: true,
                    //topMatchedEntitiesPerPathStepIndexes: [0],
                    topMatchedRelationsPerPathStepIndexes: [
                        1
                    ],
                    //uniqueMatchedEntitiesPerPathStepIndexes: [0],
                    //uniqueMatchedRelationsPerPathStepIndexes: [0],
                    withConceptDomains: false,
                    withConceptPrefnames: true
                }

                let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
                { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

                let entities = {}

                let allEntities = []
                if (ocids[0].length !== 0) {
                    ocids.forEach(ocid => {
                        let allSubEntities = []
                        for (let i = 0; i < ocid.length; i++) {
                            for (let j = 0; j < ocid[i].length; j++) {
                                allSubEntities = [...allSubEntities, { ocid: ocid[i][j]['+ocid'], conceptLookupType: this.state.startChecked[pathNumber - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                            }
                        }
                        allSubEntities.forEach(entity => {
                            allEntities = [...allEntities, (entity)]
                        })
                    })
                } else {
                    allEntities = rootOCIDsAll
                }

                entities[0] = allEntities

                if (this.state.endTermOCID[pathNumber - 1] !== '') {
                    let allEndEntities = []
                    this.state.endTermOCID[pathNumber - 1].forEach(ocid => {
                        let allSubEntities = []
                        for (let i = 0; i < ocid.length; i++) {
                            allSubEntities = [...allSubEntities, { ocid: ocid[i]['+ocid'], conceptLookupType: this.state.endChecked[pathNumber - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                        }
                        allSubEntities.forEach(entity => {
                            allEndEntities = [...allEndEntities, (entity)]
                        })
                    })
                    entities[1] = allEndEntities
                } else {
                    entities[1] = rootOCIDsAll
                }

                request['pathEntitiesFilter'] = entities

                if (selectedRelations !== undefined && selectedRelations.length !== 0) {
                    let allRelations = []
                    selectedRelations.forEach(rel => {
                        allRelations = [...allRelations, { ocid: rel, conceptLookupType: this.state.relationMode[pathNumber - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                    })
                    request['pathRelationsFilter'][1] = allRelations
                } else {
                    request['pathRelationsFilter'] = {
                        "1": this.state.relationsChecked === "narrow" ? [
                            { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                            , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                            { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                            { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
                    }
                }

                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {

                            let matchesRight = []
                            let newRelationsMulti = self.state.relationOCIDMulti.slice()
                            let newTripleHits = self.state.tripleHits.slice()
                            let newUniqueRight = self.state.rightDomains
                            let newUniqueRightShow = self.state.rightDomainsShow
                            //let newUniqueLeft = self.state.leftDomains
                            let newRelationOCID = self.state.relationOCID.slice()
                            let newRelationValues = self.state.relationValues.slice()

                            if (response.data.ocidToPreferredName) {
                                Object.keys(response.data.ocidToPreferredName).forEach(entry => {
                                    let singleMatch = response.data.ocidToPreferredName[entry].match(/\[(.*?)\]/g)
                                    if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiology' ||
                                        singleMatch[1].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'effect') {
                                        matchesRight.push("effects or process")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'animal disease') {
                                        matchesRight.push("disease")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alloy') {
                                        matchesRight.push("substance")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'chem') {
                                        matchesRight.push("chemistry")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'bacterium'
                                        || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'virus') {
                                        matchesRight.push("species")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'compound') {
                                        matchesRight.push("chemistry")
                                        matchesRight.push("drug")
                                        matchesRight.push("natural products")
                                    } else {
                                        matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                    }
                                    //matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                })
                                let uniqueRight = [...new Set(matchesRight)]
                                newRelationsMulti[pathNumber - 1] = response.data.ocidToPreferredName
                                newTripleHits[pathNumber - 1] = response.data.matchedPathsCount
                                newUniqueRight[pathNumber - 1] = ocids[0].length > 0 ? uniqueRight : ['all domains']
                                newUniqueRightShow[pathNumber - 1] = ocids[0].length > 0 ? uniqueRight : ['all domains']
                                newRelationValues[pathNumber - 1] = []
                            } else {
                                newUniqueRight[pathNumber - 1] = ['all domains']
                                newUniqueRightShow[pathNumber - 1] = ['all domains']
                                newTripleHits[pathNumber - 1] = response.data.matchedPathsCount
                                newRelationsMulti[pathNumber - 1] = {}
                                newRelationValues[pathNumber - 1] = []
                            }

                            //newRelationOCID[pathNumber - 1] = ["232000000387", "232000000391", "232000000378", "232000000504", "232000000393", "232000000588"]
                            newRelationOCID[pathNumber - 1] = []
                            self.setState({
                                startTermShow: query,
                                fetchingResults: false,
                                relationOCIDMulti: newRelationsMulti,
                                relationValues: newRelationValues,
                                tripleHits: newTripleHits,
                                rightDomains: newUniqueRight,
                                rightDomainsShow: newUniqueRightShow,
                                //leftDomains: newUniqueLeft,
                                allRightDomainForStart: newUniqueRight,
                                relationOCID: newRelationOCID
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            }
        }

        let oldConceptsStart = this.state.queryConceptsStart
        let newConceptsStart = oldConceptsStart.filter(function (item) {
            return query.indexOf(item) !== -1
        })

        /*let newCompleteArrayStart
        if (completeArrayStart === undefined) {
            newCompleteArrayStart = []
        } else {
            newCompleteArrayStart = completeArrayStart
        }*/

        let newStartTermDomains = this.state.startTermDomains.slice()
        newStartTermDomains[pathNumber - 1] = domains

        let newAutocompleteOrBrowserStart = this.state.autocompleteOrBrowserStart.slice()
        newAutocompleteOrBrowserStart[pathNumber - 1] = autocompleteOrBrowserStart

        this.setState({
            startTerm: query,
            startTermOCID: ocids,
            queryConceptsStart: newConceptsStart,
            rerenderTable: false,
            filterChecked: query[0].length === 0 && this.state.pathNumber > 1 ? false : this.state.filterChecked,
            startTermDomains: newStartTermDomains,
            autocompleteOrBrowserStart: newAutocompleteOrBrowserStart
        }, async () => {
            if (run) {
                if (this.state.logicals[pathNumber - 1] !== 'aaa' && this.state.logicals[pathNumber - 1] !== 'aac' && this.state.logicals[pathNumber - 1] !== 'aac' && this.state.logicals[pathNumber - 1] !== 'aad'
                    && this.state.logicals[pathNumber - 1] !== 'aapp' && this.state.logicals[pathNumber - 1] !== 'aap' && this.state.logicals[pathNumber - 1] !== 'caa' && this.state.logicals[pathNumber - 1] !== 'daa' &&
                    this.state.logicals[pathNumber - 1] !== 'ppaa' && this.state.logicals[pathNumber - 1] !== 'paa') {
                    await this.myCallbackFromExecuteSinglePath(pathNumber, true)
                }
                if (this.state.pathNumber > 1) {
                    for (let i = 1; i <= this.state.pathNumber - 1; i++) {
                        await this.myCallbackFromExecutePath(i, true)
                    }
                }
                if (this.state.pathNumber > 1) {
                    await this.handleSearch(this.state.pathNumber, true)
                }
            }
        })
    }

    myCallbackFromRelationMultiChange = (relations, number, uniqueLeft, uniqueRight, relationNames, relationValues) => {
        this.setState({
            fetchingResults: false
        })
        const newRelationOCID = this.state.relationOCID.slice()
        let newItems = []
        relations.forEach(rel => {
            newItems.push(rel)
        })
        newRelationOCID[number - 1] = newItems

        const newLeftDomains = this.state.leftDomains.slice()
        const newLeftDomainsShow = this.state.leftDomainsShow.slice()
        const newRightDomains = this.state.rightDomains.slice()
        const newRightDomainsShow = this.state.rightDomainsShow.slice()
        const newRelationNames = this.state.relationNames.slice()
        const newRelationValues = this.state.relationValues.slice()

        if (number === 1 && relations.length === 0 && this.state.startTerm[0].length !== 0) {
            newRightDomains[number - 1] = this.state.allRightDomainForStart[0]
            newLeftDomains[number - 1] = ['all domains']
            newRightDomainsShow[number - 1] = this.state.allRightDomainForStart[0]
            newLeftDomainsShow[number - 1] = ['all domains']
        } else if (number === 1 && relations.length === 0 && this.state.endTerm[0].length !== 0) {
            newRightDomains[number - 1] = ['all domains']
            newLeftDomains[number - 1] = this.state.allLeftDomainForStart[0]
            newRightDomainsShow[number - 1] = ['all domains']
            newLeftDomainsShow[number - 1] = this.state.allLeftDomainForStart[0]
        } else {
            newRightDomains[number - 1] = uniqueRight.length > 0 ? uniqueRight : ['all domains']
            newLeftDomains[number - 1] = uniqueLeft.length > 0 ? uniqueLeft : ['all domains']
            newRightDomainsShow[number - 1] = uniqueRight.length > 0 ? uniqueRight : ['all domains']
            newLeftDomainsShow[number - 1] = uniqueLeft.length > 0 ? uniqueLeft : ['all domains']
        }

        if (number >= 2) {
            newRightDomains[number - 2] = uniqueLeft.length > 0 ? uniqueLeft : ['all domains']
            newRightDomainsShow[number - 2] = uniqueLeft.length > 0 ? uniqueLeft : ['all domains']
        }
        newRelationNames[number - 1] = relationNames
        newRelationValues[number - 1] = relationValues

        this.setState({
            relationOCID: newRelationOCID,
            leftDomains: newLeftDomains,
            leftDomainsShow: newLeftDomainsShow,
            rightDomains: newRightDomains,
            rightDomainsShow: newRightDomainsShow,
            relationNames: newRelationNames,
            relationValues: newRelationValues
        }, async () => {
            await this.myCallbackFromExecuteSinglePath(number, true)
            if (this.state.pathNumber > 1) {
                for (let i = 1; i <= this.state.pathNumber - 1; i++) {
                    await this.myCallbackFromExecutePath(i, true)
                }
            }
            if (this.state.pathNumber > 1) {
                await this.handleSearch(this.state.pathNumber, true)
            }
        })
    }

    myCallbackFromRelationModeChange = (relationMode, number) => {
        this.setState({
            fetchingResults: false
        })
        const newRelationMode = this.state.relationMode.slice()
        newRelationMode[number - 1] = relationMode

        this.setState({
            relationMode: newRelationMode
        }, async () => {
            await this.myCallbackFromExecuteSinglePath(number, true)
            if (this.state.pathNumber > 1) {
                for (let i = 1; i <= this.state.pathNumber - 1; i++) {
                    await this.myCallbackFromExecutePath(i, true)
                }
            }
            if (this.state.pathNumber > 1) {
                await this.handleSearch(this.state.pathNumber, true)
            }
        })
    }

    myCallbackFromRelationChange = (relation, relationOCID, completeArray, pathNumber) => {
        this.setState({
            relation: relationOCID,
            relationOCID: relationOCID,
            rerenderTable: false
        })
    }

    myCallbackFromEndTermChange = async (query, ocids, completeArrayEnd, pathNumber, domains, autocompleteOrBrowserEnd, run) => {

        if (run) {

            this.setState({
                fetchingResults: true
            })

            if (this.state.logicals[pathNumber - 1] === 'aaa' || this.state.logicals[pathNumber - 1] === 'aac' || this.state.logicals[pathNumber - 1] === 'aac' || this.state.logicals[pathNumber - 1] === 'aad'
                || this.state.logicals[pathNumber - 1] === 'aapp' || this.state.logicals[pathNumber - 1] === 'aap' || this.state.logicals[pathNumber - 1] === 'caa' || this.state.logicals[pathNumber - 1] === 'daa' ||
                this.state.logicals[pathNumber - 1] === 'ppaa' || this.state.logicals[pathNumber - 1] === 'paa') {


                let request = {
                    apiRevision: 0,
                    domainWhitelist: [
                    ],
                    forceCompletePathMatching: true,
                    //matchedPathsMaxCount: 100,
                    matchedPathsMaxTripleSentences: 0,
                    matchedPathsOffset: 0,
                    //matchedPathsRanking: "TRIPLE_COUNT_AVG",
                    matchedPathsWithTripleBaseData: false,
                    maxMegaTriplesToProcess: 2,
                    maxTopMatchedConceptsPerPathStep: 1000,
                    matchedPathsOrdering: "RANKED",
                    pathEntitiesFilter: {
                    },
                    pathRelationsFilter: {
                    },
                    repository: this.state.selectedRepository,
                    topMatchPerPathStepCountingUseTripleCount: true,
                    /*topMatchedEntitiesPerPathStepIndexes: [
                        0
                    ],*/
                    topMatchedRelationsPerPathStepIndexes: [
                        1
                    ],
                    /*uniqueMatchedEntitiesPerPathStepIndexes: [
                        0
                    ],*/
                    /*uniqueMatchedRelationsPerPathStepIndexes: [
                        0
                    ],*/
                    withConceptDomains: false,
                    withConceptPrefnames: true
                }

                let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
                { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

                let entities = {}

                let allEntities = []
                if (pathNumber === 1) {
                    if (ocids[pathNumber - 1] !== "") {
                        ocids[pathNumber - 1].forEach(ocid => {
                            let allSubEntities = []
                            for (let i = 0; i < ocid.length; i++) {
                                allSubEntities = [...allSubEntities, { ocid: ocid[i]['+ocid'], conceptLookupType: this.state.endChecked[pathNumber - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]

                            }
                            allSubEntities.forEach(entity => {
                                allEntities = [...allEntities, (entity)]
                            })
                        })
                    } else {
                        allEntities = rootOCIDsAll
                    }
                } else {
                    if (ocids[pathNumber - 1]) {
                        ocids[pathNumber - 1].forEach(ocid => {
                            let allSubEntities = []
                            for (let i = 0; i < ocid.length; i++) {
                                allSubEntities = [...allSubEntities, { ocid: ocid[i]['+ocid'], conceptLookupType: this.state.endChecked[pathNumber - 2] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                            }
                            allSubEntities.forEach(entity => {
                                allEntities = [...allEntities, (entity)]
                            })
                        })
                    } else {
                        allEntities = rootOCIDsAll
                    }
                }

                entities[1] = allEntities

                let allStartEntities = []

                if (this.state.startTermOCID[pathNumber - 1] !== '' && pathNumber === 1) {
                    this.state.startTermOCID[pathNumber - 1].forEach(ocid => {
                        let allSubEntities = []
                        for (let i = 0; i < ocid.length; i++) {
                            allSubEntities = [...allSubEntities, { ocid: ocid[i]['+ocid'], conceptLookupType: this.state.endChecked[pathNumber - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                        }
                        allSubEntities.forEach(entity => {
                            allStartEntities = [...allStartEntities, (entity)]
                        })
                    })
                    entities[0] = allStartEntities
                } else if (pathNumber !== 1) {
                    if (this.state.endTermOCID[pathNumber - 2]) {
                        this.state.endTermOCID[pathNumber - 2].forEach(ocid => {
                            let allSubEntities = []
                            for (let i = 0; i < ocid.length; i++) {
                                allSubEntities = [...allSubEntities, { ocid: ocid[i]['+ocid'], conceptLookupType: this.state.endChecked[pathNumber - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                            }
                            allSubEntities.forEach(entity => {
                                allStartEntities = [...allStartEntities, (entity)]
                            })
                        })
                        entities[0] = allStartEntities
                    }
                } else {
                    entities[0] = rootOCIDsAll
                }

                request['pathEntitiesFilter'] = entities

                request['pathRelationsFilter'] = {
                    "1": this.state.relationsChecked === "narrow" ? [
                        { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                        , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                        { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                        { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
                }

                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchesRight = []
                            let matchesLeft = []
                            let newRelationsMulti = self.state.relationOCIDMulti.slice()
                            //let newTripleHits = self.state.tripleHits.slice()
                            let newUniqueRight = self.state.rightDomains
                            let newUniqueRightShow = self.state.rightDomainsShow
                            let newUniqueLeft = self.state.leftDomains
                            let newUniqueLeftShow = self.state.leftDomainsShow
                            let newRelationOCID = self.state.relationOCID.slice()

                            if (response.data.ocidToPreferredName) {
                                Object.keys(response.data.ocidToPreferredName).forEach(entry => {

                                    let singleMatch = response.data.ocidToPreferredName[entry].match(/\[(.*?)\]/g)

                                    if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiology' ||
                                        singleMatch[1].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'effect') {
                                        matchesRight.push("effects or process")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'animal disease') {
                                        matchesRight.push("disease")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alloy') {
                                        matchesRight.push("substance")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'chem') {
                                        matchesRight.push("chemistry")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'bacterium'
                                        || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'virus') {
                                        matchesRight.push("species")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'compound') {
                                        matchesRight.push("chemistry")
                                        matchesRight.push("drug")
                                        matchesRight.push("natural products")
                                    } else {
                                        matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                    }
                                })

                                Object.keys(response.data.ocidToPreferredName).forEach(entry => {
                                    let singleMatch = response.data.ocidToPreferredName[entry].match(/\[(.*?)\]/g)
                                    if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'physiology' ||
                                        singleMatch[0].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'effect') {
                                        matchesLeft.push("effects or process")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'animal disease') {
                                        matchesLeft.push("disease")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'alloy') {
                                        matchesLeft.push("substance")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'chem') {
                                        matchesLeft.push("chemistry")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'bacterium'
                                        || singleMatch[0].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'virus') {
                                        matchesLeft.push("species")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'compound') {
                                        matchesLeft.push("chemistry")
                                        matchesLeft.push("drug")
                                        matchesLeft.push("natural products")
                                    } else {
                                        matchesLeft.push(singleMatch[0].match(/\[(.*?)\]/)[1])
                                    }
                                    // matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                })
                                let uniqueLeft = [...new Set(matchesLeft)]
                                let uniqueLeftShow = [...new Set(matchesLeft)]

                                newRelationsMulti[pathNumber - 1] = response.data.ocidToPreferredName

                                if (pathNumber === 1) {
                                    newUniqueLeft[pathNumber - 1] = ocids[0].length > 0 ? uniqueLeft : ['all domains']
                                    newUniqueLeftShow[pathNumber - 1] = ocids[0].length > 0 ? uniqueLeftShow : ['all domains']
                                }
                            } else {
                                newUniqueLeft[pathNumber - 1] = ['all domains']
                                newUniqueLeftShow[pathNumber - 1] = ['all domains']
                                newUniqueRight[pathNumber] = ['all domains']
                                newUniqueRightShow[pathNumber] = ['all domains']
                                newRelationsMulti[pathNumber - 1] = {}
                            }
                            newRelationOCID[pathNumber - 1] = []
                            let newRelationValues = self.state.relationValues.slice()
                            newRelationValues[pathNumber - 1] = []
                            self.setState({
                                fetchingResults: false,
                                endTermShow: query,
                                relationOCIDMulti: newRelationsMulti,
                                rerenderTable: false,
                                leftDomains: newUniqueLeft,
                                leftDomainsShow: newUniqueLeftShow,
                                allLeftDomainForStart: newUniqueLeft,
                                allLeftDomainForStartShow: newUniqueLeftShow,
                                relationOCID: newRelationOCID,
                                relationValues: newRelationValues,
                                //rightDomains: newUniqueRight
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            }

        }

        let oldConceptsEnd = this.state.queryConceptsEnd
        let newConceptsEnd = oldConceptsEnd.filter(function (item) {
            return query.indexOf(item) !== -1
        })
        /*let newCompleteArrayEnd
        if (completeArrayEnd === undefined) {
            newCompleteArrayEnd = []
        } else {
            newCompleteArrayEnd = completeArrayEnd
        }*/
        let newRelationOCID = this.state.relationOCID.slice()
        newRelationOCID[pathNumber - 1] = [] /*this.state.relationsChecked === "narrow" ? [
            '232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588'] :
            ['232000000387', '232000000391', '232000000378', '232000000504', '232000000393', '232000000588',
                '232000000022', '232000000125', '232000000179', '232000000142', '232000000534', '232000000133', '232000000353',
                '232000000179', '232000001284', '232000000055', '232000000882', '232000001080', '232000000883']*/

        let newEndTermDomains = this.state.endTermDomains.slice()
        newEndTermDomains[pathNumber - 1] = domains
        let newAutocompleteOrBrowserEnd = this.state.autocompleteOrBrowserEnd.slice()
        newAutocompleteOrBrowserEnd[pathNumber - 1] = autocompleteOrBrowserEnd

        this.setState({
            endTerm: query,
            endTermOCID: ocids,
            endTermDomains: newEndTermDomains,
            queryConceptsEnd: newConceptsEnd,
            relationOCID: newRelationOCID,
            rerenderTable: false,
            filterChecked: this.state.pathNumber === pathNumber && this.state.pathNumber > 1 && query[0].length === 0 ? false : this.state.filterChecked,
            autocompleteOrBrowserEnd: newAutocompleteOrBrowserEnd
        }, async () => {
            if (run) {
                await this.myCallbackFromExecuteSinglePath(pathNumber, true)
                if (this.state.pathNumber >= pathNumber + 1) {
                    await this.myCallbackFromExecuteSinglePath(pathNumber + 1, true)
                }
                if (this.state.pathNumber > 1) {
                    for (let i = 1; i <= this.state.pathNumber - 1; i++) {
                        await this.myCallbackFromExecutePath(i, true)
                    }
                    await this.calculateRelations(this.state.pathNumber, pathNumber)
                }
                if (this.state.pathNumber > 1) {
                    await this.handleSearch(this.state.pathNumber, true)
                }
            }
        })
    }

    calculateRelations = async (statePathNumber, pathNumber) => {
        if (pathNumber < statePathNumber) {
            let blacklistEntries = []

            if (this.state.selectedBlacklists && this.state.selectedBlacklists.length > 0) {
                for (let i = 0; i < this.state.selectedBlacklists.length; i++) {
                    if (hasUserRole('ROLE_BLACKLISTS')) {
                        await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${this.state.selectedBlacklists[i]}`, { headers: getHeaderToken() })
                            // eslint-disable-next-line
                            .then(function (response) {
                                //self.showCollectionRefreshSuccessGrowl()
                                let entries = response.data
                                blacklistEntries = [...blacklistEntries, entries]
                            })
                            .catch(function (error) {
                                if (error.response.status === 400) {
                                    this.showFailGrowl(error.response.data)
                                } else if (error.response.status === 401) {
                                    this.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                    }
                }
            }

            let negated = []

            blacklistEntries.forEach(entry => {
                entry.forEach(item => {
                    negated = [...negated, { ocid: item.ocid, negated: true, conceptLookupType: item.conceptLookupType }]
                })
            })

            this.setState({
                fetchingResults: true
            })

            let request = {
                apiRevision: 0,
                domainWhitelist: [
                ],
                forceCompletePathMatching: true,
                //matchedPathsMaxCount: 100,
                matchedPathsMaxTripleSentences: 0,
                matchedPathsOffset: 0,
                //matchedPathsRanking: "TRIPLE_COUNT_AVG",
                matchedPathsWithTripleBaseData: false,
                maxMegaTriplesToProcess: 2,
                maxTopMatchedConceptsPerPathStep: 1000,
                matchedPathsOrdering: "RANKED",
                pathEntitiesFilter: {
                },
                pathRelationsFilter: {
                },
                repository: this.state.selectedRepository,
                topMatchPerPathStepCountingUseTripleCount: true,
                /*topMatchedEntitiesPerPathStepIndexes: [
                    0
                ],*/
                topMatchedRelationsPerPathStepIndexes: [
                    1
                ],
                /*uniqueMatchedEntitiesPerPathStepIndexes: [
                    0
                ],*/
                /*uniqueMatchedRelationsPerPathStepIndexes: [
                    0
                ],*/
                withConceptDomains: false,
                withConceptPrefnames: true
            }

            let entities = {}
            let counter = 0

            let rootOCIDs = [{ ocid: 150000001799 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 }]
            let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
            { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

            if (this.state.endTerm[pathNumber - 1] === undefined || ((this.state.endTerm[pathNumber - 1] === '' || this.state.endTerm[pathNumber - 1].length === 0) && pathNumber - 1 === 0)) {
                this.state.logicals[pathNumber - 1] === 'ctd' || this.state.logicals[pathNumber - 1] === 'cip' || this.state.logicals[pathNumber - 1] === 'cid' ||
                    this.state.logicals[pathNumber - 1] === 'cic' || this.state.logicals[pathNumber - 1] === 'cipp' || this.state.logicals[pathNumber - 1] === 'caa' ?
                    entities[0] = rootOCIDs
                    :
                    this.state.endTerm[pathNumber - 1] === undefined || this.state.logicals[pathNumber - 1] === 'aaa' || this.state.logicals[pathNumber - 1] === 'aac' || this.state.logicals[pathNumber - 1] === 'aad' ||
                        this.state.logicals[pathNumber - 1] === 'aapp' || this.state.logicals[pathNumber - 1] === 'aap' ?
                        entities[0] = rootOCIDsAll
                        :
                        entities[0] = [{
                            domain:
                                this.state.logicals[pathNumber - 1] === 'pid' ? 'proteins' :
                                    this.state.logicals[pathNumber - 1] === 'paa' ? 'proteins' :
                                        this.state.logicals[pathNumber - 1] === 'pmc' ? 'proteins' :
                                            this.state.logicals[pathNumber - 1] === 'pmpp' ? 'proteins' :
                                                this.state.logicals[pathNumber - 1] === 'ppmp' ? '' :
                                                    this.state.logicals[pathNumber - 1] === 'ppipp' ? '' :
                                                        this.state.logicals[pathNumber - 1] === 'pppd' ? '' :
                                                            this.state.logicals[pathNumber - 1] === 'dmp' ? 'diseases' :
                                                                this.state.logicals[pathNumber - 1] === 'daa' ? 'diseases' : '',
                            ocid: this.state.logicals[pathNumber - 1] === 'ppmp' || this.state.logicals[pathNumber - 1] === 'ppipp' || this.state.logicals[pathNumber - 1] === 'pppd'
                                || this.state.logicals[pathNumber - 1] === 'ppaa' ? '230000035790' : ''
                        }
                        ]
                counter = counter + 1
            } else if (this.state.endTerm[pathNumber - 1] !== '' && pathNumber - 1 === 0) {
                let allEntities = []
                for (let j = 0; j < this.state.endTerm[pathNumber - 1].length; j++) {
                    let allSubEntities = []
                    for (let k = 0; k < this.state.endTermOCID[pathNumber - 1][j].length; k++) {
                        allSubEntities = [...allSubEntities, { ocid: this.state.endTermOCID[pathNumber - 1][j][k]['+ocid'], conceptLookupType: this.state.startChecked[pathNumber - 1] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                    }
                    allSubEntities.forEach(entity => {
                        allEntities = [...allEntities, (entity)]
                    })
                }
                entities[0] = allEntities
                counter = counter + 1
            }

            if (this.state.endTerm[pathNumber] === '' || this.state.endTerm[pathNumber] === undefined || (this.state.endTerm[pathNumber] !== undefined && this.state.endTerm[pathNumber].length === 0)) {
                this.state.logicals[pathNumber] === 'cic' || this.state.logicals[pathNumber] === 'pmc' || this.state.logicals[pathNumber] === 'aac' ?
                    entities[1] = rootOCIDs
                    :
                    this.state.logicals[pathNumber] === 'aaa' || this.state.logicals[pathNumber] === 'caa' || this.state.logicals[pathNumber] === 'daa' ||
                        this.state.logicals[pathNumber] === 'ppaa' || this.state.logicals[pathNumber] === 'paa' ?
                        entities[1] = rootOCIDsAll
                        :
                        entities[1] = [{
                            domain:
                                this.state.logicals[pathNumber] === 'ctd' ? 'diseases' :
                                    this.state.logicals[pathNumber] === 'cip' ? 'proteins' :
                                        this.state.logicals[pathNumber] === 'cid' ? 'diseases' :
                                            this.state.logicals[pathNumber] === 'cipp' ? '' :
                                                this.state.logicals[pathNumber] === 'ppipp' ? '' :
                                                    this.state.logicals[pathNumber] === 'pid' ? 'diseases' :
                                                        this.state.logicals[pathNumber] === 'pppd' ? 'diseases' :
                                                            this.state.logicals[pathNumber] === 'aad' ? 'diseases' :
                                                                this.state.logicals[pathNumber] === 'pmpp' ? '' :
                                                                    this.state.logicals[pathNumber] === 'ppmp' ? 'proteins' :
                                                                        this.state.logicals[pathNumber] === 'aap' ? 'proteins' :
                                                                            this.state.logicals[pathNumber] === 'dmp' ? 'proteins' : '',
                            ocid: this.state.logicals[pathNumber] === 'cipp' || this.state.logicals[pathNumber] === 'pmpp' || this.state.logicals[pathNumber] === 'ppipp'
                                || this.state.logicals[pathNumber] === 'aapp' ? '230000035790' : ''
                        }
                        ]
            } else {
                let allEntities = []
                for (let j = 0; j < this.state.endTerm[pathNumber].length; j++) {
                    let allSubEntities = []
                    for (let k = 0; k < this.state.endTermOCID[pathNumber][j].length; k++) {
                        allSubEntities = [...allSubEntities, { ocid: this.state.endTermOCID[pathNumber][j][k]['+ocid'], conceptLookupType: this.state.endChecked[pathNumber] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                    }
                    allSubEntities.forEach(entity => {
                        allEntities = [...allEntities, (entity)]
                    })
                }
                entities[1] = allEntities
            }


            if (negated && negated.length > 0) {
                Object.keys(entities).forEach(key => {
                    for (let j = 0; j < negated.length; j++) {
                        entities[key] = [...entities[key], negated[j]]
                    }
                })
            }

            request['pathEntitiesFilter'] = entities

            request['pathRelationsFilter'][1] = this.state.relationsChecked === "narrow" ? [
                { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]

            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status === 200) {
                        if (response.data.ocidToPreferredName) {
                            let matchesRight = []
                            let newUniqueRight = self.state.rightDomains.slice()
                            let newUniqueRightShow = self.state.rightDomainsShow.slice()

                            Object.keys(response.data.ocidToPreferredName).forEach(entry => {
                                let singleMatch = response.data.ocidToPreferredName[entry].match(/\[(.*?)\]/g)

                                if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiology' ||
                                    singleMatch[1].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'effect') {
                                    matchesRight.push("effects or process")
                                } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'animal disease') {
                                    matchesRight.push("disease")
                                } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alloy') {
                                    matchesRight.push("substance")
                                } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'chem') {
                                    matchesRight.push("chemistry")
                                } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'bacterium'
                                    || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'virus') {
                                    matchesRight.push("species")
                                } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'compound') {
                                    matchesRight.push("chemistry")
                                    matchesRight.push("drug")
                                    matchesRight.push("natural products")
                                } else {
                                    matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                }
                                // matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                            })
                            let uniqueRight = [...new Set(matchesRight)]

                            let newRelationsMulti = self.state.relationOCIDMulti.slice()
                            newRelationsMulti[pathNumber] = response.data.ocidToPreferredName
                            newUniqueRight[pathNumber] = uniqueRight
                            newUniqueRightShow[pathNumber] = uniqueRight
                            self.setState({
                                fetchingResults: false,
                                relationOCIDMulti: newRelationsMulti,
                                rerenderTable: false,
                                rightDomains: newUniqueRight,
                                rightDomainsShow: newUniqueRightShow
                            })
                        } else {
                            let newRelationsMulti = self.state.relationOCIDMulti.slice()
                            newRelationsMulti[pathNumber] = []
                            self.setState({
                                fetchingResults: false,
                                relationOCIDMulti: newRelationsMulti,
                                rerenderTable: false
                            })
                        }
                    }
                }).catch(function (response) {
                    if (response.response.status === 400) {
                    } else if (response.response.status === 401) {
                        self.props.history.push('/')
                    }
                })
        }
    }

    myCallbackFromTypeRadioChange = (type, pathNumber) => {
        this.setState({
            type: type,
            rerenderTable: false
        })
    }

    myCallbackFromStartToggleChange = (value, pathNumber) => {
        this.setState({
            startChecked: value,
            rerenderTable: false
        })
    }

    myCallbackFromEndToggleChange = (value, pathNumber) => {
        this.setState({
            endChecked: value,
            rerenderTable: false
        })
    }

    myCallbackFromRelationToggleChange = (value, pathNumber) => {
        this.setState({
            relationMode: value,
            rerenderTable: false
        })
    }

    myCallbackFromClear = () => {
        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }
        this.setState({
            selectedFormatPaths: this.formats[0],
            selectedFormatEntities: this.formats[0],
            selectedFormatSentences: this.formats[0],
            pathNumber: 0,
            startTerm: [''],
            startTermOCID: [''],
            startChecked: ['ontological'],
            relationMode: ['ontological'],
            endTerm: [''],
            endTermOCID: [''],
            endChecked: ['ontological'],
            type: ['relations'],
            relation: [''],
            relationOCID: [''],
            pathRepositories: [[]],
            //pathBlacklists: [],
            selectedBlacklists: [],
            logicals: ['free'],
            queryComplete: [],
            queryOCIDS: [],
            queryNames: [],
            showChildren: false,
            pathOne: false,
            activeIndex: 2,
            disabledFull: true,
            disabledTriple: true,
            disabledSubPath: true,
            subPathNumber: 0,
            fullPathNumber: 0,
            tripleNumber: 0,
            rerenderTable: false,
            queryConceptsStart: [],
            queryConceptsEnd: [],
            selectedRepository: 'relation',
            filterTriple: [],
            filterSub: [],
            filterFull: [],
            relationOCIDMulti: [''],
            tripleHits: [''],
            subPathHits: [''],
            fullPathHits: [''],
            leftDomains: [['all domains']],
            rightDomains: [['all domains']],
            leftDomainsShow: [['all domains']],
            rightDomainsShow: [['all domains']],
            filterChecked: false,
            relationsChecked: "narrow",
            numberOfHops: [1, 4],
            hopsOfTable: 1,
            hopsToShow: 1,
            hopData: [],
            hopRequests: [{}],
            filterZeroHops: [],
            filterOneHop: [],
            filterTwoHops: [],
            filterThreeHops: [],
            filterFourHops: [],
            pathsHops: [[]],
            countsHops: [""],
            preferredNamesHops: [{}],
            disabledHops: true,
            countFullPath: 20,
            startFullPath: 0,
            countSubPath: 20,
            startSubPath: 0,
            countTriplePath: 20,
            startTriplePath: 0,
            countZeroHopsPath: 20,
            startZeroHopsPath: 0,
            countOneHopPath: 20,
            startOneHopPath: 0,
            countTwoHopsPath: 20,
            startTwoHopsPath: 0,
            countThreeHopsPath: 20,
            startThreeHopsPath: 0,
            countFourHopsPath: 20,
            startFourHopsPath: 0,
            startTermDomains: [[]],
            endTermDomains: [[]],
            checkedEntities: [],
            autocompleteOrBrowserStart: [[]],
            autocompleteOrBrowserEnd: [[]],
            displayConfirmationDialog: false,
            displayExportDialog: false,
            rowsPaths: "all",
            rowsEntities: "all",
            rowsSentences: "all",
            activeIndexExport: 0,
            selectedEntityOption: 'unique',
            selectedColumnOption: 1,
            selectedNumberSentences: '1',
            selectedTriple: 1,
            selectedRowsTriple: [],
            selectedRowsSubPath: [],
            selectedRowsZeroHops: [],
            selectedRowsOneHop: [],
            selectedRowsTwoHops: [],
            selectedRowsThreeHops: [],
            selectedRowsFourHops: [],
            selectedRowsPath: [],
            showResults: false,
            displaySaveQueryDialog: false,
            queryFullName: '',
            queryName: '',
            queryDescription: '',
            queryString: '',
            relationNames: [['All possible relations']],
            relationValues: [[]],
            fromEdit: false,
            editQuery: false,
            displayCustomToast: false
        })
    }

    myCallbackFromRemove = (pathNumber) => {
        let newPathNumber = pathNumber - 1

        let newStartTerms = this.state.startTerm
        let newStartTermsOCID = this.state.startTermOCID
        let newStartChecked = this.state.startChecked
        let newRelationMode = this.state.relationMode

        let newRelations = this.state.relation
        let newRelationOCID = this.state.relationOCID
        let newRelationOCIDMulti = this.state.relationOCIDMulti

        let newEndTerms = this.state.endTerm
        let newEndTermsOCID = this.state.endTermOCID
        let newEndChecked = this.state.endChecked

        let newTripleHits = this.state.tripleHits
        let newSubPathHits = this.state.subPathHits
        let newFullPathHits = this.state.fullPathHits
        newFullPathHits[pathNumber - 2] = newSubPathHits[pathNumber - 2]

        let newLeftDomains = this.state.leftDomains
        let newRightDomains = this.state.rightDomains

        let newLeftDomainsShow = this.state.leftDomainsShow
        let newRightDomainsShow = this.state.rightDomainsShow

        let newStartTermDomains = this.state.startTermDomains
        let newEndTermDomains = this.state.endTermDomains

        let newAutocompleteOrBrowserStart = this.state.autocompleteOrBrowserStart
        let newAutocompleteOrBrowserEnd = this.state.autocompleteOrBrowserEnd

        let newRelationNames = this.state.relationNames

        let newRelationValues = this.state.relationValues

        newStartTerms = newStartTerms.slice(0, pathNumber - 1)
        newStartTermsOCID = newStartTermsOCID.slice(0, pathNumber - 1)
        newStartChecked = newStartChecked.slice(0, pathNumber - 1)
        newRelationMode = newRelationMode.slice(0, pathNumber - 1)

        newRelations = newRelations.slice(0, pathNumber - 1)
        newRelationOCID = newRelationOCID.slice(0, pathNumber - 1)
        newRelations = newRelations.slice(0, pathNumber - 1)
        newRelationOCIDMulti = newRelationOCIDMulti.slice(0, pathNumber - 1)

        newEndTerms = newEndTerms.slice(0, pathNumber - 1)
        newEndTermsOCID = newEndTermsOCID.slice(0, pathNumber - 1)
        newEndChecked = newEndChecked.slice(0, pathNumber - 1)

        newTripleHits = newTripleHits.slice(0, pathNumber - 1)
        newSubPathHits = newSubPathHits.slice(0, pathNumber - 1)
        newFullPathHits = newFullPathHits.slice(0, pathNumber - 1)

        newLeftDomains = newLeftDomains.slice(0, pathNumber - 1)
        newRightDomains = newRightDomains.slice(0, pathNumber - 1)

        newLeftDomainsShow = newLeftDomainsShow.slice(0, pathNumber - 1)
        newRightDomainsShow = newRightDomainsShow.slice(0, pathNumber - 1)

        newStartTermDomains = newStartTermDomains.slice(0, pathNumber - 1)
        newEndTermDomains = newEndTermDomains.slice(0, pathNumber - 1)

        newAutocompleteOrBrowserStart = newAutocompleteOrBrowserStart.slice(0, pathNumber - 1)
        newAutocompleteOrBrowserEnd = newAutocompleteOrBrowserEnd.slice(0, pathNumber - 1)

        newRelationNames = newRelationNames.slice(0, pathNumber - 1)

        this.setState({
            pathNumber: newPathNumber,
            startTerm: newStartTerms,
            startTermOCID: newStartTermsOCID,
            startChecked: newStartChecked,
            relationMode: newRelationMode,
            endTerm: newEndTerms,
            endTermOCID: newEndTermsOCID,
            endChecked: newEndChecked,
            relation: newRelations,
            relationOCID: newRelationOCID,
            rerenderTable: false,
            relationOCIDMulti: newRelationOCIDMulti,
            tripleHits: newTripleHits,
            subPathHits: newSubPathHits,
            fullPathHits: newFullPathHits,
            leftDomains: newLeftDomains,
            rightDomains: newRightDomains,
            leftDomainsShow: newLeftDomainsShow,
            rightDomainsShow: newRightDomainsShow,
            startTermDomains: newStartTermDomains,
            endTermDomains: newEndTermDomains,
            autocompleteOrBrowserStart: newAutocompleteOrBrowserStart,
            autocompleteOrBrowserEnd: newAutocompleteOrBrowserEnd,
            relationNames: newRelationNames,
            relationValues: newRelationValues
            //showResults: pathNumber === 0 ? false : null
        })
    }

    myCallbackFromStart = async (pathNumber, type, pathRepositories, logicals, relation, relationOCID, startTerm, startTermShow, startTermOCID, startChecked, endTerm, endTermShow, endTermOCID, endChecked, fromEdit, relationMode) => {
        if (fromEdit) {
            this.handleSearch(this.state.pathNumber, undefined, this.state.filterChecked, this.state.relationsChecked)
        } else {
            if (logicals[0] === 'aaa' || logicals[0] === 'aac' || logicals[0] === 'aad' || logicals[0] === 'aap' ||
                logicals[0] === 'aapp' || logicals[0] === 'caa' || logicals[0] === 'daa' || logicals[0] === 'paa' || logicals[0] === 'ppaa') {

                this.setState({
                    fetchingResults: true
                })

                let request = {
                    apiRevision: 0,
                    domainWhitelist: [
                    ],
                    forceCompletePathMatching: true,
                    //matchedPathsMaxCount: 100,
                    matchedPathsMaxTripleSentences: 0,
                    matchedPathsOffset: 0,
                    //matchedPathsRanking: "TRIPLE_COUNT_AVG",
                    matchedPathsWithTripleBaseData: false,
                    maxMegaTriplesToProcess: 2,
                    maxTopMatchedConceptsPerPathStep: 1000,
                    matchedPathsOrdering: "RANKED",
                    pathEntitiesFilter: {
                    },
                    pathRelationsFilter: {
                    },
                    repository: this.state.selectedRepository,
                    topMatchPerPathStepCountingUseTripleCount: true,
                    /*topMatchedEntitiesPerPathStepIndexes: [
                        0
                    ],*/
                    topMatchedRelationsPerPathStepIndexes: [
                        1
                    ],
                    /*uniqueMatchedEntitiesPerPathStepIndexes: [
                        0
                    ],*/
                    /*uniqueMatchedRelationsPerPathStepIndexes: [
                        0
                    ],*/
                    withConceptDomains: false,
                    withConceptPrefnames: true
                }

                //let entities = {}

                let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
                { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

                request['pathEntitiesFilter'] = logicals[0] === 'aad' ? { "1": [{ domain: 'diseases' }], "0": rootOCIDsAll } :
                    logicals[0] === 'aac' ? { "1": [{ domain: 'chem' }, { domain: 'drugs' }, { domain: 'natprod' }, { domain: 'substances' }], "0": rootOCIDsAll } :
                        logicals[0] === 'aap' ? { "1": [{ domain: 'proteins' }], "0": rootOCIDsAll } :
                            logicals[0] === 'aapp' ? { "1": [{ domain: 'effects', namedFilter: 'ocidSubtrees', allowedSubtreesRootOcids: '230000035790' }], "0": rootOCIDsAll } :
                                logicals[0] === 'caa' ? { "0": [{ domain: 'chem' }, { domain: 'drugs' }, { domain: 'natprod' }, { domain: 'substances' }], "1": rootOCIDsAll } :
                                    logicals[0] === 'daa' ? { "0": [{ domain: 'diseases' }], "1": rootOCIDsAll } :
                                        logicals[0] === 'ppaa' ? { "0": [{ domain: 'effects', namedFilter: 'ocidSubtrees', allowedSubtreesRootOcids: '230000035790' }], "1": rootOCIDsAll } :
                                            logicals[0] === 'paa' ? { "0": [{ domain: 'proteins' }], "1": rootOCIDsAll } :
                                                request['pathEntitiesFilter'] = { "0": rootOCIDsAll, "1": rootOCIDsAll }

                //request['pathRelationsFilter'] = { "1": [{ domain: 'chem', negated: true }] }
                request['pathRelationsFilter'] = {
                    "1": this.state.relationsChecked === "narrow" ? [
                        { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                        , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                        { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                        { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
                }
                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, request, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {


                            /*let matchesRight = []
                            let matchesLeft = []

                                Object.keys(response.data.ocidToPreferredName).forEach(entry => {

                                    let singleMatch = response.data.ocidToPreferredName[entry].match(/\[(.*?)\]/g)
                                    if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'physiology' ||
                                        singleMatch[1].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'effect') {
                                        matchesRight.push("effects or process")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'animal disease') {
                                        matchesRight.push("disease")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alloy') {
                                        matchesRight.push("substance")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'chem') {
                                        matchesRight.push("chemistry")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'bacterium'
                                        || singleMatch[1].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[1].match(/\[(.*?)\]/)[1] === 'virus') {
                                        matchesRight.push("species")
                                    } else if (singleMatch[1].match(/\[(.*?)\]/)[1] === 'compound') {
                                        matchesRight.push("chemistry")
                                        matchesRight.push("drug")
                                        matchesRight.push("natural products")
                                    } else {
                                        matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                    }
                                    // matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                })
                                //let uniqueRight = [...new Set(matchesRight)]

                                Object.keys(response.data.ocidToPreferredName).forEach(entry => {
                                    let singleMatch = response.data.ocidToPreferredName[entry].match(/\[(.*?)\]/g)
                                    if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'physiological effect' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'physiology' ||
                                        singleMatch[0].match(/\[(.*?)\]/)[1] === 'health' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'effect') {
                                        matchesLeft.push("effects or process")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'animal disease') {
                                        matchesLeft.push("disease")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'vitamin' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'alloy') {
                                        matchesLeft.push("substance")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'chem') {
                                        matchesLeft.push("chemistry")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'fungus' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'plant' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'plants' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'bacterium'
                                        || singleMatch[0].match(/\[(.*?)\]/)[1] === 'alga' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'edible plant' || singleMatch[0].match(/\[(.*?)\]/)[1] === 'virus') {
                                        matchesLeft.push("species")
                                    } else if (singleMatch[0].match(/\[(.*?)\]/)[1] === 'compound') {
                                        matchesLeft.push("chemistry")
                                        matchesLeft.push("drug")
                                        matchesLeft.push("natural products")
                                    } else {
                                        matchesLeft.push(singleMatch[0].match(/\[(.*?)\]/)[1])
                                    }
                                    // matchesRight.push(singleMatch[1].match(/\[(.*?)\]/)[1])
                                })

                                let uniqueLeft = [...new Set(matchesLeft)]
                                let uniqueRight = [...new Set(matchesRight)]

                                let newUniqueRight = self.state.rightDomains
                                let newUniqueRightShow = self.state.rightDomainsShow
                                let newUniqueLeft = self.state.leftDomains
                                let newUniqueLeftShow = self.state.leftDomainsShow

                                newUniqueRight[0] = uniqueRight
                                newUniqueRightShow[0] = uniqueRight
                                newUniqueLeft[0] = uniqueLeft
                                newUniqueLeftShow[0] = uniqueLeft*/


                            let relationsMulti = []
                            relationsMulti[0] = response.data.ocidToPreferredName
                            let newTripleHits = self.state.tripleHits.slice()
                            newTripleHits[0] = response.data.matchedPathsCount
                            self.setState({
                                fetchingResults: false,
                                pathNumber: pathNumber,
                                type: type,
                                pathRepositories: pathRepositories,
                                logicals: logicals,
                                relation: relation,
                                startTerm: startTerm,
                                startTermShow: startTermShow,
                                startTermOCID: startTermOCID,
                                startChecked: startChecked,
                                relationMode: relationMode,
                                endTerm: endTerm,
                                endTermShow: endTermShow,
                                endTermOCID: endTermOCID,
                                endChecked: endChecked,
                                relationOCIDMulti: relationsMulti,
                                rerenderTable: false,
                                tripleHits: newTripleHits,
                                /*leftDomains: newUniqueLeft,
                                leftDomainsShow: newUniqueLeftShow,
                                rightDomains: newUniqueRight,
                                rightDomainsShow: newUniqueRightShow*/
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            } else {
                this.setState({
                    pathNumber: pathNumber,
                    type: type,
                    pathRepositories: pathRepositories,
                    logicals: logicals,
                    relation: relation,
                    startTerm: startTerm,
                    startTermShow: startTermShow,
                    startTermOCID: startTermOCID,
                    startChecked: startChecked,
                    relationMode: relationMode,
                    endTerm: endTerm,
                    endTermShow: endTermShow,
                    endTermOCID: endTermOCID,
                    endChecked: endChecked,
                    relationOCID: relationOCID,
                    rerenderTable: false
                }, async () => { await this.myCallbackFromExecuteSinglePath(pathNumber, true) })
            }
        }
    }

    myCallbackFromTripleExecute = (matchedPaths, count, prefNames, number, request) => {
        this.setState({
            matchedTriplePaths: matchedPaths,
            matchedTriplePrefNames: prefNames,
            matchedTriplePathCount: count,
            tripleRequest: request,
            disabledTriple: false,
            activeIndex: 2,
            showResults: true,
            tripleNumber: number,
            rerenderTable: true,
            filterTriple: [],
            filterTripleItems: []
        }, () => {
            //scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 })
            window.scrollTo(0, this.table?.current?.offsetTop - 200)
        })
    }

    myCallbackFromFilterTriple = (matchedPaths, count, prefNames, number, request) => {
        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }
        this.setState({
            matchedTriplePaths: matchedPaths,
            matchedTriplePrefNames: prefNames,
            matchedTriplePathCount: count,
            tripleRequest: request,
            disabledTriple: false,
            activeIndex: 2,
            showResults: true,
            tripleNumber: number,
            rerenderTable: true,
            checkedEntities: [],
            displayCustomToast: false
        }, () => { //scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 }) }
            window.scrollTo(0, this.table?.current?.offsetTop - 200)
        })
    }

    myCallbackFromSubPathExecute = (matchedPaths, count, prefNames, number, request) => {
        this.setState({
            matchedSubPaths: matchedPaths,
            matchedSubPrefNames: prefNames,
            subPathRequest: request,
            matchedSubPathCount: count,
            disabledSubPath: false,
            activeIndex: 3,
            showResults: true,
            subPathNumber: number,
            rerenderTable: true
        }, //() => scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 })
            window.scrollTo(0, this.table?.current?.offsetTop - 200))
    }

    myCallbackFromFilterSub = (matchedPaths, count, prefNames, number, request) => {
        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }
        this.setState({
            matchedSubPaths: matchedPaths,
            matchedSubPrefNames: prefNames,
            subPathRequest: request,
            matchedSubPathCount: count,
            disabledSubPath: false,
            activeIndex: 3,
            showResults: true,
            subPathNumber: number,
            rerenderTable: true,
            checkedEntities: [],
            displayCustomToast: false
        }, () => //scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 })
            window.scrollTo(0, this.table?.current?.offsetTop - 200))

    }

    myCallbackFromFilterFull = (matchedPaths, count, prefNames, number, request) => {
        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }
        this.setState({
            showResults: true,
            matchedPaths: matchedPaths,
            prefNames: prefNames,
            matchedPathsCount: count,
            displayDialogExecute: true,
            fullPathRequest: request,
            fetchingResults: false,
            fullPathNumber: number,
            disabledFull: false,
            rerenderTable: true,
            checkedEntities: [],
            displayCustomToast: false
        }, () => {// scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 }) 
            window.scrollTo(0, this.table?.current?.offsetTop - 200)
        })

    }

    myCallbackFromFilterZeroHops = (matchedPaths, count, prefNames, number, request) => {

        let newZeroHopsPaths = this.state.pathsHops.slice()
        newZeroHopsPaths[0] = matchedPaths

        let newZeroHopsPrefNames = this.state.preferredNamesHops.slice()
        newZeroHopsPrefNames[0] = prefNames

        let newZeroHopsCount = this.state.countsHops.slice()
        newZeroHopsCount[0] = count

        let newZeroHopsRequest = this.state.hopRequests.slice()
        newZeroHopsRequest[0] = request

        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }

        this.setState({
            showResults: true,
            pathsHops: newZeroHopsPaths,
            preferredNamesHops: newZeroHopsPrefNames,
            countsHops: newZeroHopsCount,
            displayDialogExecute: true,
            hopRequests: newZeroHopsRequest,
            fetchingResults: false,
            //fullPathNumber: number,
            disabledHops: false,
            rerenderTable: true,
            checkedEntities: [],
            displayCustomToast: false
        }, () => { //scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 }) 
            window.scrollTo(0, this.table?.current?.offsetTop - 200)
        })

    }

    myCallbackFromFilterOneHop = (matchedPaths, count, prefNames, number, request) => {

        let newOneHopPaths = this.state.pathsHops.slice()
        newOneHopPaths[1] = matchedPaths

        let newOneHopPrefNames = this.state.preferredNamesHops.slice()
        newOneHopPrefNames[1] = prefNames

        let newOneHopCount = this.state.countsHops.slice()
        newOneHopCount[1] = count

        let newOneHopRequest = this.state.hopRequests.slice()
        newOneHopRequest[1] = request

        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }

        this.setState({
            showResults: true,
            pathsHops: newOneHopPaths,
            preferredNamesHops: newOneHopPrefNames,
            countsHops: newOneHopCount,
            displayDialogExecute: true,
            hopRequests: newOneHopRequest,
            fetchingResults: false,
            //fullPathNumber: number,
            disabledHops: false,
            rerenderTable: true,
            checkedEntities: [],
            displayCustomToast: false
        }, () => { //scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 }) 
            window.scrollTo(0, this.table?.current?.offsetTop - 200)
        })

    }

    myCallbackFromFilterTwoHops = (matchedPaths, count, prefNames, number, request) => {
        let newTwoHopsPaths = this.state.pathsHops.slice()
        newTwoHopsPaths[2] = matchedPaths

        let newTwoHopsPrefNames = this.state.preferredNamesHops.slice()
        newTwoHopsPrefNames[2] = prefNames

        let newTwoHopsCount = this.state.countsHops.slice()
        newTwoHopsCount[2] = count

        let newTwoHopsRequest = this.state.hopRequests.slice()
        newTwoHopsRequest[2] = request

        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }

        this.setState({
            showResults: true,
            pathsHops: newTwoHopsPaths,
            preferredNamesHops: newTwoHopsPrefNames,
            countsHops: newTwoHopsCount,
            displayDialogExecute: true,
            hopRequests: newTwoHopsRequest,
            fetchingResults: false,
            //fullPathNumber: number,
            disabledHops: false,
            rerenderTable: true,
            checkedEntities: [],
            displayCustomToast: false
        }, () => { //scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 }) 
            window.scrollTo(0, this.table?.current?.offsetTop - 200)
        })

    }

    myCallbackFromFilterThreeHops = (matchedPaths, count, prefNames, number, request) => {
        let newThreeHopsPaths = this.state.pathsHops.slice()
        newThreeHopsPaths[3] = matchedPaths

        let newThreeHopsPrefNames = this.state.preferredNamesHops.slice()
        newThreeHopsPrefNames[3] = prefNames

        let newThreeHopsCount = this.state.countsHops.slice()
        newThreeHopsCount[3] = count

        let newThreeHopsRequest = this.state.hopRequests.slice()
        newThreeHopsRequest[3] = request

        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }

        this.setState({
            showResults: true,
            pathsHops: newThreeHopsPaths,
            preferredNamesHops: newThreeHopsPrefNames,
            countsHops: newThreeHopsCount,
            displayDialogExecute: true,
            hopRequests: newThreeHopsRequest,
            fetchingResults: false,
            //fullPathNumber: number,
            disabledHops: false,
            rerenderTable: true,
            checkedEntities: [],
            displayCustomToast: false
        }, () => { //scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 }) 
            window.scrollTo(0, this.table?.current?.offsetTop - 200)
        })

    }

    myCallbackFromFilterFourHops = (matchedPaths, count, prefNames, number, request) => {
        let newFourHopsPaths = this.state.pathsHops.slice()
        newFourHopsPaths[4] = matchedPaths

        let newFourHopsPrefNames = this.state.preferredNamesHops.slice()
        newFourHopsPrefNames[4] = prefNames

        let newFourHopsCount = this.state.countsHops.slice()
        newFourHopsCount[4] = count

        let newFourHopsRequest = this.state.hopRequests.slice()
        newFourHopsRequest[4] = request

        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }

        this.setState({
            showResults: true,
            pathsHops: newFourHopsPaths,
            preferredNamesHops: newFourHopsPrefNames,
            countsHops: newFourHopsCount,
            displayDialogExecute: true,
            hopRequests: newFourHopsRequest,
            fetchingResults: false,
            //fullPathNumber: number,
            disabledHops: false,
            rerenderTable: true,
            checkedEntities: [],
            displayCustomToast: false
        }, () => { //scrollToComponent(this.table, { offset: -225, align: 'top', duration: 100 })
            window.scrollTo(0, this.table?.current?.offsetTop - 200)
        })

    }

    myCallbackFromExport = async (triple, subPath, fromPath, fromHops, hits) => {
        hits > 0 ?
            this.setState({
                selectedFormatPaths: this.formats[0],
                selectedFormatEntities: this.formats[0],
                selectedFormatSentences: this.formats[0],
                rowsPaths: "all",
                rowsEntities: "all",
                rowsSentences: "all",
                activeIndexExport: 0,
                selectedEntityOption: 'unique',
                selectedColumnOption: 1,
                selectedNumberSentences: '1',
                selectedTriple: 1,
                exportFromTriple: triple,
                exportFromSubPath: subPath,
                exportFromPath: fromPath,
                exportFromHops: fromHops,
                displayExportDialog: true
            })
            :
            this.showExportWarnGrowl()
    }

    handleExport = async () => {

        this.setState({
            fetchingResults: true
        })

        let exportRequest
        if (this.state.exportFromHops && this.state.hopsToShow === 1 && this.state.filterZeroHops.length === 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.hopRequests[0]))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 1 && this.state.filterZeroHops.length > 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.newZeroHopsRequest))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 2 && this.state.filterOneHop.length === 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.hopRequests[1]))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 2 && this.state.filterOneHop.length > 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.newOneHopRequest))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 3 && this.state.filterTwoHops.length === 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.hopRequests[2]))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 3 && this.state.filterTwoHops.length > 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.newTwoHopsRequest))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 4 && this.state.filterThreeHops.length === 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.hopRequests[3]))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 4 && this.state.filterThreeHops.length > 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.newThreeHopsRequest))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 5 && this.state.filterFourHops.length === 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.hopRequests[4]))
        } else if (this.state.exportFromHops && this.state.hopsToShow === 5 && this.state.filterFourHops.length > 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.newFourHopsRequest))
        } else if (this.state.exportFromPath && this.state.filterFull.length === 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.fullPathRequest))
        } else if (this.state.exportFromPath && this.state.filterFull.length > 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.newFullRequest))
        } else if (this.state.exportFromTriple && this.state.filterTriple.length === 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.tripleRequest))
        } else if (this.state.exportFromTriple && this.state.filterTriple.length > 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.newTripleRequest))
        } else if (this.state.exportFromSubPath && this.state.filterSub.length === 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.subPathRequest))
        } else if (this.state.exportFromSubPath && this.state.filterSub.length > 0) {
            exportRequest = JSON.parse(JSON.stringify(this.state.newSubRequest))
        }
        exportRequest.matchedPathsMaxCount = 1000
        exportRequest.matchedPathsOffset = 0

        if (this.state.activeIndexExport === 2) {
            exportRequest.matchedPathsMaxTripleSentences = this.state.selectedNumberSentences
        }

        let selectedRows = []

        if ((this.state.activeIndexExport === 0 && this.state.rowsPaths === 'selected') ||
            (this.state.activeIndexExport === 1 && this.state.rowsEntities === 'selected') ||
            (this.state.activeIndexExport === 2 && this.state.rowsSentences === 'selected')) {
            if (this.state.exportFromPath && this.state.selectedRowsPath && this.state.selectedRowsPath.length > 0) {
                this.state.selectedRowsPath.forEach(row => {
                    selectedRows = [...selectedRows, row.counter - 1]
                })
            } else if (this.state.exportFromSubPath && this.state.selectedRowsSubPath && this.state.selectedRowsSubPath.length > 0) {
                this.state.selectedRowsSubPath.forEach(row => {
                    selectedRows = [...selectedRows, row.counter - 1]
                })
            } else if (this.state.exportFromTriple && this.state.selectedRowsTriple && this.state.selectedRowsTriple.length > 0) {
                this.state.selectedRowsTriple.forEach(row => {
                    selectedRows = [...selectedRows, row.counter - 1]
                })
            } else if (this.state.exportFromHops && this.state.hopsToShow === 1 && this.state.selectedRowsZeroHops && this.state.selectedRowsZeroHops.length > 0) {
                this.state.selectedRowsZeroHops.forEach(row => {
                    selectedRows = [...selectedRows, row.counter - 1]
                })
            } else if (this.state.exportFromHops && this.state.hopsToShow === 2 && this.state.selectedRowsOneHop && this.state.selectedRowsOneHop.length > 0) {
                this.state.selectedRowsOneHop.forEach(row => {
                    selectedRows = [...selectedRows, row.counter - 1]
                })
            } else if (this.state.exportFromHops && this.state.hopsToShow === 3 && this.state.selectedRowsTwoHops && this.state.selectedRowsTwoHops.length > 0) {
                this.state.selectedRowsTwoHops.forEach(row => {
                    selectedRows = [...selectedRows, row.counter - 1]
                })
            } else if (this.state.exportFromHops && this.state.hopsToShow === 4 && this.state.selectedRowsThreeHops && this.state.selectedRowsThreeHops.length > 0) {
                this.state.selectedRowsThreeHops.forEach(row => {
                    selectedRows = [...selectedRows, row.counter - 1]
                })
            } else if (this.state.exportFromHops && this.state.hopsToShow === 5 && this.state.selectedRowsFourHops && this.state.selectedRowsFourHops.length > 0) {
                this.state.selectedRowsFourHops.forEach(row => {
                    selectedRows = [...selectedRows, row.counter - 1]
                })
            }
        }
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/xlsx/triple-relation-paths`,
            {
                indexesOfPathsToExport: selectedRows.length > 0 ? selectedRows : null,
                entityColumnIndex: this.state.activeIndexExport === 0 || this.state.activeIndexExport === 2 ? null : self.state.selectedColumnOption,
                tripleRelationColumnIndex: this.state.activeIndexExport === 0 || this.state.activeIndexExport === 1 ? null : self.state.selectedTriple,
                tripleRelationPathsRequest: exportRequest,
                hitLimit: this.state.activeIndexExport === 0 && this.state.rowsPaths !== 'all' && this.state.rowsPaths !== 'selected' ? this.state.rowsPaths :
                    this.state.activeIndexExport === 1 && this.state.rowsEntities !== 'all' && this.state.rowsEntities !== 'selected' ? this.state.rowsEntities :
                        this.state.activeIndexExport === 2 && this.state.rowsSentences !== 'all' && this.state.rowsSentences !== 'selected' ? this.state.rowsSentences : null
            }, { headers: getHeaderToken(), responseType: 'blob' })
            .then((response) => {
                saveAs(new Blob([response.data]),
                    this.state.activeIndexExport === 0 && this.state.exportFromTriple ?
                        `pathsOfTriple_${self.state.tripleNumber}.${self.state.selectedFormatPaths.key}` :
                        this.state.activeIndexExport === 0 && this.state.exportFromSubPath ?
                            `pathsOfSubPathUpToTriple_${self.state.subPathNumber + 1}.${self.state.selectedFormatPaths.key}` :
                            this.state.activeIndexExport === 0 && this.state.exportFromPath ?
                                `fullPaths_${self.state.fullPathNumber + 1}_entities.${self.state.selectedFormatPaths.key}` :
                                this.state.activeIndexExport === 0 && this.state.exportFromHops ?
                                    `shortestPaths_${self.state.hopsToShow}_hops.${self.state.selectedFormatPaths.key}` :

                                    this.state.activeIndexExport === 1 && this.state.exportFromTriple ?
                                        `entities_column_${self.state.selectedColumnOption}_pathsOfTriple_${self.state.tripleNumber}.${self.state.selectedFormatPaths.key}` :
                                        this.state.activeIndexExport === 1 && this.state.exportFromSubPath ?
                                            `entities_column_${self.state.selectedColumnOption}_pathsOfSubPathUpToTriple_${self.state.subPathNumber + 1}.${self.state.selectedFormatPaths.key}` :
                                            this.state.activeIndexExport === 1 && this.state.exportFromPath ?
                                                `entities_column_${self.state.selectedColumnOption}_fullPaths_${self.state.fullPathNumber + 1}_entities.${self.state.selectedFormatPaths.key}` :
                                                this.state.activeIndexExport === 1 && this.state.exportFromHops ?
                                                    `entities_column_${self.state.selectedColumnOption}_shortestPaths_${self.state.hopsToShow}_hops.${self.state.selectedFormatPaths.key}` :

                                                    this.state.activeIndexExport === 2 && this.state.exportFromTriple ?
                                                        `evidence_triple_${self.state.selectedTriple}_pathsOfTriple_${self.state.tripleNumber}.${self.state.selectedFormatPaths.key}` :
                                                        this.state.activeIndexExport === 2 && this.state.exportFromSubPath ?
                                                            `evidence_triple_${self.state.selectedTriple}_pathsOfSubPathUpToTriple_${self.state.subPathNumber + 1}.${self.state.selectedFormatPaths.key}` :
                                                            this.state.activeIndexExport === 2 && this.state.exportFromPath ?
                                                                `evidence_triple_${self.state.selectedTriple}_fullPaths_${self.state.fullPathNumber + 1}_entities.${self.state.selectedFormatPaths.key}` :
                                                                this.state.activeIndexExport === 2 && this.state.exportFromHops ?
                                                                    `evidence_triple_${self.state.selectedTriple}_shortestPaths_${self.state.hopsToShow}_hops.${self.state.selectedFormatPaths.key}` :

                                                                    `test.${self.state.selectedFormatPaths.key}`)

                self.setState({
                    rerenderTable: false,
                    fetchingResults: false
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showExportFailGrowl()
                    self.setState({
                        rerenderTable: false,
                        fetchingResults: false
                    })
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.setState({
                        rerenderTable: false,
                        fetchingResults: false
                    })
                    console.log("not found")
                }
            })
    }

    showExportFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: "Export failed.", life: 6000 };
        this.growl.current.show(msg);
    }

    myCallbackFromPathSelect = (value) => {
        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }
        this.setState({
            rerenderTable: value,
            checkedEntities: [],
            displayCustomToast: false
        })
    }

    myCallbackFromBlacklistChange = (selectedBlacklists) => {
        this.setState({
            selectedBlacklists: selectedBlacklists.value
        })
    }

    myCallbackFromBlacklistConcepts = (mode, selectedBlackList, selectedEntities, fromTriple, fromSubPath, fromPath, fromHop, fromTable, checkedEntities) => {
        if (selectedBlackList.length > 0 && (selectedEntities.length > 0 || checkedEntities.length > 0)) {
            let blacklistSelection = selectedBlackList
            if (!fromTable) {
                if (fromTriple) {
                    let conceptArray = []
                    for (let i = 0; i < selectedEntities.length; i++) {
                        // eslint-disable-next-line
                        Object.keys(this.state.matchedTriplePrefNames).forEach(key => {
                            if (this.state.matchedTriplePrefNames[key] === selectedEntities[i]) {
                                conceptArray = [...conceptArray, {
                                    ocid: key, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                                    scope: 'entity', prefName: selectedEntities[i]
                                }]
                            }
                        })
                    }
                    let self = this
                    blacklistSelection.forEach(list => {
                        if (hasUserRole('ROLE_BLACKLISTS')) {
                            axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${list}`, conceptArray, { headers: getHeaderToken() })
                                .then(function (response) {
                                    //self.toastBC.current.clear()
                                    self.setState({
                                        // checkedEntities: []
                                    }, () =>
                                        self.refreshBlacklists(),
                                        self.showEntryAddSuccessGrowl()
                                    )

                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.setState({
                                            //checkedEntities: []
                                        }, () =>
                                            self.showEntryAddFailGrowl()
                                        )
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        }
                    })

                } else if (fromSubPath) {
                    let conceptArray = []
                    for (let i = 0; i < selectedEntities.length; i++) {
                        // eslint-disable-next-line
                        Object.keys(this.state.matchedSubPrefNames).forEach(key => {
                            if (this.state.matchedSubPrefNames[key] === selectedEntities[i]) {
                                conceptArray = [...conceptArray, {
                                    ocid: key, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                                    scope: 'entity', prefName: selectedEntities[i]
                                }]
                            }
                        })
                    }
                    let self = this
                    blacklistSelection.forEach(list => {
                        if (hasUserRole('ROLE_BLACKLISTS')) {
                            axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${list}`, conceptArray, { headers: getHeaderToken() })
                                .then(function (response) {
                                    //self.toastBC.current.clear()
                                    self.setState({
                                        //checkedEntities: []
                                    }, () =>
                                        self.refreshBlacklists(),
                                        self.showEntryAddSuccessGrowl()
                                    )

                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        //self.toastBC.current.clear()
                                        self.setState({
                                            //checkedEntities: []
                                        }, () =>
                                            self.showEntryAddFailGrowl()
                                        )
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        }
                    })
                } else if (fromPath) {
                    let conceptArray = []
                    for (let i = 0; i < selectedEntities.length; i++) {
                        // eslint-disable-next-line
                        Object.keys(this.state.prefNames).forEach(key => {
                            if (this.state.prefNames[key] === selectedEntities[i]) {
                                conceptArray = [...conceptArray, {
                                    ocid: key, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                                    scope: 'entity', prefName: selectedEntities[i]
                                }]
                            }
                        })
                    }
                    let self = this
                    blacklistSelection.forEach(list => {
                        if (hasUserRole('ROLE_BLACKLISTS')) {
                            axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${list}`, conceptArray, { headers: getHeaderToken() })
                                .then(function (response) {
                                    //self.toastBC.current.clear()
                                    self.setState({
                                        //checkedEntities: []
                                    }, () =>
                                        self.refreshBlacklists(),
                                        self.showEntryAddSuccessGrowl()
                                    )

                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        //self.toastBC.current.clear()
                                        self.setState({
                                            //checkedEntities: []
                                        }, () =>
                                            self.showEntryAddFailGrowl()
                                        )
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        }
                    })
                } else if (fromHop) {
                    let conceptArray = []
                    if (this.state.hopsToShow === 1) {
                        for (let i = 0; i < selectedEntities.length; i++) {
                            // eslint-disable-next-line
                            Object.keys(this.state.preferredNamesHops[0]).forEach(key => {
                                if (this.state.preferredNamesHops[0][key] === selectedEntities[i]) {
                                    conceptArray = [...conceptArray, {
                                        ocid: key, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                                        scope: 'entity', prefName: selectedEntities[i]
                                    }]
                                }
                            })
                        }
                    } else if (this.state.hopsToShow === 2) {
                        for (let i = 0; i < selectedEntities.length; i++) {
                            // eslint-disable-next-line
                            Object.keys(this.state.preferredNamesHops[1]).forEach(key => {
                                if (this.state.preferredNamesHops[1][key] === selectedEntities[i]) {
                                    conceptArray = [...conceptArray, {
                                        ocid: key, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                                        scope: 'entity', prefName: selectedEntities[i]
                                    }]
                                }
                            })
                        }
                    } else if (this.state.hopsToShow === 3) {
                        for (let i = 0; i < selectedEntities.length; i++) {
                            // eslint-disable-next-line
                            Object.keys(this.state.preferredNamesHops[2]).forEach(key => {
                                if (this.state.preferredNamesHops[2][key] === selectedEntities[i]) {
                                    conceptArray = [...conceptArray, {
                                        ocid: key, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                                        scope: 'entity', prefName: selectedEntities[i]
                                    }]
                                }
                            })
                        }
                    } else if (this.state.hopsToShow === 4) {
                        for (let i = 0; i < selectedEntities.length; i++) {
                            // eslint-disable-next-line
                            Object.keys(this.state.preferredNamesHops[3]).forEach(key => {
                                if (this.state.preferredNamesHops[3][key] === selectedEntities[i]) {
                                    conceptArray = [...conceptArray, {
                                        ocid: key, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                                        scope: 'entity', prefName: selectedEntities[i]
                                    }]
                                }
                            })
                        }
                    } else if (this.state.hopsToShow === 5) {
                        for (let i = 0; i < selectedEntities.length; i++) {
                            // eslint-disable-next-line
                            Object.keys(this.state.preferredNamesHops[4]).forEach(key => {
                                if (this.state.preferredNamesHops[4][key] === selectedEntities[i]) {
                                    conceptArray = [...conceptArray, {
                                        ocid: key, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                                        scope: 'entity', prefName: selectedEntities[i]
                                    }]
                                }
                            })
                        }
                    }

                    let self = this
                    blacklistSelection.forEach(list => {
                        if (hasUserRole('ROLE_BLACKLISTS')) {
                            axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${list}`, conceptArray, { headers: getHeaderToken() })
                                .then(function (response) {
                                    //self.toastBC.current.clear()
                                    self.setState({
                                        //checkedEntities: []
                                    }, () =>
                                        self.refreshBlacklists(),
                                        self.showEntryAddSuccessGrowl()
                                    )

                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        //self.toastBC.current.clear()
                                        self.setState({
                                            //checkedEntities: []
                                        }, () =>
                                            self.showEntryAddFailGrowl()
                                        )
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        }
                    })
                }
            } else {
                let conceptArray = []
                for (let i = 0; i < checkedEntities.length; i++) {
                    // eslint-disable-next-line
                    conceptArray = [...conceptArray, {
                        ocid: checkedEntities[i].entityKey, conceptLookupType: mode === 'ontological' ? 'ONTOLOGICAL' : 'EXACT',
                        scope: 'entity', prefName: checkedEntities[i].prefName
                    }]
                }
                let self = this
                blacklistSelection.forEach(list => {
                    if (hasUserRole('ROLE_BLACKLISTS')) {
                        axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${list}`, conceptArray, { headers: getHeaderToken() })
                            .then(function (response) {
                                //self.toastBC.current.clear()
                                self.setState({
                                    //checkedEntities: []
                                }, () =>
                                    self.refreshBlacklists(),
                                    self.showEntryAddSuccessGrowl()
                                )

                            })
                            .catch(function (error) {
                                if (error.response.status === 400) {
                                    //self.toastBC.current.clear()
                                    self.setState({
                                        //checkedEntities: []
                                    }, () =>
                                        self.showEntryAddFailGrowl()
                                    )
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                    }
                })
            }
        } else {
            this.showEmptyFailGrowl()
        }
    }

    myCallbackFromCheckedEntityChange = (entities) => {
        if (entities.length === 0) {
            if (this.toastBC.current) {
                this.toastBC.current.clear()
            };
            this.setState({
                displayCustomToast: false
            })
        } else if (entities.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
        /*if (this.toastBC.current.state.messages.length === 0) {
            this.toastBC.current.show({
                closable: false, severity: '', sticky: true, content: (
                    <div className='col-12' style={{ display: 'block', padding: 0 }}>
                        <div className='col-12' style={{ textAlign: 'center', padding: 0, paddingBottom: 5 }}>
                            <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{entities.length} {entities.length !== 1 ? 'concepts' : 'concept'} selected</label>
                        </div>
                        <div className='col-12 grid' style={{ justifyContent: 'space-between', padding: 0 }}>
                            <div className='col-6' style={{}}>
                                <a style={{}}
                                    title={entities.length && entities.length === 1 ? `Add concept to blocklists` : `Add ${entities.length} selected concepts to blocklists`}
                                    onClick={this.handleAddToBlocklistDialog}>Add to blocklists</a>
                            </div>
                            <div className='col-6' style={{ textAlign: 'end' }}>
                                <a style={{}}
                                    title='Unselect'
                                    onClick={this.myCallbackFromRemoveSelectedEntities}>Unselect</a>
                            </div>
                        </div>
                    </div>
                )
            });
        } else if (this.toastBC.current.state.messages.length !== 0) {
            this.toastBC.current.state.messages = [{
                id: this.state.toastID,
                closable: false, severity: '', sticky: true, content: (
                    <div className='col-12' style={{ display: 'block', padding: 0 }}>
                        <div className='col-12' style={{ textAlign: 'center', padding: 0, paddingBottom: 5 }}>
                            <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{entities.length} {entities.length !== 1 ? 'concepts' : 'concept'} selected</label>
                        </div>
                        <div className='col-12 grid' style={{ justifyContent: 'space-between', padding: 0 }}>
                            <div className='col-6' style={{}}>
                                <a style={{}}
                                    title={entities.length && entities.length === 1 ? `Add concept to blocklists` : `Add ${entities.length} selected concepts to blocklists`}
                                    onClick={this.handleAddToBlocklistDialog}>Add to blocklists</a>
                            </div>
                            <div className='col-6' style={{ textAlign: 'end' }}>
                                <a style={{}}
                                    title='Unselect'
                                    onClick={this.myCallbackFromRemoveSelectedEntities}>Unselect</a>
                            </div>
                        </div>
                    </div>
                )
            }]
        }*/

        this.setState({
            checkedEntities: entities
        }/*, () => {
            if (this.toastBC.current.state.messages[0]) {
                this.setState({
                    toastID: this.toastBC.current.state.messages[0].id
                })
            }
        }*/
        )
    }

    handleAddToBlocklistDialog = () => {
        this.setState({
            displayDialogAddToBlacklist: true
        })
    }

    closeAddToBlocklistDialog = () => {
        this.setState({
            displayDialogAddToBlacklist: false
        })
    }

    myCallbackFromRemoveSelectedEntities = () => {
        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }
        this.setState({
            displayDialogAddToBlacklist: false,
            checkedEntities: [],
            displayCustomToast: false
        })
    }

    refreshBlacklists = () => {
        let self = this;
        if (hasUserRole('ROLE_BLACKLISTS')) {
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        blacklists: response.data
                    }, () => {
                        let pathBlacklists = []
                        response.data.forEach(blacklist => {
                            pathBlacklists = [...pathBlacklists, { label: blacklist.title, value: blacklist.id }]
                        })
                        self.setState({
                            pathBlacklists: pathBlacklists
                        })
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    showExportWarnGrowl = () => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: 'No paths matched your search.' };
        this.growl.current.show(msg);
    }

    showEntryAddSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Entity successfully added to blocklist.' };
        this.growl.current.show(msg);
    }

    showEntryAddFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Entity could not be added to blocklist.', life: 6000 };
        this.growl.current.show(msg);
    }

    showEmptyFailGrowl = (e) => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: 'Select at least one entity and one blacklist.', life: 6000 };
        this.growl.current.show(msg);
    }


    myCallbackFromAddBlacklist = (blacklist) => {
        let actualBlacklists = this.state.blacklists
        actualBlacklists.push(blacklist)
        this.setState({
            blacklists: blacklist
        })
    }

    myCallbackFromRepositoryChange = (value) => {
        this.setState({
            selectedRepository: value
        })
    }

    myCallbackFromFilter = async (filter, fromTriple, fromSub, fromPath, fromHops, fromPageChange, first, rows) => {

        this.setState({
            fetchingResults: true
        })

        if (fromTriple) {
            if (!fromPageChange) {
                let filterTriple = filter

                let newFilterTriples = this.state.filterTriple
                filterTriple.forEach(triple => {
                    newFilterTriples = [...newFilterTriples, triple]
                })

                newFilterTriples.forEach(entry => {
                    Object.keys(this.state.matchedTriplePrefNames).forEach(key => {
                        if (!entry.key.includes('relation')) {
                            if (entry.value === this.state.matchedTriplePrefNames[key]) {
                                if (entry["ocid"] === undefined) {
                                    entry["ocid"] = [{ ocid: key }]
                                } else {
                                    entry["ocid"].push({ ocid: key })
                                }
                            }
                        } else {
                            if (this.state.matchedTriplePrefNames[key].includes(entry.value)) {
                                if (entry["ocid"] === undefined) {
                                    entry["ocid"] = [{ ocid: key }]
                                } else {
                                    entry["ocid"].push({ ocid: key })
                                }
                            }
                        }
                    })
                })

                let oldRequest = JSON.parse(JSON.stringify(this.state.tripleRequest))
                let newRequest = oldRequest
                newFilterTriples && newFilterTriples.forEach(entry => {
                    if (entry.key === 'start') {
                        newRequest.pathEntitiesFilter[0] = entry.ocid
                    } else if (entry.key === 'end') {
                        newRequest.pathEntitiesFilter[1] = entry.ocid
                    } else if (entry.key === 'relation') {
                        newRequest.pathRelationsFilter[1] = entry.ocid
                    }
                })
                newRequest.matchedPathsOffset = 0
                newRequest.matchedPathsMaxCount = 20

                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchedPathsTriple = response.data.matchedPaths
                            let matchedPathsCount = response.data.matchedPathsCount
                            let prefNames = response.data.ocidToPreferredName
                            let number = self.state.tripleNumber
                            self.setState({
                                newTripleRequest: newRequest,
                                countTriplePath: 20,
                                startTriplePath: 0,
                                fetchingResults: false
                            }, () => {
                                self.myCallbackFromFilterTriple(matchedPathsTriple, matchedPathsCount, prefNames,
                                    number, self.state.tripleRequest)
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
                this.setState({
                    filterTriple: newFilterTriples
                })
            } else {
                let newFilterTripleRequest = JSON.parse(JSON.stringify(this.state.newTripleRequest))
                newFilterTripleRequest.matchedPathsOffset = first
                newFilterTripleRequest.matchedPathsMaxCount = rows
                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newFilterTripleRequest, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchedPathsTriple = response.data.matchedPaths
                            let matchedPathsCount = response.data.matchedPathsCount
                            let prefNames = response.data.ocidToPreferredName
                            let number = self.state.tripleNumber
                            self.setState({
                                countTriplePath: rows,
                                startTriplePath: first
                            }, () => {
                                self.myCallbackFromFilterTriple(matchedPathsTriple, matchedPathsCount, prefNames,
                                    number, self.state.tripleRequest)
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            }
        } else if (fromSub) {
            if (!fromPageChange) {
                let filterSub = filter

                let newFilterSub = this.state.filterSub
                filterSub.forEach(triple => {
                    newFilterSub = [...newFilterSub, triple]
                })

                newFilterSub.forEach(entry => {
                    Object.keys(this.state.matchedSubPrefNames).forEach(key => {
                        if (!entry.key.includes('relation')) {
                            if (entry.value === this.state.matchedSubPrefNames[key]) {
                                if (entry["ocid"] === undefined) {
                                    entry["ocid"] = [{ ocid: key }]
                                } else {
                                    entry["ocid"].push({ ocid: key })
                                }
                            }
                        } else {
                            if (this.state.matchedSubPrefNames[key].includes(entry.value)) {
                                if (entry["ocid"] === undefined) {
                                    entry["ocid"] = [{ ocid: key }]
                                } else {
                                    entry["ocid"].push({ ocid: key })
                                }
                            }
                        }
                    })
                })
                let oldRequest = JSON.parse(JSON.stringify(this.state.subPathRequest))
                let newRequest = oldRequest
                newFilterSub && newFilterSub.forEach(entry => {
                    if (entry.key === 'start') {
                        newRequest.pathEntitiesFilter[0] = entry.ocid
                    } else if (entry.key === 'end') {
                        newRequest.pathEntitiesFilter[1] = entry.ocid
                    } else if (entry.key === 'relation') {
                        newRequest.pathRelationsFilter[1] = entry.ocid
                    } else if (entry.key === 'end2') {
                        newRequest.pathEntitiesFilter[2] = entry.ocid
                    } else if (entry.key === 'end3') {
                        newRequest.pathEntitiesFilter[3] = entry.ocid
                    } else if (entry.key === 'end4') {
                        newRequest.pathEntitiesFilter[4] = entry.ocid
                    } else if (entry.key === 'relation2') {
                        newRequest.pathRelationsFilter[2] = entry.ocid
                    } else if (entry.key === 'relation3') {
                        newRequest.pathRelationsFilter[3] = entry.ocid
                    } else if (entry.key === 'relation4') {
                        newRequest.pathRelationsFilter[4] = entry.ocid
                    }
                })
                newRequest.matchedPathsOffset = 0
                newRequest.matchedPathsMaxCount = 20

                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchedSubPaths = response.data.matchedPaths
                            let matchedSubPathCount = response.data.matchedPathsCount
                            let matchedSubPrefNames = response.data.ocidToPreferredName
                            let number = self.state.subPathNumber
                            self.setState({
                                newSubRequest: newRequest,
                                countSubPath: 20,
                                startSubPath: 0
                            }, () => {
                                self.myCallbackFromFilterSub(matchedSubPaths, matchedSubPathCount, matchedSubPrefNames,
                                    number, self.state.subPathRequest)
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
                this.setState({
                    filterSub: newFilterSub
                })
            } else {
                let newFilterSubRequest = JSON.parse(JSON.stringify(this.state.newSubRequest))
                newFilterSubRequest.matchedPathsOffset = first
                newFilterSubRequest.matchedPathsMaxCount = rows
                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newFilterSubRequest, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchedSubPaths = response.data.matchedPaths
                            let matchedSubPathCount = response.data.matchedPathsCount
                            let matchedSubPrefNames = response.data.ocidToPreferredName
                            let number = self.state.subPathNumber
                            self.setState({
                                countSubPath: rows,
                                startSubPath: first
                            }, () => {
                                self.myCallbackFromFilterSub(matchedSubPaths, matchedSubPathCount, matchedSubPrefNames,
                                    number, self.state.subPathRequest)
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            }
        } else if (fromPath) {
            if (!fromPageChange) {
                let filterFull = filter

                let newFilterFull = this.state.filterFull
                filterFull.forEach(triple => {
                    newFilterFull = [...newFilterFull, triple]
                })

                newFilterFull.forEach(entry => {
                    Object.keys(this.state.prefNames).forEach(key => {
                        if (!entry.key.includes('relation')) {
                            if (entry.value === this.state.prefNames[key]) {
                                if (entry["ocid"] === undefined) {
                                    entry["ocid"] = [{ ocid: key }]
                                } else {
                                    entry["ocid"].push({ ocid: key })
                                }
                            }
                        } else {
                            if (this.state.prefNames[key].includes(entry.value)) {
                                if (entry["ocid"] === undefined) {
                                    entry["ocid"] = [{ ocid: key }]
                                } else {
                                    entry["ocid"].push({ ocid: key })
                                }
                            }
                        }
                    })
                })
                let oldRequest = JSON.parse(JSON.stringify(this.state.fullPathRequest))
                let newRequest = oldRequest
                newRequest.matchedPathsOffset = 0
                newRequest.matchedPathsMaxCount = 20
                newFilterFull && newFilterFull.forEach(entry => {
                    if (entry.key === 'start') {
                        newRequest.pathEntitiesFilter[0] = entry.ocid
                    } else if (entry.key === 'end') {
                        newRequest.pathEntitiesFilter[1] = entry.ocid
                    } else if (entry.key === 'relation') {
                        newRequest.pathRelationsFilter[1] = entry.ocid
                    } else if (entry.key === 'end2') {
                        newRequest.pathEntitiesFilter[2] = entry.ocid
                    } else if (entry.key === 'end3') {
                        newRequest.pathEntitiesFilter[3] = entry.ocid
                    } else if (entry.key === 'end4') {
                        newRequest.pathEntitiesFilter[4] = entry.ocid
                    } else if (entry.key === 'end5') {
                        newRequest.pathEntitiesFilter[5] = entry.ocid
                    } else if (entry.key === 'relation2') {
                        newRequest.pathRelationsFilter[2] = entry.ocid
                    } else if (entry.key === 'relation3') {
                        newRequest.pathRelationsFilter[3] = entry.ocid
                    } else if (entry.key === 'relation4') {
                        newRequest.pathRelationsFilter[4] = entry.ocid
                    } else if (entry.key === 'relation5') {
                        newRequest.pathRelationsFilter[5] = entry.ocid
                    }
                })
                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchedFullPaths = response.data.matchedPaths
                            let matchedFullPathCount = response.data.matchedPathsCount
                            let matchedFullPrefNames = response.data.ocidToPreferredName
                            let number = self.state.fullPathNumber
                            self.setState({
                                newFullRequest: newRequest,
                                countFullPath: 20,
                                startFullPath: 0
                            }, () => {
                                self.myCallbackFromFilterFull(matchedFullPaths, matchedFullPathCount, matchedFullPrefNames,
                                    number, self.state.fullPathRequest)
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })

                this.setState({
                    filterFull: newFilterFull
                })
            } else {
                let newFilterFullRequest = JSON.parse(JSON.stringify(this.state.newFullRequest))
                newFilterFullRequest.matchedPathsOffset = first
                newFilterFullRequest.matchedPathsMaxCount = rows
                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newFilterFullRequest, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchedFullPaths = response.data.matchedPaths
                            let matchedFullPathCount = response.data.matchedPathsCount
                            let matchedFullPrefNames = response.data.ocidToPreferredName
                            let number = self.state.fullPathNumber
                            self.setState({
                                countFullPath: rows,
                                startFullPath: first
                            }, () => {
                                self.myCallbackFromFilterFull(matchedFullPaths, matchedFullPathCount, matchedFullPrefNames,
                                    number, self.state.fullPathRequest)
                            })
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            }
        } else if (fromHops) {
            if (!fromPageChange) {
                let filterHops = filter

                let newFilterHops

                if (this.state.hopsToShow === 1) {
                    newFilterHops = this.state.filterZeroHops
                } else if (this.state.hopsToShow === 2) {
                    newFilterHops = this.state.filterOneHop
                } else if (this.state.hopsToShow === 3) {
                    newFilterHops = this.state.filterTwoHops
                } else if (this.state.hopsToShow === 4) {
                    newFilterHops = this.state.filterThreeHops
                } else if (this.state.hopsToShow === 5) {
                    newFilterHops = this.state.filterFourHops
                }


                filterHops.forEach(triple => {
                    newFilterHops = [...newFilterHops, triple]
                })

                if (this.state.hopsToShow === 1) {
                    newFilterHops.forEach(entry => {
                        Object.keys(this.state.preferredNamesHops[0]).forEach(key => {
                            if (!entry.key.includes('relation')) {
                                if (entry.value === this.state.preferredNamesHops[0][key]) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            } else {
                                if (this.state.preferredNamesHops[0][key].includes(entry.value)) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            }
                        })
                    })
                } else if (this.state.hopsToShow === 2) {
                    newFilterHops.forEach(entry => {
                        Object.keys(this.state.preferredNamesHops[1]).forEach(key => {
                            if (!entry.key.includes('relation')) {
                                if (entry.value === this.state.preferredNamesHops[1][key]) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            } else {
                                if (this.state.preferredNamesHops[1][key].includes(entry.value)) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            }
                        })
                    })
                } else if (this.state.hopsToShow === 3) {
                    newFilterHops.forEach(entry => {
                        Object.keys(this.state.preferredNamesHops[2]).forEach(key => {
                            if (!entry.key.includes('relation')) {
                                if (entry.value === this.state.preferredNamesHops[2][key]) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            } else {
                                if (this.state.preferredNamesHops[2][key].includes(entry.value)) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            }
                        })
                    })
                } else if (this.state.hopsToShow === 4) {
                    newFilterHops.forEach(entry => {
                        Object.keys(this.state.preferredNamesHops[3]).forEach(key => {
                            if (!entry.key.includes('relation')) {
                                if (entry.value === this.state.preferredNamesHops[3][key]) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            } else {
                                if (this.state.preferredNamesHops[3][key].includes(entry.value)) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            }
                        })
                    })
                } else if (this.state.hopsToShow === 5) {
                    newFilterHops.forEach(entry => {
                        Object.keys(this.state.preferredNamesHops[4]).forEach(key => {
                            if (!entry.key.includes('relation')) {
                                if (entry.value === this.state.preferredNamesHops[4][key]) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            } else {
                                if (this.state.preferredNamesHops[4][key].includes(entry.value)) {
                                    if (entry["ocid"] === undefined) {
                                        entry["ocid"] = [{ ocid: key }]
                                    } else {
                                        entry["ocid"].push({ ocid: key })
                                    }
                                }
                            }
                        })
                    })
                }

                let oldRequest
                if (this.state.hopsToShow === 1) {
                    oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[0]))
                } else if (this.state.hopsToShow === 2) {
                    oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[1]))
                } else if (this.state.hopsToShow === 3) {
                    oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[2]))
                } else if (this.state.hopsToShow === 4) {
                    oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[3]))
                } else if (this.state.hopsToShow === 5) {
                    oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[4]))
                }

                let newRequest = oldRequest
                newRequest.matchedPathsOffset = 0
                newRequest.matchedPathsMaxCount = 20

                newFilterHops && newFilterHops.forEach(entry => {
                    if (entry.key === 'start') {
                        newRequest.pathEntitiesFilter[0] = entry.ocid
                    } else if (entry.key === 'end') {
                        newRequest.pathEntitiesFilter[1] = entry.ocid
                    } else if (entry.key === 'relation') {
                        newRequest.pathRelationsFilter[1] = entry.ocid
                    } else if (entry.key === 'end2') {
                        newRequest.pathEntitiesFilter[2] = entry.ocid
                    } else if (entry.key === 'end3') {
                        newRequest.pathEntitiesFilter[3] = entry.ocid
                    } else if (entry.key === 'end4') {
                        newRequest.pathEntitiesFilter[4] = entry.ocid
                    } else if (entry.key === 'end5') {
                        newRequest.pathEntitiesFilter[5] = entry.ocid
                    } else if (entry.key === 'relation2') {
                        newRequest.pathRelationsFilter[2] = entry.ocid
                    } else if (entry.key === 'relation3') {
                        newRequest.pathRelationsFilter[3] = entry.ocid
                    } else if (entry.key === 'relation4') {
                        newRequest.pathRelationsFilter[4] = entry.ocid
                    } else if (entry.key === 'relation5') {
                        newRequest.pathRelationsFilter[5] = entry.ocid
                    }
                })

                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchedHopPath = response.data.matchedPaths
                            let matchedHopPathCount = response.data.matchedPathsCount
                            let matchedHopPrefNames = response.data.ocidToPreferredName
                            let number = self.state.hopsToShow

                            if (self.state.hopsToShow === 1) {
                                self.setState({
                                    newZeroHopsRequest: newRequest,
                                    countZeroHopsPath: 20,
                                    startZeroHopsPath: 0
                                }, () => {
                                    self.myCallbackFromFilterZeroHops(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[0])
                                })
                            } else if (self.state.hopsToShow === 2) {
                                self.setState({
                                    newOneHopRequest: newRequest,
                                    countOneHopPath: 20,
                                    startOneHopPath: 0
                                }, () => {
                                    self.myCallbackFromFilterOneHop(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[1])
                                })
                            } else if (self.state.hopsToShow === 3) {
                                self.setState({
                                    newTwoHopsRequest: newRequest,
                                    countTwoHopsPath: 20,
                                    startTwoHopsPath: 0
                                }, () => {
                                    self.myCallbackFromFilterTwoHops(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[2])
                                })
                            } else if (self.state.hopsToShow === 4) {
                                self.setState({
                                    newThreeHopsRequest: newRequest,
                                    countThreeHopsPath: 20,
                                    startThreeHopsPath: 0
                                }, () => {
                                    self.myCallbackFromFilterThreeHops(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[3])
                                })
                            } else if (self.state.hopsToShow === 5) {
                                self.setState({
                                    newFourHopsRequest: newRequest,
                                    countFourHopsPath: 20,
                                    startFourHopsPath: 0
                                }, () => {
                                    self.myCallbackFromFilterFourHops(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[4])
                                })
                            }
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })

                if (self.state.hopsToShow === 1) {
                    self.setState({
                        filterZeroHops: newFilterHops
                    })
                } else if (self.state.hopsToShow === 2) {
                    self.setState({
                        filterOneHop: newFilterHops
                    })
                } else if (self.state.hopsToShow === 3) {
                    self.setState({
                        filterTwoHops: newFilterHops
                    })
                } else if (self.state.hopsToShow === 4) {
                    self.setState({
                        filterThreeHops: newFilterHops
                    })
                } else if (self.state.hopsToShow === 5) {
                    self.setState({
                        filterFourHops: newFilterHops
                    })
                }

            } else {

                let newFilterHopRequest

                if (this.state.hopsToShow === 1) {
                    newFilterHopRequest = JSON.parse(JSON.stringify(this.state.newZeroHopsRequest))
                } else if (this.state.hopsToShow === 2) {
                    newFilterHopRequest = JSON.parse(JSON.stringify(this.state.newOneHopRequest))
                } else if (this.state.hopsToShow === 3) {
                    newFilterHopRequest = JSON.parse(JSON.stringify(this.state.newTwoHopsRequest))
                } else if (this.state.hopsToShow === 4) {
                    newFilterHopRequest = JSON.parse(JSON.stringify(this.state.newThreeHopsRequest))
                } else if (this.state.hopsToShow === 5) {
                    newFilterHopRequest = JSON.parse(JSON.stringify(this.state.newFourHopsRequest))
                }

                newFilterHopRequest.matchedPathsOffset = first
                newFilterHopRequest.matchedPathsMaxCount = rows

                let self = this;
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newFilterHopRequest, { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status === 200) {
                            let matchedHopPath = response.data.matchedPaths
                            let matchedHopPathCount = response.data.matchedPathsCount
                            let matchedHopPrefNames = response.data.ocidToPreferredName
                            let number = self.state.hopsToShow

                            if (self.state.hopsToShow === 1) {
                                self.setState({
                                    countZeroHopsPath: rows,
                                    startZeroHopsPath: first
                                }, () => {
                                    self.myCallbackFromFilterZeroHops(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[0])
                                })
                            } else if (self.state.hopsToShow === 2) {
                                self.setState({
                                    countOneHopPath: rows,
                                    startOneHopPath: first
                                }, () => {
                                    self.myCallbackFromFilterOneHop(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[1])
                                })
                            } else if (self.state.hopsToShow === 3) {
                                self.setState({
                                    countTwoHopsPath: rows,
                                    startTwoHopsPath: first
                                }, () => {
                                    self.myCallbackFromFilterTwoHops(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[2])
                                })
                            } else if (self.state.hopsToShow === 4) {
                                self.setState({
                                    countThreeHopsPath: rows,
                                    startThreeHopsPath: first
                                }, () => {
                                    self.myCallbackFromFilterThreeHops(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[3])
                                })
                            } else if (self.state.hopsToShow === 5) {
                                self.setState({
                                    countFourHopsPath: rows,
                                    startFourHopsPath: first
                                }, () => {
                                    self.myCallbackFromFilterFourHops(matchedHopPath, matchedHopPathCount, matchedHopPrefNames,
                                        number, self.state.hopRequests[4])
                                })
                            }
                        }
                    }).catch(function (response) {
                        if (response.response.status === 400) {
                        } else if (response.response.status === 401) {
                            self.props.history.push('/')
                        }
                    })
            }
        }
    }

    handleRemoveTripleFilter = async (key) => {
        this.setState({
            fetchingResults: true
        })
        let filterTriple = this.state.filterTriple
        filterTriple = filterTriple.filter(function (entry) {
            return entry.key !== key
        })

        let newFilterTriples = this.state.filterTriple
        filterTriple.forEach(triple => {
            newFilterTriples = [...newFilterTriples, triple]
        })

        let oldRequest = JSON.parse(JSON.stringify(this.state.tripleRequest))
        let newRequest = oldRequest
        filterTriple && filterTriple.forEach(entry => {
            if (entry.key === 'start') {
                newRequest.pathEntitiesFilter[0] = entry.ocid
            } else if (entry.key === 'end') {
                newRequest.pathEntitiesFilter[1] = entry.ocid
            } else if (entry.key === 'relation') {
                newRequest.pathRelationsFilter[1] = entry.ocid
            }
        })
        newRequest.matchedPathsMaxCount = 20
        newRequest.matchedPathsOffset = 0

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    let matchedPathsTriple = response.data.matchedPaths
                    let matchedPathsCount = response.data.matchedPathsCount
                    let prefNames = response.data.ocidToPreferredName
                    let number = self.state.tripleNumber
                    self.setState({
                        newTripleRequest: newRequest,
                        countTriplePath: 20,
                        startTriplePath: 0,
                        fetchingResults: false
                    }, () => {
                        self.myCallbackFromFilterTriple(matchedPathsTriple, matchedPathsCount, prefNames,
                            number, self.state.tripleRequest)
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
        this.setState({
            filterTriple: filterTriple
        })
    }

    handleRemoveSubFilter = async (key) => {
        this.setState({
            fetchingResults: true
        })
        let filterSub = this.state.filterSub
        filterSub = filterSub.filter(function (entry) {
            return entry.key !== key
        })

        let newFilterSub = this.state.filterSub
        filterSub.forEach(triple => {
            newFilterSub = [...newFilterSub, triple]
        })

        let oldRequest = JSON.parse(JSON.stringify(this.state.subPathRequest))
        let newRequest = oldRequest
        filterSub && filterSub.forEach(entry => {
            if (entry.key === 'start') {
                newRequest.pathEntitiesFilter[0] = entry.ocid
            } else if (entry.key === 'end') {
                newRequest.pathEntitiesFilter[1] = entry.ocid
            } else if (entry.key === 'relation') {
                newRequest.pathRelationsFilter[1] = entry.ocid
            } else if (entry.key === 'end2') {
                newRequest.pathEntitiesFilter[2] = entry.ocid
            } else if (entry.key === 'end3') {
                newRequest.pathEntitiesFilter[3] = entry.ocid
            } else if (entry.key === 'end4') {
                newRequest.pathEntitiesFilter[4] = entry.ocid
            } else if (entry.key === 'relation2') {
                newRequest.pathRelationsFilter[2] = entry.ocid
            } else if (entry.key === 'relation3') {
                newRequest.pathRelationsFilter[3] = entry.ocid
            } else if (entry.key === 'relation4') {
                newRequest.pathRelationsFilter[4] = entry.ocid
            }
        })
        newRequest.matchedPathsMaxCount = 20
        newRequest.matchedPathsOffset = 0

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    let matchedSubPaths = response.data.matchedPaths
                    let matchedSubPathCount = response.data.matchedPathsCount
                    let matchedSubPrefNames = response.data.ocidToPreferredName
                    let number = self.state.subPathNumber
                    self.setState({
                        newSubRequest: newRequest,
                        countSubPath: 20,
                        startSubPath: 0,
                        fetchingResults: false
                    }, () => {
                        self.myCallbackFromFilterSub(matchedSubPaths, matchedSubPathCount, matchedSubPrefNames,
                            number, self.state.subPathRequest)
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
        this.setState({
            filterSub: filterSub
        })
    }

    handleRemoveFullFilter = async (key) => {
        this.setState({
            fetchingResults: true
        })
        let filterFull = this.state.filterFull
        filterFull = filterFull.filter(function (entry) {
            return entry.key !== key
        })

        let newFilterFull = this.state.filterFull
        filterFull.forEach(triple => {
            newFilterFull = [...newFilterFull, triple]
        })

        let oldRequest = JSON.parse(JSON.stringify(this.state.fullPathRequest))
        let newRequest = oldRequest
        filterFull && filterFull.forEach(entry => {
            if (entry.key === 'start') {
                newRequest.pathEntitiesFilter[0] = entry.ocid
            } else if (entry.key === 'end') {
                newRequest.pathEntitiesFilter[1] = entry.ocid
            } else if (entry.key === 'relation') {
                newRequest.pathRelationsFilter[1] = entry.ocid
            } else if (entry.key === 'end2') {
                newRequest.pathEntitiesFilter[2] = entry.ocid
            } else if (entry.key === 'end3') {
                newRequest.pathEntitiesFilter[3] = entry.ocid
            } else if (entry.key === 'end4') {
                newRequest.pathEntitiesFilter[4] = entry.ocid
            } else if (entry.key === 'end5') {
                newRequest.pathEntitiesFilter[5] = entry.ocid
            } else if (entry.key === 'relation2') {
                newRequest.pathRelationsFilter[2] = entry.ocid
            } else if (entry.key === 'relation3') {
                newRequest.pathRelationsFilter[3] = entry.ocid
            } else if (entry.key === 'relation4') {
                newRequest.pathRelationsFilter[4] = entry.ocid
            } else if (entry.key === 'relation5') {
                newRequest.pathRelationsFilter[5] = entry.ocid
            }
        })
        newRequest.matchedPathsMaxCount = 20
        newRequest.matchedPathsOffset = 0

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    let matchedFullPaths = response.data.matchedPaths
                    let matchedFullPathCount = response.data.matchedPathsCount
                    let matchedFullPrefNames = response.data.ocidToPreferredName
                    let number = self.state.fullPathNumber
                    self.setState({
                        newFullRequest: newRequest,
                        countFullPath: 20,
                        startFullPath: 0,
                        fetchingResults: false
                    }, () => {
                        self.myCallbackFromFilterFull(matchedFullPaths, matchedFullPathCount, matchedFullPrefNames,
                            number, self.state.fullPathRequest)
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
        this.setState({
            filterFull: filterFull
        })
    }

    handleRemoveZeroHopsFilter = async (key) => {
        this.setState({
            fetchingResults: true
        })
        let filterZeroHops = this.state.filterZeroHops
        filterZeroHops = filterZeroHops.filter(function (entry) {
            return entry.key !== key
        })

        let newFilterZeroHops = this.state.filterZeroHops
        filterZeroHops.forEach(triple => {
            newFilterZeroHops = [...newFilterZeroHops, triple]
        })

        let oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[0]))
        let newRequest = oldRequest
        filterZeroHops && filterZeroHops.forEach(entry => {
            if (entry.key === 'start') {
                newRequest.pathEntitiesFilter[0] = entry.ocid
            } else if (entry.key === 'end') {
                newRequest.pathEntitiesFilter[1] = entry.ocid
            } else if (entry.key === 'relation') {
                newRequest.pathRelationsFilter[1] = entry.ocid
            } else if (entry.key === 'end2') {
                newRequest.pathEntitiesFilter[2] = entry.ocid
            } else if (entry.key === 'end3') {
                newRequest.pathEntitiesFilter[3] = entry.ocid
            } else if (entry.key === 'end4') {
                newRequest.pathEntitiesFilter[4] = entry.ocid
            } else if (entry.key === 'end5') {
                newRequest.pathEntitiesFilter[5] = entry.ocid
            } else if (entry.key === 'relation2') {
                newRequest.pathRelationsFilter[2] = entry.ocid
            } else if (entry.key === 'relation3') {
                newRequest.pathRelationsFilter[3] = entry.ocid
            } else if (entry.key === 'relation4') {
                newRequest.pathRelationsFilter[4] = entry.ocid
            } else if (entry.key === 'relation5') {
                newRequest.pathRelationsFilter[5] = entry.ocid
            }
        })
        newRequest.matchedPathsMaxCount = 20
        newRequest.matchedPathsOffset = 0
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    let matchedHopPaths = response.data.matchedPaths
                    let matchedHopPathCount = response.data.matchedPathsCount
                    let matchedHopPrefNames = response.data.ocidToPreferredName
                    let number = self.state.hopsToShow
                    self.setState({
                        newZeroHopsRequest: newRequest,
                        countZeroHopsPath: 20,
                        startZeroHopsPath: 0,
                        fetchingResults: false
                    }, () => {
                        self.myCallbackFromFilterZeroHops(matchedHopPaths, matchedHopPathCount, matchedHopPrefNames,
                            number, self.state.hopRequests[0])
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
        this.setState({
            filterZeroHops: filterZeroHops
        })
    }

    handleRemoveOneHopFilter = async (key) => {
        this.setState({
            fetchingResults: true
        })
        let filterOneHop = this.state.filterOneHop
        filterOneHop = filterOneHop.filter(function (entry) {
            return entry.key !== key
        })

        let newFilterOneHop = this.state.filterOneHop
        filterOneHop.forEach(triple => {
            newFilterOneHop = [...newFilterOneHop, triple]
        })

        let oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[1]))
        let newRequest = oldRequest
        filterOneHop && filterOneHop.forEach(entry => {
            if (entry.key === 'start') {
                newRequest.pathEntitiesFilter[0] = entry.ocid
            } else if (entry.key === 'end') {
                newRequest.pathEntitiesFilter[1] = entry.ocid
            } else if (entry.key === 'relation') {
                newRequest.pathRelationsFilter[1] = entry.ocid
            } else if (entry.key === 'end2') {
                newRequest.pathEntitiesFilter[2] = entry.ocid
            } else if (entry.key === 'end3') {
                newRequest.pathEntitiesFilter[3] = entry.ocid
            } else if (entry.key === 'end4') {
                newRequest.pathEntitiesFilter[4] = entry.ocid
            } else if (entry.key === 'end5') {
                newRequest.pathEntitiesFilter[5] = entry.ocid
            } else if (entry.key === 'relation2') {
                newRequest.pathRelationsFilter[2] = entry.ocid
            } else if (entry.key === 'relation3') {
                newRequest.pathRelationsFilter[3] = entry.ocid
            } else if (entry.key === 'relation4') {
                newRequest.pathRelationsFilter[4] = entry.ocid
            } else if (entry.key === 'relation5') {
                newRequest.pathRelationsFilter[5] = entry.ocid
            }
        })
        newRequest.matchedPathsMaxCount = 20
        newRequest.matchedPathsOffset = 0
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    let matchedHopPaths = response.data.matchedPaths
                    let matchedHopPathCount = response.data.matchedPathsCount
                    let matchedHopPrefNames = response.data.ocidToPreferredName
                    let number = self.state.hopsToShow
                    self.setState({
                        newOneHopRequest: newRequest,
                        countOneHopPath: 20,
                        startOneHopPath: 0,
                        fetchingResults: false
                    }, () => {
                        self.myCallbackFromFilterOneHop(matchedHopPaths, matchedHopPathCount, matchedHopPrefNames,
                            number, self.state.hopRequests[1])
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
        this.setState({
            filterOneHop: filterOneHop
        })
    }

    handleRemoveTwoHopsFilter = async (key) => {
        this.setState({
            fetchingResults: true
        })
        let filterTwoHops = this.state.filterTwoHops
        filterTwoHops = filterTwoHops.filter(function (entry) {
            return entry.key !== key
        })

        let newFilterTwoHops = this.state.filterTwoHops
        filterTwoHops.forEach(triple => {
            newFilterTwoHops = [...newFilterTwoHops, triple]
        })

        let oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[2]))
        let newRequest = oldRequest
        filterTwoHops && filterTwoHops.forEach(entry => {
            if (entry.key === 'start') {
                newRequest.pathEntitiesFilter[0] = entry.ocid
            } else if (entry.key === 'end') {
                newRequest.pathEntitiesFilter[1] = entry.ocid
            } else if (entry.key === 'relation') {
                newRequest.pathRelationsFilter[1] = entry.ocid
            } else if (entry.key === 'end2') {
                newRequest.pathEntitiesFilter[2] = entry.ocid
            } else if (entry.key === 'end3') {
                newRequest.pathEntitiesFilter[3] = entry.ocid
            } else if (entry.key === 'end4') {
                newRequest.pathEntitiesFilter[4] = entry.ocid
            } else if (entry.key === 'end5') {
                newRequest.pathEntitiesFilter[5] = entry.ocid
            } else if (entry.key === 'relation2') {
                newRequest.pathRelationsFilter[2] = entry.ocid
            } else if (entry.key === 'relation3') {
                newRequest.pathRelationsFilter[3] = entry.ocid
            } else if (entry.key === 'relation4') {
                newRequest.pathRelationsFilter[4] = entry.ocid
            } else if (entry.key === 'relation5') {
                newRequest.pathRelationsFilter[5] = entry.ocid
            }
        })
        newRequest.matchedPathsMaxCount = 20
        newRequest.matchedPathsOffset = 0

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    let matchedHopPaths = response.data.matchedPaths
                    let matchedHopPathCount = response.data.matchedPathsCount
                    let matchedHopPrefNames = response.data.ocidToPreferredName
                    let number = self.state.hopsToShow
                    self.setState({
                        newTwoHopsRequest: newRequest,
                        countTwoHopsPath: 20,
                        startTwoHopsPath: 0,
                        fetchingResults: false
                    }, () => {
                        self.myCallbackFromFilterTwoHops(matchedHopPaths, matchedHopPathCount, matchedHopPrefNames,
                            number, self.state.hopRequests[2])
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
        this.setState({
            filterTwoHops: filterTwoHops
        })
    }

    handleRemoveThreeHopsFilter = async (key) => {
        this.setState({
            fetchingResults: true
        })
        let filterThreeHops = this.state.filterThreeHops
        filterThreeHops = filterThreeHops.filter(function (entry) {
            return entry.key !== key
        })

        let newFilterThreeHops = this.state.filterThreeHops
        filterThreeHops.forEach(triple => {
            newFilterThreeHops = [...newFilterThreeHops, triple]
        })

        let oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[3]))
        let newRequest = oldRequest
        filterThreeHops && filterThreeHops.forEach(entry => {
            if (entry.key === 'start') {
                newRequest.pathEntitiesFilter[0] = entry.ocid
            } else if (entry.key === 'end') {
                newRequest.pathEntitiesFilter[1] = entry.ocid
            } else if (entry.key === 'relation') {
                newRequest.pathRelationsFilter[1] = entry.ocid
            } else if (entry.key === 'end2') {
                newRequest.pathEntitiesFilter[2] = entry.ocid
            } else if (entry.key === 'end3') {
                newRequest.pathEntitiesFilter[3] = entry.ocid
            } else if (entry.key === 'end4') {
                newRequest.pathEntitiesFilter[4] = entry.ocid
            } else if (entry.key === 'end5') {
                newRequest.pathEntitiesFilter[5] = entry.ocid
            } else if (entry.key === 'relation2') {
                newRequest.pathRelationsFilter[2] = entry.ocid
            } else if (entry.key === 'relation3') {
                newRequest.pathRelationsFilter[3] = entry.ocid
            } else if (entry.key === 'relation4') {
                newRequest.pathRelationsFilter[4] = entry.ocid
            } else if (entry.key === 'relation5') {
                newRequest.pathRelationsFilter[5] = entry.ocid
            }
        })
        newRequest.matchedPathsMaxCount = 20
        newRequest.matchedPathsOffset = 0

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    let matchedHopPaths = response.data.matchedPaths
                    let matchedHopPathCount = response.data.matchedPathsCount
                    let matchedHopPrefNames = response.data.ocidToPreferredName
                    let number = self.state.hopsToShow
                    self.setState({
                        newThreeHopsRequest: newRequest,
                        countThreeHopsPath: 20,
                        startThreeHopsPath: 0,
                        fetchingResults: false
                    }, () => {
                        self.myCallbackFromFilterThreeHops(matchedHopPaths, matchedHopPathCount, matchedHopPrefNames,
                            number, self.state.hopRequests[3])
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
        this.setState({
            filterThreeHops: filterThreeHops
        })
    }

    handleRemoveFourHopsFilter = async (key) => {
        this.setState({
            fetchingResults: true
        })
        let filterFourHops = this.state.filterFourHops
        filterFourHops = filterFourHops.filter(function (entry) {
            return entry.key !== key
        })

        let newFilterFourHops = this.state.filterFourHops
        filterFourHops.forEach(triple => {
            newFilterFourHops = [...newFilterFourHops, triple]
        })

        let oldRequest = JSON.parse(JSON.stringify(this.state.hopRequests[4]))
        let newRequest = oldRequest
        filterFourHops && filterFourHops.forEach(entry => {
            if (entry.key === 'start') {
                newRequest.pathEntitiesFilter[0] = entry.ocid
            } else if (entry.key === 'end') {
                newRequest.pathEntitiesFilter[1] = entry.ocid
            } else if (entry.key === 'relation') {
                newRequest.pathRelationsFilter[1] = entry.ocid
            } else if (entry.key === 'end2') {
                newRequest.pathEntitiesFilter[2] = entry.ocid
            } else if (entry.key === 'end3') {
                newRequest.pathEntitiesFilter[3] = entry.ocid
            } else if (entry.key === 'end4') {
                newRequest.pathEntitiesFilter[4] = entry.ocid
            } else if (entry.key === 'end5') {
                newRequest.pathEntitiesFilter[5] = entry.ocid
            } else if (entry.key === 'relation2') {
                newRequest.pathRelationsFilter[2] = entry.ocid
            } else if (entry.key === 'relation3') {
                newRequest.pathRelationsFilter[3] = entry.ocid
            } else if (entry.key === 'relation4') {
                newRequest.pathRelationsFilter[4] = entry.ocid
            } else if (entry.key === 'relation5') {
                newRequest.pathRelationsFilter[5] = entry.ocid
            }
        })
        newRequest.matchedPathsMaxCount = 20
        newRequest.matchedPathsOffset = 0

        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    let matchedHopPaths = response.data.matchedPaths
                    let matchedHopPathCount = response.data.matchedPathsCount
                    let matchedHopPrefNames = response.data.ocidToPreferredName
                    let number = self.state.hopsToShow
                    self.setState({
                        newFourHopsRequest: newRequest,
                        countFourHopsPath: 20,
                        startFourHopsPath: 0,
                        fetchingResults: false
                    }, () => {
                        self.myCallbackFromFilterFourHops(matchedHopPaths, matchedHopPathCount, matchedHopPrefNames,
                            number, self.state.hopRequests[4])
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                }
            })
        this.setState({
            filterFourHops: filterFourHops
        })
    }

    myCallbackFromFilterCheckedChange = (value) => {
        this.setState({
            filterChecked: value
        }, () => {
            if (this.state.startTerm[0].length > 0 && this.state.endTerm[this.state.pathNumber - 1].length > 0) {
                this.handleSearch(this.state.pathNumber, true, true)
            }
        })
    }

    myCallbackFromHopNumberChange = (value) => {
        this.setState({
            numberOfHops: value,
            hopsOfTable: value[0],
            //hopsToShow: value[0]
        })
    }

    myCallbackFromSearchWithHops = async (min, max, fromPageChange) => {
        let blacklistEntries = []

        if (this.state.selectedBlacklists && this.state.selectedBlacklists.length > 0) {
            for (let i = 0; i < this.state.selectedBlacklists.length; i++) {
                if (hasUserRole('ROLE_BLACKLISTS')) {
                    await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${this.state.selectedBlacklists[i]}`, { headers: getHeaderToken() })
                        // eslint-disable-next-line
                        .then(function (response) {
                            let entries = response.data
                            blacklistEntries = [...blacklistEntries, entries]
                        })
                        .catch(function (error) {
                            if (error.response.status === 400) {
                                this.showFailGrowl(error.response.data)
                            } else if (error.response.status === 401) {
                                this.state.history.push('/')
                            } else if (error.response.status === 404) {
                                console.log("not found")
                            }
                        })
                }
            }
        }

        let negated = []

        blacklistEntries.forEach(entry => {
            entry.forEach(item => {
                negated = [...negated, { ocid: item.ocid, negated: true, conceptLookupType: item.conceptLookupType }]
            })
        })

        if (!fromPageChange) {
            this.setState({
                countZeroHopsPath: 20,
                startZeroHopsPath: 0,
                countOneHopPath: 20,
                startOneHopPath: 0,
                countTwoHopsPath: 20,
                startTwoHopsPath: 0,
                countThreeHopsPath: 20,
                startThreeHopsPath: 0,
                countFourHopsPath: 20,
                startFourHopsPath: 0
            })
        }

        this.setState({
            fetchingResults: true
        })

        let request = {
            apiRevision: 0,
            domainWhitelist: [
            ],
            forceCompletePathMatching: true,
            matchedPathsMaxCount: this.state.hopsToShow === 1 ? this.state.countZeroHopsPath : this.state.hopsToShow === 2 ? this.state.countOneHopPath : this.state.hopsToShow === 3 ? this.state.countTwoHopsPath : this.state.hopsToShow === 4 ? this.state.countThreeHopsPath : this.state.countFourHopsPath,
            matchedPathsOffset: this.state.hopsToShow === 1 ? this.state.startZeroHopsPath : this.state.hopsToShow === 2 ? this.state.startOneHopPath : this.state.hopsToShow === 3 ? this.state.startTwoHopsPath : this.state.hopsToShow === 4 ? this.state.startThreeHopsPath : this.state.startFourHopsPath,
            matchedPathsRanking: "TRIPLE_COUNT_AVG",
            matchedPathsWithTripleBaseData: true,
            maxMegaTriplesToProcess: 2,
            maxTopMatchedConceptsPerPathStep: 1000,
            matchedPathsOrdering: "RANKED",
            matchedPathsMaxTripleSentences: 10,
            pathEntitiesFilter: {
            },
            pathRelationsFilter: {
            },
            repository: this.state.selectedRepository,
            topMatchPerPathStepCountingUseTripleCount: true,
            topMatchedEntitiesPerPathStepIndexes: [
                0
            ],
            topMatchedRelationsPerPathStepIndexes: [
                0
            ],
            uniqueMatchedEntitiesPerPathStepIndexes: [
                0
            ],
            uniqueMatchedRelationsPerPathStepIndexes: [
                0
            ],
            withConceptDomains: true,
            withConceptPrefnames: true
        }

        let entitiesStart = {}
        let entitiesEnd = {}

        let rootOCIDs = [{ ocid: 150000001799 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 }]
        let rootOCIDsAll = [{ ocid: 150000005829 }, { ocid: 229930014626 }, { ocid: 229940079593 }, { ocid: 239000000126 },
        { ocid: 230000035790 }, { ocid: 201000010099 }, { ocid: 108020003292 }, { ocid: 241000894007 }, { ocid: 102000006028 }, { ocid: 210000003484 }, { ocid: 261000000573 }] //,{ocid: 103000003236}]

        if (this.state.startTerm[0] === '' || this.state.startTerm[0].length === 0) {
            this.state.logicals[0] === 'ctd' || this.state.logicals[0] === 'cip' || this.state.logicals[0] === 'cid' || this.state.logicals[0] === 'cic' ||
                this.state.logicals[0] === 'cipp' || this.state.logicals[0] === 'caa' ?
                entitiesStart['0'] = rootOCIDs
                :
                this.state.logicals[0] === 'aaa' || this.state.logicals[0] === 'aac' || this.state.logicals[0] === 'aad' || this.state.logicals[0] === 'aapp' ||
                    this.state.logicals[0] === 'aap' ?
                    entitiesStart['0'] = rootOCIDsAll
                    :
                    entitiesStart['0'] = [
                        {
                            domain:
                                this.state.logicals[0] === 'pid' ? 'proteins' :
                                    this.state.logicals[0] === 'paa' ? 'proteins' :
                                        this.state.logicals[0] === 'pmc' ? 'proteins' :
                                            this.state.logicals[0] === 'pmpp' ? 'proteins' :
                                                this.state.logicals[0] === 'ppmp' ? '' :
                                                    this.state.logicals[0] === 'ppipp' ? '' :
                                                        this.state.logicals[0] === 'pppd' ? '' :
                                                            this.state.logicals[0] === 'dmp' ? 'diseases' :
                                                                this.state.logicals[0] === 'daa' ? 'diseases' : '',
                            ocid: this.state.logicals[0] === 'ppmp' || this.state.logicals[0] === 'ppipp' || this.state.logicals[0] === 'pppd'
                                || this.state.logicals[0] === 'ppaa' ? '230000035790' : ''
                        }
                    ]
        } else {
            entitiesStart['0'] = []
            let allStartEntities = []
            for (let j = 0; j < this.state.startTermOCID[0].length; j++) {
                let allSubEntities = []
                for (let k = 0; k < this.state.startTermOCID[0][j].length; k++) {
                    allSubEntities = [...allSubEntities, { ocid: this.state.startTermOCID[0][j][k]['+ocid'], conceptLookupType: this.state.startChecked[0] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                }
                allSubEntities.forEach(entity => {
                    allStartEntities = [...allStartEntities, (entity)]
                })
            }
            entitiesStart['0'] = allStartEntities
        }

        if (this.state.endTerm[0] === '' || this.state.endTerm[0].length === 0) {
            this.state.logicals[0] === 'cic' || this.state.logicals[0] === 'pmc' || this.state.logicals[0] === 'aac' ?
                entitiesEnd['1'] = rootOCIDs
                :
                this.state.logicals[0] === 'aaa' || this.state.logicals[0] === 'caa' || this.state.logicals[0] === 'daa' ||
                    this.state.logicals[0] === 'ppaa' || this.state.logicals[0] === 'paa' ?
                    entitiesEnd['1'] = rootOCIDsAll
                    :
                    entitiesEnd['1'] = [{
                        domain:
                            this.state.logicals[0] === 'ctd' ? 'diseases' :
                                this.state.logicals[0] === 'cip' ? 'proteins' :
                                    this.state.logicals[0] === 'cid' ? 'diseases' :
                                        this.state.logicals[0] === 'cipp' ? '' :
                                            this.state.logicals[0] === 'ppipp' ? '' :
                                                this.state.logicals[0] === 'pid' ? 'diseases' :
                                                    this.state.logicals[0] === 'pppd' ? 'diseases' :
                                                        this.state.logicals[0] === 'aad' ? 'diseases' :
                                                            this.state.logicals[0] === 'pmpp' ? '' :
                                                                this.state.logicals[0] === 'ppmp' ? 'proteins' :
                                                                    this.state.logicals[0] === 'aap' ? 'proteins' :
                                                                        this.state.logicals[0] === 'dmp' ? 'proteins' : '',
                        ocid: this.state.logicals[0] === 'cipp' || this.state.logicals[0] === 'pmpp' || this.state.logicals[0] === 'ppipp'
                            || this.state.logicals[0] === 'aapp' ? '230000035790' : ''
                    }
                    ]
        } else {
            entitiesEnd['1'] = []
            let allEndEntities = []
            for (let j = 0; j < this.state.endTermOCID[0].length; j++) {
                let allSubEntities = []
                for (let k = 0; k < this.state.endTermOCID[0][j].length; k++) {
                    allSubEntities = [...allSubEntities, { ocid: this.state.endTermOCID[0][j][k]['+ocid'], conceptLookupType: this.state.endChecked[0] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                }
                allSubEntities.forEach(entity => {
                    allEndEntities = [...allEndEntities, (entity)]
                })
            }
            entitiesEnd['1'] = allEndEntities
        }

        let startEnd = Object.assign(entitiesStart, entitiesEnd)

        request['pathEntitiesFilter'] = startEnd

        let allRelations = []
        if (typeof this.state.relationOCID[0] !== 'string') {
            if (this.state.relationOCID[0].length > 0) {
                this.state.relationOCID[0].forEach(ocid => {
                    allRelations = [...allRelations, { ocid: ocid, conceptLookupType: this.state.relationMode[0] === 'ontological' ? 'ONTOLOGICAL' : 'EXACT' }]
                })
            } else {
                allRelations = this.state.relationsChecked === "narrow" ? [
                    { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                    , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                    { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                    { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
            }
        }

        if (typeof this.state.relationOCID[0] === 'string') {
            if (this.state.relationOCID[0] !== "") {
                allRelations = [{
                    ocid: this.state.relationOCID[0]
                }]
            } else {
                allRelations = this.state.relationsChecked === "narrow" ? [
                    { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                    , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                    { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                    { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
            }
        }

        request['pathRelationsFilter'][1] = allRelations

        let counter = 1
        let minReached = false

        for (let i = min; i <= max; i++) {
            let newRequest = JSON.parse(JSON.stringify(request))
            if (i > 1) {
                newRequest['pathEntitiesFilter'][i] = newRequest['pathEntitiesFilter'][1]
                newRequest['pathRelationsFilter'][i] = newRequest['pathRelationsFilter'][1]
                for (let j = 1; j < i; j++) {
                    newRequest['pathEntitiesFilter'][j] = rootOCIDsAll
                    newRequest['pathRelationsFilter'][j] = this.state.relationsChecked === "narrow" ? [
                        { ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' }
                        , { ocid: '232000000879' }, { ocid: '232000001070' }, { ocid: '232000000120' }, { ocid: '232000000317' }] : [{ ocid: '232000000387' }, { ocid: '232000000391' }, { ocid: '232000000378' }, { ocid: '232000000504' }, { ocid: '232000000393' }, { ocid: '232000000588' },
                        { ocid: '232000000125' }, { ocid: '232000000179' }, { ocid: '232000000142' }, { ocid: '232000000534' }, { ocid: '232000000133' }, { ocid: '232000000353' },
                        { ocid: '232000000179' }, { ocid: '232000001284' }, { ocid: '232000000055' }, { ocid: '232000000882' }, { ocid: '232000001080' }, { ocid: '232000000883' }, { ocid: '232000000880' }, { ocid: '232000000524' }, { ocid: '232000000795' }, { ocid: '232000001055' }, { ocid: '232000001283' }]
                }
            }
            if (negated && negated.length > 0) {
                Object.keys(newRequest['pathEntitiesFilter']).forEach(key => {
                    for (let j = 0; j < negated.length; j++) {
                        newRequest['pathEntitiesFilter'][key] = [...newRequest['pathEntitiesFilter'][key], negated[j]]
                    }
                })
            }

            let self = this;
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/triple-relation-paths`, newRequest, { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status === 200) {
                        if (response.data.ocidToPreferredName === undefined && minReached === false) {
                            counter = counter + 1
                        } else if (response.data.ocidToPreferredName !== undefined && minReached === false) {
                            minReached = true
                        }
                        let newHopRequest = self.state.hopRequests.slice()
                        newHopRequest[i - 1] = newRequest

                        let newPaths = self.state.pathsHops.slice()
                        newPaths[i - 1] = response.data.matchedPaths

                        let newCountsHops = self.state.countsHops.slice()
                        newCountsHops[i - 1] = response.data.matchedPathsCount

                        let newPreferredNamesHops = self.state.preferredNamesHops.slice()
                        newPreferredNamesHops[i - 1] = response.data.ocidToPreferredName

                        if (!fromPageChange) {
                            self.setState({
                                counterMinHops: counter
                            })
                        }

                        self.setState({
                            hopRequests: newHopRequest,
                            pathsHops: newPaths,
                            countsHops: newCountsHops,
                            preferredNamesHops: newPreferredNamesHops
                        })
                        if (i === max && counter < 6) {
                            self.setState({
                                fetchingResults: false,
                                disabledHops: false,
                                activeIndex: 0,
                                showResults: true,
                                hopsOfTable: fromPageChange ? self.state.counterMinHops : counter,
                                hopsToShow: fromPageChange ? self.state.hopsToShow : counter
                            }, () => {
                                window.scrollTo(0, self.table?.current?.offsetTop - 200)
                            })
                        } else if (counter >= 6) {
                            self.setState({
                                fetchingResults: false,
                                disabledHops: false
                            }, () => {
                                self.showHopFailGrowl()
                            })
                        }
                    }
                }).catch(function (response) {
                    if (response.response.status === 400) {
                    } else if (response.response.status === 401) {
                        self.props.history.push('/')
                    }
                })
        }
    }

    showHopFailGrowl = () => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'No paths found for your search!', life: 6000 };
        this.growl.current.show(msg);
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.current.show(msg);
    }

    myCallbackFromDropdownHopChange = (value) => {
        this.setState({ hopsToShow: value })
    }

    myCallbackFromPageChange = (first, rows, fromTriple, fromSubPath, fromPath, fromHops) => {
        if (fromPath) {
            if (this.state.filterFull.length > 0) {
                this.setState({
                    countFullPath: rows,
                    startFullPath: first
                }, () =>
                    this.myCallbackFromFilter(this.state.filterFull, fromTriple, fromSubPath, fromPath, fromHops, true, first, rows)
                )
            } else {
                this.setState({
                    countFullPath: rows,
                    startFullPath: first
                }, () =>
                    this.handleSearch(1, false)
                )
            }
        } else if (fromSubPath) {
            if (this.state.filterSub.length > 0) {
                this.setState({
                    countSubPath: rows,
                    startSubPath: first
                }, () =>
                    this.myCallbackFromFilter(this.state.filterSub, fromTriple, fromSubPath, fromPath, fromHops, true, first, rows)
                )
            } else {
                this.setState({
                    countSubPath: rows,
                    startSubPath: first
                }, () =>
                    this.myCallbackFromExecutePath(1, false)
                )
            }
        } else if (fromTriple) {
            if (this.state.filterTriple.length > 0) {
                this.setState({
                    countTriplePath: rows,
                    startTriplePath: first
                }, () =>
                    this.myCallbackFromFilter(this.state.filterTriple, fromTriple, fromSubPath, fromPath, fromHops, true, first, rows)
                )
            } else {
                this.setState({
                    countTriplePath: rows,
                    startTriplePath: first
                }, () =>
                    this.myCallbackFromExecuteSinglePath(1, false)
                )
            }
        } else if (fromHops) {
            if (this.state.hopsToShow === 1) {
                if (this.state.filterZeroHops.length > 0) {
                    this.setState({
                        countZeroHopsPath: rows,
                        startZeroHopsPath: first
                    }, () =>
                        this.myCallbackFromFilter(this.state.filterZeroHops, fromTriple, fromSubPath, fromPath, fromHops, true, first, rows)
                    )
                } else {
                    this.setState({
                        countZeroHopsPath: rows,
                        startZeroHopsPath: first
                    }, () =>
                        this.myCallbackFromSearchWithHops(this.state.hopsToShow, this.state.hopsToShow, true)
                    )
                }
            } else if (this.state.hopsToShow === 2) {
                if (this.state.filterOneHop.length > 0) {
                    this.setState({
                        countOneHopPath: rows,
                        startOneHopPath: first
                    }, () =>
                        this.myCallbackFromFilter(this.state.filterOneHop, fromTriple, fromSubPath, fromPath, fromHops, true, first, rows)
                    )
                } else {
                    this.setState({
                        countOneHopPath: rows,
                        startOneHopPath: first
                    }, () =>
                        this.myCallbackFromSearchWithHops(this.state.hopsToShow, this.state.hopsToShow, true)
                    )
                }
            } else if (this.state.hopsToShow === 3) {
                if (this.state.filterTwoHops.length > 0) {
                    this.setState({
                        countTwoHopsPath: rows,
                        startTwoHopsPath: first
                    }, () =>
                        this.myCallbackFromFilter(this.state.filterTwoHops, fromTriple, fromSubPath, fromPath, fromHops, true, first, rows)
                    )
                } else {
                    this.setState({
                        countTwoHopsPath: rows,
                        startTwoHopsPath: first
                    }, () =>
                        this.myCallbackFromSearchWithHops(this.state.hopsToShow, this.state.hopsToShow, true)
                    )
                }
            } else if (this.state.hopsToShow === 4) {
                if (this.state.filterThreeHops.length > 0) {
                    this.setState({
                        countThreeHopsPath: rows,
                        startThreeHopsPath: first
                    }, () =>
                        this.myCallbackFromFilter(this.state.filterThreeHops, fromTriple, fromSubPath, fromPath, fromHops, true, first, rows)
                    )
                } else {
                    this.setState({
                        countThreeHopsPath: rows,
                        startThreeHopsPath: first
                    }, () =>
                        this.myCallbackFromSearchWithHops(this.state.hopsToShow, this.state.hopsToShow, true)
                    )
                }
            } else if (this.state.hopsToShow === 5) {
                if (this.state.filterFourHops.length > 0) {
                    this.setState({
                        countFourHopsPath: rows,
                        startFourHopsPath: first
                    }, () =>
                        this.myCallbackFromFilter(this.state.filterFourHops, fromTriple, fromSubPath, fromPath, fromHops, true, first, rows)
                    )
                } else {
                    this.setState({
                        countFourHopsPath: rows,
                        startFourHopsPath: first
                    }, () =>
                        this.myCallbackFromSearchWithHops(this.state.hopsToShow, this.state.hopsToShow, true)
                    )
                }
            }
        }
    }

    onTabAllChange = (index) => {
        this.setState({
            activeIndexAll: index
        }, () => {
            let self = this
            if (hasUserRole('ROLE_BLACKLISTS')) {
                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists`, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.setState({
                            blacklists: response.data
                        }, () => {
                            let pathBlacklists = []
                            response.data.forEach(blacklist => {
                                pathBlacklists = [...pathBlacklists, { label: blacklist.title, value: blacklist.id }]
                            })
                            self.setState({
                                pathBlacklists: pathBlacklists
                            })
                        })
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
        }
        )
    }

    callbackFromCloseConfirm = () => {
        this.setState({
            displayConfirmationDialog: false
        })
    }

    /*myCallbackFromFirstRelationClick = (value) => {
        this.setState({
            showFirstRelation: value
        })
    }*/

    handleSelectionChange = (rows, fromTriple, fromSubPath, fromHops, fromPath) => {
        if (fromTriple) {
            this.setState({
                selectedRowsTriple: rows
            })
        } else if (fromSubPath) {
            this.setState({
                selectedRowsSubPath: rows
            })
        } else if (fromHops) {
            if (this.state.hopsToShow === 1) {
                this.setState({
                    selectedRowsZeroHops: rows
                })
            } else if (this.state.hopsToShow === 2) {
                this.setState({
                    selectedRowsOneHop: rows
                })
            } else if (this.state.hopsToShow === 3) {
                this.setState({
                    selectedRowsTwoHops: rows
                })
            }
            else if (this.state.hopsToShow === 4) {
                this.setState({
                    selectedRowsThreeHops: rows
                })
            } else {
                this.setState({
                    selectedRowsFourHops: rows
                })
            }
        } else if (fromPath) {
            this.setState({
                selectedRowsPath: rows
            })
        }
    }

    onTabChange = (e) => {
        if (this.toastBC.current) {
            this.toastBC.current.clear()
        }
        this.setState({
            activeIndex: e.index,
            checkedEntities: [],
            displayCustomToast: false
        })
    }

    onSaveQuery = () => {
        let startString = 'Entity 1: '
        if (this.state.startTerm[0] && this.state.startTerm[0].length > 0) {
            this.state.startTerm[0].forEach(startTerm => {
                startString += startTerm + ", "
            })
            startString = startString.slice(0, -2)
        } else {
            startString += "All possible concepts"
        }
        let endString = []
        this.state.autocompleteOrBrowserEnd.forEach((endTerm, i) => {
            if (this.state.autocompleteOrBrowserEnd[i].length === 0) {
                endString[i] = `Entity ${i + 2}: All possible concepts`
            } else {
                endString[i] = `Entity ${i + 2}: `
                this.state.endTerm[i].forEach(entry => {
                    endString[i] += entry + ", "
                })
                endString[i] = endString[i].slice(0, -2)
            }
        })
        let completeEndString = ""
        endString.forEach((endTerm, j) => {
            if (j < endString.length - 1) {
                let relationNames = ""
                this.state.relationNames[j + 1].forEach(entry => {
                    relationNames += entry + ", "
                })
                relationNames = relationNames.slice(0, -2)
                completeEndString += endTerm + ` [${relationNames}] `
            } else {
                completeEndString += endTerm
            }
        })

        let startRelations = ""
        this.state.relationNames[0].forEach(relation => {
            startRelations += relation + ", "
        })
        startRelations = startRelations.slice(0, -2)

        let isEmpty = a => Array.isArray(a) && a.every(isEmpty)
        if (isEmpty(this.state.autocompleteOrBrowserStart) && isEmpty(this.state.autocompleteOrBrowserEnd)) {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'warn', life: 6000,
                summary: 'Empty search',
                detail: `Cannot save empty search. Please fill at least one entity.`
            })
        } else {
            this.setState({
                overwrite: false,
                queryFullName: '',
                queryDescription: '',
                queryName: '',
                displaySaveQueryDialog: true,
                selectedQueryCollectionIDs: [],
                queryString: startString + ` [${startRelations}] ` + completeEndString
            });
        }
    }

    onHideSaveQuery = () => {
        this.setState({
            displaySaveQueryDialog: false
        });
    }

    /**
     * 
     */
    onStoreQuery = async (queryString, queryName, queryFullName, queryDescription, watchlistIDs) => {

        const formContent = {
            allRightDomainForStart: this.state.allRightDomainForStart,
            allLeftDomainForStart: this.state.allLeftDomainForStart,
            allLeftDomainForStartShow: this.state.allLeftDomainForStartShow,
            autocompleteOrBrowserStart: this.state.autocompleteOrBrowserStart,
            autocompleteOrBrowserEnd: this.state.autocompleteOrBrowserEnd,
            endChecked: this.state.endChecked,
            endTerm: this.state.endTerm,
            endTermDomains: this.state.endTermDomains,
            endTermOCID: this.state.endTermOCID,
            endTermShow: this.state.endTermShow,
            leftDomains: this.state.leftDomains,
            leftDomainsShow: this.state.leftDomainsShow,
            logicals: this.state.logicals,
            pathNumber: this.state.pathNumber,
            pathRepositories: this.state.pathRepositories,
            relation: this.state.relation,
            relationNames: this.state.relationNames,
            relationValues: this.state.relationValues,
            relationOCID: this.state.relationOCID,
            relationOCIDMulti: this.state.relationOCIDMulti,
            rightDomains: this.state.rightDomains,
            rightDomainsShow: this.state.rightDomainsShow,
            startChecked: this.state.startChecked,
            relationMode: this.state.relationMode,
            startTerm: this.state.startTerm,
            startTermDomains: this.state.startTermDomains,
            startTermOCID: this.state.startTermOCID,
            startTermShow: this.state.startTermShow,
            type: this.state.type,
            selectedBlacklists: this.state.selectedBlacklists ? this.state.selectedBlacklists.map(bl => bl) : [],
            filterChecked: this.state.filterChecked,
            relationsChecked: this.state.relationsChecked,
            fullPathHits: this.state.fullPathHits,
            subPathHits: this.state.subPathHits,
            tripleHits: this.state.tripleHits
        };

        let response;
        // --- existing query is updated --- //
        if (this.state.editQuery && this.state.overwrite) {
            response = await updateQuery(this.state.editQueryID,
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_FACT_SEARCH.id,
                QUERY_TYPE_FACT_SEARCH.id, "fact", formContent, watchlistIDs);
        } else if (this.state.editQuery && !this.state.overwrite) {
            response = await storeQuery(
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_FACT_SEARCH.id,
                QUERY_TYPE_FACT_SEARCH.id, "fact", formContent, watchlistIDs);
        } // --- new query is stored --- //
        else {
            response = await storeQuery(
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_FACT_SEARCH.id,
                QUERY_TYPE_FACT_SEARCH.id, "fact", formContent, watchlistIDs);
        }

        const result = checkResultAndGetPayload(response, this.growl, 'Success',
            `${this.state.editQuery ? 'Search successfully updated.' : 'Search successfully stored.'}`);
        if (response.status === "SUCCESS") {
            this.setState({
                queryNameEdit: queryName,
                queryDescriptionEdit: queryDescription,
                queryFullNameEdit: queryFullName,
                displaySaveQueryDialog: false,
                selectedQueryCollectionIDs: watchlistIDs
            })
        }

        /*this.setState({
            displaySaveQueryDialog: false
        });*/

        /*let response;
        // --- existing query is updated --- //
        if (this.state.editQuery) {
            response = await updateQuery(this.state.editQueryID,
                queryString, queryName, queryFullName, queryDescription, QUERY_CATEGORY_FACT_SEARCH.id,
                QUERY_TYPE_FACT_SEARCH.id, "fact", formContent, watchlistIDs);
        }

        const result = checkResultAndGetPayload(response, this.growl, 'Success',
            `${this.state.editQuery ? 'Search successfully updated.' : 'Search successfully stored.'}`);

        this.setState({
            displaySaveQueryDialog: false
        });
    }

    /**
     * 
     */
    }


    onEditQuery = () => {
        let startString = 'Entity 1: '
        if (this.state.startTerm[0] && this.state.startTerm[0].length > 0) {
            this.state.startTerm[0].forEach(startTerm => {
                startString += startTerm + ", "
            })
            startString = startString.slice(0, -2)
        } else {
            startString += "All possible concepts"
        }
        let endString = []
        this.state.autocompleteOrBrowserEnd.forEach((endTerm, i) => {
            if (this.state.autocompleteOrBrowserEnd[i].length === 0) {
                endString[i] = `Entity ${i + 2}: All possible concepts`
            } else {
                endString[i] = `Entity ${i + 2}: `
                this.state.endTerm[i].forEach(entry => {
                    endString[i] += entry + ", "
                })
                endString[i] = endString[i].slice(0, -2)
            }
        })
        let completeEndString = ""
        endString.forEach((endTerm, j) => {
            if (j < endString.length - 1) {
                let relationNames = ""
                this.state.relationNames[j + 1].forEach(entry => {
                    relationNames += entry + ", "
                })
                relationNames = relationNames.slice(0, -2)
                completeEndString += endTerm + ` [${relationNames}] `
            } else {
                completeEndString += endTerm
            }
        })

        let startRelations = ""
        this.state.relationNames[0].forEach(relation => {
            startRelations += relation + ", "
        })
        startRelations = startRelations.slice(0, -2)

        this.setState({
            overwrite: true,
            queryFullName: this.state.queryFullNameEdit,
            queryDescription: this.state.queryDescriptionEdit,
            queryName: this.state.queryNameEdit,
            selectedQueryCollectionIDs: this.state.selectedQueryCollectionIDsEdit,
            displaySaveQueryDialog: true,
            queryString: startString + ` [${startRelations}] ` + completeEndString
        });
    }

    changeRelationsChecked = (mode) => {
        let newRelationOCID = this.state.relationOCID
        newRelationOCID.forEach((relation, i) => {
            newRelationOCID[i] = []
        })
        let newRelationValues = this.state.relationValues
        newRelationValues.forEach((relation, i) => {
            newRelationValues[i] = []
        })
        this.setState({
            relationsChecked: mode,
            relationOCID: newRelationOCID,
            relationValues: newRelationValues
        }, async () => {
            if (this.state.pathNumber > 1) {
                for (let i = 1; i <= this.state.pathNumber; i++) {
                    await this.myCallbackFromExecuteSinglePath(i, true)
                    await this.calculateRelations(this.state.pathNumber, i - 1)
                }
                for (let i = 1; i <= this.state.pathNumber - 1; i++) {
                    await this.myCallbackFromExecutePath(i, true)
                }
            } else {
                await this.myCallbackFromExecuteSinglePath(1, true)
                await this.calculateRelations(1, 0)

            }
            await this.handleSearch(this.state.pathNumber, true)

        })
    }

    showMoreInfo = () => {
        this.setState({
            showMore: false
        })
    }

    showLessInfo = () => {
        this.setState({
            showMore: true
        })
    }
    render() {

        let filterTripleItems = this.state.filterTriple && this.state.filterTriple.length > 0 &&
            this.state.filterTriple.map(filter => {
                return <Button
                    key={filter.key}
                    icon='pi pi-times'
                    iconPos='right'
                    onClick={(e) => this.handleRemoveTripleFilter(filter.key, e)}
                    label={`${filter.key === "relation" ? "Relation 1" : filter.key === "relation2" ? "Relation 2" : filter.key === "relation3" ? "Relation 3"
                        : filter.key === "relation4" ? "Relation 4" : filter.key === "relation5" ? "Relation 5" :
                            filter.key === "start" ? "Entity 1" :
                                filter.key === "end" ? "Entity 2" : filter.key === "end2" ? "Entity 3" : filter.key === "end3" ? "Entity 4" :
                                    filter.key === "end4" ? "Entity 5" : filter.key === "end5" ? "Entity 6" : filter.value}: ${filter.value}`}
                    className={filter.key !== 'relation' ? 'p-button-text' : 'p-button-text'}
                    title='Click to remove this filter'
                    style={{ marginRight: 5, verticalAlign: ' middle', fontWeight: !filter.key.includes("relation") ? 'bold' : 'normal', color: 'black', backgroundColor: '#e8f3fc', borderColor: '#cfcfcf' }}>
                </Button>
            })

        let filterSubItems = this.state.filterSub && this.state.filterSub.length > 0 && this.state.filterSub.map(filter => {
            return <Button
                key={filter.key}
                icon='pi pi-times'
                iconPos='right'
                onClick={(e) => this.handleRemoveSubFilter(filter.key, e)}
                label={`${filter.key === "relation" ? "Relation 1" : filter.key === "relation2" ? "Relation 2" : filter.key === "relation3" ? "Relation 3"
                    : filter.key === "relation4" ? "Relation 4" : filter.key === "relation5" ? "Relation 5" :
                        filter.key === "start" ? "Entity 1" :
                            filter.key === "end" ? "Entity 2" : filter.key === "end2" ? "Entity 3" : filter.key === "end3" ? "Entity 4" :
                                filter.key === "end4" ? "Entity 5" : filter.key === "end5" ? "Entity 6" : filter.value}: ${filter.value}`}
                className={filter.key !== 'relation' && filter.key !== 'relation2' && filter.key !== 'relation3' && filter.key !== 'relation4' ? 'p-button-text' : 'p-button-text'}
                title='Click to remove this filter'
                style={{ marginRight: 5, verticalAlign: ' middle', fontWeight: !filter.key.includes("relation") ? 'bold' : 'normal', color: 'black', backgroundColor: '#e8f3fc', borderColor: '#cfcfcf' }}>
            </Button>
        })

        let filterFullItems = this.state.filterFull && this.state.filterFull.length > 0 && this.state.filterFull.map(filter => {
            return <Button
                key={filter.key}
                icon='pi pi-times'
                iconPos='right'
                onClick={(e) => this.handleRemoveFullFilter(filter.key, e)}
                label={`${filter.key === "relation" ? "Relation 1" : filter.key === "relation2" ? "Relation 2" : filter.key === "relation3" ? "Relation 3"
                    : filter.key === "relation4" ? "Relation 4" : filter.key === "relation5" ? "Relation 5" :
                        filter.key === "start" ? "Entity 1" :
                            filter.key === "end" ? "Entity 2" : filter.key === "end2" ? "Entity 3" : filter.key === "end3" ? "Entity 4" :
                                filter.key === "end4" ? "Entity 5" : filter.key === "end5" ? "Entity 6" : filter.value}: ${filter.value}`}
                className={filter.key !== 'relation' && filter.key !== 'relation2' && filter.key !== 'relation3' && filter.key !== 'relation4' && filter.key !== 'relation5' ? 'p-button-text' : 'p-button-text'}
                title='Click to remove this filter'
                style={{ marginRight: 5, verticalAlign: ' middle', fontWeight: !filter.key.includes("relation") ? 'bold' : 'normal', color: 'black', backgroundColor: '#e8f3fc', borderColor: '#cfcfcf' }}>
            </Button>
        })

        let filterZeroHopsItems = this.state.filterZeroHops && this.state.filterZeroHops.length > 0 && this.state.filterZeroHops.map(filter => {
            return <Button
                key={filter.key}
                icon='pi pi-times'
                iconPos='right'
                onClick={(e) => this.handleRemoveZeroHopsFilter(filter.key, e)}
                label={`${filter.key === "relation" ? "Relation 1" : filter.key === "relation2" ? "Relation 2" : filter.key === "relation3" ? "Relation 3"
                    : filter.key === "relation4" ? "Relation 4" : filter.key === "relation5" ? "Relation 5" :
                        filter.key === "start" ? "Entity 1" :
                            filter.key === "end" ? "Entity 2" : filter.key === "end2" ? "Entity 3" : filter.key === "end3" ? "Entity 4" :
                                filter.key === "end4" ? "Entity 5" : filter.key === "end5" ? "Entity 6" : filter.value}: ${filter.value}`}
                className={filter.key !== 'relation' && filter.key !== 'relation2' && filter.key !== 'relation3' && filter.key !== 'relation4' && filter.key !== 'relation5' ? 'p-button-text' : 'p-button-text'}
                title='Click to remove this filter'
                style={{ marginRight: 5, verticalAlign: ' middle', fontWeight: !filter.key.includes("relation") ? 'bold' : 'normal', color: 'black', backgroundColor: '#e8f3fc', borderColor: '#cfcfcf' }}>
            </Button>
        })

        let filterOneHopItems = this.state.filterOneHop && this.state.filterOneHop.length > 0 && this.state.filterOneHop.map(filter => {
            return <Button
                key={filter.key}
                icon='pi pi-times'
                iconPos='right'
                onClick={(e) => this.handleRemoveOneHopFilter(filter.key, e)}
                label={`${filter.key === "relation" ? "Relation 1" : filter.key === "relation2" ? "Relation 2" : filter.key === "relation3" ? "Relation 3"
                    : filter.key === "relation4" ? "Relation 4" : filter.key === "relation5" ? "Relation 5" :
                        filter.key === "start" ? "Entity 1" :
                            filter.key === "end" ? "Entity 2" : filter.key === "end2" ? "Entity 3" : filter.key === "end3" ? "Entity 4" :
                                filter.key === "end4" ? "Entity 5" : filter.key === "end5" ? "Entity 6" : filter.value}: ${filter.value}`}
                className={filter.key !== 'relation' && filter.key !== 'relation2' && filter.key !== 'relation3' && filter.key !== 'relation4' && filter.key !== 'relation5' ? 'p-button-text' : 'p-button-text'}
                title='Click to remove this filter'
                style={{ marginRight: 5, verticalAlign: ' middle', fontWeight: !filter.key.includes("relation") ? 'bold' : 'normal', color: 'black', backgroundColor: '#e8f3fc', borderColor: '#cfcfcf' }}>
            </Button>
        })


        let filterTwoHopsItems = this.state.filterTwoHops && this.state.filterTwoHops.length > 0 && this.state.filterTwoHops.map(filter => {
            return <Button
                key={filter.key}
                icon='pi pi-times'
                iconPos='right'
                onClick={(e) => this.handleRemoveTwoHopsFilter(filter.key, e)}
                label={`${filter.key === "relation" ? "Relation 1" : filter.key === "relation2" ? "Relation 2" : filter.key === "relation3" ? "Relation 3"
                    : filter.key === "relation4" ? "Relation 4" : filter.key === "relation5" ? "Relation 5" :
                        filter.key === "start" ? "Entity 1" :
                            filter.key === "end" ? "Entity 2" : filter.key === "end2" ? "Entity 3" : filter.key === "end3" ? "Entity 4" :
                                filter.key === "end4" ? "Entity 5" : filter.key === "end5" ? "Entity 6" : filter.value}: ${filter.value}`}
                className={filter.key !== 'relation' && filter.key !== 'relation2' && filter.key !== 'relation3' && filter.key !== 'relation4' && filter.key !== 'relation5' ? 'p-button-text' : 'p-button-text'}
                title='Click to remove this filter'
                style={{ marginRight: 5, verticalAlign: ' middle', fontWeight: !filter.key.includes("relation") ? 'bold' : 'normal', color: 'black', backgroundColor: '#e8f3fc', borderColor: '#cfcfcf' }}>
            </Button>
        })

        let filterThreeHopsItems = this.state.filterThreeHops && this.state.filterThreeHops.length > 0 && this.state.filterThreeHops.map(filter => {
            return <Button
                key={filter.key}
                icon='pi pi-times'
                iconPos='right'
                onClick={(e) => this.handleRemoveThreeHopsFilter(filter.key, e)}
                label={`${filter.key === "relation" ? "Relation 1" : filter.key === "relation2" ? "Relation 2" : filter.key === "relation3" ? "Relation 3"
                    : filter.key === "relation4" ? "Relation 4" : filter.key === "relation5" ? "Relation 5" :
                        filter.key === "start" ? "Entity 1" :
                            filter.key === "end" ? "Entity 2" : filter.key === "end2" ? "Entity 3" : filter.key === "end3" ? "Entity 4" :
                                filter.key === "end4" ? "Entity 5" : filter.key === "end5" ? "Entity 6" : filter.value}: ${filter.value}`}
                className={filter.key !== 'relation' && filter.key !== 'relation2' && filter.key !== 'relation3' && filter.key !== 'relation4' && filter.key !== 'relation5' ? 'p-button-text' : 'p-button-text'}
                title='Click to remove this filter'
                style={{ marginRight: 5, verticalAlign: ' middle', fontWeight: !filter.key.includes("relation") ? 'bold' : 'normal', color: 'black', backgroundColor: '#e8f3fc', borderColor: '#cfcfcf' }}>
            </Button>
        })

        let filterFourHopsItems = this.state.filterFourHops && this.state.filterFourHops.length > 0 && this.state.filterFourHops.map(filter => {
            return <Button
                key={filter.key}
                icon='pi pi-times'
                iconPos='right'
                onClick={(e) => this.handleRemoveFourHopsFilter(filter.key, e)}
                label={`${filter.key === "relation" ? "Relation 1" : filter.key === "relation2" ? "Relation 2" : filter.key === "relation3" ? "Relation 3"
                    : filter.key === "relation4" ? "Relation 4" : filter.key === "relation5" ? "Relation 5" :
                        filter.key === "start" ? "Entity 1" :
                            filter.key === "end" ? "Entity 2" : filter.key === "end2" ? "Entity 3" : filter.key === "end3" ? "Entity 4" :
                                filter.key === "end4" ? "Entity 5" : filter.key === "end5" ? "Entity 6" : filter.value}: ${filter.value}`}
                className={filter.key !== 'relation' && filter.key !== 'relation2' && filter.key !== 'relation3' && filter.key !== 'relation4' && filter.key !== 'relation5' ? 'p-button-text' : 'p-button-text'}
                title='Click to remove this filter'
                style={{ marginRight: 5, verticalAlign: ' middle', fontWeight: !filter.key.includes("relation") ? 'bold' : 'normal', color: 'black', backgroundColor: '#e8f3fc', borderColor: '#cfcfcf' }}>
            </Button>
        })

        const directMatchOptions = [
            { label: 'Hide', value: true },
            { label: 'Show', value: false }
        ]

        const relationsOptions = [
            { label: 'Narrow', value: "narrow" },
            { label: 'Broad', value: "broad" }
        ]

        const rowsOptions = [
            { label: '100', value: 100 },
            { label: '500', value: 500 },
            { label: '1,000', value: 1000 },
            //{ label: 'Selected', value: 'selected' },
            { label: 'All (max. 10,000)', value: 'all' }
        ]

        if ((this.state.exportFromPath && this.state.selectedRowsPath && this.state.selectedRowsPath.length > 0)
            || (this.state.exportFromSubPath && this.state.selectedRowsSubPath && this.state.selectedRowsSubPath.length > 0)
            || (this.state.exportFromTriple && this.state.selectedRowsTriple && this.state.selectedRowsTriple.length > 0)
            || (this.state.exportFromHops && this.state.hopsToShow === 1 && this.state.selectedRowsZeroHops && this.state.selectedRowsZeroHops.length > 0)
            || (this.state.exportFromHops && this.state.hopsToShow === 2 && this.state.selectedRowsOneHop && this.state.selectedRowsOneHop.length > 0)
            || (this.state.exportFromHops && this.state.hopsToShow === 3 && this.state.selectedRowsTwoHops && this.state.selectedRowsTwoHops.length > 0)
            || (this.state.exportFromHops && this.state.hopsToShow === 4 && this.state.selectedRowsThreeHops && this.state.selectedRowsThreeHops.length > 0)
            || (this.state.exportFromHops && this.state.hopsToShow === 5 && this.state.selectedRowsFourHops && this.state.selectedRowsFourHops.length > 0)) {
            rowsOptions.splice(3, 0, { label: 'Selected', value: 'selected' })
        }

        /*const entityOptions = [
            { label: 'Only uniques', value: 'unique' },
            //{ label: 'All', value: 'all' }
        ]*/

        let columnOptions = [
            { label: 'Entities col. 1', value: 1 },
            { label: 'Entities col. 2', value: 2 }
        ]

        if (this.state.exportFromPath && this.state.pathNumber && this.state.pathNumber >= 2) {
            for (let i = 1; i < this.state.pathNumber; i++) {
                columnOptions = [...columnOptions, { label: `Entities col. ${i + 2}`, value: `${i + 2}` }]
            }
        } else if (this.state.exportFromSubPath && this.state.subPathNumber && this.state.subPathNumber >= 1) {
            for (let i = 1; i < this.state.subPathNumber + 1; i++) {
                columnOptions = [...columnOptions, { label: `Entities col. ${i + 2}`, value: `${i + 2}` }]
            }
        } else if (this.state.exportFromHops && this.state.hopsToShow && this.state.hopsToShow >= 2) {
            for (let i = 1; i < this.state.hopsToShow; i++) {
                columnOptions = [...columnOptions, { label: `Entities col. ${i + 2}`, value: `${i + 2}` }]
            }
        }

        let tripleOptions = [
            { label: '1', value: 1 }
        ]

        if (this.state.exportFromPath && this.state.pathNumber && this.state.pathNumber >= 2) {
            for (let i = 1; i < this.state.pathNumber; i++) {
                tripleOptions = [...tripleOptions, { label: `${i + 1}`, value: `${i + 1}` }]
            }
        } else if (this.state.exportFromSubPath && this.state.subPathNumber && this.state.subPathNumber >= 1) {
            for (let i = 1; i < this.state.subPathNumber + 1; i++) {
                tripleOptions = [...tripleOptions, { label: `${i + 1}`, value: `${i + 1}` }]
            }
        } else if (this.state.exportFromHops && this.state.hopsToShow && this.state.hopsToShow >= 2) {
            for (let i = 1; i < this.state.hopsToShow; i++) {
                tripleOptions = [...tripleOptions, { label: `${i + 1}`, value: `${i + 1}` }]
            }
        }

        let numberSentencesOptions = [
            { label: '1', value: '1' },
            { label: '5', value: '5' },
            { label: '10', value: '10' }
        ]

        return (
            <div className="grid" style={{ width: '100%' }}>
                <LoadingOverlay
                    active={this.state.fetchingResults}
                    spinner={true}
                    className='fullWidth'>
                    <Toast ref={this.growl} />
                    <div className='col-12' style={{ paddingBottom: 0 }}>
                        <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader">Fact Finder</h1>
                    </div>
                    <div className='col-12' style={{ paddingBottom: 0 }}>
                        {/*<label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}>Semantic knowledge extraction results
                            in information organized into triples (e.g. “sumatriptan” “treats” “migraine headache”).
                            Those triples come from millions of documents of various sources and can be queried individually or they can even be chained together in
                            order to perform a shortest path analysis.
        </label>*/}
                        {this.state.showMore ?
                            <p style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}>Semantic knowledge extraction results
                                in information organized into triples (e.g. “sumatriptan” “treats” “migraine headache”).
                                Those triples come from millions of documents of various sources and can be queried individually or they can even be chained together in
                                order to perform a shortest path analysis.
                                <a
                                    style={{ paddingLeft: 5 }}
                                    title="Click for more information about Fact Finder searches"
                                    onClick={this.showMoreInfo}>More</a>
                            </p>
                            : <p style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}>Semantic knowledge extraction results
                                in information organized into triples (e.g. “sumatriptan” “treats” “migraine headache”).
                                Those triples come from millions of documents of various sources and can be queried individually or they can even be chained together in
                                order to perform a shortest path analysis.
                                You can search for all available triples by limiting to an entity or a relation type. For automatic path building you must enter at least two entities. Then you can search for the shortest path and subsequently extend this triple by adding additional hops automatically in between.
                                <a
                                    style={{ paddingLeft: 5 }}
                                    title="Click to hide more information"
                                    onClick={this.showLessInfo}>Less
                                </a>
                            </p>
                        }
                    </div>
                    <div style={{ marginBottom: 0, paddingTop: 37, paddingLeft: 13, marginLeft: 8, borderBottom: '1px solid #e1e1e1' }}>
                        <InferencePathSearchComponent
                            userDetails={this.props.userDetails}
                            userData={this.props.userData}
                            pathRepositories={this.state.pathRepositories}
                            callBackFromRepositories={this.myCallbackRepositories}
                            pathNumber={this.state.pathNumber}
                            callbackFromAddPath={this.myCallbackFromAddPath}
                            callbackFromStartTermChange={this.myCallbackFromStartTermChange}
                            callbackFromRelationChange={this.myCallbackFromRelationChange}
                            callbackFromEndTermChange={this.myCallbackFromEndTermChange}
                            fetchTermSuggestions={this.props.fetchTermSuggestions}
                            queryNames={this.state.startTerm}
                            relation={this.state.relation}
                            relationOCID={this.state.relationOCID}
                            relationValues={this.state.relationValues}
                            type={this.state.type}
                            terms={this.props.terms}
                            callbackFromTypeRadioChange={this.myCallbackFromTypeRadioChange}
                            callbackFromLayerAdd={this.myCallbackFromLayerAdd}
                            logicals={this.state.logicals}
                            startTerm={this.state.startTerm}
                            startTermShow={this.state.startTermShow}
                            startTermOCID={this.state.startTermOCID}
                            startChecked={this.state.startChecked}
                            endTerm={this.state.endTerm}
                            endTermShow={this.state.endTermShow}
                            endTermOCID={this.state.endTermOCID}
                            endChecked={this.state.endChecked}
                            callbackFromClear={this.myCallbackFromClear}
                            callbackFromRemove={this.myCallbackFromRemove}
                            showChildren={this.state.showChildren}
                            callbackFromStart={this.myCallbackFromStart}
                            callbackFromTripleExecute={this.myCallbackFromTripleExecute}
                            callbackFromSubPathExecute={this.myCallbackFromSubPathExecute}
                            callbackFromStartToggleChange={this.myCallbackFromStartToggleChange}
                            callbackFromRelationToggleChange={this.myCallbackFromRelationToggleChange}
                            callbackFromEndToggleChange={this.myCallbackFromEndToggleChange}
                            selectedBlacklists={this.state.selectedBlacklists}
                            availableDomains={this.props.availableDomains}
                            callbackFromDomainBrowser={this.myCallbackFromDomainBrowser}
                            selectedRepository={this.state.selectedRepository}
                            filterTriple={this.state.filterTriple}
                            filterSub={this.state.filterSub}
                            filterFull={this.state.filterFull}
                            callbackFromRelationMultiChange={this.myCallbackFromRelationMultiChange}
                            callbackFromRelationModeChange={this.myCallbackFromRelationModeChange}
                            relationOCIDMulti={this.state.relationOCIDMulti}
                            relationMode={this.state.relationMode}
                            tripleHits={this.state.tripleHits}
                            subPathHits={this.state.subPathHits}
                            fullPathHits={this.state.fullPathHits}
                            leftDomains={this.state.leftDomains}
                            rightDomains={this.state.rightDomains}
                            leftDomainsShow={this.state.leftDomainsShow}
                            rightDomainsShow={this.state.rightDomainsShow}
                            myCallbackFromExecutePath={this.myCallbackFromExecutePath}
                            executeSinglePath={this.myCallbackFromExecuteSinglePath}
                            filterChecked={this.state.filterChecked}
                            relationsChecked={this.state.relationsChecked}
                            callbackFromFilterCheckedChange={this.myCallbackFromFilterCheckedChange}
                            numberOfHops={this.state.numberOfHops}
                            callbackFromHopNumberChange={this.myCallbackFromHopNumberChange}
                            callbackFromSearchWithHops={this.myCallbackFromSearchWithHops}
                            startTermDomains={this.state.startTermDomains}
                            endTermDomains={this.state.endTermDomains}
                            //showFirstRelation={this.state.showFirstRelation}
                            callbackFromFirstRelationClick={this.myCallbackFromFirstRelationClick}
                            autocompleteOrBrowserStart={this.state.autocompleteOrBrowserStart}
                            autocompleteOrBrowserEnd={this.state.autocompleteOrBrowserEnd}
                            displayConfirmationDialog={this.state.displayConfirmationDialog}
                            callbackFromCloseConfirm={this.callbackFromCloseConfirm}
                            fromEdit={this.state.fromEdit}>
                        </InferencePathSearchComponent>
                        {this.state.pathNumber > 1 &&
                            <div style={{ display: 'flow-root' }}>
                                <label style={{ fontSize: '13px', paddingRight: 5, marginBottom: 10, color: this.state.fullPathHits[this.state.pathNumber - 1] > 0 ? '#757575' : '#d0021b', float: 'right' }}>
                                    <NumberFormat value={this.state.fullPathHits[this.state.pathNumber - 1]} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} /> paths
                                </label>
                            </div>
                        }
                    </div>
                    {/*</Panel>*/}
                    <div className='col-12' style={{ marginBottom: 10, textAlign: 'right' }}>
                        {this.state.pathNumber > 0 &&
                            <div>
                                <div>
                                    {this.state.pathNumber <= 5 //&& this.state.pathNumber > 1 
                                        &&
                                        <React.Fragment>
                                            <div style={{ marginTop: 0 }}>
                                                <label style={{ marginTop: 3, float: 'left' }}>
                                                    Relations:</label>
                                                <Dropdown
                                                    options={relationsOptions}
                                                    value={this.state.relationsChecked}
                                                    onChange={(e) => this.changeRelationsChecked(e.value)}
                                                    style={{ float: 'left', marginTop: -4, paddingRight: 0 }}>
                                                </Dropdown>
                                                <a className="infoIconLink valignMiddle"
                                                    style={{ marginTop: 1, float: 'left' }}
                                                    title="Click for information about narrow and broad relations"
                                                    onClick={e => this.recallInfoOverlay.toggle(e)}>
                                                    <img src={infoIcon} alt="Relations information"
                                                        style={{ marginLeft: 0, paddingRight: 20 }} />
                                                </a>
                                                <OverlayPanel
                                                    //className="removeArrow"
                                                    ref={(el) => this.recallInfoOverlay = el}
                                                    dismissable={true}>
                                                    <div style={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }}>
                                                        <p>
                                                            Narrow relation types are unidirect and causal relationships. Broad relation types contain in addition also symmetric and non-causal relationships.
                                                        </p>
                                                    </div>
                                                </OverlayPanel>
                                            </div>
                                            <div style={{ marginTop: 0 }}>
                                                <label style={{ marginTop: 3, float: 'left' }}>
                                                    Direct matches:</label>
                                                <Dropdown
                                                    options={directMatchOptions}
                                                    value={this.state.filterChecked}
                                                    onChange={(e) => this.setState({ filterChecked: e.value })}
                                                    style={{ float: 'left', marginTop: -4, paddingRight: 5 }}
                                                    disabled={this.state.startTerm[0] !== "" && this.state.startTerm[0].length > 0 &&
                                                        this.state.endTerm[this.state.pathNumber - 1] !== "" && this.state.endTerm[this.state.pathNumber - 1].length > 0
                                                        //&& this.state.fullPathHits[this.state.pathNumber - 1] > 0 
                                                        ? false : true}
                                                    title={(this.state.startTerm[0] === "" || this.state.startTerm[0].length === 0 ||
                                                        this.state.endTerm[this.state.pathNumber - 1] === "" || this.state.endTerm[this.state.pathNumber - 1].length === 0
                                                        //|| this.state.fullPathHits[this.state.pathNumber - 1] === 0
                                                    )
                                                        ? "Define at least first and last entities. Number of hits for full path have to be > 0." : this.state.filterChecked ? "Filter direct matches." : "Do not filter direct matches."}>
                                                </Dropdown>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {hasUserRole('ROLE_BLACKLISTS') ?
                                        <React.Fragment>
                                            <label style={{ float: 'left', marginTop: 3 }}>Blocklists:</label>
                                            <MultiSelect className='inferenceMultiSelect'
                                                pt={{
                                                    checkboxIcon: {
                                                        onClick: (e) => {
                                                            e.stopPropagation();
                                                            if (e.target.className.baseVal !== "") {
                                                                e.target.parentNode.click();
                                                            } else {
                                                                e.target.parentNode.parentNode.click();
                                                            }
                                                        },
                                                    },
                                                    headerCheckbox: {
                                                        onClick: (e) => {
                                                            e.stopPropagation();
                                                            e.target.parentNode.click();
                                                        },
                                                    },
                                                }}
                                                value={this.state.selectedBlacklists}
                                                options={this.state.pathBlacklists}
                                                onChange={(e) => this.myCallbackFromBlacklistChange(e)}
                                                style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none', float: 'left', color: 'black', marginTop: -2, paddingRight: 10 }}
                                                disabled={this.state.pathBlacklists.length > 0 ? false : true}
                                                //filter
                                                //filterPlaceholder="Search"
                                                placeholder={"Select blocklists"} />
                                            {/*
                                                <Button
                                                    icon='pi pi-external-link'
                                                    iconPos='right'
                                                    onClick={(e) => this.openBlocklists(e)}
                                                    label='Manage'
                                                    className='p-button-text'
                                                    title='Open My Blocklists in new tab'
                                                    style={{ marginRight: 5, verticalAlign: ' middle', color: '#0973c0', float: 'left', marginTop: -8 }}>
                                                </Button>
                                            */}
                                            <Link to="/my/blocklists" target="_blank" title='Open My Blocklists in new tab'
                                                style={{ marginRight: 5, verticalAlign: ' middle', float: 'left', marginTop: 3 }}>
                                                Manage <span className="extLinkIcon">&#8599;</span>
                                            </Link>
                                        </React.Fragment>
                                        : null}
                                </div>
                                <a title="Clear all"
                                    onClick={() => this.setState({ displayConfirmationDialog: true })}
                                    style={{ verticalAlign: '50%', display: 'inline-block' }}>Clear all</a>
                                {/*this.state.editQuery ?
                                    <a title="Save current search"
                                        onClick={() => this.onSaveQuery()}
                                        style={{ marginRight: 30, verticalAlign: '50%', display: 'inline-block' }}>Save as new</a>
                                    : <a title="Save current search"
                                        onClick={() => this.onSaveQuery()}
                                        style={{ marginRight: 30, verticalAlign: '50%', display: 'inline-block' }}>Save search</a>}

                                {this.state.editQuery ?
                                    <a title="Save and overwrite current search"
                                        onClick={() => this.onEditQuery()}
                                        style={{ marginRight: 30, verticalAlign: '50%', display: 'inline-block' }}>Save and overwrite search</a>
                                : null*/}

                                <span style={{ verticalAlign: '50%' }}>
                                    <SeparatorPoint />
                                    <SaveSearchButtons
                                        savedSearchExists={!!this.state.editQueryID}
                                        editableQuery={this.state.editQuery}
                                        onSaveSearch={(editQuery) => { editQuery ? this.onEditQuery() : this.onSaveQuery() }}
                                        savedSearchName={this.state.queryFullNameEdit}
                                    />
                                </span>

                                <Button label={this.state.pathNumber === 1 && this.state.startTerm[0].length !== 0 && this.state.endTerm[0].length !== 0 && (this.state.relationOCID[0] === "" ||
                                    this.state.relationOCID[0].length === 0) ? "Search shortest path" : "Search"}
                                    className='p-button-sm primaryButton'
                                    style={{ marginLeft: 30, marginRight: -7 }}
                                    onClick={this.handleSearch}
                                    //icon="pi pi-search"
                                    disabled={false} />
                            </div>
                        }
                    </div>
                    {this.state.showResults && this.state.pathNumber > 0 &&
                        <div className={`col-12 ${this.state.queryChanged ? 'disableContent' : ''}`} ref={this.table}>
                            <TabView style={{ paddingBottom: 50 }} activeIndex={this.state.activeIndex} onTabChange={(e) => this.onTabChange(e)}>
                                <TabPanel header="Shortest path" disabled={this.state.disabledHops ? true : false}>
                                    <div className='col-12'>
                                        {this.state.hopsToShow === 1 ?
                                            this.state.filterZeroHops && this.state.filterZeroHops.length > 0 ?
                                                <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                    <label style={{ verticalAlign: 'textBottom' }}>Filters: </label>
                                                    {filterZeroHopsItems}
                                                </div>
                                                : null
                                            :
                                            this.state.hopsToShow === 2 ?
                                                this.state.filterOneHop && this.state.filterOneHop.length > 0 ?
                                                    <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                        <label style={{ verticalAlign: 'textBottom' }}>Filters: </label>
                                                        {filterOneHopItems}
                                                    </div>
                                                    : null :
                                                this.state.hopsToShow === 3 ?
                                                    this.state.filterTwoHops && this.state.filterTwoHops.length > 0 ?
                                                        <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                            <label style={{ verticalAlign: 'textBottom' }}>Filters: </label>
                                                            {filterTwoHopsItems}
                                                        </div>
                                                        : null :
                                                    this.state.hopsToShow === 4 ?
                                                        this.state.filterThreeHops && this.state.filterThreeHops.length > 0 ?
                                                            <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                                <label style={{ verticalAlign: 'textBottom' }}>Filters: </label>
                                                                {filterThreeHopsItems}
                                                            </div>
                                                            : null :
                                                        this.state.filterFourHops && this.state.filterFourHops.length > 0 ?
                                                            <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                                <label style={{ verticalAlign: 'textBottom' }}>Filters: </label>
                                                                {filterFourHopsItems}
                                                            </div>
                                                            : null
                                        }
                                        <InferenceResultTable
                                            userData={this.props.userData}
                                            userDetails={this.props.userDetails}
                                            departmentMembers={this.state.departmentMembers}
                                            orgArray={this.state.orgArray}
                                            userArray={this.state.userArray}

                                            usersWrite={this.state.usersWrite}
                                            usersShared={this.state.usersShared}
                                            sharedUsers={this.state.sharedUsers}
                                            checkedKeys={this.state.checkedKeys}
                                            checkedOrgKeys={this.state.checkedOrgKeys}
                                            handleMemberChange={this.handleMemberChange}
                                            onWriteMembersChange={this.onWriteMembersChange}
                                            displayDialogAddBlacklist={this.state.displayDialogAddBlacklist}
                                            newBlacklistName={this.state.newBlacklistName}
                                            newBlacklistDescription={this.state.newBlacklistDescription}
                                            updateBlacklistName={this.updateBlacklistName}
                                            updateBlacklistDescription={this.updateBlacklistDescription}
                                            addNewBlacklist={this.addNewBlacklist}
                                            hideAddBlacklist={this.hideAddBlacklist}
                                            onSaveBlacklist={this.onSaveBlacklist}

                                            startTerm={this.state.startTerm}
                                            prefNamesHops={this.state.hopsToShow === 1 ?
                                                this.state.preferredNamesHops[0] :
                                                this.state.hopsToShow === 2 ?
                                                    this.state.preferredNamesHops[1] :
                                                    this.state.hopsToShow === 3 ?
                                                        this.state.preferredNamesHops[2] :
                                                        this.state.hopsToShow === 4 ?
                                                            this.state.preferredNamesHops[3] :
                                                            this.state.preferredNamesHops[4]}
                                            matchedPathsHops={this.state.hopsToShow === 1 ?
                                                this.state.pathsHops[0] :
                                                this.state.hopsToShow === 2 ?
                                                    this.state.pathsHops[1] :
                                                    this.state.hopsToShow === 3 ?
                                                        this.state.pathsHops[2] :
                                                        this.state.hopsToShow === 4 ?
                                                            this.state.pathsHops[3] :
                                                            this.state.pathsHops[4]}
                                            matchedPathsCountHops={this.state.hopsToShow === 1 ?
                                                this.state.countsHops[0] :
                                                this.state.hopsToShow === 2 ?
                                                    this.state.countsHops[1] :
                                                    this.state.hopsToShow === 3 ?
                                                        this.state.countsHops[2] :
                                                        this.state.hopsToShow === 4 ?
                                                            this.state.countsHops[3] :
                                                            this.state.countsHops[4]}
                                            fromTriple={false}
                                            fromSubPath={false}
                                            fromPath={false}
                                            fromHops={true}
                                            selectedRowsHops={this.state.hopsToShow === 1 ?
                                                this.state.selectedRowsZeroHops :
                                                this.state.hopsToShow === 2 ?
                                                    this.state.selectedRowsOneHop :
                                                    this.state.hopsToShow === 3 ?
                                                        this.state.selectedRowsTwoHops :
                                                        this.state.hopsToShow === 4 ?
                                                            this.state.selectedRowsThreeHops :
                                                            this.state.selectedRowsFourHops}
                                            header='triple with hops'
                                            //fullPathNumber={this.state.fullPathNumber}
                                            callbackFromExport={this.myCallbackFromExport}
                                            rerenderTable={this.state.rerenderTable}
                                            blacklists={this.state.blacklists}
                                            callbackFromBlacklistConcepts={this.myCallbackFromBlacklistConcepts}
                                            callbackFromFilter={this.myCallbackFromFilter}
                                            hopsOfTable={this.state.hopsOfTable}
                                            hopsToShow={this.state.hopsToShow}
                                            filterHops={this.state.hopsToShow === 1 ?
                                                this.state.filterZeroHops :
                                                this.state.hopsToShow === 2 ?
                                                    this.state.filterOneHop :
                                                    this.state.hopsToShow === 3 ?
                                                        this.state.filterTwoHops :
                                                        this.state.hopsToShow === 4 ?
                                                            this.state.filterThreeHops :
                                                            this.state.filterFourHops}
                                            callbackFromDropdownHopChange={this.myCallbackFromDropdownHopChange}
                                            rowsHops={this.state.hopsToShow === 1 ?
                                                this.state.countZeroHopsPath :
                                                this.state.hopsToShow === 2 ?
                                                    this.state.countOneHopPath :
                                                    this.state.hopsToShow === 3 ?
                                                        this.state.countTwoHopsPath :
                                                        this.state.hopsToShow === 4 ?
                                                            this.state.countThreeHopsPath :
                                                            this.state.countFourHopsPath}
                                            firstHops={this.state.hopsToShow === 1 ?
                                                this.state.startZeroHopsPath :
                                                this.state.hopsToShow === 2 ?
                                                    this.state.startOneHopPath :
                                                    this.state.hopsToShow === 3 ?
                                                        this.state.startTwoHopsPath :
                                                        this.state.hopsToShow === 4 ?
                                                            this.state.startThreeHopsPath :
                                                            this.state.startFourHopsPath}
                                            callbackFromPageChange={this.myCallbackFromPageChange}
                                            checkedEntities={this.state.checkedEntities}
                                            callbackFromPathSelect={this.myCallbackFromPathSelect}
                                            callbackFromCheckedEntityChange={this.myCallbackFromCheckedEntityChange}
                                            callbackFromRemoveSelectedEntities={this.myCallbackFromRemoveSelectedEntities}
                                            handleSelectionChange={this.handleSelectionChange}
                                            displayDialogAddToBlacklist={this.state.displayDialogAddToBlacklist}
                                            closeAddToBlocklistDialog={this.closeAddToBlocklistDialog}>
                                        </InferenceResultTable>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Full path" disabled={this.state.disabledFull ? true : false}>
                                    <div className='col-12' ref={this.table}>
                                        {this.state.filterFull && this.state.filterFull.length > 0 ?
                                            <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                <label style={{ verticalAlign: 'textBottom' }}>Filters: </label>
                                                {filterFullItems}
                                            </div>
                                            : null}
                                        <InferenceResultTable
                                            userData={this.props.userData}
                                            userDetails={this.props.userDetails}
                                            departmentMembers={this.state.departmentMembers}
                                            orgArray={this.state.orgArray}
                                            userArray={this.state.userArray}

                                            usersWrite={this.state.usersWrite}
                                            usersShared={this.state.usersShared}
                                            sharedUsers={this.state.sharedUsers}
                                            checkedKeys={this.state.checkedKeys}
                                            checkedOrgKeys={this.state.checkedOrgKeys}
                                            handleMemberChange={this.handleMemberChange}
                                            onWriteMembersChange={this.onWriteMembersChange}
                                            displayDialogAddBlacklist={this.state.displayDialogAddBlacklist}
                                            newBlacklistName={this.state.newBlacklistName}
                                            newBlacklistDescription={this.state.newBlacklistDescription}
                                            updateBlacklistName={this.updateBlacklistName}
                                            updateBlacklistDescription={this.updateBlacklistDescription}
                                            addNewBlacklist={this.addNewBlacklist}
                                            hideAddBlacklist={this.hideAddBlacklist}
                                            onSaveBlacklist={this.onSaveBlacklist}

                                            startTerm={this.state.startTerm}
                                            prefNames={this.state.prefNames}
                                            matchedPaths={this.state.matchedPaths}
                                            matchedPathsCount={this.state.matchedPathsCount}
                                            fromTriple={false}
                                            fromSubPath={false}
                                            fromPath={true}
                                            fromHops={false}
                                            selectedRowsPath={this.state.selectedRowsPath}
                                            header='full path'
                                            fullPathNumber={this.state.fullPathNumber}
                                            callbackFromExport={this.myCallbackFromExport}
                                            rerenderTable={this.state.rerenderTable}
                                            blacklists={this.state.blacklists}
                                            callbackFromBlacklistConcepts={this.myCallbackFromBlacklistConcepts}
                                            callbackFromFilter={this.myCallbackFromFilter}
                                            filterFull={this.state.filterFull}
                                            callbackFromDropdownHopChange={this.myCallbackFromDropdownHopChange}
                                            rowsFull={this.state.countFullPath}
                                            firstFull={this.state.startFullPath}
                                            callbackFromPageChange={this.myCallbackFromPageChange}
                                            checkedEntities={this.state.checkedEntities}
                                            callbackFromPathSelect={this.myCallbackFromPathSelect}
                                            callbackFromCheckedEntityChange={this.myCallbackFromCheckedEntityChange}
                                            callbackFromRemoveSelectedEntities={this.myCallbackFromRemoveSelectedEntities}
                                            handleSelectionChange={this.handleSelectionChange}
                                            displayDialogAddToBlacklist={this.state.displayDialogAddToBlacklist}
                                            closeAddToBlocklistDialog={this.closeAddToBlocklistDialog}>
                                        </InferenceResultTable>
                                    </div>
                                </TabPanel>
                                <TabPanel header={this.state.tripleNumber >= 1 ? `Triple ${this.state.tripleNumber}` : `Triple`} disabled={this.state.disabledTriple ? true : false}>
                                    <div className='col-12' ref={this.table}>
                                        {this.state.filterTriple && this.state.filterTriple.length > 0 ?
                                            <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                <label style={{ verticalAlign: 'textBottom' }}>Filters: </label>
                                                {filterTripleItems}
                                            </div>
                                            : null}
                                        <InferenceResultTable
                                            userData={this.props.userData}
                                            userDetails={this.props.userDetails}
                                            departmentMembers={this.state.departmentMembers}
                                            orgArray={this.state.orgArray}
                                            userArray={this.state.userArray}

                                            usersWrite={this.state.usersWrite}
                                            usersShared={this.state.usersShared}
                                            sharedUsers={this.state.sharedUsers}
                                            checkedKeys={this.state.checkedKeys}
                                            checkedOrgKeys={this.state.checkedOrgKeys}
                                            handleMemberChange={this.handleMemberChange}
                                            onWriteMembersChange={this.onWriteMembersChange}
                                            displayDialogAddBlacklist={this.state.displayDialogAddBlacklist}
                                            newBlacklistName={this.state.newBlacklistName}
                                            newBlacklistDescription={this.state.newBlacklistDescription}
                                            updateBlacklistName={this.updateBlacklistName}
                                            updateBlacklistDescription={this.updateBlacklistDescription}
                                            addNewBlacklist={this.addNewBlacklist}
                                            hideAddBlacklist={this.hideAddBlacklist}
                                            onSaveBlacklist={this.onSaveBlacklist}

                                            startTerm={this.state.startTerm}
                                            prefNamesTriple={this.state.matchedTriplePrefNames}
                                            matchedPathsTriple={this.state.matchedTriplePaths}
                                            matchedTriplePathCount={this.state.matchedTriplePathCount}
                                            fromTriple={true}
                                            fromSubPath={false}
                                            fromPath={false}
                                            fromHops={false}
                                            selectedRowsTriple={this.state.selectedRowsTriple}
                                            header={`triple ${this.state.tripleNumber}`}
                                            callbackFromExport={this.myCallbackFromExport}
                                            rerenderTable={this.state.rerenderTable}
                                            callbackFromPathSelect={this.myCallbackFromPathSelect}
                                            blacklists={this.state.blacklists}
                                            callbackFromBlacklistConcepts={this.myCallbackFromBlacklistConcepts}
                                            callbackFromFilter={this.myCallbackFromFilter}
                                            filterTriple={this.state.filterTriple}
                                            callbackFromDropdownHopChange={this.myCallbackFromDropdownHopChange}
                                            rowsTriple={this.state.countTriplePath}
                                            firstTriple={this.state.startTriplePath}
                                            callbackFromPageChange={this.myCallbackFromPageChange}
                                            checkedEntities={this.state.checkedEntities}
                                            callbackFromCheckedEntityChange={this.myCallbackFromCheckedEntityChange}
                                            callbackFromRemoveSelectedEntities={this.myCallbackFromRemoveSelectedEntities}
                                            handleSelectionChange={this.handleSelectionChange}
                                            displayDialogAddToBlacklist={this.state.displayDialogAddToBlacklist}
                                            closeAddToBlocklistDialog={this.closeAddToBlocklistDialog}>
                                        </InferenceResultTable>
                                    </div>
                                </TabPanel>
                                <TabPanel header={this.state.subPathNumber >= 1 ? `Sub path up to triple ${this.state.subPathNumber + 1}` : `Sub path`} disabled={this.state.disabledSubPath ? true : false}>
                                    <div className='col-12' ref={this.table}>
                                        {this.state.filterSub && this.state.filterSub.length > 0 ?
                                            <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                <label style={{ verticalAlign: 'textBottom' }}>Filters: </label>
                                                {filterSubItems}
                                            </div>
                                            : null}
                                        <InferenceResultTable
                                            userData={this.props.userData}
                                            userDetails={this.props.userDetails}
                                            departmentMembers={this.state.departmentMembers}
                                            orgArray={this.state.orgArray}
                                            userArray={this.state.userArray}

                                            usersWrite={this.state.usersWrite}
                                            usersShared={this.state.usersShared}
                                            sharedUsers={this.state.sharedUsers}
                                            checkedKeys={this.state.checkedKeys}
                                            checkedOrgKeys={this.state.checkedOrgKeys}
                                            handleMemberChange={this.handleMemberChange}
                                            onWriteMembersChange={this.onWriteMembersChange}
                                            displayDialogAddBlacklist={this.state.displayDialogAddBlacklist}
                                            newBlacklistName={this.state.newBlacklistName}
                                            newBlacklistDescription={this.state.newBlacklistDescription}
                                            updateBlacklistName={this.updateBlacklistName}
                                            updateBlacklistDescription={this.updateBlacklistDescription}
                                            addNewBlacklist={this.addNewBlacklist}
                                            hideAddBlacklist={this.hideAddBlacklist}
                                            onSaveBlacklist={this.onSaveBlacklist}

                                            startTerm={this.state.startTerm}
                                            prefNamesSubPath={this.state.matchedSubPrefNames}
                                            matchedPathsSubPaths={this.state.matchedSubPaths}
                                            matchedSubPathCount={this.state.matchedSubPathCount}
                                            subPathNumber={this.state.subPathNumber}
                                            fromTriple={false}
                                            fromSubPath={true}
                                            fromPath={false}
                                            fromHops={false}
                                            selectedRowsSubPath={this.state.selectedRowsSubPath}
                                            header={`sub path up to triple ${this.state.subPathNumber + 1}`}
                                            callbackFromExport={this.myCallbackFromExport}
                                            rerenderTable={this.state.rerenderTable}
                                            blacklists={this.state.blacklists}
                                            callbackFromBlacklistConcepts={this.myCallbackFromBlacklistConcepts}
                                            callbackFromFilter={this.myCallbackFromFilter}
                                            filterSub={this.state.filterSub}
                                            callbackFromDropdownHopChange={this.myCallbackFromDropdownHopChange}
                                            rowsSub={this.state.countSubPath}
                                            firstSub={this.state.startSubPath}
                                            callbackFromPageChange={this.myCallbackFromPageChange}
                                            checkedEntities={this.state.checkedEntities}
                                            callbackFromPathSelect={this.myCallbackFromPathSelect}
                                            callbackFromCheckedEntityChange={this.myCallbackFromCheckedEntityChange}
                                            callbackFromRemoveSelectedEntities={this.myCallbackFromRemoveSelectedEntities}
                                            handleSelectionChange={this.handleSelectionChange}
                                            displayDialogAddToBlacklist={this.state.displayDialogAddToBlacklist}
                                            closeAddToBlocklistDialog={this.closeAddToBlocklistDialog}>
                                        </InferenceResultTable>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                    }
                    <Dialog visible={this.state.displayExportDialog} style={{ width: '55vw' }} header={`Export`}
                        modal={true} dismissableMask={true} focusOnShow={false}
                        onHide={() => this.setState({ displayExportDialog: false })} className='styledDialog'>
                        <LoadingOverlay
                            active={this.state.fetchingResults}
                            spinner={true}
                            text="Export in progress..." >
                            <div className='col-12' style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 30, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                <TabView activeIndex={this.state.activeIndexExport} onTabChange={(e) => this.setState({ activeIndexExport: e.index })}>
                                    <TabPanel header="Whole path">
                                        <div className='col-12'>
                                            <div className='col-12' style={{ paddingBottom: 35, paddingTop: 0 }}>
                                                <div className='col-12' style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, marginLeft: -12 }}>
                                                    <label style={{ marginTop: -1 }}>
                                                        Rows (max.):</label>
                                                    <Dropdown
                                                        appendTo={document.body}
                                                        options={rowsOptions}
                                                        value={this.state.rowsPaths}
                                                        onChange={(e) => this.setState({ rowsPaths: e.value })}
                                                        style={{ marginTop: -8, paddingRight: 5 }}
                                                        title='Choose max. number of rows'>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            {/*<div className='col-12' style={{ paddingBottom: 80 }}>
                                            <label style={{ display: 'inline', paddingRight: 8 }}>Export format:</label>
                                            {
                                                this.formats.map((format) => {
                                                    return (
                                                        <div style={{ display: 'inline' }} key={format.key} className="p-field-radiobutton">
                                                            <RadioButton inputId={format.key} name="format" value={format} onChange={(e) => this.setState({ selectedFormatPaths: e.value })} checked={this.state.selectedFormatPaths.key === format.key} />
                                                            <label style={{ color: 'black', marginRight: 10 }} htmlFor={format.key}>{format.name}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>*/}
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Entities">
                                        <div className='col-12'>
                                            <div className='col-12 grid' style={{ paddingBottom: 35 }}>
                                                <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6' style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, marginLeft: -5 }}>
                                                    <label style={{ marginTop: -1 }}>
                                                        Rows (max.):</label>
                                                    <Dropdown
                                                        appendTo={document.body}
                                                        options={rowsOptions}
                                                        value={this.state.rowsEntities}
                                                        onChange={(e) => this.setState({ rowsEntities: e.value })}
                                                        style={{ marginTop: -8, paddingRight: 5 }}
                                                        title='Choose max. number of rows'>
                                                    </Dropdown>
                                                </div>
                                                <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6' style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    <label style={{ marginTop: -1 }}>
                                                        Columns:</label>
                                                    <Dropdown
                                                        appendTo={document.body}
                                                        options={columnOptions}
                                                        value={this.state.selectedColumnOption}
                                                        onChange={(e) => this.setState({ selectedColumnOption: e.value })}
                                                        style={{ marginTop: -8, paddingRight: 5 }}
                                                        title='Choose column'>
                                                    </Dropdown>
                                                </div>
                                                {/*<div className='col-4' style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <label style={{ marginTop: -1 }}>
                                                    Entities:</label>
                                                <Dropdown
                                                    appendTo={document.body}
                                                    options={entityOptions}
                                                    value={this.state.selectedEntityOption}
                                                    onChange={(e) => this.setState({ selectedEntityOption: e.value })}
                                                    style={{ marginTop: -8, paddingRight: 5 }}
                                                    title='Choose if unique or all entites are exported'>
                                                </Dropdown>
                                    </div>*/}
                                            </div>
                                            {/*<div className='col-12' style={{ paddingBottom: 80 }}>
                                            <label style={{ display: 'inline', paddingRight: 8 }}>Export format:</label>
                                            {
                                                this.formats.map((format) => {
                                                    return (
                                                        <div style={{ display: 'inline' }} key={format.key} className="p-field-radiobutton">
                                                            <RadioButton inputId={format.key} name="format" value={format} onChange={(e) => this.setState({ selectedFormatEntities: e.value })} checked={this.state.selectedFormatEntities.key === format.key} />
                                                            <label style={{ color: 'black', marginRight: 10 }} htmlFor={format.key}>{format.name}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>*/}
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Evidence">
                                        <div className='col-12'>
                                            <div className='col-12' style={{ paddingBottom: 35 }}>
                                                <div className='col-12 grid' style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, marginLeft: -19 }}>
                                                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4' style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        <label style={{ marginTop: -1 }}>
                                                            Rows (max.):</label>
                                                        <Dropdown
                                                            appendTo={document.body}
                                                            options={rowsOptions}
                                                            value={this.state.rowsSentences}
                                                            onChange={(e) => this.setState({ rowsSentences: e.value })}
                                                            style={{ marginTop: -8, paddingRight: 5 }}
                                                            title='Choose max. number of rows'>
                                                        </Dropdown>
                                                    </div>
                                                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4' style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        <label style={{ marginTop: -1 }}>
                                                            Triple:</label>
                                                        <Dropdown
                                                            appendTo={document.body}
                                                            disabled={this.state.exportFromTriple || (this.state.exportFromPath && this.state.pathNumber === 1)
                                                                || (this.state.exportFromHops && this.state.hopsToShow === 1) ? true : false}
                                                            options={tripleOptions}
                                                            value={this.state.selectedTriple}
                                                            onChange={(e) => this.setState({ selectedTriple: e.value })}
                                                            style={{ marginTop: -8, paddingRight: 5 }}
                                                            title='Choose triple'>
                                                        </Dropdown>
                                                    </div>
                                                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4' style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        <label style={{ marginTop: -1 }}>
                                                            Max. evidence:</label>
                                                        <Dropdown
                                                            appendTo={document.body}
                                                            options={numberSentencesOptions}
                                                            value={this.state.selectedNumberSentences}
                                                            onChange={(e) => this.setState({ selectedNumberSentences: e.value })}
                                                            style={{ marginTop: -8, paddingRight: 5 }}
                                                            title='Choose max. number of evidence'>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className='col-12' style={{ paddingBottom: 80 }}>
                                            <label style={{ display: 'inline', paddingRight: 8 }}>Export format:</label>
                                            {
                                                this.formats.map((format) => {
                                                    return (
                                                        <div style={{ display: 'inline' }} key={format.key} className="p-field-radiobutton">
                                                            <RadioButton inputId={format.key} name="format" value={format} onChange={(e) => this.setState({ selectedFormatSentences: e.value })} checked={this.state.selectedFormatSentences.key === format.key} />
                                                            <label style={{ color: 'black', marginRight: 10 }} htmlFor={format.key}>{format.name}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>*/}
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                            <div style={{ textAlign: "right", paddingTop: 10, paddingRight: 20, paddingBottom: 5 }}>
                                <Button label="Cancel"
                                    style={{ marginRight: 10 }}
                                    className='p-button-secondary p-button-sm'
                                    onClick={(e) => this.setState({ displayExportDialog: false })}
                                />
                                <Button label="Export"
                                    className='p-button-sm primaryButton'
                                    onClick={(e) => this.handleExport(e)}
                                />
                            </div>
                        </LoadingOverlay>
                    </Dialog>
                    <SaveSearchDialog
                        displayDialogStoreQuery={this.state.displaySaveQueryDialog}
                        editQuery={this.state.fromEditQuery}
                        queryString={this.state.queryString}
                        savedSearchID={this.state.editQueryID}
                        allowAlerts={false}
                        onSubmit={this.onStoreQuery}
                        onHide={this.onHideSaveQuery}
                        userData={this.props.userData}
                    />
                    <Toast ref={this.toastBC} position="bottom-center" style={{ backgroundColor: 'white', opacity: '1', marginBottom: 45, boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }} />
                    <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                        onHide={this.myCallbackFromRemoveSelectedEntities} style={{ width: '24rem', bottom: 25 }}
                        draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                        <ToastContent selectedElements={this.state.checkedEntities}
                            elementLabel='concept'
                            onHide={this.myCallbackFromRemoveSelectedEntities}
                            activeOptions={['addToBlocklist']}
                            onAddToBlocklist={this.handleAddToBlocklistDialog} />
                    </Dialog>
                </LoadingOverlay>
            </div>
        );
    }
}

export default InferenceSearch;