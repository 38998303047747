import { HiDuplicate } from "react-icons/hi";


const DuplicatesColumn = ({ isDuplicate }) => {

  return isDuplicate ?
    <div className="flex align-items-center justify-content-center h-full">
      <HiDuplicate
        title="Other query term found which translates to the same query string. You can delete this entry."
        style={{ width: 18, height: 18, marginLeft: 0 }}
      />
    </div> : null;
}

export default DuplicatesColumn;