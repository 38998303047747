import { Tooltip } from "primereact/tooltip";

const MatchingDocumentsStatistic = ({
    id,
    totalCount,
    matchingDocumentCount,
    similarDocumentCount,
    includeSimilarDocuments
}) => {

    const exactIsGreater = matchingDocumentCount > similarDocumentCount;
    const exactCountPercentage = matchingDocumentCount / totalCount * 100;
    const similarCountPercentage = similarDocumentCount / totalCount * 100;
    const exactCountPercentageRounded = exactIsGreater ? Math.floor(exactCountPercentage) : Math.ceil(exactCountPercentage);
    const similarCountPercentageRounded = exactIsGreater ? Math.ceil(similarCountPercentage) : Math.floor(similarCountPercentage);
    const foundDocumentsPercentage = exactCountPercentageRounded + (includeSimilarDocuments ? similarCountPercentageRounded : 0);
    const noMatchCount = totalCount - matchingDocumentCount - similarDocumentCount;
    const noMatchCountPercentage = 100 - exactCountPercentageRounded - similarCountPercentageRounded;

    return <>
        <div style={{ display: 'inline-flex', width: '100%', margin: '10px 0px 5px 0px' }}>
            <div id={`statistics_${id}`}
                className="document-match-bars" style={{ maxWidth: '100%', minWidth: 100, marginRight: 10, marginTop: 0 }}>
                <div className="document-match-bar documents-bg-color exact"
                    style={{ width: `${exactCountPercentageRounded}%` }}>
                </div>
                <div className="document-match-bar documents-bg-color similar"
                    style={{ width: `${similarCountPercentageRounded}%` }}>
                </div>
                <div className="document-match-bar documents-bg-color none"
                    style={{ width: `${noMatchCountPercentage}%` }}>
                </div>
            </div>

            <div>{foundDocumentsPercentage}% found</div>
        </div>

        <Tooltip target={`#statistics_${id}`} baseZIndex={10}>
            <p>{`Matching documents found for ${matchingDocumentCount} of ${totalCount} IDs (${exactCountPercentageRounded}%).`}</p>
            {includeSimilarDocuments && <p>{`Similar documents found for ${similarDocumentCount} of ${totalCount} IDs (${similarCountPercentageRounded}%).`}</p>}
            <p>{`No documents found for ${noMatchCount} of ${totalCount} IDs (${noMatchCountPercentage}%).`}</p>
        </Tooltip>
    </>
}

export default MatchingDocumentsStatistic;