import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { Dropdown } from "primereact/dropdown";


class FilterSectionDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }

    render() {

        const { filterID, filterEntries, placeholder, selectedEntry, filterInfo, onFilterInputValuesChange, showClear = true } = this.props;
        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className="grid">
                    <div className="col">
                        {filterEntries ?
                            <div key={filterID} style={{ marginLeft: 0 }}>
                                <Dropdown
                                    className={`width100perc withClearIcon withBorder ${selectedEntry ? 'inputFilled' : ''}`}
                                    value={selectedEntry}
                                    options={filterEntries}
                                    //optionValue="value"
                                    optionLabel="filterValueLabel"
                                    onChange={(e) => {
                                        //console.log('e: ', e.value);
                                        const filterObject = e.value ? {
                                            filterID: filterID,
                                            value: e.value
                                        } : {};
                                        onFilterInputValuesChange(filterObject);
                                    }}
                                    placeholder={placeholder}
                                    showClear={showClear}
                                    scrollHeight="400px" />
                            </div>
                            : null
                        }
                    </div>
                    {filterInfo ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {filterInfo}
                        </div> : null}
                </div>
            </>
        );
    }
}

export default FilterSectionDropdown;