import { Component } from 'react';
import DocumentMetadataInfo from '../../../docparts/DocumentMetadataInfo';
import DocumentTitle from '../../../docparts/DocumentTitle';
import ReactionSourceSnippet from './ReactionSourceSnippet';


class ResultDocument extends Component {

    render() {

        const { document, reactionData, repositoryInfo, onOpenDocument } = this.props;

        return document ?
            <div style={{ marginTop: 10, marginBottom: 10 }}>

                <DocumentTitle
                    document={document}
                    onOpenDocument={onOpenDocument}
                />

                <DocumentMetadataInfo
                    repositoryInfo={repositoryInfo}
                    documentData={document?.docMetadata}
                />

                <ReactionSourceSnippet
                    reactionData={reactionData}
                />
            </div>
            :
            null
    }
}
export default ResultDocument