import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { getHeaderToken } from '../../api/index'
import axios from 'axios'
import { APP_PROPERTIES } from '../../properties/index';

class MoveUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            moveUsersChecked: false,
            selectedCompany: null,
            selectedDepartment: null
        }
    }

    componentDidMount() {
        if (this.props.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN') {
            let selectedCompany = this.props.selectedCompany
            this.setState({
                selectedCompany: selectedCompany,
                departments: this.props.departments
            })
        }
        if (this.props.userDetails.highestAdminType !== 'ROLE_SUPER_ADMIN') {
            let selectedCompany = this.props.companies[0].id
            this.setState({
                selectedCompany: selectedCompany,
                departments: this.props.departments
            })
        }
        if (this.props.userDetails.highestAdminType !== 'ROLE_SUPER_ADMIN' && this.props.userDetails.highestAdminType !== 'ROLE_COMPANY_ADMIN') {
            let selectedDepartment = this.props.departments[0].id
            this.setState({
                selectedDepartment: selectedDepartment
            })
        }
    }

    handleCompanyChange = (e) => {
        let self = this
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/companies/${e.value}/departments`, { headers: getHeaderToken() })
            .then(function (response) {
                let departments = response.data
                self.setState({
                    selectedCompany: e.value,
                    departments: departments
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    handleDepartmentChange = (e) => {
        this.setState({
            selectedDepartment: e.value
        })
    }

    onCheckMoveUsers = (e) => {
        if (e.checked) {
            this.setState({
                moveUsersChecked: true
            })
        } else {
            this.setState({
                moveUsersChecked: false
            })
        }
    }

    onMoveUsers = () => {
        this.props.onMoveUsers(this.state.selectedCompany, this.state.selectedDepartment)
    }

    render() {

        let userDetails = this.props.selectedUserDetails && this.props.selectedUserDetails.map(user => {
            return <div key={user.userName} style={{ marginBottom: 5 }}>
                <label>{`${user.lastName}, ${user.foreName} (${user.userName})`}</label>
            </div>
        })

        const footerMoveUsers = (
            <div>
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelMoveUsers}
                />
                <Button label="Move"
                    className='p-button-sm primaryButton'
                    disabled={this.state.moveUsersChecked && this.state.selectedCompany !== null && this.state.selectedDepartment !== null ? false : true}
                    onClick={this.onMoveUsers}
                />
            </div>
        )

        let companyOptions = []
        this.props.companies && this.props.companies.forEach(comp => {
            let activeOrNor = comp.deactivated ? "Deactivated" : " Active"
            companyOptions = [...companyOptions, { label: `${comp.name} - ${activeOrNor}`, value: comp.id, name: `${comp.name}` }]
        })

        let departmentOptions = []
        this.state.selectedCompany && this.state.selectedCompany !== null && this.state.departments && this.state.departments.forEach(dep => {
            let activeOrNor = dep.deactivated ? "Deactivated" : " Active"
            departmentOptions = [...departmentOptions, { label: `${dep.name === 'default' ? 'Unassigned / Inactive regular users' : dep.name} - ${activeOrNor}`, value: dep.id }]
        })


        return (
            <Dialog visible={this.props.displayMoveUsers} style={{ 'width': "40vw" }} header="Move users" focusOnShow={false}
                modal={true} footer={footerMoveUsers} dismissableMask={false}
                onHide={() => this.props.onCancelMoveUsers()} className='styledDialog'>
                <Toast ref={(el) => { this.growl = el }} />
                <div style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 20 }}>
                    <Dropdown style={{ width: '100%', border: '1px solid #d6d6d6', fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}
                        className='dropdownNoBorder'
                        value={this.state.selectedCompany}
                        appendTo={document.body}
                        filter
                        showClear
                        options={companyOptions}
                        disabled={this.props.userDetails.highestAdminType !== 'ROLE_SUPER_ADMIN' ? true : false}
                        onChange={(e) => this.handleCompanyChange(e)}
                        placeholder='Select organization'>
                    </Dropdown>
                    <Dropdown style={{ width: '100%', border: '1px solid #d6d6d6', fontWeight: 'bold', fontSize: 14, marginBottom: 10 , zIndex: '3203'}}
                        className='dropdownNoBorder'
                        value={this.state.selectedDepartment}
                        appendTo={document.body}
                        filter
                        showClear
                        options={departmentOptions}
                        disabled={(this.state.selectedCompany === null) || (this.props.userDetails.highestAdminType !== 'ROLE_SUPER_ADMIN' && this.props.userDetails.highestAdminType !== 'ROLE_COMPANY_ADMIN') ? true : false}
                        onChange={(e) => this.handleDepartmentChange(e)}
                        placeholder='Select suborganization'>
                    </Dropdown>
                    <div style={{ marginBottom: 10, marginTop: 15 }}>
                        <label>{`This will move the following users:`}</label>
                    </div>
                    {userDetails}
                    <div style={{ marginBottom: 15, marginTop: 15, marginLeft: -2 }}>
                        <Checkbox inputId='moveUserChecked'
                            onChange={(e) => this.onCheckMoveUsers(e)}
                            disabled={this.state.selectedCompany !== null && this.state.selectedDepartment !== null ? false : true}
                            checked={this.state.moveUsersChecked ? true : false}
                            value='Please check to confirm' style={{ 'cursor': 'pointer' }}
                        />
                        <label htmlFor='moveUserChecked' className='p-checkbox-label'>Please check to confirm</label>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default MoveUsers