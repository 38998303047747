import InfoOverlayWithIcon from "../../../webapi/general/InfoOverlayWithIcon";

const BackupInfo = () => {
  return (
    <InfoOverlayWithIcon
      infoID="autoBackupInfo"
      title="Click for information about automatic backups"
      imageAltText="Information about automatic backups"
      overlayContent={
        <>
          <h3>Automatic backups</h3>
          <p>
            An automatic backup is created whenever you leave or reload this
            page. If the search form is empty, the latest non-empty version of
            the search form will be stored.
          </p>
          <p>
            Backups can then be restored from the dropdown when you come back.
            The number of backups is limited to 10, i.e. if the limit is reached
            the newly created backup will overwrite the oldest one.
          </p>
        </>
      }
    />
  );
};

export default BackupInfo;
