import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import OntologyBrowserView from '../ontbrowser/OntologyBrowserView'


const mapStateToProps = state => ({
    fetchingAvailableDomains: state.webAPI.fetchingAvailableDomains,
    availableDomains: state.webAPI.availableDomains,
})

const mapDispatchToProps = dispatch => ({
})

const OntologyBrowserContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(OntologyBrowserView))

export default OntologyBrowserContainer
