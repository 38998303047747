import * as ActionTypes from '../actions'

const initialState = {
    managerTab: 0,

    cartridges: [],
    cartridgesLoaded: false,
    cartridgeUploading: "",
    cartridgeDeleting: false,
    cartridgeInstallingAvailable: false,

    repositories: [],
    repositoriesLoaded: false,
    repositoryDeleting: false,

    annotationResults: {},
    annotationUploading: false,
    annotationFilename: "",

    jobs: [],
    activeJobs: [],
    jobsLoading: false,
    jobsPages: 0,
    jobsNum: 0,
    jobsPage: 0,
    jobsRows: 5,
    jobErros: {},

    growlmessages: [],

    lockFrontend: false,
    backendUp: true
}


const managerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CHANGE_MANAGER_TAB:
            return {
                ...state,
                managerTab: action.managerTab,
            }
        case ActionTypes.ADD_ALL_CARTRIDGES:
            return {
                ...state,
                cartridges: action.cartridges,
                cartridgesLoaded: true
            }
        case ActionTypes.SET_CARTRIDGE_UPLOADING:
            return {
                ...state,
                cartridgeUploading: action.cartridgeName
            }
        case ActionTypes.SET_CARTRIDGE_DELETING:
            return {
                ...state,
                cartridgeDeleting: action.cartridgeDeleting
            }
        case ActionTypes.SET_CARTRIDGE_INSTALL_AVAILABLE:
            return {
                ...state,
                cartridgeInstallingAvailable: action.cartridgeInstallingAvailable
            }
        case ActionTypes.SET_CARTRIDGE_DOMAIN_ADDING:
            return {
                ...state,
                cartridgeDomainAdding: action.cartridgeDomainAdding
            }
        case ActionTypes.SET_CARTRIDGE_DOMAIN_EDITING:
            return {
                ...state,
                cartridgeDomainEditing: action.cartridgeDomainEditing
            }
        case ActionTypes.ADD_ALL_REPOSITORIES_MANAGER:
            return {
                ...state,
                repositories: action.repositories,
                repositoriesLoaded: true
            }
        case ActionTypes.SET_REPOSITORY_DELETING:
            return {
                ...state,
                repositoryDeleting: action.repositoryDeleting
            }
        case ActionTypes.SET_ANNOTATION_SUBMISSION:
            return {
                ...state,
                annotationResults: {},
                annotationFilename: action.filename,
                annotationUploading: true
            }
        case ActionTypes.DISPLAY_ANNOTATION_RESULTS:
            return {
                ...state,
                annotationUploading: false,
                annotationResults: action.annotationResults
            }
        case ActionTypes.SHOW_NOTIFICATION:
            return {
                ...state,
                growlmessages: state.growlmessages.concat([{
                    severity: action.severity,
                    summary: action.summary,
                    detail: action.detail,
                    debug: action.debug,
                    date: Date.now(),
                    sticky: action.severity === 'error',
                    closable: action.severity === 'error'
                }])
            }
        case ActionTypes.UPDATE_JOBS:
            return {
                ...state,
                jobs: action.jobsResult.content,
                jobsPages: action.jobsResult.totalPages,
                jobsNum: action.jobsResult.totalElements,
                jobsPage: action.jobsResult.number,
                jobsRows: action.jobsResult.size,
            }
        case ActionTypes.ADD_JOB_ERRORS:

            let nerr = { ...state.jobErros }
            nerr[action.jobsResult.jobId] = action.jobsResult.itemErrors
            console.log(action);
            console.log(nerr);
            return {
                ...state,
                jobErros: nerr
            }
        case ActionTypes.UPDATE_ACTIVE_JOBS:
            return {
                ...state,
                activeJobs: action.jobsResult.content,
            }
        case ActionTypes.JOBS_LOADING:
            return {
                ...state,
                jobsLoading: action.jobsLoading
            }
        case ActionTypes.LOCK_FRONTEND:
            return {
                ...state,
                lockFrontend: action.lockFrontend,
                backendUp: false
            }
        case ActionTypes.SERVER_STATUS:
            let backendUp = false;
            if (action.serverStatus.services &&
                action.serverStatus.services["Annotation Service"] &&
                action.serverStatus.services["OC|Miner Manager Service"] &&
                action.serverStatus.services["OC|Miner Service"]
                //action.serverStatus.services["Processor Manager Service"] &&
                //action.serverStatus.services["Cartridge Manager Service"]
            ) {
                backendUp = action.serverStatus.services["Annotation Service"].available &&
                    action.serverStatus.services["OC|Miner Manager Service"].available &&
                    action.serverStatus.services["OC|Miner Service"].available
            }
            return {
                ...state,
                backendUp: backendUp
            }
        default:
            return state

    }
}

export default managerReducer
