import React from "react";
import InfoOverlayWithIcon from "../../../general/InfoOverlayWithIcon";
import ToolbarOption from "./ToolbarOption";


export default function ToolbarCategory({ toolbarItem }) {

    return toolbarItem ? <div key={toolbarItem.label}
        className="col-fixed query-editor-category">
        <div className="query-editor-category-inner">
            <div className="query-editor-category-label secondaryInfo">
                <div>{toolbarItem.label}</div>
                <div className="query-editor-category-info-icon" style={{ visibility: toolbarItem.help ? 'visible' : 'hidden' }}>
                    <InfoOverlayWithIcon
                        infoID={toolbarItem.label}
                        title="Click for quick help"
                        imageAltText="Quick information about how to create a powerful search query"
                        overlayContent={toolbarItem.help}
                    />
                </div>
            </div>
            <div className="query-editor-category-selections">
                {toolbarItem.options?.map(option => {
                    return (
                        <React.Fragment key={option.label}>
                            <ToolbarOption option={option} />
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    </div> : null
}