export type Filter = {
  key: string;
  filterID: string;
}

export type FilterGroup = {
  key: string;
  type: 'category';
  label: string;
  selectable: boolean;
  expand?: boolean;
  children: FilterGroup[] | Filter[];
}

export type Filters = FilterGroup[];

export const ADVANCED_SEARCH_3_FILTERS: Filters = [
  {
    key: "metadata",
    type: "category",
    label: "Basic data",
    selectable: false,
    children: [
      { key: "metadata:pubDate", filterID: "pubDate" },
      { key: "metadata:author", filterID: "author" },
      { key: "metadata:authorAff", filterID: "authorAff" },
    ],
  },
  {
    key: "docIDs",
    type: "category",
    label: "Document IDs",
    selectable: false,
    expand: true,
    children: [
      {
        key: "docIDs:extIDs",
        type: "category",
        label: "External IDs",
        selectable: false,
        children: [
          { key: "docIDs:extIDs:anyExtID", filterID: "anyExtID" },
          { key: "docIDs:extIDs:doi", filterID: "doi" },
          { key: "docIDs:extIDs:issn", filterID: "issn" },
          { key: "docIDs:extIDs:pmID", filterID: "pmID" },
          { key: "docIDs:extIDs:pmcID", filterID: "pmcID" },
          { key: "docIDs:extIDs:patNo", filterID: "patNo" },
          { key: "docIDs:extIDs:trialID", filterID: "trialID" },
        ],
      },
      {
        key: "docIDs:intIDs",
        type: "category",
        label: "Internal IDs",
        selectable: false,
        children: [{ key: "docIDs:intIDs:ocDocID", filterID: "ocDocID" }],
      },
    ],
  },
  {
    key: "dates",
    type: "category",
    label: "Dates",
    selectable: false,
    children: [
      { key: "dates:pubDate", filterID: "pubDate" },
      { key: "dates:prioDate", filterID: "prioDate" },
      { key: "dates:startDate", filterID: "startDate" },
      { key: "dates:endDate", filterID: "endDate" },
      { key: "dates:insDate", filterID: "insDate" },
    ],
  },
  {
    key: "specific",
    type: "category",
    label: "Source-specific",
    selectable: false,
    expand: true,
    children: [
      {
        key: "specific:patents",
        type: "category",
        label: "Patents",
        selectable: false,
        children: [
          { key: "specific:patents:patNo", filterID: "patNo" },
          { key: "specific:patents:patentOffice", filterID: "patentOffice" },
          { key: "specific:patents:assignee", filterID: "assignee" },
          { key: "specific:patents:inventor", filterID: "inventor" },
          { key: "specific:patents:legalStatus", filterID: "legalStatus" },
          { key: "specific:patents:cpcClass", filterID: "cpcClass" },
          { key: "specific:patents:ipcClass", filterID: "ipcClass" },
          { key: "specific:patents:prioDate", filterID: "prioDate" },
        ],
      },
      {
        key: "specific:clinTrials",
        type: "category",
        label: "Clinical trials",
        selectable: false,
        children: [
          { key: "specific:clinTrials:trialID", filterID: "trialID" },
          { key: "specific:clinTrials:sponsor", filterID: "sponsor" },
          { key: "specific:clinTrials:investigator", filterID: "investigator" },
          {
            key: "specific:clinTrials:investigatorAff",
            filterID: "investigatorAff",
          },
          { key: "specific:clinTrials:status", filterID: "status" },
          { key: "specific:clinTrials:studyType", filterID: "studyType" },
          { key: "specific:clinTrials:clinPhase", filterID: "clinPhase" },
          { key: "specific:clinTrials:startDate", filterID: "startDate" },
          { key: "specific:clinTrials:endDate", filterID: "endDate" },
        ],
      },
      {
        key: "specific:grants",
        type: "category",
        label: "Grants",
        selectable: false,
        children: [
          { key: "specific:grants:investigator", filterID: "investigator" },
          { key: "specific:grants:funder", filterID: "funder" },
          { key: "specific:grants:funderCountry", filterID: "funderCountry" },
          { key: "specific:grants:researchOrg", filterID: "researchOrg" },
          {
            key: "specific:grants:researchOrgCountry",
            filterID: "researchOrgCountry",
          },
          // { key: 'specific:grants:clinPhase', label: 'State', filterID: 'clinPhase' },
          { key: "specific:grants:startDate", filterID: "startDate" },
          { key: "specific:grants:endDate", filterID: "endDate" },
        ],
      },
      {
        key: "specific:dspubs",
        type: "category",
        label: "DS publications",
        selectable: false,
        children: [
          { key: "specific:dspubs:doi", filterID: "doi" },
          { key: "specific:dspubs:pmID", filterID: "pmID" },
          { key: "specific:dspubs:pmcID", filterID: "pmcID" },
          {
            key: "specific:dspubs:openAccessStatus",
            filterID: "openAccessStatus",
          },
          {
            key: "specific:dspubs:publicationType",
            filterID: "publicationType",
          },
          { key: "specific:dspubs:publisher", filterID: "publisher" },
          { key: "specific:dspubs:journalSource", filterID: "journalSource" },
        ],
      },
      {
        key: "specific:pubs",
        type: "category",
        label: "Other publications",
        selectable: false,
        children: [
          { key: "specific:pubs:publisher", filterID: "publisher" },
          { key: "specific:pubs:journal", filterID: "journal" },
          { key: "specific:pubs:volume", filterID: "volume" },
          { key: "specific:pubs:issue", filterID: "issue" },
        ],
      },
    ],
  },
];

