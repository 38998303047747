import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ServicesStatus from '../services/ServicesStatus';

const mapStateToProps = state => ({
    serverStatus: state.webAPI.serverStatus
})
const mapDispatchToProps = dispatch => ({})

const ServicesStatusContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesStatus))

export default ServicesStatusContainer