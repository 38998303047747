import SeparatorPoint from "../../../../../../../../../general/SeparatorPoint";
import { renderList } from "../../../../../../../../helpers/advancedSearch";


const QueryTermsColumn = ({
    terms,
    termsCutOff,
    isShowNumOfTerms,
    domains
}) => {

    return (
        <div className="flex flex-column gap-2">
            <div className="font-bold">
                <div title={terms?.join(", ")}>
                    {renderList(terms, termsCutOff, isShowNumOfTerms, <SeparatorPoint />)}
                </div>
            </div>
            <div title={domains?.join(", ")}>
                {renderList(domains, 5, true, <SeparatorPoint />)}
            </div>
        </div>
    );
}

export default QueryTermsColumn;