import PropTypes from 'prop-types'
import { EXTRA_COLORS } from '../constants/domainColors';
import OverlayPanel from './OverlayPanel';

export const ColorPicker = ({
    changeColor,
    bgColor,
    item,
    onSpecifyQueryTerm,
    onSpecifyQueryRegex
}) => {
    
    return (
        <>
            <span className='edit-click whiteSpaceNoWrap' onClick={(event) => {
                onSpecifyQueryTerm ? onSpecifyQueryTerm(item) : onSpecifyQueryRegex(item, event)
            }}>
                | Edit |
            </span>
            <OverlayPanel direction="bottom" content={<div>
                <div className='overlay-color' >
                    <div className='picker-header'>Tag Color</div>
                    <div className='colors-wrapper'>
                        {
                            EXTRA_COLORS.map(obj => {
                                return <div key={obj} className='color-item'
                                    style={{ backgroundColor: `#${obj}` }} onClick={() => changeColor(obj, item)}>
                                    {
                                        bgColor === obj ? <i className='pi pi-check check-icon' ></i> : ''
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>} >

                <i className='pi pi-angle-down color-dropdown' style={{ backgroundColor: `#${bgColor}` }}></i>
            </OverlayPanel>
        </>
    )
}

ColorPicker.propTypes = {
    changeColor: PropTypes.func,
    bgColor: PropTypes.string,
    item: PropTypes.object,
    onSpecifyQueryTerm: PropTypes.func,
}
