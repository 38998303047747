import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

export const createYaxis = (chart, valueAxis) => {
    valueAxis.current = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.current.numberFormatter = new am4core.NumberFormatter();
    valueAxis.current.adjustLabelPrecision = false;
    valueAxis.current.renderer.labels.template.fill = am4core.color('#757575');
    // valueAxis.current.min = 0;
    valueAxis.current.title.fill = am4core.color('#757575');
    valueAxis.current.extraMax = 0.1;
}
