import { Component } from 'react';

import InfoOverlayWithIcon from '../../general/InfoOverlayWithIcon';

class FingerprintHitsInfo extends Component {
  render() {
    return (
      <InfoOverlayWithIcon
        infoID="fingerprintInfo"
        title="Click for information about hit number approximation"
        imageAltText="Information about hit number approximation"
        overlayContent={
          <>
            <p>
              This number of potential matches is only an approximation and an upper limit of
              the actual number of hits. It represents the number of hits
              found during the fingerprint screening step. The actual number of
              matches may be much lower.
            </p>
          </>
        }
      />
    );
  }
}

export default FingerprintHitsInfo;
