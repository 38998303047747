import moment from "moment";
import {
  deleteQuery,
  fetchBackupQueries,
  fetchQueryByID,
  storeBackupQuery,
  storeQuery,
  updateBackupQuery,
  updateQuery,
} from "../../../../api/content/WatchlistApi";
import {
  LOCAL_STORAGE_DOC_FINDER_DATA,
  QUERY_CATEGORY_DOC_SEARCH,
  QUERY_FORM_ADVANCED_3,
  QUERY_FORM_AUTO_SAVE,
  QUERY_TYPE_ADVANCED_SEARCH_3,
  RESPONSE_STATUS_SUCCESS,
  VERSION_1_0,
} from "../../../../properties";
import {
  addQueryAlert,
  deleteQueryAlert,
  updateQueryAlert,
} from "../../../../api/content/SaveSearch";
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "../../util/localStorage";
import {
  cloneDeep,
  convertServerTimeToLocalTime,
  getCurrentDateAndTime,
  isArrayEmpty,
} from "../../util";
import { removeIDsFromQueryTermsForAllSearchGroups } from "./advancedSearch";
import { createQueryString } from "./queryConverter";

export const storeSearch = async (
  savedSearchID,
  isEdit,
  queryData = {},
  watchlistIDs,
  alertData = {},
  formID = QUERY_FORM_ADVANCED_3.id
) => {
  const {
    searchGroups,
    queryString,
    queryName,
    queryFullName,
    queryDescription,
  } = queryData;
  const { addAlert, alertID } = alertData;

  let alertId = alertID;
  const searchGroupsCleaned = cleanupSearchGroups(cloneDeep(searchGroups));

  const formContent = {
    searchGroups: searchGroupsCleaned,
    version: VERSION_1_0,
  };

  let response;
  let queryID;
  if (isEdit) {
    response = await updateQuery(
      savedSearchID,
      queryString,
      null,
      queryName,
      queryFullName,
      queryDescription,
      QUERY_CATEGORY_DOC_SEARCH.id,
      QUERY_TYPE_ADVANCED_SEARCH_3.id,
      formID,
      formContent,
      watchlistIDs
    );
    queryID = savedSearchID;
  } else {
    response = await storeQuery(
      queryString,
      null,
      queryName,
      queryFullName,
      queryDescription,
      QUERY_CATEGORY_DOC_SEARCH.id,
      QUERY_TYPE_ADVANCED_SEARCH_3.id,
      formID,
      formContent,
      watchlistIDs
    );
    queryID = response?.payload?.id;
    alertId = null;
  }

  if (response.status !== RESPONSE_STATUS_SUCCESS) {
    return response;
  }

  // TODO: ???
  if (addAlert || alertId) {
    const responseAlerts = await updateAlert(queryID, {
      ...alertData,
      alertID: alertId,
    });
    if (responseAlerts.status !== RESPONSE_STATUS_SUCCESS) {
      return responseAlerts;
    }
  }

  return {
    status: RESPONSE_STATUS_SUCCESS,
    payload: {
      savedSearchID: queryID,
      savedSearchName: queryFullName,
      isEditable: true,
    },
  };
};

export const updateAlert = async (queryID, alertData) => {
  const { addAlert, alertID, alertActive, alertInterval, alertRepositories } =
    alertData;
  let response;

  if (addAlert) {
    const date = new Date().setDate(new Date().getDate() + 1);
    let nextSendingDate = moment(date).format("YYYY-MM-DD");
    if (alertID) {
      response = await updateQueryAlert(
        alertID,
        queryID,
        alertActive,
        alertInterval,
        nextSendingDate,
        alertRepositories
      );
    } else {
      response = await addQueryAlert(
        queryID,
        alertActive,
        alertInterval,
        nextSendingDate,
        alertRepositories
      );
    }
  } else if (alertID) {
    response = await deleteQueryAlert(alertID);
  }

  return response;
};

export const getAdvancedSearchFromLocalStorage = () => {
  const dataFromStorage = getDataFromLocalStorage(
    LOCAL_STORAGE_DOC_FINDER_DATA
  );
  removeDataFromLocalStorage(LOCAL_STORAGE_DOC_FINDER_DATA);

  return extractSavedSearchData(dataFromStorage);
};

// === auto-saved search (backup) === //

export const fetchAutoSavedSearch = async (sessionID) => {
  const response = await fetchBackupQueries();
  const autoSavedSearch = response.payload.content?.find(
    (svs) => svs.form === sessionID
  );
  return autoSavedSearch;
};

export const fetchAllAutoSavedSearches = async (sortByModifiedTime = true) => {
  const response = await fetchBackupQueries();

  const allBackupSearches = response?.payload?.content
    ? response.payload.content.map((search) => {
        const backupSearchGroups = extractSearchGroups(search);
        const modified = search?.modified ? convertServerTimeToLocalTime(search.modified) : 'no date';
        return {
          savedSearchID: search.savedSearchID,
          label: `${modified} - ${createShortLabel(backupSearchGroups)}`,
          searchGroups: backupSearchGroups,
        };
      })
    : [];

  if (response.status !== RESPONSE_STATUS_SUCCESS) {
    return response;
  }

  return {
    status: RESPONSE_STATUS_SUCCESS,
    payload: allBackupSearches,
  };
};

export const storeBackupSearch = async (
  searchGroups,
  filterDefinitions,
  backupSearchID
) => {
  const queryString = await createQueryString(searchGroups, filterDefinitions);
  const savedSearchName = `Backup - ${getCurrentDateAndTime()}`;
  const formContent = {
    searchGroups: cleanupSearchGroups(cloneDeep(searchGroups)),
    version: VERSION_1_0,
  };

  const request = {
    name: QUERY_FORM_AUTO_SAVE.id,
    fullName: savedSearchName,
    description: "Automatically saved backup",
    category: QUERY_CATEGORY_DOC_SEARCH.id,
    type: QUERY_TYPE_ADVANCED_SEARCH_3.id,
    form: QUERY_FORM_ADVANCED_3.id,
    formContent: JSON.stringify(formContent),
    value: queryString,
  };

  // TODO: move to separate general function
  let response;
  let searchID;

  if (!backupSearchID) {
    response = await storeBackupQuery(request);
    searchID = response?.payload?.id;
  } else {
    response = await updateBackupQuery(backupSearchID, request);
    searchID = backupSearchID;
  }

  if (response.status !== RESPONSE_STATUS_SUCCESS) {
    return response;
  }

  return {
    status: RESPONSE_STATUS_SUCCESS,
    payload: searchID,
  };
};

export const deleteBackupSearch = (savedSearchID) => {
  const response = deleteQuery(savedSearchID);
  return response;
};

// === saved search === //

export const fetchSavedSearch = async (savedSearchID) => {
  const response = savedSearchID ? await fetchQueryByID(savedSearchID) : null;
  // console.log('response', response);
  const savedSearch =
    response?.status === RESPONSE_STATUS_SUCCESS ? response.payload : null;
  return savedSearch;
};

export const cleanupSearchGroups = (searchGroups) => {
  let searchGroupsNew = removeIDsFromQueryTermsForAllSearchGroups(searchGroups);
  searchGroupsNew = removeUnnecessaryDataFromAllSearchGroups(searchGroupsNew);

  return searchGroupsNew;
};

export const removeUnnecessaryDataFromAllSearchGroups = (searchGroups) => {
  const searchGroupsNew = searchGroups ? [...searchGroups] : [];
  searchGroupsNew?.forEach((searchGroup) => {
    delete searchGroup.savedSearchData;
  });

  return searchGroupsNew;
};

// TODO: cleanup and rename savedSearch/savedSearchData???

export const extractSavedSearchData = (savedSearch) => {
  // console.log('extractSavedSearchData savedSearch', savedSearch);
  if (savedSearch?.formContent) {
    return {
      savedSearchID: savedSearch.id,
      savedSearchName: savedSearch.fullName,
      isEditable: isSavedSearchEditable(savedSearch),
      formContent: JSON.parse(savedSearch.formContent),
    };
  }
  return null;
};

export const isSavedSearchEditable = (savedSearch) => {
  let editable = true;
  if (!isArrayEmpty(savedSearch?.queryCollectionList)) {
    savedSearch.queryCollectionList.every((list) => {
      if (list.shared && !list.writable) {
        editable = false;
        return false;
      }
      return true;
    });
  }
  return editable;
};

export const extractSearchGroups = (savedSearchData) => {
  let searchGroups = null;
  if (savedSearchData?.formContent) {
    searchGroups =
      JSON.parse(savedSearchData.formContent)?.searchGroups || null;
  }
  return searchGroups;
};

export const createShortLabel = (searchGroups) => {
  let shortLabel = "-";
  if (
    !isArrayEmpty(searchGroups) &&
    !isArrayEmpty(searchGroups[0].queryTerms)
  ) {
    shortLabel = searchGroups[0].queryTerms.map((qt) => qt.label).join(" | ");
    shortLabel = shortLabel.substring(0, 50);
  }
  return shortLabel;
};
