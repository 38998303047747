import React, { useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './BioactivitiesTable.css';
import styles from './BioactivitiesResultTable.module.scss';
import StructureTemplate from "./StructureTemplate/StructureTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
    bioactivitiesCheckedResults,
    bioactivitiesFirstResultIndex,
    bioactivitiesResultCount,
    bioactivitiesSearchResults,
} from "../../../../../redux/selectors/selectors";
import {
    setBioactivitiesCheckedResults,
} from "../../../../../redux/actions/BioactivitiesActions";
import CheckboxWithLabel from "../../../biomarker/CheckboxWithLabel/CheckboxWithLabel";
import CustomPaginatorLeftSide from '../../../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../../../common/CustomPaginator/CustomPaginatorTemplate'
import ExternalLinkIcon from '../../../general/ExternalLinkIcon/ExternalLinkIcon';

export const BioactivitiesResultTable = ({ hitCount, onPage }) => {
    const searchResults = useSelector(bioactivitiesSearchResults);
    const resultCount = useSelector(bioactivitiesResultCount);
    const firstResultIndex = useSelector(bioactivitiesFirstResultIndex);
    const checkedResults = useSelector(bioactivitiesCheckedResults);
    const dispatch = useDispatch();
    const dt = useRef(null);

    const isChecked = checkedResults.length && checkedResults.length === searchResults?.relationResults.length;

    const rowIndexTemplate = (rowData, props) => {
        let index = (firstResultIndex + 1) + props.rowIndex;

        return (
            <div title={index}>{index}</div>
        );
    };

    const structureBodyTemplate = (rowData) => {
        return <StructureTemplate structure={rowData.fields.image}
            compound={rowData.fields.compound?.ocidPrefNames || '-'}
            ocid={rowData.fields.compound.ocids[0]} />
    }

    const compoundBodyTemplate = (rowData) => {
        const compound = rowData.fields.compound?.ocidPrefNames || '-';

        return (
            <>
                <span className="p-column-title">Compound</span>
                <span style={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 300
                }} title={compound}>{compound}</span>
            </>
        )
    }

    const targetBodyTemplate = (rowData) => {
        const target = rowData.fields.target?.ocidPrefNames || rowData.fields.target?.terms || '-';

        return (
            <>
                <span className="p-column-title">Target</span>
                <span title={target}>{target}</span>
            </>
        )
    }

    const propertyBodyTemplate = (rowData) =>
        <>
            <span className="p-column-title">Property</span>
            <span title={rowData.fields.property?.terms[0] || ''}>{rowData.fields.property?.terms[0] || '-'}</span>
        </>

    const valueBodyTemplate = (rowData) => {
        const value = rowData.fields.property?.values?.[0];
        const roundedValue = value?.toFixed(3);

        const isInteger = (num) => {
            return (num ^ 0) === num;
        }

        const valueToRender = isInteger(value) ? value : roundedValue;

        const rangeMin = rowData.fields['property']?.rangeMin;
        const rangeMax = rowData.fields['property']?.rangeMax;
        const range = rangeMin ? `${rangeMin}-${rangeMax}` : '-';

        return (
            <>
                <span className="p-column-title">Value</span>
                <span title={valueToRender || range}>{valueToRender || range}</span>
            </>
        )
    }

    const unitBodyTemplate = (rowData) =>
        <>
            <span className="p-column-title">Unit</span>
            <span>{rowData.fields['property.unit']?.storedData || ''}</span>
        </>

    const descriptionBodyTemplate = (rowData) => {
        //const link = rowData.fields['document.xref']?.storedData;
        const title = rowData.fields['assay.description'].text;

        return (
            <div //className={styles.info}
            >
                <span className="p-column-title">Assay description</span>

                <div //className={styles.descriptionWrapper}
                >
                    <p style={{ marginTop: 0 }} title={title}>{rowData.fields['assay.description'].text}</p>
                    {/*!!link && <a href={link} target='_blank'>
                        <span>ChEMBL</span>
                        <i className='pi pi-external-link' />
        </a>*/}
                </div>
            </div>
        )
    }

    const linkBodyTemplate = (rowData) => {
        const link = rowData.fields['document.xref']?.storedData;

        return (
            <div>
                {!!link && <a href={link} target='_blank' rel="noreferrer">
                    <span>ChEMBL</span>
                    <ExternalLinkIcon standardLinkIcon={true} />
                </a>}
            </div>
        )
    }

    const handleSelect = ({ value }) => {
        dispatch(setBioactivitiesCheckedResults(value));
    }

    const handleCheckBox = () => {
        if (isChecked) {
            dispatch(setBioactivitiesCheckedResults([]));
        } else {
            dispatch(setBioactivitiesCheckedResults(searchResults?.relationResults));
        }
    }

    return (
        <div className="bioactivities-table">
            <div className={styles.selectAll}>
                <CheckboxWithLabel labelText='Select all' checked={isChecked} handleCheckBox={handleCheckBox} />
            </div>

            <DataTable ref={dt} value={searchResults?.relationResults || []}
                dataKey="relationId"
                selection={checkedResults} onSelectionChange={handleSelect}
                paginatorPosition={'bottom'}
                //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                paginatorTemplate={CustomPaginatorTemplate}
                paginatorLeft={<CustomPaginatorLeftSide
                    first={firstResultIndex}
                    contentLength={hitCount}
                    rows={resultCount}>
                </CustomPaginatorLeftSide>}
                paginatorRight={<CustomPaginatorRightSide
                    rows={resultCount}
                    onChange={(e) => onPage(e, false)}
                ></CustomPaginatorRightSide>}
                paginator
                rowsPerPageOptions={[5, 10, 20, 50]}
                rows={resultCount}
                totalRecords={hitCount}
                alwaysShowPaginator={false}
                first={firstResultIndex}
                lazy
                onPage={(e) => onPage(e, true)}
                selectionMode="checkbox"
                className="p-bioactivities-table"
            >
                {/*to check the checkbox when all rows on the current page are selected with LAZY mode custom header
                is passed. default checkbox is hidden by css
                */}
                <Column selectionMode="multiple"
                    //header={<CheckboxWithLabel checked={isChecked} handleCheckBox={handleCheckBox} />}
                    className='bioactivities-selection-header' />

                <Column body={rowIndexTemplate} className={styles.rowIndex} />
                <Column header="Structure" body={structureBodyTemplate} className={styles.structure} />
                <Column header="Compound" body={compoundBodyTemplate} className={styles.compound} style={{ width: '20%' }}/>
                <Column header="Target" body={targetBodyTemplate} className={styles.target} />
                <Column header="Property" body={propertyBodyTemplate} className={styles.property} />
                <Column header="Value" body={valueBodyTemplate} className={styles.value} />
                <Column header="Unit" body={unitBodyTemplate} className={styles.unit} />
                <Column header="Assay description" body={descriptionBodyTemplate} className={styles.assay}
                //className={styles.description} 
                />
                {<Column header="" body={linkBodyTemplate} style={{ width: '7em' }} />}
            </DataTable>
        </div>
    );
}
