import React, { Component } from 'react';

class AcknowledgementContainer extends Component {


  render() {
    return (
      <div style={{
        marginLeft: '17%', marginTop: 36
      }}>
        <h1 style={{ marginBottom: 30 }}>Acknowledgements</h1>
        <span className="footer-text-left">
          <div style={{ marginBottom: 20 }}>
            <a style={{ margin: '0 0px', fontWeight: 'bold' }} href="https://blast.ncbi.nlm.nih.gov/Blast.cgi" target="_blank" rel="noopener noreferrer">BLAST - Basic Local Alignment Search Tool</a>
          </div>
          <div style={{ marginBottom: 20 }}>
            <a style={{ margin: '0 0px', fontWeight: 'bold' }} href="https://sourceforge.net/projects/cdk/" target="_blank" rel="noopener noreferrer">CDK - The Chemistry Development Kit</a>
          </div>
          {/*<div style={{marginBottom: 20}}>
            <a style={{ margin: '0 0px' }} href="http://www.chemaxon.com/" target="_blank" rel="noopener noreferrer">ChemAxon</a>
    </div>*/}
          <div style={{ marginBottom: 20 }}>
            <a style={{ margin: '0 0px', fontWeight: 'bold' }} href="http://openmolecules.org/" target="_blank" rel="noopener noreferrer">OpenChemLib</a>
          </div>
          <div style={{ marginBottom: 20 }}>
            <a style={{ margin: '0 0px', fontWeight: 'bold' }} href="http://opsin.ch.cam.ac.uk/" target="_blank" rel="noopener noreferrer">OPSIN - Open Parser for Systematic IUPAC nomenclature</a>
          </div>
          <div>
            <a style={{ margin: '0 0px', fontWeight: 'bold' }} href="https://cactus.nci.nih.gov/osra/" target="_blank" rel="noopener noreferrer">OSRA: Optical Structure Recognition Application</a>
            <div style={{ paddingLeft: 0, paddingTop: 5 }}>OSRA is a utility designed to convert graphical representations of chemical structures, as they appear in journal articles, patent documents, textbooks, trade magazines etc., into SMILES.
          </div>
          </div>
        </span>
      </div>
    );
  }
}

export default AcknowledgementContainer;