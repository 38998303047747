import { SEARCH_RESULT_TYPE_COMPOUNDS, SEARCH_RESULT_TYPE_REACTIONS } from "../utils/chemistryDBs";
import { CHEMISTRY_SEARCH_FILTER_ID_INCL_MIXTURES } from "../utils/chemistrySearchConstants";
import CompoundSearchInfo from "./CompoundSearchInfo";
import IncludeMixturesSearchInfo from "./IncludeMixturesSearchInfo";
import ReactionSearchInfo from "./ReactionSearchInfo";

export const FILTER_INFO_MAPPING = {
    [CHEMISTRY_SEARCH_FILTER_ID_INCL_MIXTURES]: <IncludeMixturesSearchInfo />
}

export const FILTER_INFO_MAPPING_BY_TYPE = {
    //[SEARCH_FILTER_TYPE_RADIO_STATIC]: <CompoundSearchInfo />,
}

export const FILTER_INFO_MAPPING_BY_SEARCH_TYPE = {
    [SEARCH_RESULT_TYPE_COMPOUNDS]: <CompoundSearchInfo />,
    [SEARCH_RESULT_TYPE_REACTIONS]: <ReactionSearchInfo />,
} 