import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import styles from './index.module.scss';
import './index.scss';

export const ActionButton = ({
  isButtonLink = true,
  label,
  extraClassNames = [],
  onClick,
  removeDefaultStyles = false,
  ...rest
}) => {
  const classes = classNames(
    ...(!removeDefaultStyles ? ['p-button-link', isButtonLink ? styles.buttonLink : styles.button] : []),
    rest?.disabled ? styles.buttonDisabled : '',
    ...extraClassNames,
  );

  return <Button label={label} className={classes} onClick={onClick} {...rest} />;
};

ActionButton.propTypes = {
  label: PropTypes.string,
  extraClassNames: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  removeDefaultStyles: PropTypes.bool,
};
