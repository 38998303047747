import React from "react";
import SearchGroupCriteriaEditor from "../../../../../../../common/queryBuilder/SearchGroupCriteriaEditor/SearchGroupCriteriaEditor";
import { LOGICAL_OPERATORS_AND_OR } from "../../../../../helpers/constants";
import { useSelector } from "react-redux";
import SearchGroupFilters from "./SearchGroupFilters";
import { filterDefinitionsSelector } from "../../../../../../../../redux/common/filters/selectors/filters";

const SearchGroupCriteriaEditorContainer = ({
  logicalOperator,
  logicalOperators = LOGICAL_OPERATORS_AND_OR,
  searchCriteria,
  searchCriteriaFilters,
  onValuesChange,
  hideSearchCriteria,
}) => {
  const filterDefinitions = useSelector(filterDefinitionsSelector);
  return (
    <SearchGroupCriteriaEditor
      filterDefinitions={filterDefinitions}
      logicalOperator={logicalOperator}
      logicalOperators={logicalOperators}
      searchCriteria={searchCriteria}
      searchCriteriaFilters={searchCriteriaFilters}
      onValuesChange={onValuesChange}
      hideSearchCriteria={hideSearchCriteria}
      renderFilter={({ filter, selectedEntry, updateSearchCriteria }) => (
        <SearchGroupFilters
          filter={filter}
          selectedEntry={selectedEntry}
          updateSearchCriteria={updateSearchCriteria}
        />
      )}
    />
  );
};

export default SearchGroupCriteriaEditorContainer;
