import moment from 'moment';

export const checkIsPeriodEnded = (period, endDate) => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const currentWeek = moment(now).week();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endWeek = moment(now).week();

    switch (period) {
        case 'yearly':
            return endYear < currentYear

        case 'monthly':
            if(endYear < currentYear) {
                return true
            }
            return  endMonth < currentMonth

        case 'weekly':
            if(endYear < currentYear) {
                return true
            }

            if(endMonth < currentMonth) {
                return true
            }

            return endWeek < currentWeek

        default:
            throw new Error('Invalid period')
    }
}
