import InfoOverlayWithIcon from "../../../general/InfoOverlayWithIcon";


const SearchGroupsInfo = () => {

    return (
        <InfoOverlayWithIcon
            infoID="searchGroupsInfo"
            title="Click for information about different search groups"
            imageAltText="Information about different search groups"
            overlayContent={
                <div className='info-text-container'>
                    <h1>Search groups</h1>
                    <p>
                        You can add different kinds of search groups and combine them with logic groups to create a complex query.
                        The last group is always the starting point for the overall search. So, please make sure that all of your groups
                        are somehow connected to the last one. You can have a look at our examples to get started.
                    </p>

                    <h2>Terms</h2>
                    <p>
                        Use term groups to add concepts or terms to your query. Decide in which document sections they should occur,
                        define a distance between concepts etc.
                    </p>

                    <h2>Logic</h2>
                    <p>
                        Use logic groups to combine all kinds of groups together. You can use AND/OR/NOT-logic.
                    </p>

                    <h2>Metadata</h2>
                    <p>
                        Use metadata groups to add metadata filters to your query, e.g. data ranges, publication type, etc.
                    </p>

                    <h2>Saved search</h2>
                    <p>
                        Use saved search groups to add an existing saved search to your query.
                        It can either be included as a reference or a copy.
                        A reference only stores the ID of a saved search and its content will change if the oiginal search changes.
                        If you click "Expand saved search" the content of the original saved search will be added
                        as a copy to your query. Changing the original saved search will have no effect on your query.
                    </p>

                    <h2>API syntax</h2>
                    <p>
                        Use API syntax groups to add advanced search strings to your query.
                        This group should only be used if there is no better way to add certain features, e.g. if they are not supported, yet.
                    </p>
                </div>
            }
        />
    );
}

export default SearchGroupsInfo;