import { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { Checkbox } from "primereact/checkbox";


class FilterSectionCheckbox extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }

    render() {

        const { filterID, value, label, filterInfo, onFilterValuesChange, disabled } = this.props;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div className={disabled ? 'grid disableContent' : 'grid nopad width1000erc'} style={{ marginLeft: 0 }}>
                    <div className="col ">
                        <Checkbox
                            style={{ visibility: disabled ? 'hidden' : 'visible' }}
                            inputId={filterID}
                            onChange={(e) => onFilterValuesChange(e.checked)}
                            checked={value}
                        />
                        <label htmlFor={filterID} className='p-checkbox-label valignMiddle'>{label}</label>
                    </div>
                    {filterInfo ?
                        <div className="col-fixed" style={{ margin: 'auto' }}>
                            {filterInfo}
                        </div> : null}
                </div>
            </>
        );
    }
}

export default FilterSectionCheckbox;