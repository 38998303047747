import { isEmpty } from "lodash";
import { InputNumber } from "primereact/inputnumber";

const TextProximityColumn = ({
    data,
    isText,
    onChange
}) => {

    const hasMultipleTextTerms = isText && !isEmpty(data?.queryValues) ? data.queryValues[0]?.split(" ")?.length > 1 : null;
    return (
        <div className="flex align-items-center justify-content-end h-full">
            {hasMultipleTextTerms ? (
                <InputNumber
                    value={data?.textDistance ?? 0}
                    onValueChange={(e) => onChange(data, e.value)}
                    min={0}
                    max={20}
                    minFractionDigits={0}
                    maxFractionDigits={0}
                    step={1}
                    showButtons
                    className="booster-spinner-button"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-secondary"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                />
            ) : (
                <div>-</div>
            )}
        </div>
    );
}

export default TextProximityColumn;