import { hasUserRole, isArrayEmpty } from './components/webapi/util';
import { APP_PROPERTIES } from './properties/index';

// ------------------ //
// --- menu items --- //
// ------------------ //

// --- administration --- //
const menuItemAdministration = {
    label: 'Administration', icon: 'work', to: '/administration'
};
/*const menuItemXfactAdministration = {
    label: 'xfact Administration', icon: 'work', to: '/xfactadmin'
};*/
const menuItemBackendAdmin = {
    label: 'Backend Admin.', icon: 'computer', to: '/backendadmin'
};

// --- search pages, etc. --- //
const menuItemSentenceAnalysis = {
    label: 'Sentence Analysis', icon: 'clear_all', to: '/analytics/sentenceanalysis',
    info: 'Analyze sentences of documents.'
};

const menuItemInferenceSearch = {
    label: 'Fact Finder', icon: 'clear_all', to: '/analytics/factfinder',
    info: 'Infer knowledge spread across millions of documents.'
};
const menuItemBlackListManager = {
    label: 'My Blocklists', icon: 'featured_play_list', to: '/my/blocklists',
    info: 'Manage your concept blocklists.'
};

const menuItemQuickSearch2 = {
    label: 'Quick Search', icon: 'search', to: '/docfinder/quicksearch',
    info: 'Simple use of the semantic document search engine.'
}
const menuItemAdvancedSearch = {
    label: 'Advanced Search', icon: 'search', to: '/docfinder/advancedsearch',
    info: 'Write API-like search queries.'
}
const menuItemAdvancedSearch3 = {
    label: 'Query Builder', icon: 'search', to: '/docfinder/querybuilder',
    info: 'Easily build complex searches.'
}
const menuItemDocumentCollection = {
    label: 'My Library', icon: 'collections', to: '/my/library',
    info: 'Manage your document collections.',
};
const menuItemApps = {
    label: 'My Apps', icon: 'apps', to: '/my/apps',
    info: 'Manage your apps.',
};
const menuItemChemistrySearch = {
    label: 'Chemistry Search', icon: '', styleClass: 'chem_search', to: '/chemfinder/chemsearch',
    info: `Find chemical compounds${hasUserRole('ROLE_REACTIONSEARCH') ? ' or reactions' : ''}.`
}
const menuItemSequenceSearch = {
    label: 'Sequence Search', icon: 'search', to: '/chemfinder/sequencesearch',
    info: 'Find patent sequences by alignment.'
}
/*const menuItemSequenceExport = {
    label: 'Sequence Exporter', icon: 'file_download', to: '/chemfinder/sequenceexporter',
    info: 'Bulk-export sequences from a document collection.'
}*/

const menuItemDomainBrowser = {
    label: 'Domain Explorer', icon: '', styleClass: 'dom_browser', to: '/analytics/domainexplorer',
    info: 'Explore the available knowledge domains.'
}
const menuItemCooccurrences = {
    label: 'Co-occurrence Analysis', icon: 'explore', to: '/analytics/cooccurrences',
    info: 'Find documents by identifying connections between concepts.'
}
/*const menuItemSemanticExporter = {
    label: 'Semantic Exporter', icon: 'file_download', to: '/analytics/semanticexporter',
    info: 'Bulk-export semantic concepts from a document collection.'
}*/
const menuItemBiomarker = {
    label: 'Biomarker', icon: 'explore', to: '/analytics/biomarker',
    info: 'Biomarker information compiled from various sources.'
};

const menuItemBioactivities = {
    label: 'Bioactivities', icon: 'explore', to: '/analytics/bioactivities',
    info: 'Browse through structure-activity relationship data.'
}

const menuItemTrendAnalysis = {
    label: 'Trend Analysis', icon: 'explore', to: '/analytics/trendanalysis',
    info: 'Analyze search results trends and compare searches.'
}

// --- my research --- //
const menuItemQueries = {
    label: 'My Saved Searches', icon: 'view_list', to: '/my/savedsearches',
    info: 'Manage your saved searches.'
};
const menuItemAlerts = {
    label: 'My Alerts', icon: 'alarm', to: '/my/alerts',
    info: 'Manage your automatic searches.'
};
const menuItemHistory = {
    label: 'My History', icon: 'history', to: '/my/history'
};
const menuItemExportCenter = {
    label: 'My Exports', icon: 'view_list', to: '/my/export-center',
    info: 'Manage your exports.'
};

// --- manager --- //
const menuItemFrameEditor = {
    label: 'Frame Editor', icon: '', to: '/ocprocessor/editor'
}
const menuItemPipelineManager = {
    label: 'Pipeline Manager', icon: '', to: '/ocprocessor/manager'
}
const menuItemServicesStatus = {
    label: 'Services Status', icon: '', to: '/ocprocessor/services'
}

// --- user --- //
const menuItemUserDetails = {
    label: 'User Details', icon: 'account_circle', to: '/user/update'
};


// ------------------------------------------ //
// --- create menu for specific user role --- //
// ------------------------------------------ //

export function createMenuForRole(role, customDashboards, customApps) {

    // --- number of items in menu that should be aligned left --- //
    let leftMenuItemsCounter = 0;
    const menu = [];

    // --- OC|processor pages --- //
    const allOCProcessorMenuItems = [];
    // --- add frame editor --- //
    if (APP_PROPERTIES.ACTIVE_PAGES.frameEditor) {
        allOCProcessorMenuItems.push({ ...menuItemFrameEditor });
    }
    // --- add pipeline manager --- //
    if (APP_PROPERTIES.ACTIVE_PAGES.pipelineManager) {
        allOCProcessorMenuItems.push({ ...menuItemPipelineManager });
    }
    // --- add services status page --- //
    if (APP_PROPERTIES.ACTIVE_PAGES.servicesStatus &&
        (role === 'ROLE_SUPER_ADMIN' || role === 'ROLE_COMPANY_ADMIN' || role === 'ROLE_DEPARTMENT_ADMIN')) {
        //    if (APP_PROPERTIES.ACTIVE_PAGES.servicesStatus) {
        allOCProcessorMenuItems.push({ ...menuItemServicesStatus });
    }
    if (allOCProcessorMenuItems.length > 0) {
        leftMenuItemsCounter++;
        if (allOCProcessorMenuItems.length === 1) {
            allOCProcessorMenuItems[0].isMainMenu = true;
            menu.push(allOCProcessorMenuItems[0]);
        }
        else {
            delete allOCProcessorMenuItems[0].isMainMenu; // ?
            menu.push({
                label: 'OC|Processor',
                icon: 'build',
                isMainMenu: true,
                items: allOCProcessorMenuItems
            });
        }
    }

    const menuItemCustomApps = []

    customApps && customApps.length > 0 && customApps.forEach((app, i) => {
        let name = app.name?.length > 60 ? `${app.name.substring(0, 59)} ...` : app.name
        let info = app.description?.length > 60 ? `${app.description.substring(0, 59)} ...` : app.description
        menuItemCustomApps[i] = { label: name, to: `/apps/${app.id}`, icon: '', info: info }
    })

    menuItemCustomApps.sort((a, b) => a.label > b.label ? 1 : -1)

    // --- add dashboards, search pages, etc. --- //
    let allMyDashboardsMenuItems = [];
    let allMyAppsMenuItems = [];
    // --- add dashboard for saved searches --- //
    if (hasUserRole('ROLE_DASHBOARD')) {
        //delete menuItemMyDashboard.isMainMenu; // ?
        //allMyDashboardsMenuItems.push({ ...menuItemMyDashboard });
    }

    if (!isArrayEmpty(customApps)) {
        customApps.forEach((app, i) => {
            allMyAppsMenuItems.push({ ...menuItemCustomApps[i] });
        })
    }

    if ((allMyAppsMenuItems.length > 0) && hasUserRole('ROLE_APPS')) {
        leftMenuItemsCounter++;
        menu.push({
            label: 'Apps',
            //icon: 'dashboard_customize',
            icon: 'apps',
            isMainMenu: true,
            items: allMyAppsMenuItems/*(allMyAppsMenuItems.length > 0 && allMyStaticAppsMenuItems.length > 0) ?
                [
                    {
                        label: 'Dynamic Apps',
                        items: menuItemCustomApps,
                        isMainMenu: false
                    },
                    {
                        label: 'Static Apps',
                        items: menuItemCustomStaticApps,
                        isMainMenu: false
                    }]
                : (allMyAppsMenuItems.length > 0 && allMyStaticAppsMenuItems.length === 0) ?
                    menuItemCustomApps
                    : menuItemCustomStaticApps*/
        });
    }

    if (allMyDashboardsMenuItems.length > 0) {
        leftMenuItemsCounter++;
        if (allMyDashboardsMenuItems.length === 1) {
            allMyDashboardsMenuItems[0].isMainMenu = true;
            menu.push(allMyDashboardsMenuItems[0]);
        }
        else {
            delete allMyDashboardsMenuItems[0].isMainMenu; // ?
            menu.push({
                label: 'My Dashboards',
                //icon: 'dashboard_customize',
                icon: 'dashboard',
                isMainMenu: true,
                items: allMyDashboardsMenuItems
            });
        }
    }

    const allDocumentSearchMenuItems = [];
    if (APP_PROPERTIES.ACTIVE_PAGES.docSearch) {
        allDocumentSearchMenuItems.push({ ...menuItemQuickSearch2 });
    }
    if (APP_PROPERTIES.ACTIVE_PAGES.docSearch  && hasUserRole('ROLE_QUERYBUILDER')) {
        allDocumentSearchMenuItems.push({ ...menuItemAdvancedSearch3 })
    }
    if (APP_PROPERTIES.ACTIVE_PAGES.docSearch) {
        allDocumentSearchMenuItems.push({ ...menuItemAdvancedSearch })
    }
    if (allDocumentSearchMenuItems.length > 0) {
        leftMenuItemsCounter++;
        if (allDocumentSearchMenuItems.length === 1) {
            allDocumentSearchMenuItems[0].isMainMenu = true;
            menu.push(allDocumentSearchMenuItems[0]);
        }
        else {
            delete allDocumentSearchMenuItems[0].isMainMenu; // ?
            menu.push({
                label: 'Document Finder',
                styleClass: 'doc_search',
                isMainMenu: true,
                items: allDocumentSearchMenuItems
            });
        }
    }

    const allChemistrySearchMenuItems = [];
    if (hasUserRole('ROLE_COMPOUNDSEARCH') || hasUserRole('ROLE_REACTIONSEARCH')) {
        allChemistrySearchMenuItems.push({ ...menuItemChemistrySearch })
    }
    if (hasUserRole('ROLE_SEQUENCESEARCH')) {
        allChemistrySearchMenuItems.push({ ...menuItemSequenceSearch })
    }
    /*if (hasUserRole('ROLE_SEQUENCEEXPORT')) {
        allChemistrySearchMenuItems.push({...menuItemSequenceExport})
    }*/
    if (allChemistrySearchMenuItems.length > 0) {
        leftMenuItemsCounter++;
        if (allChemistrySearchMenuItems.length === 1) {
            allChemistrySearchMenuItems[0].isMainMenu = true;
            menu.push(allChemistrySearchMenuItems[0]);
        }
        else {
            delete allChemistrySearchMenuItems[0].isMainMenu; // ?
            menu.push({
                label: 'Chemistry Finder',
                styleClass: 'chem_search',
                isMainMenu: true,
                items: allChemistrySearchMenuItems
            });
        }
    }

    const allAnalyticsMenuItems = [];
    allAnalyticsMenuItems.push({ ...menuItemDomainBrowser });
    if (hasUserRole('ROLE_COOCS')) {
        allAnalyticsMenuItems.push({ ...menuItemCooccurrences });
    }
    if (hasUserRole('ROLE_SENTENCEANALYSIS')) {
        allAnalyticsMenuItems.push(menuItemSentenceAnalysis)
    }
    if (hasUserRole('ROLE_PATHSEARCH')) {
        allAnalyticsMenuItems.push({ ...menuItemInferenceSearch })
    }
    /*if (hasUserRole('ROLE_SEMANTICEXPORT')) {
        allAnalyticsMenuItems.push({...menuItemSemanticExporter})
    }*/
    if (hasUserRole('ROLE_BIOMARKER')) {
        allAnalyticsMenuItems.push({ ...menuItemBiomarker })
    }
    if (hasUserRole('ROLE_TRENDANALYSIS')) {
        allAnalyticsMenuItems.push({ ...menuItemTrendAnalysis });
    }

    if (hasUserRole('ROLE_BIOACTIVITY')) {
        allAnalyticsMenuItems.push({ ...menuItemBioactivities })
    }
    if (allAnalyticsMenuItems.length > 0) {
        leftMenuItemsCounter++;
        if (allAnalyticsMenuItems.length === 1) {
            allAnalyticsMenuItems[0].isMainMenu = true;
            menu.push(allAnalyticsMenuItems[0]);
        }
        else {
            delete allAnalyticsMenuItems[0].isMainMenu; // ?
            menu.push({
                label: 'Analytics',
                icon: 'timeline',
                isMainMenu: true,
                items: allAnalyticsMenuItems
            });
        }
    }

    const allMyResearchMenuItems = [];
    if (hasUserRole('ROLE_APPS') && (role === 'ROLE_SUPER_ADMIN' || role === 'ROLE_COMPANY_ADMIN')) {
        allMyResearchMenuItems.push({ ...menuItemApps });
    }
    if (hasUserRole('ROLE_LIBRARY')) {
        allMyResearchMenuItems.push({ ...menuItemDocumentCollection });
    }
    if (hasUserRole('ROLE_WATCHLISTS')) {
        allMyResearchMenuItems.push({ ...menuItemQueries });
    }
    if (hasUserRole('ROLE_ALERTS')) {
        allMyResearchMenuItems.push({ ...menuItemAlerts });
    }
    if (APP_PROPERTIES.ACTIVE_PAGES.history) {
        allMyResearchMenuItems.push({ ...menuItemHistory });
    }

    allMyResearchMenuItems.push({ ...menuItemExportCenter });

    if (hasUserRole('ROLE_BLACKLISTS')) {
        allMyResearchMenuItems.push({ ...menuItemBlackListManager })
    }
    if (allMyResearchMenuItems.length > 0) {
        leftMenuItemsCounter++;
        if (allMyResearchMenuItems.length === 1) {
            allMyResearchMenuItems[0].isMainMenu = true;
            menu.push(allMyResearchMenuItems[0]);
        }
        else {
            delete allMyResearchMenuItems[0].isMainMenu;
            menu.push({
                label: 'My Research',
                icon: 'grade',
                isMainMenu: true,
                items: allMyResearchMenuItems
            });
        }
    }

    if (role !== 'NONE') {
        const allSettingsMenuItems = [];
        // --- add changelog, support, feedback --- //
        allSettingsMenuItems.push({ ...menuItemUserDetails });
        /*menu.push({
            label: 'User',
            icon: 'account_circle',
            items: allSettingsMenuItems
        });*/
    }

    // --- add manage menu --- //
    let manageMenuItems = [];

    switch (role) {

        case 'ROLE_SUPER_ADMIN':
            if (APP_PROPERTIES.ACTIVE_PAGES.backendAdministration) {
                manageMenuItems.unshift({
                    ...menuItemBackendAdmin
                })
            }

            manageMenuItems.unshift({
                ...menuItemAdministration
            })
            break;

        case 'ROLE_COMPANY_ADMIN':

            manageMenuItems.unshift({
                ...menuItemAdministration
            })
            break;

        case 'ROLE_DEPARTMENT_ADMIN':

            manageMenuItems.unshift({
                ...menuItemAdministration
            })
            break;

        default:

            manageMenuItems.unshift({
                ...menuItemAdministration
            })
    }

    //console.log(leftMenuItemsCounter)

    return {
        menu: menu,
        leftMenuItemsCount: leftMenuItemsCounter
    };
}