/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { getHeaderToken } from '../../../api/index'
import { APP_PROPERTIES, REPOSITORY_INFO_NOT_AVAILABLE, readFilterDefinitions, readRepositoryFilterMapping } from '../../../properties/index';
import axios from 'axios'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import DocumentResultWrapper from '../../webapi/docresults/DocumentResultWrapper';
import { Element } from 'react-scroll';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { saveAs } from 'file-saver';
import NumberFormat from 'react-number-format';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { checkResultAndGetPayload } from '../../../api';
import { getRepositoryForName } from '../../../api/content/ContentApi';
import { hasUserRole, isArrayEmpty } from '../util';
import { videos } from '../../../properties/videos'
import { fetchSortableFields } from "../../../api/content/DocumentApi";
import { DEFAULT_SORT_CRITERIA, DEFAULT_QUERY_SETTINGS, SEARCH_FILTER_ID_GROUP_BY_PAT_FAM } from "../general/docsearch/searchConstants";
import { determineActiveSortCriteria, createSortFieldsList } from '../util/content';
import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'
import TutorialVideos from '../general/TutorialVideos';
import { addPatentFamilyGroupingToQueryString } from '../general/docsearch/advancedSearch';


const SEARCH_REPOSITORY_NAME = 'patents';

class SequenceSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            activeIndexNucleotide: 0,
            activeIndexProtein: 0,
            mode: [],
            logical: 'and',
            valueNucleotide: '',
            valueNucleotide2: '',
            valueNucleotidePlain: '',
            valueProteinPlain: '',
            valueProtein: '',
            valueProtein2: '',
            parametersNucleotideCollapsed: true,
            parametersProteinCollapsed: true,
            showNucleotideResults: false,
            showProteinResults: false,
            evalueNucleotide: 10,
            evalueProtein: 10,
            fetchingBLASTResults: false,
            fetchingDocumentResults: false,
            blastnResults: [],
            blastpResults: [],
            sequenceIDs: '160000027901',
            patentIDs: '29828348',
            patents: [],
            sequences: [],
            showPatentTable: false,
            showSequenceTable: false,
            selectedNucleotideSequences: [],
            selectedNucleotideSequence: null,
            selectedProteinSequences: [],
            selectedProteinSequence: null,
            showNucleotideCharts: false,
            showNucleotideDocumentResults: false,
            showProteinDocumentResults: false,
            displayNucleotideSequenceDetails: false,
            displayProteinSequenceDetails: false,
            patentNumber: '',
            sortCriteria: {},
            firstNucleotide: 0,
            firstProtein: 0,
            rowsNucleotide: 20,
            rowsProtein: 20,
            queryNucleotide: '',
            queryProtein: '',
            filtersCollapsedNucleotide: true,
            filtersCollapsedProtein: true,
            repositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE,
            pageTutorials: [],
            section: '',
            sectionLabel: '',
            firstNucleotideTable: 0,
            firstProteinTable: 0,
            rowsNucleotideTable: 10,
            rowsProteinTable: 10
        }
        this.op = createRef();
        this.nucleotidePanelTable = createRef();
        this.docResRef = createRef();
        this.docResRefProtein = createRef();
        this.growl = createRef();
    }

    async componentDidMount() {

        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('sequence')) });
        }

        // --- get default repository --- //
        const response = await getRepositoryForName(SEARCH_REPOSITORY_NAME, false);
        const defRepository = checkResultAndGetPayload(response);
        const defaultRepository = defRepository ? defRepository : REPOSITORY_INFO_NOT_AVAILABLE;
        //console.log('-> defaultRepository: ', defaultRepository);
        this.setState({
            repositoryInfo: defaultRepository
        });

        // --- get filter definitions --- //
        const { filterDefinitions } = await readFilterDefinitions() || {};
        const repositoryFilterMap = await readRepositoryFilterMapping() || {};
        this.setState({
            filterDefinitions: filterDefinitions,
            repositoryFilterMap: repositoryFilterMap,
        });

        // @todo: move to searchUtil
        // --- get patent groupings --- //
        const activeRepoFilters = repositoryFilterMap[defaultRepository.name];
        const allowGroupByPatentFamily = activeRepoFilters ? activeRepoFilters.includes(SEARCH_FILTER_ID_GROUP_BY_PAT_FAM) : false;
        let patentFamilyGroupingValues = null, patentFamilyGrouping = null;
        if (allowGroupByPatentFamily) {
            patentFamilyGroupingValues = filterDefinitions[SEARCH_FILTER_ID_GROUP_BY_PAT_FAM]?.filterValues;
            patentFamilyGroupingValues = patentFamilyGroupingValues?.filter(val => {
                return isArrayEmpty(val.repoAllowList) || val.repoAllowList.includes(defaultRepository.name)
            });
            patentFamilyGrouping = patentFamilyGroupingValues?.find(val => val.isDefault);
        }

        this.setState({
            groupByPatFamilies: true,
            allowGroupByPatentFamily: allowGroupByPatentFamily,
            patentFamilyGroupingValues: patentFamilyGroupingValues,
            patentFamilyGrouping: patentFamilyGrouping,
        });

        // --- get sorting criteria --- //
        const schema = await this.getRepositorySchema(defaultRepository.id);
        const sortFields = createSortFieldsList(schema?.sortCriteria, defaultRepository);
        const sortCriteria = determineActiveSortCriteria(sortFields, DEFAULT_QUERY_SETTINGS.defaultSortingOptions, DEFAULT_SORT_CRITERIA);

        this.setState({
            sortFields: sortFields,
            sortCriteria: sortCriteria
        })
    }

    // @todo: move to util
    getRepositorySchema = async (repositoryID) => {
        // --- request repository schema, e.g. sortable fields, available facets, etc. --- //
        let schema;
        if (APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.fetchSortableFields) {
            const response2 = await fetchSortableFields(repositoryID);
            schema = checkResultAndGetPayload(response2, this.growl);
            // --- remove facets that are not active --- //
            if (schema?.facets && this.state.activeFacets) {
                const activeRepoFacets = schema.facets.filter(facID => this.state.activeFacets[facID]);
                schema.facets = activeRepoFacets;
            }
        }
        return schema;
    }


    handleNucleotideChange = (sequence) => {
        //console.log(sequence)
        let splittedStringArray = sequence.split('\n')
        let splittedString = ''
        let counterFASTA = 0
        splittedStringArray.forEach(line => {
            if (line.startsWith('<') || line.startsWith('>')) {
                counterFASTA += 1
            } else {
                splittedString += line
            }
        })
        if (counterFASTA >= 2) {
            this.showMultipleFASTANucleotideGrowl()
        } else if (splittedString.includes('e') || splittedString.includes('E') || splittedString.includes('f') || splittedString.includes('F')
            || splittedString.includes('i') || splittedString.includes('I') || splittedString.includes('j') || splittedString.includes('J')
            || splittedString.includes('l') || splittedString.includes('L') || splittedString.includes('o') || splittedString.includes('O')
            || splittedString.includes('p') || splittedString.includes('P') || splittedString.includes('q') || splittedString.includes('Q')
            || splittedString.includes('x') || splittedString.includes('X') || splittedString.includes('z') || splittedString.includes('Z')) {
            this.showWrongLetterNucleotideGrowl()
        }
        this.setState({
            valueNucleotide: sequence,
            valueNucleotidePlain: splittedString.replace(/[^A-Za-z]+/gi, '').replace(/e|E|f|F|i|I|j|J|l|L|o|O|p|P|q|Q|x|X|z|Z/g, ''),
            //valueNucleotidePlainShow: splittedString.replace(/[^A-Za-z]+/gi, '').replace(/e|E|f|F|i|I|j|J|l|L|o|O|p|P|q|Q|x|X|z|Z/g, '')
        })
    }

    handleProteinChange = (sequence) => {
        //console.log(sequence)
        let splittedStringArray = sequence.split('\n')
        let splittedString = ''
        let counterFASTA = 0
        splittedStringArray.forEach(line => {
            if (line.startsWith('<') || line.startsWith('>')) {
                counterFASTA += 1
            } else {
                splittedString += line
            }
        })
        if (counterFASTA >= 2) {
            this.showMultipleFASTAProteinGrowl()
        } else if (splittedString.includes('j') || splittedString.includes('J') || splittedString.includes('o') || splittedString.includes('O')) {
            this.showWrongLetterProteinGrowl()
        }
        this.setState({
            valueProtein: sequence,
            valueProteinPlain: splittedString.replace(/[^A-Za-z]+/gi, '').replace(/j|J|o|O/g, ''),
            //valueProteinPlainShow: splittedString.replace(/[^A-Za-z]+/gi, '').replace(/j|J|o|O/g, '')
        })
    }

    handleSearchBlastN = () => {
        var self = this
        let splittedStringArray = self.state.valueNucleotide.split('\n')
        let splittedString = ''
        let counterFASTA = 0
        splittedStringArray.forEach(line => {
            if (line.startsWith('<') || line.startsWith('>')) {
                counterFASTA += 1
            } else {
                splittedString += line
            }
        })
        if (counterFASTA >= 2) {
            self.showMultipleFASTANucleotideGrowl()
        } else if (splittedString.includes('e') || splittedString.includes('E') || splittedString.includes('f') || splittedString.includes('F')
            || splittedString.includes('i') || splittedString.includes('I') || splittedString.includes('j') || splittedString.includes('J')
            || splittedString.includes('l') || splittedString.includes('L') || splittedString.includes('o') || splittedString.includes('O')
            || splittedString.includes('p') || splittedString.includes('P') || splittedString.includes('q') || splittedString.includes('Q')
            || splittedString.includes('x') || splittedString.includes('X') || splittedString.includes('z') || splittedString.includes('Z')) {
            self.showWrongLetterNucleotideGrowl()
        } else {
            self.setState({
                fetchingBLASTResults: true,
                showNucleotideResults: false,
                showNucleotideDocumentResults: false,
                valueNucleotidePlain: splittedString.replace(/[^A-Za-z]+/gi, ''),
                valueNucleotidePlainShow: splittedString.replace(/[^A-Za-z]+/gi, '').replace(/e|E|f|F|i|I|j|J|l|L|o|O|p|P|q|Q|x|X|z|Z/g, ''),
                selectedNucleotideSequence: null,
                firstNucleotideTable: 0
            }, () =>
                axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-search/blastn`, { evalue: self.state.evalueNucleotide, sequence: splittedString.replace(/[^A-Za-z]+/gi, '').toLowerCase() }, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.setState({
                            blastnResults: response.data.output.BlastOutput2[0].report.results.search.hits,
                            fetchingBLASTResults: false,
                            showNucleotideResults: true,
                            databaseHitsNucleotide: response.data.output.BlastOutput2[0].report.results.search.stat.db_num
                        })
                    })
                    .catch(function (error) {
                        console.log(error)
                        self.setState({
                            fetchingBLASTResults: false
                        })
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            )
        }
    }

    clearNucleotideInput = () => {
        this.setState({
            valueNucleotide: '',
            valueNucleotidePlain: ''
        })
    }

    clearProteinInput = () => {
        this.setState({
            valueProtein: '',
            valueProteinPlain: ''
        })
    }

    showMultipleFASTANucleotideGrowl = (e) => {
        let msg = { severity: 'warn', summary: `More than one sequence!`, detail: `Your input contains two or more sequences. You can only search for one sequence at a time.`, life: 5000 };
        this.growl.current.show(msg);
    }

    showWrongLetterNucleotideGrowl = (e) => {
        let msg = { severity: 'warn', summary: `Wrong letter!`, detail: `Your sequence contains one or more letters that do not follow the IUB/IUPAC nucleic acid codes. Allowed letters: A, C, G, T, N, U, K, S, Y, M, W, R, B, D, H and V (case-insensitive).`, life: 5000 };
        this.growl.current.show(msg);
    }

    showCopySuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Sequence successfully copied to clipboard.' };
        this.growl.current.show(msg);
    }

    handleSearchBlastP = () => {
        var self = this
        let splittedStringArray = self.state.valueProtein.split('\n')
        let splittedString = ''
        let counterFASTA = 0
        splittedStringArray.forEach(line => {
            if (line.startsWith('<') || line.startsWith('>')) {
                counterFASTA += 1
            } else {
                splittedString += line
            }
        })
        if (counterFASTA >= 2) {
            self.showMultipleFASTAProteinGrowl()
        } else if (splittedString.includes('j') || splittedString.includes('J') || splittedString.includes('o') || splittedString.includes('O')) {
            self.showWrongLetterProteinGrowl()
        } else {
            self.setState({
                fetchingBLASTResults: true,
                showProteinResults: false,
                showProteinDocumentResults: false,
                valueProteinPlain: splittedString.replace(/[^A-Za-z]+/gi, ''),
                valueProteinPlainShow: splittedString.replace(/[^A-Za-z]+/gi, '').replace(/j|J|o|O/g, ''),
                selectedProteinSequence: null,
                firstProteinTable: 0
            }, () =>
                axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-search/blastp`, { evalue: self.state.evalueProtein, sequence: splittedString.replace(/[^A-Za-z]+/gi, '').toUpperCase() }, { headers: getHeaderToken() })
                    .then(function (response) {
                        self.setState({
                            blastpResults: response.data.output.BlastOutput2[0].report.results.search.hits,
                            fetchingBLASTResults: false,
                            showProteinResults: true,
                            databaseHitsProtein: response.data.output.BlastOutput2[0].report.results.search.stat.db_num
                        })
                    })
                    .catch(function (error) {
                        self.setState({
                            fetchingBLASTResults: false
                        })
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            )
        }
    }

    showMultipleFASTAProteinGrowl = (e) => {
        let msg = { severity: 'warn', summary: `More than one sequence!`, detail: `Your input contains two or more sequences. You can only search for one sequence at a time.`, life: 5000 };
        this.growl.current.show(msg);
    }

    showWrongLetterProteinGrowl = (e) => {
        let msg = { severity: 'warn', summary: `Wrong letter!`, detail: `Your sequence contains one or more letters that do not follow the IUB/IUPAC amino acid codes. Allowed letters: A, P, B, Q, C, R, D, S, E, T, F, U, G, V, H, W ,I ,Y ,K ,Z ,L ,X ,M, N and * (case-insensitive).`, life: 5000 };
        this.growl.current.show(msg);
    }

    fetchPatentsNucleotide = async (IDs) => {
        let docIDString = ''
        let queryDocIDs = ''
        IDs.forEach(id => {
            queryDocIDs += 'docid:"' + id + "\" "
        })
        docIDString += '(' + queryDocIDs.slice(0, -1) + ')'

        this.setState({
            queryNucleotide: docIDString
        }, () => this.myCallbackChartNucleotide());
    }

    fetchPatentsProtein = async (IDs) => {
        let docIDString = ''
        let queryDocIDs = ''
        IDs.forEach(id => {
            queryDocIDs += 'docid:"' + id + "\" "
        })
        docIDString += '(' + queryDocIDs.slice(0, -1) + ')'

        this.setState({
            queryProtein: docIDString
        }, () => this.myCallbackChartProtein());
    }

    showSearchBlastNGrowl = (e) => {
        let msg = { severity: 'success', summary: `BLAST!`, detail: `BLAST with ${this.state.valueNucleotide}`, life: 5000 };
        this.growl.current.show(msg);
    }

    showSearchBlastPGrowl = (e) => {
        let msg = { severity: 'success', summary: `BLAST!`, detail: `BLAST with ${this.state.valueProtein}`, life: 5000 };
        this.growl.current.show(msg);
    }

    handleAddSequence = (e) => {
        this.showAddSequenceGrowl()
        this.setState({
            showSecond: true
        })
    }

    showAddSequenceGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Sequence added', life: 5000 };
        this.growl.current.show(msg);
    }

    myCallbackFromGroupByChangeNucleotide = () => {

        let request = {}
        const query = addPatentFamilyGroupingToQueryString(this.state.queryNucleotide, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        request.query = query;
        request.repository = this.state.repositoryInfo.id
        request.api = 'oc-webapi'
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        var self = this
        self.setState({
            rerender: true,
            showNucleotideDocumentResults: false,
            showSimilarDocuments: false,
            repository: this.state.repositoryInfo.id,
            repositoryLabel: this.state.repositoryInfo.label,
            rerenderCharts: false,
            rerenderGraph: true
        }, () => self.fetchDocumentSearchResultsFunctionNucleotide(self.props.history, {
            request: request,
            startIndex: this.state.firstNucleotide,
            count: this.state.rowsNucleotide,
            repository: this.state.repositoryInfo.id
        }))
    }

    myCallbackChartNucleotide = async (rep, repLabel) => { // (showPubs, api, rep, repLabel, apiLabel, custHits)
        let request = {}
        request.query = addPatentFamilyGroupingToQueryString(this.state.queryNucleotide, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        request.repository = this.state.repositoryInfo.id
        request.api = 'oc-webapi'
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        var self = this
        self.setState({
            rerender: true,
            showNucleotideDocumentResults: false,
            showSimilarDocuments: false,
            repository: this.state.repositoryInfo.id,
            repositoryLabel: this.state.repositoryInfo.label,
            rerenderCharts: false,
            rerenderGraph: true
        }, () => self.fetchDocumentSearchResultsFunctionNucleotide(self.props.history, {
            request: request,
            startIndex: this.state.firstNucleotide,
            count: this.state.rowsNucleotide,
            repository: this.state.repositoryInfo.id
        }))
    }

    myCallbackChartNucleotideWithoutGroupByChange = async (query) => { // (showPubs, api, rep, repLabel, apiLabel, custHits)
        let request = {}
        request.query = query
        request.repository = this.state.repositoryInfo.id
        request.api = 'oc-webapi'
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        var self = this
        self.setState({
            rerender: true,
            showSimilarDocuments: false,
            repository: this.state.repositoryInfo.id,
            repositoryLabel: this.state.repositoryInfo.label,
            rerenderCharts: false,
            rerenderGraph: true
        }, () => self.fetchDocumentSearchResultsFunctionNucleotide(self.props.history, {
            request: request,
            startIndex: this.state.firstNucleotide,
            count: this.state.rowsNucleotide,
            repository: this.state.repositoryInfo.id
        }))
    }

    myCallbackFromGroupByChangeProtein = () => {
        let request = {}
        request.query = addPatentFamilyGroupingToQueryString(this.state.queryProtein, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        request.repository = this.state.repositoryInfo.id
        request.api = 'oc-webapi'
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        var self = this
        self.setState({
            rerender: true,
            showProteinDocumentResults: false,
            showSimilarDocuments: false,
            repository: this.state.repositoryInfo.id,
            repositoryLabel: this.state.repositoryInfo.label,
            rerenderCharts: false,
            rerenderGraph: true,
            fetchingDocumentResults: true
        }, () => self.fetchDocumentSearchResultsFunctionProtein(self.props.history, {
            request: request,
            startIndex: this.state.firstProtein,
            count: this.state.rowsProtein,
            repository: this.state.repositoryInfo.id
        }))
    }

    myCallbackChartProtein = async (rep, repLabel) => { // (showPubs, api, rep, repLabel, apiLabel, custHits)
        let request = {}
        request.query = addPatentFamilyGroupingToQueryString(this.state.queryProtein, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        request.repository = this.state.repositoryInfo.id
        request.api = 'oc-webapi'
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        var self = this
        self.setState({
            rerender: true,
            showProteinDocumentResults: false,
            showSimilarDocuments: false,
            repository: this.state.repositoryInfo.id,
            repositoryLabel: this.state.repositoryInfo.label,
            rerenderCharts: false,
            rerenderGraph: true
        }, () => self.fetchDocumentSearchResultsFunctionProtein(self.props.history, {
            request: request,
            startIndex: this.state.firstProtein,
            count: this.state.rowsProtein,
            repository: this.state.repositoryInfo.id
        }))
    }

    myCallbackChartProteinWithoutGroupByChange = async (query) => { // (showPubs, api, rep, repLabel, apiLabel, custHits)
        let request = {}
        request.query = query
        request.repository = this.state.repositoryInfo.id
        request.api = 'oc-webapi'
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        var self = this
        self.setState({
            rerender: true,
            showProteinDocumentResults: false,
            showSimilarDocuments: false,
            repository: this.state.repositoryInfo.id,
            repositoryLabel: this.state.repositoryInfo.label,
            rerenderCharts: false,
            rerenderGraph: true
        }, () => self.fetchDocumentSearchResultsFunctionProtein(self.props.history, {
            request: request,
            startIndex: this.state.firstProtein,
            count: this.state.rowsProtein,
            repository: this.state.repositoryInfo.id
        }))
    }

    fetchDocumentSearchResultsFunctionNucleotide = async (history, request, scroll) => {
        this.setState({
            fetchingDocumentResults: true
        })
        let self = this
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/documents?repositoryId=${this.state.repositoryInfo.id}&count=${request.count}&start=${request.startIndex + 1}`, JSON.stringify(request.request), { headers: getHeaderToken() })
            .then(function (response) {
                let docArray = []
                response.data.documents.forEach(doc => {
                    docArray = [...docArray, { docId: doc.ocDocId, repository: doc.repository, title: doc.title, repositoryId: doc.repositoryId }]
                })
                if (hasUserRole('ROLE_LIBRARY')) {
                    self.fetchDocInformationNucleotide(docArray)
                }
                self.setState({
                    docArrayNucleotide: docArray,
                    documentsNucleotide: response.data,
                    showNucleotideDocumentResults: true,
                    fetchingDocumentResults: false,
                })
                if (scroll !== false) {
                    // not working!!!
                    //window.scrollTo(0, self.docResRef.offsetTop)
                    // const offsetTop = document.getElementById("documentTable")?.offsetTop;
                    // console.log('offsetTop', offsetTop);
                    // if (offsetTop) {
                    //     window.scrollTo(0, offsetTop + 10);
                    // }
                }
            })
            .catch(function (error) {
                console.log(error)
                self.setState({
                    fetchingDocumentResults: false,
                    docArrayNucleotide: [],
                    documentsNucleotide: []
                })
                if (error.response) {
                    if (error.response.status === 400) {
                        self.showSearchResultsFailGrowl(error)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                }
                else {
                    console.log('error: ', error);
                }
            })
    }


    fetchDocumentSearchResultsFunctionProtein = async (history, request, scroll) => {
        this.setState({
            fetchingDocumentResults: true
        })
        let self = this
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/documents?repositoryId=${this.state.repositoryInfo.id}&count=${request.count}&start=${request.startIndex + 1}`, JSON.stringify(request.request), { headers: getHeaderToken() })
            .then(function (response) {
                let docArray = []
                response.data.documents.forEach(doc => {
                    docArray = [...docArray, { docId: doc.ocDocId, repository: doc.repository, title: doc.title, repositoryId: doc.repositoryId }]
                })
                if (hasUserRole('ROLE_LIBRARY')) {
                    self.fetchDocInformationProtein(docArray)
                }
                self.setState({
                    docArrayProtein: docArray,
                    documentsProtein: response.data,
                    fetchingDocumentResults: false,
                    showProteinDocumentResults: true,
                })
                if (scroll !== false) {
                    /*scrollToComponent(self.docResRefProtein, {
                        align: 'top',
                        offset: -140,
                        duration: 500
                    })*/
                    window.scrollTo(0, self.docResRefProtein.offsetTop)
                }
            }).catch(function (error) {
                self.setState({
                    fetchingDocumentResults: false,
                    docArrayProtein: [],
                    documentsProtein: []
                })
                if (error.response) {
                    if (error.response.status === 400) {
                        self.showSearchResultsFailGrowl(error)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                }
                else {
                    console.log('error: ', error);
                }
            })
    }

    showSearchResultsFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e.response.data, life: 6000 };
        this.growl.current.show(msg);
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.current.show(msg);
    }

    fetchDocInformationNucleotide = async (docArray) => {
        //console.log(docArray)
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, docArray, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response)
                self.setState({
                    docInformationNucleotide: response.data
                })
            })
    }

    fetchDocInformationProtein = async (docArray) => {
        let self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, docArray, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    docInformationProtein: response.data
                })
            })
    }

    handleShowSequenceDetailsNucleotide = (e, sequenceID, rowData) => {
        //console.log(rowData)
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-data/nucleotide?ocids=${sequenceID}`, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response)
                let singleNucleotideSequenceAlignment
                if (self.state.blastnResults && self.state.blastnResults.length > 0) {
                    self.state.blastnResults.forEach(res => {
                        if (parseInt(res.description[0].accession) === response.data.sequences[0].ocid) {
                            singleNucleotideSequenceAlignment = res
                        }
                    })
                }
                //console.log(singleNucleotideSequenceAlignment)
                self.setState({
                    displayNucleotideSequenceDetails: true,
                    singleNucleotideSequenceDetails: response.data.sequences[0],
                    singleNucleotideSequenceOCID: response.data.sequences[0].ocid,
                    singleNucleotideGaps: rowData.gaps,
                    singleNucleotideIdentity: rowData.identity,
                    singleNucleotideSequenceAlignment: singleNucleotideSequenceAlignment,
                    activeIndexNucleotide: 0
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleShowSequenceDetailsProtein = (e, sequenceID, rowData) => {
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-data/protein?ocids=${sequenceID}`, { headers: getHeaderToken() })
            .then(function (response) {
                let singleProteinSequenceAlignment
                if (self.state.blastpResults && self.state.blastpResults.length > 0) {
                    self.state.blastpResults.forEach(res => {
                        if (parseInt(res.description[0].accession) === response.data.sequences[0].ocid) {
                            singleProteinSequenceAlignment = res
                        }
                    })
                }
                //console.log(singleProteinSequenceAlignment)
                self.setState({
                    displayProteinSequenceDetails: true,
                    singleProteinSequenceDetails: response.data.sequences[0],
                    singleProteinSequenceOCID: response.data.sequences[0].ocid,
                    singleProteinGaps: rowData.gaps,
                    singleProteinIdentity: rowData.identity,
                    singleProteinSequenceAlignment: singleProteinSequenceAlignment,
                    activeIndexProtein: 0
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleExportSingleFASTANucleotide = (e, rowData) => {
        this.setState({
            fetchingBLASTResults: true
        })
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-data/nucleotide/fasta?seqId=${rowData.id}`, { headers: getHeaderToken(), responseType: 'blob' })
            .then(function (response) {
                saveAs(new Blob([response.data]), `sequence_search_nucleotide_${rowData.id}.fasta.zip`)
                self.setState({
                    fetchingBLASTResults: false
                })
            })
            .catch(function (error) {
                self.setState({
                    fetchingBLASTResults: false
                })
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleExportSingleFASTAProtein = (e, rowData) => {
        this.setState({
            fetchingBLASTResults: true
        })
        let self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-data/protein/fasta?seqId=${rowData.id}`, { headers: getHeaderToken(), responseType: 'blob' })
            .then(function (response) {
                saveAs(new Blob([response.data]), `sequence_search_protein_${rowData.id}.fasta.zip`)
                self.setState({
                    fetchingBLASTResults: false
                })
            })
            .catch(function (error) {
                self.setState({
                    fetchingBLASTResults: false
                })
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    myCallbackFromFieldChangeNucleotide = (sortField) => {
        const sortCriteria = this.state.sortFields?.find(sf => sf.value === sortField) || DEFAULT_SORT_CRITERIA;
        this.setState({
            first: 0,
            rows: 20,
            sortCriteria: sortCriteria,
        }, () => this.myCallbackFromSortNucleotide());
    }

    myCallbackFromSortModeChangeNucleotide = (sortMode) => {
        const sortCriteria = this.state.sortCriteria ? { ...this.state.sortCriteria, sortMode: sortMode } : {};
        this.setState({
            first: 0,
            rows: 20,
            sortCriteria: sortCriteria
        }, () => this.myCallbackFromSortNucleotide());
    }

    myCallbackFromFieldChangeProtein = (sortField) => {
        const sortCriteria = this.state.sortFields?.find(sf => sf.value === sortField) || DEFAULT_SORT_CRITERIA;
        this.setState({
            first: 0,
            rows: 20,
            sortCriteria: sortCriteria,
        }, () => this.myCallbackFromSortProtein());
    }

    myCallbackFromSortModeChangeProtein = (sortMode) => {
        const sortCriteria = this.state.sortCriteria ? { ...this.state.sortCriteria, sortMode: sortMode } : {};
        this.setState({
            first: 0,
            rows: 20,
            sortCriteria: sortCriteria
        }, () => this.myCallbackFromSortProtein());
    }

    myCallbackFromSortNucleotide = async () => {
        let request = {}
        request.api = 'oc-webapi'
        request.query = addPatentFamilyGroupingToQueryString(this.state.queryNucleotide, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        this.setState({
            fetchingBLASTResults: false
        }, () => this.myCallbackChartNucleotideWithoutGroupByChange(request.query));
    }

    myCallbackFromSortProtein = async () => {
        let request = {}
        request.api = 'oc-webapi'
        request.query = addPatentFamilyGroupingToQueryString(this.state.queryProtein, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        this.setState({
            fetchingBLASTResults: false
        }, () => this.myCallbackChartProteinWithoutGroupByChange(request.query));
    }

    myCallbackFromGroupByPatentFamilyChangeNucleotide = async (patFamGrouping) => {
        this.setState({
            firstNucleotide: 0,
            rowsNucleotide: 20,
            selectedDocuments: {},
            patentFamilyGrouping: patFamGrouping
        }, () => this.myCallbackFromGroupByChangeNucleotide());
    }

    myCallbackFromGroupByPatentFamilyChangeProtein = async (patFamGrouping) => {
        this.setState({
            firstProtein: 0,
            rowsProtein: 20,
            selectedDocuments: {},
            patentFamilyGrouping: patFamGrouping
        }, () => this.myCallbackFromGroupByChangeProtein());
    }

    myCallbackFromPageChangeNucleotide = async (startIndex, count) => {
        var self = this
        let request = {}
        request.api = 'oc-webapi'
        request.query = addPatentFamilyGroupingToQueryString(this.state.queryNucleotide, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        this.setState({
            firstNucleotide: startIndex,
            rowsNucleotide: count
        }, () => self.myCallbackChartNucleotideWithoutGroupByChange(request.query))
    }

    myCallbackFromPageChangeProtein = async (startIndex, count) => {
        var self = this
        let request = {}
        request.api = 'oc-webapi'
        request.query = addPatentFamilyGroupingToQueryString(this.state.queryProtein, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        request.sortBy = this.state.sortCriteria.sortField;
        request.sortDirection = this.state.sortCriteria.sortMode;
        request.sortFactor = this.state.sortCriteria.sortFactor;

        this.setState({
            firstProtein: startIndex,
            rowsProtein: count
        }, () => self.myCallbackChartProteinWithoutGroupByChange(request.query))
    }

    myCallbackFromExportNucleotide = (exportData) => {
        let documentRequest = {}
        //documentRequest['repository'] = this.state.repositoryInfo.id
        documentRequest.query = addPatentFamilyGroupingToQueryString(this.state.queryNucleotide, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        //request['facettes'] = ["DOMAIN__chem", "DOMAIN__species", "DOMAIN__substances", "DOMAIN__drugs", "DOMAIN__companies", "Inventor", "Assignee", "Author"]
        documentRequest.api = 'oc-webapi'
        documentRequest.sortBy = this.state.sortCriteria.sortField;
        documentRequest.sortDirection = this.state.sortCriteria.sortMode;
        documentRequest.sortFactor = this.state.sortCriteria.sortFactor;

        let request = {};
        if (exportData.docIDs) {
            request.documentIdentifiers = exportData.docIDs;
        }
        else {
            request.exportQuery = {
                documentRequest: documentRequest,
                repositoryIdentifier: this.state.repositoryInfo.id
            }
        }
        if (exportData.maxNumOfResults) {
            request.documentLimit = exportData.maxNumOfResults;
        }

        this.props.fetchPublicationListXLSX(this.props.history, {
            request: request
        })
    }

    myCallbackFromExportProtein = (exportData) => {
        let documentRequest = {}
        //documentRequest['repository'] = this.state.repositoryInfo.id
        documentRequest.query = addPatentFamilyGroupingToQueryString(this.state.queryProtein, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        //request['facettes'] = ["DOMAIN__chem", "DOMAIN__species", "DOMAIN__substances", "DOMAIN__drugs", "DOMAIN__companies", "Inventor", "Assignee", "Author"]
        documentRequest.api = 'oc-webapi'
        documentRequest.sortBy = this.state.sortCriteria.sortField;
        documentRequest.sortDirection = this.state.sortCriteria.sortMode;
        documentRequest.sortFactor = this.state.sortCriteria.sortFactor;

        let request = {};
        if (exportData.docIDs) {
            request.documentIdentifiers = exportData.docIDs;
        }
        else {
            request.exportQuery = {
                documentRequest: documentRequest,
                repositoryIdentifier: this.state.repositoryInfo.id
            }
        }
        if (exportData.maxNumOfResults) {
            request.documentLimit = exportData.maxNumOfResults;
        }

        this.props.fetchPublicationListXLSX(this.props.history, {
            request: request
        })
    }

    myCallbackFromReadChangeNucleotide = (data) => {

        //console.log('doc data changed: ', data);

        let newArray = this.state.docInformationNucleotide
        if (newArray.length > 0) {
            newArray.forEach(doc => {
                if (data[0].docId === doc.docId) {
                    Object.assign(doc, data[0])
                } else if (!newArray.some(doc => doc.docId === data[0].docId)) {
                    newArray = [...newArray, data[0]]
                }
            })
        } else {
            newArray = [...newArray, data[0]]
        }
        this.setState({
            docInformationNucleotide: newArray
        })
        //this.fetchDocInformation(this.state.docArray)
    }

    myCallbackFromReadChangeProtein = (data) => {
        let newArray = this.state.docInformationProtein
        if (newArray.length > 0) {
            newArray.forEach(doc => {
                if (data[0].docId === doc.docId) {
                    Object.assign(doc, data[0])
                } else if (!newArray.some(doc => doc.docId === data[0].docId)) {
                    newArray = [...newArray, data[0]]
                }
            })
        } else {
            newArray = [...newArray, data[0]]
        }
        this.setState({
            docInformationProtein: newArray
        })
        //this.fetchDocInformation(this.state.docArray)
    }

    handleNucleotideSelectionChange = (e) => {
        //console.log(e)
        this.setState({
            selectedNucleotideSequence: e.value
        })
    }

    handleProteinSelectionChange = (e) => {
        //console.log(e)
        this.setState({
            selectedProteinSequence: e.value
        })
    }

    searchNucleotidePatents = (e) => {
        //console.log(this.state.selectedNucleotideSequence)
        var self = this
        self.setState({
            fetchingBLASTResults: true,
            showPatentTable: false,
            selectedNucleotideSequenceID: self.state.selectedNucleotideSequence.id,
            firstNucleotide: 0,
            rowsNucleotide: 20,
            selectedDocuments: {}
        }, () =>
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-search/patents?seqIds=${self.state.selectedNucleotideSequence.id}`, { headers: getHeaderToken() })
                .then(function (response) {
                    let patIDs = []
                    Object.keys(response.data.seqIdPatentsMap).forEach(pat => {
                        response.data.seqIdPatentsMap[pat].forEach(entry => {
                            // @todo: MW should send correct IDs
                            // if (entry.patDocId && !`${entry.patDocId}`.startsWith('patents.')) {
                            //     entry.patDocId = `patents.${entry.patDocId}`;
                            // }
                            // patIDs = [...patIDs, entry.patDocId]
                            patIDs.push(entry.patDocId);
                        })
                    })
                    if (patIDs.length > 0) {
                        self.setState({
                            patentSeqDataNucleotide: response.data.seqIdPatentsMap[self.state.selectedNucleotideSequence.id],
                            fetchingBLASTResults: false
                        }/*, () => //scrollToComponent(self.nucleotidePanelTable, { align: 'bottom', duration: 100 })
                            window.scrollTo(0, self.nucleotidePanelTable.offsetTop)*/)
                        self.fetchPatentsNucleotide(patIDs)
                    } else {
                        self.setState({
                            fetchingBLASTResults: false,
                            documentsNucleotide: [],
                            showNucleotideDocumentResults: true
                        }, () => window.scrollTo(0, self.docResRef.offsetTop)
                        )
                    }
                })
                .catch(function (error) {
                    self.setState({
                        fetchingBLASTResults: false
                    })
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        )
    }

    searchProteinPatents = (e) => {
        var self = this
        self.setState({
            fetchingBLASTResults: true,
            showPatentTable: false,
            selectedProteinSequenceID: self.state.selectedProteinSequence.id,
            firstProtein: 0,
            rowsProtein: 20,
            selectedDocuments: {}
        }, () =>
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-search/patents?seqIds=${self.state.selectedProteinSequence.id}`, { headers: getHeaderToken() })
                .then(function (response) {
                    let patIDs = []
                    Object.keys(response.data.seqIdPatentsMap).forEach(pat => {
                        response.data.seqIdPatentsMap[pat].forEach(entry => {
                            // @todo: MW should send correct IDs
                            // if (entry.patDocId && !`${entry.patDocId}`.startsWith('patents.')) {
                            //     entry.patDocId = `patents.${entry.patDocId}`;
                            // }
                            // patIDs = [...patIDs, entry.patDocId]
                            patIDs.push(entry.patDocId);
                        })
                    })
                    if (patIDs.length > 0) {
                        self.setState({
                            patentSeqDataProtein: response.data.seqIdPatentsMap[self.state.selectedProteinSequence.id],
                            fetchingBLASTResults: false
                        })
                        self.fetchPatentsProtein(patIDs)
                    } else {
                        self.setState({
                            fetchingBLASTResults: false,
                            documentsProtein: [],
                            showProteinDocumentResults: true
                        }, () => window.scrollTo(0, self.docResRef.offsetTop)
                        )
                    }
                })
                .catch(function (error) {
                    self.setState({
                        fetchingBLASTResults: false
                    })
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        )
    }

    numTemplate = (rowData) => {
        if (rowData.hasOwnProperty('num')) {
            return <React.Fragment>
                <span className="p-column-title">#</span>
                {rowData.num}
            </React.Fragment>
        }
    }

    scoreTemplate = (rowData) => {
        if (rowData.hasOwnProperty('score')) {
            return <React.Fragment>
                <span className="p-column-title">Score</span>
                {rowData.score}
            </React.Fragment>
        }
    }

    NucleotideIDBody = (rowData) => {
        if (rowData.hasOwnProperty('id')) {
            return <React.Fragment>
                <span className="p-column-title">Sequence ID</span>
                <a id="detailSbjct"
                    title='Click to show details'
                    onClick={(e) => this.handleShowSequenceDetailsNucleotide(e, rowData.id, rowData)}
                    style={{ display: 'inline-block' }}>{rowData.id}</a>
            </React.Fragment>
        }
    }

    sequenceLengthBody = (rowData) => {
        if (rowData.hasOwnProperty('length')) {
            return <React.Fragment>
                <span className="p-column-title">Sequence length</span>
                <NumberFormat value={rowData.length} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} />
            </React.Fragment>
        }
    }

    bitScoreTemplate = (rowData) => {
        if (rowData.hasOwnProperty('bit_score')) {
            return <React.Fragment>
                <span className="p-column-title">Score (bits)</span>
                {rowData.bit_score}
            </React.Fragment>
        }
    }

    expectTemplate = (rowData) => {
        if (rowData.hasOwnProperty('expect')) {
            return <React.Fragment>
                <span className="p-column-title">Expect value</span>
                {rowData.expect}
            </React.Fragment>
        }
    }

    identityTemplate = (rowData) => {
        if (rowData.hasOwnProperty('identity')) {
            return <React.Fragment>
                <span className="p-column-title">Identity</span>
                {rowData.identity}
            </React.Fragment>
        }
    }

    positivesTemplate = (rowData) => {
        if (rowData.hasOwnProperty('positives')) {
            return <React.Fragment>
                <span className="p-column-title">Positives</span>
                {rowData.positives}
            </React.Fragment>
        }
    }

    gapsTemplate = (rowData) => {
        if (rowData.hasOwnProperty('gaps')) {
            return <React.Fragment>
                <span className="p-column-title">Gaps</span>
                {rowData.gaps}
            </React.Fragment>
        }
    }


    downloadBody = (rowData) => {
        return <React.Fragment>
            <span className="p-column-title">FASTA (.zip)</span>
            <a id="detailSbjct"
                title='FASTA (.zip)'
                onClick={(e) => this.handleExportSingleFASTANucleotide(e, rowData)}
                style={{ display: 'inline-block' }}>Download</a>
        </React.Fragment>
    }

    downloadBodyProtein = (rowData) => {
        return <React.Fragment>
            <span className="p-column-title">FASTA (.zip)</span>
            <a id="detailSbjct"
                title='FASTA (.zip)'
                onClick={(e) => this.handleExportSingleFASTAProtein(e, rowData)}
                style={{ display: 'inline-block' }}>Download</a>
        </React.Fragment>
    }

    nucleotideDetailsBody = (rowData) => {
        return <a id="detailSbjct"
            title='Click to show details'
            onClick={(e) => this.handleShowSequenceDetailsNucleotide(e, rowData.id)}
            style={{ display: 'inline-block', width: '30%' }}>Details</a>
    }

    ProteinIDBody = (rowData) => {
        if (rowData.hasOwnProperty('id')) {
            return <React.Fragment>
                <span className="p-column-title">Sequence ID</span>
                <a id="detailSbjct"
                    title='Click to show details'
                    onClick={(e) => this.handleShowSequenceDetailsProtein(e, rowData.id, rowData)}
                    style={{ display: 'inline-block' }}>{rowData.id}</a>
            </React.Fragment>
        }
    }

    proteinDetailsBody = (rowData) => {
        return <a id="detailSbjct"
            title='Click to show details'
            onClick={(e) => this.handleShowSequenceDetailsProtein(e, rowData.id)}
            style={{ display: 'inline-block', width: '30%' }}>Details</a>
    }

    onDocumentSearchFiltersChanged = (filters) => {
        this.setState({
            filters: filters
        });
        //console.log('onDocumentSearchFiltersChanged: ', filters);
    }

    onDocumentSearchFiltersChangedProtein = (filters) => {
        this.setState({
            filtersProtein: filters
        });
        //console.log('onDocumentSearchFiltersChanged: ', filters);
    }

    onCustomPageNucleotideTable = (e) => {
        this.setState({
            firstNucleotideTable: e.first,
            rowsNucleotideTable: e.rows
        })
    }

    onCustomPageProteinTable = (e) => {
        this.setState({
            firstProteinTable: e.first,
            rowsProteinTable: e.rows
        })
    }



    render() {

        const fetchingData = this.state.fetchingBLASTResults || this.state.fetchingDocumentResults

        const internalQueryNucleotide = addPatentFamilyGroupingToQueryString(this.state.queryProtein, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        const internalQueryProtein = addPatentFamilyGroupingToQueryString(this.state.queryProtein, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);

        //console.log(this.state.selectedNucleotideSequence)

        const logicals = [
            { label: 'AND', value: 'and' },
            { label: 'OR', value: 'or' }
        ]

        //let blastnResultsShow

        let sortedBlastNResults

        if (this.state.blastnResults && this.state.blastnResults.length > 0) {
            sortedBlastNResults = this.state.blastnResults.sort(function (a, b) {
                return b.hsps[0].score - a.hsps[0].score
            })
        }

        let blastnResultsTable = []

        sortedBlastNResults && sortedBlastNResults.length > 0 && sortedBlastNResults.forEach((res, i) => {
            //console.log(res)
            let identity = `${res.hsps[0].identity}/${this.state.valueNucleotidePlainShow.length} - ${((100 * res.hsps[0].identity) / this.state.valueNucleotidePlainShow.length).toFixed(2)}%`
            let gaps = `${res.hsps[0].gaps}/${this.state.valueNucleotidePlainShow.length} - ${((100 * res.hsps[0].gaps) / this.state.valueNucleotidePlainShow.length).toFixed(2)}%`
            blastnResultsTable = [...blastnResultsTable, {
                num: i + 1, length: res.len, id: res.description[0].accession, bit_score: res.hsps[0].bit_score,
                score: res.hsps[0].score, expect: res.hsps[0].evalue, identity: identity, gaps: gaps
            }]
        })

        //let blastpResultsShow

        let sortedBlastPResults

        if (this.state.blastpResults && this.state.blastpResults.length > 0) {
            sortedBlastPResults = this.state.blastpResults.sort(function (a, b) {
                return b.hsps[0].score - a.hsps[0].score
            })
        }

        let blastpResultsTable = []

        sortedBlastPResults && sortedBlastPResults.length > 0 && sortedBlastPResults.forEach((res, i) => {
            //console.log(res)
            let identity = `${res.hsps[0].identity}/${this.state.valueProteinPlainShow.length} - ${((100 * res.hsps[0].identity) / this.state.valueProteinPlainShow.length).toFixed(2)}%`
            let gaps = `${res.hsps[0].gaps}/${this.state.valueProteinPlainShow.length} - ${((100 * res.hsps[0].gaps) / this.state.valueProteinPlainShow.length).toFixed(2)}%`
            let positives = `${res.hsps[0].positive}/${this.state.valueProteinPlainShow.length} (${((100 * res.hsps[0].positive) / this.state.valueProteinPlainShow.length).toFixed(2)}%)`
            blastpResultsTable = [...blastpResultsTable, {
                num: i + 1, length: res.len, id: res.description[0].accession, bit_score: res.hsps[0].bit_score,
                score: res.hsps[0].score, expect: res.hsps[0].evalue, identity: identity, gaps: gaps, positives: positives
            }]
        })

        let patentsShow = []

        if (this.state.patents && Object.keys(this.state.patents).length > 0) {
            Object.keys(this.state.patents).forEach(pat => {
                this.state.patents[pat].forEach(entry => {
                    patentsShow = [...patentsShow, entry]
                })
            })
        }

        let sequencesShow = []

        if (this.state.sequences && Object.keys(this.state.sequences).length > 0) {
            Object.keys(this.state.sequences).forEach(seq => {
                this.state.sequences[seq].forEach(entry => {
                    sequencesShow = [...sequencesShow, entry]
                })
            })
        }

        let hitsNucleotide
        let databaseHitsNucleotide
        let hitsProtein
        let databaseHitsProtein

        hitsNucleotide = this.state.blastnResults ? this.state.blastnResults.length : 0
        databaseHitsNucleotide = this.state.databaseHitsNucleotide ? this.state.databaseHitsNucleotide : 0

        hitsProtein = this.state.blastpResults ? this.state.blastpResults.length : 0
        databaseHitsProtein = this.state.databaseHitsProtein ? this.state.databaseHitsProtein : 0

        //const repoStats = this.state.repositoryStatistics && this.state.repositoryStatistics.results.length > 0 ?
        //this.state.repositoryStatistics.results[0] : {};


        let collections = []

        this.state.documentCollections && this.state.containsPatents && this.state.documentCollections.forEach((collection, i) => {
            if (collection.numberDocuments > 0 && this.state.containsPatents[i] === 'true') {
                collections = [...collections, { label: collection.name, value: collection.id }]
            }
        })

        /*const footerDisplayNucleotideSequenceDetails = (
            <div>
                <Button label="FASTA (.zip)"
                    className='primaryButton p-button-sm'
                    //disabled={this.state.query.length > 0 ? false : true}
                    icon='pi-md-file-download'
                    onClick={this.handleExportSingleFASTANucleotide}
                    style={{ marginLeft: 0, marginTop: 0 }} />
            </div>
        )*/

        /*const footerDisplayProteinSequenceDetails = (
            <div>
                <Button label="FASTA (.zip)"
                    className='primaryButton p-button-sm'
                    //disabled={this.state.query.length > 0 ? false : true}
                    icon='pi-md-file-download'
                    onClick={this.handleExportSingleFASTAProtein}
                    style={{ marginLeft: 0, marginTop: 0 }} />
            </div>
        )*/

        let singleNucleotideSequenceOCID = this.state.singleNucleotideSequenceDetails && this.state.singleNucleotideSequenceDetails.ocid
        let singleNucleotideSequenceLength = this.state.singleNucleotideSequenceDetails && this.state.singleNucleotideSequenceDetails.seqLength ? this.state.singleNucleotideSequenceDetails.seqLength : 'No information available'
        //let singleNucleotideSequence = this.state.singleNucleotideSequenceDetails ? this.state.singleNucleotideSequenceDetails.sequence : ''
        let singleNucleotideSequenceWrapped = ''

        if (this.state.singleNucleotideSequenceDetails && this.state.singleNucleotideSequenceDetails.sequence) {
            let originalNucleotideString = JSON.parse(JSON.stringify(this.state.singleNucleotideSequenceDetails.sequence))
            /*while (originalNucleotideString.length > 0) {
                singleNucleotideSequenceWrapped += originalNucleotideString.substring(0, 70) + '\n'
                originalNucleotideString = originalNucleotideString.substring(70)
            }*/
            singleNucleotideSequenceWrapped = originalNucleotideString.match(/.{1,10}/g)
            singleNucleotideSequenceWrapped = singleNucleotideSequenceWrapped.join(' ').replace(/(.{66})/g, '$1\n')
        } else {
            singleNucleotideSequenceWrapped = 'No information available'
        }

        let singleNucleotideSequenceAlignmentWrapped = ''
        let singleNucleotideSequenceAlignmentMidlineWrapped = ''
        let singleNucleotideSequenceAlignmentTargetWrapped = ''
        let originalNucleotideAlignmentQueryFrom
        let originalNucleotideAlignmentQueryTo
        let originalNucleotideAlignmentHitFrom
        let originalNucleotideAlignmentHitTo

        if (this.state.singleNucleotideSequenceAlignment && this.state.singleNucleotideSequenceAlignment.hsps[0].qseq.toLowerCase()) {
            //console.log(this.state.singleNucleotideSequenceAlignment.hsps[0])
            let originalNucleotideAlignmentString = this.state.singleNucleotideSequenceAlignment.hsps[0].qseq.toLowerCase()
            let originalNucleotideAlignmentMidline = this.state.singleNucleotideSequenceAlignment.hsps[0].midline.toLowerCase()
            let originalNucleotideAlignmentTarget = this.state.singleNucleotideSequenceAlignment.hsps[0].hseq.toLowerCase()
            originalNucleotideAlignmentQueryFrom = this.state.singleNucleotideSequenceAlignment.hsps[0].query_from
            originalNucleotideAlignmentQueryTo = this.state.singleNucleotideSequenceAlignment.hsps[0].query_to
            originalNucleotideAlignmentHitFrom = this.state.singleNucleotideSequenceAlignment.hsps[0].hit_from
            originalNucleotideAlignmentHitTo = this.state.singleNucleotideSequenceAlignment.hsps[0].hit_to

            singleNucleotideSequenceAlignmentWrapped = originalNucleotideAlignmentString.match(/.{1,10}/g)
            singleNucleotideSequenceAlignmentMidlineWrapped = originalNucleotideAlignmentMidline.match(/.{1,10}/g)
            singleNucleotideSequenceAlignmentTargetWrapped = originalNucleotideAlignmentTarget.match(/.{1,10}/g)

            singleNucleotideSequenceAlignmentWrapped = singleNucleotideSequenceAlignmentWrapped.join(' ').replace(/(.{88})/g, '$1\n')
            singleNucleotideSequenceAlignmentMidlineWrapped = singleNucleotideSequenceAlignmentMidlineWrapped.join(' ').replace(/(.{88})/g, '$1\n')
            singleNucleotideSequenceAlignmentTargetWrapped = singleNucleotideSequenceAlignmentTargetWrapped.join(' ').replace(/(.{88})/g, '$1\n')
        } else {
            singleNucleotideSequenceAlignmentWrapped = 'No information available'
        }

        let completeAlignment = ''
        let completeStartsQuery = ''
        let completeEndsQuery = ''
        let completeStartsTarget = ''
        let completeEndsTarget = ''

        for (let i = 88, j = 0; i < singleNucleotideSequenceAlignmentWrapped.length + 88; i += 88, j++) {
            //console.log(i + j)
            //console.log(i - 88 + j)
            // console.log(singleNucleotideSequenceAlignmentTargetWrapped.substring(i - 88 + j, i + j))
            // if(i <= singleNucleotideSequenceAlignmentWrapped.length){
            //completeAlignment += 'QUERY ' + (parseInt(originalNucleotideAlignmentQueryFrom) + (j*80)) + '  ' + singleNucleotideSequenceAlignmentWrapped.substring(i - 88 + j, i + j) + '  ' +  (parseInt(originalNucleotideAlignmentQueryFrom)+((j+1)*80)-1) + '\n' + '       ' + singleNucleotideSequenceAlignmentMidlineWrapped.substring(i - 88 + j, i + j) + '\n' +
            //'SBJCT ' + (parseInt(originalNucleotideAlignmentHitFrom) + (j*80)) + '  ' + singleNucleotideSequenceAlignmentTargetWrapped.substring(i - 88 + j, i + j) + '  ' + (parseInt(originalNucleotideAlignmentHitFrom)+((j+1)*80)-1) + '\n' + '\n' + '\n'
            // completeAlignment +=  singleNucleotideSequenceAlignmentWrapped.substring(i - 88 + j, i + j) + '\n' + singleNucleotideSequenceAlignmentMidlineWrapped.substring(i - 88 + j, i + j) + '\n' +
            // + singleNucleotideSequenceAlignmentTargetWrapped.substring(i - 88 + j, i + j) + '\n' + '\n' + '\n'
            //}else {
            //completeAlignment += 'QUERY ' + (parseInt(originalNucleotideAlignmentQueryFrom) + (j*80)) + '  ' + singleNucleotideSequenceAlignmentWrapped.substring(i - 88 + j, i + j) + '  ' + originalNucleotideAlignmentQueryTo + '\n' + '       ' + singleNucleotideSequenceAlignmentMidlineWrapped.substring(i - 88 + j, i + j) + '\n' +
            //'SBJCT ' + (parseInt(originalNucleotideAlignmentHitFrom) + (j*80)) + '  ' + singleNucleotideSequenceAlignmentTargetWrapped.substring(i - 88 + j, i + j) + '  ' + originalNucleotideAlignmentHitTo + '\n' + '\n' + '\n'
            completeAlignment += singleNucleotideSequenceAlignmentWrapped.substring(i - 88 + j, i + j) + '\n' + singleNucleotideSequenceAlignmentMidlineWrapped.substring(i - 88 + j, i + j) + '\n' +
                singleNucleotideSequenceAlignmentTargetWrapped.substring(i - 88 + j, i + j) + '\n'
            // }

            if (i <= singleNucleotideSequenceAlignmentWrapped.length) {
                completeStartsQuery += (parseInt(originalNucleotideAlignmentQueryFrom) + (j * 80)) + '\n'
                completeEndsQuery += (parseInt(originalNucleotideAlignmentQueryFrom) + ((j + 1) * 80) - 1) + '\n'
                completeStartsTarget += (parseInt(originalNucleotideAlignmentHitFrom) + (j * 80)) + '\n'
                completeEndsTarget += (parseInt(originalNucleotideAlignmentHitFrom) + ((j + 1) * 80) - 1) + '\n'
            } else {
                completeStartsQuery += (parseInt(originalNucleotideAlignmentQueryFrom) + (j * 80)) + '\n'
                completeEndsQuery += originalNucleotideAlignmentQueryTo + '\n'
                completeStartsTarget += (parseInt(originalNucleotideAlignmentHitFrom) + (j * 80)) + '\n'
                completeEndsTarget += originalNucleotideAlignmentHitTo + '\n'
            }
        }
        //console.log(completeAlignment)

        //console.log(completeStartsQuery)

        let singleProteinSequenceOCID = this.state.singleProteinSequenceDetails && this.state.singleProteinSequenceDetails.ocid
        let singleProteinSequenceLength = this.state.singleProteinSequenceDetails && this.state.singleProteinSequenceDetails.seqLength ? this.state.singleProteinSequenceDetails.seqLength : 'No information available'
        //let singleProteinSequence = this.state.singleProteinSequenceDetails ? this.state.singleProteinSequenceDetails.sequence : ''
        let singleProteinSequenceWrapped = ''

        if (this.state.singleProteinSequenceDetails && this.state.singleProteinSequenceDetails.sequence) {
            let originalProteinString = JSON.parse(JSON.stringify(this.state.singleProteinSequenceDetails.sequence))
            /*while (originalProteinString.length > 0) {
                singleProteinSequenceWrapped += originalProteinString.substring(0, 70) + '\n'
                originalProteinString = originalProteinString.substring(70)
            }*/
            singleProteinSequenceWrapped = originalProteinString.match(/.{1,10}/g)
            singleProteinSequenceWrapped = singleProteinSequenceWrapped.join(' ').replace(/(.{66})/g, '$1\n')
        } else {
            singleProteinSequenceWrapped = 'No information available'
        }

        let singleProteinSequenceAlignmentWrapped = ''
        let singleProteinSequenceAlignmentMidlineWrapped = ''
        let singleProteinSequenceAlignmentTargetWrapped = ''
        let originalProteinAlignmentQueryFrom
        let originalProteinAlignmentQueryTo
        let originalProteinAlignmentHitFrom
        let originalProteinAlignmentHitTo

        if (this.state.singleProteinSequenceAlignment && this.state.singleProteinSequenceAlignment.hsps[0].qseq.toLowerCase()) {
            let originalProteinAlignmentString = this.state.singleProteinSequenceAlignment.hsps[0].qseq.toUpperCase()
            let originalProteinAlignmentMidline = this.state.singleProteinSequenceAlignment.hsps[0].midline.toUpperCase()
            let originalProteinAlignmentTarget = this.state.singleProteinSequenceAlignment.hsps[0].hseq.toUpperCase()
            originalProteinAlignmentQueryFrom = this.state.singleProteinSequenceAlignment.hsps[0].query_from
            originalProteinAlignmentQueryTo = this.state.singleProteinSequenceAlignment.hsps[0].query_to
            originalProteinAlignmentHitFrom = this.state.singleProteinSequenceAlignment.hsps[0].hit_from
            originalProteinAlignmentHitTo = this.state.singleProteinSequenceAlignment.hsps[0].hit_to

            singleProteinSequenceAlignmentWrapped = originalProteinAlignmentString.match(/.{1,10}/g)
            singleProteinSequenceAlignmentMidlineWrapped = originalProteinAlignmentMidline.match(/.{1,10}/g)
            singleProteinSequenceAlignmentTargetWrapped = originalProteinAlignmentTarget.match(/.{1,10}/g)

            singleProteinSequenceAlignmentWrapped = singleProteinSequenceAlignmentWrapped.join(' ').replace(/(.{88})/g, '$1\n')
            singleProteinSequenceAlignmentMidlineWrapped = singleProteinSequenceAlignmentMidlineWrapped.join(' ').replace(/(.{88})/g, '$1\n')
            singleProteinSequenceAlignmentTargetWrapped = singleProteinSequenceAlignmentTargetWrapped.join(' ').replace(/(.{88})/g, '$1\n')
        } else {
            singleProteinSequenceAlignmentWrapped = 'No information available'
        }

        let completeAlignmentProtein = ''
        let completeStartsQueryProtein = ''
        let completeEndsQueryProtein = ''
        let completeStartsTargetProtein = ''
        let completeEndsTargetProtein = ''

        //console.log(singleProteinSequenceAlignmentWrapped)

        for (let i = 88, j = 0; i < singleProteinSequenceAlignmentWrapped.length + 88; i += 88, j++) {
            completeAlignmentProtein += singleProteinSequenceAlignmentWrapped.substring(i - 88 + j, i + j) + '\n' + singleProteinSequenceAlignmentMidlineWrapped.substring(i - 88 + j, i + j) + '\n' +
                singleProteinSequenceAlignmentTargetWrapped.substring(i - 88 + j, i + j) + '\n'

            if (i <= singleProteinSequenceAlignmentWrapped.length) {
                completeStartsQueryProtein += (parseInt(originalProteinAlignmentQueryFrom) + (j * 80)) + '\n'
                completeEndsQueryProtein += (parseInt(originalProteinAlignmentQueryFrom) + ((j + 1) * 80) - 1) + '\n'
                completeStartsTargetProtein += (parseInt(originalProteinAlignmentHitFrom) + (j * 80)) + '\n'
                completeEndsTargetProtein += (parseInt(originalProteinAlignmentHitFrom) + ((j + 1) * 80) - 1) + '\n'
            } else {
                completeStartsQueryProtein += (parseInt(originalProteinAlignmentQueryFrom) + (j * 80)) + '\n'
                completeEndsQueryProtein += originalProteinAlignmentQueryTo + '\n'
                completeStartsTargetProtein += (parseInt(originalProteinAlignmentHitFrom) + (j * 80)) + '\n'
                completeEndsTargetProtein += originalProteinAlignmentHitTo + '\n'
            }
        }


        let splitted = completeAlignment.split("\n")
        let splittedQueryStarts = completeStartsQuery.split("\n")
        let splittedQueryEnds = completeEndsQuery.split("\n")
        let splittedTargetStarts = completeStartsTarget.split("\n")
        let splittedTargetEnds = completeEndsTarget.split("\n")
        let splittedClean = []
        let splittedCleanQueryStarts = []
        let splittedCleanQueryEnds = []
        let splittedCleanTargetStarts = []
        let splittedCleanTargetEnds = []
        let ind = []

        for (let i = 0; i < splitted.length; i++) {
            if (splitted[i] !== '') {
                if (splitted[i].endsWith(" ")) {
                    splittedClean = [...splittedClean, splitted[i].slice(0, -1)]
                } else {
                    splittedClean = [...splittedClean, splitted[i]]
                }
            }
        }
        for (let i = 0; i < splittedQueryStarts.length; i++) {
            if (splittedQueryStarts[i] !== '') {
                if (splittedQueryStarts[i].endsWith(" ")) {
                    splittedCleanQueryStarts = [...splittedCleanQueryStarts, splittedQueryStarts[i].slice(0, -1)]
                } else {
                    splittedCleanQueryStarts = [...splittedCleanQueryStarts, splittedQueryStarts[i]]
                }
            }
        }
        for (let i = 0; i < splittedQueryEnds.length; i++) {
            if (splittedQueryEnds[i] !== '') {
                if (splittedQueryEnds[i].endsWith(" ")) {
                    splittedCleanQueryEnds = [...splittedCleanQueryEnds, splittedQueryEnds[i].slice(0, -1)]
                } else {
                    splittedCleanQueryEnds = [...splittedCleanQueryEnds, splittedQueryEnds[i]]
                }
            }
        }
        for (let i = 0; i < splittedTargetStarts.length; i++) {
            if (splittedTargetStarts[i] !== '') {
                if (splittedTargetStarts[i].endsWith(" ")) {
                    splittedCleanTargetStarts = [...splittedCleanTargetStarts, splittedTargetStarts[i].slice(0, -1)]
                } else {
                    splittedCleanTargetStarts = [...splittedCleanTargetStarts, splittedTargetStarts[i]]
                }
            }
        }
        for (let i = 0; i < splittedTargetEnds.length; i++) {
            if (splittedTargetEnds[i] !== '') {
                if (splittedTargetEnds[i].endsWith(" ")) {
                    splittedCleanTargetEnds = [...splittedCleanTargetEnds, splittedTargetEnds[i].slice(0, -1)]
                } else {
                    splittedCleanTargetEnds = [...splittedCleanTargetEnds, splittedTargetEnds[i]]
                }
            }
            if (i === 0) {
                ind[i] = 2
            } else if (i === 1) {
                ind[i] = 5
            } else {
                ind[i] = ind[i - 1] + 3
            }
        }

        let splittedProtein = completeAlignmentProtein.split("\n")
        let splittedQueryStartsProtein = completeStartsQueryProtein.split("\n")
        let splittedQueryEndsProtein = completeEndsQueryProtein.split("\n")
        let splittedTargetStartsProtein = completeStartsTargetProtein.split("\n")
        let splittedTargetEndsProtein = completeEndsTargetProtein.split("\n")
        let splittedCleanProtein = []
        let splittedCleanQueryStartsProtein = []
        let splittedCleanQueryEndsProtein = []
        let splittedCleanTargetStartsProtein = []
        let splittedCleanTargetEndsProtein = []
        let indProtein = []

        for (let i = 0; i < splittedProtein.length; i++) {
            if (splittedProtein[i] !== '') {
                if (splittedProtein[i].endsWith(" ")) {
                    splittedCleanProtein = [...splittedCleanProtein, splittedProtein[i].slice(0, -1)]
                } else {
                    splittedCleanProtein = [...splittedCleanProtein, splittedProtein[i]]
                }
            }
        }
        for (let i = 0; i < splittedQueryStartsProtein.length; i++) {
            if (splittedQueryStartsProtein[i] !== '') {
                if (splittedQueryStartsProtein[i].endsWith(" ")) {
                    splittedCleanQueryStartsProtein = [...splittedCleanQueryStartsProtein, splittedQueryStartsProtein[i].slice(0, -1)]
                } else {
                    splittedCleanQueryStartsProtein = [...splittedCleanQueryStartsProtein, splittedQueryStartsProtein[i]]
                }
            }
        }
        for (let i = 0; i < splittedQueryEndsProtein.length; i++) {
            if (splittedQueryEndsProtein[i] !== '') {
                if (splittedQueryEndsProtein[i].endsWith(" ")) {
                    splittedCleanQueryEndsProtein = [...splittedCleanQueryEndsProtein, splittedQueryEndsProtein[i].slice(0, -1)]
                } else {
                    splittedCleanQueryEndsProtein = [...splittedCleanQueryEndsProtein, splittedQueryEndsProtein[i]]
                }
            }
        }
        for (let i = 0; i < splittedTargetStartsProtein.length; i++) {
            if (splittedTargetStartsProtein[i] !== '') {
                if (splittedTargetStartsProtein[i].endsWith(" ")) {
                    splittedCleanTargetStartsProtein = [...splittedCleanTargetStartsProtein, splittedTargetStartsProtein[i].slice(0, -1)]
                } else {
                    splittedCleanTargetStartsProtein = [...splittedCleanTargetStartsProtein, splittedTargetStartsProtein[i]]
                }
            }
        }
        for (let i = 0; i < splittedTargetEndsProtein.length; i++) {
            if (splittedTargetEndsProtein[i] !== '') {
                if (splittedTargetEndsProtein[i].endsWith(" ")) {
                    splittedCleanTargetEndsProtein = [...splittedCleanTargetEndsProtein, splittedTargetEndsProtein[i].slice(0, -1)]
                } else {
                    splittedCleanTargetEndsProtein = [...splittedCleanTargetEndsProtein, splittedTargetEndsProtein[i]]
                }
            }
            if (i === 0) {
                indProtein[i] = 2
            } else if (i === 1) {
                indProtein[i] = 5
            } else {
                indProtein[i] = indProtein[i - 1] + 3
            }
        }

        //console.log("ind:",ind)

        let score = (<label title='Score'>Score</label>)
        let bit_score = (<label title='Bit score'>Score (bits)</label>)
        let identities = (<label title='Identity'>Identity</label>)
        let positives = (<label title='Positives'>Positives</label>)
        let gaps = (<label title='Gaps'>Gaps</label>)

        return (
            <div className="grid">
                <Toast ref={this.growl} />

                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >

                    <div className='col-12' style={{ paddingBottom: 0, paddingTop: 10 }}>
                        <div className='grid'>
                            <div className="col textAlignLeft">
                                <h1 style={{ marginBottom: 0 }} className="pageHeader">Sequence Search</h1>
                            </div>
                            <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                                {!isArrayEmpty(this.state.pageTutorials) ?
                                    <span style={{ marginLeft: 30 }}>
                                        <TutorialVideos
                                            pageTutorials={this.state.pageTutorials}
                                            section='sequence'
                                            sectionLabel='Sequence Search'
                                        /></span> : null}
                            </div>
                        </div>
                    </div>


                    <TabView activeIndex={this.state.activeIndex} style={{ paddingLeft: 7 }} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                        <TabPanel header="Nucleotide (blastn)">
                            <div style={{ paddingLeft: 5, color: "#757575", lineHeight: "20px" }}>
                                <label>Search for nucleotide sequences (DNA, RNA) in our sequence BLAST index (blastn). The index contains millions of individual sequences extracted from patents. Unique sequences often occur in multiple source documents so there are much more patent sequence references than there are sequences.
                                    To query the index, simply paste a plain text or FASTA format sequence into the search field and hit the BLAST button. Select an interesting sequence from the result list of similar sequences and click on “Search patents” to find patents referencing this exact sequence. You can only search for one sequence at a time and the query sequence is expected to follow the IUB/IUPAC nucleic acid codes.</label>
                            </div>
                            <div className='col-12' style={{ paddingLeft: 5 }}>
                                <div>
                                    {/*<div style={{ marginBottom: 10 }} >
                                                <label style={{ marginRight: 10, marginBottom: 10, verticalAlign: 'bottom' }}>Upload file</label>
                                                <FileUpload name="demo[]" url="./upload" multiple={false}
                                                    accept=".fasta" style={{ marginTop: 10 }} />
        </div>*/}

                                    <div>
                                        {/*} <label style={{ marginRight: 10, verticalAlign: 'bottom' }}>Or paste sequence</label>*/}
                                        <div style={{ marginTop: 10 }}>
                                            <InputTextarea rows={10} cols={100} value={this.state.valueNucleotide}
                                                onChange={(e) => this.handleNucleotideChange(e.target.value)}
                                                autoResize={false}
                                                placeholder="Try attaatttcatccttttcataaacat"
                                                style={{ width: '100%' }} />
                                            {
                                                this.state.showSecond &&
                                                <div>
                                                    <InputTextarea rows={10} cols={100} value={this.state.valueNucleotide2}
                                                        onChange={(e) => this.setState({ valueNucleotide2: e.target.value })}
                                                        autoResize={false}
                                                        placeholder="Paste second sequence"
                                                        style={{ width: '100%', marginTop: 10 }} />
                                                    <Dropdown
                                                        className='switch'
                                                        value={this.state.logical}
                                                        options={logicals}
                                                        // disabled={this.props.queryNames.length > 1 ? false : true}
                                                        onChange={(e) => { this.setState({ logical: e.value }) }}
                                                        style={{ width: '200px', marginTop: 10, textAlign: 'center', 'min-width': '5em' }}
                                                        placeholder='Select logical operator' />
                                                </div>
                                            }
                                            {/*<Button label="Add sequence"
                                                    className='blue-grey-btn'
                                                    disabled={true}
                                                    onClick={this.handleAddSequence}
                                                    icon="pi-md-add"
                                            style={{ marginLeft: 0, marginTop: 10 }} />*/}
                                            <div style={{ marginTop: 0, textAlign: 'right' }}>
                                                {/*<Fieldset legend='Parameters'>*/}
                                                {/*<label htmlFor='evalueNucleotide' style={{ marginRight: 10 }}>Expect threshold</label>*/}
                                                {this.state.valueNucleotidePlain && this.state.valueNucleotidePlain.length > 0 ?
                                                    <React.Fragment>
                                                        <label style={{ float: 'left', color: '#757575' }}><NumberFormat value={this.state.valueNucleotidePlain.length} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} />{this.state.valueNucleotidePlain.length === 1 ? ' nucleotide' : ' nucleotides'}</label>
                                                        <a id="detailSbjct"
                                                            title='Clear input'
                                                            onClick={(e) => this.clearNucleotideInput(e)}
                                                            style={{ display: 'inline-block', float: 'left', marginLeft: 15 }}>Clear</a>
                                                    </React.Fragment>
                                                    : null
                                                }
                                                <div style={{ paddingTop: 10 }}>
                                                    <a style={{ marginRight: 10, marginTop: 5 }} target="_blank" rel="noopener noreferrer"
                                                        href={'https://blast.ncbi.nlm.nih.gov/Blast.cgi?CMD=Web&PAGE_TYPE=BlastDocs&DOC_TYPE=BlastHelp#expect'}>Expect threshold</a>
                                                    <InputText id='evalueNucleotide' keyfilter='alphanum' placeholder='e-value' value={this.state.evalueNucleotide}
                                                        onChange={(e) => this.setState({ evalueNucleotide: e.target.value })} style={{ width: 100 }} />
                                                    <Button label="BLAST"
                                                        className='primaryButton p-button-sm'
                                                        disabled={this.state.valueNucleotide !== '' ? false : true}
                                                        onClick={this.handleSearchBlastN}
                                                        //icon="pi-md-search"
                                                        style={{ marginLeft: 10, marginTop: 10, marginRight: 0, verticalAlign: "unset" }} />
                                                </div>
                                                {/*</Fieldset>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div style={{ textAlign: 'right' }}>
                                <Button label="BLAST"
                                    className='primaryButton p-button-sm'
                                    disabled={this.state.valueNucleotide !== '' ? false : true}
                                    onClick={this.handleSearchBlastN}
                                    icon="pi-md-search"
                                    style={{ marginLeft: 0, marginTop: 10 }} />
                                        </div>*/}
                            {this.state.showNucleotideResults &&
                                <React.Fragment>
                                    <div style={{ marginTop: 15, marginBottom: 0, paddingLeft: 5 }}>
                                        {/*<Panel header={`Matched sequences for your input sequence with ${this.state.valueNucleotidePlain.length} bp`} ref={(Panel) => { this.nucleotidePanel = Panel }}>*/}
                                        <div style={{ textAlign: 'left', marginBottom: 10 }}>
                                            <label style={{ fontWeight: 'bold', fontSize: 'larger' }}><NumberFormat value={hitsNucleotide} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} />{hitsNucleotide && hitsNucleotide === 1 ? ` hit found` : ` hits found`} in our database with <NumberFormat value={databaseHitsNucleotide} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} /> nucleotide sequences</label>
                                        </div>
                                        {blastnResultsTable.length > 0 &&
                                            <div className="datatable-responsive-demo">
                                                <DataTable value={blastnResultsTable}
                                                    removableSort={true}
                                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                                    paginator={true}
                                                    rows={this.state.rowsNucleotideTable}
                                                    first={this.state.firstNucleotideTable}
                                                    selection={this.state.selectedNucleotideSequence}
                                                    onSelectionChange={e => this.handleNucleotideSelectionChange(e)}
                                                    selectionMode="radiobutton"
                                                    //className="dataTableQueries checkboxCircle standardTableHeader"
                                                    className="p-datatable-responsive-demo"
                                                    onPage={(e) => this.onCustomPageNucleotideTable(e)}
                                                    paginatorTemplate={CustomPaginatorTemplate}
                                                    paginatorLeft={<CustomPaginatorLeftSide
                                                        first={this.state.firstNucleotideTable}
                                                        contentLength={blastnResultsTable.length}
                                                        rows={this.state.rowsNucleotideTable}>
                                                    </CustomPaginatorLeftSide>}
                                                    paginatorRight={<CustomPaginatorRightSide
                                                        rows={this.state.rowsNucleotideTable}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                rowsNucleotideTable: e.value
                                                            })
                                                        }}
                                                    >
                                                    </CustomPaginatorRightSide>}>
                                                    <Column field="check" selectionMode='single' style={{ width: '4em', fontWeight: 'normal' }} />
                                                    <Column field="num" header="#" style={{}} body={this.numTemplate} />
                                                    <Column field="score" header={score} body={this.scoreTemplate} />
                                                    <Column field="id" header="Sequence ID" body={this.NucleotideIDBody} />
                                                    <Column field="length" header="Sequence length" body={this.sequenceLengthBody} />
                                                    <Column field="bit_score" header={bit_score} body={this.bitScoreTemplate} />
                                                    <Column field="expect" header="Expect value" body={this.expectTemplate} />
                                                    <Column field="identity" header={identities} body={this.identityTemplate} />
                                                    <Column field="gaps" header={gaps} body={this.gapsTemplate} />
                                                    <Column field="" header="FASTA (.zip)" body={this.downloadBody} style={{}} />
                                                </DataTable>
                                                {/*blastnResultsShow*/}
                                            </div>
                                        }
                                        {/*</Panel>*/}
                                    </div>
                                    {blastnResultsTable.length > 0 &&
                                        <React.Fragment>
                                            <div style={{ textAlign: 'right', marginTop: 15, marginBottom: 15 }}>
                                                <Button label="Search patents"
                                                    className='primaryButton p-button-sm'
                                                    disabled={this.state.selectedNucleotideSequence !== null ? false : true}
                                                    onClick={(e) => this.searchNucleotidePatents(e)}
                                                    //icon="pi-md-search"
                                                    style={{ marginTop: 0, marginRight: 0 }} />
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                            {this.state.showPatentTable &&
                                <div style={{ marginTop: 15 }}>
                                    <Panel header='Patents' ref={this.nucleotidePanelTable}>
                                        <DataTable value={patentsShow}
                                            removableSort={true}
                                            rowsPerPageOptions={[20, 50, 100]}>
                                            <Column field="patDocId" header="Doc ID" />
                                            <Column field="patId" header="Patent ID" />
                                        </DataTable>
                                    </Panel>
                                </div>
                            }
                            {/*this.state.showNucleotideCharts ?
                                    <Element ref={(ref) => { this.repoStatsRef = ref; }}>
                                        <Panel id="repositoryOverview"
                                            className='repoStatisticsPanel removePanelHeader'
                                            header={`${repoStats.hits ? addThousandsSeparatorToNumber(repoStats.hits, ' ') : '...'} ${repoStats.hits > 1 ? 'hits' : 'hit'}`}
                                            toggleable={true}
                                            style={{ marginTop: "0px" }}>
                                            <RepositoryOverview
                                                    //headerText=
                                                    repoStatisticsResults={repoStats}
                                                    fetching={fetchingData}
                                                    callbackFromRepositoryClick={this.myCallbackChart}
                                                    selectedRepository={this.state.repository}
                                                />
                                        </Panel>
                                    </Element> : null
                                        */}
                            {this.state.showNucleotideDocumentResults && this.state.documentsNucleotide.documents && this.state.documentsNucleotide.documents.length > 0 ?
                                <Element ref={this.docResRef}>
                                    <DocumentResultWrapper
                                        showResults={true}
                                        repositoryInfo={this.state.repositoryInfo}
                                        documents={this.state.documentsNucleotide}
                                        selectedDocuments={this.state.selectedDocuments}
                                        onDocumentSelectionChange={(selDocs) => { this.setState({ selectedDocuments: selDocs }) }}
                                        docInformation={this.state.docInformationNucleotide}
                                        onChangeDocInformation={this.myCallbackFromReadChangeNucleotide}
                                        ratingReadOnly={true}
                                        onPageChange={this.myCallbackFromPageChangeNucleotide}
                                        onExport={this.myCallbackFromExportNucleotide}
                                        fetching={this.props.fetching}
                                        sortFields={this.state.sortFields}
                                        sortCriteria={this.state.sortCriteria}
                                        onSortFieldChange={this.myCallbackFromFieldChangeNucleotide}
                                        onSortModeChange={this.myCallbackFromSortModeChangeNucleotide}
                                        first={this.state.firstNucleotide}
                                        rows={this.state.rowsNucleotide}
                                        query={internalQueryNucleotide}
                                        internalQuery={internalQueryNucleotide}
                                        rerender={this.state.rerender}
                                        rerenderGraph={this.state.rerenderGraph}
                                        onClick={this.myCallbackFromClick}
                                        hideSimilaritySearch={true}
                                        history={this.props.history}
                                        addOrganisms={true}
                                        patentInfo={this.state.patentSeqDataNucleotide}
                                        selectedSequenceID={this.state.selectedNucleotideSequenceID}
                                        showGroupByPatentFamily={true}
                                        isPatentFamilyMutliple={true}
                                        patentFamilyGroupingValues={this.state.patentFamilyGroupingValues}
                                        onPatentFamilyGroupingChange={this.myCallbackFromGroupByPatentFamilyChangeNucleotide}
                                        patentFamilyGrouping={this.state.patentFamilyGrouping}
                                    />
                                </Element> :
                                this.state.showNucleotideDocumentResults && this.state.documentsNucleotide.length === 0 ?
                                    <p style={{ fontWeight: 'bold', paddingLeft: 7 }}>No documents found in Patents for this sequence.</p>
                                    : null}
                        </TabPanel>
                        <TabPanel header="Protein (blastp)">
                            <div style={{ paddingLeft: 5, color: "#757575", lineHeight: "20px" }}>
                                <label>Search for amino-acid, protein, and peptide sequences in our sequence BLAST index (blastp). The index contains millions of individual sequences extracted from patents. Unique sequences often occur in multiple source documents so there are much more patent sequence references than there are sequences.
                                    To query the index, simply paste a plain text or FASTA format sequence into the search field and hit the BLAST button. Select an interesting sequence from the result list of similar sequences and click on “Search patents” to find patents referencing this exact sequence. You can only search for one sequence at a time and the query sequence is expected to follow the IUB/IUPAC amino acid codes. Three-letter codes are not supported and may be misinterpreted as one-letter sequences.
                                </label>
                            </div>
                            <div className='col-12' style={{ paddingLeft: 5 }}>
                                {/*<Card>*/}
                                <div>
                                    {/*<div style={{ marginBottom: 10 }} >
                                                <label style={{ marginRight: 10, marginBottom: 10, verticalAlign: 'bottom' }}>Upload file</label>
                                                <FileUpload name="demo[]" url="./upload" multiple={false}
                                                    accept=".fasta" style={{ marginTop: 10 }} />
                            </div>*/}
                                    <div>
                                        {/*<label style={{ marginRight: 10, verticalAlign: 'bottom' }}>Or paste sequence</label>*/}
                                        <div style={{ marginTop: 10 }}>
                                            <InputTextarea rows={10} cols={100} value={this.state.valueProtein}
                                                onChange={(e) => this.handleProteinChange(e.target.value)}
                                                autoResize={false}
                                                placeholder="Try ETFPRPNLACGVNVNDSSN"
                                                style={{ width: '100%' }} />
                                            {
                                                this.state.showSecond &&
                                                <div>
                                                    <InputTextarea rows={10} cols={100} value={this.state.valueProtein2}
                                                        onChange={(e) => this.setState({ valueProtein2: e.target.value })}
                                                        autoResize={false}
                                                        placeholder="Paste second sequence"
                                                        style={{ width: '100%', marginTop: 10 }} />
                                                    <Dropdown
                                                        className='switch'
                                                        value={this.state.logical}
                                                        options={logicals}
                                                        // disabled={this.props.queryNames.length > 1 ? false : true}
                                                        onChange={(e) => { this.setState({ logical: e.value }) }}
                                                        style={{ width: '200px', marginTop: 10, textAlign: 'center', 'min-width': '5em' }}
                                                        placeholder='Select logical operator' />
                                                </div>
                                            }
                                            {/*<Button label="Add sequence"
                                                    className='blue-grey-btn'
                                                    //disabled={this.state.query.length > 0 ? false : true}
                                                    disabled={true}
                                                    onClick={this.handleAddSequence}
                                                    icon="pi-md-add"
                                            style={{ marginLeft: 0, marginTop: 10 }} />*/}
                                            <div style={{ marginTop: 0, textAlign: 'right' }}>
                                                {/*<Fieldset legend='Parameters'>*/}
                                                {/*<label htmlFor='evalueProtein' style={{ marginRight: 10 }}>Expect threshold</label>*/}
                                                {this.state.valueProteinPlain && this.state.valueProteinPlain.length > 0 ?
                                                    <React.Fragment>
                                                        <label style={{ float: 'left', color: '#757575' }}><NumberFormat value={this.state.valueProteinPlain.length} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} />{this.state.valueProteinPlain.length === 1 ? ' amino acid' : ' amino acids'}</label>
                                                        <a id="detailSbjct"
                                                            title='Clear input'
                                                            onClick={(e) => this.clearProteinInput(e)}
                                                            style={{ display: 'inline-block', float: 'left', marginLeft: 15 }}>Clear</a>
                                                    </React.Fragment>
                                                    : null
                                                }
                                                <div style={{ paddingTop: 10 }}>
                                                    <a style={{ marginRight: 10 }} target="_blank" rel="noopener noreferrer"
                                                        href={'https://blast.ncbi.nlm.nih.gov/Blast.cgi?CMD=Web&PAGE_TYPE=BlastDocs&DOC_TYPE=BlastHelp#expect'}>Expect threshold</a>
                                                    <InputText id='evalueProtein' keyfilter='num' placeholder='e-value' value={this.state.evalueProtein}
                                                        onChange={(e) => this.setState({ evalueProtein: e.target.value })} style={{ width: 100 }} />
                                                    <Button label="BLAST"
                                                        className='primaryButton p-button-sm'
                                                        disabled={this.state.valueProtein !== '' ? false : true}
                                                        onClick={this.handleSearchBlastP}
                                                        //icon="pi-md-search"
                                                        style={{ marginLeft: 10, marginTop: 10, marginRight: 0, verticalAlign: "unset" }} />
                                                </div>
                                                {/*</Fieldset>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div style={{ textAlign: 'right' }}>
                                <Button label="BLAST"
                                    className='primaryButton p-button-sm'
                                    disabled={this.state.valueProtein !== '' ? false : true}
                                    onClick={this.handleSearchBlastP}
                                    icon="pi-md-search"
                                    style={{ marginLeft: 0, marginTop: 10 }} />
                                        </div>*/}
                            {this.state.showProteinResults &&
                                <React.Fragment>
                                    <div style={{ marginTop: 15, marginBottom: 0, paddingLeft: 5 }}>
                                        {/*<Panel header={`Matched sequences for your input sequence with ${this.state.valueProteinPlain.length} proteins`} ref={(Panel) => { this.proteinPanel = Panel }}>*/}
                                        <div style={{ textAlign: 'left', marginBottom: 10 }}>
                                            <label style={{ fontWeight: 'bold', fontSize: 'larger' }}><NumberFormat value={hitsProtein} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} />{hitsProtein && hitsProtein === 1 ? ` hit found` : ` hits found`} in our database with <NumberFormat value={databaseHitsProtein} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} /> protein sequences</label>
                                        </div>
                                        {blastpResultsTable.length > 0 &&
                                            <div className="datatable-responsive-demo">
                                                <DataTable value={blastpResultsTable}
                                                    removableSort={true}
                                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                                    paginator={true}
                                                    rows={this.state.rowsProteinTable}
                                                    first={this.state.firstProteinTable}
                                                    onPage={(e) => this.onCustomPageProteinTable(e)}
                                                    paginatorTemplate={CustomPaginatorTemplate}
                                                    paginatorLeft={<CustomPaginatorLeftSide
                                                        first={this.state.firstProteinTable}
                                                        contentLength={blastpResultsTable.length}
                                                        rows={this.state.rowsProteinTable}>
                                                    </CustomPaginatorLeftSide>}
                                                    paginatorRight={<CustomPaginatorRightSide
                                                        rows={this.state.rowsProteinTable}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                rowsProteinTable: e.value
                                                            })
                                                        }}
                                                    >
                                                    </CustomPaginatorRightSide>}
                                                    selection={this.state.selectedProteinSequence}
                                                    onSelectionChange={e => this.handleProteinSelectionChange(e)}
                                                    selectionMode="radiobutton"
                                                    className="p-datatable-responsive-demo">
                                                    <Column field="check" selectionMode='single' style={{ width: '3em', textAlign: 'center', fontWeight: 'normal' }} />
                                                    <Column field="num" header="#" style={{}} body={this.numTemplate} />
                                                    <Column field="score" header={score} body={this.scoreTemplate} />
                                                    <Column field="id" header="Sequence ID" body={this.ProteinIDBody} />
                                                    <Column field="length" header="Sequence length" body={this.sequenceLengthBody} />
                                                    <Column field="bit_score" header={bit_score} body={this.bitScoreTemplate} />
                                                    <Column field="expect" header="Expect value" body={this.expectTemplate} />
                                                    <Column field="identity" header={identities} body={this.identityTemplate} />
                                                    <Column field="positives" header={positives} body={this.positivesTemplate} />
                                                    <Column field="gaps" header={gaps} body={this.gapsTemplate} />
                                                    {/*<Column field="" header="Sequence details" body={this.proteinDetailsBody} />*/}
                                                    <Column field="" header="FASTA (.zip)" body={this.downloadBodyProtein} style={{}} />
                                                </DataTable>
                                                {/*blastpResultsShow*/}
                                            </div>
                                        }
                                        {/*</Panel>*/}
                                    </div>
                                    {blastpResultsTable.length > 0 &&
                                        <React.Fragment>
                                            <div style={{ textAlign: 'right', marginTop: 0, marginBottom: 15 }}>
                                                <Button label="Search patents"
                                                    className='primaryButton p-button-sm'
                                                    disabled={this.state.selectedProteinSequence !== null ? false : true}
                                                    onClick={(e) => this.searchProteinPatents(e)}
                                                    //icon="pi-md-search"
                                                    style={{ marginTop: 0, marginRight: 0 }} />
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                            {/*this.state.showProteinCharts ?
                                    <Element ref={(ref) => { this.repoStatsRef = ref; }}>
                                        <Panel id="repositoryOverview"
                                            className='repoStatisticsPanel removePanelHeader'
                                            header={`${repoStats.hits ? addThousandsSeparatorToNumber(repoStats.hits) : '...'} ${repoStats.hits > 1 ? 'hits' : 'hit'}`}
                                            toggleable={true}
                                            style={{ marginTop: "0px" }}>
                                            <RepositoryOverview
                                                    //headerText=
                                                    repoStatisticsResults={repoStats}
                                                    fetching={fetchingData}
                                                    callbackFromRepositoryClick={this.myCallbackChart}
                                                    selectedRepository={this.state.repository}
                                                />
                                        </Panel>
                                    </Element> : null
                                        */}
                            {this.state.showProteinDocumentResults && this.state.documentsProtein.documents && this.state.documentsProtein.documents.length > 0 ?
                                <Element ref={this.docResRefProtein}>
                                    <DocumentResultWrapper
                                        showResults={true}
                                        repositoryInfo={this.state.repositoryInfo}
                                        documents={this.state.documentsProtein}
                                        selectedDocuments={this.state.selectedDocuments}
                                        onDocumentSelectionChange={(selDocs) => { this.setState({ selectedDocuments: selDocs }) }}
                                        docInformation={this.state.docInformationProtein}
                                        onChangeDocInformation={this.myCallbackFromReadChangeProtein}
                                        ratingReadOnly={true}
                                        onPageChange={this.myCallbackFromPageChangeProtein}
                                        onExport={this.myCallbackFromExportProtein}
                                        fetching={this.props.fetching}
                                        sortFields={this.state.sortFields}
                                        sortCriteria={this.state.sortCriteria}
                                        onSortFieldChange={this.myCallbackFromFieldChangeProtein}
                                        onSortModeChange={this.myCallbackFromSortModeChangeProtein}
                                        first={this.state.firstProtein}
                                        rows={this.state.rowsProtein}
                                        query={internalQueryProtein}
                                        internalQuery={internalQueryProtein}
                                        rerender={this.state.rerender}
                                        rerenderGraph={this.state.rerenderGraph}
                                        onClick={this.myCallbackFromClick}
                                        hideSimilaritySearch={true}
                                        history={this.props.history}
                                        addOrganisms={true}
                                        patentInfo={this.state.patentSeqDataProtein}
                                        selectedSequenceID={this.state.selectedProteinSequenceID}
                                        showGroupByPatentFamily={true}
                                        isPatentFamilyMutliple={true}
                                        patentFamilyGroupingValues={this.state.patentFamilyGroupingValues}
                                        onPatentFamilyGroupingChange={this.myCallbackFromGroupByPatentFamilyChangeProtein}
                                        patentFamilyGrouping={this.state.patentFamilyGrouping}
                                    />
                                </Element> :
                                this.state.showProteinDocumentResults && this.state.documentsProtein.length === 0 ?
                                    <p style={{ fontWeight: 'bold', paddingLeft: 7 }}>No documents found in Patents for this sequence.</p>
                                    : null
                            }
                        </TabPanel>
                    </TabView>
                    <Dialog visible={this.state.displayNucleotideSequenceDetails} style={{ 'width': "90vw" }} header="Sequence details" focusOnShow={false}
                        modal={true}
                        blockScroll={true}
                        dismissableMask={true}
                        onHide={() => this.setState({ displayNucleotideSequenceDetails: false })} className='styledDialog'>

                        <div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 15, paddingTop: 5, marginRight: 0 }}>
                            <div className='grid p-fluid'>
                                <div style={{ marginRight: 5 }}>
                                    <div style={{ padding: '.75em', paddingBottom: 0 }}>
                                        <label htmlFor="OCID">Sequence ID</label>
                                    </div>
                                    <div style={{ padding: '.75em' }}>
                                        <label style={{ fontWeight: 'bolder' }} htmlFor="OCID">{singleNucleotideSequenceOCID}</label>
                                    </div>
                                </div>
                                {this.state.activeIndexNucleotide === 1 &&
                                    <React.Fragment>
                                        <div style={{ marginRight: 5 }}>
                                            <div style={{ padding: '.75em', paddingBottom: 0 }}>
                                                <label htmlFor="OCID">Identity</label>
                                            </div>
                                            <div style={{ padding: '.75em' }}>
                                                <label style={{ fontWeight: 'bolder' }} htmlFor="OCID">{this.state.singleNucleotideIdentity}</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ padding: '.75em', paddingBottom: 0 }}>
                                                <label htmlFor="OCID">Gaps</label>
                                            </div>
                                            <div style={{ padding: '.75em' }}>
                                                <label style={{ fontWeight: 'bolder' }} htmlFor="OCID">{this.state.singleNucleotideGaps}</label>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <TabView activeIndex={this.state.activeIndexNucleotide} onTabChange={(e) => this.setState({ activeIndexNucleotide: e.index })}>
                                <TabPanel header='Sequence'>
                                    <div className="grid p-fluid">
                                        <div className="col-6" style={{ padding: '.75em', paddingLeft: 15, paddingRight: 0 }}>
                                            <label htmlFor="OCID">{`Sequence length: ${singleNucleotideSequenceLength}`}</label>
                                        </div>
                                        <div className="col-6" style={{ padding: '.75em', textAlign: "right" }}>
                                            <CopyToClipboard text={singleNucleotideSequenceWrapped}
                                                onCopy={() => this.setState({ copied: true })}>
                                                <a id="detailSbjct"
                                                    title='Copy to clipboard'
                                                    onClick={(e) => this.showCopySuccessGrowl(e)}
                                                    style={{ display: 'inline-block', float: 'right', marginRight: 10 }}>Copy to clipboard</a>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ padding: '.75em', width: 1050, height: 500 }}>
                                        <label style={{ fontFamily: 'monospace' }} htmlFor="OCID">{singleNucleotideSequenceWrapped}</label>
                                    </div>
                                </TabPanel>
                                <TabPanel header='Alignment'>
                                    {this.state.singleNucleotideSequenceAlignment &&
                                        <div style={{ width: 1050, height: 500 }}>
                                            {splittedClean.length > 1 ?
                                                splittedClean.map((line, i) =>
                                                    <div key={i} style={{ marginTop: i === 0 || i % 3 === 0 ? 15 : 0, display: 'flex', justifyContent: 'space-evenly' }}>
                                                        {i === 0 || i % 3 === 0 ?
                                                            <label style={{ width: '10%' }}>QUERY</label>
                                                            : i === 2 || i % 3 === 2 ?
                                                                <label style={{ width: '10%' }}>SBJCT</label>
                                                                :
                                                                <label style={{ width: '10%' }}></label>
                                                        }
                                                        {i === 0 || i % 3 === 0 ?
                                                            <label style={{ width: '10%' }}>{i === 0 ? splittedCleanQueryStarts[i] : splittedCleanQueryStarts[i / 3]}</label>
                                                            : i === 2 || i % 3 === 2 ?
                                                                <label style={{ width: '10%' }}>{splittedCleanTargetStarts[ind.indexOf(i)]}</label>
                                                                :
                                                                <label style={{ width: '10%' }}></label>
                                                        }
                                                        <label style={{ width: '75%', textAlign: 'start', fontFamily: 'monospace', whiteSpace: "pre-wrap" }}>{line}</label>
                                                        {i === 0 || i % 3 === 0 ?
                                                            <label style={{ width: '10%' }}>{i === 0 ? splittedCleanQueryEnds[i] : splittedCleanQueryEnds[i / 3]}</label>
                                                            : i === 2 || i % 3 === 2 ?
                                                                <label style={{ width: '10%' }}>{splittedCleanTargetEnds[ind.indexOf(i)]}</label>
                                                                :
                                                                <label style={{ width: '10%' }}></label>
                                                        }
                                                    </div>
                                                )
                                                : null
                                            }
                                        </div>
                                    }
                                </TabPanel>
                            </TabView>
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.displayProteinSequenceDetails} style={{ 'width': "90vw" }} header="Sequence details" focusOnShow={false}
                        modal={true}
                        blockScroll={true}
                        dismissableMask={true}
                        onHide={() => this.setState({ displayProteinSequenceDetails: false })} className='styledDialog'>

                        {/*<div style={{ marginTop: 10 }}>
                            <div style={{ padding: '.75em' }}>
                                <label htmlFor="OCID">Sequence ID</label>
                            </div>
                            <div style={{ padding: '.75em' }}>
                                <label style={{ fontWeight: 'bolder' }} htmlFor="OCID">{singleProteinSequenceOCID}</label>
                            </div>
                            <div className="grid p-fluid">
                                <div className="col-6" style={{ padding: '.75em', paddingLeft: 15, paddingRight: 0 }}>
                                    <label htmlFor="OCID">{`Sequence length: ${singleProteinSequenceLength}`}</label>
                                </div>
                                <div className="col-6" style={{ padding: '.75em', textAlign: "right" }}>
                                    <CopyToClipboard text={singleProteinSequenceWrapped}
                                        onCopy={() => this.setState({ copied: true })}>
                                        <a id="detailSbjct"
                                            title='Copy to clipboard'
                                            onClick={(e) => this.showCopySuccessGrowl(e)}
                                            style={{ display: 'inline-block', float: 'right', marginRight: 10 }}>Copy to clipboard</a>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div className="col-12" style={{ padding: '.75em', width: 635 }}>
                                <label style={{ fontFamily: 'monospace' }} htmlFor="OCID">{`${singleProteinSequenceWrapped.substring(0, 1749)}`} {singleProteinSequenceWrapped.length > 1750 ? '...' : ''}</label>
                            </div>
                            </div>*/}
                        <div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 15, paddingTop: 5, marginRight: 0 }}>
                            <div className='grid p-fluid'>
                                <div style={{ marginRight: 5 }}>
                                    <div style={{ padding: '.75em', paddingBottom: 0 }}>
                                        <label htmlFor="OCID">Sequence ID</label>
                                    </div>
                                    <div style={{ padding: '.75em' }}>
                                        <label style={{ fontWeight: 'bolder' }} htmlFor="OCID">{singleProteinSequenceOCID}</label>
                                    </div>
                                </div>
                                {this.state.activeIndexProtein === 1 &&
                                    <React.Fragment>
                                        <div style={{ marginRight: 5 }}>
                                            <div style={{ padding: '.75em', paddingBottom: 0 }}>
                                                <label htmlFor="OCID">Identity</label>
                                            </div>
                                            <div style={{ padding: '.75em' }}>
                                                <label style={{ fontWeight: 'bolder' }} htmlFor="OCID">{this.state.singleProteinIdentity}</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ padding: '.75em', paddingBottom: 0 }}>
                                                <label htmlFor="OCID">Gaps</label>
                                            </div>
                                            <div style={{ padding: '.75em' }}>
                                                <label style={{ fontWeight: 'bolder' }} htmlFor="OCID">{this.state.singleProteinGaps}</label>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <TabView activeIndex={this.state.activeIndexProtein} onTabChange={(e) => this.setState({ activeIndexProtein: e.index })}>
                                <TabPanel header='Sequence'>
                                    <div className="grid p-fluid">
                                        <div className="col-6" style={{ padding: '.75em', paddingLeft: 15, paddingRight: 0 }}>
                                            <label htmlFor="OCID">{`Sequence length: ${singleProteinSequenceLength}`}</label>
                                        </div>
                                        <div className="col-6" style={{ padding: '.75em', textAlign: "right" }}>
                                            <CopyToClipboard text={singleProteinSequenceWrapped}
                                                onCopy={() => this.setState({ copied: true })}>
                                                <a id="detailSbjct"
                                                    title='Copy to clipboard'
                                                    onClick={(e) => this.showCopySuccessGrowl(e)}
                                                    style={{ display: 'inline-block', float: 'right', marginRight: 10 }}>Copy to clipboard</a>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ padding: '.75em', width: 1050, height: 500 }}>
                                        <label style={{ fontFamily: 'monospace' }} htmlFor="OCID">{singleProteinSequenceWrapped}</label>
                                    </div>
                                </TabPanel>
                                <TabPanel header='Alignment'>
                                    {this.state.singleProteinSequenceAlignment &&
                                        <div style={{ width: 1050, height: 500 }}>
                                            {splittedCleanProtein.length > 1 ?
                                                splittedCleanProtein.map((line, i) =>
                                                    <div key={i} style={{ marginTop: i === 0 || i % 3 === 0 ? 15 : 0, display: 'flex', justifyContent: 'space-evenly' }}>
                                                        {i === 0 || i % 3 === 0 ?
                                                            <label style={{ width: '10%' }}>QUERY</label>
                                                            : i === 2 || i % 3 === 2 ?
                                                                <label style={{ width: '10%' }}>SBJCT</label>
                                                                :
                                                                <label style={{ width: '10%' }}></label>
                                                        }
                                                        {i === 0 || i % 3 === 0 ?
                                                            <label style={{ width: '10%' }}>{i === 0 ? splittedCleanQueryStartsProtein[i] : splittedCleanQueryStartsProtein[i / 3]}</label>
                                                            : i === 2 || i % 3 === 2 ?
                                                                <label style={{ width: '10%' }}>{splittedCleanTargetStartsProtein[indProtein.indexOf(i)]}</label>
                                                                :
                                                                <label style={{ width: '10%' }}></label>
                                                        }
                                                        <label style={{ width: '75%', textAlign: 'start', fontFamily: 'monospace', whiteSpace: "pre-wrap" }}>{line}</label>
                                                        {i === 0 || i % 3 === 0 ?
                                                            <label style={{ width: '10%' }}>{i === 0 ? splittedCleanQueryEndsProtein[i] : splittedCleanQueryEndsProtein[i / 3]}</label>
                                                            : i === 2 || i % 3 === 2 ?
                                                                <label style={{ width: '10%' }}>{splittedCleanTargetEndsProtein[indProtein.indexOf(i)]}</label>
                                                                :
                                                                <label style={{ width: '10%' }}></label>
                                                        }
                                                    </div>
                                                )
                                                : null
                                            }
                                        </div>
                                    }
                                </TabPanel>
                            </TabView>
                        </div>
                    </Dialog>
                </LoadingOverlay>
            </div>
        )
    }
}

export default SequenceSearch