import { readFilterDefinitions, readRepositoryFilterMapping } from "../../../../properties";
import { setFilterDefinitions, setRepositoryFilterMap } from '../actions/FilterActions';


export const fetchFilterDefinitions = () => (dispatch) => {
    return readFilterDefinitions()
        .then((result) => {
            dispatch(setFilterDefinitions(result?.filterDefinitions ?? {}));
        })
}

export const fetchRepositoryFilterMap = () => (dispatch) => {
    return readRepositoryFilterMapping()
        .then((result) => {
            dispatch(setRepositoryFilterMap(result ?? {}));
        })
}