import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import Job from './Job';


import {
  fetchAvailableRepositories,
  fetchJobs,
  deleteJobs,
  showGrowl
} from '../../../../redux/actions';


class Jobs extends Component {
  constructor(props) {
    super(props);
    this.fetchJobs = this.fetchJobs.bind(this);
    this.state = {
      activeJobs: 0
    }
    this.jobRefresher = this.jobRefresher.bind(this);
  }


  componentDidMount() {
    this.jobRefresher();
  }

  jobRefresher() {
    this.fetchJobs(false);

    if (this.props.activeJobs.length > 0)
      setTimeout(this.jobRefresher, 5000);
    else
      setTimeout(this.jobRefresher, 30000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.activeJobs !== nextProps.activeJobs.length) {
      if (this.state.activeJobs > nextProps.activeJobs.length) {
        setTimeout(this.props.updateRepositories, 5500);
        this.props.showGrowl("Job finished", "Your annotation job has finished!", "info");
      }
      this.setState({ activeJobs: nextProps.activeJobs.length })
    }
  }

  render() {


    let jobsTable = <p> <i className="pi pi-spin pi-spinner"></i> Loading Jobs </p>
    if (!this.props.jobsLoading) {
      if (this.props.jobsNum > 0)
        jobsTable =
          <div className="jobsTable">
            <div className="grid">
              <div className="col-12 md:col-2 lg:col-2"><strong>Job</strong></div>
              <div className="col-12 md:col-3 lg:col-3"><strong>Description</strong></div>
              <div className="col-12 md:col-2 lg:col-2"><strong>File Path</strong></div>
              <div className="col-12 md:col-4 lg:col-4"><strong>Status</strong></div>
            </div>
            {
              this.props.jobs.map((job) =>
                <Job job={job} key={job.id} />
              )
            }

            <Paginator first={this.props.jobsPage * this.props.jobsRows} rows={this.props.jobsRows} totalRecords={this.props.jobsNum} rowsPerPageOptions={[5, 10, 20, 30]} onPageChange={(e) => this.fetchJobs(false, e.first, e.rows)}></Paginator>
            <p>
              <Button label="Delete finished jobs" className="p-button-danger" onClick={() => this.deleteFinishedJobs()} />
            </p>
          </div>
      else
        jobsTable = "";
    }

    return (
      <div id="jobList">
        <h2>List of Jobs</h2>
        <p>
          <Button label="Refresh" className="primaryButton p-button-refresh-jobs" onClick={() => this.fetchJobs(true)} />
        </p>
        <p>There are currently {this.props.jobsNum} recorded Jobs.</p>
        <p>
          The left border of each cell indicates the job's status: <br />
          <span className="job-legend job-legend-QUEUED">QUEUED</span> <span className="job-legend job-legend-PROCESSING">PROCESSING</span> <span className="job-legend job-legend-FINISHED-success">FINISHED successfully</span> <span className="job-legend job-legend-FINISHED-warn">FINISHED with errors</span> <span className="job-legend job-legend-ABORTED">ABORTED</span> <span className="job-legend job-legend-FAILED">FAILED</span> <span className="job-legend job-legend-UNKNOWN">UNKNOWN</span></p>
        { jobsTable}
      </div>
    );
  }

  deleteFinishedJobs() {
    this.props.deleteJobs(this.props.jobsRows, this.props.jobsPage, "finished jobs", "")
  }

  fetchJobs(delay, first = this.props.jobsPage * this.props.jobsRows, rows = this.props.jobsRows) {
    if (delay)
      this.props.fetchJobs(rows, first / rows, 300);
    else
      this.props.fetchJobs(rows, first / rows, 0);
  }
}

const mapStateToProps = state => {
  return {
    jobs: state.managerReducer.jobs,
    jobsLoading: state.managerReducer.jobsLoading,
    jobsPages: state.managerReducer.jobsPages,
    jobsNum: state.managerReducer.jobsNum,
    jobsPage: state.managerReducer.jobsPage,
    jobsRows: state.managerReducer.jobsRows,
    activeJobs: state.managerReducer.activeJobs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateRepositories: () => dispatch(fetchAvailableRepositories()),
    fetchJobs: (count, page, delay) => dispatch(fetchJobs(count, page, delay)),
    deleteJobs: (count, page, jobDescriptions, annotationJobIds) => dispatch(deleteJobs(count, page, jobDescriptions, annotationJobIds)),
    showGrowl: (summary, detail, severity = "info", debug = "") => dispatch(showGrowl(summary, detail, severity, debug)),
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Jobs);
