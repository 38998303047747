import { sendApiRequest, sendExportToFileApiRequest } from '../index';
import { APP_PROPERTIES, DOMAIN_COLORS, QUERY_HIT_COLOR, getAnnotationTypeForDocID } from '../../properties/index';
import { EXPORTER_TARGET_ID_FILE, EXPORTER_TARGET_ID_BIGQUERY } from '../../properties';
import { convertQueriesToQueryString } from './QueryApi';
import { isArrayEmpty, isObjectEmpty } from '../../components/webapi/util';
import { createRequestFromAdvancedQueries } from '../../components/webapi/general/docsearch/advancedSearch'
import { createExportCenterEntryName, EXPORT_CENTER_CATEGORY_DOCUMENT_METADATA, EXPORT_CENTER_CATEGORY_SEMANTIC } from '../../components/webapi/util/exportCenter';

// ----------------------------------------------------------------------- //
// --- create requests from data ----------------------------------------- //
// ----------------------------------------------------------------------- //
/**
 *
 * @param {string} repID
 * @param {string} query
 * @param {Number} hitStartPos
 * @param {Number} hitCount
 * @param {Boolean} withDocMetaData
 * @param {Boolean} withImageCatalog
 * @param {Boolean} withQryAbstract
 * @param {Boolean} withTeaserImage
 */
export const createDocumentSearchRequestV1 = (repID, query, hitStartPos, hitCount,
    withDocMetaData, withImageCatalog, withQryAbstract, withTeaserImage) => {
    return {
        repository: repID,
        query: query,
        hitStartPos: hitStartPos,
        hitCount: hitCount,

        withDocMetaData: withDocMetaData,
        withImageCatalog: withImageCatalog,
        withQryAbstract: withQryAbstract,
        withTeaserImage: withTeaserImage
    };
}

/**
 * TODO: addo withMetaData etc in MW.
 * @param {string} repID
 * @param {string} query
 * @param {Array} facets 
 * @param {string} sortByField
 * @param {string} sortByMode
 * @param {*} yearStatEnd 
 * @param {*} yearStatCount 
 */
export const createDocumentSearchRequest = (repID, query, facets, sortByField, sortByMode, sortFactor = null,
    yearStatEnd = null, yearStatCount = null) => {

    const req = {
        repository: repID,
        query: query
    };

    if (sortByField) {
        req.sortBy = sortByField;
    }
    if (sortByMode) {
        req.sortDirection = sortByMode;
    }
    if (facets) {
        req.facettes = facets
    }
    if (sortFactor) {
        req.sortFactor = sortFactor;
    }
    if (yearStatEnd) {
        req.yearTo = yearStatEnd;
    }
    if (yearStatCount) {
        req.yearCount = yearStatCount;
    }

    return req;
}

/**
 * 
 * @param {string} repID
 * @param {string} query
 * @param {Array} filterQueries 
 * @param {Array} facets 
 * @param {string} sortByField
 * @param {string} sortByMode
 * @param {Number} sortFactor 
 * @param {*} yearStatEnd 
 * @param {*} yearStatCount 
 * @returns 
 */
export const createDocumentSearchRequestV2 = (repID, query, filterQueries, facets, sortByField, sortByMode, sortFactor = null,
    yearStatEnd = null, yearStatCount = null) => {

    const req = {
        repository: repID,
        query: query
    };
    if (filterQueries) {
        req.filterQueries = filterQueries;
    }
    if (sortByField) {
        req.sortBy = sortByField;
    }
    if (sortByMode) {
        req.sortDirection = sortByMode;
    }
    if (facets) {
        req.facettes = facets
    }
    if (sortFactor) {
        req.sortFactor = sortFactor;
    }
    if (yearStatEnd) {
        req.yearTo = yearStatEnd;
    }
    if (yearStatCount) {
        req.yearCount = yearStatCount;
    }

    return req;
}

/**
 * Creates an object for an annotated document request.
 *
 * @param {string} repID
 * @param {Number} ocDocId
 * @param {string} internalQuery
 * @param {Boolean} withConceptData
 * @param {Boolean} withExtendedData
 * @param {Boolean} withSourceReferences
 * @param {Boolean} withParents
 * @param {Boolean} withChildren
 * @param {Boolean} childrenMaxCount
 * @param {Boolean} withOtherRelationships
 * @param {Boolean} withImageCatalog
 * @param {Boolean} withSentenceInfo
 */
export const createAnnotatedDocumentRequest = (repID, ocDocId, internalQuery,
    withConceptData, withExtendedData, withSourceReferences,
    withParents, withChildren, childrenMaxCount, withOtherRelationships, withImageCatalog, documentParts, namedQueries = null, withSentenceInfo = false) => {
    //console.log(namedQueries)
    const request = {
        repository: repID,
        ocDocId: ocDocId,
        internalQuery: internalQuery,
        documentFormat: 'OC_ANN_XHTML_OCM', // OC_ANN_XHTML | OC_ANN_XHTML_OCM
        annotationType: getAnnotationTypeForDocID(ocDocId),
        withConceptData: withConceptData,
        withExtendedData: withExtendedData,
        withSourceReferences: withSourceReferences,
        withParents: withParents,
        withChildren: withChildren,
        childrenMaxCount: childrenMaxCount,
        withOtherRelationships: withOtherRelationships,
        withImageCatalog: withImageCatalog,
        withSentenceInfo: withSentenceInfo
    };

    if (documentParts) {
        request.documentParts = documentParts;
    }
    //console.log(namedQueries)
    if (namedQueries) {
        request.namedQueries = getNamedQuery(namedQueries)
    }

    return request;

    /* MW
    {
        "apiRevision": 0,
        "documentParts": [
          {
            "endOffset": 0,
            "name": "string",
            "startOffset": 0
          }
        ],
        "documentPartsPreferredLanguages": [],
        "domainWhitelist": [],
        "withDocumentStructureIds": true,
      }
      */
}

export const createDocumentPartsRequest = (repID, ocDocId, internalQuery,
    withConceptData, withExtendedData, withSourceReferences,
    withParents, withChildren, childrenMaxCount, withOtherRelationships, withImageCatalog) => {

    const request = {
        repository: repID,
        ocDocId: ocDocId,
        internalQuery: internalQuery,
        documentFormat: 'OC_ANN_XHTML_OCM',
        withConceptData: withConceptData,
        withExtendedData: withExtendedData,
        withSourceReferences: withSourceReferences,
        withParents: withParents,
        withChildren: withChildren,
        childrenMaxCount: childrenMaxCount,
        withOtherRelationships: withOtherRelationships,
        withImageCatalog: withImageCatalog,
    };

    return request;
}


/**
 * create an object for semantic search request
 * @param {string} repID 
 * @param {number} ocDocId 
 * @param {string} internalQuery 
 * @param {object} namedQueries 
 * @returns 
 * = { q1: "ocid:\"229960001138\"" }
 */

export const createSemanticSearchRequest = (repID, ocDocId, internalQuery, namedQueries, documentParts, withSentenceInfo = false) => {
    //console.log(namedQueries)
    const request = {
        repository: repID,
        ocDocId: ocDocId,
        internalQuery: internalQuery,
        namedQueries: getNamedQuery(namedQueries),
        documentFormat: 'OC_ANN_XHTML_OCM', // OC_ANN_XHTML | OC_ANN_XHTML_OCM
        annotationType: getAnnotationTypeForDocID(ocDocId),
        withConceptData: true,
        withExtendedData: false,
        withSourceReferences: false,
        withParents: false,
        withChildren: false,
        childrenMaxCount: 0,
        withOtherRelationships: false,
        withImageCatalog: false,
        withSentenceInfo: withSentenceInfo
    };

    if (documentParts) {
        request.documentParts = documentParts;
    }
    return request;

}

const getNamedQuery = (namedQueries) => {
    const queryObject = {}
    namedQueries?.forEach((item) => {
        queryObject[`${item.id}`] = convertQueriesToQueryString(item)
    })
    return queryObject
}



/**
 * Creates an object for document parts request.
 *
 * @param {*} repID
 * @param {*} ocDocIds
 * @param {*} withDocumentStructure
 * @param {*} withBaseMetaData
 * @param {*} withExtendedMetaData
 */
export const createDocumentPartsInfoRequest = (repID, ocDocIds, withDocumentStructure, withBaseMetaData, withExtendedMetaData) => {
    return {
        docIds: ocDocIds,
        repository: repID,
        withDocumentStructure: withDocumentStructure,
        withBaseMetaData: withBaseMetaData,
        withExtendedMetaData: withExtendedMetaData
    };
}


export const createTopHitDocumentsRequest = (queries, totalHitCountThreshold) => {

    const request = {
        queries: queries
    }
    if (totalHitCountThreshold) {
        request.totalHitCountThreshold = totalHitCountThreshold;
    }

    return request;
}
export const fetchTopHitDocuments = async (repositoryID, request) => {
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/runtophitquery?repositoryId=${repositoryID}`, request);

    return result;
}


// @todo
// export const createDocumentsHitCountRequest = (queries) => {

//     const request = {
//         queries: queries
//     }
//     // if (totalHitCountThreshold) {
//     //     request.totalHitCountThreshold = totalHitCountThreshold;
//     // }

//     return request;
// }
// export const fetchDocumentsHitCount = async (repositoryID, request) => {
//     const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/runHitCountDocumentQueries?repositoryId=${repositoryID}`, request);

//     return result;
// }

// ----------------------------------------------------------------------- //
// --- run API requests -------------------------------------------------- //
// ----------------------------------------------------------------------- //
export const fetchIPCClasses = async () => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ipc/list`);
    //
    return result;
}


export const fetchDocumentResultsStatistics = async (queryString, time = null, inclStatistics = false) => {
    const request = {
        query: queryString
    };

    const result = time ?
        await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/time/${time}/statistics?statistics=${inclStatistics}`, request) :
        await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/statistics?statistics=${inclStatistics}`, request);

    return result;
}

export const fetchDocumentResultsWithStatistics = async (queryString, searchOptions) => {
    let request = {}
    if (queryString.hasOwnProperty("filterQueries") && queryString.filterQueries.length > 0 && queryString.hasOwnProperty("formContent")) {
        const queries = createRequestFromAdvancedQueries(JSON.parse(queryString?.formContent)?.queries);
        request = { ...searchOptions, query: queryString.query, filterQueries: queries.filterQueries };
    } else if (!queryString.hasOwnProperty("filterQueries") && queryString.hasOwnProperty("query")) {
        request = { ...searchOptions, query: queryString.query };
    } else {
        request = { ...searchOptions, query: queryString };
    }

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query/statistics?statistics=true`, request);

    return result;
}


/**
 *
 * @param {Object} request object
 */
export const fetchDocumentsV1 = async (request) => {

    const repositoryId = request.repository;
    delete request.repository;
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents?repositoryId=${repositoryId}`, request);
    //
    return result;
}

/**
 *
 * @param {Integer} start
 * @param {Integer} count
 * @param {Object} request
 */
export const fetchDocuments = async (start, count, request, useQueryCache = false) => {

    const repositoryId = request.repository;
    delete request.repository;
    //request.api = 'oc-webapi';

    let result;
    if (useQueryCache && !APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.ignoreQueryCache) {
        result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/querycache/documents?repositoryId=${repositoryId}&start=${start}&count=${count}`, request);
    }
    else {
        result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/documents?repositoryId=${repositoryId}&start=${start}&count=${count}`, request);
    }

    return result;
}

/**
 *
 * @param {Integer} start
 * @param {Integer} count
 * @param {Object} request
 */
export const fetchMoreDocuments = async (request) => {

    const repositoryId = request.repository;
    delete request.repository;
    let result;
    result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v4/documents?repositoryId=${repositoryId}&count=1500`, request);

    return result;
}

/**
 *
 * @param {*} request
 * @returns
 */
export const fetchDocumentsBulk = async (request, repositoryIds) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/query-hitcounts${repositoryIds ? '?repositoryIds=' + repositoryIds : ''}`, request);
    //
    return result;
}


/**
 * Fetches all sortable fields for a given repository.
 *
 * @param {string} repID
 */
export const fetchSortableFields = async (repositoryId) => {
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/schema?repositoryId=${repositoryId}`, {
        //repository: repID
    });
    //
    return result;
}

/**
 * Fetches  document Metadata.
 *
 * @param {Object} request object
 */
export const fetchDocumentMetadata = async (request) => {
    const repositoryId = request.repositoryId;
    delete request.repositoryId;

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/data?repositoryId=${repositoryId}`, request);
    return result;
}
/**
 * Fetches annotated document.
 *
 * @param {Object} request object
 */
export const fetchAnnotatedDocument = async (request) => {
    const repositoryId = request.repository;
    delete request.repository;
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/document?repositoryId=${repositoryId}`, request);
    return result;
}

/**
 * Fetches readcube annotated document.
 *
 * @param {Object} request object
 * @param {Object}  readcubeData 
 */
export const fetchAnnotatedReadCubeDocument = async (request, readcubeData) => {
    delete request.repository;
    delete request.documentParts;
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/document/annotations?collectionId=${readcubeData.collectionId}&itemId=${readcubeData.itemId}`, request);
    return result;
}


export const isReadcubeAvailable = async (dois) => {
    let result
    if (Array.isArray(dois)) {
        result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/document-check?doi=${dois[0]}`);
    } else if (!Array.isArray(dois) && dois.isReadcubeDocument) {
        result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/document-check?readcubeItemId=${dois.readcubeItemId}&readcubeCollectionId=${dois.readcubeCollectionId}`);
    } else if (!Array.isArray(dois) && !dois.isReadcubeDocument) {
        result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/document-check?patentId=${dois.patentId[0]?.replace(/-/g, '')}`);
    }
    return result;
}


export const createPDFUrl = (repositoryId, docId, readcubeData) => {
    if (readcubeData?.available) {
        return `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/readcube/document/plain?collectionId=${readcubeData?.collectionId}&itemId=${readcubeData.itemId}`
    } else {
        return `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/document/plain?repositoryId=${repositoryId}&docId=${docId}`
    }
}

/**
 * Fetches annotated pdf document.
 * 
 * @param {Object} request object 
 */
export const fetchPDFAnnotatedDocument = async () => {

    // const repositoryId = request.repository;
    // delete request.repository;
    const request = {
        repository: "biorxivcoord",
        ocDocId: 17757,
        internalQuery: "text:\"horizontally transferred",
        withRangeText: true
    }

    const token = await sendApiRequest(
        'post',
        'https://srvcs1.ontochem.com:9502/ocm/api/getToken',
        { login: "ocext1", password: "j09wthu32892thwfhqw" }
    )

    //console.log(token, ' token ')
    const result = await sendApiRequest('POST', `https://srvcs1.ontochem.com:9504/ocm/api/getDocumentPageAnnotations`, request);




    return result;
}

/**
 * Fetches the original document which was the actual source for the annotation. Can be .doc, .xlsx, etc.
 * @param {*} repositoryName name of the repository as it is used in the backend
 * @param {*} docId the OC document ID
 * @returns 
 */
export const fetchOriginalSourceDocument = async (repositoryID, repositoryName, docId) => {
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/source-file?repositoryId=${repositoryID}`, {
        repository: repositoryName,
        ocDocId: docId,
        sourceFileType: 'orig'
    });
    return result;
}


/**
 * Fetches all available document parts for a list of documents.
 *
 * @param {Object} request object
 */
export const fetchDocumentPartsInfo = async (request) => {

    const repositoryId = request.repository;
    delete request.repository;
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/documents/data?repositoryId=${repositoryId}`, request);
    //
    return result;
}


/**
 * Fetches all claims of a patent as well as their dependencies.
 *
 * @param {string} repID the repository ID
 * @param {string} docID the document ID
 */
export const fetchClaimDependencies = async (repositoryId, docID) => {
    //const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/claim/dependency/list/repository/${repID}/docid/${docID}`);

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/claim-dependencies?repository=${repositoryId}&docId=${docID}`);

    //Error: http://oc091:8080/webapp-umicore/api/v1/claim/dependency/list/repository/relevant1/docid/10 => Maximum call stack size exceeded
    return result;
}

/**
 * Fetches additional information, such as read status, rating, collections, for single document
 * identified by its OC document ID.
 *
 * @param {string} repID repository
 * @param {string} docID  OC document ID
 */
export const fetchAdditionalDocumentInfo = async (repID, docID) => {
    //console.log("fetch")
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, [{
        docId: docID,
        repositoryId: repID,
        // TODO: remove
        repository: repID,
    }]);
    return result;
}

/**
 * Fetches additional information, such as read status, rating, collections,
 * for multiple documents identified by their OC document IDs.
 *
 * @param {string} repID repository
 * @param {Array} ocDocIDs array containing OC document IDs
 */
export const fetchAdditionalDocumentsInfo = async (repID, ocDocIDs, repIDs) => {
    //console.log("fetch2")
    //console.log('repID: ', repID)
    //console.log('repIDs: ', repIDs)
    //console.log('ocDocIDs: ', ocDocIDs)
    const docs = [];
    if (!!ocDocIDs) {
        ocDocIDs.forEach((ocDocID, i) => {
            docs.push({
                docId: ocDocID,
                repositoryId: repIDs[i] ? repIDs[i] : repID,
                // TODO: remove
                repository: repID,
            });
        });
    }

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, docs);
    return result;
}

/**
 *
 * @param {*} docID
 */
export const fetchAdditionalDocumentOrganisms = async (docID) => {
    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-search/sequences?patIds=${docID}`);
    return result;
}


export const changeDocumentRating = async (repID, docID, title, rating) => {
    //console.log("changerate")
    const result = await sendApiRequest('PUT', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, [{
        docId: docID,
        repository: repID,
        repositoryId: repID,
        rating: rating,
        title: title
    }]);
    return result;
}

export const changeDocumentReadStatus = async (repID, docID, title, read) => {
    //console.log("changeread")
    const result = await sendApiRequest('PUT', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, [{
        docId: docID,
        repository: repID,
        repositoryId: repID,
        read: read,
        title: title
    }]);
    return result;
}

export const removeDocumentsFromDocumentCollection = async (docCollectionID, docCollectionLinkIDs) => {

    const result = await sendApiRequest('DELETE', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${docCollectionID}/document?ids=${docCollectionLinkIDs}`);
    //console.log('result: ', result);
    return result;
}

export const addDocumentsToCollections = async (docCollectionIDs, documentsData) => {

    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/documents?collectionIds=${docCollectionIDs}`, documentsData);
    return result;
}

export const fetchAvailableDocumentCollections = async (page, count) => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/page/${page}/count/${count}/list`);
    //console.log('result: ', result);
    return result;
}

export const fetchDocumentsFromCollection = async (collectionID) => {

    const result = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${collectionID}/page/0/count/1000/list`);
    //console.log('result: ', result);
    return result;
}

export const fetchDocumentsMetadata = async (documents, filterRepositoryErrors = false) => {
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v3/documents?filterRepositoryErrors=${filterRepositoryErrors}`, documents);
    //console.log('result: ', result);
    return result;
}

export const createDocumentCollection = async (newCollectionName, newCollectionDescription, sharedForDepartment, usersRead, usersWrite, orgShared, editableForSharedUsers) => {
    let departments = []
    orgShared.forEach(dep => {
        departments = [...departments, Number(dep)]
    })
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections`, {
        name: newCollectionName,
        description: newCollectionDescription,
        departmentSharesRead: departments,
        //sharedForDepartment: sharedForDepartment,
        //userSharesRead: editable ? [] : usersShared,
        //userSharesWrite: editable ? usersShared : [],
        userSharesRead: usersRead,
        userSharesWrite: usersWrite
    });
    return result;
}


/**
 * Exports all chemical compounds from this document to a structure file in .smi format.
 *
 * @param {string} repID the repository ID
 * @param {string} docID the document ID
 */
export const exportCompoundsFromDocument = async (repID, docID, onlyLabeled) => {
    await sendExportToFileApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/compounds/smi?repositoryId=${repID}&docId=${docID}${onlyLabeled ? '&onlyLabeled=true' : ''}`, 'structures.smi');
}

/**
 * 
 * @param {*} target 
 * @param {*} format 
 * @param {*} docIDs 
 * @param {*} conceptExportType 
 * @param {*} conceptOcids 
 * @returns 
 */
export const semanticExportFromDocumentsByDocIDs = async (target, format, docIDs, conceptExportType, conceptOcids = []) => {

    const request = {
        name: createExportCenterEntryName(EXPORT_CENTER_CATEGORY_SEMANTIC),
        conceptExportType: conceptExportType,
        documentIdentifiers: docIDs
    }

    if (conceptExportType === 'ocids') {
        request.semanticExportFilterConceptOcids = conceptOcids
    }

    switch (target) {
        case EXPORTER_TARGET_ID_FILE: {
            //return await sendExportFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/${format}/document-meta-annotated-concept-data`, `semantic_export.${format}`, request);
            return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocwebapi-export/semantic`, request);
        }
        case EXPORTER_TARGET_ID_BIGQUERY: {
            //return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/bigquery/document-meta-annotated-concept-data`, request);
            return { status: 'FAILED', message: 'Sorry, export to BigQuery currently not supported.' };
        }
        default: // do nothing
    }

    return {
        status: 'FAILED',
        message: 'Sorry, an unknown error occurred.'
    }
}

/**
 * 
 * @param {*} target 
 * @param {*} format 
 * @param {*} repID 
 * @param {*} query 
 * @param {*} sortMode 
 * @param {*} sortField 
 * @param {*} sortFactor 
 * @param {*} conceptExportType 
 * @param {*} conceptOcids 
 * @param {*} maxNumOfDocuments 
 * @returns 
 */
export const semanticExportFromDocumentsByQuery = async (target, format, repID, query, filterQueries, sortDirection, sortBy, sortFactor, conceptExportType, conceptOcids = [], maxNumOfDocuments = null) => {

    const request = {
        name: createExportCenterEntryName(EXPORT_CENTER_CATEGORY_SEMANTIC),
        conceptExportType: conceptExportType,
        exportQuery: {
            documentRequest: { query: query },
            repositoryIdentifier: repID,
        }
    }

    // query
    if (!isArrayEmpty(filterQueries)) {
        request.exportQuery.documentRequest.filterQueries = filterQueries;
    }
    if (sortDirection) {
        request.exportQuery.documentRequest.sortDirection = sortDirection;
    }
    if (sortBy) {
        request.exportQuery.documentRequest.sortBy = sortBy;
    }
    if (sortFactor) {
        request.exportQuery.documentRequest.sortFactor = sortFactor;
    }

    if (maxNumOfDocuments) {
        request.documentLimit = maxNumOfDocuments;
    }

    if (conceptExportType === 'ocids') {
        request.semanticExportFilterConceptOcids = conceptOcids
    }

    switch (target) {
        case EXPORTER_TARGET_ID_FILE: {
            //return await sendExportFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/${format}/document-meta-annotated-concept-data`, `semantic_export.${format}`, request);
            return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocwebapi-export/semantic`, request);
        }
        case EXPORTER_TARGET_ID_BIGQUERY: {
            //return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/bigquery/document-meta-annotated-concept-data`, request);
            return { status: 'FAILED', message: 'Sorry, export to BigQuery currently not supported.' };
        }
        default: // do nothing
    }

    return {
        status: 'FAILED',
        message: 'Sorry, an unknown error occurred.'
    }
}

/**
 * 
 * @param {*} target 
 * @param {*} format 
 * @param {*} libraryID 
 * @param {*} conceptExportType 
 * @param {*} conceptOcids 
 * @returns 
 */
export const semanticExportFromDocumentsByLibrary = async (target, format, libraryID, conceptExportType, conceptOcids = []) => {

    const request = {
        name: createExportCenterEntryName(EXPORT_CENTER_CATEGORY_SEMANTIC),
        conceptExportType: conceptExportType,
        libraryIdentifier: libraryID,
    }

    if (conceptExportType === 'ocids') {
        request.semanticExportFilterConceptOcids = conceptOcids
    }

    switch (target) {
        case EXPORTER_TARGET_ID_FILE: {
            //return await sendExportFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/${format}/document-meta-annotated-concept-data`, `semantic_export.${format}`, request);
            return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocwebapi-export/semantic`, request);
        }
        case EXPORTER_TARGET_ID_BIGQUERY: {
            //return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/bigquery/document-meta-annotated-concept-data`, request);
            return { status: 'FAILED', message: 'Sorry, export to BigQuery currently not supported.' };
        }
        default: // do nothing
    }

    return {
        status: 'FAILED',
        message: 'Sorry, an unknown error occurred.'
    }
}


/**
 * Exports all sequences this document to a file in .fasta format.
 *
 * @param {string} docID the document ID
 */
export const exportNucleotideSequencesFromDocument = async (docID) => {
    await sendExportToFileApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-data/nucleotide/fasta?documentId=${docID}`, `nucleotide_sequences_${docID}.fasta.zip`);
}

export const exportProteinSequencesFromDocument = async (docID) => {
    await sendExportToFileApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/sequence-data/protein/fasta?documentId=${docID}`, `protein_sequences_${docID}.fasta.zip`);
}


/**
 * Exports metadata for a query.
 * @param {string} repID the repository ID
 * @param {string} queryString 
 * @param {Array} filterQueries 
 * @param {string} sortBy 
 * @param {string} sortDirection 
 * @param {Number} maxNumOfResults 
 * @param {Number} sortFactor 
 * @param {string} target EXPORTER_TARGET_ID_FILE (export center) or EXPORTER_TARGET_ID_BIGQUERY (big query)
 * @returns 
 */
export const exportDocumentsMetaData = async (repID, queryString, filterQueries, sortBy, sortDirection, maxNumOfResults, sortFactor,
    target = EXPORTER_TARGET_ID_FILE, outputFormat) => {

    // @note BQ not supported anymore -> will be available as export center download later

    const request = {
        name: createExportCenterEntryName(EXPORT_CENTER_CATEGORY_DOCUMENT_METADATA),
        exportQuery: { repositoryIdentifier: repID }
    }

    // query
    const documentRequest = {
        api: "oc-webapi",
        query: queryString
    };
    if (!isArrayEmpty(filterQueries)) {
        documentRequest.filterQueries = filterQueries;
    }
    request.exportQuery.documentRequest = documentRequest;

    if (sortDirection) {
        request.exportQuery.documentRequest.sortDirection = sortDirection;
    }
    if (sortBy) {
        request.exportQuery.documentRequest.sortBy = sortBy;
    }
    if (sortFactor) {
        request.exportQuery.documentRequest.sortFactor = sortFactor;
    }

    if (maxNumOfResults) {
        request.documentLimit = maxNumOfResults;
    }

    let queryParameters = '';
    if (outputFormat) {
        queryParameters = `?format=${outputFormat}`;
    }

    switch (target) {
        case EXPORTER_TARGET_ID_FILE: {
            //return await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/xlsx/document-meta-data`, 'results.xlsx', request);
            return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocwebapi-export/metadata${queryParameters}`, request);
        }
        case EXPORTER_TARGET_ID_BIGQUERY: {
            //return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/bigquery/document-meta-data`, request);
            return { status: 'FAILED', message: 'Sorry, export to BigQuery currently not supported.' };
        }
        default: // do nothing
    }

    return {
        status: 'FAILED',
        message: 'Sorry, an unknown error occurred.'
    }
}

/**
 * Exports metadata for a query ID (saved search).
 * @param {*} repID 
 * @param {*} queryID 
 * @param {*} sortBy 
 * @param {*} sortDirection 
 * @param {*} maxNumOfResults 
 * @param {*} sortFactor 
 * @param {*} date 
 * @returns 
 */
export const exportDocumentsMetaDataByQueryID = async (repID, queryID, sortBy, sortDirection, maxNumOfResults, sortFactor, date, groupBy, outputFormat) => {

    const request = {
        name: createExportCenterEntryName(EXPORT_CENTER_CATEGORY_DOCUMENT_METADATA),
        exportQuery: { repositoryIdentifier: repID }
    }

    // query
    const storedQuery = { queryIdentifier: queryID };
    if (date) {
        storedQuery.insertionDate = date;
    }
    request.exportQuery.storedQuery = storedQuery;

    // additional parameters
    const additionalParameters = {};
    if (sortDirection) {
        additionalParameters.sortDirection = sortDirection;
    }
    if (sortBy) {
        additionalParameters.sortBy = sortBy;
    }
    if (sortFactor) {
        additionalParameters.sortFactor = sortFactor;
    }
    if (groupBy) {
        additionalParameters.groupBy = groupBy;
    }
    if (!isObjectEmpty(additionalParameters)) {
        request.exportQuery.storedQuery.additionalQueryParameters = additionalParameters;
    }
    // maximum of results
    if (maxNumOfResults) {
        request.documentLimit = maxNumOfResults;
    }

    let queryParameters = '';
    if (outputFormat) {
        queryParameters = `?format=${outputFormat}`;
    }

    return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocwebapi-export/metadata${queryParameters}`, request);
}

/**
 * Exports metadata for document identifiers.
 *
 * @param {Array} docIDs array of objects containing the document ID as well as the repository ID for a document
 * @param {string} target EXPORTER_TARGET_ID_FILE (export center) or EXPORTER_TARGET_ID_BIGQUERY (big query)
 * @returns 
 */
export const exportDocumentsMetaDataByDocIDs = async (docIDs, target = EXPORTER_TARGET_ID_FILE, outputFormat) => {

    const request = {
        name: createExportCenterEntryName(EXPORT_CENTER_CATEGORY_DOCUMENT_METADATA),
        documentIdentifiers: docIDs,
    }

    let queryParameters = '';
    if (outputFormat) {
        queryParameters = `?format=${outputFormat}`;
    }

    switch (target) {
        case EXPORTER_TARGET_ID_FILE: {
            //return await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/xlsx/document-meta-data`, 'results.xlsx', request);
            return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocwebapi-export/metadata${queryParameters}`, request);
        }
        case EXPORTER_TARGET_ID_BIGQUERY: {
            //return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/data-export/v1/bigquery/document-meta-data`, request);
            return { status: 'FAILED', message: 'Sorry, export to BigQuery currently not supported.' };
        }
        default: // do nothing
    }

    return {
        status: 'FAILED',
        message: 'Sorry, an unknown error occurred.'
    }
}

/**
 * Exports document identifiers to readcube.
 *
 * @param {Array} docIDs array of objects containing the document ID as well as the repository ID for a document
 * @param {string} readCubeCollectionId ID of target readcube library
 * @returns 
 */
export const exportDocumentsToReadcube = async (docIDs, format = 'readcube', readCubeCollectionId) => {

    const request = {
        documentIdentifiers: docIDs,
        exportTarget: {
            readCubeCollectionId: readCubeCollectionId,
            format: format
        }
    }

    return await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/ocwebapi-export/metadata`, request);
}

export const exportPDFDocumentsView = async (repositoryID, docID, internalQuery, returnURL) => {

    const request = {
        //repository: repository,
        ocDocId: docID,
        internalQuery: internalQuery,
        onlyDomainsWithDefinedColor: true,
        queryHitColor: QUERY_HIT_COLOR,
        domainColors: DOMAIN_COLORS,
    }

    return await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/document/annotated?repositoryId=${repositoryID}`, '', request, returnURL);
}


/**
 * Exports document HTML to PDF file
 *
 * @param {request} request containing base64 encoded HTML text and filename
 * @returns 
 */

export const fetchDocumentAsPDF = async (request) => {
    const result = await sendApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/conversion/pdf`, request);
    //
    return result;
}
