import {
} from '../../../../redux/actions/WebApiActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReadDocuments from '../ReadDocuments'

const mapStateToProps = state => ({
    fetching: state.webAPI.fetching,
    userData: state.user.data,
    availableRepositories: state.webAPI.availableRepositories
})

const mapDispatchToProps = dispatch => ({
})


const ReadDocumentsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReadDocuments))

export default ReadDocumentsContainer