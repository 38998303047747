import { APP_ID_IMART, APP_ID_SCIWALKER_ALPHA, APP_PROPERTIES } from "../../../../properties";
import { isArrayEmpty } from "../../util";
import linkResolverImage from "../../../../assets/images/icons/animalhealth/PubLink.gif";
import { Button } from "primereact/button";


export const hasBILinkResolver = (documentData) => {
    return (APP_PROPERTIES.APP_ID === APP_ID_IMART || APP_PROPERTIES.APP_ID === APP_ID_SCIWALKER_ALPHA) &&
        documentData && (!isArrayEmpty(documentData.doi) || !isArrayEmpty(documentData.issn));
}


export default function BILinkResolver({
    documentData,
    useIcon,
    label,
    tooltip,
    buttonStyleClass,
    iconStyle,
}) {

    const getFirstEntry = (array) => {
        return !isArrayEmpty(array) ? array[0] : '';
    }

    const onLinkClick = (url) => {
        window.open(url, '_blank');
    }

    if (hasBILinkResolver(documentData)) {
        const url =
            !isArrayEmpty(documentData.doi) ?
                `https://bi.tdnetdiscover.com/resolver/full?rft_id=info:doi/${getFirstEntry(documentData.doi)}`
                :
                !isArrayEmpty(documentData.issn) ?
                    `https://bi.tdnetdiscover.com/resolver/full?rft.issn=${getFirstEntry(documentData.issn)}&rft.volume=${documentData.vol}&rft.issue=${getFirstEntry(documentData.issue)}&rft.spage=${getFirstEntry(documentData.pgStart)}&rft.epage=${getFirstEntry(documentData.pgEnd)}&rft.date=${getFirstEntry(documentData.pubdate)}`
                    : null;

        return (
            url ?
                useIcon ?
                    <span style={iconStyle ? iconStyle : {}}>
                        <a id={`biLink_fulltext_${documentData.ocDocId}`}
                            title={tooltip ? tooltip : 'Open full text in new tab'}
                            target='_blank'
                            rel="noreferrer"
                            href={url}>
                            <img src={linkResolverImage}
                                alt={label ? label : 'Full text link'} />
                        </a>
                    </span>
                    :
                    <Button
                        id={`biLink_fulltext_${documentData.ocDocId}`}
                        className={buttonStyleClass ? buttonStyleClass : ''}
                        title={tooltip ? tooltip : 'Open full text in new tab'}
                        label={label ? label : 'Full text @ BI'}
                        onClick={e => onLinkClick(url)}
                    >
                    </Button>
                : null
        );
    }
    else {
        return null;
    }
}