import './DocviewModalHeader.scss';
import PropTypes from 'prop-types';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useCallback, useEffect, useRef, useState } from 'react';
import DocumentMetadataInfo from '../../../docparts/DocumentMetadataInfo';
import { addThousandsSeparatorToNumber } from '../../../util';
import { useSelector } from 'react-redux';
import { documentInfo } from '../../../../../redux/selectors/selectors';
import _ from 'lodash';
import ButtonAndDropdown from '../../../../common/buttonAndDropdown/ButtonAndDropdown';


const Text = {
    headerTitle: 'Header Title',
    previous: 'Previous',
    numberTop: `Document`,
    numberBottom: (currentIndex, pageLength) => `${`${pageLength !== '' ? `${currentIndex} of ${pageLength}   ` : ''}`}`,
    title: (title) => `${title}`,
    subInfo: (subInfo) => `${subInfo}`,
    next: `Next`
};

export const DocviewModalHeader = ({
    elements = [],
    selectedDocument,
    nextDocument,
    previousDocument,
    repositoryInfo,
    organisms,
    documentCount,

}) => {

    const op = useRef(null);
    const [overlayDocuments, setOverlayDocuments] = useState([])
    const [nextFive, setNextFive] = useState([])
    const [previousFive, setPreviousFive] = useState([])
    const [isNextOverlay, setIsNextOverlay] = useState(false)
    const docInfo = useSelector(documentInfo);
    useEffect(() => {
        setOverlaysData(selectedDocument.number)
    }, [selectedDocument, elements])

    const setOverlaysData = useCallback((number) => {
        if (number) {
            const index = elements.findIndex(item => item.number === selectedDocument.number)
            setNextFive(elements.slice(index + 1, index + 6))
            setPreviousFive(elements.slice((index - 5) > 0 ? index - 5 : 0, index))
        }
    }, [selectedDocument.number, elements])


    const mouseOverHandler = useCallback((event, items) => {
        setOverlayDocuments(items)
        setTimeout(() => {
            if (items.length > 0) {
                op.current.show(event)
            }
        }, 300)
    }, [nextFive, previousFive])

    const prevButtonText = <span className="header-button-label">
        <span className="p-button-icon p-c pi pi-angle-left header-button-icon"></span>
        <span className="header-button-text-prev">{Text.previous}</span>
    </span>;
    const nextButtonText = <span className="header-button-label">
        <span className="header-button-text-next">{Text.next}</span>
        <span className="p-button-icon p-c pi pi-angle-right header-button-icon"></span>
    </span>;

    return (
        <div className='headerWrapper' >
            <div className='buttonWrapper'>
                <div className='start'  >
                    <ButtonAndDropdown
                        text={prevButtonText}
                        buttonClickHandler={(e) => {
                            previousDocument(elements, selectedDocument)
                            op.current?.hide(e)
                        }}
                        dropDownClickHandler={(e) => {
                            setIsNextOverlay(false)
                            mouseOverHandler(e, previousFive)
                        }}
                        extraStyles={['header-button', 'mr-10']}
                        isDisabled={!(previousFive.length > 0)}
                    />

                </div>

                <div className='end'>
                    <ButtonAndDropdown
                        text={nextButtonText}
                        buttonClickHandler={(e) => {
                            nextDocument(elements, selectedDocument)
                            op.current?.hide(e)
                        }}
                        dropDownClickHandler={(e) => {
                            setIsNextOverlay(true)
                            mouseOverHandler(e, nextFive)
                        }}
                        extraStyles={['header-button']}
                        isDisabled={!(nextFive.length > 0)}
                    />
                </div>
            </div>

            <div className='middle-count align-center whiteSpaceNoWrap' >
                {
                    documentCount > 0 &&
                    <>
                        <div>  {Text.numberTop}   </div>
                        <div>
                            {Text.numberBottom(selectedDocument.number, addThousandsSeparatorToNumber(documentCount))}
                        </div>
                    </>}

            </div>

            <div className='middle-info-wraper'>
                {!_.isEmpty(docInfo) ?
                    <>
                        <div className='document-title' title={Text.title(docInfo.title)}>
                            {Text.title(docInfo.title)}</div>
                        <div className='subinfo'>
                            <DocumentMetadataInfo
                                compactView={true}
                                repositoryInfo={docInfo?.repositoryInfo || { name: '' }}
                                documentData={docInfo?.metadata}
                                organisms={null}
                            />
                        </div>
                    </>
                    : 'loading...'}
            </div>
            {
                overlayDocuments.length > 0 &&
                <div onMouseLeave={(e) => {
                    op.current.hide(e)
                }}
                >
                    <OverlayPanel ref={op} className="overlay-panel">
                        {
                            overlayDocuments.map((item, i) => {
                                return <div className='main-wrapper' key={item.title + i}>
                                    <div className='number'>{`${item.number}.`}</div>
                                    <div className='dvo-header-wrapper'>
                                        <div className='dvo-title' onClick={(e) => {
                                            if (isNextOverlay) {
                                                nextDocument(elements, item, true)
                                            } else {
                                                previousDocument(elements, item, true)
                                            }

                                            op.current.hide(e)
                                        }}>
                                            <div>{item.title}</div>
                                        </div>
                                        {
                                            <DocumentMetadataInfo
                                                compactView={true}
                                                repositoryInfo={repositoryInfo || {
                                                    name: item.repository || '',
                                                }}
                                                documentData={item}
                                                organisms={null}
                                            />
                                        }


                                    </div>
                                </div>
                            })
                        }
                    </OverlayPanel>
                </div>
            }
        </div >
    );
};

DocviewModalHeader.propTypes = {
    elements: PropTypes.array,
    selectedDocument: PropTypes.object,
    nextDocument: PropTypes.func,
    previousDocument: PropTypes.func,
    documentCount: PropTypes.number
};
