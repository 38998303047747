import "./ReferencedSavedSearch.css";

const ReferencedSavedSearch = ({
  savedSearchID = "No data available",
  savedSearchData,
}) => {
  return savedSearchData ? (
    <div className="saved-search-reference">
      <b>
        {savedSearchData?.fullName || `No data found for: ${savedSearchID}`}
      </b>
    </div>
  ) : (
    <div className="saved-search-unknown">
      <b>{`${savedSearchID} (no data)`}</b>
    </div>
  );
};

export default ReferencedSavedSearch;
