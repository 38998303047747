import React, { Component } from "react";
import InfoOverlayWithIcon from "../../general/InfoOverlayWithIcon";


class DomainFacetsInfo extends Component {

    render() {

        return (
            <InfoOverlayWithIcon
                infoID="domainFiltersInfo"
                title="Click for information about domain filters"
                imageAltText="Domain filters information"
                moveRightArrow={true}
                overlayContent={
                    <>
                        <h3>Domain filters</h3>
                        <p>
                            For a selected set of knowledge domains the 
                            most frequent concepts for the current search are shown in this list, 
                            together with an estimated number of matching documents.
                            Clicking "More" will load up to 500 top concepts for the respective domain.
                        </p>
                        <h5>Number of hit documents</h5>
                        <p>
                            The number of hits for each concept are estimated and can slightly vary for the 
                            same search at different times.
                        </p>
                        <h5>Refine search</h5>
                        <p>
                            Refine the current search by adding one or more concepts to the search.
                            Multiple concepts from the same domain can be selected at the same time and will be 
                            OR-combined. Concepts from different domains can be added in separate steps and
                            will be AND-combined.
                        </p>
                    </>
                }
            />
        );
    }
}

export default DomainFacetsInfo;