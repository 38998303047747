import React from 'react';
import {isArrayEmpty} from "../../../../../util";

const Assignees = ({assignees, documentData}) => (
    <div style={{marginTop: 5, marginRight: 15}}>
        {assignees ?
            <span style={{marginRight: 15}}>
                                <span title={`Assignee: ${documentData.Pat_assignees.join('; ')}`}>
                                    {`Assignee: ${assignees.slice(0, 5).join('; ')}${assignees.length > 5 ? ', ...' : ''}`}
                                </span>

                {!isArrayEmpty(documentData.Pat_assignees_parent_company) ?
                    <span title="Assignees parent company">
                                        {` (${documentData.Pat_assignees_parent_company.slice(0, 5).join('; ')}${documentData.Pat_assignees_parent_company.length > 5 ? ', ...' : ''})`}
                                    </span> : null}

                            </span> : null
        }
        {documentData.Pat_inventors ?
            <span style={{marginRight: 15}} title="Inventor">
                                {`Inventor: ${documentData.Pat_inventors.slice(0, 5).join('; ')}${documentData.Pat_inventors.length > 5 ? ', ...' : ''}`}
                            </span> : null
        }
        {documentData['Pat_priority-claim_date'] ?
            <span style={{marginRight: 15}} title="Priority claim date">
                                {`Priority claim date: ${documentData['Pat_priority-claim_date']}`}
                            </span> : null
        }
    </div>
);

export default Assignees;
