/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";

class UpdateSearchResultsInfo extends Component {

    render() {

        const infoBox = <div className="searchInfoOverlay">
            {this.props.text ? this.props.text : 'Update'}
        </div>;

        return (
            this.props.onClick ?
                <a className="secondaryLink"
                    onClick={() => this.props.onClick()}>
                    {infoBox}
                </a> : infoBox
        );
    }
}

export default UpdateSearchResultsInfo;