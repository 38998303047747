import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from 'primereact/toast';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import SeparatorPoint from '../../webapi/general/SeparatorPoint';
import { AppsService } from './core/services/dataTableService';
import { getUserApps } from '../../../redux/actions';
import { hasDateExpired } from './dynamicApps.utils';
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
import { APP_PROPERTIES } from '../../../properties';
import moment from 'moment';
import _ from 'lodash';

const DynamicApps = (props) => {
  const dispatch = useDispatch()
  const toastBC = useRef();
  const [loadingIframe, setLoadingIframe] = useState(true);
  const [sourceURL, setSourceURL] = useState('');
  const [appData, setAppData] = useState({});
  const [links, setLinks] = useState(null)
  const [insertionTime, setInsertionTime] = useState(null);
  const userApps = useSelector(state => state.user.data.userDetails.userApps)
  const { id } = useParams()
  const appInfoOverlay = useRef(null)

  useEffect(() => {
    let appURL = userApps?.find(app => app.id === id);
    //console.log(appURL)
    let source = appURL?.url
    let group = appURL?.group
    setLinks(null)
    setAppData(appURL);
    if (appURL !== undefined) {
      if (group !== undefined) {
        let userAppsSorted = userApps?.sort((a, b) => a.name > b.name ? 1 : -1)
        let appLinks = userAppsSorted?.filter(app => app.group && app.group === group && app.name !== appURL.name)
        setLinks(appLinks)
      }
      if (source !== undefined) {
        const query = new URLSearchParams(props.location.search);
        const timepoint = query.get('insertion')
        const showBIDocuments = query.get('bi_documents')
        setInsertionTime(timepoint)
        if (timepoint !== null) {
          let newURL = JSON.parse(new URL(appURL?.url).searchParams.get("params"))
          newURL.insertion = timepoint
          if (showBIDocuments !== null) {
            newURL.bi_documents = showBIDocuments
          }
          let URLtoUse = new URL(appURL?.url).search = JSON.stringify(newURL)
          let newSource = new URL(appURL?.url)
          newSource.search = `params=${new URLSearchParams(URLtoUse).toString()}`
          newSource.search = newSource.search.substring(0, newSource.search.length - 1)
          setSourceURL(newSource);
          window.history.replaceState(null, null, window.location.pathname)
        } else {
          setSourceURL(source);
        }
      }
    }
    /*let f = document.getElementById(`${appURL?.id}`)
    if (f) {
      f.src = f.src
    }*/
  }, [id, userApps]);

  useEffect(() => {
    /*let f = document.getElementById(`${appData?.id}`)
    if (f) {
      f.src = f.src
    }*/
    setLoadingIframe(true);
    if ((appData?.expiryDate && hasDateExpired(appData?.expiryDate)) || appData === undefined || (appData && Object.keys(appData)?.length === 0)) {
      setLoadingIframe(false);
    }
  }, [sourceURL]);

  const changeAlertStatus = (status, id) => {
    const appService = new AppsService()
    appService.changeAlertStatus(status, id).then(res => {
      fetchApps()
    }).catch(e => {
      showFailGrowl(e)
    })
  }

  const fetchApps = () => {
    const appService = new AppsService()
    appService.getApps().then(res => {
      switch (res.status) {
        case 'SUCCESS':
          //setApps(_.orderBy(res.payload, 'updated', 'desc'))
          //dispatch(getUserApps(_.orderBy(res.payload?.filter(item => item.status === 'Finished' || item.status === 'Updated'), 'name', 'asc')))
          dispatch(getUserApps(_.orderBy(res.payload?.filter(item => item.readyForDisplay), 'name', 'asc')))
          break
        case 'FAILED':
          //throw new Error('Fetching problem')
          showFailGrowl(res?.message)
      }

    }).catch(e => {
      showFailGrowl(e)
    })
  }
  const showFailGrowl = (e, type = 'error', summary = 'Feild!') => {
    let msg = { severity: type, summary, detail: e, life: 6000 };
    toastBC.current.show(msg);
  };

  const onClearInsertionTime = (e) => {
    setInsertionTime(null)
    let appURL = userApps?.find(app => app.id === id);
    let source = appURL?.url;
    setSourceURL(source)
  }

  return (
    <LoadingOverlay
      active={loadingIframe}
      text="Loading external content..." >
      <Toast ref={toastBC} />
      <div className="col-12" style={{ padding: 0 }}>
        {sourceURL ? (
          <React.Fragment>
            <div className="col-12 grid" style={{ borderBottom: '1px solid #ccc', paddingBottom: 15, paddingTop: 0 }}>
              <div className='col-8' style={{ paddingBottom: 0 }}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '40vw', display: 'inline-block', marginRight: appData?.template !== 'static' ? 20 : 7 }}>
                  <label title={appData?.name} style={{ fontSize: 21 }}>{appData?.name}</label>
                </span>
                {appData?.template === 'static' ?
                  <span style={{ display: 'inline-block', verticalAlign: 'top', marginTop: 3 }}>
                    <a className="infoIconLink"
                      style={{ marginTop: 0, paddingRight: 20, cursor: 'default' }}
                      title="Static app without data updates and without alerts."
                    //onClick={e => this.recallInfoOverlayMaximum.toggle(e)}
                    >
                      <img src={infoIcon} alt="Static app without data updates and without alerts."
                        style={{ marginLeft: 0 }} />
                    </a>
                  </span>
                  : null
                }
                {appData?.comments &&
                  <span style={{ verticalAlign: 'super' }}>
                    <a title="Click for information about this app"
                      onClick={(e) => appInfoOverlay.current.toggle(e)}>About</a>
                  </span>
                }
                {
                  links?.map(link => {
                    return <span key={link?.id} style={{ verticalAlign: 'super', marginLeft: 15 }}>
                      <a key={link?.id} title={link.name}
                        target='_blank'
                        rel="noreferrer"
                        href={`${APP_PROPERTIES.FRONTEND_URL}apps/${link?.id}`}>{link.name}</a>
                    </span>
                  })
                }
              </div>
              <div className='col-4' style={{ paddingRight: 0, paddingBottom: 0 }}>
                {appData?.template !== 'static' ?
                  <>
                  {insertionTime !== null ?
                    <span style={{ float: 'right', marginTop: 7 }}>
                      <SeparatorPoint />
                      <a title="Clear insertion time filter"
                        onClick={(e) => onClearInsertionTime(e)}>See unfiltered view</a>
                    </span>
                    : null
                  }
                  {appData?.dataUpdated ?
                    <span style={{ float: 'right', marginTop: 7, marginLeft: 15 }}>
                      {/*<SeparatorPoint />*/}
                      <label style={{}}>Last updated: {moment(appData?.dataUpdated).format("YYYY-MM-DD")}</label>
                    </span>
                    : null
                  }
                  <label style={{ float: 'right', marginTop: 7, marginLeft: 20 }}>App update cycle:  {appData?.updateCycle}</label>
                    <span style={{ float: 'right', marginTop: 5 }}>
                      <InputSwitch style={{
                        marginRight: 10,
                        //, verticalAlign: 'sub'
                      }} checked={appData?.alertOnUpdate} onChange={(e) => changeAlertStatus(e.value, appData?.id)} />
                      <label style={{ }}>Email alerts</label>
                    </span>
                   

                    
                  </>
                  : appData?.expiryDate ?
                    <div style={{ float: 'right', marginTop: 5 }}>
                      <label style={{}}>Expiration date: {moment(appData?.expiryDate).format("YYYY-MM-DD")}</label>
                    </div>
                    : null}
              </div>
            </div>
            <OverlayPanel
              className="appOverlay"
              ref={appInfoOverlay}
              dismissable={true}>
              <div style={{}}>
                {/*appData?.description*/}
                <InputTextarea className='appInputTextarea' autoComplete='off'
                  maxLength={10000}
                  autoResize
                  disabled={true}
                  //rows={4}
                  style={{ border: 'none', opacity: '1', lineHeight: '1.3rem', padding: 0, width: '20vw' }}
                  //onChange={onAppDescriptionChange}
                  //placeholder='Description'
                  value={appData?.comments} />
              </div>
            </OverlayPanel>
            {!appData?.expiryDate || (appData?.expiryDate && !hasDateExpired(appData?.expiryDate)) ?
              <iframe
                id={appData?.id}
                title="Embedded app"
                className='iframe-apps'
                style={{
                  //position: 'fixed',
                  top: 175,
                  left: 0,
                  width: '100%',
                  height: '80vh',
                  border: 'none'
                }}
                src={sourceURL}
                onLoad={() => setLoadingIframe(false)}
                allowFullScreen={true}
              ></iframe> :
              appData?.expiryDate && hasDateExpired(appData?.expiryDate)
                ?
                <div className="col-12" style={{ paddingTop: 20 }}>
                  {`Sorry, the app you are looking for has expired on ${appData?.expiryDate}.`}
                </div>
                :
                null
            }
          </React.Fragment>
        ) : (
          <span>Oops! The app you are looking for is inactive or was deleted. Please contact someone from the support team.</span>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default DynamicApps;
