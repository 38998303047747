import classNames from 'classnames';
import { orderBy } from 'lodash';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteItem } from './useDeleteItem';
import styles from './NotificationEditingTab.module.scss';
import { NotificationItem } from './NotificationItem/NotificationItem';
import {
  deleteNotification,
  getNotifications,
  createNotification
} from '../../../redux/actions/NotificationsAdtions';
import { selectAllNotifications } from '../../../redux/selectors/notifications';
import { ConfirmationDialog } from '../../common/ConfirmationDialog/ConfirmationDialog';

export const NotificationEditingTab = () => {
  const [createNotificationText, setCreateNotificationText] = useState('');
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isCreationModeEnabled, setIsCreationModeEnabled] = useState(false);

  const allNotifications = useSelector(selectAllNotifications);

  const dispatch = useDispatch();
  
  const {
    onOpenDeleteItemModal,
    onCloseDeleteItemModal,
    isDeleteItemModalVisible,
    selectedItemIdForDelete,
    DeleteModalText,
  } = useDeleteItem();

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  useEffect(() => {
    if (allNotifications?.length) {
      const enabledNotification = allNotifications?.find((notification) => notification.enabled);
      setSelectedNotification(enabledNotification);
    }
  }, [allNotifications]);

  const handleChangeCreateNotificationText = (event) => {
    setCreateNotificationText(event.target.value);
  };

  const handleCreateNotification = () => {
    dispatch(createNotification(createNotificationText));
    setIsCreationModeEnabled(false);
    setCreateNotificationText('');
  };

  const handleOnCreationMode = () => {
    setCreateNotificationText('');
    setIsCreationModeEnabled(true);
  };

  const handleOffCreationMode = () => {
    setCreateNotificationText('');
    setIsCreationModeEnabled(false);
  };

  const onDeleteNotification = () => {
    dispatch(deleteNotification(selectedItemIdForDelete));
    onCloseDeleteItemModal();
  };

  return (
    <>
      <ConfirmationDialog
        key="1"
        visible={isDeleteItemModalVisible}
        isRemoval
        title={DeleteModalText.DeleteItemModalTitle('Notification')}
        text={DeleteModalText.DeleteItemModalText('notification')}
        confirmText={DeleteModalText.DeleteButtonTitle}
        cancelText={DeleteModalText.CancelButtonTitle}
        onClose={onCloseDeleteItemModal}
        onConfirm={onDeleteNotification}
      />
      <div className={styles.container}>
        {orderBy(allNotifications, ['notificationId'], 'desc').map((notification) => (
          <React.Fragment key={notification.notificationId}>
            <NotificationItem
              notification={notification}
              checked={selectedNotification?.notificationId === notification.notificationId}
              onOpenDeleteNotificationModal={onOpenDeleteItemModal}
            />
          </React.Fragment>
        ))}
        {isCreationModeEnabled ? (
          <div className={classNames(styles.creationRow, styles.container)}>
            <InputTextarea
              autoFocus
              onChange={handleChangeCreateNotificationText}
              value={createNotificationText}
              className={styles.notificationText}
            />
            <i
              aria-hidden
              onClick={handleOffCreationMode}
              className={classNames('pi pi-times', styles.editButton)}
            />
            <i
              aria-hidden
              onClick={handleCreateNotification}
              className={classNames('pi pi-check', styles.editButton)}
            />
          </div>
        ) : (
          <Button onClick={handleOnCreationMode} className={styles.createButton}>
            Create notification
          </Button>
        )}
      </div>
    </>
  );
};
