import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

class EditDomain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            domainLabel: '',
            domainName: '',
            domainColor: ''
        }
    }

    componentDidMount() {
        let domainName = this.props.selectedDomainName ? this.props.selectedDomainName : ''
        let domainLabel = this.props.selectedDomainLabel ? this.props.selectedDomainLabel : ''
        let domainColor = this.props.selectedDomainColor ? this.props.selectedDomainColor : ''

        this.setState({
            domainLabel: domainLabel,
            domainName: domainName,
            domainColor: domainColor
        })
    }

    onEditDomain = () => {
        this.props.onEditDomain(this.props.selectedDomainID, this.state.domainLabel, this.state.domainName,
            this.props.selectedDomainActive, this.state.domainColor, this.props.selectedDomainDescription, this.props.selectedDomainOrderPriority)
    }

    render() {

        const footerEditDomain = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Cancel"
                    className='p-button-secondary p-button-sm'
                    onClick={this.props.onCancelEditDomain}
                    style={{ marginRight: 5 }} />
                <Button label="Edit"
                    className='p-button-sm primaryButton'
                    disabled={this.state.domainLabel !== '' ? false : true}
                    onClick={this.onEditDomain}
                />
            </div>
        )

        return (
            <Dialog visible={this.props.displayEditDomain} style={{ 'width': "60vw" }} header="Domain details" focusOnShow={false}
                modal={true} footer={footerEditDomain} dismissableMask={false}
                onHide={() => this.props.onCancelEditDomain()} className='styledDialog'>
                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 0, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="p-col-12" style={{ padding: '.5em', paddingBottom: 15 }}>
                        Domain ID: {this.props.selectedDomainID}
                    </div>
                    <div className="col-12 grid p-fluid">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="domainLabel">Domain label (for displaying)</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="domainLabel"
                                onChange={(e) => { this.setState({ domainLabel: e.target.value }) }} value={this.state.domainLabel} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="domainName">Domain name</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="domainName" disabled={false}
                                onChange={(e) => { this.setState({ domainName: e.target.value }) }} value={this.state.domainName} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="domainColor">Color</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: 7 }}>#</div>
                                <InputText style={{ padding: 5, border: '1px solid #d6d6d6', marginTop: 5, backgroundColor: this.state.domainColor ? `#${this.state.domainColor}` : '' }} id="domainColor"
                                    onChange={(e) => { this.setState({ domainColor: e.target.value }) }} value={this.state.domainColor} />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default EditDomain