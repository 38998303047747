import React, { Component } from 'react';
import { APP_PROPERTIES } from '../../../../properties/index';
import { sleep } from '../../util';
import { CHEMISTRY_FORMAT_MOL, STRUCTURE_FORMAT_MOL } from '../utils/chemistrySearchConstants';
import { areStructuresEqual } from '../utils/structure';


let marvinSketcherInstance;

class StructureEditor extends Component {

    componentDidMount = async () => {

        //console.log('componentDidMount');

        // --- wait until window.getMarvinPromise is loaded --- //
        // --- this can take some time, wait 30 seconds max --- //
        let count = 0;
        while (!window.getMarvinPromise && count < 30) {
            await sleep(1000);
            count++;
        }

        var self = this;

        // --- init structure editor --- //
        window.getMarvinPromise(`#sketch`).done(function (sketcherInstance) {
            // --- store sketcher instance for later use -------------- //
            marvinSketcherInstance = sketcherInstance;
            //marvinSketcherInstance.setAutoChirality(true);
            //const settings = sketcherInstance.getDisplaySettings();
            //console.log('settings: ', settings);
            // --- add on structure change event handler --- //
            marvinSketcherInstance.on("molchange", function (e) {
                //console.log('format: ', self.props.format);
                const format = self.props.format ? self.props.format : CHEMISTRY_FORMAT_MOL;
                marvinSketcherInstance.exportStructure(format)?.then(struct => {
                    //console.log('empty: ', marvinSketcherInstance.isEmpty());
                    if (marvinSketcherInstance.isEmpty()) {
                        //console.log('changed: empty');
                        self.props.onStructureChange(null);
                    }
                    else {
                        //console.log('changed: ', struct);
                        self.props.onStructureChange(struct);
                    }
                });
            });
            // --- set initial structure --- //
            //console.log('import mount');
            marvinSketcherInstance.importStructure(self.props.format, self.props.structure);
        }).fail(function () {
            this.growl.show({ severity: 'error', summary: 'Error occurred', detail: 'loading of the sketcher failed.' });
        });
    }


    componentDidUpdate(prevProps) {
        //console.log('this.props.format: ', this.props.format);
        //console.log('prevProps.structure: ', prevProps.structure);
        //console.log('equal: ', _.isEqual(prevProps.structure, this.props.structure));
        this.updateStructure(this.props.structure, this.props.format);
    }
    /**/

    updateStructure = (structure, format) => {
        //const format = this.props.format ? this.props.format : 'mol';
        if (marvinSketcherInstance) {
            if (marvinSketcherInstance.isEmpty()) {
                if (structure) {
                    //console.log('---new1: ', structure);
                    marvinSketcherInstance.importStructure(format, structure);
                }
            }
            else {
                marvinSketcherInstance.exportStructure(format)?.then(currStruct => {
                    //console.log('---old: ', currStruct);
                    if (!areStructuresEqual(currStruct, structure, format)) {
                        //console.log('---UPDATE STRUCTURE: ');
                        //console.log('*** currStruct: ', currStruct);
                        //console.log('*** structure: ', structure);
                        marvinSketcherInstance.importStructure(format, structure);
                    }
                });
            }
        }
    }


    /**
     * Clear structure editor.
     */
    clearStructure = () => {
        marvinSketcherInstance.importStructure(STRUCTURE_FORMAT_MOL, null);
    }


    render() {

        // https://marvinjs-demo.chemaxon.com/latest/examples/example-toolbars.html

        //console.log('this.props.toolbars: ', this.props.toolbars);
        //const wsVersion = process.env.REACT_APP_CHEMAXON_WEBSERVICES_VERSION ? process.env.REACT_APP_CHEMAXON_WEBSERVICES_VERSION : 'v0';
        const wsVersion = APP_PROPERTIES.CHEMISTRY.CHEMAXON_WEBSERVICES_VERSION ? APP_PROPERTIES.CHEMISTRY.CHEMAXON_WEBSERVICES_VERSION : 'v0';
        const wsUrl = wsVersion === 'mw' ? `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/${APP_PROPERTIES.CHEMISTRY.CHEMAXON_WEBSERVICES_URL}` : APP_PROPERTIES.CHEMISTRY.CHEMAXON_WEBSERVICES_URL;

        let editorPage = APP_PROPERTIES.CHEMISTRY.INCLUDE_CHEMAXON_WEBSERVICES ? `editorws.html?ws=${wsUrl}&v=${wsVersion}` : 'editor.html';

        return (
            <React.Fragment>
                <iframe
                    key={`sketch`}
                    id={`sketch`}
                    src={`${APP_PROPERTIES.CHEMISTRY.CHEMAXON_MARVIN4JS_URL}/${editorPage}`}
                    title="Chemistry editor"
                    //data-toolbars="reaction"
                    //data-toolbars="standardReaction"
                    data-toolbars={this.props.toolbars}
                    style={{
                        overflow: "hidden",
                        minHeight: this.props.minHeight,
                        //width: this.props.width,
                        //height: '500px',
                        width: '100%',
                        //border: "1px solid white",
                        padding: "5px",
                        border: "1px solid #ddd"
                    }} />
            </React.Fragment>
        )
    }
}

export default StructureEditor;
