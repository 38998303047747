/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory } from 'react-router-dom';

export const NotFound = () => {

	const history = useHistory();

	const onHomeClick = () => {
		history.push('/')
	}

	return (
		<div style={{
			marginTop: 36, textAlign: 'center'
		}}>
			<h1 style={{ marginBottom: 30 }}>Page not found</h1>
			<span className="footer-text-left">
				<div style={{ marginBottom: 20 }}>
					<p style={{ fontSize: 16 }}>We are sorry, the requested page is not available.</p>
				</div>
				<div style={{ marginBottom: 20 }}>
					<a style={{ margin: '0 0px', fontWeight: 'bold', fontSize: 16 }} onClick={() => onHomeClick()} target="_blank" rel="noopener noreferrer">Go to homepage</a>
				</div>
			</span>
		</div>
	);
}