import { APP_PROPERTIES } from '../../../../properties';
import { NotificationsRepository } from './NotificationsRepository';

export class NotificationsRepositoryImpl extends NotificationsRepository {
  static #MainUrl = APP_PROPERTIES.MIDDLEWARE_BASE_URL;

  static #NotificationsEntitiesExtraHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  static #Urls = {
    Notifications: () => `${NotificationsRepositoryImpl.#MainUrl}/api/notifications`,
    EnabledNotifications: () => `${NotificationsRepositoryImpl.#MainUrl}/api/notifications/enabled`,
    CreateNotifications: () => `${NotificationsRepositoryImpl.#MainUrl}/api/notifications`,
    ToggleNotificationState: (id, state) => `${NotificationsRepositoryImpl.#MainUrl}/api/notifications/${id}/${state ? 'enable' : 'disable'}`,
    DeleteNotification: (notificationId) => `${NotificationsRepositoryImpl.#MainUrl}/api/notifications/${notificationId}`,
  };

  async getNotifications() {
    try {
      const result = await this.sendApiRequest(
        NotificationsRepository.Methods.Get,
        NotificationsRepositoryImpl.#Urls.Notifications(),
        null,
        false,
        {...NotificationsRepositoryImpl.#NotificationsEntitiesExtraHeaders, 'Authorization': `Bearer ${localStorage.token}`}
      );
      if (NotificationsRepository.checkIfResponseFailed(result.status)) {
        throw new Error(result?.message);
      }

      return result;
    } catch (error) {
      return {
        error: NotificationsRepositoryImpl.getErrorMessage(error.message)
      };
    }
  };

  async getEnabledNotifications() {
    try {
      const result = await this.sendApiRequest(
        NotificationsRepository.Methods.Get,
        NotificationsRepositoryImpl.#Urls.EnabledNotifications(),
        null,
        false,
        { ...NotificationsRepositoryImpl.#NotificationsEntitiesExtraHeaders, 'Authorization': `Bearer ${localStorage.token}` }
      );
      if (NotificationsRepository.checkIfResponseFailed(result.status)) {
        throw new Error(result?.message);
      }

      return result;
    } catch (error) {
      return {
        error: NotificationsRepositoryImpl.getErrorMessage(error.message)
      };
    }
  };

  async createNotification(notificationText) {
    try {
      const requestBody = { text: notificationText };
      const result = await this.sendApiRequest(
        NotificationsRepository.Methods.Post,
        NotificationsRepositoryImpl.#Urls.CreateNotifications(),
        requestBody,
        false,
        {...NotificationsRepositoryImpl.#NotificationsEntitiesExtraHeaders, 'Authorization': `Bearer ${localStorage.token}`}
      );
      if (NotificationsRepository.checkIfResponseFailed(result.status)) {
        throw new Error(result?.message);
      }

      return result;
    } catch (error) {
      return {
        error: NotificationsRepositoryImpl.getErrorMessage(error.message)
      };
    }
  };

  async toggleNotificationState(notificationId, notificationState) {
    try {
      const result = await this.sendApiRequest(
        NotificationsRepository.Methods.Patch,
        NotificationsRepositoryImpl.#Urls.ToggleNotificationState(notificationId, notificationState),
        null,
        false,
        {...NotificationsRepositoryImpl.#NotificationsEntitiesExtraHeaders, 'Authorization': `Bearer ${localStorage.token}`}
      );
      if (NotificationsRepository.checkIfResponseFailed(result.status)) {
        throw new Error(result?.message);
      }

      return result;
    } catch (error) {
      return {
        error: NotificationsRepositoryImpl.getErrorMessage(error.message),
      };
    }
  }

  async updateNotification(notification) {
    try {
      const result = await this.sendApiRequest(
        NotificationsRepository.Methods.Put,
        NotificationsRepositoryImpl.#Urls.Notifications(),
        notification,
        false,
        {...NotificationsRepositoryImpl.#NotificationsEntitiesExtraHeaders, 'Authorization': `Bearer ${localStorage.token}`}
      );
      if (NotificationsRepository.checkIfResponseFailed(result.status)) {
        throw new Error(result?.message);
      }

      return result;
    } catch (error) {
      return {
        error: NotificationsRepositoryImpl.getErrorMessage(error.message),
      };
    }
  }

  async deleteNotification(notificationId) {
    try {
      const result = await this.sendApiRequest(
        NotificationsRepository.Methods.Delete,
        NotificationsRepositoryImpl.#Urls.DeleteNotification(notificationId),
        null,
        false,
        {...NotificationsRepositoryImpl.#NotificationsEntitiesExtraHeaders, 'Authorization': `Bearer ${localStorage.token}`}
      );
      if (NotificationsRepository.checkIfResponseFailed(result.status)) {
        throw new Error(result?.message);
      }

      return result;
    } catch (error) {
      return {
        error: NotificationsRepositoryImpl.getErrorMessage(error.message),
      };
    }
  }
}
