import moment from "moment"
//import React, { useEffect } from "react"
//import { ExportService } from "./services/dataTableService"
import { formatBytes, isbold } from "./services/helperFunctions"
//import { addThousandsSeparatorToNumber } from "../../../webapi/util";

export const SORT_OPTION = [
    { label: 'Entries', value: 'count' },
    { label: 'Modified', value: 'lastModifiedDate' },
    { label: 'Name', value: 'title' }
]



const nameTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Name</span>
        <span style={{ marginBottom: 5 }}>
            <label title={rowData?.name} className={isbold(rowData)}>{rowData?.name}</label>
        </span>
    </div>
}


const insertionTimeTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Created</span>
        <span>
            <label
                title={moment(rowData?.created + "Z").format("YYYY-MM-DD")} className={isbold(rowData)}>{moment(rowData?.created + "Z").format("YYYY-MM-DD")}</label>
        </span>
    </div>
}

const categoryTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Category</span>
        <span>
            <label
                title={rowData?.category} className={isbold(rowData)}>{rowData.category?.charAt(0) + rowData.category?.toLowerCase().slice(1) || ''}</label>
        </span>
    </div>
}

const fileFormatTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">File size</span>
        <span>
            <label title="Not available" className={isbold(rowData)}>{rowData?.fileFormat || ''}</label>
        </span>
    </div>
}


const fileSizeTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Format</span>
        <span>
            <label title="Not available" className={isbold(rowData)}>{rowData?.fileSize ? formatBytes(rowData?.fileSize) : '-'}</label>
        </span>
    </div>
}



/*const recordsTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Records</span>
        <span>
            <label title="Not available" className={isbold(rowData)}>{addThousandsSeparatorToNumber(rowData?.numberRecords) || '-'}</label>
        </span>
    </div>
}*/



const expireInTemplate = (rowData) => {
    return <div>
        <span className="p-column-title">Expires in</span>
        <span>
            <label title="Not available" className={isbold(rowData)}>{rowData?.expiresIn ? rowData?.expiresIn + ' days' : '-'}</label>
        </span>
    </div>
}


export const COLUMNS = [
    {
        field: "check",
        selectionMode: 'multiple',
        style: { width: '4em', fontWeight: 'normal' }
    },
    {
        field: "name",
        header: "Name",
        body: nameTemplate,
        sortable: true,
        //style: { width: '16em', textAlign: 'left' }
        style: { width: '16em', textAlign: 'left' }
    },
    {
        field: "created",
        header: "Created",
        body: insertionTimeTemplate,
        sortable: true,
        //style: { width: '7.2em', textAlign: 'left' }
        style: { width: '7.2em', textAlign: 'left' }
    },
    {
        field: "category",
        header: 'Category',
        body: categoryTemplate,
        sortable: true,
        //style: { width: '7.8em', textAlign: 'left' }
        style: { width: '7.8em', textAlign: 'left' }
    },
    /*{
        field: "numberRecords",
        header: 'Records',
        body: recordsTemplate,
        sortable: true,
        //style: { width: '8em', textAlign: 'left', fontWeight: 'normal' }
        style: { width: '5.5em', textAlign: 'left', fontWeight: 'normal' }
    },*/
    {
        field: "fileSize",
        header: "File size",
        body: fileSizeTemplate,
        sortable: true,
        //style: { width: '6em', textAlign: 'left', fontWeight: 'normal' }
        style: { width: '6em', textAlign: 'left', fontWeight: 'normal' }
    },
    {
        field: "fileFormat",
        header: "Format",
        body: fileFormatTemplate,
        sortable: true,
        //style: { width: '6em', textAlign: 'left', fontWeight: 'normal' }
        style: { width: '5em', textAlign: 'left', fontWeight: 'normal' }
    },
    {
        field: "expiresin",
        header: "Expires in",
        body: expireInTemplate,
        sortable: true,
        //style: { width: '7em', textAlign: 'left', fontWeight: 'normal' }
        style: { width: '6em', textAlign: 'left', fontWeight: 'normal' }
    }
]




export const EXPORT_TITLE = 'All exports';

export const EXPORT_DESCRIPTION = 'All exports in all collections';
export const ROW_PER_PAGE_OPTIONS = [2, 10, 20, 50];



export const EXPORT_STATUS = {

    Waiting: {
        label: 'Waiting',
    },
    Processing: {
        label: 'In progress',
    },
    Failed: {
        label: 'Failed',
        color: '#f00'
    },
    Finished: {
        label: 'Finished',
    }
}