import { PureComponent } from 'react';


class ToggleSections extends PureComponent {

    render() {
        return (
            <>
                <label className="valignMiddle">Sections:</label>
                <a style={{ marginLeft: 10 }}
                    id="openAllSectionsBtn"
                    className="p-button-secondary p-button-sm buttonSmall valignMiddle"
                >Open</a>
                <span className="valignMiddle" style={{ marginLeft: 5 }}> | </span>
                <a style={{ marginLeft: 5 }}
                    id="closeAllSectionsBtn"
                    className="p-button-secondary p-button-sm buttonSmall valignMiddle"
                //label="Close
                >Close</a>
            </>
        );
    }
}

export default ToggleSections;