import * as ActionTypes from './index';
import * as UserApi from '../../api/UserApi';
import { APP_PROPERTIES, FUNCTION_ROLES } from '../../properties/index';
import { convertMillisecondsToMinutes, millisecondsLeftInSession, hasUserRole } from '../../components/webapi/util';

// Actions for Users 

export const doLogin = (username, password, history, showLoginGrowl, forceLogin) => (dispatch) => {
    dispatch(ActionTypes.startFetching())
    if (username && password) {
        return UserApi.doLogin(username, password, forceLogin)
            .then((answer) => {
                //console.log('answer: ', answer);
                switch (answer.status) {
                    case 'SUCCESS':
                        //localStorage.removeItem('token')
                        let savedSearchesURL = undefined
                        let savedSearchesParams = undefined
                        let localStorageSavedObject = localStorage.getItem('savedSearches')
                        if (localStorageSavedObject !== null) {
                            let savedSearchesObjectJSON = JSON.parse(localStorageSavedObject)
                            savedSearchesURL = savedSearchesObjectJSON.url
                            savedSearchesParams = savedSearchesObjectJSON.params
                        }
                        localStorage.clear()
                        localStorage.setItem('token', answer.payload.token)
                        dispatch(loginSuccess())
                        dispatch(isLoggedIn(history))

                        let homepage = ''
                        const tokenParts = localStorage.token.split('.');
                        const tokenPayload = JSON.parse(atob(tokenParts[1]));
                        if (savedSearchesURL !== undefined && savedSearchesURL !== "/forgotpw" && savedSearchesParams !== "") {
                            homepage = savedSearchesURL
                            let selectedFunctionRole = undefined
                            FUNCTION_ROLES && FUNCTION_ROLES.forEach(func => {
                                if (func.url === homepage)
                                    selectedFunctionRole = func
                            })
                            let hasLandingPage = false
                            let selectedHomepage = ''

                            if (selectedFunctionRole === undefined) {
                                hasLandingPage = true
                                selectedHomepage = homepage
                            } else if (selectedFunctionRole !== undefined) {
                                tokenPayload.rol.forEach(role => {
                                    if (role.toLowerCase().includes(selectedFunctionRole.name)) {
                                        hasLandingPage = true
                                        selectedHomepage = selectedFunctionRole.url
                                    }
                                })
                            }

                            if (hasLandingPage === false) {
                                if (tokenPayload.landing) {
                                    let tokenLanding = tokenPayload.landing;
                                    let hasLanding = false
                                    tokenPayload.rol.forEach(role => {
                                        if (role.toLowerCase().includes(tokenLanding)) {
                                            FUNCTION_ROLES.forEach(role => {
                                                if (role.name === tokenLanding) {
                                                    selectedHomepage = role.url
                                                    hasLanding = true
                                                }
                                            })
                                        }
                                    })
                                    if (hasLanding === false) {
                                        selectedHomepage = APP_PROPERTIES.HOME_PAGE
                                    }
                                } else {
                                    selectedHomepage = APP_PROPERTIES.HOME_PAGE
                                }
                            }
                            history.replace({ pathname: selectedHomepage, search: savedSearchesParams })

                        } else if (savedSearchesURL !== undefined && savedSearchesURL !== "/forgotpw" && savedSearchesParams === "") {
                            homepage = savedSearchesURL
                            let selectedFunctionRole = undefined
                            FUNCTION_ROLES && FUNCTION_ROLES.forEach(func => {
                                if (func.url === homepage)
                                    selectedFunctionRole = func
                            })
                            let hasLandingPage = false
                            let selectedHomepage = ''

                            if (selectedFunctionRole === undefined) {
                                hasLandingPage = true
                                selectedHomepage = homepage
                            } else if (selectedFunctionRole !== undefined) {
                                tokenPayload.rol.forEach(role => {
                                    if (role.toLowerCase().includes(selectedFunctionRole.name)) {
                                        hasLandingPage = true
                                        selectedHomepage = selectedFunctionRole.url
                                    }
                                })
                            }


                            if (hasLandingPage === false) {
                                if (tokenPayload.landing) {
                                    let tokenLanding = tokenPayload.landing;
                                    let hasLanding = false
                                    tokenPayload.rol.forEach(role => {
                                        if (role.toLowerCase().includes(tokenLanding)) {
                                            FUNCTION_ROLES.forEach(role => {
                                                if (role.name === tokenLanding) {
                                                    selectedHomepage = role.url
                                                    hasLanding = true
                                                }
                                            })
                                        }
                                    })
                                    if (hasLanding === false) {
                                        selectedHomepage = APP_PROPERTIES.HOME_PAGE
                                    }
                                } else {
                                    selectedHomepage = APP_PROPERTIES.HOME_PAGE
                                }
                            }
                            history.replace({ pathname: selectedHomepage })
                        } else {
                            if (tokenPayload.landing) {
                                let tokenLanding = tokenPayload.landing;
                                let hasLanding = false
                                tokenPayload.rol.forEach(role => {
                                    if (role.toLowerCase().includes(tokenLanding)) {
                                        FUNCTION_ROLES.forEach(role => {
                                            if (role.name === tokenLanding) {
                                                homepage = role.url
                                                hasLanding = true
                                            }
                                        })
                                    }
                                })
                                if (hasLanding === false) {
                                    homepage = APP_PROPERTIES.HOME_PAGE
                                }
                            } else {
                                homepage = APP_PROPERTIES.HOME_PAGE
                            }
                            history.replace(homepage)
                        }

                        break
                    case 'FAILED':
                        dispatch(loginFailed(answer.message))
                        dispatch(ActionTypes.doneFetching())
                        history.push('/')
                        showLoginGrowl(answer.message && answer.message.message ? answer.message.message : answer.message)
                        break
                    default:
                        dispatch(loginFailed(answer.message))
                        dispatch(ActionTypes.doneFetching())
                        history.push('/')
                        showLoginGrowl(answer.message && answer.message.message ? answer.message.message : answer.message)
                }
            })
    }
    return dispatch(loginEmpty())
}

//export const doLoginSAML = (history, token, pathName) => (dispatch) => {
export const doLoginSAML = (history, token) => (dispatch) => {
    //console.log(pathName)
    //console.log("SAML")
    let savedSearchesURL = undefined
    let savedSearchesParams = undefined
    let localStorageSavedObject = localStorage.getItem('savedSearches')
    if (localStorageSavedObject !== null) {
        let savedSearchesObjectJSON = JSON.parse(localStorageSavedObject)
        savedSearchesURL = savedSearchesObjectJSON.url
        savedSearchesParams = savedSearchesObjectJSON.params
    }
    localStorage.clear()
    localStorage.setItem('token', token)
    dispatch(isLoggedIn(history))
    dispatch(loginSuccess())

    let homepage = ''
    const tokenParts = localStorage.token.split('.');
    const tokenPayload = JSON.parse(atob(tokenParts[1]));
    if (savedSearchesURL !== undefined && savedSearchesURL !== "/forgotpw" && savedSearchesParams !== "") {
        homepage = savedSearchesURL
        let selectedFunctionRole = undefined
        FUNCTION_ROLES && FUNCTION_ROLES.forEach(func => {
            if (func.url === homepage)
                selectedFunctionRole = func
        })
        let hasLandingPage = false
        let selectedHomepage = ''

        if (selectedFunctionRole === undefined) {
            hasLandingPage = true
            selectedHomepage = homepage
        } else if (selectedFunctionRole !== undefined) {
            tokenPayload.rol.forEach(role => {
                if (role.toLowerCase().includes(selectedFunctionRole.name)) {
                    hasLandingPage = true
                    selectedHomepage = selectedFunctionRole.url
                }
            })
        }

        if (hasLandingPage === false) {

            if (tokenPayload.landing) {
                let tokenLanding = tokenPayload.landing;
                let hasLanding = false
                tokenPayload.rol.forEach(role => {
                    if (role.toLowerCase().includes(tokenLanding)) {
                        FUNCTION_ROLES.forEach(role => {
                            if (role.name === tokenLanding) {
                                selectedHomepage = role.url
                                hasLanding = true
                            }
                        })
                    }
                })
                if (hasLanding === false) {
                    selectedHomepage = APP_PROPERTIES.HOME_PAGE
                }
            } else {
                selectedHomepage = APP_PROPERTIES.HOME_PAGE
            }


        }
        history.replace({ pathname: selectedHomepage, search: savedSearchesParams })

    } else if (savedSearchesURL !== undefined && savedSearchesURL !== "/forgotpw" && savedSearchesParams === "") {
        homepage = savedSearchesURL
        let selectedFunctionRole = undefined
        FUNCTION_ROLES && FUNCTION_ROLES.forEach(func => {
            if (func.url === homepage)
                selectedFunctionRole = func
        })
        let hasLandingPage = false
        let selectedHomepage = ''

        if (selectedFunctionRole === undefined) {
            hasLandingPage = true
            selectedHomepage = homepage
        } else if (selectedFunctionRole !== undefined) {
            tokenPayload.rol.forEach(role => {
                if (role.toLowerCase().includes(selectedFunctionRole.name)) {
                    hasLandingPage = true
                    selectedHomepage = selectedFunctionRole.url
                }
            })
        }

        if (hasLandingPage === false) {
            if (tokenPayload.landing) {
                let tokenLanding = tokenPayload.landing;
                let hasLanding = false
                tokenPayload.rol.forEach(role => {
                    if (role.toLowerCase().includes(tokenLanding)) {
                        FUNCTION_ROLES.forEach(role => {
                            if (role.name === tokenLanding) {
                                selectedHomepage = role.url
                                hasLanding = true
                            }
                        })
                    }
                })
                if (hasLanding === false) {
                    selectedHomepage = APP_PROPERTIES.HOME_PAGE
                }
            } else {
                selectedHomepage = APP_PROPERTIES.HOME_PAGE
            }
        }
        history.replace({ pathname: selectedHomepage })
    } else {
        if (tokenPayload.landing) {
            let tokenLanding = tokenPayload.landing;
            let hasLanding = false
            tokenPayload.rol.forEach(role => {
                if (role.toLowerCase().includes(tokenLanding)) {
                    FUNCTION_ROLES.forEach(role => {
                        if (role.name === tokenLanding) {
                            homepage = role.url
                            hasLanding = true
                        }
                    })
                }
            })
            if (hasLanding === false) {
                homepage = APP_PROPERTIES.HOME_PAGE
            }
        } else {
            homepage = APP_PROPERTIES.HOME_PAGE
        }
        history.replace(homepage)
    }
    //return dispatch(loginEmpty())
}

export const updatePassword = (history, userInfos, showUpdatePasswordSuccessGrowl, showUpdatePasswordFailGrowl) => () => {
    return UserApi.updatePassword(userInfos)
        .then(answer => {
            switch (answer.status) {
                case 'SUCCESS':
                    //console.log('Update successful.')
                    showUpdatePasswordSuccessGrowl()
                    setTimeout(
                        function () {
                            !userInfos.username && history.replace('/')
                        },
                        3000)
                    //!userInfos.username && history.replace('/')
                    break
                case 'FAILED':
                    showUpdatePasswordFailGrowl(answer.payload)
                    break
                default:
                    console.log('Could not create new user.')
            }
        })
}

export const isLoggedIn = (history) => (dispatch) => {
    dispatch(ActionTypes.startFetching());
    return UserApi.fetchUserInformation()
        .then((answer) => {
            switch (answer.status) {
                case 'SUCCESS':
                    if (hasUserRole("ROLE_APPS")) {
                        dispatch(fetchAppsForUser(history))
                        //dispatch(fetchAdminAppsForUser(history))
                    }
                    //dispatch(fetchRepositoriesForUser(history))
                    dispatch(isAuthenticated(answer.payload))
                    dispatch(ActionTypes.doneFetching())
                    break
                case 'FAILED':
                default:
                    dispatch(isNotAuthenticated(answer.message))
                    dispatch(ActionTypes.doneFetching())
                    history.push('/')
            }
        })
}

export const fetchAppsForUser = (history) => (dispatch) => {
    return UserApi.fetchUserApps()
        .then((answer) => {
            switch (answer.status) {
                case 'SUCCESS':
                    dispatch(getUserApps(answer.payload?.filter(item => item.readyForDisplay)))
                    break
                case 'FAILED':
                    dispatch(getUserApps([]))
                    break
                default:
                    history.push('/')
            }
        })
}

/*export const fetchRepositoriesForUser = (history) => (dispatch) => {
    return UserApi.fetchUserRepositories()
        .then((answer) => {
            switch (answer.status) {
                case 'SUCCESS':
                    dispatch(getUserRepositories(answer.payload))
                    break
                case 'FAILED':
                    dispatch(getUserRepositories([]))
            }
        })
}*/
/*export const fetchAdminAppsForUser = (history) => (dispatch) => {
    return UserApi.fetchAdminApps()
        .then((answer) => {
            switch (answer.status) {
                case 'SUCCESS':
                    dispatch(getAdminApps(answer.payload))
                    break
                case 'FAILED':
                    dispatch(getAdminApps([]))
                    break
                default:
                    history.push('/')
            }
        })
}*/

export const getUserApps = (userApps) => ({
    type: ActionTypes.UPDATE_USER_APPS,
    userApps
})

export const getUserRepositories = (userRepositories) => ({
    type: ActionTypes.UPDATE_USER_REPOSITORIES,
    userRepositories
})

export const getAdminApps = (adminApps) => ({
    type: ActionTypes.UPDATE_ADMIN_APPS,
    adminApps
})

export const updateUserDetails = (userDetails) => ({
    type: ActionTypes.UPDATE_USER_DETAILS,
    userDetails
})

export const isAuthenticated = (userData) => ({
    type: ActionTypes.IS_AUTHENTICATED,
    userData
})

export const isNotAuthenticated = () => ({
    type: ActionTypes.IS_NOT_AUTHENTICATED
})

export const loginSuccess = (user) => ({
    type: ActionTypes.LOGIN_SUCCESS,
    user
})

export const loginFailed = (error) => ({
    type: ActionTypes.LOGIN_FAILED,
    error
})

export const loginEmpty = () => ({
    type: ActionTypes.LOGIN_EMPTY,
    error: 'Empty username or password'
})


/*export const logout = () => (dispatch) => {
    //localStorage.removeItem('token')
    //history.replace('/')
    localStorage.clear()
    dispatch(isNotAuthenticated())
}*/

/**
 * Logout user and delete token in middleware.
 */
export const logout = () => (dispatch) => {
    return UserApi.logout()
        .then(answer => {
            //console.log('answer: ', answer);
            switch (answer.status) {
                case 'SUCCESS': {
                    localStorage.clear()
                    dispatch(isNotAuthenticated())
                    break;
                }
                default: {
                    localStorage.clear()
                    dispatch(isNotAuthenticated())
                    console.log('Could not delete token in MW.');
                }
            }
        })
}


/**
 * Logout user in frontend: delete token in localStorage 
 * and set authenticated flag to false.
 */
export const localLogout = () => (dispatch) => {
    localStorage.clear();
    return dispatch(isNotAuthenticated());
}


/**
 * Checks if user is logged in. If not they will be redirected to login page. 
 * If yes token will be checked and new token requested if neccessary and wanted.
 * 
 * @param {*} history  history object for redirect to login page if user is not authenticated
 * @param {*} token current user token
 * @param {*} autoRefreshToken whether or not token should automatically be refreshed if session is running out of time 
 * @param {*} intervalInMs login check interval in milliseconds
 * @param {*} safetyTimeInMs if less than safety time is left for session token it will be refreshed if autoRefreshToken is true
 */
export const checkUserLoginStatus = (history, token, autoRefreshToken, intervalInMs, safetyTimeInMs) => (dispatch) => {

    const msLeft = millisecondsLeftInSession(token);
    const minutesLeft = convertMillisecondsToMinutes(msLeft);
    dispatch(minutesLeftInSession(minutesLeft));
    //console.log('msLeft: ', msLeft);

    // --- check if session token is still valid --- //
    return UserApi.fetchUserLoginStatus()
        .then((answer) => {
            //console.log('is logged in?: ', answer);
            switch (answer.status) {
                case 'SUCCESS':

                    if (answer.payload === true) {
                        //dispatch(isAuthenticated(answer.payload));
                        //console.log('check session token: ', answer.payload);

                        /*
                         const msLeftMinutes = convertMillisecondsToMinutes(msLeft);
                         const intervalInMsMinutes = convertMillisecondsToMinutes(intervalInMs);
                         const safetyTimeInMsMinutes = convertMillisecondsToMinutes(safetyTimeInMs);
                         console.log('left: ', msLeftMinutes);
                         console.log('interval: ', intervalInMsMinutes);
                         console.log('safety: ', safetyTimeInMsMinutes);
                         */

                        // --- if there is not enough time left to call again -> refresh token --- //
                        if (autoRefreshToken && ((msLeft - intervalInMs) < safetyTimeInMs)) {  //
                            //console.log('***request new token***');
                            dispatch(ActionTypes.startFetchingToken());

                            return UserApi.refreshToken()
                                .then(answer2 => {
                                    //console.log('answer: ', answer2);
                                    switch (answer2.status) {
                                        case 'SUCCESS': {
                                            localStorage.setItem('token', answer2.payload.token);
                                            //console.log('new token: ', answer2.payload.token);
                                            const msLeft2 = millisecondsLeftInSession(token);
                                            const minutesLeft2 = convertMillisecondsToMinutes(msLeft2);
                                            dispatch(minutesLeftInSession(minutesLeft2));
                                            dispatch(ActionTypes.doneFetchingToken());
                                            break;
                                        }
                                        default: {
                                            //console.log('Could not refresh token.');
                                            dispatch(ActionTypes.doneFetchingToken());
                                        }
                                    }
                                })
                        }
                    }
                    else {
                        dispatch(isNotAuthenticated(answer.message));
                        //???
                        localStorage.clear();
                        history.push('/');
                    }
                    break;

                case 'FAILED':

                    dispatch(isNotAuthenticated(answer.message));
                    //???
                    localStorage.clear();
                    history.push('/');

                    break;

                default:
            }
        })
}

export const minutesLeftInSession = (minutesLeft) => ({
    type: ActionTypes.MINUTES_LEFT_IN_SESSION,
    minutesLeftInSession: minutesLeft
})