import { COMPOUND_PROPERTY_ABS_WEIGHT, COMPOUND_PROPERTY_C_LOG_P, COMPOUND_PROPERTY_FORMULA, COMPOUND_PROPERTY_H_ACCEPTORS_COUNT, COMPOUND_PROPERTY_H_DONORS_COUNT, COMPOUND_PROPERTY_INCHI, COMPOUND_PROPERTY_INCHI_KEY, COMPOUND_PROPERTY_OCID, COMPOUND_PROPERTY_ROTATABLE_BOND_COUNT, COMPOUND_PROPERTY_SMILES, COMPOUND_PROPERTY_SOLUBILITY, COMPOUND_PROPERTY_SURFACE_AREA } from "../../utils/chemistryResults";
import { isChemCompoundOcid } from "../../utils/compoundClassGroup";

export const COMPOUND_PROPERTIES = [
    {
        id: COMPOUND_PROPERTY_OCID,
        label: 'OCID',
        info: 'OCID',
        isPrimaryProperty: true,
        isIdentifier: true,
        checkFunction: function (value) { return isChemCompoundOcid(value) },
    },
    {
        id: COMPOUND_PROPERTY_SMILES,
        label: 'SMILES',
        info: 'SMILES',
        isPrimaryProperty: true,
        isIdentifier: true,
        hideValue: true,
    },
    {
        id: COMPOUND_PROPERTY_INCHI,
        label: 'InChI',
        info: 'InChI',
        isPrimaryProperty: false,
        isIdentifier: true,
        hideValue: false,
    },
    {
        id: COMPOUND_PROPERTY_INCHI_KEY,
        label: 'InChI Key',
        info: 'InChI Key',
        isPrimaryProperty: false,
        isIdentifier: true,
        hideValue: false,
    },
    {
        id: COMPOUND_PROPERTY_ABS_WEIGHT,
        label: 'Mol. weight',
        unit: 'g/mol',
        info: 'Molecular weight in g/mol',
        roundNumber: true,
        isPrimaryProperty: true,
        isPhysChemProperty: true,
    },
    //{ id: 'tanimotoScore', label: 'Similarity', info: 'Tanimoto similarity score', roundNumber: true },
    {
        id: COMPOUND_PROPERTY_FORMULA,
        label: 'Mol. formula',
        info: 'Molecular formula',
        isIdentifier: true,
        isSecondaryProperty: true,
    },
    {
        id: COMPOUND_PROPERTY_H_ACCEPTORS_COUNT,
        label: '# Hydrogen acceptors',
        info: 'Number of hydrogen acceptors',
        isSecondaryProperty: true,
        isPhysChemProperty: true,
    },
    {
        id: COMPOUND_PROPERTY_H_DONORS_COUNT,
        label: '# Hydrogen donors',
        info: 'Number of hydrogen donors',
        isSecondaryProperty: true,
        isPhysChemProperty: true,
    },
    {
        id: COMPOUND_PROPERTY_ROTATABLE_BOND_COUNT,
        label: '# Rotatable bonds',
        info: 'Number of rotatable bonds',
        isSecondaryProperty: true,
        isPhysChemProperty: true,
    },
    {
        id: COMPOUND_PROPERTY_C_LOG_P,
        label: 'cLogP',
        info: 'cLogP value',
        isSecondaryProperty: true,
        isPhysChemProperty: true,
    },
    {
        id: COMPOUND_PROPERTY_SOLUBILITY,
        label: 'Solubility',
        info: 'Solubility',
        roundNumber: true,
        isSecondaryProperty: true,
        isPhysChemProperty: true,
    },
    {
        id: COMPOUND_PROPERTY_SURFACE_AREA,
        label: 'Total surface area',
        info: 'Total surface area in Å²',
        roundNumber: true,
        isSecondaryProperty: true,
        isPhysChemProperty: true,
    },
];