import { Component } from "react";

class TruncDataList extends Component {

    render() {
        const { dataList, maxNumberToDisplay, suffix } = this.props;
        const dataListTrunc = dataList.slice(0, maxNumberToDisplay);

        return (
            <>
                {dataListTrunc.map(entry => { return entry })}
                {maxNumberToDisplay < dataList.length ? <div>{suffix}</div> : null}
            </>
        );
    }
}

export default TruncDataList;
