import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  LIST_CUTOFF_QUERY_TERMS,
  LOGICAL_OPERATORS_AND_OR,
} from "../../../../../helpers/constants";
import TermGroupCompact from "../../../../../../../common/queryBuilder/searchForm/searchGroup/compactMode/groupTypes/TermGroupCompact/TermGroupCompact";

const TermGroupCompactContainer = ({ group }) => {
  const domainLabelsMap = useSelector(
    (state) => state.user.data.userDetails.department.domainLabelsMap
  );

  const operator = LOGICAL_OPERATORS_AND_OR.find(
    (op) => op.value === group.operator
  );
  const queryTerms = useMemo(
    () => group?.queryTerms ?? [],
    [group?.queryTerms]
  );

  const searchCriteria = group?.searchCriteria
    ? Object.entries(group.searchCriteria)
    : null;

  return (
    <TermGroupCompact
      searchCriteria={searchCriteria}
      operator={operator}
      queryTerms={queryTerms}
      filterTermsListMax={LIST_CUTOFF_QUERY_TERMS}
      domainLabelsMap={domainLabelsMap}
    />
  );
};

export default TermGroupCompactContainer;
