export const MAX_COOC_PAIRS = 35;
export const MAX_COOC_TERMS = 100;
export const MAX_COOC_SENTENCES = 50;

/**
 * get inner text of stringfiy html element
 * @param {*} s 
 * @param {*} space 
 * @returns 
 */

export const extractContent = (s, space) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    if (space) {
        const children = span.querySelectorAll('*');
        for (let i = 0; i < children.length; i++) {
            if (children[i].textContent)
                children[i].textContent += ' ';
            else
                children[i].innerText += ' ';
        }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, ' ');
};