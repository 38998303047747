
const AnnotationInfo = () => {

    return (
        <>
            <h3>Annotations</h3>

            <div className="grid" style={{ marginTop: 20, width: 800 }}>
                <div className="col-fixed" style={{ width: 240 }}>
                    <span className="ne highlightNE" style={{ backgroundColor: '#E192F4' }}>poisoning</span>
                </div>
                <div className="col">
                    Currently highlighted term using the navigation arrows in the Annotations list for a concept,
                    e.g. "poisoning" (toxicology).
                </div>
                <div className="breakRow"></div>
                <div className="col-fixed" style={{ width: 240 }}>
                    <span className="ne" style={{ backgroundColor: '#51CF66', border: '1px solid #51CF66' }}>mouse</span>
                    <span> ... </span>
                    <span className="ne" style={{ backgroundColor: '#FCC419', border: '1px solid #FCC419' }}>locomotion</span>
                    <span> ... </span>
                    <span className="ne" style={{ backgroundColor: '#4DABF7', border: '1px solid #4DABF7' }}>testosterone</span>
                </div>
                <div className="col">
                    Annotated concepts,
                    e.g. "mouse" (species), "locomotion" (effects/processes), "testosterone" (chemistry).
                </div>
                <div className="breakRow"></div>
                <div className="col-fixed" style={{ width: 240 }}>
                    <span className="ne ann_mult" style={{ backgroundColor: '#F783AC', border: '1px solid #F783AC' }}>cancer</span>
                </div>
                <div className="col">
                    Multiple annotations,
                    e.g. "cancer" (disease and species).
                </div>
                <div className="breakRow"></div>
                <div className="col-fixed" style={{ width: 240 }}>
                    <span style={{ textDecoration: 'underline' }}>ROS</span>
                </div>
                <div className="col">
                    Auto-recognized abbreviation,
                    e.g. "reactive oxygen species (ROS)".
                </div>
                <div className="breakRow"></div>
            </div>
        </>
    )
}

export default AnnotationInfo;