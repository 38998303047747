import React from 'react';
import {isArrayEmpty} from "../../../../../util";
import {CLINICAL_TRIAL_REPOS} from "../../../../../../../properties";
import Assignees from "./Assignees";
import Authors from "./Authors";
import Publication from "./Publication";
import Clinical from "./Clinical";

const BiomarkerDocumentMetadataInfo = ({documentData, repositoryInfo}) => {
    const hasNormalizedAssignees = !isArrayEmpty(documentData['Pat_assignees_normalized']);
    const assignees = hasNormalizedAssignees ? documentData['Pat_assignees_normalized'] : documentData['Pat_assignees'];

    return (
        <>
            {(!!assignees || !!documentData.Pat_inventors) &&
            <Assignees assignees={assignees} documentData={documentData}/>}

            {!!documentData.authors && isArrayEmpty(documentData.Pat_inventors) &&
            <Authors documentData={documentData}/>}

            {documentData.journal || (documentData.pubdate && !documentData.startdate) || !isArrayEmpty(documentData.doi) || !isArrayEmpty(documentData.pubType) ?
                <Publication documentData={documentData}/> : null
            }

            {/*Clinical trials repositories*/}
            {CLINICAL_TRIAL_REPOS[repositoryInfo.name] && (documentData.startdate || documentData.clinicalphase || documentData.status) &&
            <Clinical documentData={documentData} repositoryInfo={repositoryInfo}/>}
        </>
    )
}

export default BiomarkerDocumentMetadataInfo;

