import {
    YEAR_STATISTICS_SHOW_MODAL,
    YEAR_STATISTICS_SET_CHART_DATA,
    YEAR_STATISTICS_SET_REPOSITORIES,
    YEAR_STATISTICS_SET_CHART_TYPE,
    YEAR_STATISTICS_RESET,
    YEAR_STATISTICS_SET_CHART_TITLE,
    YEAR_STATISTICS_SET_DATA_DATA_IS_FETCHING,
    YEAR_STATISTICS_SET_SELECTED_SEARCHES,
    YEAR_STATISTICS_SET_MODAL_VISIBLE,
    YEAR_STATISTICS_SET_MODAL_MESSAGE,
    YEAR_STATISTICS_SET_VALUE_TYPE,
    YEAR_STATISTICS_SET_PERIOD,
    YEAR_STATISTICS_CLEAR_CHART,
    YEAR_STATISTICS_SET_END_DATE,
    YEAR_STATISTICS_SET_START_DATE,
    YEAR_STATISTICS_SET_UPDATE,
    YEAR_STATISTICS_SET_SEARCH_PARAMETERS,
    YEAR_STATISTICS_SET_TIME_RANGE,
    YEAR_STATISTICS_SET_IS_OUT_OF_LIMIT
} from "./index";
import { fetchDocumentResultsWithStatistics } from "../../api/content/DocumentApi";
import {
    createSearchOption
} from "../../components/webapi/trendAnalysis/YearStatistics/YearStatisticsChart/chartFunctions/createSearchOptions";

export const setShowModal = showModal => ({
    type: YEAR_STATISTICS_SHOW_MODAL,
    payload: showModal
});
export const setYearStatisticsSearchParameters = searchParameters => ({
    type: YEAR_STATISTICS_SET_SEARCH_PARAMETERS,
    payload: searchParameters
});
export const setStatisticsChartData = chartData => ({ type: YEAR_STATISTICS_SET_CHART_DATA, payload: chartData });
export const setSelectedSearches = searches => ({
    type: YEAR_STATISTICS_SET_SELECTED_SEARCHES,
    payload: searches
});
export const setRepositories = repositories => ({ type: YEAR_STATISTICS_SET_REPOSITORIES, payload: repositories });
export const setChartType = chartType => ({ type: YEAR_STATISTICS_SET_CHART_TYPE, payload: chartType });
export const setValueType = valueType => ({ type: YEAR_STATISTICS_SET_VALUE_TYPE, payload: valueType });
export const setChartTitle = chartTitle => ({ type: YEAR_STATISTICS_SET_CHART_TITLE, payload: chartTitle });
export const setIsFetching = isFetching => ({ type: YEAR_STATISTICS_SET_DATA_DATA_IS_FETCHING, payload: isFetching });
export const setModalVisible = modalVisible => ({ type: YEAR_STATISTICS_SET_MODAL_VISIBLE, payload: modalVisible });
export const setModalMessage = modalMessage => ({ type: YEAR_STATISTICS_SET_MODAL_MESSAGE, payload: modalMessage });
export const setPeriod = period => ({ type: YEAR_STATISTICS_SET_PERIOD, payload: period });
export const setUpdate = () => ({ type: YEAR_STATISTICS_SET_UPDATE });

export const setStartDate = startDate => ({ type: YEAR_STATISTICS_SET_START_DATE, payload: startDate });
export const setEndDate = endDate => ({ type: YEAR_STATISTICS_SET_END_DATE, payload: endDate });
export const setTimeRange = timeRange => ({ type: YEAR_STATISTICS_SET_TIME_RANGE, payload: timeRange });
export const setIsOutOfLimit = isOutOfLimit => ({ type: YEAR_STATISTICS_SET_IS_OUT_OF_LIMIT, payload: isOutOfLimit })
// export const setErrorMessage = errorMessage => ({type: YEAR_STATISTICS_SET_ERROR_MESSAGE, payload: errorMessage});
// export const showErrorMessage = visible => ({type: YEAR_STATISTICS_SET_ERROR_MESSAGE_VISIBLE, payload: visible});

export const fetchStatisticsData = (searchOptions, repositories, startDate, endDate, selectedSearches, period) => async (dispatch, getState) => {
    const { searchName, searchParameters } = searchOptions;

    try {
        const searchOptions = createSearchOption(startDate, endDate, period);
        const chartData = getState().yearStatistics.chartData;
        const repositorieFromState = getState().yearStatistics.repositories;

        dispatch(setIsFetching(true));
        const result = await fetchDocumentResultsWithStatistics(searchParameters, searchOptions);

        if (result.status === 'SUCCESS') {
            const dataForChart = {
                name: searchName, data: Object.values(result.payload.results[0].repData)
                    .map(({ name, statistics }) => ({ name, statistics }))
            };

            const repoNames = Object.values(result.payload.results[0].repData).map(({ name }) => name);

            const filteredRepositories = repositories.filter(({ statistics }) => statistics)
                .filter(({ name }) => repoNames.includes(name))
                .map(({ label, name }) => {
                    const item = dataForChart.data.find(item => item.name === name);
                    const disabled = item.statistics.every(({ cnt }) => cnt === 0);

                    return { label: disabled ? `${label} (no hits)` : label, value: name, disabled };
                });
            // set first repo with data like initial value in the dropdown
            const reposWithData = dataForChart.data.filter((item => !item.statistics.every(({ cnt }) => cnt === 0)))
                .map(({ name }) => name);

            const firstRepoWithData = filteredRepositories.filter(({ value }) => reposWithData.includes(value))?.[0]?.value;
            console.log('chartData', chartData);
            dispatch(setStatisticsChartData([...chartData, dataForChart]));
            dispatch(setRepositories({ ...repositorieFromState, [searchName]: filteredRepositories }));

            setTimeout(() => {
                dispatch(setUpdate(true));
            });

            if (!selectedSearches.find(repo => repo.searchName === searchName) && firstRepoWithData) {
                const _selectedSearches = [...selectedSearches];
                const target = _selectedSearches.find(repo => repo.searchName === null);
                target.repoName = firstRepoWithData;
                target.searchName = searchName;
                dispatch(setSelectedSearches(_selectedSearches));
            }
        }
    } catch (e) {
        //@todo implement handling error logic
        console.error(e)
    } finally {
        dispatch(setIsFetching(false));
    }
};

export const resetYearStatistics = () => ({ type: YEAR_STATISTICS_RESET });
export const resetYearStatisticsChart = () => {
    return { type: YEAR_STATISTICS_CLEAR_CHART }
};
