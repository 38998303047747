import React from 'react';
import LabelTermMenuHeader from "./LabelTermMenuHeader/LabelTermMenuHeader";
import TermMenuButtonGroup from "./TermMenuButtonGroup/TermMenuButtonGroup";
import './TermMenu.css';

const TermMenu = ({
                      ocids,
                      normalizedTerms,
                      originalTerms,
                      hideOverlayPanel,
                      showToast, fieldName, queryTerms, setQueryTerms
                  }) => (
    <>
        <LabelTermMenuHeader occurrence='MUST'/>
        <TermMenuButtonGroup occurrence='MUST' ocids={ocids} normalizedTerms={normalizedTerms}
                             originalTerms={originalTerms}
                             hideOverlayPanel={hideOverlayPanel}
                             showToast={showToast}
                             fieldName={fieldName}
                             queryTerms={queryTerms}
                             setQueryTerms={setQueryTerms}
        />

        <LabelTermMenuHeader occurrence='MUST_NOT'/>
        <TermMenuButtonGroup occurrence='MUST_NOT' ocids={ocids} normalizedTerms={normalizedTerms}
                             originalTerms={originalTerms}
                             hideOverlayPanel={hideOverlayPanel}
                             showToast={showToast}
                             fieldName={fieldName}
                             queryTerms={queryTerms}
                             setQueryTerms={setQueryTerms}
        />
    </>

);

export default TermMenu;
