export const CONCEPT_DETAILS_NAVIGATION_DEFINITION = 'definition';
export const CONCEPT_DETAILS_NAVIGATION_STRUCTURE = 'struct';
export const CONCEPT_DETAILS_NAVIGATION_IDENTIFIERS = 'identifiers';
export const CONCEPT_DETAILS_NAVIGATION_PROPERTIES = 'pcprops';
export const CONCEPT_DETAILS_NAVIGATION_SYNONYMS = 'synos';
export const CONCEPT_DETAILS_NAVIGATION_COOCS = 'coocs';
export const CONCEPT_DETAILS_NAVIGATION_SIM_COMPOUNDS = 'simcomp';
export const CONCEPT_DETAILS_NAVIGATION_CLASSIFICATIONS = 'class';
export const CONCEPT_DETAILS_NAVIGATION_LINKOUTS = 'linkouts';

export const CONCEPT_DETAILS_NAVIGATION_TOP_ELEMENT = 'topElement';
export const CONCEPT_DETAILS_NAVIGATION_DEFINITION_ELEMENT = 'definitionElement';
export const CONCEPT_DETAILS_NAVIGATION_STRUCTURE_ELEMENT = 'structureElement';
//export const CONCEPT_DETAILS_NAVIGATION_IDENTIFIERS_ELEMENT = 'propertiesElement';
export const CONCEPT_DETAILS_NAVIGATION_PROPERTIES_ELEMENT = 'propertiesElement';
export const CONCEPT_DETAILS_NAVIGATION_SYNONYMS_ELEMENT = 'synonymsElement';
export const CONCEPT_DETAILS_NAVIGATION_COOCS_ELEMENT = 'cooccurrencesElement';
export const CONCEPT_DETAILS_NAVIGATION_SIM_COMPOUNDS_ELEMENT = 'simcomp';
export const CONCEPT_DETAILS_NAVIGATION_CLASSIFICATIONS_ELEMENT = 'classificationsElement';
export const CONCEPT_DETAILS_NAVIGATION_LINKOUTS_ELEMENT = 'linkoutsElement';

export const CONCEPT_DETAILS_NAVIGATION_ITEMS = [
    { key: CONCEPT_DETAILS_NAVIGATION_DEFINITION, targetElement: CONCEPT_DETAILS_NAVIGATION_DEFINITION_ELEMENT, label: 'Definition' },
    { key: CONCEPT_DETAILS_NAVIGATION_STRUCTURE, targetElement: CONCEPT_DETAILS_NAVIGATION_STRUCTURE_ELEMENT, label: 'Structure', compoundOnly: true },
    { key: CONCEPT_DETAILS_NAVIGATION_IDENTIFIERS, targetElement: CONCEPT_DETAILS_NAVIGATION_PROPERTIES_ELEMENT, label: 'Identifiers', compoundOnly: true },
    { key: CONCEPT_DETAILS_NAVIGATION_PROPERTIES, targetElement: CONCEPT_DETAILS_NAVIGATION_PROPERTIES_ELEMENT, label: 'Properties', compoundOnly: true },
    { key: CONCEPT_DETAILS_NAVIGATION_SYNONYMS, targetElement: CONCEPT_DETAILS_NAVIGATION_SYNONYMS_ELEMENT, label: 'Synonyms' },
    { key: CONCEPT_DETAILS_NAVIGATION_COOCS, targetElement: CONCEPT_DETAILS_NAVIGATION_COOCS_ELEMENT, label: 'Co-occurrences' },
    //{ key: CONCEPT_DETAILS_NAVIGATION_SIM_COMPOUNDS, targetElement: 'similarCompoundsElement', label: 'Similar compounds', compoundOnly: true },
    { key: CONCEPT_DETAILS_NAVIGATION_CLASSIFICATIONS, targetElement: CONCEPT_DETAILS_NAVIGATION_CLASSIFICATIONS_ELEMENT, label: 'Classification' },
    { key: CONCEPT_DETAILS_NAVIGATION_LINKOUTS, targetElement: CONCEPT_DETAILS_NAVIGATION_LINKOUTS_ELEMENT, label: 'External sources' },
];

export const filterNavigationItems = (hasDefinition, isChemCompound) => {
    return CONCEPT_DETAILS_NAVIGATION_ITEMS.filter(nav => {
        return ((hasDefinition || nav.key !== CONCEPT_DETAILS_NAVIGATION_DEFINITION) &&
            (isChemCompound || (nav.key !== CONCEPT_DETAILS_NAVIGATION_STRUCTURE &&
                nav.key !== CONCEPT_DETAILS_NAVIGATION_IDENTIFIERS &&
                nav.key !== CONCEPT_DETAILS_NAVIGATION_PROPERTIES &&
                nav.key !== CONCEPT_DETAILS_NAVIGATION_SIM_COMPOUNDS)));
    });
}