/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { APP_PROPERTIES } from "../../../../properties";
import { isArrayEmpty } from "../../util";
import DocumentMetadataInfo from "../../docparts/DocumentMetadataInfo";
import BILinkResolver from "../../general/DocumentResult/BILinkResolver";

class References extends Component {

    render() {
        const { referencedDocuments, showAllReferences, onShowAllReferencesChange, onShowDocument, repositoryInfo, label } = this.props;

        return (
            !isArrayEmpty(referencedDocuments) ?
                <div style={{ marginTop: 40 }}>
                    <h1 style={{ fontSize: 21, fontWeight: 400 }}>{label}</h1>
                    {referencedDocuments
                        .filter((refDoc, index) => (showAllReferences || index < 3))
                        .map((refDoc, index) => {
                            return <div key={`${refDoc.extId}`} style={{ marginBottom: 20 }}>
                                <div style={{ fontSize: '16px' }} >
                                    <a onClick={(e) => onShowDocument(refDoc, e)}>{refDoc.title}</a>
                                </div>
                                <DocumentMetadataInfo
                                    repositoryInfo={repositoryInfo}
                                    documentData={refDoc.docMetadata}
                                />
                                <div style={{ marginTop: 5, fontSize: 12 }}>

                                    <BILinkResolver
                                        documentData={refDoc.docMetadata}
                                        useIcon={true}
                                        iconStyle={{ position: 'relative', top: 4, marginRight: 15 }}
                                    />

                                    {APP_PROPERTIES.INCLUDE_BATCHES && !isArrayEmpty(refDoc.docMetadata.doi) ?
                                        <>
                                            <span
                                                className="__dimensions_badge_embed__ addMarginRight"
                                                data-style="small_rectangle"
                                                data-hide-zero-citations="true"
                                                style={{ display: 'inline-block', position: 'relative', top: -1 }}
                                                data-doi={refDoc.docMetadata.doi}
                                            >
                                            </span>
                                            <div
                                                className="altmetric-embed"
                                                data-badge-popover="right"
                                                data-badge-type="2"
                                                data-hide-no-mentions="true"
                                                data-link-target="_blank"
                                                style={{ marginRight: 15, display: 'inline-block', position: 'relative', top: 5 }}
                                                data-doi={refDoc.docMetadata.doi}
                                            >
                                            </div>
                                        </> : null
                                    }
                                </div>

                            </div>
                        })}
                    {!showAllReferences && referencedDocuments.length > 3 ?
                        <a onClick={(e) => onShowAllReferencesChange(true)}>Show all</a> : null
                    }
                    {showAllReferences && referencedDocuments.length > 3 ?
                        <a onClick={(e) => onShowAllReferencesChange(false)}>Less</a> : null
                    }
                </div> : null
        );
    }
}

export default References;