import { Link } from 'react-scroll';
import { isArrayEmpty } from '../../../util';


const Navigation = ({ 
  navigation,
  scrollToContainerID,
}) => {

  const renderedNavigationLinks = navigation?.map(navi => {
    return <li key={navi.key}>
      <Link
        to={navi.targetElement}
        containerId={scrollToContainerID}
        spy={true}
        smooth={true}
        offset={0}
        duration={100}
        className="secondaryLink">
        {navi.label}
      </Link>
    </li>;
  });

  return !isArrayEmpty(renderedNavigationLinks) ? <div className="navigation">
    <label htmlFor="pageNavigation" className="secondaryInfo">
      On this page
    </label>
    <nav>
      <ul id="pageNavigation" className='navigation-list paddingSmall noStyleList'>
        {renderedNavigationLinks}
      </ul>
    </nav>
  </div> : null
}

export default Navigation;