export const createChartData = (dataForChart, repositories, selectedSearches, valueType) => selectedSearches
    .filter(({searchName}) => searchName).map(({
                                                   searchName,
                                                   repoName,
                                                   color
                                               }) => {

            const chartData = dataForChart.find(({name}) => name === searchName).data
                .find(({name}) => name === repoName).statistics
                .map(item => {
                    // in case of valueType === 'relative' multiply to 100 (it is percents)
                    let value = valueType === 'relative' ? item.proportion * 100 : item.cnt;

                    return {
                        name: searchName,
                        date: item.date,
                        value,
                        label: repositories[searchName]?.find(({value}) => repoName === value)?.label,
                        dash: item.dash,
                        estimated: item.estimated
                    };
                })

            return {
                searchName,
                data: chartData,
                repoName,
                color
            }
        }
    );
