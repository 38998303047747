const selectNotificationData = (state) => state.notificationReducer?.notifications ?? [];

export const selectAllNotifications = (state) => {
  const { allNotifications } = selectNotificationData(state);
  return allNotifications;
};
export const selectActiveNotification = (state) => {
  const { activeNotification } = selectNotificationData(state);
  return activeNotification;
};
