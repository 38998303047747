/* eslint-disable jsx-a11y/anchor-is-valid */
//import React, { Component, createRef } from 'react'
import { Component, createRef } from 'react'
import { getHeaderToken } from '../../../api/index'
import axios from 'axios'
//import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
//import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import ToastContent from '../../common/CustomToast/ToastContent';
//import { InputText } from 'primereact/inputtext';
import { APP_PROPERTIES } from '../../../properties/index';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
//import { Link } from 'react-router-dom';
import AlertOverview from './AlertOverview'
import AlertList from './AlertList'
//import pageLogo from '../../../assets/images/page_icons/illustration alerts@2x.png'
//import pageLogo from '../../../assets/images/page_icons/illustration_alerts.png'
import moment from 'moment';
import { videos } from '../../../properties/videos'
import { isArrayEmpty } from '../util';
import TutorialVideos from '../general/TutorialVideos';

class Alerts extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);
        let today = new Date()
        let minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        this.state = {
            alertTypeName: 'All alerts',
            alertTypeDescription: 'All of your personal alerts',
            queryAlerts: [],
            documentAlerts: [],
            watchlistAlerts: [],
            displayDialogRemoveAlerts: false,
            displayDialogActivateAlerts: false,
            displayDialogPauseAlerts: false,
            displayDialogSendingAlerts: false,
            displayDialogIntervalAlerts: false,
            allInterval: 'DAILY',
            nextSendingDateAll: minDate,
            minDateAll: minDate,
            pageTutorials: [],
            section: '',
            sectionLabel: '',
            first: 0,
            rows: 10,
            displayCustomToast: false,
            selectedAlerts: []
        }
        this.op = createRef();
    }

    componentDidMount() {

        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('alerts')) });
        }

        let self = this
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?count=100&page=0`, { headers: getHeaderToken() })
            .then(function (response) {
                let alertsLibrary = response.data.content.filter(function (el) {
                    return el.documentCollectionDto || el.documentCollectionPermittedForUser === false
                })
                self.setState({
                    /*alertsDocNumber: response.data.numberOfElements,
                    alertsDocuments: response.data.content*/
                    alertsDocNumber: alertsLibrary.length,
                    alertsDocuments: alertsLibrary
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?count=100&page=0`, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response)
                let alertsWatchlists = response.data.content.filter(function (el) {
                    //console.log(el)
                    return el.queryCollectionDto || el.queryCollectionPermittedForUser === false
                })
                //console.log(alertsWatchlists)
                let alertsQueries = response.data.content.filter(function (el) {
                    return el.queryDto || el.queryPermittedForUser === false
                })
                self.setState({
                    //alertsWatchlistNumber: response.data.numberOfElements,
                    alertsWatchlistNumber: alertsWatchlists.length + alertsQueries.length,
                    alertsWatchlists: alertsWatchlists.length,
                    alertsQueries: alertsQueries.length,
                    watchlistAlerts: alertsWatchlists,
                    queryAlerts: alertsQueries
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })


        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications/intervals`, { headers: getHeaderToken() })
            .then(function (response) {
                let docIntervals = []
                response.data.forEach(entry => {
                    docIntervals = [...docIntervals, { label: entry, value: entry }]
                })
                self.setState({ docIntervals: docIntervals })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications/intervals`, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response)
                let queryIntervals = []
                response.data.forEach(entry => {
                    queryIntervals = [...queryIntervals, { label: entry, value: entry }]
                })
                self.setState({ queryIntervals: queryIntervals })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    selectAlerts = (alerts) => {
        //console.log(alerts)
        if (alerts.length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (alerts.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
        /*if (this.toastBC.current.state.messages.length === 0) {
            this.toastBC.current.show({
                closable: false, severity: '', sticky: true, content: (
                    <div className='col-12' style={{ display: 'block', padding: 0 }}>
                        <div className='col-12' style={{ textAlign: 'center', padding: 0, paddingBottom: 5 }}>
                            <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{alerts.length} {alerts.length !== 1 ? 'alerts' : 'alert'} selected</label>
                        </div>
                        <div className='col-12 grid p-fluid' style={{ justifyContent: 'space-between', padding: 0 }}>
                            <div className='col-2' style={{ textAlign: 'left', width: '10%', paddingLeft: 0 }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Activate selected alert` : `Activate ${alerts.length} selected alerts`}
                                    onClick={this.handleActivateAlertsDialog}>Activate</a>
                            </div>
                            <div className='col-2' style={{ textAlign: 'center', width: '10%', paddingLeft: '0.5rem' }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Pause selected alert` : `Pause ${alerts.length} selected alerts`}
                                    onClick={this.handlePauseAlertsDialog}>Pause</a>
                            </div>
                            <div className='col-2' style={{ width: '18%', paddingRight: 0, paddingLeft: 1, marginRight: -5 }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Set interval of selected alert` : `Set interval of ${alerts.length} selected alerts`}
                                    onClick={this.handleIntervalAlertsDialog}>Set interval</a>
                            </div>
                            <div className='col-2' style={{ width: '21%', paddingRight: 0, paddingLeft: 0, marginRight: -5 }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Set next sending date of selected alert` : `Set next sending date of ${alerts.length} selected alerts`}
                                    onClick={this.handleSendingAlertsDialog}>Set next mail</a>
                            </div>
                            <div className='col-2' style={{ textAlign: 'center', width: '10%', paddingLeft: 0 }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Remove selected alert` : `Remove ${alerts.length} selected alerts`}
                                    onClick={this.handleRemoveAlertsDialog}>Remove</a>
                            </div>
                            <div className='col-2' style={{ textAlign: 'end', width: '10%' }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title='Unselect'
                                    onClick={this.handleUnselect}>Unselect</a>
                            </div>
                        </div>
                    </div>
                )
            });
        } else if (this.toastBC.current.state.messages.length !== 0) {
            this.toastBC.current.state.messages = [{
                id: this.state.toastID,
                closable: false, severity: '', sticky: true, content: (
                    <div className='col-12' style={{ display: 'block', padding: 0 }}>
                        <div className='col-12' style={{ textAlign: 'center', padding: 0, paddingBottom: 5 }}>
                            <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{alerts.length} {alerts.length !== 1 ? 'alerts' : 'alert'} selected</label>
                        </div>

                        <div className='col-12 grid p-fluid' style={{ justifyContent: 'space-between', padding: 0 }}>
                            <div className='col-2' style={{ textAlign: 'left', width: '10%', paddingLeft: 0 }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Activate selected alert` : `Activate ${alerts.length} selected alerts`}
                                    onClick={this.handleActivateAlertsDialog}>Activate</a>
                            </div>
                            <div className='col-2' style={{ textAlign: 'center', width: '10%', paddingLeft: '0.5rem' }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Pause selected alert` : `Pause ${alerts.length} selected alerts`}
                                    onClick={this.handlePauseAlertsDialog}>Pause</a>
                            </div>
                            <div className='col-2' style={{ width: '18%', paddingRight: 0, paddingLeft: 1, marginRight: -5 }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Set interval of selected alert` : `Set interval of ${alerts.length} selected alerts`}
                                    onClick={this.handleIntervalAlertsDialog}>Set interval</a>
                            </div>
                            <div className='col-2' style={{ width: '21%', paddingRight: 0, paddingLeft: 0, marginRight: -5 }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Set next sending date of selected alert` : `Set next sending date of ${alerts.length} selected alerts`}
                                    onClick={this.handleSendingAlertsDialog}>Set next mail</a>
                            </div>
                            <div className='col-2' style={{ textAlign: 'center', width: '10%', paddingLeft: 0 }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title={alerts.length && alerts.length === 1 ? `Remove selected alert` : `Remove ${alerts.length} selected alerts`}
                                    onClick={this.handleRemoveAlertsDialog}>Remove</a>
                            </div>
                            <div className='col-2' style={{ textAlign: 'end', width: '10%' }}>
                                <a style={{ marginTop: 5, marginRight: 5 }}
                                    title='Unselect'
                                    onClick={this.handleUnselect}>Unselect</a>
                            </div>
                        </div>
                    </div>
                )
            }]
        }*/
        this.setState({
            selectedAlerts: alerts
        }/*, () => {
            if (this.toastBC.current.state.messages[0]) {
                this.setState({
                    toastID: this.toastBC.current.state.messages[0].id
                })
            }
        }*/
        )
    }

    handleAlertChange = (name, description) => {
        this.toastBC.current.clear()
        this.setState({
            alertTypeName: name,
            alertTypeDescription: description,
            selectedAlerts: [],
            first: 0,
            displayCustomToast: false
        })
    }

    handleUnselect = () => {
        this.toastBC.current.clear()
        this.setState({
            selectedAlerts: [],
            displayCustomToast: false
        })
    }

    alertStatusChange = async (rowData, e) => {
        let self = this;
        let active
        if (rowData.active) {
            active = false
        } else {
            active = true
        }
        if (rowData.queryCollectionDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.id}`, {
                active: active,
                queryCollectionId: rowData.queryCollectionDto.id,
                interval: null,
                nextSendingDate: null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (rowData.queryDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.id}`, {
                active: active,
                queryId: rowData.queryDto.id,
                interval: null,
                nextSendingDate: null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (rowData.documentCollectionDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${rowData.id}`, {
                active: active,
                documentCollectionId: rowData.documentCollectionDto.id,
                interval: null,
                nextSendingDate: null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    handleIntervalChange = async (rowData, value) => {
        let self = this
        let today = new Date()
        let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        let nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
        let nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())
        if (rowData.queryCollectionDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.id}`, {
                active: null,
                queryCollectionId: rowData.queryCollectionDto.id,
                interval: value,
                nextSendingDate: value === "DAILY" ? moment(tomorrow).format("YYYY-MM-DD") : value === "WEEKLY" ? moment(nextWeek).format("YYYY-MM-DD") : value === "MONTHLY" ? moment(nextMonth).format("YYYY-MM-DD") : null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (rowData.queryDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.id}`, {
                active: null,
                queryId: rowData.queryDto.id,
                interval: value,
                nextSendingDate: value === "DAILY" ? moment(tomorrow).format("YYYY-MM-DD") : value === "WEEKLY" ? moment(nextWeek).format("YYYY-MM-DD") : value === "MONTHLY" ? moment(nextMonth).format("YYYY-MM-DD") : null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (rowData.documentCollectionDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${rowData.id}`, {
                active: null,
                documentCollectionId: rowData.documentCollectionDto.id,
                interval: value,
                nextSendingDate: value === "DAILY" ? moment(tomorrow).format("YYYY-MM-DD") : value === "WEEKLY" ? moment(nextWeek).format("YYYY-MM-DD") : value === "MONTHLY" ? moment(nextMonth).format("YYYY-MM-DD") : null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    handleRepositoryChange = async (reps, rowData, repsRaw) => {
        let newRepositories = []

        if (reps.length > 0) {
            reps.forEach(rep => {
                repsRaw.forEach(repRaw => {
                    if (rep === repRaw.id) {
                        newRepositories = [...newRepositories, repRaw]
                    }
                })
            })
        }

        let self = this
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.id}`, {
            active: null,
            queryId: rowData.queryDto.id,
            interval: null,
            nextSendingDate: null,
            repositories: newRepositories,
            updateRepositories: true
        }, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response)
                self.refreshAllAlerts(rowData)
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleSendingTimeChange = async (rowData, value) => {
        //let newDate = new Date(value).toLocaleDateString('fr-CA')
        let newDate = moment(value).format("YYYY-MM-DD")
        let self = this
        if (rowData.queryCollectionDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.id}`, {
                active: null,
                queryCollectionId: rowData.queryCollectionDto.id,
                interval: null,
                nextSendingDate: newDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (rowData.queryDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.id}`, {
                active: null,
                queryId: rowData.queryDto.id,
                interval: null,
                nextSendingDate: newDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (rowData.documentCollectionDto) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${rowData.id}`, {
                active: null,
                documentCollectionId: rowData.documentCollectionDto.id,
                interval: null,
                nextSendingDate: newDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(rowData)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    handleRemoveAlertsDialog = () => {
        this.setState({
            displayDialogRemoveAlerts: true
        })
    }

    handleCloseRemoveAlertDialog = () => {
        this.setState({
            displayDialogRemoveAlerts: false
        })
    }

    handleActivateAlertsDialog = () => {
        this.setState({
            displayDialogActivateAlerts: true
        })
    }

    handleCloseActivateAlertDialog = () => {
        this.setState({
            displayDialogActivateAlerts: false
        })
    }

    handlePauseAlertsDialog = () => {
        this.setState({
            displayDialogPauseAlerts: true
        })
    }

    handleClosePauseAlertDialog = () => {
        this.setState({
            displayDialogPauseAlerts: false
        })
    }

    handleSendingAlertsDialog = () => {
        this.setState({
            displayDialogSendingAlerts: true,
            nextSendingDateAll: this.state.minDateAll
        })
    }

    handleCloseSendingAlertDialog = () => {
        this.setState({
            displayDialogSendingAlerts: false
        })
    }

    handleIntervalAlertsDialog = () => {
        this.setState({
            displayDialogIntervalAlerts: true,
            allInterval: 'DAILY'
        })
    }

    handleCloseIntervalAlertDialog = () => {
        this.setState({
            displayDialogIntervalAlerts: false
        })
    }

    removeAlerts = async (alerts) => {
        let documentCollectionAlerts = ''
        let documentAlerts = []
        let queryAlerts = ''
        let watchlistAlerts = []
        alerts.forEach(alert => {
            if (alert.documentCollectionDto) {
                documentCollectionAlerts += alert.id + ','
                documentAlerts = [...documentAlerts, alert]
            } else {
                queryAlerts += alert.id + ','
                watchlistAlerts = [...watchlistAlerts, alert]
            }
        })
        if (documentCollectionAlerts !== '') {
            documentCollectionAlerts = documentCollectionAlerts.slice(0, -1)
            let self = this;
            await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${documentCollectionAlerts}`,
                { headers: getHeaderToken(), data: { documentCollectionAlerts } })
                .then(function (response) {
                    if (response.status === 200) {
                        self.toastBC.current.clear()
                        self.refreshAllAlerts(documentAlerts[0])
                        self.setState({
                            displayDialogRemoveAlerts: false,
                            selectedAlerts: [],
                            displayCustomToast: false
                        })
                    }
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
        if (queryAlerts !== '') {
            queryAlerts = queryAlerts.slice(0, -1)
            let self = this;
            await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${queryAlerts}`, { headers: getHeaderToken(), data: { queryAlerts } })
                .then(function (response) {
                    if (response.status === 200) {
                        self.toastBC.current.clear()
                        self.refreshAllAlerts(watchlistAlerts[0])
                        self.setState({
                            displayDialogRemoveAlerts: false,
                            selectedAlerts: [],
                            displayCustomToast: false
                        })
                    }
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    modifyAlerts = async (alerts, action) => {
        let today = new Date()
        let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        let nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
        let nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())
        let documentCollectionAlerts = ''
        let documentAlerts = []
        let queryAlerts = ''
        let watchlistAlerts = []
        alerts.forEach(alert => {
            if (alert.documentCollectionDto) {
                documentCollectionAlerts += alert.id + ','
                documentAlerts = [...documentAlerts, alert]
            } else {
                queryAlerts += alert.id + ','
                watchlistAlerts = [...watchlistAlerts, alert]
            }
        })
        if (documentCollectionAlerts !== '') {
            documentCollectionAlerts = documentCollectionAlerts.slice(0, -1)
            let self = this
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?ids=${documentCollectionAlerts}`, {
                active: action === 'activate' ? true : action === 'pause' ? false : null,
                interval: action === 'interval' ? self.state.allInterval : null,
                nextSendingDate: action === 'sending' ? //new Date(self.state.nextSendingDateAll).toLocaleDateString('fr-CA') 
                    moment(self.state.nextSendingDateAll).format("YYYY-MM-DD") : action === 'interval' && this.state.allInterval === 'DAILY' ?
                        moment(tomorrow).format("YYYY-MM-DD") :
                        action === 'interval' && this.state.allInterval === 'WEEKLY' ?
                            moment(nextWeek).format("YYYY-MM-DD") :
                            action === 'interval' && this.state.allInterval === 'MONTHLY' ?
                                moment(nextMonth).format("YYYY-MM-DD") :
                                null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(documentAlerts[0])
                    self.setState({
                        displayDialogActivateAlerts: false,
                        displayDialogPauseAlerts: false,
                        displayDialogIntervalAlerts: false,
                        displayDialogSendingAlerts: false,
                        //selectedAlerts: []
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })

        }
        if (queryAlerts !== '') {
            queryAlerts = queryAlerts.slice(0, -1)
            let self = this
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${queryAlerts}`, {
                active: action === 'activate' ? true : action === 'pause' ? false : null,
                interval: action === 'interval' ? self.state.allInterval : null,
                nextSendingDate: action === 'sending' ? //new Date(self.state.nextSendingDateAll).toLocaleDateString('fr-CA') 
                    moment(self.state.nextSendingDateAll).format("YYYY-MM-DD") : action === 'interval' && this.state.allInterval === 'DAILY' ?
                        moment(tomorrow).format("YYYY-MM-DD") :
                        action === 'interval' && this.state.allInterval === 'WEEKLY' ?
                            moment(nextWeek).format("YYYY-MM-DD") :
                            action === 'interval' && this.state.allInterval === 'MONTHLY' ?
                                moment(nextMonth).format("YYYY-MM-DD") :
                                null
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    self.refreshAllAlerts(watchlistAlerts[0])
                    self.setState({
                        displayDialogActivateAlerts: false,
                        displayDialogPauseAlerts: false,
                        displayDialogIntervalAlerts: false,
                        displayDialogSendingAlerts: false,
                        //selectedAlerts: []
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    refreshAllAlerts = (rowData) => {
        //console.log(rowData)
        //console.log(this.state.selectedAlerts)
        let self = this
        if (rowData.documentCollectionDto) {
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collection-notifications?count=100&page=0`, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    let alertsLibrary = response.data.content.filter(function (el) {
                        return el.documentCollectionDto || el.documentCollectionPermittedForUser === false
                    })
                    self.setState({
                        /*alertsDocNumber: response.data.numberOfElements,
  alertsDocuments: response.data.content*/
                        alertsDocNumber: alertsLibrary.length,
                        alertsDocuments: alertsLibrary
                    }, () => {
                        if (Array.isArray(self.state.selectedAlerts)) {
                            let selectedAlerts = []
                            self.state.selectedAlerts.forEach(selAl => {
                                self.state.alertsDocuments.forEach(al => {
                                    if (selAl.id === al.id) {
                                        selectedAlerts = [...selectedAlerts, al]
                                    }
                                })
                                if (!selectedAlerts.some(alert => alert.id === selAl.id)) {
                                    selectedAlerts = [...selectedAlerts, selAl]
                                }
                            })
                            //console.log("sel:", selectedAlerts)
                            self.setState({ selectedAlerts: selectedAlerts })
                        }
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else {
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?count=100&page=0`, { headers: getHeaderToken() })
                .then(function (response) {
                    //console.log(response)
                    let alertsWatchlists = response.data.content.filter(function (el) {
                        return el.queryCollectionDto || el.queryCollectionPermittedForUser === false
                    })
                    let alertsQueries = response.data.content.filter(function (el) {
                        return el.queryDto || el.queryPermittedForUser === false
                    })
                    self.setState({
                        //alertsWatchlistNumber: response.data.numberOfElements,
                        alertsWatchlistNumber: alertsWatchlists.length + alertsQueries.length,
                        alertsWatchlists: alertsWatchlists.length,
                        alertsQueries: alertsQueries.length,
                        watchlistAlerts: alertsWatchlists,
                        queryAlerts: alertsQueries
                    }, () => {
                        if (Array.isArray(self.state.selectedAlerts)) {
                            let selectedAlerts = []
                            self.state.selectedAlerts.forEach(selAl => {
                                self.state.watchlistAlerts.forEach(al => {
                                    if (selAl.id === al.id) {
                                        selectedAlerts = [...selectedAlerts, al]
                                    }
                                })
                                self.state.queryAlerts.forEach(al => {
                                    if (selAl.id === al.id) {
                                        selectedAlerts = [...selectedAlerts, al]
                                    }
                                })
                                if (!selectedAlerts.some(alert => alert.id === selAl.id)) {
                                    selectedAlerts = [...selectedAlerts, selAl]
                                }
                            })
                            //console.log("sel:", selectedAlerts)
                            self.setState({ selectedAlerts: selectedAlerts })
                        }
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    handleIntervalChangeAll = (interval) => {
        this.setState({
            allInterval: interval
        })
    }

    handleSendingTimeChangeAll = (date) => {
        this.setState({
            nextSendingDateAll: date
        })
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }


    onCustomPage = (e, isPaginator) => {
        if (isPaginator) {
            this.setState({
                first: e.first,
                rows: e.rows
            })
        } else {
            this.setState({
                rows: e.value
            })
        }
    }

    render() {

        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults

        return (
            <div className="grid">
                <Toast ref={(el) => { this.growl = el }} />

                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    <div className='col-12' style={{ float: 'left', padding: 0 }}>
                        {/*<div className='col-12' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h1 style={{ marginTop: 0, marginLeft: -5 }} className="pageHeader">My Alerts</h1>
                                <img src={pageLogo} alt={`library logo`} style={{ height: 120, marginRight: 15, marginTop: -10 }} />
        </div>*/}

                        <div className='col-12' style={{ paddingBottom: 0 }}>
                            <div className='grid'>
                                <div className="col textAlignLeft" style={{ paddingBottom: 0 }}>
                                    <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader">My Alerts</h1>
                                </div>
                                <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                                    {!isArrayEmpty(this.state.pageTutorials) ?
                                        <span style={{ marginLeft: 30 }}>
                                            <TutorialVideos
                                                pageTutorials={this.state.pageTutorials}
                                                section='alerts'
                                                sectionLabel='My Alerts'
                                            /></span> : null}
                                </div>
                            </div>
                        </div>

                        <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10" style={{
                            paddingLeft: 7 //paddingLeft: 0, paddingBottom: 25, marginTop: -60 
                        }}>
                            <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}>If you have set an alert on a search or a collection those will
                                appear here for easy reference. You can change how often you receive alerts, you can pause when on holiday and you can cancel alerts.
                            </label>
                        </div>
                    </div>
                    <div className='grid col-12' style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                        <div className='col-5 sm:col-6 md:col-4 lg:col-2 xl:col-3' style={{ paddingLeft: 0, borderRight: '1px solid #d6d6d6', paddingTop: 0, paddingRight: 10 }}>
                            <AlertOverview
                                alertsDocNumber={this.state.alertsDocNumber}
                                alertsWatchlistNumber={this.state.alertsWatchlistNumber}
                                alertsWatchlists={this.state.alertsWatchlists}
                                alertsQueries={this.state.alertsQueries}
                                handleAlertChange={this.handleAlertChange}
                                alertTypeName={this.state.alertTypeName}>
                            </AlertOverview>
                        </div>
                        <div className='col-7 sm:col-6 md:col-8 lg:col-10 xl:col-9' style={{ paddingTop: 0, paddingLeft: 15, paddingRight: 0 }}>
                            <AlertList
                                userData={this.props.userData}
                                watchlistAlerts={this.state.watchlistAlerts}
                                queryAlerts={this.state.queryAlerts}
                                documentAlerts={this.state.alertsDocuments}
                                docIntervals={this.state.docIntervals}
                                selectAlerts={this.selectAlerts}
                                selectedAlerts={this.state.selectedAlerts}
                                alertTypeName={this.state.alertTypeName}
                                alertTypeDescription={this.state.alertTypeDescription}
                                handleUnselect={this.handleUnselect}
                                alertStatusChange={this.alertStatusChange}
                                handleIntervalChange={this.handleIntervalChange}
                                handleSendingTimeChange={this.handleSendingTimeChange}
                                handleRemoveAlertsDialog={this.handleRemoveAlertsDialog}
                                handleCloseRemoveAlertDialog={this.handleCloseRemoveAlertDialog}
                                displayDialogRemoveAlerts={this.state.displayDialogRemoveAlerts}
                                removeAlerts={this.removeAlerts}
                                modifyAlerts={this.modifyAlerts}
                                handleActivateAlertsDialog={this.handleActivateAlertsDialog}
                                handleCloseActivateAlertDialog={this.handleCloseActivateAlertDialog}
                                handlePauseAlertsDialog={this.handlePauseAlertsDialog}
                                handleClosePauseAlertDialog={this.handleClosePauseAlertDialog}
                                handleSendingAlertsDialog={this.handleSendingAlertsDialog}
                                handleCloseSendingAlertDialog={this.handleCloseSendingAlertDialog}
                                handleIntervalAlertsDialog={this.handleIntervalAlertsDialog}
                                handleCloseIntervalAlertDialog={this.handleCloseIntervalAlertDialog}
                                displayDialogActivateAlerts={this.state.displayDialogActivateAlerts}
                                displayDialogPauseAlerts={this.state.displayDialogPauseAlerts}
                                displayDialogSendingAlerts={this.state.displayDialogSendingAlerts}
                                displayDialogIntervalAlerts={this.state.displayDialogIntervalAlerts}
                                allInterval={this.state.allInterval}
                                handleIntervalChangeAll={this.handleIntervalChangeAll}
                                nextSendingDateAll={this.state.nextSendingDateAll}
                                handleSendingTimeChangeAll={this.handleSendingTimeChangeAll}
                                handleRepositoryChange={this.handleRepositoryChange}
                                first={this.state.first}
                                rows={this.state.rows}
                                onCustomPage={this.onCustomPage}
                                callbackFromRowsChange={this.callbackFromRowsChange}>
                            </AlertList>
                        </div>
                    </div>
                    <Toast ref={this.toastBC} position="bottom-center" style={{ width: '31rem', backgroundColor: 'white', opacity: '1', marginBottom: 32, boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }} />
                    <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                        onHide={this.handleUnselect} style={{ width: '40rem', bottom: 32 }}
                        draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                        <ToastContent selectedElements={this.state.selectedAlerts}
                            elementLabel='alert'
                            onHide={this.handleUnselect}
                            onActivate={this.handleActivateAlertsDialog}
                            onDeactivate={this.handlePauseAlertsDialog}
                            onIntervalChange={this.handleIntervalAlertsDialog}
                            onNextMailChange={this.handleSendingAlertsDialog}
                            onDelete={this.handleRemoveAlertsDialog}
                            activeOptions={['activateAlerts', 'pauseAlerts', 'setInterval', 'setNextMail', 'deleteElements']} />
                    </Dialog>
                </LoadingOverlay>
            </div>
        )
    }
}

export default Alerts