import { Component } from 'react';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import DevelopmentView from './development/DevelopmentView';
import TestingView from './testing/TestingView';
import { fetchAvailableDomains } from '../../../api/content/OntologyApi';
import './FrameEditor.css';

class FrameEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            domains: [],
            services: {},
            servicesOk: true
        };
    }

    componentDidMount() {
        this.fetchAvailableDomains();
    }


    /**
     * Fetches all domains which are available for this application.
     */
    fetchAvailableDomains = async () => {
        const response = await fetchAvailableDomains(this.props.history);

        let domains = [];
        if (response && response.status === 'SUCCESS') {
            domains = response.payload && response.payload.domains ? response.payload.domains : [];
        }

        this.setState({ domains: domains });
    }


    onTabChange = (index) => {
        this.setState({ activeIndex: index });
    }


    /*
    renderStatusTabHeader = () => {

        let color = 'gray';
        let symbol = <span>?</span>;

        if (this.props.serverStatus) {
            if (this.props.serverStatus.error) {
                color = '#F44336';
                symbol = <span>!</span>;
            }
            else {
                color = '#7CB342';
                symbol = <span>✔</span>;
                // &#10004;
            }
        }

        return (
            <span style={{ color: color, display: 'inline-block' }} >
                {symbol}
                <span> Services</span>
            </span>
        );
    }
    */

    render() {

        //console.log('path: ', this.props.location.pathname);
        //const activeIndex = this.props.location.pathname === '/development' ? 0 : 1;

        return (
            <>
                <TabView className="toolbarTabview"
                    activeIndex={this.state.activeIndex}
                    renderActiveOnly={false}
                    onTabChange={(e) => this.onTabChange(e.index)} >
                    <TabPanel header="Development">
                        <DevelopmentView
                            domains={this.state.domains} />
                    </TabPanel>
                    <TabPanel header="Testing">
                        <TestingView
                            domains={this.state.domains} />
                    </TabPanel>
                    {/* 
                    <TabPanel header={this.renderStatusTabHeader()}>
                        <StatusView
                            serverStatus={this.props.serverStatus} />
                    </TabPanel>
                   */}
                </TabView>
                <Toast ref={(el) => { this.growl = el }} appendTo={document.body} />
            </>
        );
    }
}

export default FrameEditor;