export const menuTemplate = [
    {
        "label": "Export",
        "menu": [
            {
                "label": "Image",
                "menu": [
                    {"type": "png", "label": "PNG"},
                    {"type": "jpg", "label": "JPG"},
                    {"type": "svg", "label": "SVG"},
                    {"type": "pdf", "label": "PDF"}
                ]
            }, {
                "label": "Print", "type": "print"
            },
            {'label': "CSV", "type": "csv"}
        ]
    }
];
