import * as ErrorHelpers from '../../helpers/error';
import { Repository } from '../../Main/repository/Repository';

export class NotificationsRepository extends Repository {
  async getNotifications() {
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('getNotifications', 'NotificationsRepository'),
    );
  }

  async createNotification(notificationText) {
    console.error(notificationText)
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('createNotification', 'NotificationsRepository'),
    );
  }

  async toggleNotificationState(notificationId, notificationState) {
    console.error(notificationId, notificationState)
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('toggleNotificationState', 'NotificationsRepository'),
    );
  }

  async updateNotification(notification) {
    console.error(notification)
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('updateNotification', 'NotificationsRepository'),
    );
  }

  async deleteNotification(notificationId) {
    console.error(notificationId)
    console.error(
      ErrorHelpers.getMethodIsNotImplementedMessage('deleteNotification', 'NotificationsRepository'),
    );
  }
}