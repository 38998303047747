/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
//import { Button } from 'primereact/button';
//import dateFormat from 'dateformat';
//import { Dialog } from 'primereact/dialog';
//import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
//import { InputText } from 'primereact/inputtext';
//import { Paginator } from 'primereact/paginator';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import './Alerts.css'


class AlertOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            rows: 10,
            globalFilterCollections: ''
        }
    }

    handleAlertChange = (e, name, description) => {
        this.props.handleAlertChange(name, description)
    }

    render() {

        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults
        //const showIcons = APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons;

        //console.log(this.props)

        /*let headerCollections = <div className='grid' style={{
            marginRight: 0,
            //borderBottom: '1px solid #d6d6d6',
            paddingTop: 15, paddingBottom: 10, borderBottom: '1px solid #d6d6d6'
        }}>
            <div className="col-12" style={{ padding: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title='Search collections'>
                    {'search'}
                </i>
                <InputText
                    style={{ width: '95%', border: 'none', float: 'left' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterCollections: e.target.value })}
                    placeholder="Search collections"
                />
            </div>
        </div>*/

        let numberAllAlerts = (this.props.alertsDocNumber !== undefined && this.props.alertsWatchlistNumber !== undefined) ? (this.props.alertsDocNumber + this.props.alertsWatchlistNumber) : 0
        let numberDocAlerts = this.props.alertsDocNumber ? this.props.alertsDocNumber : 0
        //let numberWatchlistAlerts = this.props.alertsWatchlistNumber ? this.props.alertsWatchlistNumber : 0
        let alertsWatchlists = this.props.alertsWatchlists ? this.props.alertsWatchlists : 0
        let alertsQueries = this.props.alertsQueries ? this.props.alertsQueries : 0

        return (
            <div className="col-12 grid-nogutter" style={{ paddingTop: 0, paddingRight: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                        <div className='col-12' style={{
                            padding: 0
                            //, borderRight: '1px solid #d6d6d6' 
                        }}>
                            <div className='col-12'>
                                <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0 }}>
                                    <h1 style={{ marginTop: 0, fontSize: '1.1em', float: 'left', marginBottom: 0 }} className="pageHeader">Alerts</h1>
                                </div>
                                {/*headerCollections*/}
                            </div>
                            <div className='col-12' style={{ paddingTop: 0, paddingRight: 10, paddingBottom: 0 }}>
                                <div className='col-12' style={{ display: 'inline-block', paddingBottom: 3, paddingLeft: 0, borderBottom: '1px solid #727272', borderTop: '1px solid #727272' }}>
                                    <label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>Name</label>
                                    <label style={{ marginTop: 0, float: 'right', marginBottom: 5, marginRight: -5 }}>Entries</label>
                                </div>
                            </div>
                            <div className='col-12' style={{ paddingTop: 0, paddingRight: 10 }}>
                                <div className='col-12' style={{
                                    display: 'inline-block', paddingBottom: 10, borderBottom: '1px solid #727272',
                                    backgroundColor: this.props.alertTypeName === 'All alerts' && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                                }} onClick={(e) => this.handleAlertChange(e, 'All alerts', 'All of your personal alerts')}>
                                    {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>All documents</label>*/}
                                    <a onClick={(e) => this.handleAlertChange(e, 'All alerts', 'All of your personal alerts')}
                                        style={{
                                            marginTop: 0, float: 'left', marginBottom: 0
                                            , color: 'black'
                                        }}>All alerts</a>
                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.handleAlertChange(e, 'All alerts', 'All of your personal alerts')}>{numberAllAlerts}</label>
                                    </div>
                                    <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 0, paddingTop: 5, paddingBottom: 0 }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.handleAlertChange(e, 'All alerts', 'All of your personal alerts')}>All of your personal alerts</label>
                                    </div>
                                </div>
                                <div className='col-12' style={{
                                    display: 'inline-block', paddingBottom: 10, borderBottom: '1px solid #d6d6d6',
                                    backgroundColor: this.props.alertTypeName === 'Library alerts' && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                                }} onClick={(e) => this.handleAlertChange(e, 'Library alerts', 'All of your personal alerts for document collections')}>
                                    <a onClick={(e) => this.handleAlertChange(e, 'Library alerts', 'All of your personal alerts for document collections')}
                                        style={{
                                            marginTop: 0, float: 'left', marginBottom: 0
                                            , color: 'black'
                                        }}>Library alerts</a>
                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.handleAlertChange(e, 'Library alerts', 'All of your personal alerts for document collections')}>{numberDocAlerts}</label>
                                    </div>
                                    <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 0, paddingTop: 5, paddingBottom: 0 }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.handleAlertChange(e, 'Library alerts', 'All of your personal alerts for document collections')}>All of your personal alerts for document collections</label>
                                    </div>
                                </div>
                                <div className='col-12' style={{
                                    display: 'inline-block', paddingBottom: 10, borderBottom: '1px solid #d6d6d6',
                                    backgroundColor: this.props.alertTypeName === 'Search collection alerts' && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                                }} onClick={(e) => this.handleAlertChange(e, 'Search collection alerts', 'All of your personal alerts for search collections')}>
                                    {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>All documents</label>*/}
                                    <a onClick={(e) => this.handleAlertChange(e, 'Search collection alerts', 'All of your personal alerts for search collections')}
                                        style={{
                                            marginTop: 0, float: 'left', marginBottom: 0
                                            , color: 'black'
                                        }}
                                    >Search collection alerts</a>
                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.handleAlertChange(e, 'Search collection alerts', 'All of your personal alerts for search collections')}>{alertsWatchlists}</label>
                                    </div>
                                    <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 0, paddingTop: 5, paddingBottom: 0 }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.handleAlertChange(e, 'Search collection alerts', 'All of your personal alerts for search collections')}>All of your personal alerts for search collections</label>
                                    </div>
                                </div>
                                <div className='col-12' style={{
                                    display: 'inline-block', paddingBottom: 10, borderBottom: '1px solid #d6d6d6',
                                    backgroundColor: this.props.alertTypeName === 'Search alerts' && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                                }} onClick={(e) => this.handleAlertChange(e, 'Search alerts', 'All of your personal alerts for individual searches')}>
                                    {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>All documents</label>*/}
                                    <a onClick={(e) => this.handleAlertChange(e, 'Search alerts', 'All of your personal alerts for individual searches')}
                                        style={{
                                            marginTop: 0, float: 'left', marginBottom: 0
                                            , color: 'black'
                                        }}>Search alerts</a>
                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.handleAlertChange(e, 'Search alerts', 'All of your personal alerts for individual searches')}>{alertsQueries}</label>
                                    </div>
                                    <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 0, paddingTop: 5, paddingBottom: 0 }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.handleAlertChange(e, 'Search alerts', 'All of your personal alerts for individual searches')}>All of your personal alerts for individual searches</label>
                                    </div>
                                </div>
                                {/*{collections}
                                <PagPaginator
                                    first={this.state.first}
                                    rows={this.state.rows}
                                    template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    totalRecords={this.props.docCollections ? this.props.docCollections.length + 1 : 0}
                                    onPageChange={(e) => this.pageChange(e)}
                                rowsPerPageOptions={[10, 20, 50]}></Paginator>*/}
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default AlertOverview
