import React from "react";
import dateFormat from "dateformat";
import {
  FREETEXT_EXACT,
  FREETEXT_WITH_VARIANTS,
  QUERY_TERM_TYPE_CONCEPT,
} from "../../general/docsearch/searchConstants";
import { isArrayEmpty } from "../../util";
import {
  LOGICAL_OPERATOR_AND,
  LOGICAL_OPERATOR_OR,
  SEARCH_GROUP_TYPE_FILTER,
  SEARCH_GROUP_TYPE_LOGIC,
  SEARCH_GROUP_TYPE_TERM,
} from "./constants";

export const addIDsToQueryTermsForAllSearchGroups = (searchGroups) => {
  const searchGroupsNew = searchGroups;
  searchGroupsNew?.forEach((searchGroup) => {
    if (searchGroup.queryTerms) {
      searchGroup.queryTerms = addIDsToQueryTerms(searchGroup.queryTerms);
    }
  });

  return searchGroupsNew;
};

export const addIDsToQueryTerms = (queryTerms) => {
  const queryTermsNew = queryTerms;
  queryTermsNew?.forEach((queryTerm) => {
    addIDToQueryTerm(queryTerm);
  });

  return queryTermsNew;
};

export const addIDToQueryTerm = (queryTerm) => {
  if (queryTerm && !queryTerm.id) {
    queryTerm.id = generateQueryTermID();
  }
};

export const generateQueryTermID = () => {
  return `qt_${Math.floor(Math.random() * 1000000)}`;
};

export const removeIDsFromQueryTermsForAllSearchGroups = (searchGroups) => {
  const searchGroupsNew = searchGroups;
  searchGroupsNew?.forEach((searchGroup) => {
    if (searchGroup.queryTerms) {
      searchGroup.queryTerms = removeIDsFromQueryTerms(searchGroup.queryTerms);
    }
  });

  return searchGroupsNew;
};

export const removeIDsFromQueryTerms = (queryTerms) => {
  const queryTermsNew = queryTerms;
  queryTermsNew?.forEach((queryTerm) => {
    if (queryTerm.id) {
      delete queryTerm.id;
    }
  });

  return queryTermsNew;
};

export const createSearchGroup = (type) => {
  const id = `id_${Date.now()}`;
  const time = dateFormat(new Date(), "yyyy-mm-dd hh:mm");

  const searchGroupNew = {
    id: id,
    type: type,
    creationTime: time,
  };

  switch (type) {
    case SEARCH_GROUP_TYPE_TERM: {
      return {
        ...searchGroupNew,
        queryTerms: [],
        searchCriteria: {},
        operator: LOGICAL_OPERATOR_OR.value,
      };
    }
    case SEARCH_GROUP_TYPE_LOGIC: {
      return {
        ...searchGroupNew,
        searchGroupIDRefs: [],
        operator: LOGICAL_OPERATOR_OR.value,
      };
    }
    case SEARCH_GROUP_TYPE_FILTER: {
      return {
        ...searchGroupNew,
        queryTerms: [],
        operator: LOGICAL_OPERATOR_AND.value,
      };
    }
    default: {
      return searchGroupNew;
    }
  }
};

export const getDomainSelectionType = (domainIDs) => {
  if (domainIDs) {
    if (domainIDs.includes(FREETEXT_EXACT.prefix)) {
      return FREETEXT_EXACT.prefix;
    } else if (domainIDs.includes(FREETEXT_WITH_VARIANTS.prefix)) {
      return FREETEXT_WITH_VARIANTS.prefix;
    } else {
      return "domains";
    }
  }
  return "";
};

export const isFreeText = (domainIDs) => {
  if (domainIDs) {
    return (
      domainIDs.includes(FREETEXT_EXACT.prefix) ||
      domainIDs.includes(FREETEXT_WITH_VARIANTS.prefix)
    );
  }
  // TODO: error handling?
  return true;
};

export const hasFreeText = (queryTerms = []) => {
  for (let queryTerm of queryTerms) {
    if (isFreeText(queryTerm?.domains)) {
      return true;
    }
  }
  return false;
};

export const createDomainLabelsArray = (domainIDs, domainLabelsMap) => {
  if (!domainIDs) {
    return [];
  }
  if (domainIDs.includes(FREETEXT_EXACT.prefix)) {
    return [FREETEXT_EXACT.label];
  } else if (domainIDs.includes(FREETEXT_WITH_VARIANTS.prefix)) {
    return [FREETEXT_WITH_VARIANTS.label];
  }
  return domainIDs
    .map((domID) => (domainLabelsMap && domainLabelsMap[domID]) || domID)
    .sort();
};

export const createQueryTermFromDirectMatch = (term, directMatch) => {
  let domains = Object.keys(directMatch);
  const ocids = [];
  const prefnames = [];

  Object.values(directMatch)?.forEach((matches) => {
    matches?.forEach((match) => {
      ocids.push(match.ocid);
      prefnames.push(match.preferredName);
    });
  });

  domains = !isArrayEmpty(domains) ? domains : [FREETEXT_EXACT.prefix];

  const queryTerm = {
    // id: generateQueryTermID(),
    type: QUERY_TERM_TYPE_CONCEPT,
    domains: domains,
    term: term,
    queryValues: [term],
    label: term,
  };

  if (!isArrayEmpty(ocids)) {
    queryTerm.ocids = ocids;
  }
  if (!isArrayEmpty(prefnames)) {
    queryTerm.prefnames = prefnames;
  }

  return queryTerm;
};

export const sortGroupReferences = (
  groupReferences = [],
  searchGroups = [],
  keepFirstInPlace = false
) => {
  const mainGroupRefs = [];
  let otherGroupRefs = [];
  let otherGroupRefsSorted = [];

  if (keepFirstInPlace) {
    if (!isArrayEmpty(groupReferences)) {
      mainGroupRefs.push(groupReferences[0]);
    }
    if (groupReferences.length > 1) {
      otherGroupRefs = groupReferences.slice(1);
    }
  } else {
    otherGroupRefs = groupReferences;
  }

  searchGroups.forEach((sg) => {
    if (otherGroupRefs.includes(sg.id)) {
      otherGroupRefsSorted.push(sg.id);
    }
  });

  return [...mainGroupRefs, ...otherGroupRefsSorted];
};

// TODO: remove duplicate -> move to general file
export const renderList = (
  list = [],
  cutOff = 5,
  addNumberOfEntries = false,
  separator = ", "
) => {
  return (
    <>
      {list.slice(0, cutOff).map((entry, index) => {
        return (
          <React.Fragment key={`${index}_${entry}`}>
            {index > 0 ? separator : null}
            <span>{entry}</span>
          </React.Fragment>
        );
      })}
      {list.length > cutOff ? (
        <span> ... {addNumberOfEntries && `(${list.length})`}</span>
      ) : (
        ""
      )}
    </>
  );
};

export const abbreviateText = (text = "", maxLength = 30, suffix = "...") => {
  return text.length <= maxLength
    ? text
    : `${text.substring(0, maxLength)}${suffix}`;
};

export const createGrowlMessage = (
  summary = "",
  detail = "",
  severity = "info",
  closable = true,
  sticky = false
) => {
  return {
    sticky: sticky,
    closable: closable,
    severity: severity,
    summary: summary,
    detail: detail,
  };
};
