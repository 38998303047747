import './QuickSearch.css';
import { Component, createRef } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import { Toast } from 'primereact/toast';
import FiltersBlock from "../general/filters/FiltersBlock";
import HeaderBlock from "./header/HeaderBlock";
import SearchFormBlock from "./SearchFormBlock";
import OntologyBrowserDialog from "../ontbrowser/OntologyBrowserDialog";
import RepositoryOverview from "../docresults/RepositoryOverview";
import DocumentResultWrapper from "../docresults/DocumentResultWrapper";
import {
    BULK_IMPORT_TYPES, SEARCH_FILTER_ID_PUBDATE, SEARCH_FILTER_ID_GROUP_BY_PAT_FAM, ALLOWED_SAVED_SEARCH_TYPES, FACET_TERM_UNKNOWN,
    DOMAIN_FACETS, SEARCH_FILTER_ID_DOMAIN_ANY, MAX_NUM_OF_FILTER_FACETS, MAX_NUM_OF_DOMAIN_FACETS, DOMAIN_FACET_BLOCKLIST, SORT_FIELD_PUBDATE
} from "../general/docsearch/searchConstants";
import { addAnyDomainTermToQueryTerms, addBulkIDsToQueryTerms, addConceptsToQueryTerms, addDomainFacetsToQueryTerms, addFiltersToQueryTerms, addSearchResult, checkQueryForErrors, cleanQueryTermsForStorage, convertSavedSearchToState, createEmptySearchResult, hasSortOption, replaceQueryTermWithConcepts } from "../general/docsearch/searchUtil";
import { simplifyQuery } from "../../../api/content/QueryApi";
import { convertQueryTermsToQueryString } from "./utils/queryUtil";
import {
    createDocumentSearchRequestV2, exportDocumentsMetaData, exportDocumentsMetaDataByDocIDs,
    fetchDocuments, fetchSortableFields
} from "../../../api/content/DocumentApi";
import { submitReadcubeLibrary, fetchReadcubeRequests, deleteReadcubeLibrary, syncReadcubeLibrary } from "../../../api/TestApi"
import { checkResultAndGetPayload, getHeaderToken, Sleep } from "../../../api";
import { determineActiveQuerySettings, determineActiveDomainFacets, determineActiveRepositoryV2, determineActiveSortCriteria, createSortFieldsList } from '../util/content';
import { addThousandsSeparatorToNumber, hasUserRole, isArrayEmpty, isObjectEmpty, removeHtmlTagsFromText, sortObjectMapByAttribute } from "../util";
import {
    APP_PROPERTIES, QUERY_CATEGORY_DOC_SEARCH, QUERY_TYPE_QUICK_SEARCH_2, QUERY_FORM_QUICK, readFilterDefinitions,
    readRepositoryFilterMapping, VERSION_2_1, EXPORTER_TARGET_ID_BIGQUERY, REPO_ROLE_DOC_SEARCH, getFilterDefinitionForRepository
} from "../../../properties";
import { storeQuery, updateQuery } from "../../../api/content/WatchlistApi";
import BulkImportDialog from "../bulkimport/components/BulkImportDialog";
import ConfirmationDialog from "../../common/ConfirmDialog/ConfirmationDialog";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import {
    DEFAULT_ROW_COUNT, MAX_NUM_OF_FACETS, LOGICALS, DEFAULT_SORT_CRITERIA, DEFAULT_QUERY_SETTINGS,
    INITIAL_SEARCH_FORM_SETTINGS, INITIAL_RESULTS_SETTINGS, SEARCH_FILTER_ID_BULK_IMPORT
} from "../general/docsearch/searchConstants";
import { getDataFromLocalStorage, removeDataFromLocalStorage } from "../util/localStorage";
import { convertQuickSearchToLatestFormat } from "./utils/queryConverter";
import { Button } from "primereact/button";
import UpdateSearchResultsInfo from "./infos/UpdateSearchResultsInfo";
import DomainFiltersBlock from "../general/filters/DomainFiltersBlock";
import { setQuickSearchSelectedTerms } from "../../../redux/actions/QuickSearchActions";
import ExportCenterSuccessMessage from "../export/ExportCenterSuccessMessage";
import SaveSearchDialog from "../../common/SaveSearch/SaveSearchDialog";
import AddReadcubeDialog from "../../common/ReadCube/AddReadcubeDialog";
import { addQueryAlert, deleteQueryAlert, updateQueryAlert } from "../../../api/content/SaveSearch";
import _ from 'lodash';


class QuickSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_SEARCH_FORM_SETTINGS, ...INITIAL_RESULTS_SETTINGS,
            filterDefinitions: {}, repositoryFilterMap: {}, //, openFilterCategories: {},
            isLoadingActive: false, displayAddReadcubeDialog: false, readcubeRequests: [], numberUpdates: 0
        };
        this.growl = createRef();
        this.toast = createRef();
        this.repoStatsRef = createRef();
    }

    async componentDidMount() {
        this.props.onItemSelectedClicked([]);

        // --- get filter definitions --- //
        const { filterDefinitions, activeFacets } = await readFilterDefinitions();
        const repositoryFilterMap = await readRepositoryFilterMapping();
        //console.log('filterDefinitions', filterDefinitions);
        //console.log('activeFacets', activeFacets);
        //console.log('repositoryFilterMap', repositoryFilterMap);
        this.setState({
            filterDefinitions: filterDefinitions ? filterDefinitions : {},
            repositoryFilterMap: repositoryFilterMap ? repositoryFilterMap : {},
            activeFacets: activeFacets ? activeFacets : []
        });

        // if repositories are already loaded -> run initial search
        if (!isArrayEmpty(this.props.allRepositories)) {
            this.runInitialSearch();
        }

        // check for readcube requests every 5 seconds
        if (hasUserRole('ROLE_READCUBEVIEW')) {
            this.checkReadcubeRequests()
        }
    }

    /**
     * Run initial search if repositories are loaded or have changed.
     * @param {*} prevProps 
     */
    async componentDidUpdate(prevProps) {
        if (!isArrayEmpty(this.props.allRepositories) && !_.isEqual(prevProps.allRepositories, this.props.allRepositories)) {
            this.runInitialSearch();
        }
    }

    componentWillUnmount() {
        // --- stop interval checks --- //
        clearInterval(this.intervalID);
        //localStorage.setItem('App.js', 'unmount');
    }

    checkReadcubeRequests = async (loadAgain, repoName) => {
        if (this.intervalID !== undefined) {
            clearInterval(this.intervalID)
        }

        let response;
        response = await fetchReadcubeRequests();
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                let numberUpdates = response.payload.filter(req => req.status === 'UPDATING').length
                //console.log(numberUpdates)
                let updateFinished = false
                if (numberUpdates < this.state.numberUpdates) {
                    //console.log("reload")
                    updateFinished = true
                }
                this.setState({
                    readcubeRequests: response.payload,
                    numberUpdates: numberUpdates
                }, () => {
                    if (response.payload?.length > 0 && !loadAgain &&
                        (response.payload.filter(req => req.status === 'FINISHED' || req.status === 'FAILED').length !== response.payload.length)) {
                        this.intervalID = setInterval(() => {
                            this.checkReadcubeRequests()
                        }, 10000);
                    }
                    if ((response.payload?.length > 0 && response.payload.filter(req => req.status === 'FINISHED').length > 0
                        && (response.payload.filter(req => req.status === 'FINISHED' || req.status === 'UPDATING').length !== this.props.allRepositories.filter(repo => repo.name.startsWith("rc_")).length))
                        || loadAgain) {
                        this.props.fetchAvailableUserRepositories(this.props.history)
                    }
                    if ((loadAgain && repoName) || updateFinished) {
                        this.setState({
                            repositoryInfo: repoName === this.state.repositoryInfo.name ? INITIAL_SEARCH_FORM_SETTINGS.repositoryInfo : this.state.repositoryInfo
                        }, () => {
                            this.fetchRepositoryStatistics()
                        })
                    }
                })
            }
        }
    }

    runInitialSearch = () => {

        const dataFromStorage = getDataFromLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id);
        const searchFormData = dataFromStorage?.data;
        // console.log(searchFormData.formContent)

        if (searchFormData && searchFormData.formContent) {
            // --- fill search form with data and run the search --- //
            let formContent = JSON.parse(searchFormData.formContent);
            if (searchFormData.fromShortcut) {
                this.setState({
                    queryTerms: formContent.queryTerms,
                    logicalOperator: formContent.logicalOperator,
                    increaseRecall: formContent.increaseRecall,
                    editableQuery: false,
                    repositoryInfo: searchFormData.repositoryInfo ? searchFormData.repositoryInfo : this.state.repositoryInfo,
                }, () => this.runSearch());
            }
            else {
                // --- convert old searches into new format --- //
                const formContentConverted = convertQuickSearchToLatestFormat(formContent, searchFormData, this.state.filterDefinitions);
                if (!formContentConverted) {
                    this.growl.current.show({ closable: true, severity: 'info', life: 10000, summary: 'Unknown search format', detail: `Search format cannot be recognized.` });
                    return;
                }
                // --- convert saved search to state --- //
                searchFormData.val = searchFormData.value;
                delete searchFormData.value;
                const savedSearchConverted = convertSavedSearchToState(searchFormData, formContentConverted);
                this.setState({ ...savedSearchConverted }, () => this.runSearch());
            }
            // --- remove form data from local storage --- //
            removeDataFromLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id);
        }
        else {
            this.runSearch();
        }
    }

    setIsLoaderActive = (val) => {
        this.setState({
            isLoadingActive: val
        })
    }
    // ----------------------------------------------------------------------- //
    // --- search form changes: query terms, operator, filters --------------- //
    // ----------------------------------------------------------------------- //
    onQueryTermsChange = (queryTerms) => {
        // TODO: sort???

        this.setState({
            queryChanged: true,
            queryTerms: queryTerms,
        });
    }

    onSpecifyQueryTerm = (queryTerm) => {
        if (queryTerm?.filterID === SEARCH_FILTER_ID_BULK_IMPORT) {
            this.setState({
                bulkIDType: queryTerm.bulkIDType,
                bulkIDs: queryTerm.bulkIDs,
                bulkImportTermToReplace: queryTerm,
                displayBulkImportDialog: true
            });
        }
        else {
            this.onSpecifyQueryTermWithDomainExplorer(queryTerm);
        }
    }

    onAddFiltersToQuery = (filter) => {
        const queryTermsNew = addFiltersToQueryTerms(this.state.filterInputValues, filter, this.state.queryTerms);
        this.onQueryTermsChange(queryTermsNew);
        this.setState({ filterInputValues: {} });
    }

    onAddDomainFacetsToQuery = (domain) => {
        let queryTermsNew = (this.state.filterInputValues.filterID === SEARCH_FILTER_ID_DOMAIN_ANY) ?
            addAnyDomainTermToQueryTerms(domain, this.state.queryTerms) :
            addDomainFacetsToQueryTerms(this.state.filterInputValues?.selectedFilterEntries, domain, this.state.queryTerms);
        this.onQueryTermsChange(queryTermsNew);
        this.setState({ filterInputValues: {} });
    }

    onFilterInputValuesChange = (filterInputValues) => {
        this.setState({ filterInputValues: filterInputValues });
    }

    onImmediatelyAddFiltersToQuery = (filterInputValues, filter) => {
        this.setState({ filterInputValues: filterInputValues },
            () => { this.onAddFiltersToQuery(filter) });
    }

    onAddSelectedFacet = (filterInputValues) => {
        //console.log('***onAddSelectedFacet: ', filterInputValues);
        const filterInputValuesNew = { ...filterInputValues, input: '' };
        const selectedFilters = filterInputValues?.selectedFilterEntries ? [...filterInputValues?.selectedFilterEntries] : [];
        selectedFilters.push({ term: filterInputValues.input, isManuallyAdded: true });
        filterInputValuesNew.selectedFilterEntries = selectedFilters;
        this.setState({ filterInputValues: filterInputValuesNew });
    }

    onOpenBulkImportDialog = () => {
        this.setState({
            bulkIDType: null,
            bulkIDs: [],
            bulkImportTermToReplace: null,
            displayBulkImportDialog: true
        });
    }

    onBulkImportSubmit = (idType, ids) => {
        const queryTermsNew = addBulkIDsToQueryTerms(this.state.filterDefinitions[SEARCH_FILTER_ID_BULK_IMPORT],
            BULK_IMPORT_TYPES[idType], ids, this.state.queryTerms, this.state.bulkImportTermToReplace);
        this.onQueryTermsChange(queryTermsNew);
        this.setState({ displayBulkImportDialog: false });
    }

    onLogicalOperatorChange = (operator) => {
        this.setState({
            queryChanged: true,
            logicalOperator: operator,
        });
    }

    onIncreaseRecallChange = (increaseRecall) => {
        this.setState({
            queryChanged: true,
            increaseRecall: increaseRecall,
        });
    }

    onFacetSelectionChange = (e, filterID) => {
        if (this.state.filterInputValues?.filterID === filterID) {
            let selectedFilters = this.state.filterInputValues?.selectedFilterEntries ? [...this.state.filterInputValues.selectedFilterEntries] : [];
            if (e.checked) {
                e.value.filterID = filterID;
                selectedFilters.push(e.value);
            }
            else {
                selectedFilters = selectedFilters.filter(facet =>
                    facet.term !== e.value.term || facet.occurrence !== e.value.occurrence ||
                    facet.isManuallyAdded !== e.value.isManuallyAdded
                );
            }
            this.setState({ filterInputValues: { filterID: filterID, selectedFilterEntries: selectedFilters } });
        }
        else {
            this.setState({ filterInputValues: { filterID: filterID, selectedFilterEntries: [e.value] } });
        }
    }

    onSelectDomainAnyOption = (e, filterID, domainID) => {
        const filterInputValuesNew = e.checked ? { filterID: filterID, domain: domainID } : {};
        this.setState({ filterInputValues: filterInputValuesNew });
    }

    onLoadMoreFacets = (filter) => {
        this.fetchExampleValues(filter);
    }

    // ----------------------------------------------------------------------- //
    // --- run search -------------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    runSearch = async () => {
        this.props.onItemSelectedClicked([]);

        // --- check query terms for logical errors --- //
        const errors = checkQueryForErrors(this.state.queryTerms);
        if (!isArrayEmpty(errors)) {
            this.growl.current.show({ closable: true, severity: 'warn', life: 15000, summary: 'Please check your current search', detail: `${errors.join(' ')}` });
            return;
        }
        // --- collect semantic search terms --- //
        if (!this.isDefaultQuery(this.state.queryTerms))
            this.props.onItemSelectedClicked(this.state.queryTerms?.filter((item) => item.domains?.length > 0))
        // --- get query used for searching --- //
        const querySearch = this.isDefaultQuery(this.state.queryTerms) ? { query: DEFAULT_QUERY_SETTINGS.query } :
            await this.getQueryString(this.state.queryTerms, this.state.logicalOperator, this.state.increaseRecall, false); // null -> this.state.patFamily
        // --- get query without publication date used for statistics --- //
        const queryTermsNoPubdate = this.state.queryTerms?.filter(qt => qt.filterID !== SEARCH_FILTER_ID_PUBDATE);
        const queryStatistics = isArrayEmpty(queryTermsNoPubdate) ? { query: DEFAULT_QUERY_SETTINGS.query } :
            await this.getQueryString(queryTermsNoPubdate, this.state.logicalOperator, this.state.increaseRecall, false); // null -> this.state.patFamily

        this.setState({
            queryChanged: false,
            showFilters: false,
            queryString: querySearch.query,
            filterQueries: querySearch.filterQueries, // only if there is a bulk import
            queryStatistics: queryStatistics.query
        }, () => {
            this.fetchRepositoryStatistics();
            this.toast?.current?.clear();
        });
    }

    handleRepositoryClick = async (repositoryInfo) => {
        this.setState({
            fetchingRepoData: true,
            showFilters: false,
            showResults: false,
            filterInputValues: {},
            repositoryInfo: repositoryInfo,
        });

        // --- request repository schema --- //
        const schema = await this.getRepositorySchema(repositoryInfo?.id);
        // --- get available sorting options for this repository, plus additional relevance options --- //
        const sortFields = createSortFieldsList(schema?.sortCriteria, repositoryInfo);
        // --- check if it is default query --- //
        //const isDefaultQuery = this.state.queryString === DEFAULT_QUERY_SETTINGS.query;
        const isDefaultQuery = this.isDefaultQuery(this.state.queryTerms);
        // --- get default sorting criteria --- //
        const sortCriteria = determineActiveSortCriteria(sortFields, DEFAULT_QUERY_SETTINGS.defaultSortingOptions, DEFAULT_SORT_CRITERIA);

        // @todo: move to searchUtil
        // const activeRepoFilters = this.state.repositoryFilterMap[repositoryInfo?.name];
        const activeRepoFilters = getFilterDefinitionForRepository(this.state.repositoryFilterMap, repositoryInfo?.name);
        const allowGroupByPatentFamily = activeRepoFilters ? activeRepoFilters.includes(SEARCH_FILTER_ID_GROUP_BY_PAT_FAM) : false;
        let patentFamilyGroupingValues = null, patentFamilyGrouping = null;
        if (allowGroupByPatentFamily) {
            patentFamilyGroupingValues = this.state.filterDefinitions[SEARCH_FILTER_ID_GROUP_BY_PAT_FAM]?.filterValues;
            patentFamilyGroupingValues = patentFamilyGroupingValues?.filter(val => {
                return isArrayEmpty(val.repoAllowList) || val.repoAllowList.includes(repositoryInfo?.name)
            });
            patentFamilyGrouping = isDefaultQuery ? patentFamilyGroupingValues[0] : patentFamilyGroupingValues?.find(val => val.isDefault);
        }

        this.setState({
            ...INITIAL_RESULTS_SETTINGS,
            fetchingRepoData: false,
            repositorySchema: schema,
            allowGroupByPatentFamily: allowGroupByPatentFamily,
            patentFamilyGroupingValues: patentFamilyGroupingValues,
            patentFamilyGrouping: patentFamilyGrouping,
            selectedDocuments: {},
            sortFields: sortFields,
            sortCriteria: sortCriteria,
        }, () => this.fetchDocumentList(true));
    }

    handleSortFieldChange = (sortField) => {
        // --- get matching sorting criteria for this repository schema (or use default) --- //
        const sortCriteria = this.state.sortFields?.find(sf => sf.value === sortField) || DEFAULT_SORT_CRITERIA;
        //console.log('sortCriteria: ', sortCriteria);
        this.setState({
            first: 0,
            rows: DEFAULT_ROW_COUNT,
            sortCriteria: sortCriteria
        }, () => this.fetchDocumentList(false));
    }

    handleSortModeChange = (sortMode) => {
        const sortCriteria = this.state.sortCriteria ? { ...this.state.sortCriteria, sortMode: sortMode } : {};
        this.setState({
            first: 0,
            rows: DEFAULT_ROW_COUNT,
            sortCriteria: sortCriteria
        }, () => this.fetchDocumentList(false));
    }

    handleGroupByPatentFamilyChange = async (patFamGrouping) => {
        this.setState({
            first: 0,
            rows: DEFAULT_ROW_COUNT,
            patentFamilyGrouping: patFamGrouping
        }, () => this.fetchDocumentList(false)); // TODO: true or false???
    }

    handlePageChange = async (start, count) => {
        this.setState({
            first: start,
            rows: count
        }, async () => {
            await this.fetchDocumentList(false);
            window.scrollTo(0, this.repoStatsRef.current.offsetTop - 100); // - offset
        });
    }

    // ----------------------------------------------------------------------- //
    // --- fetch data -------------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    fetchRepositoryStatistics = async () => {
        // --- determine active repository --- //
        const newActiveRepository = determineActiveRepositoryV2(this.props.allRepositories, this.state.repositoryInfo);
        // --- store results for each repo in map --- //
        const { repoStats, reposToFetch } = createEmptySearchResult(this.props.allRepositories);

        this.setState({
            fetchingNumOfHits: true,
            showRepositoryStatistics: true,
            showResults: false,
            showFilters: false,
            repoStats: repoStats,
            selectedFilters: [],
            facets: {},
            domainFacets: {},
            documents: null,
        });

        // --- send query to each repository --- //
        this.props.allRepositories?.forEach(repo => {
            // --- run request for documents for active repo --- //
            if (newActiveRepository.id === repo.id && !newActiveRepository.unavailable) {
                this.handleRepositoryClick(newActiveRepository);
            }
            // --- run request for number of hits only --- //
            const request = createDocumentSearchRequestV2(repo.id, this.state.queryString, this.state.filterQueries);
            //sleep(Math.random() * 60000).then(e => {
            fetchDocuments(1, 0, request)
                .then(response => {
                    // --- update results data for repo --- //
                    const repoStatsNew = addSearchResult(response, repo, repoStats);
                    this.setState({ repoStats: { ...repoStatsNew } });
                })
                // not happening???
                .catch(err => {
                    console.error(err);
                    // --- update results data for repo --- //
                    //const repoStatsNew = addSearchResult(null, repo, repoStats);
                    //this.setState({ repoStats: { ...repoStatsNew } });
                })
                .finally(() => {
                    delete reposToFetch[repo.name];
                    if (isObjectEmpty(reposToFetch)) {
                        this.setState({ fetchingNumOfHits: false });
                    }
                })
            //});
        });
    }

    fetchDocumentList = async (fetchFilters) => {
        if (!this.state.repositoryInfo || !this.state.repositoryInfo.id || this.state.repositoryInfo.unavailable) {
            this.growl.current.show({ closable: true, severity: 'error', summary: 'No valid repository', detail: `Please choose a valid repository.` });
            return;
        }

        const patentFamilyGrouping = this.state.allowGroupByPatentFamily && this.state.patentFamilyGrouping ? this.state.patentFamilyGrouping : null;
        const { isDefaultQuery, queryString } = determineActiveQuerySettings(DEFAULT_QUERY_SETTINGS, this.state.queryString, patentFamilyGrouping, this.state.sortCriteria);
        // --- request matching documents for current page --- //
        //console.log('fetch documents start');

        if (!this.state.isLoadingActive) {
            this.setState({
                fetchingDocuments: true,
            });
        }

        const request = createDocumentSearchRequestV2(this.state.repositoryInfo.id, queryString, this.state.filterQueries,
            null, this.state.sortCriteria?.sortField, this.state.sortCriteria?.sortMode, this.state.sortCriteria?.sortFactor); // availableFacets
        fetchDocuments(this.state.first + 1, this.state.rows, request).then(response => {
            let documentData = checkResultAndGetPayload(response, this.growl);
            this.setState({ fetchingDocuments: false });

            if (documentData) {
                this.setState({
                    showResults: true,
                    showFilters: documentData?.documentCount > 0,
                    documents: documentData,
                    internalQuery: documentData.internalQuery,
                });
                if (isDefaultQuery) {
                    documentData = this.adjustDefaultDocumentResults(documentData);
                }
            }
            //console.log('fetch documents done');
        });

        // --- get available facets (but remove concept facets for now) as well as year statistics --- //
        if (fetchFilters) {
            await Sleep(300);
            // --- check if repository has publiciation date -> needed for statistics --- //
            const hasPubdate = hasSortOption(this.state.repositorySchema?.sortCriteria, SORT_FIELD_PUBDATE);
            const availableFacets = this.state.repositorySchema?.facets;
            const availableDomainFacets = determineActiveDomainFacets(DOMAIN_FACETS, this.props.domainLabelsMap);

            if (hasPubdate || !isArrayEmpty(availableFacets) || !isArrayEmpty(availableDomainFacets)) {
                this.setState({
                    facets: null,
                    domainFacets: {},
                    yearStatistics: null
                });
                if (!isArrayEmpty(availableFacets)) {
                    this.setState({ fetchingFilters: true });
                    this.getFacets(availableFacets, isDefaultQuery, MAX_NUM_OF_FILTER_FACETS).then(facets => {
                        this.setState({ facets: facets, fetchingFilters: false });
                    });
                }
                if (!isArrayEmpty(availableDomainFacets)) {
                    this.setState({ fetchingDomainFilters: true });
                    this.getFacets(availableDomainFacets, isDefaultQuery, MAX_NUM_OF_DOMAIN_FACETS).then(facets => {
                        this.setState({ domainFacets: facets, fetchingDomainFilters: false });
                    });
                }
                if (hasPubdate) {
                    this.setState({ fetchingYearStatistics: true });
                    this.getYearStatistics(30, isDefaultQuery).then(yearStatistics => {
                        this.setState({ yearStatistics: yearStatistics, fetchingYearStatistics: false });
                    });
                }
            }
        }
    }

    isDefaultQuery = (queryTerms) => {
        return isArrayEmpty(queryTerms);
    }

    fetchExampleValues = async (filter) => {
        this.setState({ fetchingFilters: true });

        const isDefaultQuery = this.isDefaultQuery(this.state.queryTerms);
        //const isDefaultQuery = this.state.queryString === DEFAULT_QUERY_SETTINGS.query;
        const facetValues = await this.getFacets([filter.facetID], isDefaultQuery, MAX_NUM_OF_FACETS);

        this.setState({
            fetchingFilters: false,
            exampleValues: facetValues
        });
    }

    adjustDefaultDocumentResults = (documentData) => {
        if (documentData) {
            documentData.internalQuery = DEFAULT_QUERY_SETTINGS.internalQuery;
            documentData.documents?.forEach(doc => { doc.queryAbstract = removeHtmlTagsFromText(doc.queryAbstract); });
        }
        return documentData;
    }

    getDocInformation = async (docArray) => {
        //TODO: clean up, docinformation also handled in DocumentResultsWrapper
        const response = await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, docArray, { headers: getHeaderToken() })
        return response.data;
    }

    getRepositorySchema = async (repositoryID) => {
        // --- request repository schema, e.g. sortable fields, available facets, etc. --- //
        let schema;
        if (APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.fetchSortableFields) {
            const response2 = await fetchSortableFields(repositoryID);
            schema = checkResultAndGetPayload(response2, this.growl);
            // --- remove facets that are not active --- //
            if (schema?.facets && this.state.activeFacets) {
                const activeRepoFacets = schema.facets.filter(facID => this.state.activeFacets[facID]);
                schema.facets = activeRepoFacets;
            }
        }
        return schema;
    }

    getFacets = async (availableFacets, useQueryCache = false, numOfFacets = 10) => {
        // --- request matching documents for current page --- //
        const request = createDocumentSearchRequestV2(this.state.repositoryInfo.id, this.state.queryString, this.state.filterQueries, availableFacets);
        request.numberOfFacets = numOfFacets + 1;
        const response = await fetchDocuments(this.state.first + 1, 0, request, useQueryCache);
        const filterData = checkResultAndGetPayload(response, this.growl);

        const facets = {};
        if (filterData?.facetResults) {
            Object.entries(filterData.facetResults).forEach(([facetID, facetEntries]) => {
                const facetEntriesFiltered = facetEntries.filter(facet => facet.term?.toLowerCase() !== FACET_TERM_UNKNOWN);
                facets[facetID] = {
                    facets: facetEntriesFiltered.length > numOfFacets ? facetEntriesFiltered.slice(0, numOfFacets) : facetEntriesFiltered,
                    hasMoreValues: facetEntries.length > numOfFacets
                };
            });
        }

        return facets;
    }

    getYearStatistics = async (numOfYears = 30, useQueryCache = false) => {
        // --- request matching documents for current page --- //
        const request = createDocumentSearchRequestV2(this.state.repositoryInfo.id, this.state.queryString, this.state.filterQueries, null, null, null, null, 'now', numOfYears);
        const response = await fetchDocuments(this.state.first + 1, 0, request, useQueryCache);
        const statisticsData = checkResultAndGetPayload(response, this.growl);
        let yearStatistics = statisticsData?.yearStatistics;
        // --- if query contains publication date -> request year statistics without this date to show statistics without date filter --- //
        if (yearStatistics && this.state.queryString !== this.state.queryStatistics) {
            const requestNoDate = createDocumentSearchRequestV2(this.state.repositoryInfo.id, this.state.queryStatistics, this.state.filterQueries, null, null, null, null, 'now', numOfYears);
            const responseNoDate = await fetchDocuments(this.state.first + 1, 0, requestNoDate, useQueryCache);
            const statisticsDataNoDate = checkResultAndGetPayload(responseNoDate, this.growl);
            // -- if counts for a year differ -> make bar opaque --- //
            if (statisticsDataNoDate?.yearStatistics) {
                for (var i = 0; i < statisticsDataNoDate.yearStatistics.length; i++) {
                    const entryNoDate = statisticsDataNoDate.yearStatistics[i];
                    entryNoDate.opaque = yearStatistics[i].cnt !== entryNoDate.cnt;
                }
                yearStatistics = statisticsDataNoDate.yearStatistics;
            }
        }

        return yearStatistics;
    }

    getQueryString = async (queryTerms, logicalOperator, increaseRecall = false, simplify = false) => {

        const { query, filterQueries } = convertQueryTermsToQueryString(queryTerms, logicalOperator, increaseRecall); //, patFamily
        if (!query) {
            this.growl.current.show({ closable: true, severity: 'warn', summary: 'Empty search', detail: `Your search is empty.` });
            return null;
        }

        let queryString = query;
        if (simplify) {
            const response = await simplifyQuery(query, true);
            const querySimplified = checkResultAndGetPayload(response, this.growl);
            //console.log('querySimplified: ', querySimplified);
            if (!querySimplified) {
                this.growl.current.show({
                    closable: true, severity: 'error', summary: 'Error translating search',
                    detail: `Could not translate and simlify search.`
                });
                return null;
            }
            else if (querySimplified.hasErrors) {
                this.growl.current.show({
                    closable: true, severity: 'error', summary: 'Error translating search',
                    detail: `${querySimplified?.errors?.errors.map(error => `${error.type}: ${error.queryFragment}`).join(', ')}`
                });
                return null;
            }

            queryString = querySimplified.query;
        }

        return { query: queryString, filterQueries };
    }

    // ----------------------------------------------------------------------- //
    // --- domain explorer --------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onAddQueryTermWithDomainExplorer = () => {
        this.setState({
            domainBrowserVisible: true,
            queryTermToReplace: null,
            domainExplorerQuery: null,
            domainExplorerOcids: null,
        });
    }

    onSpecifyQueryTermWithDomainExplorer = (queryTerm) => {

        this.setState({
            queryTermToReplace: queryTerm,
            domainExplorerQuery: isArrayEmpty(queryTerm?.ocids) ? queryTerm?.term : '',
            domainExplorerOcids: !isArrayEmpty(queryTerm?.ocids) ? queryTerm.ocids : [],
        }, () => {
            this.setState({ domainBrowserVisible: true })
        });
    }

    onDomainExplorerClose = () => {
        this.setState({ domainBrowserVisible: false });
    }

    onDomainExplorerSubmit = (concepts) => {
        this.setState({
            domainBrowserVisible: false,
            queryChanged: true
        });

        if (this.state.queryTermToReplace) {
            const queryTerms = replaceQueryTermWithConcepts(concepts, this.state.queryTerms, this.state.queryTermToReplace);
            this.setState({
                queryTerms: queryTerms,
                queryTermToReplace: null
            });
        }
        else {
            const queryTerms = addConceptsToQueryTerms(concepts, this.state.queryTerms, true);
            this.setState({ queryTerms: queryTerms });
        }
    }

    // ----------------------------------------------------------------------- //
    // --- save query -------------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    openSaveQueryDialog = async (editQuery, savedSearchID, savedSearchName) => {

        const { query, filterQueries } = await this.getQueryString(this.state.queryTerms, this.state.logicalOperator, this.state.increaseRecall, true,); // null -> this.state.patFamily
        if (query) {
            this.setState({
                displaySaveQueryDialog: true,
                savedSearchID: savedSearchID,
                savedSearchName: savedSearchName,
                queryString: query,
                filterQueries: filterQueries,
                editQuery: editQuery,
            });
        }
    }

    onStoreQuery = async (queryString, queryName, queryFullName, queryDescription, watchlistIDs,
        addAlert, alertID, alertActive, alertInterval, alertRepositories) => {

        const cleanedQueryTerms = cleanQueryTermsForStorage(this.state.queryTerms);
        let alertId = alertID;

        const formContent = {
            queryTerms: cleanedQueryTerms,
            logicalOperator: this.state.logicalOperator,
            increaseRecall: this.state.increaseRecall,
            version: VERSION_2_1
        };

        const query = this.state.queryString;
        const filterQueries = this.state.filterQueries;

        let response;
        let queryID;
        // --- existing query is updated --- //
        if (this.state.editQuery) {
            response = await updateQuery(this.state.savedSearchID,
                query, filterQueries, queryName, queryFullName, queryDescription, QUERY_CATEGORY_DOC_SEARCH.id,
                QUERY_TYPE_QUICK_SEARCH_2.id, QUERY_FORM_QUICK.id, formContent, watchlistIDs);
            queryID = this.state.savedSearchID;
        }
        // --- new query is stored --- //
        else {
            response = await storeQuery(
                query, filterQueries, queryName, queryFullName, queryDescription, QUERY_CATEGORY_DOC_SEARCH.id,
                QUERY_TYPE_QUICK_SEARCH_2.id, QUERY_FORM_QUICK.id, formContent, watchlistIDs);
            queryID = response?.payload?.id;
            alertId = null;
        }

        checkResultAndGetPayload(response, this.growl, 'Success',
            `${this.state.editQuery ? 'Search successfully updated.' : 'Search successfully stored.'}`);

        if (response.status === 'SUCCESS') {
            if (addAlert) {
                const date = new Date().setDate(new Date().getDate() + 1);
                let nextSendingDate = moment(date).format("YYYY-MM-DD");
                if (alertId) {
                    const responseAlert = await updateQueryAlert(alertId, queryID, alertActive, alertInterval, nextSendingDate, alertRepositories);
                    checkResultAndGetPayload(responseAlert, this.growl);
                }
                else {
                    const responseAlert = await addQueryAlert(queryID, alertActive, alertInterval, nextSendingDate, alertRepositories);
                    checkResultAndGetPayload(responseAlert, this.growl);
                }
            }
            else if (alertId) {
                const responseAlert = await deleteQueryAlert(alertId);
                checkResultAndGetPayload(responseAlert, this.growl);
            }

            this.setState({
                savedSearchID: queryID,
                savedSearchName: queryFullName,
                displaySaveQueryDialog: false,
                editableQuery: true,
            });
        }
    }

    onHideStoreQuery = () => {
        this.setState({ displaySaveQueryDialog: false });
    }

    // --- open saved query --- //
    onSelectSavedSearch = (savedSearch) => {
        //console.log('savedSearch: ', savedSearch);

        // --- fill search form with query data and run the search --- //
        if (savedSearch) {
            // --- convert old queries into new format --- //
            const formContent = JSON.parse(savedSearch.formContent);
            const formContentConverted = convertQuickSearchToLatestFormat(formContent, savedSearch, this.state.filterDefinitions);
            if (!formContentConverted) {
                this.growl.current.show({ closable: true, severity: 'info', life: 10000, summary: 'Unknown search format', detail: `Search format cannot be recognized.` });
                return;
            }
            let editable = true

            /*if (savedSearch.queryCollectionList && savedSearch.queryCollectionList.length > 0) {
                savedSearch.queryCollectionList.every(list => {
                    if (list.shared && !list.writable) {
                        editable = false
                        return false
                    }
                    return true
                })
            }*/
            if (!savedSearch.isCurrentUserOwner) {
                editable = false
            }
            //console.log(`formContent [${savedSearch.type}]: `, formContent);
            // --- convert saved search to state --- //
            savedSearch.edit = editable; //!savedSearch.queryCollectionList[0] || !savedSearch.queryCollectionList[0].shared;
            const savedSearchConverted = convertSavedSearchToState(savedSearch, formContentConverted);
            this.setState({ ...savedSearchConverted }, () => this.runSearch());
        }
    }

    // ----------------------------------------------------------------------- //
    // --- export, send to advanced search, reset page ----------------------- //
    // ----------------------------------------------------------------------- //
    handleExport = async (exportData = {}, targetID, outputFormat) => {

        this.setState({ fetchingExportData: true });

        let response;
        if (exportData.docIDs) {
            response = await exportDocumentsMetaDataByDocIDs(exportData.docIDs, targetID, outputFormat);
        }
        else {
            const patentFamilyGrouping = this.state.allowGroupByPatentFamily && this.state.patentFamilyGrouping ? this.state.patentFamilyGrouping : null;
            const { queryString } = determineActiveQuerySettings(DEFAULT_QUERY_SETTINGS, this.state.queryString, patentFamilyGrouping, this.state.sortCriteria);
            response = await exportDocumentsMetaData(this.state.repositoryInfo.id, queryString, this.state.filterQueries, this.state.sortCriteria?.sortField, this.state.sortCriteria?.sortMode,
                exportData.maxNumOfResults, this.state.sortCriteria?.sortFactor, targetID, outputFormat);
        }
        // TODO: move, duplicate code
        if (response && targetID === EXPORTER_TARGET_ID_BIGQUERY) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                const datasetExportIdentifier = result.datasetExportIdentifier;
                const forwardUrl = result.forwardUrl;
                if (forwardUrl) {
                    window.open(forwardUrl, '_blank');
                }
                else {
                    this.growl.current.show({
                        sticky: true, closable: true, severity: 'success',
                        summary: 'Export successful', detail: `Dataset export identifier: ${datasetExportIdentifier}`
                    });
                }
            }
        }
        else {
            checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage />, null, true);
        }

        this.setState({ fetchingExportData: false });
    }

    onSelectExample = async (example) => {
        this.setState({
            queryTerms: example.queryTerms,
            logicalOperator: example.logicalOperator ? example.logicalOperator : this.state.logicalOperator,
            increaseRecall: example.increaseRecall ? example.increaseRecall : this.state.increaseRecall,
            repositoryInfo: example.repositoryInfo ? example.repositoryInfo : this.state.repositoryInfo,
            editableQuery: false,
        }, () => this.runSearch());
    }

    openSendToAdvancedSearchDialog = () => {
        this.setState({ displaySendToAdvancedSearchConfirmDialog: true });
    }

    onSendToAdvancedSearch = async () => {
        const { query, filterQueries } = await this.getQueryString(this.state.queryTerms, this.state.logicalOperator, this.state.increaseRecall, true); // null -> this.state.patFamily
        if (query) {
            const queryObject = { selectedSimpleQuery: query, filterQueries: filterQueries };
            this.props.history.push({
                pathname: '/docfinder/advancedsearch',
                advancedDocFinderData: JSON.stringify(queryObject)
            })
        }
    }

    openClearAllDialog = () => {
        this.setState({ displayClearAllConfirmDialog: true });
    }

    onClearAll = () => {
        this.setState({
            ...INITIAL_SEARCH_FORM_SETTINGS, ...INITIAL_RESULTS_SETTINGS
        }, () => this.runSearch());
    }


    getFetchingInfo = () => {
        const blockPage =
            this.state.fetchingDocuments ||
            this.state.fetchingYearStatistics ||
            this.state.fetchingFilters ||
            this.state.fetchingDomainFilters ||
            this.state.fetchingRepoData ||
            this.state.fetchingExportData;

        const blockSearch = this.state.fetchingNumOfHits;

        let fetchingInfoText = 'Fetching data...';
        if (this.state.fetchingDocuments) {
            fetchingInfoText = "Fetching documents...";
        }
        else if (this.state.fetchingYearStatistics) {
            fetchingInfoText = "Fetching year statistics...";
        }
        else if (this.state.fetchingFilters || this.state.fetchingDomainFilters) {
            fetchingInfoText = "Fetching filters...";
        }
        else if (this.state.fetchingRepoData) {
            fetchingInfoText = "Fetching repository info...";
        }
        else if (this.state.fetchingExportData) {
            fetchingInfoText = "Export running...";
        }
        else if (this.state.fetchingNumOfHits) {
            fetchingInfoText = "Fetching number of hits...";
        }

        return { blockPage, blockSearch, fetchingInfoText };
    }

    onAddReadcubeLibrary = () => {
        this.setState({
            displayAddReadcubeDialog: true
        })
    }

    onSubmitReadcubeLibrary = async (lib) => {
        let response;
        response = await submitReadcubeLibrary(lib);
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                this.setState({
                    displayAddReadcubeDialog: false
                }, () => {
                    this.checkReadcubeRequests()
                })
                this.growl.current.show({
                    sticky: false, closable: true, severity: 'success', life: 10000,
                    summary: 'Importing ReadCube library', detail: "Your ReadCube library is getting imported and annotated. Depending on the number of documents importing will take some time. You will see the progress on this page."
                });
            }
        }
    }

    onDeleteReadcubeLibrary = async (e, repoName, data) => {
        e.preventDefault()
        e.stopPropagation();
        let uuidToDelete = ''
        this.state.readcubeRequests.forEach(req => {
            if (req.repositoryName === repoName) {
                uuidToDelete = req.uuid
            }
        })
        let response;
        response = await deleteReadcubeLibrary(uuidToDelete);
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                if (data?.status !== 'FAILED'){
                    this.checkReadcubeRequests(true, repoName)
                } else {
                    this.checkReadcubeRequests(false)
                }
            }
        }
    }

    onSyncReadcubeLibrary = async (e, repoName) => {
        e.preventDefault()
        e.stopPropagation();
        let uuidToSync = ''
        this.state.readcubeRequests.forEach(req => {
            if (req.repositoryName === repoName) {
                uuidToSync = req.uuid
            }
        })
        let response;
        response = await syncReadcubeLibrary(uuidToSync);
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                this.checkReadcubeRequests()
            }
        }
    }
    render() {

        //const patFam = this.state.allowGroupByPatentFamily && this.state.groupByPatFamily && this.state.patFamily ? this.state.patFamily : null;
        const patentFamilyGrouping = this.state.allowGroupByPatentFamily && this.state.patentFamilyGrouping ? this.state.patentFamilyGrouping : null;
        const { queryString, isDefaultQuery } = determineActiveQuerySettings(DEFAULT_QUERY_SETTINGS, this.state.queryString, patentFamilyGrouping, this.state.sortCriteria);
        const { blockPage, blockSearch, fetchingInfoText } = this.getFetchingInfo();
        //console.log(this.props)

        return (
            <>
                <Toast ref={this.growl} />
                <Toast ref={this.toast} className="toast quickSearchToast" position="bottom-center" />

                <LoadingOverlay
                    active={blockPage}
                    text={fetchingInfoText} >
                    <div className="grid">
                        <div className="col-fixed"
                            style={{ padding: '5px 20px 10px 10px', minHeight: 'calc(100vh - 130px)', width: 300, borderRight: '1px solid #ddd' }}>
                            {this.state.showFilters ?
                                <LoadingOverlay
                                    active={blockSearch}
                                    text={fetchingInfoText} >
                                    <FiltersBlock
                                        filterDefinitions={this.state.filterDefinitions}
                                        repositoryFilterMap={this.state.repositoryFilterMap}
                                        fetchingFilters={this.state.fetchingFilters} // !this.state.showFilters || 
                                        facets={this.state.facets}
                                        onSelectionChange={this.onFacetSelectionChange}
                                        selectedRepository={this.state.repositoryInfo}
                                        repositorySchema={this.state.repositorySchema}
                                        filterInputValues={this.state.filterInputValues}
                                        onFilterInputValuesChange={this.onFilterInputValuesChange}
                                        onAddSelectedFacet={this.onAddSelectedFacet}
                                        onAddFiltersToQuery={this.onAddFiltersToQuery}
                                        onImmediatelyAddFiltersToQuery={this.onImmediatelyAddFiltersToQuery}
                                        exampleValues={this.state.exampleValues}
                                        onLoadMoreFacets={this.onLoadMoreFacets}
                                        queryTerms={this.state.queryTerms}
                                        query={this.state.queryString}
                                        filterQueries={this.state.filterQueries}
                                        statistics={this.state.yearStatistics}
                                        showRelativeValues={!isDefaultQuery}
                                        showTrendAnalysisLink={!isDefaultQuery && hasUserRole("ROLE_TRENDANALYSIS")}
                                        openFilterCategory={this.state.openFilterCategory}
                                        onToggleFilters={(openFilterID) => { this.setState({ openFilterCategory: openFilterID }); }}
                                        showOccurrences={true}
                                        includeStatistics={true}
                                    />
                                </LoadingOverlay>
                                : null}
                        </div>
                        <div className="col" style={{ padding: '10px 20px' }}>
                            <div className="grid">
                                <div className="col-12" style={{ padding: '10px 0px' }}>
                                    <LoadingOverlay
                                        active={blockSearch}
                                        text={fetchingInfoText} >
                                        <div style={{ float: 'right', marginLeft: 15 }}>
                                            <Button
                                                className='p-button-text primaryButtonAsLink'
                                                label={`${this.state.showDomainFacets ? 'Hide domain filters' : 'Show domain filters'}`}
                                                onClick={() => this.setState({ showDomainFacets: !this.state.showDomainFacets })} />
                                        </div>
                                        <HeaderBlock onSelectExample={this.onSelectExample} />
                                    </LoadingOverlay>
                                </div>
                                <div className="col-12" style={{ padding: '0 0px' }}>
                                    <LoadingOverlay
                                        active={blockSearch}
                                        text={fetchingInfoText} >
                                        <SearchFormBlock
                                            filterDefinitions={this.state.filterDefinitions}
                                            inclDomainBrowser={true}
                                            inclBulkImport={true}
                                            onAddQueryTermsWithDomainExplorer={this.onAddQueryTermWithDomainExplorer}
                                            onBulkImport={this.onOpenBulkImportDialog}
                                            onSpecifyQueryTerm={this.onSpecifyQueryTerm}
                                            queryTerms={this.state.queryTerms}
                                            onQueryTermsChange={this.onQueryTermsChange}
                                            logicalOperators={LOGICALS}
                                            logicalOperator={this.state.logicalOperator}
                                            onLogicalOperatorChange={this.onLogicalOperatorChange}
                                            increaseRecall={this.state.increaseRecall}
                                            onIncreaseRecallChange={this.onIncreaseRecallChange}
                                            onSubmitSearch={this.runSearch}
                                            onSaveQuery={this.openSaveQueryDialog}
                                            onSendToAdvancedSearch={this.openSendToAdvancedSearchDialog}
                                            onClearAll={this.openClearAllDialog}
                                            editableQuery={this.state.editableQuery}
                                            displaySavedSearchesDialog={this.state.displaySavedSearchesDialog}
                                            allowedQueryTypes={ALLOWED_SAVED_SEARCH_TYPES}
                                            savedSearchID={this.state.savedSearchID}
                                            savedSearchName={this.state.savedSearchName}
                                            onSelectSavedSearch={this.onSelectSavedSearch}
                                            queryChanged={this.state.queryChanged}
                                        />
                                    </LoadingOverlay>
                                </div>
                                {this.state.showRepositoryStatistics ?
                                    <div ref={this.repoStatsRef}
                                        className="col-12"
                                        style={{ padding: '0 10px' }}>
                                        {this.state.queryChanged ?
                                            <UpdateSearchResultsInfo onClick={this.runSearch}
                                                text='Run search to see matching hits.' />
                                            :
                                            this.state.documents?.documentCount === 0 ?
                                                <UpdateSearchResultsInfo /*onClick={this.openClearAllDialog}*/
                                                    text={`No hits found in ${this.state.repositoryInfo.label}. You can either ${this.state.repoStats?.hits > 0 ? 'select a different repository, ' : ''} edit your current input or use "Clear all" to reset the page.`} />
                                                : null}

                                        <div className={`${this.state.queryChanged ? 'disableContent' : ''}`}>
                                            <RepositoryOverview
                                                headerText={`${this.state.repoStats && this.state.repoStats.hits ? addThousandsSeparatorToNumber(this.state.repoStats.hits) : 'No'} ${this.state.repoStats?.hits === 1 ? 'hit' : 'hits'}`}
                                                repoStatisticsResults={this.state.repoStats}
                                                fetching={this.state.fetchingNumOfHits}
                                                onRepositoryClick={(repInfo) => {
                                                    this.setState({ openFilterCategory: null });
                                                    this.handleRepositoryClick(repInfo);
                                                }}
                                                selectedRepository={this.state.repositoryInfo.id}

                                                /*Add Readcube library*/
                                                allowAddReadcubeLibrary={true}
                                                onAddReadcubeLibrary={this.onAddReadcubeLibrary}
                                                onDeleteReadcubeLibrary={this.onDeleteReadcubeLibrary}
                                                onSyncReadcubeLibrary={this.onSyncReadcubeLibrary}
                                                readcubeRequests={this.state.readcubeRequests}
                                            />
                                        </div>
                                    </div>
                                    : null
                                }
                                {this.state.showResults && this.state.documents?.documentCount > 0 ?
                                    <div className={`col-12 ${this.state.queryChanged ? 'disableContent' : ''}`}
                                        style={{ padding: '0 0px' }}>
                                        <DocumentResultWrapper
                                            queryTerms={this.state.queryTerms}
                                            isSemanticSearch={true}
                                            first={this.state.first}
                                            rows={this.state.rows}
                                            showResults={this.state.showResults}
                                            setIsLoaderActive={this.setIsLoaderActive}
                                            repositoryInfo={this.state.repositoryInfo}
                                            documents={this.state.documents}
                                            selectedDocuments={this.state.selectedDocuments}
                                            onDocumentSelectionChange={(selDocs) => { this.setState({ selectedDocuments: selDocs }) }}
                                            ratingReadOnly={true}
                                            onPageChange={this.handlePageChange}
                                            onExport={this.handleExport}
                                            sortFields={this.state.sortFields}
                                            sortCriteria={this.state.sortCriteria}
                                            onSortFieldChange={this.handleSortFieldChange}
                                            onSortModeChange={this.handleSortModeChange}
                                            query={queryString}
                                            filterQueries={this.state.filterQueries}
                                            hideSimilaritySearch={true}
                                            showGroupByPatentFamily={true}
                                            isPatentFamilyMutliple={true}
                                            groupByPatentFamily={this.state.groupByPatFamily}
                                            patentFamilyGroupingValues={this.state.patentFamilyGroupingValues}
                                            patentFamilyGrouping={this.state.patentFamilyGrouping}
                                            onPatentFamilyGroupingChange={this.handleGroupByPatentFamilyChange}
                                            allowBigQueryExport={APP_PROPERTIES.CHEMISTRY.INCLUDE_BQ_COMPOUND_EXPORT}
                                            hasExportCenter={true}
                                            withSentenceAnalyzer={true}
                                            // only for full text bulk export
                                            userData={this.props.userData}
                                            starQuery={this.isDefaultQuery(this.state.queryTerms)}
                                            // @todo
                                            internalQuery={queryString}
                                        />
                                    </div>
                                    :
                                    this.state.showResults ?
                                        <div className="col-12" style={{ padding: '30px 0' }}>No hits found in {this.state.repositoryInfo?.label}</div> : null
                                }
                            </div>
                        </div>
                        {this.state.showDomainFacets ?
                            <div className="col-fixed"
                                style={{ padding: '5px 10px 10px 20px', minHeight: 'calc(100vh - 130px)', width: 300, borderLeft: '1px solid #ddd' }}>
                                <DomainFiltersBlock
                                    filterDefinitions={this.state.filterDefinitions}
                                    repositoryFilterMap={this.state.repositoryFilterMap}
                                    fetchingFilters={this.state.fetchingDomainFilters}
                                    facets={this.state.domainFacets}
                                    blocklist={DOMAIN_FACET_BLOCKLIST}
                                    onSelectionChange={this.onFacetSelectionChange}
                                    onSelectDomainAnyOption={this.onSelectDomainAnyOption}
                                    selectedRepository={this.state.repositoryInfo}
                                    repositorySchema={this.state.repositorySchema}
                                    filterInputValues={this.state.filterInputValues}
                                    onFilterInputValuesChange={this.onFilterInputValuesChange}
                                    onAddSelectedFacet={this.onAddSelectedFacet}
                                    onAddFiltersToQuery={this.onAddDomainFacetsToQuery}
                                    onImmediatelyAddFiltersToQuery={this.onImmediatelyAddFiltersToQuery}
                                    exampleValues={this.state.exampleValues}
                                    onLoadMoreFacets={this.onLoadMoreFacets}
                                    queryTerms={this.state.queryTerms}
                                    openFilterCategory={this.state.openFilterCategory}
                                    onToggleFilters={(openFilterID) => {
                                        this.setState({ openFilterCategory: openFilterID });
                                    }}
                                    showOccurrences={true}
                                />
                            </div> : null
                        }
                    </div>
                </LoadingOverlay>

                <OntologyBrowserDialog
                    ontBrowserDialogID="quickSearchDomBrowserDlg"
                    headerLabel="Domain explorer"
                    selectionMode="multiple"
                    placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                    placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                    domains={this.props.availableDomains}
                    initialSearchTerm={this.state.domainExplorerQuery}
                    initialOcids={this.state.domainExplorerOcids}
                    numOfChildNodes={10} // not used for preloaded ontology
                    allowConceptSearchByClick={true}
                    allowSearchInOntologies={true}
                    loadOntologiesOnStart={true}
                    ontBrowserVisible={this.state.domainBrowserVisible}
                    onOntBrowserClose={this.onDomainExplorerClose}
                    onOntBrowserSubmit={this.onDomainExplorerSubmit}
                    onOntBrowserShow={() => { }}
                    width="90vw"
                    height="90vh"
                />

                <BulkImportDialog
                    displayDialog={this.state.displayBulkImportDialog}
                    valueTypes={Object.values(BULK_IMPORT_TYPES)}
                    acceptedFileTypes={['.csv', '.txt']}
                    maxValues={1500}
                    initialValueType={this.state.bulkIDType}
                    initialValues={this.state.bulkIDs}
                    onSubmit={this.onBulkImportSubmit}
                    onHide={e => { this.setState({ displayBulkImportDialog: false }) }}
                    includeIDCheck={true}
                />

                <SaveSearchDialog
                    displayDialogStoreQuery={this.state.displaySaveQueryDialog}
                    editQuery={this.state.editQuery}
                    queryString={isArrayEmpty(this.state.filterQueries) ? this.state.queryString :
                        JSON.stringify({ query: this.state.queryString, filterQueries: this.state.filterQueries })}
                    savedSearchID={this.state.savedSearchID}
                    allowAlerts={true}
                    onSubmit={this.onStoreQuery}
                    onHide={this.onHideStoreQuery}
                    userData={this.props.userData}
                />

                <ConfirmationDialog
                    displayDialog={this.state.displayClearAllConfirmDialog}
                    onHide={() => this.setState({ displayClearAllConfirmDialog: false })}
                    onSubmit={() => {
                        this.onClearAll();
                        this.setState({ displayClearAllConfirmDialog: false });
                    }}
                    headerText="Confirm"
                    messageText="Clear all input?"
                    submitButtonLabel="Clear"
                />

                <ConfirmationDialog
                    displayDialog={this.state.displaySendToAdvancedSearchConfirmDialog}
                    onHide={() => this.setState({ displaySendToAdvancedSearchConfirmDialog: false })}
                    onSubmit={this.onSendToAdvancedSearch}
                    headerText="Confirm"
                    messageText="Switching to advanced search will make your search only be usable there. You will not be able to switch back to quick search without losing your search."
                    submitButtonLabel="Send"
                />

                <AddReadcubeDialog
                    displayDialog={this.state.displayAddReadcubeDialog}
                    onHide={() => this.setState({ displayAddReadcubeDialog: false })}
                    onSubmitReadcubeLibrary={this.onSubmitReadcubeLibrary}
                    readcubeRequests={this.state.readcubeRequests}
                >
                </AddReadcubeDialog>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availableDomains: state.webAPI.availableDomains,
    domainLabelsMap: state.user.data.userDetails.department.domainLabelsMap,
    /*repositories: sortObjectMapByAttribute(state.user?.data?.userDetails?.department.selectedRepositories.filter(
        repo => repo.active && repo.features?.includes(REPO_ROLE_DOC_SEARCH.id)
    ), 'orderPriority', false),*/
    allRepositories: sortObjectMapByAttribute(state.webAPI.availableRepositories?.filter(
        repo => repo.active && repo.features?.includes(REPO_ROLE_DOC_SEARCH.id)
    ), 'orderPriority', false)
})

const mapDispatchToProps = (dispatch) => {
    return {
        onItemSelectedClicked: (value) => {
            dispatch(setQuickSearchSelectedTerms({ newTerms: [value?.filter((item) => item.domains)] }))
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickSearch))