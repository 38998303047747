import React, { useEffect, useRef } from 'react';
import { Tree } from "primereact/tree";
import './BiomarkerPopup.css';
import { BiomarkerPopupNode } from "./BiomarkerPopupNode";
import { useDispatch, useSelector } from "react-redux";
import { setPopupExpandedKeys } from "../../../../redux/actions/BiomarkerActions";
import { biomarkerPopupExpandedKeys, biomarkerPopupNodes } from "../../../../redux/selectors/selectors";

export const BiomarkerPopup = ({ loading, showToast, targetToScroll }) => {
    const targetRef = useRef(null);

    const popupExpandedKeys = useSelector(biomarkerPopupExpandedKeys);
    const popupNodes = useSelector(biomarkerPopupNodes);

    const dispatch = useDispatch();

    useEffect(() => {
        //scroll to target tree node
        if (targetRef.current && targetToScroll) {
            targetRef.current.scrollIntoView()
        }
    }, [targetToScroll]);


    const onToggle = (e) => {
        dispatch(setPopupExpandedKeys(e.value));
    }

    const nodeTemplate = (node) => (
        <BiomarkerPopupNode node={node} showToast={showToast} ref={targetRef}
            targetToScroll={targetToScroll} />
    )

    return (
        <div id='biomarker_popup' style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
            <Tree value={popupNodes} onToggle={onToggle} expandedKeys={popupExpandedKeys}
                loading={loading}
                filter
                filterMode="strict"
                nodeTemplate={nodeTemplate}
            />
        </div>
    );
};
