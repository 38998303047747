//import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ConfirmationDialog from "../../../common/ConfirmDialog/ConfirmationDialog";
import { Toast } from "primereact/toast";
//import { useEffect, useRef, useState } from "react";
import { useRef, useState } from "react";
import { ExportService } from '../core/services/dataTableService';
import './ToastContent.css';
const ToastContent = ({
    selectedExports,
    onHide,
    onReload
}) => {
    const customToast = useRef();
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

    const confirm = () => {
        /*confirmDialog({
            message: <div className="confirm-dialog-content">{'Do you want to delete this record?'}</div>,
            header: 'Delete Confirmation',
            icon: 'pi pi-trash',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });*/
        setDisplayConfirmDialog(true)
    };

    const accept = () => {
        const service = new ExportService();
        const exportIds = selectedExports?.map(item => item?.id)
        if (exportIds?.length > 0) {
            service.deleteExports(exportIds).then(res => {
                customToast.current.show({ severity: 'success', summary: 'Success', detail: 'Download successfully deleted', life: 6000 });
                onReload()
                onHide()

            }).catch(err => {
                customToast.current.show({ severity: 'error', summary: 'Error', detail: err?.message, life: 6000 });
            });
        }
    }

    const reject = () => {
        setDisplayConfirmDialog(false)
    }

    return (
        <div className=" toast-container" >
            <Toast ref={customToast} />
            <div className="toast-text-part">
                {selectedExports?.length} {selectedExports?.length > 1 ? `exports selected` : `export selected`}
            </div>
            {/* <div className="toast-icons">
                <div>
                    <i className='pi pi-download'></i>
                </div>
                <div>Download </div>
            </div> */}
            <div className="toast-icons-material pointer" onClick={confirm} style={{}}>
                <div>
                    <span className='material-symbols-outlined'>{'delete_forever'}</span>
                </div>
                <div style={{ marginTop: 3 }}>Delete</div>
            </div>
            <div className="toast-icons-close pointer" onClick={onHide} >
                <span className='material-symbols-outlined'>{'close'}</span>
            </div>
            <ConfirmationDialog
                displayDialog={displayConfirmDialog}
                onHide={reject}
                onSubmit={accept}
                headerText="Confirm"
                messageText={`Would you like to delete ${selectedExports?.length > 1 ? "these exports" : "this export"}?`}
                submitButtonLabel="Delete" />
        </div>
    );
}

export default ToastContent