export const formatBytes =(bytes, decimals = 2)=> {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}



export const isbold=(rowData)=>{
    return `${!rowData?.downloaded  && rowData?.status === "Finished" ? 'bold' : ''}`
}