import React, { Component } from 'react';
import { computeMaxImageHeight, computeStructureImageSize, countAtomsInSmiles } from '../../../util';
import CompoundImage from '../CompoundImage';

class ReactionCompoundGroup extends Component {

    render() {

        const { compounds, compoundImages, onReplaceStructure, showCompoundInfo } = this.props;

        const maxImageHeight = computeMaxImageHeight(compounds);

        return compounds ? Object.keys(compounds).map((ocid, index) => {
            const smiles = compounds[ocid];
            const image = compoundImages && compoundImages[smiles] ? compoundImages[smiles] : null;
            const numOfAtoms = smiles ? countAtomsInSmiles(smiles) : -1;
            const { height, width } = computeStructureImageSize(numOfAtoms);

            return <React.Fragment key={smiles}>
                {index > 0 ?
                    <div style={{ padding: 0, paddingLeft: 5, paddingRight: 5 }}>
                        <div className='reactionCompoundOperator plus' style={{ height: maxImageHeight }}>+</div>
                    </div> : null
                }
                <div style={{ padding: 0, paddingLeft: 5, paddingRight: 5 }}>
                    <CompoundImage
                        image={image}
                        ocid={ocid}
                        smiles={smiles}
                        height={height}
                        width={width}
                        maxWidth={400}
                        onReplaceStructure={this.props.onReplaceStructure}
                        showCompoundInfo={this.props.showCompoundInfo}
                        maxImageHeight={maxImageHeight}
                        showMoreInfoIcon={false}
                    />
                </div>
            </React.Fragment>
        }) : null
    }
}
export default ReactionCompoundGroup