import React from 'react';
import styles from "./LeftAsideNodeTemplate.module.scss";
import { MultiSelect } from "primereact/multiselect";
import RepositoriesBarChart from "../../webapi/biomarker/BiomarkerLeftAside/RepositoriesBarChart/RepositoriesBarChart";
import Link from "../Link/Link";
import { Facett } from "../Facett/Facett";
import LeftAsideItem from "../LeftAsideItem/LeftAsideItem";

export const LeftAsideNodeTemplate = ({
    node,
    setIsDialogVisible,
    setPopupIsLoading,
    showToast,
    setTargetToScroll,
    repositoriesArray,
    handleSelect,
    memoizedChartData,
    chartWidth,
    handleCheckboxAny,
    facettes,
    queryTerms,
    setQueryTerms,
    tooltipText,
    selectedReposForSearch,
    handleLinkClick
}) => {

    switch (node.label) {
        case 'multiselect':
            return (
                <div className={styles.repositories}>
                    <MultiSelect value={selectedReposForSearch} options={repositoriesArray}
                        onChange={handleSelect}
                        maxSelectedLabels={3}
                        placeholder="Select a repository" appendTo='self' pt={{
                            checkboxIcon: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (e.target.className.baseVal !== "") {
                                        e.target.parentNode.click();
                                    } else {
                                        e.target.parentNode.parentNode.click();
                                    }
                                },
                            },
                            headerCheckbox: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                },
                            },
                        }} />
                    <RepositoriesBarChart barChartData={memoizedChartData || [{}]} width={chartWidth} />
                </div>
            )

        case 'link':
            return (
                <Link title={node.text} onClick={() => handleLinkClick(node)} />
            )

        case 'list':
            const conceptAmount = 10;
            // only two repos have tree
            const repositoriesWithTree = ['type', 'population'];
            const hasTree = repositoriesWithTree.includes(node.name);

            const results = facettes[node.name]?.map(result => {
                //@todo backend have to send key or id for facette. generating key on frontend is a workaround
                // key property should be checked here. it is required to expand PrimeReact Tree

                if (!result.key && !result.ocid) {
                    result.key = Math.random().toString();
                }

                // value of ocid field is used like a key value.
                if (!result.key && result.ocid) {
                    result.key = result.ocid;
                }
                // add fieldName. It is used in queryTerms
                result.fieldName = node.name;
                return result;
            });

            if (results) {
                const concepts = results.slice(0, conceptAmount).map((result) => {
                    return (
                        <Facett node={result}
                            setIsDialogVisible={setIsDialogVisible}
                            setPopupIsLoading={setPopupIsLoading}
                            hasTree={hasTree}
                            url={node.url}
                            tree={node.tree}
                            showToast={showToast}
                            key={result.key}
                            queryTerms={queryTerms}
                            setQueryTerms={setQueryTerms}
                            setTargetToScroll={setTargetToScroll}
                        />
                    )
                }
                )

                return (
                    <ul className={styles.concepts}>{concepts}</ul>
                )
            }
            return null;

        default:
            // disable checkbox in case of no facettes
            const isFacettes = !!facettes?.[node.name];
            const checkboxAnyIsChecked = !!queryTerms?.filter(({ domainTerms }) => domainTerms).filter(({ fieldName }) => {
                return fieldName === node.name;
            }).length;
            const checkboxAnyIsDisabled = !facettes[node.name];

            return (
                <LeftAsideItem node={node} checkboxAnyIsChecked={checkboxAnyIsChecked}
                    handleCheckboxAny={handleCheckboxAny}
                    checkboxAnyIsDisabled={checkboxAnyIsDisabled}
                    isFacettes={isFacettes}
                    tooltipText={tooltipText}
                />
            )
    }
};
