// add:
// <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/"                 title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/"                 title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>
// compound structure icon: http://www.iconsmind.com
//
import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { REPOSITORY_INFO_NOT_AVAILABLE } from '../../../../src/properties/index';
import { Toast } from 'primereact/toast';
import AnnotatedDocumentView from './AnnotatedDocumentView';
import { scrollToElementViaScrollToButton } from "./util/htmlHandling";
// import PDFDocumentView from "./PDFDocumentView";
import { checkResultAndGetPayload } from '../../../api';
import {
    createAnnotatedDocumentRequest,
    fetchAnnotatedDocument,
    createDocumentSearchRequestV1,
    fetchDocumentsV1,
    fetchAdditionalDocumentOrganisms,
    createPDFUrl,
    isReadcubeAvailable,
    fetchAnnotatedReadCubeDocument,
    fetchDocumentMetadata
} from '../../../api/content/DocumentApi';
import {
    setSentenceAnalysisSentences,
    setSelectedSentenceAnalysis,
    setDocumentSentencesSentenceAnalysis,
    setLoaderSentenceAnalysis,
    setIsPDFSentenceAnalysis
} from '../../../redux/actions/SentenceAnalysisActions';
import { parseTermItemsFromQuickSearchBar } from '../docview/util/annotations'
import { PATENT_FAMILIES } from '../../../properties';
import {
    removeDocViewDataFromLocalStorage,
    getDocViewDataFromLocalStorage,
    isExternalURL,
    isArrayEmpty,
    hasUserRole
} from "../util";
import _ from "lodash";
import $ from 'jquery';
import moment from "moment";


class DocumentView extends PureComponent {

    constructor(props) {
        super(props);
        let columns = [
            { field: "check", selectionMode: 'multiple', style: { width: '5%', textAlign: 'center', fontWeight: 'normal' } },
            { field: "name", header: "Name", sortable: true, style: { width: '45%' } },
            { field: "numberDocuments", header: "Documents", sortable: true, style: { fontWeight: 'normal', textAlign: 'left', width: '25%' } },
            { field: "", header: "Sharing", body: this.sharedTemplate, sortable: true, style: { textAlign: 'left', width: '25%' } }
        ]
        this.state = {
            activeTabIndex: 0,
            annotationRanges: {},
            conceptDataMap: {},
            docMetadata: {},
            images: [],
            origHtml: '',
            origPartsHtml: {
                document: '',
            },
            intQuery: null,
            status: {
                showMessage: true,
                message: '',
                error: false,
                fetchingDocument: false
            },
            cols: columns,
            pdfUrl: '',
            defaultActiveSearchTermsCount: {},
            params: {
                ocDocID: '',
                repID: ''
            },
            documentHeight: 0,
            activeSearchTerms: !this.props.renderDocumentOnly ? this.props.defaultSearchTerms : parseTermItemsFromQuickSearchBar(this.props.selectedQueryTerms, props.userData.userDetails.department.selectedDomains),
            isSemanticSearch: false,
            abstractOnly: false,
            readcubeData: {}
        };
    }

    componentDidMount() {
        // TODO: request meta data only; load annotated document in AnnotatedDocumentView separately
        this.setState({
            messageHtml: `<span style='color: green'>Loading document...</span>`
        });
        const { params } = this.props.matchs || this.props.match
        this.setState({ params: params });


        if (this.props.matchs) {
            this.setState({
                documentHeight: 70,
                isSemanticSearch: params.isSemanticSearch
            })
        }
        if (!this.props.renderDocumentOnly && !isArrayEmpty(this.props.repositories)) {
            this.retrieveDocumentData(params);
        }

        //this.setupBeforeUnloadListener();
        //this.retrieveDocumentData();
    }


    componentDidUpdate(prevProps) {
        const { params } = this.props.matchs || this.props.match

        if (this.props.renderDocumentOnly && this.props.loader && ((this.props.searchLoaded !== prevProps.searchLoaded) || ((this.props.selectedQueryTerms !== prevProps.selectedQueryTerms) && !this.props.searchLoaded))) {
            //console.log("why")
            this.setState({
                activeSearchTerms: this.props.selectedQueryTerms
            }, () => {
                this.retrieveDocumentData(params);
            })
        }
        if (this.props.matchs) {
            if ((params.ocDocID !== prevProps.matchs.params.ocDocID && !this.props.renderDocumentOnly) ||
                (this.props.renderDocumentOnly
                    && !this.props.queryChanged && !this.props.loader &&
                    (((this.props.selectedSentenceOption !== prevProps.selectedSentenceOption) && this.props.threshold !== 1) || (this.props.threshold !== prevProps.threshold) ||
                        (this.props.selectedSections !== prevProps.selectedSections)
                        || (this.props.selectedDocument !== prevProps.selectedDocument) ||
                        (this.props.activeIndex !== prevProps.activeIndex && this.props.activeIndex !== null)))) {
                //console.log("what")
                this.setState({
                    documentHeight: 60,
                    isSemanticSearch: params.isSemanticSearch
                })
                this.retrieveDocumentData(params);
            }
        }
        // extra page doc view -> retrieve document data only if repos are defined yet
        else if (!isArrayEmpty(this.props.repositories) && !_.isEqual(prevProps.repositories, this.props.repositories)) {
            this.retrieveDocumentData(params);
        }
    }

    componentWillUnmount() {
        document.location.hash = ""
    }


    setActiveSearchTerm = (terms) => {
        this.setState({
            activeSearchTerms: terms
        })
    }

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.deleteQueryFromStorage();
        });
    };

    // Things to do before unloading/closing the tab
    deleteQueryFromStorage = () => {
        const { repID, ocDocID } = this.state.params;
        //removeQueryFromLocalStorage(repID, ocDocID);
        removeDocViewDataFromLocalStorage(repID, ocDocID);
    }


    /*
        componentWillUnmount() {
            const { repID, ocDocID } = params;
            addDocViewDataToLocalStorage(repID, ocDocID, {test: 'componentWillUnmount'});
            console.log('componentWillUnmount');
        }
    */


    //   readcube fetch document doi
    getDocumentDoi = async (ocDocID, repID) => {
        const res = await fetchDocumentMetadata({
            repositoryId: repID,
            docIds: [`${ocDocID}`],
            withExtendedMetaData: true,
            withBaseMetaData: true,

        })
        if (res.status === 'SUCCESS') {
            const documents = res?.payload?.documents
            if (documents?.length > 0 && documents[0]?.extendedMetaData.readcubeCollectionId?.length > 0 && documents[0]?.extendedMetaData.readcubeItemId?.length > 0) {
                return {
                    isReadcubeDocument: true,
                    readcubeCollectionId: documents[0]?.extendedMetaData.readcubeCollectionId[0],
                    readcubeItemId: documents[0]?.extendedMetaData.readcubeItemId[0]
                }
            } else if (documents?.length > 0 && documents[0]?.extendedMetaData?.doi) {
                return documents[0]?.extendedMetaData?.doi
            } else if (documents?.length > 0 && documents[0]?.extendedMetaData?.['Pat_document-id']) {
                return {
                    isReadcubeDocument: false,
                    patentId: documents[0]?.extendedMetaData?.['Pat_document-id']
                }
            } else {
                return null
            }
        }

        return null
    }

    //   readcube check document availablity
    checkReadcubeAvailablity = async (doi, repositoryName) => {
        if (hasUserRole('ROLE_READCUBEVIEW') && (!this.props.renderDocumentOnly || (this.props.renderDocumentOnly && repositoryName === 'na')) //&& repositoryName === 'dspub' 
            && doi !== null && (doi?.length > 0 || Object.keys(doi).length > 0)) {
            const res = await isReadcubeAvailable(doi);
            if (res.status === 'SUCCESS') {
                const readcubePayload = Object.values(res.payload);
                return readcubePayload[0];
            } else {
                return { available: false }
            }
        }

        return {
            available: false
        };
    }

    retrieveDocumentData = async (params) => {
        //console.log("retrieve")
        //console.log(this.props.selectedSections)
        // TODO: request meta data only; load annotated document in AnnotatedDocumentView separately
        // --- get URL parameters --- //
        const { repID, ocDocID, doi } = params;

        // --- check if repository ID and document ID are defined --- //
        if (!repID || !ocDocID) {
            this.setState({
                status: {
                    showMessage: true,
                    message: 'Missing URL parameters. Example: docview/5/79863',
                    error: true,
                    fetchingDocument: false
                }
            });
            return;
        }
        const data = getDocViewDataFromLocalStorage(repID, ocDocID);
        const query = data && data.query ? data.query : null;
        const sequenceID = data && data.sequenceID ? data.sequenceID : null;

        this.setState({
            status: {
                showMessage: true,
                message: 'Loading document...',
                error: false,
                fetchingDocument: true
            },
            sequenceID: sequenceID
        });

        // --- get repository info, e.g.label --- //
        const repos = this.props.repositories ? this.props.repositories.filter(repo => repo.id == repID) : null;
        let repositoryInfo = REPOSITORY_INFO_NOT_AVAILABLE;
        if (repos && repos.length > 0) {
            repositoryInfo = repos[0];
        }
        const repLabel = !repositoryInfo.unavailable ? repositoryInfo.label : repID;

        // --- decode query if it exists --- //
        const queryWithoutPercent = query ? query.replace(/%/g, '') : null;
        const intQuery = queryWithoutPercent ? decodeURIComponent(queryWithoutPercent) : null;

        // --- request parameters --- //
        const withConceptData = true;
        const withExtendedData = true;
        const withSourceReferences = true;
        const withImageCatalog = true;
        const withOtherRelationships = false;
        const withParents = false;
        const withChildren = false;
        const childrenMaxCount = 0;
        const withSentenceInfo = this.props.renderDocumentOnly ? true : false

        const documentDoi = await this.getDocumentDoi(ocDocID, repID)
        const readcubeData = await this.checkReadcubeAvailablity(documentDoi, repositoryInfo.name);

        let abstractOnly = false;
        let reqDocumentParts = null;
        // TODO: remove patents
        if (repositoryInfo.name === 'dspub') {
            abstractOnly = true;
            reqDocumentParts = [{ "name": "title", "onlyFirst": true }, { "name": "dsabstract" }];
        }
        else if (repositoryInfo.name === 'dsgrants') {
            abstractOnly = true;
            reqDocumentParts = [{ "name": "title" }, { "name": "abstract" }];
        } else if (withSentenceInfo && this.props.selectedSections?.filterValue !== 'all') {
            abstractOnly = false;
            if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'tac') {
                reqDocumentParts = [{ "name": "title" }, { "name": "claims" }, { "name": "abstract" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'ta') {
                reqDocumentParts = [{ "name": "title" }, { "name": "abstract" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'title') {
                reqDocumentParts = [{ "name": "title" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'claims') {
                reqDocumentParts = [{ "name": "claims" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'experimental') {
                reqDocumentParts = [{ "name": "experimental" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'references') {
                reqDocumentParts = [{ "name": "references" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'basicdata') {
                reqDocumentParts = [{ "name": "basicdata" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && (this.props.selectedSections.filterValue === 'design' || this.props.selectedSections.filterValue === 'methods')) {
                reqDocumentParts = [{ "name": "methods" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'description') {
                reqDocumentParts = [{ "name": "description" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'eligibility') {
                reqDocumentParts = [{ "name": "eligibility" }];
            } else if (this.props.selectedSections?.filterValue !== 'all' && this.props.selectedSections.filterValue === 'endpoints') {
                reqDocumentParts = [{ "name": "endpoints" }];
            }
        }
        //console.log(reqDocumentParts)
        const request = createAnnotatedDocumentRequest(repID, ocDocID, intQuery || this.state.intQuery,
            withConceptData, withExtendedData, withSourceReferences, withParents,
            withChildren, childrenMaxCount, withOtherRelationships, withImageCatalog, reqDocumentParts,
            this.state.activeSearchTerms, withSentenceInfo);

        const response = readcubeData?.available ? await fetchAnnotatedReadCubeDocument(request, readcubeData) : await fetchAnnotatedDocument(request);

        this.setState({ readcubeData })
        if (response.status === 'SUCCESS') {

            const payload = response.payload;
            if (payload.processState?.errorCode) {
                this.setState({
                    status: {
                        showMessage: true,
                        message: payload.processState.errorMsg,
                        error: true,
                        fetchingDocument: false
                    }
                });
            }
            else {
                payload.annotatedDocument.annotationRanges = payload.annotatedDocument.annotationRanges ? payload.annotatedDocument.annotationRanges : {};
                payload.annotatedDocument.conceptDataMap = payload.annotatedDocument.conceptDataMap ? payload.annotatedDocument.conceptDataMap : {};

                let merged = {}
                let sentences = []
                let sentenceIDs = []
                let numberAnnotationsOfSentence = {}
                let sentenceAnnotationColors = []

                if (this.props.renderDocumentOnly && payload.annotatedDocument.annotationType === 'PDF') {
                    let sentencePositions = payload.annotatedDocument.sentencePositions ? payload.annotatedDocument.sentencePositions : {}
                    let activeSentenceNumbers = []
                    Object.keys(payload.annotatedDocument.annotationRanges).forEach(key => {
                        let selectedAnnotationsIndex = payload.annotatedDocument.annotationRanges[key].annotations.findIndex(ann => ann.hasOwnProperty("queryId"))
                        if (selectedAnnotationsIndex !== -1 && payload.annotatedDocument.annotationRanges[key].hasOwnProperty("sentNr") &&
                            payload.annotatedDocument.annotationRanges[key].annotations[selectedAnnotationsIndex].queryTerm) {
                            let selectedAnnotation = { id: payload.annotatedDocument.annotationRanges[key].annId, color: this.props.selectedQueryTerms?.filter((el) => el.id === payload.annotatedDocument.annotationRanges[key].annotations[selectedAnnotationsIndex].queryId)[0].color }
                            sentenceAnnotationColors = [...sentenceAnnotationColors, selectedAnnotation]
                            if (!numberAnnotationsOfSentence.hasOwnProperty(payload.annotatedDocument.annotationRanges[key].sentNr)) {
                                numberAnnotationsOfSentence[payload.annotatedDocument.annotationRanges[key].sentNr] = { counter: 1, queryTerms: [payload.annotatedDocument.annotationRanges[key].annotations[selectedAnnotationsIndex].queryId] }
                            } else {
                                if (!numberAnnotationsOfSentence[payload.annotatedDocument.annotationRanges[key].sentNr].queryTerms.includes(payload.annotatedDocument.annotationRanges[key].annotations[selectedAnnotationsIndex].queryId)) {
                                    numberAnnotationsOfSentence[payload.annotatedDocument.annotationRanges[key].sentNr].counter += 1
                                    numberAnnotationsOfSentence[payload.annotatedDocument.annotationRanges[key].sentNr].queryTerms.push(payload.annotatedDocument.annotationRanges[key].annotations[selectedAnnotationsIndex].queryId)
                                }
                            }

                            if (!activeSentenceNumbers.includes(payload.annotatedDocument.annotationRanges[key].sentNr)) {
                                activeSentenceNumbers = [...activeSentenceNumbers, payload.annotatedDocument.annotationRanges[key].sentNr]
                            }
                        }
                    })

                    const filteredSentences = Object.fromEntries(
                        Object.entries(sentencePositions).filter(([key, value]) => activeSentenceNumbers.includes(Number(key)) && ((numberAnnotationsOfSentence[Number(key)].counter >= this.props.threshold) || this.props.selectedSentenceOption === "all"))
                    )

                    Object.keys(filteredSentences).forEach(sent => {
                        sentences = [...sentences, { annId: sent, text: filteredSentences[sent].htmlText, section: filteredSentences[sent].section }]
                        sentenceIDs = [...sentenceIDs, sent]
                    })

                    sentences.push({ colors: sentenceAnnotationColors })

                    Object.keys(filteredSentences).forEach(pos => {
                        filteredSentences[pos].position = {
                            pageHeight: filteredSentences[pos].pageHeight,
                            pageNr: filteredSentences[pos].pageNr,
                            pageWidth: filteredSentences[pos].pageWidth,
                            rects: filteredSentences[pos].rects,
                            text: filteredSentences[pos].htmlText,
                            textEnd: filteredSentences[pos].textEnd,
                            textStart: filteredSentences[pos].textStart
                        }
                        filteredSentences[pos].annotations = []
                        filteredSentences[pos].annId = pos
                    })
                    merged = { ...filteredSentences, ...payload.annotatedDocument.annotationRanges }
                }

                //console.log(merged)

                let origPartsHtml = {};
                if (abstractOnly && payload.annotatedDocument && payload.annotatedDocument.namedDocumentRanges) {
                    origPartsHtml.namedDocumentRanges = payload.annotatedDocument.namedDocumentRanges;
                    origPartsHtml.createFromDocParts = true;
                } else if (this.props.renderDocumentOnly && this.props.selectedSections?.filterValue !== 'all' && payload.annotatedDocument && payload.annotatedDocument.namedDocumentRanges) {
                    origPartsHtml.namedDocumentRanges = payload.annotatedDocument.namedDocumentRanges;
                    origPartsHtml.createFromDocParts = true;
                }
                else {
                    origPartsHtml.document = payload.annotatedDocument.document;
                    origPartsHtml.createFromDocParts = false;
                }
                //console.log(origPartsHtml)
                const hitStartPos = 0;
                const hitCount = 100;
                const withDocMetaData = true;
                const withImageCatalog2 = false;
                const withQryAbstract = false;
                const withTeaserImage = false;

                // --- request patent family members --- //
                let patFamilyMembers = [];
                if (!this.props.renderDocumentOnly) {
                    for (const patFam of Object.values(PATENT_FAMILIES)) {
                        if (payload.annotatedDocument.docMetadata[patFam.metadataName]) {
                            const patFamID = !isArrayEmpty(payload.annotatedDocument.docMetadata[patFam.metadataName]) ? payload.annotatedDocument.docMetadata[patFam.metadataName][0] : '-1';
                            if (patFamID !== '-1') {
                                const query = patFam.membersQryPrefix + ':' + payload.annotatedDocument.docMetadata[patFam.metadataName][0];
                                const request = createDocumentSearchRequestV1(repID, query, hitStartPos, hitCount,
                                    withDocMetaData, withImageCatalog2, withQryAbstract, withTeaserImage);
                                const resp = await fetchDocumentsV1(request, true);
                                const familyRes = checkResultAndGetPayload(resp, this.growl);
                                if (familyRes) {
                                    patFamilyMembers.push({ label: patFam.label, members: familyRes.hitDocuments, totalMemberCount: familyRes.hitCount });
                                }
                            }
                        }
                    }
                }

                // --- request referenced documents --- //
                if (payload.annotatedDocument.docMetadata && payload.annotatedDocument.docMetadata.references) {
                    let referencedDocuments = [];
                    let query = '';
                    for (const refID of payload.annotatedDocument.docMetadata.references) {
                        query += ` extid:"${refID}"`;
                        //query += ` extid:"${payload.annotatedDocument.extId}"`;
                    }
                    if (query) {
                        const request = createDocumentSearchRequestV1(repID, query.trim(), hitStartPos, hitCount,
                            withDocMetaData, withImageCatalog2, withQryAbstract, withTeaserImage);
                        const resp = await fetchDocumentsV1(request, true);
                        const referenceRes = checkResultAndGetPayload(resp, this.growl);
                        if (referenceRes && !isArrayEmpty(referenceRes.hitDocuments)) {
                            referencedDocuments.push(...referenceRes.hitDocuments);
                            payload.annotatedDocument.docMetadata.referencedDocuments = referencedDocuments;
                        }
                    }
                }
                /**/
                /*
                if (payload.annotatedDocument.docMetadata && payload.annotatedDocument.docMetadata.references) {
                    let referencedDocuments = [];
                    for (const refID of payload.annotatedDocument.docMetadata.references) {
                        //const query = `extid:"${refID}"`;
                        const query = `extid:"${payload.annotatedDocument.extId}"`;
                        const request = createDocumentSearchRequestV1(repID, query, hitStartPos, hitCount,
                            withDocMetaData, withImageCatalog2, withQryAbstract, withTeaserImage);
                        const resp = await fetchDocumentsV1(request, true);
                        const referenceRes = checkResultAndGetPayload(resp, this.growl);
                        if (referenceRes) {
                            referencedDocuments.push(...referenceRes.hitDocuments);
                        }
                    }
                    if (!isArrayEmpty(referencedDocuments)) {
                        payload.annotatedDocument.docMetadata.referencedDocuments = referencedDocuments;
                    }
                    //console.log('referencedDocuments: ', referencedDocuments);
                }
                */
                //console.log('citations: ', payload.annotatedDocument.docMetadata.citations);

                // --- request cited documents --- //
                if (payload.annotatedDocument.docMetadata && payload.annotatedDocument.docMetadata.citations) {
                    let citedDocuments = [];
                    let query = '';
                    for (const citID of payload.annotatedDocument.docMetadata.citations) {
                        query += ` extid:"${citID}"`;
                        //query += ` extid:"${payload.annotatedDocument.extId}"`;
                    }
                    if (query) {
                        const request = createDocumentSearchRequestV1(repID, query.trim(), hitStartPos, hitCount,
                            withDocMetaData, withImageCatalog2, withQryAbstract, withTeaserImage);
                        const resp = await fetchDocumentsV1(request, true);
                        const citationRes = checkResultAndGetPayload(resp, this.growl);
                        if (citationRes && !isArrayEmpty(citationRes.hitDocuments)) {
                            citedDocuments.push(...citationRes.hitDocuments);
                            payload.annotatedDocument.docMetadata.citedDocuments = citedDocuments;
                        }
                    }
                }

                // @todo
                let docAdditionalOrganisms = [];
                if ((hasUserRole('ROLE_SEQUENCESEARCH'))) { //  || hasUserRole('ROLE_SEQUENCEEXPORT')
                    const respOrganisms = await fetchAdditionalDocumentOrganisms(ocDocID);
                    docAdditionalOrganisms = checkResultAndGetPayload(respOrganisms, this.growl);
                }

                // --- copy extid to meta data object --- //
                if (payload.annotatedDocument.docMetadata && payload.annotatedDocument.extId) {
                    payload.annotatedDocument.docMetadata.extId = [payload.annotatedDocument.extId];
                }

                // --- put all links together in an array --- //
                const linksAll = [];
                const url = payload.annotatedDocument.url;
                const uri = payload.annotatedDocument.uri;
                const urlsMD = payload.annotatedDocument.docMetadata ? payload.annotatedDocument.docMetadata.url : null;
                const urlsExtMD = payload.annotatedDocument.docMetadata ? payload.annotatedDocument.docMetadata.urlExt : null;

                if (urlsMD) {
                    for (const urlMD of urlsMD) {
                        if (isExternalURL(urlMD)) {
                            linksAll.push(urlMD);
                        }
                    }
                }
                if (urlsExtMD) {
                    for (const extUrl of urlsExtMD) {
                        if (isExternalURL(extUrl)) {
                            linksAll.push(extUrl);
                        }
                    }
                }
                if (uri && !linksAll.includes(uri) && isExternalURL(uri)) {
                    linksAll.push(uri);
                }
                if (url && !linksAll.includes(url) && isExternalURL(url)) {
                    linksAll.push(url);
                }
                payload.annotatedDocument.docMetadata.linksAll = linksAll;

                // --- add OC Doc ID to list of metadata --- //
                payload.annotatedDocument.docMetadata.ocDocID = [payload.annotatedDocument.ocDocId];

                // --- add insertion and revision date to metadata --- //
                if (payload.annotatedDocument.insertDate) {
                    payload.annotatedDocument.docMetadata.insertDate = [moment(payload.annotatedDocument.insertDate).format('YYYY-MM-DD')];
                }
                if (payload.annotatedDocument.lastRevisionDate) {
                    payload.annotatedDocument.docMetadata.lastRevisionDate = [moment(payload.annotatedDocument.lastRevisionDate).format('YYYY-MM-DD')];
                }

                // --- update state with actual data --- //

                this.props.onSetDocumentTitle({
                    terms: {
                        repositoryInfo: repositoryInfo,
                        title: payload.annotatedDocument.title,
                        metadata: payload.annotatedDocument.docMetadata
                    }
                })

                //console.log(payload.annotatedDocument.annotationRanges)

                this.setState({
                    intQuery: intQuery || this.state.intQuery,
                    repLabel: repLabel,
                    repositoryInfo: repositoryInfo,

                    title: payload.annotatedDocument.title,
                    annotationRanges: this.props.renderDocumentOnly && payload.annotatedDocument.annotationType === 'PDF' ? Object.values(merged) : Object.values(payload.annotatedDocument.annotationRanges),
                    conceptDataMap: payload.annotatedDocument.conceptDataMap,
                    docMetadata: payload.annotatedDocument.docMetadata,
                    images: payload.annotatedDocument.images,
                    // payload.annotatedDocument.annotationType === 'PDF' ? res?.payload:
                    origHtml: payload.annotatedDocument.document,
                    origPartsHtml: origPartsHtml,
                    defaultActiveSearchTermsCount: payload.annotatedDocument.namedQueriesMatchesCount,

                    patFamilyMembers: patFamilyMembers,
                    docAdditionalOrganisms: docAdditionalOrganisms,
                    pdfUrl: payload.annotatedDocument.annotationType === 'PDF' ? createPDFUrl(repID, ocDocID, readcubeData) : '',
                    status: {
                        showMessage: false,
                        message: '',
                        error: false,
                        fetchingDocument: false
                    },
                    params,
                    abstractOnly
                }, () => {
                    if (this.props.renderDocumentOnly && payload.annotatedDocument.annotationType === 'PDF') {
                        this.props.onCreateSentenceDiv(sentences)
                        this.props.onSetSelectedSentence(sentences[0] ? sentences[0].annId : "")
                        this.props.onSetDocumentSentences(sentenceIDs)
                        this.props.onSetIsPDF(payload.annotatedDocument.annotationType === 'PDF')
                        this.props.onLoading(false)
                        scrollToElementViaScrollToButton('#scrollToBtnSent', '#' + sentences[0].annId)
                        document.location.hash = `highlight-${sentences[0].annId}`;
                    } else if (this.props.renderDocumentOnly && payload.annotatedDocument.annotationType !== 'PDF') {
                        this.props.onSetIsPDF(false)
                        this.props.onCreateSentenceDiv($('<div></div>'))
                    }
                });
            }
        }
        else {
            this.setState({
                status: {
                    showMessage: true,
                    message: 'Document not found.',
                    error: true,
                    fetchingDocument: false
                }
            }, () => {
                if (this.props.renderDocumentOnly) {
                    this.props.onLoading(false)
                }
            });
        }
    }

    onTabChange = (index) => {
        this.setState({
            activeTabIndex: index
        });
    }

    createAnnotatedDocument = () => {
        return (
            <AnnotatedDocumentView
                activeIndex={this.props.activeIndex}
                activeIndexChange={this.props.activeIndexChange}
                activeIndexIncrease={this.props.activeIndexIncrease}
                abortLoadingClicked={this.props.abortLoadingClicked}
                setAbortLoadingClicked={this.props.setAbortLoadingClicked}
                userData={this.props.userData}
                repID={this.state.params.repID}
                repLabel={this.state.repLabel}
                repositoryInfo={this.state.repositoryInfo}
                ocDocID={this.state.params.ocDocID}
                readcubeData={this.state.readcubeData}
                title={this.state.title}
                intQuery={this.state.intQuery}
                annotationRanges={this.state.annotationRanges}
                conceptDataMap={this.state.conceptDataMap}
                docMetadata={this.state.docMetadata}
                images={this.state.images}
                patFamilyMembers={this.state.patFamilyMembers}
                docAdditionalOrganisms={this.state.docAdditionalOrganisms}
                origHtml={this.state.origHtml}
                origPartsHtml={this.state.origPartsHtml}
                status={this.state.status}
                sequenceID={this.state.sequenceID}
                openDetailsTab={false}
                containerID={!this.state.pdfUrl ? 'oc_doc' : 'oc_pdfDoc'}
                pdfUrl={this.state.pdfUrl}
                repositories={this.props.repositories}
                defaultSearchTerms={this.state.activeSearchTerms}
                defaultActiveSearchTermsCount={this.state.defaultActiveSearchTermsCount}
                documentHeight={this.state.documentHeight}
                setActiveSearchTerm={this.setActiveSearchTerm}
                isSemanticSearch={this.state.isSemanticSearch}
                abstractOnly={this.state.abstractOnly}
                renderDocumentOnly={this.props.renderDocumentOnly}
                availableContentHeight={this.props.availableContentHeight}
                selectedSentence={this.props.selectedSentence}
                selectedSentenceOption={this.props.selectedSentenceOption}
                annotationCoordinates={this.props.annotationCoordinates}
                threshold={this.props.threshold}
                selectedQueryTerms={this.props.selectedQueryTerms}
                showSentenceEntities={this.props.showSentenceEntities}
                activeSentenceChange={(id) => this.props.onSetSelectedSentence(id)}
                selectedSections={this.props.selectedSections}
                searchLoaded={this.props.searchLoaded}
                loader={this.props.loader}
            />
        );
    };


    sharedTemplate(rowData) {
        return <div style={{ marginBottom: 5 }}>
            {!rowData.shared ?
                ((rowData.departmentSharesRead && rowData.departmentSharesRead.length > 0) || (rowData.userSharesRead && rowData.userSharesRead.length > 0) || (rowData.userSharesWrite && rowData.userSharesWrite.length > 0)) ?
                    <span title={`You share this document collection with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This document collection can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This document collection is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }


    render() {
        return (
            <>
                <Toast ref={(el) => this.growl = el} />
                <div>
                    {this.createAnnotatedDocument()}
                    {/*pdfUrl !== '' ? this.createAnnotatedPDFDocument() : this.createAnnotatedDocument()*/}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateSentenceDiv: (div) =>
            dispatch(setSentenceAnalysisSentences(div)),
        onSetSelectedSentence: (id) =>
            dispatch(setSelectedSentenceAnalysis(id)),
        onSetDocumentSentences: (documentIDs) =>
            dispatch(setDocumentSentencesSentenceAnalysis(documentIDs)),
        onLoading: (loader) =>
            dispatch(setLoaderSentenceAnalysis(loader)),
        onSetIsPDF: (isPDF) =>
            dispatch(setIsPDFSentenceAnalysis(isPDF)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentView))