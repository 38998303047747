import { useState } from "react"
const UseDocview = () => {

    const [isDocviewModalVisible, setIsDocviewModalVisible] = useState(false);
    const [ocDocId, setOcDocId] = useState('');
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [repositoryId, setRepositoryId] = useState('');

    const onShowDocviewModal = (repId, docId, doc) => {
        setOcDocId(docId)
        setSelectedDocument(doc)
        setRepositoryId(repId)
        setTimeout(() => {
            setIsDocviewModalVisible(true)
        }, 100)

    };
    const onCloseDocviewModal = () => {
        setIsDocviewModalVisible(false)
    };

    return {
        onShowDocviewModal,
        onCloseDocviewModal,
        isDocviewModalVisible,
        ocDocId,
        selectedDocument,
        repositoryId
    }

}

export default UseDocview;