import { fetchAllQueries } from "../../../../api/TestApi";
import { addIDsToQueryTermsForAllSearchGroups, createGrowlMessage } from "../../../../components/webapi/queryBuilder/helpers/advancedSearch";
import { fetchNumberOfHitsForSearchGroups } from "../../../../components/webapi/queryBuilder/helpers/hits";
import { isArrayEmpty } from "../../../../components/webapi/util";
import { RESPONSE_STATUS_SUCCESS } from "../../../../properties";


export const AdvancedSearch3Actions = {
  AdvancedSearch3FormContent: 'ADVANCED_SEARCH_3_FORM_CONTENT',
  AddSearchGroup: 'ADD_SEARCH_GROUP',
  EditSearchGroup: 'EDIT_SEARCH_GROUP',
  RemoveSearchGroup: 'REMOVE_SEARCH_GROUP',
  SearchGroupToEdit: 'SEARCH_GROUP_TO_EDIT',
  SelectedSearchGroups: 'SELECTED_SEARCH_GROUPS',

  PreviewRepository: 'PREVIEW_REPOSITORY',
  SearchGroupsQueryStrings: 'SEARCH_GROUPS_QUERY_STRINGS',
  FetchingNumberOfResults: 'FETCHING_NUMBER_OF_RESULTS',
  SearchGroupsNumberOfResults: 'SEARCH_GROUPS_NUMBER_OF_RESULTS',
  SearchGroupsNumberOfResultsPreview: 'SEARCH_GROUPS_NUMBER_OF_RESULTS_PREVIEW',

  SearchGroupsReachable: 'SEARCH_GROUPS_REACHABLE',
  SearchGroupsErrors: 'SEARCH_GROUPS_ERRORS',
  SearchGroupsErrorsPreview: 'SEARCH_GROUPS_ERRORS_PREVIEW',

  TermsQueryStrings: 'TERMS_QUERY_STRINGS',

  ToggleTermGroupDialog: 'TOGGLE_TERM_GROUP_DIALOG',
  ToggleLogicGroupDialog: 'TOGGLE_LOGIC_GROUP_DIALOG',
  ToggleFilterGroupDialog: 'TOGGLE_FILTER_GROUP_DIALOG',
  ToggleSavedSearchGroupDialog: 'TOGGLE_SAVED_SEARCH_GROUP_DIALOG',

  ShowGlobalGrowl: 'SHOW_GLOBAL_GROWL',

  SaveAdvancedSearch3: 'SAVE_ADVANCED_SEARCH_3',
  SavedAdvancedSearch: 'SAVED_ADVANCED_SEARCH'
};


// TODO: keep actions, move other functions to thunks folder


// === search groups === //

export const addSearchGroup = (searchGroup) => ({
  type: AdvancedSearch3Actions.AddSearchGroup,
  payload: searchGroup,
});

export const editSearchGroup = (searchGroup) => ({
  type: AdvancedSearch3Actions.EditSearchGroup,
  payload: searchGroup,
});

export const removeSearchGroup = (searchGroup) => ({
  type: AdvancedSearch3Actions.RemoveSearchGroup,
  payload: searchGroup,
});

export const setSearchGroupToEdit = (searchGroup) => ({
  type: AdvancedSearch3Actions.SearchGroupToEdit,
  payload: searchGroup,
});

export const setSelectedSearchGroups = (searchGroups) => ({
  type: AdvancedSearch3Actions.SelectedSearchGroups,
  payload: searchGroups,
});


// === dialogs === //

export const toggleTermGroupDialog = (show) => ({
  type: AdvancedSearch3Actions.ToggleTermGroupDialog,
  payload: show
});

export const toggleLogicGroupDialog = (show) => ({
  type: AdvancedSearch3Actions.ToggleLogicGroupDialog,
  payload: show
});

export const toggleFilterGroupDialog = (show) => ({
  type: AdvancedSearch3Actions.ToggleFilterGroupDialog,
  payload: show
});

export const toggleSavedSearchGroupDialog = (show) => ({
  type: AdvancedSearch3Actions.ToggleSavedSearchGroupDialog,
  payload: show
});

export const showGlobalGrowl = (message) => ({
  type: AdvancedSearch3Actions.ShowGlobalGrowl,
  payload: message
});


// === advanced search form === //

export const setSearchGroups = (searchGroups) => ({
  type: AdvancedSearch3Actions.AdvancedSearch3FormContent,
  payload: searchGroups
});


export const setAdvancedSearch3FormContent = (searchGroups) => (dispatch) => {

  // === add IDs to query terms === //
  searchGroups = addIDsToQueryTermsForAllSearchGroups(searchGroups);

  // === fetch data of all referenced saved searches === //
  let queryIDs = searchGroups.filter(sg => !!sg.savedSearchID).map(sg => sg.savedSearchID);
  if (!isArrayEmpty(queryIDs)) {
    return fetchAllQueries()
      .then((response) => {

        switch (response?.status) {

          case RESPONSE_STATUS_SUCCESS:
            const savedSearchesMap = {};
            response.payload.content?.forEach(svs => {
              savedSearchesMap[svs.id] = svs;
            });

            searchGroups.forEach(sg => {
              if (savedSearchesMap[sg.savedSearchID]) {
                sg.savedSearchData = savedSearchesMap[sg.savedSearchID];
                queryIDs = queryIDs.filter(id => id !== sg.savedSearchID);
              }
            });
            if (!isArrayEmpty(queryIDs)) {
              const message = createGrowlMessage('Saved searches not found', `Referenced saved searches with the following internal IDs could not be found:\n${queryIDs.join(', ')}`, 'error', true, true);
              dispatch(showGlobalGrowl(message));
            }

            dispatch(setSearchGroups(searchGroups));
            break;

          default:
            dispatch(setSearchGroups(searchGroups));
            const message = createGrowlMessage('Error occurred', response.message || 'Error while fetching referenced saved searches', 'error');
            dispatch(showGlobalGrowl(message));
        }
      })
  }

  dispatch(setSearchGroups(searchGroups));
}


// === preview results for search groups === //

export const setPreviewRepository = (repositoryID) => ({
  type: AdvancedSearch3Actions.PreviewRepository,
  payload: repositoryID
});

export const setSearchGroupsQueryStrings = (queryStrings) => ({
  type: AdvancedSearch3Actions.SearchGroupsQueryStrings,
  payload: queryStrings
});

export const fetchingNumberOfResults = (fetching) => ({
  type: AdvancedSearch3Actions.FetchingNumberOfResults,
  payload: fetching
});

export const setSearchGroupsNumberOfResults = (numberOfResults) => ({
  type: AdvancedSearch3Actions.SearchGroupsNumberOfResults,
  payload: numberOfResults
});

export const setSearchGroupsNumberOfResultsPreview = (numberOfResults) => ({
  type: AdvancedSearch3Actions.SearchGroupsNumberOfResultsPreview,
  payload: numberOfResults
});


export const fetchNumberOfResultsForSearchGroups = (searchGroups, filterDefinitions, previewRepository,
  numberOfResultsPrev = {}, queryStringsPrev = {}) => (dispatch) => {

    dispatch(fetchingNumberOfResults(true));
    return fetchNumberOfHitsForSearchGroups(searchGroups, filterDefinitions, previewRepository, numberOfResultsPrev, queryStringsPrev)
      .then((response) => {
        switch (response?.status) {
          case RESPONSE_STATUS_SUCCESS:
            const { numberOfResults, queryStringResultsMap } = response.payload;
            dispatch(setSearchGroupsNumberOfResults(numberOfResults));
            dispatch(setSearchGroupsQueryStrings(queryStringResultsMap));
            dispatch(fetchingNumberOfResults(false));
            break;
          default:
            dispatch(setSearchGroupsNumberOfResults(null));
            dispatch(fetchingNumberOfResults(false));
            const message = createGrowlMessage('Error occurred', response.message || 'Error while fetching documents', 'error');
            dispatch(showGlobalGrowl(message));
        }
      })
  }

export const fetchNumberOfResultsForSearchGroupsPreview = (searchGroups, filterDefinitions, previewRepository,
  numberOfResultsPrev = {}, queryStringsPrev = {}) => (dispatch) => {

    dispatch(fetchingNumberOfResults(true));
    return fetchNumberOfHitsForSearchGroups(searchGroups, filterDefinitions, previewRepository, numberOfResultsPrev, queryStringsPrev)
      .then((response) => {
        switch (response?.status) {
          case RESPONSE_STATUS_SUCCESS:
            const { numberOfResults, queryStringResultsMap } = response.payload;
            dispatch(setSearchGroupsNumberOfResultsPreview(numberOfResults));
            dispatch(setSearchGroupsQueryStrings(queryStringResultsMap));
            dispatch(fetchingNumberOfResults(false));
            break;
          default:
            dispatch(setSearchGroupsNumberOfResultsPreview(null));
            dispatch(fetchingNumberOfResults(false));
            const message = createGrowlMessage('Error occurred', response.message || 'Error while fetching documents', 'error');
            dispatch(showGlobalGrowl(message));
        }
      })
  }


// === preview results for query terms === //

export const setTermsQueryStrings = (queryStrings) => ({
  type: AdvancedSearch3Actions.TermsQueryStrings,
  payload: queryStrings
});


// === consistency checks and errors === //

export const setSearchGroupsReachable = (searchGroups) => ({
  type: AdvancedSearch3Actions.SearchGroupsReachable,
  payload: searchGroups
});

export const setSearchGroupsErrors = (errors) => ({
  type: AdvancedSearch3Actions.SearchGroupsErrors,
  payload: errors
});

export const setSearchGroupsErrorsPreview = (errors) => ({
  type: AdvancedSearch3Actions.SearchGroupsErrorsPreview,
  payload: errors
});


// === saved search === //

export const setSavedAdvancedSearch = (savedSearchData) => ({
  type: AdvancedSearch3Actions.SavedAdvancedSearch,
  payload: savedSearchData
});