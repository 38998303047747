import React from 'react';
import {Checkbox} from "primereact/checkbox";
import styles from "./CheckboxWithLabel.module.scss";
import classNames from "classnames";

const CheckboxWithLabel = ({checked, labelText, handleCheckBox, inputId, disabled, className}) => {
    const labelClassName = classNames(className, styles.label);
    return (
        <div className={styles.checkboxWrapper}>
            <Checkbox inputId={inputId} checked={checked} onChange={handleCheckBox} disabled={disabled}/>
            <label className={labelClassName} htmlFor={inputId}>{labelText}</label>
        </div>
    );
};

export default CheckboxWithLabel;
