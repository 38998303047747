/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import axios from 'axios'
import { Toast } from 'primereact/toast';
import { APP_PROPERTIES, FUNCTION_ROLES } from '../../properties/index';
//import { doLogin, doLoginSAML } from '../actions/UserActions';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Login.css';
import { getCurrentYear } from '../webapi/util';

class Verification extends Component {

	state = {
		validToken: false,
		fetching: true
	}

	UNSAFE_componentWillMount = async () => {
		// --- if token is found in properties -> Send verification call --- //
		if (this.props.token) {
			var self = this;
			await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/users/verification`, {}, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.props.token}` } })
				.then(function (response) {
					//console.log(response.data.token)
					self.setState({
						validToken: true,
						fetching: false
					})
					setTimeout(() => {
						//console.log("Login")
						self.props.history.push({ pathname: '/updatepw', token: response.data.token });
					}, 4000);
				}).catch(function (error) {
					if (error.response.status === 400) {
						self.setState({
							validToken: false,
							fetching: false
						}, () => {
							self.showFailGrowl(error.response.data)
						})
					} else if (error.response.status === 401) {
						self.setState({
							validToken: false,
							fetching: false
						})
					} else if (error.response.status === 404) {
						self.setState({
							validToken: false,
							fetching: false
						})
						self.props.history.push('/')
						console.log("not found")
					}
				})
		}
		// --- check if user has token stored in local storage and is authenticated  --- //
		// --- -> redirect to home page --- //
		else if (localStorage.token && this.props.authenticated) {
			this.setState({
				fetching: false
			})
			this.homepage = '';
			const tokenParts = localStorage.token.split('.');
			const tokenPayload = JSON.parse(atob(tokenParts[1]));
			const tokenLanding = tokenPayload.landing;
			if (tokenLanding) {
				let hasLanding = false
				tokenPayload.rol.forEach(role => {
					if (role.toLowerCase().includes(tokenLanding)) {
						FUNCTION_ROLES.forEach(role => {
							if (role.name === tokenLanding) {
								this.homepage = role.url
								hasLanding = true
							}
						})
					}
				})
				if (hasLanding === false) {
					this.homepage = APP_PROPERTIES.HOME_PAGE
				}
			} else {
				this.homepage = APP_PROPERTIES.HOME_PAGE
			}

			this.props.history.push(this.homepage);
		} else {
			this.setState({
				fetching: false
			})
		}
	}

	showFailGrowl = (e) => {
		let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
		this.growl.show(msg);
	}

	render() {

		//console.log("fetching", this.state.fetching)
		//console.log("token", this.props.token)
		//console.log("valid", this.state.validToken)

		if (this.props.token && this.state.validToken && this.state.fetching === false) {
			window.onload = function () {
				var sec = 2;
				var myInterval = setInterval(function () {
					sec !== 1 && sec !== 0 ?
						document.getElementById("timer").innerHTML = ' ' + sec + ' seconds!'
						:
						sec === 0 ?
							document.getElementById("timer").innerHTML = ' now!'
							:
							document.getElementById("timer").innerHTML = ' ' + sec + ' second!'
					sec--;
					if (sec === -1) {
						sec = 0;
						clearInterval(myInterval)
					}
				}, 1000);
			}
		}

		//console.log((this.props.token === null && this.state.validToken === false && this.state.fetching === false) === true)

		return (
			<LoadingOverlay
				active={this.state.fetching}
				spinner={true} >
				<div className="login-body" style={{
					background: APP_PROPERTIES.APP_ID !== 'dimensions' ?
						`url('${APP_PROPERTIES.LOGIN_BG_IMAGE}') no-repeat scroll center center / cover` : 'white'
				}}>
					<Toast ref={(el) => { this.growl = el }} />
					<div className="login-panel" >
						{APP_PROPERTIES.LOGIN_LOGO_APP ?
							<div className="login-panel-header" style={{ marginTop: 66, marginLeft: 'auto', marginRight: 'auto', background: 'white', padding: 0 }}>
								<img src={APP_PROPERTIES.LOGIN_LOGO_APP} alt={`${APP_PROPERTIES.APP_NAME} logo`} style={APP_PROPERTIES.LOGIN_LOGO_APP_STYLE} />
							</div>
							: null}
						{this.props.token && this.state.validToken && this.state.fetching === false ?
							<form onSubmit={this.handleLogin}>
								<div className="login-panel-content">
									<div className="grid">
										<div className="col-12">
											<h1>Verification successful. You will automatically be redirected in
												<span id="timer"> 3 seconds!</span></h1>
										</div>
										{/*<div className="col-12">
									<Captcha siteKey='null' onResponse={this.showResponse}></Captcha>
		</div>*/}
									</div>
								</div>
							</form>
							:
							((this.props.token === null && this.state.validToken === false) || (this.props.token && this.state.validToken === false)) && this.state.fetching === false ?
								<form //onSubmit={this.handleLogin}
								>
									<div className="login-panel-content">
										<div className="grid">
											<div className="col-12" style={{ textAlign: 'center' }}>
												<label style={{ fontSize: 20, color: 'black' }}>Oops! Something went wrong.</label>
											</div>
											{/*<div className="col-12">
							<Captcha siteKey='null' onResponse={this.showResponse}></Captcha>
</div>*/}
											{//APP_PROPERTIES.APP_ID === 'dimensions' ?
												<div className='col-12' style={{ paddingTop: 110 }}>
													<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
													<div className='col-12'>
														<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
														{APP_PROPERTIES.INCLUDE_ONETRUST &&
															<React.Fragment>
																<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
																{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
																<a className="optanon-show-settings" style={{ margin: '0 5px', color: '#757575' }}>Cookie settings</a>
															</React.Fragment>
														}
														<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
														<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
													</div>
												</div>
												//:
												//null
											}
										</div>
									</div>
								</form>
								:
								null
						}
					</div>
				</div>
			</LoadingOverlay>
		)
	}
}
const mapStateToProps = (state) => ({
	authenticated: state.user.authenticated
})

const mapDispatchToProps = (dispatch) => ({
	//doLoginSAML: (history, token) => dispatch(doLoginSAML(history, token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Verification))