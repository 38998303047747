export const dropdownFieldsForConcepts = [{
    label: 'Filter', code: 'Filter',
    items: [{
        label: "With Subtree",
        value: { conceptLookupType: 'ONTOLOGICAL', occurrence: 'MUST' },
        className: "filter",
        tooltip: "Filter by this concept together with its descendant concepts."
    },
    {
        label: "Without Subtree",
        value: { conceptLookupType: 'EXACT', occurrence: 'MUST' },
        className: "filter",
        tooltip: "Filter by this concept without its descendant concepts."
    },
    {
        label: "Original Term",
        value: { termLookupType: 'TERM_NORMALIZED', occurrence: 'MUST' },
        className: "filter",
        tooltip: "Filter by the original source term."
    },
        /*{
            label: "Text With Variants",
            value: {termLookupType: 'TEXT_STEMMED', occurrence: 'MUST'},
            className: "filter"
        }*/
    ]
}, {
    label: 'Exclude', code: 'Exclude',
    items: [{
        label: "With Subtree",
        value: { conceptLookupType: 'ONTOLOGICAL', occurrence: 'MUST_NOT' },
        className: "exclude",
        tooltip: "Exclude this concept together with its descendant concepts."
    },
    {
        label: "Without Subtree",
        value: { conceptLookupType: 'EXACT', occurrence: 'MUST_NOT' },
        className: "exclude",
        tooltip: "Exclude this concept without its descendant concepts."
    },
    {
        label: "Original Term",
        value: { termLookupType: 'TERM_NORMALIZED', occurrence: 'MUST_NOT' },
        className: "exclude",
        tooltip: "Exclude the original source term."
    },
        /*{
            label: "Text With Variants",
            value: {termLookupType: 'TEXT_STEMMED', occurrence: 'MUST_NOT'},
            className: "exclude"
        }*/
    ]
}];

export const dropdownFieldsForTerms = [{
    label: 'Filter', code: 'Filter',
    items: [
        {
            label: "Original Term",
            value: { termLookupType: 'TERM_NORMALIZED', occurrence: 'MUST' },
            className: "filter",
            tooltip: "Filter by the original source term."
        },
        /*{
            label: "Text With Variants",
            value: {termLookupType: 'TEXT_STEMMED', occurrence: 'MUST'},
            className: "filter"
        }*/
    ]
}, {
    label: 'Exclude', code: 'Exclude',
    items: [
        {
            label: "Original Term",
            value: { termLookupType: 'TERM_NORMALIZED', occurrence: 'MUST_NOT' },
            className: "exclude",
            tooltip: "Exclude the original source term."
        },
        /*{
            label: "Text With Variants",
            value: {termLookupType: 'TEXT_STEMMED', occurrence: 'MUST_NOT'},
            className: "exclude"
        }*/
    ]
}];

