import React, {useState} from 'react';
import styles from "./RightAsideDescription.module.scss";

export const RightAsideDescription = ({shortText, fullText}) => {
    const [showMore, setMore] = useState(false);

    const showMoreInfo = () => {
        setMore(true);
    }

    const showLessInfo = () => {
        setMore(false);
    }

    return (
        <>
            {showMore === false ?
                <p className={styles.info}>{shortText}
                    <a
                        style={{paddingLeft: 5}}
                        title="Click for more information about biomarkers"
                        onClick={showMoreInfo}>More</a>
                </p>
                :
                <p className={styles.info}>{fullText}
                    <a
                        style={{paddingLeft: 5}}
                        title="Click to hide more information"
                        onClick={showLessInfo}>Less
                    </a>
                </p>
            }
        </>
    );
};
