/* eslint-disable jsx-a11y/anchor-is-valid */
import './DocumentFinder.css'
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SearchComponent from '../SearchComponents/SearchComponentNew'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import RepositoryOverview from '../docresults/RepositoryOverview';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { getHeaderToken, checkResultAndGetPayload } from '../../../api/index'
import { addThousandsSeparatorToNumber, sortObjectMapByAttribute, isArrayEmpty, isObjectEmpty, createUserArray, createCheckedKeys, hasUserRole } from '../util';
import axios from 'axios'
import OntologyBrowserDialog from '../ontbrowser/OntologyBrowserDialog'
import {
    APP_PROPERTIES, REPOSITORY_INFO_NOT_AVAILABLE, EXPORTER_TARGET_ID_BIGQUERY, QUERY_TYPE_ADVANCED_SEARCH, REPO_ROLE_DOC_SEARCH,
    QUERY_CATEGORY_DOC_SEARCH, QUERY_FORM_ADVANCED, readRepositoryFilterMapping, readFilterDefinitions, LOCAL_STORAGE_DOC_FINDER_DATA, getFilterDefinitionForRepository
} from '../../../properties/index';
import DocumentResultWrapper from '../docresults/DocumentResultWrapper';
import { createDocumentSearchRequestV2, exportDocumentsMetaData, exportDocumentsMetaDataByDocIDs, fetchDocuments, fetchSortableFields } from '../../../api/content/DocumentApi';
import ConfirmationDialog from '../../common/ConfirmDialog/ConfirmationDialog'
import moment from 'moment';
import { videos } from '../../../properties/videos'
import pdfFileDimensions from '../../../assets/images/tutorial/Dimensions_L_C_Advanced_Search_User_Guide.pdf'
import pdfFileSciWalker from '../../../assets/images/tutorial/SciWalker_Advanced_Search_User_Guide.pdf'
import { setQuickSearchSelectedTerms } from '../../../redux/actions/QuickSearchActions'
import SeparatorPoint from '../general/SeparatorPoint'
import SearchExamples from '../general/searchexamples/SearchExamples'
import { ADVANCED_SEARCH_EXAMPLES } from './examples';
import TutorialVideos from '../general/TutorialVideos'
import SaveSearchButtons from '../../common/SaveSearch/SaveSearchButtons'
import { getDataFromLocalStorage, removeDataFromLocalStorage } from '../util/localStorage'
import { FILTER_MODE_INTERSECTION } from '../advancedSearch/components/toolbarFilterQueries/FilterQueryTab'
import ExportCenterSuccessMessage from '../export/ExportCenterSuccessMessage'
import { fetchAllQueries, submitReadcubeLibrary, fetchReadcubeRequests, deleteReadcubeLibrary, syncReadcubeLibrary } from '../../../api/TestApi'
import SaveSearchDialog from '../../common/SaveSearch/SaveSearchDialog'
import { storeQuery, updateQuery } from '../../../api/content/WatchlistApi'
import { addQueryAlert, deleteQueryAlert, updateQueryAlert } from '../../../api/content/SaveSearch'
import { createSortFieldsList, determineActiveRepositoryV2, determineActiveSortCriteria } from '../util/content'
import { addSearchResult, createEmptySearchResult } from '../general/docsearch/searchUtil'
import { DEFAULT_QUERY_SETTINGS, DEFAULT_ROW_COUNT, DEFAULT_SORT_CRITERIA, SEARCH_FILTER_ID_GROUP_BY_PAT_FAM } from '../general/docsearch/searchConstants'
import { KEY_PREFIX, addKeyToAdvancedQuery, addKeysToAdvancedQueries, createFormContentFromAdvancedQueries, createRequestFromAdvancedQueries, isEmptySearch } from '../general/docsearch/advancedSearch'
import SavedSearchesLink from '../general/SavedSearches/SavedSearchesLink'
import AddReadcubeDialog from "../../common/ReadCube/AddReadcubeDialog";
import _ from 'lodash';

const INITIAL_MAIN_QUERY = { key: `${KEY_PREFIX}0`, query: "" };
const INITIAL_ADV_QUERIES = [{ ...INITIAL_MAIN_QUERY }];
const FILTER_QUERY = { query: "", filterMode: FILTER_MODE_INTERSECTION };

class DocumentFinder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeQueryTabIndex: { active: 0 },
            advancedQueries: INITIAL_ADV_QUERIES,
            filterQueryKeyCounter: 1,
            showResults: false,
            first: 0,
            rows: 20,
            groupByPatFamilies: true,
            label: '',
            documents: [],
            queryDistance: 'off',
            repositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE,
            pageTutorials: [],
            repositoryFilterMap: {},
            sortFields: [],
            readcubeRequests: [],
            displayAddReadcubeDialog: false,
            numberUpdates: 0
        };

        this.growl = createRef();
        this.op = createRef();
    }

    async componentDidMount() {
        this.props.onItemSelectedClicked([]);

        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('advanced')) });
        }

        // --- get filter definitions --- //
        const { filterDefinitions } = await readFilterDefinitions();
        const repositoryFilterMap = await readRepositoryFilterMapping();
        // --- get default repository --- //
        const defaultRepository = REPOSITORY_INFO_NOT_AVAILABLE;
        //console.log('-> defaultRepository: ', defaultRepository);
        this.setState({
            filterDefinitions: filterDefinitions ? filterDefinitions : {},
            repositoryFilterMap: repositoryFilterMap ? repositoryFilterMap : {},
            defaultRepository: REPOSITORY_INFO_NOT_AVAILABLE,
            loadDocumentsOnStart: defaultRepository && APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showDocumentsOnStart
        });

        // http://oc123:3000/my/savedsearches?searchcol=na&query=1021&date=2023-03-08
        // fill form and run search on page load
        // why multiple different objects???
        this.docFinderData = {};
        this.advancedDocFinderData = {};
        let docFinderData = getDataFromLocalStorage(LOCAL_STORAGE_DOC_FINDER_DATA);
        removeDataFromLocalStorage(LOCAL_STORAGE_DOC_FINDER_DATA);
        //console.log('docFinderData', docFinderData);

        // load search into advanced search form on page load via local storage
        // e.g. saved search, alert email link
        if (docFinderData?.queryRowData?.id) {
            this.loadSavedSearch(docFinderData.queryRowData.id, docFinderData.noAutoRun);
            return;
        }
        // load search into advanced search form on page load via location props
        // e.g. converted quick search
        else if (this.props.location?.advancedDocFinderData) {
            const advancedDocFinderData = JSON.parse(this.props.location.advancedDocFinderData);
            this.advancedDocFinderData.formContent = createFormContentFromAdvancedQueries([
                { ...INITIAL_MAIN_QUERY, query: advancedDocFinderData.selectedSimpleQuery },
                ...(advancedDocFinderData.filterQueries || [])
            ]);
        }
        //console.log('this.docFinderData', this.docFinderData);
        //console.log('this.advancedDocFinderData', this.advancedDocFinderData);
        this.loadSearchIntoForm(false);

        if (hasUserRole('ROLE_READCUBEVIEW')) {
            this.checkReadcubeRequests()
        }
    }

    componentWillUnmount() {
        // --- stop interval checks --- //
        clearInterval(this.intervalID);
        //localStorage.setItem('App.js', 'unmount');
    }

    loadSearchIntoForm = (noAutoRun) => {
        //console.log("advanced:", this.docFinderData)
        //this.setState({ loadSavedSearch: true });
        //console.log(this.docFinderData)
        if (Object.keys(this.docFinderData).length !== 0) {
            //console.log(formContent.filters)
            this.setState({
                savedSearchID: this.docFinderData.id,
                savedSearchName: this.docFinderData.fullName,
                editableQuery: this.docFinderData.edit,
                activeQueryTabIndex: { active: 0 },
                advancedQueries: this.docFinderData.formContent?.queries ? this.docFinderData.formContent?.queries : INITIAL_ADV_QUERIES
            }, () => {
                if (!noAutoRun)
                    this.handleAdvancedSearch()
            });
        }
        else if (!isObjectEmpty(this.advancedDocFinderData)) {
            this.setState({
                activeQueryTabIndex: { active: 0 },
                advancedQueries: this.advancedDocFinderData.formContent?.queries ?
                    this.advancedDocFinderData.formContent?.queries : INITIAL_ADV_QUERIES
            }/*, () => this.handleAdvancedSearch()*/);
        }
        //this.setState({ loadSavedSearch: false });
    }

    componentDidUpdate(prevProps, prevState) {


        if (!isArrayEmpty(this.props.allRepositories) && !_.isEqual(prevProps.allRepositories, this.props.allRepositories)) {
            this.handleAdvancedSearch()
        }

        if (this.props.userData.userDetails !== prevProps.userData.userDetails) {
            var self = this
            //axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/department/user/list`, { headers: getHeaderToken() })
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/users`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        departmentMembers: response?.data
                    }, () => {
                        let userArray = createUserArray(response.data, self.props.userData)
                        self.setState({
                            userArray: userArray
                        })
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    checkReadcubeRequests = async (loadAgain, repoName) => {
        if (this.intervalID !== undefined) {
            clearInterval(this.intervalID)
        }
        let response;
        response = await fetchReadcubeRequests();
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                let numberUpdates = response.payload.filter(req => req.status === 'UPDATING').length
                //console.log(numberUpdates)
                let updateFinished = false
                if (numberUpdates < this.state.numberUpdates) {
                    //console.log("reload")
                    updateFinished = true
                }
                this.setState({
                    readcubeRequests: response.payload,
                    numberUpdates: numberUpdates
                }, () => {
                    if (response.payload?.length > 0 && !loadAgain &&
                        (response.payload.filter(req => req.status === 'FINISHED' || req.status === 'FAILED').length !== response.payload.length)) {
                        this.intervalID = setInterval(() => {
                            this.checkReadcubeRequests()
                        }, 10000);
                    }
                    if ((response.payload?.length > 0 && response.payload.filter(req => req.status === 'FINISHED').length > 0
                        && (response.payload.filter(req => req.status === 'FINISHED' || req.status === 'UPDATING').length !== this.props.allRepositories.filter(repo => repo.name.startsWith("rc_")).length))
                        || loadAgain) {
                        this.props.fetchAvailableUserRepositories(this.props.history)
                    }
                    if ((loadAgain && repoName) || updateFinished) {
                        this.setState({
                            repositoryInfo: repoName === this.state.repositoryInfo.name ? REPOSITORY_INFO_NOT_AVAILABLE : this.state.repositoryInfo
                        }, () => {
                            this.fetchRepositoryStatistics()
                        })
                    }
                })
            }
        }
    }

    onAddReadcubeLibrary = () => {
        this.setState({
            displayAddReadcubeDialog: true
        })
    }

    onSubmitReadcubeLibrary = async (lib) => {
        let response;
        response = await submitReadcubeLibrary(lib);
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                this.setState({
                    displayAddReadcubeDialog: false
                }, () => {
                    this.checkReadcubeRequests()
                })
                this.growl.current.show({
                    life: 10000,
                    sticky: false, closable: true, severity: 'success',
                    summary: 'Importing ReadCube library', detail: "Your ReadCube library is getting imported and annotated. Depending on the number of documents importing will take some time. You will see the progress on this page."
                });
            }
        }
    }

    onDeleteReadcubeLibrary = async (e, repoName, data) => {
        e.preventDefault()
        e.stopPropagation();
        let uuidToDelete = ''
        this.state.readcubeRequests.forEach(req => {
            if (req.repositoryName === repoName) {
                uuidToDelete = req.uuid
            }
        })
        let response;
        response = await deleteReadcubeLibrary(uuidToDelete);
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                if (data?.status !== 'FAILED') {
                    this.checkReadcubeRequests(true, repoName)
                } else {
                    this.checkReadcubeRequests(false)
                }
            }
        }
    }

    onSyncReadcubeLibrary = async (e, repoName) => {
        e.preventDefault()
        e.stopPropagation();
        let uuidToSync = ''
        this.state.readcubeRequests.forEach(req => {
            if (req.repositoryName === repoName) {
                uuidToSync = req.uuid
            }
        })
        let response;
        response = await syncReadcubeLibrary(uuidToSync);
        if (response) {
            const result = checkResultAndGetPayload(response, this.growl);
            if (response.status === 'SUCCESS') {
                this.checkReadcubeRequests()
            }
        }
    }

    nameTemplate(rowData) {
        if (rowData.hasOwnProperty('name')) {
            return <React.Fragment>
                {/* <span className="p-column-title">Name</span>*/}
                {rowData.name}
            </React.Fragment>
        }
    }

    numberQueriesTemplate = (rowData) => {
        if (rowData.hasOwnProperty('numberQueries')) {
            return <React.Fragment>
                {/* <span className="p-column-title">Queries</span> */}
                {rowData.numberQueries}
            </React.Fragment>
        }
    }

    sharedTemplate(rowData) {
        return <div style={{ marginBottom: 5 }}>
            {/*<span className="p-column-title">Sharing</span>*/}
            {!rowData.shared ?
                (rowData.sharedForDepartment || (rowData.usersSharedWith && rowData.usersSharedWith.length > 0)) ?
                    <span title={`You share this search collection with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This search collection can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This search collection is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }

    showEmptySearchWarningGrowl = () => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: 'Empty main query not allowed.', life: 3000 };
        this.growl.current.show(msg);
    }

    handleAdvancedSearch = () => {
        if (!isEmptySearch(this.state.advancedQueries)) {
            //console.log("rep", this.state.repositoryInfo)
            this.setState({
                showResults: false,
                first: 0,
                showRepositoryStatistics: true,
            }, () => this.fetchRepositoryStatistics())
        } else {
            this.showEmptySearchWarningGrowl();
        }
    }

    fetchRepositoryStatistics = async () => {
        // --- get query and filterQueries --- //
        const queries = createRequestFromAdvancedQueries(this.state.advancedQueries);
        // --- determine active repository --- //
        const newActiveRepository = determineActiveRepositoryV2(this.props.allRepositories, this.state.repositoryInfo);
        // --- store results for each repo in map --- //
        const { repoStats, reposToFetch } = createEmptySearchResult(this.props.allRepositories);

        this.setState({
            fetchingNumOfHits: true,
            showRepositoryStatistics: true,
            showResults: false,
            repoStats: repoStats,
            documents: null,
        });

        // --- send query to each repository --- //
        this.props.allRepositories?.forEach(repo => {
            // --- run request for documents for active repo --- //
            if (newActiveRepository.id === repo.id && !newActiveRepository.unavailable) {
                this.handleRepositoryClick(newActiveRepository);
            }
            // --- run request for number of hits only --- //
            const request = createDocumentSearchRequestV2(repo.id, queries.query, queries.filterQueries);
            //sleep(Math.random() * 60000).then(e => {
            fetchDocuments(1, 0, request)
                .then(response => {
                    const repoStatsNew = addSearchResult(response, repo, repoStats);
                    this.setState({ repoStats: { ...repoStatsNew } });
                })
                // not happening???
                .catch(err => { console.log(err); })
                .finally(() => {
                    delete reposToFetch[repo.name];
                    if (isObjectEmpty(reposToFetch)) {
                        this.setState({ fetchingNumOfHits: false });
                    }
                })
            //});
        });
    }

    handlePageChange = (startIndex, count) => {
        this.setState({
            showResults: true,
            first: startIndex,
            rows: count,
        }, () => this.fetchDocumentList())
    }

    handleExport = async (exportData = {}, targetID, outputFormat) => {

        this.setState({ fetchingExportData: true });

        const queries = createRequestFromAdvancedQueries(this.state.advancedQueries, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);

        this.setState({
        }, async () => {
            let response;
            if (exportData.docIDs) {
                response = await exportDocumentsMetaDataByDocIDs(exportData.docIDs, targetID, outputFormat);
            }
            else {
                response = await exportDocumentsMetaData(this.state.repositoryInfo.id, queries.query, queries.filterQueries,
                    this.state.sortCriteria?.sortField, this.state.sortCriteria?.sortMode, exportData.maxNumOfResults,
                    this.state.sortCriteria?.sortFactor, targetID, outputFormat);
            }
            if (response && targetID === EXPORTER_TARGET_ID_BIGQUERY) {
                const result = checkResultAndGetPayload(response, this.growl);
                if (response.status === 'SUCCESS') {
                    const datasetExportIdentifier = result.datasetExportIdentifier;
                    const forwardUrl = result.forwardUrl;
                    if (forwardUrl) {
                        window.open(forwardUrl, '_blank');
                    }
                    else {
                        this.growl.current.show({
                            sticky: true, closable: true, severity: 'success',
                            summary: 'Export successful', detail: `Dataset export identifier: ${datasetExportIdentifier}`
                        });
                    }
                }
            }
            else {
                checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage />, null, true);
            }
            this.setState({ fetchingExportData: false });
        })
    }

    addKeysToAdvancedQueries = (advancedQueries) => {
        const nextCounter = addKeysToAdvancedQueries(advancedQueries);
        this.setState({ filterQueryKeyCounter: nextCounter });
    }

    addKeyToAdvancedQuery = (advancedQuery) => {
        const nextCounter = addKeyToAdvancedQuery(advancedQuery, this.state.filterQueryKeyCounter);
        this.setState({ filterQueryKeyCounter: nextCounter });
    }

    handleRepositoryClick = async (repositoryInfo) => { // (showPubs, api, rep, repLabel, apiLabel, custHits)
        //console.log("repo:", repositoryInfo)
        //console.log(this.state)

        // --- request repository schema --- //
        const schema = await this.getRepositorySchema(repositoryInfo?.id);
        // --- get available sorting options for this repository, plus additional relevance options --- //
        const sortFields = createSortFieldsList(schema?.sortCriteria, repositoryInfo);
        // --- get default sorting criteria --- //
        const sortCriteria = determineActiveSortCriteria(sortFields, DEFAULT_QUERY_SETTINGS.defaultSortingOptions, DEFAULT_SORT_CRITERIA);

        // @todo: move to searchUtil
        const activeRepoFilters = getFilterDefinitionForRepository(this.state.repositoryFilterMap, repositoryInfo?.name);
        // const activeRepoFilters = this.state.repositoryFilterMap[repositoryInfo?.name];
        const allowGroupByPatentFamily = activeRepoFilters ? activeRepoFilters.includes(SEARCH_FILTER_ID_GROUP_BY_PAT_FAM) : false;
        let patentFamilyGroupingValues = null, patentFamilyGrouping = null;
        if (allowGroupByPatentFamily) {
            patentFamilyGroupingValues = this.state.filterDefinitions[SEARCH_FILTER_ID_GROUP_BY_PAT_FAM]?.filterValues;
            patentFamilyGroupingValues = patentFamilyGroupingValues?.filter(val => {
                return isArrayEmpty(val.repoAllowList) || val.repoAllowList.includes(repositoryInfo?.name)
            });
            patentFamilyGrouping = patentFamilyGroupingValues?.find(val => val.isDefault);
        }

        this.setState({
            groupByPatFamilies: true,
            allowGroupByPatentFamily: allowGroupByPatentFamily,
            patentFamilyGroupingValues: patentFamilyGroupingValues,
            patentFamilyGrouping: patentFamilyGrouping,
            showResults: false,
            repositoryInfo: repositoryInfo,
            selectedDocuments: {},
            sortFields: sortFields,
            sortCriteria: sortCriteria,
            first: 0,
            rows: DEFAULT_ROW_COUNT
        }, () => this.fetchDocumentList())
    }

    getRepositorySchema = async (repositoryID) => {
        // --- request repository schema, e.g. sortable fields, available facets, etc. --- //
        let schema;
        if (APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.fetchSortableFields) {
            const response2 = await fetchSortableFields(repositoryID);
            schema = checkResultAndGetPayload(response2, this.growl);
        }
        return schema;
    }

    fetchDocumentList = async (scroll = true) => {
        this.setState({ fetchingDocuments: true });

        const queries = createRequestFromAdvancedQueries(this.state.advancedQueries, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        const request = createDocumentSearchRequestV2(this.state.repositoryInfo?.id, queries.query, queries.filterQueries, null, this.state.sortCriteria?.sortField, this.state.sortCriteria?.sortMode, this.state.sortCriteria?.sortFactor);

        const self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v2/documents?repositoryId=${request.repository}&count=${this.state.rows}&start=${this.state.first + 1}`, JSON.stringify(request), { headers: getHeaderToken() })
            .then(function (response) {
                let result = response.data
                self.setState({
                    documents: result,
                    fetchingDocuments: false,
                    showResults: true,
                }, () => {
                    if (scroll) {
                        const offsetTop = document.getElementById("repositoryOverview")?.offsetTop;
                        if (offsetTop) {
                            window.scrollTo(0, offsetTop + 10);
                        }
                    }
                })
            })
            .catch(function (error) {
                self.setState({
                    fetchingDocuments: false,
                    documents: []
                })
                if (error.response) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                }
                else {
                    console.log('error: ', error);
                }
            })
    }

    handleGroupByPatentFamilyChange = async (patFamGrouping) => {
        this.setState({
            first: 0,
            rows: DEFAULT_ROW_COUNT,
            patentFamilyGrouping: patFamGrouping
        }, () => this.fetchDocumentList());
    }

    handleSortFieldChange = (sortField, runSearch = false) => {
        // --- get matching sorting criteria for this repository schema (or use default) --- //
        const sortCriteria = this.state.sortFields?.find(sf => sf.value === sortField) || DEFAULT_SORT_CRITERIA;
        this.setState({
            first: 0,
            rows: DEFAULT_ROW_COUNT,
            sortCriteria: sortCriteria,
        }, () => {
            if (runSearch) {
                this.fetchDocumentList();
            }
        });
    }

    handleSortModeChange = (sortMode, runSearch = false) => {

        const sortCriteria = this.state.sortCriteria ? { ...this.state.sortCriteria, sortMode: sortMode } : {};
        this.setState({
            first: 0,
            rows: DEFAULT_ROW_COUNT,
            sortCriteria: sortCriteria
        }, () => {
            if (runSearch) {
                this.fetchDocumentList();
            }
        });
    }

    onClearAll = () => {
        this.docFinderData = {}

        this.setState({
            resetQueryEditor: true
        }, () => this.setState({
            showRepositoryStatistics: false,
            showResults: false,
            //query: [],
            //internalQuery: '',
            repositoryInfo: REPOSITORY_INFO_NOT_AVAILABLE,
            savedSearchID: null,
            savedSearchName: null,
            activeQueryTabIndex: { active: 0 },
            advancedQueries: INITIAL_ADV_QUERIES,
        }, () => this.setState({ resetQueryEditor: false }))
        )
    }

    handleQueryChange = (queryString, htmlEditorContent) => {
        //console.log('queryString', queryString);
        const advancedQueriesNew = [...this.state.advancedQueries];
        advancedQueriesNew[this.state.activeQueryTabIndex?.active].query = queryString;
        advancedQueriesNew[this.state.activeQueryTabIndex?.active].htmlEditorContent = htmlEditorContent;
        //console.log('advancedQueriesNew', advancedQueriesNew);
        this.setState({ advancedQueries: advancedQueriesNew });
    }

    handleAddFilterQuery = () => {
        const advancedQueriesNew = [...this.state.advancedQueries];
        const activeIndexNew = advancedQueriesNew.length;

        const filterQueryNew = { ...FILTER_QUERY };
        this.addKeyToAdvancedQuery(filterQueryNew);
        advancedQueriesNew.push(filterQueryNew);

        this.setState({
            advancedQueries: advancedQueriesNew,
            activeQueryTabIndex: { active: activeIndexNew },
            filterQueryKeyCounter: this.state.filterQueryKeyCounter + 1
        });
    }

    handleRemoveFilterQuery = (index) => {
        const advancedQueriesNew = [...this.state.advancedQueries];
        advancedQueriesNew.splice(index, 1);

        let activeIndexNew = this.state.activeQueryTabIndex?.active;
        if (index === this.state.activeQueryTabIndex?.active) {
            activeIndexNew = 0;
        }
        else if (index < this.state.activeQueryTabIndex?.active) {
            activeIndexNew = this.state.activeQueryTabIndex.active - 1;
        }

        this.setState({
            advancedQueries: advancedQueriesNew,
            activeQueryTabIndex: { active: activeIndexNew }
        });
    }

    handleFilterModeChange = (index, filterMode, e) => {
        const advancedQueriesNew = [...this.state.advancedQueries];
        advancedQueriesNew[index].filterMode = filterMode;

        this.setState({ advancedQueries: advancedQueriesNew });
    }

    // ----------------------------------------------------------------------- //
    // --- domain explorer --------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onOpenExpertSearchOntologyBrowser = (e) => {
        this.setState({ ontBrowserVisible: true });
    }

    onOntBrowserClose = () => {
        this.setState({ ontBrowserVisible: false });
    }

    onOntBrowserSubmit = (concepts) => {
        this.setState({ ontBrowserVisible: false });
        this.searchComponent.addConcepts(concepts);
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.current.show(msg);
    }


    // ----------------------------------------------------------------------- //
    // --- save query -------------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    openSaveQueryDialog = async (editQuery, savedSearchID, savedSearchName) => {

        if (isEmptySearch(this.state.advancedQueries)) {
            this.showEmptySearchWarningGrowl();
            return;
        }

        const queries = createRequestFromAdvancedQueries(this.state.advancedQueries);
        const queryString = !isArrayEmpty(queries) ? JSON.stringify(queries) : "";

        this.setState({
            displaySaveQueryDialog: true,
            savedSearchID: savedSearchID,
            savedSearchName: savedSearchName,
            queryString: queryString,
            editQuery: editQuery,
        });
    }

    onStoreQuery = async (queryString, queryName, queryFullName, queryDescription, watchlistIDs,
        addAlert, alertID, alertActive, alertInterval, alertRepositories) => {

        let alertId = alertID;

        const queries = createRequestFromAdvancedQueries(this.state.advancedQueries);
        const formContent = createFormContentFromAdvancedQueries(this.state.advancedQueries);

        let response;
        let queryID;
        // --- existing query is updated --- //
        if (this.state.editQuery) {
            response = await updateQuery(this.state.savedSearchID,
                queries.query, queries.filterQueries, queryName, queryFullName, queryDescription, QUERY_CATEGORY_DOC_SEARCH.id,
                QUERY_TYPE_ADVANCED_SEARCH.id, QUERY_FORM_ADVANCED.id, formContent, watchlistIDs);
            queryID = this.state.savedSearchID;
        }
        // --- new query is stored --- //
        else {
            response = await storeQuery(
                queries.query, queries.filterQueries, queryName, queryFullName, queryDescription, QUERY_CATEGORY_DOC_SEARCH.id,
                QUERY_TYPE_ADVANCED_SEARCH.id, QUERY_FORM_ADVANCED.id, formContent, watchlistIDs);
            queryID = response?.payload?.id;
            alertId = null;
        }

        checkResultAndGetPayload(response, this.growl, 'Success',
            `${this.state.editQuery ? 'Search successfully updated.' : 'Search successfully stored.'}`);

        if (response.status === 'SUCCESS') {
            if (addAlert) {
                const date = new Date().setDate(new Date().getDate() + 1);
                let nextSendingDate = moment(date).format("YYYY-MM-DD");
                if (alertId) {
                    const responseAlert = await updateQueryAlert(alertId, queryID, alertActive, alertInterval, nextSendingDate, alertRepositories);
                    checkResultAndGetPayload(responseAlert, this.growl);
                }
                else {
                    const responseAlert = await addQueryAlert(queryID, alertActive, alertInterval, nextSendingDate, alertRepositories);
                    checkResultAndGetPayload(responseAlert, this.growl);
                }
            }
            else if (alertId) {
                const responseAlert = await deleteQueryAlert(alertId);
                checkResultAndGetPayload(responseAlert, this.growl);
            }

            this.setState({
                savedSearchID: queryID,
                savedSearchName: queryFullName,
                displaySaveQueryDialog: false,
                editableQuery: true,
            });
        }
    }

    onHideStoreQuery = () => {
        this.setState({ displaySaveQueryDialog: false });
    }


    loadSavedSearch = async (savedSearchID, noAutoRun) => {
        this.setState({ loadSavedSearch: true })
        const responseAllQueries = await fetchAllQueries();
        const savedSearch = responseAllQueries.payload.content.find(search => search.id === savedSearchID);
        this.setState({ loadSavedSearch: false })
        this.onSelectSavedSearch(savedSearch, noAutoRun);
    }

    onSelectSavedSearch = (savedSearch, noAutoRun) => {
        //console.log("on")
        if (savedSearch?.formContent) {
            //console.log("savedSearch", savedSearch);
            // new version contains json in formContent
            try {
                const queries = JSON.parse(savedSearch.formContent);
                this.addKeysToAdvancedQueries(queries?.queries);
                savedSearch.formContent = queries;
            }
            // old version contains string, must be converted to new format
            catch (e) {
                savedSearch.formContent = createFormContentFromAdvancedQueries([{ ...INITIAL_MAIN_QUERY, query: savedSearch.formContent }]);
            }

            //savedSearch.value =  savedSearch.val;
            let editable = true

            /*if (savedSearch.queryCollectionList && savedSearch.queryCollectionList.length > 0) {
                savedSearch.queryCollectionList.every(list => {
                    if (list.shared && !list.writable) {
                        editable = false
                        return false
                    }
                    return true
                })
            }*/
            if (!savedSearch.isCurrentUserOwner) {
                editable = false
            }
            savedSearch.edit = editable;// isArrayEmpty(savedSearch.queryCollectionList) || !savedSearch.queryCollectionList[0] || !savedSearch.queryCollectionList[0].shared //(savedSearch.queryCollectionList[0].shared && savedSearch.queryCollectionList[0].writable) || savedSearch.queryCollectionList[0].writable;
            this.docFinderData = savedSearch;
        }
        this.loadSearchIntoForm(noAutoRun);
    }

    handleEditable = (e) => {
        this.setState({
            editableForSharedUsers: e.value
        })
    }


    getFetchingInfo = () => {

        const blockPage =
            this.state.fetchingDocuments ||
            this.state.loadSavedSearch ||
            this.state.fetchingExportData;
        //this.state.fetchingRepoData ;

        const blockSearch = this.state.fetchingNumOfHits;

        let fetchingInfoText = 'Fetching data...';
        if (this.state.fetchingDocuments) {
            fetchingInfoText = "Fetching documents...";
        }
        else if (this.state.fetchingRepoData) {
            fetchingInfoText = "Fetching repository info...";
        }
        else if (this.state.fetchingExportData) {
            fetchingInfoText = "Export running...";
        }
        else if (this.state.fetchingNumOfHits) {
            fetchingInfoText = "Fetching number of hits...";
        }

        return { blockPage, blockSearch, fetchingInfoText };
    }


    render() {
        const queries = createRequestFromAdvancedQueries(this.state.advancedQueries, this.state.allowGroupByPatentFamily, this.state.patentFamilyGrouping);
        const { blockPage, blockSearch, fetchingInfoText } = this.getFetchingInfo();

        /*const repoStats = this.state.repoStats && this.state.repoStats.results && this.state.repoStats.results.length > 0 ?
            this.state.repoStats.results[0] : {};*/

        return (
            <>
                <Toast ref={this.growl} />

                <LoadingOverlay
                    active={blockPage}
                    spinner={true}
                    text={fetchingInfoText} >

                    <div className="grid">
                        <div className="col-12" style={{ marginBottom: 0 }}>

                            <LoadingOverlay
                                active={blockSearch}
                                text={fetchingInfoText} >
                                <div className='grid'>
                                    <div className="col textAlignLeft" style={{ paddingBottom: 0, paddingTop: 10 }}>
                                        <h1 style={{ marginBottom: 0 }} className="pageHeader">{"Advanced Search"}</h1>
                                    </div>
                                    <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                                        {!isArrayEmpty(this.state.pageTutorials) ?
                                            <span style={{ marginLeft: 30 }}>
                                                <TutorialVideos
                                                    pageTutorials={this.state.pageTutorials}
                                                    section='advanced'
                                                    sectionLabel='Advanced Search'
                                                /></span> : null}
                                    </div>
                                </div>

                                <div className='col-12' style={{ paddingLeft: 0, marginBottom: 20 }}>
                                    <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}>
                                        <span>
                                            Access the entire spectrum of powerful search strategies offered by our search API using the “Advanced Search” syntax language.
                                            Use the dropdown menus to learn what you can do or type away freely.
                                        </span>

                                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            <SeparatorPoint />

                                            <SearchExamples
                                                buttonLabel="Examples"
                                                examples={ADVANCED_SEARCH_EXAMPLES}
                                                onExampleClick={(example) => {
                                                    const mainQuery = { ...INITIAL_MAIN_QUERY, query: example.queryString };
                                                    const filterQueries = example.filterQueries || [];
                                                    this.setState({
                                                        activeQueryTabIndex: { active: 0 },
                                                        advancedQueries: [mainQuery, ...filterQueries]
                                                    }, () => this.handleAdvancedSearch());
                                                }}
                                            />

                                            <SeparatorPoint />

                                            {/*a title="Click to open manual in new tab"
                                                target='_blank'
                                                rel="noreferrer"
                                                //download
                                                //onClick={e => this.showManual(e)}
                                                href={APP_PROPERTIES.APP_ID === 'dimensions' ? pdfFileDimensions : pdfFileSciWalker}>
                                                Open Manual
                                            </a>*/}

                                            <Button
                                                className="p-button-text primaryButtonAsLink valignMiddle"
                                                title="Click to open manual in new tab"
                                                onClick={() => window.open(APP_PROPERTIES.APP_ID === 'dimensions' ? pdfFileDimensions : pdfFileSciWalker, '_blank')}>
                                                Open Manual
                                            </Button>

                                            <SeparatorPoint />

                                            <SavedSearchesLink
                                                allowedQueryTypes={[QUERY_TYPE_ADVANCED_SEARCH.id]}
                                                onSearchSelect={(savedSearch) => this.onSelectSavedSearch(savedSearch, true)}
                                            />
                                        </span>
                                    </label>
                                </div>

                                <SearchComponent
                                    ref={(el) => { this.searchComponent = el }}
                                    onActiveQueryTabIndexChange={(index) => { this.setState({ activeQueryTabIndex: { active: index } }) }}
                                    activeQueryTabIndex={this.state.activeQueryTabIndex}
                                    advancedQueries={this.state.advancedQueries}
                                    onQueryChange={this.handleQueryChange}
                                    onFilterModeChange={this.handleFilterModeChange}
                                    onAddFilterQuery={this.handleAddFilterQuery}
                                    onRemoveFilterQuery={this.handleRemoveFilterQuery}
                                    onOpenOntologyBrowser={this.onOpenExpertSearchOntologyBrowser}
                                    domains={this.props.availableDomains}
                                    resetQueryEditor={this.state.resetQueryEditor}
                                    onAddStructureToExpertSearch={() => { }}
                                    history={this.props.history}
                                    onClearAll={this.onClearAll}
                                />

                                <div className='col-12 borderPaddingLR' style={{ textAlign: 'right', paddingBottom: 0, paddingTop: 0 }}>
                                    <React.Fragment>
                                        <span style={{ display: 'inline-block', verticalAlign: '50%', marginRight: 30 }}>
                                            <Button
                                                className="p-button-text primaryButtonAsLink valignMiddle"
                                                title="Reset page"
                                                onClick={() => this.setState({ displayConfirmationDialog: true })}>
                                                Clear all
                                            </Button>

                                            <SeparatorPoint />

                                            <SaveSearchButtons
                                                savedSearchExists={!!this.state.savedSearchID}
                                                //editableQuery={this.docFinderData?.edit}
                                                editableQuery={this.state.editableQuery}
                                                onSaveSearch={(editQuery) => {
                                                    this.openSaveQueryDialog(editQuery, this.state.savedSearchID, this.state.savedSearchName);
                                                }}
                                                savedSearchName={this.state.savedSearchName}
                                            />
                                        </span>

                                        <Button label="Search"
                                            id="searchButton_expert"
                                            className='primaryButton p-button-sm'
                                            onClick={this.handleAdvancedSearch}
                                            style={{ marginRight: 0, verticalAlign: '50%' }} />
                                    </React.Fragment>
                                </div>
                            </LoadingOverlay>

                            <div id="repositoryOverview" className="col-12" style={{ marginBottom: 10, paddingTop: 0, paddingLeft: 10 }}>
                                {this.state.showRepositoryStatistics ?
                                    <RepositoryOverview
                                        headerText={`${this.state.repoStats && this.state.repoStats.hits ? addThousandsSeparatorToNumber(this.state.repoStats.hits) : 'No'} ${this.state.repoStats?.hits === 1 ? 'hit' : 'hits'}`}
                                        repoStatisticsResults={this.state.repoStats}
                                        fetching={this.state.fetchingNumOfHits}
                                        onRepositoryClick={this.handleRepositoryClick}
                                        selectedRepository={this.state.repositoryInfo.id}
                                        allowAddReadcubeLibrary={true}
                                        readcubeRequests={this.state.readcubeRequests}
                                        onAddReadcubeLibrary={this.onAddReadcubeLibrary}
                                        onDeleteReadcubeLibrary={this.onDeleteReadcubeLibrary}
                                        onSyncReadcubeLibrary={this.onSyncReadcubeLibrary}
                                    /> : null
                                }
                            </div>

                            {this.state.showResults && this.state.documents?.documentCount > 0 ?
                                <DocumentResultWrapper
                                    //isSemanticSearch={false}
                                    first={this.state.first}
                                    rows={this.state.rows}
                                    showResults={this.state.showResults}
                                    repositoryInfo={this.state.repositoryInfo}
                                    documents={this.state.documents}
                                    selectedDocuments={this.state.selectedDocuments}
                                    onDocumentSelectionChange={(selDocs) => { this.setState({ selectedDocuments: selDocs }) }}
                                    ratingReadOnly={true}
                                    onPageChange={this.handlePageChange}
                                    onExport={this.handleExport}
                                    sortFields={this.state.sortFields}
                                    sortCriteria={this.state.sortCriteria}
                                    onSortFieldChange={this.handleSortFieldChange}
                                    onSortModeChange={this.handleSortModeChange}
                                    query={queries.query}
                                    filterQueries={queries.filterQueries}
                                    hideSimilaritySearch={true}
                                    showGroupByPatentFamily={true}
                                    isPatentFamilyMutliple={true}
                                    groupByPatentFamily={this.state.groupByPatFamilies}
                                    patentFamilyGroupingValues={this.state.patentFamilyGroupingValues}
                                    onPatentFamilyGroupingChange={this.handleGroupByPatentFamilyChange}
                                    patentFamilyGrouping={this.state.patentFamilyGrouping}
                                    allowBigQueryExport={APP_PROPERTIES.CHEMISTRY.INCLUDE_BQ_COMPOUND_EXPORT}
                                    hasExportCenter={true}
                                    // only for full text bulk export
                                    history={this.props.history}
                                    userData={this.props.userData}
                                    // @todo internal query, filter queries etc. for highlighting in doc view
                                    //internalQuery={this.state.internalQuery}
                                    internalQuery={queries.query}
                                />
                                :
                                this.state.showResults ?
                                    <div className="col-12" style={{ padding: '30px 0' }}>No hits found in {this.state.repositoryInfo?.label}</div> : null
                            }

                            <SaveSearchDialog
                                displayDialogStoreQuery={this.state.displaySaveQueryDialog}
                                editQuery={this.state.editQuery}
                                queryString={this.state.queryString}
                                savedSearchID={this.state.savedSearchID}
                                allowAlerts={true}
                                onSubmit={this.onStoreQuery}
                                onHide={this.onHideStoreQuery}
                                userData={this.props.userData}
                            />

                            <OntologyBrowserDialog
                                ontBrowserDialogID="docSearchDomBrowserDlg"
                                headerLabel="Domain explorer"
                                selectionMode="multiple"
                                placeholder='Filter domain tree, e.g. try "liver" or "cancer" or leave empty to browse all domains'
                                placeholderMatches="Use input field to filter for a specific term or click an entry in the concept details view to see concepts matching your search."
                                domains={this.props.availableDomains}
                                numOfChildNodes={10} // not used for preloaded ontology
                                allowConceptSearchByClick={true}
                                allowSearchInOntologies={true}
                                loadOntologiesOnStart={true}
                                ontBrowserVisible={this.state.ontBrowserVisible}
                                onOntBrowserClose={this.onOntBrowserClose}
                                onOntBrowserSubmit={this.onOntBrowserSubmit}
                                onOntBrowserShow={() => { }}
                                width="90vw"
                                height="90vh"
                                initialSearchTerm={this.state.initialSearchTerm}
                            />

                            <ConfirmationDialog
                                displayDialog={this.state.displayConfirmationDialog}
                                onHide={() => this.setState({ displayConfirmationDialog: false })}
                                onSubmit={() => {
                                    this.onClearAll();
                                    this.setState({ displayConfirmationDialog: false });
                                }}
                                headerText="Confirm"
                                messageText="Clear all input?"
                                submitButtonLabel={"Clear"}
                            />
                            <AddReadcubeDialog
                                displayDialog={this.state.displayAddReadcubeDialog}
                                onHide={() => this.setState({ displayAddReadcubeDialog: false })}
                                onSubmitReadcubeLibrary={this.onSubmitReadcubeLibrary}
                                readcubeRequests={this.state.readcubeRequests}
                            >
                            </AddReadcubeDialog>
                        </div >
                    </div >
                </LoadingOverlay>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetchingAvailableDomains: state.webAPI.fetchingAvailableDomains,
    availableDomains: state.webAPI.availableDomains,
    currentTutorial: state.tutorialReducer.currentTutorial,
    /*repositories: sortObjectMapByAttribute(state.user?.data?.userDetails?.department.selectedRepositories.filter(
        repo => repo.active && repo.features?.includes(REPO_ROLE_DOC_SEARCH.id)
    ), 'orderPriority', false),*/
    allRepositories: sortObjectMapByAttribute(state.webAPI.availableRepositories?.filter(
        repo => repo.active && repo.features?.includes(REPO_ROLE_DOC_SEARCH.id)
    ), 'orderPriority', false)
})

const mapDispatchToProps = (dispatch) => ({
    onItemSelectedClicked: (value) => {
        dispatch(setQuickSearchSelectedTerms({ newTerms: value }))
    }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentFinder))