import { SEARCH_RESULT_TYPE_COMPOUNDS, SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE, SEARCH_RESULT_TYPE_REACTION_COMPOUNDS } from "./chemistryDBs";

export const GLOBAL_CHEMISTRY_DOWNLOAD_LIMIT = 1000000;

export const EXPORT_OPTION_ID_SELECTED = 'selected';
export const EXPORT_OPTION_ID_CURR_PAGE = 'currPage';
export const EXPORT_OPTION_ID_UP_TO_CURR_PAGE = 'upToCurrPage';
export const EXPORT_OPTION_ID_TOP500 = 'top500';
export const EXPORT_OPTION_ID_ALL = 'all';
export const EXPORT_OPTION_ID_MAX = 'max';

export const EXPORT_OPTION_BY_DOC_IDS = 'byDocIDs';
export const EXPORT_OPTION_BY_STRUCTURE = 'byStructure';

export const EXPORT_OPTIONS = [
    { id: EXPORT_OPTION_ID_SELECTED, label: 'Selected entries', type: EXPORT_OPTION_BY_DOC_IDS, multipleFileFormats: true },
    //{ id: EXPORT_OPTION_ID_CURR_PAGE, label: 'Current page', type: EXPORT_OPTION_BY_DOC_IDS },
    //{ id: EXPORT_OPTION_ID_UP_TO_CURR_PAGE, label: 'All up to last loaded page', type: EXPORT_OPTION_BY_DOC_IDS },
    //{ id: EXPORT_OPTION_ID_TOP500, label: 'Max. 500', max: 500, type: EXPORT_OPTION_BY_STRUCTURE },
    { id: EXPORT_OPTION_ID_ALL, label: 'Maximum available *', type: EXPORT_OPTION_BY_DOC_IDS, multipleFileFormats: true },
    { id: EXPORT_OPTION_ID_MAX, label: 'Maximum available (sent to My Research -> My Exports) *', max: GLOBAL_CHEMISTRY_DOWNLOAD_LIMIT, type: EXPORT_OPTION_BY_STRUCTURE },
];

export const EXPORT_FORMAT_SMI = 'smi';
export const EXPORT_FORMAT_SDF = 'sdf';

export const EXPORTER_FORMATS = [
    { id: EXPORT_FORMAT_SMI, label: 'SMI' },
    { id: EXPORT_FORMAT_SDF, label: 'SDF' },
];


export const determineAcitveExportOptions = (resultType, numOfSelectedElements, multipleQueryStructures) => {
    return EXPORT_OPTIONS.filter(expOpt => {
        return (expOpt.id === EXPORT_OPTION_ID_SELECTED && numOfSelectedElements > 0) ||
            (expOpt.id === EXPORT_OPTION_ID_ALL && resultType === SEARCH_RESULT_TYPE_COMPOUNDS_COMPLETE) ||
            (expOpt.id === EXPORT_OPTION_ID_MAX && !multipleQueryStructures &&
                (resultType === SEARCH_RESULT_TYPE_COMPOUNDS || resultType === SEARCH_RESULT_TYPE_REACTION_COMPOUNDS));
    })
}

/*
export const exportCompoundsToFile = async (exportOption, selectedCompoundOcidsMap, chemDBSearchResultEntries) => {
    let compoundOcids = null;
    switch (exportOption?.id) {
        case EXPORT_OPTION_ID_SELECTED: {
            compoundOcids = selectedCompoundOcidsMap && !isArrayEmpty(Object.keys(selectedCompoundOcidsMap)) ? [...Object.keys(this.state.selectedCompoundOcidsMap)] : [];
            break;
        }
        case EXPORT_OPTION_ID_ALL: {
            compoundOcids = chemDBSearchResultEntries?.map(entry => entry.ocid);
            break;
        }
        case EXPORT_OPTION_ID_MAX: {
            this.onSendToExportCenter(); // ??? return status/message
            return;
        }
    }
    if (compoundOcids) {
        fileFormat.id === EXPORT_FORMAT_SDF ? await exportCompoundsToSdfFile(compoundOcids) : await exportCompoundsToSmiFile(compoundOcids);
    }
} 
*/