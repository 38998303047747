import { useEffect, useRef, useState } from 'react';
import { DataView } from "primereact/dataview";
import BiomarkerSearchResultItem from "./BiomarkerSearchResultItem/BiomarkerSearchResultItem";
import ListHeader from "../../../../../components/common/ListHeader/ListHeader";
import { sortByIndex } from "../../../../common/helpers/sortByIndex";
import { createRepoAndIdMap } from "../../../../common/helpers/createRepoAndIdMap";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../../components/common/ListHeader/ListHeader.module.scss";
import CheckboxWithLabel from "../../CheckboxWithLabel/CheckboxWithLabel";
import {
    setBiomarkerFirstResultIndex, setBiomarkerResultCount,
    setBiomarkerCheckedResults, setBiomarkerSortDirection, setBiomarkerSortField
} from "../../../../../redux/actions/BiomarkerActions";
import {
    biomarkerCheckedResults, biomarkerFirstResultIndex,
    biomarkerMetaData, biomarkerNodes, biomarkerQueryTerms, biomarkerRelationResults,
    biomarkerReposWithBiomarkerSearchFeature, biomarkerResultCount,
    biomarkerSearchResults, biomarkerSelectedReposForSearch, biomarkerSortDirection, biomarkerSortField
} from "../../../../../redux/selectors/selectors"
import CustomPaginatorLeftSide from '../../../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../../../common/CustomPaginator/CustomPaginatorTemplate'
import { DocViewModal } from '../../../docview/common/DocviewModal/DocviewModal';
import UseDocview from '../../../docview/common/DocviewModal/UseDocview';
//import _ from 'lodash';

export const BiomarkerList = ({
    value,
    hitCount,
    isFetching,
    showToast, exportTopResults, exportSelectedResults
}) => {

    const {
        onShowDocviewModal,
        onCloseDocviewModal,
        isDocviewModalVisible,
        ocDocId,
        selectedDocument,
        repositoryId,
    } = UseDocview()

    const [headerDocuments, setHeaderDocuments] = useState({})
    const [hits, setHits] = useState(hitCount)
    const [rows, setRows] = useState(20)
    const reposWithBiomarkerSearchFeature = useSelector(biomarkerReposWithBiomarkerSearchFeature);
    const searchResults = useSelector(biomarkerSearchResults);
    const metaData = useSelector(biomarkerMetaData);
    const checkedResults = useSelector(biomarkerCheckedResults);
    const nodes = useSelector(biomarkerNodes);
    const sortField = useSelector(biomarkerSortField);
    const sortDirection = useSelector(biomarkerSortDirection);
    const firstResultIndex = useSelector(biomarkerFirstResultIndex);
    const resultCount = useSelector(biomarkerResultCount);
    const relationResults = useSelector(biomarkerRelationResults);
    const selectedRepos = useSelector(biomarkerSelectedReposForSearch);
    const queryTerms = useSelector(biomarkerQueryTerms);
    const ref = useRef();

    const selectedReposList = selectedRepos.map(({ label }) => label).join(', ');
    const allRelationIds = relationResults?.map(({ relationId }) => relationId);
    const dispatch = useDispatch();

    useEffect(() => {
        let temp = {}
        const page = (firstResultIndex / resultCount) + 1
        temp[`${page}`] = value.map((item) => ({ ...metaData.find(obj => obj.ocDocId === item.fields.doc_id.storedData), number: firstResultIndex + item.searchIndex + 1 }))
        if (!headerDocuments[page] || hitCount !== hits || resultCount !== rows) {
            if (hitCount !== hits || resultCount !== rows) {
                setHits(hitCount)
                setHeaderDocuments({ ...temp })
                setRows(resultCount)
            } else {
                setHeaderDocuments({ ...headerDocuments, ...temp })
                setRows(resultCount)

            }
        }
    }, [searchResults])


    const itemTemplate = (data, layout) => {
        const extractLabels = (data) => {
            // search available target headers from response
            const filterLeftAsideInitialHeaderConfigByKey = (key) => {
                return nodes.some((item) => item.name === key);
            }

            const labels = Object.keys(data.fields).filter(filterLeftAsideInitialHeaderConfigByKey);
            // search necessary data by keys
            let targetFields = [];
            for (let label of labels) {
                targetFields = [...targetFields, { [label]: data.fields[label] }];
            }
            return targetFields;
        }

        const targetFields = extractLabels(data);

        if (layout === 'list') {
            const sortedLabelList = targetFields.map(field => {
                const fieldKey = Object.keys(field).find(item => item);
                // add name to display and index to sort
                field = {
                    ...field[fieldKey],
                    label: nodes.filter(node => node.name === fieldKey).find(item => item)?.label,
                    index: nodes.filter(node => node.name === fieldKey).find(item => item)?.key,
                    fieldName: nodes.filter(node => node.name === fieldKey).find(item => item)?.name,
                };
                return field;
            }).sort(sortByIndex);
            const isChecked = checkedResults.includes(data.relationId);

            const repos = createRepoAndIdMap(reposWithBiomarkerSearchFeature);
            const repositoryInfo = repos[data.fields.srcrep.terms[0]];
            //console.log(metaData)
            //console.log(data)
            const documentData = metaData.find(item => item.ocDocId === data.fields.doc_id?.storedData);

            return (
                <BiomarkerSearchResultItem labelList={sortedLabelList}
                    isChecked={isChecked} data={data}
                    showToast={showToast}
                    repositoryInfo={repositoryInfo || {}}
                    documentData={documentData || {}}
                    queryTerms={queryTerms || []}
                    onShowDocviewModal={onShowDocviewModal}
                />
            );
        }

        if (layout === 'grid') {
            return (
                <p>Grid Content</p>
            );
        }
    }

    const onPage = (event, fromPaginator) => {
        // prevent sending http request when app is fetching data
        if (!isFetching) {
            dispatch(setBiomarkerResultCount(fromPaginator ? event.rows : event.value));
            dispatch(setBiomarkerFirstResultIndex(fromPaginator ? event.first : firstResultIndex));
        }

        //scrollToComponent(ref.current, { duration: 500 });
        window.scrollTo(0, ref.current.offsetTop)
    };

    const handleSelectAll = () => {
        const results = (function () {
            if (isSelectedAll) {
                return [];
            } else {
                return value.map(({ relationId }) => relationId);
            }
        }())
        dispatch(setBiomarkerCheckedResults(results));
    }

    const handleSortFieldChange = (event) => {
        dispatch(setBiomarkerFirstResultIndex(0));
        dispatch(setBiomarkerSortField(event.value));
    };

    const handleSortDirection = (event) => {
        dispatch(setBiomarkerFirstResultIndex(0));
        dispatch(setBiomarkerSortDirection(event.value));
    };

    const results = searchResults?.relationResults;
    const isSelectedAll = results?.length && results.every(({ relationId }) => checkedResults.includes(relationId));


    return (
        <>
            <ListHeader hitCount={hitCount}
                isSelectedAll={isSelectedAll} value={value} sortField={sortField}
                handleSortFieldChange={handleSortFieldChange}
                sortDirection={sortDirection} handleSortDirectionChange={handleSortDirection}
                exportTopResults={exportTopResults}
                exportSelectedResults={exportSelectedResults}
                checkedResults={checkedResults}
                nodes={nodes}
                allRelationIds={allRelationIds}
                ref={ref}
            />


            {hitCount ?
                <>
                    <div className={styles.selectAllContainer}>
                        <CheckboxWithLabel checked={isSelectedAll} handleCheckBox={handleSelectAll} inputId='selectAll'
                            labelText='Select all' disabled={!value.length} />
                    </div>
                    <DataView value={value} itemTemplate={itemTemplate} layout='list' paginator
                        paginatorPosition={'bottom'}
                        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                        paginatorTemplate={CustomPaginatorTemplate}
                        paginatorLeft={<CustomPaginatorLeftSide
                            first={firstResultIndex}
                            contentLength={hitCount}
                            rows={resultCount}>
                        </CustomPaginatorLeftSide>}
                        paginatorRight={<CustomPaginatorRightSide
                            rows={resultCount}
                            onChange={(e) => onPage(e, false)}
                        ></CustomPaginatorRightSide>}
                        rows={resultCount}
                        totalRecords={hitCount}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        onPage={(e) => onPage(e, true)}
                        alwaysShowPaginator={false}
                        first={firstResultIndex}
                        lazy
                        loading={isFetching}
                    />
                </> : <p>No documents found in {selectedReposList}.</p>}

            <DocViewModal
                onPageChange={onPage}
                first={firstResultIndex}
                rows={resultCount}
                documentCount={hitCount}
                isVisible={isDocviewModalVisible}
                docId={ocDocId}
                repId={repositoryId}
                documents={headerDocuments}
                selectedDoc={selectedDocument}
                onCloseDocviewModal={onCloseDocviewModal}
                isFunctionalCom={true}
            />

        </>
    );
};
