import { QuickSearchActions } from "../actions/QuickSearchActions";

const initialState = {
    quickSearchSelectedTerms: [],
    documentInfo: {}
};

const quickSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case QuickSearchActions.SetQuickSearchSelectedTerms: {
            let quickSearchSelectedTerms = action.payload?.newTerms ?? [];
            quickSearchSelectedTerms = quickSearchSelectedTerms?.length > 0 ? quickSearchSelectedTerms[0] : []
            if (action.payload.rewrite) {
                return {
                    ...state,
                    quickSearchSelectedTerms,
                };
            }
            return {
                ...state,
                quickSearchSelectedTerms
            };
        }
        case QuickSearchActions.SetDocumentTitle: {
            const { terms } = action.payload;
            return {
                ...state,
                documentInfo: { ...terms },
            };
        }
        default:
            return state;
    }
};

export default quickSearchReducer;