import React from 'react';
import {ImageWithTooltip} from "./ImageWithTooltip";
import styles from './StructureTemplate.module.scss';
import {ShowDetailsLink} from "./ShowDetailsLink";
import classNames from "classnames";

const StructureTemplate = ({structure, compound, ocid}) => {
    const classes = classNames(styles.fullImage, "p-column-title");

    return (
        <>
            <span className="p-column-title">
                Structure
            <ShowDetailsLink ocid={ocid}/>
            </span>
            <ImageWithTooltip structure={structure} compound={compound} ocid={ocid}/>

            <div className={classes}>
                <img className="p-column-title" src={structure} width="300px" alt='Structure scheme'/>
            </div>
        </>
    );
};

export default StructureTemplate;
