import { determineIdentifierType } from "../../../../api/content/ChemistryApi";
import { fetchMergedTermSuggestions } from "../../../../api/content/ConceptApi";
import { FREETEXT_EXACT, FREETEXT_WITH_VARIANTS } from "../../general/docsearch/searchConstants";
import { CHEMISTRY_IDENTIFIER_INCHI, CHEMISTRY_IDENTIFIER_INCHI_KEY, CHEMISTRY_IDENTIFIER_OCID, CHEMISTRY_IDENTIFIER_SMILES, CHEMISTRY_IDENTIFIER_UNKNOWN, CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND, CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX, CHEMISTRY_QUERY_TERM_TYPE_CONCEPT, CHEMISTRY_QUERY_TERM_TYPE_INCHI, CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY, CHEMISTRY_QUERY_TERM_TYPE_OCID, CHEMISTRY_QUERY_TERM_TYPE_SMILES, CHEMISTRY_QUERY_TERM_TYPE_UNKNOWN } from "./chemistrySearchConstants";
import { hasChemClassOcid, hasChemCompoundOcid, hasChemOcid, isChemClassOcid, isChemCompoundOcid } from "./compoundClassGroup";


export const addQueryTermTypesToSuggestions = (suggestions, termFragment) => {

    let terms = [];
    suggestions?.forEach(term => {

        const termWithType = {
            ...term,
            queryValues: [term.term],
        };

        // --- add type --- //
        if ((term.term === termFragment &&
            (term.domains?.includes(FREETEXT_EXACT.prefix) || term.domains?.includes(FREETEXT_WITH_VARIANTS.prefix)))
            || !hasChemOcid(term.ocids)) {
            termWithType.type = CHEMISTRY_QUERY_TERM_TYPE_UNKNOWN;
        }
        else {
            termWithType.type = CHEMISTRY_QUERY_TERM_TYPE_CONCEPT;
        }

        // --- add subtype -- //
        const subType = determineConceptSubtype(term.ocids);
        if (subType) {
            if (subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX) {
                terms = [...terms, ...separateCompoundAndClassConcept(termWithType)];
            }
            else {
                termWithType.subType = subType;
                terms.push(termWithType);
            }
        }
        else {
            terms.push(termWithType);
        }
    });
    return terms;
}

const separateCompoundAndClassConcept = (queryTerm) => {

    const { prefnames, otherTerms, ...rest } = queryTerm;

    return [{
        ...rest,
        subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND,
        ocids: queryTerm.ocids.filter(ocid => isChemCompoundOcid(ocid))
    }, {
        ...rest,
        subType: CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS,
        ocids: queryTerm.ocids.filter(ocid => isChemClassOcid(ocid))
    }];
}


export const addQueryTermTypesToQueryTerms = async (queryTerms, domains, filterRangeCompounds, filterRangeClasses) => {

    let queryTermsNew = [];
    if (queryTerms) {
        let matchedTerms = [];
        for (let qt of queryTerms) {
            // --- check for direct matches with complete terms to get all OCIDs --- //
            if (qt.type === CHEMISTRY_QUERY_TERM_TYPE_CONCEPT && !qt.fetchedDirectMatch) {
                //console.log('check queryTerm again: ', qt)
                const filterRange = qt.subType === CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND ? filterRangeCompounds : filterRangeClasses;
                const response = await fetchMergedTermSuggestions(qt.term, domains, null, filterRange, false, false, false); // false
                if (response?.status === 'SUCCESS') {
                    const result = response.payload;
                    //console.log('result: ', result);
                    const term = result[0]?.term;
                    if (term === qt.term) {
                        const matchedTerm = {
                            ...result[0],
                            type: CHEMISTRY_QUERY_TERM_TYPE_CONCEPT,
                            subType: qt.subType,
                            queryValues: [term],
                            fetchedDirectMatch: true
                        };
                        /*const subType = determineConceptSubtype(qt.ocids);
                        if (subType) {
                            matchedTerm.subType = subType;
                        }*/
                        matchedTerms.push(matchedTerm);
                    }
                    else {
                        // no direct match -> no OCID?
                        qt.fetchedDirectMatch = true;
                        matchedTerms.push(qt);
                    }
                }
            }
            else {
                matchedTerms.push(qt);
            }
        }
        queryTermsNew = await determineIdentifierTypes(matchedTerms);
    }
    return queryTermsNew;
}


export const determineConceptSubtype = (ocids) => {

    if (ocids) {
        const hasCompoundOcid = hasChemCompoundOcid(ocids);
        const hasClassOcid = hasChemClassOcid(ocids);
        if (hasCompoundOcid && hasClassOcid) {
            return CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND_CLASS_MIX;
        }
        else if (hasCompoundOcid) {
            return CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND;
        }
        else if (hasClassOcid) {
            return CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS;
        }
    }
}

export const determineIdentifierTypes = async (queryTerms) => {
    for (let qt of queryTerms) {
        // TODO: only if undefined
        if (qt.type === CHEMISTRY_IDENTIFIER_UNKNOWN) {
            const identifierType = determineIdentifierTypeForTerm(qt.term);
            if (identifierType.type) {
                qt.type = identifierType.type;
            }
            if (identifierType.subType) {
                qt.subType = identifierType.subType;
            }
        }
    }
    return queryTerms;
}

export const determineIdentifierTypeForTerm = async (term) => {
    const result = await determineIdentifierType(term);
    const identifierType = {};
    switch (result?.payload) {
        case CHEMISTRY_IDENTIFIER_INCHI:
            identifierType.type = CHEMISTRY_QUERY_TERM_TYPE_INCHI;
            break;
        case CHEMISTRY_IDENTIFIER_INCHI_KEY:
            identifierType.type = CHEMISTRY_QUERY_TERM_TYPE_INCHI_KEY;
            break;
        case CHEMISTRY_IDENTIFIER_OCID:
            if (isChemCompoundOcid(term)) {
                identifierType.type = CHEMISTRY_QUERY_TERM_TYPE_OCID;
                identifierType.subType = CHEMISTRY_QUERY_TERM_TYPE_CHEM_COMPOUND;
            }
            else if (isChemClassOcid(term)) {
                identifierType.type = CHEMISTRY_QUERY_TERM_TYPE_OCID;
                identifierType.subType = CHEMISTRY_QUERY_TERM_TYPE_CHEM_CLASS;
            }
            break;
        case CHEMISTRY_IDENTIFIER_SMILES:
            identifierType.type = CHEMISTRY_QUERY_TERM_TYPE_SMILES;
            break;
        default: // do nothing
    }
    return identifierType;
}

/*export const highlightSuggestion = (term, termFragment) => {

    let termFormatted = term;
    const match = findMatchIgnoreCase(term, termFragment);
    if (match) {
        termFormatted = termFormatted.replace(match, `<span style="font-weight: normal">${match}</span>`);
    }
}*/