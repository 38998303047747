export const FilterActions = {
    FilterDefinitions: 'FILTER_DEFINITIONS',
    RepositoryFilterMap: 'REPOSITORY_FILTER_MAP',
};


export const setFilterDefinitions = (filterDefinitions) => ({
    type: FilterActions.FilterDefinitions,
    payload: filterDefinitions,
});

export const setRepositoryFilterMap = (repositoryFilterMap) => ({
    type: FilterActions.RepositoryFilterMap,
    payload: repositoryFilterMap,
});