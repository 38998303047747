import "./SearchGroupEdit.css";
import SearchGroupCriteriaEditor from "./SearchGroupCriteriaEditor/SearchGroupCriteriaEditorContainer";
import SearchGroupToolbar from "./SearchGroupToolbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TermGroupEdit from "./groupTypes/term/TermGroupEdit";
import LogicGroupEdit from "./groupTypes/logic/LogicGroupEdit";
import {
  LOGICAL_OPERATORS_AND_OR,
  LOGICAL_OPERATORS_AND_OR_NOT,
  SEARCH_CRITERIA,
  SEARCH_GROUP_TYPE_API_SYNTAX,
  SEARCH_GROUP_TYPE_FILTER,
  SEARCH_GROUP_TYPE_LOGIC,
  SEARCH_GROUP_TYPE_SAVED_SEARCH,
  SEARCH_GROUP_TYPE_TERM,
} from "../../../../helpers/constants";
import FilterGroupEdit from "./groupTypes/FilterGroupEdit/FilterGroupEdit";
import {
  createPartialQueryString,
  createQueryString,
} from "../../../../helpers/queryConverter";
import { createSearchGroup } from "../../../../helpers/advancedSearch";
import TranslationDialog from "../../../../../../common/queryBuilder/searchForm/searchGroup/TranslationDialog";
import SaveSearchDialog from "../../../../../../common/SaveSearch/SaveSearchDialog";
import { checkResultAndGetPayload } from "../../../../../../../api";
import {
  extractSearchGroups,
  storeSearch,
} from "../../../../helpers/savedSearches";
import { Toast } from "primereact/toast";
import {
  checkForErrors,
  filterReachableGroupIDs,
  filterReachableGroups,
} from "../../../../helpers/checks";
import ResultsStatistics from "../statistics/ResultsStatistics";
import SavedSearchGroupEdit from "./groupTypes/savedSearch/SavedSearchGroupEdit";
import APISyntaxGroupEdit from "../../../../../../common/queryBuilder/searchForm/searchGroup/editMode/groupTypes/apiSyntax/APISyntaxGroupEdit";
import {
  editSearchGroup,
  fetchNumberOfResultsForSearchGroupsPreview,
  setAdvancedSearch3FormContent,
  setSearchGroupToEdit,
  setSearchGroupsErrorsPreview,
  setSearchGroupsNumberOfResultsPreview,
  setSearchGroupsReachable,
  setSelectedSearchGroups,
} from "../../../../../../../redux/features/queryBuilder/actions";
import { filterDefinitionsSelector } from "../../../../../../../redux/common/filters/selectors/filters";

const SearchGroupEditor = ({
  groupToEdit,
  onRunPartialSearch,
  onRemoveSearchGroup,
  hasError,
}) => {
  const growl = useRef(null);

  const searchGroups = useSelector(
    (state) => state.advancedSearch3Reducer.searchGroups
  );
  const previewRepository = useSelector(
    (state) => state.advancedSearch3Reducer.previewRepository
  );
  const queryStringsPrevious = useSelector(
    (state) => state.advancedSearch3Reducer.searchGroupsQueryStrings
  );
  const numberOfResultsPrevious = useSelector(
    (state) => state.advancedSearch3Reducer.numberOfResults
  );
  const filterDefinitions = useSelector(filterDefinitionsSelector);
  const userData = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const [groupToEditUpdated, setGroupToEditUpdated] = useState(groupToEdit);

  const [displaySaveSearchDialog, setDisplaySaveSearchDialog] = useState();
  const [qry, setQry] = useState("");
  const [partialSearchGroups, setPartialSearchGroups] = useState([]);

  const [displayTranslationDialog, setDisplayTranslationDialog] = useState();
  const [translation, setTranslation] = useState("");

  // TODO: @Anett check if this is correct
  useEffect(() => {
    setGroupToEditUpdated(groupToEdit);
  }, [groupToEdit?.id]);

  useEffect(() => {
    dispatch(setSearchGroupsReachable([]));
    dispatch(setSearchGroupsNumberOfResultsPreview(null));

    const searchGroupsPreview = searchGroups.map((searchGroup) => {
      return searchGroup.id === groupToEditUpdated.id
        ? groupToEditUpdated
        : searchGroup;
    });
    // === check for errors - if forward references are found -> cancel === //
    const errorMessages = checkForErrors(searchGroupsPreview);
    dispatch(setSearchGroupsErrorsPreview(errorMessages));
    if (errorMessages.hasForwardReferences) {
      return;
    }
    // === check which groups are reachable === //
    let reachable = filterReachableGroupIDs(searchGroupsPreview) || [];
    dispatch(setSearchGroupsReachable(reachable));
    // === fetch number of results === //
    dispatch(
      fetchNumberOfResultsForSearchGroupsPreview(
        searchGroupsPreview,
        filterDefinitions,
        previewRepository,
        numberOfResultsPrevious,
        queryStringsPrevious
      )
    );
    // TODO: missing dependencies?
  }, [groupToEditUpdated]);

  const handleSearchGroupCriteriaChange = (operator, searchCriteria) => {
    const groupToEditUpdatedNew = { ...groupToEditUpdated };
    groupToEditUpdatedNew.operator = operator;
    groupToEditUpdatedNew.searchCriteria = searchCriteria || {};
    setGroupToEditUpdated(groupToEditUpdatedNew);
  };

  const handleQueryTermsChange = (queryTerms = []) => {
    const groupToEditUpdatedNew = { ...groupToEditUpdated };
    groupToEditUpdatedNew.queryTerms = queryTerms;
    setGroupToEditUpdated(groupToEditUpdatedNew);
  };

  const handleGroupReferencesChange = (operator, groupRefs = []) => {
    const groupToEditUpdatedNew = { ...groupToEditUpdated };
    groupToEditUpdatedNew.operator = operator;
    groupToEditUpdatedNew.searchGroupIDRefs = groupRefs;
    setGroupToEditUpdated(groupToEditUpdatedNew);
  };

  const handleSavedSearchReferenceChange = (savedSearchData) => {
    const groupToEditUpdatedNew = { ...groupToEditUpdated };
    groupToEditUpdatedNew.savedSearchID = savedSearchData.id;
    groupToEditUpdatedNew.savedSearchData = savedSearchData;
    setGroupToEditUpdated(groupToEditUpdatedNew);
  };

  const handleQueryStringChange = (queryString) => {
    const groupToEditUpdatedNew = { ...groupToEditUpdated };
    groupToEditUpdatedNew.queryString = queryString;
    setGroupToEditUpdated(groupToEditUpdatedNew);
  };

  const handleExpandSavedSearch = (savedSearchData) => {
    const searchGroupsExpanded = extractSearchGroups(savedSearchData);
    const idx = searchGroups.findIndex((sg) => sg.id === groupToEdit.id);
    if (searchGroupsExpanded && idx >= 0) {
      const searchGroupsNew = [...searchGroups];
      searchGroupsNew.splice(idx, 1, ...searchGroupsExpanded);
      dispatch(setAdvancedSearch3FormContent(searchGroupsNew));
      dispatch(setSearchGroupToEdit(null));
    }
  };

  // === toolbar actions === //

  const handleSubmitEditSearchGroup = () => {
    dispatch(setSearchGroupsNumberOfResultsPreview(null));
    dispatch(setSearchGroupsErrorsPreview(null));
    dispatch(setSelectedSearchGroups([]));
    dispatch(editSearchGroup(groupToEditUpdated));
    dispatch(setSearchGroupToEdit(null));
  };

  const handleCancelEditSearchGroup = () => {
    dispatch(setSearchGroupsNumberOfResultsPreview(null));
    dispatch(setSearchGroupsErrorsPreview(null));
    dispatch(setSelectedSearchGroups([]));
    dispatch(setSearchGroupToEdit(null));
  };

  const handleClearSearchGroup = () => {
    const groupToEditNew = createSearchGroup(
      groupToEdit.type,
      filterDefinitions
    );
    const groupToEditUpdatedUpdatedNew = {
      ...groupToEditNew,
      id: groupToEdit.id,
    };
    setGroupToEditUpdated(groupToEditUpdatedUpdatedNew);
  };

  const handleTranslate = async () => {
    const queryString = await createPartialQueryString(
      groupToEditUpdated,
      searchGroups,
      filterDefinitions,
      false
    );
    setTranslation(queryString);
    setDisplayTranslationDialog(true);
  };

  const handleRunPartialSearch = async (group, includeFilters) => {
    const queryString = await createPartialQueryString(
      group,
      searchGroups,
      filterDefinitions,
      includeFilters
    );
    onRunPartialSearch(queryString);
  };

  const handleSavePartialSearch = async () => {
    const partSearchGroups = filterReachableGroups(searchGroups, groupToEdit);
    const partQueryString = await createQueryString(
      partSearchGroups,
      filterDefinitions
    );
    setPartialSearchGroups(partSearchGroups);
    setQry(partQueryString);
    setDisplaySaveSearchDialog(true);
  };

  const handleStoreSearch = async (
    queryString,
    queryName,
    queryFullName,
    queryDescription,
    watchlistIDs,
    addAlert,
    alertID,
    alertActive,
    alertInterval,
    alertRepositories
  ) => {
    // TODO: clean up ???
    const queryData = {
      searchGroups: partialSearchGroups,
      queryString,
      queryName,
      queryFullName,
      queryDescription,
    };
    const alertData = {
      addAlert,
      alertID,
      alertActive,
      alertInterval,
      alertRepositories,
    };
    const response = await storeSearch(
      null,
      false,
      queryData,
      watchlistIDs,
      alertData
    );

    const result = checkResultAndGetPayload(
      response,
      growl,
      "Success",
      "Search successfully stored."
    );
    if (result) {
      setDisplaySaveSearchDialog(false);
    }
  };

  const renderCriteria = () => {
    switch (groupToEdit?.type) {
      case SEARCH_GROUP_TYPE_TERM: {
        return (
          <div className="search-group-edit-info-container">
            <SearchGroupCriteriaEditor
              logicalOperators={LOGICAL_OPERATORS_AND_OR}
              logicalOperator={groupToEditUpdated.operator}
              searchCriteria={groupToEditUpdated.searchCriteria}
              searchCriteriaFilters={SEARCH_CRITERIA.filters}
              onValuesChange={handleSearchGroupCriteriaChange}
            />
          </div>
        );
      }

      case SEARCH_GROUP_TYPE_LOGIC: {
        return (
          <div className="search-group-edit-info-container">
            <SearchGroupCriteriaEditor
              logicalOperators={LOGICAL_OPERATORS_AND_OR_NOT}
              logicalOperator={groupToEditUpdated.operator}
              searchCriteria={groupToEditUpdated.searchCriteria}
              searchCriteriaFilters={SEARCH_CRITERIA.filters}
              onValuesChange={handleSearchGroupCriteriaChange}
            />
          </div>
        );
      }

      case SEARCH_GROUP_TYPE_FILTER: {
        return (
          <div className="search-group-edit-info-container">
            <SearchGroupCriteriaEditor
              logicalOperators={LOGICAL_OPERATORS_AND_OR}
              logicalOperator={groupToEditUpdated.operator}
              searchCriteria={groupToEditUpdated.searchCriteria}
              searchCriteriaFilters={SEARCH_CRITERIA.filters}
              onValuesChange={handleSearchGroupCriteriaChange}
              hideSearchCriteria={true}
            />
          </div>
        );
      }

      case SEARCH_GROUP_TYPE_SAVED_SEARCH: {
        return (
          <div className="search-group-edit-info-container">
            Saved search reference
            <span className="secondaryInfo ml-3">
              Note: only original search is editable
            </span>
          </div>
        );
      }

      case SEARCH_GROUP_TYPE_API_SYNTAX: {
        return (
          <div className="search-group-edit-info-container">
            API syntax string
            <span className="secondaryInfo ml-3">
              Note: Try to avoid using API syntax directly. Rather use term,
              logic, metadata groups etc.
            </span>
          </div>
        );
      }

      default:
        return <div></div>;
    }
  };

  const renderContent = () => {
    switch (groupToEdit?.type) {
      case SEARCH_GROUP_TYPE_TERM: {
        return (
          <TermGroupEdit
            queryTerms={groupToEditUpdated.queryTerms}
            onValuesChange={handleQueryTermsChange}
          />
        );
      }

      case SEARCH_GROUP_TYPE_LOGIC: {
        return (
          <LogicGroupEdit
            logicalOperator={groupToEditUpdated.operator}
            groupReferences={groupToEditUpdated.searchGroupIDRefs}
            onValuesChange={handleGroupReferencesChange}
          />
        );
      }

      case SEARCH_GROUP_TYPE_FILTER: {
        return (
          <FilterGroupEdit
            filterTerms={groupToEditUpdated.queryTerms}
            onValuesChange={handleQueryTermsChange}
          />
        );
      }

      case SEARCH_GROUP_TYPE_SAVED_SEARCH: {
        return (
          <SavedSearchGroupEdit
            savedSearchData={groupToEditUpdated.savedSearchData}
            onValuesChange={handleSavedSearchReferenceChange}
            onExpandSavedSearch={handleExpandSavedSearch}
          />
        );
      }

      case SEARCH_GROUP_TYPE_API_SYNTAX: {
        return (
          <APISyntaxGroupEdit
            queryString={groupToEditUpdated.queryString}
            onValuesChange={handleQueryStringChange}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <>
      <Toast ref={growl} />

      <div className="w-full">
        <div className="flex justify-content-between flex-wrap-reverse">
          {renderCriteria()}
          <div className="search-group-edit-toolbar-container">
            <SearchGroupToolbar
              group={groupToEditUpdated}
              onSubmitEditSearchGroup={handleSubmitEditSearchGroup}
              onCancelEditSearchGroup={handleCancelEditSearchGroup}
              onClearSearchGroup={handleClearSearchGroup}
              onRemoveSearchGroup={onRemoveSearchGroup}
              onTranslate={handleTranslate}
              onRunPartialSearch={handleRunPartialSearch}
              onSavePartialSearch={handleSavePartialSearch}
              hasError={hasError}
            />
          </div>
        </div>

        <div className="flex justify-content-between">
          {renderContent()}

          <ResultsStatistics
            groupID={groupToEdit.id}
            isEditGroup={!!groupToEdit}
          />
        </div>
      </div>

      <TranslationDialog
        show={displayTranslationDialog}
        onHide={() => setDisplayTranslationDialog(false)}
        translation={translation}
      />

      <SaveSearchDialog
        displayDialogStoreQuery={displaySaveSearchDialog}
        queryString={qry}
        // allowAlerts={true}
        onSubmit={handleStoreSearch}
        onHide={() => setDisplaySaveSearchDialog(false)}
        userData={userData}
      />
    </>
  );
};

export default SearchGroupEditor;
