import loginBGImage from '../../assets/images/bg/shutterstock_163813940_cut_min_2.jpg';
import appLogo from '../../assets/images/logos/ontochem/ontochem-logo-negativ-RGB-weiss.png';

export const APP_PROPERTIES = {
  // --- logos for login page and header --- //
  LOGIN_BG_IMAGE: loginBGImage,

  HEADER_LOGO_APP: appLogo,
  HEADER_LOGO_APP_STYLE: { height: 40, padding: '5px 15px' },

  // --- feedback and support --- //
  SUPPORT_FORM_URL:
    'https://forms.monday.com/forms/312f55bdb5829b7c7f342002021f19b7',

  // --- active pages --- //
  ACTIVE_PAGES: {
    docSearch: true,
    verificationPage: true,
    backendAdministration: true,
  },

  // --- active modules or functionalities --- //
  ACTIVE_FUNCTIONALITIES: {
    showDocumentsOnStart: true,
    fetchSortableFields: true,
    showClinicalTrialFilters: true,
    showGrantsDSFilters: true,
    showPublicationsDSFilters: true,
  },

  // --- chemistry-related settings --- //
  CHEMISTRY: {
    showGoogleLinks: true,
  },
} as const;
