
export const isOcid = (term) => {
    return term?.match(/[1-9][0-9]{11}/);
}


export const isOcidWithinFilterRanges = (ocid, rangeOcids) => {
    if (!ocid) {
        return false;
    }
    if (rangeOcids?.start && rangeOcids?.end) {
        if (rangeOcids.exclude) {
            return !(ocid >= rangeOcids.start && ocid <= rangeOcids.end);
        }
        else {
            return (ocid >= rangeOcids.start && ocid <= rangeOcids.end);
        }
    }
    return true;
}

export const isConceptWithinDomainFilters = (concept, filterDomains) => {
    return (!!concept && ((concept.known !== false && !filterDomains) || filterDomains.includes(concept.domain)));
}

export const filterChildrenAndParents = (concept, rangeOcids) => {
    if (concept && rangeOcids) {
        if (concept.children) {
            concept.children = concept.children.filter(child => isOcidWithinFilterRanges(child.ocid, rangeOcids));
        }
        if (concept.parents) {
            concept.parents = concept.parents.filter(parent => isOcidWithinFilterRanges(parent.ocid, rangeOcids));
        }
    }
    return concept;
}