import { SentenceAnalysisActions } from "../actions/SentenceAnalysisActions";
import $ from 'jquery';

const initialState = {
    sentences: $('<div></div>'),
    selectedSentenceID: "",
    documentSentences: [],
    threshold: 1,
    sentenceOption: 'all',
    activeSearchTerms: [],
    coords: [],
    loading: false,
    isPDF: false,
    showSentenceEntities: false,
    documents: [],
    selectedMetadataOption: 'compact',
    sectionOptions: [],
    selectedSections: { filterValueLabel: 'Whole document', filterValue: 'all' },
    sortFields: [],
    selectedSortField: null,
    selectedSortDirection: null,
    sortDirectionDisabled: false,
    familyDocuments: [],
    familyDocumentsRemove: [],
    searchLoaded: false,
    docHTML: ''
};

const sentenceAnalysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case SentenceAnalysisActions.SetSentenceAnalysisSentences: {
            let sentences = action.payload
            return {
                ...state,
                sentences
            };
        }
        case SentenceAnalysisActions.SetSelectedSentenceAnalysis: {
            let selectedSentenceID = action.payload
            return {
                ...state,
                selectedSentenceID
            };
        }
        case SentenceAnalysisActions.SetDocumentSentencesSentenceAnalysis: {
            let documentSentences = action.payload
            return {
                ...state,
                documentSentences
            };
        }
        case SentenceAnalysisActions.SetQueryTermsThresholdSentenceAnalysis: {
            let threshold = action.payload
            return {
                ...state,
                threshold
            };
        }
        case SentenceAnalysisActions.SetSentenceOptionSentenceAnalysis: {
            let sentenceOption = action.payload
            return {
                ...state,
                sentenceOption
            };
        }
        case SentenceAnalysisActions.SetActiveSearchTermsSentenceAnalysis: {
            let activeSearchTerms = action.payload
            return {
                ...state,
                activeSearchTerms
            };
        } case SentenceAnalysisActions.SetAnnotationCoordinatesSentenceAnalysis: {
            let coords = action.payload
            return {
                ...state,
                coords
            };
        } case SentenceAnalysisActions.SetDocumentHTMLSentenceAnalysis: {
            let docHTML = action.payload
            return {
                ...state,
                docHTML
            };
        }
        case SentenceAnalysisActions.SetLoaderSentenceAnalysis: {
            let loading = action.payload
            return {
                ...state,
                loading
            };
        }
        case SentenceAnalysisActions.SetIsPDFSentenceAnalysis: {
            let isPDF = action.payload
            return {
                ...state,
                isPDF
            };
        }
        case SentenceAnalysisActions.SetShowSentenceEntitiesSentenceAnalysis: {
            let showSentenceEntities = action.payload
            return {
                ...state,
                showSentenceEntities
            };
        }
        case SentenceAnalysisActions.SetSelectedDocumentsSentenceAnalysis: {
            let documents = action.payload
            return {
                ...state,
                documents
            };
        }
        case SentenceAnalysisActions.SetSelectedMetadataOptionSentenceAnalysis: {
            let selectedMetadataOption = action.payload
            return {
                ...state,
                selectedMetadataOption
            };
        }
        case SentenceAnalysisActions.SetSectionOptionsSentenceAnalysis: {
            let sectionOptions = action.payload
            return {
                ...state,
                sectionOptions
            };
        }
        case SentenceAnalysisActions.SetSelectedSectionsSentenceAnalysis: {
            let selectedSections = action.payload
            return {
                ...state,
                selectedSections
            };
        }
        case SentenceAnalysisActions.SetSortFieldsSentenceAnalysis: {
            let sortFields = action.payload
            return {
                ...state,
                sortFields
            };
        }
        case SentenceAnalysisActions.SetSelectedSortFieldSentenceAnalysis: {
            let selectedSortField = action.payload
            return {
                ...state,
                selectedSortField
            };
        }
        case SentenceAnalysisActions.SetSelectedSortDirectionSentenceAnalysis: {
            let selectedSortDirection = action.payload
            return {
                ...state,
                selectedSortDirection
            };
        }
        case SentenceAnalysisActions.SetSortDirectionDisabledSentenceAnalysis: {
            let sortDirectionDisabled = action.payload
            return {
                ...state,
                sortDirectionDisabled
            };
        }
        case SentenceAnalysisActions.SetSelectedFamilyDocumentsSentenceAnalysis: {
            let familyDocuments = action.payload
            return {
                ...state,
                familyDocuments
            };
        }
        case SentenceAnalysisActions.SetSelectedFamilyDocumentsRemoveSentenceAnalysis: {
            let familyDocumentsRemove = action.payload
            return {
                ...state,
                familyDocumentsRemove
            };
        }
        case SentenceAnalysisActions.SetSearchLoadedSentenceAnalysis: {
            let searchLoaded = action.payload
            return {
                ...state,
                searchLoaded
            };
        }
        default:
            return state;
    }
};

export default sentenceAnalysisReducer;