export const addOrRemove = (array, value) => {
    const resultArray = [...array];
    const index = resultArray.indexOf(value);

    if (index === -1) {
        resultArray.push(value);
    } else {
        resultArray.splice(index, 1);
    }
    return resultArray;
};
