export const truncateList = (list, maxNumOfTruncatedEntries) => {
    if (list) {
        const numOfEntries = list.length;
        const maxTruncatedLength = numOfEntries < maxNumOfTruncatedEntries ? numOfEntries : maxNumOfTruncatedEntries;
        const listTruncated = list.slice(0, maxTruncatedLength);

        return listTruncated;
    }
    return list;
}

export const addMculeDataToConceptData = (conceptData, mculeUrl) => {

    if (conceptData && mculeUrl) {
        if (!conceptData.sourceReferences) {
            conceptData.sourceReferences = [];
        }
        const mculeLink = {
            sourceName: 'Mcule',
            sourceUrl: 'https://mcule.com/',
            conceptUrl: mculeUrl,
            conceptRefId: mculeUrl.replace('https://mcule.com/', '').replace(/\/$/, '')
        };
        conceptData.sourceReferences.push(mculeLink);
        conceptData.extendedData.mculeLink = mculeLink;
    }

    return conceptData;
}