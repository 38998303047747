/*import {
    APP_PROPERTIES, QUERY_CATEGORY_CHEM_SEARCH, QUERY_CATEGORY_COOC_SEARCH, QUERY_CATEGORY_DOC_SEARCH, QUERY_FORM_CORRELATIONS,
    QUERY_TYPE_CHEM_SEARCH, QUERY_TYPE_COOC_SEARCH, QUERY_TYPE_QUICK_SEARCH_2
} from '../../../properties';*/
import {
    APP_PROPERTIES, QUERY_CATEGORY_DOC_SEARCH,
    QUERY_TYPE_CHEM_SEARCH, QUERY_TYPE_QUICK_SEARCH_2
} from '../../../properties';
import { createChemistrySearchObjectFromConcept } from '../chemistry/util';
//import { CHEM_DB_ITEMS } from '../chemistry/utils/chemistryDBs';
//import { createQuickSearchObjectFromConcepts, createQuickSearchObjectFromConceptsWithTermloc } from '../docsearch/utils/searchUtil';
import { createQuickSearchObjectFromConcepts } from '../general/docsearch/searchUtil';
//import { hasUserRole } from '../util';
import { addDataToLocalStorage } from './localStorage';
import { createChemSearchRequestFromConceptData, createChemSearchRequestFromSmiles } from './shortcuts';


export const createShortcutDataItemForSingleOcid = (ocid, domain, name) => {
    let concepts = ocid ? { [ocid]: { ocid, domain, preferredName: name } } : {};
    return createQuickSearchObjectFromConcepts(concepts);
    //return { ocid, domain, name };
}

/*
export const createShortcutDataItemForMultipleOcids = (ocids, domain, name) => {
    let concepts = {};
    ocids?.forEach(ocid => {
        concepts[ocid] = { ocid, domain, preferredName: ocid }; 
    });
    return createQuickSearchObjectFromConcepts(concepts);
    //return { ocids, domain, name };
}
*/

export const createShortcutDataItemForMultipleConcepts = (concepts) => {
    //console.log('concepts: ', concepts);
    return createQuickSearchObjectFromConcepts(concepts);
}

export const createChemSearchShortcutDataItemForOcid = (ocid, domain, name, searchTargetOption = null, searchType = null) => {
    const request = createChemSearchRequestFromConceptData(ocid, domain, name, searchTargetOption, searchType);
    return createChemistrySearchObjectFromConcept(request);
}

export const createChemSearchShortcutDataItemForSmiles = (smiles, searchTargetOption = null, searchType = null) => {
    const request = createChemSearchRequestFromSmiles(smiles, searchTargetOption, searchType);
    return createChemistrySearchObjectFromConcept(request);
}


export const createShortcutMenuItems = (inclQuickSearch, inclCoocSearch, inclChemSearch, dataItem, repositoryInfo, openInNewTab = true) => {

    if (!dataItem) {
        return []; // null???
    }

    const items = [];

    if (inclQuickSearch) {
        const shortcutQuickSearch = {
            label: QUERY_CATEGORY_DOC_SEARCH.label,
            command: () => {
                executeQuickSearchShortcut(dataItem, repositoryInfo, openInNewTab);
            }
        }
        items.push(shortcutQuickSearch);
    }

    /*
    if (inclCoocSearch && hasUserRole("ROLE_COOCS")) {
        const shortcutCoocs = {
            label: QUERY_CATEGORY_COOC_SEARCH.label,
            command: () => {
                const req = createCoocSearchRequestFromConceptData(dataItem);
                executeCoocShortcut(req, openInNewTab);
            }
        }
        items.push(shortcutCoocs);
    }

    if (inclChemSearch && hasUserRole("ROLE_COMPOUNDSEARCH")) {
        const shortcutChem = {
            label: QUERY_CATEGORY_CHEM_SEARCH.label,
            command: () => {
                const req = createChemSearchRequestFromConceptData(dataItem);
                executeChemSearchShortcut(req, openInNewTab);
            }
        }
        items.push(shortcutChem);
    }
    */

    return items;
}

/*
export const createQuickSearchRequestFromOcids = (ocids, name) => {
    const request = {
        ocid: ocid,
        domain: domain,
        name: name,
        termloc: termloc,
        termlocLabel: termlocLabel
    };
    return request;
}



export const createCoocSearchRequestFromConceptData = (ocid, domain, name) => {
    const request = {
        ocid: ocid,
        domain: domain,
        name: name
    };
    return request;
}


export const createChemSearchRequestFromConceptData = (ocid, domain, name, searchTargetOption = null, searchType = null) => {
    const request = {
        ocid: ocid,
        domain: domain,
        name: name,
        searchTargetOption: searchTargetOption,
        searchType: searchType
    };
    return request;
}

export const createChemSearchRequestFromSmiles = (smiles, searchTargetOption = null, searchType = null) => {
    const request = {
        smiles: smiles,
        searchTargetOption: searchTargetOption,
        searchType: searchType
    };
    return request;
}
*/


export const executeQuickSearchShortcut = (dataItem, repository, openInNewTab = true) => {
    addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, { data: { ...dataItem, repositoryInfo: repository } });
    window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, openInNewTab ? '_blank' : undefined);
}

/*
export const executeQuickSearchWithTermlocShortcut = (request, repositoryInfo, openInNewTab = true) => {
    let concepts = request?.ocid ? { [request.ocid]: { ocid: request.ocid, domain: request.domain, preferredName: request.name } } : {}
    addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, {
        data: { ...createQuickSearchObjectFromConceptsWithTermloc(concepts, request.termloc, request.termlocLabel), repositoryInfo: repositoryInfo }
    });
    window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, openInNewTab ? '_blank' : undefined);
}


export const executeCoocShortcut = (request, openInNewTab = true) => {
    let queryObject = {
        category: QUERY_CATEGORY_COOC_SEARCH.id,
        type: QUERY_TYPE_COOC_SEARCH.id,
        form: QUERY_FORM_CORRELATIONS.id,
        formContent: {
            queryTermsLeft: [{
                term: request.name,
                label: request.name,
                domains: [request.domain],
                ocids: [request.ocid],
                prefnames: [request.name]
            }]
        },
        edit: false
    }
    addDataToLocalStorage(QUERY_TYPE_COOC_SEARCH.id, queryObject)
    window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/cooccurrences`, openInNewTab ? '_blank' : undefined)
}

*/
export const executeChemSearchShortcut = (dataItem, chemDB, openInNewTab = true) => {
    const selectedChemDB = chemDB ? { selectedChemDB: chemDB } : { selectedChemDB: null };
    //const selectedChemDB = chemDB && CHEM_DB_ITEMS[chemDB] ? { selectedChemDB: CHEM_DB_ITEMS[chemDB] } : { selectedChemDB: null };
    addDataToLocalStorage(QUERY_TYPE_CHEM_SEARCH.id, { data: { ...dataItem, ...selectedChemDB } });
    window.open(`${APP_PROPERTIES.FRONTEND_URL}chemfinder/chemsearch`, openInNewTab ? '_blank' : '_self');
}
