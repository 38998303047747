import { useState } from "react";
import { Button } from "primereact/button";

import TranslationDialog from "../TranslationDialog";
import ResultsStatistics from "../../../../../webapi/queryBuilder/components/searchForm/searchGroup/statistics/ResultsStatistics";

const SearchGroupCompact = ({
  group,
  groupToEdit,
  onEdit,
  onRunPartialSearch,
  onRemoveSearchGroup,
  renderSearchGroupItem,
  hasError,
  fetching,
}) => {
  const [isTranslationDialogShown, setIsTranslationDialogShown] = useState();
  const [translation] = useState("");
  return (
    <>
      <div
        onDoubleClick={onEdit}
        className="flex align-items-center justify-content-between h-full w-full"
      >
        <div>
          <div className="grid" style={{ opacity: groupToEdit ? 0.6 : 1 }}>
            <div className="col-12 pt-3">
              <div className="inline-flex align-items-center justify-content-center p-0">
                {renderSearchGroupItem()}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-column gap-1 pt-1 pb-1">
          {!groupToEdit && (
            <div className="flex align-items-center mb-2">
              <div className="ml-4 _showOnHover">
                <Button
                  className="p-button-text primaryButtonAsLink"
                  onClick={onEdit}
                >
                  Edit
                </Button>
              </div>
              {!hasError && (
                <div className="ml-4 _showOnHover">
                  <Button
                    className="p-button-text primaryButtonAsLink"
                    onClick={onRunPartialSearch}
                  >
                    Run
                  </Button>
                </div>
              )}
              <div className="ml-4 _showOnHover">
                <Button
                  className="p-button-text primaryButtonAsLink"
                  onClick={onRemoveSearchGroup}
                >
                  Remove
                </Button>
              </div>
            </div>
          )}
          <ResultsStatistics
            groupID={group.id}
            isEditGroup={!!groupToEdit}
            fetching={fetching}
          />
        </div>
      </div>
      <TranslationDialog
        isVisible={isTranslationDialogShown}
        onHide={() => setIsTranslationDialogShown(false)}
        translation={translation}
      />
    </>
  );
};

export default SearchGroupCompact;
