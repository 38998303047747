import React, { useEffect, useState, useRef } from 'react';
import AppGroup from './AppGroup';
import AppList from './AppList';
import { APP_TITLE } from './core/constants';
import { Toast } from 'primereact/toast';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { AppsService } from './core/services/dataTableService';
import { getAdminApps, getUserApps } from '../../../redux/actions';
import '../exportCenter/Export.css'

const AppContainer = () => {
    const toastBC = useRef();
    const [loader, setLoader] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(APP_TITLE);
    const [categories, setCategories] = useState([]);
    const [apps, setApps] = useState([])
    const dispatch = useDispatch()
    const userData = useSelector(state => state.user.data)


    useEffect(() => {
        //fetchApps()
        fetchAdminApps()
    }, []);


    const backgroundLoading = () => {
        fetchApps()
        fetchAdminApps()
    };

    const fetchApps = () => {
        const appService = new AppsService()
        appService.getApps().then(res => {
            switch (res.status) {
                case 'SUCCESS':
                    //setApps(_.orderBy(res.payload, 'name', 'asc'))
                    //dispatch(getUserApps(_.orderBy(res.payload?.filter(item => item.status === 'Finished' || item.status === 'Updated'), 'name', 'asc')))
                    dispatch(getUserApps(_.orderBy(res.payload?.filter(item => item.readyForDisplay), 'name', 'asc')))
                    //dispatch(getAdminApps(_.orderBy(res.payload//?.filter(item => item.readyForDisplay)
                        //, 'name', 'asc')))

                    break
                case 'FAILED':
                    showFailGrowl(res?.message)
            }

        }).catch(e => {
            showFailGrowl(e)
        })
    }

    const fetchAdminApps = () => {
        const appService = new AppsService()
        appService.getAdminApps().then(res => {
            switch (res.status) {
                case 'SUCCESS':
                    setApps(_.orderBy(res.payload, 'name', 'asc'))
                    dispatch(getAdminApps(_.orderBy(res.payload//?.filter(item => item.readyForDisplay)
                    , 'name', 'asc')))

                    break
                case 'FAILED':
                    showFailGrowl(res?.message)
            }

        }).catch(e => {
            showFailGrowl(e)
        })
    }

    const categoryGrouping = (exports) => {
        if (exports && exports?.data) {
            const counts = _.sortBy(exports.data, 'updated').reverse().reduce((p, c) => {
                const name = c.category;
                if (!p.hasOwnProperty(name)) {
                    p[name] = {
                        count: 0,
                        lastDate: c.updated
                    };
                }
                p[name].count++;
                return p;
            }, {});

            const groupedCategories = Object.keys(counts).map((category) => {
                return {
                    title: category,
                    count: counts[category]?.count,
                    lastModifiedDate: counts[category]?.lastDate,
                    isSelected: false
                };
            });

            setCategories(groupedCategories);
        }
    };

    const showFailGrowl = (e, type = 'error', summary = 'Failed!') => {
        let msg = { severity: type, summary, detail: e, life: 10000 };
        toastBC.current.show(msg);
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const changeAlertStatus = (status, appID) => {
        const appService = new AppsService()
        appService.changeAlertStatus(status, appID).then(res => {
            fetchAdminApps()
            fetchApps()
        }).catch(e => {
            showFailGrowl(e.response.data.error)
        })
    }

    const changeActiveStatus = (active, appID) => {
        const appService = new AppsService()
        appService.changeActiveStatus(active, appID).then(res => {
            fetchAdminApps()
            fetchApps()
        }).catch(e => {
            showFailGrowl(e.response.data.error)
        })
    }

    const refreshApp = (appID) => {
        const appService = new AppsService()
        appService.refreshApp(appID).then(res => {
            fetchAdminApps()
            fetchApps()
        }).catch(e => {
            console.log(e)
            showFailGrowl(e.response.data.error)
        })
    }

    return (
        <>
            <Toast ref={toastBC} />

            <LoadingOverlay
                active={loader}
                spinner={true}
                className="fullPage" >

                <div className="grid">
                    <div className='col-12 p-0 float-left'>
                        <div className='col-12 pb-0'>
                            <div className='grid'>
                                <div className="col textAlignLeft pb-0">
                                    <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader mb-0">My Apps</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10" style={{ paddingLeft: 7 }}>
                            <label className='ex-description'>This page shows all available apps created by any super/support admin. New apps can be created and existing apps can be edited.
                                Apps shown here are available for every super/support admin but not visible in the Apps menu by default.</label>
                        </div>
                    </div>
                    <div className='grid col-12 pl-0 pt-0'>
                        <div className='col-4 sm:col-4 md:col-3 lg:col-2 xl:col-2 ex-gr-wrapper'>
                            <AppGroup
                                categories={categories}
                                allExportLength={apps?.length || 0}
                                handleExportChange={handleCategoryChange}
                            />
                        </div>
                        <div className='col-8 sm:col-8 md:col-9 lg:col-10 xl:col-10 ex-list-wrapper'>
                            <AppList
                                userData={userData}
                                apps={apps}
                                selectedCategory={selectedCategory}
                                onReload={() => {
                                    backgroundLoading();
                                }}
                                onShowToast={showFailGrowl}
                                changeAlertStatus={changeAlertStatus}
                                changeActiveStatus={changeActiveStatus}
                                refreshApp={refreshApp}
                            />
                        </div>
                    </div>
                </div>

            </LoadingOverlay>
        </>
    );
};

export default AppContainer;
