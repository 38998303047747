import React from 'react'

const CustomPaginatorTemplate = {
    //layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
    'FirstPageLink': (options) => {
        return (
            <button style={{ background: 'transparent' }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-paginator-icon pi pi-angle-double-left"></span>
            </button>
        )
    },
    'PrevPageLink': (options) => {
        return (
            <React.Fragment>
                <button style={{ background: 'transparent' }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-paginator-icon pi pi-angle-left"></span>
                </button>
                <button style={{ background: 'transparent', marginLeft: -10, marginBottom: 4 }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span style={{ color: '#0973c0', fontSize: 13 }} className="p-3">Previous page</span>
                </button>
            </React.Fragment>
        )
    },
    'PageLinks': (options) => {
         return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
            </button>
        )
    },
    'NextPageLink': (options) => {
        return (
            <React.Fragment>
                <button style={{ background: 'transparent', marginRight: -10, marginBottom: 4 }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span style={{ color: '#0973c0', fontSize: 13 }} className="p-3">Next page</span>
                </button>
                <button style={{ background: 'transparent' }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-paginator-icon pi pi-angle-right"></span>
                </button>
            </React.Fragment>
        )
    },
    'LastPageLink': (options) => {
        return (
            <button style={{ background: 'transparent' }} type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-paginator-icon pi pi-angle-double-right"></span>
            </button>
        )
    }/*,
    'CurrentPageReport': (options) => {
        return (
            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                {options.first} - {options.last} of {options.totalRecords} entries
            </span>
        )
    }*/
};

export default CustomPaginatorTemplate;