import { Component, createRef } from "react";
import { Button } from "primereact/button";
import ChemistryAutocompleteInputField from "../autocomplete/ChemistryAutocompleteInputField";
import StructureEditor from "./StructureEditor";
import ChemistrySearchSelection from './ChemistrySearchSelection';
import SaveSearchButtons from "../../../common/SaveSearch/SaveSearchButtons";
import BulkImportDialog from "../../bulkimport/components/BulkImportDialog";
import { CHEMISTRY_BULK_IMPORT_FILE_FORMATS, CHEMISTRY_BULK_IMPORT_TYPES } from "../utils/chemistrySearchConstants";
import { isSearchEmpty } from "../utils/queryChecker";
import SavedSearchesLink from "../../general/SavedSearches/SavedSearchesLink";


class ChemistrySearchFormBlock extends Component {

    constructor(props) {
        super(props);

        this.state = { showReactionToolbar: true };

        this.attributeSearch = createRef();
        this.structureSearch = createRef();
        this.structEditor = createRef();
    }

    onBulkImportSubmit = (type, ids) => {
        this.props.onBulkImportSubmit(type, ids);
        this.setState({ displayBulkImportDialog: false });
    }

    onSaveQuery = (editQuery) => {
        this.props.onSaveQuery(editQuery, this.props.savedSearchID, this.props.savedSearchName);
    }

    render() {

        const { isAttributeSearch, onSearchFormChange, queryTerms, onQueryTermsChange, onNonValidatedInputChange,
            queryTerm, searchTargetOptions, searchTarget, onTargetOptionChange,
            inclBulkImport, editableQuery, blockSearchButton, onSubmitSearch,
            includeChemClassesInAutocomplete, includeChemCompoundsInAutocomplete,
            onSelectSavedSearch, savedSearchID, savedSearchName, allowedQueryTypes, queryChanged,
            structure, onStructureChange, structureFormat,
            inclDomainBrowser, onAddQueryTermsWithDomainExplorer } = this.props;


        return (
            <>
                <div className="grid">
                    <div className="col" style={{ paddingBottom: 0 }}>
                        <Button
                            className="p-button-text primaryButtonAsLink valignMiddle"
                            title="Switch between search field and structure editor"
                            style={{ marginRight: 30 }}
                            onClick={onSearchFormChange}>
                            {isAttributeSearch ? 'Draw structure' : 'Search by identifier'}
                        </Button>
                        {inclDomainBrowser &&
                            <Button
                                className="p-button-text primaryButtonAsLink valignMiddle"
                                style={{ marginRight: 30 }}
                                title="Browse domains and add concepts to your search"
                                onClick={() => onAddQueryTermsWithDomainExplorer()}>
                                Chemical classification
                            </Button>
                        }
                        {inclBulkImport &&
                            <Button
                                className="p-button-text primaryButtonAsLink valignMiddle"
                                title="Add multiple SMILES, InChI keys, names etc. to your search"
                                style={{ marginRight: 30 }}
                                onClick={() => this.setState({ displayBulkImportDialog: true })}>
                                Import
                            </Button>
                        }
                        
                        <SavedSearchesLink
                            allowedQueryTypes={allowedQueryTypes}
                            onSearchSelect={(savedSearch) => onSelectSavedSearch(savedSearch)}
                        />
                    </div>
                    <div className="col-fixed textAlignRight" style={{ paddingBottom: 0 }}>
                        <Button
                            className="p-button-text primaryButtonAsLink valignMiddle"
                            style={{ marginLeft: 30, padding: 0 }}
                            title="Reset page"
                            onClick={() => this.props.onClearAll()}>
                            Clear all
                        </Button>
                    </div>
                    <div className="col-12">
                        <div style={{ display: isAttributeSearch ? 'block' : 'none' }}>
                            <ChemistryAutocompleteInputField
                                filterDefinitions={this.props.filterDefinitions}
                                queryTerms={queryTerms}
                                onQueryTermsChange={onQueryTermsChange}
                                onNonValidatedInputChange={onNonValidatedInputChange}
                                queryTerm={queryTerm}
                                onReplaceQueryTerm={this.props.onReplaceQueryTerm}
                                onReplaceQueryTermWithDomainExplorer={this.props.onReplaceQueryTermWithDomainExplorer}
                                includeChemClasses={includeChemClassesInAutocomplete}
                                includeChemCompounds={includeChemCompoundsInAutocomplete}
                            />
                        </div>
                        <div style={{ display: !isAttributeSearch ? 'block' : 'none' }}>
                            <StructureEditor
                                ref={(ref) => { this.structEditor = ref; }}
                                toolbars={this.state.showReactionToolbar ? 'reaction' : 'standard'} // reaction standardReaction
                                structure={structure}
                                onStructureChange={onStructureChange}
                                minHeight="450px"
                                width="100%"
                                format={structureFormat}
                            />
                        </div>
                    </div>
                    {!isSearchEmpty(isAttributeSearch, queryTerms, structure) ?
                        <div className="col-fixed">
                            <SaveSearchButtons
                                savedSearchExists={!!savedSearchID}
                                editableQuery={editableQuery}
                                onSaveSearch={this.onSaveQuery}
                                savedSearchName={savedSearchName}
                            />
                            {/*editableQuery ?
                                <Button
                                    className="p-button-text primaryButtonAsLink valignMiddle"
                                    title='Closes saved search. Current search form will not be cleared.'
                                    style={{ marginLeft: 30, padding: 0 }}
                                    onClick={() => onClearSavedSearch()}>
                                    Close
                    </Button> : null*/}
                        </div> : null
                    }
                    <div className="col textAlignRight">
                        <ChemistrySearchSelection
                            options={searchTargetOptions}
                            selectedOption={searchTarget}
                            onSelectOption={(option) => {
                                onTargetOptionChange(option);
                            }}
                        />
                        <Button
                            label='Search'
                            className={`p-button-sm primaryButton valignMiddle ${queryChanged ? 'attention' : ''}`}
                            disabled={blockSearchButton}
                            onClick={onSubmitSearch}
                            style={{ marginLeft: 30 }} />
                    </div>
                </div>

                <BulkImportDialog
                    displayDialog={this.state.displayBulkImportDialog}
                    valueTypes={Object.values(CHEMISTRY_BULK_IMPORT_TYPES)}
                    acceptedFileTypes={CHEMISTRY_BULK_IMPORT_FILE_FORMATS}
                    maxValues={500}
                    initialValueType={this.state.bulkIDType}
                    initialValues={this.state.bulkIDs}
                    onSubmit={this.onBulkImportSubmit}
                    onHide={e => { this.setState({ displayBulkImportDialog: false }) }}
                />
            </>
        );
    }
}

export default ChemistrySearchFormBlock;