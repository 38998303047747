/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { APP_PROPERTIES, REPO_ROLES, REPO_ROLE_DOC_SEARCH } from '../../properties';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { hasUserRole } from '../../components/webapi/util';
import RepositoryMetadata from './RepositoryMetadata';
import { NotificationEditingTab } from './NotificationEditingTab/NotificationEditingTab';
import { fetchRepositoryCatalogs } from '../../api/AdministrationApi';
import { addAnnotationVersionToRepositories } from './util';

class GlobalSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mouseOverRepositoryButton: false,
            mouseOverDomainButton: false,
            mouseOverFeatureButton: false,
            sortedRepositories: [],
            activeIndex: 0,
            filteredRepos: [],
            filteredChemRepos: [],
            domName: '',
            featName: '',
            displayMetadataDialog: false,
            selectedRepository: null,
            annotationVersionAdded: false
        }
        this.columns = [
            { field: 'name', header: '', body: this.nameTemplate, style: { padding: "0.8em 0.8em" } }
        ];
    }

    componentDidMount() {
        if (this.props.allAvailableRepositories) {
            let sortedRepositories = []
            this.props.allAvailableRepositories.forEach(rep => {
                if (rep.active) {
                    sortedRepositories = [...sortedRepositories, rep]
                }
            })
            this.setState({
                sortedRepositories: sortedRepositories
            })
        }

        let filteredRepos = [];
        let filteredChemRepos = []
        if (this.props.allAvailableRepositories && this.props.allBackends && this.props.allAvailableRepositoryFeatures) {
            const allBackends = this.props.allBackends && APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ? this.props.allBackends : [];
            const allRepositories = this.props.allAvailableRepositories ? this.props.allAvailableRepositories : [];

            const backendMap = {};
            allBackends.forEach(backend => {
                backendMap[backend.id] = backend;
            });

            filteredRepos = allRepositories.filter(repo => {
                if (backendMap[repo.backendId] && backendMap[repo.backendId].type === 'webapi') {
                    repo.backendInfo = backendMap[repo.backendId];
                    return true;
                }
                return false;
            });
            this.addFeatureLabels(filteredRepos);

            filteredChemRepos = allRepositories.filter(repo => {
                if (backendMap[repo.backendId] && backendMap[repo.backendId].type === 'chemistry') {
                    repo.backendInfo = backendMap[repo.backendId];
                    return true;
                }
                return false;
            });
            this.addFeatureLabels(filteredChemRepos);
        }
        this.setState({
            filteredRepos: filteredRepos,
            filteredChemRepos: filteredChemRepos
        });
    }

    componentDidUpdate(prevProps) {

        if (this.props.allAvailableRepositories !== prevProps.allAvailableRepositories) {

            let sortedRepositories = []
            this.props.allAvailableRepositories.forEach(rep => {
                if (rep.active) {
                    sortedRepositories = [...sortedRepositories, rep]
                }
            })

            let filteredRepos = [...this.state.filteredRepos];
            let filteredChemRepos = [...this.state.filteredChemRepos];
            if (this.props.allAvailableRepositories && this.props.allBackends && this.props.allAvailableRepositoryFeatures) {
                const allBackends = this.props.allBackends && APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ? this.props.allBackends : [];
                const allRepositories = this.props.allAvailableRepositories ? this.props.allAvailableRepositories : [];

                const backendMap = {};
                allBackends.forEach(backend => {
                    backendMap[backend.id] = backend;
                });

                filteredRepos = allRepositories.filter(repo => {
                    if (backendMap[repo.backendId] && backendMap[repo.backendId].type === 'webapi') {
                        repo.backendInfo = backendMap[repo.backendId];
                        return true;
                    }
                    return false;
                });
                this.addFeatureLabels(filteredRepos);

                filteredChemRepos = allRepositories.filter(repo => {
                    if (backendMap[repo.backendId] && backendMap[repo.backendId].type === 'chemistry') {
                        repo.backendInfo = backendMap[repo.backendId];
                        return true;
                    }
                    return false;
                });
                this.addFeatureLabels(filteredChemRepos);
            }

            this.setState({
                sortedRepositories: sortedRepositories,
                filteredRepos: this.state.filteredRepos ? filteredRepos : [],
                filteredChemRepos: this.state.filteredChemRepos ? filteredChemRepos : []
            })
        }

        if (!this.state.annotationVersionAdded && this.state.repositoriesCatalog && this.props.allAvailableRepositories) {
            addAnnotationVersionToRepositories([...this.props.allAvailableRepositories], this.state.repositoriesCatalog);
            this.setState({
                annotationVersionAdded: true
            });
        }
    }

    addFeatureLabels = (repositories) => {
        repositories?.forEach(repo => {
            repo.featureLabels = repo.features?.map(featureName => REPO_ROLES[featureName]?.label || featureName);
            repo.featureLabelsString = repo.featureLabels ? repo.featureLabels.join(' ') : '';
        });
    }


    nameTemplate = (rowData) => {
        return `${rowData.label} (${rowData.name}, ${rowData.id})`
    }

    enterButtonDomain = (name) => {
        this.setState({
            mouseOverDomainButton: true,
            domName: name
        })
    }

    leaveButtonDomain = () => {
        this.setState({
            mouseOverDomainButton: false
        })
    }

    enterButtonFeature = (name) => {
        this.setState({
            mouseOverFeatureButton: true,
            featName: name
        })
    }

    leaveButtonFeature = () => {
        this.setState({
            mouseOverFeatureButton: false
        })
    }

    openAddRepository = () => {
        this.props.openAddRepository()
    }

    onRowReorder = (e) => {
        this.setState({
            sortedRepositories: e.value
        });
    }

    saveRepositoryOrder = () => {
        let newSortedRepositories = JSON.parse(JSON.stringify(this.state.sortedRepositories))
        let sortedIDs = []
        newSortedRepositories.forEach((rep, i) => {
            sortedIDs = [...sortedIDs, rep.id]
        })
        this.props.onRepositoryOrderChange(sortedIDs)
    }

    openDeleteRepository = (id, label, name) => {
        this.setState({
            mouseOverRepositoryButton: false
        }, () => {
            this.props.openDeleteRepository(id, label, name)
        })
    }

    openEditRepository = (id, label, name, abbreviation, active, backendId, statistics, features, description) => {
        this.setState({
            mouseOverRepositoryButton: false
        }, () => {
            this.props.openEditRepository(id, label, name, abbreviation, active, backendId, statistics, features, description)
        })
    }

    openCopyRepository = (label, name, abbreviation, active, backendId, statistics, features, description) => {
        this.setState({
            mouseOverRepositoryButton: false
        }, () => {
            this.props.openCopyRepository(label, name, abbreviation, active, backendId, statistics, features, description)
        })
    }

    openSetupRepository = (id, label, name, abbreviation, active, backendId, statistics, features, description) => {
        this.setState({
            mouseOverRepositoryButton: false
        }, () => {
            this.props.openSetupRepository(id, label, name, abbreviation, active, backendId, statistics, features, description)
        })
    }

    openEditDomain = (label, name, id, active, color, description, orderPriority) => {
        this.setState({
            mouseOverDomainButton: false
        }, () => {
            this.props.openEditDomain(label, name, id, active, color, description, orderPriority)
        })
    }

    openSetupDomain = (label, name, id) => {
        this.setState({
            mouseOverDomainButton: false
        }, () => {
            this.props.openSetupDomain(label, name, id)
        })
    }

    openEditFeature = (label, name, id, canBeDeleted, isDefault) => {
        this.setState({
            mouseOverFeatureButton: false
        }, () => {
            this.props.openEditFeature(label, name, id, canBeDeleted, isDefault)
        })
    }

    openSetupFeature = (label, name, id) => {
        this.setState({
            mouseOverDomainButton: false
        }, () => {
            this.props.openSetupFeature(label, name, id)
        })
    }

    openDeleteDomain = (e, label, name, id) => {
        this.setState({
            mouseOverDomainButton: false
        }, () => {
            this.props.openDeleteDomain(label, name, id)
        })
    }

    openAddHighlightSearchDomain = () => {
        this.props.openAddDomain()
    }

    openAddFeature = () => {
        this.props.openAddFeature()
    }

    handleActivationStatusRepository = (value, id) => {
        this.props.handleActivationStatusRepository(value, id)
    }

    handleActivationStatusDomain = (value, id) => {
        this.props.handleActivationStatusDomain(value, id)
    }

    switchStatusTemplate = (rowData) => {
        return (
            <InputSwitch
                disabled={!hasUserRole("ROLE_SUPPORT_ADMIN") ? false : true}
                checked={rowData.active}
                onChange={(e) => this.handleActivationStatusRepository(e.value, rowData.id)} />
        );
    }

    repositoryTemplate = (rowData) => {
        return (
            <span className="croppedTableCell"
                title={`Label: ${rowData.label} | ID: ${rowData.id} | Name: ${rowData.name}`}>
                {rowData.label}
            </span>
        );
    }

    repoDescriptionTemplate = (rowData) => {
        return (
            rowData.description ?
                <span
                    title={rowData.description} style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                    {rowData.description}
                </span>
                : null
        );
    }

    backendTemplate = (rowData) => {
        return (
            <span className="croppedTableCell"
                title={`Label: ${rowData.backendInfo.label} | ID: ${rowData.backendInfo.id} | Name: ${rowData.backendInfo.name}`}>
                {rowData.backendInfo.label}
            </span>
        );
    }

    featureTemplate = (rowData) => {
        const renderedFeatures = rowData.featureLabels?.map(featureLabel => <div>{featureLabel}</div>);
        return (
            <span className="croppedTableCell"
                title={`Active features: ${rowData.featureLabels?.join(', ')}`} >
                {renderedFeatures}
            </span>
        );
    }

    fetchMetadataTemplate = (rowData) => {
        return (
            rowData.features?.includes(REPO_ROLE_DOC_SEARCH.id) ? <a onClick={e => this.setState({ displayMetadataDialog: true, selectedRepository: rowData })}>Show metadata</a> : null
        );
    }

    editRepositoryTemplate = (rowData) => {
        return (
            <a onClick={() => this.openEditRepository(rowData.id, rowData.label, rowData.name, rowData.abbreviation, rowData.active, rowData.backendId, rowData.statistics, rowData.features, rowData.description)}>Edit</a>
        );
    }

    copyRepositoryTemplate = (rowData) => {
        return (
            <a onClick={e => this.openCopyRepository(rowData.label, rowData.name, rowData.abbreviation, rowData.active, rowData.backendId, rowData.statistics, rowData.features, rowData.description)}>Copy</a>
        );
    }

    deleteRepositoryTemplate = (rowData) => {
        return (
            <a onClick={() => this.openDeleteRepository(rowData.id, rowData.label, rowData.name)}>Delete</a>
        );
    }

    renderRepositories = (repositories) => {

        return (
            <div className='width100perc'>
                <DataTable tableStyle={{ minWidth: '50rem' }}
                    className="p-datatable-responsive-demo"
                    emptyMessage="No repositories available."
                    value={repositories}>
                    <Column key="active" field="active" header="Status" sortable body={this.switchStatusTemplate} style={{ width: 50 }} />
                    <Column key="id" field="label" header="Repository" sortable body={this.repositoryTemplate} style={{ width: 200 }} />
                    <Column key="backendId" field="backendInfo.label" header="Backend" sortable body={this.backendTemplate} style={{ width: 150 }} />
                    <Column key="description" field="description" header="Description" body={this.repoDescriptionTemplate} />
                    <Column key="annotationVersion" field="annotationVersion" header="Annotation" style={{ width: 100 }} sortable filter />
                    <Column key="features" field="featureLabelsString" header="Features" body={this.featureTemplate} style={{ width: 200 }} filter />
                    <Column key="metadata" field="metadata" header="" body={this.fetchMetadataTemplate} style={{ width: 120 }} />
                    {!hasUserRole("ROLE_SUPPORT_ADMIN") &&
                        <Column key="edit" field="edit" header="" body={this.editRepositoryTemplate} style={{ width: 50 }} />
                    }
                    {!hasUserRole("ROLE_SUPPORT_ADMIN") &&
                        <Column key="copy" field="copy" header="" body={this.copyRepositoryTemplate} style={{ width: 50 }} />
                    }
                    {!hasUserRole("ROLE_SUPPORT_ADMIN") &&
                        <Column key="delete" field="delete" header="" body={this.deleteRepositoryTemplate} style={{ width: 60 }} />
                    }
                </DataTable>
            </div>
        );
    }

    setActiveIndex = (index) => {
        this.setState({
            activeIndex: index,
            fetchingAnnotationVersions: index === 1 && !this.state.annotationVersionAdded ? true : false
        }, () =>{
            if (index === 1 && !this.state.annotationVersionAdded){
                fetchRepositoryCatalogs(this.props.allBackends?.map(be => { return { id: be.id, type: be.type } })).then(resp => {
                    this.setState({
                        repositoriesCatalog: resp.payload,
                        fetchingAnnotationVersions: false
                    });
                })
            }
        })
    }

    render() {

        let sortedDomains = []

        if (this.props.allDomainsHighlightItems) {
            sortedDomains = this.props.allDomainsHighlightItems.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : ((b.label.toLowerCase() < a.label.toLowerCase()) ? 1 : 0))
        }

        let allDomainsHighlight = sortedDomains && sortedDomains.map(dom =>
            <div className='col-12' style={{ paddingLeft: 0 }}
                key={`${dom.name}`}>
                <InputSwitch style={{
                    marginRight: 5, marginTop: 5
                }} disabled={!hasUserRole("ROLE_SUPPORT_ADMIN") ? false : true} checked={dom.active} onChange={(e) => this.handleActivationStatusDomain(e.value, dom.id)} />
                <label onMouseLeave={() => this.leaveButtonDomain()} onMouseEnter={() => this.enterButtonDomain(dom.name)} htmlFor={`${dom.name}`}
                    className='p-checkbox-label' style={{
                        paddingLeft: 0
                    }}>{`${dom.label} (${dom.name})`}
                    {this.state.mouseOverDomainButton && this.state.domName === dom.name && !hasUserRole("ROLE_SUPPORT_ADMIN") &&
                        <React.Fragment>
                            <a id="deacDom"
                                onClick={() => this.openEditDomain(dom.label, dom.name, dom.id, dom.active, dom.color, dom.description, dom.orderPriority)}
                                style={{ display: 'inline-block', marginLeft: 15 }}>Edit</a>
                            <a id="deacDom"
                                onClick={(e) => this.openDeleteDomain(e, dom.label, dom.name, dom.id)}
                                style={{ display: 'inline-block', marginLeft: 10, color: 'red' }}>Delete</a>
                        </React.Fragment>
                    } </label>
            </div>
        )

        let allAvailableFeatures = this.props.allAvailableFeatures && this.props.allAvailableFeatures.map(feat =>
            (feat.name !== 'infopage' && feat.name !== 'history' && feat.name !== 'tutorials' && feat.name !== 'feedback' && feat.name !== 'manual' && feat.name !== 'support')
                ?
                <div className='col-12' style={{ paddingLeft: 0, marginBottom: 5 }}
                    key={`${feat.name}`}>
                    <label onMouseLeave={() => this.leaveButtonFeature()} onMouseEnter={() => this.enterButtonFeature(feat.name)} htmlFor={`${feat.name}`}
                        className='p-checkbox-label' style={{
                            paddingLeft: 0
                        }}>{`${feat.label}`}
                        {this.state.mouseOverFeatureButton && this.state.featName === feat.name && !hasUserRole("ROLE_SUPPORT_ADMIN") &&
                            <React.Fragment>
                                <a id="deacDom"
                                    onClick={() => this.openEditFeature(feat.label, feat.name, feat.id, feat.canBeDeleted, feat.isDefault)}
                                    style={{ display: 'inline-block', marginLeft: 15 }}>Edit</a>
                            </React.Fragment>
                        } </label>
                </div>
                : null
        )

        const dynamicColumns = this.columns.map((col, i) => {
            return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} body={col.body} style={col.style} />;
        });

        return (
            <>
                <Dialog visible={this.props.displayGlobalSettings} style={{ 'width': "90vw" }}
                    header="Global settings" focusOnShow={false}
                    modal={true}
                    dismissableMask={false}
                    onHide={() => this.props.onCancelGlobalSettings()} className='styledDialog'>
                    <div style={{ paddingLeft: 25, paddingRight: 10, paddingBottom: 15, marginRight: 0 }}>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setActiveIndex(e.index)}>
                            <TabPanel header="Pages and features" id="roles">
                                <div className='col-12 grid'>
                                    <div className='col-12'>
                                        <label htmlFor="roles"
                                            style={{ fontWeight: 'bold' }}>Available pages and features</label>
                                        <div style={{ paddingBottom: 0 }}>
                                            <a id="addRepo"
                                                onClick={this.openSetupFeature}
                                                style={{ float: 'left', marginRight: 15, paddingTop: 5 }}>Setup</a>
                                            {!hasUserRole("ROLE_SUPPORT_ADMIN") &&
                                                <a onClick={this.openAddFeature}
                                                    style={{ float: 'left', paddingTop: 5 }}>Add feature</a>
                                            }
                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            {allAvailableFeatures}
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Repositories" id="repos">
                                <label htmlFor="repos"
                                    style={{ fontWeight: 'bold' }}>Available text or database sources</label>
                                <span style={{ marginLeft: 20 }}>{this.state.fetchingAnnotationVersions ? 'Fetching annotation versions for webapi repositories...' : ''}</span>
                                {!hasUserRole("ROLE_SUPPORT_ADMIN") &&
                                    <a id="addRepo"
                                        onClick={this.openAddRepository}
                                        style={{ float: 'right' }}>Add repository</a>
                                }
                                <a id="addRepo"
                                    onClick={this.openSetupRepository}
                                    style={{ float: 'right', marginRight: 15 }}>Setup</a>
                                <div style={{ marginTop: 20 }}>
                                    {this.renderRepositories(this.state.filteredRepos)}
                                </div>
                                {this.state.filteredChemRepos && this.state.filteredChemRepos.length > 0 ?
                                    <div style={{ marginTop: 20 }}>
                                        <label htmlFor="repos"
                                            style={{ fontWeight: 'bold' }}>Available chemistry sources</label>
                                        <div style={{ marginTop: 20 }}>
                                            {this.renderRepositories(this.state.filteredChemRepos)}
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className="orderlist-demo" style={{ marginTop: 20 }}>
                                    <div style={{ marginTop: 25, marginBottom: 10 }}>
                                        <label
                                            title="Repository order for document searches"
                                            style={{ fontWeight: 'bold' }}>
                                            {`Repository order for document searches ${!hasUserRole("ROLE_SUPPORT_ADMIN") ? '(drag and drop)' : ''}`}</label>
                                    </div>
                                    <DataTable value={this.state.sortedRepositories} reorderableRows onRowReorder={this.onRowReorder}>
                                        {dynamicColumns}
                                        <Column rowReorder={!hasUserRole("ROLE_SUPPORT_ADMIN") ? true : false} style={{ width: '3em', cursor: 'pointer' }} />
                                    </DataTable>
                                </div>
                                {!hasUserRole("ROLE_SUPPORT_ADMIN") &&
                                    <div style={{ marginTop: 15, paddingBottom: 10, float: 'right' }}>
                                        <Button className="p-button-small p-button-secondary"
                                            onClick={this.saveRepositoryOrder}
                                            style={{ display: 'inline-block' }}>Save order</Button>
                                    </div>
                                }
                            </TabPanel>
                            {APP_PROPERTIES.APP_ID !== 'sciwalker_studio' &&
                                <TabPanel header="Domains" id="doms">
                                    <label htmlFor="doms"
                                        style={{ fontWeight: 'bold' }}>Available domains - highlighted in annotated text and available in autocomplete</label>
                                    {!hasUserRole("ROLE_SUPPORT_ADMIN") &&
                                        <a onClick={this.openAddHighlightSearchDomain}
                                            style={{ float: 'right' }}>Add domain</a>
                                    }
                                    <a id="addRepo"
                                        onClick={this.openSetupDomain}
                                        style={{ float: 'right', marginRight: 15 }}>Setup</a>
                                    <div style={{ marginTop: 20 }}>
                                        {allDomainsHighlight}
                                    </div>
                                </TabPanel>
                            }
                            <TabPanel header="Maintenance notifications" id="maintenanceNotifications">
                                <NotificationEditingTab />
                            </TabPanel>
                        </TabView>
                    </div>
                </Dialog>

                <RepositoryMetadata
                    displayDialog={this.state.displayMetadataDialog}
                    onToggleDialog={(toggle) => this.setState({ displayMetadataDialog: toggle })}
                    repository={this.state.selectedRepository}
                />
            </>
        )
    }
}

export default GlobalSettings