import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";


class ConfirmationDialog extends Component {

    render() {

        const { displayDialog, onShow, onHide, onSubmit, headerText, messageText, submitButtonLabel, cancelButtonLabel, secondChoice, onSubmitSecondChoice, customContent, onlyOneChoice } = this.props;

        const confirmationFooter = (
            <div  style={{ paddingTop: 5 }}>
                <Button
                    className={secondChoice ? "p-button-danger p-button-sm" : "primaryButton p-button-sm"}
                    label={submitButtonLabel ? submitButtonLabel : 'Yes'}
                    onClick={(e) => onSubmit()}
                />
                {!onlyOneChoice ?
                    <Button
                        className={secondChoice ? "primaryButton p-button-sm" : "p-button-secondary p-button-sm"}
                        label={cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
                        onClick={secondChoice ? (e) => onSubmitSecondChoice() : (e) => onHide()}
                        autoFocus={true}
                    />
                    : null
                }
            </div>
        );

        return (
            <Dialog
                header={headerText ? headerText : 'Confirm'}
                visible={displayDialog}
                modal={true}
                style={{ width: '40vw' }}
                footer={confirmationFooter}
                onShow={onShow ? onShow : () => { }}
                onHide={onHide ? onHide : () => { }}
                focusOnShow={false}
                dismissableMask={true} 
                className='styledDialog'>
                <div className="grid confirmation-content" style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0, marginBottom: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="col-fixed">
                        <i className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: '2rem', marginLeft: 10 }} />
                    </div>
                    <div className="col" style={{ paddingLeft: 0, marginTop: 2 }}>
                        <span style={{ verticalAlign: 'sub' }}>{messageText ? messageText : 'Would you like to proceed?'}</span>
                    </div>
                    {customContent ?
                        <div className="col-12" style={{ paddingLeft: 60 }}>
                            {customContent}
                        </div>
                        : null
                    }
                </div>
            </Dialog>
        );
    }
}

export default ConfirmationDialog;