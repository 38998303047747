/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import axios from 'axios'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { APP_PROPERTIES, FUNCTION_ROLES } from '../../properties/index';
import { doLoginSAML } from '../../redux/actions/UserActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Login.css';
import { getCurrentYear } from '../webapi/util';

class UpdatePassword extends Component {

	constructor(props) {
		super(props);
		this.state = {
			newUserPassword: '',
			newUserPasswordRepeated: '',
			type: 'password',
			typeRepeated: 'password',
			finished: false
		}
	}

	UNSAFE_componentWillMount() {
		//console.log(this.props)
		if (localStorage.token && this.props.authenticated) {
			this.homepage = ''
			const tokenParts = localStorage.token.split(".")
			const tokenPayload = JSON.parse(atob(tokenParts[1]));
			const tokenLanding = tokenPayload.landing;
			if (tokenLanding) {
				let hasLanding = false
				tokenPayload.rol.forEach(role => {
					if (role.toLowerCase().includes(tokenLanding)) {
						FUNCTION_ROLES.forEach(role => {
							if (role.name === tokenLanding) {
								this.homepage = role.url
								hasLanding = true
							}
						})
					}
				})
				if (hasLanding === false) {
					this.homepage = APP_PROPERTIES.HOME_PAGE
				}
			} else {
				this.homepage = APP_PROPERTIES.HOME_PAGE
			}

			this.props.history.push(this.homepage);
		}
	}

	handleShowHide = (e) => {
		e.preventDefault();
		if (this.state.type === 'input') {
			this.setState({
				type: 'password'
			})
		} else {
			this.setState({
				type: 'input'
			})
		}
	}

	handleShowHideRepeated = (e) => {
		e.preventDefault();
		if (this.state.typeRepeated === 'input') {
			this.setState({
				typeRepeated: 'password'
			})
		} else {
			this.setState({
				typeRepeated: 'input'
			})
		}
	}

	onInfoClick = (e) => {
		e.preventDefault();
	}

	onChangePW = (e) => {
		e.preventDefault()
		if (this.state.newUserPassword !== this.state.newUserPasswordRepeated) {
			this.showPasswordWarnGrowl()
		} else {
			var self = this;
			let encodedPassword = btoa(self.state.newUserPassword);
			axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/user/password`, encodedPassword, { headers: { 'Accept': 'text/html', 'Content-Type': 'text/html', 'Authorization': `Bearer ${this.props.location.token ? this.props.location.token : this.props.token}` } })
				.then(function (response) {
					localStorage.clear()
					self.setState({
						finished: true
					})
					setTimeout(() => {
						//console.log("Login")
						self.props.history.push('/')
					}, 5000)
				})
				.catch(function (error) {
					if (error.response.status === 400) {
						self.showEditUserFailGrowl(error.response.data)
					} else if (error.response.status === 401) {
						self.props.history.push('/')
					} else if (error.response.status === 404) {
						self.showEditUserFailGrowl(error.response.data)
						console.log("not found")
					}
				})
		}
	}

	showPasswordWarnGrowl = () => {
		let msg = { severity: 'error', summary: 'Error!', detail: `The entered passwords are not identical.`, life: 6000 };
		this.growl.show(msg)
	}

	showEditUserFailGrowl = (e) => {
		let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
		this.growl.show(msg)
	}

	render() {

		//console.log("location",this.props.location.token)
		//console.log("token",this.props.token)

		return (
			<div className="login-body" style={{
				background: APP_PROPERTIES.APP_ID !== 'dimensions' ?
					`url('${APP_PROPERTIES.LOGIN_BG_IMAGE}') no-repeat scroll center center / cover` : 'white'
				//`url('${APP_PROPERTIES.LOGIN_BG_IMAGE}') no-repeat scroll center center / cover`
			}}>
				<Toast ref={(el) => { this.growl = el }} />
				<div className="login-panel" style={{ border: '1px solid #d1d1d1', top: '40%' }}>
					{APP_PROPERTIES.LOGIN_LOGO_APP ?
						<div className="login-panel-header" style={{ marginTop: 66, marginLeft: 'auto', marginRight: 'auto', background: 'white', padding: 0 }}>
							<img src={APP_PROPERTIES.LOGIN_LOGO_APP} alt={`${APP_PROPERTIES.APP_NAME} logo`} style={APP_PROPERTIES.LOGIN_LOGO_APP_STYLE} />
						</div>
						: null}
					{this.state.finished === false ?
						this.props.location.token || this.props.token !== null ?
							<React.Fragment>
								<form style={{}} onSubmit={this.handleLogin}>
									<div className="login-panel-content">
										<div className="grid">
											<div className='col-12' style={{ paddingTop: 50 }}>
												<div className="col-12" style={{}}>
													{/*<h1>Sign in to {APP_PROPERTIES.APP_NAME}</h1>*/}
													<label style={{ fontSize: 30, color: 'black' }}>Reset password</label>
												</div>
												<div className="col-12" style={{ marginTop: 0, textAlign: 'left', paddingBottom: 4 }}>
													<label style={{ color: 'black', paddingLeft: 5 }} htmlFor="companyName">New password</label>
													<Button icon='pi pi-info-circle'
														onClick={(e) => this.onInfoClick(e)}
														style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }}
														title='Password needs to contain at least 10 characters with one uppercase character and with one number.'>
													</Button>
												</div>
												<div className="col-12" style={{ padding: '.5em', marginBottom: 10, marginTop: -10 }}>
													<InputText style={{ marginLeft: 5, padding: 5, width: '86%', backgroundColor: 'white' }}
														id="userPassword"
														autoComplete="off"
														type={this.state.type === 'input' ? 'input' : 'password'}
														onChange={(e) => {
															e.preventDefault()
															this.setState({ newUserPassword: e.target.value })
														}} value={this.state.newUserPassword} />
													<Button
														icon={this.state.type === 'input' ? 'pi pi-eye' : 'pi pi-eye-slash'}
														onClick={(e) => this.handleShowHide(e)}
														style={{ marginBottom: -5, backgroundColor: 'white', color: 'black', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }} >
													</Button>
												</div>
												<div className="col-12" style={{ marginTop: -25, textAlign: 'left', paddingBottom: 4 }}>
													<label style={{ color: 'black', paddingLeft: 5 }} htmlFor="companyName">Repeat password</label>
													<Button icon='pi pi-info-circle'
														style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }}
														onClick={(e) => this.onInfoClick(e)}
														title='Password needs to contain at least 10 characters with one uppercase character and with one number.'>
													</Button>
												</div>
												<div className="col-12" style={{ padding: '.5em', marginTop: -10 }}>
													<InputText style={{ marginLeft: 5, padding: 5, width: '86%', backgroundColor: 'white' }}
														id="userPasswordRepeated"
														autoComplete="off"
														type={this.state.typeRepeated === 'input' ? 'input' : 'password'}
														onChange={(e) => {
															e.preventDefault()
															this.setState({ newUserPasswordRepeated: e.target.value })
														}} value={this.state.newUserPasswordRepeated} />
													<Button
														icon={this.state.typeRepeated === 'input' ? 'pi pi-eye' : 'pi pi-eye-slash'}
														onClick={(e) => this.handleShowHideRepeated(e)}
														style={{ marginBottom: -5, backgroundColor: 'white', color: 'black', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }} >
													</Button>
												</div>
												<div className='col-12' style={{ marginBottom: 5, marginRight: 5, marginTop: 5 }}>
													<Button label="Reset"
														style={{ float: "right" }}
														className="primaryButtonLogin p-button-sm"
														title="Reset"
														icon='pi pi-refresh'
														iconPos='right'
														disabled={this.state.newUserPasswordRepeated !== '' && this.state.newUserPassword !== '' ? false : true}
														onClick={(e) => this.onChangePW(e)} >
													</Button>
												</div>
												{/*<div className="col-12">
									<Captcha siteKey='null' onResponse={this.showResponse}></Captcha>
		</div>*/}
												{//APP_PROPERTIES.APP_ID === 'dimensions' ?
													<div className='col-12' style={{ paddingTop: 110 }}>
														<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
														<div className='col-12'>
															<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
															{APP_PROPERTIES.INCLUDE_ONETRUST &&
																<React.Fragment>
																	<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
																	{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
																	<a className="optanon-show-settings" style={{ margin: '0 5px', color: '#757575' }}>Cookie settings</a>
																</React.Fragment>
															}
															<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
															<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
														</div>
													</div>
													//:
													//null
												}
											</div>
										</div>
									</div>
								</form>
							</React.Fragment>
							:
							<form //onSubmit={this.handleLogin}
							>
								<div className="login-panel-content">
									<div className="grid">
										<div className="col-12" style={{ textAlign: 'center' }}>
											<label style={{ fontSize: 20, color: 'black' }}>Oops! Something went wrong.</label>
										</div>
										{/*<div className="col-12">
									<Captcha siteKey='null' onResponse={this.showResponse}></Captcha>
		</div>*/}
										{//APP_PROPERTIES.APP_ID === 'dimensions' ?
											<div className='col-12' style={{ paddingTop: 110 }}>
												<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
												<div className='col-12'>
													<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
													{APP_PROPERTIES.INCLUDE_ONETRUST &&
														<React.Fragment>
															<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
															{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
															<a className="optanon-show-settings" style={{ margin: '0 5px', color: '#757575' }}>Cookie settings</a>
														</React.Fragment>
													}
													<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
													<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
												</div>
											</div>
											//:
											//null
										}
									</div>
								</div>
							</form>
						:
						<form //onSubmit={this.handleLogin}
						>
							<div className="login-panel-content">
								<div className="grid">
									<div className="col-12" style={{ textAlign: 'center' }}>
										<label style={{ fontSize: 20, color: 'black' }}>Password successfully reset. You will be redirected to the login page automatically.</label>
									</div>
									{/*<div className="col-12">
									<Captcha siteKey='null' onResponse={this.showResponse}></Captcha>
		</div>*/}
									{//APP_PROPERTIES.APP_ID === 'dimensions' ?
										<div className='col-12' style={{ paddingTop: 110 }}>
											<label style={{ color: '#757575' }}>© {getCurrentYear()} Digital Science & Research Solutions Inc.</label>
											<div className='col-12'>
												<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
												{APP_PROPERTIES.INCLUDE_ONETRUST &&
													<React.Fragment>
														<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
														{/*<a style={{ margin: '0 5px' }} href="https://www.dimensions.ai/policies-cookie/" target="_blank" rel="noopener noreferrer">Cookie policy</a>*/}
														<a className="optanon-show-settings" style={{ margin: '0 5px', color: '#757575' }}>Cookie settings</a>
													</React.Fragment>
												}
												<span style={{ marginLeft: 0, color: '#757575' }}>&#183;</span>
												<a style={{ margin: '0 5px', color: '#757575' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
											</div>
										</div>
										//:
										//null
									}
								</div>
							</div>
						</form>
					}
				</div>
			</div>
		)
	}
}
const mapStateToProps = (state) => ({
	authenticated: state.user.authenticated
})

const mapDispatchToProps = (dispatch) => ({
	doLoginSAML: (history, token) => dispatch(doLoginSAML(history, token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdatePassword))