/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { stopEventPropagation } from "../../../util";
//import { MdEdit } from "react-icons/md";
import { MdDone } from "react-icons/md";
import { MdClose } from "react-icons/md";


export const FILTER_MODE_INTERSECTION = "INTERSECTION";
export const FILTER_MODE_SUBTRACTION = "SUBTRACTION";
export const FILTER_MODES = [
    { value: FILTER_MODE_INTERSECTION, label: "Intersect with main query" },
    { value: FILTER_MODE_SUBTRACTION, label: "Subtract from main query" },
];

const FilterQueryTab = ({
    index,
    query,
    onClick,
    onFilterModeChange,
    onRemoveFilterQuery,
    styleClasses = "",
    tabWidth = 300,
}) => {

    const [edit, setEdit] = useState();

    const isMainQuery = index === 0;
    const label = index === 0 ? 'Main query' : `Filter query ${index}`;
    const queryLabel = query?.query || "No query";

    return <div
        title=""
        className={`col-fixed tile-container ${styleClasses}`}
        style={{ width: tabWidth }}
        onClick={() => onClick(index)}>
        <div className="tile-top"></div>
        <div className="tile">
            <div className="tile-content">
                <div className="grid grid-nogutter">
                    <div className="col">
                        {false && edit ?
                            <>
                                <InputText value={label} />
                                <span style={{ marginLeft: 10 }}><a className="secondaryLink" onClick={() => setEdit(false)}><MdDone /></a></span>
                                <span style={{ marginLeft: 10 }}><a className="secondaryLink" onClick={() => setEdit(false)}><MdClose /></a></span>
                            </>
                            :
                            <span onClick={() => setEdit(true)}>
                                <b>{label}</b>
                                {/*!isMainQuery && <span style={{ marginLeft: 5 }}><MdEdit /></span>*/}
                            </span>
                        }
                    </div>
                    {!isMainQuery && !edit && <div
                        onClick={(e) => { stopEventPropagation(e) }}
                        className="col remove-filter textAlignRight">
                        <a onClick={() => onRemoveFilterQuery(index)}>Remove</a>
                    </div>}
                </div>
                <div className="tile-teaser clampingContainer line-clamp line-clamp-2-lines">
                    {queryLabel}
                </div>
                {!isMainQuery && <div
                    className="tile-footer"
                //onClick={(e) => { stopEventPropagation(e) }}
                >
                    <Dropdown
                        value={query.filterMode}
                        options={FILTER_MODES}
                        onChange={(e) => { onFilterModeChange(index, e.value, e) }}
                        optionLabel="label"
                        placeholder={`${FILTER_MODES[0].label} (default)`}
                    />
                </div>}
            </div>
        </div>
    </div>
}

export default FilterQueryTab;