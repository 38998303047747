import { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ColorPicker } from 'primereact/colorpicker';
import { InputTextarea } from 'primereact/inputtextarea';

// @todo: cleanup

const DEFAULT_COLOR = 'dee2e6';

class EditCartridgeDialog extends Component {

    constructor(props) {
        super(props);
        //console.log (props);
        this.state = {
            newLabel: '',
            newColor: '',
            newDescription: '',
            input: '',
            isValid: false
        };
    }

    onHandleShow = () => {
        const isValid = this.isHexColor(this.props.color);
        const color = isValid ? this.props.color : DEFAULT_COLOR;
        this.setState({
            newLabel: this.props.label || this.props.name,
            newColor: color,
            newDescription: this.props.description,
            input: color,
            isValid: true
        });
    }

    isHexColor = (color) => {
        return !!color?.match(/[0-9A-Fa-f]{6}$/g);
    }

    render() {

        const footer = <>
            <Button label="Save"
                className='p-button-sm primaryButton'
                disabled={!this.state.newLabel || !this.state.newColor}
                onClick={() => this.props.onSubmit(this.props.id, 
                    { label: this.state.newLabel, name: this.props.name, description: this.state.newDescription, color: this.state.newColor })}
                style={{ float: 'right' }}
            />
            <Button label="Cancel"
                className='p-button-secondary p-button-sm'
                onClick={this.props.onHide}
                style={{ float: 'right', marginRight: 5 }}
            />
        </>

        return (
            <Dialog
                header={`Edit cartridge: ${this.props.name}`}
                footer={footer}
                visible={this.props.displayDialog}
                onShow={this.onHandleShow}
                onHide={this.props.onHide}
                className='styledDialog'>
                <div className="grid" style={{ marginTop: 10, width: 'fit-content', margin: 'auto', padding: '10px' }}>
                    <div className="col-fixed" style={{ width: 100 }}>Label</div>
                    <div className="col">
                        <InputText
                            value={this.state.newLabel}
                            onChange={(e) => { this.setState({ newLabel: e.target.value }) }}
                            style={{ width: '100%' }}>
                        </InputText>
                    </div>
                    <div className="breakRow"></div>
                    <div className="col-fixed" style={{ width: 100 }}>Description</div>
                    <div className="col">
                        <InputTextarea
                            value={this.state.newDescription}
                            autoResize
                            onChange={(e) => { this.setState({ newDescription: e.target.value }) }}
                            style={{ width: '100%' }}>
                        </InputTextarea>
                    </div>
                    <div className="breakRow"></div>
                    <div className="col-fixed" style={{ width: 100 }}>Color</div>
                    <div className="col-fixed">
                        <ColorPicker
                            value={this.state.newColor}
                            onChange={(e) => {
                                this.setState({
                                    newColor: e.value,
                                    input: e.value
                                })
                            }}
                            inline
                        />
                    </div>
                    <div className="col">
                        <span style={{ position: 'relative', left: 10 }}>#</span>
                        <InputText
                            value={this.state.input}
                            onChange={(e) => {
                                if (e.target.value?.length <= 6) {
                                    const newColor = e.target.value;
                                    const isValid = this.isHexColor(newColor);
                                    this.setState({
                                        input: newColor,
                                        newColor: isValid ? newColor : 'ffffff',
                                        isValid: isValid
                                    });
                                }
                            }}
                            className='p-inputtext-sm color-input'
                            keyfilter="hex"
                            style={{ width: 75, background: this.state.isValid ? '1px solid #ffffff' : '3px solid #ffa8b2' }}>
                        </InputText>
                    </div>
                    <div className="breakRow" />
                    <div className="col-fixed" style={{ width: 100 }}></div>
                    <div className="col">
                        {!this.state.isValid ?
                            'No preview available. Please provide a valid color.' :
                            <span>Preview of the chosen <span style={{ backgroundColor: `#${this.state.newColor}` }}>highlighting color</span> in a text.</span>
                        }
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default EditCartridgeDialog;