import { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { isArrayEmpty } from "../../util";
import { fetchTermSuggestions } from "../../../../api/content/ConceptApi";
import { checkResultAndGetPayload } from "../../../../api";
import AutoComplete from "../../../common/AutoComplete/AutoComplete";
import InputFieldWithClear from "../inputfields/InputFieldWithClear";


class FilterSectionAutocompleteTermloc extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }


    autocomplete = (event) => {
        //console.log('event: ', event);
        this.latestTermFragment = event.query;
        setTimeout(async () => {
            // --- fetch term suggestions --- //
            const response = await fetchTermSuggestions(event.query, this.props.filterDomains, true, false, false);
            const terms = checkResultAndGetPayload(response, this.growl);
            //console.log('autocomplete terms: ', terms);

            if (event.query === this.latestTermFragment && terms) {
                // --- no match --- //
                if (isArrayEmpty(terms) || terms[0].term !== event.query) {
                    terms.unshift({
                        domains: ['t'],
                        label: event.query + " (Text with variants)",
                        ocids: null,
                        prefnames: [event.query],
                        term: event.query,
                    });
                }

                this.setState({
                    suggestions: terms
                });
            }
        }, 200);
    }


    render() {

        const { filterID, placeholder, termlocQueryTerms, onFilterValuesChange } = this.props;

        return (
            <>
                <Toast ref={(el) => this.growl = el} />

                <div key={filterID} style={{ marginLeft: 0 }}>
                    <InputFieldWithClear
                        onClear={() => onFilterValuesChange({})}
                        showIcon={!isArrayEmpty(termlocQueryTerms)}>
                        <AutoComplete
                            className="width100perc"
                            field="term"
                            value={termlocQueryTerms}
                            suggestions={this.state.suggestions}
                            completeMethod={this.autocomplete}
                            placeholder={placeholder}
                            minLength={1}
                            multiple={true}
                            onChange={(e) => {
                                const filterObject = !isArrayEmpty(e.value) ? {
                                    filterID: filterID,
                                    termlocQueryTerms: e.value
                                } : {};
                                onFilterValuesChange(filterObject);
                            }}
                            autoHighlight={true}
                        //selectedItemTemplate={this.conceptTemplate}
                        />
                    </InputFieldWithClear>
                </div>
            </>
        );
    }
}

export default FilterSectionAutocompleteTermloc;