import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FileUpload } from 'primereact/fileupload';
import Cartridges from './Cartridges';
import { Message } from 'primereact/message';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import {
  fetchCartridges,
  uploadCartridge,
} from '../../../../redux/actions'

/**
 * Management of Cartridges
 */
class CartridgeManager extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // shared dir for available cartridges
      availableDirHost: undefined,

      // alert question
      warningModalVisible: false,
      warningModalProceedFunction: undefined
    };

    this.upload = this.upload.bind(this);
    this.showWarningModal = this.showWarningModal.bind(this);
    this.closeWarningModal = this.closeWarningModal.bind(this);
  }

  /**
   * initialise stuff, once the component is mounted
   * 
   * will fetch cartridges and download the `environment.json` to tell the user where to put available cartridges
   */
  componentDidMount() {
    this.props.fetchCartridges();
    fetch("../environment.json")
      .then((r) => r.json())
      .then((data) => {
        let dir = data.availableCartridgesHost;
        if (data.os === "windows")
          dir = dir.replace(/\//g, "\\")
        this.setState({ availableDirHost: dir });
      });
  }


  showWarningModal(proceedFunction) {
    console.log("showing warning modal");
    this.setState({
      warningModalProceedFunction: proceedFunction,
      warningModalVisible: true
    });
  }

  closeWarningModal(proceed) {
    if (proceed && this.state.warningModalProceedFunction)
      this.state.warningModalProceedFunction();
    this.setState({
      warningModalProceedFunction: undefined,
      warningModalVisible: false
    });
  }



  /**
   * render the component
   */
  render() {



    const warningModalFooter = (
      <div>
        <Button label="Proceed" icon="pi pi-check" onClick={() => this.closeWarningModal(true)} />
        <Button label="Cancel" icon="pi pi-times" onClick={() => this.closeWarningModal(false)} className="p-button-danger p-button-sm" />
      </div>
    );

    /*const warningModalIcon = (
        <button className="p-dialog-titlebar-icon p-link">
            <span className="pi pi-search"></span>
        </button>
    )*/


    // tell the user where to share available cartridges, if we know it from the `environment.json`
    let available = "";
    if (this.state.availableDirHost)
      available = <p>Available cartridges are stored in <code>{this.state.availableDirHost}</code> on your system. Copy cartridges there for an easier installation.</p>

    // is there already some upload going on? otherwise show an upload button
    let uploading = <FileUpload name="cartridges[]" url="./upload" mode="basic" accept="application/zip" auto={true} customUpload={true} multiple={false} uploadHandler={this.upload} maxFileSize={200000000}></FileUpload>;
    if (this.props.cartridgeUploading.length > 0)
      uploading = <Message className="info-loading" severity="info" text={" Processing " + this.props.cartridgeUploading}></Message>

    let cartridgesTables = <p> <i className="pi pi-spin pi-spinner"></i> Loading Cartridges </p>
    if (this.props.cartridgesLoaded)
      cartridgesTables = <div className="grid width100perc">
        <div className="col-12 uploadCartridge">
          <Fieldset legend="Upload a Cartridge">
            <p>Upload a new Cartridge from disk. Cartridges can be obtained from OntoChem. <strong>Please note, that installing a new cartridge will invalidate your repositories. Previously processed documents will be deleted.</strong></p>
            {/*<div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
              Label:
              <InputText
                value={this.state.newLabel}
                onChange={(e) => { this.setState({ newLabel: e.target.value }) }}
              >
              </InputText>
              Color:
              <InputText
                value={this.state.newColor}
                onChange={(e) => { this.setState({ newColor: e.target.value }) }}
              >
              </InputText>
              <div style={{ marginTop: 10, backgroundColor: this.state.newColor }}>Preview</div>
              {uploading}
            </div>*/}
            {uploading}
          </Fieldset>
        </div>
        <div className="col-12 md:col-12 lg:col-6 installesCartridges">
          <Fieldset legend="Installed Cartridges">
            <Cartridges
              cartridges={this.props.cartridges.filter(cartridge => cartridge.state === "INSTALLED")}
              fetchCartridges={() => this.fetchCartridges()}
              showWarningModal={this.showWarningModal}
              domainsMap={this.props.domainsMap} />
          </Fieldset>
        </div>
        <div className="col-12 md:col-12 lg:col-6 availableCartridges">
          <Fieldset legend="Available Cartridges">
            <Cartridges
              cartridges={this.props.cartridges.filter(cartridge => cartridge.state !== "INSTALLED")}
              fetchCartridges={() => this.fetchCartridges()}
              showWarningModal={this.showWarningModal}
              domainsMap={this.props.domainsMap} />
          </Fieldset>
        </div>
      </div >

    return (
      <div id="cartridgeManager">
        <h2>Cartridge Management</h2>
        <p>Here you can see a list of known cartridges. Uninstalled, but available cartridges are highlighted in green.</p>
        {available}

        {cartridgesTables}

        <Dialog header="Please Be Aware" footer={warningModalFooter} visible={this.state.warningModalVisible} style={{ width: '50vw' }} modal={true} onHide={() => this.closeWarningModal(false)}>
          Please note that any changes to your cartridge setup will invalidate your repositories and previously processed documents will be deleted.
        </Dialog>
      </div>
    )
  }


  /**
   * upload a zipped cartridge from disk
   * 
   * @param event event that triggered this call
   * @return true
   * 
   */
  upload(event, form) {
    if (this.props.cartridgeInstallingAvailable || this.props.cartridgeDeleting || this.props.cartridgeUploading.length > 0) {
      this.props.showGrowl("Changing cartridges not allowed", "The setup of cartridges is currently in process. Please try again later.");
      return;
    }

    // iterating files if upload was done using advanced FileUpload
    for (var f = 0; f < event.files.length; f++) {
      this.props.uploadCartridge(event.files[f]);
    }
    return true;
  }
}

/**
 * Creates a map with domain identifiers as keys and domain data as values. 
 * Inactive domains will be removed.
 * @param {*} domains array of domains
 * @returns map of domains
 */
const createDomainsMap = (domains) => {
  const domainsMap = {};
  domains?.forEach(domain => {
    if (domain.active) {
      domainsMap[domain.name] = domain;
    }
  });
  return domainsMap;
}

const mapStateToProps = state => {
  return {
    cartridges: state.managerReducer.cartridges,
    cartridgesLoaded: state.managerReducer.cartridgesLoaded,
    cartridgeUploading: state.managerReducer.cartridgeUploading,
    cartridgeDeleting: state.managerReducer.cartridgeDeleting,
    cartridgeInstallingAvailable: state.managerReducer.cartridgeInstallingAvailable,
    cartridgeDomainAdding: state.managerReducer.cartridgeDomainAdding,
    cartridgeDomainEditing: state.managerReducer.cartridgeDomainEditing,
    domainsMap: createDomainsMap(state.user.data.userDetails.department.selectedDomains)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCartridges: () => dispatch(fetchCartridges()),
    uploadCartridge: (file) => dispatch(uploadCartridge(file)),
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartridgeManager);
