/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import {
    getStatisticsForQuery,
    getSummaryForWatchlist
    //, getDocumentsForQuery, exportDocumentsForQuery
} from '../../../api/content/WatchlistApi';
import { checkResultAndGetPayload } from '../../../api';
//import { createNewApp } from "../../../api/content/AppsApi";
//import { Element, animateScroll as scroller } from 'react-scroll';
//import axios from 'axios'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import dateFormat from 'dateformat';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
//import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea'
import { MultiSelect } from 'primereact/multiselect';
import { TreeSelect } from 'primereact/treeselect';
//import { Paginator } from 'primereact/paginator';
import {
    APP_PROPERTIES,
    QUERY_TYPE_COOC_SEARCH,
    QUERY_CATEGORY_COOC_SEARCH,
    QUERY_TYPE_FACT_SEARCH,
    QUERY_CATEGORY_FACT_SEARCH,
    QUERY_TYPE_QUICK_SEARCH_2,
    QUERY_TYPE_ADVANCED_SEARCH,
    QUERY_TYPE_QUICK_SEARCH,
    QUERY_CATEGORY_SENTENCE_ANALYSIS_SEARCH,
    QUERY_CATEGORY_CHEM_SEARCH,
    readFilterDefinitions,
    QUERY_TYPE_CHEM_SEARCH,
    QUERY_TYPE_ADVANCED_SEARCH_3,
    LOCAL_STORAGE_DOC_FINDER_DATA,
    QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH,
    QUERY_TYPES_MAP
} from '../../../properties';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import moment from 'moment';
import { cloneDeep, hasUserRole, addThousandsSeparatorToNumber, createCheckedKeys } from '.././util'
import { addDataToLocalStorage } from '../util/localStorage';

import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'
import { createQuickSearchObjectFromSavedSearchAlert } from '../general/docsearch/searchUtil';

import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";

import CreateEditApp from '../../common/App/CreateEditApp'

//import { editQuery } from '../../../redux/actions';


const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate());
//const LAST_MONTH = new Date(TODAY.getFullYear(), TODAY.getMonth() - 1, TODAY.getDate());
//const LAST_WEEK = new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() - 7);

class WatchlistQueries extends Component {
    constructor(props) {
        super(props);
        /*,
            
                field: "fill",
                header: '',
                body: this.actionTemplateFillForm,
                style: { textAlign: 'left', fontWeight: 'normal' }
            */
        let columns = [
            {
                field: "check",
                selectionMode: 'multiple',
                style: { width: '4em', fontWeight: 'normal' }
            },
            //{ field: "read", sortable: true, header: `${APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons ? 'Status' : 'Status'}`, style: { width: '70px', textAlign: 'left' }, body: this.readTemplate },
            {
                field: "fullName",
                header: "Search",
                body: this.titleTemplate,
                sortable: true,
                style: { width: '35%' }
            },
            {
                field: "type",
                header: "Type",
                body: this.typeTemplate,
                sortable: true,
                style: { width: '8em', fontWeight: 'normal', textAlign: 'left' }
            },
            {
                field: "created",
                header: "Added",
                body: this.insertionTimeTemplate,
                sortable: true,
                style: { width: '7.2em', textAlign: 'left' }
            },
            {
                field: "modified",
                header: 'Last modified',
                body: this.modifiedTemplate,
                sortable: true,
                style: { width: '9.8em', textAlign: 'left' }
            },
            {
                field: "statistics",
                header: 'Trend analysis',
                body: this.statisticsTemplate,
                style: { width: '9em', textAlign: 'left', fontWeight: 'normal' }
            },
            {
                field: "hits",
                header: "New hits",
                body: this.newResultsTemplate,
                sortable: true,
                style: { width: '8em', textAlign: 'left', fontWeight: 'normal' }
            },
            {
                field: "alarm",
                header: "Alert",
                body: this.alarmTemplateQuery,
                style: { width: '7.5em', textAlign: 'left', fontWeight: 'normal' }
            },
            {
                field: "edit",
                header: '',
                body: this.actionTemplateEdit,
                style: { width: '5em', textAlign: 'left', fontWeight: 'normal' }
            }
        ]
        let columnsWatchlists = [
            { field: "check", selectionMode: 'single', style: { textAlign: 'center', fontWeight: 'normal' } },
            { field: "name", header: "Name", sortable: true, style: {}, body: this.nameTemplate },
            {
                field: "numberQueries",
                header: "Searches",
                sortable: true,
                style: { fontWeight: 'normal', textAlign: 'left' },
                body: this.numberQueriesTemplate
            },
            { field: "", header: "Sharing", body: this.sharedTemplate, sortable: true, style: { textAlign: 'left' } }
        ]
        this.state = {
            cols: columns,
            colsWatchlists: columnsWatchlists,
            activeOptions: [
                { label: 'Active', value: 'active' },
                { label: 'Paused', value: 'paused' }
            ],
            globalFilterQueries: '',
            globalFilterWatchlists: '',
            queryHitsTable: [],
            displayCreateAppDialog: false
        }
    }

    componentDidMount() {
        var self = this;

        if (self.props.startTime !== null && self.props.selectedWatchlist !== undefined) {
            self.fetchQueryOverviewData(self.props.startTime, self.props.selectedWatchlist.id)
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.startTime !== this.props.startTime && this.props.startTime !== null && this.props.selectedWatchlist === undefined && this.props.filteredQuery !== null) {
            let filterQuery = this.props.selectedQueries.filter(obj => {
                return obj.id === Number(this.props.filteredQuery)
            })
            //if (filterQuery[0].type === QUERY_TYPE_QUICK_SEARCH.id || filterQuery[0].type === QUERY_TYPE_QUICK_SEARCH_2.id) {
            // console.log("if")
            /*addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, { data: { ...filterQuery[0], edit: !filterQuery[0].queryCollectionList[0] || !filterQuery[0].queryCollectionList[0].shared } });
            //window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank")
            this.props.history.push({ pathname: '/docfinder/quicksearch' })*/
            // this.loadAlertQueryIntoQuickSearch(filterQuery[0], this.props.startTime)
            // } else {
            //    this.executeNewTab(filterQuery[0], this.props.startTime)
            //}
            this.fetchHitsForQuery(this.props.startTime, this.props.filteredQuery, filterQuery[0])
        }

        if (prevProps.startTime !== this.props.startTime && this.props.startTime !== null && this.props.selectedWatchlist !== undefined) {
            let filterQuery = this.props.selectedQueries.filter(obj => {
                return obj.id === Number(this.props.filteredQuery)
            })
            this.fetchQueryOverviewData(this.props.startTime, this.props.selectedWatchlist.id, filterQuery[0])
        }
    }

    sharedTemplate(rowData) {
        return <div style={{ marginBottom: 5 }}>
            <span className="p-column-title">Sharing</span>
            {!rowData.shared ?
                ((rowData.departmentSharesRead && rowData.departmentSharesRead.length > 0) || (rowData.userSharesRead && rowData.userSharesRead.length > 0) || (rowData.userSharesWrite && rowData.userSharesWrite.length > 0)) ?
                    <span title={`You share this search collection with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This search collection can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This search collection is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }

    titleTemplate = (rowData) => {
        if (rowData.fullName) {
            //console.log(rowData.queryCollectionList[0])
            let title = rowData.fullName
            let collectionList = ''
            if (rowData.queryCollectionList !== undefined && rowData.queryCollectionList.length > 0) {
                rowData.queryCollectionList.forEach(list => {
                    collectionList += list.name + ', '
                })
                collectionList = collectionList.substring(0, collectionList.length - 2)
            }
            let editable = true

            /*if (rowData.queryCollectionList && rowData.queryCollectionList.length > 0) {
                rowData.queryCollectionList.every(list => {
                    if (list.shared && !list.writable) {
                        editable = false
                        return false
                    }
                    return true
                })
            }*/
            if (!rowData.isCurrentUserOwner) {
                editable = false
            }
            // console.log(editable)
            return <div>
                <span className="p-column-title">Search</span>
                <span style={{ marginBottom: 5 }}>
                    {/*<a onClick={(e) => this.run(rowData, e)}>{title}</a>*/}
                    {rowData.type === QUERY_TYPE_COOC_SEARCH.id && hasUserRole("ROLE_COOCS") ?
                        <a onClick={() => this.onSendToCoocSearch({
                            ...rowData,
                            edit: editable
                        })} title="Click to load into Co-occurence Analysis">{title}</a>
                        :
                        rowData.type === QUERY_TYPE_COOC_SEARCH.id && !hasUserRole("ROLE_COOCS") ?
                            <span>{title}</span>
                            :
                            rowData.type === QUERY_TYPE_CHEM_SEARCH.id && (hasUserRole("ROLE_COMPOUNDSEARCH") || hasUserRole("ROLE_REACTIONSEARCH")) ?
                                <a onClick={() => this.onSendToChemSearch({
                                    ...rowData,
                                    edit: editable
                                })} title="Click to load into Chemistry Search">{title}</a>
                                :
                                rowData.type === QUERY_TYPE_CHEM_SEARCH.id ?
                                    <span>{title}</span>
                                    :
                                    rowData.type === QUERY_TYPE_FACT_SEARCH.id && hasUserRole("ROLE_PATHSEARCH") ?
                                        <a onClick={() => this.executeNewTabFact(rowData)} title="Click to load into Fact Finder">
                                            {title}
                                        </a>
                                        :
                                        rowData.type === QUERY_TYPE_FACT_SEARCH.id && !hasUserRole("ROLE_PATHSEARCH") ?
                                            <span>{title}</span>
                                            :
                                            rowData.type === QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id && hasUserRole("ROLE_SENTENCEANALYSIS") ?
                                                <a onClick={() => this.onSendToSentenceAnalysis({
                                                    data: {
                                                        ...rowData,
                                                        edit: editable
                                                    }
                                                })} title="Click to load into Sentence Analysis">
                                                    {title}
                                                </a>
                                                :
                                                rowData.type === QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id && !hasUserRole("ROLE_SENTENCEANALYSIS") ?
                                                    <span>{title}</span>
                                                    :
                                                    // quick search 1.0 and 2.0
                                                    rowData.type === QUERY_TYPE_QUICK_SEARCH.id || rowData.type === QUERY_TYPE_QUICK_SEARCH_2.id ?
                                                        <a onClick={() => this.onSendToQuickSearch({
                                                            data: {
                                                                ...rowData,
                                                                edit: editable
                                                            }
                                                        })}
                                                            title="Click to load into Quick Search">{title}</a>
                                                        :
                                                        <a onClick={() => this.executeNewTab(rowData, null, editable)}
                                                            title="Click to load into Advanced Search">
                                                            {title}
                                                        </a>
                    }
                </span>
                {/*
                    rowData.fullName ?
                        <span style={{ display: 'block', paddingTop: 5 }}>
                            <label>{rowData.fullName}</label>
                        </span>
                        :
                        null
                        */}
                {/*<span style={{ display: 'block', paddingTop: 5 }}>
                            {!rowData.shared ?
                                (rowData.sharedForDepartment || (rowData.usersSharedWith && rowData.usersSharedWith.length > 0)) ?
                                    <span title={`You share this search collection with other users`}>
                                        {' - Shared by you'}
                                    </span>
                                    :
                                    <span title={`This search collection can only be seen by you`}>
                                        {' - Private'}
                                    </span>
                                :
                                <span title={`This search collection is shared with you by another user`}>
                                    {' - Shared with you'}
                                </span>
                            }
                        </span>
                }
                {/*
                    rowData.description !== '' &&
                    <span style={{ display: 'block', paddingTop: 5 }}>
                        <label >{rowData.description}</label>
                    </span>
                */}
                {rowData.owner ?
                    <span style={{ display: 'block', paddingTop: 5 }}>
                        <label style={{ color: '#757575' }}>{`Owner: ${rowData.owner?.lastName}, ${rowData.owner?.forename}`}</label>
                    </span>
                    : null
                }
                {this.props.selectedWatchlist === undefined && collectionList !== '' ?
                    <span style={{ display: 'block', paddingTop: 5 }}>
                        <label style={{ color: '#757575' }}>In search collections: {collectionList}</label>
                    </span>
                    : rowData.queryCollectionList && rowData.queryCollectionList.length >= 2 ?
                        <span style={{ display: 'block', paddingTop: 5 }}>
                            <label style={{ color: '#757575' }}>In search collections: {collectionList}</label>
                        </span>
                        : null
                }
            </div>
        }
    }

    typeTemplate(rowData) {
        //console.log((rowData.type.charAt(0).toUpperCase() + rowData.type.slice(1)).replace(/_/g, ' '))
        let type = ''
        if (rowData.type) {
            if (rowData.type === QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id) {
                type = QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.label
                if (rowData.value) {
                    let length = 17
                    if (rowData.value.length > 17) {
                        type += ` (${rowData.value.substring(0, length - 3)} ...)`
                    } else {
                        type += ` (${rowData.value})`
                    }
                }
            }
            else if (QUERY_TYPES_MAP[rowData.type]?.label) {
                type = QUERY_TYPES_MAP[rowData.type].label;
            }
            else {
                type = (rowData.type.charAt(0).toUpperCase() + rowData.type.slice(1)).replace(/_/g, ' ')
            }
        }
        return <React.Fragment>
            <span className="p-column-title">Type</span>
            <label title={rowData.type === QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id ? `${QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.label} (${rowData.value ? rowData.value : ''})` : type}> {type}</label>
        </React.Fragment>
    }
    insertionTimeTemplate = (rowData) => {
        //console.log(rowData)
        if (rowData.created) {
            return <React.Fragment>
                <span className="p-column-title">Added</span>
                <label
                    title={moment(rowData.created + "Z").format("YYYY-MM-DD")}>{moment(rowData.created + "Z").format("YYYY-MM-DD")}</label>
            </React.Fragment>
        }
    }

    modifiedTemplate = (rowData) => {
        //console.log(rowData)
        if (rowData.modified !== undefined) {
            return <React.Fragment>
                <span className="p-column-title">Last modified</span>
                <label
                    title={moment(rowData.modified + "Z").format("YYYY-MM-DD")}>{moment(rowData.modified + "Z").format("YYYY-MM-DD")}</label>
            </React.Fragment>
        }
    }

    alarmTemplateQuery = (rowData) => {

        // --- alerts not allowed for cooc queries (atm) --- //
        if (rowData.category === QUERY_CATEGORY_COOC_SEARCH.id || rowData.category === QUERY_CATEGORY_FACT_SEARCH.id ||
            rowData.category === QUERY_CATEGORY_SENTENCE_ANALYSIS_SEARCH.id || QUERY_CATEGORY_CHEM_SEARCH) {
            return <React.Fragment>
                <span className="p-column-title">Alert</span>
                <label title="Not available">Not available</label>
            </React.Fragment>
        }

        if (rowData.notifications.length === 0) {
            return <React.Fragment>
                <span className="p-column-title">Alert</span>
                <i className="material-icons-outlined" id='read'
                    title={'Add alarm to this search'}
                    style={{ marginTop: -4, cursor: 'pointer' }}
                    onClick={(e) => //this.props.watchlistAlertChangeQuery(rowData, 'add', e)}
                        this.props.openAddAlertDialog(rowData, 'query')}>
                    {'notification_add'}
                </i>
            </React.Fragment>
        } else if (rowData.notifications.length > 0 && rowData.notifications[0].active) {
            return <React.Fragment>
                <span className="p-column-title">Alert</span>
                <i className="material-icons" id='read'
                    title={'You receive alerts for this search'}
                    style={{ marginTop: -4, cursor: 'pointer' }}
                    onClick={(e) => //this.props.watchlistAlertChangeQuery(rowData, 'pause', e)}>
                        this.props.openAddAlertDialog(rowData, 'query')}>
                    {'notifications_active'}
                </i>
            </React.Fragment>
        } else if (rowData.notifications.length > 0 && !rowData.notifications[0].active) {
            return <React.Fragment>
                <span className="p-column-title">Alert</span>
                <i className="material-icons" id='read'
                    title={'Alerts for this search are paused'}
                    style={{ marginTop: -4, cursor: 'pointer' }}
                    onClick={(e) => //this.props.watchlistAlertChangeQuery(rowData, 'activate', e)}>
                        this.props.openAddAlertDialog(rowData, 'query')}>
                    {'notifications_paused'}
                </i>
            </React.Fragment>
        }
    }

    loadAlertQueryIntoQuickSearch = async (rowData, startTime) => {
        //console.log(rowData)
        //console.log(typeof startTime)
        // http://oc123:3000/my/savedsearches?searchcol=435&query=na&date=2010-02-24
        // http://oc123:3000/my/savedsearches?searchcol=na&query=589&date=2010-02-24
        // http://oc123:3000/my/savedsearches?searchcol=na&query=640&date=2010-02-24
        const data = createQuickSearchObjectFromSavedSearchAlert(cloneDeep(rowData), startTime, this.state.filterDefinitions);
        if (data) {
            addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, { data: { ...data, edit: false } });
            window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank");
        }
        else {
            this.growl.show({ closable: true, life: 10000, severity: 'error', summary: 'Unable to load search.', detail: `Either search format or insertion date filter are unknown.` });
        }
    }

    newResultsTemplate = (rowData) => {
        if (rowData.hits !== undefined) {
            let type = ''
            if (rowData.type) {
                if (rowData.type === QUERY_TYPE_QUICK_SEARCH_2.id) {
                    type = QUERY_TYPE_QUICK_SEARCH_2.label
                } else if (rowData.type === QUERY_TYPE_QUICK_SEARCH.id) {
                    type = QUERY_TYPE_QUICK_SEARCH.label
                } else if (rowData.type === QUERY_TYPE_COOC_SEARCH.id) {
                    type = QUERY_TYPE_COOC_SEARCH.label;
                } else if (rowData.type === QUERY_TYPE_FACT_SEARCH.id) {
                    type = QUERY_TYPE_FACT_SEARCH.label
                } else if (rowData.type === QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id) {
                    type = QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.label
                    if (rowData.value) {
                        type += ` (${rowData.value})`
                    }
                }
                else {
                    type = (rowData.type.charAt(0).toUpperCase() + rowData.type.slice(1)).replace(/_/g, ' ')
                }
            }
            return <React.Fragment>
                <span className="p-column-title">New hits</span>
                {rowData.hits > 0 && rowData.type !== QUERY_TYPE_ADVANCED_SEARCH.id ?
                    <a title={`Click to load into ${type} with insertion date ${moment(this.props.startTime).format("YYYY-MM-DD")}`} onClick={() => this.loadAlertQueryIntoQuickSearch(rowData, this.props.startTime)}>
                        {addThousandsSeparatorToNumber(rowData.hits)}
                    </a>
                    :
                    rowData.hits > 0 && rowData.type === QUERY_TYPE_ADVANCED_SEARCH.id ?
                        <a
                            title={`Click to load into ${type} with insertion date ${moment(this.props.startTime).format("YYYY-MM-DD")}`} onClick={() => this.executeNewTab(rowData, this.props.startTime)}>
                            {addThousandsSeparatorToNumber(rowData.hits)}
                        </a>
                        :
                        <label>{addThousandsSeparatorToNumber(rowData.hits)}</label>
                }
                {/*<label title={rowData.hits}>{rowData.hits}</label>*/}
            </React.Fragment>
        } else {
            return <React.Fragment>
                <span className="p-column-title">New hits</span>
                <label title="Not available">Not available</label>
                {/*<label title={rowData.hits}>{rowData.hits}</label>*/}
            </React.Fragment>
        }
    }

    actionTemplateEdit = (rowData) => {
        if (rowData.formContent) {
            let editable = true

            /*if (rowData.queryCollectionList && rowData.queryCollectionList.length > 0) {
                rowData.queryCollectionList.every(list => {
                    if (list.shared && !list.writable) {
                        editable = false
                        return false
                    }
                    return true
                })
            }*/
            if (!rowData.isCurrentUserOwner) {
                editable = false
            }
            return <div>
                {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons ?
                    <Button type="button" icon="pi-md-edit" className="p-button-rounded purple-btn"
                        disabled={!editable ? true : false}
                        onClick={() => this.edit(rowData)}>

                    </Button>
                    :
                    ((rowData.type === QUERY_TYPE_FACT_SEARCH.id && !hasUserRole("ROLE_PATHSEARCH")) ||
                        (rowData.type === QUERY_TYPE_COOC_SEARCH.id && !hasUserRole("ROLE_COOCS")) ||
                        (rowData.type === QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id && !hasUserRole("ROLE_SENTENCEANALYSIS"))) ?
                        <span>Feature not available</span>
                        :
                        <a onClick={() => this.edit(rowData)}>
                            Details
                        </a>
                }
            </div>
        }
    }

    statisticsTemplate = (rowData) => {
        if (rowData.type === QUERY_TYPE_QUICK_SEARCH.id || rowData.type === QUERY_TYPE_QUICK_SEARCH_2.id || rowData.type === QUERY_TYPE_ADVANCED_SEARCH.id) {

            const showStatistics = () => {
                let searchParameters
                if (rowData.hasOwnProperty("filterQueries")) {
                    searchParameters = { 'searchName': rowData.fullName, 'searchParameters': { query: rowData.value, filterQueries: rowData.filterQueries, formContent: rowData.formContent } };
                } else {
                    searchParameters = { 'searchName': rowData.fullName, 'searchParameters': { query: rowData.value } };
                }

                localStorage.setItem('searchParameters', JSON.stringify(searchParameters));

                //const win = window.open(`/analytics/trendanalysis`);
                window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/trendanalysis`, "_blank")
                //win.focus();
            }

            return (
                <div>
                    <span className="p-column-title">Trend analysis</span>
                    <a onClick={showStatistics}>Show</a>
                </div>
            )
        } else {
            return <React.Fragment>
                <span className="p-column-title">Trend analysis</span>
                <label title="Not available">Not available</label>
            </React.Fragment>
        }
    }

    edit = (rowData) => {
        let isShared = false

        /*if (rowData.queryCollectionList && rowData.queryCollectionList.length > 0) {
            rowData.queryCollectionList.every(list => {
                if (list.shared && !list.writable) {
                    isShared = true
                    return false
                }
                return true
            })
        }*/
        if (!rowData.isCurrentUserOwner) {
            isShared = true
        }

        let collectionList = ''
        if (rowData.queryCollectionList) {
            rowData.queryCollectionList.forEach(list => {
                collectionList += list.name + ', '
            })
        }
        collectionList = collectionList.substring(0, collectionList.length - 2)

        let collections = []
        if (rowData.queryCollectionList) {
            rowData.queryCollectionList.forEach(collection => {
                collections = [...collections, collection.id]
            })
        }

        let formContent;
        let blocklistIDs = null;
        let repositoryIDs = null;
        let tempExcludedTerms = null;
        if (rowData.formContent && typeof (rowData.formContent) === 'object') {
            formContent = JSON.parse(rowData.formContent);
            //console.log('formContent: ', formContent);
            if (formContent) {
                blocklistIDs = formContent.blacklistIDs && formContent.blacklistIDs.length > 0 ? formContent.blacklistIDs : null;
                repositoryIDs = formContent.repositoryIDs && formContent.repositoryIDs.length > 0 ? formContent.repositoryIDs : null;
                tempExcludedTerms = formContent.tempExcludedTerms && Object.keys(formContent.tempExcludedTerms).length > 0 ? formContent.tempExcludedTerms : null;
            }
        }

        let queryString = <div style={{ color: '#757575', cursor: 'default', paddingTop: 5 }}>
            {!rowData.filterQueriesJsonString || rowData.filterQueriesJsonString === null ||
                rowData.filterQueriesJsonString === 'null' ? "" + rowData.value : "Main search: " + rowData.value} {
                rowData.filterQueriesJsonString && rowData.filterQueriesJsonString !== null && rowData.filterQueriesJsonString !== 'null' ?
                    <div style={{ paddingTop: 5 }}>Filter search: {rowData.filterQueriesJsonString}</div>
                    : null
            }</div>

        //console.log(queryString)

        this.setState({
            queryNameEdit: rowData.name,
            queryFullNameEdit: rowData.fullName,
            queryDescriptionEdit: rowData.description,
            queryTypeEdit: rowData.type,
            queryFormContentEdit: rowData.formContent,
            queryCollectionsEdit: collectionList,
            queryStringEdit: queryString, //rowData.value,
            queryValue: rowData.value,
            queryFormEdit: rowData.form,
            queryIDEdit: rowData.id,
            collectionsOld: collections,
            selectedCollections: collections,
            blocklistIDs: blocklistIDs,
            repositoryIDs: repositoryIDs,
            tempExcludedTerms: tempExcludedTerms,
            showCharts: false,
            showPublications: false,
            isShared: isShared,
            filterQueries: rowData.filterQueries,
            filterQueriesJsonString: rowData.filterQueriesJsonString
        }, () => {
            this.props.openEditQuery()
        })
    }

    /*actionTemplateFillForm = (rowData) => {
        if (rowData.formContent) {
            return <div>
                {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons ?
                    <Button type="button" icon="pi-md-edit" className="p-button-rounded p-button-warning"
                        disabled={rowData.queryCollectionList && rowData.queryCollectionList[0] && rowData.queryCollectionList[0].shared ? true : false}
                        onClick={() => this.execute(rowData)}>
                    </Button>
                    :
                    rowData.queryCollectionList && rowData.queryCollectionList[0] && rowData.queryCollectionList[0].shared ?
                        // quick search 1.0 and 2.0
                        rowData.type === QUERY_TYPE_QUICK_SEARCH.id || rowData.type === QUERY_TYPE_QUICK_SEARCH_2.id ?
                            <a onClick={() => {
                                addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, { data: { ...rowData, edit: false } });
                                window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank");
                            }}>Load search</a>
                            :
                            null
                        :
                        ((rowData.type === QUERY_TYPE_FACT_SEARCH.id && !hasUserRole("ROLE_PATHSEARCH")) ||
                            (rowData.type === QUERY_TYPE_COOC_SEARCH.id && !hasUserRole("ROLE_COOCS"))) ?
                            <span>Feature not available</span>
                            :
                            rowData.type === QUERY_TYPE_COOC_SEARCH.id ?
                                <a disabled={rowData.queryCollectionList && rowData.queryCollectionList[0] && rowData.queryCollectionList[0].shared && !rowData.queryCollectionList[0].writable}
                                    onClick={() => this.onSendToCoocSearch({
                                        ...rowData, edit: !rowData.queryCollectionList[0] ||
                                            !rowData.queryCollectionList[0].shared || rowData.queryCollectionList[0].writable
                                    })}>
                                    Edit search</a>
                                :
                                rowData.type === QUERY_TYPE_FACT_SEARCH.id ?
                                    <a
                                        disabled={rowData.queryCollectionList && rowData.queryCollectionList[0] && rowData.queryCollectionList[0].shared && !rowData.queryCollectionList[0].writable}
                                        onClick={() => this.executeFact(rowData)}>
                                        Edit search
                                    </a>
                                    :
                                    // quick search 1.0 and 2.0
                                    rowData.type === QUERY_TYPE_QUICK_SEARCH.id || rowData.type === QUERY_TYPE_QUICK_SEARCH_2.id ?
                                        <a onClick={() => {
                                            addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, { data: { ...rowData, edit: true } });
                                            window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank");
                                        }}>Edit search</a>
                                        :
                                        // advanced search
                                        <a disabled={rowData.queryCollectionList && rowData.queryCollectionList[0] && rowData.queryCollectionList[0].shared ? true : false}
                                            onClick={() => this.execute(rowData)}>
                                            Edit search
                                        </a>
                }
            </div>
        }
    }*/

    /*execute = (rowData) => {
        let collectionList = ''
        if (rowData.queryCollectionList) {
            rowData.queryCollectionList.forEach(list => {
                collectionList += list.name + ', '
            })
        }
        collectionList = collectionList.substring(0, collectionList.length - 2)

        let collections = []
        if (!rowData.queryCollectionList || (rowData.queryCollectionList && rowData.queryCollectionList[0] && rowData.queryCollectionList[0].shared)) {
            collections = []
        } else {
            rowData.queryCollectionList.forEach(collection => {
                collections = [...collections, collection.id]
            })
        }
        //console.log(rowData)
        this.setState({
            showDocSearch: true,
            selectedCollectionQuery: rowData.name,
            selectedCollectionQueryFullName: rowData.fullName,
            selectedCollectionQueryTool: rowData.tool,
            selectedCollectionQueryType: rowData.type,
            selectedCollectionQueryID: rowData.id,
            selectedCollectionQueryDescription: rowData.description,
            formContent: rowData.formContent,
            value: rowData.value,
            type: rowData.type,
            queryRowData: rowData,
            selectedLogicalOperator: rowData.logicalOperator,
            showCharts: false,
            showPublications: false,
            queryName: collectionList,
            collectionID: this.props.selectedWatchlist ? this.props.selectedWatchlist.id : undefined
        }, () => {
            let queryObject = {
                edit: true,
                selectedCollectionQuery: rowData.name,
                selectedCollectionQueryFullName: rowData.fullName,
                selectedCollectionQueryTool: rowData.tool,
                selectedCollectionQueryType: rowData.type,
                selectedCollectionQueryID: rowData.id,
                selectedCollectionQueryDescription: rowData.description,
                formContent: rowData.formContent,
                value: rowData.value,
                type: rowData.type,
                queryRowData: rowData,
                selectedLogicalOperator: rowData.logicalOperator,
                queryName: collections,
                collectionID: this.props.selectedWatchlist ? this.props.selectedWatchlist.id : undefined,
                //noAutoRun: rowData.type === QUERY_TYPE_ADVANCED_SEARCH.id
            }
            // --- get queries from local storage --- //
            let queryObjectDocumentFinder = localStorage.getItem('docFinderData');
            let docFinderData = queryObjectDocumentFinder ? JSON.stringify(queryObject) : JSON.stringify(queryObject)
            //localStorage.setItem('docFinderData', docFinderData)
            //window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank")
            if (rowData.type === QUERY_TYPE_QUICK_SEARCH.id) {
                this.props.history.push({ pathname: '/docfinder/quicksearch', docFinderData: docFinderData })
            } else {
                this.props.history.push({ pathname: '/docfinder/advancedsearch', docFinderData: docFinderData })
            }

        })
    }*/

    /*executeFact = (rowData) => {
        let queryObject = rowData
        queryObject["editQuery"] = true
        //console.log(queryObject)
        // --- get queries from local storage --- //
        let queryObjectFactFinder = localStorage.getItem('factFinderData');
        let factFinderData = queryObjectFactFinder ? JSON.stringify(queryObject) : JSON.stringify(queryObject)
        //localStorage.setItem('docFinderData', docFinderData)
        //window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank")
        this.props.history.push({ pathname: '/analytics/factfinder', factFinderData: factFinderData })
    }*/

    executeNewTab = (rowData, time, editable = true) => {
        //console.log(rowData)
        //console.log(editable)
        if (time !== undefined && rowData.formContent && rowData.type !== QUERY_TYPE_ADVANCED_SEARCH.id && rowData.type !== QUERY_TYPE_ADVANCED_SEARCH_3.id) {
            let formContentParsed = JSON.parse(rowData.formContent)
            if (formContentParsed.filters === undefined) {
                formContentParsed.filters = {}
                formContentParsed.filters.generalFilters = {}
                formContentParsed.filters.generalFilters.startInsertionDate = time
            } else if (formContentParsed.filters.generalFilters === undefined) {
                formContentParsed.filters.generalFilters = {}
                formContentParsed.filters.generalFilters.startInsertionDate = time
            } else if (formContentParsed.filters && formContentParsed.filters.generalFilters && formContentParsed.filters.generalFilters.startInsertionDate) {
                formContentParsed.filters.generalFilters.startInsertionDate = time
            }
            rowData.formContent = JSON.stringify(formContentParsed)
        }
        let collectionList = ''
        if (rowData.queryCollectionList) {
            rowData.queryCollectionList.forEach(list => {
                collectionList += list.name + ', '
            })
        }
        collectionList = collectionList.substring(0, collectionList.length - 2)

        /*let collections = []
        if (!rowData.queryCollectionList || (rowData.queryCollectionList && rowData.queryCollectionList[0] && rowData.queryCollectionList[0].shared)) {
            collections = []
        } else {
            rowData.queryCollectionList.forEach(collection => {
                collections = [...collections, collection.id]
            })
        }*/

        let collections = []
        if (rowData.queryCollectionList) {
            rowData.queryCollectionList.forEach(collection => {
                collections = [...collections, collection.id]
            })
        }

        this.setState({
            showDocSearch: true,
            selectedCollectionQuery: rowData.name,
            selectedCollectionQueryFullName: rowData.fullName,
            selectedCollectionQueryTool: rowData.tool,
            selectedCollectionQueryType: rowData.type,
            selectedCollectionQueryID: rowData.id,
            selectedCollectionQueryDescription: rowData.description,
            formContent: rowData.formContent,
            value: rowData.value,
            type: rowData.type,
            queryRowData: rowData,
            selectedLogicalOperator: rowData.logicalOperator,
            showCharts: false,
            showPublications: false,
            queryName: collectionList,
            collectionID: this.props.selectedWatchlist ? this.props.selectedWatchlist.id : undefined
        }, () => {
            let queryObject = {
                edit: editable,
                selectedCollectionQuery: rowData.name,
                selectedCollectionQueryFullName: rowData.fullName,
                selectedCollectionQueryTool: rowData.tool,
                selectedCollectionQueryType: rowData.type,
                selectedCollectionQueryID: rowData.id,
                selectedCollectionQueryDescription: rowData.description,
                formContent: rowData.formContent,
                value: rowData.value,
                type: rowData.type,
                queryRowData: rowData,
                selectedLogicalOperator: rowData.logicalOperator,
                queryName: collections,
                collectionID: this.props.selectedWatchlist ? this.props.selectedWatchlist.id : undefined,
                startTime: time ? time : undefined,
                // auto run search if it comes from email alerts
                noAutoRun: !time && (rowData.type === QUERY_TYPE_ADVANCED_SEARCH.id || rowData.type === QUERY_TYPE_ADVANCED_SEARCH_3.id)
            }
            // --- get queries from local storage --- //
            let queryObjectDocumentFinder = localStorage.getItem(LOCAL_STORAGE_DOC_FINDER_DATA);
            if (rowData.type === QUERY_TYPE_QUICK_SEARCH.id) {
                localStorage.setItem(LOCAL_STORAGE_DOC_FINDER_DATA, JSON.stringify(queryObject))
                window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank")
            }
            else if (rowData.type === QUERY_TYPE_ADVANCED_SEARCH.id) {
                localStorage.setItem(LOCAL_STORAGE_DOC_FINDER_DATA, JSON.stringify(queryObject))
                window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/advancedsearch`, "_blank")
            }
            else if (rowData.type === QUERY_TYPE_ADVANCED_SEARCH_3.id) {
                // --- saved searches object should have the same format as the one loaded from the dropdown --- //
                const { queryRowData, ...rest } = queryObject;
                const queryObjectNew = { ...rest, ...queryRowData };
                localStorage.setItem(LOCAL_STORAGE_DOC_FINDER_DATA, JSON.stringify(queryObjectNew));
                window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/querybuilder`, "_blank");
            }
        })
    }

    executeNewTabFact = (rowData) => {
        let queryObject = rowData
        let editable = true

        /*if (rowData.queryCollectionList && rowData.queryCollectionList.length > 0) {
            rowData.queryCollectionList.every(list => {
                if (list.shared && !list.writable) {
                    editable = false
                    return false
                }
                return true
            })
        }*/
        if (!rowData.isCurrentUserOwner) {
            editable = false
        }
        queryObject.editQuery = editable
        // --- get queries from local storage --- //
        let queryObjectFactFinder = localStorage.getItem('factFinderData');
        let factFinderData = queryObjectFactFinder ? JSON.stringify(queryObject) : JSON.stringify(queryObject)
        localStorage.setItem('factFinderData', factFinderData)
        window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/factfinder`, "_blank")
    }

    onSendToCoocSearch = (queryObject) => {
        if (queryObject) {
            addDataToLocalStorage(QUERY_TYPE_COOC_SEARCH.id, queryObject);
            window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/cooccurrences`, "_blank");
        }
    }

    onSendToChemSearch = (queryObject) => {
        if (queryObject) {
            addDataToLocalStorage(QUERY_TYPE_CHEM_SEARCH.id, queryObject);
            window.open(`${APP_PROPERTIES.FRONTEND_URL}chemfinder/chemsearch`, "_blank");
        }
    }

    onSendToSentenceAnalysis = (queryObject) => {
        if (queryObject) {
            addDataToLocalStorage(QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id, queryObject);
            window.open(`${APP_PROPERTIES.FRONTEND_URL}analytics/sentenceanalysis`, "_blank");
        }
    }

    onShowToast = (e, type = 'error', summary = 'Failed!') => {
        let msg = { severity: type, summary, detail: e, life: 10000 };
        this.growl.show(msg);
    };

    onSendToQuickSearch = (queryObject) => {
        if (queryObject) {
            addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id, queryObject);
            window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank");
        }
    }

    nameTemplate = (rowData) => {
        if (rowData.hasOwnProperty('name')) {
            return <React.Fragment>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </React.Fragment>
        }
    }

    numberQueriesTemplate = (rowData) => {
        if (rowData.hasOwnProperty('numberQueries')) {
            return <React.Fragment>
                <span className="p-column-title">Queries</span>
                {rowData.numberQueries}
            </React.Fragment>
        }
    }

    handleRemoveWatchlistDialog = () => {
        this.props.onRemoveWatchlist()
    }

    handleRemoveWatchlist = () => {
        this.props.deleteWatchlist()
    }


    handleSelectionChange = (e) => {
        this.props.selectQueries(e.value)
    }

    handleRemoveQueriesDialog = () => {
        this.props.handleRemoveQueriesDialog()
    }

    handleRemoveQueries = async () => {
        let ids = ''
        this.props.selectedQueriesAction && this.props.selectedQueriesAction.forEach(query => {
            ids += query.id + ","
        })
        ids = ids.slice(0, -1)
        this.props.removeQueries(ids)
    }

    handleDeleteQueries = async () => {
        let ids = ''
        this.props.selectedQueriesAction && this.props.selectedQueriesAction.forEach(query => {
            ids += query.id + ","
        })
        ids = ids.slice(0, -1)
        this.props.removeQueries(ids, true)
    }

    handleCopyQueryDialog = () => {
        this.props.handleCopyQueryDialog()
    }

    handleUnselect = (e) => {
        this.props.handleUnselect()
    }

    onWatchlistChange = (e) => {
        this.props.selectWatchlists(e.value)
    }

    handleCopyQueries = () => {
        let ids = ''
        this.props.selectedQueriesAction && this.props.selectedQueriesAction.forEach(query => {
            ids += query.id + ","
        })
        let ListIds = ''
        /*this.props.selectedWatchlistsAction && this.props.selectedWatchlistsAction.forEach(list => {
            ListIds += list.id + ","
        })*/
        ids = ids.slice(0, -1)
        ListIds = this.props.selectedWatchlistsAction.id
        this.props.copyQueries(ListIds, ids)
    }

    handleMoveQueries = () => {
        let ids = ''
        this.props.selectedQueriesAction && this.props.selectedQueriesAction.forEach(query => {
            ids += query.id + ","
        })
        let ListIds = ''
        /*this.props.selectedWatchlistsAction && this.props.selectedWatchlistsAction.forEach(list => {
            ListIds += list.id + ","
        })*/
        ids = ids.slice(0, -1)
        ListIds = this.props.selectedWatchlistsAction.id
        this.props.moveQueries(ListIds, ids)
    }

    addNewWatchlist = () => {
        this.props.onAddWatchlist()
    }

    handleModifyWatchlistDialog = () => {
        let canModifyWatchlist
        let sharedWithUsers = []
        let sharedWithUsersData = []
        let checkedKeys = {}
        let usersShared = []
        let checkedOrgKeys = {}
        let orgShared = []
        let usersWrite = []
        let sharedUsers = []

        if (!this.props.selectedWatchlist.userSharesRead && !this.props.selectedWatchlist.userSharesWrite) {
            sharedWithUsers = []
        } else if (this.props.selectedWatchlist.userSharesRead && this.props.selectedWatchlist.userSharesRead.length > 0) {
            this.props.selectedWatchlist.userSharesRead.forEach(user => {
                sharedWithUsers = [...sharedWithUsers, user.id]
                sharedWithUsersData = [...sharedWithUsersData, {
                    forename: user.forename,
                    lastName: user.lastName,
                    id: user.id,
                    name: user.name
                }]
                if (this.props.allPossibleUsers.includes(user.id)) {
                    checkedKeys[user.id] = { checked: true, partialChecked: false }
                    usersShared = [...usersShared, user.id]
                    sharedUsers = [...sharedUsers, { label: `${user.lastName}, ${user.forename} (${user.name})`, value: user.id }]
                }
            })
        }
        if (this.props.selectedWatchlist.userSharesWrite && this.props.selectedWatchlist.userSharesWrite.length > 0) {
            this.props.selectedWatchlist.userSharesWrite.forEach(user => {
                sharedWithUsers = [...sharedWithUsers, user.id]
                sharedWithUsersData = [...sharedWithUsersData, {
                    forename: user.forename,
                    lastName: user.lastName,
                    id: user.id,
                    name: user.name
                }]
                if (this.props.allPossibleUsers.includes(user.id)) {
                    checkedKeys[user.id] = { checked: true, partialChecked: false }
                    usersShared = [...usersShared, user.id]
                    usersWrite = [...usersWrite, user.id]
                    sharedUsers = [...sharedUsers, { label: `${user.lastName}, ${user.forename} (${user.name})`, value: user.id }]
                }
            })
        }

        if (this.props.selectedWatchlist?.departmentSharesRead && this.props.selectedWatchlist?.departmentSharesRead.length > 0) {
            this.props.selectedWatchlist.departmentSharesRead.forEach(dep => {
                if (this.props.allPossibleSuborgs.includes(dep.id)) {
                    checkedOrgKeys[`${dep.id}-${dep.name}`] = { checked: true, partialChecked: false }
                }
                orgShared = [...orgShared, dep.id]
            })
        }

        //let checkedKeysWithCompaniesAndDepartments = createCheckedKeys(checkedKeys, this.props.departmentMembers, this.props.userArray)

        if (this.props.selectedWatchlist.defaultCollection || this.props.selectedWatchlist.shared) {
            canModifyWatchlist = false
            //sharedWithUsers = []
        } else {
            canModifyWatchlist = true
        }

        //console.log(checkedKeysWithCompaniesAndDepartments)
        this.setState({
            oldWatchlistSharedWithDepartment: this.props.selectedWatchlist.sharedForDepartment,
            watchlistSharedWithDepartment: this.props.selectedWatchlist.sharedForDepartment,
            selectedWatchlistName: this.props.selectedWatchlist.name,
            selectedWatchlistDescription: this.props.selectedWatchlist.description || '',
            selectedWatchlistID: this.props.selectedWatchlist.id,
            selectedMembers: sharedWithUsers,
            oldSelectedMembers: sharedWithUsers,
            selectedMembersData: sharedWithUsersData,
            oldSelectedMembersData: sharedWithUsersData,
            canModifyWatchlist: canModifyWatchlist,
            checkedKeys: checkedKeys,
            checkedOrgKeys: checkedOrgKeys,
            usersShared: usersShared,
            orgShared: orgShared,
            usersWrite: usersWrite,
            sharedUsers: sharedUsers
        }, () => {
            this.props.onModifyWatchlist()
        })
    }

    updateWatchlistName = (name) => {
        this.setState({
            selectedWatchlistName: name
        })
    }

    updateWatchlistDescription = (desc) => {
        this.setState({
            selectedWatchlistDescription: desc
        })
    }

    handleShareWithDepartment = (e) => {
        this.setState({
            watchlistSharedWithDepartment: e.value
        })
    }

    handleModifyWatchlist = () => {
        //console.log("modify")
        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }
        this.props.modifyWatchlist(this.state.canModifyWatchlist, this.state.selectedWatchlistName, this.state.selectedWatchlistDescription,
            this.state.oldSelectedMembers, Array.from(new Set(usersRead.concat(this.props.allNotAllowedUsersRead))), Array.from(new Set(this.state.usersWrite.concat(this.props.allNotAllowedUsersWrite))), Array.from(new Set(this.state.orgShared.concat(this.props.allNotAllowedSuborgs))), this.state.oldWatchlistSharedWithDepartment, this.state.watchlistSharedWithDepartment,
            this.state.oldWatchlistEditable, this.state.watchlistEditable)
    }

    updateQueryNameEdit = (name) => {
        this.setState({
            queryNameEdit: name
        })
    }

    updateQueryFullNameEdit = (name) => {
        this.setState({
            queryFullNameEdit: name
        })
    }

    updateQueryDescriptionEdit = (name) => {
        this.setState({
            queryDescriptionEdit: name
        })
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.props.orgArray && this.props.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.props.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.props.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.props?.departmentMembers, this.props.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.props.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        //console.log(members)
        this.setState({
            usersWrite: members
        })
    }

    handleEditable = (e) => {
        this.setState({
            watchlistEditable: e.value
        })
    }

    handleCollectionChange = (collections) => {
        this.setState({
            selectedCollections: collections
        })
    }

    onModifyQuery = () => {
        this.props.onModifyQuery(this.state.queryIDEdit, this.state.queryNameEdit, this.state.queryFullNameEdit, this.state.queryDescriptionEdit, this.state.queryStringEdit, this.state.queryTypeEdit,
            'oc-webapi', this.state.queryFormEdit, this.state.queryFormContentEdit, 'sciwalker', this.state.collectionsOld, this.state.selectedCollections, this.state.isShared, this.state.queryValue, this.state.filterQueries, this.state.filterQueriesJsonString)
    }

    handleTimeChange = (date) => {
        this.props.callbackFromTimeChange(date !== null ? dateFormat(date, 'yyyy-mm-dd') : date)
    }

    showAll = () => {
        this.props.callbackFromShowAll()
    }

    fetchQueryOverviewData = async (date, id, filterQuery) => {
        this.setState({
            fetchingData: true
        });

        const { filterDefinitions } = await readFilterDefinitions();

        const promises = [];
        promises.push(getSummaryForWatchlist(id, date));
        let queryHits = {}

        Promise.all(promises).then((responses) => {
            for (var resp of responses) {
                const qryCollSummary = checkResultAndGetPayload(resp, this.growl);
                for (const res of qryCollSummary.results) {
                    queryHits[res.queryId] = res.hits
                }
            }
            this.setState({
                fetchingData: false,
                queryHitsTable: queryHits,
                filterDefinitions: filterDefinitions
            }, () => {
                if (filterQuery) {
                    if (filterQuery.type === QUERY_TYPE_QUICK_SEARCH.id || filterQuery.type === QUERY_TYPE_QUICK_SEARCH_2.id) {
                        this.loadAlertQueryIntoQuickSearch(filterQuery, this.props.startTime)
                    } else {
                        this.executeNewTab(filterQuery, date)
                    }
                }
            });
        });
    }

    fetchHitsForQuery = async (date, id, filterQuery) => {
        this.setState({
            fetchingData: true
        });
        const response = await getStatisticsForQuery(id, date)
        if (response && response.payload && response.payload.results && response.payload.results[0] && response.payload.results[0].hits) {
            const { filterDefinitions } = await readFilterDefinitions();
            this.setState({
                fetchingData: false,
                singleQueryHits: response.payload.results[0].hits,
                filterDefinitions: filterDefinitions
            }, () => {
                if (filterQuery.type === QUERY_TYPE_QUICK_SEARCH.id || filterQuery.type === QUERY_TYPE_QUICK_SEARCH_2.id) {
                    this.loadAlertQueryIntoQuickSearch(filterQuery, this.props.startTime)
                } else {
                    this.executeNewTab(filterQuery, date)
                }
            })
        } else {
            this.setState({
                fetchingData: false
            }, () => {
                let msg = {
                    severity: 'error',
                    summary: 'Error!',
                    detail: 'Search not found. Maybe the search was deleted after you received the alert for it.',
                    life: 6000
                };
                this.growl.show(msg)
            })
        }
    }

    openCreateAppDialog = () => {
        this.setState({
            displayCreateAppDialog: true
        })
    }
    render() {
        //console.log(this.state.usersShared)
        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingData
        //const showIcons = APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons;

        let filteredWatchlists = []

        if (this.props.selectedWatchlist !== undefined) {
            this.props.selectedWatchlist && this.props.allWatchlists && this.props.allWatchlists.forEach(list => {
                if (list.id !== this.props.selectedWatchlist.id) {
                    if (list.writable) {
                        filteredWatchlists = [...filteredWatchlists, list]
                    }
                }
            })
        } else {
            this.props.allWatchlists && this.props.allWatchlists.forEach(list => {
                if (list.writable) {
                    filteredWatchlists = [...filteredWatchlists, list]
                }
            })
        }

        let usersShared = ''
        let usersWrite = ''
        let suborgsShared = ''
        if (this.props.selectedWatchlist && this.props.selectedWatchlist.userSharesRead && this.props.selectedWatchlist.userSharesRead.length > 0) {
            this.props.selectedWatchlist.userSharesRead.forEach(user => {
                if (this.props.allPossibleUsers?.length === 0 || this.props.allNotAllowedUsersRead?.includes(user.id)) {
                    usersShared += `${user.lastName}, ${user.forename} (${user.name})` + '*, '
                } else {
                    usersShared += `${user.lastName}, ${user.forename} (${user.name})` + ', '
                }
            })
        }
        if (this.props.selectedWatchlist && this.props.selectedWatchlist.userSharesWrite && this.props.selectedWatchlist.userSharesWrite.length > 0) {
            this.props.selectedWatchlist.userSharesWrite.forEach(user => {
                if (this.props.allPossibleUsers?.length === 0 || this.props.allNotAllowedUsersWrite?.includes(user.id)) {
                    usersShared += `${user.lastName}, ${user.forename} (${user.name})` + '*, '
                    usersWrite += `${user.lastName}, ${user.forename} (${user.name})` + '*, '
                } else {
                    usersShared += `${user.lastName}, ${user.forename} (${user.name})` + ', '
                    usersWrite += `${user.lastName}, ${user.forename} (${user.name})` + ', '
                }
            })
        }
        if (this.props.selectedWatchlist && this.props.selectedWatchlist.departmentSharesRead && this.props.selectedWatchlist.departmentSharesRead.length > 0) {
            this.props.selectedWatchlist.departmentSharesRead.forEach(dep => {
                if (this.props.allPossibleSuborgs?.length === 0 || this.props.allNotAllowedSuborgs?.includes(dep.id)) {
                    suborgsShared += `${dep.name}` + '*, '
                } else {
                    suborgsShared += `${dep.name}` + ', '
                }
            })
        }
        usersShared = usersShared.substring(0, usersShared.length - 2)
        usersWrite = usersWrite.substring(0, usersWrite.length - 2)
        suborgsShared = suborgsShared.substring(0, suborgsShared.length - 2)

        let headerWatchlistQueries = <div className='grid' style={{
            marginRight: 0,
            borderBottom: '1px solid #757575',
            paddingTop: 18, paddingBottom: 10,
            marginLeft: 2
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title={this.props.selectedWatchlist !== undefined ? "Search in collection" : "Search"}>
                    {'search'}
                </i>
                <InputText
                    style={{
                        border: 'none',
                        width: '83%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'inline-block',
                        whiteSpace: 'nowrap'
                    }}
                    type="search"
                    value={this.state.globalFilterQueries}
                    autoComplete="off"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterQueries: e.target.value })}
                    placeholder={this.props.selectedWatchlist !== undefined ? "Search in collection" : "Search"}
                />
                {/*<Dropdown
                    className='dropdownNoBorder'
                    value={this.state.sortDocuments}
                    options={sortOptions}
                    onChange={(e) => this.onSortDocuments(e)}
                style={{ width: '90px', float: 'right' }} />*/}
            </div>
            {/*<div style={{ paddingLeft: 0, paddingRight: 0, width: '10%', display: 'flex', alignItems: 'center' }}>
                <label style={{ paddingLeft: 15, paddingBottom: 2 }}>Show:</label>
                <Dropdown
                    className='dropdownNoBorder'
                    value={this.state.showDocuments}
                    options={showOptions}
                    onChange={(e) => this.onShowDocuments(e)}
                    style={{ width: '70px', float: 'right' }} />
            </div>*/}
        </div>

        let watchlistQueries = []

        //console.log(this.props.selectedQueries)

        if (this.props.selectedQueries && this.props.selectedQueries[0] && this.props.selectedQueries[0].document) {
            this.props.selectedQueries.forEach(list => {
                watchlistQueries = [...watchlistQueries, Object.assign(list.document, {
                    id: list.id,
                    insertionTime: list.insertionTime,
                    relevance: list.relevance
                })]
            })
        } else if (this.props.selectedQueries && !this.props.selectedQueries.document) {
            this.props.selectedQueries.forEach(list => {
                watchlistQueries = [...watchlistQueries, list]
            })
        }

        //console.log("queries: ", watchlistQueries)
        //console.log(this.state.queryHitsTable)

        //console.log(this.props.filteredQuery)

        let filteredQueryID = this.props.filteredQuery

        if (watchlistQueries.length > 0 && this.props.filteredQuery !== null && this.props.startTime !== null) {
            watchlistQueries.forEach(query => {
                if (query.id.toString() === this.props.filteredQuery) {
                    query.hits = this.state.singleQueryHits
                }
            })
            if (this.props.selectedWatchlist === undefined) {
                watchlistQueries = watchlistQueries.filter(function (el) {
                    return el.id.toString() === filteredQueryID
                })
            }
        }

        if (watchlistQueries.length > 0 && this.props.startTime !== null && this.props.selectedWatchlist !== undefined) {
            watchlistQueries.forEach(query => {
                Object.keys(this.state.queryHitsTable).forEach(hit => {
                    if (query.id.toString() === hit) {
                        //console.log(query)
                        //console.log(this.state.queryHitsTable[hit])
                        query.hits = this.state.queryHitsTable[hit]
                    }
                })
            })
            /* watchlistQueries = watchlistQueries.filter(function (el) {
                 return el.hits > 0
             }) */
        }

        /*let footerQueries = <div className='col-12' style={{ paddingTop: 5, paddingBottom: 5, justifyContent: 'center', display: 'flex' }}>
            {this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 0 &&
                <div className='col-4' style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #d6d6d6', padding: '2em' }}>
                    <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{this.props.selectedQueriesAction.length} {this.props.selectedQueriesAction.length > 1 ? 'items' : 'item'} selected</label>
                    {this.props.selectedWatchlist !== undefined ?
                        <a style={{ marginTop: 5, marginRight: 5 }}
                            title={this.props.selectedQueriesAction.length && this.props.selectedQueriesAction.length === 1 ? `Copy selected query` : `Copy ${this.props.selectedQueriesAction.length} selected queries`}
                            onClick={this.handleCopyQueryDialog} >Copy</a>
                        : null
                    }
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedQueriesAction.length && this.props.selectedQueriesAction.length === 1 ? `Remove selected query` : `Remove ${this.props.selectedQueriesAction.length} selected queries`}
                        onClick={this.handleRemoveQueriesDialog}>Remove</a>
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title='Unselect'
                        onClick={this.handleUnselect}>Unselect</a>
                </div>
            }
        </div>;*/

        const footerRemoveWatchlist = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete" onClick={this.handleRemoveWatchlist} className='p-button-sm primaryButton' />
                <Button label="Cancel" onClick={this.props.onHideRemoveWatchlist}
                    className="p-button-secondary p-button-sm" />
            </div>
        )
        let columnData
        /*if (this.props.canEdit === false
        ) {
            columnData = this.state.cols.filter(column => column.field !== "check").map((col, i) => {
                return <Column className="columnheader" key={col.field}
                    field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                    header={col.header} sortable={col.sortable} body={col.body} style={col.style}
                    filter={col.filter} />
            })
        } else if (this.props.canEdit === true) {
            columnData = this.state.cols.map((col, i) => {
                return <Column className="columnheader" key={col.field}
                    field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                    header={col.header} sortable={col.sortable} body={col.body} style={col.style}
                    filter={col.filter} />
            })
        }*/

        columnData = this.state.cols.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                header={col.header} sortable={col.sortable} body={col.body} style={col.style}
                filter={col.filter} filterMatchMode={col.filterMatchMode} />
        })

        if (!hasUserRole("ROLE_ALERTS")) {
            columnData = columnData.filter(function (el) {
                return el.key !== 'alarm'
            })
        }

        if (!hasUserRole("ROLE_TRENDANALYSIS")) {
            columnData = columnData.filter(function (el) {
                return el.key !== 'statistics'
            })
        }

        if (this.props.startTime === null) {
            columnData = columnData.filter(function (el) {
                return el.key !== 'hits'
            })
        }

        let columnDataWatchlists = this.state.colsWatchlists.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                header={col.header} sortable={col.sortable} body={col.body} style={col.style}
                filter={col.filter} />
        })

        const sharedWithDepartmentOptions = [
            { label: 'yes', value: true },
            { label: 'no', value: false }
        ]

        let members = []

        this.state.selectedMembersData && this.state.selectedMembersData.forEach(member => {
            members = [...members, { label: `${member.lastName}, ${member.forename} (${member.name})`, value: member.id }]
        })
        this.props.departmentMembers && this.props.departmentMembers.forEach(mem => {
            if (members.filter(function (e) { return e.value === mem.id }).length === 0) {
                members = [...members, { label: `${mem.lastName}, ${mem.forename} (${mem.username})`, value: mem.id }]
            }
        })
        const footerRemoveQuery = (
            <div style={{ paddingTop: 5 }}>
                <Button label={"Remove"} onClick={this.handleRemoveQueries} className='p-button-sm primaryButton' />
                <Button label="Cancel" onClick={this.props.handleCloseRemoveQueryDialog}
                    className="p-button-secondary p-button-sm" />
            </div>
        )

        const footerDeleteQuery = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete" onClick={this.handleDeleteQueries} className='p-button-sm primaryButton' />
                <Button label="Cancel" onClick={this.props.handleCloseDeleteQueryDialog}
                    className="p-button-secondary p-button-sm" />
            </div>
        )

        /*const footerEditor = (
            <div>
                <label style={{ float: 'left', paddingTop: 20 }}>* required</label>
                <Button label="Cancel" onClick={(e) => this.props.onHideModifyWatchlist(e)}
                    className="p-button-secondary p-button-sm" />
                <Button label="Save" onClick={this.handleModifyWatchlist} style={{ float: 'right', marginRight: -5 }} className='p-button-sm primaryButton' />
            </div>
        )*/

        /*const footerEditorEdit = (
            <div>
                <label style={{ float: 'left', paddingTop: 10 }}>* required</label>
                <Button label="Cancel" onClick={(e) => this.props.onHideEditQuery(e)}
                    className="p-button-secondary p-button-sm" />
                <Button label="Save" onClick={this.onModifyQuery} style={{ float: 'right' }} className='p-button-sm primaryButton' />
            </div>
        )*/

        let collections = []
        this.props.allWatchlists && this.props.allWatchlists.forEach(collection => {
            if (!collection.shared || (collection.shared && collection.writable)) {
                collections = [...collections, { label: collection.name, value: collection.id }]
            }
        })

        const dashboardOptions = [
            { label: 'yes', value: true },
            { label: 'no', value: false }
        ]

        /*let timeOptions = [
            { label: 'no selection', value: 'none' },
            { label: dateFormat(YESTERDAY, 'yyyy/mm/dd'), value: dateFormat(YESTERDAY, 'yyyy-mm-dd') },
            { label: dateFormat(LAST_WEEK, 'yyyy/mm/dd'), value: dateFormat(LAST_WEEK, 'yyyy-mm-dd') },
            { label: dateFormat(LAST_MONTH, 'yyyy/mm/dd'), value: dateFormat(LAST_MONTH, 'yyyy-mm-dd') }
        ]*/

        //console.log(timeOptions)
        //console.log(this.props.startTime)

        /*if (!timeOptions.some(e => e.value === this.props.startTime.toString())) {
            timeOptions = [...timeOptions, { label: this.props.startTime.toString().replace(/-/g, '/'), value: this.props.startTime.toString() }]
        }*/

        let headerWatchlists = <div className='grid' style={{
            marginRight: 0, paddingLeft: 5,
            paddingTop: 18, paddingBottom: 10
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search collections">
                    {'search'}
                </i>
                <InputText
                    style={{
                        border: 'none',
                        width: '83%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'inline-block',
                        whiteSpace: 'nowrap'
                    }}
                    type="search"
                    autoComplete="off"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterWatchlists: e.target.value })}
                    placeholder="Search collections" />
            </div>
        </div>

        //console.log(this.props.selectedWatchlistsAction)

        //console.log(this.props.selectedWatchlist)

        //console.log(this.props.startTime)

        return (
            <div className="col-12 grid-nogutter" style={{ paddingRight: 0 }}>
                <Toast ref={(el) => {
                    this.growl = el
                }} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    <div className='col-12' style={{
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        marginBottom: this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 0 ? 60 : 0
                    }}>
                        <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 9, paddingRight: 0 }}>
                            <div className='col-12 grid'
                                style={{ paddingBottom: 0, paddingLeft: 7, paddingRight: 0 }}>
                                <div style={{}}>
                                    <label style={{
                                        marginTop: -1,
                                        fontSize: '1.1em',
                                        marginRight: 25,
                                        fontWeight: 'bold',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        display: 'inline-block',
                                        width: '100%'
                                    }}
                                        title={this.props.selectedWatchlist === undefined ? 'All saved searches' : `Search collection: ${this.props.watchlistName}`}>
                                        {this.props.selectedWatchlist === undefined ? 'All saved searches' : `Search collection: ${this.props.watchlistName}`}</label>
                                </div>
                                {(this.props.selectedWatchlist !== undefined && !this.props.selectedWatchlist.defaultCollection) ||
                                    (this.props.selectedWatchlist !== undefined) ?
                                    <div style={{ marginTop: 0, marginLeft: 'auto', marginRight: 0 }}>
                                        {this.props.selectedWatchlist && !this.props.selectedWatchlist.shared ?
                                            <a onClick={(e) => this.handleModifyWatchlistDialog(e)}
                                                style={{
                                                    display: 'inline-block',
                                                    marginRight: this.props.selectedWatchlist.defaultCollection ? 0 : 20,
                                                    marginTop: 0
                                                }}>Edit & Share</a>
                                            : null
                                        }
                                        {(!this.props.selectedWatchlist.defaultCollection && !this.props.selectedWatchlist.shared) ? //|| (this.props.selectedWatchlist.shared && this.props.selectedWatchlist.writable) ?
                                            <a onClick={(e) => this.handleRemoveWatchlistDialog(e)}
                                                style={{
                                                    display: 'inline-block',
                                                    marginRight: 0,
                                                    marginTop: 0
                                                }}>Delete</a>
                                            : null
                                        }
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className='col-12'
                            style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, paddingTop: 5 }}>
                            <label style={{
                                marginTop: 0,
                                float: 'left',
                                marginBottom: 0,
                                color: '#757575'
                            }}>{this.props.watchlistDescription}</label>
                        </div>
                        {(this.props.selectedWatchlist !== undefined && !this.props.selectedWatchlist.defaultCollection) || (this.props.selectedWatchlist !== undefined) ?
                            <div className='col-12'
                                style={{ display: 'inline-block', paddingBottom: 10, paddingLeft: 0, paddingTop: 0 }}>
                                <label style={{ marginTop: 0, float: 'left', marginBottom: 5, color: '#757575' }}>
                                    {this.props.selectedWatchlist.created ? `Created: ${moment(this.props.selectedWatchlist.created + "Z").format("YYYY-MM-DD")}` : ''}
                                    {this.props.selectedWatchlist.modified !== undefined ? ` - Modified: ${moment(this.props.selectedWatchlist.modified + "Z").format("YYYY-MM-DD")} ` : ''}
                                    {this.props.selectedWatchlist.departmentSharesRead && this.props.selectedWatchlist.departmentSharesRead.length > 0 ? <span title={`Shared with suborganizations:  ${suborgsShared} `} style={{
                                        whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                        display: 'inline-block'
                                    }}>{` - Shared with suborganizations:  ${suborgsShared}`}&nbsp;</span> : ''}
                                    {(this.props.selectedWatchlist.userSharesRead && this.props.selectedWatchlist.userSharesRead.length > 0) ||
                                        (this.props.selectedWatchlist.userSharesWrite && this.props.selectedWatchlist.userSharesWrite.length > 0) ?
                                        <span title={`Shared with users: ${usersShared}`} style={{
                                            whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                            display: 'inline-block'
                                        }}>{` - Shared with users: ${usersShared}`}&nbsp;</span> : ''}
                                    {(this.props.selectedWatchlist.userSharesWrite && this.props.selectedWatchlist.userSharesWrite.length > 0) || (this.props.selectedWatchlist.userSharesRead && this.props.selectedWatchlist.userSharesRead.length > 0) ?
                                        this.props.selectedWatchlist.userSharesWrite && this.props.selectedWatchlist.userSharesWrite.length > 0 ? <span title={`Editable for users: ${usersWrite}`} style={{
                                            whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                            display: 'inline-block'
                                        }}>{` - Editable for users: ${usersWrite}`}</span> : ' - Not editable for shared users'
                                        : ''
                                    }{this.props.allNotAllowedSuborgs?.length > 0 || this.props.allNotAllowedUsersRead?.length > 0 || this.props.allNotAllowedUsersWrite.length > 0 ?
                                        <span style={{}}>
                                            <span style={{ verticalAlign: 'middle' }}>&nbsp; -</span>
                                            <a className="infoIconLink"
                                                style={{ cursor: 'default' }}
                                                title="*: Suborganizations and users you are not allowed to administrate anymore but still share this collection with">
                                                <img src={infoIcon} alt="Sharing information"
                                                    style={{ marginLeft: 10, verticalAlign: 'middle' }} />
                                            </a>
                                        </span>
                                        : ''
                                    }
                                </label>
                            </div>
                            : null
                        }

                        <React.Fragment>
                            <div className='col-12 grid' style={{ paddingBottom: 0, paddingLeft: 0, paddingTop: 2, paddingRight: 0 }}>
                                {
                                    (this.props.selectedWatchlist !== undefined && !this.props.selectedWatchlist.defaultCollection) ||
                                        (this.props.selectedWatchlist !== undefined) ?
                                        <React.Fragment>
                                            {hasUserRole("ROLE_ALERTS") &&
                                                <div style={{ paddingLeft: 7 }}>
                                                    <label style={{
                                                        marginTop: 0,
                                                        float: 'left',
                                                        paddingRight: 10
                                                    }}>Alert:</label>
                                                    {((this.props.selectedWatchlist.notifications && this.props.selectedWatchlist.notifications.length === 0) || this.props.selectedWatchlist.notifications === undefined) ?
                                                        <div style={{
                                                            display: 'inline-block',
                                                            float: 'left',
                                                            marginTop: -5,
                                                            marginRight: 10
                                                        }}>
                                                            <i className="material-icons-outlined" id='read'
                                                                title={'Add alert to this search collection'}
                                                                onClick={(e) => //this.props.watchlistAlertChange(this.props.selectedWatchlist, 'add', e)}>
                                                                    this.props.openAddAlertDialog(this.props.selectedWatchlist, 'watchlist')}>
                                                                {'notification_add'}
                                                            </i>
                                                        </div>
                                                        : (this.props.selectedWatchlist.notifications && this.props.selectedWatchlist.notifications.length > 0 && this.props.selectedWatchlist.notifications[0].active) ?
                                                            <div className='grid'
                                                                style={{ float: 'left', marginTop: -5, marginRight: 5 }}>
                                                                <i className="material-icons" id='read'
                                                                    title={'You receive alerts for this search collection'}
                                                                    onClick={(e) => //this.props.watchlistAlertChange(this.props.selectedWatchlist, 'pause', e)}>
                                                                        this.props.openAddAlertDialog(this.props.selectedWatchlist, 'watchlist')}>
                                                                    {'notifications_active'}
                                                                </i>
                                                                {/*<div style={{ marginTop: 5 }}>
                                                                    <Dropdown className='dropdownNoBorder'
                                                                        style={{ marginTop: -7 }}
                                                                        value={'active'}
                                                                        options={this.state.activeOptions}
                                                                        onChange={(e) => {
                                                                            this.props.watchlistAlertChange(this.props.selectedWatchlist, 'pause', e)
                                                                        }} />
                                                                    </div>*/}
                                                            </div>
                                                            : (this.props.selectedWatchlist.notifications && this.props.selectedWatchlist.notifications.length > 0 && !this.props.selectedWatchlist.notifications[0].active) ?
                                                                <div className='grid' style={{
                                                                    float: 'left',
                                                                    marginTop: -5,
                                                                    marginRight: 15
                                                                }}>
                                                                    <i className="material-icons" id='read'
                                                                        title={'Alerts for this search collection are paused'}
                                                                        onClick={(e) => //this.props.watchlistAlertChange(this.props.selectedWatchlist, 'activate', e)
                                                                            this.props.openAddAlertDialog(this.props.selectedWatchlist, 'watchlist')}>
                                                                        {'notifications_paused'}
                                                                    </i>
                                                                    {/*<div style={{ marginTop: 5 }}>
                                                                        <Dropdown className='dropdownNoBorder'
                                                                            style={{ marginTop: -7 }}
                                                                            value={'paused'}
                                                                            options={this.state.activeOptions}
                                                                            onChange={(e) => {
                                                                                this.props.watchlistAlertChange(this.props.selectedWatchlist, 'activate', e)
                                                                            }} />
                                                                        </div>*/}
                                                                </div>
                                                                : null
                                                    }
                                                </div>
                                            }
                                            {/*
                                                (this.props.selectedWatchlist.notifications && this.props.selectedWatchlist.notifications.length > 0 && this.props.selectedWatchlist.notifications[0].active) ?
                                                <div style={{ paddingLeft: 5 }}>
                                                    <label style={{
                                                        marginTop: 0,
                                                        float: 'left',
                                                        marginBottom: 5
                                                    }}>Interval:</label>
                                                    <Dropdown className='dropdownNoBorder'
                                                        style={{ marginTop: -8 }}
                                                        value={this.props.selectedWatchlist.notifications[0].interval}
                                                        options={this.props.queryIntervals}
                                                        onChange={(e) => { this.props.handleIntervalChange(this.props.selectedWatchlist, e.value) }} />
                                                </div>
                                                : null
                                                */}


                                            {this.props.selectedWatchlist && this.props.userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' && hasUserRole("ROLE_APPS") ? //&& !this.props.selectedWatchlist.shared && hasUserRole("ROLE_DASHBOARD") ?
                                                <div style={{ paddingLeft: 5 }}>
                                                    {/*<label style={{
                                                        marginTop: 0,
                                                        float: 'left',
                                                        marginBottom: 5,
                                                        paddingRight: 5
                                                    }}>Show on dashboard:</label>
                                                    <Dropdown className='dropdownNoBorder'
                                                        appendTo={document.body}
                                                        style={{ marginTop: -8, marginLeft: -6 }}
                                                        value={this.props.showOnDashboard}
                                                        options={dashboardOptions}
                                                        onChange={(e) => {
                                                            this.props.handleDashboardChange(this.props.selectedWatchlist, e.value)
                                                        }} />*/}
                                                    {this.props.selectedQueries?.length > 0 ?
                                                        <a style={{
                                                            marginTop: 0,
                                                            float: 'left',
                                                            marginBottom: 5,
                                                            paddingRight: 5
                                                        }} title="Create App" onClick={(e) => this.openCreateAppDialog(e)}>
                                                            Create App</a>
                                                        :
                                                        <label>Create App</label>
                                                    }
                                                </div>
                                                : null
                                            }
                                        </React.Fragment>
                                        : null
                                }
                                {this.props.selectedWatchlist !== undefined && watchlistQueries.length > 0 ?
                                    <div style={{ marginLeft: 'auto', marginRight: -13 }}>
                                        {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5, paddingRight: 5 }}>Show new results since:</label>*/}
                                        {/*<Dropdown className='dropdownNoBorder'
                                        appendTo={document.body}
                                        style={{ marginTop: -8, marginLeft: -6 }}
                                        value={this.props.startTime}
                                        options={timeOptions}
                                        disabled={this.props.selectedWatchlist !== undefined ? false : true}
                            onChange={(e) => { this.handleTimeChange(e.value) }} />*/}
                                        <div style={{ display: 'inline' }}
                                            title="Recently added to our repositories (not publication date).">
                                            Show new hits since:
                                        </div>
                                        <Calendar
                                            //className='adminCalendar'
                                            className="standardDateWidth noBorder"
                                            placeholder="Select date"
                                            /*style={{
                                                width: '40%',
                                                //verticalAlign: 'bottom'
                                                border: 'none'
                                                //border: '1px solid #d6d6d6',
                                                //padding: 2
                                            }}*/
                                            value={this.props.startTime !== null ? new Date(this.props.startTime) : null}
                                            onClearButtonClick={(e) => this.handleTimeChange(null)}
                                            id='calendar'
                                            maxDate={YESTERDAY}
                                            //minDate={YESTERDAY}
                                            readOnlyInput={true}
                                            dateFormat='yy/mm/dd'
                                            showButtonBar={true}
                                            monthNavigator={true}
                                            //yearNavigator={true}
                                            appendTo={document.body}
                                            //disabled={watchlistQueries.length !== 0 ? false : true}
                                            onChange={(e) => {
                                                this.handleTimeChange(e.value)
                                            }}
                                            aria-label='Select date'
                                            title='Select date'
                                            showIcon
                                        //disabled={false}
                                        />
                                    </div>

                                    : this.props.selectedWatchlist === undefined && this.props.startTime !== null ?
                                        <div style={{ marginLeft: 'auto' }}>
                                            <div style={{ display: 'inline', marginRight: 20 }}
                                                title="Recently added to our repositories (not publication date).">
                                                New results for search since {this.props.startTime}
                                            </div>
                                            <a title="Show all searches" onClick={this.showAll}>
                                                Show all</a>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </React.Fragment >


                        {this.props.selectedQueries && this.props.selectedQueries.length > 0 && watchlistQueries.length > 0 ?
                            <div style={{ width: '100%' }} className="datatable-responsive-demo-l">
                                {headerWatchlistQueries}
                                <DataTable ref={(el) => this.dt = el}
                                    selection={this.props.selectedQueriesAction}
                                    removableSort={true}
                                    onSelectionChange={e => this.handleSelectionChange(e)}
                                    selectionMode="checkbox"
                                    //className="dataTableQueries checkboxCircle standardTableHeader"
                                    className="p-datatable-responsive-demo-l"
                                    value={watchlistQueries}
                                    responsive="true"
                                    paginator={true}
                                    paginatorPosition="bottom"
                                    rows={this.props.rowsQueries}
                                    globalFilter={this.state.globalFilterQueries}
                                    first={this.props.firstQueries}
                                    emptyMessage="No searches for selected time range"
                                    rowsPerPageOptions={[10, 20, 50]}
                                    onPage={(e) => this.props.onCustomPage(e, true)}
                                    //footer={footerQueries}
                                    //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    paginatorTemplate={CustomPaginatorTemplate}
                                    paginatorLeft={<CustomPaginatorLeftSide
                                        first={this.props.firstQueries}
                                        contentLength={watchlistQueries.length}
                                        rows={this.props.rowsQueries}>
                                    </CustomPaginatorLeftSide>}
                                    paginatorRight={<CustomPaginatorRightSide
                                        rows={this.props.rowsQueries}
                                        onChange={(e) => this.props.onCustomPage(e, false)}>
                                    </CustomPaginatorRightSide>}>
                                    {columnData}
                                </DataTable>
                            </div>
                            :
                            <div className="dataTable" style={{ marginTop: 20 }}>
                                <label style={{
                                    marginRight: 10,
                                    marginBottom: 20,
                                    verticalAlign: 'bottom',
                                    fontWeight: 'bold'
                                }}>
                                    {this.props.selectedWatchlist !== undefined ? 'No search in this collection.' : 'No saved searches available.'}</label>
                            </div>
                        }
                    </div >
                    <Dialog focusOnShow={false} visible={this.props.displayDialogRemoveWatchlist}
                        style={{ 'width': "50vw" }} header="Delete collection"
                        modal={true} footer={footerRemoveWatchlist} dismissableMask={true}
                        onHide={() => this.props.onHideRemoveWatchlist()} className='styledDialog'>
                        <div className="grid p-fluid"
                            style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            Warning! This collection will be deleted irrecoverable! Do you really want to delete this
                            collection?
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogRemoveQueries}
                        style={{ 'width': "50vw" }}
                        header={(this.props.selectedQueriesAction && this.props.selectedQueriesAction.length === 1 && this.props.selectedWatchlist !== undefined) ? "Remove search"
                            : (this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1 && this.props.selectedWatchlist !== undefined) ? "Remove searches"
                                : null}
                        modal={true} footer={footerRemoveQuery} dismissableMask={true}
                        onHide={() => this.props.handleCloseRemoveQueryDialog()} className='styledDialog'>
                        <div className="grid p-fluid"
                            style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            {(this.props.selectedQueriesAction && this.props.selectedQueriesAction.length === 1) &&
                                ((this.props.selectedWatchlist !== undefined && !this.props.selectedWatchlist.defaultCollection) ||
                                    (this.props.selectedWatchlist !== undefined)) ?
                                `Warning! This search will be removed from this collection! Do you really want to remove this search?`
                                :
                                (this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1)
                                    && ((this.props.selectedWatchlist !== undefined && !this.props.selectedWatchlist.defaultCollection) ||
                                        (this.props.selectedWatchlist !== undefined)) ?
                                    `Warning! These searches will be removed from this collection! Do you really want to remove these searches?`
                                    : null
                            }
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogDeleteQueries}
                        style={{ 'width': "50vw" }}
                        header={(this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1) ? "Delete searches"
                            : (this.props.selectedQueriesAction && this.props.selectedQueriesAction.length === 1) ? "Delete search"
                                : null}
                        modal={true} footer={footerDeleteQuery} dismissableMask={true}
                        onHide={() => this.props.handleCloseDeleteQueryDialog()} className='styledDialog'>
                        <div className="grid p-fluid"
                            style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            {this.props.selectedQueriesAction && this.props.selectedQueriesAction.length === 1 ?
                                `Warning! This search will be deleted! Do you really want to delete this search?`
                                : this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1 ?
                                    `Warning! These searches will be deleted! Do you really want to delete these searches?`
                                    : null
                            }
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogCopyQuery} style={{ 'width': "50vw" }}
                        header={this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1 && this.props.selectedWatchlist !== undefined ?
                            "Copy searches" : this.props.selectedQueriesAction && this.props.selectedQueriesAction.length === 1 && this.props.selectedWatchlist !== undefined ?
                                "Copy search" : this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1 && this.props.selectedWatchlist === undefined ?
                                    "Add searches" : this.props.selectedQueriesAction && this.props.selectedQueriesAction.length === 1 && this.props.selectedWatchlist === undefined ?
                                        "Add search" : null
                        }
                        modal={true} dismissableMask={true} onHide={() => this.props.handleCloseCopyQueryDialog()} className='styledDialog'>
                        <React.Fragment>
                            {filteredWatchlists && filteredWatchlists.length === 0 ?
                                <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                    <h4 style={{}}>No other watchlist available. Please create a new collection.</h4>
                                    <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }}
                                        title="Create new collection" onClick={this.addNewWatchlist}>
                                        Create collection</a>
                                </div>
                                :
                                <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                    <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }}
                                        title="Create new collection" onClick={this.addNewWatchlist}>
                                        Create collection</a>
                                </div>
                            }
                            {filteredWatchlists && filteredWatchlists.length > 0 &&
                                <React.Fragment>
                                    <div className="grid p-fluid"
                                        style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 10, paddingTop: 10, marginRight: 0, marginLeft: 0, borderBottom: '1px solid #d6d6d6' }}>

                                        <div style={{ width: '100%' }} className="datatable-responsive-demo">
                                            {headerWatchlists}
                                            <DataTable ref={(el) => this.dt = el}
                                                //scrollable
                                                //scrollHeight='190px'
                                                selection={this.props.selectedWatchlistsAction}
                                                selectionMode="radiobutton"
                                                removableSort={true}
                                                onSelectionChange={e => this.onWatchlistChange(e)}
                                                //className="dataTableQueries checkboxCircle standardTableHeader"
                                                className="p-datatable-responsive-demo"
                                                value={filteredWatchlists}
                                                responsive="true"
                                                paginator={true}
                                                rows={5}
                                                globalFilter={this.state.globalFilterWatchlists}>
                                                {columnDataWatchlists}
                                            </DataTable>
                                        </div>
                                    </div>
                                    <div className='col-12' style={{ paddingRight: 25, paddingTop: 8, marginBottom: 33 }}>
                                        {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                            title="Create new watchlist" onClick={this.addNewWatchlist}>
                        Create collection</a>*/}
                                        <Button
                                            label={this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1 && this.props.selectedWatchlist !== undefined ?
                                                "Copy searches" : this.props.selectedQueriesAction && this.props.selectedQueriesAction.length === 1 && this.props.selectedWatchlist !== undefined ?
                                                    "Copy search" : this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1 && this.props.selectedWatchlist === undefined ?
                                                        "Add searches" : this.props.selectedQueriesAction && this.props.selectedQueriesAction.length === 1 && this.props.selectedWatchlist === undefined ?
                                                            "Add search" : null}
                                            disabled={this.props.selectedWatchlistsAction && this.props.selectedWatchlistsAction !== null ? false : true}
                                            onClick={this.handleCopyQueries}
                                            className='p-button-sm primaryButton'
                                            style={{ marginRight: 0, float: 'right', marginTop: 5 }}
                                        //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                        />
                                        {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                        <Button label="Cancel" onClick={() => this.props.handleCloseCopyQueryDialog()}
                                            className="p-button-secondary p-button-sm"
                                            style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }} />
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogMoveQuery} style={{ 'width': "50vw" }}
                        header={this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1 ? "Move searches" : "Move search"}
                        modal={true} dismissableMask={true} onHide={() => this.props.handleCloseMoveQueryDialog()} className='styledDialog'>
                        <React.Fragment>
                            {filteredWatchlists && filteredWatchlists.length === 0 ?
                                <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                    <h4 style={{}}>No other watchlist available. Please create a new watchlist.</h4>
                                    <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }}
                                        title="Create new collection" onClick={this.addNewWatchlist}>
                                        Create collection</a>
                                </div>
                                :
                                <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                    <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }}
                                        title="Create new collection" onClick={this.addNewWatchlist}>
                                        Create collection</a>
                                </div>
                            }
                            {filteredWatchlists && filteredWatchlists.length > 0 &&
                                <React.Fragment>
                                    <div className="grid p-fluid"
                                        style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 10, paddingTop: 10, marginRight: 0, marginLeft: 0, borderBottom: '1px solid #d6d6d6' }}>

                                        <div style={{ width: '100%' }} className="datatable-responsive-demo">
                                            {headerWatchlists}
                                            <DataTable ref={(el) => this.dt = el}
                                                //scrollable
                                                //scrollHeight='190px'
                                                selection={this.props.selectedWatchlistsAction}
                                                removableSort={true}
                                                selectionMode="radiobutton"
                                                onSelectionChange={e => this.onWatchlistChange(e)}
                                                //className="dataTableQueries checkboxCircle standardTableHeader"
                                                className="p-datatable-responsive-demo"
                                                value={filteredWatchlists}
                                                responsive="true"
                                                paginator={true}
                                                rows={5}
                                                globalFilter={this.state.globalFilterWatchlists}>
                                                {columnDataWatchlists}
                                            </DataTable>
                                        </div>
                                    </div>
                                    <div className='col-12' style={{ paddingRight: 25, paddingTop: 8, marginBottom: 33 }}>
                                        {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                        title="Create new watchlist" onClick={this.addNewWatchlist}>
                    Create collection</a>*/}
                                        <Button
                                            label={this.props.selectedQueriesAction && this.props.selectedQueriesAction.length > 1 ? "Move searches" : "Move search"}
                                            disabled={this.props.selectedWatchlistsAction && this.props.selectedWatchlistsAction !== null ? false : true}
                                            onClick={this.handleMoveQueries}
                                            className='p-button-sm primaryButton'
                                            style={{ marginRight: 0, float: 'right', marginTop: 5 }}
                                        //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                        />
                                        {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                        <Button label="Cancel" onClick={() => this.props.handleCloseMoveQueryDialog()}
                                            className="p-button-secondary p-button-sm"
                                            style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }} />
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogModifyWatchlist}
                        style={{ 'width': "60vw" }} header="Collection details"
                        modal={true} blockScroll
                        //footer={footerEditor}
                        dismissableMask={true}
                        onHide={(e) => this.props.onHideModifyWatchlist(e)}
                        className='styledDialog'>
                        <div className="grid p-fluid"
                            style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Name *</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                <InputText autoComplete="off" name='none'
                                    maxLength={50}
                                    disabled={this.state.canModifyWatchlist ? false : true}
                                    onChange={(e) => { this.updateWatchlistName(e.target.value) }}
                                    value={this.state.selectedWatchlistName} />
                                <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Description</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                <InputText autoComplete='off'
                                    maxLength={50}
                                    disabled={this.state.canModifyWatchlist ? false : true}
                                    onChange={(e) => { this.updateWatchlistDescription(e.target.value) }}
                                    value={this.state.selectedWatchlistDescription} />
                                <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ display: 'flex', paddingBottom: 15, paddingRight: 10, paddingTop: 5 }}>
                                <div className="w-full" >
                                    <TreeSelect disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.props.departmentMembers?.length !== 0 ? false : true}
                                        id='sharingTreeSelect'
                                        resetFilterOnHide={true}
                                        //appendTo={document.body}
                                        selectionMode='checkbox'
                                        placeholder='Select suborganizations'
                                        value={this.state.checkedOrgKeys}
                                        options={this.props.orgArray}
                                        display='chip'
                                        scrollHeight='200px'
                                        filter
                                        onChange={(e) => {
                                            e.stopPropagation()
                                            this.handleMemberChange(e.value, true)
                                        }} style={{
                                            borderBottom: '1px solid gray', maxWidth: '19vw'
                                        }} />
                                </div>
                                <div className="w-full" >
                                    <TreeSelect disabled={this.props.departmentMembers?.length !== 0 ? false : true}
                                        id='sharingTreeSelect'
                                        resetFilterOnHide={true}
                                        appendTo={document.body}
                                        selectionMode='checkbox'
                                        placeholder='Select users'
                                        //title='Select colleagues'
                                        value={this.state.checkedKeys}
                                        options={this.props.userArray}
                                        display='chip'
                                        scrollHeight='200px'
                                        //optionLabel='label'
                                        //optionGroupLabel='label'
                                        //optionGroupChildren="items"
                                        //optionGroupTemplate={groupedItemsTemplate}
                                        filter
                                        onChange={(e) => {
                                            e.stopPropagation()
                                            this.handleMemberChange(e.value, false)
                                        }} style={{
                                            borderBottom: '1px solid gray', marginLeft: 10, maxWidth: '19vw'
                                        }} />
                                </div>
                            </div>
                            {this.state.usersShared && this.state.usersShared.length !== 0 ?
                                <React.Fragment>
                                    <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                        <label style={{ verticalAlign: 'sub' }}>Editable for shared users</label>
                                    </div>
                                    <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                        <MultiSelect
                                            pt={{
                                                checkboxIcon: {
                                                    onClick: (e) => {
                                                        e.stopPropagation();
                                                        if (e.target.className.baseVal !== "") {
                                                            e.target.parentNode.click();
                                                        } else {
                                                            e.target.parentNode.parentNode.click();
                                                        }
                                                    },
                                                },
                                                headerCheckbox: {
                                                    onClick: (e) => {
                                                        e.stopPropagation();
                                                        e.target.parentNode.click();
                                                    },
                                                },
                                            }}
                                            id='sharingMultiSelect'
                                            display='chip'
                                            placeholder='Select users'
                                            onChange={(e) => this.onWriteMembersChange(e.value)}
                                            value={this.state.usersWrite}
                                            options={this.state.sharedUsers}
                                        >
                                        </MultiSelect>
                                    </div>
                                </React.Fragment>
                                :
                                null}
                            <div style={{ display: 'flex', justifyContent: 'end', paddingLeft: 17 }}>
                                <div className="full-text-message-collections col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8">
                                    Note: Users changing the suborganization will lose access to search collections shared on suborganization level, but will keep access to search collections shared on personal level.
                                </div>
                            </div>
                        </div>
                        <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                            <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 20 }}>* required</label>
                            <Button label="Save" onClick={this.handleModifyWatchlist} style={{ float: 'right' }}
                                className='p-button-sm primaryButton' />
                            <Button label="Cancel" onClick={(e) => this.props.onHideModifyWatchlist(e)}
                                className="p-button-secondary p-button-sm"
                                style={{ float: 'right', marginRight: 5 }} />
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogEditQuery} style={{ 'width': "50vw" }}
                        header="Search details"
                        modal={true} blockScroll
                        //footer={footerEditorEdit}
                        dismissableMask={true}
                        onHide={(e) => this.props.onHideEditQuery(e)} className='styledDialog'>
                        <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Name *</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                <InputText maxLength={255}
                                    //keyfilter='alphanum'
                                    autoComplete="off"
                                    disabled={this.state.isShared ? true : false}
                                    onChange={(e) => { this.updateQueryFullNameEdit(e.target.value) }}
                                    value={this.state.queryFullNameEdit} />
                                <label style={{ color: 'darkgray' }}>Max. 255 characters</label>
                            </div>
                            {/*<div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Short name *</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                <InputText autoComplete="off"
                                    maxLength={20}watchlistEditable
                                    disabled={this.state.isShared ? true : false}
                                    onChange={(e) => { this.updateQueryNameEdit(e.target.value) }}
                                    value={this.state.queryNameEdit} />
                                <label style={{ color: 'darkgray' }}>Used in compact views. Max. 20 characters</label>
                        </div>*/}
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Description</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                <InputTextarea maxLength={200} disabled={this.state.isShared ? true : false} rows={2} cols={30} onChange={(e) => { this.updateQueryDescriptionEdit(e.target.value) }} value={this.state.queryDescriptionEdit} />
                                <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Type</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em', paddingTop: 18, color: '#757575' }}>
                                {this.state.queryTypeEdit ?
                                    this.state.queryTypeEdit === QUERY_TYPE_QUICK_SEARCH_2.id ? QUERY_TYPE_QUICK_SEARCH_2.label :
                                        this.state.queryTypeEdit === QUERY_TYPE_ADVANCED_SEARCH.id ? QUERY_TYPE_ADVANCED_SEARCH.label :
                                            this.state.queryTypeEdit === QUERY_TYPE_ADVANCED_SEARCH_3.id ? QUERY_TYPE_ADVANCED_SEARCH_3.label :
                                                this.state.queryTypeEdit === QUERY_TYPE_CHEM_SEARCH.id ? QUERY_TYPE_CHEM_SEARCH.label :
                                                    this.state.queryTypeEdit === QUERY_TYPE_COOC_SEARCH.id ? QUERY_TYPE_COOC_SEARCH.label :
                                                        this.state.queryTypeEdit === QUERY_TYPE_FACT_SEARCH.id ? QUERY_TYPE_FACT_SEARCH.label :
                                                            this.state.queryTypeEdit === QUERY_TYPE_QUICK_SEARCH.id ? QUERY_TYPE_QUICK_SEARCH.label :
                                                                this.state.queryTypeEdit === QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.id ? QUERY_TYPE_SENTENCE_ANALYSIS_SEARCH.label :
                                                                    (this.state.queryTypeEdit.charAt(0).toUpperCase() + this.state.queryTypeEdit.slice(1)).replace(/_/g, ' ') : ''}
                                {/*
                                <InputText disabled={true}
                                    readOnly={true} id="name"
                                    onChange={(e) => { this.updateSimpleQueryName(e.target.value) }}
                                    value={this.state.queryTypeEdit ? (this.state.queryTypeEdit.charAt(0).toUpperCase() + this.state.queryTypeEdit.slice(1)).replace(/_/g, ' ') : ''} />
                               */}
                            </div>
                            {/*<div className="col-4" style={{ padding: '.75em' }}><label htmlFor="collection">Collection(s)</label></div>
                        <div className="col-8" style={{ padding: '.5em' }}>
                            <InputText readOnly={true} disabled={true} id="newName" value={this.state.queryCollectionsEdit} />
</div>*/}
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Search</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em', paddingTop: 10 }}>
                                {this.state.queryStringEdit}
                                {/*<InputTextarea rows={5} cols={30} disabled={true} readOnly={true} value={this.state.queryStringEdit} />*/}
                            </div>
                            {this.state.repositoryIDs ?
                                <>
                                    <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                        <label style={{ verticalAlign: 'text-top' }} htmlFor="repos">Number of
                                            repositories</label>
                                    </div>
                                    <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" id="repos"
                                        style={{ padding: '.5em' }}>
                                        {this.state.repositoryIDs.length}
                                    </div>
                                </> : null
                            }
                            {this.state.tempExcludedTerms ?
                                <>
                                    <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                        <label style={{ verticalAlign: 'text-top' }} htmlFor="repos">Number of blocked
                                            concepts</label>
                                    </div>
                                    <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" id="repos"
                                        style={{ padding: '.5em' }}>
                                        {Object.keys(this.state.tempExcludedTerms).length}
                                    </div>
                                </> : null
                            }
                            {this.state.blocklistIDs ?
                                <>
                                    <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                        <label style={{ verticalAlign: 'text-top' }} htmlFor="repos">Number of
                                            blocklists</label>
                                    </div>
                                    <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" id="repos"
                                        style={{ padding: '.5em' }}>
                                        {this.state.blocklistIDs.length}
                                    </div>
                                </> : null
                            }
                            <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                <label style={{ verticalAlign: 'sub' }}>Collections</label>
                            </div>
                            <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em', paddingTop: this.state.isShared ? 15 : 10 }}>
                                {!this.state.isShared ?
                                    <MultiSelect
                                        pt={{
                                            checkboxIcon: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    if (e.target.className.baseVal !== "") {
                                                        e.target.parentNode.click();
                                                    } else {
                                                        e.target.parentNode.parentNode.click();
                                                    }
                                                },
                                            },
                                            headerCheckbox: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    e.target.parentNode.click();
                                                },
                                            },
                                        }}
                                        maxSelectedLabels={3}
                                        appendTo={document.body}
                                        placeholder='Select collections'
                                        value={this.state.selectedCollections}
                                        options={collections}
                                        disabled={this.state.isShared ? true : false}
                                        onChange={(e) => this.handleCollectionChange(e.value)} />
                                    :
                                    <label style={{ color: '#757575' }}>{this.state.queryCollectionsEdit ? this.state.queryCollectionsEdit : '-'}</label>
                                }
                            </div>
                        </div>
                        <div className='col-12' style={{ paddingRight: 25, marginTop: 5, marginBottom: 25 }}>
                            <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 20 }}>* required</label>
                            <Button disabled={this.state.isShared || this.state.queryFullNameEdit === "" || this.state.queryNameEdit === "" ? true : false} label="Save" onClick={this.onModifyQuery} style={{ float: 'right' }} className='p-button-sm primaryButton' />
                            <Button label="Cancel" onClick={(e) => this.props.onHideEditQuery(e)}
                                className="p-button-secondary p-button-sm"
                                style={{ float: 'right', marginRight: 5 }} />

                        </div>
                    </Dialog>

                    {hasUserRole("ROLE_APPS") && this.props.userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' &&
                        <CreateEditApp
                            userData={this.props.userData}
                            displayDialog={this.state.displayCreateAppDialog}
                            onHide={(e) => this.setState({
                                displayCreateAppDialog: false
                            })}
                            onShowToast={this.onShowToast}
                            initialData={{
                                queryCollection: this.props.selectedWatchlist,
                                sourceType: { name: 'My Saved Searches', id: 1 }
                            }}
                        />
                    }
                </LoadingOverlay >
            </div >
        )
    }
}

export default WatchlistQueries;
