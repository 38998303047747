import { Toast } from 'primereact/toast';
import React, { Component, createRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { roundNumber } from '../../../util';


class PropertiesDetails extends Component {

    constructor(props) {
        super(props);

        this.growl = createRef();
    }

    showSuccessGrowl = () => {
        this.growl.current.show({
            sticky: false,
            severity: 'success',
            summary: 'Success',
            detail: `Copied to clipboard.`
        });
    }


    render() {

        const { compoundProperties, propertiesDefinitions } = this.props;

        return (
            <>
                <Toast ref={this.growl} />

                <div className='grid' style={{ width: 'auto' }}>
                    {propertiesDefinitions
                        .filter((ei, index) => {
                            const extraInfoData = compoundProperties ? compoundProperties[ei.id] : null;
                            return !!extraInfoData;
                        })
                        .map((ei, index) => {
                            const extraInfoData = compoundProperties ? compoundProperties[ei.id] : null;
                            //if (extraInfoData) {
                            const value = ei.roundNumber ? roundNumber(extraInfoData, 1) : extraInfoData;

                            return (
                                <React.Fragment key={ei.id}>
                                    <div className="col-fixed" style={{ width: 150 }}>
                                        <span>{ei.label} </span>
                                    </div>
                                    <div className="col textAlignRight croppedTableCell">
                                        <CopyToClipboard
                                            text={value}>
                                            <a title={`Click to copy ${ei.info} to clipboard: ${value}`}
                                                className='secondaryLink secondaryInfo'
                                                style={{ fontWeight: 'bold' }}
                                                onClick={(e) => this.showSuccessGrowl()}>
                                                {value}
                                            </a>
                                        </CopyToClipboard>
                                    </div>
                                    <div className="breakRow"></div>
                                </React.Fragment>
                            )
                            //}
                        })}
                </div>
            </>
        )
    }
}
export default PropertiesDetails