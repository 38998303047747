import { useEffect, useState } from 'react'
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { APP_DESCRIPTION, APP_TITLE, SORT_OPTION } from './core/constants';
import AppGroupItem from './components/AppGroupItem';

const AppGroup = (props) => {

    const [categories, setCategories] = useState(props.categories)
    const [selectedOption, setSelectedOption] = useState('count')
    const [selectedCategory, setSelectedCategory] = useState('')

    return (
        <div className="col-12 grid-nogutter p-0 pl-2">
            <LoadingOverlay>
                <div className='col-12 px-0 pt-0' >
                    <div className='col-12 p-0'>
                        <div className='col-12'>
                            {/*<div className='col-12 ex-gr-header' >
                                <h1 className="pageHeader">Apps</h1>
    </div>*/}
                            {/*<div className='grid ex-header-container' >
                                <div className="col-12" >
                                    <i className="material-icons search-icon"
                                        title='Apps'>
                                        {'search'}
                                    </i>
                                    <InputText
                                        type="search"
                                        className="collectionFilter ex-gr-seach"
                                        //onInput={onSearch}
                                        placeholder="Apps"
                                    />
                                </div>
    </div>*/}
                        </div>
                        <div className='col-12 py-0 pl-0 pr-10' >
                            <div className='col-12 ex-gr-sort-container'>
                                <label >Name</label>
                                <div  style={{marginTop: 7}}>
                                    {/*<label className='mr-0' >Sort by: </label>
                                    <Dropdown
                                        className='dropdownNoBorder mr-5'
                                        appendTo={document.body}
                                        options={SORT_OPTION}
                                        value={selectedOption}
                                        disabled={true}
                                        //onChange={(e) => onSortExports(e)}
/>*/}
                                    <span className='ml-0'>
                                        <label className='mr-15'>Entries</label>
                                    </span>
                                </div>
                            </div>
                            <div className='col-12 pt-0 pr-0 pl-0' >
                            <AppGroupItem
                                key={APP_TITLE}
                                //handleExportChange={handleExportChange}
                                category={{
                                    title: APP_TITLE,
                                    //description: APP_DESCRIPTION,
                                    count: props.allExportLength
                                }}
                                style={{ background: `${(selectedCategory === '' || selectedCategory === APP_TITLE || props.allExportLength === 0) ? '#e8f3fc' : ''}` }}
                            />
                            {
                                categories?.map(category => {
                                    return <AppGroupItem key={category.title}
                                        //handleExportChange={handleExportChange}
                                        category={category}
                                        selectedCategory={selectedCategory}
                                    />

                                })
                            }
                        </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default AppGroup
