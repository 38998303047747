import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { Component, createRef } from 'react';
import { isArrayEmpty } from '../../util';
import { EXPORTER_FORMATS, EXPORT_OPTION_ID_ALL, EXPORT_OPTION_ID_SELECTED, GLOBAL_CHEMISTRY_DOWNLOAD_LIMIT } from '../utils/export';
import ExportLimit from '../../export/ExportLimit';


class ChemistryExportDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedExportOption: !isArrayEmpty(this.props.exportOptions) ? this.props.exportOptions[0] : null,
            selectedFileFormat: EXPORTER_FORMATS[0]
        };

        this.growl = createRef();
    }

    onExportSubmit = () => {
        this.props.onExportSubmit(this.state.selectedExportOption, this.state.selectedFileFormat);
    }


    render() {

        const { displayDialog, onToggleDialog, exportOptions, numOfSelectedElements, maxNumOfElements } = this.props;

        return (
            <>
                <Toast ref={this.growl} />

                <Dialog
                    focusOnShow={false}
                    visible={displayDialog}
                    style={{ minWidth: '50vw', maxWidth: '90vw' }}
                    header="Export compounds"
                    modal={true}
                    dismissableMask={true}
                    onShow={() => { this.setState({ selectedExportOption: exportOptions[0], selectedFileFormat: EXPORTER_FORMATS[0] }); }}
                    onHide={() => {
                        this.setState({ selectedExportOption: null, selectedFileFormat: null });
                        onToggleDialog(false);
                    }} className='styledDialog'>

                    <div className="grid" style={{ paddingLeft: 25, paddingRight: 10, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>

                        <div className="col-fixed" style={{ width: 130, marginTop: 2 }}>Export</div>
                        <div className="col">
                            {exportOptions.map(docSel => {
                                return <div key={docSel.id}
                                    className="p-field-radiobutton inline whiteSpaceNoWrap">
                                    <RadioButton
                                        inputId={docSel.id}
                                        name="docSel"
                                        value={docSel}
                                        onChange={(e) => { this.setState({ selectedExportOption: e.value }); }}
                                        checked={this.state.selectedExportOption && this.state.selectedExportOption.id === docSel.id}
                                    />
                                    <label style={{ marginRight: 10 }} htmlFor={docSel.id}>
                                        {docSel.label}
                                        {docSel.id === EXPORT_OPTION_ID_SELECTED && numOfSelectedElements > 0 ? ` (${numOfSelectedElements})` : ''}
                                        {docSel.id === EXPORT_OPTION_ID_ALL && maxNumOfElements > 0 ? ` (${maxNumOfElements})` : ''}
                                    </label>
                                </div>
                            })}
                        </div>
                        <div className="breakRow"></div>

                        {this.state.selectedExportOption?.multipleFileFormats ?
                            <>
                                <div className="col-fixed" style={{ width: 130, paddingTop: 15 }}>
                                    <label htmlFor="format">File format:</label>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        id='format'
                                        value={this.state.selectedFileFormat}
                                        options={EXPORTER_FORMATS}
                                        onChange={e => this.setState({ selectedFileFormat: e.value })}
                                        //showClear={true}
                                        placeholder="Select file format"
                                        appendTo={document.body}
                                    />
                                </div>
                                <div className="breakRow"></div>
                            </> : null
                        }
                    </div>
                    <div style={{ paddingTop: 15, float: 'left', paddingLeft: 20 }}>
                        <ExportLimit maximumEntriesToExport={this.props.maximumChemistryEntriesToExport || GLOBAL_CHEMISTRY_DOWNLOAD_LIMIT} />
                    </div>
                    <div style={{ paddingTop: 10, paddingBottom: 5, float: 'right', paddingRight: 20 }}>
                        <Button
                            className='p-button-sm primaryButton'
                            label="Export"
                            onClick={e => {
                                this.setState({ displayExportDialog: false });
                                this.onExportSubmit();
                            }}
                        />
                    </div>

                </Dialog>
            </>
        );
    }
}

//export default ChemistryExportDialog

const mapStateToProps = (state) => ({ maximumChemistryEntriesToExport: state.user.data.userDetails.department.numberMaximumEntriesToExport })

export default withRouter(connect(mapStateToProps, {})(ChemistryExportDialog))