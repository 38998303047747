import { PureComponent } from 'react';


class SequenceDetails extends PureComponent {

    render() {
        const { sequenceOcid, sequenceLength, sequence } = this.props;

        return (
            <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 30, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                <div className="col-4" style={{ padding: '.75em' }}>
                    <label htmlFor="OCID">Sequence ID</label>
                </div>
                <div className="col-8" style={{ padding: '.75em' }}>
                    <label htmlFor="OCID">{sequenceOcid}</label>
                </div>
                <div className="col-4" style={{ padding: '.75em' }}>
                    <label htmlFor="OCID">Sequence length</label>
                </div>
                <div className="col-8" style={{ padding: '.75em' }}>
                    <label htmlFor="OCID">{sequenceLength}</label>
                </div>
                <div className="col-4" style={{ padding: '.75em' }}>
                    <label htmlFor="OCID">Sequence</label>
                </div>
                <div className="col-6" style={{ padding: '.75em', width: 635 }}>
                    <label style={{ fontFamily: 'monospace' }} htmlFor="OCID">{`${sequence.substring(0, 1749)}`} {sequence.length > 1750 ? '...' : ''}</label>
                </div>
            </div>
        );
    }
}

export default SequenceDetails;