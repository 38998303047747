import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useRef } from "react";


const IDCheckImportButton = ({
    items = [],
    disabled = false,
    ...rest
}) => {

    const menu = useRef(null);

    return <>
        <Menu
            ref={menu}
            model={items}
            popup
            style={{ width: 'auto' }}
        />
        <Button
            //className="primaryButton p-button-sm"
            label="Import into search"
            //icon="pi pi-bars"
            disabled={disabled}
            onClick={(e) => menu.current.toggle(e)}
            {...rest}
        />
    </>
}

export default IDCheckImportButton;