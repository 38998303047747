import React, {useRef, useState} from 'react';
import styles from './Facett.module.scss';
import {addThousandsSeparatorToNumber} from "../../webapi/util";
import Link from "../Link/Link";
import {sendApiRequest} from "../../../api";
import TermMenu from "../TermMenu/TermMenu";
import {OverlayPanel} from "primereact/overlaypanel";
import {findKeysToExpand} from "../helpers/findKeyToExpand";
import {useDispatch, useSelector} from "react-redux";
import {setPopupExpandedKeys, setPopupNodes, setPopupTreeName} from "../../../redux/actions/BiomarkerActions";
import {addFieldNameToTree} from "../helpers/addFieldNameToTree";
import {APP_PROPERTIES} from '../../../properties'

export const Facett = ({
                             node,
                             setIsDialogVisible,
                             setPopupIsLoading,
                             hasTree,
                             url, tree, showToast, queryTerms, setQueryTerms, setTargetToScroll
                         }) => {
    const [isHovered, setIsHovered] = useState(false);
    const overlayPanel = useRef(null);
    const facettRef = useRef(null);

    const popupTreeName = useSelector(({biomarker}) => biomarker.popupTreeName);
    const popupNodes = useSelector(({biomarker}) => biomarker.popupNodes);

    const dispatch = useDispatch();

    const ocids = node.ocid ? [node.ocid] : [];

    const showOverlayPanel = event => overlayPanel.current.show(event);

    const hideOverlayPanel = event => overlayPanel.current.hide(event);

    const onMouseEnterHandler = () => setIsHovered(true);

    const onMouseLeaveHandler = () => setIsHovered(false);

    const onClickHandler = async () => {
        try {
            setIsDialogVisible(true);
            setTargetToScroll(node.key);
            if (popupTreeName !== tree) {
                // compare treeName from app state and facette's tree. if it is not equal fetch new data
                dispatch(setPopupTreeName(tree));
                dispatch(setPopupNodes(null));
                setPopupIsLoading(true);
                const response = await sendApiRequest('GET', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/${url}`);
                // expanded keys has to be set before setting nodes. order is required - otherwise the tree won't be expanded
                dispatch(setPopupExpandedKeys(findKeysToExpand(response.payload[0], node.key)));
                const nodesWithFieldName = addFieldNameToTree(response.payload[0], node.fieldName);
                dispatch(setPopupNodes(nodesWithFieldName));
            } else {
                dispatch(setPopupExpandedKeys(findKeysToExpand(popupNodes[0], node.key)));
            }

        } catch (e) {
            console.error(e)
            showToast(`Something went wrong while loading data\n ${e} `, 'Error occurred', 'error', 10000);
        } finally {
            setPopupIsLoading(false);
        }
    }

    const tooltip = `${node.term}: ${addThousandsSeparatorToNumber(node.count)}`;

    return (
        <>
            <OverlayPanel ref={overlayPanel} className='search-option-overlaypanel'>
                <TermMenu
                    ocids={ocids} originalTerms={node.term}
                    queryTerms={queryTerms}
                    setQueryTerms={setQueryTerms}
                    hideOverlayPanel={hideOverlayPanel}
                    showToast={showToast}
                    fieldName={node.fieldName}
                />
            </OverlayPanel>

            <li className={styles.facett} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}
                title={tooltip}>
                <p onClick={showOverlayPanel}>{node.term}</p>
                {hasTree && isHovered && node.ocid ? <Link title='Show in tree' onClick={onClickHandler}/> :
                    <span ref={facettRef}>{addThousandsSeparatorToNumber(node.count)}</span>}
            </li>
        </>
    );
};
