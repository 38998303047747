import React, { Component } from 'react';
import { connect } from 'react-redux';


/**
 * Interface Messages
 */
class Message extends Component {
  
  /*constructor(props) {
    super(props);
  }*/
  
    
  /**
   * render the component
   */
  render() {
    
    return (
      <div id="interfaceMessages">
        <h2>Interface Messages</h2>
        <p>Here you can find a log of interface messages in the current session.</p>
        
        
        <div className="messageTable">
          <div className="grid">
            <div className="col-12 md:col-2 lg:col-2 sm:col-2"><strong>Severity</strong></div>
            <div className="col-12 md:col-2 lg:col-2 sm:col-2"><strong>Date</strong></div>
            <div className="col-12 md:col-2 lg:col-2 sm:col-2"><strong>Summary</strong></div>
            <div className="col-12 md:col-6 lg:col-6 sm:col-6"><strong>Details</strong></div>
          </div>
          {
            this.props.growlmessages.reverse().map ((msg, index) => {
                  let debug = "";
                  if (msg.debug && msg.debug.length > 0)
                    debug = <pre>{msg.debug}</pre>
                  return <div className={"grid message-" + msg.severity} key={index}>
                    <div className="col-12 md:col-2 lg:col-2 sm:col-2"><strong>{msg.severity}</strong></div>
                    <div className="col-12 md:col-2 lg:col-2 sm:col-2">{new Date (msg.date).toLocaleString() }</div>
                    <div className="col-12 md:col-2 lg:col-2 sm:col-2">{msg.summary}</div>
                    <div className="col-12 md:col-6 lg:col-6 sm:col-6">{msg.detail}{debug}</div>
                  </div>
                }
              )
          }
        </div>
       </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    growlmessages: state.managerReducer.growlmessages
  }
}



export default connect (
  mapStateToProps
) (Message);
