import dateFormat from 'dateformat';


const dateLastMonth = new Date();
//const yearFormatted = dateFormat(date, 'yyyy');
dateLastMonth.setMonth(dateLastMonth.getMonth() - 3);
const dateLastMonthFormatted = dateFormat(dateLastMonth, 'yyyy-mm-dd');

const dateLastYear = new Date();
//const yearFormatted = dateFormat(date, 'yyyy');
dateLastYear.setFullYear(dateLastYear.getFullYear() - 1);
const dateLastYearFormatted = dateFormat(dateLastYear, 'yyyy-mm-dd');
export const EDV_EXAMPLES = [
    {
        // id is mandatory, it will be used as key,
        //  since there can be multiple sections
        id: "edv",
        //label: 'Search coocs',
        //description: 'blablubb',
        examples: [{
            label: 'Cognitive effects of schizophrenia in relation to chlorpromazine treatment',
            description: 'Patents published in the last year about cognitive effects of schizophrenia in relation to chlorpromazine treatment.',
            queryObject: {
                id: 'pat_example',
                query: `+(+ocid:201000000980 +ocid:230000001149 +(ocid:190000008433 ocid:229960001076)) +(+pdate:${dateLastYearFormatted})`,
                queryTerms: [
                    {
                        domains: ['diseases'],
                        label: "schizophrenia",
                        ocids: [201000000980],
                        queryValues: ['schizophrenia'],
                        term: "schizophrenia",
                        type: "concept"
                    },
                    {
                        domains: ['effects'],
                        label: "cognitive effects",
                        ocids: [230000001149],
                        queryValues: ['cognitive effects'],
                        term: "cognitive effects",
                        type: "concept"
                    },
                    {
                        domains: ['chem', 'drugs'],
                        label: "chlorpromazine",
                        ocids: [190000008433, 229960001076],
                        queryValues: ['chlorpromazine'],
                        term: "chlorpromazine",
                        type: "concept"
                    }
                ],
                queryType: "Quick Search",
                sortCriteria: {
                    label: "Relevance and date",
                    sortField: "relevanceAndDate",
                    sortMode: "descending",
                    sortModes: [
                        { label: 'ascending', value: 'ascending' },
                        { label: 'descending', value: 'descending' }
                    ],
                    value: "relevanceAndDate"
                }
            },
        }, {
            label: 'Treatments for primary biliary cirrhosis',
            description: 'PMC documents published in the last three months about treatments for primary biliary cirrhosis.',
            queryObject: {
                id: 'pmc_example',
                query: `+(ocid:231200000488 ocid:238000011282) +(ocid:208000008439 ocid:208000033222 ocid:208000012654) +(+pdate:${dateLastMonthFormatted})`,
                queryTerms: [
                    {
                        domains: ['clinicaltrials', 'methods'],
                        label: "treatment",
                        ocids: [231200000488, 238000011282],
                        queryValues: ['treatment'],
                        term: "treatment",
                        type: "concept"
                    },
                    {
                        domains: ['diseases'],
                        label: "primary biliary cirrhosis",
                        ocids: [208000008439, 208000033222, 208000012654],
                        queryValues: ['primary biliary cirrhosis'],
                        term: "primary biliary cirrhosis",
                        type: "concept"
                    }
                ],
                queryType: "Quick Search",
                sortCriteria: {
                    label: "Relevance and date",
                    sortField: "relevanceAndDate",
                    sortMode: "descending",
                    sortModes: [
                        { label: 'ascending', value: 'ascending' },
                        { label: 'descending', value: 'descending' }
                    ],
                    value: "relevanceAndDate"
                }
            }
        }
        ]
    }
]