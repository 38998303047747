/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { checkResultAndGetPayload } from '../../../api';
import { Checkbox } from 'primereact/checkbox';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import {
    createCorrelationRequest,
    createCorrelationSentencesRequest,
    createOCIDForCorrelationsRequest,
    fetchCorrelations,
    createCorrelationTopTermsRequest,
} from '../../../api/content/CorrelationsApi';
import {
    createDocumentSearchRequest,
    fetchDocuments,
    fetchDocumentsBulk
} from '../../../api/content/DocumentApi';
import { storeQuery, updateQuery } from '../../../api/content/WatchlistApi';
import { Element } from "react-scroll";
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { REPO_ROLE_COOCS, QUERY_TYPE_COOC_SEARCH, QUERY_FORM_SUPPLIERS, QUERY_FORM_DISEASE_TARGETS, QUERY_CATEGORY_COOC_SEARCH, REPOSITORY_INFO_NOT_AVAILABLE, QUERY_TYPE_QUICK_SEARCH_2, APP_PROPERTIES } from '../../../properties';
import { shortenLargeNumber, getYearRangeWithStartYear, sortObjectArrayByAttribute, scale, isArrayEmpty, hasUserRole, getIntersectionArray, removeHtmlTagsFromText } from '../util';
import CorrelationsChart from './CorrelationsChart';
import CorrelationsSentences from './CorrelationsSentences';
import CorrelationsTable from './CorrelationsTable';
import CorrelationsInputField from './CorrelationsInputField';
import CorrelationsList from './CorrelationsList';
import dateFormat from 'dateformat'
import { withRouter } from 'react-router-dom';
import ConfirmationDialog from '../../common/ConfirmDialog/ConfirmationDialog';
import { Toast } from 'primereact/toast';
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
import { Link } from 'react-router-dom';
import { createQuickSearchObjectFromCoocQueryTerms } from '../general/docsearch/searchUtil';
import { addDataToLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage } from '../util/localStorage';
import _, { isString } from 'lodash';
import { DocViewModal } from '../docview/common/DocviewModal/DocviewModal';
import { connect } from 'react-redux';
import { setQuickSearchSelectedTerms } from '../../../redux/actions/QuickSearchActions';
import { extractContent } from './helper';
import SaveSearchDialog from '../../common/SaveSearch/SaveSearchDialog';
import SaveSearchButtons from '../../common/SaveSearch/SaveSearchButtons';
import SeparatorPoint from '../general/SeparatorPoint';


class CorrelationsView extends Component {

    constructor(props) {
        super(props);

        const repositoriesFiltered = props.repositories ? props.repositories.filter(repo =>
            repo.active && repo.features && repo.features.includes(REPO_ROLE_COOCS.id)) : []
        const convertedRepositoryData = this.convertRepositoryData(repositoriesFiltered);

        this.state = {
            activeIndex: 0,

            suggestions: [],
            queryTermsLeft: [],
            queryTermsRight: [],
            tempExcludedTerms: {},
            showResults: false,
            selectedRepositories: [],
            // --- sentences --- //
            showSources: true,
            groupBySource: false,
            ontologically: false,
            selectedDocuments: [],
            ...convertedRepositoryData,
            // --- search history --- //
            //searchHistory: []
            //
            rowsPairs: 50,
            firstPairs: 0,
            rowsLeftEntities: 50,
            firstLeftEntities: 0,
            rowsRightEntities: 50,
            firstRightEntities: 0,

            queryChanged: false,
            searchInitiated: false,
            isDocviewModalVisible: false,
            ocDocId: '',
            selectedDocument: null,
            repositoryId: '',
            headerDocuments: []
        };

        this.corrResRef = createRef();
        this.sentencesRef = createRef();
        this.growl = createRef();
        this.toast = createRef();
    }




    onShowDocviewModal = (repId, docId, doc) => {
        this.setState({
            ocDocId: docId,
            repositoryId: repId,
            selectedDocument: doc,
            isDocviewModalVisible: true
        })
    };



    onCloseDocviewModal = () => {
        this.setState({
            ocDocId: '',
            repositoryId: '',
            selectedDocument: null,
            isDocviewModalVisible: false
        })
    };


    async componentDidMount() {

        //console.log('this.props.location: ', this.props.location);

        /*
        if (this.props.restrictionsRight && !isArrayEmpty(this.props.restrictionsRight.subtrees)) {
            const defaultInput = [this.props.restrictionsRight.subtrees[0]];
            this.setState({
                queryTermsRight: defaultInput
            });
        }
        */
        this.setState({
            queryTermsRight: this.props.defaultPresetRight ? [this.props.defaultPresetRight] : null
        });
    }

    componentDidUpdate(prevProps, prevState) {

        //if (this.props.repositories !== prevProps.repositories) {
        if (!isArrayEmpty(this.props.repositories) && !_.isEqual(prevProps.repositories, this.props.repositories)) {
            const repositoriesFiltered = this.props.repositories ? this.props.repositories.filter(repo =>
                repo.active && repo.features && repo.features.includes(REPO_ROLE_COOCS.id)) : []
            const convertedRepositoryData = this.convertRepositoryData(repositoriesFiltered);
            this.setState({
                ...convertedRepositoryData
            });
        }

        // --- update default input in field --- //
        if (this.props.defaultPresetRight !== prevProps.defaultPresetRight) {
            this.setState({
                queryTermsRight: this.props.defaultPresetRight ? [this.props.defaultPresetRight] : null
            });
        }

        // --- fill form if local storage contains data, and blacklists and repositories are loaded --- //
        const dataFromStorage = getDataFromLocalStorage(QUERY_TYPE_COOC_SEARCH.id);
        if (dataFromStorage?.formContent && dataFromStorage.form === this.props.view.id &&
            this.props.blacklists && this.props.repositories) {

            let formContent = dataFromStorage.formContent;
            formContent = isString(formContent) ? JSON.parse(formContent) : formContent;

            let selectedRepositories = [];
            if (formContent.repositoryIDs && this.state.repositories) {
                selectedRepositories = this.state.repositories.filter(repo => formContent.repositoryIDs.includes(repo.id));
            }
            // --- if none are defined -> use all --- //
            else if (this.state.repositories) {
                selectedRepositories = this.state.repositories;
            }

            let selectedBlacklists = [];
            if (formContent.blacklistIDs && this.props.blacklists) {
                formContent.blacklistIDs = formContent.blacklistIDs.filter(bl => !!bl);
                selectedBlacklists = this.props.blacklists.filter(bl => formContent.blacklistIDs.includes(bl.id));
                const numOfMissingBlocklists = formContent.blacklistIDs.length - selectedBlacklists.length;
                if (numOfMissingBlocklists > 0) {
                    this.growl.current.show({
                        sticky: false, closable: true, severity: 'warn', life: 10000,
                        summary: 'Unavailable blocklists',
                        detail: `${numOfMissingBlocklists} ${numOfMissingBlocklists > 1 ? 'blocklists are' : 'blocklist is'} not available`
                    });
                }
            }

            this.setState({
                queryTermsLeft: formContent.queryTermsLeft,
                queryTermsRight: formContent.queryTermsRight,
                tempExcludedTerms: formContent.tempExcludedTerms ? formContent.tempExcludedTerms : {},
                selectedRepositories: selectedRepositories,
                selectedReposPlaceholder: selectedRepositories.length === 1 ? selectedRepositories[0].label : `${selectedRepositories.length} selected`,
                blacklists: selectedBlacklists.map(bl => bl.id),
                startDate: formContent.startDate ? new Date(formContent.startDate) : '',
                endDate: formContent.endDate ? new Date(formContent.endDate) : '',
                //
                savedSearchID: dataFromStorage.id,
                savedSearchName: dataFromStorage.fullName,
                editableQuery: dataFromStorage.edit,
            }, async () => {
                if (dataFromStorage.runSearch) {
                    await this.onSearchCorrelations();
                }
            });
            // --- remove form data from local storage --- //
            removeDataFromLocalStorage(QUERY_TYPE_COOC_SEARCH.id);
        }

        // ???
        if (prevProps.changedBlacklists !== this.props.changedBlacklists) {
            //if (this.props.view.id === 'correlations') {
            //console.log('changedBlacklists != ', (prevProps.changedBlacklists !== this.props.changedBlacklists));
            //console.log(`this.props.changedBlacklists: `, this.props.changedBlacklists);
            const intersection = getIntersectionArray(this.props.changedBlacklists, this.state.blacklists);
            //console.log('intersection: ', intersection);
            if (!isArrayEmpty(intersection)) {
                this.setState({ queryChanged: true }, () => this.updateToast());
            }
            //}
        }
    }

    convertRepositoryData = (repositories) => {
        let repos = [];
        const repositoryLabelsMap = {};

        if (repositories) {
            // --- repository select items --- //
            repos = [...repositories];
            // --- store repository labels in map --- //
            repositories.forEach(repo => {
                repositoryLabelsMap[repo.id] = repo.label;
            });
        }

        return {
            repositories: repos,
            selectedRepositories: repos,
            selectedReposPlaceholder: repos.length === 1 ? repos[0].label : `${repos.length} selected`,
            repositoryLabelsMap: repositoryLabelsMap,
        };
    }

    resetForm = () => {

        this.setState({
            savedSearchID: null,
            savedSearchName: null,
            // ?
            suggestions: [],
            queryTermsLeft: [],
            queryTermsRight: [],
            showResults: false,
            startDate: '',
            endDate: '',
            tempExcludedTerms: {},
            blacklists: [],
            // --- sentences --- //
            groupBySource: false,
            ontologically: false,
            selectedDocuments: [],
            // --- repositories --- //
            selectedRepositories: this.state.repositories,
            selectedReposPlaceholder: this.state.repositories.length === 1 ? this.state.repositories[0].label : `${this.state.repositories.length} selected`
        });

        if (this.props.restrictionsRight && !isArrayEmpty(this.props.restrictionsRight.subtrees)) {
            const defaultInput = [this.props.restrictionsRight.subtrees[0]];
            this.setState({
                queryTermsRight: defaultInput
            });
        }
    }


    handleInputChangeLeft = (value) => {
        this.setState({
            queryTermsLeft: value,
            queryChanged: true
        }, () => this.updateToast());
    }

    handleInputChangeRight = (value) => {
        this.setState({
            queryTermsRight: value,
            queryChanged: true
        }, () => this.updateToast());
    }

    onSearchCorrelations = async () => {
        const { queryTermsLeft, queryTermsRight, selectedRepositories, startDate, endDate, blacklists, tempExcludedTerms } = this.state;
        if (isArrayEmpty(queryTermsLeft) && isArrayEmpty(queryTermsRight)) {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'warn', life: 10000,
                summary: 'Empty search',
                detail: `Cannot run empty search. Please fill at least one input field.`
            });
            return;
        }

        this.toast?.current?.clear();
        this.setState({ searchInitiated: true, queryChanged: false });

        this.props.onItemSelectedClicked([...(queryTermsLeft || []), ...(queryTermsRight || [])]);
        // --- repository selection --- //
        let repositoryIDs = selectedRepositories && selectedRepositories.length > 0 ? selectedRepositories.map(repo => repo.id) : [];
        // --- blacklisting via blacklists or temporary concept exclusion --- //
        //const blacklistIDs = blacklists ? blacklists.map(blacklist => blacklist.id) : null;
        const blacklistIDs = blacklists;
        //console.log('blacklistIDs: ', blacklistIDs);
        const tempExcludedOcids = tempExcludedTerms && Object.keys(tempExcludedTerms).length > 0 ? Object.keys(tempExcludedTerms) : null;

        const startDateFormatted = startDate ? dateFormat(startDate, 'yyyy-mm-dd') : null;
        const endDateFormatted = endDate ? dateFormat(endDate, 'yyyy-mm-dd') : null;

        let queryTermsLabelLeft = '';
        let queryTermsLabelRight = '';

        let isDomainList = false;
        let domainList = [];
        let isDomainLeft = false;
        let resultTermListLeft = [];
        let resultTermListRight = [];
        const correlationPairs = [];
        const correlationPairsChart = [];
        let correlationPartnerLeft = {};
        let correlationPartnerRight = {};
        const selectedPairs = [];
        const selectedPairsKeys = {};
        const selectedConceptsOCIDs = {};

        this.setState({
            showResults: false,
            fetchingCorrelations: true,
            correlationPairs: [],
            sentencesOcidsLeft: [],
            sentencesOcidsRight: [],
            sentencesLabelLeft: '',
            sentencesLabelRight: '',
            sentences: {},
            claimsOccurrences: {}
        });

        const ocidsLeft = [];
        const domainsLeft = {};
        if (!isArrayEmpty(queryTermsLeft)) {
            //console.log('queryTermsLeft: ', queryTermsLeft);
            // --- create label for left query terms --- //
            queryTermsLabelLeft = this.createQueryTermsLabel(queryTermsLeft);
            // --- collect OCIDs of left query terms --- //
            queryTermsLeft.forEach(qTerm => {
                if (qTerm.ocids) {
                    ocidsLeft.push(...qTerm.ocids);
                }
                if (qTerm.domains) {
                    qTerm.domains.forEach(dom => {
                        domainsLeft[dom] = true;
                    });
                }
            });
        }
        else if (this.props.view.restrictions && this.props.view.restrictions.left &&
            !isArrayEmpty(this.props.view.restrictions.left.ocids)) {

            //ocidsLeft.push(...this.props.view.restrictions.left.ocids);
            //ocidsLeft.push(190000021540);
            //ocidsLeft.push(229960001138);

            // ocidsLeft.push(201000010099);
            // ocidsLeft.push(229940000407);
            // ocidsLeft.push(229930000044);
            //// ocidsLeft.push(151000000875);

            this.props.view.restrictions.left.ocids.forEach(ocid => {
                ocidsLeft.push(ocid);
            });
            this.props.view.restrictions.left.domains.forEach(dom => {
                domainsLeft[dom] = true;
            });
        }

        const ocidsRight = [];
        const domainsRight = {};
        if (!isArrayEmpty(queryTermsRight)) {
            //console.log('queryTermsRight: ', queryTermsRight);
            // --- create label for right query terms --- //
            queryTermsLabelRight = this.createQueryTermsLabel(queryTermsRight);
            // --- collect OCIDs of right query terms --- //
            queryTermsRight.forEach(qTerm => {
                if (qTerm.ocids) {
                    ocidsRight.push(...qTerm.ocids);
                }
                if (qTerm.domains) {
                    qTerm.domains.forEach(dom => {
                        domainsRight[dom] = true;
                    });
                }
            });
        }
        else if (this.props.view.restrictions && this.props.view.restrictions.right &&
            !isArrayEmpty(this.props.view.restrictions.right.ocids)) {

            this.props.view.restrictions.right.ocids.forEach(ocid => {
                ocidsRight.push(ocid);
            });
            this.props.view.restrictions.right.domains.forEach(dom => {
                domainsRight[dom] = true;
            });
        }

        // --- create query objects for left and right partners --- //
        const requestTermsLeft = createOCIDForCorrelationsRequest(ocidsLeft, false);
        const requestTermsRight = createOCIDForCorrelationsRequest(ocidsRight, false);

        // --- left and right term are defined --- //
        if (ocidsLeft.length > 0 && ocidsRight.length > 0) {

            //const request = createCorrelationMatrixRequest(null, [requestTermsLeft, requestTermsRight], this.props.maxNumOfPairs, false, tempExcludedOcids, startDate, endDate);
            const request = createCorrelationRequest(null, [requestTermsLeft, requestTermsRight], this.props.maxNumOfTerms, this.props.maxNumOfPairs, 0, true, true, tempExcludedOcids, startDateFormatted, endDateFormatted);
            const response = await fetchCorrelations(request, blacklistIDs, repositoryIDs);
            const result = checkResultAndGetPayload(response, this.growl);
            //console.log('result: ', result);

            if (result && result.coocPartnerList && result.coocPartnerList.length === 2) {
                correlationPartnerLeft = result.coocPartnerList[0];
                correlationPartnerRight = result.coocPartnerList[1];

                if (correlationPartnerLeft && correlationPartnerLeft.resultData && correlationPartnerLeft.resultData.resultTerms) {
                    correlationPartnerLeft.resultData.resultTerms.forEach(term => {
                        const domainLabel = this.props.domainLabelsMap && !!this.props.domainLabelsMap[term.domain] ? this.props.domainLabelsMap[term.domain] : term.domain;
                        term.label = Object.keys(domainsLeft).length > 1 ? `${term.name} (${domainLabel})` : term.name;
                        //console.log('term l: ', term);
                    });
                }
                if (correlationPartnerRight && correlationPartnerRight.resultData && correlationPartnerRight.resultData.resultTerms) {
                    correlationPartnerRight.resultData.resultTerms.forEach(term => {
                        const domainLabel = this.props.domainLabelsMap && !!this.props.domainLabelsMap[term.domain] ? this.props.domainLabelsMap[term.domain] : term.domain;
                        term.label = Object.keys(domainsRight).length > 1 ? `${term.name} (${domainLabel})` : term.name;
                        //console.log('term r: ', term);
                    });
                }

                // TODO: use boolean instead of constant from upper class
                if (this.props.view.id === QUERY_FORM_DISEASE_TARGETS.id) {
                    await this.enrichCorrelationPartnerData(correlationPartnerRight, true, true, false);
                }
                else if (this.props.view.id === QUERY_FORM_SUPPLIERS.id) {
                    await this.enrichCorrelationPartnerData(correlationPartnerRight, false, false, true);
                }
            }

            if (result && result.coocCountMatrix && result.coocCountMatrix.matrixValues &&
                result.coocCountMatrix.colTerms && result.coocCountMatrix.rowTerms) {
                //console.log('result.directCoocEntities: ', result.directCoocEntities);

                // --- update results --- //
                resultTermListLeft = result.coocCountMatrix.rowTerms;
                resultTermListRight = result.coocCountMatrix.colTerms;

                const matrix = result.coocCountMatrix.matrixValues;
                //const domainsLeft = {};
                //const domainsRight = {};
                //const termsCountLeft = {};
                //const termsCountRight = {};
                const coocCountLeft = {};
                const coocCountRight = {};
                let minValue = Number.MAX_VALUE;
                let maxValue = 0;

                for (let rowIndex = 0; rowIndex < matrix.length; rowIndex++) {

                    const coocPartnerLeft = resultTermListLeft[rowIndex];
                    /*
                    if (!termsCountLeft[coocPartnerLeft.name]) {
                        termsCountLeft[coocPartnerLeft.name] = 1;
                    }
                    else {
                        termsCountLeft[coocPartnerLeft.name]++;
                    }
                    */

                    for (let colIndex = 0; colIndex < matrix[rowIndex].length; colIndex++) {
                        //console.log('result.directCoocEntities: ', result.directCoocEntities);

                        const value = parseInt(matrix[rowIndex][colIndex]);
                        minValue = value < minValue ? value : minValue;
                        maxValue = value > maxValue ? value : maxValue;

                        const coocPartnerRight = resultTermListRight[colIndex];
                        /*
                        if (rowIndex === 0) {
                            console.log('cooc partner right: ', coocPartnerRight);
                            if (!termsCountRight[coocPartnerRight.name]) {
                                termsCountRight[coocPartnerRight.name] = 1;
                                console.log('first: ', coocPartnerRight);
                            }
                            else {
                                termsCountRight[coocPartnerRight.name]++;
                                console.log('other: ', coocPartnerRight);
                            }
                        }
                        */

                        coocCountLeft[coocPartnerLeft.ocid] = (!coocCountLeft[coocPartnerLeft.ocid]) ?
                            value : coocCountLeft[coocPartnerLeft.ocid] + value;
                        coocCountRight[coocPartnerRight.ocid] = (!coocCountRight[coocPartnerRight.ocid]) ?
                            value : coocCountRight[coocPartnerRight.ocid] + value;
                    }
                };

                // --- summarize number of coocs for each concept --- //
                for (let rowIndex = 0; rowIndex < matrix.length; rowIndex++) {

                    const coocPartnerLeft = resultTermListLeft[rowIndex];
                    //console.log('coocPartnerLeft: ', coocPartnerLeft);

                    for (let colIndex = 0; colIndex < matrix[rowIndex].length; colIndex++) {
                        //console.log('result.directCoocEntities: ', result.directCoocEntities);
                        const coocPartnerRight = resultTermListRight[colIndex];
                        const val = parseInt(matrix[rowIndex][colIndex]);
                        const normalizedValue = scale(val, minValue, maxValue, 6, 20);

                        if (val > 0) {
                            const isQueryTermLeft = ocidsLeft && ocidsLeft.includes(coocPartnerLeft.ocid);
                            const isQueryTermRight = ocidsRight && ocidsRight.includes(coocPartnerRight.ocid);

                            const domainLabelLeft = this.props.domainLabelsMap && !!this.props.domainLabelsMap[coocPartnerLeft.domain] ? this.props.domainLabelsMap[coocPartnerLeft.domain] : coocPartnerLeft.domain;
                            const domainLabelRight = this.props.domainLabelsMap && !!this.props.domainLabelsMap[coocPartnerRight.domain] ? this.props.domainLabelsMap[coocPartnerRight.domain] : coocPartnerRight.domain;

                            coocPartnerLeft.label = Object.keys(domainsLeft).length > 1 ? `${coocPartnerLeft.name} (${domainLabelLeft})` : coocPartnerLeft.name;
                            coocPartnerLeft.prefname = coocPartnerLeft.name;
                            coocPartnerRight.label = Object.keys(domainsRight).length > 1 ? `${coocPartnerRight.name} (${domainLabelRight})` : coocPartnerRight.name;
                            coocPartnerRight.prefname = coocPartnerRight.name;

                            const pair = {
                                key: `${coocPartnerLeft.ocid}:::${coocPartnerRight.ocid}`,
                                from: coocPartnerLeft.ocid,
                                to: coocPartnerRight.ocid,
                                fromLabel: coocPartnerLeft.label,
                                toLabel: coocPartnerRight.label + ' ',
                                fromPrefname: coocPartnerLeft.name,
                                toPrefname: coocPartnerRight.name,
                                fromDomain: coocPartnerLeft.domain,
                                toDomain: coocPartnerRight.domain,
                                value: val,
                                normValue: normalizedValue,
                                fromValue: coocCountLeft[coocPartnerLeft.ocid],
                                toValue: coocCountRight[coocPartnerRight.ocid],
                                fromValueLabel: shortenLargeNumber(coocCountLeft[coocPartnerLeft.ocid], 1),
                                toValueLabel: shortenLargeNumber(coocCountRight[coocPartnerRight.ocid], 1),
                                isQueryTerm: isQueryTermLeft || isQueryTermRight,
                                isQueryTermLeft: isQueryTermLeft,
                                isQueryTermRight: isQueryTermRight,
                                //fill: '#00ff00'
                            };

                            correlationPairs.push(pair);
                            // --- only first 20 or so???
                            correlationPairsChart.push(pair);

                            if (isQueryTermLeft && isQueryTermRight) {
                                selectedPairs.push(pair);
                                selectedPairsKeys[pair.key] = true;
                                selectedConceptsOCIDs[pair.from] = true;
                                selectedConceptsOCIDs[pair.to] = true;
                            }
                        }
                    }
                }

                // --- update sentences --- //
                this.onLoadSentences(ocidsLeft, ocidsRight, queryTermsLabelLeft, queryTermsLabelRight, false);
            }
        }
        // --- right partner is empty -> show partner domains --- //
        else if (ocidsRight.length === 0) {

            const request = createCorrelationTopTermsRequest(null, [requestTermsLeft], tempExcludedOcids, startDateFormatted, endDateFormatted);
            const response = await fetchCorrelations(request, blacklistIDs, repositoryIDs);
            const result = checkResultAndGetPayload(response, this.growl);

            if (result && result.coocPartnerList && result.coocPartnerList.length > 0) {
                isDomainList = true;
                isDomainLeft = false;

                domainList = result.coocPartnerList.map(domObject => {
                    const domID = domObject.resultData.resultTerms[0].domain;
                    const dom = this.props.domainsMap[domID];
                    return {
                        id: domID,
                        label: dom && dom.label ? dom.label : domID,
                        ocids: dom && dom.ocids ? dom.ocids : []
                    }
                });
                domainList = sortObjectArrayByAttribute(domainList, 'label');
            }
        }
        // --- left partner is empty -> show partner domains --- //
        else if (ocidsLeft.length === 0) {

            const request = createCorrelationTopTermsRequest(null, [requestTermsRight], tempExcludedOcids, startDateFormatted, endDateFormatted);
            const response = await fetchCorrelations(request, blacklistIDs, repositoryIDs);
            const result = checkResultAndGetPayload(response, this.growl);

            if (result && result.coocPartnerList && result.coocPartnerList.length > 0) {
                isDomainList = true;
                isDomainLeft = true;

                domainList = result.coocPartnerList.map(domObject => {
                    const domID = domObject.resultData.resultTerms[0].domain;
                    const dom = this.props.domainsMap[domID];
                    return {
                        id: domID,
                        label: dom && dom.label ? dom.label : domID,
                        ocids: dom && dom.ocids ? dom.ocids : []
                    }
                });
                domainList = sortObjectArrayByAttribute(domainList, 'label');
            }
        }

        //console.log('correlationPairsChart: ', correlationPairsChart);

        this.setState({
            isDomainList: isDomainList,
            isDomainLeft: isDomainLeft,
            domainList: domainList,

            showResults: true,
            fetchingCorrelations: false,

            resultTermListLeft: resultTermListLeft,
            resultTermListRight: resultTermListRight,
            queryTermsLabelLeft: queryTermsLabelLeft,
            queryTermsLabelRight: queryTermsLabelRight,

            correlationPairs: correlationPairs,
            correlationPairsChart: correlationPairsChart,
            correlationPartnerLeft: correlationPartnerLeft,
            correlationPartnerRight: correlationPartnerRight,

            selectedPairs: selectedPairs,
            selectedPairsKeys: selectedPairsKeys,
            selectedConceptsOCIDs: selectedConceptsOCIDs

            // --- sentences --- //
            //sentencesOcidsLeft: ocidsLeft,
            //sentencesOcidsRight: ocidsRight,
            //sentences: sentences
        }, async () => {
            //console.log('scroll to results');

            //await sleep(5000);

            // --- scroll to document result list minus offset --- //
            /*scrollToComponent(this.corrResRef, {
                align: 'top',
                offset: -120,
                duration: 500
            })*/
            window.scrollTo(0, this.corrResRef.current.offsetTop - 100)
        });
    }

    createQueryTermsLabel = (queryTerms) => {

        let queryTermsLabel = '';
        if (queryTerms) {
            queryTerms.forEach(qTerm => {
                let domLabel = '';
                if (this.props.domainLabelsMap && qTerm.domains) {
                    domLabel = ` (${qTerm.domains.map(domain => {
                        return !!this.props.domainLabelsMap[domain] ? this.props.domainLabelsMap[domain] : domain;
                    }).join(' or ')})`;
                }
                queryTermsLabel = !queryTermsLabel ? `${qTerm.term}${domLabel}` : `${queryTermsLabel} or ${qTerm.term}${domLabel}`;
            });
        }
        return queryTermsLabel;
    }

    enrichCorrelationPartnerData = async (correlationPartner, withClaimCount, withCTCount, withPatentCount) => {

        if (correlationPartner && correlationPartner.resultData && correlationPartner.resultData.resultTerms) {

            correlationPartner.hasClaimCount = withClaimCount;
            correlationPartner.hasCTCount = withCTCount;
            correlationPartner.hasPatentCount = withPatentCount;

            //correlationPartnerRight.queryData.domain = 

            const ocids = {};
            correlationPartner.resultData.resultTerms.forEach(term => {
                ocids[term.ocid] = 0;
            });

            const ctReq = { queries: {} };
            const patentReq = { queries: {} };

            const claimOcc = {};
            const ctOcc = {};
            const patentOcc = {};

            for (let ocid of Object.keys(ocids)) {
                // --- add query for patents for each OCID --- //
                if (withPatentCount && !this.props.patRepositoryInfo.unavailable) {
                    const query = `+ocid:${ocid} +smode:s`;
                    patentReq.queries[`patent:${ocid}`] = {
                        "query": query,
                        "repository": this.props.patRepositoryInfo.name
                    }
                }
                // --- add query for patent claims for each OCID --- //
                if (withClaimCount && !this.props.patRepositoryInfo.unavailable) {
                    const query = `+termloc:claims +ocid:${ocid} +smode:s`;
                    patentReq.queries[`claim:${ocid}`] = {
                        "query": query,
                        "repository": this.props.patRepositoryInfo.name
                    }
                }
                // --- add query for clinical trials for each OCID --- //
                if (withCTCount && !this.props.ctRepositoryInfo.unavailable) {
                    const query = `ocid:${ocid} +smode:s`;
                    ctReq.queries[ocid] = {
                        "query": query,
                        "repository": this.props.ctRepositoryInfo.name
                    }
                }
            }

            // --- fetch number of matching clinical trials for each OCID --- //
            if (!isArrayEmpty(Object.keys(ctReq.queries))) {
                const ctResp = await fetchDocumentsBulk(ctReq, [this.props.ctRepositoryInfo.id]);
                if (ctResp.status === 'SUCCESS') {
                    //console.log('ctResp: ', ctResp);
                    if (ctResp.payload && ctResp.payload[this.props.ctRepositoryInfo.id]) {
                        Object.entries(ctResp.payload[this.props.ctRepositoryInfo.id].results).forEach(([ocid, res]) => {
                            if (res && res.hitCount) {
                                ctOcc[ocid] = res.hitCount;
                            }
                        });
                    }
                }
            }

            // --- fetch number of matching patents or patent claims for each OCID --- //
            if (!isArrayEmpty(Object.keys(patentReq.queries))) {
                const patentResp = await fetchDocumentsBulk(patentReq, [this.props.patRepositoryInfo.id]);
                if (patentResp.status === 'SUCCESS') {
                    if (patentResp.payload && patentResp.payload[this.props.patRepositoryInfo.id]) {
                        Object.entries(patentResp.payload[this.props.patRepositoryInfo.id].results).forEach(([key, res]) => {
                            if (res && res.hitCount) {
                                if (key.startsWith('claim:')) {
                                    const ocid = key.replace('claim:', '');
                                    claimOcc[ocid] = res.hitCount;
                                }
                                else if (key.startsWith('patent:')) {
                                    const ocid = key.replace('patent:', '');
                                    patentOcc[ocid] = res.hitCount;
                                }
                            }
                        });
                    }
                }
            }

            //console.log('claimOcc: ', claimOcc);
            //console.log('patentOcc: ', patentOcc);
            //console.log('ctOcc: ', ctOcc);

            correlationPartner.resultData.resultTerms.forEach(term => {
                //console.log('term: ', term);
                if (withClaimCount) {
                    term.claimOccurrences = claimOcc[term.ocid];
                }
                if (withCTCount) {
                    term.ctOccurrences = ctOcc[term.ocid];
                }
                if (withPatentCount) {
                    term.patentOccurrences = patentOcc[term.ocid];
                }
            });
        }
    }

    prepareSentences = (exampleSentences) => {

        const sentencesBySentence = exampleSentences ? exampleSentences : [];

        let cntUnique = 0;
        sentencesBySentence.forEach(sentence => {
            cntUnique++;
            sentence.key = `sent_${cntUnique}`;
        });

        return {
            sentencesBySentence: sentencesBySentence,
            numberOfSentences: sentencesBySentence.length,
            countUnique: cntUnique
        };
    }

    onReplaceQueryTerms = (newQueryTerms, isLeft) => {

        //console.log('new query term: ', newQueryTerms);
        //console.log('isLeft: ', isLeft);

        if (isLeft === true) {
            this.setState({
                queryTermsLeft: newQueryTerms,
            }, async () => {
                await this.onSearchCorrelations();
            });
        }
        else if (isLeft === false) {
            this.setState({
                queryTermsRight: newQueryTerms,
            }, async () => {
                await this.onSearchCorrelations();
            });
        }
        //else 
        //error
    }

    onReplaceDomain = (domain, isLeft) => {

        const newQueryTerms = [];
        newQueryTerms.push({
            domains: [domain.id],
            ocids: domain.ocids,
            term: domain.label
        });

        //console.log('domainID: ', domainID);
        //console.log('isLeft: ', isLeft);
        //console.log('newQueryTerms: ', newQueryTerms);

        if (isLeft === true) {
            this.setState({
                queryTermsLeft: newQueryTerms,
            }, async () => {
                await this.onSearchCorrelations();
            });
        }
        else if (isLeft === false) {
            this.setState({
                queryTermsRight: newQueryTerms,
            }, async () => {
                await this.onSearchCorrelations();
            });
        }
        //else 
        //error
    }


    onLoadSentences = async (ocidsLeft, ocidsRight, labelLeft, labelRight, scrollToSentences = true) => {

        //console.log('onLoadSentences');
        //console.log('ocidsLeft: ', ocidsLeft);
        //console.log('ocidsRight: ', ocidsRight);
        //console.log('labelLeft: ', labelLeft);
        //console.log('labelRight: ', labelRight);

        const { selectedRepositories, startDate, endDate, blacklists, tempExcludedTerms, ontologically } = this.state;

        // --- repository selection --- //
        let repositoryIDs = selectedRepositories && selectedRepositories.length > 0 ? selectedRepositories.map(repo => repo.id) : [];
        // --- blacklisting via blacklists or temporary concept exclusion --- //
        //const blacklistIDs = blacklists ? blacklists.map(blacklist => blacklist.id) : null;
        const blacklistIDs = blacklists;
        const tempExcludedOcids = tempExcludedTerms && Object.keys(tempExcludedTerms).length > 0 ?
            Object.keys(tempExcludedTerms) : null;

        const startDateFormatted = startDate ? dateFormat(startDate, 'yyyy-mm-dd') : null;
        const endDateFormatted = endDate ? dateFormat(endDate, 'yyyy-mm-dd') : null;


        this.setState({
            fetchingSentences: true,
            sentencesOcidsLeft: ocidsLeft,
            sentencesOcidsRight: ocidsRight,
            sentencesLabelLeft: !!labelLeft ? labelLeft : this.state.queryTermsLabelLeft,
            sentencesLabelRight: !!labelRight ? labelRight : this.state.queryTermsLabelRight,
            sentences: {}
        });

        const requestTermsLeft = createOCIDForCorrelationsRequest(ocidsLeft, !ontologically);
        const requestTermsRight = createOCIDForCorrelationsRequest(ocidsRight, !ontologically);

        const request = createCorrelationSentencesRequest(null, [requestTermsLeft, requestTermsRight],
            this.props.maxNumOfTerms, this.props.maxNumOfSentences, tempExcludedOcids, startDateFormatted, endDateFormatted);
        const response = await fetchCorrelations(request, blacklistIDs, repositoryIDs);
        const result = checkResultAndGetPayload(response, this.growl);
        //console.log('correlations: ', result);

        if (result && result.groupedExampleSentences) {

            // --- get DOIs for sentences --- //
            const queries = {};
            result.groupedExampleSentences.forEach(sentence => {
                if (sentence.sources) {
                    Object.keys(sentence.sources).forEach(sourceRep => {
                        if (!queries[sourceRep]) {
                            queries[sourceRep] = '';
                        }
                        sentence.sources[sourceRep].forEach(docID => {
                            queries[sourceRep] += ` docid:"${docID}"`;
                        });
                    });
                }
            });
            //console.log('queries: ', queries);

            const dois = {};
            for (let queryRepID of Object.keys(queries)) {
                // --- request documents to get DOI --- //
                const request = createDocumentSearchRequest(queryRepID, queries[queryRepID], null);
                const resp = await fetchDocuments(0, 100, request);
                const resDocs = checkResultAndGetPayload(resp, this.growl);
                if (resDocs && resDocs.documents) {
                    for (let doc of resDocs.documents) {
                        if (!isArrayEmpty(doc.doi)) {
                            if (!dois[queryRepID]) {
                                dois[queryRepID] = {};
                            }
                            dois[queryRepID][doc.ocDocId] = doc.doi[0];
                        }
                    }
                }
            }

            // --- update sentences --- //
            const sentences = this.prepareSentences(result.groupedExampleSentences);

            this.setState({
                sentences: sentences,
                headerDocuments: this.getModifiedDocuments(sentences),
                doisMap: dois
            }, () => {
                // --- scroll to document result list minus offset --- //
                if (scrollToSentences) {
                    //console.log('scroll to sentences');
                    /*scrollToComponent(this.sentencesRef, {
                        align: 'top',
                        offset: -120,
                        duration: 500
                    })*/
                    window.scrollTo(0, this.sentencesRef.current.offsetTop)
                }
            });
        }

        this.setState({
            fetchingSentences: false,
        });
    }


    searchModeChanged = (ontologically) => {

        this.setState({
            ontologically: ontologically
        }, () => this.onLoadSentences(this.state.sentencesOcidsLeft, this.state.sentencesOcidsRight, this.state.sentencesLabelLeft, this.state.sentencesLabelRight));
    }

    onExcludeConcepts = (excludedConcepts) => {

        const tempExcludedTerms = { ...this.state.tempExcludedTerms, ...excludedConcepts };

        this.growl.current.show({
            severity: 'success', summary: `Concepts blocked`,
            detail: `Added ${Object.keys(excludedConcepts).length} ${Object.keys(excludedConcepts).length === 1 ? 'entry' : 'entries'} to blocked concepts (${Object.keys(tempExcludedTerms).length} overall).`
        });

        this.setState({
            tempExcludedTerms: tempExcludedTerms,
            queryChanged: true
        }, () => this.updateToast(),
            //() => this.onSearchCorrelations()
        );
    }

    onIncludeConcept = (ocid) => {

        const tempExcludedTerms = { ...this.state.tempExcludedTerms };
        delete tempExcludedTerms[ocid];

        this.setState({
            tempExcludedTerms: tempExcludedTerms,
            queryChanged: true
        }, () => this.updateToast(),
            //() => this.onSearchCorrelations()
        );
    }

    onClearExcludedConcepts = () => {

        this.setState({
            tempExcludedTerms: {},
            queryChanged: true
        }, () => this.updateToast(),
            //() => this.onSearchCorrelations()
        );
    }

    onSelectPair = (pair) => {

        const selectedPairs = [];
        const selectedPairsKeys = {};
        const selectedConceptsOCIDs = {};
        if (pair) {
            selectedPairs.push(pair);
            selectedPairsKeys[pair.key] = true;
            selectedConceptsOCIDs[pair.from] = true;
            selectedConceptsOCIDs[pair.to] = true;
        }

        //console.log('pair: ', pair);
        //console.log('selectedPairs: ', selectedPairs);
        //console.log('selectedPairsKeys: ', selectedPairsKeys);
        this.setState({
            //selectedPair: pair,
            selectedPairs: selectedPairs,
            selectedPairsKeys: selectedPairsKeys,
            selectedConceptsOCIDs: selectedConceptsOCIDs
        });
    }

    onSelectPairs = (pairs) => {

        const selectedPairs = [];
        const selectedPairsKeys = {};
        const selectedConceptsOCIDs = {};
        if (pairs) {
            pairs.forEach(pair => {
                selectedPairs.push(pair);
                selectedPairsKeys[pair.key] = true;
                selectedConceptsOCIDs[pair.from] = true;
                selectedConceptsOCIDs[pair.to] = true;
            });
        }

        //console.log('pairs: ', pairs);
        //console.log('selectedPairs: ', selectedPairs);
        //console.log('selectedPairsKeys: ', selectedPairsKeys);
        //console.log('selectedConceptsOCIDs: ', selectedConceptsOCIDs);
        this.setState({
            //selectedPair: pair,
            selectedPairs: selectedPairs,
            selectedPairsKeys: selectedPairsKeys,
            selectedConceptsOCIDs: selectedConceptsOCIDs
        });
    }

    onSelectConcept = (concept, isLeft, ocidsOther = []) => {

        //console.log('select concept: ', concept);
        //console.log('select isLeft: ', concept);
        //console.log('select ocidsOther: ', ocidsOther);

        const selectedPairs = [];
        this.state.correlationPairs.forEach(pair => {
            //console.log('pair: ', pair);
            if (isLeft && pair.from === concept.ocid && ocidsOther.includes(pair.to)) {
                selectedPairs.push(pair);
            }
            else if (!isLeft && pair.to === concept.ocid && ocidsOther.includes(pair.from)) {
                selectedPairs.push(pair);
            }
        });

        this.setState({
            selectedConcepts: [concept]
        });

        this.onSelectPairs(selectedPairs);
    }

    // ----------------------------------------------------------------------- //
    // --- search in documents ----------------------------------------------- //
    // ----------------------------------------------------------------------- //
    onSearchInDocuments = (queryTermsLeft, queryTermsRight) => {
        if (!isArrayEmpty(queryTermsLeft) || !isArrayEmpty(queryTermsRight)) {
            addDataToLocalStorage(QUERY_TYPE_QUICK_SEARCH_2.id,
                { data: createQuickSearchObjectFromCoocQueryTerms(queryTermsLeft, queryTermsRight) });
            window.open(`${APP_PROPERTIES.FRONTEND_URL}docfinder/quicksearch`, "_blank");
        }
    }

    // ----------------------------------------------------------------------- //
    // --- store cooc query -------------------------------------------------- //
    // ----------------------------------------------------------------------- //
    /**
     * 
     */
    onSaveQuery = async (editQuery) => {

        if (isArrayEmpty(this.state.queryTermsLeft) && isArrayEmpty(this.state.queryTermsRight)) {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'warn', life: 10000,
                summary: 'Empty search',
                detail: `Cannot save empty search. Please fill at least one input field.`
            });
        }
        else {
            this.setState({
                displaySaveQueryDialog: true,
                editQuery: editQuery,
                queryString: `${this.createQueryTermsLabel(this.state.queryTermsLeft)} with ${this.createQueryTermsLabel(this.state.queryTermsRight)}`
            });
        }
    }

    /**
     * 
     */
    onStoreQuery = async (queryString, queryName, queryFullName, queryDescription, watchlistIDs) => {

        //console.log('queryString: ', queryString);
        //console.log('queryName: ', queryName);
        //console.log('queryFullName: ', queryFullName);
        //console.log('queryDescription: ', queryDescription);
        //console.log('watchlistIDs: ', watchlistIDs);

        const formContent = {
            queryTermsLeft: this.state.queryTermsLeft,
            queryTermsRight: this.state.queryTermsRight,
            repositoryIDs: this.state.selectedRepositories ? this.state.selectedRepositories.map(repo => repo.id) : [],
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            tempExcludedTerms: this.state.tempExcludedTerms,
            //blacklistIDs: this.state.blacklists ? this.state.blacklists.map(bl => bl.id) : []
            blacklistIDs: this.state.blacklists ? this.state.blacklists : []
        };

        let response;
        let queryID;
        // --- existing query is updated --- //
        if (this.state.editQuery) {
            response = await updateQuery(this.state.savedSearchID,
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_COOC_SEARCH.id,
                QUERY_TYPE_COOC_SEARCH.id, this.props.view.id, formContent, watchlistIDs);
            queryID = this.state.savedSearchID;
        }
        // --- new query is stored --- //
        else {
            response = await storeQuery(
                queryString, null, queryName, queryFullName, queryDescription, QUERY_CATEGORY_COOC_SEARCH.id,
                QUERY_TYPE_COOC_SEARCH.id, this.props.view.id, formContent, watchlistIDs);
            queryID = response?.payload?.id;
        }

        checkResultAndGetPayload(response, this.growl, 'Success',
            `${this.state.editQuery ? 'Search successfully updated.' : 'Search successfully stored.'}`);

        if (response?.status === 'SUCCESS') {
            this.setState({
                savedSearchID: queryID,
                savedSearchName: queryFullName,
                displaySaveQueryDialog: false,
                editableQuery: true,
            });
        }
    }

    /**
     * 
     */
    onHideStoreQuery = () => {
        this.setState({
            displaySaveQueryDialog: false
        });
    }

    onNumOfRowsChangePairs = (first, rows) => {
        this.setState({
            rowsPairs: rows,
            firstPairs: first
        });
    }

    onNumOfRowsChangeLeftEntities = (first, rows) => {
        this.setState({
            rowsLeftEntities: rows,
            firstLeftEntities: first
        });
    }

    onNumOfRowsChangeRightEntities = (first, rows) => {
        this.setState({
            rowsRightEntities: rows,
            firstRightEntities: first
        });
    }


    updateToast() {

        //console.log('update toast');
        //console.log('this.state.searchInitiated: ', this.state.searchInitiated);
        //console.log('this.state.queryChanged: ', this.state.queryChanged);

        if (!this.state.searchInitiated || !this.state.queryChanged) {
            this.toast?.current?.clear();
            return;
        }

        //console.log('run search again');

        const content = <div className="width100perc" style={{ marginRight: 20 }}>
            {/* <div className="width100perc textAlignCenter">Query may have changed</div> */}
            <Button label="Run new search"
                className={`primaryButton p-button-sm valignMiddle width100perc`} // p-button-danger
                onClick={() => {
                    this.toast?.current?.clear();
                    this.onSearchCorrelations();
                }} />
        </div>

        this.toast.current.replace({
            closable: true,
            severity: '',
            sticky: true,
            content: content
        });
    }

    getModifiedDocuments = (docs) => {
        const temp = []
        let index = 0
        docs.sentencesBySentence.forEach(sentence => {
            Object.entries(sentence.sources).forEach(([key, value]) => {
                temp.push({
                    ocDocId: value[0],
                    repositoryId: key,
                    title: extractContent(sentence.htmlSentence),
                    number: ++index,
                    query: removeHtmlTagsFromText(sentence.htmlSentence)
                })
            }
            )
        })
        return temp
    }

    render() {

        //console.log('domainsEntity1: ', this.props.domainsEntity1);
        //console.log('blacklists: ', this.props.blacklists);
        //console.log('+++queryTermsRight: ', this.state.queryTermsRight);

        /*
        let numOfPairs = 0;
        if (this.state.correlationPairsChart) {
            numOfPairs = this.state.correlationPairsChart.length;
        }
        console.log('numOfPairs: ', numOfPairs);
        */

        return (
            <>
                <Toast ref={this.growl} />

                <Toast ref={this.toast} className="toast coocSearchToast"
                    position="bottom-center" closable="true"
                    style={{ zIndex: 3000, background: 'white', marginBottom: '90px !important' }} />
                {/* style={{ minWidth: 800 }} */}

                <LoadingOverlay
                    active={this.state.fetchingCorrelations || this.state.fetchingSentences}
                    spinner={true}
                    className="fullPage"
                    text="Fetching data..." >

                    <div className="grid correlations">
                        <div className={`col`}
                            style={{ padding: '0px 5px' }}> {/* `col${this.props.view === 'correlations' ? '' : '-12'}` */}
                            <CorrelationsInputField
                                placeholder={"Type search term"}
                                queryTerms={this.state.queryTermsLeft}
                                //domains={this.props.domainsEntity1}
                                domainLabelsMap={this.props.domainLabelsMap}
                                domainColors={this.props.domainColors}
                                availableDomains={this.props.availableDomains}
                                restrictions={this.props.restrictionsLeft}
                                handleInputChange={this.handleInputChangeLeft}
                            />
                        </div>
                        <div className="col-fixed textAlignCenter"
                            style={{ width: 80, padding: '0px 5px', alignSelf: 'self-end' }}>
                            <div style={{ paddingBottom: 15 }}>
                                <span className="material-icons">keyboard_arrow_left</span>
                                <span className="material-icons">keyboard_arrow_right</span>
                            </div>
                        </div>
                        <div className='col' style={{ padding: '0px 5px' }}>
                            <CorrelationsInputField
                                placeholder={"Type search term"}
                                queryTerms={this.state.queryTermsRight}
                                //domains={this.props.domainsEntity2}
                                domainLabelsMap={this.props.domainLabelsMap}
                                domainColors={this.props.domainColors}
                                availableDomains={this.props.availableDomains}
                                restrictions={this.props.restrictionsRight}
                                handleInputChange={this.handleInputChangeRight}
                            />
                        </div>
                        <div className="breakRow"></div>

                        <div ref={this.corrResRef} className="col">
                            <Element>
                                <span className="whiteSpaceNoWrap">
                                    <label htmlFor="repos" className="valignMiddle">Repositories:</label>
                                    <MultiSelect
                                        pt={{
                                            checkboxIcon: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    if (e.target.className.baseVal !== "") {
                                                        e.target.parentNode.click();
                                                    } else {
                                                        e.target.parentNode.parentNode.click();
                                                    }
                                                },
                                            },
                                            headerCheckbox: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    e.target.parentNode.click();
                                                },
                                            },
                                        }}
                                        id="repos"
                                        maxSelectedLabels={3}
                                        filter={true}
                                        value={this.state.selectedRepositories}
                                        options={this.state.repositories}
                                        optionLabel="label"
                                        onChange={(e) => {
                                            this.setState({
                                                queryChanged: true,
                                                selectedRepositories: e.value,
                                                selectedReposPlaceholder: e.value.length === 1 ? e.value[0].label : `${e.value.length} selected`
                                            }, () => this.updateToast());
                                        }}
                                        className="dropdownNoBorder increasedDropdownWidth valignMiddle"
                                        fixedPlaceholder={true}
                                        placeholder={this.state.selectedReposPlaceholder}
                                        style={{ width: 'auto', marginRight: 25 }} />
                                    {/*
                        <Dropdown value={this.state.selectedRepository}
                            options={this.state.repositories}
                            optionLabel="label"
                            onChange={(e) => this.setState({ selectedRepository: e.value })}
                            placeholder="Select repositories"
                            className="dropdownNoBorder"
                            style={{ width: 'auto', marginRight: 25 }}
                        />
                        */}
                                </span>
                                <span className="whiteSpaceNoWrap">
                                    <label htmlFor="insTimeFrom" className="valignMiddle">First detected: between</label>
                                    <Calendar
                                        id="insTimeFrom"
                                        className="noBorder valignMiddle"
                                        placeholder="YYYY-MM-DD"
                                        showIcon={false}
                                        dateFormat='yy-mm-dd'
                                        readOnlyInput={false}
                                        hideOnDateTimeSelect={true}
                                        showButtonBar={true}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange={getYearRangeWithStartYear(2020)}
                                        minDate={new Date("2020-12-01")}
                                        value={this.state.startDate}
                                        onChange={(e) => this.setState({
                                            startDate: e.value, queryChanged: true
                                        }, () => this.updateToast())}
                                        style={{ marginRight: 5, width: 95, textAlign: 'center' }}
                                    >
                                    </Calendar>
                                    <label htmlFor="insTimeTo" className="valignMiddle">and</label>
                                    <Calendar
                                        id="insTimeTo"
                                        className="noBorder valignMiddle"
                                        placeholder="YYYY-MM-DD"
                                        showIcon={false}
                                        dateFormat='yy-mm-dd'
                                        readOnlyInput={false}
                                        hideOnDateTimeSelect={true}
                                        showButtonBar={true}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange={getYearRangeWithStartYear(2020)}
                                        minDate={new Date("2020-12-01")}
                                        value={this.state.endDate}
                                        onChange={(e) => this.setState({
                                            endDate: e.value, queryChanged: true
                                        }, () => this.updateToast())}
                                        style={{ width: 95, marginRight: 5 }}
                                    >
                                    </Calendar>
                                    <a title="Click for information about detection date"
                                        className="infoIconLink valignMiddle"
                                        onClick={e => this.insertionDateInfoOverlay.toggle(e)}
                                        style={{ width: 'auto', marginRight: 25 }}>
                                        <img src={infoIcon} alt="Detection date information" />
                                    </a>
                                    <OverlayPanel
                                        ref={(el) => this.insertionDateInfoOverlay = el}
                                        style={{ maxWidth: '30vw', minWidth: 300 }}
                                        className="whiteSpaceBreakSpaces"
                                        dismissable={true}>
                                        You can filter correlations by the date they were first detected and stored in our system.
                                        <br /><br />
                                        E.g. if you would only like to see correlations that were added in the last month just set the
                                        first entry to the respective date and leave the second one empty.
                                    </OverlayPanel>
                                </span>
                                <span className="whiteSpaceNoWrap">
                                    {this.state.tempExcludedTerms && Object.keys(this.state.tempExcludedTerms).length > 0 ?
                                        <span>
                                            <a className="secondaryLink valignMiddle"
                                                title="Click to see blocked concepts"
                                                onClick={e => this.tempBlockedInfoOverlay.toggle(e)}>
                                                <span style={{ marginRight: 25 }}>Blocked concepts: {Object.keys(this.state.tempExcludedTerms).length}</span>
                                            </a>
                                            <OverlayPanel
                                                ref={(el) => this.tempBlockedInfoOverlay = el}
                                                dismissable={true}>
                                                <div>
                                                    {Object.keys(this.state.tempExcludedTerms).map(ocid => {
                                                        return <React.Fragment key={ocid}>
                                                            <p>
                                                                <a className="secondaryLink"
                                                                    //style={{ float: 'right' }}
                                                                    onClick={(e) => {
                                                                        this.tempBlockedInfoOverlay.hide(e);
                                                                        this.onIncludeConcept(ocid);
                                                                    }}>
                                                                    <span className="material-icons"
                                                                        style={{ fontSize: 20, marginRight: 10, position: 'relative', top: 5 }}>clear</span>
                                                                </a>
                                                                {this.state.tempExcludedTerms[ocid].label}</p>
                                                        </React.Fragment>
                                                    })}
                                                    <div style={{ float: 'left', margin: '10px 20px 20px 0px' }}>
                                                        <a onClick={(e) => this.onClearExcludedConcepts(e)}>Clear</a>
                                                    </div>
                                                    {hasUserRole('ROLE_BLACKLISTS') &&
                                                        <div style={{ float: 'right', margin: '10px 0px 20px 20px' }}>
                                                            <a onClick={() => this.props.onAddToBlacklists(this.state.tempExcludedTerms)}>Add to blocklist</a>
                                                        </div>
                                                    }
                                                </div>
                                            </OverlayPanel>
                                        </span> : null
                                    }
                                    {hasUserRole('ROLE_BLACKLISTS') &&
                                        <span>
                                            <label htmlFor="blocklists" className="valignMiddle" style={{ marginRight: 5 }}>Blocklists:</label>
                                            {this.props.blacklists && this.props.blacklists.length > 0 ?
                                                <MultiSelect
                                                    pt={{
                                                        checkboxIcon: {
                                                            onClick: (e) => {
                                                                e.stopPropagation();
                                                                if (e.target.className.baseVal !== "") {
                                                                    e.target.parentNode.click();
                                                                } else {
                                                                    e.target.parentNode.parentNode.click();
                                                                }
                                                            },
                                                        },
                                                        headerCheckbox: {
                                                            onClick: (e) => {
                                                                e.stopPropagation();
                                                                e.target.parentNode.click();
                                                            },
                                                        },
                                                    }}
                                                    maxSelectedLabels={3}
                                                    id="blocklists"
                                                    filter={true}
                                                    optionLabel="title"
                                                    optionValue="id"
                                                    value={this.state.blacklists}
                                                    options={this.props.blacklists}
                                                    onChange={(e) => this.setState({
                                                        blacklists: e.value, queryChanged: true
                                                    }, () => this.updateToast())}
                                                    placeholder="Select blocklists"
                                                    className="dropdownNoBorder increasedDropdownWidth valignMiddle"
                                                    style={{ width: 'auto' }}
                                                />
                                                :
                                                null
                                            }
                                            <Link to="/my/blocklists" target="_blank" title='Open My Blocklists in new tab'
                                                style={{ marginRight: 5, verticalAlign: '-moz-middle-with-baseline' }}>
                                                Manage <span className="extLinkIcon">&#8599;</span>
                                            </Link>
                                            {/* 
                                        <Button
                                            icon='pi pi-external-link'
                                            iconPos='right'
                                            onClick={(e) => window.open(`/my/blocklists`, "_blank")}
                                            label='Manage'
                                            className='p-button-text valignMiddle'
                                            title='Open My Blocklists in new tab'
                                            style={{ marginRight: 5, color: '#0973c0' }}
                                            >
                                        </Button>
                                       */}
                                            <a title="Click for information about blocking concepts"
                                                className="infoIconLink valignMiddle"
                                                onClick={e => this.blockingInfoOverlay.toggle(e)}
                                                style={{ width: 'auto', marginLeft: 5, marginRight: 25 }}>
                                                <img src={infoIcon} alt="Blocking information" />
                                            </a>
                                            <OverlayPanel
                                                ref={(el) => this.blockingInfoOverlay = el}
                                                style={{ maxWidth: '30vw', minWidth: 300 }}
                                                className="whiteSpaceBreakSpaces"
                                                dismissable={true}>
                                                You can select concepts from the graph, table or lists and block them, i.e. you will not see any
                                                correlations containing these concepts.
                                                <br /><br />
                                                You can also add existing blocklists to your search or add your currently blocked or selected
                                                concepts to a blocklist.
                                                { /*
                                    <br /><br />
                                        <Link to="/my/blocklists" target="_blank">
                                            Click here to manage and create blocklists
                                        </Link>
                                   */}
                                            </OverlayPanel>
                                        </span>
                                    }
                                </span>
                            </Element>
                        </div>
                        <div className="col-fixed textAlignRight" style={{ paddingTop: 15 }}>
                            <Button
                                className="p-button-text primaryButtonAsLink valignMiddle"
                                //style={{ marginRight: 30 }}
                                title="Reset page"
                                onClick={() => this.setState({ displayConfirmationDialog: true })}>
                                Clear all
                            </Button>
                            {!isArrayEmpty(this.state.queryTermsLeft) || !isArrayEmpty(this.state.queryTermsRight) ?
                                <>
                                    <SeparatorPoint />
                                    <SaveSearchButtons
                                        savedSearchExists={!!this.state.savedSearchID}
                                        editableQuery={this.state.editableQuery}
                                        onSaveSearch={this.onSaveQuery}
                                        savedSearchName={this.state.savedSearchName}
                                    />

                                    <SeparatorPoint />
                                    <Button
                                        className="p-button-text primaryButtonAsLink valignMiddle"
                                        title="Open document search in new tab"
                                        onClick={() => this.onSearchInDocuments(this.state.queryTermsLeft, this.state.queryTermsRight)}>
                                        Search in documents
                                    </Button>
                                </> : null
                            }
                            <Button label="Search"
                                className={`primaryButton p-button-sm valignMiddle`}
                                style={{ marginLeft: 30 }}
                                onClick={this.onSearchCorrelations} />
                        </div>
                        <div className="breakRow"></div>

                        {this.state.showResults ?
                            this.state.isDomainList ?
                                <div style={{
                                    float: 'left',
                                    textAlign: 'left',
                                    padding: 20
                                }}>
                                    <h4>Correlations found in the following domains. Select one to specify your search:</h4>
                                    <div style={{
                                        padding: 10
                                    }}>
                                        {this.state.domainList.map(resultDomain => {
                                            return <p key={resultDomain.id}>
                                                <a onClick={() => this.onReplaceDomain(resultDomain, this.state.isDomainLeft)}>
                                                    {resultDomain.label}
                                                </a>
                                            </p>
                                        })}
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="col-12 md:col-12 lg:col-12 xl:col-8 textAlignCenter" style={{ paddingRight: 10 }}>
                                        <div className="tabViewRightToolbar">
                                            {/*<a>Export</a>*/}
                                        </div>
                                        <TabView
                                            activeIndex={this.state.activeIndex}
                                            onTabChange={(e) => {
                                                /*
                                                console.log('e.index: ', e.index);
                                                console.log('this.state.activeIndex: ', this.state.activeIndex);
                                                let sentences = this.state.sentences;
                                                if (!(e.index === 0 && this.state.activeIndex === 1 ||
                                                    e.index === 1 && this.state.activeIndex === 0)) {
                                                    sentences = [];
                                                }
                                                */
                                                this.setState({
                                                    activeIndex: e.index,
                                                    //sentences: sentences
                                                });
                                            }}
                                            renderActiveOnly={true}
                                            style={{ width: '100%' }}>

                                            <TabPanel header="Top pairs graph">
                                                <div style={{ height: 'calc(100vh - 220px)', marginBottom: 30 }}>
                                                    {this.state.correlationPairsChart && this.state.correlationPairsChart.length === 0 ?
                                                        <div style={{ margin: 10 }}>No correlations found.</div>
                                                        : null
                                                    }
                                                    <CorrelationsChart
                                                        view={this.props.view}
                                                        queryTermsLeft={this.state.queryTermsLeft}
                                                        queryTermsRight={this.state.queryTermsRight}
                                                        resultTermListLeft={this.state.resultTermListLeft}
                                                        resultTermListRight={this.state.resultTermListRight}
                                                        correlationPairs={this.state.correlationPairsChart}
                                                        onAddToBlacklists={this.props.onAddToBlacklists}
                                                        onExcludeConcepts={this.onExcludeConcepts}
                                                        tempExcludedTerms={this.state.tempExcludedTerms}
                                                        onLoadSentences={this.onLoadSentences}
                                                        onReplaceQueryTerms={this.onReplaceQueryTerms}

                                                        //selectedPair={this.state.selectedPair}
                                                        //onSelectPair={this.onSelectPair}
                                                        selectedPairs={this.state.selectedPairs}
                                                        selectedPairsKeys={this.state.selectedPairsKeys}
                                                        onSelectPairs={this.onSelectPairs}
                                                        selectedConceptsOCIDs={this.state.selectedConceptsOCIDs}

                                                        queryTermsLabelLeft={this.state.queryTermsLabelLeft}
                                                        queryTermsLabelRight={this.state.queryTermsLabelRight}
                                                    />
                                                </div>
                                            </TabPanel>

                                            <TabPanel header="Top pairs table">
                                                <CorrelationsTable
                                                    view={this.props.view}
                                                    rows={this.state.rowsPairs}
                                                    first={this.state.firstPairs}
                                                    onNumOfRowsChange={this.onNumOfRowsChangePairs}

                                                    queryTermsLeft={this.state.queryTermsLeft}
                                                    queryTermsRight={this.state.queryTermsRight}
                                                    resultTermListLeft={this.state.resultTermListLeft}
                                                    resultTermListRight={this.state.resultTermListRight}
                                                    correlationPairs={this.state.correlationPairs}
                                                    ocidDataMap={this.state.ocidDataMap}
                                                    onAddToBlacklists={this.props.onAddToBlacklists}
                                                    onExcludeConcepts={this.onExcludeConcepts}
                                                    tempExcludedTerms={this.state.tempExcludedTerms}
                                                    onLoadSentences={this.onLoadSentences}
                                                    onReplaceQueryTerms={this.onReplaceQueryTerms}

                                                    //selectedPair={this.state.selectedPair}
                                                    //onSelectPair={this.onSelectPair}
                                                    selectedPairs={this.state.selectedPairs}
                                                    selectedPairsKeys={this.state.selectedPairsKeys}
                                                    onSelectPairs={this.onSelectPairs}
                                                    onSelectConcept={this.onSelectConcept}

                                                    queryTermsLabelLeft={this.state.queryTermsLabelLeft}
                                                    queryTermsLabelRight={this.state.queryTermsLabelRight}
                                                />
                                            </TabPanel>

                                            <TabPanel header={this.props.view.headerLabelTopTermsLeft}>
                                                <CorrelationsList
                                                    view={this.props.view}
                                                    rows={this.state.rowsLeftEntities}
                                                    first={this.state.firstLeftEntities}
                                                    onNumOfRowsChange={this.onNumOfRowsChangeLeftEntities}
                                                    // TODO: remove???
                                                    queryTermsLeft={this.state.queryTermsLeft}
                                                    queryTermsRight={this.state.queryTermsRight}
                                                    resultTermListLeft={this.state.resultTermListLeft}
                                                    resultTermListRight={this.state.resultTermListRight}
                                                    ocidDataMap={this.state.ocidDataMap}
                                                    onAddToBlacklists={this.props.onAddToBlacklists}
                                                    onExcludeConcepts={this.onExcludeConcepts}
                                                    tempExcludedTerms={this.state.tempExcludedTerms}
                                                    onLoadSentences={this.onLoadSentences}
                                                    onReplaceQueryTerms={this.onReplaceQueryTerms}

                                                    selectedPairs={this.state.selectedPairs}
                                                    selectedPairsKeys={this.state.selectedPairsKeys}
                                                    onSelectPairs={this.onSelectPairs}
                                                    onSelectConcept={this.onSelectConcept}
                                                    selectedConceptsOCIDs={this.state.selectedConceptsOCIDs}

                                                    isLeft={true}
                                                    correlationPartner={this.state.correlationPartnerLeft}
                                                    queryTerms={this.state.queryTermsLeft}
                                                    queryTermsOtherPartner={this.state.queryTermsRight}
                                                    queryTermsLabel={this.state.queryTermsLabelLeft}
                                                    queryTermsLabelOtherPartner={this.state.queryTermsLabelRight}

                                                />
                                            </TabPanel>

                                            <TabPanel header={this.props.view.headerLabelTopTermsRight}>
                                                <CorrelationsList
                                                    view={this.props.view}
                                                    rows={this.state.rowsRightEntities}
                                                    first={this.state.firstRightEntities}
                                                    onNumOfRowsChange={this.onNumOfRowsChangeRightEntities}
                                                    // TODO: remove???
                                                    queryTermsLeft={this.state.queryTermsLeft}
                                                    queryTermsRight={this.state.queryTermsRight}
                                                    resultTermListLeft={this.state.resultTermListLeft}
                                                    resultTermListRight={this.state.resultTermListRight}
                                                    ocidDataMap={this.state.ocidDataMap}
                                                    onAddToBlacklists={this.props.onAddToBlacklists}
                                                    onExcludeConcepts={this.onExcludeConcepts}
                                                    tempExcludedTerms={this.state.tempExcludedTerms}
                                                    onLoadSentences={this.onLoadSentences}
                                                    onReplaceQueryTerms={this.onReplaceQueryTerms}

                                                    selectedPairs={this.state.selectedPairs}
                                                    selectedPairsKeys={this.state.selectedPairsKeys}
                                                    onSelectPairs={this.onSelectPairs}
                                                    onSelectConcept={this.onSelectConcept}
                                                    selectedConceptsOCIDs={this.state.selectedConceptsOCIDs}

                                                    isLeft={false}
                                                    correlationPartner={this.state.correlationPartnerRight}
                                                    queryTerms={this.state.queryTermsRight}
                                                    queryTermsOtherPartner={this.state.queryTermsLeft}
                                                    queryTermsLabel={this.state.queryTermsLabelRight}
                                                    queryTermsLabelOtherPartner={this.state.queryTermsLabelLeft}
                                                />
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                    <div className="col-12 md:col-12 lg:col-12 xl:col-4" style={{ paddingLeft: 10 }}>
                                        <Element ref={this.sentencesRef}>
                                            {/*
                                        <div className="tabViewRightToolbar">
                                            Document search
                                        </div>
                                       */}
                                            <TabView
                                                //activeIndex={this.state.activeIndex}
                                                //onTabChange={(e) => this.setState({ activeIndex: e.index })}
                                                renderActiveOnly={false}
                                            //style={{ width: '100%' }}
                                            >
                                                {/* ${this.state.sentences ? ` (${this.state.sentences.countAll} | ${this.state.sentences.countUnique} unique)` : ''} */}
                                                <TabPanel header={`Results`}>
                                                    <div style={{ marginTop: 0, marginBottom: 15 }}>
                                                        <span title="Include descendants of search concepts">
                                                            <Checkbox
                                                                style={{ marginRight: 5, top: 2, position: 'relative' }}
                                                                onChange={e => this.searchModeChanged(e.checked)}
                                                                checked={this.state.ontologically}>
                                                            </Checkbox>
                                                            <a className="secondaryLink">Incl. descendants</a>
                                                        </span>
                                                        <Checkbox
                                                            style={{ marginLeft: 10, marginRight: 5, top: 2, position: 'relative' }}
                                                            onChange={e => this.setState({ showSources: e.checked })}
                                                            checked={this.state.showSources}>
                                                        </Checkbox>
                                                        <a style={{ marginRight: 10 }} className="secondaryLink">Show sources</a>
                                                        <a title="Click for information about sentences"
                                                            onClick={e => this.sentencesInfoOverlay.toggle(e)}
                                                        //onMouseOver={e => this.sentencesInfoOverlay.show(e)}
                                                        //onMouseOut={e => this.sentencesInfoOverlay.hide(e)}
                                                        >
                                                            {/*<span className="pi pi-info-circle"></span>*/}
                                                            <img src={infoIcon} alt="Sentences information"
                                                                style={{ position: 'relative', top: 5 }} />
                                                        </a>
                                                        <OverlayPanel
                                                            //className="resetOverlay"
                                                            style={{ maxWidth: '30vw', minWidth: 300 }}
                                                            className="whiteSpaceBreakSpaces removeArrow"
                                                            ref={(el) => this.sentencesInfoOverlay = el}
                                                            dismissable={true}>
                                                            By default this list of sentences only contains direct occurrences of the entered or selected concepts.
                                                            If you also want to see sentences matching descendants of search concepts just check the "Incl. descendants" checkbox.
                                                            <br /><br />
                                                            You can also click on a specific pair of concepts to load the respective sentences or
                                                            click on one of the left or right concepts and choose "Load sentences" to see the ones containing this
                                                            concept together with the entered search partner.
                                                        </OverlayPanel>
                                                    </div>
                                                    <div style={{ height: 'calc(100vh - 230px)', overflow: 'auto' }}>
                                                        {!!this.state.sentencesLabelLeft && !!this.state.sentencesLabelRight ?
                                                            <div>
                                                                <b>{`${this.state.sentencesLabelLeft} with ${this.state.sentencesLabelRight}`}</b>
                                                                <br />
                                                                <div className="secondaryInfo">{`${this.state.ontologically ? 'all matches, including descendants' : 'direct matches, without descendants'}`}</div>
                                                            </div> : null
                                                        }
                                                        <CorrelationsSentences
                                                            sentences={this.state.sentences}
                                                            repositoryLabelsMap={this.state.repositoryLabelsMap}
                                                            showSources={this.state.showSources}
                                                            doisMap={this.state.doisMap}
                                                            onShowDocviewModal={this.onShowDocviewModal}
                                                        //groupBySource={this.state.groupBySource}
                                                        />
                                                    </div>
                                                </TabPanel>
                                            </TabView>
                                        </Element>
                                    </div>
                                </> : null
                        }

                        <ConfirmationDialog
                            displayDialog={this.state.displayConfirmationDialog}
                            onHide={() => this.setState({ displayConfirmationDialog: false })}
                            onSubmit={() => {
                                this.resetForm();
                                this.setState({ searchInitiated: false, displayConfirmationDialog: false });
                            }}
                            headerText="Confirm"
                            messageText="Clear all input?"
                            submitButtonLabel={"Clear"}
                        />

                        <SaveSearchDialog
                            displayDialogStoreQuery={this.state.displaySaveQueryDialog}
                            editQuery={this.state.editQuery}
                            queryString={this.state.queryString}
                            savedSearchID={this.state.savedSearchID}
                            allowAlerts={false}
                            onSubmit={this.onStoreQuery}
                            onHide={this.onHideStoreQuery}
                            userData={this.props.userData}
                        />

                        <DocViewModal
                            isVisible={this.state.isDocviewModalVisible && this.state.sentences}
                            docId={this.state.ocDocId}
                            repId={this.state.repositoryId}
                            selectedDoc={this.state.selectedDocument}
                            onCloseDocviewModal={this.onCloseDocviewModal}
                            documents={{ '1': this.state.headerDocuments }}
                            onPageChange={() => { }}
                            documentCount={this.state.headerDocuments.length}
                        />

                    </div>

                </LoadingOverlay>
            </>
        );
    }
}



const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => {
    return {
        onItemSelectedClicked: (value) => {
            dispatch(setQuickSearchSelectedTerms({ newTerms: [value?.filter((item) => item.domains)] }))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CorrelationsView)) 
