import React, { Component } from 'react';
import Cartridge from './Cartridge';
import { sortObjectArrayByAttribute } from '../../util';


/**
 * visualise a set of cartridges, in a table
 */
class Cartridges extends Component {

  /**
   * render the component -> display a table of cartridges
   */
  render() {
    let cartridges = this.props.cartridges.concat();
    cartridges = sortObjectArrayByAttribute(cartridges, 'name', true, true);
    return (
      <>
        <div className="cartridgeTable">
          <div className="grid">
            <div className="col"><strong>Identifier</strong></div>
            <div className="col"><strong>Label</strong></div>
            <div className="col-fixed" style={{ width: 120 }}><strong>Highlighting</strong></div>
            <div className="col-fixed" style={{ width: 100 }}><strong>Type</strong></div>
            <div className="col-fixed" style={{ width: 190 }}><strong>Actions</strong></div>
          </div>
          {
            cartridges.filter(cartridge => cartridge.type === 'dictionary').map((cartridge) => {
              const domain = this.props.domainsMap ? this.props.domainsMap[cartridge.name] : null;
              return <Cartridge
                key={`${cartridge.name}_${cartridge.state}_${cartridge.uri}`}
                name={cartridge.name}
                type={cartridge.type}
                uri={cartridge.uri}
                size={cartridge.packageSize}
                state={cartridge.state}
                showWarningModal={this.props.showWarningModal}
                domainID={domain?.id}
                label={domain?.label}
                color={domain?.color}
                description={domain?.description}
                domain={domain}
              />
            })
          }
          {
            cartridges.filter(cartridge => cartridge.type !== 'dictionary').map((cartridge) => {
              const domain = this.props.domainsMap ? this.props.domainsMap[cartridge.name] : null;
              return <Cartridge
                key={`${cartridge.name}_${cartridge.state}_${cartridge.uri}`}
                name={cartridge.name}
                type={cartridge.type}
                uri={cartridge.uri}
                size={cartridge.packageSize}
                state={cartridge.state}
                showWarningModal={this.props.showWarningModal}
                domainID={domain?.id}
                label={domain?.label}
                color={domain?.color}
                description={domain?.description}
                domain={domain}
              />
            })
          }
        </div>
      </>
    );
  }
}
export default Cartridges;
