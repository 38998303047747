import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { hasUserRole } from '../../components/webapi/util';

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userForename: '',
            userRole: '',
            userActive: false,
            userEmail: '',
            userUsername: '',
            userPassword: '',
            userPasswordRepeated: '',
            userForceUpdatePassword: false,
            type: 'password',
            typeRepeated: 'password'
        }
    }

    componentDidMount() {
        let userName = this.props.selectedUserName
        let userForename = this.props.selectedUserForename
        let userRole = this.props.selectedUserRole === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") && this.props.selectedUserUsername === this.props.userDetails.username ? 'ROLE_SUPPORT_ADMIN' : this.props.selectedUserRole
        let userActive = this.props.selectedUserActive === false ? true : false
        let userEmail = this.props.selectedUserEmail
        let userUsername = this.props.selectedUserUsername

        this.setState({
            userName: userName,
            userForename: userForename,
            userRole: userRole,
            userActive: userActive,
            userEmail: userEmail,
            userUsername: userUsername
        })
    }

    handleUserRoleChange = (e) => {
        this.setState({
            userRole: e.value
        })
    }

    onCheckForceUpdatePassword = (e) => {
        if (e.checked) {
            this.setState({
                userForceUpdatePassword: true
            })
        } else {
            this.setState({
                userForceUpdatePassword: false
            })
        }
    }

    handleShowHide = () => {
        if (this.state.type === 'input') {
            this.setState({
                type: 'password'
            })
        } else {
            this.setState({
                type: 'input'
            })
        }
    }

    handleShowHideRepeated = () => {
        if (this.state.typeRepeated === 'input') {
            this.setState({
                typeRepeated: 'password'
            })
        } else {
            this.setState({
                typeRepeated: 'input'
            })
        }
    }

    changeUserEmail = (email) => {
        this.setState({
            userEmail: email.replace(/\s/g, "")
        })
    }

    onEditUser = () => {
        this.props.onEditUser(this.state.userRole, this.props.selectedCompany, this.state.userActive, this.props.selectedDepartment, this.state.userEmail,
            this.state.userForename, this.state.userName, this.state.userPassword, this.state.userPasswordRepeated, this.state.userUsername, this.state.userForceUpdatePassword)
    }

    render() {

        let userRoles = [
            {
                label: 'Super admin', value: 'ROLE_SUPER_ADMIN'
            },
            {
                label: 'Support admin', value: 'ROLE_SUPPORT_ADMIN'
            },
            {
                label: 'Organization admin', value: 'ROLE_COMPANY_ADMIN'
            },
            {
                label: 'Suborganization admin', value: 'ROLE_DEPARTMENT_ADMIN'
            },
            {
                label: 'User', value: 'NONE'
            }
        ]

        userRoles = this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' && this.props.selectedUserUsername === this.props.userDetails.username ? userRoles.slice(2) :
            this.props.userDetails.highestAdminType === 'ROLE_COMPANY_ADMIN' && this.props.selectedUserUsername !== this.props.userDetails.username ? userRoles.slice(3) :
                this.props.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && this.props.selectedUserUsername === this.props.userDetails.username ? userRoles.slice(3) :
                    this.props.userDetails.highestAdminType === 'ROLE_DEPARTMENT_ADMIN' && this.props.selectedUserUsername !== this.props.userDetails.username ? userRoles.slice(4) :
                        this.props.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && hasUserRole("ROLE_SUPPORT_ADMIN") ? userRoles.slice(1) :
                            userRoles

        return (
            <Dialog visible={this.props.displayEditUser} style={{ 'width': "80vw" }} header={this.props.selectedUserSAML ? 'User details (SSO)' : 'User details'} focusOnShow={false}
                modal={true}
                dismissableMask={false}
                onHide={() => this.props.onCancelEditUser()} className='styledDialog'>
                <div className="grid p-fluid" style={{ borderBottom: '1px solid #d6d6d6', paddingLeft: 25, paddingRight: 0, paddingBottom: 15, paddingTop: 20, marginRight: 0 }}>
                    <div className="col-12 grid p-fluid">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="departmentName">Last name</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userName"
                                disabled={this.props.selectedUserSAML ? true : false}
                                onChange={(e) => { this.setState({ userName: e.target.value }) }}
                                value={this.state.userName} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label htmlFor="departmentName">First name</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userForeName"
                                disabled={this.props.selectedUserSAML ? true : false} onChange={(e) => { this.setState({ userForename: e.target.value }) }} value={this.state.userForename} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em' }}>
                            <label htmlFor="companyName">Username (cannot be changed)</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userUserName" disabled={true}
                                onChange={(e) => { this.setState({ userUsername: e.target.value }) }} value={this.state.userUsername} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em' }}>
                            <label htmlFor="companyName">EMail</label>
                            <InputText style={{ marginTop: 5, padding: 5, border: '1px solid #d6d6d6' }} id="userEmail"
                                disabled={this.props.selectedUserSAML ? true : false} onChange={(e) => { this.changeUserEmail(e.target.value) }} value={this.state.userEmail} />
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>User role</label>
                            <Dropdown style={{ width: '100%', border: '1px solid #d6d6d6', marginTop: 5 }}
                                className='dropdownNoBorder'
                                appendTo={document.body}
                                value={this.state.userRole}
                                options={userRoles}
                                onChange={(e) => this.handleUserRoleChange(e)}
                                placeholder='Select user role'>
                            </Dropdown>
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', paddingBottom: 15 }}>
                            <label>Status</label>
                            <div>
                                <InputSwitch style={{
                                    marginRight: 10, marginTop: 10
                                }} checked={this.state.userActive} onChange={(e) => this.setState({ userActive: e.value })} />
                                <label>{this.state.userActive ? 'Active' : 'Deactivated'}</label>
                            </div>
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', marginTop: -10 }}>
                            <label htmlFor="companyName">New password</label>
                            <Button icon='pi pi-info-circle'
                                style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none', paddingBottom: 7, paddingTop: 5 }}
                                title='New password needs to contain at least 10 characters with one uppercase character and with one number.'>
                            </Button>
                            <div>
                                <InputText style={{ padding: 5, border: '1px solid #d6d6d6', width: '89%' }} id="userPassword"
                                    type={this.state.type === 'input' ? 'input' : 'password'} disabled={this.props.selectedUserSAML ? true : false}
                                    onChange={(e) => { this.setState({ userPassword: e.target.value }) }} value={this.state.userPassword} />
                                <Button
                                    icon={this.state.type === 'input' ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                    onClick={this.handleShowHide}
                                    style={{ marginBottom: -5, backgroundColor: 'white', color: 'black', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }} >
                                </Button>
                            </div>
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em', marginTop: -10 }}>
                            <label htmlFor="companyName">Repeat new password</label>
                            <Button icon='pi pi-info-circle'
                                style={{ backgroundColor: 'white', color: 'black', cursor: 'default', verticalAlign: 'middle', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none', paddingBottom: 7, paddingTop: 5 }}
                                title='New password needs to contain at least 10 characters with one uppercase character and with one number.'>
                            </Button>
                            <div>
                                <InputText style={{ padding: 5, border: '1px solid #d6d6d6', width: '89%' }} id="userPasswordRepeated"
                                    type={this.state.typeRepeated === 'input' ? 'input' : 'password'} disabled={this.props.selectedUserSAML ? true : false}
                                    onChange={(e) => { this.setState({ userPasswordRepeated: e.target.value }) }} value={this.state.userPasswordRepeated} />
                                <Button
                                    icon={this.state.typeRepeated === 'input' ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                    onClick={this.handleShowHideRepeated}
                                    style={{ marginBottom: -5, backgroundColor: 'white', color: 'black', boxShadow: 'none', WebkitBoxShadow: 'none', border: 'none' }} >
                                </Button>
                            </div>
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4" style={{ padding: '.5em' }}>
                            <label>Force user to change password</label>
                            <div>
                                <Checkbox inputId='userPassword'
                                    onChange={(e) => this.onCheckForceUpdatePassword(e)}
                                    checked={this.state.userForceUpdatePassword}
                                    disabled={this.props.selectedUserSAML ? true : false}
                                    value='Force user to change password' style={{ 'cursor': this.props.selectedUserSAML ? 'default' : 'pointer', marginTop: 10, marginLeft: -2 }}
                                />
                                <label style={{ paddingLeft: 10 }}>Force password change</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                    <Button style={{ float: 'left', marginLeft: 18, height: '27.19px' }} label="Delete user"
                        className='p-button-sm'
                        onClick={this.props.onDeleteUser} />
                    <Button label="Save"
                        className='p-button-sm primaryButton'
                        style={{ float: 'right' }}
                        onClick={this.onEditUser} />
                    <Button label="Cancel"
                        className='p-button-secondary p-button-sm'
                        onClick={this.props.onCancelEditUser}
                        style={{ float: 'right', marginRight: 5 }} />
                </div>
            </Dialog>
        )
    }
}

export default EditUser