import "./QueryTermsTable.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  createDomainLabelsArray,
  isFreeText
} from "../../../../../../../helpers/advancedSearch";
import { useSelector } from "react-redux";
import { isArrayEmpty } from "../../../../../../../../util";
import { SEARCH_FILTER_ID_SEARCH_MODE_LOCAL } from "../../../../../../../../general/docsearch/searchConstants";
import { useEffect, useState } from "react";
import { fetchNumberOfHitsForQueryTerms } from "../../../../../../../helpers/hits";
import {
  createAllTermQueryStrings,
  findDuplicateQueryStrings as findDuplicateQueryTerms,
} from "../../../../../../../helpers/queryConverter";
import { HiDuplicate } from "react-icons/hi";
import { filterDefinitionsSelector } from "../../../../../../../../../../redux/common/filters/selectors/filters";
import ImportanceFactorInfo from "../../../../../../../../../common/queryBuilder/infos/ImportanceFactorInfo";
import TextProximityInfo from "../../../../../../../../../common/queryBuilder/infos/TextProximityInfo";
import SearchModeTermInfo from "../../../../../../../../../common/queryBuilder/infos/SearchModeTermInfo";
import IndexColumn from "../../../../../../../../../common/queryBuilder/common/Table/Column/IndexColumn";
import ButtonColumn from "../../../../../../../../../common/queryBuilder/common/Table/Column/ButtonColumn";
import InputTermsColumn from "./QueryTermsTableColumns/InputTermsColumn";
import QueryTermsColumn from "./QueryTermsTableColumns/QueryTermsColumn";
import SpecifyTermColumn from "./QueryTermsTableColumns/SpecifyTermColumn";
import SearchByColumn from "./QueryTermsTableColumns/SearchByColumn";
import { isEmpty } from "lodash";
import SearchModeColumn from "./QueryTermsTableColumns/SearchModeColumn";
import TextProximityColumn from "./QueryTermsTableColumns/TextProximityColumn";
import ImportanceFactorColumn from "./QueryTermsTableColumns/ImportanceFactorColumn";
import HitsColumn from "./QueryTermsTableColumns/HitsColumn";
import DuplicatesColumn from "./QueryTermsTableColumns/DuplicatesColumn";

const QueryTermsTable = ({
  queryTerms,
  onSpecifyConcepts,
  onSpecifyTextType,
  onUpdateSynonymsOnly,
  onUpdateBooster,
  onUpdateTextDistance,
  onDeleteQueryTerm,
  onDeleteQueryTerms,
}) => {
  const domainLabelsMap = useSelector(
    (state) => state.user.data.userDetails.department.domainLabelsMap
  );
  const filterDefinitions = useSelector(filterDefinitionsSelector);
  const previewRepository = useSelector(
    (state) => state.advancedSearch3Reducer.previewRepository
  );

  const searchModeFilter = filterDefinitions
    ? filterDefinitions[SEARCH_FILTER_ID_SEARCH_MODE_LOCAL]
    : null;

  const [numberOfResults, setNumberOfResults] = useState({});
  const [queryStrings, setQueryStrings] = useState({});
  const [fetchingNumberOfResults, setFetchingNumberOfResults] = useState();
  const [duplicates, setDuplicates] = useState({});

  useEffect(() => {
    fetchNumberOfResults(queryTerms, numberOfResults, queryStrings);
    findDuplicates(queryTerms);
  }, [queryTerms]);

  const fetchNumberOfResults = async (
    queryTerms,
    numberOfResultsPrev = {},
    queryStringsPrev = {}
  ) => {
    setFetchingNumberOfResults(true);
    const { numberOfResultsNew, queryStringResultsMapNew } =
      await fetchNumberOfHitsForQueryTerms(
        queryTerms,
        filterDefinitions,
        previewRepository,
        numberOfResultsPrev,
        queryStringsPrev
      );
    setNumberOfResults(numberOfResultsNew);
    setQueryStrings(queryStringResultsMapNew);
    setFetchingNumberOfResults(false);
  };

  const findDuplicates = (queryTerms) => {
    const queryObjects = createAllTermQueryStrings(queryTerms, filterDefinitions);
    const duplicatesIDs = findDuplicateQueryTerms(queryObjects);
    setDuplicates(duplicatesIDs);
  };


  const duplicatesList = duplicates ? Object.keys(duplicates) : null;

  return (
    <>
      <div className="overflow-auto mb-3">
        <DataTable
          id="termGroupTable"
          value={queryTerms}
          tableStyle={{ width: "100%" }}
        >
          <Column
            field="index"
            header="#"
            body={(_data, cellInfo) => <IndexColumn index={cellInfo.rowIndex + 1} />}
            style={{ width: 25 }}
          />
          <Column
            field="terms"
            header="Input"
            body={(data) => <InputTermsColumn terms={data.queryValues} />}
          />
          <Column
            field="queryTerms"
            header="Search terms"
            body={(data) => {
              const isText = isFreeText(data?.domains);
              return <QueryTermsColumn
                terms={isText ? data?.queryValues : data.prefnames}
                termsCutOff={isText ? 1 : 5}
                isShowNumOfTerms={!isText}
                domains={createDomainLabelsArray(data?.domains, domainLabelsMap)}
              />
            }}
          />
          <Column
            field="specify"
            header="Specify"
            body={(data) => {
              const isText = isFreeText(data?.domains);
              return <SpecifyTermColumn
                data={data}
                isText={isText}
                onSpecifyTextType={onSpecifyTextType}
                onSpecifyConcepts={onSpecifyConcepts}
              />
            }}
          />
          <Column
            field="searchBy"
            header="Searched by"
            body={(data) => {
              const searchByLabel = !isEmpty(data.ocids) ? "OCIDs" : isFreeText(data.domains) ? "Text" : "Domain/term";
              return <SearchByColumn
                text={searchByLabel}
              />
            }}
          />
          {/* <Column
            field="searchMode"
            header={
              <div className="flex align-items-center">
                <div className="mr-3">Search mode</div>
                <SearchModeTermInfo />
              </div>
            }
            body={(data) => {
              const isText = isFreeText(data?.domains);
              return <SearchModeColumn
                data={data}
                isText={isText}
                options={searchModeFilter?.filterValues}
                onChange={onUpdateSynonymsOnly}
              />
            }}
            style={{ width: 80 }}
          /> */}
          <Column
            field="textDistance"
            header={
              <div className="flex align-items-center">
                <div className="mr-3">Text proximity</div>
                <TextProximityInfo />
              </div>
            }
            body={(data) => {
              const isText = isFreeText(data?.domains);
              return <TextProximityColumn
                data={data}
                isText={isText}
                onChange={onUpdateTextDistance}
              />
            }}
            style={{ width: 80 }}
          />
          {/* <Column
            field="boost"
            header={
              <div className="flex align-items-center">
                <div className="mr-3">Importance factor</div>{" "}
                <ImportanceFactorInfo />
              </div>
            }
            body={(data) => {
              return <ImportanceFactorColumn
                data={data}
                onChange={onUpdateBooster}
              />
            }}
            style={{ width: 80 }}
          /> */}
          <Column
            field="results"
            header="# Results"
            body={(data) => {
              const hits = numberOfResults ? numberOfResults[data.id]?.hitCount : 0;
              return <HitsColumn
                numberOfHits={hits}
                loading={fetchingNumberOfResults}
              />
            }}
            style={{ width: 90, textAlign: "right" }}
          />
          <Column
            field="duplicate"
            header="Duplicate"
            body={(data) => {
              return <DuplicatesColumn
                isDuplicate={!!duplicates[data.id]}
              />
            }}
            style={{ width: 80 }}
          />
          <Column
            field="delete"
            body={(data) => <ButtonColumn
              data={data}
              label="Delete"
              onClick={onDeleteQueryTerm} />}
            style={{ width: 80 }} />
        </DataTable>
      </div>

      {!isArrayEmpty(duplicatesList) && (
        <div className="flex align-items-center justify-content-end pt-3">
          <a onClick={() => onDeleteQueryTerms(duplicatesList)}>
            Delete all duplicates ({duplicatesList.length})
          </a>
        </div>
      )}
    </>
  );
};

export default QueryTermsTable;
