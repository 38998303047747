import { notificationsService } from '../../api/content/notifications';

export const NotificationsActions = {
  SetNotifications: 'SET_NOTIFICATIONS',
  SetActiveNotification: 'SET_ACTIVE_NOTIFICATIONS',
};

export const setNotifications = (notifications) => ({
  type: NotificationsActions.SetNotifications,
  payload: { notifications },
});

export const setActiveNotification = (activeNotification) => ({
  type: NotificationsActions.SetActiveNotification,
  payload: { activeNotification },
});

export const getNotifications = (isNoSuperAdmin) => async (dispatch) => {
  if (isNoSuperAdmin) {
    await getEnabledNotifications(dispatch);
  } else {
    await getActualNotificationsWithActive(dispatch);
  }
};

export const createNotification = (notificationText) => async (dispatch) => {
  try {
    const createNotificationsResponse = await notificationsService.createNotification(
      notificationText,
    );
    if (createNotificationsResponse?.error) {
      throw new Error(createNotificationsResponse.error);
    }
    const notificationsData = await notificationsService.getNotifications();
    if (notificationsData?.error) {
      throw new Error(notificationsData.error);
    }
    dispatch(setNotifications(notificationsData));
  } catch (error) {
    console.error(error);
  }
};

export const toggleNotificationState = (notificationId, notificationState) => async (dispatch) => {
  try {
    const toggleNotificationStateResponse = await notificationsService.toggleNotificationState(
      notificationId,
      notificationState,
    );
    if (toggleNotificationStateResponse?.error) {
      throw new Error(toggleNotificationStateResponse.error);
    }
    await getActualNotificationsWithActive(dispatch);
  } catch (error) {
    console.error(error);
  }
};

export const updateNotification = (notification) => async (dispatch) => {
  try {
    const updateNotificationResponse = await notificationsService.updateNotification(notification);
    if (updateNotificationResponse?.error) {
      throw new Error(updateNotificationResponse.error);
    }
    await getActualNotificationsWithActive(dispatch);
  } catch (error) {
    console.error(error);
  }
};

export const deleteNotification = (notificationId) => async (dispatch) => {
  try {
    const deleteNotificationResponse = await notificationsService.deleteNotification(notificationId);
    if (deleteNotificationResponse?.error) {
      throw new Error(deleteNotificationResponse.error);
    }
    await getActualNotificationsWithActive(dispatch);
  } catch (error) {
    console.error(error);
  }
};

const getActualNotificationsWithActive = async (dispatch) => {
  try {
    const notificationsData = await notificationsService.getNotifications();
    if (notificationsData?.error) {
      throw new Error(notificationsData.error);
    }
    const activeNotification = notificationsData.find((notification) => notification.enabled);
    dispatch(setNotifications(notificationsData));
    dispatch(setActiveNotification(activeNotification));
  } catch (error) {
    console.error(error);
  }
};

const getEnabledNotifications = async (dispatch) => {
  try {
    const notificationsData = await notificationsService.getEnabledNotifications();
    if (notificationsData?.error) {
      throw new Error(notificationsData.error);
    }
    const activeNotification = notificationsData.find((notification) => notification.enabled);
    dispatch(setActiveNotification(activeNotification));
  } catch (error) {
    console.error(error);
  }
};
