import React from "react";
import { Button } from "primereact/button";

const noop = () => {
  console.log("Not implemented");
};
const SearchGroupFooter = ({
  cancelLabel = "Cancel",
  onCancel = noop,
  onAccept = noop,
  acceptLabel = "OK",
}) => {
  return (
    <>
      <Button
        label={cancelLabel}
        className="p-button-sm p-button-secondary"
        onClick={onCancel}
      />
      <Button
        label={acceptLabel}
        className="p-button-sm primaryButton"
        onClick={onAccept}
      />
    </>
  );
};
export default SearchGroupFooter;
