import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PipelineManager from '../manager/PipelineManager';

const mapStateToProps = state => ({
    userData: state.user.data
})
const mapDispatchToProps = dispatch => ({})

const PipelineManagerContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PipelineManager))

export default PipelineManagerContainer