/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import ExternalLinkIcon from '../general/ExternalLinkIcon/ExternalLinkIcon';
import { createDocViewUrl, removeHtmlTagsFromText } from '../util';
import { createDOILink } from '../util/externalLinks';
//import { extractContent } from './helper';


class CorrelationsSentences extends Component {

    constructor(props) {
        super(props);

        this.state = {
            //showSources: false,
            //selectedDocuments: []
        }
    }


    render() {
        const { sentences, showSources, repositoryLabelsMap, doisMap, onShowDocviewModal } = this.props;
        const sentencesBySentence = sentences && sentences.sentencesBySentence ? sentences.sentencesBySentence : [];
        return (
            <div className="grid sentences">
                <div className="col-12">
                    {sentences && sentences.countUnique > 0 ?
                        sentencesBySentence && sentencesBySentence.map(sentence => {
                            const sentenceRaw = removeHtmlTagsFromText(sentence.htmlSentence);
                            return (
                                <div key={sentence.key} className="breakWord">
                                    <div style={{ padding: '10px 5px 10px 5px' }}
                                        key={sentence.key}
                                        dangerouslySetInnerHTML={{ __html: sentence.htmlSentence }}
                                    />
                                    {showSources && sentence.sources ?
                                        <div style={{ padding: '0px 5px 5px 5px' }}>
                                            {Object.keys(sentence.sources).map(repID => {
                                                const docIDs = sentence.sources[repID];
                                                // console.log(sentence)
                                                if (repID && docIDs && docIDs.length > 0) {
                                                    return docIDs.map(docID => {
                                                        return (
                                                            <div key={`${sentence.key}_${repID}_${docID}`}>
                                                                <a className="primaryLink"
                                                                    //style={{ marginLeft: '5px' }}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        // console.log({ query: `text:"${sentenceRaw}"`})
                                                                        createDocViewUrl(repID, docID, { query: `text:"${sentenceRaw}"` })
                                                                        onShowDocviewModal(repID, docID, {})
                                                                    }}
                                                                // href={createDocViewUrl(repID, docID, { query: `text:"${sentenceRaw}"` })}
                                                                >
                                                                    Show document from {repositoryLabelsMap && repositoryLabelsMap[repID]
                                                                        ? repositoryLabelsMap[repID] : repID}
                                                                </a>
                                                                {doisMap && doisMap[repID] && doisMap[repID][docID] ?
                                                                    <span>
                                                                        <span> · </span>
                                                                        <a className="secondaryLink"
                                                                            style={{ color: '#757575' }}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            href={createDOILink(doisMap[repID][docID])}>
                                                                            {createDOILink(doisMap[repID][docID])}
                                                                            <ExternalLinkIcon standardLinkIcon={true} />
                                                                        </a>
                                                                    </span> : null}
                                                            </div>
                                                        )
                                                    });
                                                }
                                                return null;
                                            })}
                                        </div>
                                        : null
                                    }
                                    <hr style={{ margin: 0 }} />
                                </div>
                            )
                        })
                        :
                        <p style={{ lineHeight: 1.5 }}>
                            No direct matches found. Use the "Incl. descendants" checkbox to see sentences for ontologically related concepts.
                        </p>
                    }
                </div>
            </div>
        );
    }
}

export default CorrelationsSentences;