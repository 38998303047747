/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { getHeaderToken } from '../../../api/index'
import axios from 'axios'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
//import { ListBox } from 'primereact/listbox';
import { MultiSelect } from 'primereact/multiselect';
import { Rating } from 'primereact/rating';
import { TreeSelect } from 'primereact/treeselect';
import { APP_PROPERTIES } from '../../../properties/index';
import { //createDocViewUrl, 
    hasUserRole,
    createCheckedKeys
} from '.././util'
import { saveAs } from 'file-saver';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import dateFormat from 'dateformat';
import readIcon from "../../../assets/images/icons/Bookmark_active@2x.png";
import unreadIcon from "../../../assets/images/icons/Bookmark_inactive@2x.png";
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
import readcubeIcon from "../../../assets/images/icons/readcube.png";
import moment from 'moment';

import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'

import CreateEditApp from '../../common/App/CreateEditApp'

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate());
//const LAST_MONTH = new Date(TODAY.getFullYear(), TODAY.getMonth() - 1, TODAY.getDate());
//const LAST_WEEK = new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() - 7);

class CollectionDocuments extends Component {
    constructor(props) {
        super(props);
        let columns = [
            { field: "check", selectionMode: 'multiple', style: { width: '4em', fontWeight: 'normal' } },
            { field: "title", header: "Document", body: this.titleTemplate, sortable: true, style: {} },
            { field: "read", sortable: true, header: `${APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons ? 'Bookmark' : 'Bookmark'}`, style: { width: '10em', textAlign: 'left' }, body: this.readTemplate },
            { field: "rating", header: "My rating", sortable: true, body: this.relevanceTemplate, style: { width: '10em', textAlign: 'left' } },
            { field: "repository", header: "Repository", body: this.repositoryTemplate, sortable: true, style: { width: '10em', textAlign: 'left' } },
            { field: "insertionTime", header: "Added", body: this.insertionTimeTemplate, sortable: true, style: { width: '8em', textAlign: 'left' } }
        ]
        let columnsCollections = [
            { field: "check", selectionMode: 'single', style: { width: '4em', fontWeight: 'normal' } },
            { field: "name", header: "Name", sortable: true, style: {}, body: this.nameTemplate },
            { field: "numberDocuments", header: "Documents", sortable: true, style: { fontWeight: 'normal', textAlign: 'left' }, body: this.numberDocumentsTemplate },
            { field: "", header: "Sharing", body: this.sharedTemplate, style: { textAlign: 'left' } }
        ]
        this.state = {
            cols: columns,
            colsCollections: columnsCollections,
            displayDialogEditCollection: false,
            selectedCollectionName: '',
            selectedCollectionDescription: '',
            globalFilterDocuments: '',
            sortDocuments: 'all',
            showDocuments: 'all',
            displayCreateAppDialog: false,
            activeOptions: [
                { label: 'Active', value: 'active' },
                { label: 'Paused', value: 'paused' },
                { label: 'Remove', value: 'remove' }
            ]
        }
    }

    nameTemplate = (rowData) => {
        if (rowData.name) {
            return <React.Fragment>
                <span className="p-column-title">Name</span>
                <label>{rowData.name}</label>
            </React.Fragment>
        }
    }

    numberDocumentsTemplate = (rowData) => {
        //console.log(rowData.numberDocuments)
        if (rowData.hasOwnProperty('numberDocuments')) {
            return <React.Fragment>
                <span className="p-column-title">Documents</span>
                <label>{rowData.numberDocuments}</label>
            </React.Fragment>
        }
    }

    sharedTemplate = (rowData) => {
        return <div style={{}}>
            <span className="p-column-title">Sharing</span>
            {!rowData.shared ?
                ((rowData.departmentSharesRead && rowData.departmentSharesRead.length > 0) || (rowData.userSharesRead && rowData.userSharesRead.length > 0) || (rowData.userSharesWrite && rowData.userSharesWrite.length > 0)) ?
                    <span title={`You share this document collection with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This document collection can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This document collection is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }

    titleTemplate = (rowData) => {
        //console.log(this.props.collectionID)
        if (rowData.title) {
            let title = rowData.title
            let collectionList = ''
            if (rowData.documentCollectionList) {
                rowData.documentCollectionList.forEach(list => {
                    collectionList += list.name + ', '
                })
                collectionList = collectionList.substring(0, collectionList.length - 2)
            }
            return <div>
                <span className="p-column-title">Document</span>
                <span style={{ marginBottom: 5 }}>
                    {rowData.repositoryAvailable === false ?
                        <label>{title}</label>
                        :
                        <a onClick={(e) => this.showDocument(rowData, e)}>{title}</a>
                    }
                </span>
                {this.props.collectionID === undefined ?
                    <span style={{ display: 'block', paddingTop: 5 }}>
                        <label style={{ color: '#757575' }}>In document collections: {collectionList}</label>
                    </span>
                    : rowData.documentCollectionList.length >= 2 ?
                        <span style={{ display: 'block', paddingTop: 5 }}>
                            <label style={{ color: '#757575' }}>In document collections: {collectionList}</label>
                        </span>
                        : null
                }
            </div>
        }
    }

    readTemplate = (rowData) => {
        //console.log(rowData)
        if (!APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons) {
            if (rowData.read) {
                return <React.Fragment>
                    <span className="p-column-title">Bookmark</span>
                    <span style={{}}>
                        <i className="material-icons" id={rowData.repositoryAvailable === false ? '' : 'read'}
                            title={'You bookmarked this document'}
                            onClick={rowData.repositoryAvailable === false ? (e) => e.preventDefault() : (e) => this.documentReadChange(rowData, e)}
                            style={{ marginRight: 5, cursor: rowData.repositoryAvailable === false ? 'default' : 'pointer', verticalAlign: 'middle', marginTop: 0 }}>
                            {/*&#xf18c;*/}
                            <img src={readIcon} alt='Bookmarked' style={{ //verticalAlign: 'middle',
                                height: '.9em'
                            }} />
                        </i>
                        {/*<b>
                            {rowData.repositoryAvailable === false ?
                                <label style={{ //verticalAlign: 'sub'
                                }}>Bookmarked</label>
                                :
                                <a className="secondaryLink"
                                    title={'You bookmarked this document'}
                                    onClick={(e) => this.documentReadChange(rowData, e)}
                                    style={{ //verticalAlign: 'sub' 
                                    }}>
                                    Bookmarked
                </a>
                            }
                        </b>*/}
                    </span>
                </React.Fragment>
            } else {
                return <React.Fragment>
                    <span className="p-column-title">Bookmark</span>
                    <span style={{}}>
                        <i className="material-icons" id={rowData.repositoryAvailable === false ? '' : 'read'}
                            title={'Bookmark this document'}
                            onClick={rowData.repositoryAvailable === false ? (e) => e.preventDefault() : (e) => this.documentReadChange(rowData, e)}
                            style={{ marginRight: 5, cursor: rowData.repositoryAvailable === false ? 'default' : 'pointer', verticalAlign: 'middle', marginTop: 0 }}>
                            {/* &#xf18a; */}
                            <img src={unreadIcon} alt='Bookmark' style={{ //verticalAlign: 'middle',
                                height: '.9em'
                            }} />
                        </i>
                        {/*rowData.repositoryAvailable === false ?
                            <label style={{ //verticalAlign: 'super' 
                            }}>Bookmark</label>
                            : <a
                                className="secondaryLink"
                                title={'You did not read this document'}
                                onClick={(e) => this.documentReadChange(rowData, e)}
                                style={{ //verticalAlign: 'super' 
                                }}>
                                Bookmark
                </a>
                            */}
                    </span>
                </React.Fragment>
            }
        }
        else {
            /*if (rowData.read === true) {
                return <i className="material-icons" id='read'
                    title={'You read this document'}
                    onClick={(e) => this.documentReadChange(rowData, e)}>
                    /{'drafts'}
                </i>
            } else {
                return <i className="material-icons" id='read'
                    title={'You did not read this document'}
                    onClick={(e) => this.documentReadChange(rowData, e)}>
                    {'markunread'}
                </i>
            }*/
            if (rowData.read) {
                return <b>
                    <a
                        className="secondaryLink"
                        title={'You bookmarked document'}
                        style={{ color: 'black' }}
                        onClick={rowData.repositoryAvailable === false ? (e) => e.preventDefault() : (e) => this.documentReadChange(rowData, e)}>
                        Bookmarked
                    </a></b>
            } else {
                return <a
                    className="secondaryLink"
                    title={'Bookmark this document'}
                    style={{ color: 'black' }}
                    onClick={rowData.repositoryAvailable === false ? (e) => e.preventDefault() : (e) => this.documentReadChange(rowData, e)}>
                    Bookmark
                </a>
            }
        }
    }

    repositoryTemplate = (rowData) => {
        let repLabel = ''
        if (rowData.repositoryAvailable === false) {
            repLabel = rowData.repository //+ ' (Repository not available)'
        } else {
            this.props.availableRepositories &&
                this.props.availableRepositories.filter(rep => rep.name === rowData.repository).forEach(repo => {
                    repLabel = repo.label
                })
        }
        return <React.Fragment>
            <span className="p-column-title">Repository</span>
            {rowData.repository.startsWith("rc_") ?
                <img src={readcubeIcon} alt="ReadCube papers"
                    style={{ height: 17, paddingRight: 5, verticalAlign: 'bottom' }} />
                : null
            }
            {repLabel}
        </React.Fragment>
    }

    insertionTimeTemplate = (rowData) => {
        //console.log(rowData)
        if (rowData.insertionTime) {
            return <React.Fragment>
                <span className="p-column-title">Added</span>
                <label title={moment(rowData.insertionTime + "Z").format("YYYY-MM-DD")}>{moment(rowData.insertionTime + "Z").format("YYYY-MM-DD")}</label>
            </React.Fragment>
        }
    }

    showDocument = (rowData) => {
        //let link = createDocViewUrl(rowData.repositoryId, rowData.docId);

        this.props.onShowDocviewModal(rowData.repositoryId, rowData.docId, rowData)
        // window.open(link, "_blank");
    }

    relevanceTemplate = (rowData) => {
        if (rowData.rating) {
            return <div>
                <span className="p-column-title">My rating</span>
                <span style={{ display: 'inline-block' }}>
                    <Rating id="libraryRating" value={rowData.rating} readOnly={rowData.repositoryAvailable === false ? true : false} disabled={rowData.repositoryAvailable === false ? true : false} cancel={true} onChange={(e) => this.onEditorValueChange(rowData, e)} />
                </span>
            </div>
        } else {
            return <div>
                <span className="p-column-title">My rating</span>
                <span style={{ display: 'inline-block' }}>
                    <Rating id="libraryRating" value={0} readOnly={rowData.repositoryAvailable === false ? true : false} disabled={rowData.repositoryAvailable === false ? true : false} cancel={true} onChange={(e) => this.onEditorValueChange(rowData, e)} />
                </span>
            </div>
        }
    }

    onEditorValueChange = async (rowData, e) => {
        let val
        if (e.value !== null) {
            val = e.value
        } else {
            val = 0
        }
        this.props.ratingChange(rowData.api, rowData.docId, rowData.repository, val, rowData.title, rowData.repositoryId)
    }

    handleRemoveDocumentsDialog = () => {
        this.props.handleRemoveDocumentsDialog()
    }

    handleUnselect = (e) => {
        this.props.handleUnselect()
    }

    handleRemoveCollectionDialog = () => {
        this.props.onRemoveCollection()
    }

    handleSemanticExportDialog = () => {
        this.props.handleSemanticExportDialog()
    }

    handleModifyCollectionDialog = () => {
        let canModifyCollection
        let sharedWithUsers = []
        let sharedWithUsersData = []
        let checkedKeys = {}
        let usersShared = []
        let checkedOrgKeys = {}
        let orgShared = []
        let usersWrite = []
        let sharedUsers = []

        if (!this.props.selectedCollection.userSharesRead && !this.props.selectedCollection.userSharesWrite) {
            sharedWithUsers = []
        } else if (this.props.selectedCollection.userSharesRead && this.props.selectedCollection.userSharesRead.length > 0) {
            this.props.selectedCollection.userSharesRead.forEach(user => {
                sharedWithUsers = [...sharedWithUsers, user.id]
                sharedWithUsersData = [...sharedWithUsersData, {
                    forename: user.forename,
                    lastName: user.lastName,
                    id: user.id,
                    name: user.name
                }]
                if (this.props.allPossibleUsers.includes(user.id)) {
                    checkedKeys[user.id] = { checked: true, partialChecked: false }
                    usersShared = [...usersShared, user.id]
                    sharedUsers = [...sharedUsers, { label: `${user.lastName}, ${user.forename} (${user.name})`, value: user.id }]
                }
            })
        }
        if (this.props.selectedCollection.userSharesWrite && this.props.selectedCollection.userSharesWrite.length > 0) {
            this.props.selectedCollection.userSharesWrite.forEach(user => {
                sharedWithUsers = [...sharedWithUsers, user.id]
                sharedWithUsersData = [...sharedWithUsersData, {
                    forename: user.forename,
                    lastName: user.lastName,
                    id: user.id,
                    name: user.name
                }]
                if (this.props.allPossibleUsers.includes(user.id)) {
                    checkedKeys[user.id] = { checked: true, partialChecked: false }
                    usersShared = [...usersShared, user.id]
                    usersWrite = [...usersWrite, user.id]
                    sharedUsers = [...sharedUsers, { label: `${user.lastName}, ${user.forename} (${user.name})`, value: user.id }]
                }
            })
        }

        if (this.props.selectedCollection?.departmentSharesRead && this.props.selectedCollection?.departmentSharesRead.length > 0) {
            this.props.selectedCollection.departmentSharesRead.forEach(dep => {
                if (this.props.allPossibleSuborgs.includes(dep.id)) {
                    checkedOrgKeys[`${dep.id}-${dep.name}`] = { checked: true, partialChecked: false }
                }
                orgShared = [...orgShared, dep.id]
            })
        }

        //let checkedKeysWithCompaniesAndDepartments = createCheckedKeys(checkedKeys, this.props.departmentMembers, this.props.userArray)

        if (this.props.selectedCollection.defaultCollection || this.props.selectedCollection.shared) {
            canModifyCollection = false
            //sharedWithUsers = []
        } else {
            canModifyCollection = true
        }


        this.setState({
            oldCollectionSharedWithDepartment: this.props.selectedCollection.sharedForDepartment,
            collectionSharedWithDepartment: this.props.selectedCollection.sharedForDepartment,
            oldCollectionEditable: this.props.selectedCollection.editableForSharedUsers,
            collectionEditable: this.props.selectedCollection.editableForSharedUsers,
            selectedCollectionName: this.props.selectedCollection.name,
            selectedCollectionDescription: this.props.selectedCollection.description || '',
            selectedCollectionID: this.props.selectedCollection.id,
            selectedMembers: sharedWithUsers,
            oldSelectedMembers: sharedWithUsers,
            selectedMembersData: sharedWithUsersData,
            oldSelectedMembersData: sharedWithUsersData,
            canModifyCollection: canModifyCollection,
            checkedKeys: checkedKeys,
            checkedOrgKeys: checkedOrgKeys,
            usersShared: usersShared,
            orgShared: orgShared,
            usersWrite: usersWrite,
            sharedUsers: sharedUsers
        }, () => {
            this.props.onModifyCollection()
        })

    }

    handleCopyDocumentDialog = () => {
        this.props.handleCopyDocumentDialog()
    }

    handleMoveDocumentDialog = () => {
        this.props.handleMoveDocumentDialog()
    }

    handleModifyCollection = () => {
        //console.log("modify")
        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }
        this.props.modifyCollection(this.state.selectedCollectionName, this.state.selectedCollectionDescription, this.state.sharedForDepartment, usersRead, this.state.usersWrite, this.state.orgShared, this.state.editableForSharedUsers)
    }

    closeModifyCollection = () => {
        this.props.closeModifyCollection()
    }

    handleRemoveCollection = () => {
        this.props.deleteCollection()
    }

    addNewCollection = () => {
        this.props.onAddCollection()
    }

    updateCollectionName = (name) => {
        this.setState({
            selectedCollectionName: name
        })
    }

    updateCollectionDescription = (desc) => {
        this.setState({
            selectedCollectionDescription: desc
        })
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.props.orgArray && this.props.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.props.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.props.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.props?.departmentMembers, this.props.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.props.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        //console.log(members)
        this.setState({
            usersWrite: members
        })
    }

    handleRemoveDocuments = async () => {
        let ids = ''
        this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.forEach(doc => {
            ids += doc.id + ","
        })
        ids = ids.slice(0, -1)
        this.props.removeDocuments(ids)
    }

    handleCopyDocuments = async () => {
        if (this.props.collectionID !== undefined) {
            let ids = ''
            this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.forEach(doc => {
                ids += doc.id + ","
            })
            let ColIds = ''
            /*this.props.selectedCollectionsAction && this.props.selectedCollectionsAction.forEach(col => {
                ColIds += col.id + ","
            })*/
            if (this.props.selectedCollectionsAction && this.props.selectedCollectionsAction !== null) {
                ColIds = this.props.selectedCollectionsAction.id
            }
            ids = ids.slice(0, -1)
            //ColIds = ColIds.slice(0, -1)
            this.props.copyDocuments(ColIds, ids)
        } else {
            let ids = ''
            this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.forEach(doc => {
                doc.documentCollectionList && doc.documentCollectionList.forEach(list => {
                    ids += list.documentLink + ","
                })
            })
            let ColIds = ''
            /*this.props.selectedCollectionsAction && this.props.selectedCollectionsAction.forEach(col => {
                ColIds += col.id + ","
            })*/
            if (this.props.selectedCollectionsAction && this.props.selectedCollectionsAction !== null) {
                ColIds = this.props.selectedCollectionsAction.id
            }
            ids = ids.slice(0, -1)
            //ColIds = ColIds.slice(0, -1)
            //ids = ids.slice(0, -1)
            this.props.copyDocuments(ColIds, ids)
        }
    }

    handleMoveDocuments = async () => {
        let ids = ''
        this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.forEach(doc => {
            ids += doc.id + ","
        })
        let ColIds = ''
        /*this.props.selectedCollectionsAction && this.props.selectedCollectionsAction.forEach(col => {
            ColIds += col.id + ","
        })*/
        if (this.props.selectedCollectionsAction && this.props.selectedCollectionsAction !== null) {
            ColIds = this.props.selectedCollectionsAction.id
        }
        ids = ids.slice(0, -1)
        //ColIds = ColIds.slice(0, -1)
        //ids = ids.slice(0, -1)
        this.props.moveDocuments(ColIds, ids)
    }

    documentReadChange = async (rowData, e) => {
        let readStatus
        if (rowData.read === undefined) {
            readStatus = true
        } else if (rowData.read === false) {
            readStatus = true
        } else {
            readStatus = false
        }
        this.props.readStatusChange(rowData.api, rowData.docId, rowData.repository, readStatus, rowData.title, rowData.repositoryId)
    }

    handleSelectionChange = (e) => {
        this.props.selectDocuments(e.value)
    }

    onCollectionChange = (e) => {
        this.props.selectCollections(e.value)
    }

    export = async () => {
        //this.showExportGrowl()
        //console.log(this.props)
        let self = this
        await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/${self.props.selectedCollection.id}?format=xlsx`,
            { headers: getHeaderToken(), responseType: 'blob' })
            .then((response) => {
                saveAs(new Blob([response.data]), 'library.xlsx')
            })
    }

    onSortDocuments = (e) => {
        this.setState({
            sortDocuments: e.value
        })
    }

    onShowDocuments = (e) => {
        this.setState({
            showDocuments: e.value
        })
    }

    closeCopyDocumentDialog = () => {
        this.props.handleCloseCopyDocumentDialog()
    }

    closeMoveDocumentDialog = () => {
        this.props.handleCloseMoveDocumentDialog()
    }

    closeRemoveDocumentDialog = () => {
        this.props.handleCloseRemoveDocumentDialog()
    }

    handleTimeChange = (date) => {
        this.props.callbackFromTimeChange(date !== null ? dateFormat(date, 'yyyy-mm-dd') : date)
        /*if (date !== 'none') {
            this.fetchQueryOverviewData(date, this.props.selectedCollection.id)
        }*/
    }
    openCreateAppDialog = () => {
        this.setState({
            displayCreateAppDialog: true
        })
    }

    onShowToast = (e, type = 'error', summary = 'Failed!') => {
        let msg = { severity: type, summary, detail: e, life: 10000 };
        this.growl.show(msg);
    };
    render() {

        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults
        //const showIcons = APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons;

        let filteredCollections = []

        this.props.selectedCollection && this.props.collectionID !== undefined && this.props.docCollections && this.props.docCollections.forEach(doc => {
            if (doc.id !== this.props.selectedCollection.id) {
                if (doc.writable //&& !doc.hasOwnProperty('sharedComment')
                ) {
                    filteredCollections = [...filteredCollections, doc]
                }
            }
        })

        if (this.props.collectionID === undefined) {
            this.props.docCollections && this.props.docCollections.forEach(doc => {
                if (doc.writable //&& !doc.hasOwnProperty('sharedComment')
                ) {
                    filteredCollections = [...filteredCollections, doc]
                }
            })
        }

        const footerRemoveCollection = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete" onClick={this.handleRemoveCollection} className='p-button-sm primaryButton' />
                <Button label="Cancel" onClick={this.props.onHideRemoveCollection} className="p-button-secondary p-button-sm" />
            </div>
        )

        /*const footerEditor = (
            <div>
                <label style={{ float: 'left', paddingTop: 20 }}>* required</label>
                <Button label="Cancel" onClick={this.closeModifyCollection}
                    className="p-button-secondary p-button-sm" />
                <Button label="Save" onClick={this.handleModifyCollection} style={{ float: 'right', marginRight: -5 }} className='p-button-sm primaryButton' />
            </div>
        )

        const footerRemoveDocuments = (
            <div>
                <Button label="Cancel" onClick={this.closeRemoveDocumentDialog}
                    className="p-button-secondary p-button-sm" />
                <Button label="Remove" onClick={this.handleRemoveDocuments} className='p-button-sm primaryButton' />
            </div>
        )

        let footerDocuments = <div className='col-12' style={{ paddingTop: 5, paddingBottom: 5, justifyContent: 'center', display: 'flex' }}>
            {this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length > 0 &&
                <div className='col-4' style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #d6d6d6', padding: '2em' }}>
                    <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{this.props.selectedDocumentsAction.length} {this.props.selectedDocumentsAction.length !== 1 ? 'items' : 'item'} selected</label>
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedDocumentsAction.length && this.props.selectedDocumentsAction.length === 1 ? `Copy selected document` : `Copy ${this.props.selectedDocumentsAction.length} selected documents`}
                        onClick={this.handleCopyDocumentDialog} >Copy</a>
                    <a style={{ marginTop: 5 }}
                        title={this.props.selectedDocumentsAction.length && this.props.selectedDocumentsAction.length === 1 ? `Move selected document` : `Move ${this.props.selectedDocumentsAction.length} selected documents`}
                        onClick={this.handleMoveDocumentDialog}>Move</a>
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedDocumentsAction.length && this.props.selectedDocumentsAction.length === 1 ? `Remove selected document` : `Remove ${this.props.selectedDocumentsAction.length} selected documents`}
                        onClick={this.handleRemoveDocumentsDialog}>Remove</a>
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title='Unselect'
                        onClick={this.handleUnselect}>Unselect</a>
                </div>
            }
        </div>;
        */

        //console.log(this.props)


        let columnData
        if (//this.props.canEdit === false || 
            this.props.collectionID === undefined) {
            columnData = this.state.cols.filter(column => //column.field !== "check" && 
                column.field !== 'insertionTime'
            ).map((col, i) => {
                return <Column className="columnheader" key={col.field}
                    field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                    header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
            })

        } else if (this.props.canEdit === false) {
            columnData = this.state.cols//.filter(column => column.field !== "check" //&& column.field !== 'insertionTime')
                .map((col, i) => {
                    return <Column className="columnheader" key={col.field}
                        field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                        header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
                })
        } else if (this.props.canEdit === true) {
            columnData = this.state.cols.map((col, i) => {
                return <Column className="columnheader" key={col.field}
                    field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode}
                    header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
            })
        }

        if (!hasUserRole("ROLE_READDOCUMENTS")) {
            columnData = columnData.filter(function (el) {
                return el.key !== 'read'
            })
        }

        let columnDataCollections = this.state.colsCollections.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        /*let sortOptions = [
             { label: 'All fields', value: 'all' }
         ]
 
         let showOptions = [
             { label: 'All', value: 'all' },
             { label: 'Read', value: 'read' },
             { label: 'Unread', value: 'unread' }
 
         ]*/

        let headerCollectionDocuments = <div className='grid' style={{
            marginRight: 0,
            borderBottom: '1px solid #757575',
            paddingTop: 18, paddingBottom: 10,
            marginLeft: 0
        }}>
            {/*<div>
        <Button label="Cancel" onClick={() => this.export()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }} />
        </div>*/}
            <div style={{ paddingLeft: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title={this.props.collectionID !== undefined ? "Search collection documents" : "Search documents"}>
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', width: '80%', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap' }}
                    type="search"
                    value={this.state.globalFilterDocuments}
                    title={this.props.collectionID !== undefined ? "Search collection documents" : "Search documents"}
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterDocuments: e.target.value })}
                    placeholder={this.props.collectionID !== undefined ? "Search collection documents" : "Search documents"}
                />
                {/*<Dropdown
                    className='dropdownNoBorder'
                    value={this.state.sortDocuments}
                    options={sortOptions}
                    onChange={(e) => this.onSortDocuments(e)}
                style={{ width: '90px', float: 'right' }} />*/}
            </div>
            {/*<div style={{ paddingLeft: 0, paddingRight: 0, width: '10%', display: 'flex', alignItems: 'center' }}>
                <label style={{ paddingLeft: 15, paddingBottom: 2 }}>Show:</label>
                <Dropdown
                    className='dropdownNoBorder'
                    value={this.state.showDocuments}
                    options={showOptions}
                    onChange={(e) => this.onShowDocuments(e)}
                    style={{ width: '70px', float: 'right' }} />
            </div>*/}
        </div>

        let collectionDocuments = []

        if (this.props.selectedDocuments && this.props.selectedDocuments[0] && this.props.selectedDocuments[0].document) {
            this.props.selectedDocuments.forEach(doc => {
                collectionDocuments = [...collectionDocuments, Object.assign(doc.document, { id: doc.id, insertionTime: doc.insertionTime, relevance: doc.relevance })]
            })
        } else if (this.props.selectedDocuments && !this.props.selectedDocuments.document) {
            this.props.selectedDocuments.forEach(doc => {
                collectionDocuments = [...collectionDocuments, doc]
            })
        }

        //console.log(this.props.startTime)
        if (collectionDocuments.length > 0 && this.props.startTime && this.props.startTime !== null) {
            let date = this.props.startTime
            collectionDocuments = collectionDocuments.filter(function (el) {
                return moment(el.insertionTime + "Z").format("YYYY-MM-DD") >= date
            })
        }

        let usersShared = ''
        let usersWrite = ''
        let suborgsShared = ''
        if (this.props.selectedCollection && this.props.selectedCollection.userSharesRead && this.props.selectedCollection.userSharesRead.length > 0) {
            this.props.selectedCollection.userSharesRead.forEach(user => {
                if (this.props.allPossibleUsers?.length === 0 || this.props.allNotAllowedUsersRead?.includes(user.id)) {
                    usersShared += `${user.lastName}, ${user.forename} (${user.name})` + '*, '
                } else {
                    usersShared += `${user.lastName}, ${user.forename} (${user.name})` + ', '
                }
            })
        }
        if (this.props.selectedCollection && this.props.selectedCollection.userSharesWrite && this.props.selectedCollection.userSharesWrite.length > 0) {
            this.props.selectedCollection.userSharesWrite.forEach(user => {
                if (this.props.allPossibleUsers?.length === 0 || this.props.allNotAllowedUsersWrite?.includes(user.id)) {
                    usersShared += `${user.lastName}, ${user.forename} (${user.name})` + '*, '
                    usersWrite += `${user.lastName}, ${user.forename} (${user.name})` + '*, '
                } else {
                    usersShared += `${user.lastName}, ${user.forename} (${user.name})` + ', '
                    usersWrite += `${user.lastName}, ${user.forename} (${user.name})` + ', '
                }
            })
        }
        if (this.props.selectedCollection && this.props.selectedCollection.departmentSharesRead && this.props.selectedCollection.departmentSharesRead.length > 0) {
            this.props.selectedCollection.departmentSharesRead.forEach(dep => {
                if (this.props.allPossibleSuborgs?.length === 0 || this.props.allNotAllowedSuborgs?.includes(dep.id)) {
                    suborgsShared += `${dep.name}` + '*, '
                } else {
                    suborgsShared += `${dep.name}` + ', '
                }
            })
        }
        usersShared = usersShared.substring(0, usersShared.length - 2)
        usersWrite = usersWrite.substring(0, usersWrite.length - 2)
        suborgsShared = suborgsShared.substring(0, suborgsShared.length - 2)

        let headerCollections = <div className='grid' style={{
            marginRight: 0,
            paddingTop: 18, paddingBottom: 10
        }}>
            <div style={{ paddingLeft: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search collections">
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', float: 'left', width: '82%' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterCollections: e.target.value })}
                    placeholder="Search collections" />
            </div>
        </div>

        /*let timeOptions = [
            { label: 'no selection', value: 'none' },
            { label: dateFormat(YESTERDAY, 'yyyy/mm/dd'), value: dateFormat(YESTERDAY, 'yyyy-mm-dd') },
            { label: dateFormat(LAST_WEEK, 'yyyy/mm/dd'), value: dateFormat(LAST_WEEK, 'yyyy-mm-dd') },
            { label: dateFormat(LAST_MONTH, 'yyyy/mm/dd'), value: dateFormat(LAST_MONTH, 'yyyy-mm-dd') }
        ]

        if (!timeOptions.some(e => e.value === this.props.startTime.toString())) {
            timeOptions = [...timeOptions, { label: this.props.startTime.toString().replace(/-/g, '/'), value: this.props.startTime.toString() }]
        }*/

        return (
            <div className="col-12 grid-nogutter" style={{ paddingRight: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />
                <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0, marginBottom: this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length > 0 ? 45 : 0 }}>
                    <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 5, paddingTop: 0, paddingRight: 0 }}>
                        <div className='col-12 grid' style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 15 }}>
                            <div style={{ paddingLeft: 7 }}>
                                <label style={{
                                    marginTop: -1, fontSize: '1.1em', marginRight: 25, fontWeight: 'bold',
                                    overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', width: '100%'
                                }}
                                    title={this.props.collectionID === undefined ? 'All documents' : `Document collection: ${this.props.collectionName}`}>
                                    {this.props.collectionID === undefined ? 'All documents' : `Document collection: ${this.props.collectionName}`}</label>
                            </div>
                            {this.props.collectionID === undefined ?
                                <div style={{ marginTop: 0, marginLeft: 'auto', marginRight: 0 }}>
                                    <a onClick={(e) => this.handleSemanticExportDialog(e)}
                                        style={{ display: 'inline-block', marginTop: 0 }}>Export</a>
                                </div>
                                : null
                            }
                            {this.props.collectionID !== undefined ?
                                <div style={{ marginTop: 0, marginLeft: 'auto', marginRight: 0 }}>
                                    {(hasUserRole('ROLE_SEMANTICEXPORT') || (hasUserRole('ROLE_SEQUENCEEXPORT') //&& this.props.containsPatents
                                    )) && this.props.selectedDocuments.length > 0 ?
                                        <a onClick={(e) => this.handleSemanticExportDialog(e)}
                                            style={{ display: 'inline-block', marginRight: !this.props.selectedCollection.sharedComment ? 20 : 0, marginTop: 0 }}>Export</a>
                                        : null
                                    }
                                    {this.props.selectedCollection && !this.props.selectedCollection.sharedComment ?
                                        <a onClick={(e) => this.handleModifyCollectionDialog(e)}
                                            style={{
                                                display: 'inline-block', marginRight: this.props.selectedCollection.defaultCollection ? 0 : 20,
                                                //marginLeft: 10, 
                                                marginTop: 0
                                            }}>Edit & Share</a>
                                        : null
                                    }
                                    {!this.props.selectedCollection.defaultCollection && !this.props.selectedCollection.sharedComment ?
                                        <a onClick={(e) => this.handleRemoveCollectionDialog(e)}
                                            style={{ display: 'inline-block', marginRight: 0, marginTop: 0 }}>Delete</a>
                                        : null
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, paddingTop: 0 }}>
                        <label style={{ marginTop: 0, float: 'left', marginBottom: 0, color: '#757575' }}>{this.props.collectionDescription}</label>
                    </div>
                    {this.props.collectionID !== undefined ?
                        <div className='col-12'
                            style={{ display: 'inline-block', paddingBottom: 10, paddingLeft: 0, paddingTop: 0 }}>
                            <label style={{ marginTop: 0, float: 'left', marginBottom: 5, color: '#757575' }}>
                                {this.props.selectedCollection.created ? `Created: ${moment(this.props.selectedCollection.created + "Z").format("YYYY-MM-DD")}` : ''}
                                {this.props.selectedCollection.modified !== undefined ? ` - Modified: ${moment(this.props.selectedCollection.modified + "Z").format("YYYY-MM-DD")} ` : ''}
                                {this.props.selectedCollection.departmentSharesRead && this.props.selectedCollection.departmentSharesRead.length > 0 ? <span title={`Shared with suborganizations:  ${suborgsShared} `} style={{
                                    whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                    display: 'inline-block'
                                }}>{` - Shared with suborganizations:  ${suborgsShared}`}&nbsp;</span> : ''}
                                {(this.props.selectedCollection.userSharesRead && this.props.selectedCollection.userSharesRead.length > 0) ||
                                    (this.props.selectedCollection.userSharesWrite && this.props.selectedCollection.userSharesWrite.length > 0) ?
                                    <span title={`Shared with users: ${usersShared}`} style={{
                                        whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                        display: 'inline-block'
                                    }}>{` - Shared with users: ${usersShared}`}&nbsp;</span> : ''}
                                {(this.props.selectedCollection.userSharesWrite && this.props.selectedCollection.userSharesWrite.length > 0) || (this.props.selectedCollection.userSharesRead && this.props.selectedCollection.userSharesRead.length > 0) ?
                                    this.props.selectedCollection.userSharesWrite && this.props.selectedCollection.userSharesWrite.length > 0 ? <span title={`Editable for users: ${usersWrite}`} style={{
                                        whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                        display: 'inline-block'
                                    }}>{` - Editable for users: ${usersWrite}`}</span> : ' - Not editable for shared users'
                                    : ''
                                }{this.props.allNotAllowedSuborgs?.length > 0 || this.props.allNotAllowedUsersRead?.length > 0 || this.props.allNotAllowedUsersWrite.length > 0 ?
                                    <span style={{}}>
                                        <span style={{ verticalAlign: 'middle' }}>&nbsp; -</span>
                                        <a className="infoIconLink"
                                            style={{ cursor: 'default' }}
                                            title="*: Suborganizations and users you are not allowed to administrate anymore but still share this collection with">
                                            <img src={infoIcon} alt="Sharing information"
                                                style={{ marginLeft: 10, verticalAlign: 'middle' }} />
                                        </a>
                                    </span>
                                    : ''
                                }
                            </label>
                        </div>
                        : null
                    }
                    {this.props.collectionID !== undefined && hasUserRole("ROLE_ALERTS") ?
                        <React.Fragment>
                            <div className='col-12 grid' style={{ paddingBottom: 0, paddingLeft: 0, paddingTop: 2, paddingRight: 0 }}>
                                <div style={{ paddingLeft: 7 }}>
                                    <label style={{ marginTop: 0, float: 'left', marginRight: 5 }}>Alert:</label>
                                    {((this.props.selectedCollection.notifications && this.props.selectedCollection.notifications.length === 0) || this.props.selectedCollection.notifications === undefined) ?
                                        <div style={{ display: 'inline-block', float: 'left', marginTop: -5, marginRight: 10 }}>
                                            <i className="material-icons-outlined" id='read'
                                                title={'Add alarm to this document collection'}
                                                onClick={(e) => //this.props.collectionAlertChange(this.props.selectedCollection, 'add', e)
                                                    this.props.openAddAlertDialog(this.props.selectedCollection)}>
                                                {'notification_add'}
                                            </i>
                                        </div>
                                        : (this.props.selectedCollection.notifications && this.props.selectedCollection.notifications.length > 0 && this.props.selectedCollection.notifications[0].active) ?
                                            <div className='grid' style={{ float: 'left', marginTop: -5, marginRight: 5, marginLeft: 5 }}>
                                                <i className="material-icons" id='read'
                                                    title={'You receive alerts for this document collection'}
                                                    onClick={(e) => //this.props.collectionAlertChange(this.props.selectedCollection, 'pause', e)
                                                        this.props.openAddAlertDialog(this.props.selectedCollection)}>
                                                    {'notifications_active'}
                                                </i>
                                                {/*
                                                    <div style={{ marginTop: 5 }}>
                                                        <Dropdown className='dropdownNoBorder'
                                                            style={{ marginTop: -7 }}
                                                            value={'active'}
                                                            options={this.state.activeOptions}
                                                            onChange={(e) => this.props.collectionAlertChange(this.props.selectedCollection, 'pause', e)} />
                                                    </div>
                                        */}
                                            </div>
                                            : (this.props.selectedCollection.notifications && this.props.selectedCollection.notifications.length > 0 && !this.props.selectedCollection.notifications[0].active) ?
                                                <div className='grid' style={{ float: 'left', marginTop: -5, marginRight: 15, marginLeft: 5 }}>
                                                    <i className="material-icons" id='read'
                                                        title={'Alerts for this document collection are paused'}
                                                        onClick={(e) => //this.props.collectionAlertChange(this.props.selectedCollection, 'activate', e)
                                                            this.props.openAddAlertDialog(this.props.selectedCollection)}>
                                                        {'notifications_paused'}
                                                    </i>
                                                    {/*
                                                        <div style={{ marginTop: 5 }}>
                                                            <Dropdown className='dropdownNoBorder'
                                                                style={{ marginTop: -7 }}
                                                                value={'paused'}
                                                                options={this.state.activeOptions}
                                                                onChange={(e) => this.props.collectionAlertChange(this.props.selectedCollection, 'activate', e)} />
                                                        </div>
                                    */}
                                                </div>
                                                : null
                                    }
                                    {this.props.selectedCollection && this.props.userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' && hasUserRole("ROLE_APPS") ? //&& !this.props.selectedCollection.shared && hasUserRole("ROLE_DASHBOARD") ?
                                        <React.Fragment>
                                            {this.props.selectedDocuments?.length > 0 ?
                                                <a style={{
                                                    marginTop: 0,
                                                    float: 'left',
                                                    marginBottom: 5,
                                                    paddingRight: 5
                                                }} title="Create App" onClick={(e) => this.openCreateAppDialog(e)}>
                                                    Create App</a>
                                                :
                                                <label>Create App</label>
                                            }
                                        </React.Fragment>
                                        : null
                                    }
                                </div>
                                {/*
                                        (this.props.selectedCollection.notifications && this.props.selectedCollection.notifications.length > 0 && this.props.selectedCollection.notifications[0].active) ?
                                        <div style={{ paddingLeft: 5 }}>
                                            <label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>Interval:</label>
                                            <Dropdown className='dropdownNoBorder'
                                                style={{ marginTop: -8 }}
                                                value={this.props.selectedCollection.notifications[0].interval}
                                                options={this.props.docIntervals}
                                                onChange={(e) => { this.props.handleIntervalChange(this.props.selectedCollection, e.value) }} />
                                        </div>
                                        : null
                                    */}
                                {this.props.selectedDocuments && this.props.selectedDocuments.length > 0 ?
                                    <div style={{ marginLeft: 'auto', marginRight: -15 }}>
                                        {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5, paddingRight: 5 }}>Show new documents since:</label>
                                        <Dropdown className='dropdownNoBorder'
                                            appendTo={document.body}
                                            style={{ marginTop: -8, marginLeft: -6 }}
                                            value={this.props.startTime}
                                            options={timeOptions}
                                            disabled={this.props.selectedDocuments.length !== 0 ? false : true}
                                    onChange={(e) => { this.handleTimeChange(e.value) }} />*/}

                                        <div style={{ display: 'inline' }}
                                            title="Recently added to this collection.">
                                            Show new documents since:
                                        </div>
                                        <Calendar
                                            //className='adminCalendar'
                                            className="standardDateWidth noBorder"
                                            placeholder="Select date"
                                            /*style={{
                                                width: '40%',
                                                //verticalAlign: 'bottom'
                                                border: 'none'
                                                //border: '1px solid #d6d6d6',
                                                //padding: 2
                                            }}*/
                                            style={{ marginRight: this.props.selectedCollection === undefined && this.props.startTime !== null ? 20 : 0 }}
                                            value={this.props.startTime !== null ? new Date(this.props.startTime) : null}
                                            onClearButtonClick={(e) => this.handleTimeChange(null)}
                                            id='calendar'
                                            maxDate={YESTERDAY}
                                            //minDate={YESTERDAY}
                                            readOnlyInput={true}
                                            dateFormat='yy/mm/dd'
                                            showButtonBar={true}
                                            monthNavigator={true}
                                            //yearNavigator={true}
                                            appendTo={document.body}
                                            //disabled={this.props.selectedDocuments.length !== 0 ? false : true}
                                            onChange={(e) => {
                                                this.handleTimeChange(e.value)
                                            }}
                                            aria-label='Select date'
                                            title='Select date'
                                            showIcon
                                        //disabled={false}
                                        />
                                        {this.props.selectedCollection === undefined && this.props.startTime !== null ?
                                            <a title="Show all documents" onClick={(e) => this.handleTimeChange(null)}>
                                                Show all</a>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null}
                            </div>

                        </React.Fragment>
                        : null
                    }
                    {this.props.selectedDocuments && this.props.selectedDocuments.length > 0 ?
                        <div className="datatable-responsive-demo-bigger">
                            {headerCollectionDocuments}
                            <DataTable ref={(el) => this.dt = el}
                                selection={this.props.selectedDocumentsAction}
                                removableSort={true}
                                onSelectionChange={e => this.handleSelectionChange(e)}
                                selectionMode={//this.props.collectionID === undefined || this.props.canEdit === false ? null : 
                                    "checkbox"}
                                className="p-datatable-responsive-demo-bigger"
                                rowHover={true}
                                value={collectionDocuments}
                                responsive="true"
                                paginator={true}
                                paginatorPosition="bottom"
                                rows={this.props.rowsDocuments}
                                onPage={(e) => this.props.onCustomPage(e, true)}
                                globalFilter={this.state.globalFilterDocuments}
                                first={this.props.firstDocuments}
                                rowsPerPageOptions={[10, 20, 50]}
                                emptyMessage="No documents for selected time range"
                                //footer={footerDocuments}
                                //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                paginatorTemplate={CustomPaginatorTemplate}
                                paginatorLeft={<CustomPaginatorLeftSide
                                    first={this.props.firstDocuments}
                                    contentLength={collectionDocuments.length}
                                    rows={this.props.rowsDocuments}>
                                </CustomPaginatorLeftSide>}
                                paginatorRight={<CustomPaginatorRightSide
                                    rows={this.props.rowsDocuments}
                                    onChange={(e) => this.props.onCustomPage(e, false)}>
                                </CustomPaginatorRightSide>}>
                                {columnData}
                            </DataTable>
                        </div>
                        :
                        <div className="dataTable" style={{ marginTop: 20 }}>
                            <label style={{ marginRight: 10, marginBottom: 20, verticalAlign: 'bottom', fontWeight: 'bold' }}>
                                {this.props.collectionID !== undefined ? 'No documents in this document collection.' : 'No documents in your library.'}</label>
                        </div>
                    }
                </div>
                <Dialog focusOnShow={false} visible={this.props.displayDialogRemoveCollection} style={{ 'width': "50vw" }} header="Delete collection"
                    modal={true} footer={footerRemoveCollection} dismissableMask={true}
                    onHide={(e) => this.props.onHideRemoveCollection(e)}
                    className='styledDialog'>
                    <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                        Warning! This document collection will be deleted! Do you really want to delete this collection?
                        If you share the collection will also be deleted for your colleagues.
                    </div>
                </Dialog>
                <Dialog focusOnShow={false} visible={this.props.displayDialogModifyCollection} style={{ 'width': "60vw" }} header="Document collection details"
                    modal={true} blockScroll
                    // footer={footerEditor}
                    dismissableMask={true}
                    onHide={(e) => this.props.onHideModifyCollection(e)}
                    className='styledDialog'>
                    <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Name *</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <InputText maxLength={50} disabled={this.state.canModifyCollection ? false : true} onChange={(e) => { this.updateCollectionName(e.target.value) }} value={this.state.selectedCollectionName} />
                            <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Description</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                            <InputText maxLength={200} disabled={this.state.canModifyCollection ? false : true} onChange={(e) => { this.updateCollectionDescription(e.target.value) }} value={this.state.selectedCollectionDescription} />
                            <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ display: 'flex', paddingBottom: 15, paddingRight: 10, paddingTop: 5 }}>
                            <div className="w-full" >
                                <TreeSelect disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.props.departmentMembers?.length !== 0 ? false : true}
                                    id='sharingTreeSelect'
                                    resetFilterOnHide={true}
                                    //appendTo={document.body}
                                    selectionMode='checkbox'
                                    placeholder='Select suborganizations'
                                    value={this.state.checkedOrgKeys}
                                    options={this.props.orgArray}
                                    display='chip'
                                    scrollHeight='200px'
                                    filter
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        this.handleMemberChange(e.value, true)
                                    }} style={{
                                        borderBottom: '1px solid gray', maxWidth: '19vw'
                                    }} />
                            </div>
                            <div className="w-full" >
                                <TreeSelect disabled={this.props.departmentMembers?.length !== 0 ? false : true}
                                    id='sharingTreeSelect'
                                    resetFilterOnHide={true}
                                    appendTo={document.body}
                                    selectionMode='checkbox'
                                    placeholder='Select users'
                                    //title='Select colleagues'
                                    value={this.state.checkedKeys}
                                    options={this.props.userArray}
                                    display='chip'
                                    scrollHeight='200px'
                                    //optionLabel='label'
                                    //optionGroupLabel='label'
                                    //optionGroupChildren="items"
                                    //optionGroupTemplate={groupedItemsTemplate}
                                    filter
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        this.handleMemberChange(e.value, false)
                                    }} style={{
                                        borderBottom: '1px solid gray', marginLeft: 10, maxWidth: '19vw'
                                    }} />
                            </div>
                        </div>
                        {this.state.usersShared && this.state.usersShared.length !== 0 ?
                            <React.Fragment>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-5 xl:col-4" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Editable for shared users</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8" style={{ padding: '.5em' }}>
                                    <MultiSelect
                                        pt={{
                                            checkboxIcon: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    if (e.target.className.baseVal !== "") {
                                                        e.target.parentNode.click();
                                                    } else {
                                                        e.target.parentNode.parentNode.click();
                                                    }
                                                },
                                            },
                                            headerCheckbox: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    e.target.parentNode.click();
                                                },
                                            },
                                        }}
                                        id='sharingMultiSelect'
                                        display='chip'
                                        placeholder='Select users'
                                        onChange={(e) => this.onWriteMembersChange(e.value)}
                                        value={this.state.usersWrite}
                                        options={this.state.sharedUsers}
                                    >
                                    </MultiSelect>
                                </div>
                            </React.Fragment>
                            : null
                        }
                        <div style={{ display: 'flex', justifyContent: 'end', paddingLeft: 17 }}>
                            <div className="full-text-message-collections col-6 sm:col-6 md:col-6 lg:col-7 xl:col-8">
                                Note: Users changing the suborganization will lose access to search collections shared on suborganization level, but will keep access to search collections shared on personal level.
                            </div>
                        </div>
                    </div>
                    <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                        <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 20 }}>* required</label>
                        <Button label="Save" onClick={this.handleModifyCollection}
                            style={{ float: 'right' }}
                            className='p-button-sm primaryButton' />
                        <Button label="Cancel" onClick={this.closeModifyCollection}
                            className="p-button-secondary p-button-sm"
                            style={{ float: 'right', marginRight: 5 }} />
                    </div>
                </Dialog>
                <Dialog focusOnShow={false} visible={this.props.displayDialogRemoveDocuments} style={{ 'width': "50vw" }} header={this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length === 1 ? "Delete document" : this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length > 1 ? "Delete documents" : null}
                    modal={true}
                    //footer={footerRemoveDocuments} 
                    dismissableMask={true}
                    onHide={() => this.closeRemoveDocumentDialog()}
                    className='styledDialog'>
                    <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                        {this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length === 1 ?
                            `Warning! This document will be deleted from the collection! Do you really want to delete this document?
                        If you share the collection the document will also be deleted for your colleagues.`
                            : this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length > 1 ?
                                `Warning! These documents will be deleted from the collection! Do you really want to delete these documents?
                If you share the collection the documents will also be deleted for your colleagues.`
                                : null}
                    </div>
                    <div className='col-12' style={{ paddingTop: 12, paddingRight: 20, marginBottom: 25 }}>
                        <Button label="Cancel" onClick={this.closeRemoveDocumentDialog}
                            className="p-button-secondary p-button-sm"
                            style={{ float: 'right' }} />
                        <Button label="Delete" onClick={this.handleRemoveDocuments} className='p-button-sm primaryButton'
                            style={{ float: 'right', marginRight: 5 }} />
                    </div>
                </Dialog>
                <Dialog focusOnShow={false} visible={this.props.displayDialogCopyDocument} style={{ 'width': "50vw" }}
                    header={this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length === 1 ?
                        "Copy document" : this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length > 1 ? "Copy documents" : null}
                    modal={true} dismissableMask={true} onHide={() => this.closeCopyDocumentDialog()} className='styledDialog'>
                    <React.Fragment>
                        {filteredCollections && filteredCollections.length === 0 ?
                            <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                <h4 style={{}}>No other collection available. Please create a new collection.</h4>
                                <a style={{ marginBottom: 5, marginLeft: 0, marginTop: 5 }}
                                    title="Create new collection" onClick={this.addNewCollection}>
                                    Create collection</a>
                            </div>
                            :
                            <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                <a style={{ marginBottom: 5, marginLeft: 0, marginTop: 5 }}
                                    title="Create new collection" onClick={this.addNewCollection}>
                                    Create collection</a>
                            </div>
                        }
                        {filteredCollections && filteredCollections.length > 0 &&
                            <React.Fragment>
                                <div className="col-12 grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 10, paddingTop: 10, marginRight: 0, marginLeft: 0, borderBottom: '1px solid #d6d6d6' }}>
                                    <div className='col-12'>
                                        {/*<h4 style={{ margin: 5 }}>Document collections</h4>
                                            <ListBox value={this.props.selectedCollectionsAction}
                                                options={filteredCollections}
                                                onChange={(e) => this.onCollectionChange(e)}
                                                multiple={true}
                                                style={{}}
                                                optionLabel="name" />
                            */}
                                        <div className="datatable-responsive-demo">
                                            {headerCollections}
                                            <DataTable ref={(el) => this.dt = el}
                                                //scrollable
                                                //scrollHeight='250px'
                                                selection={this.props.selectedCollectionsAction}
                                                selectionMode="radiobutton"
                                                removableSort={true}
                                                onSelectionChange={(e) => this.onCollectionChange(e)}
                                                className="p-datatable-responsive-demo"
                                                value={filteredCollections}
                                                responsive="true"
                                                paginator={true}
                                                //paginatorPosition="bottom"
                                                rows={5}
                                                globalFilter={this.state.globalFilterCollections}
                                                //first={this.state.firstQueries}
                                                //rowsPerPageOptions={[10, 20, 50]}
                                                //footer={footerWatchlists}
                                                paginatorTemplate="PrevPageLink PageLinks NextPageLink">
                                                {columnDataCollections}
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12' style={{ paddingRight: 15, paddingTop: 8, marginBottom: 33 }}>
                                    <Button label="Copy"
                                        disabled={this.props.selectedCollectionsAction && this.props.selectedCollectionsAction !== null ? false : true}
                                        onClick={this.handleCopyDocuments}
                                        className='p-button-sm primaryButton'
                                        style={{ marginRight: 5, float: 'right', marginTop: 5 }}
                                    //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredCollections && filteredCollections.length > 1 ? 45 : 25 }} 
                                    />
                                    <Button label="Cancel" onClick={() => this.closeCopyDocumentDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }} />
                                    {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                </Dialog>
                <Dialog focusOnShow={false} visible={this.props.displayDialogMoveDocument} style={{ 'width': "50vw" }}
                    header={this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length === 1 ? "Move document" : this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length > 1 ? "Move documents" : null}
                    modal={true} dismissableMask={true} onHide={() => this.closeMoveDocumentDialog()} className='styledDialog'>
                    <React.Fragment>
                        {filteredCollections && filteredCollections.length === 0 ?
                            <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                <h4 style={{}}>No other collection available. Please create a new collection.</h4>
                                <a style={{ marginBottom: 15, marginLeft: 0, marginTop: 5 }} title="Create new collection" onClick={this.addNewCollection}>
                                    Create collection</a>
                            </div>
                            :
                            <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                <a style={{ marginBottom: 5, marginLeft: 0, marginTop: 5 }}
                                    title="Create new collection" onClick={this.addNewCollection}>
                                    Create collection</a>
                            </div>
                        }
                        {filteredCollections && filteredCollections.length > 0 &&
                            <React.Fragment>
                                <div className="col-12 grid p-fluid" style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 10, paddingTop: 10, marginRight: 0, marginLeft: 0, borderBottom: '1px solid #d6d6d6' }}>
                                    <div className='col-12' >
                                        {/* <h4 style={{ margin: 5 }}>Document collections</h4>
                                            <ListBox value={this.props.selectedCollectionsAction}
                                                options={filteredCollections}
                                                onChange={(e) => this.onCollectionChange(e)}
                                                multiple={true}
                                                style={{}}
                                                optionLabel="name" />
                            */}
                                        <div className="datatable-responsive-demo">
                                            {headerCollections}
                                            <DataTable ref={(el) => this.dt = el}
                                                //scrollable
                                                //scrollHeight='250px'
                                                selection={this.props.selectedCollectionsAction}
                                                selectionMode="radiobutton"
                                                removableSort={true}
                                                onSelectionChange={(e) => this.onCollectionChange(e)}
                                                className="p-datatable-responsive-demo"
                                                value={filteredCollections}
                                                responsive="true"
                                                paginator={true}
                                                //paginatorPosition="bottom"
                                                rows={5}
                                                globalFilter={this.state.globalFilterCollections}
                                                //first={this.state.firstQueries}
                                                //rowsPerPageOptions={[10, 20, 50]}
                                                //footer={footerWatchlists}
                                                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                            >
                                                {columnDataCollections}
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12' style={{ paddingRight: 15, paddingTop: 8, marginBottom: 33 }}>
                                    <Button label={this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length === 1 ? "Move" : this.props.selectedDocumentsAction && this.props.selectedDocumentsAction.length > 1 ? "Move" : null}
                                        disabled={this.props.selectedCollectionsAction && this.props.selectedCollectionsAction.length !== null ? false : true}
                                        onClick={this.handleMoveDocuments}
                                        className='p-button-sm primaryButton'
                                        style={{ marginRight: 5, float: 'right', marginTop: 5 }}
                                    //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredCollections && filteredCollections.length > 1 ? 45 : 25 }}
                                    />
                                    {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                    <Button label="Cancel" onClick={() => this.closeMoveDocumentDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }} />
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                </Dialog>
                {hasUserRole("ROLE_APPS") && this.props.userData.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' &&
                    <CreateEditApp
                        userData={this.props.userData}
                        displayDialog={this.state.displayCreateAppDialog}
                        onHide={(e) => this.setState({
                            displayCreateAppDialog: false
                        })}
                        onShowToast={this.onShowToast}
                        initialData={{
                            library: this.props.selectedCollection,
                            sourceType: { name: 'My Library', id: 2 }
                        }}
                    />}
                <LoadingOverlay
                    active={this.state.fetchingData}
                    spinner={true} >
                </LoadingOverlay>
            </div>
        )
    }
}

export default CollectionDocuments
