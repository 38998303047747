import * as ActionTypes from '../actions'

const initState = {
    roles: [],
    fetching: null
}

const admin = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_ADMIN_ROLES:
            return {
                ...state,
                roles: action.adminRoles
            }
        case ActionTypes.START_FETCHING:
            return {
                ...state,
                fetching: true
            }
        case ActionTypes.DONE_FETCHING:
            return {
                ...state,
                fetching: false
            }
        default:
            return state
    }
}

export default admin