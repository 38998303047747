// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/icons/chem/molecule.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
    Author     : tboehme, apueschel
    Description: document text css
*/

/******************************************************************************/

/*** document text formatting *************************************************/

/******************************************************************************/
body {
    font-family: 'Roboto';
}

h2 {
    font-size: 21px;
    font-weight: normal;
}

#annTextContent h1 {
    font-size: 21px;
}

#annTextContent h2 {
    font-size: 15px;
    font-weight: bold;
}

#annTextContent h3 {
    font-size: 14px;
}

#annTextContent h4 {
    font-size: 13px;
}

#annTextContent h5 {
    font-size: 13px;
}

#annTextContent h6 {
    font-size: 13px;
}


/*
 h1.ocTextHeading_1 {
    margin: 1.5rem 0rem 1rem;
 } 
 */

h2.ocTextHeading_2 {
    margin: 1.5rem 0rem 0.5rem;
    font-size: 15px;
    font-weight: bold;
    /*margin: 0px;*/
}

h3.ocTextHeading_3 {
    margin: 1.5rem 0rem 0.5rem;
    font-size: 14px;
    font-weight: bold;
    /*margin: 0px;*/
}

h4.ocTextHeading_4 {
    font-size: 13px;
    margin: 0.6rem 0rem 0.3rem;
}

/* same as H1 */

.ocTextTitle {
    /*color: #596a8b;*/
    margin: 0;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 21px;
    font-weight: bold;
}

/* same as H2 */

.ocTextSection_title {
    /*
    color: #596a8b;
    font-weight: bold;
    margin: 0;
    font-size: 1.2em;
    */
    /* padding: 15px 0 10px 0; 
 */
}

div.ocTextSection_title>h1 {
    font-size: 1.0em;
    padding: 0;
    /**/
}

.ocTextHeading {
    color: #C04040;
}

.ocAnnNo {
    margin-top: 5px;
}

.ocTextAbstract,
.ocTextSection_abstract {
    /*background-color: #FFF8F8; #FFF7F7 #FFE8E8*/
    background-color: #FFFFFF;
}

/*
 .ocTextAbstract::before {
    content: "Abstract:";
    font-size: 1.1em;
    font-weight: bold;
 }
 */

.ocTextAbs {
    position: absolute;
}

.ocTextAbs p {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
}

.ocTextIndent {
    text-indent: 1em;
}

.ocTextNoShow {
    display: none;
}

.ocTextItalic {
    font-style: italic;
}

.ocTextBold {
    font-weight: bold;
}

.ocTextFontSizeSmall {
    font-size: 0.8em;
}

.ocTextFontSizeLarge {
    font-size: 1.2em;
}

.ocTextFontSizeLarger {
    font-size: 1.6em;
}

.ocTextSpaced {
    letter-spacing: 0.3em;
}

.ocTextSpaceTop {
    padding-top: 0.5em;
}

.ocTextHeader {
    color: #808080;
}

.ocTextFooter {
    color: #808080;
}

.ocTextPageBreak {
    clear: both;
}

.ocTextAfterHeader {
    clear: both;
}

.ocTextAlignLeft {
    float: left;
    padding-right: 1em;
    margin-top: 0px;
}

.ocTextAlignLeft p:first-child {
    margin-top: 0px;
}

.ocTextAlignRight {
    float: right;
    padding-left: 1em;
    margin-top: 0px;
}

.ocTextAlignRight p:first-child {
    margin-top: 0px;
}

.ocTextAlignCenter {
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 0px;
}

.ocTextDir90,
.ocTextDir180,
.ocTextDir270 {
    border: solid 1px black;
    padding: 0.2em 1em 0.2em 1em;
}

.ocTextLineBefore {
    border-top: 1px solid #B2B2B2;
}

.ocTextPre {
    white-space: pre;
}

.ocTextPat_claims {
    white-space: pre-line;
}

/** table in document */

#ocTabView\\: table {
    background: #F2F2F2;
    empty-cells: show;
}

span.ocTextChemicalContainer {
    display: block;
    margin-bottom: 5px;
}

span.conceptInfoLabel {
    display: none;
}

.ne span.conceptInfoLabel {
    display: block;
    padding: 0px 5px 2px 5px;
}

.ne.ann_query span.conceptInfoLabel {
    display: none;
}

.thumbnail {
    max-height: 100px;
    border: 1px solid #aaa;
}

/******************************************************************************/

/*** for PMC journal articles *************************************************/

/******************************************************************************/

/** keywords */

.ocTextKeyword>div {
    display: inline;
}

.ocTextKeyword>div:after {
    content: ", ";
}

.ocTextKeyword>div:last-of-type:after {
    content: "";
}

/** formatting rules */

.ocTextSmallCaps {
    font-variant: small-caps;
}

.ocTextInline {
    display: inline;
}

.ocTextInlineTitle {
    display: inline;
    font-weight: bold;
    font-size: 1em;
    border: none;
}

.ocTextFrame {
    border: thin solid #009999;
    background-color: #DCEDEA;
    /*#d0e9e9;*/
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding: 0.4em;
}

/** content-changing formatting rules */

.ocTextCommaAfter:after {
    content: ", ";
    font-weight: normal;
}

.ocTextCommaBefore:before {
    content: ", ";
}

.ocTextPointAfter:after {
    content: ". ";
}

.ocTextSemicolonAfter:after {
    content: "; ";
}

.ocTextSemicolonAfter:last-of-type:after {
    content: "";
}

.ocTextColonAfter:after {
    content: ": ";
}

.ocTextHyphenAfter:after {
    content: "-";
}

.ocTextSpaceAfter:after {
    content: " ";
}

.ocTextParenthesis:before {
    content: "(";
}

.ocTextParenthesis:after {
    content: ") ";
}

.ocTextSquareBrackets:before {
    content: "[";
}

.ocTextSquareBrackets:after {
    content: "] ";
}

/******************************************************************************/

/*** references and notes *****************************************************/

/******************************************************************************/

.ocSem_Label {
    font-weight: bold;
}

li.ocSem_Reference {
    list-style-type: none;
    margin-left: 2em;
}

li.ocSem_Reference>.ocSem_Label {
    float: left;
    text-indent: -2em;
}

.scrollToTop {
    display: none;
    cursor: pointer;
}

a.active.slide~div.scrollToTop {
    display: inline-block;
}

/******************************************************************************/

/*** referenced content *******************************************************/

/******************************************************************************/

.ocTextRefContentDocument {
    border: 1px solid #B2B2B2;
    border-left: 10px solid #B2B2B2;
    padding: 10px;
    margin: 5px 0 10px;
}

.ocSem_ArticleMeta .ocTextTitle {
    font-size: 1.2em;
    margin: 10px 0;
    font-weight: bold;
}

.ocSem_ArticleMeta .ocSem_PublicationMeta {
    font-size: 0.9em;
}

.ocSem_ArticleMeta .ocSem_Authors {
    color: gray;
    font-size: 0.9em;
}

.ocSem_ArticleMeta .ocSem_SourceLink {
    font-size: 0.9em;
    margin: 10px 0 0;
}

.ocTextReferencedContent .ocTextRefContentDocument .ocSem_ArticleContent h1.ocTextSection_title {
    display: none;
}

/******************************************************************************/

/*** info window that will be shown when mouse moves over named entity ********/

/******************************************************************************/

.oc_iWnd {
    position: absolute;
    width: 400px;
    z-index: 100;
    background: white;
    border-top: 1px solid #4620d0;
    border-left: 1px solid #4620d0;
    border-bottom: 1px solid #150043;
    border-right: 1px solid #150043;
    /*  visibility:hidden;*/
    /*  font-size:0.8em; */
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    overflow: auto;
}

.ocIwNewAnn {
    border-top: 1px solid black;
}

.ocIwNewAnn th,
.ocIwNewAnn td {
    padding-top: 0.3em;
}

.ocNewEntryStart {
    display: none;
    padding-top: 5px;
    padding-bottom: 5px;
}

tr+.ocNewEntryStart {
    display: table-row;
}

.ocIwLastEntry th,
.ocIwLastEntry td {
    padding-bottom: 0.4em;
}

/*
 .ocIwAnnB {
    background: #efefff;
 }
 
 .ocIwAnnB.ocIwEntryB {
    background: #e0e0ff;
 }
 
 .ocIwEntryB {
    background: #EFEFFC;
 }
 */
.ocIwAttr {
    font-weight: bold;
    padding-right: 1em;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
}

.ocIwVal {
    max-height: 8em;
    overflow: auto;
}

.ocIwValList {
    padding-left: 20px;
    margin: 2px 5px;
}

.ocIwVal a {
    text-decoration: none;
}

.ocIwVal a:hover {
    text-decoration: underline;
}

.ocIwVHead {
    font-weight: bold;
}

/** font size of windows when mouse moves over named entity */

div.oc_iWnd table {
    font-size: 0.97em;
    /* 0.9 */
}

.ocTextEnum {
    font-size: 1.0em;
}

/******************************************************************************/

/*** links ********************************************************************/

/******************************************************************************/

a.linkButton {
    color: #009999;
    background: white;
    text-decoration: none;
    border: solid thin;
    padding: 1px;
}

a.linkButton:hover {
    color: white;
    background-color: #009999;
    text-decoration: underline;
    cursor: pointer;
}

/******************************************************************************/

/*** compound annotations: add overline and icon ******************************/

/******************************************************************************/

span[id^='annId'].ann_chemCompound {
    text-decoration: overline;
}

span[id^='annId'].ann_chemCompound+.ann_chemCompound_img {
    background-repeat: no-repeat;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    /* Chemical-5-icon_16px.png molecule_colored.svg */
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: 0px 3px 0 2px;
    position: relative;
    top: 3px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___.toString();
