import React, { Component } from "react";
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { createDocViewUrl } from '../util';


class PatentFamilyMembers extends Component {

    constructor(props) {
        super(props)
    }

    linkTemplate = (rowData, column) => {
        return <span><a style={{ color: '#007fdb' }} target="_blank" rel="noopener noreferrer" href={rowData.url}>{rowData.extId}</a></span>;
    }

    noLinkTemplate = (rowData, column) => {
        return <span> {rowData.extId}</span>;
    }

    titleTemplate = (rowData, column) => {
        let title = ""
        if (rowData.title !== `[${rowData.extId}]`) {
            title = rowData.title
        } else {
            title = rowData.title + ' (title not yet available)'
        }
        if (!this.props.showTitleOnly) {
            return (
                // target="_blank" 
                <span title="Open annotated document">
                    <a rel="noopener noreferrer" href={createDocViewUrl(this.props.repID, rowData.ocDocId)} target="_blank">
                        {title}
                    </a>
                </span>
            );
        } else {
            return (
                <span> {title}
                </span>
            )
        }
    }

    checkFamilyMembersTemplate = (rowData) => {
        return <div key={rowData.ocDocId}>
            <span>
                <Checkbox
                    key={rowData.ocDocId}
                    inputId={rowData.ocDocId}
                    disabled={!this.props.remove && (rowData.extId === this.props.selectedPatent ||
                        this.props.allDocuments?.filter(el => el.metadata?.hasOwnProperty('Pat_document-id') ? el.metadata['Pat_document-id'][0] === rowData.extId : null)?.length > 0 ||
                        rowData.docMetadata?.hasOwnProperty('Pat_document-id') && (rowData.docMetadata['Pat_document-id'][0] === this.props.selectedPatent) ||
                        (rowData.docMetadata?.hasOwnProperty('Pat_document-id') && (this.props.allDocuments?.filter(el => el.metadata?.hasOwnProperty('Pat_document-id') ? el.metadata['Pat_document-id'][0] === rowData.docMetadata['Pat_document-id'][0] : null)?.length > 0)))
                        ? true : false}
                    checked={(!this.props.remove && (this.props.selectedDocuments.filter(el => el.metadata?.hasOwnProperty('Pat_document-id') ? el.metadata['Pat_document-id'][0] === rowData.extId : null)?.length > 0 ||
                        rowData.docMetadata?.hasOwnProperty('Pat_document-id') && (rowData.docMetadata['Pat_document-id'][0] === this.props.selectedPatent) ||
                        this.props.allDocuments?.filter(el => el.metadata?.hasOwnProperty('Pat_document-id') ? el.metadata['Pat_document-id'][0] === rowData.extId : null)?.length > 0 ||
                        (rowData.docMetadata?.hasOwnProperty('Pat_document-id') && (this.props.allDocuments?.filter(el => el.metadata?.hasOwnProperty('Pat_document-id') ? el.metadata['Pat_document-id'][0] === rowData.docMetadata['Pat_document-id'][0] : null)?.length > 0)) ||
                        rowData.extId === this.props.selectedPatent))
                        || (this.props.remove && (this.props.selectedDocumentsRemove.filter(el => el.metadata?.hasOwnProperty('Pat_document-id') ? el.metadata['Pat_document-id'][0] === rowData.extId : null)?.length > 0))}
                    onChange={(e) => this.props.handleDocumentSelection(e, rowData, this.props.remove)}
                >
                </Checkbox>
            </span>
        </div>
    }


    render() {
        return (
            this.props.familyMembers ?
                this.props.allowSelection ?
                    this.props.familyMembers.map(patFam => {
                        return (
                            <div key={patFam.label} className="col-12">
                                <label style={{ fontWeight: 500, fontSize: 'medium', textTransform: 'capitalize' }}>{patFam.label}</label>
                                <DataTable style={{ marginTop: 10 }} value={patFam.members} className="breakWord">
                                    <Column
                                        key="check"
                                        field="check"
                                        body={this.checkFamilyMembersTemplate}
                                        style={{ width: '4em' }}>
                                    </Column>
                                    <Column field="title" body={this.titleTemplate} header="Title" sortable={true} />
                                    {this.props.showTitleOnly &&
                                        <Column sortField="extId" body={this.noLinkTemplate} header="Patent number" sortable={true} />
                                    }
                                </DataTable>
                            </div>
                        )
                    })
                    :
                    <DataTable value={this.props.familyMembers} className="breakWord">
                        {/*<Column field="docMetadata.Pat_document-id" header="Patent number" sortable={true} />*/}
                        <Column field="title" body={this.titleTemplate} header="Title" sortable={true} />
                        <Column field="docMetadata.Pat_publication_date" header="Publ. Date" sortable={true} />
                        <Column sortField="extId" body={this.linkTemplate} header="Link" sortable={true} />
                    </DataTable>
                :
                <div>No family members</div>
        );
    }
}

export default PatentFamilyMembers;
