const ButtonColumn = ({
    data,
    label,
    onClick
}) => {

    return (
        <div className="flex align-items-center h-full">
            <a onClick={() => onClick(data)}>{label}</a>
        </div>
    );
}

export default ButtonColumn;