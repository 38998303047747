import React, {useEffect, useMemo, useState} from 'react';
import {RightAsideDescription} from "../../../common/RightAsideDescription/RightAsideDescription";
import {useDispatch, useSelector} from "react-redux";
import BioactivitiesSearch from "./BioactivitiesSearch/BioactivitiesSearch";
import BioactivitiesList from "./BioactivitiesList/BioactivitiesList";
import {addSearchIndex} from "../../../common/helpers/addSearchIndex";
import {
    bioactivitiesFirstResultIndex,
    bioactivitiesIsFetching,
    bioactivitiesIsFirstRender,
    bioactivitiesQueryTerms,
    bioactivitiesResultCount,
    bioactivitiesRoleMapping,
    bioactivitiesSearchResults,
    bioactivitiesSelectedReposForSearch,
    bioactivitiesSortDirection,
    bioactivitiesSortField
} from "../../../../redux/selectors/selectors";
import {createRepoAndIdMap} from "../../../common/helpers/createRepoAndIdMap";
import {
    searchBioactivities, setBioactivitiesIsFirstRender, toggleBioactivitiesIsFetching
} from "../../../../redux/actions/BioactivitiesActions";
import {createQueryList} from "../../../common/helpers/createQueryList";
import {sendExportToFileApiRequest} from "../../../../api";
import {APP_PROPERTIES} from "../../../../properties";
import {fieldListForExportBioactivitiesInXLSX} from "./fieldListForExportBioactivitiesInXLSX";
import {usePrevious} from "../../../common/customHooks/usePrevious";

const BioactivitiesRightAside = ({showToast, clearToast}) => {
    const [needSearch, setNeedSearch] = useState({});

    const isFirstRender = useSelector(bioactivitiesIsFirstRender);
    const queryTerms = useSelector(bioactivitiesQueryTerms);
    const roleMapping = useSelector(bioactivitiesRoleMapping);
    const firstResultIndex = useSelector(bioactivitiesFirstResultIndex);
    const resultCount = useSelector(bioactivitiesResultCount);
    const isFetching = useSelector(bioactivitiesIsFetching);
    const searchResults = useSelector(bioactivitiesSearchResults);
    const selectedReposForSearch = useSelector(bioactivitiesSelectedReposForSearch);
    const sortField = useSelector(bioactivitiesSortField);
    const sortDirection = useSelector(bioactivitiesSortDirection);
    const prevQueryTerms = usePrevious(queryTerms);

    const dispatch = useDispatch();

    // repositoriesIdList is used for for calling search API method
    const repositoriesIdList = useMemo(() => selectedReposForSearch?.map(repo => repo.id), [selectedReposForSearch]);
    const repoNameAndIdMap = useMemo(() => createRepoAndIdMap(selectedReposForSearch), [selectedReposForSearch]);

    const resultsWithIndexes = addSearchIndex(searchResults.relationResults);
    const shortText = 'This table allows you to browse through structure-activity relationship data.';
    const fullText = 'This table allows you to browse through structure-activity relationship data. Use the search box to search for chemical compounds or targets and add additional filters using the facets on the left.';
    const randomName = `bioactivities_results_${Date.now().toString()}`;

    const exportSelectedResults = async (values) => {
        try {
            const relationIds = values.map(({relationId}) => relationId);
            // prevent sending http request when app is fetching data
            if (!isFetching && repositoriesIdList.length) {
                dispatch(toggleBioactivitiesIsFetching(true));

                const data = {
                    relationIds,
                    fields: fieldListForExportBioactivitiesInXLSX,
                };

                await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/bioactivities/relations/v2/xlsx`, `${randomName}.xlsx`, data);
            }
        } catch (e) {
            showToast(`Something went wrong while loading data\n ${e} `, 'Error occurred', 'error', 10000);
        } finally {
            dispatch(toggleBioactivitiesIsFetching(false));
        }
    };

    const exportTopResults = async (topX) => {
        try {
            // prevent sending http request when app is fetching data
            if (!isFetching && repositoriesIdList.length) {
                dispatch(toggleBioactivitiesIsFetching(true));
                const fieldQueryList = createQueryList(queryTerms, roleMapping);
                const data = {
                    repositories: repositoriesIdList,
                    sortBy: sortField,
                    sortDataType: "CONCEPT",
                    sortType: sortDirection,
                    fieldQueryList,
                    fields: fieldListForExportBioactivitiesInXLSX,
                    topX
                };


                await sendExportToFileApiRequest('POST', `${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/bioactivities/relations/xlsx`, `${randomName}.xlsx`, data);
            }
        } catch (e) {
            showToast(`Something went wrong while loading data\n ${e} `, 'Error occurred', 'error', 10000);
        } finally {
            dispatch(toggleBioactivitiesIsFetching(false));
        }
    };

    const handleSearch = async () => {
        // prevent sending http request when app is fetching data

        if (!isFetching && repositoriesIdList.length) {
            const fieldQueryList = createQueryList(queryTerms, roleMapping);

            const data = {
                repositories: repositoriesIdList,
                sortBy: sortField,
                sortDataType: "CONCEPT",
                sortType: sortDirection,
                fieldQueryList,
                startIndex: firstResultIndex,
                count: resultCount
            };

            dispatch(searchBioactivities(data, repoNameAndIdMap, showToast));
        }
    };

    useEffect(() => {

        if (!isFirstRender) {
            handleSearch();
        }
    }, [resultCount, firstResultIndex, isFirstRender, needSearch, sortField, sortDirection]);

    useEffect(() => {
        // search should be run after mounting
        if (repositoriesIdList.length && isFirstRender) {
            handleSearch();
            dispatch(setBioactivitiesIsFirstRender(false));
        }
    }, [repositoriesIdList.length, isFirstRender, dispatch]);

    useEffect(() => {
        // run default search after removing last item from search input
        if (prevQueryTerms?.length && !queryTerms.length && !isFetching && !isFirstRender) {
            handleSearch();
        }
    }, [queryTerms, isFetching, isFirstRender, dispatch, prevQueryTerms]);

    return (
        <div style={{paddingLeft: 17}}>
            <h1 style={{marginBottom: 5}}>Bioactivities</h1>
            <RightAsideDescription shortText={shortText} fullText={fullText}/>
            <BioactivitiesSearch searchResults={searchResults} repositoriesIdList={repositoriesIdList}
                                 setNeedSearch={setNeedSearch} showToast={showToast} clearToast={clearToast}
            />

            <BioactivitiesList hitCount={searchResults.totalHits}
                               exportSelectedResults={exportSelectedResults}
                               exportTopResults={exportTopResults}
                               value={resultsWithIndexes}/>
        </div>
    );
};

export default BioactivitiesRightAside;
