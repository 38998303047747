import { fetchAdditionalDocumentInfo, changeDocumentRating, changeDocumentReadStatus, addDocumentsToCollections, removeDocumentsFromDocumentCollection } from '../../api/content/DocumentApi';
import * as ActionTypes from './index';


/**
 * Fetches additional data for a single document (e.g. rating, read status, collections).
 */
export const fetchAdditionalDocumentData = (repositoryID, documentID) => (dispatch) => {

    dispatch(ActionTypes.startFetchingAdditionalDocumentData());

    return fetchAdditionalDocumentInfo(repositoryID, documentID)
        .then(answer => {
            //console.log('fetch additional data: answer: ', answer);
            switch (answer.status) {
                case 'SUCCESS': {
                    dispatch(additionalDocumentData(answer.payload[0]));
                    dispatch(ActionTypes.doneFetchingAdditionalDocumentData());
                    break;
                }

                default: {
                    dispatch(ActionTypes.doneFetchingAdditionalDocumentData());
                }
            }
        })
}

/**
 * Updates the rating of a single document.
 */
export const updateDocumentRating = (repositoryID, documentID, title, stars) => (dispatch) => {

    dispatch(ActionTypes.startUpdatingDocumentRating());

    return changeDocumentRating(repositoryID, documentID, title, stars)
        .then(answer => {
            //console.log('update document rating: answer: ', answer);
            switch (answer.status) {
                case 'SUCCESS': {
                    dispatch(additionalDocumentData(answer.payload[0]));
                    dispatch(ActionTypes.doneUpdatingDocumentRating());
                    break;
                }

                default: {
                    dispatch(ActionTypes.doneUpdatingDocumentRating());
                }
            }
        })
}

/**
 * Updates the read status of a single document.
 */
export const updateDocumentReadStatus = (repositoryID, documentID, title, isRead) => (dispatch) => {

    dispatch(ActionTypes.startUpdatingDocumentReadStatus());

    return changeDocumentReadStatus(repositoryID, documentID, title, isRead)
        .then(answer => {
            //console.log('update document read status: answer: ', answer);
            switch (answer.status) {
                case 'SUCCESS': {
                    dispatch(additionalDocumentData(answer.payload[0]));
                    dispatch(ActionTypes.doneUpdatingDocumentReadStatus());
                    break;
                }

                default: {
                    dispatch(ActionTypes.doneUpdatingDocumentReadStatus());
                }
            }
        })
}

/**
 * Adds a single document to collections.
 */
export const addDocumentToCollections = (repositoryID, documentID, title, docCollectionIDs) => (dispatch) => {

    dispatch(ActionTypes.startUpdatingDocumentCollections());

    return addDocumentsToCollections(docCollectionIDs, [{
        docId: documentID,
        repository: repositoryID,
        repositoryId: repositoryID,
        title: title
    }]).then(answer => {
        //console.log('update document read status: answer: ', answer);
        switch (answer.status) {
            case 'SUCCESS': {
                dispatch(fetchAdditionalDocumentData(repositoryID, documentID));
                dispatch(ActionTypes.doneUpdatingDocumentCollections());
                break;
            }

            default: {
                dispatch(ActionTypes.doneUpdatingDocumentCollections());
            }
        }
        return answer;
    })
}

/**
 * Removes a single document from a single collection.
 */
export const removeDocumentFromCollection = (repositoryID, documentID, docCollectionID, docCollectionDocumentLink) => (dispatch) => {

    dispatch(ActionTypes.startUpdatingDocumentCollections());

    return removeDocumentsFromDocumentCollection(docCollectionID, docCollectionDocumentLink).then(answer => {
        //console.log('update document read status: answer: ', answer);
        switch (answer.status) {
            case 'SUCCESS': {
                dispatch(fetchAdditionalDocumentData(repositoryID, documentID));
                dispatch(ActionTypes.doneUpdatingDocumentCollections());
                break;
            }

            default: {
                dispatch(ActionTypes.doneUpdatingDocumentCollections());
            }
        }
        //console.log('return answer', answer);
        //return answer;
    })
}

export const additionalDocumentData = (additionalDocumentData) => ({
    type: ActionTypes.ADDITIONAL_DOCUMENT_DATA,
    additionalDocumentData
})